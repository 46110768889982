(function () {
    "use strict";

    Controllers.ClientPortalPreviewController = class ClientPortalPreviewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AppStates, $translate, AnalyticsService, CompaniesManager, PopupMessageService, _, ClientPortalService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalPreviewController';

            this.AppStates = AppStates;
            this.$translate = $translate;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.CompaniesManager = CompaniesManager;
            this.ClientPortalService = ClientPortalService;
            this.company = CompaniesManager.getCurrCompany();
            this.activePreviewDevice = 'desktop';
            this._ = _;
            this.StatsigService = StatsigService;

            this._init();
            this._loadViewModel();
            if (!this.company.wasFullyFetched()) {
                this.registerOnce(this.company, 'success', () => this._loadViewModel());
            }
        }

        _init(){
            this.unsavedChanges = false;
            this.saving = false;
            this._loadViewModel();

        }

        _loadViewModel() {
            var clientPortal = this.ClientPortalService.getDefaultClientPortalConfiguration(this.company);
            this.clientPortalModel = angular.copy(clientPortal);
        }

        onUpdatedPreview() {
            this.unsavedChanges = true;
        }

        _toCompanySettingsPage() {
            this.goToState(this.AppStates.root_core_navigation_settings_company_client_portal);
        }

        _cancelEditedPortal(){
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edited_client_portal_cancel);
            this._toCompanySettingsPage();
        }

        confirmSaving(){
            if (!this.unsavedChanges) {
                this._toCompanySettingsPage();
                return;
            }

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.none,
                'CLIENT_PORTAL._UNSAVED_WARNING_',
                  'CLIENT_PORTAL._YES_PUBLISH_',
                  'FREQUENT_BUTTONS._CANCEL_',
                        'CLIENT_PORTAL._PUBLISH_CHANGES_')
                .then(() => { this._saveClientPortal(); });
        }

        exitClientPortal() {
            if (this.unsavedChanges) {
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.none,
                    'CLIENT_PORTAL._ANY_CHANGES_WILL_BE_LOST_',
                      'CLIENT_PORTAL._YES_EXIT_',
                      'FREQUENT_BUTTONS._CANCEL_',
                            'CLIENT_PORTAL._EXIT_WITHOUT_SAVING_')
                    .then(() => { this._cancelEditedPortal() });
            } else {
                this._toCompanySettingsPage();
            }
        }

        _saveClientPortal() {
            if (this.saving) {
                return;
            }
            this.unsavedChanges = false;
            this.saving = true;

            let data = this.ClientPortalService.formatConfigurationForRequest(this.clientPortalModel);

            this.CompaniesManager.updateClientPortalConfiguration(this.company, data).then(() => {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edited_client_profile_confirmed);
                this.saving = false;
                this._toCompanySettingsPage();
            });
        }
    };
}());
