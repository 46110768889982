Directives.ZoomIntegrationDirective = function ZoomIntegrationDirective()  {

    "use strict";

    // @ngInject
    function ZoomIntegrationDirectiveControllerCtor($scope, $injector, intuit,
                                                          Gon, Routes, AnalyticsService, moment, _, PopupMessageService,
                                                          UsersManager, CompaniesManager, UrlMetadataService, $window,
                                                          UserService, FeaturesService, ReactModalService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ZoomIntegrationDirectiveController';

        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this._ = _;
        this.UsersManager = UsersManager;
        this.user = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();
        this.$window = $window;
        this.UserService = UserService;
        this.FeaturesService = FeaturesService;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        this.isLoadingZoom = true;
        this.zoomAuthProvider = null;

        this.restrictedFeature = this.FeatureRestrictionService.features.zoom;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('zoomIntegrationVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });

        this.UsersManager.getZoomAuthProvider(this.user).then(function (response) {
            this.zoomAuthProvider = response.data.zoom_provider;
        }.bind(this)).finally(function final() {
            this.isLoadingZoom = false;
        }.bind(this));
    }


    var ZoomIntegrationDirectiveController = Class(Controllers.BaseController, {

        constructor: ZoomIntegrationDirectiveControllerCtor,

        onZoomConnectClick: function onZoomConnectClick() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            if (this.connectZoomInProgress) {
                return;
            }
            
            this.UserService.connectToZoomAccount(this.$window.location.href);
        },

        onZoomDisconnectClick: function onZoomDisconnectClick() {

            if (this.disconnectZoomInProgress) {
                return;
            }

            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, 'BANK_INFO.ZOOM_SETTINGS_INTEGRATION._DISCONNECT_CONFIRM_').then(function disconnect() {
                this.disconnectZoomInProgress = true;
                this.UsersManager.disconnectZoom().finally(function() {
                    this.zoomAuthProvider = null;
                    this.disconnectZoomInProgress = false;
                }.bind(this));
            }.bind(this));
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/settings/account/zoom_integration/zoom_integration_template.html',
        controller: ZoomIntegrationDirectiveController,
        controllerAs: 'zoomIntegrationVm',
        bindToController: true
    };
};
