(function () {
    'use strict';

    // How to use:
    // 1. Put vars names in variablesList under the group name (e.g. dynamicContent)
    // 2. Call init with this new group name
    // 3. Set the vars you need using setStyle. no need to pass group name anymore.
    // 4. If needed, call removeStyle to explicitly remove specific variable name.
    // 4. on destroy - call clearAllVarsByType with the group name ('type') to delete all relevant vars.

    Services.CssVariablesService = class CssVariablesService {

        // @ngInject
        constructor($window) {
            this.$window = $window;
            this.root = document.documentElement;

            this.variablesList = {
                dynamicContent : {
                    dynamicContentFontFamily:           '--dynamic-content-font-family',
                    dynamicContentFontSize:             '--dynamic-content-font-size',
                    dynamicContentFontWeight:           '--dynamic-content-font-weight',

                    dynamicContentTextColor:            '--dynamic-content-text-color',
                    dynamicContentPlaceholderColor:     '--dynamic-content-placeholder-color',
                    dynamicContentInputBorderColor:     '--dynamic-content-input-border-color',
                    dynamicContentInputBackgroundColor : '--dynamic-content-input-background-color',

                    dynamicContentFormBackgroundColor : '--dynamic-content-form-background-color',

                    dynamicContentButtonFontFamily:             '--dynamic-content-button-font-family',
                    dynamicContentButtonFontSize:               '--dynamic-content-button-font-size',
                    dynamicContentButtonFontWeight:             '--dynamic-content-button-font-weight',
                    dynamicContentButtonBackgroundColor:       '--dynamic-content-button-background-color',
                    dynamicContentButtonBackgroundColorHover:   '--dynamic-content-button-background-color-hover',
                    dynamicContentButtonTextColor : '--dynamic-content-button-text-color',
                },

                calendar: {
                    currTeamMemberBackgroundColor: '--current-team-member-background-color'
                },
            };

            this.nonVolatileVariableList = {
                companyBrandColor: '--company-brand-color'
            };

            if (!this._isNativeSupport()) {
                // Use Ponyfill to support css-vars for browsers that doesn't support css vars natively (IE)
                $window.cssVars({
                    include: 'style'
                });
            }
        }

        _isNativeSupport() {
            return this.$window.CSS && this.$window.CSS.supports && this.$window.CSS.supports("(--a: 0)");
        }

        init(type) {
            this.currVarsList = this.variablesList[type];
        }

        clearAllVarsByType(type) {
            for (let variableKey of Object.keys(this.variablesList[type])) {
                this.removeStyle(variableKey);
            }
        }

        removeStyle(variableKey) {
            if (this.root) {
                this.root.style.removeProperty(this.currVarsList[variableKey]);
            }
        }

        setStyle(variableKey, styleValue) {
            if (this.root) {

                if(this.currVarsList && this.currVarsList[variableKey]){
                    this.root.style.setProperty(this.currVarsList[variableKey], styleValue);

                }else{

                    this.root.style.setProperty(this.nonVolatileVariableList[variableKey], styleValue);
                }
            } else {
                console.error("Please run 'init()' before settings new style")
            }
        }

        getStyle(variableKey) {
            if (this.root) {

                return this.root.style.getPropertyValue(this.currVarsList[variableKey]) || this.root.style.getPropertyValue(this.nonVolatileVariableList[variableKey]);
            }

        }
    };

}());

