(function () {
    "use strict";

    Controllers.SelectTeamMemberController = Class(Controllers.BaseController, {
        // need to pass all injections from child controller.
        // @ngInject
        constructor: function constructor($scope, $modalInstance, $injector, $, userList, canSelectMultiple) {

            Controllers.SelectTeamMemberController.$super.call(this, $scope, $injector);
            this.__objectType = 'SelectTeamMemberController';

            this.modalInstance = $modalInstance;
            this.userList = userList || [];
            this.canSelectMultiple = canSelectMultiple || false;
            if (this.canSelectMultiple) {
                this.selectedUsersHash = [];
            } else {
                this.selectedUsersHash = null;
            }
            this.actionInProgress = false;
        },

        setSelection: function setSelection(user) {
            if (this.canSelectMultiple) {
                if ($.inArray(user.email,this.selectedUsersHash) > -1) {
                    this.selectedUsersHash.splice($.inArray(user.email,this.selectedUsersHash),1);
                }
                else {
                    this.selectedUsersHash.push(user.email);
                }
            } else {
                if (this.selectedUsersHash !== user) {
                    this.selectedUsersHash = user;
                } else {
                    this.selectedUsersHash = null;
                }
            }
        },

        hasSelection: function hasSelection() {
            if (this.canSelectMultiple) {
                return this.selectedUsersHash.length > 0;
            } else {
                return this.selectedUsersHash !== null;
            }
        },

        isSelected: function isSelected(user) {
            if (this.canSelectMultiple) {
                return $.inArray(user.email,this.selectedUsersHash) > -1;
            } else {
                return user === this.selectedUsersHash;
            }
        },

        getSelectedUsers: function getSelectedUsers() {
            var self = this;
            if (this.canSelectMultiple) {
                return this.userList.filter(function(user) {
                    return self.isSelected(user);
                });
            } else {
                return [this.selectedUsersHash];
            }
        },

        dismiss: function dismiss() {
            this.modalInstance.dismiss();
        },

        closeModal: function closeModal() {
            this.modalInstance.close();
        }

    });
}());

