/**
 * Created by dvircohen on 27/03/2016.
 */
(function () {
    'use strict';

    Directives.PlaceholderDirective = function PlaceholderDirective() {
        // @ngInject
        function PlaceholderDirectiveControllerCtor($scope, $injector, $log, _) {
            this.constructor.$super.call(this, $scope, $injector, $log);
            this.__objectType = 'PlaceholderDirectiveController';
            this.$log = $log;
            this._ = _;
        }

        var PlaceholderDirectiveController = Class(Controllers.BaseController, {
            constructor: PlaceholderDirectiveControllerCtor
        });

        return {
            restrict: 'A',
            controller: PlaceholderDirectiveController,
            bindToController: true,

            link: function placeholderLink($scope, $elem, $attr) {

                var options = $scope.$eval($attr.hbPlaceholderOptions) || {};
                var placeholderText = $attr.hbPlaceholder;

                if (_.isEmpty(placeholderText)) {
                    this.$log.error('Can not use hb-placeholder without text');
                    return;
                }

                if (!_.isObject(options)) {
                    this.$log.error('hb-placeholder-options must be a hash');
                    return;
                }

                // Set the placeholder text
                $elem[0].ngAttrPlaceholder = placeholderText;

                // Handle hide when disabled
                if (options.hideWhenDisabled) {
                    $scope.$watch(function() { return $elem[0].disabled; }, function(disabled) {
                       $elem[0].placeholder = disabled ? '' : placeholderText;
                    });
                }

                if (options.dynamic) {
                    $scope.$watch(function() { return $attr.hbPlaceholder; }, function changed() {
                        $elem[0].placeholder = $attr.hbPlaceholder;
                    });
                }
            }
        };
    };

}());


