// @ngInject

Models.NetworkReferralModel = function NetworkReferralModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'NetworkReferralModel';
            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        }

    });

};

