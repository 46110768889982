/**
 * The fb-focus-me directive
 *
 * set focus dynamically. lets you for instance set focus on an input field as a reaction to something.
 *
 */


(function () {
    'use strict';

    // @ngInject
    Directives.FocusMe = function FocusMe($timeout) {

        function FocusMeLink(scope, element, attrs) {
            var delay = attrs.hbFocusDelay || 0;
            scope.$watch(attrs.hbFocusMe, function (newValue, oldValue) {
                if (newValue === true) {
                    $timeout(function () {
                        element[0].focus();
                    }, delay);
                } else {
                    if (newValue === false && oldValue === true) {
                        $timeout(function () {
                            element[0].blur();
                        });
                    }
                }
            });
        }

        return {
            restrict: 'A',
            link: FocusMeLink
        };
    };


}());

