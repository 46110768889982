/**
 * Created by dvircohen on 17/05/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function SendBrochureWarningControllerCtor($scope, $injector, AnalyticsService, $modalInstance, title) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SendBrochureWarningController';
        AnalyticsService.trackPageView(this, 'SendBrochureWarningView');
        this.title = title;
        this.$modalInstance = $modalInstance;
    }

    Controllers.SendBrochureWarningController = Class(Controllers.BaseController, {
        constructor: SendBrochureWarningControllerCtor,
        
        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },
        
        send: function send() {
            this.$modalInstance.close();
        }
    });
}());

