(function () {
    "use strict";


    // @ngInject
    function JsSlicklightboxServiceCtor(PubSubService) {

        this.LIGHTBOX_INIT = 'lightboxInitialzied';
        this.LIGHTBOX_SHOWN = 'lightboxShown';
        this.LIGHTBOX_IMAGE_CHANGE = 'lightboxImageChanged';
        this.LIGHTBOX_HIDDEN = 'lightboxHidden';
        PubSubService.ventMyBitchUp(this);
        this.images = [];
        this.instance = null;
        this.containerElement = null;
        this.containerElementName = '';
    }

    Services.JsSlicklightboxService = Class(function () {
        return {
            constructor: JsSlicklightboxServiceCtor,

            show: function show() {


                if (this.instance) {
                    this.instance.unslickLightbox();
                }

                this.instance = this.containerElement.slickLightbox({
                    images: this.images,
                    itemSelector: 'img',
                    closeOnBackdropClick: false,
                    slick: {
                        onAfterChange: this.onImageChanged.bind(this),
                        prevArrow: '<button type="button" data-role="none" class="hb-slick-arrow slick-prev">Previous</button>',
                        nextArrow: '<button type="button" data-role="none" class="hb-slick-arrow slick-next">Next</button>'
                    }
                });

                this.instance.slickLightboxInstance.init(0);

            },

            onImageChanged: function onImageChanged() {
                this.trigger(this.LIGHTBOX_IMAGE_CHANGE, {container: this.containerElementName});
            },

            init: function init(images, containerElementSelector) {
                this.images = images;

                this.containerElementName = containerElementSelector;
                this.containerElement = $(containerElementSelector);

                this.containerElement.on({
                    'shown.slickLightbox': function () {
                        this.trigger(this.LIGHTBOX_SHOWN, {container: this.containerElementName});
                    }.bind(this),
                    'hidden.slickLightbox': function () {
                        this.trigger(this.LIGHTBOX_HIDDEN, {container: this.containerElementName});
                    }.bind(this)
                });
            }
        };

    });
}());
