Components.SortColumn = {
    bindings: {
        sortInvoke: '&',
        sortLabel: '@',
        sortActiveColumn: '<',
        sortColumnItem: '@',
        sortDirection: '<'
    },

    // @ngInject
    controller: function EmptyStateGraphic($scope, $element, $attrs){

    },
    name: 'nxFilterColumn',
    templateUrl: 'angular/app/modules/common/ui_components/nx_sort_column/nx_sort_column_component.html',
};