// @ngInject
Directives.ToggleOnHover = function ToggleOnHover($parse, $timeout, DeviceService) {
    var directiveName = 'hbToggleOnHover';

    function linkFn(scope, element, attr) {

        var waitTimeInMs = 150;
        var visibleTimer;
        var hideTimer;

        var createHandler = function createHandler(val) {
            return function handler(event) {
                var onChangeFn;
                if(attr[directiveName + "Callback"]) {
                    onChangeFn = $parse(attr[directiveName + "Callback"]);
                }
                var callback = function () {

                    if(attr[directiveName]) {

                        if (val) {
                            visibleTimer = $timeout(function() {
                                $parse(attr[directiveName]).assign(scope, val);
                            }, waitTimeInMs);
                            $timeout.cancel(hideTimer);
                        } else {
                            hideTimer = $timeout(function() {
                                $parse(attr[directiveName]).assign(scope, val);
                            }, waitTimeInMs);
                            $timeout.cancel(visibleTimer);
                        }
                    }

                    if(onChangeFn) {
                        onChangeFn(scope, {val: val});
                    }
                };

                scope.$apply(callback);

            };
        };

        var createTouchHandler = function createTouchHandler(val) {
            return function handler(event) {
                var callback = function () {
                    // The $timeout messes with touch interpretation
                    if (attr[directiveName]) {
                        $parse(attr[directiveName]).assign(scope, val);
                    }
                };
                scope.$apply(callback);
            };
        };


        if (DeviceService.checkIfTouchScreen()) {
            element.on('touchstart.hbToggleOnHover', createTouchHandler(true));

            scope.$on('$destroy', function() {
                element.off('touchstart.hbToggleOnHover');
            });

        } else {

            // bind to events
            element.on('mouseenter.hbToggleOnHover', createHandler(true));
            element.on('mouseleave.hbToggleOnHover', createHandler(false));

            // unbind
            scope.$on('$destroy', function() {
                element.off('mouseenter.hbToggleOnHover');
                element.off('mouseleave.hbToggleOnHover');
            });

        }


    }

    return {
        restrict: 'A',
        link: linkFn
    };
};
