(function () {
    "use strict";

    class CompanySettingsPaymentMethodsComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, uuid4, UsersManager, ModalService, $q, AnalyticsService, $translate,
                    GoogleTagManagerService, AppStates, $state, DeviceService) {
            super($scope, $injector);
            this.__objectType = 'CompanySettingsPaymentMethodsController';
            this.DeviceService = DeviceService;
            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            this.$state = $state;
            this.$q = $q;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.AppStates = AppStates;
            this.UsersManager = UsersManager;
            this.GoogleTagManagerService = GoogleTagManagerService;

            AnalyticsService.trackPageView(this, 'payment method screen');
        }
    }

    Components.CompanySettingsPaymentMethods = {
        controller: CompanySettingsPaymentMethodsComponent,
        name: 'hbCompanySettingsPaymentMethods',
        templateUrl: 'angular/app/modules/core/features/settings/company/payment_methods/company_settings_payment_methods_template.html'
    };

}());
