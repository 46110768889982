/**
 * Created by saratheneale.
 */

//this directive should add some customized style to an element for branding purposes.
// it will have the option to add a style tag for some hover styles.
// most of customized branding can be taken care of with ng-style tags in transcluded elements.
// However, we need to account for custom branded hover states as well. That's why this directive exists.

Directives.CustomizableBrandingDirective = function CustomizableBrandingDirective() {

    // @ngInject
    function CustomizableBrandingDirectiveControllerFunc($element, CompaniesManager, CompanyService, AnalyticsService, $scope, $injector, $sce) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CustomizableBrandingDirectiveController';
        var self = this;
        self.AnalyticsService = AnalyticsService;
        self.avatarElem =  $element.find(".avatar");
        this.$sce = $sce;

        this.companiesManager = CompaniesManager;
        this.companyService = CompanyService;


        if(!this.company){
            this.company = this.companiesManager.getCurrCompany();
            if (!this.company.wasFullyFetched()) {
                this.registerOnce(this.company, 'success', function gotCompany() {

                    // temp
                    this.lightened_color = 'pink';


                }.bind(this));
            }
        }

        if (this.type === "customCss"){

            //not working :(
            //self.createHTMLString();
            //why is type undefined here???
            //console.log(this.myHTML);
        }






    }

    var CustomizableBrandingDirectiveController = Class(Controllers.BaseController, {
        constructor: CustomizableBrandingDirectiveControllerFunc,
        createHTMLString : function createHTMLString(){
            this.myString = this.customCssSelector
                    + "{color: "
                    + this.company.brand_color + ' !important;'
                    + "}";

            this.myHTML = this.$sce.trustAsHtml(this.myString);
        }
    });


    return {
        scope: {
            type: '=?',
            customCssSelector: '=?',
            company: '=?',
            ttype: '=?'
        },
        transclude: true,
        //append the transclude element to our template
        //compile: function (tElement, attrs, transclude) {
        //    return function ($scope) {
        //        transclude($scope, function (clone) {
        //            tElement.append(clone);
        //        });
        //    };
        //},
        templateUrl:
            function whatsTemplateUrl(tElem, tAttri) {
                switch (tAttri.type){

                    case 'link':
                        return 'angular/app/modules/common/ui_components/customizable_branding/customizable_branding_link_template.html';

                    case 'customCss':
                        return  'angular/app/modules/common/ui_components/customizable_branding/customizable_branding_link_customCss_template.html';

                    case 'button':
                    default:
                        return 'angular/app/modules/common/ui_components/customizable_branding/customizable_branding_template.html';

                }
            },
        controller: CustomizableBrandingDirectiveController,
        controllerAs: 'customizableBrandingVm',
        bindToController: true,
        link: function ($scope, $element) {
            //$scope.customizableBrandingVm.setDomElement($element);
        }
    };
};
