Directives.FeedPvlViewerDirective = function FeedPvlViewerDirective() {

    // @ngInject
    function FeedPvlViewerDirectiveControllerFunc($scope, $injector, PvlService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedPvlViewerDirectiveController';

        this.PvlService = PvlService;
    }

    var FeedPvlViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedPvlViewerDirectiveControllerFunc,

        onViewPreferredVendors: function onViewPreferredVendors() {
            this.PvlService.showPreferredVendorList(this.pvlData.pvl_id, this.eventId);
        }
    });

    return {
        scope: {
            pvlData: '=pvlData',
            eventId: '@eventId'
        },
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_pvl_viewer_directive_template.html',
        controller: FeedPvlViewerDirectiveController,
        controllerAs: 'feedPvlViewerVm',
        bindToController: true
    };
};