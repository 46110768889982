(function () {
    "use strict";

    // @ngInject
    function ToolsMobileControllerCtor($injector, $scope, NavigationService, UsersManager, AnalyticsService, DatadogRUMService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ToolsMobileController';
        this.NavigationService = NavigationService;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.DatadogRUMService = DatadogRUMService;

        this.user = this.UsersManager.getCurrUser();

        if (!this.user.isVendor()) {
            this.isShowReactTopNav = false;
        } else {
            this.NavigationService.isShowReactTopNav().then(function (isShowReactTopNav) {
                this.isShowReactTopNav = isShowReactTopNav;
                this.AnalyticsService.track(
                    this,
                    this.AnalyticsService.analytics_events.top_nav_2023,
                    { is_mobile: true, is_new_top_nav: isShowReactTopNav }
                );
            }.bind(this))
            .catch( function () {
                this.isShowReactTopNav = false;
                this.DatadogRUMService.addError(
                    'top_nav_2023: error getting variant', {user_id: this.user._id}
                );
            }.bind(this));
        }
    }

    Controllers.ToolsMobileController = Class(Controllers.BaseController, {
        constructor: ToolsMobileControllerCtor
    });
}());

