
// @ngInject

Models.EventsCollection = function EventsCollection(BaseCollection) {

    var EventsCollection = Class(BaseCollection, {

            constructor: function constructor(){
                EventsCollection.$super.call(this);
                this._collection.__className = 'EventsCollection';
                this._collection.__childsType = 'EventModel';
                return this._collection;
            }
        }
    );

    return EventsCollection;
};
