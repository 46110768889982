(function () {
    "use strict";

    // @ngInject
    function MarkAsPaidControllerCtor($scope, $injector, $modalInstance, $filter, moment, AnalyticsService, payment, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'MarkAsPaidController';
        this.modalInstance = $modalInstance;

        this.payment = payment;
        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;


        this.btnText = "MARK " + $filter('hbcurrency')(payment.amount) + " AS PAID";
        this.markAsPaidSelectedOption = this.payment.markAsPaidTypes.cash.name;
        this.markAsPaidDate = this.moment(this.payment.isMilestonePayment() ? new Date() : this.payment.due_date).format("MMM DD, YYYY");
        this.DATE_FORMAT = 'YYYY-MM-DD';
        this.missingOtherReason = false;

        this.markAsPaidTypes = [
            {name: this.payment.markAsPaidTypes.cash.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._CASH_'},
            {name: this.payment.markAsPaidTypes.check.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._CHECK_'},
            {name: this.payment.markAsPaidTypes.other.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._OTHER_'}
        ];
    }

    Controllers.MarkAsPaidController = Class(Controllers.BaseController, {

        constructor: MarkAsPaidControllerCtor,

        close: function cancel() {
            this.modalInstance.dismiss();
        },

        focusEntryDate: function () {
            angular.element('#markAsPaid_entry_date').focus();
        },

        setFieldSettings: function setFieldSettings(option) {
            this.markAsPaidSelectedOption = option;
            this.missingOtherReason = false;
        },

        isSettingSelected: function isSettingSelected(option) {
            return this.markAsPaidSelectedOption === option;
        },

        setMarkAsPaid: function setMarkAsPaid() {
            //for 'other' method of mark as paid we must have a reason
            if (this.markAsPaidSelectedOption === this.payment.markAsPaidTypes.other.name) {
                if (!this.markAsPaidOtherReason) {
                    this.missingOtherReason = true;
                    return;
                }
            }


            this.markAsPaidInProgress = true;
            var date = this.moment(new Date(this.markAsPaidDate)).format(this.DATE_FORMAT);
            return this.payment.setMarkAsPaid(this.markAsPaidSelectedOption, date, this.markAsPaidOtherReason)
                .then(function success(respData, respStatus) {
                    this.close();

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_marked_as_paid, {
                        file_id: this.fileId,
                        payment_id: this.payment._id,
                        payment_method: this.markAsPaidSelectedOption
                    });
                }.bind(this))
                .catch(function error(respData, respStatus) {
                    this.close();

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_mark_as_paid_error, {
                        file_id: this.fileId,
                        payment_id: this.payment._id,
                        error_message: respData
                    });

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, respData.error_message);
                }.bind(this))
                .finally(function final() {
                    this.markAsPaidInProgress = false;
                }.bind(this));
        }


    });
}());

