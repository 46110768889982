(function () {
    "use strict";

    // @ngInject
    function BlockFullWidthTextControllerFunc($injector, $scope, $sce, AnalyticsService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockFullWidthTextDirectiveController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;

        this._init();
    }

    var BlockFullWidthTextDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockFullWidthTextControllerFunc,

        _init: function _init() {
            var color = this.block.file_block_style ? this.block.file_block_style.background_color : '#ffffff';
            this.setBlockBackground(color);
        },

        updateBlock: function updateBlock(field) {
            this.block.updateBlock([field]);
        },

        updateBlockContent: function updateBlockContent() {
            this.updateBlock('content');
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'content', block_type: "text"});
        },

        updateBlockBackground: function updateBlockBackground() {
            this.updateBlock('file_block_style');
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        focusContent: function focusContent(isFocus) {
            this.isContentFocus = isFocus;
        },

        /* Color picker */
        onBackgroundChange: function onBackgroundChange(color) {
            this.setBlockBackground(color);
            this.updateBlockBackground();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'background color', block_type: "text"});
        },

        setBlockBackground: function setBlockBackground(color) {
            this.styles = {
                'backgroundColor': color
            };
        },

        onPickerShown: function onPickerShown() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.showed_block_color_picker, {block_type: "text"});
        },

    });

    Components.BlockFullWidthText = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        controller: BlockFullWidthTextDirectiveController,
        controllerAs: 'blockFullWidthTextVm',
        name: 'hbBlockFullWidthText',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_full_width_text/block_full_width_text.html',
    };

}());
