Directives.CountryPickerDirective = function CountryPickerDirective() {

    var COUNTRIES = [
        {
            "name": "United States",
            "abbreviation": "US"
        },
        {
            "name": "Canada",
            "abbreviation": "CA"
        }
    ];

    // @ngInject
    var CountryPickerDirectiveControllerFunc = function CountryPickerDirectiveControllerFunc($scope, $timeout, $injector, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CountryPickerDirectiveController';
        this.countries = COUNTRIES;

        this.countries = _.sortBy(this.countries, 'name');

        this.matchSelectedFromAbbr = function matchSelectedFromAbbr(){
            if(this.selectedCountry && this.selectedCountry !== this.emptyCountry.abbreviation){
                for(var i = 0; i < this.countries.length; i++){
                    if(this.selectedCountry.toLowerCase() === this.countries[i].abbreviation.toLowerCase()){
                        this.currentlySelected = this.countries[i].name;
                    }
                }
            }
        };

        var self = this;

        this.countrySelected = function countrySelected(country){
            this.currentlySelected = country.name;
            this.selectedCountry = country.abbreviation;
            if(this.onChange){
                $timeout(function change(){
                    self.onChange();
                });

            }
        };

        $scope.$watch('countrypickerVm.selectedCountry', function(newVal, oldVal){
            if(oldVal !== newVal){
                self.matchSelectedFromAbbr();
            }
        });

        this.emptyCountry = {
            name: "Select Country",
            abbreviation: ""
        };
        this._allowNoSelection = (this.allowNoSelection === "true");

        this.currentlySelected = this.emptyCountry.name;
        this.matchSelectedFromAbbr();
    };

    var CountryPickerDirectiveController = Class(Controllers.BaseController, {
        constructor: CountryPickerDirectiveControllerFunc
    });
    return {
        scope: {
            allowNoSelection: '@allowNoSelection',
            selectedCountry: '=selectedCountry',
            onChange: '&?countrypickerOnChange',
            disablePicker: '=disablePicker',
            native: '=?native'
        },
        templateUrl: 'angular/app/modules/common/ui_components/country_picker/country_picker_directive_template.html',
        controllerAs: 'countrypickerVm',
        controller: CountryPickerDirectiveController,
        bindToController: true
    };
};