(function () {
    'use strict';

    // @ngInject
    Services.acrossTabs = function acrossTabs($window) {
        return {
            Parent: $window.AcrossTabs.default.Parent,
            parseMessage: function parseMessage(message) {
                try {
                    return JSON.parse(message);
                }
                catch (e) {
                    return null;
                }
            }
        };
    };

}());
