(function () {
    'use strict';
    Controllers.mWebConnectProjectModalController = class mWebConnectProjectModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, SearchablesManager, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'mWebConnectProjectModalController';

            this.modalInstance = $modalInstance;

            this.AnalyticsService = AnalyticsService;

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.mweb_connect_project_to_meeting_modal);

            // project search
            this.SearchablesManager = SearchablesManager;
            this.workspacesSearchText = this.eventName;
            this.searchDataBeingFetched = false;
            this.workspacesSearchResults = [];

            this.designApply = this.design || false;

            this.selectedWorkspaceBind = this;
        }

        handleSearchFocused() {
            //https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
            if (/Android 4\.[0-3]/.test(navigator.appVersion)) {
                window.addEventListener("resize", function(){
                    if(document.activeElement.tagName=="INPUT"){
                        window.setTimeout(function(){
                            document.activeElement.scrollIntoViewIfNeeded();
                        },0);
                    }
                });
            }
        }

        workspaceSearchInputChanged(value) {
            this.workspacesSearchText = value.searchTerm;
            if (this.workspacesSearchText && this.workspacesSearchText.length > 0) {
                this.searchDataBeingFetched = true;
                this.workspacesSearchResults = this.SearchablesManager.search(this.workspacesSearchText, 'couple_cards', {hideTeamMembers: true});
                this.register(this.workspacesSearchResults, 'success', function onSearchDataFetched(callbackReason) {
                    this.searchDataBeingFetched = false;
                    if(!this.workspacesSearchResults || this.workspacesSearchResults.length === 0) {
                        this.workspacesSearchResults =  [{
                            properties: {
                                event_name: 'No projects match this search',
                                is_no_results: true
                            }
                        }];
                    }
                }.bind(this));
            }
        }

        handleWorkspaceItemSelected(selected) {
            if(!selected.properties.is_no_results) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.select_project);
                this.modalInstance.close({selectedWorkspace: selected});
            }
        }

        handleCreateProject() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.create_new_project);
            this.modalInstance.close({shouldCreateNewProject: true});
        }

        close() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.close_mweb_connect_project_modal);
            this.modalInstance.close({selectedWorkspace: {
                    properties: {
                        event_name: 'No project'
                    }
                }});
        }
    };
}());