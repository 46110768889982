(function () {
    "use strict";

    class RescheduleEditContractComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, RescheduleProjectService, $q, WorkspaceFilesManager, UsersManager, WorkspaceFileService) {
            super($scope, $injector);
            this.__objectType = 'RescheduleWorkflowComponent';
            this.AnalyticsService = AnalyticsService;
            this.RescheduleProjectService = RescheduleProjectService;
            this.$q = $q;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.UsersManager = UsersManager;
            this.WorkspaceFileService = WorkspaceFileService;

            this.user = UsersManager.getCurrUser();
        }

        $onInit() {
            this.loading = true;

            const contractChoice = this.RescheduleProjectService.getEditContractChoice();
            let contractPromise;
            let templateIdPromise = this.$q.when(null);
            let fileTitle = null;

            if (contractChoice === this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.existing) {
                contractPromise = this.getExistingContractPromise();
            } else if (this.RescheduleProjectService.hasCreatedContract()) {
                contractPromise = this.$q.when(this.RescheduleProjectService.getCreatedContract());
            } else if (contractChoice === this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.amendment) {
                templateIdPromise = this.UsersManager.getAmendmentContractTemplate().then(t => t._id);
                fileTitle = `${this.RescheduleProjectService.getProject().event_name} Amendment`;
            }

            // create the file if needed
            if (!contractPromise) {
                contractPromise = this.getCreateContractPromise(templateIdPromise, fileTitle);
            }

            contractPromise.then((file) => {
                this.file = file;
                // this is a stupid necessary hack to prevent the file from being saved on directive destroy
                this.file.agreement.isBeingDeleted = true;
            }).finally(() => {
                this.loading = false;
            });
        }

        complete() {
            this.WorkspaceFileService.validateFileModel().then(() => {
                this.onComplete && this.onComplete();
            });
        }

        dismiss() {
            this.onDismiss && this.onDismiss();
        }

        getExistingContractPromise() {
            const chosenContract = this.RescheduleProjectService.getExistingContractChoice();
            let makeFileEditablePromise = this.$q.when(chosenContract);

            if (!this.RescheduleProjectService.wasDraftCreatedForFile(chosenContract)) {
                makeFileEditablePromise = this.RescheduleProjectService.makeFileEditable(chosenContract);
            }

            return makeFileEditablePromise.then(() => {
                return chosenContract.wasFullyFetched() ? chosenContract : this.WorkspaceFilesManager.getWorkspaceFile(chosenContract._id, true);
            });
        }

        getCreateContractPromise(templateIdPromise, fileTitle) {
            return templateIdPromise.then((templateId) => {
                return this.WorkspaceFilesManager.createAgreement(this.workspace, null, templateId, {file_title: fileTitle});
            }).then((resp) => {
                return this.WorkspaceFilesManager.getWorkspaceFile(resp.data._id, true);
            }).then((file) => {
                this.RescheduleProjectService.setCreatedContract(file);
                return file;
            });
        }
    }

    Components.RescheduleEditContractComponent = {
        bindings: {
            onComplete: '&',
            onDismiss: '&',
            nextStepTitle: '<',
            workspace: '<',
        },
        controller: RescheduleEditContractComponent,
        name: 'hbRescheduleEditContract',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_edit_contract_template.html',
    };

}());
