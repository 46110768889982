/**
 * Created by inonstelman on 12/29/14.
 * @class BaseModelManager
 */

Services.BaseModelManager = Class(function () {

        var DEFAULT_MODEL_TIMEOUT = 60 * 1000; // 1 minute
        var modelTimestamps = {};

        return {

            $statics: {
                ACTION_FETCH: 'fetch',
                ACTION_CREATE: 'create',
                ACTION_UPDATE: 'update',
                ACTION_DELETE: 'destroy'
            },

            constructor: function constructor(APIService, ModelFactory, $q) {
                this.APIService = APIService;
                this.ModelFactory = ModelFactory;
                this.$q = $q;
                this.updatesQueue = [];
            },

            onSuccess: function onSuccess(callback) {
                this.APIService.on('success', callback, this);
            },

            offSuccess: function offSuccess(callback) {
                this.APIService.off('success', callback, this);
            },

            onAnyError: function onAnyError(callback) {
                this.APIService.on('error*', callback, this);
            },

            offAnyError: function offAnyError(callback) {
                this.APIService.off('error*', callback, this);
            },

            onError: function onError(event, callback) {
                this.APIService.on(event, callback, this);
            },

            offError: function onError(event, callback) {
                this.APIService.off(event, callback, this);
            },

            apiFetch: function apiFetch(url, data, model, updateModelData, headers) {
                return this._apiCall(this.ACTION_FETCH, url, data, headers, model, updateModelData);
            },

            apiCreate: function apiCreate(url, data, model, updateModelData, headers, multiplePendingRequestsBehavior) {
                if (!multiplePendingRequestsBehavior) {
                    return this._apiCall(this.ACTION_CREATE, url, data, headers, model, updateModelData);
                } else {
                    return this._apiCallUpdate(this.ACTION_CREATE, url, data, headers, model, updateModelData);

                }
            },

            apiUpdate: function apiUpdate(url, data, model, updateModelData, headers, multiplePendingRequestsBehavior) {
                if (!multiplePendingRequestsBehavior){
                    return this._apiCall(this.ACTION_UPDATE, url, data, headers, model, updateModelData);
                }else{
                    return this._apiCallUpdate(this.ACTION_UPDATE, url, data, headers, model, updateModelData);

                }
            },

            apiDelete: function apiDelete(url, data, model, updateModelData, headers, multiplePendingRequestsBehavior) {
                if (!multiplePendingRequestsBehavior){
                    return this._apiCall(this.ACTION_DELETE, url, data, headers, model, updateModelData);
                } else {
                    return this._apiCallUpdate(this.ACTION_DELETE, url, data, headers, model, updateModelData);
                }
            },

            apiFetchCollection: function apiFetchCollection(url, data, collection, updateModelData, headers, clientSidePaging, saveToCache, serverSidePaging) {
                clientSidePaging = clientSidePaging || false;
                serverSidePaging = serverSidePaging || false;
                if (saveToCache !== false) {
                    saveToCache = true;
                }
                if (clientSidePaging) {
                    data.perPage = data.perPage || 20;
                    data.page = data.page || 1;
                }
                return this._collectionApiCall(this.ACTION_FETCH, url, data, headers, collection, updateModelData, clientSidePaging, saveToCache, serverSidePaging);
            },

            /**
             * This method assists in understanding whether a specific model should be fetched from the server again or is it good enough to get it from the cache.
             * <b>Notice:</b> This method doesn't replace any caching mechanism we have in the client code. It is just marking if the model need to be updated from the server.
             *
             * @param {BaseModel}model The model to check for timeout
             * @param {Boolean}[force] Flag for forcing a reset of the timeout.
             *
             * @returns {boolean} Is the model timed out or not
             */
            isModelTimedOut: function isModelTimedOut(model, force) {
                var now = (new Date()).getTime();
                if (!modelTimestamps[model._id] || force) {
                    modelTimestamps[model._id] = now;
                    return true;
                }

                var modelTimedOut = (now - modelTimestamps[model._id]) > model.getModelTimeout();
                if (modelTimedOut) {
                    modelTimestamps[model._id] = now;
                }

                return modelTimedOut;
            },

            _apiCall: function _apiCall(actionName, url, data, headers, model, updateModelData) {

                var self = this;

                var deferred = self.$q.defer();

                //set model state to updating
                if (model && angular.isFunction(model.onModelUpdating)) {
                    model.onModelUpdating();
                }

                this.APIService[actionName](url, data, headers).then(
                    function success(resp) {

                        deferred.resolve({data: resp.data, status: resp.status, headers: resp.headers, model: model});

                        if (updateModelData) {
                            self._receivedModelDataFromServer(model, resp.data, resp.status);
                        }
                        else if (model) {
                            model.onModelSuccess(resp.data, resp.status);
                        }
                    },

                    function error(resp) {

                        deferred.reject({data: resp.data, status: resp.status, statusText: resp.statusText, model: model});

                        if (model) {
                            model.onModelError(resp.data, resp.status);
                        }
                    }
                );

                return deferred.promise;
            },

            _apiCallUpdate: function _apiCallUpdate(actionName, url, data, headers, model, updateModelData) {

                var self = this;
                var deferred = self.$q.defer();

                // creating or getting the relevant queue
                var queueKey = model ? model.id() : actionName + url;

                var currQueue = self.updatesQueue[queueKey];
                if (!currQueue) {
                    self.updatesQueue[queueKey] = [];
                    currQueue = self.updatesQueue[queueKey];
                }

                // inserting call to relevant queue
                currQueue.push({
                    actionName: actionName,
                    url: url,
                    data: data,
                    headers: headers,
                    model: model,
                    hostingQueue: currQueue,
                    queueKey: queueKey,
                    updateModelData: updateModelData,
                    deferred: deferred
                });

                if (currQueue.length === 1) {
                    if (model) {
                        model.onModelUpdating();
                        currQueue[0].responsesFromServer = [];
                    }

                    self.APIService[actionName](url, data, headers).then(
                        success.bind(currQueue), error.bind(currQueue)
                    );
                }

                function processQueue(resp, isSuccess, relevantQueue) {
                    var currentUpdate = relevantQueue.shift();

                    var dataForPromise = {
                        data: resp.data,
                        status: resp.status,
                        headers: resp.headers,
                        model: model
                    };

                    var responsesFromServer = currentUpdate.responsesFromServer;

                    if (isSuccess) {
                        if (currentUpdate.updateModelData) {
                            responsesFromServer.push({data: resp.data, status: resp.status});
                        }

                        currentUpdate.deferred.resolve(dataForPromise);
                    } else {
                        currentUpdate.deferred.reject(dataForPromise);
                    }

                    if (relevantQueue.length === 0) {

                        delete self.updatesQueue[currentUpdate.queueKey];

                        if (isSuccess && updateModelData) {

                            responsesFromServer.forEach(function (ret) {
                                model.mixinFrom(ret.data);
                            });

                            model.onModelSuccess(resp.data, resp.status);

                        }
                        else if (isSuccess && model) {
                            model.onModelSuccess(resp.data, resp.status);
                        } else if (model) {
                            responsesFromServer.forEach(function (ret) {
                                model.mixinFrom(ret.data);
                            });

                            // sending one success for the model to
                            // be updated before sendind the error
                            var len = responsesFromServer.length;
                            if (len > 0) {
                                var ret = responsesFromServer[len - 1];
                                model.onModelSuccess(ret.data, ret.status);
                            }

                            model.onModelError(resp.data, resp.status);
                        }
                    } else {
                        var nextUpdate = relevantQueue[0];
                        nextUpdate.responsesFromServer = responsesFromServer;
                        var nextUpdateParams = [nextUpdate.url, nextUpdate.data, nextUpdate.headers];
                        // send the next request in line
                        self.APIService[nextUpdate.actionName].apply(self.APIService, nextUpdateParams).then(success.bind(relevantQueue), error.bind(relevantQueue));
                    }
                }

                function success(resp) {
                    var relevantQueue = this;
                    processQueue(resp, true, relevantQueue);
                }

                function error(resp) {
                    var relevantQueue = this;
                    processQueue(resp, false, relevantQueue);
                }

                return deferred.promise;
            },

            _receivedModelDataFromServer: function _receivedModelDataFromServer(model, dataFromServer, responseStatus) {
                var cachedModel = model.mixinFrom(dataFromServer, undefined, true, true);

                if (cachedModel !== model) {
                    // window.console.log('SHOULD NOT HAPPEN!!!!');
                    // window.console.log('Why this SHOULD NOT HAPPEN??? the cachedModel is an extended object of data from cache + model, how can they be equal?');
                    // window.console.log('Because either the model is new and was just inserted into the cache OR it was in the cache before and should not have been created separately');
                }

                model.onModelSuccess("data fetched", responseStatus, dataFromServer);

            },


            _collectionApiCall: function _collectionApiCall(actionName, url, data, headers, collection, updateCollectionData, clientSidePaging, saveToCache, serverSidePaging) {

                var self = this;

                var deferred = this.$q.defer();

                if (collection) {
                    collection.onCollectionUpdating();
                }

                this.APIService[actionName](url, data, headers).then(
                    function success(resp) {

                        var isComplete, collectionTotals, isFirstPage = (resp.data.cur_page === 1);
                        if (serverSidePaging){
                            isComplete = resp.data.last_page;
                            collectionTotals = resp.data.total;
                        }else if (clientSidePaging){
                            isComplete = (resp.data.last_page || (resp.data.cur_page != null && resp.data.total_pages != null && resp.data.cur_page === resp.data.total_pages));
                        } else {
                           isComplete = true;
                        }

                        deferred.resolve({
                            data: resp.data,
                            status: resp.status,
                            headers: resp.headers,
                            collection: collection,
                            isFullyFetched: isComplete,
                            totalCollectionItems: collectionTotals
                        });

                        if (updateCollectionData) {
                            self._receivedCollectionDataFromServer(collection, resp.data, resp.status, clientSidePaging, isFirstPage, isComplete, saveToCache, serverSidePaging, collectionTotals);
                        }

                        if (clientSidePaging) {
                            var getNextPage;
                            if (resp.data.total_pages) {
                                getNextPage = resp.data.cur_page < resp.data.total_pages;
                            }else {
                                getNextPage = !!!resp.data.last_page;
                            }
                            if (getNextPage) {
                                data.page = (data.page || 0) + 1; // doing this anyway, also as a fallback to last id (if there is)
                                if (resp.data.last_id){
                                    data.start_after_id = resp.data.last_id;
                                    delete data.last_id;
                                }
                                self._collectionApiCall(actionName, url, data, headers, collection, updateCollectionData, clientSidePaging, saveToCache, serverSidePaging);
                            }
                        }

                    },

                    function error(resp) {

                        var shouldStopEvents = false;

                        if (!shouldStopEvents) {
                            deferred.reject({
                                data: resp.data,
                                status: resp.status,
                                statusText: resp.statusText,
                                colection: collection
                            });

                            if (collection) {
                                collection.onCollectionError(resp.data, resp.status);
                            }
                        }
                    }
                );

                return deferred.promise;
            },
            _receivedCollectionDataFromServer: function _receivedCollectionDataFromServer(collection, dataFromServer, responseStatus, clientSidePaging, isFirstPage, isComplete, saveToCache, serverSidePaging, collectionTotals) {
                var collectionData = dataFromServer.data || dataFromServer;

                //if we are clientSidePaging and not in the first page, we want to ADD the new records, not replace
                // also, if explicitly specified
                var keepExistingRecords = (serverSidePaging || clientSidePaging) && !isFirstPage;
                collection.mixinFrom(collectionData, undefined, saveToCache, true, keepExistingRecords, isComplete);
                collection.onCollectionSuccess("data fetched", responseStatus, isComplete, collectionTotals);

                if (clientSidePaging && isComplete) {
                    collection.onPagingEnded();
                }
            }
        };
    }
);