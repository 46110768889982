
(function () {
    'use strict';

    Directives.AsideDrawerDirective = function AsideDrawerDirective() {

        // @ngInject
        function AsideDrawerDirectiveControllerFunc($scope, $injector, $timeout, AnalyticsService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'AsideDrawerDirectiveController';
            this.AnalyticsService = AnalyticsService;
            this.$timeout = $timeout;

            this.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS');

            $scope.$on('$destroy', function () {
                this.isToggleBurgerActive = false;
            }.bind(this));

            $timeout(function() {
                if(this.isInitiallyOpened) {
                    //this.expand();
                    this.appState.asideDrawerOpen = true;
                    this.isToggleBurgerActive = true;
                }
            }.bind(this), 100);
        }

        var AsideDrawerController = Class(Controllers.BaseController, {
            constructor: AsideDrawerDirectiveControllerFunc,

            toggleBurger: function toggleBurger() {
                this.AnalyticsService.trackClick(this, (!this.appState.asideDrawerOpen ? 'expand' : 'collapse') + ' aside drawer');
                !this.appState.asideDrawerOpen ? this.expand() : this.collapse();
            },

            safariToggle: function safariToggle(open) {
                // safari has an issue with updating fixed left auto
                // this function checks for safari browser and if true
                // manually updates the position then sets it back to auto
                if (this.isSafari) {
                    var filmStrip = angular.element('.file-steps')[0];
                    if (filmStrip) {
                        var leftInitial = filmStrip.offsetLeft;
                        var leftUpdate = open ? 224 : -224;

                        this.$timeout(function() {
                            filmStrip.style.left = leftInitial;
                            filmStrip.style.transition = "left 0.4s ease";

                            this.$timeout(function() {
                                filmStrip.style.left = (leftInitial + leftUpdate) + 'px';

                                this.$timeout(function() {
                                    filmStrip.style.left = 'auto';
                                }.bind(this), 400);
                            }.bind(this), 0);
                        }.bind(this), 0);
                    }
                }
            },

            expand: function expand() {
                if(!this.appState.asideDrawerOpen) {
                    this.appState.asideDrawerOpen = true;
                    this.isToggleBurgerActive = true;
                    if (this.isSafari) {
                        this.safariToggle(true);
                    }
                    this.triggerStateChange(this.appState.asideDrawerOpen);
                }
            },

            collapse: function collapse() {
                if(this.appState.asideDrawerOpen) {
                    this.appState.asideDrawerOpen = false;
                    this.isToggleBurgerActive = false;
                    if (this.isSafari) {
                        this.safariToggle(false);
                    }
                    this.triggerStateChange(this.appState.asideDrawerOpen);
                }
            },

            togglePin: function togglePin() {
                this.isPinned = !this.isPinned;
                if(!this.isPinned) {
                    this.collapse();
                }
            },

            clickOutside: function clickOutside() {

                if(!this.isPinned) {
                    this.collapse();
                }
            },

            triggerStateChange: function triggerStateChange(isOpen) {
                if(this.onStateChange) {
                    this.onStateChange({isOpen: isOpen});
                }
            }
        });

        return {
            scope: {
                title: '@title',
                isInitiallyOpened: '<',
                showNotification: '=?',
                onStateChange: '&'
            },
            transclude: {
                'content': '?asideContent',
                'notification': '?asideNotification'
            },
            templateUrl: 'angular/app/modules/common/ui_components/aside_drawer/aside_drawer.html',
            controller: AsideDrawerController,
            controllerAs: 'asideDrawerVm',
            bindToController: true
        };
    };

})();
