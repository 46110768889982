(function () {
    "use strict";

    // @ngInject
    function EditContactControllerCtor($scope, $modalInstance, $injector, $q, $translate, ContactsManager, UsersManager, UserService, _, CompanyService, ModelFactory, userToEdit, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager, FeaturesService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager);
        this.__objectType = 'EditContactController';

        this.ContactsManager = ContactsManager;
        this.$q = $q;

        this.titleText = $translate.instant("DASHBOARD.CONTACTS.EDIT_CONTACT._HEADER_TITLE_");
        this.subTitleText = "";
        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._SAVE_");

        this.OrigUser = userToEdit;

        this.user = userToEdit.getCopy();
        this.isVendor = userToEdit.isVendor();
        this.FeaturesService = FeaturesService;

        this._setupClientVendorSeparationVars(FeaturesService, UsersManager);

        this.isClient = userToEdit.isClient();
        this.businessDataOpen = this.isVendor;
        this.isVendorEditDisabled = false;
        this.shouldShowUserType = false;

        this.isDisplayAvatar = this.isVendor; // doen't show this for clients, they probably don't have an avatar.
        this.isSuggestFromGmailContacts = false;
        this.isSuggestFromDirectory = false;
    }

    Controllers.EditContactController = Class(Controllers.NewAddUserController, {

        constructor: EditContactControllerCtor,

        saveUser: function saveUser() {
            var defer = this.$q.defer();
            var user = this.user;

            var contact = this.ContactsManager.convertUserToContact(user);
            if (!user.isVendor) {
                contact.user.company = null;
            }

            this.ContactsManager.saveContact(contact)
                .then(function saveContactSuccess(resp) {
                    //update the original user model:
                    this.OrigUser.mixinFrom(resp.data.user);
                    defer.resolve(resp.data);
                }.bind(this))
                .catch(function saveContactError(resp) {
                    defer.reject(resp);
                }.bind(this));
            return defer.promise;
        },

        handleUserFromDb: function handleUserFromDb(dbUser) {
            if (dbUser._id && dbUser._id !== this.user._id) {
                //show error.
                this.addUserForm.customEmailErrorMessage = "This email address is already in use";
                this.addUserForm.email.$setValidity("customError", false);
            }
        },

        shouldShowWillInviteFooter: function () {
            return false;
        }
    });
}());

