(function () {
    'use strict';

    class TemplateFileCardPopover extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $translate,
            TemplatesViewService,
            AnalyticsService
        )  {
            super($scope, $injector);
            this.__objectType = 'TemplateFileCardPopover';
            this.$translate = $translate;
            this.TemplatesViewService = TemplatesViewService;
            this.AnalyticsService = AnalyticsService;
        }

        $onInit() {
            this.linkTextDefault = this.$translate.instant('TEMPLATES_PAGE.ACTIONS._DEFAULT_');
            this.linkTextDelete = this.$translate.instant('TEMPLATES_PAGE.ACTIONS._DELETE_');
            this.linkTextPublish = this.$translate.instant('TEMPLATES_PAGE.ACTIONS._PUBLISH_');
            this.linkTextDuplicate = this.$translate.instant('TEMPLATES_PAGE.ACTIONS._DUPLICATE_');
        }

        isDefaultable() {
            return this.TemplatesViewService.isTemplateTypeDefaultable(this.template.viewModel);
        }

        isCloneable() {
            return this.TemplatesViewService.isTemplateTypeCloneable(this.template.viewModel, this.template);
        }

        isDeletable() {
            return this.TemplatesViewService.isTemplateTypeDeletable(this.template.viewModel, this.template);
        }

        isPublishable() {
            return this.TemplatesViewService.isTemplatePublishable();
        }

        onCloneClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.isVisible = false;
            if (this.onClone && angular.isFunction(this.onClone)) {
                this.onClone({ template: this.template });
            }
        }

        onDeleteClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.isVisible = false;
            if (this.onDelete && angular.isFunction(this.onDelete)) {
                this.onDelete({ template: this.template});
            }
        }

        onDefaultClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            if (this.onDefault && angular.isFunction(this.onDefault)) {
                this.onDefault({ template: this.template });
            }
        }

        onPublishClick($event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (this.onPublish && angular.isFunction(this.onPublish)) {
                this.onPublish({ template: this.template});
            }
        }
    }

    Components.TemplateFileCardPopover = {
        bindings: {
            isVisible: '=',
            template: '<',
            onDelete: '&',
            onClone: '&',
            onDefault: '&',
            onPublish: '&',
        },
        controller: TemplateFileCardPopover,
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/template_file_card/template_file_card_popover.html',
    };

}());
