(function () {
    'use strict';

    // @ngInject
    function FinanceAppManagerServiceCtor(APIService, ModelFactory, $q, Routes, ForterHelperService, Fingerprint) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.ForterHelperService = ForterHelperService;
        this.Fingerprint = Fingerprint;
    }

    Services.FinanceAppManager = Class(Services.BaseModelManager, {
        constructor: FinanceAppManagerServiceCtor,

        createLoginToken() {
            return this.Fingerprint()
                .then(fingerprint => {
                    var data = {
                        f_token: this.ForterHelperService.getForterToken(),
                        fingerprint: fingerprint
                    };
                    const url = this.Routes.v2_finance_app_token_path();
                    return this.apiCreate(url, data);
                });
        }
    });
}());
