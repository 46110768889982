Directives.TemplatesDirective = function TemplatesDirective() {
    // @ngInject
    function TemplatesDirectiveControllerFunc(AnalyticsService,PopupMessageService, $translate, $scope, $injector, $) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplatesDirectiveController';

        this.PopupMessageService = PopupMessageService;
        this.currSelectedTemplate = this.selectedTemplate;

        this.loadingTemplate = false;
        this.$ = $;
        this.containerId = "js-" + this.templateType + "Templates";
        var self = this;

        if(this.currSelectedTemplate && this.templates && this.templates.length){
            //need template title.
            for (var i = 0, len = this.templates.length; i < len; i++) {
                var curr = this.templates[i];
                if(curr._id===this.currSelectedTemplate){
                    if(curr.title){
                        this.templateTitle = curr.title;
                    }
                    else{
                        //because everything uses .title, and proposal uses .name
                        this.templateTitle = curr.name;
                    }
                }
            }
        }

        this.selectTemplate = function selectTemplate(template, $event) {

            if (!this.templateMode) {
                if ((this.workspaceFile.isQuestionnaire() && this.workspaceFile.isComplete()) ||
                    ((this.workspaceFile.isBrochure() || this.workspaceFile.isTimeline()) && this.workspaceFile.isSent())) {

                    var msg = this.workspaceFile.isQuestionnaire() ? '_CANNOT_SELECT_TEMPLATE_SUBMITTED_' : '_CANNOT_SELECT_TEMPLATE_SENT_';
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'FILE.TEMPLATES.' + msg);
                    return;
                }

                if(this.workspaceFile.isCanceled()) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'FILE.TEMPLATES._CANNOT_SELECT_TEMPLATE_EXPIRED_');
                    return;
                }
            }

            var templateName = template.title;
            var tempId = template._id;
            if(self.loadingTemplate){
                if($event){
                    $event.stopPropagation();
                }
                return;
            }

            self.loadingTemplate = true;


            self.selectConfirm = function selectConfirm(){

                this.selectAction({file: self.workspaceFile, templateId: tempId}).then(
                    function selectSuccess() {
                        self.currSelectedTemplate = template._id;
                        self.loadingTemplate = false;
                        //self.showTemplates = false;

                        //populate the save as with the name of this template.
                        self.templateTitle = templateName;


                        var analyticsArgs = {
                            template_id: self.currSelectedTemplate,
                            template_type: self.templateType
                        };

                        AnalyticsService.track(self, AnalyticsService.analytics_events.template_selected_confirmed, analyticsArgs);
                    },
                    function error(resp) {

                        self.loadingTemplate = false;
                        PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                            'FILE.TEMPLATES._ERROR_SELECT_OCCUR_MESSAGE_');

                        var analyticsArgs = {
                            template_id: self.currSelectedTemplate,
                            template_type: self.templateType
                        };

                        AnalyticsService.track (self, AnalyticsService.analytics_events.template_selected_failed, analyticsArgs);
                    }
                );

            };


            //Confirm we want to load the template and override any changes

            self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.info,
                "Are you sure you want to load this template? Any changes made will be lost. ",
                self.selectConfirm.bind(self),
                function(){
                    self.loadingTemplate=false;
                    var analyticsArgs = {
                        template_id: self.currSelectedTemplate,
                        template_type: self.templateType
                    };
                    AnalyticsService.track (self, "cancel select template", analyticsArgs);
                }.bind(self));

        };

        this.saveAsTemplate = function saveAsTemplate(title, $event){
            var self = this;
            $event.stopPropagation();
            if(self.loadingTemplate){
                return;
            }

            self.loadingTemplate = true;
            self.saveTemplateAsAction({title: self.templateTitle}).then(
                function saveAsSuccess(){
                    self.loadingTemplate = false;
                    //TODO Template needs to be selected here. We need the ID of the new template.
                    // or compare self.templateTitle to every templates ID
                    self.updateActiveTemplate();

                    // Click the container template to hide it
                    self.$("#" + self.containerId).click();
                },

                function error(resp){
                    self.loadingTemplate = false;
                    console.log('error');
                }
            );
        },

        this.updateActiveTemplate = function updateActiveTemplate(){
            //iterate through each template and if the name matches what's in the input field,
            // set the currSelectedTemplate

            if(!this.templateTitle){
                return;
            }

            for (var i = 0, len = this.templates.length; i < len; i++) {
                var curr = this.templates[i];
                if((curr.title === this.templateTitle) || (curr.name === this.templateTitle)){
                    this.currSelectedTemplate = curr._id;
                }
            }

        }

        this.deleteTemplate = function deleteTemplate(template){
            var templateId = template._id;

            this.deleteConfirmed = function deleteConfirmed(){
                this.loadingTemplate = true;
                this.deleteAction({company: this.company,templateId: this.templateToDelete})
                .then(
                    function success(){

                        // Delete origin_copy_id to hive the Save button
                        var type_to_model_map = {
                            'proposal': 'vendor_proposal',
                            'payments-schedule': 'payments_container',
                            'brochure': 'brochure_container',
                            'timeline': 'timeline_container'
                        };

                        // What's not in the map -> same name in the model (for now: agreement, questionnaire)
                        var model_field = type_to_model_map[this.templateType] || this.templateType;

                        if (!this.templateMode) {
                            if (this.workspaceFile[model_field].origin_copy_id === this.templateToDelete) {
                                delete this.workspaceFile[model_field].origin_copy_id;
                            }
                        }

                        var analyticsArgs = {
                            template_id: this.templateToDelete,
                            template_type: this.templateType
                        };
                        AnalyticsService.track (this, AnalyticsService.analytics_events.delete_template_permanently_confirmed, analyticsArgs);
                        this.loadingTemplate = false;

                    }.bind(this))
                .catch(
                    function error(resp) {
                        var errorMsg = 'FILE.TEMPLATES._ERROR_OCCUR_MESSAGE_';
                        switch (resp.data.error_type) {
                            case 'CannotDeleteLastPackage':
                                errorMsg = 'FILE.TEMPLATES.PACKAGES._ERROR_DELETE_LAST_MESSAGE_';
                                break;
                            case 'CannotDeleteLastAgreement':
                                errorMsg = 'FILE.TEMPLATES.AGREEMENT._ERROR_DELETE_LAST_MESSAGE_';
                                break;
                            case 'CannotDeleteDefaultEmail':
                                errorMsg = 'FILE.TEMPLATES.EMAIL._ERROR_DELETE_DEFAULT_EMAIL_MESSAGE_';
                                break;
                            default:
                                if (resp.data.error_message) {
                                    errorMsg = resp.data.error_message;
                                }
                        }

                        PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, errorMsg);

                        var analyticsArgs = {
                            template_id: this.templateToDelete,
                            template_type: this.templateType
                        };
                        AnalyticsService.track (this, AnalyticsService.analytics_events.delete_template_permanently_failed, analyticsArgs);
                    this.loadingTemplate = false;
                    }.bind(this));
            };

            this.templateToDelete = templateId;

            // delete last is not possible on payments and agreement templates
            if (this.isLastDeletionAllowed === "false" && this.templates.length===1) {

                $translate('FILE.TEMPLATES._ERROR_DELETE_LAST_MESSAGE_',{ type: this.templateType }).then(
                    function translateResolved(text) {
                        PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning,
                            text);

                    }.bind(this));
            } else {
                $translate('FILE.TEMPLATES._DELETE_CONFIRM_MESSAGE_',{ type: this.templateType }).then(
                    function translateResolved(text) {
                        this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                            text,
                            this.deleteConfirmed.bind(this),
                            function(){
                                var analyticsArgs = {
                                    template_id: this.templateToDelete,
                                    template_type: this.templateType
                                };
                                AnalyticsService.track (this, "cancel delete template", analyticsArgs);
                            }.bind(this));
                    }.bind(this));
            }

        };


    }


    var TemplatesDirectiveController = Class(Controllers.BaseController, {
        constructor: TemplatesDirectiveControllerFunc
    });

    return {
        scope: {
            workspaceFile: '=workspaceFile',
            company: '=company',
            templates: '=templates',
            selectAlert: '@selectAlert',
            deleteAction: '&deleteAction',
            selectAction: '&selectAction',
            saveTemplateAsAction: '&saveTemplateAsAction',
            selectedTemplate: '=selectedTemplate',
            isLastDeletionAllowed: '@isLastDeletionAllowed',
            templateType: '@templateType',
            templateMode: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/templates/templates_directive_template.html',
        controller: TemplatesDirectiveController,
        controllerAs: 'templatesVm',
        bindToController: true
    };
};