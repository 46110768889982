(function () {
    'use strict';

    const DURATION_PLURALIZATION_MAP = {
        minute: 'minutes',
        hour: 'hours',
        day: 'days',
        week: 'weeks',
        month: 'months'
    };

    const DURATION_SINGULARIZATION_MAP = {
        minutes: 'minute',
        hours: 'hour',
        days: 'day',
        weeks: 'week',
        months: 'month'
    };

    Services.SchedulingService = class SchedulingService {

        // @ngInject
        constructor(UIUtils, _, ModelFactory, moment, FeaturesService, DateService, EnvService, $filter, Enums, $translate, TimezoneService, CompaniesManager) {

            this.UIUtils = UIUtils;
            this.ModelFactory = ModelFactory;
            this.moment = moment;
            this.FeaturesService = FeaturesService;
            this.DateService = DateService;
            this.EnvService = EnvService;
            this.$filter = $filter;
            this.Enums = Enums;
            this.$translate = $translate;
            this.TimezoneService = TimezoneService;
            this.company = CompaniesManager.getCurrCompany();

            // Generate colors
            this._generateColors();
        }

        isSchedulingEnabled(user) {
            return user.isVendor();
        }

        newSession(user) {

            // create new model
            let newSession = this.ModelFactory.newModel('SessionModel');

            // generate color
            let newColor = this._colors[0];

            // set defaults
            var newSessionDefaults = {
                _id: '-1',
                session_name: '',
                session_color: newColor,
                enabled: true,
                session_description: '',
                session_timezone: this.company.default_timezone,
                session_type: '',
                meeting_location: '',
                phone_call_caller: 'me',
                phone_call_number: '',
                video_meeting_link: '',
                session_duration: {
                    unit_type: 'minutes',
                    unit_count: '30'
                },
                buffer_before: null,
                buffer_after: null,
                notice_period: null,
                window_type: 'indefinitely',
                fixed_start_date: '',
                fixed_end_date: '',
                confirmation_message: 'Thank you for scheduling a session with me!\n\nPlease view the details of this session below:',
                allow_rescheduling: true,
                allow_canceling: true,
                send_confirmation_email: true,
                schedule_behaviour_type: '',
                day_availabilities: [],
                shared_member_ids: [],
                session_reminders: [{
                    type: 'email',
                    when: { unit_count: 1, unit_type: 'hours'},
                }, {
                    type: 'email',
                    when: { unit_count: 1, unit_type: 'days'},
                }]
            };

            // generate default availabilities
            for(let i = 1; i < 6; i++) {

                let day = this.DateService.weekDaysMap[i];

                newSessionDefaults.day_availabilities.push({
                    time_ranges: [{
                        range_time_start: '9:00',
                        range_time_end: '17:00'
                    }],
                    day_of_week: day,
                    override_date: ''
                });
            }

            newSessionDefaults.day_availabilities.push({
                time_ranges: [],
                day_of_week: this.DateService.weekDaysMap[0],
                override_date: ''
            });

            newSessionDefaults.day_availabilities.push({
                time_ranges: [],
                day_of_week: this.DateService.weekDaysMap[6],
                override_date: ''
            });

            newSession.mixinFrom(newSessionDefaults);
            return newSession;
        }

        _generateColors() {

            // BASE COLORS FOR SESSIONS
            const BASE_COLORS = ['purple', 'muted-blue', 'light-blue', 'orange', 'brown', 'red', 'pink'];

            // SHADEd
            const NUM_OF_SHADES = 3;

            this._colors = [];
            for(let j = 0; j < BASE_COLORS.length; j++) {
                for(let i = NUM_OF_SHADES; i > 0; i--) {
                    const shadeName = i > 1 ? "-shade-" + i : "";
                    this._colors.push(BASE_COLORS[j] + shadeName);
                }
            }

        }

        checkTimeRangesOverlap(timeRanges) {

            // sort them by start date
            let sortedRanges = timeRanges.sort(function(a, b) {
                let aStart = parseInt(a.range_time_start.replace(":", ""));
                let bStart = parseInt(b.range_time_start.replace(":", ""));
                return aStart > bStart ? 1 : aStart < bStart ? -1 : 0;
            });

            var result = sortedRanges.reduce((result, current, idx, arr) => {

                // get the previous range
                if (idx === 0) { return result; }
                var previous = arr[idx-1];

                // check for any overlap
                var previousEnd = parseInt(previous.range_time_end.replace(":", ""));
                var currentStart = parseInt(current.range_time_start.replace(":", ""));
                var overlap = (previousEnd >= currentStart);

                // store the result
                if (overlap) {
                    // yes, there is overlap
                    result.overlap = true;
                    // store the specific ranges that overlap
                    result.overlaps.push({
                        previous: previous,
                        current: current
                    });

                    result.ranges[previous.id] = true;
                    result.ranges[current.id] = true;
                }

                return result;

            }, {overlap: false, overlaps: [], ranges: {}});

            return result;
        }

        getSessionDescriptionText(session) {

            let durationString = '';

            if (session.session_duration && session.session_duration.unit_count) {
                const durationType = this.singularizeDuration(session.session_duration.unit_type);
                durationString = `${this.$filter('pluralize')(session.session_duration.unit_count, durationType)}, `;
            }

            return `${durationString}${this.getWindowTypeText(session)}, ${this.TimezoneService.translateFromServer(session.session_timezone)}`;
        }

        getWindowTypeText(session) {
            switch(session.window_type) {
                case this.Enums.sessionWindowType.indefinitely:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._INDEFINITELY_');
                case this.Enums.sessionWindowType.rollingWindow:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._ROLLING_WINDOW_');
                case this.Enums.sessionWindowType.fixedDateRange:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._FIXED_DATE_RANGE_');
                default:
                    return '';
            }
        }

        pluralizeDuration(type) {
            return DURATION_PLURALIZATION_MAP[type];
        }

        singularizeDuration(type) {
            return DURATION_SINGULARIZATION_MAP[type];
        }
    };

}());


// assignColorsToSessions(sessions, colors) {
//     var amountDif = -20;
//     sessions.forEach(function(session, index) {
//         var baseColorIndex = index % this.BASE_COLORS.length;
//         var baseColor = this.BASE_COLORS[baseColorIndex];
//         var brightenTimes = Math.floor(index / this.BASE_COLORS.length);
//         var color = brightenTimes === 0 ? baseColor : this.UIUtils.brightenColor(baseColor, brightenTimes * amountDif);
//         session.color = color;
//     }.bind(this));
// }

// const BASE_COLORS = [
//     purple: '#9759ff',
//     "light-blue": '#59b5ff',
//     red: '#ff5962',
//     orange: '#fda55b',
//     "muted-blue": '#738dcb',
//     brown: '#e4c093',
//     pink: '#ff95ed'
// ];

//
//
//
// _generateColors(baseColors) {
//     var amountDif = -20;
//     var shades = 3;
//
//     for(var i = 0; i < shades; i++) {
//         for(var j = 0; j < baseColors.length; j++) {
//
//             var baseColor = baseColors[j];
//             var color = i === 0 ? baseColor.color : this.UIUtils.brightenColor(baseColor.color, i * amountDif);
//             var shade = i > 0 ? "-shade-" + (i + 1) : "";
//             var colorName = baseColor.name + shade;
//
//             this._colors.push({name: colorName, color: color});
//             this._colorsMap[colorName] = color;
//         }
//     }
//     // var amountDif = -20;
//     // var shades = 3;
//     //
//     // for(var i = 0; i < shades; i++) {
//     //     for(var j = 0; j < baseColors.length; j++) {
//     //         var baseColor = baseColors[j];
//     //         var color = i === 0 ? baseColor : this.UIUtils.brightenColor(baseColor, i * amountDif);
//     //         this._colors.push({name: });
//     //     }
//     // }
// }
//
// _getNextColor(sessionsList) {
//     let currentColors = sessionsList.map((s) => s.session_color);
//     let currColorIndex = 0;
//     while(currColorIndex < this._colors.length && currentColors.indexOf(this._colors[currColorIndex].name) > -1) {
//         currColorIndex++;
//     }
//     return this._colors[currColorIndex];
// }
//
// getColor(colorName) {
//     return this._colorsMap[colorName];
// }

//// let newColor = this._getNextColor(user.session_configs);
// // BASE COLORS FOR SESSIONS
// const BASE_COLORS = [
//     { name: 'purple', color: '#9759ff' },
//     { name: 'light-blue', color: '#59b5ff' },
//     { name: 'red', color: '#ff5962' },
//     { name: 'orange', color: '#fda55b' },
//     { name: 'muted-blue', color: '#738dcb' },
//     { name: 'brown', color: '#e4c093' },
//     { name: 'pink', color: '#ff95ed' },
// ];
//
// this._colors = [];
// this._colorsMap = {};