(function () {
    'use strict';

    class ProjectDateComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout, $translate, moment, _, DeviceService, UsersManager) {
            super($scope, $injector);
            this.__objectType = 'ProjectDateComponent';

            this.$timeout = $timeout;
            this.$translate = $translate;
            this.moment = moment;
            this._ = _;
            this.UsersManager = UsersManager;
            this.isMobileDevice = DeviceService.isIOS() || DeviceService.isAndroid();
            this.errors = {
                missingStartDate: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._PLEASE_CHOOSE_A_START_DATE_'),
                invalidRange: this.$translate.instant('PROJECT.ERRORS._INVALID_DATE_RANGE_'),
                requiredField: this.$translate.instant('PROJECT.ERRORS._REQUIRED_FIELD_')
            };
            this.dateError = null;
            this.dateFormat = 'ddd, MMM D, YYYY';
            this.serverDateFormat = 'YYYY-MM-DD';
            this.$timeout(() => {
                this.endDateVisible = this.endDate || this.endTime;
                this._initDate();
            },1000);
        }

        $onInit() {
            this.showDateError = false;
            this._clearCollisionPopup();
        }

        onStartDateFocus(event) {
            // this.onStartDateFocusCallback && this.onStartDateFocusCallback();
        }

        onAddEndDateClick() {
            if(this.dateError === this.errors.invalidRange) {
                this.dateError = null;
                this.showDateError = false;
            }
            if (this.startDate) {
                this.endDateVisible = true;
                return;
            }
            this.handleError('missingStartDate');
        }

        keyDownStartDate(event) {
            this._clearCollisionPopup();
            this._clearError();
            if (event.keyCode !== 8 && event.keyCode !== 46) {
                event.preventDefault();
            } else {
                this.removeBothDates();
            }
        }

        keyDownEndDate(event) {
            this._clearCollisionPopup();
            this._clearError();
            if (event.keyCode !== 8 && event.keyCode !== 46) {
                event.preventDefault();
            } else {
                this.removeEndDate();
            }
        }

        removeStartDate(skipUpdate) {
            this.date.startDate = this.startDate;
            this.startDate = null;
            this.date.startTime = this.startTime;
            this.startTime = null;
            if (!skipUpdate) {
                this.onDateTimeChange({changes: {event_date: this.startDate, event_time_start: this.startTime}});
            }
        }

        removeEndDate(skipUpdate) {
            this._clearError();
            this.endDateVisible = false;
            this.date.endDate = this.endDate;
            this.endDate = null;
            this.date.endTime = this.endTime;
            this.endTime = null;
            if (!skipUpdate) {
                this.onDateTimeChange({changes: {event_end_date: this.endDate, event_time_end: this.endTime}});
            }
        }

        removeBothDates() {
            this.removeStartDate(true);
            this.removeEndDate(true);
            this.onDateTimeChange({
                changes: {
                    event_date: this.startDate,
                    event_time_start: this.startTime,
                    event_end_date: this.endDate,
                    event_time_end: this.endTime
                }
            });
        }

        updateStartDate() {
            let startDate = this._formatDate(this.startDate, this.serverDateFormat);
            let endDate = this._formatDate(this.endDate, this.serverDateFormat);
            this._clearCollisionPopup();
            this._clearError();
            this._getConflictingProjectsByDate(startDate, startDate);
            if(this._isValidDateRange(startDate, endDate, this.startTime, this.endTime)) {
                this._setStartDate(startDate);
            }
            else {
                this.handleError('invalidRange');
            }
            this.onDateTimeChange({changes: {event_date: this.startDate}});
        }

        updateEndDate() {
            let startDate = this._formatDate(this.startDate, this.serverDateFormat);
            let endDate = this._formatDate(this.endDate, this.serverDateFormat);
            this._clearCollisionPopup();
            this._clearError();
            this._getConflictingProjectsByDate(startDate, endDate);
            if(this._isValidDateRange(startDate, endDate, this.startTime, this.endTime)) {
                this._setEndDate(endDate);
            }
            else {
                this.handleError('invalidRange');
            }
            if (this.endDate && !this.endTime && this.startTime){
                this.endTime = this.startTime;
            }
            this.onDateTimeChange({changes: {event_end_date: this.endDate, event_time_end: this.endTime}});
        }

        updateStartTime() {
            let changes = {};
            this._clearCollisionPopup();
            this._clearError();
            if(!this.startDate && this.isRequired('startDate')) {
                this.handleError();
            }
            if(!this.startDate) {
                let startDate = this._getToday();
                this._setStartDate(startDate);
                changes = {event_date: this.startDate};
            }
            let startDate = this._formatDate(this.startDate, this.serverDateFormat);
            let endDate = this._formatDate(this.endDate, this.serverDateFormat);
            if(this._isValidDateRange(startDate, endDate, this.startTime, this.endTime)) {
                this.date.startTime = this.startTime;
                changes.event_time_start = this.startTime;
            }
            else {
                this.handleError('invalidRange');
            }
            if (this.endDate && !this.endTime && this.startTime){
                this.endTime = this.startTime;
                changes.event_time_end = this.endTime;
            }
            this.onDateTimeChange({changes: changes});
        }

        updateEndTime() {
            let changes = {};
            this._clearCollisionPopup();
            this._clearError();
            if(!this.endDate) {
                this._setEndDate(this.startDate);
                changes = {event_end_date: this.endDate};
            }
            let startDate = this._formatDate(this.startDate, this.serverDateFormat);
            let endDate = this._formatDate(this.endDate, this.serverDateFormat);
            if(this._isValidDateRange(startDate, endDate, this.startTime, this.endTime)) {
                this.date.endTime = this.endTime;
                changes.event_time_end = this.endTime;
            }
            else {
                this.handleError('invalidRange');
            }
            if (this.endDate && !this.endTime && this.startTime){
                this.endTime = this.startTime;
                changes.event_time_end = this.endTime;
            }
            this.onDateTimeChange({changes: changes});
        }

        isRequired(type) {
            return this.requiredFields ? this.requiredFields.find((field) => {return field === type;}) : false;
        }

        handleError(type) {
            this._revertDate();
            this.dateError = this.errors[type];
            this.showDateError = true;
        }

        _isValidDateRange(startDate, endDate, startTime, endTime) {
            if(startTime && endTime) {
                startDate = this._formatTime(startDate, startTime);
                endDate = this._formatTime(endDate, endTime);
                return this.moment(startDate).isBefore(endDate);
            }
            else if(startDate && endDate) {
                return this.moment(startDate).isSameOrBefore(endDate);
            }
            return true;
        }

        _clearError() {
            this.dateError = null;
            this.showDateError = false;
        }

        _getConflictingProjectsByDate(startDate, endDate) {

            this.UsersManager.getCalendarEventsOnDate(this.UsersManager.getCurrUser()._id, startDate, endDate)
                .then((resp) => {
                    this.conflictingProjects = resp.data.booked_events.concat(resp.data.lead_events);
                    this.conflictingProjects = this.projectId ? this._removeCurrentProjectFromConflictingList(this.projectId) : this.conflictingProjects;
                    if(this.moment(startDate).isSame(endDate)) {
                        this.showDateCollisionModal.event_date = this.conflictingProjects && this.conflictingProjects.length > 0;
                    }
                    else {
                        this.showDateCollisionModal.event_end_date = this.conflictingProjects && this.conflictingProjects.length > 0;
                    }
                });
        }

        _formatDate(date, targetFormat) {
            if(date) {
                return this.moment(date).format(targetFormat);
            }
            return null;
        }

        _formatTime(date, time) {
            return this.moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm a').format();
        }

        _clearCollisionPopup() {
            this.showDateCollisionModal = {event_date: false, event_end_date: false};
        }

        _initDate() {
            this.date = {
                startDate: this.startDate,
                endDate: this.endDate,
                startTime: this.startTime,
                endTime: this.endTime
            };
        }

        _revertDate() {
            this.startDate = this.date.startDate;
            this.endDate = this.date.endDate;
            this.startTime = this.date.startTime;
            this.endTime = this.date.endTime;
        }

        _setStartDate(date) {
            this.startDate = date;
            this.date.startDate = this.startDate;
        }

        _setEndDate(date) {
            this.endDate = date;
            this.date.endDate = this.endDate;
        }

        _getToday() {
            return this.moment().format(this.serverDateFormat);
        }

        _removeCurrentProjectFromConflictingList(projectId) {
            return this.conflictingProjects.filter((project) => {
                return project._id !== projectId;
            });
        }
    }

    Components.ProjectDate = {
        bindings: {
            startDate: '<',
            startTime: '<?',
            endDate: '<?',
            endTime: '<?',
            onDateTimeChange: '&?',
            requiredFields: '<?',
            labels: '<?',
            shouldShowLabel: '=?',
            shouldShowDateConflicts: '=?',
            modalInstance: '=?',
            analyticsEventsSource: '=?',
            projectId: '=?',
            innerTitle: '<?',
            openCollisionInNewTab: '=',
},

        controller: ProjectDateComponent,
        name: 'hbProjectDate',
        templateUrl: 'angular/app/modules/core/features/project/project_date/project_date.html'
    };

}());
