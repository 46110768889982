(function () {
    'use strict';

    // @ngInject
    function ConfirmGmailIntegrationNeededControllerCtor($scope, $injector, AnalyticsService, AppStates, $modalInstance) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfirmGmailIntegrationNeededController';
        AnalyticsService.trackPageView(this, 'ConfirmGmailIntegrationNeededView');

        this.modalInstance = $modalInstance;
        this.AppStates = AppStates;
    }

    Controllers.ConfirmGmailIntegrationNeededController = Class(Controllers.BaseController, {
        constructor: ConfirmGmailIntegrationNeededControllerCtor,

        cancel: function cancel() {
            this.modalInstance.dismiss();
        },

        confirm: function confirm() {
            this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
            this.modalInstance.close(true);
        }
    });
}());
