
(function () {
  'use strict';

  class ProjectDetailsFieldVisibilityComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector) {
      super($scope, $injector);
      this.__objectType = 'ProjectDetailsFieldVisibility';
    }
  }

  Components.ProjectDetailsFieldVisibility = {
    bindings: {
      visible: '<',
      visibilityToggle: '@'
    },
    controller: ProjectDetailsFieldVisibilityComponent,
    name: 'hbProjectDetailsFieldVisibility',
    templateUrl: 'angular/app/modules/core/features/settings/company/project_details/field_visibility_component/project_details_field_visibility_component.html'
  };

}());
