/**
 * Created by inonstelman on 1/6/15.
 */
(function () {
    'use strict';

    // @ngInject
    Decorators.$exceptionHandlerDecorator = function $exceptionHandlerDecorator($delegate, AppConfigService, DatadogRUMService) {

        return function $exceptionDecoratorHandlerFunction(exception, cause) {

            if (!AppConfigService.isProduction()) {
                $delegate(exception, cause);
            }

            // Track error and log anything else of interest.
            DatadogRUMService.addError(
                exception,
                {cause: cause}
            );
        };
    };


}());
