(function () {
    'use strict';

    class InteractiveOnboardingWizardController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            _,
            Enums,
            AbTestService,
            OnboardingService,
            FeaturesService,
            AnalyticsService,
            CompaniesManager,
            UsersManager
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingWizardController';

            this.$scope = $scope;
            this._ = _;
            this.Enums = Enums;
            this.AbTestService = AbTestService;
            this.OnboardingService = OnboardingService;
            this.AnalyticsService = AnalyticsService;
            this.FeaturesService = FeaturesService;
            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;

            this.currUser = this.UsersManager.getCurrUser();

            this.AnalyticsService.trackPageView(
                this,
                this.AnalyticsService.analytics_events.setup_guide,
                {
                    source: 'setup_guide_interactive',
                    setup_guide_variant: 'setup_guide_interactive',
                    phase: 'onboarding'
                }
            );

            $scope.$watch('interactiveOnboardingWizardVm.steps',
                (steps) => {
                    this.updateCurrStepIndex();
                }
            );

            $scope.$watch('interactiveOnboardingWizardVm.isCollapsed', (isCollapsed, wasCollapsed) => {
                if (isCollapsed === false && wasCollapsed === true) {
                    this.updateCurrStepIndex();
                }
            })
        }

        $onInit() {
            this.company = this.CompaniesManager.getCurrCompany();
            this.defaultCoverImage = this.company.getDefaultCoverImage();
            this.currentStepIndex = 0;
        }

        updateCurrStepIndex() {
            if (this.steps) {
                this.currentStepIndex = this._.findIndex(this.steps, step => step.key === this.currentStep.key);
            }
        }

        onStepValidation(isValid) {
            // ¯\_(ツ)_/¯
        }

        submitStep(step) {
            if (step.status === 'complete') {
                this.moveToNextStep();
            } else {
                this.$scope.$broadcast('submitOnboardingStep', {step: step});
            }
        }

        onStepComplete() {
            this.OnboardingService.completeOnboardingStep(this.currentStep);
        }

        shouldSeeBackButton() {
            return (this.currentStepIndex > 0);
        }

        shouldSeeNextButton() {
            if (this.steps) {
                return (this.currentStepIndex < this.steps.length - 1);
            }
            return false;
        }


        moveToPreviousStep() {
            this.currentStepIndex--;
            this.currentStep = this.steps[this.currentStepIndex];
            this.AnalyticsService.track(this, 'setup guide back', {
                step: this.currentStep.key
            });
        }

        moveToNextStep() {
            this.currentStepIndex++;
            this.currentStep = this.steps[this.currentStepIndex];
        }

        getStepFromKey(stepKey) {
            if (this.steps) {
                return this._.find(this.steps, step => step.key === stepKey);
            }
        }

        shouldRenderStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                const diff = this.currentStep.order - thisStep.order;
                return Math.abs(diff) <= 1;
            }
        }

        isPrecedingStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                return thisStep.order < this.currentStep.order;
            }
        }

        isSucceedingStep(stepKey) {
            const thisStep = this.getStepFromKey(stepKey);
            if (thisStep) {
                return thisStep.order > this.currentStep.order;
            }
        }
    }

    Components.InteractiveOnboardingWizard = {
        controller: InteractiveOnboardingWizardController,
        controllerAs: 'interactiveOnboardingWizardVm',
        bindings: {
            steps: '<',
            currentStep: '=',
            isCollapsed: '<',
            completeMwebWizard: '&',
        },
        name: 'hbInteractiveOnboardingWizard',
        require: {
            hbInteractiveOnboarding: '^hbInteractiveOnboarding'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/int_onb_wizard/int_onb_wizard.html',
    };
}());
