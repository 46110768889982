(function () {
    "use strict";

    // @ngInject
    function FileSectionMobilePageControllerFunc($scope, $injector, $) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ServiceDirectiveNewController';

        this.$scope = $scope;
        this.$ = $;

        // handle page scrolling when modal is shown
        $scope.$on('$destroy', function () {
            $('body').removeClass('hb-body--disable-scroll');
        });
    }

    var FileSectionMobilePageController = Class(Controllers.BaseController, {
        constructor: FileSectionMobilePageControllerFunc,

        goBack: function goBack() {
            if(this.onBack) {
               this.onBack({});
            }
        },

        onAddActionClick: function onAddActionClick() {
            if(this.onAddAction) {
                this.onAddAction({});
            }
        },

        onCancelActionClick: function onCancelActionClick() {
            if(this.onCancelAction) {
                this.onCancelAction({});
            }
        },

        _handlePageScrolling: function _handlePageScrolling() {
            this.$('body').toggleClass('hb-body--disable-scroll', !!this.shouldShow);
        },

        $onChanges: function $onChanges() {
            this._handlePageScrolling();
        }
    });

    Components.FileSectionMobilePageComponent = {
        bindings: {
            title: '@',
            onBack: '&',
            onAddAction: '&',
            showAddAction: '<',
            onCancelAction: '&',
            showCancelAction: '<',
            shouldShow: '<',
        },
        transclude: true,
        controller: FileSectionMobilePageController,
        controllerAs: 'fileSectionMobileVm',
        name: 'hbFileSectionMobilePage',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/mobile/file_section_mobile_page_template.html'
    };

}());