/**
 * The hb-contact-form-direct_link component
 *
 * This component shows the direct link panel of the contact form sidebar
 *
 */


(function () {
    'use strict';

    class ContactFormDirectLinkComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $timeout, CompaniesManager, ModalService, AnalyticsService, PopupMessageService)  {
            super($scope, $injector, $translate, $timeout);
            this.__objectType = 'ContactFormDirectLinkComponentController';
            this.$timeout = $timeout;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.company = CompaniesManager.getCurrCompany();            

            this.contactFormId = this.contactFormModel._id;
            this.linksIsCopied = false;
            this.copyLinkTooltip = "Copy link";

            this.isGiftCard = this.contactFormModel.isGiftCard();

			this.analyticsCopyEvent = 'contact form: copied direct link url';
			this.analyticsNewTabEvent = 'contact form: opened direct link in new tab';
			this.analyticsProps = `contact_form_id: ${this.contactFormId}, version:${this.contactFormType}`;

            if(this.isGiftCard) {
				this.analyticsCopyEvent = 'gift card copied direct link url';
				this.analyticsNewTabEvent = 'gift card opened direct link in new tab';
				this.analyticsProps = `gift_card_id: ${this.contactFormId}, version:${this.contactFormType}`;
			}
        }

        linkCopied() {
            var elem = document.getElementById('copyDirectLinkButton');
            this.linksIsCopied = true;
            this.copyLinkTooltip = "Link copied!";

            this.$timeout(() => {
                elem._tippy.hide();
            }, 5000);

            this.$timeout(() => {
                this.linksIsCopied = false;
                this.copyLinkTooltip = "Copy link";
            }, 5500);
        }

        openDirectLinkModal() {
            this.AnalyticsService.trackClick(this, "view direct link sharing options", {app_path: 'sidebar'});
            this.ModalService.openEmbededContactFormModal(this.contactFormModel, this.company, 'embed_code', true)
        }
        shouldShowSharingOptionsLink() {
            return !this.isGiftCard;
        }
    }

    Components.ContactFormDirectLink = {
        bindings: {
            contactFormType: '<',
            lastPublishTimestamp : '<',
            tradeShowUrl: '<',
            openTradeShowTab: '&',
            contactFormModel: '<'
        },
        controller: ContactFormDirectLinkComponentController,
        templateUrl: 'angular/app/modules/core/features/contact_form/aside_menu_content/contact_form_direct_link/contact_form_direct_link.html',
    };

}());
