(function () {
    "use strict";

    Controllers.OneFlowController = class OneFlowController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, AppStates, $state, FlowsBetaUserTypeService, OtamStatusService, $stateParams, $location) {
            super($scope, $injector);
            this.__objectType = 'OneFlowController';
            this.OtamStatusService = OtamStatusService;
            this.OtamStatusService.initializeAfterLogin();

            if ($stateParams.openConfigureModal) {
                $location.search({openConfigureModal: true});
            }
            if ($stateParams.blockToSelect) {
                $location.search({ blockToSelect: $stateParams.blockToSelect });
            }            

            if ($stateParams.scrollTo) {
                // Wait for the element to be rendered and then scroll to it
                const unWatch = $scope.$watch(function() {
                    return document.getElementById($stateParams.scrollTo);
                }, function(newElement) {
                    if (newElement) {
                        const headerOffset = 104; // Header height is 64px + 40px padding
                        const elementPosition = newElement.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.scrollY - headerOffset;
                        const container = document.querySelector('.one-flow-root-wrapper');
                        container.scrollTo({
                             top: offsetPosition,
                             behavior: "smooth"
                        });
                        unWatch();
                    }
                });
            }
            
            UsersManager.forceFetchCurrUser().then(function success(){
                FlowsBetaUserTypeService.refreshFlowBetaUserType();
                // The added conditions are for the OTAM exp
                if (!FlowsBetaUserTypeService.hasFlows &&
                    !this.OtamStatusService.isMigrationEnabledExp()) {
                    $state.go(AppStates.root_core_navigation_home);
                }
            }.bind(this));
            const scrollSection = angular.element('.js-flow-root');
            scrollSection.on('scroll', function() {
                if (scrollSection.scrollTop() > 0) {
                    $('body').addClass('page-is--scrolled');
                } else {
                    $('body').removeClass('page-is--scrolled');
                }
            });
        }
    };

}());
