
(function () {
    'use strict';

    var FILE_TYPES = {
        proposal: {
            type: 'proposal',
            icon: 'icon-hb-proposal',
            title: 'Proposal',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._PROPOSAL_'
        },
        brochure: {
            type: 'brochure',
            icon: 'icon-hb-brochure',
            title: 'Brochure',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._BROCHURE_'
        },
        services: {
            type: 'services',
            icon: 'icon-hb-brochure',
            title: 'Services',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._BROCHURE_'
        },
        'brochure-new': {
            type: 'brochure-new',
            icon: 'icon-hb-brochure',
            title: 'The new brochure',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._BROCHURE_NEW_'
        },
        scheduler: {
            type: 'scheduler',
            icon: 'icon-hb-invoice',
            title: 'Scheduler',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._SCHEDULER_'
        },
        invoice: {
            type: 'invoice',
            icon: 'icon-hb-invoice',
            title: 'Invoice',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._INVOICE_'
        },
        agreement: {
            type: 'agreement',
            icon: 'icon-hb-agreement',
            title: 'Contract',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._AGREEMENT_'
        },
        questionnaire: {
            type: 'questionnaire',
            icon: 'icon-hb-questionnaire',
            title: 'Questionnaire',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._QUESTIONNAIRE_'
        },
        timeline: {
            type: 'timeline',
            icon: 'icon-hb-timeline',
            title: 'Timeline',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._TIMELINE_'
        },
        flow: {
            type: 'flow',
            icon: 'icon-hb-timeline',
            title: 'Custom Smart File',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._FLOW_'
        },
        attachment: {
            type: 'attachment',
            title: 'Upload',
            text: 'WORKSPACE.ACTIONS.LABELS.DESC._ATTACHMENT_'
        }
    };

    Directives.FileTypeTooltipDirective = function FileTypeTooltipDirective() {

        // @ngInject
        function FileTypeTooltipDirectiveControllerFunc($scope, $injector, $translate) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'FileTypeTooltipDirectiveControllerFunc';
            this.$translate = $translate;

            this.file = FILE_TYPES[this.type];
        }

        var FileTypeTooltipDirectiveController = Class(Controllers.BaseController, {
            constructor: FileTypeTooltipDirectiveControllerFunc
        });

        return {
            scope: {
                type:'@',
                expanded: '=?',
                vendorType: '@'
            },
            templateUrl: 'angular/app/modules/core/features/workspace/file_type_tooltip/file_type_tooltip_directive_template.html',
            controller: FileTypeTooltipDirectiveController,
            controllerAs: 'fileTypeTooltipVm',
            bindToController: true
        };
    };
})();