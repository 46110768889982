(function () {
    "use strict";

    Controllers.BulkEmailsIntroModalController = class BulkEmailsIntroModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, AnalyticsService, batchSource) {

            super($scope, $injector);
            this.__objectType = 'BulkEmailsIntroModalController';

            this.$modalInstance = $modalInstance;
            this.AnalyticsService = AnalyticsService;
            this.batchSource = batchSource;

            this.data = this.batchSource === 'contacts' ?
              [{text: 'BULK_INTRO_MODAL._ITEM1_CONTACTS_'}, {text: 'BULK_INTRO_MODAL._ITEM2_CONTACTS_'}] :
              [{text: 'BULK_INTRO_MODAL._ITEM1_'}, {text: 'BULK_INTRO_MODAL._ITEM2_'}];

            AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.bulk_email_intro_modal);
        }

        close() {
            this.$modalInstance.close();
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }
    }


}());
