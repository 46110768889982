(function () {
    "use strict";

    var OTHER_CATEGORY = '_category_other';
    var CUSTOMIZED_CATEGORY_NAME = 'Customized Fields';
    var HB_DATA_TYPE = {
        CLIENT: 'client_field',
        VENDOR: 'vendor_field'
    };
    var HB_FIELD_REQUIRED = {
        REQUIRED: 'true',
        OPTIONAL: 'false'
    };
    var categoriesMap = {};
    var categoriesKeysToFieldsMap = {};
    var fieldsMapByPlaceholder = {};
    var fieldsMapByName = {};

    var InputField = Class(function () {
        return {
            constructor: function InputFieldCtor(fieldConfig) {
                this.parseFieldConfig(fieldConfig);
                this._originalField = fieldsMapByPlaceholder[this.field_placeholder] || fieldsMapByName[this.field_name] || fieldsMapByName['other'];
                if (!fieldConfig) {
                    fieldConfig = this._originalField.entry_key;
                    this.parseFieldConfig(fieldConfig);
                }
            },

            parseFieldConfig: function parseFieldConfig(fieldConfig) {
                var res = (fieldConfig || '').match(/(\w+):[^,}]+/g);
                if (res) {
                    res.forEach(function (entry) {
                        var split = entry.split(':');
                        this[split[0]] = (split[1] || '').trim();
                    }.bind(this));
                }
            },

            getDefaultValue: function getDefaultValue(agreementValuesStrategy) {
                if (this._originalField === undefined) {
                    // Old customized inputs don't have a default value, name was not set to 'other'
                    return '';
                }

                var fieldNameCamelCased = this._originalField.config.field_name.replace(/(^|_)(\w)/g, function(match, $0, $1) { return $1.toUpperCase(); });
                var fieldGetter = 'get' + fieldNameCamelCased;
                var func = agreementValuesStrategy[fieldGetter];
                return func ? func.bind(agreementValuesStrategy)() : '';
            },

            getPlaceholder: function getPlaceholder() {
                return this.field_placeholder || '';
            },

            getCategoryKey: function getCategoryKey() {
                return this._originalField.data_category;
            },

            getCategory: function getCategory() {
                return categoriesMap[this._originalField.data_category];
            },

            getCategoryName: function getCategoryName() {
                return categoriesMap[this._originalField.data_category].entry_value;
            },

            getDefaultFieldName: function getDefaultFieldName() {
                return this._originalField.entry_key;
            },

            getFieldEntryValue: function getFieldEntryValue() {
                return this._originalField.entry_value;
            },

            setClientField: function setClientField(isClient) {
                if (isClient) {
                    this.field_hb_data_type = HB_DATA_TYPE.CLIENT;
                } else {
                    this.field_hb_data_type = HB_DATA_TYPE.VENDOR;
                    this.setRequiredField(true);
                }
            },

            setRequiredField: function setRequiredFeild(isRequired) {
                if (isRequired) {
                    this.field_required = HB_FIELD_REQUIRED.REQUIRED;
                } else {
                    this.field_required = HB_FIELD_REQUIRED.OPTIONAL;
                }
            },

            setFieldChanged: function setFieldChanged(isChanged) {
                this.field_changed = (!!isChanged).toString();
            },

            setFieldPlaceholder: function setFieldPlaceholder(placeholder) {
                this.field_placeholder = placeholder;
            },

            isOtherField: function isOtherField() {
                return this.getCategoryKey() === OTHER_CATEGORY;
            },

            isClientField: function isClientField() {
                return this.field_hb_data_type === HB_DATA_TYPE.CLIENT;
            },

            isRequiredField: function isRequiredField() {
                return this.field_required === undefined ? undefined : this.field_required === HB_FIELD_REQUIRED.REQUIRED;
            },

            isFieldChanged: function isFieldChanged() {
                return this.field_changed === 'true';
            },

            isRealField: function isRealField() {
                return !!this._originalField;
            },

            toString: function toString() {
                var res = "{";
                var keys = Object.keys(this);
                keys.forEach(function (key) {
                    if (key.indexOf('_') === 0) {
                        return;
                    }
                    res += key + ':' + this[key] + ',';
                }.bind(this));

                if (keys.length > 0) {
                    // Cut off the last comma (,)
                    res = res.slice(0, res.length - 1);
                } else {
                    console.error("InputField object shouldn't be empty!!!!");
                }

                return res + '}';
            }
        };
    });

    // @ngInject
    Services.InputFieldsService = function InputFieldsService(uuid4) {
        var model = [
            {
                entry_key: "_category_file",
                entry_value: "File",
                options: [
                    {
                        entry_key: "{field_name:agreement_date, field_type:Date, field_default:current_date, field_input_type:String, field_placeholder:Agreement Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Agreement Date",
                        data_category: "_category_file",
                        default_value: '',
                        legacy_names: ['agreement_created_date'] //legacy name has to be unique. Don't use this if legacy name is now used by a different field.
                    },
                    {
                        entry_key: "{field_name:proposal_title, field_type:String, field_default:proposal_title, field_input_type:String, field_placeholder:Proposal Title, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Proposal Title",
                        data_category: "_category_file",
                        default_value: ''
                    }
                ]
            },
            {
                entry_key: "_category_payments",
                entry_value: "Payments",
                options: [
                    {
                        entry_key: "{field_name:proposal_total_price, field_type:Currency, field_default:proposal_total_price, field_input_type:String, field_placeholder:Proposal Total Amount, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Proposal Total Amount",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:first_payment_amount, field_type:Currency, field_default:first_payment_amount, field_input_type:String, field_placeholder:First Payment Amount, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Payment Amount",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:last_payment_due_date, field_type:Date, field_default:last_payment_due_date, field_input_type:String, field_placeholder:Last Payment Due Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Last Payment Due Date",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:last_payment_amount, field_type:Currency, field_default:last_payment_amount, field_input_type:String, field_placeholder:Last Payment Amount, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Last Payment Amount",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:second_payment_amount, field_type:Currency, field_default:second_payment_amount, field_input_type:String, field_placeholder:Second Payment Amount, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Payment Amount",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:first_payment_due_date, field_type:Date, field_default:first_payment_due_date, field_input_type:String, field_placeholder:First Payment Due Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Payment Due Date",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:second_payment_due_date, field_type:Date, field_default:second_payment_due_date, field_input_type:String, field_placeholder:Second Payment Due Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Payment Due Date",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:proposal_sub_total_price, field_type:Currency, field_default:proposal_sub_total, field_input_type:String, field_placeholder:Proposal Subtotal, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Proposal Subtotal",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:proposal_tax, field_type:Currency, field_default:proposal_tax, field_input_type:String, field_placeholder:Tax, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Proposal Tax",
                        data_category: "_category_payments",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:company_tax, field_type:Float, field_default:company_tax, field_input_type:String, field_placeholder:Default Tax, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Default Tax",
                        data_category: "_category_payments",
                        default_value: ''
                    }
                ]
            },
            {
                entry_key: "_category_company",
                entry_value: "Company",
                options: [
                    {
                        entry_key: "{field_name:company_name, field_type:String, field_default:company_name, field_input_type:String, field_placeholder:Company Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Company Name",
                        data_category: "_category_company",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:company_email, field_type:Email, field_default:company_email, field_input_type:String, field_placeholder:Company Email, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Company Email",
                        data_category: "_category_company",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:company_type, field_type:String, field_default:company_type, field_input_type:String, field_placeholder:Company Type, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Company Type",
                        data_category: "_category_company",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:company_phone_number, field_type:String, field_default:company_phone_number, field_input_type:String, field_placeholder:Company Phone Number, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Company Phone Number",
                        data_category: "_category_company",
                        default_value: ''
                    }
                ]
            },
            {
                entry_key: "_category_event",
                entry_value: "Project",
                options: [
                    {
                        entry_key: "{field_name:event_location, field_type:String, field_default:event_location, field_input_type:String, field_placeholder:Project Location, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Location",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_date, field_type:Date, field_default:event_date, field_input_type:String, field_placeholder:Project Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Date",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_type, field_type:String, field_default:event_type, field_input_type:String, field_placeholder:Project Type, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Type",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_time_start, field_type:String, field_default:event_time_start, field_input_type:String, field_placeholder:Project Time Start, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Time Start",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_venue_name, field_type:String, field_default:event_venue_name, field_input_type:String, field_placeholder:Project Venue Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Venue Name",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_time_end, field_type:String, field_default:event_time_end, field_input_type:String, field_placeholder:Project Time End, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Time End",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_guests_number, field_type:Integer, field_default:event_guests_number, field_input_type:String, field_placeholder:Guests Number, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Guests Number",
                        data_category: "_category_event",
                        default_value: '',
                        shouldShow: function (company) {
                            return company.shouldShowProjectField('guests');
                        }
                    },
                    {
                        entry_key: "{field_name:event_name, field_type:String, field_default:event_name, field_input_type:String, field_placeholder:Project Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Name",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_end_date, field_type:Date, field_default:event_end_date, field_input_type:String, field_placeholder:Project End Date, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project End Date",
                        data_category: "_category_event",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:event_budget, field_type:Currency, field_default:event_budget, field_input_type:String, field_placeholder:Project Budget, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Project Budget",
                        data_category: "_category_event",
                        default_value: '',
                        shouldShow: function (company) {
                            return company.shouldShowProjectField('budget');
                        }
                    }
                ]
            },
            {
                entry_key: "_category_clients",
                entry_value: "Clients",
                options: [
                    {
                        entry_key: "{field_name:client_full_name_1, field_type:String, field_default:first_client_full_name, field_input_type:String, field_placeholder:First Client Full Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Client Full Name",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_full_name_2, field_type:String, field_default:second_client_full_name, field_input_type:String, field_placeholder:Second Client Full Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Client Full Name",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_address_1, field_type:String, field_default:first_client_address, field_input_type:String, field_placeholder:First Client Address, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Client Address",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_phone_number_1, field_type:String, field_default:first_client_phone_number, field_input_type:String, field_placeholder:First Phone Number, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Client Phone Number",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_email_1, field_type:Email, field_default:first_client_email, field_input_type:String, field_placeholder:First Client Email, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Client Email",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_initials_1, field_type:String, field_default:first_client_initials, field_input_type:String, field_placeholder:First Client Initials, field_changed:false, field_hb_data_type:client_field, field_required:true}",
                        entry_value: "First Client Initials",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_email_2, field_type:Email, field_default:second_client_email, field_input_type:String, field_placeholder:Second Client Email, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Client Email",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_phone_number_2, field_type:String, field_default:second_client_phone_number, field_input_type:String, field_placeholder:Second Phone Number, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Client Phone Number",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_address_2, field_type:String, field_default:second_client_address, field_input_type:String, field_placeholder:Second Client Address, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Client Address",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_initials_2, field_type:String, field_default:second_client_initials, field_input_type:String, field_placeholder:Second Client Initials, field_changed:false, field_hb_data_type:client_field, field_required:true}",
                        entry_value: "Second Client Initials",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_first_name_1, field_type:String, field_default:first_client_first_name, field_input_type:String, field_placeholder:First Client First Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "First Client First Name",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                    {
                        entry_key: "{field_name:client_first_name_2, field_type:String, field_default:second_client_first_name, field_input_type:String, field_placeholder:Second Client First Name, field_changed:false, field_hb_data_type:vendor_field, field_required:true}",
                        entry_value: "Second Client First Name",
                        data_category: "_category_clients",
                        default_value: ''
                    },
                ]
            },
            {
                entry_key: "_category_other",
                entry_value: "Other",
                options: [
                    {
                        entry_key: "{field_name:other, field_type:String, field_default:null, field_input_type:String, field_placeholder:, field_changed:false, field_hb_data_type:client_field, field_required:false}",
                        entry_value: "Custom Field",
                        data_category: "_category_other",
                        default_value: ''
                    }
                ]
            },
            {
                entry_key: "_category_customized",
                entry_value: CUSTOMIZED_CATEGORY_NAME,
                options: []
            }
        ];
        model.forEach(function (cat) {
            categoriesMap[cat["entry_key"]] = cat;
            categoriesKeysToFieldsMap[cat.entry_value] = {};

            cat.options.forEach(function (field) {
                var inputField = new InputField(field.entry_key);
                categoriesKeysToFieldsMap[cat.entry_value][field.entry_value] = inputField;
                field.config = inputField;
                fieldsMapByPlaceholder[inputField.field_placeholder] = field;
                fieldsMapByName[inputField.field_name] = field;
                if (field.legacy_names) {
                    field.legacy_names.forEach(function (legacyName){
                        fieldsMapByName[legacyName] = field;
                    });
                }
            });
        });
        this.OTHER_CATEGORY = OTHER_CATEGORY;

        this.getCategories = function () {
            return model;
        };

        this.getCategoryName = function getCategoryName(categoryKey) {
           return categoriesMap[categoryKey].entry_value;
        } ;

        this.getCategoryFields = function getCategoryFields(category) {
            if (angular.isString(category)) {
                category = categoriesMap[category];
            }
            return category.options;
        };

        this.getField = function getField(fieldConfig) {
            return new InputField(fieldConfig);
        };

        this.getOtherCategoryField = function getOtherCategoryField() {
            var otherFieldCfg = this.getCategoryFields(this.OTHER_CATEGORY)[0];
            return this.getField(otherFieldCfg.entry_key);
        };

        this.getInputFieldByCategory = function getInputField(category, key) {

            if (categoriesKeysToFieldsMap[category] && categoriesKeysToFieldsMap[category][key]) {
                return categoriesKeysToFieldsMap[category][key];
            }

            return null;
        };

        this.getInputTagByCategory = function getInputTagByCategory(category, key) {
            var field = this.getInputFieldByCategory(category, key);

            if (field) {
                var id = uuid4.generate();
                var placeholder = field.getPlaceholder();
                return "<input type='text' value='' id='" + id + "' placeholder='" + placeholder + "' class='vendor-field email-editor-field' name='" + field + "' disabled='disabled' />";
            }

            return null;
        };

        this.createCustomizedField = function createCustomizedField(id, name) {
            var field = {
                entry_key: "{field_name:" + id + ", field_type:String, field_default:" + id + ", field_input_type:String, field_placeholder:" + name + ", field_changed:false, field_hb_data_type:vendor_field, field_required:false}",
                entry_value: name,
                data_category: "_category_customized",
                default_value: ''
            };

            // This is very important to identify this field and prevent it from being "Other"
            fieldsMapByName[id] = field;

            // add field to maps
            var inputField = new InputField(field.entry_key);
            categoriesKeysToFieldsMap[CUSTOMIZED_CATEGORY_NAME][field.entry_value] = inputField;
            field.config = inputField;
            fieldsMapByPlaceholder[inputField.field_placeholder] = field;

            return inputField;
        };
    };

}());
