(function () {
    "use strict";

    // @ngInject
    function WorkspaceFeedSwitchControllerCtor($scope, $injector, $stateParams, StatsigService, UsersManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFeedSwitchController';

        const currUser = UsersManager.getCurrUser();
        const isVendor = currUser.isVendor();
        const isClient = currUser.isClient();
        const isVendorAsClient = isVendor &&
            currUser.workspace_user_data &&
            currUser.workspace_user_data.workspace_role === 'host';

        this.version = null;
        if (isClient || isVendorAsClient) {
            this.version = 'angular';
        } else {
            StatsigService.isGateEnabled('ng2react_workspace_activity_tab')
                .then((enabled) => {
                    this.version = enabled ? 'react' : 'angular';
                    // this.version = 'angular';
                });
        }
    }

    Controllers.WorkspaceFeedSwitchController = Class(Controllers.BaseController, {
        constructor: WorkspaceFeedSwitchControllerCtor,
    });
}());
