Directives.PackageDirectiveNew = function PackageDirectiveNew() {

    // @ngInject
    function PackageDirectiveControllerFunc($scope, $injector, AnalyticsService, PopupMessageService, _, $translate, UIUtils, $exceptionHandler, WorkspaceFileService, $) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PackageDirectiveController';

        this._ = _;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.$translate = $translate;
        this.UIUtils = UIUtils;
        this.$exceptionHandler = $exceptionHandler;
        this.WorkspaceFileService = WorkspaceFileService;
        this.$ = $;

        // tax
        this.enableTax = this.packageModel.shouldShowTax();

        // svc
        this.enableSVC = this.packageModel.shouldShowSVC();

        // services view model
        this.baseServices = [];
        this.packageViewModel = {};

        // init sortable configuration
        this.sortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.js-proposal-drag-handler',
            connectWith: ['.hb-proposal-items-wrapper', '.hb-package__services'],
            disabled: (!this.isEditableMode || this.isPreviewMode),
            stop: this.onDragStop.bind(this),
            update: this.onDragUpdate.bind(this)
        };

        this._initializeUnits();

        this.fillViewModelAccordingToModel();
        this._setPricePerUnit();

        var modelToRegister = (this.fileModel && this.fileModel.isModelOf('WorkspaceFile')) ? this.fileModel : this.company;
        this.register(modelToRegister, 'success', this.fillViewModelAccordingToModel.bind(this));

        $scope.$watch('packageVm.isEditableMode', function (newVal, oldVal) {
            if(newVal !== undefined){
                this.sortableOptions.disabled = (!newVal || this.isPreviewMode);
            }
        }.bind(this));

        $scope.$watch('packageVm.isPreviewMode', function (newVal, oldVal) {
            if(newVal !== undefined){
                this.sortableOptions.disabled = (!this.isEditableMode || newVal);
            }
        }.bind(this));
    }

    var PackageDirectiveController = Class(Controllers.BaseController, {
        constructor: PackageDirectiveControllerFunc,

        fillViewModelAccordingToModel: function fillViewModelAccordingToModel() {

            // clear services
            this.baseServices.splice(0);

            // build view model
            this.packageModel.base_services.forEach(function (currentService) {
                this.baseServices.push(currentService);
            }.bind(this));

            // sort
            this.baseServices = this._.sortBy(this.baseServices, function(serviceViewModel){
                return serviceViewModel.order ? serviceViewModel.order : 0;
            });
        },

        deletePackage: function deletePackage() {

            var deleteConfirmed = function deleteConfirmed() {

                this.deleting = true;
                var analyticsArgs = this.packageModel.getAnalyticsArgs();
                analyticsArgs.source = 'File';
                this.packageModel.deletePackage().then(
                    function success() {

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.package_deleted_from_file, analyticsArgs);
                    }.bind(this),
                    function error() {

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.package_deleted_from_file_error, analyticsArgs);
                    }.bind(this)
                ).finally(function done() {
                    this.deleting = false;
                }.bind(this));
            };

            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                'PROPOSAL.PACKAGES.ALERT._CONFIRM_DELETE_',
                deleteConfirmed.bind(this));
        },

        updatePackage: function updatePackage() {
            return this.packageModel.updatePackage(this);
        },

        updatePackageQuantity: function updatePackageQuantity() {
            return this.packageModel.updatePackageQuantity();
        },

        updatePackageSelection: function updatePackageSelection() {
            if(this.isOwnerMode) {
               return;
            }
            return this.packageModel.updatePackageSelection(this.packageModel.selected);
        },

        shouldShowPackageDescription: function shouldShowPackageDescription() {
            return this.isEditMode() || (this.packageModel.description);
        },

        isEditMode: function isEditMode() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        isOwnerAndNotPreviewMode: function isOwnerAndNotPreviewMode() {
            return this.isOwnerMode && !this.isPreviewMode;
        },

        shouldShowPrice: function shouldShowPrice() {
            return true; this.isEditMode() || (this.packageModel.base_price !== undefined && this.packageModel.base_price !== 0) || this.shouldShowQuantityViewSection();
        },

        shouldShowTaxViewSection: function shouldShowTaxViewSection() {
            return this.isEditMode() || (this.packageModel.taxable && this.packageModel.base_price > 0);
        },

        shouldShowSvcViewSection: function shouldShowSvcViewSection() {
            return this.isEditMode() || (this.packageModel.svcable && this.packageModel.base_price > 0);
        },

        shouldShowQuantityViewSection: function shouldShowQuantityViewSection() {
            return this.isEditMode() ||
                this.isQuantityEditable() ||
                !isNaN(parseInt(this.packageModel.quantity)) &&
                (this.packageModel.price_per_unit !== undefined && this.packageModel.price_per_unit);
        },

        shouldShowUnitViewSection: function shouldShowUnitViewSection() {
            return this.isEditMode() || !!this.packageModel.unit;
        },

        onUnitSelect: function onUnitSelect(item) {
            this.packageModel.unit = item.key;
            this._setUnit(item.key);
            this.updatePackage();
        },

        _initializeUnits: function __initializeUnits() {

            this._setUnit(this.packageModel.unit);

            var none = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._NONE_');
            var item = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._ITEM_');
            var hour = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._HOUR_');
            var day = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._DAY_');
            var week = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._WEEK_');
            var month = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._MONTH_');

            this.packageUnits = [
                { label: none, key: null },
                { label: item, key: item },
                { label: hour, key: hour },
                { label: day, key: day },
                { label: week, key: week },
                { label: month, key: month }
            ];
        },

        _setUnit: function _setUnit(unit) {
            this.packageUnit = {
                label: unit
            };
        },

        /////////////////////////
        // Drag & Drop
        ////////////////////////
        onDragStop: function onDragStop(event, ui) {

            // This event is triggered when an item
            // from this package is dropped.
            // It can be dropped inside this list or
            // Inside another package or the parent proposal

            try {

                // check if dropped inside this package
                var sortableModel = ui.item.sortable;
                var model = sortableModel.model;

                // if received, it means the item was dropped
                // outside of the current list
                if(sortableModel.received) {
                    return;
                }

                // check if item index was changed
                var isIndexChanged = !!(sortableModel.dropindex || sortableModel.dropindex === 0);

                // if it's dropped inside, we do simple reorder
                if(isIndexChanged) {
                    this.updateOrder();
                }

            } catch(e) {
                this.$exceptionHandler(e);
            }

        },

        onDragUpdate: function onDragUpdate(event, ui) {

            // This event is triggered when there is
            // an update of any kind (move, add, remove)
            // on this list. We will take car here only
            // the add state.
            // Simple move is handled in the stop handler

            try {

                // make sure dropped item is a service before updating
                var sortableModel = ui.item.sortable;
                var model = sortableModel.model;
                var isService = (model && model.isModelOf("Service"));

                if(!isService || !sortableModel.received) {
                    return;
                }

                // reorder and move item
                this.updateOrderAndMoveService(model);

            } catch(e) {
                this.$exceptionHandler(e);
            }
        },

        updateOrder: function updateOrder() {

           // update order on services
            this.baseServices.forEach(function (serviceModel, index) {
                serviceModel.order = index;
            }.bind(this));

            // update the server with the new order
            this.packageModel.updateServicesOrder(this.fileModel, this.packageModel)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.service_drag_in_package_ended, this.packageModel.getAnalyticsArgs());
                }.bind(this))
                .catch(function error() {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PROPOSAL._SERVICE_REORDER_ERROR_');
                    this.AnalyticsService.trackError(this,this.AnalyticsService.analytics_events.service_drag_in_package_error, this.packageModel.getAnalyticsArgs());
                }.bind(this));
        },

        updateOrderAndMoveService: function updateOrderAndMoveService(service) {

            // the source can be another package or a parent proposal
            // if the parent is proposal, we keep the source empty
            var sourceId = service.__package ? service.__package._id : null;
            var destId = this.packageModel._id;

            this.packageModel.moveService(service._id, sourceId, destId, this.baseServices)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.service_drag_in_package_ended, this.packageModel.getAnalyticsArgs());
                }.bind(this))
                .catch(function error(err) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PROPOSAL._SERVICE_REORDER_ERROR_');
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.service_drag_in_package_error, this.packageModel.getAnalyticsArgs());
                }.bind(this));
        },

        calculatePrice: function calculatePrice(clearIfNeeded) {
            this.packageModel.price_per_unit = this.pricePerUnit;
            if (_.isNumber(this.packageModel.price_per_unit)) {
                this.packageModel.base_price = !this._.isNumber(this.packageModel.quantity) ? this.packageModel.price_per_unit : (this.packageModel.price_per_unit * this.packageModel.quantity).toFixed(2);
            } else if (clearIfNeeded) {
                this.packageModel.base_price = 0;
            }

            if(this.isEditMode()) {
                this.updatePackage();
            } else {
                this.updatePackageQuantity();
            }
        },

        _setPricePerUnit: function _setPricePerUnit() {
            if (_.isNumber(this.packageModel.base_price) && !_.isNumber(this.packageModel.price_per_unit)) {
                if (!this._.isNumber(this.packageModel.quantity)) {
                    this.pricePerUnit = this.packageModel.base_price;
                } else if (this.packageModel.base_price % this.packageModel.quantity === 0) {
                    this.pricePerUnit = this.packageModel.base_price / this.packageModel.quantity;
                }
            } else {
                this.pricePerUnit = this.packageModel.price_per_unit;
            }
        },

        isSelectionDisabled: function isSelectionDisabled() {
            return (this.isPreviewMode || (this.fileModel && (this.fileModel.isComplete() || this.fileModel.isCanceled())));
        },

        isQuantityEditable: function isQuantityEditable() {

            var isFile = !!(this.fileModel && this.fileModel.isModelOf('WorkspaceFile'));
            var isFileCanceled = isFile && this.fileModel.isCanceled();
            var isFileCompleted = isFile && this.fileModel.isComplete();

            if(isFileCanceled) {
                return false;
            }

            if(this.isEditMode()) {
                return !this.isQuantityEditableByClient();
            }

            return (!this.isOwnerMode && !isFileCompleted && this.isQuantityEditableByClient());
        },

        isQuantityEditableByClient: function isQuantityEditableByClient() {
            return this.packageModel.canClientUpdateQuantity();
        },

        onDragHandleMouseDown: function onDragHandleMouseDown() {
            // When dragging a package, dropping into services list should be disabled
            // This is re-enabled in proposal directive on drag stop
            this.$('.hb-package__services').sortable("disable");
        },
    });

    return {
        scope: {
            fileModel: '=fileModel',
            packageModel: "=packageModel",
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            shouldShowMenu: '@shouldShowMenu',
            company: '=company',
            isSelectable: '=?',
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/package/package_directive_new_template.html',
        controller: PackageDirectiveController,
        controllerAs: 'packageVm',
        bindToController: true
    };
};
