// Module for all reusable UI components.
// TODO(sean): Move all UI components out of common into here
common_components_module = angular.module('honeybook.common.components', [
  // TODO(sean): create modules for each component, this
  // makes mocking out dependencies much simpler for unit testing
  'honeybook.common.components.todos.todo_list'
]);

// General UI Components
common_components_module.component('hbDivider', Components.DividerComponent);
common_components_module.component('hbMenu', Components.Menu);
common_components_module.component('hbMedia', Components.Media);

// Form Components
common_components_module.component('hbDateInput', Components.DateInput);
common_components_module.component('hbTextInput', Components.TextInput);
common_components_module.component('hbPhoneInput', Components.PhoneInput);
common_components_module.component('hbEmailInput', Components.EmailInput);
common_components_module.component('hbPasswordInput', Components.PasswordInput);
common_components_module.component('hbSelectInput', Components.SelectInput);
common_components_module.component('hbInlineSvg', Components.InlineSvg);

// TODO(sean): Move following feature-specific, but stateless compoennts
// to core.features.components or core.features.<feature>?
// They are meant to be stateless/dumb and reusable, but they are closely
// related to specific areas of the app.

// Header View Component
common_components_module.component('hbHeaderView', Components.HeaderView);
// Simple Variation of Header view
common_components_module.component('nxNavbarView', Components.NavBarView);

// Mobile Footer View Component
common_components_module.component('hbMobileFooterView', Components.MobileFooterView);

// Tab Component
common_components_module.component('hbTabs', Components.Tabs);
common_components_module.component('hbTabsPane', Components.TabsPane);

//Notification Banner
common_components_module.component('hbNotificationBanner', Components.NotificationBanner);


// Registration Components
common_components_module.component('hbIntakeForm', Components.IntakeForm);

// Report Components
common_components_module.component('hbReportModule', Components.ReportModule);
common_components_module.component('hbReportModuleTabbed', Components.ReportModuleTabbed);
common_components_module.component('hbReportMetric', Components.ReportMetric);

//Burger Icon
common_components_module.component('hbBurgerClose', Components.BurgerCloseMenu);

//Tooltip HTML Template
common_components_module.component('nxTippyTemplate', Components.TippyTemplate);

//Filter tables
common_components_module.component('nxSortColumn', Components.SortColumn);

// ClientBot Tooltip
common_components_module.component('hbClientBotTooltip', Components.ClientBotTooltip);

// HB Logos
common_components_module.component('hbLogo', Components.HbLogo);
common_components_module.component('hbJuly4thLogo', Components.HbJuly4thLogo);
common_components_module.component('hbPrideLogo', Components.HbPrideLogo);
common_components_module.component('hbNormalLogo', Components.HbNormalLogo);
common_components_module.component('hbWinterLogo', Components.HbWinterLogo);
common_components_module.component('hbUsaFlagLogo', Components.HbUsaFlagLogo);
common_components_module.component('hbBlackLivesMatter', Components.HbBlackLivesMatter);
common_components_module.component('hbPrideBlm', Components.HbPrideBLM);

common_components_module.component('hbCollapsedAvatars', Components.CollapsedAvatars);


common_components_module.component('hbPopoverSelectEmailTemplate', Components.PopoverSelectEmailTemplate);


// chips checklist
common_components_module.component('hbChipsChecklist', Components.ChipsChecklist);

common_components_module.component('hbReactModal', Components.ReactModal);
common_components_module.component('hbHoneybookSplashScreen', Components.HoneyBookSplashScreen);

common_components_module.component('hb3dotsLoader', Components.Loader3Dots);

common_components_module.component('hbTwoStateButton', Components.TwoStateButton);

common_components_module.component('hbContactFormOnboarding', Components.ContactFormOnboarding);

// HBIcon
common_components_module.component('hbIcon', Components.HBIcon);
common_components_module.component('hbFilter', Components.HBFilter);

//aside menu
common_components_module.component('asideMenu', Components.AsideMenu);
common_components_module.component('hbBreadcrumbs', Components.BreadcrumbsComponent);
common_components_module.component('hbSidebarMenu', Components.SidebarMenu);


// progress bar
common_components_module.component('hbProgressBar', Components.ProgressBar);

// Error Label
common_components_module.component('hbLabelError', Components.LabelError);

common_components_module.component('hbFullPageLoader', Components.FullPageLoader);

// Feature Onboarding
common_components_module.component('hbPipelineOnboarding', Components.PipelineOnboarding);

// Pro Tip
common_components_module.component('hbProTip', Components.ProTip);
common_components_module.component('hbDotsLoader', Components.Loader3Dots);

// Helper Banner (aka "Blue Banner")
common_components_module.component('hbHelperBanner', Components.HelperBanner);

common_components_module.component('hbDuration', Components.Duration);

