/**
 * http://stackoverflow.com/questions/25600071/how-to-achieve-that-ui-sref-be-conditionally-executed
 * eat-click-if="!file.isAccessible()"
 * the if is when to disable. strange.
 * used in left panel to disable active request proposals.
 */

// @ngInject
Directives.EatClickIfDirective = function EatClickIfDirective($rootScope, $parse) {
    return {
        restrict: 'A',
        // this ensure eatClickIf be compiled before ngClick
        priority: 100,
        compile: function($element, attr) {
            var fn = $parse(attr.eatClickIf);
            return {
                pre: function link(scope, element) {
                    var eventName = 'click';
                    element.on(eventName, function(event) {
                        var callback = function() {
                            if (fn(scope, {$event: event})) {
                                // prevents ng-click to be executed
                                event.stopImmediatePropagation();
                                // prevents href
                                event.preventDefault();
                                return false;
                            }
                        };
                        if ($rootScope.$$phase) {
                            scope.$evalAsync(callback);
                        } else {
                            scope.$apply(callback);
                        }
                    });
                },
                post: function() {}
            }
        }
    }
};


