
// @ngInject

Models.QuestionnairesCollection = function QuestionnairesCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'QuestionnairesCollection';
                this._collection.__childsType = 'QuestionnaireModel';
                return this._collection;
            }
        }
    );

};