(function () {
    "use strict";

    // @ngInject
    function NotificationServiceCtor() {

    }

    Services.NotificationService = Class(function () {

        return {

            constructor: NotificationServiceCtor,
            updateSuccess: function updateSuccess() {

                this._clearNotifications();

                if (!this.user.system_notifications) {
                    return;
                }

                for (var i = 0; i < this.user.system_notifications.length; i++) {
                    switch (this.user.system_notifications[i].notification) {
                        case 'missing_bank_account':
                            this.missing_bank_account = true;
                            break;

                        case 'missing_ssn':
                            this.missing_ssn = true;
                            break;

                        case 'missing_setup_assets':
                            this.missing_setup_assets = true;
                            break;

                        case 'vendor_referral_feature':
                            this.vendor_referral_feature = true;
                            break;

                        case 'missing_identification_document':
                            this.missing_identification_document = true;
                            break;
                    }
                }
            },

            _clearNotifications: function _clearNotifications() {
                this.missing_bank_account = false;
                this.missing_ssn = false;
                this.missing_setup_assets = false;
                this.vendor_referral_feature = false;
                this.missing_identification_document = false;
            },

            reset: function reset(user) {
                this.user = user;
                this.user.on('success', this.updateSuccess, this);
                this.updateSuccess();
            }
        };
    });
}());
