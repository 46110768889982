
(function () {
    'use strict';

    class ClientPortalPreviewLogin extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, UserService,$window) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalPreviewLogin';

            this.UsersManager = UsersManager;
            this.UserService = UserService;

            this.showForm = true;
            this.showForgotPassword = false;
            if($window.clientPortalConfiguration){
                this.isPoweredByHb = $window.clientPortalConfiguration.is_powered_by_hb;
            }
        }

        onForgotPasswordClicked() {
            this.showForm = false;
            this.showForgotPassword = true;
        }

        onBackClicked() {
            this.showForm = true;
            this.showForgotPassword = false;
        }

        onLoginClicked(event) {
            event.preventDefault();

            if (this.previewOnly) {
                return;
            }

            this.loggingIn = true;
            this.loginError = false;

            this.UsersManager.login(this.user).then(() => {
                this.UserService.onClientPortalLogin(this.user);
            }).catch(() => {
                this.loginError = true;
            }).finally(() => {
                this.loggingIn = false;
            });
        }
    }


    Components.ClientPortalPreviewLogin = {
        bindings: {
            portalModel: '<',
            user: '<?',
            previewOnly: '@?'
        },
        controller: ClientPortalPreviewLogin,
        name: 'hbClientPortalPreviewLogin',
        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/preview/login/client_portal_preview_login.html'
    };

}());