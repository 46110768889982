(function () {
    'use strict';

    // @ngInject
    function TimeTrackingServiceCtor(TimeTrackingManager, $timeout, moment) {
        this.TimeTrackingManager = TimeTrackingManager;
        this.$timeout = $timeout;
        this.moment = moment;
    }

    Services.TimeTrackingService = Class({
        constructor: TimeTrackingServiceCtor,

        getTotalAmount: function getTotalAmount(timeTrackerEntryList) {
            return timeTrackerEntryList.reduce(function(prev, next){
                return prev + next.total_amount;
            }, 0);
        },

        getTotalHours: function getTotalHours(timeTrackerEntryList) {
            var totalHours = timeTrackerEntryList.reduce(function(prev, next){
                return prev + next.hours;
            }, 0);

            return Math.ceil(totalHours * 100) / 100;
        },

        downloadCSV: function downloadCSV($event, company, event, params) {
            return this.TimeTrackingManager.downloadCSV(company, event, params)
                .then(function(resp) {
                    var div = angular.element($event.currentTarget);
                    div.parent().append('<a id="temp_link"></a>');
                    var link = div.parent().find("#temp_link");
                    link.attr("href", resp);
                    this.$timeout(function () {
                        link[0].click();
                        link.remove();
                    }.bind(this), 10);
                }.bind(this));
        },

        formatDate: function formatDate(date) {
            //NOTE: if use pipe filter date is off by one day
            if (!date) {
                return;
            }

            if (this.moment(date, 'YYYY-MM-DD').isValid()) {
                return this.moment(date, 'YYYY-MM-DD').format('ll');
            }

            return '';
        },

        formatDateShort: function formatDateShort(date) {
            //NOTE: if use pipe filter date is off by one day
            if (!date) {
                return;
            }

            if (this.moment(date, 'YYYY-MM-DD').isValid()) {
                return this.moment(date, 'YYYY-MM-DD').format('l');
            }

            return '';
        },
    });
}());
