(function () {
    "use strict";

    Controllers.ContactFormMembersController = class ContactFormMembersController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, CompaniesManager, Enums, PopupMessageService, _, contactForm, ContactFormManager, AnalyticsService ,$translate) {
            super($scope, $injector);
            this.__objectType = 'ContactFormMembersController';

            this.modalInstance = $modalInstance;
            this.contactForm = contactForm;
            this.Enums = Enums;
            this.PopupMessageService = PopupMessageService;
            this._ = _;
            this.ContactFormManager = ContactFormManager;
            this.AnalyticsService = AnalyticsService;

            this.roleOptions = Object.keys(Enums.contactFormMembers).map((roleKey) => {
                const role = Enums.contactFormMembers[roleKey];
                return {
                    key: role.key,
                    icon: role.icon,
                    label: $translate.instant(role.translationEntry),
                    subtext: $translate.instant(role.subtextTranslationEntry)
                };
            });

            this.roleOptions = this.roleOptions.sort((a, b) => (a.seniorityScore > b.seniorityScore ? 1 : -1));

            this.company = CompaniesManager.getCurrCompany();
            this.prepareMembersData();
        }

        close() {
            this.modalInstance.close();
        }

        dismiss() {
            if (this.hasChanges()) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'You have unsaved changes.\nDo you want to discard your changes?',
                    () => {
                        this.modalInstance.dismiss()
                    },null,'DISCARD CHANGES');
            } else {
                this.modalInstance.dismiss();
            }
        }

        hasChanges() {
            let selectedMembers = this.teamMembers.filter(m => m.selected);
            let previousMemberIds = this.contactForm.getMemberIds(this.company);

            if (selectedMembers.length !== previousMemberIds.length) {
                return true;
            }

            for (let i=0; i < selectedMembers.length; i++) {
                let member = selectedMembers[i];
                let previousRole = this.getMemberRole(member.user);
                if (!previousRole || previousRole.key !== member.role.key) {
                    return true;
                }
            }
            return false;
        }

        saveChanges() {
            this.lastError = null;
            if (this.hasChanges()) {
                if (this.isValidSelection()) {
                    let selectedMembers = this.teamMembers.filter(m => m.selected);
                    let params = selectedMembers.map(m => {
                        return {user_id: m.user._id, role: m.role.key};
                    });
                    this.AnalyticsService.trackClick(this, "save multiple recipients selection", {number: selectedMembers.length});
                    this.ContactFormManager.updateContactFormMembers(this.contactForm, params).then(this.close.bind(this)).catch((resp) => {
                        this.lastError = "Oops, there was a problem saving the settings, please try again.";
                    });
                }
            } else {
                this.close();
            }
        }

        isValidSelection() {
            let numberOfOwners = this.teamMembers.filter(m => m.selected && m.role && m.role.key === 'owner').length;
            if (numberOfOwners !== 1) {
                let message = 'The contact form should have exactly one project owner.';
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, message);
                return false;
            } else if (this.teamMembers.some(m => m.selected && !m.role)) { //should not happen
                let memberWithoutRole = this._.find(this.teamMembers, m => m.selected && !m.role);
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, "No option selected for " + memberWithoutRole.user.full_name);
                return false;
            }
            return true;
        }

        prepareMembersData() {
            if (!this.teamMembers) { //initial run
                this.teamMembers = [];
                this.teamMembers.push({user: this.company.owner});
                this.company.team_members.forEach(member => {
                    if (member._id !== this.company.owner._id) {
                        this.teamMembers.push({user: member});
                    }
                });
            }

            const defaultRoleOption = this._getRoleOption(
                this.Enums.contactFormMembers.recipient
            );

            this.teamMembers.forEach(member => {
                let newRole = this.getMemberRole(member.user);
                if (newRole) {
                    member.role = newRole;
                    member.selected = true;
                } else {
                    member.selected = false;
                    member.role = defaultRoleOption;
                }
            });

            this.teamMembers = this._(this.teamMembers)
                .chain()
                .sortBy(m => m.user.first_name)
                .sortBy(m => {
                    const { key } = m.role || {};
                    const { seniorityScore } = this.Enums.contactFormMembers[key] || {};
                    return -seniorityScore;
                })
                .sortBy(m => !m.selected)
                .value();
        }

        getMemberRole(user) {
            if (user._id === this.contactForm.vendor_id_for_inquiries) {
                return this._getRoleOption(this.Enums.contactFormMembers.owner);
            }

            let memberObj = this.contactForm.inquiry_members.find(
                inquiryMember => user._id === inquiryMember.user_id
             );

            if (memberObj) {
                return this._getRoleOption(this.Enums.contactFormMembers[memberObj.role]);
            } else {
                return null;
            }
        }

        selectRole(member, item) {
            const roleKey = item.key;
            if (roleKey === 'owner' && !(member.role && member.role.key === 'owner')) {
                //"downgrade" old owner
                let oldOwner = this.teamMembers.find(m => m.role && m.role.key === 'owner');
                if (oldOwner) {
                    oldOwner.role = this._getRoleOption(
                        this.Enums.contactFormMembers.workspace_member
                    );
                }
            }

            member.role = item;
            member.selected = true;
        }

        // on member unselect, init back to leads recepient
        onSelectedChange(member) {
            if (!member.selected) {
                member.role = this._getRoleOption(this.Enums.contactFormMembers.recipient);
            }
        }

        _getRoleOption(enumValue) {
            return this.roleOptions.find(
                opt => opt.key === enumValue.key
            );
        }
    };
}());
