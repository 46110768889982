(function () {
    "use strict";

    Services.InjectBrandingService = class InjectBrandingService {

        // @ngInject
        constructor(PubSubService, CssVariablesService, UIUtils) {
            this._styles = [];
            PubSubService.ventMyBitchUp(this);
            this.CssVariablesService = CssVariablesService;
            this.UIUtils = UIUtils;
        }

        registerStyles(colors, cssSelectors, context) {
            this._styles.push({colors: colors, cssSelectors: cssSelectors, context: context});
            this.trigger('BrandStylesChange');
        }

        unregisterStyles(context) {
            let item = this._styles.filter(function(style) { return style.context === context; });
            if(item) {
                let index = this._styles.indexOf(item[0]);
                if (index !== -1) {
                    this._styles.splice(index, 1);
                }
            }
            this.trigger('BrandStylesChange');
        }

        getStyles() {
            let stylesCss = "";
            this._styles.forEach(function(style) {

                stylesCss += style.cssSelectors + "{";
                if(style.colors.text_color) {
                    stylesCss += "color:" + style.colors.text_color + " !important;";
                }

                if(style.colors.brand_color) {
                    stylesCss += "background-color:" + style.colors.brand_color + " !important;";
                    stylesCss += "border-color:" + style.colors.brand_color + " !important;";
                }

                stylesCss += "}";

            });

            return stylesCss;
        }

        setBrandColorVars(color){

            let colorToSet = this.UIUtils.hexToRgbVals(color) || color || '';
            this.CssVariablesService.setStyle('companyBrandColor', colorToSet);
        }
    };
}());
