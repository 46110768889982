(function () {
    "use strict";
// class PasswordInputController extends Components.InputBaseController {

// TODO: REmoveX
// @ngInject
function PasswordInputControllerCtor(
        $scope, $element, $attrs, $compile, $translate, _, $window) {

    this.constructor.$super.call(this, $scope, $element, $attrs, $compile, $translate, _, $window);

    this.__objectType = 'PasswordInputController';
    this.isRequired = false;
    this.validationError = '';
    this._ = _;
    this.$translate = $translate;

    this.modelOptions = {
        allowInvalid: true
    };

}

// TODO(sean): Remove and replace with Es6
var PasswordInputController = Class(Components.InputBaseController, {
    // TODO: Delete and replace with $onInit()
    constructor: PasswordInputControllerCtor,

    // $onInit() {
    $onInit: function $onInit() {
        // super.$onInit();
        // super.validate();
        this.constructor.$superp.$onInit.call(this);
        this.validate();
        this.textInputVariant = 'password';
        this.cssClass['form-element--password'] = true;

        // Confusing, but this is used to set the initial value of the
        // ng-form HTML element and then after initialization
        // "formName" represents that DOM element.
        //this.formName = `${this.name}Form`;
        this.formName = this.name + 'Form';

        // TODO(sean): Password strenth checking here
    },

    // onChange() {
    onChange: function onChange() {
        // super.onChange();
        // super.validate();
        this.constructor.$superp.onChange.call(this);
        this.validate();
    },

    // shouldShowError() {
    shouldShowError: function shouldShowError() {
        return (this.validationError !== ''
            && (this.formName[this.name].$dirty
                || this.formName.$$parentForm.$submitted)
        );
    },

    // validate() {
    validate: function() {
        var baseValidation = this.constructor.$superp.validate.call(this);

        var passwordValidation = true;
        if (this.value && this.minimumLength) {
            if (this.value.length < this.minimumLength) {
                this.validationError = this.$translate.instant(
                    'ERRORS._MIN_LENGTH_PASSWORD_') + this.minimumLength + ' characters';
                passwordValidation = false;
            }
        }

        // TODO: password security checking here...

        if (!baseValidation || !passwordValidation) {
            return false;
        }
    },

});

Components.PasswordInput = {
    templateUrl: 'angular/app/modules/common/ui_components/form/text_input.html',
    controller: PasswordInputController,

    // Requiring ngModel is a hack that allows us access to the
    // ng form API ($dirty, $valid, $isSubmitted, etc), while still preserving
    // the essence of a unidirectional data flow (e.g. Container -> form -> inputs)
    require: {
        model: 'ngModel'
    },

    bindings: {
        flavor: '@',
        label: '@',
        caption: '@',
        maximumLength: '@',
        minimumLength: '@',
        name: '@',
        tabIndex: '@',
    }
};

}());
