(function () {
    'use strict';

    class FlowsOnboardingWizardLegacyServicesController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            SelfOnboardingManager,
            CompaniesManager,
            UsersManager,
            _,
            TemplatesManager,
            ModalService,
            Gon,
            TemplatesRepositoryManager,
            UiPersistenceService,
            EventService,
            $translate,
            $timeout
        ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardLegacyServicesController';

            this.$scope = $scope;
            this.SelfOnboardingManager = SelfOnboardingManager;
            this.ModalService = ModalService;
            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.TemplatesManager = TemplatesManager;
            this.Gon = Gon;
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this.UiPersistenceService = UiPersistenceService;
            this.$injector = $injector;
            this.EventService = EventService;
            this.$translate = $translate;
            this.$timeout = $timeout;

            this._ = _;

        }

        getSelectedServices() {
            const selectedServices = this.servicesList.filter(service => service.selected);
            const services = this._.map(selectedServices, function (service) {
                return {name: service.name};
            });
            return services;
        }

        onServiceClicked(service) {
            const selectedServices = this.getSelectedServices();
            this.selectedServiceKey = selectedServices.map(selectedService => selectedService.name).join('#');
        }

        $onInit() {
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));

            this.user = this.UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
            this.servicesList = this.getServiceListByCompanyType(this.company.company_type);

            this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_`);
            this.subTitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_`);
            this.titleLink = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SECONDARY_TITLE_LINK_`);
            this.nudgeText = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._NUDGE_TEXT_`);
        }

        $onChanges(changes) {
            // This is our pseudo $onInit condition
            if(changes.currentStep && changes.currentStep.currentValue.key === this.thisStepKey) {
                this.$timeout( () => {
                    this.isStepValid = true;
                }, 1000);
            }
        }

        onComplete(event, currStep) {
            if (currStep.key === this.thisStepKey) {
                // do something when user click next on services step
                const selectedServices = this.getSelectedServices();
                this.currUIPersistedData = Object.assign({}, this.currUIPersistedData, {[this.thisStepKey]: selectedServices, flow_templates: null});
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, this.currUIPersistedData);
                this.showTemplatesAndSelectOne();
            }
        }
        
        openExplainServicesModal() {
            this.ModalService.openInteractiveOnboardingExplainServicesModal();
        }

        getServiceListByCompanyType(companyType) {
            const CompanyTypeToServiceList = this.Gon.company_type_to_service_list;
            return CompanyTypeToServiceList[companyType] || CompanyTypeToServiceList["other"];
        }

        // Start: Code for legacy_like variant based on code from Templates step.

        getFlowTemplateByServiceKey() {
            return this.SelfOnboardingManager.getOnboardingFlowTemplates(this.user).then(response => {
                return response.data;
            });
        }

        setSelectedFlow(flowTemplateId, flowTemplateUrl, flowTemplateTitle) {
            this.selectedFlowTemplateId = flowTemplateId;
            this.selectedFlowTemplateImageUrl = flowTemplateUrl;
            this.selectedFlowTemplateTitle = flowTemplateTitle;
        }

        onSubmitTemplateStep() {
            this.TemplatesRepositoryManager.importTemplates(this.company, {id: [this.selectedFlowTemplateId]})
                .then((data) => {
                    const projectData = this.EventService.createDefaultProject(this.company, this.user.first_name + '’s test project').dataOnly();
                    projectData.event_source = 'onboarding';
                    const selectedServices = this.getSelectedServices();
                    this.SelfOnboardingManager.updateOnboardingFlowTemplate(this.user, data.data.imported_templates[0]._id, selectedServices)
                        .then(() => {
                            const promise = this.TemplatesManager.createFlowInstanceWithProject(data.data.imported_templates[0]._id, projectData);
                            promise.then((resp) => {

                                this.flowInstanceId = resp.data._id;
                                const persistedData = {flowInstanceId: this.flowInstanceId, selectedFlowTemplateId: this.selectedFlowTemplateId, selectedFlowTemplateImageUrl: this.selectedFlowTemplateImageUrl, selectedFlowTemplateTitle: this.selectedFlowTemplateTitle};
                                this.currUIPersistedData = Object.assign({}, this.currUIPersistedData, {[this.thisStepKey]: persistedData});
                                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, this.currUIPersistedData);
                            });
                        });
                });
        }

        showTemplatesAndSelectOne() {

            this.getFlowTemplateByServiceKey().then(templatesArray => {
                if (templatesArray && templatesArray.length > 0) {
                    const firstTemplate = templatesArray[0];
                    this.setSelectedFlow(firstTemplate._id, firstTemplate.header_image.cloudinary_public_id, firstTemplate.title);
                    this.onSubmitTemplateStep();
                }

            });
        }

        // End: Code for legacy_like variant based on code from Templates step.
    }

    Components.FlowsOnboardingWizardLegacyServices = {
        controller: FlowsOnboardingWizardLegacyServicesController,
        bindings: {
            onValidation: '&',
            currentStep: '<',
            stepTranslationMap: '<',
            thisStepKey: '@',
            isStepValid: '=',
            flowInstanceId: '=',
            selectedFlowTemplateImageUrl: '=',
            selectedFlowTemplateId: '=',
            selectedServiceKey: '=',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/services/flows_onboarding_wizard_legacy_services.html',

    };
}());