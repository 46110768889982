Directives.PageTitleDirective = function PageTitleDirective() {

    // @ngInject
    function PageTitleDirectiveControllerFunc($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PageTitleDirectiveController';
    }

    var PageTitleDirectiveController = Class(Controllers.BaseController, {
        constructor: PageTitleDirectiveControllerFunc,
    });

    return {
        scope: {
            title: '@',
            loading: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/top_nav/page_title/page_title_template.html',
        controller: PageTitleDirectiveController,
        controllerAs: 'pageTitleVm',
        bindToController: true
    };
};
