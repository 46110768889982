(function () {
    "use strict";

    // @ngInject
    function DynamicContentSectionControllerFunc($injector, $scope, Enums, _, $exceptionHandler, UiPersistenceService, DeviceService, AnalyticsService,
                                                 $window, PopupMessageService, $timeout, ContactFormService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'DynamicContentSectionController';

        this.$exceptionHandler = $exceptionHandler;
        this._ = _;
        this.Enums = Enums;
        this.UiPersistenceService = UiPersistenceService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;
        this.PopupMessageService = PopupMessageService;
        this.DeviceService = DeviceService;
        this.$timeout = $timeout;
        this.ContactFormService = ContactFormService;

        this.blockItems = [];
        this.sortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.js-drag--brochure-block__drag-item',
            disabled: false,
            stop: this.onDragStop.bind(this)
        };

        this._init();

        $scope.$parent.$watch('brochureNewVm.showTooltipTitleNav', function(newVal, oldVal){
            if (newVal !== oldVal || !newVal) {
                this._shouldShowTooltipContentType();
            }
        }.bind(this));

        $scope.$watch('dynamicContentSectionVm.section', function(newVal, oldVal){
            this._onSectionChange();
        }.bind(this));

        this.register(this.fileModel, 'success', function() {
            this._onSectionChange();
        }.bind(this));
    }

    var DynamicContentSectionController = Class(Controllers.BaseController, {
        constructor: DynamicContentSectionControllerFunc,

        _init: function _init() {
            this.activeBlock = null;

            if ( this.section && this.section.file_blocks.length > 0 ) {
                this._buildSectionModel();
            }
        },

        _onSectionChange: function _onSectionChange() {
            this._buildSectionModel();
        },

        _buildSectionModel: function _buildSectionModel() {
            this.blockItems.splice(0);

            this.section.file_blocks.forEach(function (item) {
                this.blockItems.push(item);
            }.bind(this));

            this.blockItems = this._sortByOrder(this.blockItems);
            this.blockItems.forEach(function (block, index) {
                block.index = index;
            });
        },

        _sortByOrder: function _sortByOrder(collection) {
            return this._.sortBy(collection, function(e){
                return e.order ? e.order : 0;
            });
        },

        isEditable: function isEditable() {
            return this.isEditableMode;
            //return !this.isClientMode && this.isEditable;
        },

        isBlockDeletable: function isBlockDeletable(block) {
            return (block.isDeletable() && this.isEditable());
        },

        isProposalSelectable: function isProposalSelectable() {
            // proposal should be selectable when:
            // 1. For client - in all sections except the review section
            // 2. For vendor - when in preview and in all sections except the review section
            return (this.isClientMode || this.isPreviewMode);
        },
        isElementInViewport: function isElementInViewport(domElement) {
            var rect = domElement.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },

        ////////////////////////////////
        // CRUD block
        ///////////////////////////////
        showAddBlock: function showAddBlock(block) {
            this.activeBlock = block;

            this.$timeout(function() {
                var addBlockElement = document.querySelector('.brochure-add-block__add-container');
                if (!this.isElementInViewport(addBlockElement)) {
                    if (!this.DeviceService.isSafari() && !this.DeviceService.isEdge()) {
                        document.querySelector('.add-block').scrollIntoView({ behavior: 'smooth', block: 'end'});
                    } else {
                        document.querySelector('.add-block').scrollIntoView(false);
                    }
                }
            }.bind(this));
        },

        hideAddBlock: function hideAddBlock() {
            this.activeBlock = null;
        },

        addBlock: function addBlock(blockType) {
            if (this.section.getRootModelType() === 'contact_form') {
                this.ContactFormService.validateCanAddBlock(this.section.getRootModel(), blockType).then(function () {
                    this._addBlockInner(blockType);
                }.bind(this));
            } else {
                this._addBlockInner(blockType);
            }
        },

        _addBlockInner: function _addBlockInner(blockType) {
            var newBlockOrder;

            if (this.activeBlock) {
                newBlockOrder = this.activeBlock.order + 1;
            }

            this.section.addBlock(blockType, newBlockOrder).then(function(activeBlock) {
                this._onSectionChange();

                this.$timeout(function() {
                    var newBlockIndex = activeBlock ? activeBlock.index + 1: 0;
                    var currentSection = angular.element('#brochure-section__' + this.section.id());
                    var currentBlockDynamic = currentSection.find('.brochure-block__dynamic')[newBlockIndex];
                    var currentBlockItem = currentSection.find('.brochure-block__item')[newBlockIndex];
                    var focused = false;

                    // Add blue faded background to new block
                    if (currentBlockDynamic) {
                        var titleInput = currentBlockDynamic.querySelector('.hb-input__input');
                        if (titleInput) {
                            titleInput.focus();
                            focused = true;
                        }
                    }
                    if (currentBlockItem && !focused) {
                        var textInput = currentBlockItem.querySelector('.ck-editor__editable');
                        if (textInput) {
                            textInput.focus();
                            focused = true;
                        }
                    }

                    var mainFocusElement = currentBlockDynamic ? currentBlockDynamic : currentBlockItem;
                    if (mainFocusElement) { // add temp focused class
                        mainFocusElement.classList.add('brochure-block__focus-block');
                        setTimeout(function() {
                            mainFocusElement.classList.remove('brochure-block__focus-block');
                        }, 2500);
                    }
                }.bind(this));
            }.bind(this, this.activeBlock));

            this.activeBlock = null;
        },

        isQuestionBlock: function isQuestionBlock(block) {
            return block && (block.type === "component") && block.block_component && (block.block_component.type === "form");
        },

        _removeBlock: function _removeBlock(block) {

            // optimistic delete
            // remove block before actually deleting it
            this._removeBlockFromView(block);

            block.deleteBlock().then(function () {
                this._onSectionChange();
            }.bind(this));
        },

        deleteBlock: function deleteBlock(block) {

            // Requesting Confirmation to delete this block only if it's a lead_source typed one
            if (this.isQuestionBlock(block) && block.block_component.questions[0].connected_property === "lead_source") {
                // Show confirm message for question blocks

                this.PopupMessageService.showConfirm('none', 'This question captures information about where your leads are coming from and the insights are shared in your reports.\n' +
                    '\n' +
                    'Do you still wish to delete this question?\n',
                    function clickPrimaryCTA() {
                        // do nothing
                    },
                    function clickSecondaryCTA() {
                        this._removeBlock(block);
                    }.bind(this),
                    "cancel",
                    "Yes, delete",
                    "Are you sure?"
                );
            } else {
                // Just remove block for non question block
                this._removeBlock(block);
            }



        },

        _removeBlockFromView: function _removeBlockFromView(block) {
            var blockIndex = this.blockItems.indexOf(block);
            if(blockIndex !== -1) {
                this.blockItems.splice(blockIndex, 1);
            }
        },

        ////////////////////////////////
        // D&D
        ///////////////////////////////
        onDragStop: function onDragStop(event, elem) {
            try {
                var sortableModel = elem.item.sortable;

                if(sortableModel.received) {
                    return;
                }

                var isIndexChanged = !!(sortableModel.dropindex || sortableModel.dropindex === 0);

                if(isIndexChanged) {
                    this._onDraggedUpdateOrder();
                }

            } catch(e) {
                this.$exceptionHandler(e);
            }
        },

        _onDraggedUpdateOrder: function _onDraggedUpdateOrder() {

            var order = this.blockItems.map(function(item, index){
                return {_id: item._id, order: index};
            });

            this.section.updateSectionBlocksOrder(order).then(function() {
                this._onSectionChange();
            }.bind(this));

            this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.dragged_block);
        },

        ////////////////////////////////
        // Tooltip & help
        ///////////////////////////////
        enableBrochureTooltip: function enableBrochureTooltip() {
            return this.enableIntroTooltip && this.isEditable();
        },

        _shouldShowTooltipContentType: function _shouldShowTooltipContentType() {
            var brochureHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.brochureNew) || {};

            if (!brochureHash.brochureNewTooltipNavTitle || brochureHash.brochureNewContentType || this.showTooltipContentType) {
                return;
            }

            this.showTooltipContentType = true;
            brochureHash.brochureNewContentType = true;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.brochureNew, brochureHash);
        },

        closeTooltipContentType: function closeTooltipContentType() {
            this.showTooltipContentType = false;
        },

        goToLearnBrochures: function goToLearnBrochures() {
            this.$window.open('https://help.honeybook.com/using-honeybook-files/sending-and-managing-files/using-honeybook-brochures','_blank');
        },

        ////////////////////////////////
        // Analytics
        ///////////////////////////////

        analyticsProperties: function analyticsProperties() {
            return Object.assign({model_type: this.modelType},this.fileModel.getAnalyticsArgs());
        },

    });

    Components.DynamicContentSection = {
        bindings: {
            section: '<',
            companyModel: '<',
            fileModel: '=?',
            isClientMode: '<',
            isOwnerMode: '<',
            isPreviewMode: '<',
            isEditableMode: '<',
            isEditableByClient: '<',
            title: '@?',
            subtitle: '@',
            modelType: '@?',
            addBlockTitle: '@?',
            addBlockText: '@?',
            enableIntroTooltip: '=?',
            isFieldsStyleCustomizable: '<',
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/section/dynamic_content_section.html',
        controller: DynamicContentSectionController,
        controllerAs: 'dynamicContentSectionVm',
        name: 'hbDynamicContentSection'
    };

}());
