(function () {
    'use strict';

    // @ngInject
    Services.Fingerprint = function Fingerprint($window, $q) {
        var FingerprintJS = $window.FingerprintJS;
        var cachedFingerprint = null;

        return function calcFingerprint() {
            var defer = $q.defer();

            try {

                if (cachedFingerprint) { // check if already saved the result.
                    defer.resolve(cachedFingerprint);
                } else if (Services.Fingerprint._cachedResult) { // check if the calcaulation was done before the service was initialized
                    cachedFingerprint = Services.Fingerprint._cachedResult;
                    delete Services.Fingerprint._cachedResult;
                    defer.resolve(cachedFingerprint);
                } else { // last resort... calculate it
                    var fpPromise = FingerprintJS.load();
                    fpPromise
                        .then(function (fp) {
                            return fp.get();
                        })
                        .then(function (result) {
                            delete result.components.timezone;
                            var visitorId = FingerprintJS.hashComponents(result.components);
                            cachedFingerprint = visitorId;
                            defer.resolve(visitorId);
                        }.bind(this));
                }
            } catch (e) {
                // We don't wanna fail the running process in case the fingerprint fails
                defer.resolve(cachedFingerprint);
            }

            return defer.promise;
        };
    };

}());
