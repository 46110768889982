(function () {
    "use strict";

    // @ngInject

    function FacebookLeadsIntroModalControllerCtor($scope, $injector, AppConfigService, FacebookService,
                                                   _, moment, $translate, AnalyticsService, CompaniesManager, $log, $modalInstance, UsersManager, Enums, PopupMessageService, UserService, $window) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FacebookLeadsIntroModalController';
        this._ = _;
        this.UserService = UserService;
        this.AnalyticsService = AnalyticsService;
        this.$translate = $translate;
        this.$modalInstance = $modalInstance;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.company = CompaniesManager.getCurrCompany();
        this.UsersManager = UsersManager;
        this.FacebookService = FacebookService;
        this.$window = $window;
        this.Enums = Enums;
        this.$log = $log;
        this.loggingIn = false;
        this.showFetchedPages = false;
        this.currUser = this.UsersManager.getCurrUser();
        this.availablePages = [];
        this.full_result = null;
        this.selectedPage = null;
    }


    Controllers.FacebookLeadsIntroModalController = Class(Controllers.BaseController, {
        constructor: FacebookLeadsIntroModalControllerCtor,


        connectFacebook: function connectFacebook() {
            this.loggingIn = true;

            this.UserService.connectFacebook(this.currUser, this.FacebookService.permissions_types.lead_ads)
                .then(function success() {
                    this.loginSuccess();
                }.bind(this))
                .catch(function error() {
                    this.loginError();
                }.bind(this))
                .finally(function () {
                    this.loggingin = false;
                }.bind(this));
        },

        loginSuccess: function loginSuccess() {
            var self = this;
            self.loggingIn = false;
            this.$modalInstance.close('success');
        },

        loginError: function loginError(resp) {
            this.loggingIn = false;
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login, resp);
        },

        close: function close() {
            this.$modalInstance.close();
        },

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },

        openLearnMore: function openLearnMore(){
            this.$window.open("https://www.facebook.com/business/a/lead-ads", '_blank');
        },
    });
}());
