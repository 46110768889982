/**
 * Created by dvircohen on 27/07/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function LinkResolverControllerCtor($scope, $injector, $stateParams, $state,
                                        AnalyticsService, UsersManager, RedirectService, WorkspaceFileService, PopupMessageService,
                                        WorkspaceFilesManager, LinksManager, LinksService, DeviceService,
                                        RepositoryService, Constants, $log, CompaniesManager, ForterHelperService, $window) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LinkResolverController';
        AnalyticsService.trackPageView(this, 'LinkResolverView');
        this.AppsflyerWorkspcaeLink = "https://honeybook.onelink.me/850908038/52cg4ybz";
        this.UsersManager = UsersManager;
        this.$window = $window;
        this.LinksManager = LinksManager;
        this.RedirectService = RedirectService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.$state = $state;
        this.LinksService = LinksService;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.PopupMessageService = PopupMessageService;
        this.RepositoryService = RepositoryService;
        this.CompaniesManager = CompaniesManager;
        this.ForterHelperService = ForterHelperService;
        this.Constants = Constants;
        this.$log = $log;

        this.user = this.UsersManager.getCurrUser() || this.UsersManager.newUser();
        this.linkId = $stateParams.link_id;
        this.linkToken = $stateParams.link_token;
        this.state = $stateParams.state;
        this.stateParams = $stateParams.stateParams;

        this.resolveLink();
    }

    Controllers.LinkResolverController = Class(Controllers.BaseController, {
        constructor: LinkResolverControllerCtor,

        resolveLink: function resolveLink() {
            this.debugState = "Starting. Link ID: " + this.linkId + "; Link token: " + this.linkToken + "; State Params: " + this.stateParams + ";";
            var fToken = this.ForterHelperService.getForterToken();
            this.LinksManager.resolve(this.linkId, this.linkToken, this.stateParams, fToken)
                .then(function resolved(resp) {
                    this.debugState += ". Done first resolve;";
                    var data = resp.data;
                    var link_type = data.type;
                    var link_source = data.link_source;
                    var content = resp.data.content;

                    this.debugState += JSON.stringify(resp);

                    if (content.should_redirect_on_mobile && (this.DeviceService.isIOS() || this.DeviceService.isAndroid())) {
                        this.$log.debug("##[ redirectToMobileApp with content: " + JSON.stringify(content) + " ]##");
                        this.redirectToMobileApp(content);
                    }

                    var result = {
                        redirectionData: this.buildRedirectionData(content, link_type)
                    };

                    var analyticsData = {type: link_type, state: result.redirectionData.state, source: link_source, link_id: this.linkId};
                    var params = result.redirectionData.params;
                    for(var p in params) {
                        if (params.hasOwnProperty(p)) {
                            // Adding "params_" as a prefix so it won't override other properties
                            analyticsData["param_" + p] = params[p];
                        }
                    }
                    this.AnalyticsService.track(this, 'link opened', analyticsData);

                    if (data.action === 'login') {
                        this.UsersManager.setUserLoggedInExternally(resp.data.content.user)
                    }

                    if (data.action === 'redirect') {
                        if (data.redirect_to === 'reset_password') {
                            result.redirectTo = {
                                state: this.AppStates.root_core_resetPassword,
                                params: {
                                    user_id: data.content.user_id,
                                    guid: data.content.guid,
                                    link_source: link_source
                                }
                            };

                            if (result.redirectionData.params.event_id) {
                                result.redirectTo.params.event_id = result.redirectionData.params.event_id; // pass event_id if we have it
                            }
                        }
                        else if (data.redirect_to === 'network_reset_password') {
                            result.redirectTo = {
                                state: this.AppStates.root_core_resetPasswordForNetworkProfile,
                                params: {
                                    user_id: data.content.user_id,
                                    guid: data.content.guid,
                                    profile_user_id: data.content.profile_user_id
                                }
                            };
                        }
                        else if (data.redirect_to === 'client_login') {
                            result.redirectTo = {state: this.AppStates.root_core_clientLogin};
                        }
                    }

                    // Save company id on storage to show only this company projects (for clients)
                    if (this.user.isClient() && data.company_id) {
                        this.RepositoryService.storagePut(this.Constants.storage.SESSION_COMPANY_ID, data.company_id);
                    }

                    return result;

                }.bind(this))
                .then(function redirect(data) {
                    this.debugState += "Before redirect;";
                    this.RedirectService.setToState(data.redirectionData.state, data.redirectionData.params);
                    if (data.redirectTo) {
                        this.goToState(data.redirectTo.state, data.redirectTo.params);
                    }
                    else {
                        this.goToState(data.redirectionData.state, data.redirectionData.params);
                    }
                }.bind(this))
                .catch(function rejected(res) {
                    var data = res.data;

                    if (!data) {
                        var errorInfo = "HON-12891 - Res: " + JSON.stringify(res) + "; Debug state: " + JSON.stringify(this.debugState);
                        this.$log.error(errorInfo);
                        throw(errorInfo);
                    }

                    var errorData = data.error_data;
                    var errorType = data ? data.error_type : null;
                    var redirectionData = this.buildRedirectionData(errorData.objects, errorData.link.type);

                    if (errorType === 'HBUserAlreadyLoggedInError') {
                        this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.info, data.error_message).then(function ok() {
                            this.goToState(redirectionData.state, redirectionData.params);
                        }.bind(this));
                    }
                    else if (errorType === 'HBLinkExpiredError') {
                        this.RedirectService.setToState(redirectionData.state, redirectionData.params);
                        if(errorData.link.link_source === 'iterable') {
                            this.UsersManager.logout();
                            this.goToState(this.AppStates.root_core_login);
                        } else {
                            this.goToState(this.AppStates.root_core_linkExpired, {
                                link_id: this.linkId,
                                link_token: this.linkToken,
                                vendor: errorData.vendor,
                                user: errorData.user
                            });
                        }
                    }
                }.bind(this));
        },

        redirectToMobileApp: function redirectToMobileApp (content) {
            var serializedWorkspaceObj = JSON.stringify({workspaceId: content.workspace_id});
            var encodedObject = btoa(serializedWorkspaceObj);
            this.$window.location.href =  this.AppsflyerWorkspcaeLink  + "?" + encodedObject;
        },

        buildRedirectionData: function buildRedirectionData(content, link_type) {

            var redirectData = {};

            switch (link_type) {
                case 'approve_connection_link':

                    redirectData = {
                        state: this.AppStates.root_network_profile,
                        params: {
                            user_id: content.profile_user_id,
                            action: 'approve'
                        }
                    };

                    break;
                case 'request_connection_link':
                    redirectData ={
                        state: this.AppStates.root_network_profile,
                        params: {
                            user_id: content.profile_user_id,
                            action: 'request',
                            suggestion_source: content.suggestion_source,
                            suggestion_source_details: content.suggestion_source_details
                        }
                    };
                    break;
                case 'profile_link':

                    redirectData = {
                        state: this.AppStates.root_network_profile,
                        params: {
                            user_id: content.profile_user_id
                        }
                    };

                    break;

                case 'file_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFileRedirection,
                        params: {
                            workspace_file_id: content.file_id
                        }
                    };
                    break;
                case 'payment_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFilePaymentRedirection,
                        params: {
                            workspace_file_id: content.file_id,
                            payment_id: content.payment_id
                        }
                    };
                    break;
                case 'file_preview_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFileRedirection,
                        params: {
                            workspace_file_id: content.file_id,
                            preview: content.preview_mode
                        }
                    };
                    break;
                case 'flow_preview_link':
                    redirectData = {
                        state: this.AppStates.root_core_oneFlowPreview,
                        params: {
                            flow_id: content.flow_id,
                            pageName: content.page_name,
                            action: 'edit',
                            is_preview: true,
                            is_setup_guide: content.is_setup_guide
                        }
                    };
                    break;
                case 'acrocharge_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFileRedirection,
                        params: {
                            workspace_file_id: content.file_id
                        }
                    };
                    break;
                case 'workspace_link':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_event_workspace_feed,
                        params: {
                            event_id: content.event_id,
                            workspace_id: content.workspace_id
                        }
                    };
                    break;
                case 'event_link':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_event_overview,
                        params: {
                            event_id: content.event_id
                        }
                    };
                    break;
                case 'static_link':
                    var stateName = this.state || this.LinksService.getStateFromServerKey(content.state_key);
                    if(stateName) {
                        var params = {
                            shouldStartTrial: content.should_start_trial,
                            discountCode: content.discount_code,
                            promo: content.promo
                        };
                        params = angular.extend(params, this.stateParams);
                        delete params.link_id;
                        delete params.link_token;
                        delete params.extendTrial;
                        redirectData = {
                            state: stateName,
                            params: params
                        };
                    }
                    break;
                case 'pvl_link':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_event_workspace_feed,
                        params: {
                            event_id: content.event_id,
                            workspace_id: content.workspace_id,
                            source: 'pvl_refer',
                            action: 'open_pvl',
                            action_data: content.pvl_id
                        }
                    };
                    break;

                case 'new_lead_webpn_link':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_event_overview,
                        params: {
                            event_id: content.event_id
                        }
                    };

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.new_inquiry_notification_click, {
                        source: 'webpn'
                    });
                    break;

                case 'payment_received_webpn_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFileRedirection,
                        params: {
                            workspace_file_id: content.file_id
                        }
                    };

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_received_notification_click, {
                        source: 'webpn'
                    });
                    break;

                case 'agreement_signed_webpn_link':
                    redirectData = {
                        state: this.AppStates.root_core_workspaceFileRedirection,
                        params: {
                            workspace_file_id: content.file_id
                        }
                    };

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.agreement_signed_notification_click, {
                        source: 'webpn'
                    });
                    break;

                case 'email_settings_link':
                    redirectData = {
                        state: this.AppStates.root_network_settings_email,
                        params: {
                            email_unsubscribe_type: content.email_unsubscribe_type,
                            unsubscribe_email_name: content.unsubscribe_email_name
                        }
                    };
                    break;

                case 'team_member_invite_client':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_home,
                    };
                    break;


                case 'bookkeeper_invite':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_bookkeeping_reports_analytics,
                        params: {
                            auditee_company_id: content.auditee_company_id,
                        },
                    };
                    break;


                case 'email_verification':
                    redirectData = {
                        state: this.AppStates.root_core_navigation_home,
                        params: {
                            email_verification_succeeded: content.email_verification_succeeded,
                        },
                    };
                    break;
            }

            // If one of the link types has a match, and we have origin data
            // pass it to the state params
            if(redirectData.params && content.origin) {
                redirectData.params['origin'] = content.origin;
            }

            return redirectData;
        }
    });
}());
