(function () {
    "use strict";

// class TodoList {

// TODO(sean): Remove and replace with Es6

var TodoList = {
    bindings: {
        todos: '<'
    },
    templateUrl: 'angular/app/modules/common/ui_components/todos/todo_list.html',
};

angular.module('honeybook.common.components.todos.todo_list', [
    'honeybook.common.components.todos.todo'
]).component('hbTodoList', TodoList)

}());
