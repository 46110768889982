(function () {
    "use strict";

    /**
     * Constructor for class {@link CompanySettingsOverviewController}
     * @param $scope
     * @param CompaniesManager
     * @param AnalyticsService
     * @see CompaniesManager
     */
    // @ngInject
    function EditableTagsControllerCtor($scope, $injector, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EditableTagsController';
        this.$timeout = $timeout;

        this.newTag = {editable: true, label: "", editInProgress: false};
        this.tagOldValue = "";
        this.isAddTag = false;
    }


    var EditableTagsController = Class(Controllers.BaseController, {

        constructor: EditableTagsControllerCtor,


        toggleTagEditMode: function toggleTagEditMode(tagId) {

            this.isAddTag = false;
            this.newTag.label = "";
            this.tagOldValue = "";
            this.tagsArray.forEach(function (pt) {
                pt.inEditMode = pt._id === tagId;
            });
        },

        onStartEditTag: function onStartEditTag(tagOldValue) {
            this.tagOldValue = angular.copy(tagOldValue);
        },

        onCancelEditTag: function onCancelEditTag(index) {
            // if (this.tagOldValue !== "") {
            //     this.tagsArray[index].label = angular.copy(this.tagOldValue);
            //     this.tagOldValue = "";
            // }
            this.toggleTagEditMode(null);
        },


        onTagUpdate: function onTagUpdate(tag) {

            this.onTagUpdateFn({tag: tag});
        },

        onTagCreate: function onTagCreate(newTag) {
            this.onTagCreateFn({
                tag: newTag, callback: function afterCreate() {
                    this.toggleTagEditMode(null);
                }.bind(this)
            });
        },

        onTagDelete: function onTagDelete(tag) {

            this.onTagDeleteFn({tag: tag});
        },

        onAddButtonClicked: function onAddButtonClicked($event) {
            var addTagButton = angular.element($event.currentTarget);
            this.toggleTagEditMode(null);
            this.isAddTag = true;

            this.$timeout(function(){
                addTagButton.next().children('.add-tag-input-js').focus();
            });
        }
    });

    Directives.EditableTagsDirective = function EditableTagsDirective() {
        return {
            scope: {
                tagsArray: '=tagsArray',
                onTagCreateFn: '&onTagCreateFn',
                onTagUpdateFn: '&onTagUpdateFn',
                onTagDeleteFn: '&onTagDeleteFn',
            },
            templateUrl: 'angular/app/modules/common/ui_components/editable_tags/editable_tags_directive_template.html',
            controller: EditableTagsController,
            controllerAs: 'editableTagsVm',
            bindToController: true,
            link: function (scope) {
                // Expose the overviewForm to external controllers.
                scope.editableTagsVm.controller = scope.editableTagsVm || {};
            }
        };
    };

}());
