(function () {
    'use strict';

    class FlowsOnboardingWizardServicesController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope
            ,$injector
            ,UsersManager
            ,ModalService
            ,CompaniesManager
            ,$translate
            ,Enums
            ,UiPersistenceService
            ,AnalyticsService
            ,DeviceService
            ,$timeout
            ,OnboardingService
            ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardServicesController';

            this.UsersManager = UsersManager;
            this.ModalService = ModalService;
            this.CompaniesManager = CompaniesManager;
            this.$translate = $translate;
            this.Enums = Enums;
            this.UiPersistenceService = UiPersistenceService;
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.$timeout = $timeout;
            this.$injector = $injector;
            this.OnboardingService = OnboardingService;

            this.defaultSelectedService = 'invoices';

            $scope.$watch('$ctrl.privateSelectedServiceKey', (newVal) => {
                if (newVal) {
                    this.$timeout( () => {
                        this.selectedFlowTemplateId = null;
                        this.isStepValid = true;
                    }, 1000);
                } 
            });
        }

        onServiceSelect(index, key) {
            this.AnalyticsService.trackClick(this, 'toggle setup guide service', {position: index, type: key} );
            
            this.$timeout(() => {
                const flowsOnboardingSection = document.getElementById("flowsOnboardingSection");
                let scrollIntoViewOptions = {behavior: "smooth", block: "end", inline: "nearest"};
                if (this.DeviceService.isSafari() || this.DeviceService.isEdge()) {
                    // options is not supported by safari and edge
                    scrollIntoViewOptions = false;
                }
                flowsOnboardingSection.scrollIntoView(scrollIntoViewOptions);
            }, 0)            
            

            
        }

        $onInit() {
            this.user = this.UsersManager.getCurrUser();
            this.isMobile = this.DeviceService.nxSmallBreakpoint();

            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this.company = this.CompaniesManager.getCurrCompany();
            this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            const t1ActivationABCVariant = this.OnboardingService.getT1ActivationABCVariant();
            this.isSkipTemplate = (t1ActivationABCVariant === 'skip_template');
            
            this.services = [
                {text: 'Invoices & Pay', key: 'invoices', icon: 'icon-hb-nx-dollar-circle'},
                {text: 'Forms or questionnaires', key: 'forms', icon: 'icon-hb-question-mark'},
                {text: 'Contracts & E-sign', key: 'contracts', icon: 'icon-hb-nx-contract'},
                {text: 'Services & pricing guides', key: 'services', icon: 'icon-hb-nx-brochure'},
                {text: 'Company intro, inspiration or mood boards', key: 'mood_boards', icon: 'icon-hb-nx-file'},
                {text: 'Other', key: 'other', icon: 'icon-hb-nx-other'}
            ];
            this.currUIPersistedData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.flowsOnboarding) || {};
            this.privateSelectedServiceKey = this.currUIPersistedData[this.thisStepKey] || this.defaultSelectedService;
        }

        onComplete(event, currStep) {
            if (currStep.key === this.thisStepKey) {
                // do something when user click next on services step
                this.selectedServiceKey = this.privateSelectedServiceKey;
                this.currUIPersistedData = Object.assign({}, this.currUIPersistedData, {[this.thisStepKey]: this.selectedServiceKey, flow_templates: null});
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, this.currUIPersistedData);

                if (this.isSkipTemplate) {
                    this.SelfOnboardingManager = this.$injector.get('SelfOnboardingManager');
                    this.TemplatesRepositoryManager = this.$injector.get('TemplatesRepositoryManager');
                    this.EventService = this.$injector.get('EventService');
                    this.TemplatesManager = this.$injector.get('TemplatesManager');
                    this.showTemplatesAndSelectOne(this.selectedServiceKey)
                }
            }
        }

        // Start: Code for skip_template variant based on code from Templates step.

        getFlowTemplateByServiceKey(serviceKey) {

            if (!serviceKey) {
                return;
            }
            
            return this.SelfOnboardingManager.getOnboardingFlowTemplates(this.user, serviceKey).then(response => {
                return response.data
            }

            );
        }

        setSelectedFlow(flowTemplateId, flowTemplateUrl, flowTemplateTitle) {
            this.selectedFlowTemplateId = flowTemplateId;
            this.selectedFlowTemplateImageUrl = flowTemplateUrl;
            this.selectedFlowTemplateTitle = flowTemplateTitle;
        }

        onSubmitTemplateStep() {
            this.TemplatesRepositoryManager.importTemplates(this.company, {id: [this.selectedFlowTemplateId]})
                .then((data) => {
                    var projectData = this.EventService.createDefaultProject(this.company, this.user.first_name + '’s test project').dataOnly();
                    projectData.event_source = 'onboarding';
                    this.SelfOnboardingManager.updateOnboardingFlowTemplate(this.user, data.data.imported_templates[0]._id)
                        .then(() => {
                            var promise = this.TemplatesManager.createFlowInstanceWithProject(data.data.imported_templates[0]._id, projectData);
                            promise.then((resp) => {

                                this.flowInstanceId = resp.data._id;
                                const persistedData = {flowInstanceId: this.flowInstanceId, selectedFlowTemplateId: this.selectedFlowTemplateId, selectedFlowTemplateImageUrl: this.selectedFlowTemplateImageUrl, selectedFlowTemplateTitle: this.selectedFlowTemplateTitle};
                                this.currUIPersistedData = Object.assign({}, this.currUIPersistedData, {[this.thisStepKey]: persistedData});
                                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, this.currUIPersistedData);
                            });
                        });
                });
        }

        showTemplatesAndSelectOne(serviceKey) {

            this.getFlowTemplateByServiceKey(serviceKey).then(templatesArray => {
                if (templatesArray && templatesArray.length > 0) {
                    const firstTemplate = templatesArray[0];
                    this.setSelectedFlow(firstTemplate._id, firstTemplate.header_image.cloudinary_public_id, firstTemplate.title);
                    this.onSubmitTemplateStep();
                }
                
            });
        }

        // End: Code for skip_template variant based on code from Templates step.

        
    }

    Components.FlowsOnboardingWizardServices = {
        controller: FlowsOnboardingWizardServicesController,
        bindings: {
            selectedServiceKey: '=',
            thisStepKey: '@',
            isStepValid: '=',
            selectedFlowTemplateId: '=',
            flowInstanceId: '=',
            selectedFlowTemplateImageUrl: '=',
            stepTranslationMap: '<',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/services/flows_onboarding_wizard_services.html',

    };
}());
