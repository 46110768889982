/**
 * Created by inonstelman on 12/28/14.
 */
(function () {
    'use strict';


    // @ngInject
    Services.mixpanel = function mixpanel($window) {
        function mixpanelWrapper() {

            var ret = {

                get: function get() {

                    return $window.mixpanel;
                }
            };


            return ret;
        }

        return mixpanelWrapper();
    };


}());