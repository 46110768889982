(function () {
    "use strict";

    // @ngInject
    function PopupMessageControllerCtor($scope, $injector, $translate, $log, PopupMessageService, UiPersistenceService, $uibModalInstance, popupData, DatadogRUMService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PopupMessageController';

        this.$translate = $translate;
        this.$log = $log;
        this.DatadogRUMService = DatadogRUMService;
        this.UiPersistenceService = UiPersistenceService;
        this.$uibModalInstance = $uibModalInstance;
        this.popupData = popupData;

        this.initPopupData();

    }

    Controllers.PopupMessageController = Class(Controllers.BaseController, {
        constructor: PopupMessageControllerCtor,

        initPopupData: function initPopupData() {
            this.callbackInvoked = false;

            var popupInfo = this.popupData.popupInfo;

            this.popupSeverity = popupInfo.severity;
            this.popupType = popupInfo.popupType;
            this.dontShowAgain = popupInfo.dontShowAgain;
            this.dataList = popupInfo.messageRes.dataList;
            this.showVerticalButtons = popupInfo.showVerticalButtons;
            this.branded = popupInfo.branded;
            this.showXButton = popupInfo.showXButton;

            // translate positive button
            this.translateWithFallback(popupInfo.posBtnRes, 'positiveBtnText', this);

            // translate negative button
            this.translateWithFallback(popupInfo.negBtnRes, 'negativeBtnText', this);

            // translate message
            if(this.dataList) {
                this.popupText = this.translateObject(popupInfo.messageRes.msg);
            } else {
                this.popupText = this.translateObject(popupInfo.messageRes);
            }

            if (this.popupSeverity === 'Error') {
                if (!this.popupText){
                    this.DatadogRUMService.addError(new Error('Error on pop up message '), {html: popupInfo});
                } else {
                    this.DatadogRUMService.addError(new Error('Error on pop up message '), {html: this.popupText});
                }


                this.$log.error('Error on pop up message, html is: ', this.popupText);
            }

            // translate title
            this.popupTitle = "";
            if(popupInfo.titleRes) {
                this.popupTitle = this.translateObject(popupInfo.titleRes);
            }

            // callbacks
            this.successCallback = this.popupData.popupInfo.successCallback;
            this.dismissCallback = this.popupData.popupInfo.dismissCallback;

        },

        translateWithFallback: function translateWithFallback(translationKey, updateKey, updateContext) {
            if (translationKey) {
                this.$translate(translationKey).then(function (text) {
                    updateContext[updateKey] = text;
                }, function translationFailed(){
                    updateContext[updateKey] = translationKey;
                });
            }
        },

        translateObject: function translateObject(translationObject) {
            if(angular.isObject(translationObject)) {
                return this.$translate.instant(translationObject.translationId, translationObject.interpolateParams);
            } else {
                return translationObject;
            }
        },

        updateDontShow: function updateDontShow() {
            if (this.dontShowAgain && this.hidePopUp) {
                this.UiPersistenceService.setUiPersistence(this.popupData.popupInfo.uiPersistenceKey, {'dontShow': true});
            }
        },

        posBtnClick: function() {
            this.callbackInvoked = true;

            // close
            this.$uibModalInstance.close();

            this.updateDontShow();
            this.triggerCallback(this.successCallback);
        },

        negBtnClick: function() {
            this.callbackInvoked = true;
            this.updateDontShow();
            this.triggerCallback(this.dismissCallback);

            // close
            this.$uibModalInstance.close();
        },

        triggerCallback: function triggerCallback(callback) {
            if (callback && typeof callback === 'function') {
                callback();
            }
        }


    });

}());
