/**
 * Created by dvircohen on 07/08/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function WorkspaceFileRedirectionControllerCtor($scope, $injector, AnalyticsService, $stateParams, WorkspaceFileService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileRedirectionController';
        AnalyticsService.trackPageView(this, 'WorkspaceFileRedirectionView');

        WorkspaceFileService.gotoWorkspaceFileById($stateParams.workspace_file_id, $stateParams.preview, {}, true, $stateParams.payment_id);
    }

    Controllers.WorkspaceFileRedirectionController = Class(Controllers.BaseController, {
        constructor: WorkspaceFileRedirectionControllerCtor,
    });
}());

