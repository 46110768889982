Directives.WorkspaceFileCardDropdownDirective = function WorkspaceFileCardDropdownDirective() {

    // @ngInject
    function WorkspaceFileCardDropdownControllerConstructor($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileCardDropdownController';
    }

    var WorkspaceFileCardDropdownController = Class(Controllers.BaseController, {
        constructor: WorkspaceFileCardDropdownControllerConstructor,

        setDropdownPosition: function setDropdownPosition($event) {
            var targetElm = $event.currentTarget;
            var dropDown = $(targetElm).find('ul');

            if (dropDown) {
                dropDown.css({
                    marginTop: - (dropDown.innerHeight() + 25)
                });
            }
        }
    });


    return {
        scope: {
            workspaceFileId: '<',
            allowDelete: '=',
            deleteWorkspaceFileClicked: '&',
            allowDeactivate: '=',
            deactivateWorkspaceFile: '&',
            allowActivate: '=',
            activateWorkspaceFile: '&'
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file_card/workspace_file_card_dropdown_template.html',
        controller: WorkspaceFileCardDropdownController,
        controllerAs: 'wsFileCardDropdownVm',
        bindToController: true
    };
};