(function () {
    'use strict';
    Controllers.PausedOfferModalController = class PausedOfferModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, $modalInstance) {
            super($scope, $injector);
            this.__objectType = 'PausedOfferModalController';

            this.$state = $state;
            this.$modalInstance = $modalInstance;
            this.showOverlay =  false;
        }

        unpauseMembership() {
            this.$state.go(this.AppStates.root_core_navigation_settings_company_subscription);
            this.$modalInstance.dismiss();
        }
    };
}());
