
/**
 * The aside-menu component
 *
 * This component shows side menu in contact form templates editor
 *
 */

(function () {
    'use strict';

    class AsideMenuController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'AsideMenuController';
        }

        toggleClose() {
            this.isOpen = !this.isOpen;
            if (!this.isOpen && this.onClose && angular.isFunction(this.onClose)) {
                this.onClose();
            }
        }
    }

    Components.AsideMenu = {
        bindings: {
            isOpen: '=',
            onClose: '&',
            isScrollable: '<'
        },
        transclude: {
            'title': '?asideMenuTitle',
            'content': '?asideMenuContent'
        },
        controller: AsideMenuController,
        name : 'asideMenu',
        templateUrl: 'angular/app/modules/common/ui_components/aside_menu/aside_menu.html',
    };

}());
