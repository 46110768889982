(function () {
    'use strict';

    // @ngInject
    function ContactFormExamplesControllerCtor($scope, $injector, $modalInstance, $state,  _, $log, $timeout, $translate, $,
                                            AnalyticsService, UIUtils, modalSource, AppStates) {
        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ContactFormExamplesController';
        AnalyticsService.trackPageView(this, 'ContactFormExamplesView');

        this.$log = $log;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.$ = $;
        this.$modalInstance = $modalInstance;
        this.$state = $state;
        this.AppStates = AppStates;

        this.modalSource = modalSource;
        if(!this.modalSource){
            this.modalSource = 'settings contact form';
        }

        this.AnalyticsService = AnalyticsService;

        this.UIUtils = UIUtils;

        //$.cloudinary.image("contactform/1_M2m_Photobooths.png", {width: 300, crop: "scale"})

        this.examples = [
            {   climg:'contactform/1_M2m_Photobooths',
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693408/contactform/1_M2m_Photobooths.jpg',
                title: 'M2M Photobooths',
                website: this.UIUtils.fixExternalUrl('http://www.m2mphotobooths.com/contactus')
            },
            {
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693415/contactform/2_Pure_Serenity.jpg',
                title: 'Pure Serendipity',
                website: this.UIUtils.fixExternalUrl('www.pureserendipityevents.com/contact')
            },
            {
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693419/contactform/3_Crystal_Bolin_copy.jpg',
                title: 'Crystal Bolin',
                website: this.UIUtils.fixExternalUrl('www.crystalbolinphotography.com/contact/')
            },
            {
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693426/contactform/4_Angela_Nunnick.jpg',
                title: 'Angela Nunnick',
                website: this.UIUtils.fixExternalUrl('www.angelanunnink.com/contact/')
            },
            {
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693435/contactform/5_EdgeFog_FIlms.jpg',
                title: 'EdgeFog films',
                website: this.UIUtils.fixExternalUrl('http://www.edgefogfilms.com/contact')
            },
            {
                img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1475693443/contactform/6_Red_Letter_Events.jpg',
                title: 'Red Letter Events',
                website: this.UIUtils.fixExternalUrl('http://www.redlettereventplanning.com/contact/')
            },

        ]


        $scope.$on('$destroy', function() {
            // if, for some reason, escBlock is still running
            self.unblockEscKey();
        });

        this.bypassStateChange = false;
        $scope.$on('$stateChangeStart', function() {
            if (!this.bypassStateChange) {
                this.close();
            }
        }.bind(this));

        this.init();
    }

    Controllers.ContactFormExamplesController = Class(Controllers.BaseController, {
        constructor: ContactFormExamplesControllerCtor,
        debugConsoleLog: function (message) {
            //made this logging function so it would be easier to trace all the dragging and the moving of workspaces
            //once a stage is deleted
            //this.$log.log(message);
        },


        init: function init() {
            var self = this;

        },

        dismiss: function dismiss(result) {

            this.$modalInstance.dismiss(result);
        },


        close: function close(result) {
            this.$modalInstance.close(result);
        },

        abort: function abort(error) {
            this.$modalInstance.dismiss(error);
        },

        blockEscKey: function blockEscKey() {
            // disallow closing modal with esc
            this.$('body').on('keydown.pipeline.settings', function(e) {
                var escCode = 27; // escape key maps to keycode `27`

                if (e.keyCode === escCode) {
                    e.stopPropagation();
                }
            });
        },

        unblockEscKey: function unblockEscKey() {
            this.$('body').off('keydown.pipeline.settings');
        },

        onCloseClicked: function onCloseClicked() {
            this.close('dismissed');
        }
    });
}());
