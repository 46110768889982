// @ngInject

Models.PlaceModel = function PlaceModel(BaseModel){

    var PlaceModel = Class(BaseModel, {

        constructor: function constructor(){
            PlaceModel.$super.call(this);
            this.__className = 'PlaceModel';

            this.place_string = null;
            this.address_line_1 = null;
            this.address_line_2 = null;

            this.country = null;
            this.state = null;
            this.city = null;

            this.street_number = null;
            this.street_address = null;

            this.postal_code = null;
            this.post_box = null;
        },

        mapChilds: function mapChilds(){
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object){
        },

        isCachable: function isCachable() {
            return false;
        },

        toParams: function() {
            var instanceVars = [
                "place_string", "address_line_1", "address_line_2", "country", "state", "city",
                "street_number", "street_address", "postal_code", "post_box"
            ];

            var ret = {};
            var length = instanceVars.length;
            for (var i = 0; i < length; i++) {
                if (this[instanceVars[i]]) {
                    ret[instanceVars[i]] = this[instanceVars[i]];
                }
            }
            return ret;
        },

        _isBlank: function _isBlank(str) {
            return (!str || /^\s*$/.test(str));
        },

        _toString: function _toString(str) {
           if (str) {
               return str;
           } else {
               return "";
           }
        },

        _appendNonEmpty: function _appendNonEmpty(str, newAddition, separator) {
            if (!this._isBlank(newAddition)) {
                if (this._isBlank(str)) {
                    return str + newAddition;
                } else {
                    return str + separator + newAddition;
                }
            }
            return str;
        },

        streetAddress: function streetAddress() {
            if (!this._isBlank(this.address_line_1)) {
                return (this.address_line_1 + " " + this._toString(this.address_line_2)).trim();
            } else {
                return (this._toString(this.street_number) + " " + this._toString(this.street_address)).trim();
            }
        },

        addressWithoutPostal: function addressWithoutPostal() {
            if (!this._isBlank(this.place_string)) {
                return this.place_string;
            } else {
                var ret = this.streetAddress();
                ret = this._appendNonEmpty(ret, this.city, ", ");
                ret = this._appendNonEmpty(ret, this.state, ", ");
                ret = this._appendNonEmpty(ret, this.country, ", ");
                return ret.trim();
            }
        }

    });

    return PlaceModel;
};
