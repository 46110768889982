(function () {
    'use strict';

    class ProjectActionDropdownComponent extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, $state, $window, SchedulingService, AnalyticsService, UsersManager, Constants, ModalService,
                    ToastService, $translate, PopupMessageService, ReferralsManager, CompaniesManager, DeviceService, AppStates, RescheduleProjectService, WorkspacesManager,
                    StatsigService) {
            super($scope, $injector);
            this.__objectType = 'ProjectActionDropdownController';
            this.$state = $state;
            this.SchedulingService = SchedulingService;
            this.UsersManager = UsersManager;
            this.WorkspacesManager = WorkspacesManager;
            this.currentUser = UsersManager.getCurrUser();
            this.Constants = Constants;
            this.ModalService = ModalService;
            this.ToastService = ToastService;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.PopupMessageService = PopupMessageService;
            this.ReferralsManager = ReferralsManager;
            this.allowDelete = this.workspace.allowDelete();
            this.isActionMenuOpen = false;
            this.isHbAdmin = !!this.currentUser.is_hb_admin;
            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.AppStates = AppStates;
            this.$window = $window;
            this.RescheduleProjectService = RescheduleProjectService;
            this.StatsigService = StatsigService;
            this.deleteCheckInProgress = false;

            if (this.project) {
                this.project.archived = this.project.archived === undefined ? this.workspace.isArchived() : this.project.archived;
            }
            this.company = CompaniesManager.getCurrCompany();
            this.dropdownId = 'project-action' + this.workspace._id;
            this.rescheduleArchiveTooltip = $translate.instant('PROJECT_ACTION_DROPDOWN._ARCHIVED_PROJECT_RESCHEDULE_TOOLTIP_');

            this.isTeamMember = this.company._id === this.workspace.creator_company_id;
            this.isWorkspaceOwner = this.workspace.creator_id === this.currentUser._id;
            this.userCanReschedule = this.RescheduleProjectService.userCanReschedule(this.currentUser, this.workspace);
        }

        isPartOfProject() {
            let isMember = true;
            const members = this.getMembersData(this.project);
            if (this.project.creator_id !== this.currentUser._id && (this.currentUser.isCompanyOwner() || this.currentUser.hasCompanyAdminPermissions())) {

                isMember = members.some(member => {
                    return member._id === this.currentUser._id;
                });
            }
            return isMember;
        }

        getMembersData(project) {
            let list = [];

            project.workspaces.forEach(workspace => {
                list = list.concat(workspace.members.filter(member => member._id !== project.creator_id));
            });

            return list;
        }

        getSessionType(sessionType) {
            const sessionTypeObj = this.Constants.MeetingTypes.find(type => {
                return type.key === sessionType;
            });
            return sessionTypeObj.label;
        }

        clickOutside() {
            angular.element('#' + this.dropdownId).css('display', 'none');
            this.isActionMenuOpen = false;
        }

        optionClick(event) {
            event.stopPropagation();

            angular.element('.project-action-dropdown__dropdown').css('display', 'none');

            angular.element('#' + this.dropdownId).css('display', this.isActionMenuOpen ? 'none' : 'block');
            this.isActionMenuOpen = !this.isActionMenuOpen;
        }

        showSecondMenu() {
            let dropdownElement = angular.element('#' + this.dropdownId + 'extend');
            dropdownElement.css('display', 'block');
        }

        hideSecondMenu() {
            let dropdownElement = angular.element('#' + this.dropdownId + 'extend');
            dropdownElement.css('display', 'none');
        }

        onGoToCalendarClick() {
            const href = this.$state.href(this.AppStates.root_core_navigation_calendar, {}, {absolute: true});
            this.$window.open(href, '_blank');
        }

        openCreateMeetingModal(project, sessionConfig, event) {
            event.stopPropagation();
            const sessionConfigParam = sessionConfig || {};

            let meetingTitleParts = [];
            let members = this.getWorkspaceMembers(this.workspace? this.workspace : this.project, this.project.creator_id);

            if (members && members.length) {
                meetingTitleParts.push(members[0].full_name);
            }

            if (sessionConfigParam.session_name) {
                meetingTitleParts.push(sessionConfigParam.session_name);
            }

            let meetingModalOptions = {
                isCreateMeeting: true,
                type: sessionConfigParam.session_type,
                workspace_id: this.workspace._id,
                event_id: this.project._id,
                event_name: this.project.event_name,
                title: meetingTitleParts.join(' - '),
                description: sessionConfigParam.session_description,
                location: sessionConfigParam.meeting_location,
                duration: sessionConfigParam.session_duration,
                session_config_id: sessionConfigParam._id
            };

            this.ModalService.openCalendarMeetingModal(meetingModalOptions).then(calendarItemAdded => {
                this.ToastService.showSuccess({
                    contentTranslation: 'Your session was successfully scheduled',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 5000
                });
            });
        }

        getWorkspaceMembers(workspace, creator_id) {
            return workspace.members.filter(member => member._id !== creator_id);
        }

        openRescheduleProjectModal(event, isUnpause) {
            event.stopPropagation();
            this.RescheduleProjectService.openRescheduleModal(this.project, this.workspace._id, false, this.source);
        }

        openMoveStageModal(event) {
            event.stopPropagation();

            this.ModalService.openMoveStageModal(this.workspace, this.currentUser.company).then(() => {
                this.onMoveStageDone();
            });
        }

        openPauseModal(project) {
            this.ModalService.openPauseProjectModal(project._id, project.workspace._id).then(calendarItemAdded => {
                this.ToastService.showSuccess({
                    contentTranslation: 'Your project was successfully paused',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 5000
                });
            });
        }

        openArchiveModal(event) {
            event.stopPropagation();
            this.ModalService.openArchiveProjectModal(this.workspace._id, this.source).then(() => {
                this.onArchiveDone();
            });
        }

        openDeleteWorkspace(event){
            var self = this;

            event.stopPropagation();
            this.isActionMenuOpen = true;
            this.deleteCheckInProgress = true;

            this.WorkspacesManager.isDeletable(this.workspace._id).then(function success(res) {
                self.deleteCheckInProgress = false;

                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.delete_workspace_from_pipeline, {workspace_id: self.workspace._id, is_deletable: res.data.is_deletable});

                if(res.data.is_deletable) {
                    self.onDeleteWorkspace && self.onDeleteWorkspace({workspace: self.workspace});
                } else {
                    self.workspace.is_deletable = false;
                    self.onDeleteBookedWorkspace && self.onDeleteBookedWorkspace({workspace: self.workspace});
                }
            });
        }

        openWorkspaceAdminMove(event){
            event.stopPropagation();
            this.onWorkspaceAdminMove && this.onWorkspaceAdminMove({workspace: this.workspace});
        }

        getDisabledDeleteTooltipText() {
            if(this.workspace.isArchived()) {
               return this.$translate.instant('PROJECT_ACTION_DROPDOWN.TOOLTIPS._NOT_DELETABLE_ARCHIVED_ITEM_');
            }

            return this.$translate.instant('PROJECT_ACTION_DROPDOWN.TOOLTIPS._NOT_DELETABLE_ITEM_');
        }

        openWorkspaceReferred(event){
            event.stopPropagation();
            this.onWorkspaceReferred && this.onWorkspaceReferred({workspace: this.workspace});
        }

        openAssignWorkspaceToTeamMember(event){
            event.stopPropagation();
            this.onAssignWorkspaceToTeamMember && this.onAssignWorkspaceToTeamMember({workspace: this.workspace});
        }
    }


    Components.ProjectActionDropdownComponent = {
        bindings: {
            project: '=',
            sessionTypes: '=',
            source: '@',
            workspace: '=',
            dropdownText: '@',
            onArchiveDone: '&onArchiveDone',
            onMoveStageDone: '&onMoveStageDone',
            onAssignWorkspaceToTeamMember: '&onAssignWorkspaceToTeamMember',
            onDeleteWorkspace: '&onDeleteWorkspace',
            onDeleteBookedWorkspace: '&onDeleteBookedWorkspace',
            onWorkspaceReferred: '&onWorkspaceReferred',
            onWorkspaceAdminMove: '&onWorkspaceAdminMove'
        },
        controller: ProjectActionDropdownComponent,
        name : 'hbProjectActionDropdownComponent',
        templateUrl: 'angular/app/modules/common/ui_components/project_action_dropdown/project_action_dropdown_template.html',
    };
}());
