
Directives.ServiceDirectiveNew = function ServiceDirectiveNew() {

    // @ngInject
    function ServiceDirectiveNewControllerFunc($scope, $injector, AnalyticsService, $timeout, $log, ModalService, _, $translate, PopupMessageService, UIUtils, WorkspaceFileService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ServiceDirectiveNewController';

        this.$log = $log;
        this.ModalService = ModalService;
        this.$translate = $translate;
        this._ = _;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;
        this.WorkspaceFileService = WorkspaceFileService;

        this.round = Math.round;
        this.deleting = false;
        this.deletingImage = false;
        this.updatingImage = false;
        this.shouldShowAutoComplete = false;

        // tax
        this.enableTax = this.serviceModel.shouldShowTax();

        // svc
        this.enableSVC = this.serviceModel.shouldShowSVC();

        this._initializeUnits();
        this._setPricePerUnit();

        if (this.focus) {
            this._showAutoComplete();
        }
    }

    var ServiceDirectiveNewController = Class(Controllers.BaseController, {
        constructor: ServiceDirectiveNewControllerFunc,

        /////////////////////////
        // Edit / Delete
        ////////////////////////

        updateService: function updateService() {
            if (this.isEditMode()) {
                return this.serviceModel.updateService();
            }
        },

        updateServiceSelection: function updateServiceSelection() {
            if(this.isOwnerMode) {
               return;
            }
            return this.serviceModel.updateServiceSelection(this.serviceModel.selected);
        },

        updateServiceQuantity: function updateServiceQuantity() {
            return this.serviceModel.updateServiceQuantity();
        },

        calculatePrice: function calculatePrice(override) {
            this.serviceModel.price_per_unit = this.pricePerUnit;
            this.serviceModel.calculatePrice(override);

            if(this.isEditMode()) {
                this.updateService();
            } else {
                this.updateServiceQuantity();
            }
        },

        updateServiceTax: function updateServiceTax() {
            this.updateService().then(
                function success() {},
                function error() {
                    this.serviceModel.taxable = !this.serviceModel.taxable;
                }.bind(this));
        },

        updateServiceSVC: function updateServiceSVC() {
            this.updateService().then(
                function success() {},
                function error() {
                    // reset
                    this.serviceModel.svcable = !this.serviceModel.svcable;
                }.bind(this));
        },

        updateServiceImage: function updateServiceImage() {
            this.ModalService.openAttachAssetModal(true, true, true, "Library", false, false, null, 1, false, false, "image/*", '', '', 'MEDIA_MODAL.TITLE._ADD_IMAGE_').then(function success(newImage) {
                this.updatingImage = true;
                // Remember this means hasImage returns false. To account for the loader, edit the template
                this.serviceModel.package_service_image = null;
                this.serviceModel.updateServiceImage(newImage)
                    .finally(function finallyCleaning() {
                        this.updatingImage = false;
                    }.bind(this));
            }.bind(this));
        },

        editServiceImage: function editServiceImage(){
            // showCropTab, showGalleryTab, showUploadTab, defaultTab, allowMultipleAttach, showBackdrop, selectedImage, aspectRatio, showPreviewWhenCropping, startUploadFile, acceptFilesFilter, model) {
            this.ModalService.openAttachAssetModal(true, true, true, "Crop", false, false, this.serviceModel.package_service_image, 1, false, false, "image/*",'', '', 'MEDIA_MODAL.TITLE._EDIT_IMAGE_').then(function success(newImage) {

                this.updatingImage = true;
                this.serviceModel.package_service_image = null;
                this.serviceModel.updateServiceImage(newImage)
                    .finally(function finallyCleaning() {
                        this.updatingImage = false;
                    }.bind(this));

            }.bind(this));
        },

        editServiceImageMobile: function editServiceImageMobile() {
            this.showEditImageMobile = false;
            this.editServiceImage();
        },

        deleteServiceImage: function deleteServiceImage() {

            if (this.deletingImage) {
                return;
            }

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.info,
                'PROPOSAL.NEW_SERVICE.DELETE_IMAGE._BODY_',
                'FREQUENT_BUTTONS._DELETE_').then(function _delete() {
                this.deletingImage = true;
                return this.serviceModel.deleteServiceImage();
            }.bind(this)).then(function success() {
                this.serviceModel.package_service_image = null;
            }.bind(this))
            .finally(function finallyCleaning() {
                this.deletingImage = false;
            }.bind(this));
        },

        deleteServiceImageMobile: function deleteServiceImageMobile() {
            this.showEditImageMobile = false;
            this.deleteServiceImage();
        },

        deleteService: function deleteService() {

            // ignore if during delete
            if (this.deleting) {
                return;
            }

            this.deleting = true;

            return this.serviceModel.deleteService().then(
                function success() {
                    if (this.serviceModel.isPackageService()) {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.service_deleted_from_package, this.serviceModel.getAnalyticsArgs());
                    }
                    else if (this.serviceModel.isALaCarteService()) {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.service_deleted_from_a_la_carte, this.serviceModel.getAnalyticsArgs());
                    }
                    else {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.service_deleted_from_proposal, this.serviceModel.getAnalyticsArgs());
                    }

                }.bind(this),
                function error() {
                    if (this.serviceModel.isPackageService()) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.service_deleted_from_package, this.serviceModel.getAnalyticsArgs());
                    }
                    else if (this.serviceModel.isALaCarteService()) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.service_deleted_from_a_la_carte, this.serviceModel.getAnalyticsArgs());
                    }
                    else {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.service_deleted_from_proposal, this.serviceModel.getAnalyticsArgs());
                    }
                }.bind(this)
            ).finally(function finallyCleaning() {
                this.deleting = false;
            }.bind(this));
        },

        /////////////////////////
        // Helpers
        ////////////////////////

        shouldShowQuantityViewSection: function shouldShowQuantityViewSection() {
            return this.isEditMode() || this.isQuantityEditable() || !isNaN(parseInt(this.serviceModel.quantity));
        },

        shouldShowUnitViewSection: function shouldShowUnitViewSection() {
            return this.isEditMode() || !!this.serviceModel.unit;
        },

        shouldShowServiceDescription: function shouldShowServiceDescription() {
            return this.isEditMode() || (this.serviceModel.description);
        },

        shouldShowPriceOnPreview: function shouldShowPriceOnPreview() {
            return this.isEditMode() || (this.serviceModel.price !== undefined && this.serviceModel.price !== 0) || this.shouldShowQuantityViewSection();
        },

        shouldShowTaxViewSection: function shouldShowTaxViewSection() {
            return this.isEditMode() || (this.serviceModel.taxable && this.serviceModel.price > 0);
        },

        shouldShowSvcViewSection: function shouldShowSvcViewSection() {
            return this.isEditMode() || (this.serviceModel.svcable && this.serviceModel.price > 0);
        },

        isEditMode: function isEditMode() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        isOwnerAndNotPreviewMode: function isOwnerAndNotPreviewMode() {
            return this.isOwnerMode && !this.isPreviewMode;
        },

        shouldShowServiceOnPreview: function shouldShowServiceOnPreview() {
            var notEmpty =  !this._.isEmpty(this.serviceModel.name) ||
                !this._.isEmpty(this.serviceModel.description) ||
                this._.isObject(this.serviceModel.package_service_image) ||
                this.serviceModel.quantity > 0 ||
                this.serviceModel.price_per_unit > 0 ||
                this.serviceModel.price > 0;

            return this.isEditMode() || notEmpty;
        },

        onServiceUnitSelect: function onServiceUnitSelect(item) {
            this.serviceModel.unit = item.key;
            this._setServiceUnit(item.key);
            this.updateService();
        },

        onTitleClick: function onTitleClick() {
            this._showAutoComplete();
        },

        onTitleChange: function onTitleChange() {
            this._showAutoComplete();
        },

        updateServiceIfNeeded: function updateServiceIfNeeded(){
            if (this.shouldShowAutoComplete){
                this.waitForAutoCompleteToExit = true;
            }else{
                this.updateService();
            }
        },

        _showAutoComplete: function _showAutoComplete() {
            if (!this.serviceModel.name) {
                this.shouldShowAutoComplete = true;
            }
        },

        hideAutoComplete: function hideAutoComplete() {
            this.shouldShowAutoComplete = false;
            if (this.waitForAutoCompleteToExit){
                this.waitForAutoCompleteToExit = false;
                this.updateServiceIfNeeded();
            }
        },

        onAutoCompleteItemSelected: function onAutoCompleteItemSelected(item) {
            this.waitForAutoCompleteToExit = false;
            if (item.type === 'service') {
                this._.extend(this.serviceModel, item);
                this.updateService();
                // remove image id from service after update
                delete this.serviceModel.image_id;
                this._setPricePerUnit();
                if (!item.image_id) {
                    this.serviceModel.deleteServiceImage();
                }
            } else {
                var proposal = this.serviceModel.getContainingProposal();
                this.deleteService().then(function onDelete() {
                    proposal.addPackage(item.package_id);
                }.bind(this));
            }
        },

        onAutoCompleteServiceCreated: function onAutoCompleteServiceCreated(name) {
            this.serviceModel.name = name;
            this.serviceModel.updateService();
            this.serviceModel.deleteServiceImage();
        },

        _initializeUnits: function __initializeUnits() {

            this._setServiceUnit(this.serviceModel.unit);

            var none = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._NONE_');
            var item = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._ITEM_');
            var hour = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._HOUR_');
            var day = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._DAY_');
            var week = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._WEEK_');
            var month = this.$translate.instant('PROPOSAL.NEW_SERVICE.UNIT._MONTH_');

            this.serviceUnits = [
                { label: none, key: null },
                { label: item, key: item },
                { label: hour, key: hour },
                { label: day, key: day },
                { label: week, key: week },
                { label: month, key: month }
            ];
        },

        _setServiceUnit: function _setServiceUnit(unit) {
            this.serviceUnit = {
                label: unit
            };
        },

        _setPricePerUnit: function _buildViewModel() {
            if (this._.isNumber(this.serviceModel.price) && !this._.isNumber(this.serviceModel.price_per_unit)) {
                if (!this._.isNumber(this.serviceModel.quantity)) {
                    this.pricePerUnit = this.serviceModel.price;
                } else if (this.serviceModel.price % this.serviceModel.quantity === 0) {
                    this.pricePerUnit = this.serviceModel.price / this.serviceModel.quantity;
                }
            } else {
                this.pricePerUnit = this.serviceModel.price_per_unit;
            }
        },

        digitsOnly: function digitsOnly($event, decimalPrecision, preventDecimals, allowNegative) {
            this.UIUtils.digitsOnly($event, decimalPrecision, preventDecimals, allowNegative);
        },

        isSelectionDisabled: function isSelectionDisabled() {
            return (this.isPreviewMode || (this.fileModel && (this.fileModel.isComplete() || this.fileModel.isCanceled())));
        },

        isQuantityEditable: function isQuantityEditable() {

            var isFile = !!(this.fileModel && this.fileModel.isModelOf('WorkspaceFile'));
            var isFileCanceled = isFile && this.fileModel.isCanceled();
            var isFileCompleted = isFile && this.fileModel.isComplete();

            if(isFileCanceled) {
                return false;
            }

            if(this.isEditMode()) {
                return !this.isQuantityEditableByClient();
            }

            return (!this.isOwnerMode && !isFileCompleted && this.isQuantityEditableByClient());
        },

        isQuantityEditableByClient: function isQuantityEditableByClient() {
            return this.serviceModel.canClientUpdateQuantity();
        }

        // shouldShowQuantityTooltip: function shouldShowQuantityTooltip() {
        //     return (this.isEditMode() && this.isQuantityEditableByClient());
        // },
    });

    return {
        scope: {
            serviceModel: '=',
            fileModel: '=?',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            focus: '=',
            company: '=',
            isSelectable: '=?',
        },
        replace: false,
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/service/service_directive_new_template.html',
        controller: ServiceDirectiveNewController,
        controllerAs: 'serviceVm',
        bindToController: true
    };
};
