(function () {
    'use strict';

    // @ngInject
    Directives.PipelineMainDirective = function PipelineMainDirective() {

        // @ngInject
        function PipelineMainDirectiveControllerCtor($scope, $injector, $translate, $filter, $timeout, $document, $state, _, $window, moment, JsSlicklightboxService,
                                                     Constants, WorkspacesManager, ReferralsManager, UsersManager, CompaniesManager, ReportsManager, TemplatesViewService,
                                                     AnalyticsService, PopupMessageService, Enums, WorkspaceService, NotificationService, OnScreenNotificationService,
                                                     UiPersistenceService, EventService, OnboardingService, AppStates, DeviceService, UpcomingPaymentsService, NetworkConnectionService,
                                                     ModalService, OnboardingBotManager, EventsManager, $stateParams, PretrialersService, FeaturesService, AbTestService,
                                                     ToastService, $q, SchedulingService, FlowsBetaUserTypeService, DatadogRUMService, WorkspaceFileService,
                                                     FeatureRestrictionService, IntentService, StatsigService, ReactModalService, ContactViewService) {
            var self = this;

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'PipelineMainDirectiveController';
            this.$translate = $translate;
            this.$state = $state;
            this._ = _;
            this.$window = $window;
            this.$timeout = $timeout;
            this.moment = moment;
            this.PopupMessageService = PopupMessageService;
            this.ModalService = ModalService;
            this.WorkspacesManager = WorkspacesManager;
            this.WorkspaceService = WorkspaceService;
            this.TemplatesViewService = TemplatesViewService;
            this.Enums = Enums;
            this.AnalyticsService = AnalyticsService;
            this.NotificationService = NotificationService;
            this.UsersManager = UsersManager;
            this.UiPersistenceService = UiPersistenceService;
            this.pipelineFilter = $filter('HbPipelineWorkspacesFilter');
            this.EventService = EventService;
            this.OnboardingService = OnboardingService;
            this.AppStates = AppStates;
            this.OnboardingBotManager = OnboardingBotManager;
            this.showBrochureQuickSend = this.UsersManager.getCurrUser().last_file_sent_time != null; // only if sent a file
            this.followUpStageOrder = null;
            this.PretrialersService= PretrialersService;
            this.DeviceService = DeviceService;
            this.JsSlicklightboxService = JsSlicklightboxService;
            this.FeaturesService = FeaturesService;
            this.NetworkConnectionService = NetworkConnectionService;
            this.Constants = Constants;
            this.$translate = $translate;
            this.AbTestService = AbTestService;
            this.UpcomingPaymentsService = UpcomingPaymentsService;
            this.ToastService = ToastService;
            this.CompaniesManager = CompaniesManager;
            this.$q = $q;
            this.SchedulingService = SchedulingService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.DatadogRUMService = DatadogRUMService;
            this.WorkspaceFileService = WorkspaceFileService;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.IntentService = IntentService;
            // user model represents the pipeline user, if none is selected we show the whole company
            this.isViewingAllTeamMembers = !this.userModel;
            this.isFetchingWorkspaces = false;
            this.StatsigService = StatsigService;
            this.ReactModalService = ReactModalService;
            this.ContactViewService = ContactViewService;

            /* Empty state how it looks start */
            this.lightboxContainer = "#how-it-looks-pipeline";
            this.galleryInitialized = false;
            /* Empty state how it looks end */

            this.constants = {
                noCategorySelected: -1,
                noStageSelected: -1
            };

            // load user
            this.currUser = this.UsersManager.getCurrUser();
            this.isPreTrialer = this.currUser.shouldStartTrial();

            this.initSessionTypes();

            this.isCurrUser = this.isViewingAllTeamMembers ? false : this.UsersManager.isCurrentUser(this.userModel._id);
            this.MAX_PROJECTS_PER_BATCH = 200;
            this.originalStages = null;
            // init stages with min for page load
            var fakeStageId = 0;
            this.stages = [
                {_id: fakeStageId++, category: "lead", name: "PIPELINE.STAGES._INQUIRY_", counts: {active: 0}},
                {_id: fakeStageId++, category: "lead", name: "PIPELINE.STAGES._FOLLOW_UP_", counts: {active: 0}},
                {_id: fakeStageId++, category: "lead", name: "PIPELINE.STAGES._MEETING_", counts: {active: 0}},
                {_id: fakeStageId++, category: "lead", name: "PIPELINE.STAGES._PROPOSAL_SENT_", counts: {active: 0}},
                {_id: fakeStageId++, name: "PIPELINE.STAGES._PROPOSAL_SIGNED_", counts: {active: 0}},
                {_id: fakeStageId++, name: "PIPELINE.STAGES._RETAINER_PAID_", counts: {active: 0}},
                {_id: fakeStageId++, name: "PIPELINE.STAGES._PLANNING_", counts: {active: 0}},
                {_id: fakeStageId++, category: "other", name: "PIPELINE.STAGES._CLOSED_", counts: {active: 0}},
            ];
            this.stagesHash = {};


            this.isPipelineStagesLoaded = false;
            this.pipelineStagesOnReact = false;
            this.StatsigService.isGateEnabled('ng2react_pipeline_stages_react', false)
                .then(function(res){
                    this.pipelineStagesOnReact = res;
                    this.isPipelineStagesLoaded = true;
                }.bind(this));

            this.stagesDefaultOrder = {
                '_INQUIRY_': 0,
                '_FOLLOW_UP_': 1,
                '_MEETING_': 2,
                '_PROPOSAL_SENT_': 3,
                '_PROPOSAL_SIGNED_': 4,
                '_CONTRACT_SIGNED_': 5,
                '_RETAINER_PAID_': 6,
                '_PLANNING_': 7,
                '_CLOSED_': 8
            };

            // BULK ACTIONS

            this.isBulkActionOn = false;
            this.selectedWorkspacesIds = [];

            this.showMoveToOptions = false;
            this.dropDownIsShowingMsg = true;

            this.FeatureRestrictionService.checkIfBlockedAccess({
                source: 'pipeline',
                actionType: 'load'
            });

            this.talBucketTestVariant = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.talBucketTest);

            this.toggleBulkActions = function toggleBulkActions() {
                this.isBulkActionOn = !this.isBulkActionOn;
                this.selectedWorkspacesIds = [];
            };

            this.selectWorkspace = function selectWorkspace(event, workspaceId) {
                event.stopPropagation();
                if(this.isSelectedWorkspace(workspaceId)) {
                    this.unselectWorkspace(workspaceId);
                } else {
                    this.selectedWorkspacesIds.push(workspaceId);
                }

                if (this.selectedWorkspacesIds.length) {
                    this.isBulkActionOn = true;
                } else {
                    this.isBulkActionOn = false;
                }
            };

            this.unselectWorkspace = function unselectWorkspace(workspaceId) {
                if(this.isSelectedWorkspace(workspaceId)) {
                    this.selectedWorkspacesIds.splice(this.selectedWorkspacesIds.indexOf(workspaceId), 1);
                }
            };

            this.isSelectedWorkspace = function isSelectedWorkspace(workspaceId) {
                var index = this.selectedWorkspacesIds.indexOf(workspaceId);
                return index >= 0;
            };

            this.mapWorkspaces = function mapWorkspaces() {
                self = this;
                this.workspaces.forEach(function(workspace, index) {
                    self.workspacesMap[workspace._id] = workspace;
                    self.workspacesMap[workspace._id].index = index;
                });
            };

            this.removeWorkspaces = function(workspaces) {
                self = this;
                workspaces.forEach(function(workspaceId) {
                    var workspace = self.workspacesMap[workspaceId];
                    if(workspace) {
                        var workspaceClass = workspace.className();
                        var eventId = workspace.__event._id;
                        WorkspacesManager._workspaceDeleted(null, workspaceClass, workspaceId, eventId);
                        this.unselectWorkspace(workspaceId);
                    }
                }.bind(this));
                this.onWorkspaceChanged();
            };

            this.toggleMoveToOptions = function toggleMoveToOptions() {
                this.showMoveToOptions = !this.showMoveToOptions;
            };

            this.getFailedWorkspacesNames = function getFailedWorkspacesNames(data) {
                var self = this;
                var failedWorkspacesNames = null;
                if(data.workspaces && data.workspaces.failed && data.workspaces.failed.length > 0) {
                    failedWorkspacesNames = [];
                    this.mapWorkspaces();
                    data.workspaces.failed.forEach(function(workspaceId) {
                        if(self.workspacesMap[workspaceId]) {
                            failedWorkspacesNames.push(self.workspacesMap[workspaceId].event.event_name);
                        }
                    });
                }
                return failedWorkspacesNames;
            }.bind(this);

            this.onArchiveWorkspaces = function onArchiveWorkspaces(blockReason) {
                if (!this.selectedWorkspacesIds || !this.selectedWorkspacesIds.length) {
                    return;
                }

                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_bulk_action, {action: 'archive'});

                var self = this;
                var handleArchiveReason = function handleArchiveReason(reason) {
                    self.AnalyticsService.trackConfirm(
                        self,
                        'bulk action',
                        {
                            action: 'archive',
                            numOfWorkspaces: self.selectedWorkspacesIds.length,
                            blockReason: reason
                        }
                    );

                    var pendingTaskPromise = WorkspacesManager.archiveWorkspaces(
                       self.selectedWorkspacesIds,
                       reason,
                        true
                    );

                   self.mapWorkspaces();
                   self.ModalService.openPendingActionModal(
                        pendingTaskPromise,
                        self.workspacesMap,
                        'WORKSPACE.ARCHIVE._BULK_PENDING_TITLE',
                        'WORKSPACE.ARCHIVE._BULK_FAILED_TITLE_',
                        'WORKSPACE.BULK_GENERAL._FAILED_MSG_',
                         'https://help.honeybook.com/en/articles/2761219-bulk-updating-projects-through-the-project-pipeline',
                        'OK',
                        'archive',
                       self.getFailedWorkspacesNames
                    );

                    pendingTaskPromise.then(function onSuccess(resp) {
                        if(resp.workspaces) {
                            var successfulWorkspacesLength = resp.workspaces.successful.length;
                            if(successfulWorkspacesLength > 0) {
                                self.AnalyticsService.trackSuccess(
                                    self,
                                    'bulk action',
                                    {
                                        action: 'archive',
                                        numOfWorkspaces: successfulWorkspacesLength
                                    }
                                );
                                resp.workspaces.successful.forEach(function (workspaceId) {
                                    self.workspacesMap[workspaceId].active_state = false;
                                    self.WorkspacesManager._workspaceBlockedSuccess.bind(self);
                                    self.unselectWorkspace(workspaceId);

                                    // onWorkspaceChanged only removes workspace from view
                                    // when we pass it a single workspace
                                    // so in this case we need to perform removeWorkspace here
                                    var workspace = self.workspaces.find(function(ws) {
                                        return ws._id === workspaceId;
                                    });
                                    if (workspace) {
                                        self.removeWorkspace(workspace);
                                    }
                                });
                                self.$timeout(function () {
                                    self.onWorkspaceChanged();
                                }, 1200); // additinal timeout so that ES has time to index

                                if(!resp.workspaces.failed.length) {
                                    self.toggleBulkActions();
                                }

                                self.ToastService.showSuccess({
                                    content: self.$translate.instant(
                                        "PIPELINE.TOASTS._BULK_ARCHIVE_",
                                        {
                                            plural: successfulWorkspacesLength === 1 ? "" : "s",
                                            count: successfulWorkspacesLength
                                        }
                                    ),
                                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                                    dismissOnTimeout: true,
                                    dismissButton: true,
                                    timeout:3000
                                });
                            }
                        }
                    })
                    .catch(function onFailure(resp) {
                        var msg = resp && resp.data && resp.data.error_message || 'WORKSPACE.ARCHIVE._ERROR_';
                        self.AnalyticsService.trackError(self, 'bulk action', {action: 'archive', error_message: msg});
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, msg);
                    });
                };

                this.PopupMessageService.showConfirm(
                    this.PopupMessageService.severityTypes.none,
                    this.$translate.instant('PIPELINE.POPUPS.ARCHIVE._MESSAGE_', {
                        project_count: this.selectedWorkspacesIds.length,
                        plural: this.selectedWorkspacesIds.length === 1 ? '' : 's'
                    }),
                    function () { handleArchiveReason(blockReason); },
                    _.noop,
                    'PIPELINE.POPUPS.ARCHIVE._CONFIRM_',
                    'PIPELINE.POPUPS.ARCHIVE._CANCEL_'
                );
            };

            this.onDeleteWorkspaces = function onDeleteWorkspaces() {
                var self = this;

                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_bulk_action, {action: 'delete'});

                var onDeleteWorkspacesConfirmed = function onDeleteWorkspacesConfirmed() {
                    self.AnalyticsService.trackConfirm(self, 'bulk action', {action: 'delete', numOfWorkspaces: self.selectedWorkspacesIds.length});
                    var pendingTaskPromise = WorkspacesManager.deleteWorkspaces(self.selectedWorkspacesIds);
                    self.mapWorkspaces();
                    self.ModalService.openPendingActionModal(
                        pendingTaskPromise,
                        self.workspacesMap,
                        'WORKSPACE.DELETE._PENDING_TITLE_',
                        'WORKSPACE.DELETE._BULK_FAILED_TITLE_',
                        'WORKSPACE.BULK_GENERAL._FAILED_MSG_',
                        'https://help.honeybook.com/en/articles/2761219-bulk-updating-projects-through-the-project-pipeline',
                        'OK',
                        'delete',
                        self.getFailedWorkspacesNames
                    );
                    pendingTaskPromise.then(function onSuccess(resp) {
                        if(resp.workspaces) {
                            var successfulWorkspacesLength = resp.workspaces.successful.length;
                            if(successfulWorkspacesLength > 0) {
                                self.AnalyticsService.trackSuccess(self, 'bulk action', {action: 'delete', numOfWorkspaces: successfulWorkspacesLength});
                                self.removeWorkspaces(resp.workspaces.successful);
                                if(!resp.workspaces.failed.length) {
                                    self.toggleBulkActions();
                                }
                                self.ToastService.showSuccess({
                                    content: self.$translate.instant(
                                        "PIPELINE.TOASTS._BULK_DELETE_",
                                        {
                                            plural: successfulWorkspacesLength === 1 ? "" : "s",
                                            count: successfulWorkspacesLength
                                        }
                                    ),
                                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                                    dismissOnTimeout: true,
                                    dismissButton: true,
                                    timeout:3000
                                });
                            }
                        }
                    })
                        .catch(function onFailure(resp) {
                            var msg = resp && resp.data && resp.data.error_message || 'WORKSPACE.DELETE._ERROR_';
                            self.AnalyticsService.trackError(self, 'bulk action', {action: 'delete', error_message: msg});
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, msg);
                        });
                };
                if (this.selectedWorkspacesIds && this.selectedWorkspacesIds.length > 0) {
                    this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning, this.$translate.instant('WORKSPACE.DELETE._BULK_FULL_MESSAGE_', {workspaces_count: self.selectedWorkspacesIds.length}), onDeleteWorkspacesConfirmed, _.noop(), 'WORKSPACE.DELETE._CONFIRM_BULK_DELETE_');
                }

            };

            this.onMoveWorkspaces = function onMoveWorkspaces(stageId) {
                if (!this.selectedWorkspacesIds || !this.selectedWorkspacesIds.length) {
                    return;
                }

                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_bulk_action, {action: 'move stage'});

                var self = this;
                var stageName = (this.stagesHash[stageId] || {}).name;

                var onMoveStageConfirm = function onMoveStageConfirm(stageId) {
                    self.AnalyticsService.trackConfirm(
                        self,
                        'bulk action',
                        {
                            action: 'move',
                            numOfWorkspaces: self.selectedWorkspacesIds.length,
                            stage: stageId
                        }
                    );

                    var pendingTaskPromise = WorkspacesManager.moveWorkspaces(
                       self.selectedWorkspacesIds,
                       stageId
                    );

                   self.mapWorkspaces();
                   self.ModalService.openPendingActionModal(
                        pendingTaskPromise,
                        self.workspacesMap,
                        'WORKSPACE.MOVE._BULK_PENDING_TITLE_',
                        'WORKSPACE.MOVE._BULK_FAILED_TITLE_',
                        '',
                        'OK',
                         'https://help.honeybook.com/en/articles/2761219-bulk-updating-projects-through-the-project-pipeline',
                        'move'
                    );

                    pendingTaskPromise.then(function onSuccess(resp) {
                        if(resp.workspaces) {
                            var successfulWorkspacesLength = resp.workspaces.successful.length;
                            if(successfulWorkspacesLength > 0) {
                                self.AnalyticsService.trackSuccess(
                                    self,
                                    'bulk action',
                                    {
                                        action: 'move',
                                        numOfWorkspaces: successfulWorkspacesLength
                                    }
                                );

                                resp.workspaces.successful.forEach(function (workspaceId) {
                                    // onWorkspaceChanged only removes workspace from view
                                    // when we pass it a single workspace
                                    // so in this case we need to perform removeWorkspace here
                                    var workspace = self.workspaces.find(function(ws) {
                                        return ws._id === workspaceId;
                                    });
                                    if (workspace) {
                                        self.removeWorkspace(workspace);
                                    }
                                });

                                self.$timeout(function () {
                                    self.onWorkspaceChanged();
                                }, 1200);  // additinal timeout so that ES has time to index

                                if(!resp.workspaces.failed.length) {
                                    self.toggleBulkActions();
                                }

                                self.ToastService.showSuccess({
                                    content: self.$translate.instant(
                                        "PIPELINE.TOASTS._BULK_MOVE_",
                                        {
                                            plural: successfulWorkspacesLength === 1 ? "" : "s",
                                            count: successfulWorkspacesLength,
                                            stageName: stageName
                                        }
                                    ),
                                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                                    dismissOnTimeout: true,
                                    dismissButton: true,
                                    timeout:3000
                                });
                            }
                        }
                    })
                    .catch(function onFailure(resp) {
                        var msg = resp && resp.data && resp.data.error_message || 'WORKSPACE.MOVE._BULK_ERROR_';
                        self.AnalyticsService.trackError(self, 'bulk action', {action: 'move', error_message: msg});
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, msg);
                    });
                };


                this.PopupMessageService.showConfirm(
                    this.PopupMessageService.severityTypes.none,
                    this.$translate.instant('PIPELINE.POPUPS.MOVE._MESSAGE_', {
                        project_count: this.selectedWorkspacesIds.length,
                        plural: this.selectedWorkspacesIds.length === 1 ? '' : 's',
                        stageName: stageName
                    }),
                    function () { onMoveStageConfirm(stageId); },
                    _.noop,
                    'PIPELINE.POPUPS.MOVE._CONFIRM_',
                    'PIPELINE.POPUPS.MOVE._CANCEL_'
                );
            };

            this.showActive = true;
            this.showAllStages = false;
            this.selectedStage = this.constants.noStageSelected;

            this.currentSelectionActiveCounter = 0;
            this.dontShowContactFormIntro = true;
            this.perPage = 25; // can be tuned...
            this.hasMoreWorkspacesToShow = false;
            this.shownWorkspacesLimit= 0;//until we get data, relevant for client side only

            this.gettingWorkspacesFirstPage = true;
            this.gettingWorkspacesNextPage = true;
            this.gettingWorkspacesCounts = true;
            this.requestedInitialWorkspacesPage = false;
            this.workspacesCounts = {counts: {active: 0, inactive: 0},category_booked_counts:{active: 0},category_lead_counts:{active: 0}};
            this.workspaces = this.WorkspacesManager.getWorkspacesMasterCollection();
            this.workspacesMap = {};

            this.serverSidePaging = true ; // using server(true) or client(false) paging. Default is server unless decided otherwise.
            this.filteredWorkspaces = null;  // used only on client side paging

            this.setStagesData = function setStagesData() {
                this.stagesData = {
                    showActive: this.showActive,
                    showAllStages: this.showAllStages,
                    onShowAllStagesClicked: function () {this.onShowAllStagesClicked()}.bind(this),
                    stages: this.stages,
                    onShowArchivedClicked: function () {this.onShowArchivedClicked()}.bind(this),
                    onStageClicked: function (stage) { this.onStageClicked(stage)}.bind(this),
                    workspacesCounts: this.workspacesCounts,
                    selectedStage: this.selectedStage
                };
            }
            this.loadWorkspaceCounts = function loadWorkspaceCounts(initialLoading) {
                var formerHighlightStateByStageId = null;
                if (initialLoading){
                    self.gettingWorkspacesCounts = true;
                }else{
                    formerHighlightStateByStageId = {};
                    for (var stageIndex = 0, sz = this.stages.length; stageIndex < sz; stageIndex++){
                        var currentStage = this.stages[stageIndex];
                        formerHighlightStateByStageId[currentStage._id] = currentStage.highlighted;
                    }
                }

                // get the counts
                this.WorkspacesManager.getPipelineWorkspacesCounts(this.userIds)
                    .then(function success(res) {
                        this.workspacesCounts = res.data;
                        this.hasRecentActivity = res.data.has_recent_activity;
                        this.initializeStagesByWorkspaceCountsRes(formerHighlightStateByStageId);
                        this.setCurrentSelectionActiveAndInactiveCounters();
                        this.gettingWorkspacesCounts = false;

                        // Check if stage has been emptied of workspaces and has more that need to be fetched
                        var workspaces = this.getCurrentVisibleWorkspaces();
                        var updatedSelectedStage =  this.workspacesCounts.stages[this.selectedStage._id];
                        var updatedSelectedStageWorkspaceCount;
                        if (updatedSelectedStage && updatedSelectedStage.counts) {
                            updatedSelectedStageWorkspaceCount = updatedSelectedStage.counts.active;
                        } else {
                            updatedSelectedStageWorkspaceCount = null;
                        }

                        this.setStagesData();

                        if (
                            updatedSelectedStageWorkspaceCount &&
                            workspaces.length < updatedSelectedStageWorkspaceCount &&
                            workspaces.length < this.perPage
                        ) {
                            this.resetWorkspacesIfNeeded();
                        }

                        this.calcElementsLoading();
                        this.selectBanner();
                    }.bind(this))
                    .catch(function error(err) {
                        this.AnalyticsService.trackError(this, 'Pipeline Error getting the user\'s workspace counts:', err,
                            {
                                onLine: navigator && navigator.onLine,
                                isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                            });
                        this.DatadogRUMService.addError(
                            'Pipeline Error getting the user\'s workspace counts',
                            {
                                message: err, action: 'getPipelineWorkspacesCounts',
                                view_all_available: this.viewAllAvailable(),
                                onLine: navigator && navigator.onLine,
                                isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                            });
                        this.errorPreparingThePage = true;
                        this.gettingWorkspacesCounts = false;
                        this.calcElementsLoading();
                    }.bind(this));
            };


            this.getCurrentWorkpsacesPage = function getCurrentWorkpsacesPage() {
                if (this.currentWorkspacesPage === 1) {
                    this.gettingWorkspacesFirstPage = true;
                } else {
                    // should only happen in this.serverSidePaging
                    this.gettingWorkspacesNextPage = true;
                }
                this.calcElementsLoading();

                var getPipelineWorkspacesParams;
                if (this.serverSidePaging) {
                    // server side paging - get only the page needed with filtering and sorting applied.
                    getPipelineWorkspacesParams = {
                        showArchive: !this.showActive,
                        page: this.currentWorkspacesPage,
                        perPage: this.perPage,
                        stageId: ((this.selectedStageId !== this.constants.noStageSelected) && this.selectedStageId)  || ((this.selectedStage !== this.constants.noStageSelected) && this.selectedStage._id),
                        sortBy: this.sortingModel.currentSorting.serverSortKey,
                        sortDesc: this.sortingModel.currentSorting.sortReverse || false
                    };
                } else {
                    // client side paging - greedy mode, getting it all!  we'll use client side paging and filtering.
                    getPipelineWorkspacesParams = {
                        showArchive: 'both'
                    };
                }

                if (!getPipelineWorkspacesParams.stageId) {
                    this.showAllStages = true;
                }

                this.setStagesData();

                getPipelineWorkspacesParams.userIds = (this.isCurrUser ? null : this.userIds);

                getPipelineWorkspacesParams.extended_results = true
                this.WorkspacesManager.getPipelineWorkspaces(this.workspaces, getPipelineWorkspacesParams);
            };

            this.resetWorkspacesIfNeeded = function resetWorkspaces() {
                // on client side paging, do this only once
                if (this.serverSidePaging || !this.requestedInitialWorkspacesPage){
                    this.isFetchingWorkspaces = true;
                    this.requestedInitialWorkspacesPage = true;

                    this.gettingWorkspacesFirstPage = true;
                    this.calcElementsLoading();
                    this.hasMoreWorkspacesToShow = false; // assuming

                    // reset and get the first page
                    this.workspaces.splice(0,this.workspaces.length);
                    this.currentWorkspacesPage = 1;
                    this.getCurrentWorkpsacesPage();
                }
            };

            this.getNextWorkpsacesPage = function getNextWorkpsacesPage() {
                if (this.serverSidePaging){
                    this.currentWorkspacesPage += 1;
                    this.getCurrentWorkpsacesPage();
                    this.hasMoreWorkspacesToShow = false;//assume its the end until res arrives
                }
            };

            this.leadStages = [];
            this.bookedStages = [];

            this.preparingPage = false;
            this.errorPreparingThePage = false;
            this.filtering = false;
            this.wasContactFormPromoShown = false;

            this.createEventText = 'WORKSPACE.FILES._CREATE_PROJECT_TWO_';

            this.sortingModel = {
                currentSorting: null,
                createdAt: {sortName: 'createdAt', sortKey: 'created_at', serverSortKey: 'created_at', sortReverse: true, sortByLabel: 'PIPELINE.SORT._CREATE_DATE_'},
                eventCreationDate: {sortName: 'eventCreationDate', sortKey: 'event.event_date', serverSortKey: 'event_date', sortReverse: false, sortByLabel: 'PIPELINE.SORT._PROJECT_DATE_'},
                alphabetically: {sortName: 'alphabetically', sortKey: 'event.event_name', serverSortKey: 'event_name', sortReverse: false, sortByLabel: 'PIPELINE.SORT._ALPHABETICAL_'},
                stageSort: {sortName:'stageSort',sortKey: [function stageSort1(e) { return self.stageSort1(e);}, 'event.event_date'], serverSortKey: 'stage', sortReverse: false, sortByLabel: 'Stage-Sort'},
                movedSort: {sortName:'movedSort',sortKey: 'workspace_pipeline_data.current_stage.moved_on', serverSortKey: 'stage_moved_on', sortReverse: true, sortByLabel: 'Moved-Sort'},
                recentActivity: {sortName: 'recentActivity', sortKey: 'recent_activity_timestamp', serverSortKey: 'recent_activity', sortReverse: false, sortByLabel: 'PIPELINE.SORT._RECENT_ACTIVITY_'},
                leadSource: {sortName: 'leadSource', sortKey: 'event.lead_source', serverSortKey: 'lead_source', sortReverse: false, sortByLabel: 'PIPELINE.SORT._LEAD_SOURCE_'},
                projectType: {sortName: 'projectType', sortKey: 'event.event_type', serverSortKey: 'project_type', sortReverse: false, sortByLabel: 'PIPELINE.SORT._PROJECT_TYPE_'},
                archiveReason: {sortName: 'archiveReason', sortKey: [function archiveReasonSort(e) { return self.archiveReasonSort(e); }, 'event.event_date'], serverSortKey: 'archive_reason', sortReverse: false, sortByLabel: 'PIPELINE.SORT._ARCHIVE_REASON_'},
                serialize: function () {
                    return {
                        sortName: this.currentSorting.sortName,
                        sortReverse: this.currentSorting.sortReverse,
                        stageId: (self.selectedStage !== self.constants.noStageSelected) && self.selectedStage._id,
                        showActive: self.showActive
                    };
                },

                deserialize: function (serialization) {
                    this.currentSorting = this[serialization.sortName];
                    this.currentSorting.sortReverse = serialization.sortReverse;
                    self.selectedStageId = serialization.stageId ? serialization.stageId : self.constants.noStageSelected;
                    self.showActive = serialization.showActive;
                    self.showAllStages = self.showActive && self.selectedStageId === self.constants.noStageSelected;
                    self.setStagesData();
                }
            };

            this.calcElementsLoading = function calcElementsLoading() {
                this.elementsLoading = this.gettingWorkspacesCounts || this.gettingWorkspacesFirstPage || this.gettingWorkspacesNextPage || this.preparingPage || this.filtering;
                if (!this.elementsLoading) {
                    this.$window.performance && this.$window.performance.mark('hb_pipeline_shown');
                }
            };


            this.showCreateNextProjectMaxCount = 5;
            if (!this.OnboardingService.isUserSubscribed()){ //top nav create event button in hidden
                this.showCreateNextProjectMaxCount = 20;
            }


            // This takes a workspace and uses the stagesHash function and returns a numbered order to it for sorting.
            this.stageSort1 = function stageSort1(e){
                var stageToSort = this.stagesHash[e.workspace_pipeline_data.current_stage.stage_id];
                if(!stageToSort){
                    //there could be a race condition between the sorting function and the fact
                    //that some stages have been removed. if we cannot find the stage in the company
                    //settings return 0 for now. it will be refreshed and resorted afterwords when
                    //the new company data comes.
                    return 0;
                }

                if(this.currentUserCompany.pipeline_version === 0){
                    var theStageOriginalName = stageToSort.originalName;
                    return this.stagesDefaultOrder[theStageOriginalName];
                } else {
                    //it's bigger then 0. it means that the owner of the company has edited the stages.
                    //sort according to the new order of the stages
                    return stageToSort.order;
                }
            };

            // Creates a hash map of archive reason server value to archive reason display name
            // When sorting on the client side this will allow for efficient sorting by
            // archive reason
            this.blockReasonDisplayKeyByServerValue = this.Constants.BlockReasons.reduce(
                function (acc, blockReason) {
                    acc[blockReason.serverValue] = this.$translate.instant(blockReason.displayNameKey);
                    return acc;
                }.bind(this),
                {}
            )

            this.archiveReasonSort = function archiveReasonSort(e) {
                return this.blockReasonDisplayKeyByServerValue[
                    e.workspace_pipeline_data.blocked_tag
                ];
            }

            this.setSort = function (sortingModel, withoutPersistence) {
                this.sortingModel.currentSorting = sortingModel;
                if (!withoutPersistence) {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipeline_view, this.sortingModel.serialize());
                }
            };

            this.sortIt = function (sortingModel, sortReverse) {
                // check that there's no an unfinished fetch of workspaces
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_column_sort, {column: sortingModel.sortName});
                if(!this.isFetchingWorkspaces) {
                    // Reverse the sort if needed
                    if (this.sortingModel.currentSorting.sortKey === sortingModel.sortKey) {
                        sortingModel.sortReverse = !sortingModel.sortReverse;
                    }
                    this.setSort(sortingModel);

                    this.resetWorkspacesIfNeeded();
                } else {
                    this.nextSort = {
                        sortingModel: sortingModel,
                        sortReverse: sortReverse
                    };
                }
            };

            this.unhighlightStages = function unhighlightStages(stages) {
                stages.forEach(function (stage) {
                    stage.highlighted = false;
                });
            };

            this.highlightStages = function highlightStages(stages) {
                stages.forEach(function (stage) {
                    stage.highlighted = true;
                });
            };

            //set the default sorting
            var uiPersistence = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.pipeline_view);
            if (uiPersistence) {
                this.sortingModel.deserialize(uiPersistence);
            } else {
                this.setSort(this.sortingModel.movedSort, true);
                this.selectedStage = this.constants.noStageSelected;
                this.selectedStageId = this.constants.noStageSelected;
                this.showActive = true;
                this.showAllStages = true;
            }

            if ($stateParams.initialStageId) {
                this.selectedStageId = $stateParams.initialStageId;
                this.showAllStages = false;
                this._setSortByParam('createdAt');
            } else if ($stateParams.initialSort) {
                this._setSortByParam($stateParams.initialSort);
            }


            var analyticsArgs = {
                has_unseen_notifications: this.currUser.has_unseen_notifications,
                view_all_test_variant: this.viewAllAvailable() ? 'test' : 'control'
            };
            if (this.invoiceBannerTestVariant) {
                analyticsArgs['invoice_banner_in_pipeline_variation'] = this.invoiceBannerTestVariant;
            }
            AnalyticsService.trackPageView(this, 'pipeline', analyticsArgs);
            //NOTE: Pipeline logic will only work properly if current user and provided userModel share the same company
            this.currentUserCompany = CompaniesManager.getCurrCompany(true);

            this.userIds = this.isViewingAllTeamMembers ?
                this.currentUserCompany.getAllCompanyMembers().map(function (usr) {
                    return usr._id;
                }) :
                [this.userModel._id];

            // deprecated - shouldShowUpcomingPaymentModal('pipeline') always returns false
            if (this.UpcomingPaymentsService.shouldShowUpcomingPaymentModal('pipeline')) {
                this.UpcomingPaymentsService.showUpcomingPaymentModal('pipeline')
                    .then(function onSuccess(userDidTogglePayments){
                        if (userDidTogglePayments) {
                            this._promoteInstantDepositAutoToggle('upcomingPaymentsModal');
                        }
                    }.bind(this))
                    .catch(function err(error) {
                        console.warn('showUpcomingPaymentModal problem', error);
                    }.bind(this));
            }


            this.register(this.currentUserCompany, 'success', function success() {
                this.preparingPage = false;
            });
            this.register(this.currentUserCompany ,'error', function error(errorMessage) {
                this.AnalyticsService.track(this, 'Pipeline Error getting the user\'s user_pipeline_stages',
                    {
                        onLine: navigator && navigator.onLine,
                        isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                    });
                this.preparingPage = false;
                this.errorPreparingThePage = true;
                this.DatadogRUMService.addError(new Error('Pipeline Error getting the user\'s user_pipeline_stages'), {
                    message: errorMessage,
                    view_all_available: this.viewAllAvailable(),
                    onLine: navigator && navigator.onLine,
                    isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                });
            });

            this.mobileSearchCollapse = function mobileSearchCollapse() {
                this.mobileSearchOpen = !this.mobileSearchOpen;
            };

            this.initializeStagesByWorkspaceCountsRes = function (formerHighlightStateByStageId) {
                if (this.workspacesCounts.user_pipeline_stages) {
                    this.stages = this.workspacesCounts.user_pipeline_stages;
                    this.stagesHash = {};
                    this.leadStages = [];
                    this.bookedStages = [];

                    //create a hash map so getting the stages according to the id would be cheaper.
                    this.stages.forEach(function (stage, index) {
                        stage.originalName = stage.name;
                        stage.name = this.WorkspaceService.getPipelineStageName(stage.name);
                        this.stagesHash[stage._id] = stage;
                        if (this.selectedStageId && stage._id === this.selectedStageId) {
                            this.selectedStage = stage;
                            this.setEmptyStateData(stage);
                            stage.highlighted = true;
                            this.showAllStages = false;
                            this.showActive = true;
                            this.selectedStageId = this.constants.noStageSelected;
                        } else {
                            stage.highlighted = false;
                        }
                        stage.order = stage.order == null ? index : stage.order;

                        if(stage.originalName === '_FOLLOW_UP_'){
                            this.followUpStageOrder = stage.order;
                        }

                        if (formerHighlightStateByStageId) {
                            stage.highlighted = formerHighlightStateByStageId[stage._id] || false;
                        }
                        if (stage.category === Enums.WorkspaceStageCategory.lead) {
                            this.leadStages.push(stage);
                        } else if (stage.category === Enums.WorkspaceStageCategory.booked) {
                            this.bookedStages.push(stage);
                        }
                    }.bind(this));
                    //handle deleted stage, reload all active instead
                    var selectedStage = this._.find(this.stages, function(stage) {
                        return stage.highlighted === true;
                    }.bind(this));
                    if (!selectedStage && this.showActive && this.selectedStageId !== this.constants.noStageSelected) {
                        this.setSort(this.sortingModel.movedSort);
                        this.selectedStage = this.constants.noStageSelected;
                        this.selectedStageId = this.constants.noStageSelected;
                        this.showActive = true;
                        this.showAllStages = true;
                        this.resetWorkspacesIfNeeded();
                    }
                    this.setStagesData();
                }
            };

            this.showStageColumn = function () {
                return this.selectedStage === this.constants.noStageSelected &&
                       this.showActive;
            };

            this.showLeadSourceColumn = function () {
                return this.isLeadsStage();
            };

            // #region DYNAMIC COLUMN VISIBILIY

            this.getDynamicColumnVisability = function () {
                return {
                    stage: this.showStageColumn(),
                    leadSource: this.showLeadSourceColumn(),
                    archiveReason: !this.showActive,
                    downloadReport: !this.showActive && this.isCurrUser,
                    recentActivity: this.showActive && this.isCurrUser
                };
            };

            this.visibleDynamicColumns = this.getDynamicColumnVisability();
            $scope.$watch('pipelineMainVm.selectedStage', function() {
                this.visibleDynamicColumns = this.getDynamicColumnVisability();
                this.selectBanner();
            }.bind(this));

            // #endregion

            this.onShowAllStagesClicked = function () {
                this.unhighlightStages(this.stages);
                this.showActive = true;
                this.showAllStages = true;
                if (!this.isFetchingWorkspaces) {
                    this.selectedStage = this.constants.noStageSelected;
                    this.showActive = true;
                    this.setSort(this.sortingModel.currentSorting);
                    this.setCurrentSelectionActiveAndInactiveCounters();
                    this.resetWorkspacesIfNeeded();
                } else {
                    this.nextStage = this.constants.noStageSelected;
                }
                this.setEmptyStateData();

                // This is usually updated on a watch on selectedStage but
                // when moving from  active projects to archived the selected stage
                // doesn't change, so it needs some special loving...
                this.visibleDynamicColumns = this.getDynamicColumnVisability();
            };

            this.onShowArchivedClicked = function () {
                this.unhighlightStages(this.stages);
                this.showActive = false;
                this.showAllStages = true;
                if (!this.isFetchingWorkspaces) {
                    this.selectedStage = this.constants.noStageSelected;
                    this.setSort(this.sortingModel.currentSorting);
                    this.setCurrentSelectionActiveAndInactiveCounters();
                    this.resetWorkspacesIfNeeded();
                } else {
                    this.nextStage = this.constants.noStageSelected;
                }
                this.setEmptyStateData();

                // This is usually updated on a watch on selectedStage but
                // when moving from  active projects to archived the selected stage
                // doesn't change, so it needs some special loving...
                this.visibleDynamicColumns = this.getDynamicColumnVisability();
            };

            this.onStageClicked = function (stage) {
                this.unhighlightStages(this.stages);
                this.highlightStages([stage]);
                this.showAllStages = false;
                this.showActive = true;
                if (!this.isFetchingWorkspaces) {
                    this.selectedStage = stage;
                    this.setSort(this.sortingModel.currentSorting);
                    this.setCurrentSelectionActiveAndInactiveCounters();
                    this.resetWorkspacesIfNeeded();
                } else {
                    this.nextStage = this.constants.noStageSelected;
                }
                this.setEmptyStateData(stage);
            };

            this.setCurrentSelectionActiveAndInactiveCounters = function () {
                if (!this.stages) {
                    //there could be a case where we got the workspace data before the company's data (so: no stages for you... yet)
                    //it will be updated when the company data will come.
                    return;
                }

                if (this.selectedStage !== this.constants.noStageSelected) {
                    var updatedSelectedStage = this.workspacesCounts.stages[this.selectedStage._id];
                    if (updatedSelectedStage && updatedSelectedStage.counts) {
                        this.currentSelectionActiveCounter = updatedSelectedStage.counts.active;
                    }
                } else {
                    if (!this.showActive && this.showAllStages) {
                        this.currentSelectionActiveCounter = this.workspacesCounts.counts.inactive;
                    } else {
                        this.currentSelectionActiveCounter = this.workspacesCounts.counts.active;
                    }
                }
            };

            this.onShowActiveClicked = function () {
                this.showActive = true;
                this.resetWorkspacesIfNeeded();
            };

            this.onShowInactiveClicked = function () {
                this.showActive = false;
                this.resetWorkspacesIfNeeded();
            };

            $scope.$watch('pipelineMainVm.showActiveWorkspaces', function(newVal, oldVal) {
                if(oldVal !== undefined && oldVal !== newVal) {
                    if(newVal) {
                        this.onShowActiveClicked();
                    } else {
                        this.onShowInactiveClicked();
                    }
                }
            }.bind(this));

            this.onWorkspaceChanged = function (workspace) {
                this.$timeout(function () {
                    this.loadWorkspaceCounts(false); // refresh counts
                }.bind(this), 1200); // must be more then elastic refresh interval
                if (!this.serverSidePaging){
                    this.filterWorkspaces();
                } else if (workspace) {
                    this.removeWorkspace(workspace);
                }

            };

            this.register(this.workspaces, 'success', function(successType, respStatus, respIsComplete) {
                if (this.serverSidePaging){
                    this.hasMoreWorkspacesToShow = !respIsComplete;
                }

                if(!this._.isEmpty(this.stagesHash)){
                    var hasAWorkspaceWithBrochureQuickSendButton = false;
                    this._.forEach(this.workspaces, function iter(workspace) {
                        var currentWorkspaceStage;
                        if (!workspace.workspace_pipeline_data) {
                            currentWorkspaceStage = this.stages[0];
                        } else {
                            currentWorkspaceStage = this.stagesHash[workspace.workspace_pipeline_data.current_stage.stage_id];
                        }

                        if (currentWorkspaceStage && currentWorkspaceStage.order <= this.followUpStageOrder) {
                            hasAWorkspaceWithBrochureQuickSendButton = true;
                        }
                    }.bind(this));
                }

                this.gettingWorkspacesFirstPage = false;
                this.gettingWorkspacesNextPage = false;
                this.filterWorkspaces();
                this.calcElementsLoading();

                this.isFetchingWorkspaces = false;
                // check if user asked for new pipeline stage
                if(this.nextStage) {
                    if(this.nextStage === this.constants.noStageSelected) {
                        this.onShowAllStagesClicked();
                        this.nextStage = false;
                    } else {
                        this.onStageClicked(this.nextStage);
                        this.nextStage = false;
                    }
                }

                // check if user asked for new sort
                if(this.nextSort) {
                    this.sortIt(this.nextSort.sortingModel, this.nextSort.sortReverse);
                    this.nextSort = false;
                }
            });

            this.workspaces.on('error', function error(errorMessage) {
                this.AnalyticsService.track(this, 'Pipeline Error getting the user\'s workspace collections:',
                    {
                        onLine: navigator && navigator.onLine,
                        isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                    });
                this.errorPreparingThePage = true;
                this.DatadogRUMService.addError(new Error('Pipeline Error getting the user\'s workspace collections'), {
                    message: errorMessage,
                    view_all_available: this.viewAllAvailable(),
                    onLine: navigator && navigator.onLine,
                    isOfflineWithNetworkErrors: this.NetworkConnectionService.isOfflineWithNetworkErrors()
                });
                this.gettingWorkspacesFirstPage = false;
                this.gettingWorkspacesNextPage = false;
            }.bind(this));

            this.filterWorkspaces = function () {
                if (!this.serverSidePaging){
                    this.filtering = true;
                    this.calcElementsLoading();
                    $timeout(function () {
                        this.filteredWorkspaces = this.pipelineFilter(this.workspaces, this.stagesHash, this.showActive, this.selectedStage._id, null, this.showAllStages);
                        this.shownWorkspacesLimitMax = this.filteredWorkspaces.length;
                        this.updateShownWorkspacesLimit(this.perPage);
                        $timeout(function () {
                            this.filtering = false;
                            this.calcElementsLoading();
                        }.bind(this), 100);
                    }.bind(this), 100);
                }
            };

            var filterWorkspacesListener = this.filterWorkspaces.bind(this);
            $scope.$watch('pipelineMainVm.stagesHash', filterWorkspacesListener);
            $scope.$watch('pipelineMainVm.showActive', filterWorkspacesListener);
            $scope.$watch('pipelineMainVm.selectedStage._id', filterWorkspacesListener);
            $scope.$watch('pipelineMainVm.showAllStages', filterWorkspacesListener);

            this.createEvent = function createFirstEvent() {
                if (this.workspacesCounts.counts.all === 0 && this.OnboardingBotManager.isOnboardingBotActive()) {
                    if(this.FlowsBetaUserTypeService.hasOnlyFlows) {
                        this.EventService.createEventCommand();
                    } else {
                        this.ModalService.openCreateFirstProjectModal(true);
                    }
                } else {
                    this.EventService.createEventCommand();
                }
            };

            this.onWorkspaceReferred = function onWorkspaceReferred(workspace) {
                if (workspace.n_members < 2 || workspace.members.length === 1) { //use workspace.members if workspace.n_members is not available
                    return this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PIPELINE.REFER._ERROR_NO_CLIENT_');
                } else {
                    return ReferralsManager.referWorkspace(workspace, this);
                }
            };

            this.onWorkspaceAdminMove = function onWorkspaceAdminMove(workspace) {
                return ReferralsManager.adminMoveWorkspace(workspace, this);
            };

            this.deleteBookedWorkspaceClicked = function deleteBookedWorkspaceClicked(workspace) {
                var self = this;

                var archiveConfirmed = function archiveConfirmed() {
                    self.workspaceUpdating = true;

                    self.ModalService.openArchiveProjectModal(workspace._id, 'Pipeline').then(function () {
                        self.onWorkspaceChanged(workspace);
                        self.workspaceUpdating = false;

                        self.ToastService.showSuccess({
                            contentTranslation: self.$translate.instant('PIPELINE.TOASTS._ARCHIVE_'),
                            iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                            dismissOnTimeout: true,
                            dismissButton: true,
                            timeout:3000
                        });
                    });
                };

                self.$translate('WORKSPACE.ARCHIVE._FULL_MESSAGE_', {workspace_name: workspace.getWorkspaceOrProjectName()})
                    .then(function translateResolved(text) {
                        self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.info,
                            text,
                            archiveConfirmed,
                            undefined,
                            'WORKSPACE.ARCHIVE._YES_',
                            'WORKSPACE.ARCHIVE._NO_',
                            'WORKSPACE.ARCHIVE._TITLE_',
                            undefined,
                            true);
                    });
            };

            this.deleteWorkspaceClicked = function deleteWorkspaceClicked(workspace) {
                var self = this;

                var trackingData = {
                    workspace_id: workspace._id
                };

                var deleteConfirmed = function deleteConfirmed() {
                    self.workspaceUpdating = true;
                    self.deleteWorkspace(workspace)
                        .then(function success() {
                            self.workspaceUpdating = false;
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_deleted, trackingData);
                            self.onWorkspaceChanged();

                            self.ToastService.showSuccess({
                                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                                content: self.$translate.instant('PIPELINE.TOASTS._DELETE_PROJECT_',
                                    {project_name: workspace.getWorkspaceOrProjectName()}),
                                dismissOnTimeout: true,
                                timeout: 5000,
                                dismissButton: true
                            });
                        })
                        .catch(function error() {
                            self.workspaceUpdating = false;
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_deleted_error, trackingData);
                        });
                };

                this.deleteWorkspace = function deleteWorkspace(workspace) {
                    var promise = this.WorkspacesManager.deleteWorkspace(workspace);

                    promise.catch(function(resp) {
                        var msg = resp && resp.data && resp.data.error_message || 'WORKSPACE.DELETE._ERROR_';
                        this.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, msg);
                    }.bind(this));

                    return promise;
                };

                    self.$translate('WORKSPACE.DELETE._FULL_MESSAGE_', {workspace_name: workspace.getWorkspaceOrProjectName()})
                        .then(function translateResolved(text) {
                            self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.warning,
                                text,
                                deleteConfirmed,
                                undefined,
                                'WORKSPACE.DELETE._YES_',
                                'WORKSPACE.DELETE._NO_',
                                'WORKSPACE.DELETE._TITLE_',
                                undefined,
                                true);
                        });
            };

            this.onAssignWorkspaceToTeamMember = function (workspace, teamMember) {
                self.WorkspaceService.assignWorkspaceToTeamMembers(workspace, self.currentUserCompany, teamMember).then(function success() {
                    var index = self.workspaces.indexOf(workspace); // todo indexOf comparing the whole workspace...?
                    if (index > -1) {
                        self.workspaces.splice(index, 1);
                    }
                    self.filterWorkspaces();
                });
            };

            this.teamColumnAvailable = this.currentUserCompany.doesCurrUserHaveTeamMembers();
            this.showMoreWorkspaces = function showMoreWorkspaces(){
                if (this.serverSidePaging){
                    //get the next page
                    this.getNextWorkpsacesPage();
                }else{
                    // client side paging, already has all the data, simply display more
                    this.updateShownWorkspacesLimit(this.shownWorkspacesLimit + this.perPage);
                }
            };

            // has effect only on client side paging
            this.updateShownWorkspacesLimit = function updateShownWorkspacesLimit(limitTo){
                if (!this.serverSidePaging){
                    this.shownWorkspacesLimit = limitTo;
                    this.hasMoreWorkspacesToShow = this.shownWorkspacesLimit < this.shownWorkspacesLimitMax;
                }
            };

            //msg for team members without workspaces
            this.teamMemberName = this.isViewingAllTeamMembers ? 'your team' : this.userModel.full_name;
            this.showMsgEmptyWorkspaces = false;
            $timeout(function () {
                self.showMsgEmptyWorkspaces = true;
            }, 1000);


            this.calcElementsLoading();

            this.OnboardingService.on('onboardingAdded', function onboardingAddedReloadProjects() {
                this.loadWorkspaceCounts(true);
                this.getCurrentWorkpsacesPage();
            }.bind(this));

            this.loadWorkspaceCounts(true);

            // Decide on type of paging to use according to count of workspaces.
            const workspaces_count =  this.isViewingAllTeamMembers ? this.currentUserCompany.getAllCompanyMembers().reduce(function (accumulator, object) {
                return accumulator + object.workspace_count;
            }, 0) : this.userModel.workspaces_count;

            this.serverSidePaging = this.companyMemberViewingTeamMember || workspaces_count > 50;
            if (!this.serverSidePaging){
                this.updateShownWorkspacesLimit(this.perPage);
            }
            this.resetWorkspacesIfNeeded();

            this._openAddClientModal = function _openAddClientModal() {
                this.ModalService.openAddClientTopNavModal().then(function(resp) {
                    this.WorkspaceService.gotoWorkspace(resp.workspaceId, resp.projectId);
                }.bind(this));
            }.bind(this);

            // Contact Form Awareness TODO: remove when feature is removed
            this.maxCountVisitedToShowCfAwareness = 3;
            this._setVisitedCountUiPersistence();

            this.navigateToContactForm = function navigateToContactForm(source) {
                if (source === 'proTip') {
                    this.AnalyticsService.track(this, 'navigate to contact form', {
                        source: 'pipeline pro tip'
                    });
                }
                this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
            };

            this.getInquiryStageEmptyStateData = function getInquiryStageEmptyStateData() {
                if (this.shouldShowCFAwareness()) {
                    return {
                        title: 'PIPELINE.CF_AWARENESS._EMPTY_STATE_HEADER_',
                        text: 'PIPELINE.CF_AWARENESS._EMPTY_STATE_TEXT_',
                        primaryCtaText: 'PIPELINE.EMPTY_STATES.INQUIRY._PRIMARY_CTA_',
                        secondaryCtaText: 'PIPELINE.CF_AWARENESS._EMPTY_STATE_SECONDARY_CTA_',
                        image: 'empty_states/pipeline/fish-hook-with-envelope',
                        primaryCtaCallback: this._openAddClientModal.bind(this),
                        secondaryCtaCallback:  this.navigateToContactForm.bind(this),
                        primaryCtaAnalyticsData: {
                            eventName : 'click: add client',
                            data: {
                                type: 'pipeline empty state',
                                stage: 'contact form awareness'
                            }
                        },
                        secondaryCtaAnalyticsData: {
                            eventName : 'click: setup your contact form',
                            data: {
                                type: 'pipeline empty state',
                                stage: 'contact form awareness'
                            }
                        },
                    };
                }

                return {
                    title: 'PIPELINE.EMPTY_STATES.INQUIRY._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.INQUIRY._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.INQUIRY._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.INQUIRY._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/inquiry',
                    primaryCtaCallback: this._openAddClientModal.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-inquiry-01_2x.png',
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-inquiry-02_2x.png'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: add client',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'inquiry'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: add client tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'inquiry'
                        }
                    }
                };
            };

            this.emptyStatesData = {
                'Inquiry' : this.getInquiryStageEmptyStateData(),

                'Follow Up': {
                    title: 'PIPELINE.EMPTY_STATES.FOLLOW_UP._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.FOLLOW_UP._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.FOLLOW_UP._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.FOLLOW_UP._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/followup',
                    primaryCtaCallback:  this.onCreateNew.bind(this, ['services'], 'brochure'),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-follow-up_2x.png'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: brochure template',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'follow up'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: brochure template tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'follow up'
                        }
                    },
                },

                'Proposal Sent' : {
                    title: 'PIPELINE.EMPTY_STATES.PROPOSAL_SENT._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.PROPOSAL_SENT._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.PROPOSAL_SENT._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.PROPOSAL_SENT._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/proposal-sent',
                    primaryCtaCallback: this.onCreateNew.bind(this, ['invoice', 'contract'], 'proposal'),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-sent-01_2x',
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-sent-02_2x',
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-sent-03_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: create proposal',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal sent'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: create proposal tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal sent'
                        }
                    },
                },

                'Proposal Signed' : {
                    title: 'PIPELINE.EMPTY_STATES.PROPOSAL_SIGNED._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.PROPOSAL_SIGNED._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.PROPOSAL_SIGNED._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.PROPOSAL_SIGNED._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/proposal-signed',
                    primaryCtaCallback: this.onCreateNew.bind(this, ['contract'], 'agreement'),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-signed-01_2x',
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-signed-02_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: create agreement',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal signed'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: create agreement tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal signed'
                        }
                    },
                },

                'Contract Signed' : {
                    title: 'PIPELINE.EMPTY_STATES.CONTRACT_SIGNED._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.CONTRACT_SIGNED._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.CONTRACT_SIGNED._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.CONTRACT_SIGNED._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/proposal-signed',
                    primaryCtaCallback: function _openAgreement() {
                        this.TemplatesViewService.goToTemplatesEditor('agreement', null, {isDrawerOpen: true});
                    }.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-signed-01_2x',
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-proposal-signed-02_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: create agreement',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal signed'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: create agreement tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'proposal signed'
                        }
                    },
                },

                'Retainer Paid': {
                    title: 'PIPELINE.EMPTY_STATES.RETAINER_PAID._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.RETAINER_PAID._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.RETAINER_PAID._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.RETAINER_PAID._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/retainer-paid',
                    primaryCtaCallback: function _goToCompanySettings() {
                        this.$state.go(this.AppStates.root_core_navigation_settings_company_preferences);
                    }.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-retainer-paid_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: company payment preference',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'retainer paid'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: company payment preference tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'retainer paid'
                        }
                    },
                },

                'Planning' : {
                    title: 'PIPELINE.EMPTY_STATES.PLANNING._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.PLANNING._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.PLANNING._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.PLANNING._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/planning',
                    primaryCtaCallback: function _openTasks() {
                        this.$state.go(this.AppStates.root_core_navigation_tasks);
                    }.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-planning_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: add task',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'planning'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: add task tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'planning'
                        }
                    },
                },

                'Completed' : {
                    title: 'PIPELINE.EMPTY_STATES.CLOSED._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.CLOSED._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.CLOSED._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.CLOSED._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/complete',
                    primaryCtaCallback: function _openReports() {
                        this.$state.go(this.AppStates.root_core_navigation_reports_analytics);
                    }.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-complated_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: reports',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'completed'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: reports tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'completed'
                        }
                    },
                },

                'All' : {
                    title: 'PIPELINE.EMPTY_STATES.ALL._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.ALL._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.ALL._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.ALL._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/all-projects',
                    primaryCtaCallback: this.createEvent.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this,
                        [
                            '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1542652093/empty_states/pipeline-gallery/pipeline-modal-allactive-01_2x.png',
                            '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1542652093/empty_states/pipeline-gallery/pipeline-modal-allactive-02_2x.png',
                            '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1542652093/empty_states/pipeline-gallery/pipeline-modal-allactive-03_2x.png'
                        ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: create project',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'active_project'

                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: create project tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'active_project'
                        }
                    },
                },

                'Archived' : {
                    title: 'PIPELINE.EMPTY_STATES.ARCHIVED._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.ARCHIVED._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.ARCHIVED._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.ARCHIVED._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/archive',
                    primaryCtaCallback: this.createEvent.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-archive_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: create project',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'archived'
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: create project tutorial',
                        data: {
                            type: 'pipeline empty state',
                            stage: 'archived'
                        }
                    },
                },

                'Default' : {
                    title: 'PIPELINE.EMPTY_STATES.DEFAULT._HEADER_',
                    text: 'PIPELINE.EMPTY_STATES.DEFAULT._TEXT_',
                    primaryCtaText: this.isPreTrialer ? null : 'PIPELINE.EMPTY_STATES.DEFAULT._PRIMARY_CTA_',
                    secondaryCtaText: 'PIPELINE.EMPTY_STATES.DEFAULT._SECONDARY_CTA_',
                    image: 'empty_states/pipeline/generic',
                    primaryCtaCallback: this.openPipelineSettings.bind(this),
                    secondaryCtaCallback: this._secondaryEmptyStateCTA.bind(this, [
                        '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1543138373/empty_states/pipeline-gallery/pipeline-modal-generic_2x'
                    ]),
                    primaryCtaAnalyticsData: {
                        eventName : 'click: pipeline customize',
                        data: {
                            source: 'pipeline empty state',
                            stage: 'default',
                        }
                    },
                    secondaryCtaAnalyticsData: {
                        eventName : 'click: customize pipeline tutorial',
                        data: {
                            source: 'pipeline empty state',
                            stage: 'default',
                        }
                    },
                }
            };

            this.setEmptyStateData(this.selectedStage);

            this.markContactsBannerAsShown = () => {
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipelineContactsBannerShown, {value: true});
                this.selectBanner();
            };
        }

        var PipelineMainDirectiveController = Class(Controllers.BaseController, {
            constructor: PipelineMainDirectiveControllerCtor,

            openPipelineSettings: function openPipelineSettings() {
                if (this.openningPipelineSettings) {
                    //prevent double clicking the settings
                    return;
                }

                this.openningPipelineSettings = true;

                if (this.currentUserCompany.wasFullyFetched()) {
                    this.openSettingDialogInternal();
                } else {
                    this.CompaniesManager.getCurrCompany(true);
                    this.registerOnce(this.currentUserCompany, 'success', this.openSettingDialogInternal.bind(this));
                }
            },

            openSettingDialogInternal: function openSettingDialogInternal() {
                this.ModalService.openPipelineSettingsModal(this.currUser.company).then(
                    function success(result) {
                        if (result !== 'dismissed') {
                            this.reloadAndRefreshCurrentPage();
                        }
                    }.bind(this),
                    function fail(error) {
                        if (error) {
                            return this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PIPELINE.SETTINGS._ERROR_UPDATING_NEW_PIPELINE_STAGES_', function okHanlder() {
                                this.reloadAndRefreshCurrentPage();
                            }.bind(this));
                        }

                        // otherwise this could be just a dismiss of the dialog with the escape key. do nothing and let it close
                    }.bind(this))
                    .finally(function () {
                        this.openningPipelineSettings = false;
                    }.bind(this));
            },

            downloadArchiveReport: function () {
                this.WorkspacesManager.getArchiveWorkspacesReport();
            },

            _setSortByParam: function _setSortByParam(sortBy) {
                var sortModel = this._.find(this.sortingModel, function(model) { return model.sortName === sortBy; });
                if (sortModel) {
                    sortModel.sortReverse = false;
                    this.setSort(sortModel, true);
                }
            },

            gotoBusinessTools: function gotoBusinessTools() {
                this.$state.go(this.AppStates.root_core_navigation_business_tools);
            },

            reloadAndRefreshCurrentPage: function reloadAndRefreshCurrentPage() {
                this.$state.go(this.AppStates.root_core_navigation_pipeline, {}, {reload: true});
            },

            initSessionTypes: function initSessionTypes() {
                this.sessionTypes = [];
                if (this.SchedulingService.isSchedulingEnabled(this.currUser)) {
                    this.currUser.getSessions(null, null, true).then(function (resp) {
                        this.sessionTypes = resp;
                    }.bind(this));
                }
            },

            setEmptyStateData : function setEmptyStateData(state) {
                if(this.isBulkActionOn) {
                    this.toggleBulkActions();
                }
                this.galleryInitialized = false;
                var _state = state && state !== this.constants.noStageSelected ? state : {};

                if (this._.isEmpty(_state)) {
                    if (this.showAllStages && this.showActive) {
                        _state.name = 'All';
                    } else if (this.showAllStages && !this.showActive) {
                        _state.name = 'Archived';
                    }
                }

                if (_state.name && this.emptyStatesData.hasOwnProperty(_state.name)) {
                    this.currEmptyState = this.emptyStatesData[_state.name];
                } else {
                    this.currEmptyState = this.currUser.is_company_owner ? this.emptyStatesData.Default : this.emptyStatesData.All;
                }
            },

            _secondaryEmptyStateCTA: function _secondaryEmptyStateCTA(imagesArray) {
                if (!this.galleryInitialized) {
                    this.JsSlicklightboxService.init(imagesArray, this.lightboxContainer);
                    this.galleryInitialized = true;
                }
                this.JsSlicklightboxService.show();
            },

            hasOnlySampleProject: function hasOnlySampleProject() {
                var workspaces = this.getCurrentVisibleWorkspaces();

                if (workspaces && workspaces.length) {
                    return this._.every(workspaces, function (workspace) {
                        return (workspace && workspace.event && (workspace.event.event_source === 'client_bot' || workspace.event.event_source === 'first_proposal'));
                    });
                } else {
                    return false;
                }
            },

            shouldShowCreateNextProject: function shouldShowCreateNextProject() {
                return !this.hasOnlySampleProject() &&
                    !this.PretrialersService.shouldShowCleanAtwsUI() && // shouldShowCleanAtwsUI always returns false because the AB test was killed
                    this.showAllStages && // means active or archived filter
                    (this.currentSelectionActiveCounter > 0) &&
                    (this.currentSelectionActiveCounter < this.showCreateNextProjectMaxCount);
            },

            viewAllAvailable: function () {
                return this.currUser.isViewAllEnabled();
            },

            shouldShowEmptyState: function shouldShowEmptyState() {
                return (!this.companyMemberViewingTeamMember
                    && !this.elementsLoading
                    && (this.currentSelectionActiveCounter < 1))
                    || this.hasOnlySampleProject();
            },

            _setVisitedCountUiPersistence: function _setVisitedCountUiPersistence() {
                var cfOnboardingPersistanceHash = this.UiPersistenceService.getUiPersistence(
                    this.UiPersistenceService.keys.contactFormDynamicOnboarding,
                    {pipelineVisitCount: 0}
                );

                this.countVisited = cfOnboardingPersistanceHash.pipelineVisitCount || 0;

                var isFeatureVisible = this.shouldShowCFAwareness();

                if (isFeatureVisible) {
                    this.UiPersistenceService.setUiPersistenceInnerKey(
                        this.UiPersistenceService.keys.contactFormDynamicOnboarding,
                        'pipelineVisitCount',
                        this.countVisited + 1
                    );
                }
            },

            _openEmailTemplatesModal: function _openEmailTemplatesModal() {
                return this.ModalService.openEmailTemplatesModal();
            },

            _isAllowedBulkEmails: function _isAllowedBulkEmails() {

                var deferred = this.$q.defer();
                if (this.selectedWorkspacesIds.length > this.MAX_PROJECTS_PER_BATCH) {
                    deferred.reject(this.Enums.isAllowedBulkEmails.maxProjectsPerBatchExceeded);
                } else {
                    this.UsersManager.getAllowBulkWorkspaceEmails(this.currUser._id).then(function(response) {

                        if (response.data.allowed) {
                            deferred.resolve();
                        } else {
                            switch (response.data.reason) {
                                case 'unsupported_email_intg':
                                    deferred.reject(this.Enums.isAllowedBulkEmails.gmailIntegraion);
                                    break;
                                case 'premium_feature_only':
                                    deferred.reject(this.Enums.isAllowedBulkEmails.premiumFeatureOnly);
                                    break;
                                case 'max_daily_passed':
                                    deferred.reject(this.Enums.isAllowedBulkEmails.maxDailyPassed);
                                    break;
                                case 'running_task':
                                    // we can also pass data.task_progress if we want
                                    deferred.reject(this.Enums.isAllowedBulkEmails.runningTask);
                                    break;
                                default:
                                    deferred.reject(!this.Enums.isAllowedBulkEmails.notAllowed);
                                    break;
                            }
                        }
                    }.bind(this));
                }



                return deferred.promise;
            },

            _showGmailIntegraionConfirmModal: function _showGmailIntegraionConfirmModal() {
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    "BULK_EMAILS_ERRORS._GMAIL_",
                    "BULK_EMAILS_ERRORS._GMAIL_CTA_POSITIVE_",
                    "BULK_EMAILS_ERRORS._GMAIL_CTA_NEGATIVE_"
                )
                    .then(function clickedPositiveBtn() {
                        this.AnalyticsService.trackClick(this, "go to gmail integration");
                        this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    }.bind(this));
            },

            _showBulkIntroModal: function _showBulkIntroModal() {
                var deferred = this.$q.defer();

                var seenBulkEmailIntro = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.bulkEmailIntro, {seen: false}).seen;

                if (!seenBulkEmailIntro) {
                    this.ModalService.openBulkEmailsIntroModal()
                        .then(function () {
                            deferred.resolve();
                        })
                        .catch(function () {
                            deferred.reject();
                        });
                } else {
                    deferred.resolve();
                }
                return deferred.promise;
            },

            _onEmailTemplatesModalResolved: function _onEmailTemplatesModalResolved(resolvedObject) {
                var config = {
                    isSendToWorkspaces: Boolean(this.selectedWorkspacesIds && this.selectedWorkspacesIds.length), // not being used in react, in angular it looks like it should hide the add client option
                    selectedTemplateId: resolvedObject.templateId,
                    dontLoopOverRecipients: true, // irrelevant because of sendAsync (in react its only used in sendSync - useOneComposerAngularModalSending.ts)
                    editorType: "bulk workspaces email",
                    sendAsync: true
                };


                var options  = {
                    users: this.selectedWorkspacesIds,
                    subject: '',
                    body: '',
                    senderFunction: this._sendBulkEmail.bind(this),
                    config: config,
                    showScheduleOption: false
                };

                return this.WorkspaceFileService.showEmailEditorModal(options);
            },

            _sendBulkEmail: function _sendBulkEmail(props) {
                var users = props.users;
                var subject = props.subject;
                var body = props.body;
                var params = props.params;
                var attachedFiles = props.attachedFiles;
                return this.WorkspacesManager.sendBulkEmailToWorkspaces(this.selectedWorkspacesIds, body, subject, attachedFiles, this._onBulkEmailSent.bind(this));
            },

            _handleReject: function _handleReject(reason) {

                if (reason) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.bulk_workspace_email, null, {error: reason});
                    switch (reason) {

                        case this.Enums.isAllowedBulkEmails.gmailIntegraion:
                            this._showGmailIntegraionConfirmModal();
                            break;
                        case this.Enums.isAllowedBulkEmails.premiumFeatureOnly:
                            this.PopupMessageService.showErrorAlert('BULK_EMAILS_ERRORS._PREMIUM_');
                            break;
                        case this.Enums.isAllowedBulkEmails.maxDailyPassed:
                            this.PopupMessageService.showErrorAlert('BULK_EMAILS_ERRORS._MAX_DAILY_');
                            break;
                        case this.Enums.isAllowedBulkEmails.notAllowed:
                            this.PopupMessageService.showErrorAlert('BULK_EMAILS_ERRORS._NOT_ALLOWED_');
                            break;
                        case this.Enums.isAllowedBulkEmails.runningTask:
                            this.PopupMessageService.showErrorAlert('BULK_EMAILS_ERRORS._RUNNING_TASK_');
                            break;
                        case this.Enums.isAllowedBulkEmails.maxProjectsPerBatchExceeded:
                            this.PopupMessageService.showErrorAlert(this.$translate.instant('BULK_EMAILS_ERRORS._MAX_PROJECTS_PER_BATCH_EXCEEDED_', {limit: this.MAX_PROJECTS_PER_BATCH, delta:  this.selectedWorkspacesIds.length - this.MAX_PROJECTS_PER_BATCH }));
                            break;
                        default:
                            break;
                    }
                }
            },

            _onBulkEmailSent : function _onSuccessBulkEmailSend(resp) {

                // resp.workspaces.successful.count is the amount that was sent
                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.bulk_workspace_email);
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.bulkEmailIntro, {seen: true});

                this.ToastService.showSuccess({
                    contentTranslation: 'BULK_EMAILS.TOAST_SUCCESS',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout:5000
                });


            },

            onSendMailToWorkspaces: function onSendMailToWorkspaces() {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_bulk_action, {action: 'send email', numOfWorkspaces: this.selectedWorkspacesIds.length});

                this._isAllowedBulkEmails()
                    .then(this._showBulkIntroModal.bind(this))
                    .then(this._openEmailTemplatesModal.bind(this))
                    .then(this._onEmailTemplatesModalResolved.bind(this))
                    .catch(this._handleReject.bind(this));
            },

            _promoteInstantDepositAutoToggle: function _promoteInstantDepositAutoToggle(src) {
                if (this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.promoteInstantDepositAutoToggleState, {shown: false}).shown === false) {
                    this.ModalService.openInstantDepositPromoteAutoModal(src).then(
                        function success(companyAllInclusiveInstantPayoutsToggled) {
                            if (companyAllInclusiveInstantPayoutsToggled) {
                                this.CompaniesManager.getCurrCompany(true); // refresh
                            }
                        }.bind(this));

                    // either way show only once
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.promoteInstantDepositAutoToggleState, {shown: true});
                }
            },

            shouldShowCFAwareness: function shouldShowCFAwareness(stage) {
                var shouldShow =
                    this.countVisited < this.maxCountVisitedToShowCfAwareness &&
                    this.currUser.isShowContactFormAwareness();

                if (stage) {
                    shouldShow = shouldShow && stage.name === 'Inquiry';
                }

                return shouldShow;
            },

            // When workspace changes stage via quick action popup it should be remove
            // from current stage
            removeWorkspace: function removeWorkspace(workspace) {
                this.workspaces.remove(workspace);
            },

            bulkActionsToggleSelectAll: function bulkActionsToggleSelectAll() {
              var workspaces = this.getCurrentVisibleWorkspaces();
                if (!this.selectedWorkspacesIds ||
                    workspaces.length !== this.selectedWorkspacesIds.length
                ) {
                    this.selectedWorkspacesIds = workspaces.map(function(ws) {
                        return ws._id;
                    });
                } else {
                    this.selectedWorkspacesIds = [];
                }
            },

            getCurrentVisibleWorkspaces: function getCurrentVisibleWorkspaces() {
              var currentVisibleWorkspaces = this.serverSidePaging ? this.workspaces : this.filteredWorkspaces;
              return Array.isArray(currentVisibleWorkspaces) ? currentVisibleWorkspaces : [];
            },

            // is stage between inquriy and follow up
            isLeadsStage: function isLeadsStage() {
                return this.selectedStage.order >= 0 &&
                       this.selectedStage.order <= this.followUpStageOrder;
            },

            onCreateNew: function onCreateNew(flowFilters, legacyType) {
                const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                    source: 'send_file',
                    actionType: 'click'
                });
                if (isDisabled) {
                    return;
                }

                if (this.FlowsBetaUserTypeService.hasNewFlowExp) {
                    this.ModalService.openCreateFlowModal({}, this.currUser.company, flowFilters);
                } else {
                    this.TemplatesViewService.goToTemplatesEditor(legacyType, null, {isDrawerOpen: true});
                }
            },

            selectBanner: function selectBanner() {
                try { // This array holds the possible banners, sorted by priority
                    // Meaning - if more than 1 banner is eligible, the first one will be shown
                    const eligibleBanners = [
                        ['leadForms', this.shouldShowLeadFormsBanner()],
                        ['invoice', this.shouldShowInvoiceBanner()],
                        ['contacts', this.shouldShowContactsBanner()]
                    ];

                    const tests = eligibleBanners.map(x => x[1]).flat();
                    return Promise.all(tests)
                        .then(results => {
                            const selectedBannerIndex = results.findIndex(result => result);
                            if (selectedBannerIndex > -1) {
                                this.selectedBanner = eligibleBanners[selectedBannerIndex][0];
                            } else {
                                this.selectedBanner = null;
                            }
                        })
                        .catch(e => {
                            console.error('Pipeline selectBanner error', e);
                            this.DatadogRUMService.addError(e);
                        });
                } catch (e) {
                    console.error('Pipeline selectBanner error', e);
                    this.DatadogRUMService.addError(e);
                }
            },

            shouldShowInvoiceBanner: function shouldisToShowInvoiceBanner() {
                var wasInvoiceSent = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.wasInvoiceSent) && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.wasInvoiceSent).value;
                var signedContractStage = this.workspacesCounts.user_pipeline_stages && this.workspacesCounts.user_pipeline_stages.find(function (stage) {
                    return stage.originalName === '_PROPOSAL_SIGNED_';
                });

                var hasSignedContract = signedContractStage && signedContractStage.counts.active;
                var signedContractStageOrder = signedContractStage && signedContractStage.order;
                var isToShowForThisStage = this.selectedStage === -1 || this.selectedStage.order >= signedContractStageOrder;  // first, or any stage after _PROPOSAL_SIGNED_
                var talBucketControlGroup = this.talBucketTestVariant === 'tb_control';

                // only if participates has signed contract(s), no invoice sent, not using mweb, and in one of the defined stages
                return !this.DeviceService.nxSmallBreakpoint() && isToShowForThisStage && !wasInvoiceSent && hasSignedContract && !talBucketControlGroup && this.IntentService.isInIntentTestGroup();
            },

            shouldShowLeadFormsBanner: function shouldShowLeadFormsBanner() {
                return this.selectedStage && this.selectedStage.name === 'Inquiry';
            },

            shouldShowContactsBanner: function shouldShowContactsBanner() {
                const isActiveProjectsFilter = this.selectedStage === -1;
                const hasActiveProjects = this.workspacesCounts.counts.active > 0;
                const wasShown = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.pipelineContactsBannerShown, {value: false}).value;
                return !this.DeviceService.nxSmallBreakpoint() && isActiveProjectsFilter && hasActiveProjects && !wasShown;
            },

            openCreateFlowModalFromInvoiceBanner: function openCreateFlowModalFromInvoiceBanner() {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pipeline_invoice_banner_create_invoice, {
                    type: 'flow',
                    source: 'invoice_banner_in_pipeline',
                    tal_bucket_variant: this.talBucketTestVariant
                });
                this.ModalService.openCreateFlowModal({}, this.currUser.company, ['invoice']);
            }
        });

        return {
            scope: {
                userModel: '=userModel',
                elementsLoading: '=elementsLoading',
                companyMemberViewingTeamMember: '=companyMemberViewingTeamMember',
                showActiveWorkspaces: '=showActiveWorkspaces'
            },
            templateUrl: 'angular/app/modules/core/features/pipeline/main/pipeline_main_directive_template.html',
            controller: PipelineMainDirectiveController,
            controllerAs: 'pipelineMainVm',
            bindToController: true
        };
    };

}());
