Components.TippyTemplate = {
    bindings: {
        templateTitle: '@?',
        templateTriggerId: '@?',
        templateFooterCollapseText: '@?',
        templateCollapse: '@?',
        templateFooterInvokeActionText: '@',
        templateInvokeAction: '&?'
    },
    transclude: {
        'nxTippyBody': '?nxTippyBody'
    },
    // @ngInject
    controller: function EmptyStateGraphic($scope, $element, $attrs){
        function $(s) {
            return document.querySelector(s)
        }

        this.closeTemplate = function () {
            var triggerElem = '#' + this.templateTriggerId;
            $(triggerElem)._tippy.hide();
        },
        
        this.invokeAction = function () {
            this.templateInvokeAction();
        }
    },
    name: 'nxTippyTemplate',
    templateUrl: 'angular/app/modules/common/ui_components/nx_tippy_template/nx_tippy_template_component.html',
};