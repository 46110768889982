(function () {
    "use strict";

    class ProposalComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $sce, AnalyticsService, $translate, Enums, $, $timeout, CompaniesManager) {
            super($scope, $injector);
            this.__objectType = 'ProposalComponentController';

            this.$scope = $scope;
            this.$sce = $sce;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$ = $;
            this.$timeout = $timeout;
            this.CompaniesManager = CompaniesManager;

            this.connectedIndicatorText = "";
            this.previewQuestions = null;
            this.currQuestionIndex = 0;

            this.company = this.CompaniesManager.getCurrCompany();
            this.rootModel = this.component.getRootModel();
        }

        $onInit() {
            this.$scope.$on('parentComponentFocusChanged', function($event, isFocused) {
                if(isFocused === false) {
                    this.showSettings = false;
                }
            }.bind(this));
        }

        toggleSettings($event) {
            this.showSettings = !this.showSettings;
            if(this.showSettings) {
                this.$scope.$emit('childComponentFocusChanged', true);
            }

            this._scrollPageIfNeeded($event);
        }

        _getSettingPopoverHeight($event) {
            return 100;
        }

        _scrollPageIfNeeded($event) {

            let currSettingsComponentHeight = this._getSettingPopoverHeight($event);

            if (!this.showSettings) {
                currSettingsComponentHeight *= -1;
            }

            let newHeight = this.$(window).scrollTop() + this.$(window).height() + currSettingsComponentHeight ;

            if (newHeight  >= this.$(document).height()) {
                this.$('html, body').animate({
                    scrollTop: newHeight
                }, 1000);
            }
        }

    }

    Components.ProposalComponent = {
        bindings: {
            component: '=',
            block: '=',
            isRootComponent: '<',
            isEditable: '<'
        },
        controller: ProposalComponentController,
        controllerAs: 'proposalComponentVm',
        name: 'hbImageComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/proposal/proposal_component.html',
    };

}());