(function () {
    "use strict";

    // @ngInject
    function BlockProposalSettingsControllerFunc($injector, $scope, Enums, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockProposalSettingsController';

        this.Enums = Enums;
        this.AnalyticsService = AnalyticsService;

        // flags
        this.allowSelection = true;
        this.allowMultiple = true;
        this.isRequired = false;
    }

    var BlockProposalSettingsController = Class(Controllers.BaseController, {
        constructor: BlockProposalSettingsControllerFunc,

        $onInit: function $onInit() {

            this.servicesOptions = [
                this.Enums.proposalSelectionType.selectMultiple,
                this.Enums.proposalSelectionType.selectMultipleRequired,
                this.Enums.proposalSelectionType.selectOne,
                this.Enums.proposalSelectionType.selectOneRequired,
                this.Enums.proposalSelectionType.noSelect
            ];

            this.servicesOption = this.servicesOptions.filter(function(item) {
                return item === this.block.proposal.selection_type;
            }.bind(this))[0];

            // set flags
            this.allowSelection = this.servicesOption !== this.Enums.proposalSelectionType.noSelect;
            this.allowMultiple = (
                this.servicesOption === this.Enums.proposalSelectionType.selectMultiple ||
                this.servicesOption === this.Enums.proposalSelectionType.selectMultipleRequired
            );
            this.isRequired = (
                this.servicesOption === this.Enums.proposalSelectionType.selectMultipleRequired ||
                this.servicesOption === this.Enums.proposalSelectionType.selectOneRequired
            );

        },

        updateSelectionOptions: function updateSelectionOptions() {
            if(!this.allowSelection) {
                this.servicesOption = this.Enums.proposalSelectionType.noSelect;
            } else {

                if(this.allowMultiple) {

                    if(this.isRequired) {
                        this.servicesOption = this.Enums.proposalSelectionType.selectMultipleRequired;
                    } else {
                        this.servicesOption = this.Enums.proposalSelectionType.selectMultiple;
                    }

                } else {
                    if(this.isRequired) {
                        this.servicesOption = this.Enums.proposalSelectionType.selectOneRequired;
                    } else {
                        this.servicesOption = this.Enums.proposalSelectionType.selectOne;
                    }
                }
            }

            this.block.proposal.updateProposalSelectionTypeOnBlock(this.servicesOption);
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.change_block_proposal_selection_type, { option: this.servicesOption});
        },

        onChooseQtyToggle: function onChooseQtyToggle() {
            this.block.proposal.updateProposalQuantitySettingsOnBlock(this.block.proposal.enable_quantity);
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.change_block_proposal_enable_quantity, { value: this.block.proposal.enable_quantity});
        },

        onSeePricingToggle: function onSeePricingToggle() {
            // TODO: Alon - Implement
        },

    });

    Components.BlockProposalSettings = {
        bindings: {
            block: '=',
            isEditableMode: '<'
        },
        controller: BlockProposalSettingsController,
        name: 'hbBlockProposalSettings',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_proposal/block_proposal_settings/block_proposal_settings.html',
    };

}());