(function () {
    "use strict";

    // @ngInject
    function ReferralModalControllerCtor(Enums, $scope, $injector, $state, $modalInstance, AppStates, $interval, $timeout, _,
                                         UsersManager, MemberReferralsManager, FlowsBetaUserTypeService,
                                         UserService, ReferralService, AnalyticsService, ModelFactory, ModalService, AbTestService, Gon, DoodleService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReferralModalController';

        this.$state = $state;
        this.AppStates = AppStates;
        this.modalInstance = $modalInstance;

        this.UsersManager = UsersManager;
        this.UserService = UserService;

        this.currUser = UsersManager.getCurrUser();
        this.$timeout = $timeout;
        this.ReferralService = ReferralService;
        this.$scope = $scope;
        this.copyBtnText = 'FREQUENT_BUTTONS._COPY_';
        this.showLoader = true;
        this.AnalyticsService = AnalyticsService;
        this.$interval = $interval;
        this._ = _;
        this.ModelFactory = ModelFactory;
        this.ModalService = ModalService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.facebookAppId = Gon.facebook_app_id;
        this.emails = [];
        this.DoodleService = DoodleService;

        this.MemberReferralsManager = MemberReferralsManager;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

        this.bubbleSection = {
            copy: false,
            email: false,
            invite: false,
            facebook: false
        };

        this.currPhraseIndex = 0;

        this.referrals = this.ModelFactory.newCollectionByModelName('MemberReferralModel');

        this.ReferralService.getCampaignDetails(this.currUser)
            .then(function success(campaignDetails) {
                this.campaignDetails = campaignDetails;
            }.bind(this));

        this.toggleSection('facebook'); // default is facebook

        this.MemberReferralsManager.getReferralSuggestions(this.currUser)
            .then(function success(suggestions) {
                this.suggestions = suggestions.data;
                if (this.suggestions.length > 0) {
                    var args = {
                        referral_suggestions: this.suggestions.length
                    };
                    AnalyticsService.track(this, 'User has referral suggestions', args);
                }
            }.bind(this));

        this.AnalyticsService.trackPageView(this, 'referrals modal');
    }

    Controllers.ReferralModalController = Class(Controllers.BaseController, {

        constructor: ReferralModalControllerCtor,

        closeModal: function closeModal(isCloseBtnClicked) {

            if (isCloseBtnClicked && this.$state.current.url == "activity_notifications") {
                this.$state.go(this.AppStates.root_core_navigation_pipeline);
            }

            this.modalInstance.dismiss();
        },

        inviteUsers: function inviteUsers(form) {
            if (form.$valid) {
                this.referralLinkShared('email');
                this.sendingInvites = true;

                this.ReferralService.referUsersByEmail(this._.map(this.emails, function (emailTag) {
                    return emailTag.text;
                })).then(
                    function success() {
                        this.emails = [];
                    }.bind(this)).finally(function () {
                    this.sendingInvites = false;
                }.bind(this));
            }
        },

        toggleSection: function toggleCopySection(bubbleName) {
            this.bubbleSection = {
                copy: false,
                email: false,
                invite: false,
                facebook: false
            };

            this.bubbleSection[bubbleName] = !this.bubbleSection[bubbleName];
        },

        inviteContact: function inviteContact(contact) {
            this.ReferralService.referUsersByEmail([contact.user.email], this.referrals).then(
                function success() {
                    contact.invited = true;
                }.bind(this));
        },

        onShareLinkClicked: function onShareLinkClicked($event) {
            $event.target.select();
        },

        referralLinkShared: function referralLinkShared(source) {
            var params = { source: source};
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.referral_link_shared, params);

            this.convertReferralModalAbTest();
        },

        copyBtnClicked: function copyBtnClicked() {
            this.referralLinkShared('copy');
            this.copyBtnText = 'FREQUENT_BUTTONS._COPIED_';
            this.$timeout(function () {
                this.copyBtnText = 'FREQUENT_BUTTONS._COPY_';
            }.bind(this), 1000);
        },

        gotoReferrals: function gotoReferrals() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.my_benefits_button_click);
            this.goToState(this.AppStates.root_core_navigation_invite_referrals);
            this.closeModal();
        },

        onSuggestionClicked: function onSuggestionClicked(suggestion, index) {
            this.emails.push({text: suggestion.email});
            this.suggestions.splice(index, 1);
        },

        isDoodle: function isDoodle(name) {
            return this.DoodleService.isDoodle(name);
        }
    });
}());

