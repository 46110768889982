(function () {
    'use strict';

    class SignupExampleFileController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, _, $, $sce) {
            super($scope, $injector);
            this.__objectType = 'SignupExampleFileController';

            this.$ = $;
            this.$sce = $sce;

            this.defaultCompanyName = "Business name";
            this.defaultVendorName = "Full name";

            this.blankCoverPhoto = 'app/signup/example-file/blank';

            const coverPhoto = this.defaultCoverPhoto || this.blankCoverPhoto;
            this.coverPhotoSrc = this.generateCloudinarySrc(coverPhoto);

            if (this.fileType) {
                const fileTypeImageMap = {
                    invoice: 'https://res.cloudinary.com/honeybook/image/upload/v1564507226/app/signup/example-file/invoice-body.svg',
                    contract: 'https://res.cloudinary.com/honeybook/image/upload/v1564507226/app/signup/example-file/contract-body.svg',
                    proposal: 'https://res.cloudinary.com/honeybook/image/upload/v1564507226/app/signup/example-file/proposal-body.svg',
                }
                this.fileContentImageUrl = fileTypeImageMap[this.fileType];
            }

            this.randomFileContentLines = Array.from({length: 12}, () => Math.floor((Math.random() * 50) + 50));

            $scope.$watch('signupExampleFileVm.defaultCoverPhoto', (newCoverPhoto, oldCoverPhoto) => {
                if (newCoverPhoto && newCoverPhoto !== oldCoverPhoto) {
                    this.coverPhotoSrc = this.generateCloudinarySrc(newCoverPhoto);
                }
            });

            $scope.$watch('signupExampleFileVm.logoFile', (newLogoFile, oldLogoFile) => {
                if (newLogoFile) {
                    this.logoFileUrl = URL.createObjectURL(newLogoFile);
                }
            });
        }

        trustSrc(src) {
            return this.$sce.trustAsResourceUrl(src);
        }

        generateCloudinarySrc(publicId) {
            const img = this.$.cloudinary.image(publicId, {
                cloud_name: 'honeybook', // use cloudinary config service
                secure: true,
                transformation: [
                    // TODO: Add transformation for width for performance
                    {
                        flags: 'lossy',
                        quality: 'auto',
                        fetch_format: 'auto'
                    }
                ]
            });

            return img[0].src;
        }

    }


    Components.SignupExampleFile = {
        bindings: {
            vendorName: '<',
            companyName: '<',
            defaultCoverPhoto: '<',
            logoFile: '<',
            fileType: '@?',
            size: '@?',
        },
        controller: SignupExampleFileController,
        controllerAs: 'signupExampleFileVm',
        name: 'hbSignupExampleFile',
        templateUrl: 'angular/app/modules/core/features/signup/example_file/signup_example_file_component.html',
    };

}());