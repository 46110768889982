(function () {
    'use strict';

// @ngInject
    Models.ContactFormSettingsModel = function ContactFormSettingsModel(BaseModel) {

        return Class(BaseModel, {

            constructor: function ContactFormSettingsModel() {
                this.constructor.$super.call(this);
                this.__className = 'ContactFormSettingsModel';
            },

            mapChilds: function mapChilds() {
                return {};
            },

            afterMixingFrom: function afterMixingFrom(object) {
            },

            isCachable: function isCachable() {
                return true;
            }
        });
    };
}());

