(function () {
    "use strict";

    class MultiChoiceQuestionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, _, QuestionsStateService, $, $timeout, $element, Enums) {
            super($scope, $injector);
            this.__objectType = 'MultiChoiceQuestionController';

            this.$scope = $scope;
            this._ = _;
            this.QuestionsStateService = QuestionsStateService;
            this.$ = $;
            this.$timeout = $timeout;
            this.$element = $element;
            this.Enums = Enums;

            this.isExpandable = false;
            this.expandedEditMode = false;
            this.selectedOption = null;
            this.showRequiredError = false;
            this.optionAnswerError = {
                valid: 0,
                empty: 1,
                duplicate: 2
            };

            this.sortableOptions = {
                axis: 'y',
                revert: 200,
                handle: '.hb-multi-choice-question__option-drag',
                opacity: 0.8,
                placeholder: 'hb-multi-choice-question__option-drop-placeholder',
                helper: 'clone',
                stop: this.onDragStop.bind(this)
            };
        }

        $onInit() {

            this.isFocused = false;

            // handle error checks
            this.QuestionsStateService.on("CheckQuestionsErrors", this.onValidate.bind(this));

            // handle block focus
            this.$scope.$on('parentComponentFocusChanged', this.onFocusChange.bind(this));
        }

        $onChanges(changes) {

            // check if should have a collapse / expand behaviour
            // this is relevant only for dropdown question
            this.isExpandable = this.isDropDown();

            // set expanded state
            this.expandedEditMode = this.expandedEditMode || !this.isExpandable;

            this.resetErrors();

            // override optional answers
            this._initConnectedListOptions();

            // set selected option (in case this is a dropdown question and client mode)
            if(this.isDropDown() || this.isSingleChoice()) {
                this._setSelectedOption();
            }
        }

        _initConnectedListOptions() {

            var onUpdateEvent;

            // get list
            if(this.question.connected_property === this.Enums.suggestedQuestions.lead_source) {
                onUpdateEvent = 'companyLeadSourcesChanged';
            } else if (this.question.connected_property === this.Enums.suggestedQuestions.project_type) {
                onUpdateEvent = 'companyProjectTypesChanged';
            }

            if(!onUpdateEvent) {
                return;
            }

            // update list
            this._updateConnectedListOptions();

            // listen for changes on this list
            this.register(this.QuestionsStateService._watchedCompany, onUpdateEvent, this._initConnectedListOptions.bind(this));

        }

        _updateConnectedListOptions() {

            var list = this.QuestionsStateService.getConnectedQuestionOptionsList(this.question.connected_property);

            if(!list) {
                return;
            }

            // convert to optional answers structure and merge
            var optionsMap = this.question.optional_answers.reduce(function(result, option) {
                result[option.linked_object_id] = option.selected;
                return result;
            }, {});

            this.question.optional_answers = list.map((listItem) => {
                return {_id: listItem._id, linked_object_id: listItem._id, answer_text: listItem.label, order: 0, selected: optionsMap[listItem._id] };
            });

        }

        _setSelectedOption() {
            this.selectedOption = this._.find(this.question.optional_answers, function(option) {
                return !!option.selected;
            });
        }

        onValidate() {

            // reset all errors in this component
            this.resetErrors();

            // mark invalid options
            if(this.question.optional_answers) {
                this.question.optional_answers.forEach(function(option) {
                    this.errors.options[option._id] = option.answer_text ? this.optionAnswerError.valid :
                                                                           this.optionAnswerError.empty;
                    if(!option.answer_text) {
                        this.errors.hasErrors = true;
                        this.expandedEditMode = true;
                    }
                }.bind(this));

                if(this._isInsideContactForm()) {
                    this.checkForDuplicateAnswers();
                }
            }
        }

        _isInsideContactForm() {
            return this.question.__parent && (this.question.__parent.getRootModelType() === 'contact_form');
        }

        checkForDuplicateAnswers () {
            const visited_options = {};

            this.question.optional_answers
                .forEach(({_id, answer_text}) => {
                    answer_text = answer_text && answer_text.trim();

                    if(answer_text && visited_options[answer_text]) {
                        this.errors.hasErrors = true;
                        this.errors.options[_id] = this.optionAnswerError.duplicate;
                        this.expandedEditMode = true;
                    }

                    visited_options[answer_text] = true;
                });
        }

        onFocusChange($event, isFocused) {

            if(isFocused !== this.isFocused) {

                if(!isFocused) {
                    this.collapseEdit();
                    this.updateQuestion();
                }

                this.isFocused = isFocused;
            }
        }

        resetErrors() {
            this.errors = { options: {}, hasErrors: false };
        }

        expandEdit() {
            if(!this.expandedEditMode) {
                this.expandedEditMode = true;
                this.emitFocus();
            }
        }

        collapseEdit() {
            this.expandedEditMode = !this.isExpandable;
        }

        disableDropDownSelect() {
            return !this.isOwnerMode && this.isAnswerable;
        }

        emitFocus() {
            if(!this.isFocused) {
                this.$scope.$emit('childComponentFocusChanged', true);
            }
        }

        isDropDown() {
            return this.question.question_type === this.Enums.customQuestions.drop_down;
        }

        isSingleChoice() {
            return this.question.question_type === this.Enums.customQuestions.single_choice;
        }


        /////////////////////////////
        // Options CRUD
        ///////////////////////////
        _addEmptyOption() {
            this.question.optional_answers.push({answer_text: '', selected: false});
            this.hasChanges = true;
            this.emitFocus();
        }

        resetDuplicateErrors() {
            Object.keys(this.errors.options).forEach(optionId => this.errors.options[optionId] === this.optionAnswerError.duplicate &&
                                                                 delete this.errors.options[optionId]);

            this.errors.hasErrors = Object.keys(this.errors.options).some(optionId => this.errors.options[optionId]);
        }

        updateOption() {
            if(this._isInsideContactForm()) {
                this.resetDuplicateErrors();
                this.checkForDuplicateAnswers();
            }

            this.hasChanges = true;
        }

        deleteOption($event, option) {

            // prevent event from bubbling up and riggering hb-click-outside on the block
            $event.stopPropagation();

            // delete locally
            this.question.optional_answers.splice(this.question.optional_answers.indexOf(option), 1);

            this.hasChanges = true;
            this.emitFocus();
        }

        onDragStart() {
            this.emitFocus();
        }

        onDragStop() {

            // update order
            this.question.optional_answers.forEach(function (option, index) {
                option.order = index;
            }.bind(this));

            this.hasChanges = true;

        }

        onOptionInputFocus(option) {
            this.clearOptionError(option);
            this.emitFocus();
        }

        /**
         * Called when adding a new option or when clicking Enter when focused on an option
         * Function will focus on the next option and create it if neccesary
         *
         *
         * @param {JQuery} optionEl the current focused option
         */
        _moveFocusToNextOptionOrCreateIfLast(optionEl) {
            if(optionEl.hasClass('js-hb-multi-choice-question__option--last')) {

                // add empty option
                this._addEmptyOption();
            }

            // focus next option
            this.$timeout(() => {
                var nextOptionEl = optionEl.next();
                if(nextOptionEl.length) {

                    // focus option input
                    nextOptionEl.find('.hb-input__input').select();
                }
            }, 0);
        }

        onOptionTextKeyUp($event) {
            var keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            if (keyCode === 13) {

                // check if in last option
                var optionEl = angular.element($event.target).closest('.js-hb-multi-choice-question__option');
                this._moveFocusToNextOptionOrCreateIfLast(optionEl);

            }
        }

        clearOptionError(option) {
            if(this.errors.options && this.errors.options[option._id]) {
                this.errors.options[option._id] = this.optionAnswerError.valid;
            }
        }

        /////////////////////////////
        // Question Update
        ///////////////////////////
        updateQuestion() {

            // update options only for
            // editable options questions
            if(this.question.isOptionsEditable() && this.hasChanges) {
                this.hasChanges = false;
                return this.question.updateQuestion(['optional_answers']);
            }

            return null;
        }

        /////////////////////////////
        // Client interaction
        ///////////////////////////
        onAnswerSelected(option) {

            // if single choice, reset other questions
            if(this.isDropDown() || this.isSingleChoice()) {

                this.question.optional_answers.forEach(function (option) {
                    option.selected = false;
                }.bind(this));

                // mark selected option
                option.selected = true;
            }

            // update error
            this.showRequiredError = !this.question.isAnswerValid();

            // update
            return this.question.answerQuestion(['optional_answers']);
        }

        /**
         * @param {Event} $event
         */
        onAddOptionClick($event) {
            const questionContainer = $event.target.closest(".hb-multi-choice-question")
            const lastOptionEl = angular.element(questionContainer).find(".js-hb-multi-choice-question__option--last");
            this._moveFocusToNextOptionOrCreateIfLast(lastOptionEl);

        }

    }

    Components.MultiChoiceQuestion = {
        bindings: {
            question: '<',
            isEditable: '<',
            isOwnerMode: '<',
            isAnswerable: '<',
            isStyleCustomizable: '<',
        },
        controller: MultiChoiceQuestionController,
        controllerAs: 'multiChoiceQuestionVm',
        name: 'hbMultiChoiceQuestion',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/questions/multi_choice_question/multi_choice_question.html',
    };

}());
