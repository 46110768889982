(function () {
    'use strict';

    // @ngInject
    function ProfitLossControllerCtor($scope, $injector, AppConfigService, ReportsManager, UsersManager,
                                      DeviceService,
                                      _, moment, $translate, AnalyticsService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProfitLossController';
        this.moment = moment;
        this._ = _;
        this.ReportsManager = ReportsManager;
        this.$translate = $translate;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.UsersManager = UsersManager;
        this.currUser = this.UsersManager.getCurrUser();

        var reportState = AppConfigService.reportsState();

        this.loadingReports = false;
        this.loadingSummary = false;

        this.expandedView_income = false;
        this.expandedView_expenses = false;
        this.expandedView_costOfGoodsSold = false;
        this.showDateRange = false;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.restrictedFeature = this.FeatureRestrictionService.features.profitLoss;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature) && !this.DeviceService.isInAppBrowser();
        const unsubscribeFeatureRestrictions = $scope.$watch('profitLossVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature) && !this.DeviceService.isInAppBrowser();
        }.bind(this), true);

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });

        this.AnalyticsService.trackPageView(this, 'profitAndLoss');
        this.getProfitLoss();
    }

    Controllers.ProfitLossController = Class(Controllers.BaseController, {

        constructor: ProfitLossControllerCtor,

        toggleExpandedView_income: function toggleExpandedView_income() {
            this.expandedView_income = !this.expandedView_income;
        },

        toggleExpandedView_costOfGoodsSold: function toggleExpandedView_costOfGoodsSold() {
            this.expandedView_costOfGoodsSold = !this.expandedView_costOfGoodsSold;
        },
        toggleExpandedView_expenses: function toggleExpandedView() {
            this.expandedView_expenses = !this.expandedView_expenses;
        },

        filterStartDate: "30",

        filterUpdated: function filterUpdated() {
            if (this.filterStartDate === 'custom') {
                this.showDateRange = true;
            } else {
                this.showDateRange = false;
                this.getProfitLoss();
            }
        },

        setFilterStartDate: function setFilterStartDate(date) {
            var momentDate = this.moment(date, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.customeFilterStartDate = momentDate;
                this.getProfitLoss();
            }
        },

        setFilterEndDate: function setFilterEndDate(date) {
            var momentDate = this.moment(date, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.customeFilterEndDate = momentDate;
                this.getProfitLoss();
            }
        },
        
        getPrice: function getPrice(price) {
            if(this.isFeatureRestricted) {
                return 0;
            }
            return price;
        },

        getProfitLoss: function getProfitLoss() {

            if (!this.isFeatureRestricted) {

                // Make sure the user has subscription.
                if (this.currUser.account && this.currUser.account.subscription) {
                    this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.pricing_section_feature,
                        {feature: this.FeatureRestrictionService.features.profitLoss, current_plan: this.currUser.account.subscription.plan_code, is_restricted: this.isFeatureRestricted});
                }
            }

            this.payments = [];
            this.cogs_expenses = [];
            this.expenses = [];

            this.total_income = 0;
            this.cost_of_goods_sold = 0;
            this.gross_profit = 0;
            this.total_expenses = 0;
            this.net_profit_loss = 0;

            var endDate;
            var startDate;

            if (this.filterStartDate !== 'custom') {
                endDate = this.moment().format('YYYY-MM-DD');
                startDate = this.moment().subtract(this.filterStartDate, 'days').format('YYYY-MM-DD');
            }
            else {
                if (this.customeFilterEndDate) {
                    endDate = this.customeFilterEndDate.format('YYYY-MM-DD');
                }
                if (this.customeFilterStartDate) {
                    startDate = this.customeFilterStartDate.format('YYYY-MM-DD');
                }
            }
            if (!endDate || !startDate) {
                return;
            }
            this.loadingReports = true;
            this.ReportsManager.getProfitLossReport(startDate, endDate).then(
                function success(response) {
                    this.payments = response.data.payments;

                    this.total_income = this._.reduce(this.payments, function (total, payment) {
                        var refundTaxRatio = payment.payment_refund_amount ? (payment.payment_refund_amount / payment.payment_amount) : 0
                        var refundWithoutTax = payment.payment_refund_amount ? payment.payment_refund_amount - (refundTaxRatio * payment.payment_tax) : 0
                        return total + (payment.payment_amount + payment.payment_tip_paid - payment.payment_tax - refundWithoutTax);
                    }, 0);

                    this.cost_of_goods_sold = 0;
                    this.total_expenses = 0;

                    if (response.data.expenses) {

                        this._.each(response.data.expenses, function (expense) {

                            if (expense.is_COGS) {
                                this.cogs_expenses.push(expense);
                                this.cost_of_goods_sold += expense.amount;
                            } else {
                                this.expenses.push(expense);
                                this.total_expenses += expense.amount;
                            }

                        }.bind(this));
                    }

                    this.gross_profit = this.total_income - this.cost_of_goods_sold;
                    this.net_profit_loss = this.gross_profit - this.total_expenses;
                    this.loadingReports = false;

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.profit_and_loss_change_filter, {
                        filter: this.filterStartDate,
                    });
                }.bind(this),
                function error(resp) {
                    this.loadingReports = false;
                }.bind(this)
            );
        }
    });
}());
