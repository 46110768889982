(function () {
    'use strict';

    class AccountSetupSuccessController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, FlowsBetaUserTypeService, NavigationService, CompaniesManager, moment, $filter, AnalyticsService, Gon) {
            super($scope, $injector);
            this.__objectType = 'AccountSetupWaitingController';
            this.NavigationService = NavigationService;
            this.AnalyticsService = AnalyticsService;

            this.company = CompaniesManager.getCurrCompany();

            this.time_to_upload_materials = Gon.time_to_upload_materials;

            const getSuccessVariant = obj => Object.assign(obj, {
                imageUrl: '//res.cloudinary.com/honeybook/image/upload/v1/illustrations/muscle-illu.svg',
                title: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_TITLE_',
                hideUploadMore: true
            });
            let daysLeft = moment(this.company.last_date_to_upload);
            daysLeft = daysLeft.diff(moment(), 'days', true);
            daysLeft = Math.ceil(daysLeft);
            daysLeft = $filter('pluralize')(daysLeft, 'day');

            this.variants = {
                success: getSuccessVariant({
                    subtitle: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_SUBTITLE_'
                }),
                success_one_left: getSuccessVariant({
                    subtitle: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_REMAINING_DAYS_',
                    daysLeft
                }),
                pending: {
                    imageUrl: '//res.cloudinary.com/honeybook/image/upload/v1598350241/app/illustrations/upload-progress.svg',
                    title: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PENDING._TITLE_',
                    subtitle: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PENDING._SUBTITLE_',
                    subtitle2: FlowsBetaUserTypeService.hasFlows
                        ? 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PENDING._SUBTITLE_2_'
                        : null,
                    cta: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PENDING._CTA_',
                    ctaTarget: '/app/template-gallery'
                },
                partial: {
                    imageUrl: '//res.cloudinary.com/honeybook/image/upload/v1598350241/app/illustrations/upload-progress.svg',
                    title: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PARTIAL._TITLE_',
                    subtitle: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PARTIAL._SUBTITLE_',
                    subtitle2: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PARTIAL._SUBTITLE_2_',
                    cta: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_PARTIAL._CTA_',
                    ctaTarget: '/app/my-templates'
                },
                completed: {
                    imageUrl: '//res.cloudinary.com/honeybook/image/upload/v1649604700/app/account_setup/upload-progress-done.svg',
                    title: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_COMPLETE._TITLE_',
                    subtitle: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_COMPLETE._SUBTITLE_',
                    cta: 'COMPANY_SETTING.SETUP._SUCCESS_STEP_COMPLETE._CTA_',
                    ctaTarget: '/app/my-templates'
                },
            };

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.material_submitted_succesfully);
        }

        close() {
            this.NavigationService.goHistoryBack();
            this.AnalyticsService.trackConfirm(
                this,
                this.AnalyticsService.analytics_events.account_setup_modal_load
            );
        }

        currVariant() {
            return this.variants[this.variant];
        }

        shouldShowUploadMore() {
            return !this.currVariant().hideUploadMore && this.company.canUploadMaterials();
        }
    }

    Components.AccountSetupSuccess = {
        bindings: {
            variant: '<',
            onUploadMore: '<',
            getTranslationPath: '<'
        },
        controller: AccountSetupSuccessController,
        name: 'hbAccountSetupSuccess',
        templateUrl: 'angular/app/modules/core/features/account_setup/account_setup_success_template.html'
    };
})();
