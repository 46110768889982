(function () {
    'use strict';

    // @ngInject
    function RefundPaymentControllerCtor($scope, $injector, $timeout, $log, $q, $translate, $modalInstance, payment, workspaceFileId,
                                         isUndoMap, isOnRedirect, AnalyticsService, StripeHelperService, ModalService, PopupMessageService,
                                         PendingTasksManager, CompaniesManager, WorkspaceFilesManager, UsersManager, _) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'RefundPaymentController';
        this.$modalInstance = $modalInstance;
        this.$q = $q;
        this.$log = $log;
        this._ = _;
        this.$timeout = $timeout;
        this.$translate = $translate;

        this.payment = payment;
        this.workspaceFileId = workspaceFileId;
        this.isUndoMap = isUndoMap;

        this.StripeHelperService = StripeHelperService;
        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;

        this.PendingTasksManager = PendingTasksManager;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.UsersManager = UsersManager;

        this.AnalyticsService.trackPageView(this, 'RefundPaymentView');

        this.company = CompaniesManager.getCurrCompany();
        this.user = this.UsersManager.getCurrUser();

        this._setRefundAmount();
        this.maxRefundAmount = this._getMaxRefundAmount();
        this.refundAmount = this.maxRefundAmount;

        this.isManualPayment = this.payment.is_manual;
        this.transferred_to_vendor = this.payment.transferred_to_vendor;

        this.fetchingRefundPaymentMethod = false;
        this.inProgress = false;
        this.refundCompleted = false;
        this.refundFailed = false;
        this.card = {};
        this.bankAccount = null;
        this.newBankAccountAvailableBalance = null;
        this.isOnRedirect = isOnRedirect;
        this.PAYMENT_METHOD_TYPES = {
          BANK_ACCOUNT: 'ach' ,
          CREDIT_CARD: 'cc',
          MANUAL: 'manual',
          NOT_TRANSFERRED_TO_VENDOR: 'NOT_TRANSFERRED_TO_VENDOR' // meaning no need for a payment method
        };
        this.refundPaymentMethodType = this._getRefundPaymentMethodType();
        this.learnMoreLink = 'https://help.honeybook.com/en/articles/2209070-issuing-a-refund';
        this.disclaimerTooltipInit();

        if (!this.isManualPayment) {
            this._populateRefundModal();
        }

        if (this.isOnRedirect) {
            this.connectBankAccount();
        }
    }

    Controllers.RefundPaymentController = Class(Controllers.BaseController, {
        constructor: RefundPaymentControllerCtor,

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },

        onRefundClick: function onRefundClick() {

            if (!this.isManualPayment && this.addCreditCardFormVisible && !this.refundForm.$valid) {
                // CC payment method is not valid
                return;
            }

            if (this.refundAmount > this.maxRefundAmount){
                this.refundError = "Can't refund more than available on payment, current amount available for refund - " + this.maxRefundAmount;
                this.refundFailed = true;
                return;
            }

            if (this.newBankAccountAvailableBalance && this.newBankAccountAvailableBalance < this.refundAmount) {
                this.refundError = this.$translate.instant('PAYMENTS.REFUNDS._ACCOUNT_BALANCE_ERROR_');
                this.refundFailed = true;
                this.newBankAccountAvailableBalance = null; // unset to allow member to retry with new bank account
                return;
            }

            var refundPromise = null;
            this.refundError = null;

            if (this.isManualPayment && !this.isUndoMap) {
                this.refundFailed = false;
                this.inProgress = true;
                refundPromise = this._markAsRefunded();
            } else if(this.refundPaymentMethodType === this.PAYMENT_METHOD_TYPES.BANK_ACCOUNT &&
                (!this.vendorInfo || this.refundFailed)) {
                this.refundFailed = false;
                this.connectBankAccount();
                return;
            } else {
                this.refundFailed = false;
                this.inProgress = true;
                refundPromise = this._refundPayment();
            }

            refundPromise.then(function refundSuccess() {
                this.refundCompleted = true;
                this.$timeout(function success() {
                    this._close();
                }.bind(this), 1500);
            }.bind(this)).catch(function refundFailure(res) {
                this._handleRefundFailure(res);
            }.bind(this)).finally(function final() {
                this.inProgress = false;
            }.bind(this));
        },

        connectBankAccount: function connectBankAccount() {
            this.ModalService.openConnectBankAccountModal('refund', 'CONNECT_BANK_ACCOUNT', false,
                {refundAmount: this.refundAmount, paymentId: this.payment._id, isOnRedirect: this.isOnRedirect})
                .then(function onSelectedAccount(selectedAccount) {
                    // could be an empty array if error...
                    if(this._.some(selectedAccount)) {
                        this.vendorPaymentMethodEndingIn =
                            this.$translate.instant('PAYMENTS.REFUNDS._BANK_ACCOUNT_ENDING_IN_') + selectedAccount.mask;
                        this.createBankAccountForRefunds(selectedAccount);
                    }
                }.bind(this))
                .catch(function onConnectAccountFailure(error) {
                    this._handleError(error);
                }.bind(this));
        },

        toggleCreditCardFormVisible: function toggleCreditCardFormVisible() {
            this.addCreditCardFormVisible = !this.addCreditCardFormVisible;
        },

        disclaimerTooltipInit: function disclaimerTooltipInit() {
            this.disclaimerTooltip = this.$translate.instant('PAYMENTS.REFUNDS._DISCLAIMER_TOOLTIP_')  +
                '<a href="https://help.honeybook.com/payments-and-bank-info/managing-client-payments/issuing-a-refund" class="semi-bold" rel="noopener noreferrer" target="_blank">' +
                this.$translate.instant('PAYMENTS.REFUNDS._LEARN_MORE_') + '</a>';
        },

        getButtonLabel: function getButtonLabel() {
            if(this.isManualPayment) {
                return 'PAYMENTS.REFUNDS._UNDO_TEXT_';
            } else if(this.refundPaymentMethodType === this.PAYMENT_METHOD_TYPES.BANK_ACCOUNT && !this.vendorInfo) {
                return 'PAYMENTS.REFUNDS._CONNECT_BANK_ACCOUNT_';
            } else {
                return 'PAYMENTS.REFUNDS._REFUND_TEXT_';
            }
        },

        _getRefundPaymentMethodType: function _getRefundPaymentMethodType() {
            if (this.isManualPayment) {
                return this.PAYMENT_METHOD_TYPES.MANUAL;
            } else if (!this.transferred_to_vendor){
                return this.PAYMENT_METHOD_TYPES.NOT_TRANSFERRED_TO_VENDOR;
            } else {
                return this.company.allow_ach_refund ? this.PAYMENT_METHOD_TYPES.BANK_ACCOUNT : this.PAYMENT_METHOD_TYPES.CREDIT_CARD;
            }
        },

        createBankAccountForRefunds: function createBankAccountForRefunds(account) {
            this.connectingBankAccountInProgress = true;
            this.UsersManager.createChargeableBankAccount(this.user,  account, ['refund'], account.institution_id, account.institution_name, this.workspaceFileId)
                .then(function onChargeableBankAccountCreated(resp) {
                    // Bank account just added and we have available balance
                    this.newBankAccountAvailableBalance = account.available;
                    this._populateRefundModal();
                    this.connectingBankAccountInProgress = false;
                }.bind(this))
                .catch(function onFailedToCreateAccount(error) {
                    this.connectingBankAccountInProgress = false;
                    this._handleError(error);
                }.bind(this));
        },

        _refundPayment: function _refundPayment() {
            var creditCardPromise = null;

            var taskData = {
                workspace_file_id: this.workspaceFileId,
                payment_id: this.payment._id,
                amount_to_refund: this.refundAmount,
                expected_to_be_charged: this.payment.transferred_to_vendor
            };

            if (!this.transferred_to_vendor) {
                return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.refundPayment, taskData);
            }

            if(this.refundPaymentMethodType === this.PAYMENT_METHOD_TYPES.BANK_ACCOUNT && this.bankAccount) {
                taskData.refund_payment_method_id = this.bankAccount._id;
                return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.refundPayment, taskData);
            }

            if (this.addCreditCardFormVisible && this.refundForm.$valid) {
                creditCardPromise = this.StripeHelperService.getStripeTokenNew(this.tokenProviderId);
            } else {
                creditCardPromise = this.$q.when(this.card);
            }

            return creditCardPromise.then(function creditCardResolved(res) {
                // new card
                if (res.id) {
                    taskData.card = angular.extend(res.card, {id: null, token_id: res.id, card_role: 'refund'});
                } else if (this.payment.transferred_to_vendor && this.vendorInfo) {
                    taskData.refund_payment_method_id = this.vendorInfo.payment_method_id;
                }
                return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.refundPayment, taskData);
            }.bind(this));

        },

        _handleError: function _handleError(error) {
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.payment_refund_error, error);
            this.refundFailed = true;
            var message = null;
            if (error && error.data && error.data.error_type) {
                if (error.data.error_type === 'HBUserError') {
                    this.refundError = error.data.error_message;
                } else {
                    this.$log.error(error.data.error_message);
                    message = 'ERRORS.SERVER_API._UNEXPECTED_';
                }
            } else {
                message = 'ERRORS.SERVER_API._UNEXPECTED_';
            }
            if(message) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
            }
        },

        _handleRefundFailure: function _handleRefundFailure(res) {
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.payment_refund_error, res);
            this.inProgress = false;
            this.refundFailed = true;
            this.$timeout(function failedTimeout() {
                var message = null;
                if(res && res.error && res.error.message) {
                    message = res.error.message;
                }
                this.refundError = message || res || this.$translate.instant('PAYMENTS.REFUNDS._REFUND_FAILED_');
            }.bind(this), 1000);
        },

        _markAsRefunded: function _markAsRefunded() {
            return this.WorkspaceFilesManager.markPaymentAsRefunded(this.workspaceFileId, this.payment._id, this.refundAmount);
        },

        _populateRefundModal: function _populateRefundModal()  {
            this.fetchingRefundPaymentMethod = true;
            this.CompaniesManager.getRefundPaymentData(
                this.company, this.workspaceFileId,  this.payment._id)
                .then(function getRefundDataSuccess(resp) {
                    if(resp && resp.data) {
                        this.refundPaymentData = resp.data;
                        this._setRefundPaymentMethodData(this.refundPaymentData);
                    }
                }.bind(this))
                .catch(function getRefundDataFail(error) {
                    this._handleError(error);
                }.bind(this))
                .finally(function getRefundDataFinal() {
                    this.fetchingRefundPaymentMethod = false;
                }.bind(this));
        },

        _setRefundAmount: function setRefundAmount() {
            this.already_refund_amount = this.payment.refund_data ? this.payment.refund_data.refunded_amount : 0;
        },

        _setRefundPaymentMethodData: function _setRefundPaymentMethodData(refundPaymentData) {
            this.clientInfo = refundPaymentData.client_info;
            this.vendorInfo = refundPaymentData.vendor_info;
            var clientMethodType = this.clientInfo.payment_type === 'PaymentMethodChargeableBankAccount' ? this.PAYMENT_METHOD_TYPES.BANK_ACCOUNT : this.PAYMENT_METHOD_TYPES.CREDIT_CARD;
            this.clientPaymentMethodEndingIn = this._getLast4TranslationKey(this.clientInfo, clientMethodType);
            if(this.vendorInfo) {
                this.vendorPaymentMethodEndingIn = this._getLast4TranslationKey(this.vendorInfo, this.refundPaymentMethodType);
            } else if(this.refundPaymentMethodType === this.PAYMENT_METHOD_TYPES.CREDIT_CARD) {
                this.addCreditCardFormVisible = this.transferred_to_vendor; // if not transferred yet, we don't need a payment method
            }
        },

        _getLast4TranslationKey: function _getLast4TranslationKey(paymentMethod, paymentMethodType) {
            var paymentMethodTranslationKey = 'PAYMENTS.REFUNDS._BANK_ACCOUNT_ENDING_IN_';
            if(paymentMethodType === this.PAYMENT_METHOD_TYPES.CREDIT_CARD) {
                paymentMethodTranslationKey = 'PAYMENTS.REFUNDS._CARD_ENDING_IN_';
            }
            return this.$translate.instant(paymentMethodTranslationKey)  + paymentMethod.last4;
        },

        _openConfirmationPopUp: function _openConfirmationPopUp() {
            var msg = 'PAYMENTS.REFUNDS._CONFIRMATION_MESSAGE_';
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.success, {
                    translationId: msg}, null, null, 'PAYMENTS.REFUNDS._CONFIRM_',
                null, 'PAYMENTS.REFUNDS._REFUND_INITIATED_', false);
        },

        _getMaxRefundAmount: function _calcMaxRefundAmount() {
            return Number(this.payment.amount + (this.payment.tip_paid || 0) - this.already_refund_amount).toFixed(2);
        },

        _close: function close() {
            this.$modalInstance.close();
        },

        refundAmountChanged: function refundAmountChanged() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_refund_amount_changed, {amount: this.refundAmount});
        }
    });
}());
