(function () {
    'use strict';

    Services.AttachmentsService = class AttachmentsService {

        // @ngInject
        constructor(_) {
            this._ = _;
        }

        translateFilesListForServer(uploadedFilesList) {
            const translatedList = [];
            if (uploadedFilesList) {
                uploadedFilesList.forEach(file => {
                    if (file.url || file.file_url) {
                        const translatedItem = {
                            file_url: file.url || file.file_url,
                            type: file.file_type || file.type,
                            file_name: file.asset_name || file.file_name || file.name
                        };
                        if (file.cloudinary_public_id) {
                            translatedItem.cloudinary_public_id = file.cloudinary_public_id;
                        }

                        translatedList.push(translatedItem);
                    } else if (file.s3Response) {
                        translatedList.push({
                            file_url: file.s3Response.url,
                            type: file.type,
                            file_name: file.name
                        });
                    }
                });
            }

            return translatedList;
        }

        getAdditiveAttachments(newImages, newFiles, existingImages, existingFiles) {
            // set the new files as an addition to existing files
            const attachedFiles = {
                images : [],
                files : []
            };

            const concatedImages = (existingImages || []).concat(newImages || []);
            const uniqueImages = this._.uniq(concatedImages, false, function(image) { return image._id});

            const concatedFiles = (existingFiles || []).concat(newFiles  || []);
            const uniqueFiles = this._.uniq(concatedFiles, false, function(file) { return file._id});

            attachedFiles.images = uniqueImages;
            attachedFiles.files = uniqueFiles;

            return attachedFiles;

        }

    };

}());

