(function () {
    "use strict";

    Directives.AddTeamMemberFromSessionDirective = function AddTeamMemberFromSessionDirective() {
    // @ngInject
        function AddTeamMemberFromSessionDirectiveControllerFunc($injector, $scope, $translate, CompaniesManager, UsersManager, WorkspacesManager, moment, ToastService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.$translate = $translate;
            this.ToastService = ToastService;
            this.currentUser = UsersManager.getCurrUser();
            this.moment = moment;
            this.WorkspacesManager = WorkspacesManager;
            this.company = CompaniesManager.getCurrCompany();
            this.company_team_members = this.company && this.company.team_members && this.company.team_members.slice() || [];
            this.company_team_members.push(this.company.owner);
            this.CompaniesManager = CompaniesManager;
            this.nonWorkspaceMembers = null;
            this.nonWorkspaceMembersNames = null;
            this.workspace.on('success', this.calcNonWorkspaceMembersFromSessions, this);
        }

        var AddTeamMemberFromSessionDirectiveController = Class(Controllers.BaseController, {
            constructor: AddTeamMemberFromSessionDirectiveControllerFunc,
            calcJoinedNonWorkspaceMembersNames: function() {
                this.membersNames = '';
                if(this.nonWorkspaceMembersNames.length === 1) {
                    this.membersNames = this.$translate.instant('WORKSPACE.FEED.ADD_TEAM_MEMBER_BANNER._ONE_MEMBER_PREFIX_',
                        {teamMember: this.nonWorkspaceMembersNames[0]});
                } else if (this.nonWorkspaceMembersNames.length === 2) {
                    this.membersNames = this.$translate.instant('WORKSPACE.FEED.ADD_TEAM_MEMBER_BANNER._TWO_MEMBERS_PREFIX_',
                        {teamMember: this.nonWorkspaceMembersNames[0], secondTeamMember: this.nonWorkspaceMembersNames[1]});
                } else {
                    this.membersNames = this.$translate.instant('WORKSPACE.FEED.ADD_TEAM_MEMBER_BANNER._MORE_THAN_TWO_MEMBERS_PREFIX_') + ' ';
                    const membersLength = this.nonWorkspaceMembersNames.length;
                    for (let i = 0; i < membersLength - 2; i++) {
                            this.membersNames += this.nonWorkspaceMembersNames[i] + ', ';
                    }
                    this.membersNames += this.nonWorkspaceMembersNames[membersLength - 2] +
                        this.$translate.instant('WORKSPACE.FEED.ADD_TEAM_MEMBER_BANNER._AND_') + this.nonWorkspaceMembersNames[membersLength - 1];
                }
            },
            calcNonWorkspaceMembersFromSessions: function () {
                this.banner_allowed = this.currentUser.isVendor() && !this.workspace.isViewOnlyMode() && !this.workspace.isArchived();
                this.nonWorkspaceMembers = [];
                this.nonWorkspaceMembersNames = [];

                if (this.workspace.sessions_with_unrelated_member && this.workspace.scheduled_sessions.length) {
                    this.workspace.sessions_with_unrelated_member.forEach(sessionId => {
                        const session = this.workspace.scheduled_sessions.find(session => session._id === sessionId);
                        const team_member = this.company_team_members.find(member => member._id === session.owner_id);

                        this.nonWorkspaceMembersNames.push(team_member.full_name);
                        this.nonWorkspaceMembers.push({ memberId: team_member._id, memberName: team_member.full_name, session: session });
                    });

                    this.nonWorkspaceMembers.sort((a, b) => {
                        var firstStartDate = a.session.all_day ? a.session.date_start : a.session.date_time_start;
                        var secondStartDate = b.session.all_day ? b.session.date_start : b.session.date_time_start;
                        return firstStartDate > secondStartDate ? 1 : -1;
                    });
                    this.nonWorkspaceMembersNames = [...new Set(this.nonWorkspaceMembersNames)];
                    this.calcJoinedNonWorkspaceMembersNames();
                }

                return this.nonWorkspaceMembers;
            },
            isSessionOneDay: function(session) {
                return (!session.date_time_end) || this.moment(session.date_time_end).isSame(session.date_time_start, 'day');
            },
            addTeamMembers: function () {
                const memberIdsToAdd = [...new Set(this.nonWorkspaceMembers.map(member => member.memberId))];
                this.WorkspacesManager.updateTeamMembersInWorkspace(this.workspace, memberIdsToAdd)
                    .catch(function error(res) {
                        var message;
                        if (res && res.data && res.data.error_type) {
                            if (res.data.error_type === 'HBUserError') {
                                message = res.data.error_message;
                            } else {
                                message = this.$translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                            }
                        } else {
                            message = this.$translate.instant('ERRORS.SERVER_API._UNEXPECTED_');
                        }

                        this.ToastService.showError({
                            content: message,
                            dismissOnTimeout: true,
                            timeout: 5000,
                            dismissButton: true
                        });
                    }.bind(this));
            },
            hideBanner: function () {
                this.WorkspacesManager.hideAddMemberRelatedToSessionsBanner(this.workspace)
                .catch(function error(res) {
                    var message;
                    if (res && res.data && res.data.error_type) {
                        if (res.data.error_type === 'HBUserError' || res.data.error_type === 'HBGenericNoPermissionsError') {
                            message = res.data.error_message;
                        } else {
                            message = this.$translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                        }
                    } else {
                        message = this.$translate.instant('ERRORS.SERVER_API._UNEXPECTED_');
                    }

                    this.ToastService.showError({
                        content: message,
                        dismissOnTimeout: true,
                        timeout: 5000,
                        dismissButton: true
                    });
                }.bind(this));
            }
    });

        return {
        bindings: {
            workspace: '<'
        },
        scope: {
            workspace: '<'
        },
        templateUrl: 'angular/app/modules/core/features/feed/feed_add_team_member_banner/add_team_member_from_session_directive_template.html',
        controller: AddTeamMemberFromSessionDirectiveController,
        controllerAs: 'addTeamMemberFromSessionVm',
        bindToController: true
    };
};

}());