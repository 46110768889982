(function () {
    'use strict';
    class MeetingSearchField extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'MeetingSearchField';
        }

        onFieldClick() {
            this.onClick();
        }
    }

    Components.MeetingSearchField = {
        bindings: {
            value: '=',
            autoFocus: '<?',
            placeholder: '=',
            onChange: '&',
            onClick: '&',
            hideBar: '@',
            iconClass: '@?',
            analyticsEventSource: '@',
            analyticsEvent: '@',
            showError: '=',
            errorMessage: '=',
            isDisabled: '=?',
        },
        controller: MeetingSearchField,
        name: 'MeetingSearchField',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_search_field/meeting_search_field.html'
    };
}());