
// @ngInject

Models.ContactFormsCollection = function ContactFormsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'ContactFormsCollection';
                this._collection.__childsType = 'ContactFormModel';
                return this._collection;
            }
        }
    );

};
