(function () {
    "use strict";

    // @ngInject
    function WorkspaceFileStepAgreementControllerCtor(
        $scope, $stateParams, $injector, $log, AppStates,
        WorkspaceFilesManager, UsersManager, AnalyticsService,
        PopupMessageService, WorkspaceFileService, WorkspaceService, moment, PreviewModeService, $timeout,
        ModalService, OnboardingService, RedirectService, WebsocketHelperService, WorkspacesManager, DeviceService, UserService, MobileAppService, InjectBrandingService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileStepAgreementController';
        this.isPreviewMode = PreviewModeService.isInPreviewMode();
        this.OnboardingService = OnboardingService;
        this.RedirectService = RedirectService;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspaceService = WorkspaceService;
        this.AppStates = AppStates;
        this.WorkspaceFileService = WorkspaceFileService;
        this.ModalService = ModalService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.WorkspacesManager = WorkspacesManager;
        this.moment = moment;
        this.PreviewModeService = PreviewModeService;
        this.MobileAppService = MobileAppService;
        this.InjectBrandingService = InjectBrandingService;

        this.$timeout = $timeout;
        this.$log = $log;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.workspaceFileId = $stateParams.workspace_file_id;
        this.alreadyGotWorkspace = false;
        this.isInAppBrowser = DeviceService.isInAppBrowser();

        var self = this;
        this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
        AnalyticsService.trackPageView(this, 'agreement step',
            {
                preview_mode: this.isPreviewMode,
                file_mode: WorkspaceFileService.whichCurrentFileView(this.workspaceFile)
            }
        );

        this.WebsocketHelperService.registerToRoom(this.workspaceFileId + ".workspace_file_active_content_changed", this.getWorkspaceFile.bind(this));
        this.WebsocketHelperService.registerToRoom(this.user._id + ".workspace_file_status_changed", this.getWorkspaceFile.bind(this));

        this.isOwnerMode = false;
        this.isClientMode = false;
        this.isEditableMode = false;
        this.isLoggedInAsClient = this.user.client_login_file_id && this.user.client_login_file_id === this.workspaceFile._id;

        this.userHasDefaultProfileImage = this.user && this.user.isDefaultProfileImage();
        this.isAvatarUploadable = this.getIsAvatarUploadable();

        PreviewModeService.setState('agreement');

        if (this.workspaceFile.file_type !== undefined) {
            // we dont want to wait for the update to load the film strip, cause it causes a blick in the ui when
            // moving between states
            this.loadFilmStrip();
        }

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(self.AppStates.root_core_navigation_home);
            }.bind(this));
        });
        $scope.$on('$destroy', function () {
            this.WebsocketHelperService.unregisterFromRoom(this.workspaceFileId + ".workspace_file_active_content_changed");
            this.WebsocketHelperService.unregisterFromRoom(this.user._id + ".workspace_file_status_changed");
        }.bind(this));

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (this.workspaceFile.isProposal() || this.workspaceFile.isInvoice()) {
                UserService.onBookInPersonStateChange(event, toState);
            }
        }.bind(this));

        if(this.isInAppBrowser) {
            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.file_step_active, {step: 'contract'});
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._GO_TO_STEP_, function (data) {
                if(data.step === 'pay') {
                    this.continueToPayment();
                }
            }.bind(this));
        }
    }

    Controllers.WorkspaceFileStepAgreementController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileStepAgreementControllerCtor,

        gotWorkspaceFile: function gotWorkspaceFile() {

            // redirect if you are not the client
            if (this.workspaceFile.isQuestionnaire() ||
                (!this.alreadyGotFile && !this.isPreviewMode && (this.user._id === this.workspaceFile.owner._id) || this.workspaceFile.isViewOnlyMode())) {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile, null, true);
            }

            if(this.workspaceFile.company){

                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }

            this.alreadyGotFile = true;
            this.loadFilmStrip();

            this.isOwnerMode = this.workspaceFile.isOwnerMode();
            this.isClientMode = this.workspaceFile.isClientMode();
            this.isEditableMode = !!this.workspaceFile.isEditable();

            this.isHeaderEditable = this.isOwnerMode && !this.isPreviewMode && !this.workspaceFile.isViewOnlyMode();

            // I the agreement file case the
            if (!this.isPreviewMode &&
                this.workspaceFile.isAgreement() &&
                !this.workspaceFile.isAgreementSeen() && !this.isOwnerMode) {
                this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.agreement_seen);
            }

            // fetch workspace model for signatures
            if (!this.alreadyGotWorkspace) {
                this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id);
                this.alreadyGotWorkspace = true;
            }
        },

        printWorkspaceFile: function printWorkspaceFile() {
            this.WorkspaceFileService.printWorkspaceFile(this.workspaceFile);
        },

        loadFilmStrip: function loadFilmStrip() {

            if (this.filmStripLoaded === true) {
                return;
            }
            this.filmStripLoaded = true;
            this.linksTo = this.WorkspaceFileService.getFilmstripLinksForWorkspace(this.workspaceFile, 'agreement');
        },

        showActionButton: function showActionButton() {
            return (!this.workspaceFile.isPaymentsSeen());
        },

        signAgreement: function signAgreement() {
            this.WorkspaceFileService.validateFileModel().then(function success() {
                var $element = angular.element(".hb-signatures");
                $element.scrollParent().scrollTo(null, $element.offset().top, 300);
            });
        },

        continueToPayment: function continueToPayment() {

            if (this.isPreviewMode) {
                this.PreviewModeService.advancedState(this.workspaceFile, {}, 'replace');
            }
            else {
                this.working = true;
                var promise = this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.payments_seen);

                promise.then(function success() {
                    this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
                }.bind(this));

                promise.finally(function fulfilled() {
                    this.working = false;
                }.bind(this));
            }
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        openIntakeModal: function openIntakeModal() {
            this.ModalService.openIntakeModal().then(
                this.onIntakeModalSubmission.bind(this));
        },

        onIntakeModalSubmission: function onIntakeModalSubmission() {
            var userRequest = this.UsersManager.forceFetchCurrUser();

            var redirect = userRequest.then(function() {
                this.RedirectService.redirect(this.user);
            }.bind(this));
        },

        getWorkspaceFile: function getWorkspaceFile() {
            this.$timeout(function () {
                this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
            }.bind(this) , 2000);
        },

        getExpirationEnd: function getExpirationEnd() {

            var expiration = this.moment(this.workspaceFile.auto_expiration.ends_on);

            if (this.isPreviewMode) {
                switch (this.workspaceFile.auto_expiration.period) {
                    case 'one_week':
                        expiration = this.moment().add(1, 'week');
                        break;
                    case 'two_weeks':
                        expiration = this.moment().add(2, 'week');
                        break;
                    case 'three_weeks':
                        expiration = this.moment().add(3, 'week');
                        break;
                    case 'four_weeks':
                        expiration = this.moment().add(4, 'week');
                        break;
                }
            }

            return expiration.format('MMMM D, YYYY');
        },

        uploadProfileImage: function uploadProfileImage() {
            if (this.workspaceFile.owner._id === this.user._id && this.isAvatarUploadable) {
                this.ModalService.openUploadProfilePhotoModal(this.user).then(function updateUser () {
                    //refresh the avatar.
                    this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
                    this.userHasDefaultProfileImage = this.user && this.user.isDefaultProfileImage();
                }.bind(this));
            }
        },

        getIsAvatarUploadable: function getIsAvatarUploadable() {
            return (this.workspaceFile.owner._id === this.user._id) && !this.PreviewModeService.isInSendFilePreviewMode();
        }
    });
}());
