// @ngInject

Models.ContactFormModel = function ContactFormModel(BaseModel, moment, ContactFormManager, _) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'ContactFormModel';
            this.moment = moment;
            this._ = _;

            this._ = _;
            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                questions: 'QuestionsCollection',
                file_dynamic_content: 'FileDynamicContentModel',
                contact_form_settings: 'ContactFormSettingsModel',
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return {
                file_type: 'contact_form'
            };
        },

        getRootModelType: function getRootModelType() {
            return 'contact_form';
        },

        getRootModel: function getRootModel() {
            return this;
        },

        getMemberIds: function getMemberIds(company) {
            var possibleIds = [];
            if (this.inquiry_members) {
                this.inquiry_members.forEach(function(member){
                    possibleIds.push(member.user_id);
                }.bind(this));
            }
            if (this.vendor_id_for_inquiries) {
                possibleIds.push(this.vendor_id_for_inquiries);
            }

            var ret  = [];
            if (company) {
                possibleIds.forEach(function(id) {
                    if (id && (id === company.owner._id || company.team_members.some(function (m) {
                        return m._id === id;
                    }))) {
                        ret.push(id);
                    }
                });
            } else {
                ret = possibleIds;
            }
            return this._.uniq(ret);
        },

        getContactFormState: function getContactFormState() {
            var contactFormState = "add_to_web_site";
            var recaptchaReleaseDate = this.moment("2017-04-03 23:05 +0000", "YYYY-MM-DD HH:mm Z");

           if (this.migration_to_dynamic_file_timestamp && (!this.last_publish_timestamp ||
                this.moment(this.migration_to_dynamic_file_timestamp).isAfter(this.moment(this.last_publish_timestamp)))) {
                contactFormState = "add_to_web_site";
            } else if (this.are_changes_published && this.last_publish_timestamp && this.moment(this.last_publish_timestamp).isAfter(recaptchaReleaseDate)) {
                contactFormState = "embed_code"
            } else if (this.last_publish_timestamp) {
                contactFormState = "publish_changes"
            }

            return contactFormState;
        },

        setGiftCardState: function setGiftCardState() {
            this.isFirstGiftCardPublish =  !this.are_changes_published && !this.last_publish_timestamp
        },

        getStyleObj: function getStyleObj(model) {
            if (this.isDynamicFile()) {
                return this.file_dynamic_content.style_container[model] || {};
            } else {
                return this.style || {};
            }
        },

        /** Contact Form Actions */

        update: function update() {
            return this.__parent.updateContactForm(this);
        },

        createQuestion: function createQuestion(question) {
            return this.__parent.contactFormCreateQuestion(question, this);
        },

        createEventQuestions: function createEventQuestions() {
            return this.__parent.contactFormCreateEventQuestions(this);
        },

        updateQuestion: function updateQuestion(question) {
            return this.__parent.contactFormUpdateQuestion(question, this);
        },

        updateQuestionsOrder: function updateQuestionOrder() {
            return this.__parent.contactFormUpdateQuestionsOrder(this);
        },

        deleteQuestion: function deleteQuestion(question) {
            return this.__parent.contactFormDeleteQuestion(question, this);
        },

        updateStyle: function updateStyle(styleObjName) {
            if (this.isDynamicFile()) {
                return ContactFormManager.dynamicContactFormUpdateStyle(this, styleObjName);
            } else {
                return this.__parent.contactFormUpdateStyle(this);
            }
        },

        isDynamicFile: function isDynamicFile() {
            return this.data_model === 'dynamic_file';
        },

        setUserAsInquiryRecipient: function setUserAsInquiryRecipient(user_id) {
            return this.__parent.setUserAsInquiryRecipient(user_id, this);
        },

        setCaptchaType: function setCaptchaType(type) {
            return this.__parent.setContactFormCaptchaType(type, this);
        },

        setRedirectUrl: function setRedirectUrl(redirectUrl) {
            return this.__parent.setContactFormRedirectUrl(redirectUrl, this);
        },

        setContactFormIsInstantPay: function setContactFormIsInstantPay() {
            return ContactFormManager.setContactFormIsInstantPay(this.__parent, this, this.is_instant_booking_enabled);
        },

        publish: function publish() {
            return this.__parent.publishContactForm(this);
        },

        preview: function preview() {
            return this.__parent.getContactFormPreview(this);
        },

        isDeletable: function isDeletable() {
            // return !(this.from_migration || this.isPublished());
            return true;
        },

        isPublished: function isPublished() {
            return !!this.last_publish_timestamp;
        },

        isValid: function isValid() {
            return this.file_dynamic_content.isQuestionsValid();
        },

        ///////////////////////////////////////////
        // New Contact From APIs (Dynamic content)
        ///////////////////////////////////////////

        updateSettings: function updateSettings(changedFields) {
            var params = this.contact_form_settings.fieldsOnly(changedFields);
            return ContactFormManager.contactFormUpdateSettings(this, params);
        },

        addBlock: function addBlock(fileDynamicContent, section, params) {
            return ContactFormManager.addBlockToContactForm(this, section, params);
        },

        updateBlock: function updateBlock(fileDynamicContent, section, block, changedFields) {
            return ContactFormManager.updateContactFormBlock(this, section, block, changedFields);
        },

        deleteBlock: function deleteBlock(fileDynamicContent, section, block) {
            return ContactFormManager.deleteContactFormBlock(this, section, block);
        },

        updateSectionBlocksOrder: function updateSectionBlocksOrder(fileDynamicContent, section, blocksOrder) {
            return ContactFormManager.updateContactFormSectionBlocksOrder(this, section, blocksOrder);
        },

        updateComponent: function updateComponent(fileDynamicContent, section, block, component, path, changedFields, params) {
            return ContactFormManager.updateContactFormComponent(this, section, block, component, path, changedFields, params);
        },

        resetContactFormToLastPublished: function resetContactFormToLastPublished() {
            return ContactFormManager.resetContactFormToLastPublished(this);
        },

        toggleActivation: function toggleActivation() {
            return ContactFormManager.toggleActivation(this, !this.contact_form_settings.is_active);
        },

        updateProposalOnBlock: function updateProposalOnBlock(fileDynamicContent, section, block, proposalData) {
            return ContactFormManager.updateProposal(this, section, block, proposalData);
        },

        addPackageToBlock: function addPackageToBlock(fileDynamicContent, section, block, packageId) {
            return ContactFormManager.addPackage(this, section, block, packageId);
        },

        updatePackageOnBlock: function updatePackageOnBlock(fileDynamicContent, section, block, packageModel) {
            return ContactFormManager.updatePackage(this, section, block, packageModel);
        },

        deletePackageFromBlock: function deletePackageFromBlock(fileDynamicContent, section, block, packageModel) {
            return ContactFormManager.deletePackage(this, section, block, packageModel);
        },

        updatePackageServicesOrderOnBlock: function updatePackageServicesOrderOnBlock(fileDynamicContent, section, block, packageModel, order) {
            return ContactFormManager.updatePackageServicesOrder(this, section, block, packageModel, order);
        },

        addServiceToBlock: function addServiceToBlock(fileDynamicContent, section, block, packageModel) {
            return ContactFormManager.addService(this, section, block, packageModel);
        },

        updateServiceOnBlock: function updateServiceOnBlock(fileDynamicContent, section, block, service, packageModel) {
            return ContactFormManager.updateService(this, section, block, service, packageModel);
        },

        updateServiceImageOnBlock: function updateServiceImageOnBlock(fileDynamicContent, section, block, service, image, packageModel) {
            return ContactFormManager.updateServiceImage(this, section, block, service, image, packageModel);
        },

        deleteServiceFromBlock: function deleteServiceFromBlock(fileDynamicContent, section, block, service, packageModel) {
            return ContactFormManager.deleteService(this, section, block, service, packageModel);
        },

        updateProposalOrder: function updateProposalOrder(fileDynamicContent, section, block, order) {
            return ContactFormManager.updateProposal(this, section, block, order);
        },

        moveServiceOnBlock: function moveServiceOnBlock(fileDynamicContent, section, block, serviceId, sourcePackageId, destPackageId, order) {
            return ContactFormManager.moveService(this, section, block, serviceId, sourcePackageId, destPackageId, order);
        },

        getModelType: function getModelType() {
            if (this.contact_form_flow_type && this.contact_form_flow_type !== 'default') {
                return this.contact_form_flow_type;
            } else {
                return 'contact_form';
            }
        },

        isGiftCard: function isGiftCard() {
        	return this.contact_form_flow_type === 'gift_card' ||
					this.contact_form_flow_type === 'credits';
        }
    });
};

