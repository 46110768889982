(function () {
    'use strict';

    class FeedItemSessionViewerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AppStates, UsersManager, moment, TimezoneService) {
            super($scope, $injector);
            this.__objectType = 'FeedItemSessionViewerController';

            this.moment = moment;

            this.AppStates = AppStates;
            this.UsersManager = UsersManager;
            this.TimezoneService = TimezoneService;
        }

        $onInit() {
            this.user = this.UsersManager.getCurrUser();

            this.isCanceled = this.activity.action_type === 'meeting_canceled';
            this.isScheduled = this.activity.action_type === 'meeting_scheduled';
            this.isRescheduled = this.activity.action_type === 'meeting_updated';

            this.setAsProjectDate = this.activity.calendar_item.set_as_project_date;

            this.performedByCurrentUser = this.activity.performing_user_id === this.user._id;
            this.calendarItemTitle = this.activity.calendar_item.title;
            this.uniqueNum = Math.floor(Math.random() * 10000);
        }

        getSessionDate() {
            return this.activity.calendar_item.date_time_start;
        }

        shouldShowViewCalendar() {
            return this.activity.calendar_item.owner_id === this.user._id;
        }

        getActivityPreformerName(){
            let name = this.activity.performing_user_name || this.activity.meeting_owner_full_name;
            name = name.replace(/'/g, '\\\'');
            name = name.replace(/"/g, '\\\"');
            return name;
        }

        getCalendarItemTitle(){
            let sessionName = this.calendarItemTitle.replace(/'/g, '\\\'');
            sessionName = sessionName.replace(/"/g, '\\\"');
            return sessionName;
        }
    }

    Components.FeedItemSessionViewer = {
        bindings: {
            activity: '<'
        },
        controller: FeedItemSessionViewerController,
        name: 'hbFeedItemSessionViewer',
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_session_viewer_template.html',
    };

}());
