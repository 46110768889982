(function () {
    "use strict";

    var _$q, _$http, _Gon;

    // @ngInject
    function InstagramAPIServiceCtor($q, Gon, $window, UsersManager) {
        this._$q = $q;
        this._Gon = Gon;
        this.$window = $window;
        this.UsersManager = UsersManager;
    }


    Services.InstagramAPIService = Class(function () {

        return{

            constructor: InstagramAPIServiceCtor,
            connect: function connect() {
                var encodedURI = encodeURIComponent(this._Gon.url + 'app/settings/account?auth=instagram&org_redirect_uri=app.settings.account');
                var instaAuthUrl = 'https://api.instagram.com/oauth/authorize/?' + [
                        'client_id=' + this._Gon.instagram_app_client_id,
                        'redirect_uri='+ encodedURI,
                        'response_type=code',
                        'scope=basic'].join('&');
                this.$window.location = instaAuthUrl;

            },
            disconnect: function disconnect(user) {
                this.UsersManager.disconnectSocialAccount(user, 'instagram');
            }

        };
    });

}());
