(function () {
    'use strict';

    class ScheduledSessionsController extends Controllers.BaseControllerES6 {


        // @ngInject
        constructor($scope, $injector, AppStates, $state, moment, FlowService, TimezoneService) {
            super($scope, $injector);
            this.__objectType = 'ScheduledSessionsController';

            this.moment = moment;

            this.AppStates = AppStates;
            this.$state = $state;
            this.FlowService = FlowService;
            this.TimezoneService = TimezoneService;
        }

        $onInit() {
            this.MAX_DISPLAY = 2;
            this.sessionsDisplayLimit = this.MAX_DISPLAY;
        }

        shouldShowExpand() {
            return this.sessions && this.sessions.length > this.MAX_DISPLAY && this.sessionsDisplayLimit === this.MAX_DISPLAY;
        }

        expand() {
            this.sessionsDisplayLimit = undefined; // causing ng-repeat limitTo to be ignored
        }

        getSortedSessions() {
            if (!this.sessions) {
                return [];
            }

            return this.sessions.sort((a, b) => {
                return new Date(this.getSessionDate((a))) - new Date(this.getSessionDate(b));
            });
        }

        isSessionRelatedToWorkflowStep(sessionId) {
            if(this.upcomingWorkflowSteps && this.upcomingWorkflowSteps.length > 0) {
                return this.upcomingWorkflowSteps.some(step => step.session && sessionId === step.session.id);
            }

            return false;
        }

        getSessionDate(session) {
            return session.date_time_start;
        }

        goToFlow(session) {
            if(!session.flow_id) {
               return;
            }
            this.FlowService.goToFlow(session.flow_id, null, {});
        }
    }

    Components.ScheduledSessions = {
        bindings: {
            sessions: '<',
            upcomingWorkflowSteps: '<'
        },
        controller: ScheduledSessionsController,
        name: 'hbScheduledSessions',
        templateUrl: 'angular/app/modules/core/features/workspace/scheduled_sessions/scheduled_sessions_component_template.html',
    };

}());