// @ngInject

Models.FileBlockModel = function FileBlockModel(BaseModel) {

    return Class(BaseModel, {
        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'FileBlockModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                proposal: 'ProposalModel',
                block_component: 'BlockComponentModel'
            };
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        bubbleUp: function bubbleUp() {
            return [
                'updateComponent',
                'answerQuestion',
                'getRootModelType',
                'getRootModel',
            ];
        },

        isDeletable: function isDeletable() {

            if(this.type === 'component' &&
               this.block_component) {
                return this.block_component.isDeletable();
            }

            return true;
        },

        updateBlock: function updateBlock(changedFields) {
            return this.__parent.updateBlock(this, changedFields);
        },

        deleteBlock: function deleteBlock() {
            return this.__parent.deleteBlock(this);
        },

        /*****************************************
         * NEW PROPOSAL ACTIONS
         ******************************************/

        updateProposal: function updateProposal(data) {

        },

        updateProposalOnBlock: function updateProposalOnBlock(data) {
            return this.__parent.updateProposalOnBlock(this, data);
        },

        addPackage: function addPackage(proposal, packageId) {
            return this.__parent.addPackageToBlock(this, packageId);
        },

        updatePackage: function updatePackage(proposal, packageModel) {
            return this.__parent.updatePackageOnBlock(this, packageModel);
        },

        deletePackage: function deletePackage(proposal, packageModel) {
            return this.__parent.deletePackageFromBlock(this, packageModel);
        },

        updateServicesOrder: function updateServicesOrder(proposal, packageModel) {

            var order = packageModel.base_services.map(function (service) {
                return {_id: service._id, order: service.order};
            });

            return this.__parent.updatePackageServicesOrderOnBlock(this, packageModel, order);
        },

        addCustomItem: function addCustomItem(proposal) {
            return this.__parent.addServiceToBlock(this);
        },

        updateService: function updateService(proposal, service, packageModel) {
            return this.__parent.updateServiceOnBlock(this, service, packageModel);
        },

        updateServiceQuantity: function updateServiceQuantity(proposal, service, packageModel) {
            return this.__parent.updateServiceQuantityOnBlock(this, service, packageModel);
        },

        updatePackageQuantity: function updatePackageQuantity(proposal, packageModel) {
            return this.__parent.updatePackageQuantityOnBlock(this, packageModel);
        },

        updateServiceImage: function updateServiceImage(proposal, service, image, packageModel) {
            return this.__parent.updateServiceImageOnBlock(this, service, image, packageModel);
        },

        deleteServiceImage: function deleteServiceImage(proposal, service, packageModel) {
            return this.__parent.updateServiceImageOnBlock(this, service, '', packageModel);
        },

        updateProposalSelection: function updateProposalSelection(proposal, service, packageModel, isSelected) {
            return this.__parent.updateProposalSelection(this, service, packageModel, isSelected);
        },

        deleteService: function deleteService(proposal, service, packageModel) {
            return this.__parent.deleteServiceFromBlock(this, service, packageModel);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(proposal, order) {
            return this.__parent.updateProposalOrder(this, order);
        },

        moveService: function moveService(proposal, serviceId, sourcePackageId, destPackageId, servicesCollection) {

            // build order model
            var order = [];
            servicesCollection.forEach(function(service, index) {
                order.push({_id: service._id, order: index});
            });

            return this.__parent.moveServiceOnBlock(this, serviceId, sourcePackageId, destPackageId, order);
        },

        /*****************************************
         * BROCHURE SPECIFIC - TEMP
         ******************************************/
        shouldShowTax: function () {
            return false;
        },

        shouldShowSVC: function () {
            return false;
        },

        calculateProposalTax: function calculateProposalTax() {
            return false;
        },

        isInvoice: function isInvoice() {
            return false;
        },

    });
};

