(function() {
	'use strict';

	// @ngInject
	function GiftCardPublishModalControllerCtor($scope, $sce, $injector, $timeout, $modalInstance,
		AnalyticsService, contactFormModel, $document, $window, DeviceService, ContactFormService, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GiftCardPublishModalController';
        this.ContactFormService = ContactFormService;
        this.$sce = $sce;
        this.$window = $window;
		this.contactFormModel = contactFormModel;
		this.isFirstGiftCardPublish = this.contactFormModel.isFirstGiftCardPublish;
		this.isActivationToggleOn = this.contactFormModel.contact_form_settings.is_active;
		this.giftCardImage = this.contactFormModel.gift_card_image;
		this.DeviceService = DeviceService;
        this.$modalInstance = $modalInstance;
		this.AnalyticsService = AnalyticsService;
		this.$timeout = $timeout;
		this._ = _;
        this.tradeShowUrl = this.ContactFormService.getPublicUrl(contactFormModel);
		this.copyDirectLinkMsg = false;

		$document.keydown(function($event) {
			if ($event.keyCode === 27) {
				this.close();
			}
		}.bind(this));

		$scope.$on('$destroy', function() {
			$document.off('keydown');
		});

		this.copiedDirectLink = function copiedDirectLink() {
			this.copyDirectLinkMsg = true;
			this.AnalyticsService.trackClick(this, "copied gift card direct link");
		}
		
		this.openTradeShowTab = function openTradeShowTab() {
            if (this.tradeShowUrl && this.contactFormModel.last_publish_timestamp) {
				this.$window.open(this.tradeShowUrl, '_blank');
				this.AnalyticsService.trackClick(this, "gift card: opened direct link in new tab");
            } else {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.WIDGET._NEED_TO_PUBLISH_TRADE_SHOW_');
            }
		}

		if (this.DeviceService.isSafari()) {
			this.hideForSafari = true;
		}
	}

	Controllers.GiftCardPublishModalController = Class(
		Controllers.BaseController,
		{
			constructor: GiftCardPublishModalControllerCtor,

            close: function() {
				this.AnalyticsService.trackClick(this, "closed modal by clicking 'x'", {source: 'gift card'});
                this.$modalInstance.close();
			}
		}
	);
})();
