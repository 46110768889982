(function () {
    "use strict";

    // @ngInject
    function OpportunityTypesServiceCtor() {

        this.list = {
            'paid_opportunity': {
                key: 'paid_opportunity',
                title: 'Paid',
                description: 'You or someone else is paying for services.\nE.g. looking for a second shooter.',
                iconClass: 'icon-hb-nx-money-42'
            }, 'collaboration': {
                key: 'collaboration',
                title: 'Collaboration',
                description: 'You\'re seeking to work on a project w/ professionals but with no $ exchanged.\nE.g. styled shoots or dream projects',
                iconClass: 'icon-hb-nx-users-42'
            },
            'event': {
                key: 'event',
                title: 'Event',
                description: 'You have an event to share.\nE.g. workshops, meetups, webinars, networking, summits etc...',
                iconClass: 'icon-hb-nx-calendar-star-42'
            },
            'buy_sell': {
                key: 'buy_sell',
                title: 'Buy / Sell',
                description: 'You\'re looking to buy or sell a physical & professional object.\nE.g. a laptop, camera, turntable etc...',
                iconClass: 'icon-hb-nx-cart-42'
            },
            'trade': {
                key: 'trade',
                title: 'Trade',
                description: 'You\'re looking to trade professional services or objects.\nE.g. bartering for goods or skills',
                iconClass: 'icon-hb-nx-opposite-arrows-42'
            },
            'offer_services': {
                key: 'offer_services',
                title: 'Promote My Services',
                description: 'Offer your services to the community.\nE.g. availability to take new clients or a discount',
                iconClass: 'icon-hb-nx-megaphone-42'
            },
            'other': {
                key: 'other',
                title: 'Other',
                description: 'Anything that isn\'t listed above.\nE.g Emmmm, be creative!',
                iconClass: 'icon-hb-nx-other-42'
            }
        };

        this.listEdit = {
            'paid_opportunity': this.list.paid_opportunity,
            'collaboration': this.list.collaboration,
            'offer_services': this.list.offer_services,
            'other': this.list.other
        };
    }

    Services.OpportunityTypesService = Class(function () {

        return {

            constructor: OpportunityTypesServiceCtor,

            getOpportunityTypeName : function getOpportunityType(key) {
                if (!key) {
                    return null
                }
                return this.list[key].title;
            },

            getOpportunityTypeIcon : function getOpportunityType(key) {
                if (!key) {
                    return null
                }
                return this.list[key].iconClass;
            },

        };
    });
}());
