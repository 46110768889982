(function () {
    'use strict';

    // @ngInject
    function TaxInfoControllerConstructor($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TaxInfoController';
    }

    Controllers.TaxInfoController = Class(Controllers.BaseController, {
        constructor: TaxInfoControllerConstructor
    });
}());

