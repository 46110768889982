(function () {
  "use strict";

  Controllers.NewWorkspaceNotesController = class NewWorkspaceNotesController extends (
    Controllers.BaseControllerES6
  ) {
    // @ngInject
    constructor(
      $scope,
      $injector,
      $stateParams,
      UsersManager,
      WorkspacesManager
    ) {
      super($scope, $injector);
      this.__objectType = "NewWorkspaceNotesController";
      this.UsersManager = UsersManager;
      this.currUser = this.UsersManager.getCurrUser();
      this.notesScope = this.currUser.isClient()
        ? "client_portal_notes"
        : "project_workspace_notes";
      this.workspaceId = $stateParams.workspace_id;
      WorkspacesManager.getWorkspace(this.workspaceId, false, true, true).then(
        (workspace) => {
          this.isUserWorkspaceMember = workspace.isMemberInWorkspace(
            this.currUser._id
          );
        }
      );
    }
  };
})();
