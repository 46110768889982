(function () {
    'use strict';

    // @ngInject
    function CreditCardConfirmControllerCtor($scope, $injector, AnalyticsService, $translate, $modalInstance, UsersManager, PopupMessageService, card, $timeout, DeviceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CreditCardConfirmController';
        AnalyticsService.trackPageView(this, 'CreditCardConfirmView');

        this.card = card;
        this.modalInstance = $modalInstance;
        this.$translate = $translate;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.$timeout = $timeout;
        this.isTouchDevice = DeviceService.checkIfTouchDevice();
    }

    Controllers.CreditCardConfirmController = Class(Controllers.BaseController, {
        constructor: CreditCardConfirmControllerCtor,

        creditCardValidationConfirm: function creditCardValidationConfirm() {
            if (!this.expMonth || !this.expYear) {
                this.errorMessage = this.$translate.instant('FILE.PAYMENT.CARD_CONFIRM._PLEASE_ENTER_EXP_DATE_');
                return;
            }

            var month = this.expMonth;
            var year = parseInt(this.expYear) + 2000; // This will crash when will get to the 3000s, someone should fix it by then

            this.validateCardExpirationDate(this.card._id, month, year).then(function(resp) {
                this._setValidation(resp.data);

                if (this.creditCardConfirmForm.$valid){
                    this.modalInstance.close({valid: true});
                }
            }.bind(this)).catch(function fail() {
                this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_');
            }.bind(this));
        },

        validateCardExpirationDate: function validateCardExpirationDate(paymentMethodId, month, year) {
            return this.UsersManager.validateCardExpirationDate(paymentMethodId, month, year);
        },

        _setValidation: function _setValidation(val) {
            this.creditCardConfirmForm.$setValidity('cardConfirmation', val);
            this.errorMessage = !val ? this.$translate.instant('FILE.PAYMENT.CARD_CONFIRM._INCORRECT_TRY_AGAIN_') : '';
        },

        addAnotherCard: function addAnotherCard() {
            this.modalInstance.close({addNewCard: true});
        },

        dismiss: function dismiss(triggerSource) {
            this.modalInstance.dismiss();

            if (triggerSource) {
                this._analyticsClickOutside();
            }
        },

        _analyticsClickOutside: function _analyticsClickOutside() {
            var params = {
                source: 'credit card confirm modal',
                secondarySource: 'click outside'
            };
            this.AnalyticsService.trackClick(this, 'collapse credit card confirm modal', params);
        },

        onKeyUpMonth: function onKeyUpMonth($event) {
            var maxLength = parseInt($event.target.attributes["maxlength"].value, 10);

            var value = $event.target.value.toString();
            // adds 0 to month user input like 9 -> 09
            if (value.length === 1 && value > 1) {
                $event.target.value = "0" + value;
            }

            // if we have a filled input we jump to the year input
            if ($event.target.value.length >= maxLength) {
                angular.element('#expYear').focus();
            }
            $event.stopImmediatePropagation();
        },

        onKeyDownYear: function onKeyDownYear(event) {
            // if the year is empty jump to the month input
            if (event.key === "Backspace" && event.target.selectionStart === 0) {
                angular.element('#expMonth').focus();
                event.stopImmediatePropagation();
            }
        },

        allowOnlyNumbers: function allowOnlyNumbers(event) {
            var inputMatchesPattern = function(e) {

                var value = e.target.value;
                var selectionStart = e.target.selectionStart;
                var selectionEnd = e.target.selectionEnd;
                var pattern = e.target.pattern;

                var character = String.fromCharCode(e.which);
                var proposedEntry = value.slice(0, selectionStart) + character + value.slice(selectionEnd);
                var match = proposedEntry.match(pattern);

                return e.metaKey || // cmd/ctrl
                    e.which <= 0 || // arrow keys
                    e.which === 8 || // delete key
                    match && match["0"] === match.input; // pattern regex isMatch - workaround for passing [0-9]* into RegExp
            };

            if (!inputMatchesPattern(event)) {
                return event.preventDefault();
            }
        }


    });
}());
