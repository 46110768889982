(function () {
// @ngInject
    Services.HttpAuthInterceptor = function HttpAuthInterceptor(Gon, RepositoryService, Constants, Fingerprint, $window) {

        var IGNORE_REQUEST_URLS = [
            'https://api.cloudinary.com',
            'https://res.cloudinary.com'
        ];
        var userIdHeaderListener = null;
        var csrfToken = null;
        var fingerprint = null;
        Fingerprint().then(function (res) {
            fingerprint = res;
        });

        function getCSRFToken() {
            if (csrfToken === null) {
                csrfToken = angular.element('meta[name=csrf-token]').attr('content');
            }
            return csrfToken;
        }

        return {

            'request': function request(config) {

                var ignore = IGNORE_REQUEST_URLS.some(function(url) {
                    return config.url.startsWith(url);
                });

                if (ignore){
                    return config;
                }

                if (!angular.isObject(config.headers)) {
                    config.headers = {};
                }

                var userIdHeader = RepositoryService.storageGet(Constants.storage.AUTH_USER_ID);
                var authToken = RepositoryService.storageGet(Constants.storage.AUTH_TOKEN);
                var trustedDevice = RepositoryService.storageGet(Constants.storage.TRUSTED_DEVICE);
                var mfaSession = RepositoryService.storageGet(Constants.storage.MFA_SESSION);
                var isTempPass = RepositoryService.storageGet(Constants.storage.IS_TEMP_PASS);

                var extend = {};
                extend[Gon.hb_api_headers.api_client_version] = Gon.api_version;
                extend[Gon.hb_api_headers.fingerprint] = fingerprint;

                if (userIdHeader) {
                    extend[Gon.hb_api_headers.auth_user_id] = userIdHeader;
                }

                if (authToken) {
                    extend[Gon.hb_api_headers.auth_token] = authToken;
                }

                if (trustedDevice) {
                    extend[Gon.hb_api_headers.trusted_device] = trustedDevice;
                }

                if(mfaSession) {
                    extend[Gon.hb_api_headers.mfa_session] = mfaSession;
                }

                if (isTempPass) {
                    extend[Gon.hb_api_headers.is_temp_pass] = isTempPass;
                }

                extend[Gon.hb_api_headers.csrf_token] = getCSRFToken();

                var currUser = RepositoryService.storageGet(Constants.storage.CURR_USER);
                extend[Gon.hb_api_headers.admin_login] = currUser ? !!currUser.is_hb_admin : false;

                extend[Gon.hb_api_headers.hb_referer] = $window.location.href;

                if (currUser && currUser.admin_token){
                    extend[Gon.hb_api_headers.admin_token] = currUser.admin_token;
                }
                angular.extend(config.headers, extend);

                if(userIdHeaderListener){
                    userIdHeaderListener.checkCurrUserHeader(userIdHeader);
                }

                return config;
            },

            registerUserIdHeaderListener: function registerUserIdHeaderListener(listener){
                userIdHeaderListener = listener;
            }
        };
    };

}());