/**
 * Created by inonstelman on 1/1/15.
 */

Directives.ToggleElementVisibilityDirective = function ToggleElementVisibilityDirective() {

    // @ngInject
    function ToggleElementVisibilityDirectiveControllerFunc($element, $scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ToggleElementVisibilityDirectiveController';

        var self = this;

        if (self.hbToggleElementVisibility) {
            $element.bind('click', function () {

                if(self.ownToggleClass){
                    angular.element($element).toggleClass(self.ownToggleClass);
                }

                var idToFind = '#' + self.hbToggleElementVisibility;
                var toggleElement = $element.find(idToFind)[0];
                if (toggleElement) {

                    angular.element(toggleElement).toggleClass('hide');

                } else {
                    var elem = $element.parent();
                    while (elem) {
                        toggleElement = elem.find(idToFind)[0];

                        if (toggleElement) {
                            angular.element(toggleElement).toggleClass('hide');
                            return;
                        }

                        elem = elem.parent();
                    }
                }
            })

        }
        ;
    }

    var ToggleElementVisibilityDirectiveController = Class(Controllers.BaseController, {
        constructor: ToggleElementVisibilityDirectiveControllerFunc
    });


    return {
        scope: {
            hbToggleElementVisibility: '@hbToggleElementVisibility',
            ownToggleClass: '@ownElementToggleClass'
        },
        controller: ToggleElementVisibilityDirectiveController,
        controllerAs: 'toggleElementVm',
        bindToController: true
    }
}
