/*
/ text: string. to be visible in tooltip.
/ expression: string. the expression on romey's face. default to none. 'none', 'hiding' (🕶), 'error' (💧)
 */

(function () {
    'use strict';

    class RomeyTooltipController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'RomeyTooltipController';
        }
    }

    Components.RomeyTooltip = {
        bindings: {
            text: '<',
            expression: '<'
        },
        controller: RomeyTooltipController,
        name: 'hbRomeyTooltip',
        templateUrl: 'angular/app/modules/core/features/signup/romey_tooltip/romey_tooltip_component.html',
    };

}());