Directives.EditableHeaderDirective = function EditableHeaderDirective() {

    // @ngInject
    function EditableHeaderDirectiveControllerFunc($injector, $scope, _, ModalService, ImagesManager, ImageService, WorkspaceFilesManager, AnalyticsService, DeviceService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EditableHeaderDirectiveController';

        this._ = _;
        this.ModalService = ModalService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.ImagesManager = ImagesManager;
        this.ImageService = ImageService;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;

        this.imageRatio = 2.5;
        this.iconRatio = 1;
        this.initialLoad = true;
        this._init();

        this.register(this.fileModel, 'success', function success() {
            this.$scope.$applyAsync(function () {
                if (this.initialLoad) { this._setHeaderModel(); }
            }.bind(this));
        }.bind(this));

        this.register(this.fileModel, 'fileHeaderUpdated', function fileHeaderUpdated() {
            this.$scope.$applyAsync(function () {
                this._setHeaderModel();
            }.bind(this));
        }.bind(this));
    }


    var EditableHeaderDirectiveController = Class(Controllers.BaseController, {
        constructor: EditableHeaderDirectiveControllerFunc,

        ///////////////////////////////
        // HEADER SETTINGS
        //////////////////////////////

        _init: function _init() {
            this.header = {};
        },

        _setHeaderModel: function _setHeaderModel() {
            this.initialLoad = false;
            this.header = this._.clone(this._headerModel(), true);
        },

        _headerModel: function _headerModel() {
            return {
                fileTitle: this.fileModel.file_title,
                title: this.fileModel.header_title,
                subTitle: this.fileModel.header_subtitle,
                icon: this.fileModel.workspace_file_header_icon,
                image: this.fileModel.workspace_file_image,
            };
        },

        ///////////////////////////////
        // HEADER IMAGE
        //////////////////////////////
        expandHeaderImage: function expandHeaderImage() {

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.file_header_edit_background, {});

            var copiedImageId = this.header.image._id;

            var options = {
                selectedImage: this.header.image,
                imageDisplayRatio: this.imageRatio,
                showPreviewWhenCroppingDisplay: true,
                skipSavingToServer: false,
                title: 'MEDIA_MODAL.TITLE._EDIT_PROJECT_IMAGE_',
                model: ''
            };

            this.ImageService.chooseImage(options).then(
                function success(newImage) {
                    if(copiedImageId !== newImage._id ) {
                        this.WorkspaceFilesManager.setWorkspaceFileImage(this.fileModel, newImage);
                    }
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_header_edit_background, {});
                }.bind(this)
            );
        },

        ///////////////////////////////
        // HEADER ICON
        //////////////////////////////
        addHeaderIcon: function addHeaderIcon() {

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.file_header_add_icon, {});

            this.ModalService.openAttachAssetModal(
                true, true, true, "Library", false,
                false, this.header.icon, this.iconRatio, false, false, "image/*",'', '', 'MEDIA_MODAL.TITLE._ADD_SQUARE_LOGO_')
                .then(function success(newIcon) {
                    this.WorkspaceFilesManager.setWorkspaceFileHeaderIcon(this.fileModel, newIcon);
                    this.header.icon = newIcon;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_header_add_icon, {});
                }.bind(this));
        },

        editHeaderIcon: function editHeaderIcon(mobile) {
            this.copiedIcon = this._.assign({}, this.header.icon);

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.file_header_edit_icon, {});

            this.ModalService.openAttachAssetModal(
                true, true, true, "Crop", false,
                false, this.header.icon, this.iconRatio, false, false, "image/*", '', '', 'MEDIA_MODAL.TITLE._EDIT_LOGO_')
                .then(function (resp) {
                    this._updateHeaderIcon(resp, this.copiedIcon);
                    this.header.icon = this._.assign({}, resp);
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_header_edit_icon, {});
                }.bind(this));

            if (mobile) { this.showToggleMobileDrawer = false; }
        },

        _updateHeaderIcon: function _updateHeaderIcon(newIcon, currentIcon) {
            if (currentIcon._id === newIcon._id) {
                this.ImagesManager.saveImageReposition(newIcon);
            } else {
                this.WorkspaceFilesManager.setWorkspaceFileHeaderIcon(this.fileModel, newIcon);
            }
        },

        deleteHeaderIcon: function deleteHeaderIcon(mobile) {

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.file_header_delete_icon, {});

            this.WorkspaceFilesManager.deleteWorkspaceFileHeaderIcon(this.fileModel, this.header.icon)
                .then(function (resp) {
                    this.header.icon = resp.data.workspace_file_header_icon;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_header_delete_icon, {});
                }.bind(this));

            if (mobile) { this.showToggleMobileDrawer = false; }
        },

        ///////////////////////////////
        // HEADER TEXT
        //////////////////////////////
        saveTitle: function saveTitle() {
            this.fileModel.header_title = this.header.title;
            this.WorkspaceFilesManager.saveWorkspaceFile(this.fileModel);
        },

        saveSubTitle: function saveSubTitle() {
            this.fileModel.header_subtitle = this.header.subTitle;
            this.WorkspaceFilesManager.saveWorkspaceFile(this.fileModel);
        },

        saveWorkspaceFileTitle: function saveWorkspaceFileTitle() {
            if (this.header.fileTitle.length === 0) {
                return;
            }

            this.fileModel.file_title = this.header.fileTitle;
            this.WorkspaceFilesManager.saveWorkspaceFile(this.fileModel);
        },

        preventEmptySpaces: function preventEmptySpaces($event) {
            var key = $event.keyCode ? $event.keyCode : $event.which;
            if (key === 10 || key === 13)  { $event.preventDefault(); }
        },

        ///////////////////////////////
        // MOBILE PAGE
        //////////////////////////////
        onMobileEdit: function onMobileEdit() {
            this.expandMobileHeader = !this.expandMobileHeader;
            this._setHeaderModel(); //Binds updated data
        },

        onMobileEditPageBack: function onMobileEditPageBack() {
            this.expandMobileHeader = false;
        },

        onToggleMobileDrawer: function onToggleMobileDrawer() {
            if (this.header.icon) { this.showToggleMobileDrawer = !this.showToggleMobileDrawer; }
        },

    });

    return {
        scope: {
            fileModel: '=',
            isEditable: '=',
            isInAppBrowser: '=',
            workspace: '='
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file/editable_header/editable_header_template.html',
        controller: EditableHeaderDirectiveController,
        controllerAs: 'editableHeaderVm',
        bindToController: true
    };
};
