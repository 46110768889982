(function () {
    "use strict";



    Controllers.PauseProjectController = class PauseProjectController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance) {

            super($scope, $injector);
            this.__objectType = 'PauseProjectController';

            this.$modalInstance = $modalInstance;
            this.isProjectPaused = false;
            this.isSendAmendment = true;
        }

        close() {
            this.$modalInstance.dismiss();
        }

        pauseProjectClick() {

        }
    };
}());
