Directives.WorkspacePipelineDirective = function WorkspacePipelineDirective() {

    // @ngInject
        function WorkspacePipelineDirectiveControllerFunc($scope, $, _, AppStates, UIUtils, AppConfigService, AnalyticsService,
                                                      WorkspaceService, CompaniesManager, UsersManager, WorkspacesManager,
                                                      $injector, Enums, ReferralsManager, $document) {

        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspacePipelineDirectiveControllerFunc';

        this.appConfig = AppConfigService.appState();
        this.WorkspaceService = WorkspaceService;
        this.WorkspacesManager = WorkspacesManager;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;
        this.ReferralsManager = ReferralsManager;
        this._ = _;

        this.isClient = UsersManager.getCurrUser().isClient();


        this.company = CompaniesManager.getCurrCompany();
        this.currentStage = null;
        this.dropDownIsShowingMsg = false;
        this.Enums = Enums;
        this.AppStates = AppStates;

        var _init = function _init() {
            this.prepareStagesViewModel();
            this.setCurrentStage();
        }.bind(this);

        this.register(this.workspace, 'success', function success() {
            _init();
            this.setPipelineSettings();
        });


        if (this.company.wasFullyFetched()) {
            _init();
        } else {
            this.register(this.company, 'success', _init);
        }

        this.clickHandler = function (e) {
            var container = $("#ws-pipeline-btn, #ws-pipeline-cont");
            var archivedToggle = angular.element('.pl-ed-status');

            if (!container.is(e.target) && container.has(e.target).length === 0 && !archivedToggle.is(e.target)) {
                self.closePipelineWorkspace();
                $scope.$applyAsync();
            }
        };

        $document.on('click.ws-pipeline-btn.honeybook', this.clickHandler);

        this.closePipelineWorkspace = function closePipelineWorkspace() {
            this.openPipeline = false;
        };

        $scope.$on('$destroy', function handleDestroyWorkspacePipelineDirective() {
            $document.off('click.ws-pipeline-btn.honeybook', this.clickHandler);
        }.bind(this));

    }

    var WorkspacePipelineDirectiveController = Class(Controllers.BaseController, {
        constructor: WorkspacePipelineDirectiveControllerFunc,

        prepareStagesViewModel: function prepareStagesViewModel() {
            if (this.company.user_pipeline_stages) {
                //we must to copy the model otherwise we will use the original objects from company.user_pipeline_stages.
                this.stages = angular.copy(this.company.user_pipeline_stages);
                this.stages = this._.sortBy(this.stages, function(stage){
                    return stage.order ? stage.order : 0;
                });

                this.stages.forEach(function (stage) {
                    if(!stage.originalName){
                        stage.originalName = stage.name;
                    }
                    stage.originalName = stage.name;
                    stage.name = this.WorkspaceService.getPipelineStageName(stage.name);
                }.bind(this));
            }
        },

        onWorkspaceReferred: function onWorkspaceReferred(workspace) {
            return this.ReferralsManager.referWorkspace(workspace, this);
        },
        setCurrentStage: function setCurrentStage() {
            if (!this.workspace.isViewOnlyMode() && this.workspace.workspace_pipeline_data && this.stages) {
                this.currentStage = this._.findWhere(this.stages, {_id: this.workspace.workspace_pipeline_data.current_stage.stage_id});
            }
        },

        isVendor: function isVendor() {
            return this.UIUtils.isVendor();
        },

        toggleShowPipeline: function toggleShowPipeline() {
            this.openPipeline = !this.openPipeline;
        },

        setPipelineSettings: function setPipelineSettings() {
            if (this.workspace.workspace_pipeline_data) {
                this.disablePipelineTracking = this.workspace.workspace_pipeline_data.disable_tracking;
            }
        },

        onWorkspaceChanged: function onWorkspaceChanged(){
                this.openPipeline = false;
        },


        toggleDisablePipelineTracking: function toggleDisablePipelineTracking($event) {
            $event.stopPropagation();
            this.disablePipelineTracking = !this.disablePipelineTracking;
            this.updateDisablePipelineTracking();
        },

        updateDisablePipelineTracking: function updateDisablePipelineTracking() {
            var self = this;
            var trackingData = {
                workspace_id: this.workspace._id,
                disable_tracking: this.disablePipelineTracking,
                source: "workspace pipeline dropdown"
            };

            this.WorkspacesManager.updateWorkspacePipelineSettings(this.workspace, this.disablePipelineTracking)
                .then(function () {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' success', trackingData);
                })
                .catch(function () {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' failed', trackingData);
                    self.disablePipelineTracking = Boolean(self.workspace.workspace_pipeline_data.disable_tracking);
                });
        }
    });

    return {
        scope: {
            workspace: '=workspace',
            isInViewMode: '=isInViewMode'
        },
        templateUrl: 'angular/app/modules/core/features/workspace/pipeline_stage/workspace_pipeline_directive_template.html',
        controller: WorkspacePipelineDirectiveController,
        controllerAs: 'wsPipelineVm',
        bindToController: true
    };
};
