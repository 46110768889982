(function () {
    "use strict";

    Controllers.EditPaymentReminderEmailController = class EditPaymentReminderEmailController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, paymentReminder, lateFeesEnabled, Enums, CompaniesManager, AutomationService, $timeout, $translate, PopupMessageService, $q) {
            super($scope, $injector);
            this.__objectType = 'EditPaymentReminderEmailController';
            this.$modalInstance = $modalInstance;
            this.Enums = Enums;
            this.AutomationService = AutomationService;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.PopupMessageService = PopupMessageService;
            this.$q = $q;

            this.paymentReminder = paymentReminder;
            this.paymentReminderType = paymentReminder.type;
            this.subject = paymentReminder.subject;
            this.htmlBody = paymentReminder.body;
            this._originalSubject = paymentReminder.subject;
            this._originalHtmlBody = paymentReminder.body;
            this.company = CompaniesManager.getCurrCompany();
            this.toolbar = ['bold', '|', 'underline', '|', 'italic', '|', 'link', '|', 'undo'];
            this.lateFeesEnabled = lateFeesEnabled &&
                this.paymentReminderType !== Enums.WorkflowAutomationTypes.auto_recurring_payment_reminder;

            this.isUpcomingReminder = this.paymentReminderType === this.Enums.WorkflowAutomationTypes.upcoming_payment_reminder;
        }

        onSaveClick() {
            if (this.requestInProgress) {
                return;
            }

            this.requestSuccessful = false;
            this.requestInProgress = true;

            this.AutomationService.updateAutomationCustomEmail(this.company, this.paymentReminderType, this.subject, this.htmlBody).then(() => {
                this.requestSuccessful = true;
            }).finally(() => {
                this.requestInProgress = false;
                this.$timeout(() => {
                    this.$modalInstance.close();
                }, 1000);
            });
        }

        onResetToDefaultClick() {

            let subject = null;
            let body = null;

            switch (this.paymentReminderType) {
                case this.Enums.WorkflowAutomationTypes.upcoming_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.UPCOMING._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.UPCOMING._BODY_';
                    break;

                case this.Enums.WorkflowAutomationTypes.outstanding_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.OUTSTANDING._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.OUTSTANDING._BODY_';
                    break;

                case this.Enums.WorkflowAutomationTypes.due_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.DUE._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.DUE._BODY_';
                    break;

                case this.Enums.WorkflowAutomationTypes.auto_recurring_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.AUTO_RECURRING._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.AUTO_RECURRING._BODY_';
                    break;

                case this.Enums.WorkflowAutomationTypes.longer_outstanding_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LONGER_OUTSTANDING._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LONGER_OUTSTANDING._BODY_';
                    break;

                case this.Enums.WorkflowAutomationTypes.before_late_fee_payment_reminder:
                    subject = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LATE_FEE._TITLE_';
                    body = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LATE_FEE._BODY_';
                    break;
            }

            this.subject = this.$translate.instant(subject);
            this.htmlBody = this.$translate.instant(body);
        }

        onDismiss() {
            let promise = this.$q.when();

            if (this._originalSubject !== this.subject || this._originalHtmlBody !== this.htmlBody) {
                this.showOverlay = true;
                promise = this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.warning,
                    'FREQUENT_MESSAGES._CHANGES_LOST_',
                    'FREQUENT_BUTTONS._DISCARD_',
                    'FREQUENT_BUTTONS._KEEP_EDITING_',
                    'FREQUENT_MESSAGES._EXIT_WITHOUT_SAVING_', true, true);
            }

            promise.then(() => {
                this.$modalInstance.dismiss();
            }).catch(() => {
                this.showOverlay = false;
            });
        }

        onClose() {
            this.$modalInstance.close();
        }
    };

}());