/**
 * Created by inonstelman on 12/25/14.
 */
/**
 * A custom object extension method that copies property getter function definitions across from
 * the source to the target, rather than trying to just evaluate the property on the source and
 * copying that across.
 *
 * Otherwise takes the same arguments as `angular.extend`.
 */
Services.ExtendService = function ExtendService() {
    return function extend(dst) {
        angular.forEach(arguments, function(obj){
            if (obj !== dst) {
                for (var key in obj) {
                    var propertyDescriptor = Object.getOwnPropertyDescriptor(obj, key);

                    // If we encounter a getter function,
                    if (propertyDescriptor && propertyDescriptor.get) {
                        // Manually copy the definition across rather than doing a regular copy, as the latter
                        // approach would result in the getter function being evaluated. Need to make it
                        // enumerable so subsequent mixins pass through the getter.
                        Object.defineProperty(
                            dst, key, {get: propertyDescriptor.get, enumerable: true, configurable: true}
                        );
                    } else {
                        // Otherwise, just do a regular copy
                        dst[key] = obj[key];
                    }
                }
            }
        });

        return dst;
    };
};