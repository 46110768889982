(function () {
    'use strict';

    // @ngInject
    function SuggestionsServiceCtor($translate, _, ModalService, UiPersistenceService ) {
        this.$translate = $translate;
        this._ = _;
        this.ModalService = ModalService;
        this.UiPersistenceService = UiPersistenceService;

        this._init();
    }

    Services.SuggestionsService = Class({
        constructor: SuggestionsServiceCtor,

        getSuggestion: function getSuggestion(suggestion) {
            var suggestionItem = this._.find(this.suggestionList, function(e) { return e.name === suggestion; });
            if ( !this._.isEmpty(suggestionItem ) ){
                return suggestionItem;
            } else {
                return false;
            }
        },

        playVideo: function playVideo(video) {
            this.ModalService.openGenericVideoModal(video);
        },

        getSuggestionsPersistance: function getSuggestionsPersistance() {
          return this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.smartSuggestions) || {};
        },

        setSuggestionPersistance: function setSuggestionPersistance(suggestionHash) {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.smartSuggestions, suggestionHash);
        },

        _init: function _init() {
            this.suggestionList = [
                {
                    name: 'calendar',
                    copy: 'SUGGESTIONS.CONNECT_CALENDAR._TEXT_',
                    primaryUrlType: 'callback',
                    primaryCta: 'SUGGESTIONS.CONNECT_CALENDAR._PRIMARY_CTA_',
                    primaryAnalytics: 'connect google calendar',
                    secondaryUrlType: 'tab',
                    secondaryCta: 'SUGGESTIONS.CONNECT_CALENDAR._SECONDARY_CTA_',
                    secondaryUrl: '//intercom.help/honeybook-5891e2a409ff/integrations/syncing-your-google-calendar-in-honeybook',
                    secondaryAnalytics: 'connect google calendar',
                },
                {
                    name: 'google_sync',
                    copy: 'SUGGESTIONS.CONNECT_GMAIL._TEXT_',
                    primaryUrlType: 'callback',
                    primaryCta: 'SUGGESTIONS.CONNECT_GMAIL._PRIMARY_CTA_',
                    primaryAnalytics: 'people connect gmail',
                },
                {
                    name: 'sync_google_contacts',
                    copy: 'SUGGESTIONS.SYNC_GOOGLE_CONTACTS._TEXT_',
                    primaryUrlType: 'callback',
                    primaryCta: 'SUGGESTIONS.SYNC_GOOGLE_CONTACTS._PRIMARY_CTA_',
                    primaryAnalytics: 'Sync google contacts'
                },
                {
                    name: 'proposal',
                    copy: 'SUGGESTIONS.CREATING_SENDING_PROPOSAL._TEXT_',
                    primaryUrlType: 'video',
                    primaryCta: 'SUGGESTIONS.CREATING_SENDING_PROPOSAL._PRIMARY_CTA_',
                    primaryUrl: '//www.youtube.com/embed/TjFAiDe85iY?autoplay=1&rel=0',
                    primaryAnalytics: 'proposal first file'
                },
                {
                    name: 'invoice',
                    copy: 'SUGGESTIONS.CREATING_SENDING_INVOICE._TEXT_',
                    primaryUrlType: 'video',
                    primaryCta: 'SUGGESTIONS.CREATING_SENDING_INVOICE._PRIMARY_CTA_',
                    primaryUrl: '//www.youtube.com/embed/pjAW7Wbmv_4?autoplay=1&rel=0',
                    primaryAnalytics: 'invoice first file'
                },
                {
                    name: 'templates',
                    copy: 'SUGGESTIONS.FIRST_VISIT_TEMPLATES._TEXT_',
                    primaryUrlType: 'video',
                    primaryCta: 'SUGGESTIONS.FIRST_VISIT_TEMPLATES._PRIMARY_CTA_',
                    primaryUrl: '//www.youtube.com/embed/TjFAiDe85iY?autoplay=1&rel=0',
                    primaryAnalytics: 'templates first visit'
                },
                {
                    name: 'home',
                    copy: 'SUGGESTIONS.HOME._TEXT_',
                    primaryUrlType: 'tab',
                    primaryCta: 'SUGGESTIONS.HOME._PRIMARY_CTA_',
                    primaryUrl: '//www.honeybook.com/product-education',
                    primaryAnalytics: 'home second visit'
                },
                {
                    name: 'workspace',
                    copy: 'SUGGESTIONS.WORKSPACE._TEXT_',
                    primaryUrlType: 'tab',
                    primaryCta: 'SUGGESTIONS.WORKSPACE._PRIMARY_CTA_',
                    primaryUrl: '//www.honeybook.com/product-education',
                    primaryAnalytics: 'workspace first project'
                },
                {
                    name: 'pipeline',
                    copy: 'SUGGESTIONS.PIPELINE._TEXT_',
                    primaryUrlType: 'tab',
                    primaryCta: 'SUGGESTIONS.PIPELINE._PRIMARY_CTA_',
                    primaryUrl: '//www.honeybook.com/product-education',
                    primaryAnalytics: 'pipeline after upload materials',
                    secondaryUrlType: 'video',
                    secondaryCta: 'SUGGESTIONS.PIPELINE._SECONDARY_CTA_',
                    secondaryUrl: '//www.youtube.com/embed/TJIq59USd_s?autoplay=1&rel=0',
                    secondaryAnalytics: 'pipeline after upload materials'
                },
                {
                    name: 'contactForm',
                    copy: 'SUGGESTIONS.CONTACT_FORM._TEXT_',
                    primaryUrlType: 'callback',
                    primaryCta: 'SUGGESTIONS.CONTACT_FORM._PRIMARY_CTA_',
                    primaryUrl: 'templates_editor/contactForm',
                    primaryAnalytics: 'website url to CF helper'
                }
            ]
        }
    });
}());
