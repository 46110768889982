(function () {
    "use strict";

    // var CLOUDINARY_CONFIG = {
    //     userUploads: {
    //         production: {
    //             cloudName: 'hb-users',
    //             uploadPreset: 's1zjtwnc'
    //         },
    //         development: {
    //             cloudName: 'hb-dev',
    //             uploadPreset: 'vbbmttut'
    //         }
    //     },
    //     default: {
    //         cloudName: 'honeybook',
    //         uploadPreset: 's9ff7q7r'
    //     }
    // };


    // TODO udi remove this in phase 2 of cloudinary improvements
    var CLOUDINARY_CONFIG = {
        userUploads: {
            production: {
                cloudName: 'honeybook',
                uploadPreset: 'fxuwjbjr'
            },
            development: {
                cloudName: 'honeybook',
                uploadPreset: 'fxuwjbjr'
            }
        },
        default: {
            cloudName: 'honeybook',
            uploadPreset: 'fxuwjbjr'
        }
    };

    CLOUDINARY_CONFIG['userUploads']['test'] = CLOUDINARY_CONFIG['userUploads']['staging'] = CLOUDINARY_CONFIG['userUploads']['development'];

    var _AppConfigService = null;

    // @ngInject
    function CloudinaryConfigServiceCtor(AppConfigService) {
        _AppConfigService = AppConfigService;

        this.CONFIG_TYPE = {
            DEFAULT: 'default',
            USER_UPLOAD: 'userUploads'
        };
    }

    Services.CloudinaryConfigService = Class(function () {

        function getConfig(type) {
            var env = _AppConfigService.appConfiguration().environment;
            var res = CLOUDINARY_CONFIG[type];
            return res && res[env] || CLOUDINARY_CONFIG.default;
        }

        return {
            constructor: CloudinaryConfigServiceCtor,

            getCloudinaryCloudName: function getCloudinaryCloudName(type) {
                var config = getConfig(type);
                return config.cloudName;
            },

            getCloudinaryUploadPreset: function getCloudinaryUploadPreset(type) {
                var config = getConfig(type);
                return config.uploadPreset;
            },

            getDefaultCloudinaryCloudName: function getDefaultCloudinaryCloudName() {
                var config = getConfig(this.CONFIG_TYPE.DEFAULT);
                return config.cloudName;
            },

            getDefaultCloudinaryUploadPreset: function getDefaultCloudinaryUploadPreset() {
                var config = getConfig(this.CONFIG_TYPE.DEFAULT);
                return config.uploadPreset;
            }
        };
    });

}());
