
// @ngInject

Models.QuestionnaireModel = function QuestionnaireModel(BaseModel){

    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'QuestionnaireModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds(){
            return {questions: 'QuestionsCollection'};
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            var args = {
                questionnaire_id: this._id
            };

            angular.extend(args, this.__parent.getAnalyticsArgs());

            return args;
        },

        afterMixingFrom: function afterMixingFrom(){
        },

        updateCoverImage: function updateCoverImage(image) {
            return this.__parent.updateQuestionnaireCoverImage(this, image);
        },

        createQuestion: function createQuestion(question) {
            return this.__parent.createQuestion(this, question);
        },

        createEventQuestions: function createEventQuestions() {
            return this.__parent.createEventQuestions(this);
        },

        updateQuestion: function updateQuestion(question) {
            return this.__parent.updateQuestion(this, question);
        },

        updateQuestionsOrder: function updateQuestionsOrder() {
            return this.__parent.updateQuestionsOrder(this);
        },

        updateQuestionnaire: function updateQuestionnaire(callingScope) {
            return this.__parent.updateQuestionnaire(this, callingScope);
        },

        deleteQuestion: function deleteQuestion(question) {
            return this.__parent.deleteQuestion(this, question);
        }
    });
};

