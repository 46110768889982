(function () {
    "use strict";

    Directives.CompanyAccountAccessDirective =  function CompanyAccountAccessDirective() {

        // @ngInject
        function CompanyAccountAccessDirectiveControllerCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CompanyAccountAccessDirectiveController';

        }

        var CompanyAccountAccessDirectiveController = Class(Controllers.BaseController, {
            constructor: CompanyAccountAccessDirectiveControllerCtor,

        });

        return {
            scope:{},
            templateUrl : 'angular/app/modules/core/features/settings/company/account_access/company_account_access_directive_template.html',
            controller : CompanyAccountAccessDirectiveController,
            controllerAs : 'companySettingsAccountAccessVm',
            bindToController : true
        };

    };
}());
