(function () {
    'use strict';

    // @ngInject
    function ClientFlowModalControllerCtor($scope, $modalInstance, $injector, AnalyticsService, fileType) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ClientFlowModalController';
        AnalyticsService.trackPageView(this, 'ClientFlowModal', {file_type: fileType.type});

        this.$scope = $scope;
        this.AnalyticsService = AnalyticsService;
        this.$modalInstance = $modalInstance;
        this.fileType = fileType;

        this._init();
    }

    Controllers.ClientFlowModalController = Class(Controllers.BaseController, {
        constructor: ClientFlowModalControllerCtor,

        _init: function _init() {
            this.activeStep = 0;

            this.clientFlow = [
                {
                    step: 0,
                    title: 'CLIENT_FLOW_MODAL.STEP_0._TITLE_',
                    subtitle: 'CLIENT_FLOW_MODAL.STEP_0._SUB_TITLE_',
                    cta: 'FREQUENT_BUTTONS._NEXT_',
                    analytics:'next'
                },
                {
                    step: 1,
                    title: 'CLIENT_FLOW_MODAL.STEP_1._TITLE_',
                    subtitle: 'CLIENT_FLOW_MODAL.STEP_1._SUB_TITLE_',
                    cta: 'FREQUENT_BUTTONS._NEXT_',
                    analytics:'next'
                },
                {
                    step: 2,
                    title: 'CLIENT_FLOW_MODAL.STEP_2._TITLE_',
                    subtitle: 'CLIENT_FLOW_MODAL.STEP_2._SUB_TITLE_',
                    cta: 'CLIENT_FLOW_MODAL.STEP_2._CTA_',
                    analytics:'view'
                }
            ];

            /*  DEV NOTES:
                ngSrc doesn't work properly with HTML5 Video Source.
                https://github.com/angular/angular.js/issues/1352
            */
        },

        next: function next(step, last) {
            if (last) {
                this.$modalInstance.close();
                return;
            }

            this.activeStep = step;
            var elem = '#video-' + step;
            angular.element(elem)[0].play();
        },

        dismiss: function dismiss(triggerSource, stepNumber) {
            this.$modalInstance.dismiss();

            if (triggerSource) {
                this.analyticsClickOutside(stepNumber);
            }
        },

        analyticsClickOutside: function analyticsClickOutside(stepNumber) {
            var params = {
                source: this.fileType.fileType + ' client flow modal',
                secondarySource: 'click outside',
                type: 'step' + stepNumber
            };

            this.AnalyticsService.trackClick(this, 'collapse client flow modal', params);
        }


    });
}());

