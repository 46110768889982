Directives.FeedItemDirective =  function FeedItemDirective(){

    // @ngInject
    function FeedItemDirectiveControllerFunc($scope, _, $rootScope, $injector, FlowsManager, ModalService, Enums, PopupMessageService, UsersManager, WorkspaceFileService, AnalyticsService, ngToast, UIUtils, StatsigService){

        this.constructor.$super.call(this, $scope, $injector);
        const AIMeetingNoteTypes = ['ai_meeting_note_generated', 'ai_meeting_recap_generated', 'ai_project_recap_generated'];
        this.__objectType = 'FeedItemDirectiveController';
        this._ = _;
        this.ItemTypes = Enums.FeedItemTypes;
        this.FlowsManager = FlowsManager;
        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.$rootScope = $rootScope;
        this.isHidden = false;
        this.currUser = UsersManager.getCurrUser();
        this.isClient = this.currUser.isClient();
        this.data = this.item.data;
        this.ngToast = ngToast;
        this.UIUtils = UIUtils;
        this.WorkspaceFileService = WorkspaceFileService;
        this.isAIMeetingNoteFeed = AIMeetingNoteTypes.includes(this.item.type) && !this.isClient;
        this.StatsigService = StatsigService;
        this.isContactEnrichmentItem = this.item.type_cd === this.ItemTypes.client_enrichment;
        this.isContactEnrichmentEnabled = false;
        this.StatsigService.isGateEnabled('contact_enrichment').then(function (isEnabled) {
            this.isContactEnrichmentEnabled = isEnabled;
        }.bind(this));

        if (!this.feedUsers[this.currUserId]) { //add current user to feed users for correct view in case curr user comments for the first time
            //hacky solution until we move to fetching updated users in feed, properly.
            this.feedUsers[this.currUserId] = this.currUser;
        }

        this._allowComments = (this.allowComments === "true");
        if (this._allowComments) {
            this.newComment = "";
            this.submittingComment = false;
            this.toggleAllComments(false);
        }
        switch (this.item.type_cd) {
            case this.ItemTypes.workspace_email:
            case this.ItemTypes.workspace_file_email:
            case this.ItemTypes.reschedule_project_email:
            case this.ItemTypes.workspace_message:
            case this.ItemTypes.feed_message:
            case this.ItemTypes.file_comment_message:
            case this.ItemTypes.workspace_flow_email:
            case this.ItemTypes.ooo_auto_reply:
                this.hasSender = true;
                this.initSender();
                break;
            case this.ItemTypes.activity:
            case this.ItemTypes.generic_message:
            case this.ItemTypes.client_enrichment:
                this.hasSender = false;
                break;
        }

        $scope.$watch('feedItemVm.feedUsers', function (newValue, oldValue) {
           this.initSender();
            if (this.showReactEmailViewer) {
                this.initItemForReact();
            }
            if (this.isContactEnrichmentItem) {
                this.initEnrichmentFeedItem();
            }
            if (this.isAIMeetingNoteFeed) {
                this.initNoteFeedItem();
            }
        }.bind(this));

        $scope.$watch('feedItemVm.workspace.flows', function (newValue, oldValue) {
            this.flow  = this.workspace.flows ? this.workspace.flows.find(function(flow) {
                return flow._id === this.item.data._id; }
                .bind(this)) : null;
            if (this.showReactEmailViewer) {
                this.initItemForReact();
            }
        }.bind(this));

        this.isUserActivated = this.currUser.isActivated();

        if (this.showReactEmailViewer) {
            this.actionsForReact = {
                updateAfterDelete: this.updateDeleteItemForExport.bind(this),
                udpateAfterRename: this.udpateAfterRenameForExport.bind(this),
                updateAfterDeleteFlow: this.udpateAfterDeleteFlowForExport.bind(this)
            };

            this.initItemForReact();
        }

        if (this.isAIMeetingNoteFeed) {
            this.initNoteFeedItem();
        }


        if (this.isContactEnrichmentItem) {
            this.initEnrichmentFeedItem();
        }
    }

    var FeedItemDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedItemDirectiveControllerFunc,

        initItemForReact: function initItemForReact() {
            if (this.hasSender) {
                this.itemForReact = this.item;

                this.itemForReact.flow = this.flow;
                this.itemForReact.sender = this.senderUser;
                this.itemForReact.workspace = {
                    event: {
                        event_name: this.workspace.event && this.workspace.event.event_name,
                        _id: this.workspace.event && this.workspace.event._id
                    },
                    is_archived: this.workspace.is_archived,
                    company_id: this.workspace.company_id,
                    _id: this.workspace._id
                };
            }
        },

        initEnrichmentFeedItem: function initEnrichmentFeedItem() {
            this.itemForReact = this.item;
            const members = this.workspace.members || [];
            this.contactForReact = members.find(
                (function (member) {
                    return this.item.data.contact_id === member.contact_id;
                }).bind(this)
            );
            // This is a hack to prevent deployment deps, will be removed after deploying the react branch
            this.itemForReact.contact = this.contactForReact;
        },

        initNoteFeedItem: function initNoteFeedItem() {
            this.noteFeedItem = {};
            this.noteFeedItem.meetingNote = {
                meeting_name: this.data.meeting_name,
                meeting_start_date: this.data.meeting_start_date,
                meeting_end_date: this.data.meeting_end_date,
                meeting_note_id: this.data.meeting_note_id,
                calendar_item_id: this.data.calendar_item_id,
                feed_creation_date: this.item.origin_date,
                project_date: this.data.project_date,
                project_name: this.data.project_name,
                workspace_id: this.workspace._id,
                type: this.item.type
            };
            if (this.item.type === 'ai_project_recap_generated') {
                this.noteFeedItem.project_date = this.data.project_date;
                this.noteFeedItem.project_name = this.data.project_name;
            } else if (this.item.type === 'ai_meeting_recap_generated' || this.item.type === 'ai_meeting_note_generated') {
                this.noteFeedItem.meeting_start_date = this.data.meeting_start_date;
                this.noteFeedItem.meeting_end_date = this.data.meeting_end_date;
                this.noteFeedItem.meeting_name = this.data.meeting_name;
            }

        },

        commentKeyPress: function commentKeyPress(event) {
            if ((event.keyCode === 13 || event.which === 13) && !event.shiftKey) {
                event.preventDefault();
            }
        },

        udpateAfterRenameForExport: function udpateAfterRenameForExport(newTitle) {
            this.flow.title = newTitle;
            this.initItemForReact();
            this.$rootScope.$broadcast('feedItemFlowUpdated');
        },

        udpateAfterDeleteFlowForExport: function udpateAfterDeleteFlowForExport(id) {
            const flowIndex = this._.findIndex(this.workspace.flows, function(flow) { return flow._id === id;});
            if (flowIndex > -1) {
                this.workspace.flows.splice(flowIndex, 1);
            }

            this.flow = null;
            this.updateFlowsInWorkspace();

            this.initItemForReact();
            this.$rootScope.$broadcast('feedItemFlowUpdated');
        },

        commentKeyUp: function commentKeyUp(event, comment) {
            if (event.keyCode == 13 ) { //ENTER was pressed
                if (event.shiftKey) {
                    this.AnalyticsService.track(this, "press shift enter in comment", this.analyticsProperties());
                    return;
                } else {
                    if (comment) { //editing existing comment
                        this.AnalyticsService.track(this, "press enter: edit comment", this.analyticsProperties());
                        this.updateComment(comment);
                    }
                    else {
                        this.AnalyticsService.track(this, "press enter: send comment", this.analyticsProperties());
                        this.submitNewComment();
                    }
                }
            }
            if (event.keyCode == 27)  { //ESC was pressed
                if (comment) {
                    comment.body = comment._body_backup;
                    comment.allowEdit = false;
                }
            }
        },

        commentClick: function commentClick(event, comment) {
            if (comment) { //editing existing comment
                this.updateComment(comment);
            }
            else {
                this.submitNewComment();
            }

        },

        collapseEditMode: function collapseEditMode(comment) {
            comment.allowEdit = false;
        },

        toggleCommentActions: function toggleCommentActions(comment) {
            comment.showCommentActions = !comment.showCommentActions;
        },

        submitNewComment: function submitNewComment() {
            if (!/^\s*$/.test(this.newComment)) { //comment not empty
                this.submittingComment = true;
                this.itemCallbacks.submitComment(this.item, this.newComment).then(
                    function success() {
                        this.newComment = "";
                        this.submittingComment = false;
                    }.bind(this),
                    function error() {
                        this.submittingComment = false;
                    }.bind(this)
                );
            }
        },

        deleteComment: function deleteComment(comment) {
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                'COMMON_FEED_COMPONENTS.COMMENTS._CONFIRM_DELETE_MESSAGE_',
                function() {
                    this.itemCallbacks.deleteComment(this.item, comment).then(
                        function success() {
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.delete_feed_item_comment, this.analyticsProperties());
                        }.bind(this),
                        function error() {
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.delete_feed_item_comment, this.analyticsProperties());
                        }.bind(this)
                    )
                }.bind(this));
        },

        allowEditComment: function allowEditComment(comment) {
            comment.showCommentActions = false;
            comment.allowEdit = true;
            comment._body_backup = comment.body;
        },

        updateComment: function updateComment(comment) {
            comment.allowEdit = false;
            if (/^\s*$/.test(comment.body)) { //comment is empty
                comment.body = comment._body_backup;
                this.deleteComment(comment);
            } else {
                if (comment.body === comment._body_backup) {
                    return;
                }
                this.itemCallbacks.updateComment(this.item, comment).then(
                    function success(resp) {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.update_feed_item_comment, this.analyticsProperties());
                    }.bind(this),
                    function error() {
                        comment.body = comment._body_backup;
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.update_feed_item_comment, this.analyticsProperties());
                    }.bind(this)
                )
            }
        },

        toggleAllComments: function toggleAllComments(showComments) {
            if (typeof showComments === 'boolean') {
                this.showAllComments = showComments;
            } else {
                this.showAllComments = !this.showAllComments;
            }
            if (this.showAllComments) {
                this.commentsLimit = 'Infinity'; //no limit
                this.showCommentsLabel = "COMMON_FEED_COMPONENTS.COMMENTS._HIDE_COMMENTS_";
            } else {
                this.commentsLimit = -1;
                this.showCommentsLabel = "COMMON_FEED_COMPONENTS.COMMENTS._SHOW_COMMENTS_";
            }
        },

        analyticsProperties: function analyticsProperties() {
            var ret = {
                item_id: this.item._id,
                item_type: this.item.type
            };
            if (this.senderUser) {
                ret.item_creator_id = this.senderUser._id;
            }
            return ret;
        },

        shouldShowCopy: function shouldShowCopy() {
            return this.currUser.isAdminLogin();
        },

        shouldShowRestoreFlow: function shouldShowRestoreFlow() {
          return this.data.action_type === "flow_deleted";
        },

        restoreFlow: function restoreFlow() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to restore this feed smart file?').then(function yes() {
                this.item.restoreFlow()
                    .then(function success() {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, 'Flow was restored successfully');
                    }.bind(this))
                    .catch(function fail(e) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, e.data.error_message);
                }.bind(this));
            }.bind(this));

        },

        onClickDelete: function onClickDelete() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to delete this feed message?').then(function yes() {
                this.item.deleteItem().catch(function fail(e) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, e.data.error_message);
                }.bind(this));
            }.bind(this));
        },

        copyToClipBoard: function  copyToClipBoard(){
            navigator.clipboard.writeText(this.item._id.toString());
        },

        deleteScheduledMessage: function deleteScheduledMessage() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to cancel this scheduled email?').then(function yes() {
                    this.item.deleteItem().then(function success() {
                        this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.remove_scheduled_send, {
                            app_path: 'feed_message_header'
                        });
                        this.updateAfterDelete({connectedObjId: this.item.data._id});
                    }.bind(this)).catch(function fail(e) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, e.data.error_message);
                }.bind(this));
            }.bind(this));
        },

        updateDeleteItemForExport: function updateDeleteItemForExport(id) {
            this.updateAfterDelete({connectedObjId: id});
        },

        isActivity: function isActivity() {
            return this.item.type_cd === this.ItemTypes.activity;
        },

        isFlow: function isFlow() {
            return this.data && this.data.object_type === 'flow';
        },

        isSubObject: function isSubObject(type) {
            if (type === undefined && this.data && !this.data.sub_object_type) {
                return true;
            }
            return this.data && this.data.sub_object_type === type ;
        },

        isSessionFeedItem: function isSessionFeedItem() {
            var sessionTypes = ['meeting_scheduled', 'meeting_canceled', 'meeting_updated'];
            return sessionTypes.indexOf(this.item.data.action_type) !== -1;
        },

        isGiftCardFeedItem: function isGiftCardFeedItem() {
            var giftCardTypes = [this.Enums.giftCardStates.promotionPurchased];
            return giftCardTypes.indexOf(this.item.data.action_type) !== -1;
        },

        isFeedItemHidden: function isFeedItemHidden() {
            return this.item.data.action_type === this.Enums.flowStates.deleted && this.isClient;
        },

        shouldDisplayAutomationIndication: function shouldDisplayAutomationIndication() {
            return !this.isClient && (this.item.isPreferencesAutomationsGenerated() || this.item.isWorkflowGenerated());
        },
        shouldDisplayOOOReplyIndication: function shouldDisplayOOOReplyIndication() {
            return this.senderIsMe && this.item.isOOOReply();
        },

        initSender: function initSender() {
            if (this.item.data && this.item.data.sender_user_id) {
                this.senderIsMe = (this.currUserId ===  this.item.data.sender_user_id);
                this.senderUser = this.feedUsers[this.item.data.sender_user_id];

                if (!this.senderUser) {
                    this.senderUser = this.deletedUsers[this.item.data.sender_user_id];
                    
                    if (!this.senderUser) {
                        console.log("Missing user for avatar: " + this.item.data.sender_user_id);
                    }
                }
                this.sentDate = this.item.data.sent_on;
                this.fileSubject = this.item.data.subject;
            }
        },

        isFeedItemWithDeliverability: function isFeedItemWithDeliverability() {
            return this.item.data.object_type ===  "calendar_item" && this.item.data.action_type === "reminder_sent";
        },

        shouldShowContactEnrichmentFeedItem: function isContactEnrichment() {
            return this.isContactEnrichmentEnabled && this.isContactEnrichmentItem && !this.isClient;
        }
    });

    return {
        scope:{
            item: '=item',
            workspace: '<?',
            eventId: '@eventId',
            feedUsers: '=feedUsers',
            deletedUsers: '=deletedUsers',
            currUserId: '@currUserId',
            itemCallbacks: '=itemCallbacks',
            allowComments: '@allowComments',
            feedOwnerCompanyId: '=?',
            updateFlowsInWorkspace: '&',
            updateAfterDelete: '&',
            showReactEmailViewer: '='
        },
        templateUrl : 'angular/app/modules/core/features/feed/feed_item_template.html',
        controller : FeedItemDirectiveController,
        controllerAs : 'feedItemVm',
        bindToController : true
    };
};