// @ngInject
Models.TemplatesCollection = function TemplatesCollection(BaseCollection) {

    return Class(BaseCollection, {
            constructor: function constructor() {
                this.constructor.$super.call(this);
                this._collection.__className = 'TemplatesCollection';
                this._collection.__childsType = 'TemplateModel';
                this._collection.shouldMergeOnLoad = true;
                return this._collection;
            }
        }
    );
};