(function () {
    "use strict";

    Controllers.YearInReviewController = class YearInReviewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, NavigationService, AnalyticsService, moment, PendingTasksManager, AppStates) {

            super($scope, $injector);
            this.__objectType = 'YearInReviewController';
            this.data = {};
            this.NavigationService = NavigationService;
            this.AnalyticsService = AnalyticsService;
            this.PendingTasksManager = PendingTasksManager;
            this.AppStates = AppStates;

            this.curr_user = UsersManager.getCurrUser();
            this.AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.year_in_review_loaded);
            this.companyIconImage = this.curr_user.company.icon_image;
            this.userImageUrl = this.curr_user.profile_image ? this.curr_user.profile_image.url : "";

            let pendingTaskPromise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.yearlyReview, {});
            pendingTaskPromise.then(res => this.yearlyReviewDataRecieved(res));
        }

        yearlyReviewDataRecieved(data) {
            this.data = data;
            this.SplitTime(data.summary.quickest_booked_project_time);
        }

        SplitTime(numberOfHours){
            this.days=Math.floor(numberOfHours/24);
            let Remainder=numberOfHours % 24;
            this.hours=Math.floor(Remainder);
            this.minutes=Math.floor(60*(Remainder-this.hours));
        }

        close() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.year_in_review_closed);
            this.NavigationService.goHistoryBack();
        }
    };
}());
