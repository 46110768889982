(function () {
    'use strict';
    Controllers.EmailSendErrorModalController = class EmailSendErrorModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, $state, _, members, failed, succeeded, isScheduledEmail, moment,
                    PopupMessageService, AnalyticsService, ModalService, DeviceService, $q, AppStates, UserService, $window, ContactsManager, UsersManager) {
            super($scope, $injector);
            this.__objectType = 'SendEmailErrorModalController';
            this.modalInstance = $modalInstance;
            this.translate = $translate;
            this._ = _;
            this.moment = moment;

            this.AppStates = AppStates;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.UserService = UserService;
            this.$q = $q;
            this.$state = $state;
            this.$window = $window;
            this.ContactsManager = ContactsManager;
            this.UsersManager = UsersManager;

            this.ANALYTICS_EVENT_SOURCE = this.AnalyticsService.analytics_events.email_send_error;

            // modal params
            this.members = members;
            this.failed = failed;
            this.succeeded = succeeded;
            this.isScheduledEmail = isScheduledEmail;
            this.allEmailsUpdated = false;

            this.numValidEmails = succeeded.length;
            this.numInvalidEmails = failed.length;

            this.isMobile = DeviceService.nxSmallBreakpoint();

            this.AnalyticsService.trackLoad(this, this.ANALYTICS_EVENT_SOURCE);
        }

        buttonLabel() {
            let send_or_schedule = this.isScheduledEmail ? 'SCHEDULE' : 'SEND';
            let is_send_anyway = !this.isSendDisabled() && !this.allEmailsUpdated;
            let btn_label;

            if (this.isScheduledEmail) {
                if (is_send_anyway) {
                    btn_label = '_SCHEDULE_ANYWAY_';
                } else {
                    btn_label = '_SCHEDULE_';
                }
            } else {
                if (is_send_anyway) {
                    btn_label = '_SEND_ANYWAY_';
                } else {
                    btn_label = '_SEND_';
                }
            }
            return `COMMON_FEED_COMPONENTS.EMAIL_SEND_ERROR.MODAL.${btn_label}`;
        }

        dismiss() {
            this.AnalyticsService.trackClick(this, ['close', this.ANALYTICS_EVENT_SOURCE].join(' '), {});
            this.modalInstance.dismiss();
        }

        cancel() {
            this.AnalyticsService.trackClick(this, ['cancel', this.ANALYTICS_EVENT_SOURCE].join(' '), {});
            this.modalInstance.dismiss();
        }

        send() {
            this.AnalyticsService.trackClick(this, ['send', this.ANALYTICS_EVENT_SOURCE].join(' '), {});
            this.modalInstance.close();
        }

        editEmail(failedRecipientData) {
            this.AnalyticsService.trackClick(this, ['edit', this.ANALYTICS_EVENT_SOURCE].join(' '), {});
            this.editContactInProgress = true;


            let user = this.members.find(item => item._id === failedRecipientData.recipient._id);
            // When using this modal from react, each member of the members array is not of the userModel
            if(user.className !== 'UserModel'){
                let currUser = this.UsersManager.getCurrUser();
                this.ContactsManager.getContactByEmail(currUser, user.email).then(function success(res){
                    let user = res && res.data.data[0];
                    let emailBeforeEdit = user.email;
                    this.UserService.editContact(this.ContactsManager.convertToContact(user).user).then(function success(resp) {
                        if (emailBeforeEdit !== user.email) {
                            failedRecipientData.recipient.email = user.email;
                            failedRecipientData.edited = true;
                            this.allEmailsUpdated = this.failed.every(function(item) { return item.edited; });
                        }
                    }.bind(this)).finally(function () {
                        this.editContactInProgress = false;
                    }.bind(this));
                }.bind(this));
            } else {
                let emailBeforeEdit = user.email;
                this.UserService.editContact(user).then(function success(resp) {
                    if (emailBeforeEdit !== user.email) {
                        failedRecipientData.recipient.email = user.email;
                        failedRecipientData.edited = true;
                        this.allEmailsUpdated = this.failed.every(function (item) {
                            return item.edited;
                        });
                    }
                }.bind(this)).finally(function () {
                    this.editContactInProgress = false;

                }.bind(this));
            }
        }

        isSendDisabled() {
            return (this.succeeded.length + this.failed.filter(item => { return item.edited; }).length) === 0;
        }

    };
}());
