(function () {
    "use strict";


    // @ngInject
    function ContactFormManagerCtor($q, APIService, ModelFactory, Routes, _) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this._ = _;
    }

    Services.ContactFormManager = Class(Services.BaseModelManager, {

        constructor: ContactFormManagerCtor,

        isUsingDataKey: function isUsingDataKey(model, data_key) {
            if (model.isModelOf('Group') && model.contact_form && _.find(model.contact_form.questions, function (question) {
                    return question.data_key === data_key;
                })) {

                return true;

            } else {
                return false;
            }
        },

        /*****************************************
         * Dynamic Contact Form Actions
         ******************************************/

        dynamicContactFormUpdateStyle: function dynamicContactFormUpdateStyle(contactForm, styleObjectString) {
            var params = _.clone(contactForm.file_dynamic_content.style_container[styleObjectString]) || {};
            params.style_obj = styleObjectString;

            var url = this.Routes.v2_contact_form_update_style_path(contactForm._id);
            return this.apiUpdate(url, params, contactForm, true);
        },

        contactFormUpdateSettings: function contactFormUpdateSettings(contactForm, params) {
            var url = this.Routes.v2_contact_form_update_settings_path(contactForm._id);
            return this.apiUpdate(url, params, contactForm, true);
        },

        addBlockToContactForm: function addBlockToContactForm(contactForm, section, params) {
            var url = this.Routes.v2_contact_form_create_block_path(contactForm._id, section._id);
            return this.apiCreate(url, params, contactForm, true, null, true);
        },

        updateContactFormBlock: function updateContactFormBlock(contactForm, section, block, changedFields) {
            var url = this.Routes.v2_contact_form_update_block_path(contactForm._id, section._id, block._id);
            var data = block.fieldsOnly(changedFields);
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        deleteContactFormBlock: function deleteContactFormBlock(contactForm, section, block) {
            var url = this.Routes.v2_contact_form_delete_block_path(contactForm._id, section._id, block._id);
            return this.apiDelete(url, null, contactForm, true, null, true);
        },

        updateContactFormSectionBlocksOrder: function updateContactFormSectionBlocksOrder(contactForm, section, blocksOrder) {
            var url = this.Routes.v2_contact_form_update_section_blocks_order_path(contactForm._id, section._id);
            return this.apiUpdate(url, { blocksOrder: blocksOrder }, contactForm, true, undefined, true);
        },

        updateContactFormComponent: function updateContactFormComponent(contactForm, section, block, component, path, changedFields, params) {
            var data = component.fieldsOnly(changedFields, true);
            if (params) {
                data = this._.assign(data, params);
            }
            path = path.reverse().join("/");
            path = "?path=" + path;
            var url = this.Routes.v2_contact_form_update_component_path(contactForm._id, section._id, block._id);
            return this.apiUpdate(url + path, data, contactForm, true, undefined, true);
        },

        resetContactFormToLastPublished: function resetContactFormToLastPublished(contactForm) {
            var url = this.Routes.v2_contact_form_reset_changes_path(contactForm._id);
            return this.apiDelete(url, null, contactForm, true, undefined, true);

        },

        toggleActivation: function toggleActivation(contactForm, activationStatus) {
            var url = this.Routes.v2_contact_form_set_active_status_path(contactForm._id);
            return this.apiUpdate(url, {is_active: activationStatus}, contactForm, true);
        },

        updateContactFormMembers: function updateContactFormMembers(contactForm, inquiryMembers) {
            var url = this.Routes.v2_contact_form_update_inquiry_members_path(contactForm._id);
            return this.apiUpdate(url, { inquiry_members: inquiryMembers}, contactForm, true);
        },

        addPackage: function addPackage(contactForm, section, block, packageId) {
            var url = this.Routes.v2_contact_form_add_package_path(contactForm._id, section._id, block._id);
            return this.apiCreate(url, { package_id: packageId}, contactForm, true, null, true);
        },

        updatePackage: function updatePackage(contactForm, section, block, packageModel) {
            var url = this.Routes.v2_contact_form_update_package_path(contactForm._id, section._id, block._id, packageModel._id);
            var data = packageModel.dataOnly();
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        deletePackage: function deletePackage(contactForm, section, block, packageModel) {
            var url = this.Routes.v2_contact_form_delete_package_path(contactForm._id, section._id, block._id, packageModel._id);
            return this.apiDelete(url, null, contactForm, true, null, true);
        },

        updatePackageServicesOrder: function updatePackageServicesOrder(contactForm, section, block, packageModel, order) {
            var url = this.Routes.v2_contact_form_update_package_services_order_path(contactForm._id, section._id, block._id, packageModel._id);
            return this.apiUpdate(url, {servicesOrder: order}, contactForm, true, null, true);
        },

        addService: function addService(contactForm, section, block, packageModel) {
            var url = this.Routes.v2_contact_form_add_service_path(contactForm._id, section._id, block._id);
            return this.apiCreate(url, null, contactForm, true, null, true);
        },

        updateService: function updateService(contactForm, section, block, service, packageModel) {
            var url = this.Routes.v2_contact_form_update_service_path(contactForm._id, section._id, block._id, service._id);
            var data = service.dataOnly();
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        updateServiceImage: function updateServiceImage(contactForm, section, block, service, image, packageModel) {
            var url = this.Routes.v2_contact_form_update_service_path(contactForm._id, section._id, block._id, service._id);
            var data = {
                image: image
            };
            if(!data.image && data.image !== '') {
                data.image = '';
            }
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        deleteService: function deleteService(contactForm, section, block, service, packageModel) {
            var url = this.Routes.v2_contact_form_delete_service_path(contactForm._id, section._id, block._id, service._id);
            var data = {};
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiDelete(url, data, contactForm, true, null, true);
        },

        updateProposalOrder: function updateProposalOrder(contactForm, section, block, order) {
            var url = this.Routes.v2_contact_form_update_proposal_packages_and_services_order_path(contactForm._id, section._id, block._id);
            return this.apiUpdate(url, {order: order}, contactForm, true);
        },

        moveService: function moveService(contactForm, section, block, serviceId, sourcePackageId, destPackageId, order) {
            var url = this.Routes.v2_contact_form_move_service_path(contactForm._id, section._id, block._id, serviceId);
            var data = {
                source_package_id: sourcePackageId,
                dest_package_id: destPackageId,
                dest_order: order
            };
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        updateProposal: function updateProposal(contactForm, section, block, proposalData) {
            var url = this.Routes.v2_contact_form_update_proposal_path(contactForm._id, section._id, block._id);
            var data = proposalData;
            return this.apiUpdate(url, data, contactForm, true, null, true);
        },

        setContactFormIsInstantPay: function setContactFormIsInstantPay(company, contactForm, isInstantPay) {
            var url = this.Routes.v2_company_update_contact_form_path(company._id, contactForm._id);
            return this.apiUpdate(url, {is_instant_booking_enabled: isInstantPay}, company, true);
        },
    });
}());
