(function () {
    'use strict';

    // @ngInject
    function ClientNavigationControllerCtor($scope, $injector, AnalyticsService, UiPersistenceService,
                                            EventsManager, $state, AppStates, AppConfigService, EventService, _,
                                            $window, ClientPortalService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ClientNavigationController';
        AnalyticsService.trackPageView(this, 'ClientNavigationView');

        if ($window.location.host === 'www.honeybook.com') {
            AnalyticsService.track(this, "Client navigation in www.honeybook.com", {
                referrer: $window.document.referrer,
                config: ClientPortalService.getConfiguration()
            });
        }

        this.UiPersistenceService = UiPersistenceService;
        this.EventsManager = EventsManager;
        this.$state = $state;
        this.AppStates = AppStates;
        this.AppConfigService = AppConfigService;
        this.EventService = EventService;
        this._ = _;
        this.noProjects = false;
        this.navigationInProgress = true;

        this.redirectClient();
    }

    Controllers.ClientNavigationController = Class(Controllers.BaseController, {
        constructor: ClientNavigationControllerCtor,

        redirectClient: function redirectClient() {

            this.EventService.getProjectsForClient().then(function gotEvents(resp) {
                var events = resp.data.data;

                if (!events.length) {
                    this.noProjects = true;
                    return null;
                }

                if (events.length === 1) {
                    return {
                        event: events[0],
                        openEventsListOnLoad: false
                    };
                }

                return this.UiPersistenceService.refetchUiPersistence().then(function gotPersistence() {
                    var event = null;
                    var lastEventVisited = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.last_event_visited, null);
                    if (lastEventVisited) {
                        event = this._.find(events, function(e) { return e._id === lastEventVisited.event_id; });
                        event = event || events[0]; // in case the user isn't in this project anymore
                    }
                    else {
                        event = events[0];
                    }

                    return {
                        event: event,
                        openEventsListOnLoad: this.EventService.currUserHasMultipleProjects()
                    };
                }.bind(this));

            }.bind(this))
            .then(function navigate(data) {
                if (data) {
                    var stateToGoTo = this.EventService.getEventDefaultState(data.event, true);
                    this.goToState(stateToGoTo.name, angular.extend(stateToGoTo.params, {openEventsListOnLoad: data.openEventsListOnLoad}));
                }
                this.navigationInProgress = false;
            }.bind(this));
        }
    });
}());
