/**
 * Created by dvircohen on 08/05/2016.
 */

// @ngInject
Models.BrochureALaCarteProposalModel = function BrochureALaCarteProposalModel(BaseModel, $translate){
    
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'BrochureALaCarteProposalModel';
            this.cachable = false;

            $translate('PROPOSAL.NEW_SERVICE._LABEL_').then(function translateServiceConstant(text) {
                this.NEW_SERVICE_NAME = text;
            }.bind(this));
        },

        afterMixingFrom: function afterMixingFrom() {
            if (this.package_services && this.package_services.length > 0) {
                this.package_services.forEach(function (service) {
                    service.__a_la_carte_proposal = this;
                }.bind(this));
            }
        },

        findServiceModel: function findService(serviceId) {
            if (this.package_services && this.package_services.length > 0) {
                for (var i = 0, length = this.package_services.length; i < length; i++) {
                    if (this.package_services[i]._id === serviceId) {
                        return this.package_services[i];
                    }
                }
            }

            return null;
        },

        mapChilds: function mapChilds(){
            return {
                package_services: 'ServicesCollection'
            };
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(image) {
            return this.__parent.updateBrochureALaCarteImage(image);
        },

        addService: function addService() {
            return this.__parent.addServiceToBrochureALaCarte({name: this.NEW_ITEM_NAME, price: 0});
        },
        
        deleteService: function deleteService(serviceModel) {
            return this.__parent.deleteServiceFromBrochureALaCarte(serviceModel);
        },

        updateService: function updateService(serviceModel) {
            return this.__parent.updateServiceOnBrochureALaCarte(serviceModel);
        },

        updateServiceSelection: function updateServiceSelection(serviceModel, isSelected) {
            return this.__parent.updateServiceSelection(serviceModel, isSelected);  
        },
        
        updateServiceImage: function updateServiceImage(serviceModel, image) {
            return this.__parent.updateImageInALaCarteService(serviceModel, image);
        },

        deleteServiceImage: function deleteServiceImage(serviceModel) {
            return this.__parent.deleteImageFromALaCarteService(serviceModel);
        },
        
        updateServicesOrder: function updateServiceOrder() {
            return this.__parent.updateServicesOrderInALaCarte(this);  
        }
    });

};


