(function () {
    'use strict';

    Services.ColorsService = class ColorsService {

        // @ngInject
        constructor() {

            this.textColors = [
                {
                    model: '#000000',
                    title: 'Black',
                    color: '#000000',
                },
                {
                    model: '#131416',
                    title: 'Dark Grey 4',
                    color: '#131416',
                },
                {
                    model: '#666666',
                    title: 'Dark Grey 3',
                    color: '#666666',
                },
                {
                    model: '#999999',
                    title: 'Dark Grey 2',
                    color: '#999999',
                },
                {
                    model: '#B7B7B7',
                    title: 'Dark Grey 1',
                    color: '#B7B7B7',
                },
                {
                    model: '#CCCCCC',
                    title: 'Grey',
                    color: '#CCCCCC',
                },
                {
                    model: '#D9D9D8',
                    title: 'Light Grey 3',
                    color: '#D9D9D8',
                },
                {
                    model: '#EFEFEE',
                    title: 'Light Grey 2',
                    color: '#EFEFEE',
                },
                {
                    model: '#F3F3F3',
                    title: 'Light Grey 1',
                    color: '#F3F3F3',
                },
                {
                    model: '#FFFFFF',
                    title: 'White',
                    color: '#FFFFFF',
                },
                {
                    model: '#980202',
                    title: 'Red Berry',
                    color: '#980202',
                },
                {
                    model: '#FF0402',
                    title: 'Red',
                    color: '#FF0402',
                },
                {
                    model: '#FF9902',
                    title: 'Orange',
                    color: '#FF9902',
                },
                {
                    model: '#FFFF03',
                    title: 'Yellow',
                    color: '#FFFF03',
                },
                {
                    model: '#02FF02',
                    title: 'Green',
                    color: '#02FF02',
                },
                {
                    model: '#01FFFF',
                    title: 'Cyan',
                    color: '#01FFFF',
                },
                {
                    model: '#4B86E7',
                    title: 'Cornflower Blue',
                    color: '#4B86E7',
                },
                {
                    model: '#0602FF',
                    title: 'Blue',
                    color: '#0602FF',
                },
                {
                    model: '#9902FF',
                    title: 'Purple',
                    color: '#9902FF',
                },
                {
                    model: '#FF02FF',
                    title: 'Magenta',
                    color: '#FF02FF',
                },
                {
                    model: '#E6B8AE',
                    title: 'Light Red Berry 3',
                    color: '#E6B8AE',
                },
                {
                    model: '#F4CCCC',
                    title: 'Light Red 3',
                    color: '#F4CCCC',
                },
                {
                    model: '#FCE5CD',
                    title: 'Light Orange 3',
                    color: '#FCE5CD',
                },
                {
                    model: '#FFF2CC',
                    title: 'Light Yellow 3',
                    color: '#FFF2CC',
                },
                {
                    model: '#D9EAD3',
                    title: 'Light Green 3',
                    color: '#D9EAD3',
                },
                {
                    model: '#CFE0E3',
                    title: 'Light Cyan 3',
                    color: '#CFE0E3',
                },
                {
                    model: '#99ccff',
                    title: 'Light Cornflower Blue 3',
                    color: '#99ccff',
                },
                {
                    model: '#C9DAF8',
                    title: 'Light Blue 3',
                    color: '#C9DAF8',
                },
                {
                    model: '#D8D2E9',
                    title: 'Light Purple 3',
                    color: '#D8D2E9',
                },
                {
                    model: '#EAD0DC',
                    title: 'Light Magenta 3',
                    color: '#EAD0DC',
                },
                {
                    model: '#DD7E6B',
                    title: 'Light Red Berry 2',
                    color: '#DD7E6B',
                },
                {
                    model: '#EA9999',
                    title: 'Light Red 2',
                    color: '#EA9999',
                },
                {
                    model: '#F9CB9C',
                    title: 'Light Orange 2',
                    color: '#F9CB9C',
                },
                {
                    model: '#FFE599',
                    title: 'Light Yellow 2',
                    color: '#FFE599',
                },
                {
                    model: '#B6D7A8',
                    title: 'Light Green 2',
                    color: '#B6D7A8',
                },
                {
                    model: '#A2C4C9',
                    title: 'Light Cyan 2',
                    color: '#A2C4C9',
                },
                {
                    model: '#A4C2F4',
                    title: 'Light Cornflower Blue 2',
                    color: '#A4C2F4',
                },
                {
                    model: '#9FC5E7',
                    title: 'Light Blue 2',
                    color: '#9FC5E7',
                },
                {
                    model: '#B4A7D6',
                    title: 'Light Purple 2',
                    color: '#B4A7D6',
                },
                {
                    model: '#D5A6BD',
                    title: 'Light Magenta 2',
                    color: '#D5A6BD',
                },
                {
                    model: '#CC4124',
                    title: 'Light Red Berry 1',
                    color: '#CC4124',
                },
                {
                    model: '#DF6666',
                    title: 'Light Red 1',
                    color: '#DF6666',
                },
                {
                    model: '#F6B26B',
                    title: 'Light Orange 1',
                    color: '#F6B26B',
                },
                {
                    model: '#FFD966',
                    title: 'Light Yellow 1',
                    color: '#FFD966',
                },
                {
                    model: '#93C47D',
                    title: 'Light Green 1',
                    color: '#93C47D',
                },
                {
                    model: '#76A5AE',
                    title: 'Light Cyan 1',
                    color: '#76A5AE',
                },
                {
                    model: '#6D9EEB',
                    title: 'Light Cornflower Blue 1',
                    color: '#6D9EEB',
                },
                {
                    model: '#6FA8DC',
                    title: 'Light Blue 1',
                    color: '#6FA8DC',
                },
                {
                    model: '#8E7CC3',
                    title: 'Light Purple 1',
                    color: '#8E7CC3',
                },
                {
                    model: '#C27BA0',
                    title: 'Dark Magenta 1',
                    color: '#C27BA0',
                },
                {
                    model: '#A61B02',
                    title: 'Dark Red Berry 1',
                    color: '#A61B02',
                },
                {
                    model: '#CC0202',
                    title: 'Dark Red 1',
                    color: '#CC0202',
                },
                {
                    model: '#E69138',
                    title: 'Dark Orange 1',
                    color: '#E69138',
                },
                {
                    model: '#F1C232',
                    title: 'Dark Yellow 1',
                    color: '#F1C232',
                },
                {
                    model: '#6AA84F',
                    title: 'Dark Green 1',
                    color: '#6AA84F',
                },
                {
                    model: '#44818E',
                    title: 'Dark Cyan 1',
                    color: '#44818E',
                },
                {
                    model: '#3C78D8',
                    title: 'Dark Cornflower Blue 1',
                    color: '#3C78D8',
                },
                {
                    model: '#3D85C5',
                    title: 'Dark Blue 1',
                    color: '#3D85C5',
                },
                {
                    model: '#674EA7',
                    title: 'Dark Purple 1',
                    color: '#674EA7',
                },
                {
                    model: '#A64E79',
                    title: 'Dark Magenta 5',
                    color: '#A64E79',
                },
                {
                    model: '#85200D',
                    title: 'Dark Red Berry 2',
                    color: '#85200D',
                },
                {
                    model: '#990202',
                    title: 'Dark Red 2',
                    color: '#990202',
                },
                {
                    model: '#B45F08',
                    title: 'Dark Orange 2',
                    color: '#B45F08',
                },
                {
                    model: '#FFDAB9',
                    title: 'Peach Puff',
                    color: '#FFDAB9',
                },
                {
                    model: '#38761D',
                    title: 'Dark Green 2',
                    color: '#38761D',
                },
                {
                    model: '#12505C',
                    title: 'Dark Cyan 2',
                    color: '#12505C',
                },
                {
                    model: '#1256CC',
                    title: 'Dark Cornflower Blue 2',
                    color: '#1256CC',
                },
                {
                    model: '#0C5294',
                    title: 'Dark Blue 2',
                    color: '#0C5294',
                },
                {
                    model: '#341B75',
                    title: 'Dark Purple 2',
                    color: '#341B75',
                },
                {
                    model: '#741B47',
                    title: 'Dark Magenta 2',
                    color: '#741B47',
                },
                {
                    model: '#5B1001',
                    title: 'Dark Red Berry 3',
                    color: '#5B1001',
                },
                {
                    model: '#660101',
                    title: 'Dark Red 3',
                    color: '#660101',
                },
                {
                    model: '#783F07',
                    title: 'Dark Orange 3',
                    color: '#783F07',
                },
                {
                    model: '#7F6002',
                    title: 'Dark Yellow 3',
                    color: '#7F6002',
                },
                {
                    model: '#274F14',
                    title: 'Dark Green 3',
                    color: '#274F14',
                },
                {
                    model: '#0B343D',
                    title: 'Dark Cyan 3',
                    color: '#0B343D',
                },
                {
                    model: '#1C4587',
                    title: 'Dark Cornflower Blue 3',
                    color: '#1C4587',
                },
                {
                    model: '#0A3763',
                    title: 'Dark Blue 3',
                    color: '#0A3763',
                },
                {
                    model: '#20124D',
                    title: 'Dark Purple 3',
                    color: '#20124D',
                },
                {
                    model: '#4C1130',
                    title: 'Dark Magenta 3',
                    color: '#4C1130',
                },
            ];

        }

    };

}());

