(function () {
    'use strict';

    class ReschedulePaymentsContainerComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, CompaniesManager, RescheduleProjectService) {
            super($scope, $injector);
            this.__objectType = 'ReschedulePaymentsContainerComponentController';
            this.CompaniesManager = CompaniesManager;
            this.RescheduleProjectService = RescheduleProjectService;
        }

        $onInit() {
            this.selected = this.RescheduleProjectService.wasFileSelected(this.file._id) ? this.RescheduleProjectService.isFileSelected(this.file._id) : true;
            this.onFileSelectionToggle();
            this.company = this.CompaniesManager.getCurrCompany();
        }

        onRevertChangesClick() {
            if (this.revertingChanges) {
                return;
            }
            this.revertingChanges = true;
            this.RescheduleProjectService.makeFileEditable(this.file).finally(() => {
                this.revertingChanges = false;
            });
        }

        onFileSelectionToggle() {
            if (this.selected) {
               this.RescheduleProjectService.addSelectedFile(this.file._id);
            } else {
                this.RescheduleProjectService.removeSelectedFile(this.file._id);
            }
        }
    }

    Components.ReschedulePaymentsContainerComponent = {
        bindings: {
            file: '<',
        },
        controller: ReschedulePaymentsContainerComponentController,
        name: 'hbReschedulePaymentsContainerComponent',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_payments_container_template.html',
    };

}());