Directives.InputPopupDirective = function InputPopupDirective() {

    // @ngInject
    function InputPopupDirectiveControllerFunc($scope, InputFieldsService, $element, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'InputPopupDirectiveController';


        $scope.$watch("inputPopupVm.position", function (newValue) {
            if (newValue) {
                if (newValue.notch) {
                    this.notchStyle = {'top': newValue.notch.y, 'left': newValue.notch.x};
                }
                if (newValue.input) {
                    this.containerStyle = {'top': newValue.input.y, 'left': newValue.input.x};
                }

                if (newValue.notchClass === "notch-down") {
                    this.inputStyle = {'bottom': 0};
                } else if (newValue.notchClass === "notch-up") {
                    this.inputStyle = {'top': 0};
                }

                this.loadFieldConfig(this.externalFieldName);
            }
        }.bind(this));

        this.categories = InputFieldsService.getCategories();
        this.categoryFields = [];

        this.showFieldsSelection = false;
        this.showSubInputFieldSelector = false;
        this.showMainInputFieldSelector = false;

        this.isOtherField = false;
        this.isClientField = false;
        this.isRequiredField = true;

        this.categorySelected = function categorySelected(newCategory, e) {
            this.selectedFieldValue = null;
            // return if same category is selected
            if (this.category === newCategory) {
                return;
            }

            this.category = newCategory;
            if (this.category.entry_key === InputFieldsService.OTHER_CATEGORY) {
                this.showFieldsSelection = false;
                this.isOtherField = true;
                this.selectedField = InputFieldsService.getOtherCategoryField();
                this.selectedField.setFieldPlaceholder('');
                this.selectedFieldPlaceholder = this.selectedField.getPlaceholder();
            } else {
                this.showFieldsSelection = true;
                this.isOtherField = false;
                this.selectedField = null;
            }

            this.setCategoryFields();

            //close the category menu and open the other menu
            this.toggleMainInputFieldSelector();
            //eat the click since we don't use the dropdown directive anymore
            e.stopPropagation();
        };

        this.setCategoryFields = function setCategoryFields() {

            // Reset array with new selected fields
            this.categoryFields.splice.apply(this.categoryFields, [0, Infinity].concat(InputFieldsService.getCategoryFields(this.category)));
        };

        this.fieldSelected = function fieldSelected(selectedField, e) {
            this.selectedField = InputFieldsService.getField(selectedField.entry_key);
            this.selectedFieldValue = this.selectedField.getDefaultValue(this.agreementValuesStrategy);
            this.selectedFieldPlaceholder = this.selectedField.getPlaceholder();
            this.isClientField = this.selectedField.isClientField();
            this.isRequiredField = this.selectedField.isRequiredField();


            //close the dropdown menu
            this.showSubInputFieldSelector = false;
            //eat the click since dropdown menu doesn't do this for us
            if(e){
                e.stopPropagation();
            }
        };

        this.loadFieldConfig = function loadFieldConfig(cfg) {
            this.selectedField = InputFieldsService.getField(cfg);
            this.isClientField = this.selectedField.isClientField();
            this.selectedFieldPlaceholder = this.selectedField.getPlaceholder();

            if (this.selectedField.isRequiredField() === undefined) {
                this.selectedField.setRequiredField(true);
            }
            this.isRequiredField = this.selectedField.isRequiredField();

            if (this.selectedField.isRealField()) {
                this.category = this.selectedField.getCategory();
                this.isOtherField = this.selectedField.isOtherField();
                this.selectedFieldValue = this.externalFieldValue === null ? this.selectedField.getDefaultValue(this.agreementValuesStrategy) : this.externalFieldValue;

                this.setCategoryFields();
            } else {
                this.selectedField = null;
                this.category = null;
                this.isOtherField = false;
                this.selectedFieldValue = null;
            }

            this.showFieldsSelection = !!this.category && !this.isOtherField;

            //determine if we automatically show the dropdown
            this.showMainInputFieldSelector = !this.category ? true : false;

            // We're not using $timeout to avoid an unnecessary digest cycle
            setTimeout(function() {
                var inputToFocus = $element.find("#selectedFieldValue");
                inputToFocus[0].focus();
            }, 250);

        };

        this.toggleClientField = function toggleClientField() {
            this.isClientField = !this.isClientField;
            this.selectedField.setClientField(this.isClientField);
        };

        this.toggleRequiredField = function toggleRequiredField() {
            this.isRequiredField = !this.isRequiredField;
            this.selectedField.setRequiredField(this.isRequiredField);
        };

        this.toggleMainInputFieldSelector = function toggleMainInputFieldSelector(){
            this.showMainInputFieldSelector = !this.showMainInputFieldSelector;

            //if its other field, don't auto open the sub input field menu. 
            if(!this.isOtherField){
                this.showSubInputFieldSelector = !this.showMainInputFieldSelector;
            }
            return true;
        };

        this.toggleSubInputFieldSelector = function toggleSubInputFieldSelector(){
            this.showSubInputFieldSelector = !this.showSubInputFieldSelector;
            return true;
        };

        this.handleKeyPress = function handleKeyPress(evt) {
            if (evt.keyCode === 13) {
                this.saveChanges();
            }
        };

        this.cancel = function cancel() {
            this.show = false;
        };

        this.saveChanges = function saveChanges() {
            this.externalFieldValue = this.selectedFieldValue;
            if(this.selectedFieldValue === null){
                //HON-4013 - Agreement Field does not show Value when switching to Other
                this.externalFieldValue = "";
            }
            this.selectedField.setFieldChanged(this.externalFieldValue != this.selectedField.getDefaultValue(this.agreementValuesStrategy));
            this.selectedField.setFieldPlaceholder(this.selectedFieldPlaceholder);
            this.externalFieldName = this.selectedField.toString();
            this.show = false;
            if (this.onAdd !== undefined) {
                this.onAdd({outputFieldName: this.externalFieldName, outputFieldValue:this.externalFieldValue});
            }
        };
    }

    var InputPopupDirectiveController = Class(Controllers.BaseController, {
        constructor: InputPopupDirectiveControllerFunc
    });

    return {
        scope: {
            fileModel: '=fileModel',
            show: '=show',
            position: '=position',
            externalFieldName: '=selectedFieldName',
            externalFieldValue: '=selectedFieldValue',
            agreementValuesStrategy: '=',
            onAdd: '&?onAdd'
        },
        restrict: 'E',
        templateUrl: 'angular/app/modules/core/features/agreement/editable_agreement/input_popup/input_popup_directive_template.html',
        controller: InputPopupDirectiveController,
        controllerAs: 'inputPopupVm',
        bindToController: true
    };
};