/**
 * Created by dvircohen on 07/09/2016.
 */
(function () {
    'use strict';

    Directives.GenericFilmstripDirective = function GenericFilmstripDirective() {

        // @ngInject
        function GenericFilmstripDirectiveControllerCtor(
            $scope, $injector, _, $, $q, $timeout, $document,
            Constants, AppConfigService, AnalyticsService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'GenericFilmstripDirectiveController';

            this._ = _;
            this.$ = $;
            this.$q = $q;
            this.Constants = Constants;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$document = $document;
            this.AnalyticsService = AnalyticsService;

            this.appStyleType = AppConfigService.getAppStyleType();
            this.itemsInProgress = {};
            this.draggableItems = [];
            this.filmstripItemsVm = [];

            this.sortableOptions = {

                disabled: !this.isSortable,
                items: 'li:not(.not-sortable)',
                axis: 'y',
                revert: 200,
                opacity: 0.8,
                handle: '.file-step-editable__drag-container',
                helper: 'clone',

                start: function start() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.filmstrip_item_drag_started);
                    this.isSortingDnD = true;
                }.bind(this),

                stop: function stop($event, ui) {
                    var itemsIdsToOrderMap = {};
                    var items = this.draggableItems.slice(1);

                    // We need map the draggable items by id, so we can update the order in the filmstrip items
                    this._.each(items, function(item, index) { itemsIdsToOrderMap[item.id] = index; });
                    this._.each(this.filmstripItemsVm, function(itemVm) { itemVm.order = itemsIdsToOrderMap[itemVm.id]; });

                    this.$scope.$applyAsync(function() {

                        // We must update the items order now, so the ui will update
                        this.filmstripItemsVm = this._sortByOrder(this.filmstripItemsVm);

                        var potentialPromise = this.onSort({sortedItems: this.filmstripItemsVm});
                        var elemId = ui.item.attr('id').replace('item-', '');
                        var offset = parseInt(ui.item.attr('offset'));

                        var dragSuccess = function dragSuccess() {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.filmstrip_item_drag_finished);
                            this._scrollToElement(elemId, -offset).then(function() {
                                this.isSortingDnD = false;
                            }.bind(this));
                        }.bind(this);

                        if (potentialPromise && potentialPromise.then) {
                            potentialPromise.then(function() {
                                dragSuccess();
                            }.bind(this)).catch(function() {
                                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.filmstrip_item_drag_error);
                            }.bind(this));
                        }
                        else {
                            dragSuccess();
                        }
                    }.bind(this));
                }.bind(this),
            };

            // this offset defines the threshold of when to switch the active object in the film-strip.
            // it should be somewhere in the middle of the screen to take the fixed banner at top into
            // consideration. since du-scroll-container was not set the document is the default container
            switch (this.appStyleType) {
                case Constants.AppStyleTypes.small:
                    this.offsetFromTheTopForHotSpot = 300;
                    break;
                default:
                    //all the medium and big ones.
                    this.offsetFromTheTopForHotSpot = 200;
            }

            $scope.$watchCollection('genericFilmstripVm.filmstripItems', function (newItemsCollection, oldItemsCollection) {

                this.fixDefaultEnableToTrueAndScrollOffset(newItemsCollection);

                this.convertItemsToViewModel();

                // If item was added we need to scroll to it
                if (newItemsCollection.length > oldItemsCollection.length && oldItemsCollection.length > 0) {
                    var oldIds = this._.pluck(oldItemsCollection, 'id');
                    var newItem = this._.find(newItemsCollection, function(item) { return !this._.contains(oldIds, item.id); }.bind(this));
                    this._scrollToElement(newItem.domElementId, newItem.scrollToOffset);
                }

            }.bind(this));
        }

        var GenericFilmstripDirectiveController = Class(Controllers.BaseController, {
            constructor: GenericFilmstripDirectiveControllerCtor,

            convertItemsToViewModel: function convertItemsToViewModel() {

                // We need to keep 2 collections if we're dragging
                // draggableItems - to use as the ng-model of the ui-sortable (contains menu li element)
                // filmstripItemsVm - contains only ng-repeated items
                this.draggableItems.splice(0);
                this.filmstripItemsVm.splice(0);

                this._.each(this.filmstripItems, function(item) {

                    // We cant add hidden items to the collections because then we'll have issues with d&d
                    var fn = item.isHidden || this._.identity;
                    if (!fn(false)) {
                        this.draggableItems.push(this._.clone(item));
                        this.filmstripItemsVm.push(this._.clone(item));
                    }
                }, this);

                this.draggableItems = this._sortByOrder(this.draggableItems);
                this.filmstripItemsVm = this._sortByOrder(this.filmstripItemsVm);

                // Insert the menu to the beginning of the draggable items array
                if (this.onMenuAction) {
                    this.draggableItems.splice(0, 0, {id: 28491});
                }
            },

            fixDefaultEnableToTrueAndScrollOffset: function fixDefaultEnableToTrueAndScrollOffset(itemsArray) {
                if (itemsArray && this._.isArray(itemsArray)) {
                    itemsArray.forEach(function (item, index) {

                        switch (this.appStyleType) {
                            case this.Constants.AppStyleTypes.small:
                                if (index === 0) {
                                    item.scrollToOffset = 15;
                                } else {
                                    // this is an offset to take into account the fixed positioned header height and the fact
                                    // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                    // this is the sum of them. should be changed if the styling is changed.
                                    item.scrollToOffset = 220;
                                }
                                break;
                            default:
                                if (index === 0) {
                                    item.scrollToOffset = 150; // we want the whole photo to show.
                                } else {
                                    // this is an offset to take into account the fixed positioned header height and the fact
                                    // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                    // this is the sum of them. should be changed if the styling is changed.
                                    item.scrollToOffset = 150;
                                }
                                break;
                        }
                    }.bind(this));
                }
            },

            toggleMenu: function toggleMenu($event) {
                $event.stopPropagation();
                this.showFilmstripAddMenu = !this.showFilmstripAddMenu;
                var target = $event.target;
                var rect = target.getBoundingClientRect();
                this.addMenuLeft = rect.right + 10;
                this.addMenuTop = rect.top;
            },

            onMenuItemClick: function onMenuItemClick(menuItem) {

                if (this.itemsInProgress[menuItem]) {
                    return;
                }

                this.itemsInProgress[menuItem] = true;

                var potentialPromise = menuItem.onClick();

                if (potentialPromise.finally) {
                    potentialPromise.finally(function() {
                        this.itemsInProgress[menuItem] = false;
                    }.bind(this));
                }
                else {
                    this.itemsInProgress[menuItem] = false;
                }
            },

            showItemDeleteAction: function showItemDeleteAction(item) {
                return this.onItemDelete && (this.isEditable || this._.noop)() && (item.isDeletable || this._.noop)() !== false;
            },

            _scrollToElement: function _scrollToElement(element, offset) {

                var defer = this.$q.defer();

                // Scroll on timeout so le dom be ready
                this.$timeout(function scrollToDraggedElement() {

                    // Scroll to the element
                    this.$('html, body').animate({
                        scrollTop: this.$('#' + element).offset().top + offset
                    }, 700, function scrollCompleted() {

                        // Scroll another pixel just to refocus the screen on the active element
                        this.$timeout(function refocus() {
                            this.$document.scrollTo(0, this.$('body').scrollTop() + 1);
                        }.bind(this));

                        defer.resolve();
                    }.bind(this));
                }.bind(this), 0);

                return defer.promise;
            },

            _sortByOrder: function (collection) {
                return this._.sortBy(collection, function(e){
                    return e.order ? e.order : 0;
                });
            },
        });

        return {

            restrict: 'E',
            templateUrl: 'angular/app/modules/common/ui_components/filmstrip/generic_filmstrip_directive_template.html',
            controller: GenericFilmstripDirectiveController,
            controllerAs: 'genericFilmstripVm',
            bindToController: true,
            scope: {

                classes: '=?',
                isEditable: '&?',

                isSortable: '=?',
                onSort: '&?',

                /**
                 * [{
                 *  label: String
                 *  isClickable: boolean/function
                 *  onClick: function
                 * },...]
                 */
                onMenuAction: '&?',
                // TODO make it work someday
                // menuItems: '=?',

                /**
                 * [{
                 *  id: String
                 *  label: String
                 *  modelType: String
                 *  order: Integer
                 *  domElementId: String
                 *  isDeletable: function
                 *  isSortable: boolean
                 *  isSelected: boolean
                 *  isActionable: boolean
                 *  isDisabled: boolean
                 *  isHidden: function
                 *  state: String
                 *  stateParams: Object
                 * },...]
                 */
                onItemDelete: '&?',
                filmstripItems: '='
            },
            link: function($scope, $element){
                $scope.genericFilmstripVm.$element = $element;
            }
        };
    };

}());


