(function () {
    'use strict';

    class SessionAccordionItemController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'SessionAccordionItemController';

            this.AnalyticsService = AnalyticsService;
        }

        toggleShow() {
            this.shown = !this.shown;
            if(this.onChange) {
                this.onChange({shown: this.shown});
            }
        }
    }

    Components.SessionAccordionItem = {
        bindings: {
            text: '@',
            number: '@',
            user: '<',
            shown: '=',
            completed: '=',
            onChange: '&'
        },
        transclude: true,
        controller: SessionAccordionItemController,
        controllerAs: 'sessionAccordionItemVm',
        name: 'hbSessionAccordionItem',
        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/session_accordion/session_accordion_item.html',
    };

}());