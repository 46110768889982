Directives.AgreementFieldDirective = function AgreementFieldDirective() {

    // @ngInject
    function AgreementFieldDirectiveControllerFunc($scope, InputFieldsService, $element, $injector, $window, AnalyticsService, UiPersistenceService, DeviceService, $state, AppStates) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AgreementFieldDirectiveController';

        this.InputFieldsService = InputFieldsService;
        this.AnalyticsService = AnalyticsService;
        this.$element = $element;
        this.$window = $window;
        this.UiPersistenceService = UiPersistenceService;
        this.$state = $state;
        this.AppStates = AppStates;
        this.isMobile = DeviceService.nxSmallBreakpoint();

        this.filterField = this.filterField.bind(this);

        this.SETTINGS = {
            CAN_EDIT: 'can_edit',
            MUST_FILL: 'must_fill',
            CANT_EDIT: 'cant_edit'
        };
        this.settingOptions = [
            {name: this.SETTINGS.CAN_EDIT, translationKey:'_CAN_EDIT_'},
            {name: this.SETTINGS.MUST_FILL, translationKey:'_MUST_FILL_'},
            {name: this.SETTINGS.CANT_EDIT, translationKey:'_CANT_EDIT_'},
        ];

        this.inputStyle = {top:0, left: 0};
        this.containerStyle = {top:0, left:0};
        this.notchStyle = {top:0, left:0};


        $scope.$watch("agreementFieldVm.position", function (newValue) {

            if (newValue) {
                if (!this.isMobile) {
                    if (newValue.notch) {
                        this.notchStyle = {'top': newValue.notch.y, 'left': newValue.notch.x};
                    }
                    if (newValue.input) {
                        this.containerStyle = {'top': newValue.input.y, 'left': newValue.input.x};
                    }

                    if (newValue.notchClass === "notch-down") {
                        this.inputStyle = {'bottom': 0};
                    } else if (newValue.notchClass === "notch-up") {
                        this.inputStyle = {'top': 0};
                    }
                }

                this.loadFieldConfig(this.externalFieldName);
                this.reset();
            }
        }.bind(this));


        this.initFieldsList();

        this.showFieldsDropdown = false;

        this.isClientField = true;
        this.isRequiredField = false;
        this.isShowIntroPopup = this.isShowAgreementFieldIntroPopup();
    }

    var AgreementFieldDirectiveController = Class(Controllers.BaseController, {
        constructor: AgreementFieldDirectiveControllerFunc,

        isShowAgreementFieldIntroPopup: function isShowAgreementFieldIntroPopup() {
            var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.editableAgreement);
            return (!this.isMobile && (!uiPersistenceData || !uiPersistenceData.inputFieldTooltip));
        },

        setAgreementFieldIntroPopupAsSeen: function setAgreementFieldIntroPopupAsSeen() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.editableAgreement, {'inputFieldTooltip':true});
        },

        shouldSetFocusToField: function shouldSetFocusToField() {
            return this.show && !this.isShowIntroPopup && this.isMobile
        },

        initFieldsList: function initFieldsList() {
            this.fieldsList = [];
            var categoriesOrder = [
                '_category_event',
                '_category_clients',
                '_category_payments',
                '_category_company',
                '_category_file'];
            categoriesOrder.forEach(function (categoryName){
                var fields = this.InputFieldsService.getCategoryFields(categoryName);
                if (fields) {
                    for (var i=0; i<fields.length; i++) {
                        var fieldObject = this.InputFieldsService.getField(fields[i].entry_key);
                        fieldObject.name = fieldObject.getFieldEntryValue();

                        if (angular.isFunction(fields[i].shouldShow) && !fields[i].shouldShow(this.companyModel)) {
                            continue;
                        }

                        // fieldObject.placeholder = fieldObject.getPlaceholder();
                        fieldObject.defaultValue = fieldObject.getDefaultValue(this.agreementValuesStrategy);
                        fieldObject.category = fieldObject.getCategoryName();
                        this.fieldsList.push(fieldObject);
                    }
                }
            }.bind(this));

            this._getCustomizedFields().forEach(function(field) {
                var fieldObject = this.InputFieldsService.createCustomizedField(field.identifier, field.name);
                fieldObject.name = fieldObject.getFieldEntryValue();
                // fieldObject.placeholder = fieldObject.getPlaceholder();
                fieldObject.defaultValue = fieldObject.getDefaultValue(this.agreementValuesStrategy);
                fieldObject.category = fieldObject.getCategoryName();
                this.fieldsList.push(fieldObject);
            }.bind(this));
        },

        reloadDefaultValues: function reloadDefaultValues() {
            this.fieldsList.forEach(function (field) {
                field.defaultValue = field.getDefaultValue(this.agreementValuesStrategy);
            }.bind(this));
        },

        addCustomField: function addCustomField() {
            this.showFieldsDropdown = false;
            //this.selectedFieldPlaceholder holds the custom field name

            this.selectedField = this.InputFieldsService.getOtherCategoryField();
            this.selectedField.setFieldPlaceholder(this.selectedFieldPlaceholder);
            this.selectedFieldValue = '';
            this.isClientField = true;
            this.isRequiredField = false;
        },

        isSimilarPresetField: function isSimilarPresetField() {
            if (this.selectedFieldPlaceholder) {
                var lowerCaseCustomField = this.selectedFieldPlaceholder.toLowerCase().trim();
                return !!this.fieldsList.find(function (field) {
                    return (field.name.toLowerCase() === lowerCaseCustomField);
                });
            }
            return false;
        },

        selectField: function selectField(selectedField) {
            this.showFieldsDropdown = false;

            this.selectedField = selectedField;
            this.selectedFieldValue = selectedField.getDefaultValue(this.agreementValuesStrategy);
            this.selectedFieldPlaceholder = this.selectedField.getPlaceholder();
            this.isClientField = this.selectedField.isClientField();
            this.isRequiredField = this.selectedField.isRequiredField();
        },

        loadFieldConfig: function loadFieldConfig(cfg) {
            this.selectedField = this.InputFieldsService.getField(cfg);
            this.isClientField = this.selectedField.isClientField();
            this.selectedFieldPlaceholder = this.selectedField.getPlaceholder();

            if (this.selectedField.isRequiredField() === undefined) {
                this.selectedField.setRequiredField(true);
            }
            this.isRequiredField = this.selectedField.isRequiredField();

            if (this.selectedField.isRealField()) {
                this.selectedFieldValue = this.externalFieldValue === null ? this.selectedField.getDefaultValue(this.agreementValuesStrategy) : this.externalFieldValue;
            } else {
                this.selectedField = null;
                this.selectedFieldValue = null;
            }

            setTimeout(this.reloadDefaultValues.bind(this),250); //run after to prevent blocking view from opening
            this.onFieldNameChange();

            // We're not using $timeout to avoid an unnecessary digest cycle
            setTimeout(function () {
                var inputToFocus = this.$element.find("#inputPopupContainer");
                inputToFocus[0].focus();

                //clear the selected text in the agreement
                var selection = this.$window.getSelection();
                if (selection && selection.empty) {
                    selection.empty();
                } else if (selection && selection.removeAllRanges) {
                    selection.removeAllRanges();
                }

            }.bind(this), 200);

        },

        isSettingSelected: function isSettingSelected(type) {
            switch (type) {
                case this.SETTINGS.CAN_EDIT:
                    return (this.isClientField && !this.isRequiredField);
                case this.SETTINGS.MUST_FILL:
                    return (this.isClientField && this.isRequiredField);
                case this.SETTINGS.CANT_EDIT:
                    return (!this.isClientField);
            }
        },

        getSelectedSetting: function getSelectedSetting() {
              if (this.isClientField) {
                  return this.isRequiredField ? this.SETTINGS.MUST_FILL : this.SETTINGS.CAN_EDIT;
              }
              return this.SETTINGS.CANT_EDIT;
        },

        setFieldSettings: function setFieldSettings(type) {
              switch (type) {
                  case this.SETTINGS.CAN_EDIT:
                      this.isClientField = true;
                      this.isRequiredField = false;
                      break;
                  case this.SETTINGS.MUST_FILL:
                      this.isClientField = true;
                      this.isRequiredField = true;
                      break;
                  case this.SETTINGS.CANT_EDIT:
                      this.isClientField = false;
                      this.isRequiredField = true;
                      break;
              }
            this.selectedField.setClientField(this.isClientField);
            this.selectedField.setRequiredField(this.isRequiredField);
        },


        toggleFieldSelection: function toggleFieldSelection(newState) {
            this.showFieldsDropdown = newState;
        },

        filterField: function filterField(field) {
            if (this.regex) {
                return this.regex.test(field.name);
            } else {
                return true;
            }
        },

        onFieldNameChange: function onFieldNameChange() {
            if (this.selectedFieldPlaceholder) {
                this.regex = new RegExp(this.selectedFieldPlaceholder.split("").join(".*"), "i");
                this.filterPhrase = this.selectedFieldPlaceholder;
            } else {
                this.regex = null;
                this.filterPhrase = null;
            }
        },


        handleKeyPressGlobal: function handleKeyPressGlobal(evt) {
            switch (evt.keyCode) {
                case 27: //escape
                    if (this.showFieldsDropdown) {
                        this.showFieldsDropdown = false;
                    } else {
                        this.cancel();
                    }
                    break;
                case 13: //enter
                    this.saveChanges('press_enter');
                    break;
            }
        },

        handleKeyPressFirstField: function handleKeyPressFirstField(evt) {
            if (evt.keyCode === 9) { //tab
                if (evt.shiftKey) {
                    evt.stopPropagation(); //no field before to go back to
                    evt.preventDefault();
                } else {
                    this.showFieldsDropdown = false;
                }
            }
        },

        handleKeyPressSecondField: function handleKeyPressSecondField(evt) {
            if (evt.keyCode === 9) { //tab
                if (evt.shiftKey) {
                    this.showFieldsDropdown = true;
                } else {
                    evt.stopPropagation(); //don't continue to fields outside form
                    evt.preventDefault();
                }
            }
        },

        reset: function reset() {
            this.showFieldsDropdown = false;
        },

        cancel: function cancel() {
            this.reset();
            this.hideEditFieldBox();
        },

        hideEditFieldBox: function hideEditFieldBox() {
            this.show = false;
            this.editor.unlockSelection();
        },

        saveChanges: function saveChanges(saveTrigger) {
            var analyticsArgs = {
                field_type: this.selectedField.isOtherField() ? 'custom' : 'preset',
                field_setting: this.getSelectedSetting(),
                field_category: this.selectedField.getCategoryName(),
                field_name: this.selectedField.getFieldEntryValue()
            };
            if (saveTrigger) {
                analyticsArgs.trigger = saveTrigger;
            }
            if (analyticsArgs.field_type === 'custom') {
                analyticsArgs.has_similar_preset_field = this.isSimilarPresetField();
            }
            this.AnalyticsService.track(this, 'save agreement field', analyticsArgs);

            this.externalFieldValue = this.selectedFieldValue;
            if (this.selectedFieldValue === null) {
                //HON-4013 - Agreement Field does not show Value when switching to Other
                this.externalFieldValue = "";
            }
            this.selectedField.setFieldChanged(this.externalFieldValue !== this.selectedField.getDefaultValue(this.agreementValuesStrategy));
            this.selectedField.setFieldPlaceholder(this.selectedFieldPlaceholder);
            this.externalFieldName = this.selectedField.toString();
            if (this.onAdd !== undefined) {
                this.onAdd({outputFieldName: this.externalFieldName, outputFieldValue: this.externalFieldValue});
            }
            this.hideEditFieldBox();
        },

        _getCustomizedFields: function _getCustomizedFields() {
            var model = null;
            if (this.fileModel && this.fileModel.event) {
                model = this.fileModel.event;
            } else if (this.companyModel) {
                model = this.companyModel;
            }

            return (model && model.custom_project_fields_v2) || (model && model.custom_project_fields) || [];
        }

    });

    return {
        scope: {
            fileModel: '=fileModel',
            companyModel: '=',
            show: '=show',
            position: '=position',
            externalFieldName: '=selectedFieldName',
            externalFieldValue: '=selectedFieldValue',
            agreementValuesStrategy: '=',
            onAdd: '&?onAdd',
            editor: '=',
        },
        restrict: 'E',
        templateUrl: 'angular/app/modules/core/features/agreement/editable_agreement/input_popup/agreement_field_template.html',
        controller: AgreementFieldDirectiveController,
        controllerAs: 'agreementFieldVm',
        bindToController: true
    };
};