// returns true if text overflows width of element and consequently adds class to element
// feel free to edit and run a callback instead ;) (;
// example: hb-text-overflow-directive="class-to-add-on-true"
(function () {
    'use strict';

    Directives.TextOverflowDirective = function TextOverflowDirective() {
        // @ngInject
        function TextOverflowDirectiveControllerCtor($element, $scope, $attrs, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'TextOverflowDirectiveController';
            var overflowing = $element[0].offsetWidth < $element[0].scrollWidth;

            if (overflowing) {
                $element.addClass($attrs.hbTextOverflowDirective);
            } else {
                $element.removeClass($attrs.hbTextOverflowDirective);
            }
        }

        var TextOverflowDirectiveController = Class(Controllers.BaseController, {
            constructor: TextOverflowDirectiveControllerCtor
        });


        return {
            restrict: 'A',
            templateUrl: '',
            controller: TextOverflowDirectiveController,
            controllerAs: 'Vm',
            bindToController: true,
            scope: {
            }
        };
    };

}());
