(function () {
    "use strict";

    Controllers.AutomationsController = class AutomationsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state) {
            super($scope, $injector);
            this.__objectType = 'AutomationsController';
        }
    };

}());
