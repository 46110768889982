(function () {
    "use strict";


    // @ngInject
    function ProposalsManagerCtor(APIService, ModelFactory, Routes, $q, AnalyticsService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.AnalyticsService = AnalyticsService;
    }


    Services.ProposalsManager = Class(Services.BaseModelManager, {

        constructor: ProposalsManagerCtor,

        toggleTaxToAllPackages: function toggleTaxToAllPackages(workspaceFile, addTax) {
            var url = this.Routes.v2_workspace_file_toggle_tax_path(workspaceFile._id);
            return this.apiCreate(url, {tax_to_all: addTax}, workspaceFile, true);
        },

        toggleSvcToAllPackages: function toggleSvcToAllPackages(workspaceFile, addSvc) {
            var url = this.Routes.v2_workspace_file_toggle_svc_path(workspaceFile._id);
            return this.apiCreate(url, {svc_all: addSvc}, workspaceFile, true);
        },

        addPackageToFile: function addPackageToFile(workspaceFile, packageId) {
            var url = this.Routes.v2_workspace_file_add_package_path(workspaceFile._id, packageId);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        addPackageToBrochure: function addPackageToBrochure(workspaceFile, brochureProposal, packageId) {
            var url = this.Routes.v2_add_package_to_brochure_path(workspaceFile._id, brochureProposal._id, packageId);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        addCustomItemToFile: function addCustomItemToFile(workspaceFile, itemData) {
            var url = this.Routes.v2_workspace_file_add_service_path(workspaceFile._id, itemData);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        addCustomItemToBrochure: function addCustomItemToBrochure(workspaceFile, brochureProposal, itemData) {
            var url = this.Routes.v2_add_service_to_brochure_path(workspaceFile._id, brochureProposal._id, itemData);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        updateProposal: function updateProposal(workspaceFile, itemData) {
            var url = this.Routes.v2_workspace_file_update_proposal_path(workspaceFile._id, itemData);
            return this.apiUpdate(url, itemData, workspaceFile, true, null, 'waitForLast');
        },

        updateService: function updateService(workspaceFile, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {

                url = this.Routes.v2_workspace_file_update_service_on_package_path(workspaceFile._id, packageModel._id, serviceModel._id);
            } else {

                url = this.Routes.v2_workspace_file_update_service_on_proposal_path(workspaceFile._id, serviceModel._id);
            }

            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        updateServiceImage: function updateServiceImage(workspaceFile, serviceModel, image, packageModel) {
            var url = "";
            if (packageModel) {
                url = this.Routes.v2_workspace_file_update_service_image_path(workspaceFile._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_workspace_file_update_standalone_service_image_path(workspaceFile._id, serviceModel._id);
            }
            return this.apiUpdate(url, {image: image}, workspaceFile, true);
        },

        deleteServiceImage: function deleteServiceImage(workspaceFile, serviceModel, packageModel) {
            var url = "";
            if (packageModel) {
                url = this.Routes.v2_workspace_file_delete_service_image_path(workspaceFile._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_workspace_file_delete_standalone_service_image_path(workspaceFile._id, serviceModel._id);
            }
            return this.apiDelete(url, null, workspaceFile, false);
        },

        updateServiceOnBrochure: function updateServiceOnBrochure(workspaceFile, brochureProposal, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {

                url = this.Routes.v2_update_service_on_package_of_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id, serviceModel._id);
            } else {

                url = this.Routes.v2_update_service_on_proposal_of_brochure_path(workspaceFile._id, brochureProposal._id, serviceModel._id);
            }

            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        updateServiceImageOnBrochure: function updateServiceImageOnBrochure(workspaceFile, brochureProposal, serviceModel, packageModel, image) {
            var url = this.Routes.v2_update_service_image_on_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id, serviceModel._id);
            return this.apiUpdate(url, {image: image}, workspaceFile, true);
        },

        deleteServiceImageOnBrochure: function deleteServiceImageOnBrochure(workspaceFile, brochureProposal, serviceModel, packageModel) {
            var url = this.Routes.v2_delete_service_image_on_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id, serviceModel._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },


        updateServicesOrder: function updateServicesOrder(workspaceFile, packageModel) {
            var url = this.Routes.v2_update_services_order_on_package_path(workspaceFile._id, packageModel._id);

            var data = [];
            packageModel.base_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data}, workspaceFile, true);
        },


        updateVendorPackagesOrder: function updateVendorPackagesOrder(workspaceFile, packagesOrder) {
            var url = this.Routes.v2_workspace_file_update_packages_order_path(workspaceFile._id);

            return this.apiUpdate(url, {packages_order: packagesOrder}, workspaceFile, true);
        },

        deleteService: function deleteService(workspaceFile, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {
                url = this.Routes.v2_workspace_file_delete_service_on_package_path(workspaceFile._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_workspace_file_delete_service_on_proposal_path(workspaceFile._id, serviceModel._id);
            }
            return this.apiDelete(url, null, workspaceFile, true);
        },

        deleteServiceFromBrochure: function deleteServiceFromBrochure(workspaceFile, brochureProposal, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {
                url = this.Routes.v2_delete_service_on_package_from_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_delete_service_from_brochure_proposal_path(workspaceFile._id, brochureProposal._id, serviceModel._id);
            }
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updatePackage: function updatePackage(workspaceFile, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_workspace_file_update_package_on_proposal_path(workspaceFile._id, packageModel._id);
            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        updatePackageOnBrochure: function updatePackageOnBrochure(workspaceFile, brochureProposal, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_update_package_on_proposal_of_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id);
            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        deletePackage: function deletePackage(workspaceFile, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_workspace_file_delete_package_on_proposal_path(workspaceFile._id, packageModel._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        deletePackageFromBrochure: function deletePackageFromBrochure(workspaceFile, proposal, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_delete_package_on_proposal_of_brochure_path(workspaceFile._id, proposal._id, packageModel._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(workspaceFile, packageModel, newService) {

            var url = this.Routes.v2_workspace_file_package_additional_service_path(workspaceFile._id, packageModel._id);
            return this.apiCreate(url, newService, workspaceFile, true);
        },

        addAdditionalServiceToPackageInBrochure: function addAdditionalServiceToPackageInBrochure(workspaceFile, brochureProposal, packageModel, newService) {

            var url = this.Routes.v2_add_package_additional_service_to_brochure_path(workspaceFile._id, brochureProposal._id, packageModel._id);
            return this.apiCreate(url, newService, workspaceFile, true);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(workspaceFile, orderMap) {
            var url = this.Routes.v2_workspace_file_order_packages_and_services_path(workspaceFile._id);
            return this.apiUpdate(url, {order: orderMap}, workspaceFile, true);
        },

        moveService: function moveService(workspaceFile, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            var url = this.Routes.v2_workspace_file_move_service_path(workspaceFile._id, serviceId);

            var data = {
                source_package_id: sourcePackageId,
                dest_package_id: destPackageId,
                dest_order: []
            };
            servicesCollection.forEach(function(service, index) {
               data.dest_order.push({_id: service._id, order: index});
            });

            return this.apiUpdate(url, data, workspaceFile, true);
        },

        /*****************************************
         * NEW BROCHURE API (Dynamic content file)
         ******************************************/

        addPackageToBlock: function addPackageToBlock(workspaceFile, section, block, packageId) {
            var url = this.Routes.v2_workspace_file_add_package_to_block_path(workspaceFile._id, section._id, block._id);
            return this.apiCreate(url, { package_id: packageId}, workspaceFile, true);
        },

        updatePackageOnBlock: function updatePackageOnBlock(workspaceFile, section, block, packageModel) {
            var url = this.Routes.v2_workspace_file_update_package_on_block_path(workspaceFile._id, section._id, block._id, packageModel._id);
            var data = packageModel.dataOnly();
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        deletePackageFromBlock: function deletePackageFromBlock(workspaceFile, section, block, packageModel) {
            var url = this.Routes.v2_workspace_file_delete_package_from_block_path(workspaceFile._id, section._id, block._id, packageModel._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updatePackageServicesOrderOnBlock: function updatePackageServicesOrderOnBlock(workspaceFile, section, block, packageModel, order) {
            var url = this.Routes.v2_workspace_file_update_package_services_order_on_block_path(workspaceFile._id, section._id, block._id, packageModel._id);
            return this.apiUpdate(url, {servicesOrder: order}, workspaceFile, true);
        },

        addServiceToBlock: function addServiceToBlock(workspaceFile, section, block, packageModel) {
            var url = this.Routes.v2_workspace_file_add_service_to_block_path(workspaceFile._id, section._id, block._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        updateServiceOnBlock: function updateServiceOnBlock(workspaceFile, section, block, service, packageModel) {
            var url = this.Routes.v2_workspace_file_update_service_on_block_path(workspaceFile._id, section._id, block._id, service._id);
            var data = service.dataOnly();
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateServiceQuantityOnBlock: function updateServiceQuantityOnBlock(workspaceFile, section, block, service, packageModel) {
            var url = this.Routes.v2_workspace_file_update_service_quantity_on_block_path(workspaceFile._id, section._id, block._id, service._id);
            var serviceData = service.dataOnly();

            // take only quantity related
            var data = {
                quantity: serviceData.quantity
            };

            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updatePackageQuantityOnBlock: function updatePackageQuantityOnBlock(workspaceFile, section, block, packageModel) {
            var url = this.Routes.v2_workspace_file_update_package_quantity_on_block_path(workspaceFile._id, section._id, block._id, packageModel._id);
            var packageData = packageModel.dataOnly();

            // take only quantity related
            var data = {
                quantity: packageData.quantity
            };

            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateServiceImageOnBlock: function updateServiceImageOnBlock(workspaceFile, section, block, service, image, packageModel) {
            var url = this.Routes.v2_workspace_file_update_service_on_block_path(workspaceFile._id, section._id, block._id, service._id);
            var data = {
                image: image
            };
            if(!data.image && data.image !== '') {
                data.image = '';
            }
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        deleteServiceFromBlock: function deleteServiceFromBlock(workspaceFile, section, block, service, packageModel) {
            var url = this.Routes.v2_workspace_file_delete_service_from_block_path(workspaceFile._id, section._id, block._id, service._id);
            var data = {};
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiDelete(url, data, workspaceFile, true);
        },

        updateProposalOrder: function updateProposalOrder(workspaceFile, section, block, order) {
            var url = this.Routes.v2_workspace_file_update_packages_and_services_order_on_block_path(workspaceFile._id, section._id, block._id);
            return this.apiUpdate(url, {order: order}, workspaceFile, true);
        },

        moveServiceOnBlock: function moveServiceOnBlock(workspaceFile, section, block, serviceId, sourcePackageId, destPackageId, order) {
            var url = this.Routes.v2_workspace_file_move_service_on_block_path(workspaceFile._id, section._id, block._id, serviceId);
            var data = {
                source_package_id: sourcePackageId,
                dest_package_id: destPackageId,
                dest_order: order
            };
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateProposalSelection: function updateProposalSelection(workspaceFile, section, block, service, packageModel, isSelected) {
            var url = this.Routes.v2_workspace_file_update_proposal_selection_path(workspaceFile._id, section._id, block._id);
            var data = {};
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            if(service && service._id) {
                data['service_id'] = service._id;
            }
            data['is_selected'] = isSelected;
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateProposalOnBlock: function updateProposalOnBlock(workspaceFile, section, block, proposalData) {
            var url = this.Routes.v2_workspace_file_update_proposal_on_block_path(workspaceFile._id, section._id, block._id);
            var data = proposalData;
            return this.apiUpdate(url, data, workspaceFile, true);
        },
    });
}());
