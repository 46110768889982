// @ngInject

Models.SessionModel = function SessionModel(BaseModel, _, DateService, moment) {

    return Class(BaseModel, {
        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'SessionModel';
            this._ = _;
            this.moment = moment;

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {

            };
        },

        isSessionValid: function isSessionValid(userHasGoogleCalendar, userHasZoomIntegration) {
            return  this.session_type &&
                    this.session_name &&
                    this.session_duration.unit_count &&
                    this.session_timezone &&
                    this.isGoogleVideoCallValid(userHasGoogleCalendar) &&
                    this.isZoomVideoCallValid(userHasZoomIntegration) &&
                    this.isWindowValid() &&
                    this.isRemindersValid() &&
                    this.isBuffersValid() &&
                    this.isNoticeValid() &&
                    this.isTeamMembersValid();
        },

        isGoogleVideoCallValid: function isGoogleVideoCallValid(userHasGoogleCalendar) {

            if(this.session_type !== 'video_call') {
                return true;
            }

            return this.video_conference_type &&
                (this.video_conference_type !== 'google' || !!userHasGoogleCalendar);
        },

        isZoomVideoCallValid: function isZoomVideoCallValid(userHasZoomIntegration) {

            if(this.session_type !== 'video_call') {
                return true;
            }

            return this.video_conference_type &&
                (this.video_conference_type !== 'zoom' || !!userHasZoomIntegration);
        },

        isWindowValid: function isWindowValid() {

            return this.isFixedDateRangeValid() && this.isRollingWindowValid();
        },

        isRollingWindowValid: function isRollingWindowValid() {
            if (this.window_type !== 'rolling_window') {
                return true;
            }

            return !!this.rolling_window_duration.unit_count;
        },

        isFixedStartDateValid: function isFixedStartDateValid() {
            return this.fixed_start_date && this.moment().startOf('day').isSameOrBefore(this.fixed_start_date);
        },

        isFixedEndDateValid: function isFixedEndDateValid() {
            return this.fixed_start_date && this.fixed_end_date && this.moment(this.fixed_start_date).isBefore(this.fixed_end_date);
        },

        isFixedDateRangeValid: function isFixedDateRangeValid() {
            if (this.window_type !== 'fixed_date_range') {
                return true;
            }

            return this.isFixedStartDateValid() && this.isFixedEndDateValid();
        },

        isBuffersValid: function isBuffersValid() {
            var beforeValid = !this.buffer_before || !!this.buffer_before.unit_count;
            var afterValid = !this.buffer_after || !!this.buffer_after.unit_count;
            return beforeValid && afterValid;
        },

        isNoticeValid: function isNoticeValid() {
            return !this.notice_period || !!this.notice_period.unit_count;
        },

        isIncrementsValid: function isIncrementsValid() {
            return !this.availability_increments;
        },

        isRemindersValid: function isRemindersValid() {
            return this.session_reminders.every(function(reminder) {
                return !!reminder.when && !!reminder.when.unit_count;
            });
        },

        isTeamMembersValid: function isTeamMembersValid() {
            return !this.schedule_behaviour_type || this.shared_member_ids.length;
        },

        addNewReminder: function addNewReminder() {
            this.session_reminders.push({
                type: 'email',
                when: { unit_count: 1, unit_type: 'hours'},
            });
        },

        addDayConfig: function addDayConfig(dayConfig) {
            return this.day_availabilities.push(dayConfig);
        },

        deleteDayConfig: function deleteDayConfig(dayConfig) {
            this.day_availabilities.splice(this.day_availabilities.indexOf(dayConfig), 1);
        },

        deleteConfigDayOfWeek: function deleteConfigDayOfWeek(dayOfWeekNum) {
            var config = this.getDayOfWeekConfig(dayOfWeekNum);
            if(config) {
                this.deleteDayConfig(config);
            }
        },

        deleteMultipleDayConfigs: function deleteMultipleDayConfigs(dayConfigs) {
            this.day_availabilities = this.day_availabilities.filter(function(da) {
                return dayConfigs.indexOf(da) === -1;
            });
        },

        getDayOfWeekConfig: function getDayOfWeekConfig(dayOfWeekNum) {
            var dayOfWeekName = DateService.weekDaysMap[dayOfWeekNum];
            return this.day_availabilities.find(function (availability) {
                return availability.day_of_week === dayOfWeekName;
            });
        },

        getDayOfWeekConfigByName: function getDayOfWeekConfig(dayOfWeekName) {
            return this.day_availabilities.find(function (availability) {
                return availability.day_of_week === dayOfWeekName;
            });
        },

        getOverridesConfigByDayOfWeek: function getOverridesConfigByDayOfWeek(dayOfWeek) {
            return this.day_availabilities.filter(function(availability) {
                var dayOfWeekNum = this.moment(availability.override_date).day();
                var dayOfWeekName = DateService.weekDaysMap[dayOfWeekNum];
                return dayOfWeekName === dayOfWeek;
            });
        },

        deleteOverridesForDayOfWeek: function deleteOverridesForDayOfWeek(dayOfWeek) {
            var overridesToDelete = this.getOverridesConfigByDayOfWeek(dayOfWeek);
            this.deleteMultipleDayConfigs(overridesToDelete);
        },

        getFormattedData: function getFormattedData() {

            var _session = {
                week: {},
                overrides: {},
                _id: this._id,
                session: this
            };

            if(!this.day_availabilities || !this.day_availabilities.length) {
                return _session;
            }

            // go over all the day availabilities for that session
            // and split the configs from the overrides
            this.day_availabilities.forEach(function(da) {

                if(da.availability_range_type === 'basic' || !da.override_date) {

                    // get the day of week
                    var dayOfWeekNum = DateService.weekDaysMap[da.day_of_week];
                    _session.week[dayOfWeekNum] = da;

                } else {

                    _session.overrides[da.override_date] = da;

                }

            }.bind(this));

            return _session;
        },

        getDayAvailbilityByDate: function getDayAvailbilityByDate(date) {
            var dayOfDate = this.moment(date).format('dddd');
            var dayAvailability = this.day_availabilities.find(function(day) {
                return day.override_date && this.moment(day.override_date).isSame(this.moment(date).startOf('day'));
            });

            if (!dayAvailability) {
                dayAvailability = this.getDayOfWeekConfigByName(dayOfDate);
            }

            return dayAvailability;
        },

        generateDaysConfiguration: function generateDaysConfiguration (fromDate, toDate) {

            var days = {};

            // build config map
            var _sessionData = this.getFormattedData();

            var currDate = fromDate;
            var endDate = toDate;

            // map date to day availability configuration
            for(;currDate <= endDate; currDate.add(1, 'days')) {

                // get the date without the time part to use as an identifier
                var currDateNoTime = currDate.format("YYYY-MM-DD");
                var dayOfWeek = currDate.day();

                // decide if we should take the day config or the override
                var dayAvailability = _sessionData.overrides[currDateNoTime] || _sessionData.week[dayOfWeek];
                if(dayAvailability) {
                    days[currDateNoTime] = dayAvailability;
                }
            }

            return days;
        }
    });
};
