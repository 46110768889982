(function () {
    'use strict';

    // @ngInject
    var PipelineWorkspaceControllerFunc = function ($injector, $element, $document, $timeout, $state, $window, AppStates, $scope, _, moment,
                                                    Enums, Constants, AppConfigService, UsersManager, WorkspacesManager, AnalyticsService, ModalService, PopupMessageService, EventService, OnboardingBotManager,PretrialersService,
                                                    AbTestService, $translate, ToastService, FlowService, FlowsBetaUserTypeService, NavigationService, FeaturesService,
                                                    FeatureRestrictionService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PipelineWorkspaceController';

        var self = this;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$window = $window;
        this.moment = moment;
        this._ = _;
        this.Constants = Constants;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.WorkspacesManager = WorkspacesManager;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.currentWorkspaceStage = null;
        this.elapsedTimeInStep = 0;
        this.Enums = Enums;
        this.tags = null;
        this.appStyleType = AppConfigService.getAppStyleType();
        this.showDropDown = null;
        this.togllingOfDropdown = null;
        this.allowDelete = this.workspace.allowDelete();
        this.showDropDownTimeout = null;
        this.dropDownIsShowingMsg = false;
        this.movedForward = false;
        this.showDeleteDropdown = false;
        this.ModalService = ModalService;
        this.followUpStageOrder = 0;
        this.completedStageOrder = 0;
        this.stagesLength = Object.keys(this.stagesHash).length - 1;
        this.EventService = EventService;
        this.currUser = UsersManager.getCurrUser();
        this.isHbAdmin = this.currUser.is_hb_admin;
        this.OnboardingBotManager = OnboardingBotManager;
        this.PretrialersService = PretrialersService;
        this.$translate = $translate;
        this.ToastService = ToastService;
        this.FlowService = FlowService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.NavigationService = NavigationService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        if (this.appStyleType < Constants.AppStyleTypes.large) {
            this.togllingOfDropdown = false;
            this.showDropDown = true;
        } else {
            this.togllingOfDropdown = true;
            this.showDropDown = false;
        }

        // this.register(this.workspace, 'success', function gotWorkspace() {
        //     if (this.workspace.workspace_pipeline_data) {
        //         this.updateStageData();
        //         if (this.onWorkspaceChanged && angular.isFunction(this.onWorkspaceChanged)) {
        //             this.onWorkspaceChanged();
        //         }
        //     }
        // });

        if (this.workspace.workspace_pipeline_data) {
            this.updateStageData();
        }

        this.plOptsActions = 'opts-actions-' + this.workspace._id;

        this.isOnboardingBotActiveOnWS = this.OnboardingBotManager.isOnboardingBotActiveOnWS(this.workspace);

        this.visibleQuickActions = {};

        this.blockReasonTranslated = this.getBlockReasonTranslation();

        this.teamColumnAvailable = this.company.doesCurrUserHaveTeamMembers();

        if (this.teamColumnAvailable) {
            const companyWSmembers = this.workspace.members.filter((user) => {
                return user.company_ids && user.company_ids.includes(this.currUser.company._id);
            });
            // put owner of ws first, then the rest
            const [[owner], otherMembers] = this._.partition(companyWSmembers, u => u._id === this.workspace.creator_id);
            this.workspaceTeamMembers = owner ? [owner, ...otherMembers] : otherMembers;
        }
        // Uncomment this to simulate recent activity
        // this.workspace.recent_activity_type = this.Enums.homeRecentActivityTypes.contract_signed;
        // this.workspace.recent_activity_completed = false;
    };

    var PipelineWorkspaceController = Class(Controllers.BaseController, {
        constructor: PipelineWorkspaceControllerFunc,

        hoverIn: function () {
            if (this.togllingOfDropdown) {
                if (!this.showDropdownTimeout) {
                    this.showDropdownTimeout = this.$timeout(function () {
                        this.showDropDown = true;
                    }.bind(this), 500);
                }
            }
        },

        hoverOut: function () {
            if (this.togllingOfDropdown && !this.dropDownIsShowingMsg) {
                if (this.showDropdownTimeout) {
                    this.$timeout.cancel(this.showDropdownTimeout);
                    this.showDropdownTimeout = null;
                }
                this.showDropDown = false;
            }
        },

        navigateToWorkspace: function ($event) {

            var $stageDropdown = angular.element('#unblock-dropdown-' + this.workspace._id);
            var $archiveDropdown = angular.element('#block-dropdown-' + this.workspace._id);

            // todo sara make this more specific to the active workspace.
            var $referMsg = angular.element('.refer-vendor-msg');
            var $sendBrochureBtn = angular.element('.send-brochure');
            var $optionsDropdown = angular.element('[hb-dropdown-toggle="opts-actions-' + this.workspace._id + '"]');

            //Don't navigate if we are doing these actions
            var targetIsStageDropdown = $stageDropdown.length && angular.element.contains($stageDropdown[0], $event.target);
            var targetIsArchiveDropdown = $archiveDropdown.length && angular.element.contains($archiveDropdown[0], $event.target);


            var targetIsReferMsg = $referMsg.length && angular.element.contains($referMsg[0], $event.target);
            var targetIsSendBrochureBtn = $sendBrochureBtn.length && angular.element.contains($sendBrochureBtn[0], $event.target);
            var targetIsOptionsDropdown = $optionsDropdown.length && angular.element.contains($optionsDropdown[0], $event.target);

            //if a menu is open, and we're not clicking the title, we want to not navigate.

            if (targetIsStageDropdown || targetIsArchiveDropdown || targetIsReferMsg ||
                targetIsSendBrochureBtn || targetIsOptionsDropdown) {
                return;
            }

            if (this.isOnboardingBotActiveOnWS) {
                this.ModalService.openCreateFirstProjectModal(false);
                return;
            }

            $event.stopPropagation();
            $event.preventDefault();

            this.NavigationService.setBackToCurrentState();
            //navigate to the right place according to the persistency
            var stateToGoTo = this.EventService.getEventDefaultState(this.workspace.event._id);
            if ($event.metaKey) {
                var url = this.$state.href(stateToGoTo.name, stateToGoTo.params);
                this.$window.open(url, '_blank');
                return;
            }
            this.$state.go(stateToGoTo.name, stateToGoTo.params);
            this.AnalyticsService.track(this, "go to workspace", {source: 'pipeline'});
        },

        hasMoreThenOneTag: function hasMoreThenOneTag() {
            if (this.tags) {
                return this.tags.length > 1;
            }
            return false;
        },

        hasTags: function hasTags() {
            if (this.tags) {
                return this.tags.length > 0;
            }
            return false;
        },

        getTranslated: function getTranslated(tag) {
            // This doesnt work anymore, since we cant have translation for all project types
            return 'WORKSPACE.PIPELINE._' + tag.toUpperCase() + '_';
        },

        updateStageData: function () {
            var stageId = this.workspace.workspace_pipeline_data.current_stage.stage_id;
            this.currentWorkspaceStage = this.stagesHash[stageId];
            this.stageIsBooked = this.currentWorkspaceStage && this.currentWorkspaceStage.category === 'booked';

            //find the _followup_ & _completed_ stage order
            this._.forEach(this.stagesHash, function (stageViewModel) {
                if (stageViewModel.originalName === '_FOLLOW_UP_') {
                    this.followUpStageOrder = stageViewModel.order;
                }
                if (stageViewModel.originalName === '_CLOSED_') {
                    this.completedStageOrder = stageViewModel.order;
                }

            }.bind(this));

            this.elapsedTimeInStep = this.moment(this.workspace.workspace_pipeline_data.current_stage.moved_on).fromNow(true);

            //prepare the tags
            this.tags = [];
            if (this.workspace.workspace_pipeline_data.blocked_tag) {
                var blockedTagTranslated = this.getBlockReasonTranslation();
                if (blockedTagTranslated) {
                    this.tags.push(blockedTagTranslated);
                }
            }

            if (this.workspace.workspace_pipeline_data.tags && angular.isArray(this.workspace.workspace_pipeline_data.tags)) {
                this.workspace.workspace_pipeline_data.tags.forEach(function (tag) {
                    this.tags.push(this.getTranslated(tag));
                }.bind(this));
            }
        },

        movedNotification: function movedNotification() {
            this.movedForward = true;
            this.hoverOut();
            this.$timeout(function () {
                this.movedForward = false;
            }.bind(this), 3500);
        },

        disableTracking: function disableTracking() {
            var trackingData = {
                workspace_id: this.workspace._id,
                disable_tracking: true,
                source: "pipeline workspace"
            };

            var self = this;

            this.WorkspacesManager.updateWorkspacePipelineSettings(this.workspace, true)
                .then(function () {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' success', trackingData);
                })
                .catch(function () {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' failed', trackingData);
                });
        },

        showSendFlow: function showSendFlow() {
            return this.workspace.active_state &&
                this.currentWorkspaceStage &&
                (this.currentWorkspaceStage.order < this.completedStageOrder) &&
                this.FlowsBetaUserTypeService.hasFlows;
        },

        showSendBrochure: function showSendBrochure() {
            return (this.showBrochureQuickSend && 
                    this.currentWorkspaceStage && 
                    this.workspace.active_state && 
                    (this.currentWorkspaceStage.order <= this.followUpStageOrder) && 
                    this.FlowsBetaUserTypeService.hasFiles && !this.FlowsBetaUserTypeService.hasFlows);
        },

        showCreateFlowModal: function showCreateFlowModal($event) {
            const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                source: 'send_file',
                actionType: 'click'
            });
            if (isDisabled) {
                return;
            }
            
            this.ModalService.openCreateFlowModal(this.workspace.event, this.company, [], this.workspace._id, 'pipeline-quickSend');
            $event.stopPropagation();
        },

        showSendBrochureModal: function showSendBrochureModal($event) {
            if (!this.workspace.workspace_tab_info.has_members_acting_as_client) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'BROCHURE_QUICKSEND._NO_USERS_TO_SEND_TO_ERROR');
            } else {
                this.ModalService.openPipelineSendBrochureModal(this.workspace);
            }

            $event.stopPropagation();
        },

        handleArchiveReason: function handleArchiveReason(workspace, reason) {
            var trackingData = {
                workspace_id: workspace._id,
                archiveReason: reason,
            };

            return this.WorkspacesManager.blockWorkspace(workspace, reason)
                .then(function () {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.workspace_block, trackingData);
                })
                .catch(function (resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.workspace_block, resp, trackingData);
                });
        },

        openArchiveReasonsModal: function openArchiveReasonsModal() {
            this.ModalService.openArchiveReasonsModal(this.workspace, this.Constants.BlockReasons, this.handleArchiveReason);
        },

        setRecentActivity: function setRecentActivity(activity) {
            switch (activity) {
                case this.Enums.homeRecentActivityTypes.contract_signed:
                    return 'Contract signed';
                case this.Enums.homeRecentActivityTypes.feed_message:
                    return 'Message received';
                case this.Enums.homeRecentActivityTypes.flow_contract_signed:
                    return 'Contract signed';
                case this.Enums.homeRecentActivityTypes.flow_payment_paid:
                    return 'Payment paid';
                case this.Enums.homeRecentActivityTypes.payment_paid:
                    return 'Payment paid';
                case this.Enums.homeRecentActivityTypes.flow_services_selected:
                    return 'Service selected';
                case this.Enums.homeRecentActivityTypes.smart_file_created:
                    return 'File created';
                case this.Enums.homeRecentActivityTypes.legacy_file_created:
                    return 'File created';
                case this.Enums.homeRecentActivityTypes.smart_file_sent:
                    return 'File sent';
                case this.Enums.homeRecentActivityTypes.legacy_file_sent:
                    return 'File sent';
                case this.Enums.homeRecentActivityTypes.smart_file_completed:
                    return 'File completed';
                case this.Enums.homeRecentActivityTypes.legacy_file_completed:
                    return 'File completed';
                case this.Enums.homeRecentActivityTypes.smart_file_viewed:
                    return 'File viewed';
                case this.Enums.homeRecentActivityTypes.legacy_file_viewed:
                    return 'File viewed';
                case this.Enums.homeRecentActivityTypes.session_scheduled:
                    return 'Session scheduled';
                case this.Enums.homeRecentActivityTypes.session_rescheduled:
                    return 'Session rescheduled';
                case this.Enums.homeRecentActivityTypes.session_canceled:
                    return 'Session canceled';
                default :
                    return activity;
            }
        },

        onRecentActivityClick: function onRecentActivityClick(recent_activity_type) {
            if (recent_activity_type === this.Enums.homeRecentActivityTypes.contract_signed) {
                this.goToState(this.AppStates.root_core_workspaceFile, {workspace_file_id: this.workspace.recent_activity_file_id});
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_contract);
            } else if (recent_activity_type === this.Enums.homeRecentActivityTypes.flow_contract_signed) {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_flow_contract);
                this.FlowService.goToResponses(this.workspace.recent_activity_flow_id);
            } else if (recent_activity_type === this.Enums.homeRecentActivityTypes.flow_payment_paid) {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.go_to_flow_payment);
                this.FlowService.goToResponses(this.workspace.recent_activity_flow_id);
            }
        },

        showMoveStageMenu: function showMoveStageMenu(event) {
            event.stopPropagation();
            if (!event.target.classList.contains("pl-quick-action-icon")) {
                return;
            }

            this.visibleQuickActions.stage = !this.visibleQuickActions.stage;
            this.visibleQuickActions.archive = false;

            // Equivilent to event of opening the move stage dropdown in
            // the A test
            if (this.visibleQuickActions.stage) {
                this.AnalyticsService.trackClick(
                    this,
                    "pipeline_view: move to stage dropdown"
                );
            }
        },

        showArchiveMenu: function showArchiveMenu(event) {
            event.stopPropagation();

            this.ModalService.openArchiveProjectModal(this.workspace._id, 'Pipeline').then(function () {
                this.onArchiveDone();
            }.bind(this));
        },

        getBlockReasonTranslation: function getBlockReasonTranslation() {
            var reason = this._.findWhere(
                this.Constants.BlockReasons,
                {serverValue: this.workspace.workspace_pipeline_data.blocked_tag}
            );

            reason = reason ||  this._.findWhere(
                this.Constants.BlockReasonsExt,
                {serverValue: this.workspace.workspace_pipeline_data.blocked_tag}
            );

            var reasonTranslationKey = reason ? 
                reason.displayNameKey : 
                this.workspace.workspace_pipeline_data.blocked_tag;

            return this.$translate.instant(reasonTranslationKey);
        },

        onMoveStageDone: function onMoveStageDone() {
            this.visibleQuickActions.stage = false;
            this.onWorkspaceChanged(this.workspace);
            this.ToastService.showSuccess({
                contentTranslation: "PIPELINE.TOASTS._MOVE_STAGE_",
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout:3000
            });
        },

        onArchiveDone: function onArchiveDone() {
            this.visibleQuickActions.archive = false;
            this.onWorkspaceChanged(this.workspace);
            this.ToastService.showSuccess({
                contentTranslation: "PIPELINE.TOASTS._ARCHIVE_",
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout:3000
            });
        }
    });

    var scope = {
        stagesHash: '=stagesHash',
        workspace: '=workspace',
        company: '=company',
        sessionTypes:'=sessionTypes',
        visibleDynamicColumns: '=visibleDynamicColumns',
        onWorkspaceChanged: '&onWorkspaceChanged',
        onDeleteWorkspace: '&onDeleteWorkspace',
        onDeleteBookedWorkspace: '&onDeleteBookedWorkspace',
        onWorkspaceReferred: '&onWorkspaceReferred',
        onAssignWorkspaceToTeamMember: '&onAssignWorkspaceToTeamMember',
        showBrochureQuickSend: '=showBrochureQuickSend',
        showCheckbox: '=',
        selectWorkspace: '&',
        isSelectedWorkspace: '&',
        onWorkspaceAdminMove: '&onWorkspaceAdminMove'
    };

    // @ngInject
    Directives.PipelineWorkspace = function PipelineWorkspace() {
        return {
            scope: scope,
            templateUrl: 'angular/app/modules/core/features/pipeline/workspace/pipeline_workspace_template.html',
            controller: PipelineWorkspaceController,
            controllerAs: 'pipelineWorkspaceVm',
            bindToController: true
        };
    };

    // @ngInject
    Directives.PipelineWorkspaceLimited = function PipelineWorkspaceLimited() {
        return {
            scope: scope,
            templateUrl: 'angular/app/modules/core/features/pipeline/workspace/pipeline_workspace_limited_template.html',
            controller: PipelineWorkspaceController,
            controllerAs: 'pipelineWorkspaceVm',
            bindToController: true
        };
    };

}());
