(function () {
    "use strict";

    // @ngInject
    function GoogleContactsModalControllerCtor($scope, $injector, $modalInstance, $timeout, GoogleAPIService, PopupMessageService, ContactsManager, AnalyticsService, _, UsersManager, UserService, onUserClicked, addContactCta, selectVendorType, filterExistingContacts, originState, analyticsEventValue) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GoogleContactsModalController';

        this.GoogleAPIService = GoogleAPIService;
        this.PopupMessageService = PopupMessageService;
        this.ContactsManager = ContactsManager;
        this.AnalyticsService = AnalyticsService;
        this.$modalInstance = $modalInstance;
        this._ = _;
        this.currentUser = UsersManager.getCurrUser();
        this.UserService = UserService;
        this.analyticsEventValue = analyticsEventValue;

        this.NUMBER_OF_CONTACTS = 12;
        this.suggestedContacts = [];
        this.contactsStartIndex = 1;

        // This is what we bind the search to
        this.searchTerm = '';
        this.$timeout = $timeout;
        this.onUserClicked = onUserClicked;
        this.addContactCta = addContactCta;
        this.filterExistingContacts = filterExistingContacts;
        this.selectVendorType = selectVendorType;

        this.loadingContacts = false;
        this.originState = originState;

        var tempSearchText = '',
            searchTimeout;

        $scope.$watch('googleContactsVm.searchTerm', function (val) {
            if (searchTimeout) {
                this.$timeout.cancel(searchTimeout);
            }
            tempSearchText = val;
            searchTimeout = $timeout(function() {
                this.searchTerm = tempSearchText;
                this.suggestedContacts.length = 0;
                this.contactsStartIndex = 1;
                this.contactsMaxResult = this.NUMBER_OF_CONTACTS;
                this.searchInGoogleContacts(tempSearchText, this.contactsStartIndex, this.NUMBER_OF_CONTACTS, this.filterExistingContacts);
            }.bind(this), 800); // delay 800 ms
        }.bind(this));
    }


    Controllers.GoogleContactsModalController = Class(Controllers.BaseController, {

        constructor: GoogleContactsModalControllerCtor,

        searchInGoogleContacts: function searchInGoogleContacts(searchTerm, startIndex, maxResults, filterExistingContacts) {
            if(!this.loadingContacts && this.contactsMaxResult > 0) {
                this.loadingContacts = true;
                this.UserService.searchGoogleContacts(searchTerm, startIndex, maxResults, filterExistingContacts)
                    .then(function success(searchResults) {
                        var contacts = searchResults.contacts;
                        this.suggestedContacts = this.suggestedContacts.concat(contacts);
                        this.contactsStartIndex +=  searchResults.items_per_page;
                        this.contactsMaxResult = Math.min((searchResults.total_results - this.contactsStartIndex + 1), this.NUMBER_OF_CONTACTS);
                        this.contactsMaxResult = this.contactsMaxResult >= 0 ? this.contactsMaxResult : 0;
                    }.bind(this), function error() {
                        this.UserService.connectToAGoogleAccount(null, 'contacts', this.originState);
                    }.bind(this))
                    .finally(function doFinally() {
                        this.loadingContacts = false;
                    }.bind(this));
            }
        },

        getMoreGoogleSearchResults: function getMoreGoogleSearchResults() {
            this.searchInGoogleContacts(this.searchTerm, this.contactsStartIndex, this.NUMBER_OF_CONTACTS, this.filterExistingContacts);
        },

        getSelectedContacts: function getSelectedContacts() {
            return this._.filter(this.suggestedContacts, function(contact) {
                return contact.selected;
            });
        },

        closeModal: function closeModal() {
            this.$modalInstance.close(this.getSelectedContacts());
        },

        dismissModal: function dismissModal() {
            this.$modalInstance.dismiss();
        },

        hasVendorType: function hasVendorType(contact) {
            return ((!!(contact.user_id || contact.user._id)) &&
                contact.user.system_user_type === 'client' || contact.user.system_user_type === 'vendor');
        },

        userClicked: function userClicked(contact) {
            contact.userClicked = true;
            if (!contact.user.full_name) {
                contact.missingUserName = true;
            }
            if (!this.hasVendorType(contact)) {
                contact.missingVendorType = true;
            }
            if (!contact.missingUserName && (!contact.missingVendorType || !this.selectVendorType)) {
                this.onUserClicked(contact);
            }
        },

        userNameFilled: function userNameFilled(contact) {
            if (contact.user.full_name && contact.user.full_name.length >= 2) {
                contact.missingUserName = false;
            } else {
                return;
            }
            if (this.hasVendorType(contact) || !this.selectVendorType) {
                this.onUserClicked(contact);
            }
        },

        vendorTypeTagClicked: function vendorTypeTagClicked(contact, type) {
            contact.user.system_user_type = type;
            contact.missingVendorType = false;
            this.onUserClicked(contact);
        }
    });
}());