Directives.GenerateTagsListDirective = function GenerateTagsListDirective() {

    // @ngInject

    function GenerateTagsListDirectiveControllerFunc($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GenerateTagsListDirectiveController';
        var self = this;

        self.delimiter = angular.isDefined(self.delimiter) ? self.delimiter : " \u2022 ";

        self.localTags = [];

        self.pushTags = function pushTags(tags) {
            self.localTags.splice(0, self.localTags.length);
            tags.forEach(function (tag) {
                if (tag.label !== undefined && tag.label !== null && tag.label.trim()) {
                    self.localTags.push(tag);
                }
            });
        };

        if (!self.tags || self.tags.length === 0) {

            var unwatchFn = $scope.$watchCollection('generateTagsListVm.tags', function (newVal, oldVal) {

                if (newVal && newVal.length > 0) {

                    unwatchFn();
                    self.pushTags(newVal);


                }
            });
        } else {
            self.pushTags(self.tags);
        }


    }


    var GenerateTagsListDirectiveController = Class(Controllers.BaseController, {
        constructor: GenerateTagsListDirectiveControllerFunc
    });

    return {
        scope: {
            tags: '=tags',
            delimiter: '=?delimiter'
        },
        templateUrl: 'angular/app/modules/common/ui_components/generate_tags_list/generate_tags_list_directive_template.html',
        controller: GenerateTagsListDirectiveController,
        controllerAs: 'generateTagsListVm',
        bindToController: true
    }
};