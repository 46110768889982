Directives.QuickbooksIntegrationDirective = function QuickbooksIntegrationDirective()  {

    "use strict";

    // @ngInject
    function QuickbooksIntegrationDirectiveControllerCtor($scope, $injector,
                                                     Gon, Routes, AnalyticsService, moment, _, PopupMessageService,ModelFactory,
                                                     UsersManager, CompaniesManager, QuickbooksManager, UrlMetadataService, WebsocketHelperService, FeatureRestrictionService, ReactModalService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'QuickbooksIntegrationDirectiveController';

        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.QuickbooksManager = QuickbooksManager;
        this.PopupMessageService = PopupMessageService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this._ = _;
        this.UsersManager = UsersManager;
        this.ReactModalService = ReactModalService;
        this.user = UsersManager.getCurrUser();
        this.isInBookkeeperMode = this.user.isBookkeeperMode();
        this.WebsocketHelperService = WebsocketHelperService;
        this.company = this.isInBookkeeperMode ? ModelFactory.newCompanyModel(): CompaniesManager.getCurrCompany();

        if(this.isInBookkeeperMode) {
            UsersManager.getAuditedCompanies().then(function () {
                this.company = this.user.bookkeeperModeCompany();
                this.gotCompany();
            }.bind(this));
        }

        this.isLoadingQuickbooks = true;
        this.taxCodeNames = {};
        this.depositAccountsNames = {};

        this.restrictedFeature = this.FeatureRestrictionService.features.quickbooks;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('quickbooksIntegrationVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        }.bind(this), true);

        if (this.company.wasFullyFetched()) {
            this.gotCompany();
        }

        // Support for old layout
        this.shouldShowFaq = this.isOldStyle;

        this.register(this.company, 'success', this.gotCompany);
    }


    var QuickbooksIntegrationDirectiveController = Class(Controllers.BaseController, {

        constructor: QuickbooksIntegrationDirectiveControllerCtor,

        connectToQB: function connectToQB() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            this.QuickbooksManager.getQuickbooksAuthUrl().then(function gotQuickbooksAuthUrl(res) {
                window.location.href = res.data.url;
            });
        },

        gotCompany: function gotCompany() {
            this.isLoadingQuickbooks = false;
            this.isCanada = this.company.country === 'CA';
            if (this.company.isConnectedToQuickBooks()) {
                this.connectedSince = this.moment(this.company.quickbooks_integration.connected_at).fromNow();

                this.showTaxCodeLoader = true;
                this.CompaniesManager.getQuickbooksTaxCode(this.company)
                    .then(function gotTaxCodes(res) {
                            this.qbTaxCodes = res.data.available_codes.filter(function filterTaxCodes(taxCode) {
                                var res = taxCode.is_active === true;
                                if (res) {
                                    this.taxCodeNames[taxCode.id] = taxCode.name;
                                }
                                return res;
                            }.bind(this));

                            this.selectedTaxCode = res.data.selected_tax_code || '';
                            this.selectedZeroTaxCode = res.data.selected_zero_tax_code || '';
                        }.bind(this)
                    )
                    .finally(function getQuickbooksTaxCodeFinally() {
                        this.showTaxCodeLoader = false;
                    }.bind(this));

                this.updateDepositAccountInProgress = true;
                this.CompaniesManager.getQuickbooksDepositAccounts(this.company)
                    .then(function getDepositableAccounts(res) {
                        this.depositableAccounts = res.data.available_accounts;
                        this._.each(this.depositableAccounts, function(account) {
                            this.depositAccountsNames[account.id] = account.name;
                        }.bind(this));

                        this.depositAccountId = res.data.account_for_deposit || '';
                    }.bind(this))
                    .finally(function final() {
                        this.updateDepositAccountInProgress = false;
                    }.bind(this));
            }
        },

        onDepositAccountChange: function onDepositAccountChange() {

            if (this.updateDepositAccountInProgress) {
                return;
            }

            var accountName = this.depositAccountsNames[this.depositAccountId];

            this.updateDepositAccountInProgress = true;
            this.AnalyticsService.track(this, 'quickbooks deposit account change', {depositAccountId: this.depositAccountId, depositAccountName: accountName});

            this.CompaniesManager.updateQuickbooksDepositAccount(this.company, this.depositAccountId)
                .catch(function error() {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_');
                }.bind(this))
                .finally(function final() {
                    this.updateDepositAccountInProgress = false;
                }.bind(this));
        },

        onQuickbooksConnected: function onQuickbooksConnected() {
            this.AnalyticsService.trackSuccess(this, 'connect to quickbooks');
            this.CompaniesManager.getCurrCompany(true);
        },

        onQuickbooksDisconnectClick: function onQuickbooksDisconnectClick() {

            if (this.disconnectQuickbooksInProgress) {
                return;
            }

            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, 'BANK_INFO.QUICKBOOKS_SETTINGS_INTEGRATION._DISCONNECT_CONFIRM_').then(function disconnect() {
                this.disconnectQuickbooksInProgress = true;
                this.CompaniesManager.disconnectQuickbooks(this.company).finally(function() {
                    this.UsersManager.saveCurrUser();
                    this.disconnectQuickbooksInProgress = false;
                }.bind(this));
            }.bind(this));
        },

        qbTaxChange: function qbTaxChange() {
            this.AnalyticsService.track(this, 'quickbooks tax code change', {newCode: this.selectedTaxCode, newCodeName: this.taxCodeNames[this.selectedTaxCode]});
            this.CompaniesManager.updateQuickbooksTaxCode(this.company, this.selectedTaxCode || null);
        },

        qbZeroTaxChange: function qbZeroTaxChange() {
            this.AnalyticsService.track(this, 'quickbooks zero tax code change', {newCode: this.selectedZeroTaxCode, newCodeName: this.taxCodeNames[this.selectedZeroTaxCode]});
            this.CompaniesManager.updateQuickbooksZeroTaxCode(this.company, this.selectedZeroTaxCode || null);
        }
    });

    return {
        scope: {
            isOldStyle: '<',
        },
        templateUrl: 'angular/app/modules/core/features/settings/account/quickbooks_integration/quickbooks_integrations_template.html',
        controller: QuickbooksIntegrationDirectiveController,
        controllerAs: 'quickbooksIntegrationVm',
        bindToController: true
    };
};
