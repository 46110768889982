
(function () {
    'use strict';
    class CreateMeetingForm extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, _, moment, UIUtils, AnalyticsService, CompaniesManager,
                    WorkspacesManager, UsersManager, Constants, PopupMessageService, FeatureRestrictionService) {
            super($scope, $injector);
            this.__objectType = 'CreateMeetingForm';
            this.translate = $translate;
            this._ = _;
            this.moment = moment;

            // this.TimezoneService = TimezoneService;
            this.UIUtils = UIUtils;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.CompaniesManager = CompaniesManager;
            this.WorkspacesManager = WorkspacesManager;
            this.UsersManager = UsersManager;
            this.meetingTypes = Constants.MeetingTypes;
            this.videoConferenceTypes = Constants.VideoConferenceTypes;
            this.SERVER_DATE_FORMAT = 'YYYY-MM-DD';
            this.TIME_FORMAT = 'h:mm A';
            this.selectedWorkspaceBind = {};
            this.currUser = this.UsersManager.getCurrUser();
            this.loadingVideoConfLink = false;
            this.meetingConnectedProjectTooltipContent = this.meeting.flow_id
                ? this.translate.instant('CALENDAR.SCHEDULE_MEETING_MODAL._MEETING_CREATED_FROM_FILE_TOOLTIP_')
                : '';

            this.FeatureRestrictionService = FeatureRestrictionService;
            this.zoomRestrictedFeature = this.FeatureRestrictionService.features.zoom;
            this.isZoomFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.zoomRestrictedFeature);
        
            const unsubscribeFeatureRestrictions = $scope.$watch('$ctrl.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
                this.isZoomFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.zoomRestrictedFeature);
            }.bind(this));
        
            $scope.$on("$destroy", function () {
                unsubscribeFeatureRestrictions();
            });

            this.UsersManager.getCalendarProvider(this.currUser)
                .then(function (response) {
                    if (response.data && response.data.calendar_provider && response.data.calendar_provider._type) {
                        if (response.data.calendar_provider._type === 'GoogleCalendarProvider') {
                            this.googleCalendarProvider = response.data.calendar_provider;
                        } else if (response.data.calendar_provider._type === 'NylasCalendarProvider') {
                            // Nylas integration doesn't support Google meet
                            this.nylasCalendarProvider = response.data.calendar_provider;
                            this.videoConferenceTypes = this.videoConferenceTypes.filter(item => item['key'] !== 'google');
                        }
                    }
                }.bind(this));

            this.subFields = {};

            this.initFields();

            this.ANALYTICS_EVENT_PREFIX = 'edit meeting';
            this.meetingFormBinding = this;

            this._original = this.comparableMeetingForm();
        }

        comparableMeetingForm() {
            return {
                title: this.meetingTitle,
                isAllDay: this.isAllDay,
                startDate: this.meetingStartDate,
                startTime: this.meetingStartTime,
                endDate: this.meetingEndDate,
                endTime: this.meetingEndTime,
                meetingType: this.meetingType,
                videoType: this.videoConferenceType,
                videoMeetingLink: this.videoMeetingLink,
                selectedWorkspace: this.selectedWorkspace,
                description: this.meetingDescription,
                location: this.location
            };
        }

        initFields() {
            this.initTitle();
            // this.initTimezone();
            this.initDate();
            this.initMeetingDetails();
            this._initLocation();
        }

        initTitle() {
            this.meetingTitle = this.meeting.title || '';
        }

        // initTimezone() {
        //     this.timezones = this.TimezoneService.getTimezones();
        //     this.timezone = this.isEditMode && this.meeting.timezone ? this.meeting.timezone : this.getDefaultTimezone();
        // }

        initDate() {
            this.initAllDay();
            this.initDates();
            if (!this.meeting.all_day) {
                this.initTime();
            }
            // this.initMarkAsBusy();
        }

        initDates() {
            // create new meeting from calendar
            if (this.meeting.dateTimeStart) {
                this.meetingStartDate = this.meeting.dateTimeStart.format(this.SERVER_DATE_FORMAT);
                this.meetingEndDate = this.meetingStartDate;
            } else if (this.isEditMode) { // update existing meeting
                if (this.meeting.all_day) {
                    this.meetingStartDate = this.moment(this.meeting.date_start).format(this.SERVER_DATE_FORMAT);
                    this.meetingEndDate = this.meeting.date_end ? this.moment(this.meeting.date_end).format(this.SERVER_DATE_FORMAT) : this.meetingStartDate;
                } else {
                    this.meetingStartDate = this.moment(this.meeting.date_time_start).format(this.SERVER_DATE_FORMAT);
                    this.meetingEndDate = this.meeting.date_time_end ? this.moment(this.meeting.date_time_end).format(this.SERVER_DATE_FORMAT) : this.meetingStartDate;
                }
            } else {
                // create new meeting
                this.meetingStartDate = this.moment().format(this.SERVER_DATE_FORMAT);
                this.meetingEndDate = this.meetingStartDate;
            }
        }

        initTime() {
            let startDateTime = null;
            if (this.isEditMode) {
                startDateTime = this.meeting.date_time_start;
            } else if (this.meeting.dateTimeStart) {
                // coming from the calendar
                startDateTime = this.meeting.dateTimeStart;
            } else {
                // default
                startDateTime = this._getHalfHourClosestToNow();
            }
            this.meetingStartTime = this.moment(startDateTime).format(this.TIME_FORMAT);

            let durationUnit = 'minutes';
            let durationValue = 30;
            if (this.meeting.duration) {
                durationUnit = this.meeting.duration.unit_type;
                durationValue = this.meeting.duration.unit_count;
            }

            this.meetingEndTime = this.meeting.date_time_end ? this.moment(this.meeting.date_time_end).format(this.TIME_FORMAT)
                : this.UIUtils.increaseTimeBy(startDateTime, durationUnit, durationValue);
        }

        // initMarkAsBusy() {
        //     this.shouldMarkAsBusy = this.isEditMode && this.meeting.mark_as_busy ? this.meeting.mark_as_busy : false;
        // }

        initAllDay() {
            this.isAllDay = this.meeting.allDay || this.meeting.all_day;
        }

        initMeetingDetails() {
            this.initMeetingType();
            this.initConnectedProject();
            this.initMeetingDescription();
            this.initVideoConference();
            this.initPhoneCall();
        }

        initMeetingType() {
            if (this.meeting.type) {
                if (this.meeting.type === 'meeting') {
                    this.meeting.type = 'other';
                }
                this.meetingType = this.meetingTypes.find((type) => type.key === this.meeting.type);
            }
        }

        initVideoConference() {
            this.initVideoConferenceType();
            this.initVideoLink();
        }

        initVideoConferenceType() {
            if (this.meeting.type === 'video_call') {
                this._showSubField('videoConferenceType');

                const key = this.meeting.video_conference_type || 'other';
                this.videoConferenceType = this.videoConferenceTypes.find(conferenceType => conferenceType.key === key);
            }
        }

        initVideoLink() {
            if (this.meeting.video_conference_type === 'zoom' && this.meeting.video_meeting_id) {
                this.zoomMeetingDetails = {
                    id: this.meeting.video_meeting_id,
                    join_url: this.meeting.video_meeting_link,
                    password: this.meeting.video_meeting_password
                };

                this.zoomMeetingDetailsReady = true;
                this.zoomMeetingId = this.meeting.video_meeting_id;
            }
            else {
                // Google and others
                this.videoMeetingLink = this.meeting.video_meeting_link || '';
            }
        }

        initPhoneCall() {
            if (this.meeting.type === 'phone_call') {
                this._showSubField('phoneCallNumber');
            }

            this.phoneCallNumber = this.meeting.phone_call_number || '';
        }

        _getHalfHourClosestToNow() {
            const now = this.moment();
            if (now.minutes() >= 30) {
                return now.add(30, 'minutes').startOf('hour');
            } else {
                return now.startOf('hour').add(30, 'minutes');
            }
        }

        _initLocation() {
            if (this.meeting.type === 'in_person' || this.meeting.location) {
                this.setLocation(this.meeting.location);
                this._showSubField('location');
            }
        }

        setMeetingType(type) {
            if (type) {
                this.subFields = {};
                this._clearError('type');
                this.AnalyticsService.track(this, [this.ANALYTICS_EVENT_PREFIX, 'type'].join(' '), {
                    source: this.analyticsEventSource,
                    type: type.key
                });
                this.clearMeetingTypeRelatedValues(type);
                this.meetingType = type;
                if (type.subField) {
                    this._showSubField(type.subField);
                }
            }
        }

        clearMeetingTypeRelatedValues(newType) {
            if (!this.meetingType || this.meetingType.key !== newType.key) {
                this.phoneCallNumber = '';
                this.videoMeetingLink = '';
            }
        }

        setVideoConferenceType(type) {
            if (type) {
                this._clearError('videoConferenceType');
                this._clearError('videoConferenceLink');
                this.analyticsService.track(this, [this.ANALYTICS_EVENT_PREFIX, 'videoConferenceType'].join(' '), {
                    source: this.analyticsEventSource,
                    type: type.key
                });

                if (!this.videoConferenceType || type.key !== this.videoConferenceType.key) {
                    this.videoConferenceType = type;
                    this.videoMeetingLink = '';

                    if (type.key === 'zoom' && this.zoomAuthProvider && !this.zoomMeetingId) {
                        this.createNewZoomMeeting();
                    }
                    else if (this.zoomMeetingId && this.zoomAuthProvider) {
                        // We will cancel on save
                        // this.cancelZoomMeeting();
                    }
                }
            }
        }

        _showSubField(subFieldType) {
            this.subFields[subFieldType] = true;
        }

        _clearError(type) {
            if (this.errors[type]) {
                delete this.errors[type];
            }
        }

        setLocation(location) {
            this.meetingLocation = location;
        }

        initConnectedProject(selectedWorkspace) {
            this.hasActiveProjects = this.WorkspacesManager.getPipelineWorkspacesCounts([this.UsersManager.getCurrUser()._id]).then((resp) => {
                if (resp.data.category_booked_counts.active || resp.data.category_lead_counts.active || resp.data.category_other_counts.active) {
                    this.hasActiveProjects = true;
                } else {
                    this.hasActiveProjects = false;
                }
            }).catch((error) => {
                this.hasActiveProjects = false;
            });

            if (selectedWorkspace) {
                this.selectedWorkspace = selectedWorkspace;
            } else if (this.meeting.workspace_id) {
                this.selectedWorkspace = {
                    _id: this.meeting.workspace_id,
                    properties: {
                        event_id: this.meeting.event_id,
                        event_name: this.meeting.event_name
                    }
                };
            } else {
                this.selectedWorkspace = null;
            }
        }

        setConnectedProject(selectedWorkspace) {
            if (selectedWorkspace && selectedWorkspace.item) {
                this.selectedWorkspace = selectedWorkspace.item;
            } else if (selectedWorkspace) {
                this.selectedWorkspaceBind.handleWorkspaceItemSelected(selectedWorkspace);
            }
        }

        initMeetingDescription() {
            this.meetingDescription = this.meeting.description;
        }

        // getDefaultTimezone() {
        //     return this.TimezoneService.translateFromServer(this.CompaniesManager.getCurrCompany().default_timezone) || this.timezones[0];
        // }

        hasPendingChanges() {
            // if in edit mode, check for pending changes
            // otherwise it's new and addressed as changed
            return !this.isEditMode || !this._.isEqual(this._original, this.comparableMeetingForm());
        }

        onSignInClick(type) {
            if (this.isZoomFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.zoomRestrictedFeature, { isChildModal: true });
                return;
            }
            // if there are pending changes, prompt a message
            this.openSyncModal({hasPendingChanges: this.hasPendingChanges(), type: type});
        }

        createNewZoomMeeting() {
            if (this.isZoomFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.zoomRestrictedFeature, { isChildModal: true });
                return;
            }
            var self = this;
            self._clearError('videoConferenceLink');
            self.loadingVideoConfLink = true;
            self.currUser.getZoomVideoConferencingLink(self.currUser).then((res) => {
                self.zoomMeetingDetails = res.data;
                self.zoomMeetingDetailsReady = true;
                self.zoomMeetingId = self.zoomMeetingDetails.id;
            }).catch(function fail(resp) {
                if (resp && resp.data && resp.data.error_type && resp.data.error_type === 'HBZoomTokenRevokedError') {
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'BANK_INFO.ZOOM_SETTINGS_INTEGRATION._REVOKED_ERROR_');
                }
                self.errors['videoConferenceLink'] = true;
            }).finally(() => {
                self.loadingVideoConfLink = false;
            });
        }

        cancelZoomMeeting() {
            var self = this;
            self.loadingVideoConfLink = true;
            self.currUser.cancelZoomVideoConferencingLink(self.currUser, self.zoomMeetingDetails.id, self.meeting._id).then((res) => {
                // TBD
            }).catch(function fail(resp) {
                // TBD
            }).finally(() => {
                self.zoomMeetingDetails = null;
                self.zoomMeetingDetailsReady = false;
                self.loadingVideoConfLink = false;
                self.zoomMeetingId = null;
            });
        }
    }

    Components.CreateMeetingForm = {
        bindings: {
            meeting: '<?',
            meetingFormBinding: '=',
            analyticsEventSource: '@',
            isEditMode: '<',
            errors: '<?',
            showCreateProject: '&?',
            zoomAuthProvider: '<?',
            openSyncModal: '&?',
            zoomMeetingId: '='
        },
        controller: CreateMeetingForm,
        name: 'hbCreateMeetingForm',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/create_meeting_form.html'
    };
}());