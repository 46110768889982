
// @ngInject
Filters.AbsoluteUrlFilter  = function AbsoluteUrlFilter() {
    return function(url) {
        url = ( url === undefined || url === null ) ? '' : url;
        if (url.lastIndexOf('http') == 0) {
            return url;
        }else {
            return 'http://'+url;
        }
    };
};