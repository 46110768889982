(function () {
    'use strict';

    class SignupInputController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            _,
            $timeout,
            DeviceService,
            $,
            $sce
        ) {
            super($scope, $injector);
            this.__objectType = 'SignupInputController';

            this.$timeout = $timeout;
            this.DeviceService = DeviceService;
            this.$ = $;
            this.$sce = $sce;

            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.lastValueOfcurrInput = '';
            this.validateInputDebounced = _.debounce(this.validateInput, 500);

            if (this.input.prefillValue) {
                this.input.answer = this.input.prefillValue;
            }

            this.coverPhotoOptions = [
                'trees',
                'flowers',
                'succulent',
                'lamp',
                'coffee',
                'camera',
            ];

            $scope.$watch('$ctrl.input.noResults', (isTypeaheadNoResults, prevTypeaheadNoResults) => {
                if (isTypeaheadNoResults !== prevTypeaheadNoResults) {
                    if (isTypeaheadNoResults) {
                        angular.isFunction(this.input.onNoResults) && this.input.onNoResults();
                    } else {
                        angular.isFunction(this.input.onHasResults) && this.input.onHasResults();
                    }
                }
            })
        }

        onInputChange($item, isTypeaheadChange) {
            this.validateInputDebounced();
            if (isTypeaheadChange) {
                this.handleTypeaheadOnSelect();
            }
            if (this.input.onChange && angular.isFunction(this.input.onChange)) {
                this.input.onChange($item || {text: this.input.answer});
            }

        }

        onInputFocus() {
            this.signupHandleFocus();
            this.input.hasFocus = true;
            this.lastValueOfcurrInput = this.input.answer;
        }

        onInputBlur() {
            this.$timeout(() => {
                this.input.hasFocus = false;
            });

            if (this.lastValueOfcurrInput !== this.input.answer) {
                this.validateInput();
            }
        }

        getLabeltext() {
            if (
                this.isMobile
                && this.input.labelOnFocus
                && (
                    this.input.answer !== ''
                    && !this.input.valid
                    ||  this.input.hasFocus
                    || this.input.valid
                    && this.input.answer
                )
            ) {
                return this.input.labelOnFocus;
            } else if (
                this.isMobile
                && !this.input.valid
                && this.input.answer !== ''
            ) {
                return this.input.textToShowOnTooltip;
            } else {
                return this.input.label
            }

        }

        validateInput() {
            if (this.input.answer) {
                this.input.isValid()
                    .then(()=> {
                        this.currentStage.isValid();
                        this._setValidityOnInput(true)
                    })
                    .catch( (message) => {
                        this.currentStage.isValid();
                        this._setValidityOnInput(false, message);
                    });
            } else {
                this.currentStage.isValid();
                this._setValidityOnInput(true)
            }

        }

        _setValidityOnInput(isValid, message) {
            if (isValid) {
                this.input.valid = true;
                if (this.isMobile) {
                    this.input.textToShowOnTooltip = this.input.tooltip;
                } else {
                    this.$timeout(() => {
                        this.input.textToShowOnTooltip = this.input.tooltip;
                        this.input.expressionToShowOnTooltip = this.input.expression;
                    }, 200);
                }

            } else {
                this.input.setInputError(message);
            }
        }

        togglePasswordVisibility(e) {
            e.stopPropagation();
            e.preventDefault();

            this.input.toggle();

            this.input.hasFocus = true;

            if (this.input.hideText) {
                this.input.expression = "hiding";
                this.input.expressionToShowOnTooltip = this.input.expression;
            } else {
                this.input.expression = "none";
                this.input.expressionToShowOnTooltip = this.input.expression;
            }
        }

        handleTypeaheadOnSelect() {
            this.input.shouldNotContinueOnEnter = true;

            this.$timeout(() => {
                // This is a hack to clear the typeahead
                // dropdown after 'click' select
                angular.element('body').click();
            }, 10);

            this.$timeout(() => {
                this.input.shouldNotContinueOnEnter = false;
            }, 250);
        }

        onKeyUp(e) {
            var keyCode = e.keyCode || e.which;
            this.keyUpEvent({keyCode, index: this.currTabindex - 1 });
        }

        trustSrc(src) {
            return this.$sce.trustAsResourceUrl(src);
        }

        onLogoSelected($files) {
            this.logoFiles = $files;
            if ($files[0]) {
                this.logoFileUrl = URL.createObjectURL($files[0]);
            }
        }

        makeCloudinaryPublicId(key, isThumbnail) {
            return `app/signup/example-file/${key}${(isThumbnail ? '-thumbnail' : '')}`
        }

        generateCloudinarySrc(key, isThumbnail) {
            const publicId = this.makeCloudinaryPublicId(key, isThumbnail);
            var img = this.$.cloudinary.image(publicId, {
                cloud_name: 'honeybook',
                secure: true,
                transformation: [
                    {
                        flags: 'lossy',
                        quality: 'auto',
                        fetch_format: 'auto'
                    }
                ]
            });

            return img[0].src;
        }

        onCoverSelected(selectedImageKey) {
            this.coverImageKey = selectedImageKey;
            this.defaultCoverImageCloudinaryPublicId =
                this.makeCloudinaryPublicId(selectedImageKey);
        }

    }

    Components.SignupInput = {
        bindings: {
            input: '=',
            currentStage: '=',
            currTabindex: '=',
            logoFiles: '=',
            defaultCoverImageCloudinaryPublicId: '=',
            keyUpEvent: '&',
            signupHandleFocus: '&',
        },
        controller: SignupInputController,
        name: 'hbSignupInput',
        templateUrl: 'angular/app/modules/core/features/signup/signup_input/signup_input_component.html',
    };

}());