(function () {
    'use strict';

    // @ngInject
    function DebitCardControllerCtor($scope, $injector, $modalInstance, $timeout, $q, $translate,
                                     AnalyticsService, StripeHelperService, CompaniesManager, UsersManager, source) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'DebitCardController';
        this.$modalInstance = $modalInstance;
        this.CompaniesManager = CompaniesManager;
        this.StripeHelperService = StripeHelperService;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;

        this.source = source;
        this.isBookkeeping = this.source === 'bookkeeping';
        this.title = 'COMPANY_SETTING.BANK_ACCOUNT.DEBIT_CARD_MODAL._TITLE_';
        this.description = 'COMPANY_SETTING.BANK_ACCOUNT.DEBIT_CARD_MODAL._DESCRIPTION_';
        this.company = CompaniesManager.getCurrCompany();
        this.user = this.UsersManager.getCurrUser();
        this.showCardError = false;
        this.loadingCard = false;
        this.enableTwoFactorAuth = !this.user.isMalkut();
        this.onTwoFactorAuth = false;

        this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.debit_card_modal, {source: this.source});
    }

    Controllers.DebitCardController = Class(Controllers.BaseController, {
        constructor: DebitCardControllerCtor,

        onSubmit: function onSubmit() {
            if (this.enableTwoFactorAuth) {
                this.onTwoFactorAuth = true;
            } else {
                this.addDebitCard();
            }
        },

        onSetVerificationCode: function onSetVerificationCode(code, justClose) {
            this.onTwoFactorAuth = false;
            if (!justClose) {
                this.addDebitCard(code);
            } 
        },

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },

        addDebitCard: function addDebitCard(code){
            this.addCardError = null;
            this.showCardError = false;
            this.loadingCard = true;


            this.StripeHelperService.getStripeTokenNew(this.tokenProviderId).then(
                function onSuccess(creditCard) {
                    // new card
                    if (!creditCard || !creditCard.id) {
                        this._handleCardError('Error adding a debit card. Please try again');
                    } else if (!creditCard.card.funding.includes("debit")) {
                        this._handleCardError('Please enter a valid debit card');
                    } else {
                        const debitCard = angular.extend(creditCard, {
                            id: null,
                            token_id: creditCard.id,
                            card_role: 'instant_payout',
                            last4: creditCard.card.last4,
                            name: creditCard.card.name,
                            code: code
                        });

                        this.user.addPaymentMethod(debitCard).then(
                            function onSuccess(resp) {
                                this.loadingCard = false;
                                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.add_debit_card, {source: this.source});
                                this.$modalInstance.close();
                            }.bind(this)
                        ).catch(
                            function onFailure(resp) {
                                if(resp && resp.data && resp.data.error_message) {
                                    this._handleCardError(resp.data.error_message);
                                } else {
                                    this._handleCardError();
                                }
                            }.bind(this));
                    }
                }.bind(this)
            ).catch(function onFailure(resp){
                if(resp && resp.data && resp.data.error_message) {
                    this._handleCardError(resp.error.message);
                } else {
                    this._handleCardError();
                }
            }.bind(this));
        },

        _handleCardError: function _handleCardError(error) {
            this.addCardError = error || 'COMPANY_SETTING.BANK_ACCOUNT.DEBIT_CARD_MODAL._DEFAULT_CARD_ERROR_';
            this.showCardError = true;
            this.loadingCard = false;
            this.AnalyticsService.trackError(this,
                this.AnalyticsService.analytics_events.add_debit_card, null, {errorMessage: error});
        }
    });
}());
