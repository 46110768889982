(function () {
    "use strict";


    // @ngInject
    function MemberReferralsManagerCtor(APIService, Routes, $q, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.ModelFactory = ModelFactory;
    }

    Services.MemberReferralsManager = Class(Services.BaseModelManager, {

        constructor: MemberReferralsManagerCtor,

        getUserReferrals: function getUserReferrals(user, referrals, status, skip, limit) {
            var data = {
                referrer_user_id: user._id,
                status: status,
                skip: skip,
                limit: limit
            };
            var url = this.Routes.v2_user_referrals_path();

            return this.apiFetchCollection(url, data, referrals, true, null, false, true, true);
        },

        getUserReferralStats: function getUserReferralStats(user) {
            var data = {
                referrer_user_id: user._id,
            };
            var url = this.Routes.v2_user_referral_stats_path();

            return this.apiFetchCollection(url, data);
        },

        // generateNewCustomShortCode: function generateNewCustomShortCode(inviter) {
        //     var url = this.Routes.v2_generate_new_custom_short_code_path();
        //     var data = {
        //         referrer_user_id: inviter._id,
        //         promo_code: promoCode
        //     };
        //     return this.apiFetch(url, data);
        // },
        referUsersByEmail: function referUsersByEmail(inviter, emails, referrals, message) {
            var data = {
                emails: emails,
                referrer_user_id: inviter._id,
                message: message,
            };
            var url = this.Routes.v2_refer_users_by_email_path();
            
            var promise = this.apiCreate(url, data);
            promise.then(function updateReferrals(resp) {
                if (referrals) {
                    referrals.mixinFrom(resp.data, null, false, true, true);
                }
            });
            return promise;
        },

        getReferralsByIds: function getReferralsByIds(referralsCollection, referralIds) {
            var data = {
              referral_ids: referralIds
            };
            var url = this.Routes.v2_referrals_by_ids_path();
            var promise = this.apiFetch(url, data);
            promise.then(function success(resp) {
                resp.data.forEach(function (object) {
                    var obj = referralsCollection.find(function(referral){
                        return object._id === referral._id;
                    });
                    obj.mixinFrom(object, null, false, true);
                });
            });
            return promise;
        },

        getPendingCommissionReferrals: function getPendingCommissionReferrals(filterOutNonMembers, page, per_page, sortBy, searchParams) {
            var data = searchParams || {};
            data.page = page;
            data.per_page = per_page;
            data.sort_by = sortBy;
            data.filter_out_non_members = filterOutNonMembers;

            var url = this.Routes.v2_pending_commission_referrals_path();
            return this.apiFetch(url, data);
        },

        getPendingCommissionPayoutReferrals: function getPendingCommissionPayoutReferrals(pendingCommissionPayoutReferrals) {
            var data = {};
            var url = this.Routes.v2_pending_commission_payout_referrals_path();
            var promise = this.apiFetchCollection(url, data);
            promise.then(function success(resp) {
                pendingCommissionPayoutReferrals.mixinFrom(resp.data, null, false, true, true, true);
            });
            return promise;
        },

        getCampaignDetails: function getCampaignDetails(user, campaignModel, campaignUid) {
            var data = {
                user_id: user._id,
                campaign_uid: campaignUid // isn't used on server..
            };
            var url = this.Routes.v2_campaign_details_path();

            // cache this request as a hotfix
            var isDifferentCacheUser = !this.cachedUserId || this.cachedUserId !== user._id;
            if (!this.cachedResponse || isDifferentCacheUser) {
                this.cachedResponse = this.apiFetch(url, data, campaignModel, true);
                this.cachedUserId = user._id;
            }

            return this.cachedResponse;
        },

        sendReferralReminder: function sendReferralReminder(user, referral) {
            var data = {
                user_id: user._id,
                referral_id: referral._id
            };

            var url = this.Routes.v2_send_referral_reminder_path();
            return this.apiUpdate(url, data, referral, true);
        },

        getReferralSuggestions: function getReferralSuggestions(user, includeGoogleContacts) {
            var data = {
                user_id: user._id,
                include_google_contacts: includeGoogleContacts
            };
            var url = this.Routes.v2_referral_suggestions_path();
            return this.apiFetch(url, data);
        },

        createReferral: function createReferral(referrerEmail, referredEmail, source, status, shouldSendEmail) {
            var referral = this.ModelFactory.newModel('MemberReferralModel');
            var url = this.Routes.v2_create_referral_path();
            var data = {
                referrer_email: referrerEmail,
                referred_email: referredEmail,
                source: source,
                status: status,
                send_email: shouldSendEmail
            };
            this.apiCreate(url, data, referral, true);
            return referral;
        },
        
        updatePersonalInviteMessage: function updatePersonalInviteMessage(campaign, message) {
            var data = {
                message: message,
            };
            var url = this.Routes.v2_update_personal_invite_message_path();
            return this.apiUpdate(url, data, campaign, true, null, 'waitForLast');
        },

        updatePreferedCoupon: function updatePreferedCoupon(campaign, newCampaignId) {
            var data = {
                coupon_code: newCampaignId
            };
            var url = this.Routes.v2_update_coupon_code_path();
            return this.apiUpdate(url, data, campaign, true);
        },

        getReferralCode: function getReferralCode(code) {
            var url = this.Routes.v2_get_referral_code_path(code);
            return this.apiFetch(url);
        }
    });
}());