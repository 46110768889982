(function () {

    "use strict";

    Controllers.InvoiceController = class InvoiceController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, DeviceService) {
            super($scope, $injector);
            this.__objectType = 'InvoiceController';

            this.DeviceService = DeviceService;
            this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
        }
    };
}());
