(function () {
    'use strict';

    class CreateFlowMenu extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $stateParams, $q, ModalService, EventsManager, UsersManager, WorkspaceFileService, WorkspacesManager, DeviceService,
            UIUtils, PhotosUploadManager, AnalyticsService, PopupMessageService, FlowsBetaUserTypeService, _, OtamStatusService, ToastService, UiPersistenceService, FeaturesService, StatsigService, Enums) {
            super($scope, $injector);
            this.ModalService = ModalService;
            this.project = EventsManager.getEvent($stateParams.event_id);
            this.DeviceService = DeviceService;
            this.WorkspaceFileService = WorkspaceFileService;
            this.workspaceId = $stateParams.workspace_id;
            this.$q = $q;
            this.ToastService = ToastService;
            this.UIUtils = UIUtils;
            this.UsersManager = UsersManager;
            this.PhotosUploadManager = PhotosUploadManager;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this._ = _;
            this.WorkspacesManager = WorkspacesManager;
            this.UiPersistenceService = UiPersistenceService;
            this.OtamStatusService = OtamStatusService;
            this.FeaturesService = FeaturesService;
            this.Enums = Enums;
            this.StatsigService = StatsigService;

            this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceId);
            this.currentUser = UsersManager.getCurrUser();
            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            this.shouldApplyBranding = (!this.isVendor() && !this.workspace.isViewOnlyMode() && !this.workspace.isArchived() && this.uploadEnabled());
            this.uploadLimit = 100;
            this.createFileInProgress = false;
            this.isFileDropdownOpen = !this.isMobile;
            this.eventVm = $scope.$parent.$parent.eventVm;

            this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.recurringInvoiceDiscoverability,  'is_enabled', false)
                .then(function (variant) {
                    this.hasRecurringInvoiceDiscoverability = variant;
                }.bind(this));

            // ng2react - create file menu
            this.createFileButtonReacty = false;
            this.StatsigService.isGateEnabled('workspace_create_file_menu').then(function (result) {
                this.createFileButtonReacty = result;
            }.bind(this));

        }

        $onChanges(changes) {
            if (!changes.isOpen.currentValue) {
                this.isFileDropdownOpen = this.FlowsBetaUserTypeService.hasFilesAndFlows;
            }
        }

        isAllowedToPayUser() {
            return this.currentUser.isHoneyBookPaying();
        }

        uploadEnabled() {
            return this.workspace.totalAttachments() < this.uploadLimit;
        }

        onCreateFlow(filters) {
            this.onClose();
            this.ModalService.openCreateFlowModal(this.project, this.currentUser.company, filters);
        }

        toggleIsFileDropdownOpen() {
            this.isFileDropdownOpen = !this.isFileDropdownOpen;
        }

        // =====================
        // Create File
        // =====================
        createFileByType(fileType) {
            // if file creation in progress we step out...
            if (this.createFileInProgress) {
                return;
            }
            if ((this.isMobile && fileType !== 'flow') || fileType === 'timeline') {
                this.createEmptyFileByType(fileType);
            } else {
                this.openCreateFileModal(fileType);
            }
        }

        createEmptyFileByType(fileType) {
            // if file creation in progress we step out...
            if (this.createFileInProgress) {
                return;
            }

            // call create
            var promise = this.WorkspaceFileService.createFile(fileType, this.workspace, null, null, null, false);
            this.createFileInProgress = true;
            promise.finally(function createFileDone() {
                this.createFileInProgress = false;
            }.bind(this));
        }

        openCreateFileModal(fileType) {
            this.ModalService.openStartFromFileWizardModal({ type: fileType }, this.workspace);
        }

        isVendor() {
            return this.UIUtils.isVendor();
        }

        uploadEnabled() {
            return this.workspace.totalAttachments() < this.uploadLimit;
        }

        openUploadFileModal() {
            if (!this.uploadEnabled()) {
                return;
            }

            this.ModalService.openAttachAssetModal(false, this.currentUser.isVendor(), true, 'Upload',
                true, true, false, null, false, false, '', '', '', 'MEDIA_MODAL.TITLE._ATTACH_IMG_OR_FILE_', '', this.shouldApplyBranding).then(function (filesList) {
                    var promises = [];
                    var files = [];

                    if (this.workspace.totalAttachments() + filesList.length > this.uploadLimit) {
                        var rejected = this.$q.reject({
                            error_message: 'WORKSPACE.FILES._ATTACHMENTS_LIMIT_REACHED_',
                            from_client: true
                        });
                        promises.push(rejected);
                    }

                    filesList.forEach(function (file) {
                        var fileType = (/\.(gif|jpe?g|tiff|png)([?#].*)?/i.test(file.url || file.name)) ? "image" : "file";

                        if (!file.url) {
                            //start to upload these files right away. save the promises of the uploads
                            //because the send message will have to wait for them to finish till they upload
                            var model = {
                                instance: this.workspace,
                                type: 'workspace_attachment',
                                dontUpdateServer: true
                            };

                            this.PhotosUploadManager.getUpdatedModelData(model);
                            var fileUploadPromise = this.PhotosUploadManager.uploadPhoto([file], model, this);

                            promises.push(fileUploadPromise);
                        }

                        var fileName = file.name || file.url.split('\\').pop().split('/').pop();
                        var fileExt = fileName.split('.')[1];

                        this.workspace.attachments = this.workspace.attachments || { images: [], files: [] };

                        if (fileType === 'image') {
                            this.workspace.attachments.images.unshift({
                                inUpload: true,
                                url: file.url,
                                dataUrl: file.dataUrl
                            });
                        }
                        else {
                            this.workspace.attachments.files.unshift({
                                inUpload: true,
                                name: fileName,
                                file_type: fileExt
                            });
                        }

                        files.push(file);
                    }.bind(this));

                    this.$q.all(promises).then(function () {
                        var filesData = this._.map(files, function (file) {

                            if (file.url || file.file_url) {
                                var translatedItem = {
                                    url: file.url || file.file_url,
                                    asset_name: file.asset_name || file.file_name,
                                };
                                if (file.cloudinary_public_id) {
                                    translatedItem.cloudinary_public_id = file.cloudinary_public_id;
                                }

                                return translatedItem;
                            } else if (file.s3Response) {
                                return {
                                    url: file.s3Response.url,
                                    cloudinary_public_id: file.s3Response.cloudinary_public_id
                                };
                            }
                        });

                        this.WorkspacesManager.addAttachments(this.workspace, filesData).then(function success() {
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.workspace_attachments_uploaded, {
                                filesUploaded: files.length,
                                workspace_id: this.workspace._id
                            });
                        }.bind(this));

                    }.bind(this))
                        .catch(function (resp) {
                            if (resp.from_client) {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, {
                                    translationId: resp.error_message,
                                    interpolateParams: {
                                        limit: this.uploadLimit
                                    }
                                });
                            }
                            else if (resp.data) {
                                var data = resp.data;
                                if (data.error_type === 'HBWorkspaceAttachmentsLimitError') {
                                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                                }
                                else {
                                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                                }
                            }

                            this.workspace.attachments.files = this._.reject(this.workspace.attachments.files, function (file) {
                                return file.inUpload;
                            });
                            this.workspace.attachments.images = this._.reject(this.workspace.attachments.images, function (file) {
                                return file.inUpload;
                            });

                        }.bind(this));
                }.bind(this));
        }
    }

    Components.CreateFlowMenuComponent = {
        bindings: {
            isOpen: '<',
            onClose: '&'
        },
        controller: CreateFlowMenu,
        name: 'hbCreateFlowMenu',
        templateUrl: 'angular/app/modules/core/features/workspace/create_flow_menu/create_flow_menu.html',
    };
}());
