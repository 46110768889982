Components.ReportMetric = {
  bindings: {
    value: '<',
  },
  // @ngInject
  controller: function($scope, $element, $attrs, $translate) {
    this.label = $translate.instant($attrs.label);
    this.value = $attrs.value;
  },
  name: 'hbReportMetric',
  templateUrl: 'angular/app/modules/common/ui_components/report/report_metric_component.html',
};
