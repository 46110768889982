//******************************************************
// A simple service that is used to register models for the saving indicator
//******************************************************
(function () {
    "use strict";

    // @ngInject
    function SavingIndicatorServiceCtor(PubSubService) {
        PubSubService.ventMyBitchUp(this);
        this.models = [];
        this.modelEvents = ['success', 'error', 'update'];
    }

    Services.SavingIndicatorService = Class(function () {

        return {

            constructor: SavingIndicatorServiceCtor,

            registerModel: function registerModel(model) {
                this.models = this.models.concat(model);
                angular.forEach(this.modelEvents, function(event){
                    model.on(event, function(){
                        this.trigger(event);
                    }.bind(this));
                }.bind(this));
            },

            unregisterModel: function unregisterModel(model) {
                var index = this.models.indexOf(model);
                if (index > -1) {
                    this.models.splice(index, 1);
                }
            }
        };
    });
}());