(function () {
    'use strict';

    Directives.ImageCarouselReposition = function ImageCarouselReposition() {

        var MODES = {
            CLOUDINARY: 'cloudinary',
            OLD: 'old'
        };

        // @ngInject
        function ImageCarouselRepositionControllerFunc($scope, $timeout, ImagesManager, $log,
                                                        AnalyticsService, ModalService) {
            var self = this;
            this.ImagesManager = ImagesManager;
            this.$log = $log;

            this.currentIndex = 0;
            this.repositionMode = false;
            this.ModalService = ModalService;

            this.imageDisplayRatio = parseFloat(this.imageRatio) || 2.5;
            this.showPreviewWhenCroppingDisplay = (this.showPreviewWhenCropping === 'true');

            this.imageChangeIsSaving = false;

            this.enableCarousel = this.images.length < 50;


            $scope.$on('triggerProjectHeader', function () {
                this.chooseCoverImage();
            }.bind(this));


            this.setDomElement = function setDomElement(elem) {
                this.$element = elem;
            };

            this._load = function _load() {
                var index = null;
                this.slickImages = [];
                if (this.images) {
                    this.images.forEach(function imagesIteration(image, idx) {
                        if (this.selectedImage.original_url === image.url || this.selectedImage._id === image._id) {
                            index = idx;
                        }

                        var newImage = {};
                        angular.extend(newImage, image);
                        this.slickImages.push(newImage);
                    }.bind(this));
                }

                // Check if we found the selected image in the array of images
                if (index !== null) {
                    // Moving the found image to be the first image in the array
                    this.slickImages.splice(index, 1);
                    this.slickImages.unshift(this.selectedImage);
                } else {
                    // inserting a dummy image into the array and we will remove it once the the user changes the selected image.
                    // this can happen when the user removed an image from his cover images.
                    this.slickImages.unshift(this.selectedImage);
                    this.initialImage = this.selectedImage;
                }

                if (!this.enableCarousel) {
                    this.slickImages.splice(1);
                }

                this.lastSavedIndex = 0; //the selected image is always at index 0 after initialization

                // We're ready to initialize the slick.
                // We're using apply async so all the bindings (especially this.slickImages) will take affect before the carousel loads
                $scope.$applyAsync(function () {
                    // this.getSlickContainer().on('beforeChange', this.beforeImageChanged.bind(this));
                    // this.getSlickContainer().on('afterChange', this.selectedImageChanged.bind(this));

                    this.getSlickContainer().slick({
                        onBeforeChange: this.beforeImageChanged.bind(this),
                        onAfterChange: this.selectedImageChanged.bind(this),
                        nextArrow: this.$element.find('.hb-slick-next'),
                        prevArrow: this.$element.find('.hb-slick-prev'),
                        initialIndex: index || 0
                    });

                    //create a hook on top of the original funciton that changes the slides to disable the next an previous when saving
                    var slickInstance = this.getSlickContainer()[0].slick;
                    var origSlide = slickInstance.changeSlide;

                    //function that conditions the readding of slide functionality
                    slickInstance.changeSlide = function(event, dontAnimate) {
                        if (self.analyticsSource) {
                            AnalyticsService.track(this, AnalyticsService.analytics_events.image_changed_on_carousel, self.analyticsSource);
                        }

                        if ( (event.data.message === 'next' || event.data.message === 'previous') && self.imageChangeIsSaving) {
                            //do nothing. prevent the normal execution of the function
                            return;
                        }
                        origSlide(event, dontAnimate);
                    };
                    slickInstance.initArrowEvents(); //this will make the make changeSlide override work.

                    if (this.variableHeightImg){
                        this.setCarouselHeightToActiveImgHeight();
                    }
                }.bind(this));
            };

            this.checkLoad = function checkLoad() {
                if (!this.loaded) {
                    if (this.images) {
                        if (this.checkImagesLoad() && this.checkSelectedImageLoad()) {
                            this.loaded = true;
                            this._load();
                        }
                    } else if (this.hideButtons && this.checkSelectedImageLoad()) { // readonly mode
                        this.loaded = true;
                        this._load();
                    }

                }
            };

            this.checkImagesLoad = function checkImagesLoad() {
                return !!this.images;
            };

            this.checkSelectedImageLoad = function checkSelectedImageLoad() {
                return !!this.selectedImage && !!this.selectedImage._id;
            };

            this.beforeImageChanged = function beforeImageChanged(slick, currentSlide, nextSlide) {
                this.lastIndex = currentSlide;
                this.changingImage = true;
                if (this.imageChangedTimeout) { //we have an image change callback waiting to fire
                    $timeout.cancel(this.imageChangedTimeout);
                }
            };

            this.selectedImageChanged = function selectedImageChanged(slick, newIndex) {
                this.changingImage = false;
                if (this.ignoreChange) { //we changed back to a previous image after saving failed. no need to update change
                    this.ignoreChange = false;
                    return;
                }
                $scope.$apply(function () {
                    this.selectedImage = this.slickImages[newIndex];
                    if (this.initialImage && this.initialImage._type !== 'CoverImage') {
                        this.slickImages.splice(this.lastIndex, 1);
                        slick.removeSlide(this.lastIndex, undefined, undefined, newIndex - 1);
                        this.initialImage = null;
                    }
                    if (this.variableHeightImg){
                        this.setCarouselHeightToActiveImgHeight();
                    }
                }.bind(this));

                this.imageChangedTimeout = $timeout(function() {
                    // waiting for the 'this.selectedImage' changes to be applied.
                    // So we're applying a new scope here...
                    this.imageChangedTimeout = null;
                    var promise = this.imageChanged({type: 'switch', selectedImage: this.selectedImage});
                    if (promise && promise.then) { //check that return value is indeed a promise object
                        this.imageChangeIsSaving = true; //this flag is used to hide the swipe buttons when a change is being saved
                        promise.then(
                            function success() {
                                this.imageChangeIsSaving = false;
                                this.lastSavedIndex = slick.getCurrent();
                            }.bind(this),
                            function error() {
                                this.ignoreChange = true;
                                slick.slideHandler(this.lastSavedIndex, true, true);
                                this.selectedImage = this.slickImages[this.lastIndex];
                                this.imageChangeIsSaving = false;
                            }.bind(this)
                        );
                    }
                }.bind(this), 1000);
            };

            this.getSelectedImageElement = function getSelectedImageElement() {
                return this.$element.find('.reposition img');
            };

            this.getImageContainerElement = function getImageContainerElement() {
                return this.$element;
            };

            this.getSlickContainer = function getSlickContainer() {
                return this.$element.find('.slickContainer');
            };

            this.editImagePosition = function editImagePosition() {
                this.repositionMode = true;
                $timeout(function showOverlayImage() {
                    var $container = this.getImageContainerElement();
                    var $image = this.getSelectedImageElement();
                    $image.attr('src', this.selectedImage.url);
                    this.initial_pos = {
                        top: $image.css('top'),
                        left: $image.css('left')
                    };
                    this.pos = {
                        top: this.initial_pos.top,
                        left: this.initial_pos.left
                    };
                    var max_top = 0;
                    var min_top = $container.height() - $image.height();
                    var max_left = 0;
                    var min_left = $container.width() - $image.width();
                    $image.draggable({
                        scroll: false,
                        axis: "xy",
                        drag: function (event, ui) {
                            ui.position.left = Math.min(ui.position.left, max_left);
                            ui.position.left = Math.max(ui.position.left, min_left);
                            ui.position.top = Math.min(ui.position.top, max_top);
                            ui.position.top = Math.max(ui.position.top, min_top);
                        }.bind(this),
                        stop: function (event, ui) {
                            this.pos.top = ((ui.position.top / $container.height()) * 100) + '%';
                            this.pos.left = ((ui.position.left / $container.width()) * 100) + '%';
                        }.bind(this)
                    });
                }.bind(this));
            };

            this.closeReposition = function closeReposition(keepPosition) {
                this.getSelectedImageElement().draggable('destroy');
                this.repositionMode = false;

                if (!keepPosition) {
                    this.getSelectedImageElement().css({
                        top: this.initial_pos.top,
                        left: this.initial_pos.left
                    });
                }
            };

            this.save = function save() {
                this.closeReposition(true);

                // Update model with new position
                this.selectedImage['position_top'] = this.pos.top;
                this.selectedImage['position_left'] = this.pos.left;

                // Save the changes to the server
                this.ImagesManager.saveImageReposition(this.selectedImage);
            };

            this.chooseCoverImage = function chooseCoverImage() {
                if (this.changingImage) {
                    $timeout(chooseCoverImage.bind(this));
                    return;
                }

                //make that modal go.
                //this.ModalService.openCropImageModal(this.selectedImage, this.imageDisplayRatio, this.showPreviewWhenCroppingDisplay).then(
                this.ModalService.openAttachAssetModal(true, true, true, "Crop", false, false,
                    this.selectedImage, this.imageDisplayRatio, this.showPreviewWhenCroppingDisplay, false, "image/*", '', '', 'MEDIA_MODAL.TITLE._EDIT_IMAGE_').then(
                    
                    function success(newImage) {

                        if(this.skipSavingToServer){

                            this.selectedImage = newImage;
                            $scope.$applyAsync(function selectedImageChange() {
                                this.imageChanged({type: 'reposition', selectedImage: this.selectedImage});
                            }.bind(this));

                        }else{
                            this.ImagesManager.saveImageReposition(newImage)
                                .then(function success(res) {
                                    res.data.forEach(function readResult(image) {
                                        if (image._type === 'CoverImage') {
                                            this.ImagesManager.updateCoverImageInCompany(image);
                                        } else if (image._id === newImage._id) {
                                            //extend the image we have with the image that was returned from the last API
                                            angular.extend(newImage, image);
                                        }

                                    }.bind(this));

                                    this.selectedImage = newImage;
                                    $scope.$applyAsync(function selectedImageChange() {
                                        // AnalyticsService.track(this, "request to change brochure image. Image ID: " + this.selectedImage._id);
                                        this.imageChanged({type: 'reposition', selectedImage: this.selectedImage});
                                    }.bind(this));
                                }.bind(this))

                                .catch(function (err) {
                                    this.$log.error('Error while trying to save image reposition', err);
                                }.bind(this));
                        }

                    }.bind(this)
                );
            };

            this.setCarouselHeightToActiveImgHeight = function setCarouselHeightToActiveImgHeight(){
                var newHeight;
                //get height of active element
                newHeight = this.$element.find('.slick-active').height();
                //set height of slick-list
                if(newHeight > 25 && newHeight < 500){
                    this.$element.find('.slick-list').css('height', newHeight);
                }
                else{
                    //sometimes we get here and the '.slick-active' DOM isn't populated yet.
                    // so I set the height of the parent by calculating the height of img in the DOM based
                    // on cloudinary parameters position_h and position_w.
                    var width = this.$element.find('.slick-list').width();
                    newHeight = this.selectedImage.position_h * width;
                    newHeight = newHeight / this.selectedImage.position_w;

                    this.$element.find('.slick-list').css('height',newHeight);


                }
            };

            /////////////////////
            // Controller Code //
            /////////////////////

            if (this.checkImagesLoad()) {
                this.checkLoad();
            } else {
                var unwatchImages = $scope.$watch('imageCarouselRepositionVm.images', function imagesFetched(newVal) {
                    if (this.checkImagesLoad()) {
                        unwatchImages();
                        this.checkLoad();
                    }
                }.bind(this));
            }

            if (this.checkSelectedImageLoad()) {
                this.checkLoad();
            } else {
                var unwatchSelectedImage = $scope.$watch('imageCarouselRepositionVm.selectedImage', function imagesFetched(newVal) {
                    if (this.checkSelectedImageLoad()) {
                        unwatchSelectedImage();
                        this.checkLoad();
                    }
                }.bind(this));
            }
        }


        function ImageCarouselRepositionLink($scope, $element) {
            $scope.imageCarouselRepositionVm.setDomElement($element);
        }

        var ImageCarouselRepositionController = Class(Controllers.BaseController, {
            constructor: ImageCarouselRepositionControllerFunc
        });


        return {
            restrict: 'E',
            scope: {
                images: '=images',
                selectedImage: '=selectedImage',
                imageChanged: '&imageChanged',
                hideButtons: '=hideButtons',
                imageRatio: '@imageRatio',
                showPreviewWhenCropping: '@showPreviewWhenCropping',
                variableHeightImg: '=?variableHeightImg',
                skipSavingToServer: '=?skipSavingToServer',
                analyticsSource: '@analyticsSource',
                showCustomizeFlag: '=showCustomizeFlag', // if undefined, we don't use it!!!
                singlePhotoMaxWidth: '@?'
            },
            transclude: true,
            templateUrl: function whatsTemplateUrl(tElem, tAttri){
                if(tAttri.useCropModal==="true"){
                    return 'angular/app/modules/common/ui_components/image_carousel_reposition/image_carousel_reposition_template.html';
                }
                else{
                    return 'angular/app/modules/common/ui_components/image_carousel_reposition/old_image_carousel_reposition_template.html';
                }
            },
            controller: ImageCarouselRepositionController,
            controllerAs: 'imageCarouselRepositionVm',
            bindToController: true,
            link: ImageCarouselRepositionLink
        };
    };
}());