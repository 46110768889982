(function () {
    "use strict";

    var _Gon, _googleMapsDisabled = false;

    // this is necessary to be on the window otherwise the google's client.js (found on the angular.html.erb) will not call it using the onLoad callback.
    // https://developers.google.com/maps/documentation/javascript/events#auth-errors
    // BTW this probably should move to modules/common/3rd_party_wrappers/google_api_service.js, because it's totally unrelated to this file
    window.gm_authFailure = function gm_authFailure() {
        _googleMapsDisabled = true;
    };

    // @ngInject
    function GoogleAPIServiceCtor(Gon, $log, googleAccounts) {
        _Gon = Gon;
        this.$log = $log;
        this.googleAccounts = googleAccounts;
    }

    Services.GoogleAPIService = Class(function () {
        return {
            constructor: GoogleAPIServiceCtor,
            googleMapsDisabled: function googleMapsDisabled() {
                return _googleMapsDisabled;
            },
            renderGoogleSignInButton: function renderGoogleSignInButton(elementId, callback) {
                this.googleAccounts.then(function (googleAccounts) {
                    googleAccounts.id.initialize({
                        client_id: _Gon.google_api_client_id,
                        auto_select: true,
                        // TODO: nonce validation will be added in next phase
                        // nonce: _Gon.google_auth_nonce,
                        callback: function googleIdTokenCallback(credentialResponse) {
                            callback(credentialResponse.credential);
                        },
                    });
                    googleAccounts.id.renderButton(
                        document.getElementById(elementId),
                        {
                            width: 230,
                            logo_alignment: 'center'
                        }
                    );
                });
            }
        };
    });
}());