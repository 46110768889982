Directives.FilmstripDirective = function FilmstripDirective() {

    // @ngInject
    function FilmstripDirectiveControllerFunc($scope, $injector, Constants, AppConfigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FilmstripDirectiveController';
        var self = this;
        var appStyleType = AppConfigService.getAppStyleType();

        function fixDefaultEnableToTrueAndScrollOffset(linksArray) {
            if (linksArray && angular.isArray(linksArray)) {
                linksArray.forEach(function (link, index) {
                    link.enable = (link.enabled === undefined) ? 'true' : link.enabled;

                    switch (appStyleType) {
                        case Constants.AppStyleTypes.small:
                            if (index === 0) {
                                link.scrollToOffset = 15;
                            } else {
                                // this is an offset to take into account the fixed positioned header height and the fact
                                // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                // this is the sum of them. should be changed if the styling is changed.
                                link.scrollToOffset = 220;
                            }
                            break;
                        default:
                            if (index === 0) {
                                link.scrollToOffset = -150;
                            } else {
                                // this is an offset to take into account the fixed positioned header height and the fact
                                // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                // this is the sum of them. should be changed if the styling is changed.
                                link.scrollToOffset = 150;
                            }
                            break;
                    }
                });
            }
        }

        fixDefaultEnableToTrueAndScrollOffset(this.linksTo);

        $scope.$watch('filmstripVm.linksTo', function (newVal) {
            //we got a new array fix the enable property
            fixDefaultEnableToTrueAndScrollOffset(newVal);
        });

        // this offset defines the threshold of when to switch the active object in the film-strip.
        // it should be somewhere in the middle of the screen to take the fixed banner at top into
        // consideration. since du-scroll-container was not set the document is the default container
        switch (appStyleType) {
            case Constants.AppStyleTypes.small:
                this.offsetFromTheTopForHotSpot = 300;
                break;
            default:
                //all the medium and big ones.
                this.offsetFromTheTopForHotSpot = 200;
        }
    }

    var FilmstripDirectiveController = Class(Controllers.BaseController, {
        constructor: FilmstripDirectiveControllerFunc
    });
    return {
        scope: {
            linksTo: '=linksTo',
            branded: '=?branded'
        },
        templateUrl: 'angular/app/modules/common/ui_components/filmstrip/filmstrip_directive_template.html',
        controller: FilmstripDirectiveController,
        controllerAs: 'filmstripVm',
        bindToController: true
    };
};