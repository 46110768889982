(function() {
	'use strict';

	class ProjectDetailsNotesComponent extends Controllers.BaseControllerES6 {
		// @ngInject
		constructor($scope, $injector, $translate, $element, EventsManager, PopupMessageService, moment, $timeout, AnalyticsService, $stateParams, DatadogRUMService) {
			super($scope, $injector);
			this.__objectType = 'ProjectDetailsNotes';
			this.$translate = $translate;
			this.$timeout = $timeout;
			this.$element = $element;
			this.moment = moment;
			this.EventsManager = EventsManager;
			this.AnalyticsService = AnalyticsService;
			this.PopupMessageService = PopupMessageService;
			this.$stateParams = $stateParams;
			this.DatadogRUMService = DatadogRUMService;
			this.ckEditorToolbar = [];
		}

		onCreateNote() {
			this.workspaceId = this.$stateParams.workspace_id;
			this.creating = true;
			// temp log in order to investigate where we using old api
			this.DatadogRUMService.addError(new Error(), {message: 'Create old note api call', workspaceId: this.workspaceId, projectId: this.project._id, companyId: this.company._id});

			this.EventsManager.createCompanyProjectNotes(this.company, this.project, "", this.workspaceId)
				.then(() => {
					this.creating = false;
					this._focusNewElement();
				});
		}

		onUpdateNote(note) {
			// temp log in order to investigate where we using old api
			this.DatadogRUMService.addError(new Error(), {message: 'Update old note api call', projectId: this.project._id, companyId: this.company._id});
			this.EventsManager.updateCompanyProjectNotes(this.company, this.project, note);
		}


		onDeleteNote(note) {
			this._showDeleteWarning(note).then(() => {
				this.EventsManager.deleteCompanyProjectNotes(this.company, this.project, note._id);
				this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.delete_project_details_private_note_, {source: this.analyticsEventsSource});
			})
		}

		_showDeleteWarning(note){
			let warning = {
				type: this.PopupMessageService.severityTypes.none,
				date: this.moment(note.created_at).format('MMM Do'),
				time: this.moment(note.created_at).format('LT'),
				title: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._ARE_YOU_SURE_'),
				msgText1: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._YOUR_NOTE_ON_'),
				msgText2: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._at_'),
				msgText3: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._WILL_BE_DELETED_'),
				msg() {
					return `${this.msgText1} ${this.date} ${this.msgText2} ${this.time} ${this.msgText3}`
				},
				posBtn: this.$translate.instant('WORKSPACE.PROJECT_DETAILS._YES_DELETE_'),
				negBtn: null
			};

			return this.PopupMessageService.showConfirmPromise(warning.type, warning.msg(), warning.posBtn, warning.negBtn, warning.title);
		}

		_focusNewElement(){
			const elem = '.project-details__notes__item';
			this.$timeout(() => {
				this.$element.find(`${elem}-text`)[0].focus();
			});
		}

	}

	Components.ProjectDetailsNotes = {
		bindings: {
			project: '<',
			company: '<',
            analyticsEventsSource: '@'
		},
		controller: ProjectDetailsNotesComponent,
		name: 'hbProjectDetailsNotes',
		templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_notes_component_template.html'
	};
})();
