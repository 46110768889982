// @ngInject

Models.NetworkProjectsCollection = function NetworkProjectsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'NetworkProjectsCollection';
                this._collection.__childsType = 'NetworkProjectModel';
                return this._collection;
            }
        }
    );

};
