Directives.AgreementDirective =  function AgreementDirective(){

    // @ngInject
    function AgreementDirectiveControllerFunc($scope, $timeout, $injector){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AgreementDirectiveController';
        this.showAgreement = true;
        
        $scope.$watch('agreementVm.fileModel.file_version', function fileVersionChanged(newValue, oldValue) {
            // We want to respond only to real changes to the file version and not to the first time you set it (when fetching the model)
            if (angular.isDefined(oldValue)) {
                this.showAgreement = false;
                $timeout(function () {
                    this.showAgreement = true;
                }.bind(this));
            }
        }.bind(this));

        this.isEditMode = function isEditMode(){
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode && !this.isViewOnlyMode;
        };
    }

    var AgreementDirectiveController = Class(Controllers.BaseController, {
        constructor: AgreementDirectiveControllerFunc
    });

    return {
        scope:{
            fileModel: '=fileModel',
            companyModel: '=companyModel',
            workspaceModel: '=workspaceModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isViewOnlyMode: '=isViewOnlyMode',
            isEditableMode: '=isEditableMode',
            isWaitingForAccept: '=isWaitingForAccept',
            forceShowTemplatesMenu: '<',
            loaded: '=isLoaded'
        },
        templateUrl : 'angular/app/modules/core/features/agreement/agreement_directive_template.html',
        controller : AgreementDirectiveController,
        controllerAs : 'agreementVm',
        bindToController : true
    };
};