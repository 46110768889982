/**
 * Created by dvircohen on 18/06/2017.
 */

// @ngInject
Models.InvoicesCollection = function InvoicesCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'InvoicesCollection';
                this._collection.__childsType = 'PayableFileTemplateModel';
                return this._collection;
            }
        }
    );

};