// ***Rough Template Style***
// DONT PREFIX DROPDOWN ID WITH A HASH, PLEASE. IT WON'T WORK.
//  <[TOGGLE-ELEMENT] hb-dropdown-toggle="my-dropdown-id" class="hb-dropdown-container" hb-dropdown-position="top | bottom | right | left">
//      <ul id="my-dropdown-id" class="hb-dropdown">
//          <li ng-repeat="dropdownItem in dropdownItems>
//      </ul>
//  </[TOGGLE-ELEMENT]>

// there is some FLEXIBILITY with the template. it doesn't need to contain a ul element, and can have a slightly
// different structure than the example above

// the dropdown MUST have a parent element that is 'position: relative'.
// it will be positioned relative to this parent element. if the parent element is a block element,
// the dropdown may stretch all the way to the end of the element. beware!

// the class 'hb-dropdown-container' is a utility class that can be assigned to whichever element
// that you want to be the parent element of the dropdown.

// NB: there are mixins (for instance, search for 'dropdown-bottom-right' to find it in our file) in our mixin.css.scss
// that you can apply to your dropdown element
// to change it's position across different screensizes

(function () {
    "use strict";

    // @ngInject
    Directives.DropdownToggleDirective = function DropdownToggleDirective($document) {
        return {
            restrict: 'A',
            scope: {
                hbDropdownPosition: '@',
                hbDropdownToggle: '@',
                hbFullWidth: '@',
                hbNoNub: '@',
                hbPreventPropogationFromContent: '@',
                hbRemoveCallback: '&?'
            },
            link: function dropdownToggleLink($scope, $elem) {
                var eventDomain = '.scope' + $scope.$id + '.onDropdownToggleDirective.honeybook';
                var dropdownElement;
                var defaultClass = 'hb-dropdown-bottom-left';
                var elm = $elem;
                var lastHandledEvent = null;

                var initDropdown = function initDropdown() {
                    dropdownElement = angular.element('#' + $scope.hbDropdownToggle);
                    elm.addClass('active-dropdown');

                    // apply appropriate CSS class
                    if ($scope.hbDropdownPosition) {
                        var height = dropdownElement.height();
                        var width  = dropdownElement.width();
                        var toggleHeight = $elem.height();
                        var positionTop, positionRight, positionBottom, positionLeft;

                        switch ($scope.hbDropdownPosition) {
                            case 'top':
                                dropdownElement.addClass('hb-dropdown-top');
                                positionTop = '-' + (6 + height) + 'px';
                                dropdownElement.css({'top': positionTop});
                                break;
                            case 'right':
                                dropdownElement.addClass('hb-dropdown-right');
                                positionRight = '-' + (9 + width) + 'px';
                                positionTop   = '-' + ((height / 2) - (toggleHeight / 2)) + 'px';
                                dropdownElement.css({'right': positionRight, 'top': positionTop});
                                break;
                            case 'bottom-left':
                                //aligns right corner to right side.
                                dropdownElement.addClass(defaultClass);
                                var dropdownParent;
                                positionTop =  elm[0].clientHeight + 5;
                                positionRight = 0;
                                dropdownElement.css({'right': positionRight, 'top': positionTop});
                                break;
                            case 'bottom-right':
                                dropdownElement.addClass('hb-dropdown-bottom-right');
                                break;
                            case 'left':
                                dropdownElement.addClass('hb-dropdown-left');
                                positionLeft = '-' + (9 + width) + 'px';
                                positionTop  = '-' + ((height / 2) - (toggleHeight / 2)) + 'px';
                                dropdownElement.css({'left': positionLeft, 'top': positionTop});
                                break;
                        }
                    } else {
                        // defaults to bottom
                        dropdownElement.addClass(defaultClass);
                    }

                    if ($scope.hbFullWidth === 'true') {
                        // this class adds "width: 100%";
                        dropdownElement.addClass('hb-dropdown-full-width');
                    }

                    if ($scope.hbNoNub === 'true') {
                        // hide nub/arrow
                        dropdownElement.addClass('hb-dropdown-no-nub');
                    }
                };

                $document.on('click' + eventDomain, function dropdownToggleDocumentClick(e) {
                    if (!dropdownElement) {
                        initDropdown();
                    }

                    if (lastHandledEvent === e.originalEvent) {
                        return; // We do nothing because we already handled this event!!!
                    } else {
                        lastHandledEvent = e.originalEvent;
                    }

                    var target                 = angular.element(e.target);
                    var toggleIsTarget         = $elem.is(target);
                    var toggleIsTargetParent   = target.closest($elem).length;

                    var dropdownWasActive = dropdownElement.hasClass('hb-dropdown-show'); // heuristic to check if dropdown was actually active
                    if (toggleIsTarget || toggleIsTargetParent) {

                        dropdownElement.toggleClass('hb-dropdown-show');
                        elm.toggleClass('active-dropdown');

                        $scope.$applyAsync();
                    } else if (!toggleIsTarget && !toggleIsTargetParent) {

                        elm.removeClass('active-dropdown');
                        dropdownElement.removeClass('hb-dropdown-show');

                        $scope.$applyAsync();
                    }

                    var dropdownIsActive = dropdownElement.hasClass('hb-dropdown-show'); // heuristic to check if dropdown was actually active
                    if (dropdownWasActive && !dropdownIsActive && typeof $scope.hbRemoveCallback === 'function') {
                        //check if show was removed this means that we are closing the drop down call the callback
                        $scope.hbRemoveCallback();
                    }

                    if (dropdownElement.hasClass('hb-dropdown-show') && $scope.hbPreventPropogationFromContent === 'true') {
                        dropdownElement.click(function (event) {
                            event.stopPropagation();
                        });
                    } else if ($scope.hbPreventPropogationFromContent === 'true') {
                        dropdownElement.off('click');
                    }
                });

                $scope.$on('$destroy', function dropdownDestroy () {
                    $document.off(eventDomain);
                });
            }
        };
    };

}());