
(function () {
    "use strict";

    // @ngInject
    Directives.CollapseDirective = function CollapseDirective($animate, $q, $parse, $injector) {

        var $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;

        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var expandingExpr = $parse(attrs.expanding),
                    expandedExpr = $parse(attrs.expanded),
                    collapsingExpr = $parse(attrs.collapsing),
                    collapsedExpr = $parse(attrs.collapsed),
                    horizontal = false,
                    css = {},
                    cssTo = {};

                init();

                function init() {
                    horizontal = !!('horizontal' in attrs);
                    if (horizontal) {
                        css = {
                            width: ''
                        };
                        cssTo = {width: '0'};
                    } else {
                        css = {
                            height: ''
                        };
                        cssTo = {height: '0'};
                    }
                    if (!scope.$eval(attrs.hbCollapse)) {
                        element.addClass('hb-collapse-in')
                            .addClass('hb-collapse-collapse')
                            .css(css);
                    }
                }

                function getScrollFromElement(element) {
                    if (horizontal) {
                        return {width: element.scrollWidth + 'px'};
                    }
                    return {height: element.scrollHeight + 'px'};
                }

                function expand() {
                    if (element.hasClass('hb-collapse-collapse') && element.hasClass('hb-collapse-in')) {
                        return;
                    }

                    $q.resolve(expandingExpr(scope))
                        .then(function() {
                            element.removeClass('hb-collapse-collapse')
                                .addClass('hb-collapse-collapsing');

                            if ($animateCss) {
                                $animateCss(element, {
                                    addClass: 'hb-collapse-in',
                                    css: {
                                        overflow: 'hidden'
                                    },
                                    to: getScrollFromElement(element[0])
                                }).start()['finally'](expandDone);
                            } else {
                                $animate.addClass(element, 'hb-collapse-in', {
                                    css: {
                                        overflow: 'hidden'
                                    },
                                    to: getScrollFromElement(element[0])
                                }).then(expandDone);
                            }
                        });
                }

                function expandDone() {
                    element.removeClass('hb-collapse-collapsing')
                        .addClass('hb-collapse-collapse')
                        .css(css);
                    expandedExpr(scope);
                }

                function collapse() {
                    if (!element.hasClass('hb-collapse-collapse') && !element.hasClass('in')) {
                        return collapseDone();
                    }

                    $q.resolve(collapsingExpr(scope))
                        .then(function() {
                            element
                            // IMPORTANT: The width must be set before adding "collapsing" class.
                            // Otherwise, the browser attempts to animate from width 0 (in
                            // collapsing class) to the given width here.
                                .css(getScrollFromElement(element[0]))
                                // initially all panel collapse have the collapse class, this removal
                                // prevents the animation from jumping to collapsed state
                                .removeClass('hb-collapse-collapse')
                                .addClass('hb-collapse-collapsing');

                            if ($animateCss) {
                                $animateCss(element, {
                                    removeClass: 'hb-collapse-in',
                                    to: cssTo
                                }).start()['finally'](collapseDone);
                            } else {
                                $animate.removeClass(element, 'hb-collapse-in', {
                                    to: cssTo
                                }).then(collapseDone);
                            }
                        });
                }

                function collapseDone() {
                    element.css(cssTo); // Required so that collapse works when animation is disabled
                    element.removeClass('hb-collapse-collapsing')
                        .addClass('hb-collapse-collapse');
                    collapsedExpr(scope);
                }

                scope.$watch(attrs.hbCollapse, function(shouldCollapse) {
                    if (shouldCollapse) {
                        collapse();
                    } else {
                        expand();
                    }
                });
            }
        };
    };

}());