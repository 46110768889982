// @ngInject

Models.NetworkUserModel = function NetworkUserModel(BaseModel, NetworkOnboardingService) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'NetworkUserModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {projects: 'NetworkProjectsCollection',
                    feed: 'FeedModel'};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        showImproveProfileNotification: function showImproveProfileNotification(){
            "use strict";
            if (!NetworkOnboardingService.isFreshUser(this)){
                // not first session, depend on the flag
                return (this.saw_improve_modal==null || !this.saw_improve_modal);
            }else{
                // not showing on first session
                return false;
            }
        }

    });

};

