/**
 * Created by inonstelman on 12/29/14.
 */
(function () {
    "use strict";


    // @ngInject
    function ModelFactoryCtor($injector) {
        this.$injector = $injector;
        this.modelMap = {};
    }


    Models.ModelFactory = Class({

        constructor: ModelFactoryCtor,

        getMasterCollection: function getMasterCollection(modelClassName) {
            if (!this.RepositoryService) {
                this.RepositoryService = this.$injector.get('RepositoryService');
            }
            return this.RepositoryService.getMasterCollection(modelClassName);
        },

        getWorkspacesMasterCollection: function getWorkspacesMasterCollection() {
            return this.getMasterCollection('WorkspaceModel');
        },

        getNetworkUsersMasterCollection: function getNetworkUsersMasterCollection() {
            return this.getMasterCollection('NetworkUserModel');
        },

        getWorkspaceFilesMasterCollection: function getWorkspaceFilesMasterCollection() {
            return this.getMasterCollection('WorkspaceFileModel');
        },

        getEventsMasterCollection: function getEventsMasterCollection() {
            return this.getMasterCollection('EventModel');
        },

        getModelClass: function getModelClass(modelClassName) {

            var model = this.modelMap[modelClassName];
            if (!model) {
                this.modelMap[modelClassName] = model = this.$injector.get(modelClassName);
            }
            return model;
        },

        newModel: function newModel(modelClassName) {

            var model = this.getModelClass(modelClassName);
            return new model();
        },

        newCollectionByModelName: function newCollectionByModelName(modelClassName) {

            var model = this.newModel(modelClassName);
            return this.newModel(model.collectionName());
        },

        newActivityModel: function newActivityModel() {
            return this.newModel('ActivityModel');
        },

        newWorkspaceFileModel: function newWorkspaceFileModel() {
            return this.newModel('WorkspaceFileModel');
        },

        newWorkspaceFilesCollection: function newWorkspaceFilesCollection() {
            return this.newModel('WorkspaceFilesCollection');
        },

        newWorkspaceModel: function newWorkspaceModel() {
            return this.newModel('WorkspaceModel');
        },

        newWorkspacesCollection: function newWorkspaceCollection() {
            return this.newModel('WorkspacesCollection');
        },

        newUserModel: function newUserModel() {
            return this.newModel('UserModel');
        },

        newUsersCollection: function newUsersCollection() {
            return this.newModel('UsersCollection');
        },

        newEventModel: function newEventModel() {
            return this.newModel('EventModel');
        },

        newReferralCampaignModel: function newReferralCampaignModel() {
            return this.newModel('ReferralCampaign');
        },

        newReferralCampaignsCollection: function newReferralCampaignsCollection() {
            return this.newModel('ReferralCampaignsCollection');
        },

        newMemberReferralModel: function newMemberReferralModel() {
            return this.newModel('MemberReferral');
        },

        newSubscriptionModel: function newSubscriptionModel() {
            return this.newModel('SubscriptionModel');
        },

        newMemberReferralsCollection: function newMemberReferralsCollection() {
            return this.newModel('MemberReferralsCollection');
        },

        newCompanyModel: function newCompanyModel() {
            return this.newModel('CompanyModel');
        },

        newEventsCollection: function newEventsCollection() {
            return this.newModel('EventsCollection');
        },

        newSearchablesCollection: function newSearchablesCollection() {
            return this.newModel('SearchablesCollection');
        },

        newContactModel: function newContactModel() {
            return this.newModel('ContactModel');
        },

        newContactsCollection: function newContactsCollection() {
            return this.newModel('ContactsCollection');
        },

        newPlaceModel: function newPlaceModel() {
            return this.newModel('PlaceModel');
        },

        newFeedModel: function newFeedModel() {
            return this.newModel('FeedModel');
        },

        newFeedItemModel: function newFeedItemModel() {
            return this.newModel('FeedItemModel');
        },

        newFeedItemsCollection: function newFeedItemsCollection() {
            return this.newModel('FeedItemsCollection');
        },

        newLeadModel: function newLeadModel() {
            return this.newModel('LeadModel');
        },

        newLeadsCollection: function newLeadsCollection() {
            return this.newModel('LeadsCollection');
        },

        newContactForm: function newContactForm() {
            return this.newModel('ContactFormModel');
        },

        newWsVendorUser: function newWsVendorUser(){
            return this.newModel('WSVendorUserModel');
        },

        newWsVendorUsersCollection: function newWsVendorUsersCollection(){
            return this.newModel('WSVendorUserCollection');
        },

        newCompaniesCollection: function newCompaniesCollection() {
            return this.newModel('CompaniesCollection');
        },

        newRtsChapterModel: function newRtsChapterModel() {
            return this.newModel('RtsChapterModel');
        },

        newQuestionModel: function newQuestionModel() {
            return this.newModel('QuestionModel');
        }


    });
}());
