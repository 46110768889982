(function () {
    'use strict';

    class SetupTwoFactorAuthController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'SetupTwoFactorAuthController';
        }
    }

    Components.SetupTwoFactorAuth = {
        bindings: {
            onPhoneSetupComplete: '=',
        },
        controller: SetupTwoFactorAuthController,
        name: 'hbSetupTwoFactorAuth',
        templateUrl: 'angular/app/modules/common/ui_components/two_factor_auth/setup_two_factor_auth_component_template.html',
    };

}());