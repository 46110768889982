(function () {
    "use strict";

// class IntakeModalController {

// @ngInject
function IntakeModalControllerCtor(
         $scope, $injector, UsersManager, $translate, $modalInstance, $,
         CompanyService,OnboardingService, AnalyticsService, AbTestService, Enums, intakeVersion) {

    // constructor() {
    this.constructor.$super.call(this, $scope, $injector);
    this.__objectType = 'IntakeModalController';

    this.UsersManager = UsersManager;
    this.OnboardingService = OnboardingService;
    this.AbTestService = AbTestService;
    this.currUser = this.UsersManager.getCurrUser();
    this.$translate = $translate;
    this.Enums = Enums;
    this.AnalyticsService = AnalyticsService;
    this.APIErrorMessage = '';
    this.isSaving = false;
    this.$modalInstance = $modalInstance;
    this.CompanyService = CompanyService;
    this.companyTypes = this.CompanyService.getCompanyTypesSorted();
    this.companyTypesEnum = this.CompanyService.getCompanyTypesEnum();
    this.intakeVersion = intakeVersion;
    // IntakeFormVersion: {
    //     communityTools: 'communityTools',
    //         communityTrial: 'communityTrial',
    //         communityOriginal: 'communityOriginal',
    //         workflowOriginal: 'workflowOriginal',
    //         workflowHome: 'workflowHome'
    // },

    // PLEASE make sure not to push this to production when its not commented out :)
     // this.intakeVersion = this.Enums.IntakeFormVersion.workflowHome; //for debugging any of the intake form options
    // PLEASE make sure not to push this to production when its not commented out :)

    this.initIntakeVersionParams();

    AnalyticsService.trackPageView(this, 'in product intake form', {user_attribution: this.currUser.attribution.user_source_primary, is_example_proposal: false});
    this.companyTypesValues = this.companyTypes.map(function(companyType) {
        return $translate.instant(companyType.text);
    });
    // Capitalize their first name for modal title
    this.firstName = this.currUser.first_name ? ' ' + this.currUser.first_name.charAt(0).toUpperCase() + this.currUser.first_name.slice(1) : '';

    //onUpdateUserSuccess(userData) {
    this.onUpdateUserSuccess = function onUpdateUserSuccess(userData) {
        this.isSaving = false;
        this.$modalInstance.close();
        var eventName = this.AnalyticsService.analytics_events.in_product_intake;
        this.AnalyticsService.track(this, eventName, {user_attribution: this.currUser.attribution.user_source_primary, is_example_proposal: false});
        this.OnboardingService.onIntakeModalSubmission();
    }.bind(this);

    // onUpdateUserError() {
    this.onUpdateUserError = function onUpdateUserError(error) {
        this.APIErrorMessage = this.$translate.instant(
            'REGISTRATION.ERRORS._API_UPDATE_USER_');
        this.isSaving = false;
    }.bind(this);

    // }
}

//IntakeModalController.$inject = ['UsersManager', 'AnalyticsService', '$translate', 'CompanyService', '$modalInstance'];
Controllers.IntakeModalController = Class(Controllers.BaseController, {
    constructor: IntakeModalControllerCtor,

    // close() {
    close: function close() {
        // dismiss() is used for cancel, close() after successful action
        this.$modalInstance.dismiss();
    },

    initIntakeVersionParams: function initIntakeVersionParams() {
        this.intakeCTA = "START FREE TRIAL";
    },

    // this.translateFormValues(formValues) {
    translateFormValues: function translateFormValues(formValues) {
        var selectedCompanyTypeKey = this.companyTypes[0].key;
        var selectedCompanyType = _.find(this.companyTypes, function(type) {
            return this.$translate.instant(type.text) === formValues.companyType;
        }.bind(this));

        if (selectedCompanyType && selectedCompanyType.key) {
            selectedCompanyTypeKey = selectedCompanyType.key;
        }

        // Do API key translation here. UsersManager does the merge
        // of user data with the following properties for overriding.
        // Note: updateUserProperties() expects _id
        //let userData = {
        var userData = {
            full_name: formValues.fullName,
            company_name: formValues.companyName,
            company_type: selectedCompanyTypeKey,
            phone_number: formValues.phone,
            password: formValues.password,
        };

        return userData;
    },

    // saveUserInfo(formValues) {
    saveUserInfo: function saveUserInfo(formValues) {
        this.isSaving = true;

        var analyticsParams = angular.extend({}, formValues);
        delete analyticsParams.password;
        var eventName = this.AnalyticsService.analytics_events.in_product_intake_form_submit;
        this.AnalyticsService.track(this, eventName, analyticsParams);

        // adding MTA data
        var mta_mixpanel_params = {};
        mta_mixpanel_params.user_id = this.currUser._id;
        mta_mixpanel_params.company_id = this.currUser.company._id;
        mta_mixpanel_params.user_source_primary = this.currUser.attribution.user_source_primary;
        mta_mixpanel_params.user_source_secondary = this.currUser.attribution.user_source_secondary;

        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.mta_in_product_start_trial, mta_mixpanel_params);

        var userData = this.translateFormValues(formValues);
        this.UsersManager.convertUserToVendor(userData).then(
            this.onUpdateUserSuccess,
            this.onUpdateUserError
        );
    },
});

})();