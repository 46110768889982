/**
 * Created by dvircohen on 21/02/2016.
 */
(function () {
    'use strict';


    // @ngInject
    function TOSManagerCtor(APIService, $q, ModalService, AnalyticsService, ModelFactory, ngToast, $timeout, Routes) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.ModalService = ModalService;
        this.AnalyticsService = AnalyticsService;
        this.ngToast = ngToast;
        this.$timeout = $timeout;
        this.Routes = Routes;

        this.tosAcceptanceHandled = false;
    }

    Services.TOSManager = Class(Services.BaseModelManager, {
        constructor: TOSManagerCtor,


        TOSAcceptanceNeeded: function TOSAcceptanceNeeded(user) {

            if (this.tosAcceptanceHandled) {
                return;
            }


            this.tosAcceptanceHandled = true;
            this.getTOSDetails(user)
                .then(function (resp) {

                    if (resp && resp.data) {

                        var tos = resp.data;
                        if (tos.acceptance_type == 'blocking') {

                            this.ModalService.openTOSChangedModal(tos.message_text);

                        } else if (tos.acceptance_type == 'notice') {

                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.terms_of_service_toast_viewed);
                            this.$timeout(function () {
                                this.toast = this.ngToast.create({
                                    content: tos.message_text,
                                    className: 'info',
                                    dismissOnTimeout: false,
                                    dismissButton: true,
                                    dismissButtonHtml: 'Accept',
                                    onDismiss: function () {
                                        this.acceptTOS(user, 'web toast');
                                    }.bind(this)
                                });
                            }.bind(this), 4000);
                        }
                    }
                }.bind(this))
                .catch(function error(resp) {
                    this.tosAcceptanceHandled = false;
                }.bind(this));

        },

        acceptTOS: function acceptTOS(user, source) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.terms_of_service_accepted, {
                page: source
            });
            var url = this.Routes.v2_user_accept_terms_of_service_path(user._id);
            return this.apiUpdate(url, null, user, true);
        },

        getTOSDetails: function getTOSDetails(user) {

            var url = this.Routes.v2_get_user_tos_to_accept_path(user._id);
            return this.apiFetch(url);
        },

        dismissTosToast: function dismissTosToast() {
            if (this.toast) {
                this.ngToast.dismiss(this.toast);
            }

        }
    });
}());

