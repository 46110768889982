
(function () {
    'use strict';
    class MeetingLocation extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'MeetingLocation';

            this.AnalyticsService = AnalyticsService;
            
            this.onLocationChangeFn = this.onGoogleLocationChange.bind(this);
            this.onLocationFieldChangeFn = this.onLocationFieldChange.bind(this);

            this.addressAutocompleteEnabled = false;
            StatsigService.isGateEnabled('location-iq-address-autocomplete').then(isGateEnabled => {
                this.addressAutocompleteEnabled = isGateEnabled;
            });

        }

        onLocationFieldChange(event) {
            let location = this.location;
            if (event) {
                location = event.target.value;
            }
            this.AnalyticsService.track(this, this.analyticsEvent, {source: this.analyticsEventSource,ab_test_variation: this.addressAutocompleteEnabled ? 'location-iq' : 'google-places'});
            return this.onLocationChange({location: location});
        }

        onGoogleLocationChange(geocodeResult) {
            let location;
            if (geocodeResult.formatted_address) {
                location = geocodeResult.formatted_address.includes(geocodeResult.name) ?
                    geocodeResult.formatted_address :
                    `${geocodeResult.name}, ${geocodeResult.formatted_address}`;
            } else {
                location = geocodeResult.display_name.includes(geocodeResult.display_place) ?
                    geocodeResult.display_name :
                    `${geocodeResult.display_place}, ${geocodeResult.display_name}`;
            }

            this.location = location;
            this.onLocationFieldChange();
        }
    }

    Components.MeetingLocation = {
        bindings: {
            placeholder: '<?',
            location: '<',
            onLocationChange: '&?',
            analyticsEventSource: '@?',
            analyticsEvent: '@?',
            showError: '<?',
            errorMessage: '<?',
            label: '<?'
        },
        controller: MeetingLocation,
        name: 'hbMeetingLocation',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_location/meeting_location_field.html'
    };
}());