(function () {
    'use strict';

    // @ngInject
    function CalendlyRedirectControllerCtor($scope, $injector, $location, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, Enums, $window) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CalendlyRedirectController';
        AnalyticsService.trackPageView(this, 'CalendlyRedirectView');
        this.$log = $log;
        this.$q = $q;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;

        this.Enums = Enums;

        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        InitialAppLoadParamsService.cleanParamsFromView(['code', 'state']);

        var stateAsObject = JSON.parse(queryStringParams.state);

        if (queryStringParams.code && stateAsObject.validation_code) {
            this.UsersManager.setCalendlyAuthCode(queryStringParams.code, stateAsObject.validation_code)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_calendly_integration_auth_code);
                    this.redirectTo(stateAsObject.return_url);
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.calendly_error_connection, resp);
                    if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBUserError") {

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function success() {
                            this.redirectTo(queryStringParams.state);
                        }.bind(this));
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function success() {
                            this.redirectTo(queryStringParams.state);
                        }.bind(this));
                    }
                    this.$log.error('failed to save the auth-code on the calendly integration model');
                }.bind(this));

        } else if (queryStringParams.error) {
            this.$log.error('Error in calendly authentication process: ' + $location.url());
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.calendly_integration_error);

            this.$log.error("user rejected the permissions on calendly authentication page error: "+ queryStringParams.error);
            this.redirectTo(stateAsObject.return_url);
        }
    }

    Controllers.CalendlyRedirectController = Class(Controllers.BaseController, {
        constructor: CalendlyRedirectControllerCtor,

        redirectTo: function redirectTo(state) {
            if (state) {
                this.$window.location.href = state;
            } else {
                this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
            }

        }
    });
}());

Function.ubind = function (self) {
    var f = this;
    return function () {
        f.apply(self, Array.prototype.slice.call(arguments, 0));
    };
};
