(function () {
    'use strict';

    class FeedFileThumbnailComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AppStates, WorkspaceFileService) {
            super($scope, $injector);
            this.__objectType = 'FeedFileThumbnailComponentController';
            this.AppStates = AppStates;
            this.WorkspaceFileService = WorkspaceFileService;
        }

        goToFile($event) {
            $event.preventDefault();
            this.WorkspaceFileService.gotoWorkspaceFileById(this.file._id, null, null, false);
        }

        getIconClass() {
            const iconClasses = {
                agreement: 'nx-contract-32',
                brochure: 'nx-brochure-32',
                invoice: 'nx-invoice-32',
                questionnaire: 'nx-questionnaire-32',
                timeline: 'nx-timeline-32',
                proposal: 'nx-proposal-32'
            };

            return iconClasses[this.file.file_type] || iconClasses.brochure;
        }
    }

    Components.FeedFileThumbnailComponent = {
        bindings: {
            file: '<'
        },
        controller: FeedFileThumbnailComponentController,
        name: 'hbFeedFileThumbnail',
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_file_thumbnail_template.html',
    };

}());