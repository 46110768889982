(function () {

    // @ngInject
    function CompanySettingsAccountAccessControllerCtor($scope, $injector, UsersManager,AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsAccountAccessController';
        this.currUser = UsersManager.getCurrUser();
        this.accessToRequests = this.currUser.is_pro && !this.currUser.isProLogin();
        AnalyticsService.track(
            this,
            AnalyticsService.analytics_events.pro_account_access,
            {source: 'company settings',}
        );
    }

    Controllers.CompanySettingsAccountAccessController = Class(Controllers.BaseController, {
        constructor: CompanySettingsAccountAccessControllerCtor,
    });
}());
