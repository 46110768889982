/**
 * Created by inonstelman on 12/28/14.
 */
(function () {
    'use strict';


    // @ngInject
    Services.intercom = function intercom($window, Gon, ThirdPartyLoaderService, DeviceService) {

        function loadIntercom() {
            $window.intercomSettings = {
                app_id: Gon.intercom_app_id
            };

            $window.hbDelayLoad(function () {
                // do not load intercom in app browser, we have a native intercom in mobile repo
                if (DeviceService.isInAppBrowser()) {
                    return;
                }

                // source for the following is https://gist.github.com/kraftysm/09dceb319d41d8ce3549d99d254aeb86, which was taken from
                // https://www.intercom.com/help/en/articles/2631808-install-intercom-with-google-tag-manager after talking with their support
                // because their regular snippet assumes it's in the head/body and awaits a "load" event. if the page is already loaded,
                // no such event gets fired and the snippet doesn't run
                (function () {
                    var w = window;
                    var ic = w.Intercom;
                    if (typeof ic === "function") {
                        ic('reattach_activator');
                        ic('update', w.intercomSettings);
                    } else {
                        var d = document;
                        var i = function () {
                            i.c(arguments);
                        };
                        i.q = [];
                        i.c = function (args) {
                            i.q.push(args);
                        };
                        w.Intercom = i;
                        var l = function () {
                            var s = d.createElement('script');
                            s.type = 'text/javascript';
                            s.async = true;
                            s.src = 'https://widget.intercom.io/widget/' + w.intercomSettings.app_id;
                            var x = d.getElementsByTagName('script')[0];
                            x.parentNode.insertBefore(s, x);
                        };
                        if (document.readyState === 'complete') {
                            l();
                        } else if (w.attachEvent) {
                            w.attachEvent('onload', l);
                        } else {
                            w.addEventListener('load', l, false);
                        }
                    }
                })();
            });

        }
        function getIntercom() {
            if ($window.Intercom && !$window.Intercom.q) {
                return $window.Intercom;
            }
        }
        return ThirdPartyLoaderService.loadCallbackPromise(loadIntercom, getIntercom);
    };


}());