(function () {
    "use strict";

    class RescheduleSendNotificationComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, AnalyticsService) {

            super($scope, $injector);
            this.__objectType = 'RescheduleSendNotificationComponent';
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
        }

        onRejectWrapper() {
            this.AnalyticsService.trackClick(this, this.$translate.instant('FREQUENT_BUTTONS._NO_THANKYOU_'));
            this.onReject && this.onReject();
        }

    }

    Components.RescheduleSendNotificationComponent = {
        bindings: {
            onComplete: '&',
            onReject: '&',
        },
        controller: RescheduleSendNotificationComponent,
        name: 'hbRescheduleSendNotification',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_send_notification_template.html',
    };

}());
