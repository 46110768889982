(function () {
    "use strict";


    // @ngInject
    function InitialAppLoadParamsServiceCtor($location, _, $log) {
        this._ = _;
        this.$log = $log;
        this.$location = $location;
        this.initialUrlSearchParams = angular.copy($location.search());

        // cant do it, $stateParams relying on that
        // this.cleanParamsFromView();
    }

    Services.InitialAppLoadParamsService = Class(function () {
        
        return {
            constructor: InitialAppLoadParamsServiceCtor,
            clearOnLogout: function clearOnLogout() {
                this.initialUrlSearchParams = {};
            },
            getParam: function getParam(loadParam){
                return this.initialUrlSearchParams[loadParam];
            },

            getParamAndRemove: function getParamAndRemove(loadParam){
                var res = this.initialUrlSearchParams[loadParam];
                if (res){
                    this.removeParam(loadParam);
                }
                return res;
            },

            removeParam: function removeParam(loadParam){
                if (this.initialUrlSearchParams[loadParam]){
                    delete this.initialUrlSearchParams[loadParam];
                    this.$location.search(loadParam, null);//clear from url. just for ensuring.
                    return true;
                }
                return false;
            },

            removeParams: function removeParams(loadParams) {
                angular.forEach(loadParams, function (p) {
                    this.removeParam(p);
                }.bind(this));
            },

            cleanParamsFromView: function cleanParamsFromView(/* N arguments*/) {
                if (arguments.length === 0){
                    this.$log.log('cleanParamsFromView called without any arguments, clear all not supported due to $stateParams');
                }
                this._.forEach(this._.flatten(arguments), function (param) {
                    this.$location.search(param, null);
                }.bind(this));
            },

            getAllLoadParams: function getAllLoadParams() {
                return angular.copy(this.initialUrlSearchParams);
            }

        };
    });
}());

