(function () {
    "use strict";

    Controllers.EditDayAvailabilityModalController = class EditDayAvailabilityModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, moment, SchedulingService, dayAvailability, session, date, addTimeRangeOpen) {
            super($scope, $injector);
            this.__objectType = 'EditDayAvailabilityModalController';

            this.$modalInstance = $modalInstance;
            this.moment = moment;
            this.SchedulingService = SchedulingService;

            // save locally all inputs
            this.dayAvailability = dayAvailability;
            this.addTimeRangeOpen = addTimeRangeOpen;
            this.session = session;
            this.date = this.moment(date);

            this.titleDateFormat = 'ddd MMM DD, YYYY';
            this.defaultTimes = {
                from: '09:00',
                to: '17:00'
            };

            this.weekDays = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ];

            this.init();
        }

        init() {

            // set title by date
            this.title = this.date.format(this.titleDateFormat);

            // set curr day of week
            this.dayOfWeek = this.dayAvailability.day_of_week || this.weekDays[this.date.day()];

            // build time ranges list
            this.timeRanges = this.dayAvailability.time_ranges.map(function(range, index) {
                return {
                    id: index,
                    range_time_start: this.moment(range.range_time_start, 'HH:mm').format('h:mm A'),
                    range_time_end: this.moment(range.range_time_end, 'HH:mm').format('h:mm A'),
                };
            }.bind(this));

            if (this.addTimeRangeOpen) {
                this.addTimeRange();
            }
            // set apply type
            this.applyAll = !this.dayAvailability.override_date;

            // error flag
            this.isInvalid = false;
            this.validationErrorMessage = "";
        }

        cancel() {
            this.$modalInstance.dismiss();
        }

        formatTimeRanges() {
            return this.timeRanges.map(function(range) {
                const start = range.range_time_start ? this.moment(range.range_time_start, 'h:mm A').format('HH:mm') : "";
                const end = range.range_time_end ? this.moment(range.range_time_end, 'h:mm A').format('HH:mm') : "";
                return {
                    id: range.id,
                    range_time_start: start,
                    range_time_end: end,
                };
            }.bind(this));
        }

        runValidation(timeRanges) {

            // start & end exist, check start is before end
            const invalid = timeRanges.filter(function(range) {
                let start = parseInt(range.range_time_start.replace(":", ""));
                let end = parseInt(range.range_time_end.replace(":", ""));
                return isNaN(start) || isNaN(end) || start >= end;
            });

            if(invalid.length) {
                this.invalidRanges = invalid.reduce((result, range) => {
                    result[range.id] = true;
                    return result;
                }, {});
                this.isInvalid = true;
                this.validationErrorMessage = 'SCHEDULING.EDIT_DAY_MODAL._INVALID_ERROR_';
                return;
            }

            // validate time ranges
            let overlaps = this.SchedulingService.checkTimeRangesOverlap(timeRanges);
            this.isInvalid = overlaps.overlap;
            this.invalidRanges = overlaps.ranges || {};
            if(this.isInvalid) {
                this.validationErrorMessage = 'SCHEDULING.EDIT_DAY_MODAL._OVERLAP_ERROR_';
            }
        }

        save() {

            // run validation before continuing
            let _timeRanges = this.formatTimeRanges();
            this.runValidation(_timeRanges);
            this.isSaving = true;

            if(this.isInvalid) {
                this.isSaving = false;
                return;
            }

            this.$modalInstance.close({
                session: this.session,
                dayAvailability: this.dayAvailability,
                date: this.date,
                timeRanges: _timeRanges,
                applyAll: this.applyAll });
        }

        addTimeRange() {
            this.timeRanges.push({id: this.timeRanges.length, from: this.defaultTimes.from, to: this.defaultTimes.to});
        }

        unavailable() {
            this.timeRanges = [];
            this.isInvalid = false;
            this.invalidRanges = {};
            this.validationErrorMessage = "";
        }

        removeTimeRange(timeRange, $index) {
            this.timeRanges.splice($index, 1);
            let timeRanges = this.formatTimeRanges();
            this.runValidation(timeRanges);
        }

        keyDownStartTime(event) {
            if (event.keyCode !== 8 && event.keyCode !== 46) {
                event.preventDefault();
            }
        }
    };

}());
