(function () {
    "use strict";

    // @ngInject
    function AccountControllerCtor($scope, CompaniesManager, $stateParams, UsersManager, AppConfigService, SavingIndicatorService, $injector, $window, OnboardingService, Enums, $state, AppStates,
                                   ModalService, PopupMessageService, PendingTasksManager, SplashScreenService, StatsigService,ClientPortalService, RepositoryService, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AccountController';

        this.$window = $window;
        this.isSavingIndicatorActive = { value:false };
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.currentUserIsVendor = this.user.isVendor();
        this.loginTwoFactorAuthFeatureEnabled = true;
        SavingIndicatorService.registerModel(this.user);
        this.CompaniesManager = CompaniesManager;
        this.company = this.CompaniesManager.getCurrCompany();
        this.OnboardingService = OnboardingService;
        this.ModalService = ModalService;
        this.Enums = Enums;
        this.$state = $state;
        this.AppStates = AppStates;

        this.topNavigationActive = false;

        this.OnboardingService.triggerTrialIfNeeded($stateParams.shouldStartTrial);
        this.PopupMessageService = PopupMessageService;
        this.PendingTasksManager = PendingTasksManager;
        this.SplashScreenService = SplashScreenService;
        this.pendingCompanyInvites = [];
        this.getPendingInvitesForMember();
        this.ClientPortalService = ClientPortalService;
        this.RepositoryService = RepositoryService;

        StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.oooMode)
            .then(
                function(isOooModeEnabled) {
                    this.isOooModeEnabled = isOooModeEnabled;
                }
                .bind(this)
            );
            
        $timeout(function(){
            if ($stateParams.ooo) {
                document.getElementById('ooo').scrollIntoView({ behavior: 'smooth' });    
            }
        }, 300);  
        
        // ng2react - Account info
        this.accountInfoVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-settings-account-info').then(function (isEnabled) {
            this.accountInfoVariant = isEnabled ? 'react' : 'angular';
        }.bind(this)).catch(function () {
            this.accountInfoVariant = 'angular';
        }.bind(this));
    }

    Controllers.AccountController = Class(Controllers.BaseController, {

        constructor: AccountControllerCtor,

        onStartTrialClicked: function onStartTrialClicked() {
            if (!this.user.isClientUserSystemType()){
                  return this.OnboardingService.openInProductIntakeForm();
            }

            // is a ClientUser instance and should get a different experience
            this.ModalService.openStartTrialAsClientModal(this.user, this.getCompanyNameForClient());
        },

        getCompanyNameForClient: function getCompanyNameForClient() {
            return (this.$window.clientPortalConfiguration && this.$window.clientPortalConfiguration.title) || 'Your Vendor';
        },

        getPendingInvitesForMember: function getPendingInvitesForMember(){
            if(this.user.isClientUserSystemType()){
                return;
            }
            this.CompaniesManager.getPendingTeamMemberInvite(this.user._id)
                .then(function (res){
                    this.pendingCompanyInvites = (res.data || []);
                }.bind(this));
        },

        approvePendingInvite: function approvePendingInvite(invite_id){
            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.info,
                'COMPANY_SETTING.TEAM._APPROVE_COMPANY_INVITE_',
                function yes() {
                    this.SplashScreenService.showHB();
                    this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.approveCompanyInvite, {invite_id: invite_id})
                        .then(function sucsess(res){
                            this.UsersManager.logout();
                            this.$window.location.reload();
                        }.bind(this))
                        .catch(function fail(err){
                            this.PopupMessageService.showAlert(
                                this.PopupMessageService.severityTypes.error, "Error while trying to approve the invitation. Please contact us.");
                        }.bind(this))
                        .finally(function final() {
                            this.SplashScreenService.hideHB();
                        }.bind(this));
                }.bind(this),
                function no(){
                },
                'JOIN TEAM', 'CLOSE', 'COMPANY_SETTING.TEAM._APPROVE_COMPANY_INVITE_HEADER_');
        },

        declinePendingInvite: function declinePendingInvite(invite_id) {
            var self = this;
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
            'Are you sure you want to decline this invitation?',
                function clickedYeah() {
                this.CompaniesManager.declinePendingInvite(this.company._id, invite_id)
                    .catch(function err(res){
                        self.PopupMessageService.showAlert(
                            self.PopupMessageService.severityTypes.error, "Error while trying to decline the invitation. Please contact us.");
                    })
                    .finally(function (){
                        self.getPendingInvitesForMember();
                    });
            }.bind(this),
            function clickedNay(){
            }.bind(this));
        },

        getCompanyId: function getCompanyId() {
            var companyId = null;
            if (this.ClientPortalService.isInClientPortal()) {
                companyId = this.ClientPortalService.getConfiguration().company_id;
            } else {
                companyId = this.RepositoryService.storageGet(this.Constants.storage.SESSION_COMPANY_ID);
            }
            return companyId;
        },
    });
}());
