(function () {
    'use strict';

    // @ngInject
    Directives.MoveStageDropdown = function MoveStageDropdown(_, UsersManager, WorkspacesManager, AnalyticsService, Constants, $q,
                                                              ModalService, $translate, ReferralsManager, WorkspaceService, PopupMessageService, EventsManager) {

        // @ngInject
        function MoveStageDropDownControllerFunc($scope, AppStates, _, $timeout, $injector, $document) {
            var self = this;
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'MoveStageDropDownController';

            this._ = _;

            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.WorkspaceService = WorkspaceService;
            this.ModalService = ModalService;
            this.EventsManager = EventsManager;
            this.$q = $q;
            this.dropdownDataIsReady = true;
            this.currentStageIndex = 0;
            this.currentStage = null;
            this.showLoading = false;
            this.nextForwardStage = null;
            this.blockReasonsCollection = Constants.BlockReasons;
            this.dropDownIsShowingMsg = false;
            this.showStageDropdown = false;
            this.stageIsBooked = false;

            this.onBlockWorkspace = function (blockReason, $event) {
                if ($event) {
                    $event.stopPropagation();
                }

                angular.element('#' + self.disqualifyTestId).trigger('click');

                this._selectedBlockReason = blockReason;

                if (this.isReasonValidForReferral(blockReason)) {
                    this.dropDownIsShowingMsg = true;
                } else {
                    this.doBlockWorkspace();
                }
            };

            this.referEvent = function () {
                this.doBlockWorkspace();
                this.onWorkspaceReferred(this.workspace);
            };

            this.dontReferEvent = function () {
                this.doBlockWorkspace();
            };

            this.doBlockWorkspace = function doBlockWorkspace() {
                this.showLoading = true;
                var trackingData = {
                    workspace_id: self.workspace._id,
                    archiveReason: this._selectedBlockReason
                };

                return WorkspacesManager.blockWorkspace(this.workspace, this._selectedBlockReason)
                    .then(function () {
                        self.AnalyticsService.trackSuccess(self, self.AnalyticsService.analytics_events.workspace_block, trackingData);
                        self.notifyParent();
                    })
                    .catch(function (resp) {
                        self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.workspace_block, resp, trackingData);
                    })
                    .finally(function () {
                        self.openPipeline = false;
                        self.showLoading = false;
                        self.dropDownIsShowingMsg = false;
                    });

            };

            this.isReasonValidForReferral = function (reason) {
                return Constants.BlockReasonsForReferrals.indexOf(reason.serverValue) > -1;
            };

            this.notifyParent = function () {
                if (self.onWorkspaceChanged && angular.isFunction(self.onWorkspaceChanged)) {
                    self.onWorkspaceChanged();
                }
            };

            this.onUnblockWorkspace = function ($event) {
                $event.stopPropagation();

                var trackingData = {
                    workspace_id: self.workspace._id
                };

                WorkspacesManager.unblockWorkspace(this.workspace)
                    .then(function () {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_unblock + ' Successful ' + self.analyticsViewName, trackingData);
                        self.notifyParent();
                    })
                    .catch(function () {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_unblock + ' Failed' + self.analyticsViewName, trackingData);
                    });
            };
            
            this.handleArchiveReason = function handleArchiveReason(workspace, reason) {
                self.onBlockWorkspace(reason);
            };
            
            this.openArchiveReasonsModal = function openArchiveReasonsModal() {
                this.ModalService.openArchiveProjectModal(self.workspace._id, 'Workspace').then(function () {
                    self.notifyParent();
                    self.openPipeline = false;
                });
            };

            this.prepareStagesViewModel = function () {
                //we must copy the model otherwise we will use the original objects from company.user_pipeline_stages.
                self.stages = angular.copy(self.company.user_pipeline_stages);
                self.stages = this._.sortBy(self.stages, function(stage){
                    return stage.order ? stage.order : 0;
                });

                self.stages.forEach(function (stage) {
                    if(!stage.originalName){
                        stage.originalName = stage.name;
                    }
                    stage.name = self.WorkspaceService.getPipelineStageName(stage.originalName);
                }.bind(this));
            };

            this.refreshStagesViewModel = function () {
                if (self.workspace.workspace_pipeline_data && self.workspace.workspace_pipeline_data.current_stage && self.stages) {
                    var newStageId = self.workspace.workspace_pipeline_data.current_stage.stage_id;
                    self.currentStageIndex = _.findIndex(self.stages, {_id: newStageId});
                    self.currentStage = self.stages[self.currentStageIndex];
                    self.stages.forEach(function (stage, index) {
                        if (self.currentStageIndex > index) {
                            stage.actionType = 'backward';
                        } else if (self.currentStageIndex === index) {
                            stage.actionType = 'current';
                        } else {
                            stage.actionType = 'forward';
                        }
                    });

                    if (self.currentStageIndex < self.stages.length - 1) {
                        self.nextForwardStage = self.stages[self.currentStageIndex + 1];
                    } else {
                        self.nextForwardStage = null;
                    }
                }

                (self.currentStage && self.currentStage.category === 'booked') ? this.stageIsBooked = true : this.stageIsBooked = false;
            };

            if (this.workspace && this.workspace.workspace_pipeline_data && this.company && this.company.user_pipeline_stages) {
                //mark it as still not ready. make it ready when all the data comes.
                this.dropdownDataIsReady = true;
                this.prepareStagesViewModel();
                this.refreshStagesViewModel();
            } else if(this.isBulkActionOn) {
                this.dropdownDataIsReady = true;
                this.prepareStagesViewModel();
            } else {
                this.dropdownDataIsReady = false;
            }

            $scope.$watch('moveStageDropDownVm.workspace.workspace_pipeline_data.current_stage.stage_id', function (newVal, oldVal) {
                if (!newVal) {
                    /*
                    Somewhere in this big spaghetti codebase, we probably fetch a partial workspace object which doesn't have
                    the workspace_pipeline_data object. This causes the watch to trigger with newVal being undefined,
                    which causes dropdownDataIsReady to be false, which causes the whole dropdown to disappear.
                    In reality, current_stage.stage_id cannot be undefined, so we can safely ignore this case.
                     */
                    return;
                }
                if(newVal !== oldVal){ //avoid the initial watch triggering.
                    this.refreshViewModelAndStateIfNeeded();
                }
            }.bind(this));

            this.register(this.company, 'success', function companyUpdateSuccess() {
                this.refreshViewModelAndStateIfNeeded();
            });

            if(this.workspace) {
                this.moveToStageDropdownId = 'move-dropdown-' + this.workspace._id;
                this.blockWorkspaceDropdownId = 'block-dropdown-' + this.workspace._id;
                this.unblockWorkspaceDropdownId = 'unblock-dropdown-' + this.workspace._id;
                this.disqualifyTestId =           'js-disqualify-test-' + this.workspace._id;
            } else {
                this.moveToStageDropdownId = 'move-dropdown';
                this.blockWorkspaceDropdownId = 'block-dropdown';
                this.unblockWorkspaceDropdownId = 'unblock-dropdown';
                this.disqualifyTestId =           'js-disqualify-test';
            }

            this.onMoveWorkspaceToStage = function onMoveWorkspaceToStage(stageId, $event, forceMove) {
                if (!forceMove && this.currentStage && stageId === this.currentStage._id && !this.workspace.workspace_pipeline_data.disable_tracking || !stageId // this happens when we send the nextForwardStage and it is null cause it's the last one (no next to goto)
                    ) {
                    //nothing to change
                    return;
                }

                self.showLoading = true;

                var trackingData = {
                    workspace_id: self.workspace._id,
                    moveToStageId: stageId
                };

                WorkspacesManager.updateWorkspaceStage(this.workspace, stageId, this.isTrackingDisable() ? false : null)
                    .then(function success() {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_pipeline_move_to_stage + ' Successful ' + self.analyticsViewName, trackingData);
                        self.notifyParent();
                        // tell the pipeline workspace directive to do something.
                        self.movedNotification();
                        self.EventsManager.getEventsMasterCollection().trigger("workspaceStatusUpdated");
                    })
                    .catch(function failed(res) {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_pipeline_move_to_stage + ' Failed ' + self.analyticsViewName, trackingData);

                        if (res && res.data && res.data.error_message) {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, res.data.error_message);
                        } else {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                        }
                    })
                    .finally(function finallyHandler() {
                        self.showLoading = false;
                        self.showStageDropdown = false;
                    });
            };

            this.isTrackingDisable = function isTrackingDisable() {
                return this.workspace.workspace_pipeline_data.disable_tracking;
            };

            this.disablePipelineTracking = function disablePipelineTracking(track) {
                this.disableTracking = track;
                this.showLoading = true;

                var self = this;
                var trackingData = {
                    workspace_id: this.workspace._id,
                    disable_tracking: this.disableTracking,
                    source: "workspace pipeline dropdown"
                };

                WorkspacesManager.updateWorkspacePipelineSettings(this.workspace, this.disableTracking)
                    .then(function () {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' success', trackingData);
                    })
                    .catch(function () {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_update_pipeline_settings + ' failed', trackingData);
                        self.disableTracking = Boolean(self.workspace.workspace_pipeline_data.disable_tracking);
                    })
                    .finally(function () {
                        self.showLoading = false;
                    });
            };


            this.toggleStageDropdown = function toggleStageDropdown() {
                self.showStageDropdown = !self.showStageDropdown;

                $document.on('click.stage_dropdown.honeybook', function(event) {
                    if (self.showStageDropdown) {
                        var el = angular.element('#' + self.unblockWorkspaceDropdownId);
                        var toggleElParent = angular.element(el.parent('.pl-move-li-step').find('.js-stage-dropdown-toggle'));
                        var toggleElIcon = angular.element(el.parent('.pl-move-li-step').find('.icon-angle-down'));
                        var toggleElSpan = angular.element(el.parent('.pl-move-li-step').find('.current-forward-stage'));
                        var archiveToggleText = angular.element(el.parent('.pl-move-li-step').find('#js-unarchive-toggle-text'));
                        var archiveToggleParent = angular.element(el.parent('.pl-move-li-step').find('.Unarchive'));
                        if ((!el.is(event.target) && el.has(event.target).length === 0) && !toggleElIcon.is(event.target) && !toggleElSpan.is(event.target) && !toggleElParent.is(event.target) && !archiveToggleText.is(event.target) && !archiveToggleParent.is(event.target)) {
                            self.showStageDropdown = false;
                            $scope.$apply();
                        }
                    }
                });

                $scope.$on('$destroy', function() {
                    $document.off('click.stage_dropdown.honeybook');
                });
            };

        }


        var MoveStageDropdownController = Class(Controllers.BaseController, {
            constructor: MoveStageDropDownControllerFunc,

            refreshViewModelAndStateIfNeeded: function refreshViewModelAndStateIfNeeded() {
                if (this.company.user_pipeline_stages) {
                    this.prepareStagesViewModel();
                }

                this.dropdownDataIsReady = this.workspace.workspace_pipeline_data && this.stages;
                if (this.dropdownDataIsReady) {
                    this.refreshStagesViewModel();
                }
            }
        });

        return {
            scope: {
                workspace: '=?workspace',
                workspaceId: '=?workspaceId',
                company: '=company',
                onWorkspaceChanged: '&onWorkspaceChanged',
                analyticsViewName: '@analyticsViewName',
                movedNotification: '&movedNotification',
                dropDownIsShowingMsg: "=dropDownIsShowingMsg",
                openPipeline: "=?openPipeline",
                onWorkspaceReferred: '&onWorkspaceReferred',
                isPipelineMainView: '=isPipelineMainView',
                isBulkActionOn: '=?isBulkActionOn'
            },
            templateUrl: 'angular/app/modules/core/features/pipeline/workspace/move_stage_dropdown_template.html',
            controller: MoveStageDropdownController,
            controllerAs: 'moveStageDropDownVm',
            bindToController: true
        };
    };


}());


//pipelineWorkspaceIsShowing: "=pipelineWorkspaceIsShowing",