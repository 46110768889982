(function () {
    "use strict";

    // @ngInject
    function OnboardingMobileQuizControllerCtor($scope, $injector, $timeout, AbTestService, Enums, $q, DeviceService, WorkspaceFileService,
                                                 _, OnboardingService, EventService, UsersManager, AnalyticsService, $translate, UserService,
                                                $state, UiPersistenceService, $stateParams, CompaniesManager, CompanyService,
                                                IntercomService, CouponsService, $cookies, StatsigService, DatadogRUMService, ReactModalService,
                                                ConfettiService) {

        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'OnboardingMobileQuizController';

        this.$scope = $scope;
        this._ = _;
        this.UserService = UserService;
        this.UsersManager = UsersManager;
        this.$state = $state;
        this.OnboardingService = OnboardingService;
        this.EventService = EventService;
        this.AnalyticsService = AnalyticsService;
        this.UiPersistenceService = UiPersistenceService;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.$cookies = $cookies;
        this.CompaniesManager = CompaniesManager;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.DeviceService = DeviceService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.CompanyService = CompanyService;
        this.IntercomService = IntercomService;
        this.CouponsService = CouponsService;
        this.StatsigService = StatsigService;
        this.DatadogRUMService = DatadogRUMService;
        this.ReactModalService = ReactModalService;
        this.currUser = this.UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();
        this.isLoading = false;
        this.userTagData = {};
        this.isShowQuiz = false;
        this.smallLoading = false;
        this.StatsigService = StatsigService;
        this.isBrandImportAssetsSaved = true;
        this.isNewOnboardingQuiz = null;
        this.dataReady = false;
        this.isMweb = this.DeviceService.nxSmallBreakpoint() && !this.DeviceService.isInAppBrowser();
        this.ConfettiService = ConfettiService;

        this.initAngularQuiz = function initAngularQuiz() {
            if (this.$stateParams.purchasedPlan) {
                this.ConfettiService.show({ position: 'top', count: 300 });
                
                var planNames = {
                    'starter_monthly_2023': 'Starter monthly',
                    'essentials_monthly': 'Essentials monthly',
                    'premium_monthly': 'Premium monthly',
                    'starter_annual': 'Starter yearly',
                    'essentials_annual': 'Essentials yearly',
                    'premium_annual': 'Premium yearly'
                }
                
                this.purchasedPlanName = planNames[this.$stateParams.purchasedPlan];
            }
            
            var clientTypeGatePromise = this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.clientTypeQuizQuestionEnabled, false);
            this.FIRST_QUESTION_KEY = 'instantBranding';
            this.hideBackButtonOnQuestions = ['howCanHoneyBookHelpYouTheMost'];

            var showServicesHubPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.servicesHubPageForConsultants, 'show_page', false);
            Promise.allSettled([clientTypeGatePromise, showServicesHubPromise]).then(function(results) {
                this.isClientTypeQuestionEnabled = results[0].value;
                this.showServicesHub = results[1].value || this.currUser.isConsultantTrialStartedAfterServicesHubRolloutDate();
                this.initQuiz();
            }.bind(this));

            this.LAST_QUESTION_KEY = 'currentToolYouUse';

            var tagNamesInUse = {
                yearsInBusiness: 'Years_in_Business_Quiz',
                serviceGoodsOrBoth: 'Service_Goods_Or_Both_Quiz',
                whatNeedHelp: 'What_Do_You_Need_Help_With_Quiz',
                inquiriesPerMonth: 'Inquiries_Per_Month_Quiz',
                eventsPerMonth: 'Avg_Events_Jobs_Per_Month_Quiz',
                timeToBook: 'Time_To_Book_New_Lead_Quiz',
                avgRevenue: 'Avg_Annual_Revenue_Quiz',
                currentToolYouUse: 'Current_Tool_You_Use_Quiz',
                howCanHoneyBookHelpYouTheMost: 'How_Can_Honeybook_Help_You_The_Most_Quiz',
                instantBranding: 'Instant_Branding',
                whatClientType: 'What_client_type'
            };

            var firstName = this.currUser.first_name;

            this.handleClickAnswerReact = this.handleClickAnswer.bind(this);
            this.handleClickSkipReact = this.handleClickSkip.bind(this);

            angular.element(document).find('body')[0].classList.add('onboarding-mobile-quiz-body-bg-white');
            $scope.$on("$destroy", function() {
                angular.element(document).find('body')[0].classList.remove('onboarding-mobile-quiz-body-bg-white');
            });

            var questions = {
                whatClientType: function whatClientType(quiz) {
                    this.id = 'whatClientType';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE._TEXT_', {first_name: firstName});
                    this.userTagName = tagNamesInUse.whatClientType;
                    this.answers = [
                        {
                            id: 'businesses',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.BUSINESSES._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.BUSINESSES._HELPER_')
                        },
                        {
                            id: 'individuals',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.INDIVIDUALS._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.INDIVIDUALS._HELPER_')
                        },
                        {
                            id: 'both',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.BOTH._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_CLIENT_TYPE.ANSWERS.BOTH._HELPER_')
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.howLongInBusiness(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function() {
                        return new questions.howCanHoneyBookHelpYouTheMost(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                },

                howLongInBusiness: function howLongInBusiness(quiz) {
                    this.id = 'howLongInBusiness';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS._TEXT_', {first_name: firstName});
                    this.userTagName = tagNamesInUse.yearsInBusiness;
                    this.answers = [
                        {
                            id: 'notStarted',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NOT_STARTED._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NOT_STARTED._HELPER_'),
                            feedback: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NOT_STARTED._FEEDBACK_')
                        },
                        {
                            id: 'new',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NEW._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NEW._HELPER_'),
                            feedback: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.NEW._FEEDBACK_')
                        },
                        {
                            id: '1to2',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.ONE_TO_THREE._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.ONE_TO_THREE._HELPER_'),
                            feedback: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.ONE_TO_THREE._FEEDBACK_')
                        },
                        {
                            id: '3to4',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.FOUR_TO_FIVE._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.FOUR_TO_FIVE._HELPER_'),
                            feedback: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.FOUR_TO_FIVE._FEEDBACK_')
                        },
                        {
                            id: '5plus',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.SIX_PLUS._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.SIX_PLUS._HELPER_'),
                            feedback: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_LONG_IN_BUSINESS.ANSWERS.SIX_PLUS._FEEDBACK_')
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.averageAnnualRevenue(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function(options) {
                        return options.isClientTypeQuestionEnabled ?
                            new questions.whatClientType(quiz) :
                            new questions.howCanHoneyBookHelpYouTheMost(quiz);
                    };

                    this.goBack = function goBack(options) {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion(options));
                    };
                }, //2

                serviceGoodsOrBoth: function serviceGoodsOrBoth(quiz) {
                    this.id = 'serviceGoodsOrBoth';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.SERVICE_GOODS_OR_BOTH._TEXT_');
                    this.userTagName = tagNamesInUse.serviceGoodsOrBoth;
                    this.answers = [
                        {
                            id: 'service',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.SERVICE_GOODS_OR_BOTH.ANSWERS.SERVICE._TITLE_'),
                        },
                        {
                            id: 'sellingPhysicalOrDigital',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.SERVICE_GOODS_OR_BOTH.ANSWERS.GOODS._TITLE_'),
                        },
                        {
                            id: 'both',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.SERVICE_GOODS_OR_BOTH.ANSWERS.BOTH._TITLE_'),
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.whatNeedHelp(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.howCanHoneyBookHelpYouTheMost(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                },

                whatNeedHelp: function whatNeedHelp(quiz) {
                    this.id = 'whatNeedHelp';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST._TEXT_');
                    this.userTagName = tagNamesInUse.whatNeedHelp;
                    this.answers = [
                        {
                            id: 'closingMoreInquiries',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.LEADS._TITLE_'),
                        },
                        {
                            id: 'closingMoreIdealClients',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.IDEAL._TITLE_'),
                        },
                        {
                            id: 'growingMyRevenue',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.REVENUE._TITLE_'),
                        },
                        {
                            id: 'automatingMyBookings',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.AUTOMATING._TITLE_'),
                        },
                        {
                            id: 'keepingTrack',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.ONE_PLACE._TITLE_'),
                        },
                        {
                            id: 'clientExperience',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.EXPERIENCE._TITLE_'),
                        },
                        {
                            id: 'bookingProcess',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_NEED_MOST.ANSWERS.BOOKING._TITLE_'),
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.averageAnnualRevenue(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function() {
                        return new questions.howCanHoneyBookHelpYouTheMost(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                },

                inquiriesPerMonth: function inquiriesPerMonth(quiz) {
                    this.id = "inquiriesPerMonth";
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES._TEXT_');
                    this.userTagName = tagNamesInUse.inquiriesPerMonth;
                    this.answers = [
                        {
                            id: "0to5",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES.ANSWERS.UP_TO_FIVE._TITLE_'),
                        },
                        {
                            id: "6to10",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES.ANSWERS.SIX_TO_TEN._TITLE_'),
                        },
                        {
                            id: "11to20",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES.ANSWERS.UP_TO_TWENTY._TITLE_'),
                        },
                        {
                            id: "21to50",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES.ANSWERS.UP_TO_FIFTY._TITLE_'),
                        },
                        {
                            id: "51Plus",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_INQUIRIES.ANSWERS.FIFTY_PLUS._TITLE_'),
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.eventsPerMonth(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.whatNeedHelp(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                },

                eventsPerMonth: function eventsPerMonth(quiz) {
                    this.id = "eventsPerMonth";
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS._TEXT_');
                    this.userTagName = tagNamesInUse.eventsPerMonth;
                    this.answers = [
                        {
                            id: "0to1",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS.ANSWERS.ZERO_TO_ONE._TITLE_'),
                        },
                        {
                            id: "2to3",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS.ANSWERS.TWO_TO_THREE._TITLE_'),
                        },
                        {
                            id: "4to5",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS.ANSWERS.FOUR_TO_FIVE._TITLE_'),
                        },
                        {
                            id: "6to10",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS.ANSWERS.SIX_TO_TEN._TITLE_'),
                        },
                        {
                            id: "10Plus",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_MANY_PROJECTS.ANSWERS.TEN_PLUS._TITLE_'),
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.timeToBook(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.whatNeedHelp(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };

                },

                timeToBook: function timeToBook(quiz) {
                    this.id = "timeToBook";
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK._TEXT_');
                    this.userTagName = tagNamesInUse.timeToBook;
                    this.answers = [
                        {
                            id: "lessThenADay",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK.ANSWERS.LESS_THAN_DAY._TITLE_'),
                        },
                        {
                            id: "lessThenAWeek",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK.ANSWERS.LESS_THEN_WEEK._TITLE_'),
                        },
                        {
                            id: "1to2Weeks",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK.ANSWERS.ONE_TO_TWO_WEEKS._TITLE_'),
                        },
                        {
                            id: "3to4Weeks",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK.ANSWERS.THREE_TO_FOUR_WEEKS._TITLE_'),
                        },
                        {
                            id: "monthPlus",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.TIME_TO_BOOK.ANSWERS.MONTH._TITLE_'),
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.averageAnnualRevenue(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };

                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.eventsPerMonth(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                },

                averageAnnualRevenue: function averageAnnualRevenue(quiz) {
                    this.id = "averageAnnualRevenue";
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE._TEXT_');
                    this.userTagName = tagNamesInUse.avgRevenue;
                    this.answers = [
                        {
                            id: "0-5k",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE.ANSWERS.LESS10K._TITLE_'),
                            isAbove20k: false,
                        },
                        {
                            id: "5k-20k",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE.ANSWERS.K5_TO_K20._TITLE_'),
                            isAbove20k: false,
                        },
                        {
                            id: "20k-50k",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE.ANSWERS.K20_TO_K50._TITLE_'),
                            isAbove20k: true,
                        },
                        {
                            id: "50k-200k",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE.ANSWERS.K50_TO_K200._TITLE_'),
                            isAbove20k: true,
                        },
                        {
                            id: "200k-plus",
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.WHAT_AVERAGE_ANNUAL_REVENUE.ANSWERS.MORE200K._TITLE_'),
                            isAbove20k: true,
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.currentToolYouUse(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };


                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.howLongInBusiness(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                }, //3

                currentToolYouUse: function currentToolYouUse(quiz) {
                    this.id = 'currentToolYouUse';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE._TEXT_');
                    this.userTagName = tagNamesInUse.currentToolYouUse;
                    this.answers = [
                        {
                            id: 'anotherCrm',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.ANOTHER_CRM._TITLE_')
                        },
                        {
                            id: 'accountingSoftware',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.ACCOUNTING_SOFTWARE._TITLE_')
                        },
                        {
                            id: 'googleDocsEmail',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.GOOGLE_DOCS_EMAIL._TITLE_')
                        },
                        {
                            id: 'schedulingSoftware',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.SCHEDULING_SOFTWARE._TITLE_')
                        },
                        {
                            id: 'offlineMethods',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.OFFLINE_METHODS._TITLE_')
                        },
                        {
                            id: 'other',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.CURRENT_TOOL_YOU_USE.ANSWERS.OTHER._TITLE_')
                        }
                    ];

                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.averageAnnualRevenue(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };

                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.currentToolYouUse(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };
                }, //4

                howCanHoneyBookHelpYouTheMost: function howCanHoneyBookHelpYouTheMost(quiz) {
                    this.id = 'howCanHoneyBookHelpYouTheMost';
                    this.title = $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST._TEXT_', {first_name: firstName});
                    this.userTagName = tagNamesInUse.howCanHoneyBookHelpYouTheMost;
                    this.answers = [
                        {
                            id: 'invoices',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.INVOICES._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.INVOICES._HELPER_')
                        },
                        {
                            id: 'contracts',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.CONTRACTS._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.CONTRACTS._HELPER_')
                        },
                        {
                            id: 'brochuresAndQuestionnaires',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.BROCHURES_AND_QUESTIONNAIRES._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.BROCHURES_AND_QUESTIONNAIRES._HELPER_')
                        },
                        {
                            id: 'leadCapture',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.LEAD_CAPTURE._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.LEAD_CAPTURE._HELPER_')
                        },
                        {
                            id: 'crm',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.BUSINESS_MANAGEMENT._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.BUSINESS_MANAGEMENT._HELPER_')
                        },
                        {
                            id: 'scheduling',
                            title: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.SCHEDULING._TITLE_'),
                            helper: $translate.instant('ONBOARDING_MOBILE_QUIZ.QUESTIONS.HOW_CAN_HONEYBOOK_HELP_YOU_THE_MOST.ANSWERS.SCHEDULING._HELPER_')
                        }
                    ];

                    this.getNextQuestion = function getNextQuestion(isClientTypeQuestionEnabled) {
                        return isClientTypeQuestionEnabled ?
                            new questions.whatClientType(quiz) :
                            new questions.howLongInBusiness(quiz);
                    };

                    this.goNext = function goNext(isClientTypeQuestionEnabled) {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion(isClientTypeQuestionEnabled));
                    };


                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.instantBranding(quiz);
                            
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                }, //1,

                instantBranding: function instantBranding(quiz) {
                    this.id = 'instantBranding';
                    this.userTagName = tagNamesInUse.instantBranding;
                    this.answers = [];


                    this.getNextQuestion = function getNextQuestion() {
                        return new questions.howCanHoneyBookHelpYouTheMost(quiz);
                    };

                    this.goNext = function goNext() {
                        quiz.nextIndex();
                        quiz.setQuestion(this.getNextQuestion());
                    };


                    this.getPreviousQuestion = function getPreviousQuestion() {
                        return new questions.instantBranding(quiz);
                    };

                    this.goBack = function goBack() {
                        quiz.previousIndex();
                        quiz.setQuestion(this.getPreviousQuestion());
                    };
                }, //2,
            };

            var Quiz = function Quiz(options) {
                var currentQuestion = {};
                var answers;
                var currentIndex = 1;
                var length = options.length;
                var firstQuestionKey = options.firstQuestionKey;
                var lastQuestionKey = options.lastQuestionKey;

                this.start = function start(startQuestionKey) {
                    currentIndex = 1;
                    if (startQuestionKey) {
                        this.setQuestion(new questions[startQuestionKey](this));
                    }
                    answers = {};
                };

                this.setQuestion = function setQuestion(question) {
                    currentQuestion = question;
                };

                var _updateCurrentIndex = function _updateCurrentIndex() {
                    var stepsPassed = 1;
                    var tempCurrentQuestion = angular.copy(currentQuestion);
                    while (tempCurrentQuestion.id !== firstQuestionKey) {
                        tempCurrentQuestion = tempCurrentQuestion.getPreviousQuestion({ isClientTypeQuestionEnabled: options.isClientTypeQuestionEnabled });
                        stepsPassed++;
                    }
                    currentIndex = stepsPassed;
                };

                this.setQuestionById = function setQuestionById(questionId) {
                    _.each(questions, function(question, key) {
                        if (key === questionId) {
                            this.setQuestion(new question(this));
                            _updateCurrentIndex();
                        }
                    }.bind(this));
                };

                this.getCurrentQuestion = function getCurrentQuestion() {
                    return currentQuestion;
                };

                this.setAnswer = function setAnswer(answer) {
                    answers[currentQuestion.id] = answer;
                };

                this.getCurrentAnswer = function getCurrentAnswer() {
                    return this.getAnswerByQuestionId(currentQuestion.id);
                };

                this.getCurrentAnswerObject = function() {
                    return currentQuestion.answers.find(function(answer) {
                        return answer.id === this.getCurrentAnswer();
                    }.bind(this));
                };

                this.getAnswerByQuestionId = function getAnswerByQuestionId(questionId) {
                    return answers[questionId];
                };

                this.nextIndex = function() {
                    if (currentIndex <= length) {
                        currentIndex++;
                    }
                };

                this.previousIndex = function() {
                    if (currentIndex > 0) {
                        currentIndex--;
                    }
                };

                this.getLength = function getLength() {
                    return length;
                };

                this.getLastQuestionKey = function getLastQuestionKey() {
                    return lastQuestionKey;
                };

                this.getFirstQuestionKey = function getFirstQuestionKey() {
                    return firstQuestionKey;
                };

                this.getCurrentIndex = function getCurrentIndex() {
                    return currentIndex;
                };

            };

            this.calcQuizLength = function calcQuizLength() {
                return this.isClientTypeQuestionEnabled ? 6 : 5;
            };

            this.initQuiz = function initQuiz() {
                this.quizOptions = {
                    length: this.calcQuizLength(),
                    firstQuestionKey: this.FIRST_QUESTION_KEY,
                    lastQuestionKey: this.LAST_QUESTION_KEY, 
                    isClientTypeQuestionEnabled: this.isClientTypeQuestionEnabled
                };

                this.quiz = new Quiz(this.quizOptions);

                var startQuestionKey = this.FIRST_QUESTION_KEY;
                this.quiz.start(startQuestionKey);

                if ($stateParams.step && !!questions[$stateParams.step]) {
                    this.quiz.setQuestionById($stateParams.step);
                }

                this.trackQuestionLoaded();
                this.dataReady = true;

                this.$timeout(function() {
                    this.isShowQuiz = true;
                }.bind(this), 500);
            };
        };

        if (this.isQuizCompleted()) {
            this.goToOnboardingEntryPointState();
            return;
        }

        this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.mobileQuizOnboardingStarted, { started: true });

        if (this.isMweb) {
            this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.quizRedesignMweb, 'variant', 'control_old')
                .then(function success(variant) {
                    this.onboardingQuizVariant = variant;
                    this.isNewOnboardingQuiz = variant === 'v_redesign';

                    if (!this.isNewOnboardingQuiz) {
                        this.initAngularQuiz();
                    } 
                }.bind(this));
        } else {
            this.isNewOnboardingQuiz = true;
        }
            
        this.reportIntercom();
    }

    Controllers.OnboardingMobileQuizController = Class(Controllers.BaseController, {
        constructor: OnboardingMobileQuizControllerCtor,

        reportIntercom: function reportIntercom() {
            var isTrialerDiscount = !!(this.currUser.isReferredByMemberReferral() ||
                this.$cookies.get('growSumoPartnerKey') ||
                this.CouponsService.getCoupon());
            this.IntercomService.init(this.currUser);
            this.IntercomService.identify({trialer_discount: isTrialerDiscount}, true);
        },

        isQuizCompleted: function isQuizCompleted() {
            var mobileQuizOnboardingCompleted = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.mobileQuizOnboardingCompleted, {});
            return mobileQuizOnboardingCompleted && mobileQuizOnboardingCompleted.completed;
        },

        trackQuestionLoaded: function trackQuestionLoaded() {
            var source = 'short';

            this.AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.onboarding_mobile_quiz_load_panel, {
                source: source,
                initial_step: this.quiz.getCurrentQuestion().id,
                quiz_variant: this.onboardingQuizVariant
            });

            this.AnalyticsService.reportEvent('QuizQuestionLoaded', {question: this.quiz.getCurrentQuestion().id, quiz_variant: this.onboardingQuizVariant});
        },

        handleClickAnswer: function handleClickAnswer(event, answer) {
            event.stopPropagation();
            event.preventDefault();

            var source = 'short';
            var questionId = this.quiz.getCurrentQuestion().id;

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_mobile_quiz_load_panel, {
                source: source,
                initial_step: questionId,
                quiz_answer: answer.id,
                quiz_variant: this.onboardingQuizVariant
            });

            this.AnalyticsService.reportEvent('QuizQuestionAnswered', {question: questionId, answer: answer.id, quiz_variant: this.onboardingQuizVariant});

            if (answer.isAbove20k) {
                this.AnalyticsService.reportEvent('Revenue Quiz Above 20k', {quiz_variant: this.onboardingQuizVariant});
            }

            answer.isLoading = true;
            this.quiz.setAnswer(answer.id);
            this.updateUserTagsObject(answer);

            this.$timeout(function() {
                answer.isLoading = false;
                this.nextStep();
            }.bind(this), 800);

        },

        updateUserTagsObject: function updateUserTags(answer) {
            var question = this.quiz.getCurrentQuestion();
            this.userTagData[question.userTagName] = answer.id;
        },

        handleClickSkip: function handleClickSkip() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_mobile_quiz_skip,
                {
                    step: this.quiz.getCurrentQuestion().id,
                    quiz_variant: this.onboardingQuizVariant
                }
            );

            this.AnalyticsService.reportEvent('QuizQuestionSkipped', {question: this.quiz.getCurrentQuestion().id, quiz_variant: this.onboardingQuizVariant});

            if (this.isLastQuestion()) {
                this.handleClickDone();
            } else {
                this.nextStep();
            }
        },

        handleClickBack: function handleClickBack() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_mobile_quiz_back,
                {
                    step: this.quiz.getCurrentQuestion().id
                }
            );

            this.AnalyticsService.reportEvent('QuizBackClicked', {question: this.quiz.getCurrentQuestion().id});

            this.previousStep();
        },

        nextStep: function nextStep() {
            var currentQuestion = this.quiz.getCurrentQuestion();
            if (currentQuestion.id === this.quiz.getLastQuestionKey()) {
                return this.handleClickDone();
            }
            if (currentQuestion.id === 'howCanHoneyBookHelpYouTheMost') {
                currentQuestion.goNext(this.isClientTypeQuestionEnabled);
            } else {
                currentQuestion.goNext();
            }

            this.trackQuestionLoaded();
            var nextStep = this.quiz.getCurrentQuestion();
            //this controller serves two templates and states so this ensures we route to the correct one
            this.$state.go(this.$state.current.name, { step: nextStep.id }, { notify: false });
        },

        previousStep: function previousStep() {
            var currentQuestion = this.quiz.getCurrentQuestion();
            currentQuestion.goBack({ isClientTypeQuestionEnabled: this.isClientTypeQuestionEnabled });
            var previousStep = this.quiz.getCurrentQuestion();
            this.$state.go(this.$state.current.name, { step: previousStep.id }, { notify: false });
        },

        isLastQuestion: function() {
            return (this.quiz.getCurrentQuestion().id === this.LAST_QUESTION_KEY);
        },

        isFirstQuestion: function isFirstQuestion() {
            //  This should be changed upon change of logic
            return (this.quiz.getCurrentQuestion().id === this.FIRST_QUESTION_KEY);
        },

        showBack: function showBack() {
            return !this.isFirstQuestion() && !this.hideBackButtonOnQuestions.includes(this.quiz.getCurrentQuestion().id);
        },

        showSteps: function showSteps() {
            return !this.isFirstQuestion();
        },

        shouldShowSkip: function shouldShowSkip() {
            return !this.isFirstQuestion();
        },

        updateUserIntent: function updateUserIntent() {
            var intentAnswer = this.quiz.getAnswerByQuestionId('howCanHoneyBookHelpYouTheMost');
            this.UsersManager.updateCurrUserPropertiesOptimistically({user_intent: intentAnswer});
        },

        fetchInstantBrandingSuggestedAssets: function fetchInstantBrandingSuggestedAssets() {
            if (this.isBrandImportAssetsSaved) {
                return this.CompanyService.updateCompanyWithSuggestedAssets(this.company._id)
                    .then(function(isAssetsSaved) {
                        if (!isAssetsSaved) {
                            this.isBrandImportAssetsSaved = false;
                        }
                    }.bind(this))
                    .catch(function(error) {
                        this.DatadogRUMService.addError(error, {
                            instant_branding: true,
                            message: 'Failed to update instant branding assets'
                        });
                        this.isBrandImportAssetsSaved = false;
                    }.bind(this));
            }
            return Promise.resolve();
        },

        handleClickDone: function handleClickDone() {
            this.OnboardingService.skipPreAppOnboarding();

            if (!this._.isEmpty(this.userTagData)) {
                this.UsersManager.addTagsToCurrUser(this.userTagData).then(function() {
                    this.OnboardingService.initOnboardingData();
                }.bind(this));

                this.AnalyticsService.reportEvent('quiz', angular.extend({}, this.userTagData, {quiz_variant: this.onboardingQuizVariant}));
            }

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_mobile_quiz_done, angular.extend({}, this.userTagData, {quiz_variant: this.onboardingQuizVariant}));
            this.AnalyticsService.reportEvent('QuizDone', { quiz_variant: this.onboardingQuizVariant });
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.mobileQuizOnboardingCompleted, {completed: true});
            this.updateUserIntent();
            var onQuizDoneAbTestsPromise = this.AbTestService.onQuizDone();
            var fetchInstantBrandingAssetsPromise = this.fetchInstantBrandingSuggestedAssets();
            var displayPicTimeWelcomeModal = (this.currUser &&
                this.currUser.attribution &&
                this.currUser.attribution.user_utm_params &&
                this.currUser.attribution.user_utm_params.utm_source === "pictime");

            Promise.all([onQuizDoneAbTestsPromise, fetchInstantBrandingAssetsPromise]).finally(function() {
                this.goToOnboardingEntryPointState({
                    isInstantBrandingEnabled: this.isBrandImportAssetsSaved,
                    displayPicTimeWelcomeModal: displayPicTimeWelcomeModal
                });
                this.OnboardingService.setShouldShowBeforeOnboardingModal(true);
            }.bind(this));
        },

        goToOnboardingEntryPointState: function goToOnboardingEntryPointState(params) {
            var nextRoute = this.OnboardingService.getOnboardingEntryPointState();
            this.$state.go(nextRoute, params);
        },

        getAnswerFeedback: function getAnswerFeedback() {
            if(angular.isFunction(this.quiz.getCurrentQuestion().getPreviousQuestion)) {
                var prevQuestion = this.quiz.getCurrentQuestion().getPreviousQuestion();

                // find the answer selected for the previous question
                var prevAnswerId = this.quiz.getAnswerByQuestionId(prevQuestion.id);
                var prevAnswer = this._.detect(prevQuestion.answers, function(answer) {
                    return answer.id === prevAnswerId;
                });

                if(prevAnswer) {
                    return prevAnswer.feedback;
                }
            }
        },

        isCTADisabled: function() {
            return !this.quiz.getCurrentAnswerObject() || this.quiz.getCurrentAnswerObject().isLoading;
        },
    });
}());
