(function () {
    "use strict";

    class RescheduleChooseContractComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, RescheduleProjectService) {

            super($scope, $injector);
            this.__objectType = 'RescheduleChooseContractComponent';
            this.AnalyticsService = AnalyticsService;
            this.RescheduleProjectService = RescheduleProjectService;
        }

        $onInit() {
            this.contractOptions = [
            {
                id: this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.existing,
                title: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._UPDATE_EXISTING_CONTRACT_TITLE_',
                description: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._UPDATE_EXITING_CONTRACT_DESCRIPTION_',
                selected: true
            },
            {
                id: this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.amendment,
                title: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._SEND_AMENDMENTS_TITLE_',
                description: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._SEND_AMENDMENTS_DESCRIPTION_',
                selected: false
            }, {
                id: this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.new,
                title: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._CREATE_NEW_CONTRACT_TITLE_',
                description: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._CREATE_NEW_CONTRACT_DESCRIPTION_',
                selected: false
            }, {
                id: this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.noChanges,
                title: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._NO_CHANGES_TITLE_',
                description: 'RESCHEDULE_PROJECT.CHOOSE_CONTRACT.OPTIONS._NO_CHANGES_DESCRIPTION_',
                selected: false
            }];

            this.existingSelection = this.RescheduleProjectService.getEditContractChoice();
            this.contractOptionSelectedId = this.existingSelection || this.contractOptions[0].id;
            this.onSelectionChange(this.contractOptionSelectedId);

            const existingContractSelection = this.RescheduleProjectService.getExistingContractChoice();
            this.selectedContract = existingContractSelection ||  this.contractFiles[0];
            this.onContractChange(this.selectedContract);
        }

        onSelectionChange(selection) {
            this.RescheduleProjectService.setEditContractSelection(selection);
        }

        onContractChange(contractSelected){
            this.RescheduleProjectService.setExistingContractChoice(contractSelected);
        }

        complete() {
            // in case the selection had changed and we already created a contract
            if (this.RescheduleProjectService.hasCreatedContract() && this.contractOptionSelectedId !== this.existingSelection) {
                this.RescheduleProjectService.getCreatedContract().deleteWorkspaceFile();
                this.RescheduleProjectService.setCreatedContract(null);
            }

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.reschedule_project_choose_contract_selection + this.contractOptionSelectedId, {source: this.source});

            this.onComplete && this.onComplete();
        }

        dismiss() {
            this.onDismiss && this.onDismiss();
        }
    }

    Components.RescheduleChooseContractComponent = {
        bindings: {
            source: '=',
            contractFiles: '<',
            onComplete: '&',
            onDismiss: '&',
            nextStepTitle: '<'
        },
        controller: RescheduleChooseContractComponent,
        name: 'hbRescheduleChooseContract',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_choose_contract.html',
    };

}());
