(function () {
    'use strict';

    const DEFAULT_TOAST_DISMISS_TIMEOUT = 3000;
    
    Services.ToastService = class ToastService {

        // @ngInject
        constructor(ngToast, _, $translate, ToastMappings, $templateCache, $sce, $controller, $rootScope) {
            this.ngToast = ngToast;
            this._ = _;
            this.$translate = $translate;
            this.ToastMappings = ToastMappings;
            this.$templateCache = $templateCache;
            this.$sce = $sce;
            this.$controller = $controller;
            this.$rootScope = $rootScope;
        }

        _show(options, mapping, data) {

            // build options
            let _options = {
                content: '',
                className: '',
                timeout: DEFAULT_TOAST_DISMISS_TIMEOUT,
                dismissOnTimeout: true,
                contentWrapperClass: null
            };

            _options = this._.extend({}, _options, (options || {}));

            // handle our custom options
            const contentClass = ["nx-toast__content"];

            // template
            let controller = null;
            if (mapping && !_options.content) {
                _options.content = this._getTemplate(mapping.templateUrl);
                controller = this._getController(mapping.controller, data);
                _options.compileContent = controller.$scope;
            }

            // contentTranslation (translationId / translationObject)
            if (_options.contentTranslation) {
                const toastContentText = this._translate(_options.contentTranslation);
                _options.content = _options.content + toastContentText;
            }

            // title (simple text)
            if (_options.title) {
                const toastTitle = `<div><strong>${_options.title}</strong></div>`;
                _options.content = toastTitle + _options.content;
            }

            // titleTranslation (translationId / translationObject)
            if(_options.titleTranslation) {
                const toastTitleText = this._translate(_options.titleTranslation);
                const toastTitleEl = `<div><strong>${toastTitleText}</strong></div>`;
                _options.content = toastTitleEl + _options.content;
            }

            // iconCssClass
            if(_options.iconCssClass) {
                const toastIcon = `<span class="${_options.iconCssClass}"></span>`;
                _options.content = `${toastIcon}<div>${_options.content}</div>`;
                contentClass.push("nx-toast__content--with-icon");
            }

            // contentWrapperClass
            if(_options.contentWrapperClass) {
                contentClass.push(_options.contentWrapperClass);
            }

            // always wrap content
            const finalContent = `<div class="${contentClass.join(' ')}">${_options.content}</div>`;
            if (_options.compileContent) {
                // We need the content to be double wrapped in spans to match all the css
                _options.content = this.$sce.trustAsHtml(`<span><span>${finalContent}</span></span>`);
            } else {
                _options.content = finalContent;
            }

            // create toast
            const toastId = this.ngToast.create(_options);

            if (controller) {
                this._buildToastInstance(controller, toastId, data);
            }

            return toastId;
        }

        _translate(translation) {
            return this._.isObject(translation) ?
                this.$translate.instant(translation.translationId, translation.interpolateParams) :
                this.$translate.instant(translation);
        }

        show(options, mapping) {
            return this._show(options, mapping);
        }

        showSuccess(options, mapping, data) {
            return this._show(this._.extend({className: 'success'}, (options || {})), mapping, data);
        }

        showInfo(options, mapping) {
            return this._show(this._.extend({className: 'info'}, (options || {})), mapping);
        }

        showWarning(options, mapping) {
            return this._show(this._.extend({className: 'warning'}, (options || {})), mapping);
        }

        showError(options, mapping) {
            return this._show(this._.extend({className: 'danger'}, (options || {})), mapping);
        }

        dismiss(toastId) {
            this.ngToast.dismiss(toastId);
        }

        updateToast(text, toastId){
            const selectorStr = "li[data-message-id='" + toastId.toString() +"']";
            const element = document.querySelectorAll(selectorStr)[0];
            if(element) {
                const toastText = element.getElementsByClassName('nx-toast__content')[0].innerText;
                const devidedText = toastText.split('\n');
                // Toast has title
                if (devidedText.length > 1) {
                    const elementToShow = `<div><strong>${devidedText[0]}</strong></div>` + text;
                    // Toast has icon
                    if (element.getElementsByClassName('nx-toast__content')[0].childElementCount === 2) {
                        element.getElementsByClassName('nx-toast__content')[0].children[1].innerHTML = elementToShow;
                    } else {
                        element.getElementsByClassName('nx-toast__content')[0].innerHTML = elementToShow;
                    }
                } else {
                    element.getElementsByClassName('nx-toast__content')[0].innerText = text;
                }
            }
        }

        // ===================================================
        // specific toasts with templates
        // ===================================================

        showAchPushToast() {
            return this.showSuccess({
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                timeout: 4000,
                dismissButton: true,
            }, this.ToastMappings.AchPush);
        }

        showRescheduleSuccessToast(project, workspaceID, wasEmailSent) {
            return this.showSuccess({
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                timeout: 4000,
                dismissButton: true
            }, this.ToastMappings.RescheduleSuccess, {project, workspaceID, wasEmailSent});
        }

        // ===================================================
        // private
        // ===================================================

        _getTemplate(template) {
            return this.$templateCache.get(template);
        }

        _getController(controller, data) {
            return this.$controller(controller, this._.extend({
                $scope: this.$rootScope.$new(true),
                toastInstance: {}
            }, data));
        }

        _buildToastInstance(controller, toastId) {
            controller.toastInstance.dismiss = () => {
                this.dismiss(toastId);
            };
        }
    };

}());