(function () {
    'use strict';

    // @ngInject
    function ArchiveReasonsModalControllerCtor($scope, $injector, $modalInstance, workspace, reasons, handleArchiveReason) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ArchiveReasonsModalController';
        
        this.$modalInstance = $modalInstance;
        this.handleArchiveReason = handleArchiveReason;
        this.workspace = workspace;
        this.reasons = reasons;
        this.selectedReason = null;
    }

    Controllers.ArchiveReasonsModalController = Class(Controllers.BaseController, {
        
        constructor: ArchiveReasonsModalControllerCtor,
        
        select: function select($event) {
            var reasons = angular.element(
                document.querySelectorAll('.archive-modal__reason')
            );
            angular.forEach(reasons, function (reason) {
                angular.element(reason).removeClass('selected');
            });
            angular.element($event.currentTarget).addClass('selected');
            this.selectedReason = angular.element($event.currentTarget).attr('data-id')
            this.enableSubmit();
        },
        
        enableSubmit: function enableSubmit() {
            if (this.selectedReason) {
                angular.element(document.querySelector('.archive-modal__ok')).addClass('enabled');
            }
        },
        
        submit: function submit() {
            if (this.selectedReason) {
                this.handleArchiveReason(this.workspace, this.selectedReason);
                this.$modalInstance.dismiss('submit');
            }
        },
        
        cancel: function cancel() {
            this.$modalInstance.dismiss('cancel');
        }
        
    });

}());