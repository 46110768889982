/**
 * Created by inonstelman on 1/5/15.
 */
(function () {
    "use strict";
    // this is `FacebookService` service (and NOT `FacebookUtilsService`)
    // `FB` service is used to loads Facebook JS SDK
    // `FacebookService` uses `FB` to use the SDK functions
    // it is also different from Facebook Pixel, which is loaded by Segment.io

    //@ngInject
    function FacebookServiceCtor(FB, $q, $timeout, AnalyticsService) {

        var self = this;
        this.$timeout = $timeout;
        this.$q = $q;
        this.FB = FB;
        this.AnalyticsService = AnalyticsService;
        self.facebookLoginResponse = null;
        FB.then(function success(FB) {
            function statusChangeCallback(response) {
                self.facebookLoginResponse = response;
            }

            FB.Event.subscribe('auth.authResponseChange', statusChangeCallback);
            return this.$q.resolve(FB);
        }.bind(this))
        .then(function(FB) {
            // this is just to trigger the auth.authResponseChange event on line 20
            FB.getLoginStatus(function() {});
            return this.$q.resolve(FB);
        }.bind(this))
        ;
    }

    Services.FacebookService = Class(function () {
        return {

            permissions_types: {
                lead_ads: 'business_management,pages_manage_ads,pages_read_engagement,pages_show_list,leads_retrieval,pages_manage_metadata,ads_management'
            },

            constructor: FacebookServiceCtor,

            handleLogin: function handleLogin(permissionsToAsk) {
                var defer = this.$q.defer();

                this.FB.then(function(FB) {
                    FB.login(function onLoginResponse(loginResponse) {
                            if (loginResponse.authResponse) {
                                this.facebookLoginData().then(function(data) {
                                    defer.resolve(data);
                                }).catch(function(error) {
                                    defer.resolve({
                                        user_id: loginResponse.authResponse.userID,
                                        auth_token: loginResponse.authResponse.accessToken
                                    });
                                });
                            } else {
                                this.AnalyticsService.track(this, 'FB login error', {data: JSON.stringify(loginResponse).toString(), permissionsToAsk: permissionsToAsk});
                                defer.reject({status: 'Failed to login to facebook'});
                            }
                        }.bind(this),
                        {scope: permissionsToAsk});
                }.bind(this));
                return defer.promise;
            },

            addContactUsToFbPage: function addContactUsToFbPage(facebookPageID, facebookPageAccessToken, shareLink) {

                // shareLink = "https://www.honeybook.com/widget/testhoneybook"; //Get this from company.hb_url
                // facebookPageAccessToken = "EAAXc08YDvS0BAPb6rS4WZA4QZA7dtdv5i9eYBnZBoZBBotZCcPDY1XiuN4F51HYEFCu7h36eDiH2PNkMsEkHJi6gf9Lfc2IqbMxsPARGUfAdn2LsiexwsAKl4ZAuhDsx64KQut3VmEZBGNBgMFy8CAsM6mMXKrOzAxi1jpzrnOCvu1A3iim9I1UOSLZByajrLvagDfYA1iRhUwZDZD";
                // facebookPageID = "613015619081357"; //Get this from sergio

                var defer = this.$q.defer();
                var promise = defer.promise;
                var cta_params = {type: "CONTACT_US", web_url: shareLink, web_destination_type: 'WEBSITE', access_token:facebookPageAccessToken};

                this.FB.then(function(FB) {
                    FB.api('/'+facebookPageID+'/call_to_actions','post', cta_params ,function (response) {
                        if(response.error) {
                            defer.reject(response.error);
                        } else {
                            defer.resolve("Contact Us button was added to you page!");
                        }
                    });
                });
                return promise;
            },


            logoutOfFacebook: function logoutOfFacebook() {
                this.FB.then(function(FB) {
                    FB.getLoginStatus(function(response) {
                        if (response && response.status === 'connected') {
                            FB.logout(function(response) {
                                // user is now logged out
                            });
                        }
                    });
                });
            },

            hasPermissions: function hasPermissions(required_permissions){

                var deferred = this.$q.defer();
                var permissions_to_ask = (!!required_permissions && required_permissions !== '') ? required_permissions : 'email';
                var permissionsChackTO = this.$timeout(function error(){
                    deferred.reject('timeout');
                }.bind(this), 8000);
                this.FB.then(function(FB) {
                    FB.api(
                        "/me/permissions",
                        function (response) {

                            this.$timeout.cancel(permissionsChackTO);
                            if (response && !response.error) {
                                var needed_permissions = permissions_to_ask.split(',');
                                response.data.forEach(function(e) {
                                    if (e.status === 'granted'){
                                        var index = needed_permissions.indexOf(e.permission);
                                        if (index !== -1){
                                            needed_permissions.splice(index, 1);
                                        }
                                    }
                                });

                                deferred.resolve(needed_permissions.length === 0);
                            }else{
                                deferred.resolve(false);
                            }
                        }.bind(this)
                    );
                }.bind(this)).catch(function(error) {
                    this.AnalyticsService.trackError(this, 'FB DEBUG7', error);
                    deferred.reject(error);
                }.bind(this));
                return deferred.promise;
            },

            facebookLoginData: function facebookLoginData() {
                return this.FB.then(function(FB) {
                    if(FB.getAccessToken()) {
                        return this.$q.resolve({
                            user_id: FB.getUserID(),
                            auth_token: FB.getAccessToken()
                        });
                    } else {
                        return this.$q.reject('Problem getting FB login data');
                    }
                }.bind(this));
            },

            loginToFacebook: function loginToFacebook(requiredPermissions){

                var defer = this.$q.defer();

                // the only reason for the following is to make sure this.facebookLoginResponse had the ability to be filled in the FB.Event.subscribe that's in the constructor
                this.FB.then(function(FB) {

                    if (this.facebookLoginResponse && this.facebookLoginResponse.status === 'connected') {

                        this.hasPermissions(requiredPermissions).then(function success(hasPermissions) {
                            if (hasPermissions) {
                                this.facebookLoginData().then(function (data) {
                                    defer.resolve(data);
                                }).catch(function(error) {
                                    this.AnalyticsService.trackError(this, 'FB DEBUG1', error);
                                    defer.reject(error);
                                });
                            } else {
                                this.handleLogin(requiredPermissions).then(function success(facebookParams) {
                                    this.facebookLoginData().then(function (data) {
                                        defer.resolve(data);
                                    }).catch(function(error) {
                                        this.AnalyticsService.trackError(this, 'FB DEBUG2', error);
                                        defer.reject(error);
                                    });
                                }.bind(this)).catch(function error(error) {
                                    this.AnalyticsService.trackError(this, 'FB DEBUG3', error);
                                    defer.reject(error);
                                });
                            }
                        }.bind(this)).catch(function(error) {
                            this.AnalyticsService.trackError(this, 'FB DEBUG4', error);
                            defer.reject(error);
                        });

                    } else {
                        this.handleLogin(requiredPermissions).then(function success(facebookParams) {
                            defer.resolve(facebookParams);
                        }).catch(function error(error) {
                            this.AnalyticsService.trackError(this, 'FB DEBUG5', error);
                            defer.reject(error);
                        });
                    }
                }.bind(this)).catch(function(error) {
                    this.AnalyticsService.trackError(this, 'FB DEBUG6', error);
                    defer.reject(error);
                }.bind(this));

                return defer.promise;
            },

            showFbPages: function showFbPages() {
                var defer = this.$q.defer();

                // this is here to make sure this.facebookLoginResponse had the ability to be filled in the FB.Event.subscribe that's in the constructor
                this.FB.then(function(FB) {
                    if (this.facebookLoginResponse && this.facebookLoginResponse.status === 'connected') {
                        FB.api("/me/accounts", function (response) {
                            if (response && !response.error) {
                                defer.resolve([response.data, FB.getAccessToken()]);
                            } else {
                                defer.reject(response && response.error);
                            }
                        });
                    } else {
                        defer.reject({
                            status: 'User is not connected to Facebook'
                        });
                    }
                }.bind(this));
                return defer.promise;
            }
        };
    });
}());
