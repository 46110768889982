
// @ngInject

Models.ContactModel = function ContactModel(BaseModel){
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'ContactModel';

            this.cachable = false;
        },

        afterMixingFrom: function afterMixingFrom(){
        },

        mapChilds: function mapChilds(){
            return {'user': 'UserModel'};
        }
    });

};

