/**
 * The hb-chips directive
 *
 * This directive show chips elements with x button, and a text box with typeahead. example in the create opp modal.
 * ideally for filters, types etc.
 *
 * parameters:
 * view-mode: boolean. default to true. edit allows add/remove chips.
 * collection-to-update: the collection that holds the selected filters.
 * collection-all-filters: the collection that holds all possible filters.
 * on-remove-item: function. optional. called when user removes an item.
 * on-add-item: function. optional. called when user adds an item.
 * on-add-button-click: function. optional. called when user click on 'plus' to add items
 * is-allow-add-items : boolean. allow adding items.
 * input-placeholder-text: string. text to be appear inside adding input.
 *
 */

(function () {
    'use strict';

    Directives.ChipsDirective = function ChipsDirective() {

        // @ngInject
        function ChipsDirectiveControllerConstructor($scope, $injector, $timeout) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'ChipsDirectiveController';

            this.$timeout = $timeout;

            this.isShowDropDown = false;
            this.shouldFocus = false;
        }


        var ChipsDirectiveController = Class(Controllers.BaseController, {

            constructor: ChipsDirectiveControllerConstructor,

            toggleDropdown: function toggleDropdown() {
                this.isShowDropDown = !this.isShowDropDown;

                if (this.isShowDropDown) {
                    this.shouldFocus = true;
                    if (typeof this.onAddButtonClick === 'function') {
                        this.onAddButtonClick();
                    }

                }
            },

            availableCollection: function availableCollection() {
                return _.difference(this.entireCollection, this.selectedCollection);
            },

            addItem: function(item) {
                this.selectedCollection.push(item);
                this.inputAddModel = undefined;
                this.toggleDropdown();

                if (typeof this.onAddItem === 'function') {
                    this.onAddItem();
                }
            },

            removeItem: function removeVendorType(key) {
                this.selectedCollection = _.without(this.selectedCollection, _.findWhere(this.selectedCollection, {
                    key: key
                }));

                if (typeof this.onRemoveItem === 'function') {
                    this.onRemoveItem();
                }
            },

            onBlur: function onBlur() {
                this.$timeout(function(_this){
                    return function () {
                        if (!_this.inputAddModel) {
                            _this.isShowDropDown = false;
                        }
                    }

                }(this), 500);
            }

        });

        return {
            scope: {
                viewMode: '=',
                selectedCollection: '=',
                entireCollection: '<',
                onRemoveItem: '&',
                onAddItem: '&',
                isAllowAddItems: '=',
                placeholderText:'@',
                inputAddModel: '=',
                onAddButtonClick: '&'
            },
            templateUrl: 'angular/app/modules/common/ui_components/chips/chips_directive_template.html',
            controller: ChipsDirectiveController,
            controllerAs: 'chipsVm',
            bindToController: true
        };
    };


}());