(function () {
    'use strict';

    class AvailabilityViewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $compile, $timeout, moment, $element, $translate, Enums, ModalService, DateService, AnalyticsService, _, TimezoneService) {
            super($scope, $injector);
            this.__objectType = 'AvailabilityViewController';

            this.$timeout = $timeout;
            this.moment = moment;
            this.$element = $element;
            this.$compile = $compile;
            this.$translate = $translate;
            this.ModalService = ModalService;
            this.DateService = DateService;
            this.AnalyticsService = AnalyticsService;
            this.Enums = Enums;
            this._ = _;
            this.TimezoneService = TimezoneService;

            this.weekDaysMap = this.DateService.weekDaysMap;
            this.isCalendarComponentLoaded = false;
            this.isCalendarRenederd = false;

            // date formats
            this.DATE_FORMATS = {
                DATE_ONLY: 'YYYY-MM-DD',
                DATE_AND_TIME_24: 'YYYY-MM-DD hh:mm'
            };

            // save the calendar el container
            this.calEl = this.$element.find('.availability-calendar');

            // by default, load 4 weeks ahead
            this.loadedTimeFrame = {
                from: null,
                to: null
            };
            this.viewingTimeFrame = {
                from: this.moment().startOf('isoWeek'),
                to: this.moment().startOf('isoWeek').add(1, 'weeks')
            };

            // sources
            this.sources = {};
            this.eventsInCalendar = {};
            this.days = {};
            this.sessionsInRange = [];

            // first load
            this.isFirstLoad = true;
        }

        $onInit() {


        }

        $onChanges(changes) {
            if(!this.isCalendarComponentLoaded) {

                // init calendar component
                this.initCalendarComponent();
            }

            // if it's only isVisible change, render the calendar
            if(!this.isCalendarRenederd && changes['isVisible'] && this.isVisible) {
                this.calEl.fullCalendar('refetchEvents');
                this.isCalendarRenederd = true;
                return;
            }

            // clear all
            this.days = {};
            this.clearSourcesFromCalendar();
            this.sources = {};
            this.eventsInCalendar = {};
            this.loadedTimeFrame = {
                from: null,
                to: null
            };

            // load data
            if(this.sessions && this.sessions.length) {
                if (this.isFirstLoad) {
                    this.isFirstLoad = false;
                }
                this.loadAvailabilityData();
            }
        }

        initCalendarComponent() {
            if(!this.isCalendarComponentLoaded) {
                this.mode = this.mode || 'multiple';

                this.calendarConfig = {
                    header: false,
                    allDaySlot: false,
                    firstDay: 1,
                    defaultDate: this.moment(),
                    defaultView: 'basicWeek',
                    slotDuration: '01:00:00',
                    fixedWeekCount: false,
                    editable: false,
                    timezone: 'local',
                    selectable: false,
                    slotEventOverlap: false,
                    contentHeight: 'auto',
                    timeFormat: 'h:mmA',
                    displayEventTime: true,
                    displayEventEnd: true,
                    eventClick: function(calEvent, jsEvent) {
                        this.editRangeByEvent(calEvent, jsEvent);
                    }.bind(this),
                    dayClick: function handleDayClick (date, jsEvent) {
                       this.onDayClick(date, jsEvent);
                    }.bind(this),
		            eventRender: function eventRender(event, element) {
                        this.onEventRender(event, element);
                    }.bind(this),
                    eventAfterAllRender: function(view) {
                        this.onAfterAllRender(view);
                    }.bind(this),
                    axisFormat: 'h a',
                };

                // create full calendar
                this.calEl.fullCalendar(this.calendarConfig);

                // set title
                this._updateTitle();

                // mark as loaded
                this.isCalendarComponentLoaded = true;
            }
        }

        onDayClick(date, jsEvent) {
            const currSession = this.sessions[0];
            const currDateNoTime = date.format(this.DATE_FORMATS.DATE_ONLY);

            if (this.mode === 'multiple' || (this.mode === 'single' && this.allowSessionEditOnDate(currSession, currDateNoTime))) {
                this.editRangeByDate(date, jsEvent);
            }
        }

        onEventRender(event, element) {
            if (this.mode === 'multiple') {
                this.addTooltipToEvent(event, element);

                const currDateNoTime = this.moment(event.start).format(this.DATE_FORMATS.DATE_ONLY);

                // Add event data to element
                element[0].setAttribute('data-date', currDateNoTime);
            }
        }

        onAfterAllRender(view) {
            if (this.mode === 'multiple') {
                const cells = view.el.find('.fc-content-skeleton').find('td');
                const firstTableCells = view.el.find('.fc-content-skeleton').find('tr')[0].childNodes;

                [...firstTableCells].forEach(firstCell => {
                    if (firstCell.classList.length && this.sessions.length) {
                        this.addPlusToEvent(firstCell, true);
                    }
                });

                [...cells].forEach((cell) => {
                    if (!cell.classList.length) {
                        const unavailableDiv = document.createElement('div');
                        unavailableDiv.classList.add('availability-calendar__unavailable');
                        unavailableDiv.innerHTML = 'Unavailable';
                        cell.setAttribute('style', 'vertical-align: middle');

                        if (this.sessions && this.sessions.length) {
                            cell.setAttribute('ng-click', 'availabilityViewVm.togglePopover($event)');
                        }

                        cell.append(unavailableDiv);
                        this.$compile(cell)(this.$scope);
                    } else if (this.sessions.length) {
                        this.addPlusToEvent(cell);
                    }
                });
            } else {
                const currSession = this.sessions[0];
                if (currSession) {
                    view.dayGrid.cellEls.map((dayIndex, dayCell) => {
                        const currDateNoTime = this.moment(dayCell.dataset.date);

                        dayCell.classList.remove('availability-calendar__disabled');
                        if (!this.isDateInSessionTimeRange(currSession, currDateNoTime)) {
                            dayCell.classList.add('availability-calendar__disabled');
                        }
                    });
                }
            }
        }

        filterSessionsInRange(currDate) {
            this.sessionsInRange = [];

            this.sessions.forEach(session => {
                if (this.allowSessionEditOnDate(session, currDate)) {
                    this.sessionsInRange.push(session);
                }
            });
        }

        addTooltipToEvent(event, element) {
            const beforeBufferTemplate = event.hbData.session.buffer_before ? `${event.hbData.session.buffer_before.unit_count} ${event.hbData.session.buffer_before.unit_type} before` : '';
            const afterBufferTemplate = event.hbData.session.buffer_after ? `${event.hbData.session.buffer_after.unit_count} ${event.hbData.session.buffer_after.unit_type} after` : '';
            const shouldShowCommaInBuffer = !!beforeBufferTemplate && !!afterBufferTemplate ? ',' : '';

            const rangeTimeStart = this.moment(event.start, 'h:mm').format('h:mmA');
            const rangeTimeEnd = this.moment(event.end, 'h:mm').format('h:mmA');


            const template = document.createElement('span');
            template.innerHTML = `<div class="session-tooltip__item">
                        <span class="session-tooltip__item-title-same-line">Available slot:</span> ${rangeTimeStart} - ${rangeTimeEnd}
                    </div>
                    <div class="session-tooltip__item">
                        <span class="session-tooltip__item-title-same-line">Session:</span> ${event.title}
                    </div>
                    <div class="session-tooltip__item">
                        <span class="session-tooltip__item-title-same-line">Time zone:</span> ${event.hbData.session.session_timezone}
                    </div>
                    ${event.hbData.session.buffer_before || event.hbData.session.buffer_after ? `<div class="session-tooltip__item">
                        <span class="session-tooltip__item-title-same-line">Buffer:</span>
                        ${beforeBufferTemplate}${shouldShowCommaInBuffer}
                        ${afterBufferTemplate}
                    </div>
                    ` : ''}`;

            tippy(element[0], {
                placement: 'top',
                animation: 'fade',
                arrow: true,
                theme: 'nx-tippy',
                dynamicTitle: true,
                maxWidth: '248px',
                html: template.cloneNode(true)
            });
        }

        addPlusToEvent(element, insertBefore) {
            const plusElementContainer = document.createElement('div');
            plusElementContainer.classList.add('add-session__plus__container')
            plusElementContainer.innerHTML = `<div class="add-session__plus__line" ng-click="availabilityViewVm.togglePopover($event)">
                    <div class="add-session__plus__circle">+</div>
                </div>`;

            if (insertBefore) {
                const firstElementInTableCell = element.querySelector('a.fc-day-grid-event.fc-h-event');
                element.insertBefore(plusElementContainer, firstElementInTableCell);
            } else {
                element.append(plusElementContainer);
            }

            this.$compile(element)(this.$scope);
        }

        togglePopover(event, closePopover) {
            if (closePopover) {
                this.showAddSessionPopover = false;
                this.popoverLocation = null;
                this.currentEventDate = null;
            } else {
                const parentElement = event.target.closest('td').querySelector('a.fc-day-grid-event.fc-h-event') || event.target;
                const containerClientRect = document.querySelector('.fc-view-container').getBoundingClientRect();
                const popoverWidth = 308;

                this.currentEventDate = parentElement.dataset.date;
                this.filterSessionsInRange(this.currentEventDate);

                this.popoverLocation = { left: event.clientX - containerClientRect.left + (popoverWidth / 2), top: event.clientY - containerClientRect.top + 15 };
                this.showAddSessionPopover = true;
            }
        }

        getSessionTypeText(sessionType) {
            switch(sessionType) {
                case this.Enums.sessionType.inPerson:
                    return this.$translate.instant('SCHEDULING.SESSION.SESSION_TYPES._MEETING_');
                case this.Enums.sessionType.phoneCall:
                    return this.$translate.instant('SCHEDULING.SESSION.SESSION_TYPES._PHONE_CALL_');
                case this.Enums.sessionType.videoCall:
                    return this.$translate.instant('SCHEDULING.SESSION.SESSION_TYPES._VIDEO_CALL_');
                default:
                    return this.$translate.instant('SCHEDULING.SESSION.SESSION_TYPES._OTHER_');
            }
        }

        getWindowTypeText(windowType) {
            switch(windowType) {
                case this.Enums.sessionWindowType.indefinitely:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._INDEFINITELY_');
                case this.Enums.sessionWindowType.rollingWindow:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._ROLLING_WINDOW_');
                case this.Enums.sessionWindowType.fixedDateRange:
                    return this.$translate.instant('SCHEDULING.SESSION.WINDOW_TYPES._FIXED_DATE_RANGE_');
                default:
                    return '';
            }
        }

        loadAvailabilityData() {
            let from = this.loadedTimeFrame.from, to = this.loadedTimeFrame.to;

            // calc from
            if(!from) {
                from = this.moment().startOf('isoWeek');
            }

            // calc to
            if(!to) {
                to = this.moment(this.viewingTimeFrame.from).add(12, 'weeks');
            }

            if (to.isBefore(this.moment(this.viewingTimeFrame.to).add(4, 'weeks'))) {
                from = this.moment(to);
                to = this.moment(from).add(8, 'weeks');
            }

            let loadMore = this.loadedTimeFrame.from !== from ||
                                this.loadedTimeFrame.to !== to;

            if(loadMore) {
                this.generateCalendarData(from, to);
            }
        }

        generateCalendarData(from, to) {
            // update loaded time frame
            if(!this.loadedTimeFrame.from || from.isBefore(this.loadedTimeFrame.from)) {
                this.loadedTimeFrame.from = from;
            }

            if(!this.loadedTimeFrame.to || to.isAfter(this.loadedTimeFrame.to)) {
                this.loadedTimeFrame.to = to;
            }

            this.updateDays(this.sessions, from, to);
            this.generateFCEvents(this.sessions, from, to);
            this.loadSourcesToCalendar();
        }

        updateDays(sessions, from, to) {
            let formattedSessions = sessions.map((session) => {
                return session.getFormattedData();
            });

            let currDate = this.moment(from);
            let endDate = this.moment(to);

            // map date to day availability configuration
            for(;currDate <= endDate; currDate.add(1, 'days')) {

                // get the date without the time part to use as an identifier
                let currDateNoTime = currDate.format(this.DATE_FORMATS.DATE_ONLY);
                let dayOfWeekNumber = currDate.day();

                // init in days
                if(!this.days[currDateNoTime]) {
                    this.days[currDateNoTime] = {};
                }

                formattedSessions.forEach((formattedSession) => {
                    // decide if we should take the day config or the override
                    let dayAvailability = formattedSession.overrides[currDateNoTime] || formattedSession.week[dayOfWeekNumber];

                    if(dayAvailability && this.allowSessionEditOnDate(formattedSession.session, currDateNoTime)) {
                        this.days[currDateNoTime][formattedSession._id] = dayAvailability;
                    }
                });
            }
        }

        isDateInSessionTimeRange(session, currDateNoTime) {
            let isSessionInRange = true;
            let startDate, endDate;
            const today = this.moment().startOf('day');
            const currMoment = this.moment(currDateNoTime);

            // Calculate if session is before end date
            if(session.window_type === this.Enums.sessionWindowType.indefinitely) {
                isSessionInRange = currMoment.isSameOrAfter(today);
            } else if(session.window_type === this.Enums.sessionWindowType.rollingWindow &&
                session.rolling_window_duration) {
                endDate = this.moment(today).add(session.rolling_window_duration.unit_count, session.rolling_window_duration.unit_type);
                isSessionInRange = currMoment.isSameOrAfter(today) && currMoment.isSameOrBefore(endDate);
            } else if(session.window_type === this.Enums.sessionWindowType.fixedDateRange &&
                session.fixed_end_date && session.fixed_start_date) {
                startDate = today.isSameOrAfter(session.fixed_start_date) ? today : session.fixed_start_date;
                endDate = session.fixed_end_date;
                isSessionInRange = currMoment.isSameOrAfter(startDate) && currMoment.isSameOrBefore(endDate);
            }

            return isSessionInRange;
        }

        allowSessionEditOnDate(session, currDateNoTime) {
            let isSessionInRange = true;

            if(session.window_type === this.Enums.sessionWindowType.fixedDateRange &&
                session.fixed_end_date && session.fixed_start_date) {
                const currMoment = this.moment(currDateNoTime);
                isSessionInRange = currMoment.isSameOrAfter(session.fixed_start_date) && currMoment.isSameOrBefore(session.fixed_end_date);
            }

            return isSessionInRange;
        }

        generateFCEvents(sessions, from, to) {
            let currDate = this.moment(from);
            let endDate = this.moment(to);

            let sessionsMap = sessions.reduce((sm, s) => {
                sm[s._id] = s;
                return sm;
            }, {});

            // map date to day availability configuration
            for(;currDate <= endDate; currDate.add(1, 'days')) {

                // get the date without the time part to use as an identifier
                let currDateNoTime = currDate.format(this.DATE_FORMATS.DATE_ONLY);

                // get config for that day
                let dayConfigs = this.days[currDateNoTime];

                let sessionIds = Object.keys(dayConfigs);
                sessionIds.forEach((sessionId) => {
                    if(!sessionsMap[sessionId]) {
                        return;
                    }

                    let daySessionConfig = dayConfigs[sessionId];
                    let timeRanges = daySessionConfig.time_ranges;

                    timeRanges.forEach(function (timeRange) {

                        // generate cal event
                        let event = this.generateRangeEvent(
                            sessionsMap[sessionId],
                            currDateNoTime,
                            timeRange,
                            {session: sessionsMap[sessionId], dayAvailability: daySessionConfig});

                        // add event to calendar
                        this.addEventToSource(sessionId, currDateNoTime, event);

                    }.bind(this));
                });
            }
        }

        addEventToSource(sourceId, day, event) {
            if(!this.sources[sourceId]) {
                this.sources[sourceId] = { events: [] };
            }

            let slotKey = this.moment(event.start, this.DATE_FORMATS.DATE_AND_TIME_24);
            let eventId = slotKey + "-" + sourceId;
            if(this.eventsInCalendar[eventId]) {
                let index = this.sources[sourceId].events.indexOf(this.eventsInCalendar[eventId]);
                if(index > -1) {
                    this.sources[sourceId].events.splice(index, 1);
                }
            }
            this.eventsInCalendar[eventId] = event;
            this.sources[sourceId].events.push(event);
        }

        //////////////////////////////////
        // Editing
        /////////////////////////////////

        clearSession(session) {
            let source = this.sources[session._id];
            if(source) {
                this.calEl.fullCalendar('removeEventSource', source);
                source.events = [];
            } else {
                this.sources[session._id] = { events: [] };
            }

            // clear days
            const days = Object.keys(this.days);
            days.forEach((day) => {
                if(day[session._id]) {
                    delete day[session._id];
                }
            });

        }

        updateAvailability(data) {
            let session = data.session;

            // update on the session model
            this.updateDayAvailability(data);

            // remove session source from calendar
            this.clearSession(session);

            // update days
            this.updateDays([session], this.loadedTimeFrame.from, this.loadedTimeFrame.to);

            // regenerate the days configuration
            this.generateFCEvents([session], this.loadedTimeFrame.from, this.loadedTimeFrame.to);

            // load source
            this.loadSourceToCalendar(session._id);
        }

        editRangeByDate(calDate, jsEvent) {
            // enable only on single mode
            let currDateNoTime = calDate.format(this.DATE_FORMATS.DATE_ONLY);

            if(this.mode === 'single') {
                let dateConfigs = this.days[currDateNoTime];
                let dateConfig = dateConfigs[Object.keys(dateConfigs)[0]];
                this.editRange(this.sessions[0], dateConfig, calDate);
            } else {
                jsEvent.target.setAttribute('data-date', currDateNoTime);
            }
        }

        editRangeByEvent(calEvent, jsEvent) {
            // enable only in multiple mode
            // if(this.mode === 'multiple') {
            //     this.editRange(calEvent.hbData.session, calEvent.hbData.dayAvailability, calEvent.start);
            // }
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edit_session);
            this.editRange(calEvent.hbData.session, calEvent.hbData.dayAvailability, calEvent.start);
        }

        addSessionByEvent(session) {
            const dayAvailabilityData = session.getDayAvailbilityByDate(this.currentEventDate);

            // enable only in multiple mode
            if(this.mode === 'multiple') {
                const currDateNoTime = this.moment(this.currentEventDate).format(this.DATE_FORMATS.DATE_ONLY);
                // Check if there are any sessions in this date
                const shouldAddNewTime = Object.keys(this.days[currDateNoTime]).some(sessionId => {
                    return this.days[currDateNoTime][sessionId].time_ranges.length;
                });
                this.editRange(session, dayAvailabilityData, this.moment(this.currentEventDate), shouldAddNewTime);
                this.togglePopover(null, true);
            }
        }

        editRange(session, dayAvailability, date, addTimeRangeOpen) {
            if(this.editable) {
                this.ModalService.openEditDayAvailabilityModal(session, dayAvailability, date, addTimeRangeOpen).then(function(data) {
                    this.updateAvailability(data);
                    if(this.onChange) {
                        this.onChange({session: session});
                    }
                }.bind(this));
            }
        }

        //////////////////////////////////
        // Full calendar specific
        /////////////////////////////////
        clearSourcesFromCalendar() {
            Object.keys(this.sources).forEach(function(sourceId) {
                this.calEl.fullCalendar('removeEventSource', this.sources[sourceId]);
            }.bind(this));
        }

        loadSourcesToCalendar() {
            Object.keys(this.sources).forEach(function(sourceId) {
                this.loadSourceToCalendar(sourceId);
            }.bind(this));
        }

        loadSourceToCalendar(sourceId) {
            this.calEl.fullCalendar('removeEventSource', this.sources[sourceId]);
            this.calEl.fullCalendar('addEventSource', this.sources[sourceId]);
        }

        generateRangeEvent(session, date, range, data) {
            let title = this.mode === 'single' ? '' : session.session_name;
            let rangeFrom = this.moment(date + ' ' + range.range_time_start, this.DATE_FORMATS.DATE_AND_TIME_24);
            let rangeTo = this.moment(date + ' ' + range.range_time_end, this.DATE_FORMATS.DATE_AND_TIME_24);

            return {
                title: title || '',
                allDay: false,
                start: rangeFrom,
                end: rangeTo,
                eventType: '',
                className: `availability-calendar__range-cell session-color-background--${session.session_color}`,
                hbData: data
            };
        }

        onMove(moveDirection) {

            let view = this.calEl.fullCalendar('getView');

            // block moving to the past
            if(moveDirection === 'next' || this.moment().isBefore(view.start)) {

                // trigger move on the calendar
                this.calEl.fullCalendar(moveDirection);
            }

            // update toolbar range title
            this._updateTitle();

            // update viewing time frame
            this.viewingTimeFrame.from = this.moment(view.start.format());
            this.viewingTimeFrame.to = this.moment(view.end.format());

            // load more if needed
            this.$timeout(() => this.loadAvailabilityData());
        }

        _updateTitle() {
            this.calendarTitle = this.calEl.fullCalendar('getView').title;
        }

        //////////////////////////////////
        // Other
        /////////////////////////////////

        updateDayAvailability (data) {
            const shouldOverride = true;
            let newAvailability = data.dayAvailability;
            let session = data.session;

            // if we changed an override to config
            if(newAvailability.override_date && data.applyAll) {

                // get day of week number
                let dayOfWeekNum = this.moment(newAvailability.override_date).day();

                // find this day of week config and delete if exists
                session.deleteConfigDayOfWeek(dayOfWeekNum);

                // remove specific date
                newAvailability.override_date = undefined;

                // set day of week
                newAvailability.day_of_week = this.DateService.weekDaysMap[dayOfWeekNum];

                // set ranges
                newAvailability.time_ranges = data.timeRanges;

                // if should override all custom dates
                if(shouldOverride) {
                    session.deleteOverridesForDayOfWeek(newAvailability.day_of_week);
                }

                // if we changed a config to an override
            } else if(!newAvailability.override_date && !data.applyAll) {

                // keep the existing config and create new override
                var newDayAvailability = {
                    override_date: data.date.format("YYYY-MM-DD"),
                    time_ranges: data.timeRanges
                };

                session.addDayConfig(newDayAvailability);

                // no type change, only ranges change
            } else {

                // set ranges
                newAvailability.time_ranges = data.timeRanges;
            }

        }

    }

    Components.AvailabilityView = {
        bindings: {
            sessions: '<',
            user: '<',
            editable: '<',
            onChange: '&?',
            viewType: '@',
            mode: '@',
            isVisible: '<',
            isEmpty: '<',
            emptyText: '@'
        },
        transclude: true,
        controller: AvailabilityViewController,
        controllerAs: 'availabilityViewVm',
        name: 'hbAvailabilityView',
        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/availability_view/availability_view.html',
    };

}());
