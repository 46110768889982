(function () {
    'use strict';

    // @ngInject
    Components.HomeExploreToolsMenu = {
        controller: function HomeExploreToolsMenuController(
            $scope,
            $element,
            $attrs,
            $state,
            $translate,
            AppStates,
            UsersManager,
            AnalyticsService,
            UiPersistenceService,
            DeviceService,
            ReferralService,
            TemplatesViewService
        ) {

            this.AppStates = AppStates;

            this.mobileAppUrl = "https://itunes.apple.com/app/apple-store/id1104772757?pt=118116061&ct=mweb_home&mt=8";

            this.TemplatesViewService = TemplatesViewService;

            const user = UsersManager.getCurrUser();

            ReferralService.getCampaignDetails(user).then( campaignDetails => {
                if (campaignDetails && campaignDetails.campaign_uid) {
                    this.tools.myReferrals.shouldDisplay = true;
                }
            });

            this.tools = {
                templates: {
                    name: $translate.instant('HOME.TOOLS.LABELS._TEMPLATES_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395962/app/home/tools-templates.svg',
                    state:  this.TemplatesViewService.getTemplatesPrimaryUrl(),
                    shouldDisplay: true,
                },
                calendar: {
                    name: $translate.instant('HOME.TOOLS.LABELS._CALENDAR_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395961/app/home/tools-calendar.svg',
                    state: AppStates.root_core_navigation_calendar,
                    shouldDisplay: true,
                },
                branding: {
                    name: $translate.instant('HOME.TOOLS.LABELS._BRANDING_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395960/app/home/tools-branding.svg',
                    state: AppStates.root_core_navigation_settings_company_branding,
                    shouldDisplay: true,
                },
                mobileApp: {
                    name: $translate.instant('HOME.TOOLS.LABELS._MOBILE_APP_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395960/app/home/tools-app.svg',
                    state: '',
                    shouldDisplay: DeviceService.isIOS(),
                },
                library: {
                    name: $translate.instant('HOME.TOOLS.LABELS._LIBRARY_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395960/app/home/tools-library.svg',
                    state: AppStates.root_core_navigation_settings_companySettings_library,
                    shouldDisplay: true,
                },
                tasks: {
                    name: $translate.instant('HOME.TOOLS.LABELS._TASKS_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395962/app/home/tools-tasks.svg',
                    state: AppStates.root_core_navigation_tasks,
                    shouldDisplay: true,
                },
                myReferrals: {
                    name: $translate.instant('HOME.TOOLS.LABELS._MY_REFERRALS_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395961/app/home/tools-referrals.svg',
                    state: AppStates.root_core_navigation_invite_referrals,
                    shouldDisplay: false, // Set when ReferralService.getCampaignDetails resolves
                },
                bookkeeping: {
                    name: $translate.instant('HOME.TOOLS.LABELS._BOOKKEEPING_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395960/app/home/tools-bookkeeping.svg',
                    state: AppStates.root_core_navigation_reports_payments,
                    shouldDisplay: true,
                },
                timeTracker: {
                    name: $translate.instant('HOME.TOOLS.LABELS._TIME_TRACKER_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395963/app/home/tools-time-tracker.svg',
                    state: AppStates.root_core_navigation_time_tracker,
                    shouldDisplay: true,
                },
                reports: {
                    name: $translate.instant('HOME.TOOLS.LABELS._REPORTS_'),
                    assetUrl: '//res.cloudinary.com/honeybook/image/upload/v1535395962/app/home/tools-reports.svg',
                    state: AppStates.root_core_navigation_reports_analytics,
                    shouldDisplay: true,
                },
            };

            this.toolsToDisplay = ['templates', 'branding', 'calendar'];

            this.handleToolClick = function (tool) {
                if (tool === 'mobileApp') {
                    window.location.href = this.mobileAppUrl;
                } else {
                    $state.go(this.tools[tool].state)
                }
            }

        },
        name: 'hbHomeExploreToolsMenu',
        templateUrl: 'angular/app/modules/core/features/home/explore_tools/explore_tools_component_template.html',
    }


}());
