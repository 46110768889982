Directives.BadgePillListDirective = function BadgePillListDirective() {

    // @ngInject
    function BadgePillListDirectiveControllerFunc($injector, _, $, $scope, Enums, DeviceService, AnalyticsService, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BadgePillListDirectiveController';

        this._ = _;
        this.$ = $;
        this.Enums = Enums;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.isMobile = this.DeviceService.nxSmallBreakpoint();
        this.$translate = $translate;

        this.requestInProgress = false;
        this.tags = [];
        this.numberOfEditItems = 0;
        this._init();
    }

    var BadgePillListDirectiveController = Class(Controllers.BaseController, {
        constructor: BadgePillListDirectiveControllerFunc,

        _init: function _init() {

            // new tag
            this.isInAddMode = false;
            this.newTagText = '';

            // edit tag
            this.editingTagIndex = -1;
            this.editingTagInvalidIndex = -1;

            // delete tag
            this.deletingTagIndex = -1;

            // save the original tags collection
            this.tags = angular.copy(this.collection);
            this._buildAnalyticsConfig();
        },

        _buildAnalyticsConfig: function _buildAnalyticsConfig() {
            var analyticsEvents = this.AnalyticsService.analytics_events;

            this.analyticsObjectPrefix = this.badgePillListType;

            this.analyticsEventNames = {
                saved: analyticsEvents[this.analyticsObjectPrefix + "__saved"],
                edit: analyticsEvents[this.analyticsObjectPrefix + "__item_edit_clicked"],
                add: analyticsEvents[this.analyticsObjectPrefix + "__item_add_clicked"],
                deleteClicked: analyticsEvents[this.analyticsObjectPrefix + "__item_delete_clicked"],
                deleteConfirmed: analyticsEvents[this.analyticsObjectPrefix + "__item_delete_confirmed"],
                deleteCancelled: analyticsEvents[this.analyticsObjectPrefix + "__item_delete_canceled"],
            };
        },

        _resetEditTag: function() {
            this.editingTagIndex = -1;
        },

        _resetDeleteTag: function() {
            this.deletingTagIndex = -1;
        },

        ///////////////////////////////////
        // EDIT
        ///////////////////////////////////

        _shouldEnableActions: function _shouldEnableActions() {
            return this.editingTagInvalidIndex === -1;
        },

        _focusOnItem: function _focusOnItem(elem) {
            this.$(elem.target.closest('li')).find('input').select();
        },

        enterEditMode: function enterEditMode($event, $index) {
            if(this._shouldEnableActions()) {
                this._focusOnItem($event);
                this._resetDeleteTag();
                //this.resetAddMode(true);
                this.editingTagIndex = $index;
                this.AnalyticsService.track(this, this.analyticsEventNames.edit, this.analyticsArgs);
            }
        },

        resetEditMode: function resetEditMode() {
            // if(this.isMobile) {
            //     return;
            // }
            this._resetEditTag();
        },

        onEditTagClickOutside: function onEditTagClickOutside() {
            if(!this.isMobile || this.deletingTagIndex === -1) {
                this.resetEditMode();
                this._resetDeleteTag();
                this._updateValidation();
            }
        },

        onEditTagBlur: function onEditTagBlur() {
            if(!this.isMobile) {
                this.resetEditMode();
                this._updateValidation();
            }
            this.numberOfEditItems++;
        },

        onEditTagTextChange: function onEditTagTextChange($index) {

            // marj current editing index
            this.editingTagIndex = $index;

            // capitalize
            this.tags[$index].label = this._capitalize(this.tags[$index].label);

            // get label
            var label = this.tags[$index].label;

            var valid = false;
            if (label) {

                // validate
                valid = this._validateSameLabelName(label, this.tags, false);
            }

            if(!valid) {
                this.editingTagInvalidIndex = $index;
            } else {
                this.editingTagInvalidIndex = -1;
            }

            // handle add text
            this.onAddTagTextChange();
        },

        ///////////////////////////////////
        // DELETE
        ///////////////////////////////////

        showDeleteTooltip: function showDeleteTooltip(tag, $index) {
            if(this._shouldEnableActions()) {
                this.deletingTagIndex = $index;
                this.AnalyticsService.track(this, this.analyticsEventNames.deleteClicked, this.analyticsArgs);
            }
        },

        confirmDelete: function confirmDelete(tag) {
            var index = this._.findIndex(this.tags, function(e) { return e.label === tag.label; });
            if (index !== -1) {
                this.tags.splice(index, 1);
            }
            this._resetDeleteTag();
            this.onAddTagTextChange();
            this.numberOfEditItems++;
            this.badgePillForm.$pristine = false;

            this.AnalyticsService.track(this, this.analyticsEventNames.deleteConfirmed, this.analyticsArgs);
         },

        cancelDelete: function cancelDelete() {
            this.AnalyticsService.track(this, this.analyticsEventNames.deleteCancelled, this.analyticsArgs);
            this._resetDeleteTag();
        },

        ///////////////////////////////////
        // CREATE
        ///////////////////////////////////

        getAddLabel: function getAddLabel() {
            switch (this.badgePillListType) {
                case 'lead_source':
                    this.badgePillLabel = this.$translate.instant('COMPANY_SETTING.BADGE_PILLS.LABELS._LEAD_SOURCE_');
                    return 'LEADSOURCE.EDIT_MODAL._ADD_SOURCE_';
                case 'project_type':
                    this.badgePillLabel = this.$translate.instant('COMPANY_SETTING.BADGE_PILLS.LABELS._PROJECT_TYPE_');
                    return 'LEADSOURCE.EDIT_MODAL._ADD_PROJECT_';
                case 'company_spaces':
                    this.allowDuplicates = true;
                    this.unlimitedBadges = true;
                    return 'LEADSOURCE.EDIT_MODAL._ADD_SPACE_';
            }
        },

        _shouldEnableAdd: function _shouldEnableAdd() {

            if (this.unlimitedBadges) {
                return true;
            }

            return (!this.limit || this.limit === -1 || this.tags.length < this.limit);
        },

        enterAddMode: function enterAddMode($event) {

            // check if can add more
            if(!this._shouldEnableAdd()) {
                this.isInAddMode = false;
                this.showInvalidNumberOfLeadsTippy = true;
                return;
            }

            // if not in the middle of editing
            if(!this._shouldEnableActions()) {
                return;
            }

            // allow
            this.isInAddMode = true;
            this._focusOnItem($event);
        },

        resetAddMode: function resetAddMode(force) {
            if (!force && this.newTagText) {
                return;
            }

            this.isInAddMode = false;
            this.newTagText = '';
            this.showInvalidNumberOfLeadsTippy = false;
        },

        addTag: function addTag() {

            // validate
            this.invalidEntry = !this._validateTagLabel(this.newTagText);

            // if valid, add
            if (!this.invalidEntry) {
                var tag = {
                    label: this._capitalize(this.newTagText),
                    editable: true,
                    open_text: false
                };

                this.tags.unshift(tag);
                this.editingTagInvalidIndex = -1;
                this._updateValidation();
                // TODO: Should we do something to enter add mode again, or the click on add already handles this for us?
                this.newTagText = '';
            }
            this.numberOfEditItems++;
            this.AnalyticsService.track(this, this.analyticsEventNames.add, this.analyticsArgs);
        },

        onAddTagTextChange: function onAddTagTextChange() {

            if(this.newTagText === '') {
                this.invalidEntry = false;
                return;
            }

            // validate
            this.invalidEntry = !this._validateTagLabel(this.newTagText);
        },

        ///////////////////////////////////
        // SAVE
        ///////////////////////////////////

        updateCollection: function updateCollection() {
            this.resetEditMode();
            this._resetDeleteTag();
            this.resetAddMode(true);

            if (this.badgePillForm.$invalid) {
                return;
            }

            if (this._updateValidation()) {
                return;
            }

            this.requestInProgress = true;

            this._trackSaved();

            this.update({badgePillCollection:this.tags})
                .then(function success(resp){
                    this.tags = this.collection.slice(0);
                    this.tags = resp;
                    this.requestInProgress = false;
                }.bind(this));
        },

        _trackSaved: function() {
            var args = this._.extend({}, this.analyticsArgs, {
                edited_items_count: this.numberOfEditItems,
                total_items_count: this.tags.length
            });
            this.AnalyticsService.track(this, this.analyticsEventNames.saved, args);
        },

        ///////////////////////////////////
        // Validations
        ///////////////////////////////////

        _validateTagLabel: function _validateTagLabel(label) {

            // validate empty / valid form
            if (!label || this.badgePillForm.$invalid) { return false; }

            if (this.allowDuplicates) {
                return true;
            }

            // validate duplicate
            return this._validateSameLabelName(label, this.tags, true);
        },

        _validateSameLabelName: function _validateSameLabelName(label, collection, isNew) {
            var tagLabel = label.toLowerCase();
            var matches = this._.filter(collection, function(e) {
                return e.label.toLowerCase() === tagLabel;
            }.bind(this));

            return isNew ? !matches.length : matches.length === 1;
        },

        _updateValidation: function _updateValidation() {
            var duplicates = this._calcDuplicateValues();
            if(duplicates.length) {
                this._addVisualValidationError(duplicates);
            }
            return duplicates.length;
        },

        _calcDuplicateValues: function _calcDuplicateValues() {

            if (this.allowDuplicates) {
                return [];
            }

            // find uniq
            var uniq = this.tags.map(function(entry) {
                if (entry.label) {
                    return { count: 1, name: entry.label.toLowerCase() };
                }
            }).filter(function(entry){
                return entry !== undefined;
            });

            // reduce
            uniq = uniq.reduce(function(a, b) {
                a[b.name] = (a[b.name] || 0) + b.count;
                return a;
            }, {});

            var duplicates = Object.keys(uniq).filter(function(a) { return uniq[a] > 1; });
            return duplicates;
        },

        _addVisualValidationError: function _addVisualValidationError(duplicates) {
            if (this.allowDuplicates) {
                return;
            }

            for (var i = 0; i < duplicates.length; i++) {
                var index = this._.findIndex(this.tags, function(e) { return e.label.toLowerCase() === duplicates[i]; });
                if (index !== -1 && this.editingTagInvalidIndex === -1){
                    this.editingTagInvalidIndex = index;
                }
            }
        },

        _capitalize: function _capitalize(text) {

            function capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }

            if(!text) {
                return text;
            }

            var words = text.split(' ');
            return words.map(function(word) {return capitalize(word);}).join(' ');
        }

    });

    return {
        scope: {
            collection: "=",
            update: "&",
            badgePillListType: "<",
            analyticsArgs: "<",
            limit: "<",
            source: "="
        },
        templateUrl: 'angular/app/modules/common/ui_components/badge_pill_list/badge_pill_list_directive_template.html',
        controller: BadgePillListDirectiveController,
        controllerAs: 'badgePillVm',
        bindToController: true
    };
};
