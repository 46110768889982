Directives.FeedMessageViewerDirective = function FeedMessageViewerDirective() {

    // @ngInject
    function FeedMessageViewerDirectiveControllerFunc($sce, $scope, $injector, PvlService, FeedService, UIUtils, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedMessageViewerDirectiveController';

        this.PvlService = PvlService;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;

        var body = this.messageModel.html_body;

        // Rip out client loginless links from HTML
        // Rip out style tags
        body = FeedService.cleanFeedItemHtml(body);
        
        this.htmlBody = $sce.trustAsHtml(body);

        if (this.messageModel.files) {
            this.formattedAttachmentFiles = this.messageModel.files.map(function (file) {
                return {
                    _id: file._id,
                    name: file.file_name,
                    type: file.file_type,
                    url: file.url
                };
            });
        }

        this.onDownloadFile = function onDownloadFile () {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.download_attachment);
        }.bind(this);

        var unwatchFiles = $scope.$watchCollection('feedMessageViewerVm.files', function () {
            this.thumbnailsLoaderParams = {
                width: 100,
                height: 120,
                style: { marginRight: '7px' },
                count: this.messageModel.files ? this.messageModel.files.length : 0
            };
        }.bind(this));

        $scope.$on('$destroy', function () {
            unwatchFiles();
        });
    }

    var FeedMessageViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedMessageViewerDirectiveControllerFunc,

        onViewPreferredVendors: function onViewPreferredVendors() {
            this.PvlService.showPreferredVendorList(this.messageModel.pvl_id, this.eventId);
        }
    });

    return {
        scope: {
            messageModel: '=messageModel',
            feedItem: '=feedItem',
            eventId: '@eventId',
            feedUsers: '=',
            feedOwnerCompanyId: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_message_viewer_directive_template.html',
        controller: FeedMessageViewerDirectiveController,
        controllerAs: 'feedMessageViewerVm',
        bindToController: true
    };
};
