Directives.SignaturesDirective =  function SignaturesDirective(){

    // @ngInject
    function SignaturesDirectiveControllerFunc($timeout, _, Enums, $scope, $injector, $q, UsersManager, WorkspaceService,
                                               PopupMessageService, CompanyService, DeviceService, MobileAppService, uuid4, WorkspaceFileService, AnalyticsService, CompaniesManager){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SignaturesDirectiveController';

        this.$scope = $scope;
        this.UsersManager = UsersManager;
        this.WorkspaceService = WorkspaceService;
        this.CompanyService = CompanyService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.$q = $q;
        this._ = _;
        this.DeviceService = DeviceService;
        this.MobileAppService = MobileAppService;
        this.Enums = Enums;
        this.AnalyticsService = AnalyticsService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.company = this.CompaniesManager.getCurrCompany();

        this.user= this.UsersManager.getCurrUser();
        this.isMemberSignature = this.fileModel.modelVersion() >= 1;
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();
        //used to remove the add button as long as we are in the process of adding a signature
        this.isAddingSignature = false;
        this.firstCoupleSignatureFound = false;

        this.addSignature = function addSignature(){

            // if in app browser just trigger an event
            if(this.isInAppBrowser) {
                this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.add_signature);
                return;
            }

            if(this.isMemberSignature){
                if(this.getValidUsersForSignature().length === 0) {
                    //no users initiate the process to add them
                    this.onAddUser();
                } else {
                    //this will initiate the dropdown allowing the selection of a user
                    this.isAddingSignature = true;
                }
            } else {
                this.fileModel.addSignature();
            }
        };

        this.rerenderSignature = function rerenderSignature(signatureModel){

            var location = _.findIndex(this.fileModel.agreement.contract_signatures, signatureModel);
            this.fileModel.agreement.contract_signatures.remove(signatureModel);
            $timeout(this.reAddSignature.bind(this, signatureModel, location), 100);
        };

        this.reAddSignature = function reAddSignature(signatureModel, location){
            this.fileModel.agreement.contract_signatures.splice(location, 0, signatureModel);
        };

        this.firstCoupleSignature = function firstCoupleSignature(index){
            if (index < 1){
                return false;
            }

            var currSignature = this.fileModel.agreement.contract_signatures[index];
            var previousSignature = this.fileModel.agreement.contract_signatures[index -1];

            if (currSignature.signee_type_cd === Enums.ContractSigneeTypes.couple_signature &&
                (!currSignature.signature || currSignature.signature.length === 0) &&
                (previousSignature.signee_type_cd === Enums.ContractSigneeTypes.planner_signature ||
                    (previousSignature.signature && previousSignature.signature.length > 0))){
                return true;
            }
            return false;
        };

        var signaturesValidationId = uuid4.generate();
        WorkspaceFileService.addFileValidation(signaturesValidationId, this.validateAgreementSignature.bind(this), 50);

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(signaturesValidationId);
        });
    }

    var SignaturesDirectiveController = Class(Controllers.BaseController, {
        constructor: SignaturesDirectiveControllerFunc,

        getValidUsersForSignature: function getValidUsersForSignature(teamMembersOnly) {

            if (!this.isOwnerMode) {
                return [];
            }

            teamMembersOnly = !!teamMembersOnly;
            var signatoriesIds = this._.groupBy(this.fileModel.agreement.contract_signatures, 'signee_id');

            return this.workspaceModel.members.filter(function (user) {
                var isUserSignatory = !!signatoriesIds[user._id];
                return !isUserSignatory && (!teamMembersOnly || user.isMyTeamMember());
            }.bind(this));
        },

        getTeamMembersSignatories: function getTeamMembersSignatories() {
            return this.getValidUsersForSignature(true);
        },

        onAddUser: function onAddUser() {
            var self = this;
            this.isAddingSignature = true;
            var oldListOfValidUsers = this.getValidUsersForSignature();
            var currentSignee = null;

            this.$scope.$emit('onNewSignatoryOpen');

            this.WorkspaceService.addWorkspaceParticipant(this.workspaceModel, this.fileModel, this.company)
                .then(function success(resp) {
                    var usersToAddSingnature = this.getValidUsersForSignature();
                    //remove the old ones so we will add only the newly added members
                    usersToAddSingnature = this._.difference(usersToAddSingnature, oldListOfValidUsers);
                    var executionFunctions = usersToAddSingnature.map(function (signee) {
                        return function () {
                            currentSignee = signee;
                            return self.fileModel.addSignature(signee);
                        };
                    });

                    return executionFunctions.reduce(function (curAddingFunction, next) {
                        return curAddingFunction.then(next);
                    }, this.$q.resolve());
                }.bind(this))
                .then(function () {
                  // console.log("finished all the updates of the signatures");
                })
                .catch(function fail(error) {
                    if (currentSignee) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, {
                            translationId: 'AGREEMENT.MESSAGES._ADD_SIGNATURE_ERROR_',
                            interpolateParams: {userFullName: currentSignee.full_name}
                        });
                    }
                }.bind(this))
                .finally(function finallyHandler() {
                    this.$scope.$emit('onNewSignatoryClose');
                    this.isAddingSignature = false;
                }.bind(this));
        },

        onAddSignature: function onAddSignature(signee) {
            if (this.callingServerToAddSignature) {
                return;
            }

            this.callingServerToAddSignature = true;
            this.fileModel.addSignature(signee)
                .then(function success() {

                }.bind(this))
                .catch(function fail(error) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, {
                        translationId: 'AGREEMENT.MESSAGES._ADD_SIGNATURE_ERROR_',
                        interpolateParams: {userFullName: signee.full_name}
                    });
                }.bind(this))
                .finally(function finallyHandler() {
                    this.callingServerToAddSignature = false;
                    this.isAddingSignature = false;
                }.bind(this));

        },

        handleClickOutsideSignature: function handleClickOutsideSignature($event) {
            this.isAddingSignature = false;
        },

        validateAgreementSignature: function validateAgreementSignature() {
            return this.$q(function signaturesValidationPromise(resolve, reject) {

                // Check if there is at least 1 signature in the file (other than the owner's) for agreement/proposal
                var hasSignatoryClient = this._.any(this.fileModel.agreement.contract_signatures, function(signature) {
                    return signature.signee_type_cd === this.Enums.ContractSigneeTypes.couple_signature;
                }.bind(this));

                if (hasSignatoryClient) {
                    resolve();
                } else {

                    // track
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.no_singnature_before_sending_validaiton);

                    var fileTypeDisplayName = this.WorkspaceFileService.formatFileTypeToDisplayName(this.fileModel.file_type);

                    // prompt the user that there is no client signature
                    this.PopupMessageService.showAlert(
                        this.PopupMessageService.severityTypes.info,
                        { translationId: 'FILE.SEND_FILE._NO_CLIENT_SIGNATORY_ ', interpolateParams: {fileType: fileTypeDisplayName} },
                        function onConfirm() {
                            // this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.add_signature_from_validation);
                            this.autoAddSignatureToFile();
                        }.bind(this),
                        'FILE.SEND_FILE._ADD_SIGNATURE_BUTTON_'
                    );
                    reject();
                }

            }.bind(this));
        },

        autoAddSignatureToFile: function autoAddSignatureToFile() {

            if(this.fileModel.isEditable()) {
                this.addSignature();
            } else {
                this.workspaceFile.createFileDraft().then(function () {
                    this.isEditableMode = this.workspaceFile.isEditable();
                    this.addSignature();
                }.bind(this));
            }
        },

        addSignature: function addSignature() {
            if (this.isMemberSignature) {
                var validUsers = this.getValidUsersForSignature();
                if (validUsers.length > 0) {
                    this.fileModel.addSignature(validUsers[0]).then(function() {
                        var $element = angular.element(".hb-signatures");
                        $element.scrollParent().scrollTo(null, $element.offset().top, 300);
                    });
                }
            }
        }

    });

    return {
        scope:{
            fileModel: '=fileModel',
            companyModel: '=companyModel',
            workspaceModel: '=workspaceModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode'
        },
        templateUrl : 'angular/app/modules/core/features/signatures/signatures_directive_template.html',
        controller : SignaturesDirectiveController,
        controllerAs : 'signaturesVm',
        bindToController : true
    };
};