Directives.CompanyNavigation = function CompanyNavigation() {

    // @ngInject
    function CompanyNavigationControllerFunc($injector, $scope, $state, AppStates, UsersManager, OnboardingService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanyNavigationController';

        this.$state = $state;
        this.AppStates = AppStates;
        this.user = UsersManager.getCurrUser();

        // company settings links
        this.navItems = [
            {linkTitleKey: 'General', stateToGoto: AppStates.root_core_navigation_settings_company_general}
        ];


        // add routes for vendors only
        if (this.user.isVendor()) {
            this.navItems.push({
                linkTitleKey: 'Branding', stateToGoto: AppStates.root_core_navigation_settings_company_branding
            });

            this.navItems.push({
                linkTitleKey: 'Team', stateToGoto: AppStates.root_core_navigation_settings_company_team
            });

            this.navItems.push({
                linkTitleKey: 'Preferences',
                stateToGoto: AppStates.root_core_navigation_settings_company_preferences
            });
        }

        // add routes for company owner only
        if (this.user.isCompanyOwner()) {
            // membership
            if (this.user.shouldShowMembershipToUser()) {
                this.navItems.push({
                    linkTitleKey: 'Membership',
                    stateToGoto: AppStates.root_core_navigation_settings_company_subscription
                });

            }
            // bank details
            this.navItems.push({
                linkTitleKey: 'Bank Details',
                stateToGoto: AppStates.root_core_navigation_settings_company_bankInfo
            });
        }

        if (this.user.hasCompanySuperAdminPermissions()) {
            // client portal
            this.navItems.splice(this.navItems.indexOf('Company Brand'), 0, {
                linkTitleKey: 'Client Portal',
                stateToGoto:AppStates.root_core_navigation_settings_company_client_portal
            });
        }

    }

    var CompanyNavigationController = Class(Controllers.BaseController, {
        constructor: CompanyNavigationControllerFunc,

    });

    return {
        templateUrl: 'angular/app/modules/core/features/settings/company/navigation/company_navigation.html',
        controller: CompanyNavigationController,
        controllerAs: 'companyNavVm',
        bindToController: true
    };
};
