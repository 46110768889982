(function () {
    "use strict";


    // @ngInject
    function TimelineManagerCtor($q,
                                  APIService, RepositoryService, PrintService,
                                  Routes, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.PrintService = PrintService;
    }

    Services.TimelineManager = Class(Services.BaseModelManager, {

        constructor: TimelineManagerCtor,

        /*****************************************
         * TIMELINE SECTIONS ACTIONS
         ******************************************/
        createTimelineSection: function createTimelineSection(workspaceFile) {
            var url = this.Routes.v2_workspace_file_create_timeline_section_path(workspaceFile._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        deleteTimelineSection: function deleteTimelineSection(workspaceFile, timelineSection) {
            var url = this.Routes.v2_workspace_file_delete_timeline_section_path(workspaceFile._id, timelineSection._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updateTimelineSection: function updateTimelineSection(workspaceFile, timelineSection) {
            var data = {
                section_title: timelineSection.section_title,
                section_date: timelineSection.section_date
            };
            var url = this.Routes.v2_workspace_file_update_timeline_section_path(workspaceFile._id, timelineSection._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },


        /*****************************************
         * TIMELINE ITEMS ACTIONS
         ******************************************/
        createTimelineItem: function createTimelineItem(workspaceFile, timelineSection) {
            var url = this.Routes.v2_workspace_file_create_timeline_item_path(workspaceFile._id, timelineSection._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        deleteTimelineItem: function deleteTimelineItem(workspaceFile, timelineSection, timelineItem) {
            var url = this.Routes.v2_workspace_file_delete_timeline_item_path(workspaceFile._id, timelineSection._id, timelineItem._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updateTimelineItem: function updateTimelineItem(workspaceFile, timelineSection, timelineItem) {
            var data = {
                title: timelineItem.title,
                time: timelineItem.time,
                description: timelineItem.description
            };
            var url = this.Routes.v2_workspace_file_update_timeline_item_path(workspaceFile._id, timelineSection._id, timelineItem._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateTimelineItemPositions: function updateTimelineItemPositions(workspaceFile, timelineSection) {
            var positionsMap = {}
            var nItems = timelineSection.timeline_items.length;
            for (var i = 0; i < nItems; i++) {
                positionsMap[timelineSection.timeline_items[i]._id] = timelineSection.timeline_items[i].position;
            }
            var data = {
                new_positions: positionsMap
            };
            var url = this.Routes.v2_workspace_file_update_timeline_item_positions_path(workspaceFile._id, timelineSection._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        }

    });
}());
