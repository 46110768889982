Directives.PreferredVendorCardDirective = function PreferredVendorCardDirective() {

    // @ngInject
    function PreferredVendorCardDirectiveControllerFunc($injector, $scope, AnalyticsService, UIUtils,
                                                        ModalService, WorkspacesManager, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PreferredVendorCardDirectiveController';

        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.WorkspacesManager = WorkspacesManager;
        this.DatadogRUMService = DatadogRUMService;
        this.vendor = this.contact.user;
        this.creatingInquiry = false;
        this.errorCreatingInquiry = false;
        this.createdInquerySuccessfully = false;
        //the client gets the preview mode and the vendor gets the editable mode
        this.isPreviewMode = (this.isEditMode !== 'true');
        this.isCurrUserIsFromReferringCompany = (this.currUserIsFromReferringCompany === 'true');

        this.fixedBrowsableWebSiteUrl = UIUtils.fixExternalUrl(this.vendor.company.website_url);
        this.websitePretty = UIUtils.extractPrettyWebsiteLink(this.fixedBrowsableWebSiteUrl);
    }

    var PreferredVendorCardDirectiveController = Class(Controllers.BaseController, {
        constructor: PreferredVendorCardDirectiveControllerFunc,

        toggleSelected: function toggleSelected($event) {
            if (this.isEditMode === "true") {
                this.contact._selected = !this.contact._selected;
                this.AnalyticsService.track(this, "click: toggle select preferred vendor");
            } else {
                if ($event.target.nodeName !== "A") { //not anchor element
                    this.AnalyticsService.track(this, "click: preferred vendor card", {mode: 'view mode'});
                }
            }
        },

        createPVLInquiry: function createPVLInquiry() {
            if(this.isOwnerMode || this.creatingInquiry){
                //we dont want any action if this is the vendor editing this view.
                return;
            }
            
            this.creatingInquiry = true;
            this.errorCreatingInquiry = false;
            this.WorkspacesManager.createPvlInquiry(this.referringVendorId, this.vendor._id, this.eventId, this.pvlId)
                .then(function success() {
                    this.createdInquerySuccessfully = true;
                }.bind(this))
                .catch(function fail(error) {
                    this.errorCreatingInquiry = true;
                    this.DatadogRUMService.addError(error, { 'pvl': 1 })
                }.bind(this))
                .finally(function finallyHandler() {
                    this.creatingInquiry = false;
                }.bind(this));
        }
     });

    return {
        scope: {
            isOwnerMode:'=isOwnerMode',
            contact: '=contact',
            isEditMode: '@isEditMode',
            referringVendorId: '@referringVendorId',
            currUserIsFromReferringCompany: '@currUserIsFromReferringCompany',
            eventId: '@eventId', // in case we are showing the edit mode for the vendor, this is not needed
            pvlId: '@pvlId' // in case we are showing the edit mode for the vendor, this is not needed
        },

        templateUrl: 'angular/app/modules/core/features/preferred_vendors/preferred_vendor_card_directive_template.html',
        controller: PreferredVendorCardDirectiveController,
        controllerAs: 'preferredVendorCardVm',
        bindToController: true
    };
};
