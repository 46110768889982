(function () {
    "use strict";

    Controllers.OrganizationViewController = class OrganizationViewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, AppStates, AnalyticsService, StatsigService, Enums) {
            super($scope, $injector);
            this.__objectType = 'OrganizationViewController';
            this.StatsigService = StatsigService;
            this.Enums = Enums;

            this.isOrganizationViewEnabled = this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.organizationViewEnabled);

            if (!this.isOrganizationViewEnabled){
                $state.go(AppStates.root_core_navigation_home);
            }
        }
    };

}());
