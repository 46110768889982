
Directives.EditableHeaderBillToDirective = function EditableHeaderBillToDirective() {

    // @ngInject
    function EditableHeaderBillToDirectiveControllerFunc($injector, $scope, WorkspaceFilesManager, _, ModalService, UsersManager, PreviewModeService, AnalyticsService, StatsigService, ReactModalService, ModelFactory, CompaniesManager) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EditableHeaderBillToDirectiveController';

        this.$scope = $scope;
        this.ModalService = ModalService;
        this._ = _;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.AnalyticsService = AnalyticsService;

        this.user = UsersManager.getCurrUser();
        this.isPreviewMode = PreviewModeService.isInPreviewMode();
        this.billTo = null;
        this.StatsigService = StatsigService;
        this.ReactModalService = ReactModalService;
        this.ModelFactory = ModelFactory;
        this.CompaniesManager = CompaniesManager;
        this.company = this.CompaniesManager.getCurrCompany();
        this.members = [];

        this.register(this.fileModel, 'success', function success() {
            this.$scope.$applyAsync(function () {
                this.loadModel();
            }.bind(this));
        }.bind(this));
    }

    var EditableHeaderBillToDirectiveController = Class(Controllers.BaseController, {
        constructor: EditableHeaderBillToDirectiveControllerFunc,

        // --- Hack alert --- //
        // when this.WorkspaceFilesManager.getWorkspaceFileMembers returns, fileModel has a client_users array
        // populated with javascript objects. When loading the page, it has a users array populated with
        // User model instances. This method returns the correct array depending on the state of the fileModel.
        getFileMembersArray: function getFileMembersArray() {
            // if there's both users and client users - find the client users that don't exist in the users array, 
            // instantiate a user model out of them and return a merged array
            if (this.fileModel.users && this.fileModel.users.length && this.fileModel.client_users && this.fileModel.client_users.length) {
                const instantiatedClientUsers = this.fileModel.client_users.map(clientUser => {
                    const userModel = this.ModelFactory.newUserModel();
                    userModel.mixinFrom(clientUser);
                    return userModel;
                });

                const userIds = new Set(this.fileModel.users.map(user => user._id));
                const uniqueClientUsers = instantiatedClientUsers.filter(clientUser => !userIds.has(clientUser._id));
            
                const mergedUsers = [...this.fileModel.users, ...uniqueClientUsers];
            
                return mergedUsers;
            }

            if (this.fileModel.users && this.fileModel.users.length) {
                return this.fileModel.users;
            } else if (this.fileModel.client_users && this.fileModel.client_users.length) {
                const instantiatedClientUsers = this.fileModel.client_users.map(clientUser => {
                    const userModel = this.ModelFactory.newUserModel();
                    userModel.mixinFrom(clientUser);
                    return userModel;
                });

                return instantiatedClientUsers;
            }

            // fallback just in case
            return this.fileModel.users || this.fileModel.client_users || [];

        },

        loadModel: function loadModel() {
            this.members = this.getFileMembersArray();
            this._getFileClients();
            if (this.fileModel.bill_to) {
                this.billTo = this._.find(this.members, function (member) {
                    return member._id === this.fileModel.bill_to;
                }.bind(this));
            }
        },

        onMemberSelected: function onMemberSelected(member, isFromAddParticipant) {

            // save current state for analytics
            var wasBillToFilled = !!this.billTo;

            this.billTo = member;
            this.WorkspaceFilesManager.setWorkspaceFileBillTo(this.fileModel, this.billTo);
            this.closeDropdown();

            // analytics
            var analyticsArgs = {
                is_edit: wasBillToFilled
            };

            if(!!isFromAddParticipant) {
                analyticsArgs.is_add_participant = true;
            }

            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_bill_to_set_client, analyticsArgs);
        },

        dropdownToggle: function dropdownToggle() {
            if (this.isEditable) {
                this.isDropdownActive = !this.isDropdownActive;

                if(this.isDropdownActive) {
                    var hasBillTo = !!this.billTo;
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.file_bill_to_set_client, {is_edit: hasBillTo});
                }

            }
        },

        closeDropdown: function closeDropdown() {
            this.isDropdownActive = false;
        },

        clearBillTo: function clearBillTo() {
            this.billTo = null;
            this.closeDropdown();
            this.WorkspaceFilesManager.deleteWorkspaceFileBillTo(this.fileModel);
        },

        openAddParticipantModal: function openAddParticipantModal() {
            return this.ReactModalService.openGlobalModal('AddClientToWorkspaceModalWrapper', {
                workspaceId: this.workspace._id,
                customFieldSchemas:  this.company.custom_contact_fields
            }).then(function (addMemberResp) {
                      const userModel = this.ModelFactory.newUserModel();
                      userModel.mixinFrom(addMemberResp.user);
                        this.workspace.members.push(userModel);
                        this.WorkspaceFilesManager.getWorkspaceFileMembers(this.fileModel).then(function success() {

                            this.loadModel();

                            var client = this._.find(this.members, function (member) {
                                return member._id === userModel._id;
                            }.bind(this));
                            this.onMemberSelected(client, true);
                        }.bind(this));
                    }.bind(this));
        },

        _getFileClients: function _getFileClients() {
            this.clients = this.members.filter(function filterFileClients(user) {
                return !user.company || user.company._id !== this.fileModel.owner.company._id;
            }.bind(this));
        },
    });

    return {
        scope: {
            fileModel: '=',
            isEditableMode: '=',
            isEditable: '=',
            workspace: '='
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file/editable_header/bill_to/editable_header_bill_to_template.html',
        controller: EditableHeaderBillToDirectiveController,
        controllerAs: 'billToVm',
        bindToController: true
    };
};
