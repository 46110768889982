(function () {
    "use strict";

    Controllers.ConfigureSessionController = class ConfigureSessionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, $document, AnalyticsService, PopupMessageService, ModalService, SchedulingService, UsersManager, AppStates, $translate, $timeout, _, moment, RegexService, $q, TimezoneService, FeaturesService, UserService, NavigationService, $window, FlowsBetaUserTypeService, FeatureRestrictionService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'ConfigureSessionController';

            this.$state = $state;
            this.$document = $document;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.ModalService = ModalService;
            this.SchedulingService = SchedulingService;
            this.UsersManager = UsersManager;
            this.AppStates = AppStates;
            this.$translate = $translate;
            this.$timeout = $timeout;
            this._ = _;
            this.moment = moment;
            this.RegexService = RegexService;
            this.$q = $q;
            this.TimezoneService = TimezoneService;
            this.UserService = UserService;
            this.NavigationService = NavigationService;
            this.$window = $window;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.FeaturesService = FeaturesService;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.zoomRestrictedFeature = this.FeatureRestrictionService.features.zoom;
            this.isZoomFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.zoomRestrictedFeature);

            // ng2react - Configure session form
            this.configureSessionVariant = 'pending';
            StatsigService.isGateEnabled('ng2react-session-config-form').then(function (isEnabled) {
                this.configureSessionVariant = isEnabled ? 'react' : 'angular';
            }.bind(this)).catch(function () {
                this.configureSessionVariant = 'angular';
            }.bind(this));
               
        
            const unsubscribeFeatureRestrictions = $scope.$watch('configureSessionVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
                this.isZoomFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.zoomRestrictedFeature);
            }.bind(this));
        
            $scope.$on("$destroy", function () {
                unsubscribeFeatureRestrictions();
            });

            this.AnalyticsService.trackPageView(this, 'Configure Session View');

            // options lists
            this.sessionTypes = [
                { key: 'phone_call', label: 'Phone call'},
                { key: 'in_person', label: 'In-person'},
                { key: 'video_call', label: 'Video'},
                { key: 'other', label: 'Other'}
            ];
            this.windowTypes = [
                { key: 'indefinitely', label: 'Indefinitely'},
                { key: 'rolling_window', label: 'Rolling window'},
                { key: 'fixed_date_range', label: 'Fixed date range'}
            ];
            this.videoConferenceTypes = [
                { key: 'google', label: 'Google' },
                { key: 'zoom', label: 'Zoom' },
                { key: 'other', label: 'Other' }
            ];

            this.timezones =  this.TimezoneService.getTimezones();
            this.customIncrements = [5, 10, 15, 20, 30, 45, 60];
            this.defaultIncrement = 30;

            // reset view flags
            this.showBuffers = false;
            this.showNotice = false;
            this.showIncrements = false;
            this.accordionItems = {
                details: { key:'details', open: true, completed: false },
                availability: { key:'availability', open: false, completed: false },
                confirmation: { key:'confirmation', open: false, completed: false }
            };

            // get curr user
            this.user = this.UsersManager.getCurrUser();
            this.company = this.user.company;
            this.isCompanyOwner = this.user.isCompanyOwner();

            // Determine session create / edit
            this.today = this.moment().startOf('day').format("ddd, MMM D, YYYY");
            this.session = {};
            this.sessions = [];
            this.sessionId = (this.$state.params.sessionId && this.$state.params.sessionId !== 'new') ? this.$state.params.sessionId : null;
            this.sessionUserId = this.$state.params.userId || null;
            this.isNewSession = !this.sessionId;

            this.startTimeValid = true;
            this.endTimeValid = true;
            this.memberSelectionValid = true;
            this.googleVideoConferenceValid = true;
            this.zoomVideoConferenceValid = true;

            this.googleCalendarProvider = null;
            this.zoomAuthProvider = null;

            this.returnTo = this.$state.params.returnTo;

            // prepare team members
            this.teamMembers = [];
            const teamMembers = this.company.getAllCompanyMembers();
            teamMembers.forEach(member => {
                if (member.full_name) {
                    this.teamMembers.push({member, selected: false});
                }
            });

            // bypassPendingChangesCheck is true when
            // creating new session or when the user
            // confirmed to discard changes
            this.bypassPendingChangesCheck = false;

            // listen to state change for pending changes
            $scope.$on('$stateChangeStart', function (event, toState, toParams) {
                if (this.configureSessionVariant === 'react') {
                    return;
                }
                this.handlePendingChanges(event, toState, toParams);
            }.bind(this));

            // colors
            this.optionalColors = [...this.SchedulingService._colors];

            var promiseProvider = this.UsersManager.getCalendarProvider(this.user)
                .then(function (response) {
                    if (response.data && response.data.calendar_provider && response.data.calendar_provider._type) {
                        if (response.data.calendar_provider._type === 'GoogleCalendarProvider') {
                            this.googleCalendarProvider = response.data.calendar_provider;
                        } else if (response.data.calendar_provider._type === 'NylasCalendarProvider') {
                            // Nylas integration doesn't support Google meet
                            this.videoConferenceTypes = this.videoConferenceTypes.filter(item => item['key'] !== 'google');
                        }
                    }
                }.bind(this));

            var promiseZoomProvider = this.UsersManager.getZoomAuthProvider(this.user)
                .then(function (response) {
                    this.zoomAuthProvider = response.data.zoom_provider;
                }.bind(this));

            // init
            this.init();
        }

        init() {
            // get session data
            if(this.isNewSession) {
                this.session = this.SchedulingService.newSession(this.user);
                this.buildSessionData();
            } else {
                this.user.getSessions(this.sessionUserId, [this.sessionId], false, true, true).then((res) => {
                    this.session = res[0];
                    this.buildSessionData();
                });
            }
        }

        buildSessionData() {
            this.sessions = [this.session];

            // build data
            this.selectedSessionType = this.session.session_type ? this.sessionTypes.find((st) => st.key === this.session.session_type) : '';
            this.selectedWindowType = this.session.window_type ? this.windowTypes.find((wt) => wt.key === this.session.window_type) : '';
            this.selectedConferenceType = this.session.video_conference_type ? this.videoConferenceTypes.find((vct) => vct.key === this.session.video_conference_type) : '';
            this.selectedIncrement = this.session.availability_increments || this.defaultIncrement;
            this.selectedSessionTimezone = this.TimezoneService.translateFromServer(this.session.session_timezone);

            // set notice & buffers
            this.showBuffers = !!this.session.buffer_before || !!this.session.buffer_after;
            this.showNotice = !!this.session.notice_period;
            this.showIncrements = !!this.session.availability_increments;
            this.showTeamSession = this.session.schedule_behaviour_type === 'single';

            // team members
            if(this.showTeamSession &&
               this.session.shared_member_ids.length) {
                this.teamMembers.forEach((member) => {
                    if(this.session.shared_member_ids.indexOf(member.member._id) > -1) {
                        member.selected = true;
                    }
                });
            }

            // set accordion items
            if(!this.isNewSession) {
                Object.keys(this.accordionItems).forEach((ai) => {
                    this.accordionItems[ai].completed = true;
                });
            }

            // keep origin data to detect changes
            this._originalSessionData = this.session.dataOnly();
        }

        isSessionValid () {
            return this.session.isSessionValid(!!this.googleCalendarProvider, !!this.zoomAuthProvider);

        }

        shouldShowError(fieldName, customValidation = false) {
            const field = this.sessionForm[fieldName];
            const showError = this.sessionForm.$submitted || (field && field.$touched);

            if (customValidation) {
                return showError;
            }

            return showError && (field && field.$invalid);
        }

        getErrorMessage() {

        }

        // ACCORDION

        focusSection(sectionKey, closeOthers) {

            // hide all
            if(closeOthers) {
                Object.keys(this.accordionItems).forEach((ai) => {
                    this.accordionItems[ai].open = false;
                });
            }

            // mark open
            this.accordionItems[sectionKey].open = true;
        }

        nextDetails() {
            this.focusSection('availability', true);
            this.updateSectionsValidity(this.accordionItems.availability.key);
        }

        nextAvailability() {
            this.focusSection('confirmation', true);
            this.updateSectionsValidity(this.accordionItems.confirmation.key);
        }

        onAccordionStateChange(shown, sectionId) {
            if(shown) {
                this.updateSectionsValidity(sectionId);
            }
        }

        triggerAvailabilityRefresh() {
            this.sessions = [this.session];
        }

        updateSectionsValidity(sectionId) {

            // check details
            if(sectionId === 'details') {
                if(!this.session.session_type ||
                    !this.session.session_name ||
                    !this.session.session_duration.unit_count ||
                    !this.session.isWindowValid() ||
                    !this.session.isRemindersValid()) {
                    this.accordionItems.details.completed = false;
                } else {
                    this.accordionItems.details.completed = true;
                }
            }

            // check availability
            if(sectionId === 'availability') {

                // buffers
                if(!this.session.isBuffersValid() || !this.session.isNoticeValid() || !this.session.isIncrementsValid()) {
                    this.accordionItems.availability.completed = false;
                } else {
                    this.accordionItems.availability.completed = true;
                }
            }

            // check confirmation
            if(sectionId === 'confirmation') {
                this.accordionItems.confirmation.completed = true;
            }

        }

        // FORM

        onSessionTypeChange(type) {
            this.session.session_type = type.key;
            this.fireChangeAnalytics('type', this.session.session_type);
            this.onDetailsSectionChange();
        }

        toggleSetAsProjectDate() {
            this.fireChangeAnalytics('set_as_project_date', this.session.set_as_project_date);
        }

        onConferenceTypeChange(type) {
            this.session.video_conference_type = type.key;
            this.fireChangeAnalytics('video conference type', this.session.video_conference_type);
            this.onDetailsSectionChange();
            this.triggerAvailabilityRefresh();
        }

        onSessionTitleChange() {
            this.fireChangeAnalytics('title');
            this.onDetailsSectionChange();
        }

        onSesssionTimezoneChange(timezone) {
            this.session.session_timezone = this.TimezoneService.translateToServer(timezone);
            this.fireChangeAnalytics('session timezone', this.session.session_timezone);
            this.onDetailsSectionChange();
        }

        onWindowTypeChanged(type) {
            this.session.window_type = type.key;

            if(this.session.window_type === 'rolling_window') {
                this.session.fixed_start_date = undefined;
                this.session.fixed_end_date = undefined;
                this.session.rolling_window_duration = {
                    unit_type: 'months',
                    unit_count: '6'
                };
            } else if(this.session.window_type === 'fixed_date_range') {
                this.session.rolling_window_duration = undefined;
                this.session.fixed_start_date = this.moment().startOf('day').format("YYYY-MM-DD");
                this.session.fixed_end_date = this.moment().startOf('day').add(7 ,'d').format("YYYY-MM-DD");
            } else {
                this.session.rolling_window_duration = undefined;
                this.session.fixed_start_date = undefined;
                this.session.fixed_end_date = undefined;
            }

            this.fireChangeAnalytics('window type', this.session.window_type);
            this.onDetailsSectionChange();
            this.triggerAvailabilityRefresh();
        }

        onDurationChange(duration) {
            this.session.session_duration = duration;
            this.onDetailsSectionChange();
        }

        onRollingWindowChange(duration) {
            this.session.rolling_window_duration = duration;
            this.onDetailsSectionChange();
            this.triggerAvailabilityRefresh();
        }

        onFixedDateRangeChange() {
            this.onDetailsSectionChange();
            this.startTimeValid = this.session.isFixedStartDateValid();
            this.endTimeValid = this.session.isFixedEndDateValid();
            this.triggerAvailabilityRefresh();
        }

        onBeforeBufferChange(duration) {
            this.session.buffer_before = duration;
            this.onAvailabilitySectionChange();
        }

        onAfterBufferChange(duration) {
            this.session.buffer_after = duration;
            this.onAvailabilitySectionChange();
        }

        toggleBuffers() {
            this.session.buffer_before = this.showBuffers ? {unit_type: 'minutes', unit_count: '30'} : null;
            this.session.buffer_after = this.showBuffers ? {unit_type: 'minutes', unit_count: '30'} : null;
            this.fireChangeAnalytics('buffer toggle', `${this.showBuffers}`);
            this.onAvailabilitySectionChange();
        }

        addBeforeBuffer() {
            this.session.buffer_before = {unit_type: 'minutes', unit_count: '30'};
            this.fireChangeAnalytics('before buffer added');
            this.onAvailabilitySectionChange();
        }

        removeBeforeBuffer() {
            this.session.buffer_before = null;
            this.fireChangeAnalytics('before buffer removed');
            this.onAvailabilitySectionChange();
        }

        addAfterBuffer() {
            this.session.buffer_after = {unit_type: 'minutes', unit_count: '30'};
            this.fireChangeAnalytics('after buffer added');
            this.onAvailabilitySectionChange();
        }

        removeAfterBuffer() {
            this.session.buffer_after = null;
            this.fireChangeAnalytics('after buffer removed');
            this.onAvailabilitySectionChange();
        }

        onNoticeChange(duration) {
            this.session.notice_period = duration;
            this.onAvailabilitySectionChange();
        }

        toggleNotice() {
            this.session.notice_period = this.showNotice ? {unit_type: 'days', unit_count: '2'} : null;
            this.fireChangeAnalytics('notice toggle', `${this.showNotice}`);
            this.onAvailabilitySectionChange();
        }

        onIncrementsChange(minutes) {
            this.session.availability_increments = minutes;
            this.onAvailabilitySectionChange();
        }

        toggleIncrements() {
            this.session.availability_increments = this.showIncrements ? this.defaultIncrement : null;
            this.fireChangeAnalytics('availability increments toggle', `${this.showIncrements}`);
            this.onAvailabilitySectionChange();
        }


        onDetailsSectionChange() {
            this.updateSectionsValidity(this.accordionItems.details.key);
        }

        onAvailabilitySectionChange() {
            this.updateSectionsValidity(this.accordionItems.availability.key);
        }

        toggleTeamSession() {

            // if no admin permissions block
            if(!this.user.hasCompanyAdminPermissions()) {
                return;
            }

            if(this.showTeamSession) {
                this.session.schedule_behaviour_type = 'single';
                this.resetTeamSession();
                this.toggleTeamMember(this.teamMembers[0])
            } else {
                this.session.schedule_behaviour_type = null;
                this.resetTeamSession();
            }

            this.memberSelectionValid = this.session.isTeamMembersValid();

            this.fireChangeAnalytics('toggle team', `${this.showTeamSession}`);
        }

        resetTeamSession() {
            this.session.shared_member_ids = [];
            this.teamMembers.forEach((m) => m.selected = false );
        }

        toggleTeamMember(member) {

            // if no admin permissions block
            if(!this.user.hasCompanyAdminPermissions()) {
                return;
            }

            member.selected = !member.selected;
            const memberIndex = this.session.shared_member_ids.indexOf(member.member._id);
            if(member.selected) {
                if(memberIndex === -1) {
                    this.session.shared_member_ids.push(member.member._id);
                }
            } else {
                this.session.shared_member_ids.splice(memberIndex, 1);
            }

            this.memberSelectionValid = this.session.isTeamMembersValid();

            this.fireChangeAnalytics('toggle team member', `${member.selected}`);
        }

        selectColor(color) {
            this.session.session_color = color;
        }

        // REMINDERS

        addReminder() {
            this.session.addNewReminder();
            this.onDetailsSectionChange();
        }

        removeReminder(reminder, index) {
            this.session.session_reminders.splice(index, 1);
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.remove_session_reminder);
            this.onDetailsSectionChange();
        }

        // ACTIONS

        save() {

            // mark as submitted so errors will show
            this.sessionForm.$setSubmitted();

            // validate
            if(!this.isSessionValid()) {
                this.setValidationErrors();
                this.goToInvalidField();
                return;
            }

            // mark bypass
            this.bypassPendingChangesCheck = true;

            // clear fields according to session type
            if(this.session.session_type !== 'phone_call') {
                this.session.phone_call_number = '';
            }

            if(this.session.session_type !== 'in_person') {
                this.session.meeting_location = '';
            }

            if(this.session.session_type !== 'video_call' || this.session.video_conference_type !== 'other') {
                this.session.video_meeting_link = '';
            }

            // create / save
            let savePromise = this.isNewSession ? this.user.createSession(this.session) : this.user.updateSession(this.session);
            savePromise.then(function(session) {
                this.user.getSessions(this.user._id, null, false, true).then((sessions) =>{
                    this.FeatureRestrictionService.updateFeatureRestrictions();
                });
                // go to scheduling main page
                if (this.returnTo && this.returnTo.eventId && this.returnTo.workspaceId) {
                    this.$state.go(this.AppStates.root_core_navigation_event_workspace_feed, {
                        event_id: this.returnTo.eventId,
                        workspace_id: this.returnTo.workspaceId
                    });
                } else {
                    // if arrived from the builder, then close the tab after save and finish
                    const searchParams = (new URL(this.$window.location)).searchParams;
                    const origin = searchParams.get('origin');
                    if (origin === 'builder') {
                        window.close();
                    } else {
                        this.$state.go(this.AppStates.root_core_navigation_scheduling, {});

                        // open modal
                        if(this.isNewSession) {
                            this.ModalService.openCreatedSessionModal(session, this.company);
                        }
                    }

                }
            }.bind(this));
        }

        cancel() {
            if (this.returnTo && this.returnTo.eventId && this.returnTo.workspaceId) {
                this.$state.go(this.AppStates.root_core_navigation_event_workspace_feed, {
                    event_id: this.returnTo.eventId,
                    workspace_id: this.returnTo.workspaceId
                });
            } else {
                // if arrived from the builder, then close the tab after cancel
                const searchParams = (new URL(this.$window.location)).searchParams;
                const origin = searchParams.get('origin');
                if (origin === 'builder') {
                    window.close();
                } else {
                    this.NavigationService.goHistoryBack();
                }
            }
        }

        isGoogleVideoConferenceValid() {
            if (this.session.video_conference_type !== 'google') {
                return true;
            }

            return !!this.googleCalendarProvider;
        }

        isZoomVideoConferenceValid() {
            if (this.session.video_conference_type !== 'zoom') {
                return true;
            }

            return !!this.zoomAuthProvider;
        }

        setValidationErrors() {
            this.startTimeValid = this.session.isFixedStartDateValid();
            this.endTimeValid = this.session.isFixedEndDateValid();
            this.googleVideoConferenceValid = this.isGoogleVideoConferenceValid();
            this.zoomVideoConferenceValid = this.isZoomVideoConferenceValid();
        }

        goToInvalidField() {
            let sectionToFocus;
            let elToFocus;

            if(
                !this.session.session_type ||
                !this.session.session_name ||
                !this.session.session_duration.unit_count ||
                !this.session.session_timezone ||
                !this.session.isFixedDateRangeValid() ||
                !this.session.isWindowValid() ||
                !this.session.isGoogleVideoCallValid(!!this.googleCalendarProvider) ||
                !this.session.isZoomVideoCallValid(!!this.zoomAuthProvider)) {

                sectionToFocus = 'details';
                elToFocus = '.js-configure-session__details';
            } else if (!this.session.isRemindersValid()) {

                sectionToFocus = 'details';
                elToFocus = '.js-configure-session__reminders';
            } else if (
                !this.session.isBuffersValid() ||
                !this.session.isNoticeValid() ||
                !this.session.isIncrementsValid()) {

                sectionToFocus = 'availability';
                elToFocus = '.js-configure-session__availability';
            } else if (!this.session.isTeamMembersValid()) {

                sectionToFocus = 'availability';
                elToFocus = '.js-configure-session__team-members';
            }

            // open the main section
            this.focusSection(sectionToFocus);

            // scroll to element
            let el = this.$document.find(elToFocus);
            this.$document.scrollTo(el, 100, 300);
        }

        deleteSession() {
            if (this.isUsedInWorkflows()) {
                const messageKey = this.session.related_workflows.length > 1 ? 'PLURAL' : 'SINGULAR';
                this.PopupMessageService.showAlertWithTitle(
                    this.PopupMessageService.severityTypes.info,
                    this.$translate.instant(`SCHEDULING.CONFIGURE_SESSION.SESSION_USED_IN_WORKFLOW_MESSAGE._${messageKey}`, {
                        workflowCount: this.session.related_workflows.length,
                        workflowTitle: this.session.related_workflows[0].title
                    }),
                    'SCHEDULING.CONFIGURE_SESSION._SESSION_DELETE_DISABLED',
                    undefined,
                    'FREQUENT_BUTTONS._GOT_IT_'
                )
                return;
            }

            var hasFiles = this.FlowsBetaUserTypeService.hasFiles;

            var messageKey = (hasFiles) ?
                'SCHEDULING.CONFIGURE_SESSION._DELETE_SESSION_MESSAGE_WITH_FILES_' :
                'SCHEDULING.CONFIGURE_SESSION._DELETE_SESSION_MESSAGE_';

            var message = this.$translate.instant(messageKey);
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.error,
                message,
                'YES, DELETE',
                'FREQUENT_BUTTONS._CANCEL_',
                'Delete session?').then(function() {

                // analytics
                this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.delete_session);

                this.$timeout(this._onConfirmedDelete.bind(this), 10);
            }.bind(this)).catch(function() {

                // analytics
                this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.delete_session);

            }.bind(this));
        }

        _onConfirmedDelete() {

            // delete
            this.user.deleteSession(this.sessionId).then(() => {

                // go to scheduling page
                this.$state.go(this.AppStates.root_core_navigation_scheduling, {});
            });
        }

        hasPendingChanges() {
            // if new, always has pending changes
            // otherwise check for changes
            return this.isNewSession ||
                    !this._.isEqual(this._originalSessionData,this.session.dataOnly());
        }

        handlePendingChanges(event, toState, toParams) {

            // if there are pending changes, prompt a message
            if(!this.bypassPendingChangesCheck && this.hasPendingChanges()) {

                // prevent state change
                event.preventDefault();

                // show confirm popup
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.none,
                    'You have pending changes that will be discarded. Would you like to continue?',
                    'YES, DISCARD',
                    'FREQUENT_BUTTONS._CANCEL_',
                    'Are you sure?').then(function() {
                    this.bypassPendingChangesCheck = true;
                    this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.discard_session_changes);
                    this.NavigationService.goHistoryBack(toState, toParams);
                }.bind(this)).catch(function() {
                    this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.discard_session_changes);
                }.bind(this));
            }
        }

        onGenericFieldChange(fieldName, fieldValue) {
            this.fireChangeAnalytics(fieldName, fieldValue);
        }

        onValidateSessionLinkUrl(url){
            let urlTest = new RegExp("^https?://", "i");
            return urlTest.test(url);
        }

        openSyncModal(type) {
            if (type === 'zoom' && this.isZoomFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.zoomRestrictedFeature);
                return;
            }
            // if there are pending changes, prompt a message
            if(!this.bypassPendingChangesCheck && this.hasPendingChanges()) {

                // show confirm popup
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.none,
                    'You will be redirected to sign in and complete this integration. Any unsaved changes will be lost. What would you like to do?',
                    'FREQUENT_BUTTONS._CONTINUE_',
                    'FREQUENT_BUTTONS._CANCEL_',
                    'Exit without saving?').then(function() {
                    this.bypassPendingChangesCheck = true;
                    this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.discard_session_changes);

                    if (type === 'zoom') {
                        this.UserService.connectToZoomAccount(this.$window.location.href);
                    } else {
                        this.$state.go(this.AppStates.root_core_navigation_calendar, {integrateFromScheduling: true});
                    }
                }.bind(this)).catch(function() {
                    this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.discard_session_changes);
                }.bind(this));
            } else {
                if (type === 'zoom') {
                    this.UserService.connectToZoomAccount(this.$window.location.href);
                } else {
                    this.$state.go(this.AppStates.root_core_navigation_calendar, {integrateFromScheduling: true});
                }
            }
        }

        fireChangeAnalytics(fieldName, fieldValue) {
            const params = {
                field_name: fieldName,
                field_value: fieldValue
            };
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.session_field_changed, params);
        }

        isUsedInWorkflows() {
            return this.session.related_workflows && this.session.related_workflows.length > 0;
        }
    };

}());
