/**
 * The hb-chips-checklist component
 *
 * This component shows checklist in a chips view. example in signup
 *
 *
 * parameters:
 * input-title: string. text to be appear as the title of the input
 * list: array. the collection that holds all available options. array of objects contains {name, key}
 * selected-item-key: object. the key of selected item
 * on-item-click: function. optional. called when user clicks on item.
 * allow-multiple: I didn't implement this cause I wasn't needed, but in case you want to implement this.
 * you need to change the selectedItemKey model to an array and go over it.
 * look online for 'ng-repeat checkbox'
 *
 */


(function () {
    'use strict';

    class ChipsChecklistController extends Controllers.BaseControllerES6 {

        // @ngInject
         constructor($scope, $injector, uuid4, $, $timeout) {
            super($scope, $injector);
            this.$ = $;
            this.$timeout = $timeout;
            this.__objectType = 'ChipsChecklistController';
            this.listType = this.allowMultiple ? "checkbox" : "radio";
            this.list = this.list.map(item => Object.assign(item,{_id: uuid4.generate()}));
            this.timer = null;
        }

        onClick(key) {
            if (this.onItemClick && angular.isFunction(this.onItemClick)) {
                this.onItemClick(key);
            }
        }

        shouldFocus(index) {
            // yona didnt want to auto focus on HDYHAU.
            // I think we should to allow using the keyboard
            // but we should also add a focus state which is changed from selected state.
            return false; //(index === 0 && this.hasFocus);

        }

        onGotFocus(e) {
            if (this.timer) {
                this.$timeout.cancel(this.timer);
            }

            this.$(e.target).parents('hb-chips-checklist').addClass("signup-form__input--focused");
        }

        onLostFocus(e) {
            var _self = this;
            this.timer = this.$timeout(()=> {
                this.hasFocus = false;
                _self.$(e.target).parents('hb-chips-checklist').removeClass("signup-form__input--focused")
            }, 100);

        }

        onKeyUp(e) {
            this.keyUpEvent({e : e });
        }

    }

    Components.ChipsChecklist = {
        bindings: {
            inputTitle: '@',
            list: '<',
            selectedItemKey: '=',
            onItemClick: '&',
            allowMultiple: '<',
            hasFocus: '=',
            keyUpEvent: '&',
            startTabindex: '<'
        },
        controller: ChipsChecklistController,
        name : 'hbChipsChecklist',
        templateUrl: 'angular/app/modules/common/ui_components/chips-checklist/chips-checklist.html',
    };

}());
