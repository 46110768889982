(function () {
    'use strict';
    Services.ThirdPartyLoaderService = class ThirdPartyLoaderService {
        // @ngInject
        constructor($timeout, $q) {
            this.$timeout = $timeout;
            this.$q = $q;
        }

        /**
         * @param callback A callback that loads and initialize (if needed) the third party script
         * @param getObject A callback that returns the expected object or falsy if it doesn't exists (e.g. () => window.FB
         * @returns {Promise}
         */
        loadCallbackPromise(callback, getObject) {
            return this._loadPromise(completion => {
                callback();
                completion();
            }, getObject);
        }

        /**
         * @param url The url of the script
         * @param getObject A callback that returns the expected object or falsy if it doesn't exists (e.g. () => window.FB
         * @param id An optional id (will be generated if missing) to make sure we don't inject the same script twice
         * @param crossorigin
         * @returns {Promise}
         */
        loadUrlPromise(url, getObject, id, crossorigin = null) {
            const loadLogic = (completion) => this._injectScript(url, id, completion, crossorigin);
            return this._loadPromise(loadLogic, getObject);
        }

        /**
         * @param url
         */
        loadCss(url) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = url;
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        _loadPromise(loadIt, getObject) {
            const defer = this.$q.defer();

            const existingObject = getObject();

            if (existingObject) {
                defer.resolve(existingObject);
            } else {
                // once we ran the callback that loads the scripts (url or callback), we need to start looping until the object exists.
                // for callbacks, it's a matter of calling the callback and then starting the loop.
                // but for urls, we only start the loop at the onload event of the <script> tag so we wrap the looping logic in a callback and pass it to the actual logic mechanism.
                const runOnceDependencyIsLoaded = () => this._loopUntilReady(getObject, defer);
                loadIt(runOnceDependencyIsLoaded);
            }

            return defer.promise;
        }

        _injectScript(url, id, onLoad, crossorigin) {
            if (!id) { // if we didn't get an id, we generate one based on the url so we wouldn't create the same script more than once
                id = 'hbid' + this.hash(url);
            }

            if (document.getElementById(id)) { // we already injected the script, let's just check the object is loaded or wait for it to be loaded
                onLoad();
                return false;
            }

            const newScript = document.createElement('script');
            newScript.type = 'text/javascript';
            newScript.src = url;
            newScript.async = true;
            newScript.defer = true;
            newScript.id = id;
            newScript.onload = onLoad;
            if (crossorigin) {
                newScript.crossOrigin = crossorigin;
            }

            const firstScriptInPage = document.getElementsByTagName('script')[0];
            firstScriptInPage.parentNode.insertBefore(newScript, firstScriptInPage);

            return true;
        }

        _loopUntilReady(getObject, defer) {
            this.$timeout(() => {
                const existingObject = getObject();
                if (existingObject) {
                    defer.resolve(existingObject);
                } else {
                    this._loopUntilReady(getObject, defer);
                }
            }, 200);
        }

        // we use this to generate an id for the script tag so we won't inject the same script twice
        hash(str) {
            let h = 0, i = 0;
            for (; i < str.length; i++) {
                h = Math.imul(31, h) + str.charCodeAt(i) | 0;
            }
            return h;
        }
    };
}());
