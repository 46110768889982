(function () {
    "use strict";

    // @ngInject
    function ClientBotModalControlleCtor($scope, $injector, $modalInstance, $state, EventService, AppStates,DeviceService, $window, $q, AnalyticsService, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ClientBotModalController';

        this.modalInstance = $modalInstance;
        this.$state = $state;
        this.EventService = EventService;
        this.AppStates = AppStates;
        this.$window = $window;
        this.$q = $q;
        this.AnalyticsService = AnalyticsService;
        this.$translate = $translate;

        this.isMobile = DeviceService.nxSmallBreakpoint();

        this.linksList = [
            {onClick: this.createContactForm.bind(this), text: this.$translate.instant("ONBOARDING.BOT.RECAP._CONTACT_FORM_"), showOnMobile: false},
            {onClick: this.createBrochure.bind(this), text: this.$translate.instant("ONBOARDING.BOT.RECAP._BROCHURE_"), showOnMobile: true},
            {onClick: this.exploreTemplates.bind(this), text: this.$translate.instant("ONBOARDING.BOT.RECAP._TEMPLATES_"), showOnMobile: true},
            {onClick: this.addBankAccount.bind(this), text: this.$translate.instant("ONBOARDING.BOT.RECAP._BANK_ACCOUNT_"), showOnMobile: true}
        ]
    }

    Controllers.ClientBotModalController = Class(Controllers.BaseController, {

        constructor: ClientBotModalControlleCtor,

        trackAnalytics: function trackAnalytics(linkTo) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.onboarding_bot_recap_modal, {
                selected_option: linkTo
            });
        },

        close: function close() {
            this.trackAnalytics('click_x');
            this.modalInstance.close();
        },

        closePromise: function closePromise() {
            var defer = this.$q.defer();
            this.modalInstance.close();
            defer.resolve();
            return defer.promise;
        },

        gotoSetupGuide: function gotoSetupGuide() {
            this.trackAnalytics('setup_guide');
            this.closePromise().then(function() {
                this.$state.go(this.AppStates.root_core_navigation_onboardingSetupGuide);
            }.bind(this));

        },

        createProject: function gotoSetupGuide() {
            this.trackAnalytics('create_project');
            this.closePromise().then(function() {
                this.EventService.createEventCommand();
            }.bind(this));
        },

        createContactForm: function createContactForm() {
            this.trackAnalytics('contact_form');
            var url = '/app/templates_editor/contactForm';
            this.$window.open(url,'_blank');
        },

        createBrochure: function createBrochure() {
            this.trackAnalytics('brochure');
            var url = '/app/templates_editor/brochure';
            this.$window.open(url,'_blank');
        },

        exploreTemplates: function exploreTemplates() {
            this.trackAnalytics('templates');
            var url = this.$state.href(this.AppStates.root_core_navigation_templates);
            this.$window.open(url,'_blank');
        },

        addBankAccount: function addBankAccount() {
            this.trackAnalytics('bank_account');
            var url = this.$state.href(this.AppStates.root_core_navigation_settings_company_bankInfo);
            this.$window.open(url,'_blank');
        },

    });
}());
