(function () {
    "use strict";

    // @ngInject
    function CouponsServiceCtor($injector, RepositoryService, Constants, MemberReferralsManager, FullStoryService,
                                AnalyticsService) {
        this.RepositoryService = RepositoryService;
        this.FullStoryService = FullStoryService;
        this.AnalyticsService = AnalyticsService;
        this.MemberReferralsManager = MemberReferralsManager;
        this.Constants = Constants;
        this.$injector = $injector;

        this.coupon = this.RepositoryService.storageGet(this.Constants.storage.COUPON);
    }

    Services.CouponsService = Class(() => {

        return {
            constructor: CouponsServiceCtor,

            getCoupon() {
                return this.coupon;
            },

            applyCoupon(coupon) {
                this.coupon = coupon;
                this.RepositoryService.storagePut(this.Constants.storage.COUPON, coupon);
                this.trackAppliedCoupon(coupon);
            },

            reset() {
                this.coupon = null;
                this.RepositoryService.storageDelete(this.Constants.storage.COUPON);
            },

            trackAppliedCoupon(coupon) {
                this.FullStoryService.trackEvent(
                    this.AnalyticsService.analytics_events.coupon_code_applied,
                    { coupon_code: coupon, action: "action" }
                );
                this.AnalyticsService.track(
                    this,
                    this.AnalyticsService.analytics_events.coupon_code_applied,
                    { coupon_code: coupon }
                );
            }
        };
    });
}());

