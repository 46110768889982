(function () {
    'use strict';

    // @ngInject
    function SchedulingManagerServiceCtor(APIService, ModelFactory, $q, Routes) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }

    Services.SchedulingManager = Class(Services.BaseModelManager, {
        constructor: SchedulingManagerServiceCtor,

        getSessions(user, forUserId, sessionConfigIds, ignoreDayAvailabilities, ignoreTimeFrame = false, getRelatedWorkflows = false) {
            var url = this.Routes.v2_list_session_configs_path(user._id);
            var data = { for_user_id: forUserId, session_config_ids: sessionConfigIds, ignore_day_availabilities: ignoreDayAvailabilities, ignore_time_frame: ignoreTimeFrame, get_related_workflows: getRelatedWorkflows };
            return this.apiFetch(url, data, user, false);
        },

        createSession(user, session) {
            var url = this.Routes.v2_create_session_config_path(user._id);
            var data = session.dataOnly();
            return this.apiCreate(url, data, user, false);
        },

        duplicateSession(sessionId) {
            var url = this.Routes.v2_duplicate_session_config_path(sessionId);
            return this.apiCreate(url, {session_id: sessionId});
        },

        updateSession(user, session) {
            var url = this.Routes.v2_update_session_config_path(user._id, session._id);
            var data = session.dataOnly();
            return this.apiUpdate(url, data, user, false);
        },

        deleteSession(user, sessionId) {
            var url = this.Routes.v2_delete_session_config_path(user._id, sessionId);
            return this.apiDelete(url, null, user, false);
        },
    });
}());
