var models_module = angular.module('honeybook.models', [
    'honeybook.common'
]);

//MODELS
models_module.factory('BaseModel', Models.BaseModel);
models_module.factory('CompanyModel', Models.CompanyModel);
models_module.factory('UserModel', Models.UserModel);
models_module.factory('WorkspaceFileModel', Models.WorkspaceFileModel);
models_module.factory('WorkspaceModel', Models.WorkspaceModel);
models_module.factory('PaymentsContainerModel', Models.PaymentsContainerModel);
models_module.factory('PaymentModel', Models.PaymentModel);
models_module.factory('ServiceModel', Models.ServiceModel);
models_module.factory('PackageModel', Models.PackageModel);
models_module.factory('ProposalModel', Models.ProposalModel);
models_module.factory('RedemptionPolicyModel', Models.RedemptionPolicyModel);
models_module.factory('AgreementModel', Models.AgreementModel);
models_module.factory('EmailModel', Models.EmailModel);
models_module.factory('SignatureModel', Models.SignatureModel);
models_module.factory('EventModel', Models.EventModel);
models_module.factory('SearchableModel', Models.SearchableModel);
models_module.factory('ContactModel', Models.ContactModel);
models_module.factory('QuestionnaireModel', Models.QuestionnaireModel);
models_module.factory('ContactFormModel', Models.ContactFormModel);
models_module.factory('QuestionModel', Models.QuestionModel);
models_module.factory('BrochureProposalsContainerModel', Models.BrochureProposalsContainerModel);
models_module.factory('BrochureALaCarteProposalModel', Models.BrochureALaCarteProposalModel);
models_module.factory('TimelineContainerModel', Models.TimelineContainerModel);
models_module.factory('TimelineSectionModel', Models.TimelineSectionModel);
models_module.factory('TimelineItemModel', Models.TimelineItemModel);
models_module.factory('PayableFileTemplateModel', Models.PayableFileTemplateModel);
models_module.factory('PlaceModel', Models.PlaceModel);
models_module.factory('FeedModel', Models.FeedModel);
models_module.factory('FeedItemModel', Models.FeedItemModel);
models_module.factory('ActivityModel', Models.ActivityModel);
models_module.factory('LeadModel', Models.LeadModel);
models_module.factory('NetworkProjectModel', Models.NetworkProjectModel);
models_module.factory('NetworkReferralModel', Models.NetworkReferralModel);
models_module.factory('NetworkUserModel', Models.NetworkUserModel);
models_module.factory('ReferralCampaignModel', Models.ReferralCampaignModel);
models_module.factory('MemberReferralModel', Models.MemberReferralModel);
models_module.factory('FileBlockModel', Models.FileBlockModel);
models_module.factory('FileSectionModel', Models.FileSectionModel);
models_module.factory('FileDynamicContentModel', Models.FileDynamicContentModel);
models_module.factory('TemplateModel', Models.TemplateModel);
models_module.factory('BlockComponentModel', Models.BlockComponentModel);
models_module.factory('ContactFormSettingsModel', Models.ContactFormSettingsModel);
models_module.factory('SessionModel', Models.SessionModel);
models_module.factory('SubscriptionModel', Models.SubscriptionModel);

//COLLECTIONS
models_module.factory('BaseCollection', Models.BaseCollection);
models_module.factory('UsersCollection', Models.UsersCollection);
models_module.factory('WorkspaceFilesCollection', Models.WorkspaceFilesCollection);
models_module.factory('WorkspacesCollection', Models.WorkspacesCollection);
models_module.factory('PaymentsCollection', Models.PaymentsCollection);
models_module.factory('ServicesCollection', Models.ServicesCollection);
models_module.factory('PackagesCollection', Models.PackagesCollection);
models_module.factory('AgreementsCollection', Models.AgreementsCollection);
models_module.factory('EmailsCollection', Models.EmailsCollection);
models_module.factory('SignaturesCollection', Models.SignaturesCollection);
models_module.factory('EventsCollection', Models.EventsCollection);
models_module.factory('ContactsCollection', Models.ContactsCollection);
models_module.factory('SearchablesCollection', Models.SearchablesCollection);
models_module.factory('QuestionsCollection', Models.QuestionsCollection);
models_module.factory('BlockComponentsCollection', Models.BlockComponentsCollection);
models_module.factory('NetworkReferralsCollection', Models.NetworkReferralsCollection);
models_module.factory('QuestionnairesCollection', Models.QuestionnairesCollection);
models_module.factory('BrochureProposalsContainersCollection', Models.BrochureProposalsContainersCollection);
models_module.factory('BrochureProposalsCollection', Models.BrochureProposalsCollection);
models_module.factory('TimelineSectionsCollection', Models.TimelineSectionsCollection);
models_module.factory('TimelineItemsCollection', Models.TimelineItemsCollection);
models_module.factory('FeedItemsCollection', Models.FeedItemsCollection);
models_module.factory('LeadsCollection', Models.LeadsCollection);
models_module.factory('CompaniesCollection', Models.CompaniesCollection);
models_module.factory('NetworkProjectsCollection', Models.NetworkProjectsCollection);
models_module.factory('NetworkUsersCollection', Models.NetworkUsersCollection);
models_module.factory('ReferralCampaignsCollection', Models.ReferralCampaignsCollection);
models_module.factory('MemberReferralsCollection', Models.MemberReferralsCollection);
models_module.factory('InvoicesCollection', Models.InvoicesCollection);
models_module.factory('ProposalsCollection', Models.ProposalsCollection);
models_module.factory('ContactFormsCollection', Models.ContactFormsCollection);
models_module.factory('FileBlocksCollection', Models.FileBlocksCollection);
models_module.factory('FileSectionsCollection', Models.FileSectionsCollection);
models_module.factory('TemplatesCollection', Models.TemplatesCollection);
models_module.factory('SessionsCollection', Models.SessionsCollection);

//MANAGERS
models_module.service('ValidationsManager', Services.ValidationsManager);
models_module.service('UsersManager', Services.UsersManager);
models_module.service('WorkspaceFilesManager', Services.WorkspaceFilesManager);
models_module.service('WorkspacesManager', Services.WorkspacesManager);
models_module.service('CompaniesManager', Services.CompaniesManager);
models_module.service('PhotosUploadManager', Services.PhotosUploadManager);
models_module.service('ProposalsManager', Services.ProposalsManager);
models_module.service('TemplatesManager', Services.TemplatesManager);
models_module.service('TemplatesRepositoryManager', Services.TemplatesRepositoryManager);
models_module.service('FlowsManager', Services.FlowsManager);
models_module.service('ReferralsManager', Services.ReferralsManager);
models_module.service('EventsManager', Services.EventsManager);
models_module.service('ContactsManager', Services.ContactsManager);
models_module.service('ReportsManager', Services.ReportsManager);
models_module.service('SearchablesManager', Services.SearchablesManager);
models_module.service('ImagesManager', Services.ImagesManager);
models_module.service('BrochuresManager', Services.BrochuresManager);
models_module.service('TimelineManager', Services.TimelineManager);
models_module.service('SelfOnboardingManager', Services.SelfOnboardingManager);
models_module.service('LeadsManager', Services.LeadsManager);
models_module.service('TOSManager', Services.TOSManager);
models_module.service('ContactFormManager', Services.ContactFormManager);
models_module.service('PendingTasksManager', Services.PendingTasksManager);
models_module.service('LinksManager', Services.LinksManager);
models_module.service('MemberReferralsManager', Services.MemberReferralsManager);
models_module.service('EmailPreviewManager', Services.EmailPreviewManager);
models_module.service('TasksManager', Services.TasksManager);
models_module.service('WorkflowManager', Services.WorkflowManager);
models_module.service('TimeTrackingManager', Services.TimeTrackingManager);
models_module.service('OnboardingBotManager', Services.OnboardingBotManager);
models_module.service('HomeManager', Services.HomeManager);
models_module.service('AccountsManager', Services.AccountsManager);
models_module.service('SchedulingManager', Services.SchedulingManager);
models_module.service('VideoConferencingManager', Services.VideoConferencingManager);
models_module.service('FinanceAppManager', Services.FinanceAppManager);
models_module.service('QuickbooksManager', Services.QuickbooksManager);

//UTILS
models_module.service('ModelUtils', Models.ModelUtils);
models_module.service('ModelFactory', Models.ModelFactory);