
(function () {
    'use strict';

    class ClientPortalPreviewSettings extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, AppStates) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalPreviewSettings';
            this.AnalyticsService = AnalyticsService;
            this.AppStates = AppStates;
            this._init();
        }

        _init() {
            this.logoType = this.portalModel.logo.type === 'square';
            this.background_color = this.portalModel.background_color;
        }

        onLogoTypeUpdate(){
            this.portalModel.logo.type = this.logoType ? 'square' : 'rounded';
            this.updated();
        }

        onBackgroundPreview(color) {
            this.portalModel.background_color = color;
        }

        onBackgroundUpdate(color){
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_client_portal_settings, { field: 'background color'});
            this.portalModel.background_color = color;
            this.updated();
        }
    }

    Components.ClientPortalPreviewSettings = {
        bindings: {
            updated: '&',
            portalModel: '='
        },
        controller: ClientPortalPreviewSettings,
        name: 'hbClientPortalPreviewSettings',
        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/preview/settings/client_portal_preview_settings.html'
    };

}());