Directives.FaceboookPageLeadDirective = function FaceboookPageLeadDirective() {

    // @ngInject
    function FaceboookPageLeadDirectiveControllerFunc($scope, $injector, AppStates, CompanyService, $filter, CompaniesManager, AnalyticsService, moment,
                                            Enums, _, UsersManager, $translate, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FaceboookPageLeadDirectiveController';
        this.AppStates = AppStates;
        this.CompanyService = CompanyService;
        this.$translate = $translate;
        this.PopupMessageService = PopupMessageService;
        this.CompaniesManager = CompaniesManager;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.moment = moment;
        this._ = _;
        this.deleting = false;

        this.Enums = Enums;
        this.user = this.UsersManager.getCurrUser();
    }

    var FaceboookPageLeadDirectiveController = Class(Controllers.BaseController, {

        constructor: FaceboookPageLeadDirectiveControllerFunc,

        handleClickDelete: function handleClickDelete() {
            var confirmMessage = this.$translate.instant('FACEBOOK_LEADS._DELETE_CONFIRM_MESSAGE_');
            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, confirmMessage, this.$translate.instant('FACEBOOK_LEADS._APPROVE_')).then(function confirm() {
                this.deleting = true;
                this.CompaniesManager.deleteFacebookLeadPage(this.company, this.page).then(function success(resp) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.facebook_page_disconnected);
                        this.onDelete(this.page);
                    }.bind(this),
                    function error() {

                    }).finally(function finallyDo() {
                    this.deleting = false;
                }.bind(this));
            }.bind(this));
        },
    });

    return {
        scope: {
            page: '=',
            company: '=',
            onDelete: '&'
        },
        templateUrl: 'angular/app/modules/core/features/facebook_leads/facebook_lead_page_directive_template.html',
        controller: FaceboookPageLeadDirectiveController,
        controllerAs: 'fbPageLeadVm',
        bindToController: true
    };
};
