(function () {
    'use strict';

    Directives.WorkspaceNotesDirective = function WorkspaceNotesDirective() {
        // @ngInject
        function WorkspaceNotesDirectiveControllerCtor($scope, $injector, UsersManager, EventsManager) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'NotesDirectiveController';
            this.user = UsersManager.getCurrUser();
            this.EventsManager = EventsManager;

            this.hadNotes = false;
        }

        var WorkspaceNotesDirectiveController = Class(Controllers.BaseController, {
            constructor: WorkspaceNotesDirectiveControllerCtor,

            onNotesClick: function onNotesClick() {
                this.expand();
                this.inEditMode = true;
            },

            onWorkspaceNoteBlur: function onWorkspaceNoteBlur() {
                return this.EventsManager.updateCompanyProjectNotes(this.user.company, this.workspace.__event, {text: this.workspace.__event.company_notes[0].text});
            },

            hasNotes: function hasNotes() {

                if (!this.workspace.__event) {
                    return false;
                }

                var _hasNotes = !!this.workspace.__event.company_notes && !!this.workspace.__event.company_notes[0] && !!this.workspace.__event.company_notes[0].text;

                if (!this.checkedNotes) {
                    this.checkedNotes = true;
                    this.hadNotes = _hasNotes;
                }

                return _hasNotes;
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace/notes/workspace_notes_directive_template.html',
            controller: WorkspaceNotesDirectiveController,
            controllerAs: 'workspaceNotesVm',
            bindToController: true,
            scope: {
                workspace: '=',
                expand: '&'
            }
        };
    };

}());

