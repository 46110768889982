Components.HbLogo = {
    bindings: {
        color: '@?'
    },
    // @ngInject
    controller: function HbLogoComponentController(
            $scope, $element, $attrs, $document, DoodleService, Enums, AbTestService) {

        this.$onInit = function $onInit() {
            this.Enums = Enums;
            this.DoodleService = DoodleService;
            this.AbTestService = AbTestService;

            this.fillColor = null;
            if (this.color) {
                this.fillColor = this.color;
            } else {
                this.fillColor = '#787878';
            }

            this.getContentType();
        };

        this.onDblClick = function onDblClick() {
            this.AbTestService.toggleIsShowAbTestBrowser();
        }

        this.getContentType = function getContentType() {
            switch(DoodleService.getDoodle()){
                case Enums.doodles.winter:
                    this.contentType = 'winter';
                    break;
                case Enums.doodles.july4th:
                    this.contentType = '4th-july';
                    break;
                case Enums.doodles.pride:
                    this.contentType = 'pride';
                    break;
                case Enums.doodles.usaFlag:
                    this.contentType = 'usa-flag';
                    break;
                case Enums.doodles.blackLivesMatter:
                    this.contentType = 'black-lives-matter';
                    break;
                case Enums.doodles.prideBlm:
                    this.contentType = 'pride-blm';
                    break;
                default:
                    this.contentType = 'normal';
            }
        };
    },
    name: 'hbLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/hb_logo.html',
};
