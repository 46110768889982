(function () {
    'use strict';

    // @ngInject
    function PricingPlansServiceCtor(AccountsManager, Enums, $translate, PubSubService, $timeout) {
        this.AccountsManager = AccountsManager;
        this.Enums = Enums;
        this.$translate = $translate;
        this.$timeout = $timeout;

        this.tierTypes = {
            basic: 'basic',
            medium: 'medium',
            top: 'top'
        };

        PubSubService.ventMyBitchUp(this);
    }

    Services.PricingPlansService = Class({
        constructor: PricingPlansServiceCtor,

        _planTypePredicateFunction: function _planTypePredicateFunction(planA, planB) {
            var planTypeWeight= {
                annual: 1,
                monthly: 2
            };

            return planTypeWeight[planA.plan_type] - planTypeWeight[planB.plan_type];
        },
    });
}());
