/**
 * The hb-pipeline-table-heading component
 *
 *
 *
 */


(function () {
    'use strict';

    class PipelineTableHeadingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector)  {
            super($scope, $injector);
        }

    }

    Components.PipelineTableHeading = {
        bindings: {
            sortingModel: '=',
            onSortClick: '&',
            label: '@',
            sortProperty: '=',
            classes: '@'
        },
        controller: PipelineTableHeadingController,
        templateUrl: 'angular/app/modules/core/features/pipeline/main/pipeline_table_heading.html',
    };

}());
