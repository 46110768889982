(function () {

     // Helper function to parse JSON strings
     function parseJsonIfObject(str) {
        if (typeof str === 'string' && str.trim().startsWith('{') && str.trim().endsWith('}')) {
            try {
                const parsed = JSON.parse(str);
                if (typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)) {
                    return parsed;
                }
            } catch (error) {
                return str;
            }
        }
        return str;
    }

    function parseJsonParams(obj) {
        // Ensure the input is an object
        if (typeof obj !== 'object' || obj === null) {
            throw new Error("Input must be a non-null object");
        }
    
    
        // Iterate over all keys in the object
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key] = parseJsonIfObject(obj[key]);
            }
        }
    
        return obj;
    }
    
    "use strict";

    Controllers.NgInReactModalOpenerController = class NgInReactModalOpenerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $stateParams,
            ModalService,
            ngInReactService
        ) {
            super($scope, $injector);
            this.__objectType = 'NgInReactModalOpenerController';

            const modalMethod = ModalService[$stateParams.modalServiceMethodName];
            let parsedParams = {};
            if ($stateParams.modalParams) {
                parsedParams = parseJsonParams(JSON.parse($stateParams.modalParams));
            }

            if (modalMethod) {
                modalMethod.bind(ModalService)(...Object.values(parsedParams|| {}))
                .then((result) => {
                    ngInReactService.sendMessage('ModalResolved', {
                         modalServiceMethodName: $stateParams.modalServiceMethodName,
                         result 
                    });
                })
                .catch((error) => {
                    ngInReactService.sendMessage('ModalRejected', {
                         modalServiceMethodName: $stateParams.modalServiceMethodName,
                         error 
                    });
                })
                .finally(() => {
                    ngInReactService.sendMessage('ModalClosed', { modalServiceMethodName: $stateParams.modalServiceMethodName });
                });
            }
        }
    };

}());
