(function () {
    "use strict";

    // @ngInject
    function ReactLoaderServiceCtor($rootScope, $window, Gon, UsersManager, CompaniesManager, _) {
        this.$window = $window;
        this.Gon = Gon;
        this._ = _;
        this.$rootScope = $rootScope;
        this.UsersManager = UsersManager;
        this.CompaniesManager = CompaniesManager;

        this.loadReactPromise = new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if ($window.honeybookReact) {
                    clearInterval(intervalId);
                    resolve($window.honeybookReact);
                }
            }, 500);
        });
    }

    Services.ReactLoaderService = Class(function () {

        return {

            constructor: ReactLoaderServiceCtor,

            getReactInstance: function getReactInstance() {
                return this.loadReactPromise;
            },

            loadReactInfraData: function loadReactInfraData(props) {
                return new Promise((resolve) => {
                    const currUser = this.UsersManager.getCurrUser();
                    const currCompany = this.CompaniesManager.getCurrCompany();
                    const deregister = this.$rootScope.$watch(() => {
                        if (window.honeybookReact) {
                            if (props && props['forceLoad']) {
                                return true;
                            }
                            return !!((currUser && currUser.isUserFullyLoaded() && (!this._.isEmpty(currCompany) || currUser.isClient())));
                        }
                    }, (newValue) => {
                        if (!!newValue) {
                            this.$window.honeybookReact.loadInfraData(this.Gon, currUser, currCompany);
                            deregister();
                            resolve();
                        }
                    });
                });
            }
        };
    });
}());