(function () {
    'use strict';

    Controllers.WaiveLateFeeController = class WaiveLateFeeController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, $modalInstance, $timeout, $q, $translate,
                    AnalyticsService, StripeHelperService, CompaniesManager, UsersManager, source, payment) {
            super($scope, $injector);
            this.__objectType = 'WaiveLateFeeController';
            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.$q = $q;
            this.$translate = $translate;
            this.AnalyticsService = AnalyticsService;
            this.UsersManager = UsersManager;
            this.payment = payment;
            this.source = source;
            this.title = 'REPORTS.PAYMENTS.WAIVE_LATE_FEE_MODAL._TITLE_';
            this.description = 'REPORTS.PAYMENTS.WAIVE_LATE_FEE_MODAL._BODY_';
            this.isLoading = false;
            this.company = CompaniesManager.getCurrCompany();
            this.user = this.UsersManager.getCurrUser();

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.waive_late_fee_modal, {source: this.source});
        }

        onCancelFee() {
            this.isLoading = true;
            this.UsersManager.waiveLateFee(this.payment)
                .then(() => {
                    this.$modalInstance.close();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        dismissModal() {
            this.$modalInstance.dismiss();
        }
    };
}());
