(function () {
    'use strict';

    Controllers.SearchV2ModalController = class SearchV2ModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $translate,
                    AnalyticsService, SearchV2Service) {
            super($scope, $injector);
            this.__objectType = 'SearchV2ModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;
            this.AnalyticsService = AnalyticsService;
            this.SearchV2Service = SearchV2Service;

            this.AnalyticsService.trackLoad(this, 'searchV2 modal', {});
        }

        dismiss() {
            this.AnalyticsService.trackClick(this, "searchV2 modal close");
            this.SearchV2Service.dismiss();
        }

    };
}());
