(function () {
    "use strict";

    // @ngInject
    function IntroTipControllerCtor($scope, $injector, $state, $modalInstance, AppStates,
                                    AnalyticsService, EventsManager, EventService, WorkspaceService, CompaniesManager,
                                    PopupMessageService, UsersManager, UserService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'IntroTipController';

        this.EventsManager = EventsManager;
        this.EventService = EventService;
        this.WorkspaceService = WorkspaceService;
        this.$state = $state;
        this.appStates = AppStates;
        this.modalInstance = $modalInstance;
        this.companiesManager = CompaniesManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UsersManager = UsersManager;
        this.UserService = UserService;

        this.currUser = UsersManager.getCurrUser();
        this.phoneNumber = "";
        if (this.currUser.phone_number) {
            this.phoneNumber = this.currUser.phone_number;
        } else if (this.currUser.company) {
            this.phoneNumber = this.currUser.company.phone_number;
        }

        this.$scope = $scope;

        AnalyticsService.track(this, "load", {unique: "sms_me_link_to_download",source: "popup"});

    }


    Controllers.IntroTipController = Class(Controllers.BaseController, {


        constructor: IntroTipControllerCtor,

        IntroTip: function IntroTip() {
            var self = this;


        },
        cancelIntroTip: function cancelIntroTip(){
            this.modalInstance.dismiss();
        },
        sendSMSToClient: function sendSMSToClient(){
            this.UserService.sendSMSToUser(this.phoneNumber);
        }
    });
}());

