/**
 * Created by iggy on 7/14/15.
 */
(function () {
    'use strict';

    // @ngInject
    Directives.PipeTooltipsStagesDirecive = function PipeTooltipsSteps() {

        // @ngInject
        function PipelineTooltipsControllerFunc($scope, $document, $injector, WorkspaceService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'pipelineTooltipsControllerFunc';
            this.WorkspaceService = WorkspaceService;

            this.showStepTipsList = false;

            $document.on('click.showStepTips.honeybook', function (e) {
                var container = angular.element("#showStepTips");
                if (this.showStepTipsList && !container.is(e.target) && container.has(e.target).length === 0) {
                    $scope.$evalAsync(function () {
                        this.closeStepTips();
                    }.bind(this));
                }
            }.bind(this));

            this.closeStepTips = function closeStepTips() {
                this.showStepTipsList = false;
            };

            this.$scope.$on('$destroy', function () {
                $document.off('click.showStepTips.honeybook');
            });

            this._filterStages();
        }

        var PipelineTooltipsController = Class(Controllers.BaseController, {
            constructor: PipelineTooltipsControllerFunc,

            showStepTips: function showStepTips() {
                this.showStepTipsList = !this.showStepTipsList;
            },

            _filterStages: function _filterStages() {
                this.unbookedStages = [];
                this.bookedStages = [];

                this.stages.forEach(function(stage) {
                    if (!stage.is_editable) {
                        var cloned = angular.extend({}, stage);
                        if (stage.category === 'lead') {
                            this.unbookedStages.push(cloned);
                        } else {
                            this.bookedStages.push(cloned);
                        }
                    }
                }.bind(this));
            }
        });

        return {
            scope: {
                stages: '='
            },
            templateUrl: 'angular/app/modules/core/features/pipeline/tooltips/pipeline_tooltips_stages_directive_template.html',
            controller: PipelineTooltipsController,
            controllerAs: 'pipelineTooltipsVm',
            bindToController: true
        };
    };
}());
