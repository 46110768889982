(function () {
    "use strict";

    // @ngInject
    function TimeTrackerEntryModalControllerCtor($modalInstance, $scope, $injector, AnalyticsService, source, projectId, timeTrackerEntry, _,
                                                 TimeTrackingManager, EventsManager, UsersManager, PopupMessageService, moment, Enums, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerEntryModalController';


        AnalyticsService.trackPageView(this, 'time_tracker_entry_modal');

        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.$modalInstance = $modalInstance;
        this._ = _;
        this.EventsManager = EventsManager;
        this.TimeTrackingManager = TimeTrackingManager;
        this.UIUtils = UIUtils;

        this.source = source;
        this.isActionFromTool = this.source === Enums.timeTrackerSource.tool;
        this.projectId = projectId;
        this.timeTrackerEntry = timeTrackerEntry;
        this.user = UsersManager.getCurrUser();
        this.requestInProgress = false;
        this.isNewEntry =  !this.timeTrackerEntry._id;
        this.timeTracker = {};

        this.timeTrackerInitialize();

    }

    Controllers.TimeTrackerEntryModalController = Class(Controllers.BaseController, {
        constructor: TimeTrackerEntryModalControllerCtor,

        timeTrackerInitialize: function timeTrackerInitialize () {
            if ( this.projectId) {
                this.event = this.EventsManager.getEvent(this.projectId);
                this.timeTracker.project_id = this.projectId;
            }


            if (this.isNewEntry) {
                this.entryDateFormatted = this.moment().format('MMM DD, YYYY');
                this.timeTracker = {
                    completed_on: this.formatEntryDateAPI(this.entryDateFormatted),
                    billable: true
                };

                if (this.projectId) {
                    this.timeTracker.project_id = this.projectId;
                }

            } else {
                this.timeTracker = this._.assign({}, this.timeTracker, this.timeTrackerEntry );
                this.entryDateFormatted = this.formatForDatePicker(this.timeTracker.completed_on);
                this.isEntryBillable();
            }

            this.shouldPrefixFor = this.getLabelForHeader();

        },

        validateLoggedHours: function validateLoggedHours() {
          return this.timeTrackerForm.timeTracker_entry_hours.$valid;
        },

        calculateTotal: function calculateTotal() {
            if (angular.isDefined(this.timeTrackerForm)) {
                if (this.validateLoggedHours()) {
                    if (this.timeTracker.billable){
                        this.timeTracker.total_amount = this.timeTracker.hours * this.timeTracker.rate;
                    } else {
                        this.timeTracker.total_amount = 0;
                    }
                } else {
                    this.timeTracker.total_amount = '';
                }
            }
        },

        isEntryBillable: function isEntryBillable() {
            !this.timeTracker.billable ? this.timeTracker.total_amount = 0 : this.calculateTotal();
        },

        formatForDatePicker : function formatForDatePicker(date) {
            if (!date) {
                return;
            }

            if (this.moment(date, 'YYYY-MM-DD').isValid()) {
                return this.moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
            }
        },

        formatEntryDateAPI: function formatEntryDateAPI(date) {
            if (!date) { return; }

            if (this.moment(date, 'MMM DD, YYYY').isValid()) {
                return this.moment(date, 'MMM DD, YYYY').format('YYYY-MM-DD');
            }
        },

        validateEntryDate: function validateEntryDate() {
            this.timeTracker.completed_on = this.formatEntryDateAPI(this.entryDateFormatted);
        },

        saveChanges: function saveChanges() {
            if (this.requestInProgress) { return; }

            this.requestInProgress = true;

            if (this.isNewEntry){
                return this.TimeTrackingManager.createTimeTracking(this.user.company, this.timeTracker)
                    .then(function success(resp) {
                        this._trackEntrySaved();
                        return this.$modalInstance.close(resp);
                }.bind(this));
            } else {
                return this.TimeTrackingManager.updateTimeTracking(this.timeTracker)
                    .then(function success(resp) {
                        this._trackEntryUpdated();
                        return this.$modalInstance.close(resp);
                }.bind(this));
            }
        },

        _deleteTimeTrackEntry: function _deleteTimeTrackEntry() {
            this.AnalyticsService.track(this, 'time_tracker_entry_modal_deleted_mobile', {source: "time_tracker_entry_modal",  app_path: "time_tracker_entry_modal", event: "time_tracker_entry_deleted_mobile", eventType: "click"});
            this.TimeTrackingManager.deleteTimeTracking(this.timeTracker._id)
                .then(function success(resp) {
                    return this.$modalInstance.close(resp);
            }.bind(this));
        },

        confirmDeleteTimeTrackEntry: function confirmDeleteTimeTrackEntry() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'TIME_TRACKER.WARNING._MSG_',
                'TIME_TRACKER.WARNING._YES_DELETE_',
                'FREQUENT_BUTTONS._CANCEL_').then(function () {
                this._deleteTimeTrackEntry();
            }.bind(this));
        },

        projectChanged: function projectChanged(projectId) {
            if(!projectId) {
                this.timeTracker.project_id = null;
                this.timeTracker.project_name = "";
            }
        },

        getLabelForHeader: function getLabelForHeader() {
           return this.projectId ? 'for ' : '';
        },

        focusEntryDate: function () {
            angular.element('#timeTracker_entry_date').focus();
        },

        close: function close() {
            this.$modalInstance.dismiss();
        },

        //////////////////////////////////
        // Analytics
        /////////////////////////////////

        _trackEntrySaved: function _trackEntrySaved() {
            this._trackAnalytics('time_tracker_entry_modal_entry_saved');
        },

        _trackEntryUpdated: function _trackEntryUpdated() {
            this._trackAnalytics('time_tracker_entry_modal_entry_edited');
        },

        _trackAnalytics: function _trackAnalytics(event) {
            this.AnalyticsService.track(this, event, {
                source: "time_tracker_entry_modal",
                secondary_source: this.source,
                filled: this._parseTimeEntryFields()
            });
        },

        _parseTimeEntryFields: function _parseTimeEntryFields() {
            var filledFields = [];
            var properties = ["description", "hours", "rate", "billable", "completed_on"];

            if(this.isActionFromTool) {
                properties.push("project_id");
            }

            if(this.isNewEntry) {
                properties.forEach(function(attr) {
                    if(this.timeTracker[attr] !== undefined && this.timeTracker[attr] !== null) {
                        filledFields.push(attr);
                    }
                }.bind(this));
            } else {

                properties.forEach(function(attr) {
                    if(this.timeTracker[attr] !== this.timeTrackerEntry[attr]) {
                        filledFields.push(attr);
                    }
                }.bind(this));

            }

            return filledFields.join(" | ");
        }
    });
}());
