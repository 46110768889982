
(function () {
    'use strict';

    class ClientPortalPreviewHeader extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalPreviewHeader';

            this.deviceList = [
                {type: 'desktop', icon:'icon-hb-nx-desktop-computer'},
                {type: 'mobile', icon:'icon-hb-nx-mobile-phone'}
            ];
        };
    };

    Components.ClientPortalPreviewHeader = {
        bindings: {
            exit: '&',
            save: '&',
            disabledSave: '<',
            activePreviewDevice: '='
        },
        controller: ClientPortalPreviewHeader,
        name: 'hbClientPortalPreviewHeader',
        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/preview/header/client_portal_preview_header.html'
    };

}());