(function () {
    "use strict";


    // @ngInject
    function UserServiceCtor($window, $location, Gon, $q, AppConfigService, UsersManager, Enums, WorkspaceFileService, ModalService,
                             OnboardingService, AnalyticsService, UiPersistenceService, RedirectService, PopupMessageService, PubSubService,
                             AbTestService, _, moment, ServiceLazyLoaderService, $translate, NavigationService, AppStates, $state,
                             $timeout, DeviceService, WebsocketHelperService, ReportsManager,FlowsBetaUserTypeService, StatsigService) {

        this.ModalService = ModalService;
        this.UsersManager = UsersManager;
        this.OnboardingService = OnboardingService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.FacebookService = ServiceLazyLoaderService.get('FacebookService');
        this.$window = $window;
        this.$location = $location;
        this.$translate = $translate;
        this.Gon = Gon;
        this.$q = $q;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this._ = _;
        this.moment = moment;
        this.NavigationService = NavigationService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.AppStates = AppStates;
        this.$state = $state;
        this.UiPersistenceService = UiPersistenceService;
        this.RedirectService = RedirectService;
        this.AppConfigService = AppConfigService;
        this.ReportsManager = ReportsManager;
        this.$timeout = $timeout;
        this.isMobile = DeviceService.checkIfMobileSize();
        this.FlowsBetaUserTypeService=FlowsBetaUserTypeService;
        this.StatsigService = StatsigService;

        this.paymentsValueSum = this.getTotalTransactions();
        this.WebsocketHelperService.registerToRoom(this.UsersManager.getCurrUser()._id + ".total_transactions_updated", this.totalTransactionsUpdated.bind(this));

        PubSubService.ventMyBitchUp(this);

        this.UsersManager.on('loggingOut', this.onLoggingOut, this);

        this.userTypeEnum = [
            {key: 'client', text: 'USER.TYPES._CLIENT_'},
            {key: 'vendor', text: 'USER.TYPES._VENDOR_'}

        ];

        this.hbUserTypes = {
            regular: 'regular',
            demo: 'demo',
            malkut: 'malkut',
            admin: 'admin',
            honeybook_invoicing: 'honeybook_invoicing'
        };

        this.vendorAsClient = {
            system_user_type: 'vendor',
            workspace_role: 'host',
            text: 'USER.WORKSPACE_ROLES._CLIENT_',
            description: 'USER.WORKSPACE_ROLES._CLIENT_'
        };

        this.vendorAsVendor = {
            system_user_type: 'vendor',
            workspace_role: 'vendor',
            text: 'USER.WORKSPACE_ROLES._VENDOR_',
            description: 'USER.WORKSPACE_ROLES._VENDOR_DESCRIPTION_'
        };

        this.vendorAsPlanner = {
            system_user_type: 'vendor',
            workspace_role: 'vendor',
            text: 'USER.WORKSPACE_ROLES._PLANNER_',
            description: 'USER.WORKSPACE_ROLES._PLANNER_DESCRIPTION_'
        };

        this.clientAsClient = {
            system_user_type: 'client',
            workspace_role: 'host',
            text: 'USER.WORKSPACE_ROLES._CLIENT_',
            description: 'USER.WORKSPACE_ROLES._CLIENT_DESCRIPTION_'
        };

        this.actionTypes = {
            viewed_pipeline_referrals: 'viewed_pipeline_referrals'
        };

    }

    Services.UserService = Class(function () {

        return {

            constructor: UserServiceCtor,

            onLoggingOut: function onLoggingOut() {
                delete this.suggestedGoogleContacts;
            },

            getUserTypesEnum: function getUserTypesEnum() {
                return this.userTypeEnum;
            },

            getUserTypesEnumForAddMember: function getUserTypesEnumForAddMember(user, isNewToWorkspace, allowClient, allowChangingType, hidePlannerUserType) {

                // The Best Ugliest code ever

                var userTypeEnum = [];
                var userTypeInfo = {userTypeList: userTypeEnum};

                // New User
                if (!user.system_user_type) {
                    if (allowClient) {
                        userTypeEnum.push(this.clientAsClient);
                        userTypeInfo.selected = this.clientAsClient;
                        user.setSystemUserType(this.clientAsClient.system_user_type);
                    } else {
                        userTypeInfo.selected = this.vendorAsVendor;
                        user.setSystemUserType(this.vendorAsVendor.system_user_type);
                    }

                    userTypeEnum.push(this.vendorAsVendor);

                    // hide planner user type

                    // if (!angular.isDefined(hidePlannerUserType) || !hidePlannerUserType) {
                    //     userTypeEnum.push(this.vendorAsPlanner);
                    // }

                    // we currently do not support adding a new user as a vendor as client (no sense)
                    return userTypeInfo;
                }

                // Client
                if (user.system_user_type == 'client') {
                    // client as client
                    userTypeEnum.push(this.clientAsClient);

                    userTypeInfo.selected = this.clientAsClient;
                    return userTypeInfo;
                }

                // Vendor
                var clientSelected = (user.isMemberOfWorkspace && user.workspace_user_data && user.workspace_user_data.workspace_role && user.workspace_user_data.workspace_role === 'host');

                // we dont allow changing

                if (!clientSelected || isNewToWorkspace) {
                    userTypeEnum.push(this.vendorAsVendor);
                }
                if (allowClient && allowChangingType && (clientSelected || isNewToWorkspace)) {
                    userTypeEnum.push(this.vendorAsClient);
                }

                userTypeInfo.selected = clientSelected ? this.vendorAsClient : this.vendorAsVendor;

                return userTypeInfo;
            },

            getSelectedUserTypeFromEnum: function getSelectedUserTypeFromEnum(user) {
                var self = this;

                var selectedUser = null;
                this.userTypeEnum.forEach(function (type) {
                    if (user.system_user_type === type.key) {
                        selectedUser = type;
                        return;
                    }

                });

                return selectedUser;
            },

            editContact: function editContact(contact) {
                return this.ModalService.openEditContactModal(contact);
            },

            addContact: function addContact() {
                return this.ModalService.openNewAddContactModal();
            },

            newAddPreferredVendorContact: function newAddPreferredVendorContact(source) {
                return this.ModalService.newAddPreferredContactModal(source);
            },

            areUsersTeamMembers: function areUsersTeamMembers(user1, user2) {
                return user1.company && user2.company && user1.company._id === user2.company._id;
            },

            getWebinarLink: function getWebinarLink() {
                if (this.FlowsBetaUserTypeService.hasFlows) {
                    return 'https://academy.honeybook.com/sl/335e3fbb';
                }
                return 'https://www.bigmarker.com/honeybook/HoneyBook-Trialer-Webinar';
            },
           
            openAECalendlyLink: function openAECalendlyLink(user, source, calendlyLink) {   
                this.OnboardingService.markOnboardingOverviewTasksComplete('walkthroughClicked');
                var videoOptions;
                var aeCalendlyLink = calendlyLink || user.ae_calendly_link;
                if (!!aeCalendlyLink) {

                    videoOptions = {
                        modalOpenSource: source,
                        videoTitle: 'Free Walkthrough',
                        videoLink: aeCalendlyLink,
                        modalTitle: 'Schedule a live walkthrough with us',
                        modalSubTitle: undefined,
                        modalBtnText: undefined,
                        modalBtnOnClickFunc: undefined,
                        additionalCssClasses: 'ae-calendly-modal'
                    };

                    this.ModalService.openGenericVideoModal(videoOptions);

                    return;
                }

                user.getAECalendlyLink().then(function gotAECalendlyLink() {
                    aeCalendlyLink = user.ae_calendly_link;

                    videoOptions = {
                        modalOpenSource: 'Left Panel',
                        videoTitle: 'Free Walkthrough',
                        videoLink: aeCalendlyLink,
                        modalTitle: 'Schedule a live walkthrough with us',
                        modalSubTitle: undefined,
                        modalBtnText: undefined,
                        modalBtnOnClickFunc: undefined,
                        additionalCssClasses: 'ae-calendly-modal'
                    };

                    this.ModalService.openGenericVideoModal(videoOptions);

                }.bind(this));
            },

            sendSMSToUser: function sendSMSToUser(phoneNumber) {
                this.AnalyticsService.track(this, "click", {unique: "sms_me_link_to_download", source: "popup"});

                var linkData = {

                    tags: [],
                    channel: 'Website',
                    feature: 'TextMeTheApp',
                    data: {
                        'campaign': 'textMeTheApp'
                    }
                };
                var options = {};

                var callback = function (err, result) {
                    if (err) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS._SMS_WASNT_SENT");
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'Text message sent to ' + phoneNumber.toString() + '. It may take a minute to get to you');
                    }
                }.bind(this);
            },

            /**
             * connectToAGoogleAccount = gets the GoogleAuthUrl and opens the returned url.
             * @param {string} preferredGoogleAccountEmail - a hint to the google auth screen so the email that we started
             * off from our app will automatically be filled into the username input block
             * @param {string} originAppState - could be 'calendar' or 'gmail' to indicate where we started the flow from
             * in our app so we can return to that state later on when we finish the flow on google.
             * @returns {promise} of the call to our server
             */
            connectToAGoogleAccount: function connectToAGoogleAccount(preferredGoogleAccountEmail, requestedIntegrationType, originAppState) {
                originAppState = originAppState || requestedIntegrationType;
                return this.UsersManager.getGoogleAuthURL(preferredGoogleAccountEmail, originAppState, requestedIntegrationType)
                    .then(function success(response) {
                        // we have to do it on the window cause we are changing the location of the app
                        // to another domain (google's)
                        this.$window.location.href = response.data.google_auth_url;
                    }.bind(this))
                    .catch(function fail(error) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS.SERVER_API._TRY_AGAIN_");
                    }.bind(this));
            },

            connectToAGoogleAccountWithPopUp: function connectToAGoogleAccount(preferredGoogleAccountEmail, requestedIntegrationType) {
                const defer = this.$q.defer();
                var done = false;
                this.UsersManager.getGoogleAuthURL(preferredGoogleAccountEmail, 'popup', requestedIntegrationType)
                    .then(function success(response) {
                        const windowFeatures = "left=0";
                        this.$window.onGoogleRedirectComplete = function(isSuccess) {
                            if (isSuccess) {
                                this.UsersManager.refreshGoogleIntegrations();
                                defer.resolve();
                            } else {
                                defer.reject();
                            }
                            done = true;
                        }.bind(this);
                        const popupWindow = this.$window.open(response.data.google_auth_url, "popup", windowFeatures);
                        function onUnload() {
                            if (!done) {
                                defer.reject();
                                done = true;
                            }
                        }
                        popupWindow.addEventListener("unload", onUnload, {capture: true});
                    }.bind(this))
                    .catch(function fail(error) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS.SERVER_API._TRY_AGAIN_");
                        defer.reject();
                        done = true;
                    }.bind(this));
                return defer.promise;
            },

            connectToNylasAccount: function connectToNylasAccount(returnUrl, originState, provider, email) {
                return this.UsersManager.getNylasAuthURL(returnUrl, originState, provider, email)
                    .then(function success(response) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.redirect_to_nylas_success, {requested_email: email, provider_name: provider});
                        this.$window.location.href = response.data.nylas_auth_url;
                    }.bind(this))
                    .catch(function fail(error) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.redirect_to_nylas_failure, error, {requested_email: email, provider_name: provider});
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS.SERVER_API._TRY_AGAIN_");
                    }.bind(this));
            },

            connectToZoomAccount: function connectToZoomAccount(returnUrl) {
                return this.UsersManager.getZoomAuthURL(returnUrl)
                    .then(function success(response) {
                        this.$window.location.href = response.data.zoom_auth_url;
                    }.bind(this))
                    .catch(function fail(error) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS.SERVER_API._TRY_AGAIN_");
                    }.bind(this));
            },

            connectToCalendlyAccount: function connectToCalendlyAccount(returnUrl) {
                return this.UsersManager.getCalendlyAuthURL(returnUrl)
                    .then(function success(response) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.calendy_oauth_send);
                        this.$window.location.href = response.data.calendly_auth_url;
                    }.bind(this))
                    .catch(function fail(error) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "ERRORS.SERVER_API._TRY_AGAIN_");
                    }.bind(this));
            },

            searchGoogleContacts: function searchGoogleContacts(searchTerm, startIndex, maxResults, filterExistingContacts) {
                var defer = this.$q.defer();
                this.UsersManager.searchGoogleContacts(searchTerm, startIndex, maxResults, filterExistingContacts)
                    .then(function success(resp) {
                        defer.resolve(resp.data);
                    })
                    .catch(function failure(resp) {
                        defer.reject(resp);
                    });
                return defer.promise;
            },

            getNextGoogleContacts: function getNextGoogleContacts(numOfResults, refreshContactsFilter) {
                var defer = this.$q.defer();

                if(refreshContactsFilter || !this.suggestedGoogleContacts) {
                    this.UsersManager.getGoogleContacts()
                        .then(function success(resp) {
                            this.suggestedGoogleContacts = resp.data.contacts;
                            this.suggestedGoogleContactsIdx = 0;

                            var newNumOfResults = this.suggestedGoogleContacts.length;

                            if(numOfResults) {
                                newNumOfResults = Math.min(numOfResults, this.suggestedGoogleContacts.length - this.suggestedGoogleContactsIdx);
                            }

                            var sliced = this.suggestedGoogleContacts.slice(this.suggestedGoogleContactsIdx, this.suggestedGoogleContactsIdx + newNumOfResults);
                            this.suggestedGoogleContactsIdx += newNumOfResults;

                            defer.resolve(sliced);
                        }.bind(this))
                        .catch(function failure(resp) {
                            defer.reject(resp);
                        }.bind(this));
                } else {
                    var newNumOfResults = Math.min(numOfResults, this.suggestedGoogleContacts.length - this.suggestedGoogleContactsIdx);
                    var sliced = this.suggestedGoogleContacts.slice(this.suggestedGoogleContactsIdx, this.suggestedGoogleContactsIdx + newNumOfResults);
                    this.suggestedGoogleContactsIdx += newNumOfResults;

                    defer.resolve(sliced);
                }

                return defer.promise;
            },

            syncGoogleContactClicked: function syncGoogleContactClicked() {
                this.trigger('syncGoogleContactClicked');
            },

            goToContactsAndSyncGoogle: function goToContactsAndSyncGoogle() {
                if(!(this.NavigationService.isRouteActive(this.AppStates.root_core_navigation_contacts))) {
                    this.$state.go(this.AppStates.root_core_navigation_contacts, {openSyncGoogleContacts:true});
                } else {
                    this.syncGoogleContactClicked();
                }
            },

            addContactBtnClicked: function addContactBtnClicked() {
                this.trigger('addContactBtnClicked');
            },

            getFirstClientFirstName: function getFirstClientFirstName(users, currUser) {
                // filter me out
                var usersWithoutCurrUser = this._.select(users || [], function (user) {
                    return user._id !== currUser._id;
                }.bind(this));

                // searching for the first client
                var client = this._.find(usersWithoutCurrUser, function(user) {
                    return user.system_user_type === 'client';
                }.bind(this));

                if (client && client.first_name) {
                    return client.first_name;
                }

                // searching for vendor from another company
                var vendor = this._.find(usersWithoutCurrUser, function(user) {
                    return user.company._id !== currUser.company._id;
                }.bind(this));

                return vendor && vendor.first_name;
            },

            has2FAQualificationPeriodPassed: function has2FAQualificationPeriodPassed(user) {
                var delay = parseFloat(this.Gon.user_registration_2fa_delay_in_hours);
                var qualificationPeriodInHours = isNaN(delay) ? 24 : delay;
                return this.moment().diff(user.first_login, 'hours') >= qualificationPeriodInHours;
            },


            shouldShowClientAccessibilityTopNav: function shouldShowClientAccessibilityTopNav(user) {
                return user && !user.shouldStartTrial();
            },

            onClientPortalLogin: function onClientPortalLogin(user) {
                if (user.isVendor()) {
                    this.UsersManager.logout();
                    this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.info, 'CLIENT_PORTAL._VENDOR_REDIRECT_', 'CLIENT_PORTAL._LOGIN_TO_HB_').then(function() {
                        var path = this.$state.href(this.AppStates.root_core_login);
                        var url = (this.AppConfigService.hbUrl() + path).replace('//app', '/app');
                        this.$window.location.href = url;
                    }.bind(this));
                } else {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipeline_view, null);
                    this.RedirectService.redirect(user);
                }
            },

            connectFacebook: function connectFacebook(currUser, requiredPermissions) {
                var defer = this.$q.defer();
                var promise = defer.promise;

                var analyticsData = {
                    social_network_type: this.Enums.SocialNetworkTypes.facebook
                };

                // manage pages hard coded for now
                this.FacebookService.loginToFacebook(requiredPermissions).then(
                    function success(facebookParams) {
                        this.UsersManager.connectSocialAccount(currUser, this.Enums.SocialNetworkTypes.facebook, facebookParams.auth_token).then(
                            function success() {
                                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connecting_social_network, analyticsData);
                                defer.resolve()
                            }.bind(this),
                            function error(resp) {
                                // show error message
                                this._facebookLoginError(resp);
                                defer.reject(resp)
                            }.bind(this)
                        )
                    }.bind(this),
                    function error(resp) {
                        // this error occurs because of facebook api is not synced. ignore it.
                        this._facebookLoginError(resp);
                        defer.reject(resp);
                    }.bind(this)
                );

                return promise;
            },

            _facebookLoginError: function _facebookLoginError(resp){

                this.AnalyticsService.trackError(this,
                                                 this.AnalyticsService.analytics_events.connecting_social_network,
                                                 resp, {social_network_type: this.Enums.SocialNetworkTypes.facebook});
                this.$translate('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: 'Facebook'}).then(function translateResolved(errorMessage) {
                    if (resp.data && resp.data.error_type && resp.data.error_type === 'HBUserError') {
                        errorMessage = errorMessage + ': ' + resp.data.error_message;
                    }
                    this.PopupMessageService.showErrorAlert(errorMessage);
                }.bind(this));

                if (resp.error_subcode !== 467) {
                    this.$translate('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: 'Facebook'}).then(function translateResolved(errorMessage) {
                        this.PopupMessageService.showErrorAlert(errorMessage);
                    }.bind(this));
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, {social_network_type: this.Enums.SocialNetworkTypes.facebook});
                }
            },

            isTodayUsersBirthday: function isTodayUsersBirthday(user) {
                var birthDate = moment(user.date_of_birth).format('MM-DD');
                var todayDate = moment().format('MM-DD');
                var isUsersBirthday = birthDate === todayDate;
                return isUsersBirthday;
            },

            isBankAccountTypeIndividual: function isBankAccountTypeIndividual(user) {
                var bankAccount = user.myBankAccount();
                var userType = bankAccount && bankAccount.type ? bankAccount.type.toLowerCase() : null;
                var isValidUserType = userType === 'individual';
                return isValidUserType;
            },

            showGmailRevokedPopup: function showGmailRevokedPopup(user) {

                var shouldShow = false;
                var persistency = null;
                var currentPersistency = null;

                if (user.gmail_integration_revoked_at) {
                    persistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.gmailRevoked, {});
                    currentPersistency = persistency[user.gmail_integration_revoked_at] || {seenCount: 0, hideIndefinitely: false};

                    // persistency object doesn't event exists, show for sure
                    if (!currentPersistency.seenCount && !currentPersistency.hideIndefinitely) {
                        shouldShow = true;
                    } else {
                        var today = this.moment();
                        var revokedAt = this.moment(user.gmail_integration_revoked_at);
                        var dayPassed = revokedAt.add(1, 'day').isSame(today, 'day');
                        shouldShow = currentPersistency.seenCount === 1 && dayPassed;
                    }
                }

                if (shouldShow) {
                    this.$timeout(function() {
                        this.PopupMessageService.showConfirmPromise(
                            this.PopupMessageService.severityTypes.error,
                            'ACCOUNT.EMAIL_INTEGRATION.REVOKED_POPUP._CONTENT_',
                            'ACCOUNT.EMAIL_INTEGRATION.REVOKED_POPUP._GO_',
                            'ACCOUNT.EMAIL_INTEGRATION.REVOKED_POPUP._SKIP_',
                            'ACCOUNT.EMAIL_INTEGRATION.REVOKED_POPUP._TITLE_',
                            false, false, true
                        ).then(function onSuccess() {
                            currentPersistency.hideIndefinitely = true;
                            this.$state.go(this.AppStates.root_core_navigation_settings_company_integrations);
                        }.bind(this)).finally(function final() {
                            currentPersistency.seenCount++;
                            persistency[user.gmail_integration_revoked_at] = currentPersistency;
                            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.gmailRevoked, persistency);
                        }.bind(this));
                    }.bind(this), 1000);
                }
            },

            bookInPersonLogout: function bookInPersonLogout(nextState, stateParams) {
                return this.UsersManager.vendorLogoutAsClient(this.UsersManager.getCurrUser().client_login_file_id).then(function() {
                    var currUser = this.UsersManager.getCurrUser();
                    this.AnalyticsService.unidentify();
                    this.AnalyticsService.identify(currUser);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.client_onsite_logout);
                    this.$state.go(nextState, stateParams || {});
                }.bind(this));
            },

            onBookInPersonStateChange: function BookInPersonStateChange(event, toState) {
                var currUser = this.UsersManager.getCurrUser();
                if (currUser.client_login_vendor_id && toState.name.indexOf(this.AppStates.root_core_workspaceFile) === -1 && toState.name !== this.AppStates.root_core_receipt) {
                    event.preventDefault();
                    this.showBookInPersonUnauthorizedPopup(currUser);
                }
            },

            showBookInPersonUnauthorizedPopup: function showBookInPersonUnauthorizedPopup() {
                var fileId = this.UsersManager.getCurrUser().client_login_file_id;
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.none,
                    'FILE.CLIENT_ONSITE.UNAUTHORIZED_POPUP._BODY_',
                    'FILE.CLIENT_ONSITE.UNAUTHORIZED_POPUP._COMPLETE_',
                    'FILE.CLIENT_ONSITE.UNAUTHORIZED_POPUP._RETURN_',
                    'FILE.CLIENT_ONSITE.UNAUTHORIZED_POPUP._TITLE_',
                    true, false, true
                ).then(function() {
                    if (this.$state.current.name.indexOf(this.AppStates.root_core_workspaceFile) === -1) {
                        this.$state.go(this.AppStates.root_core_workspaceFileRedirection, { workspace_file_id: fileId });
                    }
                }.bind(this)).catch(function() {
                    this.bookInPersonLogout(this.AppStates.root_core_workspaceFileRedirection, { workspace_file_id: fileId });
                }.bind(this));
            },

            redirectToServerDomain: function redirectToServerDomain() {
                var url = this.AppConfigService.hbUrl() + 'app' + this.$location.path();
                this.$window.location.href = url;
            },

            getTotalTransactions: function getTotalTransactions(userId) {
                userId = userId || this.UsersManager.getCurrUser()._id;
                var total_transactions = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.totalTransactionAmount, {});

                if ((!total_transactions.total_transactions || total_transactions.total_transactions < 0) &&
                    userId && this.UsersManager.getCurrUser().isVendor() && this.UsersManager.getCurrUser().isStarterPlan()) {
                    total_transactions = 0;
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.totalTransactionAmount, {total_transactions: total_transactions, last_update: Date.now()});
                    return total_transactions;
                } else {
                    return total_transactions;
                }
            },

            totalTransactionsUpdated: function totalTransactionsUpdated(json) {
                var data = {};
                try {
                    data = JSON.parse(json).data;
                }
                catch(e) {
                    console.error('totalTransactionsUpdated json error:' + e);
                    console.error('totalTransactionsUpdated json: ' + json);
                }
                this.paymentsValueSum = data.total_transactions;
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.totalTransactionAmount, {total_transactions: data.total_transactions});
            },

            getShouldShowEmailDeliverabilityTest: function getShouldShowEmailDeliverabilityTest(emailProvidersRespData) {
                return this.getHasCustomDomainEmailIntegration(emailProvidersRespData).then(
                    function (response) {
                        this.showEmailDeliverabilityTest = response;
                        return this.showEmailDeliverabilityTest;
                    }.bind(this)
                );
            },

            getHasCustomDomainEmailIntegration: function getHasCustomDomainEmailIntegration(emailProvidersRespData) {
                return emailProvidersRespData ?
                    Promise.resolve(this.hasCustomDomainEmailIntegration(emailProvidersRespData))
                    : this.UsersManager.getEmailProviders(this.UsersManager.getCurrUser()).then(
                          function (resp) {
                              return this.hasCustomDomainEmailIntegration(resp.data);
                          }.bind(this)
                      );
            },

            hasCustomDomainEmailIntegration: function hasCustomDomainEmailIntegration(emailProvidersRespData) {
                return emailProvidersRespData.providers_list.some(function (provider) {
                    return (
                        provider.is_custom_domain &&
                        provider.type === emailProvidersRespData.selected_provider
                    );
                });
            },

            getIsConsultantABTest: function getIsConsultantABTest() {
                var currUser = this.UsersManager.getCurrUser();
                if (!currUser.isConsultantTrialStartedAfterABTestDate()) {
                    return Promise.resolve(false);
                } else {
                    return this.StatsigService.getExperimentConfigValue(
                        this.Enums.StatsigExperiments.consultantsAdjusments,
                        'show_adjustments',
                        false
                    );
                }
            }
        };
    });
}());
