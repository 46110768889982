
// @ngInject

Models.QuestionModel = function QuestionModel(BaseModel, _, Enums){
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'QuestionModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds(){
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object){
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            var args = {
                question_id: this._id,
                question_type: this.question_type
            };

            angular.extend(args, this.__parent.getAnalyticsArgs());

            return args;
        },

        supportingMultipleAnswers: function supportingMultipleAnswers() {
            return this.support_multiple_answers;
        },

        isAnswered: function isAnswered() {

            if (this.supportingMultipleAnswers()) {
                for (var i in this.optional_answers) {
                    if (this.optional_answers[i].selected) {
                        return true;
                    }
                }

                return false;
            }

            return this.answer !== null && this.answer !== undefined && this.answer !== '';

        },

        isShownToClient: function isShownToClient() {
            if (this.supportingMultipleAnswers()) {
                return this.optional_answers.length > 0;
            } else {
                return !_.isEmpty(this.question_text) || !_.isEmpty(this.placeholder);
            }
        },

        ///////////////////////////////
        // API
        //////////////////////////////

        updateQuestion: function updateQuestion(changedFields) {
            var params = this.fieldsOnly(changedFields);
            params.question_id = this._id;
            if (changedFields.indexOf('optional_answers') >= 0) {
                params.optional_answers = [];
                this.optional_answers.forEach(function (option) {
                    params.optional_answers.push({
                        _id: option._id,
                        answer_text: option.answer_text,
                        order: option.order,
                    });
                });
            }

            if (this.optional_answers && !this.connected_property) {
                const questionData = Object.assign({}, {optional_answers: this.optional_answers}, params);
                return this.__parent.updateComponent(null, null, null, {question: questionData});
            }

            return this.__parent.updateComponent(null, null, null, {question: params});
        },

        answerQuestion: function answerQuestion(changedFields) {
            var params = this.fieldsOnly(changedFields);
            params.question_id = this._id;
            if (changedFields.indexOf('optional_answers') >= 0) {
                params.optional_answers = [];
                this.optional_answers.forEach(function (option) {
                    if(option.selected) {
                        params.optional_answers.push({
                            _id: option._id,
                            selected: option.selected,
                            linked_object_id: option.linked_object_id
                        });
                    }
                });
            }
            return this.__parent.answerQuestion(null, null, null, {question: params});
        },

        ///////////////////////////////
        // New Question model
        //////////////////////////////

        isDeletable: function isDeletable() {
            return !!this.is_deletable;
        },

        isValid: function isValid() {

            // validate question text
            if(!this.text) {
                return false;
            }

            // validate options
            const isInsideBrochure = this.__parent.getRootModelType() === 'brochure';
            if(this.isMultiChoiceQuestion()) {
                if(!this.optional_answers || this.optional_answers.length === 0) {
                    return false;
                }

                const visitedOptions = {};

                return this.optional_answers
                    .every(function(option) {
                    var answer_text = option.answer_text && option.answer_text.trim();
                    var isOptionValid = !!answer_text && (isInsideBrochure || !visitedOptions[answer_text]);
                    visitedOptions[answer_text] = true;

                    return isOptionValid;
                }.bind(this));
            }

            return true;
        },

        isAnswerValid: function isAnswerValid() {

            // if not required, always valid
            if(!this.is_required) {
                return true;
            }

            // multiple choice option
            if(this.optional_answers) {
                return this.optional_answers.some(function(option) { return option.selected; });
            }

            // all other types
            return this.answer !== null && this.answer !== undefined && this.answer !== '';
        },

        isSmartQuestion: function isSmartQuestion() {
            return !!this.connected_property;
        },

        isQuestionTypeOf: function isQuestionTypeOf(type) {
            return this.isSmartQuestion() ? this.connected_property === type : this.question_type === type;
        },

        isMultiChoiceQuestion: function isMultiChoiceQuestion() {
            return _.contains(['dropdown', 'radio', 'checkbox'], this.question_type);
        },

        ///////////////////////////////
        // Multi Choice Only
        //////////////////////////////

        addOption: function addOption() {

            if(!this.optional_answers) {
                this.optional_answers = [];
            }

            this.optional_answers.push({answer_text: '', selected: false});
        },

        isOptionsEditable: function isOptionsEditable() {
            if(!this.isSmartQuestion()) {
                return true;
            }

            return (this.connected_property !== Enums.suggestedQuestions.lead_source &&
                this.connected_property !== Enums.suggestedQuestions.project_type);
        },

    });
};

