(function () {
    "use strict";

    // @ngInject
    function TwoFactorAuthModalControllerCtor($scope, $injector, $modalInstance, UsersManager, enablePhoneVerification, enableEmailVerification) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TwoFactorAuthModalController';

        this.UsersManager = UsersManager;
        this.modalInstance = $modalInstance;
        this.enablePhoneVerification = enablePhoneVerification;
        this.enableEmailVerification = enableEmailVerification;
    }

    Controllers.TwoFactorAuthModalController = Class(Controllers.BaseController, {

        constructor: TwoFactorAuthModalControllerCtor,

        onCodeVerificationSet: function onCodeVerificationSet(code, justClose, selectedMethod) {
            if (justClose) {
                this.modalInstance.dismiss({cancel: true});
            } else {
                this.modalInstance.close({cancel: false, code: code, selectedMethod: selectedMethod});
            }
        },

    });
}());

