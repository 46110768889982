(function () {
    'use strict';

    class SignupHeaderController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'SignupHeaderController';

            this.progressNumerator = this.form.currentStageIndex + 1;
            this.progressDenominator = this.form.stages.length;
        }

        handleCloseIntakeForm(e) {
            e.preventDefault();
            e.currentTarget.blur();
            window.location = this.returnLocation || '/';
        }

        handleBackIntakeForm(e) {
            e.preventDefault();
            e.currentTarget.blur();
            this.moveToPreviousStage();
        }

        handleNextIntakeForm(e) {
            e.preventDefault();
            e.currentTarget.blur();
            this.moveToNextStage();
        }
    }


    Components.SignupHeader = {
        bindings: {
            form: '<',
            moveToPreviousStage: '&',
            moveToNextStage: '&',
            nextIsDisabled: '<',
            isFacebookMobileFlow: '<',
            returnLocation: '<',
        },
        controller: SignupHeaderController,
        name: 'hbSignupHeader',
        templateUrl: 'angular/app/modules/core/features/signup/signup_header/signup_header_component.html',
    };

}());