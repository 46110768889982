(function () {
    'use strict';

    Services.UIUtils = class UIUtils {

        // @ngInject
        constructor(Enums, UsersManager, _, $translate, $, $timeout, AnalyticsService, moment, $sanitize) {
            this.Enums = Enums;
            this.UsersManager = UsersManager;
            this._ = _;
            this.$translate = $translate;
            this.$ = $;
            this.$timeout = $timeout;
            this.moment = moment;
            this.$sanitize = $sanitize;
            this.AnalyticsService = AnalyticsService;
        }

        generateClassForUserType() {
            if (this.UsersManager.getCurrUser().isVendor()) {
                return "user-type-vendor";
            } else {
                return "user-type-client";
            }
        }

        isValidZipCode(zipCode, country){
            let _country = country || 'US';
            const zipCodePatterns = {
                US: /^\d{5}(?:[-\s]\d{4})?$/,
                CA: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/
            };

            return zipCodePatterns[_country].test(zipCode);
        }

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        downloadFileFromLink($event, url, analyticsEventName) {
          let div = angular.element($event.currentTarget);
          div.parent().append('<a id="temp_link" ></a>');
          let link = div.parent().find("#temp_link");
          link.attr("href", url);
          // to avoid problems of $apply inside $apply
          this.$timeout(function () {
            link[0].click();
            link.remove();
            analyticsEventName && this.AnalyticsService.track(this, analyticsEventName);
          }.bind(this), 10);
        }

        findDeep(list, predicate, listKey) {
            let foundItem = undefined;

            const _findDeep = function _findDeep(list, predicate, listKey) {
                list.forEach(item => {
                    if (predicate && angular.isFunction(predicate) && predicate(item)) {
                        foundItem = item;
                        return;
                    }
                    if (item[listKey]) {
                        _findDeep(item[listKey], predicate, listKey)
                    }
                });
            }(list, predicate, listKey);

            return foundItem;
        }

        //Taken from: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
        hexToRgbA(hex) {
            var rgbVals = this.hexToRgbVals(hex);

            if (rgbVals) {
                return 'rgba(' + rgbVals + ',1)';
            } else {
                return hex;
            }
        }

        hexToRgbAWithAlpha(hex, alpha) {
            var rgbVals = this.hexToRgbVals(hex);

            if (rgbVals) {
                return 'rgba(' + rgbVals + ',' + alpha + ')';
            } else {
                return hex;
            }
        }

        hexToRgbVals(hex){

            var c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
            } else {
                return null;
            }

        }

        getAlphaFromRgbaValue(rgbaValue) {
            var lastCommaPosition = rgbaValue.lastIndexOf(",") + 2;
            return rgbaValue.substring(lastCommaPosition, rgbaValue.length - 1);
        }

        generateClassByFileType(fileTypeCd) {
            switch (fileTypeCd) {
                case this.Enums.WorkspaceFileTypes.invoice:
                    return 'file-type-invoice';
                case this.Enums.WorkspaceFileTypes.questionnaire:
                    return 'file-type-questionnaire';
                case this.Enums.WorkspaceFileTypes.brochure:
                    return 'file-type-brochure';
                case this.Enums.WorkspaceFileTypes.timeline:
                    return 'file-type-timeline';
                case this.Enums.WorkspaceFileTypes.agreement:
                    return 'file-type-agreement';
                case this.Enums.WorkspaceFileTypes.proposal:
                default:
                    return 'file-type-proposal';
            }
        }

        formatRoundNumber(number, digits_after_dot) {
            var formatedNumber = number;
            if (number > 1000) {
                // from 1000 to 1K, 1000000 to 1M, etc...
                var numberLength = (number.toString()).length;
                digits_after_dot = Math.pow(10, digits_after_dot);
                var metricPrefixes = " KMGTPEZY"
                numberLength -= numberLength % 3;
                formatedNumber = Math.round(number * digits_after_dot / Math.pow(10,numberLength)) / digits_after_dot;
                if (numberLength/3 > 0) {
                    formatedNumber = formatedNumber + metricPrefixes[numberLength/3]
                }
            }
            return formatedNumber;
        }

        generateClassForFileType(workspaceFile) {
            if (workspaceFile.isOwnerMode()) {
                return "file-owner";
            } else {
                return "file-client";
            }
        }

        generateClassForFilePreviewMode(previewModeBool) {
            if (previewModeBool) {
                return "preview-mode";
            } else {
                return "edit-mode";
            }
        }

        generateClassForActiveState(activeState) {
            if (activeState) {
                return 'unarchived';
            } else {
                return 'archived';
            }
        }

        generateClassForRequest(requestActive) {
            if (requestActive) {
                return 'requestFile';
            } else {
                return 'not_request';
            }
        }

        generateClassForCanceledState(canceledState) {
            if (canceledState) {
                return 'workspace-canceled';
            } else {
                return '';
            }
        }

        generateClassForWorkspaceState(workspace) {
            if (workspace.isCanceled()) {
                return 'Canceled';
            }

            if (workspace.isArchived()) {
                return 'Archive';
            }

            if (workspace.isBooked()) {
                return 'Booked';
            }

            return 'Lead';
        }

        generateClassForEditableState(isEditable){
            if (isEditable){
                return 'editable-file-mode';
            }else{
                return 'summary-file-mode';
            }
        }

        generateClassForDraftMode(isDraft) {
            if (isDraft) {
                return 'draft-file-mode';
            } else {
                return '';
            }
        }

        toBinaryBase64(string) {
            if (!string) {
                return '';
            }
            var codeUnits = new Uint16Array(string.length);
            for (var i = 0; i < codeUnits.length; i++) {
                codeUnits[i] = string.charCodeAt(i);
            }
            return window.btoa(
                new Uint8Array(codeUnits.buffer).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '')
            );
        }

        fileTypeTranslate(fileType) {
            switch (fileType) {
                case this.Enums.WorkspaceFileTypes.invoice:
                    return this.$translate.instant('FILE.RIBBON_LABEL._INVOICE_').toLowerCase();
                case this.Enums.WorkspaceFileTypes.questionnaire:
                    return this.$translate.instant('FILE.RIBBON_LABEL._QUESTIONNAIRE_').toLowerCase();
                case this.Enums.WorkspaceFileTypes.brochure:
                    return this.$translate.instant('FILE.RIBBON_LABEL._BROCHURE_').toLowerCase();
                case this.Enums.WorkspaceFileTypes.timeline:
                    return this.$translate.instant('FILE.RIBBON_LABEL._TIMELINE_').toLowerCase();
                case this.Enums.WorkspaceFileTypes.agreement:
                    return this.$translate.instant('FILE.RIBBON_LABEL._AGREEMENT_').toLowerCase();
                case this.Enums.WorkspaceFileTypes.proposal:
                    return this.$translate.instant('FILE.RIBBON_LABEL._PROPOSAL_').toLowerCase();
                default:
                    return 'file';
            }
        }

        isVendor(){
            if (this.UsersManager.getCurrUser().isVendor()){
                return true;
            } else{
                return false;
            }
        }

        fixExternalUrl(url) {
            if (!url) {
                return '';
            }

            var return_url = url.trim();

            if (/^https?:\/\//i.test(return_url)) {
                return return_url;
            } else if (return_url.indexOf('//') === 0) {
                return 'http:' + return_url;
            } else {
                return 'http://' + return_url;
            }
        }

        /**
         * converts a full url to a more pretty url that is nicer to show as a the link text
         * @param {string} url a url that starts with http:// (like http://www.some.domain.here)
         * @returns {string} the part after the http:// (in the example www.some.domain.here)
         */
        extractPrettyWebsiteLink(url) {
            if( this._.isEmpty(url)){
                return null;
            }

            var splitUrl = url.split('://', 2);
            if(splitUrl.length === 2){
                //if we have the http prefix return for the presentation just the latter part
                return splitUrl[1];
            } else if (splitUrl.length === 1){
                // if there was no http:// just return the string as is
                return url;
            } else {
                return null;
            }
        }

        /*
         * Removes HTML comments from given string
         * includes <!--...--> and escaped comments.
         *
         * @param  {string} string
         * @return {string}
         */
        stripHTMLComments(string) {
            if (!string) {
                return '';
            }
            var pattern = /(&lt;|<!--)[\s\S]*?(--&gt;|>)/g;
            return string.replace(pattern, '');
        }

        concatenateString(text, maxLength) {
            if (!text || text.length === 0 || !maxLength) {
                return '';
            }
            return text.slice(0, maxLength);
        }

        getCountryFromGeoAddrComponents(address_components) {
            // geocodeResult response result
            // https://developers.google.com/maps/documentation/geocoding/intro#Types
            return address_components.filter(function(obj){return obj.types[0] === 'country';})[0].long_name;
        }

        getCountryFromGeoAddrComponentsShortName(address_components) {
            // geocodeResult response result
            // https://developers.google.com/maps/documentation/geocoding/intro#Types
            return address_components.filter(function(obj){return obj.types[0] === 'country';})[0].short_name;
        }

        getCityFromGeoAddrComponents(address_components) {
            var localityGeoLocation = address_components.filter(function(obj){return obj.types[0] === 'locality';});
            if (localityGeoLocation.length > 0) {
                return localityGeoLocation[0];
            }

            // If we don't have "locality", we'll fallback to administrative_area_level_3 (happens in rural areas for example)
            var adminLevel3GeoLocation = address_components.filter(function(obj){return obj.types[0] === 'administrative_area_level_3';});
            if (adminLevel3GeoLocation.length > 0) {
                return adminLevel3GeoLocation[0];
            }

            // If admin level 3 is not present, last resort would be to go administrative_area_level_2, which is broader
            return address_components.filter(function(obj){return obj.types[0] === 'administrative_area_level_2';})[0];
        }

        getCityFromGeoAddrComponentsLongName(address_components) {
            return this.getCityFromGeoAddrComponents(address_components).long_name;
        }

        getCityFromGeoAddrComponentsShortName(address_components) {
            return this.getCityFromGeoAddrComponents(address_components).short_name;
        }

        getStateCodeFromGeoAddrComponents(address_components) {
            return address_components.filter(function(obj){return obj.types[0] === 'administrative_area_level_1';})[0];
        }

        getStateCodeFromGeoAddrComponentsLongName(address_components) {
            return this.getStateCodeFromGeoAddrComponents(address_components).long_name;
        }

        getStateCodeFromGeoAddrComponentsShortName(address_components) {
            return this.getStateCodeFromGeoAddrComponents(address_components).short_name;
        }

        getHtmlBodyCleanInlineStyle(body) {
            var $elements, $template, $spans;
            var _this = this;
            $template = _this.$("<div>" + body + "</div>");
            $elements = $template.find('p, h1, h2, h3, ul, li, div');
            $elements.css({
                'font-family': '',
                'font-size': '',
                'line-height': '',
                'border': '',
                'margin': '',
                'padding': '',
                'color': ''
            });
            $spans = $template.find('span');
            $spans.each(function () {
                if (_this.$(this).css('color') === 'rgb(141, 154, 165)') {
                    _this.$(this).css({
                        'color': ''
                    });
                }
            });
            return $template.html();
        }

        sanitize(text) {
            // Use this when you dont need to render HTML. and all you need is a safe string.
            return this._.escape(text);
        }

        sanitizeHtml(htmlBody) {
            // Use this when you NEED to render HTML.
            // Like links and CK Editor content etc...
            // and all you need is a safe HTML
            return this.$sanitize(htmlBody)
        }

        replaceHtmlInputsWithString(lookIn, replaceWith) {
            return lookIn.replace(/(<input [^/>]+(value="([^"]*)")[^/>]+)(\/?>)/g, '<span>' + replaceWith + '</span>');
        }

        capitalize(str) {
            var strToCapitalize = str ? str + "" : "";
            return strToCapitalize.charAt(0).toUpperCase() + strToCapitalize.slice(1).toLowerCase();
        }

        convertToCamelCase(string) {
            // This is only written to handle
            // "spaced strings like this" => "spacedStringsLikeThis"
            //
            // This method isn't written for kebab-case-strings,
            // pathological test cases from QA, or.other.weird
            // spacing_schemes_that you//can//dream//up
            //
            // Please expand if needed
            const firstWord = string.split(' ')[0].toLowerCase();
            const otherWordsCaptialized =
                string.split(' ')
                    .slice(1)
                    .map(this.capitalize);

            return [firstWord, ...otherWordsCaptialized].join('');
        }

        toPercentage(number) {
            const digits = number ? Math.round(number * 100) : 0;
            return `${digits}%`;
        }

        convertStringToObject(rawString) {
            // converts string like this: "name: yaniv, last: aharon"
            // into a valid object like this: {name: yaniv, last: aharon}
            var returnedObject = {};
            if (rawString) {
                var items = rawString.split(',');
                this._.each(items, function (item) {
                    var itemArray = item.split(':');
                    if (itemArray.length === 2) {
                        var key = itemArray[0].trim();
                        var value = itemArray[1].trim();
                        returnedObject[key] = value;
                    }
                });
            }
            return returnedObject;
        }

        alphanumericalOnly($event) {
            var key = $event.keyCode ? $event.keyCode : $event.which;
            var entryValue = $event.target.value;

            // unlike chrome, firefox triggers keypress on backspace as well
            if (key === 8) {
                return;
            }

            if (key === 13 || key === 32) {
                $event.preventDefault();
            }

            // 65-122 is all english alphabet
            if (isNaN(String.fromCharCode(key)) && !(key >= 65 && key <= 122)){
                $event.preventDefault();
            }
        }

        digitsOnly($event, decimalPrecision, preventDecimals, allowNegative ) {
            var key = $event.keyCode ? $event.keyCode : $event.which;
            var entryValue = $event.target.value;

            // unlike chrome, firefox triggers keypress on backspace as well
            if (key === 8) {
                return;
            }

            if (key === 13) {
                $event.preventDefault();
            }

            if (isNaN(String.fromCharCode(key)) && key !== 46 && key !== 45){
                $event.preventDefault();
            }

            if (!preventDecimals && entryValue.indexOf(".") > -1 ) {
                if (key === 46) { $event.preventDefault(); }
            }

            if (allowNegative && entryValue.indexOf("-") > -1 ) {
                if (key === 45) { $event.preventDefault(); }
            }

            if (!allowNegative && key === 45) {
                $event.preventDefault();
            }

            if ( entryValue.indexOf(".") > -1 ) {
                switch (decimalPrecision){
                    case 4 :
                        if (entryValue.split(".")[1].length > 3 && key !== 45) {
                            $event.preventDefault();
                        }
                        break;

                    case 2  :
                    default :
                        if (entryValue.split(".")[1].length > 1 && key !== 45) {
                            $event.preventDefault();
                        }
                }
            }
        }

        formatTextEvenly(text, seperateAtCharCount, seperator) {
            var res = '';
            var spl = text.split(' ');
            var currCharCount = 0;
            while (spl.length > 0) {
                var word = spl.shift();
                currCharCount += word.length;
                res += word;
                if (currCharCount > seperateAtCharCount) {
                    res += seperator;
                    currCharCount = 0;
                } else {
                    res += ' ';
                }
            }
            return res;
        }

        isEmptyOrWhiteSpace(text) {
            return ((text === undefined) || (text === null) || (text.toString().trim() === ''));
        }

        formatDate(date, format) {
            return this.moment(date).format(format);
        }

        /**
         * @param {string} dateTime dateTime to increase
         * @param {string} unit time unit to increase by (hours/minutes/seconds)
         * @param {number} amount number of units to increase by
         * @returns {Moment} moment instance in time format h:mm A
         */

        increaseTimeBy(dateTime, unit, amount) {
            return this.formatDate(this.moment(dateTime).add(amount, unit), 'h:mm A');
        }

        joinListWithCommasAndAmpersand(list) {
            return list.reduce(function (previous, current, index, array) {
                if (index === array.length - 1) {
                    return previous + ' & ' + current;
                } else {
                    return previous + ', ' + current;
                }
            });
        }

        makeBlackOrWhiteForContrast(backgroundHex) {
            var textColor = '#FFFFFF';

            if (!backgroundHex) {
                return textColor;
            }

            if (backgroundHex.indexOf('#') === 0) {
                backgroundHex = backgroundHex.slice(1);
            }

            if (backgroundHex.length === 3) {
                backgroundHex =
                    backgroundHex[0] +
                    backgroundHex[0] +
                    backgroundHex[1] +
                    backgroundHex[1] +
                    backgroundHex[2] +
                    backgroundHex[2];
            }

            var r = parseInt(backgroundHex.slice(0, 2), 16),
                g = parseInt(backgroundHex.slice(2, 4), 16),
                b = parseInt(backgroundHex.slice(4, 6), 16);

            if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
                textColor = '#000000';
            }

            return textColor;
        }

        modulo(number, base) {
            // fix JS modulo bug
            // https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
            return ((number%base)+base)%base;
        }

        convertSecondsToHoursMinutes(seconds) {
            const duration = this.moment.duration(parseInt(seconds), 'seconds');
            const h = Math.floor(duration.asHours());
            const m = duration.minutes();
            const hDisplay = `${h}h`;
            const mDisplay = m > 0 ? ` ${m}m` : '';

            return `${hDisplay}${mDisplay}`;
        }

        brightenColor(color, amount) {
            return window.tinycolor(color).brighten(amount).toString();
        }

        isRichText(text) {
            const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
            return htmlTagRegex.test(text);
        }
        
        stripHTMLTags(stringWithHTML){
            const span = document.createElement('span');
            span.innerHTML = stringWithHTML;
            return span.textContent || span.innerText;
        }

        getFileIcon(name, type, url) {
            const DEFAULT_ICON_URL =
                '//res.cloudinary.com/honeybook/image/upload/v1502008386/system_web/rebranding/file-default.svg';
            const EXCEL_ICON_URL =
                '//res.cloudinary.com/honeybook/image/upload/v1501764251/system_web/rebranding/file_icon_xls.svg';
            const DOC_ICON_URL =
                '//res.cloudinary.com/honeybook/image/upload/v1501764251/system_web/rebranding/file_icon_doc.svg';
            const PDF_ICON_URL =
                "//res.cloudinary.com/honeybook/image/upload/v1501764252/system_web/rebranding/file_icon_pdf.svg";
            const PDF_PREVIEW_URL_PREFIX =
                '//res.cloudinary.com/honeybook/image/fetch/q_auto,dpr_3,w_80,h_80,c_thumb,f_auto,fl_lossy,c_fill,g_north/';

            let iconUrl = DEFAULT_ICON_URL;
            const iconType = type || '';

            if (!name) {
                return iconUrl;
            }

            if (name.match(/xlsx?$/) || iconType.match(/xlsx?$/)) {
                iconUrl = EXCEL_ICON_URL;
            } else if (name.match(/docx?$/) || iconType.match(/docx?$/)) {
                iconUrl = DOC_ICON_URL;
            } else if (name.match(/pdf$/) || iconType.match(/pdf$/)) {
                if (url) {
                    iconUrl = `${PDF_PREVIEW_URL_PREFIX}/${url}`;
                } else {
                    iconUrl = PDF_ICON_URL;
                }
            }

            return iconUrl;
        }

        isUrl(str) {
            return str ? !!str.match(/^https?:\//) : false;
        }

        getCloudinaryPathFromCloudinaryUrl(url) {
            const CLOUDINARY_URL_REGEX =
            /^(https:\/\/res.cloudinary.com\/honeybook\/image\/upload\/v[0-9]+\/)(.*)$/;
            // this regex should also match cloudinary urls with transformations
            const CLOUDINARY_URL_WITH_TRANSFORMATIONS_REGEX =
                /^(https:\/\/res.cloudinary.com\/honeybook\/image\/upload\/w_[0-9]+,h_[0-9]+\/v[0-9]+\/)(.*)$/;

            const [, , cloudinaryPath] = url.split(CLOUDINARY_URL_REGEX) || [];
        
            const [, , cloudinaryWithTransformPath] =
                url.split(CLOUDINARY_URL_WITH_TRANSFORMATIONS_REGEX) || [];
            return cloudinaryPath || cloudinaryWithTransformPath;
        };

        addDefaultFlagsToCloudinaryUrl(
            url,
            cloudinaryPublicId,
            transformations = [
                { fetchFormat: 'auto', flags: 'lossy', quality: 'auto' }
            ],
        ) {
            if (!url && !cloudinaryPublicId) {
                return null;
            }

            const cloudinaryPath = this.getCloudinaryPathFromCloudinaryUrl(url) || cloudinaryPublicId;
            const cloudinaryCore = new cloudinary.Cloudinary({
                cloud_name: 'honeybook'
            });
        
            return cloudinaryCore.url(cloudinaryPath, {
                transformation: transformations,
                secure: true
            });
        };

        isCloudinaryImage(image) {
            return image && image.url && image.url.indexOf('res.cloudinary.com/honeybook') > -1;
        };
    };
}());
