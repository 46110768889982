// @ngInject


Models.BaseCollection = function BaseCollection($q, ModelFactory, ModelUtils, Hash, RepositoryService, PubSubService) {

    return Class({

            // a way to know that this is a collection object when we are mixin in
            _hbModelCollection: true,

            constructor: function constructor() {

                this._collection = Object.create(Array.prototype);
                this._collection = (Array.apply(this._collection, arguments) || this._collection);

                // Add all the class methods to the collection.
                this.injectClassMethods(this._collection);

                this._collection.__className = 'BaseCollection';
                this._collection.__childsType = 'BaseModel';

                this._collection.__modelIds = {};

                this._collection.shouldMergeOnLoad = true;

                this.mixinIgnoreKeys = {
                    lastError: true,
                    _collection: true,
                    mixinIgnoreKeys: true
                };

                PubSubService.ventMyBitchUp(this);
            },

            className: function className() {
                return this.__className;
            },

            childsType: function childsType() {
                return this.__childsType;
            },

            hash: function hash() {

                if (!this.__hash) {
                    this.__hash = Hash();
                }

                return this.__hash;
            },

            dataOnly: function dataOnly() {

                var copy = {};
                //we only need a shallow copy
                //angular.extend(copy, this);


                for (var key in this) {

                    if(key.lastIndexOf('__', 0) === 0 || this.mixinIgnoreKeys[key] || typeof this[key] === 'function'){
                        continue;
                    }
                    if (this[key] && typeof this[key]['dataOnly'] === 'function') {
                        copy[key] = this[key].dataOnly();
                    }else{
                        copy[key] = this[key];
                    }
                }

                this._dataOnlyInterceptor(copy);
                return copy;
            },

            _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {

            },

            injectClassMethods: function injectClassMethods(collection) {
                // Loop over all the prototype methods and add them
                // to the new collection.
                var curr_prototype = Object.getPrototypeOf(this);
                for (var method in curr_prototype) {

                    // Make sure this is a local method.
                    if (curr_prototype.hasOwnProperty(method)) {

                        // Add the method to the collection.
                        collection[method] = curr_prototype[method];

                    }

                }
                // Return the updated collection.
                return ( collection );
            },

            push: function push(item) {

                if (this.__modelIds[item._id]) {
                    return;
                }
                this.__modelIds[item._id] = true;
                return Array.prototype.push.apply(this, arguments);
            },

            unshift: function unshift(item) {

                if (this.__modelIds[item._id]) {
                    return;
                }
                this.__modelIds[item._id] = true;
                return Array.prototype.unshift.apply(this, arguments);
            },

            splice: function splice(index, howMany) {

                var maxIndex = Math.max((index + howMany) - 1, this.length - 1);
                for (var i = index; i <= maxIndex; i++) {
                    delete this.__modelIds[this[i]._id];
                }
                return Array.prototype.splice.apply(this, arguments);
            },


            /**
             * Mixing in from a different array/collection
             *
             * @param {Array / collection} the source array / collection to mixin from
             * @param {Model} the parent model to set for the model entries within this collection
             * @param {Boolean} whether to save the collection models to cache or not. each model also
             * defines if it is cachable or not - so even if this argument is true it may be overridden
             * @param {Booalean} whether thi mixin is called as a result of a server response or not - if yes
             * it will update all child models 'lastServerUpdate' property recursively
             * @param {Boolean} whether to keep existing models that are not present in the source array
             * @param {isComplete} false if this call is part of some pagination that is still ongoing
             *
             */
            mixinFrom: function mixinFrom(objects, parent, saveToCache, isUpdateFromServer, keepExistingRecords, isComplete) {
                if (typeof saveToCache !== 'boolean') {
                    saveToCache = true;
                }

                var _this = this;
                if (_this.childsType() !== 'BaseModel') {

                    var objectsArr = [];
                    var clonedObjects = angular.copy(objects);

                    if (Array.isArray(clonedObjects)) {
                        objectsArr = clonedObjects;
                    } else {
                        for (var key in clonedObjects) {
                            var currObj = clonedObjects[key];
                            if (currObj && (currObj.__className === _this.__childsTypes)) {
                                objectsArr.push(currObj);
                            }
                        }
                    }

                    // when this is a master collection we want to always keep it up to date
                    // so while paging we save all the ids that we received from the server from
                    // when we started paging - then - once we have no more pages, we remove entries that
                    // are no longer needed (old ones)
                    // note - we have the removeExisting option in ModelUtils.mergeArrayItems but
                    // this is a stateless class so it will not take paging into account but will treat each
                    // execution as new
                    if (_this.isMaster) {

                        if (!_this.currRequestReceivedItemsIds) {
                            _this.currRequestReceivedItemsIds = {};
                        }

                        objects.forEach(function (element) {
                            _this.currRequestReceivedItemsIds[element._id] = true;
                        });

                        ModelUtils.mergeArrayItems(_this, objectsArr, _this.childsType(), parent, false, isUpdateFromServer);

                        if (isComplete) {

                            //we now remove deleted entries!
                            for (var i = this.length - 1; i >= 0; i--) {
                                var obj = _this[i];
                                if (!_this.currRequestReceivedItemsIds[obj._id]) {
                                    RepositoryService.cacheDelete(obj);
                                }
                            }

                            _this.currRequestReceivedItemsIds = {};
                        }

                    } else if (keepExistingRecords || !this.shouldMergeOnLoad) {

                        if (!keepExistingRecords) {
                            //clear array first
                            _this.splice(0, _this.length);
                        }


                        // If we're actually mixing into something,
                        objectsArr.forEach(function (object, i) {
                            var curr_collection_model  = RepositoryService.cacheGet(_this.childsType(), object._id);
                            if (object) {
                                
                                if(!curr_collection_model){
                                    curr_collection_model = ModelFactory.newModel(_this.childsType());
                                }

                                var cachedModel = curr_collection_model.mixinFrom(object, undefined, saveToCache, isUpdateFromServer);
                                curr_collection_model.__parent = parent;
                                _this.push(cachedModel);
                            }
                        });
                    } else if (this.shouldMergeOnLoad) {
                        ModelUtils.mergeArrayItems(_this, objectsArr, _this.childsType(), parent, true, isUpdateFromServer);
                    }
                }
            },

            add: function add(model) {
                var sizeBefore = this.length;
                this.push(model);
                if (this.length !== sizeBefore) {

                    this.trigger('itemAdded', model);
                }
            },

            remove: function remove(model) {
                var index;
                for (var i = 0; i < this.length; i++) {
                    if (model._id === this[i]._id) {
                        index = i;
                        break;
                    }
                }

                if (typeof index !== 'undefined') {
                    var item = this[index];
                    this.splice(index, 1);
                    this.trigger('itemRemoved', item);
                }
            },

            onCollectionError: function onCollectionError(message, status) {
                if (message && message.error_message) {
                    message = message.error_message;
                }
                this.lastError = message;

                this.trigger('error', message, status);
            },

            onCollectionSuccess: function onCollectionSuccess(message, status, isComplete, totals) {
                this.trigger('success', message, status, isComplete, totals);
            },

            onPagingEnded: function onPagingEnded(message, status) {
                this.trigger('pagingEnded', message, status);
            },

            onCollectionUpdating: function onCollectionUpdating() {
                this.trigger('update');
            }

        }
    );

};
