
(function () {
  'use strict';

  class CalendarListComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, DeviceService) {
      super($scope, $injector);
      this.__objectType = 'CalendarListComponent';

      this.isMobile = DeviceService.nxSmallBreakpoint();
    }
  }

  Components.CalendarList = {
    bindings: {
      list: '=',
      toggleSelectAll: '&',
      toggleOpenList: '&',
      handleChangeCog: '&',
      openedList: '<',
      changeCalendarColor: '&',
      handleChangeListItem: '&'
    },
    controller: CalendarListComponent,
    name : 'hbCalendarList',
    templateUrl: 'angular/app/modules/core/features/calendar/calendar_list/calendar_list.html',
  };

}());


