
(function () {
    'use strict';

    class CalendarPopoverComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $element, $document, AnalyticsService, $timeout, moment, Enums, CalendarService, MathService) {
            super($scope, $injector);
            this.__objectType = 'CalendarPopoverComponent';

            this.AnalyticsService = AnalyticsService;
            this.$document = $document;
            this.moment = moment;
            this.$scope = $scope;
            this.Enums = Enums;
            this.$timeout = $timeout;
            this.CalendarService = CalendarService;
            this.MathService = MathService;

            this.$rootElement = $element.find('.hb-calendar-popover');
            this.isPopoverOpen = false;

            // listen to events from the calendar to show the popover
            $scope.$on(this.eventListener, (event, popover) => {
                $timeout(() => {
                    this.onShowPopover(popover);
                });
            });

            this.documentClickHandler = () => {
              this.resetPopover();
            };

            $scope.$on('$destroy', () => this.removePopoverListeners(true));
        }

        onShowPopover(data) {

            // validate data
            if (!data) {
                return;
            }

            // update data for content
            const {date, jsEvent, view, popoverType, dayElement} = data;
            this.data = date;
            this.activeView = view;
            this.popoverType = popoverType;
            this.handleAnalytics();
            this.initPopoverListeners();

            // position the popover
            switch(this.activeView) {
                case 'month':
                    switch (popoverType) {
                        case this.Enums.calendarPopoverTypes.event_limit :
                            this.popoverPosition(date.dayEl);
                            break;
                        default:
                            this.popoverPosition(dayElement);
                            break;
                    }
                    break;

                // agendaWeek, agendaDay
                default:
                    this.popoverPosition(jsEvent.target);
            }
        }

        initPopoverListeners() {
            if (this.popoverEventListening) {
                return;
            }

            this.$document.on('click.calendar-create-item-popup', this.documentClickHandler);
            this.popoverEventListening = true;
            this.scrollDetector();
        }

        removePopoverListeners(force) {
            if (this.popoverEventListening || force) {
                this.popoverEventListening = false;
                this.$document.off('click.calendar-create-item-popup', this.documentClickHandler);
            }

            this.$document.off('scroll.hb-calendar-popover__list-item-wrapper', this.scrollDetector);
        }

        scrollDetector(){
            this.$timeout(() => {
                const popoverContentHeight = 331;
                const main = angular.element('.hb-calendar-popover-content');
                const elem = angular.element('.hb-calendar-popover__list-item-wrapper');

                if (elem[0] && main) {
                    elem[0].scrollHeight > popoverContentHeight ?
                      main.addClass('hb-popover--overflow') :
                      main.removeClass('hb-popover--overflow');

                    elem.bind('scroll', () => elem.scrollTop() > 1 ?
                      main.addClass('hb-popover--scrolled') :
                      main.removeClass('hb-popover--scrolled'));
                }

            }, 100);
        }

        closePopover() {
            this.isPopoverOpen = false;
            this.closeSecondaryPopover();
            this.$rootElement.css({
                'top'  : '',
                'left' : '',
                'width': '',
                'visibility': 'hidden'
            });
        }

        closeSecondaryPopover() {
            this.showSecondaryPopup = false;
        }

        onButtonXClose($event) {
            this.resetPopover();
        }

        onSecondaryButtonXClose($event) {
            $event.stopPropagation();
            this.closeSecondaryPopover();
        }

        resetPopover() {
            this.closePopover();
            this.removePopoverListeners();
        }

        handleAnalytics(){
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.open_popover_on_calendar, {
                calendarViewType: this.activeView,
                popoverType: this.popoverType.replace(/_/g, ' ').toLowerCase()
              });
        }

        onAction(payload) {
            this.handleCallback({payload: payload});
            this.resetPopover();
        }

        handleShowSecondaryPopup($event, dataItem) {
            $event.stopPropagation();

            // mark to show
            this.showSecondaryPopup = true;

            // init data
            this.secondaryPopoverType = this.CalendarService.getPopoverType(dataItem);
            this.secondaryPopoverData = dataItem;

            // position it

            /** screens < 1400 always right **/
            if (this.calCont.width < 1400 && this.calElem.left < (this.calCont.width / 1.5)) {
                this.secondaryPopoverPosition = 'right';
            } else if (this.calElem.left > (this.calCont.width / 2)) {
                this.secondaryPopoverPosition = 'left';
            } else {
                this.secondaryPopoverPosition = 'right';
            }

            //this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.open_calendar_secondary_popover, popoverParams);
        }

        _popoverWidth() {
            return this.popoverType === this.Enums.calendarPopoverTypes.day_click ? 189 : 280;
        }

        _popoverLeftPos() {
            const borderLineWidth = 2;
            const isSunday = this.calElem.left === this.calCont.left || this.calElem.left < 270;
            const centerAlignedPopover = this.calElem.left + ((this.calElem.width - this.popoverWidth) / 2);
            const leftAlignedPopover = this.calElem.left - this.popoverWidth;

            /** popover:show_more **/
            if (this.popoverType === this.Enums.calendarPopoverTypes.event_limit) {

              /** Sunday **/
              if (isSunday) {
                return leftAlignedPopover + this.elemDelta;
              }

              /** default **/
              return leftAlignedPopover;
            }

            /** popover: day **/
            if (this.popoverType === this.Enums.calendarPopoverTypes.day_click) {

              /** right **/
              if (this.calElem.left >= this.calCont.width) {

                /** centered for lg screens **/
                if (this.calCont.width > 1325) {
                  return centerAlignedPopover;
                }

                /** half width of popover **/
                return this.calElem.left - (this.popoverWidth / 2);
              }

              /** center **/
              return centerAlignedPopover;
            }

            /** popover: Sunday **/
            if (isSunday) {
              return leftAlignedPopover + (this.elemDelta * 2);
            }

            /** popover: default  **/
            return leftAlignedPopover - borderLineWidth;
        }

        _popoverVerticalPos() {
            const showMoreDeltaTop = 100;
            const mainPopoverHeight = angular.element('.main-calendar-popover')[0].offsetHeight;

            /** popover: show_more **/
            if (this.popoverType === this.Enums.calendarPopoverTypes.event_limit) {

              if (this.activeView === "agendaWeek" ) {
                return this.calElem.top;
              }

              /** show_more: bottom days **/
              if (this.calElem.top >= (this.calCont.height / 1.5) || (this.calElem.bottom + 1 === this.calCont.bottom)) {
                return (this.calElem.top + showMoreDeltaTop) - mainPopoverHeight;
              }

              /** show_more: default **/
              return (this.calElem.top + showMoreDeltaTop) - this.elemDelta;
            }

            /** all: bottom days except for day popover  **/
            if ((this.calElem.top >= (this.calCont.height / 2)) && this.popoverType !== this.Enums.calendarPopoverTypes.day_click) {
              return (this.calElem.top - mainPopoverHeight) + this.elemDelta;
            }

            /** if bottom day popover exceeds window, then open above */
            if (
                this.calElem.top + mainPopoverHeight > window.innerHeight &&
                this.popoverType === this.Enums.calendarPopoverTypes.day_click
            ) {
              return this.calElem.top - mainPopoverHeight;
            }

            /** popover: all other days **/
            return this.calElem.top;
        }

        _popoverVerticalPosInBoundary() {
            const calendarElement = angular.element('.hb-calendar__main')[0];
            const popoverElement = angular.element('.main-calendar-popover')[0];
            
            const top = this._popoverVerticalPos();
            const minTop = parseInt(getComputedStyle(calendarElement).paddingTop)
            const maxTop = window.innerHeight - popoverElement.offsetHeight;

            return this.MathService.clamp(top, minTop, maxTop)
        }

        popoverPosition(elem) {
            this.refElem = elem;
            this.calElem = angular.element(elem)[0].getBoundingClientRect();
            this.calCont = angular.element('.fc-view-container')[0].getBoundingClientRect();
            this.popoverWidth = this._popoverWidth();
            this.elemDelta = 25;

            this.$timeout(() => {
              this.$rootElement.css({
                  'left' : this._popoverLeftPos(),
                  'width': this.popoverWidth,
                  'visibility': 'visible',
              });
            });
            
            // this allows the element to calculate height correctly if it has long description
            this.$timeout(() => {
              this.$rootElement.css({
                  'top'  : this._popoverVerticalPosInBoundary()
              });
            });

            this.isPopoverOpen = true;
        }
    }

    Components.CalendarPopover = {
        bindings: {
            handleCallback: '&',
            reloadItems: '&',
            eventListener: '@',
            user: '<'
        },
        controller: CalendarPopoverComponent,
        name : 'hbCalendarPopover',
        templateUrl: 'angular/app/modules/core/features/calendar/calendar_popover/calendar_popover.html',
    };

}());

// /** POPOVER: Summary: On list item click: callback to trigger secondary popover */
// handleShowMore($event, dataItem){
//     $event.stopPropagation();
//
//     // const payload = {
//     //     type: "TRIGGER_SECONDARY_POPOVER",
//     //     data: dataItem,
//     //     refElem: this.refElem,
//     //     view: this.activeView
//     // };
//     //
//     // this.handleCallback({payload: payload});
// }
