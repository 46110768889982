/**
 * Created by Erez on 05/10/2016.
 */

Directives.ReferFriendDirective = function ReferFriendDirective() {

    // @ngInject
    function ReferFriendDirectiveControllerFunc($scope, $injector, ReferralService, AnalyticsService, UsersManager, AbTestService, Enums, $translate) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReferFriendDirectiveController';
        this.AbTestService = AbTestService;
        this.AnalyticsService = AnalyticsService;
        this.ReferralService = ReferralService;
        this.currUser = UsersManager.getCurrUser();
        this.Enums = Enums;
        this.$translate = $translate;

        this.showReferrAFriend = false;

        this.ReferralService.getCampaignDetails(this.currUser).then(function success(campaignDetails) {
            if (campaignDetails && campaignDetails.campaign_uid) {
                this.showReferrAFriend = true;
            }
        }.bind(this));
    }

    var ReferFriendDirectiveController = Class(Controllers.BaseController, {
        constructor: ReferFriendDirectiveControllerFunc,

        referAFriendClick: function referAFriendClick() {

            this.ReferralService.openReferAFriend();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.refer_a_friend_button_click,{
                campaign_uid :this.ReferralService.userCampaignUid
            });
        }
    });


    return {
        scope: {
            imageType: '@imageType'
        },
        templateUrl: 'angular/app/modules/common/ui_components/refferal/refer_friend_directive_template.html',
        controller: ReferFriendDirectiveController,
        controllerAs: 'referFriendVm',
        bindToController: true
    };
};

