(function () {
    "use strict";

    // @ngInject
    function BlockProposalControllerFunc($injector, $scope, Enums) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockProposalDirectiveController';

        this.Enums = Enums;
    }

    var BlockProposalDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockProposalControllerFunc,

        shouldShowSettings: function shouldShowSettings() {
            return this.isEditable;
        },

        toggleSettings: function toggleSettings() {
            this.showSettings = !this.showSettings;
        },

        hideSettings: function hideSettings() {
            this.showSettings = false;
        },

        shouldShowClientHelper: function shouldShowClientHelper() {
            return !this.isEditable && this.isSelectable && this.block.proposal.isSelectable();
        },

        getClientHelperText: function getClientHelperText() {
            if(this.block.proposal.selection_type === this.Enums.proposalSelectionType.selectOneRequired) {
                return 'BROCHURE.SERVICES._SELECT_ONLY_ONE_FROM_THE_FOLLOWING_';
            } else if(this.block.proposal.selection_type === this.Enums.proposalSelectionType.selectMultipleRequired) {
                return 'BROCHURE.SERVICES._SELECT_AT_LEAST_ONE_FROM_THE_FOLLOWING_';
            }

            return 'BROCHURE.SERVICES._SELECT_ANY_OF_THE_FOLLOWING_';
        },

        isSelectionValid: function isSelectionValid() {
            return this.block.proposal.isSelectionValid();
        }

    });

    Components.BlockProposal = {
        bindings: {
            block: '<',
            companyModel: '<',
            fileModel: '<',
            isEditable: '<',
            isPreviewMode: '<',
            isOwnerMode: '<',
            isSelectable: '<',
        },
        controller: BlockProposalDirectiveController,
        controllerAs: 'blockProposalVm',
        name: 'hbBlockProposal',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_proposal/block_proposal.html',
    };

}());