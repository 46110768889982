(function () {
    "use strict";

    // @ngInject
    function AchPushToastControllerCtor($scope, $injector, $stateParams, $timeout, $document, moment, toastInstance, WorkspaceFilesManager, UiPersistenceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$document = $document;
        this.toastInstance = toastInstance;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.workspaceFileId = $stateParams.workspace_file_id;
        this.UiPersistenceService = UiPersistenceService;
        this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
        var nonSaved = this.workspaceFile.getTotalProposalValue() * 0.015;
        this.ach_saving = Math.round(nonSaved*100)/100;
    }

    Controllers.AchPushToastController = Class(Controllers.BaseController, {
        constructor: AchPushToastControllerCtor,

        openDrawer: function openDrawer() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showAchPushToast, {count: 5});
            this.$timeout(function timeout() {
                this.$document.find('body').find(".aside-drawer__burger-container").click();
            }.bind(this), 300);
        },

    });

}());
