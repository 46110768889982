(function () {
    "use strict";



    Controllers.SignupController = class SignupController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope, $injector, $location, $timeout, $translate, $window, _,
            AppStates, AnalyticsService, RegexService, CompanyService, Fingerprint,
            RedirectService, UsersManager, Enums, DeviceService, $, $q, $state, Gon,
            OnboardingService, PhotosUploadManager, CompaniesManager,
            CouponsService, AppConfigService, GoogleTagManagerService, RecaptchaService, UIUtils, ForterHelperService, DatadogRUMService
        ) {

            super($scope, $injector);
            this.__objectType = 'SignupController';

            this.$window = $window;
            this.DatadogRUMService = DatadogRUMService;
            this.$timeout = $timeout;
            this.$location = $location;
            this.AppStates = AppStates;
            this.Enums = Enums;
            this.DeviceService = DeviceService;
            this.RedirectService = RedirectService;
            this.$q = $q;
            this.$ = $;
            this.RegexService = RegexService;
            this.Fingerprint = Fingerprint;
            this._ = _;
            this.AnalyticsService = AnalyticsService;
            this.UsersManager = UsersManager;
            this.CompanyService = CompanyService;
            this.$translate = $translate;
            this.$state = $state;
            this.OnboardingService = OnboardingService;
            this.PhotosUploadManager = PhotosUploadManager;
            this.CompaniesManager = CompaniesManager;
            this.CouponsService = CouponsService;
            this.AppConfigService = AppConfigService;
            this.GoogleTagManagerService = GoogleTagManagerService;
            this.UIUtils = UIUtils;
            this.Gon = Gon;
            this.ForterHelperService = ForterHelperService;

            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.userData = null;
            this.firstName = '';
            this.signingCaption = '';
            this.redirectObject = null;
            this.requiredErrorMessage = $translate.instant('ERRORS._FIELD_IS_REQUIRED_');
            this.invalidPhoneNumberErrorMessage = "Phone number must be at least 10 digits";
            this.invalidEmailErrorMessage = "The email you entered doesn’t seem valid";
            this.generalServerErrorMessage = "Oops... Server error. Please try again.";

            this.isSigningUserUp = false;
            this.isShowPressEnter = !this.isMobile;

            this.signupData = this._getDataFromMarketingSite();
            
            this.intakeFormAnalyticsObject = {
                signup_landing_page: '', 
                start_trial_variant: '', 
                intake_variant: '',
                sso: ''
            };
            this.signupLandingPage = this.signupData.signupLandingPage || this.signupData.signup_landing_page;
            this.returnLocation = this.signupData.returnLocation;

            RecaptchaService.load(); // pre-loading the RecaptchaService so it will be ready for the actual call to the server

            this.GoogleTagManagerService.pushEvent({'event': 'optimize.activate'});

            // ************ Optimize Experiments start
            class OptimizeExperiment {
                constructor(options) {
                    this.experiment_id = options.experiment_id;
                    this.experiment_name = options.experiment_name;
                    this.variation_id = options.variation_id;
                    this.variation_name = options.variation_name;
                    this.extra_data = options.extra_data;
                }
            }


            this.optimizeTests = [];
            this.unbounceTest = new OptimizeExperiment({
                extra_data: []
            });
            this._getOptimizeTestsData();
            // ************ Optimize Experiments end

            // ************ Stage inputs start
            class Stage {
                constructor(options) {
                    this.key = options.key;
                    this.title = options.title;
                    this.subtitle = options.subtitle;
                    this.inputs = options.inputs;
                    this.completed = options.completed;
                    this.subtext = options.subtext;
                    this.valid = false;
                    this.loading = false;
                    this.analyticsEvents = options.analyticsEvents;
                    this.updateTitleFunction = options.updateTitleFunction;

                    if (options.validateStage) {
                        this.validateStage = options.validateStage;
                    }

                }

                validateStage() {
                    let defer = $q.defer();
                    defer.resolve();
                    return defer.promise;
                }

                visibleInputs() {
                    return this.inputs.filter( input => input.isVisible )
                }

                getCtaTabIndex() {
                    let tabIndex = 1;
                    this.visibleInputs().forEach(input => {
                       if (input.options && input.options.length) {
                           tabIndex += input.options.length;
                       } else {
                           tabIndex++;
                       }
                    });

                    return tabIndex;
                }

                updateTitle(newTitle) {
                    if (_.isFunction(this.updateTitleFunction)) {
                        this.title = this.updateTitleFunction(newTitle);
                    } else {
                        this.title = newTitle;
                    }

                }

                _isAllInputsValid() {
                    let defer = $q.defer();
                    let promises = [];

                    this.visibleInputs().forEach( input => {
                        promises.push(input.isValid());
                    });

                    $q.all(promises).then(() => {
                        defer.resolve();
                    }).catch(() => {
                        defer.reject();
                    });

                    return defer.promise;
                }

                isValid() {
                    return this._isAllInputsValid()
                        .then(() => {
                            this.valid = true;
                            return true;
                        })
                        .catch( () => {
                            this.valid = false;
                            return false;
                        });
                }
            }

            class Input {
                constructor(options) {
                    this.label = options.label;
                    this.placeholder = options.placeholder;
                    this.textFormat = options.textFormat;
                    this.serverKey = options.serverKey;
                    this.answer = options.answer || '';
                    this.validationFunction = options.validationFunction;
                    this.isMandatory = options.isMandatory;
                    this.isVisible = (options.isVisible !== undefined) ? options.isVisible : true;
                    this.tooltip = options.tooltip;
                    this.expression = options.expression;
                    this.valid = true;
                    this.labelOnFocus = options.labelOnFocus;
                    this.analyticsEvents = options.analyticsEvents;
                    this.maxlength = options.maxlength;
                    this.shouldNotContinueOnEnter = false;
                    this.filterBy = options.filterBy;
                    this.name = options.name;

                    this.textToShowOnTooltip = options.tooltip;
                    this.expressionToShowOnTooltip = options.expression;
                }

                isValid() {
                    if ((this.isMandatory || this.answer) && (this.validationFunction && angular.isFunction(this.validationFunction))) {
                        return this.validationFunction(this.answer);
                    } else {
                        let defer = $q.defer();
                        defer.resolve();
                        return defer.promise;
                    }
                }

                getValue() {
                    return this.answer;
                }

                setInputError(message) {
                    this.valid = false;
                    if (DeviceService.nxSmallBreakpoint()) {
                        this.textToShowOnTooltip = message;
                    } else {
                        $timeout(() => {
                            this.textToShowOnTooltip = message;
                            this.expressionToShowOnTooltip = 'error';
                        }, 200);
                    }
                }
            }

            class TextInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "text";
                }
            }

            class EmailInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "email";
                }
            }

            class PhoneInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "tel";
                }

                getValue() {
                    return this.answer.replace(/\D/g, '');
                }
            }

            class PasswordInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "password";
                    this.hideText = true;
                    this.toggle = () => {
                        this.hideText = !this.hideText;
                    }
                }
            }

            class WebsiteInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "text";
                }
            }

            class CheckboxInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "checkbox";
                }
            }

            class TypeaheadInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "typeahead";
                    this.collection = options.collection;
                    this.prefillValue = options.prefillValue;
                    this.answer = this.prefillValue;
                    this.onChange = (answer) => {
                        this.answer = answer.text;
                        this.answerKey = answer.key;

                        if (options.onChange && angular.isFunction(options.onChange)) {
                            let key = answer.key || answer.text;
                            options.onChange(key);
                        }
                    };

                    this.noResults = false;
                    this.onNoResults = () => {
                        this.tooltip = options.noResultsText;
                    }
                    this.onHasResults = () => {
                        this.tooltip = options.tooltip;
                    }
                }

                getValue() {
                    return this.answerKey || this.answer;
                }
            }

            class ChipsInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "chips";
                    this.options = options.options;
                }
            }

            class UploadLogoInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "uploadlogo";
                    this.options = options.options;
                }
            }

            class DefaultCoverPhotoInput extends Input {
                constructor(options) {
                    super(options);
                    this.type = "defaultCoverPhoto";
                    this.options = options.options;
                }
            }

            const _isEmailDomainValid = (email) => {
                let defer = $q.defer();
                if (!email) {
                    defer.resolve();
                } else {
                    let userData = this._getUserData();
                    let query = Object.assign({}, userData, { vendor_email: email, vendor_name: userData.name });
                    this.UsersManager.validateEmail(query)
                        .then( serverResponse => {
                            var isValid = serverResponse.data.is_valid;
                            var domainNotValid = serverResponse.data.domain_not_valid;
                            var redirectToUrl = serverResponse.data.redirect_to_url;

                            if (domainNotValid) {
                                AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_email_domain_not_valid);
                                defer.reject({message: this.invalidEmailErrorMessage, input:inputEmail});
                            } else if (!isValid) {
                                // we need to populate a redirect object. for instance: login for exisiting users, confirm_email for unclaimed users...
                                this.redirectObject = {
                                    url: redirectToUrl
                                };
                                defer.resolve();
                            } else {
                                this.redirectObject = null;
                                defer.resolve();
                            }
                        })
                        .catch( message => {
                            defer.reject({message: this.generalServerErrorMessage});
                        });
                }

                return defer.promise;
            };

            const _isEmailValid = (email) => {
                return this.RegexService.isEmailValid(email);
            };

            const _isPhoneValid = (phone) => {
                return this.RegexService.isPhoneLongEnoughValid(phone);
            };

            const nameValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    defer.resolve();
                } else {
                    defer.reject(this.requiredErrorMessage);
                }
                return defer.promise;
            };

            const companyNameValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    defer.resolve();
                } else {
                    defer.reject(this.requiredErrorMessage);
                }
                return defer.promise;
            };

            const filterCompanyTypeFn = (value) => {
                let typedText = this.form.name.company_type.$viewValue;

                if (!typedText) {
                    return true;
                }

                const currentItemValue = value.text.toLowerCase();
                const splittedTypedText = typedText.toLowerCase().split(' ');

                return splittedTypedText.some(splittedTypedTextItem => currentItemValue.includes(splittedTypedTextItem));
            }

            const companyTypeValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    defer.resolve();
                } else {
                    defer.reject(this.requiredErrorMessage);
                }
                return defer.promise;
            };

            const companyTypeOtherValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    defer.resolve();
                } else {
                    defer.reject(this.requiredErrorMessage);
                }
                return defer.promise;
            };

            const emailValidationFn = answer => {
                let defer = $q.defer();
                if (_isEmailValid(answer)) {
                    defer.resolve();
                } else {
                    defer.reject(this.invalidEmailErrorMessage);
                }
                return defer.promise;
            };

            const phoneValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    if (_isPhoneValid(answer)) {
                        defer.resolve();
                    }
                    else {
                        defer.reject(this.invalidPhoneNumberErrorMessage);
                    }
                } else {
                    if (!inputPhone.isMandatory) {
                        defer.resolve();
                    } else {
                        defer.reject(this.requiredErrorMessage);
                    }

                }

                return defer.promise;
            };

            const passwordValidationFn = answer =>  {
                let defer = $q.defer();
                if (!!answer) {
                    if (answer.length >= 8 && answer.length <= 72) {
                        defer.resolve();
                    } else {
                        defer.reject($translate.instant('SIGNUP.INPUT_PASSWORD._ERROR_'));
                    }
                } else {
                    defer.reject(this.requiredErrorMessage);
                }
                return defer.promise;
            };

            const companyWebsiteValidationFn = answer => {
                let defer = $q.defer();
                if (!answer || this.RegexService.isWebsiteUrlValid(answer)) {
                    defer.resolve();
                } else {
                    defer.reject($translate.instant('SIGNUP.INPUT_WEBSITE._ERROR_'));
                }
                return defer.promise;
            };

            const tosValidationFn = answer => {
                let defer = $q.defer();
                if (!!answer) {
                    defer.resolve();
                } else {
                    defer.reject();
                }
                return defer.promise;
            };

            // ************ Stage inputs end
            const extraTypes = [
                {key: 'event_planning',      text: 'COMPANY_SETTING.LABELS._TYPE_EVENT_PLANNING_',       parentType: "planning"}
            ];

            const typesWithoutOther = CompanyService.companyTypeEnum.slice(0, CompanyService.companyTypeEnum.length -1);
            const allTypes = [...typesWithoutOther, ...extraTypes];


            this.companyTypes = allTypes.map(companyType => {
                return {text: $translate.instant(companyType.text), key: companyType.key, parentType: companyType.parentType};
            }).sort((a,b) => {
                if(a.text < b.text) { return -1; }
                if(a.text > b.text) { return 1; }
                return 0;
            });

            const otherItem = this._.last(CompanyService.companyTypeEnum);
            this.companyTypes.push({text: $translate.instant(otherItem.text), key: otherItem.key, isExtra: false});

            const getPrefillCompanyType = () => {
                const companyTypes = this.companyTypes,
                    signupLandingPage = this.signupData.signup_landing_page,
                    prefillLandingPages = [
                    // Some landing pages allow us to assume the company type
                    { path: 'photography-contract-template',
                      typeKey: CompanyService.companyTypeKeyEnum.photographer },
                    { path: 'event-planner-contract-template',
                      typeKey: CompanyService.companyTypeKeyEnum.eventPlanner },
                    { path: 'doula-business-software',
                      typeKey: CompanyService.companyTypeKeyEnum.doula },
                    { path: 'marketing-consultant-software',
                      typeKey: CompanyService.companyTypeKeyEnum.marketing },
                    { path: 'venue-management-software',
                      typeKey: CompanyService.companyTypeKeyEnum.venue },
                    { path: 'crm-for-photographers',
                      typeKey: CompanyService.companyTypeKeyEnum.photographer },
                    { path: 'crm-for-landscapers',
                      typeKey: CompanyService.companyTypeKeyEnum.landscaper },
                    { path: 'dj-booking-software',
                      typeKey: CompanyService.companyTypeKeyEnum.djMusician },
                    { path: 'software-for-florists',
                      typeKey: CompanyService.companyTypeKeyEnum.florist },
                    { path: 'software-for-event-planners',
                      typeKey: CompanyService.companyTypeKeyEnum.eventPlanner },
                    { path: 'catering-management-software',
                      typeKey: CompanyService.companyTypeKeyEnum.catering },
                    { path: 'crm-for-consultants',
                      typeKey: CompanyService.companyTypeKeyEnum.businessConsultant },
                    { path: 'health-coach-software',
                      typeKey: CompanyService.companyTypeKeyEnum.wellness },
                    { path: 'crm-for-graphic-designers',
                      typeKey: CompanyService.companyTypeKeyEnum.graphicDesigner },
                    { path: 'free-invoice-templates/doula',
                      typeKey: CompanyService.companyTypeKeyEnum.doula },
                    { path: 'free-invoice-templates/graphic-design',
                      typeKey: CompanyService.companyTypeKeyEnum.graphicDesigner },
                    { path: 'free-invoice-templates/interior-design',
                      typeKey: CompanyService.companyTypeKeyEnum.interiorDesigner },
                    { path: 'free-invoice-templates/coach',
                      typeKey: CompanyService.companyTypeKeyEnum.coaching },
                    { path: 'free-invoice-templates/consultants',
                      typeKey: CompanyService.companyTypeKeyEnum.businessConsultant },
                    { path: 'free-invoice-templates/photographers',
                      typeKey: CompanyService.companyTypeKeyEnum.photographer },
                    { path: 'free-invoice-templates/tutors',
                      typeKey: CompanyService.companyTypeKeyEnum.education },
                    { path: 'free-invoice-templates/cleaners',
                      typeKey: CompanyService.companyTypeKeyEnum.cleaning },
                    { path: 'free-invoice-templates/virtual-assistant',
                      typeKey: CompanyService.companyTypeKeyEnum.virtualAssistant },
                    { path: 'crm-for-freelancers',
                      typeKey: CompanyService.companyTypeKeyEnum.freelancer },
                    { path: 'crm-for-designers',
                      typeKey: CompanyService.companyTypeKeyEnum.graphicDesigner },
                    { path: 'contractor-billing-software',
                      typeKey: CompanyService.companyTypeKeyEnum.contractor },
                    { path: 'tutor-software',
                      typeKey: CompanyService.companyTypeKeyEnum.education },
                    { path: 'virtual-assistant-software',
                      typeKey: CompanyService.companyTypeKeyEnum.virtualAssistant },
                    { path: 'real-estate-crm-software',
                      typeKey: CompanyService.companyTypeKeyEnum.realEstate },
                    { path: 'social-media-crm',
                      typeKey: CompanyService.companyTypeKeyEnum.socialMedia },
                    { path: 'software-for-home-accountants',
                      typeKey: CompanyService.companyTypeKeyEnum.accounting },
                    { path: 'interior-design-business-software',
                      typeKey: CompanyService.companyTypeKeyEnum.interiorDesigner },
                    { path: 'tools-for-digital-marketers',
                      typeKey: CompanyService.companyTypeKeyEnum.marketing },
                    { path: 'software-for-copywriters',
                      typeKey: CompanyService.companyTypeKeyEnum.marketing },
                    { path: 'life-coach-software',
                      typeKey: CompanyService.companyTypeKeyEnum.coaching },
                    { path: 'software-for-cleaning-business',
                      typeKey: CompanyService.companyTypeKeyEnum.cleaning },
                    { path: 'freelance-consulting-contract-template',
                      typeKey: CompanyService.companyTypeKeyEnum.businessConsultant }
                ];

                const landingPageMatch =
                    prefillLandingPages.find(page => page.path === signupLandingPage);

                if (landingPageMatch) {
                    const prefillType =
                        companyTypes.find(type => type.key === landingPageMatch.typeKey);
                    return $translate.instant(prefillType.text);
                }
            };

            var getHowDidYouHearData = () => {
                let howDidYouHearAboutUs = _.shuffle([
                    {name: 'Referral', key: 'referral'},
                    {name: 'Audio Ad', key: 'audioAd' },
                    {name: 'Pinterest', key: 'pinterest'},
                    {name: 'Review Sites', key: 'reviewSites'},
                    {name: 'Rising Tide', key: 'risingTideSociety'},
                    {name: 'TV', key: 'TV'},
                    {name: 'Web Search', key: 'searchEngine'},
                    {name: 'Facebook / Instagram', key: 'facebookInstagram'},
                    {name: 'Online ad', key: 'onlineAd'},
                    {name: 'YouTube', key: 'youTube'},
                    {name: 'Linkedin', key: 'linkedin'},
                    {name: 'Reddit', key: 'reddit'},
                    {name: 'TikTok', key: 'tiktok'}
                ]);
                howDidYouHearAboutUs.push({name: 'Other', key: 'other'});
                return howDidYouHearAboutUs;
            };

            const onChangeCompanyType = key => {
                if (key && key.toLowerCase() === "other") {
                    inputCompanyTypeText.isVisible = true;
                    inputCompanyTypeText.isMandatory = true;
                } else {
                    inputCompanyTypeText.isVisible = false;
                    inputCompanyTypeText.isMandatory = false;
                }
            };

            /*
             *
             * Input definitions
             *
             */

            this.inputName = new TextInput({
                analyticsEvents: {
                    submit: AnalyticsService.analytics_events.intake_form_submit_user_name,
                    load: AnalyticsService.analytics_events.intake_form_loaded_user_name
                },
                label: 'Your full name',
                serverKey: 'name',
                validationFunction: nameValidationFn,
                isMandatory: true,
                isVisible: true,
                tooltip: "This is the name we will use to create your e-signature and present it to your clients 🚀"
            });

            let inputHDYHAU = new ChipsInput({
                analyticsEvents: {
                    submit: AnalyticsService.analytics_events.intake_form_submit_HDYHAU,
                    load: AnalyticsService.analytics_events.intake_form_loaded_HDYHAU
                },
                label: 'How did you hear about HoneyBook?',
                serverKey: 'How_heard_of_us__c',
                isMandatory: false,
                answer: 'noAnswer',
                options: getHowDidYouHearData(),
                tooltip: "We’re curious to learn about the one place that introduced you to our product."
            });

            this.inputCompanyName = new TextInput({
                label: 'Your business name',
                serverKey: 'company_name',
                validationFunction: companyNameValidationFn,
                isMandatory: true,
                tooltip: "A good business name is not easy to come up with. We can’t wait to hear yours 🤩"
            });

            let inputCompanyTypeText = new TextInput({
                label: 'Company type other',
                validationFunction: companyTypeOtherValidationFn,
                serverKey: 'company_other',
                isMandatory: false,
                isVisible: false,
                tooltip: "What type did we miss?",
                maxlength: "100",
            });

            let inputCompanyType = new TypeaheadInput({
                label: 'Company type',
                serverKey: 'company_type',
                validationFunction: companyTypeValidationFn,
                isMandatory: true,
                collection: this.companyTypes,
                onChange: onChangeCompanyType,
                tooltip: "This will help us customize your Processes and Templates ⚡️",
                maxlength: "100",
                filterBy: filterCompanyTypeFn,
                name: 'company_type',
                noResultsText: "Don’t see your industry? Choose the closest match for the best experience, or type your own.",
                prefillValue: getPrefillCompanyType(),
            });

            let inputEmail = new EmailInput({
                analyticsEvents: {
                    submit: AnalyticsService.analytics_events.intake_form_submit_email,
                    load: AnalyticsService.analytics_events.intake_form_loaded_email
                },
                label: 'Your business email',
                serverKey: 'email',
                validationFunction: emailValidationFn,
                isMandatory: true,
                tooltip: "Your Client will receive your communications from this email"
            });

            let inputPhone = new PhoneInput({
                label: 'Phone number',
                serverKey: 'phone_number',
                validationFunction: phoneValidationFn,
                isMandatory: true,
                tooltip: "Clients like to have a direct contact point"
            });

            let inputPassword = new PasswordInput({
                label: 'Password',
                labelOnFocus: 'Password (8 - 72 characters)',
                serverKey: 'password',
                validationFunction: passwordValidationFn,
                isMandatory: true,
                tooltip: "8 - 72 characters",
                expression: "hiding"
            });

            let inputCompanyWebsite = new WebsiteInput({
                label: $translate.instant('SIGNUP.INPUT_WEBSITE._LABEL_'),
                serverKey: 'website_url',
                validationFunction: companyWebsiteValidationFn,
                isMandatory: false
            });

            let inputCheckbox = new CheckboxInput({
                answer: true,
                label: 'I agree to HoneyBook\'s <a target=\'_blank\' href=\'/tos\' rel=\'noopener\'>terms of service</a> and to receive occasional emails, texts, or calls.',
                serverKey: 'sms_opt_in__c',
                validationFunction: tosValidationFn,
                isMandatory: true,
                tooltip: "Don't worry, you can unsubscribe from email, call, and text anytime."
            });

            let inputUploadLogo = new UploadLogoInput({
                answer: true,
                // Note: logo_upload gets to the server via
                // _createNewUserSuccess -> uploadLogoImage(),
                // not on normal createUser POST with other data
                serverKey: 'logo_upload',
                isMandatory: false,
            });

            let inputDefaultCoverPhoto = new DefaultCoverPhotoInput({
                answer: true,
                serverKey: 'default_cover_image_cloudinary_public_id',
                isMandatory: false,
            });

            /*
             *
             * Stage definitions
             *
             */

            let stageIntroduction = new Stage({
              key: "stageIntroduction",
              title: $translate.instant('SIGNUP.STAGE_INTRODUCTION._TITLE_'),
              subtitle: $translate.instant('SIGNUP.STAGE_INTRODUCTION._SUBTITLE_'),
              inputs: [this.inputName, inputHDYHAU]
            });

            let stageCompany = new Stage({
              key: "stageCompany",
              title: "",
              subtitle: "",
              inputs: [
                this.inputCompanyName,
                inputCompanyType,
                inputCompanyTypeText
              ],
              analyticsEvents: {
                submit: [
                  AnalyticsService.analytics_events
                    .intake_form_submit_company_info
                ],
                load: [
                  AnalyticsService.analytics_events
                    .intake_form_loaded_company_info
                ]
              }
            });

            let stageAccount = new Stage({
                key: 'stageAccount',
                title: '',
                subtitle: 'One last thing — you know the drill 💪',
                inputs: [inputEmail, inputPhone, inputPassword, inputCheckbox],
                analyticsEvents: {
                    submit: [AnalyticsService.analytics_events.intake_form_submit_user_info],
                    load: [AnalyticsService.analytics_events.intake_form_loaded_user_info]
                },
                validateStage: () => {
                    return _isEmailDomainValid(inputEmail.answer);
                }
            });

            let stageAccountForRegForm = new Stage({
                key: stageAccount.key,
                title: '',
                subtitle: '',
                inputs: [inputEmail, inputPhone, inputPassword],
                analyticsEvents: stageAccount.analyticsEvents,
                validateStage: stageAccount.validateStage
            });

            let stageAccountForSSO = new Stage({
                key: stageAccount.key,
                title: '',
                subtitle: '',
                inputs: [inputEmail, inputPhone],
                analyticsEvents: stageAccount.analyticsEvents,
                validateStage: stageAccount.validateStage
            });

            let stageHDYHAU = new Stage({
                key: 'stageHDYHAU',
                title: '',
                subtitle: 'One last thing... 💪',
                inputs: [inputHDYHAU, inputCheckbox]
            });

            // mPaid funnel stages

            let mpaidStageCompanyName = new Stage({
                key: 'mpaidStageCompanyName',
                title: 'Next, add the name of your business.',
                subtitle: '',
                inputs: [this.inputCompanyName]
            });

            let mpaidStageIntroduction = new Stage({
                key: 'mpaidStageIntroduction',
                title: 'Now, add your full name.',
                subtitle: '',
                inputs: [this.inputName]
            });

            let mpaidStageCompanyType = new Stage({
                key: 'mpaidStageCompanyType',
                title: 'What industry is your business in?',
                subtitle: '',
                inputs: [inputCompanyType, inputCompanyTypeText],
                updateTitleFunction: givenTitle => {
                    let newTitle = givenTitle;
                    let firstName = this.inputName.answer
                        ? this.inputName.answer.split(' ')[0]
                        : '';

                    if (firstName) {
                        // Remove the question mark from the end
                        newTitle = this.currentStage.title.slice(0, -1);

                        // Grab the last word
                        const lastWordOfTitle = newTitle.split(' ').slice(-1)[0]

                        if (lastWordOfTitle === 'in') {
                            // Probably the default title, just append the first name
                            newTitle += `, ${this.firstName}?`;
                        } else {
                            // Assume there's a firstName at the end already
                            // and swap that name for new firstName:
                            newTitle =
                                newTitle
                                    // Break it in to array of words
                                    .split(' ')
                                    // Remove the old firstName from the end
                                    .slice(0, -1)
                                    // Append new first name
                                    .concat([firstName + '?'])
                                    // Put array back together, return a string
                                    .join(' ');
                        }
                    }
                    return newTitle;
                }
            });

            let mpaidStageUploadLogo = new Stage({
                key: 'mpaidStageUploadLogo',
                title: 'Upload your logo',
                subtitle: '',
                inputs: [inputUploadLogo],
                subtext: 'Don’t have one now?\nNo worries, you can do it later.'
            });

            let mpaidStageCoverImage = new Stage({
                key: 'mpaidStageCoverImage',
                title: 'Pick a header image',
                subtitle: '',
                inputs: [inputDefaultCoverPhoto],
                subtext: "You can upload your own later."
            });

            let mpaidStageAccountInfo = new Stage({
                key: 'mpaidStageAccountInfo',
                title: 'Almost done! Start a free trial to begin using your templates',
                subtitle: '',
                inputs: [inputEmail, inputPassword, inputCheckbox]
            });
            /*
             *
             * Form definitions
             *
             */

            let defaultForm = {
                stages: [
                    stageIntroduction,
                    stageCompany,
                    stageAccount
                ],
                name: "currStageForm",
                ctaText: "Next",
                ctaId: "btnNext",
                currentStageIndex: 0,
            };

            let facebookMobileForm = {
                stages: [
                    mpaidStageCoverImage,
                    mpaidStageCompanyName,
                    mpaidStageIntroduction,
                    mpaidStageCompanyType,
                    mpaidStageUploadLogo,
                    mpaidStageAccountInfo
                ],
                name: "currStageForm",
                className: "mpaid-signup-form",
                ctaText: "Next",
                ctaId: "btnNext",
                currentStageIndex: 0,
            }

            this.isFacebookMobileFlow = this._isFacebookMobileFlow();

            this.form = this.isFacebookMobileFlow ? facebookMobileForm : defaultForm;

            if  (this._isCameFromRegistrationFormOrSSO()) {

                if (this._isSSOUser()) {
                    this.form.stages = [stageCompany, stageAccountForSSO, stageHDYHAU];
                } else {
                    this.form.stages = [stageCompany, stageAccountForRegForm, stageHDYHAU];
                }

                inputEmail.answer = this.signupData.vendor_email;
                this.signupData.name = this.signupData.vendor_name;
                this.inputName.answer = this.signupData.vendor_name;

                if(this.signupData.vendor_email) {
                    this.AnalyticsService.reportIterableEvent(
                        'lead_created',
                        {lead_type: 'lead_intake_dropoff'},
                        {email: this.signupData.vendor_email}
                    );
                }
            }

            this._setCurrentStage(0, true);

            // stop submit on enter
            $('.signup-form__fields').on('keyup keypress', function(e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            });

            this._raiseGoogleOptimizeCallback('event', 'optimize.callback', {
                callback: this._implementManyExperiments.bind(this)
            });

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_form_loaded, this.getDataForLoadIntakeForm());

        } // close constructor

        

        getStartTrialVariant() {
            if (this.signupData.is_sso) {
                return 'signup-with-sso';
            } else {
                return this.signupData.start_trial_variant;
            }
        }

        getSSOVariant() {
            if (this.signupData.is_sso) {
                return this.signupData.sso_type;
            } else {
                return 'none';
            }
        }
        
        getDataForLoadIntakeForm() {
            this.intakeFormAnalyticsObject.signup_landing_page = this.signupData.signupLandingPage || this.signupData.signup_landing_page || ''
            this.intakeFormAnalyticsObject.start_trial_variant = this.getStartTrialVariant();
            this.intakeFormAnalyticsObject.intake_variant = 'angular-wizard';
            this.intakeFormAnalyticsObject.sso = this.getSSOVariant();
            return this.intakeFormAnalyticsObject;
        }
        
        getDataForLoadedStep() {
            return this.intakeFormAnalyticsObject;
        }

        getDataForSubmitSuccess() {
            return this.intakeFormAnalyticsObject;
        }

        applyCoupon() {
            var coupon = this.userData.utmMbsy || this.userData.promo;
            if (coupon) {
                this.CouponsService.applyCoupon(coupon);
            }
        }

        _raiseGoogleOptimizeCallback() {
            this.GoogleTagManagerService.pushEvent(arguments);
        }

        _implementManyExperiments(value, id) {
        }

        _isCameFromRegistrationFormOrSSO() {
            return (this.signupData && this.signupData.vendor_email && this.signupData.vendor_name);
        }

        _isSSOUser() {
            return (this.signupData && this.signupData.is_sso);
        }

        _isFacebookMobileFlow() {
            if (this.signupLandingPage === this.Enums.LandingPages.smartBooking){
                return true;
            }

            return false;
        }

        _getFirstName(fullName) {
            let firstName;
            if (fullName) {
                firstName = fullName.split(' ')[0];
            }
            return firstName;

        }

        _getCurrentTitle() {
            const defaultTitle = 'Hello and welcome 🙂';
            let currentTitle = defaultTitle;

            if (this.inputName.answer) {
                this.firstName = this._getFirstName(this.inputName.answer);
                currentTitle = `Hi ${this.firstName}, welcome 🙂`;
            }

            if (this.currentStage.title) {
                currentTitle = this.currentStage.title;
            }

            return currentTitle;
        }

        _trackStage(eventType) {
            // If added Analytics Event on stage, track it. add stage data as well.
            if (this.currentStage.analyticsEvents && this.currentStage.analyticsEvents[eventType]) {
                let currStageData = undefined;
                if (eventType === 'submit') {
                    currStageData = {};
                    this.currentStage.inputs.forEach(input => {
                        if (input.serverKey !== 'password') {
                            currStageData[input.serverKey] = input.answerKey || input.answer;
                        }
                    });
                }

                this._.each(this.currentStage.analyticsEvents[eventType], event => {
                    this.AnalyticsService.track(this, event, currStageData);
                })
            }
        }

        _trackStageInputs(eventType) {

            // If added Analytics Event on input, track it.
            let shouldlTrackInput = true;
            this.currentStage.inputs.forEach(input => {
                shouldlTrackInput = (eventType === 'load' ? true : (input.answerKey || input.answer));
                if (input.analyticsEvents && shouldlTrackInput) {
                    this.AnalyticsService.track(this, input.analyticsEvents[eventType], eventType === 'submit' ? {[input.serverKey]: input.answerKey || input.answer} : undefined );
                }
            });
        }

        _fireStageAnalytics(eventType) {
            // eventType : 'load' / 'submit'
            this._trackStage(eventType);
            this._trackStageInputs(eventType);
        }

        _moveToNextStage() {
            this.generalServerError = null;

            this._fireStageAnalytics('submit');
            this.AnalyticsService.reportEvent('SubmittedIntakeFormStage', {stage: this.currentStage.key});

            if (this.form.currentStageIndex < this.form.stages.length - 1) {
                this._setCurrentStage(++this.form.currentStageIndex);
            } else {
                if (this.redirectObject) {
                    // clear localstorage
                    this.$window.localStorage.removeItem("signupData");

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.registration_validation_redirect);
                    this.$window.location.href = this.redirectObject.url;
                } else {
                    this._createUserPost();
                }
            }
        }

        _setCurrentStage(stageIndex, isInitialLoad) {
            this.stageIn = false;
            this.$("html, body").animate({ scrollTop: 0 }, "slow");
            this.$timeout( () => {
                this.stageIn = true;
                this.currentStage = this.form.stages[stageIndex];
                // this.GoogleTagManagerService.pushEvent({'event': 'optimize.activate', 'currentStage': this.currentStage.key});
                const titleString = this._getCurrentTitle();
                this.currentStage.updateTitle(titleString);
                this.form.isFinalStage = (stageIndex === this.form.stages.length - 1);

                this.finalCtaText = this.isFacebookMobileFlow
                    ? 'Start free trial'
                    : 'Create free account';

                this.form.ctaText = this.form.isFinalStage ? this.finalCtaText : 'Next';
                this.form.ctaId = this.form.isFinalStage ? 'btnSubmitIntake' : 'btnNext';

                this.$timeout(function () {
                    let firstEmptyInput = this._.find(this.currentStage.inputs, (input) => !input.answer || input.answer === 'noAnswer');
                    if (firstEmptyInput && !this.isFacebookMobileFlow) {
                        firstEmptyInput.hasFocus = true;
                    }
                }.bind(this));

                this.$timeout(function () {
                    this._.each(this.currentStage.inputs, input => {
                        if (input.prefillValue) {
                            input.answer = input.prefillValue;
                        }
                    });
                }.bind(this));

                //validate the fields of current stage
                this.currentStage.isValid();

                this._fireStageAnalytics('load');
                if (!isInitialLoad) {
                    // i want to track a step load event but not for the initial load
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_form_loaded_step, this.getDataForLoadedStep());
                }
            }, 300);
        }

        moveToPreviousStage() {
            this.generalServerError = null;
            if (this.form.currentStageIndex > 0) {
                this._setCurrentStage(--this.form.currentStageIndex);
            }
        }

        handleCtaClick() {
            this._clearGeneralErrorText();
            this.currentStage.loading = true;
            this.currentStage.validateStage()
                .then( () => {
                    if (this.form.name.$valid) {
                        this.$timeout(() => {
                            this._moveToNextStage();
                            this.currentStage.loading = false;
                        }, 200);
                    }
                })
                .catch( (errorObject) => {
                    this.$timeout(() => {
                        this.currentStage.loading = false;
                    }, 500);

                    var message = errorObject.message;
                    var currInput = errorObject.input;

                    if (currInput) {
                        this.currentStage.valid = false;
                        currInput.setInputError(message);
                    } else {
                        this.currentStage.valid = true;
                        this._setGeneralErrorText(message);
                    }
            });
        }

        _getDataFromMarketingSite() {
            let signupDataObject = {};

            const signupDataFromLocalStorage = this.$window.localStorage.getItem("signupData");
            const signupDataFromQueryString = this.$location.search();

            if (signupDataFromLocalStorage && this.UIUtils.isJsonString(signupDataFromLocalStorage)) {
                signupDataObject = JSON.parse(signupDataFromLocalStorage);
            } else if (signupDataFromQueryString && Object.keys(signupDataFromQueryString).length > 0) {
                signupDataObject = signupDataFromQueryString;
                this.$location.replace().url(this.$location.path());
            }

            return signupDataObject;
        }

        _getOptimizeTestsData() {
            if (this.signupData && this.signupData.testName && this.signupData.testVariant){
                this.unbounceTest.experiment_id = 'unbounce-' + this.signupData.testName;
                this.unbounceTest.experiment_name = this.signupData.testName;
                this.unbounceTest.variation_id = 'unbounce-' + this.signupData.testVariant;
                this.unbounceTest.variation_name = this.signupData.testVariant;

                this.optimizeTests.push(this.unbounceTest);
            }
        }

        _getCompanyType(inputs) {
            var companyType = _.find(inputs, function(input){ return input.serverKey === 'company_type' });
            var companyTypeOther = _.find(inputs, function(input){ return input.serverKey === 'company_other' });

            var companyObject = {
                companyType : '',
                companyTypeOther: ''
            };

            if (companyType.answerKey) {
                // user inserted the data properly: USER SELECTED ITEM FROM THE DROPDOWN
                var parentType = this._getCompanyParentTypeByText(companyType.answer);
                if (parentType) {
                    companyObject.companyType = parentType;
                    companyObject.companyTypeOther = companyType.answer;
                } else {
                    companyObject.companyType = companyType.answerKey;
                    companyObject.companyTypeOther = companyTypeOther.answer;
                }
            } else {
                // user didnt select from the drop down
                // check if there is a match to something in the list,
                let matchingCompanyTypeKey = this._getCompanyTypeKeyByText(companyType.answer);
                if (matchingCompanyTypeKey) {
                    companyObject.companyType = matchingCompanyTypeKey;
                    companyObject.companyTypeOther = companyTypeOther.answer;
                } else {
                    // if not set it as other, and set the value entered in company_other
                    companyObject.companyType = "other";
                    companyObject.companyTypeOther = companyType.answer;
                }

            }

            return companyObject;
        }

        _getCompanyTypeKeyByText(answerText) {
            let companyTypeKey;

            let companyType = _.find(this.companyTypes, function(companyTypeItem){ return companyTypeItem.text.toLowerCase() === answerText.toLowerCase() });
            if (companyType) {
                companyTypeKey = companyType.key;
            }

            return companyTypeKey;
        }

        _getCompanyParentTypeByText(answerText) {
            let companyType = this.companyTypes.find(companyTypeItem => {return companyTypeItem.text.toLowerCase() === answerText.toLowerCase();});
            if (companyType) {
                return companyType.parentType;
            }

            return false;
        }

        _getFormInputs() {
            return this._.flatten(this.form.stages.map(stage => stage.inputs));
            // returns [ { input }, { input }, ...]
        }

        _getFormAnswers() {
            var inputs = this._getFormInputs();
            var answers = inputs.reduce((memo, input) => {
                memo[input.serverKey] = input.answer;
                return memo;
            }, {});

            let companyTypeObject = this._getCompanyType(inputs);
            answers.company_type = companyTypeObject.companyType;
            answers.company_other = companyTypeObject.companyTypeOther;

            return answers;
            // returns {company_name: "fdsfds", password: "76677887", ... }
        }

        _getUserData() {
            var formData = this._getFormAnswers();
            const utmParams = this._getUtmParams();
            this.userData = this._.extend({}, formData, this.signupData, utmParams);
            return this.userData;
        }

        _showSigningCaption(index, industryType) {
            var loaderSteps = [
                {
                    captionText: 'Installing ' + industryType + '\ninvoice templates...',
                },
                {
                    captionText: 'Installing ' + industryType + '\ncontract templates...',
                },
                {
                    captionText: 'Configuring ' + industryType + '\nworkflow automations...',
                },
                {
                    captionText: 'Setting up ' + industryType + '\nclient pipeline...',
                },
            ];

            this.signingCaption = loaderSteps[index].captionText;

            this.$timeout(() => {
                index = (index + 1) % loaderSteps.length;
                this._showSigningCaption(index, industryType)
            }, 3000)
        }

        _getCompanyTypeTextByType(userData) {
            var company_type = userData.company_type;
            var companyTypesTranslations = this.CompanyService.getCompanyTypesInformalTranslationMap();
            var industryType = userData.company_other || this.$translate.instant(companyTypesTranslations[company_type]) || "";

            if (industryType === '' || industryType === 'other') {
                industryType = 'your';
            }

            return industryType;
        }


        _showSigningAnimation(userData) {
            this.isSigningUserUp = true;
            this.$timeout(function() {
                this.isSigningUserUpDelayed = true;
            }.bind(this), 100);

            var industryType;
            if (!userData) {
                industryType = "";
            } else {
                industryType = this._getCompanyTypeTextByType(userData);
            }

            this._showSigningCaption(0, industryType);

        }

        _formatCreateUserParams(data) {
            // This is where preparing the params for the
            // server should happen.
            return {
                name: data.name,
                email: data.email,
                auth_token: data.auth_token || '',
                auth_code: data.auth_code || '',
                is_sso: data.is_sso || '', // Server expects "" not `false` 🙄
                sso_type: data.sso_type || 'regular',
                registration_variant: data.registration_variant,
                company_name: data.company_name,
                company_type: data.company_type,
                company_other: data.company_other,
                website_url: data.website_url,
                phone_number: data.phone_number,
                password: data.password,
                is_demo: data.is_demo || 'false',
                injected_ab_test: data.ab_test || '',
                injected_ab_test_variant: data.ab_test_variant || '',
                utm_campaign: data.utmCampaign || data.utm_campaign,
                utm_content: data.utmContent || data.utm_content,
                utm_medium: data.utmMedium || data.utm_medium,
                utm_source: data.utmSource || data.utm_source,
                utm_term: data.utmTerm || data.utm_term,
                utm_mbsy: data.utmMbsy,
                utm_origin: data.utmOrigin || data.utm_origin,
                promo: data.promo,
                referral_source: data.referralSource,
                gclid: data.gclid,
                fbclid: data.fbclid,
                irclickid: data.irclickid,
                ttclid: data.ttclid,
                placement: data.placement,
                ad_id: data.adId,
                geo_data: data.geo_data || null,
                referrer: data.referrer,
                optimizely_experiments: data.optimizelyTests,
                optimizely_user_id: data.optimizelyUserId,
                How_heard_of_us__c: data.How_heard_of_us__c,
                sms_opt_in__c: data.sms_opt_in__c,
                pricing_plan: data.pricing_plan  || '',
                signup_landing_page: data.signupLandingPage || data.signup_landing_page || '',
                location_data: this.$window.location_data || {}, // This is community registration Google Map form element
                default_cover_image_cloudinary_public_id: this.defaultCoverImageCloudinaryPublicId,
                use_default_icon: !this.logoFiles,
                optimize_experiments: this.optimizeTests.concat(data.optimize_experiments),
                ab_tests: data.ab_tests
            };
        }

        _createUserPost() {
            let userData = this._getUserData();
            this._showSigningAnimation(userData);
            let analyticsProperties = this._getIntakeAnalyticsProperties(userData);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_modal_click_submit, analyticsProperties);
            this.GoogleTagManagerService.onStartTrialClicked(userData);
            this.AnalyticsService.reportEvent('Lead Submitted', {
                ecData: {
                    email: userData.email,
                    phone_number: userData.phone_number,
                }
            });

            return this.Fingerprint()
                .then(fingerprint => {
                    userData = this._formatCreateUserParams(userData);
                    userData.fingerprint = fingerprint;
                    userData.f_token = this.ForterHelperService.getForterToken();

                    return this.UsersManager.createNewUser(userData)
                        .then(this._createNewUserSuccess.bind(this))
                        .catch(this._createNewUserFailure.bind(this));
                });

        }



        _getIntakeAnalyticsProperties(data) {
            if (data) {
                return {
                    name: data.name || '',
                    email: data.email || '',
                    orig_vendor_email: this.signupData.vendor_email,
                    registration_variant: data.registration_variant || '',
                    is_demo: data.is_demo,
                    signup_landing_page: data.signupLandingPage || data.signup_landing_page || '',
                    sso_type: data.sso_type || '',
                    source: data.source || '',
                    start_trial_variant: this.getStartTrialVariant(),
                    sso: this.getSSOVariant(),
                    intake_variant: 'angular-wizard',
                    company_name: data.company_name || '',
                    company_type: data.company_type || '',
                    company_other: data.company_other || '',
                    phone_number: data.phone_number || '',
                    how_heard_of_us: data.How_heard_of_us__c || '',
                    default_cover_image_cloudinary_public_id: this.defaultCoverImageCloudinaryPublicId,
                };
            }
        }

        _createNewUserSuccess(res) {
            this.user = res.model;

            var userData = this._getUserData();
            this.applyCoupon();

            var analyticsProperties = this._getIntakeAnalyticsProperties(userData);

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_modal_submit_success, this.getDataForSubmitSuccess());

            if (userData.registration_variant !== 'community') {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.mta_start_a_trial, analyticsProperties);
            }

            if (userData.is_sso) {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.facebook_sso_new_user);
            }

            if (this.logoFiles) {
                this.uploadLogoImage();
            }

            this._handleAnalytics();

            // clear localstorage
            this.$window.localStorage.removeItem("signupData");

            this.isAnimatingOut = true;

            this.$timeout(() => {
                let onboardingQuizState = this.OnboardingService.getOnboardingQuizState();
                let onboardingQuizStateParams = {};

                if (this.isFacebookMobileFlow) {
                    // Assert FB mpaid only see the 2Q variation
                    // (These trialers are excluded from test on server)
                    onboardingQuizState = this.AppStates.root_core_onboardingQuiz;
                    onboardingQuizStateParams.isFacebookMobileFlow = true;
                }

                this.$state.go(onboardingQuizState, onboardingQuizStateParams);
            }, 500);

        }

        _setGeneralErrorText(text) {
            this.generalServerError = text;
        }

        _clearGeneralErrorText() {
            this.generalServerError = '';
        }

        _createNewUserFailure(serverResponse) {

            var userData = this._getUserData();
            var analyticsProperties = this._getIntakeAnalyticsProperties(userData);

            // Enrich with error response data
            analyticsProperties.error_status = serverResponse && serverResponse.status ? serverResponse.status : null;
            analyticsProperties.error_type = serverResponse && serverResponse.data ? serverResponse.data.error_type : null;
            analyticsProperties.error_message = serverResponse && serverResponse.data ? serverResponse.data.error_message : null;

            this._setGeneralErrorText(`Sorry ${this.firstName},\n something went wrong while creating your new account. To fix it, press the ${this.finalCtaText.toUpperCase()} button again.`)
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.intake_form_submit_error, analyticsProperties);
            this.isSigningUserUp = false;
        }

        handleFocus() {
            if (this.isFacebookMobileFlow) {
                this.$window.scrollTo({
                    top: 240,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }

        onCtaKeyUp(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                this.handleCtaClick();
            }
        }

        onKeyUp(keyCode, index) {
            // Ignore invisible fields when moving inputs
            let visibleInputs = this.currentStage.visibleInputs();
            let currInput = visibleInputs[index];
            let nextInput = visibleInputs[index + 1];
            if (keyCode === 13) {
                if (currInput.shouldNotContinueOnEnter) {
                    // e.g. Hitting 'Enter' on typeahead
                    // dropdown should not continue the flow
                    return;
                }
                if (currInput) {
                    currInput.hasFocus = false;
                }
                if (nextInput) {
                    nextInput.hasFocus = true;
                } else {
                    this.currentStage.isValid()
                        .then( isValid => {
                            if (isValid) {
                                this.handleCtaClick();
                            }
                        });
                }
            }
        }

        uploadLogoImage() {
            let logoFile = this.logoFiles[0];
            let logoUploadModel = {
                instance: this.user.company,
                dontUpdateServer: false,
                type: 'icon',
                file: logoFile,
                name: logoFile.name,
                url: null
            };

            this.PhotosUploadManager.getUpdatedModelData(logoUploadModel);
            this.PhotosUploadManager.uploadPhoto(this.logoFiles, logoUploadModel, this);
        }

        _getUtmParams() {
            let utms = {};
            try {
                const URLSearchParams = this.$window.URLSearchParams;
                const urlValue = this.$("input[name='gtmCampaignUrl']").val();
                if(urlValue !== 'undefined') {
                    const url = new URL(urlValue);
                    const searchParams = new URLSearchParams(url.search);
                    utms = {
                        utmCampaign: searchParams.get('utm_campaign'),
                        utmContent: searchParams.get('utm_content'),
                        utmMedium: searchParams.get('utm_medium'),
                        utmSource: searchParams.get('utm_source'),
                        utmTerm: searchParams.get('utm_term'),
                        utmOrigin: searchParams.get('utm_origin'),
                        utmMbsy: searchParams.get('code'),
                        gclid: searchParams.get('gclid'),
                        fbclid: searchParams.get('fbclid'),
                        irclickid: searchParams.get('irclickid'),
                        ttclid: searchParams.get('ttclid'),
                        promo: searchParams.get('promo'),
                        referralSource: searchParams.get('referral_source'),
                        placement: searchParams.get('placement'),
                        adId: searchParams.get('ad_id')
                    };
                }
            } catch (e) {
                this.DatadogRUMService.addError(
                    e,
                    {
                        message: 'An error occurred in _getUtmParams'
                    }
                );
            }
            return utms;
        }

        _handleAnalytics() {
            // Google analytics
            this.GoogleTagManagerService.trackEvent('Start Trial');

            const vertical = this.user.company.company_type;
            if (this.GoogleTagManagerService.targetEventVerticals.includes(vertical)) {
                this.GoogleTagManagerService.trackEvent('TEV Start Trial');
            }

            if (this.GoogleTagManagerService.targetNonEventVerticals.includes(vertical)) {
                this.GoogleTagManagerService.trackEvent('TNEV Start Trial');
            }

            // GTM
            this.GoogleTagManagerService.onStartTrial();

            // Segment
            this.AnalyticsService.identifyUser(this.user);
            this.$timeout(() => {
                this.AnalyticsService.reportEvent('Lead', { userId: this.user._id });
                this.AnalyticsService.reportEvent('Trial Started');
            }, 200);
        }
    };
}());