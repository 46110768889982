
// @ngInject

Models.PackagesCollection = function PackagesCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'PackagesCollection';
                this._collection.__childsType = 'PackageModel';
                return this._collection
            }
        }
    );

};
