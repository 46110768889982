(function () {
    'use strict';

    class TwoFactorAuthController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, ToastService, $timeout) {
            super($scope, $injector);
            this.__objectType = 'TwoFactorAuthController';
            this.UsersManager = UsersManager;
            this.$timeout = $timeout;
            this.ToastService = ToastService;

            this.isFirstCode = true;
        }

        codeChanged() {
            const length = this.user.twoFactorAuthCode ? this.user.twoFactorAuthCode.length : 0;

            if (length === 0) {
                this.codeError = null;
            }

            if (length === 6 && this.isFirstCode) {
                this.validateTwoFactorAuthCode();
            }
        }

        validateTwoFactorAuthCode() {

            const length = this.user.twoFactorAuthCode ? this.user.twoFactorAuthCode.length : 0;
            if (length === 0) {
                this.codeError = 'Please enter code';
                return;
            }

            this.codeError = null;
            this.isFirstCode = false;

            this.loading = true;
            this.UsersManager.sendTwoFactorAuthCode(this.user)
                .then((resp) => {
                    this.codeError = null;
                    if (this.onVerificationSuccess) {
                        this.onVerificationSuccess({resp: resp});
                    }
                })
                .catch(e => {
                    this.loading = false;

                    if (e.data && e.data.error) {
                        const errorType = e.data.error_type;
                        const errorMsg = e.data.error_message;

                        if (errorType === "HBTwoFactorAuthError") {
                            this.codeError = errorMsg; // wrong code
                        }
                    }

                    if (this.onVerificationFailure) {
                        this.onVerificationFailure({error: e});
                    }
                });
        }

        resendCode(type) {
            if (this.disableResendCode) {
                return;
            }

            this.disableResendCode = true;
            this.codeError = null;
            this.UsersManager.sendVerificationCode(this.user, type)
                .then(this.resendCodeSuccess.bind(this))
                .catch(this.resendCodeError.bind(this));
            this.$timeout(() => {
                this.disableResendCode = false;
            }, 5000);
        }

        resendCodeSuccess() {
            // show code sent
            this.ToastService.showSuccess({
                content: 'Code sent', dismissOnTimeout: true, timeout: 5000, dismissButton: true
            });
        }

        resendCodeError(e) {
            if (e.data.error) {
                this.ToastService.showError({
                    iconCSSClass: 'icon-hb-nx-exclamation-mark-circle',
                    content: e.data.error_message,
                    dismissOnTimeout: true,
                    timeout: 5000,
                    dismissButton: true
                });

                this.showTwoFactorAuth = false;
            }
        }

        isLoading() {
            return this.loading || this.disableCta;
        }
    }

    Components.TwoFactorAuthNew = {
        bindings: {
            onVerificationSuccess: '&', onVerificationFailure: '&', disableCta: '=', user: '='
        },
        controller: TwoFactorAuthController,
        name: 'hbTwoFactorAuthNew',
        templateUrl: 'angular/app/modules/common/ui_components/two_factor_auth/two_factor_auth_component_template.html',
    };

}());