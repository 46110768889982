(function () {
    'use strict';

    // @ngInject
    Services.Rellax = function Rellax($window) {
        if ($window.Rellax) {
            return $window.Rellax;
        }
        return {};
    };

}());
