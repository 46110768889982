Directives.FeedEmailViewerDirective =  function FeedEmailViewerDirective(){

    // @ngInject
    function FeedEmailViewerDirectiveControllerFunc($sce, $scope, $injector, $translate, $filter, AppStates, WorkspaceFileService, FeedService, CompaniesManager, UiPersistenceService, $, Enums, UIUtils) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedEmailViewerDirectiveController';
        this.AppStates = AppStates;
        this.lastDateViewed = null;
        this.pvlInquiryCopyClip = false;
        this.UiPersistenceService = UiPersistenceService;
        this.company = CompaniesManager.getCurrCompany();
        this.$ = $;
        this.Enums = Enums;
        this.ItemTypes = Enums.FeedItemTypes;

        $scope.$watch('feedEmailViewerVm.workspace.flows', function (newValue, oldValue) {
            this.flow  = this.workspace.flows ? this.workspace.flows.find(function(flow) {
                return flow._id === this.emailModel._id; }
                .bind(this)) : null;
        }.bind(this));

        this.isNewInquiryEmail = (this.emailModel.email_type_cd === this.Enums.EmailTypesCd.widget);

        this.shouldShowFeedItemBox = this.feedItem.type_cd === this.ItemTypes.workspace_flow_email;

        var body = this.emailModel.html_body;
        var from = body.indexOf("<!-- EMAIL LIST DISPLAY START -->");
        if (from < 0) {
            if (body.indexOf("<html") === 0){
                from = body.indexOf("<div");
            }
            else {
                from = 0;
            }
        }
        var to = body.indexOf("<!-- EMAIL LIST DISPLAY END -->");

        if (to < 0) {
            if (body.indexOf("<html") === 0 && body.indexOf("</body>") !== -1) {
                to = body.indexOf("</body>");
            }
            else {
                to = body.length;
            }
        }

        body = body.substring(from, to);

        // Rip out client loginless links from HTML
        // Rip out style tags
        body = FeedService.cleanFeedItemHtml(body);

        this.htmlBody = $sce.trustAsHtml(body);

        if (this.emailModel.email_type_cd == 5 && !this.activeUser) {
            this.pvlInquiryCopyClip = true;
        }

    }

    var FeedEmailViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedEmailViewerDirectiveControllerFunc,

        shouldShowPreferencesBanner: function shouldShowPreferencesBanner() {
            var showUI = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.activityPreferencesBanner, {show: true}).show;
            return this.feedItem.isPreferencesAutomationsGenerated() && showUI && this.company && this.company._id === this.feedOwnerCompanyId;
        },

        closePreferencesBanner: function closePreferencesBanner() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.activityPreferencesBanner, {show: false});
        },

        updateFlows: function updateFlows() {
            this.flow  = this.workspace.flows ? this.workspace.flows.find(function(flow) {
                return flow._id === this.emailModel._id; }
                .bind(this)) : null;

            this.updateFlowsInWorkspace();
        }

    });

    return {
        scope:{
            emailModel: '=emailModel',
            activeUser: '=activeUser',
            feedItem: '=feedItem',
            feedUsers: '=',
            feedOwnerCompanyId: '=?',
            workspace: '=?',
            updateFlowsInWorkspace: '&'
        },
        templateUrl : 'angular/app/modules/core/features/feed/viewer/feed_email_viewer_directive_template.html',
        controller : FeedEmailViewerDirectiveController,
        controllerAs : 'feedEmailViewerVm',
        bindToController : true
    };
};
