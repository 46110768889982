(function () {
    'use strict';

    // @ngInject
    function InstantDepositModalControllerCtor($scope, $injector, $modalInstance, $timeout, $q, $translate, moment,
                                     AnalyticsService, StripeHelperService, PopupMessageService, WorkspaceFilesManager,
                                     CompaniesManager, UsersManager, source, FlowsManager, paymentParentId, invoiceId, isFlowPayment) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'InstantDepositModalController';
        this.$modalInstance = $modalInstance;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.PopupMessageService = PopupMessageService;
        this.StripeHelperService = StripeHelperService;
        this.FlowsManager = FlowsManager;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.source = source;
        this.paymentParentId = paymentParentId;
        this.isFlowPayment = isFlowPayment;
        this.company = CompaniesManager.getCurrCompany();
        this.initialCompanyUnivesalToggle = this.company.all_inclusive_instant_payouts;
        this.user = this.UsersManager.getCurrUser();
        this.paymentAmount = 0;
        this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.instant_deposit_modal, {source: this.source});
        // internal client side dialog errors
        this.ERROR_TYPES = {
            EXPIRED: 'EXPIRED',
            NOT_ALLOWED: 'NOT_ALLOWED',
            MISSING_PAYMENT: 'MISSING_PAYMENT',
            IS_INSTANT: 'IS_INSTANT'
        };
        this.DEFAULT_ERROR = {
                event: this.AnalyticsService.analytics_events.confirm_instant_deposit_modal_error,
                icon: 'nx-modal__icon-error icon-hb-nx-triangle-exclamation-mark-42',
                title: 'ERRORS.SERVER_API._UNKNOWN_',
                description: 'ERRORS.SERVER_API._UNEXPECTED_',
                confirmButtonLabel: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.ERROR._MAIN_CTA_',
                mainCta: this.$modalInstance.close.bind(this)
        };
        this.STEPS = {
            ERROR: this.DEFAULT_ERROR
        };
        this.loading = false;
        this.loadingModalContent = true;
        this.payment = null;

        if(paymentParentId && invoiceId) {
            this.getPayment(paymentParentId, invoiceId);
        } else {
            this.handleError(this.ERROR_TYPES.MISSING_PAYMENT);
        }
    }

    Controllers.InstantDepositModalController = Class(Controllers.BaseController, {
        constructor: InstantDepositModalControllerCtor,

        init: function init() {
            if(this.payment.use_instant_payout) {
                this.handleError(this.ERROR_TYPES.IS_INSTANT);
            }
            else {
                var promise;

                if (this.isFlowPayment) {
                    promise = this.FlowsManager.allowFlowChargedInstantPayout(this.paymentParentId, this.payment._id);
                } else {
                    promise = this.WorkspaceFilesManager.allowChargedInstantPayout(this.paymentParentId, this.payment._id);
                }
                promise
                    .then(function onSuccess(resp) {
                        var alreadyInstant = resp && resp.data && resp.data.process_as_instant_payout;
                        var isAllowed = resp && resp.data && resp.data.allow_charged_instant_payout;
                        if (alreadyInstant) {
                            this.handleError(this.ERROR_TYPES.IS_INSTANT);
                        } else if(isAllowed) {
                            if(this.isWithinTimeFrame(this.payment.payment_charge_date)) {
                                this.prepareNextSteps();
                                this.currentStep = this.STEPS.CONFIRM_INSTANT_DEPOSIT;
                                this.loadingModalContent = false;
                            }
                            else {
                                this.handleError(this.ERROR_TYPES.EXPIRED);
                            }
                        } else {
                            this.handleError(this.ERROR_TYPES.NOT_ALLOWED);
                        }
                    }.bind(this))
                    .catch(function onError(resp) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.allow_instant_deposit_for_payment,
                           resp);
                        if(resp.data && resp.data.error_message) {
                            this.handleError(this.ERROR_TYPES.NOT_ALLOWED);
                        } else {
                            this.handleError();
                        }
                    }.bind(this));
                }
        },

        getPayment: function getPayment(paymentParentId, invoiceId) {
            var promise;

            if (this.isFlowPayment) {
                promise = this.FlowsManager.getPayment(paymentParentId, invoiceId);
            } else {
                promise = this.WorkspaceFilesManager.getPayment(paymentParentId, invoiceId);
            }

            promise.then(
                function onSuccess(resp) {
                    this.payment = resp.data;
                    if(this.payment) {
                        this.init();
                    } else {
                        this.handleError(this.ERROR_TYPES.MISSING_PAYMENT);
                    }
                }.bind(this)).catch(
                function onError() {
                    this.handleError(this.ERROR_TYPES.MISSING_PAYMENT);
                }.bind(this)
            );
        },

        handleError: function handleError(type) {
            var baseT = 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.ERROR._';
            switch (type) {
                case this.ERROR_TYPES.EXPIRED:
                case this.ERROR_TYPES.NOT_ALLOWED:
                case this.ERROR_TYPES.MISSING_PAYMENT:
                case this.ERROR_TYPES.IS_INSTANT:
                    this.STEPS.ERROR.title = baseT + type + '_TITLE_';
                    this.STEPS.ERROR.description = baseT + type + '_DESCRIPTION_';
                    break;
                default:
                    // server error
                    this.STEPS.ERROR.title = 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.ERROR._NOT_ALLOWED_TITLE_';
                    this.STEPS.ERROR.description = type;
                    break;
                case null:
                case undefined:
                    type = 'unknown';
                    break;
            }
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.instant_deposit_modal_error,
                {type: type});
            this.currentStep = this.STEPS.ERROR;
            this.loadingModalContent = false;
        },

        prepareNextSteps: function prepareNextSteps() {
            this.STEPS.CONFIRM_INSTANT_DEPOSIT = {
                event: this.AnalyticsService.analytics_events.confirm_instant_deposit,
                icon: 'icon-hb-nx-dollar-fast',
                title: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT._TITLE_',
                description: this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT._DESCRIPTION_',
                {last4: this.user.myDebitCard().last4}),
                confirmButtonLabel: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT._MAIN_CTA_',
                fee: this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT._FEE_',
                {fee_amount: this.payment.instant_payout_theoretical_fee}),
                note: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT._NOTE_',
                mainCta: this.confirmDepositNow.bind(this)
            };
            this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED = {
                event: this.AnalyticsService.analytics_events.confirm_instant_deposit_initiated,
                icon: 'icon-hb-nx-check-42',
                title: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT_INITIATED._TITLE_',
                description: this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT_INITIATED._DESCRIPTION_',
                {total_amount: this.getAmount()}),
                confirmButtonLabelWithoutUniversalToggle: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT_INITIATED._MAIN_CTA_',
                confirmButtonLabelWithUniversalToggle: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT_INITIATED._MAIN_CTA_WITH_UNIVESAL_TOGGLED_',
                note: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_MODAL.CONFIRM_INSTANT_DEPOSIT_INITIATED._NOTE_',
                mainCta: this.confirmInstantDepositInitiated.bind(this)
            };
            this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED.confirmButtonLabel =
                this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED.confirmButtonLabelWithoutUniversalToggle;

        },

        confirmInstantDepositInitiated: function confirmInstantDepositInitiated() {
            if (!this.initialCompanyUnivesalToggle && this.company.all_inclusive_instant_payouts) {
                this.loading = true;
                this.CompaniesManager.updateAllInclusiveInstantPayouts(this.company,
                    this.company.all_inclusive_instant_payouts, 'instant_deposit_modal_universal_toggle')
                    .then(function success(resp) {
                        this.AnalyticsService.trackSuccess(this, 'universal instant deposit enabled',
                            {toggledTo: true, source: this.source, from: 'deposit now flow'});
                    }.bind(this))
                    .finally(function final() {
                        this.loading = false;
                        this.$modalInstance.close();
                    }.bind(this));
            } else {
                this.$modalInstance.close();
            }
        },

        confirmDepositNow: function confirmDepositNow() {
            this.loading = true;

            var promise;
            if (this.isFlowPayment) {
                promise = this.FlowsManager.markPaymentAsInstantPayout(this.paymentParentId, this.payment._id, true, 'instant_deposit_modal');
            } else {
                promise = this.WorkspaceFilesManager.markPaymentAsInstantPayout(this.paymentParentId, this.payment._id, true, 'instant_deposit_modal');
            }

            promise.then(
                function onSuccess(resp) {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.toggle_instant_payment,
                        {newState: true, source: 'instant deposit modal'});
                    this.currentStep = this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED;
                    this.loading = false;
                    this.loadingModalContent = false;
                }.bind(this)).catch(
                function onError(resp) {
                    this.loading = false;
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.toggle_instant_payment, resp);
                    this.handleError((resp.data && resp.data.error_message) || this.ERROR_TYPES.NOT_ALLOWED);
                }.bind(this));
        },

        isWithinTimeFrame: function isWithinTimeFrame(chargeDate) {
            var maxDateForInstant = this.moment(chargeDate).add(1, 'days');
            return this.moment().isBefore(maxDateForInstant);
        },

        getAmount: function getTotal() {
            return this.payment.hb_vendor_transfer_amount - this.payment.instant_payout_theoretical_fee;
        },

        showUniversalToggle: function showUniversalToggle() {
            return !this.initialCompanyUnivesalToggle && // already toggled...?
                this.source === 'bookkeeping' && // only deposit now flow from bookeeping
                this.currentStep === this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED; // the right time
        },

        changeConfirmLabel: function changeConfirmLabel() {
            this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED.confirmButtonLabel =
                (!this.initialCompanyUnivesalToggle && this.company.all_inclusive_instant_payouts) ?
                this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED.confirmButtonLabelWithUniversalToggle:
                this.STEPS.CONFIRM_INSTANT_DEPOSIT_INITIATED.confirmButtonLabelWithoutUniversalToggle;
        },

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        }
    });
}());
