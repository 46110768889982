// What to pass?
// Functions - Pass functions definitions without the ().
// To be able to use THIS inside the funtions, you need to bind it beforehand.
// to do some correctly: define them in the constructor. Use arrow functions or bind(this).
// e.g.: this.callback = () => { ... }
// All other params should be passed without curly brackets {{}}
// We are stringified objects before passing them to react

(function () {
	'use strict';
	class ReactComponentController extends Controllers.BaseControllerES6 {
		
		// @ngInject
		constructor($scope, $element, $parse, $injector, $attrs, uuid4,  ReactLoaderService, $stateParams = {}) {
			super($scope, $injector);
			this.__objectType = 'ReactComponentController';
			this.$element = $element;
			
			this.$parse = $parse;
			this.$scope = $scope;
			this.parentScope = $scope.$parent;
			this.$attrs = $attrs;
			this.$injector = $injector;
			this.$stateParams = $stateParams;
			this.reactRenderId = uuid4.generate();
			this.ReactLoaderService = ReactLoaderService;
		}
		
		$postLink() {
			this.registerObservers();
			const props = this.getProps();
			this.ReactLoaderService.loadReactInfraData(props).then(() => {
				return this.ReactLoaderService.getReactInstance();
			}).then(honeybookReact => {
				this.reactRenderFunction = honeybookReact.bootstrap(this.component);
				const _props = this.getProps();
				this.render(_props);
			});
		}
		
		render(props, isPropUpdate) {
			const routeParams = { match: { params: Object.assign({}, this.$stateParams) } };
			
			if (!this.reactRenderFunction){
				return;
			}
			
			this.reactUnrenderFunction = this.reactRenderFunction(
				this.$element[0],
				this.reactRenderId,
				props,
				routeParams,
				this.$injector,
				isPropUpdate
				);
				
			}
			
			registerObservers() {
				const watchGroupExpressions = Object.keys(this.$attrs.$attr)
				.filter(key => key !== 'component')
				.reduce((result, key) => {
					result.push(this.$attrs[key]);
					
					return result;
				}, []);
				
				
				this.unsubscribe = this.parentScope.$watchGroup(
					watchGroupExpressions,
					(newValues, oldValues) => {
						if (newValues.every((newValue, index) => oldValues[index] === newValue)) {
							return;
						}
						
						const props = this.getProps();

						this.render(props, true);
					}
					);
				}
				
				getProps() {
					return Object.keys(this.$attrs.$attr)
					.filter(key => key !== 'component')
					.reduce((result, key) => {
						const getValue = this.$parse(this.$attrs[key]);
						result[key] = getValue(this.parentScope);
						
						return result;
					}, {});
				}
				
				$onDestroy() {
					this.reactUnrenderFunction && this.reactUnrenderFunction();
					this.unsubscribe();
				}
			}
			
			Components.ReactComponent = {
				template: '<div></div>',
				bindings: {
					component: '@'
				},
				controller: ReactComponentController
			};
			
		}()); 