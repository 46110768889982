(function () {
    'use strict';

    Directives.ActivityNotificationDirective = function ActivityNotificationDirective() {
        // @ngInject
        function ActivityNotificationDirectiveControllerCtor(
            $scope, $injector, moment, $translate, $state,
            AppStates, UsersManager, AnalyticsService, ReferralService,
            UIUtils, WebPNService, $filter, Enums, ModalService, EventsManager, UserService,
            OnboardingService, SetupGuideService, FeaturesService, WorkspaceFilesManager, ReactModalService,
            StatsigService, CompaniesManager, UiPersistenceService) {

            this.UsersManager = UsersManager;
            this.UIUtils = UIUtils;
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'ActivityNotificationDirectiveController';

            this.moment = moment;
            this.$state = $state;
            this.$translate = $translate;
            this.$filter = $filter;
            this.Enums = Enums;
            this.AppStates = AppStates;
            this.AnalyticsService = AnalyticsService;
            this.ReferralService = ReferralService;
            this.WebPNService = WebPNService;
            this.OnboardingService = OnboardingService;
            this.SetupGuideService = SetupGuideService;
            this.FeaturesService = FeaturesService;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.ReactModalService = ReactModalService;
            this.StatsigService = StatsigService;
            this.company = CompaniesManager.getCurrCompany();
            this.UiPersistenceService = UiPersistenceService;

            this.currUser = UsersManager.getCurrUser();
            this.elapsedTimeInStep = null;
            this.description = null;
            this.linkUrl = null;
            this.stateToGoTo = null;
            this.stateParams = null;
            this.stateOptions = null;
            this.action = null;
            this.reformatDate();
            this.ModalService = ModalService;
            this.EventsManager = EventsManager;
            this.UserService = UserService;
            this.isDesktopAIComposerEnabled = false;

            $scope.$watch('activityVm.showing', function (newVal, oldVal) {
                if (!oldVal && newVal) {
                    this.reformatDate();
                }
            }.bind(this));

            this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.aiComposerWebEnabled).then(function(value) {
                this.isDesktopAIComposerEnabled = value;
                this.formatData();
            }.bind(this));


            $scope.$watch('activityVm.model.last_version_created_at', function (newVal, oldVal) {
                if (oldVal !== newVal) {
                    this.formatData();
                }
            }.bind(this));
        }

        var ActivityNotificationDirectiveController = Class(Controllers.BaseController, {
            constructor: ActivityNotificationDirectiveControllerCtor,

            formatData: function formatData() {
                this.activityType = this.model.data.generic_type ? 'generic_' + this.model.data.generic_type : this.model.type;
                var interpulationParams;

                switch (this.model.type) {
                    case 'pvl_requested':

                        var is_reminder = this.model.data.is_reminder;
                        var messageTranslation = is_reminder ?
                            'ACTIVITY_NOTIFICATIONS._PVL_REQUESTED_REMINDER_' :
                            'ACTIVITY_NOTIFICATIONS._PVL_REQUESTED_';

                        this.messageAsHtml = this.$translate.instant(messageTranslation, {
                            clientFirstName: this.model.data.client_first_name
                        });

                        this.setLinkToFeed();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-pvl';
                        break;
                    case 'new_inquiry':

                        const name = this.model.data.client_full_name || this.model.data.client_first_name;
                        if (
                            this.model.data.auto_suggestion &&
                            this.isDesktopAIComposerEnabled
                        ) {
                            if (this.model.data.event_date) {
                                const momentEventData = this.moment(this.model.data.event_date, "YYYY-MM-DD");
                                this.messageAsHtml = this.$translate.instant(
                                    'ACTIVITY_NOTIFICATIONS._NEW_INQUIRY_WITH_AI_RESPONSE_WITH_DATE_',
                                    {
                                        clientName: name,
                                        eventDate:
                                            momentEventData.format('MMM DD, YYYY')
                                    }
                                );
                            } else {
                                this.messageAsHtml = this.$translate.instant(
                                    'ACTIVITY_NOTIFICATIONS._NEW_INQUIRY_WITH_AI_RESPONSE_',
                                    {
                                        clientName: name
                                    }
                                );
                            }
                        } else {
                            if (this.model.data.event_date) {
                                const momentEventData = this.moment(this.model.data.event_date, "YYYY-MM-DD");
                                this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NEW_INQUIRY_WITH_DATE_', {
                                    clientName: name,
                                    eventDate: momentEventData.format("MMM DD, YYYY")
                                });
                            } else {
                                this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NEW_INQUIRY_WITHOUT_DATE_', {
                                    clientName: name,
                                });
                            }
                        }
                        this.setLinkToFeed();
                        var originalAction = this.goToAssociatedState.bind(this);
                        this.action = function () {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.new_inquiry_notification_click, {
                                source: 'notification_center'
                            });
                            originalAction();
                        }.bind(this);
                        this.iconType = 'icon-hb-nx-new-inquiry';
                        break;
                    case 'new_hot_inquiry':
                        var analyticEvent = "";
                        var iconType = 'icon-hb-nx-new-inquiry';
                        var messageKey = '';
                        const hasDate = this.model.data.event_date && this.moment(this.model.data.event_date, "YYYY-MM-DD").format("MMM DD, YYYY") || undefined;

                        if (
                            this.model.data.auto_suggestion &&
                            this.isDesktopAIComposerEnabled
                        ) {
                            messageKey = '_WITH_AI_RESPONSE_';
                            analyticEvent =
                                this.AnalyticsService.analytics_events.new_hot_inquiry_auto_suggestion_notification_click;
                            iconType = 'icon-hb-nx-fire-24';
                        } else if (this.model.data.hot_lead.revenue && this.model.data.hot_lead.cvr) {
                            messageKey = "_BOTH_";
                            analyticEvent = this.AnalyticsService.analytics_events.new_hot_inquiry_cnr_notification_click;
                            iconType = 'icon-hb-nx-crown-24';
                        } else if (this.model.data.hot_lead.revenue) {
                            messageKey = "_REVENUE_";
                            analyticEvent = this.AnalyticsService.analytics_events.new_hot_inquiry_rev_notification_click;
                            iconType = 'icon-hb-nx-spark';
                            if (this.model.data.hot_lead.budget) {
                                messageKey = "_REVENUE_BUDGET_";
                            }
                        } else if (this.model.data.hot_lead.cvr) {
                            messageKey = "_CVR_";
                            analyticEvent = this.AnalyticsService.analytics_events.new_hot_inquiry_cnv_notification_click;
                            iconType = 'icon-hb-nx-fire-24';
                            if (this.model.data.hot_lead.is_referral) {
                                messageKey = "_CVR_REFERRAL_";
                            }
                        }

                        if (hasDate) {
                            messageKey += "DATE_";
                        }

                        const clientFullName = this.model.data.client_full_name || this.model.data.client_first_name;

                        this.setLinkToFeed();
                        const originalActionTemp = this.goToAssociatedState.bind(this);
                        this.action = function () {
                            this.AnalyticsService.track(this, analyticEvent, {
                                source: 'notification_center'
                            });
                            originalActionTemp();
                        }.bind(this);
                        this.iconType = iconType;
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS.HOT_LEAD.' + messageKey, {
                            clientFullName: clientFullName,
                            eventDate: hasDate,
                            budget: this.model.data.hot_lead.budget
                        });
                        break;
                    case 'new_promotion_inquiry':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NEW_PROMOTION_INQUIRY_', {
                            clientFullName: this.model.data.client_full_name,
                            productType: this.$translate.instant('CONTACT_FORM.FLOW_TYPES._' + this.model.data.flow_type.toUpperCase() + '_')
                        });

                        this.setLinkToContact(this.model.data.client_email);
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-gift-card-24';
                        break;
                    case 'promotion_purchased':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROMOTION_PURCHASED_', {
                            clientFullName: this.model.data.client_full_name,
                            productType: this.$translate.instant('CONTACT_FORM.FLOW_TYPES._' + this.model.data.flow_type.toUpperCase() + '_')
                        });

                        this.setLinkToContact(this.model.data.client_email);
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-gift-card-24';
                        break;
                    case 'new_feed_message':
                        var initialTextOfFeedMessage = "";
                        if (this.model.data.html_body.indexOf('<') >= 0) {
                            var html_body = this.model.data.html_body.replace(/<img[^>]*>/g,"");
                            try {
                                initialTextOfFeedMessage = angular.element(html_body).text();
                            }
                            catch(err) {
                                html_body = "<html>" + html_body + "</html>";
                                initialTextOfFeedMessage = angular.element(html_body).text();
                            }
                        } else {
                            initialTextOfFeedMessage = this.model.data.html_body || '';
                        }

                        //remove unneeded spaces
                        initialTextOfFeedMessage = initialTextOfFeedMessage.split(/\s+/).join(" ");

                        //trim any beginning spaces
                        initialTextOfFeedMessage = initialTextOfFeedMessage.trim();

                        //if this is a long text take just the first 100 chars we dont need anymore (like when it's coming from an inbound email that we insert to the feed)
                        initialTextOfFeedMessage = initialTextOfFeedMessage.substring(0, 100);

                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FEED_MESSAGE_', {
                            senderFirstName: this.model.data.sender_first_name,
                            initialText: initialTextOfFeedMessage
                        });

                        this.setLinkToFeed();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-message';
                        break;
                    case 'email_marked_unread':
                        var momentDateSentOn = this.moment(this.model.data.email_sent_on, "YYYY-MM-DD");
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._EMAIL_MARKED_UNREAD_', {
                            clientFirstName: this.model.data.client_first_name,
                            dateFileEmailWasSent: momentDateSentOn.format("MMM DD, YYYY")
                        });

                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-email-exclamation';
                        break;
                    case 'payment_received':
                        interpulationParams = {
                            paymentAmount: this.model.data.payment_amount.toFixed(2),
                            fileName: this.model.data.workspace_file_title,
                            paymentCountDescription: this.model.data.payment_count_description,
                            allowInstantDeposit: this.model.data.seconds_left_for_instant_depositing > 0 && this.instantPayoutDateRelevant(this.model.data.paid_at),
                        };
                        var relevantForInstantDeposit =
                            this.currUser.company.allow_instant_payouts && interpulationParams.allowInstantDeposit;

                        this.setPaymentReceivedMessage(interpulationParams, this.model.data.tip_paid);
                        this.setLinkToFile();
                        if(relevantForInstantDeposit) {
                            this.action = this.startInstantDepositFlow.bind(this);
                            this.iconType = 'icon-hb-nx-dollar-fast';
                        } else {
                            this.action = this.goToAssociatedState.bind(this);
                            this.iconType = 'icon-hb-nx-dollar';
                        }
                        break;
                    case 'flow_payment_received':
                        interpulationParams = {
                            payerName: this.model.data.payer_name,
                            paymentAmount: this.model.data.payment_amount,
                            flowName: this.model.data.flow_title,
                            allowInstantDeposit: this.model.data.seconds_left_for_instant_depositing > 0 && this.instantPayoutDateRelevant(this.model.data.paid_at),
                            gratuity: this.model.data.gratuity,
                            isAch: this.model.data.is_ach,
                            isBankAccountConnected: this.model.data.is_bank_account_connected,
                            paymentIndex: this.model.data.payment_index,
                            totalPaymentsCount: this.model.data.total_payments_count
                        };

                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FLOW_PAYMENT_PAID_', interpulationParams);
                        this.iconType = 'icon-hb-nx-dollar';
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_NAME_SUB_TEXT_', interpulationParams);
                        this.setLinkToFlow();

                        relevantForInstantDeposit = this.currUser.company.allow_instant_payouts && interpulationParams.allowInstantDeposit;

                        if(relevantForInstantDeposit) {
                            this.action = this.startInstantDepositFlow.bind(this);
                        }
                        break;
                    case 'ach_success':
                        interpulationParams = {
                            payerName: this.model.data.payer_name,
                            paymentAmount: this.model.data.payment_amount,
                            flowName: this.model.data.flow_title,
                            paymentIndex: this.model.data.payment_index,
                            totalPaymentsCount: this.model.data.total_payments_count
                        };

                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._ACH_SUCCESS_', interpulationParams);
                        this.iconType = 'icon-hb-nx-dollar';
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_NAME_SUB_TEXT_', interpulationParams);
                        this.setLinkToFlow();
                        break;
                    case 'flow_payment_reminder_sent':
                        var translationKey = null;
                        if (this.model.data.is_automated) {
                            translationKey = 'ACTIVITY_NOTIFICATIONS._FLOW_AUTOMATED_PAYMENT_REMINDER_';
                        }
                        interpulationParams = {
                            clientName: this.model.data.recipient_name,
                            flowName: this.model.data.flow_name
                        };
                        this.messageAsHtml = this.$translate.instant(translationKey, interpulationParams);
                        this.iconType = 'icon-hb-nx-bell';
                        this.setLinkToFlow();
                        break;
                    case 'payment_pending':
                        interpulationParams = {
                            paymentAmount: this.model.data.payment_amount.toFixed(2),
                            fileName: this.model.data.workspace_file_title,
                            paymentCountDescription: this.model.data.payment_count_description
                        };

                        if (this.model.data.tip_paid) {
                            interpulationParams.paymentGratuity = this.model.data.tip_paid;
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PAYMENT_PENDING_WITH_GRATUITY_', interpulationParams);
                        } else {
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PAYMENT_PENDING_WITHOUT_GRATUITY_', interpulationParams);
                        }

                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar';
                        break;
                    case 'brochure_package_selected':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._BROCHURE_PACKAGE_SELECTED_', {
                            clientFirstName: this.model.data.client_first_name,
                            brochureFileTitle: this.model.data.workspace_file_title
                        });

                        this.setLinkToBrochureFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-check-circle';
                        break;
                    case 'agreement_signed':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._AGREEMENT_SIGNED_', {
                            clientFirstName: this.model.data.client_first_name,
                            fileName: this.model.data.workspace_file_title
                        });

                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-signature';
                        break;
                    case 'questionnaire_submitted':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._QUESTIONNAIRE_SUBMITTED_', {
                            clientFirstName: this.model.data.client_first_name,
                            fileName: this.model.data.workspace_file_title
                        });

                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-check-circle';
                        break;
                    case 'event_complete':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_COMPLETE', {
                            eventName: this.model.data.event_name
                        });

                        this.action = function() {
                            this.ReferralService.getCampaignDetails(this.UsersManager.getCurrUser()).then(function success(campaignDetails) {
                                if (campaignDetails && campaignDetails.campaign_uid) {
                                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.refer_a_friend_button_click,{
                                        campaign_uid: this.ReferralService.campaign_uid
                                    });
                                    this.ReferralService.openReferAFriend();
                                }
                            }.bind(this));
                        };
                        this.iconType = ''; //todo()NEED ICON
                        break;
                    case 'new_trialer':
                        // No longer created
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NEW_TRIALER', {
                            trialerName: this.model.data.trialer_name
                        });
                        this.action = function() {
                        };
                        this.iconType = 'icon-hb-nx-new-trialer';
                        break;
                    case 'meeting_today':
                        var meetingTimeAsString;
                        if(this.model.data.all_day){
                            meetingTimeAsString = this.moment(this.model.data.date_start).utc().format('ll');
                        } else {
                            meetingTimeAsString = this.moment(this.model.data.date_time_start).format('ll');
                        }
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._MEETING_TODAY_', {
                            meetingDateTimeStart: meetingTimeAsString,
                            eventName: this.model.data.event_name,
                            meetingTitle: this.model.data.meeting_title
                        });

                        this.setLinkToMeeting();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-calendar';
                        break;
                    case 'not_fully_signed_file':

                        var translation;

                        if (this.model.days_passed === 3) {
                            translation = 'ACTIVITY_NOTIFICATIONS._NOT_FULLY_SIGNED_AFTER_3_DAYS_';
                        }
                        else {
                            translation = 'ACTIVITY_NOTIFICATIONS._NOT_FULLY_SIGNED_AFTER_1_WEEK_';
                        }

                        this.messageAsHtml = this.$translate.instant(translation, {
                            fileName: this.model.data.workspace_file_title
                        });

                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = '';
                        break;
                    case 'countersign_agreement':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._COUNTERSIGN_AGREEMENT_', {
                            fileName: this.model.data.workspace_file_title
                        });
                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-signature';
                        break;
                    case "automated_workflow_inquiry_arrived_with_date_tbd":
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._AUTOMATED_AUTOMATION_INQUIRY_ARRIVED_WITH_DATE_TBD_');
                        this.setLinkToFeed();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-new-automated';
                        break;
                    case 'hyper_relevant_opportunity':
                        var matchingTypes = this.model.data.matching_types ? this.model.data.matching_types.map(function (t) {
                            return this.$translate.instant(this.Enums.ExtendedCreativeTypesTranslationMap[t]);
                        }.bind(this)) : [this.currUser.company.company_type_name];
                        var matchingTypesStr;
                        if (this.model.data.n_types && this.model.data.matching_types &&
                            this.model.data.n_types > this.model.data.matching_types.length) {
                            matchingTypesStr = matchingTypes.join(", ") + " and " + (this.model.data.n_types - matchingTypes.length) + " more";
                        } else {
                            matchingTypesStr = matchingTypes[0];
                            if (matchingTypes.length > 1) {
                                for (var i=1; i<matchingTypes.length - 1; i++) {
                                    matchingTypesStr += ", " + matchingTypes[i];
                                }
                                matchingTypesStr += " and " + matchingTypes[matchingTypes.length - 1];
                            }
                        }
                        if (this.model.data.opportunity_ids) {
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._HYPER_RELEVANT_OPPORTUNITY_AGGREGATED_', {
                                nOpps: this.model.data.opportunity_ids.length,
                                location: this.currUser.network.city || 'you',
                                vendorTypes: matchingTypesStr
                            });
                            this.description = this.$filter('hbTrunc')(this.model.data.looking_for,140,'ellipsis');
                        } else if (this.model.data.opportunity_id) {
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._HYPER_RELEVANT_OPPORTUNITY_', {
                                opp_type: this.model.data.opportunity_type ? this.Enums.OpportunityTypes[this.model.data.opportunity_type] : "opportunity",
                                location: this.model.data.location,
                                vendorType: matchingTypesStr
                            });
                            this.description = this.$filter('hbTrunc')(this.model.data.looking_for,140,'ellipsis');
                        }

                        this.setLinkToOpportunity();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-location-pin';
                        break;
                    case 'connection_posted_opportunity':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._CONNECTION_POSTED_OPPORTUNITY_', {
                            oppType: this.model.data.opportunity_type ? this.Enums.OpportunityTypes[this.model.data.opportunity_type] : "opportunity",
                            connectionFullName: this.model.data.connection_full_name,
                        });

                        this.setLinkToOpportunity();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-medal';
                        break;
                    case 'reader_comment_on_opportunity':
                        if (this.model.data.n_comments && this.model.data.n_comments > 1) { //aggregated version
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._READER_COMMENT_ON_OPPORTUNITY_AGGREGATED_', {
                                nComments: this.model.data.n_comments
                            });
                            this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._READER_COMMENT_ON_OPPORTUNITY_AGGREGATED_DESCRIPTION_', {
                                lookingFor: this.model.data.looking_for
                            });
                        } else {
                            this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._READER_COMMENT_ON_OPPORTUNITY_', {
                                firstName: this.model.data.first_name,
                                comment: this.$filter('hbTrunc')(this.model.data.comment,60,'ellipsis')
                            });
                            this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._READER_COMMENT_ON_OPPORTUNITY_DESCRIPTION_', {
                                lookingFor: this.model.data.looking_for
                            });
                        }

                        this.setLinkToOpportunity();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-message';
                        break;
                    case 'author_comment_on_opportunity':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._AUTHOR_COMMENT_ON_OPPORTUNITY_', {
                            lookingFor: this.model.data.looking_for
                        });
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._AUTHOR_COMMENT_ON_OPPORTUNITY_DESCRIPTION_', {
                            firstName: this.model.data.first_name,
                            comment: this.$filter('hbTrunc')(this.model.data.comment,60,'ellipsis')
                        });
                        this.setLinkToOpportunity();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-message';
                        break;
                    case 'network_connection_request':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NETWORK_CONNECTION_REQUEST_', {
                            fullName: this.model.data.full_name
                        });
                        this.stateToGoTo = this.AppStates.root_network_profile;
                        this.stateParams = {user_id: this.model.data.user_id};
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-plus-user';
                        break;
                    case 'network_connection_approval':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NETWORK_CONNECTION_APPROVAL_', {
                            fullName: this.model.data.full_name
                        });
                        this.stateToGoTo = this.AppStates.root_network_profile;
                        this.stateParams = {user_id: this.model.data.user_id};
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-plus-user';
                        break;
                    case 'new_calendly_meeting':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._NEW_CALENDLY_MEETING_', {
                            clientName: this.model.data.client_name,
                            meetingDate: this.moment(this.model.data.meeting_date).format('ll')
                        });
                        this.stateToGoTo = this.AppStates.root_core_navigation_calendar;
                        this.stateParams = {
                            calendar_item_id: this.model.data.calendar_item_id,
                            dateTimeStart: this.model.data.meeting_date
                        };
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-calendar';
                        break;
                    case 'referral_awareness':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_AWARENESS_');
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_NOTIFICATIONS_DESCRIPTION_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_invite_referrals;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar-circle';
                        break;
                    case 'premium_referral_campaign':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PREMIUM_REFERRAL_CAMPAIGN_', {
                            maxCommissionAmount: this.model.data.max_commission_amount,
                            discountAmount: this.model.data.discount_amount,
                            discountDuration: this.model.data.discount_duration
                        });
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_NOTIFICATIONS_DESCRIPTION_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_invite_referrals;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar-circle';
                        break;
                    case 'referral_started_trial':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_STARTED_TRIAL_', {
                            referredUserName: this.model.data.referred_user_name,
                        });
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_NOTIFICATIONS_DESCRIPTION_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_invite_referrals;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar-circle';
                        break;
                    case 'referral_subscribed':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_SUBSCRIBED_', {
                            referredUserName: this.model.data.referred_user_name,
                            commission_amount: this.model.data.commission_amount
                        });
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_NOTIFICATIONS_DESCRIPTION_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_invite_referrals;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar-circle';
                        break;
                    case 'referral_commission_payout':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_COMMISSION_PAYOUT_', {
                            referredUserName: this.model.data.referred_user_name,
                        });
                        this.description = this.$translate.instant('ACTIVITY_NOTIFICATIONS._REFERRAL_NOTIFICATIONS_DESCRIPTION_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_invite_referrals;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-dollar-circle';
                        break;
                    case 'client_upload_attachment':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._CLIENT_UPLOAD_ATTACHMENT_', {
                            clientFirstName: this.model.data.client_first_name
                        });

                        this.setLinkToFiles();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-clip';
                        break;
                    case 'auto_payment_unaccepted_version':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._AUTO_PAYMENT_UNACCEPTED_CHANGES_', {
                            fileType: this.model.data.workspace_file_type
                        });
                        this.setLinkToFile();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        break;
                    case 'gmail_unauthorized':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._GMAIL_UNAUTHORIZED_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_company_integrations;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        break;
                    case 'gcal_revoked':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._GCAL_REVOKED_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_calendar;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        break;
                    case 'fb_leads_unauthorized':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FB_LEADS_UNAUTHORIZED_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_company_integrations;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        break;
                    case 'email_unauthorized':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._EMAIL_UNAUTHORIZED_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_company_integrations;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        break;
                    case 'session_scheduled':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SESSION_SCHEDULED_', {client: this.model.data.client_name});
                        this.iconType = 'icon-hb-nx-calendar-star';
                        if (this.model.data.flow_id) {
                            const interData = { projectName: this.model.data.project_name, flowName: this.model.data.flow_title };
                            this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_AND_FLOW_NAME_SUB_TEXT_', interData);
                            this.setLinkToFlow();
                        } else {
                            this.stateToGoTo = this.AppStates.root_core_navigation_calendar;
                            this.stateParams = {dateTimeStart: this.model.data.session_date, stc: this.model.data.stc};
                            this.action = this.goToAssociatedState.bind(this);
                        }
                        break;
                    case 'session_rescheduled':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SESSION_RESCHEDULED_', {client: this.model.data.client_name});
                        this.iconType = 'icon-hb-nx-calendar-clock-24';
                        if (this.model.data.flow_id) {
                            const interData = { projectName: this.model.data.project_name, flowName: this.model.data.flow_title };
                            this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_AND_FLOW_NAME_SUB_TEXT_', interData);
                            this.setLinkToFlow();
                        } else {
                            this.stateToGoTo = this.AppStates.root_core_navigation_calendar;
                            this.stateParams = {dateTimeStart: this.model.data.session_date};
                            this.action = this.goToAssociatedState.bind(this);
                        }
                        break;
                    case 'session_canceled':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SESSION_CANCELED_', {client: this.model.data.client_name});
                        this.iconType = 'icon-hb-nx-calendar-line';
                        if (this.model.data.flow_id) {
                            const interData = { projectName: this.model.data.project_name, flowName: this.model.data.flow_title };
                            this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_AND_FLOW_NAME_SUB_TEXT_', interData);
                            this.setLinkToFlow();
                        } else {
                            this.stateToGoTo = this.AppStates.root_core_navigation_calendar;
                            this.stateParams = {dateTimeStart: this.model.data.session_date};
                            this.action = this.goToAssociatedState.bind(this);
                        }
                        break;
                    case 'flow_viewed':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FLOW_VIEWED_', {client: this.model.data.client_name, flowName: this.model.data.title});
                        this.iconType = 'icon-hb-file-sparks-24';
                        this.setLinkToFlow();
                        break;
                    case 'legacy_automation_exist_after_first_sf_sent':
                        this.title = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FIRST_FLOW_SENT_HAS_LEGACY_AUTOMATIONS_TITLE_');
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FIRST_FLOW_SENT_HAS_LEGACY_AUTOMATIONS_');
                        this.iconType = 'icon-hb-nx-automation';
                        this.setLinkToAutomations();
                        break;
                    case 'flow_submitted':
                        var flowTranslation = this.model.data.recompleted ? 'ACTIVITY_NOTIFICATIONS._FLOW_RESUBMITTED_' : 'ACTIVITY_NOTIFICATIONS._FLOW_SUBMITTED_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {client: this.model.data.client_name, flowName: this.model.data.title});
                        this.iconType = 'icon-hb-file-sparks-24';
                        this.setLinkToFlow();
                        break;
                    case 'public_flow_submitted':
                        var flowTranslation = 'ACTIVITY_NOTIFICATIONS._LEAD_FORM_SUBMITTED_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {client: this.model.data.client_name, flowName: this.model.data.title});
                        this.iconType = 'icon-hb-file-sparks-24';
                        this.setLinkToFlow();
                        break;
                    case 'flow_completed':
                        var flowTranslation = this.model.data.recompleted ? 'ACTIVITY_NOTIFICATIONS._FLOW_RECOMPLETED_' : 'ACTIVITY_NOTIFICATIONS._FLOW_COMPLETED_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {client: this.model.data.client_name, flowName: this.model.data.title});
                        this.iconType = 'icon-hb-file-sparks-24';
                        this.setLinkToFlow();
                        break;
                    case 'flow_service_selected':
                        var flowTranslation = this.model.data.price ? 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_' : 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_NO_PRICE_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {
                            flowTitle: this.model.data.title,
                            clientName: this.model.data.client_name,
                            price: this.model.data.price
                        });
                        this.iconType = 'icon-hb-dollar-list-24';
                        this.setLinkToFlow();
                        break;
                    case 'flow_automatically_created_by_selected_service':
                        var flowTranslation = this.model.data.price ? 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_' : 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_NO_PRICE_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {
                            flowTitle: this.model.data.title,
                            clientName: this.model.data.client_name,
                            price: this.model.data.price
                        });
                        this.iconType = 'icon-hb-dollar-list-24';
                        this.setLinkToFlow();
                        break;
                    case 'flow_service_selection_changes':
                        var flowTranslation = this.model.data.price ? 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_' : 'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_NO_PRICE_';
                        this.messageAsHtml = this.$translate.instant(flowTranslation, {
                            flowTitle: this.model.data.title,
                            clientName: this.model.data.client_name,
                            price: this.model.data.price
                        });
                        this.messageAsHtml += this.model.data.create_draft_from_services_selection ?
                            this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._INVOICE_FLOW_UPDATED_') : '';
                        this.iconType = 'icon-hb-nx-arrows-opposite';
                        this.setLinkToFlow();
                        break;
                    case 'project_task':
                        this.messageAsHtml = this.model.data.message;
                        this.setLinkToFeed();
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-tasks';
                        break;
                    case 'general_task':
                        this.messageAsHtml = this.model.data.message;
                        this.stateToGoTo = this.AppStates.root_core_navigation_tasks;
                        this.action = this.goToAssociatedState.bind(this);
                        this.iconType = 'icon-hb-nx-tasks';
                        break;
                    case 'flow_signature_collected':
                        this.title = this.$translate.instant(`WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COLLECTED_TITLE_`, {
                            flowTitle: this.model.data.title,
                            clientName: this.model.data.client_name
                        });

                        let signaturesLeft = this.model.data.client_signatures_left_count;
                        let members_ids = this.model.data.all_members_ids;
                        this.desc = '';
                        if (members_ids && members_ids.indexOf(this.currUser._id)) {
                            if (signaturesLeft > 1) {
                                this.desc = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COLLECTED_VENDOR_DESCRIPTION_PLURAL_', {
                                    signaturesLeftCount: signaturesLeft,
                                    clientName: this.model.data.client_name,
                                    flowTitle: this.model.data.title,
                                });
                            } else if (signaturesLeft === 1) {
                                this.desc = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COLLECTED_VENDOR_DESCRIPTION_SINGULAR_');
                            }
                        }

                        this.messageAsHtml = `${this.title} ${this.desc}`;
                        this.iconType = 'icon-hb-nx-signature';
                        this.stateParams = {flow_id: this.model.data.flow_id, action: 'responses'};
                        this.stateToGoTo = this.AppStates.root_core_oneFlow;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'flow_countersign_ready':
                        this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COUNTERSIGN_READY_TITLE_', {
                            flowTitle: this.model.data.title,
                            clientName: this.model.data.client_name
                        });

                        if(this.model.data.missing_member_sign_data.findIndex(({_id}) => _id === this.currUser._id) !== -1) {
                            this.desc = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COUNTERSIGN_READY_DESCRIPTION_');
                        } else {
                            this.desc = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._NOT_COUNTERSIGNING_MEMBER_COUNTERSIGN_READY_DESCRIPTION_', {
                                membersNames: this.model.data.missing_member_sign_data.map(({full_name}) => full_name).join(', ')
                            });
                        }

                        this.messageAsHtml = `${this.title} ${this.desc}`;
                        this.iconType = 'icon-hb-nx-signature';
                        this.stateParams = {flow_id: this.model.data.flow_id, action: 'responses'};
                        this.stateToGoTo = this.AppStates.root_core_oneFlow;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'flow_awaiting_countersign':
                        this.messageAsHtml = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._AWAITING_COUNTERSIGN_', {
                            flowTitle: this.model.data.title,
                            eventName: this.model.data.event_name
                        });
                        this.iconType = 'icon-hb-nx-signature';
                        this.stateParams = {flow_id: this.model.data.flow_id, action: 'responses'};
                        this.stateToGoTo = this.AppStates.root_core_oneFlow;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'flow_contract_complete':
                        this.messageAsHtml = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._CONTRACT_COMPLETED_', {
                            flowTitle: this.model.data.title,
                        });
                        this.iconType = 'icon-hb-nx-signature';
                        this.stateParams = {flow_id: this.model.data.flow_id, action: 'responses'};
                        this.stateToGoTo = this.AppStates.root_core_oneFlow;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'add_your_bank_account':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._ADD_YOUR_BANK_ACCOUNT_');
                        this.iconType = 'icon-hb-nx-bank';
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_companySettings_bankInfo;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'ask_email_verification':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._ASK_EMAIL_VERIFICATION_');
                        this.iconType = 'icon-hb-information';
                        this.action = this.openEmailVerificationModal.bind(this);
                        break;
                    case 'expired_credit_card':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._EXPIRED_CREDIT_CARD_');
                        this.iconType = 'icon-hb-nx-bank';
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_companySettings_subscription;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'account_setup_completed':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._ACCOUNT_SETUP_COMPLETED_');
                        this.iconType = 'icon-hb-information';
                        this.stateToGoTo = this.AppStates.root_core_navigation_templates;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'account_setup_failed':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._ACCOUNT_SETUP_FAILED_');
                        this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                        this.stateToGoTo = this.AppStates.root_core_accountSetup;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'payment_without_bank':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PAYMENT_WITHOUT_BANK_');
                        this.iconType = 'icon-hb-nx-bank';
                        this.stateToGoTo = this.AppStates.root_core_navigation_settings_companySettings_bankInfo;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'shared_flow_template':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SHARED_FLOW_TEMPLATE_', {
                            sender_full_name: this.model.data.sender_full_name,
                        });
                        this.iconType = 'icon-hb-nx-template-24';
                        this.setLinkToSharedTemplate();
                        break;
                    case 'shared_template_saved':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SAVED_SHARED_TEMPLATE_', {
                            flow_name: this.model.data.flow_name,
                        });
                        this.iconType = 'icon-hb-nx-template-24';
                        this.stateToGoTo = this.AppStates.root_core_navigation_my_templates;
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'session_scheduled_with_non_ws_member':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._SESSION_SCHEDULED_WITH_NON_WS_MEMBER_', {
                            client: this.model.data.client_name,
                        });
                        this.iconType = 'icon-hb-nx-calendar-star';
                        const interData = { projectName: this.model.data.project_name, flowName: this.model.data.flow_title };
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PROJECT_AND_FLOW_NAME_SUB_TEXT_', interData);
                        this.setLinkToWorkspace();
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'email_deliverability_issue_found':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._EMAIL_DELIVERABILITY_ISSUE_FOUND');
                        this.iconType = 'icon-hb-nx-email-exclamation';
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._EMAIL_DELIVERABILITY_ISSUE_FOUND_SUB_TEXT');
                        this.setLinkToCompanyIntegrations();
                        this.action = this.goToAssociatedState.bind(this);
                        break;
                    case 'john_branch_templates_imported':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._JOHN_BRANCH_TEMPLATES_IMPORTED_');
                        this.imagePath = 'app/notifications/John_Branch.png';
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._JOHN_BRANCH_TEMPLATES_IMPORTED_SUB_TEXT_');
                        this.stateToGoTo = this.AppStates.root_core_navigation_my_templates;
                        const folderId = this.model.data.folder_id;
                        this.stateParams = { redirectToFolderId: folderId };
                        this.action = () => {
                            if (folderId) {
                                this.UiPersistenceService.setUiPersistence(
                                    this.UiPersistenceService.keys.selectedTemplatePanel,
                                    {
                                        type: 'folder',
                                        value: folderId
                                    }
                                );
                                this.goToAssociatedState.bind(this)();
                            }
                        };
                        break;
                    case 'freelancers_union_template_imported':
                        this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FREELANCERS_UNION_TEMPLATE_IMPORTED_');
                        this.imagePath = 'app/partners/fu/fu_notification.png';
                        this.overrideSubText = this.$translate.instant('ACTIVITY_NOTIFICATIONS._FREELANCERS_UNION_TEMPLATE_IMPORTED_SUB_TEXT_');
                        this.setLinkToTemplate();
                        break;
                    case 'generic':

                        // we just show the message from the server without an action.
                        this.messageAsHtml = this.UIUtils.sanitize(this.model.data.message);

                        this.iconType = 'icon-hb-information';

                        // If we have an associated state
                        if (this.model.data.next_state) {
                            this.stateToGoTo = this.model.data.next_state;
                        }
                        else if (this.model.data.generic_type) {

                            // Although we support setting the next state on the notification data itself, its really not nice that the server
                            // well be familiar with our angular states.
                            switch (this.model.data.generic_type) {
                                case 'community_onboarding':
                                    this.stateToGoTo = this.AppStates.root_network_edit_profile;
                                    this.iconType = 'icon-hb-nx-smile';
                                    break;
                                case 'clients_csv_upload_success':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_contacts;
                                    this.iconType = 'icon-hb-nx-check-circle';
                                    break;
                                case 'clients_csv_upload_failed':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_contacts;
                                    this.iconType = 'icon-hb-nx-bell';
                                    break;
                                case 'contact_form_intro':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_templatesEditor;
                                    this.stateParams = {templateModelKey: 'contactForm'};
                                    this.iconType = 'icon-hb-nx-bell';
                                    break;
                                case 'zoom_integration_revoked':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_settings_company_integrations;
                                    this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                                    break;
                                case 'qb_integration_revoked':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_settings_companySettings_quickbooks;
                                    this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                                    break;
                                case 'update_bank_info':
                                    this.stateToGoTo = this.AppStates.root_core_navigation_settings_companySettings_bankInfo;
                                    this.iconType = 'icon-hb-nx-exclamation-mark-circle';
                                    break;
                            }
                        }

                        if (this.model.data.generic_type === 'product_onboarding') {
                            this.action = function() {
                                if (this.OnboardingService.isUserSubscribed()) {
                                    this.SetupGuideService.openWebinarPage();
                                } else {
                                    this.SetupGuideService.openTrialerWebinarPage();
                                }
                            }.bind(this);

                        } else {
                            this.action = this.goToAssociatedState.bind(this);
                        }

                        break;
                }

                // Additional parsing. $sanitize strips out tags like <style> or <script>
                // but not HTML comments embedded in the text.
                this.messageAsHtml = this.UIUtils.stripHTMLComments(this.messageAsHtml);
            },

            reformatDate: function reformatDate() {
                var date = this.model.last_version_created_at ? this.model.last_version_created_at : this.model.created_at;
                this.elapsedTimeInStep = this.moment(date).fromNow();
            },

            goToAssociatedState: function goToAssociatedState() {
                if (this.stateToGoTo !== null) {
                    this.$state.go(this.stateToGoTo, this.stateParams, this.stateOptions);
                }
            },

            openEmailVerificationModal: function openEmailVerificationModal() {
                this.ReactModalService.openEmailVerificationModal();
            },

            doActivityAction: function doActivityAction() {
                this.markAsRead();
                if(this.currUser.companyHasSubscription()) {
                    this.WebPNService.subscribe();
                }

                this.toggleNotifications(false);
                this.showing = false;

                if(this.action !== null) {
                    this.action();
                }
            },

            setLinkToFlow: function setLinkToFlow() {
                this.action = this.goToAssociatedState.bind(this);
                this.stateToGoTo = this.AppStates.root_core_oneFlow;
                this.stateParams = {flow_id: this.model.data.flow_id, action: 'responses'};
            },

            setLinkToTemplate: function setLinkToTemplate() {
                this.action = this.goToAssociatedState.bind(this);
                this.stateToGoTo = this.AppStates.root_core_oneFlow;
                this.stateParams = {flow_id: this.model.data.template_id, action: 'responses'};
            },

            setLinkToSharedTemplate: function setLinkToSharedTemplate(){
                this.action = this.goToAssociatedState.bind(this);
                this.stateToGoTo = this.AppStates.root_core_navigation_my_templates_preview;
                this.stateOptions = {reload: true};
                this.stateParams = {'preview-shared': this.model.data.metadata_id};
            },

            setLinkToAutomations: function setLinkToAutomations() {
                this.action = function() {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.automations_update_notification,
                        {flows_enabled: true, source: 'notification_center'});
                    this.goToAssociatedState();
                }.bind(this);
                this.stateToGoTo = this.AppStates.root_core_navigation_automations;
                this.stateParams = {};
            },

            setLinkToFile: function setLinkToFile() {
                this.stateToGoTo = this.AppStates.root_core_workspaceFile;
                this.stateParams = {
                    workspace_file_id: this.model.data.workspace_file_id
                };
            },

            setLinkToMeeting: function setLinkToMeeting() {
                this.stateToGoTo = this.AppStates.root_core_navigation_calendar;

                this.stateParams = {
                    calendar_item_id: this.model.data._id,
                    dateTimeStart: this.model.data.date_time_start
                };
            },

            setLinkToFeed: function setLinkToFeed() {
                this.stateToGoTo = this.AppStates.root_core_navigation_event_workspace_feed;
                this.stateParams = {
                    event_id: this.model.data.event_id,
                    workspace_id: this.model.data.workspace_id
                };
            },

            setLinkToContact: function setLinkToContact(clientEmail, isGiftCArd) {
                this.stateToGoTo = this.AppStates.root_core_navigation_contacts;
                this.stateParams = {
                    search: clientEmail,
                    giftCard: isGiftCArd
                };
            },

            setLinkToFiles: function setLinkToFeed() {
                this.stateToGoTo = this.AppStates.root_core_navigation_event_workspace_files;
                this.stateParams = {
                    event_id: this.model.data.event_id,
                    workspace_id: this.model.data.workspace_id
                };
            },

            setLinkToBrochureFile: function setLinkToBrochureFile() {
                this.stateToGoTo = this.AppStates.root_core_workspaceFile_brochure;
                this.stateParams = {
                    workspace_file_id: this.model.data.workspace_file_id
                };
            },

            setLinkToOpportunity: function setLinkToOpportunity() {
                this.stateToGoTo = this.AppStates.root_network_opportunities;
                this.stateOptions = {reload: true};
                if (this.model.data.opportunity_ids) {
                    this.stateParams = {
                        opportunity_ids: this.model.data.opportunity_ids.join(","),
                        opportunity_id: undefined
                    };
                } else if (this.model.data.opportunity_id) {
                    this.stateParams = {
                        opportunity_id: this.model.data.opportunity_id,
                        opportunity_ids: undefined
                    };
                }
            },
            setLinkToWorkspace: function setLinkToWorkspace() {
                this.stateToGoTo = this.AppStates.root_core_navigation_event_workspace_feed;
                this.stateOptions = {reload: true};
                this.stateParams = {
                    workspace_id: this.model.data.workspace_id,
                    event_id: this.model.data.event_id
                };
            },
            setLinkToCompanyIntegrations: function setLinkToCompanyIntegrations() {
                this.stateToGoTo = this.AppStates.root_core_navigation_settings_company_integrations;
            },
            setPaymentReceivedMessage: function setPaymentReceivedMessage(interpulationParams, tipPaid) {
                if (tipPaid) {
                    interpulationParams.paymentGratuity = tipPaid;
                    this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PAYMENT_RECEIVED_WITH_GRATUITY_', interpulationParams);
                } else {
                    this.messageAsHtml = this.$translate.instant('ACTIVITY_NOTIFICATIONS._PAYMENT_RECEIVED_WITHOUT_GRATUITY_', interpulationParams);
                }
            },

            startInstantDepositFlow: function startInstantDepositFlow() {
                var objectId, isFlowPayment;
                if (this.model.data.workspace_file_id) {
                    objectId = this.model.data.workspace_file_id;
                    isFlowPayment = false;
                } else {
                    objectId = this.model.data.flow_id;
                    isFlowPayment = true;
                }

                if(!this.currUser.myDebitCard()) {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.open_debit_card_modal,
                        {source: 'activity notification'});
                    // open add debit card modal
                    this.ModalService.openAddDebitCardModal('activity notification').then(
                        function onSuccess(){
                            if(this.currUser.myDebitCard()) {
                                this.ModalService.openInstantDepositModal('debit card modal', objectId, this.model.data.payment_invoice, isFlowPayment);
                            }
                        }.bind(this),
                        function dismissed(){
                            this.goToAssociatedState();
                        }.bind(this)
                    ).catch(
                        function onFailure(){
                        }.bind(this));
                } else {
                    this.ModalService.openInstantDepositModal('activity notification', objectId, this.model.data.payment_invoice, isFlowPayment).then(
                        function onSuccess(){

                        }.bind(this),
                        function dismissed(){
                            this.goToAssociatedState();
                        }.bind(this)
                    ).catch(
                        function onFailure(){
                        }.bind(this));
                }
            },

            markAsRead: function markAsRead() {
                this.currUser.markActivityNotificationAsSeen(this.model._id);
                this.showMarkAsReadEmptyState = true;
            },

            instantPayoutDateRelevant: function instantPayoutDateRelevant(chargeDate) {
                // seconds_left_for_instant_depositing is not a real indication. We remove eligible mark on notification center after 24 hours
                return this.moment(chargeDate).add(1, 'days').isAfter(this.moment());
            }

        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/activity_notifications/activity_notification_directive_template.html',
            controller: ActivityNotificationDirectiveController,
            controllerAs: 'activityVm',
            bindToController: true,
            scope: {
                model: '=model',
                showing: '=showing',
                showMarkAsReadEmptyState: '=showMarkAsReadEmptyState',
                toggleNotifications: '&'
            }
        };
    };

}());
