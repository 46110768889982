(function () {
    'use strict';

    // @ngInject
    function QuickbooksRedirectControllerCtor($scope, $injector, $q, InitialAppLoadParamsService, UsersManager, CompaniesManager,
                                              QuickbooksManager, $window, AppStates, PopupMessageService, AnalyticsService,) {
        this.constructor.$super.call(this, $scope, $injector);

        this.__objectType = 'QuickbooksRedirectController';
        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        InitialAppLoadParamsService.cleanParamsFromView(['code']);

        this.$window = $window;
        this.AppStates = AppStates;
        this.$q = $q;
        this.UsersManager = UsersManager;
        this.company = CompaniesManager.getCurrCompany();
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;

        if(queryStringParams.state && queryStringParams.code && queryStringParams.realmId) {
            QuickbooksManager.setQuickbooksIntegration(queryStringParams.state, queryStringParams.code, queryStringParams.realmId).then(function success() {
                this.goToState(this.AppStates.root_core_navigation_settings_companySettings_quickbooks);
            }.bind(this)).catch(function fail(resp) {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.quickbooks_integration_error, resp);

                if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBUserError") {

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function success() {
                        this.redirectToIntegrationsPage();
                    }.bind(this));
                }
                else {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function success() {
                        this.redirectToIntegrationsPage();
                    }.bind(this));
                }
            }.bind(this));
        } else if (queryStringParams.error) {
                // error "access denied" is returned from quickbooks if the user clicks "no thanks" and returns to honeybook without completing the connection
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.quickbooks_integration_error, {error: queryStringParams.error});
                this.redirectToIntegrationsPage();
        }
    }

    Controllers.QuickbooksRedirectController = Class(Controllers.BaseController, {
        constructor: QuickbooksRedirectControllerCtor,
        redirectToIntegrationsPage: function redirectToIntegrationsPage() {
            this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
        }
    });
}());