/*
 Add additional <hb-tabs-pane title="Tab"> for extra tabs.
 --------------------------------------------------------

 <hb-tabs>
     <hb-tabs-pane title="Tab 1">
        content 1
     </hb-tabs-pane>

     <hb-tabs-pane title="tab 2">
        content 2
     </hb-tabs-pane>
 </hb-tabs>

*/



Components.Tabs = {
    transclude: true,
    // @ngInject
    controller: function TabsController(){
        this.panes = [];

        this.select = function select( pane ) {
            angular.forEach( this.panes, function( pane ) {
                pane.selected = false;
            });

            pane.selected = true;
        };

        this.addPane = function addPane( pane ) {
            this.panes.push( pane );

            if ( this.panes.length === 1 || pane.selectOnLoad ) {
                this.select( pane );
            }
        };
    },
    name: 'hbTabs',
    templateUrl: 'angular/app/modules/common/ui_components/tabs/tabs_component_template.html',
};