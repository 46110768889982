(function () {
    "use strict";

    // @ngInject
    function DisputesServiceCtor($q, moment, ModelFactory, EventsManager, UsersManager, MalkutStripeManager, WorkspaceFileService, PopupMessageService) {

        this.$q = $q;
        this.moment = moment;
        this.ModelFactory = ModelFactory;
        this.UsersManager = UsersManager;
        this.MalkutStripeManager = MalkutStripeManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.PopupMessageService = PopupMessageService;
    }

    Services.DisputesService = Class(function () {
        return {
            constructor: DisputesServiceCtor,
            sendEmailReminder: function (dispute, emailNumber) {
                const defer = this.$q.defer();
                this.MalkutStripeManager.prepareDisputedPaymentReminder(dispute._id, emailNumber)
                    .then(function success(res) {
                        var fake_users = [{email: dispute.vendor_email, full_name: dispute.vendor_name}];
                        var config = {
                            editorType: "malkut dispute reminder",
                            title: 'SEND REMINDER #' + emailNumber
                        };

                        var options  = {
                            users: fake_users,
                            subject: res.data.subject,
                            body: res.data.html_body,
                            generalFiles: res.data.file_attachments,
                            imageFiles: res.data.image_attachments,
                            senderFunction: function senderFunction(props) {

                                var user = props.users;
                                var subject = props.subject;
                                var htmlBody = props.body;

                                var mailData = {
                                    reminder_number: emailNumber,
                                    html_body: htmlBody,
                                    subject: subject
                                };
                                return this.MalkutStripeManager.sendDisputedPaymentReminder(mailData, dispute)
                                    .then(function (res) {
                                        if (res && res.data && res.data.error_message) {
                                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, res.data.error_message);
                                            defer.reject();
                                        } else {
                                            defer.resolve(res.data);
                                        }
                                    }.bind(this))
                                    .catch(function (res) {
                                        if (res && res.data && res.data.error_message) {
                                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, res.data.error_message);
                                        } else {
                                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                                        }
                                        defer.reject();
                                    }.bind(this));
                            }.bind(this),
                            config: config,
                            showScheduleOption: false
                        };

                        this.WorkspaceFileService.showEmailEditorModal(options);
                    }.bind(this))
                    .catch(function fail(res) {
                        if (res && res.data && res.data.error_message) {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, res.data.error_message);
                        } else {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                        }
                    }.bind(this)).finally(function finallyHandler() {
                        this.sendingReminder = false;
                    }.bind(this)
                );
                return defer.promise;
            },
        };
    });
}());
