(function () {
    'use strict';

    // @ngInject
    function DatadogLOGSServiceCtor($window) {
        this.$window = $window;

        this.logToDatadog = function (logLevel, message, context = {}, error = null) {
            const datadog = this.$window && this.$window.DD_LOGS;

            if (!datadog || !datadog.logger) {
                return;
            }

            datadog.logger[type](message, context, error);
        }.bind(this);
    }

    Services.DatadogLOGSService = Class(function () {
        return {
            constructor: DatadogLOGSServiceCtor,

            identifyUser: function(userData) {
                if (this.$window.DD_LOGS && angular.isFunction(this.$window.DD_LOGS.setUser)) {
                    this.$window.DD_LOGS.setUser(userData);
                }
            },

            logDebug: function (message, context = {}, error = null) {
                this.logToDatadog('debug', message, context, error);
            },

            logInfo: function (message, context = {}, error = null) {
                this.logToDatadog('info', message, context, error);
            },

            logWarn: function (message, context = {}, error = null) {
                this.logToDatadog('warn', message, context, error);
            },

            logError: function (message, context = {}, error = null) {
                this.logToDatadog('error', message, context, error);
            }
        };
    });

}());
