/**
 * The hb-popover directive
 *
 * This directive pops message over an html element. it may have persistency support by passing persistency key.
 *
 * parameters:
 * contentTitle: Optional. text title to be shown
 * body: Optional. text body to be shown. can be html.
 * showCloseButton : Optional. boolean. hide/show the 'x' button
 * ctaText: Optional. optional: text to be shown as call to action button. e.g - 'Got it!'
 * showArrow : Optional .boolean, rather show popover arrow or not.
 * arrowPosition : Optional. string: top/bottom/left/right
 * isVisible : boolean, let you hide/show the popover programmatically
 * analyticsEventName: Optional. string. the analytics event to track if the popover is shown. if not passed, no analytics.
 * disableAnimation: Optional. boolean to disable popover animation.
 * onClose : Optional. function to run on popover close
 * showCtaAsButton: Optional. make the CTA a button
 * customAnimationClass: Optional. pass custom animation class over the default
 *
 */

(function () {
    'use strict';

    Directives.PopoverDirective = function PopoverDirective() {

        // @ngInject
        function PopoverDirectiveControllerFunc($scope, $injector, AnalyticsService) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'PopoverDirectiveController';
            this.AnalyticsService = AnalyticsService;
            if (this.analyticsEventName) {
                // track that popover is visible
                $scope.$watch('popoverVm.isVisible', function (newVisibleValue) {
                    if (newVisibleValue) {
                        this.AnalyticsService.track(this, 'visible ' + this.analyticsEventName);
                    }
                }.bind(this));
            }
        }


        var PopoverDirectiveController = Class(Controllers.BaseController, {

            constructor: PopoverDirectiveControllerFunc,

            closePopover : function closePopover() {
                this.isVisible = false;
                this.AnalyticsService.track(this, 'click: closed ' + this.analyticsEventName);

                if (this.onClose) {
                    this.onClose();
                }
            },

            getPosition : function getPosition(position) {
                return (position ? position.toLowerCase() : "");
            }


        });

        return {
            scope: {
                contentTitle: '@',
                body: '@',
                showCloseButton : '=',
                ctaText: '@',
                showArrow : '=',
                arrowPosition : '@',
                isVisible : '=',
                analyticsEventName: '@',
                disableAnimation : '=',
                onClose: '&?',
                showCtaAsButton: '=',
                customAnimationClass: '@',
                showOverlay: '<'
            },
            transclude: {
                'content': '?popoverContent'
            },
            templateUrl: 'angular/app/modules/common/ui_components/popover/popover_directive_template.html',
            controller: PopoverDirectiveController,
            controllerAs: 'popoverVm',
            bindToController: true
        };
    };


}());
