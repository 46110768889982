// @ngInject

Models.FeedItemModel = function FeedItemModel(Enums, BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'FeedItemModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {activity: 'ActivityModel'};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
        },

        isFlow: function isFlow() {
            return this.data && this.data.object_type === 'flow';
        },

        isSystemGenerated: function isSystemGenerated() {
            return this.data && !!this.data.system_generated;
        },

        isPreferencesAutomationsGenerated: function isPreferencesAutomationsGenerated() {
            return this.data && !!this.data.preferences_automations_generated;
        },

        isWorkflowGenerated: function isWorkflowGenerated() {
            return !!this.from_workflow_title;
        },

        isOOOReply: function isOOOReply () {
          return this.type === 'ooo_auto_reply';
        },

        isEmailType: function isEmailType(type) {
            return type && this.data && this.data.email_type_cd === Enums.EmailTypesCd[Enums.EmailTypes[type]];
        },

        deleteItem: function deleteItem() {
            return this.__parent.deleteItem(this);
        },

        restoreFlow: function restoreFlow() {
          return this.__parent.restoreFlow(this);
        }
    });

};
