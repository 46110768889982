/**
 * Created by Erez on 27/10/2016.
 */

Directives.SocialAccountsDirective = function SocialAccountsDirective() {

    // @ngInject
    function SocialAccountsDirectiveControllerFunc($scope, $injector, PopupMessageService, UsersManager, Enums, _,
                                                   ServiceLazyLoaderService, InstagramAPIService, AnalyticsService,
                                                   $translate, FeaturesService, ReactModalService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SocialAccountsDirectiveController';

        this.currentUser = UsersManager.getCurrUser();
        this.UsersManager = UsersManager;
        this.Enums = Enums;
        this.$scope = $scope;
        this.$translate = $translate;
        this.socialAccounts = [];
        this.PopupMessageService = PopupMessageService;
        this.FacebookService = ServiceLazyLoaderService.get('FacebookService');
        this.InstagramAPIService = InstagramAPIService;
        this.AnalyticsService = AnalyticsService;
        this.FeaturesService = FeaturesService;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this._ = _;

        if (this.currentUser.isVendor()) {
            this.initSocialAccounts();
        }

        this.showZapierIntegration = this.currentUser.isVendor();

        this.restrictedFeature = this.FeatureRestrictionService.features.socialIntegrations;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('socialAccountsVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });
    }


    var SocialAccountsDirectiveController = Class(Controllers.BaseController, {
        constructor: SocialAccountsDirectiveControllerFunc,

        initSocialAccounts: function initSocialAccounts() {
            var facebookSocialAccount = {};
            facebookSocialAccount.type = this.Enums.SocialNetworkTypes.facebook;
            facebookSocialAccount.logoSrc = 'https://res.cloudinary.com/honeybook/image/upload/w_25/v1479041437/facebook-logo.png';
            facebookSocialAccount.connectSocialNetwork = this.connectFacebook.bind(this);
            facebookSocialAccount.disconnectSocialNetwork = this.disconnectFacebook.bind(this);
            facebookSocialAccount.isConnected = this.isConnectedToSocialNetwork(this.Enums.SocialNetworkTypes.facebook);
            facebookSocialAccount.connectedAs = this.getConnectedAs(this.Enums.SocialNetworkTypes.facebook);
            facebookSocialAccount.showLoading = false;
            facebookSocialAccount.description = this.$translate.instant('ACCOUNT.LABELS._SOCIAL_ACCOUNT_DETAILS_FACEBOOK');
            this.socialAccounts.push(facebookSocialAccount);
        },

        showZapierApiKey: function showZapierApiKey() {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.zapier_show_key,
                { current_plan: this.currentUser.getPlanType() }
            );

            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            if (this.apiKey) {
                this.showApiKey = true;
            } else {
                this.showApiKeyLoader = true;
                this.UsersManager.getZapierApiKey(this.currentUser)
                    .then(function getApiKeySuccess(res) {
                        this.showApiKey = true;
                        this.showApiKeyLoader = false;
                        this.apiKey = res.data.api_key;
                    }.bind(this));
            }

        },

        doneCopyToClipboard: function doneCopyToClipboard() {
            this.showApiKey = false;
        },

        failedCopyToClipboard: function failedCopyToClipboard() {
            this.PopupMessageService.showErrorAlert('WORKSPACE.ACCOUNT.ERRORS._FAILED_COPY_TO_CLIPBOARD_');
        },

        isConnectedToSocialNetwork: function isConnectedToSocialNetwork(socialNetworkType) {
            var account = this._.findWhere(this.currentUser.social_network_accounts, {social_network_type: socialNetworkType});
            return !!account;
        },

        getConnectedAs: function getConnectedAs(socialNetworkType) {
            var account = this._.findWhere(this.currentUser.social_network_accounts, {social_network_type: socialNetworkType});
            var connectedAs = null;
            if (account) {
                connectedAs = account.connected_as;
            }
            return connectedAs;
        },

        connectInstagram: function connectInstagram() {
            this.InstagramAPIService.connect();
        },

        disconnectInstagram: function disconnectInstagram() {
            this.InstagramAPIService.disconnect(this.currentUser);
        },

        connectFacebook: function connectFacebook() {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.connect_facebook,
                { current_plan: this.currentUser.getPlanType() }
            );
            
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            var facebookAccountData = this._.findWhere(this.socialAccounts, {type: this.Enums.SocialNetworkTypes.facebook});
            facebookAccountData.showLoading = true;
            var analyticsData = {
                social_network_type: this.Enums.SocialNetworkTypes.facebook
            };
            this.FacebookService.loginToFacebook('email').then(
                function success(facebookParams) {
                    this.UsersManager.connectSocialAccount(this.currentUser, this.Enums.SocialNetworkTypes.facebook, facebookParams.auth_token).then(
                        function success() {
                            facebookAccountData.isConnected = this.isConnectedToSocialNetwork(this.Enums.SocialNetworkTypes.facebook);
                            facebookAccountData.connectedAs = this.getConnectedAs(this.Enums.SocialNetworkTypes.facebook);
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connecting_social_network, analyticsData);
                        }.bind(this),
                        function error(resp) {
                            // show error message
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, analyticsData);
                            this.$translate('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: 'Facebook'}).then(function translateResolved(errorMessage) {
                                if (resp.data && resp.data.error_type && resp.data.error_type === 'HBUserError') {
                                    errorMessage = errorMessage + ': ' + resp.data.error_message;
                                }
                                this.PopupMessageService.showErrorAlert(errorMessage);
                            }.bind(this));
                        }.bind(this)
                    ).finally(function () {
                        facebookAccountData.showLoading = false;
                    }.bind(this));
                }.bind(this),
                function error(resp) {
                    // this error occurs because of facebook api is not synced. ignore it.
                    if (resp.error_subcode !== 467) {
                        this.$translate('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: 'Facebook'}).then(function translateResolved(errorMessage) {
                            this.PopupMessageService.showErrorAlert(errorMessage);
                        }.bind(this));
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, analyticsData);
                    }
                    facebookAccountData.showLoading = false;
                }.bind(this)
            );
        },

        disconnectFacebook: function disconnectFacebook() {
            this.FacebookService.logoutOfFacebook();
        },

        disconnectSocialNetwork: function disconnectSocialNetwork(socialNetworkType) {
            var analyticsData = {
                social_network_type: socialNetworkType,
            };
            var socialNetworkAccount = this._.findWhere(this.socialAccounts, {type: socialNetworkType});
            socialNetworkAccount.showLoading = true;
            this.UsersManager.disconnectSocialAccount(this.currentUser, socialNetworkType).then(
                function success() {
                    socialNetworkAccount.isConnected = false;
                    socialNetworkAccount.connectedAs = null;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.disconnecting_social_network, analyticsData);
                    socialNetworkAccount.disconnectSocialNetwork();
                }.bind(this),

                function error(resp) {
                    // show error message
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.disconnecting_social_network, resp, analyticsData);
                    this.$translate('ACCOUNT.ERRORS._DISCONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: socialNetworkType}).then(function translateResolved(errorMessage) {
                        this.PopupMessageService.showErrorAlert(errorMessage);
                    }.bind(this));
                }.bind(this)
            ).finally(function () {
                socialNetworkAccount.showLoading = false;
            }.bind(this));
        }
    });

    return {
        scope: {},

        templateUrl: 'angular/app/modules/core/features/settings/account/social_accounts/social_accounts_template.html',
        controller: SocialAccountsDirectiveController,
        controllerAs: 'socialAccountsVm',
        bindToController: true
    };
};
