(function () {
    "use strict";

    class VideoTutorialController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $, $sce, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'VideoTutorialController';
            this.$translate = $translate;
            this.$ = $;
            this.$sce = $sce;
            this.AnalyticsService = AnalyticsService;
        }

        $onInit() {
            this.modalContent = {
                dynamic_contact_form_intro: {
                    analyticsGotoStage: 'new cf intro video go to stage',
                    analyticsSubtitleAction: 'new cf intro video watch this later',
                    title: "CONTACT_FORM.DYNAMIC_TUTORIAL._TITLE_",
                    subtitle: "CONTACT_FORM.DYNAMIC_TUTORIAL._SUBTITLE_",
                    subtitleLink: "CONTACT_FORM.DYNAMIC_TUTORIAL._SUBTITLE_LINK_",
                    subtitleLinkAction: 'close',
                    steps: [
                        {
                            videoTag: "app/contactform/videos/dynamic/onboarding-demoV2b-vid1",
                            elementPlayerId: "cf_dynamic_intro_1",
                        },
                        {
                            videoTag: "app/contactform/videos/dynamic/onboarding-demoV2b-vid2",
                            elementPlayerId: "cf_dynamic_intro_2",
                        },
                        {
                            videoTag: "app/contactform/videos/dynamic/onboarding-demoV2b-vid3",
                            elementPlayerId: "cf_dynamic_intro_3",
                        },
                        {
                            videoTag: "app/contactform/videos/dynamic/onboarding-demoV2b-vid4",
                            elementPlayerId: "cf_dynamic_intro_4",
                        },
                        {
                            videoTag: "app/contactform/videos/dynamic/onboarding-demoV2b-vid5",
                            elementPlayerId: "cf_dynamic_intro_5",
                        }
                    ]
                },
            };

            this.curContent = this.modalContent[this.contentType];
            this.currVideos = this.curContent.steps;
            this.nSteps = this.currVideos.length;
            this.currStepIndex = 0;
            this.moveToStep(0, true);
        }

        replayVideo() {
            let currVideo = document.getElementById(this.currVideos[this.currStepIndex].elementPlayerId);
            if (currVideo) {
                currVideo.pause();
                currVideo.currentTime = '0';
                currVideo.play();
            }
        }

        moveToStep(stepIndex, isSkipAnalytics) {
            if (!isSkipAnalytics) {
                this.AnalyticsService.trackClick(this, this.curContent.analyticsGotoStage, {stage: stepIndex + 1});
            }
            if (stepIndex === this.nSteps) {
                this.onClose();
            } else {
                this.currStepIndex = stepIndex;
                this.currStep = this.currVideos[this.currStepIndex];
                this.replayVideo();
                this.setMainButtonText();

                if (this.onMoveToStep) {
                    this.onMoveToStep({nStep: stepIndex + 1});
                }
            }
        }

        setMainButtonText() {
            if (this.currStepIndex < this.nSteps - 1) {
                this.mainButtonText = this.$translate.instant('FREQUENT_BUTTONS._NEXT_');
            } else {
                this.mainButtonText = this.$translate.instant('FREQUENT_BUTTONS._DONE_');
            }
            this.mainButtonText += ` ${this.currStepIndex + 1}/${this.nSteps}`;
        }


        generateVideoTag(step) {
            if (step && step.videoTag) {
                let currVideoHtml = this.$.cloudinary.videoTag(step.videoTag,{
                    autoplay: true,
                    secure: true,
                    muted:true,
                    poster: false,
                    playsinline: true,
                    fallback_content: "Your browser does not support HTML5 video tags",
                    id: step.elementPlayerId }).toHtml();
                return this.$sce.trustAsHtml(currVideoHtml);
            } else {
                return null;
            }
        }
        onClickSubtitleLink() {
            if (this.curContent.analyticsSubtitleAction) {
                this.AnalyticsService.trackClick(this, this.curContent.analyticsSubtitleAction);
            }
            switch (this.curContent.subtitleLinkAction) {
                case 'close':
                    this.onClose();
                    break;
            }
        }


    }

    Components.VideoTutorial = {
        bindings: {
            contentType: '@',
            onClose: '&',
            onMoveToStep: '&?',
        },
        controller: VideoTutorialController,
        name: 'hbVideoTutorial',
        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/video-tutorial/video-tutorial-component.html',
    };

}());