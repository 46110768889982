(function () {
    "use strict";

    // @ngInject
    function VideoCarouselModalControlleCtor($scope, $injector, $modalInstance, $state, EventService,
                                              AppStates, DeviceService, $window, $q, $sce, $, $timeout, modalContentType, $translate,
                                              onConfirmCallback, onCancelCallback, AnalyticsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'VideoCarouselModalController';

        this.modalInstance = $modalInstance;
        this.$state = $state;
        this.EventService = EventService;
        this.AppStates = AppStates;
        this.$window = $window;
        this.$q = $q;
        this.$sce = $sce;
        this.$ = $;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.onConfirmCallback = onConfirmCallback;
        this.onCancelCallback = onCancelCallback;
        this.AnalyticsService = AnalyticsService;

        this.isMobile = DeviceService.nxSmallBreakpoint();

        this.currStepIndex = 0;
        this.videoStepWidth = 640;
        if (this.isMobile) {
            this.videoStepWidth = 100;
        }

        this.modalContent = {
          dynamic_contact_form_teaser: {
            title: "CONTACT_FORM.INTRO_MODAL._TITLE_",
            bottomText: this.$translate.instant(
              "CONTACT_FORM.INTRO_MODAL._DISCLAIMER_"
            ),
            bottomTextLastStep: this.$translate.instant(
              "CONTACT_FORM.INTRO_MODAL._DISCLAIMER_LAST_STEP_"
            ),
              analyticsGotoStage: 'old cf go to stage',
              analyticsOnConfirm: 'old cf new intro convert',
              analyticsOnCancel :'old cf new intro not now',
            videos: [
              {
                videoTag:
                  "app/contactform/videos/CF-prop1-add_images",
                title: "CONTACT_FORM.INTRO_MODAL._SUBTITLE_1_",
                elementPlayerId: "cf_dynamic_intro_1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/contactform/videos/CF-prop2-layouts",
                title: "CONTACT_FORM.INTRO_MODAL._SUBTITLE_2_",
                elementPlayerId: "cf_dynamic_intro_2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/contactform/videos/CF-prop3-fonts-colors",
                title: "CONTACT_FORM.INTRO_MODAL._SUBTITLE_3_",
                elementPlayerId: "cf_dynamic_intro_3",
                primaryCtaText: "CONTACT_FORM.INTRO_MODAL._CTA_CONFIRM_",
                secondaryCtaText: "CONTACT_FORM.INTRO_MODAL._CTA_CANCEL_",
              }
            ]
          },
          clientbot_inquiries: {
            title: "ONBOARDING.BOT.VIDEO_MODAL.INQUIRIES._TITLE_",
            videos: [
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/inquiries/01_Create_form",
                title: "ONBOARDING.BOT.VIDEO_MODAL.INQUIRIES._VIDEO_1_TITLE_",
                elementPlayerId: "clientbot_inquiries1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/inquiries/02_Publish_form",
                title: "ONBOARDING.BOT.VIDEO_MODAL.INQUIRIES._VIDEO_2_TITLE_",
                elementPlayerId: "clientbot_inquiries2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/inquiries/03_Get_inquiry",
                title: "ONBOARDING.BOT.VIDEO_MODAL.INQUIRIES._VIDEO_3_TITLE_",
                elementPlayerId: "clientbot_inquiries3",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_DONE_"
              }
            ]
          },

          clientbot_brochures: {
            title: "ONBOARDING.BOT.VIDEO_MODAL.BROCHURES._TITLE_",
            videos: [
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/brochures/01_Create_brochure",
                title: "ONBOARDING.BOT.VIDEO_MODAL.BROCHURES._VIDEO_1_TITLE_",
                elementPlayerId: "clientbot_brochures1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/brochures/02_Customize_brochure",
                title: "ONBOARDING.BOT.VIDEO_MODAL.BROCHURES._VIDEO_2_TITLE_",
                elementPlayerId: "clientbot_brochures2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/brochures/03_Attach_brochure",
                title: "ONBOARDING.BOT.VIDEO_MODAL.BROCHURES._VIDEO_3_TITLE_",
                elementPlayerId: "clientbot_brochures3",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_DONE_"
              }
            ]
          },

          clientbot_packages: {
            title: "ONBOARDING.BOT.VIDEO_MODAL.PACKAGES._TITLE_",
            videos: [
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/client_picks/01_Client_receives_email",
                title: "ONBOARDING.BOT.VIDEO_MODAL.PACKAGES._VIDEO_1_TITLE_",
                elementPlayerId: "clientbot_packages1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/client_picks/02_Client_picks",
                title: "ONBOARDING.BOT.VIDEO_MODAL.PACKAGES._VIDEO_2_TITLE_",
                elementPlayerId: "clientbot_packages2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_DONE_"
              }
            ]
          },

          clientbot_proposals: {
            title: "ONBOARDING.BOT.VIDEO_MODAL.PROPOSALS._TITLE_",
            videos: [
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/proposals/01_Contract_and_Payment",
                title: "ONBOARDING.BOT.VIDEO_MODAL.PROPOSALS._VIDEO_1_TITLE_",
                elementPlayerId: "clientbot_proposals1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/proposals/02_Sign",
                title: "ONBOARDING.BOT.VIDEO_MODAL.PROPOSALS._VIDEO_2_TITLE_",
                elementPlayerId: "clientbot_proposals2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/proposals/03_Pay",
                title: "ONBOARDING.BOT.VIDEO_MODAL.PROPOSALS._VIDEO_3_TITLE_",
                elementPlayerId: "clientbot_proposals3",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_DONE_"
              }
            ]
          },
          clientbot_get_paid: {
            title: "ONBOARDING.BOT.VIDEO_MODAL.GET_PAID._TITLE_",
            videos: [
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/get_paid/01_Set_up_bank_account",
                title: "ONBOARDING.BOT.VIDEO_MODAL.GET_PAID._VIDEO_1_TITLE_",
                elementPlayerId: "clientbot_more1",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/get_paid/02_CC_and_Bank",
                title: "ONBOARDING.BOT.VIDEO_MODAL.GET_PAID._VIDEO_2_TITLE_",
                elementPlayerId: "clientbot_more2",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_NEXT_"
              },
              {
                videoTag:
                  "app/modules/core/features/clientbot/videos/get_paid/03_Cha-ching",
                title: "ONBOARDING.BOT.VIDEO_MODAL.GET_PAID._VIDEO_3_TITLE_",
                elementPlayerId: "clientbot_more3",
                primaryCtaText: "ONBOARDING.BOT.VIDEO_MODAL._CTA_DONE_"
              }
            ]
          }
        };

        var _modalContentType = modalContentType || 'clientbot_inquiries';
        this.currContent = this.modalContent[_modalContentType];
        this.currTitle = this.modalContent[_modalContentType].title;
        this.currVideos = this.modalContent[_modalContentType].videos;
        this.bottomText = this.modalContent[_modalContentType].bottomText;
        this.bottomTextLastStep = this.modalContent[_modalContentType].bottomTextLastStep || this.bottomText;
        this.isVerticalCTA = this.currContent.finalCTA && this.currContent.finalCTA.layout === 'vertical';
        this.isLastStep = this.currStepIndex === this.currVideos.length - 1;

    }

    Controllers.VideoCarouselModalController = Class(Controllers.BaseController, {

        constructor: VideoCarouselModalControlleCtor,

        getLeft: function getLeft(currStepIndex) {

            var mobilePattern = '[num]vw';
            var desktopPattern = '[num]px';
            var currLeft = 0;

            if (this.isMobile) {
                currLeft = mobilePattern.replace('[num]', -1 * currStepIndex * this.videoStepWidth);
            } else {
                currLeft = desktopPattern.replace('[num]', -1 * currStepIndex * this.videoStepWidth);
            }
            return currLeft;
        },


        getVideoHtml: function getVideoHtml(video) {
            var currVideoHtml = this.$.cloudinary.videoTag(video.videoTag,{
                autoplay: true,
                secure: true,
                muted:true,
                poster: false,
                playsinline: true,
                fallback_content: "Your browser does not support HTML5 video tags",
                quality: "70:qmax_20",
                id: video.elementPlayerId }).toHtml();
            return this.$sce.trustAsHtml(currVideoHtml);
        },

        close: function close(dataToPassToModalInstance) {
            this.modalInstance.close(dataToPassToModalInstance);
        },

        dismiss: function dismiss() {
          this.modalInstance.dismiss();
        },

        replayVideo: function replayVideo() {
            var currVideo = document.getElementById(this.currVideos[this.currStepIndex].elementPlayerId);
            currVideo.pause();
            currVideo.currentTime = '0';
            currVideo.play();
        },

        getBottomText: function getBottomText() {
          if (this.isLastStep) {
            return this.bottomTextLastStep;
          }
          return this.bottomText;
        },

        gotoStep: function gotoStep(stepIndex) {
            if (stepIndex >= this.currVideos.length) {
                this.onConfirm();
                return;
            }
            this.AnalyticsService.trackClick(this, this.currContent.analyticsGotoStage, {stage: stepIndex + 1});
            this.currStepIndex = stepIndex;
            this.replayVideo();
            this.isLastStep = this.currStepIndex === this.currVideos.length - 1;
        },

        gotoPreviousStep: function gotoPreviousStep() {
            this.gotoStep(this.currStepIndex - 1);
        },

        gotoNextStep: function gotoNextStep() {
            this.$("#videoCarouselModalCta").blur();
            this.gotoStep(this.currStepIndex + 1);
        },

        getPrimaryCtaText: function getCtaText() {
            return this.$translate.instant(this.currVideos[this.currStepIndex].primaryCtaText);
        },

        getSecondaryCtaText: function getCtaText() {
            return this.$translate.instant(this.currVideos[this.currStepIndex].secondaryCtaText);
        },

        onConfirm: function onConfirm() {
            this.AnalyticsService.trackClick(this, this.currContent.analyticsOnConfirm);
            if (this.onConfirmCallback) {
                this.onConfirmCallback();
            }

            this.close({confirm:true});
        },

        onCancel: function onCancel() {
            this.AnalyticsService.trackClick(this, this.currContent.analyticsOnCancel);
            if (this.onCancelCallback) {
                this.onCancelCallback();
            }
            this.close();
        },

    });
}());
