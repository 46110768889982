Directives.ProposalDirective = function ProposalDirective() {

    // @ngInject
    function ProposalDirectiveControllerFunc($scope, $q, $injector, $rootScope, $timeout, _,
                                             AnalyticsService, WorkspaceFileService, PopupMessageService, UiPersistenceService, DeviceService,
                                             uuid4, CompaniesManager, WorkspaceFilesManager, ModalService, OnboardingService, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProposalDirectiveController';
        var self = this;
        this.stateChangePending = false;
        this.$q = $q;
        this._ = _;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.proposal = this.proposalModel;
        this.packageServicesVmCollection = [];
        this.ModalService = ModalService;
        this.UiPersistenceService = UiPersistenceService;
        this.OnboardingService = OnboardingService;
        this.DeviceService = DeviceService;
        this.showTaxPopover = false;
        this.vendor_packages=[];

        this.proposalSortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.package-drag-icon-container',
            disabled: false,
            start: function (event, ui) {
                 AnalyticsService.track(self, AnalyticsService.analytics_events.proposal_drag_package_started, self.proposalModel.getAnalyticsArgs());
                //console.log('drag start');
            },

            update: function update(event, ui) {
                //console.log("in update");
            },

            stop: function stop(event, ui) {


                var packages_order=[];
                self.vendor_packages.forEach(function (vendor_package, index) {

                    vendor_package.order=index;
                    packages_order.push({_id:vendor_package._id,order:index})

                });

                //update the server with the new order
                self.proposal.updateVendorPackagesOrder(packages_order)
                    .then(function success() {
                        AnalyticsService.track(self, AnalyticsService.analytics_events.proposal_drag_package_ended, self.proposalModel.getAnalyticsArgs());
                    }.bind(self))
                    .catch(function error(err) {
                        AnalyticsService.trackError(self, AnalyticsService.analytics_events.proposal_drag_package_error, self.proposalModel.getAnalyticsArgs());
                    }.bind(self));
            }
        };

        this.showDragIcon = function showDragIcon(service) {
            if (!this.isEditMode()) {
                return false;
            }

            return service.__hoveredOn;
        };

        //onboarding

        this.isOnboardingAddPackageStepTime = this.OnboardingService.shouldShowAddPackagePin();

        this.shouldShowOnboardingTooltip = this.OnboardingService.hasNotYetSentFirstFile();

        this.onboardingAddPackageClose = function () {
            //set the ui persistence.
            self.OnboardingService.setAddPackagePopoverPersistence();

            //uncomment for debugging
            //self.showOnboardingAddPackagePin=false;
        };

        //end onboarding

        this.fillViewModelAccordingToModel = function fillViewModelAccordingToModel() {
            this.vendor_packages.splice(0);
            this.proposal.vendor_packages.forEach(function (vendor_package) {
                var packageViewModel = vendor_package;//.dataOnly();
                //add the the hover state
                packageViewModel.__hoveredOn = false;
                this.vendor_packages.push(packageViewModel);
            }.bind(this));

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.vendor_packages = _.sortBy(this.vendor_packages, function(packageViewModel){
                return packageViewModel.order ? packageViewModel.order : 0;
            });
        };

        this.fillViewModelAccordingToModel();

        var modelToRegister = this.fileModel ? this.fileModel : this.companyModel;
        this.register(modelToRegister, 'success', function success() {

            // Hack for existing venues without svc
            // should happen only once for each venue
            if (this.isEditMode() &&
                this.shouldShowSVCForCompany() && !this.proposalModel.svc_type) {
                this.proposalModel.svc_type = 'relative';
                this.proposalModel.svc = this.companyModel.getDefaultSVC();
                this.updateProposal();
            }

            this.noTaxedItems = this.shouldShowNoTaxableItemsLabel();

            //we need to wait for the digest cycle to finish, so all child models will update
            $scope.$applyAsync(function () {
                this.convertServicesToViewModel();
            }.bind(this));

            this.areAllItemsTaxed = !!this.proposal.all_taxed;

            this.fillViewModelAccordingToModel();
        }.bind(this));

        this.convertServicesToViewModel = function convertServicesToViewModel() {

            if (self.proposalModel.package_services) {
                self.packageServicesVmCollection.splice(0);
                self.proposalModel.package_services.forEach(function (currentService) {
                    self.packageServicesVmCollection.push(currentService.dataOnly());
                });
            }
        };

        // call this on loading, since after refresh the file is loaded before we load this directive
        this.convertServicesToViewModel();


        this.toggleAllTax = function toggleAllTax() {

            self.duringAllTaxableToggle = true;
            self.proposal.toggleTaxToAllPackages(self.areAllItemsTaxed).then(
                function success() {
                    if (self.areAllItemsTaxed) {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_add_tax_to_all);
                    }
                    else {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_remove_all_tax);
                    }
                },
                function error() {
                    if (self.areAllItemsTaxed) {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_add_tax_to_all_error);
                    }
                    else {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_remove_all_tax_error);
                    }
                }
            ).finally(function final() {
                self.duringAllTaxableToggle = false;
            });
        };

        this.updateProposal = function updateProposal() {
            var proposalData = {
                description: self.proposal.description,
                discount: self.proposal.discount,
                discount_type: self.proposal.discount_type,
                tax: self.proposal.tax,
                tax_type: self.proposal.tax_type,
                svc: self.proposal.svc,
                svc_type: self.proposal.svc_type || "relative",
                svc_taxable: self.proposal.svc_taxable
            };
            return self.proposal.updateProposal(proposalData);
        };

        this.updateDiscount = function updateDiscount() {

            this.proposal.discount = this.proposal.discount || 0;

            self.updateProposal().then(
                function success() {
                    var analyticsArgs = {
                        discount: self.proposal.discount,
                        discount_type: self.proposal.discount_type
                    };

                    self.AnalyticsService.trackSuccess(self, self.AnalyticsService.analytics_events.proposal_discount_updated, analyticsArgs);
                },
                function error() {
                }
            );
        };

        this.taxAmountChanged = function taxAmountChanged() {

            if (self.proposal.tax_type === 'relative') {
                self.companyModel.updatedDefaultTax(self.proposal.tax);
            }

            self.updateProposal().then(
                function success() {
                    var analyticsArgs = {
                        tax: self.proposal.tax,
                        tax_type: self.proposal.tax_type
                    };

                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_tax_updated, analyticsArgs);
                },
                function error() {
                }
            );
        };

        this.taxTypeChanged = function taxTypeChanged(new_type) {
            self.proposal.tax_type = new_type;

            if (new_type === 'relative') {
                self.proposal.tax = self.companyModel.getDefaultTax();
            } else {
                self.proposal.tax = self.proposal.total_tax.toFixed(2);
            }

            self.updateProposal().then(
                function success() {
                    var analyticsArgs = {
                        tax: self.proposal.tax,
                        tax_type: self.proposal.tax_type
                    };

                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_tax_type_changed, analyticsArgs);
                },
                function error() {

                }
            );
        };

        this.svcAmountChanged = function svcAmountChanged() {

            // Update default company SVC only if value is not 0. If we change default value to 0, caput.
            if (self.proposal.svc_type === 'relative' && self.proposal.svc !== "0" && !isNaN(parseFloat(self.proposal.svc)) && isFinite(self.proposal.svc)) {
                self.companyModel.updatedDefaultSVC(self.proposal.svc);
            }

            self.updateProposal().then(
                function success() {
                    var analyticsArgs = {
                        svc: self.proposal.svc,
                        svc_type: self.proposal.svc_type
                    };

                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_svc_updated, analyticsArgs);
                },
                function error() {
                }
            );
        };

        this.svcTypeChanged = function svcTypeChanged(new_type) {
            self.proposal.svc_type = new_type;

            if (new_type === 'relative') {
                self.proposal.svc = self.companyModel.getDefaultSVC();
            } else {
                self.proposal.svc = self.proposal.total_svc.toFixed(2);
            }

            self.updateProposal().then(
                function success() {
                    var analyticsArgs = {
                        svc: self.proposal.svc,
                        svc_type: self.proposal.svc_type
                    };

                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.proposal_svc_type_changed, analyticsArgs);
                },
                function error() {

                }
            );
        };

        this.shouldShowNoTaxableItemsLabel = function shouldShowNoTaxableItemsLabel() {

            var itemsTaxed = self._.some(self.proposalModel.package_services, function(service) { return service.taxable; });
            itemsTaxed = itemsTaxed || self._.some(this.proposalModel.vendor_packages, function(pckg) {
                return pckg.taxable || self._.some(pckg.base_services, function(service) { return service.taxable; });
            });

            return !itemsTaxed;
        };

        this.noTaxedItems = this.shouldShowNoTaxableItemsLabel();

        this.shouldShowNoSVCableItemsLabel = function shouldShowNoSVCableItemsLabel() {
            // Service charge section in totals depends on it. Pay attention
            return self.proposal.sub_total > 0 &&
                self.proposal.tax_type === 'relative' &&
                self.proposal.total_svc === 0 &&
                self.proposal.svc !== 0;
        };

        this.shouldShowTaxViewSection = function shouldShowTaxViewSection() {
            return this.isEditMode() || self.proposal.total_tax !== 0;
        };

        this.shouldShowSVCForCompany = function shouldShowSVCForCompany() {
            return this.companyModel && this.companyModel.isVenue();
        };

        this.shouldShowDiscountViewSection = function shouldShowDiscountViewSection() {
            return this.isEditMode() || self.proposal.discount !== 0;
        };

        this.shouldHideSubTotal = function shouldHideSubTotal() {
            return (self.isPreviewMode || !self.isEditableMode) && self.proposal.discount === 0 && self.proposal.total_tax === 0;
        };

        this.shouldHideSubTotalClient = function shouldHideSubTotal() {
            return !self.isOwnerMode && self.proposal.discount === 0 && self.proposal.total_tax === 0;
        };

        this.shouldShowPackagesTooltip = function shouldShowPackagesTooltip() {
            var noOnboardy = true;
            if (self.fileModel.isInvoice() || self.fileModel.isProposal()) {
                noOnboardy = (!this.OnboardingService.isOnboardingActive() || !this.isOnboardingAddPackageStepTime);
            }
            return self.proposal.vendor_packages.length === 0 && self.proposal.package_services.length === 0 && noOnboardy;
        };

        this.isProposalEmpty = function isProposalEmpty() {
            return self.proposal.vendor_packages.length === 0 && self.proposal.package_services.length === 0;
        };

        self.proposalHasOneEmptyPackage = function proposalHasOneEmptyPackage() {
            var packageData = this._.map(self.proposal.vendor_packages, function (package) {
                return package.dataOnly().base_services;
            });

            // got "Cannot read property '0' of undefined" for this post onboarding. patching as this is a minor.
            if (this._.isEmpty(packageData) || !this._.isArray(packageData)) {
                return false;
            }
            var theFirstPackage = _.first(packageData)[0];
            var isPackageEmpty = theFirstPackage && theFirstPackage.name === "" && theFirstPackage.description === "";

            var res = isPackageEmpty && self.proposal.vendor_packages.length === 1 && self.proposal.package_services.length === 0;
            return res;
        };

        this.isEditMode = function isEditMode() {
            return self.isOwnerMode &&
                self.isEditableMode && !self.isPreviewMode;
        };

        this.enableDragAndDrop= function enableDragAndDrop() {
            var enable=(this.fileModel && (this.fileModel.isProposal() || this.fileModel.isInvoice())) || this.templateMode ;
            return enable;
        };

        this.showSvcOnSummary = function showSvcOnSummary() {
            // for company - check self it is a venue
            // for client - just check self a service charge exists
            return (!self.companyModel || self.shouldShowSVCForCompany()) &&
                (self.proposal.sub_total > 0 &&
                self.proposal.total_svc > 0);
        };

        this.saveAsProposalTemplate = function saveAsProposalTemplate(title) {
            this.templateTitle = title;
            if (!this.templateTitle || this.templateTitle.length === 0) {
                PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._MISSING_TEMPLATE_NAME_');
                return this.$q.reject();
            }

            var self = this;

            return CompaniesManager.saveAsVendorProposalTemplate(self.companyModel, self.fileModel, self.templateTitle).then(
                function success() {

                    // origin_copy_id changes
                    self.fileModel = WorkspaceFilesManager.getProposalTemplateOrigin(self.fileModel);


                    var analyticsArgs = {
                        template_origin_id: self.proposalModel._id,
                        template_title: self.templateTitle,
                        template_type: "Proposal",
                        company_id: self.companyModel._id
                    };

                    AnalyticsService.track(self, AnalyticsService.analytics_events.template_save_as_confirmed, analyticsArgs);
                },
                function error(resp) {

                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                        'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');

                    var analyticsArgs = {
                        template_origin_id: self.proposalModel._id,
                        template_title: self.templateTitle,
                        template_type: "Proposal",
                        company_id: self.companyModel._id,
                        error: resp.data.error_message
                    };

                    AnalyticsService.track(self, AnalyticsService.analytics_events.template_save_as_failed, analyticsArgs);
                }
            );
        };

        // brochure does not have a proposal, so we dont want to validate total price.
        if (this.fileModel && this.fileModel.hasProposal()) {
            var directiveId = uuid4.generate();
            WorkspaceFileService.addFileValidation(directiveId, function proposalValidator() {
                return $q(function (resolve, reject) {
                    if (self.proposal.total_price > 0) {
                        resolve();
                    } else {
                        $scope.scrollToTotalPrice();
                        if (this.fileModel.isRecurringPayment()) {
                            PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'PROPOSAL._ZERO_TOTAL_MESSAGE_ERROR_');
                            reject();
                        } else {
                            PopupMessageService.showConfirmPromise(PopupMessageService.severityTypes.warning, 'PROPOSAL._ZERO_TOTAL_MESSAGE_WARNING_', 'Continue', 'Cancel').then(function() {
                                resolve();
                            }.bind(this))
                                .catch(function() {
                                    reject();
                                }.bind(this));
                        }
                    }
                });
            }, 600);
        }

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(directiveId);
        });

        this.invokeSendFile = function () {
            this.resendFileAction();
        };
    }

    var ProposalDirectiveController = Class(Controllers.BaseController, {
        constructor: ProposalDirectiveControllerFunc,

        taxSaved: function taxSaved() {
            if (this.proposalModel.tax_type === 'relative') {
                this.companyModel.updatedDefaultTax(this.proposalModel.tax, this.proposalModel.tax_title);
            }

            this.toggleTaxPopover(false);
        },

        toggleTaxPopover: function toggleTaxPopover(show) {
            this.showTaxPopover = show;
        },

        getTaxSymbol: function getTaxSymbol(type) {
            return type === 'relative' ? '%' : '$';
        }
    });


    return {
        scope: {
            proposalModel: '=proposalModel',
            fileModel: '=fileModel', // might be null if in company templates
            companyModel: '=companyModel',
//            isEditMode: '&?isEditMode',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            alreadyGotFile: '=alreadyGotFile',
            // First file cleanup
            isFirstFileNotSent: '=isFirstFileNotSent',
            hidePriceAndDetails: '=?hidePriceAndDetails',
            templateMode: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/proposal/proposal_directive_template.html',
        controller: ProposalDirectiveController,
        controllerAs: 'proposalVm',
        bindToController: true,
        link: function proposalDirectiveLink(scope, element) {
            scope.scrollToTotalPrice = function scrollToTotalPrice() {
                var totalPriceElement = element.find('#totalPrice');
                return totalPriceElement.scrollParent().scrollTo(null, totalPriceElement.offset().top - 300, 300);
            };

        }
    };
};
