(function () {
    'use strict';

    class RescheduleExpiredFileComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, RescheduleProjectService, UsersManager, ReactModalService) {
            super($scope, $injector);
            this.__objectType = 'RescheduleExpiredFileComponentController';
            this.AnalyticsService = AnalyticsService;
            this.RescheduleProjectService = RescheduleProjectService;
            this.UsersManager = UsersManager;
            this.ReactModalService = ReactModalService;
        }

        onUnexpireFileClick() {
            var user = this.UsersManager.getCurrUser();
            if (this.file.is_canceled && this.file.isRecurringPayment() && !user.account.card_on_file_enabled) {
                this.ReactModalService.openEnableCardOnFileModal('unexpired');
                return;
            }
            if (this.requestInProgress) {
                return;
            }

            this.requestInProgress = true;

            const trackingData = {
                workspace_file_id: this.file._id
            };

            this.file.activateWorkspaceFile().then(() => {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
                return this.RescheduleProjectService.makeFileEditable(this.file);
            }).catch(() => {
                this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
            }).finally(() => {
                this.requestInProgress = false;
            });
        }
    }

    Components.RescheduleExpiredFileComponent = {
        bindings: {
            file: '<'
        },
        controller: RescheduleExpiredFileComponentController,
        name: 'hbRescheduleExpiredFileComponent',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_expired_file_template.html',
    };

}());