/**
 * Created by inonstelman on 1/1/15.
 */

Directives.UserAvatarDirective = function UserAvatarDirective() {

    // @ngInject
    function UserAvatarDirectiveControllerFunc($element, AvatarService, AnalyticsService, $scope, $injector, AppStates, $state, $window) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'UserAvatarDirectiveController';
        var self = this;
        self.AnalyticsService = AnalyticsService;
        self.avatarElem = $element.find(".avatar");
        this.AppStates = AppStates;
        this.AvatarService = AvatarService;

        this.$state=$state;
        this.$window = $window;

        var data = {
            user: self.user,
            offset: null,
            allowMessaging: false,
            showDownloadImageLink: this.showDownloadImageLink
        };

        if (angular.isUndefined(this.avatarSize) || this.avatarSize === '') {
            this.avatarSize = 'auto';
        }

        self.styleObjectForAvatarImage = null;

        self.hoverOn = function hoverOn() {
            if (self.popupOnHover === "true") {
                data.offset = self.avatarElem.offset();
                data.offset.top += self.avatarElem.height();
                //data.offset.left += avatarElem.width();
                AvatarService.showAvatarPopup(data);

                self.sendAnalyticsTimeout = setTimeout(
                    function () {
                        self.AnalyticsService.track(self,self.AnalyticsService.analytics_events.avatar_hover_event);
                    }, 2000
                );
            }
        };

        self.hoverOff = function hoverOff() {
            if (self.popupOnHover === "true") {
                clearTimeout(self.sendAnalyticsTimeout);
                AvatarService.hideAvatarPopup();
            }

        };

        this.getInitials = function getInitials(user) {
            var initials = '';

            if (user.initials) {
                initials = user.initials;

            } else if (user.first_name && user.first_name.length) {
                initials += user.first_name.charAt(0);

                if (user.last_name && user.last_name.length) {
                    initials += user.last_name.charAt(0);
                } else if (user.first_name.length > 1) {
                    initials += user.first_name.charAt(1);
                }

            } else if (user.last_name && user.last_name.length) {
                initials += user.last_name.charAt(0);

                if (user.last_name.length > 1) {
                    initials += user.last_name.charAt(1);
                }

            } else if (user.email && user.email.length) {
                initials += user.email.charAt(0);

                if (user.email.charAt(1) !== '@') {
                    initials += user.email.charAt(1);
                }
            }

            return initials;
        };

        $scope.$watch('[userAvatarVm.user.first_name, userAvatarVm.user.last_name, userAvatarVm.user.email]', function (newValue, oldValue) {
            if (self.user) {
                self.initials = self.getInitials(self.user);
                self.hasProfileImage = (self.user && self.user.profile_image &&
                    self.user.profile_image.cloudinary_public_id) || self.imageUrlOverride;
                self.showInitials = (self.hasProfileImage && self.AvatarService.isDefaultProfileImage(self.user)) ||
                    self.showInitialsForMissingProfileImage;// do we really need the showInitialsForMissingProfileImage???

                if (!self.hasProfileImage) {
                    self.styleObjectForAvatarImage = null;
                }
            }
        });

        $scope.$watch('userAvatarVm.user.profile_image', function (newValue, oldValue) {
            var imgSize = 180;
            var dprTransition = {dpr: "auto"};
            switch (self.avatarSize) {
                case "x-large":
                    imgSize = 180;
                    // dprTransition = {dpr: 2}; // why was this needed ?
                    break;
                case "large":
                    imgSize = 110;
                    break;
                case "large64":
                    imgSize = 64;
                    break;
                case "medium":
                    imgSize = 46;
                    break;
                case "small32":
                    imgSize = 32;
                    break;
                case "small":
                    imgSize = 30;
                    break;
                default:
                    imgSize = 180;
            }


            if (self.user) {
                var url = self.user.profile_image ? self.user.profile_image.url : "";

                if (self.user.profile_image && self.user.profile_image.cloudinary_public_id) {
                    // fetch double size for image quality on retina displays
                    var img = this.$.cloudinary.image(self.user.profile_image.cloudinary_public_id, {
                        cloud_name: self.user.profile_image.cloudinary_sub_account,
                        transformation: [
                            {
                                flags: 'lossy',
                                crop: 'lfill',
                                width: imgSize,
                                height: imgSize,
                                quality: 'auto:best',
                                fetch_format: 'auto'
                            },
                            dprTransition
                        ]
                    });

                    url = img.attr('src');
                }

                if (!self.AvatarService.isDefaultProfileImage(self.user)) {
                    self.styleObjectForAvatarImage = {
                        'background-image': 'url(' + url + ')'
                    };
                } else {
                    self.styleObjectForAvatarImage = null;
                }
            }


        }, true);

        if (this.imageUrlOverride) {
            self.styleObjectForAvatarImage = {
                'background-image': 'url(' + this.imageUrlOverride + ')'
            };
        }
    }

    var UserAvatarDirectiveController = Class(Controllers.BaseController, {
        constructor: UserAvatarDirectiveControllerFunc
    });


    return {
        scope: {
            user: '=user',
            popupOnHover: '@popupOnHover',
            avatarSize: '@avatarSize',
            hoverButton: '@hoverButton',
            hoverButtonAction: '&hoverButtonAction',
            allowMessaging: '=allowMessaging',
            imageUrlOverride: '=',
            showInitialsForMissingProfileImage: '=',
            showBadge: "=",
            badgeType: "@badgeType",
            badgeClickEnabled: "=",
            avatarHref: '<',
            showShadow: '=?',
            showDownloadImageLink: '='
        },
        templateUrl: 'angular/app/modules/common/ui_components/avatar/user_avatar_directive_template.html',
        controller: UserAvatarDirectiveController,
        controllerAs: 'userAvatarVm',
        bindToController: true
    };
};
