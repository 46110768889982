(function () {
    "use strict";


    // @ngInject
    function ContactsManagerCtor($q, Routes, APIService, ModelFactory, UsersManager) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.UsersManager = UsersManager;


    }


    Services.ContactsManager = Class(Services.BaseModelManager, {

        constructor: ContactsManagerCtor,

        newContactsCollection: function newContactsCollection() {
            return this.ModelFactory.newContactsCollection();
        },

        newContact: function newContact() {
            return this.ModelFactory.newContactModel();
        },

        convertUserToContact: function convertUserToContact(jsonUser) {
            var contact = this.newContact();
            var user = this.UsersManager.convertToUser(jsonUser);
            contact.user = user;

            return contact;
        },

        convertToContact: function convertToContact(jsonContact) {
            var contact = this.newContact();

            contact.mixinFrom(jsonContact, null, false, false);
            return contact;
        },

        /*****************************************
         * ACTIONS
         ******************************************/
        getPeople: function getPeople(contacts, page, filters, perPage) {
            var url = this.Routes.v2_get_people_path();
            filters = filters || {};
            filters.perPage = perPage || 50;
            filters.page = page;

            return this.apiFetchCollection(url, filters, contacts, true, null, false, false, true);
        },

        getUserContacts: function getUserContacts(contacts, user, page, filters, perPage) {
            var url = this.Routes.v2_get_user_contacts_path(user._id);
            filters = filters || {};
            filters.perPage = perPage || 50;
            filters.page = page;

            return this.apiFetchCollection(url, filters, contacts, true, null, false, false, true);
        },

        getWorkspaceConnectionsForContact: function getWorkspaceConnections(contact) {
            var url = this.Routes.v2_get_workspace_connections_for_contact_path(contact._id);
            return this.apiFetch(url, null, contact, true);
        },

        /*
        returns the count of contacts only (no real data), with query parameters.
        By default, all contacts are returned.
        supported query parameters:
        - vendors_only (boolean)
        - clients_only (boolean)
        - preferred_vendors_only (boolean)
         */
        getContactByEmail: function getContactsByEmail(currUser, email) {
            var url = this.Routes.v2_get_user_contacts_path(currUser._id);
            var filters = {};
            filters.per_page = 1;
            filters.page = 1;
            filters.exact_email = true;
            filters.text = email;

            return this.apiFetch(url, filters);
        },

        getContactsCount: function getContactsCount(queryParams) {
            var data = queryParams;
            var url = this.Routes.v2_get_contacts_count_path();
            return this.apiFetch(url, data, null, null);
        },

        getCreatedVendorsCount: function getCreatedVendorsCount() {
            var data = {"user_type": "vendor"};
            var url = this.Routes.v2_get_created_users_path();
            return this.apiFetch(url, data);
        },

        getSuggestedPreferredContacts: function getSuggestedPreferredContacts(limit, createCollection) {
            var url = this.Routes.v2_get_suggested_preferred_vendors_path();
            var data = {
                limit: limit
            };
            if (createCollection) {
                var contacts = this.newContactsCollection();
                this.apiFetchCollection(url, data, contacts, true);
                return contacts;
            } else {
                return this.apiFetch(url, data, null, null);
            }
        },

        hasPreferredContacts: function () {
            var url = this.Routes.v2_has_preferred_vendors_path();
            return this.apiFetch(url);
        },

        saveContact: function saveContact(contact) {

            var data = contact.user.dataOnly();
            var url = this.Routes.v2_save_contact_path();
            return this.apiCreate(url, data, contact, true);
        },

        savePreferredVendorContact: function savePreferredVendorContact(contact, is_preferred) {
            var data = contact.user.dataOnly();
            data.is_preferred = is_preferred;
            var url = this.Routes.v2_save_preferred_vendor_contact_path();
            return this.apiCreate(url, data, contact, true);
        },

        updatePreferredVendorContact: function updatePreferredVendorContact(contact, isPreferred) {
            var data = {
                contact_id: contact._id,
                is_preferred: isPreferred
            };
            var url = this.Routes.v2_update_preferred_vendor_contact_path();
            return this.apiUpdate(url, data);
        },

        saveContactUser: function saveContactUser(user, source) {
            var data = user.dataOnly();

            if(source){
                data['source'] = source;
            }
            var url = this.Routes.v2_save_contact_path();
            return this.apiCreate(url, data);
        },

        createPreferredContact: function createPreferredContact(user, source, isPreferred) {

            var data = {
                user_id: user._id,
                source: source,
                is_preferred: isPreferred
            };

            var url = this.Routes.v2_create_preferred_contact_path();
            return this.apiCreate(url, data);
        },

        deleteContact: function deleteContact(contact) {
            var url = this.Routes.v2_delete_contact_path(contact._id);
            return this.apiDelete(url);
        },

        updateContactNotes: function updateContactNotes(contact, notes) {
            var data = {
                notes: notes
            };
            var url = this.Routes.v2_contacts_update_notes_path(contact._id);
            return this.apiUpdate(url, data, contact, true);
        }


    });
}());
