/**
 * Created by inonstelman on 12/28/14.
 */

(function () {
    'use strict';

    // @ngInject
    Services.GoogleApi = function GoogleApi($window, Gon, ThirdPartyLoaderService) {
        // I Added &callback=Function.prototype to eliminate js error from Google API.
        // See https://stackoverflow.com/questions/75179573/how-to-fix-loading-the-google-maps-javascript-api-without-a-callback-is-not-supp
        var url = "https://maps.googleapis.com/maps/api/js?key=" + Gon.google_api_api_key + "&libraries=places" + "&callback=Function.prototype";
        
        return ThirdPartyLoaderService.loadUrlPromise(url, function() {
            // waiting for window.google is not enough, because we load other google SDKs (like google.accounts)
            if ($window.hasOwnProperty('google') && $window.google.hasOwnProperty('maps')) {
                return $window.google;
            }
        });
    };

}());
