// hb-honeybook-splash-screen

(function () {
    'use strict';
    
    class HoneyBookSplashScreenController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, SplashScreenService) {
            super($scope, $injector);
            this.__objectType = 'ReactModalController';
            this.SplashScreenService = SplashScreenService;
        }
    }

    Components.HoneyBookSplashScreen = {
        controller: HoneyBookSplashScreenController,
        templateUrl: 'angular/app/modules/common/ui_components/honeybook_splash_screen/honeybook_splash_screen.html'
    };
})();
