Components.ReportModule = {
  transclude: {
    headerContent: '?headerContent',
    headerControls: '?headerControls',
    footerContent: '?footerContent',
    footerControls: '?footerControls',
  },
  name: 'hbReportModule',
  templateUrl: 'angular/app/modules/common/ui_components/report/report_module_component.html',
};
