(function () {
    'use strict';

    // @ngInject
    function AddWorkspaceParticipantControllerCtor($scope, $modalInstance, $injector, $translate, ModelFactory, $q, EventsManager,
                                                    CompaniesManager, TimezoneService, EventService, AbTestService, Enums, workspace,
                                                    isNewWorkspace, subTitleText, shouldShowUserType, defaultTab,
                                                    UsersManager, PopupMessageService, AnalyticsService, WorkspacesManager,
                                                    UserService, _, CompanyService, GoogleAPIService,
                                                    SearchablesManager, ValidationsManager, ContactsManager, FeaturesService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager);
        this.__objectType = 'AddWorkspaceParticipantController';
        AnalyticsService.trackPageView(this, 'CreateProjectWithClientsControllerView');
        this.UsersManager = UsersManager;
        this.ModelFactory = ModelFactory;
        this.EventsManager = EventsManager;
        this.CompaniesManager = CompaniesManager;
        this.TimezoneService = TimezoneService;
        this.WorkspacesManager = WorkspacesManager;
        this.EventService = EventService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$q = $q;
        this.ContactsManager = ContactsManager;
        this.workspace = workspace;
        this.isNewWorkspace = isNewWorkspace;
        this.defaultTab = defaultTab;
        this.isVendor = false;

        if (this.workspace) {
            this.titleText = $translate.instant("ADD_USER_DIALOG._ADD_USER_");
            this.shouldShowUserType = shouldShowUserType || false;
        } else {
            this.shouldShowUserType = false;
            this.titleText = $translate.instant("ADD_USER_DIALOG._ADD_USER_CLIENT_FLOW_");
        }

        this._setupClientVendorSeparationVars(FeaturesService, UsersManager);

        this.subTitleText = subTitleText || $translate.instant("ADD_USER_DIALOG._ADD_CLIENT_SUBTITLE_");

        this.company = this.CompaniesManager.getCurrCompany(true);
        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._ADD_");

        this.isSuggestFromContacts = true;
        this.isSuggestFromGmailContacts = true;
        this.shouldShowBusinessInfoOnBottom = true;
        this._setupTabs(this.defaultTab);
    }

    Controllers.AddWorkspaceParticipantController = Class(Controllers.NewAddUserController, {
        constructor: AddWorkspaceParticipantControllerCtor,

        saveUser: function saveUser(){

            var saveUserDefer = this.$q.defer();
            var projectDefer = this.$q.defer();

            if (this.workspace) {
                projectDefer.resolve({workspaceId: this.workspace._id, projectId: this.workspace.event._id});
            }
            else {
                // create new project when needed
                var currCompany = this.CompaniesManager.getCurrCompany();
                var newProject = this.ModelFactory.newEventModel();

                this.user.system_user_type = 'client';
                this.isClient = true;
                this.isVendor = false;

                newProject.event_name = this.user.full_name + "'s project";
                newProject.event_image = this.EventService.getInitialProjectImage(currCompany);
                newProject.event_type = currCompany.sortedProjectTypes()[0].label;
                var timezone = this.TimezoneService.getTimezoneForCompany(currCompany);
                newProject.event_timezone = this.TimezoneService.translateToServer(timezone);

                this.EventsManager.createEvent(newProject).then(function (resp) {
                    projectDefer.resolve({workspaceId: resp.data._id, projectId: resp.data.event._id});
                    this.AnalyticsService.reportEvent('ProjectCreated');
                }.bind(this));
            }

            projectDefer.promise.then(function(resp) {
                this.user.workspace_role = 'host';
                this.WorkspacesManager.saveMember(resp.workspaceId, this.user).then(function saveMemberSuccess() {
                    saveUserDefer.resolve({user: this.user, projectId: resp.projectId, workspaceId: resp.workspaceId});
                }.bind(this)).catch(function saveMemberError(resp) {
                    saveUserDefer.reject(resp);
                }.bind(this));
            }.bind(this));

            return saveUserDefer.promise;
        }
    });
}());
