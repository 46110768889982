(function () {
    'use strict';

    // @ngInject
    function SettingsSecondLevelNavigationControllerCtor($scope, $injector, $state, AppStates, UsersManager, AuthService, AnalyticsService, ModalService, StatsigService, $window, $timeout) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SettingsSecondLevelNavigationController';

        this.$state = $state;
        this.AppStates = AppStates;
        this.AuthService = AuthService;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.StatsigService = StatsigService;

        this.user = UsersManager.getCurrUser();

        // prepare the view model
        this.title = $state.$current.data.title;

        // company settings links
        this.navItems = [
            {linkTitleKey: 'Company Brand', stateToGoto: AppStates.root_core_navigation_settings_company_general, dataTestId: 'company-nav-company-brand--link'},
            {linkTitleKey: 'Preferences', stateToGoto: AppStates.root_core_navigation_settings_company_preferences, dataTestId: 'company-nav-preferences--link'},
            {linkTitleKey: 'Integrations', stateToGoto: AppStates.root_core_navigation_settings_company_integrations, dataTestId: 'company-nav-integrations--link'},
            {linkTitleKey: 'Team', stateToGoto: AppStates.root_core_navigation_settings_company_team, dataTestId: 'company-nav-team--link'}
        ];

        $timeout(function() {
            var container = document.querySelector('.company-nav__list');
            var header = document.querySelector('.top-view--js');
        
            function adjustPosition() {
                if (container && header) {
                    var containerRect = container.getBoundingClientRect();
                    var headerHeight = header.getBoundingClientRect().height;
                    if (containerRect.bottom > $window.innerHeight) {
                        var menuSpacingFromHeader = 50;
                        var windowSize = $window.innerHeight;
                        var updatedHeight = windowSize - menuSpacingFromHeader - headerHeight;
                        container.style.height = updatedHeight + 'px';
                    } else {
                        container.style.height = 'auto';
                    }
                } 
                
            };
        
            angular.element($window).on('resize', adjustPosition);
            $scope.$on('$destroy', function() {
                angular.element($window).off('resize', adjustPosition);
            });
            adjustPosition();
        }, 0);
        

        
        // add routes for company owner only
        if (this.user.isCompanyOwner() || this.user.isAccountOwner()) {

            // membership
            if (this.user.shouldShowMembershipToUser()) {

                this.navItems.push({
                    linkTitleKey: 'Membership',
                    stateToGoto: AppStates.root_core_navigation_settings_company_subscription,
                    dataTestId: 'company-nav-membership--link'
                });
            }
            // bank details
            this.navItems.push({
                linkTitleKey: 'Bank Details',
                stateToGoto: AppStates.root_core_navigation_settings_company_bankInfo,
                dataTestId: 'company-nav-bank-details-payments--link'
            });

            // Payment Method
            this.navItems.push({
                linkTitleKey: 'Client Payment Methods',
                stateToGoto: AppStates.root_core_navigation_settings_company_paymentMethod,
                dataTestId: 'company-nav-payment-method--link'
            });
        }

        if (this.user.shouldShowClientPortal()) {
            this.navItems.splice(2, 0, {linkTitleKey: 'Domain &\nClient Portal', stateToGoto:AppStates.root_core_navigation_settings_company_client_portal, dataTestId: 'company-nav-domain-client-portal--link'});
        }

        Promise.all([
            this.StatsigService.isGateEnabled('priority_leads_enabled'),
            this.StatsigService.isGateEnabled('ai_composer_enabled'),
            this.StatsigService.isGateEnabled('ai_meeting_notes')
        ])
            .then(function(results) {
                var isHotLeadEnabled = results[0];
                var isAIEnabled = results[1];
                var isAiMeetingNotesEnabled = results[2];

                if (isHotLeadEnabled || isAIEnabled || isAiMeetingNotesEnabled) {
                    this.navItems.splice(2, 0, {
                        linkTitleKey: 'AI preferences',
                        stateToGoto: AppStates.root_core_navigation_settings_company_ai_preferences,
                        dataTestId: 'company-nav-ai-preferences--link'
                    });
                }
            }.bind(this));

        this.StatsigService.isGateEnabled('tax_documents_download').then(function (isEnabled) {
            if (this.user.company.country === 'US' && isEnabled) {
                var controllerRef = this;
                UsersManager.getUserTaxDocuments().then(function success(response) {
                    const taxDocuments = response.data;

                    if (taxDocuments.length) {
                        controllerRef.navItems.push({
                            linkTitleKey: 'Tax Information',
                            stateToGoto: AppStates.root_core_navigation_settings_company_tax_information,
                            dataTestId: 'company-nav-tax-information--link'
                        });
                    }
                });
            }

            // pro routes
            if ((this.user.is_pro && !this.user.isProLogin())
            ) {
                this.navItems.push({
                    linkTitleKey: 'Account Access',
                    stateToGoto: AppStates.root_core_navigation_settings_company_account_access
                });
            }
        }.bind(this));
    }

    Controllers.SettingsSecondLevelNavigationController = Class(Controllers.BaseController, {
        constructor: SettingsSecondLevelNavigationControllerCtor,

        onMenuItemClicked: function onMenuItemClicked(stateToGoto) {
            this.goToState(stateToGoto);
        },

        onUploadIconClick: function onUploadIconClick() {
            this.ModalService.openUploadIconPhotoModal(this.user.company);
        },

        logout: function logout() {
            this.AuthService.logout();
        },

        activeState: function activeState(route) {
            return route === this.$state.current.name;
        },

        findRootIndex: function findMenuItem(item) {
            return item.linkTitleKey === 'Integrations';
        }

    });
}());

