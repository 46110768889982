(function () {
    'use strict';

    class InteractiveServicesController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            SelfOnboardingManager,
            CompaniesManager,
            UsersManager,
            _,
            TemplatesManager,
            ModalService,
            Gon
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveServicesController';

            this.$scope = $scope;
            this.SelfOnboardingManager = SelfOnboardingManager;
            this.ModalService = ModalService;
            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.TemplatesManager = TemplatesManager;
            this.Gon = Gon;

            this._ = _;

        }

        getSelectedServices() {
            var selectedServices = this.servicesList.filter(service => service.selected);
            var services = this._.map(selectedServices, function (service) {
                return {name: service.name};
            });
            this.selectedServices = services;
            return services;
        }

        $onInit() {
            // This step is initialized early in order to support a smooth
            // step animation. This means $onInit is called while seeing the
            // preceding step. This creates a bug because we would be generating
            // the invoice without branding data filled in yet.
            // We're working around this by using the $onChange function
            // for our initialization code.



            this.hasNotInitialized = true;
        }

        $onChanges(changes) {
            // This is our pseudo $onInit condition
            if(changes.currentStep && changes.currentStep.currentValue.key === 'services') {
                // This block used to be inside of $onInit
                this.user = this.UsersManager.getCurrUser();
                this.company = this.CompaniesManager.getCurrCompany();
                this.servicesList = this.getServiceListByCompanyType(this.company.company_type);
                this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));

                if (!this.user.onboarding_container.onboarding_invoice_template_id && this.hasNotInitialized) {
                    this.createInvoiceTemplate()
                        .then(resp => this.updateInvoiceOnboardingData(resp.data))
                        .then(resp => this.getInvoiceTemplate(resp.data.onboarding_container.onboarding_invoice_template_id))
                        .then(resp => this.onInvoiceTemplateReceived())
                        .then(resp => this.handleProposalTemplateCreation())
                        .then(resp => this.preSelectServices())
                        .then(() => this.validateStepIfServicesSelected());
                    this.hasNotInitialized = false;
                } else if(this.user.onboarding_container.onboarding_invoice_template_id) {
                    this.getInvoiceTemplate(this.user.onboarding_container.onboarding_invoice_template_id)
                        .then(resp => this.onInvoiceTemplateReceived())
                        .then(resp => this.handleProposalTemplateCreation())
                        .then(() => this.validateStepIfServicesSelected());
                }

                this.validateStepIfServicesSelected();
            }
        }

        handleProposalTemplateCreation() {
            if (!this.user.onboarding_container.onboarding_proposal_template_id) {
                return this.createPropsalTemplate()
                        .then(resp => this.updateProposalOnboardingData(resp.data))
                        .then(resp => this.getProposalTemplate(resp.data.onboarding_container.onboarding_proposal_template_id))
                        .then(() => this.onProposalTemplateReceived());
            }
            else {
                return this.getProposalTemplate(this.user.onboarding_container.onboarding_proposal_template_id)
                        .then(resp => this.onProposalTemplateReceived());
            }
        }

        createInvoiceTemplate() {
            return this.SelfOnboardingManager.createOnboardingInvoiceTemplate();
        }

        createPropsalTemplate() {
            return this.SelfOnboardingManager.createOnboardingProposalTemplate();
        }

        openExplainServicesModal() {
            this.ModalService.openInteractiveOnboardingExplainServicesModal();
        }

        updateInvoiceOnboardingData(data) {
            var onboardingData = {
                onboarding_invoice_template_id: data.template_id
            };
            return this.SelfOnboardingManager.updateOnboardingData(this.user, onboardingData);
        }

        updateProposalOnboardingData(data) {
            var onboardingData = {
                onboarding_proposal_template_id: data.template_id
            };
            return this.SelfOnboardingManager.updateOnboardingData(this.user, onboardingData);
        }

        addService(service) {
            this.invoiceTemplate.addCustomItem(null, {name: service.name});
            this.proposalTemplate.addCustomItem(null, {name: service.name});
        }

        removeService(service) {
            this.removeServiceFromInvoice(service);
            this.removeServiceFromProposal(service);
        }

        removeServiceFromInvoice(service) {
            var invoiceServiceToDelete = this._.detect(this.invoiceTemplate.vendor_proposal.package_services, function (templateService) {
                return templateService.name === service.name;
            }, this);
            this.invoiceTemplate.deleteService(null, invoiceServiceToDelete);
        }

        removeServiceFromProposal(service) {
            var proposalServiceToDelete = this._.detect(this.proposalTemplate.vendor_proposal.package_services, function (templateService) {
                return templateService.name === service.name;
            }, this);

            this.proposalTemplate.deleteService(null, proposalServiceToDelete);
        }

        validateStepIfServicesSelected() {
            var selectedServices = this.getSelectedServices();
            var isValid = selectedServices.length > 0;
            this.onValidation({isValid});
        }

        onServiceClicked(service) {
            if (service.selected) {
                this.addService(service);
            } else {
                this.removeService(service);
            }
            const selectedServices = this.getSelectedServices();
            this.validateStepIfServicesSelected();
        }

        getInvoiceTemplate(templateId) {
            return this.TemplatesManager.getInvoice(this.company, this.user, templateId);
        }

        getProposalTemplate(templateId) {
            return this.TemplatesManager.getProposal(this.company, this.user, templateId);
        }

        onInvoiceTemplateReceived() {
            this.invoiceTemplate = this._.detect(this.company.invoices, function (invoice) {
                return invoice._id === this.user.onboarding_container.onboarding_invoice_template_id;
            }, this);

            this.invoiceTemplate.vendor_proposal.package_services.forEach(function (service) {
                var selectedService = this._.detect(this.servicesList, function (serviceItem) {
                    return service.name === serviceItem.name;
                }, this);
                if (selectedService) {
                    selectedService.selected = true;
                }
            }.bind(this));
        }

        onProposalTemplateReceived() {
            this.proposalTemplate = this._.detect(this.company.proposals, function (proposal) {
                return proposal._id === this.user.onboarding_container.onboarding_proposal_template_id;
            }, this);

            this.proposalTemplate.vendor_proposal.package_services.forEach(function (service) {
                var selectedService = this._.detect(this.servicesList, function (serviceItem) {
                    return service.name === serviceItem.name;
                }, this);
                if (selectedService) {
                    selectedService.selected = true;
                }
            }.bind(this));
        }

        preSelectServices() {
            var service = this.servicesList[0];
            service.selected = true;
            this.onServiceClicked(service);
        }

        getServiceListByCompanyType(companyType) {

            const CompanyTypeToServiceList = this.Gon.company_type_to_service_list;
            return CompanyTypeToServiceList[companyType] || CompanyTypeToServiceList["other"];
        }
    }

    Components.InteractiveServices = {
        controller: InteractiveServicesController,
        bindings: {
            onValidation: '&',
            onComplete: '&',
            currentStep: '<',
        },
        name: 'hbInteractiveServices',
        require: {
            hbInteractiveOnboarding: '^hbInteractiveOnboarding'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/services/services.html',

    };
}());