(function () {
    "use strict";

    Services.FirebaseAPIService = class FirebaseAPIService {

        // @ngInject
        constructor($http, $q, AppConfigService, $log, Routes, firebase) {
            this.$q = $q;
            this.$log = $log;
            this.Routes = Routes;
            this.AppConfigService = AppConfigService;
            this.firebase = firebase;
        }

        ensuredFirebaseLoaded() {
            if (!this.firebase || this.firebase.apps.length === 0) {
                this.initAuth();
            }
        }

        signIn(provider_type) {
            this.ensuredFirebaseLoaded();
            let provider;
            if (provider_type === 'apple') {
                provider = new this.firebase.auth.OAuthProvider('apple.com');
                provider.addScope('email');
                provider.addScope('name');
            }
            else if (provider_type === 'google') {
                provider = new this.firebase.auth.GoogleAuthProvider();
                provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            }

            const defer = this.$q.defer();

            this.firebase.auth().signInWithPopup(provider)
                .then((result) => {
                    defer.resolve(result);
                }).catch((error) => {
                    defer.reject(error);
            });

            return defer.promise;
        }

        signOut() {
            this.ensuredFirebaseLoaded();
            if (this.firebase) {
                this.firebase.auth().signOut().then(() => {
                    // user is now logged out
                }).catch((error) => {
                    // An error happened.
                });
            }
        }

        initAuth() {
            const firebaseConfig = this.AppConfigService.appConfiguration().firebase_config;

            this.firebase.initializeApp(firebaseConfig);
        }

    };
}());
