Directives.ChangePasswordDirective = function ChangePasswordDirective() {

    // @ngInject
    function ChangePasswordDirectiveControllerFunc($scope, $injector, UsersManager, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ChangePasswordDirectiveController';
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.loading = false;

    }

    var ChangePasswordDirectiveController = Class(Controllers.BaseController, {
        constructor: ChangePasswordDirectiveControllerFunc,

        changePasswordSuccessful: function onModelUpdateSuccess() {
            this.resetForm();
            this.user.changePasswordSuccessful();
            this.passwordChangeResult = 'success';
        },

        changePasswordNotSuccessful: function onModelUpdateError() {
            this.passwordChangeResult = 'failed';
        },

        resetForm: function resetForm() {
            this.formChangePassword.$setPristine();
            this.formChangePassword.$setValidity();
            this.formChangePassword.$setUntouched();
            this.currentPassword = '';
            this.newPassword = '';
            this.passwordConfirm = '';
        },

        doPasswordsMatch: function doPasswordsMatch() {
            return this.newPassword === this.passwordConfirm;
        },

        changePassword: function changePassword() {
            this.isSavingIndicatorActive.value = false;
            this.passwordChangeResult = '';

            var data = {
                current_password: this.currentPassword,
                new_password: this.newPassword,
                id: this.user._id
            };

            if (this.formChangePassword.$invalid) {
                return;
            }

            this.updating = true;
            this.user.changePassword(data)
                .then(function success(resp) {
                    this.changePasswordSuccessful();
                }.bind(this))
                .catch(function error(resp) {
                    if (resp && resp.data && resp.data.error_type === "HBCantReusePasswordError" && resp.data.error_message) {
                        this.PopupMessageService.showErrorAlert(resp.data.error_message)
                    } else {
                        this.changePasswordNotSuccessful();
                    }
                }.bind(this))
                .finally(function() {
                    this.updating = false;
                }.bind(this));
        },

        sentSetPasswordLink: function sentSetPasswordLink() {
            this.loading = true;
            this.UsersManager.sendSetPassword(this.user)
                .then(function success() {
                    this.setPasswordLinkSent = true;
                    this.loading = false;
                }.bind(this))
                .catch(function error() {
                    this.setPasswordLinkError = true;
                    this.loading = false;
                }.bind(this));
        }
    });

    return {
        scope: {
            user: '=user',
            isSavingIndicatorActive: '=isSavingIndicatorActive'
        },
        templateUrl: 'angular/app/modules/core/features/settings/account/password/change_password_template.html',
        controller: ChangePasswordDirectiveController,
        controllerAs: 'pwVM',
        bindToController: true
    };
};