
(function () {
    "use strict";


    // @ngInject
    function WorkspaceFileServiceCtor($state, $q, $translate,
                                      AppStates, PreviewModeService, UIUtils, Enums, DeviceService, ReactModalService, 
                                      AnalyticsService, PopupMessageService, ModalService, OnboardingService, AbTestService,
                                      WorkspaceFilesManager, UsersManager, moment, PvlService, $window, EmailPreviewManager,
                                      _, EventsManager, UiPersistenceService, PendingTasksManager, FeaturesService,
                                      QuestionsStateService, StatsigService, CompaniesManager, ModelFactory) {

        this.$state = $state;
        this.$q = $q;
        this.$translate = $translate;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;
        this.Enums = Enums;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.ModalService = ModalService;
        this.ReactModalService = ReactModalService;
        this.UsersManager = UsersManager;
        this.OnboardingService = OnboardingService;
        this.AbTestService = AbTestService;
        this.moment = moment;
        this.PvlService = PvlService;
        this.$window = $window;
        this.EmailPreviewManager = EmailPreviewManager;
        this.DeviceService = DeviceService;
        this.PreviewModeService = PreviewModeService;
        this.UiPersistenceService = UiPersistenceService;
        this._ = _;
        this.EventsManager = EventsManager;
        this.PendingTasksManager = PendingTasksManager;
        this.FeaturesService = FeaturesService;
        this.QuestionsStateService = QuestionsStateService;
        this.StatsigService = StatsigService;
        this._backToState = null;
        this.CompaniesManager = CompaniesManager;
        this.ModelFactory = ModelFactory;
        this.company = this.CompaniesManager.getCurrCompany();
    }

    function isBookedVersion(file, versionObj) {
        if (versionObj) {
            return versionObj.is_booked;
        } else {
            return file.isBookedVersion();
        }
    }

    function isActiveVersion(file, versionObj) {
        if (versionObj) {
            return versionObj.is_active;
        } else {
            return file.isActiveVersion();
        }
    }

    Services.WorkspaceFileService = Class(function () {
        var registry = {};


        return {

            constructor: WorkspaceFileServiceCtor,

            fileStatuses: {
                new_workspace_create: 0,
                welcome_email_sent: 1,
                welcome_brochure_seen: 2,
                questionnaire_seen: 3,
                questionnaire_answered: 4,
                proposal_created: 5,
                proposal_sent: 6,
                proposal_brochure_seen: 7,
                proposal_seen: 8,
                agreement_seen: 9,
                agreement_signed: 10,
                payments_seen: 11,
                retainer_paid: 12,
                payment_done: 13

            },

            //WORKSPACE_FILE_TYPE = [:proposal, :invoice, :direct_payment, :questionnaire, :brochure, :timeline, :agreement]
            fileTypes: {
                proposal : 0,
                invoice : 1,
                direct_payment : 2,
                questionnaire : 3,
                brochure: 4,
                timeline: 5,
                agreement: 6
            },

            WorkspaceStatus: {
                lead: 0,
                lead_archived: 1,
                client: 2,
                client_archived: 3,
                lead_sent: 4
            },

            getStatusClasses: function getStatusClasses(workspaceFile) {
                return this.getStatusClassesArray(workspaceFile).join(' ');
            },

            openRefundModal: function openRefundModal(user, company, payment, fileId, isUndoMap, isOnRedirect) {
                if (payment.is_manual) {
                    return this.ModalService.openRefundPaymentModal(payment, fileId, isUndoMap, isOnRedirect);
                } else {
                    var defer = this.$q.defer();

                    var fileIdForRefundData = !payment.is_flow_payment ? fileId : null;

                    this.CompaniesManager.getRefundPaymentData(company, fileIdForRefundData, payment._id).then(function getRefundDataSuccess(resp) {
                        if (resp && resp.data) {
                            var modalArgs = {
                                onRefund: function onRefund(data) {

                                    var taskData = {
                                        payment_id: payment._id,
                                        amount_to_refund: data.amount_to_refund,
                                        expected_to_be_charged: data.expected_to_be_charged,
                                        refund_payment_method_id: data.refund_payment_method_id
                                    };

                                    if (payment.is_flow_payment) {
                                        taskData.flow_id = fileId;
                                    } else {
                                        taskData.workspace_file_id = fileId;
                                    }

                                    return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.refundPayment, taskData).then(function () {
                                        defer.resolve();
                                    }.bind(this));
                                }.bind(this)
                            };

                            var paymentData = {
                                invoiceId: payment.invoice,
                                paymentId: payment._id,
                                paymentAmount: payment.amount + (payment.tip_paid || 0),
                                refundedAmount: payment.refund_data ? payment.refund_data.refunded_amount : 0,
                                paymentChargeDate: payment.charge_date,
                                transferredToVendor: payment.transferred_to_vendor,
                                flowId: fileId
                            };

                            var opts = angular.extend(
                                {},
                                {refundData: resp.data},
                                modalArgs,
                                paymentData
                            );
                            this.ReactModalService.openRefundModal(opts);
                        }
                    }.bind(this));

                    return defer.promise;
                }
            },

            getStatusClassesArray: function getStatusClasses(workspaceFile) {
                return [
                    this.UIUtils.generateClassByFileType(workspaceFile.file_type_cd),
                    this.UIUtils.generateClassForFileType(workspaceFile),
                    workspaceFile.status,
                    workspaceFile.status_type,
                    workspaceFile.workspace_status_type,
                    this.UIUtils.generateClassForActiveState(workspaceFile.active_state),
                    this.UIUtils.generateClassForRequest(workspaceFile.request_active),
                    this.UIUtils.generateClassForCanceledState(workspaceFile.is_canceled),
                    this.UIUtils.generateClassForEditableState(workspaceFile.isEditable()),
                    this.UIUtils.generateClassForDraftMode(workspaceFile.isDraft())
                ];
            },

            getSendFileLabel: function getSendFileLabel(workspaceFile, workspace) {
                if (workspaceFile.isDirectPayment()){
                    return 'FILE.SEND_FILE.DIRECT_PAYMENT._PAY_TO_';
                }

                var hasValidRecipient = workspace ? workspace.hasValidRecipient() : workspaceFile.hasValidRecipient();

                if(!hasValidRecipient && workspaceFile.isOwnerMode()) {
                    return 'FILE.SEND_FILE.GENERAL_FILE._NEXT_ADD_CLIENT_';
                }

                var statusToTranslate;
                if (workspaceFile.isSent()) {
                    statusToTranslate = 'FILE.SEND_FILE' + this.getFileTypeTranslationPrefix(workspaceFile.file_type_cd) + '._RESEND_';
                } else {
                    statusToTranslate = 'FILE.SEND_FILE.GENERAL_FILE._NEXT_';
                }

                return statusToTranslate;
            },

            getFileTypeTranslationPrefix: function getFileTypeTranslationPrefix(fileType) {
                var statusToTranslate;

                if (fileType === this.fileTypes.proposal) {
                    statusToTranslate = '.PROPOSAL';
                } else if(fileType === this.fileTypes.invoice){
                    statusToTranslate = '.INVOICE';
                } else if(fileType === this.fileTypes.questionnaire){
                    statusToTranslate = '.QUESTIONNAIRE';
                } else if(fileType === this.fileTypes.brochure){
                    statusToTranslate = '.BROCHURE';
                } else if(fileType === this.fileTypes.timeline){
                    statusToTranslate = '.TIMELINE';
                } else if (fileType === this.fileTypes.agreement) {
                    statusToTranslate = '.AGREEMENT';
                } else {
                    statusToTranslate = '.PROPOSAL';
                }

                return statusToTranslate;
            },

            getStatusLabelForVendor: function getStatusLabelForVendor(fileType, fileStatus) {
                var statusToTranslate = 'FILE.FILE_STATUS';

                statusToTranslate = statusToTranslate + '.VENDOR';

                statusToTranslate = statusToTranslate + this.getFileTypeTranslationPrefix(fileType);

                statusToTranslate = statusToTranslate + '.' + this.Enums.WorkspaceFileStatus[fileStatus];

                return statusToTranslate;
            },

            getStatusLabel: function getStatusLabel(workspaceFile, forceVendor) {
                var statusToTranslate = 'FILE.FILE_STATUS';

                if (forceVendor || workspaceFile.isModeratorMode()) {
                    statusToTranslate += '.VENDOR';
                } else {
                    statusToTranslate += '.CLIENT';
                }

                statusToTranslate += this.getFileTypeTranslationPrefix(workspaceFile.file_type_cd);

                if (workspaceFile.hasPendingPayment()) {
                    statusToTranslate += '.' + '_PAYMENT_PENDING_';
                }
                else {
                    statusToTranslate += '.' + this.Enums.WorkspaceFileStatus[workspaceFile.status_cd];
                }

                return statusToTranslate;

            },

            getFileType: function getFileType(fileTypeEnum){
                for(var fileType in this.fileTypes) {
                    if (fileTypeEnum === this.fileTypes[fileType]){
                        return [fileType][0].replace(/_/g, ' ');
                    }
                }
            },

            getFileCardClasses: function getFileCardClasses(workspaceFile) {
                var cardClasses = this.getStatusClassesArray(workspaceFile);
                return cardClasses.join(' ');
            },

            getProposalLabel: function getProposalLabel(workspaceFile) {
                if (workspaceFile.isProposal()){
                    return 'FILE.RIBBON_LABEL._PROPOSAL_';
                }

                if (workspaceFile.isInvoice()){
                    return 'FILE.RIBBON_LABEL._INVOICE_';
                }

                if (workspaceFile.isQuestionnaire()){
                    return 'FILE.RIBBON_LABEL._QUESTIONS_';
                }

                if (workspaceFile.isBrochure()){
                    return 'FILE.RIBBON_LABEL._BROCHURE_';
                }

                if (workspaceFile.isTimeline()){
                    return 'FILE.RIBBON_LABEL._TIMELINE_';
                }

                if (workspaceFile.isAgreement()){
                    return 'FILE.RIBBON_LABEL._AGREEMENT_';
                }

                return 'FILE.RIBBON_LABEL._PROPOSAL_';
            },

            gotoPayView: function gotoPayView(workspaceFileId, paymentId, extraParams, options) {
                extraParams = extraParams || {};
                this.$state.go(this.AppStates.root_core_workspaceFile_stepPayment, angular.extend(extraParams, {workspace_file_id: workspaceFileId, payment_id: paymentId}), options);
            },

            gotoWorkspaceFileById: function gotoWorkspaceFileById(workspaceFileId, isPreviewMode, params, isReplaceUrl, paymentId){
                var self = this;
                params = params || {};
                return this.WorkspaceFilesManager.getWorkspaceFileStatusData(workspaceFileId).then(
                    function statusSuccess(resp){
                        if (isPreviewMode) {
                            params.shouldExplicitlyExitPreviewtoEditFile = true;
                            params.isSendFilePreview = isPreviewMode === 'send_file_preview';
                            self.PreviewModeService.enter(resp.model, params);
                        } else {
                            var versionObj = null;
                            if(params.version){
                                versionObj = resp.model.file_version_container.versions.find(function (version) {
                                    return version.file_version === params.version;
                                });
                            }
                            self.gotoWorkspaceFileView(resp.model, versionObj, isReplaceUrl, params.reload, paymentId);
                        }

                    },
                    function error(resp) {
                        if (resp) {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.warning,
                                {
                                    translationId: 'FILE.ALERTS._UNABLE_TO_FIND_THE_FILE_',
                                    interpolateParams: {
                                        userName: self.UsersManager.getCurrUser().first_name
                                    }
                                },
                                function successCallback() {
                                    this.$state.go(this.AppStates.root_core_navigation_home);
                                }.bind(this),
                                'FILE.ALERTS._PRINT_ERROR_POPUP_BTN_'
                            );
                        }
                    }.bind(this));
            },

            gotoWorkspaceFileSummary: function gotoWorkspaceFileSummary(workspaceFileId, fileVersion, options){
                this.$state.go(this.AppStates.root_core_workspaceFile, {workspace_file_id: workspaceFileId, file_version: fileVersion, force:true}, options);
            },

            gotoWorkspaceFileView: function gotoWorkspaceFileView(workspaceFile, versionObj, replace, reload, paymentId) {
                var versionNumber = versionObj ? versionObj.file_version : null;
                var workspaceFileId = workspaceFile._id;
                var options = {};
                if (replace){
                    options.location = "replace";
                }

                if(reload) {
                    options.reload = true;
                }

                // brochure - always show the controller and not the summary
                if (workspaceFile.isBrochure()){
                    this.$state.go(this.AppStates.root_core_workspaceFile_brochure, {workspace_file_id: workspaceFileId}, options);
                    return;
                }

                // timeline - always show the controller and not the summary
                if (workspaceFile.isTimeline()){
                    this.$state.go(this.AppStates.root_core_workspaceFile_timeline, {workspace_file_id: workspaceFileId}, options);
                    return;
                }

                if (workspaceFile.isOwnerMode() || workspaceFile.isViewOnlyMode()) {
                    this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                    return;
                }

                // CLIENT!
                if (workspaceFile.isCanceled() || workspaceFile.hasPendingPayment()) {
                    //go to general file view (summary). ignore state
                    this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                    return;
                }

                var status_cd_to_follow = workspaceFile.status_cd;
                if(workspaceFile.hasPayments() && !workspaceFile.isBooked()) {
                    status_cd_to_follow = workspaceFile.file_version_container.active_content.status_cd;
                }

                if (workspaceFile.isQuestionnaire()){
                    this.$state.go(this.AppStates.root_core_workspaceFile_stepQuestionnaire, {workspace_file_id: workspaceFileId}, options);
                    return;
                }

                if (paymentId) { // Redirecting to a specific payment
                    if((workspaceFile.isProposal() && isActiveVersion(workspaceFile, versionObj) && isBookedVersion(workspaceFile, versionObj)) || (workspaceFile.isInvoice() && isActiveVersion(workspaceFile, versionObj))) {
                        this.$state.go(this.AppStates.root_core_workspaceFile_stepPayment, {
                            workspace_file_id: workspaceFileId,
                            payment_id: paymentId
                        }, options);
                        return;
                    }
                }

                switch (status_cd_to_follow) {
                    case this.fileStatuses.proposal_sent:
                    case this.fileStatuses.proposal_seen:
                        if (workspaceFile.isQuestionnaire()) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepQuestionnaire, {workspace_file_id: workspaceFileId}, options);
                        } else if (workspaceFile.isAgreement()) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepAgreement, {workspace_file_id: workspaceFileId}, options);
                        } else if (isActiveVersion(workspaceFile, versionObj) && (!isBookedVersion(workspaceFile, versionObj) || workspaceFile.is_booked_manually)) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepProposal, {workspace_file_id: workspaceFileId}, options);
                        } else {
                            this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                        }

                        break;

                    case this.fileStatuses.agreement_seen:
                        if(!workspaceFile.isQuestionnaire() && isActiveVersion(workspaceFile, versionObj) && (!isBookedVersion(workspaceFile, versionObj) || workspaceFile.is_booked_manually)) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepAgreement, {workspace_file_id: workspaceFileId}, options);
                        } else {
                            this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                        }
                        break;

                    case this.fileStatuses.agreement_signed:
                        if(!workspaceFile.isAgreement() && workspaceFile.isProposal() && isActiveVersion(workspaceFile, versionObj) && isBookedVersion(workspaceFile, versionObj)) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepAgreement, {workspace_file_id: workspaceFileId}, options);
                        } else {
                            this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                        }
                        break;

                    case this.fileStatuses.payments_seen:
                        if ((workspaceFile.isProposal() && isActiveVersion(workspaceFile, versionObj) && isBookedVersion(workspaceFile, versionObj)) || (workspaceFile.isInvoice() && isActiveVersion(workspaceFile, versionObj))) {
                            this.$state.go(this.AppStates.root_core_workspaceFile_stepPayment, {workspace_file_id: workspaceFileId, payment_id: paymentId}, options);
                        } else {
                            this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                        }
                        break;

                    case this.fileStatuses.retainer_paid:
                    case this.fileStatuses.payment_done:
                        this.gotoWorkspaceFileSummary(workspaceFileId, versionNumber, options);
                        break;
                    default:
                        window.console.log("state " + workspaceFile.status + " is not supported");
                        this.$state.go(this.AppStates.root_core_workspaceFile_stepProposal, {workspace_file_id: workspaceFileId}, options);
                }
            },

            printWorkspaceFile: function printWorkspaceFile(workspaceFile) {
                if (this.DeviceService.isInAppBrowser()) {
                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage('hb_app_print', "*");
                } else {
                    var analyticsArgs = {workspace_file: workspaceFile._id};
                    var self = this;
                    workspaceFile.print("all").then(
                        function success() {
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.file_print_successful, analyticsArgs);
                        },
                        function error() {
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.file_print_error, analyticsArgs);

                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.warning,
                                'FILE.ALERTS._PRINT_ERROR_POPUP_', null, 'FILE.ALERTS._PRINT_ERROR_POPUP_BTN_');
                        }
                    );
                }
            },

            whichCurrentFileView: function whichCurrentFileView(workspaceFile) {
                if (!workspaceFile) {
                    return;
                }
                var currentFileView;
                var fileViews = {
                    owner: 'owner',
                    client: 'client',
                    clientPreview: 'preview',
                    sendTestFilePreview: 'test'
                };

                if (workspaceFile.isOwnerMode()) {
                    currentFileView = fileViews.owner;
                }
                if (workspaceFile.isClientMode()) {
                    currentFileView = fileViews.client;
                }

                if (this.PreviewModeService.inPreviewMode) {
                    this.PreviewModeService.inSendFilePreviewMode
                        ? currentFileView = fileViews.sendTestFilePreview
                        : currentFileView = fileViews.clientPreview;
                }

                return currentFileView;
            },

            downloadAsPdfFile: function downloadAsPdfFile(workspaceFile, body) {
                // var analyticsArgs = {workspace_file: workspaceFile._id};
                if (this.DeviceService.isInAppBrowser()) {
                    window.ReactNativeWebView && window.ReactNativeWebView.postMessage('hb_app_print_pdf', "*");
                } else {
                    this.$window.print();
                }
            },

            generateAnalyticsProps: function generateAnalyticsProps(workspaceFile) {
                if (workspaceFile) {

                    var ownerName, ownerEmail, ownerId, ownerUserType, ownerHBUserType, ownerCompanyId, ownerCompanyRole, ownerCompanyName, ownerCompanyType,isNotOwnerOpen,isOwnerOpen;
                    if (workspaceFile.owner) {
                        var owner = workspaceFile.owner;
                        ownerName = owner.full_name;
                        ownerEmail = owner.email;
                        ownerId = owner._id;
                        ownerUserType = owner.system_user_type;
                        ownerCompanyRole = owner.company_role;
                        ownerHBUserType = Array.isArray(owner.hb_user_type) ? owner.hb_user_type[0] : '';
                        var currUser = this.UsersManager.getCurrUser();
                        if (currUser.isVendor() && workspaceFile.owner.isMyTeamMember()) {
                            isNotOwnerOpen = currUser._id !== owner._id;
                        }
                        isOwnerOpen = currUser._id === owner._id;

                        if (owner.company) {
                            var company = owner.company;
                            ownerCompanyId = company._id;
                            ownerCompanyName = company.company_name;
                            ownerCompanyType = company.company_type_name;
                        }
                    }
                    return {file_id: workspaceFile._id,
                        file_title: workspaceFile.file_title,
                        file_type: workspaceFile.file_type,
                        file_status: workspaceFile.status_name,
                        file_workspace_name: workspaceFile.workspace_name,
                        file_event_name: workspaceFile.event ? workspaceFile.event.event_name : '',
                        file_owner_id: ownerId,
                        file_owner_name: ownerName,
                        file_owner_email: ownerEmail,
                        file_owner_user_type: ownerUserType,
                        file_owner_hb_user_type: ownerHBUserType,
                        file_owner_company_id: ownerCompanyId,
                        file_owner_company_name: ownerCompanyName,
                        file_owner_company_type: ownerCompanyType,
                        file_owner_company_role: ownerCompanyRole,
                        is_team_member: isNotOwnerOpen,
                        is_owner: isOwnerOpen

                    };
                }
            },

            ////////////////////////////////////
            // WorkspaceFile model validation //
            ////////////////////////////////////

            /**
             * Running all registered validations according to the order specified.
             * @returns {promise} Since this method runs asynchronously we return a promise.
             */
            validateFileModel: function validateFileModel() {
                return this.$q(function validateQueue(resolve, reject) {
                    var index = 0;
                    var keys = Object.keys(registry).sort(function keySort(a, b) {
                        var aOrder = registry[a].order;
                        var bOrder = registry[b].order;
                        if (aOrder > bOrder) {
                            return -1;
                        } else if (aOrder < bOrder) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    function runSingleTest() {
                        var validator = registry[keys[index++]].validator;
                        if (angular.isFunction(validator)) {
                            validator().then(
                                function success() {
                                    if (keys.length === index) {
                                        resolve();
                                    } else {
                                        runSingleTest();
                                    }
                                },
                                function fail() {
                                    reject();
                                }
                            );
                        }
                    }

                    // start the cycle...
                    if (keys.length) {
                        runSingleTest();
                    } else {
                        resolve();
                    }
                });
            },

            /**
             * Adding a validation method to the file validation mechanism
             * @param {string} key The key to register the validation on
             * @param {function} validator Validation function that must return a promise.
             * @param {number} order Optional. The order to run the validation. The highest number will run first. Default is 0.
             */
            addFileValidation: function addFileValidation(key, validator, order) {
                registry[key] = {validator: validator, order: order || 0};
            },

            /**
             * Removes a validation from the file validation mechanism
             * @param {string} key The key of the validation to remove
             */
            removeFileValidation: function removeFileValidation(key) {
                delete registry[key];
            },

            /** SEND FILE **/
            sendFile: function sendFile(currentUser, workspace, workspaceFile, client, pvlData, hidePvl) {
                return this.$q(function _sendFile(resolve, reject) {
                    this.validateFileModel().then(function success() { // The case the file can be sent
                        if (workspaceFile.isDirectPayment()) {
                            return this.WorkspaceFilesManager.payToUser(workspaceFile, client).then(function success() {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'FILE.SEND_FILE.DIRECT_PAYMENT._PAYMENT_DONE_');
                                resolve();
                            }.bind(this)).catch(function error(res) {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, res.data.error_message);
                                reject();
                            }.bind(this));
                        } else {
                            this.openEmailEditor(currentUser, workspace, workspaceFile, pvlData, hidePvl).then(function fileSent() {
                                resolve();
                                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.send_file, {file_type: workspaceFile.file_type});
                                currentUser.setLastSentFileTime(Date.now());
                                if(workspaceFile.hasPayments()) {
                                    this.AnalyticsService.reportEvent('PayableFileSent', {value: workspaceFile.payments_container.getTotalPaymentsAmount(), currency: currentUser.company.currency});
                                }
                            }.bind(this)).catch(function fileDidNotSend() {
                                reject();
                            });
                        }
                    }.bind(this)).catch(function validationError() {
                        reject();
                    });
                }.bind(this));
            },

            sendFilePreview: function sendFilePreview(currentUser, workspace, workspaceFile) {
                return this.$q(function _sendFile(resolve, reject) {
                    this.openEmailEditor(currentUser, workspace, workspaceFile, null, null, true).then(
                        function fileSent() {
                            resolve();
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.send_file, {file_type: workspaceFile.file_type});
                        }.bind(this),
                        function fileDidNotSend() {
                            reject();
                        }
                    );
                }.bind(this));
            },

            resendFile: function resendFile(currentUser, workspace, workspaceFile, client, pvlData) {
                var self = this;
                if (workspaceFile.isAllowedToReSend()) {
                    return this.$q(function _resendFile(resolve, reject) {
                        // Making sure the workspace file can be sent
                        this.validateFileModel().then(
                            function validateSuccess() {

                                // Get the email 'template'
                                var email = workspaceFile.getEmailToSend();
                                var usersListForEmail = workspace.members.filter(function (user) {
                                    return (!!user.email && !this.UsersManager.isCurrentUser(user._id) && !user.isMyTeamMember());
                                }.bind(this));

                                var options  = {
                                    users: usersListForEmail,
                                    subject: email.subject || "",
                                    body: email.html_body || "",
                                    generalFiles: email.file_attachments,
                                    imageFiles: email.image_attachments,
                                    senderFunction: this._getSendFileFunction(currentUser, workspaceFile, true),
                                    config: this._emailEditorConfig(workspaceFile, currentUser, pvlData, workspace),
                                    previewFunction: function previewSendToUser(user, subject, body, params, attachedFiles) {
                                        return this.previewSendToUser(workspaceFile, user, subject, body, params, attachedFiles);
                                    }.bind(this),
                                    showScheduleOption: true
                                };

                                this.showEmailEditorModal(options).then(
                                    function sendSuccess(){
                                        resolve();
                                        self.AnalyticsService.trackSuccess(self, self.AnalyticsService.analytics_events.send_file, {file_type: workspaceFile.file_type});
                                    }.bind(this),
                                    function sendError() {
                                        reject();
                                    }
                                );
                                
                            }.bind(this),
                            function validateError() {
                                reject();
                            }
                        );
                    }.bind(this));
                }
            },

            showEmailEditorModal: function showEmailEditorModal(options) {
                return this.ReactModalService.openEmailEditorModal(options);
            },

            requestPayment: function requestPayment(currentUser, workspace, workspaceFile, payment) {
                var self = this;
                return this.$q(function _requestPayment(resolve, reject) {

                    var usersListForEmail = workspace.members.filter(function (user) {
                        return (!!user.email && !this.UsersManager.isCurrentUser(user._id) && !user.isMyTeamMember());
                    }.bind(this));

                    var body = "<p>Hi <input disabled=\"disabled\" data-cke-saved-name=\"{field_name:client_first_name_1, field_type:String, field_default:first_client_first_name, field_input_type:String, field_placeholder:First Client First Name, field_changed:false, field_hb_data_type:vendor_field}\" name=\"{field_name:client_first_name_1, field_type:String, field_default:first_client_first_name, field_input_type:String, field_placeholder:First Client First Name, field_changed:false, field_hb_data_type:vendor_field}\" placeholder=\"First Client First Name\" type=\"text\" value=\"\" style=\"width: 45px;\" class=\"vendor-field email-editor-field\" data-cke-editable=\"1\" contenteditable=\"true\">,</p><p>This message is to inform you that a milestone has been completed.<br />Click below to pay for this milestone.</p>"

                    var options  = {
                        users: usersListForEmail,
                        subject: "Your invoice",
                        body: body,
                        senderFunction: this._getRequestMilestonePaymentFunction(currentUser, workspaceFile, payment),
                        config: {editorType: 'request milestone payment', workspace: workspace },
                        showScheduleOption: false
                    };

                    this.showEmailEditorModal(options).then(function sendSuccess(){ resolve(); }.bind(this), function sendError() { reject();});
                    
                }.bind(this));
            },

            _emailEditorConfig: function _emailEditorConfig(workspaceFile, currUser, pvlData, workspace, hidePvl, sendFilePreview) {
                var self=this;
                var config = {};

                config.editorType = "send file";
		        config.sendAsync = true;

                if (workspaceFile && workspaceFile.owner._id !== currUser._id) {
                    var headerLabelText = this.$translate.instant('FILE.SEND_FILE.EMAIL_MODAL._NOT_OWNER_LABEL_', {ownerName: workspaceFile.owner.full_name, ownerEmail: workspaceFile.owner.email});
                    config.headerLabel = "<p>" + headerLabelText + "</p>";
                    config.fileOwner = workspaceFile.owner;

                    config.beforeSendCallback = function() {
                        self.analyticsArgs = {owner: workspaceFile.owner._id, team_member: currUser._id, app_path:"email_editor"};
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.sending_file_on_behalf_of_owner_open_email, self.analyticsArgs);
                        var promise =  this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info,
                            { translationId: 'FILE.SEND_FILE._SEND_ON_BEHALF_ ', interpolateParams: {ownerName: workspaceFile.owner.full_name, ownerEmail: workspaceFile.owner.email} }
                        );
                        promise.then(function(analyticsArgs) {
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.sending_file_on_behalf_of_owner_confirmed, self.analyticsArgs);
                        }, function(analyticsArgs) {
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.sending_file_on_behalf_of_owner_canceled, self.analyticsArgs);
                        });

                        return promise;
                    }.bind(this);

                }
                // TODO: Alon
                if (!hidePvl) {
                    this.PvlService.getPvlConfigForEmailEditor(config, workspaceFile);
                }

                // if ((workspaceFile.isProposal() || workspaceFile.isInvoice()) && !workspaceFile.isBooked() &&
                //     workspaceFile.isOwnedByCurrUser() &&
                //     (!workspaceFile.preferred_vendors_list || !workspaceFile.preferred_vendors_list.preferred_vendor_ids ||
                //         workspaceFile.preferred_vendors_list.preferred_vendor_ids.length === 0)) {
                //     config.showSharePVL = true;
                // }
                // if (pvlData && pvlData.pvList && pvlData.pvList.length > 0) {
                //     config.pvList = pvlData.pvList;
                //     config.pvlParams = pvlData.pvlParams;
                // }

                // Add the current workspace to the config so we know where to add any new participants
                if (workspace){
                    config.workspace = workspace;
                    config.showAddWsParticipantBtn = true;
                }
                config.ckeditor = {
                    includeToolbarItems: ['AddClientName', 'AddEmailSignature', 'addFileAttachment'],
                    height: "300px"
                };

                if (currUser.calendly_url) {
                    config.ckeditor.includeToolbarItems.push('addCalendlyLink');
                }

                if (sendFilePreview) {
                    config.sendAsync = false;
                    config.sendFilePreview = sendFilePreview;
                    config.sendButtonText = 'EMAIL_EDITOR._SEND_MYSELF_';
                    config.showAddWsParticipantBtn = false;
                    config.showBccMyselfCheckbox = false;
                    config.showSharePVL = false;
                    config.showPreviewBtn = false;
                    config.visualDisableInput = true;
                    config.fileType = workspaceFile.file_type;
                }

                return config;
            },

            openEmailEditor: function openEmailEditor(currentUser, workspace, workspaceFile, pvlData, hidePvl, sendFilePreview) {
                var myId = currentUser._id;
                // Get the email 'template'
                var email = workspaceFile.getEmailToSend();
                var usersListForEmail = [];
                if(sendFilePreview) {
                    usersListForEmail = [currentUser];
                } else {
                    usersListForEmail = workspace.members.filter(function (user) {
                        return (!!user.email && user._id !== myId && !user.isMyTeamMember());
                    });
                }

                var options  = {
                    users: usersListForEmail,
                    subject: email && email.subject,
                    body: email && email.html_body,
                    generalFiles: email && email.file_attachments,
                    imageFiles: email && email.image_attachments,
                    senderFunction: this._getSendFileFunction(currentUser, workspaceFile, false, sendFilePreview),
                    config: this._emailEditorConfig(workspaceFile, currentUser, pvlData, workspace, hidePvl, sendFilePreview),
                    previewFunction: function previewSendToUser(user, subject, body, params, attachedFiles) {
                        return this.previewSendToUser(workspaceFile, user, subject, body, params, attachedFiles);
                    }.bind(this),
                    showScheduleOption: true
                };

                return this.showEmailEditorModal(options);
            },

            _getSendFileFunction: function _getSendFileFunction(currUser, workspaceFile, isResend, sendFilePreview) {

                if (!sendFilePreview) {
                    return function asyncSenderFunction(props) {
                        var users = props.users;
                        var subject = props.subject;
                        var body = props.body;
                        var params = props.params;
                        var attachedFiles = props.attachedFiles;
                        var scheduledSendDate = props.scheduledSendDate;
                        var data = {
                            file_id: workspaceFile._id,
                            recipients_ids: users.map(function(user) { return user._id; }),
                            email: {
                                subject: subject,
                                html_body: this.UIUtils.toBinaryBase64(body),
                                email_type: 'proposal'
                            },
                            pvl_id: params['pvlId'],
                            image_attachments: attachedFiles.imageFiles,
                            file_attachments: attachedFiles.generalFiles,
                            is_resend: !!isResend,
                            scheduled_send_date: scheduledSendDate
                        };
                        return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendFile, data).then(function taskCompleted(data) {
                            this.OnboardingService.setSendFileComplete(workspaceFile);
                            return data;
                        }.bind(this))
                        .catch(function fail(res) {
                            var message;
                            // pending task returns a string... :(
                            if (res && res.indexOf('date is limited') !== -1) {
                                message = res;
                            } else {
                                message = 'ERRORS.SERVER_API._UNEXPECTED_';
                            }
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                        }.bind(this));
                    }.bind(this);
                }

                return function senderFunction(props) {
                    var user = props.users;
                    var subject = props.subject;
                    var body = props.body;
                    var isLast = props.isLast;
                    var params = props.params;
                    var attachedFiles = props.attachedFiles;
                    var scheduledSendDate = props.scheduledSendDate;
                    params.isResend = !!isResend;
                    return this.sendToUser(workspaceFile, user, subject, body, isLast, params, attachedFiles, scheduledSendDate);
                }.bind(this);
            },

            _getRequestMilestonePaymentFunction: function _getRequestMilestonePaymentFunction(currUser, workspaceFile, payment, scheduledSendDate) {
                return function senderFunction(props) {
                    var user = props.users;
                    var subject = props.subject;
                    var body = props.body;
                    var params = props.params;
                    var attachedFiles = props.attachedFiles;
                    var scheduledSendDate = props.scheduledSendDate;

                    var data = {
                        file_id: workspaceFile._id,
                        payment_id: payment._id,
                        recipients_ids: user._id,
                        email: {
                            subject: subject,
                            html_body: body
                        },
                        image_attachments: attachedFiles.imageFiles,
                        file_attachments: attachedFiles.generalFiles,
                        scheduled_send_date: scheduledSendDate
                    };
                    return this.WorkspaceFilesManager.requestPayment(workspaceFile, payment._id, data, !!payment.flow_id);
                }.bind(this);
            },

            sendToUser: function sendToUser(workspaceFile, user, subject, body, isLast, params, attachedFiles, scheduledSendDate) {
                var sendFilePreview = this.UsersManager.isCurrentUser(user._id);
                var email = workspaceFile.getEmailToSend();
                var promise;
                if(sendFilePreview) {
                    promise = this.WorkspaceFilesManager.sendFilePreview(workspaceFile, subject, body, email.email_type, attachedFiles);
                } else {
                    promise = this.WorkspaceFilesManager.sendFileToUser(workspaceFile, user, subject, body, email.email_type, isLast, params, attachedFiles, scheduledSendDate);
                }
                promise.then(function sendToUserSuccess() {
                    if (isLast && (workspaceFile.isProposal() || workspaceFile.isInvoice())) {
                        this.OnboardingService.setSendFileComplete(workspaceFile);
                    }
                }.bind(this));

                return promise;
            },

            previewSendToUser: function previewSendToUser(workspaceFile, user, subject, body, params ,attachedFiles) {
                var self = this;
                var fileOwner = workspaceFile.owner;
                var fileType = workspaceFile.file_type;
                var gaAnalyticsTrack = this.AnalyticsService;
                var email = workspaceFile.getEmailToSend();
                var promise = this.EmailPreviewManager.getWorkspaceFileEmailPreview(workspaceFile, user, subject, body, email.email_type, attachedFiles);
                promise.then(
                    function success() {
                        console.log('success');
                    },
                    function error() {
                        console.log('error');
                    }
                );

                return promise;
            },

            setCreatingFileListener: function setCreatingFileListener(callback){
                this.creatingFileListener = callback;
            } ,

            removeCreatingFileListener: function removeCreatingFileListener(callback){
                this.creatingFileListener = null;
            } ,

            setCreatingFile: function setCreatingFile(){
                if(this.creatingFileListener){
                    this.creatingFileListener.setCreatingFile();
                }
            },

            removeCreatingFile: function removeCreatingFile(){
                if(this.creatingFileListener){
                    this.creatingFileListener.removeCreatingFile();
                }
            },

            getLastEditedText: function getLastEditedText(workspaceFile, user) {
                if (workspaceFile.last_edited_by.user_id == null) {
                    return "";
                } else {
                    var date = this.moment(workspaceFile.last_edited_by.last_edited_on).format('MMM DD, h:mm A');
                    if (workspaceFile.last_edited_by.user_id !== user._id) {
                        var maxNameLength = 75;
                        if(workspaceFile.last_edited_by.user_full_name.length > maxNameLength){
                            return this.$translate.instant('FILE.HEADER._LAST_EDITED_AT_', {
                                date: date
                            });
                        } else {
                            return this.$translate.instant('FILE.HEADER._LAST_EDITED_BY_USER_', {
                                name: workspaceFile.last_edited_by.user_full_name,
                                date: date
                            });
                        }
                    } else if (user.company.doesCurrUserHaveTeamMembers()){
                        return this.$translate.instant('FILE.HEADER._LAST_EDITED_BY_YOU_', {
                            date: date
                        });
                    }
                    else{
                        return this.$translate.instant('FILE.HEADER._LAST_EDITED_AT_', {
                            date: date
                        });
                    }


                }
            },

            showPreferredVendorList: function showPreferredVendorList(workspaceFile) {
                return this.PvlService.showPreferredVendorList(workspaceFile.preferred_vendors_list._id, workspaceFile.event._id);
            },

            /** CLIENT ON-SITE **/
            bookInPerson: function bookInPerson(currentUser, workspace, workspaceFile, client, pvlData) {

                return this.$q(function _sendFile(resolve, reject) {
                    // Making sure the workspace file can be sent
                    this.validateFileModel().then(
                        function success() { // The case where the client can login

                            var usersListForEmail = workspace.members.filter(function (user) {
                                return (!!user.email && user._id !== currentUser._id && !user.isMyTeamMember());
                            });

                            if(usersListForEmail.length > 0){
                                client = usersListForEmail[0];
                            }

                            // Launch login as client modal
                            this.ModalService.openLoginAsClientModal(client, workspaceFile._id).then(
                                function success(result) {
                                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.login_as_client, {file_id: workspaceFile._id});
                                    // Send generic login email to client so they can access the file later on
                                    if(result){
                                        this.sendLoginEmailToClient(client, workspaceFile);
                                    }
                                    resolve(result);
                                }.bind(this),
                                function fail() {
                                    reject();
                                }
                            );
                        }.bind(this),
                        function validationError() {
                            reject();
                        }
                    );
                }.bind(this));
            },

            sendLoginEmailToClient: function sendLoginEmailToClient(client, workspaceFile){
                var emailTemplate = workspaceFile.getEmailToSend();
                var body = '<div style="color:#8d9aa5;padding-left: 10px;padding-top: 5px;font-family: sans-serif;margin:0; line-height:17px;">' +
                        '<p style="padding-top: 5px;font-family: arial,sans-serif;font-size: 14px;margin:0;">' +
                        'This ' + emailTemplate.email_type + ' was shared with you in person.</p></div>'
                return this.sendToUser(workspaceFile, client, emailTemplate.subject, body, true, {clientLogin: true});
            },

            arePaymentsAndProposalAmountsEqual: function arePaymentsAndProposalAmountsEqual(paymentsContainer) {
                var proposalValue = paymentsContainer.getTotalProposalValue();
                var paymentsAmount = paymentsContainer.getTotalPaymentsAmount();
                if (proposalValue === undefined || paymentsAmount === undefined) {
                    return false;
                }
                return proposalValue.toFixed(2) === paymentsAmount.toFixed(2);
            },

            setShowTemplatesMenu: function setShowTemplatesMenu() {
                if (!this.DeviceService.checkIfMobileSize()) {
                    this.showTemplatesMenu = true;
                }
            },

            shouldShowTemplatesMenu: function shouldShowTemplatesMenu() {
                var oldValue = this.showTemplatesMenu;
                this.showTemplatesMenu = false;
                return oldValue;
            },

            createFileByType: function createFileByType(fileType, workspace, recentFileId, templateId) {

                var promise;

                switch (fileType){
                    case "invoice":
                        promise = this.WorkspaceFilesManager.createInvoice(workspace, recentFileId, templateId);
                        break;
                    case "proposal":
                        promise = this.WorkspaceFilesManager.createProposal(workspace, recentFileId, templateId);
                        break;
                    case "agreement":
                        promise = this.WorkspaceFilesManager.createAgreement(workspace, recentFileId, templateId);
                        break;
                    case "questionnaire":
                        promise = this.WorkspaceFilesManager.createQuestionnaire(workspace, recentFileId, templateId);
                        break;
                    case "timeline":
                        promise = this.WorkspaceFilesManager.createTimeline(workspace, recentFileId, templateId);
                        break;
                    case "brochure":
                        promise = this.WorkspaceFilesManager.createBrochure(workspace, recentFileId, templateId);
                        break;
                    case "setPaymentInvoice":
                        promise = this.WorkspaceFilesManager.createSetPaymentInvoice(workspace, recentFileId, templateId);
                        break;
                    default:
                        break;
                }

                return promise;
            },

            createFileByTypeWithProject: function createFileByType(fileType, recentFileId, templateId, projectData) {
                var promise = this.WorkspaceFilesManager.createWorkspaceFileAndProject(fileType, recentFileId, templateId, projectData);
                return promise;
            },

            _createFileSuccess: function _createFileSuccess(response, fileType, fileOriginType, workspace_file_id, isReplaceUrl) {

                // if invoice or proposal we mark it for onboarding
                if(fileType === "invoice" || fileType === "proposal") {
                    this.OnboardingService.setOnboardingWorkspaceFile(workspace_file_id);
                    this.OnboardingService.setStepComplete(this.OnboardingService.OnboardingSteps.createFile);
                }

                if(fileOriginType !== this.Enums.createFileOriginType.template) {
                    this.setShowTemplatesMenu();
                }

                this.gotoWorkspaceFileById(workspace_file_id, null, null, isReplaceUrl);
            },

            _createFileError: function _createFileError(response) {
                this.removeCreatingFile();
            },

            createFile: function createFile(fileType, workspace, recentFileId, templateId, user, isReplaceUrl) {

                // no vendors, but we have clients (for setPaymentInvoice)
                if(fileType === "setPaymentInvoice" && !workspace.shouldAllowSetPaymentInvoice(user)) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                        'WORKSPACE.DIRECT_PAYMENT._NO_VENDOR_OR_TO_MANY_MEMBERS_');
                    return this.$q.reject();
                }

                // mark as in progress
                this.setCreatingFile();

                var fileOriginType = this.Enums.createFileOriginType.blank;
                if(recentFileId) {
                    fileOriginType = this.Enums.createFileOriginType.file;
                } else if(templateId) {
                    fileOriginType = this.Enums.createFileOriginType.template;
                }

                // call create
                var promise = this.createFileByType(fileType, workspace, recentFileId, templateId);

                promise.then(
                    function createFileSuccess(response) {
                        var workspace_file_id = response.data._id;
                        this._createFileSuccess(response, fileType, fileOriginType, workspace_file_id, isReplaceUrl);
                    }.bind(this))
                    .catch(this._createFileError.bind(this));

                return promise;
            },

            createFileWithProject: function createFileWithProject(fileType, recentFileId, templateId, projectData) {

                // mark as in progress
                this.setCreatingFile();

                var fileOriginType = this.Enums.createFileOriginType.blank;
                if(recentFileId) {
                    fileOriginType = this.Enums.createFileOriginType.file;
                } else if(templateId) {
                    fileOriginType = this.Enums.createFileOriginType.template;
                }

                // call create
                var promise = this.createFileByTypeWithProject(fileType, recentFileId, templateId, projectData);

                promise.then(
                    function createFileSuccess(response) {
                        this._createFileSuccess(response, fileType, fileOriginType, response.data.workspace_file_id);
                    }.bind(this))
                    .catch(this._createFileError.bind(this));

                return promise;
            },

            /////////////////////////////////////////////////
            // File Validations
            ////////////////////////////////////////////////
            validateFileTitle: function validateFileTitle(workspaceFile, defaultFileName) {
                return this.$q(function fileTitleValidationPromise(resolve, reject) {
                    if (!!workspaceFile.file_title && workspaceFile.file_title !== defaultFileName) {
                        resolve();
                    } else {
                        var promise = this.ModalService.openFileTitleModal(workspaceFile);
                        promise.then(
                            function success() {
                                this.WorkspaceFilesManager.saveWorkspaceFile(workspaceFile);
                                resolve();
                            }.bind(this),
                            function fail() {
                                reject();
                            }
                        );
                    }
                }.bind(this));
            },

            membersFileValidation: function membersFileValidation(workspaceFile, workspace, cb) {
                return this.$q(function membersValidationPromise(resolve, reject) {
                    // check there is at least one user with email address in the members list
                    if (workspace.hasValidRecipient()) {
                        resolve();
                    } else {

                        // user is file owner - let them add users to workspace
                        if (workspaceFile.isOwnerMode()) {
                            return this.ReactModalService.openGlobalModal('AddClientToWorkspaceModalWrapper', {
                                workspaceId: workspace._id,
                                customFieldSchemas: this.company.custom_contact_fields
                            }).then(function (response) {
                                        // add user to workspace members
                                        if (response.user) {
                                            var newClientUser = this.ModelFactory.newUserModel();
                                            var clientUser = angular.extend(newClientUser, response.user);
                                            workspace.members.add(clientUser);
                                        }
                                        if (cb) {
                                            cb()
                                        }
                                        return response;
                                    }.bind(this));
                        } else {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, {
                                translationId: 'FILE.SEND_FILE._NO_MEMBERS_ON_BEHALF_ ',
                                interpolateParams: {ownerName: workspaceFile.owner.full_name}
                            });
                            reject();
                        }

                    }
                }.bind(this));
            },

            questionsFileValidation: function questionsFileValidation(workspaceFile) {
                return this.$q(function questionsFileValidationPromise(resolve, reject) {

                    // check questions validation
                    var isValid = workspaceFile.file_dynamic_content.isQuestionsValid();

                    // pass validation if valid
                    if (isValid) {
                        resolve();
                    } else {

                        // trigger error check to diaply in the UI
                        this.QuestionsStateService.triggerErrorCheck();

                        // show error message
                        var errorMessage = 'Just a sec, some of the fields are still empty. press Confirm and look for the red fields.';
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, errorMessage);

                        // reject the validation
                        reject();
                    }
                }.bind(this));
            },

            /**
             * Returns a list of filmstrip links given a workspace file.
             *
             * @param {Object} workspaceFile - Workspace model object
             * @param {Boolean} isExampleProposal - Used to determine if the
             *        links should be configured for the example proposal flow.
             * @param {String} activeId - The active filmstrip item ('proposal', 'agreement', 'pay')
             * @return {Array} links - A list of filmstrip items
             *
             * TODO: Currently this only supports the filmstrip items in the stepped workspace flow.
             * Continue to refactor all filmstrip item generation logic here.
             */
            getFilmstripLinksForWorkspace: function(workspaceFile, activeId, stateParams) {
                var _isProposal = workspaceFile.isProposal();
                var _isInvoice = workspaceFile.isInvoice();

                var links = [];

                // If this is not an example proposal, we want the user force the
                // client through each step of the workspace so the do not miss
                // anything.
                var enableAgreement = workspaceFile.isAgreementSeen();
                var enablePayments = workspaceFile.isPaymentsSeen() && !workspaceFile.hasOnlyNotCompletedMilestonePayments();
                var isPreviewMode = this.PreviewModeService.isInPreviewMode();

                var linkToProposal = {};
                angular.extend(linkToProposal, { workspace_file_id: workspaceFile._id, preventStateChangeModals: isPreviewMode }, stateParams);
                linkToProposal = "(" + JSON.stringify(linkToProposal) + ")";

                links.push({
                    enabled: true,
                    isActive: activeId === 'proposal',
                    label: this.getProposalLabel(workspaceFile),
                    sparam: linkToProposal,
                    sref: (_isInvoice || _isProposal) ? this.AppStates.root_core_workspaceFile_stepProposal : this.AppStates.root_core_workspaceFile_stepAgreement,
                });

                if (_isProposal) {
                    links.push({
                        enabled: enableAgreement || isPreviewMode,
                        isActive: activeId === 'agreement',
                        label: 'FILE.FILMSTRIP._AGREEMENT_',
                        sparam: linkToProposal,
                        sref: this.AppStates.root_core_workspaceFile_stepAgreement,
                    });
                }

                if (_isInvoice || _isProposal) {
                    links.push({
                        enabled: enablePayments || isPreviewMode,
                        isActive: activeId === 'pay',
                        label: 'FILE.FILMSTRIP._PAY_',
                        sparam: linkToProposal,
                        sref: this.AppStates.root_core_workspaceFile_stepPayment,
                    });
                }



                return links;
            },

            formatFileTypeToDisplayName: function formatFileTypeToDisplayName(fileType, capitalize) {
                var fileTypeName = fileType === 'agreement' ? 'contract' : fileType;
                return capitalize ? this.UIUtils.capitalize(fileTypeName) : fileTypeName;
            },

            isNewBrochure: function isNewBrochure(currFile) {
                return currFile.is_new_brochure;
            },

            validateBrochureSelection: function validateBrochureSelection(workspaceFile) {
                var fileContent = workspaceFile.file_dynamic_content;
                var sections = fileContent.file_sections;
                var allSectionsValid = sections.every(function(section) {
                    return section.isSelectionValid();
                });
                return allSectionsValid;
            },

            shouldShowClientPreviewModal: function shouldShowClientPreviewModal() {
                var clientFlowHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.clientFlowModal) || {};
                return !clientFlowHash.seen;
            },

            clientPreviewModalIfNeeded: function clientPreviewModalIfNeeded(fileType) {
               if (!this.shouldShowClientPreviewModal()) {
                   var deferred = this.$q.defer();
                   deferred.resolve();
                   return deferred.promise;
               }

                return this.ModalService.openClientFlowModal({fileType: fileType})
                    .finally(function () {
                        this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.clientFlowModal, {seen: true});
                    }.bind(this));
            },

            shouldShowSendFilePreview: function shouldShowSendFilePreview(workspaceFile) {
                if (
                    workspaceFile
                    && workspaceFile.isBookable()
                    && !this.DeviceService.checkIfMobileSize()
                ) {
                    var currUser = this.UsersManager.getCurrUser();
                    return !currUser.isActivated();
                }
            }

        };
    });
}());
