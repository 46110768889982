(function () {
    'use strict';

    // @ngInject
    function ScheduleEmailSendControllerCtor($, $modalInstance, $document, $scope, $translate, $injector, $window,
                                       _, ModalService, ContactsManager, PopupMessageService, $q, AnalyticsService, moment, DeviceService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ScheduleEmailSendController';

        this.ModalService = ModalService;
        this.$modalInstance = $modalInstance;
        this.$translate = $translate;
        this.$window = $window;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.moment = moment;
        this.$ = $;
        this._ = _;
        this.$q = $q;

        this.futureDaysLimit = 538; // 18 months
        this.TIME_FORMAT = 'h:mm A';
        this.customTimePickerSelected = false;
        this.isMobile = this.DeviceService.checkIfMobileSize();
        this.updateTitle();

        var now = new Date();
        var hours = now.getHours();
        var date_to_suggest = new Date();
        var title_day = 'This';

        // Jump to next day after 7am
        if (hours > 7) {
            date_to_suggest.setDate(date_to_suggest.getDate() + 1);
            title_day = 'Tomorrow';
        }
        this.selectableOptions = [
            {
                title: title_day + ' morning',
                date: date_to_suggest.setHours(8,0,0)
            },
            {
                title: title_day + ' noon',
                date: date_to_suggest.setHours(12,0,0)
            },
            {
                title: title_day + ' evening',
                date: date_to_suggest.setHours(18,0,0)
            }
        ];

        this.submitError = false;
    }

    // @ngInject
    Controllers.ScheduleEmailSendController = Class(Controllers.BaseController, {

        constructor: ScheduleEmailSendControllerCtor,

        predefinedOptionSelected: function predefinedOptionSelected(epochSelectedDate) {
            this.close(epochSelectedDate);
        },

        close: function close(epochSelectedDate) {
            this.$modalInstance.close({epochSelectedDate:epochSelectedDate});
        },

        dismiss: function dismiss() {
            this.$modalInstance.dismiss();
        },

        customTimeSelect: function() {
            this.customTimePickerSelected = true;
            this.updateTitle();
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.schedule_send_custom_date);
        },

        updateTitle: function updateTitle() {
            this.modalTitle = this.customTimePickerSelected ? 'SCHEDULE_EMAIL.MODAL._CUSTOM_SELECT_' : 'SCHEDULE_EMAIL.MODAL._PREDEFINED_TITLE_';
        },

        customTimeConfirmed: function customTimeConfirmed() {
            var date = this.sendDate;
            var time = this.sendTime;
            if(this._isValidDateAndTime(date, time)) {
                var epochSelectedDate = this._formatTime(date, time);
                this.close(epochSelectedDate);
            } else {
                this._showError(date, time, true);
            }
        },

        onFieldChange: function onFieldChange() {
            this.submitError = false;
            this.submitErrorMessage = '';
            if(!this._isValidDateAndTime(this.sendDate, this.sendTime)) {
                this._showError(this.sendDate, this.sendTime, false);
            }
        },

        _showError: function _showError(date, time, isOnSubmit) {
            if(isOnSubmit && !date) {
                this.submitErrorMessage = 'SCHEDULE_EMAIL.MODAL._MISSING_DATE_';
                this.submitError = true;
            } else if (isOnSubmit && !time){
                this.submitErrorMessage = 'SCHEDULE_EMAIL.MODAL._MISSING_TIME_';
                this.submitError = true;
            } else if (!this.futureDateInAllowedPeriod(date)) {
                var maxDate = this.moment().add(this.futureDaysLimit, 'days').format('LL');
                this.submitErrorMessage = this.$translate.instant('SCHEDULE_EMAIL.MODAL._FUTURE_DATE_NOT_VALID_', {maxDate: maxDate});
                this.submitError = true;
            } else if(date && time){
                this.submitErrorMessage = 'SCHEDULE_EMAIL.MODAL._TIME_IS_IN_PAST_';
                this.submitError = true;
            }
        },

        _isValidDateAndTime: function _isValidDateAndTime(date, time) {
            return  date && time && this._isValidTime(date, time) && this.futureDateInAllowedPeriod(date);
        },

        _isValidTime: function _isValidTime(date, time) {
            var format = 'YYYY-MM-DD HH:mm a';
            var dateAndTime = date + ' ' + time;
            var formattedDateAndTime = this.moment(dateAndTime).format(format);
            var now = this.moment().format(format);
            return this.moment(now).isBefore(formattedDateAndTime);
        },

        futureDateInAllowedPeriod: function futureDateInAllowedPeriod(date) {
            // Client pending task has a 18 months retention
            return this.moment(date).diff(this.moment(), "days") < this.futureDaysLimit;
        },

        _formatDate: function _formatDate(date, targetFormat) {
            if(date) {
                return this.moment(date).format(targetFormat);
            }
            return null;
        },

        _formatTime: function(date, time) {
            time = this.moment(time, this.TIME_FORMAT);
            return this.moment(date).set({
                'hour': time.get('hour'),
                'minute': time.get('minute'),
                'second': time.get('second')
            }).valueOf();
        }

    });


}());
