(function () {
    'use strict';

    // @ngInject
    function TitleSecondLevelNavigationControllerCtor(
        $scope,
        $injector,
        $state,
        $translate,
        UsersManager,
        Enums
    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TitleSecondLevelNavigationController';

        this.currUser = UsersManager.getCurrUser();
        this.Enums = Enums;
        this.$translate = $translate;
        this.$state = $state;
        this.title = this.$state.$current.data.title;
    }

    Controllers.TitleSecondLevelNavigationController = Class(Controllers.BaseController, {
        constructor: TitleSecondLevelNavigationControllerCtor
    });
}());
