/**
 *
 * This directive highlights the text of an input on focus
 *
 */


(function () {
    'use strict';

    // @ngInject
    Directives.SelectOnFocus = function SelectOnFocus($timeout) {

        function SelectOnFocusLink($scope, element) {
            element.on('focus', function () {
                $timeout(function () {
                    if ($(element).is(":focus")) {
                        this.select();
                    }
                }.bind(this), 50);
            });
        }

        return {
            restrict: 'A',
            link: SelectOnFocusLink
        };
    };


}());
