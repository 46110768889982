(function () {
    "use strict";

    const SIZES = {
        default: {
            xs: {
                height: 8,
                width: 84
            },
            sm: {
                height: 11,
                width: 116
            },
            md: {
                height: 14,
                width: 147
            },
            lg: {
                height: 18,
                width: 189
            },
            xl: {
                height: 24,
                width: 252
            }
        },
        short: {
            xs: {
                height: 10,
                width: 19
            },
            sm: {
                height: 14,
                width: 27
            },
            md: {
                height: 18,
                width: 34
            },
            lg: {
                height: 24,
                width: 45
            },
            xl: {
                height: 32,
                width: 60
            }
        }
    };

    // @ngInject
    function NewHbLogoComponentControllerFunc($injector, $scope, uuid4) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NewHbLogoComponentController';

        this._type = SIZES[this.type] || SIZES['default'];
        this.dimensions = this._type[this.size] || this._type['XL'];
        this.fillColor = this.color || '#131416';
        this.svgLink = `https://hbstatic.honeybook.com/assets_system/system_web/logos/new_logo${this.type === 'short' ? '_short' : ''}${this.suffix ? this.suffix : ''}.svg`;

        this.maskId = uuid4.generate();
    }

    var NewHbLogoComponentController = Class(Controllers.BaseController, {
        constructor: NewHbLogoComponentControllerFunc,
    });

    Components.NewHbLogo = {
        bindings: {
            color: '@',
            type: '@',
            size: '@',
            suffix: '@'
        },
        controller: NewHbLogoComponentController,
        controllerAs: 'newHbLogoVm',
        name: 'hbNewHbLogo',
        templateUrl: 'angular/app/modules/common/svgs/new_hb_logo/new_hb_logo_directive_template.html',
    };
}());