Directives.FeedGenericMessageViewerDirective =  function FeedCommentViewerDirective(){

    // @ngInject
    function FeedGenericMessageViewerDirectiveControllerFunc($translate, $element, $, AppStates, $compile, $scope, $injector){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedGenericMessageViewerDirectiveController';

        this.template = this.genericMessageModel.message_template;
    }

    var FeedGenericMessageViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedGenericMessageViewerDirectiveControllerFunc
    });

    return {
        scope:{
            genericMessageModel: '=genericMessageModel'
        },
        templateUrl : 'angular/app/modules/core/features/feed/viewer/feed_generic_message_viewer_directive_template.html',
        controller : FeedGenericMessageViewerDirectiveController,
        controllerAs : 'feedGenericMessageViewerVm',
        bindToController : true
    };
};