
// @ngInject
Directives.AutoInputSizeDirective = function AutoInputSizeDirective(AutoInputSizeService) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function autoInputSizeLink($scope, $element, attr, ngModelController) {
            if (ngModelController.$options && ngModelController.$options["updateOn"] === "blur") {
                //add listeners which will update text size when input text changes, even if model still hasn't changed
                $element.on('keyup.inputSize.honeybook', function() {
                    AutoInputSizeService.setSize($element);
                });

                // We need this to set the initial input size
                // We're not using $timeout to avoid an unnecessary digest cycle
                setTimeout(function() {
                    AutoInputSizeService.setSize($element);
                });

                $scope.$on('$destroy', function() {
                    $element.off('inputSize.honeybook');
                });
            }

            $scope.$watch(function valueToWatch() {
                return ngModelController.$modelValue;
            }, function onModelChanged() {
                AutoInputSizeService.setSize($element);
            });
        }
    }
};

