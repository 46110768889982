(function () {
    "use strict";


    // @ngInject
    function WorkspacesManagerCtor($q, APIService, Routes, ModelFactory, RepositoryService, EventsManager, WorkspaceFilesManager, FeedService, PubSubService, _, AnalyticsService, PendingTasksManager, PopupMessageService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.EventsManager = EventsManager;
        this.RepositoryService = RepositoryService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.FeedService = FeedService;
        this.AnalyticsService = AnalyticsService;
        this.PendingTasksManager = PendingTasksManager;
        this.PopupMessageService = PopupMessageService;
        this.$q = $q;
        this._ = _;

        PubSubService.ventMyBitchUp(this);
    }


    Services.WorkspacesManager = Class(Services.BaseModelManager, {

        constructor: WorkspacesManagerCtor,

        /*****************************************
         * FILE ACTIONS
         ******************************************/

        newWorkspace: function newWorkspace() {
            return this.ModelFactory.newWorkspaceModel();
        },

        newWorkspaces: function newWorkspaces() {
            return this.ModelFactory.newWorkspacesCollection();
        },

        blockWorkspace: function blockWorkspace(workspace, reason) {
            var workspaceId = workspace._id;
            var url = this.Routes.v2_block_workspace_path(workspaceId);
            var promise = this.apiUpdate(url, {reason: reason}, workspace, true);

            promise.then(
                this._workspaceBlockedSuccess.bind(this)
            );

            return promise;
        },

        unblockWorkspace: function unblockWorkspace(workspace) {
            var workspaceId = workspace._id;
            var url = this.Routes.v2_unblock_workspace_path(workspaceId);
            var promise = this.apiUpdate(url, null, workspace, true);

            promise.then(
                this._workspaceBlockedSuccess.bind(this)
            );

            return promise;
        },

        _workspaceBlockedSuccess: function _workspaceBlockedSuccess(resp) {
            // we just want to refresh the cache
            this.EventsManager.getEvent(resp.data.event._id, true);
            this.EventsManager.getEventsMasterCollection().trigger("workspaceStatusUpdated");
        },

        isDeletable: function isDeletable(workspaceId) {
            var url = this.Routes.v2_get_is_deletable_for_workspace_path(workspaceId);

            return this.apiFetch(url, null, false);
        },

        deleteWorkspace: function deleteWorkspace(workspace) {
            var self = this;
            var workspaceId = workspace._id;
            var workspaceClass = workspace.className();
            var eventId = workspace.__event._id;

            var url = this.Routes.v2_delete_workspace_path(workspaceId);
            var promise = this.apiDelete(url, null, workspace, false);

            var deferred = this.$q.defer();

            promise.then(
                function deletedSuccess(resp) {
                    self._workspaceDeleted(deferred, workspaceClass, workspaceId, eventId);
                },
                function deletedError(resp) {
                    deferred.reject(resp);
                }
            );

            return deferred.promise;
        },

        sendBulkEmailToWorkspaces: function sendBulkEmailToWorkspaces(workspaces, htmlBody, subject, attachedFiles, onSuccessFunction) {

            var promise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendBulkEmailToWorkspaces, {workspaces: workspaces, html_body: htmlBody, subject: subject, image_attachments: attachedFiles.imageFiles, file_attachments: attachedFiles.generalFiles})
            promise.then(function taskSuccess(resp) {
                this.getWorkspacesMasterCollection().onCollectionSuccess();
                onSuccessFunction(resp);
            }.bind(this));
            return promise;
        },

        deleteWorkspaces: function deleteWorkspaces(workspaces) {
            var promise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.deleteWorkspaces, {workspaces: workspaces})
            promise.then(function taskSuccess() {
                this.getWorkspacesMasterCollection().onCollectionSuccess();

            }.bind(this));
            return promise;
        },

        archiveWorkspaces: function archiveWorkspaces(workspaces, reason, notifyMembers) {
            var promise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.archiveWorkspaces, {workspaces: workspaces, reason: reason, notifyMembers: notifyMembers});
            promise.then(function taskSuccess() {
                this.getWorkspacesMasterCollection().onCollectionSuccess();
            }.bind(this));
            return promise;
        },

        moveWorkspaces: function moveWorkspaces(workspaces, stage, disableTacking) {
            var promise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.moveWorkspacesStage, {workspaces: workspaces, stage: stage, disable_tracking: disableTacking});
            promise.then(function taskSuccess() {
                this.getWorkspacesMasterCollection().onCollectionSuccess();
            }.bind(this));
            return promise;
        },

        _workspaceDeleted: function _workspaceDeleted(deferred, workspaceClass, workspaceId, eventId) {
            var self = this;

            this.RepositoryService.cacheDelete(null, workspaceClass, workspaceId);
            this.FeedService.deleteDraftMessage(workspaceId);

            // Update event
            var promise = this.EventsManager.getEvent(eventId, true, true);
                promise.then(
                    function eventReturnedSuccess(event) {
                        self._eventReturnedSuccess(event, deferred);
                    },
                    function eventReturnedError(resp) {
                        self._eventReturnedError(resp, eventId, deferred);
                    }
                );
        },

        getWorkspaceSummary: function getWorkspaceSummary(workspaceId) {
            var workspace = this.RepositoryService.cacheGet("WorkspaceModel", workspaceId);
            if (!workspace) {
                workspace = this.newWorkspace();
                workspace._id = workspaceId;
                this.RepositoryService.cachePut(workspace);
            }

            var url = this.Routes.v2_workspace_path(workspaceId);
            var data = {show_archive: true};
            return this.apiFetch(url, data, workspace, true);
        },

        // loads only the basic info for the tab and list in mini event
        reloadWorkspaceInfo: function reloadWorkspaceInfo(workspaceId) {
            var workspace = this.RepositoryService.cacheGet("WorkspaceModel", workspaceId);
            if (!workspace) {
                workspace = this.newWorkspace();
                workspace._id = workspaceId;
                this.RepositoryService.cachePut(workspace);
            }

            var url = this.Routes.v2_workspace_summary_path(workspaceId);
            return this.apiFetch(url, null, workspace, true);
        },

        _eventReturnedSuccess: function _eventReturnedSuccess(resp, deferred) {
            if(deferred) {
                deferred.resolve(resp);
            }
        },
        _eventReturnedError: function _eventReturnedError(resp, eventId, deferred) {
            if (resp.status === 404) {
                // last workspace in event, event deleted.
                this.EventsManager.removeEventFromCache(eventId);
                if(deferred) {
                    return deferred.resolve(resp);
                }
            }
            if(deferred) {
                return deferred.reject(resp);
            }
        },

        getWorkspaces: function getWorkspaces(showArchive) {
            var workspaces = this.ModelFactory.getWorkspacesMasterCollection();

            showArchive = showArchive || false;
            var url = this.Routes.v2_workspaces_path();
            var data = {show_archive: showArchive, perPage: 50};
            this.apiFetchCollection(url, data, workspaces, true, null, true);

            return workspaces;
        },

        getWorkspacesMasterCollection: function getWorkspacesMasterCollection() {
            return this.ModelFactory.getWorkspacesMasterCollection();
        },

        //returns the workspaces model collections with the data that is needed
        getPipelineWorkspaces: function getPipelineWorkspaces(workspaces, params) {
            // url, data,
            // collection, updateModelData, headers,
            // paging, saveToCache, addToExistingCollection
            return this.apiFetchCollection(this.Routes.v2_workspaces_pipeline_path(), this._pipelineParams(params),
                workspaces, true, null,
                false, true, true);
        },

        getPipelineWorkspacesPromise: function getPipelineWorkspacesPromise(params) {
            var url = this.Routes.v2_workspaces_pipeline_path();
            return this.apiFetch(url, this._pipelineParams(params));
        },

        //returns the workspaces counts by stages and categories
        getPipelineWorkspacesCounts: function getPipelineWorkspacesCounts(userIds, allowDb) {
            // default to true
            allowDb = allowDb !== false;

            return this.apiFetch(
                this.Routes.v2_workspaces_pipeline_path(),
                {
                    userIds: userIds,
                    counts_only: true,
                    allow_db: allowDb
                },
                null, false);
        },

        userHasAnyWorkspaces: function userHasAnyWorkspaces() {
          return this.apiFetch(this.Routes.v2_workspaces_has_any_path());
        },

        getArchiveWorkspacesReport: function getArchiveWorkspacesReport() {
            var self = this;

            var taskData = {
                report_type: 'archived_workspaces_report'
            };

            self.pendingTaskPromise = self.PendingTasksManager.createPendingTask(self.PendingTasksManager.pendingTasksTypes.generateReport, taskData);
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.archive_ws_report_requested, taskData);

            return self.pendingTaskPromise.then(
                function success(resp) {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.archive_ws_report_downloaded, taskData);
                    return resp;
                })
                .catch(function fail(resp) {
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, resp);
                    self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.failed_download_archive_ws_report, resp);
                    return null;
                });
        },

        getWorkspace: function getWorkspace(workspaceId, showArchive, refresh, returnPromise) {
            refresh = refresh !== false; // default true

            var workspace = this.RepositoryService.cacheGet("WorkspaceModel", workspaceId);
            if (!workspace) {
                workspace = this.newWorkspace();
                workspace._id = workspaceId;
                this.RepositoryService.cachePut(workspace);
            } else if (!refresh) {
                // no need to fetch, guess we need only basic stuff
                return returnPromise ? this.$q.when(workspace) : workspace;
            }

            if (workspace.wasFullyFetched() && !this.isModelTimedOut(workspace, refresh === true)) {
                return returnPromise ? this.$q.when(workspace) : workspace;
            }

            showArchive = showArchive || false; // todo yona: do we want to handle this?
            var url = this.Routes.v2_workspace_path(workspaceId);
            var data = {show_archive: showArchive};
            var promise = this.apiFetch(url, data, workspace, true).then(function gotWorkspace() {
                workspace.setFullyFetched();
                return workspace;
            });

            return returnPromise ? promise : workspace;
        },

        filterArchivedWorkspaces: function filterArchivedWorkspaces() {
            var workspaces = this.ModelFactory.getWorkspacesMasterCollection();

            var workspacesToDelete = workspaces.filter(function (workspace) {
                return !workspace.active_state;
            });

            for (var workspaceIndex = 0; workspaceIndex < workspacesToDelete.length; workspaceIndex++) {
                this.RepositoryService.cacheDelete(workspacesToDelete[workspaceIndex]);
            }
            return this.ModelFactory.getWorkspacesMasterCollection();
        },

        getPaymentsForWorkspace: function getPaymentsForWorkspace(workspace, workspaceId) {
            var url = this.Routes.v2_workspace_payments_path(workspaceId);
            return this.apiFetch(url, null, workspace, true);
        },

        getAttachments: function getAttachments(workspace) {
            var url = this.Routes.v2_workspace_attachments_path(workspace._id);
            return this.apiFetch(url, null, workspace, true);
        },

        addAttachments: function addAttachments(workspace, attachmentsData) {
            var url = this.Routes.v2_add_workspace_attachments_path(workspace._id);
            return this.apiCreate(url, {attachments: attachmentsData}, workspace, true);
        },

        deleteAttachment: function deleteAttachment(workspace, attachmentId, assetType) {
            var url = this.Routes.v2_delete_workspace_attachment_path(workspace._id, attachmentId);
            return this.apiDelete(url).then(function(resp) {

                var collection = assetType === 'image' ? workspace.attachments.images : workspace.attachments.files;
                var index = this._.findIndex(collection, function(attachment) { return attachment._id === attachmentId; });
                if (index !== -1) {
                    collection.splice(index, 1);
                }

                return resp;
            }.bind(this));
        },

        getFeedForWorkspace: function getFeedForWorkspace(workspace, workspaceId) {
            var url = this.Routes.v2_workspace_feed_path(workspaceId);
            return this.apiFetch(url, null, workspace, true);
        },

        markFeedItemsAsSeen: function markFeedItemsAsSeen(workspaceId, items) {
            var itemIds = items.map(function (item) {
                return item._id;
            });

            var data = {
                item_ids: itemIds
            };

            var url = this.Routes.v2_mark_feed_items_as_seen_path(workspaceId);
            return this.apiUpdate(url, data, null).then(function (res) {
                if (Array.isArray(res.data)) {
                    var seenAt = {};
                    var feedTracking = {};
                    res.data.forEach(function (item) {
                        seenAt[item._id] = item.seen_at;
                        feedTracking[item._id] = item.feed_tracking;
                    });
                    items.forEach(function (item){
                        if (seenAt[item._id]) {
                            item.seen_at = seenAt[item._id];
                        }

                        if (feedTracking[item._id]) {
                            item.feed_tracking = feedTracking[item._id];
                        }
                    });
                }
            });
        },

        deleteFeedItem: function deleteFeedItem(workspace, item) {
            var url = this.Routes.v2_workspace_delete_feed_item_path(workspace._id, item._id);
            return this.apiDelete(url).then(function success() {
                var itemIndex = this._.findIndex(workspace.feed.feed_items, function(fi) { return fi._id === item._id; });
                if (itemIndex !== -1) {
                    workspace.feed.feed_items.splice(itemIndex, 1);
                }
            }.bind(this));
        },

        restoreFlow: function restoreFlow(flowId) {
            var url = this.Routes.v2_restore_deleted_flow_path(flowId);
            return this.apiCreate(url);
        },

        updateWorkspaceName: function updateWorkspaceName(workspace) {
            var data = {
                name: workspace.workspace_tab_info.name
            };
            var url = this.Routes.v2_update_workspace_tab_path(workspace._id);
            return this.apiUpdate(url, data, workspace, true);
        },

        updateWorkspaceStage: function updateWorkspaceStage(workspace, stage_id, track) {
            var defer = this.$q.defer();
            var url = this.Routes.v2_update_workspace_stages_path(workspace._id, stage_id);
            this.apiUpdate(url, {disable_tracking: track}, workspace, true).then(function success(resp) {
                defer.resolve(resp);
                this.onWorkspaceStageUpdated(workspace);
            }.bind(this))
            .catch(function error(resp) {
               defer.reject(resp);
            });

            return defer.promise;
        },

        onWorkspaceStageUpdated: function onWorkspaceStageUpdated(workspace) {
            this.trigger('workspacesStageUpdated', workspace);
        },

        updateWorkspacePipelineSettings: function updateWorkspacePipelineSettings(workspace, disableTracking) {
            var settings = {
                "disable_tracking": disableTracking
            };
            var url = this.Routes.v2_update_pipeline_settings_path(workspace._id);
            return this.apiUpdate(url, settings, workspace, true);
        },

        sendRequestProposalToUser: function sendRequestProposalToUser(workspace, user, subject, htmlBody, emailType) {
            var email = {
                subject: subject,
                user_id: user._id,
                html_body: htmlBody,
                email_type: emailType
            };

            var workspaceFile = this.WorkspaceFilesManager.newWorkspaceFile();
            var url = this.Routes.api_v2_workspace_request_proposal_path(workspace._id);
            return this.apiCreate(url, email, workspaceFile, true);
        },

        /**
         *
         * @param workspace
         * @param messageHTML html body of the message to be sent
         * @param attachedFiles hash which has 2 lists of uploaded files: generalFiles and imageFiles
         * @param pvList expecting an array of user ids of the preferred vendors, or otherwise null/undefined
         * @returns {*}
         */
        sendWorkspaceMessage: function sendWorkspaceMessage(workspace, messageHTML, attachedFiles, pvlData, subject, feedToReplyId, scheduledSendDate, force) {
            var message = {
                subject: subject,
                html_body: messageHTML,
                general_files: attachedFiles.generalFiles,
                image_files: attachedFiles.imageFiles,
                feed_to_reply_id: feedToReplyId,
                scheduled_send_date: scheduledSendDate,
                force: force || false
            };

            if (pvlData.pvl_id) {
                message.pvl_id = pvlData.pvl_id;
            }

            var url = this.Routes.v2_workspace_send_message_path(workspace._id);
            return this.apiCreate(url, message, workspace, true);
        },

        requestPreferredVendorsList : function requestPreferredVendorsList(workspace, workspaceFile, companyTypes) {
            var data = {
                vendor_types: companyTypes,
                workspace_file_id: workspaceFile ? workspaceFile._id : undefined
            };
            var url = this.Routes.v2_workspace_request_preferred_vendors_list_path(workspace._id);
            return this.apiCreate(url, data, workspace, true);
        },

        dismissFeedPvlRequest : function dismissFeedPvlRequest(workspace) {
            var url = this.Routes.v2_dismiss_feed_pvl_request_path(workspace._id);
            return this.apiUpdate(url, null, workspace, true);
        },

        getPreferredVendorsListById: function getPreferredVendorsList(pvlId) {
            var url = this.Routes.v2_get_preferred_vendors_list_path(pvlId);
            return this.apiFetch(url, {}, null, false);
        },

        savePreferredVendorsList: function savePreferredVendorsList(pvl) {
            var data = {
                pvl_id: pvl._id ? pvl._id : undefined,
                notify_vendors: pvl.notify_vendors,
                preferred_vendor_ids: pvl.preferred_vendor_ids ? pvl.preferred_vendor_ids : [],
                workspace_file_id: pvl.workspace_file_id ? pvl.workspace_file_id : undefined,
                flow_type: pvl.flow_type ? pvl.flow_type : undefined
            };

            var workspace_id =  pvl.workspace_id ? pvl.workspace_id : undefined;

            data.workspace_id = workspace_id;
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.vendor_pvl_save, data);

            var url;
            if(data.pvl_id) {
                url = this.Routes.v2_workspace_update_preferred_vendors_list_path(workspace_id);
                return this.apiUpdate(url, data, null, false);
            } else {
                url = this.Routes.v2_workspace_save_preferred_vendors_list_path(workspace_id);
                return this.apiCreate(url, data, null, false);
            }
        },

        sendPreferredVendorsListForWSFile: function sendPreferredVendorsListForWSFile(workspaceId, workspaceFileId) {
            var data = {
                workspace_file_id: workspaceFileId
            };
            var url = this.Routes.v2_workspace_send_preferred_vendors_list_path(workspaceId);
            return this.apiCreate(url, data, null, false);
        },

        // =================================
        // MEMBER FUNCTIONS
        // =================================

        removeMember: function removeMember(workspaceId, userId) {

            var url = this.Routes.v2_workspace_remove_member_path(workspaceId, userId);
            return this.apiDelete(url, null, null, false);
        },

        saveMember: function saveMember(workspaceId, user) {

            var url = this.Routes.v2_workspace_add_member_path(workspaceId);
            return this.apiCreate(url, user.dataOnly(), user, true);
        },

        addTeamMemberToWS: function addTeamMemberToWS(workspaceId, member) {
            var url = this.Routes.v2_workspace_add_member_path(workspaceId);
            return this.apiCreate(url, member.dataOnly(), member, true);
        },

        updateTeamMembersInWorkspace: function updateTeamMembersInWorkspace(workspace, addedMemberIDs, removedMemberIDs) {
            var data = {
                added_members: addedMemberIDs,
                removed_members: removedMemberIDs
            };
            var url = this.Routes.v2_workspace_update_team_members_path(workspace._id);
            return this.apiUpdate(url, data, workspace, true);
        },

        hideAddMemberRelatedToSessionsBanner: function hideAddMemberRelatedToSessionsBanner(workspace) {
            var url = this.Routes.v2_remove_sessions_with_unrelated_member_path(workspace._id);
            return this.apiUpdate(url, null, workspace, true);
        },

        sendMemberReInvite: function sendMemberReInvite(workspaceId, userId) {

            var url = this.Routes.v2_workspace_send_invite_to_workspace_member_path(workspaceId, userId);
            return this.apiCreate(url, null, null, false);
        },

        removedSelfFromWorkspace: function removedSelfFromWorkspace(workspace) {
            var workspaceId = workspace._id;
            var workspaceClass = workspace.className();
            var eventId = workspace.__event._id;
            var event = this.EventsManager.getEvent(eventId, false, false);

            this.RepositoryService.cacheDelete(null, workspaceClass, workspaceId);

            event.workspaces = event.workspaces.filter(function(w) {
                return w._id !== workspace._id;
            });

            if (event.workspaces.length === 0) { //remove event from cache if no workspaces remained
                this.EventsManager.removeEventFromCache(eventId);
            }
        },

        updateFileNotificationsAsSeen: function updateFileNotificationsAsSeen(workspace, filesToMarkAsNotificationSeen) {
            var url = this.Routes.v2_workspace_update_notification_path(workspace._id);

            return this.apiUpdate(url, {filesToMarkAsNotificationSeen: filesToMarkAsNotificationSeen}, workspace, true);
        },

        getWorkspaceMembers: function getWorkspaceMembers(workspace) {
            var url = this.Routes.v2_get_workspace_members_path(workspace._id);
            return this.apiFetch(url, null, workspace, true);
        },

        dismissRequestsForPvl: function dismissRequestsForPvl(workspace, pvl_id, reason) {
            var data = {
                dismiss_reason: reason,
                pvl_id: pvl_id
            };
            var url = this.Routes.v2_workspace_dismiss_pvl_requests_path(workspace._id);
            return this.apiUpdate(url, data, workspace, true);
        },

        createPvlInquiry: function createPvlInquiry(referringVendorId, inquiredVendorId, eventId, pvlId) {
            var url = this.Routes.v2_pvl_create_inquiry_path();

            var data = {
                referring_vendor_id: referringVendorId,
                inquired_vendor_id: inquiredVendorId,
                event_id: eventId,
                pvl_id: pvlId
            };

            return this.apiCreate(url, data, null, false);
        },

        _pipelineParams: function _pipelineParams(params) {
            var data = {
                userIds: params.userIds || null,   // null means current user
                show_archive: params.showArchive || false,
                page:params.page || null, perPage: params.perPage || 50,
                stage_id: params.stageId || null,
                category: params.category,
                extended_results: !!params.extended_results,
                sort_by: params.sortBy, sort_desc: params.sortDesc || false,
                lean_format: params.leanFormat || false
            };

            return data;
        }
    });
}());
