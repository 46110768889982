(function () {
    "use strict";

    Directives.CompanyInfoDirective = function CompanyInfoDirective() {

        // @ngInject
        function CompanyInfoDirectiveControllerCtor($scope, $injector, $translate, $state, _, AppStates, UiPersistenceService, DeviceService,
                                                    CompaniesManager, AnalyticsService, ModalService, ReactModalService, PopupMessageService, CompanyService, AppConfigService, TimezoneService,
                                                    UsersManager, $sce, Enums, ngToast, ToastService, $timeout, UIUtils, RegexService, SuggestionsService, ValidationsManager, FlowsBetaUserTypeService,
                                                    StatsigService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CompanyInfoDirectiveController';

            this.CompaniesManager = CompaniesManager;
            this._ = _;
            this.RegexService = RegexService;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.ReactModalService = ReactModalService;
            this.CompanyService = CompanyService;
            this.TimezoneService = TimezoneService;
            this.PopupMessageService = PopupMessageService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$sce = $sce;
            this.$state = $state;
            this.AppStates = AppStates;
            this.UiPersistenceService = UiPersistenceService;
            this.DeviceService = DeviceService;
            this.SuggestionsService = SuggestionsService;
            this.UIUtils = UIUtils;
            this.ValidationsManager = ValidationsManager;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

            this.$timeout = $timeout;
            this.ngToast = ngToast;
            this.ToastService = ToastService;

            this.user = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();
            this.isVendor = this.user.isVendor();

            this.projectTypes = angular.copy(this.company.sortedProjectTypes());
            this.newProjectType = {label: "", editInProgress: false};
            this.projectTypOldValue = "";
            this.source = "company_settings";
            this.tagsAnalyticsArgs = {source: "lead_source_modal", secondary_source: this.source};
            this.companyTypes = this.CompanyService.getCompanyTypesSorted();
            this.timezones = TimezoneService.getTimezones();
            this.warnOnSave = false;
            this.showWebsitePopover = false;


            StatsigService.isGateEnabled('ng2react-company-settings-brand-info').then(function (isGateEnabled){
                this.settingsInfoFramework = isGateEnabled ? 'React' : 'Angular';
            }.bind(this)).catch(function (){
                this.settingsInfoFramework = 'Angular'; 
            }.bind(this));

            this.initializeCompanyType();
            this.initializeTimezone();
            this.initProjectTypesVm();
            this.initEmailSignature();

            // there is a default company type until the model will be loaded
            if (this.company.wasFullyFetched()) {
                this.shouldWarnWebsiteUrl = !this.company.website_url_validation;
                this._validateWebsiteUrlRegEx();

                if (this.company.website_url_validation === null){
                    this._serverSideValidateWebsite();
                }
            } else{
                this.registerOnce(this.company, 'success', function() {
                    this.initializeCompanyType();
                    this.initializeTimezone();
                    this.initProjectTypesVm();
                    this.initEmailSignature();
                    this._serverSideValidateWebsite();

                }.bind(this));
            }

            $scope.$watch('companySettingsOverviewVm.company.country', function(newVal, oldVal){
                if(oldVal !== newVal){
                    this.company.state = "";
                }
                this.stateTitle = this.company.country === 'CA' ? 'COMPANY_SETTING.LABELS._PROVINCE_' : 'COMPANY_SETTING.LABELS._STATE_';
                this.zipTitle = this.company.country === 'CA' ? 'COMPANY_SETTING.LABELS._POSTAL_CODE_' : 'COMPANY_SETTING.LABELS._ZIP_CODE_';
            }.bind(this));

            this.$scope.$on("$destroy", function () {
                this.ngToast.dismiss(this.toastLeadSource);
            }.bind(this));

            this._validateWebsiteUrlRegExDebounced = this._.debounce(this._validateWebsiteUrlRegEx.bind(this), 1000);

        }

        var CompanyInfoDirectiveController = Class(Controllers.BaseController, {
            constructor: CompanyInfoDirectiveControllerCtor,

            saveChangesDelayed: function saveChangesDelayed() {
                // ng model has race condition with phone number directive
                // hence needed to delay it to avoid it
                this.$timeout(function() {
                    this.saveChanges();
                }.bind(this), 0)
            },

            /**
             * Saves the changes done in the company model to the server
             */
            saveChanges: function(force) {
                var self = this;
                var validations = this.companyInfoForm;

                if (force || (validations.$dirty && validations.$valid)) {

                    var promise = this.CompaniesManager.saveCompanyFields(this.company, null, this.useMalkutPath);
                    promise.then(function(response){
                        // Clear validations
                        validations.$setPristine();

                        var analyticsArgs = {
                            company: self.company._id,
                            website_valid: this.company.website_url_validation
                        };

                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.company_updated, analyticsArgs);

                        this.shouldWarnWebsiteUrl = !this.company.website_url_validation;
                        if (this.shouldWarnWebsiteUrl) {
                            this.warnOnSave = true;
                        }
                        else {
                            this.warnOnSave = false;
                            this._shouldShowSuggestions();
                        }


                    }.bind(this))
                    .catch(function error(resp) {
                        if (resp.data && resp.data.error_type === 'HBUserError') {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                        } else {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                        }
                    }.bind(this));
                }
            },

            _validateWebsiteUrlRegEx: function(){
                var isValid = this.RegexService.isCompanyWebsiteUrlValid(this.company.website_url) || this.company.website_url == '';
                this.$timeout(function(){
                    this.companyInfoForm.website_url.$setValidity('pattern', isValid);
                    this.$scope.$apply();
                }.bind(this),0);
            },

            _serverSideValidateWebsite: function(){
                this.CompaniesManager.validateCompanyWebsiteUrl(this.company).then(function(){
                    this.shouldWarnWebsiteUrl = !this.company.website_url_validation;
                }.bind(this)).catch(function() {
                    this.shouldWarnWebsiteUrl = true;
                }.bind(this));
            },

            validateEmail: function() {
               if (!this.company.email) {
                   return;
               }

                this.ValidationsManager.validateEmail(this.company.email).then(function() {
                    this.companyInfoForm.email.$setValidity('pattern', true);
                    this.saveChanges();
                 }.bind(this), function(resp) {
                    if (resp.data && resp.data.error_message === "Invalid Email Address") {
                        this.companyInfoForm.email.$setValidity('pattern', false);
                    }
                 }.bind(this));
            },

            onWebsiteUrlChange: function(){
                this.shouldWarnWebsiteUrl = false;

                if (this.companyInfoForm.website_url.$error && this.companyInfoForm.website_url.$error.pattern){
                    this._validateWebsiteUrlRegEx();
                } else {
                    this._validateWebsiteUrlRegExDebounced();
                    this.websiteUrlChanged = true;
                }
            },

            setActiveCompanyType: function(type) {
                this.company.company_type = type.key;
                this.selectedCompanyType = type;
                this.saveChanges(true);
            },

            initializeCompanyType: function(){
                this.selectedCompanyType = this.CompanyService.getSelectedCompanyTypeFromEnum(this.company);
            },

            companyTypeIsOther: function() {
                return this.company.company_type == 'other';
            },

            setDefaultTimezone: function(timezone) {
                var self = this;
                var analyticsArgs = {
                    company: self.company._id,
                    timezone: timezone
                };
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.company_updated_timezone, analyticsArgs);
                this.selectedTimezone = timezone;
                this.company.default_timezone = this.TimezoneService.translateToServer(timezone);
                this.saveChanges(true);
            },

            initializeTimezone: function() {
                this.selectedTimezone = this.TimezoneService.translateFromServer(this.company.default_timezone);
                if (!this.selectedTimezone) {
                    this.selectedTimezone = this.TimezoneService.DEFAULT_TIMEZONE;
                }
            },

            initProjectTypesVm: function() {
                this.projectTypes = angular.copy(this.company.projectTypesSort());
            },

            saveLeadSources: function(leadSourcesList) {
                return this.CompaniesManager.updateLeadSources(this.company, leadSourcesList, this.shouldShowLeadSourcesEditedToast());
            },

            editLeadSources: function() {
                this.ModalService.openEditLeadSourceModal(
                    this.company.sortedLeadSources(), this.saveLeadSources.bind(this), this.tagsAnalyticsArgs)
                    .then(function (edited) {
                        if(edited) {
                            if (this.shouldShowLeadSourcesEditedToast()) {
                                this._showLeadSourcesEditedToast();
                            }
                        }
                    }.bind(this));
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source__modal_opened, this.tagsAnalyticsArgs);
            },

            shouldShowLeadSourcesEditedToast: function() {
                return !this.DeviceService.checkIfMobileSize() && this.user.isCompanyOwner() && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: true}).show;
            },

            _showLeadSourcesEditedToast: function() {
                var toastIcon      = '<span class="icon icon-hb-nx-bell"></span>';
                var toastTitle     = this.$translate.instant('LEADSOURCE.TOAST._TITLE_');
                var toastSubTitle  = this.$translate.instant('LEADSOURCE.TOAST._SUB_TITLE_');
                var toastCtaUpdateText = this.$translate.instant('LEADSOURCE.TOAST._UPDATE_NOW_');
                var toastTextIntro    = this.$translate.instant('LEADSOURCE.TOAST._YOU_CAN_');
                var toastTextReview    = this.$translate.instant('LEADSOURCE.TOAST._REVIEW_');

                var dismiss = '<a ng-click="companySettingsOverviewVm.dissmissLeadSourceToast()" class="lead-sources-toast__close"><span class="icon icon-hb-nx-close-16"></span></a>';
                var toastCtaUpdate = '<a class="lead-sources-toast__cta" ng-click="companySettingsOverviewVm.updateContactForm()" >' + toastCtaUpdateText + '</a>';
                var toastCtaReview = '<div>' + toastTextIntro + '<a ng-click="companySettingsOverviewVm.reviewContactForm()" >' + toastTextReview + '</a>' + toastSubTitle + toastCtaUpdate + '</div>';
                var toastContent   = '<div class="lead-sources-toast__content">' +  '<div><strong>' + toastTitle + '</strong>' + toastCtaReview + '</div>';

                this.toastLeadSourceContent   = '<div class="lead-source__toast">' + toastIcon + toastContent + dismiss +'</div>';

                this.toastLeadSource = this.ngToast.create({
                    content: this.$sce.trustAsHtml(this.toastLeadSourceContent),
                    className: 'lead-source_toast alert alert-info',
                    dismissOnTimeout: false,
                    compileContent: this.$scope
                });

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__shown, {source: "lead_source_modal", secondary_source: this.source});
            },

            reviewContactForm: function() {
                this.ngToast.dismiss(this.toastLeadSource);
                this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {source: "lead_source_modal", secondary_source: this.source, contact_form: "review"});
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
            },

            openEmailEditorModal: function(){
                this.ReactModalService.openGlobalModal('EmailSignatureModalWrapper', {});
            },

            updateContactForm: function() {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {source: "lead_source_modal", secondary_source: this.source, contact_form: "update"});
                this.CompaniesManager.publishContactFormsLeadSource(this.company)
                    .then(function() {
                        this.ngToast.dismiss(this.toastLeadSource);
                        this._showConfirmationSavedContactFormToast();
                        this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
                    }.bind(this));
            },

            _showConfirmationSavedContactFormToast: function() {
                this.$timeout(function() {
                    this.ToastService.showSuccess({
                        contentTranslation: 'LEADSOURCE.TOAST._CONFIRMATION_SAVED_',
                        iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                        dismissOnTimeout: true,
                        timeout:3000
                    });
                }.bind(this), 1000);
            },

            dissmissLeadSourceToast: function() {
                this.ngToast.dismiss(this.toastLeadSource);
            },


            toggleCompanyName: function() {
                this.editingCompanyName = !this.editingCompanyName;
            },

            editCompanyName: function() {
                this.editingCompanyName = true;

                this.$timeout(function () {
                    angular.element('.company-settings__company-name-input').focus();
                }, 500);

            },

            disableEditCompanyName: function() {
                this.editingCompanyName = false;
            },

            //Note: 3 edge cases. has signature, has image container(only added after first interaction w template.) and has image.
            hasSignature: function() {
                if (this.user.email_signatures) {
                    return !!this.user.email_signatures[0];
                }
            },

            isSignatureLogo: function() {
                var logo = angular.element('.email-signature-logo');
                if ($(logo).length  > 0){
                    return $(logo).find('img').attr('src'); // check if has an image
                } else {
                    return true;
                }
            },

            initEmailSignature: function() {
                var signature = this.user.email_signatures[0];
                if (signature) {
                    this.userEmailSignature = this.$sce.trustAsHtml(signature.html_body);
                }
            },

            onShowMoreSocial: function() {
                this.showMoreSocial = !this.showMoreSocial;
            },

            toggleWebsitePopover: function() {
                this.showWebsitePopover = !this.showWebsitePopover;
            },

            /* Suggestions */
            _shouldShowSuggestions: function _shouldShowSuggestions() {
                if (!this.websiteUrlChanged) {
                    //another field was updated.
                    return;
                }

                this.websiteUrlChanged = false;

                this.shouldShowContactFormSuggestion = false;

                if (!this.company.website_url || this.company.isContactFormActive()) {
                    return;
                }

                if (this.user.isInTrial() || this.user.companyHasSubscription()) {
                    var smartSuggestionsHash = this.SuggestionsService.getSuggestionsPersistance();

                    if (smartSuggestionsHash.contactFormAdopt) {
                        return;
                    }

                    this.shouldShowContactFormSuggestion = true;
                    this.contactFormSuggestion = this.SuggestionsService.getSuggestion('contactForm');

                    this.originalWebsiteUrl = this.company.website_url; //to avoid showing it when other fields are updated.
                }
            },

            suggestionViewed: function suggestionViewed() {
                var smartSuggestionsHash = this.SuggestionsService.getSuggestionsPersistance();
                smartSuggestionsHash.contactFormAdopt = true;
                this.SuggestionsService.setSuggestionPersistance(smartSuggestionsHash);
            },

            onContactFormSuggestionCTAClick: function onContactFormSuggestionCTAClick() {
                this.shouldShowContactFormSuggestion = false;
                this.suggestionViewed();
                this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
            }

        });

        return {
            scope:{},
            templateUrl : 'angular/app/modules/core/features/settings/company/general/company_info_directive_template.html',
            controller : CompanyInfoDirectiveController,
            controllerAs : 'companySettingsOverviewVm',
            bindToController : true
        };

    };
}());
