(function () {
    "use strict";


    // @ngInject
    function ReferralsManagerCtor(APIService, Routes, $q, AnalyticsService, ModalService, ModelFactory, UsersManager, PopupMessageService, OnScreenNotificationService, WorkspaceFileService, UIUtils) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.OnScreenNotificationService = OnScreenNotificationService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.UIUtils = UIUtils;
    }

    Services.ReferralsManager = Class(Services.BaseModelManager, {

        constructor: ReferralsManagerCtor,

        getDefaultVendorReferralEmail: function getDefaultVendorReferralEmail(workspace) {

            var url = this.Routes.v2_get_referral_email_path(workspace.id());
            return this.apiFetch(url);
        },

        sendReferral: function sendReferal(workspace, user, subject, htmlBody, isLastUser, params) {
            const encodedBody = this.UIUtils.toBinaryBase64(htmlBody);

            var data = {
                subject: subject,
                user_id: user._id,
                html_body: encodedBody,
                email_type: 'vendor_referral'
            };

            for (var attrname in params) {
                data[attrname] = params[attrname];
            }

            if (isLastUser) {
                data["send_confirmation"] = true;
            }

            var url = this.Routes.v2_workspace_send_referral_to_vendor_path(workspace._id, user._id);
            return this.apiCreate(url, data, workspace, true);
        },

        moveWorkspace: function moveWorkspace(workspace, user) {
            var url = this.Routes.v2_transfer_workspace_path(workspace._id, user._id);
            return this.apiCreate(url, null, workspace, true);
        },

        getUserHasReferrals: function () {
            var url = this.Routes.v2_get_has_referrals_path();
            return this.apiFetch(url);
        },

        newReferralsCollection: function newReferralsCollection() {
            return this.ModelFactory.newContactsCollection();
        },

        referWorkspace: function referWorkspace(workspace, callingScope) {
            // temp hack so we can call this function from react
            if (!workspace.__className) {
                workspace = this.ModelFactory.newWorkspaceModel().mixinFrom(workspace);
            }
            var self = this;
            var subject, html_body;
            this.getDefaultVendorReferralEmail(workspace).then(function (resp) {
                html_body = resp.data.vendor_referral_email.html_body;
                subject = resp.data.vendor_referral_email.subject;
            });
            return this.ModalService.openNewVendorReferralModal(workspace).then(function success(vendorsToRefer) {
                var config = {
                    title: 'VENDOR_REFERRAL_EMAIL_EDITOR._HEADER_TITLE_',
                    showCCToClient: true,
                    shouldShowEventDetails: true,
                    info_message: 'VENDOR_REFERRAL_EMAIL_EDITOR._INFO_MSG_',
                    event_id: workspace.event._id,
                    editorType: "vendor referral",
                    workspace: workspace
                };

                if (vendorsToRefer.length === 0) {
                    return;
                }

                var sendToUser = function sendToUser(props) {
                    var user = props.users;
                    var subject = props.subject;
                    var body = props.body;
                    var isLast = props.isLast;
                    var params = props.params;

                    var self = this;
                    params.keep_sessions = true;

                    if (workspace.scheduled_sessions && workspace.scheduled_sessions.length) {
                        return this.PopupMessageService.showConfirmPromise(
                            this.PopupMessageService.severityTypes.warning,
                            'VENDOR_REFERRAL_KEEP_SESSIONS._TEXT_',
                            'VENDOR_REFERRAL_KEEP_SESSIONS._YES_KEEP_',
                            'VENDOR_REFERRAL_KEEP_SESSIONS._NO_CANCEL_')
                            .then(() => {
                                return this.executeSendReferral(workspace, user, subject, body, isLast, params, callingScope);
                            })
                            .catch(() => {
                                params.keep_sessions = false;
                                return this.executeSendReferral(workspace, user, subject, body, isLast, params, callingScope);
                            });
                    } else {
                        return this.executeSendReferral(workspace, user, subject, body, isLast, params, callingScope);
                    }
                };


                var options  = {
                    users: vendorsToRefer,
                    subject: subject,
                    body: html_body,
                    senderFunction: sendToUser.bind(self),
                    config: config,
                    showScheduleOption: false
                };

                self.WorkspaceFileService.showEmailEditorModal(options);

            }, function error() {

            });
        },

        executeSendReferral: function executeSendReferral(workspace, user, subject, body, isLast, params, callingScope) {
            var self = this;

            return this.sendReferral(workspace, user, subject, body, isLast, params).then(
                function success(){
                    var analyticsArgs = {
                        workspace_id: workspace._id,
                        user_email: user.email,
                        user_id: user._id
                    };

                    self.AnalyticsService.track(callingScope, self.AnalyticsService.analytics_events.vendor_referred_a_workspace, analyticsArgs);
                }
            );
        },

        adminMoveWorkspace: function adminMoveWorkspace(workspace, callingScope) {
            var self = this;
            return self.ModalService.openWorkspaceAdminMoveModal(workspace, []).then(function success(vendors) {
                var user = vendors[0];

                if (user == null) {
                    return;
                }

                self.UsersManager.getUserByEmail(user.email).then(
                    function success(res){
                        user = res.data;
                        self.OnScreenNotificationService.createOnScreenNotification(self.OnScreenNotificationService.types.info, "Moving workspace to " + user.full_name, null, 0);

                        self.moveWorkspace(workspace, user).then(
                            function success(){
                                self.OnScreenNotificationService.createOnScreenNotification(self.OnScreenNotificationService.types.info, "Workspace was succesfully moved to " + user.full_name, null, 0);
                                var analyticsArgs = {
                                    workspace_id: workspace._id,
                                    user_email: user.email,
                                    user_id: user._id
                                };

                                self.AnalyticsService.track(callingScope, self.AnalyticsService.analytics_events.admin_moved_a_workspace, analyticsArgs);
                            },
                            function error(resp) {
                                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, resp);
                            }.bind(this)
                        );
                    });
            }, function error(resp) {
                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, resp);
            });
        }
    });
}());
