
// @ngInject

Models.ServicesCollection = function ServicesCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'ServicesCollection';
                this._collection.__childsType = 'ServiceModel';
                return this._collection;
            }
        }
    );

};
