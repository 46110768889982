/**
 * Created by inonstelman on 12/28/14.
 */

(function () {
    'use strict';
    // this is `FB` service (and NOT `FacebookService`)
    // `FB` service is used to loads Facebook JS SDK
    // `FacebookService` uses `FB` to use the SDK functions
    // it is also different from Facebook Pixel, which is loaded by Segment.io

    // @ngInject
    Services.facebook = function facebook($window, Gon, ThirdPartyLoaderService) {
        $window.fbAsyncInit = function() {
            $window.FB.init({
                appId      : Gon.facebook_app_id, // App ID
                status     : true, // check login status
                cookie     : true, // enable cookies to allow the server to access the session
                xfbml      : true, // parse XFBML
                version    : 'v19.0'
            });
        };

        return ThirdPartyLoaderService.loadUrlPromise('https://connect.facebook.net/en_US/sdk.js', function() {return $window.FB;}, 'facebook-jssdk', 'anonymous');
    };
}());
