(function () {
    'use strict';

    class FlowsOnboardingWizardBrandingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope
            ,$injector
            ,UsersManager
            ,ModalService
            ,CompaniesManager
            ,$translate
            ,Enums
            ,CompanyService
            ,ImageService
            ,AnalyticsService
            ,UIUtils
            ,AvatarService
            ,DeviceService
            ,OnboardingService
            ,$state
            ,UiPersistenceService
            ,DatadogRUMService
            ,ToastService
            ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardBrandingController';

            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.ModalService = ModalService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.CompanyService = CompanyService;
            this.ImageService = ImageService;
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.OnboardingService = OnboardingService;
            this.UIUtils = UIUtils;
            this._ = _;
            this.$ = $;
            this.AvatarService = AvatarService;
            this.UiPersistenceService = UiPersistenceService;
            this.$state = $state;
            this.DatadogRUMService = DatadogRUMService;
            this.ToastService = ToastService;

            this.hasUserChangedBrandColor = false;
            this.isInstantBrandingEnabledFromQuiz = this.$state.params.isInstantBrandingEnabled;
            this.instantBrandingUiPersistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.instantBranding, {});
            this.isShowInstantBrandingAssets = this.isInstantBrandingEnabledFromQuiz ||  this.instantBrandingUiPersistency.hasToShowInstantBrandingAssets;
        }

        $onInit() {
            this.user = this.UsersManager.getCurrUser();
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this.company = this.CompaniesManager.getCurrCompany();
            this.instantBrandingSuggestedAssets = this.company.suggested_assets;
            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            this.setTitles();
            if (this.isInstantBrandingEnabledFromQuiz) {
                this.instantBrandingUiPersistency.hasToShowInstantBrandingAssets = true;
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.instantBranding, this.instantBrandingUiPersistency);
            }
            this.hasNewLogo = !this.CompanyService.isDefaultCompanyIcon(this.company);
            this.hasProfileImage = !this.AvatarService.isDefaultProfileImage(this.user);

            if (this.company.wasFullyFetched()) {
                this.onCompanyFetched();
            } else {
                this.register(this.company, 'success', this.onCompanyFetched);
            }

            this.analyticsProperties = this.getAnalyticsProperties();
            this.onColorSelected = this.onColorSelected.bind(this);
            this.updateBrandColorOnServer = this.updateBrandColorOnServer.bind(this);
        }

        $onChanges(changes){
            if (changes.stepTranslationMap && changes.stepTranslationMap.currentValue) {
                this.setTitles();
            }
        }

        getAnalyticsProperties() {
            return `onboarding_type:${this.onboardingType}, onboarding_abtest_name:${this.onboardingAbTest && this.onboardingAbTest.name}, onboarding_abtest_variant:${this.onboardingAbTest && this.onboardingAbTest.variant}, is_changing_hb_default_image:${!this.hasProfileImage}`;
        }

        setTitles() {
            if (this.isShowInstantBrandingAssets) {
                this.title = this.$translate.instant(`FLOWS_ONBOARDING.STEP_INSTANT_BRANDING._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
                this.subtitle = this.$translate.instant(`FLOWS_ONBOARDING.STEP_INSTANT_BRANDING._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            } else {
                this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
                this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            }
        }

        getSuggestedPresetColors() {
            if (this.isShowInstantBrandingAssets && this.instantBrandingSuggestedAssets && this.instantBrandingSuggestedAssets.brand_colors) {
                return this.instantBrandingSuggestedAssets.brand_colors;
            }
        }

        getSuggestedPresetColorsTitle() {
            if (this.isShowInstantBrandingAssets && this.instantBrandingSuggestedAssets && this.instantBrandingSuggestedAssets.brand_colors ) {
                return 'Suggested brand colors';
            }
        }

        onResetAssets() {
            this.CompaniesManager.resetCompanySuggestedBrandElements(this.company)
                .then((res) => {
                    this.isShowInstantBrandingAssets = false;
                    this.instantBrandingUiPersistency.hasToShowInstantBrandingAssets = false;
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.instantBranding,  this.instantBrandingUiPersistency);
                    const removedElements = res.data.reset_properties;
                    if (removedElements.icon_image) {
                        this.hasNewLogo = false;
                    }
                    if (removedElements.profile_image) {
                        this.hasProfileImage = false;
                    }
                    if (removedElements.brand_color) {
                        this.onColorSelected(removedElements.brand_color);
                        this.shouldResetBrandColor = true;
                    }
                    this.ToastService.showSuccess({
                        titleTranslation: 'FLOWS_ONBOARDING.STEP_INSTANT_BRANDING.DELETE_ASSETS_TOAST._TITLE_',
                        contentTranslation: 'FLOWS_ONBOARDING.STEP_INSTANT_BRANDING.DELETE_ASSETS_TOAST._BODY_',
                        iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                        dismissButton: true
                    });
                    this.CompanyService.linkBrandAssetsToCompany(removedElements, false);
                    this.setTitles();
                })
                .catch((error) => {
                    this.DatadogRUMService.addError(error, {
                        inatant_branding: true,
                        message: 'Failed to delete instant branding assets'
                    });
                });
        }

        onCompanyFetched() {
            this.setIsStepValid();
            this.setIsStepDirty();
            this.hasNewLogo = !this.CompanyService.isDefaultCompanyIcon(this.company);
            this.instantBrandingSuggestedAssets = this.company.suggested_assets;
        }

        setIsStepValid() {
            this.isStepValid = true;
        }

        setIsStepDirty() {
            this.setHasBrandColor();
            this.isStepDirty = this.hasNewLogo || this.hasProfileImage || this.hasBrandColor;   
        }
        
        setHasBrandColor() {
            this.hasBrandColor = !['#18c7c9', '#007DB3'].includes(this.company.brand_color);

        }

        openPreviewBrandingModal() {
            this.AnalyticsService.trackClick(this, 'preview branding');
            this.ModalService.openMwebPreviewBrandingModalController();
        }

        onComplete(event, currStep) {
            if (currStep.key === this.thisStepKey) {
                // do something after user clicks NEXT
            }
        }

        uploadProfileImage() {
            this.ModalService.openUploadProfilePhotoModal(this.user).then(() => {
                this.isStepDirty = true;
                this.hasProfileImage = !this.AvatarService.isDefaultProfileImage(this.user);
                this.analyticsProperties = this.getAnalyticsProperties();
            });
        }

        changeIconPhoto() {
            this.ImageService.uploadCompanyIcon(this.company).then( ()=> {
                this.hasNewLogo = !this.CompanyService.isDefaultCompanyIcon(this.company)
                this.isStepDirty = this.isStepValid || this.hasNewLogo;
            });
        }

        onFakeButtonClick() {
            this.AnalyticsService.trackClick(this, 'fake image button');
        }

        onColorSelected(color, isManuallyChanged = true) {
            if (isManuallyChanged) {
                this.hasUserChangedBrandColor = true;
            }
            this.company.brand_color = color;
            const companyFlowTheme = this.company.company_flow_theme;
            companyFlowTheme.styles.accent_color = color;
        }

        updateBrandColorOnServer(color) {
            this.onColorPickerSelected(color);
            const companyFlowTheme = this.company.company_flow_theme;
            this.CompaniesManager.updateCompanyFlowTheme(this.company, companyFlowTheme);
            this.setHasBrandColor();
            this.isStepDirty = true;
            return this.CompaniesManager.saveCompanyFields(this.company);
        }

        onColorPickerShow() {
            this.AnalyticsService.track(this, 'open color picker', {
                color_for: 'brand color',
                onboarding_type: this.onboardingType,
                onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant});
        }

        onColorPickerSelected(color) {
            this.AnalyticsService.trackClick(this, 'choose', {
                color_selected: color,
                is_changing_hb_default_color: !this.hasBrandColor
            });
        }
    }

    Components.FlowsOnboardingWizardBranding = {
        controller: FlowsOnboardingWizardBrandingController,
        bindings: {
            thisStepKey: '@',
            isStepValid: '=',
            isStepDirty: '=',
            stepTranslationMap: '<',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/branding/flows_onboarding_wizard_branding.html',

    };
}());
