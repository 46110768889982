(function () {
    "use strict";

    Components.InjectBranding = {
        bindings: {},

        // @ngInject
        controller: function TabsController($scope, InjectBrandingService){
            this.$onInit = function $onInit() {

                this.styles = "";

                // subscribe for changes
                InjectBrandingService.on('BrandStylesChange', this.OnBrandStylesChange.bind(this));
            };

            this.OnBrandStylesChange = function OnBrandStylesChange() {
                this.styles = InjectBrandingService.getStyles();
            };
        },
        name: 'hbInjectBranding',
        template: '<style>{{$ctrl.styles}}</style>',
    };

}());