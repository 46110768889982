// @ngInject
Filters.HbThousandSuffixFilter = function HbThousandSuffixFilter() {
    return function (input) {
        var exp;
        var suffixes = ['k', 'M', 'B'];

        if (input < 1000) {
            return '$' + input.toFixed(2);
        }

        exp = Math.floor(Math.log(input) / Math.log(1000));

        var value = (Math.floor((input / Math.pow(1000, exp)) * 10) / 10).toString();

        value = value.replace(/\.(.*)$/, function (match, cents) {
            if (+cents === 0) {
                return '';
            } else {
                return match;
            }
        });

        return '$' + value + suffixes[exp - 1];
    };
};

