(function () {
    'use strict';
    // @ngInject
    Filters.HbPipelineWorkspacesFilter = function HbPipelineWorkspacesFilter(Enums) {
        // @ngInject
        return function (workspaces, stagesHash, showActive, stageId, category, all) {
            var filtered = [];
            var currentWorkspace;
            var currentWorkspaceStage;
            for (var i = 0, length = workspaces.length; i < length; i++) {
                currentWorkspace = workspaces[i];
                if (currentWorkspace.workspace_pipeline_data && currentWorkspace.workspace_pipeline_data.current_stage && !currentWorkspace.workspace_pipeline_data.disable_tracking)
                {
                    currentWorkspaceStage = stagesHash[currentWorkspace.workspace_pipeline_data.current_stage.stage_id];
                    if ((currentWorkspace.active_state === showActive) &&
                        ((currentWorkspace.workspace_pipeline_data.current_stage.stage_id === stageId) ||
                            ((currentWorkspace.workspace_pipeline_data.current_stage.stage_id !== stageId) && currentWorkspaceStage &&(currentWorkspaceStage.category === category)) ||
                            (currentWorkspace.workspace_pipeline_data.current_stage.stage_id !== stageId) && all)) {

                        filtered.push(currentWorkspace);
                    }
                }

            }
            return filtered;
        };
    };

}());

