// class IntakeFormController {
// @ngInject
function IntakeFormController() {

    // $onInit() {
    this.$onInit = function $onInit() {
        this.submitDisabled = false;
        this.fullName = this.currUser.full_name ? this.currUser.full_name : "";
        this.phone = this.currUser.phone_number ? this.currUser.phone_number : '';
        if (!this.currUser.has_password){
            this.password = '';
            this.showPassword = true;
        } else{
            this.showPassword = false;
        }

        if (this.currUser.isVendor() && this.currUser.shouldStartTrial()) {
            this.companyName = this.currUser.company.company_name ? this.currUser.company.company_name : '';
            var selectedCompanyType = this.companyTypesEnum.filter(function (type) {
                return this.currUser.company.company_type === type.key;
            }.bind(this));
            if(selectedCompanyType && selectedCompanyType.length > 0){
                this.companyType = this.$translate.instant(selectedCompanyType[0].text);
            } else {
                this.companyType = '';
            }
        }
    };
    
    this.handleTosClick = function handleTosClick() {
        return this.submitDisabled = !this.submitDisabled;
    };

    this.submitForm = function submitForm(form) {
        var formValues = {
            fullName: form.fullName.$modelValue,
            companyName: form.companyName.$modelValue,
            phone: form.phone.$modelValue,
            companyType: form.companyType.$modelValue,
        };

        if (form.password !== undefined){
            formValues.password= form.password.$modelValue;

        }

        if (!form.$valid) {
            form.$setSubmitted();
            return;
        }

        // If form is valid, call parent submitCallback
        this.onSubmit({formValues: formValues});
    };
}

Components.IntakeForm = {
    bindings: {
        companyTypes: '<',
        companyTypesEnum: '<',
        isSaving: '<',
        onSubmit: '&',
        currUser: '=',
        $translate: '=',
        hideFullName: '=',
        ctaText: '='
    },
    controller: IntakeFormController,
    templateUrl: 'angular/app/modules/common/ui_components/registration/intake_form.html',
};
