// @ngInject

Models.ActivityModel = function ActivityModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'ActivityModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                project: 'NetworkProjectModel',
                referral: 'NetworkReferralModel'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
        }

    });

};
