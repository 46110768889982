(function () {
    'use strict';

    // cool general code

    /** WATCH VAR CHANGES */
    //var _st;
    //Object.defineProperty(this, 'showTemplates', {
    //    get: function () {console.log('get');console.trace();return _st;},
    //    set: function (val) {console.log('set', val);console.trace();_st = val;}
    //})


    /**
     * To turn devtools on run the following
     *
     * $('body').injector().get('dev_tools')
     *
     * Then you can access the devtools in the global scope
     */

    // @ngInject
    function devTools(_, $, $window, $http) {
        var console = $window.console;

        $window.devtools = {
            measure: function measure(url, cycles, parallel) {
                // defaults
                if (cycles == null) {
                    cycles = 10;
                }
                if (parallel == null) {
                    parallel = false;
                }

                // structures
                var data = {
                    max: 0,
                    min: Infinity,
                    avg: 0,
                    all: []
                };

                // methods
                var finalize = function () {
                    var sd;
                    sd = 0;
                    _.each(data.all, function (val) {
                        sd += Math.abs(val - data.avg);
                    });
                    data.sd = sd / data.all.length;
                };
                var run = function () {
                    var t1;
                    t1 = Date.now();
                    $http.get(url).success(function () {
                            console.log(arguments[0]);
                            var curr;
                            curr = Date.now() - t1;
                            data.avg = (data.avg * data.all.length + curr) / (data.all.length + 1);
                            data.all.push(curr);
                            data.max = Math.max(data.max, curr);
                            data.min = Math.min(data.min, curr);
                            if (!parallel && data.all.length < cycles) {
                                run();
                            } else {
                                finalize();
                                console.log(data);
                            }
                        }
                    );
                };
                run();

                return 'Wait for it...';
            },

            testControlFlow: function testControlFlow(iterations) {
                var i, res, t;
                var LOOPS = iterations || 1000000;
                var variable = null;

                t = Date.now();
                for (i = 0; i < LOOPS; i++) {
                    try {
                        res = variable.data;
                    } catch (e) {
                        res = null;
                    }
                }
                console.log('exception control flow (' + LOOPS + ' iterations) took:    ' + ((Date.now() - t) / 1000) + 'sec');

                t = Date.now();
                for (i = 0; i < LOOPS; i++) {
                    if (variable === null) {
                        res = null;
                    } else {
                        res = variable.data;
                    }
                }
                console.log('if statement control flow (' + LOOPS + ' iterations) took: ' + ((Date.now() - t) / 1000) + 'sec');
            },

            checkCircularPointer: function checkCircularPointer(obj) {
                var path = [];
                var tested = [];

                function internalCheck(obj) {
                    tested.forEach(function (tobj) {
                        var tmp = tobj.obj;
                        if (tmp === obj) {
                            var msg = 'found circular pointer at ' + path.join('.') + ' <==> ' + (tobj.path || '(root object)');
                            throw new Error(msg, tmp);
                        }
                    });

                    tested.push({obj: obj, path: path.join('.')});
                    if (obj) {
                        Object.keys(obj).forEach(function (key) {
                            if (typeof obj[key] === 'object') {
                                path.push(key);
                                internalCheck(obj[key]);
                                path.pop();
                            }
                        });
                    }
                    tested.pop();
                }

                internalCheck(obj);
                return 'OK';
            }

        };

        return {};
    }


    angular.module('honeybook.common')
        .factory('dev_tools', devTools);

}());