(function () {
    "use strict";


    // @ngInject
    function CompanyServiceCtor($q, $injector, ModalService, ReactModalService, _, PubSubService, $window, AppConfigService, Enums, AnalyticsService, CompaniesManager, Gon, FeaturesService, UsersManager) {

        this.$q = $q;
        this.$injector = $injector;
        this.ModalService = ModalService;
        this.UsersManager = UsersManager;
        this.ReactModalService = ReactModalService;
        this._ = _;
        this.$window = $window;
        this.AppConfigService = AppConfigService;
        this.Enums = Enums;
        this.PubSubService = PubSubService;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.Gon = Gon;
        this.FeaturesService = FeaturesService;

        //Note the order in this array is not the order of the real enum numbers,
        //but rather the order in which the company types are presented in menus
        //i.e. DO NOT rely on this for code like "companyTypeEnum[company.company_type_cd]"

        // order is used in the directory for ordering the type filters

        this.nonEventCompanyTypeEnum = this.Gon.non_event_company_type_enum;

        this.companyTypeKeyEnum = {
            eventPlanner: 'event_planner',
            cake: 'cake',
            venue: 'venue',
            photographer: 'photographer',
            cinematography: 'cinematography',
            eventLighting: 'event_lighting',
            rental: 'rental',
            hairMakeup: 'hair_makeup',
            florist: 'florist',
            djMusician: 'dj_musician',
            caterer: 'caterer',
            stationery: 'stationery',
            fashion: 'fashion',
            officiant: 'officiant',
            photoBooth: 'photo_booth',
            other: 'other',
            marketing: 'marketing',
            visualArtist: 'visual_artist',
            blogger: 'blogger',
            businessConsultant: 'business_consultant',
            calligrapher: 'calligrapher',
            interiorDesigner: 'interior_designer',
            graphicDesigner: 'graphic_designer',
            makerArtisan: 'maker_artisan',
            webDesigner: 'web_designer',
            accounting: 'accounting',
            catering: 'catering',
            coaching: 'coaching',
            wellness: 'wellness',
            agency: 'agency',
            doula: 'doula',
            landscaper: 'landscape',
            cleaning: 'cleaning',
            freelancer: 'freelance',
            socialMedia: 'social_media',
            realEstate: 'real_estate',
            education: 'education',
            virtualAssistant: 'virtual_assistant',
            contractor: 'contractor',

        };

        this.basicUser = {
            cd: 0,
            name: 'basic',
            text: 'COMPANY_SETTING.TEAM.PERMISSIONS._BASIC_',
            description: 'COMPANY_SETTING.TEAM.PERMISSIONS._BASIC_DESCRIPTION_'
        };

        this.moderator = {
            cd: 1,
            name: 'moderator',
            text: 'COMPANY_SETTING.TEAM.PERMISSIONS._MODERATOR_',
            description: 'COMPANY_SETTING.TEAM.PERMISSIONS._MODERATOR_DESCRIPTION_'
        };

        this.adminUser = {
            cd: 2,
            name: 'admin',
            text: 'COMPANY_SETTING.TEAM.PERMISSIONS._ADMIN_',
            description: 'COMPANY_SETTING.TEAM.PERMISSIONS._ADMIN_DESCRIPTION_'
        };

        this.superAdminUser = {
            cd: 3,
            name: 'super_admin',
            text: 'COMPANY_SETTING.TEAM.PERMISSIONS._SUPER_ADMIN_',
            description: 'COMPANY_SETTING.TEAM.PERMISSIONS._SUPER_ADMIN_DESCRIPTION_'
        };

        this.companyPermissionTypes = [
            this.basicUser,
            this.moderator,
            this.adminUser,
            this.superAdminUser
        ];

        this.companyPermissionMap = {}; //e.g. {'admin': this.adminUser, 'basic':this.basicUser,...}
        this.companyPermissionTypes.forEach(function (entry) {
            this.companyPermissionMap[entry.name] = entry;
        }.bind(this));

        PubSubService.ventMyBitchUp(this);
        this.companyTypeEnum = this.Gon.company_type_enum;
        setTimeout(function (){
            this.initializeCompanyTypeObjects();
        }.bind(this), 0);
    }

    Services.CompanyService = Class(function () {
        return {

            constructor: CompanyServiceCtor,

            getCompanyTypesEnum: function getCompanyTypesEnum() {
                if(this.UsersManager.getCurrUser().isTrialStartedAfterNewMSPCompanyTypes()) {
                    this.companyTypeEnum = this.Gon.company_type_enum.filter(function (company_type) {
                        return !company_type.old_msp;
                    }.bind(this));
                } else {
                    this.companyTypeEnum = this.Gon.company_type_enum.filter(function (company_type) {
                        return !company_type.new_msp;
                    }.bind(this));
                }
                return this.companyTypeEnum;
            },

            getCompanyTypesEnumClone: function getCompanyTypesEnumClone() {
                var companyTypes = [];
                this.getCompanyTypesEnum().forEach(function(companyType) {
                    companyTypes.push({
                        text: companyType.text,
                        key: companyType.key,
                        icon: companyType.icon
                    });
                });
                return companyTypes;
            },

            getCompanyTypesSorted: function getCompanyTypesSorted() {
                return this._.sortBy(this.getCompanyTypesEnum(), function(companyType) {
                    return companyType.order;
                });
            },

            initializeCompanyTypeObjects: function initializeCompanyTypeObjects() {
                this.companyTypeTranslationMap = {};
                this.companyTypeEnum.forEach(function (entry) {
                    this.companyTypeTranslationMap[entry.key] = entry.text;
                }.bind(this));

                this.companyTypeInformalTranslationMap = {};
                this.companyTypeEnum.forEach(function (entry) {
                    this.companyTypeInformalTranslationMap[entry.key] = entry.textInformal;
                }.bind(this));

                this.companyTypeIconMap = {};
                this.companyTypeEnum.forEach(function (entry) {
                    this.companyTypeIconMap[entry.key] = entry.icon;
                }.bind(this));
            },

            companyProfileUrl: function companyProfileUrl (company) {
                var currentPrefix;
                var profileUrl = company.company_url_domain;
                var companyUrls = company.company_url_prefixes;
                var companyCustomDomains = company.company_custom_domains;

                var isCompanyCustomDomainExists =
                    companyCustomDomains &&
                    companyCustomDomains.length > 0 &&
                    companyCustomDomains[companyCustomDomains.length - 1]
                        .validation_status === "active";
                const activeCustomDomain = this.getActiveCustomDomain(company.company_custom_domains);


                if(!this.AppConfigService.isProduction() && !this.$window.location.href.includes('staging-qa-app') ) {
                    const cxDomain = this.$window.location.host.replace('app', 'cx');
                    const url = cxDomain + '/app/' + '?cpu=' + (activeCustomDomain ? activeCustomDomain.full_hostname : companyUrls[companyUrls.length - 1] + '.' + profileUrl);
                    return url;
                }

                if (isCompanyCustomDomainExists) {
                    currentPrefix = companyCustomDomains[companyCustomDomains.length - 1];
                    return currentPrefix.full_hostname + "/app/";
                } else if (companyUrls) {
                    currentPrefix = companyUrls[companyUrls.length - 1];
                    return currentPrefix + "." + profileUrl + "/app/";
                }
            },

            getCompanyTypesTranslationMap: function getCompanyTypesTranslationMap() {
                return this.companyTypeTranslationMap || {};
            },

            getCompanyTypesInformalTranslationMap: function getCompanyTypesInformalTranslationMap() {
                return this.companyTypeInformalTranslationMap;
            },

            getCompanyTypesIconMap: function getCompanyTypesIconMap() {
                return this.companyTypeIconMap;
            },

            getSelectedCompanyTypeFromEnum: function getSelectedCompanyTypeFromEnum(company) {
                var selectedCompany = this.getCompanyTypesEnum()[0];
                this.getCompanyTypesEnum().forEach(function (type) {
                    if (company.company_type === type.key) {
                        selectedCompany = type;
                        return;
                    }

                });

                return selectedCompany;
            },

            editTeamMembers: function editTeamMembers(company, members) {
                return this.ModalService.openNewAddCompanyTeamMemberModal(company, members);
            },

            getActiveCustomDomain: function getActiveCustomDomain(companyCustomDomains) {
                if (companyCustomDomains.length) {
                    return this._.first(companyCustomDomains.filter(function (ccd) {return ccd.validation_status === 'active';}));
                }
            },

            getCompanyCustomDomains: function getCompanyCustomDomains(company) {
                return company.company_custom_domains;
            },

            getCompanyActiveCustomDomain: function getCompanyActiveCustomDomain(company) {
                return this.getCompanyCustomDomainByStatuses(company,['active']);
            },

            getCompanyInactiveCustomDomain: function getCompanyInactiveCustomDomain(company) {
                return this.getCompanyCustomDomainByStatuses(company,['invalid', 'cname_failed']);
            },

            getCompanyInProgressCustomDomain: function getCompanyInProgressCustomDomain(company) {
                return this.getCompanyCustomDomainByStatuses(company,['inprogress']);
            },

            getCompanyCustomDomainByStatuses: function getCompanyCustomDomainByStatuses(company, statuses) {
                var companyCustomDomains = this.getCompanyCustomDomains(company);
                if (companyCustomDomains.length) {
                    return this._.first(companyCustomDomains.filter(function (ccd) {return this._.contains(statuses, ccd.validation_status);}.bind(this)));
                }
                return null;
            },

            isCustomDomainActive: function isCustomDomainActive(company) {
                return this.getCompanyActiveCustomDomain(company) !== null;
            },

            isNev: function isNev(company) {
                return this.nonEventCompanyTypeEnum.includes(company.company_type);
            },

            getCompanyPermissionTypes: function getCompanyPermissionTypes() {
                return this.companyPermissionTypes;
            },

            getCompanyPermissionForUser: function getCompanyPermissionForUser(company, user) {
                if (!company || (user.company && user.company._id !== company._id)) { //make sure we are checking permissions for the right company.
                    return null;
                }

                if (user.is_company_owner) { //first check if owner
                    return this.superAdminUser;
                }

                //else - first check for permission settings under company
                var activePermission;
                if (company.user_permissions) { //get active permission from the company instead of the user
                    company.user_permissions.forEach(function (permission) {
                       if (permission.user_id === user._id) {
                           activePermission = permission;
                       }
                    });
                }
                if (!activePermission) { //company permissions were not found. try permission under user
                    activePermission = user.user_company_permission;
                }

                if (activePermission) { //found permission for that user
                    if (typeof activePermission.company_permission_cd === "number") {
                        return this.companyPermissionTypes[activePermission.company_permission_cd];
                    } else {
                        return this.companyPermissionMap[activePermission.company_permission];
                    }
                } else { //default to basic user
                    return this.basicUser;
                }
            },

            hasCompanyAdminPermissions: function hasCompanyAdminPermissions(user, company) {
                return this.hasPermissionsOrHigher(company, user, this.adminUser);
            },

            hasCompanyModeratorPermissions: function hasCompanyModeratorPermissions(user, company) {
                return this.hasPermissionsOrHigher(company, user, this.moderator);
            },

            hasCompanySuperAdminPermissions: function hasCompanySuperAdminPermissions(user, company) {
                return user.is_company_owner || this.hasPermissionsOrHigher(company, user, this.superAdminUser);
            },

            hasPermissionsOrHigher: function hasPermissionsOrHigher(company, user, userPermissions) {
                return this.getCompanyPermissionForUser(company, user).cd >= userPermissions.cd;
            },

            isDefaultCompanyLogo: function isDefaultCompanyLogo(company) {
                var defaultImageName = 'yourlogohere.jpg';
                if (!company.logo_image || !company.logo_image.original_url) {
                    return undefined;
                }
                return (company.logo_image.original_url.indexOf(defaultImageName) > -1);
            },

            isDefaultCompanyIcon: function isDefaultCompanyIcon(company) {
                //used to work similar to isDefaultCompanyLogo, now taking from server.
                return (!company.icon_image || company.icon_image.is_default_company_icon)
            },

            getRandomCompanyCoverImage: function getRandomCompanyCoverImage(company) {
                var cover_images = company.cover_images;

                if(!cover_images || !cover_images.length) {
                    return null;
                }

                var imagePosition = Math.min(Math.floor(Math.random() * (cover_images.length + 1)), cover_images.length - 1);
                var image = cover_images[imagePosition];
                return image;
            },

            triggerCompanyBrandingUpdated: function triggerCompanyBrandingUpdated() {
                this.trigger(this.Enums.PubSubTriggers.companyBrandingUpdated);
            },

            editLeadSources: function editLeadSources(company, analyticsArgs) {
                var _analyticsArgs = analyticsArgs || {};
                _analyticsArgs['source'] = "lead_source_modal";

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source__modal_opened, _analyticsArgs);

                return this.ModalService.openEditLeadSourceModal(company.sortedLeadSources(), function saveLeadSources(updatedLeadSourcesList) {
                    return this.CompaniesManager.updateLeadSources(company, updatedLeadSourcesList, true).then(function() {
                        company.trigger('companyLeadSourcesChanged');
                    });
                }.bind(this) , _analyticsArgs);
            },

            editProjectTypes: function editProjectTypes(company, analyticsArgs) {
                var onProjectTypesSaved = function(projectTypes, defaultProjectTypeLabel) {
                    var deferred = this.$q.defer();
                    this.CompaniesManager.updateProjectTypes(company, projectTypes, defaultProjectTypeLabel).then(function() {
                        company.trigger('companyProjectTypesChanged');
                        deferred.resolve();
                    });
                    return deferred.promise;
                }.bind(this);

                return this.ReactModalService.openEditProjectTypesModal(analyticsArgs['secondary_source'], onProjectTypesSaved);
            },

            linkBrandAssetsToCompany: function linkBrandAssetsToCompany(brandAssets, shouldLinkSuggestedAssets, websiteUrl) {
                
                if (brandAssets && !this._.isEmpty(brandAssets)){
                    this.UiPersistenceService = this.$injector.get('UiPersistenceService');
                    var companyProps = {};
                    var instantBrandingUiPersistencyProps = { 
                        hasToShowInstantBrandingAssets: false
                    };
                    var numberOfSavedAssets = 0;
                    if (shouldLinkSuggestedAssets) {
                        companyProps.suggested_assets = brandAssets;
                    }

                    if (websiteUrl) {
                        companyProps.website_url = websiteUrl;
                    }

                    if (brandAssets.brand_color) {
                        companyProps.brand_color = brandAssets.brand_color;
                        instantBrandingUiPersistencyProps.brandColor = true;
                        numberOfSavedAssets++;
                    }

                    if (brandAssets.icon_image) {
                        companyProps.icon_image = brandAssets.icon_image;
                        instantBrandingUiPersistencyProps.iconImage = true;
                        numberOfSavedAssets++;
                    }

                    this.UsersManager.updateCompanyPropertiesOptimistically(companyProps);

                    if (brandAssets.profile_image) {
                        this.UsersManager.updateCurrUserPropertiesOptimistically({ profile_image: brandAssets.profile_image });
                        instantBrandingUiPersistencyProps.profileImage = true;
                        numberOfSavedAssets++;
                    }

                    instantBrandingUiPersistencyProps.numberOfSavedAssets = numberOfSavedAssets;
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.instantBranding, instantBrandingUiPersistencyProps);
                }
            },

            updateCompanyWithSuggestedAssets: function updateCompanyWithSuggestedAssets(companyId) {
                return this.CompaniesManager.updateCompanyWithSuggestedAssets(companyId).then(function(res) {
                    const savedAssets = res.data.saved_assets;
                    const websiteUrl = res.data.website_url;
                    this.linkBrandAssetsToCompany(savedAssets, true, websiteUrl);
                    return !this._.isEmpty(savedAssets);
                }.bind(this));
            }
        };
    });
}());
