(function () {
    'use strict';

    // @ngInject
    Controllers.WorkspaceTypeIntroController = function WorkspaceTypeIntroController($modalInstance, workspace) {

        this.workspace = workspace;
        this.workspaceType = this.workspace.workspace_type;

        var baseTranslationPath = "WORKSPACE.WORKSPACE_TYPE_INTRO.";
        this.translationPaths = {
            team: baseTranslationPath + "TEAM.",
            design: baseTranslationPath + "DESIGN.",
            all_vendors: baseTranslationPath + "ALL_VENDORS.",
            timeline: baseTranslationPath + "TIMELINE."
        };

        this.getTranslationPath = function getTranslationPath() {
            return this.translationPaths[this.workspaceType];
        };

        this.analyticsProperties = function analyticsProperties() {
            return {
                workspace_id: this.workspace._id,
                workspace_type: this.workspaceType
            };
        };

        this.ok = function ok() {
            $modalInstance.close();
        };

        this.cancel = function cancel() {
            $modalInstance.dismiss('cancel');
        };

    };
}());