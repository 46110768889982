// @ngInject

Models.TimelineSectionModel = function TimelineSectionModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'TimelineSectionModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {'timeline_items': 'TimelineItemsCollection'};
        },

        afterMixingFrom: function afterMixingFrom(object) {

            var self = this;
            if (this.timeline_items && this.timeline_items.length > 0) {

                this.timeline_items.forEach(function (timelineItem, index) {
                    timelineItem.__section = self;
                });
            }
        },

//        getAnalyticsArgs: function getAnalyticsArgs() {
//            var parentAnalyticsArgs = this.__parent.getAnalyticsArgs();
//            var analyticsArgs = {
//                package_title: this.title,
//                package_id: this.id()
//            };
//
//            for (var property in parentAnalyticsArgs) {
//                if (parentAnalyticsArgs.hasOwnProperty(property)) {
//                    analyticsArgs[property] = parentAnalyticsArgs[property];
//                }
//            }
//
//            return analyticsArgs;
//        },


        addAdditionalTimelineItemToTimelineSection: function addAdditionalTimelineItemToTimelineSection() {
            return this.__parent.addTimelineItemToTimelineSection(this);
        },

        updateTimelineItem: function updateTimelineItem(timelineItemModel) {
            return this.__parent.updateTimelineItem(timelineItemModel, this);
        },

        updateTimelineItemsOrder: function updateTimelineItemsOrder() {
            return this.__parent.updateTimelineItemsOrder(this);
        },

        deleteTimelineItem: function deleteTimelineItem(timelineItemModel) {
            return this.__parent.deleteTimelineItem(timelineItemModel, this);
        },

        updateTimelineSection: function updateTimelineSection(callingScope) {
            return this.__parent.updateTimelineSection(this, callingScope);
        },

        deleteTimelineSection: function deleteTimelineSection() {
            return this.__parent.deleteTimelineSection(this);
        },

        findTimelineItemModel: function findTimelineItemModel(timelineItemId) {
            if (this.timeline_items && this.timeline_items.length > 0) {
                for (var i = 0, length = this.timeline_items.length; i < length; i++) {
                    if (this.timeline_items[i]._id === timelineItemId) {
                        return this.timeline_items[i];
                    }
                }
            }
        }
    });

};
