(function () {
    'use strict';

    // @ngInject
    function WebsocketHelperServiceCtor($q, $rootScope, websocket, AppConfigService, $http, Gon, Constants, RepositoryService, AnalyticsService, PubSubService, Fingerprint, $injector, _) {

        this.websocket = websocket;
        this.$q = $q;
        this.AppConfigService = AppConfigService;
        this.$http = $http;
        this.Gon = Gon;
        this.RepositoryService = RepositoryService;
        this.$injector = $injector;
        this.Constants = Constants;
        this.registeredRooms2 = [];
        this.waitingToSocketRooms2 = [];
        this.AnalyticsService = AnalyticsService;
        this.$rootScope = $rootScope;
        this.Fingerprint = Fingerprint;
        this.websocket2Authenticated = false;
        this.websocket2 = null;
        this._ = _;

        PubSubService.ventMyBitchUp(this);

        this.onSocketConnectCallback = this.onSocketConnectCallback.bind(this);
        this.onSocketDisconnectedCallback = this.onSocketDisconnectedCallback.bind(this);
        this.onAuthenticatedCallback = this.onAuthenticatedCallback.bind(this);
        this.onUnauthenticatedCallback = this.onUnauthenticatedCallback.bind(this);
        this.connect = this.connect.bind(this);
        this.shouldConnectToWebSocket = this.shouldConnectToWebSocket.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.isConnected = this.isConnected.bind(this);
        this.registerToRoom = this.registerToRoom.bind(this);
        this.unregisterFromRoom = this.unregisterFromRoom.bind(this);

        this.unregisterTimestampByRoomId = {};
    }

    Services.WebsocketHelperService = Class({
        constructor: WebsocketHelperServiceCtor,

        onSocketConnectCallback: function onSocketConnectCallback() {
            var token = this.RepositoryService.storageGet(this.Constants.storage.AUTH_TOKEN);
            var userId = this.RepositoryService.storageGet(this.Constants.storage.AUTH_USER_ID);
            var apiVersion = this.Gon.api_version;
            this.Fingerprint().then(function (fingerprint) {
                this.websocket2.emit('authentication', {
                    token: token,
                    user_id: userId,
                    api_version: apiVersion,
                    fingerprint: fingerprint
                });
            }.bind(this));
            this.websocket2.once('authenticated', this.onAuthenticatedCallback);
            this.websocket2.once('unauthorized', this.onUnauthenticatedCallback);
        },

        onSocketDisconnectedCallback: function onSocketDisconnectedCallback() {
            this.trigger('websocket_disconnected');
            this.websocket2Authenticated = false;
            for (var i = this.registeredRooms2.length - 1; i >= 0; i--) {
                var entry = this.registeredRooms2[i];

                if (this.waitingToSocketRooms2.map(function (x) {
                    return x.id;
                }).indexOf(entry.id) === -1) {
                    this.waitingToSocketRooms2.push({
                        id: entry.id,
                        callback: entry.callback
                    });
                }
                this.registeredRooms2.splice(i, 1);
                this.websocket2.off(entry.id);
            }
            if (this.websocket2.io.connecting.length === 0 && this.RepositoryService.storageGet(this.Constants.storage.AUTH_USER_ID)) {
                this.websocket2.connect();
            }
        },

        onAuthenticatedCallback: function onAuthenticatedCallback() {
            this.websocket2.off('unauthorized', this.onUnauthenticatedCallback);
            this.websocket2Authenticated = true;
            this.trigger('websocket_connected');

            for (var i = this.waitingToSocketRooms2.length - 1; i >= 0; i--) {
                var entry = this.waitingToSocketRooms2[i];
                this.registerToRoom(entry.id, entry.callback);
                this.waitingToSocketRooms2.splice(i, 1);
            }
        },

        onUnauthenticatedCallback: function onUnauthenticatedCallback() {
            this.websocket2.off('authenticated',this.onAuthenticatedCallback);
            var UsersManager = this.$injector.get('UsersManager');
            UsersManager.forceFetchCurrUser();
        },

        connect: function connect() {
            var secureConn = this.Gon.environment !== 'development';
            var isConnectionRetry = this.Gon.web_socket_conn_retry === "true";
            var socket_environment = this.Gon.web_socket_environment; // TBD - Currently not used. For prod we have one prod env using prod moshe

            if (this.shouldConnectToWebSocket()) {
                this.websocket2 = this.websocket.connect(this.Gon.websocket_address_2, {
                    secure: secureConn,
                    reconnection: isConnectionRetry,
                    reconnectionDelay: 5000,
                    reconnectionDelayMax: 60000,
                    transports: [ "websocket", "polling"]
                });

                this.websocket2.on('connect', this.onSocketConnectCallback);
                this.websocket2.on('disconnect', this.onSocketDisconnectedCallback);
            }
        },

        shouldConnectToWebSocket: function shouldConnectToWebSocket() {
            var isClientPortal = window.isClientPortal && window.isClientPortal();
            return this.Gon.websocket_address_2 && !isClientPortal && !this.AppConfigService.isMalkutServer();
        },

        disconnect: function disconnect() {
            if (this.websocket2 && this.websocket2.connected) {
                this.websocket2.disconnect();
            }
        },

        isConnected: function isConnected() {
            return this.websocket2 && this.websocket2.connected;
        },

        isConnectedToRoom: function isConnectedToRoom(object_id) {
            return !!(this.isConnected() && this.registeredRooms2 && this._.find(this.registeredRooms2, function (curr) { return curr.id === object_id; }));
        },

        registerReactCPT: function registerReactCPT(room, callback) {
            this.websocket2.on(room, callback);
            function unsubscribe() {
                this.websocket2.off(room, callback);
            }
            return unsubscribe.bind(this);
        },
        
        registerToRoom: function registerToRoom(object_id, callback) {
            if (this.websocket2 && this.websocket2.connected && this.websocket2Authenticated) {

                const lastUnregisterTimestamp = this.unregisterTimestampByRoomId[object_id];
                const isUnregisteredRecently = lastUnregisterTimestamp ? Date.now() - lastUnregisterTimestamp < 2000 : false;
                const registerDelay = isUnregisteredRecently ? 1000 : 0;

                // to prevent race condition between the unregister & register in react hook
                setTimeout(function() {
                        //don't register already to already registered rooms
                        var position2 = this.registeredRooms2.map(function (x) {
                            return x.id;
                        }).indexOf(object_id);
                        if (position2 === -1) {
                            this.websocket2.emit('register', {room: object_id});
                            this.websocket2.on(object_id, function () {
                                var args = Array.prototype.slice.call(arguments, 0); // arguments is not a native Array so we need to call slice in a funky way
                                this.$rootScope.$applyAsync(function asyncCall() {
                                    callback.apply(callback, args);
                                });
                            }.bind(this));
                            this.registeredRooms2.push({id: object_id, callback: callback});
                        }
                    }.bind(this),registerDelay);
            } else {
                // Add to waiting list
                if (this.waitingToSocketRooms2.map(function (x) {
                    return x.id;
                }).indexOf(object_id) === -1) {
                    this.waitingToSocketRooms2.push({id: object_id, callback: callback});
                }
            }
        },

        unregisterFromRoom: function unregisterFromRoom(object_id) {
            if (this.websocket2 && this.websocket2.connected && this.websocket2Authenticated) {
                this.unregisterTimestampByRoomId[object_id] = Date.now();
                
                this.websocket2.emit('unregister', {room: object_id});
                this.websocket2.off(object_id);

                var position2 = this.registeredRooms2.map(function (x) {
                    return x.id;
                }).indexOf(object_id);
                if (position2 > -1) {
                    this.registeredRooms2.splice(position2, 1);
                }
            }
        },

    });

}());
