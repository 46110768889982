(function () {
        "use strict"
        // @ngInject
        Services.AxiosInterceptorService = function AxiosInterceptorService($injector, uuid4, $timeout, $q, Gon, UrlMetadataService, $log, RepositoryService, DatadogRUMService, Constants, Fingerprint, $window) {
            var interceptor = {};
            var IGNORE_REQUEST_URLS = [
                'https://api.cloudinary.com',
                'https://res.cloudinary.com'
            ];
            var userIdHeaderListener = null;
            var fingerprint = null;
            Fingerprint().then(function (res) {
                fingerprint = res;
            });

            interceptor['request'] = function (config) {
                var shouldIgnore = IGNORE_REQUEST_URLS.some(function (url) {
                    return config.url.startsWith(url);
                });

                config = duplicateCallsPreventionInterceptor(config, shouldIgnore);
                config = metaDataInterceptor(config, shouldIgnore);
                config = httpAuthInterceptor(config, shouldIgnore);
                config = jsonpInterceptor(config);
                config = clientsApiSeparationInterceptor(config, shouldIgnore);
                config = axiosGetInterceptor(config)

                return config

            };
            interceptor['response'] = function (response) {
                var config = response.config;
                if (config.method === 'JSONP') {
                    delete $window[config.callbackName]; // cleanup
                }

                return response;
            };

            interceptor['responseError'] = function (rejection) {
                var config = rejection.config;
                if (config.method === 'JSONP') {
                    delete $window[config.callbackName]; // cleanup
                }

                return $q.reject(rejection);
            };
            interceptor['axiosRegisterUserIdHeaderListener'] = axiosRegisterUserIdHeaderListener

            function duplicateCallsPreventionInterceptor(config, shouldIgnore) {
                // *******     Duplication     *******
                try {
                    if (!shouldIgnore) {
                        config.headers[Gon.hb_api_headers.duplicate_calls_prevention] = uuid4.generate();
                    }
                } catch (error) {
                    DatadogRUMService.addError(new Error("Error in metadata interceptor", {cause: error}), {
                        config: config,
                        error: JSON.stringify(error)
                    });
                }
                return config;

                // *******     Duplication     *******
            }

            function metaDataInterceptor(config, shouldIgnore) {
                try {

                    if (shouldIgnore || config.url.indexOf(Gon.api_urls_prefix + '/api/v') !== 0) { // Add only to api calls
                        return config;
                    }

                    config.url = UrlMetadataService.addMetadata(config.url);

                } catch (e) {
                    // ignoring error here so we won't block the http call
                    $log.error(e);
                    DatadogRUMService.addError(new Error("Error in metadata interceptor", {cause: e}), {
                        config: config,
                        error: JSON.stringify(e)
                    });
                }
                return config;
            }

            function httpAuthInterceptor(config, shouldIgnore) {
                try {
                    var csrfToken = null;

                    function getCSRFToken() {

                        if (csrfToken === null) {
                            csrfToken = angular.element('meta[name=csrf-token]').attr('content');
                        }
                        return csrfToken;
                    }

                    if (shouldIgnore) {
                        return config;
                    }

                    if (!angular.isObject(config.headers)) {
                        config.headers = {};
                    }
                    try {
                        var currUser = RepositoryService.storageGet(Constants.storage.CURR_USER);
                        if (currUser && currUser.is_pro) {
                            var angularJStorage = RepositoryService.storageGet(Constants.storage.AUTH_USER_ID);
                            var absolutejStorageValue = JSON.parse(localStorage.getItem("jStorage")).HB_AUTH_USER_ID;
                            if (angularJStorage !== absolutejStorageValue) {
                                // RepositoryService.storagePut(Constants.storage.AUTH_USER_ID, absolutejStorageValue);
                                DatadogRUMService.addError(new Error("mismatch between jstorage value and local stroage value"), {
                                    jstorage_user_id: RepositoryService.storageGet(Constants.storage.AUTH_USER_ID),
                                    local_storage_user_id: JSON.parse(localStorage.getItem("jStorage")).HB_AUTH_USER_ID
                                });
                            }
                        }

                    } catch (error) {
                        DatadogRUMService.addError(new Error("Failed to extract data from local storage key - jStorage", {cause: error}));
                    }

                    var userIdHeader = RepositoryService.storageGet(Constants.storage.AUTH_USER_ID);
                    var authToken = RepositoryService.storageGet(Constants.storage.AUTH_TOKEN);
                    var trustedDevice = RepositoryService.storageGet(Constants.storage.TRUSTED_DEVICE);
                    var mfaSession = RepositoryService.storageGet(Constants.storage.MFA_SESSION);
                    var isTempPass = RepositoryService.storageGet(Constants.storage.IS_TEMP_PASS);

                    var extend = {};
                    extend[Gon.hb_api_headers.api_client_version] = Gon.api_version;
                    extend[Gon.hb_api_headers.fingerprint] = fingerprint;

                    if (userIdHeader) {
                        extend[Gon.hb_api_headers.auth_user_id] = userIdHeader;
                    }

                    if (authToken) {
                        extend[Gon.hb_api_headers.auth_token] = authToken;
                    }

                    if (trustedDevice) {
                        extend[Gon.hb_api_headers.trusted_device] = trustedDevice;
                    }

                    if (mfaSession) {
                        extend[Gon.hb_api_headers.mfa_session] = mfaSession;
                    }

                    if (isTempPass) {
                        extend[Gon.hb_api_headers.is_temp_pass] = isTempPass;
                    }

                    extend[Gon.hb_api_headers.csrf_token] = getCSRFToken();

                    var currUser = RepositoryService.storageGet(Constants.storage.CURR_USER);
                    extend[Gon.hb_api_headers.admin_login] = currUser ? !!currUser.is_hb_admin : false;

                    extend[Gon.hb_api_headers.hb_referer] = $window.location.href;

                    if (currUser && currUser.admin_token) {
                        extend[Gon.hb_api_headers.admin_token] = currUser.admin_token;
                    }
                    angular.extend(config.headers, extend);

                    if (userIdHeaderListener) {
                        userIdHeaderListener.checkCurrUserHeader(userIdHeader);
                    }
                } catch (error) {
                    DatadogRUMService.addError(new Error("Error in httpAuth interceptor", {cause: error}), {
                        config: config,
                        error: JSON.stringify(error)
                    });
                }
                return config;
            }

            function jsonpInterceptor(config) {
                try {
                    if (config.method === 'JSONP') {
                        var callbackId = angular.callbacks.counter.toString(36);
                        config.callbackName = 'angular_callbacks_' + callbackId;
                        config.url = config.url.replace('JSON_CALLBACK', config.callbackName);

                        $timeout(function () {
                            $window[config.callbackName] = angular.callbacks['_' + callbackId];
                        }, 0, false);
                    }
                } catch (error) {
                    DatadogRUMService.addError(new Error("Error in jsonp interceptor", {cause: error}), {
                        config: config,
                        error: JSON.stringify(error)
                    });

                }
                return config;
            }

            function axiosGetInterceptor(config) {
                try {
                    if (config.params && typeof config.params === 'object' && config.method.toUpperCase() === 'GET') {
                        let transformedParams = {};
                        Object.keys(config.params).forEach(key => {
                            const value = config.params[key];
                            const transformedKey = Array.isArray(value) ? `${key}[]` : key;
                            const transformedValue = Array.isArray(value)
                                ? value.map(item => (item && typeof item === 'object' ? JSON.stringify(item) : item))
                                : (value && typeof value === 'object' ? JSON.stringify(value) : value);
                            transformedParams[transformedKey] = transformedValue;
                        });

                        config.params = transformedParams;
                    }
                } catch (error) {
                    DatadogRUMService.addError(new Error("Error in axios interceptor", {cause: error}), {
                        config: config,
                        error: JSON.stringify(error)
                    });
                }
                return config;
            }

            function clientsApiSeparationInterceptor(config) {
                try {
                    if ((!Gon.api_urls_prefix || Gon.api_urls_prefix && config.url.startsWith(Gon.api_urls_prefix)) &&
                        $window.isClientPortal()) {
                        config.url = config.url.replace(/\/api\/v2\/(.*)/, function (match, $1) {
                            return `/api/v2/client/${$1}`
                        });
                    }
                } catch (error) {
                    DatadogRUMService.addError(new Error("Error in clientApiSeperation interceptor", {cause: error}), {
                        config: config,
                        error: JSON.stringify(error)
                    });
                }
                return config;
            }


            function axiosRegisterUserIdHeaderListener(listener) {
                userIdHeaderListener = listener;
            }

            return interceptor;
        }


    }()
);