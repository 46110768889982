Components.HbNormalLogo = {
    // @ngInject
    controller: function HbLogoComponentController() {
        this.$onInit = function $onInit() {
            this.fillColor = this.color || this.hbLogo.fillColor;
        };
    },
    bindings: {
        color: '@?',
        title: '@?'
    },
    name: 'hbNormalLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/normal/normal_logo.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
