(function () {
    "use strict";

    // @ngInject
    function PendingActionModalControllerCtor($scope, $injector, $modalInstance, $timeout, PopupMessageService, promise, workspaces, title, failedTitle, failedText, failedLink, positiveBtnText, analyticsActionName, successCallback, $translate, $sce) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'PendingActionModalController';
        this.$modalInstance = $modalInstance;
        this.timeout = $timeout;
        this.promise = promise;
        this.message = '';
        this.showFailed = false;
        this.showSuccess = false;
        this.isPending = true;
        this.progress = 0;
        this.workspaces = workspaces;
        this.popupSeverity = 'none';
        this.positiveBtnText = positiveBtnText;
        this.analyticsActionName = analyticsActionName;
        this.successCallback = successCallback || function() {};
        this.list = [];
        this.title = title;
        this.failedTitle = failedTitle;
        this.failedText = failedText;
        this.failedLink = failedLink;
        this.$translate = $translate;
        this.$sce = $sce;

        if(this.promise) {
            this.addPendingTask();
        }
    }

    Controllers.PendingActionModalController = Class(Controllers.BaseController, {
        constructor: PendingActionModalControllerCtor,

        posBtnClick: function posBtnClick() {
            this.$modalInstance.close();
        },

        setProgress: function updateProgress(progress) {
            this.progress = progress;
        },

        showResult: function showResult(data) {
            var self = this;
            this.timeout(function () {
                if (self.failedText) {
                    self.message = this.$sce.trustAsHtml(this.$translate.instant(self.failedText , { failed_link: this.failedLink }));
                }

                const failedCount = (data && data.length) || 'some';
                self.title = this.$translate.instant(self.failedTitle, { failed_count: failedCount });

                self.popupSeverity = 'Error';
                self.isPending = false;
                self.showFailed = true;

            }.bind(this), 1000);
        },

        addPendingTask: function addPendingTaskToNotification() {
            var self = this;
            this.promise.then(
                function success(data) {
                    this.setProgress( 100);
                    var resp = this.successCallback(data);
                    if(resp) {
                        this.list = resp;
                        self.showResult(resp);
                    }
                    else {
                        this.timeout(function () {
                            self.$modalInstance.close();
                        }, 1000);
                    }
                }.bind(this),
                function error(data) {
                    this.message = this.failedText;
                    this.$modalInstance.close();
                }.bind(this),
                function notify(data) {
                    this.setProgress(parseInt(data));
                }.bind(this)
            );
        }
    });
}());
