
Directives.TemplatesListDirective = function TemplatesListDirective() {

    // @ngInject
    function TemplatesListDirectiveControllerFunc($scope, $injector, TemplatesViewService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplatesListDirective';
        this.TemplatesViewService = TemplatesViewService;

        this.templateSections = [];
        this._buildTemplatesLists();

        $scope.$watchCollection('templatesNavigationListVm.templatesViewModel.templatesList()', function(newList, oldList) {
            this._buildTemplatesLists();
        }.bind(this));
    }

    var TemplatesListDirectiveController = Class(Controllers.BaseController, {
        constructor: TemplatesListDirectiveControllerFunc,

        _buildTemplatesLists: function _buildTemplatesLists() {
            this.templateSections = [];
            var allTitle = '';
            var allTemplates = this.templatesViewModel.templatesList();
            var legacyTemplatesList = [];

            // add default if needed
            // TODO (Alon) - Maybe remove the default from the rest of the list (not like that in prod)
            if(this.isDefaultable()) {
                var defaultTemplate = this.TemplatesViewService.getDefaultTemplate(this.templatesViewModel);
                this.templateSections.push({title: 'DEFAULT', templatesList: [defaultTemplate], section: 'default'});
                allTitle = 'ALL';
            }

            // add legacy if needed
            if(this.hasLegacy()) {
                var templatesGroups = this.TemplatesViewService.splitTemplatesToNewAndLegacy(allTemplates);
                allTemplates = templatesGroups[0];

                if(this.isInTemplates) {
                    legacyTemplatesList = templatesGroups[1];
                }
            }

            // push all templates
            this.templateSections.push({title: allTitle, templatesList: allTemplates, section: 'all'});

            if(legacyTemplatesList.length) {
                this.templateSections.push({title: 'LEGACY', templatesList: legacyTemplatesList, section: 'legacy'});
            }
        },

        isCreatable: function isCreatable() {
            return this.TemplatesViewService.isTemplateTypeCreatable(this.templatesViewModel);
        },

        isDefaultable: function isDefaultable() {
            return this.TemplatesViewService.isTemplateTypeDefaultable(this.templatesViewModel);
        },

        hasLegacy: function hasLegacy() {
            return this.TemplatesViewService.hasLegacy(this.templatesViewModel);
        },

        onAddNewTemplateClick: function onAddNewTemplateClick() {
            if (this.onNew) {
                this.onNew({templateViewModel: this.templatesViewModel});
            }
        },

        onTemplateClick: function onTemplateClick(template) {
            if(this.onSelect) {
                this.onSelect({template: template, templateViewModel: this.templatesViewModel});
            }
        },

        onDeleteClick: function onDeleteClick(template) {
            if(this.onDelete) {
                this.onDelete({template: template, templateViewModel: this.templatesViewModel});
            }
        },

        onDefaultClick: function onDefaultClick(template) {
            if(this.onDefault) {
                this.onDefault({template: template, templateViewModel: this.templatesViewModel}).then(function setDefaultSuccess() {
                    this._buildTemplatesLists();
                }.bind(this));
            }
        },

        onCloneClick: function onCloneClick(template) {
            if(this.onClone) {
                this.onClone({template: template, templateViewModel: this.templatesViewModel});
            }
        },

    });

    return {
        scope: {
            templatesViewModel: '=',
            selectedTemplateId: '=',
            isInTemplates:'=',

            newLinkText: '@',
            enableActions: '=',
            onNew: '&',
            onSelect: '&',
            onDelete: '&',
            onDefault: '&',
            onClone: '&'
        },
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/templates_list_template.html',
        controller: TemplatesListDirectiveController,
        controllerAs: 'templatesNavigationListVm',
        bindToController: true
    };

};