(function () {
    "use strict";

    // @ngInject
    function ChangeSubscriptionControllerCtor($scope, $injector, $state, $modalInstance, $translate, $sce,
                                              paymentMethod, currentPlan, price, isDiscounted, UsersManager,
                                              AnalyticsService, moment) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ChangeSubscriptionController';

        this.$state = $state;
        this.modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.price = price || 500;

        AnalyticsService.trackPageView(this, 'change subscription');

        this.company = UsersManager.getCurrUser().company;

        if (paymentMethod) {
            this.hasPaymentMethod = true;
            this.lastFourDigits = paymentMethod.last4;
            this.paymentType = paymentMethod.type || '';
        } else {
            this.hasPaymentMethod = false;
        }


        if (!currentPlan) { // Shouldn't happen!
            this.message = $translate.instant('ONBOARDING.CHANGE_PLAN.TEXT._FEE_AMOUNT_');
        } else {
            var startDate = moment(currentPlan.plan_start_date);
            if (moment().diff(startDate, 'days') > 60) { // more than 2 months ago
                this.message = $translate.instant('ONBOARDING.CHANGE_PLAN.TEXT._FEE_AMOUNT_');
            } else {
                var amount = this.company.company_type === 'venue' ? 1500 : 500;
                this.message = $translate.instant('ONBOARDING.CHANGE_PLAN.TEXT._REDUCED_LIFETIME_MEMBERSHIP_', {amount: (amount - currentPlan.total_amount_paid)});
            }
        }

        // in case we have a custom price (promos) we just show the price
        if(isDiscounted){
            this.message = '';
        }
        // The message might have HTML elements in it
        this.message = $sce.trustAsHtml(this.message);

    }

    Controllers.ChangeSubscriptionController = Class(Controllers.BaseController, {
        constructor: ChangeSubscriptionControllerCtor,

        cancel: function cancel() {
            this.modalInstance.dismiss();
        },

        upgrade: function upgrade() {
            this.modalInstance.close();
        }
    });
}());

