(function () {
    "use strict";


    // @ngInject
    function AbTestServiceCtor($injector, InitialAppLoadParamsService, $q, Enums, AppStates, $log, AnalyticsService, DeviceService, FeaturesService) {
        this.$q = $q;
        this.hasManualOverride = false;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.$injector = $injector;
        this.Enums = Enums;
        this.$log = $log;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.FeaturesService = FeaturesService;
        this.initialize();
    }

    Services.AbTestService = Class(function () {
        return {
            constructor: AbTestServiceCtor,

            initialize: function initialize(){
                this.tests = {};

                this.audienceFunctionMapping = {};
                this.isShowAbTestBrowser = false;
            },

            reset: function reset(){
                this.tests = {};
                this.user = null;
            },

            getAbTestVariation: function getAbTestVariation(abTestName) {
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                this.user = this.UsersManager.getCurrUser();

                var defer = this.$q.defer();
                var res = this.InitialAppLoadParamsService.getParam(abTestName);
                if (res) {
                    this.hasManualOverride = true;
                    this.tests[abTestName] = JSON.parse(res);
                    defer.resolve(JSON.parse(res));
                    return defer.promise;
                }

                // check audience
                if(angular.isFunction(this.audienceFunctionMapping[abTestName])) {
                    var isInAudience = this.audienceFunctionMapping[abTestName].call();
                    if(!isInAudience) {
                        defer.resolve(null);
                        return defer.promise;
                    }
                }

                if (this.tests[abTestName]){
                    defer.resolve(this.tests[abTestName]);
                    return defer.promise;
                }

                this.UsersManager.getAbTestVariation(abTestName).then(
                    function success(response) {
                        this.tests[abTestName] = response.data;
                        defer.resolve(response.data);
                    }.bind(this)
                );

                return defer.promise;
            },

            addSpecificAbTestVariant: function addSpecificAbTestVariant(abTestName, abTestVariant) {
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                return this.UsersManager.addSpecificAbTestVariant(abTestName, abTestVariant);
            },

            onAccountSetup: function onAccountSetup() {
                this.$log.debug('[tracking: abtest] onAccountSetup');
            },

            setIntentOnboardingAbTestOnUser: function setIntentOnboardingAbTestOnUser(testName, testVariation) {
                this.user.ab_tests[testName] = testVariation;
                this.UsersManager.updateCurrUserPropertiesOptimistically({ab_tests: this.user.ab_tests});
            },

            onQuizDone: function onQuizDone() {
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                var intentService = this.$injector.get('IntentService');
                this.user = this.UsersManager.getCurrUser();

                return Promise.resolve();
            },

            // called also on loginless!
            onLogin: function onLogin() {

            },

            toggleIsShowAbTestBrowser: function toggleIsShowAbTestBrowser() {
                this.isShowAbTestBrowser = !this.isShowAbTestBrowser;
            }

            
        };
    });
}());
