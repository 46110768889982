(function () {
    "use strict";

    // @ngInject
    function NonUsaWarningControllerCtor($scope, $injector, $modalInstance, $timeout, moment, $location,
        AnalyticsService, ModalService, OnboardingService, UsersManager,
        clickSource, overrideCallback, $window) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'NonUsaWarningController';

        this.AnalyticsService = AnalyticsService;
        this.AnalyticsService.trackPageView(this, 'non usa warning modal');

        this.modalInstance = $modalInstance;
        this.OnboardingService = OnboardingService;
        this.UsersManager = UsersManager;
        this.ModalService = ModalService;
        this.moment = moment;
        this.$location = $location;
        this.clickSource = clickSource;
        this.overrideCallback = overrideCallback;
        this.$window = $window;

    }

    Controllers.NonUsaWarningController = Class(Controllers.BaseController, {
        constructor: NonUsaWarningControllerCtor,

        cancelModal: function cancelModal() {
            this.modalInstance.dismiss();
        },

        handleClickCTA: function openIntakeForm() {
            if (this.clickSource === 'login_page') {
                this.modalInstance.dismiss();
                this.$window.location.assign(this.$window.location.origin + '/signup');
            } else {
                if (this.overrideCallback) {
                    this.overrideCallback();
                } else {
                    this.OnboardingService.openInProductIntakeForm(true);
                }
                this.modalInstance.dismiss();
            }

        }
    });
}());
