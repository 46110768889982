
(function () {
    'use strict';

    class ClientPortalComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, AppStates, CompaniesManager, ClientPortalService, ModalService, UsersManager, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalController';
            this.AppStates = AppStates;
            this.company = CompaniesManager.getCurrCompany();
            this.ClientPortalService = ClientPortalService;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;
            this.helpCenterUrl = "https://help.honeybook.com/getting-started/clients-and-creatives/your-clients-honeybook-experience";

            this._init();

            if (!this.company.wasFullyFetched()) {
                this.registerOnce(this.company, 'success', this._init);
            }
            this.calcDisableCustomizeScreen();

            this.user = UsersManager.getCurrUser();
            this.isAccountOwner = this.user.isAccountOwner();
            this.companyHasAnySubscription = this.user.companyHasAnySubscription();
        }

        _init(){
            this.clientPortalModel = this.ClientPortalService.getDefaultClientPortalConfiguration(this.company);
            this.calcDisableCustomizeScreen();
        }

        openEducationModal() {
            this.ModalService.openClientPortalDemoModal();
        }

        toggleDisablePreview() {
            this.disableCustomizeScreen = !this.disableCustomizeScreen;
        }

        calcDisableCustomizeScreen() {
            this.disableCustomizeScreen = !(this.company.company_url_prefixes && this.company.company_url_prefixes.length);
        }
    }


    Components.CompanyClientPortal = {
        bindings: {
            mobileView: '<'
        },
        controller: ClientPortalComponent,
        name: 'hbCompanyClientPortal',
        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/company_client_portal_template.html'
    };

}());