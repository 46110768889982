(function () {
    'use strict';

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb

    class IdentificationDocumentUploadController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, PhotosUploadManager, CompaniesManager, PopupMessageService) {
            super($scope, $injector);
            this.__objectType = 'IdentificationDocumentUploadController';

            this.PhotosUploadManager = PhotosUploadManager;
            this.CompaniesManager = CompaniesManager;
            this.PopupMessageService = PopupMessageService;
        }

        $onInit() {
            this.uploadIdentificationModel = angular.extend(this.PhotosUploadManager.getUpdatedModelData({
                instance: this.CompaniesManager.getCurrCompany(),
                type: 'user_identification_doc',
                forceUploadToS3: true
            }), {dontUpdateServer: true});

            this.uploadIdentificationPattern = '.png,.jpg,.jpeg';
        }

        onFilesDraggedOrSelected(files) {
            if (!files || !files.length) {
                return;
            }

            let file = files[0];
            this.onFileSelected({identificationFile: file, key: this.key});

            if (!file) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'UPLOAD_FILE._ERROR_FILE_TYPE_NOT_SUPPORTED_');
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                this.identificationUploadError = 'UPLOAD_FILE._ERROR_FILE_TOO_LARGE_SHORTER_';
                return;
            }

            this.identificationUploadInProgress = true;
            this.identificationUploadError = null;

            let promise = this.PhotosUploadManager.uploadPhoto([file], this.uploadIdentificationModel, this, true, true);
            promise.then(() => {
                this.onIdentificationUploaded({identificationFileUrl: file.s3Response.url, key: this.key});
            }).catch((resp) => {
                this.identificationUploadError = 'Oops, we\'ve encountered an error. Please reload the page and try again.'
            }).finally(() => {
                this.identificationUploadInProgress = false;
            });
        }
    }

    Components.IdentificationDocumentUpload = {
        bindings: {
            key: '@',
            title: '@',
            subTitle: '@',
            hasIdentificationImage: '<',
            onIdentificationUploaded: '&',
            onFileSelected: '&',
            shouldShowRequiredError: '<'
        },
        controller: IdentificationDocumentUploadController,
        name: 'hbIdentificationDocumentUpload',
        templateUrl: 'angular/app/modules/core/features/bank_account/identification_document_upload_template.html',
    };

}());