(function () {
    // @ngInject
    Services.DuplicateCallsPreventionInterceptor = function DuplicateCallsPreventionInterceptor(Gon, uuid4) {
        var IGNORE_REQUEST_URLS = [
            'https://api.cloudinary.com',
            'https://res.cloudinary.com'
        ];

        return {
            'request': config => {
                var ignore = IGNORE_REQUEST_URLS.some(function(url) {
                    return config.url.startsWith(url);
                });

                if (!ignore){
                    config.headers[Gon.hb_api_headers.duplicate_calls_prevention] = uuid4.generate();
                }

                return config;
            }
        };
    };
}());