(function () {
    "use strict";

    // @ngInject
    function InvoiceHubServiceCtor(StatsigService, Enums, UsersManager, DeviceService) {

        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.DeviceService = DeviceService;

        this.invoiceHubPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.invoiceHub,  'show invoice hub');
        this.invoiceAndPaymentHubPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.invoiceAndPaymentHubs,  'show hubs');
        this.filesHubPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.filesHub,  'show_files_tab');
        this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
    }

    Services.InvoiceHubService = Class(() => {

        return {
            constructor: InvoiceHubServiceCtor,

            isInvoiceHubVisible() {
                // this should be here in order to get the right user value after logout and re-login as another user
                const currUser = this.UsersManager.getCurrUser();
                const isSuperAdmin = currUser.hasCompanySuperAdminPermissions();
                const haveInvoicePageUserPermissions = currUser.isBookkeeper() || isSuperAdmin || currUser.isAccountOwner();

                return Promise.all([this.invoiceHubPromise, this.invoiceAndPaymentHubPromise, this.filesHubPromise]).then((values) => {
                    return this.isDesktop && haveInvoicePageUserPermissions && (values[0] || values[1]) && !values[2];
                });
            }
        };
    });
}());

