(function () {
    'use strict';

    // @ngInject
    function SecondLevelNavigationHomeControllerCtor($scope, $injector, $state, moment, Enums, UsersManager,
                                                     CompaniesManager, SetupGuideService, AnalyticsService, AppStates,
                                                     HomeService, OnboardingService, StatsigService, UiPersistenceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SecondLevelNavigationHomeController';

        this.moment = moment;
        this.UsersManager = UsersManager;
        this.SetupGuideService = SetupGuideService;
        this.AppStates = AppStates;
        this.Enums = Enums;
        this.HomeService = HomeService;
        this.AnalyticsService = AnalyticsService;
        this.$state = $state;
        this.OnboardingService = OnboardingService;
        this.StatsigService = StatsigService;
        this.UiPersistenceService = UiPersistenceService;
        this.HomeService.getShouldShowOneHome().then(function (isShowOneHome) {
            this.isShowOneHome = isShowOneHome;
        }.bind(this));


        // TODO: @ian Move this.states and calcStates to HomeService
        // since it's now shared with a few different components

        // min setup guide complete width (for ui purposes)
        var MIN_SETUP_GUIDE_COMPLETE = 20;

        this.states = {
            morning: {
                image: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-morning.svg",
                titleTranslationKey: "HOME.STATES.MORNING._TITLE_",
                subtitleTranslationKey: "HOME.STATES.MORNING._SUBTITLE_"
            },
            afternoon: {
                image: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-afternoon.svg",
                titleTranslationKey: "HOME.STATES.AFTERNOON._TITLE_",
                subtitleTranslationKey: "HOME.STATES.AFTERNOON._SUBTITLE_"
            },
            evening: {
                image: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-evening.svg",
                titleTranslationKey: "HOME.STATES.EVENING._TITLE_",
                subtitleTranslationKey: "HOME.STATES.EVENING._SUBTITLE_"
            },
            night: {
                image: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-night.svg",
                titleTranslationKey: "HOME.STATES.NIGHT._TITLE_",
                subtitleTranslationKey: "HOME.STATES.NIGHT._SUBTITLE_"
            },
            setup_guide: {
                image: "//res.cloudinary.com/honeybook/image/upload/v1524415232/app/home/header-setup.svg",
                titleTranslationKey: "HOME.STATES.SETUP_GUIDE._TITLE_",
                subtitleTranslationKey: "HOME.STATES.SETUP_GUIDE._SUBTITLE_"
            }
        };

        this.user = this.UsersManager.getCurrUser();
        this.companyIconImage = this.user.company.getCompanyIconImage();
        this.companyIconPublicId = this.user.company.getCompanyIconPublicId();
        this.isHomeForTrialer = this.HomeService.shouldShowHomeForTrialers();
        this.isUserSubscribed = this.user.companyHasSubscription();

        CompaniesManager.on(this.Enums.PubSubTriggers.companySubscribed, function companySubscribed() {
            this.isSetupGuideActive = this.SetupGuideService.isSetupGuideActive();
        }.bind(this));

        SetupGuideService.on('SetupGuideStepsUpdated', function onStepsUpdated() {
            this.isSetupGuideActive = this.SetupGuideService.isSetupGuideActive();
        }.bind(this));

        this.currentState = this.calcState();

        if (this.isUserSubscribed) {
            this.StatsigService.isGateEnabled('plm_gtm_homepage_banner', false).then((function (isEnabled) {
                var hiddenHomeBannerThemes = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.hiddenHomeBannerThemes, {});
                var currentProductLoveBannerTheme = window.statsig.getConfig('plm_gtm_theme').value.theme;
                var isSeenCurrentBannerTheme = hiddenHomeBannerThemes[currentProductLoveBannerTheme];
                if (!isSeenCurrentBannerTheme) {
                    this.HomeService.setVisibilityHomeBanner(isEnabled);
                }
            }).bind(this));
        }

        this.onCloseHomeBanner = this.onCloseHomeBanner.bind(this);
    }

    Controllers.SecondLevelNavigationHomeController = Class(Controllers.BaseController, {
        constructor: SecondLevelNavigationHomeControllerCtor,

        onCloseHomeBanner: function onCloseHomeBanner() {
            this.HomeService.setVisibilityHomeBanner(false);
        },

        upgradeNow: function onUpgradeNow() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.navigate_to_pricing, {
                trial_days_left: this.getDaysTillTrialEnds(),
                source:'second_level_navigation'
            });
        },

        getDaysTillTrialEnds: function getDaysTillTrialEnds() {
            return this.user && this.user.nTillTrialEnds();
        },

        goToMainCompany: function goToMainCompany() {
            this.$state.go(this.AppStates.root_core_navigation_settings_company_general);
        },

        calcState: function calcState() {

            var SPLIT_MORNING = 6;
            var SPLIT_AFTERNOON = 11;
            var SPLIT_EVENING = 17;
            var SPLIT_NIGHT = 22;

            var currState = null;

            // curr hour
            var currHour = parseFloat(this.moment().format("HH"));

            if (currHour >= SPLIT_MORNING && currHour <= SPLIT_AFTERNOON) {
                currState = this.states.morning;
            } else if (currHour >= SPLIT_AFTERNOON && currHour <= SPLIT_EVENING) {
                currState = this.states.afternoon;
            } else if(currHour >= SPLIT_EVENING && currHour <= SPLIT_NIGHT) {
                currState = this.states.evening;
            } else {
                currState = this.states.night;
            }

            return currState;
        }
    });
}());