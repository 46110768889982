(function () {
    'use strict';

    class DurationController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, UIUtils, SchedulingService) {
            super($scope, $injector);
            this.__objectType = 'DurationController';

            this.AnalyticsService = AnalyticsService;
            this.SchedulingService = SchedulingService;
            this.UIUtils = UIUtils;
        }

        $onChanges() {
            this._duration = this.duration || {};
            this._handleTypes();
        }

        onDurationCountChange() {

            // validate larger than 0
            if (this._duration.unit_count < 1) {
                this._duration.unit_count = 1;
            }

            this._handleTypes();
        }

        onDurationCountBlur() {
            this.onDurationCountChange();
            this.fireChangeAnalytics('value', this._duration.unit_count);
            this._onChange();
        }

        onDurationTypeChange(type) {
            this.unitType = type;
            this.fireChangeAnalytics('type', type);
            this._onChange();
        }

        fireChangeAnalytics(fieldName, fieldValue) {
            const params = {
                field_name: `${this.modelName} ${fieldName}`,
                field_value: fieldValue
            };
            this.AnalyticsService.track(this, this.analyticsEvent, params);
        }

        _onChange() {
            if(this.onChange) {
                this.onChange({
                    duration: {
                        unit_type: this.SchedulingService.pluralizeDuration(this.unitType) || this.unitType,
                        unit_count: this._duration.unit_count
                    }
                });
            }
        }

        _handleTypes() {
            this.durationTypes = this.options.map(type => this._duration.unit_count === 1 ? type : this.SchedulingService.pluralizeDuration(type));
            const unitType = this._duration.unit_count === 1 ? this.SchedulingService.singularizeDuration(this._duration.unit_type) : this._duration.unit_type;
            this.unitType = unitType || this.durationTypes[0];
        }
    }

    Components.Duration = {
        bindings: {
            duration: '<',
            options: '<',
            onChange: '&',
            showError: '<',
            errorMessage: '@',
            modelName: '@',
            analyticsEvent: '@'
        },
        controller: DurationController,
        name : 'duration',
        templateUrl: 'angular/app/modules/common/ui_components/duration/duration.html',
    };

}());
