Components.HbWinterLogo = {
    // @ngInject
    controller: function HbWinterLogoController($timeout, $interval) {

        this.playPromise = null;
        this.$onInit = function $onInit() {
            this.fillColor = this.hbLogo.fillColor;
            this.$timeout = $timeout;
            this.$interval = $interval;

            this.canDraw = true;

            var canvasSnow = document.getElementsByClassName("js-canvas-snow")[0];
            this.ctx = canvasSnow.getContext("2d");

            //canvas dimensions
            this.W = window.innerWidth;
            this.H = window.innerHeight;
            canvasSnow.width = this.W;
            canvasSnow.height = this.H;

            //snowflake particles
            this.mp = 25; //max particles
            this.particles = [];
            for (var i = 0; i < this.mp; i++) {
                this.particles.push({
                    x: Math.random()*this.W, //x-coordinate
                    y: Math.random()*this.H, //y-coordinate
                    r: Math.random()*4+1, //radius
                    d: Math.random()*this.mp //density
                });
            }
        };

        this.drawSnowBg = function drawSnowBg() {
            if (angular.isDefined(this.stop)) return;

            this.stop = this.$interval(function() {
                this.ctx.clearRect(0, 0, this.W, this.H);

                this.ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
                this.ctx.beginPath();
                for (var i = 0; i < this.mp; i++) {
                    var p = this.particles[i];
                    this.ctx.moveTo(p.x, p.y);
                    this.ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
                }
                this.ctx.fill();
                this.updateSnowBg();
            }.bind(this), 33);
        }.bind(this);

        this.stopSnowingInterval = function stopSnowingInterval() {
            if (angular.isDefined(this.stop)) {
                this.$interval.cancel(this.stop);
                this.stop = undefined;
            }
        }.bind(this);

        this.$onDestroy = function $onDestroy() {
            this.stopSnowingInterval();
        };

        //Function to move the snowflakes
        //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
        var angle = 0;
        this.updateSnowBg = function updateSnowBg() {
            angle += 0.01;
            for(var i = 0; i < this.mp; i++)
            {
                var p = this.particles[i];
                //Updating X and Y coordinates
                //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
                //Every particle has its own density which can be used to make the downward movement different for each flake
                //Lets make it more random by adding in the radius
                p.y += Math.cos(angle+p.d) + 1 + p.r/2;
                p.x += Math.sin(angle) * 2;

                //Sending flakes back from the top when it exits
                //Lets make it a bit more organic and let flakes enter from the left and right also.
                if(p.x > this.W+5 || p.x < -5 || p.y > this.H)
                {
                    if(i%3 > 0) //66.67% of the flakes
                    {
                        this.particles[i] = {x: Math.random()*this.W, y: -10, r: p.r, d: p.d};
                    }
                    else
                    {
                        //If the flake is exitting from the right
                        if(Math.sin(angle) > 0)
                        {
                            //Enter from the left
                            this.particles[i] = {x: -5, y: Math.random()*this.H, r: p.r, d: p.d};
                        }
                        else
                        {
                            //Enter from the right
                            this.particles[i] = {x: this.W+5, y: Math.random()*this.H, r: p.r, d: p.d};
                        }
                    }
                }
            }
        };

        this.snowBg = document.getElementsByClassName("js-snow-background")[0];

        this.startSnowing = function startSnowing() {
            this.snowBg.classList.add('snow-background--active');
            this.drawSnowBg();
        }.bind(this);

        this.stopSnowing = function stopSnowing() {
            this.snowBg.classList.remove('snow-background--active');
            this.$timeout(function () {
                this.stopSnowingInterval();
                this.canDraw = true;
            }.bind(this), 1000);
        }.bind(this);


        this.startSnowbird = function startSnowbird() {
            if (this.canDraw) {
                this.playPromise = angular.element('.js-video-logo')[0].play().catch((e) => {});
                this.startSnowing();
            }
        };

        this.stopSnowbird = function stopSnowbird() {
            this.canDraw = false;
            var vid = angular.element('.js-video-logo')[0];
            // see https://developers.google.com/web/updates/2017/06/play-request-was-interrupted for explanation to the following
            if (this.playPromise) {
                this.playPromise.then(() => {
                    vid.pause();
                    vid.currentTime = 0;
                }).catch((e) => {});
            }
            this.stopSnowing();
        };
    },
    name: 'hbWinterLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/winter/winter_logo.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
