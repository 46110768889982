Components.BurgerCloseMenu = {
    bindings: {
        shouldShow: '=',
        burgerToggle: '&',
        isBurgerActive: '='
    },
    // @ngInject
    controller: function BurgerCloseMenu(){
        this.toggleBurger = function toggleBurger() {
            this.burgerToggle();
        };
    },
    name: 'hbBurgerClose',
    templateUrl: 'angular/app/modules/common/ui_components/burger_and_close_menu/burger_and_close_template.html',
};