/**
 * Created by inonstelman on 12/25/14.
 */

(function(){

    if (!window.Services) {
        window.Services = {};
    }

    if (!window.Decorators) {
        window.Decorators = {};
    }

    if (!window.Models) {
        window.Models = {};
    }

    if (!window.Controllers) {
        window.Controllers = {};
    }

    if (!window.Directives) {
        window.Directives = {};
    }

    if (!window.Components) {
        window.Components = {};
    }

    if (!window.Filters) {
        window.Filters = {};
    }

    if (!window.Classes) {
        window.Classes = {};
    }

}());
