
// @ngInject

Models.PaymentsContainerModel = function PaymentsContainerModel(BaseModel, _, Enums){
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'PaymentsContainerModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds(){
            return {payments: 'PaymentsCollection'};
        },

        afterMixingFrom: function afterMixingFrom() {

            if(this.payments && this.payments.length > 0){
                var self = this;

                var foundFirstUnpaid = false;
                this.payments.forEach(function(payment, index){
                    payment.__payments_container = self;
                    payment.position = index + 1;
                    if (!payment.is_paid && !payment.is_pending && !foundFirstUnpaid && !payment.isMilestonePayment()){
                        payment.first_unpaid = true;
                        foundFirstUnpaid = true;
                    }
                    var file = payment.__payments_container.__workspace_file;
                    if (file) {
                        if (!file.has_map_payment && payment.isMarkedAsPaid()){
                            file.has_map_payment = true;
                        }
                        else {
                            file.has_map_payment = false;
                        }
                    }
                });
            }
        },

        getTotalProposalValue: function getTotalProposalValue(){
            return this.__parent.getTotalProposalValue();
        },

        allowAddPayment: function allowAddPayment() {

            return (this.__workspace_file &&
                    this.__workspace_file.isEditable() &&
                    this.isOwnerMode());
        },

        isOwnerOfFile: function isOwnerOfFile(){
            return (this.__workspace_file && this.__workspace_file.isOwnedByCurrUser());
        },

        isOwnerMode: function isOwnerMode(){
            return (this.__workspace_file && this.__workspace_file.isOwnerMode());
        },

        isAutoPayByVendor: function isAutoPayByVendor() {
            return this.auto_payment && this.auto_payment.activated_by_vendor;
        },

        isAutoPaymentActive: function isAutoPaymentActive() {
            return this.auto_payment && this.auto_payment.status === Enums.AutoPaymentStatus.active;
        },

        isAutoPaymentPending: function isAutoPaymentPending() {
            return this.auto_payment && this.auto_payment.status === Enums.AutoPaymentStatus.pendingApproval;
        },

        deletePayment: function deletePayment(paymentModel){
            return this.__parent.deletePayment(paymentModel);
        },

        addPayment: function addPayment(){
            return this.__parent.addPayment();
        },

        sendPaymentReminder: function sendPaymentReminder(paymentModel) {
            return this.__parent.sendPaymentReminder(paymentModel);
        },

        updatePayment: function updatePayment(paymentModel) {
            return this.__parent.updatePayment(paymentModel);
        },

        printPaymentReceipt: function printPaymentReceipt(payment) {
            return this.__parent.printPaymentReceipt(payment);
        },

        getTotalPaymentsAmount: function getTotalPaymentsAmount(){

            return _.reduce(this.payments, function(sum, payment){
                // if there is a refund, and the amount of the refund is bigger than the paid amount,
                // we include the tip in the total amount calculation => Refund will be first extracted from the payment amount and only then from tip
                // If there is no refund, we ignore the tip for that calculation.
                if(payment.isRefunded() && payment.refundedAmount() >  payment.amount) {
                    return (sum + payment.amount + payment.tip_paid - payment.refundedAmount());
                } else {
                    return (sum + payment.amount - payment.refundedAmount());
                }
            }, 0);

        },

        saveAsTemplate: function saveAsTemplate(company, templateTitle) {
            return this.__parent.savePaymentsContainerAsTemplate(templateTitle, company);
        },

        updatePaymentsByTemplate: function updatePaymentsByTemplate(templateId) {
            return this.__parent.updatePaymentsByTemplate(templateId);
        },

        getPaymentLocationById: function getPaymentById(id){
            var payment = null;
            for (var i=0; i< this.payments.length && payment===null; i++){
                if (this.payments[i]._id === id){
                    payment = this.payments[i];
                }
            }

            return i-1;
        },

        isFullyPaid: function isFullyPaid(){
            if (this.payments.length > 0) {
                var paidPayments = [];
                this.payments.forEach(function (payment) {
                    if (payment.is_paid) {
                        paidPayments.push(payment);
                    }
                });

                return (this.payments.length===paidPayments.length);
            }

            return false;
        },

        hasPaidPayments: function hasPaidPayments() {
            if (!this.payments) {
                return false;
            }

            return this.payments.some(function (payment) {
                return payment.is_paid || payment.is_pending;
            });
        },

        hasPendingPayment: function hasPendingPayment() {
            return angular.isDefined(this.getPendingPayment());
        },

        hasOnlyNotCompletedMilestonePayments: function hasOnlyNotCompletedMilestonePayments() {
            return this.payments.every(function (payment) {
                return payment.is_milestone && !payment.milestone_completed_on;
            });
        },

        getPendingPayment: function getPendingPayment() {

            if (!this.payments) {
                return undefined;
            }

            return this.payments.find(function (payment) {
                return payment.is_pending;
            });
        },

        getUnpaidPayments: function getUnpaidPayments() {
            if (!this.payments) {
                return [];
            }

            return _.filter(this.payments, function(payment) {
               return !payment.is_pending && !payment.is_paid;
            });
        },

        isRecurringPayment() {
            return this.__parent.isRecurringPayment();
        },

        isRecurringPaymentActive() {
            return this.isRecurringPayment() && this.recurring_payment && this.recurring_payment.is_active;
        },

        isRecurringPaymentAccepted() {
            return this.isRecurringPayment() && this.recurring_payment && this.recurring_payment.is_accepted;
        },

        togglePaymentScheduleType(isRecurring) {
            return this.__parent.togglePaymentScheduleType(isRecurring);
        },

        updateRecurringPayment(startDate, endDate) {
            return this.__parent.updateRecurringPayment(startDate, endDate);
        },

        cancelRecurringPayment() {
            return this.__parent.cancelRecurringPayment();
        },

        updateRecurringPaymentMethod(paymentMethodId) {
            return this.__parent.updateRecurringPaymentMethod(paymentMethodId);
        }
    });

};

