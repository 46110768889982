/**
 * The hb-file-status directive
 *

 *
 */

(function () {
    'use strict';

    Directives.FileStatusDirective = function FileStatusDirective() {

        // @ngInject
        function FileStatusControllerConstructor($scope, $injector) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'FileStatusController';

            this.fileStatusLabel = this.formatFileStatusLabel();

            $scope.$watch('fileStatusVm.workspaceFile.status', function (newStatus, oldStatus) {
                if (newStatus && (newStatus !== oldStatus)) {
                    this.fileStatusLabel = this.formatFileStatusLabel();
                }
            }.bind(this));
        }


        var FileStatusController = Class(Controllers.BaseController, {

            constructor: FileStatusControllerConstructor,

            formatFileStatusLabel: function formatFileStatusLabel() {
                if(this.workspaceFile.expired) {
                    return 'STATUS._CANCELLED_';
                }

                switch (this.workspaceFile.status) {
                    case 'proposal_created':
                        return 'FILE.FILE_STATUS_NEW._DRAFT_';
                    case 'proposal_sent':
                        return this.isTeamMember ? 'FILE.FILE_STATUS_NEW._SENT_' : 'FILE.FILE_STATUS_NEW._NEW_';
                    case 'proposal_seen':
                        return 'FILE.FILE_STATUS_NEW._VIEWED_';
                    case 'agreement_seen':
                        return 'FILE.FILE_STATUS_NEW._VIEWED_';
                    case 'agreement_signed':
                        return 'FILE.FILE_STATUS_NEW._SIGNED_';
                    case 'payments_seen':
                        return this.workspaceFile.has_pending_payment ? 'FILE.FILE_STATUS_NEW._PENDING_PAYMENT_': 'FILE.FILE_STATUS_NEW._PAYMENT_VIEWED_';
                    case 'retainer_paid':
                        if (this.workspaceFile.isRecurringPayment()) {
                            return 'FILE.FILE_STATUS_NEW._RECURRING_';
                        } else {
                            return 'FILE.FILE_STATUS_NEW._RETAINER_PAID_';
                        }
                    case 'payment_done':
                        // if (this.workspaceFile.isRecurringPayment()) {
                        //     return 'FILE.FILE_STATUS_NEW._RECURRING_';
                        // }
                        switch (this.workspaceFile.file_type) {
                            case "questionnaire":
                                return 'FILE.FILE_STATUS_NEW._SUBMITTED_';
                            case "agreement":
                                return 'FILE.FILE_STATUS_NEW._SIGNED_';
                            case "brochure":
                                return 'FILE.FILE_STATUS_NEW._SUBMITTED_';
                            case "timeline":
                                return 'FILE.FILE_STATUS_NEW._VIEWED_';
                            default:
                                return 'FILE.FILE_STATUS_NEW._COMPLETE_';
                        }
                    case 'draft':
                        return 'FILE.FILE_STATUS_NEW._DRAFT_';
                    case 'in_progress':
                        return 'FILE.FILE_STATUS_NEW._IN_PROGRESS_';
                    case 'viewed':
                        return 'FILE.FILE_STATUS_NEW._VIEWED_';
                    case 'sent':
                        return 'FILE.FILE_STATUS_NEW._SENT_';
                    case 'new':
                        return 'FILE.FILE_STATUS_NEW._NEW_';
                    case 'completed':
                        return 'FILE.FILE_STATUS_NEW._COMPLETED_';
                    default:
                        return '';
                }
            },

            onStatusLabelClick: function onStatusLabelClick(event) {
                event.stopPropagation();
            },

        });

        return {
            scope: {
                workspaceFile: '=',
                isTeamMember: '=',
                isShowCanceled : '=',
                className : '@'
            },
            templateUrl: 'angular/app/modules/common/ui_components/file_status/file_status_directive_template.html',
            controller: FileStatusController,
            controllerAs: 'fileStatusVm',
            bindToController: true
        };
    };


}());