(function () {
    "use strict";

    // @ngInject
    function ViewPreferredVendorsListControllerCtor($injector, $scope, $modalInstance, _, CompanyService, LeadsManager, UsersManager,
                                                    PopupMessageService, AnalyticsService, pvl_id, WorkspacesManager, eventId, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ViewPreferredVendorsListController';

        this._ = _;
        this.modalInstance = $modalInstance;
        this.PopupMessageService = PopupMessageService;
        this.CompanyService = CompanyService;
        this.WorkspacesManager = WorkspacesManager;

        this.currUser = UsersManager.getCurrUser();
        AnalyticsService.trackPageView(this, 'view preferred vendors list', {user_type: this.currUser.system_user_type, user_id: this.currUser._id});
        this.eventId = eventId;
        this.pvl_id = pvl_id;
        this.DatadogRUMService = DatadogRUMService;
        this.companyTypesMap = CompanyService.getCompanyTypesTranslationMap();
        this.selectedPVs = {};
        this.company = {};
        this.showCompanyLogo = false;
        this.selectedCompanyTypes = [];
        this.referringVendorId = null;
        this.currUserIsFromReferringCompany = false;

        this.loading = true;

        this.WorkspacesManager.getPreferredVendorsListById(pvl_id).then(
          function gotPreferredLeads(resp) {
              this.loading = false;

              // srt company logo
              this.company = resp.data.company;
              this.referringVendorId = resp.data.referring_vendor_id;
              this.currUserIsFromReferringCompany = (this.currUser.company._id === resp.data.company._id);
              this.showCompanyLogo = !this.CompanyService.isDefaultCompanyLogo(this.company);

              // create vendors list
              this.createPvlViewModel(resp.data.users);

          }.bind(this),
            function errorGettingPVs(error) {
                this.loading = false;
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                    'VENDOR_RECOMMENDATIONS.EDIT._ERROR_FETCHING_PVL_',
                        this.closeModal.bind(this));
                this.DatadogRUMService.addError(error, { 'pvl': 1 })
            }.bind(this)
        );

        this.isViewOnly = true;
        this.isPreview = false;
        this.isOwnerMode = false;
    }

    Controllers.ViewPreferredVendorsListController = Class(Controllers.BaseController, {
        constructor: ViewPreferredVendorsListControllerCtor,

        onTemplateInit: function onTemplateInit() {
            this.scrollSection = angular.element('.js-scrolling-section');
            this.vendorList = angular.element('.js-vendor-list');

            this.scrollSection.on('scroll', function () {
                if (this.scrollSection.scrollTop() > 0) {
                    this.vendorList.addClass('scrolling');
                } else {
                    this.vendorList.removeClass('scrolling');
                }
            }.bind(this));
        },

        scrollUp: function scrollUp() {
            this.scrollSection.animate({scrollTop: 0});
        },

        dismissModal: function dismissModal() {
            this.modalInstance.dismiss();
        },

        closeModal: function closeModal(){
            this.modalInstance.close();
        },

        analyticsProperties: function analyticsProperties() {
            return {
                controller: "ViewPreferredVendorsList"
            };
        },
        
        showLogo: function showLogo() {
            return this.showCompanyLogo;
        },

        createPvlViewModel: function createPvlViewModel(users) {
            this.selectedPVs = {};
            users.forEach(function(user) {
                var contact = {user: user};
                this.selectedPVs[user.company.company_type] = this.selectedPVs[user.company.company_type] || [];
                this.selectedPVs[user.company.company_type].push(contact);
            }.bind(this));
            this.selectedCompanyTypes = this._.keys(this.selectedPVs);
        }
    });

}());
