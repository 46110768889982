Directives.FeedCommentViewerDirective =  function FeedCommentViewerDirective(){

    // @ngInject
    function FeedCommentViewerDirectiveControllerFunc($translate, $element, $, AppStates, $compile, $scope, $injector,
                                                      WorkspaceFileService, UIUtils){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedCommentViewerDirectiveController';

        this.comment = UIUtils.sanitize(this.commentModel.html_body);

        this.goToFile = function goToFile() {
            WorkspaceFileService.gotoWorkspaceFileById(this.commentModel.workspace_file._id, null, null, false);
        };
    }

    var FeedCommentViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedCommentViewerDirectiveControllerFunc
    });

    return {
        scope:{
            commentModel: '=commentModel'
        },
        templateUrl : 'angular/app/modules/core/features/feed/viewer/feed_comment_viewer_directive_template.html',
        controller : FeedCommentViewerDirectiveController,
        controllerAs : 'feedCommentViewerVm',
        bindToController : true
    };
};