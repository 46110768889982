(function () {
    "use strict";

    Services.QuestionsStateService = class QuestionsStateService {

        // @ngInject
        constructor(PubSubService, Enums) {
            this.PubSubService = PubSubService;
            this.Enums = Enums;
            this._init();
            this.PubSubService.ventMyBitchUp(this);
        }

        _init() {
            this._currentQuestionsKeys = [];
            this._watchedModel = null;
            this._watchedCompany = null;
        }

        watchModel(model, company) {

            // set current model
            this._watchedModel = model;
            this._watchedCompany = company;

            // first update
            this.updateQuestionsKeys();

            // DON"T DELETE THIS
            // // every time the model changes, update again
            // var modelToRegister;
            // if (this._watchedModel.isModelOf('WorkspaceFile') ||
            //     this._watchedModel.isModelOf('ContactForm')) {
            //     modelToRegister = this._watchedModel;
            // } else {
            //     modelToRegister = this._watchedCompany;
            // }
            //
            // modelToRegister.on('success', this.updateQuestionsKeys.bind(this));
        }

        unwatchModel() {
            this._init();
        }

        triggerErrorCheck() {
            this.trigger('CheckQuestionsErrors');
        }

        updateQuestionsKeys() {

            // get th
            var _questionKeys = this._watchedModel.file_dynamic_content.getConnectedQuestionsInContent();

            // save on model
            this._watchedModel.__questionsKeys = _questionKeys;

            // set on this service
            this._currentQuestionsKeys.splice(0, this._currentQuestionsKeys.length);
            this._currentQuestionsKeys.push(..._questionKeys);
            this.trigger('QuestionsKeysChanged');
        }

        hasQuestion(key) {
            return this._currentQuestionsKeys.indexOf(key) > -1;
        }

        getConnectedQuestionOptionsList(questionKey) {
            var list;

            if(!this._watchedModel || !this._watchedCompany) {
                return list;
            }

            // get list
            if(questionKey === this.Enums.suggestedQuestions.lead_source) {
                list = this._watchedCompany.sortedLeadSources();
            } else if (questionKey === this.Enums.suggestedQuestions.project_type) {
                list = this._watchedCompany.sortedProjectTypes();
            }

            return list;
        }

    };
}());