(function () {
    'use strict';

    Services.FontsService = class FontsService {

        // @ngInject
        constructor(_) {

            const _fontTypeComparator = (fontA, fontB) => {
                var nameA = fontA.font_type.toUpperCase();
                var nameB = fontB.font_type.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            };

            this.fonts = [
                {
                    font_type: "Abel",
                    font_url: "https://fonts.googleapis.com/css?family=Abel",
                    font_family: "'Abel', sans-serif"
                }, 
                {
                    font_type: "Abril Fatface",
                    font_url: "https://fonts.googleapis.com/css?family=Abril+Fatface",
                    font_family: "'Abril Fatface', cursive"
                }, 
                {
                    font_type: "Arvo",
                    font_url: "https://fonts.googleapis.com/css?family=Arvo",
                    font_family: "'Arvo', serif"
                }, 
                {
                    font_type: "Baloo",
                    font_url: "https://fonts.googleapis.com/css?family=Baloo",
                    font_family: "'Baloo', cursive"
                },
                {
                    font_type: "Bree Serif",
                    font_url: "https://fonts.googleapis.com/css?family=Bree+Serif",
                    font_family: "'Bree Serif', serif"
                },
                {
                    font_type: "Caveat",
                    font_url: "https://fonts.googleapis.com/css?family=Caveat",
                    font_family: "'Caveat', cursive"
                },
                {
                    font_type: "Comfortaa",
                    font_url: "https://fonts.googleapis.com/css?family=Comfortaa",
                    font_family: "'Comfortaa', cursive"
                },
                {
                    font_type: "Concert One",
                    font_url: "https://fonts.googleapis.com/css?family=Concert+One",
                    font_family: "'Concert One', cursive"
                },
                {
                    font_type: "Corben", //TODO: no bold
                    font_url: "https://fonts.googleapis.com/css?family=Corben",
                    font_family: "'Corben', cursive"
                },
                {
                    font_type: "Cormorant Garamond",
                    font_url: "https://fonts.googleapis.com/css?family=Cormorant+Garamond",
                    font_family: "'Cormorant Garamond', serif"
                },
                {
                    font_type: "Courgette",
                    font_url: "https://fonts.googleapis.com/css?family=Courgette",
                    font_family: "'Courgette', cursive"
                },
                {
                    font_type: "Cutive Mono",
                    font_url: "https://fonts.googleapis.com/css?family=Cutive+Mono",
                    font_family: "'Cutive Mono', monospace"
                },
                {
                    font_type: "Dancing Script",
                    font_url: "https://fonts.googleapis.com/css?family=Dancing+Script",
                    font_family: "'Dancing Script', cursive"
                },
                {
                    font_type: "Dosis",
                    font_url: "https://fonts.googleapis.com/css?family=Dosis",
                    font_family: "'Dosis', sans-serif"
                },
                {
                    font_type: "Droid Serif",
                    font_url: "https://fonts.googleapis.com/css?family=Droid+Serif",
                    font_family: "'Droid Serif', serif"
                },
                {
                    font_type: "Elsie",
                    font_url: "https://fonts.googleapis.com/css?family=Elsie",
                    font_family: "'Elsie', cursive"
                },
                {
                    font_type: "Frank Ruhl Libre",
                    font_url: "https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre",
                    font_family: "'Frank Ruhl Libre', serif"
                },
                {
                    font_type: "Fugaz One",
                    font_url: "https://fonts.googleapis.com/css?family=Fugaz+One",
                    font_family: "'Fugaz One', cursive"
                },
                {
                    font_type: "Great Vibes",
                    font_url: "https://fonts.googleapis.com/css?family=Great+Vibes",
                    font_family: "'Great Vibes', cursive"
                },
                {
                    font_type: "Inconsolata",
                    font_url: "https://fonts.googleapis.com/css?family=Inconsolata",
                    font_family: "'Inconsolata', monospace"
                },
                {
                    font_type: "Josefin Sans",
                    font_url: "https://fonts.googleapis.com/css?family=Josefin+Sans",
                    font_family: "'Josefin Sans', sans-serif"
                },
                {
                    font_type: "Kaushan Script",
                    font_url: "https://fonts.googleapis.com/css?family=Kaushan+Script",
                    font_family: "'Kaushan Script', cursive"
                },
                {
                    font_type: "Knewave",
                    font_url: "https://fonts.googleapis.com/css?family=Knewave",
                    font_family: "'Knewave', cursive"
                },
                {
                    font_type: "Kristi",
                    font_url: "https://fonts.googleapis.com/css?family=Kristi",
                    font_family: "'Kristi', cursive"
                },
                {
                    font_type: "Lato",
                    font_url: "https://fonts.googleapis.com/css?family=Lato",
                    font_family: "'Lato', sans-serif"
                },
                {
                    font_type: "Libre Baskerville",
                    font_url: "https://fonts.googleapis.com/css?family=Libre+Baskerville",
                    font_family: "'Libre Baskerville', serif"
                },
                {
                    font_type: "Lily Script One",
                    font_url: "https://fonts.googleapis.com/css?family=Lily+Script+One",
                    font_family: "'Lily Script One', cursive"
                },
                {
                    font_type: "Lobster",
                    font_url: "https://fonts.googleapis.com/css?family=Lobster",
                    font_family: "'Lobster', cursive"
                },
                {
                    font_type: "Lora",
                    font_url: "https://fonts.googleapis.com/css?family=Lora",
                    font_family: "'Lora', serif"
                },
                {
                    font_type: "Merriweather",
                    font_url: "https://fonts.googleapis.com/css?family=Merriweather",
                    font_family: "'Merriweather', serif"
                },
                {
                    font_type: "Montserrat",
                    font_url: "https://fonts.googleapis.com/css?family=Montserrat",
                    font_family: "'Montserrat', sans-serif"
                },
                {
                    font_type: "Mr Dafoe",
                    font_url: "https://fonts.googleapis.com/css?family=Mr+Dafoe",
                    font_family: "'Mr Dafoe', cursive"
                },
                {
                    font_type: "Mr De Haviland",
                    font_url: "https://fonts.googleapis.com/css?family=Mr+De+Haviland",
                    font_family: "'Mr De Haviland', cursive"
                },
                {
                    font_type: "Mrs Saint Delafield",
                    font_url: "https://fonts.googleapis.com/css?family=Mrs+Saint+Delafield",
                    font_family: "'Mrs Saint Delafield', cursive"
                },
                {
                    font_type: "Mrs Sheppards",
                    font_url: "https://fonts.googleapis.com/css?family=Mrs+Sheppards",
                    font_family: "'Mrs Sheppards', cursive"
                },
                {
                    font_type: "Nunito",
                    font_url: "https://fonts.googleapis.com/css?family=Nunito",
                    font_family: "'Nunito', sans-serif"
                },
                {
                    font_type: "Open Sans",
                    font_url: "https://fonts.googleapis.com/css?family=Open+Sans",
                    font_family: "'Open Sans', sans-serif"
                },
                {
                    font_type: "Open Sans Condensed",
                    font_url: "https://fonts.googleapis.com/css?family=Open+Sans+Condensed",
                    font_family: "'Open Sans Condensed', sans-serif"
                },
                {
                    font_type: "Oswald",
                    font_url: "https://fonts.googleapis.com/css?family=Oswald",
                    font_family: "'Oswald', sans-serif"
                },
                {
                    font_type: "Oxygen",
                    font_url: "https://fonts.googleapis.com/css?family=Oxygen",
                    font_family: "'Oxygen', sans-serif"
                },
                {
                    font_type: "Parisienne",
                    font_url: "https://fonts.googleapis.com/css?family=Parisienne",
                    font_family: "'Parisienne', cursive"
                },
                {
                    font_type: "Playfair Display",
                    font_url: "https://fonts.googleapis.com/css?family=Playfair+Display",
                    font_family: "'Playfair Display', serif"
                },
                {
                    font_type: "Proxima Nova",
                    font_url: "https://d25purrcgqtc5w.cloudfront.net/dist/fonts/proximanova/302D42_4_0.eot",
                    font_family: "'ProximaNova', Arial, sans-serif"
                },
                {
                    font_type: "PT Sans",
                    font_url: "https://fonts.googleapis.com/css?family=PT+Sans",
                    font_family: "'PT Sans', sans-serif"
                },
                {
                    font_type: "PT Sans Narrow",
                    font_url: "https://fonts.googleapis.com/css?family=PT+Sans+Narrow",
                    font_family: "'PT Sans Narrow', sans-serif"
                },
                {
                    font_type: "Quicksand",
                    font_url: "https://fonts.googleapis.com/css?family=Quicksand",
                    font_family: "'Quicksand', sans-serif"
                },
                {
                    font_type: "Raleway",
                    font_url: "https://fonts.googleapis.com/css?family=Raleway",
                    font_family: "'Raleway', sans-serif"
                },
                {
                    font_type: "Roboto",
                    font_url: "https://fonts.googleapis.com/css?family=Roboto",
                    font_family: "'Roboto', sans-serif"
                },
                {
                    font_type: "Roboto Slab",
                    font_url: "https://fonts.googleapis.com/css?family=Roboto+Slab",
                    font_family: "'Roboto Slab', serif"
                },
                {
                    font_type: "Satisfy",
                    font_url: "https://fonts.googleapis.com/css?family=Satisfy",
                    font_family: "'Satisfy', cursive"
                },
                {
                    font_type: "Slabo",
                    font_url: "https://fonts.googleapis.com/css?family=Slabo+13px",
                    font_family: "'Slabo 13px', serif"
                },
                {
                    font_type: "Source Sans Pro",
                    font_url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro",
                    font_family: "'Source Sans Pro', sans-serif"
                },
                {
                    font_type: "Special Elite",
                    font_url: "https://fonts.googleapis.com/css?family=Special+Elite",
                    font_family: "'Special Elite', cursive"
                },
                {
                    font_type: "Spectral",
                    font_url: "https://fonts.googleapis.com/css?family=Spectral",
                    font_family: "'Spectral', serif"
                }
            ].sort(_fontTypeComparator);


            this.ckFonts = _.pluck(this.fonts, 'font_family');

            this.oldContactFormFont = _.filter(this.fonts, function(font){ return font.font_img; });

            this.fontSizes = [
                {text: 'Small', font_size: "small", fontSize: "14px"},
                {text: "Normal", font_size: "medium", fontSize: "20px"},
                {text: "Large", font_size: "large", fontSize: "24px"},
                {text: "Huge", font_size: "x_large", fontSize: "36px"},
            ]

        }

    };

}());

