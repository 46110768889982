/**
 * Created by dvircohen on 12/07/2017.
 */
(function() {
    'use strict';

    // @ngInject
    function AgreementValuesStrategyFactoryCtor($injector, moment, $filter) {
        this.$injector = $injector;
        this.moment = moment;
        this.$filter = $filter;

        this.strategies = {
            file: $injector.get('AgreementValuesFileStrategy'),
            template: $injector.get('AgreementValuesTemplateStrategy'),
            company: $injector.get('AgreementValuesStrategy'),
            simulated: $injector.get('AgreementValuesSimulatedFileStrategy')
        };
    }

    Services.AgreementValuesStrategyFactory = Class(function() {
        return {
            constructor: AgreementValuesStrategyFactoryCtor,

            byFile: function byFile(workspaceFile) {
                return this._enrichCustomizedFields(workspaceFile.event, new this.strategies.file(workspaceFile));
            },

            byTemplate: function byTemplate(payableFileTemplate) {
                return this._enrichCustomizedFields(payableFileTemplate.getCompany(), new this.strategies.template(payableFileTemplate));
            },

            byCompany: function byCompany(company) {
                return this._enrichCustomizedFields(company, new this.strategies.company(company));
            },

            bySimulation: function bySimulation(workspaceFile) {
                return this._enrichCustomizedFields(workspaceFile.event, new this.strategies.simulated(workspaceFile));
            },

            _enrichCustomizedFields: function _enrichCustomizedFields(object, strategy) {
                if (object.custom_project_fields_v2) {
                    object.custom_project_fields_v2.forEach(function(field) {
                        strategy['get' + (field.linked_id)] = function() {
                            return field.type === "date" && !!field.value ? new Date(field.value).toDateString(): field.value;
                        };
                    }.bind(this));
                }
                if (object.custom_project_fields) {
                    object.custom_project_fields.forEach(function(field) {
                        strategy['get' + (field.identifier)] = function() {
                            return field.value;
                        };
                    }.bind(this));
                }

                return strategy;
            }
        };
    });
})();