(function () {
    "use strict";

    // @ngInject
    Services.AutoInputSizeService = function AutoInputSizeService($, uuid4, UIUtils) {
        var $widthTester = $("<span id='widthTester' style='display: none;'></span>");
        $('body').append($widthTester);

        this.setSize = function setSize(input, minWidth, additionalWidth) {
            var $input, text, width;

            // default
            if (minWidth == null) {
                minWidth = 25;
            }

            $input = $(input);
            if ($input.length === 0) {
                return;
            }
            text = $input.val() || $input.attr('placeholder') || '';
            if (text.length === 0) {
                width = minWidth;
            } else {
                var id = $input.attr('input-size-id');
                if (!id) {
                    id = uuid4.generate();
                    $input.attr('input-size-id', id);
                }

                if ($widthTester.attr('input-size-id') !== id) {
                    $widthTester.attr('style', 'display: none;');
                    $widthTester.css($input.css([
                        'fontFamily',
                        'fontSize',
                        'fontWeight',
                        'fontStyle',
                        'font-variant',
                        'letterSpacing',
                        'text-indent',
                        'textTransform',
                        'text-align',
                        'wordSpacing',
                        'textIndent',
                        'boxSizing',
                        'borderRightWidth',
                        'borderLeftWidth',
                        'borderLeftStyle',
                        'borderRightStyle',
                        'paddingLeft',
                        'paddingRight',
                        'marginLeft',
                        'marginRight'
                    ]));
                }
                var safeString = UIUtils.sanitize(text);
                $widthTester.html(safeString.replace(/[ ]/g, '&nbsp;'));
                width = $widthTester.outerWidth() || $widthTester.width();
                var extraSpace = $input.attr('hb-auto-input-size-extra-space');
                if (extraSpace) {
                    width = width + Number(extraSpace); //adding space, usually as placeholder for leading/trailing whitespaces
                }
            }
            if (additionalWidth) {
                $input.width(width + additionalWidth);
            } else {
                $input.width(width);
            }
        };
    };


}());
