/**
 * Directive for adding Google Places Autocomplete to an input box
 */
Directives.GoogleAutocompleteDirective = function GoogleAutocompleteDirective() {

    // @ngInject
    function GoogleAutocompleteDirectiveControllerCtor($scope, $injector, $element, google, AnalyticsService, UIUtils) {

        this.constructor.$super.call(this, $scope, $injector);
        this.google = google;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;

        var placeTypes = [];
        if ($scope.hbAutocompleteCitiesOnly) {
            placeTypes = ['(cities)'];
        }

        if ($scope.hbAutocompleteRegionsOnly) {
            placeTypes = ['(regions)'];
        }

        // gets necessary data only
        var fields = ['address_component', 'adr_address', 'formatted_address', 'geometry', 'name'];

        // When you use one of these make sure it matches the current google api
        if ($scope.hbAutocompleteFullBasicData) {
            fields.concat(['icon', 'business_status', 'photo', 'type', 'url', 'utc_offset_minutes', 'vicinity']);
        }
        if ($scope.hbAutocompleteContactData) {
            fields.concat(['formatted_phone_number', 'international_phone_number', 'opening_hours', 'website']);
        }
        if ($scope.hbAutocompleteAtmosphereData) {
            fields.concat(['price_level', 'rating', 'review']);
        }

        this.updateNgModel = !(angular.isDefined($scope.hbAutocompleteUpdateNgmodel) && !$scope.hbAutocompleteUpdateNgmodel);

        var allowedCountries = $scope.hbAutocompleteAllowedCountries ? $scope.hbAutocompleteAllowedCountries : [];
        var options = {
            types: placeTypes,
            componentRestrictions: {country: allowedCountries},
            fields: fields
        };
        this.google.then(function(googleSdk) {
            if(angular.isDefined(googleSdk.maps)){
                this.googlePlace = new googleSdk.maps.places.Autocomplete($element[0], options);
                this.initAutocompleteEventListener(this.googlePlace, $element[0], options);
            }
        }.bind(this));

        $element[0].addEventListener('paste', function (e) {
            const pastedData = (e.clipboardData || window.clipboardData).getData('text');
            this.AnalyticsService.track(
                this, 
                this.AnalyticsService.analytics_events.google_autocomplete_paste,
                 { value: pastedData }
            );
        }.bind(this));
    }

    var GoogleAutocompleteDirectiveController = Class(Controllers.BaseController, {
        constructor: GoogleAutocompleteDirectiveControllerCtor,

        applyAddress: function applyAddress(result, input){
            this.$scope.$apply(function () {
                if (this.updateNgModel) {
                    if(typeof(input) !== "undefined" && input.value){
                        this.$scope.ngModel = input.value;
                    } else{
                        this.$scope.ngModel = result.formatted_address;
                    }
                }

                if (this.$scope.hbAutocompleteCallback) {
                    this.$scope.hbAutocompleteCallback({geocodeResult: result});
                }
            }.bind(this));
        },

        initAutocompleteEventListener: function initAutocompleteEventListener(googlePlace, input, options) {
            this.google.then(function(googleSdk) {
                googleSdk.maps.event.addListener(googlePlace, 'place_changed', function () {
                    this.result = googlePlace.getPlace();
                    if (this.result !== undefined) {
                        // Handle ENTER keys
                        // Grab the first autocomplete option
                        if (this.result.address_components === undefined) {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.google_autocomplete_enter);
                            this.getFirstAutocompleteOption(this.result, input, options);
                        } else {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.google_autocomplete_click);
                            this.applyAddress(this.result, input);
                        }
                    }
                }.bind(this));
                googleSdk.maps.event.addDomListener(input, 'keydown', function (e) {
                    if (e.keyCode === 13) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                });
            }.bind(this));
        },

        /* Retrieve first result using AutocompleteService */
        getFirstAutocompleteOption: function getFirstAutocompleteOption(result, input, options) {
            this.google.then(function(googleSdk) {
                var autocompleteService = new googleSdk.maps.places.AutocompleteService();
                var sessionToken = new googleSdk.maps.places.AutocompleteSessionToken();
                if (result.name.length > 0) {
                    autocompleteService.getPlacePredictions(
                        {
                            input: result.name,
                            offset: result.name.length,
                            types: options.types,
                            componentRestrictions: options.componentRestrictions,
                            sessionToken: sessionToken
                        },
                        function (list, status) {
                            if (list === null || list.length === 0) {
                                return;
                            } else {
                                var placesService = new googleSdk.maps.places.PlacesService(input);
                                placesService.getDetails(
                                    {
                                        reference: list[0].reference,
                                        fields : options.fields,
                                        sessionToken: sessionToken
                                    },
                                    function (result, status) {
                                        if (status === googleSdk.maps.GeocoderStatus.OK) {
                                            var country = this.UIUtils.getCountryFromGeoAddrComponents(result.address_components);

                                            if (country === 'United States' || country === 'Canada') {
                                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.google_autocomplete_select_first, {
                                                    value: result && result.formatted_address ? result.formatted_address : ''
                                                });
                                                this.applyAddress(result);
                                            }
                                        }
                                    }.bind(this)
                                );
                            }
                        }.bind(this));
                }
            }.bind(this));
        }
    });

    return {
        restrict: 'A',
        controller: GoogleAutocompleteDirectiveController,
        controllerAs: 'googleAutocompleteVm',
        scope: {
            hbAutocompleteCitiesOnly: '@',
            hbAutocompleteRegionsOnly: '@',
            hbAutocompleteUpdateNgmodel: '@',
            hbAutocompleteContactData: '@',
            hbAutocompleteAtmosphereData: '@',
            hbAutocompleteFullBasicData: '@',
            hbAutocompleteAllowedCountries: '=',
            hbAutocompleteCallback: '&',
            ngModel: '='
        }
    };
};