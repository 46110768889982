(function () {
    "use strict";

    // @ngInject
    function UploadFilesControllerCtor($scope, $modalInstance, model, AnalyticsService,
                                       PhotosUploadManager, PopupMessageService, $timeout, $log, $window, DeviceService, UIUtils) {

        this.constructor.$super.call(this, $scope);
        this.__objectType = 'UploadPhotoController';

        this.$timeout = $timeout;
        this.$log = $log;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;

        this.model = this.PhotosUploadManager.getUpdatedModelData(model);
        this.modalInstance = $modalInstance;

        this.fileReaderSupported = $window.FileReader != null;

        this.title = 'UPLOAD_FILE._TITLE_' + this.model.type.toUpperCase() + '_';
        this.disclaimer = 'UPLOAD_FILE._DISCLAIMER_' + this.model.type.toUpperCase() + '_';

        this.analyticsName = 'Upload' + (model.type ? model.type.replace(model.type[0], model.type[0].toUpperCase()) : 'File') + 'Dialog';

        this.isFileDragged = false;
        this.selectedFiles = null;
        this.files = null;
        // 15 MB limit
        this.fileSizeLimit = 15 * 1024 * 1024;
        
        this.isMobile = DeviceService.checkIfMobileSize();

        $scope.$watch('uploadFilesVm.selectedFiles', function (newValue, oldValue) {
            var files;
            if (newValue && !angular.isArray(newValue)) {
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                files = [newValue];
            } else {
                files = this.selectedFiles;
            }
            var removedLargeFiles = false;
            if (files != null && files.length > 0) {
                var fileSizeLimit = this.fileSizeLimit;
                var cleanedFiles = files.filter( function(file) {
                    return file.size <= fileSizeLimit;
                });
                if (cleanedFiles.length < files.length) {
                    removedLargeFiles = true;
                    files = cleanedFiles;
                }
            }
            this.files = files;
            if (removedLargeFiles) {
                this.PopupMessageService.showErrorAlert('UPLOAD_FILE._ERROR_FILE_TOO_LARGE_');
            }
        }.bind(this));
    }

    Controllers.UploadFilesController = Class(Controllers.BaseController, {

        constructor: UploadFilesControllerCtor,

        dismiss: function dismiss() {
            this.closing = true;
            this.modalInstance.dismiss('cancel');
        },

        close: function close(result) {
            this.closing = true;
            this.modalInstance.close(result);
        },

        upload: function upload(files) {
            var self = this;
            self.isUploading = true;

            if (this.model.dontUpload) {
                self.close(files);
            } else {
                if (files && files.length) {
                    var promise = this.PhotosUploadManager.uploadPhoto(files, this.model, self);
                    promise.then(
                        function success() {
                            var analyticsArgs = {
                                photo_type: self.model.type,
                                files_number: files.length
                            };
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_uploaded_photos_successful, analyticsArgs);
                            self.close(files);
                        },
                        function error(resp) {
                            self.isUploading = false;
                            self.$log.error("Error!", resp.data);
                        }
                    );
                }
            }
        },

        isImageTypeOfFile: function isImageTypeOfFile(file) {
            return (file.type.indexOf('image') > -1);
        },

        generateThumb: function generateThumb($files) {
            this.isFileDragged = false;
            var files;
            if ($files && !angular.isArray($files)) {
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                files = [$files];
            } else {
                files = $files;
            }

            var self = this;
            if (files != null && files.length > 0) {
                for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                    var file = files[fileIndex];
                    if (this.fileReaderSupported) {
                        //Execution
                        if (!file.dataUrl) {
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = function (e) {
                                var file = this;
                                self.$timeout(function () {
                                    file.dataUrl = e.target.result;
                                });
                            }.bind(file);
                        }
                    }
                }
            }
        },

        drag: function drag($isDragging, $class, $event) {
            if ($isDragging)
                this.isFileDragged = true;
            else
                this.isFileDragged = false;
            
            this.$scope.$applyAsync();
        }
    });
}());
