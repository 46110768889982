/**
 * The hb-chips-checklist component
 *
 * This component shows checklist in a chips view. example in signup
 *
 *
 * parameters:
 * input-title: string. text to be appear as the title of the input
 * list: array. the collection that holds all available options. array of objects contains {name, key}
 * selected-item-key: object. the key of selected item
 * on-item-click: function. optional. called when user clicks on item.
 * allow-multiple: I didn't implement this cause I wasn't needed, but in case you want to implement this.
 * you need to change the selectedItemKey model to an array and go over it.
 * look online for 'ng-repeat checkbox'
 *
 */


(function () {
    'use strict';

    class HBIconController extends Controllers.BaseControllerES6 {
        // @ngInject
            constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'HBIconController';
        }
    }

    Components.HBIcon = {
        bindings: {
            name: '@',
            onItemClick: '&',
            size: '@',
            customClass: '@'
        },
        controller: HBIconController,
        name : 'hbIcon',
        templateUrl: 'angular/app/modules/common/ui_components/hb-icon/hb-icon.html'
    };

}());
