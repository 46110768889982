// @ngInject
Filters.HighlightFilter  = function HighlightFilter($filter, $sce, UIUtils) {
    return function(text, phrase, matchType) {

        if (typeof text === 'string' && text.trim()) {

            text = UIUtils.sanitize(text);

            if (phrase && text.length > 0) {
                var splitted;

                switch (matchType) {
                    // case 'characters_order':
                    //     var res, phraseIndex, textIndex, isHighlighted;
                    //     res = "";
                    //     phraseIndex = 0;
                    //     isHighlighted = false;
                    //     for (textIndex = 0; textIndex < text.length && phraseIndex < phrase.length; textIndex++) {
                    //         if (text[textIndex].toLowerCase() === phrase[phraseIndex].toLowerCase()) {
                    //             res += isHighlighted ? text[textIndex] : ("<b>" + text[textIndex]);
                    //             isHighlighted = true;
                    //             phraseIndex++;
                    //         } else {
                    //             res += isHighlighted ? ("</b>" + text[textIndex]) : text[textIndex];
                    //             isHighlighted = false;
                    //         }
                    //     }
                    //     if (isHighlighted) {
                    //         res += "</b>";
                    //     }
                    //     if (textIndex < text.length) {
                    //         res += text.substring(textIndex);
                    //     }
                    //     console.log(res);
                    //     text = res;
                    //     break;

                    default:
                        splitted = phrase.split(/\s/).filter(function (e) { return e.trim().length > 1;});

                        splitted.forEach(function (phrase_frag) {

                            // escape chars from searched phrase (so regex won't break, back slash before every special char)
                            phrase_frag = phrase_frag.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                            // single char html tag prevents replacing already replaced text when there are multiple fragments
                            text = text.replace(new RegExp('('+phrase_frag+')', 'gi'), '<b>$1</b>');

                        });
                }
            }
        }

        return $sce.trustAsHtml(text);
    };
};
