(function () {
    "use strict";

    // @ngInject
    function PipelineOnboardingModalControllerCtor($scope, $injector, $modalInstance) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PipelineOnboardingModalController';
        this.$modalInstance = $modalInstance;
    }

    Controllers.PipelineOnboardingModalController = Class(Controllers.BaseController, {
        constructor: PipelineOnboardingModalControllerCtor,

        handleClickCustomize: function handleClickCustomize() {
            return this.$modalInstance.close({ didClickCustomize: true });
        },

        close: function close() {
            return this.$modalInstance.close();
        },
    });
}());
