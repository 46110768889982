(function () {
    'use strict';
    class MeetingTextField extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'MeetingTextField';
        }
    }

    Components.MeetingTextField = {
        bindings: {
            label: '@',
            name: '@',
            value: '=',
            required: '<?',
            autoFocus: '<?',
            placeholder: '@',
            analyticsEventSource: '@',
            analyticsEvent: '@',
            showError: '=',
            errorMessage: '@',
            disabled: '<?',

        },
        controller: MeetingTextField,
        name: 'MeetingTextField',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_text_field/meeting_text_field.html'
    };
}());