(function () {
    "use strict";

    Services.ConfettiService = Class(function(){

        var MAX_CONFETTI_ITEMS = 300;
        var DEFAULT_POSITION = 'bottom';

        // @ngInject
        function ConfettiServiceCtor($timeout, _) {
            this.$timeout = $timeout;
            this._ = _;
            this.possibleAnimationStartingPositions = {
                'bottom': 'animate--bottom-up',
                'right-top': 'animate--right-top',
                'left-top': 'animate--left-top'
            };

            this.timeoutPromise = null;
            this.confettiesArray = [];
        }

        return {
            setItemsCount : function setItemsCount(count) {
                this.confettiesArray = this._.range(1, count);
            },


            show: function show(options){
                // optional options: {position, count}
                // position : from where we fire it
                // count : number of paper pieces
                //
                // when using without options, default parameters will be used.

                this.$timeout.cancel(this.timeoutPromise);
                this.animationPosition = this.possibleAnimationStartingPositions[DEFAULT_POSITION];
                this.setItemsCount(MAX_CONFETTI_ITEMS);
                
                if (options) {
                    if ((options.position) && this.possibleAnimationStartingPositions.hasOwnProperty(options.position)) {
                        this.animationPosition = this.possibleAnimationStartingPositions[options.position];
                    }
                    
                    if (options.count && options.count > 0 && options.count < MAX_CONFETTI_ITEMS) {
                        this.setItemsCount(options.count)
                    }
                }
                this.$timeout(function(){
                    this.isRunning = true;
                }.bind(this), 0);

                // kill animation after 15 seconds
                this.timeoutPromise = this.$timeout(this.hide.bind(this), 15000);
            },

            hide: function hide(){
                this.isRunning = false;
            },

            constructor: ConfettiServiceCtor,

        };
    });
}());
