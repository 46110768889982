
// @ngInject

Models.WorkspaceFilesCollection = function WorkspaceFilesCollection(BaseCollection) {

    var WorkspaceFilesCollection = Class(BaseCollection, {

            constructor: function constructor(){
                WorkspaceFilesCollection.$super.call(this);
                this._collection.__className = 'WorkspaceFilesCollection';
                this._collection.__childsType = 'WorkspaceFileModel';
                return this._collection;
            }
        }
    );

    return WorkspaceFilesCollection;
};
