Directives.HomeCardDirective = function HomeCardDirective() {

    // @ngInject
    function HomeCardDirectiveControllerFunc($injector, $scope, HomeService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'HomeCardDirectiveController';
        this.HomeService = HomeService;

        this.isHomeForTrialer = this.HomeService.shouldShowHomeForTrialers();
    }

    var HomeCardDirectiveController = Class(Controllers.BaseController, {
        constructor: HomeCardDirectiveControllerFunc,

        onCardClick : function onCardClick($event) {
            if (this.isHomeForTrialer && this.emptyState && !this.showBigPaymentsCard) {
                this.goToView();
                $event.preventDefault();
                $event.stopPropagation();
            }
        },

        onHeaderClick: function onHeaderClick() {
            this.goToView && this.goToView();
            this.analytics && this.analytics();
        }
    });

    return {
        scope: {
          type: '@',
          goToView: '&',
          analytics: '&',
          link: '@',
          emptyState: '<',
          loading: '<',
          showBigPaymentsCard: '<'
        },
        templateUrl: 'angular/app/modules/core/features/home/home_card/home_card_directive.html',
        controller: HomeCardDirectiveController,
        controllerAs: 'homeCardVm',
        transclude: {
            'shimmer': '?homeCardShimmer'
        },
        bindToController: true
    };
};
