// @ngInject
Filters.OrderEmptyFilter = function OrderEmptyFilter($filter) {
    return function (array, key, type) {
        var present, empty, result, isKeyExists;

        if (!angular.isArray(array)) {
            return;
        }

        if (!angular.isDefined(key) || key === null) {
            return;
        }

        var arrayKeys = key.split('.');

        isKeyExists = function isKeyExists(obj, keysArray, index) {

            if (index < keysArray.length - 1 && angular.isDefined(obj[keysArray[index]]) && obj[keysArray[index]] !== null) {
                return isKeyExists(obj[keysArray[index]], keysArray, index + 1);

            } else if (index === keysArray.length - 1) {
                return angular.isDefined(obj[keysArray[index]]) && obj[keysArray[index]] !== null;
            }

            return false;
        };

        present = array.filter(function (item) {
            return isKeyExists(item, arrayKeys, 0);
        });

        empty = array.filter(function (item) {
            return !isKeyExists(item, arrayKeys, 0);
        });

        switch (type) {
            case 'toBottom':
                result = present.concat(empty);
                break;
            case 'toTop':
                result = empty.concat(present);
                break;

            default:
                result = array;
                break;
        }
        return result;
    };
};
