(function () {
    'use strict';

    Components.ClientBotTooltip = {
        bindings: {
            isVisible: '=',
            title: '@',
            text: '@',
            primaryCtaText: '@',
            primaryCtaCallback: '&',
            secondaryCtaText: '@',
            secondaryCtaCallback: '&',
            isPrimaryCtaActive: '=',
            showIcon: '<',
            clientbotTooltipClass: '@',
            arrowPosition: '@'
        },

        // @ngInject
        controller: function ClientBotTooltipController() {
            this.$onInit = function () {
                this.arrowPosition = this.arrowPosition || 'bottom';
            }
        },
        name: 'hbClientBotTooltip',
        templateUrl: 'angular/app/modules/common/ui_components/clientbot_tooltip/clientbot_tooltip.html',
    };

}());