Directives.SlickLightboxItemDirective =  function SlickLightboxItemDirective() {
    return {
        restrict: 'A',
        require: '^hbSlickLightboxContainer',
        link: function slickLightboxLink($scope, $element, attr, containerCtrl) {
            containerCtrl.addItem($element);

            $scope.$on('$destroy', function() {
                containerCtrl.removeItem($element);
            });
        }
    };
};