(function () {
    "use strict";

    // @ngInject
    function NewAddCompanyTeamMemberControllerCtor($scope, $modalInstance, $injector, $q, $translate, ContactsManager, UsersManager, UserService, _, CompanyService,
                                                   ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, Hash, PopupMessageService,
                                                   CompaniesManager, company, ValidationsManager, FeaturesService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager);
        this.__objectType = 'AddTeamMemberController';
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.$q = $q;
        this.hash = Hash();

        this.allowOnlyNewUsers = true;
        this.companyToSet = company;
        if (this.companyToSet) {
            this.user.company = this.companyToSet;
        }

        this.isVendor = true;
        this.isClient = false;
        this.isVendorEditDisabled = true;
        this.isCompanyEditDisabled = true;
        this.setUserPermissions = true;
        this.user.system_user_type = 'vendor';

        this._setupClientVendorSeparationVars(FeaturesService, UsersManager);

        this.companyPermissionTypes = CompanyService.getCompanyPermissionTypes();

        this.setActiveCompanyPermissionType = function setActiveCompanyPermissionType(type) {
            this.selectedCompanyPermission = type;
            this.user.company_permission = type.name;
        };
        this.setActiveCompanyPermissionType(CompanyService.getCompanyPermissionForUser(this.companyToSet, this.user));


        this.titleText = $translate.instant("COMPANY_SETTING.TEAM._ADD_MEMBER_TITLE_");
        this.subTitleText = $translate.instant("COMPANY_SETTING.TEAM._ADD_MEMBER_SUBTITLE_");
        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._INVITE_");
    }


    Controllers.NewAddCompanyTeamMemberController = Class(Controllers.NewAddUserController, {


        constructor: NewAddCompanyTeamMemberControllerCtor,
        
        saveUser: function saveUser() {
            var defer = this.$q.defer();
            var user = this.user;
            this.CompaniesManager.addCompanyTeamMember(this.companyToSet, user).then(
                function saveMemberSuccess(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.team_member_account_created);
                    defer.resolve(resp.data);
                }.bind(this),
                function saveMemberError(resp) {
                    if (resp && resp.data && resp.data.error_message && resp.data.error_message.includes("can invite member")){
                        this.attemptToAddUserExistingInTheSystem(user.email);
                    }
                    else if (resp && resp.data && resp.data.error_message && resp.data.error_message.includes("already exists")){
                        this.dismiss();
                        this.handleFailedInvite();
                        defer.reject(resp);
                    }
                }.bind(this)
            );
            return defer.promise;
        },

        populateBaseDataFromDbUser: function populateBaseDataFromDbUser(dbUser){
            this.user.full_name = dbUser.full_name;
            this.user.phone_number = dbUser.phone_number;
            this.user.address = dbUser.address;
        },

        handleUserFromDb: function handleUserFromDb(dbUser) {
            if (dbUser._id) {
                if (dbUser.company.account_id === this.companyToSet.account_id) {
                    this.disableEditUser = true;
                    this.ctaText = this.$translate.instant('FREQUENT_BUTTONS._ADD_');
                    this.populateBaseDataFromDbUser(dbUser);
                } else {
                    this.populateBaseDataFromDbUser(dbUser);
                }
            } else {
                this.user._id = null;
                this.populateBaseDataFromDbUser(dbUser);
            }
        },

        sendInviteToMember: function sendInviteToMember(userEmail, data){
            return this.CompaniesManager.sendUserCompanyInvite(this.companyToSet._id, userEmail, data);
        },

        attemptToAddUserExistingInTheSystem: function attemptToAddUserExistingInTheSystem(userEmail) {
            var isTeamMember = false;
            if (this.companyToSet.team_members) {
                this.companyToSet.team_members.forEach(function (member) {
                    if (member.email === userEmail) {
                        isTeamMember = true;
                    }
                });
            }
            if(isTeamMember){
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.TEAM._CANNOT_ADD_ADDED_USER_', function () {
                });
            }else{
                this.dismiss();
                this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.TEAM._INVITE_EXISTING_USER_')
                    .then(function yes() {
                        var data = this.user.dataOnly();
                        this.sendInviteToMember(userEmail, data)
                            .catch(this.handleFailedInvite);
                    }.bind(this));
            }
        },

        handleFailedInvite: function handleFailedInvite(){
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.TEAM._CANNOT_ADD_EXISTING_USER_', null, "GOT IT");
        }
    });
}());

