(function () {
    "use strict";

    // @ngInject
    function TemplateExamplesModalControlleCtor($scope, $injector, $modalInstance, $state, EventService, UIUtils,
                                              AppStates, DeviceService, $window, $q, $sce, $, $timeout, modalContentType, modalContentData ,$translate,
                                              onConfirmCallback, onCancelCallback, AnalyticsService, ContactFormService, TemplatesViewService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplateExamplesModalController';

        this.modalInstance = $modalInstance;
        this.$state = $state;
        this.EventService = EventService;
        this.AppStates = AppStates;
        this.$window = $window;
        this.$q = $q;
        this.$sce = $sce;
        this.$ = $;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.onConfirmCallback = onConfirmCallback;
        this.onCancelCallback = onCancelCallback;
        this.AnalyticsService = AnalyticsService;
        this.ContactFormService = ContactFormService;
        this.TemplatesViewService = TemplatesViewService;
        this.UIUtils = UIUtils;
        this.modalContentData = modalContentData || {};

        this.isMobile = DeviceService.nxSmallBreakpoint();

        this.currStepIndex = 0;
        this.imageStepWidth = 556;
        if (this.isMobile) {
            this.imageStepWidth = 100;
        }

        this.modalContent = {
            contact_form_examples: {
                templateName: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._CONTACT_FORM_" ,
                title: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._TITLE_",
                subTitle: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._SUBTITLE_1_",
                analyticsGotoStage: 'cf examples go to stage',
                analyticsDismiss: 'new cf examples modal close',
                analyticsPreview: 'new cf examples modal live preview',
                analyticsFormTypeProperty: 'contact form',
                analyticsUseTemplate: 'new cf examples modal use this example',
                images: [
                    {
                        cfId: '5d655b490fae32023a3e7657',
                        exampleKey: 'business_consulting',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-monkey-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/smart_1/cf_id/5d655b490fae32023a3e7657"
                    }, {
                        cfId: '5d655682c7f1540139af92ff',
                        exampleKey: 'photographer',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1574690821/contactform/examples/example-photography-img_2x_2.png",
                        redirectUrl: "https://www.honeybook.com/widget/gp_photography_147695/cf_id/5d655682c7f1540139af92ff"
                    }, {
                        cfId: '5d6548b33f66ca0015b9322e',
                        exampleKey: 'branding',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-brnading-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/fishnet_branding_147692/cf_id/5d6548b33f66ca0015b9322e"
                    }, {
                        cfId: '5d652ddc7f2ef80017edca4b',
                        exampleKey: 'florist',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-florist-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/bloom_boutique_147690/cf_id/5d652ddc7f2ef80017edca4b"
                    }, {
                        cfId: '5d6542695b6bd30015c6e211',
                        exampleKey: 'interior_design',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-kate-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/kate_robins_interior_design_147691/cf_id/5d6542695b6bd30015c6e211"
                    }
                ]
            },
            gift_card_examples: {
                templateName: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._GIFT_CARD_" ,
                title: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._TITLE_",
                subTitle: "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._SUBTITLE_GIFT_CARD_",
                analyticsGotoStage: 'gift card examples go to stage',
                analyticsDismiss: 'new gift card examples modal close',
                analyticsPreview: 'new gift card examples modal live preview',
                analyticsUseTemplate: 'new gift card examples modal use this example',
                analyticsFormTypeProperty: 'gift card',
                images: [
                    // {
                    //     cfId: '5e85b8f3536f77001f29c123',
                    //     exampleKey: 'business_consulting_gift_card',
                    //     imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-monkey-img_2x.png",
                    //     redirectUrl: "https://www.honeybook.com/widget/monkey_business_147697/cf_id/5e85b8f3536f77001f29c123"
                    // },
                    {
                        cfId: '5e945e36bef01902beae2ff9',
                        exampleKey: 'gift_card_support',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1586854759/contactform/examples/giftCards/example-support-img_2x.jpg",
                        redirectUrl: "https://www.honeybook.com/widget/contact_forms_exa_155491/cf_id/5e945e36bef01902beae2ff9"
                    },
                    {
                        cfId: '5e92d13eef5ef669e6681c3c',
                        exampleKey: 'photographer_gift_card',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1586713779/contactform/examples/giftCards/example-photography-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/gp_photography_147695/cf_id/5e92d13eef5ef669e6681c3c"
                    },
                    // {
                    //     cfId: '5e85b86443aee5001984704e',
                    //     exampleKey: 'branding_gift_card',
                    //     imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1572275083/contactform/examples/example-brnading-img_2x.png",
                    //     redirectUrl: "https://www.honeybook.com/widget/fishnet_branding_147692/cf_id/5e85b86443aee5001984704e"
                    // },
                    {
                        cfId: '5e92cdbfee0d02691b48d416',
                        exampleKey: 'florist_gift_card',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1586713764/contactform/examples/giftCards/example-florist-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/bloom_boutique_147690/cf_id/5e92cdbfee0d02691b48d416"
                    }, {
                        cfId: '5e92f3376853df01e861be3c',
                        exampleKey: 'interior_design_gift_card',
                        imageTag: "https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1586760722/contactform/examples/giftCards/example-interior-img_2x.png",
                        redirectUrl: "https://www.honeybook.com/widget/kate_robins_interior_design_147691/cf_id/5e92f3376853df01e861be3c"
                    }
                ]
            }

        };

        var _modalContentType = modalContentType;
        this.currContent = this.modalContent[_modalContentType];
        this.currTitle = this.$translate.instant(this.modalContent[_modalContentType].title);
        this.currSubTitle = this.$translate.instant(this.modalContent[_modalContentType].subTitle);

        if (this.modalContentData['create_new']) {
            var templateName = this.$translate.instant(this.modalContent[_modalContentType].templateName);
            var TitleCreateNew = "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._TITLE_CREATE_NEW_";
            var subTitleCreateNew = "CONTACT_FORM.TEMPLATE_EXAMPLES_MODAL._SUBTITLE_CREATE_NEW_";
            this.currTitle = this.$translate.instant(TitleCreateNew);
            this.currSubTitle = this.$translate.instant(subTitleCreateNew, {templateType: templateName});
        }
        this.currImages = this.modalContent[_modalContentType].images;
        this.isLastStep = this.currStepIndex === this.currImages.length - 1;

    }

    Controllers.TemplateExamplesModalController = Class(Controllers.BaseController, {

        constructor: TemplateExamplesModalControlleCtor,

        getLeft: function getLeft(currStepIndex) {

            var mobilePattern = '[num]vw';
            var desktopPattern = '[num]px';
            var currLeft = 0;

            if (this.isMobile) {
                currLeft = mobilePattern.replace('[num]', -1 * currStepIndex * this.imageStepWidth);
            } else {
                currLeft = desktopPattern.replace('[num]', -1 * currStepIndex * this.imageStepWidth);
            }
            return currLeft;
        },

        dismiss: function dismiss() {
          this.AnalyticsService.trackClick(this, this.currContent.analyticsDismiss, {form_type: this.currContent.analyticsFormTypeProperty});
         this.close();
        },

        close: function close() {
            this.modalInstance.dismiss();
        },

        gotoStep: function gotoStep(stepIndex) {
            this.AnalyticsService.trackClick(this, this.currContent.analyticsGotoStage, {stage: stepIndex + 1, form_type: this.currContent.analyticsFormTypeProperty});
            this.currStepIndex = stepIndex;
            this.isLastStep = this.currStepIndex === this.currImages.length - 1;
        },

        gotoPreviousStep: function gotoPreviousStep() {
            this.gotoStep(this.UIUtils.modulo((this.currStepIndex - 1) , this.currImages.length));
        },

        gotoNextStep: function gotoNextStep() {
            this.gotoStep( (this.currStepIndex + 1) % this.currImages.length );
        },

        gotoUrl: function gotoUrl() {
            this.AnalyticsService.trackClick(this, this.currContent.analyticsPreview, { type: this.currImages[this.currStepIndex].cfId, form_type: this.currContent.analyticsFormTypeProperty });
            this.$window.open(this.currImages[this.currStepIndex].redirectUrl, '_blank');
        },

        useThisTemplate: function useThisTemplate() {
            this.TemplatesViewService.showLoader();
            this.AnalyticsService.trackClick(this, this.currContent.analyticsUseTemplate, { type: this.currImages[this.currStepIndex].cfId, form_type: this.currContent.analyticsFormTypeProperty });
            this.ContactFormService.copyContactFormExampleToCompany(this.currImages[this.currStepIndex].exampleKey).then(function () {
                this.TemplatesViewService.goToLastAddedTemplate();
                this.close();
            }.bind(this));
        }

    });
}());
