(function(){
    'use strict';
    Controllers.ChangeExpiredPasswordModalController = class ChangeExpiredPasswordModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $stateParams, $modalInstance, UsersManager, RecaptchaService, email, loginParams) {
            super($scope, $injector);
            this.__objectType = 'ChangeExpiredPasswordModalController';
            this.RecaptchaService = RecaptchaService;
            this.$modalInstance = $modalInstance;
            this.user = UsersManager.newUser();
            this.$stateParams = $stateParams;
            this.$translate = $translate;
            this.UsersManager = UsersManager;
            this.loginParams = loginParams;
            this.RecaptchaService.load();
            this.updating = false;
            this.email = email;
            this.showError = false;
            this.resetForm();
        }

        resetForm() {
            this.currentPassword = '';
            this.newPassword = '';
            this.passwordConfirm = '';
        }

        close() {
            this.$modalInstance.close();
        }

        handleFail(res){
            if (res && res.data && res.data.error_message) {
                this.errorMessage = res.data.error_message;
            } else {
                this.errorMessage = this.$translate.instant("ACCOUNT.RESULT._CHANGE_UNSUCCESSFUL_");
            }
            this.showError = true;
        }

        changePassword(){
            this.updating = true;

            const data = {
                email: this.email,
                current_password: this.currentPassword,
                new_password: this.newPassword
            };

            this.RecaptchaService.getRecaptchaToken("expiredPassword")
                .then((recaptchaToken) => {
                    data["g-recaptcha-response-v3"] = recaptchaToken;
                    this.UsersManager.changePasswordByEmail(data)
                        .then(() => {
                            this.user.email = this.email;
                            this.user.password = this.newPassword;
                            this.UsersManager.login(this.user, 'link', {linkId: this.$stateParams.link_id}, true).then(
                                ((res) => {
                                    this.loginParams.loginSuccess(res, true);
                                    this.close();
                                }),
                                ((res) => this.handleFail(res))
                            );
                        })
                        .catch((res) => this.handleFail(res))
                        .finally(()=>{
                            this.updating = false;
                        });
                });
        }

    };
})();