(function () {
  
    "use strict";

    Controllers.CapitalController = class CapitalController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            

            this.__objectType = 'CapitalController';
        }
    };
}());
