(function () {
    "use strict";

    Controllers.FinanceController = class FinanceController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, $sce, $stateParams, UsersManager, AppStates, FinanceAppService, DeviceService, StatsigService) {
            super($scope, $injector);

            this.__objectType = 'FinanceController';
            this.user = UsersManager.getCurrUser();
            this.isInAppBrowser = DeviceService.isInAppBrowser();
            this.FinanceAppService = FinanceAppService;
            this.StatsigService = StatsigService;
            this.$sce = $sce;
            this.$stateParams = $stateParams;
            this.AppStates = AppStates;

            this._loadFinanceApp();
        }

        shouldLoadFinanceAppIframe() {
            return this._shouldLoadFinanceAppIframe;
        }

        getFinanceAppIframeUrl(fallback = '/') {
            const pageParam = this.$stateParams.page;
            const sourceParam = this.$stateParams.source;

            let iframePath = pageParam ? `/${encodeURIComponent(pageParam)}` : fallback;
            let iframeUrl = `${this.FinanceAppService.getIframeUrl()}?callbackUrl=${iframePath}`;

            if (sourceParam) {
                iframeUrl += `&source=${encodeURIComponent(sourceParam)}`;
            }

            return this.$sce.trustAsResourceUrl(iframeUrl);
        }

        getFinanceAppIframeId() {
            return this.FinanceAppService.getIframeId();
        }

        _loadFinanceApp() {
            if (!this.isInAppBrowser) {
                Promise.all([
                    this.FinanceAppService.isFinanceAppEnabled(this.user),
                    this.StatsigService.isGateEnabled('finance_app_overview')
                ]).then(([isEligible, isOverviewEnabled]) => {
                    if (isEligible || isOverviewEnabled) {
                        this._shouldLoadFinanceAppIframe = true;
                        this.FinanceAppService.loadFinanceApp(this.user);
                        this.FinanceAppService.listenToSignOut().then(() => {
                            this.goToState(this.AppStates.root_core_navigation_home);
                        });
                    }
                });
            }
        }
    };
}());
