(function () {
  "use strict";

  class FeedOneComposer extends Controllers.BaseControllerES6 {
    // @ngInject
    constructor($scope, $injector) {
      super($scope, $injector);

      const unsubscribeWorkspace = $scope.$watchCollection(
          "$ctrl.workspace",
          (newVal) => {
            this._workspace = Object.assign({}, newVal.dataOnly());
          }
      );

      const unsubscribeWorkspaceMembers = $scope.$watchCollection(
          "$ctrl.workspace.members",
          (newVal) => {
            this._workspace.members = Object.assign({}, newVal.dataOnly());
          }
      );

      const unsubscribeLastMessage = $scope.$watchCollection(
        "$ctrl.lastMessageToReply",
        (newVal) => {
          if (newVal) {
            this._lastMessageToReply = Object.assign({}, newVal.dataOnly());
          }
        }
      );

      $scope.$on("$destroy", () => {
        unsubscribeWorkspace();
        unsubscribeWorkspaceMembers();
        unsubscribeLastMessage();
      });

      this.onSendMessage = this.onSendMessage();
      this.onUndoMessage =  this.onUndoMessage();
      this.onSend = this.onSend.bind(this);
      this.onUndo = this.onUndo.bind(this);

      this.workspaceVm = $scope.$parent.workspaceVm;
      this.handleAddParticipantCallBack = this.workspaceVm ? (user) => this.workspaceVm.handleAddParticipantCallBack(user) : () => {};
      this.handleAddNewClientCallback = this.workspaceVm ? (user) => this.workspaceVm.handleAddNewClientCallback(user) : () => {};
    }

    onUndo(task_id){
      return this.onUndoMessage(task_id);
    }

    onSend({
      messageBody,
      attachedFiles,
      pvl,
      subject,
      isReplyMode,
      recipientIds,
      scheduledSendDate,
      delayInSeconds,
      onCptCreated
    }) {
      const lastMessageToReplyId =
        isReplyMode && this.lastMessageToReply
          ? this.lastMessageToReply._id
          : null;
      return this.onSendMessage(
        this.workspace,
        messageBody,
        attachedFiles,
        pvl,
        subject,
        lastMessageToReplyId,
        isReplyMode,
        scheduledSendDate,
        delayInSeconds,
        onCptCreated,
        recipientIds
      );
    }
  }

  Components.FeedOneComposerComponent = {
    bindings: {
      lastMessageToReply: "<",
      onSendMessage: "&",
      onUndoMessage: "&",
      workspace: "<"
    },
    controller: FeedOneComposer,
    name: "hbFeedOneComposer",
    templateUrl:
      "angular/app/modules/core/features/feed/feed_one_composer/feed_one_composer_template.html",
  };
})();
