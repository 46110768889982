// @ngInject

Models.TimelineContainerModel = function TimelineContainerModel(BaseModel, $translate) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'TimelineContainerModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                timeline_sections: 'TimelineSectionsCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {
            var self = this;
            if (this.timeline_sections && this.timeline_sections.length > 0) {
                this.timeline_sections.forEach(function (currSection, index) {
                    currSection.__timeline_container = self;
                });
            }
        },

//        getAnalyticsArgs: function getAnalyticsArgs() {
//            return this.__parent.getAnalyticsArgs();
//        },

        addTimelineSection: function addTimelineSection(timelineSectionToAddId) {
            return this.__parent.addTimelineSection(this, timelineSectionToAddId);
        },

        updatetTimelineItem: function updatetTimelineItem(timelineItemModel, timelineSectionModel) {
            return this.__parent.updatetTimelineItem(this, timelineItemModel, timelineSectionModel);
        },

        updateTimelineItemsOrder: function updateTimelineItemsOrder(timelineSectionModel) {
            return this.__parent.updateTimelineItemsOrder(this, timelineSectionModel);
        },

//        findTimelineItemModel: function findtimelineItem(timelineItemId) {
//            if (this.package_services && this.package_services.length > 0) {
//                for (var i = 0, length = this.package_services.length; i < length; i++) {
//                    if (this.package_services[i]._id === timelineItemId) {
//                        return this.package_services[i];
//                    }
//                }
//            }
//
//            return null;
//        },

        deleteTimelineItem: function deleteTimelineItem(timelineItemModel, timelineSectionModel) {
            return this.__parent.deleteTimelineItem(this, timelineItemModel, timelineSectionModel);
        },

        updateTimelineSection: function updateTimelineSection(timelineSectionModel) {
            return this.__parent.updateTimelineSection(this, timelineSectionModel);
        },

        deleteTimelineSection: function deleteTimelineSection(timelineSectionModel) {
            return this.__parent.deleteTimelineSection(this, timelineSectionModel);
        },

        addTimelineItemToTimelineSection: function addTimelineItemToTimelineSection(timelineSectionModel) {
            return this.__parent.addTimelineItemToTimelineSection(this, timelineSectionModel, this._createEmptyTimelineItem(true));
        },

        updateTimelineContainer: function updateTimelineContainer(data) {
            return this.__parent.updateTimelineContainer(data);
        }
    });
};
