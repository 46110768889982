(function () {
    "use strict";

    // @ngInject
    function CommunityRedirectControllerCtor($scope, $injector, $stateParams, $timeout, $state, $location, AppStates, $uibModalStack, $localStorage,
                                 InitialAppLoadParamsService, UsersManager, Routes) {

        this.constructor.$super.call(this, $scope, $injector);

        this.__objectType = 'CommunityRedirectController';
        this.Routes = Routes;
        UsersManager.redirectToCommunity();
    }

    Controllers.CommunityRedirectController = Class(Controllers.BaseController, {
        constructor: CommunityRedirectControllerCtor,
    });
}());