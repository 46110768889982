
(function () {
    'use strict';

    class ProjectDetailsTimezoneComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, TimezoneService, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsTimezone';

            this.TimezoneService = TimezoneService;
            this.AnalyticsService = AnalyticsService;

            this.timezones = TimezoneService.getTimezones();
        }

        getTimezone() {
            return this.TimezoneService.translateFromServer(this.timezone);
        }

        onChange(timezone) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_timezone, {source: this.analyticsEventsSource});
            return this.onTimezoneChange({timezone: this.TimezoneService.translateToServer(timezone)});
        }
    }

    Components.ProjectDetailsTimezone = {
        bindings: {
            timezone: '<',
            onTimezoneChange: '&',
            editMode: '<?',
            analyticsEventSource: '@'
        },
        controller: ProjectDetailsTimezoneComponent,
        templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_timezone_component_template.html'
    };

}());