/**
 * Created by dvircohen on 17/11/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function EmailSignaturesTemplateEmptyStateControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, UsersManager) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EmailSignaturesTemplateEmptyStateControllerController';
        AnalyticsService.trackPageView(this, 'EmailSignaturesTemplateEmptyStateControllerView');

        this.TemplatesViewService = TemplatesViewService;
        this.currUser = UsersManager.getCurrUser();
    }

    Controllers.EmailSignaturesTemplateEmptyStateController = Class(Controllers.BaseController, {
        constructor: EmailSignaturesTemplateEmptyStateControllerCtor,

        goToEmailSignature: function goToEmailSignature() {
            var emailSignature = this.currUser.email_signatures[0];

            if (emailSignature) {
                this.TemplatesViewService.updateCurrentEmailSignature(emailSignature);
            } else {
                this.TemplatesViewService.addNewEmailSignature();
            }
        }
    });
}());