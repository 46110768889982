
// @ngInject

Models.SignaturesCollection = function SignaturesCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'SignaturesCollection';
                this._collection.__childsType = 'SignatureModel';
                //this._collection.shouldMergeOnLoad = false;
                return this._collection;
            }
        }
    );

};
