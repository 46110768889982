Directives.TopNavDropdownMobileDirective = function TopNavDropdownMobileDirective() {

    // @ngInject
    function TopNavDropdownMobileDirectiveControllerFunc($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TopNavDropdownMobileDirectiveController';
    }

    var TopNavDropdownMobileDirectiveController = Class(Controllers.BaseController, {
        constructor: TopNavDropdownMobileDirectiveControllerFunc,
    });

    return {
        scope: {
            show: '=show',
            animation: '@?'
        },
        transclude: {
            'content': '?dropdownContentMobile',
            'header': '?dropdownHeaderMobile'
        },
        templateUrl: 'angular/app/modules/core/features/top_nav/dropdown_mobile/top_nav_dropdown_mobile_template.html',
        controller: TopNavDropdownMobileDirectiveController,
        controllerAs: 'topNavDropdownMobileVm',
        bindToController: true
    };
};

