(function () {
    "use strict";

    Controllers.EmailTemplatesModalController = class EmailTemplatesModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, CompaniesManager, ModalService, UsersManager, WorkspacesManager, AnalyticsService) {

            super($scope, $injector);
            this.__objectType = 'EmailTemplatesModalController';

            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.ModalService = ModalService;
            this.WorkspacesManager = WorkspacesManager;
            this.AnalyticsService = AnalyticsService;

            AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.email_template_chooser);

            this.currUser = this.UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
        }

        createFile(templateId) {
            this.close({templateId});
        }

        close(resolvedObject) {
            this.$modalInstance.close(resolvedObject);
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }
    }


}());
