// @ngInject

Models.ServiceModel = function ServiceModel(BaseModel, PopupMessageService, _, ImageService, $q) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'ServiceModel';

            this.cachable = false;

            this.PopupMessageService = PopupMessageService;

            this.mixinIgnoreKeys.PopupMessageService = true;
        },

        mapChilds: function mapChilds() {
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
            copy.__package = undefined;
            copy.__vendor_proposal = undefined;
        },

        isPackageService: function isPackageService() {
            return !angular.isUndefined(this.__package);
        },

        isALaCarteService: function isALaCarteService() {
            return !angular.isUndefined(this.__a_la_carte_proposal);
        },

        isCustomService: function isCustomService() {
            return !this.isPackageService && !this.isALaCarteService();
        },

        getAmount: function getAmount() {
            return this.amount;
        },

        updateService: function updateService() {
            return this.__parent.updateService(this);
        },

        updateServiceQuantity: function updateServiceQuantity() {
            return this.__parent.updateServiceQuantity(this);
        },

        updateServiceSelection: function updateServiceSelection(isSelected) {
            return this.__parent.updateProposalSelection(this, isSelected);
        },

        updateServiceImage: function updateServiceImage(image) {
            return this.__parent.updateServiceImage(this, image);
        },

        deleteServiceImage: function deleteServiceImage() {
            return this.__parent.deleteServiceImage(this);
        },

        // This function is meant to cover the fact that the JS toFixed function isn't rounding up
        // numbers that end with 5. i.e. 6.235 are 6.23 instead of 6.24
        toFixed: function toFixed(num, precision) {
            return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        },

        calculatePrice: function calculatePrice(clearIfNeeded) {
            if (_.isNumber(this.price_per_unit)) {
                this.price = !_.isNumber(this.quantity) ? this.price_per_unit : this.toFixed((this.price_per_unit * this.quantity), 2);
            } else if (clearIfNeeded) {
                this.price = 0;
            }
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            var parentAnalyticsArgs = this.__parent.getAnalyticsArgs();
            var analyticsArgs = {
                service_id: this.id()
            };

            for (var property in parentAnalyticsArgs) {
                if (parentAnalyticsArgs.hasOwnProperty(property)) {
                    analyticsArgs[property] = parentAnalyticsArgs[property];
                }
            }

            return analyticsArgs;
        },

        deleteService: function deleteService() {
            return this.__parent.deleteService(this);
        },

        hasImage: function hasImage() {
            return !!this.package_service_image;
        },

        getFormattedImage: function getFormattedImage() {
            if(this.hasImage()) {
                return ImageService.getHBImageCloudinaryUrl(this.package_service_image);
            }
            return "";

        },

        getContainingProposal: function getContainingProposal() {
            return this.__vendor_proposal;
        },

        shouldShowTax: function () {
            return this.__parent.shouldShowTax(this);
        },

        shouldShowSVC: function () {
            return this.__parent.shouldShowSVC(this);
        },

        canClientUpdateQuantity: function canClientUpdateQuantity() {
            return (!this.isPackageService() && this.__parent.canClientUpdateQuantity());
        }
    });

};
