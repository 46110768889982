// @ngInject

Models.NetworkUsersCollection = function NetworkUsersCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'NetworkUsersCollection';
                this._collection.__childsType = 'NetworkUserModel';
                return this._collection;
            }
        }
    );

};
