(function () {
    'use strict';

    class HelperBannerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'HelperBannerComponentController';
        }
    }

    Components.HelperBanner = {
        controller: HelperBannerController,
        bindings: {
            text: '@',
            showButton: '<',
            buttonText: '@',
            callToAction: '&',
            imageSrc: '@',
            analyticsEvent: '@',
            analyticsEventSource: '@',
            styleClass: '@'
        },
        name: 'hbHelperBanner',
        templateUrl: 'angular/app/modules/common/ui_components/helper_banner/helper_banner_template.html',
    };
}());