Directives.SlickLightboxContainerDirective = function SlickLightboxContainerDirective() {
    //@ngInject
    function SlickLightboxContainerDirectiveControllerFunc($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SlickLightboxContainerDirectiveController';
        var self = this;

        //refreshes list of items to view
        this.updateItems = function () {
            //first erase current lightbox
            self.containerElement.unslickLightbox();
            //then create a new one to replace it
            self.containerElement.slickLightbox({
                src: 'data-lightbox-src',
                itemSelector: 'img',
                slick: {
                    onAfterChange: self.onImageChanged.bind(self)
                }
            });
        };

        this.onImageChanged = function onImageChanged(slick, index){
            if (self.afterImageChanged){
                self.afterImageChanged();
            }
        };


        this.addItem = function (item) {
            self.updateItems();
        };

        this.removeItem = function (item) {
            self.updateItems();
        };

        $scope.$on('$destroy', function () {
            self.containerElement.unslickLightbox();
        });
    }

    var SlickLightboxContainerDirectiveController = Class(Controllers.BaseController, {
        constructor: SlickLightboxContainerDirectiveControllerFunc
    });

    return {
        restrict: 'A',
        controller: SlickLightboxContainerDirectiveController,
        controllerAs: 'slickLightboxContainerVM',
        link: function slickLightboxContainerLink($scope, $element, $attr) {
            $scope.slickLightboxContainerVM.containerElement = $element;
            $scope.slickLightboxContainerVM.afterImageChanged = $scope.afterImageChanged();
        },
        scope: {
            afterImageChanged:'&onImageChanged'
        }
    };
};