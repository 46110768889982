(function () {
    "use strict";

    // @ngInject
    function NewAddPreferredContactControllerCtor($scope, $modalInstance, $injector, $q, $translate, ContactsManager, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, config, ValidationsManager, FeaturesService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager, config);
        this.__objectType = 'NewAddPreferredContactController';

        this.ContactsManager = ContactsManager;
        this.$q = $q;
        this.config = config;

        this.titleText = $translate.instant("DASHBOARD.CONTACTS.ADD_PREFERRED_VENDOR_CONTACT._HEADER_TITLE_");
         this.subTitleText = '';
        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._ADD_");

        this.isVendor = true;
        this.isClient = false;
        this.isVendorEditDisabled = true;
        // this.showOptionalFields = false;
        this.user.system_user_type = 'vendor';

        this._setupClientVendorSeparationVars(FeaturesService, UsersManager);

        this.isSuggestFromVendorContacts = true;
        this.isSuggestFromGmailContacts = true;

    }

    Controllers.NewAddPreferredContactController = Class(Controllers.NewAddUserController, {

        constructor: NewAddPreferredContactControllerCtor,

        saveUser: function saveUser() {
            var defer = this.$q.defer();
            var user = this.user;

            var contact = this.ContactsManager.convertUserToContact(user);

            this.ContactsManager.savePreferredVendorContact(contact, true)
                .then(function success(resp) {
                    this.AnalyticsService.track(this, "added contact as preferred vendor",
                        {
                            new_member_email: user.email,
                            source: this.config.source
                        });
                    defer.resolve(contact);
                }.bind(this))
                .catch(function error(resp) {
                    defer.reject(resp);
                }.bind(this));
            return defer.promise;
        },

        handleUserFromDb: function handleUserFromDb(dbUser){
            if (dbUser._id){
                if (!dbUser.isVendor()) {
                    this.addUserForm.customEmailErrorMessage = this.$translate.instant("DASHBOARD.CONTACTS.ADD_PREFERRED_VENDOR_CONTACT._CANNOT_ADD_CLIENT_AS_VENDOR_");
                    this.addUserForm.email.$setValidity("customError", false);
                    return;
                }
                this.user = dbUser;
            } else {
                this.user = angular.extend(this.getStrippedBaseDataFromDBUser(dbUser), this.user);
            }
        },

    });
}());

