(function () {
    'use strict';

    Directives.JustifiedGallery = function JustifiedGallery() {

        // @ngInject
        function JustifiedGalleryController($scope, $timeout, $element) {
            var self = this;
            this.loading = true;
            this.gallaryIsBuilt = false;

            if ($scope.justifiedGalleryVm.galleryArgs) {
                this.galleryArgs = $scope.justifiedGalleryVm.galleryArgs;
            }
            else {
                this.galleryArgs = {
                    margins: 4,
                    lastRow: 'nojustify',
                    maxRowHeight: 150
                };
            }

            var galleryElement = $element.find('.gallery');

            this.handleOnGalleryComplete = function handleOnGalleryComplete(e) {
                $timeout(function () {
                    self.loading = false;

                    galleryElement.css('visibility', 'visible');

                    if (angular.isFunction(this.hbjgOnComplete)) {
                        this.hbjgOnComplete();
                    }
                }.bind(this));

            }.bind(this);

            this.handleResizeEnded = function handleResizeEnded() {
                $timeout(function () {
                    self.loading = false;

                    galleryElement.css('visibility', 'visible');

                    if (angular.isFunction(this.hbjgOnResizeEnded)) {
                        this.hbjgOnResizeEnded();
                    }
                }.bind(this));

            }.bind(this);

            this.build = function build() {
                galleryElement.off('jg.complete', self.handleOnGalleryComplete);
                galleryElement.off('jg.resize', self.handleResizeEnded);

                $timeout(function () {
                    if(this.gallaryIsBuilt) {
                        galleryElement.justifiedGallery('destroy');
                    }

                    if (angular.isFunction(self.hbjgBeforeComplete)) {
                        self.hbjgBeforeComplete();
                    }

                    galleryElement.justifiedGallery(self.galleryArgs);
                    this.gallaryIsBuilt = true;

                    galleryElement.on('jg.complete', self.handleOnGalleryComplete);
                    galleryElement.on('jg.resize', self.handleResizeEnded);
                }.bind(this));
            };

            this.rebuild = function rebuild() {
                this.loading = true;
                galleryElement.css('visibility', 'hidden');
                this.build();
            };

            this.norewind = function norewind() {
                $timeout(function () {
                    galleryElement.justifiedGallery('norewind');
                });
            };

            $scope.$watch(
                function () {
                    var galleryChildElemets = galleryElement.find('.hbjg-item');
                    return galleryChildElemets.length;
                },
                function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue && newValue > 0) {
                        this.rebuild();
                    }
                }.bind(this)
            );

            if (this.hbjgDeepWatchCollection) {
                $scope.$watch('justifiedGalleryVm.hbjgWatchedCollection', function(newValue, oldValue) {
                    this.rebuild();
                }.bind(this), true);
            }

            if (this.hbjgWatchedCollection && this.hbjgWatchedCollection.length === 0) {
                this.handleOnGalleryComplete();
            }
        }


        return {
            restrict: 'E',
            scope: {
                hbjgWatchedCollection: '=',
                galleryArgs: '=?',
                hbjgOnResizeEnded: '&?',
                hbjgOnComplete: '&?',
                hbjgBeforeComplete: '&?',
                hbjgDeepWatchCollection: '=?',
            },
            templateUrl: 'angular/app/modules/common/ui_components/justified_gallery/justified_gallery_directive_template.html',

            transclude: true,
            controller: JustifiedGalleryController,
            controllerAs: 'justifiedGalleryVm',
            bindToController: true
        };
    };
}());
