/**
 * Created by dvircohen on 31/07/2016.
 */
(function () {
    "use strict";

    // @ngInject
    function LinksManagerCtor(APIService, $q, Routes, ModelFactory, Fingerprint) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.Fingerprint = Fingerprint;
    }

    Services.LinksManager = Class(Services.BaseModelManager, {
        constructor: LinksManagerCtor,

        resolve: function resolve(link_id, link_token, state_params, f_token) {

            if (!link_id || !link_token) {
                return;
            }

            return this.Fingerprint().then(function(fingerprint) {
                var url = this.Routes.v2_resolve_link_path(link_id, link_token, state_params);
                return this.apiUpdate(url, {fingerprint: fingerprint, f_token: f_token});
            }.bind(this));
        },

        regenerateLink: function regenerateLink(link_id, link_token) {

            if (!link_id || !link_token) {
                return;
            }

            var url = this.Routes.v2_regenerate_link_path(link_id, link_token);
            return this.apiCreate(url);
        },

        linkCodeSubmitted: function linkCodeSubmitted(link_id, code){

            if (!link_id) {
                return;
            }

            var url = this.Routes.v2_link_code_submitted_path(link_id);
            return this.apiCreate(url, {code: code});
        }
    });
}());
