// @ngInject

Models.WorkspaceFileModel = function WorkspaceFileModel(Enums, BaseModel, UsersManager, CompaniesManager, WorkspaceFilesManager, ProposalsManager, BrochuresManager, TimelineManager, _, WorkspaceFileService) {

    var fileStatuses = {
        new_workspace_create: 0,
        welcome_email_sent: 1,
        welcome_brochure_seen: 2,
        questionnaire_seen: 3,
        questionnaire_answered: 4,
        proposal_created: 5,
        proposal_sent: 6,
        proposal_brochure_seen: 7,
        proposal_seen: 8,
        agreement_seen: 9,
        agreement_signed: 10,
        payments_seen: 11,
        retainer_paid: 12,
        payment_done: 13

    };

    var fileTypes = {
        proposal: 0,
        invoice: 1,
        direct_payment: 2,
        questionnaire: 3,
        brochure: 4,
        timeline: 5,
        agreement: 6
    };

    var fileTypesLiteral = {
        proposal: 'proposal',
        invoice: 'invoice',
        direct_payment: 'direct_payment',
        questionnaire: 'questionnaire',
        brochure: 'brochure',
        timeline: 'timeline',
        agreement: 'agreement'
    };

    var WorkspaceFileModelClass = Class(BaseModel, {

        constructor: function constructor() {
            WorkspaceFileModelClass.$super.call(this);
            this.__className = 'WorkspaceFileModel';
            this._ = _;

            this.init();
        },

        init: function init() {

            this.payments_container.__workspace_file = this;
            this.active_state = true;
        },

        modelVersion: function modelVersion() {
           return this.model_version ? this.model_version : 0;
        },

        mapChilds: function mapChilds() {
            return {
                owner: 'UserModel',
                company: 'CompanyModel',
                agreement: 'AgreementModel',
                questionnaire: 'QuestionnaireModel',
                payments_container: 'PaymentsContainerModel',
                vendor_proposal: 'ProposalModel',
                brochure_container: 'BrochureProposalsContainerModel',
                timeline_container: 'TimelineContainerModel',
                file_dynamic_content: 'FileDynamicContentModel',
                users: 'UsersCollection'
            };
        },

        getRootModelType: function getRootModelType() {
            return this.file_type;
        },

        getRootModel: function getRootModel() {
            return this;
        },

        afterMixingFrom: function afterMixingFrom(object) {
            if (this.payments_container) {
                this.payments_container.__workspace_file = this;
            }

            if (this.vendor_proposal) {
                this.vendor_proposal.__workspace_file = this;
            }

            this.__usersWithoutTeamMembers = null;
            this.__clients = null;
            this.__nonTeamMembersVendors = null;
        },

        getCurrentVersionData: function getCurrentVersionData() {
            var version = null;
            this.file_version_container.versions.forEach(function(ver){
                if (version === null && ver.file_version === this.file_version) {
                    version = ver;
                }
            }.bind(this));

            return version;
        },

        isPaymentMethodAccepted: function isPaymentMethodAccepted(paymentMethod) {
            return this.accepted_payment_method === null || this.accepted_payment_method === paymentMethod;
        },

        isViewOnlyMode: function isViewOnlyMode() {

            // User is in the same company as the owner of the file
            var currUser = UsersManager.getCurrUser();
            return currUser &&
                currUser.isVendor() &&
                currUser._id !== this.owner._id &&
                currUser.company._id === this.company._id &&
                !currUser.hasCompanyAdminPermissions();
        },

        isClientMode: function isViewOnlyMode() {
            // User is a client, or the file owner, or in a different company than the file owner
            var currUser = UsersManager.getCurrUser();
            return currUser &&
                (currUser.isClient() ||
                 (angular.isDefined(this.owner._id) && angular.isDefined(this.company._id) && currUser._id !== this.owner._id && currUser.company._id !== this.company._id));
        },

        isOwnerMode: function isOwnerMode() {
            var currUser = UsersManager.getCurrUser();
            return this.isOwnedByCurrUser() ||
                (currUser.isVendor() && currUser.company && this.company &&
                currUser.company._id === this.company._id &&
                currUser.hasCompanyAdminPermissions());
        },

        //checks if the user is the file owner OR has permissions of moderator level OR HIGHER
        //in same company as the file owner
        isModeratorMode: function isModeratorMode() {
            var currUser = UsersManager.getCurrUser();
            return this.isOwnedByCurrUser() ||
                (currUser.isVendor() && currUser.company && this.company &&
                currUser.company._id === this.company._id &&
                currUser.hasCompanyModeratorPermissions());
        },

        isProposal: function isProposal() {
            return this._isFileOfType('proposal');
        },

        isInvoice: function isInvoice() {
            return this._isFileOfType('invoice');
        },

        isQuestionnaire: function isQuestionnaire() {
            return this._isFileOfType('questionnaire');
        },

        isBrochure: function isBrochure() {
            return this._isFileOfType('brochure');
        },

        isNewBrochure: function isNewBrochure() {
            return this.isBrochure() && !!this.is_new_brochure;
        },

        isTimeline: function isTimeline() {
            return this._isFileOfType('timeline');
        },

        isAgreement: function isAgreement() {
            return this._isFileOfType('agreement');
        },

        isBookable: function isBookable() {
            return this.isProposal() || this.isInvoice() || this.isAgreement();
        },

        hasProposal: function hasProposal() {
            return this.isProposal() || this.isInvoice() || this.isDirectPayment();
        },

        hasAgreement: function hasAgreement() {
            return this.isProposal() || this.isAgreement();
        },

        hasPayments: function hasPayments() {
            return this.isProposal() || this.isInvoice();
        },

        hasQuestionnaire: function hasQuestionnaire() {
            return this.isQuestionnaire();
        },

        hasBrochure: function hasBrochure() {
            return this.isBrochure();
        },

        isDirectPayment: function isDirectPayment() {
            return this.file_type_cd === fileTypes.direct_payment;
        },

        isEditable: function isEditable() {

            if(this.file_version_container){
                return !this.is_canceled && this.file_version_container.draft_editable && this.isLatestVersion() && !this.isInvalidated();
            }

            if (this.isBrochure()) {
                return !this.is_canceled && !this.isSent();
            }
            else if (this.isTimeline()) {
                return !this.is_canceled;
            }
            return !this.is_canceled && !this.isBooked();

        },

        isFirstDraft: function isFirstDraft(){
            return this.file_version === 1 && this.isEditable();
        },

        isFirstActiveVersion: function isFirstActiveVersion(){
            return this.file_version === 1 && this.isActiveVersion();
        },

        isInvalidated: function isInvalidated(){
            if (this.isQuestionnaire() || this.isBrochure()) {
                return false;
            }

            // only relevant for owner with editable draft
            return this.isLatestVersion() && this.isOwnerMode() && this.is_draft_invalidated === true;
        },

        draftInvalidatedReason: function draftInvalidatedReason(){
            if (!this.isInvalidated()) {
                return null;
            }

            return this.draft_invalidation.reason;
        },

        /**
         * Check if the file version or the version provided is the latest version in the file.
         * @param {VersionObject} [version] the version to check.
         * If not provided then the current file's version will be checked
         * @returns {boolean} if it is the latest version of the file
         */
        isLatestVersion: function isLatestVersion(version) {
            if (this.isQuestionnaire() || this.isBrochure()) {
                return false;
            }

            if (this.file_version_container && this.file_version_container.versions && this.file_version_container.versions.length > 0) {
                var versionToCheck = this.file_version;
                if (version) {
                    versionToCheck = version.file_version;
                }
                return versionToCheck === this.file_version_container.versions[0].file_version;
            }

            return true;
        },

        isWaitingForAccept: function isWaitingForAccept() {
            return (this.hasProposal() || this.isAgreement()) &&
              !this.isOwnerMode() &&
            ((!this.isBookedVersion() && this.isBooked()) || this.oldVersionPending()) &&
              this.isLatestVersion() &&
              !this.isFileAccepted();
        },

        oldVersionPending: function oldVersionPending() {
            var pendingPayment = this.payments_container.getPendingPayment();
            return pendingPayment && pendingPayment.paid_on_version < this.file_version;
        },

        isDraft: function isDraft(){
            // if its a request proposal, we wont get the versions
            return this.isLatestVersion() && this.hasDraft();
        },

        hasDraft: function hasDraft() {
            return this.file_version_container && this.file_version_container.versions && this.file_version_container.versions.length > 0 && this.file_version_container.versions[0].is_draft
        },

        isAllowedToReSend: function isAllowedToReSend() {
            if (this.isInvoice() || this.isProposal() || this.isAgreement()) {
                return this.isLatestVersion() && this.isOwnerMode() && this.isSent() && !this.isDraftEditable() && !this.is_canceled;
            }
            else if (this.isBrochure()) {
                return this.isSent() && !this.isComplete();
            }

            return false;
        },

        isAllowedToCreateDraft: function isAllowedToCreateDraft() {
            if(this.isQuestionnaire() || this.isBrochure() || this.isTimeline()){
                return false;
            }

            return !this.is_canceled &&
                this.file_version_container &&
                !this.file_version_container.draft_editable && //we are already editing
                this.isLatestVersion();
        },

        isRecurringPayment: function isRecurringPayment() {
            return this.payment_type === 'recurring';
        },

        isRecurringPaymentActive: function isRecurringPaymentActive() {
            return this.isRecurringPayment() && this.payments_container.isRecurringPaymentActive();
        },

        isRecurringPaymentAccepted: function isRecurringPaymentAccepted() {
            return this.isRecurringPayment() && this.payments_container.isRecurringPaymentAccepted();
        },

        isRecurringPaymentCanceled: function isRecurringPaymentCancelled() {
            return this.isRecurringPayment() && this.payments_container.recurring_payment && this.payments_container.recurring_payment.is_canceled;
        },

        isDraftEditable: function isDraftEditable() {
            return this.file_version_container && this.file_version_container.draft_editable;
        },

        isOwnedByCurrUser: function isOwnedByCurrUser() {

            return (this.owner &&
            this.owner._id === UsersManager.getCurrUser()._id);
        },

        isBooked: function isBooked() {

            return (
            (this.isProposal() && this.status_cd >= fileStatuses.agreement_signed) ||
            (this.isInvoice() && this.status_cd >= fileStatuses.retainer_paid) ||
            (this.isQuestionnaire() && this.status_cd >= fileStatuses.payment_done) ||
            (this.isBrochure() && this.status_cd >= fileStatuses.payment_done) ||
            (this.isDirectPayment() && this.status_cd >= fileStatuses.retainer_paid) ||
            (this.isAgreement() && this.status_cd >= fileStatuses.agreement_signed)
            );
        },

        hideBookedInPerson: function hideBookedInPerson() {

            return (
                (this.isProposal() && this.status_cd >= fileStatuses.retainer_paid) ||
                (this.isInvoice() && this.status_cd >= fileStatuses.retainer_paid) ||
                (this.isQuestionnaire()) ||
                (this.isBrochure()) ||
                (this.isDirectPayment() && this.status_cd >= fileStatuses.retainer_paid) ||
                (this.isAgreement())
            );
        },

        isActiveVersion: function isActiveVersion() {
            if (this.isQuestionnaire() || this.isBrochure() || this.isDirectPayment()) {
                return false;
            }

            if(this.active_file_version && !this.file_version){
                return true;
            }

            if(this.active_file_version && this.file_version){
                return this.active_file_version === this.file_version;
            }

            //else we try to get that information from the versions
            var version = this.getCurrentVersionData();
            return (version && version.is_active) || // in the case where we have version information check it
                (version === null); //if we dont have version information this is the active version.
        },

        isHistoryVersion: function isHistoryVersion() {
            if (this.isQuestionnaire() || this.isBrochure() || this.isDirectPayment()) {
                return false;
            }

            var version = this.getCurrentVersionData();
            return !version.is_draft && !version.is_active;
        },

        isCanceled: function isCanceled() {
            return this.is_canceled;
        },

        isBookedVersion: function isBookedVersion() {
            return this.is_booked_version;
        },

        isFileAccepted: function isFileAccepted() {
            return this.is_file_accepted;
        },

        isAgreementSeen: function isAgreementSeen() {

            return ((this.isProposal() || this.isAgreement()) && this.status_cd >= fileStatuses.agreement_seen);
        },

        isAgreementSigned: function isAgreementSigned() {
            return ((this.isProposal() || this.isAgreement()) && this.status_cd >= fileStatuses.agreement_signed);
        },

        isPaymentsSeen: function isPaymentsSeen() {
            return (this.status_cd >= fileStatuses.payments_seen);
        },

        isComplete: function isComplete() {
            return (this.status_cd === fileStatuses.payment_done);
        },

        //checks if workspace file has sent status
        isSent: function isSent() {
            // return (this.status_cd >= fileStatuses.proposal_sent);
            return !!this.sent_on;
        },

        //checks if workspace file has any sent version
        hasSentVersion: function hasSentVersion() {
            var hasSentVersion = false;
            if (!!this.file_version_container && !!this.file_version_container.versions) {
                this.file_version_container.versions.forEach(function(version) {
                    if (!!version.sent_on && version.sent_on !== "N/A") {
                        hasSentVersion = true;

                    }
                });
            }
            return hasSentVersion;
        },

        hasSentEmails: function hasSentEmails() {
            return this.isSent() && !!this.last_sent_email;
        },

        isAccessible: function isAccessible() {
            // do not allow a user to view a file if he is the client of an active request
            return (!(this.request_active && !this.isOwnedByCurrUser()));
        },

        hasPaidPayments: function hasPaidPayments() {
            return this.hasPayments() && this.payments_container.hasPaidPayments();
        },

        hasPendingPayment: function hasPendingPayment() {
            if (angular.isDefined(this.has_pending_payment)) {
                return this.has_pending_payment;
            } else {
                return this.payments_container.hasPendingPayment();
            }
        },

        hasMoreThanOneVersion: function hasMoreThanOneVersion() {
            return this.file_version_container &&
                this.file_version_container.versions &&
                this.file_version_container.versions.length > 1;
        },

        hasOnlyNotCompletedMilestonePayments: function hasOnlyNotCompletedMilestonePayments() {
            return this.payments_container.hasOnlyNotCompletedMilestonePayments();
        },

        supportVersions: function supportVersions() {
            return this.isProposal() || this.isInvoice() || this.isAgreement();
        },

        print: function print(partsToPrint) {
            return WorkspaceFilesManager.print(this, partsToPrint);
        },

        /**
         * Get the latest email tha was sent in this workspace file.
         * This email can used as a template for the next email to send.
         * @returns {object} the email
         */
        getEmailToSend: function getEmailToSend() {
            return this.last_sent_email;
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return {
                workspace_file: this._id,
                event_name: this.event.event_name
            };
        },

        hasPreferredVendorsList: function hasPreferredVendorsList() {
            return !!(this.preferred_vendors_list &&
                this.preferred_vendors_list.preferred_vendor_ids &&
                this.preferred_vendors_list.preferred_vendor_ids.length > 0);
        },

        hasPendingPvlRequest: function hasPendingPvlRequest() {
            return !!(this.preferred_vendors_list && !this.preferred_vendors_list.sent_on);
        },

        /*****************************************
         * FILE ACTION
         ******************************************/
        createFileDraft: function createFileDraft() {
            return WorkspaceFilesManager.createFileDraft(this);
        },

        discardAndCreateDraft: function discardAndCreateDraft() {
            return WorkspaceFilesManager.createFileDraft(this, true);
        },

        discardFileDraft: function discardFileDraft() {
            return WorkspaceFilesManager.discardFileDraft(this);
        },

        acceptNewVersion: function acceptNewVersion() {
            return WorkspaceFilesManager.acceptNewVersion(this);
        },

        deleteWorkspaceFile: function deleteWorkspaceFile() {
            return WorkspaceFilesManager.deleteWorkspaceFile(this);
        },

        deactivateWorkspaceFile: function deactivateWorkspaceFile() {
            return WorkspaceFilesManager.deactivateWorkspaceFile(this);
        },

        activateWorkspaceFile: function activateWorkspaceFile() {
            return WorkspaceFilesManager.activateWorkspaceFile(this);
        },

        updateAutoExpiration: function addAutoExpiration(period) {
            // set it locally first to have the UX more responsive.
            this.auto_expiration = {
                period: period
            };

            return WorkspaceFilesManager.updateAutoExpiry(this);
        },

        deleteAutoExpiration: function deleteAutoExpiration() {
            this.auto_expiration = null;
            return WorkspaceFilesManager.deleteAutoExpiry(this);
        },


        /*****************************************
         * TIMELINE ACTIONS
         ******************************************/
        addTimelineSection: function addTimelineSection(timelineSectionToAddId) {
            return TimelineManager.addTimelineSection(this, timelineSectionToAddId);
        },

        updatetTimelineItem: function updatetTimelineItem(timelineItemModel, timelineSectionModel) {
            return TimelineManager.updatetTimelineItem(this, timelineItemModel, timelineSectionModel);
        },

        updateTimelineItemsOrder: function updateTimelineItemsOrder(timelineSectionModel) {
            return TimelineManager.updateTimelineItemsOrder(this, timelineSectionModel);
        },

        deleteTimelineItem: function deleteTimelineItem(timelineItemModel, timelineSectionModel) {
            return TimelineManager.deleteTimelineItem(this, timelineItemModel, timelineSectionModel);
        },

        updateTimelineSection: function updateTimelineSection(timelineSectionModel) {
            return TimelineManager.updateTimelineSection(this, timelineSectionModel);
        },

        deleteTimelineSection: function deleteTimelineSection(timelineSectionModel) {
            return TimelineManager.deleteTimelineSection(this, timelineSectionModel);
        },

        addTimelineItemToTimelineSection: function addTimelineItemToTimelineSection(timelineSectionModel) {
            return TimelineManager.addTimelineItemToTimelineSection(this, timelineSectionModel, this._createEmptyTimelineItem(true));
        },

        updateTimelineContainer: function updateTimelineContainer(data) {
            return TimelineManager.updateTimelineContainer(data);
        },

        /*****************************************
         * PROPOSAL ACTIONS
         ******************************************/

        getTotalProposalValue: function getTotalProposalValue() {
            return this.vendor_proposal.total_price;
        },

        toggleTaxToAllPackages: function toggleTaxToAllPackages(addTax) {
            return ProposalsManager.toggleTaxToAllPackages(this, addTax);
        },

        toggleSvcToAllPackages: function toggleSvcToAllPackages(addSvc) {
            return ProposalsManager.toggleSvcToAllPackages(this, addSvc);
        },

        calculateProposalTax: function calculateProposalTax(proposal, type, tax, taxOrSvc) {
            return WorkspaceFilesManager.calculateTax(this, type, tax, taxOrSvc);
        },

        /*****************************************
         * PAYMENTS ACTIONS
         ******************************************/

        deletePayment: function deletePayment(paymentModel) {
            return WorkspaceFilesManager.deletePayment(this, paymentModel);
        },
        addPayment: function addPayment() {
            return WorkspaceFilesManager.addPayment(this);
        },

        updatePayment: function updatePayment(paymentModel) {
            return WorkspaceFilesManager.updatePayment(this, paymentModel);
        },

        sendPaymentReminder: function sendPaymentReminder(paymentModel) {
            return WorkspaceFilesManager.sendPaymentReminder(this, paymentModel);
        },

        payPayment: function payPayment(payment, paymentMethod, tip, activateAutoPayment, paidInPerson, activateRecurringPayment) {
            return WorkspaceFilesManager.payPayment(this, payment, paymentMethod, tip, activateAutoPayment, paidInPerson, activateRecurringPayment);
        },

        preValidateCharge: function preValidateCharge(payment, paymentMethod, tip, activateAutoPayment, paidInPerson) {
            return WorkspaceFilesManager.preValidateCharge(this, payment, paymentMethod, tip, activateAutoPayment, paidInPerson);
        },

        printPaymentReceipt: function printPaymentReceipt(payment) {
            return WorkspaceFilesManager.printPaymentReceipt(this, payment);
        },

        updateAllowGratuity: function updateAllowGratuity(allow) {
            return WorkspaceFilesManager.updateAllowGratuity(this, allow);
        },

        savePaymentsContainerAsTemplate: function savePaymentsContainerAsTemplate(templateTitle, company) {
            return CompaniesManager.saveAsPaymentsTemplate(company || this.company, this, templateTitle);
        },

        updatePaymentsByTemplate: function updatePaymentsByTemplate(templateId) {
            return WorkspaceFilesManager.updatePaymentsByTemplate(this, templateId);
        },

        togglePaymentScheduleType: function togglePaymentScheduleType(isRecurring) {
            return WorkspaceFilesManager.togglePaymentScheduleType(this, isRecurring);
        },

        updateRecurringPayment: function updateRecurringPayment(startDate, endDate) {
            return WorkspaceFilesManager.updateRecurringPayment(this, startDate, endDate);
        },

        updateRecurringPaymentMethod: function updateRecurringPaymentMethod(paymentMethodId) {
            return WorkspaceFilesManager.updateRecurringPaymentMethod(this, paymentMethodId);
        },

        cancelRecurringPayment: function cancelRecurringPayment() {
            return WorkspaceFilesManager.cancelRecurringPayment(this);
        },

        /*****************************************
         * PACKAGES ACTIONS
         ******************************************/

        addPackage: function addPackage(proposal, packageToAddId) {
            return ProposalsManager.addPackageToFile(this, packageToAddId);
        },

        addCustomItem: function addCustomItem(proposal, itemData) {
            return ProposalsManager.addCustomItemToFile(this, itemData);
        },

        updateProposal: function updateProposal(proposalData) {
            return ProposalsManager.updateProposal(this, proposalData);
        },

        updateService: function updateService(proposal, serviceModel, packageModel) {
            return ProposalsManager.updateService(this, serviceModel, packageModel);
        },

        updateALaCarteServiceSelection: function updateALaCarteServiceSelection(brochure, serviceModel, isSelected) {
            return BrochuresManager.updateALaCarteServiceSelection(this, serviceModel, isSelected);  
        },
        
        updateServiceImage: function updateServiceImage(proposal, serviceModel, image, packageModel) {
            return ProposalsManager.updateServiceImage(this, serviceModel, image, packageModel);
        },

        deleteServiceImage: function deleteServiceImage(proposal, serviceModel, packageModel) {
            return ProposalsManager.deleteServiceImage(this, serviceModel, packageModel);
        },

        updateServicesOrder: function updateServicesOrder(proposalModel, packageModel) {
            return ProposalsManager.updateServicesOrder(this, packageModel);
        },

        updateVendorPackagesOrder: function updateVendorPackagesOrder(proposalModel,packagesOrder) {
            return ProposalsManager.updateVendorPackagesOrder(this, packagesOrder);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(proposalModel,packagesOrder) {
            return ProposalsManager.updatePackagesAndServicesOrder(this, packagesOrder);
        },

        moveService: function moveService(proposalModel, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return ProposalsManager.moveService(this, serviceId, sourcePackageId, destPackageId, servicesCollection);
        },

        updateServicesOrderInALaCarte: function updateServicesOrderInALaCarte(brochureContainer, aLaCarteModel) {
            return BrochuresManager.updateServicesOrderInALaCarte(this, aLaCarteModel);
        },

        deleteService: function deleteService(proposal, serviceModel, packageModel) {
            return ProposalsManager.deleteService(this, serviceModel, packageModel);
        },

        updatePackage: function updatePackage(proposal, packageModel) {
            return ProposalsManager.updatePackage(this, packageModel);
        },

        deletePackage: function deletePackage(proposal, packageModel) {
            return ProposalsManager.deletePackage(this, packageModel);
        },

        shouldShowTax: function () {
            return true;
        },

        shouldShowSVC: function () {
            return this.owner.isVenueVendor();
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(proposal, packageModel, newService) {
            return ProposalsManager.addAdditionalServiceToPackage(this, packageModel, newService);
        },

        updateAgreement: function updateAgreement() {
            return WorkspaceFilesManager.updateAgreement(this);
        },

        updateAgreementWithFile: function updateAgreementWithFile(agreementModel, fileBin) {
            return WorkspaceFilesManager.updateAgreementWithFile(this, fileBin);
        },

        updateAgreementByTemplate: function updateAgreementByTemplate(templateId) {
            return WorkspaceFilesManager.updateAgreementByTemplate(this, templateId);
        },

        saveAgreementAsTemplate: function saveAgreementAsTemplate(templateTitle, company) {
            return CompaniesManager.saveAsAgreementTemplate(company || this.company, this, templateTitle);
        },

        updateSignature: function updateSignature(signatureModel) {
            return WorkspaceFilesManager.updateSignature(this, signatureModel);
        },

        changeSignatureSignatory: function changeSignatureSignatory(signatureModel, user_id) {
            return WorkspaceFilesManager.changeSignatureSignatory(this, signatureModel, user_id);
        },

        updateSignatureDescription: function updateSignatureDescription(signatureModel) {
            return WorkspaceFilesManager.updateSignatureDescription(this, signatureModel);
        },

        removeSignature: function removeSignature(signatureModel) {
            return WorkspaceFilesManager.removeSignature(this, signatureModel);
        },
        addSignature: function addSignature(signee) {
            return WorkspaceFilesManager.addSignature(this, signee);
        },

        /** Questionnaire Actions */

        createQuestion: function createQuestion(questionnaire, question) {
            return WorkspaceFilesManager.createQuestion(this, question);
        },

        createEventQuestions: function createEventQuestions(questionnaire) {
            return WorkspaceFilesManager.createEventQuestions(this);
        },

        updateQuestion: function updateQuestion(questionnaire, question) {
            var currentUser = UsersManager.getCurrUser();
            return WorkspaceFilesManager.updateQuestion(this, question, currentUser);
        },

        updateQuestionsOrder: function updateQuestionOrder(questionnaire) {
            return WorkspaceFilesManager.updateQuestionsOrder(this);
        },

        deleteQuestion: function deleteQuestion(questionnaire, question) {
            return WorkspaceFilesManager.deleteQuestion(this, question);
        },

        /*****************************************
         * BROCHURE ACTIONS
         ******************************************/
        createBrochureProposal: function createBrochureProposal() {
            return BrochuresManager.createBrochureProposal(this);
        },

        deleteBrochureProposal: function deleteBrochureProposal(brochureProposal) {
            return BrochuresManager.deleteBrochureProposal(this, brochureProposal);
        },

        createBrochureALaCarteProposal: function createBrochureALaCarteProposal() {
            return BrochuresManager.createBrochureALaCarteProposal(this);
        },

        deleteBrochureALaCarteProposal: function deleteBrochureALaCarteProposal() {
            return BrochuresManager.deleteBrochureALaCarteProposal(this);
        },

        updateBrochureProposal: function updateBrochureProposal(brochureContainer, brochureProposal) {
            return BrochuresManager.updateBrochureProposal(this, brochureProposal);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte(aLaCarte) {
            return BrochuresManager.updateBrochureALaCarte(this);
        },

        updateBrochureProposalsOrder: function updateBrochureProposalsOrder(brochure, proposalsOrder) {
            return BrochuresManager.updateBrochureProposalsOrder(this, proposalsOrder);
        },

        selectBrochureProposal: function selectBrochureProposal(brochureProposal) {
            return BrochuresManager.selectBrochureProposal(this, brochureProposal);
        },

        submitBrochure: function submitBrochure() {
            return BrochuresManager.submitBrochure(this);  
        },

        submitNewBrochure: function submitNewBrochure(clientNotes) {
            return WorkspaceFilesManager.submitNewBrochure(this, clientNotes);
        },

        createBrochureGreeting: function createBrochureGreeting() {
            return BrochuresManager.createBrochureGreeting(this);
        },

        updateBrochureGreeting: function updateBrochureGreeting() {
            return BrochuresManager.updateBrochureGreeting(this, this.brochure_container.greeting);
        },

        deleteBrochureGreeting: function deleteBrochureGreeting() {
            return BrochuresManager.deleteBrochureGreeting(this);
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(brochure, image) {
            return BrochuresManager.updateBrochureGreetingImage(this, image);
        },

        updateBrochureServicesOrder: function updateBrochureServicesOrder(brochureContainer, brochureProposal, packageModel) {
            return BrochuresManager.updateServicesOrder(this, brochureProposal, packageModel);
        },

        addPackageToBrochure: function addPackageToBrochure(brochureContainer, proposal, packageToAddId) {
            return ProposalsManager.addPackageToBrochure(this, proposal, packageToAddId);
        },

        addAdditionalServiceToPackageInBrochure: function addAdditionalServiceToPackageInBrochure(brochureContainer, proposal, packageModel, newService) {
            return ProposalsManager.addAdditionalServiceToPackageInBrochure(this, proposal, packageModel, newService);
        },

        addCustomItemToBrochure: function addCustomItemToBrochure(brochureContainer, proposal, itemData) {
            return ProposalsManager.addCustomItemToBrochure(this, proposal, itemData);
        },

        deleteServiceFromBrochure: function deleteServiceFromBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return ProposalsManager.deleteServiceFromBrochure(this, proposal, serviceModel, packageModel);
        },

        deletePackageFromBrochure: function deletePackageFromBrochure(brochureContainer, proposal, packageModel) {
            return ProposalsManager.deletePackageFromBrochure(this, proposal, packageModel);
        },

        deleteServiceImageOnBrochure: function deleteServiceImageOnBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return ProposalsManager.deleteServiceImageOnBrochure(this, proposal, serviceModel, packageModel);
        },

        deleteImageFromALaCarteService: function deleteImageFromALaCarteService(brochureContainer, serviceModel) {
            return BrochuresManager.deleteImageFromALaCarteService(this, serviceModel);
        },
        
        updateServiceOnBrochure: function updateServiceOnBrochure(brochureContainer, proposal, serviceModel, packageModel) {
            return ProposalsManager.updateServiceOnBrochure(this, proposal, serviceModel, packageModel);
        },

        addServiceToBrochureALaCarte: function addServiceToBrochureALaCarte(brochureContainer, serviceData) {
            return BrochuresManager.addServiceToBrochureALaCarte(this, serviceData);
        },

        deleteServiceFromBrochureALaCarte: function deleteServiceFromBrochureALaCarte(brochureContainer, serviceModel) {
            return BrochuresManager.deleteServiceFromBrochureALaCarte(this, serviceModel);
        },

        updateServiceOnBrochureALaCarte: function updateServiceOnBrochureALaCarte(brochureContainer, serviceData) {
            return BrochuresManager.updateServiceOnBrochureALaCarte(this, serviceData);
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(brochureContainer, image) {
            return BrochuresManager.updateBrochureALaCarteImage(this, image);
        },

        updatePackageOnBrochure: function updatePackageOnBrochure(brochureContainer, proposal, packageModel) {
            return ProposalsManager.updatePackageOnBrochure(this, proposal, packageModel);
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(brochureContainer, brochureProposal, image) {
            return BrochuresManager.updateBrochureProposalImage(this, brochureProposal, image);
        },

        updateServiceImageOnBrochure: function updateServiceImageOnBrochure(brochureContainer, brochureProposal, serviceModel, packageModel, image) {
            return ProposalsManager.updateServiceImageOnBrochure(this, brochureProposal, serviceModel, packageModel, image);
        },

        updateImageInALaCarteService: function updateImageInALaCarteService(brochureContainer, serviceModel, image) {
            return BrochuresManager.updateImageInALaCarteService(this, serviceModel, image);
        },

        isUserSignee: function isUserSignee(user) {
            if (this.hasAgreement()) {
                return this._.some(this.agreement.contract_signatures, {signee_id: user._id});
            }
        },

        updateEnableClientNotes: function updateEnableClientNotes(enable) {
            return WorkspaceFilesManager.updateEnableClientNotes(this, enable);
        },

        /*****************************************
         * SECTION ACTIONS
         ******************************************/

        addSection: function addSection(fileDynamicContent) {
            return WorkspaceFilesManager.createFileSection(this);
        },

        updateSection: function updateSection(fileDynamicContent, section) {
            return WorkspaceFilesManager.updateFileSection(this, section);
        },

        deleteSection: function deleteSection(fileDynamicContent, section) {
            return WorkspaceFilesManager.deleteFileSection(this, section);
        },

        updateSectionsOrder: function updateSectionsOrder(fileDynamicContent, sectionsOrder) {
            return WorkspaceFilesManager.updateFileSectionsOrder(this, sectionsOrder);
        },

        /*****************************************
         * BLOCKS ACTIONS
         ******************************************/

        addBlock: function addBlock(fileDynamicContent, section, params) {
            return WorkspaceFilesManager.createFileBlock(this, section, params);
        },

        updateBlock: function updateBlock(fileDynamicContent, section, block, changedFields) {
            return WorkspaceFilesManager.updateFileBlock(this, section, block, changedFields);
        },

        deleteBlock: function deleteBlock(fileDynamicContent, section, block) {
            return WorkspaceFilesManager.deleteFileBlock(this, section, block);
        },

        updateSectionBlocksOrder: function updateSectionBlocksOrder(fileDynamicContent, section, blocksOrder) {
            return WorkspaceFilesManager.updateFileSectionBlocksOrder(this, section, blocksOrder);
        },

        /*****************************************
         * COMPONENTS ACTIONS
         ******************************************/
        updateComponent: function updateComponent(fileDynamicContent, section, block, component, path, changedFields, params) {
            return WorkspaceFilesManager.updateFileBlockComponent(this, section, block, component, path, changedFields, params);
        },

        answerQuestion: function answerQuestion(fileDynamicContent, section, block, component, path, changedFields, params) {
            return WorkspaceFilesManager.answerQuestion(this, section, block, component, path, changedFields, params);
        },

        /*****************************************
         * NEW PROPOSAL ACTIONS
         ******************************************/

        addPackageToBlock: function addPackageToBlock(fileDynamicContent, section, block, packageId) {
            return ProposalsManager.addPackageToBlock(this, section, block, packageId);
        },

        updatePackageOnBlock: function updatePackageOnBlock(fileDynamicContent, section, block, packageModel) {
            return ProposalsManager.updatePackageOnBlock(this, section, block, packageModel);
        },

        deletePackageFromBlock: function deletePackageFromBlock(fileDynamicContent, section, block, packageModel) {
            return ProposalsManager.deletePackageFromBlock(this, section, block, packageModel);
        },

        updatePackageServicesOrderOnBlock: function updatePackageServicesOrderOnBlock(fileDynamicContent, section, block, packageModel, order) {
            return ProposalsManager.updatePackageServicesOrderOnBlock(this, section, block, packageModel, order);
        },

        addServiceToBlock: function addServiceToBlock(fileDynamicContent, section, block, packageModel) {
            return ProposalsManager.addServiceToBlock(this, section, block, packageModel);
        },

        updateServiceOnBlock: function updateServiceOnBlock(fileDynamicContent, section, block, service, packageModel) {
            return ProposalsManager.updateServiceOnBlock(this, section, block, service, packageModel);
        },

        updateServiceQuantityOnBlock: function updateServiceQuantityOnBlock(fileDynamicContent, section, block, service, packageModel) {
            return ProposalsManager.updateServiceQuantityOnBlock(this, section, block, service, packageModel);
        },

        updatePackageQuantityOnBlock: function updatePackageQuantityOnBlock(fileDynamicContent, section, block, packageModel) {
            return ProposalsManager.updatePackageQuantityOnBlock(this, section, block, packageModel);
        },

        updateServiceImageOnBlock: function updateServiceImageOnBlock(fileDynamicContent, section, block, service, image, packageModel) {
            return ProposalsManager.updateServiceImageOnBlock(this, section, block, service, image, packageModel);
        },

        updateProposalSelection: function updateProposalSelection(fileDynamicContent, section, block, service, packageModel, isSelected) {
            return ProposalsManager.updateProposalSelection(this, section, block, service, packageModel, isSelected);
        },

        deleteServiceFromBlock: function deleteServiceFromBlock(fileDynamicContent, section, block, service, packageModel) {
            return ProposalsManager.deleteServiceFromBlock(this, section, block, service, packageModel);
        },

        updateProposalOrder: function updateProposalOrder(fileDynamicContent, section, block, order) {
            return ProposalsManager.updateProposalOrder(this, section, block, order);
        },

        moveServiceOnBlock: function moveServiceOnBlock(fileDynamicContent, section, block, serviceId, sourcePackageId, destPackageId, order) {
            return ProposalsManager.moveServiceOnBlock(this, section, block, serviceId, sourcePackageId, destPackageId, order);
        },

        updateProposalOnBlock: function updateProposalOnBlock(fileDynamicContent, section, block, proposalData) {
            return ProposalsManager.updateProposalOnBlock(this, section, block, proposalData);
        },

        /***********************
         // Menu Actions
         ***********************/
        allowDelete: function allowDelete() {
            return this.deletable === true;
        },

        allowDeactivate: function allowDeactivate() {
            var currUser = UsersManager.getCurrUser();
            return !this.is_canceled && (this.isOwnedByCurrUser() || (currUser.hasCompanyAdminPermissions() && currUser.isMyTeamMember(this.owner)) || this.isOwnerMode());
        },

        allowActivate: function allowActivate() {
            var currUser = UsersManager.getCurrUser();
            return this.is_canceled && (this.isOwnedByCurrUser() || (currUser.hasCompanyAdminPermissions() && currUser.isMyTeamMember(this.owner)) || this.isOwnerMode());
        },

        usersWithoutTeamMembers: function usersWithoutTeamMembers() {
            if (!this.__usersWithoutTeamMembers) {
                this.__usersWithoutTeamMembers = this.users.filter(function filterFileClients(user) {
                    return !user.company || user.company._id !== this.owner.company._id;
                }.bind(this));
            }
            return this.__usersWithoutTeamMembers;
        },

        hasValidRecipient: function hasValidRecipient() {
            var usersWithoutTeamMembers = this.usersWithoutTeamMembers();
            return (!usersWithoutTeamMembers || usersWithoutTeamMembers.length > 0);
        },

        getClients: function getClients() {
            if (!this.__clients) {
                var users = this.usersWithoutTeamMembers();
                this.__clients = users.filter(function clientFilter(user) {
                   return user.system_user_type === 'client';
                });
            }

            return this.__clients;
        },

        getNonTeamMembersVendors: function getNonTeamMembersVendors() {
            if (!this.__nonTeamMembersVendors) {
                var users = this.usersWithoutTeamMembers();
                this.__nonTeamMembersVendors = users.filter(function clientFilter(user) {
                    return user.system_user_type === 'vendor';
                });
            }

            return this.__nonTeamMembersVendors;
        },

        refundPayment: function refundPayment(payment) {
            return WorkspaceFilesManager.refundPayment(this, payment);
        },

        getOriginId: function getOriginId(templateType) {

            var type_to_model_map = {
                'proposal': 'proposal',
                'payments-schedule': 'payments_container',
                'brochure': 'brochure_container',
                'timeline': 'timeline_container',
            };

            // What's not in the map -> same name in the model (for now: agreement, questionnaire)
            var model_field = type_to_model_map[templateType] || type_to_model_map[this.file_type] || templateType || this.file_type;

            // hack to support new brochure
            if(this.isNewBrochure()) {
                model_field = '';
            }

            return (this[model_field] ? this[model_field].origin_copy_id : this.origin_copy_id);
        },

        getFileTypeDisplayName: function getFileTypeDisplayName(camelize) {
            return WorkspaceFileService.formatFileTypeToDisplayName(this.file_type, camelize);
        },

        _isFileOfType: function _isFileOfType(type) {
            return this.file_type_cd === fileTypes[type] || this.file_type === fileTypesLiteral[type];
        },

        isEnableClientNotes: function isEnableClientNotes() {
            return !!this.enable_client_notes;
        },
    });

    return WorkspaceFileModelClass;
};

