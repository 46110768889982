Directives.FeedItemSeenDirective = function FeedItemSeenDirective() {

    // @ngInject
    function FeedItemSeenDirectiveControllerFunc($scope, $injector, $translate, $filter, moment, UsersManager, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedItemSeenDirectiveController';

        this.user = UsersManager.getCurrUser();
        this.showSeenUsers = false;
        this.isSeen = false;
        this.tooltip = '';
        this.moment = moment;
        this.$filter = $filter;
        this.$translate = $translate;
        this._ = _;

        this.updateSeenStatus();

        $scope.$watch('feedItemSeenVm.feedItem.seen_at', function watchLastSeenDate(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateSeenStatus();
            }
        }.bind(this));

        $scope.$watch('feedItemSeenVm.feedItem.feed_tracking', function watchFeedTracking(newValue, oldValue) {
            if (newValue.length !== oldValue.length) {
                this.updateSeenStatus();
            }
        }.bind(this));

        $scope.$watch('feedItemSeenVm.feedUsers', function watchFeedUsers(newValue, oldValue) {
            if (Object.keys(newValue).length !== Object.keys(oldValue).length) {
                this.updateSeenStatus();
            }
        }.bind(this));

        $scope.$on('feedRefreshed', function() {
            this.updateSeenStatus();
        }.bind(this));
    }

    var FeedItemSeenDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedItemSeenDirectiveControllerFunc,

        updateSeenStatus: function updateSeenStatus() {

            if (this.user.isClient()) {
                return;
            }

            var seenAt = null;
            this.seenUsers = {};
            this.bouncedUsers = {};

            if (this.feedItem.feed_tracking) {
                this.showLegacySeen = false;
                // Discard duplicates
                this.feedItem.feed_tracking = this._.uniq(this.feedItem.feed_tracking, function(elem) { return [elem.viewed, elem.to_user_id].join(); });

                angular.forEach(this.feedItem.feed_tracking, function(feedTracking) {

                    var user = this.feedUsers[feedTracking.to_user_id];

                    if (user && user._id !== this.feedItem.data.sender_user_id) {

                        if (feedTracking.bounced_on) {
                            if (!this.bouncedUsers[user._id] && !this.seenUsers[user._id]) {
                                this.bouncedUsers[user._id] = {
                                    bounced_on: feedTracking.bounced_on,
                                    user: user
                                };
                            }
                        }
                        else if (feedTracking.viewed) {
                            if (!this.seenUsers[user._id]) {
                                this.seenUsers[user._id] = {
                                    user: user,
                                    views: 1,
                                    seen_at: feedTracking.viewed
                                };

                                delete this.bouncedUsers[user._id];
                            }
                            else {
                                this.seenUsers[user._id].seen_at = feedTracking.viewed;
                                this.seenUsers[user._id].views++;
                            }
                        }
                    }
                }.bind(this));

                this.isSeen = !!Object.keys(this.seenUsers).length;
                this.hasBounced = !!Object.keys(this.bouncedUsers).length;

                if (this.isSeen) {
                    this.seenUsers = this._.sortBy(this.seenUsers, function(seenUser) {
                        // client / non team member / team member
                        return seenUser.user.isClient() ? -1 : (seenUser.user.company._id === this.feedOwnerCompanyId ? 0 : 1);
                    });
                }
            }

            // Failed to have seen users from feed_tracking
            if (!this.isSeen) {
                if (this.feedItem.seen_at && this.feedItem.data.last_viewed_at) {
                    // get the earlier view time
                    seenAt = this.moment(this.feedItem.data.last_viewed_at) < this.moment(this.feedItem.seen_at) ? this.feedItem.data.last_viewed_at : this.feedItem.seen_at;
                }
                else {
                    seenAt = this.feedItem.seen_at || this.feedItem.data.last_viewed_at;
                }

                if (seenAt) {
                    this.showLegacySeen = true;
                    this.tooltip = this.$translate.instant('WORKSPACE.EMAILS._EMAIL_VIEWED_', {date: this.$filter('date')(seenAt, 'MMM dd, yyyy')});
                    this.isSeen = true;
                }
            }
        },

        triggerShowSeenUsers: function triggerShowSeenUsers() {
            this.showSeenUsers = !this.showSeenUsers;
        },

        hideSeenUsers: function hideSeenUsers() {
            this.showSeenUsers = false;
        },

        shouldShowSeen: function shouldShowSeen() {
            return this.user.isVendor() && this.user.company._id === this.feedOwnerCompanyId;
        },
        
        shouldShowNewSeen: function shouldShowNewSeen() {
            var forceNewUi = this.moment('2023-06-15').isBefore(this.feedItem.created_at);
            return this.shouldShowSeen() && forceNewUi;
        },

        shouldShowOldSeen: function shouldShowOldSeen() {
            return this.shouldShowSeen() && !this.shouldShowNewSeen();
        },

        hasBeenSeen: function hasBeenSeen() {
            return this.isSeen && this.user.isVendor() && this.user.company._id === this.feedOwnerCompanyId;
        },

        isNotTracked: function isNotTracked() {
            return this.feedItem.item_origin && this.feedItem.item_origin !== 'honeybook';
        }

    });

    return {
        scope: {
            feedItem: '=feedItem',
            feedUsers: '=',
            feedOwnerCompanyId: '='
        },
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_seen_directive_template.html',
        controller: FeedItemSeenDirectiveController,
        controllerAs: 'feedItemSeenVm',
        bindToController: true
    };
};