 (function () {
    "use strict";


    // @ngInject
    function WorkspaceFilesManagerCtor($q,
                                       APIService, RepositoryService, PrintService, FeedService,
                                       Routes, ModelFactory, ForterHelperService, _, RescheduleProjectService, UIUtils) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.PrintService = PrintService;
        this.FeedService = FeedService;
        this.ForterHelperService = ForterHelperService;
        this._ = _;
        this.RescheduleProjectService = RescheduleProjectService;
        this.UIUtils = UIUtils;
    }


    Services.WorkspaceFilesManager = Class(Services.BaseModelManager, {

        constructor: WorkspaceFilesManagerCtor,

        /*****************************************
         * FILE ACTIONS
         ******************************************/

        newWorkspaceFile: function newWorkspaceFile() {
            return this.ModelFactory.newWorkspaceFileModel();
        },

        createFileDraft: function (workspaceFile, discardOldDraft) {
            var url = this.Routes.v2_create_workspace_file_draft_path(workspaceFile._id);
            return this.apiCreate(url, {project_date_simulation: this.RescheduleProjectService.getProjectData(), discard_old_draft: !!discardOldDraft}, workspaceFile, true);
        },

        discardFileDraft: function discardFileDraft(workspaceFile) {
            var url = this.Routes.v2_delete_workspace_file_draft_path(workspaceFile._id);
            return this.apiDelete(url, {}, workspaceFile, true);
        },

        acceptNewVersion: function acceptNewVersion(workspaceFile) {
            var data = {file_version_to_accept: workspaceFile.file_version};
            var url = this.Routes.v2_workspace_file_accept_new_version_path(workspaceFile._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        createSetPaymentInvoice: function createSetPaymentInvoice(workspace) {
            return this._createWorkspaceFile(workspace, 'direct_payment');
        },

        createProposal: function createProposal(workspace, baseFileId, templateId) {
            return this._createWorkspaceFile(workspace, 'proposal', templateId, baseFileId);
        },

        createAgreement: function createAgreement(workspace, baseFileId, templateId, extraParams) {
            return this._createWorkspaceFile(workspace, 'agreement', templateId, baseFileId, extraParams);
        },

        createInvoice: function createInvoice(workspace, baseFileId, templateId) {
            return this._createWorkspaceFile(workspace, 'invoice', templateId, baseFileId);
        },

        createQuestionnaire: function createQuestionnaire(workspace, baseFileId, templateId) {
            return this._createWorkspaceFile(workspace, 'questionnaire', templateId, baseFileId);
        },
        createBrochure: function createBrochure(workspace, baseFileId, templateId) {
            return this._createWorkspaceFile(workspace, 'brochure', templateId, baseFileId);
        },

        createTimeline: function createTimeline(workspace, extraData) {
            return this._createWorkspaceFile(workspace, 'timeline');
        },

        _createWorkspaceFile: function createWorkspaceFile(workspace, fileType, templateId, baseFileId, extraParams) {
            var workspaceFile = this.newWorkspaceFile();
            var url = this.Routes.v2_create_workspace_file_path(workspace._id);

            var data = {
                file_type: fileType,
                template_id: templateId,
                base_file_id: baseFileId
            };

            if (!!extraParams) {
                data = Object.assign(data, extraParams);
            }

            return this.apiCreate(url, data, workspaceFile, true);
        },

        createWorkspaceFileAndProject: function createWorkspaceFileAndProject(fileType, baseFileId, templateId, projectData) {
            var workspaceFile = this.newWorkspaceFile();
            var url = this.Routes.v2_create_project_with_file_path(fileType);

            var data = {
                event: projectData,
                template_id: templateId,
                base_file_id: baseFileId
            };

            return this.apiCreate(url, data, workspaceFile, true);
        },

        deleteWorkspaceFile: function deleteWorkspaceFile(workspaceFile) {
            var workspaceFileId = workspaceFile._id;
            var workspaceFileClass = workspaceFile.className();

            var self = this;
            var url = this.Routes.v2_delete_workspace_file_path(workspaceFile._id);
            var promise = this.apiDelete(url, null, workspaceFile, false);
            promise.then(function removeFileFromCache() {
                self.removeWorkspaceFileFromCache(workspaceFile);
            });

            return promise;
        },

        createTemplatePreviewFile: function createTemplatePreviewFile(fileType, templateId) {
            var workspaceFile = this.newWorkspaceFile();
            var url = this.Routes.v2_create_template_preview_file_path(templateId,fileType);

            return this.apiCreate(url, {}, workspaceFile, true).then(function() {
                workspaceFile.setFullyFetched();
                return workspaceFile;
            });
        },

        removeWorkspaceFileFromCache: function removeWorkspaceFileFromCache(workspaceFile) {
            this.RepositoryService.cacheDelete(workspaceFile);
        },

        deactivateWorkspaceFile: function deactivateWorkspaceFile(workspaceFile) {
            var url = this.Routes.v2_deactivate_workspace_file_path(workspaceFile._id);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        activateWorkspaceFile: function activateWorkspaceFile(workspaceFile) {
            var url = this.Routes.v2_activate_workspace_file_path(workspaceFile._id);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        newWorkspaceFiles: function newWorkspaceFiles() {
            return this.ModelFactory.newWorkspaceFilesCollection();
        },

        getWorkspaceFilesForUser: function getWorkspaceFilesForUser(user, showArchive) {
            var self = this;
            var workspaceFiles = this.ModelFactory.getWorkspaceFilesMasterCollection();

            showArchive = showArchive || false;
            var url = this.Routes.v2_user_workspace_files_path(user._id);
            var data = {show_archive: showArchive, perPage: 30};
            this.apiFetchCollection(url, data, workspaceFiles, true, null, true).then(
                function () {
                    self.gotFilesOnce = true;
                }
            );
            return workspaceFiles;
        },

        getWorkspaceFilesForMalkutUser: function getWorkspaceFilesForMalkutUser(user, showArchive) {
            var self = this;
            var workspaceFiles = this.ModelFactory.getWorkspaceFilesMasterCollection();

            showArchive = showArchive || false;
            var url = this.Routes.v2_user_workspace_files_malkut_path(user._id);
            var data = {show_archive: showArchive, perPage: 20, user_id: user._id};
            this.apiFetchCollection(url, data, workspaceFiles, true, null, true).then(
                function () {
                    self.gotFilesOnce = true;
                }
            );
            return workspaceFiles;
        },

        filterArchivedWorkspaceFiles: function filterArchivedWorkspaceFiles() {

            var self = this;
            var files = this.ModelFactory.getWorkspaceFilesMasterCollection();

            var filesToDelete = files.filter(function (file) {

                var activeState = file.active_state;
                if (activeState) {

                    if (file.__parent && typeof file.__parent.active_state !== 'undefined') {
                        activeState = file.__parent.active_state;
                    } else {
                        var wsId = file.couple_card_id || file.workspace_id;
                        if (!wsId && file.workspace) {
                            wsId = file.workspace._id;
                        }
                        var ws = self.RepositoryService.cacheGet('WorkspaceModel', wsId);
                        if (ws) {
                            activeState = ws.active_state;
                        } else {
                            activeState = false;
                        }
                    }
                }

                return !activeState;
            });

            for (var fileIndex = 0; fileIndex < filesToDelete.length; fileIndex++) {
                this.removeWorkspaceFileFromCache(filesToDelete[fileIndex]);
            }
            return this.ModelFactory.getWorkspaceFilesMasterCollection();
        },

        updateWorkspaceFileExternal: function updateWorkspaceFileExternal(workspaceFile) {
            this.RepositoryService.cachePut(workspaceFile,"WorkspaceFileModel", workspaceFile._id);
        },

        getWorkspaceFile: function getWorkspaceFile(workspaceFileId, returnPromise) {
            var workspaceFile = this.RepositoryService.cacheGet("WorkspaceFileModel", workspaceFileId);
            if (!workspaceFile) {
                workspaceFile = this.newWorkspaceFile();
                workspaceFile._id = workspaceFileId;
                this.RepositoryService.cachePut(workspaceFile);
            }

            var url = this.Routes.v2_workspace_file_path(workspaceFileId);

            var promise = this.apiFetch(url, {project_date_simulation: this.RescheduleProjectService.getProjectData()}, workspaceFile, true).then(function() {
                workspaceFile.setFullyFetched();
                return workspaceFile;
            });

            return returnPromise ? promise : workspaceFile;
        },

        getWorkspaceFileMembers: function getWorkspaceFileMembers(workspaceFile) {
            var url = this.Routes.v2_workspace_file_members_path(workspaceFile._id);
            return this.apiFetch(url, null, workspaceFile, true);
        },

        getWorkspaceFileVersion: function getWorkspaceFileVersion(workspaceFileId, version) {
            var _version = 0;
            if (angular.isNumber(version)) {
                _version = version.toString();
            } else if (angular.isString(version)) {
                _version = version;
            } else {
                _version = version.file_version;
            }

            var workspaceFile = this.newWorkspaceFile();
            workspaceFile._id = workspaceFileId;
            var url = this.Routes.v2_workspace_file_version_path(workspaceFileId, _version);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        getWorkspaceFileStatusData: function getWorkspaceFileStatusData(workspaceFileId){
            var workspaceFile = this.RepositoryService.cacheGet("WorkspaceFileModel", workspaceFileId);
            // we need all this data for goToFile in the service, to decide which view to show.

            if (!workspaceFile) {
                // workspace file is not in cache
                workspaceFile = this.newWorkspaceFile();
                workspaceFile._id = workspaceFileId;
                this.RepositoryService.cachePut(workspaceFile);
            }

            if (workspaceFile && workspaceFile.status &&
                workspaceFile.file_version_container &&
                workspaceFile.file_version_container.active_content &&
                workspaceFile.file_version_container.active_content.status_cd) {
                var deferred = this.$q.defer();
                var resp = {};
                resp.model = workspaceFile;
                deferred.resolve(resp);
                return deferred.promise;
            }

            var url = this.Routes.v2_workspace_file_status_path(workspaceFileId);
            return this.apiFetch(url, null, workspaceFile, true);
        },

        saveWorkspaceFile: function saveWorkspaceFile(workspaceFile) {
            var url = this.Routes.v2_update_workspace_file_path(workspaceFile._id);
            var data = {
                file_title: workspaceFile.file_title,
                header_title: workspaceFile.header_title,
                header_subtitle: workspaceFile.header_subtitle,
                header_icon: workspaceFile.header_icon

            };
            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        updateAutoExpiry: function updateAutoExpiry(workspaceFile) {
            var url = this.Routes.v2_update_workspace_file_auto_expiration_path(workspaceFile._id);
            var data = {auto_expiration: workspaceFile.auto_expiration};
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        deleteAutoExpiry: function updateAutoExpiry(workspaceFile) {
            var url = this.Routes.v2_delete_workspace_file_auto_expiration_path(workspaceFile._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updateStatus: function updateStatus(workspaceFile, status) {
            var url = this.Routes.v2_workspace_file_update_status_path(workspaceFile._id);
            var data = {status_cd: status};
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        calculateTax: function calculateTax(workspaceFile, type, tax, taxOrSvc) {
            var url = this.Routes.v2_workspace_file_calculate_tax_path(workspaceFile._id);
            return this.apiFetch(url, {tax_or_svc: taxOrSvc, tax_type: type, tax: tax});
        },

        updateAllowGratuity: function updateAllowGratuity(workspaceFile, allow) {
            var url = this.Routes.v2_workspace_file_update_allow_gratuity_path(workspaceFile._id);
            var data = {allow_gratuity: allow};
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateAcceptedPaymentMethod: function updateAcceptedPaymentMethod(workspaceFile, acceptedPaymentMethod) {
            var url = this.Routes.v2_workspace_file_update_accepted_payment_method_path(workspaceFile._id);
            var data = {accepted_payment_method: acceptedPaymentMethod};
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateAgreement: function updateAgreement(workspaceFile) {
            var url = this.Routes.v2_workspace_file_save_agreement_path(workspaceFile._id);
            var data = {project_date_simulation: this.RescheduleProjectService.getProjectData(), html_body: this.UIUtils.toBinaryBase64(workspaceFile.agreement.html_body)};
            var promise = this.apiUpdate(url, data, workspaceFile, true);
            promise.then(function updateAgreement(resp) {
                workspaceFile.mixinFrom({agreement: resp.data});
            });
            return promise;
        },

        updateAgreementWithFile: function updateAgreementWithFile(workspaceFile, agreementFileBinData){
            var url = this.Routes.v2_workspace_file_update_agreement_with_file_path(workspaceFile._id);
            var promise = this.apiUpdate(url, { 'file': agreementFileBinData }, workspaceFile, true);
            promise.then(function updateAgreement(resp) {
                workspaceFile.mixinFrom({agreement: resp.data});
            });
            return promise;
        },

        updateSignature: function updateSignature(workspaceFile, signature) {
            var data = signature.dataOnly();
            var url = this.Routes.v2_workspace_file_signature_path(workspaceFile._id, signature._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        changeSignatureSignatory: function changeSignatureSignatory(workspaceFile, signature, user_id) {
            var data = {
                admin_id: user_id
            }
            var url = this.Routes.v2_change_signature_signatory_path(workspaceFile._id, signature._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateSignatureDescription: function updateSignatureDescription(workspaceFile, signature) {
            var data = signature.dataOnly();
            var url = this.Routes.v2_workspace_file_signature_description_path(workspaceFile._id, signature._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        removeSignature: function removeSignature(workspaceFile, signature) {
            var url = this.Routes.v2_workspace_file_agreement_remove_signature_path(workspaceFile._id, signature._id);
            //there is no need to wait for response from the server to update the client model
            workspaceFile.agreement.contract_signatures.remove(signature);
            return this.apiDelete(url, {}, workspaceFile, true);
        },

        addSignature: function addSignature(workspaceFile, signee) {
            var url = this.Routes.v2_workspace_file_agreement_add_signature_path(workspaceFile._id);
            return this.apiCreate(url, {signee_id: signee? signee._id: null}, workspaceFile, true);
        },

        print: function print(workspaceFile, partsToPrint) {
            var d = new Date();
            var minutesFromUtc = d.getTimezoneOffset();

            var url = this.Routes.v2_workspace_files_print_path(workspaceFile._id, partsToPrint);
            var apiPromise = this.apiFetch(url, {file_version: workspaceFile.file_version, minutes_from_utc:minutesFromUtc}, null);

            return this.PrintService.print(apiPromise);
        },

        getItemsList: function getItemsList(userId, searchTerm, limit) {
            var url = this.Routes.v2_search_services_unified_path(userId);
            return this.apiFetch(url, {limit: limit, only_services: true, search_term: searchTerm});
        },

        /*****************************************
         * PAYMENTS ACTIONS
         ******************************************/

        markPaymentAsInstantPayout: function markPaymentAsInstantPayout(fileId, paymentId, state, source) {
            var url = this.Routes.v2_workspace_file_toggle_payment_as_instant_path(fileId, paymentId);
            return this.apiUpdate(url, {use_instant_payout: state, use_instant_payout_set_source: source});
        },

        allowChargedInstantPayout: function allowChargedInstantPayout(fileId, paymentId) {
            var url = this.Routes.v2_workspace_file_allow_charged_instant_payout_path(fileId, paymentId);
            return this.apiFetch(url, null, null, false);
        },

        markPaymentAsRefunded: function markPaymentAsRefunded(fileId, paymentId, amount) {
            var url = this.Routes.v2_workspace_file_mark_as_refunded_path(fileId, paymentId);
            return this.apiUpdate(url, {refunded_amount: amount});
        },

        togglePaymentScheduleType: function togglePaymentScheduleType(workspaceFile, isRecurring) {
            var url = this.Routes.v2_workspace_file_update_payment_schedule_type_path(workspaceFile._id);
            return this.apiUpdate(url, {activate_recurring: isRecurring}, workspaceFile, true);
        },

        updateRecurringPayment: function updateRecurringPayment(workspaceFile, startDate, endDate) {
            var url = this.Routes.v2_workspace_file_update_recurring_payment_path(workspaceFile._id);
            return this.apiUpdate(url, {start_date: startDate, end_date: endDate}, workspaceFile, true);
        },

        updateRecurringPaymentMethod: function updateRecurringPaymentMethod(workspaceFile, paymentMethodId) {
            var url = this.Routes.v2_workspace_file_update_recurring_payment_method_path(workspaceFile._id);
            return this.apiUpdate(url, {payment_method_id: paymentMethodId});
        },

        cancelRecurringPayment: function cancelRecurringPayment(workspaceFile) {
            var url = this.Routes.v2_workspace_file_cancel_recurring_payment_path(workspaceFile._id);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        getPayment: function getPayment(workspaceFileId, invoiceId) {
            var url = this.Routes.v2_workspace_file_get_payment_path(workspaceFileId, invoiceId);
            return this.apiFetch(url, null, null, false);
        },

        deletePayment: function deletePayment(workspaceFile, payment) {
            var url = this.Routes.v2_workspace_file_delete_payment_path(workspaceFile._id, payment._id);
            return this.apiDelete(url, {project_date_simulation: this.RescheduleProjectService.getProjectData()}, workspaceFile, true);
        },

        addPayment: function addPayment(workspaceFile) {
            var url = this.Routes.v2_workspace_file_add_payment_path(workspaceFile._id);
            return this.apiCreate(url, {project_date_simulation: this.RescheduleProjectService.getProjectData()}, workspaceFile, true);
        },

        updatePayment: function updatePayment(workspaceFile, payment) {
            var url = this.Routes.v2_workspace_file_update_payment_path(workspaceFile._id, payment._id);
            var data = payment.dataOnly();
            data.project_date_simulation = this.RescheduleProjectService.getProjectData();
            return this.apiUpdate(url, data, workspaceFile, true, null, 'waitForLast');
        },

        updateAutoPayment: function updateAutoPayment(workspaceFile, status) {
            var url = this.Routes.v2_workspace_file_update_auto_payment_path(workspaceFile._id);
            return this.apiUpdate(url, {status: status}, workspaceFile, true);
        },

        preValidateCharge: function preValidateCharge(workspaceFile, payment, paymentMethod, tip, activateAutoPayment, paidInPerson) {
            if (!angular.isNumber(tip)) {
                tip = 0;
            }

            var forterToken = this.ForterHelperService.getForterToken();

            var data = {
             payment_tip: tip,
             file_version: workspaceFile.file_version,
             activate_auto_payment: activateAutoPayment,
             paid_in_person: paidInPerson,
             forter_stamp: forterToken
            };

            var url = this.Routes.v2_workspace_file_pre_charge_validate_path(workspaceFile._id, payment._id, paymentMethod._id);
            return this.apiCreate(url, data, workspaceFile, true);
         },

        payPayment: function payPayment(workspaceFile, payment, paymentMethod, tip, activateAutoPayment, paidInPerson, activateRecurringPayment) {
            if (!angular.isNumber(tip)) {
                tip = 0;
            }

            var forterToken = this.ForterHelperService.getForterToken();

            var data = {
                payment_tip: tip,
                file_version: workspaceFile.file_version,
                activate_auto_payment: activateAutoPayment,
                activate_recurring_payment: activateRecurringPayment,
                paid_in_person: paidInPerson,
                forter_stamp: forterToken
            };

            var url = this.Routes.v2_workspace_file_payment_pay_path(workspaceFile._id, payment._id, paymentMethod._id);
            return this.apiCreate(url, data, workspaceFile, true);
        },

        printPaymentReceipt: function printPaymentReceipt(workspaceFile, payment) {

            var d = new Date();
            var minutesFromUtc = d.getTimezoneOffset();
            var url = this.Routes.v2_workspace_files_print_payment_path(workspaceFile._id, payment._id);

            var data = {minutes_from_utc:minutesFromUtc};
            if (!workspaceFile.isDraft()) {
                data.file_version = workspaceFile.file_version;
            }

            var apiPromise = this.apiFetch(url, data, null);

            return this.PrintService.print(apiPromise);
        },


        sendPaymentReminder: function sendPaymentReminder(workspaceFile, payment) {
            var url = this.Routes.v2_workspace_file_send_payment_reminder_path(workspaceFile._id, payment._id);
            return this.apiCreate(url, null, workspaceFile, true);

            // todo yona: need to handle emails!
        },

        createQuestion: function createQuestion(workspaceFile, question) {
            var url = this.Routes.v2_workspace_file_create_question_path(workspaceFile._id);
            return this.apiCreate(url, question, workspaceFile, true);
        },

        createEventQuestions: function createEventQuestions(workspaceFile) {
            var question = { connected_to_object: "event" };
            var url = this.Routes.v2_workspace_file_create_event_questions_path(workspaceFile._id);
            return this.apiCreate(url, question, workspaceFile, true);
        },

        updateQuestion: function updateQuestion(workspaceFile, question, user) {
            var data = question.dataOnly();
            var url = this.Routes.v2_workspace_file_update_question_path(workspaceFile._id, question._id);
            var promise = this.apiUpdate(url, data, question, true);
            promise.then(function updateWorkspaceFileLastEditedOn(resp) {
                if(workspaceFile.last_edited_by.user_id !== user._id) {
                    workspaceFile.last_edited_by.user_id = user._id;
                    workspaceFile.last_edited_by.user_full_name = user.full_name;
                }
                workspaceFile.last_edited_by.last_edited_on = new Date();
                workspaceFile.trigger('success', 'data fetched', resp.status);
            });
            return promise;
        },

        updateQuestionsOrder: function updateQuestionsOrder(workspaceFile) {

            var data = {questions_order: {}};
            workspaceFile.questionnaire.questions.forEach(function (question) {
                data.questions_order[question._id] = question.order;
            });
            var url = this.Routes.v2_workspace_file_update_questions_order_path(workspaceFile._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        deleteQuestion: function deleteQuestion(workspaceFile, question) {
            var url = this.Routes.v2_workspace_file_delete_question_path(workspaceFile._id, question._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        submitQuestionnaire: function submitQuestionnaire(workspaceFile){
            var url = this.Routes.v2_workspace_file_submit_question_path(workspaceFile._id);
            return this.apiUpdate(url, null, workspaceFile, true);
        },
        payToUser: function payToUser(workspaceFile, user) {
            var direct_payment = {
                amount: workspaceFile.payments_container.payments[0].amount,
                user_id: user._id,
                charge_description: "HoneyBook Referral"
            };

            var url = this.Routes.v2_workspace_send_payment_path(workspaceFile._id);
            return this.apiCreate(url, direct_payment, workspaceFile, true);
        },

        /**
         * sendFileToUser
         * @param {object} workspaceFile - the model of the workspace file
         * @param {object} user - the model of the user
         * @param {string} subject - the sup
         * @param {string} htmlBody - the body of the email
         * @param {integer} emailType - the type of the email to be sent
         * @param {boolean} isLastUser - is this the last email that we are sending email to. since the client is orchestrating this.
         * @param {object} additionalMetaData - additional data. this could include
         *      @param attachedFiles
         *      @param {object} additionalMetaData.pvIds - the preferred vendors Ids
         *      @param {object} additionalMetaData.pvl_notify_vendors - for pvl settings
         *      @param {boolean} additionalMetaData.justResend - used when we are not sending another file/file-version to the
         *      vendor but rather only resending the same email that was sent before
         *      @param {Array} additionalMetaData.past_email_ids - an array of past email objects that contains only their ids for example [{_id:xxxxxxx}, {_id:yyyyy}]
         *      the ids represents the id of the GenericMail that was used when sent to the user.
         * @returns {promise} of the api call
         */
        sendFileToUser: function sendFileToUser(workspaceFile, user, subject, htmlBody, emailType, isLastUser, additionalMetaData, attachedFiles, sendDelay) {
            if (typeof attachedFiles === 'undefined') {
                attachedFiles = {
                    generalFiles: [],
                    imageFiles: []
                };
            }
            var email = {
                subject: subject,
                user_id: user._id,
                html_body: htmlBody,
                email_type: emailType,
                general_files: attachedFiles.generalFiles,
                image_files: attachedFiles.imageFiles
            };

            if (isLastUser) {
                email["send_confirmation"] = true;

                if (additionalMetaData.pvlId) {
                    email.pvl_id = additionalMetaData.pvlId;
                }

            }

            // tbd - old file send send_link_to_couple not used on backend yet
            if (sendDelay) {
                email["scheduled_send_date"] = sendDelay;
            }


            if(additionalMetaData.justResend){
                email["just_resend"] = true;
            }

            if(additionalMetaData.past_emails){
                email["past_emails"] = additionalMetaData.past_emails;
            }

            if(additionalMetaData.clientLogin){
                email["ignore_feed"] = true;
            }

            if (user.recepient_manually_added) {
                email["recepient_manually_added"] = true;
            }

            var url = this.Routes.v2_workspace_send_email_path(workspaceFile._id);
            return this.apiCreate(url, email, workspaceFile, true);
        },

        updateProposalByTemplate: function updateProposalByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_select_proposal_template_path(workspaceFile._id, templateId);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        getProposalTemplateOrigin: function getProposalTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_proposal_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        updatePaymentsByTemplate: function updateProposalByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_select_payments_template_path(workspaceFile._id, templateId);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        getPaymentsTemplateOrigin: function getPaymentsTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_payments_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        updateAgreementByTemplate: function updateProposalByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_select_agreement_template_path(workspaceFile._id, templateId);
            var defer = this.$q.defer();
            var data = {project_date_simulation: this.RescheduleProjectService.getProjectData()};
            this.apiUpdate(url, data, workspaceFile, true)
                .then(function success(resp) {
                    defer.resolve(resp);
                    workspaceFile.trigger('fileTemplateChanged');
                })
                .catch(function fail(resp) {
                    defer.reject(resp);
                });

            return defer.promise;
        },

        getAgreementTemplateOrigin: function getAgreementTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_agreement_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        getQuestionnaireTemplateOrigin: function getQuestionnaireTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_questionnaire_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        updateQuestionnaireByTemplate: function updateQuestionnaireByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_select_questionnaire_template_path(workspaceFile._id, templateId);
            var defer = this.$q.defer();
            this.apiUpdate(url, null, workspaceFile, true)
                .then(function success(resp) {
                    defer.resolve(resp);
                    workspaceFile.trigger('fileTemplateChanged');
                })
                .catch(function fail(resp) {
                    defer.reject(resp);
                });

            return defer.promise;
        },

        getBrochureTemplateOrigin: function getBrochureTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_brochure_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        updateBrochureByTemplate: function updateBrochureByTemplate(workspaceFile, templateId, isNewBrochure) {

            // block apply if brochure type doesn't match (old vs new)
            if(isNewBrochure !== workspaceFile.isNewBrochure()) {
                return;
            }

            if(isNewBrochure) {
                return this.updateFileByTemplate(workspaceFile, templateId);
            } else {
                var url = this.Routes.v2_workspace_file_select_brochure_template_path(workspaceFile._id, templateId);

                // Story: when merging deleted embedded models into the main model, the returning 'null' value is not merged into the model,
                // resulting in not deleting the object. We need to delete it ourselves
                var deferred = this.$q.defer();
                this.apiUpdate(url, null, workspaceFile, true).then(function(resp) {

                    // If the template don't have a la carte, we need to delete it manually
                    if (resp.data.brochure_container.a_la_carte_proposal == null) {
                        workspaceFile.brochure_container.a_la_carte_proposal = null;
                    }

                    deferred.resolve(resp);
                });

                return deferred.promise;
            }
        },

        updateTimelineByTemplate: function updateTimelineByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_select_timeline_template_path(workspaceFile._id);
            return this.apiUpdate(url, {timeline_template_id: templateId}, workspaceFile, true);
        },

        getTimelineTemplateOrigin: function getTimelineTemplateOrigin(workspaceFile) {
            var url = this.Routes.v2_workspace_file_get_timeline_template_path(workspaceFile._id);
            this.apiFetch(url, null, workspaceFile, true);
            return workspaceFile;
        },

        updateInvoiceByTemplate: function updateInvoiceByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_set_invoice_by_template_path(workspaceFile._id, templateId);
            var defer = this.$q.defer();
            this.apiUpdate(url, null, workspaceFile, true)
                .then(function success(resp) {
                    defer.resolve(resp);
                    workspaceFile.trigger('fileTemplateChanged');
                })
                .catch(function fail(resp) {
                    defer.reject(resp);
                });

            return defer.promise;
        },

        updateProposalFileByTemplate: function updateProposalFileByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_set_proposal_file_by_template_path(workspaceFile._id, templateId);
            var defer = this.$q.defer();
            this.apiUpdate(url, null, workspaceFile, true)
                .then(function success(resp) {
                    defer.resolve(resp);
                    workspaceFile.trigger('fileTemplateChanged');
                })
                .catch(function fail(resp) {
                    defer.reject(resp);
                });

            return defer.promise;
        },

        setWorkspaceFileImage: function setWorkspaceFileImage(workspaceFile, image) {
            var url = this.Routes.v2_update_workspace_file_image_path(workspaceFile._id);
            return this.apiUpdate(url, image, workspaceFile, true);
        },

        setWorkspaceFileBillTo: function setWorkspaceFileImage(workspaceFile, billToUser) {
            var url = this.Routes.v2_update_workspace_file_header_bill_to_path(workspaceFile._id);
            billToUser = angular.isFunction(billToUser.dataOnly) ? billToUser.dataOnly() : billToUser;
            return this.apiUpdate(url, billToUser, workspaceFile, true);
        },

        deleteWorkspaceFileBillTo: function deleteWorkspaceFileBillTo(workspaceFile) {
            var url = this.Routes.v2_delete_workspace_file_header_bill_to_path(workspaceFile._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        setWorkspaceFileHeaderIcon: function setWorkspaceFileHeaderIcon(workspaceFile, image) {
            var url = this.Routes.v2_update_workspace_file_header_icon_path(workspaceFile._id);
            return this.apiUpdate(url, image, workspaceFile, true);
        },

        deleteWorkspaceFileHeaderIcon: function removeWorkspaceFileHeaderIcon(workspaceFile, image) {
            var url = this.Routes.v2_delete_workspace_file_header_icon_path(workspaceFile._id);
            return this.apiDelete(url, image, workspaceFile, true);
        },

        refundPayment: function refundPayment(file, payment) {
            var deferred = this.$q.defer();
            setTimeout(function () {
                deferred.resolve();
            }, 1000);
            return deferred.promise;
        },

        requestPayment: function requestPayment(workspaceFile, paymentId, data, isFlow) {
            if (!isFlow) {
                var url = this.Routes.v2_workspace_file_request_milestone_payment_path(workspaceFile._id, paymentId);
                return this.apiCreate(url, data, workspaceFile, true);
            } else {
                var url = this.Routes.v2_flow_request_milestone_payment_path(workspaceFile._id, paymentId);
                return this.apiCreate(url, data, workspaceFile, true);
            }
        },

        syncToQuickbooks: function syncToQuickbooks(workspaceFileId, forceInvoiceCreation) {
            var data = {force_invoice_creation: forceInvoiceCreation};
            var url = this.Routes.v2_sync_workspace_file_with_quickbooks_path(workspaceFileId);
            return this.apiCreate(url, data);
        },

        unsyncQbPayment: function unsyncQbPayment(workspaceFileId, paymentId) {
            var data = {payment_id: paymentId};
            var url = this.Routes.v2_unsync_payment_from_quickbooks_path(workspaceFileId);
            return this.apiCreate(url, data);
        },

        saveInvoiceAsTemplate: function saveInvoiceAsTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id, title: title};
            var url = this.Routes.v2_save_as_invoice_template_path(company.id());
            return this.apiCreate(url, templateData, company, true).then(function success(resp) {
               workspaceFile.origin_copy_id = this._findOriginId(company.invoices, title);
               return resp;
            }.bind(this));
        },

        saveProposalAsTemplate: function saveProposalAsTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id, title: title};
            var url = this.Routes.v2_save_as_proposal_template_path(company.id());
            return this.apiCreate(url, templateData, company, true).then(function success(resp) {
                workspaceFile.origin_copy_id = this._findOriginId(company.proposals, title);
                return resp;
            }.bind(this));
        },

        _findOriginId: function _findOriginId(templates, templateTitle) {
            var id = null;
            for (var i = 0; i < templates.length && !id; i++) {
                if (templates[i].title === templateTitle) {
                    id = templates[i]._id;
                }
            }

            return id;
        },

        /*****************************************
         * NEW BROCHURE API (Dynamic content file)
         ******************************************/

        updateFileByTemplate: function updateFileByTemplate(workspaceFile, templateId) {
            var url = this.Routes.v2_workspace_file_set_file_by_template_path(workspaceFile._id, templateId);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        createFileSection: function createFileSection(workspaceFile) {
            var url = this.Routes.v2_workspace_file_create_file_section_path(workspaceFile._id);
            return this.apiCreate(url, null, workspaceFile, true, null, true);
        },

        deleteFileSection: function deleteFileSection(workspaceFile, section) {
            var url = this.Routes.v2_workspace_file_delete_file_section_path(workspaceFile._id, section._id);
            return this.apiDelete(url, null, workspaceFile, true, null, true);
        },

        updateFileSection: function updateFileSection(workspaceFile, section) {
            var url = this.Routes.v2_workspace_file_update_file_section_path(workspaceFile._id, section._id);
            var data = { title: section.title};
            return this.apiUpdate(url, data, workspaceFile, true, null, true);
        },

        updateFileSectionsOrder: function updateFileSectionsOrder(workspaceFile, sectionsOrder) {
            var url = this.Routes.v2_workspace_file_update_file_sections_order_path(workspaceFile._id);
            return this.apiUpdate(url, { sectionsOrder: sectionsOrder }, workspaceFile, true, null, true);
        },

        createFileBlock: function createFileBlock(workspaceFile, section, params) {
            var url = this.Routes.v2_workspace_file_create_file_block_path(workspaceFile._id, section._id);
            return this.apiCreate(url, params, workspaceFile, true, null, true);
        },

        deleteFileBlock: function deleteFileBlock(workspaceFile, section, block) {
            var url = this.Routes.v2_workspace_file_delete_file_block_path(workspaceFile._id, section._id, block._id);
            return this.apiDelete(url, null, workspaceFile, true, null, true);
        },

        updateFileBlock: function updateFileBlock(workspaceFile, section, block, changedFields) {
            var url = this.Routes.v2_workspace_file_update_file_block_path(workspaceFile._id, section._id, block._id);
            var data = block.fieldsOnly(changedFields);
            return this.apiUpdate(url, data, workspaceFile, true, null, true);
        },

        updateFileSectionBlocksOrder: function updateFileSectionBlocksOrder(workspaceFile, section, blocksOrder) {
            var url = this.Routes.v2_workspace_file_update_file_section_blocks_order_path(workspaceFile._id, section._id);
            return this.apiUpdate(url, { blocksOrder: blocksOrder }, workspaceFile, true, null, true);
        },

        updateFileBlockComponent: function updateFileBlockComponent(workspaceFile, section, block, component, path, changedFields, params) {
            var data = component.fieldsOnly(changedFields, true);
            if (params) {
                data = this._.assign(data, params);
            }
            path = path.reverse().join("/");
            path = "?path=" + path;
            var url = this.Routes.v2_workspace_file_update_file_component_path(workspaceFile._id, section._id, block._id);
            return this.apiUpdate(url + path, data, workspaceFile, true, null, true);
        },

        answerQuestion: function answerQuestion(workspaceFile, section, block, component, path, changedFields, params) {
            var data = component.fieldsOnly(changedFields, true);
            if (params) {
                data = this._.assign(data, params);
            }
            path = path.reverse().join("/");
            path = "?path=" + path;
            var url = this.Routes.v2_workspace_file_update_file_answer_question_path(workspaceFile._id, section._id, block._id);
            return this.apiUpdate(url + path, data, workspaceFile, true);
        },

        submitNewBrochure: function submitNewBrochure(workspaceFile, clientNotes) {
            var url = this.Routes.v2_workspace_file_submit_brochure_path(workspaceFile._id);
            var data = { client_notes: clientNotes };
            return this.apiCreate(url, data, workspaceFile, true);
        },

        updateEnableClientNotes: function updateEnableClientNotes(workspaceFile, enable) {
            var url = this.Routes.v2_workspace_file_update_enable_client_notes_path(workspaceFile._id);
            var data = { enable_client_notes: enable };
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        sendFilePreview: function sendFilePreview(workspaceFile, subject, htmlBody, emailType, attachedFiles) {
            if (typeof attachedFiles === 'undefined') {
                attachedFiles = {
                    generalFiles: [],
                    imageFiles: []
                };
            }
            var email = {
                subject: subject,
                html_body: htmlBody,
                email_type: emailType,
                general_files: attachedFiles.generalFiles,
                image_files: attachedFiles.imageFiles
            };

            var url = this.Routes.v2_workspace_send_file_preview_path(workspaceFile._id);
            return this.apiCreate(url, email, workspaceFile, true);
        },

        getFormComponentPresets: function getFormComponentPresets() {
            var url = this.Routes.v2_get_form_component_presets_path();
            return this.apiFetch(url);
        },

        saveItemSuggestions: function saveItemSuggestions(workspaceFileId) {
            var url = this.Routes.v2_workspace_save_item_suggestions_path(workspaceFileId);
            return this.apiCreate(url);
        },

        applyVoucher: function applyVoucher(workspaceFile, voucherCode) {
            var url = this.Routes.v2_workspace_file_apply_voucher_path(workspaceFile._id, voucherCode);
            return this.apiUpdate(url, null, workspaceFile, true);
        },

        removeVoucher: function removeVoucher(workspaceFile) {
            var url = this.Routes.v2_workspace_file_remove_voucher_path(workspaceFile._id);
            return this.apiDelete(url, null, workspaceFile, true);
        }

    });
}());
