(function () {
    "use strict";

    Services.RollingWindowThrottleService = class RollingWindowThrottleService {

        // @ngInject
        constructor(_) {

            this._ = _;
            this.counters = {};

        }


        addEntryAndCheckIfLimitHit(key, options = null, resetIfLimitReached = true){

            const now = Date.now();
            this.counters[key] = this.counters[key] || {options: options ? options : {max: 3, windowTimeMS: 1000}, entries: []};

            let counter = this.counters[key];



            if(options){
                this._.extend(counter.options, options);
            }


            this.filterExpiredEntries(counter, now);
            counter.entries.push(now);


            if(counter.entries.length > counter.options.max){


                let ret = {key: key, options: options, count: counter.entries.length};
                if(resetIfLimitReached){
                    counter.entries = [];
                }

                return ret;
            }


            return null;
        }

        generateOptions(max = 3, windowTimeMS = 1000){

            return {max: max, windowTimeMS: windowTimeMS};
        }


        filterExpiredEntries(counter, now) {
            counter.entries = counter.entries.filter(entryTime => now - entryTime < counter.options.windowTimeMS);
        }

    };
}());