(function () {
    'use strict';
    Controllers.MigrateUserModalController = class MigrateUserModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, Routes, $injector, $translate, $modalInstance, $q, UsersManager, ModalService, moment,
                    PendingTasksManager, FlowsManager, FlowService, AnalyticsService, PopupMessageService, source, fileId, fileType, fileTitle, targetType, UiPersistenceService,
                    FeaturesService, OtamStatusService, FlowsBetaUserTypeService, AppConfigService, DateService, $state, AppStates) {
            super($scope, $injector, $translate);
            this.__objectType = 'MigrateUserModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;
            this.DateService = DateService;
            this.$q = $q;
            this.$state = $state;
            this.AppStates = AppStates;
            this.ModalService = ModalService;
            this.PendingTasksManager = PendingTasksManager;
            this.FlowsManager = FlowsManager;
            this.FlowService = FlowService;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.UsersManager = UsersManager;
            this.source = source;
            this.fileId = fileId;
            this.fileType = fileType;
            this.fileTitle = fileTitle;
            this.targetType = targetType;
            this.moment = moment;
            this.FeaturesService = FeaturesService;
            this.UiPersistenceService = UiPersistenceService;
            this.OtamStatusService = OtamStatusService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.AppConfigService = AppConfigService;
            this.Routes = Routes;
            const eventArgs = {
                source: this.source
            };
            if(this.targetType){
                eventArgs['type'] = this.targetType === 'flow_template' ? 'template' : 'instance';
            }
            this.AnalyticsService.trackLoad(this, 'migration source', eventArgs);
            this.currUser = UsersManager.getCurrUser();
            this.setStage();
            this.migrateUserToFlows();
        }

        setStage() {
            this.PendingTasksManager.joinPendingTask(
                'otam_migrate_user',
                this.onCompleteMigration.bind(this),
                this.onMigrationUpdate.bind(this),
                this.onPendingTaskThenCB.bind(this),
                this.onPendingTaskNoResponseCB.bind(this),
                this.onPendingTaskNoResponseCB.bind(this)
            );
        }

        onPendingTaskThenCB() {
            if(this.source !== 'old-builder'){
                this.OtamStatusService.stage = 'duringMigration';
            }
        }

        onPendingTaskNoResponseCB() {
            this.OtamStatusService.stage = 'duringMigration';
        }

        onCompleteMigration() {
            var self = this;
            this.OtamStatusService.stage = 'finishMigration';
            this.UsersManager.forceFetchCurrUser().then(()=> {
                self.FlowsBetaUserTypeService.refreshFlowBetaUserType();
                if(!self.fileId){
                    self.goToState(self.AppStates.root_core_navigation_my_templates);
                }
            });
            this.dismiss();
        }

        openDelayFlow(id) {
            setTimeout (function() {
                const options = {location: "replace", isGoToDefault : true};
                this.FlowService.goToFlow(id, 'edit', options);
                this.dismiss();
            }.bind(this), 10000);
        }

        onMigrationUpdate(progress) {
            this.OtamStatusService.stage = 'duringMigration';
            this.OtamStatusService.closeInProgressToast();
            if (progress) {
                if (progress.last_file) {
                    this.lastFile = progress.last_file;
                }

                this.migrationsCount = this.migrationsCount || progress.migrations_count;
                this.filesCount = this.filesCount || progress.files_count;
                const completionPercent = progress.finished_migrations_count / this.migrationsCount;

                this.completedFilesCount = Math.floor(completionPercent * this.filesCount);
            }
        }

        close(didChooseToMigrate, filesCount) {
            this.$modalInstance.close({
                didChooseToMigrate: didChooseToMigrate,
                filesCount: filesCount
            });
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }

        getFileTypeToMigrate(type) {
            return type === 'agreement' ? 'contract' : type;
        }

        migrateUserToFlows() {
            this.currUser.triggerOtamMigratedToFlows();
            this.lastFile = '';
            var first = true;
            var taskData = {
                user_id: this.currUser._id
            };
            if(this.fileId){
                taskData.file_id =  this.fileId;
                taskData.file_type =  this.getFileTypeToMigrate(this.fileType);
                taskData.file_title =  this.fileTitle;
                taskData.target_type =  this.targetType;
            }
            var promises = this.FlowsManager.otamMigrationForUser(this.PendingTasksManager.pendingTasksTypes.otamMigrateUser, taskData, this.openDelayFlow);
            promises.deferred.then(
                this.onCompleteMigration.bind(this),
                (error) => {
                    this.AnalyticsService.trackError(this, 'failed to migrate user to flows', error);
                    this.dismiss();
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Upgrade failed. Please refresh the page and try again.', null, 'ok');
                },
                (progress) => {
                    this.onMigrationUpdate(progress);
                    if(first){
                        promises.create.then((response) => {
                            if (response.data.flow_id) {
                                this.openDelayFlow(response.data.flow_id);
                            }
                        });
                    }

                    first = false;
                }
            );
        }
    };
}());
  