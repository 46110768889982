(function () {
    'use strict';

    class ContactFormOnboardingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UiPersistenceService, UsersManager, UIUtils) {
            super($scope, $injector);
            this.__objectType = 'ContactFormOnboardingController';

            this.showIntroPage = null;
            this.fromMigration = null;
            this.UIUtils = UIUtils;
            this.UiPersistenceService = UiPersistenceService;
            this.user = UsersManager.getCurrUser();
        }

        $onChanges(changes) {
            if (changes.isVisible && changes.isVisible.currentValue) {
                let onboardingData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormDynamicOnboarding, {});
                this.showIntroPage = !onboardingData.seenDynamicCFIntro;
                this.fromMigration = !!onboardingData.fromMigration;
            }
        }

        $onInit() {
        }

        showFirstName() {
            var capitalizedName = this.UIUtils.capitalize(this.user.first_name);
            return this.UIUtils.sanitize(capitalizedName);
        }

        onShowTutorial() {
            this.showIntroPage = false;
        }

        onSkipTour() {
            this.onCloseOnboarding();
        }

        onMoveToStep(nStep) {
            switch (nStep) {
                case 3:
                    this.showOnboardingSidebar({'menuItemType': 'fonts'});
                    break;
                case 4:
                    this.showOnboardingSidebar({'menuItemType': 'colors'});
                    break;
                case 5:
                    this.showOnboardingSidebar({'menuItemType': 'settings'});
                    break;
                default:
                    this.showOnboardingSidebar({'menuItemType': null});
            }
        }

    }

    Components.ContactFormOnboarding = {
        bindings: {
            onCloseOnboarding: '&',
            isVisible: '<',
            showOnboardingSidebar: '&',
        },
        controller: ContactFormOnboardingController,
        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/contact-form/contact-form-onboarding.html',
    };

}());
