(function () {
    'use strict';
    Controllers.TeamMembersOtamModalController = class TeamMembersOtamModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, $window, source, AnalyticsService) {
            super($scope, $injector, $translate);
            this.__objectType = 'TeamMembersOtamModalController';
            this.$modalInstance = $modalInstance;
            this.AnalyticsService = AnalyticsService;
            this.$window = $window;
            this.isFromSplashModal = source === 'splash';
        }

        openMicroSite() {
            this.$window.open("https://smartfiles.honeybook.com/",'_blank');
        }

        goToTemplates() {
            this.AnalyticsService.trackClick(this, 'see whats new', {});
            this.dismissModal();
            this.$state.go(this.AppStates.root_core_navigation_my_templates);
        }

        dismissModal(){
            this.$modalInstance.dismiss();
        }
    };
}());
