(function () {
    'use strict';

    // @ngInject
    Directives.JCrop = function JCrop($timeout, Enums) {

        return {
            restrict: 'E',
            scope: {
                imageUrl: '=imageUrl',
                coordinates: '=coordinates',
                aspectRatio: '=aspectRatio',
                showPreview: '=showPreview'
            },
            link: function JCropLink(scope, element) {
                if (!angular.isString(scope.imageUrl)) {
                    throw new Error('imageUrl must be set to a url string when loading');
                }

                var jcropAPI = null;
                var previewImage = null;
                var imgScaledWidth = null;
                var imgScaledHeight = null;
                var imgPreviewWidth = null;
                var imgPreviewHeight = null;

                scope.calcPreviewSize = function calcPreviewSize(newCoordinates) {
                    if (imgScaledWidth && imgScaledHeight) {
                        var rx = imgPreviewWidth / newCoordinates.w;
                        var ry = imgPreviewHeight / newCoordinates.h;

                        var xxxx = imgScaledWidth * rx;
                        var yyyy = imgScaledHeight * ry;

                        var previewW = Math.round(xxxx);
                        var previewH = Math.round(yyyy);
                        var previewX = Math.round(xxxx / imgScaledWidth * newCoordinates.x);
                        var previewY = Math.round(yyyy / imgScaledHeight * newCoordinates.y);

                        previewImage.css({
                            'width': previewW + 'px',
                            'height': previewH + 'px',
                            'max-width': previewW + 'px',
                            'max-height': previewH + 'px',
                            'margin-left': '-' + previewX + 'px',
                            'margin-top': '-' + previewY + 'px'
                        });
                    }
                };

                var jcropSettings = {
                    minSize: [50, 50],
                    onSelect: function onCropSelectionChanged(newCoordinates) {
                        scope.calcPreviewSize(newCoordinates);
                        $timeout(function cropChangedScopeApply() {
                            scope.coordinates[Enums.COORD_TYPE.X0] = newCoordinates.x;
                            scope.coordinates[Enums.COORD_TYPE.Y0] = newCoordinates.y;
                            scope.coordinates[Enums.COORD_TYPE.X1] = newCoordinates.x2;
                            scope.coordinates[Enums.COORD_TYPE.Y1] = newCoordinates.y2;
                            scope.coordinates[Enums.COORD_TYPE.W] = newCoordinates.w;
                            scope.coordinates[Enums.COORD_TYPE.H] = newCoordinates.h;
                        });
                    },
                    onChange: scope.calcPreviewSize,
                    setSelect: scope.coordinates,
                    bgColor: ''
                };

                if(scope.aspectRatio !== -1) {
                    jcropSettings.aspectRatio = scope.aspectRatio;
                }

                scope.$watch('imageUrl', function imageUrlWatch(newVal) {
                    if (newVal) {
                        // apply new coordinates
                        jcropSettings.setSelect = scope.coordinates;
                        scope.loadJCrop();
                    }
                });

                scope.$watch('coordinates', function imageUrlWatch(newVal) {
                    if (newVal && jcropAPI) {
                        jcropAPI.setSelect(newVal);
                    }
                });

                scope.loadJCrop = function loadJCrop() {
                    if (jcropAPI !== null) {
                        // this is not the first load
                        jcropAPI.destroy();
                    }
                    element.html('');

                    var img = angular.element('<img>');
                    img.addClass('hb-jcrop-img');

                    element.addClass('hb-jcrop');
                    element.append(img);

                    var previewDiv = angular.element('<div></div>');
                    previewDiv.addClass('hb-jcrop-img-preview-div');

                    previewImage = angular.element('<img>');
                    previewImage.addClass('hb-jcrop-img-preview');

                    if (scope.showPreview) {
                        // not showing preview...
                        // previewDiv.append(previewImage);
                        // element.append(previewDiv);
                    }

                    // activate jCrop when the image is ready
                    img.bind('load.jcrop.honeybook', function onImageLoad() {
                        img.unbind('.jcrop.honeybook');

                        previewImage.attr('src', scope.imageUrl);

                        imgScaledWidth = img[0].width;
                        imgScaledHeight = img[0].height;
                        imgPreviewWidth = previewDiv.width();
                        imgPreviewHeight = previewDiv.height();

                        // Yeah... Yeah... I know... I'm using setTimeout. Get over it!!!
                        // Trying to solve loading jCrop loading issues.
                        setTimeout(function loadTimeout() {
                            img.Jcrop(jcropSettings, function jcropReady() {
                                jcropAPI = this;
                            });
                        });
                    });
                    img.attr('src', scope.imageUrl);
                };
            }
        };
    };
}());
