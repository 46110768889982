
(function () {
    'use strict';
    class MeetingDateField extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, moment, UIUtils, AnalyticsService, DeviceService) {
            super($scope, $injector);
            this.__objectType = 'MeetingDateField';

            this.moment = moment;

            this.UIUtils = UIUtils;
            this.AnalyticsService = AnalyticsService;

            this.DATE_FORMAT = 'YYYY-MM-DD';
            this.TIME_FORMAT = 'h:mm A';

            this.isMobileDevice = DeviceService.isIOS() || DeviceService.isAndroid();


            this._initDate();
        }

        _initDate() {
            this.date = {
                startDate: this.startDate,
                endDate: this.endDate,
                startTime: this.startTime,
                endTime: this.endTime
            };
        }

        onDateTimeChange(fieldName) {
            this._clearError();
            if(this[fieldName]) {
                if(fieldName.includes('Date')) {
                    this[fieldName] = this.moment(this[fieldName]).format(this.DATE_FORMAT);
                }
                if(this._isValidDate()) {
                    this.date[fieldName] = this[fieldName];
                    this._sendAnalytics(fieldName);
                } else {
                    this._revertDate(fieldName);
                }
            }
        }

        keyDownDate(event, fieldName) {
            this._clearError();
            if (event.keyCode !== 8 && event.keyCode !== 46) {
                event.preventDefault();
            } else {
                this._revertDate(fieldName);
            }
        }

        onBlur(type) {
            if(this[type]) {
                if(type.includes('Date')) {
                    this[type] = this.moment(this[type]).format(this.DATE_FORMAT);
                }
                if(this._isValidDate()) {
                    this.date[type] = this[type];
                } else {
                    this._revertDate(type);
                }
            } else {
                this._revertDate(type);
            }
        }

        _isValidDate() {
            if((!this.isAllDay) && this.moment(this.startDate).isSame(this.endDate)) {
                const startDate = this._formatTime(this.startDate, this.startTime);
                const endDate = this._formatTime(this.startDate, this.endTime);
                return this.moment(startDate).isBefore(endDate);
            } else {
                return this.moment(this.startDate).isSameOrBefore(this.endDate);
            }
        }

        _formatTime(date, time) {
            return this.moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm a').format();
        }

        toggleAllDay() {
            if(!this.isAllDay) {
                // take the half hour closest to now
                const now = this.moment();
                let startDateTime = null;
                if(now.minutes() >= 30) {
                    startDateTime = now.add(30, 'minutes').startOf('hour');
                    this.startTime = startDateTime.format(this.TIME_FORMAT);
                } else {
                    startDateTime = now.startOf('hour').add(30, 'minutes');
                    this.startTime = startDateTime.format(this.TIME_FORMAT);
                }
                this.endTime = this.moment(startDateTime).add(30, 'minutes').format(this.TIME_FORMAT);
            }
            this._sendAnalytics('all day');
        }

        // toggleMarkAsBusy() {
        //     this.shouldMarkAsBusy = !this.shouldMarkAsBusy;
        //     this.sendAnalytics('mark as busy');
        // }


        // setSelectedTimezone(timezone) {
        //     this.timezone = timezone;
        //     this.sendAnalytics('timezone');
        // }

        _revertDate(type) {
            if(this[type]) {
                // start date is after end date
                if(type === 'startDate') {
                    this.date.endDate = this.startDate;
                    this.endDate = this.date.endDate;
                } else if(type === 'startTime') {
                    // startTime >= endTime
                    this.date.startTime = this.startTime;
                    this.date["endTime"] = this.UIUtils.increaseTimeBy(this._formatTime(this.startDate, this.startTime),
                        'minutes', 30);
                    this.endTime = this.date.endTime;
                } else {
                    this[type] = this.date[type];
                }
            } else {
                this[type] = this.date[type];
            }

        }

        _clearError() {
            this.showError = false;
        }

        _sendAnalytics(fieldName) {
            this.AnalyticsService.track(this, [this.analyticsEventPrefix, fieldName].join(' '), {source: this.analyticsEventSource});
        }
    }

    Components.MeetingDateField = {
        bindings: {
            startDate: '=',
            startTime: '=',
            endTime: '=',
            endDate: '=',
            shouldMarkAsBusy: '=',
            isAllDay: '=',
            showError: '<?',
            errorMessage: '<?',
            analyticsEventSource: '@',
            analyticsEventPrefix: '@'
        },
        controller: MeetingDateField,
        name: 'hbMeetingDateField',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_date_field/meeting_date_field.html'
    };
}());