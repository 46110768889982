/**
 * Created by dvircohen on 16/05/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function ConfirmBrochureSelectionControllerCtor($scope, $injector, $modalInstance, AnalyticsService, companyName) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfirmBrochureSelectionController';
        AnalyticsService.trackPageView(this, 'ConfirmBrochureSelectionView');
        
        this.$modalInstance = $modalInstance;
        this.companyName = companyName;
    }

    Controllers.ConfirmBrochureSelectionController = Class(Controllers.BaseController, {
        constructor: ConfirmBrochureSelectionControllerCtor,
        
        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },
        
        confirmBrochureSelection: function confirmBrochureSelection() {
            this.$modalInstance.close();   
        }
    });
}());
