/**
 * The hb-pipeline-bulk-actions component
 */


(function () {
    'use strict';

    class PipelineBulkActionsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, FeaturesService)  {

            super($scope, $injector);
            this.__objectType = 'PipelineBulkActionsController';

            this.FeaturesService = FeaturesService;
            this.currUser = UsersManager.getCurrUser();
        }

    }

    Components.PipelineBulkActions = {
        bindings: {
            onArchive: '&',
            showArchive: '<',
            onDelete: '&',
            onMove: '&',
            onSendMail: '&',
            toggleBulkActions: '&',
            toggleSelectAll: '&',
            allWorkspacesSelected: '<',
            company: '<'
        },
        controller: PipelineBulkActionsController,
        templateUrl: 'angular/app/modules/core/features/pipeline/pipeline_bulk_actions/pipeline_bulk_actions_template.html',
    };

}());
