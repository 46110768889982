// @ngInject
Directives.CompareToDirective = function CompareToDirective($parse) {
    return {
        require: "ngModel",

        link: function(scope, element, attributes, ngModel) {
            var self = this;
            this.currScope = scope;

            // For DOM -> model validation
            ngModel.$parsers.unshift(function(modelValue) {
                var value = $parse(attributes['hbCompareTo'])(self.currScope);
                var valid = value === modelValue;
                ngModel.$setValidity('compareto', valid);
                return valid ? modelValue : false;
            });

            // For model -> DOM validation
            ngModel.$validators.compareto = function(modelValue) {
                var value = $parse(attributes['hbCompareTo'])(self.currScope);
                return modelValue === value;
            };

            scope.$watch(attributes['hbCompareTo'], function() {
                ngModel.$validate();
            }, true);
        }
    };
};
