(function () {
    "use strict";

    // @ngInject
    function NewAddContactControllerCtor($scope, $modalInstance, $injector, $q, $translate, ContactsManager, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, FeaturesService, SearchablesManager, ValidationsManager, ContactViewService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager, ContactViewService);
        this.__objectType = 'NewAddContactController';

        this.ContactsManager = ContactsManager;
        this.FeaturesService = FeaturesService;
        this.ContactViewService = ContactViewService;
        this.$q = $q;

        this.titleText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._TITLE_");
        this.subTitleText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._SUBTITLE_");
        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._ADD_");
        this.isVendor = false;
        this.isClient = true;
        this.isVendorEditDisabled = false;
        this.shouldShowUserType = false;

        this.isSuggestFromGmailContacts = true;
        this.isSuggestFromDirectory = false;
        this.shouldShowBusinessInfoOnBottom = true;
    }

    Controllers.NewAddContactController = Class(Controllers.NewAddUserController, {

        constructor: NewAddContactControllerCtor,

        saveUser: function saveUser() {
            var defer = this.$q.defer();
            var user = this.user;


            user.system_user_type = 'client';
            this.isVendor = false;
            this.isClient = true;

            var contact = this.ContactsManager.convertUserToContact(user);
            if (!user.isVendor) {
                contact.user.company = null;
            }

            this.ContactsManager.saveContact(contact)
                .then(function saveContactSuccess(resp) {
                    defer.resolve(resp.data);
                    if (this.isClient) {
                        this.ContactViewService.openContactView(contact._id, 'add new contact');
                    }
                }.bind(this))
                .catch(function saveContactError(resp) {
                    defer.reject(resp);
                }.bind(this));
            return defer.promise;
        },


        shouldShowWillInviteFooter: function () {
            return false;
        }
    });
}());

