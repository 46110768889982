(function () {
    'use strict';

    Directives.ImageReposition = function ImageCarouselReposition() {

        // @ngInject
        function ImageRepositionControllerFunc($scope, $timeout, ImagesManager, $log,
                                                        AnalyticsService, ModalService, ImageService) {
            this.$log = $log;
            this.ModalService = ModalService;
            this.ImagesManager = ImagesManager;
            this.ImageService = ImageService;

            // set image ratio
            this.imageDisplayRatio = parseFloat(this.imageRatio) || 2.5;
            this.showPreviewWhenCroppingDisplay = (this.showPreviewWhenCropping === 'true');


            // Listen to an event that opens the edit modal
            $scope.$on('triggerProjectHeader', function () {
                this.chooseCoverImage();
            }.bind(this));


            this.setDomElement = function setDomElement(elem) {
                this.$element = elem;
            };


            this.checkLoad = function checkLoad() {
                // if (!this.loaded) {
                //     if (this.images) {
                //         if (this.checkImagesLoad() && this.checkSelectedImageLoad()) {
                //             this.loaded = true;
                //             this._load();
                //         }
                //     } else if (this.hideButtons && this.checkSelectedImageLoad()) { // readonly mode
                //         this.loaded = true;
                //         this._load();
                //     }
                //
                // }
            };

            this.checkSelectedImageLoad = function checkSelectedImageLoad() {
                return !!this.selectedImage && !!this.selectedImage._id;
            };



            this.getSelectedImageElement = function getSelectedImageElement() {
                return this.$element.find('.reposition img');
            };

            this.getImageContainerElement = function getImageContainerElement() {
                return this.$element;
            };

            this.chooseCoverImage = function chooseCoverImage() {
                if (this.changingImage) {
                    $timeout(chooseCoverImage.bind(this));
                    return;
                }

                var options = {
                    selectedImage: this.selectedImage,
                    imageDisplayRatio: this.imageDisplayRatio,
                    showPreviewWhenCroppingDisplay: this.showPreviewWhenCroppingDisplay,
                    skipSavingToServer: this.skipSavingToServer,
                    title: 'MEDIA_MODAL.TITLE._EDIT_PROJECT_IMAGE_',
                    model: ''
                };

                this.ImageService.chooseImage(options).then(
                    function success(newImage) {
                        this.selectedImage = newImage;
                        $scope.$applyAsync(function selectedImageChange() {
                            this.imageChanged({type: 'reposition', selectedImage: this.selectedImage});
                        }.bind(this));

                    }.bind(this)
                );

                //make that modal go.
                //this.ModalService.openCropImageModal(this.selectedImage, this.imageDisplayRatio, this.showPreviewWhenCroppingDisplay).then(
                // this.ModalService.openAttachAssetModal(true, true, true, "Crop", false, false, this.selectedImage, this.imageDisplayRatio, this.showPreviewWhenCroppingDisplay, false, "image/*").then(
                //
                //     function success(newImage) {
                //
                //         if(this.skipSavingToServer){
                //
                //             this.selectedImage = newImage;
                //             $scope.$applyAsync(function selectedImageChange() {
                //                 this.imageChanged({type: 'reposition', selectedImage: this.selectedImage});
                //             }.bind(this));
                //
                //         }else{
                //             this.ImagesManager.saveImageReposition(newImage)
                //                 .then(function success(res) {
                //                     res.data.forEach(function readResult(image) {
                //                         if (image._type === 'CoverImage') {
                //                             this.ImagesManager.updateCoverImageInCompany(image);
                //                         } else if (image._id === newImage._id) {
                //                             //extend the image we have with the image that was returned from the last API
                //                             angular.extend(newImage, image);
                //                         }
                //
                //                     }.bind(this));
                //
                //                     this.selectedImage = newImage;
                //                     $scope.$applyAsync(function selectedImageChange() {
                //                         // AnalyticsService.track(this, "request to change brochure image. Image ID: " + this.selectedImage._id);
                //                         this.imageChanged({type: 'reposition', selectedImage: this.selectedImage});
                //                     }.bind(this));
                //                 }.bind(this))
                //
                //                 .catch(function (err) {
                //                     this.$log.error('Error while trying to save image reposition', err);
                //                 }.bind(this));
                //         }
                //
                //     }.bind(this)
                // );
            };

            /////////////////////
            // Controller Code //
            /////////////////////

            if (this.checkSelectedImageLoad()) {
                this.checkLoad();
            } else {
                var unwatchSelectedImage = $scope.$watch('imageRepositionVm.selectedImage', function imagesFetched(newVal) {
                    if (this.checkSelectedImageLoad()) {
                        unwatchSelectedImage();
                        this.checkLoad();
                    }
                }.bind(this));
            }
        }


        function ImageRepositionLink($scope, $element) {
            $scope.imageRepositionVm.setDomElement($element);
        }

        var ImageRepositionController = Class(Controllers.BaseController, {
            constructor: ImageRepositionControllerFunc
        });


        return {
            restrict: 'E',
            scope: {
                selectedImage: '=selectedImage',
                imageChanged: '&imageChanged',
                hideButtons: '=hideButtons',
                imageRatio: '@imageRatio',
                showPreviewWhenCropping: '@showPreviewWhenCropping',
                variableHeightImg: '=?variableHeightImg',
                skipSavingToServer: '=?skipSavingToServer',
                analyticsSource: '@analyticsSource',
            },
            transclude: true,
            templateUrl: 'angular/app/modules/common/ui_components/image/image_reposition_template.html',
            controller: ImageRepositionController,
            controllerAs: 'imageRepositionVm',
            bindToController: true,
            link: ImageRepositionLink
        };
    };
}());
