Directives.LoginMessageBannerDirective = function LoginMessageBannerDirective() {

    // @ngInject
    function LoginMessageBannerDirectiveControllerFunc($scope, $injector, $translate, $state) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginMessageBannerDirectiveController';

        this.$state = $state;
        this.$translate = $translate;
    }

    var LoginMessageBannerDirectiveController = Class(Controllers.BaseController, {
        constructor: LoginMessageBannerDirectiveControllerFunc,

        onDismiss: function onDismiss() {
            this.onMessageDismiss && this.onMessageDismiss();
        }
    });

    return {
        scope: {
            onMessageDismiss: '&',
            title: '@',
            body: '@'
        },
        templateUrl: 'angular/app/modules/core/features/login/login_message_banner/login_message_banner_directive_template.html',
        controller: LoginMessageBannerDirectiveController,
        controllerAs: 'loginMessageBannerVm',
        bindToController: true
    };
};