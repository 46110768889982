/**
 * Created by brianschroer on 2/4/16.
 */
(function () {
    'use strict';

    Directives.PreviewFileToggleDirective = function PreviewFileToggleDirective() {
        // @ngInject
        function PreviewFileToggleDirectiveControllerCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'PreviewFileToggleDirectiveController';
        }

        var PreviewFileToggleDirectiveController = Class(Controllers.BaseController, {
            constructor: PreviewFileToggleDirectiveControllerCtor
        });


        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace_file/preview_file_toggle_directive/preview_file_toggle_directive_template.html',
            controller: PreviewFileToggleDirectiveController,
            controllerAs: 'previewFileToggleVm',
            bindToController: true,
            scope: {
                editableFunc: '&',
                isOwnerMode: '=',
                isPreviewMode: '=',
                updateFunc: '&',
                isSendFileTest: '<'
            }
        };
    };

}());