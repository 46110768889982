(function () {
    "use strict";


    // @ngInject
    function EmailPreviewManagerCtor($q, APIService, Routes, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }


    Services.EmailPreviewManager = Class(Services.BaseModelManager, {

        constructor: EmailPreviewManagerCtor,

        getRescheduleProjectEmailPreview: function getRescheduleProjectEmailPreview(recipient, subject, htmlBody, workspaceId, fileIds) {
            var email_ctx = {
                subject: subject,
                user_id: recipient._id,
                html_body: htmlBody,
                workspace_id: workspaceId,
                file_ids: fileIds
            };

            var request_body = {
                name: 'reschedule_project',
                ctx_params: email_ctx
            };

            var url = this.Routes.v2_get_email_preview_path();
            return this.apiCreate(url, request_body);
        },

        getWorkspaceFileEmailPreview: function(workspaceFile, recipient, subject, htmlBody, emailType, attachedFiles) {
            var email_ctx = {
                subject: subject,
                user_id: recipient._id,
                html_body: htmlBody,
                email_type: emailType,
                workspace_file_id: workspaceFile._id,
                general_files: attachedFiles.generalFiles,
                image_files: attachedFiles.imageFiles
            };

            var request_body = {
                name: 'send_workspace_file',

                ctx_params: email_ctx
            };

            var url = this.Routes.v2_get_email_preview_path();
            return this.apiCreate(url, request_body);
        }

    });
}());
