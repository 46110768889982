(function () {
    'use strict';

    class MobileFooterViewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, ContactFormService, TemplatesViewService, ModalService, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'MobileFooterViewController';
            this.ContactFormService = ContactFormService;
            this.TemplatesViewService = TemplatesViewService;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;

            this.user = UsersManager.getCurrUser();
            this.showCreateFileFromTemplateButton = TemplatesViewService.shouldShowCreateFileButton();
        }

        triggerAside() {
            this.triggerAsideDrawer();
        }

        createfileFromTemplate() {
            this.ModalService.openStartFromFileWizardModal({
                type: this.TemplatesViewService.currentModel.name,
                create_file_from_templates: true,
                template_id: this.TemplatesViewService.currentTemplate._id
            });
        }


        previewContactForm() {
            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                this.gettingPreviewCode = true;
                this.ContactFormService.openContactFormPreview(this.TemplatesViewService.currentTemplate).then(function () {
                    this.gettingPreviewCode = false
                }.bind(this));
            }
        }


        openEmbedCodeModal() {
            this.ContactFormService.openEmbedCodeModal(this.primaryCtaButtonType, this.TemplatesViewService.currentTemplate);
        }

        addToWebSiteClick() {
            this.isActionRunning = true;

            this.ContactFormService.publish(false, true, this.TemplatesViewService.currentTemplate).then(function finishedAddToWebSite() {
                this.isActionRunning = false;
            }.bind(this)).catch(function() {
                this.isActionRunning = false;
            }.bind(this));
        }

        publishChangesClick() {
            this.openEmbedCodeModal();
        }

        runAction() {
            this.primaryCtaButtonType = this.TemplatesViewService.currentTemplate.getContactFormState();
            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                switch (this.primaryCtaButtonType) {
                    case 'add_to_web_site':
                        this.addToWebSiteClick();
                        this.AnalyticsService.trackClick(this, "add_to_web_site_contact_form_click");
                        break;
                    case 'embed_code':
                        this.openEmbedCodeModal();
                        this.AnalyticsService.trackClick(this, "embed_code_contact_form_click");
                        break;
                    case 'publish_changes':
                        this.publishChangesClick();
                        this.AnalyticsService.trackClick(this, "publish_changes_contact_form_click", {app_path: 'header'});
                        break;
                    default:
                        break;
                }
            }
        }



    }

    Components.MobileFooterView = {
        bindings: {
            triggerAsideDrawer: '&',
            show:'@'
        },
        transclude: true,
        controller: MobileFooterViewController,
        name : 'hbMobileFooter',
        templateUrl: 'angular/app/modules/common/ui_components/mobile_footer/mobile_footer_component.html',
    };

}());
