// @ngInject

Models.EmailModel = function EmailModel(BaseModel) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'EmailModel';

            this.cachable = false;
            this.hasEmptyClientFields = false;
            this.hasClientFields = false;
            this.isBeingDeleted = false;
        },

        afterMixingFrom: function afterMixingFrom() {
        },

        updateEmail: function updateEmail(callingScope) {
            return this.__parent.updateEmail(this, callingScope);
        },

        deleteEmail: function deleteEmail() {
            return this.__parent.deleteEmail(this);
        },

        hasAttachments: function hasAttachments() {
            return this.countAttachments() > 0;
        },

        countAttachments: function countAttachments() {
            var count = 0;
            if (this.image_attachments) {
                count += this.image_attachments.length;
            }
            if (this.file_attachments) {
                count += this.file_attachments.length;
            }
            return count;
        }
    });
};

