
(function () {
    "use strict";

    // @ngInject
    function SelfOnboardingManagerCtor($q, APIService, Routes, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }

    Services.SelfOnboardingManager = Class(Services.BaseModelManager, {
        constructor: SelfOnboardingManagerCtor,

        setStepComplete: function setStepComplete(user, step, data) {
            var url = this.Routes.v2_set_self_onboarding_step_complete_path(user._id, step);

            return this.apiUpdate(url, data, user, true);
        },

        getCurrStep: function getCurrStep(user) {
            var url = this.Routes.v2_get_self_onboarding_step_path(user._id);

            return this.apiFetch(url, null, user, true);
        },

        getAtwsData: function getAtwsData(user) {
            var url = this.Routes.v2_get_atws_data_path(user._id);

            return this.apiFetch(url, null, user, true);
        },

        createOnboardingInvoiceTemplate: function createOnboardingInvoiceTemplate() {
            var url = this.Routes.v2_create_onboarding_invoice_template_path();
            return this.apiCreate(url);
        },

        createOnboardingProposalTemplate: function createOnboardingProposalTemplate() {
            var url = this.Routes.v2_create_onboarding_proposal_template_path();
            return this.apiCreate(url);
        },

        getOnboardingSequence: function getOnboardingSequence(user) {
            var url = this.Routes.v2_onboarding_sequence_path(user._id);
            return this.apiFetch(url);
        },

        completeOnboardingStep: function completeOnboardingStep(user, step) {
            var data = {};
            var url = this.Routes.v2_complete_onboarding_step_path(user._id, step.key);
            return this.apiCreate(url, data);
        },

        updateOnboardingData: function updateOnboardingData(user, data) {
            var url = this.Routes.v2_update_onboarding_data_path(user._id);
            return this.apiUpdate(url, data, user, true);
        },

        getOnboardingFlowTemplates: function getOnboardingFlowTemplates(user, service_type) {
            var url = this.Routes.v2_get_onboarding_flow_templates_path(user._id);
            return this.apiFetch(url, {service_type: service_type});
        },

        updateOnboardingFlowTemplate: function updateOnboardingFlowTemplate(user, template_id, selected_services) {
            var url = this.Routes.v2_update_onboarding_flow_template_path(user._id);
            return this.apiUpdate(url, {flow_template_id: template_id, selected_services: selected_services}, user, true);
        },

        createSmafWithServices: function createSmafWithServices(user, template_id, selectedServices, projectData) {
            var url = this.Routes.v2_create_smaf_with_services_path(user._id);
            var data = {
                flow_template_id: template_id,
                event: projectData,
                selected_services: selectedServices
            };
            return this.apiCreate(url, data, user, true);
        },

        importOnboardingFlowTemplate: function importOnboardingFlowTemplate(user, filters) {
            var data = {
                filters: filters
            };
            var url = this.Routes.v2_import_onboarding_flow_template_path(user._id);
            return this.apiUpdate(url, data, user, false);
        }

    });
}());
