/**
 * Created by saratheneale on 07/05/16.
 *
 * TODO(sean): Separate the collision list and the containing
 * modal/tooltip into separate components.
 */
(function () {
    'use strict';
    Directives.DateCollisionDirective = function DateCollisionDirective() {


        // @ngInject
        function DateCollisionDirectiveControllerFunc($state, $scope, $injector, $translate, $window, AnalyticsService,
                                                      DeviceService, EventService, moment) {

            this.constructor.$super.call(this, $scope, $injector);

            this.moment = moment;
            this.$state = $state;
            this.$window = $window;
            this.$translate = $translate;
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.EventService = EventService;
            this.AnalyticsService.trackPageView(this, 'date collision popover');
            this.headerMessage = this.$translate.instant('PROJECT.CONFLICTS._HEADER_',
                {events_count: this.collisionEvents.length, plural: this.collisionEvents.length > 1 ? 's' : ''});
        }

        var DateCollisionDirectiveController = Class(Controllers.BaseController, {
            constructor: DateCollisionDirectiveControllerFunc,
            closeModal: function closeModal(){
                this.modalOpen = false;
            },
            onEventClicked: function onEventClicked(event) {
                // navigate to the right place according to the persistency
                var stateToGoTo = this.EventService.getEventDefaultState(event._id);

                if (this.openCollisionInNewTab) {
                    const href = this.$state.href(stateToGoTo.name, stateToGoTo.params, {absolute: true});
                    this.$window.open(href, '_blank');
                } else {
                    this.$state.go(stateToGoTo.name, stateToGoTo.params);
                    this.closeModal();
                    if (this.parentModalInstance) {
                        this.parentModalInstance.dismiss();
                    }
                }
            }
        });

        return {
            restrict: 'E',
            scope: {
                modalOpen: '=',
                collisionEvents: '=',
                parentModalInstance: '=',
                openCollisionInNewTab: '=',
            },
            templateUrl: 'angular/app/modules/common/ui_components/date_collision/date_collision_template.html',
            controller: DateCollisionDirectiveController,
            controllerAs: 'dateCollisionVm',
            bindToController: true
        };
    };

}());
