(function () {
    'use strict';

    class LabelErrorController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'LabelErrorController';
        }

        errorText() {
            if (this.emptyMessage) {
                return '';
            }

            return this.errorMessage || 'Invalid';
        }
    }
    
    Components.LabelError = {
        bindings: {
            errorMessage: '@',
            customClass: '@',
            emptyMessage: '<'
        },
        controller: LabelErrorController,
        name : 'hbLabelError',
        templateUrl: 'angular/app/modules/common/ui_components/label_error/label_error.html',
    };

}());
