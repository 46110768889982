(function () {
    'use strict';

    Components.FullPageLoader = {
        bindings: {
            activeWhen: '=',
            loadingText: '@'
        },

        // @ngInject
        controller: function () {
        },
        name: 'hbFullPageLoader',
        templateUrl: 'angular/app/modules/common/ui_components/loader/full_page_loader.html',
    };

}());