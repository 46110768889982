(function () {
    "use strict";

    // @ngInject
    function ProjectDetailsCustomizeModalControllerCtor($scope, $injector, $translate, $modalInstance, PopupMessageService, CompaniesManager, $timeout, customizeFields, company, uuid4) {

        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'ProjectDetailsCustomizeModalControllerCtor';
        this.$modalInstance = $modalInstance;
        this.$translate = $translate;
        this.PopupMessageService = PopupMessageService;
        this.CompaniesManager = CompaniesManager;
        this.$timeout = $timeout;
        this.uuid4 = uuid4;

        this.customizeFields = customizeFields;
        this.company = company;

        this.customFields = [];
        this._init();
    }

    Controllers.ProjectDetailsCustomizeModalController = Class(Controllers.BaseController, {
        constructor: ProjectDetailsCustomizeModalControllerCtor,

        close: function close(data) {
            this.$modalInstance.close();
        },

        dismiss: function dismiss(error) {
            this.$modalInstance.dismiss();
        },

        _init: function _init() {
            this.helperIcon = '//res.cloudinary.com/honeybook/image/upload/v1565631962/system_web/company/helpr-icon-tooltip.svg';

            this.uneditableFields = [
                {title: 'WORKSPACE.PROJECT_DETAILS._PROJECT_NAME_'},
                {title: 'WORKSPACE.PROJECT_DETAILS._PROJECT_TYPE_'},
                {title: 'WORKSPACE.PROJECT_DETAILS._DATE_N_LOCATION_'},
                {title: 'WORKSPACE.PROJECT_DETAILS._ABOUT_THE_PROJECT_'}
            ];

            var budgetGuestsPushIndex = 3;

            if (this.company.shouldShowProjectField('budget')) {
                this.uneditableFields.splice(budgetGuestsPushIndex++, 0, {title: 'WORKSPACE.PROJECT_DETAILS._BUDGET_'});
            }

            if (this.company.shouldShowProjectField('guests')) {
                this.uneditableFields.splice(budgetGuestsPushIndex, 0, {title: 'WORKSPACE.PROJECT_DETAILS._GUESTS_'});
            }

            this.customFields = this.customizeFields.map(function (field) {
                field.clientId = this._generateId();
                field.errors = null;
                return field;
            }.bind(this));
        },

        _generateId: function _generateId() {
            return this.uuid4.generate();
        },

        _findItem: function _findItem(item) {
            return this.customFields.findIndex(function (listItem) {
                return listItem.clientId === item.clientId;
            });
        },

        _sanatizeCustomFields: function _sanatizeCustomFields(list) {
            return list.map(function (field) {
                ['errors', 'clientId'].forEach(function (key) {
                    delete field[key];
                });
                return field;
            }.bind(this));
        },

        _validateUniqueName: function _validateUniqueName(item, isNew) {
            if (!item) {
                return false;
            }

            var matches = this.customFields.filter(function (field) {
                return field.name.toLowerCase() === item.toLowerCase();
            }.bind(this));

            return isNew ? !matches.length : matches.length === 1;
        },

        onFieldTextSelect: function onFieldTextSelect($event) {
            $event.target.select();
        },

        onAddCustomField: function addCustomField() {
            const shortTextSchema = {
                type: 'open_question',
                name: '',
                clientId: this._generateId(),
                errors: null
            };

            this.customFields.push(shortTextSchema);
        },

        onDeleteField: function onDeleteField(field) {
            this.customFields.splice(this._findItem(field), 1);
            this.invalidEntries = this._checkForErrors();
        },

        onEditField: function onEditField(field) {
            if (!field.name) {
                field.errors = true;
                return;
            }

            if (this._validateUniqueName(field.name)) {
                field.errors = null;
            } else {
                field.errors = true;
            }

            this.invalidEntries = this._checkForErrors();
        },

        _checkForErrors: function _checkForErrors() {
            var hasErrors = this.customFields.findIndex(function (field) {
                return field.errors === true;
            }.bind(this));

            return hasErrors > -1;
        },

        onChangeField: function (field) {
            field.errors = null;
        },

        _saveSuccessProjectDetails: function _saveSuccessProjectDetails(){
            var message = this.$translate.instant("SETTINGS.PROJECT_DETAILS.MODAL.FIELDS._PROJECT_UPDATED_MSG_");
            var title = this.$translate.instant("SETTINGS.PROJECT_DETAILS.MODAL.FIELDS._PROJECT_UPDATED_TITLE_");

            this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.success, message, 'FREQUENT_BUTTONS._OK_', title);
        },

        onSave: function onSave() {

            // mark in progress
            this.requestInProgress = true;
            var updatedFields = this._sanatizeCustomFields(this.customFields);

            // call save
            this.CompaniesManager.saveCustomProjectFields(this.company, updatedFields).then(function saveSuccess() {

                // close modal
                this.close(updatedFields);

                // show success popup
                this.$timeout(function() {
                    this._saveSuccessProjectDetails();
                }.bind(this));
            }.bind(this));
        }

    });
}());
