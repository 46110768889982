(function () {
    'use strict';

    class ClientPortalLoginFormController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, UserService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalLoginFormController';

            this.UsersManager = UsersManager;
            this.UserService = UserService;

            this.showLoginForm = true;
            this.showForgotPassword = false;
        }

        onForgotPasswordClicked() {
            this.showLoginForm = false;
            this.showForgotPassword = true;
        }

        onBackClicked() {
            this.showLoginForm = true;
            this.showForgotPassword = false;
        }

        onLoginClicked(event) {
            event.preventDefault();

            this.loggingIn = true;
            this.loginError = false;

            this.UsersManager.login(this.user).then(() => {
                this.UserService.onClientPortalLogin(this.user);
            }).catch(() => {
                this.loginError = true;
            }).finally(() => {
                this.loggingIn = false;
            });
        }
    }

    Components.ClientPortalLoginForm = {
        bindings: {
            user: '<',
            portalModel: '<',
        },
        controller: ClientPortalLoginFormController,
        name: 'hbClientPortalLoginForm',
        templateUrl: 'angular/app/modules/core/features/login/client_portal/client_portal_login_form_template.html',
    };

}());