(function () {
    'use strict';

    // @ngInject
    Services.sha256 = function sha256($window) {
        if ($window.sha256) {
            return $window.sha256;
        }
        return {};
    };

}());
