// @ngInject

Models.PackageModel = function PackageModel(BaseModel, $q) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'PackageModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {'base_services': 'ServicesCollection'};
        },

        afterMixingFrom: function afterMixingFrom(object) {

            var self = this;
            if (this.base_services && this.base_services.length > 0) {

                this.base_services.forEach(function (service, index) {
                    service.__package = self;
                });
            }
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            var parentAnalyticsArgs = this.__parent.getAnalyticsArgs();
            var analyticsArgs = {
                package_title: this.title,
                package_id: this.id()
            };

            for (var property in parentAnalyticsArgs) {
                if (parentAnalyticsArgs.hasOwnProperty(property)) {
                    analyticsArgs[property] = parentAnalyticsArgs[property];
                }
            }

            return analyticsArgs;
        },


        addAdditionalServiceToPackage: function addAdditionalServiceToPackage() {
            return this.__parent.addAdditionalServiceToPackage(this);
        },

        getAmount: function getAmount() {
            return this.amount;
        },

        updateService: function updateService(serviceModel) {
            return this.__parent.updateService(serviceModel, this);
        },

        updateServiceQuantity: function updateServiceQuantity(serviceModel) {
            return this.__parent.updateServiceQuantity(serviceModel, this);
        },

        updatePackageQuantity: function updatePackageQuantity() {
            return this.__parent.updatePackageQuantity(this);
        },

        updatePackageSelection: function updatePackageSelection(isSelected) {
            return this.updateProposalSelection(null, isSelected);
        },

        updateProposalSelection: function updateProposalSelection(serviceModel, isSelected) {
            return this.__parent.updateProposalSelection(serviceModel, isSelected, this);
        },

        updateServiceImage: function updateServiceImage(serviceModel, image) {
            return this.__parent.updateServiceImage(serviceModel, image, this);
        },

        deleteServiceImage: function deleteServiceImage(serviceModel) {
            return this.__parent.deleteServiceImage(serviceModel, this);
        },

        updateServicesOrder: function updateServicesOrder() {
            return this.__parent.updateServicesOrder(this);
        },

        deleteService: function deleteService(serviceModel) {
            return this.__parent.deleteService(serviceModel, this);
        },

        updatePackage: function updatePackage(callingScope) {
            return this.__parent.updatePackage(this, callingScope);
        },

        deletePackage: function deletePackage() {
            return this.__parent.deletePackage(this);
        },

        togglePackageTax: function togglePackageTax(taxAll) {
            return this.__parent.togglePackageTax(this, taxAll);
        },

        togglePackageSvc: function togglePackageSvc(svcAll) {
            return this.__parent.togglePackageSvc(this, svcAll);
        },

        findServiceModel: function findServiceModel(serviceId) {
            if(this.base_services && this.base_services.length > 0){
                for(var i = 0, length = this.base_services.length ; i < length; i++){
                    if(this.base_services[i]._id === serviceId) {
                        return this.base_services[i];
                    }
                }
            }
        },

        shouldShowTax: function () {
            return this.__parent.shouldShowTax(this);
        },

        shouldShowSVC: function () {
            return this.__parent.shouldShowSVC(this);
        },

        moveService: function moveService(serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return this.__parent.moveService(serviceId, sourcePackageId, destPackageId, servicesCollection);
        },

        canClientUpdateQuantity: function canClientUpdateQuantity() {
            return (this.__parent.canClientUpdateQuantity && this.__parent.canClientUpdateQuantity());
        }

    });

};
