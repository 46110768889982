
Directives.FocusInputDirective = function FocusInputDirective() {

    // @ngInject
    function FocusInputDirectiveControllerFunc($element, $timeout, $scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FocusInputDirectiveController';

        var self = this;

        var delay = $element.attr('hb-focus-delay') || 1;

        $timeout(function (){
            self.focusCount = 0;

            self.focusOnce = $element.attr('hb-focus-once');
            self.focusIfVals = angular.fromJson($element.attr('hb-focus-if-val'));

            if (self.focusIfVals !== undefined){
                if(!Array.isArray(self.focusIfVals)){
                    self.focusIfVals = [self.focusIfVals];
                }

                self.focusIfVals.forEach(function(element, index){
                    if(element){
                        self.focusIfVals[index] = element.trim().toLowerCase();
                    }
                });
            }

            self.focus = function(){

                if(self.focusOnce && self.focusCount > 0){
                    return;
                }
                $element.focus();
                var val = $element.val();
                $element.val(val);
                self.focusCount++;

            };

            self.watchVal = function(){

                if(!self.focusOnce || (self.focusOnce && self.focusCount === 0)){


                    self.changeHandler = function changeHandler(){
                        if(self.isEqualToFocusVals()){
                            self.focus();
                            if(self.focusOnce && self.focusCount > 0){
                                unwatchFn();
                            }
                        }
                    };

                    var unwatchFn = $scope.$watch(function(){
                        return $element.val();
                    }, self.changeHandler);
                }
            };


            self.isEqualToFocusVals = function(){

                return self.focusIfVals.indexOf($element.val().toLowerCase()) !== -1;
            };

            if(self.focusIfVals){

                if(self.isEqualToFocusVals()){
                    self.focus();

                    if(!self.focusOnce){
                        self.watchVal();
                    }
                }else{
                    self.watchVal();
                }

            }else{

                self.focus();

            }


        }, delay);

    }


    var FocusInputDirectiveController = Class(Controllers.BaseController, {
        constructor: FocusInputDirectiveControllerFunc
    });

    return {

        controller: FocusInputDirectiveController,
        controllerAs: 'focusInputVm',
        bindToController: true
    };
};
