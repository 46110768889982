(function () {
    "use strict";

// class Todo {
// TODO(sean): Remove and replace with Es6
var Todo = {
    bindings: {
        onClick: '&',
        description: '@',
        isDone: '<',
        isLoading: '<',
        isLink: '<',
        estimation: '@'
    },
    // @ngInject
    controller: function($scope, $element, $attrs) {
        this.todoClickHandler = function todoClickHandler($event) {
            $event.preventDefault();
            $event.stopPropagation();

            this.onClick({
                $event: {
                    todo: this
                }
            });
        }
    },
    templateUrl: 'angular/app/modules/common/ui_components/todos/todo.html',
};

angular.module('honeybook.common.components.todos.todo', [])
    .component('hbTodo', Todo)

}());
