(function () {
    "use strict";

    Controllers.CalendarNewAbstractController = class CalendarNewAbstractController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'CalendarNewAbstractController';
        }
    };

}());
