/**
 * Created by inonstelman on 12/29/14.
 */
(function () {
    "use strict";

    var BaseModel, BaseCollection;

    // @ngInject
    function ModelUtilsCtor(ModelFactory, RepositoryService, $log) {
        this.ModelFactory = ModelFactory;
        this.RepositoryService = RepositoryService;
        this.$log = $log;
    }


    Models.ModelUtils = Class({


        constructor: ModelUtilsCtor,

        /**
         *
         * @param toObj
         * @param fromObj
         * @param saveToCache default false
         */
        extendModel: function extendModel(toObj, fromObj, saveToCache, isUpdateFromServer) {


            if (!BaseModel) {
                BaseModel = this.ModelFactory.getModelClass('BaseModel');
            }

            if (!BaseCollection) {
                BaseCollection = this.ModelFactory.getModelClass('BaseCollection');
            }

            var childrenModels = toObj.mapChilds ? toObj.mapChilds() : {};

            for (var key in fromObj) {

                if(key.lastIndexOf('__', 0) === 0){
                    continue;
                }

                // if we dont have the property on our selves then we just copy it
                if (!toObj[key]) {


                    // model

                    if (childrenModels[key]) {
                        var currModel = this.ModelFactory.newModel(childrenModels[key]);
                        if(currModel.mixinIgnoreKeys && currModel.mixinIgnoreKeys[key]){
                            continue;
                        }
                        currModel.mixinFrom(fromObj[key], toObj, saveToCache, isUpdateFromServer);
                        toObj[key] = currModel;
                        continue;
                    }

                    // not a model
                    toObj[key] = fromObj[key];
                    continue;
                }

                //property exist on our selves, we need to mix!

                //if the property is a model
                if (toObj[key] instanceof BaseModel || toObj[key]._hbModelCollection) {

                    if(toObj.mixinIgnoreKeys && toObj.mixinIgnoreKeys[key]){
                        continue;
                    }
                    toObj[key].mixinFrom(fromObj[key], toObj, saveToCache, isUpdateFromServer);
                    continue;
                }

                if (Array.isArray(toObj[key]) && (toObj[key].length > 0) && (angular.isObject(toObj[key][0])) && (toObj[key][0]._id)) {
                    if (Array.isArray(fromObj[key])) {
                        this.mergeArrayItems(toObj[key], fromObj[key], undefined, undefined, true);
                    } else if(!(fromObj[key] === null) && (typeof fromObj[key] !== 'undefined')){
                        toObj[key] = fromObj[key];
                        this.$log.error("Source is not an array - should never happen!!");
                    }
                    continue;
                }

                //if the property is an object
                if (typeof toObj[key] == 'object' && !Array.isArray(toObj[key])) {
                    // todo yona: we need to pass mapChilds to this, if we want to support models in childs

                    // if fromObj[key] is null, we want to delete it from the dest model
                    if (fromObj[key] === null) {
                        toObj[key] = null;
                    }
                    else {
                        this.extendModel(toObj[key], fromObj[key], saveToCache, isUpdateFromServer);
                    }

                    continue;
                }

                //else - an existing simple property
                toObj[key] = fromObj[key];

            }
        },

        /**
         * Merging 2 arrays and using the '_id' property to identify objects.
         *
         * @param {Array} arrTarget the array to merge into
         * @param {Array} arrSource the array to merge from
         * @param {class} [newChildType] the class that will be used to create new models when finding new elements in the source array.
         * If not supplied then the element is inserted in to the target as-is.
         * @param {BaseModel} [parent] The parent of newly created models, if any.
         *
         */
        mergeArrayItems: function mergeArrayItems(arrTarget, arrSource, newChildType, parent, removeExisting, isUpdateFromServer) {
            var idx, tCache = {}, sCache = {}, target, source;

            // Creating target cache and removing elements without '_id'
            // because we cannot merge these kind of elements
            for (idx = arrTarget.length; idx > 0; idx--) {
                target = arrTarget[idx - 1];
                if (target._id) {
                    tCache[target._id] = target;
                } else {
                    // removing non-mergable items
                    arrTarget.splice(idx - 1, 1);
                }
            }

            // Running through the target array and merging matched elements.
            // In case there is no match we simply add it.
            for (idx = 0; idx < arrSource.length; idx++) {
                source = arrSource[idx];
                if (source._id && tCache.hasOwnProperty(source._id)) {

                    if(angular.isFunction(tCache[source._id].mixinFrom)){
                        tCache[source._id].mixinFrom(source, parent, true, isUpdateFromServer);
                    }
                    this.extendModel(tCache[source._id], source, true, isUpdateFromServer);

                } else {
                    if (newChildType) {

                        var fromCache = this.RepositoryService.cacheGet(newChildType, source._id);
                        if(fromCache){
                            fromCache.mixinFrom(source, parent, true, isUpdateFromServer);
                        }
                        source = fromCache || this._createNewModel(newChildType, source, parent, isUpdateFromServer);
                    }
                    arrTarget.push(source);

                    if(source.saveToCache){
                        source.saveToCache();
                    }
                }

                if (source._id) {
                    sCache[source._id] = source;
                }
            }

            // Removing elements that existed in the target and doesn't exist in the source
            // to keep the behaviour of replacing the entire array.
            if(removeExisting){
                for (idx = arrTarget.length; idx > 0; idx--) {
                    target = arrTarget[idx - 1];
                    if (target._id && !sCache.hasOwnProperty(target._id)) {
                        arrTarget.splice(idx - 1, 1);
                    }
                }
            }

        },

        _createNewModel: function _createNewModel(modelClass, data, parent, isUpdatedFromServer) {
            var model = this.ModelFactory.newModel(modelClass);

            model.mixinFrom(data, undefined, false, isUpdatedFromServer);
            model.__parent = parent;
            return model;
        }
    });
}());
