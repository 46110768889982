(function () {
    "use strict";

    // @ngInject
    function EditPreferredVendorsListControllerCtor($injector, $scope, _, UsersManager, CompaniesManager, CompanyService,
                                                    ContactsManager, UserService, $modalInstance, PopupMessageService, ModelFactory, AbTestService,
                                                    AnalyticsService, ModalService, PhotosUploadManager, Enums, pvl) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EditPreferredVendorsListController';

        this.trackedPageView = false;

        this._ = _;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.UserService = UserService;
        this.PhotosUploadManager = PhotosUploadManager;
        this.ModalService = ModalService;
        this.modalInstance = $modalInstance;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;

        this.companyTypesMap = CompanyService.getCompanyTypesTranslationMap();

        // handle company logo
        this.company = CompaniesManager.getCurrCompany();
        this.isDefaultImage = this.company.isDefaultCompanyLogo();
        this.allowUploadPhoto = this.isDefaultImage && !this.isViewOnly;

        // pvl meta data (replaces this.pvlParams.notifyVendors)
        this.notifyVendors = true;

        // Flags
        this.isViewOnly = false; // This flag doesn't change, we need it for the shared template with view
        this.loading = false;
        this.addVendorsInProgress = false;
        this.isPreview = false;
        this.isOwnerMode = true;

        this.preferredVendors = pvl;
        this.isFromCreationGuidance = true;
        this.updatePVList('data fetched');
        this.togglePreviewMode();
    }

    Controllers.EditPreferredVendorsListController = Class(Controllers.BaseController, {
        constructor: EditPreferredVendorsListControllerCtor,

        onTemplateInit: function onTemplateInit() {
            this.scrollSection = angular.element('.js-scrolling-section');
            this.vendorList = angular.element('.js-vendor-list');

            this.scrollSection.on('scroll', function () {
                if (this.scrollSection.scrollTop() > 0) {
                    this.vendorList.addClass('scrolling');
                } else {
                    this.vendorList.removeClass('scrolling');
                }
            }.bind(this));
        },

        scrollUp: function scrollUp() {
            this.scrollSection.animate({scrollTop: 0});
        },

        closeModal: function closeModal() {
            this.modalInstance.close({preferred_vendors_list:this.getSelectedVendors(), notify_vendors: this.notifyVendors});
        },

        dismissModal: function dismissModal() {

            // break if in progress
            if (this.addVendorsInProgress) {
                return;
            }

            // if no changes, dismiss
            if (!this.hasChanges()) {
                this.modalInstance.dismiss();
                return;
            }

            // analytics
            this.AnalyticsService.track(this, "popup: save preferred vendors list changes");

            // ask the user if he wants to keep the changes
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, 'VENDOR_RECOMMENDATIONS.EDIT._UNSAVED_CHANGES_',
                function confirm() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.attach_preferred_vendors_list, {source: 'save_changes_popup'});
                    this.closeModal();
                }.bind(this),
                function cancel() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.dismiss_preferred_vendors_modal, {source: 'save_changes_popup'});
                    this.modalInstance.dismiss();
                }.bind(this),
                'FREQUENT_BUTTONS._YES_',
                'FREQUENT_BUTTONS._NO_'
            );
        },

        hasChanges: function hasChanges() {
            if (!this.initialVendorsList || this.initialVendorsList.length === 0) {
                return this.preferredVendors.length !== this.getSelectedVendors().length;
            } else {
                return !this.isSameList(this.initialVendorsList, this.getSelectedVendors());
            }
        },

        /**
         * create a view of mapping from company types to all preferred vendors - for making the view easier
         * onlySelected: decide whether to create this list of all available preferred vendors or just the selected ones
         */
        _mapPVbyCompanyType: function _mapPVbyCompanyType(onlySelected) {
            var pvList = {};
            this.preferredVendors.forEach(function (contact) {
                if (!onlySelected || contact._selected) {
                    var user = contact.user;
                    var companyType = user.company.company_type;
                    if (!companyType) {
                        companyType = "other"
                    }
                    pvList[companyType] = pvList[companyType] || [];
                    pvList[companyType].push(contact);
                }
            });
            return pvList;
        },

        // initialize newly fetched/added preferred vendors in the data structures
        updatePVList: function updatePVList(callbackReason) {

            // decide the empty state mode
            if (this.preferredVendors.length === 0) {
                this.trackPage();
            }

            if (callbackReason === "data fetched") { //callback after API call
                this.globalPreferredVendorsListCallback();
            }

            this.pvList = this._mapPVbyCompanyType(false);
            this.companyTypes = this._.keys(this.pvList);
        },

        globalPreferredVendorsListCallback: function globalPreferredVendorsListCallback() {
            if (this.initialVendorsList && this.initialVendorsList.length > 0) {

                // update from initial
                this.updatePreferredVendorListSelectionFromInitial();
            } else {

                // if did not get an initial list from outside, initialize all contacts to selected
                this.setSelectedForList(this.preferredVendors, true);
            }
        },

        updatePreferredVendorListSelectionFromInitial: function updatePreferredVendorListSelectionFromInitial() {
            this.initialVendorsList.forEach(function (contact) {
                for (var i = 0; i < this.preferredVendors.length; i++) {
                    var curContact = this.preferredVendors[i];
                    if (contact._id === curContact._id || contact.user._id === curContact.user._id) {
                        curContact._selected = contact._selected;
                        break;
                    }
                }
            }.bind(this));
        },

        // takes a list of vendors (users) and sets the 'selected' flag on them to true
        setSelectedForList: function(vendorsList, selected) {
            vendorsList.forEach(function (vendor) {
                vendor._selected = selected;
            }.bind(this));
        },

        // create a mapping for views of selected preferred vendors only
        updateSelectedList: function updateSelectedList() {
            this.selectedPVs = this._mapPVbyCompanyType(true);
            this.selectedCompanyTypes = this._.keys(this.selectedPVs);
        },

        togglePreviewMode: function togglePreviewMode() {
            // This is to prevent angular binding
            var isPreview = !this.isPreview;
            if (isPreview) {
                this.isPreview = isPreview;
                this.updateSelectedList();
            }
            else {
                if (this.isFromCreationGuidance) {
                    this.modalInstance.dismiss();
                }
                else {
                    this.isPreview = false;
                }
            }
        },

        // returns a filtered list of only selected preferred vendors
        getSelectedVendors: function getSelectedVendors() {
            return this.preferredVendors.filter(function (contact) {
                return contact._selected;
            });
        },

        // return a boo indicating if there are any selected vendors
        hasSelectedVendors: function hasSelectedVendors() {
            return this.getSelectedVendors().length;
        },

        onEditCompanyLogo: function onEditCompanyLogo() {
            if (this.allowUploadPhoto) {
                this.ModalService.openUploadLogoPhotoModal(this.company).then(function success() {
                    this.registerOnce(this.company, 'success', function success() {
                        this.isDefaultImage = this.company.isDefaultCompanyLogo();
                    }.bind(this));
                }.bind(this));
            }
        },

        isEmptyState: function isEmptyState() {
            return this.preferredVendors.length === 0 && !this.loading && !this.isPreview;
        },

        showLogo: function showLogo() {
            // is edit mode, or has a company logo
            return (!this.isPreview && !this.isViewOnly) || !this.isDefaultImage;
        },

        trackPage: function trackPage() {
            if (!this.trackedPageView) {
                this.AnalyticsService.trackPageView(this, 'edit preferred vendors list', {
                    has_preferred_vendors: this.preferredVendors.length > 0,
                    is_empty_state: this.preferredVendors.length === 0
                });
                this.trackedPageView = true;
            }
        },

        // Compares two lists by user._id
        isSameList: function isSameList(listA, listB) {
            function mapUserId(c) { return c.user._id; }
            var listAIds = this._.map(listA, mapUserId);
            var listBIds = this._.map(listB, mapUserId);
            return (this._.difference(listAIds, listBIds).length === 0);
        }

    });
}());
