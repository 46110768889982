(function () {
    "use strict";

    // @ngInject
    function ProposalHeaderComponentControllerCtor($scope, $injector, $q) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProposalHeaderDirectiveController';

        this.$q = $q;
    }

    var ProposalHeaderComponentController = Class(Controllers.BaseController, {
        constructor: ProposalHeaderComponentControllerCtor,

        toggleAllTax: function toggleAllTax() {
            if (this.duringAllTaxableToggle) {
                return;
            }

            this.duringAllTaxableToggle = true;

            var forcedPromise = this.$q.when(this.onTaxClick && this.onTaxClick());

            forcedPromise.finally(function final() {
                this.duringAllTaxableToggle = false;
            }.bind(this));
        },

        toggleAllSvc: function toggleAllSvc() {
            if (this.duringAllSvcableToggle) {
                return;
            }

            this.duringAllSvcableToggle = true;

            var forcedPromise = this.$q.when(this.onSvcClick && this.onSvcClick());

            forcedPromise.finally(function final() {
                this.duringAllSvcableToggle = false;
            }.bind(this));
        },

        inEditMode: function inEditMode() {
            return this.isTemplateMode || (this.isEditMode && this.isEditMode());
        }
    });

    Components.ProposalHeaderComponent = {
        bindings: {
            onTaxClick: '&',
            onSvcClick: '&',
            isEditMode: '&?',
            isTemplateMode: '<?',
            model: '='
        },
        controller: ProposalHeaderComponentController,
        controllerAs: 'proposalHeaderVm',
        name: 'hbProposalHeader',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/proposal_header/proposal_header_template.html'
    };

}());