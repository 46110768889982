(function () {
    "use strict";

    // @ngInject
    function SearchablesManagerCtor(APIService, Routes, $q, ModelFactory, UsersManager) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;

        // searchables data will not be saved in cache, only on manager
        this.searchResults = undefined;

        UsersManager.on('loggingOut', function onLogout() {
            this.searchResults = undefined;
        }.bind(this));
    }

    Services.SearchablesManager = Class(Services.BaseModelManager, {

        constructor: SearchablesManagerCtor,

        /*****************************************
         * ACTIONS
         ******************************************/

        // opts: searchCommunity, sortBy, pageSize, offset
        search: function search(term, searchEntities, opts) {

            // set default value
            opts = opts || {};

            // clear old search
            this.searchResults = this.ModelFactory.newSearchablesCollection();
            var data = {};

            if (opts.searchCommunity){
                data.search_community= true;
            }
            if (opts.sortBy){
                data.sort_by = opts.sortBy;
            }
            if (opts.pageSize) {
                data.page_size= opts.pageSize;
            }
            if (opts.offset || opts.offset === 0) {
                data.offset= opts.offset;
            }
            if (opts.hideTeamMembers) {
                data.hideTeamMembers = opts.hideTeamMembers;
            }
            if (opts.memberEmails) {
                data.member_emails = opts.memberEmails;
            }
            // searchEntities note - 'contacts' is valid, counts as 'users' on server.
            var url = (!term || term.trim() === '') ?
                this.Routes.v2_search_all_items_path(searchEntities) :
                this.Routes.v2_search_items_path(searchEntities, encodeURIComponent(term));
            this.apiFetchCollection(url, data, this.searchResults, true).then(
                function success(resp) {
                    var fakeScore = 0.0001;
                    this.searchResults.forEach(function (searchResult) {
                        if (!searchResult.score) {
                            searchResult.score = fakeScore;
                            fakeScore += 0.0001;
                        }
                    }.bind(this));
                }.bind(this)
            );
            return this.searchResults;
        }


    });
}());
