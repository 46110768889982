/**
 * @notes N/A
 * @convertedToReact
 * @reactLocation /src/components/AngularEntries/BatchEmailsRoot.tsx
 */

(function () {
    'use strict';

    // @ngInject
    function BatchEmailsControllerCtor($scope, $injector, ModalService, ModelFactory, $log, UsersManager, ContactsManager, moment, PopupMessageService, AnalyticsService, $translate, $window, UIUtils, $sce, $interval, ToastService, _, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BatchEmailsController';
    }

    Controllers.BatchEmailsController = Class(Controllers.BaseController, {
        constructor: BatchEmailsControllerCtor,
    });
}());
