(function () {
    "use strict";

    Controllers.UploadAdditionalDocumentsController = class UploadAdditionalDocumentsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, UsersManager, PhotosUploadManager, CompaniesManager, RegexService, AnalyticsService, $translate, _, Stripe, $q, Enums) {
            super($scope, $injector, $modalInstance, UsersManager, CompaniesManager);
            this.__objectType = 'EditPaymentReminderEmailController';
            this.PhotosUploadManager = PhotosUploadManager;
            this.AnalyticsService = AnalyticsService;
            this.Stripe = Stripe;
            this.$translate = $translate;
            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this._ = _;
            this.$q = $q;
            this.Enums = Enums;

            this.currentUser = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();
            this.bankAccount = this.currentUser.myBankAccount();

            this._init();
        }


        _init() {
            this.requiredDocumentsArr = this.company.company_verification_documents;
            this.requiredDocuments = [];

            this._.each(this.requiredDocumentsArr, function (requestedDocument) {
                var doc = {};
                const verificationDocObject = this.Enums.additionalVerificationDocuments[requestedDocument.key];
                doc.key = verificationDocObject.key;
                doc.label = requestedDocument.key === 'other' ? requestedDocument.name : verificationDocObject.label;
                doc.description = verificationDocObject.description;
                this.requiredDocuments.push(doc);
            }.bind(this));
        }

        onIdentificationFileSelected(file, side, type) {
            // TBD
        }

        onIdentificationUploaded(url, key, side) {
            var self = this;
            self.lastError = null;
            self.requestInProgress = true;
            let promise = self.CompaniesManager.saveVerificationDocument(self.company, url, key, side);

            promise.then(function success(resp) {
                self['hasIdentificationImage_'+key+side] = true;
                self['identificationImageUrl_'+key+side] = url;
            }).catch((e) => {
                    if (e.data && e.data.error_type && (e.data.error_type === 'HBStripeUserError' || e.data.error_type === 'HBUserError')) {
                        self.lastError = e.data.error_message;
                    }
                    else if (e.data && e.data.error_type && (e.data.error_type === 'Rack::Timeout::RequestTimeoutException' || e.data.error_type === 'Rack::Timeout::Error')) {
                        self.lastError = self.$translate.instant('ERRORS.SERVER_API._BACKEND_TIMEOUT_');
                    }
                    else {
                        self.lastError = self.$translate.instant('ERRORS.SERVER_API._UNEXPECTED_');
                    }
                }
                ).finally(() => self.requestInProgress = false);
        }

        hasIdentificationImage(key, side){
            return this['hasIdentificationImage_'+key+side];
        }

        dismiss() {
            this.CompaniesManager.saveVerificationDocument(this.company, 'done', 'done', 'done'); // Send email to support
            this.CompaniesManager.getCurrCompany(true); // refresh
            this.$modalInstance.dismiss();
        }
    };

}());