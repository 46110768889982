(function () {
    'use strict';

    Directives.CheckboxQuestionDirective = function CheckboxQuestionDirective() {

        // @ngInject
        function CheckboxQuestionDirectiveControllerCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CheckboxQuestionDirectiveController';
        }

        var CheckboxQuestionDirectiveController = Class(Controllers.BaseController, {
            constructor: CheckboxQuestionDirectiveControllerCtor
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/common/ui_components/questions/checkbox/checkbox_question_directive_template.html',
            controller: CheckboxQuestionDirectiveController,
            controllerAs: 'questionVm',
            bindToController: true,
            scope: {
                question: '=',
                isQuestionEditable: '&',
                isAnswerEditable: '&',
                updateQuestion: '&'
            }
        };
    };

}());

