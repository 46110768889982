Directives.SectionNavDirective = function SectionNavDirective() {

    // @ngInject
    function SectionNavDirectiveControllerFunc($scope, $injector, _, $exceptionHandler, $timeout, $, $q, $window, AnalyticsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SectionNavDirectiveController';
        this.$exceptionHandler = $exceptionHandler;
        this._ = _;
        this.$ = $;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$scope = $scope;
        this.AnalyticsService = AnalyticsService;

        // Draggable settings
        this.sectionItems = [];
        this.sectionItemsModel = [];
        this.sortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.js-drag--section-nav__list-item',
            disabled: false,
            stop: this.onDragStop.bind(this)
        };

        // scroll settings
        this.itemOffset = 115;
        this.fileType ='brochure';

        $scope.$watchCollection('sectionNavVm.sectionList', function(newList, oldList) {
            this._buildBrochureSectionModel();

            if ( oldList !== newList || !newList ) {
                if (oldList.length > 0) {
                    var newSection = this._getNewSectionAdded(newList, oldList);
                    if (newSection) {
                        this._scrollToElement(newSection._id);
                        this._focusNewSection();
                    }
                }
            }

        }.bind(this));

        this._asideNavVisibilityEvents();

    }

    var SectionNavDirectiveController = Class(Controllers.BaseController, {
        constructor: SectionNavDirectiveControllerFunc,

        _buildBrochureSectionModel: function _buildBrochureSectionModel() {
            this.sectionItems.splice(0);

            this.sectionList.forEach(function (item) {
                this.sectionItems.push(this._.clone(item));
            }.bind(this));

            this.sectionItems = this._sortByOrder(this.sectionItems);
            this.areSectionItemsVisible();
        },

        onDragStop: function onDragStop(event, elem) {
            try {
                var sortableModel = elem.item.sortable;

                if(sortableModel.received) {
                    return;
                }

                var isIndexChanged = !!(sortableModel.dropindex || sortableModel.dropindex === 0);

                if(isIndexChanged) {
                    this.updateOrder();

                    var draggedItem = angular.element(elem.item)[0].dataset.dragid;
                    var draggedItemId = this._.last(draggedItem.split('_'));
                    this._scrollToElement(draggedItemId);
                }

            } catch(e) {
                this.$exceptionHandler(e);
            }
        },

        updateOrder: function updateOrder() {
            this.sectionItemsModel = this.sectionItems.map(function(item, index){
               item.order = index;
               return item;
            });

            this._saveUpdatedSectionsOrder();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.dragged_section);
        },

        _saveUpdatedSectionsOrder: function _saveUpdatedSectionsOrder() {
            this.onSort({sortedSections: this.sectionItemsModel});
        },

        _sortByOrder: function (collection) {
            return this._.sortBy(collection, function(e){
                return e.order ? e.order : 0;
            });
        },

        _focusSectionStyle: function _focusSectionStyle(targetElem, type){
            var elemSecList = 'section-nav__list-item--inputFocus';
                type === 'focus' ?
                targetElem.addClass(elemSecList):
                targetElem.removeClass(elemSecList);
        },

        onBlurSection: function onBlurSection($event, brochureSection) {
            this.listTitleIsFocus($event, 'blur');
            this.onUpdate({ section: brochureSection});
            this.listItemSelected = '';
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.updated_section_name);
        },

        onKeyupSection: function onKeyupSection($event, brochureSection){
            var keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            if (keyCode === 13) {
                this.onBlurSection($event, brochureSection);
                this.$($event.currentTarget).blur();
            }
        },

        listTitleIsFocus: function listTitleIsFocus($event, type) {
            var elem = $event.target;
            this._focusSectionStyle(angular.element(elem.closest("li")), type);
        },

        _focusNewSection: function _focusNewSection(){
            this.$timeout(function() {
                var targetElem = angular.element('.section-nav__list-item--last-child');
                targetElem.find('input').select();
                this._focusSectionStyle(targetElem, 'focus');
            }.bind(this));
        },

        onSelectedItem: function onSelectedItem($event, $index){
            this.listItemSelected = $index;
            var elem = $event.currentTarget;
            this._selectInput(elem.querySelector('input'));
        },

        _selectInput: function _selectInput(elem) {
            this.$timeout(function() {
                angular.element(elem.select());
            }.bind(this));
        },

        _getNewSectionAdded: function _getNewSectionAdded (newList, oldList) {
            var oldIds = this._.pluck(oldList, '_id');
            var newSection = this._.find(newList, function(section) { return !this._.contains(oldIds, section._id); }.bind(this));
            return newSection;
        },

        _scrollToElement: function _scrollToElement(elemId) {
            var targetElement = '#' + this.fileType + '-section__' + elemId;

            this.$timeout(function () {
                this.$('html, body').animate({
                    scrollTop: this.$(targetElement).offset().top + this.itemOffset
                }, 700);
            }.bind(this), 0);
        },

        sectionHaveRequiredField: function sectionHaveRequiredField (section) {
            return !section.isSelectionValid();
        },

        onSectionItemClientClick: function onSectionItemClientClick() {
            //this.onReviewActive = false;
        },

        onReviewAction: function onReviewAction() {
            // var sectionList = angular.element('.section-nav__list');
            // sectionList.find('li').removeClass('active');

            //this.onReviewActive = true;
            if(this.onReview) {
                this.onReview({});
            }
        },

        _asideNavVisibilityEvents: function _asideNavVisibilityEvents() {
            this.sectionItemsHidden = false;
            this.asideNavElem = angular.element('.section-nav');

            angular.element(this.$window).bind('resize', function(){
                this.areSectionItemsVisible();
            }.bind(this));

            this.asideNavElem.bind('scroll', function(){
                this.areSectionItemsVisible('scrolling');
            }.bind(this));
        },

        areSectionItemsVisible: function areSectionItemsVisible(event) {
            var viewThresholdScroll = 90;
            var viewThresholdResize = this.isEditable ? 100 : 0;

            this.$timeout(function () {
                if (event === 'scrolling') {
                    var navScrolled = this.asideNavElem.scrollTop();
                    var navHeight = this.asideNavElem.height();
                    var lastSectionPosition = angular.element('.section-nav__list-item--last-child').position().top;

                    this.sectionItemsHidden = (navScrolled + navHeight) - viewThresholdScroll < lastSectionPosition;

                } else {
                    var winHeight = $(this.$window).height();

                    var sectionListContainer =  angular.element('.section-nav__list');

                    var sectionListHeight = sectionListContainer.outerHeight();

                    this.sectionItemsHidden = (sectionListHeight + viewThresholdResize) > winHeight;
                }
            }.bind(this), 500);
        },

        navScrollTop: function navScrollTop() {
            this.$timeout(function () {
                document.querySelector('.section-nav__list-item--last-child').scrollIntoView({ behavior: 'smooth' });
            }.bind(this), 0);
        },

        onCreateSection: function onCreateSection() {
            this.areSectionItemsVisible();
            this.onCreate();
        },

        onDeleteSection: function (brochureSection) {
            this.areSectionItemsVisible();
            this.onDelete({section: brochureSection});
        }

    });

    return {
        scope: {
            sectionList: '=',
            onCreate: '&',
            onDelete: '&',
            onUpdate: '&',
            onChange: '&',
            onSort: '&',
            onReview: '&',
            isEditable: '=',
            isClientMode: '=',
            isReview: '=',
            branded: '=?branded'
        },
        templateUrl: 'angular/app/modules/common/ui_components/section_nav/section_nav_directive_template.html',
        controller: SectionNavDirectiveController,
        controllerAs: 'sectionNavVm',
        bindToController: true
    };
};