(function () {
    'use strict';

    // @ngInject
    function TemplatesViewServiceCtor(_, $, AppStates, $state, $stateParams ,Enums, FeaturesService, AccountsService, ContactFormService,
             CompaniesManager, UsersManager, TemplatesManager, AnalyticsService, PopupMessageService, DeviceService, FlowsBetaUserTypeService,
             NavigationService, ModalService, $translate, $q, WorkspaceFilesManager, UIUtils, FlowService, UserService, FlowsManager) {
        this._ = _;
        this.$ = $;

        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.TemplatesManager = TemplatesManager;
        this.ContactFormService = ContactFormService;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.AppStates = AppStates;
        this.NavigationService = NavigationService;
        this.ModalService = ModalService;
        this.DeviceService = DeviceService;
        this.UserService = UserService;
        this.$translate = $translate;
        this.$q = $q;
        this._ = _;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.UIUtils = UIUtils;
        this.FeaturesService = FeaturesService;
        this.FlowService = FlowService;
        this.FlowsManager = FlowsManager;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

        this.currentTemplate = null;
        this.currentModel = null;
        this.newTitle = '';

        this.company = this.CompaniesManager.getCurrCompany();
        this.user = this.UsersManager.getCurrUser();

        this.UsersManager.on('loggingOut', this.handleLoginngOut.bind(this));
        this.UsersManager.on('loggingIn', this.handleLoginngIn.bind(this));

        this.paymentsValueSum = null;
        this.isUserVendor = this.user.isVendor();

        if (this.isUserVendor) {
            this.paymentsValueSum = this.UserService.paymentsValueSum;
        }

        AccountsService.on(Enums.PubSubTriggers.companyChanged, function () {
            // reset all local variables like in login...
            this.handleLoginngOut();
            this.handleLoginngIn();
        }.bind(this));

        this.loadingTemplate = false;

        // legacy templates to flow migration
        this.isMigrateTemplateToFlowInProgress = false;
        this.shouldAllowMigrationToFlow = false;
        this.templateTypeToMigrate = '';
        this.validTypesForMigration = {
            questionnaire: true,
            invoice: true,
            proposal: true,
            agreement: true,
            brochure: true
        };

        this.reloadModels();

    }

    Services.TemplatesViewService = Class({
        constructor: TemplatesViewServiceCtor,

        goToTemplatesEditor: function goToTemplatesEditor(templateModelKey, templateId, params) {

            if (templateModelKey === 'flow') {
                this.FlowService.goToFlow(templateId);
            } else {
                var _params = this._.extend({templateModelKey: templateModelKey, templateId: templateId}, params);
                this.$state.go(this.AppStates.root_core_navigation_templatesEditor, _params);
            }
        },

        /**
         * updates the current template to be edited on the view-model. calls the server to get eh updated data
         * for the selected template and sets it as the current one.
         */
        updateCurrentTemplate: function updateCurrentTemplate(editedTemplate, viewModel  ) {

            var self = this;
            this.currentTemplate = editedTemplate;
            this.currentModel = viewModel;

            // update if migration to flow is possible
            this.shouldAllowMigrationToFlow = this.allowMigrationToFlow();
            this.templateTypeToMigrate = this.getTemplateTypeToMigrate();

            this.newTitle = '';
            this.loadingTemplate = true;
            this.disableTitleUpdate = false;
            var isTemplateModel = this.isTemplateModel(this.currentTemplate);
            this.currentModel.getFunction(this.company, this.user, this.currentTemplate._id, isTemplateModel).then(function () {
                if (!self.currentModel) {
                    return;
                }
                self.currentTemplate = self._.find(self.currentModel.templatesList(), function (template) {
                    return template._id === self.currentTemplate._id;
                });
                self.currentTemplate.html_body = self.UIUtils.getHtmlBodyCleanInlineStyle(self.currentTemplate.html_body);
                self.last_saved_template_name = self.currentTemplate[self.currentModel.title];
                self.newTitle = self.last_saved_template_name;
                self.$("html, body").animate({scrollTop: 0}, 200);
                if (self.currentModel === self.email) {
                    self.disableTitleUpdate = self.currentTemplate.email_type !== 'general';
                }

                var analyticsArgs = {
                    company_id: self.company.id(),
                    template_type: self.currentModel.name,
                    template_id: self.currentTemplate._id,
                    source: 'company settings'
                };
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.choose_template_success, analyticsArgs);

            }, function error(resp) {
                self.handleError(resp);
                var analyticsArgs = {
                    company_id: self.company.id(),
                    template_type: self.currentModel.name,
                    source: 'company settings'
                };
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.choose_template_success, analyticsArgs);
            }).finally(function () {
                self.loadingTemplate = false;

                var templateType = self.$stateParams['templateModelKey'];
                self.$state.transitionTo(self.AppStates.root_core_navigation_templatesEditor, {templateModelKey: templateType, templateId: self.currentTemplate._id}, {location:'replace'});
            });
            this.mobileDropDown = false;
        },

        /**
         * calls the server and persists the edited data on the model (DB) called on the blur
         */
        updateTemplate: function updateTemplate() {
            switch (this.currentModel) {
                case this.email:
                    this.currentModel.updateFunction = this.currentTemplate.updateEmail.bind(this.currentTemplate, this);
                    break;
                case this.package:
                    this.currentModel.updateFunction = this.currentTemplate.updatePackage.bind(this.currentTemplate, this);
                    break;
                case this.agreement:
                    this.currentModel.updateFunction = this.currentTemplate.updateAgreement.bind(this.currentTemplate, this);
                    break;
                case this.questionnaire:
                    this.currentModel.updateFunction = this.currentTemplate.updateQuestionnaire.bind(this.currentTemplate, this);
                    break;
                case this.brochure:
                    if(this.currentTemplate.updateBrochure) {
                        this.currentModel.updateFunction = this.currentTemplate.updateBrochure.bind(this.currentTemplate, this);
                    } else {
                        this.currentModel.updateFunction = this.currentTemplate.updateTemplate.bind(this.currentTemplate, this);
                    }
                    break;
                case this.emailSignature:
                    this.currentModel.updateFunction = this.user.updateEmailSignature.bind(this.user, this.currentTemplate);
                    break;
                case this.invoice:
                    this.currentModel.updateFunction = this.company.updatePayable.bind(this.company, this.currentTemplate);
                    break;
                case this.proposal:
                    this.currentModel.updateFunction = this.company.updatePayable.bind(this.company, this.currentTemplate);
                    break;
                case this.contactForm:
                    this.currentModel.updateFunction = this.currentTemplate.update.bind(this.currentTemplate);
                    break;
                case this.giftCard:
                    this.currentModel.updateFunction = this.currentTemplate.update.bind(this.currentTemplate);
                    break;
            }

            if (this.currentTemplate[this.currentModel.title] === this.last_saved_template_name ||
                this.currentTemplate[this.currentModel.title].length === 0) {
                this.currentTemplate[this.currentModel.title] = this.last_saved_template_name;
                return;
            }

            var self = this;

            self.last_saved_template_name = self.currentTemplate[this.currentModel.title];

            this.currentModel.updateFunction.bind(this.currentTemplate)().then(function success() {
                // ?
            }, function failed(resp) {
                self.handleError(resp);
            });
        },

        deleteTemplate: function deleteTemplate(template, templatesViewModel, actionMode, workspaceFile, deleteAction) {
            var deferred = this.$q.defer();

            // Delete package should go by the model
            if (templatesViewModel === this.package) {
                templatesViewModel.deleteFunction = template.deletePackage.bind(template);
            }

            //////////////////////
            // Prepare
            /////////////////////
            var _deleteAction = deleteAction || templatesViewModel.deleteFunction;
            var templateType = templatesViewModel.name;
            var templates = templatesViewModel.templatesList();
            var allowDeleteLast = templatesViewModel.allowDeleteLast;
            var analyticsArgs = { template_id: template._id, template_type: templateType, source: 'company templates'};
            var isTemplateModel = this.isTemplateModel(template);

            //////////////////////
            // Validate
            /////////////////////
            var errorMsg;

            // check if last and not allowed to delete
            if (allowDeleteLast === "false" && templates.length === 1) {
                errorMsg = this.$translate.instant('FILE.TEMPLATES._ERROR_DELETE_LAST_MESSAGE_', { type: templateType });
            }

            if(errorMsg) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, errorMsg);

                deferred.reject();
                return deferred.promise;
            }

            //////////////////////
            // Do
            /////////////////////
            var onConfirm = function() {

                // mark in delete process
                template.isBeingDeleted = true;

                _deleteAction(this.company, this.user, template._id, isTemplateModel).then(
                    function deleteActionSuccess() {

                        if(actionMode === 'templates') {

                            this.onAfterTemplateDelete();

                        } else if(actionMode === 'file') {

                            // Delete origin_copy_id to hive the Save button
                            this._deleteOriginTemplate(template._id, workspaceFile, templateType);

                        }

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_template_permanently_confirmed, analyticsArgs);
                        deferred.resolve();

                        template.isBeingDeleted = false;

                    }.bind(this),
                    function deleteActionError(resp) {

                        var errorMsg = 'FILE.TEMPLATES._ERROR_OCCUR_MESSAGE_';
                        switch (resp.data.error_type) {
                            case 'CannotDeleteLastPackage':
                                errorMsg = 'FILE.TEMPLATES.PACKAGES._ERROR_DELETE_LAST_MESSAGE_';
                                break;
                            case 'CannotDeleteLastAgreement':
                                errorMsg = 'FILE.TEMPLATES.AGREEMENT._ERROR_DELETE_LAST_MESSAGE_';
                                break;
                            case 'CannotDeleteDefaultEmail':
                                errorMsg = 'FILE.TEMPLATES.EMAIL._ERROR_DELETE_DEFAULT_EMAIL_MESSAGE_';
                                break;
                            case 'CannotDeleteWorkflowRelatedTemplate':
                                errorMsg = resp.data.error_message;
                                break;
                            case 'HBEntityLinkedToWorkflowsError':
                                // hack: this case will be handled by React
                                errorMsg = null;
                                break;
                            default:
                                if (resp.data.error_message) {
                                    errorMsg = resp.data.error_message;
                                }
                        }

                        if (errorMsg) {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, errorMsg);
                        }

                        analyticsArgs.error = errorMsg;
                        this.AnalyticsService.track (this, this.AnalyticsService.analytics_events.delete_template_permanently_failed, analyticsArgs);

                        // hack: allow React handle some cases
                        deferred.reject(resp.data);

                        template.isBeingDeleted = false;

                    }.bind(this));

            };

            var onCancel = function() {
                this.AnalyticsService.track (this, "cancel delete template", analyticsArgs);
                deferred.reject();
            };

            // show confirmation popup
            var confirmMsg = this.$translate.instant('FILE.TEMPLATES._DELETE_CONFIRM_MESSAGE_', { type: templateType });

            if (templateType === 'contact form' || templateType === 'gift card') {
                confirmMsg = this.$translate.instant('FILE.TEMPLATES._DELETE_CONTACT_FORM_CONFIRM_MESSAGE_');
            }

            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.warning,
                confirmMsg,
                onConfirm.bind(this),
                onCancel.bind(this));

            return deferred.promise;
        },

        setAsDefault: function setAsDefault(template, viewModel) {
            var self = this;
            // This method can invoke outside of full page editor
            // so be careful accessing this.currentModel/this.currentTemplate
            this.currentModel = viewModel;
            return viewModel.setAsDefaultFunction(this.company, template).then(function success() {
                var analyticsArgs = {
                    company_id: self.company.id(),
                    template_type: viewModel.name,
                    template_id: template.id(),
                    source: 'company settings'
                };
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.set_default_template_success, analyticsArgs);

            }, function error() {
                var analyticsArgs = {
                    company_id: self.company.id(),
                    template_type: viewModel.name,
                    template_id: template.id(),
                    source: 'company settings'
                };
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.set_default_template_error, analyticsArgs);

            });
        },

        showLoader: function showLoader() {
            this.loadingTemplate = true;
        },

        addNewTemplate: function addNewTemplate(viewModel) {
            this.currentModel = viewModel;

            if (this.DeviceService.nxSmallBreakpoint()) {
                if(viewModel.name === 'gift card') {
                    this.goToTemplatesEditor('giftCard');
                    return;
                }

                if(viewModel.name === 'contact form') {
                    this.goToTemplatesEditor('contactForm');
                    return;
                }
            }

            this.newTitle = '';
            this.currentTemplate = undefined;
            this.loadingTemplate = true;
            this.disableTitleUpdate = false;

            const addPromise = this.currentModel.addFunction(this.company, this.user).then(function () {
                this.loadingTemplate = false;
                this.goToLastAddedTemplate();

            }.bind(this), function error() {
                this.loadingTemplate = false;
            }.bind(this));
            this.mobileDropDown = false;
            return addPromise;
        },

        goToLastAddedTemplate: function goToLastAddedTemplate() {

            this.currentTemplate = this.currentModel.templatesList()[this.currentModel.templatesList().length - 1];
            this.last_saved_template_name = this.currentTemplate[this.currentModel.title];
            this.newTitle = this.last_saved_template_name;

            // It's crazy that template models don't have a uniform way
            // of identifying what "type" each template is. This is a hack.
            var thisModelKey = this.currentTemplate.type || this.currentModel.name;
            if (thisModelKey === 'contact form') { thisModelKey = 'contactForm' }
            if (thisModelKey === 'gift card') { thisModelKey = 'giftCard' }

            if (this.currentModel === this.flow) {
                this.FlowService.goToFlow(this.currentTemplate._id);
                return;
            }
            
            var isEmailTemplate = thisModelKey === 'email';
            var route = isEmailTemplate
            ? this.AppStates.root_core_oneComposer
            : this.AppStates.root_core_navigation_templatesEditor;

            if (!this.NavigationService.isRouteActive(route)) {
                this.$state.go(route,
                    {
                        templateModelKey: thisModelKey,
                        templateId: this.currentTemplate._id
                    });
            } else {
                //don't reload the state
                this.$state.transitionTo(route,
                    {
                        templateModelKey: thisModelKey,
                        templateId: this.currentTemplate._id
                    },
                    {
                        location:'replace'
                    });
            }
        },

        thisIsTemplatesFullPageEditor: function thisIsTemplatesFullPageEditor() {
            return this.$state.is(this.AppStates.root_core_navigation_templatesEditor);
        },

        saveAsTemplate: function saveAsTemplate(template, templatesViewModel, title) {
            // validate title
            var newTitle = title || this.newTitle;
            if (!newTitle) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning,
                    'COMPANY_SETTING.TEMPLATES.ERRORS._EMPTY_TITLE_');
                return;
            }

            // args
            var analyticsArgs = {
                company_id: this.company.id(),
                template_type: templatesViewModel.name,
                source: 'company settings'
            };

            var isTemplateModel = this.isTemplateModel(template);

            return templatesViewModel.saveAsFunction(this.company, template, newTitle, isTemplateModel).then(
                function saveAsSuccess() {
                    // Templates can now be created in /app/templates
                    var newestTemplate = templatesViewModel.templatesList()[templatesViewModel.templatesList().length - 1];
                    analyticsArgs.template_id = newestTemplate._id;
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_as_template_success, analyticsArgs);
                    // Only update 'currentTemplate' if we're on the full page editor
                    if (this.thisIsTemplatesFullPageEditor()) {
                        this.currentTemplate = templatesViewModel.templatesList()[templatesViewModel.templatesList().length - 1];
                        this.last_saved_template_name = this.currentTemplate[templatesViewModel.title];
                        this.newTitle = this.last_saved_template_name;

                        if (this.currentModel === this.email) {
                            this.disableTitleUpdate = this.currentTemplate.email_type !== 'general';
                        }
                        this.updateCurrentTemplate(this.currentTemplate, this.currentModel);
                    }
                }.bind(this),
                function error(resp) {

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_as_template_error, analyticsArgs);
                    this.handleError(resp);

                }.bind(this))
                .finally(function () {
                    this.newTitle = '';
                    this.mobileDropDown = false;
                }.bind(this));
        },

        handleError: function handleError(resp) {
            var errorMsg = 'COMPANY_SETTING.TEMPLATES.ERRORS._ERROR_OCCUR_MESSAGE_';
            if (resp.data && resp.data.error_message) {
                errorMsg = resp.data.error_message;
            }
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning,
                errorMsg);
            // todo Einav - analytics?
        },

        // Invoices
        // ========
        addNewInvoice: function addNewInvoice() {
            this.addNewTemplate(this.invoice);
        },

        updateCurrentInvoice: function updateCurrentInvoice(invoice) {
            this.updateCurrentTemplate(invoice, this.invoice);
        },

        // Proposals
        // =========
        addNewProposal: function addNewProposal() {
            this.addNewTemplate(this.proposal);
        },

        updateCurrentProposal: function updateCurrentProposal(proposal) {
            this.updateCurrentTemplate(proposal, this.proposal);
        },

        // Packages
        // ========
        updateCurrentPackage: function updateCurrentPackage(currPackage) {
            this.updateCurrentTemplate(currPackage, this.package);
        },
        addNewPackage: function addNewPackage() {
            //this.packageCollapse = true;
            this.addNewTemplate(this.package);
        },

        // Agreement
        // ========
        updateCurrentAgreement: function updateCurrentAgreement(currAgreement) {
            this.isDefaultAgreement = this.company.default_agreement === currAgreement._id;
            this.updateCurrentTemplate(currAgreement, this.agreement);
        },
        addNewAgreement: function addNewAgreement() {
            //this.agreementCollapse = true;
            this.addNewTemplate(this.agreement);
        },
        // setAgreementAsDefault: function setAgreementAsDefault() {
        //     var self = this;
        //     this.setAsDefault(this.agreement).then(function () {
        //         self.isDefaultAgreement = true;
        //     });
        // },

        // Questionnaire
        // ========
        updateCurrentQuestionnaire: function updateCurrentQuestionnaire(currQuestionnaire) {
            this.updateCurrentTemplate(currQuestionnaire, this.questionnaire);
        },
        addNewQuestionnaire: function addNewQuestionnaire() {
            //this.questionnaireCollapse = true;
            this.addNewTemplate(this.questionnaire);
        },


        // Email
        // ========
        updateCurrentEmail: function updateCurrentEmail(currEmail) {
            this.updateCurrentTemplate(currEmail, this.email);
        },
        addNewEmail: function addNewEmail() {
            //this.emailCollapse = true;
            this.addNewTemplate(this.email);
        },

        clearAllCurrentTemplates: function clearAllCurrentTemplates() {
            this.currentTemplate = null;
            this.currentModel = null;
            this.newTitle = '';
        },

        // Email signature
        // ================

        updateCurrentEmailSignature: function updateCurrentEmailSignature(emailSignature) {
            this.updateCurrentTemplate(emailSignature, this.emailSignature);
        },

        addNewEmailSignature: function addNewEmailSignature() {
            this.addNewTemplate(this.emailSignature);
        },

        // Brochure
        // ========
        updateCurrentBrochure: function updateCurrentBrochure(currBrochure) {
            this.updateCurrentTemplate(currBrochure, this.brochure);
        },
        addNewBrochure: function addNewBrochure() {
            //this.brochureCollapse = true;
            this.addNewTemplate(this.brochure);
        },

        handleLoginngOut: function handleLoginngOut() {
            this.currentTemplate = null;
            this.currentModel = null;
            this.newTitle = '';
            this.company = null;
            this.user = null;
            this.shouldAllowMigrationToFlow = false;
            this.package = null;
            this.agreement = null;
            this.questionnaire = null;
            this.brochure = null;
            this.email = null;
            this.invoice = null;
        },

        handleLoginngIn: function handleLoggingIn() {
            this.company = this.CompaniesManager.getCurrCompany(true);
            this.user = this.UsersManager.getCurrUser();
            this.shouldAllowMigrationToFlow = this.allowMigrationToFlow();
            this.reloadModels();
        },

        isTemplateTypeCreatable: function isTemplateTypeCreatable(templateModel) {
            return templateModel.isCreatable !== false;
        },

        isTemplateDeletable: function isTemplateDeletable() {
            return this.currentModel.isDeletable !== false;
        },

        isTemplateCloneable: function isTemplateClonable() {
            return this.currentModel.isCloneable !== false;
        },

        isTemplatePublishable: function isTemplatePublishable() {
            return false;
        },

        isTemplateTypeDeletable: function isTemplateTypeDeletable(templateModel, template) {

            // special handle for contact form
            if(templateModel.name === 'contact form' || templateModel.name === 'gift card') {
                return template.isDeletable();
            }

            return templateModel.isDeletable !== false;
        },

        isTemplateTypeCloneable: function isTemplateTypeCloneable(templateModel, template) {
            if(templateModel.name === 'brochure') {
                return templateModel.isCloneable !== false && this.isTemplateModel(template);
            }

            return templateModel.isCloneable !== false;
        },

        isTemplateTypeDefaultable: function isTemplateTypeDefaultable(templateModel) {
            return templateModel.isDefaultable !== false;
        },

        shouldShowTemplateBadge: function shouldShowTemplateBadge(templateModel, template) {
            return templateModel.showTemplateBadge && templateModel.shouldShowBadge(template);
        },

        generateTemplateBadge: function generateTemplateBadge(templateModel, template) {
            // if(!this.shouldShowTemplateBadge(templateModel)) {
            //     return "";
            // }
            return templateModel.generateTemplateBadge(template);
        },

        setTemplateModel: function setTemplateModel(templateType, templateId) {
            this.currentModel = null;
            if (this[templateType]) {
                this.currentModel = this[templateType];
            }

            if (templateId) {

                if (this.company.wasFullyFetched()) {
                    var template = this._.find(this.currentModel.templatesList(), function(template) { return template._id === templateId; });
                    this.updateCurrentTemplate(template, this.currentModel);
                } else {

                    this.company.once('success', function onCompanyUpdated(){
                        if (!this.currentModel) {
                            return;
                        };
                        var template = this._.find(this.currentModel.templatesList(), function(template) { return template._id === templateId; });
                        this.updateCurrentTemplate(template, this.currentModel);
                    }.bind(this));
                }
            }
        },

        onExitEditor: function onFinishEditingTempalte() {
            this.currentModel = null;
            this.currentTemplate = null;
        },

        onAfterTemplateDelete: function onAfterTemplateDelete() {
            this.setFirstTemplateAsActive();
        },

        // Due to all the bindings here we need to reload this if the user logs out and the logs in.
        // Otherwise the binding will hold the old instance of the previous company
        reloadModels: function reloadModels() {
            this.invoice = {
                name: 'invoice',
                title: 'title',
                addFunction: this.TemplatesManager.addNewInvoice.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getInvoice.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteInvoice.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsPayableTemplate.bind(this.TemplatesManager),
                saveFromFileFunction: this.WorkspaceFilesManager.saveInvoiceAsTemplate.bind(this.WorkspaceFilesManager),
                applyFunction: this.WorkspaceFilesManager.updateInvoiceByTemplate.bind(this.WorkspaceFilesManager),
                templatesList: this.company.getCompanyInvoices.bind(this.company),
                isDefaultable: false,
                allowDeleteLast: false
            };

            this.proposal = {
                name: 'proposal',
                title: 'title',
                addFunction: this.TemplatesManager.addNewProposal.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getProposal.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteProposal.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsPayableTemplate.bind(this.TemplatesManager),
                saveFromFileFunction: this.WorkspaceFilesManager.saveProposalAsTemplate.bind(this.WorkspaceFilesManager),
                applyFunction: this.WorkspaceFilesManager.updateProposalFileByTemplate.bind(this.WorkspaceFilesManager),
                templatesList: this.company.getCompanyProposals.bind(this.company),
                isDefaultable: false
            };

            this.package = {
                name: 'package',
                title: 'title',
                addFunction: this.TemplatesManager.addNewPackage.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getPackage.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deletePackage.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsPackageByTemplate.bind(this.TemplatesManager),
                templatesList: this.company.getCompanyPackages.bind(this.company),
                isDefaultable: false
            };

            this.agreement = {
                name: 'agreement',
                title: 'name',
                addFunction: this.TemplatesManager.addNewAgreement.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getAgreement.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteAgreement.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsAgreementByTemplate.bind(this.TemplatesManager),
                applyFunction: this.WorkspaceFilesManager.updateAgreementByTemplate.bind(this.WorkspaceFilesManager),
                saveFromFileFunction: this.CompaniesManager.saveAsAgreementTemplate.bind(this.CompaniesManager),
                templatesList: this.company.getCompanyAgreements.bind(this.company),
                setAsDefaultFunction: this.TemplatesManager.setAgreementAsDefault.bind(this.TemplatesManager),
                getDefaultTemplate: this.company.getDefaultAgreementId.bind(this.company),
            };

            this.questionnaire = {
                name: 'questionnaire',
                title: 'title',
                addFunction: this.TemplatesManager.addNewQuestionnaire.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getQuestionnaire.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteQuestionnaire.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsQuestionnaireByTemplate.bind(this.TemplatesManager),
                saveFromFileFunction: this.CompaniesManager.saveAsQuestionnaireTemplate.bind(this.CompaniesManager),
                applyFunction: this.WorkspaceFilesManager.updateQuestionnaireByTemplate.bind(this.WorkspaceFilesManager),
                templatesList: this.company.getCompanyQuestionnaires.bind(this.company),
                isDefaultable: false
            };

            this.brochure = {
                name: 'brochure',
                title: 'title',
                addFunction: this.TemplatesManager.addNewBrochure.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getBrochure.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteBrochure.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsBrochureByTemplate.bind(this.TemplatesManager),
                saveFromFileFunction: this.CompaniesManager.saveAsBrochureTemplate.bind(this.CompaniesManager),
                applyFunction: this.WorkspaceFilesManager.updateBrochureByTemplate.bind(this.WorkspaceFilesManager),
                templatesList: function getBrochureTemplates() {
                    return this.company? this.company.getCompanyBrochures(this.company, true) : [];
                }.bind(this),
                isDefaultable: false
            };

            this.timeline = {
                name: 'timeline',
                title: 'title',
                saveFromFileFunction: this.CompaniesManager.saveAsTimelineTemplate.bind(this.CompaniesManager),
                applyFunction: this.WorkspaceFilesManager.updateTimelineByTemplate.bind(this.WorkspaceFilesManager),
                templatesList: this.company.getCompanyTimelines.bind(this.company),
                deleteFunction: this.TemplatesManager.deleteTimeline.bind(this.TemplatesManager),
                isDefaultable: false,
                isDeletable: true,
                isCloneable: false,
                allowDeleteLast: true
            };

            this.email = {
                name: 'email',
                title: 'title',
                addFunction: this.TemplatesManager.addNewEmail.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getEmail.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteEmail.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsEmailByTemplate.bind(this.TemplatesManager),
                templatesList: this.company.getCompanyEmails.bind(this.company),
                isDefaultable: false
            };

            this.emailSignature = {
                name: 'email signature',
                title: 'title',
                addFunction: this.UsersManager.createEmailSignature.bind(this.UsersManager),
                getFunction: this.UsersManager.getEmailSignature.bind(this.UsersManager),
                deleteFunction: this.UsersManager.deleteEmailSignature.bind(this.UsersManager),
                templatesList: this.user.getCompanyEmailSignatures.bind(this.user),
                isCreatable: false,
                isDeletable: false,
                isCloneable: false,
                isDefaultable: false
            };

            this.contactForm = {
                name: 'contact form',
                title: 'title',
                templatesList: this.company.getCompanyContactForms.bind(this.company),
                addFunction: this.TemplatesManager.createContactForm.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getContactForm.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsContactFormByTemplate.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteContactForm.bind(this.TemplatesManager),
                isDefaultable: false,
                isCreatable: true,
                isDeletable: true,
                isCloneable: true,
                showTemplateBadge: true,
                generateTemplateBadge: this.generateContactFormBadge.bind(this),
                shouldShowBadge: this.shouldShowContactFormBadge.bind(this)
            };

            this.giftCard = {
                name: 'gift card',
                title: 'title',
                templatesList: this.company.getCompanyGiftCards.bind(this.company),
                addFunction: this.TemplatesManager.createGiftForm.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getContactForm.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveAsContactFormByTemplate.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteContactForm.bind(this.TemplatesManager),
                isDefaultable: false,
                isCreatable: false,
                isDeletable: true,
                isCloneable: false,
                showTemplateBadge: true,
                generateTemplateBadge: this.generateContactFormBadge.bind(this),
                shouldShowBadge: this.shouldShowContactFormBadge.bind(this)
            };

            this.flow = {
                name: 'Flow',
                title: 'title',
                templatesList: this.company.getFlows.bind(this.company),
                addFunction: this.TemplatesManager.createFlow.bind(this.TemplatesManager),
                getFunction: this.TemplatesManager.getFlow.bind(this.TemplatesManager),
                saveAsFunction: this.TemplatesManager.saveFlowAsTemplate.bind(this.TemplatesManager),
                deleteFunction: this.TemplatesManager.deleteFlow.bind(this.TemplatesManager),
                isDefaultable: false,
                isCreatable: true,
                isDeletable: true,
                isCloneable: true,
                showTemplateBadge: false,
                // generateTemplateBadge: this._.constant('active');
                // shouldShowBadge: this.shouldShowContactFormBadge.bind(this)
            };
        },

        setFirstTemplateAsActive: function setFirstTemplateAsActive() {
            if (!!this.currentModel) {

                var list = this.currentModel.templatesList();
                if (list && list.length > 0) {

                    var listToSort = list;

                    if (this.hasLegacy(this.currentModel)) {
                        var splitedTemplates = this.splitTemplatesToNewAndLegacy(list);
                        var newTemplatesList = splitedTemplates[0];
                        var oldTemplatesList = splitedTemplates[1];

                        listToSort = newTemplatesList.length ? newTemplatesList : oldTemplatesList;
                    }

                    // sort by title and select the first one
                    var sorted = this._.sortBy(listToSort, function (template) {
                        return template.title && template.title.toLowerCase();
                    });

                    this.updateCurrentTemplate(sorted[0], this.currentModel);
                } else {
                    this.currentTemplate = null;
                }


            } else {
                this.currentTemplate = null;
            }
        },

        openSaveAsModal: function openSaveAsModal(template, templatesViewModel) {
            var titleWrapper = {
                title: template.title
            };

            var templateType = this.currentModel ? this.currentModel.name : templatesViewModel.name;

            return this.ModalService.openTemplateSaveAsModal(titleWrapper, '', false,
                function success() {
                    this.AnalyticsService.trackClick(
                        this,
                        this.AnalyticsService.analytics_events.save_as_template,
                        { template_type: templateType, source: 'templates' }
                    );
                    return this.saveAsTemplate(template, templatesViewModel, titleWrapper.title);
                }.bind(this),
                function cancel() {
                    this.AnalyticsService.trackClick(
                        this,
                        this.AnalyticsService.analytics_events.cancel_save_as_template,
                        { template_type: templateType, source: 'templates' }
                    );
                    return false;
                }.bind(this)
            );
        },

        applyTemplate: function applyTemplate(workspaceFile, template, templateType, applyAction) {
            var deferred = this.$q.defer();
            var errorMsg;

            // check questionnaire status
            if ((workspaceFile.isQuestionnaire() && workspaceFile.isComplete())) {
                errorMsg = 'FILE.TEMPLATES._CANNOT_SELECT_TEMPLATE_SUBMITTED_';
            }

            // check brochure and timeline status
            if ((workspaceFile.isBrochure() || workspaceFile.isTimeline()) && workspaceFile.isSent()) {
                errorMsg = 'FILE.TEMPLATES._CANNOT_SELECT_TEMPLATE_SENT_';
            }

            // check file canceled
            if(workspaceFile.isCanceled()) {
                errorMsg = 'FILE.TEMPLATES._CANNOT_SELECT_TEMPLATE_EXPIRED_';
            }

            // check brochure types match
            if(workspaceFile.isNewBrochure() !== this.isTemplateModel(template)) {
                if(workspaceFile.isNewBrochure()) {
                    errorMsg = 'FILE.TEMPLATES._CANT_APPLY_OLD_BROCHURE_TEMPLATE_';
                } else {
                    errorMsg = 'FILE.TEMPLATES._CANT_APPLY_NEW_BROCHURE_TEMPLATE_';
                }
            }

            // show alert and break if error
            if(errorMsg) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, errorMsg);

                deferred.reject();
                return deferred.promise;
            }

            var isTemplateModel = this.isTemplateModel(template);

            // this is the callback for confirming the apply action
            var onConfirm = function() {

                // do action
                applyAction(workspaceFile, template._id, isTemplateModel).then(
                    function applyActionSuccess() {

                        // analytics
                        var analyticsArgs = {
                            template_id: template._id,
                            template_type: templateType
                        };

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.template_selected_confirmed, analyticsArgs);

                        deferred.resolve();

                    }.bind(this),
                    function applyActionFailure() {

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning,
                            'FILE.TEMPLATES._ERROR_SELECT_OCCUR_MESSAGE_');

                        // analytics
                        var analyticsArgs = {
                            template_id: template._id,
                            template_type: templateType
                        };

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.template_selected_failed, analyticsArgs);

                        deferred.reject();

                    }.bind(this)
                );
            };

            // this is the callback for canceling the apply action
            var onCancel = function() {
                var analyticsArgs = {
                    template_id: template._id,
                    template_type: templateType
                };
                this.AnalyticsService.track(this, "cancel select template", analyticsArgs);

                deferred.reject();
            };

            // show confirm popup
            var confirmMsg = 'FILE.TEMPLATES._CONFIRM_TEMPLATE_CHANGE_';
            if (workspaceFile.supportVersions() && !workspaceFile.isDraftEditable()) {
                confirmMsg = 'FILE.TEMPLATES._CONFIRM_CREATE_NEW_VERSION_';
            }
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, confirmMsg, onConfirm.bind(this), onCancel.bind(this));

            return deferred.promise;
        },

        _deleteOriginTemplate: function(templateId, workspaceFile, templateType) {

            var type_to_model_map = {
                'proposal': 'vendor_proposal',
                'payments-schedule': 'payments_container',
                'brochure': 'brochure_container',
                'timeline': 'timeline_container'
            };

            // What's not in the map -> same name in the model (for now: agreement, questionnaire)
            var model_field = type_to_model_map[templateType] || templateType;

            if (workspaceFile[model_field]) {

                if (workspaceFile[model_field].origin_copy_id === templateId) {
                    delete workspaceFile[model_field].origin_copy_id;
                }
            } else {

                //  for invoice and proposal
                if(workspaceFile.origin_copy_id === templateId) {
                    delete workspaceFile.origin_copy_id;
                }
            }
        },

        getDefaultTemplate: function getDefaultTemplate(templatesViewModel) {
            var defaultTemplate;

            // return if default is not supported
            if(templatesViewModel.isDefaultable === false) {
                return defaultTemplate;
            }

            var defaultTemplateId = templatesViewModel.getDefaultTemplate();
            var templatesList = templatesViewModel.templatesList();

            for(var i = 0; i < templatesList.length; i++) {
                if(templatesList[i]._id === defaultTemplateId) {
                    defaultTemplate = templatesList[i];
                    break;
                }
            }

            return defaultTemplate;
        },

        shouldShowContactFormBadge: function shouldShowContactFormBadge(template) {
            return (template.isPublished && template.isPublished() || (!template.are_changes_published && template.isPublished()));
        },

        generateContactFormBadge: function generateContactFormBadge(template) {
            var _isGiftCard = this.currentModel && this.currentModel.name === "gift card";
            var contactFormBadge = '';

            if (_isGiftCard) {
                contactFormBadge = template.contact_form_settings.is_active ? "ACTIVE" : "INACTIVE";
            }

            else if ((template.isPublished && template.isPublished()) && template.are_changes_published) {
                contactFormBadge = "PUBLISHED";
            }

            return contactFormBadge;
        },

        shouldShowCreateFileButton: function shouldShowCreateFileButton() {
            switch (this.currentModel.name) {
                    case 'agreement':
                    case 'proposal':
                    case 'invoice':
                    case 'questionnaire':
                        return ((this.user.isInTrial() || this.user.companyHasSubscription()) && !(this.user.notSelectedNextPlanCode() && this.paymentsValueSum > 10000));
                        break;
                    case 'brochure':
                        return this.shouldShowCreateFileButtonForBrochure();
                        break;
                    default:
                        return false;
                }
        },

        shouldShowCreateFileButtonForBrochure: function shouldShowCreateFileButtonForBrochure() {
            if(!this.currentTemplate) {
                return false;
            }
            var isNewBrochureTemplate = this.isTemplateModel(this.currentTemplate);
            var isInTrialOrSubscribed = this.user.isInTrial() || this.user.companyHasSubscription();
            return isInTrialOrSubscribed && isNewBrochureTemplate;
        },

        isTemplateModel: function(template) {
            return (!!template.isModelOf && template.isModelOf("Template"));
        },

        splitTemplatesToNewAndLegacy: function getTemplatesGroup(temapltes) {
            var newTemplatesList = [];
            var legacyTemplatesList = [];

            temapltes.forEach(function(template) {
                if(this.isTemplateModel(template)) {
                    newTemplatesList.push(template);
                } else {
                    legacyTemplatesList.push(template);
                }
            }.bind(this));

            return [newTemplatesList, legacyTemplatesList];
        },

        hasLegacy: function hasLegacy(templatesViewModel) {
            return (templatesViewModel.name === 'brochure');
        },

        generateCurrentTemplateClientPreview: function generateCurrentTemplateClientPreview(fileType, templateId) {
            return this.WorkspaceFilesManager.createTemplatePreviewFile(fileType, templateId);
        },

        shouldShowNewContactForm: function shouldShowNewContactForm() {
            var _isContactForm = this.currentModel && this.currentModel.name === "contact form";
            var _isGiftCard = this.currentModel && this.currentModel.name === "gift card";
            return (_isContactForm || _isGiftCard) && this.currentTemplate && this.currentTemplate.isDynamicFile();
        },

        removeDeletedFlow: function removeDeletedFlow(flowId) {
            var flowIndex = this._.findIndex(this.company.flows, function(flow) { return flow._id === flowId; });
            if (flowIndex > -1) {
                this.company.flows.splice(flowIndex, 1);
            }
        },

        getTemplatesPrimaryUrl: function templatesPrimaryUrl() {
           if (this.FlowsBetaUserTypeService.hasNewFlowExp) {
               return this.AppStates.root_core_navigation_my_templates;
           }
          return this.AppStates.root_core_navigation_templates;
        },

        getTemplateTypeToMigrate: function getTemplateTypeToMigrate() {
            return this.currentModel.name === 'agreement' ? 'contract' : this.currentModel.name;
        },

        convertTemplateToFlow: function convertTemplateToFlow() {
            this.isMigrateTemplateToFlowInProgress = true;
            var templateType = this.getTemplateTypeToMigrate();
            this.FlowsManager.convertFileTemplateToFlow(this.currentTemplate._id, templateType, this.newTitle, 'flow_template').then(function(response) {
                var flowTemplate = response.data;
                this.FlowService.goToFlow(flowTemplate._id, null, {location: "replace", isGoToDefault: true});
            }.bind(this)).finally(function() {
                this.isMigrateTemplateToFlowInProgress = false;
            }.bind(this));
        },

        allowMigrationToFlow: function allowMigrationToFlow() {
            return false;
        }
    });
}());
