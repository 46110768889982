// Device Service

// Check whether user device fits within certain media query; e.g.: < 640px (mobile)
// Check whether device supports touch
// NB: this will not update if user re-sizes window

(function () {
    "use strict";

    // @ngInject
    Services.DeviceService = function DeviceService(Modernizr, $window) {
        const modernizr = Modernizr;
        this.device = {};

        // add media queries that we have in base/_grid.css.scss
        const mediaQueryEnums = {
            mobileSize: {query: 'only all and (max-width: 640px)'},
            ipadMaxSize: {query: 'only all and (max-width: 1024px)'},
            nxSmallBreakPoint: {query: 'only all and (max-width: 768px)'},
            xLargeBreakPoint: {query: 'only all and (max-width: 1200px)'},
            navLargeBreakPoint: {query: 'only all and (max-width: 992px)'}
        };

        this.checkIfMobileSize = () => {
            const mobileScreenSize = mediaQueryEnums.mobileSize.query; // 640px
            // mobile screens or small browser windows
            this.device.isMobileSize = modernizr.mq(mobileScreenSize);
            return this.device.isMobileSize;
        };

        this.isIpadScreenSizeOrSmaller = () => {
            const ipadMaxScreenSize = mediaQueryEnums.ipadMaxSize.query; // 640px
            // mobile screens or small browser windows
            this.device.ipadMaxSize = modernizr.mq(ipadMaxScreenSize);
            return this.device.ipadMaxSize;
        };

        this.xLargeBreakPoint = () => {
            const xLargeScreenSize = mediaQueryEnums.xLargeBreakPoint.query;
            // Navigation Large breakpoint >= 1200px
            this.device.xLargeMaxSize = modernizr.mq(xLargeScreenSize);
            return this.device.xLargeMaxSize;
        };

        this.nxSmallBreakpoint = () => {
            const nxSmallBreakpoint = mediaQueryEnums.nxSmallBreakPoint.query; // 768px
            this.device.nxSmallMaxSize = modernizr.mq(nxSmallBreakpoint);
            return this.device.nxSmallMaxSize;
        };

        this.navLargeBreakPoint = () => {
            const navMaxLargeScreenSize = mediaQueryEnums.navLargeBreakPoint.query;
            // Navigation Large breakpoint >= 992px
            this.device.navLargeMaxSize = modernizr.mq(navMaxLargeScreenSize);
            return this.device.navLargeMaxSize;
        };

        this.checkIfTouchScreen = () => {
            return modernizr.touchevents;
        };

        const isMobile = {
            Android: () =>  {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: () => {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iPhone: () => {
                return navigator.userAgent.match(/iPhone/i);
            },
            iPad: () => {
                return navigator.userAgent.match(/iPad/i);
            },
            iPod: () => {
                return navigator.userAgent.match(/iPod/i);
            },
            Opera: () => {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: () => {
                return navigator.userAgent.match(/IEMobile/i);
            }
        };

        this.checkIfTouchDevice = () => {
            return isMobile.Android() || isMobile.BlackBerry() || isMobile.iPhone() || isMobile.iPad() ||
                   isMobile.iPod() || isMobile.Opera() || isMobile.Windows();
        };

        this.getMobileDevice = () => {
            for (let key in isMobile) {
                if (isMobile[key]()) {
                    return key;
                }
            }

            return undefined;
        };

        //NOTE: http://stackoverflow.com/questions/5899783/detect-safari-using-jquery
        this.isSafari = () => {
            return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        };

        this.isEdge = () => {
            return /Edge\/\d./i.test(navigator.userAgent);
        };

        this.isFireFox = () => {
            return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        };


        this.isInAppBrowser = () => {
            return !!(window && window.isHBInAppBrowser);
        };

        this.getMobileOs = (osParams) => {
            const MOBILE_IOS = 'iOS';
            const MOBILE_ANDROID = 'Android';
            const ua = navigator.userAgent;
            let uaindex;
    
            // determine OS
            if (ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/iPhone/i)) {
                osParams.mobileOs = MOBILE_IOS;
                uaindex = ua.indexOf('OS ');
            } else if (ua.match(/Android/i)) {
                osParams.mobileOs = MOBILE_ANDROID;
                uaindex = ua.indexOf('Android ');
            }
    
            // determine version
            if (osParams.mobileOs === MOBILE_IOS && uaindex > -1) {
                const indexOfIosVersion = uaindex + 3;
                osParams.iosVersion = ua
                    .substring(
                        indexOfIosVersion,
                        ua.indexOf(' ', indexOfIosVersion)
                    )
                    .replaceAll('_', '.');
            } else if (osParams.mobileOs === MOBILE_ANDROID && uaindex > -1) {
                const indexOfAndroidVersion = uaindex + 8;
                osParams.androidVersion = ua.substring(
                    indexOfAndroidVersion,
                    ua.indexOf(';', indexOfAndroidVersion)
                );
            }
        };

        this.isIOS = () => {
            if (this.checkIfTouchDevice()) {
                let mobileOsParams = {};
                this.getMobileOs(mobileOsParams);
                if (mobileOsParams.mobileOs === 'iOS') {
                    return true;
                }
            }

            return false;
        };

        this.isAndroid = () => {
            if (this.checkIfTouchDevice()) {
                let mobileOsParams = {};
                this.getMobileOs(mobileOsParams);
                if (mobileOsParams.mobileOs === 'Android') {
                    return true;
                }
            }

            return false;
        };

        this.isTablet = () => {
            var userAgent = navigator.userAgent.toLowerCase();
            const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
            return userAgent.match(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i) != null;
        };

        this.checkIfMobileSize();

        angular.element($window).bind('resize', function(){
            this.checkIfMobileSize();
            this.nxSmallBreakpoint();
        }.bind(this));
    };
}());
