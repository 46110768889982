(function () {
    "use strict";

    Controllers.ContactViewController = class ContactViewController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, ContactViewService, $state, AppStates, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'ContactViewController';

            AnalyticsService.track(this, AnalyticsService.analytics_events.load_contact_view, {source: $state.params.source});
        }
    };

}());
