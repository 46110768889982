(function () {
    'use strict';

    // @ngInject
    function WorkflowFirstLevelNavControllerCtor($scope, $injector, FeaturesService, UiPersistenceService, UsersManager, AnalyticsService, AppStates, WorkflowService, $timeout, PopupMessageService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkflowFirstLevelNavController';
        AnalyticsService.trackPageView(this, 'WorkflowFirstLevelNavView');

        this.AppStates = AppStates;
        this.$timeout = $timeout;
        this.$scope = $scope;

        this.WorkflowService = WorkflowService;
        this.PopupMessageService = PopupMessageService;
        this.showSavingIndicator = false;
        this.changesWereSaved = false;

        this.user = UsersManager.getCurrUser();
        this.UiPersistenceService = UiPersistenceService;
    }

    Controllers.WorkflowFirstLevelNavController = Class(Controllers.BaseController, {
        constructor: WorkflowFirstLevelNavControllerCtor,

        onWorkflowTitleChanged: function onWorkflowTitleChanged() {
            this.WorkflowService.setWorkflowViewModelAsDirty();
        },

        showSavedMessage: function showSavedMessage(){
            var self = this;
            this.changesWereSaved = true;

            this.$timeout(function(){
                self.changesWereSaved = false;
                self.$scope.$apply();
            }, 3000);
        },

        onSave: function onSave() {
            this.showSavingIndicator = true;
            this.WorkflowService.onSaveWorkflow()
                .then(function success() {
                    this.showSavedMessage();
                }.bind(this))
                .catch(function fail(resp) {
                    if (resp.data && resp.data.error_type === 'HBUserError')                    {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }
                }.bind(this))
                .finally(function finallyHandler() {
                    this.showSavingIndicator = false;
                }.bind(this));
        },
        back: function back() {
            if (this.WorkflowService.viewModelIsDirety) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'WORKFLOW.ALERTS._UNSAVED_CHANGES_',
                    function clickedYeah() {
                        this.goToState(this.AppStates.root_core_navigation_automations);
                    }.bind(this),
                    function clickedNay() {
                    }, 'WORKFLOW.ALERTS._LEAVE_', 'WORKFLOW.ALERTS._STAY_');
            } else {
                this.goToState(this.AppStates.root_core_navigation_automations);
            }
        }
    });
}());