/**
 * Created by saratheneale on 6-2-15
 */

Directives.QuestionDirective = function QuestionDirective() {

    // @ngInject
    function questionControllerFunc($, Enums, $scope, $injector, $timeout, _, AnalyticsService, SavingIndicatorService, QuestionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'QuestionDirectiveController';
        this.QuestionService = QuestionService;
        this.SavingIndicatorService = SavingIndicatorService;
        this.$timeout = $timeout;
        //this.question_before_update = this.question.dataOnly();
        this._ = _;
        var self = this;
        this.SavingIndicatorService.registerModel(this.question);

        this.deleteInProgress = false;

        this.sortableOptions = {
            axis: 'y',
            revert: 200,
            handle: '.js-answer-drag',
            opacity: 0.8,

            stop: function (event) {
                AnalyticsService.track(self, AnalyticsService.analytics_events.questionnaire_optional_answer_drag, self.question.getAnalyticsArgs());
            }
        };

        this.closeEditMode = function closeEditMode() {
            if (this.isEditMode) {
                this.isEditMode = false;
                this.updateQuestion();
            }
        };

        this.showDeleteIcon = function showDeleteIcon() {

            return this.isEditMode && !this.question.hb_required;
        };

        this.titlePlaceholder = function titlePlaceholder() {
            if (this.questionIndex === 0) {
                return 'QUESTIONNAIRE._INTRO_TEXTBOX_TITLE_PLACEHOLDER_';
            } else {
                if (this.isQuestionEditable()) {
                    // questionnaire edit mode
                    return this.question.question_type === 'textbox' ? 'QUESTIONNAIRE._TEXTBOX_TITLE_PLACEHOLDER_' : 'QUESTIONNAIRE._QUESTION_TITLE_PLACEHOLDER_';
                } else {
                    // questionnaire view and preview modes
                    switch (this.question.question_type) {
                        case 'textbox':
                            return 'QUESTIONNAIRE._TEXTBOX_TITLE_PLACEHOLDER_';
                            break;
                        case 'radio':
                            return 'QUESTIONNAIRE.VIEW_PREVIEW._QUESTION_RADIO_TITLE_PLACEHOLDER_';
                            break;
                        case 'checkbox':
                            return 'QUESTIONNAIRE.VIEW_PREVIEW._QUESTION_CHECKBOX_TITLE_PLACEHOLDER_';
                            break;
                        case 'dropdown':
                            return 'QUESTIONNAIRE.VIEW_PREVIEW._QUESTION_DROPDOWN_TITLE_PLACEHOLDER_';
                            break;
                        default:
                            // open_question and any other question types
                            if (!this._.isEmpty(this.question.placeholder)) {
                                return '';
                            }
                            return 'QUESTIONNAIRE._QUESTION_TITLE_PLACEHOLDER_';
                    }
                }
            }
        };

        this.showRequired = function showRequired() {
            return this.question.question_type !== Enums.QuestionnaireQuestionTypes.textbox;
        };

        this.moveToEditMode = function moveToEditMode() {
            if (this.isOwnerMode && !this.isPreviewMode && !this.isViewOnlyMode && this.isEditableMode && !this.isEditMode) {
                this.isEditMode = true;

                this.question_data_before = $.extend(true, {}, this.question.dataOnly());
            }
        };

        this.isConnectedToEventProperty = function isConnectedToEventProperty() {
            // For share event info, tell what its connected to.
            if (this.question.connected_to_property) {
                var theProp = this.question.connected_to_property;
                this.question.property_text = Enums.QuestionnaireConnectedEventProperties[theProp];
                return this.question.property_text;
            }
        };

        this.isQuestionEditable = function isQuestionEditable() {
            return this.isOwnerMode && !this.isPreviewMode && !this.isViewOnlyMode && this.isEditableMode;
        };

        this.isAnswerEditable = function isAnswerEditable() {
            return !this.isOwnerMode && this.isEditableMode && !this.isViewOnlyMode;
        };

        this.allowEditMode = function allowEditMode() {
            return this.isEditableMode && this.isOwnerMode;
        };

        this.gotoNextAnswer = function gotoNextAnswer(focused_answer) {
            var current_answer_index = this.question.optional_answers.indexOf(focused_answer);
            if (current_answer_index < this.question.optional_answers.length - 1) {
                angular.element($('#option-input-' + this.question.optional_answers[current_answer_index + 1]._id).focus());
            } else {
                this.addOptionalAnswer();
            }
        };

        this.addOptionalAnswer = function addOptionalAnswer() {
            this.QuestionService.addOptionalAnswer(this.question);

            // get id of newest option added to question
            var input_id = this.question.optional_answers[this.question.optional_answers.length - 1]._id;

            // TODO use $applyAsync
            $timeout(function () {
                angular.element($('#option-input-' + input_id).focus());
            });
        };

        this.removeOptionalAnswer = function removeOptionalAnswer(answer_id, event) {
            event.stopPropagation();
            this.QuestionService.removeOptionalAnswer(this.question, answer_id);
        };

        this.updateQuestion = function updateQuestion() {
            if (this.question.supportingMultipleAnswers()) {
                this.question.optional_answers = this._.reject(this.question.optional_answers, function (optional_answer) {
                    var text = optional_answer.answer_text;
                    return this._.isNull(text) || text.trim().length === 0;
                }.bind(this));
            }


            if (!_.isEqual(this.question_data_before, this.question.dataOnly())) {
                this._updateQuestion();
            }
        };

        this.deleteQuestion = function deleteQuestion() {

            if (this.deleteInProgress) {
                return;
            }

            this.deleteInProgress = true;

            this._deleteQuestion().finally(function final() {
                this.deleteInProgress = false;
            }.bind(this));
        };

        this.is_intro_question = this.isIntroQuestion === "true";
        this.canEditOptionalAnswer = (this.dontAllowAnswersEdit !== "true");

        this.questionTextIsBlank = function questionTextIsBlank() {
            return this._.isEmpty(this.question.question_text);
        };

        this.hideQuestionOnViewPreviewModes = function hideQuestionOnViewPreviewModes() {
            if (this.isQuestionEditable()) {
                return false;
            }

            return !this.question.isShownToClient();
        };
    }

    var questionController = Class(Controllers.BaseController, {
        constructor: questionControllerFunc
    });

    return {
        restrict: 'E',
        scope: {
            question: '=hbQuestionModel',
            questionnaire: '=hbQuestionnaireModel',
            focusedOnId: '=focusedOnId',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            isViewOnlyMode: '=isViewOnlyMode',
            _deleteQuestion: '&deleteQuestion',
            _updateQuestion: '&updateQuestion',
            isIntroQuestion: '@',
            dontAllowAnswersEdit: '@',
            owner: '=owner',
            questionIndex: '=questionIndex'
        },
        templateUrl: 'angular/app/modules/common/ui_components/questions/question_directive_template.html',
        controller: questionController,
        controllerAs: 'questionVm',
        bindToController: true
    };
};
