(function () {
    "use strict";

    // @ngInject
    function SettingsControllerCtor($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SettingsController';
    }

    Controllers.SettingsController = Class(Controllers.BaseController, {
        constructor: SettingsControllerCtor,
    });
}());
