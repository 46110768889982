(function () {
    'use strict';

    class ClientPortalLoginController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $stateParams, UsersManager, ClientPortalService, RegexService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalLoginController';

            this.$stateParams = $stateParams;
            this.UsersManager = UsersManager;
            this.ClientPortalService = ClientPortalService;
            this.RegexService = RegexService;

            this.user = UsersManager.newUser();

            this._initPortalModel();
            this._initClientPortalState();
        }

        _initClientPortalState() {

            this.states = {
                create: 'create',
                login: 'login',
                fullLogin: 'fullLogin'
            };

            if (this.$stateParams.email && this.RegexService.isEmailValid(this.$stateParams.email)) {
                this.email = this.$stateParams.email;
            }

            if (this.email) {
                this.user.email = this.email;
                if (this.$stateParams.guid) {
                    this.currentState = this.states.create;
                } else {
                    this.currentState = this.states.login;
                }
            } else {
                this.currentState = this.states.fullLogin;
            }
        }

        _initPortalModel() {
            this.portalModel = this.ClientPortalService.getConfiguration();
        }

        goToLogin() {
            this.currentState = this.states.login;
        }
    }

    Components.ClientPortalLogin = {
        controller: ClientPortalLoginController,
        name: 'hbClientPortalLogin',
        templateUrl: 'angular/app/modules/core/features/login/client_portal/client_portal_login_template.html',
    };
}());
