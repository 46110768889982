(function () {
    "use strict";



  class RescheduleChooseDateComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, UIUtils, AnalyticsService) {
        super($scope, $injector);
        this.__objectType = 'RescheduleChooseDateComponent';
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;
        this.openCollisionInNewTab = true;

    }

    $onInit() {
        this.currentDate = this.UIUtils.formatDate(this.originalProject.event_date, 'MMMM Do, YYYY');
        this.currentTime = this.originalProject.event_time_start;

        if (this.originalProject.event_end_date) {
            this.endDate = this.UIUtils.formatDate(this.originalProject.event_end_date, 'MMMM Do, YYYY');
            this.endTime = this.originalProject.event_time_end;
        }
    }

    clearDate() {
        this.onDateTimeSelected({changes: {event_date: null, event_time_start: null, event_end_date: null, event_time_end: null}});
    }

    setTimezoneWrapper(timezone) {
      this.setTimezone({timezone: timezone.item});
    }

    dismiss() {
      this.onDismiss && this.onDismiss();
    }

  }

  Components.RescheduleChooseDateComponent = {
    bindings: {
        onComplete: '&',
        onDismiss: '&',
        project: '<',
        originalProject: '<',
        timezone: '<',
        timezones: '<',
        isUnpause: '<',
        onDateTimeSelected: '&',
        setTimezone: '&',
        shouldShowDateConflicts: '<',
        nextStepTitle: '<',
        activeWorkflow: '<'
    },
    controller: RescheduleChooseDateComponent,
    name : 'hbRescheduleChooseDate',
    templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_choose_date_template.html',
  };
}());
