(function () {
    'use strict';

    Directives.FileSettingsDirective = function FileSettingsDirective() {

        // @ngInject
        function FileSettingsDirectiveControllerFunc($scope, $injector, WorkspaceFileService, WorkspaceFilesManager, Enums, AnalyticsService, _, AppStates, $translate, PopupMessageService, UIUtils, $state, ModalService, UsersManager, ReactModalService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'FileSettingsController';

            this.AnalyticsService = AnalyticsService;
            this.WorkspaceFileService = WorkspaceFileService;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.AppStates = AppStates;
            this.PopupMessageService = PopupMessageService;
            this.UIUtils = UIUtils;
            this._ = _;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$state = $state;
            this.ModalService = ModalService;
            this.UsersManager = UsersManager;
            this.ReactModalService = ReactModalService;

            this.paymentOptionsModel = {
                options: [
                    { value: null, label: $translate.instant('FILE.DRAWER.PAYMENT_METHODS._BOTH_') },
                    { value: this.Enums.PaymentMethods.bankAccount, label: $translate.instant('FILE.DRAWER.PAYMENT_METHODS._BANK_TRANSFER_') },
                    { value: this.Enums.PaymentMethods.creditCard, label: $translate.instant('FILE.DRAWER.PAYMENT_METHODS._CREDIT_CARD_') }
                ]
            };

            this.initAutoExpirationModel();
            this.setAutoPayToggleState();

            this.gotWorkspaceFile();
            this.register(this.workspaceFile, 'success', this.gotWorkspaceFile.bind(this));
        }


        var FileSettingsController = Class(Controllers.BaseController, {
            constructor: FileSettingsDirectiveControllerFunc,

            gotWorkspaceFile: function gotWorkspaceFile() {
                this.allowGratuity = this.workspaceFile.allow_gratuity;
                this.enableClientNotes = this.workspaceFile.isEnableClientNotes();

                this.autoPaymentActive = false;
                this.setAutoPayToggleState();
                this.initAcceptedPaymentMethod();
                this.initAutoExpirationModel();
            },

            initAcceptedPaymentMethod: function initAcceptedPaymentMethod() {
                this.paymentOptionsModel.selected = this._.find(this.paymentOptionsModel.options, function (option) {
                    return option.value === this.workspaceFile.accepted_payment_method;
                }.bind(this));
            },

            onAcceptedPaymentMethodChanged: function onAcceptedPaymentMethodChanged() {
                var selectedPaymentMethod = this.paymentOptionsModel.selected.value || this.paymentOptionsModel.options[0].label;
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.select_accepted_payment_method, { payment_method: selectedPaymentMethod });
                this.WorkspaceFilesManager.updateAcceptedPaymentMethod(this.workspaceFile, this.paymentOptionsModel.selected.value);
            },

            printWorkspaceFile: function printWorkspaceFile() {
                this.WorkspaceFileService.printWorkspaceFile(this.workspaceFile);
            },

            downloadAsPdf: function downloadAsPdf() {
                this.WorkspaceFileService.downloadAsPdfFile(this.workspaceFile);
            },

            toggleAllowGratuity: function toggleAllowGratuity() {
                if (this.workspaceFile.is_canceled) {
                    return;
                }
                this.workspaceFile.updateAllowGratuity(this.allowGratuity);
            },

            toggleEnableClientNotes: function toggleEnableClientNotes() {
                if (this.workspaceFile.is_canceled) {
                    return;
                }
                this.workspaceFile.updateEnableClientNotes(this.enableClientNotes);
            },

            expireToggleIsOn: function expireToggleIsOn() {
                return ((this.workspaceFile.auto_expiration && this.workspaceFile.auto_expiration.auto_expiration_state === 'active') || this.workspaceFile.is_canceled);
            },

            updateAutoExpiration: function updateAutoExpiration(option) {
                var oldSelection = this.workspaceFile.auto_expiration;

                var apiPromise;
                if (this.autoExpirationModel.selected.serverValue === 'never') {
                    apiPromise = this.workspaceFile.deleteAutoExpiration();
                } else {
                    apiPromise = this.workspaceFile.updateAutoExpiration(this.autoExpirationModel.selected.serverValue);
                }

                var analyticsParams = {
                    expire_period: this.autoExpirationModel.selected.serverValue
                };

                apiPromise.then(function success() {
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.update_file_auto_expire, analyticsParams);
                }.bind(this))
                    .catch(function fail(error) {
                        this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.update_file_auto_expire, analyticsParams);
                        // set back the old selection before the update of the server
                        this.workspaceFile.auto_expiration = oldSelection;

                        this.initAutoExpirationModel();

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, {
                            translationId: 'FILE.ALERTS._UNABLE_TO_SET_AUTO_EXPIRATION',
                            interpolateParams: {
                                fileType: this.UIUtils.fileTypeTranslate(this.workspaceFile.file_type_cd)
                            }
                        });
                    }.bind(this));
            },

            confirmUpdateAutoExpiration: function confirmUpdateAutoExpiration() {
                if (this.autoExpirationModel.selected.serverValue === 'now') {
                    this.expireWorkspaceFile();
                    this.autoExpirationModel.selected = this.expireNowOption;
                } else {
                    this.updateAutoExpiration();
                }
            },

            expireWorkspaceFile: function expireWorkspaceFile() {
                var trackingData = {
                    workspace_file_id: this.workspaceFile._id
                };

                this.workspaceFile.deactivateWorkspaceFile().then(function success() {
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.file_expired, trackingData);
                }.bind(this))
                    .catch(function error() {
                        this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.file_expired, trackingData);
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'FILE.BLOCK._ERROR_');
                    }.bind(this));
            },


            toggleExpireSwitch: function toggleExpireSwitch() {
                var user = this.UsersManager.getCurrUser();
                if (this.expireToggleIsOn()) {
                    this.autoExpirationModel.selected = this.neverExpireOption;
                    this.updateAutoExpiration();

                    if (this.workspaceFile.is_canceled) {
                        if (this.workspaceFile.isRecurringPayment() && !user.account.card_on_file_enabled) {
                            this.ReactModalService.openEnableCardOnFileModal('unexpired');
                            return;
                        } else {
                            this.unexpireWorkspaceFile();
                            this.autoExpirationModel.selected = this.expireNowOption;
                        }
                    }

                } else {
                    this.autoExpirationModel.selected = this.expireFourWeeksOption;
                    this.updateAutoExpiration();

                    if (this.workspaceFile.is_canceled) {
                        if (this.workspaceFile.isRecurringPayment() && !user.account.card_on_file_enabled) {
                            this.ReactModalService.openEnableCardOnFileModal('unexpired');
                            return;
                        } else {
                            this.unexpireWorkspaceFile();
                        }
                    }
                }
            },

            unexpireWorkspaceFile: function unexpireWorkspaceFile() {
                var trackingData = {
                    workspace_file_id: this.workspaceFile._id
                };

                this.workspaceFile.activateWorkspaceFile().then(function success() {
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
                }.bind(this))
                    .catch(function error(res) {
                        this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
                        var error_message = 'FILE.UNBLOCK._ERROR_';
                        if (res && res.data && res.data.error_message) {
                            error_message = res.data.error_message;
                        }
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, error_message);
                    }.bind(this));
            },

            initAutoExpirationModel: function initAutoExpirationModel() {
                this.autoExpirationModel = {
                    options: [
                        { serverValue: 'now', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._NOW_') },
                        { serverValue: 'never', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._NEVER_') },
                        { serverValue: 'one_week', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._ONE_WEEK_') },
                        { serverValue: 'two_weeks', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._TWO_WEEKS_') },
                        { serverValue: 'three_weeks', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._THREE_WEEKS_') },
                        { serverValue: 'four_weeks', displayKey: this.$translate.instant('FILE.DRAWER.EXPIRY._FOUR_WEEKS_') }
                    ],
                    selected: null,
                    updatingDefaultAutoExpiration: false
                };

                this.expireNowOption = this.autoExpirationModel.options[0];
                this.neverExpireOption = this.autoExpirationModel.options[1];
                this.autoExpirationModel.selected = this.neverExpireOption;
                this.expireFourWeeksOption = this.autoExpirationModel.options[5];

                // set the invalidation model according to the server's button
                if (this.workspaceFile.auto_expiration && this.workspaceFile.auto_expiration.period) {

                    var serverPeriod = this._.detect(this.autoExpirationModel.options, function (option) {
                        return option.serverValue === this.workspaceFile.auto_expiration.period;
                    }.bind(this));

                    if (serverPeriod) {
                        this.autoExpirationModel.selected = serverPeriod;
                        this.autoExpirationModel.oldSelected = serverPeriod;
                    }
                } else {
                    this.autoExpirationModel.selected = this.neverExpireOption;
                }
            },

            goToAutomation: function goToAutomation() {
                this.$state.go(this.AppStates.root_core_navigation_settings_company_preferences);
            },

            confirmExpiration: function confirmExpiration() {
                this.ModalService.ConfirmFileExpirationModal().then(function (result) {
                    if (!result) {
                        this.autoExpirationModel.selected = this.autoExpirationModel.oldSelected;
                        return;

                    } else {
                        this.confirmUpdateAutoExpiration();
                    }
                }.bind(this));
            },

            onAutopayToggle: function onAutopayToggle() {
                var user = this.UsersManager.getCurrUser();
                if (!user.account.card_on_file_enabled && this.autoPaymentActive) {
                    this.ReactModalService.openEnableCardOnFileModal('autopay');
                    this.autoPaymentActive = !this.autoPaymentActive;
                    return;
                }
                if (this.autoPaymentDisabled) {
                    this.autoPaymentActive = !this.autoPaymentActive;
                    var autoPayPopupText = this.autoPaymentActive ? 'FILE.ALERTS._UNABLE_TO_TOGGLE_OFF_AUTO_PAY_' : 'FILE.ALERTS._UNABLE_TO_TOGGLE_ON_AUTO_PAY_';
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, autoPayPopupText);
                    return;
                }

                var status = this.workspaceFile.payments_container.auto_payment && this.workspaceFile.payments_container.auto_payment.status;
                var newStatus = this.Enums.AutoPaymentStatus.inactive;
                if (!status || status === this.Enums.AutoPaymentStatus.inactive) {
                    newStatus = this.Enums.AutoPaymentStatus.pendingApproval;
                }

                // optimistic
                this.autoPaymentActive = newStatus === this.Enums.AutoPaymentStatus.pendingApproval;
                var oldDisabledState = this.autoPaymentDisabled;
                this.setAutoPayToggleState();

                var analData = {
                    status: newStatus,
                    retainer_paid: this.workspaceFile.payments_container.payments[0].is_paid,
                    mid_schedule: this.workspaceFile.payments_container.payments.length > 2 && this.workspaceFile.payments_container.payments[1].is_paid
                };

                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.toggle_autopay, analData);

                this.WorkspaceFilesManager.updateAutoPayment(this.workspaceFile, newStatus).catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.toggle_autopay, analData);
                    this.autoPaymentDisabled = oldDisabledState;
                }.bind(this));
            },

            setAutoPayToggleState: function setAutoPayToggleState() {
                var onePaymentLeft = (this.workspaceFile.payments_container.getUnpaidPayments().length <= 1 || this.workspaceFile.payments_container.payments.length <= 1);
                this.autoPaymentDisabled = onePaymentLeft;
                var autoPayment = this.workspaceFile.payments_container.auto_payment;
                if (autoPayment) {

                    // active for vendor - pending approval/active (by client)
                    this.autoPaymentActive = this.workspaceFile.payments_container.isAutoPaymentActive() || this.workspaceFile.payments_container.isAutoPaymentPending();

                    // disabled - activated by client/only 1 payment left
                    this.autoPaymentDisabled = !this.workspaceFile.payments_container.isAutoPayByVendor() && (this.autoPaymentActive || onePaymentLeft);
                }
            },

            isNewBrochure: function isNewBrochure() {
                return this.WorkspaceFileService.isNewBrochure(this.workspaceFile);
            },
        });

        return {
            scope: {
                workspaceFile: '='
            },
            templateUrl: 'angular/app/modules/core/features/workspace_file/file_settings/file_settings_template.html',
            controller: FileSettingsController,
            controllerAs: 'fileSettingsVm',
            bindToController: true
        };

    };

})();