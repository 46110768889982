/**
 * Created by inonstelman on 1/1/15.
 */

Directives.AvatarPopupDirective = function AvatarPopupDirective() {

    var AVATAR_POPUP_IMAGE_WIDTH = 168;

    // @ngInject
    function AvatarPopupDirectiveControllerFunc(
            $, $element, $timeout, AvatarService, AnalyticsService,
            UsersManager, $scope, $injector, UIUtils,
            ImageService) {

        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AvatarPopupDirectiveController';
        self.AnalyticsService = AnalyticsService;
        this.AvatarService = AvatarService;
        this.avatarPopup = $element.find('.avatar-box');
        this.ImageService = ImageService;
        this.isOpen = false;
        this.UsersManager = UsersManager;
        self.$ = $;
        this.UIUtils = UIUtils;
        this.currUser = this.UsersManager.getCurrUser();
        this.networkProfileTarget = '_blank';
        this.AVATAR_POPUP_IMAGE_WIDTH = AVATAR_POPUP_IMAGE_WIDTH;
        this.$timeout = $timeout;

        // keep track on mouse hovers so it will be possible to
        // actually interact with the popup.
        this.refCount = 0;

        self.hoverOn = function hoverOn() {
            this.refCount++;
            self.sendAnalyticsTimeout = setTimeout(
                function () {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.avatar_hover_event);
                }, 2000
            );
        };

        self.hoverOff = function hoverOff() {

            clearTimeout(self.sendAnalyticsTimeout);

            //give the user a chance to hover over the avatar
            $timeout(function () {
                this.refCount = Math.max(this.refCount - 1, 0);
                this.closePopup();
            }.bind(this), 100);
        };

    }

    var AvatarPopupDirectiveController = Class(Controllers.BaseController, {
        constructor: AvatarPopupDirectiveControllerFunc,

        closePopup: function closePopup() {
            if (this.refCount === 0) {
                this.avatarPopup.css('left', '-99999px');
                this.isOpen = false;
            }
        },
    });

    return {
        scope: {

        },
        templateUrl: 'angular/app/modules/common/ui_components/avatar/avatar_popup/avatar_popup_directive_template.html',
        controller: AvatarPopupDirectiveController,
        controllerAs: 'avatarPopupVm',
        bindToController: true
    };
};
