(function () {
    "use strict";

    // @ngInject
    function CustomUrlModalControllerCtor($scope, $injector, $modalInstance, icon, title, text, positiveBtnText) {

        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'CustomUrlModalControllerCtor';
        this.$modalInstance = $modalInstance;
        this.icon = icon;
        this.title = title;
        this.message = text;
        this.positiveBtnText = positiveBtnText;
    }

    Controllers.CustomUrlModalController = Class(Controllers.BaseController, {
        constructor: CustomUrlModalControllerCtor,

        posBtnClick: function posBtnClick() {
            this.$modalInstance.close();
        }
    });
}());
