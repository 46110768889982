(function () {
    "use strict";

    // @ngInject
    function LoginTwoFactorAuthServiceCtor(UsersManager, ModalService, ToastService, AppConfigService, $q) {
        this.ToastService = ToastService;
        this.ModalService = ModalService;
        this.AppConfigService = AppConfigService;
        this.$q = $q;
    }

    Services.LoginTwoFactorAuthService = Class(() => {

        return {
            constructor: LoginTwoFactorAuthServiceCtor,

            addLoginTwoFactorAuth: function addLoginTwoFactorAuth(user) {
                let message, rejectionKey;
                if (!this.securityPhoneNumberExist(user)) {
                    message = 'Please add a security phone number first.';
                    rejectionKey = 'missing_security_phone_number';
                } else if (!user.isEmailVerified() && !this.AppConfigService.isE2E()) {
                    message = 'Please confirm your email first.';
                    rejectionKey = 'verify_email_first';
                } else {
                    return this.ModalService.openLoginTwoFactorAuthModal(user);
                }
                this.ToastService.showError({
                    iconCSSClass: 'icon-hb-nx-exclamation-mark-circle',
                    content: message,
                    dismissOnTimeout: true,
                    timeout: 5000,
                    dismissButton: true
                });
                return this.$q.reject(rejectionKey);
            },

            securityPhoneNumberExist: function securityPhoneNumberExist(user) {
                return !!user.securityPhoneNumberLastFourDigits();
            },

        };
    });
}());