/**
 * hb-tools-list-items component
 *
 *
 *
 * parameters:
 * category: Object of catergoy
 * onButtonClick : Function to be called on item click
 * tools: Array of category's items
 */


(function () {
    'use strict';

    class ToolsItemsListController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, Enums, $translate, AppStates, $state, $) {
            super($scope, $injector);
            this.$state = $state;
            this.Enums = Enums;
            this.$ = $;
        }

        itemClicked(tool, event) {
            this.$(event.currentTarget).blur();

            // tool click event
            tool.onClick && tool.onClick();

            if (angular.isFunction(this.onButtonClick)) {
                this.onButtonClick({item:tool});
            }
        }

        isNewTool(tool) {
            return (tool.status === this.Enums.Tools.statuses.new)
        }
    }

    Components.ToolsItemsList = {
        bindings: {
            category: '<',
            onButtonClick : '&',
            tools: '<'
        },
        controller: ToolsItemsListController,
        name: 'hbToolsCategory',
        templateUrl: 'angular/app/modules/core/features/top_nav/tools/tools_component_items_list.html',
    };

}());
