Directives.GalleryDirective = function GalleryDirective() {

    // @ngInject
    function GalleryDirectiveControllerFunc($scope, $injector,$timeout, _, $q, FlowsBetaUserTypeService, DeviceService,
                                            PhotosUploadManager,ModalService, PopupMessageService, AnalyticsService,
                                            UIUtils, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GalleryDirectiveController';
        this.PhotosUploadManager = PhotosUploadManager;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.UIUtils = UIUtils;
        this.$timeout = $timeout;
        this._ = _;
        this.$q = $q;
        this.ModalService = ModalService;
        this.DatadogRUMService = DatadogRUMService;

        this.uploadingImageFiles = [];
        this.numberOfFilesUploading = 0;

        // 15 MB limit
        this.fileSizeLimit = 15 * 1024 * 1024;

        this.uploadIngFilesPromises = [];

        this.imageHelpAssets = {
            fileHeader:'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/main_general/image_type_assets/Gallery_proposalheader.png',
            imagesInFile:'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/main_general/image_type_assets/Gallery_imagesinFile.png',
            eventHeader: 'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/main_general/image_type_assets/Gallery_eventheader.png'
        };

        // Show more of gallery on big screens
        var winHeight = window.innerHeight;
        this.imagesContainerHeight = winHeight > 1000 ? 450 : 300;
        this.imageContainerExpended = false;
        this.imagesContainerText = "More";
        this.imagesExpandButtonShow = false;


        this.load();
        if(!this.imagesPreviewWidth) {
            this.imagesPreviewHeight = this.imagesPreviewHeight || '150';
        }

        this.deleteFileFromThumbnail = function(file) {
            this.deleteFile({ file: file });
        }.bind(this);

        this.addCoverImage = function(images) {
            images.forEach(function(image) {
                this.model.cover_images.push(image);
            }.bind(this))
        }.bind(this);

        this.imagesForDisplay = [];
        this.loadLimit = 500;

        var unwatchImages = $scope.$watchCollection('galleryVm.images', function () {
            this.imagesForDisplay = this.images.slice().reverse();
        }.bind(this));

        var unwatchFiles = $scope.$watchCollection('galleryVm.files', function () {
            this.thumbnailsLoaderParams = {
                width: 100,
                height: 120,
                style: { marginRight: '7px' },
                count: this.files ? this.files.length : 0
            };
        }.bind(this));

        $scope.$on('$destroy', function () {
            unwatchFiles();
            unwatchImages();
        });
    }

    var GalleryDirectiveController = Class(Controllers.BaseController, {
        constructor: GalleryDirectiveControllerFunc,
        
        // TBD - Currently we check overflow existance after few seconds. Should be changed to ng-repeat last event or something
        load: function load() {
            var self = this;

            var dropZone = document.getElementById('drop_zone');
            dropZone.addEventListener('dragover', self.handleDragOver, false);
            dropZone.addEventListener('drop', self.handleFileSelect, false);

            dropZone = document.getElementById('drop_zone2');
            dropZone.addEventListener('dragover', self.handleDragOver, false);
            dropZone.addEventListener('drop', self.handleFileSelect, false);
        },

        checkOverflow: function checkOverflow() {
            var self = this;
            var element = document.querySelector('#ImagesContainer');

            if( element != null && element.clientHeight < element.scrollHeight){
                self.imagesExpandButtonShow = true;
            }
            else{
                self.imagesExpandButtonShow = false;
            }
        },

        readFileAsDataUrl: function readFileAsDataUrl(fileViewModel) {
            var self = this;

            var fileReader = new FileReader();
            fileReader.readAsDataURL(fileViewModel.file);
            fileReader.onload = function (e) {
                self.$timeout(function () {
                    fileViewModel.dataUrl = e.target.result;
                });
            };
        },

        generateThumb: function generateThumb(filesViewModels) {
            var self = this;
            var fileReaderSupported = window.FileReader != null;

            if (filesViewModels != null && filesViewModels.length > 0) {
                for (var fileIndex = 0; fileIndex < filesViewModels.length; fileIndex++) {
                    var fileViewModel = filesViewModels[fileIndex];
                    if (fileReaderSupported && fileViewModel.file.type.indexOf('image') > -1) {
                        self.readFileAsDataUrl(fileViewModel);
                    }
                }
            }
        },

        toggleShowImageAnswer : function toggleShowImageAnswer(){
            var self=this;
            if(!self.showImageAnswer){
                self.showImageAnswer=true;
            }
            else{self.showImageAnswer=false;}
        },

        onFilesDropped: function onFilesDropped($files) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_dragged_and_dropped, this.analyticsProperties());

            this.upload($files);
        },

        onFileSelected: function onFileSelected($files) {
            if ($files && $files.length > 0) {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_selected, this.analyticsProperties());

                this.upload($files);
            }
        },

        createDownloadableLink: function createDownloadableLink(file) {
            if(file.cloudinary_public_id != null) {
                var result = file.url.split('/upload/');
                return result.join('/upload/fl_attachment/');
            }
            else
            {
                return file.url;
            }
        },

        createDownloadSaveAsName: function createDownloadSaveAsName(file) {
            return file.url.substring(decodeURIComponent(file.url).lastIndexOf('/')+1);
        },

        deleteImage: function deleteImage(id) {
            if (angular.isArray(this.company.cover_images) && this.company.cover_images.length === 1) {
                //this is the last image that user is trying to delete. Do not allow this.
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.GALLERY._CAN_NOT_REMOVE_LAST_IMAGE_MESSAGE_');

                return;
            }
            // First send the delete to the server
            this.CompaniesManager.deleteCoverImage(this.company, id);

            // Second update the model immediately for better UI experience
            var index;
            this._.any(this.company.cover_images, function (item, idx) {
                if (item._id === id) {
                    index = idx;
                    return true; // brake the iteration
                }
            });

            if (index !== undefined) {
                this.company.cover_images.splice(index, 1);
            }
        },

        loadUploadModule: function loadUploadModule() {        
            var self = this;

            var model = {
                instance: this.model,
                type: this.modelType,
                dontUpload: true
            };
            
            this.ModalService.openAddImageToMessageModal(model).then(
                function success(filesList) {
                    if (filesList != null && filesList.length > 0) {
                        self.upload(filesList);
                    }
                },
                function error() {
                }
            );
        },

        markAsFinishedUploading: function setStatusToFinish(resolvedFilesDetailslRef) {
            var self = this;

            return function () {
                resolvedFilesDetailslRef.forEach(function (filesDetails) {
                    var indexToRemove = self._.findIndex(self.uploadingImageFiles, filesDetails.fileViewModel);
                    self.uploadingImageFiles.splice(indexToRemove, 1);
                    filesDetails.fileViewModel.uploading = false;
                    filesDetails.fileViewModel.uploaded = filesDetails.fileViewModel.uploadFailedInTheMiddle;
                    self.numberOfFilesUploading--;
                });
            };
        },

        upload: function upload(filesList) {

            var self = this;

            // Filter files larger than configured threshold and update user if relevant
            var removedLargeFiles = false;
            var fileSizeLimit = this.fileSizeLimit;
            var cleanedFiles = filesList.filter( function(file) {
                return file.size <= fileSizeLimit;
            });
            if (cleanedFiles.length < filesList.length) {
                removedLargeFiles = true;
                filesList = cleanedFiles;
            }
            if (removedLargeFiles) {
                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'UPLOAD_FILE._ERROR_FILE_TOO_LARGE_');
            }

            var resolvedFilesDetails = [];
            var uploadedFilesCount = 0;
            var saveUploadedFilesToHB = function () {
                var resolvedFilesDetailslRef = resolvedFilesDetails;
                var succeedUploadToS3Files = resolvedFilesDetailslRef.filter(function(x) { return !x.uploadFailedInTheMiddle; });
                resolvedFilesDetails = [];
                var uploadPromise = null;

                if(self.modelType === 'company_file') {
                    uploadPromise = self.addMultipleFiles({company: self.model, filesDetails: succeedUploadToS3Files.map(function (x) { return x.uploadedFileData; })});
                }
                else {
                    uploadPromise = self.$q.all(succeedUploadToS3Files.map(function(imageToUpload) {
                        uploadPromise = self.addImage({s3response: imageToUpload.s3response, type: imageToUpload.type});
                    }));
                }

                return uploadPromise
                    .catch(function(resp) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.GALLERY._ERROR_UPLOADING_FILE_');
                        var message = "Error saving the files: " + succeedUploadToS3Files.map(function(x) {return x.fileViewModel.name; }).join(', ') + " resp: " + resp;
                        self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.error_unable_to_add_image_to_company_settings, message, self.analyticsProperties());

                        succeedUploadToS3Files.forEach(function(x) { x.fileViewModel.uploadFailedInTheMiddle = true; });

                        self.DatadogRUMService.addError(new Error("Error in uploading files", {cause: resp}), {
                            source: 'gallery',
                            metadata: self.analyticsProperties()
                        });
                    })
                    .finally(function () {
                        succeedUploadToS3Files.forEach(function (filesDetails) {
                            if (!filesDetails.fileViewModel.uploadFailedInTheMiddle) {
                                filesDetails.fileViewModel.progress = 100;
                            }
                        });

                        //make the extraction of the upload be with a timeout to show the 100%
                        self.$timeout(self.markAsFinishedUploading(resolvedFilesDetailslRef), 100);

                        uploadedFilesCount += resolvedFilesDetailslRef.length;
                        if(uploadedFilesCount === filesList.length) {
                            self.properties.filesCount = uploadedFilesCount;

                            return self.AnalyticsService.track(self, self.uploadImageAnalytics, self.properties);
                        }
                    });
            };

            if(self.modelType === 'company_file') { // upload files in batches
                saveUploadedFilesToHB = this._.debounce(saveUploadedFilesToHB, 1500);
            }

            return self.$q.all(filesList.map(function (file) {
                var fileViewModel;

                if (file.type.toLowerCase().indexOf('image') !== -1) {
                    fileViewModel = self.createFileViewModel({image: file.name, type: 'cover'});
                } else {
                    fileViewModel = self.createFileViewModel({image: file.name, type: 'company_file'});
                }

                fileViewModel.uploaded = false;
                //store on the view model the original file object from the browser
                fileViewModel.file = file;

                //enrich the fileViewModel with data that is required to upload it to s3j
                self.PhotosUploadManager.getUpdatedModelData(fileViewModel);

                var promise = self.PhotosUploadManager.uploadPhoto([file], fileViewModel, self);

                self.numberOfFilesUploading++;
                fileViewModel.uploading = true;
                self.uploadingImageFiles.unshift(fileViewModel);

                self.generateThumb([fileViewModel]);

                file.uploadAPI.progress(function (percentUploaded) {
                    //save the last 5 percent for the upload to the company model.
                    fileViewModel.progress = parseInt(0.95 * percentUploaded);
                });

                return promise
                    .then(function() {
                        self.properties = self.analyticsProperties();
                        self.properties.key = fileViewModel.key;
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_successfully_uploaded, self.properties);

                        fileViewModel.url = file.s3Response.url;
                    })
                    .catch(function error() {
                        var properties = self.analyticsProperties();
                        properties.key = fileViewModel.key;

                        //this is a result of a delete of a file during an upload. remove it from the view model since we know the upload stopped
                        //before it was inserted to our company model
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_deleted_while_uploading, properties);
                        fileViewModel.uploadFailedInTheMiddle = true;
                    })
                    .finally(function () {
                        if(self.modelType === 'company_file') {
                            resolvedFilesDetails.push({
                                fileViewModel: fileViewModel,
                                uploadedFileData: Object.assign({},
                                    file.s3Response,
                                    { type: file.type.toLowerCase().indexOf('image') !== -1 ? 'cover' : 'file' }
                                )
                            });
                        }
                        else {
                            resolvedFilesDetails.push({
                                fileViewModel: fileViewModel,
                                s3response: file.s3Response,
                                type: file.type.toLowerCase().indexOf('image') !== -1 ? 'cover' : 'file'
                            });
                        }

                        saveUploadedFilesToHB();
                    });
            }));
        },

        getSizeForFile: function getSizeForFile(bytes) {
            if (bytes == null)
                return "";
            var decimals = 0;
            if(bytes == 0) return '0 bytes';
            var k = 1000; // or 1024 for binary
            var dm = decimals + 1 || 3;
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        
        changeImagesContainerHeight: function changeImagesContainerHeight() {

            var self = this;
            var winHeight = window.innerHeight;
            self.imageContainerExpended = !self.imageContainerExpended;

            if (self.imageContainerExpended)
                self.imagesContainerHeight = 4000; // some big number to set max-height
            else
                this.imagesContainerHeight = winHeight > 1000 ? 450 : 300;
            
            self.imagesContainerText = self.imageContainerExpended ? "Less" : "More";
        },
        
        handleFileSelect: function handleFileSelect(evt) {
            var self = this;
            evt.stopPropagation();
            evt.preventDefault();

            var files = evt.dataTransfer.files; // FileList object.

            // files is a FileList of File objects. List some properties.
            // var output = [];
            // for (var i = 0, f; f = files[i]; i++) {
            //     output.push('<li><strong>', escape(f.name), '</strong> (', f.type || 'n/a', ') - ',
            //         f.size, ' bytes, last modified: ',
            //         f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a',
            //         '</li>');
            // }
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                "Please use 'UPLOAD' button on the top to add new files");
        },

        handleDragOver: function handleDragOver(evt) {
            evt.stopPropagation();
            evt.preventDefault();
            evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
        }
    });

    return {
        scope: {
            images: '=images',
            files: '=files',
            formattedCompanyAssets: '=formattedCompanyAssets',
            analyticsProperties: '&analyticsProperties',
            deleteImage: '&deleteImage',
            deleteFile: '&deleteFile',
            setDefaultCoverImage: '&setDefaultCoverImage',
            defaultCoverImageId: '=defaultCoverImageId',
            createFileViewModel: '&createFileViewModel',
            addImage: '&addImage',
            addMultipleFiles: '&addMultipleFiles',
            model: '=model',
            isShowDimensionsExplanation: '=isShowDimensionsExplanation',
            uploadImageAnalytics: '=uploadImageAnalytics',
            uploadFileAnalytics: '=uploadFileAnalytics',
            modelType: '@modelType',
            setPhotoAsDefaultText: "=setPhotoAsDefaultText",
            photoIsCoverText: '=photoIsCoverText',
            imagesPreviewHeight: '=?',
            imagesPreviewWidth: '=?'
        },
        templateUrl: 'angular/app/modules/common/ui_components/gallery/gallery_directive_template.html',
        controller: GalleryDirectiveController,
        controllerAs: 'galleryVm',
        bindToController: true
    };
};
