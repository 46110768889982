(function () {
    "use strict";

    // @ngInject
    function AddCompanyModalControllerCtor($scope, $injector, $modalInstance, CompanyService) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'AddCompanyModalController';
        this.$modalInstance = $modalInstance;

        this.companyTypes = CompanyService.getCompanyTypesSorted();
    }

    Controllers.AddCompanyModalController = Class(Controllers.BaseController, {
        constructor: AddCompanyModalControllerCtor,

        dismiss: function dismiss() {
            this.$modalInstance.dismiss();
        },

        onCompanyTypeChange(item) {
            this.companyType = item.key;
        },

        create: function create() {
            this.$modalInstance.close({companyType: this.companyType, companyName: this.companyName});
        }
    });
}());
