(function () {
    "use strict";

    Controllers.ChooseTitleController = class ChooseTitleController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $, AnalyticsService, $modalInstance, config, ModalService) {
            super($scope, $injector);
            this.__objectType = 'ChooseTitleController';

            this.$ = $;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.$modalInstance = $modalInstance;
            this.newFileName = config.currentTitle;
            this.modalTitle = config.modalTitle;
            this.modalInputTitle = config.modalInputTitle;
        }

        closeModal() {
            this.$modalInstance.dismiss();
        }

        onComplete() {
            this.$modalInstance.close(this.newFileName);
        }
    };
}());
