class FeedItemFlowServiceSelectionController extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, $translate, $filter, $window, Enums, UsersManager, AnalyticsService, FlowService) {
        super($scope, $injector);
        this.__objectType = 'FeedItemFlowServiceSelectionController';

        this.$translate = $translate;
        this.$filter = $filter;
        this.$window = $window;
        this.Enums = Enums;
        this.actionType = this.activity.action_type;
        this.AnalyticsService = AnalyticsService;
        this.FlowService = FlowService;
        this.flowTitle = this.activity.title;
        this.currUser = UsersManager.getCurrUser();
        this.isClient = this.currUser.isClient();
        this.full_name = this.getUserFullName();
        this.hideFeedItem = false;

        $scope.$watch('$ctrl.workspace.flows', (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.flow = this.workspace.flows ? this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id) : null;
            }
        });
    }

    getUserFullName() {
        if (this.activity.user) {
            return this.currUser._id === this.activity.user._id ? 'You' : this.activity.user.full_name;
        }
        return 'Client';
    }

    $onInit() {
        switch (this.activity.action_type) {
            case this.Enums.flowServiceSelectionTypes.serviceSelected:
                this.setFlowServiceSelected();
                break;
            case this.Enums.flowServiceSelectionTypes.automaticFlowCreatedBySelectedService:
                this.setAutomaticFlowCreatedBySelectedService();
                break;
            case this.Enums.flowServiceSelectionTypes.serviceSelectionChanged:
                this.setFlowServiceSelectionChanges();
                break;
        }

        this.flow = this.workspace.flows ? this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id) : null;
    }

    setFlowServiceSelected() {
        let flowTranslation;
        const hasPrice = this.activity.price !== null;
        if (!this.isClient) {
            flowTranslation = hasPrice ?
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_' :
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_NO_PRICE_';
        } else {
            flowTranslation = hasPrice ?
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_CLIENT_' :
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._FLOW_SERVICE_SELECTED_CLIENT_NO_PRICE_';
        }

        this.title = this.$translate.instant(flowTranslation,
            {clientName: this.full_name, flowTitle: this.flowTitle, price: this.activity.price});
        const servicesCount = this.activity.count ? `+ ${this.activity.count}` : '';
        this.description = this.$translate.instant(
            'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_DESCRIPTION_', {
                item_title: this.activity.item_title,
                count: servicesCount
            });
    }

    setAutomaticFlowCreatedBySelectedService() {
        let flowTranslation;
        const hasPrice = this.activity.price !== null;

        if (!this.isClient) {
            flowTranslation = hasPrice ?
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_' :
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._AUTOMATIC_FLOW_CREATED_BY_SELECTED_SERVICE_NO_PRICE_';

            this.title = this.$translate.instant(flowTranslation,
                {clientName: this.full_name, flowTitle: this.flowTitle, price: this.activity.price});
            const servicesCount = this.activity.count ? `+ ${this.activity.count}` : '';
            this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_DESCRIPTION_', {
                item_title: this.activity.item_title,
                count: servicesCount
            });
        } else{
            this.hideFeedItem = true;
        }
    }

    setFlowServiceSelectionChanges() {
        let flowTranslation;
        const hasPrice = this.activity.price !== null;

        if (!this.isClient) {
            flowTranslation = hasPrice ?
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_' :
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_NO_PRICE_';
        } else {
            flowTranslation = hasPrice ?
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_CLIENT_' :
                'WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_CHANGED_CLIENT_NO_PRICE_';
        }

        this.title = this.$translate.instant(flowTranslation,
            {clientName: this.full_name, flowTitle: this.flowTitle, price: this.activity.price});

        this.title += !this.isClient && this.activity.create_draft_from_services_selection ?
            this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._INVOICE_FLOW_UPDATED_') : '';

        const servicesCount = this.activity.count ? `+ ${this.activity.count}` : '';
        this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SERVICE_SELECTION._SERVICE_SELECTION_DESCRIPTION_', {
            item_title: this.activity.item_title,
            count: servicesCount
        });
    }

    onClick(event) {
        event.stopPropagation();
        this.FlowService.goToFlowByUser(this.flow, this.currUser);
    }
}

Components.FeedItemFlowServiceSelection = {
    bindings: {
        activity: '<',
        workspace: '<?'
    },
    controller: FeedItemFlowServiceSelectionController,
    name: 'hbFeedItemFlowServiceSelectionViewer',
    templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_flow_viewer_template.html',
};