Components.HbPrideLogo = {
    // @ngInject
    controller: function HbLogoComponentController() {
        this.$onInit = function $onInit() {
            this.fillColor = this.hbLogo.fillColor;
        };
    },
    name: 'hbPrideLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/pride/pride_logo.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
