Directives.TextareaQuestionDirective = function OpenQuestionDirective() {

    //@ngInject
    function TextareaQuestionDirectiveControllerFunc($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TextareaQuestionDirectiveController';

        this.inputFocused = function inputFocused() {
            this.focusedOnId = this.question._id;
        };

        this.updateQuestion = function updateQuestion() {
            // we want to reset the focus id, in case he clicks outside.
            this.focusedOnId = "";
            this._updateQuestion();
        };
    }

    var TextareaQuestionDirectiveController = Class(Controllers.BaseController, {
        constructor: TextareaQuestionDirectiveControllerFunc
    });

    return {
        restrict: 'E',
        scope: {
            question: '=question',
            _updateQuestion: '&updateQuestion',
            isAnswerEditable: '&isAnswerEditable',
            isQuestionEditable: '&isQuestionEditable',
            focusedOnId: '=focusedOnId',
            isEditMode: '=isEditMode'
        },
        templateUrl : 'angular/app/modules/common/ui_components/questions/textarea/textarea_question_directive_template.html',
        controller: TextareaQuestionDirectiveController,
        controllerAs: 'questionVm',
        bindToController: true
    };
};