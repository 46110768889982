(function () {
    'use strict';

    // @ngInject
    function BirthdayModalController($scope, $injector, $q, $state, ConfettiService, $timeout, UsersManager, $modalInstance, AnalyticsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BirthdayModalController';
        AnalyticsService.trackPageView(this, 'View');
        this.$q = $q;

        
        this.AnalyticsService = AnalyticsService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$modalInstance = $modalInstance;
        this.ConfettiService = ConfettiService;

        this.currUser = UsersManager.getCurrUser();
        this.firstName = this.currUser.first_name;
        this.ConfettiService.show({position: 'bottom', count:300});

        this.$modalInstance.onBeforeClose = this.onBeforeClosingBirthdayModal.bind(this);
    }

    Controllers.BirthdayModalController = Class(Controllers.BaseController, {
        constructor: BirthdayModalController,

        onBeforeClosingBirthdayModal: function onBeforeClosingBirthdayModal() {
            this.ConfettiService.hide();

            var deferred = this.$q.defer();
            deferred.resolve();
            return deferred.promise;

        },

        close: function close() {
            this.$modalInstance.close();
        },

    });
}());