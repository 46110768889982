Directives.SignatureDirective = function SignatureDirective() {

    // @ngInject
    function SignatureDirectiveControllerFunc(PopupMessageService, $timeout, $element, Enums, uuid4, UsersManager, $, PreviewModeService,
                                              $translate, WorkspaceFileService, WorkspaceFilesManager, AnalyticsService, $q,
                                              $scope, $injector, ModalService, WorkspacesManager, _, DeviceService, MobileAppService, $state, AppStates) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SignatureDirectiveController';
        var self = this;
        self.$ = $;
        self.$timeout = $timeout;
        self.Enums = Enums;
        self.ModalService = ModalService;
        self.isSignatureFinal = false;
        self.allowSignVar = null;
        self.user = UsersManager.getCurrUser();
        self.WorkspaceFilesManager = WorkspaceFilesManager;
        self.WorkspaceFileService = WorkspaceFileService;
        self.AnalyticsService = AnalyticsService;
        self.PreviewModeService = PreviewModeService;
        self.WorkspacesManager = WorkspacesManager;
        self.$element = $element;
        self.PopupMessageService = PopupMessageService;
        self.$q = $q;
        self._ = _;
        self.MobileAppService = MobileAppService;
        this.$state = $state;
        this.AppStates = AppStates;


        this.saveAsDefault = false;

        self.previousContent = null;
        self.showDontSignHereError = false;

        this.showNewSignatures = this.fileModel.modelVersion() >= 1;

        this.shouldHideSignature = this.fileModel.file_version_container.draft_editable;

        this.shouldShowReplaceSig = this.signatureModel.signee_type_cd === Enums.ContractSigneeTypes.planner_signature;

        this.isInAppBrowser = DeviceService.isInAppBrowser();

        this.setDisplaysVars = function setDisplaysVars() {
            if (!!this.fileModel.owner) {
                this.allowSignVar = this.allowSign();
                this.allowEditDescriptionVar = this.allowEditDescription();
                this.allowRemoveSignatureVar = this.allowRemoveSignature();
                this.shouldSignHereVar = this.shouldSignHere();

                if (!this.signatureModel.isTyped()) {
                    this.signaturePad = this.signatureElement.signaturePad({displayOnly: true, lineWidth: null});
                    this.signaturePad.regenerate(this.signatureModel.signature);
                }
            }
            this.descriptionPlaceholder = "";
            if (this.allowSignVar) {
                var that = this;
                $translate('AGREEMENT.SIGNATURE._NAME_PLACEHOLDER_').then(function translateResolved(translateMsg) {
                    that.descriptionPlaceholder = translateMsg;
                });
            }
        };

        $scope.$watch('signatureVm.isPreviewMode', function (newVal) {
            this.allowRemoveSignatureVar = this.allowRemoveSignature();
        }.bind(this));

        $scope.$watch('signatureVm.signatureIndex', function (newVal) {
            this.allowRemoveSignatureVar = this.allowRemoveSignature();
        }.bind(this));

        this.initializeSignature = function initializeSignature(element) {
            this.signatureElement = element.find("form");

            this.register(this.fileModel, 'success', function() {
                $timeout(this.setDisplaysVars.bind(this), 100);
            });
            this.setDisplaysVars();
        };

        this.onSignatureClicked = function onSignatureClicked() {
            if(!this.signatureModel.signed_on && this.allowSignVar){
                this.openLargeSignatureDialog();
            }
        };

        this.blockSignature = function blockSignature() {
            var allowSignBeforeSent =
                (this.fileModel.agreement.hasClientFields() && this.isOwnerMode && !this.fileModel.isAgreementSigned() ||
                (this.fileModel.agreement.hasEmptyClientFields && !this.isOwnerMode));
            var shouldBlock = !this.actionWithoutBlock && this.allowSignVar && allowSignBeforeSent;
            this.actionWithoutBlock = false;
            return shouldBlock;
        };

        this.isSignatureBlocked = function isSignatureBlocked(){
            //blocksignature function covers all cases except checking the name input.
            // so we check the name input here.
            if(!this.blockSignature()){
                if (this.$.trim(this.signatureElement.find("#nameInput").val()) !== "") {
                    return false;
                }
                else {
                    AnalyticsService.track(this, 'vendor signature is blocked', {
                        allowSignVar: this.allowSignVar,
                        hasClientFields: this.fileModel.agreement.hasClientFields(),
                        isOwnerModeLocal: this.isOwnerMode,
                        isAgreementSigned: this.fileModel.isAgreementSigned(),
                        hasEmptyClientFields: this.fileModel.agreement.hasEmptyClientFields
                    });
                    return true;
                }
            }
            else {
                AnalyticsService.track(this, 'client signature is blocked', {
                    allowSignVar: this.allowSignVar,
                    hasClientFields: this.fileModel.agreement.hasClientFields(),
                    isOwnerModeLocal: this.isOwnerMode,
                    isAgreementSigned: this.fileModel.isAgreementSigned(),
                    hasEmptyClientFields: this.fileModel.agreement.hasEmptyClientFields
                });
                return true;
            }
        };

        this.changeSignatureSignatory = function changeSignatureSignatory(user) {
            this.signatureModel.changeSignatureSignatory(user);
            this.showSignatories = false;
            this.actionWithoutBlock = true;
        };

        this.updateShowSignatories = function () {
            this.showSignatories = !this.showSignatories;
            this.actionWithoutBlock = true;
        };

        this.signFullName = function signFullName(element) {
            if (this.showBlockReason()) {
                AnalyticsService.track(this, 'vendor full name signature is blocked', {
                    allowSignVar: this.allowSignVar,
                    hasClientFields: this.fileModel.agreement.hasClientFields(),
                    isOwnerModeLocal: this.isOwnerMode,
                    isAgreementSigned: this.fileModel.isAgreementSigned(),
                    hasEmptyClientFields: this.fileModel.agreement.hasEmptyClientFields
                });
            }

        };

        this.showBlockReason = function showBlockReason() {
            if (this.blockSignature()) {
                if (this.isOwnerMode) {
                    var self = this;
                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                        'AGREEMENT.SIGNATURE._VENDOR_SIGNATURE_LOCKED_',
                        function success() {
                            self.signaturePad.clearCanvas();
                            if (!self.showNewSignatures) {
                                // no need to do it on the new signatures since the name is predefined.
                                self.signatureModel.signee_name = '';
                            }
                        });
                }
                else {
                    WorkspaceFileService.validateFileModel();
                }
                return true;
            }
            return false;
        };

        this.allowSign = function allowSign() {
            if (!this.fileModel.isViewOnlyMode() && !this.fileModel.isCanceled() && !this.hasSignature() && this.fileModel.isLatestVersion() &&
                (!this.isOwnerMode || !this.fileModel.agreement.hasClientFields() || this.fileModel.isAgreementSigned()) &&
                ((this.signatureModel.signee_type_cd === this.Enums.ContractSigneeTypes.couple_signature && !this.isOwnerMode) ||
                 (this.signatureModel.signee_type_cd === this.Enums.ContractSigneeTypes.planner_signature && this.isOwnerMode))) {
                return true;
            }
            return false;
        };

        this.allowRemoveSignature = function allowRemoveSignature() {
            if (this.isPreviewMode) {
                return false;
            }

            if (!this.hasSignature() && !this.fileModel.isCanceled() && (this.signatureIndex >= 1) &&
                this.isOwnerMode && this.isEditableMode) {
                return true;
            }
            return false;
        };

        this.shouldSignHere = function shouldSignHere() {

            this.signatureError = 'AGREEMENT.SIGNATURE._SHOW_DONT_SIGN_HERE_ERROR_';

            if (this.PreviewModeService.isInPreviewMode()) {
                return false;
            }

            if (this.fileModel.isCanceled()) {
                this.signatureError = 'AGREEMENT.SIGNATURE._FILE_EXPIRED_ERROR_';
                return false;
            }

            if (!this.hasSignature()) {
                if (!this.fileModel.isLatestVersion()) {
                    this.signatureError = 'AGREEMENT.SIGNATURE._SHOW_SIGN_ACTIVE_VERSION_ERROR_';
                    return false;
                }
                else if (this.isOwnerMode && this.fileModel.agreement.hasClientFields() && !this.fileModel.isAgreementSigned()) {
                    this.signatureError = 'AGREEMENT.SIGNATURE._SIGN_ERROR_CLIENT_FIELDS_';
                    return false;
                }
            }

            if (this.showNewSignatures) {
                return this.user._id === this.signatureModel.signee_id;
            }
            else if (this.signatureModel.signee_type_cd === this.Enums.ContractSigneeTypes.planner_signature && this.isOwnerMode) {
                return true;
            }
            else if (this.signatureModel.signee_type_cd === this.Enums.ContractSigneeTypes.couple_signature && this.firstCoupleSignature && !this.isOwnerMode) {
                return true;
            }

            return false;
        };

        this.allowEditDescription = function allowEditDescription() {
            if (this.isOwnerMode && !this.hasSignature() && this.isEditableMode) {
                return true;
            }

            return false;
        };

        this.hasSignature = function hasSignature() {
            return this.isSignatureValid(this.signatureModel);
        };

        this.openLargeSignatureDialog = function openDialog() {
            //is signature blocked?
            if(this.isSignatureBlocked()){
                //show the reason
                if(!this.showBlockReason()){
                    //if there's no reason, its because the name is not filled out.
                    // show the reason here.
                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                        'AGREEMENT.SIGNATURE._PLEASE_SIGN_FIRST_');
                }
            }
            else{
                //do signature modal.
                var self = this;

                var defaultScaledSignature;
                var drawnSignature; //
                var isDefaultDrawn;
                var defaultSignature = this.isDefaultSignatureAvailable() ? this.user.default_signature : undefined;
                if(defaultSignature){
                    //clone the original default signature so it will not be overrun if the user pressed cancel on the signature modal.
                    defaultSignature = angular.copy(defaultSignature);

                    isDefaultDrawn = (this.user.default_signature.signature_type_cd===this.signatureModel.signatureTypes.drawn);
                    if(isDefaultDrawn){
                        drawnSignature = defaultSignature.signature;
                    }
                }

                if(this.isDefaultSignatureAvailable() && drawnSignature) {
                    defaultScaledSignature = this.scaleDefaultSignature(drawnSignature, {
                        width: 500,
                        height: 200
                    }, 290, 100);

                    defaultSignature.signature = defaultScaledSignature;
                }

                // trigger event if in app browser
                if(this.isInAppBrowser) {
                    this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.sign_opened);
                }

                this.ModalService.openSignatureDialog(this.signatureModel, defaultSignature, !this.isOwnerMode).then(function (data) {
                    self.AnalyticsService.track(self.$scope, self.AnalyticsService.analytics_events.contract_signed, {
                       typed: self.signatureModel.isTyped()
                    });

                    self.saveAsDefault = data.saveAsDefault;
                    self.agreeToLegalText = data.agreeToLegalText;
                    if(self.agreeToLegalText){
                        if (!self.signatureModel.isTyped()) {
                            self.signaturePad = self.signatureElement.signaturePad({
                                displayOnly: true,
                                lineWidth: null
                            });
                            self.signatureModel.signature = self.scaleSignature(self.signatureModel.signature, data.signatureCanvasSize);
                            self.signaturePad.regenerate(self.signatureModel.signature);
                        }
                        return self.updateSignature();
                    }
                }).then(function() {
                    if (self.saveAsDefault) {
                        self.user.saveDefaultSignature(self.signatureModel);
                    }
                }).catch(function() {
                    self.signatureModel.signature_text = null;
                    self.signatureModel.signature = [];
                    self.signaturePad.regenerate(self.signatureModel.signature);
                    self.setDisplaysVars();
                });
            }
        };

        this.scaleDefaultSignature = function scaleDefaultSignature(signature, canvasSize){

            var xfactor = 540/290;
            var yfactor = 200/100;
            var scaledResult = [];
            var scaledLx, scaledLy, scaledMx, scaledMy;

            var signatureAsJson = JSON.parse(JSON.stringify(signature));//JSON.parse(signature);
            var length = signatureAsJson.length;

            for (var i = 0; i < length; i++) {
                var currentLine = signatureAsJson[i];

                scaledLx = (currentLine['lx'] * xfactor);
                scaledLy = (currentLine['ly'] * yfactor);
                scaledMx = (currentLine['mx'] * xfactor);
                scaledMy = (currentLine['my'] * yfactor);

                scaledResult.push({'lx': scaledLx, 'ly':scaledLy, 'mx': scaledMx, 'my': scaledMy});
            }

            return scaledResult;
        };

        this.scaleSignature = function scaleSignature(signature, canvasSize, xFactor, yFactor) {
            var currentLine;
            var xfactor;
            var yfactor;
            if(!xFactor){
                xFactor = 290;
            }
            if(!yFactor){
                yFactor = 100;
            }
            xfactor = xFactor / canvasSize.width;
            yfactor = yFactor / canvasSize.height;
            var scaledResult = [];
            var scaledLx, scaledLy, scaledMx, scaledMy;

            var signatureAsJson = JSON.parse(signature);
            var length = signatureAsJson.length;

            for (var i = 0; i < length; i++) {
                currentLine = signatureAsJson[i];

                scaledLx = (currentLine['lx'] * xfactor);
                scaledLy = (currentLine['ly'] * yfactor);
                scaledMx = (currentLine['mx'] * xfactor);
                scaledMy = (currentLine['my'] * yfactor);

                scaledResult.push({'lx': scaledLx, 'ly':scaledLy, 'mx': scaledMx, 'my': scaledMy});
            }

            return scaledResult;
        };

        this.updateSignature = function updateSignature() {

            var defer = self.$q.defer();

            var updateConfirmed = function updateConfirmed() {
                //this.signatureModel.signature = JSON.parse(this.signatureElement.find("#signatureData").val()); deprecated
                this.signatureModel.signee_name = this.signatureElement.find("#nameInput").val();

                this.updatingSignature = true;
                this.isSignatureFinal = true;

                this.allowSignVar = false;
                this.allowEditDescriptionVar = false;
                this.allowRemoveSignatureVar = false;
                this.shouldSignHereVar = false;

                var that = this;
                return this.signatureModel.updateSignature().then(
                    function success(resp) {
                        if (that.fileModel.status_cd < that.WorkspaceFileService.fileStatuses.agreement_signed &&
                            that.signatureModel.signee_type_cd === that.Enums.ContractSigneeTypes.couple_signature) {
                            that.WorkspaceFilesManager.updateStatus(that.fileModel, that.WorkspaceFileService.fileStatuses.agreement_signed)
                        }
                        that.signaturesController.rerenderSignature(that.signatureModel);
                        that.updatingSignature = false;
                        that.isSignatureFinal = true;

                        if (self.fileModel.isAgreement() && self.$state.current.name === self.AppStates.root_core_workspaceFile_stepAgreement) {
                            WorkspaceFileService.gotoWorkspaceFileView(self.fileModel);
                        }

                        defer.resolve(resp);
                    },
                    function error(resp) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.warning, resp.data.error_message);
                        that.updatingSignature = false;
                        that.isSignatureFinal = false;

                        defer.reject(resp);
                    }
                );
            }.bind(this);

            updateConfirmed();
            return defer.promise;
        };

        this.updateSignatureDescription = function updateSignatureDescription() {
            this.signatureModel.updateSignatureDescription().then(
                function success() {
                },
                function error() {
                }
            );
        };

        this.removeSignature = function removeSignature() {
            this.signatureModel.removeSignature().then(
                function success() {
                    this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.file_updated);
                }.bind(this),
                function error() {
                }
            );
        };

        this.isDefaultSignatureAvailable = function isDefaultSignatureAvailable() {
            var default_signature = this.user.default_signature;
            if (!default_signature) {
                return false;
            }
            return this.isSignatureValid(default_signature);
        };

        this.useDefaultSignature = function useDefaultSignature() {
            if (!this.showBlockReason() && !this.isSignatureBlocked()) {
                if (this.isDefaultSignatureAvailable()) {
                    this.signatureModel.signature_type_cd = this.user.default_signature.signature_type_cd;
                    if (this.user.default_signature.signature_type_cd === this.signatureModel.signatureTypes.typed) {
                        this.signatureModel.signature_text = this.user.default_signature.signature_text;
                    } else {
                        this.signatureModel.signature = this.user.default_signature.signature;
                        this.signaturePad = this.signatureElement.signaturePad({displayOnly: true, lineWidth: null});
                        this.signaturePad.regenerate(this.signatureModel.signature);
                    }
                }
            }
        };

        this.enactSignature = function enactSignature() {
            if (self.isDefaultSignatureAvailable()) {
                this.AnalyticsService.track(this, 'default signature was inserted', {typedSignature: this.signatureModel.signature_type_cd === this.signatureModel.signatureTypes.typed});
                self.openLargeSignatureDialog();
                //self.useDefaultSignature();
                //self.updateSignature();

            } else {
                self.openLargeSignatureDialog();
            }
        };
    }


    function SignatureDirectiveLink($scope, $element, attributes, SignaturesDirectiveController) {
        $scope.signatureVm.signaturesController = SignaturesDirectiveController;
    }


    var SignatureDirectiveController = Class(Controllers.BaseController, {
        constructor: SignatureDirectiveControllerFunc,

        isSignatureValid: function isSignatureValid(signature) {
            //for old signatures the signature_type_cd is null
            return (signature.signature_type_cd === this.signatureModel.signatureTypes.typed && !!signature.signature_text) ||
                (signature.signature_type_cd !== this.signatureModel.signatureTypes.typed && !!signature.signature && !!signature.signature.length);
        },

        onSignatureDomCreated: function onSignatureDomCreated() {
            this.initializeSignature(this.$element);
        },

        onSignatureErrorClick: function onSignatureErrorClick() {
          this.showSignatureError = true;
          this.$timeout(function() {
              this.showSignatureError = false;
          }.bind(this), 4000);
        },

        getSigneeEmail: function getSigneeEmail() {

            if(!this.workspaceModel) {
                return "";
            }

            var user = this.workspaceModel.members.filter(function (user) {
                return user._id === this.signatureModel.signee_id;
            }.bind(this))[0];
            if (user && user.email) {
                return user.email;
            }
        },

        showReplaceSignature: function showReplaceSignature() {
            return this.shouldShowReplaceSig && this.isOwnerMode && !this.signatureModel.signed_on && this.teamMembersSignatories().length && !this.isPreviewMode;
        }

    });
    return {
        scope: {
            fileModel: '=fileModel',
            signatureModel: '=signatureModel',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            isOwnerMode: '=isOwnerMode',
            signatureIndex: '=signatureIndex',
            firstCoupleSignature: '=firstCoupleSignature',
            hasEmptyClientFields: '=hasEmptyClientFields',
            workspaceModel: '=workspaceModel',
            teamMembersSignatories: '&'
        },
        require: '^^hbSignatures',
        templateUrl: 'angular/app/modules/core/features/signatures/signature/signature_directive_template.html',
        controller: SignatureDirectiveController,
        link: SignatureDirectiveLink,
        controllerAs: 'signatureVm',
        bindToController: true
    };
};