Directives.SecurityPhoneNumberDirective = function SecurityPhoneNumberDirective() {

    // @ngInject
    function SecurityPhoneNumberDirectiveControllerFunc($scope, $injector, $stateParams, AppStates, UsersManager, UserService, ModalService, AuthService, RedirectService, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SecurityPhoneNumberDirectiveController';

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.UserService = UserService;
        this.ModalService = ModalService;
        this.AuthService = AuthService;
        this.RedirectService = RedirectService;
        this.PopupMessageService = PopupMessageService;

        // 2FA
        this.enablePhoneEdit = false;
        this.verificationCode = "";
        this.errorMessage = "";
        this.phoneNumberRegex = new RegExp(/^\+?(\d+)$/);

        this.setPhoneNumberModel();

        this.$stateParams = $stateParams;
        if (this.$stateParams.openAddSecurityPhoneNumberModal) {
            this.startTwoFactorAuth();
        }
    }

    var SecurityPhoneNumberDirectiveController = Class(Controllers.BaseController, {
        constructor: SecurityPhoneNumberDirectiveControllerFunc,

        startTwoFactorAuth: function startTwoFactorAuth() {
            var canVerifyByPhoneNumber = this.securityPhoneNumberExist();
            var canVerifyByEmail = !this.securityPhoneNumberExist();
            return this.ModalService.openTwoFactorAuthModal(true, canVerifyByPhoneNumber, canVerifyByEmail)
                .then(this.onTwoFactorAuthDone.bind(this))
                .catch(this.onTwoFactorAuthDone.bind(this));
        },

        setPhoneNumberModel: function setPhoneNumberModel() {
            var phoneNumber = this.user.securityPhoneNumberLastFourDigits();
            if (phoneNumber) {
                this.securityPhoneNumber = "xxxxxx" + phoneNumber;
            } else {
                this.securityPhoneNumber = '';
            }
        },

        onTwoFactorAuthDone: function onTwoFactorAuthDone(result) {
            // dismissed
            if (result.cancel) {
                return;
            }

            // all good, code is verified!
            this.enablePhoneEdit = true;
            this.verificationCode = result.code;
            this.verificationMethod = result.selectedMethod;
        },

        updateSecurityPhoneNumber: function updateSecurityPhoneNumber(securityPhoneNumber, form) {

            if(!this.verificationCode) {
                return;
            }

            this.setPhoneNumberValidity(securityPhoneNumber, form);

            if(form.$valid) {
                this.show_loading = true;

                this.UsersManager.updateSecurityPhoneNumber(securityPhoneNumber, this.verificationCode, this.verificationMethod)
                    .catch(function error(result) {
                        var msg;
                        if (result.data.error_message) {
                            msg = result.data.error_message;
                        } else if (result.status === 401) {
                            msg = 'You cannot update your security phone number at this point. Please contact us for assistance';
                        } else {
                            msg = 'Error updating 2fa phone number';
                        }
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,  msg);
                    }.bind(this))
                    .finally(function finallyDo() {
                        this.show_loading = false;
                        this.resetEditing();
                    }.bind(this));
            }

        },

        setPhoneNumberValidity: function(securityPhoneNumber, form) {
            var validPhoneNumber = this.phoneNumberRegex.test(securityPhoneNumber);
            form.security_phone_number.$setValidity('invalid', validPhoneNumber);
        },

        securityPhoneNumberExist: function securityPhoneNumberExist() {
            return !!this.user.securityPhoneNumberLastFourDigits();
        },

        resetEditing: function resetEditing() {
            this.verificationCode = undefined;
            this.enablePhoneEdit = false;
            this.formChangeSecurityPhoneNumber.security_phone_number.$setValidity('invalid', true);
            this.setPhoneNumberModel();
        },

        canEdit: function canEdit() {
            return true;
        },

        addSecurityPhoneNumber: function addSecurityPhoneNumber() {
            var minutesSinceLastLogin = (new Date().getTime() - Date.parse(this.user.last_login_time)) / 1000 / 60;
            if (minutesSinceLastLogin > 4) {
                return this.ModalService.openAddSecurityPhoneNumberModal(this.user);
            } else {
                this.startTwoFactorAuth();
            }
        },

    });

    return {
        scope: {
            user: '=user'
        },
        templateUrl: 'angular/app/modules/core/features/settings/account/security_phone_number/security_phone_number_template.html',
        controller: SecurityPhoneNumberDirectiveController,
        controllerAs: 'securityPhoneNumberVm',
        bindToController: true
    };
};
