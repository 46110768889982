(function () {
    "use strict";

    // @ngInject
    function WorkspaceFileStepQuestionnaireControllerCtor($scope, $stateParams, $injector,
                                                          AppStates,
                                                          WorkspaceFilesManager, UsersManager,
                                                          AnalyticsService, PopupMessageService, WorkspaceFileService, WorkspaceService, InjectBrandingService, DeviceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileStepQuestionnaireController';
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.AppStates = AppStates;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspaceService = WorkspaceService;
        this.AnalyticsService = AnalyticsService;
        this.InjectBrandingService = InjectBrandingService;
        this.isAndroid = DeviceService.isAndroid();


        this.user = UsersManager.getCurrUser();

        this.workspaceFileId = $stateParams.workspace_file_id;

        var self = this;
        this.workspaceFile = WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);

        AnalyticsService.trackPageView(this, 'questionnaire',
            {
                preview_mode: this.isPreviewMode,
                file_mode: WorkspaceFileService.whichCurrentFileView(this.workspaceFile)
            }
        );

        this.isOwnerMode = false;
        this.isClientMode = false;
        this.isPreviewMode = false;
        this.isEditableMode = false;

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(self.AppStates.root_core_navigation_home);
            }.bind(this));
        });
    }

    Controllers.WorkspaceFileStepQuestionnaireController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileStepQuestionnaireControllerCtor,

        gotWorkspaceFile: function gotWorkspaceFile() {

            if(this.workspaceFile.company){

                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }

            this.isOwnerMode = this.workspaceFile.isOwnerMode();
            this.isClientMode = this.workspaceFile.isClientMode();

            // redirect if you are not the client
            if (!this.alreadyGotFile &&
                (this.isOwnerMode || this.workspaceFile.isViewOnlyMode())) {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile, null, true);
            }

            if (!this.alreadyGotFile) {
                if (!this.isOwnerMode && !this.isViewOnlyMode) {
                    this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.proposal_seen);
                }
            }

            this.alreadyGotFile = true;

            this.isPreviewMode = false;
            if (this.workspaceFile.isEditable()){
                this.isEditableMode = true;
            }
        },

        submitQuestionnaire: function submitQuestionnaire(){

            this.WorkspaceFileService.validateFileModel().then(
                function success(resp) {
                    this.working = true;
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.questionnaire_before_submit_warning);
                    this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info,
                        'QUESTIONNAIRE._QUESTIONNAIRE_SUBMISSION_CONFIRMATION_',
                        function confirmCallback(){
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.questionnaire_submit_warning_confirmed);
                            var promise = this.WorkspaceFilesManager.submitQuestionnaire(this.workspaceFile);
                            promise.then(
                                this.goToSubmitted.bind(this)
                            ).catch(
                                this.errorSubmittingQuestionnaire.bind(this)
                            ).finally( function fulfilled(){
                                this.working = false;
                            }.bind(this));
                        }.bind(this),
                        function dismissCallback() {
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.questionnaire_submit_warning_dismissed);
                        }.bind(this), undefined, undefined, undefined, true);
                }.bind(this));

        },

        goToSubmitted: function goToSubmitted() {
            var questionnaireStateParams = this.appConfig.questionnaireSubmittedState();
            questionnaireStateParams.workspaceFile = this.workspaceFile;
            this.goToState(this.AppStates.root_core_questionnaireSubmitted, {}, {location: false});

        },

        errorSubmittingQuestionnaire: function errorSubmittingQuestionnaire(resp){
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, resp.data.error_message);
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        analyticsProperties: function analyticsProperties(){
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        }
    });
}());
