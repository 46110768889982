(function () {
    'use strict';

    // @ngInject
    Services.GoogleAccounts = function GoogleAccounts($window, Gon, ThirdPartyLoaderService) {
        function loadGoogleAccounts() {
            // nothing to do here since google accounts is loaded in angular.html.template
        }
        function getGoogleAccounts() {
            // waiting for window.google is not enough, because we load other google SDKs (like google.accounts)
            if ($window.hasOwnProperty('google') && $window.google.hasOwnProperty('accounts')) {
                return $window.google.accounts;
            }
        }
        return ThirdPartyLoaderService.loadCallbackPromise(loadGoogleAccounts, getGoogleAccounts);
    };

}());
