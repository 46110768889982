(function () {
    "use strict";
    Controllers.RescheduleProjectSuccessToastController = class RescheduleProjectSuccessToastController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $state, toastInstance, AppStates, project, workspaceID, wasEmailSent) {
            super($scope, $injector);
            this.__objectType = 'RescheduleProjectSuccessToastController';
            this.toastInstance = toastInstance;
            this.$state = $state;
            this.AppStates = AppStates;
            this.project = project;
            this.workspaceID = workspaceID;
            this.wasEmailSent = wasEmailSent;

            this.emailSentText = wasEmailSent ? $translate.instant('RESCHEDULE_PROJECT.SUCCESS_TOAST._EMAIL_SENT_TEXT_') : '';
        }

        goToWorkspace() {
            this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, {workspace_id: this.workspaceID, event_id: this.project._id});
        }
    };
}());