Directives.CopyToClipboardDirective =  function CopyToClipboardDirective() {

    //@ngInject
    function CopyToClipboardDirectiveControllerFunc($scope, $injector, $, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CopyToClipboardDirectiveController';

        this.$translate = $translate;
        this.$ = $;

        this.action = "copy";
        this.isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);

        $scope.$on('$destroy', function () {
            this._removeDummyElement();
            this.removeResultTooltip();
            this.$element.off('click', this._onclickListener);
        }.bind(this));

    }

    var CopyToClipboardDirectiveController = Class(Controllers.BaseController, {
        constructor: CopyToClipboardDirectiveControllerFunc,

        copyText: function copyText() {
            this.textareaEl = null;

            if (this.hbCopyToClipboardSelector) {
                this.textareaEl = this.$(this.hbCopyToClipboardSelector);

                if (this.textareaEl) {
                    this.textareaEl.select();
                }
                
                if (this.hbCutToClipboard === true) {
                    this.action = "cut";
                }
            }

            if (!this.textareaEl) { //no selector or no element found
                this._createDummyElement();

                if (this.isIOS) {
                    this.copyTextIOS();
                } else {
                    this.dummyElem.select();
                }
            }

            var success;

            try {
                success = document.execCommand(this.action);
            }
            catch (err) { //failed to copy in browser
                success = false;
            }

            this.showResultTooltip(success);

            if (success && this.hbCopyToClipboardSuccess) {
                this.hbCopyToClipboardSuccess();
            } else if (!success && this.hbCopyToClipboardError) {
                this.hbCopyToClipboardError();
            }

            return success;
        },

        showResultTooltip: function showResultTooltip(isSuccess) {
            this.removeResultTooltip();

            var tooltipText = 'COMMON_DIRECTIVES.COPY_TO_CLIPBOARD.';
            if (isSuccess) {
                tooltipText += '_SUCCESS_';
            } else {
                var isMac = (navigator.appVersion.indexOf("Mac")!== -1);
                tooltipText += isMac ? '_ERROR_MAC_' : '_ERROR_WIN_';
            }
            tooltipText = this.$translate.instant(tooltipText);

            var tooltip = '<span class="tooltip" ' +
                'tooltip-placement="bottom">' + tooltipText +
                '</span>';

            this.tooltipElem = this.$(tooltip).insertAfter(this.$element);
        },

        removeResultTooltip: function removeResultTooltip() {
            if (this.tooltipElem) {
                this.tooltipElem.remove();
                this.tooltipElem = null;
            }
        },

        // iOS requires special treatment
        copyTextIOS: function copyTextIOS() {
            var editable = this.dummyElem.contentEditable;
            var readOnly = this.dummyElem.readOnly;
            this.dummyElem.contentEditable = true;
            this.dummyElem.readOnly = true;
            var range = document.createRange();
            range.selectNodeContents(this.dummyElem);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            this.dummyElem.setSelectionRange(0, 999999);
            this.dummyElem.contentEditable = editable;
            this.dummyElem.readOnly = readOnly;
        },

        /**
         * Creates a dummy textarea element, then sets its value from `text` property
         */
        _createDummyElement: function _createDummyElement() {
            var isRTL = document.documentElement.getAttribute('dir') === 'rtl';

            this._removeDummyElement();

            this.dummyHandler = document.body.addEventListener('click', this._removeDummyElement.bind(this));

            this.dummyElem = document.createElement('textarea');
            // Prevent zooming on iOS
            this.dummyElem.style.fontSize = '12pt';
            // Reset box model
            this.dummyElem.style.border = '0';
            this.dummyElem.style.padding = '0';
            this.dummyElem.style.margin = '0';
            // Move element out of screen horizontally
            this.dummyElem.style.position = 'fixed';
            this.dummyElem.style[ isRTL ? 'right' : 'left' ] = '-9999px';
            // Move element to the same position vertically
            this.dummyElem.style.top = (window.pageYOffset || document.documentElement.scrollTop) + 'px';
            this.dummyElem.setAttribute('readonly', '');
            this.dummyElem.value = this.hbCopyToClipboardText;

            document.body.appendChild(this.dummyElem);
        },

        /**
         * Only removes the dummy element after another click event, that way
         * a user can hit `Ctrl+C` to copy because selection still exists.
         */
        _removeDummyElement: function _removeDummyElement() {
            if (this.dummyHandler) {
                document.body.removeEventListener('click');
                this.dummyHandler = null;
            }

            if (this.dummyElem) {
                document.body.removeChild(this.dummyElem);
                this.dummyElem = null;
            }
        },

        _onclickListener: function _onclickListener(event) {
            this.copyText();
        }


    });

    return {
        restrict: 'A',
        scope: {
            //text to be copied to clipboard. can be any javascript variable
            hbCopyToClipboardText: '=hbCopyToClipboardText',

            //pass jquery selector for element containing text. Only textarea elements are supported!
            hbCopyToClipboardSelector: '@?hbCopyToClipboardSelector',

            //if combined with hbCopyToClipboardSelector, this will be used to cut text instead of copy it
            hbCutToClipboard: '=?hbCutToClipboard',

            //callback functions in case you want to do something after text was copied.
            hbCopyToClipboardSuccess: '&?hbCopyToClipboardSuccess',
            hbCopyToClipboardError: '&?hbCopyToClipboardError'
        },
        controller: CopyToClipboardDirectiveController,
        controllerAs: 'copyToClipboardVM',
        bindToController: true,

        link: function copyToClipboardLink($scope, $element, attr) {
            var self = $scope.copyToClipboardVM;
            self.$element = $element;
            self.$element.on('click', self._onclickListener.bind(self));
        }
    };
};
