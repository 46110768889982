(function () {
    "use strict";

    // @ngInject
    function HelpServiceCtor(PubSubService, DeviceService, AppStates, $state) {
        this.$state = $state;

        PubSubService.ventMyBitchUp(this);

        this.isMobile = DeviceService.nxSmallBreakpoint();

        if (this.isMobile) {
            //for mobile
            this.disallowedStates = {
                partialMatch: [ //disallowed states will include all underlying states as well
                    AppStates.root_core_workspaceFile,
                ],
                exactMatch: [
                    AppStates.root_core_login,
                    AppStates.root_core_signup,
                    AppStates.root_core_navigation_contacts  
                ]
            };
        } else {
            //for desktop
            this.disallowedStates = {
                partialMatch: [],
                exactMatch: [
                    AppStates.root_core_login,
                    AppStates.root_core_signup
                ]
            };
        }
    }

    Services.HelpService = Class(function () {
        return {

            constructor: HelpServiceCtor,

            onStateChange: function onStateChange(fromState, toState) {
                // var isFromDisallowed = false;
                var isNewStateDisallowed = false;
                var i;
                for (i = 0; i < this.disallowedStates.partialMatch.length; i++) {
                    if (toState.name.startsWith(this.disallowedStates.partialMatch[i])) {
                        isNewStateDisallowed = true;
                        break;
                    }
                }
                if (!isNewStateDisallowed) {
                    for (i = 0; i < this.disallowedStates.exactMatch.length; i++) {
                        if (toState.name === this.disallowedStates.exactMatch[i]) {
                            isNewStateDisallowed = true;
                            break;
                        }
                    }
                }

                if (isNewStateDisallowed) {
                    this.hideHelp();
                } else {
                    this.showHelp();
                }
            },

            handleCurrentState: function handleCurrentState() {
                this.onStateChange(null, this.$state.current);
            },

            restoreDefaultVisibility: function restoreDefaultVisibility() {
                this.handleCurrentState();
            },

            showHelp: function showHelp() {
                this.trigger('showHelp');
            },

            hideHelp: function hideHelp() {
                this.trigger('hideHelp');
            }

        };
    });
}());
