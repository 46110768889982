(function () {
    'use strict';

    Directives.ImageEditable = function ImageEditable() {

        // @ngInject
        function ImageEditableDirectiveCtor($scope, $injector, ImageService, $timeout) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'ImageEditableController';

            this.ImageService = ImageService;

            this.round = Math.round;
            this.addTooltipDelete = this.tooltipDelete || '';
            this.addTooltipIcon =  this.tooltipIcon || '';


            var scopeChanged = function wrapperMethod() {
                this.refreshFlag = true;
                $timeout(function timeout() {
                    this.refreshFlag = false;
                }.bind(this), 0);
            }.bind(this);
            $scope.$watch('imgEditableVm.image.cloudinary_public_id', scopeChanged);
            $scope.$watch('imgEditableVm.image.position_y', scopeChanged);
            $scope.$watch('imgEditableVm.image.position_x', scopeChanged);
            $scope.$watch('imgEditableVm.image.position_h', scopeChanged);
            $scope.$watch('imgEditableVm.image.position_w', scopeChanged);

            this._init();
        }

        var ImageEditableController = Class(Controllers.BaseController, {
            constructor: ImageEditableDirectiveCtor,

            _init: function _init() {

                switch (this.imageWidth){
                    case '64':
                        this.imageSize = 'image-editable--lg';
                        break;
                    default:
                        this.imageSize = 'image-editable--reg';
                }

            },

            getFormattedImage: function getFormattedImage() {
                return this.hasImage() ? this.ImageService.getHBImageCloudinaryUrl(this.image) : '';
            },

            hasImage: function hasImage() {
                return !!this.image;
            }

        });

        return {
            scope: {
                image: '=',
                isEditMode: '&',
                updateImage: '&',
                deleteImage: '&',
                expandImage: '&',
                imageWidth: '@',
                imageHeight: '@',
                styleClass: '@',
                tooltipDelete: '@',
                tooltipIcon: '@'
            },
            templateUrl: 'angular/app/modules/common/ui_components/image_editable/image_editable.html',
            controller: ImageEditableController,
            controllerAs: 'imgEditableVm',
            bindToController: true
        };
    };
}());


