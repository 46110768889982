
// @ngInject

Models.ReferralCampaignsCollection = function ReferralCampaignsCollection(BaseCollection) {

    var ReferralCampaignsCollection = Class(BaseCollection, {

            constructor: function constructor(){
                ReferralCampaignsCollection.$super.call(this);
                this._collection.__className = 'ReferralCampaignsCollection';
                this._collection.__childsType = 'ReferralCampaignModel';
                return this._collection;
            }
        }
    );

    return ReferralCampaignsCollection;
};