// This is the email editor that appears on: EMAIL EDITOR MODAL
(function () {
    'use strict';

    // @ngInject
    function EmailEditorControllerCtor($, $modalInstance, $document, $scope, $translate, $injector, $window, $filter, ToastService,
                                       _, ModalService, ContactsManager, Enums, UsersManager, AbTestService, AttachmentsService,
                                       CompaniesManager, TemplatesManager, EventsManager, PopupMessageService, DeviceService,
                                       AnalyticsService, users, subject, body, senderFunction, previewFunction, config, PvlService,
                                       WorkspaceService, UIUtils, $log, $q, UserService, $timeout, HelpService, generalFiles, imageFiles, FeaturesService, showScheduleOption) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EmailEditorController';

        this.ModalService = ModalService;
        this.$modalInstance = $modalInstance;
        this.$translate = $translate;
        this.$window = $window;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.UserManager = UsersManager;
        this.UserService = UserService;
        this.Enums = Enums;
        this.PvlService = PvlService;
        this.WorkspaceService = WorkspaceService;
        this.DeviceService = DeviceService;
        this.TemplatesManager = TemplatesManager;
        this.config = config;
        this.$ = $;
        this._ = _;
        this.$q = $q;
        this.$filter = $filter;
        this.FeaturesService = FeaturesService;
        this.ToastService = ToastService;
        this.AttachmentsService = AttachmentsService;

        // 1. Pass data into the modal: Attachments for templates with attachment
        this.senderFunction = senderFunction;
        this.users = users;
        this.subject = subject;
        this.UIUtils = UIUtils;
        this.$log = $log;
        this.body = this.UIUtils.getHtmlBodyCleanInlineStyle(body);
        this.imageFiles = imageFiles;
        this.generalFiles = generalFiles;

        this.editorReference = null;
        this.savingTemplate = false;
        this.title = config.title;
        this.beforeSendCallback = config.beforeSendCallback;
        this.showCCToClient = config.showCCToClient;
        this.shouldShowEventDetails = config.shouldShowEventDetails;
        this.info_message = config.info_message;
        this.event_id = config.event_id;
        this.currUser = UsersManager.getCurrUser();
        this.headerLabel = config.headerLabel;
        this.hasHeaderLabel = !!this.headerLabel;
        this.sendLabel = config.sendLabel;
        this.fileOwner = config.fileOwner;
        this.signatureUser = this.fileOwner || this.currUser;
        this.showFromLine = config.showFromLine;
        this.showBccMyselfCheckbox = config.showBccMyselfCheckbox;
        this.showAddWsParticipantBtn = config.showAddWsParticipantBtn;
        this.bccMyself = false;
        this.workspace = config.workspace;
        this.previewFunction = previewFunction;
        this.showPreviewBtn = angular.isDefined(config.showPreviewBtn) ? config.showPreviewBtn : true;
        this.showScheduleOption = showScheduleOption;
        this.previewLoading = false;
        this.shouldCCToClient = false;
        this.isEmailFirstTime = !this.currUser.last_file_sent_time;
        this.company = CompaniesManager.getCurrCompany();
        this.sharePVL = false;
        this.enableAttachPvl = config.showSharePVL && this.currUser.isVendor() && !this.currUser.isInTrialAndNotSubscribed();
        this.fromLine = this.getFromLine();
        this.toLine = this.getToLine(users);
        this.editorHeight = this.enableAttachPvl ? '231px' : '300px';
        this.sendButtonText = this.$translate.instant(config.sendButtonText) || this.$translate.instant('FREQUENT_BUTTONS._SEND_');
        this.cancelButtonText = this.$translate.instant(config.cancelButtonText) || this.$translate.instant('FREQUENT_BUTTONS._CANCEL_');
        this.$timeout = $timeout;
        this.isMobile = DeviceService.checkIfMobileSize();
        this.sendAsync = this.config.sendAsync;
        this.sendFilePreview = config.sendFilePreview;
        this.fileType = config.fileType;
        this.scheduledSendDate = null;
        this.showingEmailScheduler = false;
        this.preSending = false;
        // mWeb
        if (this.DeviceService.isAndroid()) {
            this.$('body').addClass('is-android');
        }
        if (this.isMobile) {
            HelpService.hideHelp();
        }

        // PVL
        if (this.enableAttachPvl) {
            this.pvl = config.pvl;

            // TODO: Move this to validate pvl
            ContactsManager.hasPreferredContacts().then(function (resp) {
                this.hasPreferredVendors = resp.data.result;
                this.AnalyticsService.trackPageView(this, 'email editor with share pvl option', {has_preferred_vendors: this.hasPreferredVendors});
            }.bind(this));
        }

        // 2. ShowEventDetails (getEvent, show details in panel)
        if (this.shouldShowEventDetails) {
            EventsManager.getEvent(this.event_id, true, true).then(function eventReturnedSuccess(event) {
                this.event = event;
                this.event_type = this.event.event_type;
            }.bind(this));
        }

        // Close email templates panel on click (not relevant for OneComposer)
        $document.on('click.email_templates.honeybook', function (e) {
            var container = $("#email-templates-region");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if (this.showEmailTemplates) {
                    this.closeMenu();
                }
            }
        }.bind(this));
        $scope.$on('$destroy', function() {
            $document.off('click.email_templates.honeybook');
            if (this.isMobile) {
                HelpService.restoreDefaultVisibility();
            }
        }.bind(this));

        // 3. send test : add [test] to subject line
        $scope.$watch('emailEditorVm.subject', function(newVal, oldVal) {
            var prefix = '[TEST]';
            if (this.sendFilePreview && newVal.indexOf(prefix) !== 0) {
                if(oldVal.indexOf(prefix) !== 0) {
                    this.subject = prefix + ' ' + newVal;
                } else {
                    this.subject = oldVal;
                }
            }
        }.bind(this));

        // 4. Show the selected email template
        $scope.$watch('emailEditorVm.editorReference', function(newVal) {
            if (newVal) {

                if (this.config.selectedTemplateId) {
                    var templateObject = {
                        _id: this.config.selectedTemplateId,
                        id: function id() {
                            return templateObject._id
                        }
                    };

                    this.editorReference.on('instanceReady', function () {
                        this.templateChosen(templateObject);
                    }.bind(this));
                }
            }
        }.bind(this));

    }

    // @ngInject
    Controllers.EmailEditorController = Class(Controllers.BaseController, {

        constructor: EmailEditorControllerCtor,

        // 5. Main Send button CTA function
        send: function send() {
            // 6. disable cta
            this.preSending = true;

            // remove this
            if (this.showingEmailScheduler) {
                this.preSending = false;
                return;
            }
            this.closeDrop();

            // 7. wait for upload to finish
            // create an array of promises needs to resolve
            // before the actual send
            var beforeSendPromises = [];

            // uploading images promises (as one combined), if exists            
            var uploadImagesPromise = this.getAllUploadingFilePromises();
            if (uploadImagesPromise) {
                beforeSendPromises.push(uploadImagesPromise);
            }
            // 8. call beforeSendCallback
            if (angular.isFunction(this.beforeSendCallback)) {
                this.beforeSendCallback().then(function success() {

                    // wait for all promises to resolve, or fire immediately
                    if (beforeSendPromises.length > 0) {
                        this.$q.all(beforeSendPromises).then(function success() {
                            this._send();
                        }.bind(this));
                    } else {
                        this._send();
                    }
                }.bind(this));
            }
            else {
                // wait for all promises to resolve, or fire immediately
                if (beforeSendPromises.length > 0) {
                    this.$q.all(beforeSendPromises).then(function success() {
                        this._send();
                    }.bind(this));
                } else {
                    this._send();
                }
            }
        },

        _filePreviewSendSuccess: function _filePreviewSendSuccess() {
            this.filePreviewSendSuccess = true;
        },

        _sendAsync: function _sendAsync() {
            //10. Send Async
            this.sending = true;
            if (this.config.isSendToWorkspaces || this.config.sendBulkToContacts) {
                //11. Show bulk email init message
                this.sendSuccess = true;
                this.showDoneButtonInSuccessState = true;
                this.isBlueCheckmark = true;
                this.successPrimaryText = "BULK_EMAILS.EMAIL_SUCCESS_PRIMARY";
                this.successSecondaryText = "BULK_EMAILS.EMAIL_SUCCESS_SECONDARY";
            }
            //12. Update Async progress object 
            this.asyncProgress = {};

            this.senderFunction({
                users: this.users,
                subject: this.subject,
                body: this.getHtmlBodyWithoutInputs(),
                params: this.getParamsForSend(),
                attachedFiles: this.prepareAttachedFiles(),
                scheduledSendDate: this.scheduledSendDate
            })
            .then(function success(resp) {
                // 13. success async send
                this.asyncProgress = resp;

                this.isSentWithFailures = _.any(this.asyncProgress, function(asyncResult) {
                   return !asyncResult.delivery || asyncResult.delivery.status !== 'success';
                });
                if (!this.isSentWithFailures) {
                    if(this.sendFilePreview) {
                        // show success message for send test
                        this._filePreviewSendSuccess();
                    } else {
                        // success sync test
                        this.closeModalWithDelay();
                    }
                }

            }.bind(this), null, function notify(progress) {
                if (progress){
                    this.asyncProgress = progress;
                }
            }.bind(this));
        },

        _send: function _send() {
            //9. Show send animation
            this.sending = true;

            if (!this.senderFunction) {
                return;
            }

            // remove start
            // make sure everything is good in terms of pvl
            if (!this.validatePreferredVendorPreferences()) {
                this.sending = false;
                this.preSending = false;
                return;
            }
            // handle pvl save before sending
            // only if pvl exist and not the default
            var pvlPromise = this.$q.when();
            if (this.sharePVL && this.pvl && this.pvl.preferred_vendor_ids.length) {
                pvlPromise = this.PvlService.savePreferredVendorsList(this.pvl, 'attached_to_file');
            }
            // remove end
            // remove pvlPromise
            pvlPromise.then(function() {
                if (this.sendAsync) {
                    this._sendAsync();
                } else {
                    this._sendSync(this.getParamsForSend());
                }

            }.bind(this));
        },

        // 17. Show status for each recipient
        getDelivery: function getDelivery(user) {
            if (!this.asyncProgress[user._id] || !this.asyncProgress[user._id].delivery || this.asyncProgress[user._id].delivery.status === 'running') {
                return {status: 'sending'};
            }

            return this.asyncProgress[user._id].delivery;
        },

        // 18. Check if workspace is archived
        isArchived: function isArchived() {
            return this.workspace && this.workspace.isArchived();
        },

        _showArchivedWarning: function _showArchivedWarning() {
            if (this.isArchived()) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._ARCHIVE_WORKSPACE_FILE_POPUP_', null, 'FREQUENT_BUTTONS._OK_');
            }
        },

        _sendSync: function _sendSync(params, curIndex) {
            // 14. Send Sync
            curIndex = curIndex || 0;

            if (curIndex === this.users.length) {
                // 15. Success Sync send
                if(this.sendFilePreview) {
                    this._filePreviewSendSuccess();
                } else {
                    this.closeModalWithDelay();
                    this._showArchivedWarning();
                }
                return;
            }

            var user = this.users[curIndex++];
            this.sending = true;
            this.sendingToUserName = user.full_name;

            if (this.params) {
                // add to the params the list of all the last email id's that were sent so we can keep track of them
                // since the client is orchestrating this whole process of sending emails and on the server we only save
                // one GenericMail model for all of these emails (the last one, all the rest are not saved)
                this.params['past_emails'] = this.past_emails;
            }

            this.senderFunction({
                users: user,
                subject: this.subject,
                body: this.getHtmlBodyWithoutInputs(),
                isLast: (curIndex === this.users.length),
                params: params,
                attachedFiles: this.prepareAttachedFiles(),
                scheduledSendDate: this.scheduledSendDate
            })
            .then(function success(resp) {
                if (!this.config.dontLoopOverRecipients) {
                    if (resp && resp.data && resp.data.last_email_sent) {
                        // Must use angular.copy, otherwise the world ends
                        // Or the past emails will hold bad data b/c of race condition
                        // Plus - eyal is the js king
                        this.past_emails.push(angular.copy(resp.data.last_email_sent));
                    }
                    this._sendSync(params, curIndex);
                } else {
                    this.closeModalWithDelay();
                    this._showArchivedWarning();
                }
            }.bind(this)).catch(function error(resp) {
                if (resp === "closeEmailEditor") {
                    this.$modalInstance.close();
                    return;
                }
                this.sending = false;
                this.preSending = false;
                this.sendingToUserName = undefined;
                if (resp && resp.data) {
                    if (resp.status === 0 || resp.data.error_type === 'SocketError') {
                        this.errorMessage = this.$translate.instant('ERRORS.SERVER_API._COULD_NOT_CONNECT_TO_SERVER_');
                    } else if (resp.data.is_timeout) {
                        // HON-10559
                        // talk to sergei to understand why we show google error for any timeout in this api call
                        this.errorMessage = this.$translate.instant('ERRORS.SERVER_API._EMAIL_TIMEOUT_');
                    } else if (resp.data.error_message) {
                        if (resp.data.error_type && resp.data.error_type === 'Mandrill::Error') {
                            this.errorMessage = this.$translate.instant('EMAIL_EDITOR._MANDRILL_ERROR_');
                        } else {
                            this.errorMessage = resp.data.error_message;
                        }

                    } else {
                        this.errorMessage = this.$translate.instant('EMAIL_EDITOR._GENERAL_ERROR_');
                    }
                } else {
                    this.errorMessage = 'Unknown Error';
                }
            }.bind(this));
        },


        // remove ?
        getBody: function _getBody() {
            var htmlBody = this.editorReference ? this.editorReference.getData() : "";

            if (this.shouldShowEventDetails) {
                var eventDetails = this.$("#RealPopulatedEventDetails").clone().wrap('<div/>').parent().html();
                htmlBody = htmlBody.replace("<div id=\"ToReplacePopulatedEventDetails\">&nbsp;</div>", eventDetails);
            }

            return htmlBody;
        },

        // 19. show preview. remove?
        preview: function preview() {
            if (!this.previewFunction) {
                return;
            }

            var users = this.users;
            this.previewLoading = true;
            var attachedFiles = this.prepareAttachedFiles();

            this.previewFunction(users[0], this.subject, this.getHtmlBodyWithoutInputs(), this.params, attachedFiles).then(function success(resp) {
                var html = resp.data.body;
                win.document.write(html);
            }.bind(this)).finally(function () {
                this.previewLoading = false;
            }.bind(this));

            var win = this.$window.open('/email_preview');
        },

        // remove
        openSchedulerModal: function() {
            this.showingEmailScheduler = true;

            this.ModalService.openEmailSchedulerModal().then(
                function success(resp) {
                    if (resp.epochSelectedDate) {
                        this.scheduledSendDate = resp.epochSelectedDate;
                    }
                    this.showEmailScheduler = false;
                    this.refreshUI();
                }.bind(this),
                function error(resp) {
                    this.scheduledSendDate = null;
                    this.refreshUI();
                }.bind(this))
                .finally(function final() {
                    this.showingEmailScheduler = false;
                }.bind(this));
        },
        // remove
        scheduleSendToggle: function scheduleSendToggle() {
            if (this.showingEmailScheduler) {
                return;
            }
            if (this.scheduledSendDate) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.cancel_schedule_email_editor);
                this.scheduledSendDate = null;
                this.refreshUI();
            } else {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.open_schedule_send_modal, {source: 'file'});
                this.openSchedulerModal();
            }
        },

        // remove
        refreshUI: function refreshUI() {
            if (this.scheduledSendDate) {
                this.sendButtonText = 'SCHEDULE';
            } else {
                this.sendButtonText = this.$translate.instant(this.config.sendButtonText) || this.$translate.instant('FREQUENT_BUTTONS._SEND_');
            }
        },

        //20. getParamsForSend
        getParamsForSend: function getParamsForSend() {
            this.params = {};
            this.past_emails = [];

            if (this.config.showCCToClient) {
                this.params["is_cc_client"] = this.shouldCCToClient;
            }

            if (this.bccMyself) {
                this.params['bcc_self'] = this.bccMyself;
            }

            if (this.sharePVL) {
                this.params['pvlId'] = this.pvl._id ? this.pvl._id : 'default';
            }

            return this.params;
        },

        // remove
        closeDrop: function closeDrop() {
            if ((this.drop !== undefined) && this.drop.isOpened()) {
                this.drop.close();
            }
        },

        // remove
        deleteTemplate: function deleteTemplate(template) {

            var deleteConfirmed = function deleteConfirmed() {

                this.deleting = true;
                var analyticsArgs = {
                    template_id: template.id(),
                    template_type: 'email',
                    source: 'email editor'
                };

                this.TemplatesManager.deleteEmail(this.company, this.currUser, template.id()).then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_template_permanently_confirmed, analyticsArgs);
                }.bind(this)).catch(function failed(resp) {
                    var error = 'FILE.TEMPLATES._ERROR_OCCUR_MESSAGE_';

                    if (resp.data.error_type === 'CannotDeleteDefaultEmail') {
                        error = 'FILE.TEMPLATES.EMAIL._ERROR_DELETE_DEFAULT_EMAIL_MESSAGE_';
                    }

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, error);

                    analyticsArgs.error = error;
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_template_permanently_failed, analyticsArgs);
                }.bind(this)).finally(function done() {
                    this.deleting = false;
                }.bind(this));
            }.bind(this);

            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info,
                'FILE.TEMPLATES.EMAIL._DELETE_EMAIL_CONFIRM_MESSAGE_',
                deleteConfirmed);
        },

        // 21. Update body with selected email template. needed?
        templateChosen: function templateChosen(template) {

            var templateId = template.id();

            var analyticsArgs = {
                company_id: this.company.id(),
                template_type: 'email',
                template_id: templateId,
                source: 'email editor'
            };

            this.TemplatesManager.getEmail(this.company, this.signatureUser, templateId).then(function success() {
                var currentTemplate = this._.find(this.company.vendor_emails, function (template) {
                    return template._id === templateId;
                });

                this.subject = currentTemplate.subject;
                var body = this.UIUtils.getHtmlBodyCleanInlineStyle(currentTemplate.html_body);
                var attachedFiles = this.AttachmentsService.getAdditiveAttachments(currentTemplate.image_attachments, currentTemplate.file_attachments, this.imageFiles, this.generalFiles);
                this.imageFiles = attachedFiles.images;
                this.generalFiles =  attachedFiles.files;

                if (this.config.isSendToWorkspaces) {
                    while (body.indexOf("<input") > -1) {
                        var startIndex = body.indexOf("<input");
                        var endIndex = body.indexOf(">", startIndex);
                        body = body.split(body.substring(startIndex, endIndex+1)).join('');
                    }
                }

                this.body = body;
                this.$scope.$broadcast('updateBody', this.body);

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.choose_template_success, analyticsArgs);
            }.bind(this)).catch(function error() {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.choose_template_error, analyticsArgs);
            }.bind(this));
        },

        // not relevant. remove
        confirmSavingAttachments: function confirmSavingAttachments()  {
            var defer = this.$q.defer();

            var countAttachments = this.generalFiles.length + this.imageFiles.length;

            if (!countAttachments) {
                defer.resolve();
            } else {
                var message = this.$translate.instant('EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._MESSAGE_SINGLE_');
                if (countAttachments > 1) {
                    message = this.$translate.instant('EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._MESSAGE_PLURAL_', {count: countAttachments});
                }

                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    message,
                    'EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._POSITIVE_',
                    'EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._NEGATIVE_',
                    'EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._TITLE_',
                    undefined,
                    undefined,
                    undefined,
                    'email-template-show-confirm-wrapper')
                    .then(function saveAttachments() {
                        this.generalFilesToSave = this.generalFiles;
                        this.imageFilesToSave = this.imageFiles;
                    }.bind(this))
                    .finally(function() {
                        defer.resolve();
                    });
            }

            return defer.promise;
        },

        // not relevant. remove
        _hasAttachment: function _hasAttachment() {
            return (this.generalFilesToSave.length + this.imageFilesToSave.length > 0);
        },

        // not relevant. remove
        templateSaveAs: function templateSaveAs(title) {
            if (!title) {
                return;
            }

            this.generalFilesToSave = [];
            this.imageFilesToSave = [];

            this.confirmSavingAttachments()
                .then(function () {
                    this.isSavingTemplate = true;
                    return this.TemplatesManager.saveAsEmail(this.company, title, this.getHtmlBodyCleanInputValue(), this.subject, this.generalFilesToSave, this.imageFilesToSave)
                }.bind(this))
                .then(function success() {
                    var analyticsArgs = {
                        company_id: this.company.id(),
                        template_type: 'email',
                        source: 'email editor',
                        has_attachment: this._hasAttachment()
                    };
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_as_template_success, analyticsArgs);

                    this.ToastService.showSuccess({
                        contentTranslation: 'EMAIL_EDITOR.PROMPT_USER_SAVE_ATTACHMENTS._TOAST_TEXT_',
                        iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                        dismissOnTimeout: true,
                        dismissButton: true,
                        timeout: 3000
                    });
                }.bind(this))
                .catch(function error() {
                    var analyticsArgs = {
                        company_id: this.company.id(),
                        template_type: 'email',
                        source: 'email editor',
                        has_attachment: this._hasAttachment()
                    };
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_as_template_success, analyticsArgs);
                }.bind(this))
                .finally(function final() {
                    this.isSavingTemplate = false;
                    this.showEmailTemplates = false;
                }.bind(this));
        },
        // remove
        closeMenu: function closeMenu() {
            this.showEmailTemplates = false;
        },

        // supported?
        getFromLine: function getFromLine() {
            if (this.company && this.company.owner) {
                return this.company.owner.full_name;
            } else {
                return "";
            }
        },

        // remove
        getToLine: function (users) {
            var toLine = '';
            if (!angular.isArray(users)) {
                users = [];
            }

            if (this.config.isSendToWorkspaces) {
                if (users.length > 1)  {
                    toLine = this.$translate.instant('EMAIL_EDITOR._ALL_PARTICIPANTS_PLURAL_', {count: users.length});
                } else {
                    toLine = this.$translate.instant('EMAIL_EDITOR._ALL_PARTICIPANTS_', {count: users.length});
                }
            } else if (this.config.sendBulkToContacts){
                toLine =  this.$filter('pluralize')(users.length, 'contact');

            } else {
                users.filter(function (user) { // filter users with email
                    return !!user.email;
                }).forEach(function (user) {
                    return toLine += user.full_name + ' <' + user.email + '>, ';
                }.bind(this));

                toLine = toLine.length > 0 ? toLine.substring(0, toLine.length - 2) : toLine;
            }

            return toLine;
        },

        close: function close() {
            this.closeDrop();
            // 23. pass data to resolved promise
            this.$modalInstance.close({subject: this.subject, body: this.getHtmlBodyWithoutInputs()});
        },

        cancel: function cancel() {
            // 24. pass data to rejected promise
            if (this.showingEmailScheduler) {
                return;
            }

            this.closeDrop();
            this.$modalInstance.dismiss('cancelEmail');
        },

        // remove
        emailTemplates: function emailTemplates() {
            this.showEmailTemplates = !this.showEmailTemplates;
        },

        // remove
        editPVL: function editPVL() {
            this.PvlService.editPreferredVendorsList(this.pvl);
        },

        // remove
        validatePreferredVendorPreferences: function validatePreferredVendorPreferences() {
            // TODO: Move the check if the user has preferred vendors in contacts to here, and make it async

            if(!this.sharePVL) {
                return true;
            }

            var useDefaulePvl = !this.pvl || !this.pvl.preferred_vendor_ids || this.pvl.preferred_vendor_ids.length == 0;

            if (useDefaulePvl && !this.hasPreferredVendors)
            {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'FILE.SEND_FILE.EMAIL_MODAL.PVL._NO_PREFERRED_VENDORS_');

                // analytics
                this.AnalyticsService.track(this, 'alert: cant attach empty pvl to file');

                return false;
            }


            // we got here so all cool
            return true;
        },

        // not sure we need this
        getFirstClientFirstName: function getFirstClientFirstName() {
            if (this.config.ignoreClientNameField || this.config.isSendToWorkspaces || this.config.sendBulkToContacts) {
                return '';
            } else {
                return this.UserService.getFirstClientFirstName(this.users, this.currUser);
            }

        },

        /**
         * Replaces all input fields with their values. Should be used before sending since
         * we don't know which mail client support input fields and how will their styling look like.
         *
         * @returns {String} The html_body for the email after removing the input fields
         */
        getHtmlBodyWithoutInputs: function getHtmlBodyWithoutInputs() {
            return this.UIUtils.replaceHtmlInputsWithString(this.getBody(), '$3');
        },

        /**
         * Clean up all values in the input fields and leave them empty. This should be used
         * before saving this email as a template.
         * @returns {*}
         */
        getHtmlBodyCleanInputValue: function getHtmlBodyCleanInputValue() {
            var body = this.getBody();
            // Clean up all values in the input fields
            return body.replace(/((<input [^/>]+)(value="([^"]*)")([^/>]+))(\/?>)/g, '$2value=""$5$6');
        },

        /**
         * Pop open the add participant modal
         * @returns {*}
         */
        // removed
        addWorkspaceParticipant: function addWorkspaceParticipant() {
            if (this.showAddWsParticipantBtn && this.workspace) {
                this.WorkspaceService.addWorkspaceParticipant(this.workspace, null, this.company).then(function (resp) {
                    var newUser = resp.user;
                    var userAlreadyInWorkspace = this._.find(this.users, function (user) {
                        return user._id === newUser._id;
                    });
                    if (!userAlreadyInWorkspace) {
                        newUser.recepient_manually_added = true;
                        this.users.push(newUser);
                    }
                    else {
                        var index = this._.findIndex(this.users, function(user) { return user._id === newUser._id; });
                        this.users[index].recepient_manually_added = true;
                    }
                    this.toLine = this.getToLine(this.users);
                }.bind(this));
            }
        },

        // 25. prepareAttachedFiles
        prepareAttachedFiles: function prepareAttachedFiles() {

            try {
                var attachedFiles = {
                    generalFiles: this.AttachmentsService.translateFilesListForServer(this.generalFiles),
                    imageFiles: this.AttachmentsService.translateFilesListForServer(this.imageFiles)
                };
            }
            catch (e) {
                // We failed miserably while trying to prepare the message for sending
                // and now, with the draft saving, even refresh won't save us!
                // So I'm resetting the file section of the message editor :-(
                this.imageFiles = [];
                this.generalFiles = [];
            }
            return attachedFiles;
        },

        // 26. getAllUploadingFilePromises

        getAllUploadingFilePromises: function getAllUploadingFilePromises() {
            return this.$q.all(this._.map(this.uploadingFilesPromises, function (node) {
                return node.fileUploadPromise;
            }));
        },

        //27. closeModalWithDelay
        closeModalWithDelay: function closeModalWithDelay() {
            // mark as success
            this.sendSuccess = true;

            // close modal after 2 seconds
            this.$timeout(function() {
                this.$modalInstance.close();
            }.bind(this), 2000);
        }

    });


}());
