
Directives.SavingIndicatorDirective = function SavingIndicatorDirective() {

    // @ngInject
    function SavingIndicatorDirectiveControllerFunc($scope, $injector, SavingIndicatorService, DeviceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SavingIndicatorDirectiveController';
        this.SavingIndicatorService = SavingIndicatorService;
        if(!this.model){
            return;
        }
        this.SavingIndicatorService.registerModel(this.model);
        this.updateCount = 0;
        this.savingStateText = '';
        this.nextShouldBeIgnored = this.ignoreFirst === "true";

        // Hack alert - we keep the possibility for the isActive to be undefined so it won't mess with
        // Changing states like in the company settings
        if (!this.ignoreFirst || this.ignoreFirst === "false") {
            this.isActive = true;
        }

        this.modelUpdating = function modelUpdating(){
            if (!this.isActive){
                return;
            }

            this.savingStateText = 'SAVING_MODEL_MESSAGE._SAVING_';
        };

        this.updateSuccess = function updateSuccess(){
            if (this.isActive === false || this.nextShouldBeIgnored){
                this.isActive = true;
                this.savingStateText = '';
                this.nextShouldBeIgnored = false;
                return;
            }

            this.savingStateText = DeviceService.nxSmallBreakpoint() 
                                    ? 'SAVING_MODEL_MESSAGE._SAVED_' //  Saved!
                                    : 'SAVING_MODEL_MESSAGE._SAVED_FULL_'; // All changes saved!

            // even when the param is not mandatory, angular pass a wrapper function
            if (angular.isDefined(this.getSavingSuccessMsg) && angular.isDefined(this.getSavingSuccessMsg()))
            {
                this.savingStateText = this.getSavingSuccessMsg();
            }
        };

        this.updateError = function updateError(){

            if (this.isActive === false || this.nextShouldBeIgnored){
                this.isActive = true;
                this.nextShouldBeIgnored = false;
                return;
            }

            this.savingStateText = 'SAVING_MODEL_MESSAGE._ERROR_';
        };

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            this.isActive = false;
        }.bind(this));

        this.SavingIndicatorService.on('success', function(){
           this.updateSuccess();
        }.bind(this));
        this.SavingIndicatorService.on('error', function(){
            this.updateError();
        }.bind(this));
        this.SavingIndicatorService.on('update', function(){
            this.modelUpdating();
        }.bind(this));
    }

    var SavingIndicatorDirectiveController = Class(Controllers.BaseController, {
        constructor: SavingIndicatorDirectiveControllerFunc
    });

    return {
        scope: {
            model: '=savingIndicatorModel',
            ignoreFirst: '@savingIndicatorIgnoreFirstUpdate',
            getSavingSuccessMsg : '&?getSavingSuccessMsg'
        },
        templateUrl: 'angular/app/modules/common/ui_components/saving_indicator/saving_indicator_directive_template.html',
        controller: SavingIndicatorDirectiveController,
        controllerAs: 'savingIndicatorVm',
        bindToController: true
    };
};