(function () {
    "use strict";

    // @ngInject
    function InlineSvgComponentController($scope, $element, $attrs, $parse, $http, $) {

        this._loadSvgContent = function(svgSrc) {
            $http.get(svgSrc).then(function(response) {
               this._injectSvgContent(response.data);
            }.bind(this));
        };

        this._injectSvgContent = function(svgContent) {
            $($element).html(svgContent);
        };

        this.$onInit = function $onInit() {

        };

        this.$onChanges = function $onChanges() {
            this._loadSvgContent(this.src);
        };
    }

    Components.InlineSvg = {
        bindings: {
            src: '@'
        },
        controller: InlineSvgComponentController,
        name: 'hbInlineSvg'
    };
}());