(function () {
    "use strict";

    // @ngInject
    function ListComponentControllerFunc($injector, $scope, $sce, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ListComponentController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
    }

    var ListComponentController = Class(Controllers.BaseController, {
        constructor: ListComponentControllerFunc,

    });

    Components.ListComponent = {
        bindings: {
            component: '=',
            isRootComponent: '<',
            isEditable: '<',
        },
        controller: ListComponentController,
        controllerAs: 'listComponentVm',
        name: 'hbListComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/list/list_component.html',
    };

}());
