// @ngInject

Models.NetworkProjectModel = function NetworkProjectModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'NetworkProjectModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        getCoverPhoto: function getCoverPhoto() {
            var self = this;
            var cover_images = this.gallery_assets.filter(function (item) {
                return self.default_cover_image_id === item._id;
            });
            if (!cover_images || cover_images.length === 0){
                return this.gallery_assets[0];
            }
            return cover_images[0];
        }

    });

};

