(function () {
    "use strict";

    // @ngInject
    function BlockCoverImgControllerFunc($injector, $scope, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockCoverImgDirectiveController';
        this.AnalyticsService = AnalyticsService;

        this.imageModel = '';
    }

    var BlockCoverImgDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockCoverImgControllerFunc,

        saveImage: function saveImage(imageModel) {
            this.block.image = imageModel;
            this.block.updateBlock(['image']);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'image', block_type: "image"});
        }

    });

    Components.BlockCoverImg = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        controller: BlockCoverImgDirectiveController,
        controllerAs: 'blockCoverImgVm',
        name: 'hbBlockCoverImg',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_cover_img/block_cover_img.html',
    };

}());