/**
 * Created by inonstelman on 12/28/14.
 */
(function () {
    'use strict';

    // @ngInject
    Services.underscore = function underscore($window) {
        return $window._;
    };


}());