/**
 * Created by dvircohen on 02/08/2016.
 */
(function () {
    "use strict";

    // @ngInject
    function FeaturesServiceCtor(moment) {
        this.moment = moment;
    }

    Services.FeaturesService = Class(() => {

        return {
            constructor: FeaturesServiceCtor,

            features: {
                // Format: yyyy-mm-dd

                loginless: '2016-08-14',
                asideNotification: '2017-07-31',
                notifications: '2017-08-20',
                pretrialUpgrade: '2017-09-10',
                onboardingBanner: '2017-10-03',
                productExplanationBanners: '2018-01-08',
                onboardingSetupGuide: '2017-12-17',
                setupGuideDemoAccount: '2018-02-06',
                membershipModal: '2018-05-14',
                setupGuideProgressBarForSubscribedUsers: '2018-05-01',
                setupGuideModal: '2018-07-08',
                home: '2018-07-20',
                recurringPayments: '2018-08-20',
                multiBrandEducation: '2019-02-10',
                brochureConvertTemplate: '2019-02-19',
                pipelineOnboarding: '2019-02-13',
                brochureEducation: '2019-02-17',
                templatesEditorDrawerPopover: '2019-03-26',
                streamlinedInvoiceVsProposal: '2019-09-24',
                interactiveSetupGuideEndTest: '2019-12-01',
                sendTestFileAnimation: '2020-03-09',
                templatesExplainModal: '2020-04-10',
                bookingReview: '2021-10-10'
            },

            rolloutFeatures: {
                multipleContactForms: 'multiple_contact_forms',
                invoiceRevamp: 'invoice_revamp',
                doodles: 'doodles',
                // tax_mgmt: 'tax_mgmt', // obsolete
                leadsInsights: 'leads_insights', //removed from $rollout for now.
                expenses_import: 'expenses_import',
                automations: 'automations',
                bulkEmails: 'bulk_emails',
                schedulingExposurePoc: 'scheduling_exposure_poc',
                publishTemplates: 'publish_templates',
                yearInReview: 'year_in_review_2020',
                blockTemplates: 'block_templates',
                capitalJaris: 'capital_jaris',
                flowsFolders: 'flows_folders',
                otamTestMigrateToFlows: 'otam_test_migrate_to_flows',
                schedulerBlock: 'schedule_block',
                clientVendorSeparation:'client_vendor_separation',
                financeApp: 'finance_app',
                viewAllInPipeline: 'pipeline_view_all',
                reactTasks: 'react_tasks',
                enableNewExport: 'new_reports_export',
                showEstimatedFees: 'show_estimated_fees',
                globalBanner:'global_banner'
            },

            packagingFeatures: {
                quickbooks: 'quickbooks',
            },

            MEMBER_CURBSIDE_CONFECTIONARY_ACCOUNT_ID: '5ff07d2c357f3501b2823926',

            isNewNotesByUser: function isNewNotesByUser(user) {
                // Special occasion for Curbside Confectionary account. specific account that is still served old notes. hardcoded to save on statsig check
                return user.account_id !== this.MEMBER_CURBSIDE_CONFECTIONARY_ACCOUNT_ID;
            },

            isNewForUser: function isNewForUser(user, featureLaunchDate) {
                if (!user || !featureLaunchDate) {
                    return;
                }

                return this.moment(user.created_at).isBefore(featureLaunchDate);
            },

            wasCreatedAfterFeature: function wasCreatedAfterFeature(user, featureLaunchDate) {
                return !this.isNewForUser(user, featureLaunchDate);
            },

            isNewForCompany: function isNewForUser(company, featureLaunchDate) {
                if (!company || !featureLaunchDate) {
                    return;
                }

                return this.moment(company.created_at).isBefore(featureLaunchDate);
            },

            companyWasCreatedAfterFeature: function wasCreatedAfterFeature(company, featureLaunchDate) {
                return !this.isNewForCompany(company, featureLaunchDate);
            },


            startedOnboardingAfterFeature: function startedOnboardingAfterFeature(user, featureLaunchDate) {
                if (!user || !featureLaunchDate || !user.getTrial() || !user.getTrial().start_date) {
                    return false;
                }

                return this.moment(featureLaunchDate).isBefore(user.getTrial().start_date);
            },

            isNewForUserByFirstLogin: function isNewForUserByFirstLogin(user, featureLaunchDate) {
                if (!user || !featureLaunchDate) {
                    return;
                }

                return this.moment(user.first_login).isBefore(featureLaunchDate);
            },

            wasFeatureEnabledBefore(featureLaunchDate, numberOf, timePeriod) {
                if (!featureLaunchDate || !numberOf || !timePeriod) {
                    return;
                }

                return this.moment(featureLaunchDate).add(numberOf, timePeriod).isBefore()
            }
        };
    });
}());
