
// @ngInject
Directives.ChangeSrcOnHoverDirective = function ChangeSrcOnHoverDirective($timeout, $window, autosize) {


    return {
        scope: {
          srcToHover: '@srcToHover',
          srcHoverOff: '@srcHoverOff'
        },
        restrict: 'A',
        link: function(scope, element, attrs, ngModel) {

            var enterFlag = false;

            function switchToHoverSrc(e){
                // if it has been 1s+ since the last mouseenter, do stuff
                if(enterFlag==false){
                    enterFlag = true;

                    element.css('visibility', 'hidden');
                    $timeout(function() {
                        element.attr('src',scope.srcToHover);
                        element.css('visibility', 'visible');
                    }, 100);

                    $timeout(function(){
                        enterFlag=false;
                        //switchToSrc();
                    },1000);
                }
            }
            function switchToSrc(){
                //ISSUE: Switching the src will reanimate a gif that is the current src. - even if you hide the element
                element.css('visibility', 'hidden');
                $timeout(function() {
                    element.attr('src', scope.srcHoverOff);
                    element.css('visibility', 'visible');
                },100);

            }

            element.on('mouseenter', switchToHoverSrc);

            //ISSUE: Switching to the off image will reanimate a gif.
            //element.on('mouseleave',switchToSrc);

            scope.$on("$destroy", function () {
                element.off('mouseenter');
            });




        }
    };

};