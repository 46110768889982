(function () {
    'use strict';

    // @ngInject
    Services.CkEditor5 = function CkEditor5($window) {
        if ($window.CkEditor5) {
            return $window.CkEditor5;
        }
        return {};
    };

}());
