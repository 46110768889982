(function () {
    "use strict";

    Controllers.NgInReactComponentRendererController = class NgInReactComponentRendererController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $stateParams,
            $interval,
            ngInReactService
        ) {
            super($scope, $injector);
            this.__objectType = 'NgInReactComponentRendererController';
            this.componentName = $stateParams.componentName;

            let lastScrollHeight = document.body.scrollHeight;
            let sameHeightCount = 0;
            let maxSameCount = 3;
        
            const intervalId = $interval(() => {
                const currentScrollHeight = document.body.scrollHeight;
                if (currentScrollHeight === lastScrollHeight) {
                    sameHeightCount++;
                } else {
                    ngInReactService.sendMessage(
                        'Loaded', 
                        { 
                            scrollHeight: document.body.scrollHeight,
                             componentName: this.componentName 
                        }
                    );
                    lastScrollHeight = currentScrollHeight;
                    sameHeightCount = 0;
                }
        
                if (sameHeightCount >= maxSameCount) {
                    $interval.cancel(intervalId);
                    console.log('Interval stopped after 3 consecutive checks with the same scroll height.');
                }
            }, 1500);
        
            $scope.$on('$destroy', function() {
                $interval.cancel(intervalId);
            });
        }
    };

}());
