(function () {
  'use strict';

  class CalendarPopoverListItemComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, AppStates, UIUtils) {
      super($scope, $injector);
      this.__objectType = 'CalendarPopoverListItemComponent';
      this.AppStates = AppStates;
      this.UIUtils = UIUtils;
    }

    fixedProjectLink() {
      return this.UIUtils.fixExternalUrl(this.project.link);
    }
  }

  Components.CalendarPopoverListItem = {
    bindings: {
      icon: '@',
      label: '<',
      secondaryLabel: '<',
      project: '<'
    },
    controller: CalendarPopoverListItemComponent,
    name : 'hbCalendarPopoverListItem',
    templateUrl: 'angular/app/modules/core/features/calendar/calendar_popover/calendar_popover_list_item.html',
  };

}());

