(function () {
    'use strict';

    class FeedItemFlowPaymentViewerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $filter, $window, Enums, UsersManager, AnalyticsService, FlowService) {
            super($scope, $injector);
            this.__objectType = 'FeedItemFlowPaymentViewerController';

            this.$translate = $translate;
            this.$filter = $filter;
            this.$window = $window;
            this.Enums = Enums;
            this.actionType = this.activity.action_type;
            this.AnalyticsService = AnalyticsService;
            this.FlowService = FlowService;
            this.flowTitle = this.activity.title;
            this.payment = this.activity.payment;
            this.shouldShowCompletedBox = false;
            this.shouldShowArrow = true;
            this.isVisibleOnlyForVendor = false;
            this.shouldShowPaymentCounter = false;
            this.currUser = UsersManager.getCurrUser();
            this.isClient = this.currUser.isClient();
            this.full_name = this.getUserFullName();

            $scope.$watch('$ctrl.workspace.flows', (newVal, oldVal) => {
                if(newVal !== oldVal) {
                    this.flow = this.workspace.flows ? this.workspace.flows.find((flow) => flow._id === this.activity.flow_id) : null;
                }
            });
        }

        $onInit() {
            switch (this.activity.action_type) {
                case this.Enums.flowPaymentActivities.markedAsPaid:
                    this.setMarkAsPaidFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.markedAsUnpaid:
                    this.setMarkAsUnpaidFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.markedAsRefund:
                    this.setMarkAsRefundFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.refund:
                    this.setRefundFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.paid:
                    this.setPaidPaymentFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.pending:
                    this.setPendingPaymentFlowItem();
                    break;
                case this.Enums.flowPaymentActivities.paymentRequest:
                    this.setPaymentRequestFlowItem();
                    break;
            }

            this.flow = this.workspace.flows ?
                this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id || flow._id === this.activity.flow_id) :
                null;
        }

        setMarkAsPaidFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._MARK_AS_PAID_';
            const amount = this.payment.amount;
            this.title = this.$translate.instant(translation, {full_name: this.full_name, amount, title: this.flowTitle});
        }

        setMarkAsUnpaidFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._MARK_AS_UNPAID_';
            const amount = this.payment.amount;
            this.title = this.$translate.instant(translation, {full_name: this.full_name, amount, title: this.flowTitle});
        }

        setMarkAsRefundFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._MARK_AS_REFUND_';
            const amount = this.payment.refunded_amount || this.payment.amount;
            this.title = this.$translate.instant(translation, {full_name: this.full_name, amount, title: this.flowTitle});
        }

        setRefundFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._REFUND_';
            const amount = this.payment.refunded_amount || this.payment.amount;
            const client = this.activity.payer.full_name;
            this.title = this.$translate.instant(translation, {full_name: this.full_name, amount, client, title: this.flowTitle});
        }

        setPaidPaymentFlowItem() {
            const gratuity = this.payment.tip_paid;
            const translation = gratuity > 0 ? 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._PAID_WITH_GRATUITY_' : 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._PAID_';
            const amount = this.payment.amount;
            const client = this.full_name;
            const paymentMethod = this.payment.payment_method_type === 'bank_account' ? 'bank transfer' : 'credit card';
            this.title = this.$translate.instant(translation, {amount, client, paymentMethod, gratuity, title: this.flowTitle});
            this.shouldShowPaymentCounter = this.payment.total_payments_count > 1;
        }

        setPendingPaymentFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._PAYMENT_PENDING_';
            const amount = this.payment.amount;
            const client = this.activity.payer.full_name;
            this.title = this.$translate.instant(translation, {amount, client, title: this.flowTitle});
            this.shouldShowPaymentCounter = this.payment.total_payments_count > 1;
        }

        setPaymentRequestFlowItem() {
            const translation = 'WORKSPACE.FEED.ACTIVITY.FLOW.PAYMENT._PAYMENT_REQUESTED_';
            this.title = this.$translate.instant(translation, {full_name: this.full_name, title: this.flowTitle});
        }

        getUserFullName() {
            if (this.activity.user) {
                return this.currUser._id === this.activity.user._id ? 'You' : this.activity.user.full_name;
            }
            return 'Client';
        }

        onClick(event) {
            event.stopPropagation();
            this.FlowService.goToFlowByUser(this.flow, this.currUser);
        }
    }

    Components.FeedItemFlowPaymentViewer = {
        bindings: {
            activity: '<',
            workspace: '<?'
        },
        controller: FeedItemFlowPaymentViewerController,
        name: 'hbFeedItemFlowPaymentViewer',
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_flow_payment_viewer_template.html',
    };

}());