(function () {
    'use strict';

    // @ngInject
    function TemplatesFullPageEditorControllerCtor($scope, CompaniesManager, $injector, _, $, $state, $stateParams, SavingIndicatorService, UsersManager, ImageService,
                                                    TemplatesViewService, AnalyticsService, ModalService, NavigationService, CompanyService, FeaturesService,
                                                   UiPersistenceService, $timeout, DeviceService, TemplatesManager, moment, AppConfigService, AppStates, OnboardingService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplatesFullPageEditorController';
        AnalyticsService.track(this, 'Company Templates');

        this.NavigationService = NavigationService;
        this.TemplatesViewService = TemplatesViewService;
        this.TemplatesManager = TemplatesManager;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.CompanyService = CompanyService;
        this.CompaniesManager = CompaniesManager;
        this._ = _;
        this.$ = $;
        this.UiPersistenceService = UiPersistenceService;
        this.$timeout = $timeout;
        this.DeviceService = DeviceService;
        this.FeaturesService = FeaturesService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.moment = moment;
        this.AppConfigService = AppConfigService;
        this.AppStates = AppStates;
        this.OnboardingService = OnboardingService;
        this.ImageService = ImageService;

        this.disableTitleUpdate = false;
        this.isEditable = true;
        this.company = CompaniesManager.getCurrCompany();
        this.shouldShowFilmstrip = false;
        this.firstLinkFilmStrip = '';
        this.loading = false;

        this.mobileNav = function mobileNav() {
            this.mobileDropDown = !this.mobileDropDown;
            this.showTemplatesNav = !this.showTemplatesNav;
        };

        this.showIconLoader = false;
        SavingIndicatorService.registerModel(UsersManager.getCurrUser());

        $scope.$on('$destroy', function() {
            SavingIndicatorService.unregisterModel(UsersManager.getCurrUser());
            this.TemplatesViewService.onExitEditor();
            this.AppConfigService.appState().showLegacyTemplatesBanner = false;
        }.bind(this));

        this.TemplatesViewService.setTemplateModel($stateParams.templateModelKey, $stateParams.templateId);
        if(!this.TemplatesViewService.currentTemplate) {

            if (this.company.wasFullyFetched()) {
            	if(!$stateParams.templateId) {
					this.TemplatesViewService.setFirstTemplateAsActive();
				}
            } else {
                this.loading = true;

                this.registerOnce(this.company, 'success', function gotCompany() {
                	if(!$stateParams.templateId) {
						this.TemplatesViewService.setFirstTemplateAsActive();
					}
                    if(this.TemplatesViewService.allowMigrationToFlow()) {
                        this.AppConfigService.appState().showLegacyTemplatesBanner = true;
                    }
                    this.loading = false;
                }.bind(this));
            }
        }

        this.currentTemplateModel = this.TemplatesViewService.currentModel;
        this.shouldShowCarrousel = this.showCarrousel();
        this.loadFilmStrip();

        this.user = UsersManager.getCurrUser();

        this.isContactFormOnMobile = DeviceService.nxSmallBreakpoint() && $stateParams.templateModelKey === 'contactForm';
        this.isInitialyOpenAsideDrawer = this.$stateParams.isDrawerOpen;

        this.showDrawerPopover = !this.$stateParams.isDrawerOpen && this.shouldShowDrawerPopover();

        this.reloadContactForm = false;
        TemplatesManager.on('contactFormsMigratedToDynamic', function() {
            this.reloadContactForm = true;
            this.UiPersistenceService.setUiPersistenceInnerKey(this.UiPersistenceService.keys.contactFormDynamicOnboarding, 'fromMigration', true);
            this.$timeout(function () {
                this.reloadContactForm = false;
            }.bind(this), 100);
        }.bind(this));

        this.OnboardingService.onTemplateLoaded($stateParams.templateModelKey);
        this.AnalyticsService.trackPageView(this, 'template', {type: this.$stateParams.templateModelKey});

        if(this.TemplatesViewService.allowMigrationToFlow()) {
            this.AppConfigService.appState().showLegacyTemplatesBanner = true;
        }
        
        if($stateParams.templateModelKey === 'email'){
            this.$state.go(this.AppStates.root_core_oneComposer,
                {
                    templateModelKey: 'email',
                    templateId: $stateParams.templateId
                });
        }
    }

    Controllers.TemplatesFullPageEditorController = Class(Controllers.BaseController, {
        constructor: TemplatesFullPageEditorControllerCtor,

        clickFilmstripAddIntro: function clickFilmstripAddIntro(brochure) {
            this.loadingFilmstripAddIntro = false;
            brochure.createGreeting().finally(function success() {
                this.loadingFilmstripAddIntro = true;
                this.openMenu = false;
            }.bind(this));
        },

        clickFilmstripAddPackage: function clickFilmstripAddPackage(brochure) {
            this.loadingFilmstripAddPackage = false;
            brochure.createBrochureProposal().finally(function success() {
                this.loadingFilmstripAddPackage = true;
                this.openMenu = false;
            }.bind(this));
        },

        deleteBrochureIntro: function deleteBrochureIntro(brochure) {
            brochure.deleteGreeting();
        },

        clickFilmstripAddALaCarte: function clickFilmstripAddALaCarte(brochure) {
            this.loadingFilmstripAddALaCarte = false;
            brochure.createBrochureALaCarteProposal().finally(function success() {
                this.loadingFilmstripAddALaCarte = true;
                this.openMenu = false;
            }.bind(this));
        },

        shouldShowAddALaCarte: function shouldShowAddALaCarte(brochure) {
            return brochure.a_la_carte_proposal && !brochure.a_la_carte_proposal.isEmpty();
        },

        triggerAsideDrawer: function triggerAsideDrawer() {
            this.appState.asideDrawerOpen = !this.appState.asideDrawerOpen;
        },

        showAsideDrawer: function showAsideDrawer() {
            this.appState.asideDrawerOpen = true;
        },

        onAddNewTemplateClick: function onAddNewTemplateClick(templateViewModel) {
            this.TemplatesViewService.addNewTemplate(templateViewModel);
            if (this.DeviceService.nxSmallBreakpoint()) {
                this.appState.asideDrawerOpen = false;
            }
        },

        onTemplateSelect: function onTemplateSelect(template, templateViewModel) {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.template_select,
                {type: template.type, template_name: template.title}
            );
            this.TemplatesViewService.updateCurrentTemplate(template, templateViewModel);
            if (this.DeviceService.nxSmallBreakpoint()) {
                this.appState.asideDrawerOpen = false;
            }
        },

        showCarrousel: function showCarrousel() {
          return this.currentTemplateModel === this.TemplatesViewService.questionnaire ||
              this.currentTemplateModel === this.TemplatesViewService.invoice ||
              this.currentTemplateModel === this.TemplatesViewService.proposal ||
              this.currentTemplateModel === this.TemplatesViewService.agreement;
        },

        shouldShowEmptyState: function shouldShowEmptyState() {
            var isNotLoading = !(this.TemplatesViewService.loadingTemplate || this.loading);
            var hasModelWithNoTemplate = this.TemplatesViewService.currentModel && !this.TemplatesViewService.currentTemplate;
            var shouldShowEmptyState = this.$stateParams.shouldShowEmptyState;

            return shouldShowEmptyState || (isNotLoading && hasModelWithNoTemplate);
        },

        saveFileImage: function saveFileImage(type, image) {
            var promise = this.TemplatesViewService.currentTemplate.updateCoverImage(image);
            if (type === 'reposition') {
                this.shouldShowCarrousel = false;
                this.$scope.$applyAsync(function loadChangesTimeout() {
                    this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function getCompanySuccess() {
                        this.shouldShowCarrousel = true;
                    }.bind(this));
                }.bind(this));
            }
            return promise;
        },

        changeIcon: function changeIcon() {
            this.showIconLoader = true;
            var logoChangeFinally = function logoChangeFinally() {
                this.showIconLoader = false;
            }.bind(this);

            var model = {
                instance: this.company,
                type: 'icon'
            };

            var options = {
                selectedImage: this.company.icon_image,
                imageDisplayRatio: 1,
                showPreviewWhenCroppingDisplay: true,
                skipSavingToServer: false,
                title: 'MEDIA_MODAL.TITLE._UPLOAD_NEW_LOGO_',
                defaultTab: '',
                model: model,
                showGalleryTab: false,
                footNoteText: 'MEDIA_MODAL._FOOT_NOTE_'
            };

            this.ImageService.chooseImage(options)
                .then(logoChangeFinally)
                .catch(logoChangeFinally);

        },

        asideToggle: function asideToggle(isOpen) {
            if (this.DeviceService.isSafari() || this.DeviceService.isFireFox()) {
                if (this.DeviceService.navLargeBreakPoint()) {
                    return;
                }

                this.fixPositioningOfFixedElements();
            }

            if (isOpen) {
                this.onCloseDrawerPopover();
            }
        },

        fixPositioningOfFixedElements: function fixPositioningOfFixedElements() {
            //FIX FOR: Position fixed on filmstrip, agreement message, ad ck-editor- gets out of context when transitioned left panel. FF && SAFARI.
            //This is a workaround for a known issue for safari: "transition positioned fixed children"
            angular.element('.file-steps').css('display', 'none');
            angular.element('.agreement-fields-message').css('display', 'none');
            angular.element('.cke_top.cke_reset_all').css('display', 'none');
            this.$timeout(function () {
                angular.element('.file-steps').css('display', 'flex');
                angular.element('.agreement-fields-message').css('display', 'flex');
                angular.element('.cke_top.cke_reset_all').css('display', 'flex');

            }.bind(this), 500);
        },

        loadFilmStrip: function loadFilmStrip() {
            switch(this.currentTemplateModel) {
                case this.TemplatesViewService.proposal:
                    this.linksTo = [
                        {label: 'FILE.FILMSTRIP._PROPOSAL_', element: 'hb-proposal'},
                        {label: 'FILE.FILMSTRIP._PAYMENTS_', element: 'hb-payments'},
                        {label: 'FILE.FILMSTRIP._AGREEMENT_', element: 'hb-agreement'},
                    ];
                    this.firstLinkFilmStrip = 'hb-proposal';
                    this.shouldShowFilmstrip = true;
                    break;

                case this.TemplatesViewService.invoice:
                    this.linksTo = [
                        {label: 'FILE.RIBBON_LABEL._INVOICE_', element: 'hb-proposal-invoice'},
                        {label: 'FILE.FILMSTRIP._PAYMENTS_', element: 'hb-payments'},
                    ];
                    this.firstLinkFilmStrip = 'hb-proposal-invoice';
                    this.shouldShowFilmstrip = true;
                    break;

                default:
                    this.firstLinkFilmStrip = '';
                    this.shouldShowFilmstrip = false;
            }
        },

        loadPackageUsage: function loadPackageUsage() {
            this.showPackageUsage = !this.showPackageUsage;

            if (this.loadingPackageUsage) {
                return;
            }

            this.loadingPackageUsage = true;

            this.CompaniesManager.getUsageOfPackage(this.company, this.TemplatesViewService.currentTemplate)
                .then(function success(resp) {
                this.usageInTemplates = resp.data;
            }.bind(this))
                .finally(function final() {
                   this.loadingPackageUsage = false;
                }.bind(this));

        },

        goToUsedTemplate: function goToUsedTemplate(template) {
            this.TemplatesViewService.goToTemplatesEditor(template.type, template._id);
        },

        closePackageUsagePopover: function closePackageUsagePopover() {
            this.showPackageUsage = false;
        },

        isNewBrochure: function isNewBrochure() {
            return (this.TemplatesViewService.currentTemplate && this.TemplatesViewService.isTemplateModel(this.TemplatesViewService.currentTemplate));
        },

        createBrochureTemplateFromOld: function createBrochureTemplateFromOld() {

            if(this.creatingNewTemplateInProgress) {
                return;
            }

            this.creatingNewTemplateInProgress = true;
            var template = this.TemplatesViewService.currentTemplate;
            this.TemplatesManager.createBrochureTemplateFromOld(this.company, template._id).then(function copySuccess() {
                this.creatingNewTemplateInProgress = false;
                var templatesList = this.TemplatesViewService.currentModel.templatesList();
                var lastCreatedTemplate = templatesList[templatesList.length - 1];
                this.onTemplateSelect(lastCreatedTemplate, this.TemplatesViewService.currentModel);
            }.bind(this),
            function copyError() {
                this.creatingNewTemplateInProgress = false;
            }.bind(this));
        },

        getDaysToConvert: function getDaysToConvert() {
            var baseDays = 45;
            var brochureConvertDate = this.FeaturesService.features.brochureConvertTemplate;
            var daysDiff = this.moment().diff(brochureConvertDate, 'days');
            var daysToConvert = baseDays - daysDiff;
            return daysToConvert > 1 ? daysToConvert : 2;
        },

        isBrochure: function isBrochure() {
            return this.TemplatesViewService.currentModel === this.TemplatesViewService.brochure;
        },

        isEmailSignature: function isEmailSignature() {
            return this.TemplatesViewService.currentModel === this.TemplatesViewService.emailSignature;
        },

        shouldShowMigrationBanner: function shouldShowMigrationBanner() {
            return (this.TemplatesViewService.currentTemplate &&
                !this.TemplatesViewService.loadingTemplate &&
                this.isBrochure() &&
                !this.isNewBrochure() &&
                !this.DeviceService.nxSmallBreakpoint());
        },

        showAccountSetupBanner: function showAccountSetupBanner() {
            if (this.currentTemplateModel === this.TemplatesViewService.invoice, this.TemplatesViewService.proposal, this.TemplatesViewService.package, this.TemplatesViewService.contract, this.TemplatesViewService.questionnaire) {
                var isUserSubscribed = this.user.companyHasSubscription() && this.user.company.account.pricing_plan;
                var timeToStop = isUserSubscribed ? this.moment(this.user.company.account.pricing_plan.plan_start_date).add(30, 'days') : false;
                return (this.user.isCompanyOwner() && this.user.company.offer_account_setup && (this.user.isInTrial() || this.moment().isBefore(timeToStop)));
            } else {
                return false;
            }
        },

        shouldShowDrawerPopover: function shouldShowDrawerPopover() {
            var startedAfterFeature = this.FeaturesService.startedOnboardingAfterFeature(this.currUser, this.FeaturesService.features.templatesEditorDrawerPopover);
            var seenDrawerPopover = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.templatesEditorDrawerPopover, {seen: false}).seen;
            var isMobile = this.DeviceService.checkIfMobileSize();
            var isEmailSignature = this.isEmailSignature();
            return !isEmailSignature && !isMobile && !seenDrawerPopover && (startedAfterFeature || !this.AppConfigService.isProduction());
        },

        onCloseDrawerPopover: function onCloseDrawerPopover() {
            this.showDrawerPopover = false;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.templatesEditorDrawerPopover, {seen: true});
        },

        isContactFormTypeModel: function isContactFormTypeModel() {
            return this.TemplatesViewService.currentModel === this.TemplatesViewService.contactForm ||
                this.TemplatesViewService.currentModel === this.TemplatesViewService.giftCard;
        },

        shouldShowRemitTo: function shouldShowRemitTo() {
            return (
                this.currentTemplateModel.name === this.TemplatesViewService.invoice.name
                || this.currentTemplateModel.name === this.TemplatesViewService.proposal.name
            );
        },
    });
}());
