
(function () {
    "use strict";


    // @ngInject
    function FeedServiceCtor(RepositoryService, Gon) {
        this.feedEditorInstance = null;
        this.RepositoryService = RepositoryService;
        this.Gon = Gon;
    }

    Services.FeedService = Class(function () {

        return {

            constructor: FeedServiceCtor,

            saveDraftMessage: function saveDraftMsg(feedParentId, msg) {
                var messageJson = {
                    files: [],
                    images: [],
                    htmlBody: msg.htmlBody,
                    pvl: msg.pvl,
                    subject: msg.subject,
                    replyMode: msg.replyMode
                };

                if (msg.images) {
                    msg.images.forEach(function eachImage(img) {
                        var imgJson;
                        if (img._id) { // Assuming this is an image from the user's "library"
                            imgJson = this._getAssetJSON(img);
                        } else { // Assuming it is a fresh image that was just uploaded
                            imgJson = this._getUploadedFileJSON(img);
                        }
                        messageJson.images.push(imgJson);
                    }.bind(this));
                }
                if (msg.files) {
                    msg.files.forEach(function eachFile(file) {
                        var fileJson;
                        if (file._id) { // Assuming this is a file from the user's "library"
                            fileJson = this._getAssetJSON(file);
                        } else { // Assuming it is a fresh file that was just uploaded
                            fileJson = this._getUploadedFileJSON(file);
                        }
                        messageJson.files.push(fileJson);
                    }.bind(this));
                }
                this.RepositoryService.storagePut(this.getDraftMessageKey(feedParentId), messageJson);
            },

            _getUploadedFileJSON: function _getUploadedFileJSON(data) {
                return {
                    dataUrl: data.dataUrl,
                    s3Response: data.s3Response,
                    name: data.name,
                    type: data.type,
                    uploaded: data.uploaded
                };
            },

            _getAssetJSON: function _getAssetJSON(data) {
                return {
                    _id: data._id,
                    _type: data._type,
                    asset_name: data.asset_name,
                    asset_type: data.asset_type,
                    file_type: data.file_type,
                    cloudinary_public_id: data.cloudinary_public_id,
                    original_url: data.original_url,
                    url: data.url
                };
            },

            deleteDraftMessage: function deleteDraftMessage(feedParentId) {
                this.RepositoryService.storageDelete(this.getDraftMessageKey(feedParentId));
            },

            getDraftMessage: function getDraftMessage(feedParentId) {
                return this.RepositoryService.storageGet(this.getDraftMessageKey(feedParentId));
            },

            getDraftMessageKey: function getDraftMessageKey(id) {
                return "draft-message-" + id;
            },

            registerFeed: function registerFeed(feed, leads) {
                if (this.feedInstance) {
                    console.log("NOTE!!! We don't support registering more than one feed per view.");
                    console.log("Consider adding support for multiple feed registrations or not registering a feed in your view");
                }
                this.feedInstance = feed;
                this.leads = this.mapLeads(leads);
            },

            refreshLeads: function refreshLeads(leads) {
                this.leads = this.mapLeads(leads);
            },

            unregisterFeed: function unregisterFeed() {
                this.feedInstance = null;
                this.leads = null;
            },

            registerFeedEditor: function registerFeedEditor(feedEditor) {
                this.feedEditorInstance = feedEditor;
                if (this.feedInstance) {
                    this.feedInstance.trigger("registerFeedEditor");
                }
            },

            unregisterFeedEditor: function unregisterFeedEditor() {
                this.feedEditorInstance = null;
                if (this.feedInstance) {
                    this.feedInstance.trigger("unregisterFeedEditor");
                }
            },

            populateFeedEditor: function populateFeedEditor(htmlBody, images, files, flushCurrentContent) {
                if (this.feedEditorInstance) {
                    this.feedEditorInstance.populateMessageEditor(htmlBody, images, files, flushCurrentContent);
                    return true;
                }
                return false;
            },

            createPreferredVendorList: function createPreferredVendorList(pvl_id) {
                if (this.feedEditorInstance) {
                    this.feedEditorInstance.callOnSharePreferredVendors(pvl_id);
                    return true;
                }
                return false;
            },

            getFeedEditorHtmlBody: function getFeedEditorHtmlBody() {
                if (this.feedEditorInstance) {
                    return this.feedEditorInstance.getHtmlContents();
                }
                return null;
            },

            getFeedEditorSubject: function getFeedEditorSubject() {
                if (this.feedEditorInstance) {
                    return this.feedEditorInstance.getSubject();
                }
                return null;
            },

            showSendTooltip: function showSendTooltip(tooltipText) {
                if (this.feedEditorInstance) {
                    this.feedEditorInstance.enableSendTooltip(tooltipText);
                }
            },

            hideSendTooltip: function hideSendTooltip(tooltipText) {
                if (this.feedEditorInstance) {
                    this.feedEditorInstance.disableSendTooltip(tooltipText);
                }
            },

            mapLeads: function mapLeads(leads) {
                if (leads) {
                    var leadsMap = {};
                    leads.forEach(function (lead) {
                        leadsMap[lead._id] = lead;
                    });
                    return leadsMap;
                }
            },

            getLead: function getLead(leadId) {
                if (this.leads) {
                    return this.leads[leadId];
                }
                return null;
            },

            getDefaultRecommendationsText: function getDefaultRecommendationsText(sender, type) {
                var senderName = sender ? sender.full_name : "&lt;Vendor Full Name&gt;"; //<Vendor Full Name> placeholder

                if (type === 'alternate_vendors') {
                  return "<p>Hi,<br />I&rsquo;m so sorry I&rsquo;ll be unavailable for your event.<br /><br />I thought you might want recommendations for some other great vendors. Here are a few options I handpicked to suit your style.<br /><br />Feel free to get in touch if you have any questions.<br /><br />Sincerely,<br />" +
                    senderName + "</p>";
                }
                else if (type === 'preferred_vendors') {
                    return "<p>Hi,<br />I&rsquo;m very excited to be working with you!<br /><br />As you continue to plan your event, I thought you may want recommendations for other vendors. Here are a few options I handpicked to suit your style.<br /><br />Feel free to get in touch if you have any questions.<br /><br />Sincerely,<br />" +
                      senderName + "</p>";
                }
                else {
                    return "Hi!";
                }
            },

            setFeedEditorPlaceholder: function setFeedEditorPlaceholder(placeholder) {
                if (this.feedEditorInstance) {
                    this.feedEditorInstance.setEditorPlaceholder(placeholder);
                    return true;
                }
                return false;
            },

            cleanFeedItemHtml: function cleanFeedItemHtml(html) {

                // Declare regex
                var hbLinkPattern = new RegExp("<a\\s+(?:[^>]*?\\s+)?href=\"" + this.Gon.url.replace(/\//g, '\\\/') + "app\/link\/resolve\/(?:[^>]*)\"[^>]*>(.*?)<\/a>", 'i');
                var mandrillLinkPattern = new RegExp(/<a\s+(?:[^>]*?\s+)?href=\"http:\/\/mandtrack\.honeybook\.com\/track\/click\/[a-z0-9]+\/www\.honeybook\.com\?p=([a-zA-Z0-9=]+)\"[^>]*>([\s\S]*?)<\/a>/g);

                // Remove hb links
                 if(hbLinkPattern.test(html)){
                    html = html.replace(hbLinkPattern, '$1');
                }

                // Remove mandrill links that leads to hb link
                // Mandrill links have mandrill url in order to track clicks
                // the hb link is base64 encoded inside that link
                // so we are extracting those links and check if we need to
                // replace them
                var matches = getMatches(html, mandrillLinkPattern, 1);
                if(matches && matches.length) {

                    // save number of matches
                    var numOfMatches = matches.length;

                    // hb link pattern
                    var hbUrlPattern = new RegExp(this.Gon.url.replace(/\//g, '\\\/') + "app\/link\/resolve\/", 'i');

                    // loop over matches and check if the relevant
                    for(var i = 0; i < numOfMatches; i++) {

                        var match = matches[i];

                        try {

                            // decode
                            var decoded = atob(match[1]);
                            var url = JSON.parse(JSON.parse(decoded).p).url;

                            if(hbUrlPattern.test(url)) {
                                html = html.replace(match[0], match[2]);
                            }
                        } catch(e) {

                        }

                    }
                }

                // REMOVES <style> added by email clients
                var doc = new DOMParser().parseFromString(html, 'text/html');
                doc.querySelectorAll('style').forEach(function(styleTag) {
                    styleTag.remove();
                });
                html = doc.body.innerHTML;

                function getMatches(string, regex) {
                    var matches = [];
                    var match;
                    while (match = regex.exec(string)) {
                        matches.push(match);
                    }
                    return matches;
                }

                return html;

            }
        };
    });
}());
