(function () {
    "use strict";

    // @ngInject
    function PipelineControllerCtor($scope, $injector, $document, $timeout, $,moment, FeaturesService,
                                    UsersManager, CompaniesManager, ReferralsManager, Enums, ContactsManager, $location,
                                    PopupMessageService, UserService, ModalService, UiPersistenceService, OnboardingService,
                                    DeviceService, AbTestService, AnalyticsService, NavigationService, InitialAppLoadParamsService, $stateParams, $translate, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PipelineController';
        this.currUser = UsersManager.getCurrUser();
        this.selectedUser = this.currUser;
        this.company = CompaniesManager.getCurrCompany();
        this.isSuperAdmin = this.currUser.hasCompanySuperAdminPermissions();
        this.CompaniesManager = CompaniesManager;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.PopupMessageService = PopupMessageService;
        this.ModalService = ModalService;
        this.UiPersistenceService = UiPersistenceService;
        this.ContactsManager = ContactsManager;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.UserService = UserService;
        this.AbTestService = AbTestService;
        this.NavigationService = NavigationService;
        this.moment = moment;
        this.PopupMessageService = PopupMessageService;
        this.OnboardingService = OnboardingService;
        this.$translate = $translate;
        this.selectTeamMember = this.selectTeamMember.bind(this);
        this.isOnboardingActive = OnboardingService.isOnboardingActive();
        this.onboardingCreateEventTooltipStep = OnboardingService.OnboardingSteps.createEventTooltip;
        this.$timeout = $timeout;
        this.isTouchDevice = this.DeviceService.checkIfTouchDevice();
        this.openningPipelineSettings = false;
        var onboardingMenuPersistenceHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.leftPanelView, {
            showOnboardingOptionsMenuOnLoad: true,
            persistOnboardingOptionsMenu: false
        });
        this.appState.isFileRouteAndClientMode = false;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.pipelineOnboardingIsOpen = false;

        this.onboardingMenuHasBeenSeen = !onboardingMenuPersistenceHash.showOnboardingOptionsMenuOnLoad;

        this.onboardingOverviewTaskAccomplished = this.OnboardingService.getOnboardingOverviewTaskAccomplished();
        // Update the number in the onboarding icon badge every time when it changes
        this.OnboardingService.on('onboardingOverviewTaskAccomplished', function hanldeOnboardingOverviewChagned(tasksHash) {
            this.onboardingOverviewTaskAccomplished = tasksHash;
        }.bind(this));
        
        this.showActiveWorkspaces = true;

        // deprecated since 2021
        this.stripeMigrationWarning = [
            {
                startDate: '10/30/2021',
                endDate: '12/14/2021',
                text: 'PIPELINE.STRIPE_BANNER._FIRST_NOTICE_',
                class: 'nx-banner--info'
            },
            {
                startDate: '12/14/2021',
                endDate: '03/01/2022',
                text: 'PIPELINE.STRIPE_BANNER._LAST_CALL_',
                class: 'nx-banner--alert'
            }

        ];

        this.today = this.moment();

        var _init = function _init() {
            // depends on user.gmail_integration_revoked_at, which is deprecated since June 2021
            UserService.showGmailRevokedPopup(this.currUser);

            this.InitialAppLoadParamsService.cleanParamsFromView('discountCode', 'promo'); // copied to react
            this.OnboardingService.triggerTrialIfNeeded($stateParams.shouldStartTrial); // copied to react

            this.isCompanyModerator = this.currUser.hasCompanyModeratorPermissions();
            this.otherTeamMembers = this.company.getOtherCompanyMembers();

            this.stripeMigrationObject = this.getStripeWarningObject();
        }.bind(this);

        StatsigService.isGateEnabled('ng2react_pipeline', false)
            .then(function(res) {
                this.pipelineVersion = (res && this.currUser.isViewAllEnabled()) ? 'react' : 'angular';
                if (this.pipelineVersion === 'angular') {
                    // open settings on load - used on new setup guide
                    // customize used in email link
                    // deprecated - was being called in setup_guide_service but it's not there anymore
                    if ($stateParams.openSettingsOnLoad || $stateParams.customize) {
                        this.openPipelineSettings();
                    }

                    // showFeatureOnboarding is sent from the top nav
                    if ($stateParams.showFeatureOnboarding || $stateParams.onboarding) {
                        this.showPipelineOnboarding();
                    }

                    if (this.company.wasFullyFetched()) {
                        _init();
                    } else {
                        this.registerOnce(this.company, 'success', _init);
                    }
                }
            }.bind(this));
    }

    Controllers.PipelineController = Class(Controllers.BaseController, {
        constructor: PipelineControllerCtor,

        showOnboardingCreateEventPopover: function showOnboardingCreateEventPopover() {
            return this.isOnboardingActive && this.OnboardingService.isStep(this.onboardingCreateEventTooltipStep) && !this.DeviceService.checkIfMobileSize() && this.currUser.isInTrial();
        },

        viewAllAvailable: function () {
            return this.currUser.isViewAllEnabled();
        },

        reloadAndRefreshCurrentPage: function reloadAndRefreshCurrentPage() {
            // Adds {showFeatureOnboarding: false} because we do not want to show the
            // pipeline tour when closing the pipelineSettings modal
            this.$state.go(this.AppStates.root_core_navigation_pipeline, {showFeatureOnboarding: false}, {reload: true});
        },

        selectTeamMember: function selectTeamMember(user) {
            this.selectedUser = (user && user._id === this.currUser._id) ? this.currUser : user;
        },

        openPipelineSettings: function openPipelineSettings() {
            if (this.openningPipelineSettings) {
                //prevent double clicking the settings
                return;
            }
            this.openningPipelineSettings = true;
            this.toggleCustomizePopover(false);

            if (this.company.wasFullyFetched()) {
                this.openSettingDialogInternal();
            } else {
                this.CompaniesManager.getCurrCompany(true);
                this.registerOnce(this.company, 'success', this.openSettingDialogInternal.bind(this));
            }
        },

        openSettingDialogInternal: function openSettingDialogInternal() {
            this.ModalService.openPipelineSettingsModal(this.currUser.company).then(
                function success(result) {
                    if (result !== 'dismissed') {
                        this.reloadAndRefreshCurrentPage();
                    }
                }.bind(this),
                function fail(error) {
                    if (error) {
                        return this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PIPELINE.SETTINGS._ERROR_UPDATING_NEW_PIPELINE_STAGES_', function okHanlder() {
                            this.reloadAndRefreshCurrentPage();
                        }.bind(this));
                    }

                    // otherwise this could be just a dismiss of the dialog with the escape key. do nothing and let it close
                }.bind(this))
                .finally(function () {
                    this.openningPipelineSettings = false;
                }.bind(this));
        },

        hasOnboardingMenuBeenClosed: function hasOnboardingMenuBeenClosed() {
            return this.OnboardingService.getOnboardingMenuClosed();
        },

        showPipelineOnboarding: function showPipelineOnboarding() {
            this.pipelineOnboardingIsOpen = true;
            
            this.ModalService.openPipelineOnboardingModal()
                // Clicking 'Close', 'Done', or 'Customize'
                .then(this.onPipeLineOnboardingClose.bind(this))
                // Keypress 'Esc' or clicking outside modal
                .catch(this.onPipeLineOnboardingClose.bind(this));
        },

        onPipeLineOnboardingClose: function onPipeLineOnboardingClose(data) {
            if (data && data.didClickCustomize) {
                this.openPipelineSettings();
            } else {
                if (this.currUser.hasCompanySuperAdminPermissions() && !this.DeviceService.checkIfMobileSize()) {
                    var companyType = this.currUser.company.company_type;
                    var companyTypePlural = (this.Enums.ExtendedCreativeTypesPlural[companyType] && companyType !== 'other') ? this.Enums.ExtendedCreativeTypesPlural[companyType] : 'members';
                    this.customizePopoverMessage = this.$translate.instant('PIPELINE.TOOLTIPS._CUSTOMIZE_', {
                        industryPlural: companyTypePlural.toLowerCase()
                    });
                    this.toggleCustomizePopover(true);
                }
            }
            this.pipelineOnboardingIsOpen = false;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.pipelineOnboarding, {seen: true});
        },

        toggleCustomizePopover: function toggleCustomizePopover(show) {
            this.showCustomizePopover = show;
        },

        isPendingStripeFutureRequirements: function isPendingStripeFutureRequirements() {
            return this.currUser.isCompanyOwner() && this.company.payment_gateway_future_requirements_action && this.company.payment_gateway_future_requirements_action !== 'none';
        },

        getStripeWarningObject: function getStripeWarningObject() {

           var stripeWarning = this.stripeMigrationWarning.filter(function(obj) {

               var beginning = this.moment(obj.startDate).format('ddd, MMM DD, YYYY');
               var end = this.moment(obj.endDate).format('ddd, MMM DD, YYYY');

               return this.moment(this.today).isBetween(beginning, end);

           }.bind(this));

           if (stripeWarning && stripeWarning.length === 0) {
                stripeWarning.text = null;
                stripeWarning.class = '';
           } else {
               stripeWarning = stripeWarning[0];
           }

           return stripeWarning;
        }

    });
}());
