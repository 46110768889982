(function () {
    'use strict';

    Directives.ClientRequestPvlFeedHeaderDirective = function ClientRequestPvlFeedHeaderDirective() {
        // @ngInject
        function ClientRequestPvlFeedHeaderDirectiveControllerCtor($scope, $injector, ModalService, WorkspacesManager) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'ClientRequestPvlFeedHeaderDirectiveController';

            this.ModalService = ModalService;
            this.WorkspacesManager = WorkspacesManager;
        }

        var ClientRequestPvlFeedHeaderDirectiveController = Class(Controllers.BaseController, {
            constructor: ClientRequestPvlFeedHeaderDirectiveControllerCtor,

            request: function request() {
                this.ModalService.openPvlRecommendationsModal(this.workspace);
            },

            dismiss: function dismiss() {
                this.WorkspacesManager.dismissFeedPvlRequest(this.workspace);
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace/feed/client_request_pvl_from_feed_directive_template.html',
            controller: ClientRequestPvlFeedHeaderDirectiveController,
            controllerAs: 'clientPvlFeedRequestVm',
            bindToController: true,
            scope: {
                workspace: '=workspace'
            }
        };
    };

}());

