(function () {
    "use strict";

    // @ngInject
    function BrandComponentControllerFunc($injector, $scope) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrandComponentController';

        this.layouts = {
            horizontal: 'horizontal'
        };
    }

    var BrandComponentController = Class(Controllers.BaseController, {
        constructor: BrandComponentControllerFunc,

    });

    Components.Brand = {
        bindings: {
            company: '<',
            layout: '@?'
        },
        controller: BrandComponentController,
        controllerAs: 'brandVm',
        name: 'hbBrand',
        templateUrl: 'angular/app/modules/core/features/brands/brand_component_template.html',
    };

}());