(function () {
    "use strict";

    // @ngInject
    function ExpandImageControllerCtor($scope, $injector, $state, $modalInstance,
                                          AnalyticsService, serviceModel, isEditMode) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ExpandImageController';

        this.$state = $state;
        this.modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.serviceModel = serviceModel;
        this.round = Math.round;
        //this.workspaceFile = workspaceFile;

        this.message = "";
        this.isEditMode = isEditMode;

        AnalyticsService.trackPageView(this, 'expand image');
    }

    Controllers.ExpandImageController = Class(Controllers.BaseController, {

        constructor: ExpandImageControllerCtor,

        editImage: function ExpandImage() {
            var self = this;

            //this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.send_brochure_collection_selection);
            this.modalInstance.close({edit:true});
        },
        deleteImage: function deleteImage(){
            this.modalInstance.close({delete:true});
        },

        cancelExpandImage: function cancelExpandImage(){
            //this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.send_brochure_collection_selection);
            this.modalInstance.dismiss();
        }
    });
}());

