/**
 * Created by iggy on 5/1/17.
 */

Components.NotificationBanner = {
    transclude: {
        'bannerCopy': '?bannerCopy'
    },
    name: 'hbNotificationBanner',
    templateUrl: 'angular/app/modules/common/ui_components/notification_banner/notification_banner.html'
};

