(function () {
    'use strict';

// @ngInject
    Models.SubscriptionModel = function SubscriptionModel(BaseModel) {

        return Class(BaseModel, {

            constructor: function SubscriptionModel() {
                this.constructor.$super.call(this);
                this.__className = 'SubscriptionModel';
            },

            mapChilds: function mapChilds() {
                return {};
            },

            afterMixingFrom: function afterMixingFrom(object) {
            },

            isCachable: function isCachable() {
                return true;
            },

            isExpired: function isExpired() {
                return this.state === 'expired';
            }
        });
    };
}());

