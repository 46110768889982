// Menu Component
//
// This component is designed to be simipler, more opinionated, and
// more reusable than the existing hbDropdown directive.
// The existing dropdown directive requires a fixed DOM ID in order to hook
// up event binding. This menu can be placed in any context and you won't have
// to worry about scope conflicts. Just pass it a click callback and a list
// of text items or objects with a specified display key.
//
// Consider building out the full feature set described here:
//   - https://material.google.com/components/menus.html#
//   - http://www.material-ui.com/#/components/dropdown-menu (React implementation)

/******************************************************************************
 *
 * hb-menu
 *
 * itemDisplayKey - is the value to present inside the drop down menu
 * valueDisplayKey - is the value to present after a selection been made on the drop down label
 *
 */



 Components.Menu = {
    bindings: {
        value: '<',
        items: '<',
        itemSecondaryText: '@',
        itemDisplayKey: '@',
        valueDisplayKey: '@',
        valueUniqueKey: '@',
        shouldTranslate: '=',
        showIcons: '<',
        placeholder: '@?',
        isOpen: '=?',
        translatePlaceholder: '=?',
        disabled: '=?',
        selectedValueLabel: "@",
        onItemHover: '&?',
        onItemSelect: '&',
        onOpen: '&?',
        beforeItemSelect: '&?',
        beforeItemSelectPromise: '&?', //can be used to show a confirm popup before making a selection
        position: '@', // top, bottom
        itemTemplateUrl: '@?',
        valueTemplateUrl: '@?',
        showActionItem: '=',
        disabledItemText: '@?',
        selectedIcon: '<',
        addNewListItem:'&?',
        listType: '@?',
        itemDisplayStyleKeyValuePair: '<?',
        id: '@',
        hasStaticTrigger: '<',
        isMultiSelect: '=?' ,
        multiSelectChoices: '<?',  // array of strings of the selected items names binding to the parent component
        resetValue: '<?'

    },
    transclude: {
        actionItem: '?actionItem',
        staticTrigger: '?staticTrigger'
    },
    // @ngInject
    controller: function MenuComponentController(
            $scope, $element, $attrs, $document, $filter, $templateCache, $timeout, $translate, _, PopupMessageService, DeviceService, UIUtils) {

        // Subscribe to external open event
        $scope.$on("OPEN_HB_MENU_EXTERNALLY", (_e, id) => {
            if (id === this.id) {
                this.open();
            }
        })


        this.$onInit = function $onInit() {           
            if (this.items.length === 0) {
                throw Error('Empty menu items');
            }

            if (typeof this.items[0] === 'object' && !this.itemDisplayKey) {
                throw Error('Lists of objects require a display key');
            }

            if (typeof this.items[0] !== 'object' && this.itemDisplayKey) {
                throw Error('Expects a list of objects with displayKey: ' +
                    this.itemDisplayKey);
            }

            if (!this.position) {
                this.position = 'bottom';
            }

            if (!this.showIcons) {
                this.showIcons = false;
            }

            if (typeof this.addNewListItem === 'function') {
                this.allowAddNewListItem = true;
                this.newListItemPlaceholder = $translate.instant('HB_MENU._NEW_TYPE_', {type: this.listType}) + '...';
                this.errorMsgNewListItem = $translate.instant('HB_MENU._ERROR_', {type: this.listType});
                this.watchNewListItemValue();
            }
            if (this.itemTemplateUrl) {
                if ($templateCache.get(this.itemTemplateUrl)){
                    this.hasItemTemplate = true;
                } else {
                    throw Error('Expects a templateUrl to be exist on the $templateCache service: ' + this.itemTemplateUrl);
                }
            } else {
                this.hasItemTemplate = false;
            }

            if (this.valueTemplateUrl) {
                if ($templateCache.get(this.valueTemplateUrl)){
                    this.hasValueTemplate = true;
                } else {
                    throw Error('Expects a templateUrl to be exist on the $templateCache service: ' + this.valueTemplateUrl);
                }
            } else {
                this.hasValueTemplate = false;
            }

            this.groupedItems = _.groupBy(this.items, function(item){ return item.categoryName });

            this._selectedItem = this.value;
            this.UIUtils = UIUtils;
            this.isOpen = false;
            this.isMobile = DeviceService.nxSmallBreakpoint();
        };

        this.$onChanges = function $onChanges() {
            this.filterData();
        };

        this.filterData = function filterData() {
            this._setDisplayValue(this.value);
            this._setItems();

            if (this.placeholder) {
                if (this.translatePlaceholder) {
                    this.placeholderValue = $filter('translate')(this.placeholder);
                } else {
                    this.placeholderValue = this.placeholder;
                }
            }
        };

        this.$postLink = function $postLink() {
            $element.addClass('hb-menu');

            if(this.disabled !== undefined){
                $scope.$watch('$ctrl.disabled', function (newValue, oldValue) {
                    if(newValue){
                        $element.addClass('hb-menu--disabled');
                    } else {
                        $element.removeClass('hb-menu--disabled');
                    }
                }.bind(this));
            }


            $element.on('click', function toggleMenu(e) {
                const optsClassNames = ['hb-menu__list__item__text','hb-menu__list__item__text__div','ng-scope ng-binding','hb-menu__list__item ng-scope active','hb-menu__list__item ng-scope'];
                if(this.disabled){
                    return;
                }

                if (e.target.classList.contains('js--prevent-default')) {
                    return;
                }

                if (this.isOpen) {
                    if(this.isMultiSelect && optsClassNames.includes(e.target.className)){
                        //did it to avoid closing menu while multi select is on
                        return
                    }                    
                    this.close();
                } else {
                    this.open();
                }
            }.bind(this));

            // Close the menu if the user clicks outside.
            this.onOutsideBoundsClick = function onOutsideBoundsClick(e) {
                var isClickedInside = $element.find(e.target).length > 0;

                if (isClickedInside) {
                    return;
                }

                this.close();
            };

            $document.bind('click', this.onOutsideBoundsClick.bind(this));
        };

        // Make sure to cleanup to prevent memory leaks
        this.$onDestroy = function $onDestroy() {
            $document.off('click', this.onOutsideBoundsClick);
        };

        this.selectItem = function selectItem(item) {
            var changedApprove = (this.beforeItemSelect || angular.identity)({item: item});

            if (this.beforeItemSelectPromise) {
                this.beforeItemSelectPromise({item: item}).then(() => {
                    this._selectItemInner(item);
                });
            } else if (changedApprove) {
                this._selectItemInner(item);
            }
        };

        this._selectItemInner = function _selectItemInner(item) {
            if(!this.isMultiSelect) {
                this._selectedItem = item;
                this._setDisplayValue(item);
            }
            this.onItemSelect({item: item});
        };

        this.hoverIn = function hoverIn(item) {
            if (this.onItemHover) {
                this.onItemHover({item: item, hoverIn: true});
                if (this.hoverOutTimeout) {
                    $timeout.cancel(this.hoverOutTimeout);
                }
            }
        };

        this.hoverOut = function hoverOut(item) {
            if (this.onItemHover) {
                this.hoverOutTimeout = $timeout(() => {
                    this.onItemHover({item: item, hoverIn: false});
                    this.hoverOutTimeout = null;
                }, 50);
            }
        };

        this._setDisplayValue = function _setDisplayValue(item) {
            if (!item) {
                this.displayValue = '';
                this.displayValueIcon = '';
            } else if (!this.shouldTranslate && this.valueDisplayKey) {
                this.displayValue = item[this.valueDisplayKey];
                this.displayValueIcon = item.icon;
            } else if (!this.shouldTranslate && this.itemDisplayKey && item[this.itemDisplayKey]) {
                this.displayValue = item[this.itemDisplayKey];
                this.displayValueIcon = item.icon;
            } else if (!this.shouldTranslate && !this.itemDisplayKey && !this.valueDisplayKey) {
                this.displayValue = item;
            } else if (this.shouldTranslate && this.valueDisplayKey) {
                this.displayValue = $filter('translate')(item[this.valueDisplayKey]);
                this.displayValueIcon = item.icon;
            } else if (this.shouldTranslate && this.itemDisplayKey) {
                this.displayValue = $filter('translate')(item[this.itemDisplayKey]);
                this.displayValueIcon = item.icon;
            } else if (this.shouldTranslate && !this.itemDisplayKey) {
                this.displayValue = $filter('translate')(this.value);
                this.displayValueIcon = item.icon;
            } else {
                this.displayValue = '';
                this.displayValueIcon = '';
            }
        };

        this._setItems = function _setValues() {
            if (this.shouldTranslate && this.itemDisplayKey) {
                this.items.map((item) => {
                    item[this.itemDisplayKey] = $filter('translate')(item[this.itemDisplayKey]);
                });
            } else if (this.shouldTranslate && !this.itemDisplayKey) {
                this.items.map((item) => {
                    item = $filter('translate')(item);
                });
            }

            this.groupedItems = _.groupBy(this.items, function(item){ return item.categoryName });
        };

        this.resetCreateListItemState = function resetCreateListItemState() {
            this.showAddListItem = false;
            this.showErrorCreateListItem = false;
            this.newListItemValue = "";
        };

        this.open = function open() {
            this.isOpen = true;
            $element.addClass('hb-menu--open');
            if (this.onOpen && angular.isFunction(this.onOpen)) {
                this.onOpen();
            };
        };

        this.close = function close() {
            this.isOpen = false;
            $element.removeClass('hb-menu--open');

            if (this.allowAddNewListItem) {
                this.resetCreateListItemState();
            }
        };

        this.handleAddListItem = function handleAddListItem($event) {
            $event.stopPropagation();
            this.showAddListItem = true;
            $timeout(()=> {
                $element.find('.js--hb-menu__input').focus();
            })
        };

        this.sanatizeNewListItem = function sanatizeNewListItem(item) {
            const listItem = item.split(' ');
            return listItem.map(val => {
                return val.charAt(0).toUpperCase() + val.slice(1);
            }).join(' ');
        };

        this.isDuplicateEntry = function isDuplicateEntry(collection, newItem){
            return collection.some(item => item.label === newItem);
        };

        this.newListItemFocused = function newListItemFocused() {
            this.showErrorCreateListItem = false;
        };

        this.watchNewListItemValue = function watchNewListItemValue() {
            $scope.$watch('$ctrl.newListItemValue', newValue => {
                this.isNewListItemInValid = newValue;
            });
        };

        this.addCreateListItem = function addCreateListItem($event) {
            $event.stopPropagation();
            $event.preventDefault();

            if (!this.newListItemValue) {
                return;
            }

            let itemValue = this.sanatizeNewListItem(this.newListItemValue);

            if (this.isDuplicateEntry(this.items, itemValue)) {
                this.showErrorCreateListItem = true;
                this.isNewListItemInValid = true;
                return;
            }

            this.savingNewListItem = true;

            this.addNewListItem({item: itemValue})
              .then(item => {
                  this.selectItem(item[0]);
              })
              .catch((err) => {
                  PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, err.data.error_message);
                  console.log(err);
              })
              .finally(() => {
                  this.close();
                  this.resetCreateListItemState();
                  this.savingNewListItem = false;
              });

        };

        this.setAsActive = (item) => {
           const itemForMultiSelect = typeof item === 'object' ? item[this.itemDisplayKey] : item
            return (this.valueUniqueKey ? (item[this.valueUniqueKey] === this._selectedItem[this.valueUniqueKey]) :
                ($filter('translate')(item[this.itemDisplayKey]) === this.displayValue || $filter('translate')(item[this.valueDisplayKey]) === this.displayValue))
                 || (this.multiSelectChoices &&  this.multiSelectChoices.includes(itemForMultiSelect));
        }

        this.getCurrItemStyle = (item) => {
            return this.itemDisplayStyleKeyValuePair && { [this.itemDisplayStyleKeyValuePair.key] : item[this.itemDisplayStyleKeyValuePair.value] };
        }

        this.getItemsPreviewStyle = () => {
            return {
            'overflow': 'hidden',
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            'padding-right': '5px',
            'max-width': '280px'
           }
        }

        this.displayText = (item) => {
            let _displayText = item;

            if (item[this.itemDisplayKey] !== undefined) {
                _displayText = (item[this.itemDisplayKey] !== null ? item[this.itemDisplayKey] : '');
            }

            return this.UIUtils.sanitize(_displayText);
        }

        this.displaySecondaryText = (item) => {
            if(!this.itemSecondaryText) {
                return
            }

            let _displaySecondaryText = item;

            if (item[this.itemSecondaryText] !== undefined) {
                _displaySecondaryText = (item[this.itemSecondaryText] !== null ? item[this.itemSecondaryText] : '');
            }

            return this.UIUtils.sanitize(_displaySecondaryText);
        }

        this.itemsTrackingHash = (items) => {
            return items.map(item => this._hashItem(item)).join('');
        }

        this._hashItem = (item) => {
            if (angular.isObject(item)) {
                return Object.values(item).join('');
            }

            return item;
        }
    },
    name: 'hbMenu',
    templateUrl: 'angular/app/modules/common/ui_components/menu/menu.html',
};
