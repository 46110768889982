/**
 * Created by inonstelman on 1/5/15.
 */
(function () {
    "use strict";

    Services.AvatarService = Class(function(){


        return {

            setShowHideAvatarPopupHandler: function setShowHideAvatarPopupHandler(handler){

                this.showHideAvatarPopupHandler = handler;
            },

            showAvatarPopup: function showAvatarPopup(data){

                if(this.showHideAvatarPopupHandler){
                    this.showHideAvatarPopupHandler(true, data);
                }
            },

            hideAvatarPopup: function hideAvatarPopup(){

                if(this.showHideAvatarPopupHandler){
                    this.showHideAvatarPopupHandler(false);
                }
            },

            isDefaultProfileImage: function isDefaultProfileImage(user) {
                // image is the same as default empty or the template users
                var isTimeStampedBowtieProfileImage =
                    user.profile_image && user.profile_image.url && user.profile_image.url.includes('/honeybook_cdn/assets_users_production/users/');
                    // && user.profile_image.url.includes('profile_photos/2014-07-30-11-00-29-261.png');

                return (user.profile_image == null
                    || user.profile_image.url === 'https://honeybook_cdn.s3.amazonaws.com/assets_users_production/users/empty_default_image.png'
                    || user.profile_image.original_url === 'https://s3.amazonaws.com/honeybook_cdn/assets_users_demo/users/sample_user/profilepic180x180.png'
                    || isTimeStampedBowtieProfileImage);
            },
        };
    });
}());
