(function () {
    'use strict';

    // @ngInject
    function NewAutomationsControllerCtor(
        $scope,
        $injector,
        $state,
        Enums,
        AppStates,
        NewAutomationsService
    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NewAutomationsController';
        this.AppStates = AppStates;
        this.$state = $state;
        this.Enums = Enums;
        this.NewAutomationsService = NewAutomationsService;

        this.NewAutomationsService.isNewAutomationsEnabled().then(function (isEnabled) {
            if (!isEnabled) {
                this.$state.go(AppStates.AppStates.root_core_navigation_automations);
            }
        }.bind(this));
    }

    Controllers.NewAutomationsController = Class(Controllers.BaseController, {
        constructor: NewAutomationsControllerCtor,
    });
}());
