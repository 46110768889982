
// @ngInject

Models.LeadsCollection = function LeadsCollection(BaseCollection) {

    var LeadsCollection = Class(BaseCollection, {

            constructor: function constructor(){
                LeadsCollection.$super.call(this);
                this._collection.__className = 'LeadsCollection';
                this._collection.__childsType = 'LeadModel';
                return this._collection;
            }
        }
    );

    return LeadsCollection;
};