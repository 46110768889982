/**
 * Created by dvircohen on 18/06/2017.
 */
// @ngInject

Models.PayableFileTemplateModel = function PayableFileTemplateModel(BaseModel) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'PayableFileTemplateModel';
            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                vendor_proposal: 'ProposalModel',
                payments_container: 'PaymentsContainerModel',
                agreement: 'AgreementModel'
            };
        },

        payableType: function payableType() {
            return this.type;
        },

        afterMixingFrom: function afterMixingFrom() {
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        shouldShowTax: function () {
            return this.__parent.shouldShowTax(this);
        },

        shouldShowSVC: function shouldShowSVC() {
            return this.__parent.shouldShowSVC(this);
        },

        getCompany: function getCompany() {
            return this.__parent;
        },

        // =====================================
        // Image
        // =====================================

        updateCoverImage: function updateCoverImage(image) {
            return this.__parent.updatePayableCoverImage(this, image);
        },

        // =====================================
        // Proposal
        // =====================================

        updateProposal: function updateProposal(proposalData) {
            return this.__parent.updatePayableProposal(this, proposalData);
        },

        toggleTaxToAllPackages: function toggleTaxToAllPackages(addTax) {
            return this.__parent.togglePayableAllPackagesTax(this, addTax);
        },

        toggleSvcToAllPackages: function toggleSvcToAllPackages(addSvc) {
            return this.__parent.togglePayableAllPackagesSvc(this, addSvc);
        },

        calculateProposalTax: function calculateProposalTax(proposal, type, tax, taxOrSvc) {
            return this.__parent.calculateProposalTax(this, type, tax, taxOrSvc);
        },

        // =====================================
        // Packages
        // =====================================

        addPackage: function addPackage(proposal, packageIdToAdd) {
            return this.__parent.addPayablePackage(this, packageIdToAdd);
        },

        updatePackage: function updatePackage(proposal, packageModel) {
            return this.__parent.updatePayablePackage(this, packageModel);
        },

        deletePackage: function(proposal, packageIdToDelete) {
            return this.__parent.deletePayablePackage(this, packageIdToDelete);
        },

        // =====================================
        // Services
        // =====================================

        addCustomItem: function addCustomItem(proposal, serviceModel) {
            return this.__parent.addPayableService(this, serviceModel);
        },

        addAdditionalServiceToPackage: function addService(proposal, packageModel, serviceModel) {
            return this.__parent.addPayablePackageService(this, packageModel, serviceModel);
        },

        updateService: function(proposal, serviceModel, packageModel) {
            return this.__parent.updatePayablePackageService(this, packageModel, serviceModel);
        },

        deleteService: function deleteService(proposal, serviceModel, packageModel) {
            return this.__parent.deletePayablePackageService(this, packageModel, serviceModel);
        },

        updateServiceImage: function updateServiceImage(proposal, serviceModel, image, packageModel) {
            return this.__parent.updatePayablePackageServiceImage(this, serviceModel, image, packageModel);
        },

        deleteServiceImage: function deleteServiceImage(proposal, serviceModel, packageModel) {
            return this.__parent.deletePayablePackageServiceImage(this, packageModel, serviceModel);
        },

        updateServicesOrder: function updateServicesOrder(proposal, packageModel) {
            return this.__parent.updatePayableServicesOrder(this, packageModel);
        },

        updateVendorPackagesOrder: function updateVendorPackagesOrder(proposal,packagesOrder) {
            return this.__parent.updateVendorPackagesOrder(this, packagesOrder);
        },

        moveService: function moveService(proposal, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return this.__parent.moveService(this, serviceId, sourcePackageId, destPackageId, servicesCollection);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(proposal, order) {
            return this.__parent.updatePackagesAndServicesOrder(this, order);
        },

        // =====================================
        // Payments
        // =====================================

        getTotalProposalValue: function getTotalProposalValue() {
            return this.vendor_proposal.total_price;
        },

        addPayment: function addPayment() {
            return this.__parent.addPayablePayment(this);
        },

        updatePayment: function updatePayment(payment) {
            return this.__parent.updatePayablePayment(this, payment);
        },

        deletePayment: function deletePayment(payment) {
            return this.__parent.deletePayablePayment(this, payment);
        },

        savePaymentsContainerAsTemplate: function savePaymentsContainerAsTemplate(templateTitle) {
            return this.__parent.savePaymentsContainerAsTemplate(this, templateTitle);
        },

        updatePaymentsByTemplate: function updatePaymentsByTemplate(templateId) {
            return this.__parent.updatePaymentsByTemplate(this, templateId);
        },

        isRecurringPayment: function isRecurringPayment() {
            return this.payment_type === 'recurring';
        },

        togglePaymentScheduleType: function togglePaymentScheduleType(isRecurring) {
            return this.__parent.togglePayablePaymentScheduleType(this, isRecurring);
        },

        updateRecurringPayment: function updateRecurringPayment(startDate, endDate) {
            return this.__parent.updatePayableRecurringPayment(this, startDate, endDate);
        },

        // =====================================
        // Agreement
        // =====================================

        updateAgreement: function updateAgreement() {
            return this.__parent.updatePayableAgreement(this);
        },

        updateAgreementByTemplate: function updateAgreementByTemplate(templateId) {
            return this.__parent.updatePayableAgreementByTemplate(this, templateId);
        },

        saveAgreementAsTemplate: function saveAgreementAsTemplate(templateTitle) {
            return this.__parent.savePayableAgreementAsTemplate(this, templateTitle);
        }
    });
};

