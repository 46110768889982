/**
 * Created by dikla oren on 15/9/16.
 */
(function () {
    'use strict';

    // @ngInject
    function NetworkOnboardingService(AppStates, $state,
                                     UsersManager, moment) {

        this.UsersManager = UsersManager;
        this.AppStates = AppStates;
        this.moment = moment;
        this.$state = $state;

        UsersManager.on('loggingIn', function userLoggedIn() {
            this.currUser = this.UsersManager.getCurrUser();
        }.bind(this));

        UsersManager.on('resetPsw', function userResetPsw() {
            this.currUser = this.UsersManager.getCurrUser();
        }.bind(this));

        UsersManager.on('loggingOut', this.onLogoutCleanup.bind(this));
    }

    Services.NetworkOnboardingService = Class({
        constructor: NetworkOnboardingService,

        // NETWORK_ONBOARDING_STAGE = [:new, :reset_password, :welcome_intro, :user_profile, :completed, :unlock_hb]
        OnboardingSteps: {
            new: 'new',
            resetPassword: 'reset_password',
            welcomeIntro: 'welcome_intro',
            userProfile: 'user_profile',
            completed: 'completed',
            unlockHb: 'unlock_hb',
        },

        onLogoutCleanup: function onLogoutCleanup() {
            this.currUser = null;
        },

        isOnboardingDone: function isOnboardingDone(user) {
            return (user.network_onboarding_container &&
                    user.network_onboarding_container.network_onboarding_step &&
                    user.network_onboarding_container.network_onboarding_step === this.OnboardingSteps.userProfile);
        },

        isHBUnlocked: function isHBUnlocked(user) {
            return (user.network_onboarding_container &&
                angular.isDefined(user.network_onboarding_container.unlock_hb) &&
                user.network_onboarding_container.unlock_hb);
        },

        isOnboardingActive: function isOnboardingActive(user) {
            return (user.network_onboarding_container &&
                    user.network_onboarding_container.network_onboarding_step &&
                    user.network_onboarding_container.network_onboarding_step === this.OnboardingSteps.userProfile &&
                    user.network_onboarding_container.onboarding_active);
        },

        isOnboardingOnUserProfile: function isOnboardingOnUserProfile(user) {
            return (user.network_onboarding_container &&
                user.network_onboarding_container.network_onboarding_step &&
                user.network_onboarding_container.network_onboarding_step === this.OnboardingSteps.welcomeIntro);
        },

        isFreshUser: function isFreshUser(user){
            if (angular.isDefined(user.network_onboarding_container) &&
                user.network_onboarding_container !== null &&
                angular.isDefined(user.network_onboarding_container.last_stage_update) &&
                user.network_onboarding_container.last_stage_update !== null &&
                !this.moment().tz('America/Los_Angeles').isSame(user.network_onboarding_container.last_stage_update,'day')){
                return false;
            }else{
                return true;
            }

        }

    });
}());