Directives.CompanyPackagesDirective = function CompanyPackagesDirective() {

    // @ngInject
    function CompanyPackagesDirectiveControllerFunc($scope, $injector, CompaniesManager, AnalyticsService, PopupMessageService, hotkeys) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanyPackagesDirectiveController';

        this.AnalyticsService = AnalyticsService;
        var that = this;

        this.savingStateText = '';

        this.addPackage = function addPackage(packageToAdd) {

            that.working = true;
            that.proposalModel.addPackage(packageToAdd._id).then(
                function success() {
                    var workspaceFile = that.proposalModel.__parent;
                    var analyticsArgs = {
                        package_id: packageToAdd._id,
                        package_title: packageToAdd.title
                    };

                    AnalyticsService.trackSuccess(that, AnalyticsService.analytics_events.package_added_to_file, analyticsArgs);
                },
                function error(resp) {
                    var workspaceFile = that.proposalModel.__parent;
                    var analyticsArgs = {
                        package_id: packageToAdd._id,
                        package_title: packageToAdd.title
                    };

                    AnalyticsService.track(that, AnalyticsService.analytics_events.package_added_to_file, resp, analyticsArgs);
                }
            ).finally(function done() {
                    that.working = false;
                });
            that.showList = !that.showList;
        };

        this.addCustomItem = function addCustomItem() {

            that.proposalModel.addCustomItem().then(
                function success() {
                    var workspaceFile = that.proposalModel.__parent;

                    AnalyticsService.track(that, AnalyticsService.analytics_events.service_added_to_proposal);
                },
                function error() {
                    var workspaceFile = that.proposalModel.__parent;

                    AnalyticsService.track(that, AnalyticsService.analytics_events.service_added_to_proposal_error);
                }
            );
            that.showList = !that.showList;
        };

        this.deletePackage = function deletePackage(packageId) {

            var that = this;

            var analyticsArgs = {
                template_id: that.packageToDelete,
                template_type: 'package',
                source: 'file'
            };

            that.deleteConfirmed = function deleteConfirmed() {
                CompaniesManager.deletePackageTemplate(that.company, that.packageToDelete).then(
                    function success() {
                        that.showList = false;

                        AnalyticsService.track(that, AnalyticsService.analytics_events.delete_template_permanently_confirmed, analyticsArgs);
                    },
                    function failed(resp) {
                        that.showList = false;

                        var error = 'FILE.TEMPLATES._ERROR_OCCUR_MESSAGE_';

                        if (resp.data.error_type === 'CannotDeleteLastPackage') {
                            error = 'FILE.TEMPLATES.PACKAGES._ERROR_DELETE_LAST_MESSAGE_';
                        }

                        PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                            error);

                        analyticsArgs.error = error;

                        AnalyticsService.track(that, AnalyticsService.analytics_events.delete_template_permanently_failed, analyticsArgs);
                    }
                );
            };


            that.packageToDelete = packageId;
            PopupMessageService.showConfirm(PopupMessageService.severityTypes.warning,
                'FILE.TEMPLATES.PACKAGES._DELETE_CONFIRM_MESSAGE_',
                that.deleteConfirmed.bind(that));
        };

        hotkeys.bindTo($scope).add({
            order: 2,
            combo: 'shift+p',
            description: 'Add Package',
            callback: function addPackageHotkeyCallback() {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.hotkey_used, {combo: 'shift+p', action: 'add package'});
                this.showList = !this.showList;
            }.bind(this)
        });




    }

    var CompanyPackagesDirectiveController = Class(Controllers.BaseController, {
        constructor: CompanyPackagesDirectiveControllerFunc
    });

    return {
        scope: {
            proposalModel: '=proposalModel',
            proposalEmpty: '=proposalEmpty',
            company: '=company'
        },
        templateUrl: 'angular/app/modules/core/features/proposal/company_packages/company_packages_directive_template.html',
        controller: CompanyPackagesDirectiveController,
        controllerAs: 'companyPackagesVm',
        bindToController: true
    }
};