//cases - many are covered by the drawnSignature check in signature directive.
//1. User has a default signature already - typed.
//2. User has a default signature already - drawn.
//3. User typed a signature and saved as default.
//4. User DREW a signature and saved as default
//5. User had a default typed signature and drew a signature and saved as default.
// 6. User had a default tyepd signature adn drew a new signature (no default saved)
// 7. User had a default tyepd signature adn typed a new signature and saved as default.
// 8. User had a default tyepd signature adn typed a new signature (no default saved)
// 9. User had a default DRAWN signature and drew a signature and saved as default.
//10. User had a default DRAWN signature adn drew a new signature (no default saved)
//11. User had a default DRAWN signature adn typed a new signature and saved as default.
//12. User had a default DRAWN signature adn typed a new signature (no default saved)
//13. User typed a signature
//14. User drew a signature
(function () {
    'use strict';

    // @ngInject
    function SignatureModalControllerCtor($scope, $modalInstance, $timeout, $window, signatureModel, DeviceService, AnalyticsService, defaultSignatureFromUser, MobileAppService, branded, UsersManager, DatadogRUMService) {

        // bridge dependency to vm
        this.$scope = $scope;
        this.$modalInstance = $modalInstance;
        this.$timeout = $timeout;
        this.$window = $window;
        this.DatadogRUMService = DatadogRUMService;

        this.DeviceService = DeviceService;
        this.AnalyticsService = AnalyticsService;
        this.MobileAppService = MobileAppService;

        this.signatureModel = signatureModel;
        this.defaultSignatureFromUser = defaultSignatureFromUser;
        this.signatureTypes = this.signatureModel.signatureTypes;
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();
        this.branded = branded;
        this.currUser = UsersManager.getCurrUser();

        // initialize internal state
        this.signatureText = this.signatureModel.signature_text || this.signatureModel.signee_name;
        this.signatureDraw = this.signatureModel.signature;
        this.isDefaultSignatureExists = !!this.defaultSignatureFromUser;

        this.saveAsDefault = false;
        this.agreeToLegal = true;
        this.signatureType = this.signatureTypes.typed; // also used for active tab
        this.analyticsName = 'signature modal';

        this.canvasSize = {
            height: null,
            width: null
        };

        this.tabs = [
            { text: 'TYPE', type: this.signatureTypes.typed},
            { text: 'DRAW', type: this.signatureTypes.drawn }
        ];

        // draw default signature if exists
        if(this.isDefaultSignatureExists) {

            this.signatureType = this.defaultSignatureFromUser.signature_type_cd;

            // extract default signature value by type
            switch(this.defaultSignatureFromUser.signature_type_cd) {
                case this.signatureTypes.typed:
                    this.signatureText = this.defaultSignatureFromUser.signature_text;
                    break;
                case this.signatureTypes.drawn:
                    this.signatureDraw = this.defaultSignatureFromUser.signature;
                    break;
                default:
                    break;
            }
        }

        // TODO: EXTARCT THIS TO A SERVICE
        this.focusTextSignature = function useTextSignature() {
            this.$timeout(function() {
                angular.element('#js-signature-type').focus();
            });
        };

        AnalyticsService.trackPageView(this, this.analyticsName);


        ///////////////////////////////
        // Listen to mobile app events
        ///////////////////////////////
        if(this.isInAppBrowser) {

            // listen to "Accept"
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._SIGN_COMPLETED_, function () {
                this.$scope.$applyAsync(function () {
                    this.clickAccept();
                }.bind(this));
            }.bind(this));

            // listen to "Cancel"
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._SIGN_CANCEL_, function () {
                this.$scope.$applyAsync(function () {
                    this.cancel();
                }.bind(this));
            }.bind(this));
        }
    }

    Controllers.SignatureModalController = Class(Controllers.BaseController, {
        constructor: SignatureModalControllerCtor,

        clickAccept: function clickAccept() {

            // validate legal agree
            if (!this.agreeToLegal) {
                this.textError = "You must check the confirmation to sign";
                return;
            }

            // TODO: check valid signature type
            if(this.signatureType !== this.signatureTypes.typed && this.signatureType !== this.signatureTypes.drawn) {
                this.textError = "Invalid Signature Type";
                return;
            }

            // validate signature value - TYPED
            if(this.signatureType === this.signatureTypes.typed && $.trim(this.signatureText) === "") {
                this.textError = "You must enter a name to sign.";
                return;
            }

            // validate signature value - DRAW
            if(this.signatureType === this.signatureTypes.drawn &&
                (!this.signatureDraw || this.signatureDraw === "[]")) {
                this.textError = "You must sign in the box to have a valid signature.";
                return;
            }

            // if we got till here it means all validations passed! Whoho!
            this.ok();

        },

        toggleAgreeToLegal: function toggleAgreeToLegal() {
            this.agreeToLegal = !this.agreeToLegal;
        },

        toggleSaveAsDefault: function toggleSaveAsDefault(){
            this.saveAsDefault = !this.saveAsDefault;
        },

        ok: function ok() {
            this.$timeout(function() {
                try {
                    // the time out is done to give a chance for the large signature directive to
                    // update it's data and the signature model that is binded to it.

                    // map internal state to external signatureModel
                    this.signatureModel.signature_type_cd = this.signatureType;

                    if(this.signatureType === this.signatureTypes.typed) {
                        this.signatureModel.signature_text = this.signatureText;
                    } else if(this.signatureType === this.signatureTypes.drawn) {
                        this.signatureModel.signature = this.signatureDraw;
                    }

                    // close modal
                    var closeModalModel = {
                        saveAsDefault: this.saveAsDefault,
                        agreeToLegalText: this.agreeToLegal,
                        signatureCanvasSize: this.canvasSize
                    };

                    if (this.isInAppBrowser) {
                        this.unregister(this.MobileAppService);
                    }

                    this.$modalInstance.close(closeModalModel);


                    // after close
                    this.AnalyticsService.track(this, 'accept', {
                        saveAsDefault: this.saveAsDefault,
                        typedSignature: this.signatureType !== this.signatureTypes.drawn
                    });

                    // TODO: Check how to scroll in mobile
                    if (this.DeviceService.checkIfMobileSize()) {
                        var $element = angular.element("#hb-signatures");
                        $element.scrollParent().scrollTo(null, $element.offset().top, 300);
                    }
                } catch (err) {
                    this.DatadogRUMService.addError(
                        err,
                        {
                            message: 'SignatureModalController | Error on clickAccept' + err.message
                        }
                    );
                }
            }.bind(this), 0);
        },

        cancel: function cancel() {
            if(this.isInAppBrowser) {
                this.unregister(this.MobileAppService);
            }
            this.$modalInstance.dismiss('cancel');
        },

        setActiveTab: function makeActive(tabType) {
            this.signatureType = tabType;
        }
    });
}());
