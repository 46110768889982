(function () {
    'use strict';

    class ContactFormDesignFontsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, FontsService, Enums, _) {

            super($scope, $injector);
            this.__objectType = 'ContactFormDesignFontsController';
            this.FontsService = FontsService;
            this.Enums = Enums;
            this._ = _;

            this.fontTypeDisplayStyleKeyValuePair = {key:'font-family', value: 'font_family'};
            this.fontSizeDisplayStyleKeyValuePair = {key:'font-size', value: 'fontSize'};

            this.fonts = this.FontsService.fonts;
            this.fontSizes = this.FontsService.fontSizes;
            this.isButtonsBold = this.currStyle.button_component_style ? (this.currStyle.button_component_style.font_weight === 'bold') : false;
            this.isQuestionsBold = this.currStyle.form_component_style.font_weight === 'bold';
        }


        $onChanges(changes) {
            this.questionsFontSize = this.getSelectedFontSize(this.fontSizes, this.currStyle.form_component_style.font_size);
            this.buttonsFontSize = this.getSelectedFontSize(this.fontSizes, this.currStyle.button_component_style.font_size)
        }

        getSelectedFontSize(list, fontSize) {
            let currItem = list.filter(listItem => {
                return listItem.font_size === fontSize;
            });

            return currItem.length ? this._.first(currItem) : null;
        }

        // Questions
        onBoldQuestionsClick() {
            const fontWeight = this.isQuestionsBold ? "bold" : "normal";
            const fontWeightOnOff = this.isQuestionsBold ? "on" : "off";
            const style = Object.assign({}, this.currStyle.form_component_style, {font_weight: fontWeight});
            return this.onToggleFontWeight({type: this.Enums.ContactFormBuilderType.questions, newvalue: fontWeightOnOff, item: style});
        }

        onQuestionsFontListShow() {
            if (this.onFontListShow && angular.isFunction(this.onFontListShow)) {
                return this.onFontListShow({type: this.Enums.ContactFormBuilderType.questions})
            } 
        }

        onQuestionsFontSizeListShow() {
            if (this.onFontListShow && angular.isFunction(this.onFontListShow)) {
                return this.onFontSizeListShow({type: this.Enums.ContactFormBuilderType.questions})
            } 
        }
        
        onSetQuestionFontSize(item) {
            if (this.onSetFontSize && angular.isFunction(this.onSetFontSize)) {
                return this.onSetFontSize({type: this.Enums.ContactFormBuilderType.questions, item: item, newvalue: item.text})
            } 
        }

        onSetQuesionActiveFont(item) {
            if (this.onSetActiveFont && angular.isFunction(this.onSetActiveFont)) {
                return this.onSetActiveFont({type: this.Enums.ContactFormBuilderType.questions, item: item, newvalue: item.font_type})
            } 
        }


        // Buttons
        onBoldButtonsClick() {
            const fontWeight = this.isButtonsBold ? "bold" : "normal";
            const fontWeightOnOff = this.isQuestionsBold ? "on" : "off";
            const style = Object.assign({}, this.currStyle.button_component_style, {font_weight: fontWeight});
            return this.onToggleFontWeight({type: this.Enums.ContactFormBuilderType.buttons, newvalue: fontWeightOnOff, item: style});
        }

        onButtonsFontListShow() {
            if (this.onFontListShow && angular.isFunction(this.onFontListShow)) {
                return this.onFontListShow({type: this.Enums.ContactFormBuilderType.buttons})
            } 
        }

        onButtonsFontSizeListShow() {
            if (this.onFontSizeListShow && angular.isFunction(this.onFontSizeListShow)) {
                return this.onFontSizeListShow({type: this.Enums.ContactFormBuilderType.buttons})
            } 
        }

        onSetButtonFontSize(item) {
            if (this.onSetFontSize && angular.isFunction(this.onSetFontSize)) {
                return this.onSetFontSize({type: this.Enums.ContactFormBuilderType.buttons, item: item, newvalue: item.text})
            } 
        }

        onSetButtonActiveFont(item) {
            if (this.onSetActiveFont && angular.isFunction(this.onSetActiveFont)) {
                return this.onSetActiveFont({type: this.Enums.ContactFormBuilderType.buttons, item: item, newvalue: item.font_type})
            } 
        }
    }

    Components.ContactFormDesignFonts = {
        bindings: {
            currStyle : '<',
            isChecked: '<',
            
            onSetActiveFont : '&',
            onSetFontSize: '&',

            onFontListShow:'&',
            onFontSizeListShow:'&',

            onToggleFontWeight: '&',
        },
        controller: ContactFormDesignFontsController,
        templateUrl: 'angular/app/modules/core/features/contact_form/aside_menu_content/contact_form_design_fonts/contact_form_design_fonts.html',
    };

}());