
Directives.TemplatesListItemDirective = function TemplatesListItemDirective() {

    // @ngInject
    function TemplatesListItemDirectiveControllerFunc($scope, $injector, TemplatesViewService, moment, $translate) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplatesListItemDirective';
        this.moment = moment;
        this.$translate = $translate;

        this.TemplatesViewService = TemplatesViewService;
    }

    var TemplatesListItemDirectiveController = Class(Controllers.BaseController, {
        constructor: TemplatesListItemDirectiveControllerFunc,

        isDefaultable: function isDefaultable() {
            return this.TemplatesViewService.isTemplateTypeDefaultable(this.templatesViewModel);
        },

        isCloneable: function isCloneable() {
            return this.TemplatesViewService.isTemplateTypeCloneable(this.templatesViewModel, this.template);
        },

        isDeletable: function isDeletable() {
            return this.TemplatesViewService.isTemplateTypeDeletable(this.templatesViewModel, this.template);
        },

        onDeleteClick: function onDeleteClick($event) {
            $event.stopPropagation();
            if(this.onDelete) {
                this.onDelete({template: this.template, templateViewModel: this.templatesViewModel});
            }
        },

        onDefaultClick: function onDefaultClick($event) {
            $event.stopPropagation();
            if(this.onDelete) {
                this.onDefault({template: this.template, templateViewModel: this.templatesViewModel});
            }
        },

        onCloneClick: function onCloneClick($event) {
            $event.stopPropagation();
            if(this.onClone) {
                this.onClone({template: this.template, templateViewModel: this.templatesViewModel});
            }
        },

        shouldShowTemplateBadge: function shouldShowTemplateBadge() {
            return this.TemplatesViewService.shouldShowTemplateBadge(this.templatesViewModel, this.template);
        },

        generateTemplateBadge: function generateTemplateBadge() {
            return this.TemplatesViewService.generateTemplateBadge(this.templatesViewModel, this.template);
        },

    });

    return {
        scope: {
            template: '=',
            templatesViewModel: '=',
            enableActions: '=',
            onDelete: '&',
            onDefault: '&',
            onClone: '&',
            section: '@'
        },
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/templates_list_item_template.html',
        controller: TemplatesListItemDirectiveController,
        controllerAs: 'templatesListItemVm',
        bindToController: true
    };

};