// @ngInject

Models.AgreementsCollection = function AgreementsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor() {
                this.constructor.$super.call(this);
                this._collection.__className = 'AgreementsCollection';
                this._collection.__childsType = 'AgreementModel';
                return this._collection;
            }
        }
    );

};
