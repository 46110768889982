
(function () {
  'use strict';

  class CalendarControlComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, AnalyticsService) {
      super($scope, $injector);
      this.__objectType = 'CalendarControlComponent';

      this.onChange = (date) => {
        if (isNaN(date)) {
          return;
        }

        AnalyticsService.trackClick(this, AnalyticsService.analytics_events.calendar_date_picked, {date: date, today: Date.now()});

        this.handleChange({move:date});
      };
    }

    updateTaskDueDate() {
      this.handleChange({move:this.dueDate});
      this.calendarViewOpen = false;
    }
  }

  Components.CalendarControl = {
    bindings: {
      title: '=',
      date: '=',
      dateSelectionOpen: '=',
      calView: '<',
      handleChange: '&',
      analytics: '@'
    },
    controller: CalendarControlComponent,
    name : 'hbCalendarControl',
    templateUrl: 'angular/app/modules/core/features/calendar/calendar_control/calendar_control.html',
  };

}());
