(function () {
    "use strict";

    Services.MobileAppService = class MobileAppService {

        // @ngInject
        constructor(AppStates, $state, $rootScope, WorkspaceFileService, AccountsService, UsersManager, PubSubService) {
            this.AppStates = AppStates;
            this.$state = $state;
            this.$rootScope = $rootScope;
            this.WorkspaceFileService = WorkspaceFileService;
            this.AccountsService = AccountsService;
            this.UsersManager = UsersManager;
            this.PubSubService = PubSubService;

            PubSubService.ventMyBitchUp(this);

            this.INBOUND = {
                WORKSPACE_FILE: {
                    _GO_TO_CLIENT_VIEW_: 'file_go_to_client_view',
                    _EXIT_CLIENT_VIEW_: 'file_exit_client_view',
                    _TOGGLE_ASIDE_DRAWER_: 'file_toggle_aside_drawer',
                    _UPDATE_HEADER_: 'file_update_header',
                    _UPDATE_AGREEMENT_: 'file_update_agreement',
                    _ADD_AGREEMENT_FIELD_: 'file_add_agreement_field',
                    _EDIT_AGREEMENT_FIELD_: 'file_edit_agreement_field',
                    _EDIT_AGREEMENT_FIELD_CANCEL_: 'file_edit_agreement_field_cancel',
                    _VALIDATE_AGREEMENT_: 'file_validate_agreement',
                    _SIGN_COMPLETED_: 'file_sign_completed',
                    _SIGN_CANCEL_: 'file_sign_cancel',
                    _GO_TO_STEP_: 'file_go_to_step',
                },
                FLOW: {
                    _GO_TO_FLOW_: 'go_to_flow',
                    _GO_TO_FLOW_PREVIEW_: 'go_to_flow_preview',
                    _EXIT_FLOW_PREVIEW_: 'exit_flow_preview',
                    _NATIVE_LOADED_RECEIVED_ACK_: 'native_loaded_and_received_ack'
                },
                _CLOSE_BANK_DETAILS_MODAL: 'close_bank_details_modal'
            };

            this.OUTBOUND = {
                print: 'hb_app_print',
                print_pdf: 'hb_app_print_pdf',
                workspace_file_loaded: 'hb_app_workspace_file_loaded',
                edit_agreement_field: 'hb_app_edit_agreement_field',
                add_signature: 'hb_app_add_signature',
                sign_opened: 'hb_app_sign_opened',
                file_updated: 'hb_app_file_updated',
                validate_agreement_result: 'hb_app_validate_agreement_result',
                onIdentifyBusinessTypeClick: 'on_identify_business_type_click',
                onStripeAgreementLinkClick: 'on_stripe_agreement_link_click',
                file_step_active: 'hb_app_file_step_active',
                open_file: 'hb_app_open_file',
                open_flow: 'hb_app_open_flow',
                native_controller_loaded: 'hb_app_native_controller_loaded',
                view_loaded: 'hb_app_view_loaded',
                native_controller_health_check: 'hb_app_native_controller_health_check',

                FLOW: {
                    FLOW_LOADED: 'hb_app_flow_loaded'
                }
            };
        }

        goToPipeline() {
            this.$state.go(this.AppStates.root_core_navigation_pipeline, {}, {});
        }

        goToWorkspaceFile(workspaceFileId, params = {}) {
            var fileParams = {
                reload:true,
            };
            if(params.version){
                fileParams.version= params.version;
            }
            this.WorkspaceFileService.gotoWorkspaceFileById(workspaceFileId, params.preview, fileParams);
        }

        goToCalendar(params = {}) {
            this.$state.go(this.AppStates.root_core_navigation_calendar, {dateTimeStart: params.dateTimeStart}, {});
        }

        goToReports() {
            this.$state.go(this.AppStates.root_core_navigation_reports_analytics, {
            }, {});
        }

        goToPayments() {
            this.$state.go(this.AppStates.root_core_navigation_reports_payments, {
            }, {});
        }

        goToProfitAndLoss() {
            this.$state.go(this.AppStates.root_core_navigation_profit_loss, {
            }, {});
        }


        goToBankDetails() {
            this.$state.go(this.AppStates.root_core_navigation_settings_company_bankInfo);
        }

        goToWorkspace(workspaceId, eventId, target = 'feed') {
            switch (target) {
                case 'payments':
                    this.$state.go(this.AppStates.root_core_navigation_event_workspace_payments, {
                        event_id: eventId,
                        workspace_id: workspaceId
                    }, {});
                    break;
                case 'feed':
                    this.$state.go(this.AppStates.root_core_navigation_event_workspace_feed, {
                        workspace_id: workspaceId,
                        event_id: eventId
                    });
            }
        }

        goToCreateProject() {
            this.$state.go(this.AppStates.root_network_create_project, {});
        }

        goToSubscriptionPage() {
            this.$state.go(this.AppStates.root_core_navigation_settings_company_subscription, {});
        }

        goToYearInReviewPage() {
            this.$state.go(this.AppStates.root_core_navigation_yearInReview, {});
        }

        switchCompany(companyId) {
            let user = this.UsersManager.getCurrUser();
            if (user && user.company && user.company.id === companyId) {
                return;
            }
            this.AccountsService.switchCompany(companyId);
        }

        viewLoaded(){
            this.triggerAppEvent(this.OUTBOUND.view_loaded);
        }

        healthCheck(){
            this.triggerAppEvent(this.OUTBOUND.native_controller_health_check);
        }

        notifyAppLoadedAck(){
            this.$rootScope.$broadcast(this.INBOUND.FLOW._NATIVE_LOADED_RECEIVED_ACK_);
        }


        nativeEvent(event) {
            if (event) {
                var _event = event.indexOf('eventName') ? JSON.parse(event) : { eventName: event};
                this.trigger(_event.eventName, _event.eventData);
            }
        }

        triggerAppEvent(eventName, data) {
            if (eventName && window.ReactNativeWebView) {
                var _data = { eventName: eventName, eventData: data || {}};
                window.ReactNativeWebView.postMessage(JSON.stringify(_data));
            }
        }
    };
}());
