
Directives.ScrollToEventDirective = function ScrollToEventDirective() {

    // @ngInject
    function ScrollToEventDirectiveControllerFunc($scope, $injector, $timeout, $) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ScrollToEventDirectiveController';

        this.$timeout = $timeout;
        this.$ = $;

        var self = this;
        $scope.$watch('scrollToEventVm.scrollFlag', function(newFlag, oldFlag) {
            if (self.scrollFlag) {
                self.scrollToSelectedEvent();
            }
        });
    }

    var ScrollToEventDirectiveController = Class(Controllers.BaseController, {
        constructor: ScrollToEventDirectiveControllerFunc,

        scrollToSelectedEvent: function scrollToSelectedEvent() {
            var self = this;
            this.$timeout(function() {
                if (self.scrollFlag) {
                    var element = document.getElementById("event-tile-"+self.eventId);
                    if (element) {
                        var eventIndex = element.attributes['data-event-index'].value;
                        if (eventIndex > 2) {
                            var scrollToIndex = eventIndex - 3;
                            var scrollToElement = self.$("[data-event-index=" + scrollToIndex + "]")[0];
                            scrollToElement.scrollIntoView();
                        }
                        else {
                            self.$("#events-menu-container").scrollTop(0);
                        }
                    }
                    self.scrollFlag = false;
                }
            }, 0);
        }
    });


    return {
        scope: {
            eventId: '=hbScrollToEventId',
            scrollFlag: '=hbScrollToEventFlag'
        },
        controller: ScrollToEventDirectiveController,
        controllerAs: 'scrollToEventVm',
        bindToController: true
    };
};
