(function () {
    'use strict';
    Controllers.UpcomingPaymentsModalController = class UpcomingPaymentsModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, analyticsSource, payments,
                    AnalyticsService, UsersManager) {
            super($scope, $injector, $translate);
            this.__objectType = 'UpcomingPaymentsModalController';
            this.modalInstance = $modalInstance;

            this.AnalyticsService = AnalyticsService;

            this.payments = payments;
            this.paymentsToggledAsInstant = [];
            this.source = analyticsSource;

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.instant_deposit_weekend_modal,
                {source: this.source, debitCard: !!UsersManager.getCurrUser().myDebitCard()});
        }

        toggleInstantDeposit(payment) {
            if(payment.use_instant_payout) {
                this.paymentsToggledAsInstant.push(payment);
            } else {
                this.paymentsToggledAsInstant = this.paymentsToggledAsInstant.filter(toggledPayment =>
                    toggledPayment._id !== payment._id
                );
            }
        }

        confirmInstantDeposits() {
            if (this.paymentsToggledAsInstant.length > 0) {
                this.modalInstance.close({
                    didSucceed: true,
                    paymentsToggledAsInstant: this.paymentsToggledAsInstant});
            } else {
                this.close();
            }
        }

        keepStandardDeposit() {
            this.close();
        }

        closeAndDontShowAgain() {
            this.modalInstance.close({dontShowAgain: true});
        }

        close() {
            this.modalInstance.dismiss();
        }
    };
}());
