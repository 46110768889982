(function () {
    'use strict';

    // @ngInject
    function FirstLevelNavSinglePageControllerCtor($scope, $injector, AnalyticsService, AppStates, $state, NavigationService, $timeout) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FirstLevelNavSinglePageController';
        this.$scope = $scope;

        this.AppStates = AppStates;
        this.$state = $state;
        this.NavigationService = NavigationService;

        this.title = $state.$current.data.title;

        if ($state.$current.data.broadcastPageTitle) {
            // catches emit rebroadcast in network controller
            this.$scope.$on('broadcastPageTitle', function(event, data) {
                this.title = data;
            }.bind(this));
        }
    }

    Controllers.FirstLevelNavSinglePageController = Class(Controllers.BaseController, {
        constructor: FirstLevelNavSinglePageControllerCtor,
        
        handleClickBack: function handleClickBack() {
            this.NavigationService.goHistoryBack();
        }
    });
}());
