(function () {
    "use strict";

    // @ngInject
    function StartTrialAsClientModalController($scope, $injector, $modalInstance, $window, ModalService, user, companyName) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'StartTrialAsClientModalController';
        this.modalInstance = $modalInstance;
        this.ModalService = ModalService;
        this.$window = $window;
        this.user = user;
        this.companyName = companyName;
        this.dismiss = this.dismiss.bind(this);
        this.onCtaClick = this.onCtaClick.bind(this);
    }

    Controllers.StartTrialAsClientModalController = Class(Controllers.BaseController, {

        constructor: StartTrialAsClientModalController,

        dismiss: function dismiss() {
            this.modalInstance.dismiss();
        },

        redirectToSignUpPage: function redirectToSignUpPage() {
            const appUrl = this.$window.gon.url.endsWith('/') ? `${this.$window.gon.url}` : `${this.$window.gon.url}/`;
            const signupUrl = `${appUrl}signup`;
            return this.$window.open(signupUrl, '_blank').focus();
        },

        onCtaClick: function onCtaClick() {
            this.user.isLocatedInUSOrCanada().then(function (isLocationInUsOrCanada) {
                if (isLocationInUsOrCanada) {
                    this.redirectToSignUpPage();
                }
                else {
                    this.ModalService.openNonUsaWarningForClientModal(this.redirectToSignUpPage.bind(this));
                }
                this.dismiss();
            }.bind(this));
        }
    });
}());

