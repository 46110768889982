/**
 * Created by dvircohen on 16/05/2017.
 */
(function () {
    'use strict';

    Directives.InnerEmailEditorDirective = function InnerEmailEditorDirective() {
        // @ngInject
        function InnerEmailEditorDirectiveControllerCtor($scope, $injector, $state, AnalyticsService, RTEFactory, CKEDITOR, uuid4, AutoInputSizeService, InputFieldsService, $q, _, $sanitize,
                                                         $timeout, $, $document, $element, PhotosUploadManager, UsersManager, ModalService, $location, $anchorScroll, RegexService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'InnerEmailEditorDirectiveController';

            this.$ = $;
            this.CKEDITOR = CKEDITOR;
            this.uuid4 = uuid4;
            this.$timeout = $timeout;
            this.$element = $element;
            this.AutoInputSizeService = AutoInputSizeService;
            this.AnalyticsService = AnalyticsService;
            this.InputFieldsService = InputFieldsService;
            this.PhotosUploadManager = PhotosUploadManager;
            this.ModalService = ModalService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;
            this.RegexService = RegexService;
            this.$q = $q;
            this.$state = $state;
            this._ = _;
            this.$sanitize = $sanitize;
            this.activeHyperLink = false;

            this.showCropTab = false;
            this.showGalleryTab = true;
            this.showUploadTab = true;
            this.defaultTab = "Library";
            this.allowMultipleAttach = true;
            this.imageFiles = this.imageFiles || [];
            this.generalFiles = this.generalFiles || [];
            this.uploadingFilesPromises = [];
            this.UsersManager = UsersManager;
            this.user = this.UsersManager.getCurrUser();
            this.hasUserClickedCancel = false;

            // We're using a timeout as the controller is loaded before the dom
            $timeout(function() {
                var config = {};

                if (this.config.ckeditor) {
                    config = this.config.ckeditor;
                } else {
                    config = {
                        height: this.height || '300',
                    };
                }

                var toolbarItemsToRemoveArray = [];
                if (this.config.isSendToWorkspaces || this.config.sendBulkToContacts) {
                    toolbarItemsToRemoveArray = ['AddClientName'];
                }
                this.editor = this.editorReference = RTEFactory.newEditorFromType(this.editorType, 'emailTextArea', config, toolbarItemsToRemoveArray);

                if (this.user.calendly_url) {
                  config.includeToolbarItems.push('addCalendlyLink');
                }

                if (angular.isFunction(this.onEditorBlur)) {
                    this.editor.on('blur', this.onEditorBlur);
                }

                CKEDITOR.once('instanceLoaded', function onInstanceLoaded() {

                    // ugly as shit, remove all title attributes from the toolbar buttons
                    $('.cke_toolbar a').each(function(i, elm) {
                        $(elm).removeAttr('title');
                    });

                    this.setHtmlBody(this.body);

                    angular.element('.email-text-area-wrapper').scrollTop(0);
                }.bind(this));

                this.editor.on('addClientName', function onAddClientName() {

                    var FIRST_CLIENT_FIELD_NAME = '{field_name:client_first_name_1, field_type:String, field_default:first_client_first_name, field_input_type:String, field_placeholder:First Client First Name, field_changed:false, field_hb_data_type:vendor_field}';
                    var field = this.InputFieldsService.getField(FIRST_CLIENT_FIELD_NAME);
                    AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.client_first_name_field_button);

                    var $tmp = new CKEDITOR.dom.element('input');

                    $tmp.setAttributes({
                        type: 'text',
                        value: this._getClientNameValue(),
                        id: uuid4.generate(),
                        placeholder: field.getPlaceholder(),
                        class: 'vendor-field email-editor-field',
                        name: FIRST_CLIENT_FIELD_NAME,
                        disabled: 'disabled'
                    });
                    this.editor.insertElement($tmp);
                    this.editor.getSelection().selectElement($tmp);

                    AutoInputSizeService.setSize($tmp.$);
                }.bind(this));

                this.editor.on('addEmailSignature', function onAddEmailSignature() {

                    var signatures = this._getSignatures();

                    if (signatures.length === 0) {
                        return;
                    }

                    this.removeCurrentSignature();
                    this.initEmailSignature(signatures[0]);
                    this.addRemoveSignatureButton();

                }.bind(this));

                this.editor.on('addHyperLink', function() {
                    $scope.$apply(function() {
                        this.activeHyperLink = !this.activeHyperLink;
                    }.bind(this));
                }.bind(this));

                this.editor.on('addFileAttachment', function () {
                    this.addFileToEmailEditor();
                }.bind(this));

                this.editor.on('addCalendlyLink', function () {
                    this.editor.insertText(this.calendlyUrl);
                    this.AnalyticsService.trackClick(this, "add calendly link to email editor");
                }.bind(this));

                this.editor.on('dataReady', function() {

                    // Replace signature for current user
                    if (this._innerRemoveCurrentSignature()) {
                        this.initEmailSignature(this._getSignatures()[0]);
                        this.addRemoveSignatureButton();
                    }

                    // Make sure all the values are set
                    this.$(this.editor.container.$).find('input').each(function (idx, input) {
                        var $input = this.$(input);
                        $input.val(this._getClientNameValue());
                        $input.attr('value', this._getClientNameValue());
                        this.AutoInputSizeService.setSize($input);
                    }.bind(this));

                }.bind(this));

            }.bind(this));

            $document.on('click.email_signature.honeybook', "#removeEmailSignature", function() {
                this._innerRemoveCurrentSignature();
            }.bind(this));

            $scope.$on('$destroy', function() {
                $document.off('click.email_signature.honeybook');
            });

            $scope.$on('updateBody', function(e, htmlBody) {
                this.setHtmlBody(htmlBody);
            }.bind(this));

            $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                var numberOfUnuploadedFiles = this.uploadingFilesPromises.filter(function(file) {
                    return file.file.progress < 100;
                }).length;

                if (!this.hasUserClickedCancel && numberOfUnuploadedFiles && toState.name !== fromState.name) {
                    event.preventDefault();
                    this.openFilesUploadingModal(numberOfUnuploadedFiles < 1, toState, toParams);
                }
            }.bind(this));
        }

        var InnerEmailEditorDirectiveController = Class(Controllers.BaseController, {
            constructor: InnerEmailEditorDirectiveControllerCtor,

            validateUrl: function validateUrl(str){
                this.checkUrlIsValid = !( this.RegexService.isUrlValid(str) || this.RegexService.isEmailValid(str));
            },

            openFilesUploadingModal: function openFilesUploadingModal(isMoreThanOneFile, toState, toParams) {
                this.ModalService.openUploadingFilesModal(isMoreThanOneFile).then(function(wait) {
                    if (!wait) {
                        // Remove non uploaded files
                        const uploadingFilesPromisesCopy = this.uploadingFilesPromises.slice();
                        uploadingFilesPromisesCopy.forEach(function(file) {
                            if (file.file.progress < 100) {
                                this.removeAttachment(file.file);
                            }
                        }.bind(this));
                        this.hasUserClickedCancel = true;
                        this.$state.go(toState, toParams);
                    }
                }.bind(this));
            },

            addLinkToBody: function addLinkToBody() {

                var hyperLinkUrl = this.linkUrl.trim();
                var hyperlinkText = this.linkUrlText;
                if (hyperlinkText) {
                    hyperlinkText = this.$sanitize(hyperlinkText);
                }
                var resTarget = "_blank";

                var resText = this._getSelectedText();

                if (resText === "") {
                    resText = " " + hyperLinkUrl + " ";
                }

                if (hyperLinkUrl) {
                    if (this._isEmail(hyperLinkUrl)) {
                        hyperLinkUrl = "mailto:" + hyperLinkUrl;
                        resTarget = "_top";
                    } else if (!this._urlHasProtocol(hyperLinkUrl)) {
                        hyperLinkUrl = "http://" + hyperLinkUrl;
                    }

                    var newLinkElem = new this.CKEDITOR.dom.element('a');
                    newLinkElem.setAttributes({
                        href: hyperLinkUrl,
                        target: resTarget,
                    }).setHtml(hyperlinkText ? hyperlinkText : resText);

                    this.editor.insertElement(newLinkElem);

                    this.linkUrl = "";
                    this.linkUrlText = "";
                    this.activeHyperLink = false;
                }
            },

            setHtmlBody: function setHtmlBody(htmlBody) {

                if (!this.editor) {
                    return;
                }

                this.editor.setData(htmlBody);
            },

            initEmailSignature: function initEmailSignature(sig) {
                if (sig && sig.html_body) {
                    var $tmp = new this.CKEDITOR.dom.element('div');
                    $tmp.setAttributes({
                        id: this.uuid4.generate(),
                        class: 'editor-email-signature ck-content vendor-field email-editor-field',
                        name: 'EmailSignature',
                        contenteditable:"false"
                    }).setHtml(sig.html_body);

                    // Move selection to the end of the editable element.
                    var range = this.editor.createRange();
                    range.moveToPosition( range.root, this.CKEDITOR.POSITION_BEFORE_END);
                    this.editor.getSelection().selectRanges( [ range ] );
                    this.editor.insertElement($tmp);
                    this.editor.getSelection().selectElement($tmp);
                }
            },

            removeCurrentSignature: function removeCurrentSignature() {
                this.AnalyticsService.trackClick(this, "remove email signature");
                return this._innerRemoveCurrentSignature();

            },

            addRemoveSignatureButton: function addRemoveSignatureButton() {
                var signatureCont = angular.element('.editor-email-signature');
                signatureCont.prepend('<span id="removeEmailSignature" class="remove-signature-email"></span>');
            },

            _innerRemoveCurrentSignature: function __innerRemoveCurrentSignature() {
                var signatureCont = this.$element.find('.editor-email-signature');
                if (signatureCont.length === 1) {
                    var next = signatureCont.next();
                    if (next && next.prop("tagName") && next.text() === "") {
                        next.remove();
                    }
                    signatureCont.remove();
                    this.editor.focus();
                    return true;
                }

                return false;
            },

            _getSignatures: function getSignatures() {
                return angular.isArray(this.signatures) ? this.signatures : [];
            },

            _getClientNameValue: function getClientNameValue() {
                return angular.isFunction(this.addClientValue) ? this.addClientValue() : '';
            },

            _isEmail: function _isEmail(str) {
                return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str);
            },

            _urlHasProtocol: function _urlHasProtocol(url) {
                return /^(https?|ftp|news):\/\//.test(url);
            },

            _getSelectedText: function _getSelectedText() {
                return this.editor.getSelection().getSelectedText();
            },

            addFileToEmailEditor: function addFileToEmailEditor() {
                this.AnalyticsService.trackClick(this, "attach files to email modal");
                this.ModalService.openAttachAssetModal(this.showCropTab, this.showGalleryTab, this.showUploadTab, this.defaultTab, this.allowMultipleAttach, false, null, false, false, '',
                '', '', '', 'MEDIA_MODAL.TITLE._ATTACH_IMG_OR_FILE_').then(
                    function success(filesList) {

                        filesList = filesList.filter(function (currFile) {

                            var isNewUploaded = true;
                            var _isFileExists;

                            if (!currFile || !currFile.dataUrl) {
                                isNewUploaded = false;

                                // In case user added existing files, 'url' field should be used instead of dataUrl
                                if (!currFile || !currFile.url) {
                                    return false;
                                }
                            }

                            var isFileExistsInFilesList = function isFileExistsInFilesList(file) {
                                return (file.dataUrl && file.dataUrl === currFile.dataUrl) || (file.url && file.url === currFile.url) || (file.file_url && file.file_url === currFile.file_url);
                            };

                            // check if this file already exists on the image files list
                            _isFileExists = this.imageFiles.some(isFileExistsInFilesList);
                            if (_isFileExists) {
                                return false;
                            }

                            // check if this file already exists on the general files list
                            _isFileExists = this.generalFiles.some(isFileExistsInFilesList);
                            if (_isFileExists) {
                                return false;
                            }

                            return true;
                        }.bind(this));

                        filesList.forEach(function (file) {
                            var fileType;

                            if (!file.url) {
                                // New file to upload

                                fileType = file.type.toLowerCase();

                                //start to upload these files right away. save the promises of the uploads
                                //because the send message will have to wait for them to finish till they upload
                                var model = {
                                    instance: this,
                                    type: "workspace_message",
                                    dontUpdateServer: true
                                };

                                this.PhotosUploadManager.getUpdatedModelData(model);
                                var fileUploadPromise = this.PhotosUploadManager.uploadPhoto([file], model, this);
                                var fileNode = {
                                    file: file,
                                    fileUploadPromise: fileUploadPromise
                                };
                                this.uploadingFilesPromises.push(fileNode);
                            }

                            else {

                                // File from library

                                fileType = (/\.(gif|jpg|jpeg|tiff|png)([?#].*)?/i.test(file.url)) ? "image" : "file";
                            }

                            if (fileType.indexOf('image') !== -1) {
                                this.imageFiles.push(file);
                            } else {
                                this.generalFiles.push(file);
                            }

                        }.bind(this));

                        if (this.imageFiles.length || this.generalFiles.length || this.uploadingFilesPromises.length) {

                            var uploadFilesPromises = this.uploadingFilesPromises.map(function (file) {
                                return file.fileUploadPromise;
                            });

                            this.$q.all(uploadFilesPromises).then(function success() {

                                this.imageFiles = this.imageFiles.map(function(file) {

                                    if (file.s3Response){
                                        // uploaded
                                        return this._.extend({}, file, {file_url: file.s3Response.url, type: "image"});
                                    } else {
                                        // from library
                                        return this._.extend({}, file, {file_url: file.url, type: "image"});
                                    }

                                }, this);

                                this.generalFiles = this.generalFiles.map(function(file) {
                                    if (file.s3Response){
                                        // uploaded
                                        return this._.extend({}, file, {file_url: file.s3Response.url, file_name: file.name, type: "file"});
                                    } else {
                                        // library
                                        return this._.extend({}, file, {file_url: file.url, file_name: file.name, type: "file"});
                                    }
                                }, this);

                                if (angular.isFunction(this.onFilesChanged)) {
                                    this.onFilesChanged();
                                }

                            }.bind(this));

                        }

                    }.bind(this),
                    function error() {
                    }
                )
            },

            removeFileUploadPromise: function removeFileUploadPromise(file) {
                var indexToRemove = null;
                for (var i = 0; i < this.uploadingFilesPromises.length; i++) {
                    if (indexToRemove === null && this.uploadingFilesPromises[i].file === file) {
                        indexToRemove = i;
                    }
                }

                if (indexToRemove !== null) {
                    this.uploadingFilesPromises.splice(indexToRemove, 1);
                }
            },

            removeImage: function removeImage(index) {
                var removedFile = this.imageFiles.splice(index, 1)[0];
                this.removeAttachment(removedFile);
            },

            removeGeneralFile: function removeGeneralFile(index) {
                var removedFile = this.generalFiles.splice(index, 1);
                this.removeAttachment(removedFile);
            },

            removeAttachment: function(removedFile) {
                this.fileBeingRemoved = true;

                if (angular.isFunction(this.onFilesChanged)) {
                    this.onFilesChanged();
                }

                if (removedFile.uploadAPI) {
                    removedFile.uploadAPI.abort();
                    this.removeFileUploadPromise(removedFile);
                }
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/emails/editor/inner_email_editor_directive_template.html',
            controller: InnerEmailEditorDirectiveController,
            controllerAs: 'innerEmailEditorVm',
            bindToController: true,
            scope: {

                editorType: '<',

                // Ugly hack to propagate the editor reference up to get its data
                editorReference: '=?',
                config: '=',
                onEditorBlur: '&?',
                body: '=',
                height: '<?',
                signatures: '=?',
                imageFiles: '=',
                generalFiles: '=',
                uploadingFilesPromises: '=',
                addClientValue: '&?',
                calendlyUrl: '=',
                onFilesChanged: '&?',
            }
        };
    };

}());
