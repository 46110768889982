(function () {
    "use strict";

    // @ngInject
    function AddPromotionModalControlleCtor($modalInstance, AnalyticsService, ContactFormService, TemplatesViewService) {
        this.modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.ContactFormService = ContactFormService;
        this.TemplatesViewService = TemplatesViewService;
    }

    Controllers.AddPromotionModalController = Class(Controllers.BaseController, {

        constructor: AddPromotionModalControlleCtor,

        dismiss: function dismiss() {
            this.AnalyticsService.trackClick(this, "add promotion modal close");
            this.close();
        },

        close: function close() {
            this.modalInstance.dismiss();
        },

        getTemplate: function getTemplate(type, AnalyticType) {
            this.TemplatesViewService.showLoader();
            this.AnalyticsService.trackClick(this, "gift card get started", { type: AnalyticType });
            this.ContactFormService.copyContactFormExampleToCompany(type).then(function () {
                this.TemplatesViewService.goToLastAddedTemplate();
                this.close();
            }.bind(this), function (error) {
                //if from any reason (maybe staging of localhost that doesn't have the cf examples) - try to create the default)
                this.ContactFormService.createDefaultGiftCardForm().then(function () {
                    this.TemplatesViewService.goToLastAddedTemplate();
                    this.close();
                }.bind(this));
            }.bind(this));
        }
    });
}());
