Directives.FeedActivityViewerDirective = function FeedActivityViewerDirective() {

    // @ngInject
    function FeedActivityViewerDirectiveControllerFunc($translate, $element, $, AppStates, $state, $compile, $scope, $injector,
                                                       UsersManager, OnboardingBotManager, UIUtils) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedActivityViewerDirectiveController';
        this.user = UsersManager.getCurrUser();

        this.$ = $;
        this.$state = $state;
        this.$translate = $translate;
        this.$element = $element;
        this.AppStates = AppStates;
        this.$compile = $compile;
        this.OnboardingBotManager = OnboardingBotManager;
        this.UIUtils = UIUtils;

        this.iconBase = 'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/icons/';
        this.icon = {};

        this.setActivityTemplateText();

        $scope.$applyAsync(function(){
            this.applyActivityView();
        }.bind(this));
    }

    var FeedActivityViewerDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedActivityViewerDirectiveControllerFunc,

        iconUrl: function iconUrl(iconName, brochure) {
            var ext = '_blue.svg';
            if (brochure) {
                ext = '.svg';
            }
            this.icon.alt = iconName;
            this.icon.img = this.iconBase + iconName + ext;
        },

        setActivityTemplateText: function setActivityTemplateText() {
            var prefix = "WORKSPACE.FEED.ACTIVITY";
            var translationEntry;
            switch (this.activityModel.object_type) {
                case 'payment':
                    switch (this.activityModel.action_type) {
                        case 'paid':
                            if (this.activityModel.payment.index === 1) {
                                translationEntry = prefix + '.FIRST_PAYMENT_PAID';
                                this.iconUrl('booked');
                            } else {
                                translationEntry = prefix + '.SOME_PAYMENT_PAID';
                                this.iconUrl('paid');
                            }
                            break;
                        case 'pending':
                            if (this.activityModel.payment.index === 1) {
                                translationEntry = prefix + '.FIRST_PAYMENT_PENDING';
                                this.iconUrl('booked');
                            } else {
                                translationEntry = prefix + '.SOME_PAYMENT_PENDING';
                                this.iconUrl('paid');
                            }
                            break;
                        case 'set_as_paid':
                            if (this.activityModel.payment.index === 1) {
                                translationEntry = prefix + '.FIRST_PAYMENT_MAP';
                                this.iconUrl('booked');
                            } else {
                                translationEntry = prefix + '.SOME_PAYMENT_MAP';
                                this.iconUrl('paid');
                            }
                            break;
                        case 'reminder_sent':
                            if (!!this.activityModel.flow_id) {
                                return;
                            }
                            if (this.activityModel.user) {
                                translationEntry = prefix + '.REMINDER_SENT';
                            }
                            else {
                                translationEntry = prefix + '.AUTO_REMINDER_SENT';
                            }
                            this.iconUrl('reminder_sent');
                            break;
                        case 'payment_requested':
                            translationEntry = prefix + '.PAYMENT_REQUESTED';
                            this.iconUrl('reminder_sent');
                            break;
                        case 'partial_refund':
                            translationEntry = prefix + '.PARTIAL_REFUND_PAYMENT_MAP';
                            this.iconUrl('refund');
                            break;
                        case 'refund':
                            translationEntry = prefix + '.REFUND_PAYMENT_MAP';
                            this.iconUrl('refund');
                            break;
                    }
                    break;
                case 'couple_card':
                    switch (this.activityModel.action_type) {
                        case 'added_user':
                            translationEntry = prefix + '.ADDED_USER';
                            this.iconUrl('useradded');
                            break;
                        case 'assigned_user':
                            translationEntry = prefix + '.ASSIGNED_USER';
                            this.iconUrl('send');
                            break;
                        case 'pvl_request':
                            translationEntry = prefix + '.PVL_REQUESTED';
                            this.iconUrl('pvl-recommendations-req');
                            break;
                    }
                    break;
                case 'brochure':
                    switch (this.activityModel.action_type) {
                        case 'selected_brochure_proposal':
                            if (this.OnboardingBotManager.isOnboardingBotActiveOnWS(this.activityModel.workspace)){
                                translationEntry = prefix + '.SELECTED_BROCHURE_PROPOSAL_BOT';
                            }else{
                                translationEntry = prefix + '.SELECTED_BROCHURE_PROPOSAL';
                            }
                            this.iconUrl('checkmark-09', true); //img name
                            break;
                    }
                    break;
                case 'signature':
                    switch (this.activityModel.action_type) {
                        case 'signed':
                            translationEntry = prefix + '.AGREEMENT_SIGNED';
                            this.iconUrl('signed');
                            break;
                    }
                    break;
                case 'calendar_item':
                    switch (this.activityModel.action_type) {
                        case 'meeting_scheduled':
                            if(this.activityModel.calendar_item.all_day){
                                translationEntry = prefix + '.ALL_DAY_MEETING_SCHEDULED';
                            } else {
                                translationEntry = prefix + '.MEETING_SCHEDULED';
                            }
                            this.iconUrl('watch');
                            break;

                        case 'meeting_updated':
                            if(this.activityModel.calendar_item.all_day){
                                translationEntry = prefix + '.ALL_DAY_MEETING_UPDATED';
                            } else {
                                translationEntry = prefix + '.MEETING_UPDATED';
                            }
                            this.iconUrl('watch');
                            break;
                    }
                    break;
                case 'questionnaire':
                    switch (this.activityModel.action_type) {
                        case 'questionnaire_submitted':
                            translationEntry = prefix + '.QUESTIONNAIRE_SUBMITTED';
                            this.iconUrl('checkmark-09', true); //img name
                            break;
                    }
                    break;
            }
            if (translationEntry) {
                this.display = true;
                this.rawHeader = translationEntry + '._HEADER_';
                this.rawBody = translationEntry + '._BODY_';
                this.rawFooter = translationEntry + '._FOOTER_';
            }
        },

        applyActivityView: function applyActivityView() {
            if (this.display) {

                var bodyElement = this.$element.find(".feed-activity-body")[0];


                var self = this;
                this.$(bodyElement).find(".file-link").each(function () {
                    var parent = self.$(this);
                    var el = self.$compile('<a ui-sref="' + self.AppStates.root_core_workspaceFile +
                        "({workspace_file_id: feedActivityViewerVm.activityModel.workspace_file._id})" + '" hb-analytics-event="link to file from feed item">' +
                        parent.text() + "</ui-sref>")(self.$scope);
                    parent.html("").append(el);
                });

                this.$(bodyElement).find(".workspace-files-tab-link").each(function () {
                    var parent = self.$(this);
                    var el = self.$compile('<a ui-sref="' + self.AppStates.root_core_navigation_event_workspace_files +
                        "({workspace_id: feedActivityViewerVm.activityModel.workspace._id})" + '" hb-analytics-event="link to workspace files tab from feed item">' +
                        parent.text() + "</ui-sref>")(self.$scope);
                    parent.html("").append(el);
                });

                if (this.activityModel.user._id === this.user._id) {
                    // if this is the user that is the owner of this meeting allow him to click the meeting title and jump to the calendar
                    this.$(bodyElement).find(".meeting-title").each(function () {
                        var $el = angular.element(this);
                        $el.click(function () {
                            self.$state.go(self.AppStates.root_core_navigation_calendar, {
                                calendar_item_id: self.activityModel.calendar_item._id,
                                dateTimeStart: self.activityModel.calendar_item.date_time_start
                            });
                        });
                        $el.addClass('link-act');
                    });
                }


            }
        }
    });

    return {
        scope: {
            activityModel: '=activityModel'
        },
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_activity_viewer_directive_template.html',
        controller: FeedActivityViewerDirectiveController,
        controllerAs: 'feedActivityViewerVm',
        bindToController: true
    };
};
