(function () {
    "use strict";

    // @ngInject
    function TimeTrackerToolInvoiceControllerCtor($modalInstance, $scope, $injector, AnalyticsService, ModalService, EventsManager) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerToolInvoiceController';

        this.$modalInstance = $modalInstance;
        AnalyticsService.trackPageView(this, 'time_tracker_tool_invoice_modal');

        this.ModalService = ModalService;
        this.AnalyticsService = AnalyticsService;
        this.EventsManager = EventsManager;

        this.fetchingProject = false;

        this.timeTracker = {
            project_id : '',
            project_name: ''
        }

        this.CONSTANTS = {
            CREATE_INVOICE : 'create_invoice'
        }
    }

    Controllers.TimeTrackerToolInvoiceController = Class(Controllers.BaseController, {
        constructor: TimeTrackerToolInvoiceControllerCtor,

        projectChanged: function projectChanged(projectId) {
            this.timeTracker.project_id = projectId ? projectId : '';
        },

        setWorkspaceForInvoice: function setWorkspaceForInvoice() {
            if (!this.timeTracker.project_id) {
                return;
            }

            this.fetchingProject = true;
            this.EventsManager.getEvent(this.timeTracker.project_id, true, true)
                .then(function (project) {
                    this.fetchingProject = false;
                    this.projectLoaded(project);
                }.bind(this));

        },

        projectLoaded: function projectLoaded(project) {
            this.$modalInstance.dismiss();
            this.ModalService.openTimeTrackerViewAllModal(false, this.CONSTANTS.CREATE_INVOICE, project );

        },

        dismiss: function dismiss() {
            this.$modalInstance.dismiss();
        },

    });
}());
