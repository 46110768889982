Directives.BrochureNewDirective = function BrochureNewDirective() {

    // @ngInject
    function BrochureNewDirectiveControllerFunc($injector, $scope, $timeout, $window, $translate,
                                                ModalService, PopupMessageService, _, UiPersistenceService,
                                                AnalyticsService, DeviceService, QuestionsStateService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrochureNewDirectiveController';

        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.UiPersistenceService = UiPersistenceService;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;
        this.DeviceService = DeviceService;
        this.QuestionsStateService = QuestionsStateService;

        this._init();

        var modelToRegister = (this.fileModel && this.fileModel.isModelOf('WorkspaceFile')) ? this.fileModel : this.companyModel;
        this.register(modelToRegister, 'success', this._fileModelLoaded.bind(this));

        this._onBrochureSectionsChange();

        // watch the questions types in this contact form
        // used to mark which questions can't be added
        // because they are already in the contact form
        this.QuestionsStateService.watchModel(this.fileModel, this.companyModel);

        this.$scope.$on('$destroy', function() {
            this.QuestionsStateService.unwatchModel();
        }.bind(this));
    }

    var BrochureNewDirectiveController = Class(Controllers.BaseController, {
        constructor: BrochureNewDirectiveControllerFunc,

        _init: function _init() {
            this.brochureSectionsList = [];
            this.requestInProgress = false;
        },

        _fileModelLoaded: function _fileModelLoaded() {
            this._onBrochureSectionsChange();
            this._shouldShowTooltipTitleNav();

            this.QuestionsStateService.updateQuestionsKeys();
        },

        isTemplate: function isTemplate() {
            return this.fileModel.isModelOf('Template');
        },

        isEditableByOwner: function isEditableByOwner() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode && !this.DeviceService.device.nxSmallMaxSize;
        },

        isEditableByClient: function isEditableByClient() {
            return  this.isClientMode &&
                    !(this.fileModel.isComplete() || this.fileModel.isCanceled());
        },

        isClickNextEnabled: function isClickNextEnabled() {
            return this.isEditableByOwner();
        },

        shouldShowSubmit: function shouldShowSubmit() {
            return (this.isClientMode && !(this.fileModel.isComplete() || this.fileModel.isCanceled()));
        },

        shouldShowComments: function shouldShowComments() {

            // if template, we are not showing this
            if(this.isTemplate()) {
                return false;
            }

            if(!this.fileModel.isEnableClientNotes()) {
                return false;
            }

            // if not editable, show notes if exist
            if (this.fileModel.isComplete() || this.fileModel.isCanceled()) {
                return !!this.fileModel.client_notes;
            }

            // always show for vendor in preview mode
            if(this.isOwnerMode && this.isPreviewMode) {
                return true;
            }

            // show for clients
            if(this.isClientMode) {
                return true;
            }

            return false;
        },

        isCommentsEnabled: function isCommentsEnabled() {
            return this.shouldShowSubmit();
        },

        shouldShowReviewSection: function shouldShowReviewSection() {

            // if no proposal, there is no review
            if(!this.fileContentModel.has_proposal) {
                return false;
            }

            // if this is the client, show review
            if(this.isClientMode) {
                return true;
            }

            // if we got here it means there is a proposal
            // and this is a vendor
            return  this.isOwnerMode &&
                    !this.isEditableMode &&
                    !this.isPreviewMode;
        },

        shouldShowSelectionBanner: function shouldShowSelectionBanner() {
            return !this.isTemplate() &&
                    this.fileContentModel.has_proposal &&
                    this.isOwnerMode &&
                    this.fileModel.isComplete() &&
                    !!this.summaryProposal &&
                    !this.summaryProposal.isEmpty() &&
                    !this.isPreviewMode;
        },

        //////////////////////////////
        // Sections CRUD
        /////////////////////////////
        onCreateBrochureSection: function onCreateBrochureSection() {
            this.fileContentModel.addSection()
                .then(function(resp){
                    this._onSectionAdded(resp);
                }.bind(this));
        },

        onUpdateBrochureSection: function onUpdateBrochureSection(brochureSection) {
            this.fileContentModel.updateSection(brochureSection)
                .then(function(resp){
                    this._onSectionUpdated(resp);
                }.bind(this));
        },

        onDeleteBrochureSection: function onDeleteBrochureSection(brochureSection) {
            var warningMessage = this.$translate.instant("BROCHURE._DELETE_SECTION_CONFIRM_MSG_");
            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.warning, warningMessage)
                .then(function confirmDelete() {
                    this.fileContentModel.deleteSection(brochureSection)
                        .then(function(resp){
                            this._onSectionDeleted(resp);
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.delete_section);
                        }.bind(this));
                }.bind(this));
        },

        onSortBrochureSections: function onSortBrochureSections(brochureSections) {
            var data = [];
            brochureSections.forEach(function (section, index) {
                data.push({_id: section._id, order: index});
            });

            this.fileContentModel.updateSectionsOrder(data)
                .then(function(resp){
                    this._onSectionsOrderUpdated(resp);
                }.bind(this));
        },

        onChangeBrochureSection: function onChangeBrochureSection() {
            this.closeTooltipTitle();
        },

        _onBrochureSectionsChange: function _onBrochureSectionsChange() {
            this.brochureSectionsList.splice(0);

            if(this.fileContentModel) {
                this.brochureSectionsList = this.fileContentModel.file_sections;
                this._sortSections(this.brochureSectionsList);
                this.summarySection = this.fileContentModel.selection_summary_section;
                if(this.summarySection && this.summarySection.file_blocks.length) {
                    this.summaryProposal = this.summarySection.file_blocks[0].proposal;
                }

            }
        },

        _onSectionAdded: function _onSectionAdded() {
            this._onBrochureSectionsChange();
        },

        _onSectionDeleted: function _onSectionDeleted() {
            this._onBrochureSectionsChange();
        },

        _onSectionUpdated: function _onSectionUpdated() {
            this._onBrochureSectionsChange();
        },

        _onSectionsOrderUpdated: function _onSectionsOrderUpdated() {
            this._onBrochureSectionsChange();
        },

        _sortSections: function _sortSections(sections) {
            this.brochureSectionsList = this._sortByOrder(sections);
            this.$scope.$applyAsync();
        },

        _sortByOrder: function _sortByOrder(collection) {
            return this._.sortBy(collection, function(e){
                return e.order ? e.order : 0;
            });
        },

        getClientName: function getClientName() {
            if(this.isTemplate()) {
                this.clientFirstName = "";
            } else if(!this.clientFirstName) {
                var clients = this.fileModel.usersWithoutTeamMembers();
                var firstClient = clients.length ? clients[0] : null;
                this.clientFirstName = firstClient.first_name;
            }

            return this.clientFirstName;
        },

        //////////////////////////////
        // Helper tooltip
        /////////////////////////////
        getTooltipTitle: function getTooltipTitle() {
            return '<span class="brochure__tooltip-title--intro">'+ this.$translate.instant('BROCHURE.TOOLTIP.TITLE._TEXT_ONE_') +'</span>' +
                   '<span class="brochure__tooltip-title--tip">' + this.$translate.instant('BROCHURE.TOOLTIP.TITLE._TIP_') +'</span>';
        },

        goToLearnBrochures: function goToLearnBrochures() {
            this.$window.open('https://help.honeybook.com/using-honeybook-files/sending-and-managing-files/using-honeybook-brochures','_blank');
        },

        closeTooltipTitle: function closeTooltipTitle() {
            if (!this.showTooltipTitleNav) {
                return;
            }

            var brochureHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.brochureNew) || {};
            brochureHash.brochureNewTooltipNavTitle = true;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.brochureNew, brochureHash);

            this.showTooltipTitleNav = false;
        },

        _shouldShowTooltipTitleNav: function _shouldShowTooltipTitleNav() {
            var brochureHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.brochureNew) || {};

            if (brochureHash.brochureNewTooltipNavTitle) {
                return;
            }

            this.showTooltipTitleNav = true;
            this._focusSection();
        },

        _focusSection: function _focusSection(){
            this.$timeout(function() {
                angular.element('.section-nav__list-item--last-child')
                       .addClass('section-nav__list-item--inputFocus')
                       .find('input').select();
            }.bind(this));
        },

        //////////////////////////////
        // Brochure actions
        /////////////////////////////
        onClientReviewBrochure: function onClientReviewBrochure() {

        },

        vendorReviewBrochure: function vendorReviewBrochure() {
            if(this.onReview) {
                this.onReview({});
            }
        },

        submitBrochure: function submitBrochure() {
            if(this.onSubmit) {
                this.onSubmit({clientNotes: this.fileModel.client_notes});
            }
        }

    });

    return {
        scope: {
            fileModel: '=?fileModel',
            fileContentModel: '=?fileContentModel',
            companyModel: '=companyModel',
            isClientMode: '=isClientMode',
            isOwnerMode: '=',
            isEditableMode: '=',
            isPreviewMode: '=',
            isTemplateMode: '=',
            isSubmitEnabled: '=',
            onReview: '&',
            onSubmit: '&'
        },
        templateUrl: 'angular/app/modules/core/features/brochure/brochure_new/brochure_new_directive.html',
        controller: BrochureNewDirectiveController,
        controllerAs: 'brochureNewVm',
        bindToController: true
    };
};
