// @ngInject

Models.EmailsCollection = function EmailsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor() {
                this.constructor.$super.call(this);
                this._collection.__className = 'EmailsCollection';
                this._collection.__childsType = 'EmailModel';
                return this._collection;
            }
        }
    );

};
