/**
 * The hb-pipeline-actions-popover component
 *
 * Popover for Move stage/Archive actions
 *
 */


(function () {
    'use strict';

    class PipelineActionsPopoverController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector, 
            $translate, 
            Constants, 
            WorkspacesManager,
            WorkspaceService,
            AnalyticsService,
            EventsManager,
            PopupMessageService,
            Enums
        )  {
            super($scope, $injector);
            this.__objectType = 'PipelineActionsPopoverController';
            this.WorkspacesManager = WorkspacesManager;
            this.AnalyticsService = AnalyticsService;
            this.WorkspaceService = WorkspaceService;
            this.EventsManager = EventsManager;
            this.PopupMessageService = PopupMessageService;
            this.Enums = Enums;
            this.isBtnClicked = false;
            this.content = {
                stage: {
                    title: $translate.instant(
                        this.isBulk ?
                            'PIPELINE.ACTIONS_POPOVER.STAGE._TITLE_BULK_' :
                            'PIPELINE.ACTIONS_POPOVER.STAGE._TITLE_'
                    ),
                    subtitle: $translate.instant('PIPELINE.ACTIONS_POPOVER.STAGE._SUBTITLE_'),
                    ctaText: $translate.instant('PIPELINE.ACTIONS_POPOVER.STAGE._CTA_'),
                    learnMoreLink: "https://help.honeybook.com/en/articles/2209123-understanding-the-project-pipeline",
                    ctaCallback: this.isBulk ? 
                        this.onBulkMove.bind(this) : 
                        this.onMoveStage.bind(this)
                }, 
                archive: {
                    title: $translate.instant(
                        this.isBulk ? 
                            'PIPELINE.ACTIONS_POPOVER.ARCHIVE._TITLE_BULK_' :
                            'PIPELINE.ACTIONS_POPOVER.ARCHIVE._TITLE_'
                    ),
                    subtitle: $translate.instant('PIPELINE.ACTIONS_POPOVER.ARCHIVE._SUBTITLE_'),
                    explanation: $translate.instant('PIPELINE.ACTIONS_POPOVER.ARCHIVE._EXPLANATION_', {
                        plural: this.isBulk ? 's' : ''
                    }),
                    ctaText: $translate.instant('PIPELINE.ACTIONS_POPOVER.ARCHIVE._CTA_'),
                    learnMoreLink: "https://help.honeybook.com/en/articles/2209125-deleting-or-archiving-a-project",
                    ctaCallback: this.isBulk ?
                        this.onBulkArchive.bind(this) :
                        this.onArchive.bind(this)
                }
            };

            this.props = this.content[this.actionType || Enums.pipelineActionsPopoverTypes.stage];
            this.archiveReasons = Constants.BlockReasons;

            if (this.actionType === Enums.pipelineActionsPopoverTypes.stage) {
                this.stageDropdownOptions =  this.prepareStageDropdownOptions();

                // assign selectedStage
                if (this.workspace && this.workspace.active_state) {
                    this.selectedStage = this.stageDropdownOptions.find(
                        opt => opt._id === this.workspace
                                               .workspace_pipeline_data
                                               .current_stage
                                               .stage_id
                    );
                } else {
                    this.selectedStage = null;
                }
            }
        }

        onCtaClick(event) {
            this.isBtnClicked = true;
            this.props.ctaCallback();
            event.stopPropagation();
        }

        onArchiveReasonChange(item) {
            this.selectedArchiveReason = item;
        }

        onStageChange(item) {
            this.selectedStage = item;
        }

        onArchive() {
            this.requestInProgress = true;
            const trackingData = {
                workspace_id: this.workspace._id,
                archiveReason: this.selectedArchiveReason.serverValue,
                notifying_members: false
            };

            return this.WorkspacesManager.blockWorkspace(
                this.workspace,
                this.selectedArchiveReason.serverValue
            )
            .then(() => {
                this.AnalyticsService.trackSuccess(
                    this,
                    this.AnalyticsService.analytics_events.workspace_block, 
                    trackingData
                );
            })
            .catch((resp) => {
                this.AnalyticsService.trackError(
                    this, 
                    this.AnalyticsService.analytics_events.workspace_block, 
                    resp, 
                    trackingData
                );
            })
            .finally(() => {
                this.requestInProgress = false;
                if(angular.isFunction(this.onCtaDoneCallback)) {
                    this.onCtaDoneCallback();
                }
            });
        }

        onBulkArchive() {
            if (angular.isFunction(this.onCtaDoneCallback)) {
                this.onCtaDoneCallback({
                    blockReason: this.selectedArchiveReason ?
                     this.selectedArchiveReason.serverValue :
                     null
                });
            }
        }

        onMoveStage() {
            this.AnalyticsService.trackClick(
                this,
                "pipeline_view: Move to stage"
            );
            
            const currentStageId = this.workspace.workspace_pipeline_data.current_stage.stage_id;
            const stageId = this.selectedStage._id;

            if (currentStageId === stageId && this.workspace.active_state) {
                return this.onCtaDoneCallback && this.onCtaDoneCallback();
            }

            this.requestInProgress = true;
            const trackingDisabled = this.workspace.workspace_pipeline_data.disable_tracking;

            const trackingData = {
                workspace_id: this.workspace._id,
                moveToStage: stageId
            };

            this.WorkspacesManager.updateWorkspaceStage(
                this.workspace,
                stageId,
                trackingDisabled ? false : null
            ).then(() => {
                this.AnalyticsService.track(
                    this, this.AnalyticsService.analytics_events.workspace_pipeline_move_to_stage + ' Successful pipeline_quick_action',
                    trackingData
                );
                this.EventsManager.getEventsMasterCollection().trigger("workspaceStatusUpdated");
            }).catch((res) => {
                this.AnalyticsService.track(
                    this, 
                    this.AnalyticsService.analytics_events.workspace_pipeline_move_to_stage + ' Failed pipeline_quick_action',
                    trackingData
                );

                if (res && res.data && res.data.error_message) {
                    this.PopupMessageService.showAlert(
                        this.PopupMessageService.severityTypes.error,
                        res.data.error_message
                    );
                } else {
                    this.PopupMessageService.showAlert(
                        this.PopupMessageService.severityTypes.error,
                        'ERRORS.SERVER_API._UNKNOWN_'
                    );
                }
            }).finally(() => {
                this.requestInProgress = false;
                this.onCtaDoneCallback && this.onCtaDoneCallback();
            });

        }

        onBulkMove() {
            if (angular.isFunction(this.onCtaDoneCallback)) {
                this.onCtaDoneCallback({
                    stageId: this.selectedStage ?
                     this.selectedStage._id :
                     null
                });
            }
        }

        isCtaDisabled() {
          let isCtaDisabled;
            switch (this.actionType) {
                case this.Enums.pipelineActionsPopoverTypes.archive:
                  isCtaDisabled = !this.selectedArchiveReason;
                  break;
                case this.Enums.pipelineActionsPopoverTypes.stage:
                  isCtaDisabled = !this.selectedStage;
                  break;
                default:
                  isCtaDisabled = true;
            }

          return this.isBtnClicked || isCtaDisabled;
        }

        prepareStageDropdownOptions() {
            return this.company.user_pipeline_stages
                .map(stage => {
                    const originalName = stage.originalName || stage.name;

                    return Object.assign({}, stage, {
                        originalName,
                        name: this.WorkspaceService.getPipelineStageName(originalName)
                    });
                })
                .sort(stage => stage.order || 0);
        }
    }

    Components.PipelineActionsPopover = {
        bindings: {
            actionType: "@",
            workspace: '=',
            company: '=',
            onCtaDoneCallback: '&',
            isVisible: '=',
            isBulk: '='
        },
        controller: PipelineActionsPopoverController,
        templateUrl: 'angular/app/modules/core/features/pipeline/pipeline_actions_popover/pipeline_actions_popover_template.html',
    };

}());
