(function () {
    'use strict';

    // @ngInject
    Services.ClientsApiSeparationInterceptor = function ClientsApiSeparationInterceptor($window, Gon) {

        return {
            'request': config => {
                if ((!Gon.api_urls_prefix || Gon.api_urls_prefix && config.url.startsWith(Gon.api_urls_prefix)) &&
                    $window.isClientPortal()) {
                    config.url = config.url.replace(/\/api\/v2\/(.*)/, function (match, $1) {
                        return `/api/v2/client/${$1}`
                    });
                }

                return config;
            }
        };
    };
}());