(function () {
    "use strict";

    // @ngInject
    Controllers.TemplateSaveAsController = function TemplateSaveAsController(
        $q,
        $timeout,
        $modalInstance,
        titleWrapper,
        modalHeader,
        preventEmptyTitle,
        onSuccess,
        onFailure
    ) {
        this.newTemplateName = titleWrapper.title;
        this.preventEmptyTitle = !!preventEmptyTitle;

        this.modalHeader = modalHeader || 'COMPANY_SETTING.SAVEAS_MODAL._HEADER_LABEL_';

        this.loading = false;

        this.save = function close() {

            if (this.inProgress()) {
                return;
            }

            this.errorMsg = '';

            if(this.preventEmptyTitle && (!this.newTemplateName || this.newTemplateName === '')) {
                this.errorMsg = "Template name can't be empty";
                return;
            }

            this.loading = true;

            titleWrapper.title = this.newTemplateName;

            if (angular.isFunction(onSuccess)) {
                return onSuccess().then(function(resp) {
                    this.showSuccess = true;
                    $timeout(function() {
                        $modalInstance.close(resp);
                    }, 1000);
                }.bind(this)).finally(function final() {
                    this.loading = false;
                }.bind(this));
            }

            return $modalInstance.close();
        };

        this.cancel = function cancel() {

            if (this.inProgress()) {
                return;
            }

            if (angular.isFunction(onFailure)) {
                return $q.when(onFailure()).then(function(resp) {
                   $modalInstance.dismiss(resp);
                });
            }
            return $modalInstance.dismiss();
        };

        this.inProgress = function inProgress() {
            return this.loading || this.showSuccess;
        };
    };

}());
