(function () {
    "use strict";

    // @ngInject
    function LinksServiceCtor(AppStates) {
        this.AppStates = AppStates;

        this.serverStateKeyToClientState = {
            'account_settings': this.AppStates.root_core_navigation_settings_account,
            'company_settings': this.AppStates.root_core_navigation_settings_companySettings_overview,
            'pipeline': this.AppStates.root_core_navigation_pipeline,
            'subscription': this.AppStates.root_core_navigation_settings_company_subscription,
            'account_setup': this.AppStates.root_core_accountSetup,
            'setup_guide': this.AppStates.root_core_navigation_onboardingSetupGuide,
            'network_edit_profile': this.AppStates.root_network_edit_profile,
            'network_create_project': this.AppStates.root_network_create_project,
            'templates': this.AppStates.root_core_navigation_templates,
            'company_branding': this.AppStates.root_core_navigation_settings_company_branding,
            'home': this.AppStates.root_core_navigation_home,
            'client_portal': this.AppStates.root_core_navigation_settings_company_client_portal,
            'automations': this.AppStates.root_core_navigation_automations

        };
    }

    Services.LinksService = Class(function () {


        return {

            constructor: LinksServiceCtor,

            getStateFromServerKey: function getStateFromServerKey(key) {
                return this.serverStateKeyToClientState[key] || null;
            }

        };
    });
}());
