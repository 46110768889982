//hb-empty-state-screen

(function () {
    'use strict';

    Directives.EmptyStateDirective = function EmptyStateDirective() {

        // @ngInject
        function EmptyStateDirectiveControllerCtor($scope, $injector, AnalyticsService) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'EmptyStateDirectiveController';
            this.AnalyticsService = AnalyticsService;
        }


        var EmptyStateDirectiveController = Class(Controllers.BaseController, {
            constructor: EmptyStateDirectiveControllerCtor,

            primaryCtaHandler: function primaryCtaHandler() {
                if (this.primaryCtaAnalyticsData && this.primaryCtaAnalyticsData.eventName) {
                    this.AnalyticsService.track(this, this.primaryCtaAnalyticsData.eventName, this.primaryCtaAnalyticsData.data);
                }

                this.ctaCallback();
            },

            secondaryCtaHandler: function secondaryCtaHandler() {
                if (this.secondaryCtaAnalyticsData && this.secondaryCtaAnalyticsData.eventName) {
                    this.AnalyticsService.track(this, this.secondaryCtaAnalyticsData.eventName, this.secondaryCtaAnalyticsData.data);
                }
                this.secondaryBtnCallback();
            },

            textInlineCtaHandler: function textInlineCtaHandler() {
                if (this.textInlineCtaAnalyticsData && this.textInlineCtaAnalyticsData.eventName) {
                    this.AnalyticsService.track(this, this.textInlineCtaAnalyticsData.eventName, this.textInlineCtaAnalyticsData.data);
                }
                this.textInlineCtaCallback();
            },

            getCtaClass: function getCtaClass() {
                return this.ctaClass || 'nx-button';
            }
        });

        return {
            scope: {
                image: '@',
                iconClass: '@',
                title: '@',
                text: '@',
                ctaText: '@',
                ctaCallback: '&',
                secondaryBtnText: '@',
                secondaryBtnCallback:'&',
                textInlineCta: '@',
                textInlineCtaCallback: '&',
                className: "@",
                primaryCtaAnalyticsData: "=",
                secondaryCtaAnalyticsData: "=",
                textInlineCtaAnalyticsData: "=",
                ctaClass: '@',
                footer: '@'
            },
            templateUrl: 'angular/app/modules/common/ui_components/empty_state_screen/empty_state_screen_template.html',
            controller: EmptyStateDirectiveController,
            controllerAs: 'emptyStateVm',
            bindToController: true
        };
    };


}());