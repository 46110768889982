(function () {
    "use strict";

    // @ngInject
    function PackageTemplateComponentControllerCtor($scope, $injector, $q, CompaniesManager, _) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PackageTemplateDirectiveController';

        this.$q = $q;
        this._ = _;

        this.company = CompaniesManager.getCurrCompany();
        this.isEditable = true;
    }

    var PackageTemplateComponentController = Class(Controllers.BaseController, {
        constructor: PackageTemplateComponentControllerCtor,

        togglePackageTax: function togglePackageTax() {
            var allItemsTaxed = this._.all(this.packageModel.base_services, function(service) { return service.taxable; });
            return this.packageModel.togglePackageTax(!allItemsTaxed);
        },

        togglePackageSvc: function togglePackageSvc() {
            var allItemsWithSvc = this._.all(this.packageModel.base_services, function(service) { return service.svcable; });
            return this.packageModel.togglePackageSvc(!allItemsWithSvc);
        }
    });

    Components.PackageTemplateComponent = {
        bindings: {
            packageModel: '=',
        },
        controller: PackageTemplateComponentController,
        controllerAs: 'packageTemplateVm',
        name: 'hbPackageTemplate',
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/full_page_editor/package_template/package_template_template.html'
    };

}());