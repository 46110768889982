
(function () {
    'use strict';

    Directives.BannerDirective = function BannerDirective() {

        // @ngInject
        function BannerDirectiveControllerFunc($scope, $injector, $timeout, _) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'BannerDirectiveController';
            this._ = _;

            this.title = this.title || 'Banner title';
            this.message = this.message || 'This is the banner message.';

            this.classNames = this.generateClassNames(this.type);

        }

        var BannerController = Class(Controllers.BaseController, {
            constructor: BannerDirectiveControllerFunc,

            generateClassNames: function generateClassNames(classString) {

                // "apple" -> "nx-banner--apple"
                // "apple orange" -> "nx-banner--apple nx-banner--orange"

                if (!classString) {
                    return;
                }

                var classArray = classString.split(" ");

                return this._.map( classArray, function(theClass) { 
                    return "nx-banner--" + theClass;
                }).join(" ");

            },

        });

        return {
            scope: {
                title: '@title',
                message: '@message',
                type: '@bannerType',
            },
            transclude: {
                bannerTitle: '?bannerTitle',
                bannerMessage: '?bannerMessage',
                bannerClose: '?bannerClose',
                bannerGraphic: '?bannerGraphic'
            },
            templateUrl: 'angular/app/modules/common/ui_components/banner/banner_directive_template.html',
            controller: BannerController,
            controllerAs: 'bannerVm',
            bindToController: true
        };
    };

})();
