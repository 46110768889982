(function () {
    'use strict';

    // @ngInject
    function TemplatesFirstLevelNavControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, AppStates, ModalService, UsersManager, NavigationService, FeaturesService, ContactFormService,
        AppConfigService, DeviceService, UiPersistenceService, PopupMessageService, $translate, moment, AbTestService, _, $timeout, ngToast, Enums, QuestionsStateService, FlowsBetaUserTypeService, ReactModalService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TemplatesFirstLevelNavController';

        this._ = _;
        this.AnalyticsService = AnalyticsService;
        this.UiPersistenceService = UiPersistenceService;
        this.PopupMessageService = PopupMessageService;
        this.$translate = $translate;
        this.moment = moment;
        this.AbTestService = AbTestService;
        this.$timeout = $timeout;
        this.AppStates = AppStates;
        this.TemplatesViewService = TemplatesViewService;
        this.disableTitleUpdate = false;
        this.ModalService = ModalService;
        this.NavigationService = NavigationService;
        this.FeaturesService = FeaturesService;
        this.DeviceService = DeviceService;
        this.ngToast = ngToast;
        this.Enums = Enums;
        this.QuestionsStateService = QuestionsStateService;
        this.ContactFormService = ContactFormService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.ReactModalService = ReactModalService;
        this.user = UsersManager.getCurrUser();
        this.initTemplateHeader();

        if (this.TemplatesViewService.shouldShowNewContactForm()) {
            this.initNewContactFormHeader();
        }

        this.shouldShowActivationToggle = this.TemplatesViewService.currentModel.name === "gift card" &&
            !DeviceService.nxSmallBreakpoint();

        this.onActivationChange = function onActivationChange(e) {
            e.preventDefault();
            this.ContactFormService.handleToggleActivation(this.TemplatesViewService.currentTemplate)
        }

        this.getPublicUrl = function getPublicUrl() {
            return this.ContactFormService.getPublicUrl(this.TemplatesViewService.currentTemplate);
        }

        $scope.$watch('templatesTopNavVm.TemplatesViewService.shouldShowNewContactForm()', function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.initNewContactFormHeader();
            }
        }.bind(this))
    }

    Controllers.TemplatesFirstLevelNavController = Class(Controllers.BaseController, {
        constructor: TemplatesFirstLevelNavControllerCtor,

        initTemplateHeader: function initTemplateHeader() {

            switch (this.TemplatesViewService.currentModel.name) {
                case 'package':
                    this.templateTypeCollection = this.TemplatesViewService.company.vendor_packages;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_PACKAGES_';
                    break;

                case 'agreement':
                    this.templateTypeCollection = this.TemplatesViewService.company.agreements;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_CONTRACT_';
                    break;

                case 'questionnaire':
                    this.templateTypeCollection = this.TemplatesViewService.company.questionnaires;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_QUESTIONNAIRE_';
                    break;

                case 'brochure':
                    this.templateTypeCollection = this.TemplatesViewService.company.getCompanyBrochures(true);
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_BROCHURE_';
                    break;

                case 'email':
                    this.templateTypeCollection = this.TemplatesViewService.company.vendor_emails;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_EMAIL_';
                    break;

                case 'email signature':
                    this.templateTypeCollection = this.TemplatesViewService.user.email_signatures;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_EMAIL_SIGNATURE_';
                    break;

                case 'invoice':
                    this.templateTypeCollection = this.TemplatesViewService.company.invoices;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_INVOICE_';
                    break;

                case 'proposal':
                    this.templateTypeCollection = this.TemplatesViewService.company.proposals;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_PROPOSAL_';
                    break;

                case 'contact form':
                    this.templateTypeCollection = this.TemplatesViewService.company.contact_forms;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_CONTACT_FORM_';
                    break;

                case 'gift card':
                    this.templateTypeCollection = this.TemplatesViewService.company.contact_forms;
                    this.templateTypeLabel = 'COMPANY_SETTING.TEMPLATES._TEMPLATES_GIFT_CARD_';
                    break;
            }
        },

        _checkPreviousState: function _checkPreviousState(routeName) {
            if (this.$state.previous) {
                return this.$state.previous.route.name === routeName;
            }

            return false;
        },

        backButtonLink: function backButtonLink() {
            this.NavigationService.goHistoryBack();
        },

        createfileFromTemplate: function createfileFromTemplate() {
            if (!this.user.account.card_on_file_enabled && this.TemplatesViewService.currentTemplate.payment_type === "recurring") {
                this.ReactModalService.openEnableCardOnFileModal('recurring');
                return;
            }
            this.ModalService.openStartFromFileWizardModal({
                type: this.TemplatesViewService.currentModel.name,
                create_file_from_templates: true,
                template_id: this.TemplatesViewService.currentTemplate._id,
                source: 'template editor'
            });
        },

        /// New contact form Start
        initNewContactFormHeader: function init() {
            this.actionButtonText = "";
            this.actionButton = "";
            this.isActionRunning = false;
            this.gettingPreviewCode = false;
        },

        runAction: function runAction() {
            if (this.TemplatesViewService.currentModel.name === "gift card") {
                this.TemplatesViewService.currentTemplate.setGiftCardState();
                this.AnalyticsService.trackClick(this, "Gift card publish changes");
                if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                    this.publishGiftCard();
                }
                return;
            }
            this.primaryCtaButtonType = this.TemplatesViewService.currentTemplate.getContactFormState();
            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                switch (this.primaryCtaButtonType) {
                    case 'add_to_web_site':
                        this.addToWebSiteClick();
                        this.AnalyticsService.trackClick(this, "add_to_web_site_contact_form_click");
                        break;
                    case 'embed_code':
                        this.openEmbedCodeModal();
                        this.AnalyticsService.trackClick(this, "embed_code_contact_form_click");
                        break;
                    case 'publish_changes':
                        this.publishChangesClick();
                        this.AnalyticsService.trackClick(this, "publish_changes_contact_form_click", { app_path: 'header' });
                        break;
                    default:
                        break;
                }
            }
        },

        openGiftCardDirectLinkModal: function openGiftCardDirectLinkModal() {
            this.ContactFormService.openGiftCardDirectLinkModal(this.TemplatesViewService.currentTemplate)
        },

        openEmbedCodeModal: function openEmbedCodeModal() {
            this.ContactFormService.openEmbedCodeModal(this.primaryCtaButtonType, this.TemplatesViewService.currentTemplate)
        },

        addToWebSiteClick: function addToWebSiteClick() {
            this.isActionRunning = true;

            this.ContactFormService.publish(false, true, this.TemplatesViewService.currentTemplate).then(function finishedAddToWebSite() {
                this.isActionRunning = false;
            }.bind(this)).catch(function () {
                this.isActionRunning = false;
            }.bind(this));
        },

        publishGiftCard: function publishGiftCard() {
            this.isActionRunning = true;
            this.ContactFormService.publishGiftCard(this.TemplatesViewService.currentTemplate).then(function finishedAddToWebSite() {
                this.isActionRunning = false;
            }.bind(this)).catch(function () {
                this.isActionRunning = false;
            }.bind(this));
        },

        dismissPostTourTooltip: function DismissPostTourTooltop() {
            this.AnalyticsService.trackClick(this, "new cf publish tooltip got it");
            this.UiPersistenceService.setUiPersistenceInnerKey(this.UiPersistenceService.keys.contactFormDynamicOnboarding, 'dismissedMigratedTooltip', true);
        },

        confirmPostTourTooltip: function confirmPostTourTooltip() {
            this.AnalyticsService.trackClick(this, "new cf publish tooltip get code");
            this.runAction()
        },

        isShowPostTourTooltip: function isShowPostTourTooltip() {
            return this.ContactFormService.getMigratedContactFormFlags().showTooltip;
        },

        publishChangesClick: function publishChangesClick() {
            this.openEmbedCodeModal();
        },

        previewContactForm: function previewContactForm() {
            if (this.TemplatesViewService.currentModel.name === "gift card") {
                this.AnalyticsService.trackClick(this, "preview_gift_card_click");
            } else {
                this.AnalyticsService.trackClick(this, "preview_contact_form_click");
            }

            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                this.gettingPreviewCode = true;
                this.ContactFormService.openContactFormPreview(this.TemplatesViewService.currentTemplate).then(function () { this.gettingPreviewCode = false }.bind(this));
            }

        },
        /// New contact form end

    });
}());
