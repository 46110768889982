(function () {
    "use strict";

    // @ngInject
    function FilesHubServiceCtor(StatsigService, Enums, UsersManager, DeviceService) {

        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.DeviceService = DeviceService;

        this.filesHubPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.filesHub,  'show_files_tab');

        this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
    }

    Services.FilesHubService = Class(() => {

        return {
            constructor: FilesHubServiceCtor,

            isFilesHubVisible() {

                return this.filesHubPromise.then((isVisible) => this.isDesktop && isVisible);
            }
        };
    });
}());