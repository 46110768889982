(function () {
    'use strict';
    Controllers.RateBookingController = class RateBookingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, $modalInstance, $translate, moment, _, AppStates, config,
                    CompaniesManager, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'RateBookingController';
            this.modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.AnalyticsService = AnalyticsService;
            this.config = config;

            this.ratingGrade = this.config.ratingGrade;
            this.vendorFirstName = this.config.vendorFirstName;
            this.eventId = this.config.eventId;
            this.company = this.config.company;
            this.subTitle = $translate.instant(
                'FILE.PAYMENT.RECEIPT.RATE_BOOKING._SUB_TITLE_', {vendor_name: this.vendorFirstName});
            this.feedbackPlaceholder = $translate.instant(
                'FILE.PAYMENT.RECEIPT.RATE_BOOKING._FEEDBACK_PLACEHOLDER_', {vendor_name: this.vendorFirstName});

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.feedback_modal);
        }

        setRatingGrade(value) {
            this.ratingGrade = value;
        }

        sendRating() {
            if (this.ratingComment && this.ratingComment.trim() === '') {
                this.ratingComment = undefined;
            }
            this.CompaniesManager.createBookingReview(this.company, this.eventId, this.ratingGrade, this.ratingComment);
            this.AnalyticsService.trackClick(this,this.AnalyticsService.analytics_events.send_feedback, {
                feedback_rank_stars: this.ratingGrade,
                feedback_free_text: this.ratingComment,
            });
            this.modalInstance.close();
        }
    };
}());
