(function () {
    "use strict";

    Controllers.MwebPreviewBrandingModalController = class MwebPreviewBrandingModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope
            ,$injector
            ,$modalInstance
            ,CompaniesManager
            ,AnalyticsService
        ) {
            super($scope, $injector);
            this.__objectType = 'MwebPreviewBrandingModalController';

            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.AnalyticsService = AnalyticsService;
            this.company = this.CompaniesManager.getCurrCompany();
        }

        handleClose() {
            this.AnalyticsService.trackClick(this, 'close preview branding');
            this.$modalInstance.close();
        }

    }
}());
