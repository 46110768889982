(function () {
    'use strict';

    class GiftCardController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $filter) {
            super($scope, $injector);
            this.__objectType = 'GiftCardController';

            this.$filter = $filter;
        }

		getDiscountText() {
        	//this.$filter('hbcurrency')(min_budget);
			if(this.discountType === 'absolute') {
				return `${this.$filter('hbcurrency')(this.discount, this.currency)} OFF`;
			} else {
				return `${this.discount}% OFF`;
			}
		}

		getAmountAfterDiscount() {

			if(!this.amount) {
				return 0;
			}

			if(!this.discount) {
				return this.amount;
			}

			if(this.discountType === 'absolute') {
				return this.amount - this.discount;
			} else {
				return this.amount - (this.amount * this.discount / 100);
			}
		}
    }

    Components.GiftCardComponent = {
        bindings: {
        	text: '<',
			amount: '<',
            currency: '<',
			image: '<',
			textStyle: '<',
			discount: '<',
			discountType: '<',
        },
        transclude: true,
        controller: GiftCardController,
        name: 'hbGiftCard',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/promotion_offer/gift_card_component.html',
    };

}());