(function () {
    "use strict";

    // @ngInject
    function BlockLeftAlignedControllerFunc($injector, $scope, $sce, AnalyticsService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockLeftAlignedDirectiveController';

        this.$sce = $sce;
        this.UIUtils = UIUtils;
        this.AnalyticsService = AnalyticsService;

        this._init();
    }

    var BlockLeftAlignedDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockLeftAlignedControllerFunc,

        _init: function _init() {
            var color = this.block.file_block_style ? this.block.file_block_style.background_color : '#ffffff';
            this.setBlockBackground(color);
        },

        updateBlock: function updateBlock(field) {
            this.block.updateBlock([field]);
        },

        updateBlockContent: function updateBlock() {
            this.updateBlock('content');
            this.sendSavedAnalytics('content');
        },

        updateBlockBackground: function updateBlockBackground() {
            this.updateBlock('file_block_style');
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        focusContent: function focusContent(isFocus) {
            this.isContentFocus = isFocus;
        },

        /* Color picker */
        onBackgroundChange: function onBackgroundChange(color) {
            this.setBlockBackground(color);
            this.updateBlockBackground();
            this.sendSavedAnalytics('background color');
        },

        setBlockBackground: function setBlockBackground(color) {
            this.styles = {
                'backgroundColor': color
            };
        },

        onPickerShown: function onPickerShown(color) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.showed_block_color_picker, {block_type: "text and image"});
        },

        saveImage: function saveImage(imageModel) {
            this.block.image = imageModel;
            this.block.updateBlock(['image']);
            this.sendSavedAnalytics('image');
        },

        sendSavedAnalytics: function sendSavedAnalytics(field) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: field, block_type: "text and image"});
        }

    });

    Components.BlockLeftAligned = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_left_aligned/block_left_aligned.html',
        controller: BlockLeftAlignedDirectiveController,
        controllerAs: 'blockLeftAlignedVm',
        name: 'hbBlockLeftAligned'
    };

}());
