(function () {
    'use strict';

    // @ngInject
    Controllers.UnsentFileController = function UnsentFileController($modalInstance, fileType, fileHasPayment) {

        this.getFileType = function getFileType() {
            return fileType;
        };

        this.exit = function exit() {
            $modalInstance.close(false);
        };

        this.shouldShowResendFile = function shouldShowResendFile(){
            return fileHasPayment;
        };

        this.cancel = function cancel() {
            $modalInstance.dismiss('cancel');
        };

        this.resendFile = function resendFile() {
            $modalInstance.close(true);
        };

    };
}());



