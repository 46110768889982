(function () {
    "use strict";

    Controllers.CreatedSessionModalController = class CreatedSessionModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, SchedulingService, $timeout, $modalInstance, session, company) {
            super($scope, $injector);
            this.__objectType = 'CreatedSessionModalController';

            this.AnalyticsService = AnalyticsService;
            this.SchedulingService = SchedulingService;
            this.$timeout = $timeout;
            this.$modalInstance = $modalInstance;
            this.session = session;
            this.company = company;

            this.sessionUrl = this.session.session_url;
        }

        copy() {
            this.linksIsCopied = true;

            this.$timeout(() => {
                this.linksIsCopied = false;
            }, 2500);
        }

        close() {
            this.$modalInstance.close();
        }
    };

}());
