(function () {
    'use strict';

    Controllers.MwebBlockModalController = class MwebBlockModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            AnalyticsService,
            UsersManager,
            $modalInstance

    ) {
            super($scope, $injector);
            this.__objectType = 'MwebBlockModalController';
            this.$modalInstance = $modalInstance;
            this.user = UsersManager.getCurrUser();
            this.user.on('success', this.onUserUpdate, this);

            AnalyticsService.trackLoad(
                this,
                AnalyticsService.analytics_events.mweb_block_modal
            );

        }

        onUserUpdate() {
            if(this.user.has_logged_in_via_desktop) {
                this.$modalInstance.close();
            }
        }

    }

}());