(function () {
    "use strict";

    Controllers.ClientPortalSendLinkController = class ClientPortalSendLinkController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, _, $q, $modalInstance, ModalService, $translate, AnalyticsService, UsersManager, CompaniesManager, PopupMessageService, CompanyService,
                    UserService, $timeout, workspaceClients, clientPortalUrl, UiPersistenceService, DeviceService, ContactsManager, ModelFactory, WorkspaceFileService, $stateParams, WorkspacesManager) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalSendLinkController';
            this.$q = $q;
            this._ = _;
            this.$timeout = $timeout;
            this.$modalInstance = $modalInstance;
            this.ModalService = ModalService;
            this.$translate = $translate;
            this.currentUser = UsersManager.getCurrUser();
            this.UserService = UserService;
            this.UsersManager = UsersManager;
            this.DeviceService = DeviceService;
            this.UiPersistenceService = UiPersistenceService;
            this.ContactsManager = ContactsManager;
            this.PopupMessageService = PopupMessageService;
            this.ModelFactory = ModelFactory;
            this.contacts = ModelFactory.newContactsCollection();
            this.AnalyticsService = AnalyticsService;
            this.CompaniesManager = CompaniesManager;
            this.WorkspaceFileService = WorkspaceFileService;
            this.company = CompaniesManager.getCurrCompany();

            this.workspaceClients = workspaceClients || false;
            this.recipients = [];
            this.$stateParams = $stateParams;
            this.WorkspacesManager = WorkspacesManager;
            this.toggleHideCPInvite = !this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showInviteModalActiveProject, {show: true}).show;
            if (!this.workspaceClients) {
                this._initSettingsFlow();
            } else {
                if (clientPortalUrl === true) {
                    this.showDontShowAgain = true;
                    this.clientPortalUrl = 'https://' + CompanyService.companyProfileUrl(this.company);
                } else {
                    this.clientPortalUrl = clientPortalUrl ? 'https://' + clientPortalUrl : '';
                }
            }

            this.source = !this.workspaceClients ? 'settings' : 'workspace';
            this.isWorkspaceContext = (Boolean(this.workspaceClients));
            this.workspaceId = this.$stateParams.workspace_id;
            this.workspace = this.isWorkspaceContext && this.WorkspacesManager.getWorkspace(this.workspaceId, false, false);
        };

        onDismiss() {
            this.$modalInstance.dismiss();
        }

        onClose() {
            this.$modalInstance.close();
        }

        _initSettingsFlow(){
            this._focusInputOnLoad();
        }

        _filterData(contacts){
           return this._.map(contacts, (contact) => {
             return { name: contact.user.full_name, text: contact.user.email};
           });
        }

        _focusInputOnLoad(){
            this.$timeout(() => angular.element('.tags').find('input').select());
        }

        onTagAdded($tag) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.add_recipient_client_portal_link, {
                source: "client portal send link modal"
            });
        }

        toggleShowAgainUI(){
            this.toggleHideCPInvite = !this.toggleHideCPInvite;
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.hide_invite_modal_active_project, {value: this.toggleHideCPInvite});
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showInviteModalActiveProject, {show: this.toggleHideCPInvite});
        }

        onSelectedWorkspaceClient(client){
            var idx = this.recipients.indexOf(client);

            if (idx > -1) {
                this.recipients.splice(idx, 1);
            } else {
                this.recipients.push(client);
            }
        }

        _clientPortalEmail(){
            const body = this.$translate.instant('CLIENT_PORTAL.SEND_LINK._EMAIL_BODY_');
            const link = `<a target="_blank" rel="noopener noreferrer" href="clientPortalLinkPlaceholder">${this.$translate.instant('CLIENT_PORTAL.SEND_LINK._CLIENT_PORTAL_')}</a>`;

            return {
                subject: 'Client portal login',
                html_body: `<br>Hello <span class="hb-smart-value" data-field="{CLIENT.FIRST.FIRST_NAME" data-field-key="CLIENT.FIRST.FIRST_NAME" data-is-available="undefined" data-is-valid="undefined">{ FIRST CLIENT FIRST NAME }</span>,<br><br>${body}<br><br>${link}<br><br>Warmly,<br>${this.currentUser.full_name}`
            };
        }

        _cleanData(contacts){
            return this._.map(contacts, (contact) => {
                return { full_name: contact.name, email: contact.text};
            });
        }

        fetchSuggestions($query) {
            var defer = this.$q.defer();

            this.ContactsManager.getUserContacts(this.contacts, this.currentUser, 1, {has_project: true, text: $query}, 20)
                .then((resp) => {
                    let result = this._filterData(resp.data.data);
                    defer.resolve(result);
                })
                .catch((resp) => {
                    defer.resolve([]);
                });

            return defer.promise;
        }


        previewClientPortalSendLinkEmail() {
            this.sendingLink = true;
            const email = this._clientPortalEmail();
            const config = { showFromLine: true,
                showBccMyselfCheckbox: false,
                editorType: this.workspace ? "client portal link" : 'settings client portal link',
                ckeditor : {includeToolbarItems:['AddClientName', 'AddEmailSignature']},
                workspace: this.workspace
                };

            let recipients = this.workspaceClients ? this.recipients : this._cleanData(this.recipients);

            var options  = {
                users: recipients,
                subject: email.subject,
                body: email.html_body,
                generalFiles: email.file_attachments,
                imageFiles: email.image_attachments,
                senderFunction: this._sendLink.bind(this),
                config: config,
                showScheduleOption: false
            };

            this.WorkspaceFileService.showEmailEditorModal(options).then(() => {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.send_client_portal_link, {source: this.source});
                })
                .catch((res) => {
                    if (res && res.data && res.data.error_message) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, res.data.error_message);
                    }
                })
                .finally(() => {
                    this.$modalInstance.close();
                }); 
        }

        _sendLink(props) {
            var user = props.users;
            var subject = props.subject;
            var body = props.body;

            return this.CompaniesManager.sendClientPortalLink(user, subject, body, this.workspaceId)
                .then(() => this.isWorkspaceContext && this.WorkspacesManager.getFeedForWorkspace(this.workspace, this.workspaceId));
        }

    };


}());




