/**
 * Created by dvircohen on 12/07/2017.
 */

// @ngInject
Classes.AgreementValuesTemplateStrategy = function(moment, AgreementValuesStrategy) {

    var AgreementValuesTemplateStrategy = Class(AgreementValuesStrategy, {

        constructor: function AgreementValuesTemplateStrategyCtor(agreementParent) {
            this.agreementParent = agreementParent;
            this.agreementParentPresent = this.agreementParent !== null && this.agreementParent !== undefined;
        },

        // ============================================
        // Proposal fields
        // ============================================

        getProposalTotalPrice: function getProposalTotalPrice() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.total_price) || '';
        },

        getProposalSubTotalPrice: function getProposalSubTotalPrice() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.sub_total) || '';
        },

        getProposalTax: function getProposalTax() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.total_tax) || '';
        },

        // ============================================
        // Payments fields
        // ============================================

        getFirstPaymentAmount: function getFirstPaymentAmount() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[0] && this.formatMoney(this.agreementParent.payments_container.payments[0].amount) || '';
        },

        getFirstPaymentDueDate: function getFirstPaymentDueDate() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[0] && this.formatDate(this.agreementParent.payments_container.payments[0].due_date) || '';
        },

        getSecondPaymentAmount: function getSecondPaymentAmount() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[1] && this.formatMoney(this.agreementParent.payments_container.payments[1].amount) || '';
        },

        getSecondPaymentDueDate: function getSecondPaymentDueDate() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[1] && this.formatDate(this.agreementParent.payments_container.payments[1].due_date) || '';
        },

        getLastPaymentAmount: function getLastPaymentAmount() {
            var lastPaymentIdx = this.agreementParent.payments_container.payments.length - 1;
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[lastPaymentIdx] && this.formatMoney(this.agreementParent.payments_container.payments[lastPaymentIdx].amount) || '';
        },

        getLastPaymentDueDate: function getLastPaymentDueDate() {
            var lastPaymentIdx = this.agreementParent.payments_container.payments.length - 1;
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[lastPaymentIdx] && this.formatDate(this.agreementParent.payments_container.payments[lastPaymentIdx].due_date) || '';
        },

        // ============================================
        // Agreement fields
        // ============================================

        getAgreementDate: function getAgreementDate() {
            return moment().format('MMM DD, YYYY');
        },

        // ============================================
        // Company fields
        // ============================================

        getCompanyTax: function getCompanyTax() {
            return this.agreementParentPresent && this.agreementParent.getCompany() && this.agreementParent.getCompany().default_tax || '';
        },

        getCompanyName: function getCompanyName() {
            return this.agreementParentPresent && this.agreementParent.getCompany() && this.agreementParent.getCompany().company_name || '';
        },

        getCompanyType: function getCompanyType() {
            return this.agreementParentPresent && this.agreementParent.getCompany() && this.agreementParent.getCompany().company_type_name || '';
        },

        getCompanyEmail: function getCompanyEmail() {
            return this.agreementParentPresent && this.agreementParent.getCompany() && this.agreementParent.getCompany().email || '';
        },

        getCompanyPhoneNumber: function getCompanyPhoneNumber() {
            return this.agreementParentPresent && this.agreementParent.getCompany() && this.agreementParent.getCompany().phone_number || '';
        }
    });

    return AgreementValuesTemplateStrategy;
};