// @ngInject

Models.TimelineItemModel = function TimelineItemModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'TimelineItemModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
            copy.__section = undefined;
            copy.__timeline_container = undefined;
        },

        updateTimelineItem: function updateTimelineItem() {
            return this.__parent.updateTimelineItem(this);
        },

//        getAnalyticsArgs: function getAnalyticsArgs() {
//            var parentAnalyticsArgs = this.__parent.getAnalyticsArgs();
//            var analyticsArgs = {
//                service_id: this.id()
//            };
//
//            for (var property in parentAnalyticsArgs) {
//                if (parentAnalyticsArgs.hasOwnProperty(property)) {
//                    analyticsArgs[property] = parentAnalyticsArgs[property];
//                }
//            }
//
//            return analyticsArgs;
//        },

        deleteTimelineItem: function deleteTimelineItem() {
            return this.__parent.deleteTimelineItem(this);
        }

    });

};
