(function () {
    "use strict";

    Controllers.PayController = class PayController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $stateParams, Gon) {
            super($scope, $injector);
            this.__objectType = 'PayController';
            this.hb_token = $stateParams.hb_token;
            this.base_url = Gon.api_urls_prefix;
            this.stripe_key = Gon.stripe_key;
        }




    };

}());

