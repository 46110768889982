Components.PipelineOnboarding = {
    bindings: {
        onCloseOnboarding: '&',
        handleClickCustomize: '&'
    },
    // @ngInject
    controller: function PipelineOnboardingController(
        $scope, 
        $element, 
        $attrs, 
        $timeout, 
        $, 
        $sce, 
        $translate,
        AnalyticsService,
        UsersManager,
        ModalService,
        Modernizr
        ) {

        this.$onInit = function $onInit() {
            this.$ = $;
            this.$sce = $sce;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;

            this.steps = [
                [
                    {
                        title: $translate.instant('PIPELINE_TOUR.STEP_1._TITLE_'),
                        body: $translate.instant('PIPELINE_TOUR.STEP_1._BODY_'),
                        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_one.html',
                        mp4TemplateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_one_safari.html',
                        onStepStartFunc: this.step1VideoSequencer.bind(this)
                    },
                ],
                [
                    {
                        title: $translate.instant('PIPELINE_TOUR.STEP_2._TITLE_'),
                        body: $translate.instant('PIPELINE_TOUR.STEP_2._BODY_'),
                        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_two.html',
                    },
                ],
                [
                    {
                        title: $translate.instant('PIPELINE_TOUR.STEP_3._TITLE_'),
                        body: $translate.instant('PIPELINE_TOUR.STEP_3._BODY_'),
                        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_three.html'
                    },
                ],
                [
                    {
                        title: $translate.instant('PIPELINE_TOUR.STEP_4._TITLE_'),
                        body: $translate.instant('PIPELINE_TOUR.STEP_4._BODY_'),
                        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_four.html'
                    },
                    {
                        title: $translate.instant('PIPELINE_TOUR.FINAL._TITLE_'),
                        body: $translate.instant('PIPELINE_TOUR.FINAL._BODY_'),
                        templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/step_five.html'
                    }
                ]
            ];

            this.stepsCount = this.steps.length;

            this.tourFinished = false;

            this.currentStep = 0;
            this.currentStage = 0;
            this.navButtonsEnabled = false;

            this.currUser = UsersManager.getCurrUser();
            this.isCompanyOwner = this.currUser.is_company_owner;

            this.isWebmSupported = Modernizr.video.webm;

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.pipeline_tour);
        };

        this.$postLink = function $postLink() {
            $timeout(() => {
                this.navButtonsEnabled = true;
            }, 500);

            this.nextStep();
        };

        this.step1VideoSequencer = function step1VideoSequencer() {

            // $timeout to give openModalPromise time to resolve
            // before we search the DOM for these video elements
            $timeout(() => {

                this.video1 = document.getElementById('pipelineOnboarding1');
                this.video2 = document.getElementById('pipelineOnboarding2');
                this.video3 = document.getElementById('pipelineOnboarding3');
                this.video4 = document.getElementById('pipelineOnboarding4');
                this.video5 = document.getElementById('pipelineOnboarding5');

                // currentVideo and nextVideo are HTML5 <video> elements
                const transitionBetweenVideos = (currentVideo, nextVideo) => {
                    currentVideo.classList.add('hidden');
                    nextVideo.classList.remove('hidden');
                    nextVideo.play();
                };

                this.video1 && this.video1.addEventListener('ended', () => transitionBetweenVideos(this.video1, this.video2));
                this.video2 && this.video2.addEventListener('ended', () => transitionBetweenVideos(this.video2, this.video3));
                this.video3 && this.video3.addEventListener('ended', () => transitionBetweenVideos(this.video3, this.video4));
                this.video4 && this.video4.addEventListener('ended', () => transitionBetweenVideos(this.video4, this.video5));

                this.video1 && this.video1.play();

            }, 500);
        };


        this.nextStep = function nextStep() {
            if (this.currentStep) {
                this.AnalyticsService.trackClick(
                    this, 
                    this.AnalyticsService.analytics_events.pipeline_tour_next, {
                        source: this.currentStep,
                    }
                );
            }

            this.currentStep++;
            this.currentStage = 0;

            if (this.currentStep >= this.stepsCount) {
                this.tourFinished = false;
                $timeout(()=> {
                    this.tourFinished = true;
                }, 2000);
            }

            this.onStepStart(this.currentStep);
        };

        this.nextStage = function nextStage() {
            this.currentStage++;
        };

        this.prevStep = function prevStep() {
            this.currentStep--;
            this.currentStage = 0;
            this.onStepStart(this.currentStep);
        };

        this.onStepStart = function onStepStart(step) {
            if (this.steps[step-1][0].onStepStartFunc) {
                this.steps[step-1][0].onStepStartFunc();
            }
        };

        this.openPipelineOnboardingVideoModal = function openPipelineOnboardingVideoModal () {
            let videoOptions = {
                modalOpenSource: 'Pipeline',
                videoTitle: '',
                videoLink: 'https://www.youtube.com/embed/75jW6WpLBNA?autoplay=1&mute=1&rel=0'
            };

            this.ModalService.openGenericVideoModal(videoOptions);
        };

    },

    name: 'pipelineOnboarding',
    templateUrl: 'angular/app/modules/common/ui_components/feature-onboarding/pipeline/pipeline_onboarding.html',
};
