/**
 * Created by sergeiton on 14/5/17.
 */
(function () {
    'use strict';

    // @ngInject
    Services.ForterHelperService = function ForterHelperService($window, $q, AppConfigService) {
        this.getForterToken = function getForterToken() {
            var fToken = $window.fToken;
            if (!fToken) {
                var value = "; " + document.cookie;
                var parts = value.split("; forterToken=");
                if (parts.length > 1) {
                    fToken = parts.pop().split(";").shift();
                }
            }
            return fToken;
        };
    };
}());