(function () {
    "use strict";

    // @ngInject
    function BlockTextImgControllerFunc($injector, $scope, $sce, AnalyticsService, ImageService, ModalService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockTextImgDirectiveController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
        this.ImageService = ImageService;
        this.ModalService = ModalService;
        this.UIUtils = UIUtils;

        this.toolbar = ['fontFamily', '|', 'fontSize', '|', 'bold', '|', 'underline', '|', 'italic', '|', 'hbfontColor', '|', 'link', '|', 'removeFormat', '|', 'undo'];


    }

    var BlockTextImgDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockTextImgControllerFunc,

        updateBlock: function updateBlock() {
            this.block.updateBlock(['content']);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'content', block_type: "text over image"});
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        saveImage: function saveImage(imageModel) {
            this.block.image = imageModel;
            this.block.updateBlock(['image']);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'image', block_type: "text over image"});
        },

        getImageUrl: function getImageUrl() {
            var imageModel = this.block.image;
            if (imageModel) {
                return this.ImageService.getHBImageCloudinaryUrl(imageModel);
            }
        },

        onChooseImage: function onChooseImage() {
            var imageModel = this.block.image;
            if (imageModel) {
                this.editImage(imageModel);
            } else {
                this.addImage(imageModel);
            }
        },

        addImage: function addImage() {
            this.changeImage('MEDIA_MODAL.TITLE._ADD_IMAGE_', '', 'Upload');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.add_block_image);
        },

        editImage: function editImage(imageModel) {
            this.changeImage('MEDIA_MODAL.TITLE._EDIT_IMAGE_', imageModel, 'Crop');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edit_block_image);
        },

        changeImage: function changeImage(modalTitle, modalImage, defaultTab) {
            return this.ModalService.openAttachAssetModal(true, true, true,
                defaultTab, false, false, modalImage, -1,
                false, false, 'image/*', '', '', modalTitle)
                .then(function success(image){
                    this.saveImage(angular.copy(image));
                }.bind(this))
                .finally(function () {
                }.bind(this));
        }
    });

    Components.BlockTextImg = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_text_img/block_text_img.html',
        controller: BlockTextImgDirectiveController,
        controllerAs: 'blockTextImgVm',
        name: 'hbBlockTextImg'
    };

}());



