
(function () {
    'use strict';

    // @ngInject
    function WorkspaceFileControllerCtor($scope, $stateParams, $injector, $q, $translate, $location, $filter, $timeout, DeviceService,
        UsersManager, AppStates, UserService, $state, ContactsManager, PreviewModeService,
        WorkspaceFilesManager, WorkspacesManager, CompaniesManager, CompanyService, OnboardingService,
        AutosizeTextareaUpdater, PopupMessageService, AnalyticsService, Enums, AbTestService,
        WorkspaceFileService, uuid4, ModalService, WorkspaceService, UiPersistenceService, UIUtils,
        $window, TemplatesViewService, TemplatesManager, WebsocketHelperService, _,
        OnboardingBotManager, ToastService, SuggestionsService, MobileAppService, InjectBrandingService, AccountsService, OtamStatusService, ReactModalService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileController';

        var self = this;

        this.$stateParams = $stateParams;
        this.OtamStatusService = OtamStatusService;
        this.AppStates = AppStates;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.UIUtils = UIUtils;
        this.$location = $location;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.DeviceService = DeviceService;
        this.$filter = $filter;
        this.$scope = $scope;
        this.$state = $state;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.UserService = UserService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.ModalService = ModalService;
        this.WorkspaceService = WorkspaceService;
        this.WorkspacesManager = WorkspacesManager;
        this.CompaniesManager = CompaniesManager;
        this.CompanyService = CompanyService;
        this.UiPersistenceService = UiPersistenceService;
        this.OnboardingService = OnboardingService;
        this.AbTestService = AbTestService;
        this.$window = $window;
        this.$q = $q;
        this.TemplatesViewService = TemplatesViewService;
        this.TemplatesManager = TemplatesManager;
        this.WebsocketHelperService = WebsocketHelperService;
        this._ = _;
        this.OnboardingBotManager = OnboardingBotManager;
        this.ToastService = ToastService;
        this.SuggestionsService = SuggestionsService;
        this.MobileAppService = MobileAppService;
        this.InjectBrandingService = InjectBrandingService;
        this.AccountsService = AccountsService;
        this.ReactModalService = ReactModalService;
        this.persistenceHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.intro_images_in_file) || { 'dontShow': false };
        this.user = UsersManager.getCurrUser();
        this.isClient = this.user.isClient();
        this.isVendor = this.user.isVendor();
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();

        this.getWorkspaceFile();
        this.WebsocketHelperService.registerToRoom(this.user._id + ".workspace_file_status_changed", this.getWorkspaceFile.bind(this));

        this.loading = true;
        this.updateTextareas = AutosizeTextareaUpdater;

        this.isOwnerMode = false;
        this.isClientMode = false;
        this.isPreviewMode = false;
        this.isEditableMode = false;
        this.isViewOnlyMode = this.workspaceFile.isViewOnlyMode();
        this.hasBeenSent = false;
        this.alreadyGotWorkspace = false;

        this.isDirectPayment = false;
        this.firstMemberName = '';
        this.reloadCarouselFlag = false;

        this.showVendorVersionNotification = false;
        this.closeVendorVersionNotification = false;
        this.showClientVersionNotification = false;

        this.showCommentsDrawer = false;

        this.isDraft = false;
        this.alreadyGotFile = false;
        this.showBookInPerson = false;
        this.showDrawerOnLoad = WorkspaceFileService.shouldShowTemplatesMenu();

        PreviewModeService.quit();

        this.onCloseVendorVersionNotification = function closeVendorVersionNotification() {
            this.closeVendorVersionNotification = true;
            this.updateShowingVersionNotification();
        };

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }

            // always show a general message. for yotam
            message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(self.AppStates.root_core_navigation_home);
            }.bind(this));
        });

        this.showSendPreviewFile = this.WorkspaceFileService.shouldShowSendFilePreview(this.workspaceFile);

        // First file cleanup test
        this.isFirstFileNotSent = this.OnboardingService.hasNotYetSentFirstFile();

        // File title validation
        var fileTitleValidationId = uuid4.generate();
        $translate('FILE.TITLE_MODAL._DEFAULT_').then(function (text) {
            self.defaultFileName = text;
        });
        this.WorkspaceFileService.addFileValidation(fileTitleValidationId, function validateFileTitleFunc() {
            return this.WorkspaceFileService.validateFileTitle(this.workspaceFile, this.defaultFileName);
        }.bind(this), 200);

        // Members validation
        var membersValidationId = uuid4.generate();
        this.WorkspaceFileService.addFileValidation(membersValidationId, function membersFileValidationFunc() {
            return this.WorkspaceFileService.membersFileValidation(this.workspaceFile, this.workspace,this.updateSendFileText.bind(this)).then(function (res) {
                return res;
            }.bind(this));
        }.bind(this), 100);

        $scope.$on('$destroy', function () {
            this.WorkspaceFileService.removeFileValidation(fileTitleValidationId);
            this.WorkspaceFileService.removeFileValidation(membersValidationId);
            this.WebsocketHelperService.unregisterFromRoom(this.user._id + ".workspace_file_status_changed");
        }.bind(this));

        this.register(this.workspaceFile, 'fileTemplateChanged', function onFileTemplateChanged() {
            this.reloadCarouselFlag = true;
            this.$timeout(function () {
                this.reloadCarouselFlag = false;
            }.bind(this), 50);
        }.bind(this));

        this.bypassStateChange = DeviceService.isInAppBrowser();
        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

            if (this.workspaceFile.isProposal() || this.workspaceFile.isInvoice()) {

                UserService.onBookInPersonStateChange(event, toState);

                if (this.workspaceFile.isSent() && !this.bypassStateChange && !toParams.preventStateChangeModals && this.isOwnerMode) {
                    // Draft exit
                    if (this.workspaceFile.hasDraft() && toState.name !== fromState.name) {
                        event.preventDefault();
                        this.showDraftPopup(toState, toParams);
                    } else if (this.OnboardingService.isOnboardingActive() &&
                        this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.trialerLeftFile, { show: true }).show) {

                        this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.trialerLeftFile, { show: false });

                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.trialer_left_file);

                        event.preventDefault();
                        this.showScheduleDemoPopup(toState, toParams);
                    }
                }
            }

        }.bind(this));

        if (this.isInAppBrowser) {

            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._UPDATE_HEADER_, function () {
                $scope.$applyAsync(function () {
                    this.getWorkspaceFile();
                    this.registerOnce(this.workspaceFile, 'success', function () {
                        this.workspaceFile.trigger('fileHeaderUpdated');
                    }.bind(this));
                }.bind(this));
            }.bind(this));

            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._VALIDATE_AGREEMENT_, function () {
                $scope.$applyAsync(function () {
                    this.WorkspaceFileService.validateFileModel().then(
                        function success() {
                            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.validate_agreement_result, { result: true });
                        }.bind(this))
                        .catch(function validationError() {
                            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.validate_agreement_result, { result: false });
                        }.bind(this));
                }.bind(this));
            }.bind(this));
        }

        const showCardOnFileBannerPersistence = this.UiPersistenceService.getUiPersistence(
            this.UiPersistenceService.keys.showEnableCardOnFileBannerLegacyFile,
            { value: true }
        );
        const isInProposalOrInvoice = this.workspaceFile.isInvoice() || this.workspaceFile.isProposal()

        this.shouldShowCardOnFileBanner = this.user.is_company_owner && !this.user.account.card_on_file_enabled &&
            showCardOnFileBannerPersistence.value && isInProposalOrInvoice;

        this.onCardOnFileBannerDismiss = function onCardOnFileBannerDismiss() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.showEnableCardOnFileBannerLegacyFile,
                { value: false }
            );

            this.shouldShowCardOnFileBanner = false;
        }.bind(this);
    }


    Controllers.WorkspaceFileController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileControllerCtor,

        showDraftPopup: function showDraftPopup(toState, toParams) {
            this.ModalService.openUnsentFileModal(this.workspaceFile.file_type, this.workspaceFile.isInvalidated()).then(function (resend) {
                if (resend) {
                    this.sendFile();
                } else {
                    this.bypassStateChange = true;
                    this.goToState(toState, toParams);
                }
            }.bind(this));
        },

        beforeDestroy: function beforeDestroy() {
            this.appConfig.resetFilePaymentsState();
            this.appConfig.resetFilePackageState();
        },

        gotWorkspace: function gotWorkspace(message, status) {
            if (this.isDirectPayment === false && this.workspaceFile.isDirectPayment()) {
                this.isDirectPayment = true;
                this.client = this.workspace.getVendorsForRequestProposal(this.user._id)[0];
                this.firstMemberName = this.client.full_name;
            }

            this.alreadyGotWorkspace = true;
        },

        onAcceptedNewVersion: function () {
            var promise = this.WorkspaceFileService.validateFileModel();
            var that = this;
            promise.then(function success() {
                that.workspaceFile.acceptNewVersion().then(
                    function success() { },
                    function error(res) {
                        that.PopupMessageService.showAlert(that.PopupMessageService.severityTypes.error, res.data.error_message);
                    }
                );
            }
            );
        },

        updateShowingVersionNotification: function updateShowingVersionNotification() {
            this.showVendorVersionNotification =
                !this.isPreviewMode &&
                this.workspaceFile.hasProposal() &&
                this.isOwnerMode &&
                this.workspaceFile.isSent() &&
                this.workspaceFile.isEditable() && !this.closeVendorVersionNotification;

            this.showClientVersionNotification = !this.isOwnerMode && !this.isViewOnlyMode && this.workspaceFile.isWaitingForAccept();

            if (this.showClientVersionNotification) {
                this.$window.scrollTo(0, 0);
            }

            this.isDraft = this.workspaceFile.isDraft();
        },

        gotWorkspaceFile: function gotWorkspaceFile(message, status) {
            this.AccountsService.verifyLoggedCompany(
                this.workspaceFile.company._id,
                this.workspaceFile.company.account_id,
                'file',
                this.workspaceFile.company.company_name
            );

            this.OnboardingService.onWorkspaceFileViewed(this.workspaceFile);
            if (!this.alreadyGotFile && this.workspaceFile.preferred_vendors_list &&
                this.workspaceFile.preferred_vendors_list.preferred_vendor_ids) {
                this.pvList = [];
                this.pvlParams = { notifyVendors: this.workspaceFile.preferred_vendors_list.notify_referred_vendors };
                this.workspaceFile.preferred_vendors_list.preferred_vendor_ids.forEach(function (vendorId) {
                    this.pvList.push({
                        _selected: true,
                        user: {
                            _id: vendorId
                        }
                    });
                }.bind(this));
            }

            if (!this.alreadyGotFile) {
                this.isInAppBrowser = this.isInAppBrowser && !this.workspaceFile.isTimeline();
                this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.workspace_file_loaded);

                if (this.isInAppBrowser && this.workspaceFile.hasAgreement()) {
                    this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._UPDATE_AGREEMENT_, function (data) {
                        this.$scope.$applyAsync(function () {
                            if (data && data.refresh_workspace) {
                                this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true);
                            }
                            this.getWorkspaceFile();
                        }.bind(this));
                    }.bind(this));
                }
            }

            if (this.workspaceFile.company) {

                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }
            this.alreadyGotFile = true;
            this.loading = false;
            this.updateTextareas();
            this.isOwnerMode = this.workspaceFile.isOwnerMode();
            this.isClientMode = this.workspaceFile.isClientMode();
            this.showOwner = !this.isOwnerMode;
            this.isEditableMode = this.workspaceFile.isEditable();
            this.isInvalidated = this.workspaceFile.isInvalidated();
            this.hasBeenSent = this.workspaceFile.sent_on ? true : false;

            if (this.isInvalidated) {
                var draftInvalidatedReason = this.workspaceFile.draftInvalidatedReason();
                this.draftInvalidatedReasonText = "FILE.ALERTS." + this.Enums.DraftInvalidation[draftInvalidatedReason];
            }
            this.isViewOnlyMode = this.workspaceFile.isViewOnlyMode();

            this.notificationTranslationData = {
                fileType: this.$filter('lowercase')(this.$translate.instant(this.WorkspaceFileService.getProposalLabel(this.workspaceFile))),
                dateSent: this.$filter('hbdate')(this.workspaceFile.sent_on),
                vendorName: this.workspaceFile.owner.full_name
            };

            this.notificationTranslationText = this.user.company.doesCurrUserHaveTeamMembers() ?
                'FILE.ALERTS._VENDOR_VERSION_NOTIFICATION_WITH_TEAM_MEMBERS_' : 'FILE.ALERTS._VENDOR_VERSION_NOTIFICATION_';


            this.updateShowingVersionNotification();

            this.loadFilmStrip();

            this.updateSendFileText();

            if (!this.alreadyGotWorkspace) {
                var refresh = this.$stateParams.file_version == null;
                this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true, refresh);
                this.register(this.workspace, 'success', this.gotWorkspace);
            }

            var setParams = function setParams() {
                this.isOwnerMode = this.workspaceFile.isOwnerMode();
                this.isClientMode = this.workspaceFile.isClientMode();
                this.isViewOnlyMode = this.workspaceFile.isViewOnlyMode();
            }.bind(this);

            if (this.isOwnerMode && angular.isUndefined(this.company)) {
                this.company = this.CompaniesManager.getCurrCompany();

                if (this.company.wasFullyFetched()) {
                    setParams();
                } else {
                    this.registerOnce(this.company, 'success', setParams);
                }
            }

            this.file_title = this.workspaceFile.file_title;
            this.selectedFileImage = {};
            angular.extend(this.selectedFileImage, this.workspaceFile.workspace_file_image);

            this.showBookInPerson = !this.workspaceFile.hideBookedInPerson() && !this.workspaceFile.is_canceled && !this.user.isInTrialAndNotSubscribed();

            //this._shouldShowSuggestions();
            this.inAppBrowserOwner = this.isInAppBrowserOwner();

        },

        isInAppBrowserOwner: function isInAppBrowserOwner() {
            // Its NOT inAppBrowser return true - ignore.
            if (!this.isInAppBrowser) {
                return true;
            }

            if (this.isOwnerMode && this.isInAppBrowser) {
                return false;
            }

            return true;
        },

        updateSendFileText: function updateSendFileText() {
            this.sendFileButtonText = this.WorkspaceFileService.getSendFileLabel(this.workspaceFile, this.workspace);
        },

        loadFilmStrip: function loadFilmStrip() {

            if (this.filmStripLoaded === true) {
                return;
            }

            this.filmStripLoaded = true;

            this.linksTo = [
                { label: this.WorkspaceFileService.getProposalLabel(this.workspaceFile), element: 'hb-proposal' }
            ];

            if (this.workspaceFile.hasProposal()) {
                this.linksTo.push({ label: 'FILE.FILMSTRIP._PAYMENTS_', element: 'hb-payments' });
            }

            if (this.workspaceFile.isProposal()) {
                this.linksTo.push({ label: 'FILE.FILMSTRIP._AGREEMENT_', element: 'hb-agreement' });
            }

            if (this.workspaceFile.isBrochure()) {
                this.linksTo = [];
            }

            if (this.workspaceFile.isAgreement()) {
                this.linksTo = [];
                this.linksTo.push({ label: 'FILE.FILMSTRIP._AGREEMENT_', element: 'hb-agreement' });
            }
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        getFileClasses: function getFileCardClasses() {
            var fileClasses = this.WorkspaceFileService.getStatusClassesArray(this.workspaceFile);
            if (this.isPreviewMode && !this.workspaceFile.isInvoice() && !this.workspaceFile.isProposal()) {
                fileClasses.push('preview-mode');
            }

            if (this.isClient) {
                fileClasses.push('view--client');
            }

            if (this.shouldShowAsideDrawer()) {
                fileClasses.push('aside-drawer-main');
            }

            if (this.isInAppBrowser && !this.workspaceFile.isTimeline()) {
                fileClasses.push('workspace-file--in-app-browser');
            }

            return fileClasses;
        },

        generateAnalyticsProps: function generateAnalyticsProps() {
            if (this.workspaceFile) {

                var ownerName, ownerEmail, ownerId, ownerUserType, ownerHBUserType, ownerCompanyId, ownerCompanyRole, ownerCompanyName, ownerCompanyType;
                if (this.workspaceFile.owner) {
                    var owner = this.workspaceFile.owner;
                    ownerName = owner.full_name;
                    ownerEmail = owner.email;
                    ownerId = owner._id;
                    ownerUserType = owner.system_user_type;
                    ownerCompanyRole = owner.company_role;
                    ownerHBUserType = Array.isArray(owner.hb_user_type) ? owner.hb_user_type[0] : '';

                    if (owner.company) {
                        var company = owner.company;
                        ownerCompanyId = company._id;
                        ownerCompanyName = company.company_name;
                        ownerCompanyType = company.company_type_name;
                    }
                }
                return {
                    file_id: this.workspaceFile._id,
                    file_title: this.workspaceFile.file_title,
                    file_type: this.workspaceFile.file_type,
                    file_status: this.workspaceFile.status_name,
                    file_workspace_name: this.workspaceFile.workspace_name,
                    file_event_name: this.workspaceFile.event ? this.workspaceFile.event.event_name : '',
                    file_owner_id: ownerId,
                    file_owner_name: ownerName,
                    file_owner_email: ownerEmail,
                    file_owner_user_type: ownerUserType,
                    file_owner_hb_user_type: ownerHBUserType,
                    file_owner_company_id: ownerCompanyId,
                    file_owner_company_name: ownerCompanyName,
                    file_owner_company_type: ownerCompanyType,
                    file_owner_company_role: ownerCompanyRole
                };
            }
        },

        saveFileImage: function saveFileImage(type) {
            var promise = this.WorkspaceFilesManager.setWorkspaceFileImage(this.workspaceFile, this.selectedFileImage);
            if (type === 'reposition') {
                this.reloadCarouselFlag = true;
                this.$timeout(function () {
                    this.company = this.CompaniesManager.getCurrCompany(true);
                    this.company.once('success', function success() {
                        this.reloadCarouselFlag = false;
                    }, this);
                }.bind(this));
            }
            return promise;
        },

        sendFile: function sendFile() {
            return this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile, this.client).then(this._fileSendSuccess.bind(this));
        },

        onSendOrResendClick: function onSendOrResendClick(isResend) {
            if (!this.user.account.card_on_file_enabled && this.workspaceFile.payment_type === "recurring") {
                this.ReactModalService.openEnableCardOnFileModal('recurring');
                return;
            }
            if (isResend) {
                this.resendFile();
            } else {
                this.sendFile();
            }

        },

        sendFilePreview: function sendFilePreview() {
            return this.WorkspaceFileService.sendFilePreview(this.user, this.workspace, this.workspaceFile).then(this._fileSendPreviewSuccess.bind(this));
        },

        resendFile: function resendFile() {
            var promise = this.WorkspaceFileService.resendFile(this.user, this.workspace, this.workspaceFile, this.client);
            if (!promise) {
                return;
            }
            promise.then(this._fileSendSuccess.bind(this));
            return promise;
        },

        _isFileCreatedFromTemplates: function _isFileCreatedFromTemplates() {
            if (!!this.$state.previous) {
                return this.$state.previous.route.name === this.AppStates.root_core_navigation_templatesEditor;
            }
        },

        _fileSendSuccess: function _fileSendSuccess() {
            this.$scope.$broadcast('workspaceFileSent', { file: this.workspaceFile });

            this.bypassStateChange = true;

            if (this._isFileCreatedFromTemplates()) {
                this.WorkspaceService.gotoWorkspace(this.workspace._id, this.workspace.event._id, { origin: [this.Enums.createFileOriginType.template, this.workspaceFile.file_type] });
            } else {
                this.WorkspaceService.gotoWorkspace(this.workspace._id, this.workspace.event._id);
            }

        },

        _fileSendPreviewSuccess: function _fileSendPreviewSuccess() {
            this.$scope.$broadcast('workspaceFileSent', { file: this.workspaceFile });
        },

        discardDraft: function discardDraft(evt) {
            // Make sure loadVersion doesn't get called
            if (evt) {
                evt.stopPropagation();
            }

            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, 'FILE.HEADER._CONFIRM_DELETE_DRAFT_').then(function () {
                this.workspaceFile.discardFileDraft();
            }.bind(this));
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        toggleDrawer: function toggleDrawer() {
            this.showCommentsDrawer = !this.showCommentsDrawer;

            if (this.showCommentsDrawer) {
                var el = angular.element('#js-submit-comment-textarea');
                if (el) {
                    this.$timeout(function () {
                        el.focus();
                    });
                }
            }
        },

        hideDrawerMethod: function hideDrawer() {
            this.showCommentsDrawer = false;
        },

        changeIcon: function changeIcon() {
            this.showIconLoader = true;
            this.ModalService.openUploadIconPhotoModal(this.company, true)
                .finally(function logoChangeFinally() {
                    // Instead of reloading the file we set the new uploaded company image
                    this.workspaceFile.owner.company.icon_image = this.company.icon_image;
                    this.showIconLoader = false;
                }.bind(this));
        },

        showScheduleDemoPopup: function showScheduleDemoPopup(state, params) {
            if (this.OnboardingBotManager.isOnboardingBotActiveOnWS(this.workspace)) {
                this.$state.go(state.name, params);
            } else {
                var self = this;
                var isCompanySubscribed = this.OnboardingService.isUserSubscribed();

                self.AnalyticsService.trackLoad(self, self.AnalyticsService.analytics_events.onboarding_webinar);
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info,
                    'FILE.DEMO_MODAL._INFO_',
                    function clickedYeah() {
                        self.AnalyticsService.trackClick(self, self.AnalyticsService.analytics_events.onboarding_webinar, { is_subscribed: isCompanySubscribed });
                        this.openWebinarPage()
                    }.bind(this),
                    function clickedNay() {
                        self.AnalyticsService.trackCancel(self, self.AnalyticsService.analytics_events.onboarding_webinar, { is_subscribed: isCompanySubscribed });
                        self.bypassStateChange = true;
                        self.$state.go(state.name, params);
                    }.bind(this)
                );
            }
        },

        openWebinarPage: function openWebinarPage() {
            var otherWindow = this.$window.open();
            otherWindow.opener = null;
            otherWindow.location = self.UserService.getWebinarLink();
        },

        bookInPerson: function bookInPerson() {
            return this.WorkspaceFileService.bookInPerson(this.user, this.workspace, this.workspaceFile, this.client).then(
                function success(result) {
                    if (result) {
                        this.UsersManager.vendorLoginAsClient(this.workspaceFile._id, result).then(
                            function success(resp) {
                                var currUser = this.UsersManager.getCurrUser();
                                this.AnalyticsService.unidentify();
                                this.AnalyticsService.identify(currUser);
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.client_login_as);

                                var nextState = this.AppStates.root_core_workspaceFile_stepProposal;
                                this.goToState(nextState, {
                                    preventStateChangeModals: true,
                                    workspace_file_id: this.workspaceFile._id
                                });
                            }.bind(this));
                    }
                }.bind(this)
            );
        },

        updateInvoiceByTemplate: function updateInvoiceByTemplate(file, templateId) {
            var deferred = this.$q.defer();
            var self = this;

            this.WorkspaceFilesManager.updateInvoiceByTemplate(file, templateId).then(
                function success(resp) {

                },

                function error(resp) {

                    deferred.reject(resp);
                });

            return deferred.promise;

        },

        updateProposalFileByTemplate: function updateProposalFileByTemplate(file, templateId) {
            var deferred = this.$q.defer();
            var self = this;

            this.WorkspaceFilesManager.updateProposalFileByTemplate(file, templateId).then(
                function success(resp) {

                },

                function error(resp) {

                    deferred.reject(resp);
                });

            return deferred.promise;

        },

        /////////////////////////////////////////////////
        // Templates actions
        ////////////////////////////////////////////////
        getTemplatesViewModel: function getTemplatesViewModel() {
            if (this.workspaceFile.isInvoice()) {
                return this.TemplatesViewService.invoice;
            }
            else if (this.workspaceFile.isProposal()) {
                return this.TemplatesViewService.proposal;
            }
            else if (this.workspaceFile.isAgreement()) {
                return this.TemplatesViewService.agreement;
            }
            else if (this.workspaceFile.isQuestionnaire()) {
                return this.TemplatesViewService.questionnaire;
            }
            return null;
        },

        onTemplateSelect: function onTemplateSelect(template, templateViewModel) {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.load_template_to_file,
                { type: template.type }
            );

            if (this.workspaceFile.isRecurringPayment() && (this.workspaceFile.isSent() || this.workspaceFile.hasPaidPayments())) {

                var fileTypeDisplayName = this.WorkspaceFileService.formatFileTypeToDisplayName(this.workspaceFile.file_type);

                var popupText = {
                    translationId: 'FILE.HEADER._CANT_EDIT_RECURRING_FILE_',
                    interpolateParams: { fileType: fileTypeDisplayName }
                };

                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, popupText, null, 'FREQUENT_BUTTONS._OK_');
                return;
            }

            this.TemplatesViewService.applyTemplate(
                this.workspaceFile,
                template,
                templateViewModel.name,
                templateViewModel.applyFunction);
        },

        onSaveAsTemplate: function onSaveAsTemplate(templateViewModel) {

            var titleWrapper = {
                title: ''
            };

            this.ModalService.openTemplateSaveAsModal(titleWrapper, '', true,
                function success() {
                    this.AnalyticsService.track(this.$scope, 'click: save as template', { source: 'file' });
                    return templateViewModel.saveFromFileFunction(this.company, this.workspaceFile, titleWrapper.title);
                }.bind(this),
                function cancel() {
                    this.AnalyticsService.track(this.$scope, 'click: cancel save as template', { source: 'file' });
                }.bind(this)
            );
        },

        shouldShowAsideDrawer: function shouldShowAsideDrawer() {
            return this.isOwnerMode && !this.isPreviewMode;
        },

        asideToggle: function asideToggle(isOpen) {
            if (this.DeviceService.isSafari() || this.DeviceService.isFireFox()) {
                if (this.DeviceService.navLargeBreakPoint()) {
                    return;
                }

                this.fixPositioningOfFixedElements();
            }
        },

        fixPositioningOfFixedElements: function fixPositioningOfFixedElements() {
            //FIX FOR: Position fixed on filmstrip, agreement message, ad ck-editor- gets out of context when transitioned left panel. FF && SAFARI.
            //This is a workaround for a known issue for safari: "transition positioned fixed children"
            angular.element('.file-steps').css('display', 'none');
            angular.element('.agreement-fields-message').css('display', 'none');
            angular.element('.cke_top.cke_reset_all').css('display', 'none');
            this.$timeout(function () {
                angular.element('.file-steps').css('display', 'flex');
                angular.element('.agreement-fields-message').css('display', 'flex');
                angular.element('.cke_top.cke_reset_all').css('display', 'flex');

            }.bind(this), 500);
        },

        getWorkspaceFile: function getWorkspaceFile() {
            this.workspaceFileId = this.$stateParams.workspace_file_id;
            if (angular.isDefined(this.$stateParams.file_version) && this.$stateParams.file_version !== null) {
                this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFileVersion(this.workspaceFileId, this.$stateParams.file_version);
            } else {
                this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
            }
        },

        isHeaderEditable: function isHeaderEditable() {
            return this.isOwnerMode && !this.isPreviewMode && !this.isViewOnlyMode && this.isEditableMode;
        },

        /* Bill to */
        shouldShowBillTo: function shouldShowBillTo() {
            return this.workspaceFile.isInvoice() || this.workspaceFile.isProposal();
        },

        /* Suggestions */
        _shouldShowSuggestions: function _shouldShowSuggestions() {
            this.showInvoiceSuggestion = false;
            this.showProposalSuggestion = false;

            if (!this.user.companyHasSubscription()) {
                return;
            }

            switch (this.workspaceFile.file_type_cd) {
                case this.Enums.WorkspaceFileTypes.invoice:
                    this._suggestionInvoice();
                    break;

                case this.Enums.WorkspaceFileTypes.proposal:
                    this._suggestionProposal();
                    break;
            }
        },

        _suggestionInvoice: function () {
            this.invoiceSuggestion = this.SuggestionsService.getSuggestion('invoice');
            this.showInvoiceSuggestion = true;
        },

        _suggestionProposal: function () {
            this.proposalSuggestion = this.SuggestionsService.getSuggestion('proposal');
            this.showProposalSuggestion = true;
        },

        suggestionViewed: function suggestionViewed(suggestion) {
            switch (suggestion) {
                case 'first_invoice':
                    //setUiPersistence
                    break;
                case 'first_proposal':
                    //setUiPersistence
                    break;
            }
        }
    });
}());
