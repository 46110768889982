Directives.TopNavDropdownDirective = function TopNavDropdownDirective() {

    // @ngInject
    function TopNavDropdownDirectiveControllerFunc($scope, $injector, $rootScope, UsersManager,Enums, AbTestService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TopNavDropdownDirectiveController';

        // init
        this.blockElmClass = this.bemClass ? 'hb-nav__dropdown-container--' + this.bemClass : '';
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.Enums = Enums;
        this.AbTestService = AbTestService;
        this.showToolsBlockWithAnimation = false;

        if (this.shouldBlockTools()) {
            this.showToolsBlockWithAnimation = true;
        }

        $scope.$watch('topNavDropdownVm.show', function(newVal, oldValue) {
            if (angular.isDefined(newVal) && angular.isDefined(oldValue) && newVal !== oldValue) {
                $rootScope.$broadcast('toolsMenuVisibilityChanged', newVal);
            }
        }.bind(this));
    }

    var TopNavDropdownDirectiveController = Class(Controllers.BaseController, {
        constructor: TopNavDropdownDirectiveControllerFunc,
        shouldBlockTools: function shouldBlockTools() {
            return this.user.isNetworkPretrialer();
        },
    });

    return {
        scope: {
            show: '=show',
            bemClass: '@?',
            arrowPosition: '@?',
            src:'@'
        },
        transclude: {
            'content': '?dropdownContent'
        },
        templateUrl: 'angular/app/modules/core/features/top_nav/dropdown/top_nav_dropdown_template.html',
        controller: TopNavDropdownDirectiveController,
        controllerAs: 'topNavDropdownVm',
        bindToController: true
    };
};
