/**
 * Created by iggy on 7/16/15.
 */

// @ngInject
Directives.FeedMessageEditorMobileDirective = function FeedMessageEditorMobileDirective($window, $timeout, Modernizr) {

    return {
        restrict: 'A',
        link: function ($scope, $elem, $attrs) {

            function setMobileEditor() {
                $timeout(function () {
                    var miniEventBtns = angular.element(document.getElementsByClassName('tab-minievent-selector'));

                    angular.element($window).on("resize.feedEditor.resizingWindow", function () {
                        $scope.$applyAsync(function () {
                            removeTabs();
                        });
                    });

                    if ($window.innerWidth < 640 || (Modernizr.touch)) {
                        $scope.$applyAsync(function () {
                            removeTabs();
                        });
                    }

                    //REMOVE TABS FROM MINIPROJECT
                    function removeTabs() {
                        angular.element($window).bind("scroll", function () {
                            if ($window.innerWidth < 640 || (Modernizr.touch)) {
                                if (this.pageYOffset >= 10) {
                                    miniEventBtns.hide();
                                } else {
                                    miniEventBtns.show();
                                }
                                $scope.$applyAsync();
                            } else {
                                miniEventBtns.show();
                                unbind();
                            }
                        });
                    }

                    function unbind() {
                        angular.element($window).off("resize.feedEditor.resizingWindow");
                        angular.element($window).unbind('scroll', removeTabs);
                    }

                    //REMOVES HANDLER
                    $scope.$on("$destroy", function () {
                       unbind();
                    });


                }, 100);//timeout
            }//setMobileEditor

            setMobileEditor();

        }//link
    };//return
};
