(function () {
    "use strict";

    // @ngInject
    function RemitToComponentCtor($scope, $injector, _) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'RemitToComponent';

        this._ = _;
    }

    var RemitToComponentController = Class(Controllers.BaseController, {
        constructor: RemitToComponentCtor,

        getVendorInfo() {

            let vendorInfo = [];
            let company = this.vendor.company;
            [this.vendor.email, company.website_url, company.phone_number, company.formatted_address].forEach((info) => {
               if (info) {
                   vendorInfo.push(info);
               }
            });

            return vendorInfo;
        }
    });

    Components.RemitToComponent = {
        bindings: {
            vendor: '=',
        },
        controller: RemitToComponentController,
        controllerAs: 'remitToVm',
        name: 'hbRemitTo',
        templateUrl: 'angular/app/modules/core/features/workspace_file/remit_to/remit_to_template.html'
    };

}());