(function () {
    'use strict';

    // @ngInject
    Directives.AddCopyToClipboardDirective = function AddCopyToClipboardDirective($compile, ModalService) {
        return {
            priority: 1, //make sure we are running after the ng-bind-html
            scope: false,
            restrict: 'A',
            link: function (scope, element, attrs) {

                scope.ModalService = ModalService;
                var self = this;

                scope.$watch(function () {
                    return attrs.ngBindHtml;
                }, function (newValue, oldValue) {
                    if(!newValue) {
                        return;
                    }
                    var elementToAddTo = angular.element('.email-vendor-copy-js');
                    var elementToCopy = elementToAddTo.text();

                    if (elementToCopy.indexOf('Email Address:') >= 0) {
                        var removeLabel = elementToCopy.replace('Email Address:', '');
                        elementToCopy = removeLabel.trim();
                    }

                    scope.elementToClipboard = elementToCopy;
                    
                    elementToAddTo.append('<button class="button medium pvl-inquiry-vendor-btn" hb-analytics-event="email inquiry copy clipboard" hb-copy-to-clipboard hb-copy-to-clipboard-text="elementToClipboard">COPY EMAIL ADDRESS</button>');

                    if (elementToAddTo.length > 0) {
                        $compile(element.children())(scope);
                    }

                });
            }
        };
    };
}());


