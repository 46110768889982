(function () {
    'use strict';

    Directives.ActivityNotificationsDirective = function ActivityNotificationsDirective() {

        var NotificationType = {
            CONVERSATION: 'CONVERSATION',
            ACTIVITY: 'ACTIVITY',
        };

        // @ngInject
        function ActivityNotificationsDirectiveControllerCtor($scope, $injector, _, UsersManager, $translate, AnalyticsService, UiPersistenceService, AppConfigService, Enums, AccountsService, FlowsBetaUserTypeService, $window) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'ActivityNotificationsDirectiveController';

            this._ = _;
            this.UsersManager = UsersManager;
            this.$translate = $translate;
            this.$window = $window;
            this.AnalyticsService = AnalyticsService;

            this.currUser = this.UsersManager.getCurrUser();
            this.updating = false;
            this.notificationsViewModel = [];
            this.showOnlyUnread = true;
            this.isSettingsOpenByEdit = false;
            this.AppConfigService = AppConfigService;
            this.showMarkAsReadEmptyState = false;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.UiPersistenceService = UiPersistenceService;
            var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.notificationsShowMarkAsReadEmptyState);
            if (uiPersistenceData && !uiPersistenceData.showInfoMsg) {
                this.showMarkAsReadEmptyStateUiPersistence = false;
            } else {
                this.showMarkAsReadEmptyStateUiPersistence = true;
            }

            // bind to reload flag
            $scope.$watch('activityNotificationsVm.shouldReload', function (newVal, oldVal) {
                if (newVal) {
                    this.showOnlyUnread = !!this.currUser.hasUnseenNotifications();

                    angular.element('.activities-list-container').scrollTop(0);
                    this.refreshWebNotifications(true);
                    this.isSettingsOpen = this.isSettingsOpenByEdit;
                    this.isSettingsOpenByEdit = false;
                    this.showMarkAsReadEmptyState = false;
                    var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.notificationsShowMarkAsReadEmptyState);
                    if (uiPersistenceData && !uiPersistenceData.showInfoMsg) {
                        this.showMarkAsReadEmptyStateUiPersistence = false;
                    } else {
                        this.showMarkAsReadEmptyStateUiPersistence = true;
                    }
                }
            }.bind(this));

            var filesLabel = 'Activity on ';
            if (this.FlowsBetaUserTypeService.hasOnlyFlows) {
                filesLabel += 'Smart Files';
            } else if (!this.FlowsBetaUserTypeService.hasFlows) {
                filesLabel += 'Files';
            } else {
                filesLabel += 'Smart Files & Files';
            }

            this.notificationGroups = [
                {
                    key: 'new_inquiries',
                    label: 'New Inquiries'
                },
                {
                    key: 'activity_messages',
                    label: 'Project Messages'
                },
                {
                    key: 'payments',
                    label: 'Payments'
                },
                {
                    key: 'files',
                    label: filesLabel
                },
                {
                    key: 'opportunities',
                    label: 'Opportunities'
                },
                {
                    key: 'community_connections',
                    label: 'Community Connections'
                },
                {
                    key: 'referrals',
                    label: 'Referral Program'
                }
            ];

            // register for user model updates
            this.register(this.currUser, 'success', this.handleGotUserModel);
            this.handleGotUserModel();

            // register for company change
            this.register(AccountsService, Enums.PubSubTriggers.companyChanged, this._onCompanyChanged);

            this.refreshWebNotifications();

            this.isSettingsOpen = false;

            this.requestInProgress = false;

            if (!this.AppConfigService.wasWebNotificationsSettingsInitiallyFetched()) {
                // shouldnt fetch notification settings for clients
                if(this.currUser.isClient()){
                    if(this.$window && this.$window.DD_RUM){
                        this.$window.DD_RUM.addError(new Error('ClientAskedForWebNotificationsSettings'), {userId: this.currUser._id});
                    }
                    return;
                }

                this.requestInProgress = true;
                UsersManager.getNotificationSettings(true).finally(function () {
                    this.requestInProgress = false;
                    this.AppConfigService.setWebNotificationsSettingsInitiallyFetched();
                }.bind(this));
            }

            $scope.$on('toggleNotificationsSettings', function () {
                this.isSettingsOpen = !this.isSettingsOpen;
            }.bind(this));

            $scope.$on('setNotificationsSettingsOn', function () {
                this.isSettingsOpenByEdit = true;
            }.bind(this));

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.notifications_center, {});
        }

        var ActivityNotificationsDirectiveController = Class(Controllers.BaseController, {
            constructor: ActivityNotificationsDirectiveControllerCtor,

            toggleNotificationGroup: function toggleNotificationGroup(group, toggleValue) {
                this.currUser.notification_settings.notification_groups[group.key] = toggleValue;
                return this.UsersManager.updateNotificationSettings({
                    notification_group: group.key,
                    activate: !!toggleValue
                })
                    .then(function () {
                    })
                    .catch(function error() {
                        this.currUser.notification_settings.notification_groups[group.key] = !toggleValue;
                    }.bind(this));
            },

            onNotificationSettingsChange: function onNotificationSettingsChange(group) {
                this.toggleNotificationGroup(group, group.isChecked);

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.activity_notifications_settings_toggle, {
                    group: group.key,
                    value: group.isChecked
                });
            },

             refreshWebNotifications: function refreshWebNotifications(force) {
                 if ((!this.AppConfigService.wasWebNotificationsFetched() && (!this.lastRefreshActivityNotifications || (new Date().getTime() - this.lastRefreshActivityNotifications > 30000))) || force) {
                    this.lastRefreshActivityNotifications = new Date().getTime();

                    this.currUser.getRecentWebNotifications(this.currUser)
                        .then(this.handleGotActivitiesNotifications.bind(this))
                        .catch(this.handleErrorGettingActivitiesNotifications.bind(this))
                        .finally(function finallyHandler() {
                            this.updating = false;
                            this.AppConfigService.setWebNotificationsFetched();
                        }.bind(this));
                }
            },


            handleGotActivitiesNotifications: function handleGotActivitiesNotifications(response) {
                //TODO: add some analytics here
            },

            handleErrorGettingActivitiesNotifications: function handleErrorGettingActivitiesNotifications(error) {
                //TODO: add some analytics here
                //TODO: think about throwing an exception to see it on log entries.
            },

            buildNotificationsViewModel: function buildNotificationsViewModel() {
                var conversationNotificationsView = this.buildNotificationsViewModelByType(this.currUser.conversation_notifications, NotificationType.CONVERSATION);
                var activityNotificationsView = this.buildNotificationsViewModelByType(this.currUser.activity_notifications, NotificationType.ACTIVITY);

                return conversationNotificationsView.concat(activityNotificationsView);
            },

            buildNotificationsViewModelByType: function buildNotificationsViewModel(notifications, notificationType) {
                var notificationsView = angular.copy(notifications);
                if (!this._.isEmpty(notificationsView)) {
                    //convert the date to a javascript date so we can sort it and mark notification type
                    this._.forEach(notificationsView, function (notification) {
                        var date = notification.last_version_created_at ? notification.last_version_created_at : notification.created_at;
                        notification.dateCreatedAt = new Date(date);
                        notification.notificationType = notificationType;
                    });
                } else {
                    notificationsView = [];
                }

                return notificationsView;
            },

            _initializeNotificationGroups : function _initializeNotificationGroups() {
                _.each(this.notificationGroups, function _initializeNotificationGroup(group) {
                    var isOn = true;
                    var groupFromUser = this.currUser.notification_settings && this.currUser.notification_settings.notification_groups[group.key];
                    if (angular.isDefined(groupFromUser)) {
                        isOn = !!groupFromUser;
                    }

                    group.isChecked = isOn;
                }.bind(this));
            },

            handleGotUserModel: function handleGotUserModel() {
                this.notificationsViewModel = this.buildNotificationsViewModel();
                this._initializeNotificationGroups();
            },

            showUnreadConversations: function (item) {
                return true;
            },

            markAllAsRead: function markAllAsRead() {
                if (this.currUser.hasUnseenNotifications()) {
                    this.updating = true;
                    this.currUser.markAllWebNotificationsAsSeen()
                        .then(this.handleGotActivitiesNotifications.bind(this))
                        .catch(this.handleErrorGettingActivitiesNotifications.bind(this))
                        .finally(function finallyHandler() {
                            this.updating = false;
                        }.bind(this));
                }
            },

            toggleUnseenAndAll: function toggleUnseenAndAll(comeFromMarkAsRead) {
                this.showOnlyUnread = !this.showOnlyUnread;
                this.showMarkAsReadEmptyState = false;
                if (comeFromMarkAsRead) {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.notificationsShowMarkAsReadEmptyState, {showInfoMsg: false});
                    this.showMarkAsReadEmptyStateUiPersistence = false;
                }
            },

            seeAll: function() {
                if(this.showOnlyUnread) {
                    this.toggleUnseenAndAll();
                }
            },

            seeUnread: function() {
                if(!this.showOnlyUnread) {
                    this.toggleUnseenAndAll();
                }
            },

            closeMarkAsReadEmptyState:function closeMarkAsReadEmptyState() {
                this.showMarkAsReadEmptyState = false;
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.notificationsShowMarkAsReadEmptyState, {showInfoMsg: false});
                this.showMarkAsReadEmptyStateUiPersistence = false;
            },

            _onCompanyChanged: function _onCompanyChanged() {
                this.refreshWebNotifications(true);
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/activity_notifications/activity_notifications_directive_template.html',
            controller: ActivityNotificationsDirectiveController,
            controllerAs: 'activityNotificationsVm',
            bindToController: true,
            scope: {
                shouldReload: '=?reload',
                toggleNotifications: '&'
            }
        };
    };
}());
