(function () {
    'use strict';

    Directives.RadioQuestionDirective = function CheckboxQuestionDirective() {

        // @ngInject
        function RadioQuestionDirectiveControllerCtor($scope, $injector, _) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'RadioQuestionDirectiveController';
            this._ = _;
            this.selectedAnswerId = null;

            $scope.$watch('questionVm.question', function (newVal) {
                this._setSelectedAnswerId(newVal);
            }.bind(this));
        }

        var RadioQuestionDirectiveController = Class(Controllers.BaseController, {
            constructor: RadioQuestionDirectiveControllerCtor,

            handleChange: function handleChange() {
                this.question.optional_answers.forEach(function(answer) {
                    answer.selected = (answer._id === this.selectedAnswerId);
                }.bind(this));

                this.updateQuestion();
            },

            _setSelectedAnswerId: function _setSelectedAnswerId(question) {
                this.selectedAnswerId = null;
                this._.each(question.optional_answers, function(answer) {
                    if (answer.selected) {
                        this.selectedAnswerId = answer._id;
                    }
                }.bind(this));

            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/common/ui_components/questions/radio/radio_question_directive_template.html',
            controller: RadioQuestionDirectiveController,
            controllerAs: 'questionVm',
            bindToController: true,
            scope: {
                question: '=',
                isQuestionEditable: '&',
                isAnswerEditable: '&',
                updateQuestion: '&'
            }
        };
    };

}());

