/**
 * Created by inonstelman on 1/1/15.
 */

Directives.ImageDirective = function ImageDirective() {

    // @ngInject
    function ImageDirectiveControllerFunc($scope, $injector, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ImageDirectiveController';

        var $image = null;
        var $container = null;
        var $element;
        var imageLoaded = false;

        this.maxWidth = this.maxWidth || 2000;
        this.style = {
            top: this.image && this.image.position_top || '0%',
            left: this.image && this.image.position_left || '0%'
        };

        this.setDomElement = function setDomElement(elem) {
            $element = elem;
        };

        // Exposing Math.round for use in the directive's template
        this.round = Math.round;

        this.adjustPositionWithDigestLoop = function adjustPositionWithDigestLoop() {
            var args = Array.prototype.slice.call(arguments, 0);
            // Running a digest loop to make sure we're in the angular context and we don't have a digest loop in progress.
            // But we don't want to use the $timeout because it makes the code run
            // later and we need to run this now(!) => we are trying to update a picture's position.
            // For more explanation see http://stackoverflow.com/questions/22346990/why-is-using-ifscope-phase-scope-apply-an-anti-pattern
            //
            // I know it's HACKY but I didn't find any other solution yet.
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply(function runWithDigest() {
                    this.adjustPosition.apply(this, args);
                }.bind(this));
            } else {
                this.adjustPosition.apply(this, args);
            }
        };

        this.adjustPosition = function adjustPosition(markAsLoaded) {
            if (markAsLoaded === true) {
                imageLoaded = true;
            } else if (!imageLoaded) {
                // we break this method since the image is not loaded yet
                return;
            }

            var $image = this._getImageElement();
            var $container = this._getContainerElement();

            var min_y = this._calcMinSize(this._getImageHeight($image), $container.height());
            var top = this.image.position_top != null ? this.image.position_top : '0%';
            var top_num = parseFloat(top);
            if (top_num < min_y) {
                top = min_y + '%';
            }

            var min_x = this._calcMinSize(this._getImageWidth($image), $container.width());
            var left = this.image.position_left != null ? this.image.position_left : '0%';
            var left_num = parseFloat(left);
            if (left_num < min_x) {
                left = min_x + '%';
            }

            this.style = {
                'top': top,
                'left': left
            };
        };

        this._calcMinSize = function _calcMinSize(imageSize, containerSize) {
            var size;
            if (imageSize <= containerSize) {
                size = 0;
            } else {
                size = -(((imageSize / containerSize) * 100) - 100);
            }

            return size;
        };

        this._getImageElement = function _getImageElement() {
            if ($image === null) {
                $image = $element.find('img');
            }

            return $image;
        };

        this._getContainerElement = function _getContainerElement() {
            if ($container === null) {
                // we need the container of the image to calculate the position
                // In case it is in the hb-image-carousel-reposition directive then it is the containing parent
                $container = $element.closest('hb-image-carousel-reposition');
                if ($container.length === 0) {
                    $container = $element.parent(); // otherwise just use the direct parent
                }
            }

            return $container;
        };

        this._getImageHeight = function _getImageHeight($image) {
            var imageHeight;

            if ($image.is(':visible')) {
                // In case the image is visible, it's easy to get the image size
                imageHeight = $image.height();
            } else {
                // Calculate the image height in case it is hidden - by using the DOM
                imageHeight = $image[0].height || $image.clientHeight;
            }

            return imageHeight;
        };

        this._getImageWidth = function _getImageWidth($image) {
            var imageWidth;

            if ($image.is(':visible')) {
                // In case the image is visible, it's easy to get the image size
                imageWidth = $image.width();
            } else {
                // Calculate the image height in case it is hidden - by using the DOM
                imageWidth = $image[0].width || $image.clientWidth;
            }

            return imageWidth;
        };

        // Fix issues with the crappy cloudinary directive.
        // It does NOT react to model changes so we need to manually refresh the img tag in the HTML
        var scopeChanged = function wrapperMethod() {
            this.refreshFlag = true;
            $timeout(function timeout() {
                this.refreshFlag = false;
            }.bind(this), 0);
        }.bind(this);
        $scope.$watch('imageVm.image.cloudinary_public_id', scopeChanged);
        $scope.$watch('imageVm.image.position_y', scopeChanged);
        $scope.$watch('imageVm.image.position_x', scopeChanged);
        $scope.$watch('imageVm.image.position_h', scopeChanged);
        $scope.$watch('imageVm.image.position_w', scopeChanged);
    }


    function ImageLink($scope, $element) {
        $scope.imageVm.setDomElement($element);
        $element.find('img').bind('load', function () {
            $scope.imageVm.adjustPositionWithDigestLoop(true);
        });
    }

    var ImageDirectiveController = Class(Controllers.BaseController, {
        constructor: ImageDirectiveControllerFunc
    });
    return {
        restrict: 'E',
        scope: {
            image: '=hbImageModel',
            maxWidth: '<?',
            preventResponsive: '<?'
        },
        templateUrl: 'angular/app/modules/common/ui_components/image/image_directive_template.html',
        controller: ImageDirectiveController,
        controllerAs: 'imageVm',
        bindToController: true,
        link: ImageLink
    };
};
