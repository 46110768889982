(function () {
    'use strict';

    const STEPS = {
        WHY_PLAID: 'why-plaid',
        CONNECT_BANK_ACCOUNT: 'connect-bank-account', // the plaid dialog, not HB
        CHOOSE_BANK_SUB_ACCOUNTS: 'choose-bank-sub-accounts',
        SHOULD_CONNECT_ANOTHER_BANK_ACCOUNT: 'should-connect-another-bank-account',
    };

    Controllers.ConnectBankAccountModalController = class ConnectBankAccountModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $translate, $timeout, src, step, isMultiSelect, props, PlaidLinkService,
                    AnalyticsService, PopupMessageService, UsersManager, PendingTasksManager,
                    _, $log) {
            super($scope, $injector);
            this.__objectType = 'ConnectBankAccountModalController';
            this.$log = $log;
            this._ = _;
            this.$modalInstance = $modalInstance;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.PlaidLinkService = PlaidLinkService;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.UsersManager = UsersManager;
            this.PendingTasksManager = PendingTasksManager;

            this.imageSrcRoot = 'https://res.cloudinary.com/honeybook/image/upload/v1579424123/app/logos/';

            this.accountTypes = [
                    { name: 'American Express', imageSrc: this.imageSrcRoot + 'amex.png'},
                    { name: 'Bank of America', imageSrc: this.imageSrcRoot + 'boa.png'},
                    { name: 'Charles SCHWAB', imageSrc: this.imageSrcRoot + 'charles-schwab.png'},
                    { name: 'CHASE', imageSrc: this.imageSrcRoot + 'chase.png'},
                    { name: 'WELLS FARGO', imageSrc: this.imageSrcRoot + 'wells-fargo.png'},
                    { name: 'VISA', imageSrc: this.imageSrcRoot + 'visa.png'},
                    { name: 'mastercard', imageSrc: this.imageSrcRoot + 'mastercard.png'}
                ];

            this.src = src;
            this.AnalyticsService.trackLoad(this, 'connect bank account modal', {
                source: this.src});

            this.props = props;
            this.refundAmount = props.refundAmount;

            this.user = UsersManager.getCurrUser();
            this.allSelectedSubAccounts = [];
            this.currBankSubAccounts = [];
            this.connectStep = (step && !props.isOnRedirect) ? STEPS[step] : STEPS.CONNECT_BANK_ACCOUNT;
            this.isLoading = false;
            this.isMultiSelect = isMultiSelect;
            this.inProgress = false;

            if (this.connectStep === STEPS.CONNECT_BANK_ACCOUNT) {
                this.plaidShow();
            }
        }

        handleAddAccountsClick() {
            this.connectStep = STEPS.CONNECT_BANK_ACCOUNT;
            this.plaidShow();
        }

        plaidShow() {
            this.isLoading = true;
            const plaidParams = { paymentId: this.props.paymentId, shouldRedirect: true, source: this.src }
            const plaidLinkPromise = this.src === 'refund' ?
                this.PlaidLinkService.launchPlaidLinkForAuthAndTransactions(plaidParams) :
                this.PlaidLinkService.launchPlaidLinkForTransactions(plaidParams);
            plaidLinkPromise.then(
                function launchPlaidLinkSuccess(plaidResponseMetadata) {
                    // we're good with the client side, got we need. lets create the bank accounts in the server:
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.add_bank_account);
                    const { institution_id, name } = plaidResponseMetadata.institution;
                    this.UsersManager.connectPlaidBankAccount(
                        this.user, institution_id, name, this.src
                    ).then((resp) => {
                        // use the connect plaid account response accounts filtered to the accounts
                        // returned by the plaid link dialog (onSuccess)
                        this.currBankSubAccounts = resp.data.filter(respAccount =>
                            plaidResponseMetadata.accounts.find(
                                ma => ma.id === respAccount.id ||
                                    ma.mask === respAccount.mask // in case account ids were changed
                            ));
                        this.currBankSubAccountsInstitution = plaidResponseMetadata.institution;
                        this.isLoading = false;
                        this.connectStep = STEPS.CHOOSE_BANK_SUB_ACCOUNTS;
                    }).catch((resp) => {
                        this.isLoading = false;
                        if (resp.data && resp.data.error) {
                            this.errorMessage = resp.data.error_message;
                            this.hasError = true;
                            this.handleBankSelectionError(this.errorMessage,
                                () => this.plaidShow());
                        } else {
                            this.currBankSubAccounts = [];
                            this.currBankSubAccountsInstitution = {};
                            this.close(this.allSelectedSubAccounts, this.errorMessage);
                        }
                    });
                }.bind(this),
                function launchPlaidLinkError(res) {
                    this.isLoading = false;
                    this.hasError = true;

                    if (res.err) {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.add_bank_account, {}, res.err);
                        this.handleBankSelectionError(
                            this.$translate.instant('PAYMENTS.REFUNDS._CONNECTION_ERROR_'),
                            () => this.plaidShow());
                    } else {
                        this.AnalyticsService.trackClick(this, 'close plaid dialog');
                        this.close();
                    }
                }.bind(this));

        }

        formatBankAccount(bankAccount) {
            return `${bankAccount.name} (${bankAccount.type} / ${bankAccount.subtype})`;
        }

        isSelectionDisabled(bankAccount) {
            if(this.src === 'refund') {
                return bankAccount.type !== 'depository';
            }
            if(this.isMultiSelect) {
                return bankAccount.is_user_marked_as_business;
            } else {
                return !bankAccount.selected && this.allSelectedSubAccounts && this.allSelectedSubAccounts.length > 0;
            }
        }

        toggleAccountSelection(account) {
            if (account.selected) {
                this.selectAccount(account);
                if (!this.isMultiSelect) {
                    for (const otherAccount of this.currBankSubAccounts) {
                        if (otherAccount.account_id !== account.account_id && otherAccount.selected) {
                            this.deselectAccount(otherAccount);
                        }
                    }
                }
            } else {
                this.deselectAccount(account);
            }
        }

        selectAccount(account) {
            this.allSelectedSubAccounts.push(account);
        }

        deselectAccount(account) {
            account.selected = false;
            this.allSelectedSubAccounts.splice(this.allSelectedSubAccounts.findIndex((selectedAccount) => {
                return selectedAccount._id === account._id;
            }), 1);
        }

        handleBankSelectionError(msg, tryAgainCB) {
            this.AnalyticsService.trackError(this, 'select account', {}, {errorMsg: msg});
            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.error, {
                translationId: msg}, 'PAYMENTS.REFUNDS._CONNECT_TO_DIFFERENT_ACCOUNT_',
                'PAYMENTS.REFUNDS._TRY_LATER_', null, true, false, true)
                .then(() => {
                    this.AnalyticsService.trackClick(this, 'use different account');
                    if (tryAgainCB) {
                        tryAgainCB();
                    }
                }).catch(() => {
                    this.AnalyticsService.trackClick(this, 'try later');
                    this.close();
                });
        }

        doneSelectingSubAccounts() {
            const selectedBankAccounts = this.currBankSubAccounts.filter((account) => account.selected);

            this.allSelectedSubAccounts = this._.uniq(
                this.allSelectedSubAccounts.concat(selectedBankAccounts), (selectedAccount) => selectedAccount.account_id
            );


            if(this.isMultiSelect) {
                this.selectAccounts(this.allSelectedSubAccounts);
                this.connectStep = STEPS.SHOULD_CONNECT_ANOTHER_BANK_ACCOUNT;
            } else {
                this.close(this.allSelectedSubAccounts[0]);
            }
        }

        selectAccounts(accounts) {
            const accountIds = accounts.map(account => account._id);
            this.UsersManager.selectPlaidBusinessSubAccounts(this.user, accountIds)
                .then((resp) => {
                    this.AnalyticsService.trackSuccess(this, 'successfully selected sub account(s)');
                }).catch((error) => {
                    this.AnalyticsService.trackError(this, 'error fetching selecting sub accounts from plaid', error);
                }).finally(() => {
                    this.inProgress = false;
                });
        }

        connectAnotherAccount() {
            this.connectStep = STEPS.CONNECT_BANK_ACCOUNT;
            this.plaidShow();
        }

        hasAnyBankAccountSelected() {
            return this.allSelectedSubAccounts && this.allSelectedSubAccounts.length > 0;
        }

        closeWithCurrentSelected() {
            return this.close(this.allSelectedSubAccounts);
        }

        close(selectedAccounts) {
            this.$modalInstance.close(selectedAccounts);
        }
    };
}());
