// @ngInject

Models.TemplateModel = function TemplateModel(BaseModel) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'TemplateModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                file_dynamic_content: 'FileDynamicContentModel'
            };
        },

        bubbleUp: function bubbleUp() {
            return [
                'addSection',
                'updateSection',
                'deleteSection',
                'updateSectionsOrder',
                'addBlock',
                'updateBlock',
                'deleteBlock',
                'updateSectionBlocksOrder',
                'updateProposalOnBlock',
                'addPackageToBlock',
                'updatePackageOnBlock',
                'deletePackageFromBlock',
                'updatePackageServicesOrderOnBlock',
                'addServiceToBlock',
                'updateServiceOnBlock',
                'updateServiceImageOnBlock',
                'deleteServiceFromBlock',
                'updateProposalOrder',
                'moveServiceOnBlock',
                'updateComponent',
            ];
        },

        getRootModelType: function getRootModelType() {
            return 'template';
        },

        getRootModel: function getRootModel() {
            return this;
        },

        shouldShowTax: function () {
            return this.__parent.shouldShowTax(this);
        },

        updateTemplate: function updateTemplate() {
            return this.__parent.updateTemplate(this);
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return {
                template: this._id
            };
        },

        updateProposalSelection: function updateProposalSelection() {
            // shouldn't do anything if we get here
        },

        answerQuestion: function answerQuestion() {
            return null;
        }

    });
};

