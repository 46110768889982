(function () {
    'use strict';

    Services.EnvService = class EnvService {

        // @ngInject
        constructor(Gon) {
            this.Gon = Gon;

            this.environments = {
                production: 'production',
                staging: 'staging',
                development: 'development',
                test: 'test'
            };
        }

        getEnv() {
            return this.Gon.environment;
        }

        isEnv(env) {
            return this.Gon.environment === env;
        }

        isProduction() {
            return this.isEnv(this.environments.production);
        }

        isDevelopment() {
            return this.isEnv(this.environments.development);
        }

        isTest() {
            return this.isEnv(this.environments.test);
        }

        isStaging() {
            return this.isEnv(this.environments.staging);
        }
    };
}());
