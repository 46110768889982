(function () {
    "use strict";

    // @ngInject
    function PvlRecommendationsControllerCtor($scope, $injector, $modalInstance, workspace) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'PvlRecommendationsController';

        this.modalInstance = $modalInstance;
        this.workspace = workspace;
    }

    Controllers.PvlRecommendationsController = Class(Controllers.BaseController, {
        constructor: PvlRecommendationsControllerCtor,

        handlePvlRequestSent: function handlePvlRequestSent() {
            this.modalInstance.close();
        },

        handlePvlRequestDismissed: function handlePvlRequestDismissed() {
            this.modalInstance.dismiss();
        }
    });
}());
