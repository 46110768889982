/**
 * The hb-video-player directive
 * Encapsulate player logic.
 *
 * parameters:
 *  videoLink: Just a video url to play. I use it for backwards compatibility and simplicity.
 *  videoId: If you are using youtube. it's better to use use the video-id rather than url.
 *  enableYoutubeApi : Enable youtube js events listeners.
 *  onVideoEnds: what to do when video ends
 *  isRunning: Boolean. indicates that the video is running.
 */

(function () {
    'use strict';

    Directives.VideoPlayerDirective = function ChipsDirective() {

        // @ngInject
        function VideoPlayerDirectiveControllerConstructor($scope, $injector, $timeout, _, $sce, YouTubeAPIService, uuid4, DatadogRUMService) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'VideoPlayerDirectiveController';

            this.$timeout = $timeout;
            this._ = _;
            this.$sce = $sce;
            this.$scope = $scope;
            this.player = undefined;
            this.playerId = "hb-video-player__" + uuid4.generate();
            this.YouTubeAPIService = YouTubeAPIService;
            this.DatadogRUMService = DatadogRUMService;

            this.showVideoLoader = true;

            var videoUrl = this.videoLink;
            if (this.videoId) {
                var playerVars = {
                    'showinfo': 0,
                    'autoplay': 1,
                    'cc_load_policy': 0,
                    'rel': 0,
                    'fs': 0,
                    'modestbranding': 1,
                    'enablejsapi':1
                };
                var _youtubeVideoSrc = "https://www.youtube.com/embed/{videoId}?{playerVars}";
                var playerVarsString = this._.map(playerVars, function(value, key) { return key + "="  + value }).join('&');
                _youtubeVideoSrc = _youtubeVideoSrc.replace('{videoId}', this.videoId).replace('{playerVars}', playerVarsString);
                videoUrl = _youtubeVideoSrc;
            }

            this.trustUrl = this.$sce.trustAsResourceUrl(videoUrl);

            var onPlayerReady = function onPlayerReady(event) {
                this.showVideoLoader = false;
                this.$scope.$applyAsync();
                this.player.seekTo(0);
                event.target.setPlaybackQuality('hd1080');
            }.bind(this);

            var onPlayerStateChange = function onPlayerStateChange(event) {
                if (event.data === YT.PlayerState.ENDED) {
                    if (angular.isFunction(this.onVideoEnds)) {
                        this.onVideoEnds();
                    }
                }
            }.bind(this);

            var video_options = {
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            };

            if (this.enableYoutubeApi) {
                // overcomes postmessage error.
                $timeout(function () {
                    this.YouTubeAPIService.createPlayer(this.playerId, video_options)
                        .then(function (player) {
                            this.player = player;
                        }.bind(this))
                        .catch(function (reason) {
                            this.DatadogRUMService.addError(
                                reason,
                                {
                                    message: 'Error loading Youtube API ' +  reason
                                }
                            );
                        });
                }.bind(this), 0);
            }

            this.$scope.$watch('videoPlayerVm.isRunning', function(newVal, oldVal) {
                //if isRunning changed from false to true then it means we need to replay.
                if (newVal !== oldVal && newVal) {
                    this.replay();
                }

            }.bind(this));

        }

        var VideoPlayerDirectiveController = Class(Controllers.BaseController, {

            constructor: VideoPlayerDirectiveControllerConstructor,

            replay : function replay() {
                this.player.seekTo(0);
                this.player.playVideo();
            }

        });

        return {
            scope: {
                videoLink: '@',
                videoId: '@',
                enableYoutubeApi : '=',
                onVideoEnds: '&',
                isRunning: '='
            },
            templateUrl: 'angular/app/modules/common/ui_components/video_player/video_player_template.html',
            controller: VideoPlayerDirectiveController,
            controllerAs: 'videoPlayerVm',
            bindToController: true
        };
    };


}());