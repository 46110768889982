

// @ngInject
Models.SearchableModel = function SearchableModel(BaseModel){
    var SearchableModel = Class(BaseModel, {

        constructor: function constructor(){
            this.__className = 'SearchableModel';
        },
        afterMixingFrom: function afterMixingFrom(){
        },

        mapChilds: function mapChilds(){
            return {};
        },

        /**
         * Override this.cachable when you don't want the models to be cached (always fetched from server)
         */
        isCachable: function isCachable() {
            return false;
        }

    });

    return SearchableModel;
};

