(function () {
    'use strict';

    // @ngInject
    function WorkspaceFileTimelineControllerCtor($scope, $stateParams, $injector, $q, $filter, _, AnalyticsService, AppStates, WorkspaceFilesManager, $timeout, ModalService,
                                                 WorkspaceFileService, WorkspacesManager, WorkspaceService, UsersManager, CompaniesManager, TemplatesViewService, PreviewModeService,
                                                 TimelineManager, PopupMessageService, AutosizeTextareaUpdater, uuid4, InjectBrandingService, AccountsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileTimelineController';
        this._ = _;

        var self = this;

        this.AnalyticsService = AnalyticsService;
        this.AppStates = AppStates;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspacesManager = WorkspacesManager;
        this.WorkspaceService = WorkspaceService;
        this.UsersManager = UsersManager;
        this.CompaniesManager = CompaniesManager;
        this.TimelineManager = TimelineManager;
        this.TemplatesViewService = TemplatesViewService;
        this.PopupMessageService = PopupMessageService;
        this.$q = $q;
        this.$timeout = $timeout;
        this.ModalService = ModalService;
        this.InjectBrandingService = InjectBrandingService;
        this.AccountsService = AccountsService;

        this.user = UsersManager.getCurrUser();
        this.isClient = this.user.isClient();

        this.workspaceFileId = $stateParams.workspace_file_id;
        this.workspaceFile = WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
        this.company = this.CompaniesManager.getCurrCompany();
        this.loading = true;
        this.updateTextareas = AutosizeTextareaUpdater;

        this.isOwnerMode = this.workspaceFile && this.workspaceFile.isOwnerMode();
        this.isClientMode = this.workspaceFile && this.workspaceFile.isClientMode();
        this.isPreviewMode = false;
        this.isEditableMode = false;
        this.canEdit = false;
        this.hasBeenSent = false;

        this.alreadyGotFile = false;
        this.alreadyGotWorkspace = false;
        this.$filter = $filter;
        this.linksTo = [];

        this.showDrawerOnLoad = WorkspaceFileService.shouldShowTemplatesMenu();

        PreviewModeService.quit();

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(self.AppStates.root_core_navigation_home);
            }.bind(this));
        });

        // Members validation
        var membersValidationId = uuid4.generate();
        this.WorkspaceFileService.addFileValidation(membersValidationId, function membersFileValidationFunc() {
            return this.WorkspaceFileService.membersFileValidation(this.workspaceFile, this.workspace);
        }.bind(this), 100);

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(membersValidationId);
        });
    }

    Controllers.WorkspaceFileTimelineController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileTimelineControllerCtor,

        saveFileImage: function saveFileImage(type) {
            var promise = this.WorkspaceFilesManager.setWorkspaceFileImage(this.workspaceFile, this.selectedFileImage);
            if (type === 'reposition') {
                this.reloadCarouselFlag = true;
                this.$timeout(function() {
                    this.company = this.CompaniesManager.getCurrCompany(true);
                    this.company.once('success', function success() {
                        this.reloadCarouselFlag = false;
                    }, this);
                }.bind(this));
            }
            return promise;
        },

        changeIcon: function changeIcon() {
            this.showIconLoader = true;
            this.ModalService.openUploadIconPhotoModal(this.company, true)
                .finally(function logoChangeFinally() {
                    // Instead of reloading the file we set the new uploaded company image
                    this.workspaceFile.owner.company.icon_image = this.company.icon_image;
                    this.showIconLoader = false;
                }.bind(this));
        },

        getFileClasses: function getFileCardClasses() {
            var fileClasses = this.WorkspaceFileService.getStatusClassesArray(this.workspaceFile);
            if (this.isPreviewMode) {
                fileClasses.push('preview-mode');
            }

            if (this.shouldShowAsideDrawer()) {
                fileClasses.push('aside-drawer-main');
            }

            return fileClasses;
        },

        gotWorkspaceFile: function gotWorkspaceFile(message, status) {

            if(this.workspaceFile && this.workspaceFile.company){
                this.AccountsService.verifyLoggedCompany(
                    this.workspaceFile.company._id,
                    this.workspaceFile.company.account_id,
                    'file',
                    this.workspaceFile.company.company_name
                );
                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color)
            }
            if (!this.alreadyGotFile) {
                this.isEditableMode = this.workspaceFile.isEditable();

                this.isOwnerMode = this.workspaceFile.isOwnerMode();
                this.isClientMode = this.workspaceFile.isClientMode();
                this.isViewOnlyMode = this.workspaceFile.isViewOnlyMode();
                if (!this.isOwnerMode && !this.isViewOnlyMode) {
                    this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.proposal_seen);
                }
                this.canEdit = this.allowEdit();
                this.showOwner = !this.isOwnerMode;

                this.loading = false;
                this.updateTextareas();

                this.hasBeenSent = this.workspaceFile.sent_on ? true : false;

                this.alreadyGotFile = true;

                this.loadFilmStrip();
                this.addTimelineSectionsToFilmstrip();

                this.updateSendFileText();

                if (!this.alreadyGotWorkspace) {
                    this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true);
                    this.alreadyGotWorkspace = true;
                }

                this.file_title = this.workspaceFile.file_title;
            } else {
                //update data fields view
                this.updateTextareas();
                this.addTimelineSectionsToFilmstrip();
                this.updateSendFileText();
            }

            this.hasBeenSent = this.workspaceFile.sent_on ? true : false;

            this.selectedFileImage = {};
            angular.extend(this.selectedFileImage, this.workspaceFile.workspace_file_image);

            this.AnalyticsService.trackPageView(this, 'workspace file', {file_type: this.workspaceFile.file_type});
        },

        allowEdit: function allowEdit() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        updateSendFileText: function updateSendFileText() {
            this.sendFileButtonText = this.WorkspaceFileService.getSendFileLabel(this.workspaceFile, this.workspace);
        },

        loadFilmStrip: function loadFilmStrip() {

            if (this.filmStripLoaded === true) {
                return;
            }

            this.filmStripLoaded = true;

            if (this.workspaceFile.isTimeline()){
                this.linksTo = [];
            }
        },

        sendFile: function sendFile() {
            return this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile).then(function _fileSendSuccess() {
                this.WorkspaceService.gotoWorkspace(this.workspace._id, this.workspace.event._id);
            }.bind(this));
        },

        printWorkspaceFile: function printWorkspaceFile() {
            this.WorkspaceFileService.printWorkspaceFile(this.workspaceFile);
        },

        addTimelineSectionsToFilmstrip: function addTimelineSectionsToFilmstrip (){
            var sections = this.workspaceFile.timeline_container.timeline_sections;
            this.linksTo = [];
            for( var i=0; i< sections.length; i++){
                if(!sections[i].is_empty || (this.isOwnerMode && !this.isPreviewMode)){
                    var label;
                    if (sections[i].section_title) {
                        label = sections[i].section_title;
                    } else if (sections[i].section_date) {
                        label = this.$filter('date')(sections[i].section_date, "MMM dd, yyyy");
                    } else {
                        label = 'TIMELINE._SECTION_TITLE_PLACEHOLDER_';
                    }
                    this.linksTo.push({label: label,
                        id:sections[i]._id,
                        element: "timelineSection-"+sections[i]._id,
                        actionable: true});
                }

            }

        },

        analyticsProperties: function analyticsProperties() {
            return {
                workspace_file_id: this.workspaceFileId
            };
        },

        analyticsPropertiesForSection: function analyticsPropertiesForSection(section) {
            var args = this.analyticsProperties();
            args.section_id = section._id;
            args.section_title = section.title;
            return args;
        },

        createTimelineSection: function createTimelineSection() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_timeline_section, this.analyticsProperties());
            return this.TimelineManager.createTimelineSection(this.workspaceFile);
        },

        deleteTimelineSectionById: function deleteTimelineSectionById(id){
            var self = this;
            var timelineSection = this._.find(this.workspaceFile.timeline_container.timeline_sections, function(section) {
                return section._id === id;
            });
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_timeline_section,
            this.analyticsPropertiesForSection(timelineSection));
            return this.TimelineManager.deleteTimelineSection(this.workspaceFile, timelineSection);
        },

        getTemplatesViewModel: function getTemplatesViewModel() {
            return this.TemplatesViewService.timeline;
        },

        onTemplateSelect: function onTemplateSelect(template, templateViewModel) {
            this.TemplatesViewService.applyTemplate(
                this.workspaceFile,
                template,
                templateViewModel.name,
                templateViewModel.applyFunction);
        },

        onSaveAsTemplate: function onSaveAsTemplate(templateViewModel) {

            var titleWrapper = {
                title: ''
            };

            this.ModalService.openTemplateSaveAsModal(titleWrapper, '', true,
                function success() {
                    this.AnalyticsService.track(this.$scope, 'click: save as template', {source: 'file'});
                    return templateViewModel.saveFromFileFunction(this.company, this.workspaceFile, titleWrapper.title);
                }.bind(this),
                function cancel() {
                    this.AnalyticsService.track(this.$scope, 'click: cancel save as template', {source: 'file'});
                }.bind(this)
            );
        },

        shouldShowAsideDrawer: function shouldShowAsideDrawer() {
            return this.isOwnerMode && !this.isPreviewMode;
        },

        isHeaderEditable: function isHeaderEditable() {
            return this.isOwnerMode && !this.isPreviewMode && !this.isViewOnlyMode && this.isEditableMode;
        }
    });
}());
