/**
 * Created by inonstelman on 3/3/15.
 */

// @ngInject
Services.AutosizeTextareaUpdater = function AutosizeTextareaUpdater($rootScope) {

    return function () {
        $rootScope.$broadcast('autosizeTextarea:update');
    };
};
