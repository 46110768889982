let ngInReactModule = angular.module('honeybook.ngInReact', ['ui.router', 'uuid4']);

function normalizeUrl(url) {
    const urlSplitted = url.split('?');
    const queryParamsString = urlSplitted[1];
    if (!queryParamsString) {
        return { url, path: urlSplitted[0] };
    }

    const queryParams = queryParamsString.split('&')
        .map(qp => qp.split('='))
        .filter(qps => qps.length === 2)
        .map(qps => qps.join('='))
        .join('&');
    const searchString = queryParams ? `?${queryParams}` : '';

    return { url: `${urlSplitted[0]}${searchString}`, path: urlSplitted[0] };
}

class NgInReactService {
    get isInIframe() {
        return window.top !== window.self;
    }

    constructor($state, uuid4) {
        this.$state = $state;
        this.uuid4 = uuid4;
        window.addEventListener('message', e => {
            if (typeof e.data !== 'object') {
                return;
            }

            this.processMessage(e.data);
        });
    }

    init() {
        this.sessionId = this.uuid4.generate();
        this.sendMessage('Loaded');
    }

    sendMessage(subject, payload) {
        window.parent.postMessage({ subject, sessionId: this.sessionId, payload }, '*');
    }

    sendUrlChange(_url) {
        const { url, path } = normalizeUrl(_url);
        console.warn('send url ', url, path)
        this.sendMessage('Navigation', { url, path });
    }

    sendBusy(state) {
        this.sendMessage('Busy', { state });
    }

    processMessage(message) {
        if (!this.sessionId || !message || message.sessionId !== this.sessionId) {
            return;
        }

        switch (message.subject) {
            case 'Navigation':
                this.navigate(message.payload);
                break;
        }
    }

    navigate(routerState) {
        const path = routerState.path;
        this.$state.go(
            path,
            Object.assign({}, routerState.params),
            { reload: true, inherit: false, location: false }
        );
    }
}

NgInReactService.$inject = ['$state', 'uuid4'];
ngInReactModule.service('ngInReactService', NgInReactService);
