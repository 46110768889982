    (function () {
    'use strict';

    // @ngInject
    Directives.PhoneNumberFormatter = function PhoneNumberFormatter($timeout) {

        function PhoneNumberFormatterLink(scope, element, attrs, ngModel) {
            if (element.length) {
                element.mobilePhoneNumber({
                    allowPhoneWithoutPrefix: '+1',
                });
            }

            scope.$watch(() =>  element.val(), (newVal, oldVal) => {
                ngModel.$setViewValue(newVal);
            });
        }

        return {
            require: 'ngModel',
            restrict: 'A',
            link: PhoneNumberFormatterLink
        };

    };
}());

