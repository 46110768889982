(function () {
    "use strict";

    Directives.CompanyTeamDirective =  function CompanyTeamDirective() {

        // @ngInject
        function CompanyTeamDirectiveControllerCtor($scope, $q, $injector, $filter, $state, $translate, AnalyticsService,
                                                    UsersManager, CompaniesManager, CompanyService, PopupMessageService,
                                                    PendingTasksManager, ReactModalService, FeaturesService, StatsigService,
                                                    Enums, AppStates, AccountsManager, moment, FeatureRestrictionService,
                                                    WebsocketHelperService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CompanyTeamDirectiveController';

            this.CompanyService = CompanyService;
            this.CompaniesManager = CompaniesManager;
            this.AccountsManager = AccountsManager;
            this.PopupMessageService = PopupMessageService;
            this.PendingTasksManager = PendingTasksManager;
            this.AnalyticsService = AnalyticsService;
            this.FeaturesService = FeaturesService;
            this.ReactModalService = ReactModalService;
            this.AppStates = AppStates;
            this.$state = $state;
            this.$translate = $translate;
            this.moment = moment;
            this.$q = $q;
            this.company = CompaniesManager.getCurrCompany();
            this.WebsocketHelperService = WebsocketHelperService;
            this.currUser = UsersManager.getCurrUser();
            this.companyPermissionTypes = CompanyService.getCompanyPermissionTypes();
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.restrictedFeature = this.FeatureRestrictionService.features.teamMembers;
            this.isTeamMembersFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
            this.isBookkeeperAccessEnabled = false;
            
            this.maxTeamMembersNb = this.FeatureRestrictionService.getFeatureVariation(this.restrictedFeature);
            this.shouldShowTeamMemberSeatsUsed = this.maxTeamMembersNb > 0 && this.maxTeamMembersNb !== 'unlimited';

            if (this.currUser.hasCompanySuperAdminPermissions()) {
                StatsigService.isGateEnabled('bookkeeper_access_enabled').then(function (gateEnabled) {
                    this.isBookkeeperAccessEnabled = gateEnabled;
                }.bind(this));
            }
 
            const unsubscribePricingPlanType = $scope.$watch('companySettingsTeamVm.currUser.account.subscription', function onFeatureRestrictionsChange(subscription) {
              if (subscription && subscription.pricing_plan && subscription.pricing_plan.plan_type) {
                const planType = subscription.pricing_plan.plan_type.split('_')[0];
                if (['essentials','starter'].includes(planType)) {
                    this.planType = planType.toUpperCase();
                }
            } else {
                this.planType = 'STARTER';
            }
            this.upgradeBannerTitle = this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.' + this.planType + '._TITLE_');
            this.upgradeBannerBenefits = [
                this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.' + this.planType + '.BENEFITS._BENEFIT1_'),
                this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.' + this.planType + '.BENEFITS._BENEFIT2_')
            ];
            if (this.planType === 'ESSENTIALS') {
                this.essentialsBannerTitleForMobile = this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.ESSENTIALS._TITLE_MOBILE_');
                this.essentialsBannerBenefitsForMobile =  [
                    this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.ESSENTIALS.BENEFITS_MOBILE._BENEFIT1_'),
                    this.$translate.instant('COMPANY_SETTING.TEAM.UPGRADE_PLAN.ESSENTIALS.BENEFITS_MOBILE._BENEFIT2_')
                ];
            }  else {
                this.essentialsBannerTitleForMobile = null;
                this.essentialsBannerBenefitsForMobile = null;
            }
            }.bind(this), true);

            const unsubscribeFeatureRestrictions = $scope.$watch('companySettingsTeamVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
                this.isTeamMembersFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
            }.bind(this), true);

            this.WebsocketHelperService.registerToRoom(this.company._id + ".invitation_completed", this.getPendingTeamInvites.bind(this));

            $scope.$on("$destroy", function () {
                unsubscribeFeatureRestrictions();
                unsubscribePricingPlanType();
                this.WebsocketHelperService.unregisterFromRoom(this.company._id + ".invitation_completed");
            });


            this.isAdmin = this.currUser.hasCompanyAdminPermissions();

            this.notificationTypesEnum = Enums.CompanyOwnerNotificationTypes;
            if (angular.isDefined(this.company.owner))
            {
                this.isOwner = this.company.owner._id === this.currUser._id;
                this.isSuperAdmin = this.currUser.hasCompanySuperAdminPermissions();
                this.hasTeamMembers = this.company.doesCurrUserHaveTeamMembers();
            }

            this.notificationTypes = [];
            angular.forEach(this.notificationTypesEnum, function(value, key) {
                this.push(
                    {text: value,
                        isOn: false,
                        type: key});
            }, this.notificationTypes);
            this.updateNotifications($filter);

            this.subscriptionChange = null;
            if (this.isOwner && this.currUser.companyHasWebSubscription()) {
                this.AccountsManager.getSubscriptionChange().then(function success(resp) {
                    this.subscriptionChange = resp.data;
                }.bind(this));
            }

            this.company.once('success', function gotCompany() {
                this.isAdmin = this.currUser.hasCompanyAdminPermissions();
                this.isOwner = this.company.owner._id === this.currUser._id;
                this.isSuperAdmin = this.currUser.hasCompanySuperAdminPermissions();
                this.hasTeamMembers = this.company.doesCurrUserHaveTeamMembers();
                this.updateNotifications($filter);
            }, this);

            this.addTeamMembers = function addTeamMembers() {
                    if (this.isTeamMembersFeatureRestricted) {
                        this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                        return;
                    }
                        this.AnalyticsService.trackClick(this,
                            this.AnalyticsService.analytics_events.add_team_member,
                            { variant: 'add team member test' }
                        );

                        this.ReactModalService.openInviteTeamMemberModal().then(function success(company) {
                            this.checkTeamMembersCount();
                        }.bind(this));

            };

            this.onEditRoleClick = function onEditRoleClick(teamMember) {
                const self = this;
                const currPermission = self.getUserCompanyPermission(teamMember).name;
                const data = { _id: teamMember._id, currPermission: currPermission};
                this.AnalyticsService.trackClick(this,
                    this.AnalyticsService.analytics_events.edit_team_member_permission,
                    {
                        variant: 'change permission test',
                        current_chosen_permission: currPermission
                    }
                );
                this.ReactModalService.openEditTeamMemberRoleModal(data).then(function (userPermissions) {
                    this.company = CompaniesManager.getCurrCompany(true);
                }.bind(this));
            };

            this.hasPermissionToRemoveAndEditTeamMember = function hasPermissionToRemoveAndEditTeamMember(teamMember) {
                var roleToEdit = this.getUserCompanyPermission(teamMember).name;
                var canEditSuperAdmins = this.isSuperAdmin || roleToEdit !== 'super_admin';
                var notEditingThemselves = teamMember._id !== this.currUser._id;
                var notEditingOwner = this.company.owner._id !== teamMember.id;
                return (this.isAdmin && notEditingThemselves && notEditingOwner && canEditSuperAdmins);
            };

            this.shouldDisplayEnableTeamMemberButton = function shouldDisplayEnableTeamMemberButton(teamMember) {
                return teamMember.is_disabled && this.isOwner;
            };

            this.companyDataViewModel = {};

            this.CompaniesManager.getCompanyAdditionalInfo(this.company).then(function getCompanyPrivateFields(res) {
                this.companyDataViewModel = res.data;
            }.bind(this));

            this.getPendingTeamInvites();
        }

        var CompanyTeamDirectiveController = Class(Controllers.BaseController, {
            constructor: CompanyTeamDirectiveControllerCtor,

            checkTeamMembersCount: function checkTeamMembersCount() {
                const defer = this.$q.defer();

                this.CompaniesManager.getCurrCompanyPromise(true).then(function getCompanySuccess(company) {
                    this.FeatureRestrictionService.updateFeatureRestrictions();
                    defer.resolve();
                }.bind(this));

                return defer.promise;
            },

            getUserCompanyPermission: function getUserCompanyPermission(user) {
                return this.CompanyService.getCompanyPermissionForUser(this.company, user);
            },

            getPendingTeamInvites: function getPendingTeamInvites(){
                if (!this.isAdmin){
                    return;
                }

                this.pendingCompanyInvites = [];
                this.CompaniesManager.getPendingCompanyTeamMemberInvite(this.company._id)
                    .then(function (res){
                        this.pendingCompanyInvites = (res.data || []);
                    }.bind(this));
            },

            setUserCompanyPermission: function setUserCompanyPermission(user, permission) {
                this.CompaniesManager.saveUserCompanyPermission(this.company, user, permission.name);
            },

            toggleNotification: function toggleNotification(index) {
                var NotificationObj = this.notificationTypes[index];
                this.company.setNotificationStatus(NotificationObj.type, NotificationObj.isOn);
            },

            updateNotifications: function updateNotifications($filter){
                var self = this;
                if (angular.isDefined(this.company.company_owner_notifications)){
                    angular.forEach(this.company.company_owner_notifications, function(notification) {
                        var found =  $filter('filter')(self.notificationTypes, {type: notification.notification_type_cd.toString()},true);
                        if (angular.isDefined(found) && found.length >0) {
                            found[0].isOn = notification.is_notification_on;
                        }
                    });
                }
            },

            shouldShowPendingInvites:function shouldShowPendingInvites(){
             return this.pendingCompanyInvites && this.pendingCompanyInvites.length;
            },

            toggleShareTeamCalendar: function toggleShareTeamCalendar() {
                if(!this.company.company_settings){
                    this.company.company_settings = {};
                }
                this.CompaniesManager.saveCompanySettings(this.company); //Error handling for now is just the glitch notification on the top right
            },


            deleteUserFromTeam: function deleteUserFromTeam(user) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'You are about to remove ' + user.email + ' from company team. All workspaces, files and sessions will be reassigned to company owner',
                    function clickedYeah() {
                        this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.confirm_team_member_removal);
                        this.removeUserFromTeamConfirmed(user);
                    }.bind(this),
                    function clickedNay(){
                        this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.not_confirm_team_member_removal);
                    }.bind(this)
                );
            },

            cancelPendingInvite: function cancelPendingInvite(invite_id) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'Are you sure you want to cancel this invitation?',
                    function clickedYeah() {
                        this.CompaniesManager.cancelPendingInvite(this.company._id, invite_id)
                            .then(function success(res){
                                this.getPendingTeamInvites();
                            }.bind(this))
                            .catch(function err(res){
                                this.PopupMessageService.showAlert(
                                    this.PopupMessageService.severityTypes.error, "Error while trying to cancel the invitation. Please contact us.");
                            });
                    }.bind(this),
                    function clickedNay(){
                    }.bind(this)
                );
            },

            removeUserFromTeamConfirmed: function removeUserFromTeamConfirmed(user) {
                var self = this;

                self['deleting' + user._id] = true;
                var taskData = {
                    company_id: self.currUser.company._id,
                    member_id: user._id,
                    performing_user_id: this.currUser._id
                };

                //we save the promise so that the polling could be canceled once we destroy the button. the promise is the token to stop the polling
                self.pendingTaskPromise = self.PendingTasksManager.createPendingTask(self.PendingTasksManager.pendingTasksTypes.removeTeamMember, taskData);
                self['deleting' + user._id] = true;

                return self.pendingTaskPromise.then(
                    function success(resp) {
                        // self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info, "User removed");
                        self['deleting' + user._id] = false;
                        self.company.removeTeamMember(user._id);
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.remove_team_member_success, taskData);

                        self.checkTeamMembersCount();
                        if (angular.isDefined(self.company.owner))
                        {
                            self.hasTeamMembers = self.company.doesCurrUserHaveTeamMembers();
                        }
                    })
                    .catch(function fail(resp) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, resp);
                        self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.remove_team_member_error, resp);
                    })
                    .finally(function finallyHandler() {
                        self['deleting' + user._id] = false;
                    });
            },

            updateCompanyData: function updateCompanyData() {
                this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.company_additional_data_updated, this.companyDataViewModel);
                this.CompaniesManager.updateCompanyAdditionalInfo(this.company, this.companyDataViewModel).catch(function (resp){
                    var message;
                    if (resp && resp.data && resp.data.error_message) {
                        message = resp.data.error_message;
                    } else {
                        message = 'ERRORS.SERVER_API._UNEXPECTED_';
                    }
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                    this.AnalyticsService.trackError(this, message, resp);
                }.bind(this))
            },

            enableTeamMember: function enableTeamMember(teamMemberId) {
                if (this.isTeamMembersFeatureRestricted) {
                    this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                    return;
                }

                this.isEnableTeamMemberLoading = true;

                this.CompaniesManager.enableCompanyTeamMember(this.company._id, teamMemberId).then(function enableCompanyTeamMemberSuccess() {
                    this.checkTeamMembersCount().finally(function finallyCheckTeamMembers() {
                        this.isEnableTeamMemberLoading = false;
                    }.bind(this));
                }.bind(this));
            }
        });

        return {
            scope:{},
            templateUrl : 'angular/app/modules/core/features/settings/company/team/company_team_directive_template.html',
            controller : CompanyTeamDirectiveController,
            controllerAs : 'companySettingsTeamVm',
            bindToController : true
        };

    };
}());
