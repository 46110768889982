Directives.RadialProgressBar = function RadialProgressBar() {

    //@ngInject
    function RadialProgressBarDirectiveControllerFunc($scope, $injector, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'RadialProgressBarDirectiveController';

        // Firefox has a bug where it doesn't handle rotations and stroke dashes correctly.
        // https://bugzilla.mozilla.org/show_bug.cgi?id=949661
        this.offset = /firefox/i.test(navigator.userAgent) ? -89.9 : -90;

        var updateRadius = function () {
            this.strokeWidthCapped = Math.min(this.strokeWidth, this.size / 2 - 1);
            this.radius = Math.max((this.size - this.strokeWidthCapped) / 2 - 1, 0);
            this.circumference = 2 * Math.PI * this.radius;
        }.bind(this);
        $scope.$watchCollection('[size, strokeWidth]', updateRadius);
        updateRadius();

        this.dashOffset = this.circumference;
        $timeout(function() {
            this.dashOffset =  (1 - this.complete()) * this.circumference;
        }.bind(this), 2000);

    }

    var RadialProgressBarDirectiveController = Class(Controllers.BaseController, {
        constructor: RadialProgressBarDirectiveControllerFunc
    });

    return {
        restrict: 'E',
        scope: {
            size:             '@', // Size of element in pixels.
            strokeWidth:      '@', // Width of progress arc stroke.
            stroke:           '@', // Color/appearance of stroke.
            complete:         '&', // Expression evaluating to float [0.0, 1.0]
            background:       '@', // Color of the background ring. Defaults to null.
            boxShadowColor:   '@',
            animate:          '='  // Boolean, if true the progress bar animates to fill on init
        },
        templateUrl : 'angular/app/modules/common/ui_components/radial_progress/radial_progress_bar_directive_template.html',
        controller: RadialProgressBarDirectiveController,
        controllerAs: 'rpbVm',
        bindToController: true
    };
};
