//// unfinished
//(function () {
//
//
//    Directives.DropDirective = function DropDirective() {
//
//        // @ngInject
//
//        function DropDirectiveCtor($element, $scope, $injector, $compile, $interpolate, $parse, Drop) {
//            this.constructor.$super.call(this, $scope, $injector);
//            this.__objectType = 'DropDirectiveController';
//
//            var target = this.target ? this.target : $element[0];
//
//            if (this.compilingRequired) {
//                this.content = $compile(this.content)($scope)[0];
//            }
//
//            var drop = new Drop({
//                target: target,
//                openOn: 'click',
//                classes: 'drop',
//                content: this.content,
//                position: this.position,
//                remove: true
//            });
//        }
//
//        var DropDirectiveController = Class(Controllers.BaseController, {
//            constructor: DropDirectiveCtor
//
//        });
//
//        return {
//            scope: {
//                position: '@',
//                content: '@',
//                target: '@',
//                class: '@',
//                compilingRequired: '@'
//            },
//            template: this.outerContent,
//            controller: DropDirectiveController,
//            controllerAs: 'dropVm',
//            bindToController: true
//        };
//    };
//}());
