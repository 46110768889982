Directives.TimeTrackerDirective = function TimeTrackerDirective() {

    // @ngInject
    function TimeTrackerDirectiveControllerFunc($injector, $scope, ModalService, TimeTrackingManager, UsersManager, AnalyticsService, TimeTrackingService, Enums, ReactModalService, StatsigService, WorkspacesManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerDirectiveController';
        this.ModalService = ModalService;

        this.UsersManager = UsersManager;
        this.TimeTrackingManager = TimeTrackingManager;
        this.user = UsersManager.getCurrUser();
        this.AnalyticsService = AnalyticsService;
        this.TimeTrackingService = TimeTrackingService;
        this.reactModalService = ReactModalService;
        this.WorkspacesManager = WorkspacesManager;
        this.Enums = Enums;
        this.entriesTrackedHours = 0;
        this.timeTrackerEntryList = [];
        this.progressBar = '100%';


        this.CONSTANTS = {
            VIEW_ALL_ENTRIES : 'view_all_entries',
            CREATE_INVOICE : 'create_invoice',
        }

        StatsigService.isGateEnabled('ng2react_time_tracker').then(function (result) {
            this.showReactTimeTrackerModal = result;
        }.bind(this));

        if (this.workspace && !this.workspace.event) {
            this.workspace = this.WorkspacesManager.getWorkspace(this.workspace._id);
        }

        this.registerOnce(this.workspace, 'success', this.workspaceLoaded);
    }

    var TimeTrackerDirectiveController = Class(Controllers.BaseController, {
        constructor: TimeTrackerDirectiveControllerFunc,

        workspaceLoaded: function workspaceLoaded() {
            if (!this.workspace.event) {
                this.registerOnce(this.workspace, 'success', this.workspaceLoaded);
            }
            this.workspace.event && this._fetchEntries();
        },

        _fetchEntries: function _fetchEntries() {
            return this.TimeTrackingManager.fetchForProject(this.user.company, this.workspace.event)
                .then(function(resp) {
                    this.timeTrackerEntryList.splice(0);
                    this.timeTrackerEntryList = resp.data;
                }.bind(this)).then(this._calculateTotals.bind(this));
        },


        viewAllEntries: function viewAllEntries() {
            if (this.showReactTimeTrackerModal) {
                const project = {
                    event_id: this.workspace.event._id,
                    event_name:this.workspace.event.event_name
                };
            this.reactModalService.openTimeTrackerWorkspaceModal({project: project, viewType: 'viewAllEntries'}).then(function () {
                this._fetchEntries();
            }.bind(this));
                return;
            }
            this.ModalService.openTimeTrackerViewAllModal(this.workspace, this.CONSTANTS.VIEW_ALL_ENTRIES, false )
                .then(function(resp) {
                    this.timeTrackerEntryList.splice(0);
                    this.timeTrackerEntryList = resp;
                }.bind(this)).then(this._calculateTotals.bind(this));
        },

        addNewEntry: function addNewEntry() {
            this.timeTracakerEntry = {};
            this.shouldShowActiveView = true;

            this.AnalyticsService.trackClick(this, 'time_tracker_log_new', {
                                                    source: "time_tracker_workspace",
                                                    type: "list",
                                                    app_path: "time_tracker_workspace",
                                                    event: "time_tracker_log_new",
                                                    eventType: "click"});


            if (this.showReactTimeTrackerModal) {
                const project = {
                    event_id: this.workspace.event._id,
                    event_name:this.workspace.event.event_name
                };
            this.reactModalService.openTimeTrackerWorkspaceModal({project: project, viewType: 'addNewEntry'}).then(function () {
                this._fetchEntries();
            }.bind(this));
                return;
            }
            if (this.workspace.event){
                this.ModalService.openTimeTrackerEntryModal(this.workspace.event._id, this.timeTracakerEntry, this.Enums.timeTrackerSource.workspace)
                    .then(function success(resp) {
                        this._onEntryAdded(resp.data);
                }.bind(this));
            }
        },

        _onEntryAdded: function _onEntryAdded(entry) {
            this.timeTrackerEntryList.push(entry);
            this._calculateTotals();
        },

        _calculateTotals: function _calculateTotals() {
            this.entriesTotalAmount = this.TimeTrackingService.getTotalAmount(this.timeTrackerEntryList);
            this.entriesTrackedHours = this.TimeTrackingService.getTotalHours(this.timeTrackerEntryList);
            this.shouldShowActiveView = this.timeTrackerEntryList.length;
        },

        activateInitialView: function activateInitialView() {
            this.shouldShowActiveView = true;
        },

        createInvoice: function createInvoice () {
            if (this.showReactTimeTrackerModal) {
                const project = {
                    event_id: this.workspace.event._id,
                    event_name:this.workspace.event.event_name
                };
                this.reactModalService.openTimeTrackerWorkspaceModal({project: project, viewType: 'createInvoice'}).then(function () {
                    this._fetchEntries();
                }.bind(this));
                return;
            }
            this.ModalService.openTimeTrackerViewAllModal(this.workspace, this.CONSTANTS.CREATE_INVOICE, false );

        }

    });

    return {
        scope: {
            workspace: '='
        },
        templateUrl: 'angular/app/modules/core/features/time_tracker/time_tracker_project/time_tracker_directive_template.html',
        controller: TimeTrackerDirectiveController,
        controllerAs: 'timeTrackerVm',
        bindToController: true
    };
};
