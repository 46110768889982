(function () {
    "use strict";

    // @ngInject
    function BlockTwoColTextControllerFunc($injector, $scope, $sce, AnalyticsService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockTwoColTextDirectiveController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;
        this._init();
    }

    var BlockTwoColTextDirectiveController = Class(Controllers.BaseController, {
        constructor: BlockTwoColTextControllerFunc,

        _init: function _init() {
            var color = this.block.file_block_style ? this.block.file_block_style.background_color : '#ffffff';
            this.setBlockBackground(color);
        },

        updateBlock: function updateBlock(field) {
            this.block.updateBlock([field]);
        },

        updateBlockContent: function updateBlock(field) {
            this.updateBlock(field);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'content', block_type: "two column text"});
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        updateBlockBackground: function updateBlockBackground() {
            this.updateBlock('file_block_style');
        },

        focusContent: function focusContent(isFocus) {
            this.isContentFocus = isFocus;
        },

        /* Color picker */
        onBackgroundChange: function onBackgroundChange(color) {
            this.setBlockBackground(color);
            this.updateBlockBackground();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'background color', block_type: "two column text"});
        },

        setBlockBackground: function setBlockBackground(color) {
            this.styles = {
                'backgroundColor': color
            };
        },

        onPickerShown: function onPickerShown(color) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.showed_block_color_picker, {block_type: "two column text"});
        },
    });

    Components.BlockTwoColText = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_two_col_text/block_two_col_text.html',
        controller: BlockTwoColTextDirectiveController,
        controllerAs: 'blockTwoColTextVm',
        name: 'hbBlockTwoColText'
    };

}());



