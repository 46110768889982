(function () {
    "use strict";

    Controllers.BaseController = Class(function () {

        return {

            constructor: function ($_scope, $injector) {
                this.$scope = $_scope;
                this.$state = $_scope ? $_scope.$state : undefined;
                this.AppStates = $_scope ? $_scope.appStates : undefined;
                this.Constants = $_scope ? $_scope.constants : undefined;
                this.__modelListeners = [];

                if($injector){
                    var appConfig = $injector.get('AppConfigService');
                    this.appState = appConfig.appState();
                    this.analyticsService = $injector.get('AnalyticsService');
                    this.appConfig = appConfig;
                    this.Hash = $injector.get('Hash');
                    $injector.get('AngularControllerUsageService').registerUsage(this);
                }


                this.__objectType = 'BaseController';
                this.__baseType = 'BaseController';

                this.$parent = $_scope ? $_scope.$parent : undefined;
                //self.analyticsName = 'BaseController';

                if (this.$scope) {

                    // remove all listeners on destruction to avoid leaks
                    this.$scope.$on('$destroy', function () {
                        if(this._analyticsProperties){
                            this.analyticsService.removeAnalyticsProperties(this._analyticsProperties);
                        }

                        this.__modelListeners.forEach(function(model) {
                            model.offAll(this);
                        }.bind(this));

                        if (typeof this['beforeDestroy'] === 'function'){
                            this['beforeDestroy']();
                        }
                    }.bind(this));
                }
            },

            __objectType: function __objectType() {
                return this.__objectType;
            },

            __baseType: function _baseType() {
                return this.__baseType;
            },

            _hash: function _hash() {
                if (!this.__hash) {
                    this.__hash = this.Hash();
                }

                return this.__hash;
            },

            goToState: function goToState(to, params, options) {
                this.$state.go(to, params, options);
            },

            register: function register(model, event, callback) {
                model.on(event, callback, this);
                this._register(model);
            },

            unregister: function unregister(model, event) {

                if (event) {
                    model.off(event, null, this);
                }
                else {
                    var index = this.__modelListeners.indexOf(model);

                    if (index !== -1) {
                        this.__modelListeners.splice(index, 1);
                        model.offAll(this);
                    }
                }
            },

            registerOnce: function registerOnce(model, event, callback) {
                model.once(event, callback, this);
                this._register(model);
            },

            _register: function _register(model) {
                if (this.__modelListeners.indexOf(model) === -1) {
                    this.__modelListeners.push(model);
                }
            }
        };
    });
}());
