(function () {
    "use strict";

    // @ngInject
    function ClientPortalServiceCtor(CompanyService, _, $window) {
        this.CompanyService = CompanyService;
        this._ = _;
        this.$window = $window;
    }

    Services.ClientPortalService = Class(() => {

        return {
            constructor: ClientPortalServiceCtor,


            getDefaultClientPortalConfiguration(company) {
                let companyLogo = this.CompanyService.isDefaultCompanyLogo(company) ? false : company.logo_image;
                let companyIcon = this.CompanyService.isDefaultCompanyIcon(company) ? false : company.icon_image;
                let showLogo = !(!companyLogo && !companyIcon);
                let defaultType = companyLogo ? 'square' : 'rounded';

                let defaultData = {
                    title: company.company_name,
                    background_color: '#FBFBFB',
                    has_logo: !!companyLogo,
                    has_icon: !!companyIcon,
                    logo: {
                        show: showLogo,
                        type: defaultType,
                        logo_url: company.logo_image && company.logo_image.url,
                        icon_url: company.icon_image && company.icon_image.url
                    },
                    brand: {
                        brand_color: company.brand_color,
                        text_color: company.text_color
                    }
                };

                if (company.client_portal_configuration) {
                    return this._.extend(defaultData, company.client_portal_configuration);
                }

                return defaultData;
            },

            formatConfigurationForRequest(model) {
                return  {
                    background_color: model.background_color,
                    show_logo: model.logo.show,
                    logo_type: model.logo.type
                };
            },

            isInClientPortal() {
                return !!this.$window.clientPortalConfiguration;
            },

            getConfiguration() {
                return this.$window.clientPortalConfiguration;
            }
        };
    });
}());

