(function () {
    "use strict";


    var progressBarTemplate = '<div class="notification-progress-container active">\
  <div class="notification-progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">\
  </div>\
</div>';

    // @ngInject
    function OnScreenNotificationServiceCtor(pnotify, AnalyticsService) {

        this.pnotify = pnotify;
        this.AnalyticsService = AnalyticsService;
    }


    Services.OnScreenNotificationService = Class(function () {

        return {

            types: {
                info: 'info',
                warn: 'notice',
                success: 'success',
                error: 'error'
            },

            stack: {"dir1": "up", "dir2": "left", "push": "top"},


            constructor: OnScreenNotificationServiceCtor,

            createOnScreenNotification: function createOnScreenNotification(type, text, timeout, progressStartValue) {

                var delay = timeout ? timeout : 99999999999999;

                var notif = new this.pnotify({
                    title: text,
                    type: this.types[type],
                    destroy: true,
                    remove: true,
                    hide: true,
                    delay: delay,
                    addclass: "stack-bottomright",
                    stack: this.stack,
                    buttons: {
                        closer: true,
                        closer_hover: true,
                        sticker: false
                    }

                });

                if (typeof progressStartValue !== 'undefined') {
                    var progressBar = progressBarTemplate;
                    notif.update({text: progressBar});
                    var progress = notif.get().find("div.notification-progress-bar");
                    progress.width(progressStartValue + "%").attr("aria-valuenow", progressStartValue);
                }

                return notif;
            },

            updateOnScreenNotification: function updateOnScreenNotification(notification, type, text, timeout, progressValue) {

                if (text) {
                    notification.update({
                        title: text,
                    });
                }

                if (type) {
                    notification.update({
                        type: this.types[type]
                    });
                }


                if (timeout) {
                    notification.update({delay: timeout});
                    notification.queueRemove();

                }

                if (typeof progressValue !== 'undefined') {
                    var progress = notification.get().find("div.notification-progress-bar");
                    if(progress){
                        progress.width(progressValue + "%").attr("aria-valuenow", progressValue);
                    }

                }

                return notification;
            },

            addPendingTaskToNotification: function addPendingTaskToNotification(notification, pendingTaskPromise, successMessage, failureMessage, successAnalytics, failureAnalytics) {

                pendingTaskPromise.then(
                    function success(data) {

                        this.updateOnScreenNotification(notification, this.types.success, successMessage, 4000, 100);
                        this.AnalyticsService.trackSuccess(this, successAnalytics);
                    }.bind(this),
                    function error(data) {
                        if (data && data.includes("Sorry")) {
                            failureMessage = data;
                        }
                        this.updateOnScreenNotification(notification, this.types.error, failureMessage, 600000);
                        this.AnalyticsService.trackError(this, failureAnalytics);
                    }.bind(this),
                    function notify(data) {
                        this.updateOnScreenNotification(notification, null, null, null, parseInt(data) || 0);
                    }.bind(this)
                );

            }
        };


    });
}());
