/**
 * Created by inonstelman on 12/28/14.
 */

(function () {
    "use strict";

    // @ngInject
    function APIServiceCtor($http, RepositoryService, $q, AxiosInterceptorService, Gon, PubSubService, uuid4, AppConfigService, _, DatadogRUMService, RollingWindowThrottleService, AnalyticsService) {
        this.$http = $http;
        this.RepositoryService = RepositoryService;
        this.$q = $q;
        this.Gon = Gon;
        this._ = _;
        this.AnalyticsService = AnalyticsService;
        this.AppConfigService = AppConfigService;
        this.RollingWindowThrottleService = RollingWindowThrottleService;
        this.DatadogRUMService = DatadogRUMService;
        this.uuid = uuid4;
        // Axios interceptor for request
        axios.interceptors.request.use(
            AxiosInterceptorService.request,
            error => {
                return Promise.reject(error);
            }
        );

        // Axios interceptor for response
        axios.interceptors.response.use(
            AxiosInterceptorService.response
            ,
            error => {
                return Promise.reject(error);
            }
        );
        PubSubService.ventMyBitchUp(this);
    }

    Services.APIService = Class(function () {

        return {

            constructor: APIServiceCtor,

            _performRequest: function _performRequest(method, url, data, headers) {

                let useAxios = this.Gon.should_use_axios && !!window.axios

                var reqHeaders = {};

                if (headers) {
                    angular.extend(reqHeaders, headers);
                }

                if (!this._.isEmpty(this.Gon.api_urls_prefix)) {
                    url = this.Gon.api_urls_prefix + url;
                }

                var req;
                if (method === 'GET' || method === 'DELETE') {

                    // Add cache buster to all AJAX GET requests because some fucked-up browsers (Safari) cache them (????)
                    // http://stackoverflow.com/questions/9948545/how-to-disable-ajax-caching-in-safari-browser
                    //
                    // Because of the angular templates cache we cannot use an HTTP Interceptor

                    // 15/05/16 - Doron: reverted this change as it needs to be fixed by proper headers,
                    // and not by query params. However, I cannot reproduce this bug so for now - no new
                    // headers are set.
                    // if (method === 'GET') {
                    //     if (!angular.isObject(data)) {
                    //         data = {};
                    //     }
                    //     data['nocache'] = +(new Date());
                    // }
                    if (method === 'GET' && !useAxios) {
                        if (angular.isObject(data)) {
                            // modify keys that are of type array to syntax that allows sending an array over url params
                            var deleteKeys = [];

                            Object.keys(data).forEach(function (key) {
                                if (Array.isArray(data[key])) {
                                    data[key + "[]"] = data[key];
                                    deleteKeys.push(key);
                                }
                            });

                            deleteKeys.forEach(function (key) {
                                delete data[key];
                            });
                        }
                    }

                    req = {
                        method: method,
                        url: url,
                        headers: reqHeaders,
                        params: data
                    };
                } else {
                    req = {
                        method: method,
                        url: url,
                        headers: reqHeaders,
                        data: data
                    };
                }

                req['withCredentials'] = true;
                req['timeout'] = 30000; // 30 seconds
                var defer = this.$q.defer();
                var self = this
                if (useAxios) {
                    const RETRY_COUNT = 3;
                    var reqIdentifier = self.uuid.generate();

                    function makeRequestWithRetry(retriesLeft) {
                        axios(req)
                            .then(function (response) {
                                // Handle Axios success
                                defer.resolve({
                                    data: response.data,
                                    status: response.status,
                                    headers: response.headers,
                                    config: response.config,
                                    statusText: response.statusText
                                });


                                self.trigger('success', response.data, response.status, response.headers, response.config, response.statusText);
                            }.bind(self))
                            .catch(function (error) {
                                // Handle Axios error
                                var resp = {
                                    data: error.response ? error.response.data : null,
                                    status: error.response ? error.response.status : 0,
                                    headers: error.response ? error.response.headers : {},
                                    config: error.config,
                                    statusText: error.response ? error.response.statusText : null,
                                    stopEvents: false,
                                    isAxiosError: error.isAxiosError, // Indicates if the error is an Axios error
                                    stack: error.stack,
                                    cause: error.cause ? JSON.stringify(error.cause) : null, // Stack trace of the error
                                    message: error.message,
                                    causeStack: error.causeStack ? error.causeStack : null, // Stack trace of the cause
                                    code: error.code ? error.code : null,
                                    retryCount: 3 - retriesLeft, //  3- 3 would be before retry
                                    reqIdentifier: reqIdentifier
                                };
                                if (resp.status === 0) {
                                    this.DatadogRUMService.addError(new Error("Error in axios request", {cause: error}), {
                                        resp: resp
                                    });
                                }
                                if ((error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED') && retriesLeft > 0) {
                                    this.DatadogRUMService.addError(new Error("retrying axios request", {cause: error}), {
                                        code: error.code,
                                        reqIdentifier: reqIdentifier,
                                        retryCount: 3 - retriesLeft,
                                        method: method.toUpperCase(),
                                    })
                                    let delay = (4 - retriesLeft) * 100;

                                    setTimeout(function () {
                                        makeRequestWithRetry(retriesLeft - 1);
                                    }, delay);

                                } else {

                                    if (resp.status === -1 || resp.status !== 404) {
                                        self.AnalyticsService.track(self, 'ApiService pipeline http error with axios', {
                                            data: JSON.stringify(resp.data).toString(),
                                            status: resp.status,
                                            headers: resp.headers.toString().replace(/(\r\n|\n|\r)/gm, ""),
                                            config: error.config && JSON.stringify(error.config).toString().replace(/(\r\n|\n|\r)/gm, ""),
                                            statusText: resp.statusText
                                        });
                                    }

                                    self.trigger('error*', resp.data, resp.status, resp.headers, resp.config, resp.statusText);
                                    if (resp.data && resp.data.error_type) {
                                        self.trigger(resp.data.error_type, resp.data, resp.status, resp.headers, resp.config, resp.statusText);
                                    }
                                    defer.reject(resp);
                                }
                            }.bind(self));
                    }

                    makeRequestWithRetry(RETRY_COUNT);
                } else {
                    this.$http(req)
                        .success(function (data, status, headers, config, statusText) {
                            defer.resolve({
                                data: data,
                                status: status,
                                headers: headers,
                                config: config,
                                statusText: statusText
                            });

                            this.trigger('success', data, status, headers, config, statusText);

                        }.bind(this))
                        .error(function (data, status, headers, config, statusText) {

                            var resp = {
                                data: data,
                                status: status,
                                headers: headers,
                                config: config,
                                statusText: statusText,
                                stopEvents: false
                            };

                            if (JSON.stringify(config).toString().indexOf("pipeline") !== -1 && status !== 404) {
                                this.AnalyticsService.track(this, 'ApiService pipeline http error', {
                                    data: JSON.stringify(data).toString(),
                                    status: status,
                                    headers: headers.toString().replace(/(\r\n|\n|\r)/gm, ""),
                                    config: JSON.stringify(config).toString().replace(/(\r\n|\n|\r)/gm, ""),
                                    statusText: statusText
                                });
                            }

                            // if (status === -1) {
                            //     this.AnalyticsService.track(this, 'ApiService http error', {data: JSON.stringify(data).toString(), status: status, headers: headers.toString().replace(/(\r\n|\n|\r)/gm, ""), config: JSON.stringify(config).toString().replace(/(\r\n|\n|\r)/gm, ""), statusText: statusText});
                            // }

                            this.trigger('error*', data, status, headers, config, statusText);
                            if (data && data.error_type) {
                                this.trigger(data.error_type, data, status, headers, config, statusText);
                            }

                            defer.reject(resp);

                        }.bind(this));
                }

                var throttleRes = this.RollingWindowThrottleService.addEntryAndCheckIfLimitHit(url, this.RollingWindowThrottleService.generateOptions(40, 60000));

                if (throttleRes) {
                    //a request was performed more than 40 times in a minute - sending to DD for analysis
                    this.DatadogRUMService.addError(
                        new Error("Request limit was hit"),
                        {
                            message: "Request limit was hit. url: " + url + ", method(last): " + method + ", invocationCount: " + throttleRes.count + ", rollingWindowMS: " + throttleRes.options.windowTimeMS
                        }
                    );
                }

                return defer.promise;

            },

            fetch: function fetch(url, data, headers) {
                return this._performRequest('GET', url, data, headers);
            },

            create: function create(url, data, headers) {
                return this._performRequest('POST', url, data, headers);
            },

            update: function update(url, data, headers) {
                return this._performRequest('PUT', url, data, headers);
            },

            destroy: function destroy(url, data, headers) {
                return this._performRequest('DELETE', url, data, headers);
            }
        };
    });

}());
