(function () {

    "use strict";

    // @ngInject
    function FacebookLeadsControllerCtor($scope, $injector, $log, ModalService, $, $rootScope, CompanyService, AnalyticsService, CompaniesManager, FacebookService,
                                         AppStates, moment, $translate, $timeout, UsersManager, _, PopupMessageService, $window, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FacebookLeadsController';
        this.ModalService = ModalService;
        this.AnalyticsService = AnalyticsService;
        this.FacebookService = FacebookService;
        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.$window = $window;
        this.DatadogRUMService = DatadogRUMService;

        this._ = _;
        this.$log = $log;

        this.company = CompaniesManager.getCurrCompany();
        this.currentUser = this.UsersManager.getCurrUser();

        this.showFetchedPages = false;
        this.companyLeadPages = [];
        this.availablePages = [];
        this.initializedPages = false;
        this.loadingPages = false;

        this.refreshLeadPages();

        this.emptyStatePrimaryCtaAnalyticsData = {
            eventName: this.AnalyticsService.analytics_events.action,
            data: {
                page: 'facebook_leads',
                unique: 'empty_state_primary_cta'
            }
        };
        this.emptyStateSecondaryCtaAnalyticsData = {
            eventName: this.AnalyticsService.analytics_events.action,
            data: {
                page: 'facebook_leads',
                unique: 'empty_state_secondary_cta'
            }
        };

    }

    Controllers.FacebookLeadsController = Class(Controllers.BaseController, {

        constructor: FacebookLeadsControllerCtor,

        refreshLeadPages: function refreshLeadPages() {
            this.loadingPages = true;
            return this.CompaniesManager.getFacebookLeadPages(this.company)
                .then(function success(resp) {
                        this.showFetchedPages = false;
                        this.selectedPage = false;
                        this.companyLeadPages = resp.data;
                        this.showEmptyState = this.companyLeadPages.length === 0;
                        this.loadingPages = false;
                        this.initializedPages = true;
                    }.bind(this),
                    function error() {

                    });
        },

        onPageDelete: function onPageDelete(page) {
            this.companyLeadPages = this.companyLeadPages.filter(function (item) {
                return item._id !== page._id;
            });
        },

        isConnectedToFacebook: function isConnectedToFacebook() {
            var account = this._.findWhere(this.currentUser.social_network_accounts, {social_network_type: 'facebook'});
            return !!account;
        },

        openLearnMore: function openLearnMore(){
            this.$window.open("https://www.facebook.com/business/a/lead-ads", '_blank');
        },

        openAddPageModal: function openAddPageModal() {

            this.FacebookService.loginToFacebook(this.FacebookService.permissions_types.lead_ads)
                .then(function goodToGo() {
                    this.FacebookService.showFbPages().then(
                        function success(result) {
                            this.full_result = result[0];
                            this.user_access_token = result[1];
                            this.availablePages = result[0].map(function (a) {
                                return a.name;
                            });
                            this.DatadogRUMService.addError(new Error('Facebook pages result'), {full_result: JSON.stringify(JSON.stringify(result[0]))});
                            this.showFetchedPages = true;

                            // get pages that are not connected
                            var ids = this._.map(this.companyLeadPages, function (v) {
                                return v._id;
                            });
                            var availablePagesToAdd = this._.filter(this.full_result, function (facebookPage) {
                                return ids.indexOf(facebookPage.id) === -1;
                            }.bind(this));

                            var paramsToPassToModel = {
                                availablePages: this.availablePages,
                                full_result: availablePagesToAdd,
                                pageConfirmed: this.pageConfirmed.bind(this)
                            };

                            if (availablePagesToAdd.length === 0) {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'FACEBOOK_LEADS._NO_PAGES_TO_ADD_');

                            } else {
                                this.ModalService.openFacebookLeadsModal(paramsToPassToModel)
                                    .then(function success(result) {
                                    }.bind(this))
                                    .finally(function final() {

                                    }.bind(this));
                            }


                        }.bind(this))
                        .catch(function error(e) {
                            this.DatadogRUMService.addError(
                                e,
                                {
                                    user_id: this.currentUser._id,
                                    message: 'An error occurred while fetching facebook pages'
                                }
                            );
                            this.$log.error(e);
                        }.bind(this))
                        .finally(function () {
                        }.bind(this));
                }.bind(this))
                .catch(function error(e) {
                    this.$log.error(e);
                    this.DatadogRUMService.addError(
                        e,
                        {
                            user_id: this.currentUser._id,
                            message: 'An error occurred while connecting to facebook'
                        }
                    );
                }.bind(this))
                .finally(function () {
                }.bind(this));
        },

        pageConfirmed: function pageConfirmed(selectedPage) {
            this.loadingPages = true;
            return this.CompaniesManager.setFacebookLeadsPage(selectedPage, this.user_access_token)
                .finally(function final() {
                    this.refreshLeadPages();
                }.bind(this));
        },
    });

}());
