(function () {
    "use strict";

    // @ngInject
    function AccountsServiceCtor(AccountsManager, AppStates, $state, PubSubService, Enums, UsersManager, SplashScreenService,
                                 CompaniesManager, PopupMessageService, FeatureRestrictionService) {
        this.AccountsManager = AccountsManager;
        this.AppStates = AppStates;
        this.$state = $state;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.SplashScreenService = SplashScreenService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        PubSubService.ventMyBitchUp(this);
    }

    Services.AccountsService = Class(() => {

        return {
            constructor: AccountsServiceCtor,

            createCompany(accountId, companyType, companyName) {
                this.SplashScreenService.showHB();
                return this.AccountsManager.createCompany(accountId, companyType, companyName);
            },

            switchCompany(companyId, keepState) {
                return this.SplashScreenService.showHB().then(() => {
                    return this.AccountsManager.switchCompany(companyId);
                }).then(() => {
                    this.trigger(this.Enums.PubSubTriggers.companyChanged);
                    this.SplashScreenService.hideHB();
                    if (!keepState) {
                        this.$state.go(this.AppStates.root_core_navigation_home);
                    } else {
                        // reloading because some objects were already loaded in the context of the previous company and they're not usable
                        // a classic example is workspace members which loads contacts and client users info (which are company bound)
                        this.$state.reload();
                    }
                    
                    this.FeatureRestrictionService.checkIfBlockedAccess({
                        source: 'switch_company',
                        actionType: 'load'
                    });
                });
            },

            verifyLoggedCompany(expectedCompanyId, ownedAccountId, type, otherCompanyName) {
                const currUser = this.UsersManager.getCurrUser();
                if (currUser.account_id === ownedAccountId &&
                    currUser.company && currUser.company._id &&
                    currUser.company._id !== expectedCompanyId) {

                    const error = `
                    You are trying to access a ${type} that belongs to ${otherCompanyName || 'one of your other companies'}.\n
                    Would you like to switch to that company?
                  `;
                    this.PopupMessageService.showConfirmPromise(
                        this.PopupMessageService.severityTypes.info,
                        error,
                        'Yes, Switch',
                        'Home Page',
                        "Wrong Brand",
                        true,
                        'static'
                    ).then(() => {
                        this.switchCompany(expectedCompanyId, true);
                    }).catch(() => {
                        this.$state.go(this.AppStates.root_core_navigation_home);
                    });
                }
            }
        };
    });
}());

