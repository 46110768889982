(function () {
    "use strict";

    // @ngInject
    function ReportsControllerCtor($scope, UsersManager, AppStates, AppConfigService, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReportsController';

        this.user = UsersManager.getCurrUser();
        this.appConfig = AppConfigService;

    }


    Controllers.ReportsController = Class(Controllers.BaseController, {

        constructor: ReportsControllerCtor,

        beforeDestroy: function beforeDestroy() {
            this.appConfig.resetReportsState();
        }
    });
}());
