(function () {
    "use strict";


    // @ngInject
    function EventsManagerCtor(APIService, Routes, ModelFactory, RepositoryService, UsersManager, $q, moment, _, PubSubService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.UsersManager = UsersManager;
        this.moment = moment;
        this._ = _;
        this.$q = $q;
        this.PubSubService = PubSubService;

        this.eventsMasterCollection = false;
        this.lastSortColumn = '';

        PubSubService.ventMyBitchUp(this);
    }


    Services.EventsManager = Class(Services.BaseModelManager, {

        constructor: EventsManagerCtor,

        /*****************************************
         * EVENT ACTIONS
         ******************************************/

        newEvent: function newEvent(data) {
            var newEvent = this.ModelFactory.newEventModel();

            if (!!data) {
                newEvent.mixinFrom(data);
            }

            return newEvent;
        },

        newEvents: function newEvents() {
            return this.ModelFactory.newEventsCollection();
        },

        // The Events API expects the locale format for writing
        // moment().format('ll'); e.g. Nov 21, 2016
        formatEventDataForAPIWrite: function (event) {
            var data = event.dataOnly ? event.dataOnly() : event;
            data.event_date = data.event_date ? this.moment(data.event_date).format('ll') : data.event_date;
            data.event_end_date = data.event_end_date ? this.moment(data.event_end_date).format('ll') : data.event_end_date;

            // don't need to send this to server
            delete data.workspaces;
            delete data.creator;
            delete data.event_users;
            delete data.user_visits;
            return data;
        },

        createEvent: function createEvent(event, userIdToAdd) {
            var url = this.Routes.v2_create_event_path();
            var data = this.formatEventDataForAPIWrite(event);

            if (userIdToAdd) {
                data.add_user_id = userIdToAdd;
            }

            var promise = this.apiCreate(url, data);
            promise.then(
                function success(resp){
                    event.mixinFrom(resp.data.event, null, true, true);
                    this.onEventAdded(event);
                }.bind(this)
            );
            return promise;
        },

        getEventFromCache: function getEventFromCache(eventId){
            if(eventId){
                return this.RepositoryService.cacheGet('EventModel', eventId);

            }else{
                return null;
            }
        },

        getLatestEventFromCache: function getLatestEventFromCache(eventId){
            var events = this.ModelFactory.getEventsMasterCollection();
            return events[events.length - 1];
        },

        getEventsSummary: function getEventsSummary(filterCompanyId) {
            var events = this.getEventsMasterCollection();
            return this.apiFetchCollection(this.Routes.v2_events_summary_path(), {filter_company_id: filterCompanyId}, events, true, null, false);
        },

        getEvent: function getEvent(eventId, refresh, returnPromise) {
            var event = this.getEventFromCache(eventId);

            // if event doesn't exists in cache (or hard force), create it
            if (!event) {
                event = this.newEvent();
                event._id = eventId;
                event = event.saveToCache();
            }

            var promise = this.$q.when(event);

            if (this.isModelTimedOut(event, refresh === true)) {
                var url = this.Routes.v2_event_details_path(eventId);
                promise = this.apiFetch(url, null , event, true).then(function() { return event; });
            }

            return returnPromise === true ? promise : event;
        },

        removeEventFromCache: function removeEventFromCache(eventId) {
            this.RepositoryService.cacheDelete(null, 'EventModel', eventId);
        },

        addWorkspaceToEvent: function addWorkspaceToEvent(eventId, workspaceType) {

            var newWorkspace = this.ModelFactory.newWorkspaceModel();

            var url = this.Routes.v2_create_workspaces_on_event_path(eventId);
            var data = {
                workspace_type: workspaceType
            };
            return this.apiCreate(url, data , newWorkspace, true);


        },

        updateEventFromWorkspace: function updateEventFromWorkspace(eventObj, workspace){

            var event = this.RepositoryService.cacheGet('EventModel', eventObj._id);
            var wasntInCache = false;

            if(!event){
                event = this.newEvent();
                event._id = eventObj._id;
                wasntInCache = true;
            }

            event.updateEventDetails(eventObj);

            event.addWorkspaceIfNeeded(workspace);

            if(wasntInCache){
                // we need to add time from server so that we will recognize that the event got from server, relevant for inserting
                // the event to cache (done in the base model usually, but in case this event is added by mixin of workspace then this is done too late)
                event._lastServerUpdate = this.moment().toString();
                event.saveToCache();
            }
        },

        createCompanyProjectNotes: function createCompanyProjectNotes(company, project, note, workspaceId) {
            var url = this.Routes.v2_create_company_project_notes_path(project._id);
            return this.apiCreate(url, {company_id: company._id, notes: note, workspace_id: workspaceId}, project, true);
        },

        updateCompanyProjectNotes: function updateCompanyProjectNotes(company, project, note) {
            var url = this.Routes.v2_update_company_project_notes_path(project._id);
            return this.apiUpdate(url, {note_id: note._id, company_id: company._id, notes: note.text}, project, true);
        },

        deleteCompanyProjectNotes: function deleteCompanyProjectNotes(company, project, noteId) {
            var url = this.Routes.v2_delete_company_project_notes_path(project._id, noteId);
            return this.apiDelete(url, {company_id: company._id }, project, true);
        },

        createProjectDate: function createProjectDates(project) {
            var url = this.Routes.v2_create_project_dates_path(project._id);
            return this.apiCreate(url, null, project, true);
        },

        updateProjectDate: function updateProjectDates(project, date) {
            var url = this.Routes.v2_update_project_dates_path(project._id);
            return this.apiUpdate(url, {date: date}, project, true);
        },

        deleteProjectDate: function deleteProjectDate(project, date_id) {
            var url = this.Routes.v2_delete_project_dates_path(project._id, date_id);
            return this.apiDelete(url, null, project, true);
        },

        setEventImage: function setEventImage(event, image) {
            var url = this.Routes.v2_update_event_image_path(event._id);
            return this.apiUpdate(url, {event_image: image}, event, true);
        },

        updateEventDetails: function updateEventDetails(event){
            var url = this.Routes.v2_update_event_path(event._id);
            return this.apiUpdate(url, this.formatEventDataForAPIWrite(event), event, true);
        },

        updateEventInfoHtml: function updateEventInfoHtml(event, eventInfoHtml){
            var url = this.Routes.v2_update_event_path(event._id);
            return this.apiUpdate(url, {event_info_html_body: eventInfoHtml}, event, true);
        },

        getConflictingEventsByDate: function getConflictingEventsByDate(event, includeTeam) {
            var q = this.$q.defer();
            this.UsersManager.getCalendarEventsOnDate(this.UsersManager.getCurrUser()._id, event.event_date, event.event_end_date, includeTeam).then(function success(resp) {
                var resolved = resp.data.booked_events.concat(resp.data.lead_events).filter(function (currEvent) {
                    return event._id !== currEvent._id;
                });

                q.resolve(resolved);

            }.bind(this), function error() {
                q.reject();
            });

            return q.promise;
        },

        getConflictingEvents: function getConflictingEvents(eventId) {
            var url = this.Routes.v2_get_conflicting_events_path(eventId);
            return this.apiFetch(url, null, null, false);
        },

        addSpace: function addSpace(event, spaceId) {
            var url = this.Routes.v2_event_add_space_path(event._id, spaceId);
            return this.apiCreate(url, null, event, true);
        },

        removeSpace: function removeSpace(event, spaceId) {
            var url = this.Routes.v2_event_remove_space_path(event._id, spaceId);
            return this.apiDelete(url, null, event, true);
        },

        getEventsMasterCollection: function getEventsMasterCollection() {
            return this.ModelFactory.getEventsMasterCollection();
        },

        getFilteredEvents: function getFilteredEvents(params) {

            // Check if first time or user changed sort option. Initiate collection if he did
            if (params.sortColumn !== this.lastSortColumn) {
                this.lastSortColumn = params.sortColumn;
            }

            return this.apiFetchCollection(this.Routes.v2_get_filtered_events_path(), params, this.ModelFactory.getEventsMasterCollection(), true, null, false, true, true);
        },

        onEventAdded: function onEventAdded(event) {
            this.trigger('projectCreated', event);
        },

        setPvlConflictView: function setPvlConflictView(event, shouldHide) {
            var data = {
                hide: shouldHide
            };

            var url = this.Routes.v2_event_conflict_pvl_action_path(event._id);
            return this.apiUpdate(url, data, event, true);
        },
    });
}());
