/**
 * The hb-pro-tip component
 *
 * This component shows a pro-tip
 *
 *
 * parameters:
 * boldText: string. Text to be appear in bold.
 * text: string. Text
 * isFaded: bool. Cases in which pro tip should fade.
 * linkTitle: string. text to show on link
 * linkAction: function. what to do in case of link click.
 * iconType: 'default', 'yellow-shiny'
 *
 */


(function () {
    'use strict';

    class ProTipController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout) {
            super($scope, $injector);
            this.$timeout = $timeout;
            this.__objectType = 'ProTipController';

            this.iconType = this.iconType || 'default';
        }
    }

    Components.ProTip = {
        bindings: {
            iconType: '@',
            boldText: '@',
            text: '@',
            linkTitle: '@',
            linkAction: '&',
            isFaded: '<'
        },
        controller: ProTipController,
        name : 'hbProTip',
        templateUrl: 'angular/app/modules/common/ui_components/pro-tip/pro-tip.html',
    };

}());
