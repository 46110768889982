// Options
//

// @ngInject
Directives.BasicTooltip = function BasicTooltip($timeout, Tippy, UIUtils) {

    // default configuration
    var _defaultValues = {
        animation: "shift-away",
        duration: "200",
        arrow: true,
        theme: 'nx-tippy',
        dynamicTitle: true,
        maxWidth: '300px',
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    escapeWithReference: true
                },
                offset: {
                    offset: '0, 0'
                }
            }
        }
    };

    return {
        restrict: 'A',
        link: function(scope, element, attr) {

            // default
            var _customOptions = {};

            // set placement
            if(attr.placement) {
                _customOptions['placement'] = attr.placement;
            }

            if (attr.maxWidth) {
                _customOptions['maxWidth'] = attr.maxWidth;
            }

            _defaultValues.popperOptions.modifiers.offset.offset = [attr.offsetX || 0, attr.offsetY || 0].join();
            // flip needs to be disabled for negative offset values
            // https://popper.js.org/docs/v1/#modifiers..offset
            if ([attr.offsetX, attr.offsetY].some(offset => offset && offset.toString().startsWith('-'))) {
                _defaultValues.popperOptions.modifiers.flip = { enabled: false }
            }

            var _options = Object.assign({}, _defaultValues, _customOptions);
            $timeout(() => {
                if (!attr.hasOwnProperty('doNotSanitize')){
                    element[0].title = UIUtils.sanitize(element[0].title);
                }
                Tippy(element[0], _options);
            });
        },

    };
};