(function () {
    'use strict';

    // @ngInject
    function VideoConferencingManagerServiceCtor(APIService, ModelFactory, $q, Routes) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }

    Services.VideoConferencingManager = Class(Services.BaseModelManager, {
        constructor: VideoConferencingManagerServiceCtor,

        createZoomVideoConference(user) {
            var url = this.Routes.v2_create_video_conference_path(user._id);
            var data = {};
            return this.apiCreate(url, data, user, false);
        },

        cancelZoomVideoConference(user, meetingId, calendarItemID) {
            var url = this.Routes.v2_cancel_video_meeting_path(user._id);
            var data = {meeting_id: meetingId, calendar_item_id: calendarItemID};
            return this.apiDelete(url, data);
        }
    });
}());
