(function () {
    'use strict';

    // @ngInject
    function FullStoryServiceCtor(FS, AppConfigService, $log, DeviceService, DatadogRUMService) {
        this.FS = FS;
        this.isTracking = false;
        this.AppConfigService = AppConfigService;
        this.DeviceService = DeviceService;
        this.DatadogRUMService = DatadogRUMService;
        this.$log = $log;
    }


    Services.FullStoryService = Class(function () {
        return {
            constructor: FullStoryServiceCtor,

            identifyFSUser: function (user) {
                try {
                    this.FS('setIdentity', {
                        uid: user._id,
                        properties: {
                            displayName: user.full_name,
                            email: user.email,
                            userType_str: user.system_user_type,
                            isActivated_bool: typeof(user.isActivated) === 'function' ? user.isActivated() : !!user.is_activated,
                            activatedOn_date: user.activated_on ? new Date(user.activated_on) : null,
                            isMobileActive_bool: user.is_mobile_active,
                            companyType_str: user.company && user.company.company_type,
                            trialStartDate_date: (user.company && user.company.trial && user.company.trial.start_date) ? new Date(user.company.trial.start_date) : null,
                            trialDuration_number: (user.company && user.company.trial && user.company.trial.duration) ? user.company.trial.duration : null,
                            featuresActive_str: (user.features_active ? user.features_active.join(',') : ''),
                            segmentPrediction_str: user.account && user.account.predicted_member_segment ? user.account.predicted_member_segment : null,
                            enableFlowFlagDate_date: user.account && user.account.enable_flow_flag ? new Date(user.account.enable_flow_flag) : null,
                            blockFilesFlagDate_date: user.account && user.account.block_files_flag ? new Date(user.account.block_files_flag) : null,
                            otamGroup_str: user.account && user.account.otam_group,
                            hasSubscriptions_bool: user.account && user.account.has_subscriptions,
                            isSubscribed_bool: user.account && user.account.is_subscribed,
                            isBookkeeper:  typeof(user.isBookkeeper) === 'function' ? user.isBookkeeper() : user.is_bookkeeper,
                            userIntent_str: user.user_intent,
                            utm_source: user.attribution && user.attribution.user_utm_params && user.attribution.user_utm_params.utm_source,
                            isPreTrialCollaborator: user.isAtwsPreTrialVendor()
                        }
                    });
                } catch (e) {
                    this.DatadogRUMService.addError(
                        e,
                        {
                            message: "FullStory: Error identifying user in FullStory",
                        }
                    );
                    try { // If full identify throws, try leaner identify
                        this.FS('setIdentity', {
                            uid: user._id,
                            properties: {
                                displayName: user.full_name,
                                email: user.email,
                            }
                        });
                    } catch (e2) {
                        this.DatadogRUMService.addError(
                            e2,
                            {
                               message: "FullStory: Error identifying user in FullStory (leaner)",
                            }
                        );
                    }
                }
            },


            initTracking: function (user) {
                if (this.FS && !this.isTracking) {
                    try {
                        this.FS('restart');
                        this.identifyFSUser(user);
                        this.isTracking = true;
                    } catch (e) {
                        this.DatadogRUMService.addError(
                            e,
                            {
                                message: "FullStory: Error initializing FullStory",
                            }
                        );
                    }
                }
            },

            stopTracking: function () {
                if (this.FS && !this.DeviceService.isInAppBrowser()) {
                    try {
                        this.FS('setIdentity', { anonymous: true });
                        console.debug('FullStory: Stopping tracking, shutdown called');
                        this.FS('shutdown');
                        this.isTracking = false;
                    }
                    catch (e) {
                        this.DatadogRUMService.addError(
                            e,
                            {
                                message: "FullStory: Error stopping FullStory",
                            }
                        );
                    }
                }
            },

            trackEvent: function (eventName, eventProps) {
                if (this.FS) {
                    const { action = 'action' } = eventProps;

                    const properties = Object.assign({}, eventProps, { description: eventName });

                    this.$log.debug('[tracking: fullStory] ' + action + ', ' + JSON.stringify(properties));
                    this.FS('trackEvent', {
                        name: action,
                        properties: properties
                    });
                }
            },

            shouldSampleSession: function (sampleSize) {
                // sampleSize is a fraction who represent % of recorded sessions
                if (!sampleSize || isNaN(Number(sampleSize))) {
                    return false;
                }

                return Math.random() <= sampleSize;
            },
        };
    });

}());
