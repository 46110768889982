
(function () {
    'use strict';

    /**
     * intuit on the window element helps with QuickBooks integration.
     *
     * @see https://developer.intuit.com/
     */
    // @ngInject
    Services.intuit = function intuit($window, ThirdPartyLoaderService) {
        return ThirdPartyLoaderService.loadUrlPromise('https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js', function() {return $window.intuit;});
    };

}());