/**
 * Created by inonstelman on 1/1/15.
 */

Directives.LoaderDirective = function LoaderDirective() {

    // @ngInject
    function LoaderDirectiveControllerFunc($scope, $element, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoaderDirectiveController';

        var self = this;

        if(self.hbLoader === 'button'){
            var parentElem = $element.parent();
        }

        $scope.$watch('loaderVm.activeWhen', function (newVal, oldVal){
            if(self.activeWhen){
                $element.css('display', '');
                $element.addClass('active');

                if(parentElem){
                    parentElem.addClass('disabled');
                    parentElem.attr("disabled", "disabled");
                }
            }else{
                $element.css('display', 'none');
                $element.removeClass('active');

                if(parentElem){
                    parentElem.removeClass('disabled');
                    parentElem.removeAttr("disabled");
                }
            }
        });
    }



    var LoaderDirectiveController = Class(Controllers.BaseController, {
        constructor: LoaderDirectiveControllerFunc
    });
    return {
        controller: LoaderDirectiveController,
        scope: {
            hbLoader: '@hbLoader',
            activeWhen: '=activeWhen'
        },
        //templateUrl: 'angular/app/modules/common/ui_components/loader/loader_directive_template.html',
        controllerAs: 'loaderVm',
        bindToController: true
    };
};
