
(function () {
    'use strict';

    Directives.FlowsFileTypeTooltipDirective = function FlowsFileTypeTooltipDirective() {

        // @ngInject
        function FlowsFileTypeTooltipDirectiveControllerFunc($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'FileTypeTooltipDirectiveControllerFunc';
            this.fileType = this._getType();
        }

        var FlowsFileTypeTooltipDirectiveController = Class(Controllers.BaseController, {
            constructor: FlowsFileTypeTooltipDirectiveControllerFunc,

            _getType: function _getType() {
                switch (this.type) {
                    case 'brochure':
                    case 'new-brochure':
                        return 'services;'
                    case 'agreement':
                        return 'contract'
                    case 'flow':
                        return 'customFile'
                    default:
                        return this.type;
                }
            }
        });

        return {
            scope: {
                type:'@',
                vendorType: '@'
            },
            templateUrl: 'angular/app/modules/core/features/workspace/file_type_tooltip/flows_file_type_tooltip_directive.html',
            controller: FlowsFileTypeTooltipDirectiveController,
            controllerAs: 'vm',
            bindToController: true
        };
    };
})();