(function () {
    "use strict";

    Services.FlowService = class FlowService {

        // @ngInject
        constructor($rootScope, AppStates, $state, $window, MobileAppService, DeviceService, AnalyticsService, acrossTabs, UsersManager, Constants, CompaniesManager) {

            this.$rootScope = $rootScope;
            this.AppStates = AppStates;
            this.$state = $state;
            this.$window = $window;
            this.MobileAppService = MobileAppService;
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.AcrossTabs = acrossTabs;
            this.UsersManager = UsersManager;
            this.Constants = Constants;
            this.currCompany = CompaniesManager.getCurrCompany();
        }

        registerAppEvents() {
            if (this.DeviceService.isInAppBrowser()) {
                this.MobileAppService.on(this.MobileAppService.INBOUND.FLOW._GO_TO_FLOW_, (data) => {
                    const user = this.UsersManager.convertToUser(data.user);
                    this.goToFlowByUser(data.flow, user, {reload: true});
                    this.AnalyticsService.reportEvent('event:GoToFlow', {flowId: data.flow._id, source: 'inbound angular event'});
                });
                this.MobileAppService.on(this.MobileAppService.INBOUND.FLOW._GO_TO_FLOW_PREVIEW_, (data) => this.goToPreview(data.flow._id, {reload: true, activeMode: data.flow.active_mode}));
                this.MobileAppService.on(this.MobileAppService.INBOUND.FLOW._EXIT_FLOW_PREVIEW_, (data) => this.goToResponses(data.flow._id, {reload: true}));
            }
        }

        goToFlow(flowId, target, options= {}, extraParams = {}) {
            const params = Object.assign({ flow_id: flowId }, extraParams);
            let state = this.AppStates.root_core_oneFlow;
            const goBackTo = {
                name: this.$state.current.name,
                params: this.$state.params
            };

            switch(target) {
                case 'edit':
                    params.action = 'edit';
                    params.isGoToDefault = options.isGoToDefault;
                    if (options && options.isNewTemplate) {
                        params.isNewTemplate = true;
                    }

                    break;

                case 'responses':
                    params.action = 'responses';
                    params.goBackTo = goBackTo;
                    break;
                    
                case 'preview':
                    params.action = 'edit';
                    params.goBackTo = goBackTo;
                    if (options && options.activeMode) {
                        params.activeMode = true;
                    }
                    state = this.AppStates.root_core_oneFlowPreview;
                    break;

                default:
                    params.goBackTo = goBackTo;
                    if (options && options.isNewTemplate) {
                        params.isNewTemplate = true;
                    }

                    break;
            }

            this.$state.go(state, params, options);
        }

        isClientInFlow(flow, currUser) {
            if(!flow || currUser.isClient()) {
                return true;
            }
            return currUser.company && flow.company_id !== currUser.company._id;
        }

        isScheduledFlow(flow) {
            if(flow && flow.scheduled_to_be_sent) {
                return true;
            }

            return false;
        }

        initConnectTabManager() {
            this.connectedTabManager = new this.AcrossTabs.Parent({
                onChildCommunication: (data) => {
                    const tabMessage = this.AcrossTabs.parseMessage(data);
                    if (!tabMessage) {
                        return;
                    }

                    this.handleChildTabMessage(tabMessage);
                }
            });
        }

        handleChildTabMessage({id, action}){
            if(action === 'request-authentication') {
                const user = this.UsersManager.currUser.dataOnly();

                this.connectedTabManager.broadCastTo(id, JSON.stringify({
                    action: 'authentication',
                    user
                }));
            }
        }

        goToFlowByUser(flow, currUser, options={}) {
            if (flow) {
                const companies = currUser.companies || [currUser.company];
                if (!this.isClientInFlow(flow, currUser) && companies.some(c => c._id === flow.company_id)) {
                    this.AnalyticsService.trackAction(this, 'flow feed item', {
                        status: flow.status,
                        app_path: this.analyticsAppPath
                    });

                    this.goToFlow(flow._id, null, options);

                } else {
                    this.AnalyticsService.trackAction(this, 'client flow action required', {
                        status:flow.status
                    });

                    if(!this.connectedTabManager) {
                        this.initConnectTabManager();
                    }

                    this.connectedTabManager.openNewTab({
                        url: flow.flow_url,
                        windowName: '_blank'
                    });
                }
            }
        }

        goToPreview(flowId, options={}) {
            this.goToFlow(flowId, 'preview', options);
        }

        goToTemplateGalleryPreview(flowId, templateId) {
            const state = this.AppStates.root_core_oneFlowTemplateGalleryPreview;
            let params = { flow_id: flowId,  template_id: templateId};
            this.$state.go(state, params, {});
        }

        goToTemplateGalleryPreviewNewTab(flowId, templateId) {
            const state = this.AppStates.root_core_oneFlowTemplateGalleryPreview;
            let params = { flow_id: flowId,  template_id: templateId};
            const url = this.$state.href(state , params);
            window.open(url,'_blank', 'noopener,noreferrer');
        }

        goToResponses(flowId, options={}) {
            this.goToFlow(flowId, 'responses', options);
        }
    };
}());
