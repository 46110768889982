(function () {
  'use strict';

  class CalendarListItemComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, UIUtils, _) {
      super($scope, $injector);
      this.__objectType = 'CalendarListItemComponent';
      this.UIUtils = UIUtils;

      // Those colors are used for the color picker suggestions, first 5 are old, the rest are from the session config page.
      this.presetColors = ['#4CC676', '#fc8300', '#3758c8', '#597EFF', '#3a009b', '#5a00f1', '#9759ff', '#273a68', '#3c5aa2', '#738dcb', '#00569b', '#0085f1',
        '#59b5ff', '#994702', '#ee6e03', '#fda55b', '#875a22', '#ce8c39', '#e4c093', '#9b0008', '#f1000d', '#ff5962', '#b60097', '#ff1cd8'];
      this.currColor = this.color;


      let setColor = () => {
        this.changeCalendarColor({color:this.currColor, type: this.item.color_type});
      };

      setColor = _.debounce(setColor, 1000);

      this.onChange = (color) => {
        this.currColor = color;
        setColor();
      };

    }
  }

  Components.CalendarListItem = {
    bindings: {
      color: '@',
      cssClass: '@',
      toggleCheckbox: '&',
      analyticsCheckboxEvent: '@',
      analyticsCheckboxProps: '@',
      checkboxActive: '=',
      label: '@',
      changeCalendarColor: '&',
      item: '<',
      hideColorPicker: '@',
      staticList: '='
    },
    controller: CalendarListItemComponent,
    name : 'hbCalendarListItem',
    templateUrl: 'angular/app/modules/core/features/calendar/calendar_list/calendar_list_item.html',
  };

}());

