/**
 * Created by dvircohen on 15/05/2017.
 */
(function () {
    'use strict';

    // @ngInject
    function RTEFactoryCtor(CKEDITOR, _) {
        this.CKEDITOR = CKEDITOR;
        this._ = _;

        this.defaultPlugins = [
            'dialogui', 'dialog', 'dialogadvtab', 'hbckeditor',
            'basicstyles', 'toolbar', 'clipboard', 'button',
            'panelbutton', 'panel', 'floatpanel', 'menu',
            'enterkey', 'entities', 'font', 'format',
            'htmlwriter', 'divarea', 'wysiwygarea', 'justify',
            'pastetext', 'pastefromword', 'removeformat', 'showborders',
            'undo', 'colorbutton'];

        this.defaultContentAllowed = ['p', 'span', 'a', 'b', 'i', 'u', 's', 'hr', 'h1', 'h2', 'h3', 'h4', 'div', 'ul', 'ol', 'li'];

        this.defaultToolbarName = 'paragraph';
        this.defaultToolbarItems = [
            'Format', 'Bold', 'Italic', 'Underline', 'TextColor',
            'BGColor', 'JustifyLeft', 'JustifyCenter', 'JustifyRight',
            'JustifyBlock', 'BulletedList', 'NumberedList', 'HorizontalRule',
            'Blockquote', 'RemoveFormat', 'AddHyperLink'];

        this.defaultSettings = {
            skin: 'moono',
            language: 'en',
            title: false,
            startupFocus: true,
            width: '100%',
            baseFloatZIndex: 100000000,
            contentsCss: (window.CKEDITOR_BASEPATH || "/assets/") + 'ckeditor-inner.css'
        };
    }

    Services.RTEFactory = Class({
        constructor: RTEFactoryCtor,

        newFeedEditor: function newFeedEditor(containerId, settings) {
            return this.newEditor(containerId, settings);
        },

        newAgreementEditor: function newAgreementEditor(containerId, settings) {

            var agreementEditorDefaults = {

                includePlugins: [
                    'autogrow', 'contextmenu', 'popup', 'listblock', 'richcombo',
                    'horizontalrule', 'indent', 'indentblock', 'indentlist', 'list',
                    'liststyle', 'pastetext', 'tab', 'hbeditableagreement'],

                allowedContent: ['input', 'form', 'table', 'tr', 'td']
            };

            angular.forEach(agreementEditorDefaults, function(value, key) {
                if (!settings[key]) {
                    settings[key] = value;
                }
            });

            return this.newEditor(containerId, settings);
        },

        newEmailSignatureEditor: function newEmailSignatureEditor(containerId, settings) {
            var emailSignatureEditorDefaults = {

                includePlugins: [
                    'contextmenu', 'popup', 'listblock', 'richcombo', 'horizontalrule',
                    'indent', 'indentblock', 'indentlist', 'list', 'liststyle', 'pastetext',
                    'tab', 'hbemaileditor'],

                allowedContent: ['dd', 'dl', 'blockquote', 'table', 'tr', 'td', 'input', 'img'],
            };

            angular.forEach(emailSignatureEditorDefaults, function(value, key) {
                if (!settings[key]) {
                    settings[key] = value;
                }
            });

            return this.newEditor(containerId, settings);
        },

        newEmailEditor: function newEmailEditor(containerId, settings, toolbarItemsToRemoveArray) {

            var includeToolbarItems =  ['AddClientName', 'AddEmailSignature', 'addFileAttachment'];

            includeToolbarItems = this._.difference(includeToolbarItems, toolbarItemsToRemoveArray);

            var emailEditorDefaults = {

                includePlugins: [
                    'contextmenu', 'popup', 'listblock', 'richcombo', 'horizontalrule',
                    'indent', 'indentblock', 'indentlist', 'list', 'liststyle', 'pastetext',
                    'tab', 'hbemaileditor'],

                allowedContent: ['dd', 'dl', 'blockquote', 'table', 'tr', 'td', 'input', 'img'],

                includeToolbarItems: includeToolbarItems
            };

            angular.forEach(emailEditorDefaults, function(value, key) {
               if (!settings[key]) {
                   settings[key] = value;
               }
            });

            return this.newEditor(containerId, settings);
        },

        newEditorFromType: function newEditorFromType(type, containerId, settings, toolbarItemsToRemoveArray) {
            if (type === 'email') {
                return this.newEmailEditor(containerId, settings, toolbarItemsToRemoveArray);
            }
            else if (type === 'emailSignature') {
                return this.newEmailSignatureEditor(containerId, settings);
            }

            return this.newEditor(containerId, settings);
        },

        /**
         * Initialize new editor instance and return it by values
         * those instances are not cached
         *
         * @param containerId   String  DOM element id to apply the editor on
         * @param settings      Hash    Hash of settings to apply on the editor, options are:
         *                                  - excludePlugins
         *                                  - includePlugins
         *                                  - allowedContent
         *                                  - disallowedContent
         *                                  - includeToolbarItems
         *                                  - excludeToolbarItems
         *
         * @return editor
         */

        newEditor: function newEditor(containerId, settings) {

            settings = settings || {};

            // Create plugins array
            var plugins = this._mergeSettingsArray(this.defaultPlugins, settings['includePlugins'], settings['excludePlugins']).join(',');

            // Create allowed html content array
            var allowedContent = this._mergeSettingsArray(this.defaultContentAllowed, settings['allowedContent'], settings['disallowedContent']);
            allowedContent.push('[*]{*}(*)');
            allowedContent = allowedContent.join(' ');

            // Handle toolbar items
            var toolbar = [
                {
                    name: settings['toolbarName'] || this.defaultToolbarName,
                    items: this._prepareToolbarItems(this._mergeSettingsArray(this.defaultToolbarItems, settings['includeToolbarItems'], settings['excludeToolbarItems']))
                }
            ];

            // Merge all other options to the
            var editorSettings = angular.extend({plugins: plugins, allowedContent: allowedContent, toolbar: toolbar}, this.defaultSettings);

            // Build final settings array without plugins and content values
            angular.forEach(settings, function(value, key) {
                if (['includeToolbarItems', 'includePlugins', 'excludePlugins', 'allowedContent', 'disallowedContent', 'plugins'].indexOf(key) === -1) {
                    editorSettings[key] = value;
                }
            });

            return this.CKEDITOR.replace(containerId, editorSettings);
        },

        _mergeSettingsArray: function _mergeSettingsArray(defaultArray, includedItems, excludedItems) {
            includedItems = includedItems || [];
            excludedItems = excludedItems || [];
            return this._.union(this._.difference(defaultArray, excludedItems), includedItems);
        },

        _prepareToolbarItems: function _prepareToolbarItems(items) {
            var itemsArray = [];

            angular.forEach(items, function(item) {
               itemsArray.push(item);
               itemsArray.push('-');
            });

            // Remove last -
            itemsArray.pop();

            return itemsArray;
        }
    });
}());

