Directives.ServiceDirective = function ServiceDirective() {

    // @ngInject
    function ServiceDirectiveControllerFunc(AnalyticsService, $timeout,
                                            $scope, $injector, $log, ModalService, _) {
        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ServiceDirectiveController';

        this.ModalService = ModalService;

        var serviceId = this.serviceViewModel._id;
        this.serviceModel = this.parentModel.findServiceModel(serviceId);
        this.$log = $log;
        this.ModalService = ModalService;
        this.round = Math.round;

        this._ = _;
        this.deleting = false;
        this.deletingImage = false;
        this.updatingImage = false;
        this.shouldShowSVC = typeof this.parentModel.shouldShowSVC === 'function' ? this.parentModel.shouldShowSVC() : false;

        this.updateService = function updateService() {
            if (this.isEditMode()) {
                return this.serviceModel.updateService();
            }
        };

        this.calculatePrice = function calculatePrice(override) {
            this.serviceModel.calculatePrice(override);
            this.updateService();
        };

        this.shouldShowServiceOnPreview = function shouldShowServiceOnPreview() {
            var notEmpty =  !this._.isEmpty(this.serviceModel.name) ||
                !this._.isEmpty(this.serviceModel.description) ||
                this._.isObject(this.serviceModel.package_service_image) ||
                this.serviceModel.quantity > 0 ||
                this.serviceModel.price_per_unit > 0 ||
                this.serviceModel.price > 0;

            return this.isEditMode() || notEmpty;
        };

        this.removeTimeout = function removeTimeout() {
            if (this.updateServiceTimeout) {
                $timeout.cancel(this.updateServiceTimeout);
            }
        };

        this.updateServiceTax = function updateServiceTax() {
            var self = this;
            //sync the model according to the viewModel
            self.serviceModel.taxable = self.serviceViewModel.taxable;
            this.updateService().then(function success() {
                },
                function error() {
                    //an error occurred go backwards on the model and view model
                    self.serviceModel.taxable = !self.serviceModel.taxable;
                    self.serviceViewModel.taxable = self.serviceModel.taxable;
                });
        };

        this.updateServiceSVC = function updateServiceSVC() {
            var self = this;
            //sync the model according to the viewModel
            self.serviceModel.svcable = self.serviceViewModel.svcable;
            this.updateService().then(function success() {
                },
                function error() {
                    //an error occurred go backwards on the model and view model
                    self.serviceModel.svcable = !self.serviceModel.svcable;
                    self.serviceViewModel.svcable = self.serviceModel.svcable;
                });
        };

        this.deleteService = function deleteService() {
            var that = this;

            if (this.deleting) {
                return; //we are already in the process of deleting. no need to call the server again. at least not until we get an answer.
            }

            this.deleting = true;

            that.serviceModel.deleteService().then(
                function success() {
                    if (that.serviceModel.isPackageService()) {
                        AnalyticsService.trackSuccess(that, AnalyticsService.analytics_events.service_deleted_from_package, that.serviceModel.getAnalyticsArgs());
                    }
                    else if (that.serviceModel.isALaCarteService()) {
                        AnalyticsService.trackSuccess(that, AnalyticsService.analytics_events.service_deleted_from_a_la_carte, that.serviceModel.getAnalyticsArgs());
                    }
                    else {
                        AnalyticsService.trackSuccess(that, AnalyticsService.analytics_events.service_deleted_from_proposal, that.serviceModel.getAnalyticsArgs());
                    }

                },
                function error() {
                    if (that.serviceModel.isPackageService()) {
                        AnalyticsService.trackError(that, AnalyticsService.analytics_events.service_deleted_from_package, that.serviceModel.getAnalyticsArgs());
                    }
                    else if (that.serviceModel.isALaCarteService()) {
                        AnalyticsService.trackError(that, AnalyticsService.analytics_events.service_deleted_from_a_la_carte, that.serviceModel.getAnalyticsArgs());
                    }
                    else {
                        AnalyticsService.trackError(that, AnalyticsService.analytics_events.service_deleted_from_proposal, that.serviceModel.getAnalyticsArgs());
                    }
                }
            ).finally(function finallyCleaning() {
                that.deleting = false;
            });
        };

        this.shouldShowTaxViewSection = function shouldShowTaxViewSection() {
            return this.isEditMode() || (this.serviceModel.taxable && this.serviceModel.price > 0);
        };
        this.shouldShowSVCViewSection = function shouldShowSVCViewSection() {
            return this.shouldShowSVC && (this.isEditMode() || (this.serviceModel.svcable && this.serviceModel.price > 0));
        };

        this.shouldShowQuantityViewSection = function shouldShowQuantityViewSection() {
            return this.isEditMode() ||
                !isNaN(parseInt(this.serviceModel.quantity)) &&
                (this.serviceModel.price_per_unit !== undefined && this.serviceModel.price_per_unit);
        };

        this.shouldShowServiceDescription = function shouldShowServiceDescription() {
            return this.isEditMode() || (this.serviceModel.description);
        };

        this.shouldShowPriceOnPreview = function shouldShowPriceOnPreview() {
            if (this.isEditMode()) {
                return true;
            } else {
                return this.serviceModel.price !== undefined && this.serviceModel.price != 0;
            }
        };

        this.isEditMode = function isEditMode() {
            return this.isOwnerMode &&
                this.isEditableMode && !this.isPreviewMode;
        };

        this.expandImage = function expandImage(){
            this.ModalService.openExpandImageModal(self.serviceModel, self.isEditMode())
                .then(function ok(actions) {
                    if(actions.edit){
                        //open the crop image modal
                        // this.updateServiceImage();
                        this.editServiceImage();
                    }
                    else if(actions.delete){
                        //delete the image
                        this.deleteServiceImage();
                    }
                }.bind(this))
                .catch(function canceled() {
                    //TODO: add some analytics here
                });
        };

        this.watchedValuesChanged = function watchedValuesChanged(newVal, oldVal) {
            var newValUndefined = newVal == null || newVal == undefined;
            var oldValUndefined = oldVal == null || oldVal == undefined;
            return ((newValUndefined && !oldValUndefined) ||
                (!newValUndefined && oldValUndefined)) ||
                newVal.toString() !== oldVal.toString();
        };

        $scope.$watch('serviceVm.serviceViewModel.name', function (newVal, oldVal) {
            if (self.ignoredFirstNameUpdate && self.watchedValuesChanged(newVal, oldVal)) {
                self.serviceModel.name = newVal;
                self.updateService();
            } else {
                self.ignoredFirstNameUpdate = true;
            }
        });

        $scope.$watch('serviceVm.serviceViewModel.description', function (newVal, oldVal) {
            if (self.ignoredFirstDescriptionUpdate && self.watchedValuesChanged(newVal, oldVal)) {
                self.serviceModel.description = newVal;
                self.updateService();
            } else {
                self.ignoredFirstDescriptionUpdate = true;
            }
        });

        $scope.$watch('serviceVm.serviceViewModel.quantity', function (newVal, oldVal) {
            if (self.ignoredFirstQuantityUpdate && self.watchedValuesChanged(newVal, oldVal)) {
                self.serviceModel.quantity = newVal;
                self.calculatePrice();
            } else {
                self.ignoredFirstQuantityUpdate = true;
            }
        });

        $scope.$watch('serviceVm.serviceViewModel.price_per_unit', function (newVal, oldVal) {
            if (self.ignoredFirstPricePerUnitUpdate && self.watchedValuesChanged(newVal, oldVal)) {
                self.serviceModel.price_per_unit = newVal;
                self.calculatePrice();
            } else {
                self.ignoredFirstPricePerUnitUpdate = true;
            }
        });

        $scope.$watch('serviceVm.serviceViewModel.price', function (newVal, oldVal) {

            // Ignore re-updating the service if needed when this watch is trigger due to ajax response
            var modelValueChanged = self.serviceModel.price !== newVal;

            if (self.ignoredFirstPriceUpdate &&
                modelValueChanged &&
                self.watchedValuesChanged(newVal, oldVal)) {
                self.serviceModel.price = newVal;
                self.calculatePrice();
            } else {
                self.ignoredFirstPriceUpdate = true;
            }
        });
    }

    var ServiceDirectiveController = Class(Controllers.BaseController, {
        constructor: ServiceDirectiveControllerFunc,

        updateServiceImage: function updateServiceImage() {
            this.ModalService.openAttachAssetModal(true, true, true, "Library", false, false, null, 1, false, false, "image/*", '', '', 'MEDIA_MODAL.TITLE._ADD_IMAGE_').then(function success(newImage) {
                this.updatingImage = true;
                // Remember this means hasImage returns false. To account for the loader, edit the template
                this.serviceModel.package_service_image = null;
                this.serviceModel.updateServiceImage(newImage)
                    .finally(function finallyCleaning() {
                        this.updatingImage = false;
                    }.bind(this));
            }.bind(this));
        },

        editServiceImage: function editServiceImage(){
            // showCropTab, showGalleryTab, showUploadTab, defaultTab, allowMultipleAttach, showBackdrop, selectedImage, aspectRatio, showPreviewWhenCropping, startUploadFile, acceptFilesFilter, model) {
            this.ModalService.openAttachAssetModal(true, true, true, "Crop", false, false, this.serviceModel.package_service_image, 1, false, false, "image/*",  '', '', 'MEDIA_MODAL.TITLE._EDIT_IMAGE_').then(function success(newImage) {

                this.updatingImage = true;
                this.serviceModel.package_service_image = null;
                this.serviceModel.updateServiceImage(newImage)
                    .finally(function finallyCleaning() {
                        this.updatingImage = false;
                    }.bind(this));

            }.bind(this));
        },

        deleteServiceImage: function deleteServiceImage() {
            this.deletingImage = true;
            this.serviceModel.deleteServiceImage()
                .then(function success() {
                    this.serviceModel.package_service_image = null;
                }.bind(this))
                .finally(function finallyCleaning() {
                    this.deletingImage = false;
                }.bind(this));
        },

        hasImage: function hasImage() {
            return this.serviceModel.package_service_image;
        }
    });

    return {
        scope: {
            serviceViewModel: '=serviceViewModel',
            parentModel: '=parentModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            hidePriceAndDetails: '=?hidePriceAndDetails'

        },
        templateUrl: 'angular/app/modules/core/features/proposal/service/service_directive_template.html',
        controller: ServiceDirectiveController,
        controllerAs: 'serviceVm',
        bindToController: true
    };
};