(function () {
    "use strict";

    // @ngInject
    function SubmittedControllerCtor($scope, AnalyticsService, $injector, AppConfigService, WorkspaceFileService) {

        this.constructor.$super.call(this, $scope, $injector);

        this.AnalyticsService = AnalyticsService;
        this.appConfig = AppConfigService;
        this.WorkspaceFileService = WorkspaceFileService;

        this.__objectType = 'SubmittedController';

        this.submittedState = this.appConfig.questionnaireSubmittedState();
        this.workspaceFile = this.submittedState.workspaceFile;
    }


    Controllers.SubmittedController = Class(Controllers.BaseController, {

        constructor: SubmittedControllerCtor,

        goToWorkspace: function goToWorkspace() {
            var stateParams = {
                event_id: this.workspaceFile.event._id,
                workspace_id: this.workspaceFile.couple_card_id
            };

            this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, stateParams);
        },

        beforeDestroy: function beforeDestroy() {
            this.appConfig.resetQuestionnaireSubmittedState();
        },

        analyticsProperties: function analyticsProperties(){
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        }
    });
}());

