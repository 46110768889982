(function () {
    "use strict";

    // @ngInject
    function CompanySettingsPricingControllerCtor($scope, $injector, AnalyticsService,
                                                  UsersManager, FeaturesService, $stateParams, moment,
                                                  Enums) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsPricingController';
        this.openEditBillingInfoModalOnMount = $stateParams.openBillingModal || false;
        AnalyticsService.trackPageView(this, 'company setting membership tab');
        AnalyticsService.reportEvent('InitiateCheckout');

        this.Enums = Enums;
        this.currUser = UsersManager.getCurrUser();
        AnalyticsService.reportIterableEvent('subscription_page_visit', {date: moment()}, this.currUser);
    }

    Controllers.CompanySettingsPricingController = Class(Controllers.BaseController, {
        constructor: CompanySettingsPricingControllerCtor
    });
}());
