Directives.WorkspaceFileCardDirective = function WorkspaceFileCardDirective() {

    // @ngInject
    function WorkspaceFileCardControllerConstructor($scope, $injector, UIUtils, UsersManager, WorkspaceFileService,
        PopupMessageService, AnalyticsService, _, $translate, $timeout, AppStates, ReactModalService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileCardController';

        this.user = UsersManager.getCurrUser();
        this._ = _;
        this.$scope = $scope;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.AppStates = AppStates;

        this.escapedTitleName = this.workspaceFile.file_title.replace(/[^a-zA-Z0-9@!# $\.,%^&*]/g, '');
        this.tags = [this.workspaceFile.owner.full_name, this.workspaceFile.company.company_name];
        this.isUpdatingStatus = false;
        this.notificationTextCode = null;
        this.generateClassByFileType = UIUtils.generateClassByFileType;

        this.isVendor = this.user.isVendor();
        this.isOwnedByCurrUser = this.workspaceFile.isOwnedByCurrUser();
        this.isVendorView = this.isVendor && (this.isOwnedByCurrUser || this.workspaceFile.owner.isMyTeamMember());
        this.ReactModalService = ReactModalService;
    }

    var WorkspaceFileCardController = Class(Controllers.BaseController, {
        constructor: WorkspaceFileCardControllerConstructor,

        getFileCardClasses: function getFileCardClasses() {
            return this.WorkspaceFileService.getFileCardClasses(this.workspaceFile);
        },

        getStatusClasses: function getStatusClasses() {
            return this.WorkspaceFileService.getStatusClasses(this.workspaceFile);
        },

        gotoFile: function gotoFile($event) {
            $event.preventDefault();
            // if the file is a requestFile and file-client then don't go to file.
            if (!this.workspaceFile.isAccessible()) {
                return;
            }
            this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
        },

        // =========================
        // Actions
        // =========================

        deleteWorkspaceFileClicked: function deleteWorkspaceFileClicked() {
            var warningMessage = this.$translate.instant("FILE.DELETE._ALERT_") + this.escapedTitleName + '?';
            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.warning, warningMessage)
                .then(function confirmDelete() {
                    this._deleteWorkspaceFile();
                }.bind(this))
                .catch(function cancelDelete() {

                }.bind(this));
        },

        _deleteWorkspaceFile: function deleteWorkspaceFile() {
            this.isUpdatingStatus = true;

            var trackingData = {
                workspace_file_id: this.workspaceFile._id
            };

            this.workspaceFile.deleteWorkspaceFile().then(
                function deleteSuccess() {
                    this.isUpdatingStatus = false;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.file_deleted, trackingData);
                }.bind(this),
                function deleteError(resp) {
                    this.isUpdatingStatus = false;
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.file_deleted, resp, trackingData);

                    var message = 'FILE.DELETE._ERROR_';
                    if (resp && resp.data && resp.data.error_message) {
                        message = resp.data.error_message;
                    }

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                }.bind(this));
        },

        deactivateWorkspaceFile: function deactivateWorkspaceFile() {
            var trackingData = {
                workspace_file_id: this.workspaceFile._id
            };

            this.workspaceFile.deactivateWorkspaceFile().then(
                function success() {
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.file_expired, trackingData);
                }.bind(this), function error() {
                    this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.file_expired, trackingData);
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'FILE.BLOCK._ERROR_');
                }.bind(this)
            );
        },

        activateWorkspaceFile: function activateWorkspaceFile() {
            var trackingData = {
                workspace_file_id: this.workspaceFile._id
            };

            if (this.workspaceFile.is_canceled && this.workspaceFile.isRecurringPayment() && !this.user.account.card_on_file_enabled) {
                this.ReactModalService.openEnableCardOnFileModal('unexpired');
                return;
            }

            this.workspaceFile.activateWorkspaceFile().then(
                function success() {
                    this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
                }.bind(this), function error() {
                    this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.file_unexpired, trackingData);
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                        'FILE.UNBLOCK._ERROR_');
                }.bind(this)
            );
        },

        // =========================
        // Menu
        // =========================
        showMenu: function showMenu() {
            return this.workspaceFile.isOwnedByCurrUser() || (this.user.hasCompanyAdminPermissions() && (this.allowDeactivate() || this.allowActivate()))
        },
        allowDelete: function allowDelete() {
            return (this.isOwnedByCurrUser || this.user.isCompanyOwner()) && this.workspaceFile.allowDelete();
        },

        allowDeactivate: function allowDeactivate() {
            return this.workspaceFile.allowDeactivate();
        },

        allowActivate: function allowActivate() {
            return this.workspaceFile.allowActivate();
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        formatFileType: function formatFileType(fileType) {
            return this.WorkspaceFileService.formatFileTypeToDisplayName(fileType, true);
        },

        enterWorkspaceCard: function enterWorkspaceCard($event) {
            this.$scope.activateTooltip();
            // var activeStyle = 'css-tooltip-top--active';
            // var activeElmState = $($event.target).find('.ws-progress__item--active');
            // var listElmsStates = $($event.target).find('.ws-progress__dots-list').children();
            //
            // function activateTooltip(){
            //     console.log("timeout run");
            //     if (!activeElmState.hasClass(activeStyle)){
            //         console.log("Active element no class");
            //         activeElmState.addClass(activeStyle);
            //         console.log("After add class: " + activeElmState.hasClass(activeStyle) + activeElmState.length);
            //
            //         // $(listElmsStates).hover(function () {
            //         //     activeElmState.removeClass(activeStyle);
            //         // });
            //
            //         $(listElmsStates).one('mouseenter', function(e) {
            //             console.log("removing class");
            //             activeElmState.removeClass(activeStyle);
            //         });
            //     } else {
            //         console.log("Active element has class");
            //     }
            // }
            //
            // this.$timeout(function () {
            //     activateTooltip();
            // }.bind(this), 300);
        },

    });

    var WorkspaceFileCardLinkFunction = function WorkspaceFileCardLinkFunction($scope, elem, attrs) {

        $scope.activateTooltip = function activateTooltip() {
            var ACTIVE_TOOLTIP_STYLE = 'css-tooltip-top--active';

            function activateTooltipHandler() {
                var activeElmState = elem.find('.ws-progress__item--active');
                var dotsListElm = elem.find('.ws-progress__dots-list');

                var isTooltipActive = isOneTooltipActive(dotsListElm.children());

                if (!activeElmState.hasClass(ACTIVE_TOOLTIP_STYLE) && !isTooltipActive) {

                    // add active class
                    activeElmState.addClass(ACTIVE_TOOLTIP_STYLE);

                    // bind to any of the dots hover
                    // to remove the active class so the
                    // default tooltip behavior will take place
                    $(dotsListElm).one('mouseover', '.ws-progress__item', function (e) {
                        activeElmState.removeClass(ACTIVE_TOOLTIP_STYLE);
                    });
                }
            }

            function isOneTooltipActive(list) {
                var result = false;
                list.each(function (index, el) {
                    result = result || $(el).find(".css-tooltip").is(":hover");
                });
                return result;
            }

            $scope.workspaceFileCardVm.$timeout(function () {
                activateTooltipHandler();
            }.bind(this), 300);
        };

        $scope.deactivateTooltip = function deactivateTooltip() {
            var dotsListElm = elem.find('.ws-progress__dots-list');
            $(dotsListElm).off('mouseenter', '.ws-progress__item');
        };

        $scope.$on('$destroy', function () {
            $scope.deactivateTooltip();
        });
    };


    return {
        scope: {
            workspaceFile: '=workspaceFile',
            notification: '=?notification'
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file_card/workspace_file_card_directive_template.html',
        controller: WorkspaceFileCardController,
        link: WorkspaceFileCardLinkFunction,
        controllerAs: 'workspaceFileCardVm',
        bindToController: true
    };
};