(function () {
    "use strict";


    // @ngInject

    function SetupGuideServiceCtor(UiPersistenceService, PubSubService, OnboardingService, EventService, TemplatesViewService, NavigationService,
                                   AppStates, $state, $window, JsSlicklightboxService, _, $q, AnalyticsService, PopupMessageService,
                                   UsersManager, FeaturesService, UserService, OnboardingBotManager, CompaniesManager, AbTestService, Enums,
                                   ModalService, $translate, $timeout, DeviceService, WorkspaceFileService, WorkspacesManager, moment, CompanyService, TemplatesManager, SelfOnboardingManager) {

        this.UiPersistenceService = UiPersistenceService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;

        PubSubService.ventMyBitchUp(this);
        this.OnboardingService = OnboardingService;
        this.EventService = EventService;
        this.TemplatesViewService = TemplatesViewService;
        this.AppStates = AppStates;
        this.$state = $state;
        this.$window = $window;
        this.ModalService = ModalService;
        this.$translate = $translate;
        this.PopupMessageService = PopupMessageService;
        this.STEP_COMPLETED_EVENT_NAME = "setupGuideStepCompleted";
        this.JsSlicklightboxService = JsSlicklightboxService;
        this._ = _;
        this.$timeout = $timeout;
        this.$q = $q;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UsersManager = UsersManager;
        this.FeaturesService = FeaturesService;
        this.UserService = UserService;
        this.OnboardingBotManager = OnboardingBotManager;
        this.DeviceService = DeviceService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.CompaniesManager = CompaniesManager;
        this.WorkspacesManager = WorkspacesManager;
        this.NavigationService = NavigationService;
        this.moment = moment;
        this.CompanyService = CompanyService;
        this.TemplatesManager = TemplatesManager;
        this.SelfOnboardingManager = SelfOnboardingManager;

        this.currUser = UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();
        this.setupGuideVersionNames = {
            v1: "setup_guide_page",
            v2: "setup_guide_modal",
            v3: "setup_guide_streamlined",
            v4: "setup_guide_home"
        };

        this.setupGuideStats = {
            completion: 0
        };

        this.demo_assets = [
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/01_pipeline.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/02_workspace_activity.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/03_workspace_files.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/04_invoice.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/05_agreements.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/06_bookkeping.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/07_workflows.png",
            "https://res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1517476087/onboarding/demo/08_tasks.png"
        ];
        this.galleryInitialized = false;
        this.lightboxContainer = '#demo-account-gallery';
        this._subscriptionPercent = 1;

        // Todo: All this service need a cleanup
        this.initializeSetupGuideService();

        this.UsersManager.on('loggingIn', this.initializeSetupGuideService.bind(this));
        this.OnboardingService.on('onboardingAdded', this.initializeSetupGuideService.bind(this));
    }

    Services.SetupGuideService = Class(function () {
        return {
            constructor: SetupGuideServiceCtor,

            onTopNavSetupGuideButtonClick: function onTopNavSetupGuideButtonClick() {
                this.stopAnimateSetupGuideTopNavAfterClose();
                this.$state.go(
                    this.OnboardingService.getSetupGuideState(),
                    { openOnboardingCard: true }
                );

                // This clears the parameter so that repeated SetupGuideButton clicks
                // will reopen the streamlined card
                this.$timeout(function () {
                    this.$state.params.openOnboardingCard = null;
                }.bind(this), 100);
            },

            getInteractiveResources: function getInteractiveResources() {
                this._setupGuideRawData = {
                    customizeItems: {

                        videoTutorials: {
                            ignoreInCalc: true,
                            disableOnMobile: false,
                            title: 'INTERACTIVE_ONBOARDING.RESOURCES.VIDEO_TUTORIALS._TITLE_',
                            image: '//res.cloudinary.com/honeybook/image/upload/v1527168848/app/setup_guide_modal/setup-full-account.svg',
                            externalLink: 'https://www.youtube.com/playlist?list=PL6TUvBzCkmeXaqKG62m8bUhQ1puQA3Fna',
                            clickHandler: function clickHandler() {
                                this.AnalyticsService.trackClick(
                                    this,
                                    "setup guide task",
                                    {
                                        setup_task: "video tutorials",
                                        source: "setup guide",
                                    }
                                );
                            }.bind(this)
                        },

                        webinars: {
                            ignoreInCalc: true,
                            disableOnMobile: false,
                            title: 'INTERACTIVE_ONBOARDING.RESOURCES.WEBINARS._TITLE_',
                            image: '//res.cloudinary.com/honeybook/image/upload/v1527168848/app/setup_guide_modal/setup-full-account.svg',
                            externalLink: this.UserService.getWebinarLink(),
                            clickHandler: function clickHandler() {
                                this.AnalyticsService.trackClick(
                                    this,
                                    "setup guide task",
                                    {
                                        setup_task: "webinars",
                                        source: "setup guide",
                                    }
                                );
                            }.bind(this)
                        },

                        accountSetup: {
                            ignoreInCalc: true,
                            disableOnMobile: false,
                            title: 'ONBOARDING.SETUP_GUIDE_STREAMLINED.SECONDARY_CONTENT._ACCOUNT_SETUP_',
                            clickHandler: function clickHandler() {
                                this.$state.go(this.AppStates.root_core_accountSetup)
                                this.AnalyticsService.trackClick(
                                    this,
                                    "setup guide task",
                                    {
                                        setup_task: "accountSetup",
                                        source: "setup guide",
                                    }
                                );
                            }.bind(this)
                        },

                    }
                }

                var stepsArray = this._.map(this._setupGuideRawData, function (list, listKey) {
                    return this._.map(list, function (item, itemKey) {
                        var clonedItem = this._.clone(item);
                        return this._.extend(clonedItem, {
                            key: itemKey,
                            listKey: listKey,
                            isComplete: false,
                            isLoading: false,
                        })
                    }.bind(this))
                }.bind(this));

                var concatedArrays = [];
                this._.each(stepsArray, function (array) {
                    concatedArrays = concatedArrays.concat(array);
                });
                return concatedArrays;
            },

            initializeSetupGuideService: function initializeSetupGuideService() {
                this.currUser = this.UsersManager.getCurrUser();
                if (this.currUser.isClient()) {
                    return;
                }
            },



            // lightbox test
            onLightboxShown: function onLightboxShown(event) {
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'demo_account_test', {stage: 'galley', action: 'opened'});
                }
            },

            onLightboxHidden: function onLightboxHidden(event) {
                // add ui only show once?
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'demo_account_test', {stage: 'galley', action: 'closed'});
                    this.openFirstQuestion();
                }
            },

            openFirstQuestion: function openFirstQuestion() {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info,
                    'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._LIKE_TO_TRY_',
                    function () {
                        // this.PopupMessageService.clearPopup();

                        this.$timeout(function () {
                            this.openSecondQuestion();
                        }.bind(this), 50);

                        this.AnalyticsService.track(this, 'demo_account_test', {
                            stage: 'first_question',
                            action: 'select_yes'
                        })
                    }.bind(this),
                    function () {
                        this.AnalyticsService.track(this, 'demo_account_test', {
                            stage: 'first_question',
                            action: 'dismiss'
                        })
                    }.bind(this),
                    'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._LIKE_TO_TRY_YES_',
                    'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._LIKE_TO_TRY_NO_');
            },

            openSecondQuestion: function openFirstQuestion() {
                var isCompanySubscribed = this.OnboardingService.isUserSubscribed();

                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info,
                    isCompanySubscribed ? 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_WEBINAR_' : 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_DEMO_',
                    function () {
                        isCompanySubscribed ? this.openWebinarPage() : this.openCalendly();
                        this.AnalyticsService.track(this, 'demo_account_test', {
                            stage: 'second_question',
                            action: 'select_yes',
                            is_subscribed: isCompanySubscribed
                        });
                    }.bind(this),
                    function () {
                        this.AnalyticsService.track(this, 'demo_account_test', {
                            stage: 'second_question',
                            action: 'select_no',
                            is_subscribed: isCompanySubscribed
                        })
                    }.bind(this),
                    isCompanySubscribed ? 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_WEBINAR_YES_' : 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_DEMO_YES_',
                    isCompanySubscribed ? 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_WEBINAR_NO_' : 'ONBOARDING.SETUP_GUIDE.STEPS.DEMO._SCHEDULE_DEMO_NO_');
            },

            openWebinarPage: function openWebinarPage() {
                var otherWindow = this.$window.open();
                otherWindow.opener = null;
                otherWindow.location = "https://www.honeybook.com/product-education";
            },

            openTrialerWebinarPage: function openTrialerWebinarPage() {
                var otherWindow = this.$window.open();
                otherWindow.opener = null;
                otherWindow.location = this.UserService.getWebinarLink();
            },

            openCalendly: function openCalendly() {
                this.AnalyticsService.track(this, 'demo_account_test', {stage: 'calendly_opened'});
                this.UserService.openAECalendlyLink(this.currUser);

            },

            onLightboxImageChanged: function onLightboxImageChanged(event) {
                if (event.container === this.lightboxContainer) {
                    this.AnalyticsService.track(this, 'demo_account_test', {stage: 'galley', action: 'image_changed'});
                }
            },

            isSetupGuideActiveForTrialers: function isSetupGuideActiveForTrialers() {

                this.currUser = this.UsersManager.getCurrUser();
                var isPreTrialer = this.currUser.shouldStartTrial();
                var isUserSubscribed = this.currUser.companyHasSubscription() && this.currUser.company.account.pricing_plan;
                var timeToStop = isUserSubscribed ? this.moment(this.currUser.company.account.pricing_plan.plan_start_date).add(14, 'days') : null;
                var ui_persistencyCompleted = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.setupGuideCompletedAllSteps, {allStepsCompleted: false}).allStepsCompleted;

                var isSetupGuideCompleted = this.isSetupGuideCompleted() || ui_persistencyCompleted;
                return !isSetupGuideCompleted && !isPreTrialer && (!isUserSubscribed || this.moment().isBefore(timeToStop));
            },

            isSetupGuideActiveForSubscribedUsers: function isSetupGuideActiveForSubscribedUsers() {
                this.currUser = this.UsersManager.getCurrUser();

                var isSetupGuideCompleted = this.isSetupGuideCompleted();
                var isCompanySubscribed = this.currUser.companyHasSubscription();
                var isStartedOnboardingAfterJan2019 = this.currUser.startedTrialAfterDate('2019-01-01');

                return (isCompanySubscribed && !isSetupGuideCompleted && isStartedOnboardingAfterJan2019);
            },

            isAccountSetupShown: function isAccountSetupShown() {
                var isSetupGuideCompleted = this.isSetupGuideCompleted();
                var isCompanySubscribed = this.currUser.companyHasSubscription();
                var isStartedOnboardingAfterJan2019 = this.currUser.startedTrialAfterDate('2019-01-01');
                var isUserActivated = this.currUser.isActivated();
                var isShowAccountSetup = this.currUser.company && this.currUser.company.offer_account_setup  && this.currUser.isCompanyOwner();

                return (isCompanySubscribed && isSetupGuideCompleted && isStartedOnboardingAfterJan2019 && !isUserActivated && isShowAccountSetup)
            },

            isSetupGuideCompleted: function isSetupGuideCompleted() {
                return this.OnboardingService.allOnboardingStepsAreComplete();
            },

            isSetupGuideActive: function isSetupGuideActive() {
                return (this.isSetupGuideActiveForSubscribedUsers() || this.isSetupGuideActiveForTrialers());
            },

            startInvoiceTemplateFlow: function startInvoiceTemplateFlow() {

                return this.SelfOnboardingManager.createOnboardingInvoiceTemplate().then( function(ret){
                    if (ret.data &&  ret.data.template_id) {
                        var templateId = ret.data.template_id;

                        //mixin the templates, another api call.
                        this.TemplatesManager.getInvoice(this.currUser.company, this.currUser, templateId).then(function () {
                            this.TemplatesViewService.goToTemplatesEditor('invoice', templateId);
                        }.bind(this));
                    }
                }.bind(this));
            },

            animateSetupGuideTopNavAfterClose: function animateSetupGuideTopNavAfterClose() {
                var shouldAnimate = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.setupGuideTopNavAnimate, {shouldAnimate: true}).shouldAnimate;
                if (shouldAnimate) {
                    this.setupGuideTopNavAnimationActive = true;
                    this.$timeout( function () {
                        this.setupGuideTopNavAnimationActive = false;
                    }.bind(this), 5000);
                }
            },

            stopAnimateSetupGuideTopNavAfterClose: function stopAnimateSetupGuideTopNavAfterClose() {
                this.UiPersistenceService.setUiPersistence(
                    this.UiPersistenceService.keys.setupGuideTopNavAnimate,
                    {shouldAnimate: false});
            },

            getPersistenceHomeOnboardingCard: function getPersistenceHomeOnboardingCard() {
                return this.UiPersistenceService.getUiPersistence(
                    this.UiPersistenceService.keys.homeOnboardingCard,
                    {
                        isCollapsed: false,
                        hasSeenCreateProjectPopover: false,
                        hasSeenSubscribeHerePopover: false,
                        hasMarkedComplete: false,
                        hasClickedCompleteMwebWizard: false,
                    }
                );
            },

            getPersistenceIntentOnboarding: function getPersistenceIntentOnboarding() {
                return this.UiPersistenceService.getUiPersistence(
                    'intentOnboarding', {}
                );
            },

            setPersistenceHomeOnboardingCard: function setPersistenceHomeOnboardingCard(hash) {
                var homeOnboardingCardPersistence =
                    this.getPersistenceHomeOnboardingCard();

                var updatedPersistence = this._.extend(
                    homeOnboardingCardPersistence,
                    hash
                );

                this.UiPersistenceService.setUiPersistence(
                    this.UiPersistenceService.keys.homeOnboardingCard,
                    updatedPersistence
                );
            },

            animateCreateEvent: function animateCreateEvent() {
                this.createEventIsAnimating = true;
                this.$timeout(function () {
                    this.createEventIsAnimating = false;
                }.bind(this), 5000);
            },

        };
    });
}());
