
// @ngInject
Directives.AutosizeTextareaDirective = function AutosizeTextareaDirective($timeout, $window, autosize) {


    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ngModel) {

            element.attr('rows', 1);
            autosize(element[0]);

            var update = function update(){
                autosize.update(element[0]);
            };

            scope.$on("triggerUpdateEvent", function(){
                update();
            });

            $timeout(update,1);

            scope.$watch(function() {
                return ngModel.$modelValue;
            }, function(newValue) {
                update();
            });

            var $win = angular.element($window);

            $win.bind('resize', update);

            element.on('keydown keyup focus input propertychange change', function () {
                update();
            });

            scope.$on('autosizeTextarea:update', function() {
                $timeout(update,1);
            });

            scope.$on('$destroy', function() {
                autosize.destroy(element[0]);
                $win.unbind('resize', update);
            });
        }
    };

};
