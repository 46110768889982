(function () {
    "use strict";

    class RescheduleWorkflowComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService) {

            super($scope, $injector);
            this.__objectType = 'RescheduleWorkflowComponent';
            this.AnalyticsService = AnalyticsService;
        }

        $onInit() {
            this.completedSteps = 0;
            this.workflow.workflow_steps.forEach(step => {
                if (step.workflow_step_action.completed) {
                    this.completedSteps++;
                } else if (!this.currentStepId) {
                    this.currentStepId = step._id;
                }
            });
        }

        dismiss() {
            this.onDismiss && this.onDismiss();
        }
    }

    Components.RescheduleWorkflowComponent = {
        bindings: {
            onComplete: '&',
            onDismiss: '&',
            workflow: '<',
            detachWorkflow: '<',
            toggleDetachWorkflow: '&',
            nextStepTitle: '<',
            project: '<'
        },
        controller: RescheduleWorkflowComponent,
        name: 'hbRescheduleWorkflow',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_workflow_template.html',
    };

}());
