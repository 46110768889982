/**
 * The hb-3dots-loader component
 *
 * This component shows a loader of 3 dots
 *
 *
 */


(function () {
    'use strict';

    class Loader3DotsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout) {
            super($scope, $injector);
            this.__objectType = 'Loader3DotsController';
        }
    }

    Components.Loader3Dots = {
        bindings: {
        },
        controller: Loader3DotsController,
        templateUrl: 'angular/app/modules/common/ui_components/loader-3dots/loader-3dots.html',
    };

}());
