Directives.QuestionnaireDirective = function QuestionnaireDirective() {

    // @ngInject
    function QuestionnaireDirectiveControllerFunc($q, $, $injector, $scope, $translate, $timeout, $element, $window, $document,
                                                  AnalyticsService, CompaniesManager, WorkspaceFilesManager, WorkspaceFileService, QuestionService,
                                                  uuid4, PopupMessageService, FlowsManager, FeaturesService, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'QuestionnaireDirectiveController';
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.FlowsManager = FlowsManager;
        this.FeaturesService = FeaturesService;
        this.PopupMessageService = PopupMessageService;
        this.QuestionService = QuestionService;
        this.$translate = $translate;
        this.$ = $;
        this._ = _;
        this.$q = $q;

        this.QUESTION_TYPES = {
            TEXT_BOX: 'textbox',
            OPEN_QUESTION: 'open_question',
            DROPDOWN: 'dropdown',
            RADIO: 'radio',
            CHECKBOX: 'checkbox',
            TEXTAREA: 'textarea'
        };

        this.sortableOptions = {
            axis: 'y',
            revert: 200,
            handle: '.js-question-drag',
            opacity: 0.8,
            placeholder: 'question-sortable-placeholder',
            helper: 'clone',

            stop: function (event, ui) {

                var args = {};
                var id = ui.item[0].id.split("-")[1];

                // Create order data
                self.questions_model.forEach(function (questionViewModel, index) {
                    var questionModel = self._.findWhere(self.questionnaireModel.questions, {_id: questionViewModel._id});
                    questionModel.order = index;

                    if (id === questionModel._id) {
                        args = questionModel.getAnalyticsArgs();
                    }

                }.bind(self));

                self.questionnaireModel.updateQuestionsOrder();

                AnalyticsService.track(self, AnalyticsService.analytics_events.questionnaire_question_drag, args);
            }
        };

        this.isEditMode = function isEditMode() {
            return this.isOwnerMode &&
                this.isEditableMode && !this.isPreviewMode;
        }.bind(this);

//      Question Editable = this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
//      Answer Editable = !this.isOwnerMode && this.isEditableMode
//      Answer Visible = !this.isEditableMode
//      Placeholder Editable? = QuestionEditable
//      Placeholder Visible? =

//      Regular - Question Editable
//      Hover - Question Editable
//      Edit - Question Editable
//      Client - Answer Editable
//      Summary - Answer Visible

        // HTML elements bindings
        var self = this;
        this.directiveId = uuid4.generate();
        this.focusedOnId = '';
        this.showAddLoader = false;

        this.questions_model = [];

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(self.directiveId);
        });

        var fillQuestionsModelCallback = function success() {
            self.fillViewModelAccordingToModel();
        };

        var modelToRegister = this.fileModel ? this.fileModel : this.companyModel;
        this.register(modelToRegister, 'success', fillQuestionsModelCallback);

        this.register(this.questionnaireModel, 'success', function success() {
            $timeout(function () {
                if (!this.focusedOnId) {
                    return;
                }

                $element.find('#answer-question-' + this.focusedOnId).focus();
            }.bind(this), 0);
        });

        this.fillViewModelAccordingToModel = function fillViewModelAccordingToModel() {
            this.questions_model.splice(0);

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.questions_model = _.sortBy(this.questionnaireModel.questions, function (question) {
                return question.order ? question.order : 0;
            });
        };
        this.fillViewModelAccordingToModel();

        this.setDOMElement = function setDOMElement(elem) {
            this.$element = elem;
            this.$addQOptions = $('.add-question-options', this.$element);
        };

        this.addQuestion = function addQuestion(type) {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;

            this.questionnaireModel.createQuestion({question_type: type}).then(
                function success() {
                    self.showAddOptions = false;
                    self.closeMenu();

                    // get last added question
                    var question = self.questions_model[self.questions_model.length - 1];
                    self.initializeNewQuestion(question);
                })
                .catch(function fail(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.too_many_items, {model: 'questionnaire', type: 'question'});
                    if (resp.data.error_type === 'TooManyItemsError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    }
                }.bind(this))
                .finally(function final() {
                    this.showAddLoader = false;
                }.bind(this));
        };

        this.initializeNewQuestion = function initializeNewQuestion(question) {
            // initial empty answers when a question is created
            if (question.question_type === 'checkbox') {
                this.QuestionService.addOptionalAnswer(question);
            }
            else if (question.question_type === 'radio') {
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
            } else if (question.question_type === 'dropdown') {
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
            }
            // click question to put into edit mode
            $element.find('#question-' + question._id + ' .questionnaire-question').click();
            // focus on question title field
            $element.find('#question-' + question._id + ' .question-title').focus();
        };

        this.addEventQuestions = function addEventQuestions() {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;

            //check if we already have such questions and show a warning
            var promiseToContinueWith = null;

            var alreadyHasSomeEventQuestionsAdded = this._.any(this.questions_model, {
                connected_to_object: 'event'
            });

            if(alreadyHasSomeEventQuestionsAdded){
                this.AnalyticsService.track(this, 'showing confirm to add event-connected questions');
                promiseToContinueWith = this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    'QUESTIONNAIRE._PROJECT_QUESTIONS_EXISTS_WARNNING_',
                    'FREQUENT_BUTTONS._OK_',
                    'FREQUENT_BUTTONS._CANCEL_'
                );

                promiseToContinueWith
                    .then(function success() {
                        this.AnalyticsService.track(this, 'confirm add event-connected questions');
                    }.bind(this))
                    .catch(function fail(resp) {
                        this.AnalyticsService.track(this, 'cancel confirm to add event-connected questions');
                        if (resp.data.error_type === 'TooManyItemsError') {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                        }
                    }.bind(this));

            } else {
                promiseToContinueWith = $q.resolve(true);
            }

            promiseToContinueWith
                .then(function confirmOk() {
                    return this.questionnaireModel.createEventQuestions();
                }.bind(this))
                .then(function successInCreatingEventQuestions() {
                    self.showAddOptions = false;
                    self.closeMenu();
                })
                .catch(function fail(resp) {
                    if (resp.data.error_type === 'TooManyItemsError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    }
                }.bind(this))
                .finally(function finallyHandler() {
                    self.showAddLoader = false;
                });

        };

        this.updateQuestion = function updateQuestion(question) {
            return this.questionnaireModel.updateQuestion(question);
        };

        this.deleteQuestion = function deleteQuestion(question) {
            return this.questionnaireModel.deleteQuestion(question);
        };

        this.closeMenu = function closeMenu() {
            this.showAddOptions = false;

            if (this.$question) {
                this.$question.find('.questionnaire-question').css('margin-bottom', 'inherit');
            }
        };

        this.toggleAddMenu = function toggleAddMenu() {
            this.showAddOptions = !this.showAddOptions;
            if (this.showAddOptions) {
                // scroll down to show add question menu
                $timeout(function () {
                    $window.scrollTo(0, $document[0].body.scrollHeight);
                });
            }
            var self = this;

            // if this isn't mobile...
            if (!($(window).width() / parseFloat($("html").css("font-size")) < 40.01)) {
                // add style to the closest hb-question
                if (this.$question) {
                    //this.$addQOptions.show();
                    setTimeout(function () {
                        var theHeight = $scope.questionnaireVm.$addQOptions.height() + 50;
                        $scope.questionnaireVm.$question.find('.questionnaire-question').css('margin-bottom', theHeight); //addClass('closestQuestionToAdd');
                    }, 2);

                }
            }
            else {
                if (this.$question) {
                    this.$question.find('.questionnaire-question').css('margin-bottom', 'inherit');
                }
            }
        };

        this.backFirstScreen = function backFirstScreen() {
            this.isSaveAsEnabled = false;
        };

        this.finalizeSaveAs = function finalizeSaveAs() {
            this.duringSaveAs = false;
            this.ProposalMenuActive = false;
            this.isSaveAsEnabled = false;
        };

        this.saveAsQuestionnaireTemplate = function saveAsQuestionnaireTemplate(title) {

            this.templateTitle = title;

            if (!this.templateTitle || this.templateTitle.length === 0) {
                this.duringSaveAs = false;
                PopupMessageService.showAlert(PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._MISSING_TEMPLATE_NAME_');
                return this.$q.reject();
            }

            var that = this;
            that.duringSaveAs = true;

            return CompaniesManager.saveAsQuestionnaireTemplate(that.companyModel, that.fileModel, that.templateTitle).then(
                function success() {

                    // origin_copy_id changes
                    that.fileModel = WorkspaceFilesManager.getQuestionnaireTemplateOrigin(that.fileModel);

                    that.finalizeSaveAs();

                    var analyticsArgs = {
                        workspace_file_id: that.fileModel._id,
                        template_title: that.templateTitle,
                        template_type: "questionnaire",
                        company_id: that.companyModel._id
                    };

                    AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_as_confirmed, analyticsArgs);
                },
                function error(resp) {
                    that.finalizeSaveAs();

                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                        'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');

                    var analyticsArgs = {
                        template_title: that.templateTitle,
                        workspace_file_id: that.fileModel._id,
                        template_type: "questionnaire",
                        company_id: that.companyModel._id,
                        error: resp.data.error_message
                    };

                    AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_as_failed, analyticsArgs);
                }
            );
        };

        // TODO (abe) refactor, perhaps by checking $pristine and/or $untouched state of question textarea
        this.shouldShowQuestionTooltip = function shouldShowQuestionTooltip() {
            if (this.isEditMode()) {
                // check if there are 2 questions, seems to be none before initialization
                if (this.questionnaireModel.questions.length == 2) {
                    // question with tooltip has been modified, check by value comparison (refactor to check untouched?)
                    // and check if user has clicked the question and activated editMode on the question
                    return !this.questionnaireModel.questions[1].question_text && !angular.element('#question-' + this.questionnaireModel.questions[1]._id + ' .questionnaire-question').hasClass('edit-mode');
                } else {
                    // tooltip does not show if there are no questions, or only 1, or more than 2
                    return false;
                }
            } else {
                return false;
            }
        };

        this.introIsEmpty = function introIsEmpty() {

            var text = null;
            var question = this.questionnaireModel.questions[0];
            if (question && question.question_type === 'textbox') {
                text = question.question_text;
            }
            return this._.isNull(text) || text.trim().length === 0;
        };

        WorkspaceFileService.addFileValidation(this.directiveId, function questionnaireOwnerValidation() {
            return $q(function questionnaireOwnerValidationPromise(resolve, reject) {
                if (this.isOwnerMode) {
                    if (this.questionnaireModel.questions.length > 0) {
                        resolve();
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'FILE.SEND_FILE.QUESTIONNAIRE._NO_QUESTIONS_MESSAGE_');
                        reject();
                    }
                } else {
                    var requiredCounter = 0;

                    // find required question without an answer
                    for (var index = 0; index < this.questionnaireModel.questions.length; index++) {
                        var question = this.questionnaireModel.questions[index];
                        if (question.required && question.isShownToClient() && !question.isAnswered()) {
                            requiredCounter++;
                            this.$("#question-" + question._id).addClass('question-submitted-invalid');
                        } else {
                            this.$("#question-" + question._id).removeClass('question-submitted-invalid');
                        }
                    }

                    if (requiredCounter > 0) {
                        this.errorMessage = requiredCounter > 1 ?
                            this.$translate.instant('FILE.SEND_FILE.QUESTIONNAIRE._NUMBER_OF_QUESTIONS_LEFT_', {emptyRequiredQuestionsCounter: requiredCounter}) :
                            this.$translate.instant('FILE.SEND_FILE.QUESTIONNAIRE._ONE_QUESTION_LEFT_');
                        this.$("html, body").animate({scrollTop: 0}, 200);
                        reject();

                    } else {
                        this.errorMessage = '';
                        resolve();
                    }
                }

            }.bind(this));
        }.bind(this), 300);

    }

    var QuestionnaireDirectiveController = Class(Controllers.BaseController, {
        constructor: QuestionnaireDirectiveControllerFunc
    });

    return {
        scope: {
            fileModel: '=fileModel',
            questionnaireModel: '=questionnaireModel',
            companyModel: '=companyModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            isViewOnlyMode: '=isViewOnlyMode',
            owner: '=owner'
        },
        templateUrl: 'angular/app/modules/core/features/questionnaire/questionnaire_directive_template.html',
        controller: QuestionnaireDirectiveController,
        controllerAs: 'questionnaireVm',
        bindToController: true,
        link: function (scope, element) {
            scope.questionnaireVm.setDOMElement(element);
        }
    };
};
