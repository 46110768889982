(function () {
    "use strict";

    // @ngInject
    function WorkspaceFileStepPaymentControllerCtor(
        $scope, $stateParams, $injector, _, $q, $log, $state, NavigationService,
        AnalyticsService, WorkspaceFileService, ModalService, SetupGuideService,
        PopupMessageService, AppConfigService, PlaidLinkService, PreviewModeService,
        AbTestService, Enums, WorkspaceFilesManager, WorkspaceService, $translate,
        UsersManager, AppStates, OnboardingService, RedirectService, $timeout, UserService,
        MobileAppService, DeviceService, InjectBrandingService, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileStepPaymentController';

        this.isPreviewMode = PreviewModeService.isInPreviewMode();
        this.$scope = $scope;
        this.$q = $q;
        this.$log = $log;
        this.NavigationService = NavigationService;
        this.DatadogRUMService = DatadogRUMService;

        // Services assignment
        this.SetupGuideService = SetupGuideService;
        this._ = _;
        this.RedirectService = RedirectService;
        this.OnboardingService = OnboardingService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspaceService = WorkspaceService;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.AppStates = AppStates;
        this.ModalService = ModalService;
        this.AppConfigService = AppConfigService;
        this.workspaceFileId = $stateParams.workspace_file_id;
        this.paymentId = $stateParams.payment_id;
        this.UsersManager = UsersManager;
        this.PlaidLinkService = PlaidLinkService;
        this.user = UsersManager.getCurrUser();
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$translate = $translate;
        this.PreviewModeService = PreviewModeService;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$timeout = $timeout;
        this.MobileAppService = MobileAppService;
        this.InjectBrandingService = InjectBrandingService;
        this.AnalyticsService = AnalyticsService;

        this.isInAppBrowser = DeviceService.isInAppBrowser();

        this.workspaceFile = WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
        AnalyticsService.trackPageView(this, 'payments step',
            {
                preview_mode: this.isPreviewMode,
                file_mode: WorkspaceFileService.whichCurrentFileView(this.workspaceFile)
            }
        );

        this.showOverallLoader = true;
        this.fetchingPaymentMethods = true;
        this.payment_methods = [];

        this.showEditButton = true;
        this.isLoggedInAsClient = false;
        this.showAddCardForm = false;
        this.addCardAndMakingPaymentInProcess = false;
        this.autopayAgreed = false;
        this.initPaymentTabs();

        PreviewModeService.setState('payment');

        var self = this;

        this.isLoggedInAsClient = this.user.client_login_file_id && this.user.client_login_file_id === this.workspaceFile._id;

        // redirect if there are not payments to pay
        if (this.workspaceFile.payments_container.isFullyPaid() && !this.isLoggedInAsClient) {
            this.AnalyticsService.track(this.$scope, this.AnalyticsService.analytics_events.access_payments_when_fully_paid,
                {
                    workspace_file: this.workspaceFile._id
                });
            this.goToState(this.AppStates.root_core_navigation_event_workspace_files, { workspace_id: this.workspaceFile.couple_card_id, event_id: this.workspaceFile.event._id });
        }

        this.noTip = { value: 0, label: 'FILE.PAYMENT._NO_THANKS_' };
        this.customTip = { value: '', label: 'FILE.PAYMENT._CUSTOM_TIP_' };
        this._updateTips();
        this.currentTip = this.noTip;

        if (this.workspaceFile.file_type !== undefined) {
            // we dont want to wait for the update to load the film strip, cause it causes a blick in the ui when
            // moving between states
            this.loadFilmStrip();
        }

        this.onEditPaymentMethodMode = !!this.$stateParams.editCardMode;

        this.setupCurrentPaymentModel();
        this.paymentNumberBeforeSuccess = this.paymentNumber;
        this.register(this.workspaceFile, 'success', function success() {
            this.gotWorkspaceFile();

            // redirect if you are not the client
            if (!this.isPreviewMode && this.user._id === this.workspaceFile.owner._id || this.workspaceFile.isViewOnlyMode()) {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile, null, true);
            }

            // redirect if there are not payments to pay
            if (this.workspaceFile.payments_container.isFullyPaid() && !this.isLoggedInAsClient) {
                this.goToState(this.AppStates.root_core_navigation_event_workspace_files, { workspace_id: this.workspaceFile.couple_card_id, event_id: this.workspaceFile.event._id });
            }

            // We want to recalculate the payment details because when the model is updated
            this.setupCurrentPaymentModel();
        });

        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(self.AppStates.root_core_navigation_home);
            }.bind(this));
        });

        this.setupPaymentMethodsModel();
        this.isDeletePaymentMethodMode = false;
        this.hasError = false;

        this.isAddCreditCardFormValid = false;
        $scope.$watch('workspaceFileStepPaymentVm.addPayFormValid', function (newVal, oldVal) {
            if (!angular.equals(newVal, oldVal)) {
                this.validationFromAddCreditCard(newVal);
            }
        }.bind(this), true);

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            UserService.onBookInPersonStateChange(event, toState);
        }.bind(this));

        if (this.isInAppBrowser) {
            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.file_step_active, { step: 'pay' });
        }

        if (this.$stateParams.isOnRedirect) {
            this.selectPaymentTab('Bank Transfer');
            this.plaidShow();
        }

    }


    Controllers.WorkspaceFileStepPaymentController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileStepPaymentControllerCtor,

        _updateTips: function _updateTips() {
            this.tipOptions = [
                { value: Math.round(this.workspaceFile.getTotalProposalValue() * 0.05), label: '5' },
                { value: Math.round(this.workspaceFile.getTotalProposalValue() * 0.10), label: '10' },
                { value: Math.round(this.workspaceFile.getTotalProposalValue() * 0.15), label: '15' }
            ];
        },

        shouldShowOverallLoader: function shouldShowOverallLoader() {
            return this.showOverallLoader || this.fetchingPaymentMethods;
        },

        onSavePaymentMethod: function onSavePaymentMethod() {
            var paymentMethod = this.getSelectedPaymentMethod();
            var shouldCreateCard = (!paymentMethod || paymentMethod._type === 'PaymentMethodStripeCard') && !this.getCreditCardsCount();

            if (shouldCreateCard) {
                this.processingRequest = true;
                this.$scope.$broadcast('addCard');
            } else {
                this._editRecurringPaymentMethod();
            }
        },

        initPaymentTabs: function initPaymentTabs() {
            switch (this.workspaceFile.accepted_payment_method) {
                case this.Enums.PaymentMethods.creditCard:
                    this.achComponentTabs = [{ name: 'Credit Card' }];
                    break;

                case this.Enums.PaymentMethods.bankAccount:
                    this.achComponentTabs = [{ name: 'Bank Transfer' }];
                    break;

                default:
                case null:
                    this.achComponentTabs = [{ name: 'Bank Transfer' }, { name: 'Credit Card' }];
                    break;
            }

            if (this.workspaceFile.accepted_payment_method) {
                this.activePaymentTab = this.achComponentTabs[0].name; // credit card or ach tab
            } else {
                this.activePaymentTab = this.achComponentTabs[1].name; // credit card or ach tab
            }


            this.achStates = [
                { name: 'list' },
                { name: 'select' }
            ];
            this.activeAchState = this.achStates[0].name; // list ach accounts or select ach account

        },

        gotWorkspaceFile: function gotWorkspaceFile(message, status) {

            if (this.workspaceFile.company) {

                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }
            this.autopayActive = this.workspaceFile.payments_container.auto_payment && this.workspaceFile.payments_container.auto_payment.status === this.Enums.AutoPaymentStatus.active;
            this.autoPaymentByVendor = this.workspaceFile.payments_container.isAutoPayByVendor();
            this.shouldShowAutoPayment = this.workspaceFile.company.account.card_on_file_enabled && (!this.currentPayment.isLastPayment() || this.autopayActive) && !this.isLoggedInAsClient;
            this.isRecurringPayment = this.workspaceFile.payments_container.isRecurringPayment();
            this.shouldShowRecurringPayment = this.currentPayment.isFirstPayment() && this.isRecurringPayment && !this.workspaceFile.isRecurringPaymentAccepted();
            this.shouldUpdateRecurringPaymentMethod = this.isRecurringPayment && this.workspaceFile.isRecurringPaymentAccepted();

            if (!this.isPreviewMode && !this.isOwnerMode && !this.alreadyGotFile) {
                this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.payments_seen);
            }

            this.enableAch = this.workspaceFile.isPaymentMethodAccepted(this.Enums.PaymentMethods.bankAccount);

            if (this.enableAch) {
                this.setupBankAccountModel();
            }

            this.isOwnerMode = this.workspaceFile.isOwnerMode();
            this.isClientMode = this.workspaceFile.isClientMode();
            this.alreadyGotFile = true;

            this.loadFilmStrip();
            this.showOverallLoader = false;

            this._updateTips();
            this._setHeaderImage();
        },

        _setHeaderImage: function _setHeaderImage() {
            if (this.workspaceFile.workspace_file_header_icon) {
                this.headerImage = this.workspaceFile.workspace_file_header_icon;
                this.isHeaderIconImage = true;
            } else {
                if (this.workspaceFile.company.icon_image) {
                    this.headerImage = this.workspaceFile.company.icon_image.url;
                }
            }
        },

        loadFilmStrip: function loadFilmStrip() {
            if (this.filmStripLoaded === true) {
                return;
            }

            this.filmStripLoaded = true;
            this.linksTo = this.WorkspaceFileService.getFilmstripLinksForWorkspace(
                this.workspaceFile, 'pay', this.$stateParams);
        },

        showAddAchMethod: function showAddAchMethod() {
            if (this.enableAch) {
                this.plaidShow();
            }
        },

        plaidShow: function plaidShow() {
            if (this.isPreviewMode) {
                return;
            }
            this.achBankAccounts = [];
            this.showAchBankAccountsLoader = true;
            this.PlaidLinkService.launchPlaidLinkForAuth({ shouldRedirect: true, paymentId: this.paymentId, source: 'payment' }).then(
                function launchPlaidLinkSuccess(plaidResponseMetadata) {
                    this.AnalyticsService.trackSuccess(this.$scope, this.AnalyticsService.analytics_events.add_bank_account, plaidResponseMetadata);
                    this.activeAchState = this.achStates[1].name;

                    this.achBankAccounts = plaidResponseMetadata.accounts;
                    this.selectedNewAccountPlaidInstitution = plaidResponseMetadata.institution;
                    this.selectedNewAccount = this.achBankAccounts[0];
                    this.showAchBankAccountsLoader = false;
                }.bind(this),
                function launchPlaidLinkError(res) {
                    if (res && res.err) {
                        var message = 'ERRORS.SERVER_API._UNEXPECTED_';
                        if (res && res.reason === 'USER_FAIL' && res.err && res.err.display_message) {
                            message = res.err.display_message; // message from plaid
                        }
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                    }

                    this.showAchBankAccountsLoader = false;

                    // go back to the add account or credit card state
                    this.cancelAchAccountSelection();

                    this.AnalyticsService.trackError(this.$scope, this.AnalyticsService.analytics_events.add_bank_account, {}, res.err);
                }.bind(this));
        },

        selectPaymentTab: function selectPaymentTab(tabName) {
            this.hasError = false;
            if (this.activePaymentTab !== tabName) {
                this.activePaymentTab = tabName;
                this.isDeletePaymentMethodMode = false;
                this.showAddCardForm = false;
            }
        },

        setSelectedNewAccount: function setSelectedNewAccount(account) {
            this.selectedNewAccount = account;
        },

        setSelectedCurrentAccount: function setSelectedCurrentAccount(account) {
            if (this.isDeletePaymentMethodMode) {
                var analyticsParams = { payment_method_id: account._id, method_type: account._type, credit_card_name: account.name };
                this.AnalyticsService.track(this.$scope, 'click: ' + this.AnalyticsService.analytics_events.bank_account_deleted, analyticsParams);
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, 'FILE.PAYMENT.ALERT._CONFIRM_DELETE_',
                    this.deleteAccountMethod.bind(this, account), undefined, undefined, undefined, undefined, true);
            }
            this.selectedCurrentAccount = account;
            // this.showEditButton = this.hasSelectedPaymentMethod();
        },

        submitAchAccountSelection: function submitAchAccountSelection(account) {
            this.activeAchState = this.achStates[0].name;
            this.showOverallLoader = true;

            if (!this.selectedNewAccount) {
                return;
            }

            this.UsersManager.createChargeableBankAccount(
                this.user, this.selectedNewAccount, null,
                this.selectedNewAccountPlaidInstitution.institution_id,
                this.selectedNewAccountPlaidInstitution.name,
                this.workspaceFileId
            ).then(
                function successCreatingBankAccount(resp) {
                    var newPaymentMethod = resp.data;
                    this.setupBankAccountModel({ paymentMethodToAdd: newPaymentMethod });
                    this.$log.info('success in creating the bank account payment method');
                }.bind(this),
                function errorCreatingBankAccount(res) {
                    if (res.data && res.data.error) {
                        this.errorMessage = res.data.error_message;
                        this.hasError = true;
                    }
                    this.$log.error('error creating chargeable bank account', arguments);
                }.bind(this)
            ).finally(function finallyCallback() {
                this.showOverallLoader = false;
            }.bind(this));

        },

        cancelAchAccountSelection: function cancelAchAccountSelection(account) {
            this.activeAchState = this.achStates[0].name;
        },

        continueToPayment: function continueToPayment() {

            this.working = true;
            var promise = this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.payments_seen);

            promise.then(function success() {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
            }.bind(this));

            promise.finally(function fulfilled() {
                this.working = false;
            }.bind(this));
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        setupCurrentPaymentModel: function setupCurrentPaymentModel() {

            // position field is not accessible yet
            var nonPaidPayments = [];
            this.workspaceFile.payments_container.payments.forEach(function (payment) {
                if (!payment.is_paid && !payment.is_pending) {
                    nonPaidPayments.push(payment);
                }
            });
            nonPaidPayments = this._.sortBy(nonPaidPayments, function (payment) {
                return payment.due_date;
            });
            if (nonPaidPayments.length === 0) {
                // There are no payments left to pay so we're going to the summary view
                this.WorkspaceFileService.gotoWorkspaceFileSummary(this.workspaceFileId);
                return;
            }

            if (this.paymentId) {
                this.currentPayment = nonPaidPayments.find(function (payment) { return payment._id == this.paymentId }.bind(this));
            }

            if (!this.currentPayment) {
                this.currentPayment = nonPaidPayments[0];
            }

            var hasUnpaidPayments = nonPaidPayments.length > 0;
            var notFirstUnpaid = nonPaidPayments.length > 0 && !this.currentPayment.isFirstPayment();
            var isOnlyPayment = this.workspaceFile.payments_container.payments.length === 1;
            this.showGratuity = this.workspaceFile.allow_gratuity && (this.isPreviewMode || (hasUnpaidPayments && (notFirstUnpaid || isOnlyPayment)));
            this.paymentNumber = this.workspaceFile.payments_container.payments.findIndex(function (payment) { return payment._id == this.currentPayment._id }.bind(this)) + 1;
            this.totalPrice = this.workspaceFile.getTotalProposalValue();

            this.paymentsLeft = this.workspaceFile.payments_container.payments.length - this.paymentNumber;
        },

        _setupPaymentMethodsModel: function _setupPaymentMethodsModel() {
            var methods = this.payment_methods.filter(function (method) {
                return method._type === 'PaymentMethodStripeCard';
            });
            if (methods.length > 0) {
                this.currentPaymentMethod = methods[methods.length - 1];
            } else {
                this.currentPaymentMethod = undefined;
            }
        },

        _setupBankAccountModel: function _setupBankAccountModel() {
            var methods = this.payment_methods.filter(function (method) {
                return method._type === 'PaymentMethodChargeableBankAccount';
            });

            if (methods.length > 0) {
                this.selectedCurrentAccount = methods[methods.length - 1];
            } else {
                this.selectedCurrentAccount = null;
            }
        },

        _handlePaymentMethodChange: function _handlePaymentMethodChange(paymentMethodData) {
            if (paymentMethodData) {
                var paymentMethodToAdd = paymentMethodData.paymentMethodToAdd;
                var paymentMethodToRemove = paymentMethodData.paymentMethodToRemove;
                if (paymentMethodToAdd) {
                    this.payment_methods.push(paymentMethodToAdd);
                }
                if (paymentMethodToRemove) {
                    this.payment_methods = this.payment_methods.filter(function (pm) { return pm._id !== paymentMethodToRemove._id })
                }
            }
        },

        setupBankAccountModel: function setupBankAccountModel(paymentMethodData) {
            if (this.enableAch) {
                this._handlePaymentMethodChange(paymentMethodData);
                this.refreshPaymentMethods(this._setupBankAccountModel)
            }
        },

        setupPaymentMethodsModel: function setupPaymentMethodsModel(paymentMethodData) {
            this._handlePaymentMethodChange(paymentMethodData);
            this.refreshPaymentMethods(this._setupPaymentMethodsModel);
        },

        getCreditCardsCount: function getCreditCardsCount() {
            return this.getCreditCards().length;
        },

        getCreditCards: function getCreditCards() {
            if (this.isPreviewMode) {

                this.dummyCreditCard = this.dummyCreditCard || {
                    _id: '123abc',
                    type: 'MasterCard',
                    last4: 'test'
                };

                this.selectPaymentMethod(this.dummyCreditCard);

                return [this.dummyCreditCard];
            }

            return this.payment_methods.filter(function (method) {
                return method._type === 'PaymentMethodStripeCard';
            });
        },

        getBankAccounts: function getBankAccounts() {
            if (this.isPreviewMode) {

                this.dummyBankAccount = this.dummyBankAccount || {
                    _id: '456fde',
                    subtype: 'test',
                    last4: '9898'
                };

                this.setSelectedCurrentAccount(this.dummyBankAccount);

                return [this.dummyBankAccount];
            }

            return this.payment_methods.filter(function (method) {
                return method._type === 'PaymentMethodChargeableBankAccount';
            });
        },

        openAddCardForm: function openAddCardForm() {
            this.hasError = false;
            if (!this.isPreviewMode) {
                this.showAddCardForm = true;
            }
            this.closeExpirationDateTooltip();
        },

        closeAddCardForm: function closeAddCardForm() {
            this.showAddCardForm = false;
        },

        successAddCardForm: function successAddCardForm(newPaymentMethod) {
            this.isDeletePaymentMethodMode = false;
            this.setupPaymentMethodsModel({ paymentMethodToAdd: newPaymentMethod });
            this.closeAddCardForm();
            this._trackNewCreditCard(newPaymentMethod._id);
            if (this.shouldUpdateRecurringPaymentMethod) {
                this._editRecurringPaymentMethod();
            }
        },

        successAddAchForm: function successAddAchForm(newPaymentMethod) {
            this.isDeletePaymentMethodMode = false;
            this.setupPaymentMethodsModel({ paymentMethodToAdd: newPaymentMethod });
            if (this.shouldUpdateRecurringPaymentMethod) {
                this._editRecurringPaymentMethod();
            }
        },

        successAddCardSubmitFirstPayment: function successAddCardSubmitFirstPayment(newPaymentMethod) {
            this.successAddCardForm(newPaymentMethod);
            this._internalCharge();
        },

        failedAddCardSubmitFirstPayment: function failedAddCardSubmitFirstPayment() {
            this.addCardAndMakingPaymentInProcess = false;
        },

        selectPaymentMethod: function selectPaymentMethod(method) {
            if (this.isDeletePaymentMethodMode) {
                var analyticsParams = { payment_method_id: method._id, method_type: method._type, credit_card_name: method.name };
                this.AnalyticsService.track(this.$scope, 'click: ' + this.AnalyticsService.analytics_events.delete_credit_card, analyticsParams);
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, 'FILE.PAYMENT.ALERT._CONFIRM_DELETE_',
                    this.deletePaymentMethod.bind(this, method), undefined, undefined, undefined, undefined, true);
            } else {
                this.currentPaymentMethod = method;
            }

            // this.showEditButton = this.hasSelectedPaymentMethod();
        },

        deletePaymentMethod: function deletePaymentMethod(method) {
            var promise = this.user.deletePaymentMethod(method._id, true);
            var analyticsParams = { payment_method_id: method._id, method_type: method._type, credit_card_name: method.name };
            promise.then(
                function success() {
                    this.AnalyticsService.trackSuccess(this.$scope, this.AnalyticsService.analytics_events.delete_credit_card, analyticsParams);
                    this.setupPaymentMethodsModel({ paymentMethodToRemove: method });
                }.bind(this),
                function canceled() {
                    this.AnalyticsService.trackCancel(this.$scope, this.AnalyticsService.analytics_events.delete_credit_card, analyticsParams);
                }.bind(this)
            );

        },

        deleteAccountMethod: function deleteAccountMethod(method) {
            var promise = this.user.deletePaymentMethod(method._id, true);
            var analyticsParams = { payment_method_id: method._id, method_type: method._type, credit_card_name: method.name };
            promise.then(
                function success() {
                    this.AnalyticsService.trackSuccess(this.$scope, this.AnalyticsService.analytics_events.bank_account_deleted, analyticsParams);
                    this.setupBankAccountModel({ paymentMethodToRemove: method });
                }.bind(this),
                function canceled() {
                    this.AnalyticsService.trackCancel(this.$scope, this.AnalyticsService.analytics_events.bank_account_deleted, analyticsParams);
                }
            );

        },

        hasSelectedPaymentMethod: function hasSelectedPaymentMethod() {
            return !!this.getSelectedPaymentMethod();
        },

        getSelectedPaymentMethod: function getSelectedPaymentMethod() {
            if (this.enableAch) {
                if (this.activePaymentTab === this.achComponentTabs[0].name) { // PAY WITH BANK ACCOUNT
                    return this.selectedCurrentAccount;
                } else if (this.activePaymentTab === this.achComponentTabs[1].name) { // PAY WITH CREDIT CARD
                    return this.currentPaymentMethod;
                }
            } else { // PAY WITH CREDIT CARD
                return this.currentPaymentMethod;
            }
        },

        _openClientExperienceConfirmAutomaticPaymentsPopup: function _openClientExperienceConfirmAutomaticPaymentsPopup() {
            return this.PopupMessageService.showConfirmWithDontShowAgainPromise(
                this.PopupMessageService.severityTypes.none,
                'FILE.PAYMENT._AUTO_PAY_DESC_',
                'FILE.PAYMENT._AUTO_PAY_TITLE_',
                'FILE.PAYMENT._AUTO_PAY_CTA_',
                null, // 'Cancel' by default
                null, // Not using UI Persistence (checkbox hidden in CSS)
                'file-preview-hb-popup', // But we are using CSS class
                true
            );
        },

        _openClientExperienceCompletePopup: function _openClientExperienceCompletePopup() {
            const popupText = this.PreviewModeService.templateOriginId
                ? 'FILE.PAYMENT._TEMPLATES_PREVIEW_MODE_TEST_DONE_'
                : 'FILE.PAYMENT._PREVIEW_MODE_TEST_DONE_';
            const popupCta = this.PreviewModeService.templateOriginId
                ? 'FILE.PAYMENT._RETURN_TO_TEMPLATE_'
                : 'FILE.PAYMENT._RETURN_TO_FILE_';

            return this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.none,
                popupText,
                popupCta,
                null, // 'Cancel' by default
                'FILE.PAYMENT._PREVIEW_MODE_TITLE_DONE_',
                undefined,
                undefined,
                undefined,
                undefined,
                true
            );
        },

        _handleClientExperiencePopupConfirm: function _handleClientExperiencePopupConfirm() {
            this.AnalyticsService.trackClick(this, 'confirm return to file');
            if (this.PreviewModeService.shouldExplicitlyExitPreviewtoEditFile) {
                this.PreviewModeService.returnToFile(this.workspaceFile, this.$state.params, true);
            } else {
                this.NavigationService.goHistoryBack();
            }
        },

        _handleClientExperiencePopupReject: function _handleClientExperiencePopupReject() {
            this.AnalyticsService.trackClick(this, 'dismissed return to file');
        },

        getActivateAutoPayment: function getActivateAutoPayment() {
            return this.autopayAgreed || this.autopayActive;
        },

        getActivateRecurringPayment: function getActivateRecurringPayment() {
            return this.shouldShowRecurringPayment && this.recurringPaymentAgreed;
        },

        triggerCharge: function triggerCharge(method, analyticsProperties) {
            var promise = this.workspaceFile.payPayment(
                this.currentPayment,
                method,
                (this.currentTip.value || 0),
                this.getActivateAutoPayment(),
                this.isLoggedInAsClient,
                this.getActivateRecurringPayment());

            promise.then(function success(resp) {
                try {
                    this.AnalyticsService.trackSuccess(this.$scope, this.AnalyticsService.analytics_events.payment_paid, analyticsProperties);

                    var receiptStateParams = this.AppConfigService.receiptState();
                    receiptStateParams.workspaceFile = resp.model;
                    receiptStateParams.paymentId = this.currentPaymentId;

                    this.addCardAndMakingPaymentInProcess = false;

                } catch (err) {
                    this.DatadogRUMService.addError(
                        err,
                        {
                            message: 'Error in payment_paid success callback'
                        }
                    );
                } finally {
                    // always go to receipt, we remove the setup page
                    this.goToState(this.AppStates.root_core_receipt, { isLoggedInAsClient: this.isLoggedInAsClient }, { location: false });
                }
            }.bind(this))
                .catch(function failure(resp) {
                    if (resp.data && resp.data.error_message) {
                        if (resp.data.error_type === "HistoryVersionError") {
                            var file = null;
                            // Get the new file and resolve a promise to know when it's done
                            var getFilePromise = this.$q(function getFileQueue(resolve, reject) {
                                file = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
                                this.registerOnce(file, 'success', function success() {
                                    resolve();
                                });
                                this.registerOnce(file, 'error', function error() {
                                    reject();
                                });
                            }.bind(this));

                            // show an error message and resolve a promise when it's done
                            var messagePromise = this.$q(function messageAlertQueue(resolve, reject) {
                                this.PopupMessageService.showAlertWithCloseCallback(this.PopupMessageService.severityTypes.warning, resp.data.error_message, function popupClose() {
                                    resolve();
                                });
                            }.bind(this));

                            this.$q.all([getFilePromise, messagePromise]).finally(function allPromisesDone() {
                                this.WorkspaceFileService.gotoWorkspaceFileView(file);
                            }.bind(this));
                        }
                        else if (resp.data.error_type === 'HBStripeCardDeclinedError') {
                            this.errorMessage = this.$translate.instant('FILE.PAYMENT.ERRORS._CARD_DECLINED_');
                        }
                        else {
                            this.errorMessage = resp.data.error_message;
                        }
                    } else {
                        this.errorMessage = 'Unknown error has occurred';
                    }

                    this.hasError = true;
                    this.addCardAndMakingPaymentInProcess = false;

                }.bind(this))
                .finally(function complete() {
                    this.processingRequest = false;
                }.bind(this));
        },

        _internalCharge: function _internalCharge() {

            if (this.isPreviewMode) {
                if (this.autopayAgreed) {
                    this._openClientExperienceConfirmAutomaticPaymentsPopup()
                        .then(this._openClientExperienceCompletePopup.bind(this))
                        .then(this._handleClientExperiencePopupConfirm.bind(this))
                        .catch(this._handleClientExperiencePopupReject.bind(this));
                    return;
                } else {
                    this._openClientExperienceCompletePopup()
                        .then(this._handleClientExperiencePopupConfirm.bind(this))
                        .catch(this._handleClientExperiencePopupReject.bind(this));
                    return;
                }
            }

            var method = this.getSelectedPaymentMethod();

            if (!method) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'FILE.PAYMENT.ALERT._NO_CARD_SELECTED_');
                return;
            }

            var analyticsProperties = {
                method_type: method._type,
                credit_card_name: method.name,
                payment_tip: this.currentTip.value
            };

            this.AnalyticsService.track(this.$scope, 'click: ' + this.AnalyticsService.analytics_events.payment_paid, analyticsProperties);

            this.errorMessage = false;
            this.processingRequest = true;
            this.currentPaymentId = this.currentPayment._id;

            // For GA tracking
            this.oldStatus = this.workspaceFile.status_cd;

            var forterPreValidatePromise = this.workspaceFile.preValidateCharge(
                this.currentPayment,
                method,
                (this.currentTip.value || 0),
                this.getActivateAutoPayment(),
                this.isLoggedInAsClient);

            forterPreValidatePromise.then(function success(resp) {
                if (resp.data.result) {
                    this.triggerCharge(method, analyticsProperties);
                }
                else {
                    this.errorMessage = this.$translate.instant('FILE.PAYMENT.ERRORS._FORTER_PRE_CHARGE_DECLINE');
                    this.hasError = true;
                    this.addCardAndMakingPaymentInProcess = false;
                    this.processingRequest = false;
                }

            }.bind(this))
                .catch(function failure(resp) {
                    if (resp.data && resp.data.error_type === 'HBUserError') {
                        this.errorMessage = resp.data.error_message;
                        this.hasError = true;
                        this.addCardAndMakingPaymentInProcess = false;
                    }
                    else {
                        // If something bad happened we cannot stop charge from processing
                        this.triggerCharge(method, analyticsProperties);
                    }
                    this.processingRequest = false;
                }.bind(this))
                .finally(function complete() {
                }.bind(this));
        },

        _editRecurringPaymentMethod: function _editRecurringPaymentMethod() {
            this.processingRequest = true;

            this.workspaceFile.updateRecurringPaymentMethod(this.getSelectedPaymentMethod()._id).then(function () {
                this.$state.go(this.$state.previous.route.name, this.$state.previous.routeParams);
            }.bind(this)).finally(function () {
                this.processingRequest = false;
            }.bind(this));
        },

        _addCardTooltip: function _addCardTooltip() {
            this.$timeout(function () {
                this.expirationDateAddNewCard = true;
            }.bind(this), 500);
        },

        closeExpirationDateTooltip: function closeExpirationDateTooltip() {
            this.expirationDateAddNewCard = false;
        },

        onPayWithExistingCard: function onPayWithExistingCard() {
            var paymentMethod = this.getSelectedPaymentMethod();
            this.hasError = false;

            if (!this._isNewCreditCard(paymentMethod._id) && paymentMethod._type === 'PaymentMethodStripeCard') {
                this.ModalService.openCreditCardConfirmModal(paymentMethod)
                    .then(function (res) {
                        switch (true) {
                            case res.valid:
                                this.onPayClick();
                                break;
                            case res.addNewCard:
                                this._addCardTooltip();
                                break;
                        }
                    }.bind(this));
            } else {
                this.onPayClick();
            }
        },

        onPayClick: function onPayClick() {
            var promise = this.$q.when();
            var isAutopay = this.autopayAgreed;
            var paymentMethod = this.getSelectedPaymentMethod();
            var addCard = (!paymentMethod || paymentMethod._type === 'PaymentMethodStripeCard') && !this.getCreditCardsCount();
            var analProps = {
                auto_pay: isAutopay,
                add_card: addCard,
                payment_tip: this.currentTip.value || null,
                preview_mode: this.isPreviewMode,
                payment_method_type: paymentMethod ? (paymentMethod._type === "PaymentMethodStripeCard" ? 'credit card' : 'bank transfer') : 'new card',
                payment_method: paymentMethod ? paymentMethod._type : ''
            };

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.pay, analProps);

            // auto pay
            if (isAutopay && !this.autoPaymentByVendor) {
                if (this.isPreviewMode) {
                    this._openClientExperienceConfirmAutomaticPaymentsPopup()
                        .then(this._openClientExperienceCompletePopup.bind(this))
                        .then(this._handleClientExperiencePopupConfirm.bind(this))
                        .catch(this._handleClientExperiencePopupReject.bind(this));
                    return;
                }
                promise = this.ModalService.openConfirmAutoPayModal(false);
            }

            promise.then(function ok() {

                if (addCard) {
                    this.$scope.$broadcast('AddCardAndPay');
                    this.addCardAndMakingPaymentInProcess = true;
                }
                else {
                    this._internalCharge();
                }

            }.bind(this));
        },

        setTip: function setTip(tip, inputId) {
            this.currentTip = tip;
            if (inputId) {
                angular.element('#' + inputId).focus();
                this.shouldShowDollarSign = true;
                this.currentTipFocused = true;
            }
        },

        onCustomTipBlur: function onCustomTipBlur() {
            this.shouldShowDollarSign = false;
            this.currentTipFocused = false;

            if (this.customTip.value > 0) {
                this.shouldShowDollarSign = true;
            } else {
                this.shouldShowDollarSign = false;
            }
        },

        calculateTotal: function calculateTotal() {
            return (this.currentPayment !== undefined ? this.currentPayment.amount + (this.currentTip.value || 0) : 0);
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        openIntakeModal: function openIntakeModal() {
            this.ModalService.openIntakeModal().then(
                this.onIntakeModalSubmission.bind(this));
        },

        onIntakeModalSubmission: function onIntakeModalSubmission() {
            var userRequest = this.UsersManager.forceFetchCurrUser();

            var redirect = userRequest.then(function () {
                this.RedirectService.redirect(this.user);
            }.bind(this));
        },

        validationFromAddCreditCard: function validationFromAddCreditCard(res) {
            this.isAddCreditCardFormValid = res;
        },

        autopayValid: function autopayValid() {
            if(!this.workspaceFile.company.account.card_on_file_enabled && this.autoPaymentByVendor){
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.ForceAutopayOnCardOnFileDisabled);
            }
            return this.autopayActive || (this.autoPaymentByVendor ? this.autopayAgreed : true) || !this.workspaceFile.company.account.card_on_file_enabled;
        },

        allowPay: function allowPay() {
            return (this.isPreviewMode || this.currentPayment.allowPay()) &&
                this.autopayValid() &&
                (!this.shouldShowRecurringPayment || this.recurringPaymentAgreed);
        },

        hasPaymentMethod: function hasPaymentMethod() {
            if (this.activePaymentTab === 'Credit Card') {
                return this.getCreditCardsCount() > 0;
            }

            return this.getBankAccounts().length > 0;
        },

        _trackNewCreditCard: function _trackNewCreditCard(paymentMethodId) {
            this.newPaymentMethods = this.newPaymentMethods || [];
            this.newPaymentMethods.push(paymentMethodId);
        },

        _isNewCreditCard: function _isNewCreditCard(paymentMethodId) {
            this.newPaymentMethods = this.newPaymentMethods || [];
            return this.newPaymentMethods.indexOf(paymentMethodId) !== -1;
        },

        refreshPaymentMethods: function refreshPaymentMethods(setupModelFunction) {
            // only fetch payment methods once - all other operations happen locally.
            if(this.fetchingPaymentMethods){
                this.user.getValidPaymentMethods(this.workspaceFileId).then(function(response){
                    if(response.data){
                        this.payment_methods = response.data;
                    }
                }.bind(this)).catch(function (error) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, error);
                }.bind(this)).finally(function () {
                    this.fetchingPaymentMethods = false;
                    if (setupModelFunction) {
                        setupModelFunction.bind(this)();
                    }
                }.bind(this));
            } else {
                if (setupModelFunction) {
                    setupModelFunction.bind(this)();
                }
            }
        }
    });
}());
