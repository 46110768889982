(function () {
    // @ngInject
    Services.HttpMetadataInterceptor = function HttpMetadataInterceptor($log, Gon, UrlMetadataService) {

        const IGNORE_REQUEST_URLS = [
            'https://api.cloudinary.com',
            'https://res.cloudinary.com'
        ];

        return {

            'request': (config) => {
                try {

                    var ignore = IGNORE_REQUEST_URLS.some((url) => {
                        return config.url.startsWith(url);
                    });

                    if (ignore || config.url.indexOf(Gon.api_urls_prefix + '/api/v') !== 0) { // Add only to api calls
                        return config;
                    }

                    config.url = UrlMetadataService.addMetadata(config.url);

                } catch (e) {
                    // ignoring error here so we won't block the http call
                    $log.error(e);
                }
                return config;
            }
        };
    };

}());