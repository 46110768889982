// hb-classic-editor

(function () {
    'use strict';


    class ClassicCKEditorController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $element, CkEditor5, FontsService, PhotosUploadManager, CompaniesManager, ColorsService, ImageService, UIUtils, $, $q) {
            super($scope, $injector);
            this.ImageService = ImageService;
            this.UIUtils = UIUtils;
            this.$ = $;
            this.$q = $q;

            const self = this;

            const fontFamilyOptions = FontsService.ckFonts;

            const defaultToolbar = ['fontSize', 'bold', 'italic', 'underline', 'hbfontColor', 'fontBackgroundColor', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', 'bulletedList', 'numberedList', 'horizontalLine', 'link', 'uploadModal', 'removeFormat', 'undo'];
            if (this.calendlyUrl) {
                defaultToolbar.push("addCalendlyLink");
            }

            const textColors = ColorsService.textColors;

            CkEditor5.ClassicEditor.create(($element[0]), {
                plugin: [],
                removePlugins: [ 'ImageResize' ],
                image: {
                    resizeUnit: 'px'
                },
                toolbar: defaultToolbar,
                fontFamily: {
                    options: fontFamilyOptions
                },
                fontColor: {
                    colors: textColors
                },
                fontBackgroundColor: {
                    colors: textColors
                },
                resize_enabled: false,
                autosave: {
                    waitingTime: 1000,
                    save() {
                        if (self.saveFunction && angular.isFunction(self.saveFunction)) {
                            return self.saveFunction();
                        } else {
                            const deferred = self.$q.defer();
                            deferred.resolve();
                            return deferred.promise;
                        }


                    }
                },
                link: {
                    defaultProtocol: 'https://',
                    addTargetToExternalLinks: true
                }
            })
                .then(editor => {

                    this.editorReference = editor;

                    // init data
                    if (this.ngModelCtrl.$modelValue) {
                        editor.setData(this.ngModelCtrl.$modelValue);
                    }

                    editor.model.document.on('hbUploadModalClicked', () => {
                        this.openUploadModal(editor);
                    });

                    editor.model.document.on( 'change:data', () => {
                        const currentData = this.editorReference.getData();
                        if (!currentData) {
                            return;
                        }
                        // Fix https://honeybook.atlassian.net/browse/HON-18070
                        // deleting editor that ended with figure tag or had no text caused ck to crash
                        // fix by adding en empty <p> at the end of the editor - this reolves the issue (and same happens
                        // in one composer actually
                        if (!/<\/p>$/i.test(currentData)) {
                            this.editorReference.setData(`${currentData}<p></p>`);
                        }
                    });


                    editor.model.document.on('hbCalendlyButtonClicked', () => {
                        const content = '<p>' + this.UIUtils.sanitize(this.calendlyUrl) + '</p>';
                        const viewFragment = editor.data.processor.toView(content);
                        const modelFragment = editor.data.toModel(viewFragment);
                        editor.model.insertContent(modelFragment);

                    });

                    $scope.$on('$destroy', () => {
                        editor.destroy();
                    });

                })
                .catch(error => {
                    console.error(error);
                })
        }

        openUploadModal(editor) {

            const options = {
                showCropTab: true,
                showGalleryTab: true,
                showUploadTab: true,
                defaultTab: 'Upload',
                showPreviewWhenCroppingDisplay: false,
                model: '',
                selectedImage: false,
                skipSavingToServer: false
            };

            Object.assign(options, this.uploadModalOptions);

            this.ImageService.chooseImage(options).then(file => {

                let transformationConfigArray = [{
                    width: file.position_w,
                    height: file.position_h,
                    x: file.position_x,
                    y: file.position_y,
                    crop: 'crop'
                }];

                this.cloudinaryTransformationArray = this.cloudinaryTransformationArray || [];

                transformationConfigArray = [...transformationConfigArray, ...this.cloudinaryTransformationArray];

                if (file.url) {
                    const logoElement = this.$.cloudinary.image(file.cloudinary_public_id, {
                        cloud_name: file.cloudinary_sub_account,
                        transformation: transformationConfigArray
                    });

                    const imgUrl = logoElement[0].src;
                    editor.execute('imageInsert', {source: imgUrl});
                }
            });
        }

    }

    /***
     *
     * @type {{controller: ClassicCKEditorController, bindings: {ngModel: string, calendlyUrl: string, uploadModalOptions: string, editorReference: string, cloudinaryTransformationArray: string, saveFunction: string}, name: string, require: {ngModelCtrl: string}}}
     *
     * Classic CK Editor (CK5)
     *
     * editorReference: Reference to the editor. so we can reach it on the parent controller
     * saveFunction: The function that is fired upon autosaving
     * ngModel: the data to be shown inside the editor
     * calendlyUrl: We use that to add the calendly button to the toolbar and allow users to its value into the editor
     * uploadModalOptions: Add the extra options parameters that only relevant to you
     * cloudinaryTransformationArray: Add the extra cloudinary transformations objects that relevant to you
     *
     */


    Components.ClassicCKEditor = {
        bindings: {
            editorReference: '=',
            saveFunction: '&',
            ngModel: "<",
            calendlyUrl: '<',
            uploadModalOptions: '<',
            cloudinaryTransformationArray: '<'
        },
        controller: ClassicCKEditorController,
        name: 'hbClassicCKEditor',
        require: {ngModelCtrl: "ngModel"}

    };

}());
