/**
 * Created by dvircohen on 28/11/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function PVLCreationGuidanceControllerCtor($scope, $injector, $modalInstance, $q, _, $translate, moment,
                                               AnalyticsService, UsersManager, UserService, ContactsManager, ModelFactory, ModalService, CompanyService, WorkspacesManager, pvl, $window, $timeout, DatadogRUMService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PVLCreationGuidanceController';
        AnalyticsService.trackPageView(this, 'PVLCreationGuidanceView');

        this.UsersManager = UsersManager;
        this.ContactsManager = ContactsManager;
        this.CompanyService = CompanyService;
        this.modalInstance = $modalInstance;
        this.UserService = UserService;
        this.ModalService = ModalService;
        this.$q = $q;
        this.$translate = $translate;
        this._ = _;
        this.moment = moment;
        this.pvl = pvl;
        this.$timeout = $timeout;
        this.DatadogRUMService = DatadogRUMService;
        this.loadingVendors = true;
        this.vendorsList = ModelFactory.newContactsCollection();
        this.vendorsListViewModel = [];
        this.selectedUsersHash = [];
        this.page = 1;
        this.requestedVendorTypesString = "";
        this.hasPvl = false;

        this.requestedTimeAgo = null;
        this.workspace = WorkspacesManager.getWorkspace(this.pvl.workspace_id, true, false);
        this.requestingClient = _.find(this.workspace.members, function(member) { return member._id === pvl.requested_by_user_id; });

        this.register(this.vendorsList, 'success', function gotContacts(successType, respStatus, respIsComplete, totals) {
            this.hasMoreContacts = !respIsComplete;
            this.totalVendors = totals;
            this.gotDataFromServer = true;
            this.loadingVendors = false;
        }.bind(this));

        this.initVendorsList(pvl);
        this.vendorsSelectedNumber = 0;

        angular.element($window).on('resize.PVLCreationGuidanceView', function() {
            var minHeight = 100;
            var modalHeight = angular.element('.js-pvl-modal').height();
            var modalHeaderHeight = angular.element('.js-pvl-modal__header').height();
            var modalFooterHeight = angular.element('.js-pvl-modal__footer').height();

            var heightContainer = modalHeight - modalHeaderHeight - modalFooterHeight;
            if (heightContainer < minHeight) {
                heightContainer = minHeight;
            }

            var vendorContainer = angular.element('.client-container-js');
            $scope.$apply(function() {
                vendorContainer.css({'height': heightContainer + 'px'});
            });
        });

        $scope.$on('$destroy', function onDestroy(){
            angular.element($window).off('resize.PVLCreationGuidanceView');
        });
    }

    Controllers.PVLCreationGuidanceController = Class(Controllers.BaseController, {
        constructor: PVLCreationGuidanceControllerCtor,

        showPreview: function showPreview() {

            var ids = this._.map(this.selectedUsersHash, function(v) { return v.user._id; });
            var previewList = this._.filter(this.vendorsList, function(vendor) {
                return ids.indexOf(vendor.user._id) !== -1;
            }.bind(this));

            this.ModalService.openEditPreferredVendorsModal(previewList).then(function(resp) {
                this.modalInstance.close(resp);
            }.bind(this));
        },

        onAddVendor: function onAddVendor() {
            this.UserService.newAddPreferredVendorContact('edit_preferred_vendors_list').then(function (resp) {
                var vendorAlreadyInList = this._.find(this.vendorsListViewModel, function (vendor) {
                    return vendor.user._id === resp.user._id;
                });
                var clonedVendor;
                if (!vendorAlreadyInList) {
                    this.vendorsList.push(resp);
                     clonedVendor = this._addVendorToViewModel(resp);
                    this.toggleVendorSelection(clonedVendor);
                } else {
                    vendorAlreadyInList.user.isSelected = false;
                    this.toggleVendorSelection(vendorAlreadyInList);
                }

            }.bind(this))
            .catch(function(error) {
                this.DatadogRUMService.addError(error, { 'pvl': 1 });
            }.bind(this));
        },

        mapRequestedVendorsByType: function mapRequestedVendorsByType() {
            this.requestedVendorsByTypeCount = {};
            this.vendorsListViewModel.forEach(function(vendor) {
                var vendorType = vendor.user.company.company_type;
                if (this._.contains(this.pvl.requested_vendor_types, vendorType)) {
                    this.requestedVendorsByTypeCount[vendorType] = this.createNewRequestedCountRecord(true);
                }
            }.bind(this));
        },

        createNewRequestedCountRecord: function createNewRequestedCountRecord(originallyRequested) {
           return  {
               count: 0,
               originallyRequests: originallyRequested
           };
        },

        setVendorContHeight: function setVendorContHeight() {
            var minHeight = 100;
            var modalHeight = angular.element('.js-pvl-modal').height();
            var modalHeaderHeight = angular.element('.js-pvl-modal__header').height();
            var modalFooterHeight = angular.element('.js-pvl-modal__footer').height();

            var heightContainer = modalHeight - modalHeaderHeight - modalFooterHeight;
            if (heightContainer < minHeight) {
                heightContainer = minHeight;
            }

            var vendorContainer = angular.element('.client-container-js');

            if (vendorContainer.length) {
                this.$timeout(function() {
                    var heightAttr = vendorContainer.css({'height': heightContainer + 'px'});
                });
            }
        },

        toggleVendorSelection: function toggleVendorSelection(vendor) {
            vendor.user.isSelected = !vendor.user.isSelected;

            if (vendor.user.isSelected) {
                this.selectedUsersHash.push(vendor);
            }
            else {
                var index = this._.findIndex(this.selectedUsersHash, function(v) { return v.user._id === vendor.user._id; });
                this.selectedUsersHash.splice(index, 1);
            }

            this.updateCountersMap(vendor);
        },

        updateCountersMap: function updateCountersMap(vendor) {
            var vendorType = vendor.user.company.company_type;
            var vendorTypeKey = "other";
            if (vendorType) {
                vendorTypeKey = vendorType.replace(/_/g, " ");
            }

            this.selectedUsersAvatars = this.selectedUsersHash;

            if(!this.requestedVendorsByTypeCount[vendorTypeKey]){
                this.requestedVendorsByTypeCount[vendorTypeKey] = this.createNewRequestedCountRecord(false); //mark that this is not originally requested
            }

            if (vendor.user.isSelected) {
                this.requestedVendorsByTypeCount[vendorTypeKey].count++;
            } else {
                this.requestedVendorsByTypeCount[vendorTypeKey].count--;
            }

            if(!this.requestedVendorsByTypeCount[vendorTypeKey].originallyRequests && this.requestedVendorsByTypeCount[vendorTypeKey].count === 0){
                //if the vendor is pushing the pvl there is no pvl already in place we dont want to show unselected as 0
                delete this.requestedVendorsByTypeCount[vendorTypeKey];
            }

            this.vendorsSelectedNumber = this.selectedUsersHash.length;
        },

        prepareRequestedHeaderStrings: function prepareRequestedVendorTypesHeaderString() {
            var translatedCompanyTypes = [];
            var translationMap = this.CompanyService.getCompanyTypesTranslationMap();

            if (this.pvl && this.pvl.requested_vendor_types) {
                 this.pvl.requested_vendor_types.forEach(function (companyType) {
                     translatedCompanyTypes.push(this.$translate.instant(translationMap[companyType]));
                 }.bind(this));

                this.requestedVendorTypesString = translatedCompanyTypes.toString().replace(/,/g, ', ');

                this.requestedTimeAgo = this.moment(this.pvl.requested_at).fromNow();
            }
        },

        initVendorsList: function initVendorsList() {

            var vendorsPromise = this.ContactsManager.getPeople(this.vendorsList, this.page, {vendor_or_client: 'vendor', is_preferred: true}, 200);
            var lastPvlPromise;
            var bypassClientRequest = false;

            if (this.pvl.preferred_vendor_ids && this.pvl.preferred_vendor_ids.length > 0) {
                lastPvlPromise = this.$q.when({
                    data: {
                        preferred_vendor_ids: this.pvl.preferred_vendor_ids
                    }
                });
                bypassClientRequest = true;
            }
            else {
                lastPvlPromise = this.UsersManager.getLastSentPvl();
            }

            this.$q.all([vendorsPromise, lastPvlPromise])
                .then(function(responses) {
                    this.last_sent_pvl = responses[1].data.preferred_vendor_ids;
                    this.hasPvl = !this._.isEmpty(this.pvl._id);
                    this._createViewModel();
                    this.mapRequestedVendorsByType();
                    this.markPreferredVendors(bypassClientRequest);
                    this.prepareRequestedHeaderStrings();
                    this.setVendorContHeight();
                }.bind(this))
                .catch(function(error) {
                    this.DatadogRUMService.addError(error, { 'pvl': 1 });
                }.bind(this))
                .finally(function final() {
                    this.loadingVendors = false;
                }.bind(this));
        },

        markPreferredVendors: function markPreferredVendors(bypassClientRequest) {
            this.vendorsListViewModel.forEach(function(vendor) {
                var vendorType = vendor.user.company.company_type;
                var isPreviouslySelected = this._.contains(this.last_sent_pvl, vendor.user._id);
                var isTypeRequestedByClient = this._.contains(this.pvl.requested_vendor_types, vendorType);

                if (isPreviouslySelected && (bypassClientRequest || isTypeRequestedByClient)) {
                    this.toggleVendorSelection(vendor);
                }
            }.bind(this));
        },

        getCityAndStateString: function getCityAndStateString(vendor) {
            if (vendor.user.company.city && vendor.user.company.state) {
                return vendor.user.company.city + ', ' + vendor.user.company.state;
            } else if (vendor.user.company.city && !vendor.user.company.state) {
                return vendor.user.company.city;
            } else if (!vendor.user.company.city && vendor.user.company.state) {
               return vendor.user.company.state;
            } else {
                return "";
            }
        },

        dismiss: function dismiss() {
            this.modalInstance.close();
        },

        _createViewModel: function _createViewModel() {
            this.vendorsList.forEach(function(vendor) {
                this._addVendorToViewModel(vendor);
            }.bind(this));
        },

        _addVendorToViewModel: function _addVendorToViewModel(vendor) {
            var vendorViewModel = vendor.dataOnly();
            vendorViewModel.user.isSelected = false;

            this.vendorsListViewModel.push(vendorViewModel);
            return vendorViewModel;
        }
    });
}());

