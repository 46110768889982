/**
 * Created by dvircohen on 06/12/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function TimezoneServiceCtor(moment, jstz) {
        this.moment = moment;
        this.jstz = jstz;

        this.timezones = ['PDT/PST', 'MDT/MST', 'MST', 'CDT/CST', 'CST', 'EDT/EST', 'HST', 'AKDT/AKST', 'ADT/AST', 'AST', 'NST/NDT'];
        var serverTimeZones = ['PDT', 'MDT', 'MST', 'CDT', 'CST', 'EDT', 'HST', 'AKDT', 'ADT', 'AST', 'NST'];

        this.serverToClientMap = {};
        this.clientToServerMap = {};

        for (var i = 0; i < this.timezones.length; i++) {
            var clientTimezone = this.timezones[i];
            var serverTimezone = serverTimeZones[i];
            this.serverToClientMap[serverTimezone] = clientTimezone;
            this.clientToServerMap[clientTimezone] = serverTimezone;
        }

        this.hbZoneToMomentZoneNameMap = {
            'PDT': 'America/Los_Angeles',
            'MDT': 'America/Denver',
            'MST': 'America/Phoenix',
            'CST': 'America/Regina',
            'EDT': 'America/New_York',
            'CDT': 'America/Chicago',
            'HST': 'Pacific/Honolulu',
            'AKDT': 'America/Anchorage',
            'AST': 'America/Puerto_Rico',
            'ADT': 'America/Halifax',
            'NST': 'America/St_Johns',
            'IDT': 'Asia/Jerusalem',
        };

        this.hbZoneNameMapToTimeZone = {
            'America/Los_Angeles': 'PDT/PST',
            'America/Denver': 'MDT/MST',
            'America/Phoenix': 'MST',
            'America/New_York': 'EDT/EST',
            'America/Chicago': 'CDT/CST',
            'America/Regina': 'CST',
            'Pacific/Honolulu': 'HST',
            'America/Anchorage': 'AKDT/AKST',
            'America/Puerto_Rico': 'AST',
            'America/Halifax': 'ADT/AST',
            'America/St_Johns': 'NST/NDT',
            'Asia/Jerusalem': 'IDT',
        };

        this.DEFAULT_TIMEZONE = this.timezones[0];
        this.currentTimezone = this.hbZoneNameMapToTimeZone[Intl.DateTimeFormat().resolvedOptions().timeZone];
    }

    Services.TimezoneService = Class({

        constructor: TimezoneServiceCtor,

        getTimezones: function getTimezones() {
            return angular.copy(this.timezones);
        },

        getMomentTimezone: function getMomentTimezone(timezone) {
            return this.hbZoneToMomentZoneNameMap[timezone || this.clientToServerMap[this.DEFAULT_TIMEZONE]];
        },

        translateFromServer: function translateFromServer(serverTimezone) {
            return this.serverToClientMap[serverTimezone];
        },

        translateToServer: function translateToServer(clientTimezone) {
            return this.clientToServerMap[clientTimezone];
        },

        getTimezoneForCompany: function getTimezoneForCompany(company) {
          if (company.default_timezone !== null) {
              return this.translateFromServer(company.default_timezone);
          }

          return this.DEFAULT_TIMEZONE;
        },

        getDefaultTimezoneIndex: function getDefaultTimezoneIndex() {
            var defaultZone = this.moment().tz(this.jstz.determine().name()).zoneAbbr();

            var index = 0;
            if (defaultZone) {
                for (var i = 0; i < this.timeZones.length; i++) {
                    if (this.timeZones[i].toLowerCase().indexOf(defaultZone.toLowerCase()) > -1) {
                        index = i;
                    }
                }
            }

            return index;
        }
    });
}());
