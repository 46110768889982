(function () {
    "use strict";

    class FormComponentSettingsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $sce, AnalyticsService, $translate, Enums, ModalService, CompaniesManager, _,
                    CompanyService, QuestionService, QuestionsStateService, ModelFactory, PopupMessageService,
                    ContactFormService) {
            super($scope, $injector);
            this.__objectType = 'FormComponentSettingsController';

            this.$sce = $sce;
            this.$scope = $scope;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.ModalService = ModalService;
            this.CompaniesManager = CompaniesManager;
            this.CompanyService = CompanyService;
            this._ = _;
            this.QuestionService = QuestionService;
            this.QuestionsStateService = QuestionsStateService;
            this.ModelFactory = ModelFactory;
            this.PopupMessageService = PopupMessageService;
            this.ContactFormService = ContactFormService;
        }

        $onInit() {
            // holds all the settings
            this.settings = {};

            // holds the displayed options
            this.displayedOptions = {};

            this.company = this.CompaniesManager.getCurrCompany();

            this.linkedToTitle = this.$translate.instant('DYNAMIC_CONTENT.FORM_COMPONENT._LINKED_TO_TITLE_');
            this.disabledItemText = this.$translate.instant('DYNAMIC_CONTENT.FORM_COMPONENT._DISABLED_ITEM_TEXT_');

            // question types
            let _questionTypes = [
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._FULL_NAME_',
                    key: this.Enums.suggestedQuestions.full_name,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/full-name.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._EMAIL_',
                    key: this.Enums.suggestedQuestions.email,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/email.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PHONE_NUMBER_',
                    key: this.Enums.suggestedQuestions.phone_number,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230611/app/modules/core/features/question-type-icons/phone.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._ABOUT_',
                    key: this.Enums.suggestedQuestions.about,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230608/app/modules/core/features/question-type-icons/message.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LEAD_SOURCE_',
                    key: this.Enums.suggestedQuestions.lead_source,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/leadsource.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_TYPE_',
                    key: this.Enums.suggestedQuestions.project_type,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230612/app/modules/core/features/question-type-icons/project-type.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LOCATION_',
                    key: this.Enums.suggestedQuestions.project_location,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230608/app/modules/core/features/question-type-icons/location.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._BUDGET_',
                    key: this.Enums.suggestedQuestions.project_budget,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/budget.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._GUESTS_',
                    key: this.Enums.suggestedQuestions.guests,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1558861200/app/modules/core/features/question-type-icons/guests.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_DATE_',
                    key: this.Enums.suggestedQuestions.project_date,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230612/app/modules/core/features/question-type-icons/project-date.svg',
                    categoryName: this.Enums.questionsCategories.suggested
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._TWO_FIELDS_',
                    key: this.Enums.customQuestions.two_fields,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/2-short-text.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._SHORT_TEXT_',
                    key: this.Enums.customQuestions.short_text,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230613/app/modules/core/features/question-type-icons/short-text.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._LONG_TEXT_',
                    key: this.Enums.customQuestions.long_text,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230611/app/modules/core/features/question-type-icons/long-text.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._DROPDOWN_',
                    key: this.Enums.customQuestions.drop_down,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/dropdown.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._SINGLE_CHOICE_',
                    key: this.Enums.customQuestions.single_choice,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230613/app/modules/core/features/question-type-icons/single-choice.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._MULTIPLE_CHOICE_',
                    key: this.Enums.customQuestions.multiple_choice,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230608/app/modules/core/features/question-type-icons/multi-choice.svg',
                    categoryName: this.Enums.questionsCategories.custom
                },
                {
                    textTranslation: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._DATE_PICKER_',
                    key: this.Enums.customQuestions.date_picker,
                    icon: '//res.cloudinary.com/honeybook/image/upload/v1557230606/app/modules/core/features/question-type-icons/date.svg',
                    categoryName: this.Enums.questionsCategories.custom
                }
            ];

            this.LEFT = 0;
            this.RIGHT = 1;
            this.currQuestionIndex = this.LEFT;

            this.questionTypes = [];

            // remove not allowed questions
            let availableQuestions = this.QuestionService.getAvailableQuestionTypes(this.component.getRootModelType());

            // go over questions and filter required ones
            _questionTypes.forEach((questionType) => {
                if (availableQuestions.indexOf(questionType.key) > -1 && this._shouldAddQuestion(questionType.key)) {
                    this.questionTypes.push(questionType);
                }
            });

            this.twoFieldsQuestion = this.questionTypes.find(q => q.key === this.Enums.customQuestions.two_fields);
            this.subQuestionTypes = this.questionTypes.filter(q => q.key !== this.Enums.customQuestions.two_fields).map(obj => Object.assign({}, obj));

            this.QuestionsStateService.on('QuestionsKeysChanged', this._markDisabledQuestionTypes.bind(this));

            this._onChanges();
        }

        $onChanges(changes) {
            if (changes.isVisible && changes.isVisible.currentValue === true && changes.isVisible.previousValue !== true) {
                this._onChanges();
            }
        }

        _onChanges() {

            this.isTwoFields = (this.component.form_type === this.Enums.customQuestions.two_fields);
            if (!this.isTwoFields) {
                this.currQuestionIndex = this.LEFT;
            }
            this.question = this.component.questions[this.currQuestionIndex];

            // init settings values
            this._initSettings();

            // update connected field display
            this._setConnectedField();

            // set displayed options
            this._updateDisplayedOptions();

            // update question types list
            this._markDisabledQuestionTypes();

            // set the title
            this._setTitle();
        }

        _initSettings() {

            let selectedQuestionType = this.questionTypes.find((typeObj) => {
                if (this.question.isSmartQuestion()) {
                    return typeObj.key === this.question.connected_property;
                } else {
                    return typeObj.key === this.question.question_type;
                }
            });
            let selectedMainType = this.isTwoFields ? this.twoFieldsQuestion : selectedQuestionType;
            let mainQuestionDeletable = this._.all(this.component.questions, q => q.isDeletable());

            // init settings values
            this.settings = {
                isRequired: this.question.is_required,

                selectedMainQuestionType: selectedMainType,
                mainQuestionDeletable: mainQuestionDeletable,

                selectedSubQuestionType: selectedQuestionType,
                subQuestionDeletable: this.question.isDeletable(),
            };
        }

        _markDisabledQuestionTypes() {

            // update
            this.questionTypes.forEach(function(questionType) {
                if (this.isTwoFields && this._.any(this.component.questions, q => q.isQuestionTypeOf(questionType.key))) {
                    questionType.disabled = false;
                } else if (questionType.key === this.Enums.suggestedQuestions.full_name || questionType.key === this.Enums.suggestedQuestions.email) {
                    questionType.disabled = false;
                } else {
                    questionType.disabled = this.QuestionsStateService.hasQuestion(questionType.key);
                }
            }.bind(this));

            this.subQuestionTypes.forEach(function(questionType) {
                if (questionType.key === this.Enums.suggestedQuestions.full_name || questionType.key === this.Enums.suggestedQuestions.email) {
                    questionType.disabled = false;
                } else {
                    questionType.disabled = this.QuestionsStateService.hasQuestion(questionType.key);
                }
            }.bind(this));
        }

        _setConnectedField() {
            let connectedKeyDisplayName = '';
            if (this.question.isSmartQuestion()) {
                const currQuestionType = this.questionTypes.find((questionType) => questionType.key === this.question.connected_property);
                if (currQuestionType) {
                    connectedKeyDisplayName = this.$translate.instant(currQuestionType.textTranslation);
                }
            }

            this.connectedKeyDisplayName = connectedKeyDisplayName;
        }

        _updateDisplayedOptions() {

            this.displayedOptions = {
                questionType: true,
                isRequired: true,
            };

            if(this.question.isSmartQuestion()) {
                this.displayedOptions.connectedInfoMessage = this._shouldDisplayConnectedLabel();

                if(this.question.connected_property === this.Enums.suggestedQuestions.lead_source ||
                    this.question.connected_property === this.Enums.suggestedQuestions.project_type) {
                    this.displayedOptions.connectedList = true;
                }

            }
        }

        _shouldAddQuestion(key) {
            const isBudget = key === this.Enums.suggestedQuestions.project_budget;
            const isGuests = key === this.Enums.suggestedQuestions.guests;

            if (!isBudget && !isGuests) {
                return true;
            }

            return (isBudget && (this.QuestionsStateService.hasQuestion(key) || this.company.shouldShowProjectField('budget'))) ||
                   (isGuests && (this.QuestionsStateService.hasQuestion(key) || this.company.shouldShowProjectField('guests')));
        }

        _shouldDisplayConnectedLabel() {
            const isBudget = this.question.isQuestionTypeOf(this.Enums.suggestedQuestions.project_budget);
            const isGuests = this.question.isQuestionTypeOf(this.Enums.suggestedQuestions.guests);

            if (!isBudget && !isGuests) {
                return true;
            }

            return (isBudget && this.company.shouldShowProjectField('budget')) || (isGuests && this.company.shouldShowProjectField('guests'));
        }

        _setTitle() {
            this.title = (this.connectedKeyDisplayName ? this.connectedKeyDisplayName : "Question") + " settings";

            this.changeQuestionLabel = this.currQuestionIndex === this.LEFT ? 'DYNAMIC_CONTENT.QUESTIONS.SETTINGS._CHANGE_LEFT_FIELD_' :
                'DYNAMIC_CONTENT.QUESTIONS.SETTINGS._CHANGE_RIGHT_FIELD_';
        }

        selectTwoFieldsTab(index) {
            this.currQuestionIndex = index;
            this.AnalyticsService.trackClick(this.$scope, 'two fields question tab', {tab: index === this.LEFT ? 'left' : 'right'});
            this._onChanges();
        }

        onTypeHover(type, hoverIn) {
            if (hoverIn && type.key !== this.settings.selectedMainQuestionType.key) {
                let previewQuestions = this.QuestionService.generatePreviewQuestion(type, this.component.questions[0]);
                if (previewQuestions) {
                    this.onPreview({questions: Array.isArray(previewQuestions) ? previewQuestions : [previewQuestions]});
                } else {
                    this.onPreview({questions: null});
                }
            } else {
                this.onPreview({questions: null});
            }
        }

        ///////////////////////
        // Edit lists
        //////////////////////
        onEditListClick() {
            return this.QuestionService.editQuestionConnectedList(this.question, this.company, "contact_form_question_settings");
        }

        ///////////////////////
        // Update
        //////////////////////
        validateQuestionTypeChange(newType, isMain) {
            if (this.component.getRootModelType() === 'contact_form') {
                return this.ContactFormService.validateCanChangeQuestionType(this.component.getRootModel(), newType, isMain);
            } else {
                return Promise.resolve();
            }
        }

        onQuestionTypeChange(newType, isMain) {
            var isSmartQuestion = newType.categoryName === this.Enums.questionsCategories.suggested;

            if (isMain && this.isTwoFields) {
                if (newType.key === this.Enums.customQuestions.two_fields) {
                    return;
                }

                if (!this.component.questions[0].isQuestionTypeOf(newType.key) && !this.component.questions[0].isDeletable()) {
                    return; //can't change the first question to a different type if it's non-deletable
                }

                if (!this.component.questions[1].isDeletable()) {
                    return; //can't turn two fields question into single question if the second question is mandatory
                }
            } else {
                // ignore if the same as current type
                if(this.question.isQuestionTypeOf(newType.key)) {
                    return;
                }

                // block if this is not deletable question
                if(!this.question.isDeletable()) {
                    return;
                }
            }

            // change type
            let specificQuestionToUpdate = isMain ? null : this.question;
            this.QuestionService.changeQuestionType(this.component, specificQuestionToUpdate, newType.key, isSmartQuestion).then(function() {
                this._onChanges();
            }.bind(this));

            var analyticsArgs = {
                oldvalue: isMain ? this.settings.selectedMainQuestionType.key : this.settings.selectedSubQuestionType.key,
                newvalue: newType.key,
                field_type: isMain ? 'primary' : 'secondary'
            };

            // analytics
            this.AnalyticsService.trackClick(this.$scope, this.AnalyticsService.analytics_events.question_type_changed, analyticsArgs);
        }

        onRequiredToggle() {
            this.question.is_required = this.settings.isRequired;
            this.question.updateQuestion(['is_required']);
        }

        _showConnectedFieldWarning(newType, isMain) {
            let questionTypeText = this.$translate.instant(newType.textTranslation);
            let warningText = this.$translate.instant('DYNAMIC_CONTENT.FORM_COMPONENT._CONNECTED_QUESTION_REPLACE_WARNING_', {question_type: questionTypeText});
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                warningText, () => {
                    this._questionTypeChangeInner(newType, isMain);
                });
        }

    }

    Components.FormComponentSettings = {
        bindings: {
            component: '<',
            onPreview: '&',
            currQuestionIndex: '=',
            isVisible: '<',
        },
        controller: FormComponentSettingsController,
        controllerAs: 'formComponentSettingsVm',
        name: 'hbFormComponentSettings',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/form/form_settings/form_component_settings.html',
    };

}());
