// @ngInject
Filters.HbDateFilter = function HbDateFilter($filter) {
    return function (dates, showDay, fallback) {

        showDay = !!showDay;
        var dayPrefix = showDay ? 'EEE, ' : '';
        var partialDate = dayPrefix + 'MMM dd';
        var fullDate = partialDate + ', y';

        if (!dates) {
            return ((fallback === undefined) ? 'TBD' : fallback);
        }

        if (dates.constructor !== Array) {
            return $filter('date')(dates, fullDate);
        }

        if (!dates[0]) {
            return ((fallback === undefined) ? 'TBD' : fallback);
        }

        if (dates.length !== 2 || !dates[1]) {
            return $filter('date')(dates[0], fullDate);
        }

        if (dates[0] === dates[1]) {
            return $filter('date')(dates[0], fullDate);
        }

        var value = $filter('date')(dates[0], partialDate) + " - " + $filter('date')(dates[1], fullDate);
        return value;
    };
};
