Directives.ProposalDirectiveNew = function ProposalDirectiveNew() {

    // @ngInject
    function ProposalDirectiveNewControllerFunc($scope, $q, $injector, _, $, AnalyticsService,
                                                UiPersistenceService, DeviceService, CompaniesManager, $filter, AutosizeTextareaUpdater,
                                                WorkspaceFilesManager, ModalService, OnboardingService, $exceptionHandler, PopupMessageService, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProposalDirectiveNewController';

        this.$scope = $scope;
        this.$q = $q;
        this._ = _;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.ModalService = ModalService;
        this.UiPersistenceService = UiPersistenceService;
        this.OnboardingService = OnboardingService;
        this.DeviceService = DeviceService;
        this.$ = $;
        this.$filter = $filter;
        this.$exceptionHandler = $exceptionHandler;
        this.PopupMessageService = PopupMessageService;
        this.textareaUpdater = AutosizeTextareaUpdater;
        this.$translate = $translate;

        this.stateChangePending = false;
        this.device = this.DeviceService.device;

        // list containing services and packages
        // we need a combined list to have a
        // proper order and reorder
        this.genericListViewModel = [];
        this.numberOfItems = this.proposal.package_services.length;
        this.firstLoad = true;

        // init sortable configuration
        this.proposalSortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.js-proposal-drag-handler', // class used only for indicating the drag handler
            cancel: '.package-service-wrapper .js-proposal-drag-handler', // don't huse as handle the inner services drag handle
            connectWith: '.hb-package__services', // inner services list container inside each package
            disabled: false,
            stop: this.onDragStop.bind(this),
            update: this.onDragUpdate.bind(this),
        };

        // prepare view model
        this.buildGenericListViewModel();
        this._setMobileItemsCount();

        // rebuild view models on every model update
        var modelToRegister = (this.fileModel && (this.fileModel.isModelOf('WorkspaceFile') || this.fileModel.isModelOf('ContactForm'))) ? this.fileModel : this.companyModel;
        this.register(modelToRegister, 'success', function success() {

            // we need to wait for the digest cycle to finish,
            // so all child models will update
            this.$scope.$applyAsync(function () {
                this.buildGenericListViewModel();
                this.firstLoad = false;
            }.bind(this));

            this.buildGenericListViewModel();
            this._setMobileItemsCount();
        }.bind(this));

    }

    var ProposalDirectiveNewController = Class(Controllers.BaseController, {
        constructor: ProposalDirectiveNewControllerFunc,

        buildGenericListViewModel: function buildGenericListViewModel() {

            // reset array (we use .splice to keep the array ref, need to check if we really need it)
            this.genericListViewModel.splice(0);

            // Mobile counting
            this.numberOfPackages = 0;
            this.numberOfServices = 0;

            // add items to array
            if(this.proposal.vendor_packages) {
                this.proposal.vendor_packages.forEach(function (vendor_package) {
                    this.genericListViewModel.push(vendor_package);
                    this.numberOfPackages++;
                    this.numberOfServices += vendor_package.base_services.length;
                }.bind(this));
            }

            if (this.proposal.package_services) {
                this.proposal.package_services.forEach(function (service) {
                    this.genericListViewModel.push(service);
                }.bind(this));
                this.numberOfServices += this.proposal.package_services.length;
            }

            // all tax
            this.areAllItemsTaxed = !!this.proposal.all_taxed;

            // sort
            this.genericListViewModel = this._.sortBy(this.genericListViewModel, function(item){
                return item.order ? item.order : 0;
            });

            // focus on last item if we added a new one
            if (this.numberOfItems < this.proposal.package_services.length && !this.firstLoad) {
                this.focusIndex = this.genericListViewModel.length-1;
                this.numberOfItems = this.proposal.package_services.length;
            }
        },

        addCustomItem: function addCustomItem() {
            this.proposal.addCustomItem().then(function success() {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.service_added_to_proposal);
            }.bind(this)).catch(function error() {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.service_added_to_proposal_error);
            }.bind(this));
        },

        toggleAllTax: function toggleAllTax() {

            if(this.duringAllTaxableToggle) {
                return;
            }

            this.duringAllTaxableToggle = true;
            this.areAllItemsTaxed = !this.areAllItemsTaxed;
            this.proposal.toggleTaxToAllPackages(this.areAllItemsTaxed).then(
                function success() {
                    if (this.areAllItemsTaxed) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_add_tax_to_all);
                    }
                    else {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_remove_all_tax);
                    }
                }.bind(this),
                function error() {
                    if (this.areAllItemsTaxed) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_add_tax_to_all_error);
                    }
                    else {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_remove_all_tax_error);
                    }
                }.bind(this)
            ).finally(function final() {
                this.duringAllTaxableToggle = false;
            }.bind(this));
        },

        toggleAllSvc: function toggleAllSvc() {
            if(this.duringAllTSvcableToggle) {
                return;
            }

            this.duringAllTSvcableToggle = true;

            var allItemsWithSvc = this._.all(this.genericListViewModel, function(item) { return item.svcable; });
            this.proposal.toggleSvcToAllPackages(!allItemsWithSvc).finally(function final() {
                this.duringAllTSvcableToggle = false;
            }.bind(this));
        },

        isEditMode: function isEditMode() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        isMinimizedMode: function isMinimizedMode() {
            return this.isOwnerMode && !this.isPreviewMode && this.device.isMobileSize && !this.disableMinimizedMode;
        },

        //////////////////////////
        // D&D handlers
        //////////////////////////

        onDragStop: function onDragStop(event, ui) {
            try {
                // services D&D is disabled on package drag start and should be
                // re-enabled on drag stop (if the original conditions for enabling it exist)
                // The reason this is done with jquery sortable is because disabling using angular's sortable-ui
                // options is not applied when set during a drag event
                if (this.isEditableMode || !this.isPreviewMode) {
                    this.$('.hb-package__services').sortable("enable");
                }

                // check if dropped inside the proposal list
                var sortableModel = ui.item.sortable;
                var model = sortableModel.model;

                // if received, it means the item was dropped
                // outside of the current list
                if(sortableModel.received) {
                    return;
                }

                // check if item index was changed
                var isIndexChanged = !!(sortableModel.dropindex || sortableModel.dropindex === 0);

                // if it's dropped inside, we do simple reorder
                if(isIndexChanged) {
                    this.updateOrder();
                }

            } catch(e) {
                this.$exceptionHandler(e);
            }
       },

        onDragUpdate: function onDragUpdate(event, ui) {

            try {

                // make sure dropped item is a service before updating
                var sortableModel = ui.item.sortable;
                var model = sortableModel.model;
                var isService = (model && model.isModelOf("Service"));

                if(!isService || !sortableModel.received) {
                    return;
                }

                // reorder and move item
                this.updateOrderAndMoveService(model);

            } catch(e) {
                this.$exceptionHandler(e);
            }
        },

        updateOrder: function updateOrder() {

            // update order of items
            var order = [];
            this.genericListViewModel.forEach(function (item, index) {
                item.order = index;
                order.push({ _id:item._id, order:index });
            });

            //update the server with the new order
            this.proposal.updatePackagesAndServicesOrder(order)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_drag_package_ended, this.proposal.getAnalyticsArgs());
                }.bind(this))
                .catch(function error(err) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PROPOSAL._SERVICE_REORDER_ERROR_');
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.proposal_drag_package_error, this.proposal.getAnalyticsArgs());
                }.bind(this));
        },

        updateOrderAndMoveService: function updateOrderAndMoveService(service) {

            this.proposal.moveService(service._id, service.__package._id, null, this.genericListViewModel)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.proposal_drag_package_ended, this.proposal.getAnalyticsArgs());
                }.bind(this))
                .catch(function error(err) {

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'PROPOSAL._SERVICE_REORDER_ERROR_');
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.proposal_drag_package_error, this.proposal.getAnalyticsArgs());
                }.bind(this));

        },

        //////////////////////////
        // mWeb
        //////////////////////////

        onMobilePageBack: function onMobilePageBack() {
            this.showMobileItems = false;
        },

        onMobileSummaryPageBack: function onMobileSummaryPageBack() {
            this.showMobileSummary = false;
        },

        onAddItemMobile: function onAddItemMobile() {
            this.showAddItemMobile = false;
        },
        
        _setMobileItemsCount: function _setMobileItemsCount() {
            
            if (!this.device.isMobileSize) {
                return;
            }

            var hasServicesOrPackages = false;

            this.mobileItemsCountText = '';
            if (this.numberOfPackages) {
                this.mobileItemsCountText += this.$filter('pluralize')(this.numberOfPackages, 'package');
                if (this.numberOfServices) {
                    this.mobileItemsCountText += ', ';
                }

                hasServicesOrPackages = true;
            }
            
            if (this.numberOfServices) {
                this.mobileItemsCountText += this.$filter('pluralize')(this.numberOfServices, 'item');
                hasServicesOrPackages = true;
            }

            if (!hasServicesOrPackages) {
                this.mobileItemsCountText = this.$translate.instant('PROPOSAL.LABELS._NO_ITEMS_');
            }
        },

        onProposalItemsClick: function onProposalItemsClick() {
            this.showMobileItems = true;
            this.textareaUpdater();
        }

    });

    return {
        scope: {
            proposal: '=proposalModel',
            fileModel: '=fileModel', // might be null if in company templates
            companyModel: '=companyModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            alreadyGotFile: '=alreadyGotFile',
            disableMinimizedMode: '=',
            templateMode: '=?',
            isSelectable: '=?',
            showSummary: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/proposal_directive_new_template.html',
        controller: ProposalDirectiveNewController,
        controllerAs: 'proposalVm',
        bindToController: true,
        link: function proposalDirectiveLink(scope, element) {
            scope.scrollToTotalPrice = function scrollToTotalPrice() {
                var totalPriceElement = element.find('#totalPrice');
                return totalPriceElement.scrollParent().scrollTo(null, totalPriceElement.offset().top - 300, 300);
            };

        }
    };
};


//this.$(ui.item).removeClass("hb-proposal__item--drag");