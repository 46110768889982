(function () {
    "use strict";

    Controllers.SubdomainConfigModalController = class SubdomainConfigModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $window, $modalInstance, CompaniesManager, company, AnalyticsService, PopupMessageService) {

            super($scope, $injector);
            this.__objectType = 'SubdomainConfigModalController';
            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.company = company;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.domainValue = company.custom_domain ? company.custom_domain.domain :'';
            this.subdomainValue = company.custom_domain ? company.custom_domain.subdomain :'';

            this.verifyDomainIsValidLoading = false;
            this.verifySubdomainVarificationLoading = false;
            this.currStep = 1;
            this.isValidDomain = true;
            this.hasDomainWhitelistedCertificateAuthority = true;
            this.isDomainConnectSupported = false;
            this.isSubdomainValid = true;
            this.domainConnectUrl = true;
            this.finalStep = false;
            this.errorToPass = null;
            this.verifySubdomainErr = 'Invalid subdomain';
            this.$window = $window;
            this.isEasyConnect = false;
            this.popupTick = null;
        }

        verifyDomainIsValid() {
            this.domainValue = this.domainValue.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];

            this.isValidDomain = this.isValidDomainName(this.domainValue);
            if (!this.isValidDomain){
                return;
            }

            this.verifyDomainIsValidLoading = true;
            this.hasDomainWhitelistedCertificateAuthority = true;
            this.isValidDomain = true;
            this.CompaniesManager.verifyDomainIsValid(this.company, this.domainValue.toLocaleLowerCase())
                .then((resp) => {
                    if (resp.data) {
                        const {domain_exists, domain_whitelists_certificate_authority, is_domain_connect_supported} = resp.data;
                        this.isDomainConnectSupported = is_domain_connect_supported;
                        this.hasDomainWhitelistedCertificateAuthority = domain_whitelists_certificate_authority;
                        this.isValidDomain = domain_exists && this.hasDomainWhitelistedCertificateAuthority;

                        if (this.isValidDomain) {
                            this.changeStep(2);
                            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.custom_domain_verify, {result: 'success'});
                        } else {
                            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.custom_domain_verify, {result: 'failure', value: this.domainValue});
                            this.isValidDomain = false;
                        }
                    }

                    this.verifyDomainIsValidLoading = false;
                }).catch((err) => {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.custom_domain_verify, {result: 'failure', value: this.domainValue});
                    this.isValidDomain = false;
                    this.verifyDomainIsValidLoading = false;
                });
        }

        openConfirmModal(comingFromEasyConnect = false) {
            this.isValidDomain = true;
            this.isEasyConnect = comingFromEasyConnect;
            const fullURL = this.subdomainValue + '.' + this.domainValue;
            const confirmationMsgText = this.isEasyConnect ? 'Please confirm that you’ve successfully logged in to your domain host and approved the subdomain' :
                'Please confirm you have added a new CNAME record named "' +  fullURL  + '" on your domain host';

            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.none,
                confirmationMsgText,
                this.activateCustomDomain.bind(this));
        }

        activateCustomDomain() {
            if (this.isEasyConnect) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.confirm_easy_connect_domain_connection);
            } else {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.confirm_domain_connection);
            }

            this.CompaniesManager.verifyCustomDomainDnsConfigured(this.company, this.domainValue, this.subdomainValue)
                .then((resp) => {
                    if (resp.data) {
                        this.finalStep = true;
                    }
                })
                .catch(err=> {
                    this.errorToPass = err.error_message;
                });
        }

        openEasyConnectPopup() {
            var popup = this.$window.open(this.domainConnectUrl, '_blank', "width=1100,height=600");

            if (this.popupTick !== null) {
                clearInterval(this.popupTick);
            }
            
            this.popupTick = setInterval(function() {
                if (popup.closed) {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.easy_connect_popup_closed);
                    clearInterval(this.popupTick);
                }
            }.bind(this), 500);
        }

        verifyCustomDomainAvailable(){
            this.isSubdomainValid = this.isValidSubdomain(this.subdomainValue.toLocaleLowerCase());
            if (!this.isSubdomainValid){
                return;
            }

            this.verifySubdomainVarificationLoading = true;
            this.CompaniesManager.verifyCustomDomainAvailable(this.company, this.domainValue, this.subdomainValue)
                .then((resp) => {
                    if (resp.data) {
                        this.isSubdomainValid = resp.data.is_valid;
                        this.domainConnectUrl = resp.data.domain_connect_url;
                        if (this.isSubdomainValid) {
                            this.changeStep(3);
                            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.create_subdomain_verification, {result: 'success'});

                            // If this domain is eligible to Easy Connect - let's open the URL in a window
                            if (this.isDomainConnectSupported && this.domainConnectUrl && this.domainConnectUrl.length > 0) {
                                this.openEasyConnectPopup();
                            }
                        } else {
                            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.create_subdomain_verification, {result: 'failure', value: this.domainValue});
                            this.isSubdomainValid = false;
                        }
                    }

                    this.verifySubdomainVarificationLoading = false;
                }).catch((err) => {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.create_subdomain_verification, {result: 'failure', value: this.domainValue});
                this.isSubdomainValid = false;
                this.verifySubdomainVarificationLoading = false;
                this.verifySubdomainErr = err && err.data && err.data.error_message ? err.data.error_message : 'Invalid subdomain';
            });
        }

        isValidSubdomain(name){
            const validRegex = /^(?:[A-Za-z0-9][A-Za-z0-9\-]{0,61}[A-Za-z0-9]|[A-Za-z0-9])$/;
            return validRegex.test(name);
        }

        isValidDomainName(name){
            // const validRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
            const validRegex = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{1,64}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;

            return validRegex.test(name);
        }

        goBackToStep(step) {
            if (this.currStep > step) {
                this.changeStep(step);
            }
        }

        changeStep(step) {
            this.currStep = step;
        }

        close() {
            this.$modalInstance.close();
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }
    };
}());
