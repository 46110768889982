Directives.TimelineDirective = function TimelineDirective() {

    // @ngInject
    function TimelineDirectiveControllerFunc($injector, $scope, TimelineManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimelineDirectiveController';
        this.TimelineManager = TimelineManager;
    }

    var TimelineDirectiveController = Class(Controllers.BaseController, {
        constructor: TimelineDirectiveControllerFunc,

        allowEdit: function allowEdit() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        shouldShowItemTooltip: function shouldShowItemTooltip() {
            return !!this.workspaceFile && !!this.workspaceFile.timeline_container &&
                this.workspaceFile.timeline_container.timeline_sections.length === 1 &&
                this.workspaceFile.timeline_container.timeline_sections[0].timeline_items.length === 1;
        }
    });

    return {
        scope: {
            workspaceFile: '=workspaceFile',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode'
        },
        templateUrl: 'angular/app/modules/core/features/timeline/timeline_directive_template.html',
        controller: TimelineDirectiveController,
        controllerAs: 'timelineVm',
        bindToController: true
    };
};
