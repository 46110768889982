// @ngInject
Directives.FormattedInputDirective = function FormattedInputDirective($filter) {

    return {
        require: '?ngModel',

        link: function (scope, elem, attrs, ngModelCtrl) {

            if (!ngModelCtrl) {
                return;
            }

            var inputType = inputType || attrs.formattedInputType;
            var inputFormat = inputFormat || attrs.formattedInputFormat;
            var precision = precision || attrs.formattedInputPrecision;
            var currency = attrs.formattedInputCurrency;
            var allowNegative = Boolean(attrs.formattedInputAllowNegative);
            var negate = Boolean(attrs.formattedInputNegate);

            var parseNumber = function(input, acceptNegative) {
                var plainNumber = input.toString().replace(/\.(?=\d*\s*%?\s*$)/, '1101011');
                var isNegative = /^.?\-.*/.test(plainNumber); //allow one leading char. for example $ sign
                plainNumber = plainNumber.replace(/[\D]/g, '');
                plainNumber = plainNumber.replace('1101011', '.');
                plainNumber = isNegative && acceptNegative ? "-" + plainNumber : plainNumber;
                return Number(plainNumber);
            }

            // model to view
            ngModelCtrl.$formatters.unshift(function (a) {

                switch (inputType) {
                    case 'currency':

                        if (typeof ngModelCtrl.$modelValue === 'undefined' ||
                            ngModelCtrl.$modelValue === '' ||
                            ngModelCtrl.$modelValue === null) {

                            return '';

                        } else if (!ngModelCtrl.$modelValue) {
                            return $filter('hbcurrency')('0', currency, undefined, negate);
                        }
                        //we want to strip the number in the best imaginable way
                        var plainNumber = parseNumber(ngModelCtrl.$modelValue, allowNegative);
                        return $filter('hbcurrency')(plainNumber, currency, undefined, negate);

                    case 'decimal':
                    case 'number':
                        if (typeof ngModelCtrl.$modelValue === 'undefined' ||
                            ngModelCtrl.$modelValue === '' ||
                            ngModelCtrl.$modelValue === null) {

                            return '';

                        } else if (!ngModelCtrl.$modelValue) {
                            return '0';
                        }
                        var plainNumber = parseNumber(ngModelCtrl.$modelValue, allowNegative);
                        return $filter('number')(plainNumber);

                    case 'date':

                        var format = inputFormat || 'MMM dd, yyyy';
                        return $filter('date')(ngModelCtrl.$modelValue, format);

                    case 'percentage':

                        if (!ngModelCtrl.$modelValue) {
                            return '0';
                        }
                        //we want to strip the number in the best imaginable way
                        var plainNumber = parseNumber(ngModelCtrl.$modelValue, allowNegative);
                        return $filter('number')(plainNumber, 2) + '%';
                }

            });

            // view to model
            ngModelCtrl.$parsers.unshift(function (viewValue) {

                switch (inputType) {
                    case 'currency':

                        if (viewValue === 0 || viewValue === '0') {
                            return '0';

                        } else if (!viewValue) {
                            return '';
                        }
                        var plainNumber = parseNumber(viewValue, allowNegative);
                        if (precision) {
                            plainNumber = Number(plainNumber).toFixed(precision);
                        }
                        elem.val($filter('hbcurrency')(plainNumber, currency, undefined, negate));
                        return parseFloat(plainNumber, 10);
                    case 'decimal':

                        if (viewValue === 0 || viewValue === '0') {
                            return 0;

                        } else if (!viewValue) {
                            return '';
                        }
                        var plainNumber = parseNumber(viewValue, allowNegative);
                        if (precision) {
                            plainNumber = Number(plainNumber).toFixed(precision);
                        }
                        elem.val($filter('number')(plainNumber));
                        return plainNumber;

                    case 'number':

                        if (viewValue === 0 || viewValue === '0') {
                            return 0;

                        } else if (!viewValue) {
                            return '';
                        }
                        var plainNumber = parseNumber(viewValue, allowNegative);
                        if (precision) {
                            plainNumber = Number(plainNumber).toFixed(precision);
                        }
                        elem.val($filter('number')(plainNumber));
                        return parseInt(plainNumber, 10);

                    case 'percentage':

                        if (!viewValue) {
                            return '0';
                        }
                        var plainNumber = parseNumber(viewValue, allowNegative);
                        if (precision) {
                            plainNumber = Number(plainNumber).toFixed(precision);
                        }
                        //elem.val($filter('hbpercent')(plainNumber, 2));
                        return parseFloat(plainNumber, 10);

                    case 'date':

                        var format = inputFormat || 'MMM dd, yyyy';
                        var val = $filter('date')(viewValue, format);
                        return val;

                }
            });
        }
    }
};
