
(function () {
    'use strict';

    class InputGroupTitle extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'InputGroupTitle';
        }
    }

    Components.InputGroupTitle = {
        bindings: {
            iconClass: '@',
            label: '@'
        },
        controller: InputGroupTitle,
        name: 'hbInputGroupTitle',
        templateUrl: 'angular/app/modules/core/features/calendar/input_group_title/input_group_title.html'
    };

}());
