(function () {
    'use strict';

    // @ngInject
    Directives.ChangeAndBlur = function ChangeAndBlur($parse) {

        function ChangeAndBlurLink(scope, element, attrs, ctrl) {

            var oldVal = ctrl.$viewValue;
            var isChanged = false;

            ctrl.$viewChangeListeners.push(function() {
                isChanged = (oldVal !== ctrl.$viewValue);
            });

            element.on('blur', function(event) {
                oldVal = ctrl.$viewValue;
                if(isChanged) {
                    var fn = $parse(attrs.hbChangeAndBlur);
                    scope.$evalAsync(function() {
                        fn(scope, {$event: event});
                    });
                    isChanged = false;
                }
            });

        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: ChangeAndBlurLink
        };

    };


}());

