(function () {
    "use strict";

    let PROJECT_TYPES = {};
    const MINIMAL_REQUEST_TIMESPAN_IN_WEEKS = 25;
    const MINIMAL_GOOGLE_REQUEST_TIMESPAN_IN_WEEKS = 4;

    // @ngInject
    function CalendarControllerCtor($scope, $injector, moment, $document, $filter, $location, $window, _, $log, $q, $timeout, $translate, $state, $stateParams,
                                    ReportsManager, UsersManager, AnalyticsService, UserService, StatsigService, UIUtils,
                                    PopupMessageService, ModalService, EventService, UiPersistenceService, CompaniesManager, OnboardingService,
                                    $sce, SuggestionsService, Enums, CalendarService, CssVariablesService, TimezoneService, NavigationService, SchedulingService, WebsocketHelperService) {
        const self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CalendarController';

        AnalyticsService.trackPageView(this, 'Calendar View');
        this.AnalyticsService = AnalyticsService;

        this.moment = moment;
        this._ = _;
        this.$document = $document;
        this.$filter = $filter;
        this.$log = $log;
        this.$location = $location;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.$state = $state;
        this.$stateParams = $stateParams;

        this.UsersManager = UsersManager;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.TimezoneService = TimezoneService;
        this.ModalService = ModalService;
        this.EventService = EventService;
        this.OnboardingService = OnboardingService;
        this.UiPersistenceService = UiPersistenceService;
        this.$sce = $sce;
        this.$q = $q;
        this.SuggestionsService = SuggestionsService;
        this.Enums = Enums;
        this.CalendarService = CalendarService;
        this.ReportsManager = ReportsManager;
        this.CssVariablesService = CssVariablesService;
        this.CssVariablesService.init('calendar');
        this.NavigationService = NavigationService;
        this.SchedulingService = SchedulingService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.UserService = UserService;
        this.UIUtils = UIUtils;

        this.calendarElement = this.$document.find('#mainCalendarView');

        this.user = this.UsersManager.getCurrUser();
        this.calendarColors = this.user.calendar_colors;
        if(this.calendarColors && this.calendarColors.tentative_projects_color) {
            document.documentElement.style.setProperty('--tentative-event-color', this.calendarColors.tentative_projects_color);
            document.documentElement.style.setProperty('--tentative-event-color-light', this.UIUtils.hexToRgbAWithAlpha(this.calendarColors.tentative_projects_color, 0.6));
        }
        this.company = this.CompaniesManager.getCurrCompany();
        this.nylasIntegrationAllowed = this.user.isNylasCalendarIntegrationEnabled();

        this.all_events = [];
        this.allCalendarEventIds = {};
        this.activePromises = [];

        this.showPayments = false;
        this.showLeadEvents = false;
        this.showTeamEvents = true;
        this.showMeetingEvents = true;
        this.isTeamEventsCalendarOn = !!(
            (this.user.company) &&
            (this.user.company.company_settings) &&
            this.user.company.company_settings.share_team_members_calendar
        );

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.currentTimezone = this.TimezoneService.hbZoneNameMapToTimeZone[timezone];

        this.emailForm = {};
        this.calendarProvidersInfoLoading = true;

        this.showCalendarToggles = null;
        this.showSessionsToggles = null;
        this.settingButtonEnabled = false;
        this.showTeamMembersToggles = true;
        this.calendarProvider = null;

        this.userIsSubscribeToCalendly = false;
        this.showCalendlyLoader = false;
        this.getCalendlyPublicLink();

        this.sharedWithEmailsCollection = [];
        this.toggledTeamMembers = {};
        this.toggledSpaces = {};
        this.teamMembersIndices = [];

        this.notesDiscoverabilityRenderKey = 0;

        PROJECT_TYPES = this.CalendarService.CALENDAR_ITEM_TYPES;

        // this is a hash of full calendar event sources. it serves as the container for all the sources
        this.calendarSources = {};
        this.calendarSources[PROJECT_TYPES.BOOKED_PROJECT] = {mySourceId: PROJECT_TYPES.BOOKED_PROJECT, events: []};
        this.calendarSources[PROJECT_TYPES.LEAD_PROJECT] = {mySourceId: PROJECT_TYPES.LEAD_PROJECT, events: []};
        this.calendarSources[PROJECT_TYPES.PAYMENT] = {mySourceId: PROJECT_TYPES.PAYMENT, events: []};
        this.calendarSources[PROJECT_TYPES.CALENDAR_ITEM] = {mySourceId: PROJECT_TYPES.CALENDAR_ITEM, events: []};

        this.fetchTimeSpan = {
            resetTimeSpan: function resetTimeSpan() {
                this.startDate = null;
                this.endDate = null;
            },
            getSpanForRequest: function setSpan(requestedStartDate, requestedEndDate) {
                return self.getTimeSpanForRequest(this, requestedStartDate, requestedEndDate, MINIMAL_REQUEST_TIMESPAN_IN_WEEKS);
            },
            startDate: null,
            endDate: null
        };

        this.fetchGoogleEventsTimeSpan = {
            resetTimeSpan: function resetTimeSpan() {
                this.startDate = null;
                this.endDate = null;
            },
            getSpanForRequest: function setSpan(requestedStartDate, requestedEndDate) {
                return self.getTimeSpanForRequest(this, requestedStartDate, requestedEndDate, MINIMAL_GOOGLE_REQUEST_TIMESPAN_IN_WEEKS);
            },
            startDate: null,
            endDate: null
        };

        // this is the container for the acutually rendered calendars
        this.selectedCalendarSources = [];

        this.firstRender = true;

        this.lastTimeRange = {
            end: null,
            start: null
        };

        this.daySelectedElement = null;

        const calendarUiPeristancy = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.calendarView, {
            showPayments: true,
            showLeadEvents: true,
            showTeamEvents: true,
            showCalendarToggles: true,
            showSessionsToggles: true,
            showGoogleCalendarAnnouncementTooltip: true,
            calendarDefaultView: this.CalendarService.CALENDAR_MONTH_VIEW,
            toggledTeamMembers: {},
            toggledSpaces: {}
        });

        this.deserailizeUiState(calendarUiPeristancy);

        UserService.showGmailRevokedPopup(this.user);

        $scope.$on('handle-schedule-meeting', function(event, meeting){
            this.handleCreateMeeting(meeting);
        }.bind(this));

        $scope.$on('$destroy', function () {
            this.CssVariablesService.clearAllVarsByType('calendar');
        }.bind(this));

        if ($stateParams.nylasIntegrationCreated) {
            UsersManager.setNylasCalendarImporter()
                .then(function success(response) {
                    if (response.data && response.data.calendar_provider) {
                        this.nylasCalendarProvider = response.data.calendar_provider;
                    }

                    const modalOptions = {
                        isConnected: true,
                        commingFromNylasAuth: true
                    };

                    self.ModalService.openConnectNylasCalendarModal(modalOptions)
                        .then(self.handleUserSelectionOnConnectCalendarModal.bind(this))
                        .catch(this.clearCalendarProviderIfNeeded.bind(this));
                    this.$parent.$stateParams.nylasIntegrationCreated = false;
                }.bind(this))
                .catch(function fail(resp) {
                    if (resp && resp.data && resp.data.error_type === 'HBUserError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.IMPORTING._ERROR_IMPORTING_');
                    }

                }.bind(this));
        }
        else if ($stateParams.googleIntegrationCreated) {
            // this means that the we are comming back from the google auth page we need to send this authentication code to save this
            // on the user model
            UsersManager.setCalendarImporter()
                .then(function success(response) {
                    this.calendarProvider = response.data.calendar_provider;

                    const modalOptions = {
                        isConnected: true,
                        commingFromGoogleAuth: true
                    };

                    self.ModalService.openConnectCalendarModal(modalOptions)
                        .then(self.handleUserSelectionOnConnectCalendarModal.bind(this))
                        .catch(this.clearCalendarProviderIfNeeded.bind(this));
                        this.$parent.$stateParams.googleIntegrationCreated = false;
                }.bind(this))
                .catch(function fail(resp) {
                    if (resp && resp.data && resp.data.error_type === 'HBUserError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.IMPORTING._ERROR_IMPORTING_');
                    }

                }.bind(this));
        }

        if ($stateParams.integrateFromScheduling) {
            this.openSyncModal();
        }

        const promiseProvider = this.getCalendarProvider();

        this.company.getAllCompanyMembers().forEach(function (member, index) {
            this.calendarSources[this.getTeamMemberSourceId(member._id)] = {
                mySourceId: this.getTeamMemberSourceId(member._id),
                events: []
            };
            if (!this.teamMembersIndices[member._id]) {
                this.teamMembersIndices[member._id] = index;
            }
        }.bind(this));

        this.calendarItems = [];

        const calendarItemsPromise = this.getItems();

        this.isVenue = this.user.isVenueVendor();

        if (this.isVenue) {
            this.isCompanyOwner = this.user.isCompanyOwner();

            this.setSpacesPromise = this.CompaniesManager.getCompanySpaces(this.user.company).then(function success() {
                this.companySpaces = this.user.company.company_spaces;
                this.showSpacesToggles = true;

                this.companySpaces.forEach(function (space) {
                    this.calendarSources[this.getSpaceSourceId(space._id)] = {
                        mySourceId: this.getSpaceSourceId(space._id),
                        events: []
                    };
                }.bind(this));

                this.companySpacesToIds = this.companySpaces.map(function (space) {
                    return space._id;
                });

                this.spacesCalendarList = this._getSpacesSidebarList();

            }.bind(this));
        }

        this.WebsocketHelperService.registerToRoom(this.user._id + ".calendar_update", this.webSocketCalendarUpdate.bind(this));

        // remove all listeners on destruction to avoid leaks
        $scope.$on('$destroy', function () {
            this.WebsocketHelperService.unregisterFromRoom(this.workspaceId + ".calendar_update");
        }.bind(this));


        this.$q.all([promiseProvider, calendarItemsPromise, this.setSpacesPromise]).finally(function finallyOnLoaing() {
            self.handleProviderUpdated(self.calendarProvider);
            self.calendarProvidersInfoLoading = false;

            // let the binding do it's thing
            self.$timeout(function () {
                self.initCalendarData($stateParams.dateTimeStart);
            });
        });

        /* Suggestions */
        this._shouldShowSuggestions();

        this.honeyBookCalendarList = this._getHoneyBookSidebarList();
        this.teamMemberCalendarList = this._getTeamMemberCalendarList();

        // register to parent calendar toolbar events
        this.$scope.calendarAbstractVm.onCalendarToolbarEvent(this.onCalendarToolbarEvent.bind(this));

        // set calendar as the active route
        this.$scope.calendarAbstractVm.setCalendarActive();
    }

    Controllers.CalendarController = Class(Controllers.BaseController, {
        constructor: CalendarControllerCtor,

        /** SIDEBAR: HB */
        _getHoneyBookSidebarList: function _getHoneyBookSidebarList() {
            const userColors = this.calendarColors;
            const hbCalendarList = {
                label: "CALENDAR.LABELS._HB_",
                keep_dropdown_mounted: true,
                hide_cog: true,
                analytics: "honeybook calendar",
                calendars: [
                    {
                        calendar_color: userColors.booked_projects_color || "#3758c8",
                        static_list: true,
                        calendar_name: "REPORTS.CALENDAR._BOOKED_PROJECTS_",
                        color_type: 'booked_projects_color'
                    },
                    {
                        calendar_color: userColors.meetings_color || "#fc8300",
                        static_list: true,
                        calendar_name: "REPORTS.CALENDAR._MEETING_PROJECTS_",
                        color_type: 'meetings_color'
                    },
                    {
                        calendar_color: userColors.payments_color || "#4CC676",
                        type: this.Enums.calendarTypes.payments,
                        calendar_name: "REPORTS.CALENDAR._PAYMENTS_",
                        enabled: this.showPayments,
                        color_type: 'payments_color'
                    },
                    {
                        calendar_color: userColors.tentative_projects_color || "#597EFF",
                        type: this.Enums.calendarTypes.tentativeProjects,
                        calendar_name: "REPORTS.CALENDAR._TENTATIVE_PROJECTS_",
                        enabled: this.showLeadEvents,
                        css_class: userColors.tentative_projects_color ? "custom-color-checkbox" : "tentative-events",
                        color_type: 'tentative_projects_color'
                    }
                ],
            };

            if (this.userIsSubscribeToCalendly || this.userHaveCalendlyMeetings) {
                hbCalendarList.calendars.splice(2, 0, {
                    calendar_color: userColors.calendly_color || "#9271f5",
                    static_list: true,
                    calendar_name: "REPORTS.CALENDAR._CALENDLY_MEETING_",
                    color_type: 'calendly_color'
                });
            }

            return hbCalendarList;
        },

        reload: function reload() {
            this.getItems().then(() => {
                this.$timeout(() => {
                    this.all_events = [];
                    this.initCalendarData(null);
                    this.filterEvents();
                    this.forceViewToRefreshSources();
                });
            })
        },

        getItems: function getItems() {
            return this.UsersManager.getCalendarsItems(this.user).then(
                (resp) => {
                    this.calendarItems = resp.data.calendar_items.filter(function(item) {
                        return item.date_time_start || item.date_start;
                    });
                    this.sessionsList = this.getSessionConfigsCalendarSideList(resp.data.sessions_configs);
                },
                (resp) => {
                    if (resp && resp.data && resp.data.error_message) {
                        this.lastError = resp.data.error_message;
                    } else {
                        this.lastError = $translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                    }
                }
            );
        },
        getSessionConfigsCalendarSideList: function getSessionConfigsCalendarSideList(sessionConfigs) {

            sessionConfigs = sessionConfigs.map((config)=>{
                return {
                    calendar_name: config.session_name,
                    calendar_color: config.color,
                    static_list: true,
                };
            });
            const hbCalendarList = {
                label: "CALENDAR.LABELS._SESSION_CONFIGS_",
                keep_dropdown_mounted: false,
                hide_cog: true,
                analytics: "honeybook sessions",
                hide_color_picker: true,
                calendars: sessionConfigs,
            };

            return hbCalendarList;
        },

        getCalendarProvider: function getCalendarProvider() {
            return this.UsersManager.getCalendarProvider(this.user)
                .then(function (response) {
                    this.calendarProvider = response.data.calendar_provider;
                    if (this.calendarProvider) {
                        this.showHbEventsOnCalendar = this.calendarProvider.shares_enabled ? true : false; // it could be null
                    }
                }.bind(this));
        },

        _getTeamMemberCalendarList: function _getTeamMemberCalendarList() {
            const teamMembers = [];
            const teamMembersColorList = this.calendarColors.team_members_color || {};

            this.company.getAllCompanyMembers().forEach(
                function (teamMember, index) {
                    if (teamMember._id !== this.user._id) {
                        teamMembers.push({
                            calendar_name: teamMember.full_name,
                            enabled: this.toggledTeamMembers[this.getTeamMemberSourceId(teamMember._id)],
                            type: this.getTeamMemberSourceId(teamMember._id),
                            css_class: "events-team-members-" + (this.teamMembersIndices[teamMember._id] ? this.teamMembersIndices[teamMember._id] : index),
                            color_type: teamMember._id,
                            calendar_color: teamMembersColorList[teamMember._id],
                            _id: teamMember._id
                        });
                        if (!this.teamMembersIndices[teamMember._id]) {
                            this.teamMembersIndices[teamMember._id] = index;
                        }
                    }
                }.bind(this));

            const returnValue = {
                label: "CALENDAR.LABELS._TEAM_MEMBERS_",
                hide_cog: true,
                calendars: teamMembers,
                analytics: "team members calendar"
            };

            return returnValue;

        },
        /** SIDEBAR: Google imported calendars */
        _getGoogleSidebarList: function _getGoogleSidebarList() {
            var label = this.calendarProvider._type === 'NylasCalendarProvider' ? this.calendarProvider.main_email : "CALENDAR.LABELS._GOOGLE_";
            let calendars = this.calendarProvider.calendar_importer ? this.calendarProvider.calendar_importer.calendar_imports : null;
            if (calendars) {
                calendars.forEach((calendar) => {
                    calendar.color_type = 'integrated_calendar_' + calendar._id;
                });
            }
            return {
                label: label,
                checked_all_items: true,
                calendars: calendars
            };
        },

        /** SIDEBAR: Google imported calendars */
        _getSessionsSidebarList: function _getSessionsSidebarList() {

            const calendars = this.sessionsTypes.map(function (session) {
                return {
                    calendar_name: session.session_name,
                    css_class: "session-calendar--" + session.session_color,
                    enabled: true,
                };
            });

            return {
                label: "CALENDAR.LABELS._SESSIONS_",
                checked_all_items: true,
                analytics: "sessions list",
                calendars: calendars
            };
        },

        /** SIDEBAR: Venue spaces */
        _getSpacesSidebarList: function _getSpacesSidebarList() {
            const spacesColorList = this.calendarColors.spaces_color || {};
            let spaces = []
            if (this.companySpaces !== undefined) {
                spaces = this.companySpaces.map(function (space, index) {
                    return {
                        calendar_name: space.name,
                        enabled: this.toggledSpaces[space._id],
                        css_class: "events-spaces-" + index,
                        _id: space._id,
                        calendar_color: spacesColorList[space._id],
                        color_type: "space_" + space._id,
                    };
                }.bind(this));
            }

            const isOwner = this.user.isCompanyOwner();

            return {
                label: "CALENDAR.LABELS._SPACES_",
                calendars: spaces,
                show_cog_mobile: isOwner,
                hide_cog: !isOwner,
                analytics: "spaces calendar"
            };
        },

        getTimeSpanForRequest: function getTimeSpanForRequest(timeSpanObj, requestedStartDate, requestedEndDate, maxWeeks) {
            let result = {
                startDate: null,
                endDate: null
            };

            if (!requestedStartDate || !requestedEndDate) {
                return null;
            }

            if ((timeSpanObj.startDate === null) && (timeSpanObj.endDate === null)) {
                result.startDate = timeSpanObj.startDate = requestedStartDate;
                result.endDate = timeSpanObj.endDate = requestedEndDate;
            } else if (requestedStartDate.isBefore(timeSpanObj.startDate)) {
                result.endDate = timeSpanObj.startDate;
                timeSpanObj.startDate = requestedStartDate;
                result.startDate = requestedStartDate;
                if (result.endDate.diff(result.startDate, "weeks") < maxWeeks) {
                    timeSpanObj.startDate = result.startDate = this.moment(result.endDate).subtract(maxWeeks, "weeks");
                } else if (result.endDate.diff(result.startDate, "months") > 2) {
                    // When jumping back more than 2 months, google is not able to fetch
                    timeSpanObj.endDate = result.endDate = this.moment(result.startDate).add(3, "months");
                }
            } else if (requestedEndDate.isAfter(timeSpanObj.endDate)) {
                result.startDate = timeSpanObj.endDate;
                timeSpanObj.endDate = requestedEndDate;
                result.endDate = requestedEndDate;
                if (result.endDate.diff(result.startDate, "weeks") < maxWeeks) {
                    timeSpanObj.endDate = result.endDate = this.moment(result.startDate).add(maxWeeks, "weeks");
                } else if (result.endDate.diff(result.startDate, "months") > 2) {
                    // When jumping back more than 2 months, google is not able to fetch
                    timeSpanObj.startDate = result.startDate = this.moment(result.endDate).subtract(3, "months");
                }
            } else {
                result = null;
            }

            return result;
        },

        convertCalendarItemToFullCalendarEvent: function convertCalendarItemToFullCalendarEvent(calendarItem) {
            const cal_event = {};

            cal_event.event_location = calendarItem.location;
            cal_event.title = calendarItem.title;
            cal_event.allDay = calendarItem.all_day;
            if (cal_event.allDay) {
                cal_event.start = this.moment(calendarItem.date_start, 'YYYY-MM-DD');
                cal_event.end = calendarItem.date_end ? this.moment(calendarItem.date_end, 'YYYY-MM-DD').add(1, 'days') : cal_event.start;
            } else {
                cal_event.start = calendarItem.date_time_start;
                cal_event.end = calendarItem.date_time_end ? calendarItem.date_time_end : cal_event.start;
            }
            cal_event.eventType = PROJECT_TYPES.CALENDAR_ITEM;
            if (calendarItem.calendly_id) {
                cal_event.className = 'cal-calendly-meeting';
                cal_event.color = this.calendarColors.calendly_color;
                this.userHaveCalendlyMeetings = true;
            } else {
                cal_event.className = 'cal-meeting-event';
                cal_event.color = calendarItem.color || this.calendarColors.meetings_color;
            }
            cal_event.hbData = {
                calendarItem: calendarItem
            };

            return cal_event;
        },

        /**
         * call this funcions with the this as the context of the event.. the calendar
         * @param event the event to convert
         * @param calendar the google calendar that this event belongs to
         * @returns {object} the converted event in the format that fullcalendar can handle
         */
        convertFetchedEventToFullCalendarEvent: function convertFetchedEventToFullCalendarEvent(event, calendarImport) {
            const cal_event = {};

            if (event.status === "cancelled") {
                return null;
            }
            cal_event.event_location = event.location;
            cal_event.title = event.summary;
            cal_event.googleEventId = event.id;
            cal_event.start = event.start.date_time ? event.start.date_time : event.start.date;
            cal_event.end = event.end.date_time ? event.end.date_time : event.end.date;
            cal_event.color = calendarImport.calendar_color;
            cal_event.isGoogleEvent = true;
            cal_event.calendarLink = event.html_link;
            cal_event.eventType = (event.provider && event.provider === 'nylas') ? PROJECT_TYPES.NYLAS_EVENT : PROJECT_TYPES.GOOGLE_EVENT;
            cal_event.className = 'hb-calendar__color__google-grid';
            cal_event.availabilityType = (event.transparency && event.transparency === "transparent" ? "free" : "busy");
            //this will prevents from events to disapear when moving between months.
            //cal_event.stick = true;

            // event day is EXCLUSIVE in google
            cal_event.endForPopup = event.end.date ? this.moment(cal_event.end).add(-1, 'day').format("YYYY-MM-DD") : undefined;

            return cal_event;
        },

        convertHbEventToCalendarEvent: function convertHbEventToCalendarEvent(hbEvent) {
            const cal_event = {};
            const eventMoments = this.EventService.hbEventToMoments(hbEvent);

            cal_event.hbData = {
                event_location: hbEvent.event_location ? hbEvent.event_location : '',
                event_member_names: hbEvent.event_member_names,
                is_team_member_event: hbEvent.is_team_member_event,
                has_event_time_end: !!hbEvent.event_time_end
            };

            cal_event.title = hbEvent.event_name;
            cal_event.eventId = hbEvent._id;
            cal_event.lastWorkspaceId = hbEvent.workspaces_ids[hbEvent.workspaces_ids.length - 1];
            cal_event.availabilityType = hbEvent.availability_type || 'busy';

            if (eventMoments.wholeDayEvent) {
                cal_event.allDay = true;
                cal_event.start = this.moment(hbEvent.event_date).format("YYYY-MM-DD");
                if (hbEvent.event_end_date) {
                    // adding 1 day because end is EXCLUSIVE in fullcalendar
                    cal_event.end = this.moment(hbEvent.event_end_date).add(1, 'day').format("YYYY-MM-DD");
                    cal_event.endForPopup = this.moment(hbEvent.event_end_date).format("YYYY-MM-DD");
                }
            } else {
                cal_event.start = eventMoments.momentEventTimeStart.format();
                cal_event.end = eventMoments.momentEventTimeEnd.format();
            }

            if (!!hbEvent.is_booked) {
                cal_event.className = 'cal-date-past-events';
                cal_event.eventType = PROJECT_TYPES.BOOKED_PROJECT;
                cal_event.color = this.calendarColors.booked_projects_color;
            } else {
                cal_event.className = this.calendarColors.tentative_projects_color ? 'custom-color' : 'cal-date-past-events-lead';
                cal_event.eventType = PROJECT_TYPES.LEAD_PROJECT;
            }

            if (this.isVenue && hbEvent.spaces) {
                cal_event.hbData.spaces = hbEvent.spaces;
            }

            return cal_event;
        },

        persistUiState: function persistUiState() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.calendarView, this.serializeUiState());
        },

        serializeUiState: function serializeUiState() {
            return {
                showPayments: this.showPayments,
                showLeadEvents: this.showLeadEvents,
                showTeamEvents: this.showTeamEvents,
                showCalendarToggles: this.showCalendarToggles,
                showSessionsToggles: this.showSessionsToggles,
                showGoogleCalendarAnnouncementTooltip: false,
                calendarDefaultView: this.calendarView,
                toggledTeamMembers: this.toggledTeamMembers,
                teamMembersIndices: this.teamMembersIndices,
                toggledSpaces: this.toggledSpaces
            };
        },

        deserailizeUiState: function deserailizeUiState(persistanceHash) {
            if (persistanceHash.showPayments !== undefined) {
                this.showPayments = persistanceHash.showPayments;
            }

            if (persistanceHash.showLeadEvents !== undefined) {
                this.showLeadEvents = persistanceHash.showLeadEvents;
            }

            if (persistanceHash.showGoogleCalendarAnnouncementTooltip) {
                this.showGoogleCalendarAnnouncementTooltip = true;
            }

            if (persistanceHash.showCalendarToggles !== undefined) {
                this.showCalendarToggles = persistanceHash.showCalendarToggles;
            }

            if (persistanceHash.showSessionsToggles !== undefined) {
                this.showSessionsToggles = persistanceHash.showSessionsToggles;
            }

            if (persistanceHash.showTeamEvents !== undefined) {
                this.showTeamEvents = persistanceHash.showTeamEvents;
            }

            if (!this._.isEmpty(persistanceHash.toggledTeamMembers)) {
                this.toggledTeamMembers = persistanceHash.toggledTeamMembers;
            }

            if (!this._.isEmpty(persistanceHash.toggledSpaces)) {
                this.toggledSpaces = persistanceHash.toggledSpaces;
            }

            if (!this._.isEmpty(persistanceHash.teamMembersIndices)) {
                this.teamMembersIndices = persistanceHash.teamMembersIndices;
            }

            if (this.$stateParams.dateTimeStart) {
                this.calendarView = this.CalendarService.CALENDAR_DAY_VIEW;
            } else if (persistanceHash.calendarDefaultView) {
                this.calendarView = persistanceHash.calendarDefaultView;
            } else {
                this.calendarView = this.CalendarService.CALENDAR_MONTH_VIEW;
            }

            this.$scope.calendarAbstractVm.setCalendarView(this.calendarView);
        },

        canAddToView: function canAddToView(originalId) {
            return !this.allCalendarEventIds[originalId];
        },

        addCalendarEventToView: function addCalendarEventToView(originalId, calEvent) {
            this.allCalendarEventIds[originalId] = true;
            this.all_events.push(calEvent);
            if (calEvent.otherType) {
                this.calendarSources[calEvent.otherType].events.push(calEvent);
            } else {
                this.calendarSources[calEvent.eventType].events.push(calEvent);
            }
        },

        addHbEventsToCalendarView: function addHbEventsToCalendarView(events) {
            if (!events) {
                return;
            }

            this.addEventsToCalendar(events.past_events);
            this.addEventsToCalendar(events.future_events);
        },

        addEventsToCalendar: function addEventsToCalendar(events) {
            if (events) {
                events.forEach(
                    function (event, index) {
                        if (this.canAddToView(event._id)) {
                            const cal_event = this.convertHbEventToCalendarEvent(event);
                            this.copyEventToItsTeamMembers(event.all_event_member_ids, cal_event);
                            this.copyEventToItsSpaces(cal_event);
                            if (event.all_event_member_ids && event.all_event_member_ids.includes(this.user._id)) {
                                this.addCalendarEventToView(event._id, cal_event);
                            }
                        }
                    }.bind(this)
                );
            }
        },

        convertPaymentToCalendarEvent: function convertPaymentToCalendarEvent(payment, type) {
            const calEvent = {};
            calEvent.amount = this.$filter('hbcurrency')((payment.payment_hb_transfer_to_vendor || payment.payment_amount));
            calEvent.title = payment.payment_count_description + " of " + this.$filter('hbcurrency')(payment.payment_amount) + " due: " + payment.event_name;
            calEvent.project_title = payment.event_name;
            calEvent.is_milestone = payment.is_milestone;
            calEvent.paymentPopupTitle = payment.event_name + ': ' + payment.payment_count_description;
            calEvent.start = this.moment(payment.payment_due_date).format("YYYY-MM-DD");
            calEvent.eventType = PROJECT_TYPES.PAYMENT;
            calEvent.coupleCardId = payment.couple_card_id;
            calEvent.eventId = payment.event_id;

            const paymentColor = this.calendarColors.payments_color;

            switch (type) {
                case PROJECT_TYPES.PAYMENT_TYPES.LATE:
                    calEvent.amount = this.$filter('hbcurrency')(payment.payment_amount);
                    calEvent.className = 'cal-payment-overdue';
                    calEvent.color = paymentColor;
                    break;
                case PROJECT_TYPES.PAYMENT_TYPES.IMMINENT:
                    calEvent.className = 'cal-payment-alert';
                    calEvent.color = paymentColor;
                    break;
                case PROJECT_TYPES.PAYMENT_TYPES.DUE:
                    calEvent.className = 'cal-payment-due';
                    calEvent.color = paymentColor;
                    break;
                case PROJECT_TYPES.PAYMENT_TYPES.PAID:
                    calEvent.title = payment.payment_count_description + " of " + this.$filter('hbcurrency')((payment.payment_hb_transfer_to_vendor || payment.payment_amount)) + " paid: " + payment.event_name;
                    calEvent.start = this.moment(payment.payment_charge_date).format("YYYY-MM-DD");
                    calEvent.className = 'cal-payment-paid';
                    calEvent.color = paymentColor;
                    break;
            }

            return calEvent;
        },

        addPaymentEventToView: function addPaymentEventToView(payment, type) {
            if (this.canAddToView(payment._id)) {
                const calEvent = this.convertPaymentToCalendarEvent(payment, type);
                this.addCalendarEventToView(payment._id, calEvent);
            }
        },

        addPaymentsToCalendarView: function addPaymentsToCalendarView(payments) {

            if (payments.late_payments) {
                payments.late_payments.forEach(
                    function (payment, index) {
                        if (!payment.payment_date_is_tbd) {
                            this.addPaymentEventToView(payment, PROJECT_TYPES.PAYMENT_TYPES.LATE);
                        }
                    }.bind(this)
                );
            }

            if (payments.imminent_payments) {
                payments.imminent_payments.forEach(
                    function (payment, index) {
                        if (!payment.payment_date_is_tbd) {
                            this.addPaymentEventToView(payment, PROJECT_TYPES.PAYMENT_TYPES.IMMINENT);
                        }
                    }.bind(this)
                );
            }

            if (payments.upcoming_payments) {
                payments.upcoming_payments.forEach(
                    function (payment, index) {
                        if (!payment.payment_date_is_tbd) {
                            this.addPaymentEventToView(payment, PROJECT_TYPES.PAYMENT_TYPES.DUE);
                        }
                    }.bind(this)
                );
            }

            if (payments.due_payments) {
                payments.due_payments.forEach(
                    function (payment, index) {
                        if (!payment.payment_date_is_tbd) {
                            this.addPaymentEventToView(payment, PROJECT_TYPES.PAYMENT_TYPES.DUE);
                        }
                    }.bind(this)
                );
            }

            if (payments.paid_payments) {
                payments.paid_payments.forEach(
                    function (payment, index) {
                        this.addPaymentEventToView(payment, PROJECT_TYPES.PAYMENT_TYPES.PAID);
                    }.bind(this)
                );
            }
        },


        onEventRender(event, element) {
            // attribute added to distinguish workspace related events. used in notes discoverability.
            if(event.hbData && event.hbData.calendarItem && event.hbData.calendarItem.workspace_id) {
                element[0].setAttribute('data-is-workspace-related', 'true');
            }
        },
        
        initCalendarData: function initCalendarData(defaultDate) {
            const self = this;
            const calendarConfig = {
                header: false,
                eventLimit: 3,
                slotDuration: '00:30:00',
                timezone: 'local',
                editable: false,
                selectable: true,
                fixedWeekCount: false,
                slotEventOverlap: false,
                contentHeight: 'auto',
                allDayText: 'All day',
                timeFormat: 'h:mm A',
                nextDayThreshold: '03:00:00',
                defaultView: this.calendarView.key,
                views: {
                    month: {
                        columnFormat: 'ddd'
                    },
                    week: {
                        columnFormat: 'ddd D',
                        axisFormat: 'h a',
                    },
                    day: {
                        columnFormat: 'dddd D',
                        axisFormat: 'h a',
                    }
                },
                eventRender: function eventRender(event, element) {
                    this.onEventRender(event, element);
                }.bind(this),
            };

            if (self.calendarItems) {
                self.calendarItems.forEach(function (calendarItem, index) {
                    const cal_event = self.convertCalendarItemToFullCalendarEvent(calendarItem);
                    if (this.user._id !== calendarItem.owner_id) {
                        this.copyEventToItsTeamMembers([calendarItem.owner_id], cal_event);
                    } else {
                        self.all_events.push(cal_event);
                    }
                }.bind(this));
            }


            calendarConfig.viewRender = self.handleViewDateRangeChanged.bind(self);

            if (defaultDate) {
                // the requested date. should be a string that a momemt can parse
                if (defaultDate === "today") {
                    defaultDate = self.moment();
                    calendarConfig.defaultDate = defaultDate;
                    calendarConfig.defaultView = 'agendaDay';
                    calendarConfig.scrollTime = self.moment(defaultDate).format('HH:mm:ss');
                } else if (defaultDate === "week") {
                    defaultDate = self.moment();
                    calendarConfig.defaultView = 'agendaWeek';
                    calendarConfig.defaultDate = defaultDate;
                } else {
                    calendarConfig.defaultDate = defaultDate;
                    calendarConfig.defaultView = 'agendaDay';
                    calendarConfig.scrollTime = self.moment(defaultDate).format('HH:mm:ss'); // This is not working: https://github.com/fullcalendar/fullcalendar/issues/4114
                    setTimeout(function () {
                        const hourSlotHeight = 70;
                        window.scrollTo({
                            top: self.moment(defaultDate).hour() * hourSlotHeight,
                            behavior: 'smooth'
                        });
                    }, 200);
            }        
            } else {
                //today
                calendarConfig.defaultDate = self.moment().format("YYYY-MM-DD");
            }

            calendarConfig.eventClick = function eventClick(date, jsEvent, view) {
                self.CssVariablesService.setStyle('currTeamMemberBackgroundColor', angular.element(this).css("background"));
                const popover = {
                    date: date,
                    jsEvent: jsEvent,
                    view: view.type,
                    popoverType: self.CalendarService.getPopoverType(date),
                    dayElement: this
                };
                self.$scope.$broadcast('main-calendar-popover', popover);
            };

            calendarConfig.eventLimitClick = function eventLimitClick(date, jsEvent, view) {
                const popover = {
                    date: date,
                    jsEvent: jsEvent,
                    view: view.type,
                    popoverType: self.Enums.calendarPopoverTypes.event_limit,
                    dayElement: this
                };
                self.$scope.$broadcast('main-calendar-popover', popover);
            };

            calendarConfig.dayClick = function dayClick(date, jsEvent, view) {
                const popover = {
                    date: date,
                    jsEvent: jsEvent,
                    view: view.type,
                    popoverType: self.Enums.calendarPopoverTypes.day_click,
                    dayElement: this
                };
                self.$scope.$broadcast('main-calendar-popover', popover);
            };

            this.$scope.calendarAbstractVm.updateCalendarView = this.persistUiState.bind(this);

            this.calendarElement.fullCalendar(calendarConfig);

            this.calendarConfig = calendarConfig;
            this.updateCalendarTitle();
        },

        handleViewDateRangeChanged: function handleViewDateRangeChanged(view, element) {
            let startDateMoment, endDateMoment;

            // for some strage resson the moment objects on the full calendar view is do not format it right. convert it to our moment.
            startDateMoment = this.moment(view.start.format());
            endDateMoment = this.moment(view.end.format());
            this.curStartDate = this.moment(startDateMoment);
            this.curEndDate = this.moment(endDateMoment);

            if (view.type === 'month') {
                //pre-fetch the previous months and the next months, every time we have less than 15 weeks of pre-fetched dates
                startDateMoment = startDateMoment.subtract(5, "weeks");
                endDateMoment = endDateMoment.add(5, "weeks");
            } else if (view.type === "agendaDay" || view.type === "agendaWeek") {
                //when using daily or weekly view, pre-fetch more dates when having less than 5 extra weeks of pre-fetched dates
                startDateMoment = startDateMoment.subtract(5, "weeks");
                endDateMoment = endDateMoment.add(5, "weeks");
            } else {
                throw "unexpected view type";
            }

            if (this.firstRender) {
                this.firstRender = false;

                //finished loading
                this.$timeout(function () {
                    this.calendarElement.fullCalendar('refetchEvents');
                    this.filterEvents();
                }.bind(this), 100);

                this.getEventsForTimeRange(startDateMoment, endDateMoment);
                return;
            }

            this.getEventsForTimeRange(startDateMoment, endDateMoment);
            this.updateLoaderStatus();
        },

        getSpaceSourceId: function (spaceId) {
            return "space-" + spaceId;
        },

        copyEventToItsSpaces: function copyEventToItsSpaces(hbEvent) {
            const self = this;
            self.$q.all([this.setSpacesPromise]).then(function success() {
                self.$timeout(function () {
                    if (hbEvent.hbData && hbEvent.hbData.spaces) {
                        hbEvent.hbData.spaces.forEach(function (spaceId) {
                            const index = self.companySpacesToIds.indexOf(spaceId);

                            if (index === -1) {
                                return;
                            }

                            // copy event
                            const spaceEvent = JSON.parse(JSON.stringify(hbEvent));

                            let newClassName = "cal-date-events-spaces-" + index;
                            spaceEvent.color = null;
                            if (spaceEvent.className.indexOf("lead") > -1) {
                                newClassName = newClassName + "-lead";
                            }
                            spaceEvent.className = newClassName;

                            self.calendarSources[self.getSpaceSourceId(spaceId)].events.push(spaceEvent);
                        });
                    }
                });
            });
        },

        getTeamMemberSourceId: function (teamMemberId) {
            return "team_" + teamMemberId;
        },

        getTeamItemType: function getTeamItemType(type) {
            if (type && type.includes("meeting")) {
                return "team_meeting";
            }
            return "team_project";
        },

        copyEventToItsTeamMembers: function copyEventToItsTeamMembers(membersIds, cal_event) {
            const membersIdsUniq = new Set(membersIds);
            membersIdsUniq.forEach(function (memberId) {
                const index = this.teamMembersIndices[memberId];
                if (index !== undefined && memberId !== this.user._id) {
                    const event = {};
                    angular.copy(cal_event, event);
                    event.className = "cal-date-events-team-members-" + index;
                    event.eventType = this.getTeamItemType(cal_event.className);
                    event.color = this.calendarColors.team_members_color[memberId];

                    // otherType contains extra data to classify where the event belongs to - to team member/meeting/payment etc
                    event.otherType = this.getTeamMemberSourceId(memberId);
                    this.addCalendarEventToView(event.eventId, event);
                }
            }.bind(this));
        },

        //the function resets the calender providers events and adds the providers again
        filterEvents: function filterEvents() {
            //clear all the currently added events from the sources we will add the again
            this._.forEach(this.calendarSources, function (calendarSource, calendarSourceKey) {
                this.calendarSources[calendarSourceKey].events.splice(0, this.calendarSources[calendarSourceKey].events.length);
            }.bind(this));

            //run on all of the events and fill them up on the right source
            this.all_events.forEach(function (hbEvent) {
                if (hbEvent.otherType) {
                    this.calendarSources[hbEvent.otherType].events.push(hbEvent);
                } else {
                    this.calendarSources[hbEvent.eventType].events.push(hbEvent);
                }
            }.bind(this));

            //run on all of the events and fill them up on the right space
            this.all_events.forEach(this.copyEventToItsSpaces.bind(this));

            //booked events source will allways show
            this.addCalendarSourceToSelected(PROJECT_TYPES.BOOKED_PROJECT);
            this.addCalendarSourceToSelected(PROJECT_TYPES.CALENDAR_ITEM);

            if (this.showLeadEvents) {
                this.addCalendarSourceToSelected(PROJECT_TYPES.LEAD_PROJECT);
            }

            if (this.showPayments) {
                this.addCalendarSourceToSelected(PROJECT_TYPES.PAYMENT);
            }

            if (this.isTeamEventsCalendarOn) {
                if (this.showTeamEvents) {
                    this.addCalendarSourceToSelected(PROJECT_TYPES.TEAM);
                }
                if (!this._.isEmpty(this.toggledTeamMembers)) {
                    this._.forEach(this.toggledTeamMembers, function (shouldShow, memberId) {
                        if (shouldShow && memberId.replace('team_', '') !== this.user._id) {
                            this.addCalendarSourceToSelected(memberId);
                        }
                    }.bind(this));

                }
            }

            this.filterGoogleEvents();
        },

        filterGoogleEvents: function filterGoogleEvents() {
            let googleCalendarImporter;
            const self = this;

            if (this.calendarProvider && this.calendarProvider.calendar_importer) {
                googleCalendarImporter = this.calendarProvider.calendar_importer;

                if (googleCalendarImporter.enabled) {
                    this._.each(googleCalendarImporter.calendar_imports, function (calendarImport) {

                        self.removeCalendarSourceFromSelected(calendarImport.calendar_id);

                        if (calendarImport.enabled) {
                            self.addCalendarSourceToSelected(calendarImport.calendar_id);
                        }
                    });
                }
            }
        },

        removeCalendarSourceFromSelected: function removeCalendarSourceFromSelected(sourceId) {
            this.notesDiscoverabilityRenderKey += 1;
            const indexInSelectd = this._.findIndex(this.selectedCalendarSources, {mySourceId: sourceId});
            if (indexInSelectd !== -1) {
                this.calendarElement.fullCalendar('removeEventSource', this.selectedCalendarSources[indexInSelectd]);
                const removedSourcesArray = this.selectedCalendarSources.splice(indexInSelectd, 1);
            }

            // return an indication if it was actually removed
            return (indexInSelectd !== -1);
        },

        addCalendarSourceToSelected: function addCalendarSourceToSelected(sourceId) {
            this.notesDiscoverabilityRenderKey += 1;
            const indexInSelectd = this._.findIndex(this.selectedCalendarSources, {mySourceId: sourceId});
            if (indexInSelectd === -1) {
                //only if its not already included
                this.selectedCalendarSources.push(this.calendarSources[sourceId]);
                this.calendarElement.fullCalendar('addEventSource', this.calendarSources[sourceId]);
            }
        },

        toggleShowCalendars: function toggleShowCalendars() {
            this.showCalendarToggles = !this.showCalendarToggles;
            this.persistUiState();
        },

        toggleShowSessions: function toggleShowSessions() {
            this.showSessionsToggles = !this.showSessionsToggles;
            this.persistUiState();
        },

        togglePayments: function togglePayments() {
            this.showPayments = !this.showPayments;

            if (this.showPayments) {
                // it was false before add it now to the list of sources to be seen
                this.addCalendarSourceToSelected(PROJECT_TYPES.PAYMENT);
            } else {
                this.removeCalendarSourceFromSelected(PROJECT_TYPES.PAYMENT);
            }

            this.persistUiState();
        },

        toggleLeadEvents: function toggleLeadEvents() {
            this.showLeadEvents = !this.showLeadEvents;

            //this.calendarConfig.events = this.filterEvents();
            if (this.showLeadEvents) {
                // it was false before add it now to the list of sources to be seen
                this.addCalendarSourceToSelected(PROJECT_TYPES.LEAD_PROJECT);
            } else {
                this.removeCalendarSourceFromSelected(PROJECT_TYPES.LEAD_PROJECT);
            }

            this.persistUiState();
        },

        toggleImportedCalendar: function importedCalendarToggleClicked(calendarImport) {
            calendarImport.enabled = !calendarImport.enabled;

            if (calendarImport.enabled) {
                // it was false before add it now to the list of sources to be seen
                this.addCalendarSourceToSelected(calendarImport.calendar_id);
            } else {
                this.removeCalendarSourceFromSelected(calendarImport.calendar_id);
            }

            //this.forceViewToRefreshSources();

            //persist the enable/disable to the model on the db
            this.UsersManager.setCalendarImport(calendarImport);
        },

        openSyncModal: function openSyncModal() {
            if (this.calendarProvider && this.calendarProvider._type === 'NylasCalendarProvider') {
                this.ModalService.openConnectNylasCalendarModal({isConnected: !!this.calendarProvider})
                    .then(this.handleUserSelectionOnConnectCalendarModal.bind(this))
                    .catch(this.clearCalendarProviderIfNeeded.bind(this));
            } else {
                this.ModalService.openConnectCalendarModal({isConnected: !!this.calendarProvider})
                    .then(this.handleUserSelectionOnConnectCalendarModal.bind(this))
                    .catch(this.clearCalendarProviderIfNeeded.bind(this));
            }
            this.shouldShowCalendarSuggestion = false;
        },

        handleUserSelectionOnConnectCalendarModal: function handleUserSelectionOnConnectCalendarModal(userSelection) {
            if (userSelection) {

                // clear provider if needed
                if (userSelection.isCalendarDisconnected) {
                    this.clearCalendarProviderIfNeeded(userSelection);
                    return;
                }

                // map shared emails object to only emails
                userSelection.sharedWithEmailsCollection = userSelection.sharedWithEmailsCollection.map(function (e) {
                    return e.email;
                });

                // update calendar provider settings
                this.UsersManager.updateCalendarProvider(this.user, userSelection).then(function (response) {
                    var homePersistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.oneHomeZeroStates, {});
                    homePersistency.calendar = false;
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.oneHomeZeroStates, homePersistency);

                    const userWithCalendarProvider = response.data;
                    this.handleProviderUpdated(userWithCalendarProvider.calendar_provider);
                    this.googleCalendars = this._getGoogleSidebarList();
                }.bind(this))
                    .catch(function error() {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.SYNC._ERROR_SHARING_');
                    }.bind(this));
            }
        },

        clearCalendarProviderIfNeeded: function clearCalendarProviderIfNeeded(userSelection) {
            if (userSelection && userSelection.isCalendarDisconnected) {

                // remove all calendar imports sources
                if (this.calendarProvider &&
                    this.calendarProvider.calendar_importer) {
                    const imports = this.calendarProvider.calendar_importer.calendar_imports;

                    // loop over all imports and clear from full calendar
                    imports.forEach(function (calendarImport) {
                        this.removeCalendarSourceFromSelected(calendarImport.calendar_id);
                    }.bind(this));
                }

                // only after we removed all we can clean the provider
                this.calendarProvider = null;

                // clear sidebar list
                this.googleCalendars = undefined;
            }
        },

        beforeDestroy: function beforeDestroy() {
        },

        clearAllSourcesAndSelected: function clearAllSelecedCalendarSources() {
            const googleCalendarImporter = this.calendarProvider.calendar_importer;
            if (googleCalendarImporter) {
                this._.each(googleCalendarImporter.calendar_imports, function (calendarImport) {
                    delete this.calendarSources[calendarImport.calendar_id];
                }.bind(this));
            }

            this.selectedCalendarSources.forEach(function (selectedCalendarSource) {
                this.calendarElement.fullCalendar('removeEventSource', selectedCalendarSource);
            }.bind(this));

            this.selectedCalendarSources.splice(0, this.selectedCalendarSources.length);
            this.fetchTimeSpan.resetTimeSpan();
        },

        handleProviderUpdated: function (calendarProvider) {
            const self = this;
            let googleCalendarImporter;
            let startDate, endDate;

            self.calendarProvider = calendarProvider;

            //we want to catch all the changes to the importers when they are updated
            if (calendarProvider && calendarProvider.calendar_importer && !calendarProvider.calendar_importer.revoked) {
                googleCalendarImporter = calendarProvider.calendar_importer;

                //if we have an importer fill it up with the calendars and ask for the events list for this month
                // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
                startDate = self.EventService.momentToRFC3339(self.moment().startOf('month'));
                endDate = self.EventService.momentToRFC3339(self.moment().endOf('month'));

                //clear the imported calendar sources we are going to rebuild them
                self.clearAllSourcesAndSelected();

                //build the fullcalendar source for this calendarImport and add it to the collection of soruces
                if (calendarProvider.calendar_importer.calendar_imports) {
                    calendarProvider.calendar_importer.calendar_imports.forEach(function (calendarImport) {

                        self.calendarSources[calendarImport.calendar_id] = {
                            mySourceId: calendarImport.calendar_id,
                            events: [],
                            eventsHash: {}
                        };
                    });
                }

                this.filterEvents();
                this.fetchGoogleEventsTimeSpan.resetTimeSpan();
                if (this.curStartDate && this.curEndDate) {
                    //we may be getting them as null since this happens at the very first time too and the view has
                    //not renderd yes so there are no start and to times. but we do if we come back from the settings dialog
                    this.getEventsForTimeRange(this.curStartDate, this.curEndDate);
                }
            }
        },

        wrapPromise: function wrapPromise(promise, timeSpanToRequest) {
            let ret;
            if (promise) {
                ret = promise.then(
                    function resolve(ret) {
                        return {ret: ret, resolved: true};
                    }).catch(
                    function reject(ret) {
                        return {ret: ret, resolved: false};
                    });
            } else {
                ret = promise;
            }
            if (timeSpanToRequest) { //show loader for this promise
                this.registerLoadPromise(timeSpanToRequest.startDate, timeSpanToRequest.endDate, ret);
            }
            return ret;
        },

        getEventsForTimeRange: function getEventsForTimeRange(startDateMoment, endDateMoment) {

            this.lastTimeRange.start = startDateMoment;
            this.lastTimeRange.end = endDateMoment;

            const timeSpanToRequest = this.fetchTimeSpan.getSpanForRequest(startDateMoment, endDateMoment);
            const calTimeSpanToRequest = this.fetchGoogleEventsTimeSpan.getSpanForRequest(this.curStartDate, this.curEndDate); //for Google always cur calendar view

            const promises = [];
            if (calTimeSpanToRequest) {
                promises.push(this.wrapPromise(this.getIntegratedCalendarEventsForTimeRange(calTimeSpanToRequest)));
            }
            if (timeSpanToRequest) {
                promises.push(this.wrapPromise(this.fetchHbEventsForTimeRange(timeSpanToRequest), timeSpanToRequest));
                promises.push(this.wrapPromise(this.fetchPaymentsReportForTimeRange(timeSpanToRequest), timeSpanToRequest));
            }
            if (promises.length > 0) {
                return this.$q.all(promises);
            } else {
                //no need to get anything we already have the data or it is coming.
                return this.$q.resolve(null);
            }

        },

        fetchHbEventsForTimeRange: function fetchHbEventsForTimeRange(timeSpanToRequest) {
            if (!timeSpanToRequest) {
                return;
            }
            return this.ReportsManager.getReportsCalendar(null, timeSpanToRequest.startDate, timeSpanToRequest.endDate).then(
                function success(resp) {
                    this.addHbEventsToCalendarView(resp.data);
                    this.forceViewToRefreshSources();
                }.bind(this),

                function error(resp) {
                    if (resp && resp.data && resp.data.error_message) {
                        this.lastError = resp.data.error_message;
                    } else {
                        this.lastError = this.$translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                    }
                }.bind(this)
            );
        },

        fetchPaymentsReportForTimeRange: function fetchPaymentsReportForTimeRange(timeSpanToRequest) {
            if (!timeSpanToRequest) {
                return;
            }

            return this.ReportsManager.getReportsPayments(null, true, timeSpanToRequest.startDate, timeSpanToRequest.endDate).then(
                function success(resp) {
                    this.addPaymentsToCalendarView(resp.data);
                    this.forceViewToRefreshSources();
                }.bind(this),

                function error(resp) {
                    if (resp && resp.data && resp.data.error_message) {
                        this.lastError = resp.data.error_message;
                    } else {
                        this.lastError = this.$translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                    }
                }.bind(this)
            );
        },

        updateLoaderStatus: function updateLoaderStatus() {
            if (!this.curStartDate || !this.curEndDate) {
                return;
            }

            this.loadingReports = this._.any(this.activePromises, function (obj) {
                return obj.endDate.isSameOrAfter(this.curStartDate) && obj.startDate.isSameOrBefore(this.curEndDate);
            }.bind(this));
        },

        registerLoadPromise: function registerLoadPromise(startDate, endDate, loadPromise) {
            if (!loadPromise || !startDate || !endDate) {
                return;
            }
            this.activePromises.push({startDate: startDate, endDate: endDate, promise: loadPromise});

            const onPromiseComplete = function () {
                const index = this._.findIndex(this.activePromises, function (p) {
                    return p.promise === loadPromise;
                });
                if (index >= 0) {
                    this.activePromises.splice(index, 1);
                }
                this.updateLoaderStatus();
            }.bind(this);

            loadPromise.then(onPromiseComplete).catch(onPromiseComplete);

            this.updateLoaderStatus();
        },

        getIntegratedCalendarEventsForTimeRange: function getIntegratedCalendarEventsForTimeRange(timeSpanToRequest) {
            const self = this;

            //convert the time boundries to the one google needs
            const startTimeForRequest = this.EventService.momentToRFC3339(timeSpanToRequest.startDate);
            const endTimeForRequest = this.EventService.momentToRFC3339(timeSpanToRequest.endDate);

            if (!this.calendarProvider) {
                return;
            }

            this.googleCalendars = this._getGoogleSidebarList();

            const calendarImporter = this.calendarProvider.calendar_importer;
            if (calendarImporter && !calendarImporter.revoked) {

                return self.UsersManager.getIntegratedCalendarImporterEvents(startTimeForRequest, endTimeForRequest).then(
                    function success(response) {
                        let replyMomentStartDate, replyMomentEndDate, diffResult;
                        //merge the events to the current data on the calendars sources

                        replyMomentStartDate = timeSpanToRequest.startDate;
                        replyMomentEndDate = timeSpanToRequest.endDate;
                        const recievedCaledarHash = response.data;
                        self._.each(recievedCaledarHash, function (eventsArray, calendarId) {

                            const calendarImport = self._.findWhere(calendarImporter.calendar_imports, {calendar_id: calendarId});
                            const calendarSource = self.calendarSources[calendarId];

                            if (calendarSource) {
                                self._.each(eventsArray, function (event) {
                                    //add the event only if the event is not already there (could be optimized)
                                    const eventIncludedAlready = calendarSource.eventsHash[event.id];
                                    if (!eventIncludedAlready && !event.recurrence) {

                                        const cal_event = self.convertFetchedEventToFullCalendarEvent(event, calendarImport);

                                        if (cal_event) {
                                            calendarSource.events.push(cal_event);
                                            calendarSource.eventsHash[event.id] = true;
                                        }
                                    }
                                });
                            }
                        });
                        self.forceViewToRefreshSources();
                    },
                    function fail(response) {
                        self.$log.error('failed to get the events for the calendar importer');
                        if (response && response.data && response.data.error_type) {
                            if (response.data.error_type === 'HBCalendarImporterTokenRevokedError') {
                                //mark it now as revoked
                                calendarImporter.revoked = true;
                                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.SYNC._ERROR_PERMISION_REVOKED_');
                            } else if (response.data.error_type === 'HBNylasIntegrationUserError' && response.data.error_message) {
                                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, response.data.error_message);
                            } else {
                                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                            }
                        } else {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                        }
                    });
            }
        },

        shouldShowCalendarImports: function shouldShowCalendarImports() {
            return this.showCalendarToggles &&
                this.calendarProvider &&
                this.calendarProvider.calendar_importer &&
                this.calendarProvider.calendar_importer.enabled &&
                !this.calendarProvider.calendar_importer.revoked;

        },

        hideGoogleCalendarAnnouncementTooltip: function hideGoogleCalendarAnnouncementTooltip() {
            const oldValue = this.showGoogleCalendarAnnouncementTooltip;
            this.showGoogleCalendarAnnouncementTooltip = false;
            if (oldValue === true && this.showGoogleCalendarAnnouncementTooltip === false) {
                this.persistUiState();
            }
        },

        forceViewToRefreshSources: function forceViewToRefreshSources() {
            this.selectedCalendarSources.forEach(function (selectedCalendarSource) {
                this.calendarElement.fullCalendar('removeEventSource', selectedCalendarSource);
                this.calendarElement.fullCalendar('addEventSource', selectedCalendarSource);
            }.bind(this));
        },

        onCreateEvent: function onCreateEvent(date) {
            const dateParams = {};
            dateParams.date = date.format("ddd, MMM D, YYYY");
            if (date.hasTime()) {
                dateParams.time = date.format('hh:mm A');
            }
            const config = {dateParams: dateParams};
            this.EventService.createEventCommand(config);
            this.AnalyticsService.trackClick(this, 'new event');
        },

        findCalendarItemIndices: function findCalendarItemIndices(calendarItemId) {
            const indicesToReturn = {
                allEventsIndex: -1,
                calendarSourceIndex: -1
            };

            indicesToReturn.allEventsIndex = this._.findIndex(this.all_events,
                e => e.hbData && e.hbData.calendarItem && e.hbData.calendarItem._id === calendarItemId
             );

            indicesToReturn.calendarSourceIndex = this._.findIndex(this.calendarSources[PROJECT_TYPES.CALENDAR_ITEM].events,
                e => e.hbData && e.hbData.calendarItem && e.hbData.calendarItem._id === calendarItemId
             );

            return indicesToReturn;
        },

        handleCreateMeeting: function handleCreateMeeting(props) {
            this.ModalService.openCalendarMeetingModal(props)
                .then(function success(calendarItemAdded) {
                    this.onMeetingCreated(calendarItemAdded);
                }.bind(this))
                .catch(function fail(error) {
                }.bind(this));
        },

        onCreateCalendarItem: function onCreateMeeting(dateTimeStart) {
            const fullcalendarViewObject = this.calendarElement.fullCalendar('getView');
            const allDay = fullcalendarViewObject.name === 'month';
            const meetingModalOptions = {
                isCreateMeeting: true,
                dateTimeStart: dateTimeStart,
                allDay: allDay
            };

            this.handleCreateMeeting(meetingModalOptions);
        },

        onMeetingCreated: function onMeetingCreated(calendarItemAdded) {
            const fcEvent = this.convertCalendarItemToFullCalendarEvent(calendarItemAdded);
            this.all_events.push(fcEvent);
            this.calendarSources[PROJECT_TYPES.CALENDAR_ITEM].events.push(fcEvent);
            this.forceViewToRefreshSources();
        },

        onDeleteCalendarItem: function onDeleteCalendarItem(calEvent) {
            //just a small protection just in case
            if (!(calEvent.hbData && calEvent.hbData.calendarItem && calEvent.hbData.calendarItem._id)) return;

            const calendarItem = calEvent.hbData.calendarItem;

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.none,
                'CALENDAR._DELETE_MEETING_CONFIRMATION_',
                'FREQUENT_BUTTONS._YES_DELETE_',
                'FREQUENT_BUTTONS._CANCEL_',
                'FREQUENT_MESSAGES._ARE_YOU_SURE_').then(function () {

                const defer = this.$q.defer();

                if (this.CalendarService.hasInvitees(calendarItem) ||
                    this.CalendarService.isConnectedToProject(calendarItem)) {
                    this.PopupMessageService.showConfirmPromise(
                        this.PopupMessageService.severityTypes.none,
                        'CALENDAR.NOTIFY_INVITEES_POPUP._CANCEL_BODY_',
                        'CALENDAR.NOTIFY_INVITEES_POPUP._REVIEW_EMAIL_',
                        'CALENDAR.NOTIFY_INVITEES_POPUP._CANCEL_CTA_',
                        'CALENDAR.NOTIFY_INVITEES_POPUP._TITLE_', false, false, true).then(function () {
                        defer.resolve(true);
                    }).catch(function () {
                        defer.resolve(false);
                    });
                } else {
                    defer.resolve(false);
                }

                return defer.promise;
            }.bind(this)).then(function remove(notifyMembers) {
                return this.UsersManager.deleteCalendarsItem(this.user, calendarItem._id, notifyMembers);
            }.bind(this)).then(function success() {
                this.removeMeetingFromCalendar(calEvent.hbData.calendarItem._id);
                this.forceViewToRefreshSources();
            }.bind(this));
        },

        onEditCalendarItem: function onUpdateCalendarItem(calEvent) {
            //just a small protection just in case
            if (!(calEvent.hbData && calEvent.hbData.calendarItem && calEvent.hbData.calendarItem._id)) return;
            //open the meeting dialog in edit mode
            this.ModalService.openCalendarMeetingModal({
                isCreateMeeting: false,
                calendarItemModel: calEvent.hbData.calendarItem
            })
                .then(function success(calendarItemAfterEdit) {
                    //reinsert the new returned value
                    //find the old one and remove it
                    const calEventIndexInAllEvents = this.findCalendarItemIndices(calEvent.hbData.calendarItem._id);
                    if (calEventIndexInAllEvents) {
                        const meetingRemoved = this.removeMeetingFromCalendar(calEvent.hbData.calendarItem._id);
                        if (meetingRemoved) {
                            //create a new one and add it
                            const newFcEvent = this.convertCalendarItemToFullCalendarEvent(calendarItemAfterEdit);

                            this.all_events.push(newFcEvent);
                            this.calendarSources[PROJECT_TYPES.CALENDAR_ITEM].events.push(newFcEvent);
                            this.forceViewToRefreshSources();
                        }
                    }
                }.bind(this))
                .catch(function fail(error) {
                }.bind(this))
                .finally(function finallyHandler() {
                }.bind(this));
        },

        toggleSpace: function toggleSpace(calendar) {
            const spaceId = calendar._id;
            this.toggledSpaces[spaceId] = !this.toggledSpaces[spaceId];
            if (this.toggledSpaces[spaceId]) {
                this.addCalendarSourceToSelected(this.getSpaceSourceId(spaceId));
            } else {
                this.removeCalendarSourceFromSelected(this.getSpaceSourceId(spaceId));
            }

            this.spacesCalendarList = this._getSpacesSidebarList();
            this.persistUiState();
        },

        toggleTeamMember: function toggleTeamMember(teamMemberCalendar) {
            this.toggledTeamMembers[teamMemberCalendar.type] = !this.toggledTeamMembers[teamMemberCalendar.type];
            teamMemberCalendar.enabled = this.toggledTeamMembers[teamMemberCalendar.type];
            if (this.toggledTeamMembers[teamMemberCalendar.type]) {
                this.addCalendarSourceToSelected(teamMemberCalendar.type);
            } else {
                this.removeCalendarSourceFromSelected(teamMemberCalendar.type);
            }
            this.persistUiState();
        },

        addingSpaceClicked: function () {
            this.addingSpace = true;
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.spaces_clicked_on_add_space);
        },

        onGoToWorkspace: function onGoToWorkspace(calEvent) {
            this.stateParams = {
                event_id: calEvent.hbData.calendarItem.event_id,
                workspace_id: calEvent.hbData.calendarItem.workspace_id
            };

            this.$state.go(this.AppStates.root_core_navigation_event_workspace_feed, this.stateParams);
        },

        getCalendlyPublicLink: function getCalendlyPublicLink() {
            this.showCalendlyLoader = true;
            this.UsersManager.getCalendlyPublicLink(this.user).then(function success(resp) {
                this.userIsSubscribeToCalendly = !!resp.data.public_link;
                if (this.userIsSubscribeToCalendly) {
                    this.publicLink = resp.data.public_link + "?utm_source=honeybook&utm_content=company-" + this.user.company._id + "-" + this.user._id;
                }
                this.copyCalendlyLink = "COPY CALENDLY LINK";
            }.bind(this)).finally(function final() {
                this.showCalendlyLoader = false;
                this.honeyBookCalendarList = this._getHoneyBookSidebarList();
            }.bind(this));
        },

        /* Suggestions */
        _shouldShowSuggestions: function _shouldShowSuggestions() {
            this.shouldShowCalendarSuggestion = false;

            if (this.calendarProvider) {
                return;
            }

            if (this.user.isInTrial() || this.user.companyHasSubscription()) {
                const smartSuggestionsHash = this.SuggestionsService.getSuggestionsPersistance();

                if (smartSuggestionsHash.connectCalendar) {
                    return;
                }

                this.shouldShowCalendarSuggestion = true;
                this.calendarSuggestion = this.SuggestionsService.getSuggestion('calendar');
            }
        },

        suggestionViewed: function suggestionViewed() {
            const smartSuggestionsHash = this.SuggestionsService.getSuggestionsPersistance();
            smartSuggestionsHash.connectCalendar = true;
            this.SuggestionsService.setSuggestionPersistance(smartSuggestionsHash);
        },

        suggestionConnectCal: function suggestionConnectCal() {
            this.openSyncModal();
        },

        selectAllCalendars: function selectAllCalendars() {
            this.togglePayments();
            this.toggleLeadEvents();

            if (this.shouldShowCalendarImports()) {
                const googleCal = this.calendarProvider.calendar_importer.calendar_imports;
                googleCal.forEach(function (cal) {
                    this.toggleImportedCalendar(cal);
                }.bind(this));
            }
        },

        selectAllVenues: function selectAllVenues() {
            if (!this.companySpaces) {
                return;
            }

            this.companySpaces.forEach(function (space) {
                this.toggleSpace(space);
            }.bind(this));
        },

        handleGoogleListSelectAll: function handleGoogleListSelectAll() {
            console.log('coming soon');
        },

        toggleHoneyBookCalendar: function toggleHoneyBookCalendar(calendar) {
            switch (calendar.type) {
                case this.Enums.calendarTypes.payments:
                    this.togglePayments();
                    break;
                case this.Enums.calendarTypes.tentativeProjects:
                    this.toggleLeadEvents();
                    break;
            }
            this.honeyBookCalendarList = this._getHoneyBookSidebarList();
        },

        changeCalendarColor: function changeCalendarColor(color, type) {
            this.UsersManager.updateCalendarColor(this.user, type, color).then(function success(resp) {
                if(type === 'tentative_projects_color') {
                    document.documentElement.style.setProperty('--tentative-event-color', color);
                    document.documentElement.style.setProperty('--tentative-event-color-light', this.UIUtils.hexToRgbAWithAlpha(color, 0.6));
                }
              this.honeyBookCalendarList = this._getHoneyBookSidebarList();
              this.teamMemberCalendarList = this._getTeamMemberCalendarList();
              this.spacesCalendarList = this._getSpacesSidebarList();
              this.allCalendarEventIds = {};
              this.all_events = [];
              this.selectedCalendarSources.forEach(function (CalendarSource) {
                  this.calendarElement.fullCalendar('removeEventSource', CalendarSource);
                  if (CalendarSource) {
                      CalendarSource.events = [];
                      if (CalendarSource.eventsHash) {
                          CalendarSource.eventsHash = {};
                      }
                  }
              }.bind(this));
              this.fetchGoogleEventsTimeSpan.resetTimeSpan();
              this.fetchTimeSpan.resetTimeSpan();
              const providerPromise = this.getCalendarProvider();
              const itemsPromise = this.UsersManager.getCalendarsItems(this.user);
              this.$q.all([providerPromise, itemsPromise]).then(function success(res) {
                  const cal = res[1];
                  this.calendarItems = cal.data.calendar_items.filter(function(item) {
                      return item.date_time_start || item.date_start;
                  });
                  this.initCalendarData(null, null);
                  this.filterEvents();
                  this.forceViewToRefreshSources();
                  this.getEventsForTimeRange(this.curStartDate, this.curEndDate);
              }.bind(this)).catch(function error(resp) {
                  if (resp && resp.data && resp.data.error_message) {
                      this.lastError = resp.data.error_message;
                  } else {
                      this.lastError = this.$translate.instant('ERRORS.SERVER_API._UNKNOWN_');
                  }
              }.bind(this));
          }.bind(this))
                .catch(function error(resp) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                }.bind(this));
        },

        goToProject: function goToProject(event_id) {
            this.$state.go(this.AppStates.root_core_navigation_event_overview, {event_id: event_id});
        },

        goToViewPayment: function goToViewPayment(payload) {
            this.$state.go(this.AppStates.root_core_navigation_event_workspace_payments, {
                event_id: payload.data.eventId,
                workspace_id: payload.data.coupleCardId
            });
        },

        handlePopoverCallback: function handlePopoverCallback(payload) {
            const popoverParams = {source: "calendar_popover", secondary_source: 'calendar'};
            switch (payload.type) {
                case "CREATE_MEETING":
                    this.onCreateCalendarItem(payload.data);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_meeting_on_calendar_popover, popoverParams);
                    break;
                case "CREATE_PROJECT":
                    this.onCreateEvent(payload.data);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_project_on_calendar_popover, popoverParams);
                    break;
                case "EDIT_HB_MEETING":
                    this.onEditCalendarItem(payload.data);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_meeting_on_calendar_popover, popoverParams);
                    break;
                case "DELETE_HB_MEETING":
                    this.onDeleteCalendarItem(payload.data);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_meeting_on_calendar_popover, popoverParams);
                    break;
                case "GO_TO_PROJECT":
                    this.goToProject(payload.data.eventId);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.view_project_on_calendar_popover, popoverParams);
                    break;
                case "VIEW_PAYMENT":
                    this.goToViewPayment(payload);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.view_payment_on_calendar_popover, popoverParams);
                    break;
            }
        },

        updateSpaces: function updateSpaces() {

            const newSpacesIds = this.user.company.company_spaces.map(function (space) {
                return space._id;
            });

            // find the ones that were removed
            const removedSpacesIds = this._.difference(this.companySpacesToIds, newSpacesIds);

            // remove spaces sources from calendar
            removedSpacesIds.forEach(function (spaceId) {
                const spaceSourceId = this.getSpaceSourceId(spaceId);
                this.toggledSpaces[spaceId] = undefined;
                this.removeCalendarSourceFromSelected(spaceSourceId);
                delete this.calendarSources[spaceSourceId];
            }.bind(this));

            // add new spaces
            const addedSpacesIds = this._.difference(newSpacesIds, this.companySpacesToIds);
            addedSpacesIds.forEach(function (spaceId) {
                const spaceSourceId = this.getSpaceSourceId(spaceId);

                // add to calendar sources
                this.calendarSources[spaceSourceId] = {
                    mySourceId: spaceSourceId,
                    events: []
                };

                // add to actual calendar
                this.addCalendarSourceToSelected(spaceSourceId);

            }.bind(this));

            // set updated spaces
            this.companySpaces = this.user.company.company_spaces;

            // update spaced ids
            this.companySpacesToIds = newSpacesIds;

            // update sidebar list
            this.spacesCalendarList = this._getSpacesSidebarList();
        },

        saveSpaces: function saveSpaces(spaces) {
            const _spaces = spaces.map(function (space) {
                return {
                    _id: space._id,
                    name: space.label
                };
            });

            return this.CompaniesManager.updateCompanySpaces(this.company, _spaces).then(function () {
                this.updateSpaces();
            }.bind(this));
        },

        _formatSpacesData: function _formatSpacesData(spaces) {
            return spaces.map(function (space) {
                return {
                    _id: space._id,
                    label: space.name,
                    editable: true
                }
            });
        },

        editSpaces: function editSpaces() {
            const spacesAnalyticsArgs = {source: "company_spaces_modal", secondary_source: 'calendar'};
            if (this.loadingSpaces) {
                return;
            }

            this.loadingSpaces = true;

            this.CompaniesManager.getCompanySpaces(this.company).then(function (res) {
                this.ModalService.openEditSpacesModal(
                    this._formatSpacesData(this.company.company_spaces), this.saveSpaces.bind(this), spacesAnalyticsArgs);
                this.loadingSpaces = false;
            }.bind(this)).catch(function () {
                this.loadingSpaces = false;
            }.bind(this));

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_spaces__modal_opened, spacesAnalyticsArgs);
        },

        onCalendarToolbarEvent: function onCalendarToolbarEvent(event, data) {

            switch (event) {
                case 'changeView':
                    this.calendarElement.fullCalendar('changeView', data.key);
                    this.calendarView = data;
                    this.persistUiState();
                    break;
                case 'today':
                    this.calendarElement.fullCalendar('today');
                    break;
                case 'move':
                    this.calendarElement.fullCalendar(data);
                    break;
                case 'gotoDate':
                    this.calendarElement.fullCalendar('gotoDate', data);
                    break;

            }
            this.updateCalendarTitle();
        },

        updateCalendarTitle: function updateCalendarTitle() {
            const cal = this.calendarElement.fullCalendar('getView');
            const date = this.calendarElement.fullCalendar('getDate');
            this.$scope.calendarAbstractVm.hbCalendar.title = cal.title;
            this.$scope.calendarAbstractVm.hbCalendar.calView = cal.type === 'month' ? 'month' : 'day';
            this.$scope.calendarAbstractVm.hbCalendar.date = date.toDate();
        },

        webSocketCalendarUpdate: function webSocketCalendarUpdate(json) {
            let socketData = {};
            try {
                socketData = JSON.parse(json).data;
            } catch (e) {
                console.error(`webSocketCalendarUpdate json error: ${e}`);
                console.error(`webSocketCalendarUpdate json: ${json}`);
            }

            switch (socketData.state) {
                case 'created':
                    this.UsersManager.getCalendarItem(this.user, socketData.calendar_item_id).then((res) => {
                        const calEventIndices = this.findCalendarItemIndices(res.data._id);
                        if (calEventIndices.allEventsIndex === -1 && calEventIndices.calendarSourceIndex === -1) {
                            this.onMeetingCreated(res.data);
                        }
                    });
                    break;
                case 'rescheduled':
                    this.UsersManager.getCalendarItem(this.user, socketData.calendar_item_id).then((res) => {
                        this.removeMeetingFromCalendar(res.data._id);
                        this.onMeetingCreated(res.data);
                    });
                    break;
                case 'canceled':
                    const meetingRemoved = this.removeMeetingFromCalendar(socketData.calendar_item_id);
                    if (meetingRemoved) {
                        this.forceViewToRefreshSources();
                    }
                    break;
                default:
                    console.error('webSocketCalendarUpdate json error: scoketData has no state or invalid one');
                    break;
            }
        },

        removeMeetingFromCalendar: function removeMeetingFromCalendar(calendarId) {
            const calEventIndices = this.findCalendarItemIndices(calendarId);
            if (calEventIndices.allEventsIndex !== -1 && calEventIndices.calendarSourceIndex !== -1) {
                this.all_events.splice(calEventIndices.allEventsIndex, 1);
                this.calendarSources[PROJECT_TYPES.CALENDAR_ITEM].events.splice(calEventIndices.calendarSourceIndex, 1);
                return true;
            }

            return false;
        },

        addNylasCalendarIntegration: function addNylasCalendarIntegration($event) {
            this.ModalService.openSelectEmailProviderModal('calendar')
                .then(function (res) {
                    if (res.isGoogle) {
                        this.UserService.connectToAGoogleAccount("", 'calendar');
                    }
                }.bind(this));
        },
    });

}());
