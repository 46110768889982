Directives.TaskListDirective = function TaskListDirective() {

    // @ngInject
    function TaskListDirectiveControllerFunc($injector, $scope, moment, PopupMessageService, AppStates,
                                             AnalyticsService, TasksManager, CompaniesManager, $timeout, ModalService, _,
                                             WorkspacesManager, UiPersistenceService, $q, TemplatesManager, UsersManager, WorkspaceFileService, FeaturesService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TaskListDirectiveController';
        this.PopupMessageService = PopupMessageService;
        this.AppStates = AppStates;
        this.eventSearchResults = false;
        this.eventSearchTxt = this.task.project_name;
        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.TasksManager = TasksManager;
        this.company = CompaniesManager.getCurrCompany();
        this.isEditMode = false;
        this.$timeout = $timeout;
        this.ModalService = ModalService;
        this._ = _;
        this.WorkspacesManager = WorkspacesManager;
        this.UiPersistenceService = UiPersistenceService;
        this.TemplatesManager = TemplatesManager;
        this.$q = $q;
        this.WorkspaceFileService = WorkspaceFileService;

        this.user = UsersManager.getCurrUser();


        if(!this.showProjectColumn) {
            this.workspace = this.WorkspacesManager.getWorkspace(this.task.workspace_id, true);
            this.showAllMembersDropDown = false;
        }

        if (!this.task.due_date) {
            this.dueDatePlaceholder = "Pick a Due Date";
        } else {
            this.updatePlaceholder(this.task.due_date);
        }
        this.dueDate = null;
        this.task.approvalPending = false;
        this.projectSelected = this.task.project_name ? "selected" : "";
        this.approveClicked = false;
        this.confirmApproveClicked = false;

        this.hasDefaultDescription = false;

        if (this.task.description) {
            if (this.task.description === 'New Task') {
                this.hasDefaultDescription = true;
            } else {
                this.hasDefaultDescription = false;
            }
        }

        this.approveText = this.task.type === 'pipeline' ? 'Move' : 'Send it';

    }

    var TaskListDirectiveController = Class(Controllers.BaseController, {
        constructor: TaskListDirectiveControllerFunc,

        workspaceTeamMembers: function workspaceTeamMembers() {
            if (!this.workspace || !this.workspace.members) {
                return [];
            }
            return this.workspace.members.filter(
                function (member) {
                    return member.company._id === this.company._id;
                }.bind(this)
            );
        },

        projectOwner: function projectOwner() {
            if (!this.workspace || !this.workspace.members) {
                return null;
            }
            return this.workspace.members.find(
                function (member) {
                    return member._id === this.workspace.creator_id;
                }.bind(this)
            );
        },

        taskAssignee: function taskAssignee() {
            if (!this.workspace || !this.workspace.members) {
                return null;
            }
            if (
                this.workspaceTeamMembers().some(
                    function (workspaceTeamMember) {
                        return workspaceTeamMember._id === this.task.assignee._id;
                    }.bind(this)
                )
            ) {
                return this.task.assignee;
            }
            return this.projectOwner();
        },

        updateTaskProjectId: function updateTaskProjectId(projectId) {
            this.task.project_id = projectId;
            this.updateTask(this.task);
            this.projectSelected = this.task.project_id ? "selected" : "";
        },

        handleClickBackground: function handleClickBackground($event) {
            var targetElm = angular.element($event.currentTarget);
            if (this.task.source !== "workflow") {
                this.isEditMode = true;
                this.$timeout(function () {
                    targetElm.find('.search-box-input').focus();
                });

            }
        },

        handleClickOutside: function handleClickOutside() {
            this.isEditMode = false;
            this.showAllMembersDropDown = false;
        },

        removeTask: function removeTask() {
            if (this.task.type === "task") {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'WORKFLOW.ALERTS._SKIP_WORKFLOW_TASK',
                    function clickedYeah() {
                        this.deleteTask(this.task);
                    }.bind(this),
                    function clickedNay() {
                    }.bind(this));
            } else {
                this.deleteTask(this.task);
            }
        },

        toggleTaskStatus: function toggleTaskStatus() {
            if (this.task.status === "pending" || this.task.status === "required") {
                this.task.status = "completed";
                this.task.prevStatus = this.task.status;
                return true;
            } else if ((this.task.source !== 'workflow' && this.task.status === "completed") || (this.task.status === "approved")) {
                this.task.status = "pending";
                this.task.prevStatus = this.task.status;
                return true;
            }
            return false;
        },

        toggleTaskAndUpdate: function toggleTaskAndUpdate() {
            if (this.toggleTaskStatus()) {
                this.updateTaskAndMoveToTheBottomOfTheList(this.task);
            }
        },

        confirmApproveTask: function confirmApproveTask() {
            if (this.confirmApproveClicked) {
                return;
            }
            this.confirmApproveClicked = true;
            if ((this.task.status === "pending" || this.task.status === "required") && this.task.type !== "task" && this.task.type !== "pipeline") {
                this.task.approvalPending = true;
            } else if ((this.task.status === "pending" || this.task.status === "required") && (this.task.type === "task" || this.task.type === "pipeline")) {
                this.toggleTaskAndUpdate();
            } else if ((this.task.status === "completed") || (this.task.status === "approved")) {
                this.toggleTaskAndUpdate();
            }
        },

        cancelApproveTask: function cancelApproveTask() {
            this.confirmApproveClicked = false;
            this.task.approvalPending = false;
        },

        approveTask: function approveTask() {
            this.approveClicked = true;
            this.task.approvalPending = false;
            this.toggleTaskAndUpdate();
        },

        updateTaskDueDate: function updateTaskDueDate() {
            this.task.due_date = this.formatForEventAPI(this.dueDate);
            this.updatePlaceholder(this.task.due_date);
            this.dueDate = null;
            this.updateTask(this.task);
        },
        updatePlaceholder: function updatePlaceholder(dueDate) {
            this.dueDatePlaceholder = this.moment(dueDate, 'YYYY-MM-DD').calendar(null, {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: 'ddd, MMM D, YYYY',
                nextWeek: 'ddd, MMM D, YYYY',
                sameElse: 'ddd, MMM D, YYYY'
            });
            if (this.dueDatePlaceholder === "Today") {
                this.dueStatus = "today";
                return;
            }
            if (this.moment(dueDate) < this.moment()) {
                this.dueStatus = "overdue";
                return;
            }
            this.dueStatus = "";
        },

        formatForDatePicker: function formatForDatePicker(date) {
            if (date && this.moment(date, 'YYYY-MM-DD').isValid()) {
                return this.moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
            }
        },

        formatForEventAPI: function formatForEventAPI(date) {
            if (date && this.moment(date, 'MMM DD, YYYY').isValid()) {
                return this.moment(date, 'MMM DD, YYYY').format('YYYY-MM-DD');
            }
        },

        updateTask: function updateTask(task) {
            this.TasksManager.updateTask(task)
                .then(function success(resp) {
                    angular.extend(this.task, resp.data);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.task_mgmt_update_task, {origin: this.showProjectColumn ? "task_management" : "workspace"});
                    this.refreshTaskCounts();
                }.bind(this));
        },

        shouldDisableEdit: function shouldDisableEdit() {
            return this.task.source === 'workflow';
        },

        activeFieldFocus: function activeFieldFocus(status) {
            if (status !== 'completed') {
                this.isEditMode = true;
            }
        },

        activeTextAreaFocus: function activeTextAreaFocus($event) {
            this.isEditMode = true;
            var textAreaVal = $event.target.value;
            if (textAreaVal === 'New Task') {
                this.task.description = '';
            }
        },

        prepareOpenPreviewEmail: function prepareOpenPreviewEmail() {
            if (this.openingPreviewEmail) {
                return;
            }

            this.openingPreviewEmail = true;
            this.workspace = this.WorkspacesManager.getWorkspace(this.task.workspace_id, true);
            this.registerOnce(this.workspace, 'success', function () {
                this.openPreviewEmail();
                this.openingPreviewEmail = false;
            }.bind(this));

        },

        openPreviewEmail: function openPreviewEmail() {
            var taskId = this.task._id;
            this.TasksManager.getEmail(taskId).then(function success(result) {
                var currentTemplate = result.data;
                var usersListForEmail = this.workspace.members.filter(function (user) {
                    return (!!user.email && user._id !== this.company.owner._id && !user.isMyTeamMember());
                }.bind(this));

                var options = {
                    users: usersListForEmail,
                    subject: currentTemplate.subject,
                    body: currentTemplate.html_body,
                    generalFiles: currentTemplate.file_attachments,
                    imageFiles: currentTemplate.image_attachments,
                    senderFunction: function senderFunction(props) {
                        var user = props.users;
                        var subject = props.subject;
                        var body = props.body;
                        var isLast = props.isLast;
                        var params = props.params;
                        var attachedFiles = props.attachedFiles;
                        var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.workflowApprovalDependedStep);
                        this.task.subject = subject;
                        this.task.body = body;
                        this.task.attachedFiles = attachedFiles;
                        if (this.task.status === 'required' && this.task.has_depended_step && (!uiPersistenceData || (uiPersistenceData && !uiPersistenceData.dontShow))) {
                            return this.PopupMessageService.showConfirmWithDontShowAgainPromise(this.PopupMessageService.severityTypes.warning,
                                'WORKFLOW.WORKFLOW_STEPS_EDITOR.DO_NOT_SHOW_AGAIN._MSG_COPY_', null, null, null, this.UiPersistenceService.keys.workflowApprovalDependedStep).then(
                                function confirmCallback() {
                                    this.task.dontShowPopUp = true;
                                    return this.updateTaskAndMoveToTheBottomOfTheList(this.task);
                                }.bind(this),
                                function dismissCallback() {
                                    return this.$q.reject("closeEmailEditor");
                                }.bind(this));
                        } else {
                            return this.updateTaskAndMoveToTheBottomOfTheList(this.task);
                        }
                    }.bind(this),
                    config: this.emailEditorConfig(),
                    showScheduleOption: false
                };

                this.WorkspaceFileService.showEmailEditorModal(options);
            }.bind(this), function fail(e) {
                console.log(e);
            }.bind(this));
        },

        assignTaskToTeamMember: function assignTaskToTeamMember(teamMember) {
            this.task.assignee = teamMember.dataOnly();
            this.updateTask(this.task);
        },

        emailEditorConfig: function emailEditorConfig() {
            var config = {};
            config.ckeditor = {
                includeToolbarItems: ['AddClientName', 'AddEmailSignature', 'addFileAttachment'],
                height: "300px",
            };
            if (this.user.calendly_url) {
                config.ckeditor.includeToolbarItems.push('addCalendlyLink');
            }
            config.dontLoopOverRecipients = true;
            config.sendButtonText = 'WORKFLOW.APPROVAL._APPROVE_AND_SEND_NOW_';
            config.cancelButtonText = 'WORKFLOW.APPROVAL._GO_BACK_';
            config.editorType = "task email";
            config.workspace = this.workspace;
            return config;
        }

    });

    return {
        templateUrl: 'angular/app/modules/core/features/tasks/task_list/task_list_directive_template.html',
        controller: TaskListDirectiveController,
        controllerAs: 'taskListVm',
        bindToController: true,
        scope: {
            task: '=',
            deleteTask: '&',
            updateTaskAndRemoveFromList: '&',
            updateTaskAndMoveToTheBottomOfTheList: '&',
            refreshTaskCounts: '&',
            showProjectColumn: '=',
            onLoad: '&'
        }
    };
};
