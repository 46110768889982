(function () {
    "use strict";

    Directives.NewCustomizeUrlDirective = function NewCustomizeUrlDirective() {

        // @ngInject
        function NewCustomizeUrlDirectiveCtor($scope, $injector, $timeout, $window, ModalService, $q,
                                              UsersManager, CompaniesManager, CompanyService, DeviceService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'NewCustomizeUrlDirective';
            this.ModalService = ModalService;
            this.$timeout = $timeout;
            this.user = UsersManager.getCurrUser();
            this.CompaniesManager = CompaniesManager;
            this.CompanyService = CompanyService;
            this.company = CompaniesManager.getCurrCompany();
            this.$q = $q;
            this.$window = $window;
            this.isCopying = false;
            this.url = '';
            this.isMobile = DeviceService.checkIfMobileSize();

            if (this.company.wasFullyFetched()) {
                this._initCustomizationData();
            } else {
                this.registerOnce(this.company, 'success', function () {
                    this._initCustomizationData();
                }.bind(this));
            }

            this.companyHasAnySubscription = this.user.companyHasAnySubscription();
        }

        var NewCustomizeUrlDirective = Class(Controllers.BaseController, {
            constructor: NewCustomizeUrlDirectiveCtor,

            onCopyClicked: function onCopyClicked(e) {
                this.isCopying = !this.isCopying;
            },

            onSendClicked: function onSendClicked() {
                this.ModalService.openClientPortalSendLinkModal();
            },

            onCopiedSuccessfully: function onCopiedSuccessfully() {
                this.$timeout(function () {
                    this.isCopying = false;
                }.bind(this), 3000);
            },

            _initCustomizationData: function _initCustomizationData() {
                this.wasInitialUrlClaimed = this.company.company_url_prefixes && this.company.company_url_prefixes.length;
                this.domain = '.' + this.company.company_url_domain + '/app/';
                this.activeCustomDomain = this.CompanyService.getActiveCustomDomain(this.company.company_custom_domains);

                this.url = 'https://' + (this.activeCustomDomain ? this.activeCustomDomain.full_hostname : this.hbPortalName + this.domain);
            }
        });

        return {
            scope: {
                company: '=',
                toggleDisablePreview: '&',
                hbPortalName: '='
            },
            templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/customize_url/new_client_portal_customize_url.html',
            controller: NewCustomizeUrlDirective,
            controllerAs: 'newCustomizeUrlVm',
            bindToController: true
        };
    };
}());
