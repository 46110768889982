(function () {
  "use strict";

  // @ngInject
  function ProjectDetailsNotesModalControllerCtor($scope, $injector, $modalInstance, project, company) {

    this.constructor.$super.call(this, $scope, $injector);
    this._objectType = 'ProjectDetailsNotesModalControllerCtor';
    this.$modalInstance = $modalInstance;

    this.project = project;
    this.company = company;
  }

  Controllers.ProjectDetailsNotesModalController = Class(Controllers.BaseController, {
    constructor: ProjectDetailsNotesModalControllerCtor,

    close: function close() {
      this.$modalInstance.close();
    }
  });
}());
