// @ngInject
Directives.ReadableAgreementDirective =  function ReadableAgreementDirective($, uuid4, AutoInputSizeService, InputFieldsService, $translate, $timeout,
                                                                             AnalyticsService, UsersManager, OnboardingService) {

    // @ngInject
    function ReadableAgreementDirectiveControllerFunc($scope, $injector, WorkspaceFileService, WorkspaceFilesManager, $q, PopupMessageService, $document) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReadableAgreementDirectiveController';

        var self = this;
        this.OnboardingService = OnboardingService;

        this.currUser = UsersManager.getCurrUser();
        this.isModeratorMode = this.fileModel.isModeratorMode();
        this.isClientMode = this.fileModel.isClientMode();
        this.shouldShowfloatingBanner = true;

        this.showNumberOfFields = function showNumberOfFields() {
            this.floatingBannerLeft = '';
            this.floatingBannerRight = '';
            this.floatingBannerHighlighted = '';
            this.shouldShowfloatingBanner = true;
            if (this.fileModel.isCanceled()) {
                this.shouldShowfloatingBanner = false;
                this.allFieldsAreValidSignMark(false);
            } else if (this.isClientMode || this.isPreviewMode) {
                if (this.clientIstNotTheSignatory() && this.fileModel.agreement.contract_signatures.length > 1) {
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._NOT_THE_SIGNATORY_');
                } else if (this.numOfClientFieldsLeft > 0) {
                    this.allFieldsAreValidSignMark(false);
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._NUMBER_OF_FIELDS_LEFT_LEFT_');
                    this.floatingBannerRight = $translate.instant('AGREEMENT.LABELS._NUMBER_OF_FIELDS_LEFT_RIGHT_');
                    if (this.numOfClientFieldsLeft > 1) {
                        this.plural = "fields";
                    } else {
                        this.plural = "field";
                    }
                    this.floatingBannerHighlighted = this.numOfClientFieldsLeft + " highlighted " + this.plural;
                } else if (this.isClientMode && this.fileModel.isProposal() && this.fileModel.agreement.isAgreementSignedByCurrUser()) {
                    if (this.fileModel.payments_container.getUnpaidPayments().length > 0 && !this.fileModel.payments_container.hasPaidPayments() && !this.fileModel.hasOnlyNotCompletedMilestonePayments()) {
                        this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._SIGNED_READY_TO_PAY_');
                        this.floatingBannerHighlighted = $translate.instant('AGREEMENT.LABELS._SIGNED_READY_TO_PAY_HIGHLIGHTED_');
                    } else {
                        this.shouldShowfloatingBanner = false;
                        this.allFieldsAreValidSignMark(false);
                    }
                } else if (this.isClientMode && this.fileModel.isAgreement() && this.fileModel.agreement.isAgreementSignedByCurrUser()) {
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._CONTRACT_SIGNED_');
                } else if (this.allClientFields && this.allClientFields.length === 0) {
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._NO_FIELDS_READ_AND_SIGN_');
                    this.floatingBannerHighlighted = $translate.instant('AGREEMENT.LABELS._NO_FIELDS_READ_AND_SIGN_HIGHLIGHTED_');
                    this.allFieldsAreValidSignMark(true);
                } else if (this.isViewOnlyMode) {
                    this.allFieldsAreValidSignMark(false);
                } else if (!this.fileModel.agreement.isAgreementSignedByClient() && this.fileModel.agreement.contract_signatures.length > 1) {
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._ALL_FIELDS_FILLED_');
                    this.floatingBannerHighlighted = $translate.instant('AGREEMENT.LABELS._ALL_FIELDS_FILLED_HIGHLIGHTED_');
                    this.allFieldsAreValidSignMark(true);
                } else {
                    this.shouldShowfloatingBanner = false;
                    this.allFieldsAreValidSignMark(false);
                }
            } else if (this.isModeratorMode || this.isOwnerMode) {
                if (this.fileModel.agreement.isAgreementSignedByClient() && !this.fileModel.agreement.isAgreementSignedByVendor()) {
                    this.floatingBannerLeft = $translate.instant('AGREEMENT.LABELS._CLIENT_HAS_SIGNED_AGREEMENT_');
                    this.floatingBannerHighlighted = $translate.instant('AGREEMENT.LABELS._CLIENT_HAS_SIGNED_AGREEMENT_HIGHLIGHTED_');
                    this.allFieldsAreValidSignMark(true);
                } else {
                    this.shouldShowfloatingBanner = false;
                    this.allFieldsAreValidSignMark(false);
                }
            } else {
                this.shouldShowfloatingBanner = false;
                this.allFieldsAreValidSignMark(false);
            }
        };

        this.floatingBannerAction = function floatingBannerAction() {
            if (this.numOfClientFieldsLeft > 0) {
                this.scrollToNextClientField();
            } else if (
                this.fileModel.agreement.isAgreementSignedByClient()
                && (this.isClientMode || this.isPreviewMode)
            ) {
                WorkspaceFilesManager.updateStatus(
                    this.fileModel,
                    WorkspaceFileService.fileStatuses.payments_seen
                ).then(function updateStatusSuccess() {
                    WorkspaceFileService.gotoPayView(
                        this.fileModel._id,
                        null,
                        {},
                        { location: "replace" }
                    );
                }.bind(this));
            } else if (
                this.fileModel.agreement.isAgreementSignedByClient()
                && !this.fileModel.agreement.isAgreementSignedByVendor()
                && (this.isModeratorMode || this.isOwnerMode)
            ) {
                this.scrollToSignatures(100);
            } else {
                this.scrollToSignatures(0);
            }
        };

        this.allFieldsAreValidSignMark = function allFieldsAreValidSignMark(val) {
            val ? angular.element('#hb-agreement').addClass('hb-agreement--valid-to-sign') : angular.element('#hb-agreement').removeClass('hb-agreement--valid-to-sign');
        };

        this.saveAgreementByClient = function saveAgreementByClient(fullHTML) {
            if (this.isViewOnlyMode) {
                return;
            }
            this.fileModel.agreement.html_body = fullHTML;
            var promise = this.fileModel.updateAgreement();
            promise.then(function updateAgreementSuccess() {
                // self.afterSaveAgreement(by_input, divIdToReload)
                })
                .catch(function fail(resp) {
                    if (resp && resp.data && resp.data.error_type === 'HBUserError') {
                        PopupMessageService.showAlert(PopupMessageService.severityTypes.error, resp.data.error_message);
                        AnalyticsService.trackError(this, AnalyticsService.analytics_events.agreement_failed_to_save_file, resp);
                    } else {
                        PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }
                });
        };

        this.clientIstNotTheSignatory = function clientIstNotTheSignatory() {
            var contractSignatures = this.fileModel.agreement.contract_signatures;
            if(!contractSignatures){
                return;
            }
            var isSignedByCurrentUser = contractSignatures.filter(function (user) {
                if (this.currUser._id === user.signee_id) {
                    return user;
                }
            }.bind(this));
            return !isSignedByCurrentUser.length;
        };

        this.getClassesForInputName = function getClassesForInputName(name) {
            var classes = [];
            var field = InputFieldsService.getField(name);
            if (field.isClientField()){
                classes.push('client-field');
                classes.push('branded-agreement-input');
            } else {
                classes.push('vendor-field');
            }

            if (field.isRequiredField()) {
                classes.push('field-required');
                classes.push('branded-agreement-input');
            }
            return classes;
        };

        var directiveId = uuid4.generate();
        WorkspaceFileService.addFileValidation(directiveId, function agreementValidation() {
            return $q(function agreementValidationPromise(resolve, reject) {

                var $firstEmptyField = null;
                $('div#agreement input:text[value=""]').each(function (idx, input) {
                    var $input = $(input);
                    var field = InputFieldsService.getField($input.attr('name'));

                    if (field.isRequiredField() === undefined || field.isRequiredField() === false) {
                        return;
                    }
                    if (self.isOwnerMode && !field.isClientField() ||
                        (!self.isOwnerMode && field.isClientField()) ||
                        (self.isPreviewMode && field.isClientField())) { // we know this is a requiered field by now
                        if ($firstEmptyField === null) {
                            $firstEmptyField = $input;
                        }
                    }
                    $input.addClass('validation-error');
                });

                if ($firstEmptyField === null) {
                    resolve();
                } else {
                    if (self.isOwnerMode) {
                        AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_vendor_signature_locked_fields);
                    } else if (!this.fileModel.isAgreementSigned()) {
                        AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_client_signature_locked_fields);
                    } else {
                        AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_client_signature_locked_accept_changes);
                    }

                    // This gap is because if we are at the top of the document and the sticky header is not activated we need to scroll
                    var gap = $document.scrollTop() === 0 ? 225 : 150;
                    if (this.currUser.shouldSeeTrialWarnings()) {
                        gap = gap + 60; // plus the onboarding height.
                    }
                    // Using document to scroll to the input field and not scrollParent() method because the CKEditor is scrollable as well
                    // and the scrollParent() returns it. The CKEditor never has a scroll so we should always use the document.
                    $document.scrollTo(null, $firstEmptyField.offset().top - gap, 300);
                    reject();
                }
            }.bind(this));
        }.bind(this), 300);


        this.scrollToNextClientField = function scrollToNextClientField() {
            var $firstEmptyField = null;
            var $element = angular.element("#agreement");

            $element.find("input[value=\"\"].client-field").each(function (idx, input) {
                var $input = $(input);
                var field = InputFieldsService.getField($input.attr('name'));

                if (field.isRequiredField() === undefined || field.isRequiredField() === false) {
                    return;
                }
                if (self.isOwnerMode && !field.isClientField() ||
                    (!self.isOwnerMode && field.isClientField()) ||
                    (self.isPreviewMode && field.isClientField())) { // we know this is a requiered field by now
                    if ($firstEmptyField === null) {
                        $firstEmptyField = $input;
                        return;
                    }
                }
            });
            var gap = self.isPreviewMode ? 225 : 150;
            if (this.currUser.shouldSeeTrialWarnings()) {
                gap = gap + 60; // plus the onboarding height.
            }
            $document.scrollTo(null, $firstEmptyField.offset().top - gap, 300);
            AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_floating_bubble_scroll_to_next_required_field);

        };

        this.scrollToSignatures = function scrollToSignatures(gap) {
            var $element = angular.element(".hb-signatures");
            if ($element.length > 0) {
                $element.scrollParent().scrollTo(null, $element.offset().top - gap, 300);
                AnalyticsService.track(this, AnalyticsService.analytics_events.agreement_floating_bubble_scroll_to_signature);
            }
        };

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(directiveId);

            $document.off('scroll.readableAgreement.honeybook');
        });
    }

    var ReadableAgreementDirectiveController = Class(Controllers.BaseController, {
        constructor: ReadableAgreementDirectiveControllerFunc
    });

    return {
        scope:{
            fileModel: '=fileModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isViewOnlyMode: '=isViewOnlyMode',
            isEditableMode: '=isEditableMode',
            isWaitingForAccept: '=isWaitingForAccept'
        },
        templateUrl : 'angular/app/modules/core/features/agreement/readable_agreement/readable_agreement_directive_template.html',
        controller : ReadableAgreementDirectiveController,
        controllerAs : 'readableAgreementVm',
        bindToController : true,
        link: function(scope, $element) {
            var self = this;
            var placeHolderSelector = "#agreement";

            scope.$watch('readableAgreementVm.isEditableMode', function (newVal) {
                self.setAgreementViewForClient();
            });

            scope.$watch('readableAgreementVm.isWaitingForAccept', function (newVal) {
                self.setAgreementViewForClient();
            });

            scope.$watch('readableAgreementVm.fileModel.agreement.contract_signatures.length', function (newVal) {
                if (newVal) {
                    var readableAgreementVm = scope.readableAgreementVm;
                    readableAgreementVm.showNumberOfFields();
                }
            });

            self.getEditableAgreementViewFlag = function getEditableAgreementViewFlag() {
                var readableAgreementVm = scope.readableAgreementVm;

                return !readableAgreementVm.fileModel.isCanceled() && (!readableAgreementVm.isOwnerMode && !readableAgreementVm.isViewOnlyMode &&
                    ((readableAgreementVm.fileModel.isBooked() && readableAgreementVm.isWaitingForAccept) || !readableAgreementVm.fileModel.isBooked() ||
                        !readableAgreementVm.fileModel.agreement.isAgreementSignedBySomeone()) &&
                    readableAgreementVm.fileModel.isLatestVersion());
            };

            self.setAgreementViewForClient = function setAgreementViewForClient(){
                var readableAgreementVm = scope.readableAgreementVm;
                var agreement = readableAgreementVm.fileModel.agreement;

                if (!agreement.html_body) {
                    return;
                }

                var $placeholder = $element.find(placeHolderSelector);

                var agreementDom = $placeholder.html(agreement.html_body);

                agreementDom.find('#img_logo').load(function(){
                    scope.$applyAsync(function(){
                        self.reRenderClientFields();
                    });
                });

                var editableAgreementView = self.getEditableAgreementViewFlag();

                var allFields = agreementDom.find('input');
                readableAgreementVm.numOfClientFieldsLeft = 0;
                allFields.each(function(index){
                    var $input = $(allFields[index]);
                    var field = InputFieldsService.getField($input.attr('name'));
                    var editableInput = editableAgreementView && field.isClientField();

                    $input.removeClass('client-field vendor-field field-required');
                    var classes = readableAgreementVm.getClassesForInputName($input.attr('name'));
                    angular.forEach(classes, function(val) {
                        $input.addClass(val);
                    })

                    if (!editableInput) {
                        $input.attr('disabled', true);
                    }else{
                        $input.attr('disabled', false);
                        if ($input.val().trim() === "") {
                            $input.addClass('validation-error');
                            readableAgreementVm.numOfClientFieldsLeft++;
                        }

                        $input.on('keyup', function() {
                            AutoInputSizeService.setSize($input, 50, $input.hasClass('client-field') && $input.hasClass('field-required') ? 10 : 0);
                        });

                        $input.on('change', function () {
                            scope.$apply(function inputChange() {
                                var newValue = $input.val().trim();
                                if (newValue !== '') {
                                    $input.removeClass('validation-error');
                                }else{
                                    $input.addClass('validation-error');
                                }
                                $input.attr('value', $input.val().trim());
                                $input.attr('name', $input.attr('name').replace("field_changed:false", "field_changed:true"));
                                $input.addClass('next'); //for the field helper to change to next
                                var fullHTML = $placeholder.html();
                                readableAgreementVm.saveAgreementByClient(fullHTML);
                                var allClientFields = $element.find("input[value=\"\"].client-field");
                                var requiredClientFields = [];
                                allClientFields.each(
                                    function (idx, clientField) {
                                        var fieldObj = InputFieldsService.getField($(clientField).attr('name'));
                                        if (fieldObj.isRequiredField()){
                                            requiredClientFields.push(clientField);
                                        }
                                    }
                                );
                                readableAgreementVm.allClientFields = $element.find(".client-field");
                                readableAgreementVm.theClientFields = requiredClientFields;
                                readableAgreementVm.numOfClientFieldsLeft = readableAgreementVm.theClientFields.length; // find empty fields
                                readableAgreementVm.showNumberOfFields();
                                readableAgreementVm.fileModel.agreement.setHasEmptyClientFields(readableAgreementVm.numOfClientFieldsLeft !== 0);

                                //Set up the Agreement helper points.
                                // We needed to wait for everything to render, so we do it here.
                                reRenderClientFields();
                            });
                        });
                    }

                    // If this is a required client field, increase the adjusted width so we can add an asterisk after
                    AutoInputSizeService.setSize($input, 50, $input.hasClass('client-field') && $input.hasClass('field-required') ? 10 : 0);
                });

                var allClientFields = $element.find("input[value=\"\"].client-field");
                var requiredClientFields = [];
                allClientFields.each(
                    function (idx, clientField) {
                        var fieldObj = InputFieldsService.getField($(clientField).attr('name'));
                        if (fieldObj.isRequiredField()){
                            requiredClientFields.push(clientField);
                        }
                    }
                );
                readableAgreementVm.allClientFields = $element.find(".client-field");
                readableAgreementVm.theClientFields = requiredClientFields;
                readableAgreementVm.numOfClientFieldsLeft = readableAgreementVm.theClientFields.length; // find empty fields
                readableAgreementVm.showNumberOfFields();

                readableAgreementVm.fileModel.agreement.setHasEmptyClientFields(readableAgreementVm.numOfClientFieldsLeft !== 0);

                //if the offset of the agreement $element changes, rerender client fields.
                var el = $element
                scope.fieldsListener = scope.$watch($element[0].offsetTop, function (old, newVal, wat) {
                    self.reRenderClientFields();

                });


                // Watching for just the img-logo isn't working :(
                //scope.fieldsListener = scope.$watch($element.find('#img_logo').height(), function (old, newVal, wat) {
                //    console.log('img-logo')
                //    console.log($element.find('#img_logo').height())
                //    self.reRenderClientFields();
                //
                //});
            };

            self.reRenderClientFields = function reRenderClientFields(){
                //Set up the Agreement helper points.
                // We needed to wait for everything to render, so we do it here.

                var clientFieldsForHelper = $(scope.readableAgreementVm.theClientFields);
                var readableAgreementDocOffset = $element.find('.agreement-fields-wrapper').offset(); //offset of <agreement-fields-wrapper> from  document
                if(readableAgreementDocOffset){
                    readableAgreementDocOffset = readableAgreementDocOffset.top;
                }
                else{
                    readableAgreementDocOffset = 0;
                }

                for(var i =0; i < clientFieldsForHelper.length; i++){

                    var elDocOffset = $(clientFieldsForHelper[i]).offset(); //offset of the field from document.
                    if(elDocOffset){
                        elDocOffset = elDocOffset.top;
                    }
                    else{
                        elDocOffset = 0;
                    }


                    //New num - subtract to get the number of px relative to the <hb-readable-agreement>.
                    // That is position relative and our agreement helper points are pos absolute. So this number is accurate.
                    // Subtract half of the line height to vertically center the helper. (5)
                    var num = elDocOffset - readableAgreementDocOffset - 2;
                    clientFieldsForHelper[i].distanceFromAgreement = num; //send the number to the template to update.
                }
                scope.$applyAsync(function () {
                });
            };

            var updateClientFields = function updateClientFields() {
                var $placeholder = $element.find(placeHolderSelector);
                var el = $element;

                $timeout(function () {
                    //get the client fields from the html_body of the model
                    var clientFieldsFromOfModel = $(scope.readableAgreementVm.fileModel.agreement.html_body)
                        .find('input[name*="field_hb_data_type:client_field"]');


                    //get the client fields from the html that is rendered on the screen
                    var clientFieldsRendered = $placeholder.find('input[name*="field_hb_data_type:client_field"]');
                    var clientModelElementValue;
                    var $clientFieldRenderedElement;

                    for(var i = 0; i < clientFieldsFromOfModel.length; i++){
                        clientModelElementValue = $(clientFieldsFromOfModel[i]).val();
                        $clientFieldRenderedElement = $(clientFieldsRendered[i]);

                        $clientFieldRenderedElement.val(clientModelElementValue);
                        $clientFieldRenderedElement.attr('value', clientModelElementValue);
                        // If this is a required client field, increase the adjusted width so we can add an asterisk after
                        AutoInputSizeService.setSize($clientFieldRenderedElement, 50, $clientFieldRenderedElement.hasClass('client-field') && $clientFieldRenderedElement.hasClass('field-required') ? 10 : 0);

                    }

                    reRenderClientFields();

                }, 1);
            };


            var model = scope.readableAgreementVm.fileModel;
            var user = scope.readableAgreementVm.currUser;
            var viewRendered = false;
            if (model.agreement && model.agreement.html_body && user && (user.isClient() || user.company)) {
                self.setAgreementViewForClient();
                updateClientFields();
                viewRendered = true;
            }

            scope.readableAgreementVm.register(model, 'success', function success() {
                // If the view wasn't rendered yet then we must render it for the first time.
                // If the client is editing the client-fields we don't want to override his work :-)
                $timeout(function() { //insert to timeout to allow onw more cycle of event loop. we want all file flags to update
                    if (!viewRendered || !self.getEditableAgreementViewFlag()) {
                        self.setAgreementViewForClient();
                        updateClientFields();


                        viewRendered = true;

                    }
                });
            }, this);

            ////////////////////////////////////
            // Handle fields helper sticky banner
            ////////////////////////////////////
            var $scrollParent = $element.scrollParent();

            $scrollParent.on('scroll.readableAgreement.honeybook', function () {

                var $helper = $element.find('.agreement-fields-wrapper');
                if ($helper.length) {
                    var offsetTop = $helper.offset().top;

                    var isSticky;

                    if (scope.readableAgreementVm.isPreviewMode) {
                        isSticky = ($scrollParent.scrollTop() - offsetTop + 160) > 0;
                        $helper.toggleClass('agreement-fields-wrapper--sticky-preview', isSticky && scope.readableAgreementVm.shouldShowfloatingBanner);
                    }  else {
                        isSticky = ($scrollParent.scrollTop() - offsetTop + 96) > 0;
                        $helper.toggleClass('agreement-fields-wrapper--sticky', isSticky && scope.readableAgreementVm.shouldShowfloatingBanner);
                    }
                    var $message = $helper.find('.agreement-fields-message');

                    if (isSticky) {
                        $message.css('width', $helper.width());
                    }
                }
            });
        }
    };
};