// hi
// @ngInject
Directives.WindowResizeDirective = function WindowResizeDirective($window, DeviceService) {

    return {
        restrict: 'E',
        multiElement: true,
        link: function(scope) {
            this.DeviceService = DeviceService;

            var updateDeviceService = function updateDeviceService() {
                scope.$applyAsync(function() {
                    this.DeviceService.checkIfMobileSize();
                });
            };

            angular.element($window).bind('resize', updateDeviceService);

            scope.$on('$destroy', function () {
                angular.element($window).off('resize');
            });
        }
    };
}
