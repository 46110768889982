(function () {
    'use strict';

    // @ngInject
    function TasksMgmtControllerCtor($scope, $injector, UsersManager, FeaturesService, TasksManager, $stateParams) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TasksMgmtController';
        this.TasksManager = TasksManager;
        this.filter = $stateParams.filter;
    }

    Controllers.TasksMgmtController = Class(Controllers.BaseController, {
        constructor: TasksMgmtControllerCtor,


    });
}());
