Directives.AccountSetupFaq = function AccountSetupFaq() {

    "use strict";
    
    // @ngInject
    function AccountSetupFaqControllerCtor($scope, $injector, $translate, Gon, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'AccountSetupDisabledController';
        this.$translate = $translate;
        this.time_to_upload_materials = Gon.time_to_upload_materials;
        this.AnalyticsService = AnalyticsService;

        const NUMBER_OF_QUESTIONS = 5;

        this.faqData = [];
        for (let i = 1; i <= NUMBER_OF_QUESTIONS; i++) {
            this.faqData.push({
                id: i,
                name: this.$translate.instant(`COMPANY_SETTING.SETUP.DISABLED.FAQS.FAQ_${i}._NAME_`),
                question: this.$translate.instant(`COMPANY_SETTING.SETUP.DISABLED.FAQS.FAQ_${i}._QUESTION_`),
                answer: this.$translate.instant(`COMPANY_SETTING.SETUP.DISABLED.FAQS.FAQ_${i}._ANSWER_`, {time_to_upload_materials: this.time_to_upload_materials}),
                isOpen: false
            });
        }
    }

    var AccountSetupFaqController = Class(Controllers.BaseController, {
        constructor: AccountSetupFaqControllerCtor,

        toggleAccordion: function toggleAccordion(clickedFaqId) {
            this.faqData.forEach((faq)=>{
                if(faq.id === clickedFaqId) {
                    faq.isOpen = !faq.isOpen;
                    this.AnalyticsService.trackClick(
                        this, this.AnalyticsService.analytics_events.account_setup_faq,
                        {
                            faq_name: faq.name,
                            is_open: faq.isOpen
                        }
                    );
                } else {
                    faq.isOpen = false;
                }
            });
        },

    });

    return {
        templateUrl: 'angular/app/modules/core/features/account_setup/account_setup_faq_template.html',
        controller: AccountSetupFaqController,
        controllerAs: 'accountSetupFaqVm',
        bindToController: true
    };
};
