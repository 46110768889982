(function () {
    'use strict';

    Directives.TaxEditorDirective = function TaxEdtitorDirective() {
        // @ngInject
        function TaxEditorDirectiveControllerCtor($scope, $injector, Enums, AnalyticsService, UIUtils) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'TaxEditorDirectiveController';
            this.Enums = Enums;
            this.AnalyticsService = AnalyticsService;
            this.UIUtils = UIUtils;

            this._init();
            this.taxHierarchy = this.isSecondaryTax ? 'secondary' : 'primary';
        }

        var TaxEditorDirectiveController = Class(Controllers.BaseController, {
            constructor: TaxEditorDirectiveControllerCtor,

            onInputChanged: function onTaxTitleChanged() {
                this._handleSave();
            },

            onTitleChange: function onTitleChange() {
                if (this.modelTax.title === this.initialTaxDataCopy.title){
                    return;
                }

                this._handleSave();
            },

            onTypeClick: function onTypeClick(taxObj, type) {
                if (taxObj.type === type) {
                    return;
                }

                taxObj.type = type;
                this._handleSave();
            },

            _init: function _init() {
                this.dataChanged = false;
                this.relativeTax = this.Enums.taxTypes.relative;
                this.absoluteTax = this.Enums.taxTypes.absolute;

                this.taxMode = this.taxType === 'tax';

                if (this.taxMode) {
                    if (this.isSecondaryTax) {
                        this.modelTax = this._initTaxData(
                          'secondary',
                          this.proposalModel.secondary_tax_title,
                          this.proposalModel.secondary_tax_type,
                          this.proposalModel.secondary_tax );
                    } else {
                        this.modelTax = this._initTaxData(
                          'primary',
                          this.proposalModel.tax_title,
                          this.proposalModel.tax_type,
                          this.proposalModel.tax);
                    }
                } else {
                    this.modelTax = this._initTaxData(
                        'primary',
                        null,
                        this.proposalModel.svc_type,
                        this.proposalModel.svc);
                }
            },

            _initTaxData: function _initTaxData(importance, title, type, tax) {
                var taxData = {
                    importance: importance,
                    type: type || this.relativeTax,
                    title: title || '',
                    amount: tax || '',
                };

                this.initialTaxDataCopy = angular.copy(taxData);
                return taxData;
            },

            _formatTaxData: function _formatTaxData(tax) {
                var data = {};

                if (this.taxMode) {
                    if (this.isSecondaryTax) {
                        data.secondary_tax_title = tax.title || '';
                        data.secondary_tax_type = tax.type || this.Enums.taxTypes.relative;
                        data.secondary_tax = tax.amount || 0;
                    } else {
                        data = {
                            tax_title: tax.title || '',
                            tax_type: tax.type || this.Enums.taxTypes.relative,
                            tax: tax.amount || 0
                        };
                    }
                } else {
                    data = {
                        svc: tax.amount || 0,
                        svc_type: tax.type || this.Enums.taxTypes.relative
                    };
                }
                return data;
            },


            _handleSave: function _handleSave(revertChanges) {
                this.dataChanged = true;
                var formattedTaxData = revertChanges ? this._formatTaxData(this.initialTaxDataCopy) : this._formatTaxData(this.modelTax);

                this.proposalModel.updateProposal(formattedTaxData)
                  .then(function success() {
                      this.onSave && this.onSave();
                  }.bind(this))
                  .finally(function final() {
                      this.saving = false;
                      if(revertChanges){
                          this.onClose();
                      }
                  }.bind(this));
            },

            onRemoveTax: function onRemoveTax(){
                this.proposalModel.updateProposal(this._formatTaxData({}))
                  .then(function success() {
                      this.onSave && this.onSave();
                  }.bind(this))
                  .finally(function final() {
                      this.saving = false;
                      this.onClose();
                  }.bind(this));
            },

            handleCancel: function handleCancel() {
                if( !this.dataChanged ) {
                    return this.onClose();
                }

                this._handleSave(true);
            },
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/proposal/tax/tax_editor_template.html',
            controller: TaxEditorDirectiveController,
            controllerAs: 'taxEditorVm',
            bindToController: true,
            scope: {
                proposalModel: '=',
                supportSecondary: '<?',
                onSave: '&',
                taxType: '@',
                onClose: '&',
                isSecondaryTax: '<'
            }
        };
    };

}());

