/**
 * Created by inonstelman on 12/28/14.
 */

(function () {
    'use strict';

    // @ngInject
    Services.jsRoutes = function jsRoutes($window) {
        return $window.Routes;
    };

}());
