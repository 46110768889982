
(function () {
    'use strict';

    Directives.AccordionDirective = function AccordionDirective() {

        // @ngInject
        function AccordionDirectiveControllerFunc($scope, $injector, $timeout, AnalyticsService, UIUtils) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'AccordionDirectiveController';
            this.AnalyticsService = AnalyticsService;
            this.UIUtils = UIUtils;

            this.isOpen = false;
        }

        var AccordionDirectiveController = Class(Controllers.BaseController, {
            constructor: AccordionDirectiveControllerFunc,
            toggleOpen: function toggleOpen() {
                this.isOpen = !this.isOpen;
                if (this.isOpen) {
                    this.AnalyticsService.track(this, this.analyticsEventName, this.UIUtils.convertStringToObject(this.analyticsEventProperties));
                }
            }
        });

        return {
            scope: {
                title: '@',
                content: '@',
                contentTranslateProperties: '=',
                analyticsEventName: '@',
                analyticsEventProperties: '@'
            },
            templateUrl: 'angular/app/modules/common/ui_components/accordion/accordion_directive_template.html',
            controller: AccordionDirectiveController,
            controllerAs: 'accordionDirectiveVm',
            bindToController: true
        };
    };

})();
