(function () {
    'use strict';

    // @ngInject
    function OnboardingGuidanceControllerCtor($scope, $injector, $q, UsersManager, $window, AnalyticsService, CompaniesManager, ImagesManager, AppStates,
                                              ModalService, PhotosUploadManager, PopupMessageService, OnboardingService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'OnboardingGuidanceController';
        AnalyticsService.trackPageView(this, 'View');

        this.$q = $q;
        this.$window = $window;
        this.ModalService = ModalService;
        this.CompaniesManager = CompaniesManager;
        this.ImagesManager = ImagesManager;
        this.PopupMessageService = PopupMessageService;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.AppStates = AppStates;
        this.OnboardingService = OnboardingService;

        this.currUser = UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();

        this.isCoverImageLoaded = false;
        this.isCoverImageLoading = false;
        this.isCoverImageMobileLoading = false;
        this.selectedCoverImage = null;

        this.isIconLoaded = false;
        this.isIconLoading = false;
        this.iconLoadingProgress = 0;

        this.isProfileImageLoaded = false;
        this.isProfileImageLoadeding = false;
        this.profileImageProgress = 0;

        this.fileHeaderAspectRatio = 2.5;
        this.rightAspectRatioHeight = 0;

        // calculate the right height for the file cover image to keep the right
        // aspect ratio as it will show up in the end
        $scope.$watch(function () {
            return angular.element('.onboarding-guidance__document-header').width();
        }, function (newValue, oldValue) {
            if (newValue) {
                this.rightAspectRatioHeight = parseInt(newValue / this.fileHeaderAspectRatio);
            }
        }.bind(this));

    }

    Controllers.OnboardingGuidanceController = Class(Controllers.BaseController, {
        constructor: OnboardingGuidanceControllerCtor,

        isEmptyFilesCollection: function isEmptyFilesCollection($files) {
            // this happens if the hot-spot is being double clicked (this could be bug on the upload plugin)
            return ($files && $files.length === 0 || !$files);
        },

        findImageInCompanyAccordingToAmazonUrl: function findImageInCompanyAccordingToAmazonUrl(companyCoverImages, s3Url) {
            var imageFound;
            companyCoverImages.forEach(function(image, key) {
                if (image.original_url === s3Url) {
                    imageFound = image;
                }
            });

            return imageFound;
        },

        uploadCoverImageInMobile: function uploadCoverImageInMobile($files) {
            var coverUploadModel = {
                instance: this.company,
                dontUpdateServer: true,
                type: 'cover',
                file: $files[0],
                name: $files[0].name,
                url: null
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.onboarding_guidance_uploading_file_header_from_mobile);

            this.isCoverImageMobileLoading = true;

            this.PhotosUploadManager.getUpdatedModelData(coverUploadModel);
            this.PhotosUploadManager.uploadPhoto($files, coverUploadModel, this)
                .then(function success(resp) {
                    return this.CompaniesManager.createCompanyImage(this.company, coverUploadModel.file.s3Response, coverUploadModel.type)
                        .then(function createCompanyImageSuccess(result) {
                            this.isCoverImageLoaded = true;

                            this.selectedCoverImage = this.findImageInCompanyAccordingToAmazonUrl(result.data.cover_images, coverUploadModel.file.s3Response.url);

                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.onboarding_guidance_saved_file_header);
                        }.bind(this));
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.onboarding_guidance_saved_file_header, resp);

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ONBOARDING.GUIDANCE.ERRORS._ERROR_UPLOADING_FILE_');
                }.bind(this))
                .finally(function finallyHandler() {
                    this.isCoverImageLoading = false;
                    this.isCoverImageMobileLoading = false;
                }.bind(this));
        },

        onDocumentHeaderSelected: function onDocumentHeaderSelected($files) {
            if (this.isEmptyFilesCollection($files)) {
                return;
            }
            this.isCoverImageLoading = true;

            if(this.$window.innerWidth < 640){
                // on small screens we dont allow to open the crop dialog.
                // the image will be taken as is and a default cropping will be done on the server side.
                // (take the full width of the image and the height will be taken according to the aspect ratio of 2.5)
                this.uploadCoverImageInMobile($files);
            } else {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.onboarding_guidance_opening_crop_dialog);

                this.ModalService.openAttachAssetModal(true, false, true, 'Upload', false, false, null, this.imageDisplayRatio,
                    this.showPreviewWhenCroppingDisplay, $files, 'image/*', '', 'reveal-modal-attach-asset__v2 hide-tabs', 'MEDIA_MODAL.TITLE._ADD_IMAGE_')
                    .then(this.handleSaveRepositionOfSelectedCoverImage.bind(this))
                    .finally(function openAttachAssetModalFinally() {
                        this.isCoverImageLoading = false;
                    }.bind(this));
            }
        },

        openAssetDialogForEditing: function openAssetDialogForEditing() {
            this.isCoverImageLoading = true;

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.onboarding_guidance_opening_crop_dialog_for_editing);

            this.ModalService.openAttachAssetModal(true, false, true, 'Crop', false, false, this.selectedCoverImage, this.imageDisplayRatio,
                this.showPreviewWhenCroppingDisplay, false, 'image/*', '', 'reveal-modal-attach-asset__v2', 'MEDIA_MODAL.TITLE._EDIT_IMAGE_')
                .then(this.handleSaveRepositionOfSelectedCoverImage.bind(this))
                .finally(function openAttachAssetModalFinally() {
                    this.isCoverImageLoading = false;
                }.bind(this));
        },

        handleSaveRepositionOfSelectedCoverImage: function handleSaveRepositionOfSelectedCoverImage(newImage) {
            this.ImagesManager.saveImageReposition(newImage)
                .then(function success(res) {

                    res.data.forEach(function readResult(image) {
                        if (image._type === 'CoverImage') {
                            this.ImagesManager.updateCoverImageInCompany(image);
                        } else if (image._id === newImage._id) {
                            //extend the image we have with the image that was returned from the last API
                            angular.extend(newImage, image);
                        }

                        this.selectedCoverImage = newImage;
                        this.isCoverImageLoaded = true;

                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.onboarding_guidance_saved_file_header);

                    }.bind(this));
                }.bind(this))
                .catch(function errorSavingReposition(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.onboarding_guidance_saved_file_header, resp);
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ONBOARDING.GUIDANCE.ERRORS._ERROR_UPLOADING_FILE_');
                }.bind(this))
                .finally(function () {
                    this.isCoverImageLoading = false;
                }.bind(this));
        },

        /***
         * onFileToUploadAsIconAndLogoSelected - will upload the file selected as the company logo and the compay icon
         * later on the user can decide to change ether one of them from the company settings
         * @param $files - the file selected we allow him to select only one
         */
        onFileToUploadAsIconAndLogoSelected: function onFileToUploadAsIconAndLogoSelected($files) {
            if (this.isEmptyFilesCollection($files)) {
                return;
            }
            var fileToUpload = $files[0];

            this.iconLoadingProgress = 0;

            this.isIconLoading = true;
            this.isIconLoaded = false;

            // Upload the icon of the company
            var modelIcon = {
                instance: this.company,
                saveIconAsLogoToo: true, //this will make sure that this file is saved as icon and logo on the company model
                type: 'icon'
            };

            this.PhotosUploadManager.getUpdatedModelData(modelIcon);
            var uploadIconPromise = this.PhotosUploadManager.uploadPhoto($files, modelIcon, this);

            uploadIconPromise
                .then(function successIconAndLogo() {
                    this.isIconLoaded = true;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.onboarding_guidance_icon_and_logo_updated);
                }.bind(this))
                .catch(function fail(resp) {
                    this.isIconLoaded = false;
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.onboarding_guidance_icon_and_logo_updated, resp);

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ONBOARDING.GUIDANCE.ERRORS._ERROR_UPLOADING_FILE_');
                }.bind(this))
                .finally(function iconUploaderFinallyHandler() {
                    this.isIconLoading = false;
                }.bind(this));

            fileToUpload.uploadAPI.progress(function (uploadProgress) {
                this.iconLoadingProgress = uploadProgress;
            }.bind(this));

        },

        onProfileImageToUploadSelected: function onProfileImageToUploadSelected($files) {
            if (this.isEmptyFilesCollection($files)) {
                return;
            }

            //we allow only one file to be dropped here and we know that $files is not empty
            var fileToUpload = $files[0];
            
            this.profileImageProgress = 0;
            this.isProfileImageLoadeding = true;

            var modelLogo = {
                instance: this.currUser,
                type: 'profile_image'
            };
            
            this.PhotosUploadManager.getUpdatedModelData(modelLogo);
            this.PhotosUploadManager.uploadPhoto($files, modelLogo, this)
                .then(function updateLogoCompanySuccess() {
                    this.isProfileImageLoaded = true;

                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.onboarding_guidance_profile_image_updated);
                }.bind(this))
                .catch(function fail(resp) {
                    this.isProfileImageLoaded = false;
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.onboarding_guidance_profile_image_updated, resp);

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ONBOARDING.GUIDANCE.ERRORS._ERROR_UPLOADING_FILE_');
                }.bind(this))
                .finally(function profileImageUploaderFinallyHandler() {
                    this.isProfileImageLoadeding = false;
                }.bind(this));

            fileToUpload.uploadAPI.progress(function (uploadProgress) {
                this.profileImageProgress = uploadProgress;
            }.bind(this));
        },

        shouldShowFileSelectionHotSpot: function shouldShowFileSelectionHotSpot() {
            return (!this.isCoverImageLoading && !this.isCoverImageLoaded) ||
                ( this.$window.innerWidth < 640 && !this.isCoverImageLoading && this.isCoverImageLoaded);
        },

        onDoneButtonClicked: function onDoneButtonClicked() {
            if (this.isProfileImageLoadeding || this.isIconLoading || this.isCoverImageLoading) {
                return; //just a protection
            }

            this.OnboardingService.setStepComplete(this.OnboardingService.OnboardingSteps.initialUserInfo);
        },
    });
}());