(function () {
    "use strict";

    Services.IntentService = class IntentService {

        // @ngInject
        constructor(Enums, UsersManager, StatsigService, $injector, DeviceService) {
            this.INTENTS = {
                invoices: 'invoices',
                contracts: 'contracts',
                automations: 'automations',
                leadCapture: 'leadCapture',
                businessManagement: 'businessManagement',
                crm: 'crm',
                scheduling: 'scheduling',
                brochuresAndQuestionnaires: 'brochuresAndQuestionnaires',
                allInOne: 'all_in_one',
                templates: 'templates',
            };

            this.UsersManager = UsersManager;
            this.Enums = Enums;
            this.currUser = UsersManager.getCurrUser();
            this.userIntent = this.currUser.user_intent;
            this.StatsigService = StatsigService;
            this.$injector = $injector;
            this.DeviceService = DeviceService;
            this.isMobile = this.DeviceService.nxSmallBreakpoint();

            this.StatsigService.isGateEnabled('adobe_users', false)
                    .then(function (result) {
                        this.isAdobeUser = result;
                        const OnboardingService = this.$injector.get('OnboardingService');
                        OnboardingService.onboardingAbTestType = null;
                    }.bind(this));
        }

        getAccountIntent() {
            this.currUser = this.UsersManager.getCurrUser();
            if (this.isInIntentTestGroup()) {
                return this.currUser.user_intent;
            } 
            return null;
        }

        isInIntentTestGroup() {
            this.currUser = this.UsersManager.getCurrUser();
            const intentInfra = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.intentInfra);
            const isStartedOnbAfterInfraClosed = this.currUser.startedTrialAfterDate('2023-10-31');
            if (intentInfra === 'intent_test' || isStartedOnbAfterInfraClosed) {
                return true;
            }
            return false;
        }

        isInvoiceIntentBehavior() {
            this.currUser = this.UsersManager.getCurrUser();
            const userIntent = this.currUser.user_intent;

            return (this.isInIntentTestGroup()) && (!userIntent || userIntent === this.INTENTS.invoices || userIntent === this.INTENTS.templates);
        }

        shouldGetLegacyLikeOnboarding() {
            return this.isInvoiceIntentBehavior() && !this.shouldGetInvoiceIntentOnboarding();
        }

        shouldGetInvoiceIntentOnboarding() {
            this.currUser = this.UsersManager.getCurrUser();

            const invoiceIntentServicesOnboarding = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.invoiceIntentServicesOnboarding);
            return this.isInvoiceIntentBehavior() && invoiceIntentServicesOnboarding !== 'control_legacy_like';
        }

        shouldGetSchedulerIntentOnboarding() {
            this.currUser = this.UsersManager.getCurrUser();

            const schedulerIntentOnboarding = this.currUser.getExistingAbTestVariation(this.Enums.ABTests.schedulerIntentOnboarding);
            return this.isInSpecificIntentGroup(this.INTENTS.scheduling) && schedulerIntentOnboarding !== 'control';
        }

        shouldGetLeadCaptureIntentOnboarding() {
            if(!angular.isDefined(this.isShowLeadCaptureIntentOnboarding) && !this.isFetchingStatsigData && this.isLeadCaptureIntentUser()){
                this.isFetchingStatsigData = true;
                this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.leadCaptureIntentOnboarding,  'lead_capture_onboarding_enabled', false)
                    .then(function (result) {
                        this.isShowLeadCaptureIntentOnboarding = result;
                    }.bind(this))
                    .finally(function () {
                        this.isFetchingStatsigData = false;
                    }.bind(this));
            }
            return this.isShowLeadCaptureIntentOnboarding;
        }
        
        shouldGetCrmIntentOnboarding() {
            if(!angular.isDefined(this.isShowCrmIntentOnboarding) && !this.isFetchingStatsigData && this.isCrmIntentUser()){
                this.isFetchingStatsigData = true;
                this.StatsigService.isGateEnabled('crm_onboarding_control_group', false)
                .then(function (isInControlGroup) {
                        this.isShowCrmIntentOnboarding = !isInControlGroup;
                    }.bind(this))
                    .finally(function () {
                        this.isFetchingStatsigData = false;
                    }.bind(this));
            }
            return this.isShowCrmIntentOnboarding;
        }

        isLeadCaptureIntentUser() {
            this.currUser = this.UsersManager.getCurrUser();
            const userIntent = this.currUser.user_intent;
            return userIntent ===  this.INTENTS[this.INTENTS.leadCapture];
        }

        isCrmIntentUser() {
            this.currUser = this.UsersManager.getCurrUser();
            const userIntent = this.currUser.user_intent;
            return userIntent ===  this.INTENTS[this.INTENTS.crm];
        }

        isInSpecificIntentGroup(intent) {
            this.currUser = this.UsersManager.getCurrUser();
            const userIntent = this.currUser.user_intent;

            return this.isInIntentTestGroup() &&  userIntent === this.INTENTS[intent];
        }

        isContractIntentAndIntentTestGroup() {
            return this.isInSpecificIntentGroup(this.INTENTS.contracts);
        }

        shouldGetContractV4IntentOnboarding() {
            if (!angular.isDefined(this.isShowContractV4IntentOnboarding) && !this.isFetchingStatsigData) {
                this.isFetchingStatsigData = true;
                this.StatsigService.getExperimentConfigValue('contract_intent_onboarding_v4',  'is_customized_onboarding', false)
                    .then(function (showContractV4IntentOnboarding) {
                        this.isShowContractV4IntentOnboarding = showContractV4IntentOnboarding;
                    }.bind(this))
                    .finally(function () {
                        this.isFetchingStatsigData = false;
                    }.bind(this));
            }
            return this.isShowContractV4IntentOnboarding;
        }

        isShowReactOnboarding() {
            const reactOnboardings = [
                this.shouldGetInvoiceIntentOnboarding(),
                this.shouldGetSchedulerIntentOnboarding(),
                this.shouldGetLeadCaptureIntentOnboarding(),
                this.shouldGetCrmIntentOnboarding(),
                this.shouldGetUploadPdfOnboarding(),
                this.shouldGetContractV4IntentOnboarding()
            ];
            return reactOnboardings.some(reactOnboarding => reactOnboarding);
        }

        shouldGetUploadPdfOnboarding() {
            const relevantIntents = [
                this.INTENTS.invoices,
                this.INTENTS.contracts,
                this.INTENTS.brochuresAndQuestionnaires
            ];
            const userIntent = this.currUser.user_intent;
            // This is a temporary solution until we figure out why statsig is not returns the correct value on the first call
            const isAdobeUser = this.isAdobeUser || this.currUser.attribution.user_utm_params.utm_source === 'adobe';
            return isAdobeUser && !this.isMobile && (!userIntent || relevantIntents.includes(userIntent));
        }
    };
}());
