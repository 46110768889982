Directives.LoginTwoFactorAuthDirective = function LoginTwoFactorAuthDirective() {

    // @ngInject
    function LoginTwoFactorAuthDirectiveControllerFunc($scope, $injector, $stateParams, UsersManager, ModalService, ToastService, PopupMessageService, LoginTwoFactorAuthService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginTwoFactorAuthDirectiveController';
        this.LoginTwoFactorAuthService = LoginTwoFactorAuthService;
        this.$stateParams = $stateParams;

        if (this.$stateParams.openTwoFactorAuthModal) {
            this.addLoginTwoFactorAuth();
        }

    }

    var LoginTwoFactorAuthDirectiveController = Class(Controllers.BaseController, {
        constructor: LoginTwoFactorAuthDirectiveControllerFunc,

        addLoginTwoFactorAuth: function addLoginTwoFactorAuth() {
            this.LoginTwoFactorAuthService.addLoginTwoFactorAuth(this.user);
        },

        securityPhoneNumberExist: function securityPhoneNumberExist() {
            return this.LoginTwoFactorAuthService.securityPhoneNumberExist(this.user);
        },

    });

    return {
        scope: {
            user: '=user'
        },
        templateUrl: 'angular/app/modules/core/features/settings/account/login_two_factor_auth/login_two_factor_auth_template.html',
        controller: LoginTwoFactorAuthDirectiveController,
        controllerAs: 'loginTwoFactorAuthVm',
        bindToController: true
    };
};