'use strict';

class SignupLoaderController extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, _, $) {
        super($scope, $injector);
        this.__objectType = 'SignupLoaderController';

        this.mobileLoadingText = "Generating templates";

        if (this.companyName) {
            this.mobileLoadingText += ` for ${this.companyName}`
        }
    }

}


Components.SignupLoader = {
    bindings: {
        vendorName: '<',
        companyName: '<',
        logoFile: '<',
        defaultCoverPhoto: '<',
        signingCaption: '<',
        selectedLogoBlob: '<',
        isFacebookMobileFlow: '<',
    },
    controller: SignupLoaderController,
    name: 'hbSignupLoader',
    templateUrl: 'angular/app/modules/core/features/signup/signup_loader/signup_loader_component.html',
};

