(function () {
    'use strict';

    // @ngInject
    function WorkspaceFileBrochureControllerCtor($scope, $stateParams, $injector, $q, $translate, $location, $filter, $state, _, TemplatesViewService,
                                         UsersManager, AppStates,
                                         WorkspaceFilesManager, WorkspacesManager, CompaniesManager, BrochuresManager, PreviewModeService,
                                         AutosizeTextareaUpdater, PopupMessageService, AnalyticsService, Enums, WorkspaceFileService, uuid4,
                                         ModalService, WorkspaceService, UiPersistenceService, $timeout, ToastService, DeviceService, $window, MobileAppService, InjectBrandingService, AccountsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileBrochureController';

        this.PreviewModeService = PreviewModeService;

        this.$state = $state;
        this.AppStates = AppStates;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.WorkspaceFileService = WorkspaceFileService;
        this.ModalService = ModalService;
        this.WorkspaceService = WorkspaceService;
        this.WorkspacesManager = WorkspacesManager;
        this.CompaniesManager = CompaniesManager;
        this.BrochuresManager = BrochuresManager;
        this.TemplatesViewService = TemplatesViewService;
        this._ = _;
        this.$q = $q;
        this.UiPersistenceService = UiPersistenceService;
        this.$timeout = $timeout;
        this.ToastService = ToastService;
        this.DeviceService = DeviceService;
        this.$scope = $scope;
        this.MobileAppService = MobileAppService;
        this.InjectBrandingService = InjectBrandingService;
        this.AccountsService = AccountsService;

        this.persistenceHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.intro_images_in_file) || {'dontShow': false};
        this.user = UsersManager.getCurrUser();
        this.isClient = this.user.isClient();
        this.workspaceFileId = $stateParams.workspace_file_id;
        this.workspaceFile = WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
        this.company = this.CompaniesManager.getCurrCompany();
        this.loading = true;
        this.updateTextareas = AutosizeTextareaUpdater;

        // state flags
        this.isOwnerMode = this.workspaceFile && this.workspaceFile.isOwnerMode();
        this.isClientMode = this.workspaceFile && this.workspaceFile.isClientMode();
        this.isViewOnlyMode = this.workspaceFile && this.workspaceFile.isViewOnlyMode();
        this.isPreviewMode = false;
        this.isEditableMode = false;
        this.canEdit = false;
        this.alreadyGotFile = false;
        this.alreadyGotWorkspace = false;

        this.menuItems = [];
        this.filmstripItems = [];

        this.selectedDrawerTab = WorkspaceFileService.shouldShowTemplatesMenu();

        PreviewModeService.quit();

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info, message, function success() {
                this.goToState(this.AppStates.root_core_navigation_home);
            }.bind(this));
        });

        this.allowEdit = function allowEdit(){
            // a file can be editable in theory, but should not be edited right now, because it is a client / preview etc.
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        };

        this.allowDelete = function allowDelete(){
            return this.allowEdit() && this.hasCollection();
        };

        this.confirmExit = function confirmExit() {
            return "You didn’t submit your selection. Are you sure?";
        };

        //Brochure Film Strip Menu Management

        this.clickOutsideFilmstripAddMenu = function clickOutsideFilmstripAddMenu(){
            this.showFilmstripAddMenu = false;
        };

        this.addMenuFilmstripAction = function addMenuFilmstripAction($event){
            $event.stopPropagation();
            this.showFilmstripAddMenu = true;

            var target = $event.target;
            var rect = target.getBoundingClientRect();
            this.addMenuLeft = rect.right + 10;
            this.addMenuTop = rect.top;
        };

        this.clickFilmstripAddPackage = function clickFilmstripAddPackage(){
            this.loadingFilmstripAddPackage = true;
            this.createBrochureProposal().finally(function stopFSAddPackageLoading() {
                this.loadingFilmstripAddPackage = false;
                this.showFilmstripAddMenu = false;
            }.bind(this));
        };

        this.clickFilmstripAddIntro = function clickFilmstripAddIntro(){
            this.loadingFilmstripAddIntro = true;
            this.createBrochureIntro().finally(function stopFSIntroLoading(){
                this.loadingFilmstripAddIntro = false;
                this.showFilmstripAddMenu = false;
            }.bind(this));
        };

        this.clickFilmstripAddALaCarte = function clickFilmstripAddALaCarte(){
            this.loadingFilmstripAddALaCarte = true;
            this.createALaCarte().finally(function stopFSALaCarteLoading() {
                this.loadingFilmstripAddALaCarte = false;
                this.showFilmstripAddMenu = false;
            }.bind(this));
        };

        // Members validation
        var membersValidationId = uuid4.generate();
        this.WorkspaceFileService.addFileValidation(membersValidationId, function membersFileValidationFunc() {
            return this.WorkspaceFileService.membersFileValidation(this.workspaceFile, this.workspace);
        }.bind(this), 100);

        // questions state validation
        if(this.isNewBrochure()) {
            var QuestionsValidationId = uuid4.generate();
            this.WorkspaceFileService.addFileValidation(QuestionsValidationId, function questionsFileValidationFunc() {
                return this.WorkspaceFileService.questionsFileValidation(this.workspaceFile);
            }.bind(this), 100);
        }

        this.bypassStateChange = false;
        $scope.$on('$stateChangeStart', function(event, state, params) {
            if (!this.bypassStateChange && this.workspaceFile.isClientMode() && this.workspaceFile.selected_proposal_id && !this.workspaceFile.isComplete()) {
                event.preventDefault();
                this.ModalService.openConfirmBrochureSelectionModal(this.workspaceFile.owner.company.company_name).then(
                    function ok() {
                        this.submitBrochure();
                    }.bind(this),
                    function cancel() {
                        this.bypassStateChange = true;
                        $state.go(state.name, params);
                    }.bind(this)
                );
            }
        }.bind(this));

        $scope.$on('$destroy', function () {
            this.WorkspaceFileService.removeFileValidation(membersValidationId);
        }.bind(this));

    }

    Controllers.WorkspaceFileBrochureController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileBrochureControllerCtor,

        isSubmitBrochureEnabled: function isSubmitBrochureEnabled() {

            // disable if in progress
            if(this.submitInProgress) {
                return false;
            }

            // Can't re-submit
            if (this.workspaceFile.isComplete()) {
                return false;
            }

            if(this.isNewBrochure()) {

                var isValid = false;

                var hasProposal = !!this.workspaceFile.file_dynamic_content.has_proposal;
                if(!hasProposal) {
                    isValid = true;
                } else {
                    isValid = this.workspaceFile.file_dynamic_content.isServiceSelectionsValid();
                }

                isValid = isValid && this.workspaceFile.file_dynamic_content.isQuestionsAnswersValid();
                return isValid;

            } else {

                // old brochure selection
                if (this.workspaceFile.brochure_container.brochure_proposals.length >= 1) {
                    return !this._.isEmpty(this.workspaceFile.selected_proposal_id);
                }

                return this._.some(this.workspaceFile.brochure_container.a_la_carte_proposal.package_services, function(service) {
                    return service.selected;
                });
            }
        },

        submitBrochure: function submitBrochure() {
            if (!this.isSubmitBrochureEnabled()) {
                return;
            }

            this.submitInProgress = true;

            this.workspaceFile.submitBrochure()
                .then(
                    function ok() {
                        window.onbeforeunload = null;
                        this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, {event_id: this.workspaceFile.event._id, workspace_id: this.workspaceFile.couple_card_id});
                    }.bind(this)
                ).finally(
                function doFinally() {
                    this.submitInProgress = false;
                }.bind(this)
            );
        },

        onSelectBrochureAction: function onSelectBrochureAction() {
            window.onbeforeunload = this.confirmExit;
        },

        showActionButton: function showActionButton() {
            return ((this.isClientMode) && !this.loading) && (!this.workspaceFile.isPaymentsSeen() && !this.workspaceFile.isCanceled());
        },

        gotWorkspaceFile: function gotWorkspaceFile(message, status) {
            this.loading = false;

            if(this.workspaceFile.company){
                this.AccountsService.verifyLoggedCompany(
                    this.workspaceFile.company._id,
                    this.workspaceFile.company.account_id,
                    'file',
                    this.workspaceFile.company.company_name
                );
                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }

            if (!this.alreadyGotFile) {
                var initPermissionBasedViewModes = function initPermissionBasedViewModes() {
                    this.isOwnerMode = this.workspaceFile.isOwnerMode();
                    this.isClientMode = this.workspaceFile.isClientMode();
                    this.isViewOnlyMode = this.workspaceFile.isViewOnlyMode();
                    if (!this.isOwnerMode && !this.isViewOnlyMode) {
                        this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.proposal_seen);
                    }
                    this.canEdit = this.allowEdit();
                    this.showOwner = !this.isOwnerMode;
                }.bind(this);

                if (this.user.isVendor() && !this.workspaceFile.isClientMode()) {
                    this.company = this.CompaniesManager.getCurrCompany();
                    if (this.company.wasFullyFetched()) {
                        initPermissionBasedViewModes();
                    } else {
                        this.company.once('success', initPermissionBasedViewModes);
                    }
                } else {
                    this.company = this.workspaceFile.company;
                    initPermissionBasedViewModes();
                }

                if (!this.alreadyGotWorkspace) {
                    this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true);
                    this.alreadyGotWorkspace = true;
                }

                this.isInAppBrowser = this.DeviceService.isInAppBrowser();
                this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.workspace_file_loaded);
            }


            this.updateTextareas();
            this.showOwner = !this.isOwnerMode;
            this.isEditableMode = this.workspaceFile.isEditable();
            this.canEdit = this.allowEdit();

            this.buildFilmstrip();
            this.updateSendFileText();

            this.file_title = this.workspaceFile.file_title;

            this.alreadyGotFile = true;

            this.isClient = this.user.isClient();
            this.isVendor = this.user.isVendor();

            this.AnalyticsService.trackPageView(this, 'workspace file', {file_type: this.workspaceFile.file_type});
        },

        updateSendFileText: function updateSendFileText() {
            this.sendFileButtonText = this.WorkspaceFileService.getSendFileLabel(this.workspaceFile, this.workspace);
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        getFileClasses: function getFileCardClasses() {
            var fileClasses = this.WorkspaceFileService.getStatusClassesArray(this.workspaceFile);
            if (this.isPreviewMode) {
                fileClasses.push('preview-mode');
            }

            if (this.shouldShowAsideDrawer()) {
                fileClasses.push('aside-drawer-main');
            }

            if(this.isInAppBrowser) {
                fileClasses.push('workspace-file--in-app-browser');
            }

            // if (this.isNewBrochureVendorMobile()) {
            //     fileClasses.push('brochure-new--vendor');
            // }

            return fileClasses;
        },

        generateAnalyticsProps: function generateAnalyticsProps(){
            if(this.workspaceFile){

                var ownerName, ownerEmail, ownerId, ownerUserType, ownerHBUserType, ownerCompanyId, ownerCompanyRole, ownerCompanyName, ownerCompanyType;
                if(this.workspaceFile.owner){
                    var owner = this.workspaceFile.owner;
                    ownerName = owner.full_name;
                    ownerEmail = owner.email;
                    ownerId = owner._id;
                    ownerUserType = owner.system_user_type;
                    ownerCompanyRole = owner.company_role;
                    ownerHBUserType = Array.isArray(owner.hb_user_type) ? owner.hb_user_type[0] : '';

                    if(owner.company){
                        var company = owner.company;
                        ownerCompanyId = company._id;
                        ownerCompanyName = company.company_name;
                        ownerCompanyType = company.company_type_name;
                    }
                }
                return {file_id: this.workspaceFile._id,
                    file_title: this.workspaceFile.file_title,
                    file_type: this.workspaceFile.file_type,
                    file_status: this.workspaceFile.status_name,
                    file_workspace_name: this.workspaceFile.workspace_name,
                    file_event_name: this.workspaceFile.event ? this.workspaceFile.event.event_name : '',
                    file_owner_id: ownerId,
                    file_owner_name: ownerName,
                    file_owner_email: ownerEmail,
                    file_owner_user_type: ownerUserType,
                    file_owner_hb_user_type: ownerHBUserType,
                    file_owner_company_id: ownerCompanyId,
                    file_owner_company_name: ownerCompanyName,
                    file_owner_company_type: ownerCompanyType,
                    file_owner_company_role: ownerCompanyRole
                };
            }
        },

        sendFile: function sendFile() {

            if(this.isNewBrochure()) {
                this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile).then(this._fileSendSuccess.bind(this));
            } else {
                if (this.workspaceFile.brochure_container.brochure_proposals.length === 1) {
                    this.ModalService.openSendBrochureWarningModal('FILE.SEND_FILE.BROCHURE._ONE_COLLECTION_WARNING_').then(function send() {
                        this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile).then(this._fileSendSuccess.bind(this));
                    }.bind(this));
                }
                else if (this.workspaceFile.brochure_container.brochure_proposals.length === 0 &&
                    this.workspaceFile.brochure_container.a_la_carte_proposal.package_services.length === 0) {
                    this.ModalService.openSendBrochureWarningModal('FILE.SEND_FILE.BROCHURE._NO_COLLECTIONS_WARNING_').then(function send() {
                        this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile).then(this._fileSendSuccess.bind(this));
                    }.bind(this));
                }
                else {
                    this.WorkspaceFileService.sendFile(this.user, this.workspace, this.workspaceFile).then(this._fileSendSuccess.bind(this));
                }
            }
        },

        resendFile: function resendFile() {
            var promise = this.WorkspaceFileService.resendFile(this.user, this.workspace, this.workspaceFile, this.client);
            if (!promise) {
                return;
            }
            promise.then(this._fileSendSuccess.bind(this));
            return promise;
        },

        _fileSendSuccess: function _fileSendSuccess() {
            this.$scope.$broadcast('workspaceFileSent', { file: this.workspaceFile });
            this.WorkspaceService.gotoWorkspace(this.workspace._id, this.workspace.event._id);
        },

        analyticsProperties: function analyticsProperties(){
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        buildFilmstrip: function buildFilmstrip (){

            this.menuItems.splice(0);
            this.filmstripItems.splice(0);

            this.filmstripItems.push({
                id: 'brochure-intro',
                label: 'intro',
                domElementId: 'js-brochure-intro',
                showDeleteAction: this.allowEdit.bind(this),
                isSortable: false,
                isHidden: function() { return !this.workspaceFile.brochure_container.hasIntro(); }.bind(this),
                isDeletable: function() { return this.workspaceFile.brochure_container.allowDeleteIntro(); }.bind(this),
                order: -1,
                modelType: 'intro'
            });

            this._.each(this.workspaceFile.brochure_container.brochure_proposals, function(proposal, index) {
                this.filmstripItems.push({
                    id: proposal._id,
                    label: proposal.title,
                    modelType: 'proposal',
                    domElementId: "brochureProposal-" + proposal._id,
                    isSelected: this.workspaceFile.selected_proposal_id === proposal._id,
                    isSortable: true,
                    isDeletable: function() { return this.workspaceFile.brochure_container.allowDeletePackage(); }.bind(this),
                    order: proposal.order
                });
            }.bind(this));

            if (this.workspaceFile.brochure_container.a_la_carte_proposal) {
                this.filmstripItems.push({
                    id: 'brochure-a-la-carte',
                    label: this.workspaceFile.brochure_container.a_la_carte_proposal.title,
                    domElementId: 'js-a-la-carte',
                    order: 999,
                    isHidden: function() { return !this.shouldShowALaCartePane(); }.bind(this),
                    isDeletable: function() { return this.workspaceFile.brochure_container.allowDeleteALaCarte(); }.bind(this),
                    modelType: 'aLaCarte'
                });
            }
        },

        onFilmstripSort: function onFilmstripSort(sortedItems) {

            var idsToOrderMap = this._.groupBy(sortedItems, function(item) {
               return item.id;
            });

            this._.each(this.workspaceFile.brochure_container.brochure_proposals, function (proposal) {
               proposal.order = idsToOrderMap[proposal._id][0].order;
            });

            return this.workspaceFile.brochure_container.updateProposalsOrder(this.workspaceFile.brochure_container.brochure_proposals);
        },

        deleteFilmstripItem: function deleteFilmstripItem(item) {
            switch (item.modelType) {
                case 'proposal':
                    return this.deleteBrochureProposalById(item.id);

                case 'aLaCarte':
                    return this.deleteALaCarte();

                case 'intro':
                    return this.deleteBrochureIntro();
            }
        },

        createBrochureProposal: function createBrochureProposal() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_brochure_colleciton);
            return this.BrochuresManager.createBrochureProposal(this.workspaceFile);
        },

        deleteALaCarte: function deleteALaCarte() {
            if (this.workspaceFile.isSent()) {
                return;
            }

            this.workspaceFile.brochure_container.deleteBrochureALaCarteProposal();
        },

        shouldShowDeleteALaCarte: function shouldShowDeleteALaCarte() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode && this.workspaceFile.brochure_container.hasALaCarte();
        },

        createALaCarte: function createALaCarte() {
            if (this.workspaceFile.isSent()) {
                return;
            }

            return this.workspaceFile.brochure_container.createBrochureALaCarteProposal();
        },

        shouldShowALaCartePane: function shouldShowALaCartePane(){

            // we only want to show it if there is something there.
            if (this.workspaceFile.brochure_container.hasALaCarte()) {
                if (this.isPreviewMode || this.isClientMode || this.workspaceFile.isSent()) {
                    return this.workspaceFile.brochure_container.a_la_carte_proposal.package_services.length > 0;
                }

                return true;
            }
            return false;
        },

        createBrochureIntro: function createBrochureIntro(){
            return this.workspaceFile.brochure_container.createGreeting();
        },

        deleteBrochureIntro: function deleteBrochureIntro() {
            return this.workspaceFile.brochure_container.deleteGreeting();
        },

        deleteBrochureProposalById: function deleteBrochureProposalById(id){
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_brochure_colleciton);
            var brochureProposal = this._.find(this.workspaceFile.brochure_container.brochure_proposals, function(prop) {
                return prop._id === id;
            });
            return this.deleteBrochureProposal(brochureProposal);
        },

        deleteBrochureProposal: function deleteBrochureProposal(brochureProposal) {
            this.BrochuresManager.deleteBrochureProposal(this.workspaceFile, brochureProposal).catch(function(resp) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
            }.bind(this));
        },

        /**************
         * TEMPLATES
         */

        getTemplatesViewModel: function getTemplatesViewModel() {
            return this.TemplatesViewService.brochure;
        },

        onTemplateSelect: function onTemplateSelect(template, templateViewModel) {
            this.TemplatesViewService.applyTemplate(
                this.workspaceFile,
                template,
                templateViewModel.name,
                templateViewModel.applyFunction);
        },

        onSaveAsTemplate: function onSaveAsTemplate(templateViewModel) {

            var titleWrapper = {
                title: ''
            };

            this.ModalService.openTemplateSaveAsModal(titleWrapper, '', true,
                function success() {
                    this.AnalyticsService.track(this.$scope, 'click: save as template', {source: 'file'});
                    return templateViewModel.saveFromFileFunction(this.company, this.workspaceFile, titleWrapper.title);
                }.bind(this),
                function cancel() {
                    this.AnalyticsService.track(this.$scope, 'click: cancel save as template', {source: 'file'});
                    return false;
                }.bind(this)
            );
        },

        shouldShowAsideDrawer: function shouldShowAsideDrawer() {
            return this.isOwnerMode && !this.isPreviewMode;
        },

        //////////////////////////////
        // New Brochure Only
        /////////////////////////////

        isNewBrochure: function isNewBrochure() {
            return this.WorkspaceFileService.isNewBrochure(this.workspaceFile);
        },

        shouldShowNewBrochureWarning: function shouldShowNewBrochureWarning() {
            var isBrochureEmpty = false;
            var sections = this.workspaceFile.file_dynamic_content.file_sections;
            if(!sections.length || (sections.length === 1 && !sections[0].file_blocks.length)) {
                isBrochureEmpty = true;
            }

            return this.isEditableMode && !this.isPreviewMode && isBrochureEmpty;
        },

        submitNewBrochure: function submitNewBrochure(clientNotes) {

            this.submitInProgress = true;
            this.workspaceFile.submitNewBrochure(clientNotes)
                .then(
                    function ok() {
                        window.onbeforeunload = null;
                        this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, {event_id: this.workspaceFile.event._id, workspace_id: this.workspaceFile.couple_card_id});
                    }.bind(this)
                ).finally(
                function doFinally() {
                    this.submitInProgress = false;
                }.bind(this)
            );


        },

        viewTemplates: function viewTemplates() {
            //DOM ready
            this.$timeout(function() {
                this.appState.asideDrawerOpen = true;
            }.bind(this), 100);
        },

        reviewBrochure: function reviewBrochure() {

        },
    });
}());