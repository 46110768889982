/**
 * Created by dvircohen on 11/09/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function BrochureTemplateEmptyStateControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, $state, AppStates) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrochureTemplateEmptyStateController';
        AnalyticsService.trackPageView(this, 'BrochureTemplateEmptyStateView');

        this.TemplatesViewService = TemplatesViewService;
        this.$state = $state;
        this.AppStates = AppStates;
    }

    Controllers.BrochureTemplateEmptyStateController = Class(Controllers.BaseController, {
        constructor: BrochureTemplateEmptyStateControllerCtor
    });
}());
