(function () {
    "use strict";

    class BlockDynamicController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, _) {
            super($scope, $injector);
            this.__objectType = 'BlockDynamicController';

            this.$scope = $scope;
            this.AnalyticsService = AnalyticsService;
            this._ = _;
        }

        $onInit() {
            this.$scope.$on('childComponentFocusChanged', function ($event, isFocused) {

                // stop here the event
                $event.stopPropagation();

                // call handler
                this.onFocusChange(isFocused);

            }.bind(this));

            var color = this.block.file_block_style ? this.block.file_block_style.background_color : '#ffffff';
            this.setBlockBackground(color);

            this.showColorPicker = this.block.block_component && (this.block.block_component.hasComponentType('text') ||
                this.block.block_component.hasComponentType('list'));
        }

        onFocusChange(isFocused) {
            if(this.isBlockFocus !== !!isFocused) {

                this.isBlockFocus = !!isFocused;

                // broadcast
                this.$scope.$broadcast('parentComponentFocusChanged', this.isBlockFocus);
            }
        }

        unFocus() {
            if(this.isBlockFocus) {
                this.onFocusChange(false);
            }
        }

        updateBlock(field) {
            this.block.updateBlock([field]);
        }

        updateBlockBackground() {
            this.updateBlock('file_block_style');
        }

        /* Color picker */
        onBackgroundChange(color) {
            this.setBlockBackground(color);
            this.updateBlockBackground();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'background color', block_type: "text"});
        }

        setBlockBackground(color) {
            this.styles = {
                'backgroundColor': color
            };
        }

        onPickerShown() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.showed_block_color_picker, {block_type: "text"});
        }

    }

    Components.BlockDynamic = {
        bindings: {
            block: '=',
            isEditable: '<',
            isEditableByClient: '<',
            isOwnerMode: '<',
            isStyleCustomizable: '<',
        },
        controller: BlockDynamicController,
        controllerAs: 'blockDynamicVm',
        name: 'hbBlockDynamic',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_dynamic/block_dynamic.html',
    };

}());