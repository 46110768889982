(function () {
    'use strict';

    class InteractiveOnboardingProgressController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $element,
            $attrs,
            $injector,
            $timeout,
            _
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingProgressController';

            this._ = _;
            this.$timeout = $timeout;
        }

        $onInit() {
        }

        makeTitleTranslationKey(step) {
            const stepIsComplete = step => step.status === 'complete';

            return this.stepTranslationMap[step.key].concat(
                stepIsComplete(step)
                    ? '._HEADER_TITLE_PAST_'
                    : '._HEADER_TITLE_FUTURE_'
            )
        }

        makeTitleShortTranslationKey(step) {
            return this.stepTranslationMap[step.key].concat('._HEADER_TITLE_SHORT_');
        }

        makeTimeEstimationTranslationKey(step) {
            return this.stepTranslationMap[step.key].concat('._TIME_ESTIMATION_');
        }

        isStepCompleted(step) {
            return step.status === 'complete';
        }

        isFirstPendingStep(step) {
            return this._.isEqual(this.firstPendingStep, step)
        }

    }

    Components.InteractiveOnboardingProgress = {
        bindings: {
            steps: '<',
            currentStep: '<',
            isCollapsed: '<',
            stepTranslationMap: '<',
            firstPendingStep: '<',
        },
        name: 'hbInteractiveOnboardingProgress',
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/progress/progress.html',
        controller: InteractiveOnboardingProgressController,
        controllerAs: 'intOnbProgressVm',
    };
}());