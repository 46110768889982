(function () {
    'use strict';

    class LabelComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'LabelComponentController';
        }
    }

    Components.LabelComponent = {
        controller: LabelComponentController,
        bindings: {
            text: '@',
        },
        name: 'nxLabel',
        templateUrl: 'angular/app/modules/common/ui_components/label/label_component_template.html',
    };

}());