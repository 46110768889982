(function () {
    "use strict";

    // @ngInject
    function QuickbooksManagerCtor(APIService, Routes, UsersManager, ModelFactory, $q) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.UsersManager = UsersManager;
    }

    Services.QuickbooksManager = Class(Services.BaseModelManager, {
        constructor: QuickbooksManagerCtor,

        setQuickbooksIntegration: function setQuickbooksIntegration(state, code, realmId) {
            var url = this.Routes.v2_quickbooks_set_auth_code_path(this.UsersManager.getCurrUser()._id);

            var data = {
                state: state,
                code: code,
                realmId: realmId
            };

            return this.apiCreate(url, data, this.UsersManager.getCurrUser().company, true);
        },

        getQuickbooksAuthUrl: function getQuickbooksAuthUrl() {
            var url = this.Routes.v2_quickbooks_get_auth_url_path();

            return this.apiFetch(url, {});
        }
    });

}());