// @ngInject

Models.BlockComponentModel = function BlockComponentModel(BaseModel, _) {

    return Class(BaseModel, {
        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'BlockComponentModel';
            this._ = _;

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                questions: 'QuestionsCollection',
                block_components: 'BlockComponentsCollection',
                proposal: 'ProposalModel',
            };
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        isDeletable: function isDeletable() {

            if(this.type === 'form' && this.questions && this.questions.length) {
                return this.questions.every(function(question) {
                    return question.isDeletable();
                });
            }

            if(this.type === 'proposal' || this.type === 'redemption_policy') {
                return false;
            }

            return true;
        },

        isRootComponent: function isRootComponent() {
            return (this.__parent && this.__parent.__className !== this.__className);
        },

        hasComponentType: function hasComponentType(componentType) {
          if (this.type === componentType) {
              return true;
          } else if (this.block_components) {
              return this._.any(this.block_components, function(component) {
                  return component.hasComponentType(componentType);
              });
          }
          return false;
        },

        getRootModelType: function getRootModelType() {
            return this.__parent.getRootModelType();
        },

        getRootModel: function getRootModel() {
            return this.__parent.getRootModel();
        },

        /* child component is usually null, unless when a sub-component in a list calls its list component parent
         */
        updateComponent: function updateComponent(childComponent, path, changedFields, params) {
            path = path || [];
            return this.__parent.updateComponent(this, path.concat([this._id]), changedFields, params);
        },

        answerQuestion: function answerQuestion(childComponent, path, changedFields, params) {
            path = path || [];
            return this.__parent.answerQuestion(this, path.concat([this._id]), changedFields, params);
        },

        /*****************************************
         * COMPONENT SPECIFIC
         ******************************************/
        getConnectedQuestions: function getConnectedQuestions() {
            return this.questions.filter(function(question) {
                return question.isSmartQuestion();
            });
        },

        findConnectedQuestion: function findConnectedQuestion(connectedProperty) {
            return this._.find(this.questions, function(question) {
                return question.isQuestionTypeOf(connectedProperty);
            });
        },

        updateFormComponentType: function updateFormComponentType(formParams) {
            return this.updateComponent(this, null, null, formParams);
        },

        updateContent: function updateContent() {
            return this.updateComponent(this, null, null, {content: this.content});
        },

        updateTitle: function updateTitle() {
            return this.updateComponent(this, null, null, {title: this.title});
        },

        resetToDefault: function resetToDefault() {
            return this.updateComponent(this, null, null, {reset: true});
        },

        saveImage: function saveImage() {
            return this.updateComponent(this, null, null, {image: this.image});
        },

        /*****************************************
         * PROPOSAL ACTIONS
         ******************************************/

        updateProposalOnBlock: function updateProposalOnBlock(data) {
            return this.__parent.updateProposalOnBlock(data, this);
        },

        addPackage: function addPackage(proposal, packageId) {
            return this.__parent.addPackage(proposal, packageId, this);
        },

        updatePackage: function updatePackage(proposal, packageModel) {
            return this.__parent.updatePackage(proposal, packageModel, this);
        },

        deletePackage: function deletePackage(proposal, packageModel) {
            return this.__parent.deletePackage(proposal, packageModel, this);
        },

        updateServicesOrder: function updateServicesOrder(proposal, packageModel) {
            return this.__parent.updateServicesOrder(proposal, packageModel, this);
        },

        addCustomItem: function addCustomItem(proposal) {
            return this.__parent.addCustomItem(this);
        },

        updateService: function updateService(proposal, service, packageModel) {
            return this.__parent.updateService(proposal, service, packageModel, this);
        },

        updateServiceQuantity: function updateServiceQuantity(proposal, service, packageModel) {
            return this.__parent.updateServiceQuantity(proposal, service, packageModel, this);
        },

        updatePackageQuantity: function updatePackageQuantity(proposal, packageModel) {
            return this.__parent.updatePackageQuantity(proposal, packageModel, this);
        },

        updateServiceImage: function updateServiceImage(proposal, service, image, packageModel) {
            return this.__parent.updateServiceImage(proposal, service, image, packageModel, this);
        },

        deleteServiceImage: function deleteServiceImage(proposal, service, packageModel) {
            return this.__parent.deleteServiceImage(proposal, service, packageModel, this);
        },

        updateProposalSelection: function updateProposalSelection(proposal, service, packageModel, isSelected) {
            return this.__parent.updateProposalSelection(proposal, service, packageModel, isSelected, this);
        },

        deleteService: function deleteService(proposal, service, packageModel) {
            return this.__parent.deleteService(proposal, service, packageModel, this);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(proposal, order) {
            return this.__parent.updatePackagesAndServicesOrder(proposal, order, this);
        },

        moveService: function moveService(proposal, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return this.__parent.moveService(proposal, serviceId, sourcePackageId, destPackageId, servicesCollection, this);
        },

        /*****************************************
         * BROCHURE SPECIFIC - TEMP
         ******************************************/
        shouldShowTax: function () {
            return false;
        },

        shouldShowSVC: function () {
            return false;
        },

        calculateProposalTax: function calculateProposalTax() {
            return false;
        },

        isInvoice: function isInvoice() {
            return false;
        },

    });
};

