(function () {
    'use strict';

    class FeedItemGiftCardViewerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $filter, Enums) {
            super($scope, $injector);
            this.__objectType = 'FeedItemGiftCardViewerController';

            this.$translate = $translate;
            this.$filter = $filter;
            this.Enums = Enums;
        }

        $onInit() {
            switch (this.activity.action_type) {
                case this.Enums.giftCardStates.promotionPurchased:
                    this.getPurchasedContent();
                    break;
                case this.Enums.giftCardStates.voucherRedeemed: 
                    this.getRedeemedContent();
                    break;
            }
        }

        getPurchasedContent() {
            const { 
                user: {full_name},
                value,
                discount, 
                discount_type,
                currency,
                voucher_code
            } = this.activity;

            let discountText = '';

            if (discount && discount_type) {
                const amountPaid = this.$filter('hbcurrency')(
                    this.getDiscountedAmount(),
                    currency
                );
                const discountAmount = discount_type === 'relative' ?
                    `${discount}%` : 
                    this.$filter('hbcurrency')(discount, currency);

                discountText = this.$translate.instant(
                    'WORKSPACE.FEED.ACTIVITY.GIFT_CARD._DISCOUNT_MESSAGE_',
                    { discountAmount, amountPaid}
                );
            }

            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.GIFT_CARD._PROMOTION_PURCHASED_');
            this.content = this.$translate.instant(
                'WORKSPACE.FEED.ACTIVITY.GIFT_CARD._PURCHASED_MESSAGE_', 
                {full_name, value, discountText, voucher_code, currency}
            );            
        }

        getRedeemedContent() {
            const { 
                voucher_code,
                remaining_value,
                currency,
                redeemed_value
            } = this.activity;

            let paymentStatus = '';

            const redeemedValue = this.$filter('hbcurrency')(redeemed_value, currency)

            if (remaining_value) {
                paymentStatus = this.$translate.instant(
                    'WORKSPACE.FEED.ACTIVITY.GIFT_CARD._REDEEMED_MESSAGE_REMAINING_',
                    { remaining_value, currency}
                );
            } else {
                paymentStatus = this.$translate.instant(
                    'WORKSPACE.FEED.ACTIVITY.GIFT_CARD._REDEEMED_MESSAGE_DONE_',
                    {redeemedValue}
                );
            }

            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.GIFT_CARD._REDEEMED_');
            this.content = this.$translate.instant(
                'WORKSPACE.FEED.ACTIVITY.GIFT_CARD._REDEEMED_MESSAGE_', 
                {redeemedValue, voucher_code, paymentStatus}
            );            
        }

        getDiscountedAmount() {
            const { value, discount, discount_type } = this.activity;
            if (!discount || !discount_type) {
                return value;
            }

            if (discount_type === 'absolute') {
                return value - discount;
            }

            if (discount_type === 'relative') {
                return value - ((discount / 100) * value);
            }

            return value;
        }
    }

    Components.FeedItemGiftCardViewer = {
        bindings: {
            activity: '<'
        },
        controller: FeedItemGiftCardViewerController,
        name: 'hbFeedItemGiftCardViewer',
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_gift_card_viewer_template.html',
    };

}());