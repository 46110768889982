(function () {
    "use strict";

    Controllers.ArchiveProjectController = class ArchiveProjectController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, Constants, WorkspacesManager, workspaceId, source, AnalyticsService) {

            super($scope, $injector);
            this.__objectType = 'ArchiveProjectController';

            this.$modalInstance = $modalInstance;
            this.WorkspacesManager = WorkspacesManager;
            this.workspaceId = workspaceId;
            this.AnalyticsService = AnalyticsService;

            this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceId, true);
            this.isSendAmendment = true;

            this.currentStepIndex = 0;
            this.archiveReasons = Constants.BlockReasons;
            this.selectedArchiveReason = source === 'corona' ? {displayNameKey: 'PIPELINE.BLOCK_REASONS._CORONA_', serverValue: 'Corona'} : null;
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.archive_project, {source: source});
        }

        onArchiveReasonChange(item) {
            this.selectedArchiveReason = item;
        }

        onArchive() {
            this.requestInProgress = true;
            const trackingData = {
                workspace_id: this.workspaceId,
                archiveReason: this.selectedArchiveReason.serverValue,
                notifying_members: false
            };

            return this.WorkspacesManager.blockWorkspace(
                this.workspace,
                this.selectedArchiveReason.serverValue
            )
                .then(() => {
                    this.AnalyticsService.trackSuccess(
                        this,
                        this.AnalyticsService.analytics_events.workspace_block,
                        trackingData
                    );
                })
                .catch((resp) => {
                    this.AnalyticsService.trackError(
                        this,
                        this.AnalyticsService.analytics_events.workspace_block,
                        resp,
                        trackingData
                    );
                })
                .finally(() => {
                    this.$modalInstance.close();
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.archive_project);
                });
        }


        close() {
            this.$modalInstance.dismiss();
        }

        previous() {
            this._switchStep(this.currentStepIndex - 1);
        }

        switchToNextStep() {
            this._switchStep(this.currentStepIndex + 1);
        }
    };
}());