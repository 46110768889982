(function () {
    'use strict';

    // @ngInject
    function HomeManagerCtor($q, APIService, Routes, CompaniesManager, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);

        this.Routes = Routes;
    }

    Services.HomeManager = Class(Services.BaseModelManager, {
        constructor: HomeManagerCtor,

        getHomebookkeeping: function getHomebookkeeping() {
            var url = this.Routes.v2_get_home_bookkeeping_path();
            return this.apiFetch(url);
        },

        getCalendar: function getCalendar(timezone) {
            var url = this.Routes.v2_get_home_calendar_path();
            return this.apiFetch(url, { timezone: timezone });
        },

        getRecentActivity: function getRecentActivity() {
            var url = this.Routes.v2_get_home_recent_activity_path();
            return this.apiFetch(url);
        },

        getIsPicTimeCouponEligible: function getIsPicTimeCouponEligible(accountId) {
            var url = this.Routes.v2_get_pictime_coupon_eligibility_path(accountId);
            return this.apiFetch(url);
        }
    });

}());