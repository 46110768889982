/**
 * Created by yaniv on 13/7/17.
 *
 * This directive adds debounce for the input element is attached to.
 * With debounce, you can add a delay for updating the ngModel value. This delay resets after each change.
 * simply put hb-debounce='500' to set debounce of 500 ms, or just use 'hb-debounce' for the default 300 ms.
 *
 */


(function () {
    'use strict';

    Directives.InputDebounce = function InputDebounce() {

        function InputDebounceLink($scope, element, attr, ngModelCtrl) {

            var defaultDebounceTimeInMs = 300;
            ngModelCtrl.$options = {
                updateOn: 'blur',
                updateOnDefault: true,
                debounce: {
                    'blur': 0,
                    'default': attr.hbDebounce ? parseInt(attr.hbDebounce) : defaultDebounceTimeInMs
                }
            }
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: InputDebounceLink
        };
    };


}());
