(function () {
    "use strict";

    // @ngInject
    function NativeControllerCtor($scope, $injector, $rootScope, MobileAppService, UsersManager, CompaniesManager) {

        this.constructor.$super.call(this, $scope, $injector, $rootScope, MobileAppService);
        this.__objectType = 'NativeController';
        this.$rootScope = $rootScope;
        this.currUser = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();

        let intervalId;

        this.$rootScope.$on(MobileAppService.INBOUND.FLOW._NATIVE_LOADED_RECEIVED_ACK_, function() {
            clearInterval(intervalId);
            intervalId = null;
        });

        // Wait for template to load and then notify mobile
        var deregistrationFn = $scope.$watchCollection(() => {
            return this.currUser && this.currUser.ab_tests && this.company && (document.getElementById("honeybook_native_template_container") !== null);
        }, (newValue) => {
            if (!!newValue) {
                deregistrationFn();
                if (!intervalId) {
                    intervalId = setInterval(() => {
                        MobileAppService.triggerAppEvent(MobileAppService.OUTBOUND.native_controller_loaded);
                    }, 500);
                }
            }
        });
    }


    Controllers.NativeController = Class(Controllers.BaseController, {
        constructor: NativeControllerCtor
    });
}());
