Directives.SearchForProjectDirective = function SearchForProjectDirective() {

    // @ngInject
    function SearchForProjectDirectiveControllerFunc($scope, $injector, SearchablesManager, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SearchForProjectDirective';
        this.$timeout = $timeout;
        // project search
        this.SearchablesManager = SearchablesManager;
        this.searchingWorkspaceShow = false;
        this.searching = false;
        this.workspacesSearchText = this.eventName;
        this.selectedWorkspace = !!this.eventName;
        this.searchDataBeingFetched = false;
        this.workspacesSearchResults = [];

        this.designApply = this.design || false;
        this.showResults = false;
    }

    var SearchForProjectDirective = Class(Controllers.BaseController, {
        constructor: SearchForProjectDirectiveControllerFunc,

        workspaceSearchInputChanged: function workspaceSearchInputChanged() {
            if ((this.workspacesSearchText && this.workspacesSearchText.length >= 2) || !!this.memberEmails) {
                this.searchDataBeingFetched = true;
                this.workspacesSearchResults = this.SearchablesManager.search(this.workspacesSearchText, 'couple_cards', {hideTeamMembers: true, memberEmails: this.memberEmails});
                this.register(this.workspacesSearchResults, 'success', function onSearchDataFetched(callbackReason) {
                    this.showResults = this.shouldShowResultList();
                    this.searchDataBeingFetched = false;
                    this.isSearchTriggered = true;
                }.bind(this));
            } else {
                this.workspacesSearchResults = [];
            }
        },

        shouldShowResultList: function shouldShowResultList () {
          return (this.workspacesSearchText && this.workspacesSearchText.length >= 2) || !!this.memberEmails;
        },


        clearSearch: function clearSearch($event) {
            this.workspacesSearchText = null;
            this.workspacesSearchResults = [];
            this.selectedWorkspace = null;

            if(this.eventId) {
                this.eventId = null;
                this.onChange({projectId: null});
            }

            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
        },

        handleWorkspaceItemSelected: function handleWorkspaceItemSelected(workspaceSearchItem) {
            this.searchingWorkspaceShow = false;
            this.showResults = false;

            this.selectedWorkspace = workspaceSearchItem;
            this.workspacesSearchText = workspaceSearchItem.properties.event_name;
            this.searching = false;
            this.eventId = workspaceSearchItem.properties.event_id;
            this.onChange({projectId: workspaceSearchItem.properties.event_id});
        },

        selectedWorkspaceName: function selectedWorkspaceName() {
            if (this.selectedWorkspace === null) {
                return "Search for project";
            } else {
                return this.selectedWorkspace.properties.event_name;
            }
        },

        handleClickOutsideSearch: function handleClickOutsideSearch() {
            if(this.showResults){
                if (!this.selectedWorkspace) {
                    this.clearSearch();
                } else {
                    this.workspacesSearchText = this.workspacesSearchText;
                }

                this.searching = false;
                this.showResults = false;
            }
        },

        handleSearchFocused: function handleSearchFocused() {
            this.searching = true;
            this.showResults = true;

            //https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
            if (/Android 4\.[0-3]/.test(navigator.appVersion)) {
                window.addEventListener("resize", function(){
                    if(document.activeElement.tagName=="INPUT"){
                        window.setTimeout(function(){
                            document.activeElement.scrollIntoViewIfNeeded();
                        },0);
                    }
                })
            }

        },

        searchWorkspace: function searchWorkspace($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.searchingWorkspaceShow = true;
        },

    });
    return {
        scope: {
            eventId: '=',
            eventName: '=',
            memberEmails: '=',
            onChange: '&',
            design: '@?',
            labelName: '@?',
            placeholderName: '@?',
            isDisabled: '=?'
        },
        templateUrl: 'angular/app/modules/common/ui_components/search_for_project/search_for_project_directive.template.html',
        controller: SearchForProjectDirective,
        controllerAs: 'searchForProjectVm',
        bindToController: true
    };
};