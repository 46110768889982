(function () {
    'use strict';
    Controllers.ConnectProjectModalController = class ConnectProjectModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, options, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'ConnectProjectModalController';
            this.modalInstance = $modalInstance;

            this.AnalyticsService = AnalyticsService;
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.connect_project_to_meeting_modal);

            this.isConnectingProject = false;
            this.selectedWorkspaceBind = {};
            this.hasActiveProjects = options.hasActiveProjects;
        }

        setConnectedProject(selected) {
            this.selectedWorkspace = selected.selectedWorkspace;
        }

        connectProject() {
            if(!this.selectedWorkspace || (this.selectedWorkspace && this.selectedWorkspace.properties.event_name === 'No project')) {
                this.showError = true;
            }
            else {
                this.modalInstance.close({selectedWorkspace: this.selectedWorkspace});
            }
        }

        createNewProject() {
            this.modalInstance.close({shouldCreateNewProject: true});
        }


        close() {
            this.modalInstance.close({selectedWorkspace: {
                properties: {
                    event_name: 'No project'
                }
           }});
        }
    };
}());