/**
 * Created by inonstelman on 12/29/14.
 */

(function () {
    "use strict";

    var identityMap = {};

    // @ngInject
    function DataRepositoryCtor(jStorage, ExtendService, ModelFactory) {
        this.jStorage = jStorage;
        this.ExtendService = ExtendService;
        this.ModelFactory = ModelFactory;
        this.masterCollections = {};
        this.identityMap = {};
        this.persistenceHash = {};
    }


    Services.DataRepository = Class(function () {


        return {

            constructor: DataRepositoryCtor,

            _putInMap: function _putInMap(className, id, object) {
                if (object) {
                    var mappedObject;
                    if (this.identityMap[className]) {
                        mappedObject = this.identityMap[className][id];
                        if (mappedObject) {
                            this.ExtendService(mappedObject, object);
                        } else {
                            this.identityMap[className][id] = object;
                            mappedObject = object;
                        }
                    } else {
                        this.identityMap[className] = {};
                        this.identityMap[className][id] = object;
                        mappedObject = object;
                    }
                    return mappedObject;
                }
            },

            _removeFromMap: function _removeFromMap(className, id) {
                var removedObject;
                if (this.identityMap[className]) {
                    removedObject = this.identityMap[className][id];
                    if (removedObject) {
                        this.identityMap[className][id] = undefined;
                    }
                }
                return removedObject;
            },

            _getFromMap: function _getFromMap(className, id) {
                var mappedObject;
                if (this.identityMap[className]) {
                    if (id) {
                        mappedObject = this.identityMap[className][id];
                    }
                    else{
                        mappedObject = this.identityMap[className];
                    }
                }
                return mappedObject;
            },

            _clearMap: function _clearMap() {
                this.identityMap = {};
            },

            _clearMasterCollections: function _clearMasterCollections(){
                this.masterCollections = {};
            },

            getMasterCollection: function getMasterCollection(modelName){
                var collection = this.masterCollections[modelName];
                if(!collection){
                    collection =this.ModelFactory.newCollectionByModelName(modelName);
                    collection.isMaster = true;
                    this.masterCollections[collection.childsType()] = collection;
                }
                return collection;
            },

            cachePut: function cachePut(object, className, objId) {
                if (!object) {
                    return null;
                }

                if (!className) {
                    if (!object.className || typeof object.className !== 'function') {
                        return null;
                    }

                    className = object.className();
                }

                if (!objId) {
                    if (!object.id || typeof object.id !== 'function') {
                        return null;
                    }

                    objId = object.id();
                }


                var exists = this._getFromMap(className, objId);
                var lastAdded = exists && exists._lastServerUpdate;
                var ret = this._putInMap(className, objId, object);

                var neverAddedToMasterCollection = (!exists || !lastAdded);

                if(neverAddedToMasterCollection && objId && ret._lastServerUpdate){
                    var masterCollection = this.masterCollections[className];
                    if(masterCollection){
                        masterCollection.add(ret);
                    }
                }
                return ret;
            },

            cacheGet: function cacheGet(className, objId) {

                return this._getFromMap(className, objId);

            },

            cacheDelete: function cacheDelete(object, className, objId) {

                if (!className) {
                    if (!object || !object.className || typeof object.className !== 'function') {
                        return null;
                    }

                    className = object.className();
                }

                if (!objId) {
                    if (!object || !object.id || typeof object.id !== 'function') {
                        return null;
                    }

                    objId = object.id();
                }

                var removedObject = this._removeFromMap(className, objId);

                if(removedObject && objId){
                    var masterCollection = this.masterCollections[className];
                    if(masterCollection){
                        masterCollection.remove(removedObject);
                    }
                }
                return removedObject;
            },

            cacheClearAll: function cacheClearAll() {
                this._clearMap();
                this._clearMasterCollections();
            },

            storagePut: function storagePut(key, value) {
                this.jStorage.set(key, value);
            },

            storageGet: function storageGet(key) {
                return this.jStorage.get(key);
            },

            storageDelete: function storageDelete(key) {
                return this.jStorage.deleteKey(key);
            },

            storageClearAll: function storageClearAll() {
                this.jStorage.flush();
            },

            persistentClearAll: function persistentClearAll() {
                this.persistenceHash = {};
            },

            persistentPut: function persistentPut(key, value) {
                var oldValue = this.persistenceHash[key];
                var copyOfElementToStore;

                if (oldValue) {
                    if (angular.equals(oldValue, value)) {
                        return;
                    }
                }

                //we want to save a copy of the given element so that the angular.equals will not use the === for object comparison
                copyOfElementToStore = angular.copy(value);
                this.persistenceHash[key] = copyOfElementToStore;
            },

            persistentGet: function persistentGet(key, defaultVal) {
                var returnedValTemp, returnedVal, copyOfElementToStore;
                var valueStoredOnKey = this.persistenceHash[key];
                if( valueStoredOnKey !== undefined && valueStoredOnKey !== null){
                    returnedValTemp = valueStoredOnKey;
                } else {
                    returnedValTemp = defaultVal;
                    //store this last default value that was returned to support getting default on another view after a default was added
                    copyOfElementToStore = angular.copy(returnedValTemp);
                    this.persistenceHash[key] = copyOfElementToStore;
                }

                //we want to save a copy of the given element so that the angular.equals will not use the === for object comparison
                returnedVal = angular.copy(returnedValTemp);

                return returnedVal;
            },

            persistentDelete: function persistentDelete(key) {
                var oldValue = this.persistenceHash[key];
                if (oldValue) {
                    delete this.persistenceHash[key];
                    return oldValue;
                }
            }
        };

    });

}());