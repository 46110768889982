(function () {
    "use strict";

    Services.InstantBrandingService = class InstantBrandingService {

        // @ngInject
        constructor(UsersManager, CompaniesManager, UiPersistenceService, _, StatsigService, Enums) {
            this.UsersManager = UsersManager;
            this.user = UsersManager.getCurrUser();

            this.CompaniesManager = CompaniesManager;
            this.company = this.CompaniesManager.getCurrCompany();

            this.UiPersistenceService = UiPersistenceService;
            this._ = _;
            this.StatsigService = StatsigService;
            this.Enums = Enums;

            this.instantBrandingUiPersistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.instantBranding, {});

            this.getFetchedSuggestedAssetsAnalyticsArgs = this.getFetchedSuggestedAssetsAnalyticsArgs.bind(this);
            this.getSavedSuggestedAssetsAnalyticsArgs = this.getSavedSuggestedAssetsAnalyticsArgs.bind(this);
            this.isInstantBrandingImage = this.isInstantBrandingImage.bind(this);
            this.isInstantBrandingBrandColor = this.isInstantBrandingBrandColor.bind(this);
        }

        getFetchedSuggestedAssetsAnalyticsArgs() { 
            return this.getInstantBrandingVariant().then(function success(instantBrandingVariant) {
                if (this.shouldGetOnlyBasicProps()) { 
                    return { instant_branding_abtest_variant: instantBrandingVariant };
                }
                return {
                    instant_branding_abtest_variant: instantBrandingVariant,
                    suggested_brand_color_fetch_status: this.instantBrandingUiPersistency.brandColor ? 'imported' : 'missing',
                    suggested_icon_image_fetch_status: this.instantBrandingUiPersistency.iconImage ? 'imported' : 'missing',
                    suggested_profile_image_fetch_status: this.instantBrandingUiPersistency.profileImage ? 'imported' : 'missing',
                    number_of_fetched_suggested_assets: this.instantBrandingUiPersistency.numberOfSavedAssets || 0,
                };    
            }.bind(this)).catch(function error() {
                return {};
            });
        }

        getSavedSuggestedAssetsAnalyticsArgs() {
            return this.getInstantBrandingVariant().then(function success(instantBrandingVariant) {
                if (this.shouldGetOnlyBasicProps()) { 
                    return { instant_branding_abtest_variant: instantBrandingVariant };
                }

                let numberOfApprovedAssets = 0;
                const processAsset = (assetKey, assetCheckFunction, assetUrl) => {
                    if (!this.instantBrandingUiPersistency[assetKey]) {
                        return 'missing';
                    }
                    const isInstantBrandingAsset = assetCheckFunction(assetUrl);
                    numberOfApprovedAssets += isInstantBrandingAsset ? 1 : 0;
                    return isInstantBrandingAsset ? 'approved' : 'rejected';
                };

                const profileImageArg = processAsset('profileImage', this.isInstantBrandingImage, this.user.profile_image.url);
                const iconImageArg = processAsset('iconImage', this.isInstantBrandingImage, this.company.icon_image.url);
                const brandColorArg = processAsset('brandColor', this.isInstantBrandingBrandColor);

                return {
                    instant_branding_abtest_variant: instantBrandingVariant,
                    suggested_brand_color_status: brandColorArg,
                    suggested_icon_image_status: iconImageArg,
                    suggested_profile_image_status: profileImageArg,
                    number_of_fetched_suggested_assets: this.instantBrandingUiPersistency.numberOfSavedAssets || 0,
                    number_of_approved_suggested_assets: numberOfApprovedAssets,
                };
            }.bind(this)).catch(function error() {
                return {};
            });
        }

        isInstantBrandingImage(url) {
            return url.includes('instant_branding');
        }

        isInstantBrandingBrandColor() {
            const suggestedAssets = this.company.suggested_assets;
            if (suggestedAssets && suggestedAssets.brand_colors) {
                return this.company.suggested_assets.brand_colors.includes(this.company.brand_color);
            }
        }

        getInstantBrandingVariant() {
            const mobileQuizOnboardingStarted = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.mobileQuizOnboardingStarted, {});
            const hasSeenQuiz = mobileQuizOnboardingStarted && mobileQuizOnboardingStarted.started;
            if (!hasSeenQuiz) {
                return Promise.resolve(undefined);
            }

            if (this.company.isConsultant() && this.user.onboarding_container && this.user.onboarding_container.onboarding_start_device !== "mobile") {
                return Promise.resolve(true);
            }

            return this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.instantBranding, 'variant', undefined, false);
        }

        // return true if the member skipped the instant branding quiestion or got control or non-participant
        shouldGetOnlyBasicProps() {
            // if we have websiteUrl it means the member enter website url on the quiz -> the member didn't skip
            const websiteUrl = this.company.website_url; 
            return this._.isEmpty(this.instantBrandingUiPersistency) && !websiteUrl;
        }

    };
}());
