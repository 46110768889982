Directives.VoKeyUpDirective = function VoKeyUpDirective() {

    // @ngInject
    function VoKeyUpDirectiveControllerFunc($timeout,$scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'VoKeyUpDirectiveController';
        this.$timeout = $timeout;
    }

    var VoKeyUpDirectiveController = Class(Controllers.BaseController, {
        constructor: VoKeyUpDirectiveControllerFunc,

    });

    function link(scope, element, attrs) {
        element.bind('keydown', function(event) {
            var keyCode = event.which || event.keyCode;

            if (keyCode === 16 || keyCode === 17 || keyCode === 18 || keyCode === 91 || keyCode === 93) {
                return;
            }

            if (keyCode !== 13) {
                scope.voKeyUpDirectiveVm.$timeout(function() {
                    scope.$apply(function() {
                        scope.$eval(attrs.onKeyUp);
                    });
                    event.preventDefault();
                }, 0);
            }
            if (keyCode === 13) {
                scope.voKeyUpDirectiveVm.$timeout(function() {
                    scope.$apply(function() {
                        scope.$eval(attrs.onEnterKey);
                    });
                    event.preventDefault();
                }, 0);
            }
        });
    }

    return {
        link: link,
        restrict: 'AE',
        controller: VoKeyUpDirectiveController,
        controllerAs: 'voKeyUpDirectiveVm',
        bindToController: true
    };
};
