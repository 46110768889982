(function () {
    "use strict";

// class TextInputController extends Components.InputBaseController {

// @ngInject
function PhoneInputControllerCtor(
        $scope, $element, $attrs, $compile, $translate, _, $window) {

    this.constructor.$super.call(this, $scope, $element, $attrs, $compile, $translate, _, $window);

    this.__objectType = 'PhoneInputController';
    // TODO: Remove after ES6
    this.isRequired = false;
    this.validationError = '';
    this._ = _;
    this.$translate = $translate;
}

// TODO(sean): Remove and replace with Es6
var PhoneInputController = Class(Components.InputBaseController, {
    constructor: PhoneInputControllerCtor,

    // $onInit() {
    $onInit: function $onInit() {
        // super.$onInit();
        // super.validate();
        this.constructor.$superp.$onInit.call(this);
        this.textInputVariant = 'tel';
        this.validate();
        this.cssClass['form-element--phone'] = true;

        // Confusing, but this is used to set the initial value of the
        // ng-form HTML element and then after initialization
        // "formName" represents that DOM element.
        //this.formName = `${this.name}Form`;
        this.formName = this.name + 'Form';
    },

    // onChange() {
    onChange: function onChange() {
        // super.onChange();
        // super.validate();
        this.constructor.$superp.onChange.call(this);
        this.validate();
    },

    // shouldShowError() {
    shouldShowError: function() {
        return (this.validationError !== ''
            && (this.formName[this.name].$dirty
                || this.formName.$$parentForm.$submitted)
        );
    },

    // validate() {
    validate: function() {
        var baseValidation = this.constructor.$superp.validate.call(this);
        if (!baseValidation) {
            return false;
        }

        // Loose US and intl phone number validation
        // make sure it consists of numbers, spaces, dashes, periods, or paranthesis
        var isPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(this.value);
        if (!isPhone) {
            this.validationError = this.$translate.instant('ERRORS._INVALID_PHONE_NUMBER_');
            return false;
        }
    },

});

Components.PhoneInput = {
    templateUrl: 'angular/app/modules/common/ui_components/form/text_input.html',
    controller: PhoneInputController,

    // Requiring ngModel is a hack that allows us access to the
    // ng form API ($dirty, $valid, $isSubmitted, etc), while still preserving
    // the essence of a unidirectional data flow (e.g. Container -> form -> inputs)
    require: {
        model: 'ngModel'
    },

    bindings: {
        flavor: '@',
        label: '@',
        maximumLength: '@',
        minimumLength: '@',
        name: '@',
        caption: '@',
        tabIndex: '@',
    }
};

}());