(function () {
    'use strict';

    class ClientPortalLoginCreationController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, $stateParams) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalLoginCreationController';

            this.UsersManager = UsersManager;
            this.guid = $stateParams.guid;
            this.userId = $stateParams.id;
        }

        onCreateLoginClicked(event) {
            event.preventDefault();
            this.createLoginForm.$setSubmitted();

            if (this.password !== this.passwordConfirmation) {
                return;
            }

            this.creatingPassword = true;

            this.UsersManager.resetUserPassword(this.userId, this.guid, this.password, this.user)
                             .then(this.goToLogin)
                             .catch((res) => {
                                 if (res && res.error && res.error.message) {
                                     this.error = res.error.message;
                                     setTimeout(() => {
                                         this.error = '';
                                     }, 3000);
                                 }
                                 this.isSettingPassword = false;
                             });
        }
    }

    Components.ClientPortalLoginCreation = {
        bindings: {
            portalModel: '<',
            user: '<',
            goToLogin: '&'
        },
        controller: ClientPortalLoginCreationController,
        name: 'hbClientPortalLoginCreation',
        templateUrl: 'angular/app/modules/core/features/login/client_portal/client_portal_login_creation_template.html',
    };

}());