(function () {
    "use strict";

    var _WorkspaceFilesManager;
    // @ngInject
    function DashboardWorkspaceFilesControllerCtor($scope, $translate, $injector, $timeout,
                                                   UsersManager, WorkspaceFilesManager,
                                                   AnalyticsService, AppConfigService, EventService) {

        var self = this;
        this.constructor.$super.call(this, $scope, $injector);

        _WorkspaceFilesManager = WorkspaceFilesManager;

        this.__objectType = 'DashboardWorkspaceFilesController';
        AnalyticsService.trackPageView(this, 'files dashboard');

        this.WorkspaceStatusNames = {
            lead: 'lead',
            booked: 'client'
        };
        this.$timeout = $timeout;

        this.user = UsersManager.getCurrUser();
        this.isClient = this.user.isClient();

        this.workspaceFiles = _WorkspaceFilesManager.getWorkspaceFilesForUser(this.user);
        this.shownFilesLimit = 30;
        this.EventService = EventService;

        this.bookedFilesNumber = 0;
        this.leadFilesNumber = 0;

        this.dashboardState = AppConfigService.dashboardState();
        this.dashboardState.filesLoading = true;

        this.sortByLabel = "";
        // sorting initialization
        $translate('DASHBOARD.SORT._CREATE_DATE_').then(function translateResolved(text){
            self.sortByLabel = text;
            self.sortByKey = 'created_at';
            self.sortOrder = true;
            self.sortById = 'sort_by_creation_date';
            angular.element(document.querySelector('#'+self.sortById)).addClass("active"); // toggle first selection

        });

        // Filters handling
        this.filteredFiles = [];
        this.filter = null;
        this.showFilters = this.user.isVendor();
        this.updateFilter = function updateFilter(filter) {
            self.filter = filter;
            self.updateShownFilesLimit(30);

        };
        this.filterCards = function filterCards(file) {
            return self.filter === null || self.filter === file.workspace.workspace_status_type;
        };

        // Model events registration
        this.register(this.workspaceFiles, 'success', this.gotSomeWorkspaceFiles);
        this.register(this.workspaceFiles, 'pagingEnded', this.gotAllWorkspaceFiles);
        this.register(this.workspaceFiles, 'update', this.collectionUpdating);
        this.register(this.workspaceFiles, 'error', this.collectionError);

        this.mobileSearchCollapse = function mobileSearchCollapse() {
            this.mobileSearchOpen = !this.mobileSearchOpen;
        };
    }

    Controllers.DashboardWorkspaceFilesController = Class(Controllers.BaseController, {

        constructor: DashboardWorkspaceFilesControllerCtor,

        _countWorkspaceFilesImp: function _countWorkspaceFilesImp() {
            this.bookedFilesNumber = 0;
            this.leadFilesNumber = 0;

            this.workspaceFiles.forEach(function (file) {
                if (file.active_state && file.workspace) {
                    switch (file.workspace.workspace_status_type) {
                        case this.WorkspaceStatusNames.booked:
                            this.bookedFilesNumber++;
                            break;
                        case this.WorkspaceStatusNames.lead:
                            this.leadFilesNumber++;
                            break;
                        default :
                            console.log('cant handle workspace status : ' + file.workspace.workspace_status_cd);
                            console.log(file.workspace.workspace_status);
                    }
                }
            }.bind(this));
        },

        gotSomeWorkspaceFiles: function gotSomeWorkspaceFiles(message, status) {
            this.dashboardState.filesLoading = true;
            this._countWorkspaceFilesImp();
        },

        gotAllWorkspaceFiles: function gotAllWorkspaceFiles(message, status) {
            this.dashboardState.filesLoading = false;

            this.updateShownFilesLimit(this.shownFilesLimit);

        },

        collectionUpdating: function collectionUpdating(){
            this.dashboardState.filesLoading = true;
        },

        collectionError: function collectionError(){
            this.dashboardState.filesLoading = false;
        },

        goToWorkspaceFile: function goToWorkspaceFile(workspace_file_id) {
            this.goToState(this.AppStates.root_core_workspaceFile, {workspace_file_id: workspace_file_id});
        },

        getWorkspaceFilesCount: function getWorkspaceFilesCount() {
            return this.workspaceFiles.length;
        },

        sortKeySelected: function sort($event) {
            angular.element(document.querySelector('#'+this.sortById)).removeClass("active"); // untoggle last selection
            this.sortByKey = angular.element($event.target.attributes.sortkey).val();
            this.sortByLabel = angular.element($event.target).text();
            this.sortById = $event.target.id;
            angular.element(document.querySelector('#'+this.sortById)).addClass("active"); // toggle first selection

            var tempSortOrder = angular.element($event.target.attributes.sortreverse).val();
            this.sortOrder = (tempSortOrder === 'true');
        },


        showMoreFiles: function showMoreFiles(){
            this.updateShownFilesLimit(this.shownFilesLimit + 50);
        },

        updateShownFilesLimit: function updateShownFilesLimit(limitTo){
            this.shownFilesLimit = limitTo;
            var self = this;
            this.$timeout(function timedOut(){
                self.hasMoreFilesToShow = self.shownFilesLimit <= self.filteredFiles.length;
            });

        },

        createEvent: function createEvent() {
            this.EventService.createEventCommand();
        },

        workspaceCardEnter: function workspaceCardEnter ($event, entering) {
            var targetElm = $($event.currentTarget);
            var nextElmSibilings = targetElm.nextAll('li');
            var prevElmSibilings = targetElm.prevAll('li');
            var inactive = 'nx-workspace__list-item--inactive';
            var active = 'nx-workspace__list-item--active';

            if (entering) {
                nextElmSibilings.removeClass(inactive).addClass(inactive);
                prevElmSibilings.removeClass(inactive).addClass(inactive);
                targetElm.addClass(active)
            } else {
                nextElmSibilings.removeClass(inactive);
                prevElmSibilings.removeClass(inactive);
                targetElm.removeClass(active)
            }
        }
    });
}());
