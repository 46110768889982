(function () {
    "use strict";

    // @ngInject
    function CompanySettingsMobileControllerCtor($scope, $injector, UsersManager, FeaturesService,
                                                 Enums, $stateParams) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsMobileController';
        this.openEditBillingInfoModalOnMount = $stateParams.openBillingModal || false;

        this.user = UsersManager.getCurrUser();
        this.showClientPortal = this.user.shouldShowClientPortal();
        this.company = this.user.company;
        this.wasInitialUrlClaimed = this.company.company_url_prefixes && this.company.company_url_prefixes.length;
        this.isNewClientPortalActive = this.wasInitialUrlClaimed;
        this.Enums = Enums;
    }

    Controllers.CompanySettingsMobileController = Class(Controllers.BaseController, {
        constructor: CompanySettingsMobileControllerCtor,
    });
}());
