(function () {
    "use strict";

    // @ngInject
    function UploadPhotoControllerCtor($scope, $modalInstance, model, AnalyticsService, PhotosUploadManager, $timeout, PopupMessageService) {

        this.constructor.$super.call(this, $scope);
        this.__objectType = 'UploadPhotoController';

        this.$timeout = $timeout;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;

        this.model = this.PhotosUploadManager.getUpdatedModelData(model);
        if (this.model.acceptFilesFilter == null || this.model.acceptFilesFilter === '') {
            this.model.acceptFilesFilter = 'image/*';
        }
        this.modalInstance = $modalInstance;

        if(model.footerNote){
            this.footerNote = model.footerNote;

        }

        if (model.forceUploadToS3) {
            this.forceUploadToS3 = model.forceUploadToS3;
        }

        this.fileReaderSupported = window.FileReader != null;

        this.title = 'UPLOAD_PHOTO._TITLE_' + this.model.type.toUpperCase() + '_';
        this.disclaimer = 'UPLOAD_PHOTO._DISCLAIMER_' + this.model.type.toUpperCase() + '_';

        this.analyticsName = 'Upload' + (model.type ? model.type.replace(model.type[0], model.type[0].toUpperCase()) : 'File') + 'Dialog';

        this.selectedFiles = null;
        this.files = null;



        // 15 MB limit
        this.fileSizeLimit = 15 * 1024 * 1024;

        $scope.$watch('uploadPhotoVm.selectedFiles', function (newValue, oldValue) {

            if(newValue && !angular.isArray(newValue) ){
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                this.files = [newValue];
            } else {
                this.files = this.selectedFiles;
            }

            var removedLargeFiles = false;
            if (this.files != null && this.files.length > 0) {
                var fileSizeLimit = this.fileSizeLimit;
                var cleanedFiles = this.files.filter(function (file) {
                    return file.size <= fileSizeLimit;
                });
                if (cleanedFiles.length < this.files.length) {
                    removedLargeFiles = true;
                    this.files = cleanedFiles;
                }
            }

            if (removedLargeFiles) {
                this.PopupMessageService.showErrorAlert('UPLOAD_FILE._ERROR_FILE_TOO_LARGE_');
            }
        }.bind(this));
    }


    Controllers.UploadPhotoController = Class(Controllers.BaseController, {

        constructor: UploadPhotoControllerCtor,

        dismiss: function dismiss() {
            this.closing = true;
            this.modalInstance.dismiss('cancel');
        },

        close: function close(result) {
            this.closing = true;
            this.modalInstance.close(result);
        },

        upload:  function upload(files) {
            if (this.isUploading || !this.files || this.files.length === 0) {
                return;
            }

            var self = this;
            self.isUploading = true;

            if (this.model.dontUpload) {
                self.close(files);
            }
            else {
                if (files && files.length) {
                    var promise = this.PhotosUploadManager.uploadPhoto(files, this.model, self, this.forceUploadToS3);
                    promise.then(
                        function (){
                            var analyticsArgs = {
                                photo_type: self.model.type,
                                files_number:files.length
                            };
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_uploaded_photos_successful, analyticsArgs);
                            self.close(files);
                        },
                        function (resp){
                            self.isUploading = false;
                            self.PopupMessageService.showErrorAlert('Oops, there was a problem uploading the files. Please try again.');
                            self.dismiss();
                        }
                    );
                }
            }
        },

        generateThumb : function generateThumb($files) {
            var self = this;
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.files_selected_dropped_to_ngf, {});

            var files;
            if($files && !angular.isArray($files)){
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                files = [$files];
            } else {
                files = $files;
            }

            if (files != null && files.length > 0) {
                for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                    var file = files[fileIndex];

                    var analyticsArgs = {
                        file_reader_supported: self.fileReaderSupported,
                        type: file.type
                    };

                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.generating_thumb_for_selected_image, analyticsArgs);

                    if (self.fileReaderSupported && file.type.indexOf('image') > -1) {
                        var fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = function (e) {
                            self.$timeout(function () {
                                file.dataUrl = e.target.result;
                            });
                        };
                    }
                }
            }
            else {
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.no_files_for_thumb_generation, {});
            }
        }
    });
}());
