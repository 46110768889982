/*
* hb-collapsed-avatars
* ====================
*
* Use this do show collapsed avatars like team members, multiple recipients, etc.
*
* Params:
*
* users - array of users to show
* max - max avatars to show. default to 4.
* counter-text-pattern - `+[NUM] Team members`.
*
*
*
 */

//

(function () {
    'use strict';

    class CollapsedAvatarsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'CollapsedAvatarsController';
            this.DEFAULT_MAX_OF_USERS = 4;

            this.maxUsers = this.max || this.DEFAULT_MAX_OF_USERS;
            this._initAvatars()

        }

        _initAvatars() {
            this.hasExtraAvatars = false;
            this.extraAvatars = 0;
            this.usersToShow = this.users;
            this.extraAvatarsFullNames = '';
        }

        $onChanges(changes) {
            if (changes.users.currentValue && changes.users.currentValue.length > this.maxUsers) {
                this.usersToShow = this.users.slice(0, this.maxUsers - 1);
                this.hasExtraAvatars = true;
                this.extraAvatars = this.users.slice(this.maxUsers - 1);
                this.extraAvatarsFullNames = _.pluck(this.users.slice(this.maxUsers - 1), 'full_name').join(', ');
            } else {
                this._initAvatars();
            }
        }

        getText() {
            let text = ''
            if (this.counterTextPattern) {
                text = this.counterTextPattern.replace("[NUM]", this.users.length);
            }
            return text;
        }
    }

    Components.CollapsedAvatars = {
        bindings: {
            users: '<',
            max: '<',
            counterTextPattern: '@',
            extraAsText: '<',
        },
        controller: CollapsedAvatarsController,
        name : 'hbCollapsedAvatars',
        templateUrl: 'angular/app/modules/common/ui_components/collapsed-avatars/collapsed-avatars.html',
    };

}());
