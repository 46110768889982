(function () {
    'use strict';

    // @ngInject
    Services.upscope = function upscope($window, Gon, ThirdPartyLoaderService) {

        function loadUpscope() {
            if (!Gon.upscope_key) {
                // Upscope is not enabled
                return;
            }
            
            // This will initiate Upscope connection. It's important it is added to all pages, even when the user is not logged in.
            (function (w, u, d) {
                var i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://code.upscope.io/' + Gon.upscope_key + '.js';
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (typeof u !== "function") {
                    w.Upscope = i;
                    l();
                }
            })($window, $window.Upscope, document);

            $window.Upscope('init');
        }

        return ThirdPartyLoaderService.loadCallbackPromise(loadUpscope, function () {});
    };
}());
