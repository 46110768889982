Directives.SuggestionsDirective = function SuggestionsDirective() {

    // @ngInject
    function SuggestionsDirectiveControllerFunc($injector, $scope, AnalyticsService, _, SuggestionsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SuggestionsDirectiveController';
        this.AnalyticsService = AnalyticsService;

        this._ = _;
        this.SuggestionsService = SuggestionsService;
        this.boxExpanded = this.isBoxOpen;

        this._init();
    }
    var SuggestionsDirectiveController = Class(Controllers.BaseController, {
        constructor: SuggestionsDirectiveControllerFunc,

        toggleExpand: function toggleExpand() {
            this.boxExpanded = !this.boxExpanded;
            this.animationInitial = false;
            this.setPersistency();
        },

        _init: function _init() {
            this.initialSettings = {
                copy: '',
                primaryCta: '',
                primaryUrl: '',
                primaryUrlType: '',
                secondaryCta: '',
                secondaryUrl: '',
                secondaryUrlType: '',
            };

            this.ACTIONTYPE = {
                PRODUCT: 'route',
                VIDEO : 'video',
                NEWTAB : 'tab',
                CALLBACK : 'callback'
            }

            this.suggestion = this._.assign(this.initialSettings, this.suggestion );
            this.animationInitial = true;
        },


        onOpenVideoModal: function onOpenVideoModal(video) {
            var videoOptions = {
                modalOpenSource: 'Suggestions',
                videoLink: video
            };

            if (videoOptions.videoLink) {
                this.SuggestionsService.playVideo(videoOptions);
            }

            this.closeBox();
        },

        closeBox: function closeBox(triggerSource) {
            this.boxExpanded = false;

            if (triggerSource === 'click outside') {

                var params = {
                    source: this.suggestion.primaryAnalytics,
                    secondarySource: 'click outside'
                };

                this.AnalyticsService.trackClick(this, 'collapse smart suggestion box', params);
            }
        }

    });

    return {
        templateUrl: 'angular/app/modules/core/features/suggestions/suggestions_directive_view.html',
        controller: SuggestionsDirectiveController,
        controllerAs: 'suggestionsVm',
        bindToController: true,
        scope: {
            suggestion: '=',
            setPersistency: '&',
            modifier: '@',
            triggerPrimary: '&',
            triggerSecondary: '&',
            isBoxOpen: '@'
        }
    };
};


/*
    DEV NOTES:
        primaryUrlType and secondaryUrlType accept: route, tab and video properties as strings.
        route: For product route.
        tab: opens a new tab for external links.
        video: opens a video modal.
        setPersistancy: For UI Persistency
        triggerPrimary: callback for external functions.
        triggerSecondary: callback for external functions.
*/