(function () {
    'use strict';

    class InteractiveBrandingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            CompaniesManager,
            UsersManager,
            AnalyticsService,
            CompanyService,
            ModalService,
            ImageService,
            UIUtils,
            TemplatesManager,
            _,
            $,
            Enums,
            $translate
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveBrandingController';

            this.CompanyService = CompanyService;
            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.ImageService = ImageService;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;
            this.UIUtils = UIUtils;
            this.TemplatesManager = TemplatesManager;
            this._ = _;
            this.Enums = Enums;
            this.$translate = $translate;
            this.$ = $;
        }

        $onInit() {
            this.company = this.CompaniesManager.getCurrCompany();
            this.registerOnce(this.company, 'success', () => {
                this.defaultCoverImage = this.company.getDefaultCoverImage();
            });

            this.user = this.UsersManager.getCurrUser();
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this.hasNewLogo = !this.CompanyService.isDefaultCompanyIcon(this.company);
            this.onValidation({ isValid: true });
            this.titleKey = this.$translate.instant('INTERACTIVE_ONBOARDING.STEP_BRANDING._TITLE_');
        }

        openExplainBrandingModal() {
            this.ModalService.openInteractiveOnboardingExplainBrandingModal({
                brandColor: this.company.brand_color,
                logoImage: this.company.icon_image,
                defaultCoverImage: this.defaultCoverImage,
            });
        }

        changeIconPhoto() {
            this.ImageService.uploadCompanyIcon(this.company).then( ()=> {
                this.hasNewLogo = !this.CompanyService.isDefaultCompanyIcon(this.company)
            });
        }

        onColorSelected(color) {
            this.company.brand_color = color;
            this.CompaniesManager.saveCompanyFields(this.company);
        }

        onColorPickerShow() {
            this.AnalyticsService.track(this, 'open color picker', {color_for: 'brand color'});
        }

        makeBlackOrWhiteForContrast(backgroundHex) {
            return this.UIUtils.makeBlackOrWhiteForContrast(backgroundHex);
        }

        changeCoverPhoto() {
            var defaultImageId = this.company.default_cover_image_id;

            var options = {
                selectedImage: this.defaultCoverImage,
                imageDisplayRatio: 3,
                showPreviewWhenCroppingDisplay: true,
                skipSavingToServer: false,
                title: 'MEDIA_MODAL.TITLE._EDIT_COVER_HEADER_',
                defaultTab: defaultImageId ? 'Crop' : 'Upload',
                model: ''
            };

            this.ImageService.chooseImage(options).then( newImage => {
                if (defaultImageId !== newImage._id) {
                    this.CompaniesManager.setDefaultCoverImage(this.company, newImage._id)
                        .then(resp => {
                            if(this.user.onboarding_container.onboarding_invoice_template_id) {
                                this.updateTemplateCover();
                            }
                        });
                    this.company.default_cover_image_id = newImage._id;
                    this.defaultCoverImage = newImage;
                    this.CompanyService.triggerCompanyBrandingUpdated();
                }
                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.company_cover_photo_changed, {});
            });
        }

        makeCloudinaryUrl(image) {
            if (image) {
                const transformedImages = this.$.cloudinary.image(image.cloudinary_public_id, {
                    cloud_name: image.cloudinary_sub_account,
                    secure: true,
                    transformation: [
                        { fetch_format: 'auto', flags: 'lossy', quality: 'auto' }
                    ]
                });
                return transformedImages[0].src;
            }
        }


        onBrandColorSelected(color) {
            this.company.brand_color = color;
            this.CompaniesManager.saveCompanyFields(this.company);
        }

        updateTemplateCover() {
            this.getInvoiceTemplate(this.user.onboarding_container.onboarding_invoice_template_id)
                .then(resp => this.onInvoiceTemplateReceived());
        }

        getInvoiceTemplate(templateId) {
            return this.TemplatesManager.getInvoice(this.company, this.user, templateId);
        }

        onInvoiceTemplateReceived() {
            var invoiceTemplate = this._.detect(this.company.invoices, function (invoice) {
                return invoice._id === this.user.onboarding_container.onboarding_invoice_template_id;
            }, this);

            var image = this.company.getDefaultCoverImage();
            invoiceTemplate.updateCoverImage(image);
        }
    }

    Components.InteractiveBranding = {
        controller: InteractiveBrandingController,
        bindings: {
            onValidation: '&',
            onComplete: '&',
            defaultCoverImage: '='
        },
        name: 'hbInteractiveBranding',
        require: {
            hbInteractiveOnboarding: '^hbInteractiveOnboarding'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/branding/branding.html',
    };
}());
