Directives.PackageDirective = function PackageDirective() {

    // @ngInject
    function PackageDirectiveControllerFunc(AppConfigService, AnalyticsService, PopupMessageService, CompaniesManager,
                                            $translate, $scope, $injector, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PackageDirectiveController';

        var self = this;
        this._ = _;
        this.templateTitle = this.packageModel.title;
        this.shouldShowMenu = this.shouldShowMenu === "true";
        this.shouldShowSVC = this.packageModel.shouldShowSVC();


        //create the view model for each service (the service view model should be a serializable object)
        this.baseServices = [];

        this.sortableOptions = {
            revert: 200,
            axis: 'y',
            handle: ' .drag-icon-container',
            disabled: (!this.isEditableMode || this.isPreviewMode),
            start: function (event, ui) {
                AnalyticsService.track(self, AnalyticsService.analytics_events.service_drag_in_package_started, self.packageModel.getAnalyticsArgs());
            },

            update: function update(event, ui) {
                //window.console.log("in update");
            },

            stop: function stop(event, ui) {
                //window.console.log('in stop after drop');
                self.baseServices.forEach(function (serviceViewModel, index) {
                    var serviceModel = self._.findWhere(self.packageModel.base_services, {_id: serviceViewModel._id});
                    serviceModel.order = index;
                }.bind(self));

                //update the server with the new order
                self.packageModel.updateServicesOrder(self.fileModel, self.packageModel)
                    .then(function success() {
                        AnalyticsService.track(self, AnalyticsService.analytics_events.service_drag_in_package_ended, self.packageModel.getAnalyticsArgs());
                    })
                    .catch(function error() {
                        AnalyticsService.trackError(self, AnalyticsService.analytics_events.service_drag_in_package_error, self.packageModel.getAnalyticsArgs());
                    });

            }
        };

        $scope.$watch('packageVm.isEditableMode', function (newVal, oldVal) {
            if(newVal !== undefined){
                self.sortableOptions.disabled = (!newVal || self.isPreviewMode);
            }
        });

        $scope.$watch('packageVm.isPreviewMode', function (newVal, oldVal) {
            if(newVal !== undefined){
                self.sortableOptions.disabled = (!self.isEditableMode || newVal);
            }
        });

        this.fillViewModelAccordingToModel = function fillViewModelAccordingToModel() {
            this.baseServices.splice(0);
            this.packageModel.base_services.forEach(function (currentService) {
                var serviceViewModel = currentService.dataOnly();
                //add the the hover state
                serviceViewModel.hoveredOn = false;
                this.baseServices.push(serviceViewModel);
            }.bind(this));

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.baseServices = _.sortBy(this.baseServices, function(serviceViewModel){
                return serviceViewModel.order ? serviceViewModel.order : 0;
            });
        };

        this.fillViewModelAccordingToModel();

        var modelToRegister = this.fileModel ? this.fileModel : this.company;
        this.register(modelToRegister, 'success', function success() {
            this.fillViewModelAccordingToModel();
        });

        this.showDragIcon = function showDragIcon(service){
            if (!this.isEditMode()) {
                return false;
            }

            return service.hoveredOn;
        };

        var packageState = AppConfigService.filePackageState();

        var curPackageState = packageState[this.packageModel._id];
        if (!curPackageState) {
            curPackageState = {};
            packageState[this.packageModel._id] = curPackageState;
            curPackageState.showPackages = true;
        }

        this.showPackages = curPackageState.showPackages;

        this.addService = function addService() {
            var that = this;

            that.addPackageInProgress = true;
            setTimeout(function () {
                that.packageModel.addAdditionalServiceToPackage().then(
                function success() {


                    that.addPackageInProgress = false;
                    AnalyticsService.trackSuccess(that, AnalyticsService.analytics_events.service_added_to_package, that.packageModel.getAnalyticsArgs());
                },

                function error(resp) {
                    that.addPackageInProgress = false;
                    AnalyticsService.trackError(that, AnalyticsService.analytics_events.service_added_to_package, resp, that.packageModel.getAnalyticsArgs());
                }
                );
            }, 300);

        };

        this.updatePackage = function updatePackage() {
            this.packageModel.updatePackage(this);
        };

        this.deletePackage = function deletePackage() {

            var that = this;

            var deleteConfirmed = function deleteConfirmed() {

                that.deleting = true;
                var analyticsArgs = that.packageModel.getAnalyticsArgs();
                analyticsArgs.source = 'File';
                that.packageModel.deletePackage().then(
                    function success() {

                        AnalyticsService.track(that, AnalyticsService.analytics_events.package_deleted_from_file, analyticsArgs);
                    },
                    function error() {

                        AnalyticsService.track(that, AnalyticsService.analytics_events.package_deleted_from_file_error, analyticsArgs);
                    }
                ).finally(function done() {
                        that.deleting = false;
                    });
            };

            PopupMessageService.showConfirm(PopupMessageService.severityTypes.warning,
                'PROPOSAL.PACKAGES.ALERT._CONFIRM_DELETE_',
                deleteConfirmed);
        };

        //For the package Menu on the front-end
        this.packageMenuClosed = true;

        this.togglePackageMenu = function () {
            this.packageMenuClosed = !this.packageMenuClosed;
        };

        this.closePackageMenu = function () {
            this.packageMenuClosed = true;
        };

        // For show-more/less button
        this.SHOW_MORE = 'PROPOSAL.PACKAGES.SHOW_LIST._MORE_';

        this.SHOW_LESS = 'PROPOSAL.PACKAGES.SHOW_LIST._LESS_';

        this.showPackagesText = this.SHOW_LESS;

        this.showPackagesListClicked = function showPackagesListClicked() {
            this.showPackages = !this.showPackages;
            this.showPackagesText = this.showPackages ? this.SHOW_LESS : this.SHOW_MORE;
            curPackageState.showPackages = this.showPackages;
        };

        this.shouldShowTaxViewSection = function shouldShowTaxViewSection() {
            return this.isEditMode() || (this.packageModel.taxable && this.packageModel.base_price > 0);
        };

        this.shouldShowSVCViewSection = function shouldShowSVCViewSection() {
            return this.shouldShowSVC && (this.isEditMode() || (this.packageModel.svcable && this.packageModel.base_price > 0));
        };

        this.shouldShowPackageDescription = function shouldShowPackageDescription() {
            return this.isEditMode() || (this.packageModel.description);
        };

        this.isEditMode = function isEditMode() {
            return  this.isOwnerMode &&
                this.isEditableMode && !this.isPreviewMode;
        };

        this.savePackageTemplate = function savePackageTemplate() {
            var that = this;


            var saveCanceled = function saveCanceled() {
                that.duringSave = false;
            };

            var saveConfirmed = function saveConfirmed() {

                that.duringSave = true;

                CompaniesManager.savePackageTemplate(that.company, that.packageModel.__vendor_proposal.__workspace_file, that.packageModel._id).then(
                    function success() {
                        that.duringSave = false;
                        that.packageMenuClosed = true;

                        var analyticsArgs = {
                            template_origin_id: that.packageModel.origin_copy_id,
                            workspace_file_id: that.fileModel._id,
                            template_type: "Package",
                            company_id: that.company._id
                        };

                        AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_confirmed, analyticsArgs);
                    },
                    function error(resp) {
                        that.duringSave = false;
                        that.packageMenuClosed = true;

                        PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                            'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');
                        var analyticsArgs = {
                            template_origin_id: that.packageModel.origin_copy_id,
                            workspace_file_id: that.fileModel._id,
                            template_type: "Package",
                            company_id: that.company._id,
                            error: resp.data.error_message
                        };

                        AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_failed, analyticsArgs);
                    }
                );
            };

            $translate('FILE.TEMPLATES._CONFIRM_SAVE_TEMPLATE_MESSAGE_', { template_name: that.packageModel.title }).then(function translateResolved(confirmMessage) {

                PopupMessageService.showConfirm(PopupMessageService.severityTypes.info,
                    confirmMessage,
                    saveConfirmed, saveCanceled);
            });

        };

        this.enableSaveAsPackageTemplate = function enableSaveAsPackageTemplate() {
            this.isSaveAsEnabled = true;
            this.packageMenuClosed = true;
        };

        this.cancelNewPackageName = function cancelNewPackageName() {
            this.isSaveAsEnabled = false;
        };

        // close with button
        this.cancelSaveAs = function cancelSaveAs() {
            this.duringSaveAs = false;
            this.isSaveAsEnabled = false;
        };

        this.finalizeSaveAs = function finalizeSaveAs() {
            this.duringSaveAs = false;
            this.packageMenuClosed = true;
            this.isSaveAsEnabled = false;
        };

        this.saveAsPackageTemplate = function saveAsPackageTemplate() {

            if (!this.templateTitle || this.templateTitle.length === 0) {
                this.duringSaveAs = false;

                PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                    'FILE.TEMPLATES._MISSING_TEMPLATE_NAME_');
                return;
            }

            var that = this;
            that.duringSaveAs = true;

            CompaniesManager.saveAsPackageTemplate(that.company, that.packageModel.__vendor_proposal.__workspace_file, that.packageModel._id, that.templateTitle).then(
                function success() {
                    // Update the current package with the new template name
                    that.packageModel.title = that.templateTitle;

                    that.finalizeSaveAs();

                    var analyticsArgs = {
                        template_origin_id: that.packageModel.origin_copy_id,
                        workspace_file_id: that.fileModel._id,
                        template_type: "Package",
                        company_id: that.company._id,
                        template_title: that.templateTitle
                    };

                    AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_as_confirmed, analyticsArgs);
                },
                function error(resp) {
                    that.finalizeSaveAs();

                    PopupMessageService.showAlert(PopupMessageService.severityTypes.warning,
                        'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');

                    var analyticsArgs = {
                        template_origin_id: that.packageModel.origin_copy_id,
                        workspace_file_id: that.fileModel._id,
                        template_type: "Package",
                        company_id: that.company._id,
                        template_title: that.templateTitle,
                        error: resp.data.error_message
                    };

                    AnalyticsService.track(that, AnalyticsService.analytics_events.template_save_as_failed, analyticsArgs);
                }
            );

        };
    }

    var PackageDirectiveController = Class(Controllers.BaseController, {
        constructor: PackageDirectiveControllerFunc
    });



    return {
        scope: {
            fileModel: '=fileModel',
            packageModel: "=packageModel",
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            shouldShowMenu: '@shouldShowMenu',
            company: '=company',
            hidePriceAndDetails: '=?hidePriceAndDetails',
            templateMode: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/proposal/package/package_directive_template.html',
        controller: PackageDirectiveController,
        controllerAs: 'packageVm',
        bindToController: true
    };
};
