(function () {
    'use strict';

    Directives.DropdownQuestionDirective = function dropdownQuestionDirective() {

        // @ngInject
        function DropdownQuestionDirectiveControllerCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'DropdownQuestionDirectiveController';
        }

        var DropdownQuestionDirectiveController = Class(Controllers.BaseController, {
            constructor: DropdownQuestionDirectiveControllerCtor,

            DEFAULT_ANSWER_TEXT: 'QUESTIONNAIRE._QUESTION_DROPDOWN_DEFAULT_',

            selectAnswer: function selectAnswer(id) {

                this.question.optional_answers.forEach(function(answer) {
                    answer.selected = answer._id === id;
                });

                this.updateQuestion();
            },

            getChosenAnswer: function setChosenAnswer() {

                for (var i in this.question.optional_answers) {
                    var optional_answer = this.question.optional_answers[i];
                    if (optional_answer.selected) {
                        return optional_answer.answer_text;
                    }
                }

                return this.DEFAULT_ANSWER_TEXT;
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/common/ui_components/questions/dropdown/dropdown_question_directive_template.html',
            controller: DropdownQuestionDirectiveController,
            controllerAs: 'questionVm',
            bindToController: true,
            scope: {
                question: '=',
                isQuestionEditable: '&',
                isAnswerEditable: '&',
                updateQuestion: '&'
            }
        };
    };

}());

