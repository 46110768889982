(function () {
    'use strict';

    // @ngInject
    function BatchEmailsModalControllerCtor($scope, $injector, $modalInstance, AnalyticsService, UsersManager,
                                            SearchablesManager, _, $log, $timeout, $translate, AttachmentsService,
                                            TemplatesManager, CompaniesManager, $document, UIUtils, PendingTasksManager, ToastService, onSuccessFunction,
                                            PopupMessageService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BatchEmailsModalController';

        this.$modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.SearchablesManager = SearchablesManager;
        this.PendingTasksManager = PendingTasksManager;
        this.ToastService = ToastService;
        this.$log = $log;
        this._ = _;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.TemplatesManager = TemplatesManager;
        this.AttachmentsService = AttachmentsService;
        this.PopupMessageService = PopupMessageService;
        this.reload = true;
        this.showSendTestEmail = true;
        this.showSendTestEmailText = "Sending...";
        this.onSuccessFunction = onSuccessFunction;
        this.currentTemplate = {
            name: 'email',
            subject: '',
            html_body: '',
            image_attachments: [],
            file_attachments: []
        };
        this.UIUtils = UIUtils;
        this.AnalyticsService.trackPageView(this, 'BatchEmailsModalView');
        this.MAX_CONTACTS_PER_BATCH = 200;
        this.SEARCH_PAGE_SIZE = 100;
        this.isLastPage = false;

        this.company = CompaniesManager.getCurrCompany();
        this.user = this.UsersManager.getCurrUser();

        this.selectAll = false;

        this.sendEmailTestEmail = function sendEmailTestEmail() {
            var subject = this.currentTemplate.subject;
            var htmlBody = this.currentTemplate.html_body;

            if (!this._.isEmpty(subject) && !this._.isEmpty(htmlBody)) {
                this.showSendTestEmailText = "Sending...";
                this.showSendTestEmail = false;

                var data = {
                    user_id: this.user._id,
                    recipient_user_ids: [this.user._id],
                    subject: this.currentTemplate.subject,
                    html_body: this.currentTemplate.html_body,
                    context_company_id: this.company._id,
                    testEmail: true,
                    image_attachments: this.AttachmentsService.translateFilesListForServer(this.currentTemplate.image_attachments),
                    file_attachments: this.AttachmentsService.translateFilesListForServer(this.currentTemplate.file_attachments)
                };

                return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendBatchEmail, data)
                    .then(function success(resp) {
                        this._onBatchEmailsSent(this.AnalyticsService.analytics_events.batch_emails_send_test_email_to_myself);
                        this.showSendTestEmailText = "Sent!";
                        this.$timeout(function () {
                            this.showSendTestEmail = true;
                        }.bind(this), 2000);
                    }.bind(this));
            }
            else {
                this.showNoContentError();
            }
        }.bind(this);

        this.modalStates = {
            EMAIL_EDITING: 0,
            RECIPIENTS_SELECTOR: 1,
            SEND_EMAIL: 2
        };

        $document.on('click.batch_emails.honeybook', function (e) {
            var container = $("#email-templates-region");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if (this.showEmailTemplates) {
                    this.closeMenu();
                }
            }
        }.bind(this));
        $scope.$on('$destroy', function() {
            $document.off('click.batch_emails.honeybook');
        });

        // passed to one composer so it can update state object here
        this.setCurrentTemplate = function setCurrentTemplate(edit){
            this.currentTemplate = edit;
        }.bind(this);

        this.closeMenu = function closeMenu() {
            this.showEmailTemplates = false;
        };

        this.emailTemplates = function emailTemplates() {
            this.showEmailTemplates = !this.showEmailTemplates;
        };

        this.templateChosen = function templateChosen(template) {

            var templateId = template.id();

            var analyticsArgs = {
                company_id: this.company.id(),
                template_type: 'email',
                template_id: templateId,
                source: 'email editor'
            };

            this.closeMenu();

            this.TemplatesManager.getEmail(this.company, this.user, templateId).then(function success() {
                var currentTemplate = this._.find(this.company.vendor_emails, function (template) {
                    return template._id === templateId;
                });
                this.reload = false;
                this.currentTemplate.subject = currentTemplate.subject;
                this.currentTemplate.html_body = this.UIUtils.getHtmlBodyCleanInlineStyle(currentTemplate.html_body);

                var attachments = this.AttachmentsService.getAdditiveAttachments(currentTemplate.image_attachments, currentTemplate.file_attachments, this.currentTemplate.image_attachments, this.currentTemplate.file_attachments);

                this.currentTemplate.image_attachments = attachments.images;
                this.currentTemplate.file_attachments = attachments.files;

                }.bind(this),
                function error() {})
                .finally(function () {
                $scope.$applyAsync(function () {
                    this.reload = true;
                }.bind(this));
            }.bind(this));
        };

        this.modalState = this.modalStates.EMAIL_EDITING;

        this.searchText = "";
        this.filteredUserListResult = [];
        this.currentPage = 1;
        this.selectedUsers = [];
        this.selectedUsersHash = {};
        this.searchDataBeingFetched = false;
        this.sendingEmails = false;
        this.showEmptyEmailError = false;
        this.showServerError = false;
        this.serverErrorMsg = '';

        //different types of filters
        this.filters = {
            filterVendors: false,
            filterClients: false
        };

        this.overlayUserSelectionOnModel(this.filteredUserListResult, this.selectedUsersHash);

        this.onSearchInputChanged(); // start the initial search (for all results)
    }

    Controllers.BatchEmailsModalController = Class(Controllers.BaseController, {
        constructor: BatchEmailsModalControllerCtor,

        overlayUserSelectionOnModel: function overlayUserSelectionOnModel(usersList, selectedUsers) {
            usersList.forEach(function filteredIter(user) {
                if (selectedUsers[user._id]) {
                    user.isSelected = true;
                }
            });
        },

        onSearchInputChanged: function onSearchInputChanged(shouldReset, searchOpts) {
            if (shouldReset) {
                this.currentPage = 1;
                this.filteredUserListResult = [];
                this.isLastPage = false;
            }
            if (this.isLastPage){
                return;
            }

            searchOpts = Object.assign({sortBy: 'first_name', pageSize: this.SEARCH_PAGE_SIZE, offset: 0}, searchOpts || {} );

            this.searchDataBeingFetched = true;

            var searchEntities = 'contacts';
            if (this.filters.filterVendors && !this.filters.filterClients) {
                searchEntities = 'vendorContacts';
            } else if (!this.filters.filterVendors && this.filters.filterClients) {
                searchEntities = 'clientContacts';
            }
            this.filteredUserListResultRaw = this.SearchablesManager.search(this.searchText, searchEntities, searchOpts);

            this.register(this.filteredUserListResultRaw, 'success', function onSearchDataFetched(callbackReason) {
                if (this.filteredUserListResultRaw.length < this.SEARCH_PAGE_SIZE && this.currentPage > 1){
                    this.searchDataBeingFetched = false;
                    this.isLastPage = true;
                }
                this.filteredUserListResult = this.filteredUserListResult.concat(this.filteredUserListResultRaw.map(function (e) {
                    var es_result = e.properties;
                    var mappedUser = {
                        _id: es_result._id,
                        full_name: es_result.full_name,
                        profile_image: {
                            url: es_result.profile_image
                        }
                    };

                    if (es_result.system_user_type === 'client') {
                        mappedUser.type = 'Client';
                    } else if (es_result.system_user_type === 'vendor') {
                        mappedUser.type = 'Creative';
                        if (es_result.company_name && es_result.company_name !== '') {
                            mappedUser.full_name += ' / ' + es_result.company_name;
                        }
                    }

                    var split_name = es_result.full_name.trim().split(/\s*/);
                    mappedUser.initials = "";
                    if (split_name.length > 0 && split_name[0].length > 0) {
                        mappedUser.initials += split_name[0][0];
                    }
                    if (split_name.length > 1 && split_name[1].length > 0) {
                        mappedUser.initials += split_name[1][0];
                    }
                    mappedUser.initials = mappedUser.initials.toUpperCase();

                    return mappedUser;
                }));
                this.searchDataBeingFetched = false;
                this.overlayUserSelectionOnModel(this.filteredUserListResult, this.selectedUsersHash);
            }.bind(this));
        },

        loadMoreUsers: function loadMoreUsers() {
            if (!this.searchDataBeingFetched) {
                this.onSearchInputChanged(false, {offset: (this.currentPage) * 100})
                this.currentPage++;
            }
        },

        onUserClicked: function onUserClicked(user) {
            if (user.isSelected) {
                this.removeSelectedUser(user);
            } else {
                this.addSelectedUser(user);
            }
        },

        _onBatchEmailsSent: function _onBatchEmailsSent(message) {
            this.AnalyticsService.trackSuccess(this, message);
            this.onSuccessFunction();

            this.ToastService.showSuccess({
                contentTranslation: 'BATCH_EMAILS.TOAST_SUCCESS',
                iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout: 5000
            });

        },

        addSelectedUser: function addSelectedUser(user) {
            user.isSelected = true;
            this.selectedUsersHash[user._id] = true;
            this.selectedUsers.push(user);
        },

        removeSelectedUser: function removeSelectedUser(user) {
            user.isSelected = false;
            delete this.selectedUsersHash[user._id];
            var userIndex = this._.findIndex(this.selectedUsers, {_id: user._id});
            if (userIndex || userIndex === 0) {
                this.selectedUsers.splice(userIndex, 1);
            }
        },

        goToRecipientsSelector: function goToRecipientsSelector() {
            //only if there is a subject at least on the email editor
            var subject = this.currentTemplate.subject;
            var htmlBody = this.currentTemplate.html_body;

            if (!this._.isEmpty(subject) && !this._.isEmpty(htmlBody)) {
                this.modalState = this.modalStates.RECIPIENTS_SELECTOR;
            } else {
                this.showNoContentError();
            }
        },

        showNoContentError: function showNoContentError() {
            this.showEmptyEmailError = true;
            this.$timeout(function () {
                this.showEmptyEmailError = false;
            }.bind(this), 4000);
        },

        toggleFilter: function toggleFilter(filter) {
            this.filters[filter] = !this.filters[filter];

            this.onSearchInputChanged(true);
        },

        onGoBack: function onGoBack() {
            this.modalState = this.modalStates.EMAIL_EDITING;
        },

        goToSendScreen: function goToSendScreen() {
            if (this.selectedUsers.length > this.MAX_CONTACTS_PER_BATCH) {
                var exceeded = this.selectedUsers.length - this.MAX_CONTACTS_PER_BATCH;
                return this.PopupMessageService.showErrorAlert(
                  this.$translate.instant('BULK_EMAILS_ERRORS._MAX_CONTACTS_PER_BATCH_EXCEEDED_',
                    {   limit: this.MAX_CONTACTS_PER_BATCH,
                        delta: exceeded,
                        plural: exceeded === 1 ? "" : "s"
                    }));
            }

            var userIdsArray = this.selectedUsers.map(function mapIter(user) {
                return user._id;
            });

            if (userIdsArray.length === 0) {
                this.showNoUserWasSelectedError();
            } else {
                this.sendingEmails = true;
                var data = {
                    user_id: this.user._id,
                    recipient_user_ids: userIdsArray,
                    subject: this.currentTemplate.subject,
                    html_body: this.currentTemplate.html_body,
                    testEmail: false,
                    image_attachments: this.AttachmentsService.translateFilesListForServer(this.currentTemplate.image_attachments),
                    file_attachments: this.AttachmentsService.translateFilesListForServer(this.currentTemplate.file_attachments)
                };

                this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendBatchEmail, data)
                    .then(function success() {
                    this._onBatchEmailsSent(this.AnalyticsService.analytics_events.batch_emails_send_emails);
                }.bind(this))
                    .catch(function fail(resp) {
                        this.showServerReturnedError(resp.data.error_message);
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }.bind(this))
                    .finally(function finallyHandler() {
                        this.sendingEmails = false;
                        this.$timeout(function () {
                            this.onDone();
                        }.bind(this), 20000);
                    }.bind(this));
                this.modalState = this.modalStates.SEND_EMAIL;
            }
        },

        showNoUserWasSelectedError: function showNoUserSelectedError() {
            this.showNoUserSelectedError = true;
            this.$timeout(function () {
                this.showNoUserSelectedError = false;
            }.bind(this), 4000);
        },

        showServerReturnedError: function showServerError(error) {
            this.showServerError = true;
            this.serverErrorMsg = error;
            this.$timeout(function () {
                this.showServerError = false;
                this.serverErrorMsg = '';
            }.bind(this), 4000);
        },

        backToEditing: function backToEditing() {
            if (this.modalState === this.modalStates.RECIPIENTS_SELECTOR) {
                this.modalState = this.modalStates.EMAIL_EDITING;
            }
        },

        onDone: function onDone() {
            if (!this.isModalClosed) {
                this.isModalClosed = true;
                this.$modalInstance.close('done');
            }
        },

        onCancel: function onCancel() {
            if (!this.isModalClosed) {
                this.isModalClosed = true;
                this.$modalInstance.dismiss();
            }
        },
        onSelectAll: function onSelectAll() {
            this.selectAll = !this.selectAll;

            if (this.selectAll) {
                this.selectedUsers = [];
            }
            this._.each(this.filteredUserListResult, function (user, index) {
                if (this.selectAll) {
                    this.addSelectedUser(user);
                } else {
                    this.removeSelectedUser(user);
                }
            }.bind(this));
        }
    });
}());
