(function () {
    'use strict';

    // @ngInject
    function SecondLevelNavigationControllerCtor($scope, $injector, $stateParams, $timeout, $state, AnalyticsService,
                                                 AppStates, UsersManager, moment, UserService,
                                                 NavigationService, ngToast, UIUtils, AbTestService, Enums) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SecondLevelNavigationController';
        
        this.menuItemsViewModel = [];
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.moment = moment;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.UIUtils = UIUtils;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.UserService = UserService;

        this.user = UsersManager.getCurrUser();
        this.AnalyticsService = AnalyticsService;

        this.ngToast = ngToast;
        
        this.navigationRightMenuItems = [];
        this.navigationMoreItems = [];

        // Nav Tab
            // settings
            this.title = $state.$current.data.title;
            
            this.navigationMenuItems = [
                {linkTitleKey: 'General', stateToGoto: AppStates.root_core_navigation_settings_companySettings_new_general}
            ];
            
            // add routes for vendors only
            if(this.user.isVendor()) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Team', stateToGoto: AppStates.root_core_navigation_settings_companySettings_team
                });
            }

            // add routes for company owner only
            if(this.user.isCompanyOwner()) {

                // membership
                if(this.user.shouldShowMembershipToUser()) {

                    this.navigationMenuItems.push({
                        linkTitleKey: 'Membership', stateToGoto: AppStates.root_core_navigation_settings_companySettings_subscription
                    });

                }

                // bank details
                this.navigationMenuItems.push({
                    linkTitleKey: 'Bank details', stateToGoto: AppStates.root_core_navigation_settings_companySettings_bankInfo
                });
            }

        // }

        this.navigationMoreItems = (this.navigationMenuItems.concat(this.navigationRightMenuItems)).filter(function(item) {
            return item.hideOnMobile && !item.hideInMoreMenu;
        });

    }

    Controllers.SecondLevelNavigationController = Class(Controllers.BaseController, {
        constructor: SecondLevelNavigationControllerCtor,

        onMenuItemClicked: function onMenuItemClicked(menuItem) {
            this.goToState(menuItem.stateToGoto, menuItem.stateParams, menuItem.stateOptions);
        }
    });
}());

