/**
 * Created by inonstelman on 2/24/15.
 */

Directives.DatePickerDirective = function DatePickerDirective(){

    // @ngInject
    var DatePickerDirectiveControllerFunc = function controller($element, $scope, $injector, _, moment){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'DatePickerDirectiveController';
        this._ = _;
        this.moment = moment;

        this.initialDate = new Date(2012,1,1);
        this.format = 'M dd, yyyy';

    };

    var DatePickerDirectiveController = Class(Controllers.BaseController, {
        constructor: DatePickerDirectiveControllerFunc
    });
    return {
        restrict: 'A',
        controller: DatePickerDirectiveController,
        controllerAs: 'datePickerVm',
        bindToController: true,

        link: function(scope, elem, attrs, ctrl) {
            if (!this._.isEmpty(attrs.hbDatePicker)) {
                if (attrs.hbDatePicker === 'BirthDayDatePicker') {
                    ctrl.initialDate = null;
                    ctrl.format = "M dd, yyyy";
                    ctrl.startView = 2;
                }
                else {
                    ctrl.format = attrs.hbDatePicker;
                }

            }

            if(attrs.hbDatePickerStartLimit) {
                if (attrs.hbDatePickerStartLimit === 'today') {
                    ctrl.initialDate = new Date();
                } else if (attrs.hbDatePickerStartLimit === 'yesterday') {
                        ctrl.initialDate = new Date(Date.now() - 864e5);
                } else {
                    var initialDate = this.moment(attrs.hbDatePickerStartLimit);
                    if (initialDate.isValid()) {
                        ctrl.initialDate = initialDate.toDate();
                    }
                }
            }

            if (attrs.hbDatePickerDisableKeyboard) {
                elem.on('keydown', function onKeyDown() {
                   return false;
                });
            }

            function _setZindex(zindex) {
                //patch for hardcoded value on the package css
                var datePickers = document.querySelectorAll('.datepicker');
                if (datePickers){
                    datePickers.forEach(function (el) {
                        el.style.setProperty('z-index', zindex, 'important');
                    });
                }
            }

            elem.fdatepicker({startDate: ctrl.initialDate, format: ctrl.format, startView: ctrl.startView})
                .on('show', function () {
                    if (attrs.hbDatePickerZindex){
                        _setZindex(attrs.hbDatePickerZindex);
                    }
                })
                .on('hide', function () {
                    if (attrs.hbDatePickerZindex){
                        _setZindex('');
                    }
                })
                .on('changeDate', function(ev){
                    if (ev.date.getFullYear() < 2012 && this.initialDate != null){
                        var today = new Date();
                        ev.date.setFullYear(today.getFullYear());
                    }
                })
                .on('remove', function() {
                        elem.fdatepicker('remove');
                });
            if(attrs.hbDatePickerForceReloadOnChange) {
                scope.$watch(function () {
                    return elem.attr('hb-date-picker-start-limit');
                }, function (newVal, oldVal) {
                    elem.fdatepicker('setStartDate', newVal);
                });
            }
        }
    };
};
