(function () {
    'use strict';

// @ngInject
    Models.ReferralCampaignModel = function ReferralCampaignModel(BaseModel) {

        return Class(BaseModel, {

            constructor: function ReferralCampaignModel() {
                this.constructor.$super.call(this);
                this.__className = 'ReferralCampaignModel';
            },

            mapChilds: function mapChilds() {
                return {};
            },

            afterMixingFrom: function afterMixingFrom(object) {
            },

            isCachable: function isCachable() {
                return false;
            }
        });
    };
}());

