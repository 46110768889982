(function () {
    "use strict";


    // @ngInject
    function TasksManagerCtor(APIService, Routes, ModelFactory, RepositoryService, UsersManager, $q, moment, _, PubSubService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.UsersManager = UsersManager;
        this.moment = moment;
        PubSubService.ventMyBitchUp(this);

        this._ = _;

        this.eventsMasterCollection = false;
        this.eventsToShow = false;
        this.lastSortColumn = '';
    }


    Services.TasksManager = Class(Services.BaseModelManager, {

        constructor: TasksManagerCtor,

        /*****************************************
         * Tasks ACTIONS
         ******************************************/

        createTask: function createTaks(workspaceId, origin) {
            var url = this.Routes.v2_create_task_path();
            var data = {
                description: "New Task",
                workspace_id: workspaceId,
                origin: origin
            };
            return this.apiCreate(url, data);
        },

        updateTask: function updateTaks(task) {
            var url = this.Routes.v2_update_task_path(task._id);
            return this.apiUpdate(url, task);
        },

        deleteTask: function deleteTask(task) {
            var url = this.Routes.v2_delete_task_path(task._id);

            return this.apiDelete(url);
        },

        fetchUsersTasks: function fetchUsersTasks(params) {
            var data = {
                filter_by: params.filter,
                page: params.page || null,
                perPage: params.perPage || 15,
                sort_by: params.sortBy,
                sort_desc: params.sortDesc || false,
                tasks_misc_offset: params.tasks_misc_offset,
                wf_approvals_misc_offset: params.wf_approvals_misc_offset,
                wf_approvals_after_step_execution_offset: params.wf_approvals_after_step_execution_offset,
                curr_date: this.moment(new Date()).format('MM/DD/YYYY')
            };
            var url = this.Routes.v2_get_tasks_for_user_optimized_path();
            return this.apiFetch(url, data);
        },

        fetchWorkspaceTasks: function fetchWorkspaceTasks(workspaceId, params) {
            var data = {
                filter_by: params.filter,
                page: params.page || null,
                perPage: params.perPage || 15,
                sort_by: params.sortBy,
                sort_desc: params.sortDesc || false,
                curr_date: this.moment(new Date()).format('MM/DD/YYYY')
            };
            var url = this.Routes.v2_get_tasks_for_workspace_path(workspaceId);
            return this.apiFetch(url, data);
        },

        fetchUserTasksCount: function fetchUserTasksCount() {
            var data = {
                curr_date: this.moment(new Date()).format('MM/DD/YYYY')
            };
            var url = this.Routes.v2_get_tasks_counts_for_user_path();
            return this.apiFetch(url, data);
        },

        getEmail: function getEmail(taskId) {
            var url = this.Routes.v2_get_task_email_path(taskId);
            return this.apiFetch(url);
        },

        fetchUserTasksCountAndThrowAnEvent: function fetchUserTasksCountAndThrowAnEvent() {
            this.fetchUserTasksCount().then(function success(resp) {
                this.trigger('show_task_notification_dot', resp.data["today"] > 0);
            }.bind(this));
        },

        fetchWorkspaceTasksCount: function fetchWorkspaceTasksCount(workspaceId) {
            var data = {
                curr_date: this.moment(new Date()).format('MM/DD/YYYY')
            };
            var url = this.Routes.v2_get_tasks_counts_for_workspace_path(workspaceId);
            return this.apiFetch(url, data);
        }

    });
}());