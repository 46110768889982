Components.Media = {
    bindings: {
        align: '@',

        // Need unique attribute names (not src or srcset) so Angular
        // does not try to fetch these assets automatically through templating.
        srcUrl: '@',
        srcsetConfig: '@',
    },
    transclude: true,
    // @ngInject
    controller: function($scope, $element, $attrs) {
        this.$onInit = function() {
            this.cssClass = [
                'media',
                'media--' + this.align
            ];

            // Can't set these directly in the template due to CORS
            // Angular runs all templates through eval()... :(
            var $figure = $element.find('.media__figure');
            $figure.attr('src', this.srcUrl);
            $figure.attr('srcset', this.srcsetConfig);
        }
    },
    name: 'hb-media',
    templateUrl: 'angular/app/modules/common/ui_components/media/media.html',
};

