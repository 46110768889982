(function () {
    "use strict";


    // @ngInject
    function BrochuresManagerCtor($q,
                                       APIService, RepositoryService, PrintService,
                                       Routes, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.PrintService = PrintService;
    }

    Services.BrochuresManager = Class(Services.BaseModelManager, {

        constructor: BrochuresManagerCtor,

        /*****************************************
         * BROCHURE ACTIONS
         ******************************************/
        createBrochureProposal: function createBrochureProposal(workspaceFile) {
            var url = this.Routes.v2_workspace_file_create_brochure_proposal_path(workspaceFile._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        deleteBrochureProposal: function deleteBrochureProposal(workspaceFile, brochureProposal) {
            var url = this.Routes.v2_workspace_file_delete_brochure_proposal_path(workspaceFile._id, brochureProposal._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        createBrochureALaCarteProposal: function createBrochureALaCarteProposal(workspaceFile) {
            var url = this.Routes.v2_workspace_file_create_brochure_a_la_carte_proposal_path(workspaceFile._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        deleteBrochureALaCarteProposal: function deleteBrochureALaCarteProposal(workspaceFile) {
            var url = this.Routes.v2_workspace_file_create_brochure_a_la_carte_proposal_path(workspaceFile._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(workspaceFile, image) {
            var url = this.Routes.v2_workspace_file_update_brochure_a_la_carte_image_path(workspaceFile._id);
            return this.apiUpdate(url, image, workspaceFile, true);
        },

        createBrochureGreeting: function createBrochureGreeting(workspaceFile) {
            var url = this.Routes.v2_create_file_brochure_greeting_path(workspaceFile._id);
            return this.apiCreate(url, null, workspaceFile, true);
        },

        updateBrochureGreeting: function updateBrochureGreeting(workspaceFile, greeting) {
            var url = this.Routes.v2_update_brochure_greeting_path(workspaceFile._id);
            var data = {
                greeting_title: greeting.title,
                greeting_body: greeting.body
            };
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        deleteBrochureGreeting: function deleteBrochureGreeting(workspaceFile) {
            var url = this.Routes.v2_delete_file_brochure_greeting_path(workspaceFile._id);
            return this.apiDelete(url, null, workspaceFile, true);
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(brochureModel, image) {
            var url = this.Routes.v2_update_brochure_greeting_image_path(brochureModel._id);
            return this.apiUpdate(url, image, brochureModel, true);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte(brochure) {
            var url = this.Routes.v2_workspace_file_update_brochure_a_la_carte_proposal_path(brochure._id);
            return this.apiUpdate(url, {title: brochure.brochure_container.a_la_carte_proposal.title}, brochure, true, null, 'waitForLast');
        },

        updateBrochureProposal: function updateBrochureProposal(workspaceFile, brochureProposal) {
            // do we want to pass sooo much stuff?
            var data = brochureProposal.dataOnly();
            var url = this.Routes.v2_workspace_file_update_brochure_proposal_path(workspaceFile._id, brochureProposal._id);
            return this.apiUpdate(url, data, workspaceFile, true);
        },

        updateBrochureProposalsOrder: function updateBrochureProposalsOrder(workspaceFile, proposalsModel) {
            var url = this.Routes.v2_workspace_file_update_brochure_proposals_order_path(workspaceFile._id);
            var data = {};
            proposalsModel.forEach(function(proposal) {
               data[proposal._id] = proposal.order;
            });

            return this.apiUpdate(url, {brochure_proposals_order: data}, workspaceFile, true);
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(workspaceFile, brochureProposal, image) {
            var url = this.Routes.v2_workspace_file_update_brochure_proposal_image_path(workspaceFile._id, brochureProposal._id);
            return this.apiUpdate(url, image, workspaceFile, true);
        },

        selectBrochureProposal: function selectBrochureProposal(workspaceFile, brochureProposal) {
            var url = this.Routes.v2_workspace_file_select_brochure_proposal_path(workspaceFile._id, brochureProposal._id);
            return this.apiUpdate(url, null, workspaceFile, true);
        },
        
        submitBrochure: function submitBrochure(brochureFile) {
            var url = this.Routes.v2_workspace_file_submit_brochure_path(brochureFile._id);
            return this.apiCreate(url, null, brochureFile, true);
        },

        /*****************************************
         * SERVICES ACTIONS
         ******************************************/
        updateServicesOrder: function updateServicesOrder(workspaceFile, brochureProposal, packageModel) {
            var url = this.Routes.v2_update_services_order_on_brochure_package_path(workspaceFile._id, brochureProposal._id, packageModel._id);

            var data = [];
            packageModel.base_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data}, workspaceFile, true);
        },

        updateALaCarteServiceSelection: function updateALaCarteServiceSelection(brochureFile, serviceModel, isSelected) {
            var url = this.Routes.v2_update_brochure_a_la_carte_service_selection_path(brochureFile._id, serviceModel._id);
            return this.apiUpdate(url, {selected: isSelected}, brochureFile, true);
        },

        updateServicesOrderInALaCarte: function updateServicesOrderInALaCarte(workspaceFile, aLaCarteModel) {
            var url = this.Routes.v2_update_services_order_in_brochure_a_la_carte_path(workspaceFile._id);
            
            var data = [];
            aLaCarteModel.package_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });
            
            return this.apiUpdate(url, {servicesOrder: data}, workspaceFile, true);
        },

        addServiceToBrochureALaCarte: function addServiceToBrochureALaCarte(brochureFile, serviceData) {
            var url = this.Routes.v2_add_service_to_brochure_a_la_carte_path(brochureFile._id, serviceData);
            return this.apiCreate(url, null, brochureFile, true);
        },

        deleteServiceFromBrochureALaCarte: function deleteServiceFromBrochureALaCarte(brochureFile, serviceData) {
            var url = this.Routes.v2_delete_service_on_brochure_a_la_carte_path(brochureFile._id, serviceData._id);
            return this.apiDelete(url, null, brochureFile, true);
        },

        updateServiceOnBrochureALaCarte: function updateServiceOnBrochureALaCarte(brochureFile, serviceData) {
            var url = this.Routes.v2_update_service_on_brochure_a_la_carte_path(brochureFile._id, serviceData._id);
            return this.apiUpdate(url, serviceData, brochureFile, true);
        },

        updateImageInALaCarteService: function updateImageInALaCarteService(brochureFile, serviceModel, image) {
            var url = this.Routes.v2_brochure_a_la_carte_update_service_image_path(brochureFile._id, serviceModel._id);
            return this.apiUpdate(url, {image: image}, brochureFile, true);
        },

        deleteImageFromALaCarteService: function deleteImageFromALaCarteService(brochureFile, serviceModel) {
            var url = this.Routes.v2_brochure_a_la_carte_delete_service_image_path(brochureFile._id, serviceModel._id);
            return this.apiDelete(url, null, brochureFile, false);
        }
    });
}());
