(function () {
    "use strict";

    class FormComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $sce, AnalyticsService, $translate, Enums, $, $timeout) {
            super($scope, $injector);
            this.__objectType = 'FormComponentController';

            this.$scope = $scope;
            this.$sce = $sce;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$ = $;
            this.$timeout = $timeout;

            this.connectedIndicatorText = "";
            this.previewQuestions = null;
            this.currQuestionIndex = 0;

            // var questionsDisplayText = [
            //     {key:this.Enums.suggestedQuestions.full_name, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._FULL_NAME_'},
            //     {key:this.Enums.suggestedQuestions.email, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._EMAIL_'},
            //     {key:this.Enums.suggestedQuestions.phone_number, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PHONE_NUMBER_'},
            //     {key:this.Enums.suggestedQuestions.about, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._ABOUT_'},
            //     {key:this.Enums.suggestedQuestions.lead_source, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LEAD_SOURCE_'},
            //     {key:this.Enums.suggestedQuestions.project_type, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_TYPE_'},
            //     {key:this.Enums.suggestedQuestions.project_location, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LOCATION_'},
            //     {key:this.Enums.suggestedQuestions.project_budget, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._BUDGET_'},
            //     {key:this.Enums.suggestedQuestions.guests, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._GUESTS_'},
            //     {key:this.Enums.suggestedQuestions.project_date, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_DATE_'},
            // ];
            //
            // this.connectedQuestionDisplayTextMap = questionsDisplayText.reduce((obj, item) => {
            //     obj[item.key] = item;
            //     return obj;
            // }, {});
        }

        $onInit() {
            this.$scope.$on('parentComponentFocusChanged', function($event, isFocused) {

                if(isFocused === false) {
                    this.showSettings = false;
                }

            }.bind(this));

            // this.$scope.$watch('formComponentVm.component.questions[0].connected_property', function() {
            //     this._setConnectedFieldText();
            // }.bind(this));
        }

        $onChanges() {
            // this._setConnectedFieldText();
        }

        // _setConnectedFieldText() {
        //     if(this.component.questions[0].isSmartQuestion()) {
        //         var translationKey = this.connectedQuestionDisplayTextMap[this.component.questions[0].connected_property];
        //         this.connectedIndicatorText = this.$translate.instant(translationKey.text);
        //     } else {
        //         this.connectedIndicatorText = "";
        //     }
        // }

        toggleSettings($event) {
            this.showSettings = !this.showSettings;
            if(this.showSettings) {
                this.$scope.$emit('childComponentFocusChanged', true);
            }

            this._scrollPageIfNeeded($event);
        }

        _getSettingPopoverHeight($event) {
            let hardCodedHeight = 400;
            let actualHeight = this.$($event.currentTarget).parent().find(".hb-popover-content").innerHeight();

            // TODO: the actual height is incorrect, since the element is hidden, so at the moment i return the hard coded value;

            return hardCodedHeight;
        }

        _scrollPageIfNeeded($event) {

            let currSettingsComponentHeight = this._getSettingPopoverHeight($event);

            if (!this.showSettings) {
                currSettingsComponentHeight *= -1;
            }

            let newHeight = this.$(window).scrollTop() + this.$(window).height() + currSettingsComponentHeight ;

            if (newHeight  >= this.$(document).height()) {
                this.$('html, body').animate({
                    scrollTop: newHeight
                }, 1000);
            }
        }

        onSettingsClosed() {
            // don't do anything
        }

        onPreview(questions) {
            this.previewQuestions = questions;
        }
        
    }

    Components.FormComponent = {
        bindings: {
            component: '=',
            isRootComponent: '<',
            isEditable: '<',
            isEditableByClient: '<',
            isOwnerMode: '<',
            isStyleCustomizable: '<',
        },
        controller: FormComponentController,
        controllerAs: 'formComponentVm',
        name: 'hbFormComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/form/form_component.html',
    };

}());
