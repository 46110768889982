(function () {
    'use strict';

    // @ngInject
    Services.RecaptchaService = function RecaptchaService($window, $q, Gon, ThirdPartyLoaderService, AppConfigService) {
        return {
            load: function() {
                if (!this.loadPromise) {
                    this.loadPromise = ThirdPartyLoaderService.loadUrlPromise(
                        `https://www.google.com/recaptcha/api.js?render=${Gon.google_recaptcha_v3_site_key}`,
                        () => $window.grecaptcha
                    )
                }

                return this.loadPromise;
            },

            getRecaptchaToken: function(actionName) {
                const defer = $q.defer();
                this.load().then(grecaptcha => {
                    grecaptcha.ready(() => {
                        grecaptcha.execute(AppConfigService.appConfiguration().google_recaptcha_v3_site_key, {action: actionName}).then(recaptchaToken => {
                            defer.resolve(recaptchaToken);
                        }).catch(err => {
                            defer.reject(err);
                        });
                    });
                }).catch(err => {
                    defer.reject(err);
                });

                return defer.promise;
            }
        };
    };

}());
