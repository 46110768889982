(function () {
    'use strict';

    // @ngInject
    function LoginAsClientControllerCtor($injector, $scope, $modalInstance, UsersManager, AnalyticsService, client, workspaceFileId) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginAsClientController';

        this.$modalInstance = $modalInstance;
        this.client = client;
        this.workspaceFileId = workspaceFileId;
        this.codeSent = false;
        this.codeResent = false;
        this.showVerificationError = false;
        this.codeVerified = false;
        this.UsersManager = UsersManager;

        AnalyticsService.trackPageView(this, 'login as client modal');
    }

    Controllers.LoginAsClientController = Class(Controllers.BaseController, {
        constructor: LoginAsClientControllerCtor,

        sendCode: function sendCode(resend){
            this.codeSent = true;
            this.UsersManager.sendClientLoginCode(this.client, this.workspaceFileId).then(function success(){
                if(angular.isDefined(resend) && resend){
                    this.codeResent = true;
                }
            }.bind(this));
        },

        verifyCode: function verifyCode(){
            this.UsersManager.verifyClientLoginCode(this.client, this.verificationCode).then(
                function success(){
                    this.codeVerified = true;
                }.bind(this),
                function error(resp, e){
                    this.showVerificationError = true;
                }.bind(this)
            );
        },

        close: function close(result) {
            this.codeSent = true;
            if(result === "login"){
                return this.$modalInstance.close(this.verificationCode);
            }
            this.$modalInstance.close();
        },

    });

}());