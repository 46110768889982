Directives.BrochureDirective = function BrochureDirective() {

    // @ngInject
    function BrochureDirectiveControllerFunc($injector, $scope, $element,
                                             BrochuresManager, CompaniesManager, WorkspaceFilesManager,
                                             ModalService, AnalyticsService, PopupMessageService,UIUtils, _, $translate) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrochureDirectiveController';
        this.BrochuresManager = BrochuresManager;
        this.CompaniesManager = CompaniesManager;
        this.ModalService = ModalService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.$element = $element;
        this.reloadCarouselFlags = {};
        this.savingSelection = false;
        this.UIUtils = UIUtils;
        this.$translate = $translate;
        this._ = _;

        $scope.$watch('brochureVm.brochureModel.greeting.body', function(newVal, oldVal) {
            if (newVal !== undefined && oldVal !== newVal) {
                this.updateBrochureGreeting();
            }
        }.bind(this));

        var modelToRegister = this.workspaceFile ? this.workspaceFile : this.companyModel;
        this.register(modelToRegister, 'success', function success() {
            $scope.$applyAsync(function() {
                this.convertALaCarteServiceToViewModel();
                this.convertProposalsToViewModel();
            }.bind(this));
        }.bind(this));

        if (this.workspaceFile && this.isClientMode) {
            this.hasSelectedALaCarte = _.some(this.brochureModel.a_la_carte_proposal.package_services, function(service) {
                return service.selected;
            }.bind(this));
        }

        this.brochureProposalsVmCollection = [];
        this.convertProposalsToViewModel = function convertProposalsToViewModel() {
            this.brochureProposalsVmCollection.splice(0);
            this.brochureModel.brochure_proposals.forEach(function (proposal) {
                this.brochureProposalsVmCollection.push(proposal);
            }.bind(this));

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.brochureProposalsVmCollection = _.sortBy(this.brochureProposalsVmCollection, function(proposalViewModel) {
                return proposalViewModel.order ? proposalViewModel.order : 0;
            });
        };

        // call this on loading, since after refresh the file is loaded before we load this directive
        this.convertProposalsToViewModel();

        this.convertALaCarteServiceToViewModel = function convertALaCarteServiceToViewModel() {

            this.aLaCarteServicesVmCollection = [];

            if (this._.isObject(this.brochureModel.a_la_carte_proposal)) {
                var aLaCarte = this.brochureModel.a_la_carte_proposal;

                if (aLaCarte.package_services) {
                    this.aLaCarteServicesVmCollection.splice(0);
                    aLaCarte.package_services.forEach(function (currentService) {
                        this.aLaCarteServicesVmCollection.push(currentService.dataOnly());
                    }.bind(this));

                    //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
                    this.aLaCarteServicesVmCollection = _.sortBy(this.aLaCarteServicesVmCollection, function(serviceViewModel) {
                        return serviceViewModel.order ? serviceViewModel.order : 0;
                    });
                }
            }
        };
        this.convertALaCarteServiceToViewModel();

        this.aLaCarteSortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.drag-icon-container',
            opacity: 0.8,
            helper: 'clone',

            start: function (event, ui) {
                AnalyticsService.track(this, AnalyticsService.analytics_events.service_drag_in_a_la_carte_started, this.brochureModel.a_la_carte_proposal.getAnalyticsArgs());
            }.bind(this),

            stop: function stop(event, ui) {
                this.aLaCarteServicesVmCollection.forEach(function (serviceViewModel, index) {
                    var serviceModel = this._.findWhere(this.brochureModel.a_la_carte_proposal.package_services, {_id: serviceViewModel._id});
                    serviceModel.order = index;
                }.bind(this));

                this.brochureModel.a_la_carte_proposal.updateServicesOrder().then(
                    function success() {
                        AnalyticsService.track(this, AnalyticsService.analytics_events.service_drag_in_a_la_carte_ended, this.brochureModel.a_la_carte_proposal.getAnalyticsArgs());
                    }.bind(this),
                    function error() {
                        AnalyticsService.track(this, AnalyticsService.analytics_events.service_drag_in_a_la_carte_error, this.brochureModel.a_la_carte_proposal.getAnalyticsArgs());
                    }.bind(this)
                );

            }.bind(this)
        };
    }

    var BrochureDirectiveController = Class(Controllers.BaseController, {
        constructor: BrochureDirectiveControllerFunc,

        updateBrochureGreeting: function updateBrochureGreeting() {
            return this.brochureModel.updateBrochureGreeting();
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(type, selectedImage) {
            var promise = this.brochureModel.updateBrochureGreetingImage(selectedImage);
            if (type === 'reposition') {
                this.reloadCarouselFlags.intro = true;
                this.$scope.$applyAsync(function loadChangesTimeout() {
                    this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function getCompanySuccess() {
                        this.reloadCarouselFlags.intro = false;
                    });
                }.bind(this));
            }
            return promise;
        },

        isIntroBodyEmpty: function isIntroBodyEmpty() {
            return !(this.brochureModel.greeting && this.brochureModel.greeting.body);
        },

        showTooltip: function showTooltip(){
            var show = false;

            var firstHasTitle = this.brochureModel.brochure_proposals &&
                this.brochureModel.brochure_proposals[0] &&
                this.brochureModel.brochure_proposals[0].title &&
                (this.brochureModel.brochure_proposals[0].title !== "Collection Title" && this.brochureModel.brochure_proposals[0].title !== "Package Title"); //package title is the old title of a new brochure proposal.
            var firstHasPrice = this.brochureModel.brochure_proposals &&
                this.brochureModel.brochure_proposals[0] &&
                this.brochureModel.brochure_proposals[0].brochure_price &&
                this.brochureModel.brochure_proposals[0].brochure_price !== "Price";

            if(this.isOwnerMode && this.isEditableMode && !this.isPreviewMode && !this.isViewOnlyMode && //vendor edit mode
                (!firstHasTitle && !firstHasPrice)){ // one package with default values
                show = true;
            }
            return show;
        },

        deleteBrochureProposalInner: function deleteBrochureProposalInner(brochureProposal){
            this.deleteBrochureProposalLoader = true;
            return this.brochureModel.deleteBrochureProposal(brochureProposal).finally(function afterDeleteBrochureProposal() {
                this.deleteBrochureProposalLoader = false;
            }.bind(this));
        },

        deleteBrochureProposal: function deleteBrochureProposal(brochureProposal) {
            var onCancel = function() {
            };

            // show confirmation popup
            var confirmMsg = this.$translate.instant('FILE.TEMPLATES.BROCHURE._DELETE_COLLECTION_CONFIRM_MESSAGE_');
            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.warning,
                confirmMsg,
                function(){
                    this.deleteBrochureProposalInner(brochureProposal)
                }.bind(this),
                onCancel.bind(this));
        },

        updateBrochureProposal: function updateBrochureProposal(brochureProposal) {
            return this.brochureModel.updateBrochureProposal(brochureProposal);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte() {
            return this.brochureModel.updateBrochureALaCarte();
        },

        createBrochureProposal: function createBrochureProposal() {
            return this.brochureModel.createBrochureProposal();
        },

        chooseBrochureProposal: function chooseBrochureProposal(brochureProposal) {

            if(this.isOwnerMode || this.isViewOnlyMode) {
                //do nothing if this is the vendor that is pressing the choose button in (in the preview mode)
                return;
            }

            if (this.savingSelection) {
                //we are already in the process of saving the selection. prevent another from taking place.
                return;
            }

            if (this.workspaceFile.isComplete()) {
                return;
            }

            if (brochureProposal._id === this.workspaceFile.selected_proposal_id){
                return;
            }

            this.savingSelection = true;
            this.brochureModel.selectBrochureProposal(brochureProposal).finally(function doFinally() {
                this.savingSelection = false;
                this.onSelectBrochureAction();
            }.bind(this));
        },

        isEditMode: function isEditMode() {
            return  this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(type, selectedImage, brochureProposal) {
            var promise = brochureProposal.updateBrochureProposalImage(selectedImage);
            if (type === 'reposition') {
                this.reloadCarouselFlags[brochureProposal._id] = true;
                this.$scope.$applyAsync(function loadChangesTimeout() {
                    this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function getCompanySuccess() {
                        this.reloadCarouselFlags[brochureProposal._id] = false;
                    });
                }.bind(this));
            }
            return promise;
        },

        toggleServiceSelection: function toggleServiceSelection(service) {
            if(this.isOwnerMode || (this.isClientMode && (this.workspaceFile.isCanceled() || this.workspaceFile.isComplete()))) {
                return;
            }
            this.brochureModel.updateServiceSelection(service, !service.selected);

            // Whenever this method is called, it means the client saw and understood that he can pick an a la carte item
            // No need for a real calculation here
            this.hasSelectedALaCarte = true;
        },

        addALaCarteService: function addALaCarteService() {
            this.brochureModel.a_la_carte_proposal.addService();
        },

        addBrochureProposal: function addBrochureProposal() {
            this.addBrochureProposalLoader = true;
            var promise = this.brochureModel.createBrochureProposal();
            promise.then(function () {
                var unwatch = this.$scope.$watchCollection('brochureVm.brochureModel.brochure_proposals', function collectionChanged() {
                    // Wait until the collection is changed.
                    // It means we can scroll to the newly created collection in the brochure
                    var scrollParent = this.$element.scrollParent();
                    var lastProposalHeight = this.$element.find('.hb-section-file-main:last-child').height();
                    var greetingContainerHeight = this.$element.find('.brochure-greeting-container').height();
                    var scrollY = scrollParent.height() - lastProposalHeight - greetingContainerHeight -150; //150 approximate height of other stuff on templates
                    scrollParent.scrollTo(scrollParent.scrollLeft(), scrollY, 500); // scroll to bottom
                    unwatch();
                }.bind(this));
            }.bind(this));
            promise.finally(function afterAddBrochureProposal() {
                this.addBrochureProposalLoader = false;
            }.bind(this));
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(type, selectedImage) {
            var promise = this.brochureModel.a_la_carte_proposal.updateBrochureALaCarteImage(selectedImage);
            if (type === 'reposition') {
                this.reloadCarouselFlag = true;
                this.$scope.$applyAsync(function loadChangesTimeout() {
                    this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function getCompanySuccess() {
                        this.reloadCarouselFlag = false;
                    });
                }.bind(this));
            }
            return promise;
        },

        createALaCarte: function createALaCarte() {
            var promise = this.brochureModel.createBrochureALaCarteProposal();
            promise.finally(function(){
                this.convertALaCarteServiceToViewModel();
                var unwatch = this.$scope.$watchCollection('brochureVm.brochureModel.a_la_carte_proposal', function collectionChanged() {
                    // Wait until the collection is changed.
                    // It means we can scroll to the newly created collection in the brochure
                    var scrollParent = this.$element.scrollParent();
                    scrollParent.scrollTo(scrollParent.scrollLeft(), scrollParent.height(), 500); // scroll to bottom
                    unwatch();
                }.bind(this));
            }.bind(this));
        },
        
        deleteALaCarte: function deleteALaCarte() {
            var onCancel = function() {
            };

            // show confirmation popup
            var confirmMsg = this.$translate.instant('FILE.TEMPLATES.BROCHURE._DELETE_A_LA_CARTE_CONFIRM_MESSAGE_');
            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.warning,
                confirmMsg,
                this.brochureModel.deleteBrochureALaCarteProposal.bind(this.brochureModel),
                onCancel.bind(this));
        },

        deleteBrochureIntroConfirm: function deleteBrochureIntroConfirm() {
            var onCancel = function() {
            };

            // show confirmation popup
            var confirmMsg = this.$translate.instant('FILE.TEMPLATES.BROCHURE._DELETE_INTRO_CONFIRM_MESSAGE_');
            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.warning,
                confirmMsg,
                this.deleteBrochureIntro.bind(this),
                onCancel.bind(this));
        },


        shouldShowALaCarte: function shouldShowALaCarte() {

            var aLaCarteProposal = this.brochureModel.a_la_carte_proposal;

            if (aLaCarteProposal && !aLaCarteProposal.isEmpty()) {

                // If we have a la carte and we in preview/client mode, only show if it has services
                if (this.isPreviewMode || this.isClientMode || (this.workspaceFile && this.workspaceFile.isSent())) {
                    return aLaCarteProposal.package_services.length > 0;
                }

                // If edit mode/templates
                return this.isEditableMode || this.isInCompanyTemplates;
            }

            return false;
        }
    });

    return {
        scope: {
            workspaceFile: '=?workspaceFile',
            brochureModel: '=brochureModel',
            companyModel: '=companyModel',
            isOwnerMode: '=isOwnerMode',
            isClientMode: '=isClientMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            isViewOnlyMode: '=isViewOnlyMode',
            isInCompanyTemplates: '=?isInCompanyTemplates',
            deleteBrochureIntro: '&?',
            addBrochureIntro: '&?',
            owner: '=owner',
            onSelectBrochureAction: '&?'

        },
        templateUrl: 'angular/app/modules/core/features/brochure/brochure_directive_template.html',
        controller: BrochureDirectiveController,
        controllerAs: 'brochureVm',
        bindToController: true
    };
};
