(function () {
    'use strict';

    class BankAccountTypeSelectionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, UsersManager, DeviceService) {
            super($scope, $injector);
            this.__objectType = 'BankAccountTypeSelectionController';
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.user = UsersManager.getCurrUser();
            this.showTooltip = false;
            this.isSmallScreen = this.DeviceService.nxSmallBreakpoint();
            this.isCanada = this.user.company.country === 'CA';
            this.individualCardToolip = this.isCanada ?
                'COMPANY_SETTING.BANK_ACCOUNT._INDIVIDUAL_CARD_TOOLTIP_BODY_CA_' : 'COMPANY_SETTING.BANK_ACCOUNT._INDIVIDUAL_CARD_TOOLTIP_BODY_';

            this.soloProprietorCardToolip = this.isCanada ?
                'COMPANY_SETTING.BANK_ACCOUNT._SOLE_PROPRIETOR_CARD_TOOLTIP_BODY_CA_' : 'COMPANY_SETTING.BANK_ACCOUNT._SOLE_PROPRIETOR_CARD_TOOLTIP_BODY_';

        }

        onAccountTypeClick(type) {
            this.AnalyticsService.trackClick(
                this, 
                'add bank account',
                { type }
            );
            this.onTypeSelection({type});
        }


    }

    Components.BankAccountTypeSelection = {
        bindings: {
            onTypeSelection: '&',
            customClass: '@?',
            shortText: '<?'
        },
        controller: BankAccountTypeSelectionController,
        name: 'hbBankAccountTypeSelection',
        templateUrl: 'angular/app/modules/core/features/settings/company/bank/bank_account_type_selection_component_template.html',
    };

}());