(function () {
    "use strict";

    // @ngInject
    function SettingsControllerCtor($scope, $injector, $, $rootScope,
                                    AppStates, NotificationService, AnalyticsService,
                                    CompaniesManager, UsersManager, moment) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SettingsController';

        this.NotificationService = NotificationService;

        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.user = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();


        this.updateStateData(this.$state.current);

        this.deregisterStateChange = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            this.updateStateData(toState);
        }.bind(this));

        this.registerOnce(this.company, 'success', function success() {
            //OPTIMIZATION: so some pre-fetching for all the company cover_images
            if (this.company.cover_images) {
                this.company.cover_images.forEach(function (image) {
                    if (image.cloudinary_public_id) {
                        $.cloudinary.image(image.cloudinary_public_id, {
                            cloud_name: image.cloudinary_sub_account,
                            transformation: [
                                {height: 150, crop: 'fill'},
                                {fetch_format: 'auto', flags: 'lossy'}
                            ]
                        });
                    }
                });
            }
        });

        $scope.$on("$destroy", function handleDestroy() {
            this.deregisterStateChange();
        }.bind(this));
    }


    Controllers.SettingsController = Class(Controllers.BaseController, {

        constructor: SettingsControllerCtor,

        updateStateData: function updateStateData(state) {
            if(state.data && state.data.showChangedSavedIndicator !== undefined){
                this.showChangedSavedIndicator = state.data.showChangedSavedIndicator;
            } else {
                //make the default as shown
                this.showChangedSavedIndicator = true;
            }
        }
    });
}());
