(function () {
    'use strict';

    // @ngInject
    function ActivityNotificationsControllerCtor($scope, $injector, AnalyticsService, UsersManager) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ActivityNotificationsController';

        AnalyticsService.trackPageView(this, 'ActivityNotificationsView');

        // mark as read
        this.currUser = UsersManager.getCurrUser();
        if(this.currUser.hasUnseenNotifications()) {
            // this.currUser.markAllUserActivityNotificationsAsSeen();
        }
    }

    Controllers.ActivityNotificationsController = Class(Controllers.BaseController, {
        constructor: ActivityNotificationsControllerCtor,


    });
}());
