Directives.TimeTrackerEmptyDirective = function TimeTrackerEmptyDirective() {

    // @ngInject
    function TimeTrackerEmptyDirectiveControllerFunc($injector, $scope) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerEmptyDirectiveController';

    }

    var TimeTrackerEmptyDirectiveController = Class(Controllers.BaseController, {
        constructor: TimeTrackerEmptyDirectiveControllerFunc,

    });

    return {
        scope: {
            logTime: '&',
            viewSample: '&',
            showFilterEmptyState: '='
        },
        templateUrl: 'angular/app/modules/core/features/time_tracker/time_tracker_empty_state/time_tracker_empty_state_template.html',
        controller: TimeTrackerEmptyDirectiveController,
        controllerAs: 'timeTrackerEmptyVm',
        bindToController: true
    };
};
