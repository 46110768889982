(function () {
    'use strict';

    class FlowCardComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, $sce, UIUtils, $translate, $window, _, AppStates, ModalService, TemplatesManager, FlowService, FlowsManager,
            PopupMessageService, UsersManager, CompaniesManager, Routes, ngToast, ToastService, AnalyticsService, Enums, ReactLoaderService, DeviceService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'FlowCardComponentController';
            this.AppStates = AppStates;
            this.$state = $state;
            this.$sce = $sce;
            this.UIUtils = UIUtils;
            this._ = _;
            this.$translate = $translate;
            this.$window = $window;
            this.FlowsManager = FlowsManager;
            this.FlowService = FlowService;
            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.TemplatesManager = TemplatesManager;
            this.AnalyticsService = AnalyticsService;
            this.Routes = Routes;
            this.ModalService = ModalService;
            this.ToastService = ToastService;
            this.ngToast = ngToast;
            this.PopupMessageService = PopupMessageService;
            this.currCompany = CompaniesManager.getCurrCompany();
            this.currUser = UsersManager.getCurrUser();
            this.isClient = this.currUser.isClient();
            this.Enums = Enums;
            this.ReactLoaderService = ReactLoaderService;
            this.isFlowThumbnailVisibleToClients = this.flow.is_flow_thumbnail_visible_to_client;
            this.variablesMap = this.flow.variables.reduce((result, variable) => {
                result[variable.variable_id] = variable;
                return result;
            }, {});
            this.requiredActionBlocks = this.flow.actions.filter(action => action.is_required);
            this.requiredQuestions = this.flow.questions.filter(q => q.is_required);

            this.enableMarkFlowAsCompleted = this.flow.is_eligible_for_mark_as_completed && this.currUser.hasCompanyModeratorPermissions() && !this.flow.expired && this.flow.status !== 'completed';

            this.hasViewOnly = !!this.flow.view_only_flow_url && this.currUser.hasCompanyModeratorPermissions();
            this.enableGenerateViewOnly = !this.hasViewOnly && this.flow.is_published && this.currUser.hasCompanyModeratorPermissions();

            // maps the page numbers to the actions in them, separated by the type { page_number: { action_type: [{ action }] } }
            this.pageToActionsByType = this.requiredActionBlocks.reduce((result, action) => {
                if (!result[action.page_number]) result[action.page_number] = {};
                if (!result[action.page_number][action.type]) result[action.page_number][action.type] = [];
                result[action.page_number][action.type].push(action);
                return result;
            }, {});

            // returns the actions that are both first of their type in their page and the previous page didn't contain an action of that type
            this.actionsToDisplay = this.requiredActionBlocks.filter(action => {
                const actionsOfTypeInPage = this.pageToActionsByType[action.page_number][action.type];
                const currIndex = actionsOfTypeInPage.findIndex(a => a.block_id === action.block_id);
                const isInSamePage = action.type === 'Service' && actionsOfTypeInPage.slice(0, currIndex).length;
                const prevPageActions = this.pageToActionsByType[action.page_number - 1];
                const isInPrevPage = prevPageActions
                    && prevPageActions[action.type]
                    && prevPageActions[action.type].length;
                return !isInSamePage && !isInPrevPage;
            })

            // maps the block_ids to their consecutive actions, separated by the page number (the data used for rendering the tooltip body)
            // { block_id: { page_number: [{ actionTooltipData }] }
            this.actionIdToConsecutiveActionsMap = this.actionsToDisplay.reduce((result, action) => {
                const numberOfConsecutiveActions = this.getConsecutiveActions(action).length;
                if (numberOfConsecutiveActions > 1) {
                    result[action.block_id] = this.getConsecutiveActionData(action);
                }
                return result;
            }, {});

            this.unWatchFlow = $scope.$watch('$ctrl.flow', this.handleFlowChange.bind(this), true)
        }

        handleFlowChange(newFlow, oldFlow) {
            if (newFlow !== oldFlow) {
                const isVisible = newFlow.is_flow_thumbnail_visible_to_client;
                if (isVisible !== this.isFlowThumbnailVisibleToClients) {
                    this.isFlowThumbnailVisibleToClients = isVisible;
                }
            }
        }

        $onDestroy() {
            this.unWatchFlow();
        }

        numberOfViewedPages() {
            return this.flow.pages_info.filter(page => page.was_viewed).length;
        }

        isFromLeadForm() {
            return this.flow.source_attribution === 'public_flows';
        }

        copyViewOnlyLink(event){
            event.stopPropagation();
            navigator.clipboard.writeText(this.flow.view_only_flow_url);
            this.ToastService.showSuccess({
                contentTranslation: 'Link copied!',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout: 2000
            });
            this.isPopoverVisible = false;
        }

        isActionAggregated(actionId) {
            return !!this.actionIdToConsecutiveActionsMap[actionId];
        }

        consecutiveActionsCompleted(action) {
            const consecutiveActions = this.getConsecutiveActions(action);
            return consecutiveActions.every(action => this.isCompletedAction(action));
        }

        consecutiveActionsInProgress(action) {
            const consecutiveActions = this.getConsecutiveActions(action);
            const numberOfCompletedActions = consecutiveActions.filter(action => this.isCompletedAction(action)).length;
            return consecutiveActions.some(action => this.isInProgressAction(action))
                || (numberOfCompletedActions > 0 && numberOfCompletedActions < consecutiveActions.length);
        }

        consecutiveActionsEmpty(action) {
            const consecutiveActions = this.getConsecutiveActions(action);
            return consecutiveActions.every(action => this.isEmptyAction(action));
        }

        // returns an array of the input action and all of it's consecutives(actions of the same type that appear in the same page and/or the consecutive one(s))
        getConsecutiveActions(action) {
            let currPage = action.page_number;
            let consecutiveActions = [];
            while (this.pageToActionsByType[currPage] && this.pageToActionsByType[currPage][action.type]) {
                const actionsOfTypeInPage = this.pageToActionsByType[currPage][action.type];
                consecutiveActions = consecutiveActions.concat(actionsOfTypeInPage);
                currPage++;
            }
            return consecutiveActions;
        }

        numberOfConsecutiveAppearances(action) {
            return this.getConsecutiveActions(action).length;
        }

        getActionDisplayName(action) {
            if (action.type === 'Service') {
                if (this.isActionAggregated(action.block_id)) {
                    return 'Service selection';
                }
                return 'Services';
            } else if (action.type === 'Recurring Invoice') {
                return 'Invoice';
            }
            return action.type;
        }

        getActionData(action) {
            switch (action.type) {
                case 'Service':
                    return {
                        description: action.service.selection_type === 'multiple' ? 'Multiple select' : 'Single select',
                        completion_status: `Selected ${action.service.total_selected_services} of ${action.service.total_services}`
                    }
                case 'Contract':
                    return {
                        description: 'Required signatures',
                        completion_status: `Signed ${action.contract.signed_required_signatures} of ${action.contract.total_required_signatures}`
                    }
                default:
                    break;
            }
        }

        // creates the data structure used to render the action tooltip body
        getConsecutiveActionData(action) {
            const consecutiveActions = this.getConsecutiveActions(action);
            return consecutiveActions.reduce((result, action) => {
                if (!result[action.page_number]) result[action.page_number] = [];
                result[action.page_number].push(this.getActionData(action));
                return result;
            }, {});
        }

        getVariable(item) {
            const { connected_variable_id } = item;
            return this.variablesMap[connected_variable_id];
        }

        isEmptyAction(action) {
            return action.status === this.Enums.flowActionStatuses.pending;
        }

        isCompletedAction(action) {
            return action.status === this.Enums.flowActionStatuses.completed;
        }

        isInProgressAction(action) {
            return action.status === this.Enums.flowActionStatuses.inProgress;
        }

        getDataContent(action) {
            switch (action.type) {
                case 'Invoice': {
                    return `(paid ${action.invoice.payments_paid} of ${action.invoice.total_payments})`;
                }
                case 'Recurring Invoice': {
                    return `(${action.invoice.payments_paid} paid)`;
                }
                case 'Contract': {
                    return `(signed ${action.contract.signed_required_signatures} of ${action.contract.total_required_signatures})`;
                }
                case 'Service': {
                    return `(selected ${action.service.total_selected_services} of ${action.service.total_services})`;
                }
                case 'Scheduler': {
                    return action.scheduler.is_scheduled ? '(session scheduled)' : '';
                }
                default:
                    break;
            }
        }

        numberOfCompletedQuestions() {
            const completedQuestions = this.requiredQuestions.filter(question => {
                const { connected_variable_id } = question;
                const variable = this.variablesMap[connected_variable_id];

                if (!variable) {
                    return false;
                }

                const { value } = variable;
                const { isObject, isEmpty } = this._;

                const isEmptyObject =
                    isObject(value) &&
                    (isEmpty(value) || Object.values(value).every(val => val === null));

                return value && !isEmptyObject;
            });

            return completedQuestions.length;
        }

        isDescendant(el, parentId) {
            let isChild = false;

            while (el !== null && !isChild) {
                if (el.id === parentId) {
                    isChild = true;
                }

                el = el.parentNode;
            }

            return isChild;
        }

        goToFlow(event, shouldOpenConfigModal) {
            event.stopPropagation();
            if (document.querySelector('.tippy-tooltip')) {
                document.querySelector('.tippy-tooltip').remove();
            }
            if (shouldOpenConfigModal) {
                this.FlowService.goToFlow(this.flow._id, null, null, { openConfigureModal: true });
            } else {
                this.FlowService.goToFlowByUser(this.flow, this.currUser);
            }
        }

        markFlowAsCompleted(event) {
            event.stopPropagation();
            this.FlowsManager.markFlowAsCompleted(this.flow).then(() => {
                this.flow.status = 'completed';
                this.AnalyticsService.trackAction(this, 'click mark file as completed', {source:'activity tab three dots menu'});
            }).finally(() => {
                this.isPopoverVisible = false;
                this.enableMarkFlowAsCompleted = false;
                this.ToastService.showSuccess({
                    contentTranslation: this.$translate.instant('WORKSPACE.FILES._MARK_AS_COMPLETED_TOAST_'),
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 3000
                });
            });
        }

        goToPreview($event) {
            $event.stopPropagation();
            this.AnalyticsService.trackAction(this, 'flow three dots menu action', {
                status: this.flow.status,
                action: 'preview',
                app_path: this.analyticsAppPath
            });
            this.FlowService.goToPreview(this.flow._id);
        }

        handleMenuClick($event) {
            $event.stopPropagation();
            this.isPopoverVisible = !this.isPopoverVisible;
        }

        clickOutsideMenu() {
            this.isPopoverVisible = false;
        }

        renameFlow($event) {
            $event.stopPropagation();
            this.AnalyticsService.trackAction(this, 'flow three dots menu action', {
                status: this.flow.status,
                action: 'rename',
                app_path: this.analyticsAppPath
            });
            const modalTitle = 'WORKSPACE.FEED.FLOW.MODALS._RENAME_FLOW_';
            const modalInputTitle = 'WORKSPACE.FEED.FLOW.MODALS._FLOW_NAME_';
            this.ModalService.openChooseTitleModal({
                currentTitle: this.flow.title,
                modalTitle: modalTitle,
                modalInputTitle: modalInputTitle
            }).then(newTitle => {
                this.FlowsManager.updateFlow({ title: newTitle }, this.flow._id).then(flow => {
                    this.flow.title = flow.title;
                });
            });
        }

        goToTemplates() {
            this.$state.go(this.AppStates.root_core_navigation_my_templates);
        }

        isClientInFlow() {
            return this.FlowService.isClientInFlow(this.flow, this.currUser);
        }

        isScheduledFlow() {
            return this.FlowService.isScheduledFlow(this.flow);
        }

        deleteEnabled() {
            return this.currUser.hasCompanyModeratorPermissions() && this.flow.is_deletable;
        }

        saveFlowAsTemplate($event) {
            $event.stopPropagation();
            this.AnalyticsService.trackAction(this, 'flow three dots menu action', {
                status: this.flow.status,
                action: 'save_as_template',
                app_path: this.analyticsAppPath
            });
            const modalTitle = 'WORKSPACE.FEED.FLOW.MODALS._SAVE_AS_TEMPLATE_';
            const modalInputTitle = 'WORKSPACE.FEED.FLOW.MODALS._TEMPALTE_NAME_';
            this.ModalService.openChooseTitleModal({
                currentTitle: this.flow.title,
                modalTitle: modalTitle,
                modalInputTitle: modalInputTitle
            }).then(templateTitle => {
                this.TemplatesManager.saveFlowAsTemplate(this.currCompany, this.flow, templateTitle).then(() => {
                    const toastContent = `<div class="flow-action-toast-content">
                                                <i class="icon icon-hb-nx-check-mark-circle-16"></i>
                                                <div class="text-container">
                                                    <div>Flow saved as "${templateTitle}"</div>
                                                    <a ng-click="$ctrl.goToTemplates()">Go to template</a>
                                                </div>
                                            </div>`;

                    this.ngToast.create({
                        content: this.$sce.trustAsHtml(toastContent),
                        className: 'flow-action-toast alert-success',
                        dismissOnTimeout: true,
                        timeout: 4000,
                        dismissButton: true,
                        compileContent: this.$scope
                    });
                });
            });
        }

        copyFlowURL($event) {
            $event.stopPropagation();
            // Do something
        }

        getTooltipText() {
            if (this.minimizedMode) {
                return this.flow ? this.flow.title : `You can’t access "${this.activityFeedItem.title}" because it was deleted`;
            }
        }

        removeScheduleSend($event) {
            $event.stopPropagation();
            this.AnalyticsService.trackAction(this, 'flow three dots menu action', {
                status: this.flow.status,
                action: 'remove schedule',
                app_path: this.analyticsAppPath
            });

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to cancel this scheduled email?')
                .then(() => {
                    this.FlowsManager.removeScheduleSend(this.workspace.flows, this.flow._id).then((flows) => {
                        this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.remove_scheduled_send, {
                            app_path: 'miniflow_3_dots'
                        });
                        if (this.updateFlows) {
                            this.updateFlows({ connectedObjId: this.flow._id });
                        }
                    });
                })


        }

        getSessionProps() {
            var variable = this.flow.variables.find(variable => variable.type === 'schedule_container_variable');
            if (variable && variable.value.status === 'scheduled') {
                return { 
                    sessionName: variable.value.session_name,
                    isFutureMeeting: new Date(variable.value.date_time_start) > new Date()
                };
            }
            return null;
        }

        deleteFlow($event) {
            $event.stopPropagation();
            this.AnalyticsService.trackAction(this, 'flow three dots menu action', {
                status: this.flow.status,
                action: 'delete',
                app_path: this.analyticsAppPath
            });

            var confirmMsg = this.$translate.instant(this.flow.scheduled_to_be_sent ? 'WORKSPACE.FEED.FLOW.MODALS._SCHEDULED_DELETE_CONFIRMATION_' : 'WORKSPACE.FEED.FLOW.MODALS._DELETE_CONFIRMATION_', { flowTitle: this.flow.title, scheduledDate: new Date(this.flow.scheduled_to_be_sent).toDateString() });

            var sessionProps = this.getSessionProps();
            var ctaText = 'FREQUENT_BUTTONS._YES_DELETE_';

            if (sessionProps) {
                if (sessionProps.isFutureMeeting) {
                    confirmMsg += this.$translate.instant('WORKSPACE.FEED.FLOW.MODALS._SESSION_WILL_BE_CANCELED_NOTE_', sessionProps);
                    ctaText = 'FREQUENT_BUTTONS._YES_DELETE_CANCEL_SESSION_';
                } else {
                    confirmMsg += this.$translate.instant('WORKSPACE.FEED.FLOW.MODALS._SESSION_WILL_BE_DELETED_NOTE_', sessionProps);
                    ctaText = 'FREQUENT_BUTTONS._YES_DELETE_SESSION_';
                }
            } else if (this.flow.scheduled_to_be_sent) {
                ctaText = 'FREQUENT_BUTTONS._YES_DELETE_DONT_SEND_';
            }

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                confirmMsg,
                ctaText,
                'FREQUENT_BUTTONS._DONT_DELETE_',
                'WORKSPACE.FEED.FLOW.MODALS._DELETE_CONFIRMATION_TITLE_',
                undefined,
                undefined,
                this.flow.scheduled_to_be_sent || sessionProps,
                'flow-card__delete-confirmation-dialog')
                .then(() => {
                    this.FlowsManager.deleteFlow(this.workspace.flows, this.flow._id).then((flows) => {
                        this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.remove_scheduled_send, {
                            app_path: 'delete_flow'
                        });
                        if (this.updateFlows) {
                            this.updateFlows({ connectedObjId: this.flow._id });
                        }
                    });
                });
        }
    }

    Components.FlowCard = {
        bindings: {
            flow: '<',
            workspace: '=',
            updateFlows: '&',
            analyticsAppPath: '@',
            minimizedMode: '<?',
            isEmailFeedItem: '<?',
            activityFeedItem: '<?'
        },
        controller: FlowCardComponentController,
        name: 'hbFlowCard',
        templateUrl: 'angular/app/modules/core/features/workspace/flow/flow_card_template.html',
    };

}());