(function () {
    'use strict';

    // @ngInject
    function TrialExtendedControllerCtor($scope, $injector, $modalInstance, AnalyticsService, extensionLength) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TrialExtendedController';
        this.$modalInstance = $modalInstance;
        this.extensionLength = extensionLength;
        var commonNums = {
            7: "seven",
            10: "ten",
            14: "fourteen",
        };
        this.nDays = commonNums[this.extensionLength] ? commonNums[this.extensionLength] : this.extensionLength;

        AnalyticsService.trackPageView(this, 'trial extended modal');
    }

    Controllers.TrialExtendedController = Class(Controllers.BaseController, {
        constructor: TrialExtendedControllerCtor,

        close: function close() {
            this.$modalInstance.dismiss('cancel');
        }
    });
}());
