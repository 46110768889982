Directives.MeetingConnectedProject = function MeetingConnectedProject() {

    // @ngInject
    function MeetingConnectedProjectControllerCtor($scope, $injector, $timeout, SearchablesManager, AnalyticsService,
                                                   ModalService, DeviceService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'MeetingConnectedProject';
        this.$timeout = $timeout;

        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        this.isMobile = DeviceService.nxSmallBreakpoint();

        // project search
        this.SearchablesManager = SearchablesManager;
        this.searchingWorkspaceShow = false;
        this.searching = false;
        this.workspacesSearchText = this.eventName;
        this.searchDataBeingFetched = false;
        this.workspacesSearchResults = [];

        this.designApply = this.design || false;
        this.showResults = false;

        this.selectedWorkspaceBind = this;
    }

    var MeetingConnectedProject = Class(Controllers.BaseController, {
        constructor: MeetingConnectedProjectControllerCtor,

        workspaceSearchInputChanged: function workspaceSearchInputChanged(value) {
            this.workspacesSearchText = value.searchTerm;
            if (this.workspacesSearchText && this.workspacesSearchText.length > 0) {
                this.searchDataBeingFetched = true;
                this.workspacesSearchResults = this.SearchablesManager.search(this.workspacesSearchText, 'couple_cards', {hideTeamMembers: true});
                this.register(this.workspacesSearchResults, 'success', function onSearchDataFetched(callbackReason) {
                    this.searchDataBeingFetched = false;
                    this.isSearchTriggered = true;
                    if(this.workspacesSearchResults && this.workspacesSearchResults.length > 0) {
                        this.showResults = true;
                    }
                }.bind(this));
            }
        },

        shouldShowResultList: function shouldShowResultList () {
          return this.workspacesSearchText && this.workspacesSearchText.length > 0;
        },

        handleWorkspaceItemSelected: function handleWorkspaceItemSelected(workspaceSearchItem) {
            this.searchingWorkspaceShow = false;
            this.showResults = false;
            workspaceSearchItem = workspaceSearchItem && workspaceSearchItem.selectedItem ? workspaceSearchItem.selectedItem :  workspaceSearchItem;

            if (workspaceSearchItem) {
                this.selectedWorkspace = workspaceSearchItem;
                this.workspacesSearchText = this.selectedWorkspace.properties.event_name;
                this.eventId = this.selectedWorkspace.properties.event_id;
                this.workspacesSearchResults.unshift({properties: {event_name: 'No project'}});
                this.onChange({item: this.selectedWorkspace});
            }
            this.searching = false;
            const selectedProjectType = this.selectedWorkspace.properties.event_name === 'No project' ? 'no project' : 'existing project';
            this.AnalyticsService.track(this, this.analyticsEvent, {source: this.analyticsEventSource, type: selectedProjectType});
        },

        handleClickOutsideSearch: function handleClickOutsideSearch() {
            this.searching = false;
            this.showResults = false;
        },

        handleSearchFocused: function handleSearchFocused() {
            if(this.isMobile) {
                this.AnalyticsService.trackClick(this, 'open mweb connect project modal', {source: this.analyticsEventSource});
                this.ModalService.openMwebConnectProjectModal({}).then(function(resp){
                    if(resp.selectedWorkspace) {
                        this.handleWorkspaceItemSelected(resp.selectedWorkspace);
                    }
                    else if(resp.shouldCreateNewProject) {
                        this.handleCreateProject();
                    }
                }.bind(this));
            }
            else {
                this.searching = true;
                this.showResults = this.shouldShowResultList();

                //https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
                if (/Android 4\.[0-3]/.test(navigator.appVersion)) {
                    window.addEventListener("resize", function(){
                        if(document.activeElement.tagName=="INPUT"){
                            window.setTimeout(function(){
                                document.activeElement.scrollIntoViewIfNeeded();
                            },0);
                        }
                    });
                }
            }
        },

        handleCreateProject: function handleCreateProject($event) {
            const isUserDisabled = this.FeatureRestrictionService.checkIfBlockedAccess();
            
            if (isUserDisabled) {
                return;
            }
            
            this.AnalyticsService.track(this, [this.analyticsEvent, 'create new'].join(' '), {source: this.analyticsEventSource});
            this.createProject();
        }

    });
    return {
        scope: {
            eventId: '=',
            eventName: '=',
            onChange: '&',
            design: '@?',
            placeholderName: '@?',
            isDisabled: '=?',
            createProject: '&',
            analyticsEvent: '@',
            analyticsEventSource: '@',
            selectedWorkspaceBind: '=?',
            showEmptyState: '=',
            showError: '=',
            errorMessage: '='

        },
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_connected_project/meeting_connected_project.html',
        controller: MeetingConnectedProject,
        controllerAs: 'meetingConnectedProjectVm',
        bindToController: true
    };
};