(function () {
    "use strict";

    const DEFAULT_BUSINESS_OWNERSHIP = 100;

    const MIN_ALLOWED_AGE = 18;

    const MAPPED_FIELD_NAMES = {
        firstName: "first_name",
        lastName: "last_name",
        birthDate: "date_of_birth",
        ssn: "ssn",
        businessOwnership: "ownership_percentage",
        city: "city",
        street: "street_address",
        zip: "zip_code",
        state: "state",
        email: "email",
        phone: "phone_number",
        supportPhone: "support_phone_number",
        identificationFrontImageUrl: "identification_front_image_url",
        identificationBackImageUrl: "identification_back_image_url",
        addressIdentificationFrontImageUrl: "address_identification_front_image_url",
        addressIdentificationBackImageUrl: "address_identification_back_image_url",
        jobTitle: "job_title",
        directorFirstName: "director_first_name",
        directorLastName: "director_last_name",
        directorEmailAddress: "director_email_address"
    };

    Controllers.AddBankAccountOwnerController = class AddBankAccountOwnerController extends Controllers.BankAccountFormController {

        // @ngInject
        constructor($scope, $injector, $modalInstance, UsersManager, PhotosUploadManager, person, fromBankAccountForm, bankAccountType, personType, showAwaitingVerificationOnFinish,
                    CompaniesManager, RegexService, AnalyticsService, $translate, _, Stripe, $q, OnboardingService, PendingTasksManager, DeviceService, MobileAppService, UIUtils) {
            super($scope, $injector, $modalInstance, UsersManager, CompaniesManager, MobileAppService, DeviceService);
            this.__objectType = 'BankAccountOwnerController';
            this.PhotosUploadManager = PhotosUploadManager;
            this.AnalyticsService = AnalyticsService;
            this.CompaniesManager = CompaniesManager;
            this.Stripe = Stripe;
            this.$translate = $translate;
            this._ = _;
            this.$q = $q;
            this.fromBankAccountForm = fromBankAccountForm;
            this.useNewDesign = true;
            this.topMessageBanner = null;
            this.OnboardingService = OnboardingService;
            this.PendingTasksManager = PendingTasksManager;
            this.bankAccountType = bankAccountType;
            this.awaitingVerificationImageUrl = 'https://res.cloudinary.com/honeybook/image/upload/v1652105920/upload-progress_jgte2t.svg';
            this.isInAppBrowser = DeviceService.isInAppBrowser();
            this.UIUtils = UIUtils;

            this.showAwaitingVerificationOnFinish = showAwaitingVerificationOnFinish;
            this.showAwaitingVerificationModal = false;
            this.isEditMode = !!person;
            this.person = person;
            this.requestInProgress = false;
            this.personType = personType;
            this.personTypeOwner = this.personType === 'owner';
            this.personTypeDirector = this.personType === 'director';
            this.checkIfDirectorsExist = this.personType === 'check';

            if (this.checkIfDirectorsExist) {
                this.hasDirector =  true; // default
            }

            if (this.checkIfDirectorsExist) {
                this.infoLabel = 'COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL._DO_YOU_HAVE_DIRECTORS_';
            } else if (this.personTypeOwner) {
                this.infoLabel = 'COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL.' + (fromBankAccountForm ? '_AFTER_BANK_ACCOUNT_FORM_' : '_MUST_BE_RELATED_');
            } else {
                this.infoLabel = 'COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL.' + (fromBankAccountForm ? '_AFTER_BANK_ACCOUNT_FORM_DIRECTOR_' : '_MUST_BE_RELATED_DIRECTOR_');
            }

            const { 
                VALID_SSN,
                VALID_SSI,
                VALID_ZIP,
                VALID_CANADAIAN_POSTAL_CODE,
                VALID_EMAIL,
            } = RegexService.PATTERNS;

            this.localSsnRegex = this.isCanada() ? VALID_SSI : VALID_SSN;
            this.ssnRegex = this.isEditMode ? /.*/ : this.localSsnRegex;
            this.ssnMinLength = this.isEditMode ? 4 : 9;

            this.zipRegex = this.isCanada() ? VALID_CANADAIAN_POSTAL_CODE : VALID_ZIP;
            this.emailRegex = VALID_EMAIL;

            this.currentUser = UsersManager.getCurrUser();
            this.company = this.currentUser.company;
            this.stripePersons = this.company.stripe_persons;
            this.bankAccount = this.currentUser.myBankAccount();
            this.showOwnershipField = !this.bankAccount || !this.bankAccount.type || this.bankAccount.type !== "Individual";
            this.showJobTitleField = this.isCanada() || this.bankAccountType === 'corp';

            // For now don't prefill for director role
            this.shouldPrefill = this.company.stripe_persons &&
                                 this.company.stripe_persons.length === 0 && this.personTypeOwner;
            this.lastError = null;
            this._init(person, this.bankAccount);
        }

        prefillOwner(owner, bankAccount) {
            if (!owner && this.shouldPrefill) {
                this.firstName = this.currentUser.first_name;
                this.lastName = this.currentUser.last_name;
                this.email = this.currentUser.email;
                this.phone = bankAccount.phone_number || this.currentUser.phone_number;
                this.businessOwnership = DEFAULT_BUSINESS_OWNERSHIP;
                this.street = bankAccount.street_address;
                this.city = bankAccount.city;
                this.zip = bankAccount.zip_code;
                this.state = this.states.find(
                    state => state.abbreviation === bankAccount.state
                );

                // in case these fields were pre-filled we need to make sure to
                // add them to the dirty fields list
                this.preFilledFieldNames = [
                    "firstName",
                    "lastName",
                    "email", 
                    "phone", 
                    "businessOwnership", 
                    "street",
                    "city",
                    "state",
                    "zip"
                ];
                return;
            } else if (!owner) {
                return;
            }

            // For all person types
            this.firstName = owner.first_name;
            this.lastName = owner.last_name;
            this.email = owner.email;

            // Only for owners (not directors)
            if (this.personTypeOwner) {
                this.birthDate = owner.date_of_birth;
                this.ssn = `*****${owner.ssn}`;

                this.businessOwnership = owner.ownership_percentage || DEFAULT_BUSINESS_OWNERSHIP;
                this.street = owner.street_address;
                this.city = owner.city;
                this.zip = owner.zip_code;
                this.state = this.states.find(
                    state => state.abbreviation === owner.state
                );

                this.phone = owner.phone_number;
                this.hasIdentificationFrontImage = owner.has_identification_front_image;
                this.hasIdentificationBackImage = owner.has_identification_back_image;
                this.hasAddressIdentificationFrontImage = owner.has_address_identification_front_image;
                this.hasAddressIdentificationBackImage = owner.has_address_identification_back_image;
                this.jobTitle = owner.job_title;
            }
        }

        getDirtyFieldNames() {
            const nonInputOrPrefilledDirtyFields = Object.keys(this.nonInputDirtyFieldNames).concat(
                this.preFilledFieldNames || []
            );

            return this.getFormDirtyFieldNames(
                this.addOwnerForm,
                nonInputOrPrefilledDirtyFields
            );
        }

        showCanadaText() {
            return this.isCanada();
        }

        setNonInputDirtyField(name) {
            this.nonInputDirtyFieldNames[name] = true;
        }

        getFieldValue(name) {
            switch(name) {
                case 'state':
                    return this.state.abbreviation;
                case 'identificationBackImageUrl':
                    return this.identificationBackImageUrl;
                case 'identificationFrontImageUrl':
                    return this.identificationFrontImageUrl;
                case 'addressIdentificationBackImageUrl':
                    return this.addressIdentificationBackImageUrl;
                case 'addressIdentificationFrontImageUrl':
                    return this.addressIdentificationFrontImageUrl;
                default:
                    return this[name];
            }
        }

        isSubmitDisabled(form) {
            return this.requestInProgress;
        }

        submit() {
            this.lastError = null;

            if (this.checkIfDirectorsExist) {
                if (!this.hasDirector) {
                    this.requestInProgress = true;
                    const data = {};
                    data.has_directors = false;
                    this.checkIfDirectorsExist = false;
                    return this.submitData(data);
                } else {
                    this.personType = 'director';
                }
            }

            this.addOwnerForm.$setSubmitted();
            this.AnalyticsService.trackClick(
                this,
                `save bank account owner ${this.personType}`,
                { action: this.isEditMode ? 'update' : 'save'}
              );

            if (!this.isFormValid()) {
                if (!this.state) {
                    this.fieldsToFix.state = true;
                }

                if (this.showJobTitleField && !this.jobTitle) {
                    this.fieldsToFix.jobTitle = true;
                }
                this.AnalyticsService.track(
                    this,
                    "bank details owner modal - submitted with errors"
                );
                return;
            }

            this.requestInProgress = true;

            const changedFieldNames = this.getDirtyFieldNames();
            const data = changedFieldNames.reduce((acc, fieldName) => {
                acc[MAPPED_FIELD_NAMES[fieldName]] = this.getFieldValue(fieldName);
                return acc;
            }, {
                token_id: ''
            });

            data.country = this.company.country;
            data.person_type = this.personType;

            // If SSN is dirty, get token from Stripe
            if (data.ssn) {
                this.getPersonToken().then((response) => {
                    if (response) {
                        if (response) {
                            data.token_id = response.token.id;
                            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.stripe_person_token_created);
                        }
                        this.submitData(data);
                    }
                })
                    .catch((response) => {
                        if (response && response.error && response.error.message) {
                            this.lastError = response.error.message;
                        }
                        else if (response && response.data && response.data.error_message) {
                            this.lastError = response.data.error_message;
                        }
                        else {
                            this.lastError = "An error has occurred. If the problem persists, please contact us";
                        }
                        this.lastError = this.lastError.replace('support@stripe.com', 'concierge@honeybook.com');
                        this.requestInProgress = false;

                        // Show internal error on Analytics if exists
                        var error_message = this.lastError;
                        if (response && response.message) {
                            error_message += response.message;
                        }
                        // Scroll to top
                        angular.element("#bank_account_owner_container").animate({scrollTop: 0}, 200);
                    });
            } else {
                this.submitData(data);
            }

        }

        submitData(data) {
            var self = this;
            if (data.ssn && data.ssn.length > 4) {
                data.ssn = data.ssn.slice(-4);
            }

            if (self.isEditMode) {
                data.payment_person_id = self.person._id;
            }

            var promise = self.PendingTasksManager.createPendingTask(self.PendingTasksManager.pendingTasksTypes.upsertBankAccountOwner, data);

            promise.then(function(res) {
                if (!self.isEditMode) {
                    self.AnalyticsService.track(self, "create bank account owner success");
                } else {
                    self.AnalyticsService.track(self, "update bank account owner success");
                }

                self.OnboardingService.onBankAccountAdded();
                if (self.fromBankAccountForm && self.showAwaitingVerificationOnFinish) {
                    self.showAwaitingVerificationModal = true;
                } else {
                    self.close();
                }

                // Hard refresh for E2E
                self.CompaniesManager.getCurrCompany(true);
            }).catch((err) => {
                if (err && typeof err === 'string') {
                    self.lastError = err;
                }
                else {
                    self.lastError = self.$translate.instant('ERRORS.SERVER_API._UNEXPECTED_');
                }
                // scroll container to top so error message is visible
                angular.element("#bank_account_owner_container")
                .animate({scrollTop: 0}, 200);
            })
            .finally(() => self.requestInProgress = false);
        }

        getPersonToken() {
            var deferred = this.$q.defer();
            this.Stripe.then(stripeSdk => {
                var stripe = stripeSdk(this.currentUser.stripe_key);
                stripe.createToken('pii', {
                    personal_id_number: this.ssn
                }).then(function(response) {
                    if (response.error) {
                        deferred.reject(response);
                        return;
                    }
                    deferred.resolve(response);
                }).catch(function (res) {
                    console.log(res);
                });
            });
            return deferred.promise;
        }

        onIdentificationFileSelected(file, side, type) {
            let imageFieldName;
            if (type === 'address') {
                imageFieldName = side.toLowerCase() === 'front' ?
                    'addressIdentificationFrontImageUrl' :
                    'addressIdentificationBackImageUrl';
            } else {
                imageFieldName = side.toLowerCase() === 'front' ?
                    'identificationFrontImageUrl' :
                    'identificationBackImageUrl';
            }

            this.setNonInputDirtyField(imageFieldName);
            this.AnalyticsService.trackClick(
                this, 
                "upload id document", 
                {side: side, type: type}
            );

        }

        onIdentificationUploaded(url, side, type) {
            if (type && type === 'address') {
                if (side.toLowerCase() === 'front') {
                    delete this.fieldsToFix["proof_of_liveness_front"];
                } else {
                    delete this.fieldsToFix["proof_of_liveness_back"];
                }
            } else {
                if (side.toLowerCase() === 'front') {
                    delete this.fieldsToFix["photo_id_front"];
                } else {
                    delete this.fieldsToFix["photo_id_back"];
                }
            }

            if (type === 'address') {
                if (side.toLowerCase() === 'front') {
                    this.addressIdentificationFrontImageUrl = url;
                    this.hasAddressIdentificationFrontImage = true;
                } else {
                    this.addressIdentificationBackImageUrl = url;
                    this.hasAddressIdentificationBackImage = true;
                }
            } else {
                if (side.toLowerCase() === 'front') {
                    this.identificationFrontImageUrl = url;
                    this.hasIdentificationFrontImage = true;
                } else {
                    this.identificationBackImageUrl = url;
                    this.hasIdentificationBackImage = true;
                }
            }
        }

        onDateKeyDown($event) {
            if ($event.keyCode !== 8 && $event.keyCode !== 46) {
                $event.preventDefault();
            }
        }

        onStateChange(stateOption) {
            this.setNonInputDirtyField("state");
            delete this.fieldsToFix["state"];
            this.state = stateOption;
        }

        isFormValid() {
            return this.addOwnerForm.$valid &&
                this._.isEmpty(this.fieldsToFix) &&
                // check the following only for owners, not directors
                (!this.personTypeOwner ||
                (this.state &&
                this.hasIdentificationFrontImage &&
                (!this.isCanada() || (this.hasIdentificationBackImage && this.hasAddressIdentificationFrontImage && this.hasAddressIdentificationBackImage))));
        }

        isValidDOB(shouldShowMissingError) {
            if (!this.birthDate) {
                return !shouldShowMissingError;
            }

            const today = new Date();
            const minValidDate = new Date(today.setYear(today.getFullYear() - MIN_ALLOWED_AGE));
            const birthDate = new Date(this.birthDate);
            
            return birthDate < minValidDate;
        }

        getDOBError() {
            return this.birthDate ? 'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT._DOB_ERROR_' : 'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.MISSING._DOB_ERROR_';
          }
          
        getErrorMessage(fieldName, translationString) {
            if (this.fieldsToFix[fieldName]) {
                if (fieldName === 'ssn') {
                    return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._SSN_ERROR_MESSAGE_';
                } else {
                    return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._MISSING_INFO_ERROR_MESSAGE_';
                }
            }

            return this.patternFieldErrorMessage(
                fieldName,
                'COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL.' + translationString,
                this.addOwnerForm
            );
        }

        getNewFormErrorMessage(fieldName, translationString) {
            if (this.fieldsToFix[fieldName]) {
              return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._MISSING_INFO_ERROR_MESSAGE_';
            }
            const missingErrorKey = 'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.MISSING.' + translationString;
            if (fieldName === 'state') {
              return missingErrorKey;
            }
            return this.patternFieldErrorMessage(
              fieldName,
              'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.' + translationString,
              this.addOwnerForm,
              missingErrorKey
            );
          }

        onSSNTooltipHover() {
            this.AnalyticsService.track(this, "bank details opened ssn tooltip");
        }

        onSsnBlur() {
            if (this.addOwnerForm.ssn.$dirty) {
                this.ssnRegex = this.localSsnRegex;
                this.ssnMinLength = 9;
            }
        }

        onBusinessOwnershipBlur() {
            this.addOwnerForm.businessOwnership.$setValidity('pattern', true);
            if (this.stripePersons) {
                const filteredPersons = this.stripePersons.filter(p => p.email !== this.email);

                let totalOwnershipPercentage = filteredPersons.reduce(
                    (acc, person) => acc + person.ownership_percentage,
                this.businessOwnership);


                if (totalOwnershipPercentage > 100) {
                    this.addOwnerForm.businessOwnership.$setValidity('pattern', false);
                }

                let newValue = this.addOwnerForm.businessOwnership && this.addOwnerForm.businessOwnership.$viewValue;
                if (newValue && (newValue < 0 || newValue > 100)) {
                    this.addOwnerForm.businessOwnership.$setValidity('pattern', false);
                }
            }
        }

        getBusinessOwnershipErrorMessage() {
            const $error = this.addOwnerForm.businessOwnership.$error;

            if (!$error) {
                return null;
            }

            if ($error.pattern) {
                return "COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL._BUSINESS_OWNERSHIP_ERROR_";
            }

            if ($error.min) {
                return "COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL._BUSINESS_OWNERSHIP_MIN_ERROR_";
            }

            if ($error.max) {
                return "COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL._BUSINESS_OWNERSHIP_MAX_ERROR_";
            }

            return 'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.MISSING._OWNERSHIP_PERCENTAGE_ERROR_';
        }

        shouldShowError(fieldName, customValidation = false) {
            const field = this.addOwnerForm[fieldName];
            const isFieldToFix = this.fieldsToFix[fieldName];
            const showError = this.addOwnerForm.$submitted || (field && field.$touched) || isFieldToFix;

            if (customValidation) {
                return showError;
            }

            return showError && (field && (field.$invalid || isFieldToFix));
        }

        onFieldFocus(fieldName) {
            if (this.fieldsToFix[fieldName]) {
                delete this.fieldsToFix[fieldName];
            }
        }

        shouldShowAllFieldsReqError() {
            return this.addOwnerForm.$submitted &&
                    (!this.addOwnerForm.$valid || 
                     !this._.isEmpty(this.fieldsToFix)
                    );
        }

        _init(owner = null, bankAccount = {}) {
            this.prefillOwner(owner, bankAccount);
            this.initFieldsToFix(owner);

            if (this.fieldsToFix && this.fieldsToFix["ssn"]) {
                this.topMessageBanner = 'COMPANY_SETTING.BANK_ACCOUNT.ACCOUNT_OWNER_MODAL._INVALID_SSN_BANNER_';
            }
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }

        showErrorForDocument(side, type) {
            let submitted = this.addOwnerForm.$submitted;
            let result;
            switch(type) {
                case 'identity':
                    result = side.toLowerCase() === 'front' ?
                        (submitted && !this.hasIdentificationFrontImage) || !!this.fieldsToFix['photo_id_front'] :
                        (submitted && this.isCanada() && !this.hasIdentificationBackImage) || !!this.fieldsToFix['photo_id_back'];
                    break;
                case 'address':
                    result = side.toLowerCase() === 'front' ?
                        (submitted && !this.hasAddressIdentificationFrontImage) || !!this.fieldsToFix['proof_of_liveness_front'] :
                        (submitted && !this.hasAddressIdentificationBackImage) || !!this.fieldsToFix['proof_of_liveness_back'];
                    break;
                default:
                    result = false;
            }
            return result;
        }
    };

}());