(function () {
    "use strict";

    // @ngInject
    function ReferralServiceCtor(UsersManager, $window, $timeout, $q, $state, AppStates, $injector,
                                 MemberReferralsManager, ModelFactory, ModalService, AbTestService, Enums, WebsocketHelperService) {
        this.UsersManager = UsersManager;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$q = $q;
        this.MemberReferralsManager = MemberReferralsManager;
        this.ModelFactory = ModelFactory;
        this.UsersManager.on('loggingOut', this.onLoggingOut, this);
        this.UsersManager.on('loggingIn', this.initReferralService, this);
        this.UsersManager.on('resetPsw', this.initReferralService, this);
        this.ModalService = ModalService;
        this.AppStates = AppStates;
        this.$state = $state;
        this.$injector = $injector;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.WebsocketHelperService = WebsocketHelperService;
        this.currUser = this.UsersManager.getCurrUser();

        if(UsersManager.isLoggedIn() && this.currUser.isVendor()) {
            this.WebsocketHelperService.registerToRoom(this.currUser._id + ".referrals", this.referralsReceivedUpdate.bind(this));
        }

        this.initReferralService();
    }

    Services.ReferralService = Class(function () {

        return {

            constructor: ReferralServiceCtor,

            initReferralService: function initReferralService() {
                this.currUser = this.UsersManager.getCurrUser();
                this.campaignDetails = this.ModelFactory.newModel('ReferralCampaignModel');
                this.WebsocketHelperService.registerToRoom(this.currUser._id + ".referrals", this.referralsReceivedUpdate.bind(this));
            },

            onLoggingOut: function onLoggingOut() {
                this.clearAll();
            },

            clearAll: function clearAll() {
                if (this.currUser) {
                    this.currUser.offAll(this);
                    this.currUser = null;
                }
                delete this.campaignDetails;
                delete this.userRefrralStats;
                delete this.referrals;
            },

            getCampaignDetails: function getCampaignDetails(user, campaign_uid) {
                var defer = this.$q.defer();

                if (!this.currUser || (user._id !== this.currUser._id)) {
                    this.initReferralService();
                }

                if(user.isClient()) {
                    defer.reject();
                } else if (!this.campaignDetails || this.campaignDetails.isEmpty()) {
                    this.MemberReferralsManager.getCampaignDetails(user, this.campaignDetails, campaign_uid).then(function success(resp) {
                        if(this.campaignDetails && !this.campaignDetails.isEmpty()) {
                            defer.resolve(this.campaignDetails);
                        } else {
                            defer.reject();
                        }
                    }.bind(this), function error() {
                        defer.reject();
                    }.bind(this));
                } else {
                    defer.resolve(this.campaignDetails);
                }

                return defer.promise;
            },

            getCampaignDetailsFromReact: function getCampaignDetailsFromReact(campaign_uid) {
                
                var UsersManager = this.$injector.get('UsersManager');
                var promise = new Promise(function (resolve, reject) {
                    var user = UsersManager.getCurrUser();

                    if (!user) {
                        user.on('success', function () {
                            this.initReferralService();
                            this.getCampaignDetails(user, campaign_uid).then(function (value) {  
                                resolve(value);
                             }, function () {
                                reject();
                             });
                        }, this);
                    } else {
                        this.initReferralService();
                        this.getCampaignDetails(user, campaign_uid).then(function (value) {  
                            resolve(value);
                         }, function () {
                            reject();
                         });
                    }
                }.bind(this));

                return promise;

            },

            referUsersByEmail: function referUsersByEmail(emails, referrals, message) {
                return this.MemberReferralsManager.referUsersByEmail(this.currUser, emails, referrals, message);
            },

            openReferAFriend: function openReferAFriend() {
                this.ModalService.openReferralModal();
            },

            getUserReferralStats: function getUserReferralStats() {
                var defer = this.$q.defer();

                if(!this.userRefrralStats) {
                    this.MemberReferralsManager.getUserReferralStats(this.currUser)
                        .then(function success(resp) {
                            this.userRefrralStats = resp.data;
                            defer.resolve(this.userRefrralStats);
                        }.bind(this))
                        .catch(function failure(err) {
                            defer.reject(err);
                        });
                } else {
                    defer.resolve(this.userRefrralStats);
                }

                return defer.promise;
            },

            referralsReceivedUpdate: function referralsReceivedUpdate(json) {
                var data;
                try {
                    data = JSON.parse(json).data;
                    var user = this.UsersManager.getCurrUser();
                    if(data.campaign_uid) {
                        this.MemberReferralsManager.getCampaignDetails(user, this.campaignDetails, data.campaign_uid);
                    }
                } catch(e) {
                    console.error('referralsReceivedUpdate json error:' + e);
                    console.error('referralsReceivedUpdate json: ' + json);
                }
            }
        };
    });
}());
