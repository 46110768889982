
(function () {
    'use strict';
    class PasskeysController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, RepositoryService, AnalyticsService, Constants, StatsigService, Enums, DatadogRUMService, $timeout) {
            super($scope, $injector);
            this.__objectType = 'PasskeysController';
            this.$timeout = $timeout;
            this.UsersManager = UsersManager;
            this.AnalyticsService = AnalyticsService;
            this.RepositoryService = RepositoryService;
            this.Constants = Constants;
            this.DatadogRUMService = DatadogRUMService;

            StatsigService.isGateEnabled(Enums.StatsigFeatureGates.passkeysLoginEnabled).then(isEnabled => {
                if (isEnabled || /passkeys/.test(window.location.href)) {
                    this.startOwnID();
                }
            });
        }

        startOwnID() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.passkeys_starting_own_id);
            ownid('activateReturningUsersPrompt', { onlyMobile: true });
            ownid("login", {
                loginIdField: document.getElementById("login-form-email-input"),
                passwordField: document.getElementById("login-form-password-input"),
                onError: (e) => { this.DatadogRUMService.addError(new Error("OwnID internal error"), {cause: e}); },
                onLogin: ({res}) => {
                    this.UsersManager.login_with_passkeys(this.loginParams.user, res.email, res.token).then(
                        (resp) => {
                            this.loginParams.loginSuccess(resp, this.loginParams.user.bypass_2fa);
                            this.RepositoryService.storagePut(this.Constants.storage.OWN_ID_SESSION_TOKEN, res.token);
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.login_with_passkeys);
                        },

                        (resp) => {
                            this.loginParams.loginError(resp);
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login_with_passkeys, resp);
                            this.DatadogRUMService.addError(new Error("OwnID login error"), {cause: resp});
                        }
                    );
                },
                passwordToggle: "#formLogin > div.nx-fieldset.login__fieldset.login__fieldset--password > label"
            });
        }
    }

    Components.Passkeys = {
        bindings: {
            loginParams: '='
        },
        controller: PasskeysController,
        name: 'passkeysVm',
        templateUrl: 'angular/app/modules/common/ui_components/passkeys_login/passkeys_component_template.html',
    };

}());
