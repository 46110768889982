(function () {
    'use strict';

    class InteractiveAccountController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, ModalService, CompaniesManager, $translate, Enums) {
            super($scope, $injector);
            this.__objectType = 'InteractiveAccountController';

            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.ModalService = ModalService;
            this.$translate = $translate;
            this.Enums = Enums;
        }

        $onInit() {
            this.user = this.UsersManager.getCurrUser();
            this.onValidation({isValid: true});
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this.company = this.CompaniesManager.getCurrCompany();

            this.titleKey = this.$translate.instant('INTERACTIVE_ONBOARDING.STEP_ACCOUNT._TITLE_FIRST_');
        }

        uploadProfileImage() {
            this.ModalService.openUploadProfilePhotoModal(this.user);
        }
    }

    Components.InteractiveAccount = {
        controller: InteractiveAccountController,
        bindings: {
            onValidation: '&',
            onComplete: '&'
        },
        name: 'hbInteractiveAccount',
        require: {
            hbInteractiveOnboarding: '^hbInteractiveOnboarding'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/account/account.html',

    };
}());
