(function () {
    "use strict";

    Controllers.YearInReview2022Controller = class YearInReview2022Controller extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AppConfigService, AppStates) {

            super($scope, $injector);
            this.__objectType = 'YearInReview2022Controller';
            this.AppConfigService = AppConfigService;
            this.AppStates = AppStates;

            // if (this.AppConfigService.isProduction()) {
            //     this.goToState(AppStates.root_core_navigation_home);
            // }
        }
    };
}());
