(function () {
    'use strict';

    class SignupProgressBarController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, _) {
            super($scope, $injector);
            this.__objectType = 'SignupProgressBarController';
            this._ = _;
        }

        isCurrentOrComplete(stageKey) {
            //const stages = this._.keys(this.form.stages);

            // The <li> instance in ng-repeat
            //const listIndex = this._.indexOf(stages, stageKey);
            // The state of the form
            //const currIndex = this._.indexOf(stages, this.form.currentStageKey);

            //return listIndex <= currIndex;
        }

        setFill(index) {
            let scaleValue = 0;
            if (index <= this.form.currentStageIndex) {
                scaleValue = 1;
            //} else if (index === this.form.currentStageIndex) {
            //    scaleValue = 0.1;
            }
            return {'transform': 'scaleX(' + scaleValue + ')'}
        }

    }

    Components.SignupProgressBar = {
        bindings: {
            form: '<',
        },
        controller: SignupProgressBarController,
        name: 'hbSignupProgressBar',
        templateUrl: 'angular/app/modules/core/features/signup/signup_progress_bar/signup_progress_bar_component.html',
    };

}());