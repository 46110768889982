// @ngInject

Models.AgreementModel = function AgreementModel(BaseModel, $translate, Enums, UsersManager) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'AgreementModel';

            this.cachable = false;
            this.hasEmptyClientFields = false;
            this.isBeingDeleted = false;
        },

        mapChilds: function mapChilds() {
            return {contract_signatures: 'SignaturesCollection'};
        },

        afterMixingFrom: function afterMixingFrom() {
        },

        hasClientFields: function hasClientFields() {
            return this.html_body.indexOf("field_hb_data_type:client_field") !== -1;
        },

        isAgreementSignedBySomeone: function isAgreementSignedBySomeone() {
            var contractSignatures = this.contract_signatures;
            if (!contractSignatures) {
                return false;
            }
            return contractSignatures.some(function (signature) {
                return !!signature.signed_on;
            });
        },

        isAgreementSignedByVendor: function isAgreementSignedByVendor() {
            return this.isAgreementSignedBy(Enums.ContractSigneeTypes.planner_signature);
        },

        isAgreementSignedByClient: function isAgreementSignedByClient() {
            return this.isAgreementSignedBy(Enums.ContractSigneeTypes.couple_signature);
        },

        isAgreementSignedByCurrUser: function isAgreementSignedByCurrUser() {
            return this.isAgreementSignedByUser(UsersManager.getCurrUser());
        },

        isAgreementSignedByUser: function isAgreementSignedByUser(user) {
            var contractSignatures = this.contract_signatures;
            if (!contractSignatures) {
                return false;
            }
            return !!contractSignatures.find(function (signature) {
                return signature.signee_id === user._id && signature.signed_on;
            });
        },

        isAgreementSignedBy: function isAgreementSignedBy(signee_type_cd) {
            var contractSignatures = this.contract_signatures;
            if (!contractSignatures) {
                return false;
            }
            var isSignedBy = contractSignatures.filter(function (signature) {
                if (signature.signee_type_cd === signee_type_cd && signature.signed_on) {
                    return signature;
                }
            }.bind(this));
            return isSignedBy.length > 0;
        },

        setHasEmptyClientFields: function setHasEmptyClientFields(hasEmpty) {
            this.hasEmptyClientFields = hasEmpty;
        },

        updateAgreement: function updateAgreement(callingScope) {
            return this.__parent.updateAgreement(this, callingScope);
        },

        updateAgreementWithFile: function updateAgreementWithFile(file) {
            return this.__parent.updateAgreementWithFile(this, file);
        },

        updateSignature: function updateSignature(signatureModel) {
            return this.__parent.updateSignature(signatureModel);
        },

        changeSignatureSignatory: function changeSignatureSignatory(signatureModel, user_id) {
            return this.__parent.changeSignatureSignatory(signatureModel, user_id);
        },

        updateSignatureDescription: function updateSignatureDescription(signatureModel) {
            return this.__parent.updateSignatureDescription(signatureModel);
        },

        removeSignature: function removeSignature(signatureModel) {
            return this.__parent.removeSignature(signatureModel);
        },

        updateAgreementByTemplate: function updateAgreementByTemplate(templateId) {
            return this.__parent.updateAgreementByTemplate(templateId);
        },

        saveAsTemplate: function saveAsTemplate(company, templateTitle) {
            return this.__parent.saveAgreementAsTemplate(templateTitle, company);
        },

        updateCoverImage: function updateCoverImage(image) {
            return this.__parent.updateAgreementCoverImage(this, image);
        }
    });
};

