(function () {
    'use strict';

    // fullstory wrapper
    // @ngInject
    Services.FS = function gon($window) {
        if ($window.FS) {
            return $window.FS;
        }
        return false;
    };

}());

