(function () {
    "use strict";



    Controllers.InteractiveOnboardingExplainBrandingModalController = class InteractiveOnboardingExplainBrandingModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $modalInstance,
            $translate,
            CompaniesManager,
            CompanyService,
            fileData
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingExplainBrandingModalController';

            this.$modalInstance = $modalInstance;
            this.CompaniesManager = CompaniesManager;
            this.CompanyService = CompanyService;
            this.company = this.CompaniesManager.getCurrCompany();

            this.exampleFileType = 'invoice'
            this.exampleFileName = $translate.instant('INTERACTIVE_ONBOARDING.STEP_BRANDING.EXPLAIN_MODAL._EXAMPLE_FILE_NAME_');

            // brand color
            this.exampleFileBrandColor = fileData.brandColor || this.company.brand_color;

            // cover image
            this.defaultCoverImage = fileData.defaultCoverImage;
            this.exampleFileCoverImagePublicId = fileData.defaultCoverImage && fileData.defaultCoverImage.cloudinary_public_id;

            // logo image
            this.exampleFileLogoImagePublicId = fileData.logoImage && fileData.logoImage.cloudinary_public_id;

        }

        handleClose() {
            this.$modalInstance.close();
        }


    }
}());
