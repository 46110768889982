(function () {
    "use strict";

    // @ngInject
    function CompanySettingsGeneralControllerCtor($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsGeneralController';
    }

    Controllers.CompanySettingsGeneralController = Class(Controllers.BaseController, {
        constructor: CompanySettingsGeneralControllerCtor,
    });

}());
