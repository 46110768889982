
(function () {
  'use strict';

  class DiscountEditorComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, $element, $document, AnalyticsService, UIUtils, Enums ) {
      super($scope, $injector);
      this.__objectType = 'DiscountEditorComponent';

      this.AnalyticsService = AnalyticsService;
      this.UIUtils = UIUtils;
      this.Enums = Enums;

      this.memoDiscount = this.model.discount;
      this.invalidAmount = false;
    }

    $onInit() {
      this._initialDiscountCopy = this.model.discount;
      this._initialTypeCopy = this.model.discount_type;
    }

    _isInvalidDiscount(type){
      const discountType = type || this.model.discount_type;

      if(discountType === this.Enums.discountTypes.relative) {
        return this.invalidAmount = parseInt(this.memoDiscount) > 100;
      }

      if (discountType === this.Enums.discountTypes.absolute) {
        return this.invalidAmount = parseInt(this.memoDiscount) > this.model.sub_total;
      }
    }

    onDiscountChange(){
      if(this._isInvalidDiscount()){
        return;
      }

      this.invalidAmount = false;
      this.handleDiscountAmount({amount: this.memoDiscount});
    }

    onTypeChange(type){
      if(this._isInvalidDiscount(type)){
        return;
      }
      this.handleDiscountType({type})
    }

    onRemoveDiscount(){
      this.handleDiscountAmount({amount: null});
    }

    onCancel(){
      /* if cancel and values did not change */
      if (this._initialDiscountCopy === this.model.discount &&
          this._initialTypeCopy === this.model.discount_type) {
          return this.handleCancel();
      }

      /* cancel and reverse changes */
      this.handleCancel({discount: {
          type: this._initialTypeCopy,
          amount: this._initialDiscountCopy
        }});
    }
  }

  Components.DiscountEditor = {
    bindings: {
      model: '=',
      handleDiscountType: '&',
      handleDiscountAmount: '&',
      handleCancel: '&',
    },
    controller: DiscountEditorComponent,
    name : 'hbDiscountEditor',
    templateUrl: 'angular/app/modules/core/features/workspace_file/discount_editor/discount_editor.html',
  };

}());

