
// @ngInject
Directives.IframeOnloadDirective = function IframeOnloadDirective($parse) {
    return {
        restrict: 'A',
        link: function iframeOnloadLink($scope, $element, attr) {
            var func = $parse(attr.hbIframeOnload);
            $element.bind('load', function iframeLoaded() {
                $scope.$apply(function () {
                    func($scope);
                });
            });
        }
    };
};

