Components.HbPrideBLM = {
    // @ngInject
    controller: function PrideBLMController() {

    },
    name: 'prideBlmLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/pride-blm/pride-blm.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
