(function () {
    'use strict';

    // @ngInject
    Directives.BankAccountDependentField = function BankAccountDependentField($timeout) {

        function BankAccountDependentFieldLink(scope, element, attrs, ngModel) {
            // directive is only relevent in edit mode
            const isEditMode = attrs.hbBankAccountDependentField === 'true';
            const initialValue = attrs.dependentFieldInitialValue;

            if (!isEditMode) {
                return;
            }

            const onBlur = () => {
                // if field value changed, initialize all other dependent fields
                if (ngModel.$modelValue !== initialValue) {
                    scope.$emit("INIT_DEPENDENT_FIELD_VALUES", attrs.name);
                    
                    // stop listening to changes (on all fields) after first init has fired
                    $timeout(() => scope.$emit(
                        "UNREGSITER_DEPENDENT_FIELDS_DIRECTIVE"
                    ), 0);
                }              
            }

            element[0].addEventListener('blur', onBlur);

            scope.$on("INIT_DEPENDENT_FIELD_VALUES", (event, changedFieldName) => {
                // only init field if this is not the changed field
                if (attrs.name !== changedFieldName) {
                    ngModel.$setViewValue('');
                    ngModel.$render();
                }
            });

            scope.$on("UNREGSITER_DEPENDENT_FIELDS_DIRECTIVE", () => {
                element[0].removeEventListener('blur', onBlur);
            })
        }

        return {
            require: 'ngModel',
            restrict: 'A',
            link: BankAccountDependentFieldLink
        };

    };
}());

