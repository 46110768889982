(function () {
    "use strict";

    // @ngInject
    function NewVendorReferralControllerCtor($scope, $modalInstance, $injector, $q, $translate, ContactsManager, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, PopupMessageService, config, workspace, ValidationsManager, CompaniesManager) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $translate, UsersManager, UserService, _, CompanyService, ModelFactory, GoogleAPIService, AnalyticsService, SearchablesManager, ValidationsManager, config, workspace);
        this.__objectType = 'NewVendorReferralController';
        this.analyticsName = 'newAddUserToReferralDialog';

        this.workspace = workspace;
        this.ContactsManager = ContactsManager;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.$translate = $translate;
        this.$q = $q;
        this.isVendor = true;
        this.isClient = false;
        this.isVendorEditDisabled = true;
        this.user.system_user_type = 'vendor';
        this.hideIsBusiness = true;
        this.shouldShowUserType = false;
        this.businessDataOpen = true;
        this.company = CompaniesManager.getCurrCompany();
        this.currentUser = UsersManager.getCurrUser();

        this.isSuggestFromVendorContacts = true;
        this.isSuggestFromGmailContacts = true;

        this.titleText = this.currentUser.isConsultantCopyChangedEnabled() ? $translate.instant("VENDOR_REFERRAL_DIALOG._HEADER_TITLE_CONSULTANT_") :
            $translate.instant("VENDOR_REFERRAL_DIALOG._HEADER_TITLE_");
        this.subTitleText = this.currentUser.isConsultantCopyChangedEnabled() ? $translate.instant("VENDOR_REFERRAL_DIALOG._SUB_TITLE_CONSULTANT_"):
            $translate.instant("VENDOR_REFERRAL_DIALOG._SUB_TITLE_");

        this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._REFER_");
    }

    Controllers.NewVendorReferralController = Class(Controllers.NewAddUserController, {

        constructor: NewVendorReferralControllerCtor,

        saveUser: function saveUser(){
            var defer = this.$q.defer();
            var vendor = this.user;

            this.ContactsManager.saveContactUser(vendor, 'vendor_referral').then(
                function saveContactSuccess(resp) {
                    vendor = vendor.mixinFrom(resp.data.user);
                    this.userList = [vendor]; // resetting user so it would contain the user_id
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.vendor_added_for_referral,
                        {   new_vendor_email: vendor.email,
                            new_vendor_id: vendor._id,
                            new_vendor_full_name: vendor.full_name});
                    defer.resolve(this.userList);
                }.bind(this),
                function saveContactError(resp) {
                    defer.reject(resp);
                }.bind(this)
            );
            return defer.promise;
        },

        handleUserFromDb: function handleUserFromDb(dbUser){
            if (dbUser._id){
                this.submitError = "";
                if (!dbUser.isVendor()) {
                    this.user.email = null;
                    this.submitError = this.$translate.instant('VENDOR_REFERRAL_DIALOG._CANNOT_ADD_CLIENT_');
                    return;
                }
                this.user = dbUser;
            }
            else{ //user doesn't exist
                this.user = angular.extend(this.getStrippedBaseDataFromDBUser(dbUser), this.user);
            }
        },

    });
}());

