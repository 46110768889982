(function () {
    "use strict";

    // @ngInject
    Directives.LargeSignatureDirective = function LargeSignatureDirective() {

        // @ngInject
        function LargeSignatureDirectiveCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'LargeSignatureDirectiveController';
        }

        var linkFunction = function linkFunction($scope, iElement, iAttrs) {

            // save elements refs
            var signatureElement = iElement.find('.sigPad');
            var canvasElement = signatureElement.find('canvas');
            var outputElement = iElement.find('.output');

            // signaturePad api
            var defaultOpts = {
                drawOnly: true,
                lineWidth: null,
                validateFields : false
            };
            var signatureAPI = signatureElement.signaturePad(defaultOpts);

            // store api on scope for controller
            $scope.largeSignatureVm.signatureAPI = signatureAPI;

            // draw signature if exists
            var signature = $scope.largeSignatureVm.signature;
            if(signature && signature.length) {
                signatureAPI.regenerate(signature);
            }

            // register watches
            $scope.$watch(signatureValue, bindValueChangeHandler(setSignatureValue));
            $scope.$watch(signatureElementWidth, bindValueChangeHandler(setSignatureElementWidth));
            $scope.$watch(signatureElementHeight, bindValueChangeHandler(setSignatureElementHeight));

            function bindValueChangeHandler(setter) {
                return function(newVal) {
                    if(newVal) {
                        setter(newVal);
                    }
                };
            }

            function signatureValue() {
                return outputElement.val();
            }

            function setSignatureValue(val) {
                $scope.largeSignatureVm.signature = val;
            }

            function signatureElementWidth() {
                return canvasElement.attr('width');
            }

            function setSignatureElementWidth(val) {
                $scope.largeSignatureVm.canvasSize.width = val;
            }

            function signatureElementHeight() {
                return canvasElement.attr('height');
            }

            function setSignatureElementHeight(val) {
                $scope.largeSignatureVm.canvasSize.height = val;
            }
        };

        var LargeSignatureDirectiveController = Class(Controllers.BaseController, {
            constructor: LargeSignatureDirectiveCtor,

            clearSignature: function clearSignature() {
                this.signatureAPI.clearCanvas();
                this.signature="[]"; //reset signature so old one isn't saved if user tries to save a blank signature
            }
        });

        return {
            scope: {
                signature: '=signature',
                canvasSize: '=canvasSize'
            },
            templateUrl: 'angular/app/modules/core/features/signatures/signature_modal/large_signature_directive_template.html',
            controller: LargeSignatureDirectiveController,
            link: linkFunction,
            controllerAs: 'largeSignatureVm',
            bindToController: true
        };
    };
}());