(function () {
    "use strict";
// class SelectListController extends Components.InputBaseController {

// TODO: REmoveX
// @ngInject
function SelectInputControllerCtor(
        $scope, $element, $attrs, $compile, $translate, _, $window) {

    this.constructor.$super.call(this, $scope, $element, $attrs, $compile, $translate, _, $window);

    this.__objectType = 'SelectListController';
    this.isRequired = false;
    this.validationError = '';
    this.value = '';
    this._ = _;
    this.$translate = $translate;
    this.$compile = $compile;
    this.$scope = $scope;
    this.$window = $window;
    this.$element = $element;
}

// TODO(sean): Remove and replace with Es6
var SelectInputController = Class(Components.InputBaseController, {
    // TODO: Delete and replace with $onInit()
    constructor: SelectInputControllerCtor,

    // $onInit() {
    $onInit: function $onInit() {
        // super.$onInit();
        // super.validate();
        this.constructor.$superp.$onInit.call(this);
        this.constructor.$superp.validate.call(this);

        this.cssClass['form-element--select'] = true;

        // Confusing, but this is used to set the initial value of the
        // ng-form HTML element and then after initialization
        // "formName" represents that DOM element.
        //this.formName = `${this.name}Form`;
        this.formName = this.name + 'Form';
    },

    onFocus: function onFocus() {
        this.cssClass['form-element--focused'] = true;
    },

    onBlur: function onBlur() {
        this.cssClass['form-element--focused'] = false;
        this.destroyMenu();
    },

    // onChange() {
    onChange: function onChange(value) {
        this.model.$setViewValue(value);
        this.value = value;
        this.formName.$setDirty();
        this.destroyMenu();
    },

    // shouldShowError() {
    shouldShowError: function shouldShowError() {
        return (this.validationError !== ''
            && (this.formName[this.name].$touched
                || this.formName.$$parentForm.$submitted)
        );
    },

    renderMenuItem: function(value) {
        // Note: ng-mousedown is signficant because it gets called before on-blur
        return (
            '<div class="select-menu__item" ng-mousedown="$ctrl.onChange(\'' + value + '\')">' +
                value +
            '</div>'
        );
    },

    // renderMenu()
    renderMenu: function renderMenu() {
        var html = '<div class="select-menu">' +
                        '<div class="select-menu__content">';

        this.options.map(function(item) {
            html += this.renderMenuItem(item);
        }.bind(this));

        html += '</div></div>';

        angular.element(this.$window).bind('resize',
            this._.debounce(this.setStyles, 500).bind(this));

        return this.$compile(html)(this.$scope);
    },

    setStyles: function() {
        var xPos = this.$element.offset().left;
        var yPos = this.$element.offset().top;
        var width = this.$element.children()[0].clientWidth;

        angular.element('.select-menu').css({
            width: width,
            top: yPos,
            left: xPos,
        });
    },

    // showMenu()
    showMenu: function showMenu() {
        this.menuElement = this.renderMenu();
        angular.element(document.body).append(this.menuElement);
        this.setStyles();
    },

    // destroyMenu()
    destroyMenu: function destroyMenu() {
        this.menuElement.remove();
        angular.element(this.$window).off('resize', this.setStylesDebounced);
    },

});

Components.SelectInput = {
    templateUrl: 'angular/app/modules/common/ui_components/form/select_input.html',
    controller: SelectInputController,

    // Requiring ngModel/ngOptions is a hack that allows us access to the
    // ng form API ($dirty, $valid, $isSubmitted, etc), while still preserving
    // the essence of a unidirectional data flow (e.g. Container -> form -> inputs)
    require: {
        model: 'ngModel',
    },

    bindings: {
        options: '<',
        flavor: '@',
        label: '@',
        name: '@',
    }
};

}());
