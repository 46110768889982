(function () {
    "use strict";

    // @ngInject
    function BadgePillTypesModalControllerCtor($modalInstance, $scope, _, $injector, AnalyticsService, CompaniesManager,
                                               PopupMessageService, Enums, badgePillType, badgePillCollection, onSaveHandler,
                                               limit, modalTitle, modalDescription, analyticsArgs) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BadgePillTypesModalController';

        AnalyticsService.trackPageView(this, 'badge_pills_types_modal');

        this._ = _;
        this.Enums = Enums;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;

        this.$modalInstance = $modalInstance;
        this.badgePillCollection = badgePillCollection;
        this.badgePillType = badgePillType;
        this.onSaveHandler = onSaveHandler;
        this.limit = limit;
        this.modalTitle = modalTitle;
        this.modalDescription = modalDescription;
        this.analyticsArgs = analyticsArgs;

        this.company = this.CompaniesManager.getCurrCompany();
    }

    Controllers.BadgePillTypesModalController = Class(Controllers.BaseController, {
        constructor: BadgePillTypesModalControllerCtor,

        dismiss: function dismiss() {
            this.$modalInstance.close(false);
            this._trackClose();
        },

        _onSaveHandler: function _onSaveHandler(badgePillCollection) {
            return this.onSaveHandler(badgePillCollection).then(function() {
                this.$modalInstance.close(true);
            }.bind(this)).catch(function error(resp) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, resp.data.error_message);
            }.bind(this));
        },

        _trackClose: function _trackClose() {
            var event = this.AnalyticsService.analytics_events[this.badgePillType + "__modal_closed"];
            this.AnalyticsService.track(this, event, this.analyticsArgs);
        }

    });
}());
