(function () {
    'use strict';

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb

    class BankAccountIdentificationUploadController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, PhotosUploadManager, CompaniesManager, PopupMessageService) {
            super($scope, $injector);
            this.__objectType = 'BankAccountIdentificationUploadController';

            this.PhotosUploadManager = PhotosUploadManager;
            this.CompaniesManager = CompaniesManager;
            this.PopupMessageService = PopupMessageService;
        }

        $onInit() {
            this.uploadIdentificationModel = angular.extend(this.PhotosUploadManager.getUpdatedModelData({
                instance: this.CompaniesManager.getCurrCompany(),
                type: 'user_identification_doc',
                forceUploadToS3: true
            }), {dontUpdateServer: true});

            this.uploadIdentificationPattern = '.png,.jpg,.jpeg';
        }

        onFilesDraggedOrSelected(files) {
            if (!files || !files.length) {
                return;
            }
            let file = files[0];
            this.onFileSelected({identificationFile: file, side: this.side, type: this.type});

            if (!file) {
                // Popup till we fix how identificationUploadError is showed
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'UPLOAD_FILE._ERROR_FILE_TYPE_NOT_SUPPORTED_');
                // this.identificationUploadError = 'UPLOAD_FILE._ERROR_FILE_TYPE_NOT_SUPPORTED_';
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                this.identificationUploadError = 'UPLOAD_FILE._ERROR_FILE_TOO_LARGE_SHORTER_';
                return;
            }

            this.identificationUploadInProgress = true;
            this.identificationUploadError = null;

            let promise = this.PhotosUploadManager.uploadPhoto([file], this.uploadIdentificationModel, this, true, true);
            promise.then(() => {
                this.onIdentificationUploaded({identificationFileUrl: file.s3Response.url, side: this.side, type: this.type});
            }).catch((resp) => {
                if (resp.indexOf("The request signature we calculated") > 0) {
                    // We probably need to call getAwsData inside uploadPhoto but we haven't because it wasn't expired yet
                    this.identificationUploadError = 'We had an error, please RELOAD the page and retry';
                } else {
                    this.identificationUploadError = 'We had an error, please try again';
                }
            }).finally(() => {
                this.identificationUploadInProgress = false;
            });
        }
    }

    Components.BankAccountIdentificationUpload = {
        bindings: {
            type: '@',
            side: '@',
            hasIdentificationImage: '<',
            onIdentificationUploaded: '&',
            onFileSelected: '&',
            shouldShowRequiredError: '<',
            onNewDesign: '<',
            showError: '='
        },
        controller: BankAccountIdentificationUploadController,
        name: 'hbBankAccountIdentificationUpload',
        templateUrl: 'angular/app/modules/core/features/bank_account/bank_account_identification_upload_template.html',
    };

}());