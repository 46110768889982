Components.TabsPane = {
    transclude: true,
    require: {
        hbTabs: '^hbTabs'
    },
    bindings: {
        title: '@',
        selectOnLoad: '<'
    },
    // @ngInject
    controller: function TabsController( $scope ){
        this.$onInit = function $onInit() {
            this.hbTabs.addPane( this );
        };
    },
    name: 'hbTabsPane',
    templateUrl: 'angular/app/modules/common/ui_components/tabs/tabs_pane_component_template.html',
};