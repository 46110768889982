(function () {
    "use strict";

    Controllers.ClientPortalDemoModalController = class ClientPortalDemoModalController  extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $translate) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalDemoModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;

            this._init()
        };

        _init(){
            this.demoSteps = [
                {
                    _id: 1,
                    active: true,
                    title: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_1_TITLE_'),
                    text: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_1_'),
                    img: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1551738720/honeybook_cdn/client-portal/slide-1-a.png'
                },
                {
                    _id: 2,
                    active: false,
                    title: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_2_TITLE_'),
                    text: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_2_'),
                    img: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1551738720/honeybook_cdn/client-portal/slide-2.png'
                },
                {
                    _id: 3,
                    active: false,
                    title: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_3_TITLE_'),
                    text: this.$translate.instant('CLIENT_PORTAL.DEMO._STEP_3_'),
                    img: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1551738720/honeybook_cdn/client-portal/slide-3.png'
                }
            ];

            this.activeStep = 1;
        }

        onArrowClick(direction, stepId){
            this.activeStep = direction === 'next' ? stepId + 1 : stepId - 1;
        }

        onDismiss() {
            this.$modalInstance.dismiss();
        }

        onClose() {
            this.$modalInstance.close();
        }


    };


}());




