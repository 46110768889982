(function () {
    "use strict";

    // @ngInject
    function CompanySettingsQuickbooksControllerCtor($scope, $injector,StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsQuickbooksController';

        this.StatsigService = StatsigService;
    }


    Controllers.CompanySettingsQuickbooksController = Class(Controllers.BaseController, {

        constructor: CompanySettingsQuickbooksControllerCtor,

    });
}());
