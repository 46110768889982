// const Card = {
var Card = {
    name: 'hbCard',
    templateUrl: 'angular/app/modules/common/ui_components/card/card.html',
    transclude: {
        cardHeader: '?cardHeader',
        cardFooter: '?cardFooter',
    },
    bindings: {
        disabled: '<',
        showFooter: '='
    },
    controller: function() {
      this.$onChanges = function() {
        this.updateStyle();
      };

      this.updateStyle = function() {
        if (this.disabled) {
          this.cssClass = ['card--disabled'];
        } else {
          this.cssClass = [];
        }
      };
    }
};

angular.module('honeybook.common.components.card', [])
  .component('hbCard', Card);
