Directives.ProjectToolsEmptyStateDirective = function ProjectToolsEmptyStateDirective() {

    // @ngInject
    function ProjectToolsEmptyStateControllerFunc($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProjectToolsDirectiveController';
    }

    var ProjectToolsEmptyStateController = Class(Controllers.BaseController, {
        constructor: ProjectToolsEmptyStateControllerFunc,
    });

    return {
        scope: {
            title: '@',
            icon:'@',
            shouldShowUpgradeBadge:'=?',
            feature: '<?'
        },
        templateUrl: 'angular/app/modules/core/features/workspace/project_tools/project_tools_empty_state.html',
        controller: ProjectToolsEmptyStateController,
        controllerAs: 'toolEmptyStateVm',
        bindToController: true
    };
};
