
Directives.UserInfoDirective =  function UserInfoDirective(){

    // @ngInject
    function UserInfoDirectiveControllerFunc($validation, AnalyticsService, ModalService, UsersManager, PopupMessageService, $scope, $injector, $translate, StatsigService,Enums){

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'UserInfoDirectiveController';
        this.validation = $validation;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.StatsigService = StatsigService;
        this.Enums = Enums;

        this.placeholderJobTitle ="";
        this.authenticationCompleted = false;
        this.currentUserEmail = this.user.email;
        this.changingEmail = false;
        this.isClient = this.user.isClient();

        var self = this;

        if(!self.user.isClient()){
            self.placeholderJobTitle = $translate.instant('ACCOUNT.PLACEHOLDERS._JOB_');
        }

        this.updateUserData = function updateUserData() {
            if (self.user.isTrialStartedAfterSubtypeABTestDate()) {
                this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.companySubTypeSelection, 'showCompanySubType', false)
                    .then(function (variant) {
                        this.showCompanySubTypeVariant = variant;
                        self.userData = {
                            _id: self.user._id,
                            job_title: this.showCompanySubTypeVariant && self.user.company_role === 'Business Consultant' ? 'Consultant' : self.user.job_title,
                            email: self.user.email,
                            phone_number: self.user.phone_number,
                            full_name: self.user.full_name,
                            personal_address: self.user.address
                        };
                    })
            } else {
                self.userData = {
                    _id: self.user._id,
                    job_title: self.user.job_title,
                    email: self.user.email,
                    phone_number: self.user.phone_number,
                    full_name: self.user.full_name,
                    personal_address: self.user.address
                };
            }
        };

        self.updateUserData();

        this.onUpdateSuccess = function onModelUpdateSuccess() {
            self.updateUserData();
        };

        this.onUpdateError = function onModelUpdateError() {
//            self.updateUserData();
//            alert('error saving user');
        };

        this.authenticateUser = function authenticateUser() {
            var analyticsArgs = {
                user_id: self.user._id,
                current_email: self.currentUserEmail
            };

            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_clicked_email_field, analyticsArgs);

            if (!self.authenticationCompleted) {

                if (self.user.securityPhoneNumberLastFourDigits()) { // has security phone number
                    self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.warning,
                        'In order to change your email, you are required to complete 2FA authentication using your phone.',
                        function ok(){
                            self.ModalService.openTwoFactorAuthModal(true, true)
                                .then(self.onTwoFactorAuthDone.bind(self))
                                .catch(self.onTwoFactorAuthDone.bind(self));
                        },
                        function cancelled(){
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_cancelled_on_2fa_heads_up_popup, analyticsArgs);
                        },
                        'Continue',
                        'Cancel');
                } else {
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.warning,
                        'COMPANY_SETTING.ERRORS._MISSING_SECURITY_PHONE_NUMBER_')
                }
            }
        };

        this.updateUserEmail = function updateUserEmail() {
            var analyticsArgs = {
                user_id: self.user._id,
                old_email: self.currentUserEmail,
                new_email: self.userData.email
            };

            if(!self.verificationCode) {
                return;
            }

            if(self.user.isClient()){
                return;
            }

            self.changingEmail = true;
            self.UsersManager.updateUserEmail(self.userData.email, self.verificationCode)
                .then(function success() {
                    self.currentUserEmail = self.userData.email;
                    self.updateUserData();
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info, 'Your email has been successfully changed' );
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_email_change_success, analyticsArgs);
                })
                .catch(function error(result) {
                    self.userData.email = self.currentUserEmail;
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error,  result.data.error_message || 'Error updating your email. Please contact us)');
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_email_change_failure, analyticsArgs);
                })
                .finally(function finallyDo() {
                    self.changingEmail = false;
                    self.authenticationCompleted = false;
                });
        };

        this.onTwoFactorAuthDone = function onTwoFactorAuthDone(result) {
            // dismissed
            if(result.cancel) {
                return;
            }

            // all good, code is verified!
            self.authenticationCompleted = true;
            self.verificationCode = result.code;
            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info, 'Validation in process. You may change your email now' );
        },

        this.updateUserProperties = function updateUserProperties(form){

            if (!form.$dirty) return;

            self.isSavingIndicatorActive.value = true;

            if (form.$valid) {
                self.userData.first_name = form.full_name.$viewValue.split(' ')[0];
                self.userData.last_name = form.full_name.$viewValue.split(' ').slice(1).join(' ');
                self.userData.address = form.personal_address.$viewValue;

                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_changed_personal_info, self.userData);
                self.UsersManager.updateUserProperties(self.userData, self.user, true).then(
                    self.onUpdateSuccess,
                    self.onUpdateError
                );
            }
        };

        this.changeProfilePhoto = function changeProfilePhoto() {
            self.ModalService.openUploadProfilePhotoModal(self.user);
        };

        StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.oooMode)
            .then(
                function(isOooModeEnabled) {
                    this.isOooModeEnabled = isOooModeEnabled;
                }
                    .bind(this)
            );
    }


    var UserInfoDirectiveController = Class(Controllers.BaseController, {
        constructor: UserInfoDirectiveControllerFunc
    });

    return {
        scope:{
            user: '=user',
            isSavingIndicatorActive: '=isSavingIndicatorActive',
            hideUserAvatar: '=hideUserAvatar',
            accountInfoVariant: '=accountInfoVariant'
        },

        templateUrl : 'angular/app/modules/core/features/settings/account/user_info/user_info_template.html',
        controller : UserInfoDirectiveController,
        controllerAs : 'userInfoVm',
        bindToController : true
    }
};