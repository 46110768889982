(function () {
    "use strict";

    // @ngInject
    function HomeServiceCtor($state, AppStates, AppConfigService, AnalyticsService, UsersManager, AbTestService, Enums, $rootScope, DeviceService,
                             $q, UiPersistenceService, FeaturesService, _, ReactModalService, StatsigService) {
        this.$state = $state;
        this.AppStates = AppStates;
        this.AppConfigService = AppConfigService;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.$rootScope = $rootScope;
        this.DeviceService = DeviceService;
        this.$q = $q;
        this.UiPersistenceService = UiPersistenceService;
        this.FeaturesService = FeaturesService;
        this._ = _;
        this.ReactModalService = ReactModalService;
        this.isMobileDevice = DeviceService.nxSmallBreakpoint();
        this.StatsigService = StatsigService;
        
        this.isShowHomeBanner = false;
        this.shouldShowOneHome;
        this.UsersManager.on('loggingOut', function() {
            this.shouldShowOneHome = undefined;
        }, this);
    }

    Services.HomeService = Class(function () {

        return {

            constructor: HomeServiceCtor,

            shouldShowHome: function shouldShowHome() {
                var user = this.UsersManager.getCurrUser();
                var isPreTrialer = user.shouldStartTrial();
                return !isPreTrialer && !user.isBookkeeperMode();
            },

            shouldShowBookkeeping: function shouldShowBookkeeping() {
                var user = this.UsersManager.getCurrUser();
                return user.isBookkeeperMode();
            },

            routeToPipelineOrHome: function routeToPipelineOrHome(ignoreHome) {
                var route = this.getRouteToPipelineOrHome(ignoreHome);
                if (route) {
                    this.$state.go(route);
                }
            },

            getRouteToPipelineOrHome: function getRouteToPipelineOrHome(ignoreHome) {
                if (this.shouldShowHome()) {
                    return this.AppStates.root_core_navigation_home;
                }

                if (this.shouldShowBookkeeping()) {
                    return this.AppStates.root_core_navigation_bookkeeping_reports_payments;
                }

                return this.AppStates.root_core_navigation_pipeline;
            },

            validateUserShouldSeeHome: function validateUserShouldSeeHome() {
                var shouldShowHome = this.shouldShowHome();
                var shouldShowBookkeeping = this.shouldShowBookkeeping();

                if (!shouldShowHome) {
                    if (shouldShowBookkeeping) {
                        this.$state.go(this.AppStates.root_core_navigation_bookkeeping_reports_payments);
                    } else {
                        this.$state.go(this.AppStates.root_core_navigation_pipeline);
                    }
                }
                return shouldShowHome;
            },

            getShouldShowOneHome: function getShouldShowOneHome() {
                if (angular.isDefined(this.shouldShowOneHome)) {
                    return this.$q.resolve(this.shouldShowOneHome);
                } 

                var isGotNewHomeVariantPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.newHomeTest, 'isNewHome', false);
                var isInNewHomeRolloutPromise = this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.newHomeRollout, false);
    
                return this.$q.all([isGotNewHomeVariantPromise, isInNewHomeRolloutPromise]).then(function(results) {
                    this.shouldShowOneHome = this._.some(results);
                    return this.shouldShowOneHome;
                }.bind(this)).catch(function() {
                    return false;
                });
            },

            shouldShowHomeForTrialers: function shouldShowHomeForTrialers() {
                this.currUser = this.UsersManager.getCurrUser();
                var isPreTrialer = this.currUser.shouldStartTrial();
                var isUserSubscribed = this.currUser.companyHasSubscription();
                return (!this.isMobileDevice && !isPreTrialer && !isUserSubscribed);
            },

            getShouldShowOneHomeMWeb: function shouldShowHomeForMWeb() {
                if (angular.isDefined(this.shouldShowOneHomeMWeb)) {
                    return this.$q.resolve(this.shouldShowOneHomeMWeb);
                }

                var isInNewHomeMWebRolloutPromise = this.StatsigService.isGateEnabled('one_home_mweb', false);

                return this.$q.all([isInNewHomeMWebRolloutPromise]).then(function(results) {
                    this.shouldShowOneHomeMWeb = this._.some(results);
                    return this.shouldShowOneHomeMWeb;
                }.bind(this)).catch(function() {
                    return false;
                });
            },

            setVisibilityHomeBanner: function setVisibilityHomeBanner(isVisible) {
                this.isShowHomeBanner = isVisible;
            }
        };
    });
}());
