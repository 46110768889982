/**
 * Created by Jonah Kim on 5/10/16.
 */

(function () {
    'use strict';

    // @ngInject
    function GenericVideoControllerCtor($modalInstance, $scope, $timeout, $state, AnalyticsService, modalOpenSource, videoTitle, videoLink,  modalTitle, modalSubTitle, modalBtnText, modalBtnOnClickFunc, videoId, enableYoutubeApi, analyticsName, AppStates, UserService, _) {

        this.$modalInstance = $modalInstance;
        this.$state = $state;
        this.__objectType = 'GenericVideoController';
        this.videoLink = videoLink;
        this.videoId = videoId;
        this.enableYoutubeApi = enableYoutubeApi;
        this.modalTitle = modalTitle;
        this.modalSubTitle = modalSubTitle;
        this.modalBtnText = modalBtnText;
        this.modalBtnOnClickFunc = modalBtnOnClickFunc;
        this.AppStates = AppStates;
        this.UserService = UserService;
        this.$scope = $scope;
        this.AnalyticsService = AnalyticsService;
        this.analyticsName = analyticsName;
        this._ = _;

        this.showVideo = true;
        this.AnalyticsService.trackPageView(this, "generic video modal", {source: modalOpenSource, videoTitle: videoTitle});

        var timeout = $timeout(function () {
            this.AnalyticsService.track(this, 'video watched 10s: ' + videoTitle, {type: this.analyticsName});
        }.bind(this), 10 * 1000);

        $scope.$on('$destroy', function () {
            $timeout.cancel(timeout);
        }.bind(this));

    }

    Controllers.GenericVideoController = Class(Controllers.BaseController, {
        constructor: GenericVideoControllerCtor,

        onVideoCompleted: function onVideoCompleted() {
            this.showVideo = false;
            this.$scope.$applyAsync();
            this.sendAnalyticsEvent('video completed');
        },

        replay: function replay() {
            this.showVideo = true;
            this.sendAnalyticsEvent('video replayed');
        },

        handleClickCTA: function handleClickCTA() {
            this.close('CTA');
        },

        close: function close(origin) {
            this.$modalInstance.close();
            this.sendAnalyticsEvent('video closed', {button: origin || 'x-button'});
        },

        onModalBtnClick: function onModalBtnClick() {
            this.modalBtnOnClickFunc();
        },

        sendAnalyticsEvent: function sendAnalyticsEvent(eventName, parameters) {
            parameters = this._.extend(parameters || {}, {type: this.analyticsName});
            this.AnalyticsService.track(this, eventName, parameters);
        }

    });
}());