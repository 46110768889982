(function () {
    'use strict';

    class ProjectDetailsSpacesComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, CompaniesManager, ModalService, AppStates, EventsManager) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsSpaces';

            this.CompaniesManager = CompaniesManager;
            this.ModalService = ModalService;
            this.AppStates = AppStates;
            this.EventsManager = EventsManager;
        }

        $onInit() {
            this.initiallyLoaded = false;
            this.companyVenuesList = [];

            this._fetchSpaces();

            // set the space selected in the modal as checked
            this.register(
                this.project,
                'success', 
                () => this._setSpaces(this.companyVenuesList)
            );
        }

        _fetchSpaces() {
            this.CompaniesManager.getCompanySpaces(this.company)
                .then(data => this._setSpaces(data.data.company_spaces))
                .finally(() => this.initiallyLoaded = true);
        }

        _setSpaces(spaces) {

            if (spaces.length > 0) {

                this.companyVenuesList = spaces.map(space => {
                    if (this.project.spaces) {
                        space.isChecked = this.project.spaces.findIndex(project => project.space_id === space._id) > -1;
                    }

                    return space;
                });

            }
        }

        toggleSpace(space) {

            // mark in progress
            this.updateInProgress = true;

            let updatePromise = space.isChecked ?
                this.EventsManager.addSpace(this.project, space._id) :
                this.EventsManager.removeSpace(this.project, space._id);
            return updatePromise.then(function() {
                this.updateInProgress = false;
            }.bind(this));
        }
    }

    Components.ProjectDetailsSpaces = {
        bindings: {
            company: '<',
            project: '<',
            analyticsEventsSource: '@'
        },
        controller: ProjectDetailsSpacesComponent,
        name: 'hbProjectDetailsSpaces',
        templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_spaces.html'
    };

}());
