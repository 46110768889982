(function () {
    'use strict';

    class CreateTemplateDropdown extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            AnalyticsService,
            OtamStatusService,
            FlowsBetaUserTypeService
        )  {
            super($scope, $injector);
            this.__objectType = 'CreateTemplateDropdown';
            this.AnalyticsService = AnalyticsService;
            this.OtamStatusService = OtamStatusService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        }

        $onInit() {
            this.filteredTemplateTypes = this.templateTypes.filter(this.isPermittedType);
        }

        isPermittedType(type) {
            // Currently, email signature doesn't have a create function
            const blockedTypeKeys = ["emailSignature"];
            return !(blockedTypeKeys.includes(type.key));
        }

        onCreateClick($event, templateType) {
            $event.preventDefault();
            $event.stopPropagation();
            this.showDropdown = false;
            if (this.onCreate && angular.isFunction(this.onCreate)) {
                this.onCreate({templateType: templateType});
            }
        }

        updateDescriptionView(templateType) {
            this.activeTemplateType = templateType;
        }
    }

    Components.CreateTemplateDropdown = {
        bindings: {
            showDropdown: '=',
            templateTypes: '<',
            onCreate: '&',
            isShowSmallGraphic: '<'
        },
        controller: CreateTemplateDropdown,
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/create_template_dropdown/create_template_dropdown.html',
    };

}());
