Directives.TasksMgmtDirective = function TasksMgmtDirective() {

    // @ngInject
    function TasksMgmtDirectiveControllerFunc($injector, $scope, TasksManager, _, AnalyticsService, $timeout, UiPersistenceService,
                                              CompaniesManager, WorkflowManager, PopupMessageService, ModalService, WebsocketHelperService, $log) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TasksMgmtDirectiveController';
        this.TasksManager = TasksManager;
        this.AnalyticsService = AnalyticsService;
        this.$log = $log;
        this._ = _;
        this.$timeout = $timeout;
        this.UiPersistenceService = UiPersistenceService;
        this.WorkflowManager = WorkflowManager;
        this.$scope = $scope;
        this.PopupMessageService = PopupMessageService;
        this.ModalService = ModalService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.tasks = [];
        this.perPage = 30;
        this.isFetchingTasks = false;
        this.hasMoreTasksToShow = false;
        this.loadingTaskIndicator = true;
        this.currentTaskPage = 1;
        this.offsets = {
            tasks_misc: 0,
            wf_approvals_misc: 0,
            wf_approvals_after_step_execution: 0
        };
        this.company = CompaniesManager.getCurrCompany();
        var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.tasks_mgmt_footer);
        this.AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.tasks_page);

        if (uiPersistenceData && !uiPersistenceData.showFooter) {
            this.showFooter = false;
        } else {
            this.showFooter = true;
        }

        this.filters = [
            {translation: 'TASKS.LABELS.FILTERS._ALL_', value: 'all', style: '', count: null},
            {translation: 'TASKS.LABELS.FILTERS._TODAY_', value: 'today', style: 'today', count: null},
            {translation: 'TASKS.LABELS.FILTERS._THIS_WEEK_', value: 'this_week', style: '', count: null},
            {translation: 'TASKS.LABELS.FILTERS._OVERDUE_', value: 'overdue', style: 'overdue', count: null}
        ];

        this.filters.push({
            translation: 'TASKS.LABELS.FILTERS._APPROVALS_',
            value: 'approvals',
            style: '',
            count: null
        });
        this.filters.push({translation: 'TASKS.LABELS.FILTERS._TASKS_', value: 'tasks', style: '', count: null});

        this.filters.push({
            translation: 'TASKS.LABELS.FILTERS._COMPLETED_',
            value: 'completed',
            style: '',
            count: null
        });

        this.onLoad();

        this.sortingModel = {
            currentSorting: null,
            byDescription: {
                sortName: 'byDescription',
                sortKey: 'task.description',
                serverSortKey: 'description',
                sortReverse: false
            },
            byWorkflow: {
                sortName: 'byWorkflow',
                sortKey: 'task.workflow',
                serverSortKey: 'workflow_name',
                sortReverse: false
            },
            byProject: {
                sortName: 'byProject',
                sortKey: 'task.project_name',
                serverSortKey: 'project_name',
                sortReverse: false
            },
            dueDate: {
                sortName: 'dueDate',
                sortKey: 'task.due_date',
                serverSortKey: 'due_date',
                sortReverse: false
            },
        };
        //set the default sorting and filter or take filter from state param
        this.setSort(this.sortingModel.dueDate);
        if (this.filterParam) {
            var paramFilter = this._.find(this.filters, function (filter) {
                return filter.value === this.filterParam;
            }.bind(this));
            this.setFilter(paramFilter, true);
        } else {
            this.setFilter(this.filters[1], true);
        }
        this.refreshTaskCounts();

        this.createTaskLoader = false;
        this.taskLoader = false;
        this.WebsocketHelperService.registerToRoom(this.company._id + ".reload_tasks", this.refreshTasksWebsocket.bind(this));

        // clean up
        $scope.$on('$destroy', function () {
            this.WebsocketHelperService.unregisterFromRoom(this.company._id + ".reload_tasks");
        }.bind(this));
        this.initialLoadEmptyTasks = false;
    }

    var TasksMgmtDirectiveController = Class(Controllers.BaseController, {
        constructor: TasksMgmtDirectiveControllerFunc,

        refreshTasksWebsocket: function refreshTasksWebsocket() {
            this.refreshTasks(false);
        },

        createTask: function createTask() {
            this.createTaskLoader = true;

            this.TasksManager.createTask(this.workspaceId, this.showProjectColumn ? "task_management" : "workspace")
                .then(function success(resp) {
                    this.tasks.unshift(resp.data);
                    this.refreshTaskCounts();
                    this.createTaskLoader = false;
                    this.initialLoadEmptyTasks = false;
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.task_mgmt_add_new_task, {origin: this.showProjectColumn ? "task_management" : "workspace"});
                    this.autoSelect();
                }.bind(this));
        },

        deleteTask: function deleteTask(task) {
            this.taskLoader = true;
            if (task.source === "workflow" && (task.status === "pending" || task.status === "required") && task.type !== "task") {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    'WORKFLOW.ALERTS._DELETING_CURRENT_OR_FUTURE_APPROVAL_',
                    function clickedYeah() {
                        this.skipStep(task);
                    }.bind(this),
                    function clickedNay() {
                    }.bind(this));
            }
            else if (task.type === "task") {
                this.skipStep(task);
            }
            else {
                this.TasksManager.deleteTask(task)
                    .then(function success() {
                        var task_id = task._id;
                        var index = this._.findIndex(this.tasks, function (task) {
                            return task._id === task_id;
                        });
                        if (index !== -1) {
                            this.tasks.splice(index, 1);
                        }
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.task_mgmt_delete_task, {origin: this.showProjectColumn ? "task_management" : "workspace"});
                        this.refreshTaskCounts();
                        this.taskLoader = false;

                    }.bind(this));
            }
        },

        skipStep: function skipStep(task) {
            this.WorkflowManager.changeWorkflowStepState(task.workspace_id, task._id, "declined")
                .then(function success() {
                    var task_id = task._id;
                    var index = this._.findIndex(this.tasks, function (task) {
                        return task._id === task_id;
                    });

                    if (index !== -1) {
                        this.tasks.splice(index, 1);
                    }
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.workflow_mark_as_declined, {origin: this.showProjectColumn ? "task_management" : "workspace"});
                    this.refreshTaskCounts();
                    this.taskLoader = false;
                }.bind(this));
        },

        approveTask: function approveTask(task) {
            return this.WorkflowManager.changeWorkflowStepState(task.workspace_id, task._id, "approved", task.subject, task.body, task.attachedFiles)
                .then(function success() {
                    var task_id = task._id;
                    var index = this._.findIndex(this.tasks, function (rowItem) {
                        return rowItem._id === task_id;
                    });

                    if (index !== -1) {
                        this.tasks.splice(index, 1);
                        this.tasks.push(task);
                    }

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.workflow_mark_task_as_approved, {origin: this.showProjectColumn ? "task_management" : "workspace"});

                    this.refreshTaskCounts();
                    this.taskLoader = false;
                }.bind(this))
                .catch(function fail(res) {
                    var message;
                    if (res && res.data && res.data.error_type) {
                        if (res.data.error_type === 'HBUserError') {
                            message = res.data.error_message;
                        } else {
                            this.$log.error(res.data.error_message);
                            message = 'ERRORS.SERVER_API._UNEXPECTED_';
                        }
                    } else {
                        message = 'ERRORS.SERVER_API._UNEXPECTED_';
                    }

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);


                }.bind(this));
        },

        autoSelect: function autoSelect() {
            this.$timeout(function () {
                var activeField = angular.element('#task-list-js').find('textarea').eq(0);
                activeField.focus();
                activeField.select();
            });
        },

        updateTaskAndMoveToTheBottomOfTheList: function updateTaskAndMoveToTheBottomOfTheList(task) {
            this.taskLoader = true;
            if (task.source === "workflow") {
                var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.workflowApprovalDependedStep);
                if ((task.status === 'required' || task.status === 'completed') && task.has_depended_step && (!uiPersistenceData || (uiPersistenceData && !uiPersistenceData.dontShow)) && !task.dontShowPopUp) {
                    this.PopupMessageService.showConfirmWithDontShowAgain(this.PopupMessageService.severityTypes.warning,
                        'WORKFLOW.WORKFLOW_STEPS_EDITOR.DO_NOT_SHOW_AGAIN._MSG_COPY_',
                        function clickedYeah() {
                            return this.approveTask(task);
                        }.bind(this),
                        function clickedNay() {
                            task.status = task.prevStatus;
                        }.bind(this), null, null, this.UiPersistenceService.keys.workflowApprovalDependedStep);
                }
                else {
                    return this.approveTask(task);
                }
            } else {
                this.TasksManager.updateTask(task)
                    .then(function success() {
                        var task_id = task._id;
                        var index = this._.findIndex(this.tasks, function (rowItem) {
                            return rowItem._id === task_id;
                        });

                        if (index !== -1) {
                            this.tasks.splice(index, 1);
                            this.tasks.push(task);
                        }
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.task_mgmt_mark_complete_task, {origin: this.showProjectColumn ? "task_management" : "workspace"});

                        this.refreshTaskCounts();
                        this.taskLoader = false;

                    }.bind(this));
            }
        },

        toggleFilters: function toggleFilters() {
            this.showFilterOptions = !this.showFilterOptions;
        },

        closeFilters: function closeFilters() {
            this.showFilterOptions = false;
        },

        _fetchTasks: function _fetchTasks(params) {
            if (this.workspaceId) {
                return this.TasksManager.fetchWorkspaceTasks(this.workspaceId, params)
                    .then(function(resp) {
                        return {
                            data: {
                                tasks: resp.data
                            }
                        };
                    });
            } else {
                return this.TasksManager.fetchUsersTasks(params);
            }
        },

        _fetchTaskCounts: function _fetchTaskCounts() {
            if (this.workspaceId) {
                return this.TasksManager.fetchWorkspaceTasksCount(this.workspaceId);
            } else {
                return this.TasksManager.fetchUserTasksCount();
            }
        },

        refreshTasks: function refreshTasks(isGetNextPage, isFirstLoad) {
            this.taskLoader = true;
            this.loadingTaskIndicator = true;

            if (isGetNextPage) {
                this.currentTaskPage += 1;
                this.offsets = this.nextOffsets;
            } else {
                this.currentTaskPage = 1;
                this.offsets = {
                    tasks_misc: 0,
                    wf_approvals_misc: 0,
                    wf_approvals_after_step_execution: 0
                };
            }
            var tasksParams = {
                page: this.currentTaskPage,
                perPage: this.perPage,
                sortBy: this.sortingModel.currentSorting.serverSortKey,
                sortDesc: this.sortingModel.currentSorting.sortReverse || false,
                filter: this.filter.value,
                tasks_misc_offset: this.offsets.tasks_misc, // general tasks offset
                wf_approvals_misc_offset: this.offsets.wf_approvals_misc, // Not being used?
                wf_approvals_after_step_execution_offset: this.offsets.wf_approvals_after_step_execution // Not being used?
            };

            this._fetchTasks(tasksParams)
                .then(function success(resp) {
                    var tasks = resp.data.tasks;
                    if (isGetNextPage) {
                        var taskIds = {};
                        this.tasks.forEach(function (item) {
                            taskIds[item._id] = true;
                        });
                        tasks.forEach(function (task) {
                            if (!taskIds[task._id]) {
                                this.tasks.push(task);
                            }
                        }.bind(this));
                    } else {
                        this.tasks = tasks;

                        if (this.tasks.length === 0 && isFirstLoad) {
                            this.setFilter(this.filters[0]);
                            this.initialLoadEmptyTasks = true;
                            return;
                        }
                    }

                    if (tasks.length === this.perPage) {
                        this.hasMoreTasksToShow = true;
                    } else {
                        this.hasMoreTasksToShow = false;
                    }
                    this.taskLoader = false;
                    this.loadingTaskIndicator = false;
                    this.moreTasksLoader = false;
                    this.nextOffsets = resp.data.offsets;

                    this.refreshTaskCounts();
                }.bind(this),
                function error(resp) {
                    this.loadingTaskIndicator = false;
                    this.moreTasksLoader = false;
            }.bind(this));
        },

        refreshTaskCounts: function refreshTaskCounts() {
            return this._fetchTaskCounts().then(function success(resp) {
                this.filters.forEach(function (filter) {
                    if (typeof resp.data[filter.value] === 'number') {
                        filter.count = resp.data[filter.value];
                    }
                }.bind(this));
                setTimeout(this.$scope.$digest, 1);
            }.bind(this));
        },

        setFilter: function setFilter(filter, isFirstLoad) {
            if (this.filter) {
                this.filter.selected = false;
            }
            this.filter = filter;
            this.filter.selected = true;

            this.refreshTasks(false, isFirstLoad);

            this.closeFilters();
        },
        setSort: function setSort(sortingModel) {
            this.sortingModel.currentSorting = sortingModel;
        },

        sortIt: function sortIt(sortingModel, sortReverse) {
            // check that there's no an unfinished fetch of tasks
            if (!this.isFetchingTasks) {
                // Reverse the sort if needed
                if (this.sortingModel.currentSorting.sortKey === sortingModel.sortKey) {
                    sortingModel.sortReverse = !sortingModel.sortReverse;
                }
                this.setSort(sortingModel);

                this.refreshTasks(false);
            } else {
                this.nextSort = {
                    sortingModel: sortingModel,
                    sortReverse: sortReverse
                };
            }
        },

        showMoreTasks: function showMoreTasks() {
            if ( this.moreTasksLoader ) {
                return;
            }

            this.refreshTasks(true);
            this.hasMoreTasksToShow = false;
            this.moreTasksLoader = true;
        },

        closeFooter: function closeFooter() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.tasks_mgmt_footer, {showFooter: false});
            this.showFooter = false;
        },
    });

    return {
        templateUrl: 'angular/app/modules/core/features/tasks/tasks_mgmt/tasks_mgmt_directive_template.html',
        controller: TasksMgmtDirectiveController,
        controllerAs: 'tasksVm',
        bindToController: true,
        scope: {
            workspaceId: '@',
            onLoad: '&',
            showProjectColumn: '=',
            filterParam: '=',
            designLayout: '@'
        }
    };
};
