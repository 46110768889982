(function () {
    'use strict';

    // @ngInject
    function IntellumSSORedirectControllerCtor($scope, $injector, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, $window, $state, $stateParams) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'IntellumSSORedirectController';
        this.$log = $log;
        this.$q = $q;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$window = $window;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;

        this.AnalyticsService.trackPageView(this, 'IntellumSSORedirectView');

        this.intellumCode = this.InitialAppLoadParamsService.getParamAndRemove('intellumCode');
        if(!this.intellumCode) {
            this.intellumCode = this.$stateParams['intellumCode'];
        }

        this.InitialAppLoadParamsService.cleanParamsFromView('intellumCode');

        this.redirectToIntellumIfNeedded();
    }

    Controllers.IntellumSSORedirectController = Class(Controllers.BaseController, {
        constructor: IntellumSSORedirectControllerCtor,

        showErrorAndRedirect: function showErrorAndRedirect() {
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_', function success() {
                this.$state.go(this.AppStates.root_core_navigation_home);
            }.bind(this));
        },

        redirectToIntellumIfNeedded: function redirectToIntellumIfNeedded() {
            var user = this.UsersManager.getCurrUser();
            var shouldBeRedirected = user && user.isVendor() && this.intellumCode;
            if (!shouldBeRedirected) {
                return this.showErrorAndRedirect();
            }

            this.UsersManager.getRedirectUrlForIntellumSSO(this.intellumCode).then(function success(response) {
                if(response && response.data && response.data.redirectUrl) {
                    this.InitialAppLoadParamsService.removeParam('intellumCode');
                    this.$window.location = response.data.redirectUrl;
                } else {
                    this.showErrorAndRedirect();
                }
            }.bind(this), this.showErrorAndRedirect.bind(this));
        }
    });
}());

Function.ubind = function (self) {
    var f = this;
    return function () {
        f.apply(self, Array.prototype.slice.call(arguments, 0));
    };
};
