Directives.PaymentsContainerDirective = function PaymentsContainerDirective() {

    // @ngInject
    function PaymentsContainerDirectiveControllerFunc($scope, $q, $translate, $injector, $timeout, Enums, _,
        AnalyticsService, WorkspaceFileService, uuid4, PopupMessageService, OnboardingService, DeviceService, ModalService,
        CompaniesManager, WorkspaceFilesManager, UsersManager, $filter, UIUtils, UiPersistenceService, FeaturesService, moment) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PaymentsContainerDirectiveController';

        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.OnboardingService = OnboardingService;
        this.DeviceService = DeviceService;
        this.ModalService = ModalService;
        this.$q = $q;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.WorkspaceFileService = WorkspaceFileService;
        this.uuid4 = uuid4;
        this.$filter = $filter;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;
        this.UiPersistenceService = UiPersistenceService;
        this.FeaturesService = FeaturesService;
        this.moment = moment;
        this.paymentsContainer = this.paymentsContainerModel || this.fileModel.payments_container;
        this.allowGratuity = this.fileModel && this.fileModel.allow_gratuity;
        this.currUser = UsersManager.getCurrUser();
        this.isClientMode = false;
        this.showPaymentsTemplates = false;
        this.isCardOnFileEnabled = this.currUser.account && this.currUser.account.card_on_file_enabled;

        ///////////////////////////////
        // File validation
        ///////////////////////////////

        var hasMoreThanOnePaymentValidationId = uuid4.generate();
        WorkspaceFileService.addFileValidation(hasMoreThanOnePaymentValidationId, function proposalValidator() {
            return $q(function (resolve, reject) {
                if (this.paymentsContainer.payments.length > 0) {
                    resolve();
                } else {
                    $scope.scrollToPayments();
                    PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'PAYMENTS._NO_PAYMENTS_MESSAGE_');
                    reject();
                }
            }.bind(this));
        }.bind(this), 500);

        var subOfPaymentsIsEqualToTheAmountOfTheAgreement = uuid4.generate();
        WorkspaceFileService.addFileValidation(subOfPaymentsIsEqualToTheAmountOfTheAgreement, function paymentsSumValidator() {
            return $q(function (resolve, reject) {

                if (this.arePaymentsAndProposalAmountsEqual()) {
                    resolve();
                } else {
                    $scope.scrollToPayments();
                    PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'PAYMENTS._PAYMENTS_DO_NOT_ADD_UP_TO_PROPOSAL_SUM_');
                    reject();
                }
            }.bind(this));
        }.bind(this), 550);

        if (this.fileModel) {
            this.isFileLoaded = false;
            this.gotWorkspaceFile();
            this.register(this.fileModel, 'success', this.gotWorkspaceFileCallback);
        } else {
            this.isFileLoaded = true;
        }

        var bannerParamsAndValidationsID = uuid4.generate();

        $scope.$on('$destroy', function () {
            WorkspaceFileService.removeFileValidation(hasMoreThanOnePaymentValidationId);
            WorkspaceFileService.removeFileValidation(subOfPaymentsIsEqualToTheAmountOfTheAgreement);
            WorkspaceFileService.removeFileValidation(bannerParamsAndValidationsID);
        });

        this.showMobileItems = false;
        this.device = this.DeviceService.device;

        // handle intro
        this.paymentsIntroSeen = true;
        this.paymentsIntroSeen = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.recurringPaymentsIntro, { seen: false }).seen;

        this.shouldShowPaymentsIntro = this.shouldShowPaymentsIntroFunc();
        if (this.fileModel) {
            this.register(this.fileModel, 'success', () => {
                this.$timeout(this.setBannerParamsAndValidations.bind(this, bannerParamsAndValidationsID), 0);
            });
        }

        this.AnalyticsService.trackLoad(this, 'payments container');
    }

    var PaymentsContainerDirectiveController = Class(Controllers.BaseController, {
        constructor: PaymentsContainerDirectiveControllerFunc,

        getDiffErrorText: function getDiffErrorText() {
            var proposalValue = this.paymentsContainer.getTotalProposalValue() || 0;
            var paymentsAmount = this.paymentsContainer.getTotalPaymentsAmount() || 0;

            var diffAmount = proposalValue - paymentsAmount;
            var diffType = diffAmount > 0 ? 'less' : 'more';
            diffAmount = Math.abs(diffAmount);

            diffAmount = this.$filter('currency')(diffAmount);
            proposalValue = this.$filter('currency')(proposalValue);

            return { diff_amount: diffAmount, diff_type: diffType, total_amount: proposalValue };
        },

        setBannerParamsAndValidations: function setBannerParamsAndValidations(validationID) {
            this.shouldShowPaymentsIntro = this.shouldShowPaymentsIntroFunc();

            this.WorkspaceFileService.addFileValidation(validationID, function proposalValidator() {
                return this.$q(function (resolve, reject) {
                    if (!this.shouldShowPaymentsIntro) {
                        resolve();
                    } else {
                        this.$scope.scrollToPayments();
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.unselect_payment_type);
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'PROPOSAL._UNSELECTED_PAYMENT_TYPE_MESSAGE', null, 'PROPOSAL._UNSELECTED_PAYMENT_TYPE_');
                        reject();
                    }
                }.bind(this));
            }.bind(this), 600);
        },

        showOptions() {

            if (this.hideOptions) {
                return false;
            }

            // if file has payments, vendor and client have actions they can do
            // in schedule and recurring files (refund, receipt, print, MAP, reminder, edit payment method)
            if (this.paymentsContainer.hasPaidPayments()) {
                return true;
            }

            // in recurring, the only option to do is for the client when the file is active (edit payment method)
            if (this.paymentsContainer.isRecurringPayment()) {
                return this.fileModel && !this.fileModel.isOwnerMode() && this.paymentsContainer.isRecurringPaymentActive();
            }

            // in all other cases only the owner has options (MAP, reminder delete on mWeb)
            return this.isOwnerMode;
        },

        showAutoPayment: function showAutoPayment(forVendor) {
            return this.fileModel && !this.fileModel.isCanceled() && !this.fileModel.isComplete() && this.paymentsContainer.isAutoPaymentActive();
        },

        getAutopayTooltip: function getAutopayTooltip() {

            if (this.isInWorkspaceView || this.isClientMode) {
                return '';
            }

            if (this.paymentsContainer.isAutoPayByVendor()) {
                return this.$translate.instant('FILE.PAYMENT.TOOLTIP._AUTO_PAY_TOOLTIP_VENDOR_');
            }

            return this.$translate.instant('FILE.PAYMENT.TOOLTIP._AUTO_PAY_TOOLTIP_CLIENT_');
        },

        getInitiatingMember: function getInitiatingMember() {
            var activatedById = this.paymentsContainer.auto_payment.activated_by;
            if (activatedById === this.currUser._id) {
                return 'you';
            }

            if (activatedById === this.fileModel.owner._id) {
                return this.fileModel.owner.full_name;
            }

            var user = _.find(this.fileModel.users, function (user) { return user._id === activatedById; });
            if (!user) {
                return 'your client';
            }

            return user.full_name;
        },

        showDisableAutoPayModal: function showDisableAutoPayModal() {
            this.ModalService.openDisableAutoPayModal(this.fileModel);
        },

        allowLoadTemplate: function allowLoadTemplate() {
            return this.companyModel &&
                this.companyModel.payments_containers &&
                this.companyModel.payments_containers.length > 0 &&
                this.fileModel.isOwnerMode() &&
                this.fileModel.isFirstDraft();
        },

        toggleAllowGratuity: function toggleAllowGratuity() {
            this.allowGratuity = !this.allowGratuity;
            this.fileModel.updateAllowGratuity(this.allowGratuity);
        },

        addPayment: function addPayment() {
            if (this.showAddPaymentLoader) {
                // We are probably still waiting for a previous addPayment call to finish.
                return;
            }

            this.showAddPaymentLoader = true;

            this.paymentsContainer.addPayment()
                .then(function () {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.payment_added);
                }.bind(this))
                .catch(function (resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.payment_added, resp, {
                        error_message: resp.data
                    });
                }.bind(this))
                .finally(function () {
                    this.showAddPaymentLoader = false;
                }.bind(this));
        },

        arePaymentsAndProposalAmountsEqual: function arePaymentsAndProposalAmountsEqual() {

            // for recurring payments, ignore for now
            if (this.paymentsContainer.isRecurringPayment()) {
                return true;
            }

            return this.WorkspaceFileService.arePaymentsAndProposalAmountsEqual(this.paymentsContainer);
        },

        gotWorkspaceFile: function gotWorkspaceFile() {
            this.isClientMode = this.fileModel.isClientMode();
            if (this.paymentsContainer.isAutoPaymentActive()) {
                this.initiatingAutoPaymentUser = this.getInitiatingMember();
                this.autoPaymentTooltip = this.getAutopayTooltip();
            }
        },

        gotWorkspaceFileCallback: function gotWorkspaceFileCallback() {

            // mark as first time load
            if (!this.isFileLoaded) {
                this.isFileLoaded = true;
            }

            this.gotWorkspaceFile();
        },

        saveAsPaymentsTemplate: function saveAsPaymentsTemplate(title) {

            this.templateTitle = title;

            if (!this.templateTitle || this.templateTitle.length === 0) {
                this.duringSaveAs = false;
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._MISSING_TEMPLATE_NAME_');
                return this.$q.reject();
            }

            this.duringSaveAs = true;
            return this.paymentsContainer.saveAsTemplate(this.companyModel, this.templateTitle).then(function success() {
                var analyticsArgs = {
                    template_type: "payments-schedule",
                    company_id: this.companyModel._id,
                    template_title: this.templateTitle
                };

                // origin_copy_id changes
                if (!this.templateMode) {
                    this.fileModel = this.WorkspaceFilesManager.getPaymentsTemplateOrigin(this.fileModel);
                    analyticsArgs.template_origin_id = this.fileModel.payments_container.origin_copy_id;
                    analyticsArgs.workspace_file_id = this.fileModel._id;
                }

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.template_save_as_confirmed, analyticsArgs);
            }.bind(this))
                .catch(function error(resp) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'FILE.TEMPLATES._ERROR_SAVE_OCCUR_MESSAGE_');

                    var analyticsArgs = {
                        template_type: "payments-schedule",
                        company_id: this.companyModel._id,
                        template_title: this.templateTitle,
                        error: resp.data.error_message
                    };

                    // origin_copy_id changes
                    if (!this.templateMode) {
                        this.fileModel = this.WorkspaceFilesManager.getPaymentsTemplateOrigin(this.fileModel);
                        analyticsArgs.template_origin_id = this.fileModel.payments_container.origin_copy_id;
                        analyticsArgs.workspace_file_id = this.fileModel._id;
                    }

                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.template_save_as_failed, analyticsArgs);
                }.bind(this));
        },

        onMobilePageBack: function onMobilePageBack() {
            this.shouldShowPaymentsMobilePage = false;
        },

        showPaymentsMobilePage: function showPaymentsMobilePage() {
            this.shouldShowPaymentsMobilePage = !!(this.isOwnerMode && !this.isPreviewMode);
        },

        allowRecurringPaymentEdit: function allowRecurringPaymentEdit() {
            return this.templateMode || (this.fileModel && !this.fileModel.isSent() && !this.paymentsContainer.hasPaidPayments());
        },

        onEndRecurringPaymentClick() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.error,
                'PAYMENTS.POPUPS.END_RECURRING_PAYMENT._BODY_',
                'FREQUENT_BUTTONS._YES_STOP_',
                'FREQUENT_BUTTONS._CANCEL_',
                'PAYMENTS.POPUPS.END_RECURRING_PAYMENT._TITLE_')
                .then(() => {
                    this.paymentsContainer.cancelRecurringPayment();
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.end_recurring_payment_confirmed);
                }).catch(() => {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.end_recurring_payment_canceled);
                });
        },

        onTogglePaymentScheduleTypeClicked(isRecurring) {
            if (this.paymentsContainer.isRecurringPayment() !== isRecurring) {
                this.paymentsContainer.togglePaymentScheduleType(isRecurring);
            }
        },

        selectPaymentsTypeFromIntro(isRecurring) {
            this.paymentsIntroSeen = true;
            this.shouldShowPaymentsIntro = this.shouldShowPaymentsIntroFunc();
            this.onTogglePaymentScheduleTypeClicked(isRecurring);
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.recurringPaymentsIntro, { seen: true });
        },

        getPayments() {
            return this.paymentsContainer.payments;
            // commented for v1 - leave here
            // var payments = this.paymentsContainer.payments;
            // var paymentsLength = payments.length;
            //
            // if (!this.paymentsContainer.isRecurringPayment()) {
            //     return payments;
            // }
            //
            // var paymentsToShow = !!this.recurringPaymentsExpanded ? 5 : 2;
            // var startIndex = paymentsLength < paymentsToShow ? 0 : paymentsLength - paymentsToShow;
            // return payments.slice(startIndex, paymentsLength);
        },

        getRecurringStartDate() {
            return this.UIUtils.formatDate(this.paymentsContainer.recurring_payment.start_date, 'Do');
        },

        shouldShowPaymentTypeToggle() {
            return this.allowRecurringPaymentEdit() &&
                this.isOwnerMode &&
                this.isEditableMode &&
                !this.isPreviewMode;
        },

        shouldShowPaymentsIntroFunc() {

            // Show intro only on new files
            // that were created after the feature was launched
            // and only for the first time they see this option

            if (this.templateMode) {
                return false;
            }

            // if not enable payments type toggle, return
            if (!this.shouldShowPaymentTypeToggle()) {
                return false;
            }

            // if file created before recurring feature was launched, return
            var recurringLaunchDate = this.FeaturesService.features.recurringPayments;
            var fileCreatedDate = this.fileModel.created_at;
            if (!this.moment(fileCreatedDate).isAfter(this.moment(recurringLaunchDate))) {
                return false;
            }

            return !this.paymentsIntroSeen;
        }
    });

    return {
        scope: {
            fileModel: '=?fileModel',
            companyModel: '=companyModel',
            paymentsContainerModel: '=paymentsContainerModel',
            templateMode: '=?',
            isInWorkspaceView: '=isInWorkspaceView',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isViewOnlyMode: '=isViewOnlyMode',
            isEditableMode: '=isEditableMode',
            isWaitingForAccept: '=isWaitingForAccept',
            hideOptions: '<'
        },
        templateUrl: 'angular/app/modules/core/features/payments/payments_container_directive_template.html',
        controller: PaymentsContainerDirectiveController,
        controllerAs: 'paymentsContainerVm',
        bindToController: true,
        link: function (scope, element) {
            scope.scrollToPayments = function scrollToPayments() {
                return element.scrollParent().scrollTo(null, element.offset().top - 150, 300);
            };
        }
    };
};