Directives.StatePickerDirective = function StatePickerDirective() {

    var US_STATES = [
        {
            "name": "Alabama",
            "abbreviation": "AL"
        },
        {
            "name": "Alaska",
            "abbreviation": "AK"
        },
        {
            "name": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "name": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "name": "California",
            "abbreviation": "CA"
        },
        {
            "name": "Colorado",
            "abbreviation": "CO"
        },
        {
            "name": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "name": "Delaware",
            "abbreviation": "DE"
        },
        {
            "name": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "name": "Florida",
            "abbreviation": "FL"
        },
        {
            "name": "Georgia",
            "abbreviation": "GA"
        },
        {
            "name": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "name": "Idaho",
            "abbreviation": "ID"
        },
        {
            "name": "Illinois",
            "abbreviation": "IL"
        },
        {
            "name": "Indiana",
            "abbreviation": "IN"
        },
        {
            "name": "Iowa",
            "abbreviation": "IA"
        },
        {
            "name": "Kansas",
            "abbreviation": "KS"
        },
        {
            "name": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "name": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "name": "Maine",
            "abbreviation": "ME"
        },
        {
            "name": "Maryland",
            "abbreviation": "MD"
        },
        {
            "name": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "name": "Michigan",
            "abbreviation": "MI"
        },
        {
            "name": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "name": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "name": "Missouri",
            "abbreviation": "MO"
        },
        {
            "name": "Montana",
            "abbreviation": "MT"
        },
        {
            "name": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "name": "Nevada",
            "abbreviation": "NV"
        },
        {
            "name": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "name": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "name": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "name": "New York",
            "abbreviation": "NY"
        },
        {
            "name": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "name": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "name": "Ohio",
            "abbreviation": "OH"
        },
        {
            "name": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "name": "Oregon",
            "abbreviation": "OR"
        },
        {
            "name": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "name": "Puerto Rico",
            "abbreviation": "PR"
        },
        {
            "name": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "name": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "name": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "name": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "name": "Texas",
            "abbreviation": "TX"
        },
        {
            "name": "Utah",
            "abbreviation": "UT"
        },
        {
            "name": "Vermont",
            "abbreviation": "VT"
        },
        {
            "name": "Virginia",
            "abbreviation": "VA"
        },
        {
            "name": "Washington",
            "abbreviation": "WA"
        },
        {
            "name": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "name": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "name": "Wyoming",
            "abbreviation": "WY"
        }
    ];

    var CANADA_STATES = [

        {
            "name": "Ontario",
            "abbreviation": "ON"
        },
        {
            "name": "Quebec",
            "abbreviation": "QC"
        },
        {
            "name": "Nova Scotia",
            "abbreviation": "NS"
        },
        {
            "name": "New Brunswick",
            "abbreviation": "NB"
        },
        {
            "name": "Manitoba",
            "abbreviation": "MB"
        },
        {
            "name": "British Columbia",
            "abbreviation": "BC"
        },
        {
            "name": "Prince Edward Island",
            "abbreviation": "PE"
        },
        {
            "name": "Saskatchewan",
            "abbreviation": "SK"
        },
        {
            "name": "Alberta",
            "abbreviation": "AB"
        },
        {
            "name": "Newfoundland and Labrador",
            "abbreviation": "NL"
        },
        {
            "name": "Nunavut",
            "abbreviation": "NU"
        },
        {
            "name": "Northwest Territories",
            "abbreviation": "NT"
        },
        {
            "name": "Yukon",
            "abbreviation": "YT"
        }
    ];

    // @ngInject
    var StatePickerDirectiveControllerFunc = function StatePickerDirectiveControllerFunc($scope, $timeout, $injector, _, uuid4) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'StatePickerDirectiveController';
        this.states = US_STATES;
        this.dropElementId = uuid4.generate();

        this.updateStatesForCountry();

        this.states = _.sortBy(this.states, 'name');

        this.matchSelectedFromAbbr = function matchSelectedFromAbbr(){
            if(this.selectedState && this.selectedState !== this.emptyState.abbreviation){
                for(var i = 0; i < this.states.length; i++){
                    if(this.selectedState.toLowerCase() === this.states[i].abbreviation.toLowerCase()){
                        this.currentlySelected = this.states[i].name;
                        this.nativeCurrentlySelected = this.states[i];
                    }
                }
            }
        };

        var self = this;

        this.emptyState = {
            name: "Select State",
            abbreviation: ""
        };

        this.stateSelected = function stateSelected(state){
            if (state) {
                this.currentlySelected = state.name;
                this.selectedState = state.abbreviation;
                if (this.onChange) {
                    $timeout(function change() {
                        self.onChange();
                    });
                }
            }
        };

        $scope.$watch('statepickerVm.selectedState', function(newVal, oldVal){

            if (!newVal) {
                this.currentlySelected = this.emptyState.name;
            }
            else if(oldVal !== newVal){
                self.matchSelectedFromAbbr();
            }
        }.bind(this));

        $scope.$watch('statepickerVm.selectedCountry', function(newVal, oldVal){

            if(oldVal !== newVal || !newVal){
                this.currentlySelected = this.emptyState.name;
                self.updateStatesForCountry();
            }
        }.bind(this));

        this._allowNoSelection = (this.allowNoSelection === "true");

        this.currentlySelected = this.emptyState.name;
        this.matchSelectedFromAbbr();
    };

    var StatePickerDirectiveController = Class(Controllers.BaseController, {
        constructor: StatePickerDirectiveControllerFunc,

        updateStatesForCountry: function updateStatesForCountry() {
            if (this.selectedCountry) {
                switch(this.selectedCountry.toLowerCase()) {
                    case "ca":
                        this.states = CANADA_STATES;
                        break;
                    case "us":
                    default:
                        this.states = US_STATES;
                        break;
                }
            }
            else if (this.nonUsStates) {
                this.states = this.states.concat(CANADA_STATES);
                }
            }
    });
    return {
        scope: {
            allowNoSelection: '@allowNoSelection',
            selectedState: '=selectedState',
            onChange: '&?statepickerOnChange',
            disablePicker: '=disablePicker',
            native: '=?native',
            nonUsStates: '=?',
            shortHand: '=?',
            selectedCountry: '=?'
        },
        templateUrl: 'angular/app/modules/common/ui_components/statepicker/statepicker_directive_template.html',
        controllerAs: 'statepickerVm',
        controller: StatePickerDirectiveController,
        bindToController: true
    };
};