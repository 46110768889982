(function () {
    "use strict";

    Services.RescheduleProjectService = class RescheduleProjectService {

        // @ngInject
        constructor(EventsManager, ModalService, ToastService) {
            this.EventsManager = EventsManager;
            this.ModalService = ModalService;
            this.ToastService = ToastService;

            this.selectedFiles = {};
            this.newlyEditableFiles = [];

            this.CHOOSE_CONTRACT_TYPES = {
                existing: 'existing',
                amendment: 'amendment',
                new: 'newContract',
                noChanges: 'noChanges'
            };
        }

        userCanReschedule(currentUser, workspace) {
            const isWorkspaceOwner = workspace && workspace.creator_id === currentUser._id;
            let isAdminTeamMember = false;

            if(workspace && !isWorkspaceOwner) {
                isAdminTeamMember = currentUser.hasCompanyAdminPermissions();
            }

            return isWorkspaceOwner || isAdminTeamMember;
        }

        setProject(project) {
            this.project = project;
        }

        openRescheduleModal(project, workspaceId, isUnpause, source) {
            this.ModalService.openRescheduleModal(project._id, workspaceId, isUnpause, source).then((wasEmailSent) => {
                this.ToastService.showRescheduleSuccessToast(project, workspaceId, wasEmailSent);
            });
        }

        reset() {
            this.setProject(null);
            this.selectedFiles = {};
            this.newlyEditableFiles.splice(0);
            this.createdContract = null;
            this.editContractChoice = null;
            this.existingContractChoice = null;
        }

        getProject() {
            return this.project;
        }

        getProjectData() {
            return this.project && this.EventsManager.formatEventDataForAPIWrite(this.project);
        }

        addSelectedFile(fileId) {
            this.selectedFiles[fileId] = true;
        }

        removeSelectedFile(fileId) {
            this.selectedFiles[fileId] = false;
        }

        wasFileSelected(fileId) {
            return !angular.isUndefined(this.selectedFiles[fileId]);
        }

        isFileSelected(fileId) {
            return !!this.selectedFiles[fileId];
        }

        getSelectedFiles(files) {
            return files.filter(f => this.isFileSelected(f._id));
        }

        getUnselectedFiles(files) {
            return files.filter(f => !this.isFileSelected(f._id));
        }

        hasCreatedContract() {
            return !!this.createdContract;
        }

        getCreatedContract() {
            return this.createdContract;
        }

        setCreatedContract(file) {
            this.createdContract = file;
        }

        getFilesToReview(files) {
            const allFiles = this.getSelectedFiles(files);
            if (this.hasCreatedContract()) {
                allFiles.push(this.getCreatedContract());
            }

            return allFiles;
        }

        setEditContractSelection(selection) {
            this.editContractChoice = selection;
        }

        getEditContractChoice() {
            return this.editContractChoice;
        }

        setExistingContractChoice(selection) {
            this.existingContractChoice = selection;
        }

        getExistingContractChoice() {
            return this.existingContractChoice;
        }

        makeFileEditable(file) {
            if (!this.wasDraftCreatedForFile(file)) {
                this.newlyEditableFiles.push(file);
            }

            return file.discardAndCreateDraft();
        }

        makeFileNonEditable(file) {

            const index = this.newlyEditableFiles.indexOf(file);
            if (index === -1) {
                return;
            }

            this.newlyEditableFiles.splice(index, 1);
            return file.discardFileDraft();
        }

        wasDraftCreatedForFile(file) {
            return this.newlyEditableFiles.indexOf(file) !== -1;
        }

        getNewlyEditableFiles() {
            return this.newlyEditableFiles;
        }
    };
}());
