Directives.OtamBannerDirective = function OtamBannerDirective() {
    // @ngInject
    function OtamBannerControllerConstructor($scope, $injector, _, $translate, ModalService, OtamStatusService, TemplatesRepositoryManager, FlowsManager, AnalyticsService,
                                             FlowService, FlowsBetaUserTypeService, TemplatesViewService, WorkspaceFileService, UsersManager, FeaturesService, DeviceService, $interval) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'OtamBannerController';
        this._ = _;
        this.$scope = $scope;
        this.$interval = $interval;
        this.TemplatesRepositoryManager = TemplatesRepositoryManager;
        this.$translate = $translate;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.FlowService = FlowService;
        this.FlowsManager = FlowsManager;
        this.OtamStatusService = OtamStatusService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.TemplatesViewService = TemplatesViewService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.FeaturesService = FeaturesService;
        this.user = UsersManager.getCurrUser();
        this.OtamStatusService.initializeAfterLogin();
        this.isDesktop = !DeviceService.isIpadScreenSizeOrSmaller();
        if(this.OtamStatusService.isMigrationEnabledExp()){
            this.initMigrationEnabledExp();
        } else if(this.FlowsBetaUserTypeService.hasFlows){
            this.initAfterMigrationExp();
        }
    }

    var OtamBannerController = Class(Controllers.BaseController, {
        constructor: OtamBannerControllerConstructor,

        initMigrationEnabledExp: function initMigrationEnabledExp(){
            this.originFromArrows = false;
            this.currentIndexStage = 0;
            const extraStepByType = [
                {
                    type:'invoice',
                    details: {
                        primaryTitle: 'This invoice could be smarter',
                        secondaryTitle: 'Invoices can now automatically populate your client\'s selections.',
                        img: 'https://res.cloudinary.com/honeybook/image/upload/v1644481765/honeybook_cdn/otam/legacy_files/Invoice.svg'
                    }
                },
                {
                    type:'proposal',
                    details: {
                        primaryTitle: 'Send more convincing proposals',
                        secondaryTitle: 'Impress clients by adding a brochure or portfolio to your proposal.',
                        img: 'https://res.cloudinary.com/honeybook/image/upload/v1644481765/honeybook_cdn/otam/legacy_files/Proposal.svg'
                    }
                },
                {
                    type:'questionnaire',
                    details: {
                        primaryTitle: 'Ask once, remember forever',
                        secondaryTitle: 'Client information can now get pulled from questionnaires and added to things such as contracts or invoices so you never miss a detail.',
                        img: 'https://res.cloudinary.com/honeybook/image/upload/v1644481765/honeybook_cdn/otam/legacy_files/Questionnaire.svg'
                    }
                },
                {
                    type:'agreement',
                    details: {
                        primaryTitle: 'Imagine contracts that are anything but static and stuffy',
                        secondaryTitle: 'Contracts can now auto-populate fields with services, pricing, and client details.',
                        img: 'https://res.cloudinary.com/honeybook/image/upload/v1644481765/honeybook_cdn/otam/legacy_files/Contract.svg'
                    }
                },
                {
                    type:'brochure',
                    details: {
                        primaryTitle: 'What if brochures showed off more of your personality?',
                        secondaryTitle: 'Create brochures that communicate your brand vibe using all-new layout and design capabilities.',
                        img: 'https://res.cloudinary.com/honeybook/image/upload/v1644481765/honeybook_cdn/otam/legacy_files/Brochure.svg'
                    }
                }
            ];
            this.TemplatesRepositoryManager.fetchTemplateRecords({id:['620b8d521af3ae007f66f701', '620b8e7f1af3ae00b52c0893', '620b8ee21af3ae00b52c0918']}).then(function loadTemplates(resp){
                this.templates = resp.data;
                this.templates[0].actionTags = "Questionnaire";
                this.templates[1].actionTags = "Contract, Invoice";
                this.templates[2].actionTags = "Services, Contract, Invoice";
            }.bind(this));
            this.stages = [
                {
                    primaryTitle: 'Smarter files are here 🎉',
                    secondaryTitle: 'Everything you love about files, but better. Turn your files into smart files for more design, style, and configuration options.',
                    img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1644481765/honeybook_cdn/otam/legacy_files/1.gif'
                },
                {
                    primaryTitle: 'You\'re not starting from scratch',
                    secondaryTitle: 'We\'ll copy your existing templates and get them ready for all the new possibilities.',
                    img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1644481768/honeybook_cdn/otam/legacy_files/2.png'
                },
                {
                    primaryTitle: 'Take your files with you',
                    secondaryTitle: 'You can still use your legacy files as before, tried and true. But they\'re also ready to work smarter and harder.',
                    img: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1644481770/honeybook_cdn/otam/legacy_files/3.png'
                }
            ];
            const extraStep = extraStepByType.find(function(step) { return step.type === this.fileType; }.bind(this)).details;
            this.stages.splice(1,0, extraStep);
            var carouselInterval = this.$interval(function () {
                this.slideCarousel();
            }.bind(this), 7000);
            this.$scope.$on('$destroy', function () {
                this.$interval.cancel(carouselInterval);
            }.bind(this));
        },

        initAfterMigrationExp: function afterMigrationExp(){
          const textByType = [
              {
                  type: 'invoice',
                  textsOptions: [
                      'Show your brand! Customize your invoice title, design, and more using Smart Files.',
                      'Add important details. Use custom invoice labels to show taxes, service fees, and more.',
                      'No missed payments. Set recurring schedules for your clients—daily, weekly, monthly...'
                  ]
              },
              {
                  type: 'agreement',
                  textsOptions: [
                      'So efficient! Contracts are auto-filled with project details, client selections, and more.'
                  ]
              },
              {
                  type: 'proposal',
                  textsOptions: [
                      'Show your brand! Customize your invoice title, design, and more using Smart Files.',
                      'Add important details. Use custom invoice labels to show taxes, service fees, and more.',
                      'No missed payments. Set recurring schedules for your clients—daily, weekly, monthly...',
                      'Impress your clients. Put together professional proposals that’ll get them ordering.'
                  ]
              },
              {
                  type: 'brochure',
                  textsOptions: [
                      'Showcase your services. Customize brochures so clients can view, select, & even pay.',
                      'Take control. Customize brochure layout, manage how services look, and more. '
                  ]
              },
              {
                  type: 'questionnaire',
                  textsOptions: [
                      'Need some answers? Ask your clients questions, then check out the summary report.'
                  ]
              }
          ];
          const allTypesText = [
              'Feeling inspired? Go for it. Change the design & layout of invoices, contracts, and more.',
              'Show your stuff! Create your own style by using any combo of design styles & layouts.'
          ];
          const textsOptions = textByType.find(function(data) { return data.type === this.fileType; }.bind(this)).textsOptions.concat(allTypesText);
          const randomIndex = this._.random(0, textsOptions.length - 1);
          this.bannerText = textsOptions[randomIndex];
        },

        openTeamMembersOtamModal: function openTeamMembersOtamModal(){
            this.AnalyticsService.trackClick(this, 'team member banner', { app_path: this.targetType === 'flow_template' ? 'template' : 'draft' });
            this.ModalService.openTeamMembersOtamModal();
        },

        slideAfter: function slideAfter(stopCarousel){
            this.originFromArrows = stopCarousel;
            this.slideLeft = false;
            this.slideRight = true;
            this.currentIndexStage = this.currentIndexStage < this.stages.length - 1 ? this.currentIndexStage + 1 : 0;
        },

        slideCarousel: function slideCarousel(){
            if(!this.stopCarousel && !this.originFromArrows) {
                this.slideAfter(this.originFromArrows);
            }
        },

        slideBefore: function slideBefore(stopCarousel){
            this.originFromArrows = stopCarousel;
            this.slideLeft = true;
            this.slideRight = false;
            this.currentIndexStage = this.currentIndexStage > 0 ? this.currentIndexStage - 1 : this.stages.length - 1;
        },

        hoverOn :function hoverOn(){
            this.stopCarousel = true;
        },

        hoverOff :function hoverOff(){
            this.stopCarousel = false;
        },

        previewTemplate: function previewTemplate(temp){
            this.FlowService.goToTemplateGalleryPreviewNewTab(temp.published_template_id, 1);
        },
        seeLiveExamples: function seeLiveExamples(value){
            this.isShowExamples = value;
        },

        convertFileToFlow: function convertFileToFlow(){
            if(this.targetType === 'flow_template'){
                this.convertTemplateToFlow();
            } else {
                this.convertInstanceToFlow();
            }
        },

        convertTemplateToFlow: function convertTemplateToFlow() {
            if(this.TemplatesViewService.isMigrateTemplateToFlowInProgress) {
                return;
            }
           
            this.TemplatesViewService.convertTemplateToFlow();
        },

        convertInstanceToFlow: function convertInstanceToFlow() {
            this.parent = this.$scope.$parent.workspaceFileVm || this.$scope.$parent.workspaceFileBrochureVm;
            this.workspaceFile = this.parent.workspaceFile;
            if(this.parent.isMigrateFileToFlowInProgress) {
                return;
            }
           
            this.parent.isMigrateFileToFlowInProgress = true;
            const fileType = this.getFileTypeToMigrate(this.fileType);
            this.FlowsManager.convertFileTemplateToFlow(this.fileId, fileType, this.fileTitle, this.targetType).then(function(response) {
                this.workspaceFile.updateAutoExpiration("four_weeks");
                this.FlowService.goToFlow(response.data._id, 'edit', {location: "replace"});
            }.bind(this)).finally(function() {
                this.parent.isMigrateFileToFlowInProgress = false;
            }.bind(this));
        },
        getFileTypeToMigrate: function getFileTypeToMigrate(type) {
            return type === 'agreement' ? 'contract' : type;
        },

    });

    return {
        templateUrl: 'angular/app/modules/core/features/workspace_file/otam_banner/otam_banner_directive_template.html',
        controller: OtamBannerController,
        controllerAs: 'OtamBannerVm',
        bindToController: true,
        scope:{
            fileId:'=',
            fileType:'=',
            fileTitle:'=',
            targetType:'='
        }
    };
};
