(function () {
    "use strict";

    Controllers.ClientPortalController = class ClientPortalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, FeaturesService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalController';
            this.StatsigService = StatsigService;
            this.currUser = UsersManager.getCurrUser();
            this.company = this.currUser.company;
            this.wasInitialUrlClaimed = this.company.company_url_prefixes && this.company.company_url_prefixes.length;
            this.isNewClientPortalActive = this.wasInitialUrlClaimed;
        }
    };

}());





