(function () {
    'use strict';

    const SEND_INTERVAL = 1000 * 60; // 1 minute interval
    Services.AngularControllerUsageService = class AngularControllerUsageService {

        // @ngInject
        constructor($injector, $timeout, $window, _, AppConfigService) {
            this.$timeout = $timeout;
            this._ = _;
            this.$injector = $injector;
            this.$window = $window;

            this.supportWebWorkers = !!$window.Worker;
            this.isProduction = AppConfigService.isProduction();
            this.resetCache();
            this.$timeout(() => this.sendUsageData(), SEND_INTERVAL);

            // make one last call before the tab is closed or a full refresh is issues
            $window.addEventListener("beforeunload", e => this.sendUsageData());
        }

        resetCache() {
            this.usageCache = {};
            this.startTime = (new Date).toJSON();
        }

        registerUsage(controller) {
            this.$timeout(() => { // setTimeout because we need to let the controller's constructor to finish execution
                const objectType = controller.__objectType;
                const currEntry = this.usageCache[objectType] = this.usageCache[objectType] || {
                    count: 0,
                    appPaths: {}
                };
                let $scope = controller.$scope;
                let path = objectType;
                let lastControllerName = objectType;
                while ($scope) {
                    const parentController = $scope[this._.find(Object.keys($scope), key => key.match(/Vm$/) || key === '$ctrl')];
                    if (parentController) {
                        const parentObjectType = parentController.__objectType;
                        if (parentObjectType !== lastControllerName) {
                            lastControllerName = parentObjectType;
                            path = `${parentObjectType}/${path}`;
                        }
                    }
                    $scope = $scope.$parent;
                }
                currEntry.appPaths[path] = currEntry.appPaths[path] || 0;
                currEntry.appPaths[path]++;
                currEntry.count++;
            });
        }

        getUserId() {
            if (!this.UsersManager) {
                try {
                    this.UsersManager = this.$injector.get('UsersManager');
                } catch (e) {
                    // ignore
                }
            }
            const user = this.UsersManager && this.UsersManager.getCurrUser();
            return user && user._id;
        }

        sendUsageData() {
            if (this.isProduction && this.supportWebWorkers && !this._.isEmpty(this.usageCache)) {
                const data = {
                    user_id: this.getUserId() || 'N/A',
                    startTime: this.startTime,
                    endTime: (new Date).toJSON(),
                    usage: this.usageCache
                };
                if (!this.streamsWorker) {
                    this.streamsWorker = new this.$window.Worker("/streams-web-worker.js");
                }

                this.streamsWorker.postMessage({streamName: 'directive-usage', message: data});
            }
            this.resetCache();

            this.$timeout(() => this.sendUsageData(), SEND_INTERVAL);
        }
    };
})();
