/**
 * Created by dvircohen on 12/07/2017.
 */
// @ngInject
Classes.AgreementValuesFileStrategy = function(moment, AgreementValuesStrategy) {

    var AgreementValuesFileStrategy = Class(AgreementValuesStrategy, {

        constructor: function AgreementValuesFileStrategyCtor(agreementParent) {
            this.agreementParent = agreementParent;
            this.agreementParentPresent = this.agreementParent !== null && this.agreementParent !== undefined;
        },

        // ============================================
        // Project fields
        // ============================================

        getEventType: function getEventType() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_type || '';
        },

        getEventDate: function getEventDate() {
            return this.agreementParentPresent && this.agreementParent.event && this.formatDate(this.agreementParent.event.event_date) || 'TBD';
        },

        getEventEndDate: function getEventEndDate() {
            return this.agreementParentPresent && this.agreementParent.event && this.formatDate(this.agreementParent.event.event_end_date) || '';
        },

        getEventLocation: function getEventLocation() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_location || '';
        },

        getEventVenueName: function getEventVenueName() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_location || '';
        },

        getEventName: function getEventName() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_name || '';
        },

        getEventTimeStart: function getEventTimeStart() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_time_start || '';
        },

        getEventTimeEnd: function getEventTimeEnd() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_time_end || '';
        },

        getEventGuestsNumber: function getEventGuestsNumber() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_guests || '';
        },

        getEventBudget: function getEventBudget() {
            return this.agreementParentPresent && this.agreementParent.event && this.agreementParent.event.event_budget || '';
        },

        // ============================================
        // Proposal fields
        // ============================================

        getProposalTitle: function getProposalTitle() {
            return this.agreementParentPresent && this.agreementParent.file_title || '';
        },

        getProposalTotalPrice: function getProposalTotalPrice() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.total_price) || '';
        },

        getProposalSubTotalPrice: function getProposalSubTotalPrice() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.sub_total) || '';
        },

        getProposalTax: function getProposalTax() {
            return this.agreementParentPresent && this.formatMoney(this.agreementParent.vendor_proposal.total_tax) || '';
        },

        // ============================================
        // Payments fields
        // ============================================

        getFirstPaymentAmount: function getFirstPaymentAmount() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[0] && this.formatMoney(this.agreementParent.payments_container.payments[0].amount) || '';
        },

        getFirstPaymentDueDate: function getFirstPaymentDueDate() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[0] && this.formatDate(this.agreementParent.payments_container.payments[0].due_date) || '';
        },

        getSecondPaymentAmount: function getSecondPaymentAmount() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[1] && this.formatMoney(this.agreementParent.payments_container.payments[1].amount) || '';
        },

        getSecondPaymentDueDate: function getSecondPaymentDueDate() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[1] && this.formatDate(this.agreementParent.payments_container.payments[1].due_date) || '';
        },

        getLastPaymentAmount: function getLastPaymentAmount() {
            var lastPaymentIdx = this.agreementParent.payments_container.payments.length - 1;
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[lastPaymentIdx] && this.formatMoney(this.agreementParent.payments_container.payments[lastPaymentIdx].amount) || '';
        },

        getLastPaymentDueDate: function getLastPaymentDueDate() {
            var lastPaymentIdx = this.agreementParent.payments_container.payments.length - 1;
            return this.agreementParentPresent && this.agreementParent.payments_container.payments[lastPaymentIdx] && this.formatDate(this.agreementParent.payments_container.payments[lastPaymentIdx].due_date) || '';
        },

        getMilestonePayments: function getMilestonePayments() {
            return this.agreementParentPresent && this.agreementParent.payments_container.payments.filter(function(item){ return item.isMilestonePayment() }.bind(this));
        },

        // ============================================
        // Agreement fields
        // ============================================

        getAgreementDate: function getAgreementDate() {
            return moment().format('MMM DD, YYYY');
        },

        // ============================================
        // Company fields
        // ============================================

        getCompanyTax: function getCompanyTax() {
            return this.agreementParentPresent && this.agreementParent.company && this.agreementParent.company.default_tax || '';
        },

        getCompanyName: function getCompanyName() {
            return this.agreementParentPresent && this.agreementParent.company && this.agreementParent.company.company_name || '';
        },

        getCompanyType: function getCompanyType() {
            return this.agreementParentPresent && this.agreementParent.company && this.agreementParent.company.company_type_name || '';
        },

        getCompanyEmail: function getCompanyEmail() {
            return this.agreementParentPresent && this.agreementParent.company && this.agreementParent.company.email || '';
        },

        getCompanyPhoneNumber: function getCompanyPhoneNumber() {
            return this.agreementParentPresent && this.agreementParent.company && this.agreementParent.company.phone_number || '';
        },

        // ============================================
        // Client fields
        // ============================================

        getClientFullName1: function getClientFullName1() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(0, 'full_name');
        },

        getClientFirstName1: function getClientFirstName1() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(0, 'first_name');
        },

        getClientEmail1: function getClientEmail1() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(0, 'email');
        },

        getClientAddress1: function getClientAddress1() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(0, 'address');
        },

        getClientPhoneNumber1: function getClientPhoneNumber1() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(0, 'phone_number');
        },

        getClientFullName2: function getClientFullName2() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(1, 'full_name');
        },

        getClientFirstName2: function getClientFirstName2() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(1, 'first_name');
        },

        getClientEmail2: function getClientEmail2() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(1, 'email');
        },

        getClientAddress2: function getClientAddress2() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(1, 'address');
        },

        getClientPhoneNumber2: function getClientPhoneNumber2() {
            if (!this.agreementParentPresent) {
                return '';
            }

            return this.getNthUserProperty(1, 'phone_number');
        },

        getNthUserProperty: function getNthUserProperty(n, prop) {
            var clientsAndVendors = [].concat(this.agreementParent.getClients()).concat(this.agreementParent.getNonTeamMembersVendors());
            return (clientsAndVendors[n] && clientsAndVendors[n][prop]) || '';
        }
    });

    return AgreementValuesFileStrategy;
};