(function () {
    "use strict";

    // @ngInject
    function EventWrapperControllerCtor($scope, $state, $stateParams, $injector, AppStates, NavigationService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EventController';

        this.NavigationService = NavigationService;

        $scope.$on('$destroy', function onDestroy(){

        });
    }


    Controllers.EventWrapperController = Class(Controllers.BaseController, {
        constructor: EventWrapperControllerCtor
    });
}());
