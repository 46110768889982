common_module = angular.module('honeybook.common', [
    'validation',
    'validation.rule',
    'duScroll',
    'mm.foundation',
    'ngFileUpload',
    'ui.sortable',
    'uuid4',
    'cloudinary',
    'rzModule',
    'ngSanitize',
    'drop-ng',
    'infinite-scroll',
    'honeybook.common.components',
    'ngTagsInput',
    'djds4rce.angular-socialshare',
    '720kb.socialshare',
    'angular-inview',
    'ngclipboard',
    'ngToast',
    'ui.bootstrap',
    'hmTouchEvents',
    'ngStorage',
    'ngCookies'
]);

//setup default element that will be added to DOM in case of form field validation error
// @ngInject
common_module.config(function ($validationProvider) {
    $validationProvider.setErrorHTML(function (msg) {

        return '<label class="error">' + msg + '</label>';
    });

    $validationProvider.showSuccessMessage = false;

});

//config default values for tooltips
// @ngInject
common_module.config(function ($tooltipProvider) {
    $tooltipProvider.options({
        animation: false
    });

});

//config $http interceptors
// @ngInject
common_module.config(function ($httpProvider) {
    $httpProvider.interceptors.push('DuplicateCallsPreventionInterceptor', 'HttpMetadataInterceptor', 'HttpAuthInterceptor', 'jsonpInterceptor', 'ClientsApiSeparationInterceptor');
});

//config ngToast
// @ngInject
common_module.config(['ngToastProvider', function(ngToast) {
    ngToast.configure({
        animation: 'slide',
        additionalClasses: 'angular-animate nx-toast',
        timeout: 3000,
        dismissOnClick: false,
        combineDuplications: true,
        dismissButtonHtml: '<span class="icon icon-hb-nx-close-16"></span>'
    });
}]);

// make sure first/last navigation items are active when scrolled all the way to the top/bottom
common_module.value('duScrollGreedy', true);

//third party wrappers (to maintain testability)
common_module.factory('Routes', Services.jsRoutes);
common_module.factory('Mixpanel', Services.mixpanel);
common_module.factory('Intercom', Services.intercom);
common_module.factory('Upscope', Services.upscope);
common_module.factory('Gon', Services.gon);
common_module.factory('intuit', Services.intuit);
common_module.factory('_', Services.underscore);
common_module.factory('ObjectPath', Services.ObjectPath);
common_module.factory('autosize', Services.autosize);
common_module.factory('acrossTabs', Services.acrossTabs);
common_module.factory('$', Services.jquery);
common_module.factory('moment', Services.momentJS);
common_module.factory('jStorage', Services.jStorage);
common_module.factory('jstz', Services.jstz);
common_module.factory('Stripe', Services.stripe);
common_module.factory('CKEDITOR', Services.CKEDITOR);
common_module.factory('CkEditor5', Services.CkEditor5);
common_module.factory('Spectrum', Services.spectrum);
common_module.factory('sha256', Services.sha256);
common_module.factory('google', Services.GoogleApi);
common_module.factory('googleAccounts', Services.GoogleAccounts);
common_module.factory('firebase', Services.Firebase);
common_module.factory('HttpAuthInterceptor', Services.HttpAuthInterceptor);
common_module.factory('HttpMetadataInterceptor', Services.HttpMetadataInterceptor);
common_module.factory('DuplicateCallsPreventionInterceptor', Services.DuplicateCallsPreventionInterceptor);
common_module.factory('ClientsApiSeparationInterceptor', Services.ClientsApiSeparationInterceptor);
common_module.factory('FS', Services.FS);
common_module.factory('Modernizr', Services.Modernizr);
common_module.factory('Plaid', Services.plaid);
common_module.factory('Drop', Services.drop);
common_module.factory('Fingerprint', Services.Fingerprint);
common_module.factory('websocket', Services.websocket);
common_module.factory('pnotify', Services.pnotify);
common_module.factory('Rellax', Services.Rellax);
common_module.factory('Tippy', Services.Tippy);
common_module.factory('RecaptchaService', Services.RecaptchaService);
common_module.factory('Qualtrics', Services.qualtrics);
common_module.factory('Maxmind', Services.Maxmind);

//$http interceptors
//we need this one to change the default JSONP callback name - since some servers don't like it...
common_module.factory('jsonpInterceptor', Services.JsonpInterceptor);

//services
common_module.factory('AutosizeTextareaUpdater', Services.AutosizeTextareaUpdater);
common_module.factory('ExtendService', Services.ExtendService);
common_module.service('StatsigService', Services.StatsigService);
common_module.service('AnalyticsService', Services.AnalyticsService);
common_module.service('APIService', Services.APIService);
common_module.service('AngularControllerUsageService', Services.AngularControllerUsageService);
common_module.service('WebPNService', Services.WebPNService);
common_module.service('AppConfigService',  Services.AppConfig);
common_module.service('AutoInputSizeService', Services.AutoInputSizeService);
common_module.service('AvatarService', Services.AvatarService);
common_module.service('ConfettiService', Services.ConfettiService);
common_module.service('DeviceService', Services.DeviceService);
common_module.service('UrlMetadataService', Services.UrlMetadataService);
common_module.service('Enums', Services.Enums);
common_module.factory('AxiosInterceptorService',Services.AxiosInterceptorService);
common_module.service('TimezoneService', Services.TimezoneService);
common_module.service('FacebookService', Services.FacebookService);
common_module.factory('FB', Services.facebook);
common_module.service('InstagramAPIService', Services.InstagramAPIService);
common_module.service('GoogleAPIService', Services.GoogleAPIService);
common_module.service('FirebaseAPIService', Services.FirebaseAPIService);
common_module.service('YouTubeAPIService', Services.YouTubeAPIService);
common_module.service('IntercomService', Services.IntercomService);
common_module.service('ThirdPartyLoaderService', Services.ThirdPartyLoaderService);
common_module.service('InputFieldsService', Services.InputFieldsService);
common_module.service('ImageService', Services.ImageService);
common_module.service('DoodleService', Services.DoodleService);
common_module.service('CloudinaryConfigService', Services.CloudinaryConfigService);
common_module.service('FullStoryService', Services.FullStoryService);
common_module.service('DatadogRUMService', Services.DatadogRUMService);
common_module.service('DatadogLOGSService', Services.DatadogLOGSService);
common_module.service('ModalService', Services.ModalService);
common_module.service('OnScreenNotificationService', Services.OnScreenNotificationService);
common_module.service('PlaidLinkService', Services.PlaidLinkService);
common_module.service('PopupMessageService', Services.PopupMessageService);
common_module.service('PrintService', Services.PrintService);
common_module.service('PubSubService', Services.PubSubService);
common_module.service('RepositoryService', Services.DataRepository);
common_module.service('StripeHelperService', Services.StripeHelperService);
common_module.service('UIUtils', Services.UIUtils);
common_module.service('ReactModalService', Services.ReactModalService);
common_module.service('WebsocketHelperService', Services.WebsocketHelperService);
common_module.service('ClientCacheService', Services.ClientCacheService);
common_module.service('ServiceLazyLoaderService', Services.ServiceLazyLoaderService);
common_module.service('ForterHelperService', Services.ForterHelperService);
common_module.service('RegexService', Services.RegexService);
common_module.value('Hash', Services.Hash);
common_module.service('ToastService', Services.ToastService);
common_module.service('SplashScreenService', Services.SplashScreenService);
common_module.service('RollingWindowThrottleService', Services.RollingWindowThrottleService);
common_module.service('DateService', Services.DateService);
common_module.service('EnvService', Services.EnvService);
common_module.service('GoogleTagManagerService', Services.GoogleTagManagerService);

common_module.controller('EmailEditorController', Controllers.EmailEditorController);
common_module.controller('ScheduleEmailSendController', Controllers.ScheduleEmailSendController);
common_module.controller('IntroTipController', Controllers.IntroTipController);
common_module.controller('ExpandImageController', Controllers.ExpandImageController);
common_module.controller('TrialExtendedController', Controllers.TrialExtendedController);
common_module.controller('ChangeSubscriptionController', Controllers.ChangeSubscriptionController);
common_module.controller('EditableTagsController', Controllers.EditableTagsController);
common_module.controller('PopupMessageController', Controllers.PopupMessageController);

//directives

common_module.directive('hbEmptyStateScreen', Directives.EmptyStateDirective);
common_module.directive('hbOnScrollBottom', Directives.ScrollBottomDirective);
common_module.directive('hbInlineAlert', Directives.InlineAlertDirective);
common_module.directive('hbUserAvatar', Directives.UserAvatarDirective);
common_module.directive('hbReferFriend', Directives.ReferFriendDirective);
common_module.directive('hbAvatarPopup', Directives.AvatarPopupDirective);
common_module.directive('hbAnalyticsEvent', Directives.AnalyticsDirective);
common_module.directive('hbPopupAlert',  Directives.PopupMessageDirective);
common_module.directive('hbFilmstrip', Directives.FilmstripDirective);
common_module.directive('hbSectionNav', Directives.SectionNavDirective);
common_module.directive('hbFilmstripWithActions', Directives.FilmstripWithActionsDirective);
common_module.directive('hbGenericFilmstrip', Directives.GenericFilmstripDirective);
common_module.directive('hbLoader',  Directives.LoaderDirective);
common_module.directive('hbLocationMap', Directives.LocationMapDirective);
common_module.directive('hbBack',  Directives.HistoryBackDirective);
common_module.directive('hbDatePicker',  Directives.DatePickerDirective);
common_module.directive('hbDateCollision',  Directives.DateCollisionDirective);
common_module.directive('hbTimePicker',  Directives.TimePickerDirective);
common_module.directive('hbFormattedInput', Directives.FormattedInputDirective);
common_module.directive('hbSavingIndicator', Directives.SavingIndicatorDirective);
common_module.directive('hbAutosizeTextarea', Directives.AutosizeTextareaDirective);
common_module.directive('hbBlurOnEnter', Directives.BlurOnEnter);
common_module.directive('hbGenerateTagsList', Directives.GenerateTagsListDirective);
common_module.directive('hbImage', Directives.ImageDirective);
common_module.directive('hbImageEditable', Directives.ImageEditable);
common_module.directive('hbAutoInputSize', Directives.AutoInputSizeDirective);
common_module.directive('hbSlickLightboxContainer', Directives.SlickLightboxContainerDirective);
common_module.directive('hbSlickLightboxItem', Directives.SlickLightboxItemDirective);
common_module.directive('hbToggleElementVisibility', Directives.ToggleElementVisibilityDirective);
common_module.directive('hbFocusInput', Directives.FocusInputDirective);
common_module.directive('hbFileRead', Directives.FileReadDirective);
common_module.directive('hbPrint', Directives.PrintDirective);
common_module.directive('hbStickyHeader', Directives.StickyHeaderDirective);
common_module.directive('hbImageCarouselReposition', Directives.ImageCarouselReposition);
common_module.directive('hbImageReposition', Directives.ImageReposition);
common_module.directive('hbInputAutofillFix', Directives.InputAutofillFixDirective);
common_module.directive('hbIframeOnload', Directives.IframeOnloadDirective);
common_module.directive('hbClickOutside', Directives.ClickOutsideDirective);
common_module.directive('eatClickIf', Directives.EatClickIfDirective);
common_module.directive('hbStatePicker', Directives.StatePickerDirective);
common_module.directive('hbCountryPicker', Directives.CountryPickerDirective);
common_module.directive('hbEmailEditorDropdownPosition', Directives.EmailEditorDropDownPositionDirective);
common_module.directive('hbScrollToElement', Directives.ScrollToElement);
common_module.directive('hbChangeSrcOnHover', Directives.ChangeSrcOnHoverDirective);
common_module.directive('hbDropdownToggle', Directives.DropdownToggleDirective);
common_module.directive('hbJustifiedGallery', Directives.JustifiedGallery);
common_module.directive('hbJustifiedGalleryViewer', Directives.JustifiedGalleryViewer);
common_module.directive('hbJcrop', Directives.JCrop);
common_module.directive('hbColorPicker', Directives.ColorPicker);
common_module.directive('hbVisible', Directives.VisibleDirective);
common_module.directive('hbWindowResize', Directives.WindowResizeDirective);
common_module.directive('hbFacebookLogin', Directives.FacebookLoginDirective);
common_module.directive('hbGoogleLogin', Directives.GoogleLoginDirective);
common_module.directive('hbAppleLogin', Directives.AppleLoginDirective);
common_module.directive('hbBackgroundImage', Directives.BackgroundImage);
common_module.directive('hbHelp', Directives.HelpDirective);
common_module.component('hbAbTestBrowser', Components.AbTestBrowser);
common_module.directive('hbGracefulLoader', Directives.GracefulReloadDirective);
common_module.directive('hbGooglePlacesAutocomplete', Directives.GoogleAutocompleteDirective);
common_module.directive('hbTwoFactorAuth', Directives.TwoFactorAuthDirective);
common_module.directive('hbVisibilityPanel', Directives.VisibilityPanelDirective);
common_module.directive('hbCard', Directives.CardDirective);

common_module.directive('hbPlaceholder', Directives.PlaceholderDirective);
common_module.directive('hbCopyToClipboard', Directives.CopyToClipboardDirective);
common_module.directive('hbHtmlEventListener', Directives.HtmlEventListenerDirective);
common_module.directive('hbCustomizableBranding', Directives.CustomizableBrandingDirective);
common_module.directive('hbEditableTags', Directives.EditableTagsDirective);
common_module.directive('hbDebounce', Directives.InputDebounce);
common_module.directive('hbFocusMe', Directives.FocusMe);
common_module.directive('hbPopover', Directives.PopoverDirective);
common_module.directive('hbChips', Directives.ChipsDirective);
common_module.directive('hbVideoPlayer', Directives.VideoPlayerDirective);
common_module.directive('hbCreateNewPanel', Directives.CreateNewPanel);
common_module.directive('hbFileStatus', Directives.FileStatusDirective);
common_module.directive('hbFlexibleInput', Directives.FlexibleInputDirective);
common_module.directive('hbSelectOnClick', Directives.SelectOnClick);
common_module.directive('hbSelectOnFocus', Directives.SelectOnFocus);
common_module.directive('hbBanner', Directives.BannerDirective);
common_module.directive('hbTabToFocusOnMenu', Directives.TabToFocusOnMenu);
common_module.directive('hbNewCreditCardForm', Directives.NewCreditCardFormDirective);

//NX COMPONENTS
common_module.directive('nxTippy', Directives.TippyDirective);
common_module.directive('hbBalloonEditor', Directives.BalloonCKEditor);
common_module.component('hbClassicEditor', Components.ClassicCKEditor);
common_module.component('nxLabel', Components.LabelComponent);
common_module.directive('hbBasicTooltip', Directives.BasicTooltip);

//filters
common_module.filter('titlecase', Filters.TitleCaseFilter);
common_module.filter('hbcurrency', Filters.HbCurrencyFilter);
common_module.filter('hbthousandSuffix', Filters.HbThousandSuffixFilter);
common_module.filter('orderempty', Filters.OrderEmptyFilter);
common_module.filter('orderObjectBy', Filters.OrderObjectByFilter);
common_module.filter('makerange', Filters.MakeRangeFilter);
common_module.filter('toarray', Filters.ToArrayFilter);
common_module.filter('highlight', Filters.HighlightFilter);
common_module.filter('snakecase', Filters.SnakecaseFilter);
common_module.filter('hbmarker', Filters.HbmarkerFilter);
common_module.filter('hbTrunc', Filters.HbTruncFilter);
common_module.filter('hbjoinArray', Filters.JoinArrayFilter);
common_module.filter('hbtrust', Filters.HbtrustFilter);
common_module.filter('hbdate', Filters.HbDateFilter);
common_module.filter('hbUnSnake', Filters.UndoSnakeCaseFilter);
common_module.filter('reverse', Filters.reverse);
common_module.filter('prettyDecimal', Filters.prettyDecimal);
common_module.filter('hbRelativeTime', Filters.HbRelativeTimeFilter);
common_module.filter('absoluteUrl', Filters.AbsoluteUrlFilter);
common_module.filter('nl2br', ['$sanitize', Filters.NewlineFilter]);
common_module.filter('taxsymbol', Filters.TaxSymbolFilter);
common_module.filter('pluralize', Filters.PluralizeFilter);
common_module.filter('encodeURIComponent', Filters.EncodeURIFilter);
common_module.filter('phoneNumber', Filters.PhoneNumber);
common_module.filter('hblocaleamount', Filters.HbToLocaleAmount);

common_module.run(function commonModuleRun($rootScope, $window, $, CloudinaryConfigService) {
    $rootScope.$on('$stateChangeSuccess', function stateChangedSuccess() {
        setTimeout(function stateChanged() { // Using setTimeout to avoid another digest cycle once the function is executed (this is the behavior of all jquery events).
            angular.element($window).trigger('routechanged');
        });
    });

    // Initializing cloudinary configuration
    $.cloudinary.config().cloud_name = CloudinaryConfigService.getDefaultCloudinaryCloudName();
    $.cloudinary.config().upload_preset = CloudinaryConfigService.getDefaultCloudinaryUploadPreset();
    $.cloudinary.responsive();
});