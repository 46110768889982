/**
 * Created by danielkidon on 11/03/22.
 */

(function () {
    "use strict";

    // @ngInject
    function ClientCacheServiceCtor($window, Gon, WebsocketHelperService, UsersManager, StatsigService) {
        this.$window = $window;
        this.Gon = Gon;
        this.WebsocketHelperService = WebsocketHelperService;
        this.UsersManager = UsersManager;
        this.StatsigService = StatsigService;

        this.HB_CACHE_NAME = 'honeybook-cache';
        this.CLIENT_CACHE_ROOM_PREFIX = 'client_side_cache;';

        // keep track of the most recent invalidation message per endpoint until each stale_data_served event
        // this helps debug websocket issues.
        this.mostRecentInvalidations = {};
        this.sendReadbackOnNextInvalidation = {};

        // we MUST register to messages before sending out a "loaded" message
        // so that the service can "catch" a sw_version message sent from the service worker as a response
        this.registerToIncomingMessageFromServiceWorker();

        // we start out with a disconnect message to make sure local variables in sw are emptied and off
        // subsequent message on "websocket_connected" will "start" the sw cache
        this.notifyServiceWorkerOfDisconnection();
        this.notifyServiceWorkerOfInitialLoad();

        this.UsersManager.on('loggingIn', this.notifyServiceWorkerOfInitialLoad, this);
        this.UsersManager.on('loggingOut', this.tearDownCacheListenersAndSettings, this);
    }

    Services.ClientCacheService = Class(function () {

        return {
            constructor: ClientCacheServiceCtor,

            setupCacheListenersAndSettings: function setupCacheListenersAndSettings() {
                this.StatsigService.isGateEnabled('client_cache').then(clientCacheGateEnabledForUser => {
                    if (!clientCacheGateEnabledForUser) {
                        return;
                    }

                    console.debug(`setting up cache service configuration`);

                    if (this.WebsocketHelperService.isConnected()) {
                        this.getCacheConfigFromGon();
                        this.notifyServiceWorkerOfConnection();
                    }

                    this.WebsocketHelperService.on('websocket_disconnected', this.notifyServiceWorkerOfDisconnection, this);
                    this.WebsocketHelperService.on('websocket_connected', this.getCacheConfigFromGon, this);
                    this.WebsocketHelperService.on('websocket_connected', this.notifyServiceWorkerOfConnection, this);

                    this.registerToAllRooms();
                });
            },

            tearDownCacheListenersAndSettings: function tearDownCacheListenersAndSettings() {
                console.debug(`tearing down cache service configuration`);

                this.WebsocketHelperService.off('websocket_disconnected', null, this);
                this.WebsocketHelperService.off('websocket_connected', null, this);
                this.WebsocketHelperService.off('websocket_connected', null, this);

                this.notifyServiceWorkerOfDisconnection();
                this.unRegisterFromAllRooms();
            },

            sendMessageToServiceWorker: function sendMessageToServiceWorker(type, message) {
                return new Promise(resolve => {
                    if (this.$window.navigator.serviceWorker) {
                        this.$window.navigator.serviceWorker.ready.then(() => {
                            if (this.$window.navigator.serviceWorker.controller) {
                                console.debug("sending message to service worker: ", {[type]: message});
                                resolve(this.$window.navigator.serviceWorker.controller.postMessage({[type]: message}));
                            }
                        });
                    }
                });
            },

            notifyServiceWorkerOfConnection: function notifyServiceWorkerOfConnection() {
                this.sendMessageToServiceWorker("connectionStatus", "connected");
            },

            notifyServiceWorkerOfDisconnection: function notifyServiceWorkerOfDisconnection() {
                this.sendMessageToServiceWorker("connectionStatus", "disconnected");
            },

            notifyServiceWorkerOfInitialLoad: function notifyServiceWorkerOfInitialLoad() {
                this.sendMessageToServiceWorker("loaded", true);
            },

            getCacheConfigFromGon: function getCacheConfigFromGon() {
                const cacheConfig = this.Gon.api_cache_config || {};
                this.sendMessageToServiceWorker("cacheConfig", cacheConfig);
            },

            handleCacheInvalidation: function handleCacheInvalidation(roomUpdate) {
                console.debug(`got invalidation request from websocket: ${roomUpdate}`);

                // caches interface not available on window - nothing to do
                if (!this.$window.caches) {
                    return;
                }

                try {
                    const {data} = JSON.parse(roomUpdate);
                    const {endpoints, prefetch, disableAllCache, invalidation_id} = data;

                    // global flag to enable disabling all cache for user in a session
                    // sent manually by us in case of catastrophic error!
                    if (disableAllCache) {
                        return this.tearDownCacheListenersAndSettings();
                    }

                    const sanitizedEndpoints = (endpoints || [])
                        .map(endpoint => (this.Gon.api_urls_prefix || "") + (endpoint.startsWith('/') ? endpoint : `/${endpoint}`));

                    return this.$window.caches.open(this.HB_CACHE_NAME).then(cache =>
                        this.handleInvalidatedEndpoints(sanitizedEndpoints, cache, prefetch, invalidation_id));

                } catch (err) {
                    console.error(`failed to ascertain which cache to invalidate err => ${err}`);
                    // if we fail to know which cache to invalidate
                    // we should invalidate all the cache to make sure we dont bring back invalidated data
                    return this.invalidateAllCache();
                }
            },

            // handle every endpoint received from invalidation room update
            // for each endpoint to invalidate -
            // get all available cache (disregarding query params) and delete them
            // also optionally send out a new fetch request to re-instate cache (prefetch)
            handleInvalidatedEndpoints: function handleInvalidatedEndpoints(endpoints, cache, prefetch, invalidation_id) {
                const fullPathEndpoints = [];
                const wildcardPathEndpoints = [];
                endpoints.forEach(endpoint => {
                    const hasWildcard = endpoint.includes("/*");

                    if (hasWildcard) {
                        wildcardPathEndpoints.push(endpoint);
                    } else {
                        fullPathEndpoints.push(endpoint);
                    }
                });

                const fullPathPromises = fullPathEndpoints.map(endpoint => {
                    return cache.keys(endpoint, {ignoreSearch: true, ignoreVary: true}).then(requests => {
                        const handleRequestsPromises = requests.map(request => {
                            return this.handleInvalidatedRequest(request, cache, prefetch, invalidation_id);
                        });
                        return Promise.all(handleRequestsPromises);
                    });
                });

                let wildcardPathPromises = cache.keys().then(keys => {
                    const handleRequestsPromises = keys.map(request => {
                        let isMatchingWildcardPath = wildcardPathEndpoints.some(endpoint => {
                            return this.isWildcardPathMatchPath(endpoint, request.url);
                        });
                        if (isMatchingWildcardPath) {
                            return this.handleInvalidatedRequest(request, cache, prefetch, invalidation_id);
                        }
                    });
                    return Promise.all(handleRequestsPromises);
                });

                const allPromises = fullPathPromises.concat(wildcardPathPromises);
                return Promise.all(allPromises);
            },

            isWildcardPathMatchPath: function isWildcardPathMatchPath(wildcardUrl, url) {
                const wildcardPath = this.getPathFromUrl(wildcardUrl);
                const path = this.getPathFromUrl(url);
                const splittedWildcardPath = wildcardPath.split('/');
                const splittedPath = path.split('/');

                if (splittedWildcardPath.length !== splittedPath.length) {
                    return false;
                }
                return splittedWildcardPath.every((item, index) => {
                    return item === '*' || item === splittedPath[index];
                });
            },

            handleInvalidatedRequest: function handleInvalidatedRequest(request, cache, prefetch, invalidation_id) {
                this.manageInvalidationDebugging(request, invalidation_id);

                // delete cache for request and then prefetch if needed
                return cache.delete(request).then(() => {
                    // this will send out an api call identical to what should be invalidated
                    // and the cache mechanism will re-instate cache with new response
                    if (prefetch) {
                        return fetch(request);
                    }
                });
            },

            getPathFromUrl: function getPathFromUrl(url){
                const apiv2Location = url.indexOf('/api/v2');

                // validate that "path" is legal and relevant
                // empty path is ignored everywhere
                if (apiv2Location === -1){
                    return;
                }

                var endStringLocation = url.indexOf('?') !== -1 ? url.indexOf('?') : url.length;
                return url.substring(apiv2Location, endStringLocation);
            },

            manageInvalidationDebugging: function manageInvalidationDebugging(request, invalidation_id) {
                // save most recent invalidation per endpoint
                // and handle invalidation readback
                let endpoint = this.getPathFromUrl(request.url);
                if (!endpoint) { return; }
                this.mostRecentInvalidations[endpoint] = {
                    id: invalidation_id,
                    timestamp: new Date()
                };
                if (this.sendReadbackOnNextInvalidation[endpoint]) {
                    this.UsersManager.clientCacheLogEvent({
                        event_type: 'invalidation_readback_event',
                        additional_data: {
                            invalidation_id
                        }
                    });
                    this.sendReadbackOnNextInvalidation[endpoint] = false;
                }
            },

            invalidateAllCache: function invalidateAllCache() {
                if (this.$window.caches) {
                    return this.$window.caches.delete(this.HB_CACHE_NAME);
                }
            },

            allRoomsToRegisterTo: function allRoomsToRegisterTo() {
                const currUser = this.UsersManager.getCurrUser();
                const companyIds = currUser.companyIds();
                const rooms = [...companyIds, currUser._id, 'all'].map(room => `${this.CLIENT_CACHE_ROOM_PREFIX}${room}`);
                return rooms;
            },

            registerToAllRooms: function registerToAllRooms() {
                const rooms = this.allRoomsToRegisterTo();
                rooms.forEach(room => {
                    this.WebsocketHelperService.registerToRoom(room, this.handleCacheInvalidation.bind(this));
                });
            },

            unRegisterFromAllRooms: function unRegisterFromAllRooms() {
                const rooms = this.allRoomsToRegisterTo();
                rooms.forEach(room => {
                    this.WebsocketHelperService.unregisterFromRoom(room);
                });
            },

            handleStaleEventFromSW: function handleStaleEventFromSW(eventData) {
                const {
                    routeName,
                    path,
                    diff,
                    cachedData,
                    actualData,
                    testRate,
                    samplingRate,
                    expiredAt,
                    freezeDuration,
                    originalRequestTimestamp
                } = eventData;

                const {
                    id: lastInvalidationId,
                    timestamp: lastInvalidationTimestamp
                } = this.mostRecentInvalidations[path] || {id: null, timestamp: null};
                this.sendReadbackOnNextInvalidation[path] = true;

                const shouldSkip = lastInvalidationTimestamp && lastInvalidationTimestamp > originalRequestTimestamp;

                const MAX_OBJECT_LENGTH = 100000;
                const trimmedCachedData = JSON.stringify(cachedData || {}).slice(0, MAX_OBJECT_LENGTH);
                const trimmedActualData = JSON.stringify(actualData || {}).slice(0, MAX_OBJECT_LENGTH);

                return {
                    route_name: routeName,
                    path,
                    expired_at: expiredAt,
                    cached: trimmedCachedData,
                    actual: trimmedActualData,
                    diff: diff,
                    test_rate: testRate,
                    sampling_rate: samplingRate,
                    freeze_duration: freezeDuration,
                    last_invalidation_id: lastInvalidationId,
                    original_request_timestamp: originalRequestTimestamp,
                    should_skip: shouldSkip
                };
            },

            handleErrorEventFromSW: function handleErrorEventFromSW(eventData) {
                const {error} = eventData;
                return {error};
            },

            handleVersionMessageFromSW: function handleVersionMessageFromSW(eventData) {
                let expectedVersion = this.Gon.client_cache_version;
                let actualVersion = eventData.swVersion;

                if (expectedVersion && expectedVersion === actualVersion) {
                    return this.setupCacheListenersAndSettings();
                }
            },

            handleTestSuccessEventFromSW: function handleTestSuccessEventFromSW(eventData) {
                const {routeName, path, testRate, samplingRate, expiredAt, freezeDuration} = eventData;

                return {
                    route_name: routeName,
                    path,
                    test_rate: testRate,
                    sampling_rate: samplingRate,
                    expired_at: expiredAt,
                    freeze_duration: freezeDuration
                };
            },

            handleTestMissEventFromSW: function handleTestSuccessEventFromSW(eventData) {
                const {routeName, path, testRate, samplingRate, isCurrentlyFrozen, freezeDuration} = eventData;

                return {
                    route_name: routeName,
                    path,
                    test_rate: testRate,
                    sampling_rate: samplingRate,
                    is_currently_frozen: isCurrentlyFrozen,
                    freeze_duration: freezeDuration
                };
            },

            registerToIncomingMessageFromServiceWorker: function registerToIncomingMessageFromServiceWorker() {
                if (this.$window.navigator.serviceWorker) {
                    this.$window.navigator.serviceWorker.addEventListener('message', event => {
                        console.debug(event.data);
                        const {eventType, swVersion} = event.data;

                        if (eventType === "versionHandshake") {
                            return this.handleVersionMessageFromSW(event.data);
                        }

                        const data = {
                            event_type: eventType || 'unknown',
                            sw_version: swVersion
                        };

                        switch (eventType) {
                            case 'stale_data_served':
                                data.additional_data = this.handleStaleEventFromSW(event.data);
                                break;
                            case 'test_cache_hit':
                                data.additional_data = this.handleTestSuccessEventFromSW(event.data);
                                break;
                            case 'test_cache_miss':
                                data.additional_data = this.handleTestMissEventFromSW(event.data);
                                break;
                            case 'error':
                                data.additional_data = this.handleErrorEventFromSW(event.data);
                                break;
                        }

                        this.UsersManager.clientCacheLogEvent(data);
                    });
                }
            }
        };
    });

}());
