(function () {
    'use strict';

    // @ngInject
    function TimeTrackerControllerCtor($scope, $injector, AnalyticsService,  _, TimeTrackingManager, UsersManager, moment, DeviceService, ModalService,
                                       PopupMessageService, $timeout, TimeTrackingService, Enums, FlowsBetaUserTypeService, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerController';

        this._ = _;
        this.AnalyticsService = AnalyticsService;

        this.moment = moment;
        this.TimeTrackingService = TimeTrackingService;
        this.UsersManager = UsersManager;
        this.TimeTrackingManager = TimeTrackingManager;
        this.DeviceService = DeviceService;
        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.$timeout = $timeout;
        this.Enums = Enums;


        this.user = UsersManager.getCurrUser();

        this.loadingView = true;
        this.timeTrackerEntryList = [];

        this._setColumnSortDefaults();
        this._setFilterDropdown();
        this.showCustomDates = false;
        this.apiDateFormat = 'YYYY-MM-DD';
        this.isLoaded = false;

        StatsigService.isGateEnabled('ng2react_time_tracker').then(function (result) {
            this.showReactTimeTracker = result;
            this.isLoaded = true;
            if (!this.showReactTimeTracker) {
                this.$state.current.data.hideSecondLevelNavigation = false;
                this.AnalyticsService.trackPageView(this, 'TimeTrackerTool');
                this._fetchCompanyEntries();

            }
        }.bind(this));
    }

    Controllers.TimeTrackerController = Class(Controllers.BaseController, {
        constructor: TimeTrackerControllerCtor,

        _fetchCompanyEntries: function _fetchCompanyEntries() {
            this.requestInProgress = true;
            var params = this.prepareParams();
            return this.TimeTrackingManager.fetchForCompany(this.user.company, params)
                .then(function(resp) {
                    this.timeTrackerEntryList.splice(0);
                    this.timeTrackerEntryList = resp.data;
                }.bind(this))
                .finally(function final() {
                    this.requestInProgress = false;
                    this.loadingView = false;
                }.bind(this));
        },


        _onEntryUpdated: function _onEntryUpdated(entry) {
            var index = this._.findIndex(this.timeTrackerEntryList, function(e) { return e._id === entry._id; });
            this.timeTrackerEntryList[index] = this._.assign({}, entry );
        },

        _onEntryAdded: function _onEntryAdded(entry) {
            this.timeTrackerEntryList.push(entry);
        },

        addNewEntry: function addNewEntry() {
            this.timeTrackerEntry = {};
            this.workspaceId = false;

                this.ModalService.openTimeTrackerEntryModal(this.workspaceId, this.timeTrackerEntry, this.Enums.timeTrackerSource.tool)
                    .then(function success(resp) {
                        this._onEntryAdded(resp.data);
                    }.bind(this));
        },

        _deleteTimeTrackEntry: function _deleteTimeTrackEntry(entry) {

            this.TimeTrackingManager.deleteTimeTracking(entry._id).then(function success() {
                this._onEntryDeleted(entry);
            }.bind(this));

            this.AnalyticsService.trackClick(this, 'time_tracker_entry_deleted',
                {   source: "time_tracker_tool",
                    app_path: "time_tracker_tool",
                    event: "time_tracker_entry_deleted",
                    eventType: "click"} );
        },

        _onEntryDeleted: function _onEntryDeleted(entry) {
            var index = this._.findIndex(this.timeTrackerEntryList, function(e) { return e._id === entry._id; });
            if (index !== -1) {
                this.timeTrackerEntryList.splice(index, 1);
            }
        },

        confirmDeleteTimeTrackEntry: function confirmDeleteTimeTrackEntry(entry) {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'TIME_TRACKER.WARNING._MSG_',
                'TIME_TRACKER.WARNING._YES_DELETE_',
                'FREQUENT_BUTTONS._CANCEL_').then(function () {
                this._deleteTimeTrackEntry(entry);
            }.bind(this));
        },

        _openTimeEntryModal: function _openTimeEntryModal(entry) {
            this.timeTracakerEntry = entry;
            var projectId = entry.project_id ? entry.project_id : false;

            this.ModalService.openTimeTrackerEntryModal(projectId, this.timeTracakerEntry, this.Enums.timeTrackerSource.tool)
                .then(function success(resp) {
                    //user can delete from entry modal on mobile
                    this._.isEmpty(resp.data) ? this._onEntryDeleted(entry) : this._onEntryUpdated(resp.data);
                }.bind(this));
        },

        editTimeTrackEntry: function editTimeTrackEntry(entry) {
            this._openTimeEntryModal(entry);
        },

        formatDate : function formatDate (date) {
            return this.TimeTrackingService.formatDate(date);
        },

        mobileEditEntry: function mobileEditEntry(entry) {
            if (this.DeviceService.xLargeBreakPoint()) {
                this.editTimeTrackEntry(entry);
            }
        },

        downloadCSV: function downloadCSV($event) {
            this.downloadingCsv = true;
            var params = this.prepareParams();
            this.TimeTrackingService.downloadCSV($event, this.user.company, null, params).finally(function handleFinally() {
                this.downloadingCsv = false;
            }.bind(this));
        },

        setFilterStartDate: function setFilterStartDate() {
            var momentDate = this.moment(this.filterStartDate, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.filterStartDate = momentDate;
                this._fetchCompanyEntries();
            }
        },

        setFilterEndDate: function setFilterEndDate() {
            var momentDate = this.moment(this.filterEndDate, 'MMM DD, YYYY');
            if (momentDate.isValid()) {
                this.filterEndDate = momentDate;
                this._fetchCompanyEntries();
            }
        },

        viewSample: function viewSample() {
            this.ModalService.openTimeTrackerSampleModal();
        },

        createInvoice: function createInvoice() {
            this.ModalService.openTimeTrackerToolInvoiceModal();
        },

        handleClickColumnSort: function handleClickColumnSort(column) {
            var order = '';

            if (this.currentColumn === column) {
                this.sortDirectionReverse = !this.sortDirectionReverse;
                order = this.orderProperty.slice(0, 1) === '+' ? '-' : '+';

            } else {
                this.currentColumn = column;
                order = '+';
            }

            this.orderProperty = order + column;
        },

        _setColumnSortDefaults: function _setColumnSortDefaults() {
            this.orderProperty = "+completed_on";
            this.currentColumn = "completed_on";
        },

        _setFilterDropdown: function _setFilterDropdown() {


            this.filterList = [
                {
                    key: this.Enums.timeTrackerFilters.allTime,
                    text: 'TIME_TRACKER.FILTER._ALL_TIME_'
                },
                {
                    key: this.Enums.timeTrackerFilters.last30Days,
                    text: 'TIME_TRACKER.FILTER._LAST_30_DAYS_'
                },
                {
                    key: this.Enums.timeTrackerFilters.last90Days,
                    text: 'TIME_TRACKER.FILTER._LAST_90_DAYS_'
                },
                {
                    key: this.Enums.timeTrackerFilters.customRange,
                    text: 'TIME_TRACKER.FILTER._CUSTOM_RANGE_'
                }
            ];

            if (this.DeviceService.navLargeBreakPoint()) {
                this.filterList.pop();
            }


            this.activeFilter = this.filterList[0];
        },

        updateTimeTrackerFilter: function updateTimeTrackerFilter(item) {
            this.activeFilter = item;
            if (item.key === this.Enums.timeTrackerFilters.customRange) {
                this.showCustomDates = true;
                this.filterStartDate = null;
                this.filterEndDate = null;
            } else {
                this.showCustomDates = false;
            }
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.time_tracking_change_filter, {
                filter: this.activeFilter.key,
            });
            this._fetchCompanyEntries();
        },

        prepareParams: function prepareParams() {
            var params = {};
            if (this.activeFilter && this.activeFilter.key) {
                var today = this.moment();
                switch (this.activeFilter.key) {
                    case this.Enums.timeTrackerFilters.last30Days:
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(30, 'day').format(this.apiDateFormat);
                        break;
                    case this.Enums.timeTrackerFilters.last90Days:
                        params.to_date = today.format(this.apiDateFormat);
                        params.from_date = today.subtract(90, 'day').format(this.apiDateFormat);
                        break;
                    case this.Enums.timeTrackerFilters.customRange:
                        if (this.filterStartDate) {
                            params.from_date = this.filterStartDate.format(this.apiDateFormat);
                        }
                        if (this.filterEndDate) {
                            params.to_date = this.filterEndDate.format(this.apiDateFormat);
                        }
                        break;
                }
            }
            return params;
        },


    });
}());
