/**
 * Created by dvircohen on 21/03/2017.
 */
(function () {
    'use strict';

    // @ngInject
    function WorkflowManagerCtor($q, APIService, ModelFactory, _, Routes, UIUtils) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this._ = _;
        this.Routes = Routes;
        this.UIUtils = UIUtils;
    }

    Services.WorkflowManager = Class(Services.BaseModelManager, {
        constructor: WorkflowManagerCtor,

        updateWorkflow: function updateWorkflow(company, workflow) {
            var url = this.Routes.v2_update_workflow_path(company._id, workflow._id);
            return this.apiUpdate(url, workflow, null, false);
        },

        getWorkflowTemplate: function getWorkflowTemplate(companyId, workflowId) {
            var url = this.Routes.v2_get_workflow_path(companyId, workflowId);
            return this.apiFetch(url, null, null, false);
        },

        getWorkflowInstance: function getWorkflowInstance(workspaceId) {
            var url = this.Routes.v2_get_workflow_for_workspace_path(workspaceId);
            return this.apiFetch(url).then(function(resp) {
                return resp.data.workflow;
            });
        },

        removeWorkflowInstanceFromWorkspace: function removeWorkflowInstanceFromWorkspace(workspace_id, workflow_id) {
            var url = this.Routes.v2_unset_workflow_instance_path(workspace_id, workflow_id);
            return this.apiDelete(url, null, null, false);
        },

        changeWorkflowStepState: function changeWorkflowStepState(workspace_id, step_or_task_id, approval_status, subject, body, attachedFiles) {
            const newBody = this.UIUtils.toBinaryBase64(body);
            var data = {
                approval_status: approval_status,
                subject: subject,
                body: newBody,
                general_files: attachedFiles ? attachedFiles.generalFiles : [],
                image_files: attachedFiles ? attachedFiles.imageFiles : [],
                encoded: !!newBody
            };

            var url = this.Routes.v2_change_workflow_step_state_path(workspace_id, step_or_task_id);
            return this.apiUpdate(url, data, null, false);
        },
    });

}());

