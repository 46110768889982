(function () {
    "use strict";

    const US_STATES = [
        {
            name: "Alabama",
            abbreviation: "AL"
        },
        {
            name: "Alaska",
            abbreviation: "AK"
        },
        {
            name: "Arizona",
            abbreviation: "AZ"
        },
        {
            name: "Arkansas",
            abbreviation: "AR"
        },
        {
            name: "California",
            abbreviation: "CA"
        },
        {
            name: "Colorado",
            abbreviation: "CO"
        },
        {
            name: "Connecticut",
            abbreviation: "CT"
        },
        {
            name: "Delaware",
            abbreviation: "DE"
        },
        {
            name: "District Of Columbia",
            abbreviation: "DC"
        },
        {
            name: "Florida",
            abbreviation: "FL"
        },
        {
            name: "Georgia",
            abbreviation: "GA"
        },
        {
            name: "Hawaii",
            abbreviation: "HI"
        },
        {
            name: "Idaho",
            abbreviation: "ID"
        },
        {
            name: "Illinois",
            abbreviation: "IL"
        },
        {
            name: "Indiana",
            abbreviation: "IN"
        },
        {
            name: "Iowa",
            abbreviation: "IA"
        },
        {
            name: "Kansas",
            abbreviation: "KS"
        },
        {
            name: "Kentucky",
            abbreviation: "KY"
        },
        {
            name: "Louisiana",
            abbreviation: "LA"
        },
        {
            name: "Maine",
            abbreviation: "ME"
        },
        {
            name: "Maryland",
            abbreviation: "MD"
        },
        {
            name: "Massachusetts",
            abbreviation: "MA"
        },
        {
            name: "Michigan",
            abbreviation: "MI"
        },
        {
            name: "Minnesota",
            abbreviation: "MN"
        },
        {
            name: "Mississippi",
            abbreviation: "MS"
        },
        {
            name: "Missouri",
            abbreviation: "MO"
        },
        {
            name: "Montana",
            abbreviation: "MT"
        },
        {
            name: "Nebraska",
            abbreviation: "NE"
        },
        {
            name: "Nevada",
            abbreviation: "NV"
        },
        {
            name: "New Hampshire",
            abbreviation: "NH"
        },
        {
            name: "New Jersey",
            abbreviation: "NJ"
        },
        {
            name: "New Mexico",
            abbreviation: "NM"
        },
        {
            name: "New York",
            abbreviation: "NY"
        },
        {
            name: "North Carolina",
            abbreviation: "NC"
        },
        {
            name: "North Dakota",
            abbreviation: "ND"
        },
        {
            name: "Ohio",
            abbreviation: "OH"
        },
        {
            name: "Oklahoma",
            abbreviation: "OK"
        },
        {
            name: "Oregon",
            abbreviation: "OR"
        },
        {
            name: "Pennsylvania",
            abbreviation: "PA"
        },
        {
            name: "Puerto Rico",
            abbreviation: "PR"
        },
        {
            name: "Rhode Island",
            abbreviation: "RI"
        },
        {
            name: "South Carolina",
            abbreviation: "SC"
        },
        {
            name: "South Dakota",
            abbreviation: "SD"
        },
        {
            name: "Tennessee",
            abbreviation: "TN"
        },
        {
            name: "Texas",
            abbreviation: "TX"
        },
        {
            name: "Utah",
            abbreviation: "UT"
        },
        {
            name: "Vermont",
            abbreviation: "VT"
        },
        {
            name: "Virginia",
            abbreviation: "VA"
        },
        {
            name: "Washington",
            abbreviation: "WA"
        },
        {
            name: "West Virginia",
            abbreviation: "WV"
        },
        {
            name: "Wisconsin",
            abbreviation: "WI"
        },
        {
            name: "Wyoming",
            abbreviation: "WY"
        }
    ];
    const CANADA_STATES = [
        {
            name: "Alberta",
            abbreviation: "AB"
        },
        {
            name: "British Columbia",
            abbreviation: "BC"
        },
        {
            name: "Manitoba",
            abbreviation: "MB"
        },
        {
            name: "New Brunswick",
            abbreviation: "NB"
        },
        {
            name: "Newfoundland and Labrador",
            abbreviation: "NL"
        },
        {
            name: "Northwest Territories",
            abbreviation: "NT"
        },
        {
            name: "Nova Scotia",
            abbreviation: "NS"
        },
        {
            name: "Nunavut",
            abbreviation: "NU"
        },
        {
            name: "Ontario",
            abbreviation: "ON"
        },
        {
            name: "Prince Edward Island",
            abbreviation: "PE"
        },
        {
            name: "Quebec",
            abbreviation: "QC"
        },
        {
            name: "Saskatchewan",
            abbreviation: "SK"
        },
        {
            name: "Yukon",
            abbreviation: "YT"
        }
    ];

    Controllers.BankAccountFormController = class BankAccountFormController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, UsersManager, CompaniesManager, MobileAppService, DeviceService) {
            super($scope, $injector);
            this.__objectType = 'BankAccountFormController';
            this.$modalInstance = $modalInstance;
            this.MobileAppService = MobileAppService;
            this.CompaniesManager = CompaniesManager;
            this.isInAppBrowser = DeviceService.isInAppBrowser();


            this.user = UsersManager.getCurrUser();
            this.phoneRegex = /([(+]*[0-9]+[()+. -]*)/;
            // object to keep track of dirty fields for non-input fields
            this.nonInputDirtyFieldNames = {};
            let unsubscribeFromBankAccountListener;
            if (this.isInAppBrowser) {
                unsubscribeFromBankAccountListener = this.register(this.MobileAppService, this.MobileAppService.INBOUND._CLOSE_BANK_DETAILS_MODAL, () => {
                    this.dismiss();
                });
            }

            $scope.$on('$destroy', () => {
                unsubscribeFromBankAccountListener && unsubscribeFromBankAccountListener();
            });

            this._initialize();
        }

        close() {
            this.$modalInstance.close();
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }

        isCanada() {
            return this.user.company.country === 'CA';
        }

        patternFieldErrorMessage(fieldName, translationString, form, missingError = 'FREQUENT_ERRORS._REQUIRED_') {
            const $error = form[fieldName].$error;
            return $error.pattern || $error.maxlength || $error.minlength
                ? translationString 
                : missingError;
        }

        getFormDirtyFieldNames(form, initialFields = []) {
            return Object.keys(form).reduce((acc, key) => {
               // leave only ngModelController type properties
              if (key.startsWith("$")) {
                return acc;
            }

            const value = form[key];

            if (value.$dirty) {
                acc.push(key);
            }

            return acc; 
            }, initialFields);
        }

        _initialize() {
            this.states = this.isCanada() ? CANADA_STATES : US_STATES;
        }

        initFieldsToFix(entity) {
            this.fieldsToFix = (entity && entity.fields_to_fix) ?
            entity.fields_to_fix.reduce((acc, fieldName) => {
              if (fieldName === "bank_account_info") {
                if (this.isCanada) {
                  acc['institutionNumber'] = true;
                  acc['transitNumber'] = true;
                  acc['accountNumber'] = true;
                } else {
                  acc['routingNumber'] = true;
                  acc['accountNumber'] = true;
                }

                return acc;
              }

              if (fieldName === 'proof_of_liveness') {
                  acc['proof_of_liveness_front'] = true;
                  acc['proof_of_liveness_back'] = true;
                  return acc;
              }

            if (fieldName === 'photo_id') {
                acc['photo_id_front'] = true;
                acc['photo_id_back'] = true;
                return acc;
            }

              acc[fieldName] = true;
              return acc;
            },{}) :
            {};
        }
    };

}());