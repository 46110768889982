(function () {
    "use strict";

    // @ngInject

    function FacebookLeadsDirectiveControllerCtor($scope, $injector, AppConfigService, FacebookService,
                                                  _, moment, $translate, modalParams, AnalyticsService, CompaniesManager, $log, $modalInstance, UsersManager, Enums) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FacebookLeadsDirectiveController';
        this._ = _;
        this.FacebookService = FacebookService;
        this.AnalyticsService = AnalyticsService;
        this.$translate = $translate;
        this.$modalInstance = $modalInstance;
        this.CompaniesManager = CompaniesManager;
        this.company = CompaniesManager.getCurrCompany();
        this.UsersManager = UsersManager;
        this.Enums = Enums;
        this.$log = $log;
        this.loggingIn = false;
        this.showFetchedPages = false;
        this.currentUser = this.UsersManager.getCurrUser();
        this.availablePages = [];
        this.full_result = null;
        this.selectedPage = null;
        this.addingPage = false;

        this._initFields(modalParams);
    }


    Controllers.FacebookLeadsDirectiveController = Class(Controllers.BaseController, {
        constructor: FacebookLeadsDirectiveControllerCtor,

        _initFields: function _initFields(modalParams) {
            if (modalParams) {
                this.availablePages = modalParams.availablePages;
                this.full_result = modalParams.full_result;
                this.pageConfirmedInternal = modalParams.pageConfirmed;

            }
        },

        close: function close() {
            this.$modalInstance.close();
        },

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        },

        pageConfirmed: function pageConfirmed() {
            this.addingPage = true;
            this.pageConfirmedInternal(this.selectedPage)
                .finally(function () {
                    this.close();
                    this.addingPage = false;

                }.bind(this));
        }
    });
}());
