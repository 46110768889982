(function () {
    'use strict';

    // @ngInject
    function DatadogRUMServiceCtor(AppConfigService, $log, $window) {
        this.AppConfigService = AppConfigService;
        this.$log = $log;
        this.$window = $window;
        this.recurringErrors = new Map();

        this.debounceReportRecurringDataDogErrors = function (error, context) {
            let errorMessage;
            if (error) {
                if (typeof error === 'string') {
                    errorMessage = error;
                } else if (typeof error === 'object' && typeof error.message === 'string') {
                    errorMessage = error.message;
                }
            }
            if (context && typeof context === 'object') {
                const sensitiveKeysRegex = /token|password/i;
                const deleteSensitiveKeys = (obj) => {
                    if (Array.isArray(obj)) {
                        // If obj is an array, recursively check each element
                        obj.forEach((element) => {
                            if (element && typeof element === 'object') {
                                deleteSensitiveKeys(element);
                            }
                        });
                    } else {
                        Object.keys(obj).forEach((key) => {
                            const lowerKey = key.toLowerCase();
                            if (obj[key] && typeof obj[key] === 'object') {
                                deleteSensitiveKeys(obj[key]);
                            } else if (sensitiveKeysRegex.test(lowerKey)) {
                                delete obj[key];
                            } else if (typeof obj[key] === 'string' && sensitiveKeysRegex.test(obj[key])) {
                                try {
                                    let parseObj = JSON.parse(obj[key]);
                                    deleteSensitiveKeys(parseObj)
                                    obj[key] = JSON.stringify(parseObj)
                                } catch (e) {

                                }
                            }
                        });
                    }
                };

                deleteSensitiveKeys(context);
            }

            if (!!errorMessage) {
                if (this.recurringErrors.has(errorMessage)) {
                    const {timeoutId} = this.recurringErrors.get(errorMessage);
                    clearTimeout(timeoutId);
                } else {
                    this.reportToDataDog(error, context);
                }

                this.recurringErrors.set(errorMessage, {
                    timeoutId: setTimeout(
                        () => this.recurringErrors.delete(errorMessage),
                        1000
                    )
                });
            } else {
                this.reportToDataDog(error, context);
            }
        }.bind(this);

        this.reportToDataDog = function (error, context) {
            if (this.$window.DD_RUM && angular.isFunction(this.$window.DD_RUM.addError)) {
                this.$window.DD_RUM.addError(error, context || {});
            }
        }.bind(this);
    }

    // RUM stands for Real User Monitoring
    Services.DatadogRUMService = Class(function () {
        return {
            constructor: DatadogRUMServiceCtor,

            initTracking: function () {
                if (this.$window.DD_RUM && angular.isFunction(this.$window.DD_RUM.startSessionReplayRecording)) {
                    this.$window.DD_RUM.startSessionReplayRecording();
                }
            },

            stopTracking: function () {
                if (this.$window.DD_RUM && angular.isFunction(this.$window.DD_RUM.stopSessionReplayRecording)) {
                    this.$window.DD_RUM.stopSessionReplayRecording();
                }
            },

            identifyUser: function (userData) {
                if (this.$window.DD_RUM && angular.isFunction(this.$window.DD_RUM.setUser)) {
                    this.$window.DD_RUM.setUser(userData);
                }
            },

            /**
             * @param {Error} error - Javascript Rrror object
             * @param {Object} context - key value pairs of any additional data
             */
            addError: function (error, context = {}) {
                try {
                    this.debounceReportRecurringDataDogErrors(error, context);
                } catch (e) {
                    // temp fix - this function can fail and we would never want to throw an error here
                    // TODO: debug why this function can fail with RangeError: Maximum call stack size exceeded
                }

                if (this.AppConfigService.isDevelopment()) {
                    const errorToSend = error instanceof Error ? error : new Error(error);
                    let contextToSend = Object.assign(context, {cause: errorToSend});
                    console.error(error, context, errorToSend, contextToSend);
                }
            },

            addFeatureFlagEvaluation: function(key, value) {
                if (this.$window.DD_RUM && angular.isFunction(this.$window.DD_RUM.addFeatureFlagEvaluation)) {
                    this.$window.DD_RUM.addFeatureFlagEvaluation(key, value);
                }
            }
        };
    });
}());
