(function () {
    "use strict";

    // @ngInject
    Controllers.FileTitleController = function FileTitleController($modalInstance, workspaceFile) {
        this.newFileName = workspaceFile.file_title;
        this.valid = true;

        this.close = function close() {
            if (this.newFileName && this.newFileName !== 'Untitled File') {
                workspaceFile.file_title = this.newFileName;
                this.valid = true;

                $modalInstance.close();
            } else {
                this.valid = false;
            }
        };

        this.cancel = function cancel() {
            $modalInstance.dismiss();
        };
    };

}());
