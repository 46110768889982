/**
 * Created by dvircohen on 12/07/2017.
 */

// @ngInject
Classes.AgreementValuesStrategy = function AgreementValuesStrategy(moment, $filter) {

    var AgreementValuesStrategy = Class({

        constructor: function AgreementValuesStrategyCtor(agreementParent) {
            this.agreementParent = agreementParent;
        },

        // ============================================
        // Project fields
        // ============================================

        getEventType: function getEventType() {
            return '';
        },

        getEventDate: function getEventDate() {
            return '';
        },

        getEventEndDate: function getEventEndDate() {
            return '';
        },

        getEventLocation: function getEventLocation() {
            return '';
        },

        getEventVenueName: function getEventVenueName() {
            return '';
        },

        getEventName: function getEventName() {
            return '';
        },

        getEventTimeStart: function getEventTimeStart() {
            return '';
        },

        getEventTimeEnd: function getEventTimeEnd() {
            return '';
        },

        getEventGuestsNumber: function getEventGuestsNumber() {
            return '';
        },

        getEventBudget: function getEventBudget() {
            return '';
        },

        // ============================================
        // Proposal fields
        // ============================================

        getProposalTitle: function getProposalTitle() {
            return '';
        },

        getProposalTotalPrice: function getProposalTotalPrice() {
            return '';
        },

        getProposalSubTotalPrice: function getProposalSubTotalPrice() {
            return '';
        },

        getProposalTax: function getProposalTax() {
            return '';
        },

        // ============================================
        // Payments fields
        // ============================================

        getFirstPaymentAmount: function getFirstPaymentAmount() {
            return '';
        },

        getFirstPaymentDueDate: function getFirstPaymentDueDate() {
            return '';
        },

        getSecondPaymentAmount: function getSecondPaymentAmount() {
            return '';
        },

        getSecondPaymentDueDate: function getSecondPaymentDueDate() {
            return '';
        },

        getLastPaymentAmount: function getLastPaymentAmount() {
            return '';
        },

        getLastPaymentDueDate: function getLastPaymentDueDate() {
            return '';
        },

        // ============================================
        // Agreement fields
        // ============================================

        getAgreementDate: function getAgreementDate() {
            return '';
        },

        // ============================================
        // Company fields
        // ============================================

        getCompanyTax: function getCompanyTax() {
            return this.agreementParent && this.agreementParent.default_tax || '';
        },

        getCompanyName: function getCompanyName() {
            return this.agreementParent && this.agreementParent.company_name || '';
        },

        getCompanyType: function getCompanyType() {
            return this.agreementParent && this.agreementParent.company_type_name || '';
        },

        getCompanyEmail: function getCompanyEmail() {
            return this.agreementParent && this.agreementParent.email || '';
        },

        getCompanyPhoneNumber: function getCompanyPhoneNumber() {
            return this.agreementParent && this.agreementParent.phone_number || '';
        },

        // ============================================
        // Client fields
        // ============================================

        getClientFullName1: function getClientFullName1() {
            return '';
        },

        getClientFirstName1: function getClientFirstName1() {
            return '';
        },

        getClientInitials1: function getClientInitials1() {
            return '';
        },

        getClientEmail1: function getClientEmail1() {
            return '';
        },

        getClientAddress1: function getClientAddress1() {
            return '';
        },

        getClientPhoneNumber1: function getClientPhoneNumber1() {
            return '';
        },

        getClientFullName2: function getClientFullName2() {
            return '';
        },

        getClientFirstName2: function getClientFirstName2() {
            return '';
        },

        getClientInitials2: function getClientInitials2() {
            return '';
        },

        getClientEmail2: function getClientEmail2() {
            return '';
        },

        getClientAddress2: function getClientAddress2() {
            return '';
        },

        getClientPhoneNumber2: function getClientPhoneNumber2() {
            return '';
        },

        // ============================================
        // Other
        // ============================================

        getOther: function getOther() {
            return '';
        },

        /**
         * formatting string of date to a nice looking date
         * @param {string|date} val the value to format
         * @param {string} [defaultVal] Optional. The default value in case the val is falsy. Defaulted to empty string if not provided.
         * @returns {string} nice looking date
         */
        formatDate: function formatDate(val, defaultVal) {
            if (typeof defaultVal === 'undefined') {
                defaultVal = '';
            }
            var filter = $filter('date');
            return val ? filter(val, 'mediumDate') : defaultVal;
        },

        /**
         * formatting a string of number to a nice looking currency without the dollar sign($)
         * @param {string|number} val the value to format
         * @param {string} [defaultVal] Optional. The default value in case the val is falsy. Defaulted to empty string if not provided.
         * @returns {string} nice looking currency
         */
        formatMoney: function formatMoney(val, defaultVal) {
            if (typeof defaultVal === 'undefined') {
                defaultVal = '';
            }
            var filter = function internalFilter(val) {
                // using the 'hbcurrency' filter
                var res = $filter('hbcurrency')(val);

                // removing the $ sign
                return res.replace('$', '');
            };
            return typeof val === 'number' || val ? filter(val) : defaultVal;
        }

    });

    return AgreementValuesStrategy;
};