// @ngInject

Models.FileSectionModel = function FileSectionModel(BaseModel, Enums) {

    return Class(BaseModel, {
        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'FileSectionModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                file_blocks: 'FileBlocksCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {

        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        bubbleUp: function bubbleUp() {
            return [
                'updateSection',
                'deleteSection',
                'updateBlock',
                'deleteBlock',
                'updateSectionBlocksOrder',
                'updateProposalOnBlock',
                'addPackageToBlock',
                'updatePackageOnBlock',
                'updatePackageQuantityOnBlock',
                'deletePackageFromBlock',
                'updatePackageServicesOrderOnBlock',
                'addServiceToBlock',
                'updateServiceOnBlock',
                'updateServiceQuantityOnBlock',
                'updateServiceImageOnBlock',
                'updateProposalSelection',
                'deleteServiceFromBlock',
                'updateProposalOrder',
                'moveServiceOnBlock',
                'updateComponent',
                'answerQuestion',
                'getRootModelType',
                'getRootModel',
            ];
        },

        addBlock: function addBlock(blockType, newBlockOrder) {

            var params = {
                block_type: blockType.type,
                block_order: newBlockOrder
            };

            // // if subType is provided, it means we are adding a form block
            if(blockType.subType) {
                params['block_sub_type'] = 'form';

                // if custom, add form type
                if(blockType.category === 'custom') {
                    if (blockType.subType === Enums.customQuestions.two_fields) {
                        params['form_type'] = 'two_fields';
                    } else {
                        params['form_type'] = 'single_question';
                        params['question'] = { question_type: blockType.subType };
                    }

                // if suggested add form preset
                } else if(blockType.category === 'suggested') {
                    params['form_preset'] = blockType.subType;
                }
            }

            if (this.getRootModelType() === 'contact_form' && params.block_type !== 'component') {
                params.block_type = 'component';
                params.block_sub_type = blockType.type;
            }
            return this.__parent.addBlock(this, params, newBlockOrder);
        },

        hasRequiredField: function hasRequiredField() {
            var blocks = this.file_blocks.filter(function(block) {
                return block.type === 'proposal';
            });
            return !!blocks.length;
        },

        isSelectionValid: function isSelectionValid() {
            var proposalBlocks = this.file_blocks.filter(function(block) {
                return block.type === 'proposal';
            });

            var valid = proposalBlocks.every(function(block) {
                return block.proposal.isSelectionValid();
            }.bind(this));

            return valid;
        },
    });
};

