(function () {
    'use strict';

    Directives.JustifiedGalleryViewer = function JustifiedGalleryViewer() {

        // @ngInject
        function JustifiedGalleryViewerController($scope, $timeout, $element) {
            this.loading = true;
            this.rowHeight = 180;

            this.initializeGallery = function initializeGallery() {
                var jg = $element.find('.js-justified-gallery');
                $timeout(function() {
                    jg.justifiedGallery({
                        rowHeight : this.rowHeight,
                        lastRow : 'justify',
                        margins : 8,
                        captions: false,
                        waitThumbnailsLoad: false
                    });
                }.bind(this));
            };
            
            var clearWatch = $scope.$watch(
                function() {
                    return this.images ? this.images.length : 0;
                }.bind(this),
                function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue && newValue > 0) {
                            this.showButtonForMoreImages = this.images.length > this.maxImagesShownAtFirst ? true : false;
                            this.initializeGallery();
                            clearWatch();
                        }
                    }
                }.bind(this)
            );

            this.maxImagesShownAtFirst = 5;
            this.showMoreImages = false;

            this.showMore = function showMore() {
                this.showMoreImages = true;
                var jg = $element.find('.js-justified-gallery');
                $timeout(function() {
                    jg.justifiedGallery('norewind');
                }.bind(this));
            };
        }

        return {
            restrict: 'E',
            scope: {
                images: '='
            },
            templateUrl: 'angular/app/modules/common/ui_components/justified_gallery_viewer/justified_gallery_viewer_directive_template.html',

            transclude: true,
            controller: JustifiedGalleryViewerController,
            controllerAs: 'JustifiedGalleryViewerVm',
            bindToController: true
        };
    };
}());
