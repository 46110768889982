
(function () {
    'use strict';

    Directives.AddCardComponentDirective = function AddCardComponentDirective() {
        // @ngInject
        function AddCardComponentDirectiveControllerCtor($scope, UsersManager, StripeHelperService, AnalyticsService,
                                                         $injector, moment, $q, $timeout, DeviceService, UIUtils, $translate,
                                                         _, GoogleAPIService) {


            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'AddCardComponentDirectiveController';

            this.$q = $q;
            this.StripeHelperService = StripeHelperService;
            this.AnalyticsService = AnalyticsService;
            this.moment = moment;
            this.UIUtils = UIUtils;
            this.currUser = UsersManager.getCurrUser();
            this.DeviceService = DeviceService;
            this.$translate = $translate;
            this.isTouchDevice = this.DeviceService.checkIfTouchDevice();
            this._ = _;
            this.GoogleAPIService = GoogleAPIService;

            this.card = {};
            this.showCreditCardBillingSection = false;
            this.$timeout = $timeout;

            this.loading = false;
            this.closing = false;
            this.lastError = "";
            this.cardToAdd = {
                token_id: null,
                vendor_id: null
            };

            this.isAmexCard = false;
            this.creditCardType = 'default';
            this.paymentLocation = [
                {
                    key: 'paying_in_US',
                    text: 'FILE.PAYMENT.ADD_NEW_CARD.LABELS._USA_'
                },
                {
                    key: 'paying_in_CANADA',
                    text: 'FILE.PAYMENT.ADD_NEW_CARD.LABELS._CANADA_'
                },
                {
                    key: 'paying_international',
                    text: 'International'
                }
            ];

            this.creditCardTooltip = this.$translate.instant('ADD_CREDIT_CARD._CVV_TOOLTIP_');

            $scope.$watch('addCardComponentVm.card.exp_year', function(newVal, oldVal){
                if (this.card.exp_year && this.card.exp_year.toString().length === 4) {
                    this.card.exp_year = parseInt(this.card.exp_year.toString().substring(2));
                }
            }.bind(this));

            this.activePaymentLocation = this.paymentLocation[0];

            this.isAddCardFormValid = false;
            this.isNameOnCardInvalid = false;

            //ZipCode US and CANADA : /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
            //ZipCode US Only : /^\d{5}(?:[-\s]\d{4})?$/;
            this.zipValidationCanada = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
            this.zipValidationUS = /^\d{5}(?:[-\s]\d{4})?$/;
            this.zipValidation = this.zipValidationUS;
            this.payingInternational = false;
            this.selectedCountry = 'us';
            this.card.address_country = this.selectedCountry.toUpperCase();

            $scope.$on('AddCardAndPay', function () {
                this.addCardAndPayFunc();
            }.bind(this));

            $scope.$on('addCard', function() {
                this.submitAddCard(false);
            }.bind(this));
        }

        var AddCardComponentDirectiveController = Class(Controllers.BaseController, {
            constructor: AddCardComponentDirectiveControllerCtor,

            submitAddCard: function submitAddCard(firstPayment) {
                this.loading = true;
                this.creditCardVisualValidation();

                // send to strip & add to server
                if (firstPayment) {
                    this.createCardAndPay();
                } else {
                    this.createCardApi();
                }
            },

            //Add credit card only
            createCardApi: function createCardApi() {
                var additionalData = this.payingInternational ? {} : this.card;
                this.StripeHelperService.getStripeTokenNew(this.tokenProviderId, additionalData).then(function (response) {
                        this.cardToAdd = response.card;
                        this.cardToAdd.token_id = response.id;
                        this.cardToAdd.vendor_id = this.vendorId;

                        this.currUser.addPaymentMethod(this.cardToAdd, null, this.workspaceFileId).then(
                            function (resp) {
                                var newPaymentMethod = resp.data;
                                this.loading = false;
                                // this.modalInstance.close('success');
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card, {
                                    card_name: this.cardToAdd.name
                                });

                                this.successAddCardForm({newPaymentMethod: newPaymentMethod});
                            }.bind(this),

                            function (resp) {
                                this.loading = false;
                                this.lastError = resp.data.error_message || resp.data;

                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card_error,
                                    {
                                        error_message: this.lastError
                                    });

                            }.bind(this)
                        );

                    }.bind(this),

                    function (response) {
                        this.lastError = response.error.message;

                        this.loading = false;
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card_error,
                            {
                                error_message: this.lastError
                            });
                    }.bind(this));
            },

            //Add credit card and make first Payment
            createCardAndPay: function createCardAndPay() {
                var additionalData = this.payingInternational ? {} : this.card;
                this.StripeHelperService.getStripeTokenNew(this.tokenProviderId, additionalData).then(function (response) {
                        this.cardToAdd = response.card;
                        this.cardToAdd.token_id = response.id;
                        this.cardToAdd.vendor_id = this.vendorId;
                        this.cardToAdd.name_on_card =  this.cardToAdd.name;
                        this.currUser.addPaymentMethod(this.cardToAdd, null, this.workspaceFileId).then(
                            function (resp) {
                                var newPaymentMethod = resp.data;
                                this.loading = false;
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card_and_pay, {
                                    card_name: this.cardToAdd.name
                                });
                                this.successAddCardAndPay({newPaymentMethod: newPaymentMethod});
                            }.bind(this),

                            function (resp) {
                                this.loading = false;
                                this.lastError = resp.data.error_message || resp.data;
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card_and_pay_error,
                                    {
                                        error_message: this.lastError
                                    });
                                this.failedAddCardAndPay();
                            }.bind(this)
                        );

                    }.bind(this),

                    function (response) {
                        this.lastError = response.error.message;
                        this.failedAddCardAndPay();
                        this.loading = false;
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_new_credit_card_and_pay_error,
                            {
                                error_message: this.lastError
                            });
                    }.bind(this));
            },


            dismiss: function dismiss() {
                this.closing = true;
                this.modalInstance.dismiss('cancel');
            },

            creditCardVisualValidation: function creditCardVisualValidation() {
                this.isCardInvalid = false;
                this.showCreditCardBillingSection = true;
            },

            togglePayOutsideUS: function togglePayOutsideUS() {
                this.payingInternational = !this.payingInternational;
                this.stateRequired = !this.payingInternational;

                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.toggle_paying_international, {
                    payingInternational: this.payingInternational
                });
            },

            updatePaymentLocation: function updatePaymentLocation(location) {
                this.cleanAddress();
                this.activePaymentLocation = location;
                switch (location.key) {
                    case 'paying_in_US':
                        this.payingInternational = false;
                        this.selectedCountry = 'us';
                        this.zipValidation = this.zipValidationUS;
                        this.card.address_country = this.selectedCountry.toUpperCase();
                        this.isCreditCardBillingValid();
                        break;
                    case 'paying_in_CANADA':
                        this.payingInternational = false;
                        this.selectedCountry = 'ca';
                        this.zipValidation = this.zipValidationCanada;
                        this.card.address_country = this.selectedCountry.toUpperCase();
                        this.isCreditCardBillingValid();
                        break;
                    case 'paying_international':
                        this.payingInternational = true;
                        this.isAddCardFormValid = !this.isCardInvalid;
                        break;
                }
            },


            billingSectionFocus: function billingSectionFocus(zipField) {
                this.isBillingSectionFocus = true;

                if (zipField) {
                    this.zipCodeIsFocused = true;
                }
            },

            billingSectionBlur: function billingSectionBlur(zipField) {
                this.isBillingSectionFocus = false;

                if (zipField) {
                    this.zipCodeIsFocused = false;
                }
                if (!this.payingInternational) {
                    this.isCreditCardBillingValid();
                }
            },

            fieldSettings: function fieldSettings($event) {
            //Note: ng-keypress doesn't work well on android. Added inline function.

                switch ($event.target.id){
                    case "billing-zip":
                        if (this.selectedCountry === 'us') {
                            var zipField = $event.target;

                            // skip the limit if there is a selection in the field
                            if (zipField && zipField.value.substr(zipField.selectionStart, zipField.selectionEnd).length) {
                                break;
                            }
                            this.limitMaxCharacters($event, 5);
                        } else if (this.selectedCountry === 'ca') {
                            this.isAddCardFormValid = this.addCardForm.$valid;
                        }
                        break;

                    case "card_cvc":
                        this.limitMaxCharacters($event, 4);
                        break;

                    case "street-address":
                        this.isAddCardFormValid = this.addCardForm.$valid;
                        break;
                }
            },

            creditCardSectionBlur: function creditCardSectionBlur() {
                this.isCreditCardSectionFocus = false;
                this.creditCardVisualValidation();

                if (!this.payingInternational) {
                    this.isCreditCardBillingValid();
                }
            },

            limitMaxCharacters: function limitMaxCharacters($event, numberLimit, nextSibiling) {
                var key = $event.keyCode ? $event.keyCode : $event.which;


                if (key !== 8 && key !== 46) {
                    if ($event.target.value.length >= numberLimit) {
                        $event.preventDefault();
                    }

                    if ( !this.isTouchDevice ) {
                        if (nextSibiling && $event.target.value.length >= (numberLimit - 1)) {

                            // STUPID IE sets the value of the field in focus after the event is finished, so in the last character, it sets it in the next element
                            // so using timeout will move the focus to the field after the event is finished
                            this.$timeout(function() {
                                angular.element(nextSibiling).focus();
                            });
                        }
                    }

                    if( isNaN( String.fromCharCode(key) ) ){
                        $event.preventDefault();
                    }
                }
            },

            validateZipCode: function validateZipCode(zip) {
                //Covering edge cases w auto complete
                if (zip) {
                    var isZipCodeValid = this.zipValidation.test(zip);
                    if (isZipCodeValid) {
                        this.isBillingZipCodeInvalid = false;
                        return;
                    }
                }

                // Not supporting Canada Postal codes. Disable for now
                // if (this.addCardForm.address_zip.$dirty && !isZipCodeValid) {
                //     this.isBillingZipCodeInvalid = true;
                //     return;
                // }
            },

            isCreditCardBillingValid: function isCreditCardBillingValid() {
                this.$timeout(function () {
                    this.validateZipCode(this.card.address_zip);
                    this.isBillingCityInvalid = this.addCardForm.address_city.$dirty && this.addCardForm.address_city.$invalid;
                    this.isBillingAddressInvalid = this.addCardForm.address_line1.$dirty && this.addCardForm.address_line1.$invalid;
                    this.isBillingStateInvalid = this.addCardForm.stateModel.$dirty && this.addCardForm.stateModel.$invalid;

                    if( this.isBillingZipCodeInvalid || this.isBillingCityInvalid || this.isBillingAddressInvalid || this.isBillingStateInvalid) {
                        this.isCardBillingAddressInvalid = true;
                    } else {
                        this.isCardBillingAddressInvalid = false;
                    }
                    this.isAddCardFormValid = this.addCardForm.$valid;
                }.bind(this),300);

            },

            zipCodeAutoComplete: function zipCodeAutoComplete(geocodeResult) {
                this.card.address_zip = '';

                //NOTE: if zip field has value - autocomplete adds full address to zipcode field. This is a fix for the bug.
                this.$timeout(function () {
                    this.card.address_zip = geocodeResult.address_components[0].short_name;
                    this.card.address_city = this.UIUtils.getCityFromGeoAddrComponentsShortName(geocodeResult.address_components);
                    this.card.address_state = this.UIUtils.getStateCodeFromGeoAddrComponentsShortName(geocodeResult.address_components);
                    this.isCreditCardBillingValid();
                }.bind(this));
            },

            addCardAndPayFunc: function addCardAndPayFunc() {
                this.submitAddCard(true);
            },

            cleanAddress: function cleanAddress() {
                this.card.address_zip = null;
                this.card.address_city = '';
                this.card.address_state = '';
                this.card.address_country = '';
                this.isCardBillingAddressInvalid = false;
                this.card.address_line1 = '';

                this.$timeout(function() {
                    this.addCardForm.$setPristine();
                }.bind(this));
            },

            googleMapsDisabled: function googleMapsDisabled() {
                return this.GoogleAPIService.googleMapsDisabled();
            },
        });


        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/workspace_file/payment/add_card/add_card_directive_template.html',
            controller: AddCardComponentDirectiveController,
            controllerAs: 'addCardComponentVm',
            bindToController: true,
            scope: {
                paymentMethodsCount:'=',
                user: '&',
                vendorId: '=',
                closeAddCardForm: '&',
                successAddCardForm: '&',
                successAddCardAndPay: '&',
                failedAddCardAndPay: '&',
                workspaceFileId: '=',
                currPaymentMethods: '=',
                isAddCardFormValid: '=?isAddCardFormValid'
            }
        };
    };

}());
