(function () {
    'use strict';
    Controllers.PreMigrateUserModalController = class PreMigrateUserModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, $q, ModalService, moment, Gon,
                    UiPersistenceService, FlowsBetaUserTypeService, TemplatesRepositoryManager, FlowService, AnalyticsService, source, UsersManager, OtamStatusService, $state, $window) {
            super($scope, $injector, $translate);
            this.__objectType = 'PreMigrateUserModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;
            this.ModalService = ModalService;
            this.$window = $window;
            this.OtamStatusService = OtamStatusService;
            this.Gon = Gon;
            this.OtamStatusService.initializeAfterLogin();
            this.source = source;
            this.currUser = UsersManager.getCurrUser();
            this.isPhaseTwo = this.OtamStatusService.isMigrationEnabledExp();
            this.isTeamMember = !this.currUser.isCompanyOwner();
            this.subTitle = 'Everything you love about templates & files, but way more customizable.';
            if(this.isTeamMember) {
                this.subTitle += ' View examples below and learn about Smart Files.';
            } else if(!this.isPhaseTwo){
                this.subTitle += ' Join the waitlist and soon you’ll be a smart file user too.';
            }
            this.AnalyticsService = AnalyticsService;
            this.AnalyticsService.trackLoad(this, 'source to pre-migration modal', {source: this.source});
            this.moment = moment;
            this.UiPersistenceService = UiPersistenceService;
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

            this.FlowService = FlowService;
            var otamData = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.otamMigration,
                {}
            );
            this.wasAddedToList = !!otamData['AddToWaitingList'];
            this.TemplatesRepositoryManager.fetchTemplateRecords({id:['620b8d521af3ae007f66f701', '620b8e7f1af3ae00b52c0893', '620b8ee21af3ae00b52c0918']}).then(function loadTemplates(resp){
                this.templates = resp.data;
                this.templates[0].actionTags = "Questionnaire";
                this.templates[1].actionTags = "Contract, Invoice";
                this.templates[2].actionTags = "Services, Contract, Invoice";
            }.bind(this));
        }

        addToWaitingList(){
            if (!this.wasAddedToList){
                this.notifyIterable();
                this.wasAddedToList = true;
                var otamData = this.UiPersistenceService.getUiPersistence(
                    this.UiPersistenceService.keys.otamMigration,
                    {}
                );
                otamData['AddToWaitingList'] = this.moment();
                this.UiPersistenceService.setUiPersistence(
                    this.UiPersistenceService.keys.otamMigration,
                    otamData
                );
            }
        }

        notifyIterable() {
            this.AnalyticsService.reportIterableEvent('flows_waitlist', {email: this.currUser.email}, this.currUser);
        }

        dismissModal(){
            this.$modalInstance.dismiss();
        }

        openMicroSite() {
            this.$window.open("https://smartfiles.honeybook.com/",'_blank');
        }

        seeLiveExamples(value){
            this.isShowExamples = value;
        }
        previewTemplate(template){
            this.AnalyticsService.trackClick(this, 'preview template name', {name: template.title, source: this.source});
            this.FlowService.goToTemplateGalleryPreviewNewTab(template.published_template_id, 1);
        }
        migrateToFlows(){
            this.OtamStatusService.markElementAsSeen('migrationModalOpen');
            this.$modalInstance.dismiss();
            this.ModalService.openMigrateUserModal(this.source);
        }
    };
}());
  