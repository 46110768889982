(function () {
    "use strict";


    Controllers.NewAddUserController = Class(Controllers.BaseController, {

        // need to pass all injections from child controller.
        // @ngInject
        constructor: function constructor($scope, $modalInstance, $injector, $translate, UsersManager, UserService, _,
                                          CompanyService, ModelFactory, GoogleAPIService, AnalyticsService,
                                          SearchablesManager, ValidationsManager, PopupMessageService) {

            Controllers.NewAddUserController.$super.call(this, $scope, $injector);
            this.__objectType = 'NewAddUserController';
            this.$injector = $injector;
            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this._ = _;
            this.$translate = $translate;
            this.$modalInstance = $modalInstance;
            this.ModelFactory = ModelFactory;
            this.GoogleAPIService = GoogleAPIService;
            this.AnalyticsService = AnalyticsService;
            this.SearchablesManager = SearchablesManager;
            this.ValidationsManager = ValidationsManager;
            this.fetchedUserFromDb = false;
            this.PopupMessageService = PopupMessageService;

            if ($injector) {
                this.DeviceService = $injector.get("DeviceService");
                this.HelpService = $injector.get("HelpService");
                this.ContactsManager = $injector.get('ContactsManager');
            }

            if (this.DeviceService) {
                this.isMobile = this.DeviceService.nxSmallBreakpoint();
            } else {
                this.isMobile = false;
            }

            this.shouldShowCompanyTypeDropdown = false;
            this.shouldShowBusinessInfoOnBottom = false;

            if (ModelFactory) {
                this.user = ModelFactory.newUserModel();
                this.user.system_user_type = 'client';
            }
            if (CompanyService) {
                this.companyTypes = CompanyService.getCompanyTypesEnum();
                this.companyTypesValues = this.companyTypes.map(function (companyType) {
                    return $translate.instant(companyType.text);
                });
            }

            if ($scope) {
                $scope.$watch('addUserVm.user.email', function (newVal, oldVal) {
                    if (newVal !== undefined && oldVal !== newVal) {
                        this.onEmailChange();
                    }
                }.bind(this));

                $scope.$on('$destroy', function () {
                    this._onCloseModal();
                }.bind(this));
            }

            this.NOTES_SETTINGS = {
                MAX_LENGTH: 500,
                MAX_NEGATIVE_CHARS: 100,
                CHANGE_STYLE_AFTER_CHARS: 450,
                MIN_CHARS_TO_SHOW_COUNTER: 300
            };

            this.showErrorMessage = false;

            if ($translate) { //set default. override when inherit.
                this.titleText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._TITLE_");
                this.subTitleText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._SUBTITLE_");
                this.ctaText = $translate.instant("ADD_USER_DIALOG.NEW_ADD_USER_MODAL._SAVE_");
            }

            this.isFetchingClient = false;
            this.isVendor = false;
            this.isVendorEditDisabled = false;
            this.hideIsBusiness = false;
            this.showOptionalFields = true;
            this.isDisplayAvatar = false; //override for edit -display the avatar only in edit.
            this.isSuggestFromGmailContacts = false;
            this.isSuggestFromContacts = false;
            this.fetchUserFromContacts = true;
            this.suggestedContacts = [];

            this.showDropdown = false;

            this.USER_TYPES = {
                CLIENT: 'Client',
                COLLABORATOR: 'Collaborator',
                TEAM_MEMBER: 'Team member'
            };
            if (ModelFactory) {
                this._setupTabs(this.USER_TYPES.CLIENT);
            }

            this.selectedUserType = 0;
            this.shouldShowUserType = true;
            this.businessDataOpen = false;
            this.isClient = true;
            this.isProcessingTeamMember = false;

            if (this.isMobile && this.HelpService) {
                this.HelpService.hideHelp();
            }

            if (_) {
                this.searchSuggestions = _.debounce(this._searchSuggestions.bind(this), 500);
            } else {
                this.searchSuggestions = this._searchSuggestions.bind(this);
            }
        },

        _onCloseModal: function () {
            if (this.isMobile && this.HelpService) {
                this.HelpService.restoreDefaultVisibility();
            }
        },

        _setupTabs: function(defaultTab) {
            this.setUserType(defaultTab, null);
            this.userTypeOptions = [
                {name: this.USER_TYPES.CLIENT, translationKey: '_CLIENT_', shouldShow: (defaultTab === this.USER_TYPES.CLIENT), key: 0},
                {name: this.USER_TYPES.COLLABORATOR, translationKey: '_COLLABORATOR_', shouldShow: true, key: 1},
                {name: this.USER_TYPES.TEAM_MEMBER, translationKey: '_TEAM_MEMBER_', shouldShow: true, key: 2},
            ];
        } ,

        _setupClientVendorSeparationVars: function (featuresService, usersManager) {
            this.currUser = usersManager.getCurrUser();

            if(this.isVendor) {
                this.hideIsBusiness = true;
                this.shouldShowUserType = false;
                this.businessDataOpen = true;
            }
        },

        shouldShowWillInviteFooter: function () {
            return false;
        },

        onUserTypeChanged: function () {
            this.afterIsVendorChanged();
        },

        afterIsVendorChanged: function () {
            //to be overridden. we used to change the cta text here for example, in the current design it is the same.
        },

        onEmailChange: function () { //override this for edit mode. we don't want to reset the user._id. and we don't want to display drop down.
            //custom error will usually be server side error, so need to reset it.
            this.customEmailErrorMessage = "";
            this.addUserForm.email.$setValidity("customError", true); // set it to valid.

            this.isFetchingClient = true;
            this.getUserFromDb().finally(function () {
                this.isFetchingClient = false;
            }.bind(this));

        },

        getUserFromDb: function () {
            var dbUser = this.ModelFactory.newUserModel();

            return this._fetchUserFromDb(this.user, dbUser).then(function () {
                this.handleUserFromDb(dbUser);
                if (!dbUser.email && this.user.email) {
                    this.validateEmailAddress(this.user.email);
                } else {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_user_dialog_found_user_by_email, {user_email: dbUser.email});
                }
            }.bind(this));
        },

        validateEmailAddress: function (email) {
            this.ValidationsManager.validateEmail(email).then(function () {
                this.hideEmailWarning();
            }.bind(this), function (resp) {
                if (resp.data && resp.data.error_message === "Invalid Email Address") {
                    this.showEmailWarning(email);
                } else if (userModel.__warnInvalidEmail) {
                    this.hideEmailWarning();
                }
            }.bind(this));
        },

        onEmailTextChange: function () {
            this.hideEmailWarning();
            this.submitError = null;
            this.searchSuggestions(this.addUserForm.email.$viewValue);
        },

        onEmailTabKeyClicked: function () {
            this.hideDropdown();
        },

        handleUserFromDb: function handleUserFromDb(dbUser) { //override this for add connection, should always be disabled true.
            if (dbUser._id) {
                this.fetchedUserFromDb = true;
                this.isVendorEditDisabled = true;
                this.user = angular.extend({}, dbUser);

                this.afterIsVendorChanged();
            } else { //user doesn't exist
                if (dbUser.email) { // contact was found - no user of correct type
                    this.user = angular.extend(this.getStrippedBaseDataFromDBUser(dbUser), this.user);
                } else {
                    this.user._id = null;
                }
                this.isVendorEditDisabled = false;
            }
        },

        onSaveClick: function (e, form) {
            e.preventDefault();
            this.addUserForm.$setSubmitted();

            if (!form.$valid) {
                this.showErrorMessage = true;
                return;
            }

            if(this.workspace && this.workspace.members && this.workspace.members.map(member => member.email).includes(this.user.email)){
                this.submitError = this.$translate.instant("ADD_USER_DIALOG._ERROR_CLIENT_ALREADY_EXISTS_");
                return;
            }

            this.isSaving = true;

            this.saveUser()
                .then(function (resp) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_user_dialog_save_user_success);
                        this.$modalInstance.close(resp);
                    }.bind(this),
                    function (resp) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_user_dialog_save_user_error, resp.data.error_message);
                        this.submitError = resp.data.error_message;
                    }.bind(this))
                .finally(function () {
                    this.isSaving = false;
                }.bind(this));
        },

        dismiss: function () {
            this.$modalInstance.dismiss();
            this._onCloseModal();
        },

        saveUser: function () {
            throw "unimplemented";
        },

        close: function (resp) {
            this.$modalInstance.close(resp);
            this._onCloseModal();
        },

        showTypeahead: function () {
            angular.element('.js-typeahead-dropdown-control + .f-dropdown').removeClass('hidden');
        },

        onCompanyTypeChanged: function ($item) {

            var selectedCompanyTypeName = $item || this.user.company.company_type_name;

            var selectedCompanyType = this._.find(this.companyTypes, function (companyType) {
                return this.$translate.instant(companyType.text) === selectedCompanyTypeName;
            }.bind(this));

            if (selectedCompanyType) {
                this.user.company.company_type = selectedCompanyType.key;
                angular.element('.js-typeahead-dropdown-control + .f-dropdown').addClass('hidden');
            } else if (selectedCompanyTypeName) {
                this.user.company.company_type = "other";
                this.user.company.company_other = selectedCompanyTypeName;
            } else {
                this.user.company.company_type = null;
                this.user.company.company_other = null;
            }
        },

        _searchSuggestions: function (searchText) {

            if (!searchText) {
                this.showDropdown = false;
                return;
            }

            if (searchText.length < 3) {
                this.showDropdown = false;
                this.clearSuggestions();
                return;
            }

            if (this.isSuggestFromGmailContacts) {
                this.getGmailContacts(searchText);
            }
            if (this.isSuggestFromContacts) {
                this.searchContacts(searchText);
            }
            if (this.isSuggestFromVendorContacts) {
                this.searchVendorContacts(searchText);
            }

            this.showDropdown = true;
        },

        getGmailContacts: function (searchText) {
            this.getContactsToView(searchText);
        },

        onSearchItemClick: function (selectedUser) {
            this._copySearchResultToUser(selectedUser);

            //do we need to pass the avatar here?
            this.showDropdown = false;
            this.onEmailChange();
        },

        onGoogleSearchItemClick: function (selectedContact) {
            this._copySearchResultToUser(selectedContact);

            //do we need to pass the avatar here?
            this.showDropdown = false;
            this.onEmailChange();

        },

        _copySearchResultToUser: function _copySearchResultToUser(selectedResult) {
            this.user._id = selectedResult.user_id;
            this.user.email = selectedResult.email;
            this.user.full_name = selectedResult.full_name;
            this.user.phone_number = selectedResult.phone_number;
            this.user.address = selectedResult.address;

            this.user.company.company_type = selectedResult.company_type;
            this.user.company.company_name = selectedResult.company_name;
            this.user.company.website_url = selectedResult.website_url;

            this.user.notes = selectedResult.notes;
        },

        searchContacts: function (searchText) {
            this.searchData = this.SearchablesManager.search(searchText, "contacts");
        },

        searchVendorContacts: function (searchText) {
            this.searchData = this.SearchablesManager.search(searchText, "vendorContacts");
        },

        connectGoogleContacts: function () {
            var self = this;

            this.UserService.connectToAGoogleAccountWithPopUp(null, 'contacts')
                .then(function onSuccess() {
                this.AnalyticsService.track(self, self.AnalyticsService.analytics_events.connect_to_google_successful);
                    this.showConnectToGoogle = false;
                    this.onEmailTextChange();
                }.bind(this))
                .catch(function onError() {
                    this.AnalyticsService.track(self, self.AnalyticsService.analytics_events.connect_to_google_unsuccessful);
                    this.showConnectToGoogle = false;
                }.bind(this));
        },

        connectGoogleOptionShown: function () {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.connect_to_google_option_show);
        },

        clearSuggestions: function () {
            this.suggestedContacts = [];
            this.searchData = [];
            this.showConnectToGoogle = false;
        },

        getContactsToView: function (search) {
            var self = this;

            if (search === undefined) {
                search = null;
            }

            if (!this.UsersManager.getCurrUser().isGoogleIntegrationActive('contacts')) {
                this.showConnectToGoogle = true;
                return;
            }

            this.isLoadingContacts = true;
            this.UserService.searchGoogleContacts(search, 1, 30, true)
                .then(function (searchResults) {
                    self.suggestedContacts = searchResults.contacts;
                })
                .finally(function () {
                    self.isLoadingContacts = false;
            });
        },

        getContacts: function (num, isFirstTime) {
            var self = this;

            if (isFirstTime == null) {
                isFirstTime = false;
            }

            this.contactsAPI.getNextContacts(num, isFirstTime).then(function (response) {

                if (self.isLoadingContacts) {
                    self.isLoadingContacts = false;
                }

                self.suggestedContacts = response.contacts;

            }, function (xhr) {
                return self.handleXHRError(xhr);
            });
        },

        showEmailWarning: function (email) {
            this.emailInvalidWarning = "* The email " + email + " seems to be invalid";
        },

        hideEmailWarning: function () {
            this.emailInvalidWarning = null;
        },

        hideDropdown: function () {
            this.showDropdown = false;
        },

        getNotesCounter: function () {
            var counter = '';
            if (this.user.notes && (this.user.notes.length >= this.NOTES_SETTINGS.MIN_CHARS_TO_SHOW_COUNTER)) {
                counter = this.NOTES_SETTINGS.MAX_LENGTH - this.user.notes.length;
            }
            return counter;
        },

        getFullNameErrorMessage: function getFullNameErrorMessage() {
            if (this.addUserForm.fullName.$error.required) {
                return this.$translate.instant('ERRORS._NAME_MISSING_');
            }
            if (this.addUserForm.fullName.$error.minlength) {
                return this.$translate.instant('ERRORS._NAME_TOO_SHORT_');
            }
            if (this.addUserForm.fullName.$error.maxlength) {
                return this.$translate.instant('ERRORS._NAME_MISSING_');
            }
        },
        getEmailErrorMessage: function () {
            var invalidEmailString = this.$translate.instant('ERRORS._INVALID_EMAIL_ADDRESS_');

            if (this.addUserForm.email.$error.email) {
                return invalidEmailString;
            } else if (!this.addUserForm.email.$error.email && this.addUserForm.email.$error.pattern) {
                return invalidEmailString;
            } else if (this.addUserForm.email.$error.required) {
                return invalidEmailString;
            } else if (this.addUserForm.customEmailErrorMessage) {
                return this.addUserForm.customEmailErrorMessage;
            }
        },

        _fetchUserFromDb: function _fetchUserFromDb(user, dbUser) {
            var promise = null;

            if (this.fetchUserFromContacts) {
                promise = this._fetchContact(user, dbUser);
            } else {
                promise = this.UsersManager.getUserByEmail(user.email, dbUser);
            }

            return promise;
        },

        _decide_user_id_for_legacy_ui: function _decide_user_id_for_legacy_ui(contact){
            let contact_user_id = null;
            // order is 1. team member 2. client 3. vendor - by whichever exists first.
            if(this.UsersManager.getCurrUser().company.isCompanyTeamMember(contact.vendor_id)){
                contact_user_id = contact.vendor_id;
                contact.user.system_user_type = 'vendor';
            } else if(contact.client_id){
                contact_user_id = contact.client_id;
                contact.user.system_user_type = 'client';
            } else {
                contact_user_id = contact.vendor_id;
                if(contact_user_id){
                    contact.user.system_user_type = 'vendor';
                }
            }
            return contact_user_id;
        },

        _decide_on_contact_user_id: function _decide_on_contact_user_id(contact) {
            let contact_user_id = null;
            if (this.isVendor) {
                contact_user_id = contact.vendor_id;
                contact.user.system_user_type = 'vendor';
            } else if (this.isClient) {
                contact_user_id = contact.client_id;
                contact.user.system_user_type = 'client';
            }

            return contact_user_id;
        },

        _fetchContact: function _fetchContact(user, dbUser) {
            const collection = this.ModelFactory.newContactsCollection();
            return this.ContactsManager.getUserContacts(collection, this.UsersManager.getCurrUser(), 1, {
                exact_email: true,
                text: user.email
            }, 1).then(function () {
                const contact = collection[0];
                if (contact) {
                    contact.user._id = this._decide_on_contact_user_id(contact);
                    dbUser.mixinFrom(contact.user);
                }
                return dbUser;
            }.bind(this));
        },

        handleXHRError: function handleXHRError(error) {
            // do something about it?
        },

        getStrippedBaseDataFromDBUser: function getStrippedBaseDataFromDBUser(dbUser) {
            const userData = {
                full_name: dbUser.full_name,
                phone_number: dbUser.phone_number,
                address: dbUser.address,
                notes: dbUser.notes
            };

            const companyData = dbUser.company ? {
                website_url: dbUser.company.website_url,
                company_name: dbUser.company.company_name,
                company_type: dbUser.company.company_type,
                company_type_name: dbUser.company.company_type_name
            } : null;

            userData.company = companyData;

            return this.ModelFactory.newUserModel().mixinFrom(userData);
        },

        setUserType: function setUserType(type, $event) {
            if(!!$event) {
                $event.stopPropagation();
                $event.preventDefault();
            }

            switch (type) {
                case this.USER_TYPES.CLIENT:
                    this.selectedUserType = 0;
                    this.isVendor = false;
                    this.isClient = true;
                    this.isVendorEditDisabled = false;
                    this.user.system_user_type = 'client';
                    this.subTitleText = this.$translate.instant("ADD_USER_DIALOG._ADD_CLIENT_SUBTITLE_");
                    break;
                case this.USER_TYPES.COLLABORATOR:
                    this.selectedUserType = 1;
                    this.isVendor = true;
                    this.isClient = false;
                    this.businessDataOpen = true;
                    this.isVendorEditDisabled = true;
                    this.user.system_user_type = 'vendor';
                    this.subTitleText = this.$translate.instant("ADD_USER_DIALOG._ADD_COLLABORATOR_SUBTITLE_");
                    break;
                case this.USER_TYPES.TEAM_MEMBER:
                    this.selectedUserType = 2;
                    this.isVendor = true;
                    this.isClient = false;
                    this.user.system_user_type = 'vendor';
                    this.subTitleText = this.$translate.instant("ADD_USER_DIALOG._ADD_TEAM_MEMBER_SUBTITLE_");
                    this.loadModels();
                    break;
            }
        },

        isUserTypeSelected: function isUserTypeSelected(type) {
            return type.key === this.selectedUserType;
        },

        loadModels: function loadModels() {
            this.isProcessingTeamMember = true;
            this.workspaceMembersHash = {};
            this.teamMembersCollection = [];
            var creator_id = this.workspace.creator_id;

            // map workspace members by id
            this.workspace.members.forEach(function processWorkspaceMember(member) {
                this.workspaceMembersHash[member._id] = member;
            }.bind(this));

            // add company owner
            if (this.currentUserId !== this.company.owner._id) {
                var ownerIsInWS = !!this.workspaceMembersHash[this.company.owner._id];
                this.teamMembersCollection.push({
                    member: this.company.owner,
                    isAdded: ownerIsInWS
                });
            }

            this.company.team_members.forEach(function processTeamMember(teamMember) {

                // dont add to the list if current user
                if (teamMember._id === this.currentUserId) {
                    return;
                }

                var isInWS = !!this.workspaceMembersHash[teamMember._id];
                this.teamMembersCollection.push({
                    member: teamMember,
                    isAdded: isInWS
                });

            }.bind(this));

            this.teamMembersCollection = this.teamMembersCollection.filter(function (member) {
                return member.member._id !== creator_id;
            });

            this.isProcessingTeamMember = false;
        },

        toggleTeamMember: function toggleTeamMember(member) {
            member.isAdded = !member.isAdded;
        },

        saveMembersToWorkspace: function saveMembersToWorkspace() {
            this.isProcessingTeamMember = true;
            this.isSaving = true;

            var oldMemberIds = this.workspace.members.filter(member => member.company._id === this.company._id && member._id !== this.workspace.creator_id).map(member => member._id);
            var newMemberIds = this.teamMembersCollection.map(function (member) {
                if (member.isAdded) {
                    return member.member._id;
                }
            });

            newMemberIds = newMemberIds.filter(n => !!n);
            var removedTeamMembers = oldMemberIds.filter(function (id) {
                return !newMemberIds.includes(id);
            });

            var addedTeamMembers = newMemberIds.filter(function (id) {
                return !oldMemberIds.includes(id);
            });

            this.WorkspacesManager
                .updateTeamMembersInWorkspace(this.workspace, addedTeamMembers, removedTeamMembers)
                .then(function success(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.add_user_dialog_save_user_success);
                    this.$modalInstance.close(resp);
                }.bind(this))
                .catch(function error(res) {
                    var message;
                    if (res && res.data && res.data.error_type) {
                        if (res.data.error_type === 'HBUserError') {
                            message = res.data.error_message;
                        } else {
                            this.$log.error(res.data.error_message);
                            message = 'ERRORS.SERVER_API._UNKNOWN_';
                        }
                    } else {
                        message = 'ERRORS.SERVER_API._UNEXPECTED_';
                    }

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                }.bind(this))
                .finally(function saveMemberDone() {
                    this.isSaving = false;
                }.bind(this));
        }

    });
}());
