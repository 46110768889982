// @ngInject

Models.ProposalModel = function ProposalModel(BaseModel, $translate, Enums) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'ProposalModel';

            this.cachable = false;

            var that = this;

            $translate('PROPOSAL.NEW_SERVICE._LABEL_').then(function translateServiceConstant(text) {
                that.NEW_SERVICE_NAME = text;
            });

            $translate('PROPOSAL.NEW_SERVICE._LABEL_IN_PACKAGE_').then(function translateServiceConstant(text) {
                that.NEW_ITEM_NAME = text;
            });
        },

        mapChilds: function mapChilds() {
            return {
                vendor_packages: 'PackagesCollection',
                package_services: 'ServicesCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {
            var self = this;
            if (this.vendor_packages && this.vendor_packages.length > 0) {
                this.vendor_packages.forEach(function (currPackage, index) {
                    currPackage.__vendor_proposal = self;

                    if (currPackage.base_services && currPackage.base_services.length) {
                        currPackage.base_services.forEach(function(currService) {
                           currService.__vendor_proposal = self;
                        });
                    }

                });
            }

            if (this.package_services && this.package_services.length > 0) {
                this.package_services.forEach(function (service, index) {
                    service.__vendor_proposal = self;
                });
            }
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        toggleTaxToAllPackages: function toggleTaxToAllPackages(addTax) {
            return this.__parent.toggleTaxToAllPackages(addTax);
        },

        toggleSvcToAllPackages: function toggleSvcToAllPackages(addSvc) {
            return this.__parent.toggleSvcToAllPackages(addSvc);
        },

        addPackage: function addPackage(packageToAddId) {
            return this.__parent.addPackage(this, packageToAddId);
        },

        addCustomItem: function addCustomItem() {
            return this.__parent.addCustomItem(this, this._createEmptyService());
        },

        updateService: function updateService(serviceModel, packageModel) {
            return this.__parent.updateService(this, serviceModel, packageModel);
        },

        updateServiceQuantity: function updateServiceQuantity(serviceModel, packageModel) {
            return this.__parent.updateServiceQuantity(this, serviceModel, packageModel);
        },

        updatePackageQuantity: function updatePackageQuantity(packageModel) {
            return this.__parent.updatePackageQuantity(this, packageModel);
        },

        updateProposalSelection: function updateProposalSelection(serviceModel, isSelected, packageModel) {

            // in order to make the update look fast
            if(this.selection_type === Enums.proposalSelectionType.selectOne ||
               this.selection_type === Enums.proposalSelectionType.selectOneRequired) {

                // remove selection
                this.unselectAll();

                // select
                if(isSelected) {
                    if(serviceModel) {
                        serviceModel.selected = true;
                    } else {
                        packageModel.selected = true;
                    }
                }
            }

            return this.__parent.updateProposalSelection(this, serviceModel, packageModel, isSelected);
        },

        updateServiceImage: function updateServiceImage(serviceModel, image, packageModel) {
            return this.__parent.updateServiceImage(this, serviceModel, image, packageModel);
        },

        deleteServiceImage: function deleteServiceImage(serviceModel, packageModel) {
            return this.__parent.deleteServiceImage(this, serviceModel, packageModel);
        },

        updateServicesOrder: function updateServicesOrder(packageModel) {
            return this.__parent.updateServicesOrder(this, packageModel);
        },

        updateVendorPackagesOrder: function updateVendorPackagesOrder(packagesOrder) {
            return this.__parent.updateVendorPackagesOrder(this, packagesOrder);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(order) {
            return this.__parent.updatePackagesAndServicesOrder(this, order);
        },

        moveService: function moveService(serviceId, sourcePackageId, destPackageId, servicesCollection) {
            return this.__parent.moveService(this, serviceId, sourcePackageId, destPackageId, servicesCollection);
        },

        findServiceModel: function findService(serviceId) {
            if (this.package_services && this.package_services.length > 0) {
                for (var i = 0, length = this.package_services.length; i < length; i++) {
                    if (this.package_services[i]._id === serviceId) {
                        return this.package_services[i];
                    }
                }
            }

            return null;
        },

        shouldShowTax: function () {
            return this.__parent.shouldShowTax(this);
        },

        shouldShowSVC: function () {
            return this.__parent.shouldShowSVC(this);
        },

        deleteService: function deleteService(serviceModel, packageModel) {
            return this.__parent.deleteService(this, serviceModel, packageModel);
        },

        updatePackage: function updatePackage(packageModel) {
            return this.__parent.updatePackage(this, packageModel);
        },

        deletePackage: function deletePackage(packageModel) {
            return this.__parent.deletePackage(this, packageModel);
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(packageModel) {
            return this.__parent.addAdditionalServiceToPackage(this, packageModel, this._createEmptyService(true));
        },

        updateProposal: function updateProposal(data) {
            return this.__parent.updateProposal(data);
        },

        updateProposalOnBlock: function updateProposalOnBlock(data) {
            return this.__parent.updateProposalOnBlock(data);
        },

        updateProposalSelectionTypeOnBlock: function updateProposalSelectionTypeOnBlock(type) {
            return this.updateProposalOnBlock({selection_type: type});
        },

        updateProposalQuantitySettingsOnBlock: function updateProposalQuantitySettingsOnBlock(enable) {
            return this.updateProposalOnBlock({enable_quantity: enable});
        },

        calculateTax: function calculateTax(type, tax, taxOrSvc) {
            return this.__parent.calculateProposalTax(this, type, tax, taxOrSvc);
        },

        getSelectedPackageOrServiceCount: function getSelectedPackageOrServiceCount() {

            var selectedPackages = this.vendor_packages.filter(function(p) {
                return p.selected;
            });

            var selectedServices = this.package_services.filter(function(s) {
                return s.selected;
            });

            return (selectedPackages.length + selectedServices.length);
        },

        hasSelectedPackageOrService: function hasSelectedPackageOrService() {
            return this.getSelectedPackageOrServiceCount() > 0;
        },

        ////////////////////////////
        // Brochure Proposal API  //
        ////////////////////////////
        updateBrochureProposalImage: function updateBrochureProposalImage(selectedImage) {
            return this.__parent.updateBrochureProposalImage(this, selectedImage);
        },

        _createEmptyService: function _createEmptyService(isAddedToPackage) {
            return {name: isAddedToPackage ? this.NEW_ITEM_NAME : this.NEW_SERVICE_NAME, price: "0"};
        },

        isInvoice: function isInvoice() {
            return this.__parent.isInvoice();
        },

        getWorkspaceFile: function _getWorkspaceFile() {
            return this.__workspace_file;
        },

        isEmpty: function isEmpty() {
            return ((!this.package_services || !this.package_services.length) &&
                    (!this.vendor_packages || !this.vendor_packages.length));
        },

        isSelectable: function isSelectable() {
            return this.selection_type !== Enums.proposalSelectionType.noSelect;
        },

        isSelectionValid: function isSelectionValid() {

            if(!this.selection_type ||
                this.selection_type === Enums.proposalSelectionType.noSelect ||
                this.selection_type === Enums.proposalSelectionType.selectOne ||
                this.selection_type === Enums.proposalSelectionType.selectMultiple) {
                return true;
            }

            var selectedCount = this.getSelectedPackageOrServiceCount();

            if(this.selection_type === Enums.proposalSelectionType.selectOneRequired) {
                return selectedCount === 1;
            }

            if(this.selection_type === Enums.proposalSelectionType.selectMultipleRequired) {
                return selectedCount > 0;
            }

            // default
            return true;
        },

        unselectAll: function unselectAll() {
            var allItems = this.vendor_packages.concat(this.package_services);
            allItems.forEach(function(item) {
                item.selected = false;
            });
        },

        canClientUpdateQuantity: function canClientUpdateQuantity() {
            return !!this.enable_quantity;
        },

        hasAppliedVoucher: function hasAppliedVoucher() {
            return !!this.voucher_id;
        }
    });
};

