(function () {
    "use strict";

    // @ngInject
    function GoogleTagManagerServiceCtor($window, $log, $injector, moment, AnalyticsService, sha256) {
        this.$window = $window;
        this.$log = $log;
        this.$injector = $injector;
        this.$window.dataLayer = this.$window.dataLayer || [];
        this.sha256 = sha256;
        this.moment = moment;
        this.AnalyticsService = AnalyticsService;

        this.targetEventVerticals = [
            'officiant',
            'photographer',
            'catering',
            'cinematography',
            'event_planner',
            'stationery',
            'florist',
            'rental',
            'venue',
            'calligrapher'
        ];
        this.targetNonEventVerticals = [
            'virtual_assistant',
            'interior_designer',
            'doula',
            'web_designer',
            'coaching',
            'agency',
            'graphic_designer',
            'marketing',
            'other',
            'social_media',
            'freelance',
            'business_consultant'
        ];

    }

    Services.GoogleTagManagerService = Class(() => {

        return {
            constructor: GoogleTagManagerServiceCtor,

            getEnhancedConversionData(user) {
                const ecData = {};
                if (user) {
                    ecData.email = this.sha256(user.email);
                    ecData.phone_number = user.phone_number ? this.sha256(user.phone_number) : null;
                }

                return ecData;
            },

            trackEvent(event, attributes) {
                var tag = { event: event };
                this.$log.debug('[tracking: GoogleTagManager] ' + event + ', ' + JSON.stringify(attributes));
                this.pushEvent(Object.assign(tag, attributes));
            },

            pushEvent(attributes) {
                this.$window.dataLayer.push(attributes);
            },

            onLogin() {
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                var user = this.UsersManager.getCurrUser();
                var attributes = {
                    sha256_user_email: this.sha256(user.email),
                    sha256_user_phone: user.phone_number ? this.sha256(user.phone_number) : null
                };
                this.$window.dataLayer.push(attributes);
            },

            onStartTrial() {
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                const user = this.UsersManager.getCurrUser();
                const vertical = user.company.company_type;

                const pushTrialEvent = (eventName) => {
                    this.pushEvent({
                        'event': eventName,
                        'user_id': user._id,
                        'sha256_user_email': this.sha256(user.email),
                        'vertical': user.company.company_type_name
                    });
                };

                pushTrialEvent('start_trial');

                if (this.targetEventVerticals.includes(vertical)) {
                    pushTrialEvent('tev_start_trial');
                }

                if (this.targetNonEventVerticals.includes(vertical)) {
                    pushTrialEvent('tnev_start_trial');
                }
            },

            onStartTrialClicked({ email, phone_number }) {
                this.trackEvent('gtm.click', {
                    ecData: {
                        email,
                        phone_number,
                    },
                    'gtm.elementId': 'intakeFormSubmit'
                });
            },

            onBankAccountAdded() {
                var now = this.moment.utc();
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                var user = this.UsersManager.getCurrUser();
                var trialStartedDate = user.getTrial().start_date;

                if (now.diff(trialStartedDate, 'days') < 1) {
                    this.pushEvent({
                        'event': 'bank_account_added',
                        'user_id': user._id,
                        'sha256_user_email': this.sha256(user.email),
                        'bank_account_added_date': now.format(),
                        'trial_started_date': trialStartedDate,
                        'ecData': this.getEnhancedConversionData(user)
                    });
                    this.AnalyticsService.reportEvent('Bank Account Added');
                }
            },

            onCompanyIconUpload() {
                var now = this.moment.utc();
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                var user = this.UsersManager.getCurrUser();
                var trialStartedDate = user.getTrial().start_date;

                if (now.diff(trialStartedDate, 'days') < 1) {
                    this.pushEvent({
                        'event': 'company_icon_upload',
                        'user_id': user._id,
                        'sha256_user_email': this.sha256(user.email),
                        'company_icon_upload_date': now.format(),
                        'trial_started_date': trialStartedDate,
                        'ecData': this.getEnhancedConversionData(user)
                    });
                    this.AnalyticsService.reportEvent('Company Icon Uploaded');
                }
            },

            onProfileImageUpload() {
                var now = this.moment.utc();
                this.UsersManager = this.UsersManager || this.$injector.get('UsersManager');
                var user = this.UsersManager.getCurrUser();
                var trialStartedDate = user.getTrial().start_date;

                if (now.diff(trialStartedDate, 'days') < 1) {
                    this.pushEvent({
                        'event': 'profile_image_upload',
                        'user_id': user._id,
                        'sha256_user_email': this.sha256(user.email),
                        'profile_image_upload_date': now.format(),
                        'trial_started_date': trialStartedDate,
                        'ecData': this.getEnhancedConversionData(user)
                    });
                    this.AnalyticsService.reportEvent('Profile Image Uploaded');
                }
            },
        };
    });
}());

