(function () {
    'use strict';
    class AddListItemButton extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'AddListItemButton';
        }
    }

    Components.AddListItemButton = {
        bindings: {
            onCreateNew: '&',
            className: '@?'
        },
        controller: AddListItemButton,
        name: 'AddListItemButton',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_add_list_item_button/meeting_add_list_item_button.html'
    };
}());