Directives.BankAccountDirective = function BankAccountDirective() {
    'use strict';

    class BankAccountDirectiveController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, ModalService, PopupMessageService, AnalyticsService,
                    NotificationService, UsersManager, PendingTasksManager) {
            super($scope, $injector);

            this.__objectType = 'BankAccountDirectiveController';

            this.UsersManager = UsersManager;
            this.PendingTasksManager = PendingTasksManager;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.NotificationService = NotificationService;
            this.ModalService = ModalService;
            this.deletingAccountInProcess = false;
        }

        handleRemoveAccount(accountId) {
            this.AnalyticsService.trackClick(this, 'remove bank account');
            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.warning,
                'BANK_INFO._DELETE_CONFIRM_MESSAGE_').then(() => {
                this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.bank_account_deleted);
                this.deselectAccount(accountId);
            }).catch(() => {
                this.AnalyticsService.trackCancel(this, 'remove bank account');
            });
        }

        deselectAccount(accountId) {
            this.deletingAccountInProcess = true;

            this.UsersManager.deselectBankAccount(this.user._id, accountId)
                .then((resp) => {
                this.deletingAccountInProcess = false;
                this.accounts = resp.data;
                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.bank_account_deleted);
                this.onChange();
            }).catch((error) => {
                this.deletingAccountInProcess = false;
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.bank_account_delete_error, error);
            });
        }
    }

    return {
        restrict: 'E',
        templateUrl: 'angular/app/modules/core/features/bank_account/bank_account_template.html',
        controller: BankAccountDirectiveController,
        controllerAs: 'bankAccountVm',
        bindToController: true,
        scope: {
            user: '=user',
            accounts: '=accounts',
            onChange: '&'
        }
    };
};
