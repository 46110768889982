// @ngInject
Filters.HbTruncFilter  = function HbTruncFilter($filter) {
    return function(text, length, truncType) {
        if (!text || !length || text.length <= length) {
            return text || '';
        }
        var ret = text.substring(0,length);
        if (truncType === 'ellipsis') {
            ret = ret + '...';
        }
        return ret;
    };
};