Directives.MessageComposerDirective = function MessageComposerDirective() {

    // @ngInject
    function MessageComposerDirectiveControllerFunc($timeout,$scope, $injector, uuid4) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'MessageComposerDirectiveController';
        this.$timeout = $timeout;
        this.uuid4 = uuid4;

        var that = this,
            resetLiveLastMessageReference = false,
            _sendFx = function() {
                if (!angular.isDefined(that.rawmessage) || !that.rawmessage.trim().length) {
                    return;
                }
                var _message = {
                    _id: that.uuid4.generate(),
                    type: 'message',
                    text: that.rawmessage,
                    user: {
                        _id: that.localUser._id,
                        avatar: that.localUser.profile_image ? that.localUser.profile_image.url : "",
                        userName: that.localUser.full_name
                    },
                    sent: false,
                    date: Date.now()
                };
                if (that.options.liveMode && angular.isDefined(that.liveFlagFunction) && that.rawmessage.length === 1) {
                    that.liveFlagFunction({
                        id: 'sc' + Date.now(),
                        type: 'flag',
                        userId: that.localUser._id,
                        label: 'startSentence'
                    });
                }
                if (!resetLiveLastMessageReference) {
                    that.sendFunction({message: _message});
                }
                // if (that.options.liveMode && !resetLiveLastMessageReference) {
                //     if (that.messages.length === 0) {
                //         that.messages.push(_message);
                //     } else {
                //         if (that.rawmessage.length === 1) {
                //             that.messages.push(_message);
                //         } else {
                //             that.messages[that.messages.length - 1] = _message;
                //         }
                //     }
                // } else if (that.options.liveMode && resetLiveLastMessageReference) {
                //     resetLiveLastMessageReference = false;
                //     that.rawmessage = '';
                //     that.liveFlagFunction({
                //         _id: 'sc' + Date.now(),
                //         type: 'flag',
                //         userId: that.localUser.userId,
                //         label: 'endSentence'
                //     });
                // } else {
                //     that.messages.push(_message);
                // }
                $scope.$emit('simple-chat-message-posted');
                if (!that.options.liveMode) {
                    that.rawmessage = '';
                }
            };
        this._send = function() {
            if (this.options.liveMode) {
                resetLiveLastMessageReference = true;
            }
            _sendFx();
        };
        this._onKeyUp = function() {
            if (this.options.liveMode) {
                _sendFx();
            }
        };

    }

    var MessageComposerDirectiveController = Class(Controllers.BaseController, {
        constructor: MessageComposerDirectiveControllerFunc,

    });


    function link(scope, element, attrs, controllers) {
        var simpleChatCtrl = controllers[0],
            messageComposerCtrl = controllers[1];

        messageComposerCtrl.localUser = simpleChatCtrl.localUser;
        messageComposerCtrl.sendButtonText = simpleChatCtrl.sendButtonText;
        messageComposerCtrl.composerPlaceholderText = simpleChatCtrl.composerPlaceholderText;
        messageComposerCtrl.options = simpleChatCtrl.options;
        messageComposerCtrl.messages = simpleChatCtrl.messages;
        messageComposerCtrl.liveFlagFunction = simpleChatCtrl.liveFlagFunction;
    }

    return {
        controller: MessageComposerDirectiveController,
        controllerAs: 'mc',
        scope: {
            sendFunction: '&'
        },
        link: link,
        bindToController: true,
        restrict: 'AE',
        require: ['^simpleChat', 'messageComposer'],
        templateUrl: 'angular/app/modules/core/features/chat/message-composer/message_composer_directive_template.html'
    };
};
