
// @ngInject

Models.ContactsCollection = function ContactsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'ContactsCollection';
                this._collection.__childsType = 'ContactModel';
                this._collection.shouldMergeOnLoad = false;
                return this._collection;
            },

        clear: function clear() {
            this.length = 0;
            this._collection.__modelIds = {};
            this.__modelIds = {};
            }
        }
    );

};
