(function () {
    'use strict';

    Directives.VisibilityPanelDirective = function VisibilityPanelDirective() {

        // @ngInject
        function VisibilityPanelDirectiveControllerFunc($scope, $injector, AnalyticsService) {

            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'VisibilityPanelDirectiveController';
            this.AnalyticsService = AnalyticsService;

        }


        var VisibilityPanelDirectiveController = Class(Controllers.BaseController, {

            constructor: VisibilityPanelDirectiveControllerFunc,

        });

        return {
            scope: {
                sampleUserNames: '@',
                usersCounter: '@'
            },
            templateUrl: 'angular/app/modules/common/ui_components/visibility-panel/visibility_panel_template.html',
            controller: VisibilityPanelDirectiveController,
            controllerAs: 'visibilityPanelVm',
            bindToController: true
        };
    };


}());