/**
 * This directive is for 'blur'ing from an input field when enter key is pressed
 */
Directives.BlurOnEnter = function BlurOnEnter() {

    // @ngInject
    function BlurOnEnterControllerFunc($element, $scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlurOnEnterController';
        $element.bind('keydown', function(evt) {
            if (evt.which === 13) {
                $element.trigger('blur');
            }
        });
    }

    var BlurOnEnterController = Class(Controllers.BaseController, {
        constructor: BlurOnEnterControllerFunc
    });

    return {
        restrict: 'A',
        controller: BlurOnEnterController
    };
};
