(function () {
  "use strict";

  Controllers.MyTemplatesController = class MyTemplatesController extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, UsersManager, ModalService, AppStates, $state, FlowsBetaUserTypeService,
                AnalyticsService, DeviceService, OtamStatusService) {
      super($scope, $injector);
      this.__objectType = 'MyTemplatesController';
      this.ModalService = ModalService;
      this.user = UsersManager.getCurrUser();
      this.redirectToFolderId = $state.params.redirectToFolderId;
      this.redirectToPanel = $state.params.redirectToPanel;
      this.isMobile = DeviceService.nxSmallBreakpoint();
      this.OtamStatusService = OtamStatusService;
      FlowsBetaUserTypeService.refreshFlowBetaUserType();
      if (!FlowsBetaUserTypeService.hasNewFlowExp) {
        $state.go(AppStates.root_core_navigation_templates);
      }
      AnalyticsService.reportEvent('ViewTemplates');
    
      if (!this.isMobile) {
        const queryParams = document.location.href.split('?')[1];
        if((queryParams && (queryParams.includes('pre_otam') || queryParams.includes('show_otam')) || $state.params.pre_otam === 'inProduct') && this.OtamStatusService.shouldSeePreOtamModal()) {
            const source = $state.params.pre_otam === 'inProduct' ? 'try it banner' : 'marketingEmail';
            this.ModalService.openPreMigrateUserModal(source);
        }
    }
    }
  };

}());
