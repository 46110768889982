(function () {
    'use strict';

    class ReschedulePaymentsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, WorkspacesManager, CompaniesManager, _, $q, RescheduleProjectService, WorkspaceFileService) {
            super($scope, $injector);
            this.__objectType = 'ReschedulePaymentsController';
            this.WorkspacesManager = WorkspacesManager;
            this.company = CompaniesManager.getCurrCompany();
            this.RescheduleProjectService = RescheduleProjectService;
            this.WorkspaceFileService = WorkspaceFileService;
            this._ = _;
            this.$q = $q;
        }

        $onInit() {
            const sortedFiles = this._.sortBy(this.files, (f) => f.sent_on);
            const [expiredFiles, unexpiredFiles] = this._.partition(sortedFiles, (f) => f.isCanceled());
            this.sortedFiles = unexpiredFiles.concat(expiredFiles);

            const promises = [];

            this.loading = true;

            this.sortedFiles.forEach((file) => {
                if (!this.RescheduleProjectService.wasDraftCreatedForFile(file) && !file.isRecurringPayment() && !file.isCanceled()) {
                    promises.push(this.RescheduleProjectService.makeFileEditable(file));
                }
            });

            this.$q.all(promises).then(() => {
                this.loading = false;
            });
        }

        complete() {
            this.WorkspaceFileService.validateFileModel().then(() => {
                this.onComplete && this.onComplete();
            });
        }

        dismiss() {
            this.onDismiss && this.onDismiss();
        }
    }

    Components.ReschedulePaymentsComponent = {
        bindings: {
            files: '<',
            onComplete: '&',
            onDismiss: '&',
            nextStepTitle: '<'
        },
        controller: ReschedulePaymentsController,
        name: 'hbReschedulePayments',
        templateUrl: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_payments_template.html',
    };

}());