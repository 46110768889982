
Directives.FadeInDirective = function FadeInDirective() {

    // @ngInject
    function FadeInDirectiveControllerFunc($element, $timeout, $scope, $injector, $) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FadeInDirectiveController';

        $element.css('display', 'none');
        $timeout(function (){
            $element.fadeIn("slow");
        }.bind(this), 100);

    }


    var FadeInDirectiveController = Class(Controllers.BaseController, {
        constructor: FadeInDirectiveControllerFunc
    });

    return {

        controller: FadeInDirectiveController,
        controllerAs: 'fadeInVm',
        bindToController: true
    };
};
