'use strict';

(function () {
    // @ngInject
    function WebPNServiceCtor(Routes, APIService, UsersManager, $q, $window, Gon) {
        this.Routes = Routes;
        this.APIService = APIService;
        this.UsersManager = UsersManager;
        this.$q = $q;
        this.$window = $window;
        this.Gon = Gon;
        this.isPushEnabled = false;

        // Check that service workers are supported, if so, progressively
        // enhance and add push messaging support, otherwise continue without it.
        // also avoid registering in e2e envs to avoid redundant http calls and\or caching
        var isE2E = $window.location.href.includes(".e2e.hbdev.io") || $window.location.href.includes("e2e.stg-us-east-1-v7.hbdev.io");
        if ('serviceWorker' in $window.navigator && !isE2E) {
            $window.navigator.serviceWorker.register('/sw.js').then(this.initialiseState.bind(this));
        }

    }

    Services.WebPNService = Class({

        constructor: WebPNServiceCtor,

        subscribe: function subscribe() {
            if (!('PushManager' in this.$window)) {
                // console.log('Push messaging isn\'t supported.');
                return;
            }

            this.$window.navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                serviceWorkerRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: new Uint8Array(this.Gon.vapid_public_key)
                })
                    .then(function (subscription) {
                        this.isPushEnabled = true;
                        return this.sendSubscriptionToServer(subscription);
                    }.bind(this))
                    .catch(function (e) {
                        if (Notification.permission === 'denied') {
                            // The user denied the notification permission which
                            // means we failed to subscribe and the user will need
                            // to manually change the notification permission to
                            // subscribe to push messages
                            // console.log('Permission for Notifications was denied');
                        } else {
                            // A problem occurred with the subscription, this can
                            // often be down to an issue or lack of the gcm_sender_id
                            // and / or gcm_user_visible_only
                            // console.log('Unable to subscribe to push.', e);
                        }
                    }.bind(this));
            }.bind(this));
        },

        unsubscribe: function unsubscribe() {
            if (!('PushManager' in this.$window)) {
                // console.log('Push messaging isn\'t supported.');
                return;
            }

            if (this.$window.navigator.serviceWorker) {
                this.$window.navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                    // To unsubscribe from push messaging, you need get the
                    // subcription object, which you can call unsubscribe() on.
                    serviceWorkerRegistration.pushManager.getSubscription().then(
                        function (pushSubscription) {
                            // Check we have a subscription to unsubscribe
                            if (!pushSubscription) {
                                // console.log('no existing subscription');
                                // No subscription object, so set the state
                                // to allow the user to subscribe to push
                                this.isPushEnabled = false;
                                // todo: update ui to show a "currently disabled" state and "enable" button
                                return;
                            }

                            // console.log('we have a subscription');

                            // TODO: Make a request to your server to remove
                            // the users data from your data store so you
                            // don't attempt to send them push messages anymore
                            this.sendUnsubscribeToServer(pushSubscription);

                            // We have a subcription, so call unsubscribe on it
                            pushSubscription.unsubscribe().then(function () {
                                // console.log('iinside unsubscribe');
                                this.isPushEnabled = false;
                                // todo: update ui to show a "currently disabled" state and "enable" button
                            }.bind(this)).catch(function (e) {
                                // We failed to unsubscribe, this can lead to
                                // an unusual state, so may be best to remove
                                // the subscription id from your data store and
                                // inform the user that you disabled push

                                // console.log('Unsubscription error: ', e);
                            });
                        }.bind(this)
                    ).catch(function (e) {
                        // console.log('Error thrown while unsubscribing from push messaging.', e);
                    });
                }.bind(this))
            }
        },

        // Once the service worker is registered set the initial state
        initialiseState: function initialiseState() {
            // Are Notifications supported in the service worker?
            if (!(this.$window.ServiceWorkerRegistration && 'showNotification' in this.$window.ServiceWorkerRegistration.prototype)) {
                // console.log('Notifications aren\'t supported.');
                return;
            }

            // Check the current Notification permission.
            // If its denied, it's a permanent block until the
            // user changes the permission
            if (this.$window.Notification && this.$window.Notification.permission === 'denied') {
                // console.log('The user has blocked notifications.');
                return;
            }

            // Check if push messaging is supported
            if (!('PushManager' in this.$window)) {
                // console.log('Push messaging isn\'t supported.');
                return;
            }

            // We need the service worker registration to check for a subscription
            if (this.$window.navigator.serviceWorker) {
                this.$window.navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                    // Do we already have a push message subscription?
                    serviceWorkerRegistration.pushManager.getSubscription()
                        .then(function (subscription) {
                            // Enable any UI which subscribes / unsubscribes from
                            // push messages.

                            if (!subscription) {
                                // We aren’t subscribed to push, so set UI
                                // to allow the user to enable push
                                return;
                            }

                            // Keep your server in sync with the latest subscription
                            this.sendSubscriptionToServer(subscription);

                            // Set your UI to show they have subscribed for
                            // push messages
                            // todo: update ui ?
                            this.isPushEnabled = true;
                        }.bind(this))
                        .catch(function (err) {
                            // console.log('Error during getSubscription()', err);
                        });
                }.bind(this));
            }
        },

        sendSubscriptionToServer: function sendSubscriptionToServer(subscription) {
            this.APIService.create(Routes.v2_add_user_webpn_endpoint_path(this.UsersManager.getCurrUser()._id), {
                subscription: subscription
            });
        },

        sendUnsubscribeToServer: function sendUnsubscribeToServer(subscription) {
            this.APIService.create(Routes.v2_remove_user_webpn_endpoint_path(this.UsersManager.getCurrUser()._id), {
                endpoint: subscription.endpoint
            });
        }
    })
})();
