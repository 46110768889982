(function () {

    // @ngInject
    function CompanySettingsTeamControllerCtor($scope, $injector, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsTeamController';

        // ng2react - Team settings
        this.teamSettingsVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-company-team-settings').then(function (isEnabled) {
            this.teamSettingsVariant = isEnabled ? 'react' : 'angular';
        }.bind(this)).catch(function () {
            this.teamSettingsVariant = 'angular';
        }.bind(this));
    }

    Controllers.CompanySettingsTeamController = Class(Controllers.BaseController, {
        constructor: CompanySettingsTeamControllerCtor,
    });

}());
