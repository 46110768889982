(function () {
  
    "use strict";

    Controllers.PlaidOAuthController = class PlaidOAuthController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, AppStates, PopupMessageService, AnalyticsService) {
            super($scope, $injector);
            
            this.__objectType = 'PlaidOAuthController';
            this.AppStates = AppStates;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;

            this.AnalyticsService.track(this, 'Arrived at OAuth redirect page', {});

            const redirectData = this.loadRedirectData();
            if(redirectData){
                this.handleRedirect(redirectData);
            }  else {
                this.handleError();
            }
        }
        
        loadRedirectData() {
            return JSON.parse(localStorage.getItem('oauth-plaid-redirect'));
        }
        
        getOAuthStateId() {
            // const oAuthStateId = document.location.href.split('?')[1].replace('oauth_state_id=','');
            const queryParams = document.location.href.split('?')[1];
            if(!queryParams || !queryParams.includes('oauth_state_id') || queryParams.includes('&')){
                this.handleError()
            }
            return queryParams.replace('oauth_state_id=','');
        }
        
        handleError(msg = 'Something went wrong while connecting to Plaid, please try again.') {
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, msg);
            this.AnalyticsService.track(this, msg, {});
            setTimeout(() => {
                this.goToState(this.AppStates.root_core_navigation_home)
            },3000)
        }
        
        handleRedirect(redirectData) {
            const timeSince = Date.now() - redirectData.saveTime;
            if (timeSince > 1000 * 60 * 2) {
                this.handleError('Connecting plaid took too long, please try again.');
                localStorage.removeItem('oauth-plaid-redirect')
            } else {
                this.AnalyticsService.track(this, 'Redirect successful, moving the user back.', {});
                const oAuthStateId = this.getOAuthStateId();
                document.location = `${redirectData.originalUrl}?oauth_state_id=${oAuthStateId}`;
            }
        }
    };
}());