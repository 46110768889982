Directives.ClientPvlRequestDirective = function ClientPvlRequestDirective() {

    // @ngInject
    function ClientPvlRequestDirectiveControllerFunc($injector, $scope, AnalyticsService, _, PopupMessageService,
                                                     CompanyService, WorkspacesManager, Enums, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ClientPvlRequestDirectiveController';
        this._ = _;

        this.WorkspacesManager = WorkspacesManager;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.CompanyService = CompanyService;
        this.PopupMessageService = PopupMessageService;
        this.DatadogRUMService = DatadogRUMService;
        this.workspaceId = null;
        this.requesting = false;
        this.pvlFeedRecommend = null;
        this.companyTypes = [];
        this.selectedVendorTypes = [];
        this.analyticsProperties = "";
        this.shouldShowNext = true;
        this.nextClicked = false;
        this.creator_company_type = null;

        if(this.workspaceFile){
            // we got here from a file though the receipt
            this.pvlFeedRecommend = false;

            this.workspaceId = this.workspaceFile.couple_card_id;

            this.analyticsProperties = 'Payment_number: ' + this.paymentNumber + ', source: receipt';
        } else if (this.workspace) {
            // this means that this flow is coming from the feed
            this.pvlFeedRecommend = true;

            // leave the workspaceFile undefined in this case. we support creating the request not just from the workspace file (also from the feed)
            this.workspaceId = this.workspace._id;
            
            this.analyticsProperties = "source: workspace_feed";
        }

        // get the workspace now we need it for the rest of the flow
        if (!this.workspace) {
            this.workspace = WorkspacesManager.getWorkspace(this.workspaceId, true, false);
        }

        // prepare company types
        this.companyTypes = this.prepareCompanyTypes();

        // preselect
        this.preselectVendors();

    }

    var ClientPvlRequestDirectiveController = Class(Controllers.BaseController, {
        constructor: ClientPvlRequestDirectiveControllerFunc,

        prepareCompanyTypes: function prepareCompanyTypes() {

            // get the basic list of company types
            var companyTypes = this.CompanyService.getCompanyTypesEnumClone();

            // exclude unwanted types
            var excludeCompanyTypes = [
                this.CompanyService.companyTypeKeyEnum.eventPlanner,
                this.CompanyService.companyTypeKeyEnum.venue,
                this.CompanyService.companyTypeKeyEnum.eventLighting,
                this.CompanyService.companyTypeKeyEnum.officiant,
                this.CompanyService.companyTypeKeyEnum.other,
                this.CompanyService.companyTypeKeyEnum.agency,
            ];



            if(this.workspaceFile && excludeCompanyTypes.indexOf(this.workspaceFile.company.company_type) === -1) {
                excludeCompanyTypes.push(this.workspaceFile.company.company_type);
            }
            
            // !this.workspaceFile => From the feed,
            // therefore there's no specific file company_type to reference
            // So, pick any file so long as it's owned by the vendor (creator of the workspace)
            if (!this.workspaceFile && this.workspace) {
                var creatorId = this.workspace.creator_id;

                var creatorsFiles = this._.filter( this.workspace.workspace_files, function(file) { return (file.owner._id === creatorId);});

                if (creatorsFiles[0]) {
                    excludeCompanyTypes.push( creatorsFiles[0].company.company_type );
                    this.creator_company_type = creatorsFiles[0].company.company_type;
                }
            }

            companyTypes = this._.filter(companyTypes,
                function(type) {
                    return (excludeCompanyTypes.indexOf(type.key) === -1);
                }
            );

            companyTypes = this._.filter(companyTypes,
                function(type) {
                    return (this.CompanyService.nonEventCompanyTypeEnum.indexOf(type.key) === -1);
                }.bind(this)
            );

            // prepare the model of the company type being selected. needs to be for the sorting to work.
            this._.forEach(companyTypes, function forEachIter(companyType) {
                companyType.topmost = false;
                companyType.selected = false;
            });
            
            return companyTypes;

        },
        
        analyticsProperties: function analyticsProperties() {
            return {
                controller: this.__objectType,
                selected_vendor_types: this.selectedVendorTypes
            };
        },

        sendRequest: function sendRequest() {
            this.requesting = true;
            var promise = this.WorkspacesManager.requestPreferredVendorsList(this.workspace, this.workspaceFile, this.getSortedSelectedCompanyTypes())
                .then(function success() {
                    if (this.sendRequestCallbackFunc) {
                        this.sendRequestCallbackFunc();
                    }
                }.bind(this))
                .catch(function fail(error) {
                    this.PopupMessageService.showAlert(this, this.PopupMessageService.severityTypes.error,
                        'FILE.PAYMENT.RECEIPT.REQUEST_PVL._ERROR_IN_REQUEST_');
                    this.DatadogRUMService.addError(error, { 'pvl': 1 });
                }.bind(this))
                .finally(function finallyHandler() {
                    this.requesting = false;
                }.bind(this));
        },

        dismiss: function dismiss() {
            if (this.dismissFunc) {
                this.dismissFunc();
            }
        },

        toggleCompanyType: function toggleCompanyType(companyType) {
            companyType.selected = !companyType.selected;
            if (companyType.selected) {
                this.selectedVendorTypes.push(companyType.key);
            } else {
                var index = this.selectedVendorTypes.indexOf(companyType.key);
                if (index > -1) {
                    this.selectedVendorTypes.splice(index, 1);
                }
            }
        },

        getSortedSelectedCompanyTypes: function getSortedSelectedCompanyTypes() {
            var ret = [];
            this.companyTypes.forEach(function (companyType) {
                if (companyType.selected) {
                    ret.push(companyType.key);
                }
            });
            return ret;
        },

        viewShown: function viewShown($inview, $inviewpart){

            if($inview){
                this.shouldShowNext = false;
            }

        },

        scrollToPvl: function scrollToPvl(){

            this.nextClicked = true;
        },

        preSelectCompanyType: function preSelectCompanyType(companyTypeKey) {
            if(!this._.some(this.selectedVendorTypes, companyTypeKey)){
                this.selectedVendorTypes.push(companyTypeKey);
            }

            var selectedCompanyType = this._.find(this.companyTypes, {key: companyTypeKey});
            selectedCompanyType.selected = true;
            selectedCompanyType.topmost = true;
        },

        // preselects the requires vendors according to our DB analysis of what vendors usually select
        // according to the type of the vendor that this payment was done to.
        preselectVendors: function preselectVendor() {

            var type = this.workspaceFile ? this.workspaceFile.company.company_type : this.creator_company_type;

            if (type === this.CompanyService.companyTypeKeyEnum.venue) {
                this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.caterer);
                this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.florist);
                this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.cake);
            } else {
                //this is not a venue
                if (type !== this.CompanyService.companyTypeKeyEnum.florist) {
                    this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.florist);
                }

                if (type !== this.CompanyService.companyTypeKeyEnum.cake) {
                    this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.cake);
                }

                if (type !== this.CompanyService.companyTypeKeyEnum.hairMakeup) {
                    this.preSelectCompanyType(this.CompanyService.companyTypeKeyEnum.hairMakeup);
                }
            }
        }
    });

    return {
        scope: {
            workspaceFile: '=?workspaceFile',
            workspace: '=?workspace',
            sendRequestCallbackFunc: '&?sendRequestCallbackFunc',
            dismissFunc: '&?dismissFunc',
            paymentNumber: '=paymentNumber'
        },

        templateUrl: 'angular/app/modules/core/features/preferred_vendors/pvl_request/client_pvl_request_directive_template.html',
        controller: ClientPvlRequestDirectiveController,
        controllerAs: 'clientPvlRequestVm',
        bindToController: true
    };
};
