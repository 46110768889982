class FeedItemFlowSignatureViewerController extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, $translate, $filter, $window, Enums, UsersManager, AnalyticsService, FlowService) {
        super($scope, $injector);
        this.__objectType = 'FeedItemFlowSignatureViewerController';

        this.$translate = $translate;
        this.$filter = $filter;
        this.$window = $window;
        this.Enums = Enums;
        this.actionType = this.activity.action_type;
        this.AnalyticsService = AnalyticsService;
        this.FlowService = FlowService;
        this.flowTitle = this.activity.title;
        this.currUser = UsersManager.getCurrUser();
        this.isClient = this.currUser.isClient();

        $scope.$watch('$ctrl.workspace.flows', (newVal, oldVal) => {
            if(newVal !== oldVal) {
                this.flow = this.workspace.flows ? this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id) : null;
            }
        });
    }

    $onInit() {
        switch (this.activity.action_type) {
            case this.Enums.flowSignatureActivityTypes.collected:
                this.setSignatureCollected(this.activity);
                break;
            case this.Enums.flowSignatureActivityTypes.countersignReady:
                this.setCountersignReady(this.activity);
                break;
            case this.Enums.flowSignatureActivityTypes.completed:
                this.setContractCompleted();
                break;
        }

        this.flow = this.workspace.flows ? this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id) : null;
    }

    setSignatureCollected({client_name: clientName, client_id: clientId, client_signatures_left_count: clientSignaturesLeftCount}) {
        this.isHidden = clientSignaturesLeftCount === 0 && !this.isClient;

        const titleTranslateName = clientId === this.currUser._id ? '_COLLECTED_TITLE_CURRENT_CLIENT_' : '_COLLECTED_TITLE_';
        this.title = this.$translate.instant(`WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE.${titleTranslateName}`, {
            flowTitle: this.flowTitle,
            clientName
        });

        if(this.isClient) {
            return;
        }

        if (clientSignaturesLeftCount > 1) {
            this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COLLECTED_VENDOR_DESCRIPTION_PLURAL_', {
                signaturesLeftCount: clientSignaturesLeftCount,
                flowTitle: this.flowTitle,
                clientName
            });
        } else if(clientSignaturesLeftCount === 1) {
            this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COLLECTED_VENDOR_DESCRIPTION_SINGULAR_');
        }
    }

    setCountersignReady({client_name: clientName, missing_member_sign_data: missingMemberSignData}) {
        if(this.isClient) {
            this.isHidden = true;

            return;
        }

        this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COUNTERSIGN_READY_TITLE_', {
            flowTitle: this.flowTitle,
            clientName
        });

        if(missingMemberSignData.findIndex(({_id}) => _id === this.currUser._id) !== -1) {
            this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._COUNTERSIGN_READY_DESCRIPTION_');
        } else {
            this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._NOT_COUNTERSIGNING_MEMBER_COUNTERSIGN_READY_DESCRIPTION_', {
                membersNames: missingMemberSignData.map(({full_name}) => full_name).join(', ')
            });
        }
    }

    setContractCompleted() {
        this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.SIGNATURE._CONTRACT_COMPLETED_', {
            flowTitle: this.flowTitle,
        });
    }

    onClick(event) {
        event.stopPropagation();
        if (this.flow) {
            this.FlowService.goToFlowByUser(this.flow, this.currUser);
        }
    }
}

Components.FeedItemFlowSignatureViewer = {
    bindings: {
        activity: '<',
        workspace: '<?',
        isHidden: '='
    },
    controller: FeedItemFlowSignatureViewerController,
    name: 'hbFeedItemSignatureViewer',
    templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_flow_signature_viewer_template.html',
};