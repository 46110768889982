(function () {
    "use strict";

    // @ngInject

        class ContactFormWebsiteBuilderHelpDocController extends Controllers.BaseControllerES6 {

            // @ngInject
            constructor($scope, $injector, uuid4, $, $timeout, $translate, UiPersistenceService, AnalyticsService, CompaniesManager, UIUtils) {
                super($scope, $injector);
                this.$translate = $translate;
                this.$ = $;
                this.UiPersistenceService = UiPersistenceService;
                this.AnalyticsService = AnalyticsService;
                this.CompaniesManager = CompaniesManager;
                this.$timeout = $timeout;
                this.UIUtils = UIUtils;
                this.__objectType = 'ContactFormWebsiteBuilderHelpDocController';
                this.websiteBuilders = {
                    squarespace: {
                        name: 'Squarespace',
                        help_url: 'https://intercom.help/honeybook-5891e2a409ff/my-workflow/contact-forms-and-workflows/installing-the-honeybook-contact-form-on-a-squarespace-website'
                    },
                    wordpress: {
                        name: 'Wordpress',
                        help_url: 'https://intercom.help/honeybook-5891e2a409ff/my-workflow/contact-forms-and-workflows/installing-the-honeybook-contact-form-on-a-wordpress-website'
                    },
                    wix: {
                        name: 'Wix',
                        help_url: 'https://intercom.help/honeybook-5891e2a409ff/my-workflow/contact-forms-and-workflows/installing-the-honeybook-contact-form-on-a-wix-website'
                    },
                    weebly: {
                        name: 'Weebly',
                        help_url: 'https://intercom.help/honeybook-5891e2a409ff/my-workflow/contact-forms-and-workflows/installing-the-honeybook-contact-form-on-a-weebly-website'
                    },
                    showit: {
                        name: 'Showit',
                        help_url: 'https://learn.showit.co/faqs-and-troubleshooting/building-your-site-faqs/honeybook-showit'
                    }
                };

                this.register(this.company, 'success', this.gotCompany);

                this.generalHelpUrl = 'https://intercom.help/honeybook-5891e2a409ff/my-workflow/contact-forms-and-workflows/embedding-your-contact-form';
                this.generalHelpToolTip = this.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._HELP_TOOL_TIP_');
                this.generalHelpCenterLabelText = this.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._HELP_CENTER_');
                this.generalBuildersToPresent = ['squarespace', 'wordpress', 'wix', 'weebly', 'showit'];
                this.identifyingText = this.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._IDENTIFYING_BUILDER_');
                this.wasMissingValidationCallStart = false;
                this.gotCompany();
            }

            gotCompany(){
                if (this.company.website_platform) {

                    this.builderType = this.company.website_platform.toLowerCase();
                    this.builderTypeCapitalized = this.UIUtils.capitalize(this.builderType);

                    // not using
                    var checkOutOtherGuidesText = this.$translate.instant('CONTACT_FORM_WEBSITE_BUIDLER._OTHER_GUIDES_LINK_TEXT_');
                    this.otherGuidesLink = this.$translate.instant('CONTACT_FORM_WEBSITE_BUIDLER._OTHER_GUIDES_LINK_', {builder_type: this.builderTypeCapitalized});
                    this.otherGuidesLink = this.otherGuidesLink.replace('[LINK]', `<a target="_blank" rel="noopener noreferrer" href="${this.generalHelpUrl}">${checkOutOtherGuidesText}</a>`)


                }else if (this.company.website_url_validation === null) {
                    // this is only a ongoing migration to add missing url data from companies that are here before entering
                    // company info page
                    if (!this.wasMissingValidationCallStart){
                        this.wasMissingValidationCallStart = true;
                        this.CompaniesManager.validateCompanyWebsiteUrl(this.company);
                    }
                }

                this.isBuilderFound = !!this.websiteBuilders[this.builderType];

                this.buildersToPresent = [];
                this.linkClickEventName = this.AnalyticsService.analytics_events.website_builder_help_doc_open;
                this.persistencePltafromHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.websitePlatformHash);
                var delay = 2500;
                if (this.type !== 'single-line' ||  this.persistencePltafromHash && this.persistencePltafromHash.wasShown && this.persistencePltafromHash.builderType ===  this.builderType ){
                    delay = 0;
                }else {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.websitePlatformHash, {builderType: this.builderType , wasShown : true});
                }

                this.load(delay);
            }

            load(delay) {
                var _self = this;
                this.loading = true;
                _self.$timeout(() => {
                    _self.loading = false;

                    if (_self.isBuilderFound) {
                        _self.buildersToPresent = [_self.builderType];
                        _self.mainText = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._WORKS_WITH_YOUR_WEBSITE_', {builder: _self.websiteBuilders[_self.builderType].name});
                        _self.linkLabel = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._LINK_LABEL_');
                        _self.help_url = _self.websiteBuilders[_self.builderType].help_url;
                        _self.generalWebsiteBuilderHeader  = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._NOT_USING_FOUND_GENERAL_TRY_', {builder: _self.websiteBuilders[_self.builderType].name});
                        _self.embedCodeBuilderFound = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._EMBED_CODE_BUILDER_FOUND_', {builder: _self.websiteBuilders[_self.builderType].name});

                    } else {
                        _self.buildersToPresent = _self.generalBuildersToPresent;
                        _self.mainText = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._GENERAL_WEBSITE_');
                        _self.linkLabel = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._GENERAL_LINK_LABEL_');
                        _self.help_url = _self.generalHelpUrl;
                        _self.generalWebsiteBuilderHeader  = _self.$translate.instant('CONTACT_FORM.WEBSITE_BUILDER._NOT_USING_NOT_FOUND_GENERAL_TRY_');
                    }

                }, delay);
            };
        }
        Components.ContactFormWebsiteBuilderHelpDoc = {
                bindings: {
                    company: '=',
                    type: '@'
                },
        controller: ContactFormWebsiteBuilderHelpDocController,
        name: 'hbContactFormWebsiteBuilderHelpDoc',
        templateUrl: 'angular/app/modules/core/features/contact_form/website_builder_help_doc/contact_form_website_builder_help_doc.html'

    }

})();