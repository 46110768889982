(function () {
    'use strict';

    Services.UpcomingPaymentsService = class UpcomingPaymentsService {

        // @ngInject
        constructor(_, $q, moment, UsersManager, CompaniesManager, PopupMessageService, ModalService,
                    WorkspaceFilesManager, ReportsManager, AnalyticsService, UiPersistenceService, DeviceService
        ) {
            this._ = _;
            this.$q = $q;

            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.ReportsManager = ReportsManager;
            this.DeviceService = DeviceService;
            this.moment = moment;
            this.UiPersistenceService = UiPersistenceService;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.ModalService = ModalService;

            const uipKey = this.UiPersistenceService.keys.upcomingPaymentsModal;
            this.uipShouldShowThisWeekend = () => UiPersistenceService.getUiPersistence(uipKey, {dontShowAgainThisWeekend: false}).dontShowAgainThisWeekend;
            this.uipShouldShowAtAll = () => UiPersistenceService.getUiPersistence(uipKey, {dontShow: false}).dontShow;
            this.uipDontShowAgainThisWeekend = () => UiPersistenceService.setUiPersistence(uipKey, {dontShowAgainThisWeekend: true});
            this.uipShowAgainNextWeekend = () => UiPersistenceService.setUiPersistence(uipKey, {dontShowAgainThisWeekend: false});
            this.uipDontShowAgainForever = () => UiPersistenceService.setUiPersistence(uipKey, {dontShow: true});
            this.uipResetState = () => {
                this.uipShowAgainNextWeekend();
                UiPersistenceService.setUiPersistence(uipKey, {dontShowAgainThisWeekend: false});
            };
        }


        shouldShowUpcomingPaymentModal(source, requireDebitCard) {
            return this.shouldntShowUpcomingPaymentModalReasons(source, requireDebitCard).length === 0;
        }

        shouldntShowUpcomingPaymentModalReasons(source, requireDebitCard) {
            let reasons = [];
            if (!source || source !== 'payments') { reasons.push(`source '${source}' not enabled`); }

            if (this.CompaniesManager.getCurrCompany().all_inclusive_instant_payouts) { reasons.push('all_inclusive_instant_payouts'); }
            if (this.CompaniesManager.getCurrCompany().owner._id !== this.UsersManager.getCurrUser()._id) { reasons.push('not owner'); }
            if (this.uipShouldShowAtAll()) { reasons.push('uip dont show set'); }
            if (this.DeviceService.isIOS() || this.DeviceService.isAndroid()) { reasons.push('its mobile'); }
            if (requireDebitCard && !this.UsersManager.getCurrUser().myDebitCard()) { reasons.push('no debit card'); }
            if (!this.CompaniesManager.getCurrCompany().allow_instant_payouts) { reasons.push('company allow_instant_payouts is off'); }
            const now = this.moment();
            // now = now.set({'day': 4}); // cheat for debugging
            const dayOfWeek = now.day();
            // time range is between Thursday at 6:00am to Sunday at 9:00pm
            const isWithinTimeRange = (dayOfWeek === 4 && now.isSameOrAfter(this.moment(now).set({'hour' : 6, 'minute': 0, 'second': 0}))) ||
                (dayOfWeek > 4 && dayOfWeek <= 6) || (dayOfWeek === 0 && now.isSameOrBefore(this.moment(now).set({'hour' : 21, 'minute': 0, 'second': 0})));
            if(!isWithinTimeRange) {
                this.uipShowAgainNextWeekend();
                reasons.push('not a weekend');
            }
            if(this.uipShouldShowThisWeekend()) { reasons.push('dontShowAgainThisWeekend'); }

            return reasons; // if its empty, no reason to not show, ie do show :)
        }

        showUpcomingPaymentModal(source) {
            const upcomingPaymentsPromise = this.$q.defer();
            const now = this.moment();
            const dayOfWeek = now.day();
            const numberOfDaysLeft = dayOfWeek > 0 ? 7 - dayOfWeek : 0;
            const endDate = this.moment().add(numberOfDaysLeft, 'days'); // end of weekend
            this.ReportsManager.getReportsPayments(this.UsersManager.getCurrUser()._id, false, now, endDate)
                .then(resp => {
                    this.AnalyticsService.trackSuccess(this, 'got outstanding weekend payments');
                    const imminent_payments = resp && resp.data && resp.data.imminent_payments || [];
                    if (imminent_payments.length > 0) {
                        const paymentsEligibleForInstantDeposits = imminent_payments.filter(payment =>
                             (!payment.use_instant_payout) && payment.allow_instant_payout && !payment.payment_charge_date
                        );
                        if(paymentsEligibleForInstantDeposits.length > 0) {
                            this.uipDontShowAgainThisWeekend(); // regardless of output
                            this.ModalService.openUpcomingPaymentsModal(paymentsEligibleForInstantDeposits, source).then(resp => {
                                if (!resp) {
                                    // not an error but not user opted out
                                    upcomingPaymentsPromise.resolve(false);
                                } else if (resp.didSucceed && (resp.paymentsToggledAsInstant || []).length > 0 ) {
                                    if (this.UsersManager.getCurrUser().myDebitCard()) {
                                        this.markPaymentsAsInstantPayout(resp.paymentsToggledAsInstant, source, upcomingPaymentsPromise);
                                    } else {
                                        this.openDebitCardModal(resp.paymentsToggledAsInstant, source, upcomingPaymentsPromise);
                                    }
                                } else if (resp.dontShowAgain){
                                    this.uipDontShowAgainForever();
                                } else {
                                    window.console.error('UpcomingPaymentsService: unhandled state', resp);
                                }
                            });
                        }
                    }

                }).catch(error => {
                this.AnalyticsService.trackError(this, 'failed to get outstanding weekend payments', error);
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                    (error && error.data.error_message) || 'ERRORS.SERVER_API._UNKNOWN_');
                upcomingPaymentsPromise.reject(error);
            });

            return upcomingPaymentsPromise.promise;

        }

        markPaymentsAsInstantPayout(paymentsToggledAsInstant, source, upcomingPaymentsPromise)  {
            this.isProcessing = true;
            const toggleApiCalls = paymentsToggledAsInstant.map(payment =>
                this.WorkspaceFilesManager.markPaymentAsInstantPayout(payment.workspace_file_id,
                    payment._id, true, 'weekend_modal_' + source)
            );

            this.$q.all(toggleApiCalls)
                .then((resp) => {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.toggle_instant_payments,
                        {source: 'instant deposits weekend modal'});
                    upcomingPaymentsPromise.resolve(true); // finally done :)
                }).catch((err) => {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.toggle_instant_payments,
                    err);
                upcomingPaymentsPromise.reject(err);
            }).finally(() => {
                this.isProcessing = false;
            });
        }

        openDebitCardModal(pendingInstantDepositTogglePayments, source, upcomingPaymentsPromise) {
            this.ModalService.openAddDebitCardModal(source).then(
                () => {
                    if(this.UsersManager.getCurrUser().myDebitCard()) {
                        this.markPaymentsAsInstantPayout(pendingInstantDepositTogglePayments, source, upcomingPaymentsPromise);
                    } else {
                        upcomingPaymentsPromise.resolve(false);
                    }
                }
            ).catch(resp => {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.open_debit_card_modal, resp);
                upcomingPaymentsPromise.reject();
            });
        }
    };

}());

