
/**
 * The hb-sidebar-menu component
 *
 * This component shows menu typically used in a sidebar
 *
 */

(function () {
    'use strict';

    class SidebarMenuComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'SidebarMenuComponentController';
        }

        onSidebarMenuItemClick(item) {
            if (this.onItemClick && angular.isFunction(this.onItemClick)) {
                this.onItemClick({item});
            }

            if (item.onClick && angular.isFunction(item.onClick)) {
                item.onClick();
            }
        }
    }

    Components.SidebarMenu = {
        bindings: {
            list : '<',
            onItemClick: '&',
            isExpanded: '<'
        },
        controller: SidebarMenuComponentController,
        templateUrl: 'angular/app/modules/common/ui_components/sidebar_menu/sidebar_menu.html',
    };

}());
