(function() {
	'use strict';

	// @ngInject
	function EmbededCodeContactFormControllerCtor($scope, $sce, $injector, $timeout, $modalInstance, ModalService,
		AnalyticsService, contactFormModel, contactFormParentModel, contactFormState, showOnlyDirectLink,
		CompaniesManager, CompanyService, PopupMessageService, AppConfigService, $document, $window,
		DeviceService, TemplatesViewService, ContactFormService, _) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EmbededCodeContactFormController';
		this.CompaniesManager = CompaniesManager;
        this.CompanyService = CompanyService;
        this.PopupMessageService = PopupMessageService;
        this.AppConfigService = AppConfigService;
        this.TemplatesViewService = TemplatesViewService;
        this.ContactFormService = ContactFormService;
        this.$sce = $sce;
        this.$window = $window;
		this.contactFormModel = contactFormModel;
		this.contactFormParentModel = contactFormParentModel;
		this.contactFormState = contactFormState;
		this.showOnlyDirectLink = showOnlyDirectLink;
		this.DeviceService = DeviceService;
        this.$modalInstance = $modalInstance;
		this.AnalyticsService = AnalyticsService;
		this.ModalService = ModalService;
		this.$timeout = $timeout;
		this._ = _;
        this.tradeShowUrl = this.ContactFormService.getPublicUrl(contactFormModel);

		this.linkCopiedText = '';
		this.isShowTakeoverModal = true;

		this.loadingEmbedCode = true;
        this.showNewContactForm = this.TemplatesViewService.shouldShowNewContactForm();
        this.widgetIFrameSourceURL = '';
        this.iframeCode = '';
		this.copyEmbedCodeMsg = false;
		this.copyDirectLinkMsg = false;
		this.isDirectLinkModalOpen = false;
        this.loadingWidget = true;
        this.showNoCompanyURLError =
            !this.contactFormParentModel.isModelOf('Group') &&
            (!this.contactFormParentModel.website_url ||
                this.contactFormParentModel.website_url === '');
		this.SharingPlatforms = {
			facebook: 'https://help.honeybook.com/en/articles/2921679-how-can-i-capture-leads-through-my-facebook-business-page',
			linktree: 'https://help.honeybook.com/en/articles/3625370-adding-your-direct-link-to-linktree'
		}
        this.getCodeToEmbed();

		if (this.showOnlyDirectLink) {
			this.isDirectLinkModalOpen = true;
		}
		
		$document.keydown(function($event) {
			if ($event.keyCode === 27) {
				this.close();
			}
		}.bind(this));

		$scope.$on('$destroy', function() {
			$document.off('keydown');
		});

		this.copiedIframe = function copiedIframe() {
			this.copyEmbedCodeMsg = true;
		};

		this.copiedDirectLink = function copiedDirectLink() {
			this.copyDirectLinkMsg = true;
			this.AnalyticsService.trackClick(this, "contact form: copied direct link url", {app_path: 'post-publish'});
		};

		this.onDisplayHeaderChange = function onDisplayHeaderChange() {
			const isDisplayHeaderChecked = !this.contactFormModel.contact_form_settings.display_header_for_direct_link;
            this.contactFormModel.contact_form_settings.display_header_for_direct_link = isDisplayHeaderChecked;
			this.contactFormModel.updateSettings(['display_header_for_direct_link']);
			this.AnalyticsService.trackClick(this, "Change direct link header visibility", {new_value: isDisplayHeaderChecked});
		},
		
		this.openTradeShowTab = function openTradeShowTab() {
            if (this.tradeShowUrl && this.contactFormModel.last_publish_timestamp) {
				this.$window.open(this.tradeShowUrl, '_blank');
				this.AnalyticsService.trackClick(this, "contact form: opened direct link in new tab", {app_path: 'post-publish'});
            } else {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.WIDGET._NEED_TO_PUBLISH_TRADE_SHOW_');
            }
		},
		
		this.openSharingPlatformTab = function openLinktreeTab(platformType) {
			this.AnalyticsService.trackClick(this, "direct link platform sharing", {type: platformType});
            this.$window.open(this.SharingPlatforms[platformType], '_blank');
		},

		this.showNoCompanyURLMustMatchMsg = !this.contactFormParentModel.isModelOf(
			'Group'
		);

		if (this.DeviceService.isSafari()) {
			this.hideForSafari = true;
		}
	}

	Controllers.EmbededCodeContactFormController = Class(
		Controllers.BaseController,
		{
			constructor: EmbededCodeContactFormControllerCtor,

            close: function() {
                this.$modalInstance.close();
			},
			
			dismiss: function() {
				this.$modalInstance.dismiss()
			},

            linkCopied: function linkCopied() {
			    var copyLinkElement = document.querySelector('#linkCopyAnchor');

                this.$timeout(function () {
                    copyLinkElement._tippy.show();
                }.bind(this), 500);

                this.$timeout(function () {
                    copyLinkElement._tippy.hide();
                }.bind(this), 3000);
            },

			activateEmbedCode: function activateEmbedCode() {
				this.copyEmbedCode = !this.copyEmbedCode;
				if (this.copyEmbedCode === true) {
					angular.element('textarea#contactFormCode').select();
				}
			},

			isShowPublishModalTakeover: function isShowPublishModalTakeover() {
				return this.isNeedToReEmbedContactForm() && this.isShowTakeoverModal;
			},

			confirmModalTakeover: function confirmModalTakeover() {
				this.AnalyticsService.trackClick(this, "new cf publish first slide next");
				this.isShowTakeoverModal = false;
			},

			dissmissModalTakeover: function dissmissModalTakeover() {
				this.AnalyticsService.trackClick(this, "new cf publish first slide not right now");
				this.dismiss();
			},

			notRightNowModalTakeover: function notRightNowModalTakeover() {
				this.AnalyticsService.trackClick(this, "new cf publish first slide not right now");
				this.dismiss();
			},

			isNeedToReEmbedContactForm: function isNeedToReEmbedContactForm() {
				return this.ContactFormService.getMigratedContactFormFlags().showHeaderOnPublishModal;
			},

            publishChanges: function publishChanges() {
                this.ContactFormService.publish(true, false, this.contactFormModel).then(function() {
                    this.close();
                }.bind(this));
            },

			goToCompanySettings: function goToCompanySettings() {
				this.$state.go(
					this.AppStates
						.root_core_navigation_settings_companySettings_overview
				);
				this.close();
			},

			toggleDirectLinkModal: function toggleDirectLinkModal() {
				if (!this.isDirectLinkModalOpen) {
					this.AnalyticsService.trackClick(this, "view direct link sharing options", {app_path: 'post-publish'});
				}
				this.isDirectLinkModalOpen = !this.isDirectLinkModalOpen;
			},

			getCodeToEmbed: function getCodeToEmbed() {
				//this.gettingCode = true;
				var widgetIFrameSourceURL = '';
				var iframeCode = '';
                this.company = this.contactFormParentModel;
                this.CompaniesManager.getCompanyWidget(
                    this.company,
                    this.contactFormModel._id
                ).then(
                    function gotWidget(resp) {
                        this.widgetIFrameSourceURL = this.$sce.trustAsHtml(
                            resp.data.html
                        );
                        this.iframeCode = resp.data.iframe_code;
                        this.gettingCode = false;
						this.loadingEmbedCode = false;
						if (!this.showOnlyDirectLink) {
							this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.loaded_contact_form_snippet_code, {is_placement_snippet: !!resp.data.is_placement_snippet});
						}
                    }.bind(this)
                );

			},
			generateTradeShowURLTab: function generateTradeShowURLTab() {
				var tradeShowUrl;
                
                this.company = this.contactFormParentModel;

                if (this.company.hb_url) {
                    if (this.contactFormModel.from_migration) {
                        tradeShowUrl = this.AppConfigService.appConfiguration().url + "widget/" + this.company.hb_url;
                    } else {
                        tradeShowUrl = this.ContactFormService.getPublicUrl(this.contactFormModel);
                    }
                }
                
				if (tradeShowUrl && this.contactFormModel.last_publish_timestamp) {
					this.$window.open(tradeShowUrl, '_blank');
				} else {
					this.PopupMessageService.showAlert(
						this.PopupMessageService.severityTypes.info,
						'COMPANY_SETTING.WIDGET._NEED_TO_PUBLISH_TRADE_SHOW_'
					);
				}
			}
		}
	);
})();
