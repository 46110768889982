Directives.AccountSetupDisabled = function AccountSetupDisabled() {

    "use strict";
    
    // @ngInject
    function AccountSetupDisabledControllerCtor($scope, $injector, AppStates, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this._objectType = 'AccountSetupDisabledController';
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
    }

    var AccountSetupDisabledController = Class(Controllers.BaseController, {
        constructor: AccountSetupDisabledControllerCtor,
        getTranslationKey: function getTranslationKey(key) {
            return `COMPANY_SETTING.SETUP.DISABLED.${key}`;
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/account_setup/account_setup_disabled_template.html',
        controller: AccountSetupDisabledController,
        controllerAs: 'accountSetupDisabledVm',
        bindToController: true
    };
};