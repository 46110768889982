
(function () {
    'use strict';

    Directives.CardDirective = function CardDirective() {

        // @ngInject
        function CardDirectiveControllerFunc($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CardDirectiveController';

            this.title = this.title || '';
            this.message = this.message || '';
        }

        var CardDirectiveController = Class(Controllers.BaseController, {
            constructor: CardDirectiveControllerFunc,

        });

        return {
            scope: {
                title: '@',
                subTitle: '@',
                style: '@',
            },
            transclude: {
                header: '?hbCardHeader',
            },
            templateUrl: 'angular/app/modules/common/ui_components/hb_card/hb_card.html',
            controller: CardDirectiveController,
            controllerAs: 'cardVm',
            bindToController: true
        };
    };

})();
