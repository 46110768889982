Directives.TimelineSectionDirective = function TimelineSectionDirective() {

    // @ngInject
    function TimelineSectionDirectiveControllerFunc($injector, $scope, _, $timeout, TimelineManager, AnalyticsService, moment) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimelineSectionDirectiveController';
        this.TimelineManager = TimelineManager;
        this._ = _;
        this.moment = moment;

        this.timelineItems = [];
        this.sortByPosition = function sortByPosition(a, b) {
            return a.position - b.position;
        };

        this.fillViewModelAccordingToModel();

        var self = this;
        this.sortableOptions = {
            revert: 200,
            axis: 'y',
            handle: '.js-timeline-item-drag',
            disabled: (!this.isEditableMode || this.isPreviewMode),
            start: function (event, ui) {
                AnalyticsService.track(self, AnalyticsService.analytics_events.timeline_item_drag_started);
            },

            update: function update(event, ui) {
            },

            stop: function stop(event, ui) {

                self.timelineItems.forEach(function (item, index) {
                    var item = _.findWhere(self.sectionModel.timeline_items, {_id: item._id});
                    item.position = index;
                });

                //update the server with the new order
                TimelineManager.updateTimelineItemPositions(self.workspaceFile, self.sectionModel)
                    .then(function success() {
                        AnalyticsService.track(self, AnalyticsService.analytics_events.timeline_item_drag_ended);
                    })
                    .catch(function error() {
                        AnalyticsService.trackError(self, AnalyticsService.analytics_events.timeline_item_drag_error);
                    });

            }
        };

        this.register(this.workspaceFile, 'success', function success() {
            $timeout(this.fillViewModelAccordingToModel.bind(this), 100);
        });
    }

    var TimelineSectionDirectiveController = Class(Controllers.BaseController, {
        constructor: TimelineSectionDirectiveControllerFunc,

        analyticsProperties: function analyticsProperties() {
            var args = {
                section_id: this.sectionModel._id,
                section_title: this.sectionModel.section_title
            };
            return args;
        },

        fillViewModelAccordingToModel: function fillViewModelAccordingToModel() {
            this.timelineItems.splice(0);
            this.sectionModel.timeline_items.forEach(function (currentItem) {
                var itemModel = currentItem.dataOnly();
                this.timelineItems.push(itemModel);
            }.bind(this));

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.timelineItems = this._.sortBy(this.timelineItems, function(item){
                return item.position;
            });
        },

        updateTimelineSectionDate: function updateTimelineSectionDate() {
            if (!this._.isEmpty(this.sectionModel.section_date)) {
                var date = this.moment(this.sectionModel.section_date, 'MMM DD, YYYY');

                if (date.isValid()) {
                    this.sectionModel.section_date = date.format('Y-MM-DD');
                }
                else {
                    this.sectionModel.section_date = this.moment().format('Y-MM-DD');
                }
            }

            return this.TimelineManager.updateTimelineSection(this.workspaceFile, this.sectionModel);
        },

        updateTimelineSectionTitle: function updateTimelineSectionTitle() {
            return this.TimelineManager.updateTimelineSection(this.workspaceFile, this.sectionModel);
        },

        deleteTimelineItem: function deleteTimelineItem(timelineItem) {
            return this.TimelineManager.deleteTimelineItem(this.workspaceFile, this.sectionModel, timelineItem);
        },

        updateTimelineItem: function updateTimelineItem(timelineItem) {
            return this.TimelineManager.updateTimelineItem(this.workspaceFile, this.sectionModel, timelineItem);
        },

        createTimelineItem: function createTimelineItem() {
            this.TimelineManager.createTimelineItem(this.workspaceFile, this.sectionModel).then(
                function success() {
                },
                function error() {
                }
            );
        },

        updateTimelineItemPosition: function updateTimelineItemPosition(selectedTimelineItem) {
            var newPosition = 0;
            for (newPosition = 0; newPosition < this.timelineItems.length; newPosition++) {
                if (this.timelineItems[newPosition] === selectedTimelineItem) {
                    break;
                }
            }
            return this.TimelineManager.updateTimelineItem(this.workspaceFile, this.sectionModel, timelineItem);
        },

        allowEdit: function allowEdit() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        }
    });

    return {
        scope: {
            workspaceFile: '=workspaceFile',
            sectionModel: '=sectionModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            showItemTooltip: '=showItemTooltip'
        },
        templateUrl: 'angular/app/modules/core/features/timeline/timeline_section_directive_template.html',
        controller: TimelineSectionDirectiveController,
        controllerAs: 'timelineSectionVm',
        bindToController: true
    };
};
