(function () {
    'use strict';

    // @ngInject
    function ReportsAnalyticsControllerCtor($scope, $injector) {
        var self = this;

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReportsAnalyticsController';
    }

    Controllers.ReportsAnalyticsController = Class(Controllers.BaseController, {
        constructor: ReportsAnalyticsControllerCtor
    });
}());
