(function () {
    'use strict';

    Directives.BrochureTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/brochure_template_empty_state.html',
            controller: 'BrochureTemplateEmptyStateController',
            controllerAs: 'brochureTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());


(function () {
    'use strict';

    Directives.QuestionnaireTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/questionnaire_template_empty_state.html',
            controller: 'QuestionnaireTemplateEmptyStateController',
            controllerAs: 'questionnaireTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());

(function () {
    'use strict';

    Directives.EmailSignaturesTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/email_signatures_templates_empty_state.html',
            controller: 'EmailSignaturesTemplateEmptyStateController',
            controllerAs: 'emailSignatureTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());

(function () {
    'use strict';

    Directives.EmailTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/email_template_empty_state.html',
            controller: 'EmailTemplateEmptyStateController',
            controllerAs: 'emailTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());

(function () {
    'use strict';

    Directives.PackageTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/package_template_empty_state.html',
            controller: 'PackageTemplateEmptyStateController',
            controllerAs: 'packageTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());

(function () {
    'use strict';

    Directives.GiftCardTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/gift_card_template_empty_state.html',
            controller: 'GiftCardTemplateEmptyStateController',
            controllerAs: 'giftCardTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());

(function () {
    'use strict';

    Directives.ProposalTemplateEmptyStateDirective = function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/company_settings/templates/empty_states/proposal_template_empty_state.html',
            controller: 'ProposalTemplateEmptyStateController',
            controllerAs: 'proposalTemplateEmptyStateVm',
            bindToController: true,
            scope: {}
        };
    };

}());