
// @ngInject

Models.FeedItemsCollection = function FeedItemsCollection(BaseCollection) {

    var FeedItemsCollection = Class(BaseCollection, {

            constructor: function constructor(){
                FeedItemsCollection.$super.call(this);
                this._collection.__className = 'FeedItemsCollection';
                this._collection.__childsType = 'FeedItemModel';
                return this._collection;
            }
        }
    );

    return FeedItemsCollection;
};