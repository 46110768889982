(function () {
    "use strict";


    // @ngInject
    function TemplatesManagerCtor(APIService, Routes, $q, AnalyticsService, ModelFactory, _, PubSubService, ModelUtils, UIUtils) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.AnalyticsService = AnalyticsService;
        this._ = _;
        this.ModelUtils = ModelUtils;
        this.UIUtils = UIUtils;

        PubSubService.ventMyBitchUp(this);
    }


    Services.TemplatesManager = Class(Services.BaseModelManager, {

        constructor: TemplatesManagerCtor,

        // ====================
        // INVOICE & PROPOSAL
        // ====================

        updatePayableCoverImage: function updatePayableCoverImage(company, payable, image) {
            var url = this.Routes.v2_company_update_payable_image_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {_id: image._id, url: image.url}, company, true);
        },

        updatePayableProposal: function updatePayableProposal(company, payable, proposalData) {
            var url = this.Routes.v2_company_update_payable_proposal_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, proposalData, company, true);
        },

        togglePayableAllPackagesTax: function togglePayableAllPackagesTax(company, payable, addTax) {
            var url = this.Routes.v2_company_toggle_payable_all_packages_tax_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {add_tax: addTax}, company, true);
        },

        togglePayableAllPackagesSvc: function togglePayableAllPackagesSvc(company, payable, addSvc) {
            var url = this.Routes.v2_company_toggle_payable_all_packages_svc_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {add_svc: addSvc}, company, true);
        },

        addNewInvoice: function addNewInvoice(company, user) {
            var url = this.Routes.v2_company_add_payable_path(company._id, 'invoice');
            return this.apiCreate(url, null, company, true);
        },

        addNewProposal: function addNewProposal(company, user) {
            var url = this.Routes.v2_company_add_payable_path(company._id, 'proposal');
            return this.apiCreate(url, null, company, true);
        },

        getInvoice: function getInvoice(company, user, invoiceId) {
            var url = this.Routes.v2_company_get_payable_path(company._id, 'invoice', invoiceId);
            return this.apiFetch(url, null, company, true);
        },

        getProposal: function getProposal(company, user, proposalId) {
            var url = this.Routes.v2_company_get_payable_path(company._id, 'proposal', proposalId);
            return this.apiFetch(url, null, company, true);
        },

        updatePayable: function updatePayable(company, payable) {
            var url = this.Routes.v2_company_update_payable_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, payable.dataOnly(), company, true, null, 'waitForLast');
        },

        deleteInvoice: function deleteInvoice(company, user, invoiceId) {
            var url = this.Routes.v2_delete_payable_template_path(company._id, 'invoice', invoiceId);
            return this.apiDelete(url, null, company, true);
        },

        deleteProposal: function deleteProposal(company, user, proposalId) {
            var url = this.Routes.v2_delete_payable_template_path(company._id, 'proposal', proposalId);
            return this.apiDelete(url, null, company, true);
        },

        addPayablePackage: function addPayablePackage(company, payable, packageId) {
            var url = this.Routes.v2_company_add_payable_package_path(company._id, payable.payableType(), payable._id);
            return this.apiCreate(url, {package_id: packageId}, company, true);
        },

        updatePayablePackage: function updatePayablePackage(company, payable, packageModel) {
            var url = this.Routes.v2_company_update_payable_package_path(company._id, payable.payableType(), payable._id, packageModel._id);
            return this.apiUpdate(url, packageModel.dataOnly(), company, true);
        },

        addPayableService: function addPayableService(company, payable, serviceModel) {
            var url = this.Routes.v2_company_add_payable_service_path(company._id, payable.payableType(), payable._id);
            return this.apiCreate(url, serviceModel, company, true);
        },

        deletePayablePackage: function deletePayablePackage(company, payable, packageId) {
            var url = this.Routes.v2_company_delete_payable_package_path(company._id, payable.payableType(), payable._id, packageId);
            return this.apiDelete(url, null, company, true);
        },

        addPayablePackageService: function addPayablePackageService(company, payable, packageModel, serviceModel) {
            var url = this.Routes.v2_company_add_payable_package_service_path(company._id, payable.payableType(), payable._id, packageModel._id);
            return this.apiCreate(url, serviceModel, company, true);
        },

        updatePayablePackageService: function updatePayablePackageService(company, payable, packageModel, serviceModel) {
            var url;
            if (packageModel) {
                url = this.Routes.v2_company_update_payable_package_service_path(company._id, payable.payableType(), payable._id, packageModel._id, serviceModel._id);
            }
            else {
                url = this.Routes.v2_company_update_payable_service_path(company._id, payable.payableType(), payable._id, serviceModel._id);
            }
            return this.apiUpdate(url, serviceModel.dataOnly(), company, true);
        },

        deletePayablePackageService: function deletePayablePackageService(company, payable, packageModel, serviceModel) {
            var url;
            if (packageModel) {
                url = this.Routes.v2_company_delete_payable_package_service_path(company._id, payable.payableType(), payable._id, packageModel._id, serviceModel._id);
            }
            else {
                url = this.Routes.v2_company_delete_payable_service_path(company._id, payable.payableType(), payable._id, serviceModel._id);
            }
            return this.apiDelete(url, null, company, true);
        },

        updatePayablePackageServiceImage: function updatePayablePackageServiceImage(company, payable, serviceModel, image, packageModel) {
            var url = "";
            if (packageModel) {
                url = this.Routes.v2_update_payable_package_service_image_path(company._id, payable.payableType(), payable._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_update_payable_standalone_service_image_path(company._id, payable.payableType(), payable._id, serviceModel._id);
            }
            return this.apiUpdate(url, {image: image}, company, true);
        },

        deletePayablePackageServiceImage: function deletePayablePackageServiceImage(company, payable, packageModel, serviceModel) {
            var url = "";
            if (packageModel) {
                url = this.Routes.v2_company_delete_payable_package_service_image_path(company._id, payable.payableType(), payable._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_company_delete_payable_standalone_service_image_path(company._id, payable.payableType(), payable._id, serviceModel._id);
            }
            return this.apiDelete(url, null, company, true);
        },

        updatePayableServicesOrder: function updatePayableServicesOrder(company, payable, packageModel) {
            var url = this.Routes.v2_company_update_payable_services_order_path(company._id, payable.payableType(), payable._id, packageModel._id);

            var data = [];
            packageModel.base_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data});
        },

        addPayablePayment: function addPayablePayment(company, payable) {
            var url = this.Routes.v2_company_add_payable_payment_path(company._id, payable.payableType(), payable._id);
            return this.apiCreate(url, null, company, true);
        },

        updatePayablePayment: function updatePayablePayment(company, payable, payment) {
            var url = this.Routes.v2_company_update_payable_payment_path(company._id, payable.payableType(), payable._id, payment._id);
            return this.apiUpdate(url, payment.dataOnly(), company, true);
        },

        deletePayablePayment: function deletePayablePayment(company, payable, payment) {
            var url = this.Routes.v2_company_delete_payable_payment_path(company._id, payable.payableType(), payable._id, payment._id);
            return this.apiDelete(url, null, company, true);
        },

        savePaymentsContainerAsTemplate: function savePaymentsContainerAsTemplate(company, payable, templateTitle) {
            var url = this.Routes.v2_company_save_payable_payments_container_as_template_path(company._id, payable.payableType(), payable._id);
            return this.apiCreate(url, {title: templateTitle}, company, true);
        },

        updatePaymentsByTemplate: function updatePaymentsByTemplate(company, payable, templateId) {
            var url = this.Routes.v2_company_set_payable_payments_by_template_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {payments_container_id: templateId}, company, true);
        },

        saveAsPayableTemplate: function saveAsPayableTemplate(company, payable, title) {
            var data = {
                title: title,
                save_as: true,
                payable_id: payable._id
            };
            var url = this.Routes.v2_company_add_payable_path(company._id, payable.payableType());
            return this.apiCreate(url, data, company, true);
        },

        updatePayableAgreement: function updatePayableAgreement(company, payable) {
            var url = this.Routes.v2_company_update_payable_agreement_path(company._id, payable.payableType(), payable._id);
            var data = payable.agreement.dataOnly();
            Object.assign(data, {html_body: this.UIUtils.toBinaryBase64(data.html_body)});
            return this.apiUpdate(url, data, company, true);
        },

        updatePayableAgreementByTemplate: function updatePayableAgreementByTemplate(company, payable, templateId) {
            var url = this.Routes.v2_company_set_payable_agreement_by_template_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {agreement_id: templateId}, company, true);
        },

        savePayableAgreementAsTemplate: function savePayableAgreementAsTemplate(company, payable, templateTitle) {
            var url = this.Routes.v2_company_save_payable_agreement_as_template_path(company._id, payable.payableType(), payable._id);
            var data = { title: templateTitle, save_as: true };
            return this.apiCreate(url, data, company, true);
        },

        calculateProposalTax: function calculateProposalTax(company, payable, type, tax, taxOrSvc) {
            var url = this.Routes.v2_company_payable_calculate_tax_path(company._id, payable.payableType(), payable._id);
            var data = {tax_or_svc: taxOrSvc, tax_type: type, tax: tax};
            return this.apiFetch(url, data);
        },

        moveService: function moveService(company, payable, serviceId, sourcePackageId, destPackageId, servicesCollection) {
            var url = this.Routes.v2_company_move_payable_service_path(company._id, payable.payableType(), payable._id, serviceId);

            var data = {
                source_package_id: sourcePackageId,
                dest_package_id: destPackageId,
                dest_order: []
            };
            servicesCollection.forEach(function(service, moveService) {
                data.dest_order.push({_id: service._id, order: moveService});
            });

            return this.apiUpdate(url, data, company, true);
        },

        updatePackagesAndServicesOrder: function updatePackagesAndServicesOrder(company, payable, orderMap) {
            var url = this.Routes.v2_company_update_payable_packages_and_services_order_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {order: orderMap}, company, true);
        },

        togglePayablePaymentScheduleType: function togglePayablePaymentScheduleType(company, payable, isRecurring) {
            var url = this.Routes.v2_company_payable_toggle_payment_recurring_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {activate_recurring: isRecurring}, company, true);
        },

        updatePayableRecurringPayment: function updatePayableRecurringPayment(company, payable, startDate, endDate) {
            var url = this.Routes.v2_company_payable_update_payment_recurring_path(company._id, payable.payableType(), payable._id);
            return this.apiUpdate(url, {start_date: startDate, end_date: endDate}, company, true);
        },

        // ====================
        // PACKAGES & SERVICES
        // ====================
        /** Packages Actions */
        updateService: function updateService(company, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url = this.Routes.v2_company_update_service_on_package_path(company._id, packageModel._id, serviceModel._id);

            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        updateServicesOrder: function updateServicesOrder(company, packageModel) {
            var url = this.Routes.v2_update_services_order_on_package_template_path(company._id, packageModel._id);

            var data = [];
            packageModel.base_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data});
        },


        updateVendorPackagesOrder: function updateVendorPackagesOrder(company, payable, packagesOrder) {
            var url = this.Routes.v2_company_update_payable_packages_order_path(company._id, payable.payableType(), payable._id);

            return this.apiUpdate(url, {packages_order: packagesOrder}, company, true);
        },

        updateServiceImage: function updateServiceImage(company, serviceModel, packageModel, image) {
            var url = this.Routes.v2_update_service_image_on_package_template_path(company._id, packageModel._id, serviceModel._id);
            return this.apiUpdate(url, {image: image}, company, true);
        },

        deleteServiceImage: function deleteServiceImage(company, serviceModel, packageModel) {
            var url = this.Routes.v2_delete_service_image_on_package_template_path(company._id, packageModel._id, serviceModel._id);
            return this.apiDelete(url, null, company, false);
        },

        deleteService: function deleteService(company, serviceModel, packageModel) {
            var url = this.Routes.v2_company_delete_service_on_package_path(company._id, packageModel._id, serviceModel._id);
            return this.apiDelete(url, null, company, true);
        },

        getPackage: function getPackage(company, user, packageId) {
            var url = this.Routes.v2_company_get_package_path(company._id, packageId);
            return this.apiFetch(url, null, company, true);
        },

        updatePackage: function updatePackage(company, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_company_update_package_path(company._id, packageModel._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        addNewPackage: function addNewPackage(company, user) {
            var url = this.Routes.v2_company_create_package_path(company._id);
            return this.apiCreate(url, null, company, true);
        },

        deletePackage: function deletePackage(company, packageId) {

            var url = this.Routes.v2_delete_package_template_path(company.id(), packageId);
            return this.apiDelete(url, null, company, true);

        },

        togglePackageTax: function togglePackageTax(company, pckg, taxAll) {
            var url = this.Routes.v2_company_toggle_package_tax_path(company._id, pckg._id);
            return this.apiUpdate(url, {tax_all: taxAll}, company, true);
        },

        togglePackageSvc: function togglePackageSvc(company, pckg, svcAll) {
            var url = this.Routes.v2_company_toggle_package_svc_path(company._id, pckg._id);
            return this.apiUpdate(url, {svc_all: svcAll}, company, true);
        },

        /** Questionnaire Actions */
        updateQuestionnaireCoverImage: function updateQuestionnaireCoverImage(company, questionnaire, image) {
            var url = this.Routes.v2_company_update_questionnaire_image_path(company._id, questionnaire._id);
            return this.apiUpdate(url, {_id: image._id, url: image.url}, company, true);
        },

        createQuestion: function createQuestion(company, questionnaire, question) {
            var url = this.Routes.v2_company_questionnaire_template_create_question_path(company._id, questionnaire._id);
            return this.apiCreate(url, question, company, true);
        },

        createEventQuestions: function createEventQuestions(company, questionnaire) {
            var question = { connected_to_object: "event" };
            var url = this.Routes.v2_company_questionnaire_template_create_event_questions_path(company._id, questionnaire._id);
            return this.apiCreate(url, question, company, true);
        },

        updateQuestion: function updateQuestion(company, questionnaire, question) {
            var data = question.dataOnly();
            var url = this.Routes.v2_company_questionnaire_template_update_question_path(company._id, questionnaire._id, question._id);
            return this.apiUpdate(url, data, question, true, null, 'waitForLast');
        },

        updateQuestionsOrder: function updateQuestionsOrder(company, questionnaire) {

            var data = {questions_order: {}};
            questionnaire.questions.forEach(function (question) {
                data.questions_order[question._id] = question.order;
            });

            var url = this.Routes.v2_company_questionnaire_update_questions_order_path(company._id, questionnaire._id);
            return this.apiUpdate(url, data, questionnaire, true);
        },

        deleteQuestion: function deleteQuestion(company, questionnaire, question) {
            var url = this.Routes.v2_delete_questionnaire_template_question_path(company._id, questionnaire._id, question._id);
            return this.apiDelete(url, null, company, true);
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(company, packageModel, newService) {
            var url = this.Routes.v2_company_package_additional_service_path(company._id, packageModel._id);
            return this.apiCreate(url, newService, company, true);
        },

        saveAsPackageByTemplate: function saveAsPackageByTemplate(company, packageToCopyFrom, title) {
            var data = {
                title: title,
                save_as: true,
                package_to_copy: packageToCopyFrom.id()
            };
            var url = this.Routes.v2_company_create_package_path(company._id);
            return this.apiCreate(url, data, company, true);
        },

        // ====================
        //      AGREEMENTS
        // ====================

        getAgreement: function getAgreement(company, user, agreementId) {
            var url = this.Routes.v2_company_get_agreement_path(company._id, agreementId);
            return this.apiFetch(url, null, company, true);
        },

        updateAgreement: function updateAgreement(company, agreementModel) {
            var data = agreementModel.dataOnly();
            Object.assign(data, {html_body: this.UIUtils.toBinaryBase64(data.html_body)});
            var url = this.Routes.v2_company_update_agreement_path(company._id, agreementModel._id);
            return this.apiUpdate(url, data, company, true);
        },

        updateAgreementWithFile: function updateAgreementWithFile(company, agreementModel, fileBin) {

            var data = {'file': fileBin};
            var url = this.Routes.v2_update_agreement_with_file_path(company._id, agreementModel._id);
            var promise = this.apiUpdate(url, data, company, true);
            promise.then(function updateAgreement(resp) {
                company.mixinFrom({agreement: resp.data});
            });
            return promise;
        },

        addNewAgreement: function addNewAgreement(company, user) {
            var url = this.Routes.v2_company_create_agreement_path(company._id);
            return this.apiCreate(url, null, company, true);
        },

        deleteAgreement: function deleteAgreement(company, user, agreementId) {
            var url = this.Routes.v2_delete_agreement_template_path(company.id(), agreementId);
            return this.apiDelete(url, null, company, true);
        },

        saveAsAgreementByTemplate: function saveAsAgreementByTemplate(company, agreement, title) {
            var data = {
                name: title,
                save_as: true,
                agreement_id: agreement._id
            };
            var url = this.Routes.v2_company_create_agreement_path(company._id);
            return this.apiCreate(url, data, company, true);
        },

        setAgreementAsDefault: function setAgreementAsDefault(company, agreement) {
            var url = this.Routes.v2_company_set_default_agreement_path(company.id(), agreement._id);
            return this.apiUpdate(url, null, company, true);
        },

        updateAgreementCoverImage: function updateAgreementCoverImage(company, agreement, image) {
            var url = this.Routes.v2_company_update_agreement_image_path(company._id, agreement._id);
            return this.apiUpdate(url, {_id: image._id, url: image.url}, company, true);
        },

        // ====================
        //      QUESTIONNAIRES
        // ====================

        getQuestionnaire: function getQuestionnaire(company, user, questionnaireId) {
            var url = this.Routes.v2_company_get_questionnaire_path(company._id, questionnaireId);
            return this.apiFetch(url, null, company, true);
        },

        updateQuestionnaire: function updateQuestionnaire(company, questionnaireModel) {

            var data = questionnaireModel.dataOnly();
            var url = this.Routes.v2_company_update_questionnaire_path(company._id, questionnaireModel._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        addNewQuestionnaire: function addNewQuestionnaire(company, user) {
            var url = this.Routes.v2_company_create_questionnaire_path(company._id);
            return this.apiCreate(url, null, company, true);
        },

        deleteQuestionnaire: function deleteQuestionnaire(company, user, questionnaireId) {
            var url = this.Routes.v2_delete_questionnaire_template_path(company.id(), questionnaireId);
            return this.apiDelete(url, null, company, true);
        },

        saveAsQuestionnaireByTemplate: function saveAsQuestionnaireByTemplate(company, questionnaire, title) {
            var data = {
                title: title,
                save_as: true,
                questionnaire_id: questionnaire.id()
            };
            var url = this.Routes.v2_company_create_questionnaire_path(company.id());
            return this.apiCreate(url, data, company, true);
        },

        // ====================
        //      BROCHURES
        // ====================

        getBrochure: function getBrochure(company, user, brochureId, isNewBrochure) {
            if(isNewBrochure) {
                return this.getTemplate(company, brochureId);
            } else {
                var url = this.Routes.v2_company_get_brochure_path(company._id, brochureId);
                return this.apiFetch(url, null, company, true);
            }
        },

        updateBrochure: function updateBrochure(company, brochureModel) {

            var data = brochureModel.dataOnly();
            var url = this.Routes.v2_company_update_brochure_path(company._id, brochureModel._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        createBrochureGreeting: function createBrochureGreeting(company, brochureModel) {
            var url = this.Routes.v2_company_brochure_template_create_greeting_path(company._id, brochureModel._id);
            return this.apiCreate(url, null, company, true);
        },

        updateBrochureGreeting: function updateBrochureGreeting(company, brochureModel) {
            var url = this.Routes.v2_company_brochure_template_update_greeting_path(company._id, brochureModel._id);
            var data = {
                greeting_title: brochureModel.greeting.title,
                greeting_body: brochureModel.greeting.body
            };
            return this.apiUpdate(url, data, company, false, null, 'waitForLast');
        },

        updateBrochureProposalsOrder: function updateBrochureProposalsOrder(company, workspaceFile, proposalsModel) {
            var url = this.Routes.v2_company_brochure_template_update_brochure_proposals_order_path(company, workspaceFile._id);
            var data = {};
            proposalsModel.forEach(function(proposal) {
                data[proposal._id] = proposal.order;
            });

            return this.apiUpdate(url, {brochure_proposals_order: data}, workspaceFile, true);
        },

        deleteBrochureGreeting: function deleteBrochureGreeting(company, brochureModel) {
            var url = this.Routes.v2_company_brochure_template_delete_greeting_path(company._id, brochureModel._id);
            return this.apiDelete(url, null, company, true);
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(company, brochureModel, image) {
            var url = this.Routes.v2_company_brochure_template_update_greeting_image_path(company._id, brochureModel._id);
            return this.apiUpdate(url, image, company, false);
        },

        addNewBrochure: function addNewBrochure(company, user) {
            return this.createBrochureTemplate(company);
        },

        deleteBrochure: function deleteBrochure(company, user, brochureId, isNewBrochure) {
            if(isNewBrochure) {
                return this.deleteTemplate(company, brochureId);
            } else {
                var url = this.Routes.v2_delete_brochure_template_path(company.id(), brochureId);
                return this.apiDelete(url, null, company, true);
            }
        },

        saveAsBrochureByTemplate: function saveAsBrochureByTemplate(company, brochure, title, isNewBrochure) {
            if(isNewBrochure) {
                return this.createTemplateFromTemplate(company, 'brochure', brochure.id(), title);
            } else {
                var data = {
                    title: title,
                    save_as: true,
                    brochure_id: brochure.id()
                };
                var url = this.Routes.v2_company_create_brochure_path(company.id());
                return this.apiCreate(url, data, company, true);
            }
        },

        /** BROCHURE Actions */
        createBrochureProposal: function createBrochureProposal(company, brochureContainer) {
            var url = this.Routes.v2_company_brochure_template_create_brochure_proposal_path(company._id, brochureContainer._id);
            return this.apiCreate(url, null, company, true);
        },

        deleteBrochureProposal: function deleteBrochureProposal(company, brochureProposal, brochureContainer) {
            var url = this.Routes.v2_company_brochure_template_delete_brochure_proposal_path(company._id, brochureContainer._id, brochureProposal._id);
            return this.apiDelete(url, null, company, true);
        },

        createBrochureALaCarteProposal: function createBrochureALaCarteProposal(company, brochureContainer) {
            var url = this.Routes.v2_company_brochure_template_create_brochure_a_la_carte_proposal_path(company._id, brochureContainer._id);
            return this.apiCreate(url, null, company, true);
        },

        deleteBrochureALaCarteProposal: function deleteBrochureALaCarteProposal(company, brochureContainer) {
            var url = this.Routes.v2_company_brochure_template_delete_brochure_a_la_carte_proposal_path(company._id, brochureContainer._id);
            return this.apiDelete(url, null, company, true);
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(company, brochure, image) {
            var url = this.Routes.v2_company_brochure_template_update_brochure_a_la_carte_image_path(company._id, brochure._id);
            return this.apiUpdate(url, image, company, true);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte(company, brochure) {
            var url = this.Routes.v2_company_brochure_template_update_brochure_a_la_carte_proposal_path(company._id, brochure._id);
            return this.apiUpdate(url, {title: brochure.a_la_carte_proposal.title}, company, true, null, 'waitForLast');
        },

        updateBrochureProposal: function updateBrochureProposal(company, brochure, brochureProposal) {
            // do we want to pass sooo much stuff?
            var data = brochureProposal.dataOnly();
            var url = this.Routes.v2_company_brochure_template_update_brochure_proposal_path(company._id, brochure._id, brochureProposal._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        addPackageToBrochure: function addPackageToBrochure(company, brochureContainer, brochureProposal, packageToAddId) {
            var url = this.Routes.v2_company_brochure_template_add_package_to_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageToAddId);
            return this.apiCreate(url, null, company, true);
        },

        addAdditionalServiceToPackageInBrochure: function addAdditionalServiceToPackageInBrochure(company, brochureContainer, brochureProposal, packageModel, newService) {
            var url = this.Routes.v2_company_brochure_template_add_package_additional_service_to_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id);
            return this.apiCreate(url, newService, company, true);
        },

        addCustomItemToBrochure: function addCustomItemToBrochure(company, brochureContainer, brochureProposal, itemData) {
            var url = this.Routes.v2_company_brochure_template_add_service_to_brochure_path(company._id, brochureContainer._id, brochureProposal._id, itemData);
            return this.apiCreate(url, null, company, true);
        },

        deleteServiceFromBrochure: function deleteServiceFromBrochure(company, brochureContainer, brochureProposal, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {
                url = this.Routes.v2_company_brochure_template_delete_service_on_package_from_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id, serviceModel._id);
            } else {
                url = this.Routes.v2_company_brochure_template_delete_service_from_brochure_proposal_path(company._id, brochureContainer._id, brochureProposal._id, serviceModel._id);
            }
            return this.apiDelete(url, null, company, true);
        },

        addServiceToBrochureALaCarte: function addServiceToBrochureALaCarte(company, brochureContainer, serviceData) {
            var url = this.Routes.v2_company_add_service_to_brochure_a_la_carte_path(company._id, brochureContainer._id, serviceData);
            return this.apiCreate(url, null, company, true);
        },

        deleteServiceFromBrochureALaCarte: function deleteServiceFromBrochureALaCarte(company, brochureContainer, serviceData) {
            var url = this.Routes.v2_company_delete_service_on_brochure_a_la_carte_path(company._id, brochureContainer._id, serviceData._id);
            return this.apiDelete(url, null, company, true);
        },

        updateServiceOnBrochureALaCarte: function updateServiceOnBrochureALaCarte(company, brochureContainer, serviceData) {
            var url = this.Routes.v2_company_update_service_on_brochure_a_la_carte_path(company._id, brochureContainer._id, serviceData._id, serviceData);
            return this.apiUpdate(url, null, company, true, null, 'waitForLast');
        },

        deletePackageFromBrochure: function deletePackageFromBrochure(company, brochureContainer, proposal, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_company_brochure_template_delete_package_on_proposal_of_brochure_path(company._id, brochureContainer._id, proposal._id, packageModel._id);
            return this.apiDelete(url, null, company, true);
        },

        deleteServiceImageOnBrochure: function deleteServiceImageOnBrochure(company, brochureContainer, brochureProposal, serviceModel, packageModel) {
            var url = this.Routes.v2_company_brochure_template_delete_service_image_on_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id, serviceModel._id);
            return this.apiDelete(url, null, company, false);
        },

        deleteImageFromALaCarteService: function deleteImageFromALaCarteService(company, brochureContainer, serviceModel) {
            var url = this.Routes.v2_company_brochure_template_delete_service_image_on_a_la_carte_path(company._id, brochureContainer._id, serviceModel._id);
            return this.apiDelete(url, null, company, false);
        },

        updatePackageOnBrochure: function updatePackageOnBrochure(company, brochureContainer, brochureProposal, packageModel) {

            var data = packageModel.dataOnly();
            var url = this.Routes.v2_company_brochure_template_update_package_on_proposal_of_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        updateServiceOnBrochure: function updateServiceOnBrochure(company, brochureContainer, brochureProposal, serviceModel, packageModel) {

            var data = serviceModel.dataOnly();
            var url;
            if (packageModel) {

                url = this.Routes.v2_company_brochure_template_update_service_on_package_of_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id, serviceModel._id);
            } else {

                url = this.Routes.v2_company_brochure_template_update_service_on_proposal_of_brochure_path(company._id, brochureContainer._id, brochureProposal._id, serviceModel._id);
            }

            return this.apiUpdate(url, data, company, null, 'waitForLast');
        },

        updateBrochureServicesOrder: function updateBrochureServicesOrder(company, brochureContainer, brochureProposal, packageModel) {
            var url = this.Routes.v2_company_brochure_template_update_services_order_on_brochure_package_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id);

            var data = [];
            packageModel.base_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data}, company, true);
        },

        updateServicesOrderInALaCarte: function updateServicesOrderInALaCarte(companyModel, brochureContainer, aLaCarteModel) {
            var url = this.Routes.v2_update_services_order_in_brochure_a_la_carte_template_path(companyModel._id, brochureContainer._id);

            var data = [];
            aLaCarteModel.package_services.forEach(function (service) {
                data.push({_id: service._id, order: service.order});
            });

            return this.apiUpdate(url, {servicesOrder: data}, companyModel, true);
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(company, brochureContainer, brochureProposal, image) {
            var url = this.Routes.v2_company_brochure_template_update_brochure_proposal_image_path(company._id, brochureContainer._id, brochureProposal._id);
            return this.apiUpdate(url, image, company, true);
        },

        updateServiceImageOnBrochure: function updateServiceImageOnBrochure(company, brochureContainer, brochureProposal, serviceModel, packageModel, image) {
            var url = this.Routes.v2_company_brochure_template_update_service_image_on_brochure_path(company._id, brochureContainer._id, brochureProposal._id, packageModel._id, serviceModel._id);
            return this.apiUpdate(url, {image: image}, company, true);
        },

        updateImageInALaCarteService: function updateImageInALaCarteService(company, brochureContainer, serviceModel, image) {
            var url = this.Routes.v2_company_brochure_template_update_service_image_on_a_la_carte_path(company._id, brochureContainer._id, serviceModel._id);
            return this.apiUpdate(url, {image: image}, company, true);
        },

        // ====================
        // TIMELINE
        // ====================

        deleteTimeline: function deleteTimeline(company, user, timelineId) {
            var url = this.Routes.v2_delete_timeline_template_path(company.id(), timelineId);
            return this.apiDelete(url, null, company, true);
        },

        // ====================
        //      EMAILS
        // ====================

        getEmail: function getEmail(company, user, emailId) {
            var url = this.Routes.v2_company_get_email_path(company._id, emailId);
            var data = {
                user_id: user._id
            };
            return this.apiFetch(url, data, company, true);
        },

        updateEmail: function updateEmail(company, emailModel) {
            const { html_body } = emailModel;
            const codeUnits = new Uint16Array(html_body.length);
            for (let i = 0; i < codeUnits.length; i++) {
                codeUnits[i] = html_body.charCodeAt(i);
            }
            const encoded_body = window.btoa(
                new Uint8Array(codeUnits.buffer).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '')
            );
            var data = emailModel.dataOnly();
            data.html_body = encoded_body;
            data.encoded = true;
            var url = this.Routes.v2_company_update_email_path(company._id, emailModel._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        addNewEmail: function addNewEmail(company, user) {
            var url = this.Routes.v2_company_create_email_path(company._id);
            return this.apiCreate(url, null, company, true);
        },

        saveAsEmailByTemplate: function saveAsEmailByTemplate(company, email, title) {
            return this.saveAsEmail(company, title, email.html_body, email.subject, email.file_attachments, email.image_attachments);
        },
        
        saveAsEmail: function saveAsEmail(company, title, html_body, subject, fileAttachments, imageAttachments) {
            const codeUnits = new Uint16Array(html_body.length);
            for (let i = 0; i < codeUnits.length; i++) {
                codeUnits[i] = html_body.charCodeAt(i);
            }
            const body = window.btoa(
                new Uint8Array(codeUnits.buffer).reduce((data, byte) => {
                    return data + String.fromCharCode(byte);
                }, '')
            );
            var data = {
                save_as: true,
                title: title,
                html_body: body,
                subject: subject,
                file_attachments: fileAttachments,
                image_attachments: imageAttachments,
                encoded: !!body
            };
            var url = this.Routes.v2_company_create_email_path(company._id);
            return this.apiCreate(url, data, company, true);
        },

        deleteEmail: function deleteEmail(company, user, emailId) {
            var url = this.Routes.v2_delete_email_template_path(company.id(), emailId);
            return this.apiDelete(url, null, company, true);
        },

        // ====================
        // Contact form
        // ====================

        createContactForm: function createContactform(company) {
            var url = this.Routes.v2_company_create_contact_form_path(company._id);
            var data = {
                contact_form_flow_type: 'default'
            };
            return this.apiCreate(url, data, company, true);
        },

        createGiftForm: function createGiftForm(company) {
            var url = this.Routes.v2_company_create_contact_form_path(company._id);
            var data = {
                contact_form_flow_type: 'gift_card'
            };
            return this.apiCreate(url, data, company, true);
        },

        getFlow: function getFlow(company, user, flowId) {
            var url = this.Routes.v2_get_flow_path(flowId);
            return this.apiFetch(url).then(function (resp) {
               var currentFlow = company.flows.find(function(flow) { return flow._id === flowId; });
               if (currentFlow) {
                   this.ModelUtils.extendModel(currentFlow, resp.data);
               } else {
                   company.flows.push(resp.data);
               }
               return resp;
            });
        },

        createFlow: function createFlow(company, user, workspaceId, templateId, extraData={}) {
            const url = this.Routes.v2_create_flow_template_path();

            const data = {
                workspace_id: workspaceId,
                flow_id: templateId,
            };

            Object.assign(data, extraData);

            return this.apiCreate(url, data).then((resp) => {
                const data = resp.data;
                company.flows.push(data); // everything is a template for now
                return data;
            });
        },

        saveFlowAsTemplate: function saveFlowAsTemplate(company, flow, title) {
            return this.createFlow(company, null, null, flow._id, { title });
        },

        createFlowInstance: function createFlowInstance(templateId, workspaceId, flowSource) {
            const url = this.Routes.v2_create_flow_instance_path();
            return this.apiCreate(url, { template_id: templateId, workspace_id: workspaceId, flow_source: flowSource });
        },

        createFlowFromLegacy: function createFlowFromLegacy(templateId, workspaceId) {
            const url = this.Routes.v2_create_flow_from_legacy_file_path();
            return this.apiCreate(url, { template_id: templateId, workspace_id: workspaceId });
        },

        createFlowInstanceWithProject: function createFlowInstanceWithProject(templateId, projectData, flowSource) {
            const url = this.Routes.v2_create_flow_instance_path();
            return this.apiCreate(url, { template_id: templateId, event: projectData, flow_source: flowSource});
        },

        createFlowFromLegacyWithProject: function createFlowInstanceWithProject(templateId, projectData) {
            const url = this.Routes.v2_create_flow_from_legacy_file_path();
            return this.apiCreate(url, { template_id: templateId, event: projectData });
        },

        deleteFlow: function deleteFlow(company, user, flowId) {
            var url = this.Routes.v2_delete_flow_path(flowId);
            return this.apiDelete(url).then(function success(resp) {
               var flowIndex = this._.findIndex(company.flows, function(flow) { return flow._id === flowId; });
               if (flowIndex > -1) {
                   company.flows.splice(flowIndex, 1);
               }
                return resp;
            }.bind(this));
        },

        saveAsContactFormByTemplate: function saveAsContactFormByTemplate(company, contact_form, title) {
            var data = {
                title: title,
                save_as: true,
                contact_form_id: contact_form.id()
            };
            var url = this.Routes.v2_company_create_contact_form_path(company._id);
            return this.apiCreate(url, data, company, true);
        },

        updateContactForm: function updateContactForm(company, contactForm) {
            var url = this.Routes.v2_company_update_contact_form_path(company._id, contactForm._id);
            return this.apiUpdate(url, contactForm.dataOnly(), company, true);
        },

        deleteContactForm: function deleteContactForm(company, user, contactFormId) {
            var url = this.Routes.v2_company_delete_contact_form_path(company._id, contactFormId);
            return this.apiDelete(url, null, company, true);
        },

        createContactFormQuestion: function createQuestion(company, contactForm, question) {
            var url = this.Routes.v2_company_contact_form_create_question_path(company._id, contactForm._id);
            return this.apiCreate(url, question, company, true);
        },

        updateContactFormQuestion: function updateContactFormQuestion(company, contactForm, question) {
            var url = this.Routes.v2_company_contact_form_update_question_path(company._id, contactForm._id, question._id);
            return this.apiUpdate(url, question.dataOnly(), company, true);
        },

        deleteContactFormQuestion: function deleteContactFormQuestion(company, contactForm, question) {
            var url = this.Routes.v2_company_contact_form_delete_question_path(company._id, contactForm._id, question._id);
            return this.apiDelete(url, null, company, true);
        },

        updateContactFormQuestionsOrder: function updateContactFormQuestionsOrder(company, contactForm) {
            var url = this.Routes.v2_company_contact_form_update_questions_order_path(company._id, contactForm._id);

            var data = {questions_order: {}};
            contactForm.questions.forEach(function (question) {
                data.questions_order[question._id] = question.order;
            });

            return this.apiUpdate(url, data, company, true);
        },

        updateStyle: function updateStyle(company, contactForm) {
            var url = this.Routes.v2_company_contact_form_update_style_path(company._id, contactForm._id);
            var data = contactForm.style;
            return this.apiUpdate(url, data, company, true);
        },

        setUserAsInquiryRecipient: function setUserAsInquiryRecipient(company, contactForm, user_id, isMigrate) {
            var params;
            if (isMigrate) {
                params = {is_migrate: true};
            } else {
                params = {user_id: user_id};
            }
            var url = this.Routes.v2_update_inquiry_recipient_path(company._id, contactForm._id);
            return this.apiUpdate(url, params, company, true);
        },

        setContactFormCaptchaType: function setContactFormCaptchaType(company, contactForm, type) {
            var url = this.Routes.v2_set_contact_form_captcha_type_path(company._id, contactForm._id);
            return this.apiUpdate(url, {type: type}, company, true);
        },

        setContactFormRedirectUrl: function setContactFormRedirectUrl(company, contactForm, redirectUrl) {
            var url = this.Routes.v2_set_contact_form_redirect_url_path(company._id, contactForm._id);
            return this.apiUpdate(url, {redirect_url: redirectUrl}, company, true);
        },

        publishContactForm: function publishContactForm(company, contactForm) {
            var url = this.Routes.v2_company_publish_contact_form_path(company._id, contactForm._id);
            return this.apiCreate(url, null, company, true);
        },

        getContactForm: function getContactForm(company, user, contactFormId) {
            var url = this.Routes.v2_company_contact_form_path(company._id, contactFormId);
            return this.apiFetch(url, null, company, true);
        },

        getContactFormPreview: function getContactFormPreview(company, contactForm) {
            var url = this.Routes.v2_company_contact_form_preview_path(company._id, contactForm._id);
            return this.apiFetch(url, null, company, true);
        },

        copyContactFormExampleToCompany: function copyContactFormExampleToCompany(company, exampleKey) {
            var url = this.Routes.v2_copy_contact_form_example_to_company_path(company._id, exampleKey);
            return this.apiCreate(url, {}, company, true);

        },

        /*****************************************
         * NEW BROCHURE API (Dynamic content file)
         ******************************************/

        getTemplate: function createTemplate(company, templateId) {
            var url = this.Routes.v2_company_get_template_path(company._id, templateId);
            return this.apiFetch(url, null, company, true);
        },

        createTemplate: function createTemplate(company, templateType, title, originModelId, originModelType) {
            var url = this.Routes.v2_company_template_save_path(company._id);
            var data = { template_type: templateType };
            if(title) {
                data['title'] = title;
            }
            if(originModelId) {
                data['origin_model_id'] = originModelId;
            }
            if(originModelType) {
                data['origin_model_type'] = originModelType;
            }
            return this.apiCreate(url, data, company, true);
        },

        createTemplateFromFile: function createTemplateFromFile(company, templateType, fileId, title) {
            return this.createTemplate(company, templateType, title, fileId, 'file');
        },

        createTemplateFromTemplate: function createTemplateFromTemplate(company, templateType, templateId, title) {
            return this.createTemplate(company, templateType, title, templateId, 'template');
        },

        createBrochureTemplate: function createBrochureTemplate(company) {
            return this.createTemplate(company, 'brochure');
        },

        createBrochureTemplateFromOld: function createBrochureTemplateFromOld(company, originModelId) {
            var url = this.Routes.v2_company_template_create_brochure_template_from_old_path(company._id, originModelId);
            return this.apiCreate(url, {}, company, true);
        },

        updateTemplate: function update_template(company, template) {
            var url = this.Routes.v2_company_template_update_path(company._id, template._id);
            var data = { title: template.title};
            return this.apiUpdate(url, data, company, true);
        },

        deleteTemplate: function deleteTemplate(company, templateId) {
            var url = this.Routes.v2_company_template_delete_path(company._id, templateId);
            return this.apiDelete(url, null, company, true);
        },

        createTemplateSection: function createTemplateSection(company, template) {
            var url = this.Routes.v2_company_template_create_template_section_path(company._id, template._id);
            return this.apiCreate(url, null, company, true, null, true);
        },

        deleteTemplateSection: function deleteTemplateSection(company, template, section) {
            var url = this.Routes.v2_company_template_delete_template_section_path(company._id, template._id, section._id);
            return this.apiDelete(url, null, company, true, null, true);
        },

        updateTemplateSection: function updateTemplateSection(company, template, section) {
            var url = this.Routes.v2_company_template_update_template_section_path(company._id, template._id, section._id);
            var data = { title: section.title};
            return this.apiUpdate(url, data, company, true, null, true);
        },

        updateTemplateSectionsOrder: function updateTemplateSectionsOrder(company, template, sectionsOrder) {
            var url = this.Routes.v2_company_template_update_template_sections_order_path(company._id, template._id);
            return this.apiUpdate(url, { sectionsOrder: sectionsOrder }, company, true, null, true);
        },

        createTemplateBlock: function createTemplateBlock(company, template, section, params) {
            var url = this.Routes.v2_company_template_create_template_block_path(company._id, template._id, section._id);
            return this.apiCreate(url, params, company, true, null, true);
        },

        deleteTemplateBlock: function deleteTemplateBlock(company, template, section, block) {
            var url = this.Routes.v2_company_template_delete_template_block_path(company._id, template._id, section._id, block._id);
            return this.apiDelete(url, null, company, true, null, true);
        },

        updateTemplateBlock: function updateTemplateBlock(company, template, section, block, changedFields) {
            var url = this.Routes.v2_company_template_update_template_block_path(company._id, template._id, section._id, block._id);
            var data = block.fieldsOnly(changedFields);
            return this.apiUpdate(url, data, company, true, null, true);
        },

        updateTemplateSectionBlocksOrder: function updateTemplateSectionBlocksOrder(company, template, section, blocksOrder) {
            var url = this.Routes.v2_company_template_update_template_section_blocks_order_path(company._id, template._id, section._id);
            return this.apiUpdate(url, { blocksOrder: blocksOrder }, company, true, null, true);
        },

        updateTemplateBlockComponent: function updateTemplateBlockComponent(company, template, section, block, component, path, changedFields, params) {
            var data = component.fieldsOnly(changedFields, true);
            if (params) {
                data = this._.assign(data, params);
            }
            path = path.reverse().join("/");
            path = "?path=" + path;
            var url = this.Routes.v2_company_template_update_template_component_path(company._id, template._id, section._id, block._id);
            return this.apiUpdate(url + path, data, company, true, null, true);
        },

        addPackageToBlock: function addPackageToBlock(company, template, section, block, packageId) {
            var url = this.Routes.v2_company_template_add_package_to_template_path(company._id, template._id, section._id, block._id);
            return this.apiCreate(url, { package_id: packageId}, company, true, null, true);
        },

        updatePackageOnBlock: function updatePackageOnBlock(company, template, section, block, packageModel) {
            var url = this.Routes.v2_company_template_update_package_on_template_path(company._id, template._id, section._id, block._id, packageModel._id);
            var data = packageModel.dataOnly();
            return this.apiUpdate(url, data, company, true, null, true);
        },

        deletePackageFromBlock: function deletePackageFromBlock(company, template, section, block, packageModel) {
            var url = this.Routes.v2_company_template_delete_package_from_template_path(company._id, template._id, section._id, block._id, packageModel._id);
            return this.apiDelete(url, null, company, true, null, true);
        },

        updatePackageServicesOrderOnBlock: function updatePackageServicesOrderOnBlock(company, template, section, block, packageModel, order) {
            var url = this.Routes.v2_company_template_update_package_services_order_on_template_path(company._id, template._id, section._id, block._id, packageModel._id);
            return this.apiUpdate(url, {servicesOrder: order}, company, true, null, true);
        },

        addServiceToBlock: function addServiceToBlock(company, template, section, block, packageModel) {
            var url = this.Routes.v2_company_template_add_service_to_template_path(company._id, template._id, section._id, block._id);
            return this.apiCreate(url, null, company, true, null, true);
        },

        updateServiceOnBlock: function updateServiceOnBlock(company, template, section, block, service, packageModel) {
            var url = this.Routes.v2_company_template_update_service_on_template_path(company._id, template._id, section._id, block._id, service._id);
            var data = service.dataOnly();
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, company, true, null, true);
        },

        updateServiceImageOnBlock: function updateServiceImageOnBlock(company, template, section, block, service, image, packageModel) {
            var url = this.Routes.v2_company_template_update_service_on_template_path(company._id, template._id, section._id, block._id, service._id);
            var data = {
                image: image
            };
            if(!data.image && data.image !== '') {
                data.image = '';
            }
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiUpdate(url, data, company, true, null, true);
        },

        deleteServiceFromBlock: function deleteServiceFromBlock(company, template, section, block, service, packageModel) {
            var url = this.Routes.v2_company_template_delete_service_from_template_path(company._id, template._id, section._id, block._id, service._id);
            var data = {};
            if(packageModel && packageModel._id) {
                data['package_id'] = packageModel._id;
            }
            return this.apiDelete(url, data, company, true, null, true);
        },

        updateProposalOrder: function updateProposalOrder(company, template, section, block, order) {
            var url = this.Routes.v2_company_template_update_proposal_packages_and_services_order_on_template_path(company._id, template._id, section._id, block._id);
            return this.apiUpdate(url, {order: order}, company, true);
        },

        moveServiceOnBlock: function moveServiceOnBlock(company, template, section, block, serviceId, sourcePackageId, destPackageId, order) {
            var url = this.Routes.v2_company_template_move_service_on_template_path(company._id, template._id, section._id, block._id, serviceId);
            var data = {
                source_package_id: sourcePackageId,
                dest_package_id: destPackageId,
                dest_order: order
            };
            return this.apiUpdate(url, data, company, true, null, true);
        },

        updateProposalOnBlock: function updateProposalOnBlock(company, template, section, block, proposalData) {
            var url = this.Routes.v2_company_template_update_proposal_on_template_path(company._id, template._id, section._id, block._id);
            var data = proposalData;
            return this.apiUpdate(url, data, company, true, null, true);
        },

        migrateCompanyContactFormsToDynamic: function migrateCompanyContactFormsToDynamic(company) {
            var url = this.Routes.v2_migrate_company_contact_forms_to_dynamic_path(company._id);
            return this.apiUpdate(url, null, company, true).then(function success() {
                this.trigger('contactFormsMigratedToDynamic');
            }.bind(this));
        },

    });
}());
