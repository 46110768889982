Directives.PaymentDirective = function PaymentDirective() {

    // @ngInject
    function PaymentDirectiveControllerFunc($timeout, $element, $scope, $injector, _, moment, Enums, $document, $q,
                                               CompaniesManager, PendingTasksManager, UsersManager, AnalyticsService, ModalService,
                                               WorkspaceFileService, AppConfigService, PopupMessageService, PlaidLinkService,
                                               WorkspaceFilesManager, WorkspacesManager, $state, AppStates, $window, $translate, UIUtils, DeviceService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PaymentDirectiveController';
        var self = this;

        this.Enums = Enums;
        this.PopupMessageService = PopupMessageService;
        this.PendingTasksManager = PendingTasksManager;
        this.UsersManager = UsersManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspacesManager = WorkspacesManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.$translate = $translate;
        this.AppStates = AppStates;
        this.$state = $state;
        this.isRefundedAmountReadOnly = false;
        this.$window = $window;
        this.$q = $q;
        this.UIUtils = UIUtils;
        this.DeviceService = DeviceService;
        this.decimalRegex = /^[0-9,]+(\.[0-9,]+)?$/;
        this.moment = moment;

        this.company = CompaniesManager.getCurrCompany();
        this.currentUser = UsersManager.getCurrUser();
        this.paymentsContainer = this.payment._getPaymentsContainer();

        var paymentsState = AppConfigService.filePaymentState();

        var curPaymentState = paymentsState[this.payment._id];
        if (!curPaymentState) {
            curPaymentState = {};
            paymentsState[this.payment._id] = curPaymentState;
        }

        this.inEdit = curPaymentState.inEdit;
        this.editMode = curPaymentState.editMode || 'amount';
        this.paymentMenuOpen = curPaymentState.paymentMenuOpen;
        this.markAsPaidSelectedOption = 'non-selected';
        this.confirm_refund_amount = false;
        this.confirm_already_transferred = false;
        this.confirm_cc_for_charge = false;
        this.confirm_refund_procedure = false;
        this.disableTransition = false;

        $document.on('mousedown', function (e) {
            this.onClick(e);
        }.bind(this));

        this.onClick = function(e) {
            var target = angular.element(e.target);
            if (!e.target.closest('.hb-popover-content')) {
                this.closeAllMilestoneRemindersPopover();
            }
        }

        /*****************************************************
         * MARK AS PAID
         *****************************************************/
        this.markAsPaidTypes = [
            {type: this.payment.markAsPaidTypes.cash.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._CASH_'},
            {type: this.payment.markAsPaidTypes.check.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._CHECK_'},
            {type: this.payment.markAsPaidTypes.other.name, label: 'PAYMENTS.MARK_AS_PAID.LABELS._OTHER_'}
        ];
        this.markAsPaidStatues = {
            inputAmount: 'inputAmount',
            concierge: 'concierge',
            showForm: 'showForm',
            mapOnDraft: 'mapOnDraft'
        };
        this.DATE_FORMAT = 'YYYY-MM-DD';


        if (curPaymentState.percentageFocused) {
            $timeout(function () {
                $element.find('.amount-percent').focus();
            }, 0);
        }

        if (curPaymentState.fixedAmountFocused) {
            $timeout(function () {
                $element.find('.fixed-amount').focus();
            }, 0);
        }

        this.workspaceFile = this.payment.getFile();
        if (this.workspaceFile) {
            this.fileId = this.workspaceFile._id;

            this.register(this.workspaceFile, 'success', function() {
                $scope.$applyAsync(function () {
                    this.initializePayment();
                }.bind(this));
            }.bind(this));
        }

        /*****************************************************
         * MILESTONE REMINDER POPOVER
         *****************************************************/

        this.milestoneRemindersPopover = []
        this.showMilestoneRemindersPopover = function toggleMilestoneRemindersPopover(paymentId) {
            this.milestoneRemindersPopover[paymentId] = true;
        };

        this.closeAllMilestoneRemindersPopover = function closeAllMilestoneRemindersPopover() {
            this.milestoneRemindersPopover = [];
        };

        /*****************************************************
         * PAYMENT MENU
         *****************************************************/

        $scope.$on('workspaceFileSent', function() {
           self.closePaymentMenu();
        });

        this.closePaymentMenu = function closePaymentMenu() {
            this.paymentMenuOpen = false;
            curPaymentState.paymentMenuOpen = this.paymentMenuOpen;
            this.markAsPaidActive = false;
            this.missingOtherReason = false;
            this.editMode = "";
            this.inEdit = false;
            curPaymentState.inEdit = this.inEdit;
            this.inReceipt = false;
        };

        this.editPaymentStart = function editPaymentStart(editMode) {
            if (!this.allowEdit() || this.isPreviewMode) {
                return;
            }

            if (editMode) {
                this.editMode = editMode;
            }
            this.inEdit = true;
            curPaymentState.inEdit = this.inEdit;
            this.paymentMenuOpen = false;
            curPaymentState.paymentMenuOpen = this.paymentMenuOpen;
            this.markAsPaidActive = false;
            this.missingOtherReason = false;
        };

        this.togglePaymentEditMenu = function togglePaymentEditMenu(editMode, disableEditMode) {
            if (disableEditMode) {
                this.inEdit = false;
                return;
            }

            if (this.inEdit) {
                this.disableTransition = true;
                var current_mode = this.editMode;
                this.closePaymentMenu();
                if (current_mode !== editMode) {
                    this.editPaymentStart(editMode);
                }
            } else {
                this.disableTransition = false;
                this.editPaymentStart(editMode);
            }
        };

        this.payNow = function payNow(isOnRedirect) {
            var options = {};
            if (!this.isInWorkspaceView) {
                options.location = 'replace';
            }
            if (this.isWaitingForAccept) {
                this.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info, 'PAYMENTS._ACCEPT_CHANGES_POPUP_');
                this.$window.scrollTo(0, 0);
            }
            else {
                WorkspaceFileService.gotoPayView(this.fileId, this.payment._id, { isOnRedirect: isOnRedirect}, options);
            }
        };

        this.onEditPaymentMethodClick = function onEditPaymentMethodClick() {
            var options = {};
            if (!this.isInWorkspaceView) {
                options.location = 'replace';
            }
            WorkspaceFileService.gotoPayView(this.fileId, this.payment._id, {editCardMode: true}, options);
        };

        this.deletePayment = function deletePayment() {

            if (this.busy) {
                // We are probably working on deleting or updating this payment... wait until it ends.
                return;
            }
            this.hideOptionsFlyout();

            var confirmedDelete = function confirmedDelete() {
                this.busy = true;
                this.payment.deletePayment().then(
                    function () {
                        this.busy = false;
                        AnalyticsService.track(self, AnalyticsService.analytics_events.payment_deleted, {
                            payment_id: self.payment._id
                        });
                    }.bind(this),

                    function (resp) {
                        this.busy = false;
                        AnalyticsService.track(self, AnalyticsService.analytics_events.payment_update_error, {
                            payment_id: self.payment._id,
                            error_message: resp.data
                        });
                    }.bind(this)
                );
            };

            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'PAYMENTS.POPUPS._DELETE_PAYMENT_MESSAGE_').then(confirmedDelete.bind(this));

        };

        this.allowMenu = function allowMenu() {
            return this.payment.allowMenu();
        };

        this.allowFlyouts = function allowFlyouts() {
            return this.isEditableMode && !this.isPreviewMode && !this.isPaid && this.isOwnerMode && this.allowEdit();
        };
        this.allowEdit = function allowEdit() {
            return this.templateMode || this.payment.allowEdit();
        };

        this.allowDelete = function allowDelete() {
            return !this.paymentsContainer.isRecurringPayment() && (this.templateMode || (!this.isPreviewMode && this.isOwnerMode && this.isEditableMode && this.payment.allowDelete()));
        };

        this.markAsPaidPop = function markAsPaidPop() {
            this.markedAsPaidPop = !this.markedAsPaidPop;
        };

        this.paymentCanBeMarkedAsPaid = function paymentCanBeMarkedAsPaid() {
            return this.payment.paymentCanBeMarkedAsPaid();
        };

        this.allowSendReminder = function allowSendReminder() {
            return !this.isPreviewMode && !this.markAsPaidInProgress && this.payment.allowSendReminder();
        };

        this.allowPay = function allowPay() {
            return (this.payment.allowPay());
        };

        this.allowShowReceipt = function allowShowReceiptWithMenu() {
            return this.payment.allowShowReceipt();
        };

        this.showRefund = function showRefund() {
            // TODO UDI make sure this is the last paid payment
            return this.payment.allowRefund() && !this.markAsPaidInProgress && !this.isPreviewMode;
        };


        /*****************************************************
         * REFUND PAYMENT
         *****************************************************/

        this.openRefundPaymentSection = function openRefundPaymentSection(isUndoMap, isOnRedirect) {

            this.hideOptionsFlyout();

            var analyticsArgs = {
                transferred_to_vendor: this.payment.transferred_to_vendor
            };
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.open_refund_payment_section, analyticsArgs);

            if (!this.payment.allowRefund()) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'REPORTS.PAYMENTS._REFUND_DISABLED_');
                return;
            }

            if (this.payment.isDraftEditable() && !this.payment.is_manual) {
                this.PopupMessageService.showErrorAlert(
                    'REPORTS.PAYMENTS._REFUND_EDITABLE_DRAFT_ERROR_'
                );
                return;
            }

            // If not MAP, continue..

            if (this.payment.isRefundPeriodInvalid()) {
                this.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'REPORTS.PAYMENTS._REFUND_ACH_REFUND_PERIOD_OVERDUE_');
                return;
            }

            this.WorkspaceFileService.openRefundModal(this.currentUser, this.company, this.payment, this.workspaceFile._id, isUndoMap, isOnRedirect).then(function() {
                this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.fileId);
            }.bind(this));
        };

        /*****************************************************
         * RECEIPT
         *****************************************************/
        this.toggleReceiptShow = function toggleReceiptShow() {
            this.hideOptionsFlyout();
            this.inReceipt = !this.inReceipt;
        };

        this.showReceiptTip = function showReceiptTip() {
            return this.payment.tip_paid && this.payment.tip_paid > 0;
        };

        this.showRefundAmount = function showRefundAmount() {
            return this.payment.isRefunded();
        };

        this.printReceipt = function printReceipt() {
            this.hideOptionsFlyout();
            this.payment.printReceipt();
        };

        /*****************************************************
         * PAYMENT REMINDER
         *****************************************************/

        this.getPaymentReminderStatus = function getPaymentReminderStatus() {
            if (this.paymentReminderStatus){
                return this.paymentReminderStatus;
            }
            if (this.payment.isLastReminderSeen()){
                return 'PAYMENTS.REMINDER._REMINDER_SENT_AND_SEEN_';
            }
            if (this.payment.isReminderAlreadySentRecently()){
              return 'PAYMENTS.REMINDER._REMINDER_SENT_RECENTLY_';
            }
            if (this.payment.isReminderAlreadySent()) {
                return 'PAYMENTS.REMINDER._REMINDER_SENT_';//probably want ot display time ago or smtng like that...
            }
            return '';
        };

        this.sendPaymentReminder = function sendPaymentReminder() {
            this.closePaymentMenu();
            this.hideOptionsFlyout();
            this.paymentReminderStatus = 'PAYMENTS.REMINDER._REMINDER_SENDING_';
            var promise = this.payment.sendPaymentReminder();
            promise.then(
                this.sendPaymentReminderSuccess.bind(this),
                this.sendPaymentReminderError.bind(this)
            );
        };

        this.sendPaymentReminderSuccess = function sendPaymentReminderSuccess(message, status) {
            this.paymentReminderStatus = 'PAYMENTS.REMINDER._REMINDER_SENT_RECENTLY_';

            AnalyticsService.track(self, AnalyticsService.analytics_events.payment_reminder_sent, {
                source: 'file',
                file_id: this.fileId,
                payment_id: this.payment._id
            });
        };

        this.sendPaymentReminderError = function sendPaymentReminderError(resp) {
            this.paymentReminderStatus = resp.data.error_message;

            AnalyticsService.trackError(self, AnalyticsService.analytics_events.payment_reminder_error, resp, {
                source: 'file',
                file_id: this.fileId,
                payment_id: this.payment._id
            });
        };

        this.sendMilestonePaymentReminder = function sendMilestonePaymentReminder() {
            this.sendingReminder = true;

            var promise = this.payment.sendPaymentReminder();
            promise.then(
                this.sendMilestonePaymentReminderSuccess.bind(this),
                this.sendMilestonePaymentReminderError.bind(this)
            );

            promise.finally(function fulfilled() {
                this.sendingReminder = false;
            }.bind(this));
        };

        this.sendMilestonePaymentReminderSuccess = function sendMilestonePaymentReminderSuccess(message, status) {
            AnalyticsService.track(self, AnalyticsService.analytics_events.payment_reminder_sent, {
                source: 'file',
                file_id: this.fileId,
                payment_id: this.payment._id
            });
        };

        this.sendMilestonePaymentReminderError = function sendMilestonePaymentReminderError(resp) {
            AnalyticsService.trackError(self, AnalyticsService.analytics_events.payment_reminder_error, resp, {
                source: 'file',
                file_id: this.fileId,
                payment_id: this.payment._id
            });
        };

        /*****************************************************
         * PAYMENT EDIT
         *****************************************************/

        this.editModeChanged = function editModeChanged() {
            curPaymentState.editMode = this.editMode;
        };

        /*****************************************************
         * SCHEDULE
         *****************************************************/
        this.paymentDateTypes = [
            {type: this.payment.dateCalcTypes.invoiceDate.name, text: 'PAYMENTS.SCHEDULE.LABELS._INVOICE_DATE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._INVOICE_DATE_', className: 'payment-date-type__today', key: 'PAYMENTS.SCHEDULE.LABELS._INVOICE_DATE_'},
            {type: this.payment.dateCalcTypes.fixedDate.name, text: 'PAYMENTS.SCHEDULE.LABELS._FIXED_DATE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._FIXED_DATE_', className: 'payment-date-type__fixed_date', key: 'PAYMENTS.SCHEDULE.LABELS._FIXED_DATE_'},
            {type: this.payment.dateCalcTypes.customDate.name, text: 'PAYMENTS.SCHEDULE.LABELS._CUSTOM_DATE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._CUSTOM_DATE_', className: 'payment-date-type__custom_date', key: 'PAYMENTS.SCHEDULE.LABELS._CUSTOM_DATE_'},
            {type: this.payment.dateCalcTypes.midwayDate.name, text: 'PAYMENTS.SCHEDULE.LABELS._MIDWAY_DATE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._MIDWAY_DATE_', className: 'payment-date-type__midway_date', key: 'PAYMENTS.SCHEDULE.LABELS._MIDWAY_DATE_'},
            {type: this.payment.dateCalcTypes.eventDate.name, text: 'PAYMENTS.SCHEDULE.LABELS._PROJECT_DATE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._PROJECT_DATE_', className: 'payment-date-type__project_date', key: 'PAYMENTS.SCHEDULE.LABELS._PROJECT_DATE_'},
            {type: this.payment.dateCalcTypes.milestone.name, text: 'PAYMENTS.SCHEDULE.LABELS._MILESTONE_', secondaryText: 'PAYMENTS.SCHEDULE.SECONDARY_LABELS._MILESTONE_', className: 'payment-date-type__milestone', key: 'PAYMENTS.SCHEDULE.LABELS._MILESTONE_'}
        ];

        this.paymentDateUnitTypes = [
            {type: this.payment.dateUnitTypes.day.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._DAY_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._DAY_'},
            {type: this.payment.dateUnitTypes.week.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._WEEK_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._WEEK_'},
            {type: this.payment.dateUnitTypes.month.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._MONTH_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.UNIT_TYPES._MONTH_'}
        ];

        this.paymentDateOperatorTypes = [
            {type: this.payment.dateOperatorTypes.beforeEvent.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._BEFORE_PROJECT_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._BEFORE_PROJECT_'},
            {type: this.payment.dateOperatorTypes.afterEvent.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._AFTER_PROJECT_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._AFTER_PROJECT_'},
            {type: this.payment.dateOperatorTypes.afterInvoice.name, label: 'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._AFTER_INVOICE_', key:'PAYMENTS.SCHEDULE.CUSTOM_DATE_OPTIONS.OPERATORS._AFTER_INVOICE_'}
        ];

        this.recurringPaymentEndDateOptions = [
            {type: 'never', display: 'PAYMENTS.RECURRING.END_DATE_TYPE._NEVER_', label: 'PAYMENTS.RECURRING.END_DATE_TYPE._NEVER_'},
            {type: 'date', display: 'PAYMENTS.RECURRING.END_DATE_TYPE._ON_', label: 'PAYMENTS.RECURRING.END_DATE_TYPE._ON_DATE_'},
        ];

        var activeDateCalcType = this.payment.getActiveDateCalcType();
        this.selectedDateType = _.find(this.paymentDateTypes, function (item) {
                return (item.type === activeDateCalcType);
            }
        );

        var activeDateUnitType = this.payment.getActiveDateUnitType();
        this.selectedDateUnitType = _.find(this.paymentDateUnitTypes, function (item) {
                return (item.type === activeDateUnitType);
            }
        );

        var activeDateOperatorType = this.payment.getActiveDateOperatorType();
        this.selectedDateOperatorType = _.find(this.paymentDateOperatorTypes, function (item) {
                return (item.type === activeDateOperatorType);
            }
        );

        this.activeDateValue = this.payment.getActiveDateValue();
        if (this.selectedDateType.type === this.payment.dateCalcTypes.fixedDate.name) {
            this.activeDateValue = moment(this.payment.due_date).format("MMM DD, YYYY");
        }

        this.dateTypeChanged = function dateTypeChanged(type) {
            this.selectedDateType = type;
            this.activeDateValue = this.payment.getActiveDateValue();

            if (this.selectedDateType.type === this.payment.dateCalcTypes.fixedDate.name) {
                if (this.payment.is_milestone) {
                    this.activeDateValue = moment().format("MMM DD, YYYY");
                } else {
                    this.activeDateValue = moment(this.payment.due_date).format("MMM DD, YYYY");
                }
            } else if (this.selectedDateType.type === this.payment.dateCalcTypes.customDate.name) {
                this.activeDateValue = 1;
            }

            this.generateDueDateDescription();

            this.updatePaymentDate();

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_date_type_updated, {
                file_id: this.fileId,
                payment_id: this.payment._id,
                date_type: type.type || type
            });
        };

        this.generateDueDateDescription = function generateDueDateDescription() {

            this.dueDateDescription = this.dueDateDescriptionPrefix1 = this.dueDateDescriptionPrefix2 = undefined;
            if (!this.payment.is_paid && this.payment.isDateTbd()) {

                switch (this.selectedDateType.type) {
                    case this.payment.dateCalcTypes.invoiceDate.name:
                        this.dueDateDescription = moment().format('MMM DD, YYYY');
                        break;
                    case this.payment.dateCalcTypes.eventDate.name:
                        this.dueDateDescription = 'PAYMENTS.SCHEDULE._PROJECT_DATE_TBD_';
                        break;
                    case this.payment.dateCalcTypes.midwayDate.name:
                        this.dueDateDescription = 'PAYMENTS.SCHEDULE._MIDWAY_DATE_TBD_';
                        break;
                    case this.payment.dateCalcTypes.customDate.name:

                        this.dueDateDescriptionPrefix1 = this.activeDateValue;
                        this.dueDateDescriptionPrefix2 = this.selectedDateUnitType.label;

                        switch (this.selectedDateOperatorType.type) {
                            case this.payment.dateOperatorTypes.beforeEvent.name:
                                this.dueDateDescription = 'PAYMENTS.SCHEDULE._BEFORE_PROJECT_TBD_';
                                break;
                            case this.payment.dateOperatorTypes.afterEvent.name:
                                this.dueDateDescription = 'PAYMENTS.SCHEDULE._AFTER_PROJECT_TBD_';
                                break;
                            case this.payment.dateOperatorTypes.afterInvoice.name:
                                this.dueDateDescription = 'PAYMENTS.SCHEDULE._AFTER_INVOICE_TBD_';
                                break;
                        }

                        break;
                    default:
                        this.dueDateDescription = undefined;
                }
            } else {
                this.dueDateDescription = undefined;
            }
        };

        this.dateUnitTypeChanged = function dateUnitTypeChanged(type) {
            this.selectedDateUnitType = type;
            this.updatePaymentDate();
        };

        this.dateOperatorTypeChanged = function dateOperatorTypeChanged(type) {
            this.selectedDateOperatorType = type;
            this.updatePaymentDate();
        };

        this.dateValueChanged = function dateValueChanged() {
            this.updatePaymentDate();
        };

        this.inputFocused = function inputFocused(inputName) {
            if (inputName === this.payment.amountCalcTypes.percentageAmount.name) {
                curPaymentState.percentageFocused = true;
            } else if (inputName === this.payment.amountCalcTypes.fixedAmount.name) {
                curPaymentState.fixedAmountFocused = true;
            }
        };

        this.inputFocusLost = function inputFocusLost(inputName) {
            if (inputName === this.payment.amountCalcTypes.percentageAmount.name) {

                if (this.validatePercentage(this.activeAmountValue)) {
                    this.updatePayment(this.payment.amountCalcTypes.percentageAmount.name);
                }

                curPaymentState.percentageFocused = false;
            } else if (inputName === this.payment.amountCalcTypes.fixedAmount.name) {
                curPaymentState.fixedAmountFocused = false;
            }
        };

        this.updatePaymentDate = function updatePaymentDate() {

            var value = this.activeDateValue;
            if (this.selectedDateType.type === this.payment.dateCalcTypes.fixedDate.name) {
                value = moment(this.activeDateValue).format("YYYY-MM-DD");
            } else if (this.selectedDateType.type === this.payment.dateCalcTypes.milestone.name) {
                value = null;
            }

            this.payment.updatePaymentSchedule(this.selectedDateType.type, this.selectedDateUnitType.type, this.selectedDateOperatorType.type, value).then(
                function (message, status) {

                    $timeout(function () {
                        this.setPaymentStatus();
                    }.bind(this), 0);

                    AnalyticsService.track(self, AnalyticsService.analytics_events.payment_date_updated, {
                        file_id: self.fileId,
                        payment_id: self.payment._id
                    });

                    this.generateDueDateDescription();
                }.bind(this),

                function (message, status) {
                    AnalyticsService.track(self, AnalyticsService.analytics_events.payment_update_error, {
                        file_id: self.fileId,
                        payment_id: self.payment._id,
                        error_message: message
                    });
                }
            );
        };

        /*****************************************************
         * AMOUNT
         *****************************************************/

        this.paymentAmountTypeTab = this.payment.getActiveAmountCalcType();

        this.paymentAmountTypes = [
            {type: this.payment.amountCalcTypes.fixedAmount.name, label: 'PAYMENTS.AMOUNT.LABELS._CUSTOM_AMOUNT_', key: this.Enums.PaymentAmountTypes.customAmount},
            {type: this.payment.amountCalcTypes.percentageAmount.name, label: 'PAYMENTS.AMOUNT.LABELS._PERCENTAGE_', key: this.Enums.PaymentAmountTypes.percentage},
            {type: this.payment.amountCalcTypes.divideEquallyAmount.name, label: 'PAYMENTS.AMOUNT.LABELS._DIVIDE_EQUALLY_', key: this.Enums.PaymentAmountTypes.divideEqually}
        ];


        this.allowMarkAsPaidBlockedBecauseOfDraft = function allowMarkAsPaidBlockedBecauseOfDraft() {
            return this.payment.allowMarkAsPaidBlockedBecauseOfDraft();
        };

        this.allowClickToMarkAsPaid = function allowClickToMarkAsPaid() {
            return this.workspaceFile && this.paymentCanBeMarkedAsPaid();
        };

        this.markAsPaidStart = function markAsPaidStart() {

            if (!WorkspaceFileService.arePaymentsAndProposalAmountsEqual(this.paymentsContainer)) {
                PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'PAYMENTS._PAYMENTS_DO_NOT_ADD_UP_TO_PROPOSAL_SUM_');
                return;
            }

            if (this.payment.allowMarkAsPaidBlockedBecauseOfDraft()) {
                this.showMarkAsPaidPopup(this.markAsPaidStatues.mapOnDraft);
            }
            else {
                this.showMarkAsPaidPopup(this.markAsPaidStatues.inputAmount);
            }
        };

        this.showMarkAsPaidPopup = function showMarkAsPaidPopup(status) {
            if (!this.markAsPaidDate) {
                this.markAsPaidDate = moment(this.payment.due_date).format("MMM DD, YYYY");
            }

            this.markAsPaidFormStatus = status;
            this.markAsPaidActive = true;
        };

        this.onMarkAsPaidAmountInput = function onMarkAsPaidAmountInput() {

            if (!this.decimalRegex.test(this.mapAmount)) {
                this.showInvalidAmountError = true;
                return;
            }

            var amount = (this.mapAmount || 0).replace(/,/g, '');

            // this is just for validation it dose not change the amount of the payment that is set in the server
            if (Math.round(amount * 100) / 100 === Math.round(this.payment.amount * 100) / 100) {
                this.markAsPaidFormStatus = this.markAsPaidStatues.showForm;
            }
            else {
                this.markAsPaidFormStatus = this.markAsPaidStatues.concierge;
            }

            this.mapAmount = null;
        };

        this.disableInvalidAmountError = function disableInvalidAmountError() {
            this.showInvalidAmountError = false;
        };

        this.onDraftMarkAsPaidConfirm = function onDraftMarkAsPaidConfirm() {
            this.markAsPaidFormStatus = this.markAsPaidStatues.showForm;
            this.markAsPaidAsDraft = true;
        };

        this.closeSetAsPaidDropdown = function closeSetAsPaidDropdown() {
            $document.click();
        };

        this.markAsPaidTypeChanged = function markAsPaidTypeChanged() {
            this.missingOtherReason = false;
            if(this.mapPaymentNotSetError){
                this.mapPaymentNotSetError = false;
            }
        };

        // this.showReadOnlyRefundMessage = function showReadOnlyRefundMessage() {
        //     this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, "Partial refunds are coming soon!");
        //     var analyticsArgs = {
        //     };
        //     this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.click_refund_amount_input, analyticsArgs);
        // }

        this.setMarkAsPaid = function setMarkAsPaid() {
            if (this.markAsPaidSelectedOption === 'non-selected') {
                //this is just an internal protection so the user will be able to do a 'mark as pay' without selecting a method
                this.mapPaymentNotSetError = true;
                return;
            }
            this.mapPaymentNotSetError = false;

            //for 'other' method of mark as paid we must have a reason
            if (this.markAsPaidSelectedOption === this.payment.markAsPaidTypes.other.name) {
                if (!this.markAsPaidOtherReason) {
                    this.missingOtherReason = true;
                    return;
                }
            }

            var markAsPaid = function() {
                this.markAsPaidInProgress = true;
                var date = moment(new Date(this.markAsPaidDate)).format(this.DATE_FORMAT);
                return this.payment.setMarkAsPaid(this.markAsPaidSelectedOption, date, this.markAsPaidOtherReason)
                    .then(function success(respData, respStatus) {
                        this.closePaymentMenu();

                        AnalyticsService.track(this, AnalyticsService.analytics_events.payment_marked_as_paid, {
                            file_id: this.fileId,
                            payment_id: this.payment._id,
                            payment_method: this.markAsPaidSelectedOption
                        });
                    }.bind(this))
                    .catch(function error(respData, respStatus) {
                        this.closePaymentMenu();

                        AnalyticsService.track(this, AnalyticsService.analytics_events.payment_mark_as_paid_error, {
                            file_id: this.fileId,
                            payment_id: this.payment._id,
                            error_message: respData
                        });

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, respData.error_message);
                    }.bind(this))
                    .finally(function final() {
                        this.markAsPaidInProgress = false;
                    }.bind(this));
            }.bind(this);

            // Need to open send file modal
            // Note: if we map after the send email modal, we're not waiting for the map until navigating back to the workspace (unlike regular map)
            if (this.markAsPaidAsDraft) {
                var workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id);
                this.WorkspaceFileService.sendFile(this.currentUser, workspace, this.workspaceFile, null, null, true).then(function success() {
                    markAsPaid();
                }.bind(this));
            }
            else {
                markAsPaid().then(function success() {
                }.bind(this));
            }
        };

        this.analyticsProperties = function analyticsProperties() {

            var props = {};
            if (this.payment) {
                props.payment_id = this.payment._id;
                props.payment_portion = this.payment.count_description;
                props.payment_amount = this.payment.amount;
                props.payment_is_paid = this.payment.is_paid;
                props.payment_is_pending = this.payment.is_pending;
                props.payment_is_manual = this.payment.is_manual;
                props.payment_charge_description = this.payment.charge_description;
                props.payment_charge_date = this.payment.charge_date;
                props.payment_due_date = this.payment.due_date;
            }
            return props;
        };

        this.initializePayment();


        const redirectData = PlaidLinkService.loadRedirectData();
        if (redirectData.isRedirect && this.payment._id === redirectData.paymentId) {
            if (redirectData.source === 'refund'){
                this.openRefundPaymentSection(false, true);
            } else {
                this.payNow(true);
            }
        }
    }

    var PaymentDirectiveController = Class(Controllers.BaseController, {
        constructor: PaymentDirectiveControllerFunc,

        initializePayment: function initializePayment() {
            this.setPaymentStatus();
            this.generateDueDateDescription();
            this.activeAmountValue = this.payment.getActiveAmountValue();


            if (this.payment.activeAmountCalcType === 'percentageAmount'){
                this.validatePercentage(this.activeAmountValue);
            }

            this.selectedRecurringPaymentEndDate = this.recurringPaymentEndDateOptions[0];
            if (this.paymentsContainer.isRecurringPayment() && this.paymentsContainer.recurring_payment) {
                this.recurringPaymentStartDate = this.moment(this.paymentsContainer.recurring_payment.start_date).format("MMM DD, YYYY");
                if (this.paymentsContainer.recurring_payment.end_date !== null) {
                    this.selectedRecurringPaymentEndDate = this.recurringPaymentEndDateOptions[1];
                    this.recurringPaymentEndDate = this.moment(this.paymentsContainer.recurring_payment.end_date).format("MMM DD, YYYY");
                } else {
                    this.selectedRecurringPaymentEndDate = this.recurringPaymentEndDateOptions[0];
                }
            }
        },

        confirmRefundAmount: function confirmRefundAmount() {
            var self = this;

            if (!self.decimalRegex.test(self.amountToRefund)) {
                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'REPORTS.PAYMENTS._REFUND_NON_NUMERIC_AMOUNT_');
                return;
            }

            self.confirm_refund_amount = true;

            if (!self.showAddCreditCardForm) {
                self.confirm_refund_procedure = true;
            }

            var analyticsArgs = {
                amount: self.amountToRefund
            };
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.continue_with_refund_payment, analyticsArgs);
        },

        confirmRefundProcedure: function confirmRefundProcedure() {
            var self = this;
            self.confirm_refund_procedure = true;

            var analyticsArgs = {
                amount: self.amountToRefund
            };
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.continue_with_refund_payment2, analyticsArgs);
        },

        changeCreditCardForRefund: function changeCreditCardForRefund() {
            var self = this;
            self.showAddCreditCardForm = true;
        },

        setPaymentStatus: function setPaymentStatus() {
            var self = this;

            if (this.templateMode) {
                self.isUnpaid = true;
                self.paymentStatusLabel = 'PAYMENTS.LABELS._UPCOMING_';
                return;
            }

            self.isPaid = self.payment.isStatusPaid();
            self.isReservedForDispute = self.payment.isPaidAndDisputeReserved() && !self.isPreviewMode;
            self.isReservedForDisputeLost = self.payment.isPaidAndProcessedForDispute() && !self.isPreviewMode;
            self.isPaymentDisputed = !!self.payment.payment_dispute_status && !self.isPreviewMode;
            self.paymentDisputeStatus = self.payment.payment_dispute_status;

            if (self.isPaid) {
                if (self.isReservedForDisputeLost) {
                    self.paymentStatusLabel = 'PAYMENTS.LABELS._DISPUTE_RESERVED_LOST_';
                } else if (self.isPaymentDisputed) {
                    if (self.paymentDisputeStatus === 'won' || self.paymentDisputeStatus === 'warning_closed') {
                        self.paymentStatusLabel = 'PAYMENTS.LABELS._DISPUTED_WON_';
                    } else if (self.paymentDisputeStatus === 'lost') {
                        self.paymentStatusLabel = 'PAYMENTS.LABELS._DISPUTED_LOST_';
                    } else if (self.paymentDisputeStatus === 'partially_won') {
                        self.paymentStatusLabel = 'PAYMENTS.LABELS._DISPUTED_PARTIALLY_WON_';
                    } else {
                        self.paymentStatusLabel = 'PAYMENTS.LABELS._DISPUTED_IN_PROCESS_';
                    }
                } else if (self.isReservedForDispute) {
                    self.paymentStatusLabel = 'PAYMENTS.LABELS._HELD_FOR_DISPUTE_';
                } else {
                    self.paymentStatusLabel = 'PAYMENTS.LABELS._PAID_';
                }
            }
            self.isOverdue = self.payment.isStatusOverdue();
            if (self.isOverdue) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._OVERDUE_';
            }
            self.isUpcoming = self.payment.isStatusUpcoming();
            if (self.isUpcoming) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._UPCOMING_';
            }
            self.isZeroAmount = self.payment.isZeroAmount();
            if (self.isZeroAmount && !self.isPaid && !self.isOverdue) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._UNPAID_';
            }
            self.isUnpaid = self.payment.isStatusUnpaid();
            if (self.isUnpaid) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._UPCOMING_';
            }
            self.isDueToday = self.payment.isStatusDueToday();
            if (self.isDueToday && !self.isPaid) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._DUE_TODAY_';
            }
            self.isPending = self.payment.isStatusPending();
            if (self.isPending) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._PROCESSING_';
            }
            self.isPendingRefund = self.payment.isPendingRefund();
            if (self.isPendingRefund) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._REFUND_IN_PROCESS_';
            }
            self.isRefunded = self.payment.isRefunded();
            self.isFullyRefunded = self.payment.isFullyRefunded();
            if (self.isFullyRefunded) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._REFUNDED_';
            }
            self.isPartiallyRefunded = self.payment.isPartiallyRefunded();
            if (self.isPartiallyRefunded) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._PARTIALLY_REFUNDED_';
            }

            // new for recurring - status cancelled
            self.isPaymentCanceled = !self.isPaid && !self.isPending && this.workspaceFile && this.workspaceFile.isRecurringPaymentCanceled();
            if (self.isPaymentCanceled) {
                self.paymentStatusLabel = 'PAYMENTS.LABELS._CANCELED_';
            }
        },

        showPaymentEstimation: function showPaymentEstimation() {
            var permittedInWorkspaceView = this.currentUser.hasCompanyAdminPermissions() || (this.workspaceFile && this.workspaceFile.owner._id === this.currentUser._id);
            return !this.isRefunded && !this.isPendingRefund && !this.payment.isPaidAndProcessedForDispute() && !this.payment.isPaidAndDisputeReserved() &&
                    (this.isOwnerMode || (this.isInWorkspaceView && permittedInWorkspaceView)) &&
                    (this.isPaid || this.isPending) &&
                    (!this.payment.transferred_to_vendor || (this.payment.transferred_to_vendor && this.moment().diff(this.moment(this.payment.charge_date), 'days') < 14)) &&
                    this.payment.estimated_payout_date;
        },

        showPaymentProcessedForDispute: function showPaymentProcessedForDispute() {
            return this.payment.isPaidAndProcessedForDispute() && !this.isPreviewMode;
        },

        onClickMarkAsPaid: function onClickMarkAsPaid() {
            this.hideOptionsFlyout();

            var promise = this.$q.when();

            if (this.payment.allowMarkAsPaidBlockedBecauseOfDraft()) {
                promise = this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    'PAYMENTS.MARK_AS_PAID._CONFIRM_DRAFT_AND_SEND_',
                    'FREQUENT_BUTTONS._CONTINUE_'
                ).then(function continued() {
                    var workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id, true);
                    return this.WorkspaceFileService.sendFile(this.currentUser, workspace, this.workspaceFile, null, null, true);
                }.bind(this));
            }

            promise.then(function resolved() {
                this.ModalService.openMarkAsPaidModal(this.payment);
            }.bind(this));
        },

        validatePercentage: function validatePercentage(value) {
            var percentRegEx = /^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/;
            return percentRegEx.test(value);
        },

        setPaymentAmountType : function setPaymentAmountType(amountType) {
            this.paymentAmountTypeTab = amountType;

            switch (amountType) {
                case this.Enums.PaymentAmountTypes.customAmount:
                    this.activeAmountValue = this.payment.getDefaultFixedAmountValue();
                    this.updatePayment(this.Enums.PaymentAmountTypes.customAmount);
                    break;

                case this.Enums.PaymentAmountTypes.percentage:
                    this.makeCopyActiveAmount();
                    this.validatePercentage(this.activeAmountValue);
                    this.activeAmountValue = this.payment.getDefaultPercentageValue();
                    if(this.validatePercentage(this.activeAmountValue)){
                        this.updatePayment(this.Enums.PaymentAmountTypes.percentage);
                    }
                    break;

                case this.Enums.PaymentAmountTypes.divideEqually:
                    this.activeAmountValue = this.payment.getActiveAmountValue();
                    this.updatePayment(this.Enums.PaymentAmountTypes.divideEqually);
                    break;
            }
        },

        paymentTabActive : function paymentTabActive(amountType) {
            return this.paymentAmountTypeTab === amountType;
        },


        updatePayment : function updatePayment(paymentType) {

            this.payment.updatePaymentAmount(paymentType, this.activeAmountValue)
                .then(function (message, status) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_amount_updated, {
                            file_id: this.fileId,
                            payment_id: this.payment._id
                        });
                    }.bind(this),

                    function (message, status) {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.payment_update_error, {
                            file_id: this.fileId,
                            payment_id: this.payment._id,
                            error_message: message
                        });
                    }.bind(this));
        },

        makeCopyActiveAmount: function makeCopyActiveAmount() {
            this.activeAmountValueCopy = angular.copy(this.payment.getDefaultPercentageValue());
        },

        closePaymentAmountFlyout: function closePaymentAmountFlyout() {
            this.showPaymentAmountFlyout = false;

            if ( this.paymentTabActive(this.Enums.PaymentAmountTypes.percentage) && !this.validatePercentage(this.activeAmountValue)) {
                this.activeAmountValue = this.activeAmountValueCopy;
            }
        },

        shouldShowOptions: function shouldShowOptions() {
            return  this.allowShowReceipt() ||
                    this.allowSendReminder() ||
                    this.isPaid ||
                    (this.allowClickToMarkAsPaid() && this.isOwnerMode) ||
                    (this.DeviceService.checkIfMobileSize() && this.allowDelete()) ||
                    this.allowEditRecurringPaymentMethod();
        },

        allowEditRecurringPaymentMethod: function allowEditRecurringPaymentMethod() {
            return !this.isOwnerMode && this.payment.is_recurring_payment && !this.isPaid && !this.isPending && this.paymentsContainer.isRecurringPaymentActive();
        },

        hideOptionsFlyout: function hideOptionsFlyout() {
            this.showPaymentOptionsFlyout = false;
        },

        onRecurringEndTypeChange: function onRecurringEndTypeChange(item) {
            this.selectedRecurringPaymentEndDate = item;
            if (item.type === 'never') {
                this.recurringPaymentEndDate = null;
            } else {
                this.recurringPaymentEndDate = this.moment(this.recurringPaymentStartDate).add(1, 'years').format("MMM DD, YYYY").toString();
            }

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.recurring_payment_end_date_type_changed, {type: item.type});
            this.recurringPaymentChanged();
        },

        recurringPaymentChanged: function recurringPaymentChanged() {
            this.paymentsContainer.updateRecurringPayment(this.recurringPaymentStartDate, this.recurringPaymentEndDate);
        },

        isDueDateOnEndOfMonth: function isDueDateOnEndOfMonth() {
            if (!this.paymentsContainer.isRecurringPayment()) {
                return false;
            }

            var startDate = this.moment(this.recurringPaymentStartDate);
            var daysInMonth = startDate.clone().endOf('month').date();

            return daysInMonth === startDate.date();
        },

        allowAmountEdit: function allowAmountEdit() {
            return this.allowFlyouts() && !this.paymentsContainer.isRecurringPayment();
        },

        onWhenClicked: function onWhenClicked() {
            if (this.allowFlyouts()) {
                this.showPaymentWhenFlyout = !this.showPaymentWhenFlyout;
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.open_payment_flyout, {type: 'date'});
            }
        },

        onAmountClicked: function onAmountClicked() {
            this.makeCopyActiveAmount();
            if (this.allowAmountEdit()) {
                this.showPaymentAmountFlyout = !this.showPaymentAmountFlyout;
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.open_payment_flyout, {type: 'amount'});
            }
        },

        onClickRequestPayment: function onClickRequestPayment() {
            var workspace = this.WorkspacesManager.getWorkspace(this.workspaceFile.couple_card_id);
            this.WorkspaceFileService.requestPayment(this.currentUser, workspace, this.workspaceFile, this.payment)
                .then(function onSuccess() {
                }.bind(this))
        }
    });

    return {
        scope: {

            payment: '=payment',
            isInWorkspaceView: '=isInWorkspaceView',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isViewOnlyMode: '=isViewOnlyMode',
            isEditableMode: '=isEditableMode',
            isWaitingForAccept: '=isWaitingForAccept',
            templateMode: '=?',
            isActiveAutoPayment: '&?',
            showInvoiceNumber: '=?',
            companyModel: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/payments/payment_directive_template.html',
        controller: PaymentDirectiveController,
        controllerAs: 'paymentVm',
        bindToController: true
    };
};
