(function () {
    'use strict';

    Directives.CreateFileWizardDirective =  function CreateFileWizardDirective(){

        // @ngInject
        function CreateFileWizardDirectiveControllerCtor($scope, $injector, $state,
                                                     UsersManager, WorkspaceFilesManager, CompaniesManager,
                                                     OnboardingService, WorkspaceFileService, PopupMessageService, 
                                                     AppStates, _, AnalyticsService, Enums, TemplatesViewService, FeaturesService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CreateFileWizardDirectiveController';

            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.UsersManager = UsersManager;
            this.OnboardingService = OnboardingService;
            this.WorkspaceFileService = WorkspaceFileService;
            this.PopupMessageService = PopupMessageService;
            this.AppStates = AppStates;
            this._ = _;
            this.$state = $state;
            this.Enums = Enums;
            this.TemplatesViewService = TemplatesViewService;
            this.FeaturesService = FeaturesService;
            this.AnalyticsService = AnalyticsService;

            this.user = this.UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();
            this.initialLoading = false;

            this.initialize();

        }

        var CreateFileWizardDirectiveController = Class(Controllers.BaseController, {
            constructor: CreateFileWizardDirectiveControllerCtor,

            initialize: function initialize() {
                this.initRecentFiles();
                this.initTemplates();
            },

            isLoading: function isLoading() {
                return this.initialLoading;
            },

            initRecentFiles: function initRecentFiles() {

                this.initialLoading = true;

                if (this.fileType === 'flow') {
                    this.initialLoading = false;
                    this.recentFiles = [];
                    return;
                }

                this.UsersManager.getRecentSentFiles(this.user._id, this.fileType)
                    .then(function success(resp) {
                        this.recentFiles = resp.data;
                    }.bind(this))
                    .finally(function final() {
                        this.initialLoading = false;
                    }.bind(this));
            },

            initTemplates: function initTemplates() {

                var fileTypesMapping = {
                    invoice: {
                        templatesFunc: this.company.getCompanyInvoices.bind(this.company)
                    },

                    proposal: {
                        templatesFunc: this.company.getCompanyProposals.bind(this.company)
                    },

                    brochure: {
                        templatesFunc: this.company.getCompanyBrochures.bind(this.company)
                    },

                    questionnaire: {
                        templatesFunc: this.company.getCompanyQuestionnaires.bind(this.company)
                    },

                    agreement: {
                        templatesFunc: this.company.getCompanyAgreements.bind(this.company)
                    },

                    flow: {
                        templatesFunc: this.company.getFlows.bind(this.company)
                    }
                };

                var templatesFunc = fileTypesMapping[this.fileType].templatesFunc;
                this.templatesList = this._.sortBy(templatesFunc(), function(template) {return (template.title || "").toLowerCase();});
            },

            createFile: function(fileOriginType, modelId, title) {
                var params = { type: this.fileType }

                if (fileOriginType === 'file') {
                    Object.assign(params, { parent: 'file' })
                } else if (fileOriginType === 'template') {
                    Object.assign(params, { parent: 'template', template_name: title })
                } else {
                    Object.assign(params, { parent: 'new' })
                }

                this.AnalyticsService.trackClick(
                    this,
                    this.AnalyticsService.analytics_events.file_create, 
                    params);
                
                this.onComplete({fileOriginType: fileOriginType, modelId: modelId});
            },

            goToTemplates: function goToTemplates() {
                this.onCancel({});
                this.$state.go(this.AppStates.root_core_navigation_templates);
            },

        });

        return {
            scope: {
                fileType: '=',
                workspace: '=',
                onComplete: '&',
                onCancel: '&'
            },
            templateUrl : 'angular/app/modules/core/features/workspace_file/create_file_wizard/create_file_wizard_directive_template.html',
            controller : CreateFileWizardDirectiveController,
            controllerAs : 'createFileWizardVm',
            bindToController : true
        };
    };
}());