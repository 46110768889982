/**
 *
 * This directive opens an adjacent hb-menu when clicking tab
 * on the field previous to it
 *
 */


(function () {
    'use strict';

    const TAB_KEY_CODE = 9;

    // @ngInject
    Directives.TabToFocusOnMenu = function TabToFocusOnMenu() {

        function TabToFocusOnMenuLink($scope, element, attrs) {
            element.on('keydown', function (e) {
                if (e.keyCode !== TAB_KEY_CODE) {
                    return;
                }

                e.preventDefault();
                e.stopImmediatePropagation();

                $scope.$broadcast(
                    "OPEN_HB_MENU_EXTERNALLY",
                    attrs.hbTabToFocusOnMenu
                );
                element.blur();
            });
        }

        return {
            restrict: 'A',
            link: TabToFocusOnMenuLink
        };
    };


}());
