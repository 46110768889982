
// @ngInject

Models.MemberReferralsCollection = function MemberReferralsCollection(BaseCollection) {

    var MemberReferralsCollection = Class(BaseCollection, {

            constructor: function constructor(){
                MemberReferralsCollection.$super.call(this);
                this._collection.__className = 'MemberReferralsCollection';
                this._collection.__childsType = 'MemberReferralModel';
                return this._collection;
            }
        }
    );

    return MemberReferralsCollection;
};