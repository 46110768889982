(function () {
    "use strict";

    // @ngInject
    function FlowsManagerCtor(APIService, Routes, $q, AnalyticsService, _, ModelFactory, PubSubService, PendingTasksManager) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.AnalyticsService = AnalyticsService;
        this._ = _;
        this.PendingTasksManager = PendingTasksManager;

        PubSubService.ventMyBitchUp(this);
    }

    Services.FlowsManager = Class(Services.BaseModelManager, {
        constructor: FlowsManagerCtor,

        updateFlow(data, flowId) {
            const url = this.Routes.v2_update_flow_path(flowId);
            return this.apiUpdate(url, data).then((updatedFlowData) => updatedFlowData.data);
        },

        removeScheduleSend(flows, flowId) {
            const url = this.Routes.v2_remove_schedule_send_path(flowId);
            return this.apiUpdate(url);
        },

        afterDeleteFlow(flows, flowId) {
            const flowIndex = this._.findIndex(flows, (flow) => flow._id === flowId);
            if (flowIndex > -1) {
                flows.splice(flowIndex, 1);
            }
            return flows;
        },

        deleteFlow(flows, flowId) {
            const url = this.Routes.v2_delete_flow_path(flowId);
            return this.apiDelete(url).then(() => {
                flows = this.afterDeleteFlow(flows, flowId);
                return flows;
            });
        },

        markFlowAsCompleted(flow) {
            const url = this.Routes.v2_mark_flow_as_complete_path(flow._id);
            return this.apiUpdate(url).then(() =>{
                flow.status = 'completed';
                return flow;
            });
        },

        sendPaymentReminder(flowId, paymentId) {
            const url = this.Routes.v2_flow_send_payment_reminder_path(flowId, paymentId);
            return this.apiCreate(url);
        },

        getPayment(flowId, invoiceId) {
            const url = this.Routes.v2_flow_get_payment_path(flowId, invoiceId);
            return this.apiFetch(url, null, null, false);
        },

        allowFlowChargedInstantPayout(flowId, paymentId) {
            const url = this.Routes.v2_flow_allow_charged_instant_payout_path(flowId, paymentId);
            return this.apiFetch(url, null, null, false);
        },

        markPaymentAsInstantPayout(flowId, paymentId, state, source) {
            const url = this.Routes.v2_flow_toggle_payment_as_instant_path(flowId, paymentId);
            return this.apiUpdate(url, {use_instant_payout: state, use_instant_payout_set_source: source});
        },

        syncToQuickbooks(flowId, forceInvoiceCreation) {
            var data = {force_invoice_creation: forceInvoiceCreation};
            const url = this.Routes.v2_flow_manual_quickbooks_sync_path(flowId);
            return this.apiCreate(url, data);
        },

        deleteQuickbooksPayment: function deleteQuickbooksPayment(flowId, paymentId) {
            const url = this.Routes.v2_flow_delete_payment_from_quickbooks_path(flowId, paymentId);
            return this.apiDelete(url);
        },

        convertFileTemplateToFlow: function convertFileTemplateToFlow(templateId, templateType, title, targetType) {
            const url = this.Routes.v2_migrate_file_path();
            var data = {
                file_id: templateId,
                file_type: templateType,
                title: title,
                flow_type: targetType
            };
            return this.apiCreate(url, data);
        },

        otamMigrationForUser: function otamMigrationForUser(taskType, taskData, openDelayFlow) {
            var url = this.Routes.v2_otam_migration_path();
            var createPromise = this.apiCreate(url, {task_type: taskType, task_data: taskData});

            var taskDeferred = this.$q.defer();
            createPromise.then(
                function success(response) {
                    var taskNode = {
                        taskId: response.data.task_id,
                        taskType: taskType,
                        taskData: taskData,
                        taskDeferred: taskDeferred,
                        taskPromise: taskDeferred.promise,
                        timeoutPromise: null,
                        canceled: false,
                        pollCount: 0
                    };
                    this.PendingTasksManager.registerPendingTask(taskNode);
                }.bind(this),
                function fail(error) {
                    taskDeferred.reject(error);
                }.bind(this));
            return {deferred: taskDeferred.promise, create: createPromise};
        }
    });
}());
