// @ngInject

Models.PaymentModel = function PaymentModel(BaseModel, UsersManager, moment, _) {


    return Class(BaseModel, {

        dateCalcTypes: {
            invoiceDate: {name: 'invoiceDate', calc_type: 'date_at_invoice', value: '1'},
            eventDate: {name: 'eventDate', calc_type: 'date_at_event_date', value: '1'},
            fixedDate: {name: 'fixedDate', calc_type: 'absolute'},
            midwayDate: {name: 'midwayDate', calc_type: 'relative', related_value_type: 'event_date_and_today', value: '0.5'},
            customDate: {name: 'customDate', calc_type: 'relative'},
            milestone: {name: 'milestone', calc_type: 'milestone'}
        },

        dateUnitTypes: {
            day: {name: 'day', date_unit_type: 'day'},
            week: {name: 'week', date_unit_type: 'week'},
            month: {name: 'month', date_unit_type: 'month'}
        },

        dateOperatorTypes: {
            beforeEvent: {name: 'beforeEvent', related_value_type: 'event_date', calc_operator_type: 'before'},
            afterEvent: {name: 'afterEvent', related_value_type: 'event_date', calc_operator_type: 'after'},
            afterInvoice: {name: 'afterInvoice', related_value_type: 'invoice', calc_operator_type: 'after'}
        },

        amountCalcTypes: {
            fixedAmount: {name: 'fixedAmount', calc_type: 'absolute', related_value_type: 'none'},
            percentageAmount: {name: 'percentageAmount', calc_type: 'relative', related_value_type: 'percentage_of_total'},
            divideEquallyAmount: {name: 'divideEquallyAmount', calc_type: 'relative', related_value_type: 'equally', value: 10}

        },

        markAsPaidTypes: {
            cash: {name:'cash'},
            check: {name: 'check'},
            other: {name: 'other'}
        },


        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'PaymentModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy){
            copy.__payments_container = undefined;
        },

        _getWorkspaceFile: function _getWorkspaceFile() {
            return this.__payments_container && this.__payments_container.__workspace_file;
        },

        _getPaymentsContainer: function _getPaymentsContainer() {
            return this.__payments_container;
        },

        getNetAmount: function getNetAmount(){
            //vendor side - show net amount, client side - show amount
            return this.hb_vendor_transfer_amount || this.amount;
        },

        getPaymentMethod: function getPaymentMethod() {
            var paymentMethodNumber = (this.payment_method && this.payment_method.last4) ? ("(****** " + this.payment_method.last4 + ")") : '';
            var paymentMethodName = ((this.payment_method && UsersManager.isCurrentUser(this.payment_method.user_id)) ? this.payment_method.name : this.charge_description) || '';
            return paymentMethodName + " " + paymentMethodNumber;
        },

        getPaidBy: function getPaidBy() {
          return this.payment_method && this.payment_method.paid_by;
        },

        getFile: function getFile(){
            var file = this._getWorkspaceFile();
            return file;
        },

        getFileId: function getFileId(){
            var file = this._getWorkspaceFile();
            return file && file._id;
        },

        isMarkedAsPaid: function isMarkedAsPaid() {
            return this.is_paid && this.is_manual;
        },

        isStatusPaid: function isStatusPaid(){
            return this.is_paid;
        },

        isPaidAndDisputeReserved: function isPaidAndDisputeReserved(){
            return this.isModeratorMode() && this.is_paid && this.payment_dispute_cover && this.payment_dispute_cover.dispute_amount_reversed && this.payment_dispute_cover.dispute_amount_reversed > 0;
        },

        isPaidAndProcessedForDispute: function isPaidAndProcessedForDispute(){
            return this.isModeratorMode() && this.is_paid && this.payment_dispute_cover && this.payment_dispute_cover.dispute_amount_reversed_lost && this.payment_dispute_cover.dispute_amount_reversed_lost > 0;
        },

        isStatusPending: function isStatusPending(){
            return this.is_pending;
        },

        isRefunded: function isRefunded() {
            return !!this.refund_data && this.refund_data.is_refunded === true;
        },

        isFullyRefunded: function isFullyRefunded() {
            return this.refund_data && this.refund_data.is_refunded && Number((this.amount + this.tip_paid).toFixed(2)) === this.refund_data.refunded_amount && !this.isClientRefundPendingAchCharge();
        },

        isPartiallyRefunded: function isPartiallyRefunded() {
            return this.refund_data && this.refund_data.is_refunded && Number((this.amount + this.tip_paid)).toFixed(2) > this.refund_data.refunded_amount && !this.isClientRefundPendingAchCharge();
        },

        refundedAmount: function refundedAmount() {
            return this.refund_data && this.refund_data.refunded_amount;
        },

        isPendingRefund: function isPendingRefund() {
            return this.refund_data && (this.refund_data.is_pending || this.isClientRefundPendingAchCharge());
        },

        isClientRefundPendingAchCharge: function isClientRefundPendingAchCharge() {
            return this.refund_data && this.refund_data.is_client_pending_refund;
        },

        isRefundedorPendingRefund: function isRefundedorPendingRefund() {
            return (this.isRefunded() || this.isPendingRefund());
        },

        isStatusUpcoming: function isStatusUpcoming() {

            if (this.isStatusPaid() || !this.isFileBooked()){
                return false;
            }

            var diffInDays = moment().add(7, 'days').diff(this.due_date, 'days');

            return (diffInDays > 0 && diffInDays < 8);
        },

        isStatusOverdue: function isStatusOverdue(){

            // Removed check for isFileBooked as invoice - HON-7017
            if (this.isStatusPaid()){
                return false;
            }

            return (moment().diff(this.due_date, 'days') > 0);
        },

        isZeroAmount: function isZeroAmount() {
            return this.amount === 0;
        },

        isStatusUnpaid: function isStatusUnpaid(){
            return (!this.isStatusPaid() && !this.isStatusPending() && !this.isStatusOverdue() && !this.isStatusUpcoming() && !this.isZeroAmount());
        },

        isStatusDueToday: function isStatusDueToday(){
          return moment().isSame(this.due_date, 'day');
        },

        isFileBooked: function isFileBooked(){
            var file = this._getWorkspaceFile();
            return file && file.isBooked();
        },

        isFileCanceled: function isFileCanceled(){
            var file = this._getWorkspaceFile();
            return file && file.isCanceled();
        },

        isPaymentsSeen: function isPaymentsSeen() {
            var file = this._getWorkspaceFile();
            return file && file.isPaymentsSeen();
        },

        isOwnerOfFile: function isOwnerOfFile(){
            var file = this._getWorkspaceFile();
            return file && file.isOwnedByCurrUser();
        },

        isOwnerMode: function isOwnerMode(){
            var file = this._getWorkspaceFile();
            return file && file.isOwnerMode();
        },

        isModeratorMode: function isModeratorMode(){
            var file = this._getWorkspaceFile();
            return file && file.isModeratorMode();
        },

        isDraftEditable: function isDraftEditable() {
            var file = this._getWorkspaceFile();
            return file && file.isDraftEditable();
        },
        
        isTransferredToVendor: function isTransferredToVendor() {
            return !!this.transferred_to_vendor;
        },

        needRefundPaymentMethod: function needRefundPaymentMethod() {
            return !!this.transferred_to_vendor;
        },

        allowMenu: function allowMenu() {
            // when canceled or client - no need for menu
            // when owner - only when not booked, or first unpaid (to allow MAP and Reminders)
            // also if the client or the owner of the file is looking at an old version of the file no need for the menu

            var workspaceFile = this._getWorkspaceFile();
            var pendingPaymentFlag = this.isOwnerMode() && ((!this.allowEdit() && !workspaceFile.hasPendingPayment()) || this.allowEdit());
            return !this.is_paid &&
                    !this.is_pending &&
                    !this.isRefunded() &&
                    !this.isFileCanceled() &&
                    workspaceFile.isLatestVersion() &&
                    pendingPaymentFlag &&
                    (this.isOwnerMode() && (this.first_unpaid || this.allowEdit()));
        },

        allowEdit: function allowEdit() {
            var file = this._getWorkspaceFile();
            return ( !this.is_paid &&
                !this.is_pending &&
                file &&
                file.isEditable() &&
                this.isOwnerMode());
        },

        allowDelete: function allowDelete() {
            var file = this._getWorkspaceFile();
            return ( !this.is_paid &&
                !this.is_pending &&
                file &&
                file.isEditable() &&
                this.isOwnerMode());
        },

        paymentCanBeMarkedAsPaid: function paymentCanBeMarkedAsPaid() {
            var file = this._getWorkspaceFile();
            return this.allow_mark_as_paid && file.isLatestVersion();
        },

        isMilestonePayment: function isMilestonePayment() {
            return this.getActiveDateCalcType() === this.dateCalcTypes.milestone.calc_type;
        },

        isMilestoneCompleted: function isMilestoneCompleted() {
            return this.isMilestonePayment() && this.milestone_completed_on;
        },

        isMilestoneReminderSent: function isMilestoneReminderSent() {
            return this.isMilestonePayment() && this.reminders && this.reminders.length;
        },

        allowMarkAsPaidBlockedBecauseOfDraft: function allowMarkAsPaidBlockedBecauseOfDraft() {
            var file = this._getWorkspaceFile();
            return this.paymentCanBeMarkedAsPaid() && !(file.isFirstDraft() || file.isActiveVersion()) && this.__parent.is_dirty;
        },

        allowSendReminder: function allowSendReminder() {
            var file = this._getWorkspaceFile();
            return this.allow_send_reminder && file.isLatestVersion();
        },

        allowPay: function allowPay() {
            var fileActive, workspaceActive, isBookedVersion, isAgreementSeen;
            var workspaceFile = this._getWorkspaceFile();
            if (workspaceFile) {
                fileActive = workspaceFile.active_state;
                workspaceActive = workspaceFile.workspace_active_state;
            }

            // allow pay a milestone only if it's completed (payment requested) and not it's paid yet.
            var allowPayMilestone = !!this.isMilestoneCompleted() && !this.isStatusPaid()

            return (!this.isOwnerMode() && !this.isFileCanceled() &&
            fileActive &&
            workspaceActive &&
            workspaceFile.isLatestVersion() &&
            !workspaceFile.hasPendingPayment() &&
            ((workspaceFile.isProposal() && workspaceFile.isAgreementSigned()) || workspaceFile.isInvoice()) &&
            this.amount > 0 &&
                (this.first_unpaid || (this.isMilestonePayment() && allowPayMilestone))
            );
        },

        allowShowReceipt: function allowShowReceipt() {
            return this.is_paid || this.is_pending;
        },

        isRefundPeriodInvalid: function isRefundPeriodInvalid() {
            // ACH payments can be refunded only 90 days after the charge
            if (this.payment_method && this.payment_method._type !== 'PaymentMethodChargeableBankAccount') {
                return false;
            }

            var oneDay = 24*60*60*1000;
            var chargeDate = new Date(this.charge_date);
            var today = new Date();
            var diffDays = Math.round(Math.abs((chargeDate.getTime() - today.getTime())/(oneDay)));
            return (diffDays > 89) ? true : false;
        },

        allowRefund: function allowRefund() {
            // TODO Udi: check file active version and last paid payment
            var file = this._getWorkspaceFile();
            return this.allow_refund && file.isLatestVersion();
        },

        isReminderAlreadySent: function isReminderAlreadySent(){
            return this.last_reminder_timestamp;
        },

        isReminderAlreadySentRecently: function isReminderAlreadySentRecently(){
            return (this.last_reminder_timestamp &&
                moment().diff(moment(this.last_reminder_timestamp), 'hours') < 24);
        },

        isLastReminderSeen: function isLastReminderSeen(){
            if (!!!this.last_reminder_mail_open_timestamp){
                return false;
            }
            if (this.last_reminder_timestamp && (this.last_reminder_timestamp > this.last_reminder_mail_open_timestamp)){
                // seen, but an older one
                return false;
            }
            return this.last_reminder_mail_open_timestamp; // return the timestamp for true
        },

        isFirstPayment: function isFirstPayment(){
            return (this.position === 1);
        },

        isLastPayment: function isLastPayment() {
            return (this.position === this.__payments_container.payments.length);
        },

        isRecurring: function isRecurring() {
            return this.is_recurring_payment;
        },

        /*****************************************
         * DATE CALCULATIONS
         ******************************************/
         getActiveDateCalcType: function getActiveDateCalcType(){

            //both MIDWAY and CUSTOM have 'relative' clac_type
            //we determine which is the right one by the related_value_type
            if(this.date_calc_info.calc_type === this.dateCalcTypes.midwayDate.calc_type){

                if( this.date_calc_info.related_value_type === this.dateCalcTypes.midwayDate.related_value_type){
                    return this.dateCalcTypes.midwayDate.name;
                }else{
                    return this.dateCalcTypes.customDate.name;
                }
            }

            var self = this;
            var itemKey = _.findKey(this.dateCalcTypes, function(item){
                return (item.calc_type === self.date_calc_info.calc_type);
            });

            return this.dateCalcTypes[itemKey].name;
        },

        getActiveDateUnitType: function getActiveDateUnitType(){

            var self = this;
            var itemKey = _.findKey(this.dateUnitTypes, function(item){
                return (item.date_unit_type === self.date_calc_info.date_unit_type);
            });

            if(this.dateUnitTypes[itemKey]){
                return this.dateUnitTypes[itemKey].name;
            }else{
                return this.dateUnitTypes.month.name;
            }
        },

        getActiveDateOperatorType: function getActiveDateOperatorType(){

            var self = this;
            var itemKey = _.findKey(this.dateOperatorTypes, function(item){
                return (item.related_value_type === self.date_calc_info.related_value_type &&
                    item.calc_operator_type === self.date_calc_info.calc_operator_type );
            });

            if(this.dateOperatorTypes[itemKey]){
                return this.dateOperatorTypes[itemKey].name;
            }else{
                return this.dateOperatorTypes.beforeEvent.name;
            }
        },

        getActiveDateValue: function getActiveDateValue(){
            return this.date_calc_info.value;
        },

        isDateTbd: function isDateTbd(){
            return this.date_calc_info.is_tbd;
        },

        getDateForSorting: function getDateForSorting(){
            return this.charge_date || this.due_date;
        },

        /*****************************************
         * AMOUNT CALCULATIONS
         ******************************************/
        getActiveAmountCalcType: function getActiveAmountCalcType(){

            //both DIVIDE EQUALLY and PERCENTAGE have 'relative' calc_type
            //we determine which is the right one by the related_value_type
            if(this.amount_calc_info.calc_type === this.amountCalcTypes.percentageAmount.calc_type){

                if( this.amount_calc_info.related_value_type === this.amountCalcTypes.percentageAmount.related_value_type){
                    this.activeAmountCalcType = this.amountCalcTypes.percentageAmount.name;
                }else{
                    this.activeAmountCalcType = this.amountCalcTypes.divideEquallyAmount.name;
                }
            }else {
                this.activeAmountCalcType = this.amountCalcTypes.fixedAmount.name;
            }

            return this.activeAmountCalcType;

        },

        getActiveAmountValue: function getActiveAmountValue(){
            return this.amount_calc_info.value;
        },

        getDefaultPercentageValue: function getDefaultPercentageValue(){

            var proposalValue = this.__parent.getTotalProposalValue();
            var activeCalcType = this.activeAmountCalcType || this.getActiveAmountCalcType();
            var value;
            switch (activeCalcType){
                case this.amountCalcTypes.fixedAmount.name:
                case this.amountCalcTypes.divideEquallyAmount.name:
                    value = Math.round(((this.amount / proposalValue) * 100));
                    break;
                case this.amountCalcTypes.percentageAmount.name:
                    value = this.amount_calc_info.value;
                    break;

            }

            return value;
        },

        getDefaultFixedAmountValue: function getDefaultFixedAmountValue(){
            var proposalValue = this.__parent.getTotalProposalValue();
            var activeCalcType = this.activeAmountCalcType || this.getActiveAmountCalcType();
            var value;
            switch (activeCalcType){
                case this.amountCalcTypes.fixedAmount.name:
                    value = this.amount_calc_info.value;
                    break;
                case this.amountCalcTypes.divideEquallyAmount.name:
                    value = this.amount;
                    break;
                case this.amountCalcTypes.percentageAmount.name:
                    value = ((parseFloat(this.amount_calc_info.value) * proposalValue) / 100).toFixed(2);
                    break;

            }

            return value;
        },


        /*****************************************
         * PAYMENTS ACTIONS
         ******************************************/
        deletePayment: function deletePayment(){
            return this.__parent.deletePayment(this);
        },

        sendPaymentReminder: function sendPaymentReminder() {
            return this.__parent.sendPaymentReminder(this);
        },

        updatePaymentSchedule: function updatePaymentSchedule(dateType, dateUnit, dateOperator, dateValue){

            var calcType = this.dateCalcTypes[dateType].calc_type;
            var unit = this.dateUnitTypes[dateUnit] && this.dateUnitTypes[dateUnit].date_unit_type;
            var operatorType = this.dateOperatorTypes[dateOperator];
            var operator = operatorType && operatorType.calc_operator_type;
            var value = this.dateCalcTypes[dateType].value || dateValue;
            var relatedValue = this.dateCalcTypes[dateType].related_value_type || (operatorType && operatorType.related_value_type);

            this.date_calc_info.calc_type = calcType;
            this.date_calc_info.date_unit_type = unit;
            this.date_calc_info.calc_operator_type = operator;
            this.date_calc_info.value = value;
            this.date_calc_info.related_value_type = relatedValue;
            return this.__parent.updatePayment(this);
        },

        updatePaymentAmount: function updatePaymentAmount(amountType, amountValue){

            var calcType = this.amountCalcTypes[amountType].calc_type;
            var value = this.amountCalcTypes[amountType].value || amountValue;
            var relatedValue = this.amountCalcTypes[amountType].related_value_type;

            this.amount_calc_info.calc_type = calcType;
            this.amount_calc_info.value = value;
            this.amount_calc_info.related_value_type = relatedValue;
            return this.__parent.updatePayment(this);
        },

        setMarkAsPaid: function setMarkAsPaid(markAsPaidType, markAsPaidDate, markAsPaidReason){

            this.charge_description = markAsPaidReason || markAsPaidType;
            this.charge_date = markAsPaidDate;
            this.is_paid = true;
            return this.__parent.updatePayment(this);
        },

        printReceipt: function printReceipt(){
            this.__parent.printPaymentReceipt(this);
        },

        refund: function refund() {
            var file = this._getWorkspaceFile();
            if (file) {
                return file.refundPayment(this);
            } else {
                throw Error("Can't refund payment without a file");
            }
        }
    });

};

