(function () {
    "use strict";

    // @ngInject
    function ImageComponentControllerFunc($injector, $scope, AnalyticsService, ImagesManager, ImageService, ModalService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ImageComponentController';
        this.AnalyticsService = AnalyticsService;
        this.ImagesManager = ImagesManager;
        this.ImageService = ImageService;
        this.ModalService = ModalService;

        this.maxWidth = 768;
        this.imageRatio = -1;
        if (!this.component.isRootComponent()) {
            this.imageRatio = 1.6;
        }
    }

    var ImageComponentController = Class(Controllers.BaseController, {
        constructor: ImageComponentControllerFunc,

        onChooseImage: function onChooseImage() {
            if (this.component.image) {
                this.editImage();
            } else {
                this.addImage();
            }
        },

        addImage: function addImage() {
            this.changeImage('MEDIA_MODAL.TITLE._ADD_IMAGE_', '', 'Upload');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.add_block_image);
        },

        editImage: function editImage() {
            this.changeImage('MEDIA_MODAL.TITLE._EDIT_IMAGE_', this.component.image, 'Crop');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edit_block_image);
        },

        changeImage: function changeImage(modalTitle, modalImage, defaultTab) {
            return this.ModalService.openAttachAssetModal(true, true, true,
                defaultTab, false, false, modalImage, this.imageRatio,
                false, false, 'image/*', '', '', modalTitle)
                .then(function success(image){
                    this.saveImage(angular.copy(image));
                }.bind(this))
                .finally(function () {
                }.bind(this));
        },

        saveImage: function saveImage(imageModel) {
            this.component.image = imageModel;
            this.component.saveImage();
        },


        shouldShowChangeButton: function shouldShowChangeButton() {
            var showButton = this.component.image ? !!this.editButton : !!this.addButton;
            return showButton;
        },

    });

    Components.ImageComponent = {
        bindings: {
            component: '=',
            isRootComponent: '<',
            isEditable: '<',
            addButton: '<',
            editButton: '<',
        },
        controller: ImageComponentController,
        controllerAs: 'imageComponentVm',
        name: 'hbImageComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/image/image_component.html',
    };

}());