(function () {
    "use strict";

    // @ngInject
    function ContactsControllerCtor($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ContactsController';
    }

    Controllers.ContactsController = Class(Controllers.BaseController, {
        constructor: ContactsControllerCtor,
    });
}());
