(function () {
    'use strict';

    class SessionReminderController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, moment, $element, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'SessionReminderController';

            this.moment = moment;
            this.$element = $element;
            this.AnalyticsService = AnalyticsService;

            this.reminderTypes = [
                { key: 'email', label: 'Email' },
                { key: 'sms_and_email', label: 'Email & SMS' }
            ];
        }

        $onInit() {
            this.selectedReminderType = this.reminder.reminder_type ? this.reminderTypes.find((st) => st.key === this.reminder.reminder_type) : this.reminderTypes[1];
            if (!this.reminder.reminder_type) {
                this.reminder.reminder_type = this.selectedReminderType.key;
            }
        }

        onDurationChange(duration) {
            this.reminder.when = duration;
            this._onChange();
        }

        removeReminder(reminder, $index) {
            this.onRemove({reminder, $index});
        }

        onNotifyMeChange() {
            const params = {
                field_name: 'reminder notify me',
                field_value: this.reminder.notify_me
            };
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.session_field_changed, params);
            this._onChange();
        }

        _onChange() {
            if(this.onChange) {
                this.onChange({reminder: this.reminder});
            }
        }

        onReminderTypeChange(item) {
            this.reminder.reminder_type = item.key;
            this._onChange();
        }

    }

    Components.SessionReminder = {
        bindings: {
            session: '<',
            reminder: '<',
            onRemove: '&',
            onChange: '&',
        },
        transclude: true,
        controller: SessionReminderController,
        controllerAs: 'sessionReminderVm',
        name: 'hbSessionReminder',
        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/session_reminder/session_reminder.html',
    };

}());