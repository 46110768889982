(function () {
    'use strict';

    class InteractiveClientSideController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
                $scope,
                $injector,
                $timeout,
                $q,
                UsersManager,
                CompaniesManager,
                WorkspaceFileService,
                SelfOnboardingManager,
                WorkspaceFilesManager,
                EventService,
                DeviceService,
                ModalService,
                FeatureRestrictionService
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveClientSideController';

            this.$timeout = $timeout;
            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.WorkspaceFileService = WorkspaceFileService;
            this.SelfOnboardingManager = SelfOnboardingManager;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.EventService = EventService;
            this.DeviceService = DeviceService;
            this.ModalService = ModalService;
            this.FeatureRestrictionService = FeatureRestrictionService;
        }

        $onInit() {
            this.user = this.UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));

            const exampleFileCoverImage = this.defaultCoverImage;
            if (exampleFileCoverImage) {
                this.exampleFileCoverImagePublicId = exampleFileCoverImage.cloudinary_public_id;
            }

            if (this.company.icon_image) {
                this.exampleFileLogoImagePublicId = this.company.icon_image.cloudinary_public_id;
            }
        }

        onGoToWorkspaceFile() {
            const isBlockedAccess = this.FeatureRestrictionService.checkIfBlockedAccess();
            if (isBlockedAccess) {
                return;
            }

            this.isLoading = true;
            if(this.user.onboarding_container.workspace_file_id) {
                this.invoice = this.WorkspaceFilesManager.getWorkspaceFile(this.user.onboarding_container.workspace_file_id);
                this.registerOnce(this.invoice, 'success', () => {
                    this.goToWorkspaceFile()
                        .then(this.handleGoToWorkspaceFileSuccess.bind(this));
                });
                this.registerOnce(this.invoice, 'error', () => {
                    this.user.onboarding_container.workspace_file_id = null;
                    this.onGoToWorkspaceFile();
                });
            } else {
                this.createInvoice(this.user.onboarding_container.onboarding_invoice_template_id)
                    .then(resp => this.updateOnboardingData(resp.data))
                    .then(resp => this.getInvoice(resp.data.onboarding_container.workspace_file_id))
                    .then(this.handleGoToWorkspaceFileSuccess.bind(this));
            }
        }

        goToWorkspaceFile() {
            var workspace_file_id = this.user.onboarding_container.workspace_file_id;
            return this.WorkspaceFileService.gotoWorkspaceFileById(workspace_file_id);
        }

        handleGoToWorkspaceFileSuccess() {
            this.onValidation({ isValid: true });
            this.isLoading = false;
        }

        updateOnboardingData(data) {
            var onboardingData = {
                workspace_file_id: data.workspace_file_id,
                event_id: data.project_id,
                workspace_id: data.workspace_id
            };
            return this.SelfOnboardingManager.updateOnboardingData(this.user, onboardingData);
        }

        createInvoice(templateId) {
            var projectData = this.EventService.createDefaultProject(this.company, this.user.first_name + '’s test project').dataOnly();
            projectData.event_source = 'onboarding';
            return this.WorkspaceFileService.createFileByTypeWithProject('invoice', null, templateId, projectData);
        }

        getInvoice(workspaceFileId) {
            this.invoice = this.WorkspaceFilesManager.getWorkspaceFile(workspaceFileId);
            this.registerOnce(this.invoice, 'success', this.onGotInvoice.bind(this));
        }

        sendTestFile() {
            if(this.DeviceService.checkIfMobileSize()) {
                var email = this.invoice.getEmailToSend();
                return this.WorkspaceFilesManager.sendFilePreview(this.invoice, email.subject, email.html_body, email.email_type);
            }
        }

        onGotInvoice() {
            this.renameInvoice(this.invoice)
                .then(resp => this.saveItemSuggestions(this.invoice._id))
                .then(resp => this.sendTestFile())
                .then(resp => this.goToWorkspaceFile());
        }

        renameInvoice(workspaceFile) {
            workspaceFile.file_title = this.user.first_name + '’s invoice';
            return this.WorkspaceFilesManager.saveWorkspaceFile(workspaceFile);
        }

        saveItemSuggestions(workspaceFileId) {
            return this.WorkspaceFilesManager.saveItemSuggestions(workspaceFileId);
        }
    }

    Components.InteractiveClientSide = {
        controller: InteractiveClientSideController,
        bindings: {
            onValidation: '&',
            onComplete: '&',
            defaultCoverImage: '='
        },
        name: 'hbInteractiveClientSide',
        require: {
            hbInteractiveOnboarding: '^hbInteractiveOnboarding'
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/client_side/client_side.html',
    };
}());