(function () {
    "use strict";

    // @ngInject
    function UserBrandsComponentControllerFunc($injector, $scope, AppStates) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'UserBrandsComponentController';

        this.AppStates = AppStates;
    }

    var UserBrandsComponentController = Class(Controllers.BaseController, {
        constructor: UserBrandsComponentControllerFunc,

        onCompanyClick: function onCompanyClick(companyId, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            if(this.onCompanySelect) {
                this.onCompanySelect({companyId: companyId});
            }
        },

    });

    Components.UserBrands = {
        bindings: {
            user: '<',
            onCompanySelect: '&?',
        },
        controller: UserBrandsComponentController,
        controllerAs: 'userBrandsVm',
        name: 'hbUserBrands',
        templateUrl: 'angular/app/modules/core/features/brands/user_brands_component_template.html',
    };

}());