
// @ngInject

Models.SignatureModel = function SignatureModel(BaseModel){
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'SignatureModel';

            this.cachable = false;

            this.signature = [];

            this.signatureTypes= {
                drawn: 0,
                typed: 1
            };
        },

        mapChilds: function mapChilds(){
            return {};
        },

        afterMixingFrom: function afterMixingFrom(object){
        },

        updateSignature: function updateSignature(){
            return this.__parent.updateSignature(this);
        },

        changeSignatureSignatory: function changeSignatureSignatory(user) {
            this.signee_name = user.full_name;
            this.signee_id = user._id;
            this.signee_description = user.full_name;
            // this.signee_type_cd = user.user_type_cd;
            return this.__parent.changeSignatureSignatory(this, user._id);
        },

        updateSignatureDescription: function updateSignatureDescription(){
            return this.__parent.updateSignatureDescription(this);
        },

        removeSignature: function removeSignature(){
            return this.__parent.removeSignature(this);
        },

        isTyped: function isTyped() {
            return this.signature_type_cd === this.signatureTypes.typed;
        }
    });

};
