(function () {
    'use strict';

    class FeedItemFlowViewerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $filter, $window, Enums, UsersManager, AnalyticsService, FlowService) {
            super($scope, $injector);
            this.__objectType = 'FeedItemFlowViewerController';

            this.$translate = $translate;
            this.$filter = $filter;
            this.$window = $window;
            this.Enums = Enums;
            this.actionType = this.activity.action_type;
            this.AnalyticsService = AnalyticsService;
            this.FlowService = FlowService;
            this.flowTitle = this.activity.title;
            this.shouldShowCompletedBox = false;
            this.shouldShowArrow = true;
            this.isVisibleOnlyForVendor = false;
            this.currUser = UsersManager.getCurrUser();
            this.isClient = this.currUser.isClient();
            this.full_name = this.getUserFullName();
            this.hideFeedItem = false;

            $scope.$watch('$ctrl.workspace.flows', (newVal, oldVal) => {
                if(newVal !== oldVal) {
                    this.setFlow();
                    this.setFlowInfoByActivity();
                }
            });
        }

        $onInit() {
            this.setFlow();
            this.setFlowInfoByActivity();
        }

        setFlow() {
            this.flow = this.workspace.flows ?
                this.workspace.flows.find((flow) => flow._id === this.activity.on_object_id || flow._id === this.activity.flow_id) :
                null;
        }

        setFlowInfoByActivity() {
            switch (this.activity.action_type) {
                case this.Enums.flowStates.completed:
                    this.setCompletedFlowItem();
                    break;
                case this.Enums.flowStates.submitted:
                    this.setSubmittedFlowItem();
                    break;
                case this.Enums.flowStates.viewed:
                    this.setViewedFlowItem();
                    break;
                case this.Enums.flowStates.deleted:
                    this.setDeletedFlowItem();
                    break;
                case this.Enums.flowStates.published:
                    this.setPublishedFlowItem();
                    break;
                case this.Enums.flowStates.publishedWithoutSharing:
                    this.setPublishedFlowWithoutSharingItem();
                case this.Enums.flowStates.draft:
                    this.setDraftFlowItem();
                    break;
            }
        }

        isLeadFormFlow() {
                return this.flow && this.flow.source_attribution === "public_flows";
        }

        setCompletedFlowItem() {
            let translation = this.activity.recompleted ? 'WORKSPACE.FEED.ACTIVITY.FLOW._RE_COMPLETED_' : 'WORKSPACE.FEED.ACTIVITY.FLOW._COMPLETED_';
            if (this.isLeadFormFlow()) {
                translation = 'WORKSPACE.FEED.ACTIVITY.LEAD_FORM._COMPLETED_';
            }

            this.title = this.$translate.instant(translation, {full_name: this.full_name , title: this.flowTitle});
            this.shouldShowCompletedBox = true;
        }

        setSubmittedFlowItem() {
            const translation = !this.activity.recompleted ? 'WORKSPACE.FEED.ACTIVITY.FLOW._SUBMITTED_' : 'WORKSPACE.FEED.ACTIVITY.FLOW._RE_SUBMITTED_';
            this.title = this.$translate.instant(translation, {full_name: this.full_name , title: this.flowTitle});
            this.shouldShowCompletedBox = true;
        }

        setViewedFlowItem() {
            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW._VIEWED_',
            {full_name: this.full_name , title: this.flowTitle});
        }

        setDeletedFlowItem() {
            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW._DELETED_',
            {full_name: this.full_name , title: this.flowTitle});
            this.shouldShowArrow = false;

            if (!this.isClient) {
                this.isVisibleOnlyForVendor = true;
            } else {
                this.hideFeedItem = true;
            }
        }

        setPublishedFlowItem() {
            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW._PUBLISHED_',
                {full_name: this.full_name , title: this.flowTitle});
        }

        setDraftFlowItem() {
            this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW._DRAFT_',
                {full_name: this.full_name , title: this.flowTitle});

            if (!this.isClient) {
                this.isVisibleOnlyForVendor = true;
            } else {
                this.hideFeedItem = true;
            }
        }

        setPublishedFlowWithoutSharingItem() {
            if (this.isClient) {
                this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.PUBLISHED_WITHOUT_SHARING._CX_TITLE_',
                    {full_name: this.full_name , title: this.flowTitle});
            } else {
                this.title = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.PUBLISHED_WITHOUT_SHARING._TITLE_',
                    {full_name: this.full_name , title: this.flowTitle});
                this.description = this.$translate.instant('WORKSPACE.FEED.ACTIVITY.FLOW.PUBLISHED_WITHOUT_SHARING._DESCRIPTION_');
            }
        }

        getUserFullName() {
            if (this.activity.user) {
                return this.currUser._id === this.activity.user._id ? 'You' : this.activity.user.full_name;
            }
            return 'Client';
        }

        onClick(event) {
            event.stopPropagation();
            this.FlowService.goToFlowByUser(this.flow, this.currUser);
        }
    }

    Components.FeedItemFlowViewer = {
        bindings: {
            activity: '<',
            workspace: '<?'
        },
        controller: FeedItemFlowViewerController,
        name: 'hbFeedItemFlowViewer',
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_item_flow_viewer_template.html',
    };

}());