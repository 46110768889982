/**
    Created by brianschroer on 2/1/16.

    Popover that uses the Drop.js library (which expands the tether.js library)
    See documentation here: http://github.hubspot.com/drop/
 */

(function () {
    'use strict';

    Directives.OnboardingPopoverDirective = function OnboardingPopoverDirective() {
        // @ngInject
        function OnboardingPopoverDirectiveControllerCtor($element, $scope, $transclude, $injector, $sce, $templateCache, $compile,
                                                          Drop, AnalyticsService, OnboardingService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'OnboardingPopoverDirectiveController';
            this.Drop = Drop;
            this.AnalyticsService = AnalyticsService;
            this.OnboardingService = OnboardingService;

            this.constrain = typeof this.constrain === 'boolean' ? this.constrain : true;
            if (this.targetSelector) {
                this.target = angular.element(this.targetSelector)[0];
            } else {
                this.target = $element[0];
            }

            var rawHtml = angular.element(document.createElement('div'));
            $transclude(function(clonedTranscludedContent) { rawHtml.append(clonedTranscludedContent); });

            this.innerContent = $sce.trustAsHtml(rawHtml[0].innerHTML);
            var rawContent    = $templateCache.get('angular/app/modules/core/features/onboarding/onboarding_popover_directive/onboarding_popover_directive_template.html');
            rawContent = '<div>' + rawContent + '</div>';
            var content       = $compile(rawContent)($scope)[0];

            var defaultDropArgs= {
                target: this.target,
                openOn: null,
                classes: this.dropClasses,
                content: content,
                remove: true,
                constrainToWindow: this.constrain,
                constrainToScrollParent: this.constrain
            };

            //There is an error if position arg is sent as null. Let's only send position and other args as needed.
            if(this.position) {
                defaultDropArgs.position = this.position;
            }
            if(this.tetherOptions){
                defaultDropArgs.tetherOptions = this.tetherOptions;
            }

            this.drop = new this.Drop(defaultDropArgs);

            this.openSesame = function openSesame() {
                this.drop.open();
                if (this.analyticsEvent) {
                    this.AnalyticsService.track(this, this.analyticsEvent + ' opened');
                }
            }.bind(this);

            if (this.openOnLoad) {
                this.openSesame();
            }

            this.imageSource = 'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/main_general/alexis-thumb.jpg';

            $scope.$watch(OnboardingService.currentStep, function(oldVal, newVal) {
                if (this.currentStep && oldVal && newVal) {
                    if (newVal !== this.onboardingStep) {
                        this.remove();
                    }
                }
            }.bind(this));

            $scope.$on('$destroy', function() {
               this.remove();
            }.bind(this));

        }

        var OnboardingPopoverDirectiveController = Class(Controllers.BaseController, {
            constructor: OnboardingPopoverDirectiveControllerCtor,

            remove: function close() {
                this.drop.remove();
                //this.OnboardingService.hidePopovers();
                if (this.analyticsEvent) {
                    this.AnalyticsService.track(this, this.analyticsEvent + ' closed');
                }
                if (this.onCloseFunction){
                    this.onCloseFunction();
                }
            },

            showPopovers: function showPopovers() {
                return this.OnboardingService.shouldShowPopovers;
            }

        });


        return {
            restrict: 'EA',
            controller: OnboardingPopoverDirectiveController,
            controllerAs: 'onboardingPopoverVm',
            bindToController: true,
            replace: true,
            transclude: true,
            scope: {
                onboardingStep: '@',
                position: '@',       /* position of target element that you wish to place the popover relative to */
                dropClasses: '@',
                targetSelector: '@', /* selector of element that you wish to tether the popover to */
                openOnLoad: '@',
                includeNub: '@',     /* include optional nub/triangle at bottom of popover */
                analyticsEvent: '@', /* A general event that will be prefixed with closed on the closing of the popover and open on the opening of the popover */
                constrain: '=?', /* Drop's constrain to window & constrain to scrollParent properties; we can expand this to two attributes if we need to individually edit both */
                onCloseFunction: '=?',
                tetherOptions: '=?'
            }
        };
    };

}());
