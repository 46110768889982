(function () {
    "use strict";

    // @ngInject
    function BlockTwoImgControllerFunc($injector, $scope, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockTwoImgController';
        this.AnalyticsService = AnalyticsService;

        this._init();
    }

    var BlockTwoImgController = Class(Controllers.BaseController, {
        constructor: BlockTwoImgControllerFunc,

        _init: function _init() {
            var color = this.block.file_block_style ? this.block.file_block_style.background_color : '#ffffff';
            this.setBlockBackground(color);
        },

        updateBlock: function updateBlock(field) {
            this.block.updateBlock([field]);
        },

        updateBlockBackground: function updateBlockBackground() {
            this.updateBlock('file_block_style');
        },

        onBackgroundChange: function onBackgroundChange(color) {
            this.setBlockBackground(color);
            this.updateBlockBackground();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'background color', block_type: "two column images"});
        },

        setBlockBackground: function setBlockBackground(color) {
            this.styles = {
                'backgroundColor': color
            };
        },

        onPickerShown: function onPickerShown(color) {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.showed_block_color_picker, {block_type: "two column images"});
        },

        saveImage: function saveImage(imageModel, attribute) {
            this.block[attribute] = imageModel;
            this.block.updateBlock([attribute]);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'image', block_type: "two column images"});
        }

    });

    Components.BlockTwoImg = {
        bindings: {
            block: '=',
            isEditable: '<'
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_two_images/block_two_images.html',
        controller: BlockTwoImgController,
        controllerAs: 'blockTwoImgVm',
        name: 'hbBlockTwoImg'
    };

}());



