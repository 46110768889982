// @ngInject
Filters.HbRelativeTimeFilter = function HbRelativeTimeFilter($filter, $translate) {

    var todayStr = $translate.instant('TIME_LABELS._TODAY_');
    var yesterdayStr = $translate.instant('TIME_LABELS._YESTERDAY_');

    function daysAgoFromToday(daysAgo) {
        var now         = new Date();
        var daysAgoDate = new Date(now.setDate(now.getDate() - daysAgo));
        return daysAgoDate.toDateString();
    }

    return function (dateInput) {

        var date = new Date(dateInput);

        if (date.toDateString() === daysAgoFromToday(0)) {
            return todayStr + ", " + $filter('date')(dateInput, 'shortTime'); //shortTime: same as 'h:mm A' (e.g. 12:05 PM)
        } else if (date.toDateString() === daysAgoFromToday(1)) {
            return yesterdayStr +", " + $filter('date')(dateInput, 'shortTime');
        } else {
            return $filter('date')(date, 'short');
        }
    };
};
