// @ngInject
Filters.HbCurrencyFilter = function HbCurrencyFilter($filter) {

    var CURRENCY_SYMBOLS_MAP = {
        'USD': '$',
        'CAD': 'CAD ',
        '$': '$',
    };

    var NEGATIVE_CURRENCY_SYMBOLS_MAP = {
        '$': '-$',
        'USD': '-$',
        'CAD': 'CAD -'
    };

    return function (amount, currencySymbol, fractionSize, negate) {

        if (!currencySymbol) {
            currencySymbol = 'USD';
        }

        var finalCurrency;
        if (negate) {
            finalCurrency = NEGATIVE_CURRENCY_SYMBOLS_MAP[currencySymbol];
        } else {
            finalCurrency = CURRENCY_SYMBOLS_MAP[currencySymbol];
        }

        if (fractionSize === undefined) {
            fractionSize = 2;
        }

        amount = amount ? (amount * 1).toFixed(fractionSize) : 0.00;

        var value = $filter('currency')(amount, finalCurrency);

        // remove minus sign for 0
        if (parseFloat(amount) === 0) {
            value = value.replace('-', '');
        }

        // remove the decimal point if it is zero (0)
        value = value.replace(/\.(.*)$/, function (match, cents) {
            if (+cents === 0) {
                return '';
            } else {
                return match;
            }
        });

        return value;
    };
};
