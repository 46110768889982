(function () {
    "use strict";

    // @ngInject
    function BlockImageControllerFunc($injector, $scope, ImagesManager, ImageService, ModalService, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BlockImageController';

        this.ImagesManager = ImagesManager;
        this.ImageService = ImageService;
        this.ModalService = ModalService;
        this.AnalyticsService = AnalyticsService;
    }

    var BlockImageController = Class(Controllers.BaseController, {
        constructor: BlockImageControllerFunc,

        onChooseImage: function onChooseImage() {
            if (this.imageModel) {
                this.editImage();
            } else {
                this.addImage();
            }
        },

        addImage: function addImage() {
            this.changeImage('MEDIA_MODAL.TITLE._ADD_IMAGE_', '', 'Upload');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.add_block_image);
        },

        editImage: function editImage() {
            this.changeImage('MEDIA_MODAL.TITLE._EDIT_IMAGE_', this.imageModel, 'Crop');
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.edit_block_image);
        },

        changeImage: function changeImage(modalTitle, modalImage, defaultTab) {
            return this.ModalService.openAttachAssetModal(true, true, true,
                defaultTab, false, false, modalImage, this.imageRatio,
                false, false, 'image/*', '', '', modalTitle)
                .then(function success(image){
                    this.saveImage(angular.copy(image));
                }.bind(this))
                .finally(function () {
                }.bind(this));
        },

        saveImage: function saveImage(imageModel) {
            if(this.onSave) {
                this.onSave({imageModel: imageModel});
            }
        },

        shouldShowChangeButton: function shouldShowChangeButton() {
            var showButton = this.imageModel ? !!this.editButton : !!this.addButton;
            return showButton;
        }

    });

    Components.BlockImage = {
        bindings: {
            imageModel: '=',
            onSave: '&',
            maxWidth: '<',
            imageRatio: '<',
            addButton: '<',
            editButton: '<',
            isEditable: '<'
        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/blocks/block_image/block_image.html',
        controller: BlockImageController,
        controllerAs: 'blockImageVm',
        name: 'hbBlockImage'
    };

}());


