/**
 * Created by udicohen on 11/1/16.
 */
(function () {
    'use strict';

    Directives.BackgroundImage = function BackgroundImage() {
        // @ngInject
        function BackgroundImageControllerFunc($scope, $injector, $element) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'BackgroundImageController';

            $scope.$watch('backgroundImage', function backgroundImageChanged(newVal) {
                if (newVal) {
                    $element.css({
                        'background-image': 'url(' + $scope.backgroundImage +')'
                    });
                }
            });
        }

        var BackgroundImageController = Class(Controllers.BaseController, {
            constructor: BackgroundImageControllerFunc
        });

        return {
            scope: {
                backgroundImage: '=hbBackgroundImage'
            },
            restrict: 'A',
            controller: BackgroundImageController
        };
    };
}());