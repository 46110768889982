(function () {
    "use strict";

    const MCC_OPTIONS = [
        {
          "label": "7623 - A/C, Refrigeration Repair",
          "value": "7623"
        },
        {
          "label": "8931 - Accounting/Bookkeeping Services",
          "value": "8931"
        },
        {
          "label": "7311 - Advertising Services",
          "value": "7311"
        },
        {
          "label": "0763 - Agricultural Cooperative",
          "value": "0763"
        },
        {
          "label": "4511 - Airlines, Air Carriers",
          "value": "4511"
        },
        {
          "label": "4582 - Airports, Flying Fields",
          "value": "4582"
        },
        {
          "label": "4119 - Ambulance Services",
          "value": "4119"
        },
        {
          "label": "7996 - Amusement Parks/Carnivals",
          "value": "7996"
        },
        {
          "label": "5937 - Antique Reproductions",
          "value": "5937"
        },
        {
          "label": "5932 - Antique Shops",
          "value": "5932"
        },
        {
          "label": "7998 - Aquariums",
          "value": "7998"
        },
        {
          "label": "8911 - Architectural/Surveying Services",
          "value": "8911"
        },
        {
          "label": "5971 - Art Dealers and Galleries",
          "value": "5971"
        },
        {
          "label": "5970 - Artists Supply and Craft Shops",
          "value": "5970"
        },
        {
          "label": "7531 - Auto Body Repair Shops",
          "value": "7531"
        },
        {
          "label": "7535 - Auto Paint Shops",
          "value": "7535"
        },
        {
          "label": "7538 - Auto Service Shops",
          "value": "7538"
        },
        {
          "label": "5531 - Auto and Home Supply Stores",
          "value": "5531"
        },
        {
          "label": "6011 - Automated Cash Disburse",
          "value": "6011"
        },
        {
          "label": "5542 - Automated Fuel Dispensers",
          "value": "5542"
        },
        {
          "label": "8675 - Automobile Associations",
          "value": "8675"
        },
        {
          "label": "5533 - Automotive Parts and Accessories Stores",
          "value": "5533"
        },
        {
          "label": "5532 - Automotive Tire Stores",
          "value": "5532"
        },
        {
          "label": "9223 - Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)",
          "value": "9223"
        },
        {
          "label": "5462 - Bakeries",
          "value": "5462"
        },
        {
          "label": "7929 - Bands, Orchestras",
          "value": "7929"
        },
        {
          "label": "7230 - Barber and Beauty Shops",
          "value": "7230"
        },
        {
          "label": "7995 - Betting/Casino Gambling",
          "value": "7995"
        },
        {
          "label": "5940 - Bicycle Shops",
          "value": "5940"
        },
        {
          "label": "7932 - Billiard/Pool Establishments",
          "value": "7932"
        },
        {
          "label": "5551 - Boat Dealers",
          "value": "5551"
        },
        {
          "label": "4457 - Boat Rentals and Leases",
          "value": "4457"
        },
        {
          "label": "5942 - Book Stores",
          "value": "5942"
        },
        {
          "label": "5192 - Books, Periodicals, and Newspapers",
          "value": "5192"
        },
        {
          "label": "7933 - Bowling Alleys",
          "value": "7933"
        },
        {
          "label": "4131 - Bus Lines",
          "value": "4131"
        },
        {
          "label": "8244 - Business/Secretarial Schools",
          "value": "8244"
        },
        {
          "label": "7278 - Buying/Shopping Services",
          "value": "7278"
        },
        {
          "label": "4899 - Cable, Satellite, and Other Pay Television and Radio",
          "value": "4899"
        },
        {
          "label": "5946 - Camera and Photographic Supply Stores",
          "value": "5946"
        },
        {
          "label": "5441 - Candy, Nut, and Confectionery Stores",
          "value": "5441"
        },
        {
          "label": "7512 - Car Rental Agencies",
          "value": "7512"
        },
        {
          "label": "7542 - Car Washes",
          "value": "7542"
        },
        {
          "label": "5511 - Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing",
          "value": "5511"
        },
        {
          "label": "5521 - Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing",
          "value": "5521"
        },
        {
          "label": "1750 - Carpentry Services",
          "value": "1750"
        },
        {
          "label": "7217 - Carpet/Upholstery Cleaning",
          "value": "7217"
        },
        {
          "label": "5811 - Caterers",
          "value": "5811"
        },
        {
          "label": "8398 - Charitable and Social Service Organizations - Fundraising",
          "value": "8398"
        },
        {
          "label": "5169 - Chemicals and Allied Products (Not Elsewhere Classified)",
          "value": "5169"
        },
        {
          "label": "5641 - Chidrens and Infants Wear Stores",
          "value": "5641"
        },
        {
          "label": "8351 - Child Care Services",
          "value": "8351"
        },
        {
          "label": "8049 - Chiropodists, Podiatrists",
          "value": "8049"
        },
        {
          "label": "8041 - Chiropractors",
          "value": "8041"
        },
        {
          "label": "5993 - Cigar Stores and Stands",
          "value": "5993"
        },
        {
          "label": "8641 - Civic, Social, Fraternal Associations",
          "value": "8641"
        },
        {
          "label": "7349 - Cleaning and Maintenance",
          "value": "7349"
        },
        {
          "label": "7296 - Clothing Rental",
          "value": "7296"
        },
        {
          "label": "8220 - Colleges, Universities",
          "value": "8220"
        },
        {
          "label": "5046 - Commercial Equipment (Not Elsewhere Classified)",
          "value": "5046"
        },
        {
          "label": "5139 - Commercial Footwear",
          "value": "5139"
        },
        {
          "label": "7333 - Commercial Photography, Art and Graphics",
          "value": "7333"
        },
        {
          "label": "4111 - Commuter Transport, Ferries",
          "value": "4111"
        },
        {
          "label": "4816 - Computer Network Services",
          "value": "4816"
        },
        {
          "label": "7372 - Computer Programming",
          "value": "7372"
        },
        {
          "label": "7379 - Computer Repair",
          "value": "7379"
        },
        {
          "label": "5734 - Computer Software Stores",
          "value": "5734"
        },
        {
          "label": "5045 - Computers, Peripherals, and Software",
          "value": "5045"
        },
        {
          "label": "1771 - Concrete Work Services",
          "value": "1771"
        },
        {
          "label": "5039 - Construction Materials (Not Elsewhere Classified)",
          "value": "5039"
        },
        {
          "label": "7392 - Consulting, Public Relations",
          "value": "7392"
        },
        {
          "label": "8241 - Correspondence Schools",
          "value": "8241"
        },
        {
          "label": "5977 - Cosmetic Stores",
          "value": "5977"
        },
        {
          "label": "7277 - Counseling Services",
          "value": "7277"
        },
        {
          "label": "7997 - Country Clubs",
          "value": "7997"
        },
        {
          "label": "4215 - Courier Services",
          "value": "4215"
        },
        {
          "label": "9211 - Court Costs, Including Alimony and Child Support - Courts of Law",
          "value": "9211"
        },
        {
          "label": "7321 - Credit Reporting Agencies",
          "value": "7321"
        },
        {
          "label": "4411 - Cruise Lines",
          "value": "4411"
        },
        {
          "label": "5451 - Dairy Products Stores",
          "value": "5451"
        },
        {
          "label": "7911 - Dance Hall, Studios, Schools",
          "value": "7911"
        },
        {
          "label": "7273 - Dating/Escort Services",
          "value": "7273"
        },
        {
          "label": "8021 - Dentists, Orthodontists",
          "value": "8021"
        },
        {
          "label": "5311 - Department Stores",
          "value": "5311"
        },
        {
          "label": "7393 - Detective Agencies",
          "value": "7393"
        },
        {
          "label": "5817 - Digital Goods – Applications (Excludes Games)",
          "value": "5817"
        },
        {
          "label": "5964 - Direct Marketing - Catalog Merchant",
          "value": "5964"
        },
        {
          "label": "5965 - Direct Marketing - Combination Catalog and Retail Merchant",
          "value": "5965"
        },
        {
          "label": "5967 - Direct Marketing - Inbound Telemarketing",
          "value": "5967"
        },
        {
          "label": "5960 - Direct Marketing - Insurance Services",
          "value": "5960"
        },
        {
          "label": "5969 - Direct Marketing - Other",
          "value": "5969"
        },
        {
          "label": "5966 - Direct Marketing - Outbound Telemarketing",
          "value": "5966"
        },
        {
          "label": "5968 - Direct Marketing - Subscription",
          "value": "5968"
        },
        {
          "label": "5962 - Direct Marketing - Travel",
          "value": "5962"
        },
        {
          "label": "5310 - Discount Stores",
          "value": "5310"
        },
        {
          "label": "8011 - Doctors",
          "value": "8011"
        },
        {
          "label": "5963 - Door-To-Door Sales",
          "value": "5963"
        },
        {
          "label": "5714 - Drapery, Window Covering, and Upholstery Stores",
          "value": "5714"
        },
        {
          "label": "5813 - Drinking Places",
          "value": "5813"
        },
        {
          "label": "5912 - Drug Stores and Pharmacies",
          "value": "5912"
        },
        {
          "label": "5122 - Drugs, Drug Proprietaries, and Druggist Sundries",
          "value": "5122"
        },
        {
          "label": "7216 - Dry Cleaners",
          "value": "7216"
        },
        {
          "label": "5099 - Durable Goods (Not Elsewhere Classified)",
          "value": "5099"
        },
        {
          "label": "5309 - Duty Free Stores",
          "value": "5309"
        },
        {
          "label": "5812 - Eating Places, Restaurants",
          "value": "5812"
        },
        {
          "label": "8299 - Educational Services",
          "value": "8299"
        },
        {
          "label": "5997 - Electric Razor Stores",
          "value": "5997"
        },
        {
          "label": "5065 - Electrical Parts and Equipment",
          "value": "5065"
        },
        {
          "label": "1731 - Electrical Services",
          "value": "1731"
        },
        {
          "label": "7622 - Electronics Repair Shops",
          "value": "7622"
        },
        {
          "label": "5732 - Electronics Stores",
          "value": "5732"
        },
        {
          "label": "8211 - Elementary, Secondary Schools",
          "value": "8211"
        },
        {
          "label": "7361 - Employment/Temp Agencies",
          "value": "7361"
        },
        {
          "label": "7394 - Equipment Rental",
          "value": "7394"
        },
        {
          "label": "7342 - Exterminating Services",
          "value": "7342"
        },
        {
          "label": "5651 - Family Clothing Stores",
          "value": "5651"
        },
        {
          "label": "5814 - Fast Food Restaurants",
          "value": "5814"
        },
        {
          "label": "6012 - Financial Institutions",
          "value": "6012"
        },
        {
          "label": "9222 - Fines - Government Administrative Entities",
          "value": "9222"
        },
        {
          "label": "5718 - Fireplace, Fireplace Screens, and Accessories Stores",
          "value": "5718"
        },
        {
          "label": "5713 - Floor Covering Stores",
          "value": "5713"
        },
        {
          "label": "5992 - Florists",
          "value": "5992"
        },
        {
          "label": "5193 - Florists Supplies, Nursery Stock, and Flowers",
          "value": "5193"
        },
        {
          "label": "5422 - Freezer and Locker Meat Provisioners",
          "value": "5422"
        },
        {
          "label": "5983 - Fuel Dealers (Non Automotive)",
          "value": "5983"
        },
        {
          "label": "7261 - Funeral Services, Crematories",
          "value": "7261"
        },
        {
          "label": "7641 - Furniture Repair, Refinishing",
          "value": "7641"
        },
        {
          "label": "5712 - Furniture, Home Furnishings, and Equipment Stores, Except Appliances",
          "value": "5712"
        },
        {
          "label": "5681 - Furriers and Fur Shops",
          "value": "5681"
        },
        {
          "label": "1520 - General Services",
          "value": "1520"
        },
        {
          "label": "5947 - Gift, Card, Novelty, and Souvenir Shops",
          "value": "5947"
        },
        {
          "label": "5231 - Glass, Paint, and Wallpaper Stores",
          "value": "5231"
        },
        {
          "label": "5950 - Glassware, Crystal Stores",
          "value": "5950"
        },
        {
          "label": "7992 - Golf Courses - Public",
          "value": "7992"
        },
        {
          "label": "9399 - Government Services (Not Elsewhere Classified)",
          "value": "9399"
        },
        {
          "label": "5411 - Grocery Stores, Supermarkets",
          "value": "5411"
        },
        {
          "label": "5251 - Hardware Stores",
          "value": "5251"
        },
        {
          "label": "5072 - Hardware, Equipment, and Supplies",
          "value": "5072"
        },
        {
          "label": "7298 - Health and Beauty Spas",
          "value": "7298"
        },
        {
          "label": "5975 - Hearing Aids Sales and Supplies",
          "value": "5975"
        },
        {
          "label": "1711 - Heating, Plumbing, A/C",
          "value": "1711"
        },
        {
          "label": "5945 - Hobby, Toy, and Game Shops",
          "value": "5945"
        },
        {
          "label": "5200 - Home Supply Warehouse Stores",
          "value": "5200"
        },
        {
          "label": "8062 - Hospitals",
          "value": "8062"
        },
        {
          "label": "7011 - Hotels, Motels, and Resorts",
          "value": "7011"
        },
        {
          "label": "5722 - Household Appliance Stores",
          "value": "5722"
        },
        {
          "label": "5085 - Industrial Supplies (Not Elsewhere Classified)",
          "value": "5085"
        },
        {
          "label": "7375 - Information Retrieval Services",
          "value": "7375"
        },
        {
          "label": "6399 - Insurance - Default",
          "value": "6399"
        },
        {
          "label": "6300 - Insurance Underwriting, Premiums",
          "value": "6300"
        },
        {
          "label": "9950 - Intra-Company Purchases",
          "value": "9950"
        },
        {
          "label": "5944 - Jewelry Stores, Watches, Clocks, and Silverware Stores",
          "value": "5944"
        },
        {
          "label": "0780 - Landscaping Services",
          "value": "0780"
        },
        {
          "label": "7211 - Laundries",
          "value": "7211"
        },
        {
          "label": "7210 - Laundry, Cleaning Services",
          "value": "7210"
        },
        {
          "label": "8111 - Legal Services, Attorneys",
          "value": "8111"
        },
        {
          "label": "5948 - Luggage and Leather Goods Stores",
          "value": "5948"
        },
        {
          "label": "5211 - Lumber, Building Materials Stores",
          "value": "5211"
        },
        {
          "label": "6010 - Manual Cash Disburse",
          "value": "6010"
        },
        {
          "label": "4468 - Marinas, Service and Supplies",
          "value": "4468"
        },
        {
          "label": "1740 - Masonry, Stonework, and Plaster",
          "value": "1740"
        },
        {
          "label": "7297 - Massage Parlors",
          "value": "7297"
        },
        {
          "label": "8099 - Medical Services",
          "value": "8099"
        },
        {
          "label": "8071 - Medical and Dental Labs",
          "value": "8071"
        },
        {
          "label": "5047 - Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies",
          "value": "5047"
        },
        {
          "label": "8699 - Membership Organizations",
          "value": "8699"
        },
        {
          "label": "5611 - Mens and Boys Clothing and Accessories Stores",
          "value": "5611"
        },
        {
          "label": "5691 - Mens, Womens Clothing Stores",
          "value": "5691"
        },
        {
          "label": "5051 - Metal Service Centers",
          "value": "5051"
        },
        {
          "label": "5699 - Miscellaneous Apparel and Accessory Shops",
          "value": "5699"
        },
        {
          "label": "5599 - Miscellaneous Auto Dealers",
          "value": "5599"
        },
        {
          "label": "7399 - Miscellaneous Business Services",
          "value": "7399"
        },
        {
          "label": "5499 - Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
          "value": "5499"
        },
        {
          "label": "5399 - Miscellaneous General Merchandise",
          "value": "5399"
        },
        {
          "label": "7299 - Miscellaneous General Services",
          "value": "7299"
        },
        {
          "label": "5719 - Miscellaneous Home Furnishing Specialty Stores",
          "value": "5719"
        },
        {
          "label": "2741 - Miscellaneous Publishing and Printing",
          "value": "2741"
        },
        {
          "label": "7999 - Miscellaneous Recreation Services",
          "value": "7999"
        },
        {
          "label": "7699 - Miscellaneous Repair Shops",
          "value": "7699"
        },
        {
          "label": "5999 - Miscellaneous Specialty Retail",
          "value": "5999"
        },
        {
          "label": "5271 - Mobile Home Dealers",
          "value": "5271"
        },
        {
          "label": "7832 - Motion Picture Theaters",
          "value": "7832"
        },
        {
          "label": "4214 - Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services",
          "value": "4214"
        },
        {
          "label": "5592 - Motor Homes Dealers",
          "value": "5592"
        },
        {
          "label": "5013 - Motor Vehicle Supplies and New Parts",
          "value": "5013"
        },
        {
          "label": "5571 - Motorcycle Shops and Dealers",
          "value": "5571"
        },
        {
          "label": "5561 - Motorcycle Shops, Dealers",
          "value": "5561"
        },
        {
          "label": "5733 - Music Stores-Musical Instruments, Pianos, and Sheet Music",
          "value": "5733"
        },
        {
          "label": "5994 - News Dealers and Newsstands",
          "value": "5994"
        },
        {
          "label": "6051 - Non-FI, Money Orders",
          "value": "6051"
        },
        {
          "label": "6540 - Non-FI, Stored Value Card Purchase/Load",
          "value": "6540"
        },
        {
          "label": "5199 - Nondurable Goods (Not Elsewhere Classified)",
          "value": "5199"
        },
        {
          "label": "5261 - Nurseries, Lawn and Garden Supply Stores",
          "value": "5261"
        },
        {
          "label": "8050 - Nursing/Personal Care",
          "value": "8050"
        },
        {
          "label": "5021 - Office and Commercial Furniture",
          "value": "5021"
        },
        {
          "label": "8043 - Opticians, Eyeglasses",
          "value": "8043"
        },
        {
          "label": "8042 - Optometrists, Ophthalmologist",
          "value": "8042"
        },
        {
          "label": "5976 - Orthopedic Goods - Prosthetic Devices",
          "value": "5976"
        },
        {
          "label": "8031 - Osteopaths",
          "value": "8031"
        },
        {
          "label": "5921 - Package Stores-Beer, Wine, and Liquor",
          "value": "5921"
        },
        {
          "label": "5198 - Paints, Varnishes, and Supplies",
          "value": "5198"
        },
        {
          "label": "7523 - Parking Lots, Garages",
          "value": "7523"
        },
        {
          "label": "4112 - Passenger Railways",
          "value": "4112"
        },
        {
          "label": "5933 - Pawn Shops",
          "value": "5933"
        },
        {
          "label": "5995 - Pet Shops, Pet Food, and Supplies",
          "value": "5995"
        },
        {
          "label": "5172 - Petroleum and Petroleum Products",
          "value": "5172"
        },
        {
          "label": "7395 - Photo Developing",
          "value": "7395"
        },
        {
          "label": "7221 - Photographic Studios",
          "value": "7221"
        },
        {
          "label": "5044 - Photographic, Photocopy, Microfilm Equipment, and Supplies",
          "value": "5044"
        },
        {
          "label": "7829 - Picture/Video Production",
          "value": "7829"
        },
        {
          "label": "5131 - Piece Goods, Notions, and Other Dry Goods",
          "value": "5131"
        },
        {
          "label": "5074 - Plumbing, Heating Equipment, and Supplies",
          "value": "5074"
        },
        {
          "label": "8651 - Political Organizations",
          "value": "8651"
        },
        {
          "label": "9402 - Postal Services - Government Only",
          "value": "9402"
        },
        {
          "label": "5094 - Precious Stones and Metals, Watches and Jewelry",
          "value": "5094"
        },
        {
          "label": "8999 - Professional Services",
          "value": "8999"
        },
        {
          "label": "4225 - Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage",
          "value": "4225"
        },
        {
          "label": "7338 - Quick Copy, Repro, and Blueprint",
          "value": "7338"
        },
        {
          "label": "4011 - Railroads",
          "value": "4011"
        },
        {
          "label": "6513 - Real Estate Agents and Managers - Rentals",
          "value": "6513"
        },
        {
          "label": "5735 - Record Stores",
          "value": "5735"
        },
        {
          "label": "7519 - Recreational Vehicle Rentals",
          "value": "7519"
        },
        {
          "label": "5973 - Religious Goods Stores",
          "value": "5973"
        },
        {
          "label": "8661 - Religious Organizations",
          "value": "8661"
        },
        {
          "label": "1761 - Roofing/Siding, Sheet Metal",
          "value": "1761"
        },
        {
          "label": "7339 - Secretarial Support Services",
          "value": "7339"
        },
        {
          "label": "6211 - Security Brokers/Dealers",
          "value": "6211"
        },
        {
          "label": "5541 - Service Stations",
          "value": "5541"
        },
        {
          "label": "5949 - Sewing, Needlework, Fabric, and Piece Goods Stores",
          "value": "5949"
        },
        {
          "label": "7251 - Shoe Repair/Hat Cleaning",
          "value": "7251"
        },
        {
          "label": "5661 - Shoe Stores",
          "value": "5661"
        },
        {
          "label": "7629 - Small Appliance Repair",
          "value": "7629"
        },
        {
          "label": "5598 - Snowmobile Dealers",
          "value": "5598"
        },
        {
          "label": "1799 - Special Trade Services",
          "value": "1799"
        },
        {
          "label": "2842 - Specialty Cleaning",
          "value": "2842"
        },
        {
          "label": "5941 - Sporting Goods Stores",
          "value": "5941"
        },
        {
          "label": "7032 - Sporting/Recreation Camps",
          "value": "7032"
        },
        {
          "label": "7941 - Sports Clubs/Fields",
          "value": "7941"
        },
        {
          "label": "5655 - Sports and Riding Apparel Stores",
          "value": "5655"
        },
        {
          "label": "5972 - Stamp and Coin Stores",
          "value": "5972"
        },
        {
          "label": "5111 - Stationery, Office Supplies, Printing and Writing Paper",
          "value": "5111"
        },
        {
          "label": "5943 - Stationery Stores, Office, and School Supply Stores",
          "value": "5943"
        },
        {
          "label": "5996 - Swimming Pools Sales",
          "value": "5996"
        },
        {
          "label": "4723 - TUI Travel - Germany",
          "value": "4723"
        },
        {
          "label": "5697 - Tailors, Alterations",
          "value": "5697"
        },
        {
          "label": "9311 - Tax Payments - Government Agencies",
          "value": "9311"
        },
        {
          "label": "7276 - Tax Preparation Services",
          "value": "7276"
        },
        {
          "label": "4121 - Taxicabs/Limousines",
          "value": "4121"
        },
        {
          "label": "4812 - Telecommunication Equipment and Telephone Sales",
          "value": "4812"
        },
        {
          "label": "4814 - Telecommunication Services",
          "value": "4814"
        },
        {
          "label": "4821 - Telegraph Services",
          "value": "4821"
        },
        {
          "label": "5998 - Tent and Awning Shops",
          "value": "5998"
        },
        {
          "label": "8734 - Testing Laboratories",
          "value": "8734"
        },
        {
          "label": "7922 - Theatrical Ticket Agencies",
          "value": "7922"
        },
        {
          "label": "7012 - Timeshares",
          "value": "7012"
        },
        {
          "label": "7534 - Tire Retreading and Repair",
          "value": "7534"
        },
        {
          "label": "4784 - Tolls/Bridge Fees",
          "value": "4784"
        },
        {
          "label": "7991 - Tourist Attractions and Exhibits",
          "value": "7991"
        },
        {
          "label": "7549 - Towing Services",
          "value": "7549"
        },
        {
          "label": "7033 - Trailer Parks, Campgrounds",
          "value": "7033"
        },
        {
          "label": "4789 - Transportation Services (Not Elsewhere Classified)",
          "value": "4789"
        },
        {
          "label": "4722 - Travel Agencies, Tour Operators",
          "value": "4722"
        },
        {
          "label": "7511 - Truck StopIteration",
          "value": "7511"
        },
        {
          "label": "7513 - Truck/Utility Trailer Rentals",
          "value": "7513"
        },
        {
          "label": "2791 - Typesetting, Plate Making, and Related Services",
          "value": "2791"
        },
        {
          "label": "5978 - Typewriter Stores",
          "value": "5978"
        },
        {
          "label": "9405 - U.S. Federal Government Agencies or Departments",
          "value": "9405"
        },
        {
          "label": "5137 - Uniforms, Commercial Clothing",
          "value": "5137"
        },
        {
          "label": "5931 - Used Merchandise and Secondhand Stores",
          "value": "5931"
        },
        {
          "label": "4900 - Utilities",
          "value": "4900"
        },
        {
          "label": "5331 - Variety Stores",
          "value": "5331"
        },
        {
          "label": "0742 - Veterinary Services",
          "value": "0742"
        },
        {
          "label": "7993 - Video Amusement Game Supplies",
          "value": "7993"
        },
        {
          "label": "7994 - Video Game Arcades",
          "value": "7994"
        },
        {
          "label": "7841 - Video Tape Rental Stores",
          "value": "7841"
        },
        {
          "label": "8249 - Vocational/Trade Schools",
          "value": "8249"
        },
        {
          "label": "7631 - Watch/Jewelry Repair",
          "value": "7631"
        },
        {
          "label": "7692 - Welding Repair",
          "value": "7692"
        },
        {
          "label": "5300 - Wholesale Clubs",
          "value": "5300"
        },
        {
          "label": "5698 - Wig and Toupee Stores",
          "value": "5698"
        },
        {
          "label": "4829 - Wires, Money Orders",
          "value": "4829"
        },
        {
          "label": "5631 - Womens Accessory and Specialty Shops",
          "value": "5631"
        },
        {
          "label": "5621 - Womens Ready-To-Wear Stores",
          "value": "5621"
        },
        {
          "label": "5935 - Wrecking and Salvage Yards",
          "value": "5935"
        }
    ];

    const MAPPED_FIELD_NAMES = {
      legalBusinessName: "account_name",
      doingBusinessAsName: "doing_business_as_name",
      street: "street_address",
      city: "city",
      state: "state",
      zip: "zip_code",
      phone: "phone_number",
      supportPhone: "support_phone_number",
      tax_id: "tax_id",
      merchantCode: "merchant_category_code",
      urlProductDescription: "url_product_description",
      productDescription: "product_description",
      websiteUrl: "website_url",
      routingNumber: "routing_number_last4",
      transitNumber: "transit_number",
      accountNumber: "account_number_last4",
      institutionNumber: "institution_number",
      bankName: "bank_name"
    };

    Controllers.BankAccountBusinessInfoController = class BankAccountBusinessInfoController extends Controllers.BankAccountFormController {

        // @ngInject
        constructor($scope, $injector, $modalInstance, bankAccountType, UsersManager, CompaniesManager, PendingTasksManager,
                    RegexService, $http, $translate, $timeout, Enums, Stripe, AnalyticsService, OnboardingService, $q, _, DeviceService, MobileAppService) {
            super($scope, $injector, $modalInstance, UsersManager, CompaniesManager, MobileAppService, DeviceService);
            this.__objectType = 'BankAccountBusinessInfoController';
            this.$http = $http;
            this.Enums = Enums;
            this.Stripe = Stripe;
            this.$q = $q;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.AnalyticsService = AnalyticsService;
            this.$modalInstance = $modalInstance;
            this._ = _;
            this.PendingTasksManager = PendingTasksManager;
            this.OnboardingService = OnboardingService;

            const {
              VALID_ZIP,
              VALID_CANADAIAN_POSTAL_CODE,
              VALID_TAX_ID,
              VALID_TAX_CA_ID,
              VALID_EMAIL,
              ONLY_NUMBERS,
              NUMBERS_OR_ASTERISKS_AND_NUMBERS,
          } = RegexService.PATTERNS;

            this.useNewAddBankModals = true; // ['individual', 'sole_proprietorship', 'corp'].some(accountType => accountType === bankAccountType);
            this.isBusiness = bankAccountType === 'business' || (this.useNewAddBankModals && bankAccountType !== 'individual');
            this.bankAccountType = bankAccountType;
            this.isCanada = this.user.company.country === 'CA';
            this.requestSupportPhoneNumber = this.isCanada && bankAccountType !== 'individual';
            this.onShowHowToGetEinStep = !this.isCanada && bankAccountType !== 'individual';
            this.zipRegex = this.isCanada ? VALID_CANADAIAN_POSTAL_CODE : VALID_ZIP;
            this.phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
            this.emailRegex = VALID_EMAIL;
            this.tinEinRegex = this.isCanada ? VALID_TAX_CA_ID : VALID_TAX_ID;
            this.mccOptions = MCC_OPTIONS;
            this.user = UsersManager.getCurrUser();
            this.company = this.user.company;
            this.isMalkutUser = this.user.isMalkut();
            this.bankAccount = angular.extend({}, this.user.myBankAccount());
            this.lastError = null;
            this.requestInProgress = false;
            this.isEditMode = !_.isEmpty(this.bankAccount);
            this.enableTwoFactor = !this.isMalkutUser;
            this.stripeTokenRequired = false;
            this.showLegalNameExample = false;
            this.showEINExample = false;
            this.isInAppBrowser = DeviceService.isInAppBrowser();
            this.bankInfoNumberRegex = NUMBERS_OR_ASTERISKS_AND_NUMBERS;

            this.checkImageUrl = this.isCanada ?
              'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1719340212/system_web/check-bank-account-honeybook-canada-4.png' :
              '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1567269271/system_web/check.png';

            this.legalEntityImage = 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1652004062/honeybook_cdn/bank_account/legal_entity_name.png';
            this.EINImage = 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1652004050/honeybook_cdn/bank_account/EIN.png';
            this.IRSImage = 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1652004028/honeybook_cdn/bank_account/irs_image.png';

            this.STRIPE_TEST_ROUTE_NUM = '110000000';
            this.STRIPE_TEST_TRANSIT_NUM = '11000';
            this.STRIPE_TEST_BANK_NAME = 'Stripe Test Bank';

            this.initFieldsToFix(this.bankAccount);
            this.prefillBankAccount();
        }

        setLegalNameExampleShown(shouldShow) {
          this.showLegalNameExample = shouldShow;
        }

        setEInExampleShown(shouldShow) {
          this.showEINExample = shouldShow;
        }

        openBusinessStep() {
          this.onShowHowToGetEinStep = false;
        }

        isOnIndividualModal() {
          return this.bankAccountType === 'individual';
        }

        prefillBankAccount() {
            // if (true) { //test purposes
            //     this.bankAccount.name = 'TEST LEGAL BUSINESS NAME';
            //     this.bankAccount.doing_business_as_name = "TEST BUSINESS NAME";
            //     this.bankAccount.street_address = '539 Bryant St';
            //     this.bankAccount.city = "San Francisco";
            //     this.bankAccount.state = {abbreviation: "CA"};
            //     this.bankAccount.zip_code = "94107";
            //     this.bankAccount.phone_number = "4155917768";
            //     this.bankAccount.tax_id = "000000000";
            //     this.bankAccount.merchant_category_code = MCC_OPTIONS[0].label;
            //     this.bankAccount.url_product_description = "Test prod description";
            //     this.bankAccount.routing_number = "110000000";
            //     this.bankAccount.account_number = "000123456789";
            //     this.bankAccount.bank_name = "MY TEST BANK";
            //
            //     return;
            // }

            this.statement_descriptor = this.company.calculateStripeStatementDescr();
            this.statement_descriptor_tooltip = this.company.useCompanyNameForStatementDescr() ?
                                                this.$translate.instant('COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT._STATEMENT_DESC_COMPANY_') :
                                                this.$translate.instant('COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT._STATEMENT_DESC_OWNER_');

            if (!this.isEditMode) {
                this.preFilledFieldNames = [];

                if (this.isBusiness && !this.useNewAddBankModals) {
                  this.preFilledFieldNames.push("legalBusinessName", "doingBusinessAsName");
                  this.bankAccount.account_name = this.company.company_name;
                  this.bankAccount.doing_business_as_name = this.company.company_name;
                }

                if (this.isBusiness) {
                    if (this.company.street_address) {
                      this.bankAccount.street_address = this.company.street_address;
                        this.preFilledFieldNames.push("street");
                    }

                    if (this.company.city) {
                        this.bankAccount.city = this.company.city;
                        this.preFilledFieldNames.push("city");
                    }

                    if (this.company.zip_code) {
                        this.bankAccount.zip_code = this.company.zip_code;
                        this.preFilledFieldNames.push("zip");
                    }

                    if (this.company.phone_number) {
                        this.bankAccount.phone_number = this.company.phone_number;
                        this.preFilledFieldNames.push("phone");

                        if (this.requestSupportPhoneNumber) {
                            this.bankAccount.support_phone_number = this.company.phone_number;
                            this.preFilledFieldNames.push("supportPhone");
                        }
                    }

                    if (this.company.state) {
                        this.bankAccount.state = this.states.find(s => s.abbreviation === this.company.state);
                        this.preFilledFieldNames.push("state");
                    }
                }

                const suggestedMcc = MCC_OPTIONS.find(
                  opt => opt.value === this.getMccByCompanyType(this.company.company_type)
                );
                this.bankAccount.merchant_category_code = suggestedMcc ? suggestedMcc.label : null;

                if (this.company.website_url) {
                    if (this.isCanada) {
                        this.bankAccount.website_url = this.company.website_url;
                        this.preFilledFieldNames.push("websiteUrl");
                    } else {
                        this.bankAccount.url_product_description = this.company.website_url;
                        this.preFilledFieldNames.push("urlProductDescription");
                    }
                }

                if (suggestedMcc) {
                  this.preFilledFieldNames.push("merchantCode");
                }

            } else {
              angular.extend(this.bankAccount, {
                routing_number: this.bankAccount.routing_number_last4 ? `*****${this.bankAccount.routing_number_last4.substr(-4)}` : null,
                account_number: this.bankAccount.account_number_last4 ? `******${this.bankAccount.account_number_last4.substr(-4)}` : null,
                state: this.isBusiness ? this.states.find(s => s.abbreviation === this.bankAccount.state) : null
              });

              this.existingMccValue = this.bankAccount.merchant_category_code;
            }
        }

        updateStateInForm(stateName) {
            if (!stateName && angular.isObject(this.bankAccount.state) && this.bankAccount.state.abbreviation) {
                return;
            }
            if (!stateName) {
                stateName = this.bankAccount.state ? this.bankAccount.state : this.company.state;
            }
            this.bankAccount.state = this.states.find(s => s.abbreviation === stateName);
        }

        forceOpenDropdown(fieldName) {
          this.bankAccount.merchant_category_code = '';
          this.$timeout(() => {
            const element = document.getElementById(fieldName);
            const angularEl = angular.element(element);
            angularEl.focus();
          }, 0);
        }

        last4Digits(str) {
            return this.numbersOnly(str).substr(str.length - 4, 4);
        }

        numbersOnly(str) {
            return str.replace(/\D/g,'');
        }

        submit() {
            this.AnalyticsService.trackClick(
              this,
              'save bank account',
              { action: this.isEditMode ? 'update' : 'create'}
            );
            this.lastError = null;
            this.businessInfoForm.$setSubmitted();
            this.bankInfoForm.$setSubmitted();

            if (!this.isFormValid()) {
                this.AnalyticsService.track(this, "bank account form - submitted with errors");
                return;
            }

            this.requestInProgress = true;

            const changedFieldNames = this.getDirtyFieldNames();
            this.data = changedFieldNames.reduce((acc, fieldName) => {
              acc[MAPPED_FIELD_NAMES[fieldName]] = this.getFieldValue(fieldName);
              return acc;
            }, {
              type: this.isBusiness ? "Company" : "Individual",
              country: this.company.country
            });

            // if (!this.isEditMode && !this.data.doing_business_as_name) {
            //   this.data.doing_business_as_name =  this.data.account_name;
            // }

            if (!this.isEditMode) {
                this.data.company_structure = this.bankAccountType;
            }

            // For individual accounts, manually set account_name. If company name missing, set to doing business as name value
            if (!this.isBusiness) {
                this.data.account_name = this.bankAccount.account_name;
            }

            this.openTwoFactorAuth();
        }

        //submit - step 1
        openTwoFactorAuth() {
            if (this.enableTwoFactor) {
                this.showSMSVerification = true;
            } else {
                this.createAccount();
            }
        }

        //submit - step 2: callback for hb-two-factor-auth
        onCodeVerificationSet(code, justClose) {
            if (justClose) {
                this.showSMSVerification = false;
                this.requestInProgress = false;
            } else {
                this.createAccount(code);
            }
        }

        //submit - step 3
        createAccount(smsCode) {
            // Regardless of company processing gateway we are getting Stripe token to create backup bank method on backend
            if (this.stripeTokenRequired) {
                this.getTokenPromise().then((response) => {
                    if (response) {
                        this.data.token_id = response.token.id;
                        if (response.token.bank_account) {
                            this.bankAccount.routing_number = response.token.bank_account.routing_number;
                            this.data.routing_number_last4 = this.numbersOnly(response.token.bank_account.routing_number);
                            this.data.last4 = response.token.bank_account.last4;
                            this.data.country = response.token.bank_account.country;

                            if (response.token.bank_account.bank_name) {
                                this.bankAccount.bank_name = response.token.bank_account.bank_name;
                                this.data.bank_name = response.token.bank_account.bank_name;
                            }
                        }
                        this.AnalyticsService.track(this,this.AnalyticsService.analytics_events.stripe_token_created);
                        this.data._type = this.Enums.PaymentMethodTypes.bankAccount;

                        this.saveAccount(smsCode);
                    }
                }).catch((response) => {
                        // hide two factor auth
                        this.showSMSVerification = false;

                        if (response && response.error && response.error.message) {
                            this.lastError = response.error.message;
                        }
                        else if (response && response.data && response.data.error_message) {
                            this.lastError = response.data.error_message;
                        }
                        else {
                            this.lastError = "An error has occurred. If the problem persists, please contact us";
                        }
                        this.lastError = this.lastError.replace('support@stripe.com', 'concierge@honeybook.com');
                        this.lastError = this.lastError.replace('contact us via https://support.stripe.com/contact', 'contact us');
                        this.requestInProgress = false;

                        // Show internal error on Analytics if exists
                        var error_message = this.lastError;
                        if (response && response.message) {
                            error_message += response.message;
                        }
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.bank_account_update_error,
                            {
                                error_message: error_message
                            }
                        );

                        this.scrollFormToTop();
                    });
            } else {
                this.saveAccount(smsCode);
            }
        }

        getTokenPromise() {
            const deferred = this.$q.defer();
            const country = this.company.country;
            let routingNumber, currency;

            if (country === 'US') {
                routingNumber = this.numbersOnly(this.bankAccount.routing_number);
                currency = 'USD';
            }
            else if (country === 'CA') {
                routingNumber = this.numbersOnly(this.bankAccount.transit_number).concat(this.numbersOnly(this.bankAccount.institution_number));
                currency = 'CAD';
            }

            this.Stripe.then(stripeSdk => {
                const stripe = stripeSdk(this.user.stripe_key);
                stripe.createToken('bank_account', {
                    country: country,
                    routing_number: routingNumber,
                    account_number: this.bankAccount.account_number,
                    currency: currency
                }).then(response => {
                    if (response.error) {
                        deferred.reject(response);
                        return;
                    }
                    deferred.resolve(response);
                });
            });
            return deferred.promise;
        }

        saveAccount(smsCode) {

            var addBankAccountPromise = this.user.addBankAccount(this.data, this.company, smsCode);
            addBankAccountPromise
                .then(() => {
                    this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', (res) => {
                      this.$modalInstance.close('success');
                  });
                  this.AnalyticsService.trackSuccess(
                    this,
                    'bank account saved successfully',
                    {action: this.isEditMode ? 'update' : 'create'}
                    );

                    // hide two factor auth
                    this.showSMSVerification = false;
                }).catch((response) => {
                // hide two factor auth
                this.showSMSVerification = false;
                this.requestInProgress = false;

                if (response && response.data && response.data.error_message) {
                    this.lastError = response.data.error_message;
                }
                else {
                    this.lastError = "An error has occurred. If the problem persists, please contact us";
                }

                this.scrollFormToTop();
            });
        }


        isFormValid() {
         const isStateValid = this.isBusiness ? this.bankAccount.state : true;

          return this.businessInfoForm.$valid &&
                 this.bankInfoForm.$valid &&
                 isStateValid &&
                 this._.isEmpty(this.fieldsToFix);
        }

        getErrorMessage(fieldName, translationString) {
          if (this.fieldsToFix[fieldName]) {
            return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._MISSING_INFO_ERROR_MESSAGE_';
          }

          if (fieldName === 'state' && !this.bankAccount.state && this.businessInfoForm) {
            return 'FREQUENT_ERRORS._REQUIRED_';
          }

          return this.patternFieldErrorMessage(
            fieldName,
            'COMPANY_SETTING.BANK_ACCOUNT.BUSINESS_INFO_MODAL.' + translationString,
            this.businessInfoForm
          );
        }

        getBankErrorMessage(fieldName, translationString) {
          if (this.fieldsToFix[fieldName]) {
            return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._MISSING_INFO_ERROR_MESSAGE_';
          }
          return this.patternFieldErrorMessage(
            fieldName,
            'COMPANY_SETTING.BANK_ACCOUNT.BUSINESS_INFO_MODAL.' + translationString,
            this.bankInfoForm
          );
        }

        getNewFormErrorMessage(fieldName, translationString, form) {
          if (this.fieldsToFix[fieldName]) {
            return 'COMPANY_SETTING.BANK_ACCOUNT.COMMON_MODAL._MISSING_INFO_ERROR_MESSAGE_';
          }
          const missingErrorKey = 'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.MISSING.' + translationString;
          if (fieldName === 'state') {
            return missingErrorKey;
          }
          return this.patternFieldErrorMessage(
            fieldName,
            'COMPANY_SETTING.BANK_ACCOUNT.ADD_BANK_ACCOUNT.' + translationString,
            form,
            missingErrorKey
          );
        }

        getNewBankError(fieldName, translationString) {
          return this.getNewFormErrorMessage(fieldName, translationString, this.bankInfoForm);
        }

        getNewBusinessError(fieldName, translationString) {
          return this.getNewFormErrorMessage(fieldName, translationString, this.businessInfoForm);
        }

        getBankName() {
            if (this.bankAccount.routing_number === this.STRIPE_TEST_ROUTE_NUM || this.bankAccount.transit_number === this.STRIPE_TEST_TRANSIT_NUM ) {
                this.bankAccount.bank_name = this.STRIPE_TEST_BANK_NAME;
                return;
            }
            if (this.isCanada) {
                this.bankAccount.bank_name = this.Enums.CanadaInstitutionNumbers[this.bankAccount.institution_number];
            } else {
                var url =
                  "https://routingnumbers.herokuapp.com/api/data.json?callback=JSON_CALLBACK&rn=" + this.bankAccount.routing_number;
                this.$http
                  .jsonp(url)
                  .success((data) => this.bankAccount.bank_name = data.customer_name || "")
                  .error((data) => this.bankAccount.bank_name = "");
            }
        }

        onStateChange(stateOption) {
            this.setNonInputDirtyField("state");
            delete this.fieldsToFix["state"];
            this.bankAccount.state = stateOption;
        }

        onRoutingNumberBlur() {
          // don't run any validations on edit mode since we only have last 4 digets
          if (!this.bankInfoForm.routingNumber.$dirty) {
            return;
          }

          this.getBankName();
          this.bankInfoForm.routingNumber.$setValidity('pattern', true);

          // Make sure vendor enters both account and routing numbers on update
          if (this.isEditMode && !this.bankInfoForm.accountNumber.$dirty) {
              this.bankAccount.account_number = "";
          }

          this.stripeTokenRequired = true;
        }

        onTransitNumberBlur() {
            if (!this.bankInfoForm.transitNumber.$dirty) {
                return;
            }

            // Make sure vendor enters both account and routing numbers on update
            if (this.isEditMode && !this.bankInfoForm.accountNumber.$dirty) {
                this.bankAccount.account_number = "";
            }

            this.stripeTokenRequired = true;
        }

        onInstitutionNumberBlur() {
            if (!this.bankInfoForm.institutionNumber.$dirty) {
                return;
            }

            if (this.isEditMode && !this.bankInfoForm.accountNumber.$dirty) {
                this.bankAccount.account_number = "";
            }

            this.stripeTokenRequired = true;
            this.getBankName();
        }

        onAccountNumberBlur() {
            if (!this.bankInfoForm.accountNumber.$dirty) {
                return;
            }

            // Make sure vendor enters both account and routing numbers on update
            // Not relevant for CAD - routingNumber will be undefined
            if (this.isEditMode && this.bankInfoForm.routingNumber && !this.bankInfoForm.routingNumber.$dirty) {
                this.bankAccount.routing_number = "";
            }

            this.stripeTokenRequired = true;
        }

        analyticsEvent(eventName) {
          this.AnalyticsService.track(this, eventName);
        }

        scrollFormToTop() {
          // scroll container to top so error message is visible
          angular.element('#bank_account_business_info').animate({scrollTop: 0}, 200);
        }

        validateMcc() {
          const selectedMcc = MCC_OPTIONS.find(
            mccOpt => mccOpt.label === this.bankAccount.merchant_category_code
          );

          this.businessInfoForm.merchantCode.$setValidity('pattern', !!selectedMcc);

          if (this.isEditMode && this.existingMccValue && this.existingMccValue !== this.bankAccount.merchant_category_code) {
              this.setNonInputDirtyField('merchantCode');
          }
        }

        getDirtyFieldNames() {
          const nonInputOrPrefilledDirtyFields = Object.keys(this.nonInputDirtyFieldNames).concat(
              this.preFilledFieldNames || []
          );

          const businessInfoDirtyFields = this.getFormDirtyFieldNames(
            this.businessInfoForm,
            nonInputOrPrefilledDirtyFields
          );

          return this.getFormDirtyFieldNames(
            this.bankInfoForm,
            businessInfoDirtyFields
          );
        }

        setNonInputDirtyField(name) {
            this.nonInputDirtyFieldNames[name] = true;
        }

        getFieldValue(name) {
          switch(name) {
            case 'state':
              return this.bankAccount.state.abbreviation;
            case 'accountNumber':
              return this.last4Digits(this.bankAccount.account_number);
            case 'institutionNumber':
              return this.numbersOnly(this.bankAccount.institution_number);
            case 'transitNumber':
              return this.numbersOnly(this.bankAccount.transit_number);
            case 'routingNumber':
              return this.numbersOnly(this.bankAccount.routing_number);
            case 'phoneNumber':
              return this.numbersOnly(this.bankAccount.phone_number);
            case 'supportPhoneNumber':
              return this.numbersOnly(this.bankAccount.support_phone_number);
            case 'tax_id':
              return this.numbersOnly(this.bankAccount.tax_id);
            default:
              return this.bankAccount[MAPPED_FIELD_NAMES[name]];
          }
        }

        shouldShowStateError(entity, form) {
          return form.$submitted && !this[entity].state;
        }

        shouldShowError(form, fieldName, customValidation = false) {
          const field = form[fieldName];
          const isFieldToFix = this.fieldsToFix[fieldName];
          const showError = form.$submitted || (field && field.$touched) || isFieldToFix;
          if (customValidation) {
            return showError;
          }

          return showError && (field && (field.$invalid || isFieldToFix));
        }

        shouldShowBankError(fieldName, customValidation = false) {
          const shouldShow = this.shouldShowError(this.bankInfoForm, fieldName, customValidation);
          return shouldShow;
        }

        shouldShowBusinessError(fieldName, customValidation = false) {
          return this.shouldShowError(this.businessInfoForm, fieldName, customValidation);
        }

        onFieldFocus(fieldName) {
          if (this.fieldsToFix[fieldName]) {
            delete this.fieldsToFix[fieldName];
          }
        }

        shouldShowAllFieldsReqError() {
          return this.bankInfoForm.$submitted &&
                 (!this.bankInfoForm.$valid ||
                  !this.businessInfoForm.$valid ||
                  !this._.isEmpty(this.fieldsToFix)
                 );
        }

        getMccByCompanyType(type) {
          switch(type) {
            case 'photographer':
            case 'web_designer':
            case 'graphic_designer':
            case 'calligrapher':
            case 'visual_artist':
            case 'hand_lettering':
            case 'product_designer':
            case 'illustration':
              return '7333';
            case 'bakery':
            case 'desserts':
              return '5462';
            case 'musician':
              return '7929';
            case 'hair_design':
            case 'makeup_artist':
              return '7230';
            case 'catering':
            case 'caterer':
              return '5811';
            case 'business_consultant':
            case 'strategy':
              return '7392';
            case 'rental':
              return '7394';
            case 'florist':
              return '5992';
            case 'jewelry':
              return '5944';
            case 'publishing':
              return '2741';
            case 'cinematography':
              return '7829';
            case 'stationery':
              return '5111';
            case 'transportation':
              return '4789';
            case 'wellness':
            case 'coaching':
            case 'doula':
              return '7298';
            case 'accounting':
              return '8931';
            case 'agency':
              return '7361';
            case 'cleaning':
              return '7349';
            case 'freelancer':
            case 'virtual_assistant':
            case 'real_estate':
            case 'social_media':
              return '7399';
            case 'contractor':
              return '1520';
            case 'education':
              return '8299';
            case 'landscape':
              return '0780';
            default:
              return '8999';
          }
        }
    };

}());