(function () {
    'use strict';

    class ProjectDetailsDateLocationComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout, $translate, moment, _, DeviceService, CompaniesManager,
                    RescheduleProjectService, UsersManager, Constants, AppStates, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsDate';

            this.$timeout = $timeout;
            this.$translate = $translate;
            this.moment = moment;
            this._ = _;
            this.isMobileDevice = DeviceService.isIOS() || DeviceService.isAndroid();
            this.company = CompaniesManager.getCurrCompany();
            this.RescheduleProjectService = RescheduleProjectService;
            this.shouldShowDateConflicts = this.company.isShowDateConflictsAutomationOn();
            this.currentUser = UsersManager.getCurrUser();
            this.calendarAvailabilityTypes = Constants.CalendarAvailabilityTypes;
            this.AppStates = AppStates;

            this.onLocationChangeFn = this.onGoogleLocationChange.bind(this);
            this.onLocationFieldChangeFn = this.onLocationFieldChange.bind(this);

            this.addressAutocompleteEnabled = false;
            StatsigService.isGateEnabled('location-iq-address-autocomplete').then(isGateEnabled => {
                this.addressAutocompleteEnabled = isGateEnabled;
            });

        }

        $onInit() {
            this.lastDateLabel = this.dateLabel;
            this.lastLocationLabel = this.locationLabel;

            this.workspace = this.project ? this.project.workspaces.find((workspace)=> workspace._id === this.workspaceId) : null;
            this.userCanReschedule = this.RescheduleProjectService.userCanReschedule(this.currentUser, this.workspace);

            const key = this.availabilityTypeKey || 'busy';
            this.availabilityType = this.calendarAvailabilityTypes.find(availabilityType => availabilityType.key === key);
        }

        $onChanges(changes) {
            if(changes.locationLabel) {
                this.lastLocationLabel = this.locationLabel;
            }
            else if(changes.dateLabel) {
                this.lastDateLabel = this.dateLabel;
            }
        }

        openRescheduleProjectModal() {
            this.RescheduleProjectService.openRescheduleModal(this.project, this.workspaceId, false, 'project_details');
        }

        onLocationFieldChange() {
            return this.onLocationChange && this.onLocationChange({changes: {location: this.location}});
        }

        onAvailabilityFieldChange() {
            return this.onAvailabilityChange && this.onAvailabilityChange({changes: {availability: this.availabilityType}});
        }

        setAvailabilityType(item) {
            return this.onAvailabilityChange && this.onAvailabilityChange({changes: {availability: item.key}});
        }

        onGoogleLocationChange(geocodeResult) {
            let location;
            if (geocodeResult.formatted_address) {
                location = geocodeResult.formatted_address.includes(geocodeResult.name) ?
                    geocodeResult.formatted_address :
                    `${geocodeResult.name}, ${geocodeResult.formatted_address}`;
            } else {
                location = geocodeResult.display_name.includes(geocodeResult.display_place) ?
                    geocodeResult.display_name :
                    `${geocodeResult.display_place}, ${geocodeResult.display_name}`;
            }

            this.location = location;
            this.onLocationFieldChange();
        }

        onDateLabelChange() {
            if(this.dateLabel) {
                const updated = this.onDateTimeChange({changes: {date_label: this.dateLabel}});
                if(updated) {
                    this.showDateError = false;
                }
                else {
                    this.dateLabel = this.lastDateLabel;
                    this.dateLabelError = this.$translate.instant('WORKSPACE.PROJECT_DETAILS._LABEL_ERROR_', {labelType: 'date'});
                    this.showDateError = true;
                }

            } else {
                this.dateLabel = this.lastDateLabel;
            }
        }

        onLocationLabelChange() {
            if(this.locationLabel) {
                const updated = this.onLocationChange({changes: {location_label: this.locationLabel}});
                if(updated) {
                    this.showLocationError = false;
                }
                else {
                    this.locationLabel = this.lastLocationLabel;
                    this.locationLabelError = this.$translate.instant('WORKSPACE.PROJECT_DETAILS._LABEL_ERROR_', {labelType: 'location'});
                    this.showLocationError = true;
                }
            } else {
                this.locationLabel = this.lastLocationLabel;
            }
        }

        addLocation() {
            this.onAddLocation();
        }

        deleteLocation() {
            return this.onDateTimeChange({changes: {location_label: null, location: null}});
        }

        dateTranslation() {
            return `WORKSPACE.PROJECT_DETAILS.${
                this.isConsultantABTest ? '_START_DATE_' : '_PROJECT_DATE_'
            }`;
        }

    }

    Components.ProjectDetailsDateLocation = {
        bindings: {
            dateLabel: '<?',
            startDate: '<',
            startTime: '<?',
            endDate: '<?',
            endTime: '<?',
            editMode: '<?',
            onDateTimeChange: '&?',
            showLocation: '<?',
            locationLabel: '<?',
            location: '<',
            availabilityTypeKey: '<',
            onLocationChange: '&?',
            onAvailabilityChange: '&?',
            onAddLocation: '&?',
            onDeleteDate: '&?',
            showDateDeleteButton: '=',
            showLocationDeleteButton: '=',
            analyticsEventsSource: '=',
            projectId: '<?',
            onStartDateFocusCallback: '&?',
            project: '<',
            workspaceId: '<',
            branded: '<?',
            showAvailability: '=',
            isConsultantABTest: '<?'
        },

        controller: ProjectDetailsDateLocationComponent,
        name: 'hbProjectDetailsDateLocation',
        templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_date_location_component_template.html'
    };

}());
