/**
 * Created by sara on 2/8/16.
 */

(function () {
    'use strict';

    // @ngInject
    function TOSChangedControllerCtor($scope, $injector, $modalInstance, UsersManager, AnalyticsService, TOSManager, $window, text) {
        this.constructor.$super.call(this, $scope, $injector);
        AnalyticsService.trackPageView(this, 'TOS Modal');
        this.$modalInstance = $modalInstance;
        this.__objectType = 'TOSChangedController';
        this.UsersManager = UsersManager;
        this.TOSManager = TOSManager;
        this.$window = $window;
        this.confirmInProgress = false;
        this.text = text;
    }

    Controllers.TOSChangedController = Class(Controllers.BaseController, {
        constructor: TOSChangedControllerCtor,

        confirm: function confirm() {

            if (this.confirmInProgress) {
                return;
            }

            this.confirmInProgress = true;

            this.TOSManager.acceptTOS(this.UsersManager.getCurrUser(), 'web modal').then(function success() {
                this.$modalInstance.close();
            }.bind(this))
            .finally(function finaly() {
                this.confirmInProgress = false;
            }.bind(this));
        }
    });
}());