
Directives.HistoryBackDirective = function HistoryBackDirective() {

    // @ngInject
    function HistoryBackControllerFunc($element, $window, $scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'HistoryBackController';

        $element.bind('click', function () {
            $window.history.back();
        });
    }

    var HistoryBackController = Class(Controllers.BaseController, {
        constructor: HistoryBackControllerFunc
    });



    return {
        controller: HistoryBackController,
        bindToController: true
    };
};
