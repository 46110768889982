/**
 * Created by dvircohen on 16/05/2017.
 */
(function () {
    'use strict';

    Directives.EmailAttachmentViewDirective = function EmailAttachmentViewDirective() {

        // @ngInject
        function EmailAttachmentViewDirectiveControllerCtor($scope, $injector, AnalyticsService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'EmailAttachmentViewDirectiveController';

            // TODO (Alon) - attach sending to parent
            this.isSending = false;

        }

        var EmailAttachmentViewDirectiveController = Class(Controllers.BaseController, {
            constructor: EmailAttachmentViewDirectiveControllerCtor,

            remove: function remove(index) {
                if(this.onRemove) {
                    this.onRemove({index: index});
                }
            },
            getIconForFile: function getIconForFile(filename) {

              var url = "http://res.cloudinary.com/honeybook/image/upload/v1502008386/system_web/rebranding/file-default.svg";

              if(filename) {
                if (filename.match(/xls$/) || filename.match(/xlsx$/)) {
                  url = "http://res.cloudinary.com/honeybook/image/upload/v1501764251/system_web/rebranding/file_icon_xls.svg";
                }
                else if (filename.match(/doc$/) || filename.match(/docx$/)) {
                  url = "http://res.cloudinary.com/honeybook/image/upload/v1501764251/system_web/rebranding/file_icon_doc.svg";
                }
                else if (filename.match(/pdf$/)) {
                  url = "http://res.cloudinary.com/honeybook/image/upload/v1501764252/system_web/rebranding/file_icon_pdf.svg";
                }
              }

              return url;
            },
            isImageTypeOfFile: function isImageTypeOfFile(file) {
                var type = file.type || file.file_type;
                if (type) {
                    return (type.indexOf('image') > -1);
                } else {
                    return false;
                }
            }

        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/emails/attachments/email_attachment_view_directive_template.html',
            controller: EmailAttachmentViewDirectiveController,
            controllerAs: 'attachmentVm',
            bindToController: true,
            scope: {
                file: '<',
                attachmentType: '@',
                showRemove: '=',
                onRemove: '&'
            }
        };
    };

}());

