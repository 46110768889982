/**
 * Created by dvircohen on 12/07/2017.
 */
// @ngInject
Classes.AgreementValuesSimulatedFileStrategy = function(moment, AgreementValuesFileStrategy, RescheduleProjectService) {

    const AgreementValuesSimulatedFileStrategy = class AgreementValuesSimulatedFileStrategy extends AgreementValuesFileStrategy {

        constructor(agreementParent) {
            super(agreementParent);
            this.project = RescheduleProjectService.getProject();
        }

        getEventDate() {
            return this.project && this.formatDate(this.project.event_date) || 'TBD';
        }

        getEventEndDate() {
            return this.project && this.formatDate(this.project.event_end_date) || '';
        }

        getEventTimeStart() {
            return this.project && this.project.event_time_start || '';
        }

        getEventTimeEnd() {
            return this.project && this.project.event_time_end || '';
        }
    };

    return AgreementValuesSimulatedFileStrategy;
};