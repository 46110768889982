Directives.AppleLoginDirective = function AppleLoginDirective() {

    // @ngInject
    function AppleLoginDirectiveControllerFunc($scope, $injector, UsersManager, AnalyticsService, FirebaseAPIService, Routes) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AppleLoginDirectiveController';

        this.UsersManager = UsersManager;
        this.FirebaseAPIService = FirebaseAPIService;
        this.AnalyticsService = AnalyticsService;
        this.Routes = Routes;
    }

    var AppleLoginDirectiveController = Class(Controllers.BaseController, {
        constructor: AppleLoginDirectiveControllerFunc,

        login: function login() {
            var self = this;
            var apple = 'apple';
            
            this.loggingIn = true;
            this.FirebaseAPIService.signIn(apple).then(
                function success(result) {
                    self.UsersManager.loginWithApple(self.loginParams.user, result).then(
                        function success(resp){
                            self.loginParams.loginSuccess(resp, true);
                            self.AnalyticsService.trackSuccess(self, self.AnalyticsService.analytics_events.login_with_apple);
                        },

                        function error(resp){
                            self.loginParams.loginError(resp);
                            self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.login_with_apple, resp);
                            self.loggingIn = false;
                        }
                    );
                },
                function failure(resp) {
                    this.loggingIn = false;
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login_with_apple, resp);
                }.bind(this)
            );
        },
    });

    return {
        templateUrl: 'angular/app/modules/common/ui_components/apple_login/apple_login_directive_template.html',
        controller: AppleLoginDirectiveController,
        controllerAs: 'appleVm',
        scope: {
            loginParams: "=loginParams",
            source: '@loginSource'
        },
        bindToController: true
    };
};