/**
 * Created by brianschroer on 2/2/16.
 */

(function () {
    'use strict';

    // @ngInject
    Services.drop = function drop($window) {
        return $window.Drop;
    };


}());