(function () {
    'use strict';

    // @ngInject
    function BrandingInspirationModalCtor($scope, $injector, AnalyticsService, $modalInstance, $interval) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrandingInspirationModal';
        AnalyticsService.trackPageView(this, 'BrandingInspirationModal');

        this.modalInstance = $modalInstance;
        this.$interval = $interval;
        this._init();

        $scope.$on('$destroy', function () {
            this.stopInterval();
        }.bind(this));

        this.CONST = {
            _NEXT_ : 'next',
            _BEFORE_ : 'before',
            _ACTIVE_: 'active'
        }
    }

    Controllers.BrandingInspirationModalController = Class(Controllers.BaseController, {
        constructor: BrandingInspirationModalCtor,

        _init: function _init() {

            this.slider = [
                {image: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1529005849/honeybook_cdn/company_settings/branding/inspiration_modal/packshot-01b_3x.jpg', _id: 'slider-image--01'},
                {image: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1529005849/honeybook_cdn/company_settings/branding/inspiration_modal/packshot-02b_3x.jpg', _id: 'slider-image--02'},
                {image: '//res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1529005849/honeybook_cdn/company_settings/branding/inspiration_modal/packshot-03b_3x.jpg', _id: 'slider-image--03'}
            ];

            this.startSlider();
        },

        startSlider: function startSlider () {
            if ( angular.isDefined(this.activeSlider) ) return;
            this.activeSlider = this.$interval(function () { this.activateSlideshow(this.CONST._NEXT_); }.bind(this), 3000);
        },

        activateSlideshow: function activateSlideshow(sliderDirection) {
            var imagesList = angular.element('.slider-js');
            var active = imagesList.children('.active');
            var fistImage = imagesList.children('li').eq(0);
            var lastImage = imagesList.children('li').last();
            var nextImage = active.next();
            var prevImage = active.prev();

            active.removeClass(this.CONST._ACTIVE_);

            switch (sliderDirection){
                case this.CONST._NEXT_ :
                    nextImage.length ? nextImage.addClass(this.CONST._ACTIVE_) : fistImage.addClass(this.CONST._ACTIVE_);
                    break;
                case this.CONST._BEFORE_:
                    prevImage.length ? prevImage.addClass(this.CONST._ACTIVE_) : lastImage.addClass(this.CONST._ACTIVE_);
                    break;
            }
        },

        close: function close() {
            this.modalInstance.close();
        },

        stopInterval: function stopInterval() {
            this.$interval.cancel(this.activeSlider);
        },

        sliderControlBefore: function sliderControlBefore() {
            this.stopInterval();
            this.activateSlideshow(this.CONST._BEFORE_);
        },

        sliderControlNext: function sliderControlNext() {
            this.stopInterval();
            this.activateSlideshow(this.CONST._NEXT_);
        }


    });
}());
