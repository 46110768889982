(function () {
    'use strict';

    class PromotionOfferController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, ModalService, _, UIUtils, QuestionsStateService, $timeout) {
            super($scope, $injector);
            this.__objectType = 'PromotionOfferController';

            this.ModalService = ModalService;
            this._ = _;
            this.UIUtils = UIUtils;
            this.QuestionsStateService = QuestionsStateService;
            this.$timeout = $timeout;

            this.imageRatio = 1.6;
            this.maxWidth = 500;

            this.item = {};

            this.register(this.model, 'success', function success() {

                // we need to wait for the digest cycle to finish,
                // so all child models will update
                this.$scope.$applyAsync(function () {
                    this.buildViewModel();
                    this.firstLoad = false;
                }.bind(this));

            }.bind(this));
        }

        /////////////////////////////
		// Lifecycle
		////////////////////////////

        $onChanges(changes) {
            this.buildViewModel();
        }

        $onInit() {
            this.$scope.$on('parentComponentFocusChanged', function($event, isFocused) {
                if(isFocused === false) {
                    this.showSettings = false;
                }
            }.bind(this));

			this.QuestionsStateService.on("CheckQuestionsErrors", this.onValidate.bind(this));

            // set text color
            const textColor = (this.block && this.block.file_block_style) ? this.block.file_block_style.color : '#ffffff';
            this.setTextColor(textColor);

        }

		buildViewModel() {

			this.item = this.proposal.package_services[0];

			// set discount flag
			this.enableDiscount = !!this.item.discount_type;

			// set font size
			var fontSize = '20px';
			this.style = {
				fontSize: fontSize
			};
		}

		/////////////////////////////
		// Image editing
		////////////////////////////

		onChooseImage(service) {
			if (service.package_service_image) {
				this.editImage(service);
			} else {
				this.addImage(service);
			}
		}

		addImage(service) {
			this.changeImage('MEDIA_MODAL.TITLE._ADD_IMAGE_','Upload', service);
		}

		editImage(service) {
			this.changeImage('MEDIA_MODAL.TITLE._EDIT_IMAGE_', 'Crop', service);
		}

		changeImage(modalTitle, defaultTab, service) {
			return this.ModalService.openAttachAssetModal(true, true, true,
				defaultTab, false, false, service.package_service_image, this.imageRatio,
				false, false, 'image/*', '', '', modalTitle)
				.then(function success(image){
					this.saveImage(angular.copy(image), service);
				}.bind(this))
				.finally(function () {
				}.bind(this));
		}

		saveImage (imageModel, service) {
			service.package_service_image = null;
			service.updateServiceImage(imageModel);
		}

		/////////////////////////////
		// Form update & validation
		////////////////////////////

		onValidate() {
			this.errors = {};

			// validate amount
			this.validateAmountField();

			// validate min / max
			this.validateMinMaxField();

			// validate discount
			this.validateDiscountField();

			if(Object.keys(this.errors).length > 0) {
				this.$timeout(function() {
					this.openSettings();
				}.bind(this), 300);
			}
		}

		validateAmountField() {
			if(this.item.price <= 0) {
				this.errors['amount'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._AMOUNT_';
			}
		}

		validateMinMaxField() {
			if(this.proposal.enable_amount_limit) {
				if(this.proposal.amount_limit_min <= 0) {
					this.errors['amount_limit'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._LIMIT_MIN_';
				} else if(this.proposal.amount_limit_max <= this.proposal.amount_limit_min) {
					this.errors['amount_limit'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._LIMIT_MAX_';
				} else if(this.item.price) {
					if(this.item.price < this.proposal.amount_limit_min) {
						this.errors['amount_limit'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._LIMIT_MIN_AND_AMOUNT_';
					} else if(this.item.price > this.proposal.amount_limit_max) {
						this.errors['amount_limit'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._LIMIT_MAX_AND_AMOUNT_';
					}
				}
			}
		}

		validateDiscountField() {
			if(!!this.item.discount_type) {
				if(!this.item.discount || this.item.discount < 0) {
					this.errors['discount'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._DISCOUNT_';
				} else if (this.item.discount_type === 'relative' && (this.item.discount < 1 || this.item.discount > 100)) {
					this.errors['discount'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._RELATIVE_DISCOUNT_';
				} else if (this.item.discount_type === 'absolute' && this.item.discount > this.item.price) {
					this.errors['discount'] = 'DYNAMIC_CONTENT.GIFT_CARD.SETTINGS.ERRORS._ABSOLUTE_DISCOUNT_';
				}
			}
		}

        toggleSettings($event) {
            this.showSettings = !this.showSettings;
            if(this.showSettings) {
                this.$scope.$emit('childComponentFocusChanged', true);
            }

            // this._scrollPageIfNeeded($event);
        }

		openSettings($event) {
			if(!this.showSettings) {
				this.showSettings = true;
				if(this.showSettings) {
					this.$scope.$emit('childComponentFocusChanged', true);
				}
			}
		}

        digitsOnly($event, decimalPrecision, preventDecimals, allowNegative) {
            this.UIUtils.digitsOnly($event, decimalPrecision, preventDecimals, allowNegative);
        }

        calculatePrice(override, service) {
        	service.price_per_unit = service.price;
            service.calculatePrice(override);
            service.updateService();
        }

        updateMinMax() {
            this.onProposalChange(['amount_limit_min', 'amount_limit_max']);
        }

        updateService(service) {
            service.updateService();
        }

        setTextColor(color) {
            this.textStyle = {
                color: color
            };
        }

        updateTextColor(color) {
            this.block.updateBlock(['file_block_style']);
        }

        /* Color picker */
        onTextColorChange(color) {
            this.setTextColor(color);
            this.updateTextColor();
            // this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block, { field: 'background color', block_type: "text"});
        }

        onProposalChange(fields) {
            const data = fields.reduce((result, field) => {
                result[field] = this.proposal[field];
                return result;
            }, {});
            return this.proposal.updateProposalOnBlock(data);
        }

        onEnableCustomAmountChange(service) {
			let fields = ['enable_custom_amount', 'enable_amount_limit'];

			// if client can customize amount,
			// we force the discount type to be relative.
			// This is also happening on the server.
			//
			// Why? For example, if the client can customize the amount
			// and the discount is a fixed $50, he can in theory put $50
			// in the custom amount and get it for free.
			if(this.proposal.enable_custom_amount) {
                service.discount_type = 'relative';
            }

            // update limit flag as well
			this.proposal.enable_amount_limit = this.proposal.enable_custom_amount;

			// rest limits when toggling on / off
			fields = this.resetLimitsIfNeeded(fields);

            this.onProposalChange(fields);
        }

        onEnableAmountLimitChange() {
            let fields = ['enable_amount_limit'];

			// rest limits when toggling on / off
			fields = this.resetLimitsIfNeeded(fields);

            this.onProposalChange(fields);
        }

        resetLimitsIfNeeded(fields) {
			if(!this.proposal.enable_amount_limit) {
				this.proposal.amount_limit_min = null;
				this.proposal.amount_limit_max = null;
			} else if (this.item.price && this.item.price > 0) {
				this.proposal.amount_limit_min = this.item.price / 2;
				this.proposal.amount_limit_max = this.item.price * 3;
			}
			return fields.concat(['amount_limit_min', 'amount_limit_max']);
		}

        onEnableDiscountChange(service) {
            if(this.enableDiscount) {
                service.discount_type = 'relative';
            } else {
                service.discount_type = null;
            }
            service.updateService();
        }

        updateDiscountType(service, discountType) {
            service.discount_type = discountType;
            service.updateService();
        }

    }

    Components.PromotionOfferComponent = {
        bindings: {
            proposal: '<',
            company: '<',
            model: '<',
            block: '<'
        },
        transclude: true,
        controller: PromotionOfferController,
        name: 'hbSessionCard',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/promotion_offer/promotion_offer_component.html',
    };

}());