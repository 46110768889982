Components.SignatureDropdown = {
    bindings: {
      analyticsEventValue: '@',
        analyticsEventScope: '=',
      onAddUser: '&',
      onSelectUser: '&',
      users: '&',
    },
    name: 'hbSignatureDropdown',
    templateUrl: 'angular/app/modules/core/features/signatures/signature_dropdown.html',
    // @ngInject
    controller: function SignatureDropdownController($scope, $element, $attrs) {
        this.$onInit = function() {
            // Only show the add button if there's an add callback.
            this.showAddButton = 'onAddUser' in $attrs;
        };

        this.addUser = function(user) {
            this.onAddUser();
        };

        this.selectUser = function(user) {
            this.onSelectUser({user: user}) ;
        };
    },
};
