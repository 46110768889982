
// @ngInject

Models.UsersCollection = function UsersCollection(BaseCollection) {

    var UsersCollection = Class(BaseCollection, {

            constructor: function constructor(){
                UsersCollection.$super.call(this);
                this._collection.__className = 'UsersCollection';
                this._collection.__childsType = 'UserModel';
                return this._collection;
            }
        }
    );

    return UsersCollection;
};
