(function () {
    "use strict";

    // @ngInject
    function ContactFormExplanationVideoModalControllerCtor(
        $scope, 
        $injector, 
        $modalInstance,
        $state,
        $window,
        $,
        $sce,
        AnalyticsService,
        fileType
    ) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ContactFormExplanationVideoModalController';

        this.$ = $;
        this.$sce = $sce;
        this.modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.fileType = fileType;

        this.contactFormVideo = { 
            videoTag: 'app/contactform/contact-form-explanation-mweb', 
            title: '', 
            elementPlayerId: "contactFormExplainVideo" 
        };

        this.giftCardVideo = { 
            videoTag: 'giftcard/gift-card-mweb-intro.mp4', 
            title: '', 
            elementPlayerId: "giftCardExplainVideo" 
        };

        this.video = this.fileType === 'contactForm' ? this.contactFormVideo : this.giftCardVideo;
    }

    Controllers.ContactFormExplanationVideoModalController = Class(Controllers.BaseController, {

        constructor: ContactFormExplanationVideoModalControllerCtor,

        getVideoHtml: function getVideoHtml(video) {
            var currVideoHtml = this.$.cloudinary.videoTag(video.videoTag, {
                autoplay: true,
                loop: false,
                width: 350,
                secure: true,
                muted: true,
                poster: false,
                playsinline: true,
                fallback_content: "Your browser does not support HTML5 video tags",
                id: video.elementPlayerId }).toHtml();
            return this.$sce.trustAsHtml(currVideoHtml);
        },

        videoDidComplete: function videoDidComplete(currVideo) {
            return currVideo.currentTime/currVideo.duration === 1;
        },

        close: function close() {
            var currVideo = document.getElementById(this.video.elementPlayerId);
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.close_contact_form_demo_video,
                { is_completed: this.videoDidComplete(currVideo) }
            );
            // Remove this video instance so that old instance doesn't appear 
            // to flicker if they reopen the video modal.
            currVideo.parentNode.removeChild(currVideo);

            this.modalInstance.close();
        },

    })
})();