/**
 * Created by dvircohen on 21/09/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function PackageTemplateEmptyStateControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, $state, AppStates) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PackageTemplateEmptyStateController';
        AnalyticsService.trackPageView(this, 'PackageTemplateEmptyStateView');

        this.TemplatesViewService = TemplatesViewService;
        this.$state = $state;
        this.AppStates = AppStates;
    }

    Controllers.PackageTemplateEmptyStateController = Class(Controllers.BaseController, {
        constructor: PackageTemplateEmptyStateControllerCtor
    });
}());

