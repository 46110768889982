(function () {
    'use strict';

    // @ngInject
    function ConfirmDeleteStageControllerCtor ($scope, $injector, $modalInstance, AnalyticsService, stageToMoveWorkspacesTo) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfirmDeleteStageController';
        AnalyticsService.trackPageView(this, 'View');

        this.$modalInstance = $modalInstance;
        this.stageToMoveWorkspacesTo = stageToMoveWorkspacesTo;
    }

    Controllers.ConfirmDeleteStageController = Class(Controllers.BaseController, {
        constructor: ConfirmDeleteStageControllerCtor,

        proceed: function proceed(result) {
            this.$modalInstance.close(result);
        }

    });
}());
