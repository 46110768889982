(function () {
    "use strict";

    // @ngInject
    function AuthServiceCtor($state, AppStates, AppConfigService, AnalyticsService, UsersManager, WebPNService, StatsigService, $window) {
        this.$state = $state;
        this.$window = $window;
        this.AppStates = AppStates;
        this.AppConfigService = AppConfigService;
        this.AnalyticsService = AnalyticsService;
        this.StatsigService = StatsigService;
        this.UsersManager = UsersManager;
        this.WebPNService = WebPNService;
    }

    Services.AuthService = Class(function () {

        return {

            constructor: AuthServiceCtor,

            logout: function logout() {
                this.UsersManager.logout().then(function afterLogout() {
                    this.WebPNService.unsubscribe();
                    this.AnalyticsService.unidentify();
                    this.StatsigService.unidentify();
                    // cleanup - remove copied block from localstorage
                    this.$window.localStorage.removeItem('CLIP');
                    this.$state.go(this.AppStates.root_core_login);
                }.bind(this));
            },
            proLogoutAsMember: function proLogoutAsMember() {
                return this.UsersManager.proLogoutAsVendor().then(function () {
                    var currUser = this.UsersManager.getCurrUser();
                    this.AnalyticsService.unidentify();
                    this.AnalyticsService.identify(currUser);
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.client_onsite_logout);
                    this.$state.go(this.AppStates.root_core_navigation_home);
                }.bind(this));
            }
        };
    });
}());
