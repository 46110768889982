(function () {
    "use strict";

    // @ngInject
    function ServiceAutoCompleteResultsControllerCtor($scope, $injector, _) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ServiceAutoCompleteResultsController';

        this._ = _;
    }

    var ServiceAutoCompleteResultsController = Class(Controllers.BaseController, {
        constructor: ServiceAutoCompleteResultsControllerCtor,

        onItemClick: function onItemClick(item) {
            this.onItemSelected({item: item});
        },

        isService: function isService(item) {
            return this._isType(item, 'service');
        },

        isPackage: function isPackage(item) {
            return this._isType(item, 'package');
        },

        _isType: function _isType(item, type) {
            return item.type === type;
        }
    });

    Components.ServiceAutoCompleteResults = {
        bindings: {
            items: '<',
            isInPackage: '<',
            onItemSelected: '&',
            analyticsSource: '@'
        },
        controller: ServiceAutoCompleteResultsController,
        controllerAs: 'serviceAutoCompleteResultsVm',
        name: 'hbServiceAutoCompleteResults',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/auto_complete/service_auto_complete_results_directive_template.html'
    };

}());