(function () {
    "use strict";


    // @ngInject
    function ReportsManagerCtor(APIService, Routes, $q, moment, UsersManager, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.moment = moment;
        this.UsersManager = UsersManager;
    }


    Services.ReportsManager = Class(Services.BaseModelManager, {

        constructor: ReportsManagerCtor,

        getUsersAccountTransactions: function getUsersAccountTransactions(userId, source){

            var deferred = this.$q.defer();

            userId = userId || this.UsersManager.getCurrUser()._id;
            source = source || 'unknown';
            var params = {};
            var url = this.Routes.v2_users_account_transactions_path(userId);
            params.source = source;
            this.apiFetch(url, params).then(
                function success(resp){
                    deferred.resolve(resp);
                },
                function error(resp){
                    deferred.reject(resp);
                }
            );

            return deferred.promise;
        },

        getUserBookings: function getUserBookings(userId, source){

            var deferred = this.$q.defer();

            userId = userId || this.UsersManager.getCurrUser()._id;
            source = source || 'unknown';
            var params = {};
            var url = this.Routes.v2_user_bookings_path(userId);
            params.source = source;
            this.apiFetch(url, params).then(
                function success(resp){
                    deferred.resolve(resp);
                },
                function error(resp){
                    deferred.reject(resp);
                }
            );

            return deferred.promise;
        },

        getReportsAnalytics: function getReportsAnalytics(userId, source, options){

            var self = this;
            var deferred = this.$q.defer();

            userId = userId || this.UsersManager.getCurrUser()._id;
            source = source || 'unknown';
            var params = {};
            var url = this.Routes.v2_user_reports_analytics_path(userId);
            params.source = source;
            this.apiFetch(url, params).then(
                function success(resp){
                    if (options && options.rawData) {
                        deferred.resolve(resp);
                        return;
                    }

                    var stats = resp.data;
                    stats = self.addMissingMonthsToStats(stats);
                    resp.data = self.splitStatsToYears(stats);
                    deferred.resolve(resp);
                },

                function error(resp){
                    deferred.reject(resp);
                }
            );

            return deferred.promise;
        },

        getReportsPayments: function getReportsPayments(userId, isMinimal, fromDate, toDate) {
            userId = userId || this.UsersManager.getCurrUser()._id;
            var params = {};
            if (isMinimal) {
                params.minimal_response = true;
            }
            if (fromDate) {
                params.from_date = this.moment(fromDate).format("YYYY-MM-DD");
            }
            if (toDate) {
                params.to_date = this.moment(toDate).format("YYYY-MM-DD");
            }
            var url = this.Routes.v2_user_reports_payments_path(userId);
            return this.apiFetch(url, params);
        },

        getReportsCalendar: function getReportsCalendar(userId, fromDate, toDate) {
            userId = userId || this.UsersManager.getCurrUser()._id;
            var params = {};
            if (fromDate) {
                params.from_date = this.moment(fromDate).format("YYYY-MM-DD");
            }
            if (toDate) {
                params.to_date = this.moment(toDate).format("YYYY-MM-DD");
            }
            var url = this.Routes.v2_user_reports_calendar_path(userId);
            return this.apiFetch(url, params);
        },

        getProfitLossReport: function getProfitLossReport(start_date,end_date) {
            var userId = this.UsersManager.getCurrUser()._id;

            var url = this.Routes.v2_user_profit_loss_path(userId);
            var data = {from_date: start_date, to_date: end_date};
            return this.apiFetch(url, data);
        },

        getLeadsReportTotals: function getLeadsReportTotals(userId, startDate, endDate) {
            userId = userId || this.UsersManager.getCurrUser()._id;
            var url = this.Routes.v2_get_leads_report_by_date_path(userId);
            var data = {
                start_date: startDate,
                end_date: endDate
            };
            return this.apiFetch(url, data);
        },

        getLeadsReport: function getLeadsReport(userId, startDate, endDate) {
            userId = userId || this.UsersManager.getCurrUser()._id;
            var url = this.Routes.v2_get_new_leads_reports_by_date_path(userId);
            var data = {
                start_date: startDate,
                end_date: endDate
            };
            return this.apiFetch(url, data);
        },

        addMissingMonthsToStats: function addMissingMonthsToStats(stats){
            var date, expected_month, index, month, month_name, new_period, numberOfPeriods, period_entries, prev_date, reversed, year;
            period_entries = stats.period_entries;
            reversed = period_entries.reverse();
            if (reversed) {
                prev_date = null;
                index = 0;
                numberOfPeriods = reversed.length;
                while (index < numberOfPeriods) {
                    month = reversed[index];
                    date = new Date(month.timestamp);
                    if (!prev_date || isNaN(prev_date)) {
                        prev_date = date;
                        index++;
                        continue;
                    }
                    expected_month = ((prev_date.getMonth() + 1) % 12) + 1;
                    if (expected_month !== date.getMonth() + 1) {
                        year = prev_date.getMonth() + 1 === 12 ? prev_date.getFullYear() + 1 : prev_date.getFullYear();
                        month_name = this.moment(expected_month + '/15/' + year, 'M/D/YYYY').format('MMMM 15, YYYY') ;
                        new_period = {};
                        new_period.timestamp = new Date(month_name);
                        new_period.files_sent = 0;
                        new_period.files_booked = 0;
                        new_period.files_booked_payments_value = 0;
                        new_period.files_booked_paid_payments_value = 0;
                        new_period.files_booked_unpaid_payments_value = 0;
                        new_period.proposals_sent = 0;
                        new_period.proposals_booked = 0;
                        new_period.proposals_booked_payments_value = 0;
                        new_period.proposals_booked_paid_payments_value = 0;
                        new_period.proposals_booked_unpaid_payments_value = 0;
                        new_period.invoices_sent = 0;
                        new_period.invoices_booked = 0;
                        new_period.invoices_booked_payments_value = 0;
                        new_period.invoices_booked_paid_payments_value = 0;
                        new_period.invoices_booked_unpaid_payments_value = 0;
                        new_period.files_booked_out_of_sent = '0%';
                        reversed.splice(index, 0, new_period);
                        prev_date = new Date(month_name);
                        numberOfPeriods++;
                    } else {
                        prev_date = date;
                    }
                    index++;
                }
                stats.period_entries = reversed;
                return stats;
            }
        },

        splitStatsToYears: function splitStatsToYears(stats){
            var curr_year, date, period, period_entries, year, _i, _len, _results;
            var ranges = {};
            ranges['All-Time'] = stats;
            var all_years = [];
            period_entries = stats.period_entries;
            if (period_entries) {
                curr_year = null;
                _results = [];
                for (_i = 0, _len = period_entries.length; _i < _len; _i++) {
                    period = period_entries[_i];
                    date = new Date(period.timestamp);
                    year = null;
                    if (date.getFullYear() !== curr_year) {
                        curr_year = date.getFullYear();
                        year = {};
                        year.files_sent_sum = 0;
                        year.files_booked_sum = 0;
                        year.files_booked_out_of_sent = 0;
                        year.files_booked_paid_payments_value_sum = 0;
                        year.files_booked_unpaid_payments_value_sum = 0;
                        year.files_booked_payments_value_sum = 0;
                        year.tax_collected_sum = 0;
                        year.proposals_sent_sum = 0;
                        year.proposals_booked_sum = 0;
                        year.proposals_booked_out_of_sent = 0;
                        year.proposals_booked_paid_payments_value_sum = 0;
                        year.proposals_booked_unpaid_payments_value_sum = 0;
                        year.proposals_booked_payments_value_sum = 0;
                        year.invoices_sent_sum = 0;
                        year.invoices_booked_sum = 0;
                        year.invoices_booked_out_of_sent = 0;
                        year.invoices_booked_paid_payments_value_sum = 0;
                        year.invoices_booked_unpaid_payments_value_sum = 0;
                        year.invoices_booked_payments_value_sum = 0;
                        year.period_entries = [];
                        ranges[curr_year] = year;
                        all_years.push(curr_year);
                    } else {
                        year = ranges[curr_year];
                    }
                    year.files_sent_sum = year.files_sent_sum + (period.files_sent || 0);
                    year.files_booked_sum = year.files_booked_sum + (period.files_booked || 0);
                    year.files_booked_paid_payments_value_sum = year.files_booked_paid_payments_value_sum + (period.files_booked_paid_payments_value || 0);
                    year.files_booked_unpaid_payments_value_sum = year.files_booked_unpaid_payments_value_sum + (period.files_booked_unpaid_payments_value || 0);
                    year.files_booked_payments_value_sum = year.files_booked_payments_value_sum + (period.files_booked_payments_value || 0);
                    year.tax_collected_sum = year.tax_collected_sum + (period.tax_collected || 0);
                    year.files_booked_out_of_sent = year.files_sent_sum === 0 ? 0 : (year.files_booked_sum / year.files_sent_sum) * 100;
                    year.proposals_sent_sum = year.proposals_sent_sum + (period.proposals_sent || 0);
                    year.proposals_booked_sum = year.proposals_booked_sum + (period.proposals_booked || 0);
                    year.proposals_booked_paid_payments_value_sum = year.proposals_booked_paid_payments_value_sum + (period.proposals_booked_paid_payments_value || 0);
                    year.proposals_booked_unpaid_payments_value_sum = year.proposals_booked_unpaid_payments_value_sum + (period.proposals_booked_unpaid_payments_value || 0);
                    year.proposals_booked_payments_value_sum = year.proposals_booked_payments_value_sum + (period.proposals_booked_payments_value || 0);
                    year.proposals_booked_out_of_sent = year.proposals_sent_sum === 0 ? 0 : (year.proposals_booked_sum / year.proposals_sent_sum) * 100;
                    year.invoices_sent_sum = year.invoices_sent_sum + (period.invoices_sent || 0);
                    year.invoices_booked_sum = year.invoices_booked_sum + (period.invoices_booked || 0);
                    year.invoices_booked_paid_payments_value_sum = year.invoices_booked_paid_payments_value_sum + (period.invoices_booked_paid_payments_value || 0);
                    year.invoices_booked_unpaid_payments_value_sum = year.invoices_booked_unpaid_payments_value_sum + (period.invoices_booked_unpaid_payments_value || 0);
                    year.invoices_booked_payments_value_sum = year.invoices_booked_payments_value_sum + (period.invoices_booked_payments_value || 0);
                    year.invoices_booked_out_of_sent = year.invoices_sent_sum === 0 ? 0 : (year.invoices_booked_sum / year.invoices_sent_sum) * 100;
                    year.period_entries.push(period);
                    _results.push(all_years.sort());
                }
                return ranges;
            }
        },

        sendPaymentReminder: function sendPaymentReminder(workspaceFileId, payment) {
            var url = this.Routes.v2_workspace_file_send_payment_reminder_path(workspaceFileId, payment._id);
            return this.apiCreate(url).then(
                function success() {
                    payment.payment_last_reminder_timestamp = new Date().getTime();
                }
            );
        }
    });
}());
