
// @ngInject

Models.NetworkReferralsCollection = function NetworkReferralsCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'NetworkReferralsCollection';
                this._collection.__childsType = 'NetworkReferralModel';
                this._collection.shouldMergeOnLoad = false;
                return this._collection;
            }
        }
    );

};
