/**
 * Created by inonstelman on 12/29/14.
 */
(function () {
    "use strict";

    // @ngInject
    function MalkutTialersManager($q, APIService, Routes, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }

    Services.MalkutTialersManager = Class(Services.BaseModelManager, {
        constructor: MalkutTialersManager,

        getTrialers: function getTrialers() {
            var url = this.Routes.v2_get_trialers_path();
            var trialers =  this.apiFetch(url, null);
            return trialers;
        }
    });
}());
