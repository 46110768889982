/**
 * This directive makes the input width to dynamically grow as you type.
 * passed attribute determine the width of a char.
 * e.g. flexitible-input="10"
 *
 */

(function () {
    'use strict';

    Directives.FlexibleInputDirective = function FlexibleInputDirective() {

        function FlexibleInputDirectiveLink($scope, $element, attr) {
            var defaultCharWidth = 10;

            var charWidth = attr.hbFlexibleInput ? parseInt(attr.hbFlexibleInput) : defaultCharWidth;

            $scope.$watch('ngModel', function (newVal, oldVal) {
                if (newVal !== undefined && newVal !== null) {
                    $element.css('width', (charWidth * (1 + newVal.toString().length)) + 'px');
                }

            });

        }

        return {
            scope: {
                ngModel: "="
            },

            restrict: 'A',
            require: 'ngModel',
            link: FlexibleInputDirectiveLink
        };
    };

}());
