(function () {
    'use strict';

    class ValidationsManager extends Services.BaseModelManager {

        // @ngInject
        constructor(APIService, ModelFactory, $q, Routes) {
            super(APIService, ModelFactory, $q);
            this.Routes = Routes;
        }

        validateEmail(email) {
            const url = this.Routes.v2_validate_email_new_path();
            return this.apiCreate(url,{email: email});
        }
    }

    Services.ValidationsManager = ValidationsManager;
}());
