// @ngInject
Directives.ClickOutsideDirective = function ClickOutsideDirective($document, $parse) {
    return {
        restrict: 'A',
        link: function clickOutsideLink($scope, $element, attr) {
            var func = $parse(attr.hbClickOutside);

            var eventDomain = '.scope' + $scope.$id + '.onOutsideClickDirective.honeybook';
            var checkTargetAndApplyCallback = function (event) {
                var excluded = false;

                if (!$element.is(event.target) && $element.has(event.target).length === 0) {
                    if (attr.hbClickOutsideExclude) {

                        // support for comma separated list
                        attr.hbClickOutsideExclude.split(',').forEach(function(selector) {
                            // see if there is such an element
                            var $elementsToExclude = $document.find(selector);

                            if ($elementsToExclude.length !== 0) {
                                // if the target element of the ui event has a parent with our exclusion selector
                                // this target is considered as to be exluded from triggering the click-outside callback
                                var result = angular.element(event.target).closest(selector);
                                if (result.length > 0) {
                                    excluded = true;
                                }
                            }
                        });
                    }

                    if (!excluded) {
                        $scope.$applyAsync(function clickScopeApply() {
                            func($scope);
                        });
                    }
                }
            };

            // Our current version of Modernizr detects windows 10 laptops as touch devices
            // Until we update the 
            var events = ['touchstart' + eventDomain, 'click' + eventDomain];
            $document.on(events.join(' '), function documentClick(event) {
                checkTargetAndApplyCallback(event);
            });

            $scope.$on('$destroy', function clickOutsideDestroy() {
                $document.off(eventDomain);
            });
        }
    };
};

