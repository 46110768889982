// TODO(sean): Merge with existing datepicker directive.
Components.DateInput = {
    templateUrl: 'angular/app/modules/common/ui_components/form/date_input.html',
    bindings: {
        caption: '@',
        analyticsEventValue: '@',
        analyticsEventProperties: '@',
        analyticsEventScope: '=',
        label: '@',
        name: '@',
        onChange: '&',
        flavor: '@',

        // TODO: Replace with 1-way binding, i.e. "model: '<'" OR require:model
        // This is tricky to get working with the jQuery/Foundation datepicker
        // widget since it stores the state directly in the input.value
        ngModel : '=',
    },
    // @ngInject
    controller: function DateInputController($scope, $element, $attrs, _) {
        // TODO(sean): Consider a base class to extend from?
        this.$onInit = function () {
            // Generate unique ids for all form input elements to
            // prevent clashes.
            this.name = _.uniqueId(this.name);

            this.cssClass = {
                'form-element': true,
                'form-element--faux-dropdown': true,

                // the material flavor of date picker needs to be merged
                // and supported by this DateInput component. Right now
                // this is hardcoded to standard
                'form-element--standard': true, // this.flavor === 'standard',

                // TODO: Add support for error messages
                'form-element--error' : this.hasError,
            };
        };

        this.childDatePickerChange = function(newDate) {
            this.onChange({ngModel: newDate});
        }.bind(this);

        // TODO: handle validation here
        // TODO: callback function for onChange()
        // this.$onChanges = function() {
        //}
    }
};
