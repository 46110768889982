(function () {
    'use strict';

    // @ngInject
    Services.UrlMetadataService = function UrlMetadataService(Gon, $injector, $log) {
        this.addMetadata = function addMetadata(url) {
            try {
                const UsersManager = $injector.get('UsersManager');
                const $window = $injector.get('$window');

                let delimiter;
                if (url.indexOf('?') === -1) {
                    delimiter = "?";
                } else {
                    delimiter = "&";
                }

                let user = UsersManager.getCurrUser();
                if (user && user._id) {
                    let metadataParams = `${delimiter}ctxu=${user._id}`;
                    let companyId = user.company_id;
                    if (!companyId && user.company) {
                        companyId = user.company._id;
                    }

                    // we want to add a context_company_id even when user is a client
                    // as long as the user is logged in via a client portal
                    // this allows us to act on requests via email and still find correct user
                    if(!companyId && user.isClient()){
                        companyId = ($window.clientPortalConfiguration || {}).company_id;
                    }

                    if (companyId) {
                        metadataParams = `${metadataParams}&ctxc=${companyId}`;
                    }

                    if (user.isBookkeeperMode()) {
                        metadataParams = `${metadataParams}&auditee_company_id=${user.bookkeeperModeCompanyId}`;
                    }

                    url = `${url}${metadataParams}`;
                }
            } catch (e) {
                $log.error(e);
            }

            return url;
        };
    };
})();