Directives.WorkspaceFileCardProgressDirective = function WorkspaceFileCardProgressDirective() {

    // @ngInject
    function WorkspaceFileCardProgressControllerConstructor($scope, $injector, $filter, $sce, DeviceService, _) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileCardProgressController';

        this.$filter = $filter;
        this.$sce = $sce;
        this.$scope = $scope;
        this.DeviceService = DeviceService;
        this._ = _;

        this.fileStateDetails = '';
        this.isTeamMember = this.isVendor && this.workspaceFile.owner.isMyTeamMember();

        this.setProgressBar();
        this.formatFileStatusLabel();

        // Show details on proposal view forward
        this.shouldShowStateDetails = this.shouldShowLabelDetails();

        $scope.$watch('wsCardProgressVm.workspaceFile.status', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setProgressBar();
                this.shouldShowStateDetails = this.shouldShowLabelDetails();
            }
        }.bind(this));
    }

    var WorkspaceFileCardProgressController = Class(Controllers.BaseController, {
        constructor: WorkspaceFileCardProgressControllerConstructor,

        setProgressBar: function setProgressBar () {
            switch (this.workspaceFile.file_type) {
                case 'proposal':
                    this.progressBarProposal();
                    break;
                case 'invoice':
                    this.progressBarInvoice();
                    break;
                case 'questionnaire':
                    this.progressBarQuestionnaire();
                    break;
                case 'brochure':
                    this.progressBrochure();
                    break;
                case 'timeline':
                    this.progressBarTimeline();
                    break;
                case 'agreement':
                    this.progressBarAgreement();
                    break;
            }
        },

        progressBarProposal : function progressBarProposal() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: this.getSeenProposalStatus(),
                    tooltip: this.getSeenProposalTooltip()//are we adding dates?
                },
                {
                    status: 'agreement_signed',
                    tooltip: this.checkIfAgreementIsSigned()
                },
                {
                    status: this.getPaymentStatus(),
                    tooltip: this.getPaymentStatusTooltip()
                },

            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        progressBarInvoice : function progressBarInvoice() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: 'proposal_seen',
                    tooltip: this.getFileTypeSeenTooltip('Invoice')
                },
                {
                    status: this.getPaymentStatus(),
                    tooltip: this.getPaymentStatusTooltip()
                }
            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        progressBarQuestionnaire : function progressBarQuestionnaire() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: 'proposal_seen',
                    tooltip: this.getFileTypeSeenTooltip('Questionnaire')
                },
                {
                    status: 'payment_done',
                    tooltip: 'Questionnaire submitted'
                    // tooltip: this.workspaceFile.status === 'payment_done'? 'Questionnaire submitted': 'Get questionnaire'
                }
            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        progressBarAgreement : function progressBarAgreement() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: 'agreement_seen',
                    tooltip: this.getFileTypeSeenTooltip('Contract')
                },
                {
                    status: 'payment_done',
                    tooltip: this.workspaceFile.status === 'payment_done'? 'Contract signed': 'Get signed contract'
                }
            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        progressBrochure : function progressBrochure() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: 'proposal_seen',
                    tooltip: this.getFileTypeSeenTooltip('Brochure')
                },
                {
                    status: 'payment_done',
                    tooltip: 'Brochure submitted'
                    // tooltip: this.workspaceFile.status === 'payment_done'? 'Brochure submitted': 'Get brochure'
                }
            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        progressBarTimeline : function progressBarTimeline() {
            this.progressBarStates = [
                {
                    status: 'proposal_sent',
                    tooltip: this.getFileSentDate(this.workspaceFile.sent_on)
                },
                {
                    status: 'proposal_seen',
                    tooltip: 'Timeline Viewed'
                    // tooltip: this.workspaceFile.status === 'proposal_seen'? 'Timeline Viewed': 'Client\'s Timeline review'
                }
            ];

            if (this.isTeamMember) {
                this.progressBarStates.unshift(this.fileCreated());
            }

            if(this.workspaceFile.status && this.progressBarStates) {
                this.workspaceProgressStatus = this.setProgressBarState(this.workspaceFile.status, this.progressBarStates);
            }
        },

        fileCreated: function fileCreated() {
            var fileCreatedBy = {
                status: 'proposal_created',
                tooltip:  this.getFileCreator() + this.formatDate(this.workspaceFile.created_at)
            }
            return fileCreatedBy;
        },

        getFileCreator: function getFileCreator() {
            return 'File created by ' + this.workspaceFile.owner.full_name + ': ';
        },

        formatDate: function formatDate(val) {
            return this.$filter('date')(val, "MMM d y");
        },

        /* Timeline State */
        setProgressBarState: function setProgressBarState(currentState, stateList) {
            var activeProgressState = stateList.map(function (state, index) {
                    state.progress_index = index;
                    currentState === state.status ? state.active = true: state.active = false;
                    return state;
                });

            var currentIndex = activeProgressState.filter(function (state) {
                if (state.active){
                    return state;
                }
            });

            // For invalid state, don't fail the whole tab
            if (!currentIndex || currentIndex.length === 0) {
                console.log("Failed to fetch state " + currentState + " for file " + this.workspaceFile._id)
                return;
            }

            this.currentStateIndex = currentIndex[0].progress_index;

            var formattedProgressObj = activeProgressState.map(function (state) {
                state.progress_index <= this.currentStateIndex ? state.completed = true : state.completed = false;
                return state;
            }.bind(this));

            return formattedProgressObj;
        },

        getFileSentDate: function getFileSentDate(fileSentDate) {
            var fileSent = this.isTeamMember ? 'File sent: ' : 'New file: ';

            if ( fileSentDate ) {
                return fileSent + this.formatDate( fileSentDate );
            } else {
                return 'Send file to client';
            }
        },

        /* PROPOSAL */
        checkIfAgreementIsSigned: function checkIfAgreementIsSigned() {
            var self = this;

            if (this.workspaceFile.status === 'agreement_signed') {
                this.fileStateDetails =  this.$sce.trustAsHtml('<span class="ws-progress__label-icon">Contract Signed</span>');
            }

            if ((this.workspaceFile.status === 'agreement_signed' || this.workspaceFile.status_cd >= 10) && this.workspaceFile.active_file_version ) {
                return getSignatureDate();
            } else {
                return 'Get signed contract';
            }

            function getSignatureDate() {

                var companyMembers;

                if (self.workspaceFile.company.team_members) {
                    var teamMembers = self.workspaceFile.company.team_members.map(function (teamMember) {
                        return teamMember._id;
                    });

                    companyMembers = teamMembers.concat(self.workspaceFile.owner._id);
                } else {
                    companyMembers = [self.workspaceFile.owner._id];
                }

                var signaturesCollection = self.workspaceFile.file_version_container.active_content.contract_signatures;
                var clientSignatures = [];

                signaturesCollection.forEach(function (signee) {
                    var isTeamMember = companyMembers.indexOf(signee.signee_id) > -1;

                    if(!isTeamMember) {
                        clientSignatures.push(signee);
                    }
                });

                return clientSignatures && clientSignatures.length > 0 ?
                    'Contract signed: ' + self.formatDate(clientSignatures[0].signed_on) : 'Get signed contract';
            }
        },

        getFileTypeSeenTooltip: function getFileTypeSeenTooltip( fileType ) {
            var isVendor = this.isTeamMember ? 'Client\'s ' : '';

            if ((this.workspaceFile.status === 'proposal_seen' || this.workspaceFile.status === 'agreement_seen') || this.workspaceFile.status === 'payment_done') {
                return fileType + ' viewed';
            } else {
                return isVendor + fileType + ' review';
            }
        },

        getSeenProposalStatus: function getSeenProposalStatus() {
            switch (this.workspaceFile.status) {
                case 'agreement_seen':
                    return 'agreement_seen';
                case 'proposal_seen':
                    return 'proposal_seen';
                default:
                    return '';
            }
        },

        getSeenProposalTooltip: function getSeenProposalTooltip() {
            var isVendor = this.isTeamMember ? 'Client\'s review ' : 'Review ';

            switch (true) {
                case this.workspaceFile.status === 'proposal_seen':
                    this.fileStateDetails = 'Proposal Viewed';
                    return 'Proposal viewed';
                case this.workspaceFile.status === 'agreement_seen':
                    this.fileStateDetails = 'Contract Viewed';
                    return 'Contract viewed';
                case this.workspaceFile.status_cd > 9 :
                    return 'Contract + Proposal viewed';
                default:
                    return isVendor + 'on proposal + contract';
            }
        },

        getPaymentStatus: function getPaymentStatus() {
            switch (this.workspaceFile.status) {
                case 'retainer_paid':
                    return 'retainer_paid';
                case 'payment_done':
                    return 'payment_done';
                case 'payments_seen':
                    return 'payments_seen';
                default:
                    return '';
            }
        },

        shouldShowLabelDetails: function shouldShowLabelDetails() {
            return this.workspaceFile.status_cd >= 8;
        },

        getPaymentStatusData: function getPaymentStatusData(isPaid) {
            // TODO: (Alon) - We need to handle refunded payments
            var paymentContainer;
            if (this.workspaceFile.file_version_container.active_content) {
                paymentContainer = this.workspaceFile.file_version_container.active_content.payments_container;
            } else {
                paymentContainer = this.workspaceFile.payments_container;
            }

            var paymentData = {
                retainer_due_date: '',
                last_payment_date : '',
                next_payment_date : '',
                payment_count: '',
                current_payment : '',
                total_amount_paid: ''
            };

            if (isPaid) {
                var paidPayments = paymentContainer.payments.filter(function (payment) {
                    return payment.is_paid;
                });
                // DO WE NEED HERE THE FULL PAYMENTS CONTAINER?????
                paymentData.total_amount_paid = this._.reduce(paidPayments, function(sum, payment){
                    return sum + payment.amount - ((payment.refund_data && payment.refund_data.is_refunded) ? payment.refund_data.refunded_amount : 0);
                }, 0);
                
                var lastPayment = paidPayments.pop();
                paymentData.last_payment_date = ' paid: ' + this.formatDate(lastPayment.charge_date);
                paymentData.payment_count = lastPayment.count_description;
                paymentData.current_payment_amount = lastPayment.amount;
            } else {
                var retainer = paymentContainer.payments[0];
                paymentData.retainer_due_date = retainer.count_description + ' Due: ' + this.formatDate(retainer.due_date);
            }

            return paymentData;
        },

        getPaymentStatusTooltip: function getPaymentStatusTooltip() {
            var paymentDataObj;
            var recurringAndHasPaidPayments = false;

            // paid files
            if(this.workspaceFile.status === 'retainer_paid' || this.workspaceFile.status_cd >= 12) {

                // handle recurring
                if (this.workspaceFile.isRecurringPayment()) {

                    var paymentContainer = this.workspaceFile.file_version_container &&
                    this.workspaceFile.file_version_container.active_content ?
                        this.workspaceFile.file_version_container.active_content.payments_container :
                        this.workspaceFile.payments_container;
                    var payments = paymentContainer.payments;
                    var lastPayment = payments.length ? payments[payments.length - 1] : {};

                    // render label
                    this.renderRecurringLabel({amount: lastPayment.amount});

                    // return tooltip text
                    return 'Next payment: ' + this.formatDate(lastPayment.due_date);

                // regular paid files
                } else {

                    paymentDataObj = this.getPaymentStatusData(true);

                    //  render label
                    this.renderPaymentLabel(paymentDataObj);

                    // return tooltip text
                    return paymentDataObj.payment_count + paymentDataObj.last_payment_date;

                }

            // regular sent files - no payments
            } else if (this.workspaceFile.status === 'proposal_sent' || this.workspaceFile.status_cd >= 6) {
                paymentDataObj = this.getPaymentStatusData(false);

                // return tooltip text
                return paymentDataObj.retainer_due_date;

            // file hasn't been sent
            } else {

                // return label text
                return 'Get Paid';
            }

        },

        formatFileStatusLabel: function formatFileStatusLabel() {
            if (this.workspaceFile.status === 'payment_done') {
                switch (this.workspaceFile.file_type) {
                    case "questionnaire":
                        this.renderAgreementSignedLabel('Questionnaire Submitted');
                        break;
                    case "agreement":
                        this.renderAgreementSignedLabel('Contract Signed');
                        break;
                    case "brochure":
                        this.renderAgreementSignedLabel('Brochure Submitted');
                        break;
                    default:
                        break;
                }
            }
        },

        renderPaymentLabel: function renderPaymentLabel(filePayment) {
            this.fileStateDetails = this.$sce.trustAsHtml('<span class="ws-progress__label-icon ws-progress__label-amount">' + this.$filter('hbcurrency')(filePayment.total_amount_paid, this.workspaceFile.currency) + ' </span>' + '<span class="ws-progress__label-count">' + filePayment.payment_count + '</span>');
        },

        renderRecurringLabel: function renderRecurringLabel(filePayment) {
            this.fileStateDetails = this.$sce.trustAsHtml('<span class="ws-progress__label-icon ws-progress__label-amount">' + this.$filter('hbcurrency')(filePayment.amount, this.workspaceFile.currency) + ' </span>' + '<span class="ws-progress__label-count">Paying monthly</span>');
        },

        renderAgreementSignedLabel: function renderAgreementSignedLabel(fileTypeStatus) {
            this.fileStateDetails = this.$sce.trustAsHtml('<span class="ws-progress__label-icon">' + fileTypeStatus + '</span>' );
        },

        onStatusLabelClick: function onStatusLabelClick(event) {
            event.stopPropagation();
        },

        onStatusDotToggle: function onStatusDotToggle(event) {
            event.stopPropagation();

            // KEEP THIS CODE!!!
            // this.isMobile = this.DeviceService.checkIfMobileSize();
            //
            // if (!this.isMobile) {
            //     return;
            // }
            //
            // var toggleStyleClass = 'css-tooltip--show-on-small';
            // var dotStage = $(event.currentTarget);
            // var dotStageList = dotStage.closest('ul').find('.css-tooltip');
            // var dotStageContainer = dotStage.closest('ul').find('.ws-progress__dot-container');
            //
            // dotStageList.not(dotStage).removeClass(toggleStyleClass);
            //
            // dotStageContainer.not(dotStage).addClass('css-tooltip--disable');
            //
            //
            // dotStage.toggleClass(toggleStyleClass);
            //
            // if(dotStage.hasClass(toggleStyleClass)) {
            //     dotStageContainer.removeClass('css-tooltip--disable');
            // } else {
            //     dotStageContainer.addClass('css-tooltip--disable');
            // }

        }

    });


    return {
        scope: {
            workspaceFile: '=',
            isVendor: '<'
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file_card/workspace_file_card_progress_template.html',
        controller: WorkspaceFileCardProgressController,
        controllerAs: 'wsCardProgressVm',
        bindToController: true
    };
};