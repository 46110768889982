(function () {
    'use strict';

    class HeaderView extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $state,
            $translate,
            AppStates,
            DeviceService,
            TemplatesViewService,
            PreviewModeService,
            WorkspaceFilesManager,
            OtamStatusService,
        ) {
            super($scope, $injector);
            this.__objectType = 'HeaderView';
            this.$state = $state;
            this.$translate = $translate;
            this.AppStates = AppStates;
            this.DeviceService = DeviceService;
            this.PreviewModeService = PreviewModeService;
            this.TemplatesViewService = TemplatesViewService;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.OtamStatusService = OtamStatusService;
            this.OtamStatusService.initializeAfterLogin();

            this.isGiftCard = $state.params.templateModelKey === 'giftCard';

            this.PreviewModeService.quit(); // in case we got here from preview mode
            this.templatePreviewTooltip = this.$translate.instant('FILE.HEADER._CLIENT_VIEW_TOOLTIP_TEMPLATE_PREVIEW_');

            this.showOnlyTitleOnHeader =
                DeviceService.nxSmallBreakpoint()
                && (
                    $state.params.templateModelKey === 'contactForm'
                    || $state.params.templateModelKey === 'giftCard'
                );

            this.shouldShowBreadcrumbs = true;
        }

        handleClickBack() {
            angular.isFunction(this.backButton) && this.backButton();
        }

        goToBase() {
            if (this.breadcrumbBaseState && !this.OtamStatusService.isMigrated()) {
                this.$state.go(this.breadcrumbBaseState)
            } else {
                this.handleClickBack();
            }
        }

        breadcrumbTitleViewTranslated() {
            return this.$translate.instant(this.breadcrumbTitleView);
        }

        triggerPreviewMode() {
            this.previewIsLoading = true;
            this.TemplatesViewService.generateCurrentTemplateClientPreview(this.TemplatesViewService.currentTemplate.type, this.TemplatesViewService.currentTemplate._id)
                .then(workspaceFile => {
                    const params = {
                        preview: true,
                        templatePreview: true,
                        templateOriginId: this.$state.params.templateId,
                        templateOriginModelKey: this.$state.params.templateModelKey
                    };
                    this.PreviewModeService.enter(workspaceFile, params);
                    this.previewIsLoading = false;
                });
        }

        shouldShowPreviewModeButton() {
            // For now, client preview is limited to invoice
            const isInvoice = this.$state.params.templateModelKey === "invoice";

            return (
                !this.TemplatesViewService.isPreviewMode
                && !this.DeviceService.navLargeBreakPoint()
                && isInvoice
            )
        }
    }

    Components.HeaderView = {
        bindings: {
            backButton: '&?',
            breadcrumbBaseTitle: '@?',
            breadcrumbBaseState: '@?',
            breadcrumbTitleView: '@?',
            showSavingIndicator: '=',
            savingIndicatorModel: '=',
            currentTemplate: '=',
            ctaRightButton: '&?',
            showCtaRightButton: '<?',
            contentClass: '@?'
        },
        controller: HeaderView,
        name: 'hbHeaderView',
        templateUrl: 'angular/app/modules/common/ui_components/header_view/header_view_component_template.html',
        transclude: true,
    }

}());
