(function () {
    'use strict';
    Services.ContactViewService = class ContactViewService {

        // @ngInject
        constructor($state, AppStates) {
            this.$state = $state;
            this.AppStates = AppStates;
        }

        openContactView(contactId, source) {
            this.$state.go(this.AppStates.root_core_navigation_contact_view, {
                contact_id: contactId,
                source: source
            });
        }

    };
})();
