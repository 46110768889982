Directives.LoginTwoFactorAuthBannerDirective = function LoginTwoFactorAuthBannerDirective() {

    // @ngInject
    function LoginTwoFactorAuthBannerDirectiveControllerFunc($scope, $injector, $translate, $state, UiPersistenceService, AnalyticsService, moment, UsersManager, AppStates, OtamStatusService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginTwoFactorAuthBannerDirectiveController';

        this.$state = $state;
        this.$translate = $translate;
        this.UiPersistenceService = UiPersistenceService;

        this.user = UsersManager.getCurrUser();
        this.AppStates = AppStates;
        this.OtamStatusService = OtamStatusService;
        this.OtamStatusService.initializeAfterLogin();

        this.AnalyticsService = AnalyticsService;
        this.analyticsSource = 'login 2fa banner';

        let loginTwoFactorAuthBannerInteracted = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.loginTwoFactorAuthBannerInteracted)
        this.interactionsCount = loginTwoFactorAuthBannerInteracted ? parseInt(loginTwoFactorAuthBannerInteracted.count) : 0;
        this.lastInteraction = loginTwoFactorAuthBannerInteracted && loginTwoFactorAuthBannerInteracted.last_interaction ? moment(loginTwoFactorAuthBannerInteracted.last_interaction) : null;
        this.isDismissed = false;

        if(this.shouldShowBanner()) {
            AnalyticsService.track(this, 'show home 2fa banner', { source: this.analyticsSource });
        }
    }

    var LoginTwoFactorAuthBannerDirectiveController = Class(Controllers.BaseController, {
        constructor: LoginTwoFactorAuthBannerDirectiveControllerFunc,

        shouldShowBanner: function shouldShowBanner() {
            let isInteractedToday = this.lastInteraction && this.lastInteraction.isSame(moment(), 'day');
            return !this.isDismissed &&
                !isInteractedToday &&
                this.interactionsCount < 3 &&
                this.user.isActivated() &&
                !this.user.isTwoFactorAuthLoginEnabled() &&
                this.user.isCreatedBefore(3, 'months') &&
                !!this.user.securityPhoneNumberLastFourDigits();
        },

        notNow: function notNow() {
            this.isDismissed = true;
            this.setInteractionCount();
        },

        addTwoFactorAuth: function addTwoFactorAuth() {
            this.setInteractionCount();
            this.$state.go(this.AppStates.root_core_navigation_settings_account, { openTwoFactorAuthModal: true });
        },

        setInteractionCount: function setInteractionCount() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.loginTwoFactorAuthBannerInteracted, { count: this.interactionsCount + 1, last_interaction: Date() });
        }
    });

    return {
        scope: {},
        templateUrl: 'angular/app/modules/core/features/home/login_2fa_banner/login_2fa_banner_directive_template.html',
        controller: LoginTwoFactorAuthBannerDirectiveController,
        controllerAs: 'loginTwoFactorAuthBannerVm',
        bindToController: true
    };
};