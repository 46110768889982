/**
 * Created by udicohen on 26/5/15.
 *
 * This directive fixes a problem with auto-fill in browsers (specifically in chrome on iPhone)
 * The problem is the auto-fill doesn't trigger the change event on the input field,
 * causing the angular model to get out of sync form the DOM element.
 * Basically we're checking with an Iinterval if they are out of sync in the second if
 * statement and triggering the change event manually.
 * We're braking the interval when the directive is destroyed or when DOM and the ngModel are in sync.
 *
 * Note: canceling the interval can be called any number of times and the first cal is the one that actually cancels it.
 */

// @ngInject
Directives.InputAutofillFixDirective = function InputAutofillFixDirective($interval) {

    function InputAutofillFixLink(scope, element, attr, ngModelController) {
        var interval = $interval(function () {

            if (element.val() === ngModelController.$viewValue) {
                $interval.cancel(interval);
            } else if (element.val() && ngModelController.$viewValue === undefined) {
                element.change();
            }

        }, 500);

        scope.$on('$destroy', function () {
            $interval.cancel(interval);
        });
    }

    return {
        require: 'ngModel',
        restrict: 'A',
        link: InputAutofillFixLink
    };
};

