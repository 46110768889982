(function () {
    "use strict";

    Directives.CustomizeUrlDirective = function CustomizeUrlDirective() {

        // @ngInject
        function CustomizeUrlControllerCtor($scope, $injector, $translate, $timeout, PopupMessageService, DeviceService,
                                            CompaniesManager, ModalService, ClientPortalService, $q, UsersManager, AppStates) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'ClientPortalDirectiveController';
            this.PopupMessageService = PopupMessageService;
            this.DeviceService = DeviceService;
            this.ModalService = ModalService;
            this.CompaniesManager = CompaniesManager;
            this.ClientPortalService = ClientPortalService;
            this.$translate = $translate;
            this.$timeout = $timeout;
            this.AppStates = AppStates;
            this.user = UsersManager.getCurrUser();
            this.maxNumOfChanges = 3;
            this.$q = $q;
            this.isMaxChanges = this.company.company_url_prefixes ? this.maxNumOfChanges === this.company.company_url_prefixes.length : false;
            this.urlActions = [
                {
                    name: 'CLIENT_PORTAL._EDIT_URL_',
                    icon: 'nx-pencil',
                    action: function () {
                        this.onEditClicked();
                    }.bind(this)
                },
                {
                    name: 'CLIENT_PORTAL._COPY_URL_',
                    icon: 'nx-link',
                    action: function () {
                        this.onCopyClicked();
                    }.bind(this)
                },
                {
                    name: 'CLIENT_PORTAL._SEND_URL_',
                    icon: 'nx-share',
                    action: function () {
                        this.onSendClicked();
                    }.bind(this)
                }];
            this.editActions = [
                {
                    name: 'CLIENT_PORTAL._CANCEL_EDIT_URL_',
                    className: 'nx-button--invisible',
                    action: function () {
                        this.onCancelEdit();
                    }.bind(this)
                },
                {
                    name: 'CLIENT_PORTAL._SAVE_URL_',
                    className: '',
                    action: function () {
                        this.onSavePrefix();
                    }.bind(this)
                }];
            this.actions = this.urlActions;
            this.isEditMode = false;
            this.isMobile = this.DeviceService.checkIfMobileSize();
            this.isCopying = false;
            this.image = "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/settings/client-portal.svg";
            this.isValidPrefix = true;
            this.shouldShowMaxError = this.isMobile && this.isMaxChanges;
            this.validationError = this.shouldShowMaxError ? 'CLIENT_PORTAL._MAX_URL_CHANGES_ ' : '';
            this.maxUrlLength = 50;
            this.loadingPrefix = true;
            this.prefix = '';
            this.url = '';
            this.domain = '';
            if (this.company.wasFullyFetched()) {
                this._initCustomizationData();
            } else {
                this.registerOnce(this.company, 'success', function () {
                    this._initCustomizationData();
                }.bind(this));
            }

            this.companyHasAnySubscription = this.user.companyHasAnySubscription();
        }

        var CustomizeUrlDirective = Class(Controllers.BaseController, {
            constructor: CustomizeUrlControllerCtor,

            toggleEditActions: function hideEditActions() {
                this.toggleEditMode();
                if (this.isMobile && this.isMaxChanges) {
                    this.shouldShowMaxError = true;
                    this.validationError = 'CLIENT_PORTAL._MAX_URL_CHANGES_ ';
                }
                if (this.isMobile && this.isEditMode) {
                    this.actions = this.editActions;
                } else {
                    this.actions = this.urlActions;
                }
            },

            onEditClicked: function onEditClicked($event) {
                if (this.isMaxChanges) {
                    return;
                }
                this.toggleEditActions();
                this.onUrlPrefixChange(this.prefix);
            },

            onCopyClicked: function onCopyClicked(e) {
                this.isCopying = !this.isCopying;
            },

            onSendClicked: function onSendClicked() {
                this.ModalService.openClientPortalSendLinkModal();
            },

            onSavePrefix: function onSavePrefix(e) {

                // if url is not changed, do nothing
                if(this.company.company_url_prefixes.length &&
                    this.company.company_url_prefixes[this.company.company_url_prefixes.length - 1] === this.prefix) {
                    this.toggleEditActions();
                    return;
                }

                var isUrlAlreadyOwnedBySelf = this.company.company_url_prefixes.indexOf(this.prefix) > -1;
                var changesLeft = this.company.company_url_prefixes ? this.maxNumOfChanges - this.company.company_url_prefixes.length : this.maxNumOfChanges;
                if (!isUrlAlreadyOwnedBySelf) {
                    changesLeft = changesLeft - 1;
                }
                var msg = this.$translate.instant('CLIENT_PORTAL._URL_CHANGES_COUNTDOWN_', {changesLeft: changesLeft});
                if (changesLeft === 0) {
                    msg = 'CLIENT_PORTAL._LAST_URL_CHANGE_';
                    this.isMaxChanges = true;
                }
                var promise = this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.none, msg, 'confirm change', 'cancel', 'Change portal URL?');
                promise.then(function () {
                    this.onSaveConfirmed();
                }.bind(this));
            },

            onSaveConfirmed: function onSaveConfirmed() {
                this.CompaniesManager.updateCompanyUrlPrefix(this.company, this.prefix)
                    .then(function onSuccess(resp) {
                        if (resp.data.company_url_prefixes) {
                            this.prefix = resp.data.company_url_prefixes[resp.data.company_url_prefixes.length - 1];
                            this.updateUrl();
                            this.toggleEditActions();
                            this.CompaniesManager.updateClientPortalConfiguration(this.company, this.company.client_portal_configuration);
                        }
                    }.bind(this));
            },

            onCancelEdit: function onCancelEdit() {
                this.getCompanyUrl().then(function (prefix) {
                    this.prefix = prefix;
                }.bind(this));
                this.toggleEditActions();
            },

            createInitialPrefix: function createPrefix() {
                var self = this;
                var promise = self.CompaniesManager.getCompanyUrlPrefix(self.company);
                return promise.then(function onSuccess(resp) {
                    self.toggleEditMode();
                    return resp.data.company_url_prefix;
                }).catch(function onFailure(err) {
                    self._showError(err);
                });
            },

            onClaimUrl: function onClaimUrl() {
                var self = this;
                return self.CompaniesManager.updateCompanyUrlPrefix(self.company, self.prefix)
                    .then(function onSuccess(resp) {
                        var companyUrls = resp.data.company_url_prefixes;
                        self.prefix = companyUrls[companyUrls.length - 1];
                        self.updateUrl();
                        if (companyUrls) {
                            var defaultClientPortalConfiguration = self.ClientPortalService.getDefaultClientPortalConfiguration(self.company);
                            var defaultClientPortalConfigurationData = self.ClientPortalService.formatConfigurationForRequest(defaultClientPortalConfiguration);
                            self.CompaniesManager.updateClientPortalConfiguration(self.company, defaultClientPortalConfigurationData)
                                .then(function onSuccess() {
                                    self.ModalService.openCustomUrlModal('icon-hb-nx-check-42',
                                        'CLIENT_PORTAL._CLAIMED_URL_MODAL_TITLE_',
                                        'CLIENT_PORTAL._CLAIMED_URL_MODAL_TEXT_',
                                        'CLIENT_PORTAL._CLAIMED_URL_MODAL_POS_BTN_')
                                        .then(function onSuccess() {
                                            self.handleClaimedUrl();
                                        });
                                });
                        }
                    }).catch(function onFailure(err) {
                        self._showError(err);
                    });
            },

            handleClaimedUrl: function handleClaimedUrl() {
                this.wasInitialUrlClaimed = true;
                this.toggleEditMode();
                this.toggleDisablePreview();
            },

            updateUrl: function updateUrl() {
                this.url = 'https://' + this.prefix + this.domain;
            },

            toggleEditMode: function toggleEditMode() {
                this.isEditMode = !this.isEditMode;
            },

            getCompanyUrl: function getCompanyUrl() {
                var deferred = this.$q.defer();
                if (this.company.company_url_prefixes) {
                    var company_urls = this.company.company_url_prefixes;
                    deferred.resolve(company_urls[company_urls.length - 1]);
                    return deferred.promise;
                }

                return this.createInitialPrefix();
            },

            onCopiedSuccessfully: function onCopiedSuccessfully() {
                this.$timeout(function () {
                    this.isCopying = false;
                }.bind(this), 3000);
            },

            onUrlPrefixChange: function onUrlPrefixChange(prefix) {
                var self = this;
                return self.CompaniesManager.validateCompanyUrlPrefix(self.company, prefix)
                    .then(function onSuccess(resp) {
                        if (resp.data) {
                            self.isValidPrefix = true;
                            self.validationError = '';
                        }
                    }).catch(function onError(err) {
                        self._showError(err);
                    });
            },

            _showError: function _showError(err) {
                this.validationError = 'CLIENT_PORTAL._URL_INVALID_CHARACTERS_';
                if(err && err.data) {
                    this.validationError = err.data.error_message;
                }
                this.isValidPrefix = false;
            },

            _initCustomizationData: function _initCustomizationData() {
                this.getCompanyUrl().then(function (prefix) {
                    this.prefix = prefix;
                    this.domain = '.' + this.company.company_url_domain + '/app/';
                    this.updateUrl();
                    this.loadingPrefix = false;
                }.bind(this));
                this.wasInitialUrlClaimed = this.company.company_url_prefixes && this.company.company_url_prefixes.length;
            },

            getActionTitle: function getActionTitle(actionName) {
                if (actionName === 'CLIENT_PORTAL._EDIT_URL_' && this.isMaxChanges && !this.isMobile) {
                    return 'CLIENT_PORTAL._MAX_URL_CHANGES_';
                }
                if (actionName === 'CLIENT_PORTAL._COPY_URL_' && this.isCopying) {
                    return 'CLIENT_PORTAL._COPYING_URL_';
                }
                return actionName;
            },

            isMwebActionDisabled: function isMwebActionActive(actionName) {
                if (actionName === 'CLIENT_PORTAL._SAVE_URL_' && !this.isValidPrefix) {
                    return true;
                }

                if(actionName === 'CLIENT_PORTAL._EDIT_URL_' && (this.shouldShowMaxError || !this.companyHasAnySubscription)) {
                    return true;
                }

                return false;
            }
        });

        return {
            scope: {
                company: '=',
                toggleDisablePreview: '&'
            },
            templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/customize_url/client_portal_customize_url.html',
            controller: CustomizeUrlDirective,
            controllerAs: 'customizeUrlVm',
            bindToController: true
        };
    };
}());
