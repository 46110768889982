(function () {
    'use strict';

    Directives.NewCreditCardFormDirective = function () {

        class NewCreditCardFormDirectiveController extends Controllers.BaseControllerES6 {
            // @ngInject
            constructor($scope, $injector, $q, $, uuid4, UsersManager, StripeHelperService, $translate) {
                super($scope, $injector);

                this.__objectType = 'NewCreditCardFormDirectiveController';

                this.$scope = $scope;
                this.$q = $q;
                this.$translate = $translate;
                this.StripeHelperService = StripeHelperService;
                this.UsersManager = UsersManager;

                this.tokenProviderId = uuid4.generate();
                this.jQuery = $; // Store the root jQuery library for later usage

                this.creditCardType = 'default';
            }

            setRootElement(element) {
                this.rootElement = element;
                this.$ = this.rootElement.find.bind(this.rootElement);
            }

            addInputListereners() {
                const jQuery = this.jQuery;
                const $inputs = this.$('.input-card');
                $inputs.on('focus.card.stripe.honeybook.com', function () {
                    // We're using old-school function and `this` because it is the original element that we put the event handler on
                    jQuery(this).addClass('focused');
                }).on('blur.card.stripe.honeybook.com', function () {
                    // We're using old-school function and `this` because it is the original element that we put the event handler on
                    jQuery(this).removeClass('focused');
                }).on('keyup.card.stripe.honeybook.com', function () {
                    // We're using old-school function and `this` because it is the original element that we put the event handler on
                    const $input = jQuery(this);
                    $input.toggleClass('empty', !!$input.val());
                });

                this.$scope.$on("$destroy", () => {
                    $inputs.off('.card.stripe.honeybook.com');
                });
            }

            validateCardName() {
                if (!this.card || !this.card.name) {
                    this.card_name_error = this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._REQUIRED_FIELD_');
                    return false;
                } else {
                    this.card_name_error = null;
                    if (this.wasFormCompleteCalled) {
                        this.onFormComplete();
                    }
                    return true;
                }
            }

            initStripElements() {
                const stripePromise = this.StripeHelperService.getStripeForUser(this.UsersManager.getCurrUser());
                stripePromise.then(this._initStripeElementsLogic.bind(this));
            }

            _initStripeElementsLogic(stripe) {
                const elements = stripe.elements();

                const elementStyles = {
                    base: {
                        color: 'rgba(0, 0, 0, 0.75)',
                        fontWeight: 300,
                        fontSmoothing: 'antialiased',
                        fontFamily: 'ProximaNova, Arial, sans-serif',
                        fontSize: '14px',
                        '::placeholder': {
                            color: 'transparent',
                        },
                    },
                    invalid: {
                        color: 'rgba(0, 0, 0, 0.75)'
                    },
                };

                const elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                };

                const cardNumber = elements.create('cardNumber', {
                    style: elementStyles,
                    classes: elementClasses,
                });
                const cardCvc = elements.create('cardCvc', {
                    style: elementStyles,
                    classes: elementClasses,
                });
                const cardExpiry = elements.create('cardExpiry', {
                    style: elementStyles,
                    classes: elementClasses,
                });

                // Mount elements
                cardNumber.mount(this.$('#card-number')[0]);
                cardCvc.mount(this.$('#card-cvc')[0]);
                cardExpiry.mount(this.$('#card-expiry')[0]);

                const cardNumberReadyDeferred = this.$q.defer();
                const cardCvcReadyDeferred = this.$q.defer();
                const cardExpiryReadyDeferred = this.$q.defer();
                const readyPromisses = [cardNumberReadyDeferred.promise, cardCvcReadyDeferred.promise, cardExpiryReadyDeferred.promise];

                const cardNumberChangeDeferred = this.$q.defer();
                const cardCvcChangeDeferred = this.$q.defer();
                const cardExpiryChangeDeferred = this.$q.defer();
                const changePromisses = [cardNumberChangeDeferred.promise, cardCvcChangeDeferred.promise, cardExpiryChangeDeferred.promise];

                // Add change event listeners
                cardNumber.addEventListener('change', event => {
                    if (event.error) {
                        let errorMessageText = '';
                        switch (event.error.code) {
                            case 'invalid_number':
                                errorMessageText = this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._INVALID_CARD_NUMBER_');
                                break;
                            case 'incomplete_number':
                                errorMessageText = this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._MIN_LENGTH_');
                                break;
                            default:
                                errorMessageText = this.$translate.instant('_GENERAL_ERROR_');
                                break;
                        }

                        this.$('#card-number-errors').text(errorMessageText);
                    } else {
                        this.$('#card-number-errors').text('');
                    }

                    let newCradType;
                    if (event.brand === 'unknown') {
                        newCradType = 'default';
                    } else {
                        newCradType = event.brand;
                    }

                    this.$scope.$applyAsync(() => {
                        this.creditCardType = newCradType;
                    });

                    if (event.complete) {
                        cardNumberChangeDeferred.resolve();
                    }
                });
                cardNumber.addEventListener('ready', () => {
                    cardNumberReadyDeferred.resolve();
                });


                cardCvc.addEventListener('change', event => {
                    if (event.error) {
                        let errorMessageText = '';
                        switch (event.error.code) {
                            case 'incomplete_cvc':
                                // errorMessageText = this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._REQUIRED_FIELD_');
                                // break;
                            case 'invalid_cvc':
                                errorMessageText = this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._VALID_CVV_');
                                break;
                            default:
                                errorMessageText = this.$translate.instant('_GENERAL_ERROR_');
                                break;
                        }

                        this.$('#card-cvc-errors').text(errorMessageText);
                    } else {
                        this.$('#card-cvc-errors').text('');
                    }

                    if (event.complete) {
                        cardCvcChangeDeferred.resolve();
                    }
                });
                cardCvc.addEventListener('ready', () => {
                    cardCvcReadyDeferred.resolve();
                });

                cardExpiry.addEventListener('change', event => {
                    if (event.error) {
                        this.$('#card-expiry-errors').text(event.error.message);
                    } else {
                        this.$('#card-expiry-errors').text('');
                    }

                    if (event.complete) {
                        cardExpiryChangeDeferred.resolve();
                    }
                });
                cardExpiry.addEventListener('ready', () => {
                    cardExpiryReadyDeferred.resolve();
                });

                this.$q.all(readyPromisses).then(() => {
                    if (angular.isFunction(this.onFormReady)) {
                        this.$scope.$applyAsync(() => {
                            this.onFormReady();
                        });
                    }
                });

                this.$q.all(changePromisses).then(() => {
                    if (angular.isFunction(this.onFormComplete)) {
                        this.$scope.$applyAsync(() => {
                            this.wasFormCompleteCalled = true;
                            this.onFormComplete();
                        });
                    }
                });

                this.addInputListereners();

                this.StripeHelperService.registerStripeTokenProvider(this.tokenProviderId, this.$scope, (additionalData) => {
                    const deferred = this.$q.defer();
                    additionalData = angular.isObject(additionalData) ? additionalData : {};
                    if (!this.validateCardName()) {
                        deferred.reject({error: {message: this.$translate.instant('FILE.PAYMENT.ADD_NEW_CARD.ERRORS._FIX_INVALID_FIELDS_')}});
                    } else {
                        if(this.currency) {
                            additionalData["currency"] = this.currency;
                        }
                        additionalData = angular.extend({}, additionalData, this.card);
                        stripe.createToken(cardNumber, additionalData).then((result) => {

                            if (result.error) {
                                deferred.reject(result);
                            } else {
                                deferred.resolve(result.token);
                            }
                        }).catch((err) => {
                            deferred.reject({error: err});
                        });
                    }

                    return deferred.promise;
                });
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/common/ui_components/credit_card/new_credit_card_form_template.html',
            controller: NewCreditCardFormDirectiveController,
            controllerAs: 'creditCardVm',
            bindToController: true,
            link: function ($scope, $element) {
                $scope.creditCardVm.setRootElement($element);
                $scope.creditCardVm.initStripElements();
            },
            scope: {
                tokenProviderId: '=',
                onFormComplete: '&?', // Triggered when the card data is filled completely (not necessary valid data)
                onFormReady: '&?', // Triggered when the card form is fully loaded
                hideZipCode: '=?',
                currency: '@?'
            }
        };
    };

}());
