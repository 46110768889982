(function () {
    "use strict";

    // @ngInject
    function ReportsPaymentsControllerCtor($scope, $injector, $filter, $timeout, moment, $sce, $q, $stateParams, _,
                                           InitialAppLoadParamsService, WorkspaceFileService, PopupMessageService,
                                           AnalyticsService , UiPersistenceService, FeaturesService, ModalService, PlaidLinkService,
                                           OnboardingService, UsersManager, ReportsManager, CompaniesManager, MobileAppService,
                                           WorkspaceFilesManager, DeviceService, WorkspacesManager, UpcomingPaymentsService, FlowsManager, FlowService, $translate,
                                           StatsigService, Enums, AppConfigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReportsPaymentsController';
        this.$filter = $filter;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.WorkspaceFileService = WorkspaceFileService;
        this.AnalyticsService = AnalyticsService;
        this.ReportsManager = ReportsManager;
        this.PopupMessageService = PopupMessageService;
        this.FeaturesService = FeaturesService;
        this.WorkspacesManager = WorkspacesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.DeviceService = DeviceService;
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();
        this.FlowsManager = FlowsManager;
        this.FlowService = FlowService;
        this.PlaidLinkService = PlaidLinkService;
        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.AppConfigService = AppConfigService;
        this.MobileAppService = MobileAppService;
        this._ = _;
        this.$q = $q;
        this.moment = moment;
        this.$stateParams = $stateParams;
        this.loadingReports = false;
        this.currUser = UsersManager.getCurrUser();
        this.debitCard = this.currUser.myDebitCard();
        this.isHbAdmin = !!this.currUser.is_hb_admin;
        this.isCompanyAdmin = this.currUser.hasCompanyAdminPermissions();
        this.paymentsReminderInProgress = {};
        this.UiPersistenceService = UiPersistenceService;
        this.$sce = $sce;
        this.ModalService = ModalService;
        this.OnboardingService = OnboardingService;
        this.CompaniesManager = CompaniesManager;
        this.UpcomingPaymentsService = UpcomingPaymentsService;
        this.instantDepositsTimeFrame = 24 * 3600;
        this.isLateFeeEnabled = false;
        this.UsersManager = UsersManager;

        this.isReactMigrationGateOpen = false;
        // ng2react - payments page migration
        StatsigService.isGateEnabled('ng2react_payments_page').then(function (isEnabled) {
            this.isReactMigrationGateOpen = !!isEnabled;
        }.bind(this)).catch(function () {
            this.isReactMigrationGateOpen = false;
        }.bind(this));

        this.invoice_id = InitialAppLoadParamsService.getParamAndRemove('invoice');
        this.payment_parent_id = InitialAppLoadParamsService.getParamAndRemove('wid');
        this.is_flow_payment = InitialAppLoadParamsService.getParamAndRemove('flow') === 'true';
        this.bookkeeperMode = this.currUser.isBookkeeperMode();

        this.company = CompaniesManager.getCurrCompany();
        if (this.company.wasFullyFetched()) {
            this.gotCompany();
        }
        this.register(this.company, 'success', this.gotCompany);

        this.outstandingPaymentsFilters = {
            all: {index: 1, key: 'all', resource: 'REPORTS.PAYMENTS.FILTERS._ALL_', start: moment().add(-30, 'years'), end: moment().add(30, 'years')},
            overdue: {index: 2, key: 'overdue', resource: 'REPORTS.PAYMENTS.FILTERS._OVERDUE_', start: moment().add(-30, 'years'), end: moment().add(-1, 'days')},
            nextWeek: {index: 3, key: 'nextWeek', resource: 'REPORTS.PAYMENTS.FILTERS._NEXT_WEEK_', start: moment().add(-1, 'days'), end: moment().add(1, 'weeks')},
            nextMonth: {index: 4, key: 'nextMonth', resource: 'REPORTS.PAYMENTS.FILTERS._NEXT_MONTH_', start: moment().add(-1, 'days'), end: moment().add(1, 'months')},
            nextYear: {index: 5, key: 'nextYear', resource: 'REPORTS.PAYMENTS.FILTERS._NEXT_YEAR_', start: moment().add(-1, 'days'), end: moment().add(1, 'years')}
        };

        this.outstandingPaymentsFiltersArray = this._.values(this.outstandingPaymentsFilters);
        this.outstandingPaymentsActiveFilter = this.outstandingPaymentsFiltersArray[0];

        this.paidPaymentsFilters = {
            all: {index: 1, key: 'all', resource: 'REPORTS.PAYMENTS.FILTERS._ALL_', start: moment().add(-30, 'years'), end: moment().add(30, 'years')},
            recently: {index: 2, key: 'recently', resource: 'REPORTS.PAYMENTS.FILTERS._RECENTLY_', start: moment().add(-2, 'months'), end: moment().add(1, 'days')},
            lastSixMonths: {index: 3, key: 'lastSixMonths', resource: 'REPORTS.PAYMENTS.FILTERS._LAST_SIX_MONTHS_', start: moment().add(-6, 'months'), end: moment().add(1, 'days')},
            pastYear: {index: 4, key: 'pastYear', resource: 'REPORTS.PAYMENTS.FILTERS._PAST_YEAR_', start: moment().add(-1, 'years'), end: moment().add(1, 'days')}
        };

        this.paidPaymentsFiltersArray = this._.values(this.paidPaymentsFilters);
        this.paidPaymentsActiveFilter = this.paidPaymentsFiltersArray[0];

        this.loadPayments();

        this.setActiveOutstandingPaymentsFilter = function setActiveOutstandingPaymentsFilter(filterName) {
            this.outstandingPaymentsActiveFilter = this.outstandingPaymentsFilters[filterName];
            this.setShouldShowClientPaymentPlanDisclaimer();
        }.bind(this);

        this.setActivePaidPaymentsFilter = function setActivePaidPaymentsFilter(filterName) {
            this.paidPaymentsActiveFilter = this.paidPaymentsFilters[filterName];
        }.bind(this);

        this.filterOutstandingPayments = function filterOutstandingPayments(payment) {
            var date = moment(payment.payment_due_date);
            return (date.isBetween(this.outstandingPaymentsActiveFilter.start, this.outstandingPaymentsActiveFilter.end));
        }.bind(this);

        this.filterPaidPayments = function filterPaidPayments(payment) {
            var date = moment(payment.payment_charge_date);
            return (date.isBetween(this.paidPaymentsActiveFilter.start, this.paidPaymentsActiveFilter.end));
        }.bind(this);

        this.instantDepositsToggledThisSession = 0;

        this.StatsigService.isGateEnabled('invoice_late_fees').then(function (isEnabled) {
            this.isLateFeeEnabled = isEnabled;
        }.bind(this));

    }

    Controllers.ReportsPaymentsController = Class(Controllers.BaseController, {

        constructor: ReportsPaymentsControllerCtor,

        tryOpenOnApp: function tryOpenOnApp(event, payment){
            if(this.isInAppBrowser) {
                if (payment.workspace_file_id) {
                    this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.open_file, {file_id: payment.workspace_file_id});
                    event.preventDefault();
                } else if (payment.is_flow_payment) {
                    this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.open_flow, {flow_id: payment.flow_id} );
                    event.preventDefault();
                }
            }
        },

        loadPayments: function loadPayments() {
            this.payments = {};
            this.loadingReports = true;
            this.ReportsManager.getReportsPayments().then(function success(resp) {
                this.payments = resp.data;
                this.initialize();

                if (this.UpcomingPaymentsService.shouldShowUpcomingPaymentModal('payments')) {
                    this.UpcomingPaymentsService.showUpcomingPaymentModal('payments')
                        .then(function onSuccess(userDidTogglePayments){
                            if (userDidTogglePayments) {
                                this.loadPayments(); // refresh the list
                                this._promoteInstantDepositAutoToggle('upcomingPaymentsModal');
                            }
                        }.bind(this))
                        .catch(function err(error) {
                            console.warn('showUpcomingPaymentModal problem', error);
                        }.bind(this));
                }
            }.bind(this)).catch(function error(resp) {
                this.lastError = resp.data.error_message || resp.data;
            }.bind(this)).finally(function() {
                this.loadingReports = false;
            }.bind(this));
        },

        initialize: function initialize() {
            this.paidPayments = this.payments.paid_payments;
            if(this.paidPayments) {
                this.paidPayments.forEach(function (payment) {
                    this._enrichPaymentData(payment);
                }.bind(this));
            }

            this.outstandingPayments = [];
            if(this.payments.late_payments){
                this.payments.late_payments.forEach(function (payment){
                    this._enrichPaymentData(payment, 'late');
                }.bind(this));

                this.outstandingPayments = this.outstandingPayments.concat(this.payments.late_payments);
            }

            if(this.payments.upcoming_payments){
                this.payments.upcoming_payments.forEach(function (payment){
                    this._enrichPaymentData(payment, 'unpaid');
                }.bind(this));
                this.outstandingPayments = this.outstandingPayments.concat(this.payments.upcoming_payments);
            }

            if(this.payments.due_payments){
                this.payments.due_payments.forEach(function (payment){
                    this._enrichPaymentData(payment, 'due today');
                }.bind(this));
                this.outstandingPayments = this.outstandingPayments.concat(this.payments.due_payments);
            }

            if(this.payments.imminent_payments){
                this.payments.imminent_payments.forEach(function (payment){
                    this._enrichPaymentData(payment, 'upcoming');
                }.bind(this));
                this.outstandingPayments = this.outstandingPayments.concat(this.payments.imminent_payments);
            }

            if(this.payments.pending_payments){
                this.payments.pending_payments.forEach(function (payment){
                    this._enrichPaymentData(payment, 'pending');
                }.bind(this));
                this.outstandingPayments = this.outstandingPayments.concat(this.payments.pending_payments);
            }

            this.hasPayments = this.outstandingPayments.length > 0 || (this.paidPayments && this.paidPayments.length > 0);

            if(this.hasPayments) {
                this.dataReady = true;
            } else {
                this.showPaymentsEmptyState = true;
                this.dataReady = true;
            }

            // Filters handling
            this.outstandingPaymentsActiveFilter = this.outstandingPaymentsFilters.all;
            this.paidPaymentsActiveFilter = this.paidPaymentsFilters.all;

            this.outstandingPaymentsLimit = 5;
            this.hasMoreOutstanding = this.outstandingPaymentsLimit < this.outstandingPayments.length;

            this.paidPaymentsLimit = 5;
            this.hasMorePaid = this.paidPayments && (this.paidPaymentsLimit < this.paidPayments.length);

            if (this.$stateParams.section) {
                this.scrollToSection(this.$stateParams.section);
            }

            // Handling OAuth refund redirects
            const redirectData = this.PlaidLinkService.loadRedirectData();
            if (redirectData.isRedirect && !redirectData.redirectAutoOpened && redirectData.paymentId) {
                this.PlaidLinkService.markRedirectOpened();
                const payment = this.paidPayments.find(function(currPayment){
                    return currPayment._id === redirectData.paymentId;
                });

                var payment_parent_id = payment.workspace_file_id || payment.flow_id;
                this.ModalService.openRefundPaymentModal({
                    _id: payment._id,
                    amount: payment.payment_amount,
                    tip_paid: payment.payment_tip_paid,
                    transferred_to_vendor: payment.payment_transferred_to_vendor,
                    is_manual: payment.is_manual,
                    charge_date: payment.payment_charge_date
                }, payment_parent_id, false, true).then(this.loadPayments.bind(this));
            }

            this.setShouldShowClientPaymentPlanDisclaimer();
        },

        syncToQuickbooks: function syncToQuickbooks($event, payment, forceInvoiceCreation) {
            this.quickbooksSyncsInProgress = this.quickbooksSyncsInProgress || {};
            var qbSyncKey = this.getQBKey(payment);

            if (this.quickbooksSyncsInProgress[qbSyncKey]) {
                return;
            }

            $event.preventDefault();
            $event.stopPropagation();

            var promise = null;

            if (!this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.hideQBSyncModal, {hide: false}).hide) {
                promise = this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, 'REPORTS.PAYMENTS._ALL_FILES_ARE_SYNCED_WARNING_', 'got it');
            }
            else {
                promise = this.$q.when();
            }

            promise.then(function() {
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.hideQBSyncModal, {hide: true});
                this.quickbooksSyncsInProgress[qbSyncKey] = true;

                var promise;
                if (payment.is_flow_payment) {
                    promise = this.FlowsManager.syncToQuickbooks(qbSyncKey, forceInvoiceCreation);
                } else {
                    promise = this.WorkspaceFilesManager.syncToQuickbooks(qbSyncKey, forceInvoiceCreation);
                }

                promise.then(function success(resp) {
                    // We must change the quickbooks id for all the payments of this file

                    var qbPaymentsMapById = {};
                    angular.forEach(resp.data, function(payment) {
                        if (payment.quickbooks_id) {
                            qbPaymentsMapById[payment._id] = payment.quickbooks_id;
                        }
                    });

                    this.paidPayments.forEach(function(p) {
                        if (p.workspace_file_id === payment.workspace_file_id && qbPaymentsMapById[p._id]) {
                            p.payment_quickbooks_integration_id = qbPaymentsMapById[p._id];
                        }
                    });
                }.bind(this))
                    .catch(function error(resp) {
                        if (resp && resp.data && resp.data.error_type && resp.data.error_type === 'HBQBInvoiceNotFoundError') {
                            // Invoice that we tried to update is missing on QB database. Notify vendor and request a confirmation to create a new invoice

                            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.info, 'The invoice you are attempting to sync with cannot be located in your Quickbooks account. Would you like to create a new invoice for this file?',
                                function confirm() {
                                    this.syncToQuickbooks($event, payment, true);
                                }.bind(this),
                                function cancel() {

                                }.bind(this),
                                'FREQUENT_BUTTONS._CREATE_',
                                'FREQUENT_BUTTONS._CANCEL_'
                            );
                        }
                        else {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                        }
                    }.bind(this))
                    .finally(function final() {
                        this.quickbooksSyncsInProgress[qbSyncKey] = false;
                    }.bind(this));
            }.bind(this));
        },

        showMoreOutstandingPayments: function showMoreOutstandingPayments(){
            this.outstandingPaymentsLimit += 50;
            this.hasMoreOutstanding = this.outstandingPaymentsLimit < this.outstandingPayments.length;
            this.setShouldShowClientPaymentPlanDisclaimer();
        },

        showMorePaidPayments: function showMorePaidPayments(){
            this.paidPaymentsLimit += 50;
            this.hasMorePaid = this.paidPaymentsLimit < this.paidPayments.length;
        },

        isPaidAndDisputeReserved: function isPaidAndDisputeReserved(paymentData){
            return paymentData.payment_is_paid && paymentData.payment_dispute_amount_reversed > 0;
        },

        isPaidAndProcessedForDispute: function isPaidAndProcessedForDispute(paymentData){
            return paymentData.payment_is_paid && paymentData.payment_dispute_amount_reversed_lost > 0;
        },

        isPaymentPaid: function isPaymentPaid(paymentData) {
            return paymentData.payment_is_paid && (!paymentData.payment_refund_is_refunded && !paymentData.payment_refund_is_pending && !paymentData.payment_refund_is_error);
        },

        isPaymentDisputed: function isPaymentDisputed(paymentData) {
            return !!paymentData.payment_dispute_status;
        },

        paymentDisputedLabel: function paymentDisputedLabel(paymentData) {
            if (paymentData.payment_dispute_status === 'won' || paymentData.payment_dispute_status === 'warning_closed') {
                return 'PAYMENTS.LABELS._DISPUTED_WON_';
            } else if (paymentData.payment_dispute_status === 'lost') {
                return 'PAYMENTS.LABELS._DISPUTED_LOST_';
            } else if (paymentData.payment_dispute_status === 'partially_won') {
                return 'PAYMENTS.LABELS._DISPUTED_PARTIALLY_WON_';
            } else {
                return 'PAYMENTS.LABELS._DISPUTED_IN_PROCESS_';
            }
        },

        isPaymentRefundable: function isPaymentRefundable(paymentData) {
            return !this.currUser.isBookkeeperMode() && this.isPaymentPaid(paymentData) && paymentData;
        },

        isPaymentRefunded: function isPaymentRefunded(paymentData) {
            return !!paymentData.payment_refund_is_refunded;
        },

        isPaymentPartiallyRefunded: function isPaymentPartiallyRefunded(paymentData) {
            return !!paymentData.payment_refund_is_refunded && paymentData.payment_refund_amount < paymentData.payment_amount;
        },

        isPaymentRefundPending: function isPaymentRefundPending(paymentData) {
            return !!paymentData.payment_refund_is_pending || !!paymentData.payment_refund_is_client_pending_refund;
        },

        isPaymentRefundError: function isPaymentRefundError(paymentData) {
            return !!paymentData.payment_refund_is_error;
        },

        onRefundClick: function onRefundClick(payment, $event) {
            $event.preventDefault();

            if (!this.verifyRefundValid(payment)) {
                return;
            }

            this.WorkspaceFileService.openRefundModal(this.currUser, this.company, {
                _id: payment._id,
                amount: payment.payment_amount,
                tip_paid: payment.payment_tip_paid,
                transferred_to_vendor: payment.payment_transferred_to_vendor,
                is_manual: payment.is_manual,
                charge_date: payment.payment_charge_date,
                is_flow_payment: payment.is_flow_payment,
            }, (payment.workspace_file_id || payment.flow_id)).then(this.loadPayments.bind(this));
        },

        verifyRefundValid: function verifyRefundValid(payment) {

            var isOwnerMode = (this.currUser._id === payment.owner_id || (this.currUser.company._id === payment.company_id && this.currUser.hasCompanyAdminPermissions()));
            var isCanceled = !!payment.file_canceled;
            var isWorkspaceArchived = (payment.inactive_users && payment.inactive_users.indexOf(this.currUser._id) !== -1);
            var isFileDraft = payment.is_draft_editable && !payment.is_manual;

            var error = null;
            var valid = false;

            if (!isOwnerMode) {
                error = 'REPORTS.PAYMENTS._REFUND_NOT_OWNER_ERROR_';
            } else if (isCanceled) {
                error = 'REPORTS.PAYMENTS._REFUND_CANCELED_ERROR_';
            } else if (isWorkspaceArchived) {
                error = 'REPORTS.PAYMENTS._REFUND_WORKSPACE_ARCHIVED_ERROR_';
            } else if (isFileDraft) {
                error = 'REPORTS.PAYMENTS._REFUND_EDITABLE_DRAFT_ERROR_';
            } else {
                valid = true;
            }

            if (!valid) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, error);
            }

            return valid;
        },


        getPaymentLabelKey: function(paymentData) {
            if (this.isPaymentDisputed(paymentData)) {
                return this.paymentDisputedLabel(paymentData);
            } else if (this.isPaidAndProcessedForDispute(paymentData)) {
                return 'REPORTS.PAYMENTS._DISPUTE_RESERVED_LOST_';
            } else if (this.isPaidAndDisputeReserved(paymentData)) {
                return 'REPORTS.PAYMENTS._DISPUTE_RESERVED_';
            } else if (this.isPaymentRefundPending(paymentData)) {
                return 'REPORTS.PAYMENTS._REFUND_PENDING_';
            } else if (this.isPaymentPartiallyRefunded(paymentData)) {
                return 'REPORTS.PAYMENTS._PARTIALLY_REFUNDED_';
            } else if (this.isPaymentRefunded(paymentData)) {
                return 'REPORTS.PAYMENTS._REFUNDED_';
            } else if (this.isPaymentRefundError(paymentData)) {
                return 'REPORTS.PAYMENTS._REFUND_ERROR_';
            } else if (this.isPaymentPaid(paymentData)) {
                return 'REPORTS.PAYMENTS._PAID_';
            } else {
                // Should never hit this...
                return 'REPORTS.PAYMENTS._PAID_';
            }
        },

        getPaymentLabelKeyClass: function(paymentData) {
            var status = this.getPaymentLabelKey(paymentData)
            if(status === 'REPORTS.PAYMENTS._REFUND_PENDING_'){
                return 'refunding';
            }
            return status;
        },

        getPaymentLabelClass: function(paymentData) {
            if (this.isPaymentPaid(paymentData)) {
                return 'active-paid-icon';
            } else if (this.isPaymentPartiallyRefunded(paymentData)) {
                return 'active-partially-refunded-icon';
            } else if (this.isPaymentRefunded(paymentData)) {
                return 'active-refunded-icon';
            } else if (this.isPaymentRefundPending(paymentData)) {
                return 'active-refund-pending-icon';
            } else if (this.isPaymentRefundError(paymentData)) {
                return 'active-refund-error-icon';
            } else {
                // Should never hit this...
                return 'active-paid-icon';
            }
        },

        sendPaymentReminder: function sendPaymentReminder(payment) {

            if (!payment.is_flow_payment && !payment.file_sent_on) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'you can\'t send a reminder for an unsent ' + payment.file_type);
                return;
            }

            if (this.paymentsReminderInProgress[payment._id]) {
                return;
            }

            this.paymentsReminderInProgress[payment._id] = true;

            var reminderPromise;

            if (!payment.is_flow_payment) {
                reminderPromise = this.ReportsManager.sendPaymentReminder(payment.workspace_file_id, payment);
            } else {
                reminderPromise = this.FlowsManager.sendPaymentReminder(payment.flow_id, payment._id).then(function success(data) {
                    payment.payment_last_reminder_timestamp = new Date().getTime();
                    return data;
                });
            }

            reminderPromise.then(function success() {
                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.payment_reminder_sent, {
                    source: 'reports',
                    payment_id: payment._id
                });
            }.bind(this)).catch(function error(resp) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.payment_reminder_error, resp, {
                    source: 'reports',
                    payment_id: payment._id
                });
            }.bind(this)).finally(function final() {
                this.paymentsReminderInProgress[payment._id] = false;
            }.bind(this));
        },

        showWaiveLateFeeModal: function showWaiveLateFeeModal(payment) {
            this.ModalService.openWaiveLateFeeModal('payments', payment).then(
                function onSuccess(){
                    payment.payment_late_fee = 0;
                }.bind(this)
            );
        },

        clickToggleInstantDeposit: function clickToggleInstantDeposit(e) {
            e.stopPropagation();
            if(!this.debitCard) {
                this.openDebitCardModal();
            }
        },

        openDebitCardModal: function openDebitCardModal(paymentParentId, invoiceId, isFlowPayment) {
            this.ModalService.openAddDebitCardModal('bookkeeping').then(
                function onSuccess(paymentMethod) {
                    this.UsersManager.forceFetchCurrUser().then(function () {
                        this.debitCard = this.currUser.myDebitCard();
                        if (this.debitCard && paymentParentId && invoiceId) {
                            // deposit now flow
                            this.openInstantDepositModal('debit card modal', paymentParentId, invoiceId, isFlowPayment);
                        }
                    }.bind(this));
                }.bind(this)
            ).catch(
                function onFailure(resp){
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.open_debit_card_modal, resp);
                }.bind(this));
        },

        allowChargedInstantDeposit: function allowChargedInstantDeposit(payment) {
            return (!payment.use_instant_payout) && payment.allow_instant_payout &&
                payment.allow_charged_instant_payout;
        },

        shouldShowAsTbd: function shouldShowAsTbd(payment) {
            return ((payment.is_milestone && !payment.payment_milestone_completed_on) || payment.payment_date_is_tbd);
        },

        shouldShowAutoPay: function shouldShowAutoPay(payment) {
            return (!payment.payment_is_paid && (payment.auto_pay_status === 'active')) ||
                (payment.payment_is_paid && payment.payment_is_autopaid);
        },

        autoPayIndicationTitle: function autoPayIndicationTitle(payment) {
            if(payment.payment_is_paid){
                return this.$translate.instant('REPORTS.PAYMENTS._TOOLTIP_AUTOPAY_PAID_', {
                    chargedDate: this.moment(payment.payment_charge_date).format('MMM DD, YYYY')
                });
            }
            return this.$translate.instant('REPORTS.PAYMENTS._TOOLTIP_AUTOPAY_NOT_PAID_');
        },

        isWithinTimeFrame: function isWithinTimeFrame(chargeDate) {
            var maxDateForInstant = this.moment(chargeDate).add(1, 'days');
            return this.moment().isBefore(maxDateForInstant);
        },

        setShouldShowClientPaymentPlanDisclaimer: function showClientPaymentPlanDisclaimer() {
            var filteredPayments = this.$filter('filter')(this.outstandingPayments, this.filterOutstandingPayments);
            filteredPayments = this.$filter('orderBy')(filteredPayments, '+payment_due_date');
            filteredPayments = this.$filter('limitTo')(filteredPayments, this.outstandingPaymentsLimit);

            var hasClientPaymentPlan = filteredPayments.some(function(payment){
                return payment.is_client_payment_plan && !payment.is_recurring_payment;
            });

            this.showClientPaymentPlanDisclaimerBannerForMobile = hasClientPaymentPlan &&
                !this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.clientDismissedCPPDisclaimerBanner, {value: false}).value;
            this.showClientPaymentPlanDisclaimer = hasClientPaymentPlan;
        },

        dismissClientPaymentPlanBanner: function dismissClientPaymentPlanBanner() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.clientDismissedCPPDisclaimerBanner, {value: true});
            this.showClientPaymentPlanDisclaimerBannerForMobile = false;
        },

        promoteInstantDepositAutoToggle: function promoteInstantDepositAutoToggle() {
            this._promoteInstantDepositAutoToggle('payments');
        },

        _promoteInstantDepositAutoToggle: function _promoteInstantDepositAutoToggle(src) {
            if (this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.promoteInstantDepositAutoToggleState, {shown: false}).shown === false) {
                this.ModalService.openInstantDepositPromoteAutoModal(src).then(
                    function success(companyAllInclusiveInstantPayoutsToggled) {
                        if (companyAllInclusiveInstantPayoutsToggled) {
                            this.loadPayments();
                            this.CompaniesManager.getCurrCompany(true); // refresh
                        }
                    }.bind(this));

                // either way show only once
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.promoteInstantDepositAutoToggleState, {shown: true});
            }
        },

        togglePaymentInstantPayout: function togglePaymentInstantPayout(payment) {
            if(!this.debitCard) {
                console.warn('offered the toggle but debit card')
                return;
            }

            var promise;
            if (payment.is_flow_payment) {
                promise = this.FlowsManager.markPaymentAsInstantPayout(payment.flow_id, payment._id, payment.use_instant_payout, 'payments_tab_bookkeeping');
            } else {
                promise = this.WorkspaceFilesManager.markPaymentAsInstantPayout(payment.workspace_file_id, payment._id, payment.use_instant_payout, 'payments_tab_bookkeeping')
            }

            promise.then(
                function onSuccess(resp) {
                    this.instantDepositsToggledThisSession += (payment.use_instant_payout ? 1 : -1);

                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.toggle_instant_payment, {newState: payment.use_instant_payout});
                    const respData = resp && resp.data;
                    if (!this.company.all_inclusive_instant_payouts &&
                        respData && respData.toggle_success && respData.use_instant_payout) {
                        if (
                            // has a fully transacted instant payment
                            respData.adopted_instant_payouts ||
                            //OR
                            // multiple toggle in this session
                            this.instantDepositsToggledThisSession > 1
                        ) {
                            this.promoteInstantDepositAutoToggle();
                        }
                    }
                }.bind(this)
            ).catch(function onError(resp) {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.toggle_instant_payment, resp);
                if (resp.data && resp.data.error_message) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                } else {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                }
            }.bind(this));
        },

        onDepositNowClick: function onDepositNowClick(event, payment) {
            event.stopPropagation();

            var paymentParentId, isFlowPayment;
            if (payment.workspace_file_id) {
                paymentParentId = payment.workspace_file_id;
                isFlowPayment = false;
            } else {
                paymentParentId = payment.flow_id;
                isFlowPayment = true;
            }

            if(!this.debitCard) {
                this.openDebitCardModal(paymentParentId, payment.payment_invoice, isFlowPayment);
            } else {
                this.openInstantDepositModal('bookkeeping', paymentParentId, payment.payment_invoice, isFlowPayment);
            }
        },

        openInstantDepositModal: function openInstantDepositModal(source, paymentParentId, invoiceId, isFlowPayment) {
            this.ModalService.openInstantDepositModal(source, paymentParentId, invoiceId, isFlowPayment).then(
                function onSuccess() {
                    this.loadPayments();
                }.bind(this)
            ).catch(
                function onFailure() {

                }.bind(this)
            );
        },

        gotCompany: function gotCompany() {
            // show instant deposit. will not succeed if allow_instant_payout will be falsey.
            // note: eligible_for_instant_payouts is actually enough, left allow_instant_payouts to avoid reload
            this.showInstantDeposit =
                !this.company.eligible_for_finance_app_payments &&
                !!this.currUser.myBankAccount() &&
                (this.company.eligible_for_instant_payouts || this.company.allow_instant_payouts);

            // if eligible but not allowed, its paused (disputes, recollections, fraud, etc.)
            this.instantDepositPaused =
                this.company.eligible_for_instant_payouts &&
                !this.company.allow_instant_payouts;

            this.showInstantDepositsIntro = this.showInstantDeposit && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.instantDeposits, {show: true}).show;



            if(this.payment_parent_id && this.invoice_id) {
                // loaded with context file and invoice, meaning its a click from a "deposit now" flow
                if(!this.debitCard) {
                    this.openDebitCardModal(this.payment_parent_id, this.invoice_id, this.is_flow_payment);
                } else {
                    this.openInstantDepositModal('email', this.payment_parent_id, this.invoice_id, this.is_flow_payment);
                }
            }
        },

        hideInstantDepositsIntro: function hideInstantDepositsIntro() {
            this.showInstantDepositsIntro =  false;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.instantDeposits, {show: this.showInstantDepositsIntro});
        },

        reminderInProgress: function reminderInProgress(payment) {
            return this.paymentsReminderInProgress[payment._id] === true;
        },

        _getPayoutLabel: function _getPayoutLabel(payment) {
            return "Est. deposit: " + this.$filter('date')(payment.estimated_payout_date, 'MMM dd', 'UTC');
        },

        scrollToSection: function scrollToSection(section) {
            this.$timeout(function () {
                var elm = $('#' + section);
                $("html, body").animate({scrollTop: elm.offset().top - 180}, 300);
            }, 1000);
        },

        invoiceNumberSmall: function invoiceNumberSmall(invoice) {
            if (invoice) {
                return '#' + invoice.slice(0, 1) + '...' + invoice.slice(-3)
            }
        },

        toggleAccordion: function toggleAccordion() {
            this.isOpen = !this.isOpen;
        },

        shouldShowUnsync: function shouldShowUnsync(payment) {
            return this.currUser.isAdminLogin() && !!(payment.payment_quickbooks_integration_id);
        },

        onClickUnsync: function onClickUnsync(payment) {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to unsync this QB payment?').then(function yes() {
                this.unsyncConfirmed(payment);
            }.bind(this));
        },

        unsyncConfirmed: function unsyncConfirmed(payment) {
            this.quickbooksSyncsInProgress = this.quickbooksSyncsInProgress || {};
            var qbSyncKey = this.getQBKey(payment);

            if (this.quickbooksSyncsInProgress[qbSyncKey]) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "Unsync already in progress. Refresh the page if not resolved");
                return;
            }

            this.quickbooksSyncsInProgress[qbSyncKey] = true;

            var promise;

            if (payment.is_flow_payment) {
                promise = this.FlowsManager.deleteQuickbooksPayment(payment.flow_id, payment._id);
            } else {
                promise = this.WorkspaceFilesManager.unsyncQbPayment(payment.workspace_file_id, payment._id);
            }

            promise.then(function success() {
                payment.payment_quickbooks_integration_id = null;
            }.bind(this)).catch(function error(resp) {
                if (resp && resp.data && resp.data.error_type) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                } else {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "Not working :(");
                }
            }.bind(this)).finally(function final() {
                this.quickbooksSyncsInProgress[qbSyncKey] = false;
            }.bind(this));
        },

        getQBKey: function getQBKey(payment) {
            return payment.workspace_file_id || payment.flow_id;
        },

        showEstimatedPayoutDate: function showEstimatedPayoutDate(payment) {
            return payment.estimated_payout_date &&
                payment.estimated_payout_date !== null &&
                !this.isPaidAndProcessedForDispute(payment) &&
                !this.isPaidAndDisputeReserved(payment)
        },

        onClickRequestPayment: function onClickRequestPayment(payment, $event) {
            $event.stopPropagation();

            this.WorkspacesManager.getWorkspace(payment.couple_card_id, false, false, true)
                .then(function onSuccess(workspace) {
                    var workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(payment.workspace_file_id || payment.flow_id);
                    return this.WorkspaceFileService.requestPayment(this.currentUser, workspace, workspaceFile, payment);
                }.bind(this))
                .then(function onSuccess() {
                    payment.payment_milestone_completed_on = new Date();
                }.bind(this));
        },

        _enrichPaymentData: function _enrichPaymentData(payment, status) {
            payment.fileStatus = this.WorkspaceFileService.getStatusLabelForVendor(payment.file_type_cd, payment.file_status_cd);
            payment.file_type = payment.is_flow_payment ? 'Invoice' : this.WorkspaceFileService.getFileType(payment.file_type_cd);
            payment.estimated_payout_label = this._getPayoutLabel(payment);

            if (!!status) {
                payment.status = status;
            }
        },

        onMouseLeaveInfoIcon: function onMouseLeaveInfoIcon() {
            setTimeout(function() {
                if (!this.mouseIsOnTooltipContent) {
                    this.showTooltip = false;
                }
            }.bind(this),200)
        },

        onMouseLeaveTooltipContent: function onMouseLeaveInfoIcon() {
            this.mouseIsOnTooltipContent = false;
            this.showTooltip = false;
        }
    });
}());
