(function () {
    'use strict';

    Directives.CreateWorkspaceDropdownDirective = function CreateWorkspaceDropdownDirective() {
        // @ngInject
        function CreateWorkspaceDropdownDirectiveControllerCtor($scope, $injector) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CreateWorkspaceDropdownDirectiveController';
        }

        var CreateWorkspaceDropdownDirectiveController = Class(Controllers.BaseController, {
            constructor: CreateWorkspaceDropdownDirectiveControllerCtor,
            
            onAddWorkspaceToEvent: function addToWorkspaceToEvent(workspaceType, source) {
               this.addWorkspaceToEvent({workspaceType: workspaceType, source: source});
            }
        });


        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/event/create_workspace/create_workspace_dropdown_directive_template.html',
            controller: CreateWorkspaceDropdownDirectiveController,
            controllerAs: 'createWorkspaceMenuVm',
            bindToController: true,
            scope: {
                addWorkspaceToEvent: '&addWorkspaceToEvent'
            }
        };
    };

}());

