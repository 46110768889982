/**
 * Created by iggy on 7/6/15.
 */

(function () {
    "use strict";

    /* NOTES
    *  dropdownselector value needs to match the id of the dropdown container
    *  position: top - maybe later we can have other positions
    * */


    // @ngInject
    Directives.EmailEditorDropDownPositionDirective = function EmailEditorDropDownPositionDirective() {
        return {
            restrict: 'A',
            scope: {
              hbDropdownPosition: '@',
              hbDropdownSelector: '@'
            },
            link: function ($scope, $elem, $attrs) {

                $elem.on('click', function(){
                    var desirePosition = $attrs.hbDropdownPosition;
                    var dropDownSel = $('#' + $attrs.hbDropdownSelector);
                    var dropDownSelHeight = $(dropDownSel).height();
                    var positionTop = '-' + (dropDownSelHeight + 15) + 'px';

                        if (desirePosition === 'top') {
                            dropDownSel.css ({
                               "position": "absolute",
                                "top": positionTop
                            });
                        }
                });
            }
        };
    };

}());