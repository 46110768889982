/**
 * Created by inonstelman on 12/29/14.
 */
(function () {
    "use strict";


    // @ngInject
    function UsersManagerCtor(APIService, Routes, ModelFactory, RepositoryService, AppConfigService, InitialAppLoadParamsService,
                              Constants, $rootScope, $q, Enums, NotificationService, FullStoryService, Gon, $translate, moment, $timeout,
                              HttpAuthInterceptor, $window, $state, AppStates, AnalyticsService, AbTestService, PubSubService, TOSManager,
                              WebsocketHelperService, GoogleAPIService, ServiceLazyLoaderService,
                              _, PopupMessageService, Fingerprint, $localStorage, $cookies, CouponsService, DeviceService,
                              FeaturesService, GoogleTagManagerService, FirebaseAPIService, RecaptchaService,
                              $injector, DatadogRUMService, DatadogLOGSService, ForterHelperService, AxiosInterceptorService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.RepositoryService = RepositoryService;
        this.AppConfigService = AppConfigService;
        this.Constants = Constants;
        this.$rootScope = $rootScope;
        this.Enums = Enums;
        this.FullStoryService = FullStoryService;
        this.DatadogRUMService = DatadogRUMService;
        this.DatadogLOGSService = DatadogLOGSService;
        this.NotificationService = NotificationService;
        this.$window = $window;
        this.DeviceService = DeviceService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.Gon = Gon;
        this.WebsocketHelperService = WebsocketHelperService;
        this.GoogleAPIService = GoogleAPIService;
        this.FirebaseAPIService = FirebaseAPIService;
        this.FacebookService = ServiceLazyLoaderService.get('FacebookService');
        this.Maxmind = ServiceLazyLoaderService.get('Maxmind');
        this.moment = moment;
        this.initialUrlSearchParams = InitialAppLoadParamsService.getAllLoadParams();
        this.didIdentifiedUser = false;
        this.AppStates = AppStates;
        this.$state = $state;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.PopupMessageService = PopupMessageService;
        this._ = _;
        this.$translate = $translate;
        this.Fingerprint = Fingerprint;
        this.TOSManager = TOSManager;
        this.$localStorage = $localStorage;
        this.$q = $q;
        this.$cookies = $cookies;
        this.$timeout = $timeout;
        this.CouponsService = CouponsService;
        this.FeaturesService = FeaturesService;
        this.GoogleTagManagerService = GoogleTagManagerService;
        this.$injector = $injector;
        this.RecaptchaService = RecaptchaService;
        this.ForterHelperService = ForterHelperService;

        PubSubService.ventMyBitchUp(this);
        AxiosInterceptorService.axiosRegisterUserIdHeaderListener(this)
        HttpAuthInterceptor.registerUserIdHeaderListener(this);

        this.onSuccess(function (data, status, headers) {
            var isAxios =  typeof headers !== 'function'
            var lastUserApiVersion = isAxios? parseInt(headers[this.Gon.hb_api_headers.api_client_version]) : parseInt(headers(this.Gon.hb_api_headers.api_client_version));
            var lastUserServerUpdate = isAxios ? parseInt(headers[this.Gon.hb_api_headers.last_user_update]) : Date.parse(headers(this.Gon.hb_api_headers.last_user_update));
            var lastUserClientUpdate = isAxios ? parseInt(headers[this.currUser.last_user_update]) : Date.parse(this.currUser.last_user_update);

            // lastUserApiVersion & lastUserServerUpdate can be NaN if coming from reset password screen
            if ((!isNaN(lastUserApiVersion) && this.currUser.api_version !== lastUserApiVersion) ||
                (!isNaN(lastUserServerUpdate) && lastUserClientUpdate < lastUserServerUpdate)) {
                this.forceFetchCurrUser();
            }

            // tos version
            var tosAcceptanceNeeded = isAxios? headers[this.Gon.hb_api_headers.tos_update_needed] : headers(this.Gon.hb_api_headers.tos_update_needed) === 'true';
            if (tosAcceptanceNeeded && this.currUser._id) {
                this.TOSManager.TOSAcceptanceNeeded(this.currUser);
            }

        }.bind(this));

        this.onError(Gon.hb_base_error_codes.wrong_api_version_error, function (error) {
            this.$window.location.reload(); // this is sent from server if versions diff is greater than API_VERSION_FORCE_RELOAD_DIFF (non graceful)
        }.bind(this));

        this.onError(Gon.hb_base_error_codes.unauthorized_error, function unauthorizedErrorCallback() {

            var stop = (typeof this.currUser.authentication_token !== 'undefined');
            this.logout().then(function afterLogout() {
                $state.go(AppStates.root_core_login);
            }.bind(this));

            //stop event propagation
            return stop;

        }.bind(this));

        this.InitialAppLoadParamsService.cleanParamsFromView('error', 'error_reason', 'error_description', 'code', 'auth', 'org_redirect_uri', 'discountCode', 'promo');

        this.on('loggingOut', function onLogout() {
            AnalyticsService.track(this, 'user logging out. disconnecting from moses');
            this.WebsocketHelperService.disconnect();
            this._connectToMosesCalled = false;
            this.InitialAppLoadParamsService.clearOnLogout();
            this.TOSManager.dismissTosToast();
            this.AppConfigService.setWebNotificationsFetched(false);
            this.AppConfigService.setWebNotificationsSettingsInitiallyFetched(false);
            this.AppConfigService.setTasksCountInitiallyFetched(false);
            this.FullStoryService.stopTracking();
            this.AnalyticsService.anonymizeUser();
            this.didIdentifiedUser = false;
        }.bind(this));

        this.on('loggingIn', function onLogin() {
            this._removeOneTrustCookieBanner();
            this._connectToMoses('user is already logged in. connecting to moses');
            this._fillReferralCode();
            this._applyCoupon(false);
            this.AbTestService.onLogin();
            this.GoogleTagManagerService.onLogin();
            this.$timeout(function () {
                this.identifyUser(this.currUser);
                this.handleTrack();
            }.bind(this), 0);
        }.bind(this));

        this.on('loginLess', function onLogin() {
            // I'm afraid to uncomment, so added a card and erez will check
            // this._connectToMoses('user is already logged in. connecting to moses');
            // this._fillReferralCode();
            // this._applyCoupon(false);
            this.AbTestService.onLogin();
            this.GoogleTagManagerService.onLogin();
            this.$timeout(function() {
                this.identifyUser(this.currUser);
                this.handleTrack();
            }.bind(this), 0);
        }.bind(this));

        if (this.isLoggedIn()) {
            this._removeOneTrustCookieBanner();
            // We're using timeout so the constructor wil be done and then we can load the user model,
            // otherwise we get a circular dependency error
            $timeout(function () {
                this._connectToMoses('user logged in. connecting to moses');

                this._fillReferralCode();
                this._applyCoupon(true);
                this._completeSocialAccountAuth();
                this.GoogleTagManagerService.onLogin();
                this.$injector.get('FeatureRestrictionService').checkIfBlockedAccess({
                    source: 'app_load',
                    actionType: 'load'
                });
                this.$timeout(function() {
                    this.identifyUser(this.currUser);
                    this.handleTrack();
                }.bind(this), 0);
            }.bind(this), 500);
        }

        this.OOO_IN_EFFECT_MODAL_COOKIE_NAME = 'ooo_in_effect_modal';
    }


    Services.UsersManager = Class(Services.BaseModelManager, {

        constructor: UsersManagerCtor,

        _removeOneTrustCookieBanner: function _removeOneTrustCookieBanner(){
            const oneTrustCookieBanner = document.getElementById('onetrust-consent-sdk');
            if (oneTrustCookieBanner){
                oneTrustCookieBanner.remove();
            }
        },

        _setCurrUser: function _setCurrUser(user) {
            var self = this;
            self.currUser = user;

            self._unwatchCurrUser();
            //we set up a shallow 1-level watch on the user so that it will be up to date in the repository
            self.unbindUserWatchFn = this.$rootScope.$watchCollection(function () {
                return self.currUser;
            }, function (newVal) {
                self.saveCurrUser();
            });

            self.trigger('currentUserChanged', user);

            return self.currUser;
        },

        _completeSocialAccountAuth: function _completeSocialAccountAuth() {
            var code = this.InitialAppLoadParamsService.getParam('code'); // caution: also used in referral..
            var authParam = this.InitialAppLoadParamsService.getParamAndRemove('auth');
            var redirectUri = this.InitialAppLoadParamsService.getParamAndRemove('org_redirect_uri');


            if (authParam && code && (authParam === 'instagram')) {
                // ok its not referral code, can remove
                this.InitialAppLoadParamsService.removeParam('code');
                var analyticsData = {social_network_type: authParam};
                this.trigger(authParam + 'RegisteringCode');

                // auth with social provider to get an access token from the params
                this.connectSocialAccount(this.currUser, authParam, code, redirectUri).then(
                    function success() {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connecting_social_network, analyticsData);
                        this.trigger(authParam + 'Connected');
                    }.bind(this),
                    function error(resp) {
                        // show error message
                        this.trigger(authParam + 'OnError');
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, analyticsData);
                        this.$translate('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', {socialAccountType: authParam.charAt(0).toUpperCase() + authParam.slice(1)}).then(function translateResolved(errorMessage) {
                            if (resp.data && resp.data.error_type && resp.data.error_type === 'HBUserError') {
                                errorMessage = errorMessage + ': ' + resp.data.error_message;
                            }
                            this.PopupMessageService.showErrorAlert(errorMessage);
                        }.bind(this));
                    }.bind(this)
                );
            } else {
                var error = this.InitialAppLoadParamsService.getParam('error');
                var error_reason = this.InitialAppLoadParamsService.getParam('error_reason');
                var error_description = this.InitialAppLoadParamsService.getParam('error_description');
                if (authParam && error && error_reason && error_description){
                    // indeed, this is a social account auth fail
                    this.InitialAppLoadParamsService.removeParam('error');
                    this.InitialAppLoadParamsService.removeParam('error_reason');
                    this.InitialAppLoadParamsService.removeParam('error_description');
                    if (error_reason !== 'user_denied'){
                        this.PopupMessageService.showErrorAlert(authParam + ": " + error_description);
                    }
                }
            }
        },

        _clearCurrUser: function _clearCurrUser() {

            if (this.currUser) {

                this._unwatchCurrUser();
                this.RepositoryService.storageDelete(this.Constants.storage.CURR_USER);
                this.RepositoryService.storagePut(this.Constants.storage.API_VERSION);
                this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN);
                this.RepositoryService.storagePut(this.Constants.storage.AUTH_USER_ID);
                this.RepositoryService.storagePut(this.Constants.storage.IS_TEMP_PASS);
                Object.keys(this.currUser).forEach(function (key) {
                    delete this.currUser[key];
                }.bind(this));
                this.currUser = undefined;

                this.trigger('currentUserChanged', this.currUser);
            }
        },

        _unwatchCurrUser: function _unwatchCurrUser() {

            if (typeof this.unbindUserWatchFn === 'function') {
                this.unbindUserWatchFn();
                this.unbindUserWatchFn = undefined;
            }
        },

        _connectToMoses: function _connectToMoses(desc) {
            if (this._connectToMosesCalled) {
                return;
            }
            this._connectToMosesCalled = true;

            // this.AnalyticsService.track(this, desc);
            this.WebsocketHelperService.connect();
            this.WebsocketHelperService.registerToRoom('version_updates', this.versionUpdate.bind(this));
            var currUser = this.getCurrUser();

            if (currUser.isVendor()) {
                this.WebsocketHelperService.registerToRoom(currUser._id + ".activity_notifications", this.updateActivityNotificationsWebsocket.bind(this));
                this.WebsocketHelperService.registerToRoom(currUser._id + ".profile_image", this.updateProfileImageWebsocket.bind(this));
                this.WebsocketHelperService.registerToRoom(currUser._id + ".payment_received", this.paymentReceivedUpdate.bind(this));
                this.WebsocketHelperService.registerToRoom(currUser._id + ".referrals", this.referralsReceivedUpdate.bind(this));
                this.WebsocketHelperService.registerToRoom(currUser._id + ".user_activated", this.userActivatedUpdate.bind(this));
                this.WebsocketHelperService.registerToRoom(currUser._id + ".gmail_revoked", this.onGmailTokenRevoked.bind(this));
            }
        },

        _fillReferralCode: function _fillReferralCode() {
            //auth instagram also use code, so lets make sure its a referral code
            if (!this.initialUrlSearchParams['auth'] && this.$window.location.href.indexOf("zoomOAuth2Callback") === -1 && this.$window.location.href.indexOf("nylasOAuth2Callback") === -1) {
                var referralCode = this.initialUrlSearchParams['code'];
                var referralSource = this.initialUrlSearchParams['referral_source'];
                this.AnalyticsService.track(this, '[ReferralCode] fill referral', {code: referralCode, referrer: document.referrer, initialParams: Object.entries(this.initialUrlSearchParams).flat()});
                if (referralCode) {
                    var currUser = this.getCurrUser();
                    var url = this.Routes.v2_user_referral_info_path(currUser._id);
                    var data = {
                        referral_code: referralCode,
                        referral_source: referralSource
                    };
                    this.apiUpdate(url, data);
                }
            }
        },

        _applyCoupon: function _applyCoupon(isLoggedIn) {
            var currUser = this.getCurrUser();
            var coupon = this.initialUrlSearchParams['promo'] || this.initialUrlSearchParams['discountCode'];
            if(coupon) {
                this.CouponsService.applyCoupon(coupon);
            }

            if (coupon && (currUser.isInTrialAndNotSubscribed() || currUser.hasTrialExpired())) {
                this.apiUpdate(
                    this.Routes.v2_user_tags_path(currUser._id),
                    {tags: {promo: coupon}});

                // redirect to settings/company/subscription with promo code
                var state = this.AppStates.root_core_navigation_settings_company_subscription,
                    stateParams = {promo: coupon};
                var shouldRedirectToPricingPage = isLoggedIn && this.$state.current.name !== state;
                if (shouldRedirectToPricingPage) {
                    this.$state.go(state, stateParams);
                } else {
                    // we cant DI RedirectService coz its circular, so we mark here and RedirectService will check it.
                    this.shouldRedirectAfterLogin = {state: state, stateParams: stateParams};
                }
            }
        },

        reportPlaidEvent: function reportPlaidEvent(eventName, metadata) {
            const event = {eventName, metadata};
            if (this.batchedPlaidEvents) {
                this.batchedPlaidEvents.push(event);
            } else {
                this.batchedPlaidEvents = [event];
                this.$timeout(() => {
                        this.apiCreate(
                            this.Routes.v2_report_plaid_event_path(this.getCurrUser()._id),
                            {events: this.batchedPlaidEvents});
                        this.batchedPlaidEvents = null;
                    }, 500);
            }
        },



        addTagsToCurrUser: function addTagsToCurrUser(tagsObj) {
            var currUser = this.getCurrUser();

            return this.apiUpdate(
                this.Routes.v2_user_tags_path(currUser._id),
                {tags: tagsObj});
        },

        saveMfaSession: function saveMfaSession(mfaSession){
            this.RepositoryService.storagePut(this.Constants.storage.MFA_SESSION, mfaSession);
        },

        saveCurrUser: function saveCurrUser() {
            var currUser = this.getCurrUser();
            if (!currUser) {
                return;
            }

            var currUserDataOnly = currUser.dataOnly();
            var size = this.roughSizeOfObject(currUserDataOnly);
            if (size > 5000000) {
                window.console.log('User data local storage size warning: ' + size);
            }
            delete currUserDataOnly.password;
            this.RepositoryService.storagePut(this.Constants.storage.CURR_USER, currUserDataOnly);
            this.RepositoryService.storagePut(this.Constants.storage.API_VERSION, currUser.api_version);
            this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN, currUser.authentication_token);
            this.RepositoryService.storagePut(this.Constants.storage.AUTH_USER_ID, currUser.id());
            this.RepositoryService.storagePut(this.Constants.storage.IS_TEMP_PASS, currUser.is_temp_pass_login);

            if (currUser.trusted_device) {
                this.RepositoryService.storagePut(this.Constants.storage.TRUSTED_DEVICE, currUser.trusted_device);
            }

            if(this.currUser.email) {
                this.$localStorage.hb_user_details = {
                    email: this.currUser.email,
                    first_name: this.currUser.first_name,
                    last_name: this.currUser.last_name,
                    full_name: this.currUser.full_name
                };
            }

            this.AppConfigService.setStripeKey(currUser.stripe_key);
            this.AppConfigService.setPlaidConfig(currUser.plaid_public_key, currUser.plaid_env);

            this.identifyUser(currUser);

            // TODO:(removed AppCues - intercom?)

            this.NotificationService.reset(currUser);
        },

        roughSizeOfObject: function roughSizeOfObject(object) {
            try {
                var objectList = [];
                var stack = [object];
                var bytes = 0;

                while (stack.length) {
                    var value = stack.pop();

                    if (typeof value === 'boolean') {
                        bytes += 4;
                    }
                    else if (typeof value === 'string') {
                        bytes += value.length * 2;
                    }
                    else if (typeof value === 'number') {
                        bytes += 8;
                    }
                    else if
                        (
                        typeof value === 'object'
                        && objectList.indexOf(value) === -1
                    ) {
                        objectList.push(value);

                        for (var i in value) {
                            stack.push(value[i]);
                        }
                    }
                }
                return bytes;
            }
            catch (err) {
                window.console.error("Error calculating object size");
            }
        },

        shouldTrackUser: function shouldTrackUser() {
            return this.currUser.is_fullstory_record_enabled;
        },

        handleTrack: function handleTrack(force) {
            this.DatadogRUMService.initTracking();
            if (force || this.shouldTrackUser()) {
                this.FullStoryService.initTracking(this.currUser);
            } else {
                this.FullStoryService.stopTracking(this.currUser);
            }
        },

        checkCurrUserHeader: function checkCurrUserHeader(idFromHeader) {
            var currUser = this.getCurrUser();
            if (currUser && currUser._id && idFromHeader && idFromHeader !== currUser._id) {
                this.$window.location.reload();
            }

            if (currUser && currUser.isAdminLogin() && !currUser.isAdmin()) {

                let lastAction = currUser.lastAction;
                let now = Date.now();
                currUser.lastAction = now;
                if ((now - lastAction) > (60 * 60 * 1000)) {

                    console.log('Admin login - idle time too long, logging out');
                    this.logout();
                }
            }
        },

        obtainFBPermissions: function obtainFBPermissions(requiredPermissions) {

            var currUser = this.getCurrUser();
            this.loggingIn = true;
            var analyticsData = {
                social_network_type: this.Enums.SocialNetworkTypes.facebook
            };
            // manage pages hard coded for now
            this.FacebookService.loginToFacebook(requiredPermissions).then(
                function success(facebookParams) {
                    this.connectSocialAccount(currentUser, this.Enums.SocialNetworkTypes.facebook, facebookParams.auth_token).then(
                        function success() {
                            this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connecting_social_network, analyticsData);
                            this.resetPwSuccess();
                        }.bind(this),
                        function error(resp) {
                            // show error message
                            this.resetPwError();
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, analyticsData);
                            var errorMessage = this.$translate.instant('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', { socialAccountType: 'Facebook' });
                            if (resp.data && resp.data.error_type && resp.data.error_type === 'HBUserError') {
                                errorMessage = errorMessage + ': ' + resp.data.error_message;
                            }
                            this.PopupMessageService.showErrorAlert(errorMessage);
                        }.bind(this)
                    ).finally(function () {
                        this.loggingIn = false;
                    }.bind(this));
                }.bind(this),
                function error(resp) {
                    // this error occurs because of facebook api is not synced. ignore it.
                    if (resp.error_subcode !== 467) {
                        var errorMessage = this.$translate.instant('ACCOUNT.ERRORS._CONNECTING_SOCIAL_ACCOUNT_', { socialAccountType: 'Facebook' });
                        this.PopupMessageService.showErrorAlert(errorMessage);
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connecting_social_network, resp, analyticsData);
                    }
                }.bind(this)
            );
        },

        newUser: function newUser() {
            return this.ModelFactory.newUserModel();
        },

        convertToUser: function convertToUser(jsonUser) {
            var user = this.newUser();
            // todo yona: it still tries to save to cache
            user.mixinFrom(jsonUser, null, false, false);
            return user;
        },

        newUsers: function newUsers() {
            return this.ModelFactory.newUsersCollection();
        },

        waiveLateFee: function waiveLateFee(payment) {
            const url = this.Routes.v2_flow_payment_waive_late_fee_path(payment.flow_id, payment.payment_id);
            return this.apiUpdate(url);
        },

        sendPhoneVerification: function sendPhoneVerification(type, user) {
            var _user = user || this.currUser;
            var url = this.Routes.v2_start_phone_verification_path(_user._id);
            return this.apiCreate(url, { type: type });
        },

        sendEmail2faVerification: function sendEmail2faVerification() {
            let url = this.Routes.v2_start_2fa_by_email_path(this.currUser._id);
            return this.apiCreate(url);
        },

        updateSecurityPhoneNumber: function updateSecurityPhoneNumber(securityPhoneNumber, verificationCode, verificationMethod) {
            const url = this.Routes.v2_user_update_phone_number_for_two_factor_authentication_path(this.currUser._id);
            const data = { phone_number_for_two_factor_auth: securityPhoneNumber };
            if (verificationMethod === 'email') {
                data['email_code'] = verificationCode;
            } else {
                data['sms_code'] = verificationCode;
            }
            return this.apiUpdate(url, data, this.currUser, true);
        },

        updateUserEmail: function updateUserEmail(email, verificationCode, user) {
            var _user = user || this.currUser;
            var url = this.Routes.v2_user_update_user_email_path(_user._id);
            return this.apiUpdate(url, { email: email, sms_code: verificationCode }, _user, true);
        },

        createNewUser: function createNewUser(userData) {
            const innerCreateUser = recaptchaToken => {
                const url = this.Routes.v2_create_user_path();
                const newUser = this.ModelFactory.newUserModel();
                const headers = this.convertMtaCookieToHeader();

                // will be undefined for tests and should be ignored in server
                userData['g-recaptcha-response-v3'] = recaptchaToken;

                return this.apiCreate(url, userData, newUser, true, headers).then(res => {
                    this.AnalyticsService.alias(res.model._id);
                    this.postLogin(newUser, 'loggingIn');
                    return res;
                });
            };

            if (this.AppConfigService.isTest() || this.AppConfigService.isE2E()) {
                return innerCreateUser();
            } else {
                return this.RecaptchaService.getRecaptchaToken("signup").then(innerCreateUser);
            }
        },

        validateEmail: function validateEmail(userData) {
            var url = this.Routes.validate_onboarding_path(userData);
            return this.apiFetch(url);
        },

        disconnectSocialAccount: function disconnectSocialAccount(user, social_network_type) {
            var url = this.Routes.v2_user_disconnect_social_account_path(user._id);
            var data = {
                social_network_type: social_network_type
            };
            return this.apiUpdate(url, data, user, true);
        },

        connectSocialAccount: function connectSocialAccount(user, social_network_type, auth_token, redirect_uri) {
            var url = this.Routes.v2_user_connect_social_account_path(user._id);
            var data = {
                social_network_type: social_network_type,
                auth_token: auth_token,
                redirect_uri: redirect_uri
            };
            return this.apiUpdate(url, data, user, true);
        },

        getEmailProviders: function getEmailProviders(user) {
            var url = this.Routes.v2_get_email_providers_path(user._id);
            return this.apiFetch(url);
        },

        getTrackToolUrl: function getTrackToolUrl(user, date) {
            var url = this.Routes.v2_get_track_tool_url_path(user._id, { user_opt_in: date });
            return this.apiFetch(url);
        },

        setActiveEmailProvider: function setActiveEmailProvider(user, type, source) {
            var url = this.Routes.v2_set_active_email_provider_path(user._id);
            var data = {
                type,
                source
            };
            return this.apiUpdate(url, data, null, false).then(function (resp) {
                user.gmail_integration_revoked_at = null;
                return resp;
            });
        },

        deleteEmailProvider: function deleteEmailProvider(user, providerType) {
            var url = this.Routes.v2_delete_email_provider_path(user._id, providerType);
            return this.apiDelete(url, null);
        },

        checkForTrialExtension: function checkForTrialExtension(user) {
            var data = {};
            this.fillExtendTrialData(data);
            if (this._.isEmpty(data)) {
                return new Promise(function (resolve) { //return dummy promise
                    resolve();
                });
            } else {
                this.clearExtendTrialData(); //prevent additional API calls with same params.
                var url = this.Routes.v2_check_extend_trial_path(user._id);
                return this.apiUpdate(url, data, user, true).then(function success() {
                    if (user.trial_extension_data && user.trial_extension_data.is_extended) {
                        this.trigger(this.Enums.PubSubTriggers.extendedTrial);
                    }
                }.bind(this));
            }
        },

        //currently not in use. sourceOfRequest can be "extendTrialModal" or similar. saved in sfdc.
        requestTrialExtension: function requestTrialExtension(user, days, sourceOfRequest) {
            var url = this.Routes.v2_request_extend_trial_path(user._id);
            var data = {
                "days_to_extend": days,
                "source_of_request": sourceOfRequest
            };

            return this.apiUpdate(url, data, user, true).then(function success() {
                if (user.trial_extension_data && user.trial_extension_data.is_extended) {
                    this.trigger(this.Enums.PubSubTriggers.extendedTrial);
                }
            }.bind(this));
        },

        fillExtendTrialData: function fillExtendTrialData(data) {
            var days_to_extend_trial = this.initialUrlSearchParams['extend_trial'];
            var extend_trial_type = this.initialUrlSearchParams['trial_type'];

            if (typeof days_to_extend_trial !== "undefined") {
                data["extend_trial"] = days_to_extend_trial;
            }

            if (typeof extend_trial_type !== "undefined") {
                data["trial_type"] = extend_trial_type;
            }

            if (this.initialUrlSearchParams['utm_campaign']) {
                data['utm_campaign'] = this.initialUrlSearchParams['utm_campaign'];
            }
            return data;
        },

        clearExtendTrialData: function clearExtendTrialData() {
            delete this.initialUrlSearchParams.extend_trial;
            delete this.initialUrlSearchParams.trial_type;
            delete this.initialUrlSearchParams.utm_campaign;
        },

        login: function login(user, source, sourceData, trustThisDevice = true, recaptchaToken = null) {

            if (!user) {
                return;
            }

            var email = user.email;
            var url = this.Routes.v2_user_login_new_path();
            var company_id = (this.$window.clientPortalConfiguration || {}).company_id;
            var f_token = this.ForterHelperService.getForterToken();
            var data = {
                password: user.password,
                trust_device: trustThisDevice,
                source,
                sourceData,
                company_id,
                f_token,
                email,
                "g-recaptcha-response-v3": recaptchaToken
            };

            this.fillExtendTrialData(data);

            return this._login(user, url, data);
        },

        login_with_facebook: function login(user, auth_token) {

            if (!user) {
                user = this.newUser();
            }
            const facebook = 'facebook';
            var url = this.Routes.v2_sso_login_user_path();
            var fToken = this.ForterHelperService.getForterToken();
            var data = {
                auth_token: auth_token,
                sso_type: facebook,
                f_token: fToken
            };

            this.fillExtendTrialData(data);

            return this._login(user, url, data, true);
        },

        loginWithApple: function loginWithApple(user, result) {
            if (!user) {
                user = this.newUser();
            }

            const credential = result.credential;
            const userProfile = result.additionalUserInfo.profile;
            if (credential) {
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                const appleId = userProfile.sub;
                const apple = 'apple';

                var fToken = this.ForterHelperService.getForterToken();

                const url = this.Routes.v2_sso_login_user_path();
                const data = {
                    auth_token: accessToken,
                    id_token: idToken,
                    apple_id: appleId,
                    sso_type: apple,
                    f_token: fToken
                };

                this.fillExtendTrialData(data);

                return this._login(user, url, data, true);
            }
        },

        login_with_google: function loginWithGoogle(user, id_token) {

            if (!user) {
                user = this.newUser();
            }

            const google = 'google';
            var fToken = this.ForterHelperService.getForterToken();
            var url = this.Routes.v2_sso_login_user_path();
            var data = {
                auth_token: id_token,
                sso_type: google,
                f_token: fToken
            };

            this.fillExtendTrialData(data);

            return this._login(user, url, data, true);
        },

        login_with_passkeys: function loginWithPasskeys(user, email, id_token) {
            if (!user) {
                user = this.newUser();
            }

            var fToken = this.ForterHelperService.getForterToken();
            var url = this.Routes.v2_sso_login_user_path();
            var data = {
                auth_token: id_token,
                sso_type: 'passkeys',
                f_token: fToken,
                email: email
            };

            this.fillExtendTrialData(data);

            return this._login(user, url, data, false);
        },

        _login: function _login(user, url, data, skip_2fa = false) {

            this.preLogin(user);
            return this.Fingerprint().then(function (fingerprint) {
                data.fingerprint = fingerprint;
                try{
                    var importPublicTemplateStorageValue = sessionStorage.getItem('import_public_template');
                    var importPublicTemplateParsedValue  = importPublicTemplateStorageValue ? JSON.parse(importPublicTemplateStorageValue) : null;
                    data.source_template_id = importPublicTemplateParsedValue.templateId;
                    data.template_redeem_code = importPublicTemplateParsedValue.templateRedeemCode;
                }catch (e){
                    this.AnalyticsService.trackError(this, '[Public Template] error getting import_public_template from localStorage', e);
                }
                var headers = this.convertMtaCookieToHeader();
                return this.apiCreate(url, data, user, true, headers).then(function success(response) {
                    delete user.password;
                    var is2faOff = !user.isTwoFactorAuthLoginEnabled() || user.isTempPassLogin() || skip_2fa || user.bypass_2fa;
                    if (is2faOff && !user.isBookkeeper()) {
                        this.postLogin(user, 'loggingIn');
                    } else {
                        this.in_two_factor_stage = true;
                        this._setCurrUser(user);
                        this.saveCurrUser();
                    }

                    this.currUser.__isUserFullyLoaded = true;
                    return response.data;
                }.bind(this));

            }.bind(this));
        },

        preLogin: function _preLogin(user) {
            this._clearCurrUser();
            user.resetState();
        },

        postLogin: function _postLogin(user, event) {
            this._setCurrUser(user);
            this.saveCurrUser();
            this.AnalyticsService.identify(user);
            this.trigger(event, user);

            this.AccountsManager = this.AccountsManager || this.$injector.get('AccountsManager');
            this.AccountsManager.fetchFeaturePlan({ forceFetch: true});
        },

        redirectToCommunity: function () {
            var url = this.Routes.v2_user_community_token_path();
            return this.apiFetch(url)
                .then(response => {
                    var redirectUrl = response.data.redirect_url;
                    if (redirectUrl) {
                        window.location.replace(redirectUrl)
                    }
                })
                .catch(error => {
                    this.DatadogRUMService.addError(error, {flow: 'redirectToCommunity'});


                })
        },

        resetUserPassword: function resetUserPassword(userId, guid, password, user) {
            if (!user) {
                return;
            }

            this.preLogin(user);

            var data = {
                id: userId,
                password: password,
                reset_password_guid: guid
            };

            return this.Fingerprint().then(function (fingerprint) {
                data.fingerprint = fingerprint;

                var url = this.Routes.v2_users_reset_password_path();
                var promise = this.apiUpdate(url, data, user, false);

                return promise;
            }.bind(this));

        },

        malkutAdminLogin: function malkutAdminLogin(userEmail, adminEmail, adminUserId, malkutToken, userId) {
            var defer = this.$q.defer();
            this.logout().then(() => {
                this.RepositoryService.storagePut(this.Constants.storage.AUTH_USER_ID, adminUserId);
                this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN, malkutToken);
                this.getUserInfo(userId)
                    .then(res => {
                        this.RepositoryService.storagePut(this.Constants.storage.AUTH_USER_ID, adminUserId);
                        this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN, malkutToken);
                        this.RepositoryService.storagePut(this.Constants.storage.CURR_USER, res.data);
                        const url = this.Routes.v2_malkut_admin_login_path(userId);
                        this.apiCreate(url, { user_email: userEmail }, this.getCurrUser(), true).then(resp => {
                            this.saveCurrUser();
                            this.trigger('currentUserChanged', this.getCurrUser());
                            defer.resolve(resp);
                        }).catch(e => {
                            console.error('v2_malkut_admin_login failed', e);
                            this.DatadogRUMService.addError(e, {flow: 'login_as', action: 'v2_malkut_admin_login'});
                            defer.reject(e);
                        });
                    })
                    .catch(e => {
                        console.error('getUserInfo failed', e);
                        this.DatadogRUMService.addError(e, {flow: 'login_as', action: 'getUserInfo'});
                        defer.reject(e);
                    });
            }).catch(e => {
                this.DatadogRUMService.addError(e, {flow: 'login_as', action: 'logout'});
                defer.reject(e);
            });
            return defer.promise;
        },

        sendClientLoginCode: function sendClientLoginCode(client, workspace_file_id) {
            var url = this.Routes.v2_send_client_login_code_path(client._id);
            var data = {
                workspace_file_id: workspace_file_id
            };
            return this.apiCreate(url, data, null, false);
        },

        verifyClientLoginCode: function verifyClientLoginCode(client, verificationCode) {
            var url = this.Routes.v2_verify_client_login_code_path(client._id);
            var data = {
                verification_code: verificationCode
            };
            return this.apiFetch(url, data, null, false);
        },

        proLoginAsVendor: function proLoginAsVendor(user_id, verificationCode) {
            var url = this.Routes.v2_pro_login_as_vendor_path(user_id);
            var defer = this.$q.defer();

            this.apiCreate(url, { verification_code: verificationCode }, this.getCurrUser(), true).then(
                function success(resp) {
                    this.saveCurrUser();
                    defer.resolve(resp);
                }.bind(this),
                function error(resp) {
                    defer.reject(resp);
                }.bind(this)
            );

            return defer.promise;
        },

        vendorLoginAsClient: function vendorLoginAsClient(workspaceFileId, verificationCode) {
            var url = this.Routes.v2_vendor_login_as_client_path(workspaceFileId);
            var defer = this.$q.defer();

            this.apiCreate(url, { verification_code: verificationCode }, this.getCurrUser(), true).then(
                function success(resp) {
                    this.saveCurrUser();
                    defer.resolve(resp);
                }.bind(this),
                function error(resp) {
                    defer.reject(resp);
                }.bind(this)
            );

            return defer.promise;
        },

        vendorLogoutAsClient: function vendorLogoutAsClient(workspaceFileId) {
            var url = this.Routes.v2_vendor_logout_as_client_path(workspaceFileId);
            var defer = this.$q.defer();

            this.apiCreate(url, null, this.getCurrUser(), true).then(
                function success(resp) {
                    this.saveCurrUser();
                    defer.resolve(resp);
                }.bind(this),
                function error(resp) {
                    defer.reject(resp);
                }.bind(this)
            );

            return defer.promise;
        },

        proLogoutAsVendor: function proLogoutAsVendor() {
            var url = this.Routes.v2_pro_logout_as_vendor_path();
            var defer = this.$q.defer();

            this.apiCreate(url, null, this.getCurrUser(), true).then(
                function success(resp) {
                    this.saveCurrUser();
                    this.trigger('loggingIn', this.getCurrUser());
                    defer.resolve(resp);
                }.bind(this),
                function error(resp) {
                    defer.reject(resp);
                }.bind(this)
            );

            return defer.promise;
        },

        getMalkutUrl: function getMalkutUrl() {
            var self = this;
            var defer = this.$q.defer();
            var url = this.Routes.v2_get_malkut_url_path();

            this.apiFetch(url, null, null, false).then(
                function success(resp) {
                    defer.resolve(resp);
                },
                function error(resp) {
                    defer.reject(resp);
                }
            );

            return defer.promise;
        },

        logout: function logout() {
            var defer = this.$q.defer();

            if (this.isLoggedIn()) {
                var url = this.Routes.v2_user_logout_new_path();
                this.apiDelete(url);
            }

            // this is being done via timeout because a race-condition was causing it to happen before the
            // apiDelete that gets sent to the server - which meant that we cleared the storage (and all the API headers)
            // and were unable to properly perform the logout on the server-side

            this.$timeout(function () {
                let mfaSessionToRestore = null;
                if (this.currUser.isAdminLogin()) {
                    mfaSessionToRestore = this.RepositoryService.storageGet(this.Constants.storage.MFA_SESSION);
                }
                let trustedDeviceToRestore = this.RepositoryService.storageGet(this.Constants.storage.TRUSTED_DEVICE);
                const currentCoupon = this.RepositoryService.storageGet('HB_COUPON');
                this.RepositoryService.storageClearAll();
                this.RepositoryService.storagePut('HB_COUPON', currentCoupon);
                this._clearCurrUser();
                this.FacebookService.logoutOfFacebook();
                this.FirebaseAPIService.signOut();
                this.RepositoryService.cacheClearAll();
                this.RepositoryService.persistentClearAll();
                this.AppConfigService.clearAppState();
                this.FullStoryService.stopTracking();
                this.DatadogRUMService.stopTracking();
                this.AbTestService.reset();

                this.FeatureRestrictionService = this.FeatureRestrictionService || this.$injector.get('FeatureRestrictionService');
                this.FeatureRestrictionService.reset();

                if (mfaSessionToRestore) {
                    this.RepositoryService.storagePut(this.Constants.storage.MFA_SESSION, mfaSessionToRestore);
                }

                if (trustedDeviceToRestore) {
                    this.RepositoryService.storagePut(this.Constants.storage.TRUSTED_DEVICE, trustedDeviceToRestore);
                }

                this.trigger('loggingOut');
                defer.resolve();
            }.bind(this));
            return defer.promise;
        },

        identifyUser: function identifyUser(user) {
            if (!this.didIdentifiedUser && user && user._id) {

                // Datadog
                if (this.DatadogRUMService || this.DatadogLOGSService) {
                    // client_cache flag was here on both false
                    const user = {
                        id: this.currUser._id,
                        name: this.currUser.full_name,
                        email: this.currUser.email,
                        userType: this.currUser.system_user_type,
                        isActivate: this.currUser.isActivated(),
                        isInAppBrowser: this.DeviceService.isInAppBrowser(),
                        featuresVariationsClientCache: false,
                        featuresVariations: {
                            clientCache: false
                        }
                    };

                    if (this.DatadogRUMService) {
                        this.DatadogRUMService.identifyUser(user);
                    }

                    if (this.DatadogLOGSService) {
                        this.DatadogLOGSService.identifyUser(user);
                    }
                }

                // Segment
                if (this.AnalyticsService) {
                    this.AnalyticsService.identifyUser(this.currUser);
                }

                this.didIdentifiedUser = true;
            }
        },

        getCurrUser: function getCurrUser() {
            if (localStorage.getItem('PRO_LOGIN') === 'true') {
                var storageUser = this.RepositoryService.storageGet(this.Constants.storage.CURR_USER);
                var reactUser = JSON.parse(localStorage.getItem('HONEYBOOK_REACT_CURR_USER'));

                if (storageUser && reactUser && storageUser._id !== reactUser._id) {
                    this.privateSetUserFromJSON(reactUser);
                    this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN, reactUser.authentication_token);
                    this.RepositoryService.storagePut(this.Constants.storage.AUTH_USER_ID, reactUser._id);
                }

                localStorage.removeItem('PRO_LOGIN');
            }

            if (!this.currUser) {
                var userFromStorage = this.RepositoryService.storageGet(this.Constants.storage.CURR_USER);
                this.privateSetUserFromJSON(userFromStorage);
            }

            return this.currUser;
        },
        privateSetUserFromJSON: function privateSetUser(userFromStorage) {
            var user = this.newUser();
            user.__isUserFullyLoaded = false;
            this._setCurrUser(user);
            this.NotificationService.reset(this.currUser);
            user.mixinFrom(userFromStorage, undefined, false);
            this.AnalyticsService.identify(this.currUser);

            if (!this.currUser.isActivated() && this.currUser._id && this.currUser.isVendor()) {
                this.getActivatedState(this.currUser);
            }

            //get an updated version of the user in any case
            this.forceFetchCurrUser();
            this.getAuditedCompanies();
            return user
        },
        setUserLoggedInExternally: function setUserLoggedInExternally(userJson) {
            const user = this.getCurrUser();
            if (user._id && user._id !== userJson._id) {
                // We do this check to understand in which cases happens that the user is already logged in and we try to login another user (which is not the same).
                this.DatadogRUMService.addError(new Error('setUserLoggedInExternally - new'), {method: 'setUserLoggedInExternally', userJson: userJson._id, currUser: user._id});
            }
            this.preLogin(user);
            user.mixinFrom(userJson, undefined, false, true);
            user.__isUserFullyLoaded = true;
            this._setCurrUser(user);
            this.postLogin(user, 'loginLess');
        },

        toggleBookkeeperMode: function toggleBookkeeperMode(isBkprMode, companyId) {
            let defer = this.$q.defer();

            if (isBkprMode) {
                this.getAuditedCompanies(true).then(() => {
                    this.currUser.toggleBookkeeperMode(isBkprMode, companyId);
                    defer.resolve();
                });
            } else {
                this.currUser.toggleBookkeeperMode(isBkprMode, companyId)
                defer.resolve();
            }

            return defer.promise;
        },

        getAuditedCompanies: function getAuditedCompanies(force = false) {
            if (!this.currUser.isBookkeeper()) {
                return;
            }
            let defer = this.$q.defer();

            let audited_companies = this.currUser.audited_companies;
            let isBkprMode = this.currUser.isBookkeeperMode();
            let shouldFetchAuditees = !audited_companies || !audited_companies.length || this.isModelTimedOut(audited_companies[0], force);

            // refetch auditees if we don't have them or if they are timed out
            if (force || (shouldFetchAuditees && isBkprMode)) {
                this._fetchAuditedCompanies()
                    .then(defer.resolve)
                    .catch(defer.reject);
            } else {
                defer.resolve(audited_companies || []);
            }

            return defer.promise;
        },

        _fetchAuditedCompanies: function _fetchAuditedCompanies() {
            var url = this.Routes.v2_get_bookkeeper_authorizations_path(this.currUser._id);
            let data = { as_client: false};
            var headers = this.convertMtaCookieToHeader();

            return this.apiFetch(url, data, null, null, headers).then((res) => {
                let companies = res.data.results.map(a => a.company);
                var auditedCompanies = this.ModelFactory.newCompaniesCollection();
                auditedCompanies.mixinFrom(companies || []);
                auditedCompanies.forEach(b => b.setFullyFetched());
                this.currUser.audited_companies = auditedCompanies || [];
                return auditedCompanies;
            });
        },

        isCurrentUserEmail: function isCurrentUserEmail(email) {

            return (this.getCurrUser().email === email);
        },

        isCurrentUser: function isCurrentUser(id) {
            return (this.getCurrUser()._id === id);
        },

        isLoggedIn: function isLoggedIn() {
            return !!(this.RepositoryService.storageGet(this.Constants.storage.AUTH_TOKEN) && this.RepositoryService.storageGet(this.Constants.storage.AUTH_USER_ID));
        },

        getActivatedState: function getActivatedState(user) {
            var url = this.Routes.v2_get_activated_state_path(user._id);
            return this.apiFetch(url, null, user, true);
        },

        updateUserProperties: function updateUserProperties(userData, user, updateModel) {
            var url = this.Routes.v2_update_user_path(userData._id);
            return this.apiUpdate(url, userData, user, updateModel).then(function success() {
                this.saveCurrUser();
            }.bind(this));
        },

        updateCurrUserPropertiesOptimistically: function updateCurrUserPropertiesOptimistically(userProps) {
            angular.extend(this.currUser, userProps);
        },

        updateAccountPropertiesOptimistically: function updateCurrUserPropertiesOptimistically(accountProps) {
            angular.extend(this.currUser.account, accountProps);
        },

        updateCompanyPropertiesOptimistically: function updateCompanyPropertiesOptimistically(companyProps) {
            angular.extend(this.currUser.company, companyProps);
        },

        convertUserToVendor: function convertUserToVendor(userData) {
            if (!userData._id) {
                userData._id = this.currUser._id;
            }
            var url = this.Routes.v2_users_convert_user_to_vendor_path(userData._id);

            return this.apiCreate(url, userData, this.currUser, true).then(
                function success(resp) {
                    this.currUser.getAccount().trial = resp.data.account.trial;
                    this.trigger(this.Enums.PubSubTriggers.startedTrial);
                    this.saveCurrUser();
                }.bind(this)
            );
        },

        updateCurrentUserProperties: function updateCurrentUserProperties(userData) {
            if (!userData._id) {
                userData._id = this.currUser._id;
            }
            return this.updateUserProperties(userData, this.currUser, true);
        },

        changePassword: function changePassword(data) {
            var url = this.Routes.v2_users_change_password_path(data);
            return this.apiUpdate(url, data, this.getCurrUser(), true);
        },

        changePasswordByEmail: function changePasswordByEmail(data){
            var url = this.Routes.v2_users_unauthenticated_change_password_path();
            var company_id = (this.$window.clientPortalConfiguration || {}).company_id;
            data.company_id = company_id;
            return this.apiUpdate(url, data);
        },

        changePasswordSuccessful: function changePasswordSuccessful() {
            // this.RepositoryService.storagePut(this.Constants.storage.AUTH_TOKEN, this.currUser.authentication_token);
            this.saveCurrUser();
            this.trigger("resetPsw");
        },

        setSmsAcceptance: function setSmsAcceptance(user, acceptance) {
            var url = this.Routes.v2_user_set_sms_acceptance_path(user._id);
            return this.apiUpdate(url, { sms_acceptance: acceptance }, user, true);
        },

        setShowedSmsCheckbox: function setShowedSmsCheckbox(user, showedSms) {
            var url = this.Routes.v2_set_user_shown_sms_checkbox_path(user._id);
            return this.apiUpdate(url, { showed_sms: showedSms });
        },

        validateCardExpirationDate: function validateCardExpirationDate(paymentMethodId, month, year) {
            var url = this.Routes.v2_validate_user_credit_card_expiration_date_path(paymentMethodId);
            return this.apiUpdate(url, { month: month, year: year });
        },

        addPaymentMethod: function addPaymentMethod(user, paymentMethod, file_id) {
            var url = this.Routes.v2_create_payment_method_path(user._id);

            var self = this;
            var deferred = this.$q.defer();
            const params = Object.assign(paymentMethod, { file_id: file_id });
            this.apiCreate(url, params, self.getCurrUser(), false).then(
                function () {
                    deferred.resolve.apply(undefined, arguments);
                    self.saveCurrUser();
                },

                function () {
                    deferred.reject.apply(undefined, arguments);
                }
            );

            return deferred.promise;
        },

        deletePaymentMethod: function deletePaymentMethod(user, methodId, forceDelete) {
            var url = this.Routes.v2_delete_stripe_card_path(user._id, methodId);

            return this.apiDelete(url, { force: forceDelete }, user, true)
                .then(function success() {
                    this.saveCurrUser();
                    return arguments;
                }.bind(this));
        },

        addBankAccount: function addBankAccount(user, account, company, smsCode) {
            var url = this.Routes.v2_create_bank_account_path(user._id);

            //clone the data that we are given before we change it according to the server's api
            var accountToSend = angular.copy(account);
            //prepare the data for the api call
            accountToSend.id = null;
            accountToSend._id = null;
            accountToSend.sms_code = smsCode;

            var self = this;
            var deferred = this.$q.defer();
            this.apiCreate(url, accountToSend, user, true)
                .then(function () {
                    deferred.resolve.apply(undefined, arguments);
                    if (self.isCurrentUser(user._id)) {
                        self.saveCurrUser();
                    }
                })
                .catch(function () {
                    deferred.reject.apply(undefined, arguments);
                });

            return deferred.promise;
        },

        deleteBankAccount: function deleteBankAccount(user, account) {
            var url = this.Routes.v2_delete_bank_account_path(user._id, account._id);
            var self = this;

            return this.apiDelete(url, null, user, true).then(
                function () {
                    if (self.isCurrentUser(user._id)) {
                        self.saveCurrUser();
                    }

                    return arguments;
                }
            );
        },

        deselectBankAccount: function deselectBankAccount(userId, accountId) {
            var url = this.Routes.v2_deselect_plaid_business_sub_account_path(userId, accountId);
            return this.apiUpdate(url);
        },

        forceFetchCurrUser: function forceFetchCurrUser() {

            if (!this.currUser || !this.currUser._id || this.in_two_factor_stage) {
                var defer = this.$q.defer();
                defer.reject();
                return defer.promise;
            }

            if (this.fetchingCurrUser) {
                return this.fetchUserPromise;
            }

            this.fetchingCurrUser = true;

            var url = this.Routes.v2_get_current_user_path(this.currUser._id);
            var headers = this.convertMtaCookieToHeader();
            this.fetchUserPromise = this.apiFetch(url, null, this.currUser, true, headers).then(function getUserFromServerSuccess(response) {
                this.saveCurrUser();
                this.currUser.__isUserFullyLoaded = true;

                return response;
            }.bind(this)).finally(function final() {
                this.fetchingCurrUser = false;
            }.bind(this));

            return this.fetchUserPromise;
        },

        updateCalendarColor: function updateCalendarColor(user, calendarType, color) {
            var url = this.Routes.v2_update_calendar_color_path(user._id);
            return this.apiUpdate(url, { color: color, calendar_color_type: calendarType }, user, true);
        },

        getUserByEmail: function getUserByEmail(email, userModel) {
            var user = userModel || this.newUser();
            var url = this.Routes.v2_users_user_by_email_path();
            return this.apiFetch(url, { email: email }, user, true);
        },

        sendTwoFactorAuthCode: function sendTwoFactorAuthCode(user, onLogin = true) {
            var url = this.Routes.v2_validate_two_factor_auth_path();
            var own_id_session_token = this.RepositoryService.storageGet(this.Constants.storage.OWN_ID_SESSION_TOKEN);
            var f_token = this.ForterHelperService.getForterToken();
            return this.apiCreate(url, { code: user.twoFactorAuthCode, f_token: f_token, own_id_session_token: own_id_session_token }, user, true).then(function (resp) {
                user.twoFactorAuthCode = null;
                if (onLogin) {
                    this.in_two_factor_stage = false;
                    return this.forceFetchCurrUser().then(function () {
                        this.postLogin(user, 'loggingIn');
                        return resp;
                    }.bind(this))
                }
                return resp;
            }.bind(this))
        },

        clientCacheLogEvent: function clientCacheLogEvent(data) {
            var url = this.Routes.v2_client_cache_log_event_path();
            this.apiCreate(url, data, null)
        },

        sendVerificationCode: function sendVerificationCode(user, type) {
            var url = this.Routes.v2_send_two_factor_code_path();
            return this.apiCreate(url, { type: type }, user);
        },

        updateLogin2faState: function updateLogin2faState(user, enabled) {
            var url = this.Routes.v2_update_login_2fa_state_path();
            return this.apiCreate(url, { enabled: enabled }, user, true);
        },

        sendResetPassword: function sendResetPassword(user, urlParams) {
            var url = this.Routes.v2_users_reset_link_path();
            return this.apiCreate(url, Object.assign({ email: user.email }, urlParams));
        },

        sendSetPassword: function setSentPassword(user) {
            var url = this.Routes.v2_users_send_password_link_path(user._id);
            return this.apiCreate(url);
        },

        getAbTestVariation: function getAbTestVariation(test_name) {
            var url = this.Routes.v2_ab_test_variant_path(test_name);
            return this.apiFetch(url);
        },

        addSpecificAbTestVariant: function addSpecificAbTestVariant(testName, variant) {
            var url = this.Routes.v2_add_specific_ab_test_variant_path();
            return this.apiCreate(url, {name: testName, variant: variant});
        },

        getUserByGuid: function getUserByGuid(userId, guid, userModel, fileId, eventId) {
            var user = userModel || this.newUser();
            var data = { id: userId, reset_password_guid: guid };
            if (fileId) {
                data.file_id = fileId;
            }
            if (eventId) {
                data.event_id = eventId;
            }
            var url = this.Routes.v2_users_user_by_reset_guid_path();
            return this.apiFetch(url, data, user, true);
        },

        // the data should contain a json with one key property and one value property that contains a json to persist on that value
        setUiPersistence: function setUiPersistence(user, data) {
            if (!!!(user && user._id)) {
                return null;
            }
            var url = this.Routes.v2_save_ui_persistence_path(user._id);
            var promiseToReturn = this.apiUpdate(url, data, user, true);

            promiseToReturn.then(function success() {
                // we want to persist the user to the local storage too so when the users
                // refresh the app (no api call is done to the server) we still have the ui persistency updated
                this.saveCurrUser();
            }.bind(this));

            return promiseToReturn;
        },

        refetchUiPersistence: function refetchUiPersistence(user) {
            var url = this.Routes.v2_refetch_ui_persistence_path(user._id);
            return this.apiFetch(url, null, user, true);
        },

        getCalendarProvider: function getCalendarProviders(user) {
            var url = this.Routes.v2_get_calendar_provider_path(user._id);
            return this.apiFetch(url);
        },

        getZoomAuthProvider: function getZoomAuthProvider(user) {
            var url = this.Routes.v2_get_zoom_auth_provider_path(user._id);
            return this.apiFetch(url);
        },

        deleteCalendarProvider: function deleteCalendarProvider(user) {
            var url = this.Routes.v2_delete_calendar_provider_path(user._id);
            return this.apiDelete(url, null);
        },

        getCalendarsItems: function getCalendarsItems(user) {
            var url = this.Routes.v2_get_calendar_items_path(user._id);
            return this.apiFetch(url);
        },

        getCalendarItem: function getCalendarItem(user, calendarItemId) {
            var url = this.Routes.v2_get_calendar_item_path(user._id, calendarItemId);
            return this.apiFetch(url);
        },

        createCalendarsItem: function createCalendarsItem(user, calendarItemProperties) {
            var url = this.Routes.v2_create_calendar_item_path(user._id);
            return this.apiCreate(url, calendarItemProperties);
        },

        deleteCalendarsItem: function deleteCalendarsItem(user, calendarItemId, notifyMembers) {
            var url = this.Routes.v2_delete_calendar_item_path(user._id, calendarItemId);
            return this.apiDelete(url, { notify: !!notifyMembers });
        },

        updateCalendarItem: function updateCalendarItem(user, updatedCalendarItemId, meetingParams, notifyMembers) {
            var url = this.Routes.v2_update_calendar_item_path(user._id, updatedCalendarItemId);
            meetingParams.notify = !!notifyMembers;
            return this.apiUpdate(url, meetingParams);
        },

        reSyncCalendarItem: function reSyncCalendarItem(calendarItemId, eventType) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_resync_calendar_item_path(currUser._id, calendarItemId);
            return this.apiCreate(url, { eventType });
        },

        saveDefaultSignature: function saveDefaultSignature(user, signatureModel) {
            var url = this.Routes.v2_save_default_signature_path(user._id);
            var data = {
                signature_type_cd: signatureModel.signature_type_cd,
                signature: signatureModel.signature,
                signature_text: signatureModel.signature_text,
                signee_name: signatureModel.signee_name,
                signature_description: signatureModel.signature_description
            };
            return this.apiUpdate(url, data, user, true);
        },

        plaidCreateLinkToken: function plaidCreateLinkToken(user, plaidProducts, redirectUri) {
            var url = this.Routes.v2_plaid_create_link_token_path(user._id);
            return this.apiCreate(url, { plaid_products: plaidProducts, redirect_uri: redirectUri });
        },

        plaidExchangePublicToken: function plaidExchangePublicToken(user, token, institutionId, institutionName, plaidLinkNewAccounts) {
            var url = this.Routes.v2_plaid_exchange_public_token_path(user._id);
            return this.apiCreate(url, {
                token, institution_id: institutionId, institution_name: institutionName,
                plaid_link_new_accounts: plaidLinkNewAccounts
            });
        },

        connectPlaidBankAccount: function connectPlaidBankAccount(user, institutionId, institutionName, src) {
            const url = this.Routes.v2_connect_plaid_account_path(user._id);
            return this.apiCreate(url, { institution_id: institutionId, institution_name: institutionName, src: src });
        },

        selectPlaidBusinessSubAccounts: function selectPlaidBusinessSubAccounts(user, selected_bank_sub_account_ids) {
            var url = this.Routes.v2_select_plaid_business_sub_accounts_path(user._id);
            return this.apiUpdate(url, { selected_bank_sub_account_ids: selected_bank_sub_account_ids });
        },

        getPlaidBusinessSubAccounts: function getPlaidBusinessSubAccounts(user) {
            var url = this.Routes.v2_get_plaid_accounts_path(user._id);
            return this.apiFetch(url);
        },


        createChargeableBankAccount: function createChargeableBankAccount(user, account, bankRole, institutionId, institutionName, file_id) {
            var url = this.Routes.v2_create_chargeable_bank_account_path(user._id);
            var data = {
                account,
                bank_role: bankRole,
                institution_id: institutionId,
                institution_name: institutionName,
                file_id: file_id
            };
            return this.apiCreate(url, data, user, false);
        },

        getGoogleAuthURL: function getGoogelAuthURL(preferredGoogleAccountEmail, originAppState, requestedIntegrationType) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_google_auth_url_path(currUser._id);
            var data = {
                preferredGoogleAccountEmail: preferredGoogleAccountEmail,
                requestedIntegrationType: requestedIntegrationType,
                originState: originAppState
            };
            return this.apiFetch(url, data, null, false);
        },

        setGoogleAuthCode: function setGoogleAuthCode(authCode, integrationType, validation_code) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_google_auth_code_path(currUser._id);
            var data = {
                auth_code: authCode,
                integration_type: integrationType,
                validation_code: validation_code
            };
            return this.apiCreate(url, data, currUser, true);
        },

        getZoomAuthURL: function getZoomAuthURL(returnUrl) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_zoom_auth_url_path(currUser._id);
            var data = {
                return_url: returnUrl
            };
            return this.apiFetch(url, data, null, false);
        },

        getNylasAuthURL: function getNylasAuthURL(returnUrl, originState, provider, email, integrationType) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_nylas_auth_url_path(currUser._id);
            var data = {
                return_url: returnUrl,
                origin_state: originState,
                provider: provider,
                email: email,
                integration_type: integrationType
            };
            return this.apiFetch(url, data, null, false);
        },

        setNylasAuthCode: function setNylasAuthCode(params) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_nylas_auth_code_path(currUser._id);
            var data = {
                data: params
            };
            return this.apiCreate(url, data, currUser, true);
        },

        getEmailProviderFromNylas: function getEmailProviderFromNylas(emailAddress, integrationType) {
            var url = this.Routes.v2_get_email_provider_path();
            return this.apiCreate(url, { email_address: emailAddress, integration_type: integrationType }, null, false);
        },

        setZoomAuthCode: function setZoomAuthCode(authCode, validation_code) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_zoom_auth_code_path(currUser._id);
            var data = {
                auth_code: authCode,
                validation_code: validation_code
            };
            return this.apiCreate(url, data, currUser, true);
        },

        disconnectZoom: function disconnectZoom() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_disconnect_zoom_path(currUser._id);
            return this.apiDelete(url);
        },

        searchGoogleContacts: function searchGoogleContacts(searchTerm, startIndex, maxResults, filterExistingContacts) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_google_contacts_path(currUser._id);
            var data = {
                search_term: searchTerm,
                filter_existing_contacts: filterExistingContacts,
                start_index: startIndex,
                max_results: maxResults
            };
            return this.apiFetch(url, data, null, false);
        },

        getGoogleContacts: function suggestedGoogleContacts() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_google_contacts_path(currUser._id);
            var data = {
                filter_existing_contacts: true
            };
            return this.apiFetch(url, data, null, false);
        },

        getGoogleIntegrations: function getGoogleIntegrations() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_google_integrations_path(currUser._id);

            return this.apiFetch(url, null, currUser, true);
        },

        refreshGoogleIntegrations: function refreshGoogleIntegrations() {
            const currUser = this.getCurrUser();
            this.getGoogleIntegrations()
                .then(function(response){
                    currUser.google_integrations = response.data.google_integrations;
                });
        },

        setCalendarImporter: function setCalendarImporter() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_google_calendar_importer_path(currUser._id);
            return this.apiCreate(url, null, null, false);
        },

        setNylasCalendarImporter: function setNylasCalendarImporter() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_nylas_calendar_importer_path(currUser._id);
            return this.apiCreate(url, null, null, false);
        },

        getNylasCalendarsList: function getNylasCalendarsList() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_get_calendars_list_path(currUser._id);
            return this.apiCreate(url, null, null, false);
        },

        setCalendarImport: function setCalendarImport(calendarImport) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_import_path(currUser._id, calendarImport._id);
            return this.apiUpdate(url, { enabled: calendarImport.enabled }, null, false);
        },

        updateCalendarProvider: function updateCalendarProvider(user, settings) {
            var url = this.Routes.v2_update_calendar_provider_path(user._id);
            var data = {
                enable_sharing: settings.showHbEventsOnCalendar,
                emails: settings.sharedWithEmailsCollection,
                calendarsList: settings.listOfCalendersToImport,
                enableImport: settings.showCalendarEventsOnHb
            };
            return this.apiUpdate(url, data, null, false);
        },

        getIntegratedCalendarsList: function getIntegratedCalendarsList() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_get_integrated_calendars_list_path(currUser._id);
            return this.apiFetch(url, null, null, false);
        },

        getIntegratedCalendarImporterEvents: function getIntegratedCalendarImporterEvents(startDate, toDate) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_get_integrated_calendars_imported_events_path(currUser._id, startDate, toDate);
            return this.apiFetch(url, null, null, false);
        },

        updateAutoExpireDefault: function updateAutoExpireDefault(user, period) {
            var url = this.Routes.v2_update_default_auto_expiration_path(user._id);
            var data = { default_auto_expiration_period: period };
            return this.apiUpdate(url, data, user, true);
        },

        deleteAutoExpireDefault: function deleteAutoExpireDefault(user) {
            var url = this.Routes.v2_delete_default_auto_expiration_path(user._id);
            return this.apiDelete(url, null, user, true);
        },

        markActivityNotificationAsSeen: function markActivityNotificationAsSeen(user, activityNotificationId) {
            var url = this.Routes.v2_set_mark_activity_notification_as_seen_path(user._id);
            var defer = this.$q.defer();
            this.apiUpdate(url, { activity_notification_id: activityNotificationId }).then(function success(resp) {

                var notifications = [];
                angular.copy(user.activity_notifications, notifications);

                for (var i = 0; i < notifications.length; i++) {
                    if (notifications[i]._id === resp.data.activity_notification._id) {
                        notifications[i] = resp.data.activity_notification;
                        break;
                    }
                }

                user.mixinFrom({
                    _id: resp.data._id,
                    has_unseen_notifications: resp.data.has_unseen_notifications,
                    activity_notifications: notifications
                }, undefined, true, true);
                user.onModelSuccess("data fetched", resp.data.status);
                this.trigger('update_notification_count');
                defer.resolve(resp);

            }.bind(this)).catch(function fail(resp) {
                defer.reject(resp);
            });

            return defer.promise;
        },

        markConversationNotificationAsSeen: function markConversationNotificationAsSeen(user, conversationNotificationId) {
            var url = this.Routes.v2_set_mark_conversation_notification_as_seen_path(user._id);
            this.apiUpdate(url, { conversation_id: conversationNotificationId }, user, true).then(
                function success() {
                    this.trigger('update_notification_count');
                }.bind(this));
        },

        markAllWebNotificationsAsSeen: function markAllWebNotificationsAsSeen(user) {
            var url = this.Routes.v2_mark_all_web_notifications_as_seen_path(user._id);
            return this.apiUpdate(url, null, user, true);
        },

        getRecentWebNotifications: function getRecentWebNotifications(user) {
            var url = this.Routes.v2_user_get_recent_web_notifications_path(user._id);
            var promise = this.apiFetch(url, null, user, true);
            promise.then(function success() {
                this.trigger('update_notification_count');
            }.bind(this));
            return promise;
        },

        getRecentConversationNotifications: function getRecentConversationNotifications(user) {
            var url = this.Routes.v2_user_get_recent_conversation_notifications_path(user._id);
            return this.apiFetch(url, null, user, true);
        },

        getAECalendlyLink: function getAECalendlyLink(user) {
            var url = this.Routes.v2_user_get_ae_calendly_link_path(user._id);
            return this.apiFetch(url, null, user, true);
        },

        getCalendlyAuthURL: function getCalendlyAuthURL(returnUrl) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_calendly_auth_url_path(currUser._id);
            var data = {
                return_url: returnUrl
            };
            return this.apiFetch(url, data, null, false);
        },

        setCalendlyAuthCode: function setCalendlyAuthCode(authCode, validation_code) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_set_calendly_auth_code_path(currUser._id);
            var data = {
                auth_code: authCode,
                validation_code: validation_code
            };
            return this.apiCreate(url, data, currUser, true);
        },

        disconnectCalendly: function disconnectCalendly() {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_disconnect_calendly_path(currUser._id);
            return this.apiDelete(url);
        },

        getLastSentPvl: function getLastSentPvl() {
            var url = this.Routes.v2_get_last_sent_pvl_path(this.getCurrUser()._id);
            return this.apiFetch(url);
        },

        updateActivityNotificationsWebsocket: function webSocketRoomUpdate() {
            this.getCurrUser().getRecentWebNotifications().then(
                function success() {
                    this.trigger('update_notification_count');
                }.bind(this));
        },

        updateProfileImageWebsocket: function updateProfileImage(data) {
            if (data && data.length > 0) {
                var roomUpdateData = JSON.parse(data).data;
                if (roomUpdateData) {
                    if (roomUpdateData.profile_image_url) {
                        this.getCurrUser().profile_image.url = roomUpdateData.profile_image_url;
                    }
                }
            }
        },

        createUserImage: function createUserImage(user, s3Response, type) {
            var imageType = type || 'profile_image';
            var url = this.Routes.v2_user_save_image_path(user._id, imageType);
            return this.apiCreate(url, s3Response, user, true);
        },

        remindUserToSetAccount: function remindUserToSetAccount(user, reminders) {
            var url = this.Routes.v2_update_set_account_reminder_path(user._id);
            return this.apiUpdate(url, reminders, user, true);
        },

        paymentReceivedUpdate: function paymentReceivedUpdate(json) {
            var data = {};
            try {
                data = JSON.parse(json).data;
            }
            catch (e) {
                console.error('paymentReceivedUpdate json error:' + e);
                console.error('paymentReceivedUpdate json: ' + json);
            }
            var user = this.getCurrUser();
            user.mixinFrom({ should_offer_loan: !!data.should_offer_loan }, undefined, false);
        },

        referralsReceivedUpdate: function referralsReceivedUpdate(json) {
            var data;
            try {
                data = JSON.parse(json).data;
                var user = this.getCurrUser();
                user.mixinFrom({ referral_container: { referral_discount_amount: data.referral_discount_amount, referred_by_code: data.referred_by_code } }, undefined, false);
            } catch (e) {
                console.error('referralsReceivedUpdate json error:' + e);
                console.error('referralsReceivedUpdate json: ' + json);
            }
        },

        userActivatedUpdate: function userActivatedUpdate(json) {
            var data;
            try {
                data = JSON.parse(json).data;
                var user = this.getCurrUser();
                user.mixinFrom({ is_activated: !!data.activated_on }, undefined, false);
            } catch (e) {
                console.error('userActivatedUpdate json error:' + e);
                console.error('userActivatedUpdate json: ' + json);
            }
        },

        onGmailTokenRevoked: function onGmailTokenRevoked(json) {
            try {
                var data = JSON.parse(json).data;
                var user = this.getCurrUser();
                user.mixinFrom({ gmail_integration_revoked_at: data.revoked_on }, undefined, false);
            } catch (e) {
                console.error('onGmailTokenRevoked json error:' + e);
                console.error('onGmailTokenRevoked json: ' + json);
            }
        },

        /**
         *
         * @param userId
         * @param date should be either javascript Date object, or string in format "MM-DD-YYYY"
         */
        getCalendarEventsOnDate: function getCalendarEventsOnDate(userId, date, endDate, includeTeam) {
            var dateStr = date;
            if ((date instanceof Date) || Object.prototype.toString.call(date) === '[object Date]') {
                dateStr = this.moment(date).format("YYYY-MM-DD");
            }

            var endDateStr = endDate;
            if ((endDate instanceof Date) || Object.prototype.toString.call(endDate) === '[object Date]') {
                endDateStr = this.moment(endDate).format("YYYY-MM-DD");
            }

            var data = {
                date: dateStr,
                end_date: endDateStr,
                include_team: includeTeam
            };

            var url = this.Routes.v2_get_calendar_events_on_date_path(userId);
            return this.apiFetch(url, data);
        },

        versionUpdate: function versionUpdate(data) {
            if (!data || Object.keys(data).length === 0) {
                return;
            }
            data = JSON.parse(data);

            // moshe is sending messages in 2 different formats.
            // once I manage to change all the places of the bad format to the good format, we won't need the following blach code
            if (!data.newApiVersion && data.data && data.data.newApiVersion) {
                data = data.data;
            }
            this.AppConfigService.checkIfNeedRefresh(data.newApiVersion, data.isForced, data.isReactVersion);
        },

        showVendorLinks: function showVendorLinks(user) {
            var currUser = this.getCurrUser();
            return ((user && user.is_vendor) || (user && user.isVendor && user.isVendor())) && currUser.isVendor() && currUser.email !== user.email;
        },

        // NETWORK END *****************************************************

        getUserIdentificationImage: function getUserIdentificationImage(user_id) {
            var currUser = this.getCurrUser();
            var id = user_id || currUser._id;
            var url = this.Routes.v2_user_identification_image_path(id);
            return this.apiFetch(url);
        },

        getUserIdentificationImageStatus: function getUserIdentificationImageStatus(user_id) {
            var currUser = this.getCurrUser();
            var id = user_id || currUser._id;
            var url = this.Routes.v2_user_identification_image_status_path(id);
            return this.apiFetch(url);
        },

        checkFullSsnRequired: function checkFullSsnRequired(user_id) {
            var currUser = this.getCurrUser();
            var id = user_id || currUser._id;
            var url = this.Routes.v2_user_check_full_ssn_required_path(id);
            return this.apiFetch(url);
        },

        createEmailSignature: function createEmailSignature(company, user) {
            var url = this.Routes.v2_user_templates_create_email_signature_path();
            return this.apiCreate(url, null, user, true);
        },

        getEmailSignature: function getEmailSignature(company, user, signatureId) {
            var url = this.Routes.v2_user_templates_get_email_signature_path(signatureId);
            return this.apiFetch(url, null, user, true);
        },

        updateEmailSignature: function updateEmailSignature(user, signature) {
            var url = this.Routes.v2_user_templates_update_email_signature_path(signature._id);
            return this.apiUpdate(url, signature, user, true);
        },

        deleteEmailSignature: function deleteEmailSignature(company, user, signatureId) {
            var url = this.Routes.v2_user_templates_delete_email_signature_path(signatureId);
            return this.apiDelete(url, null, user, true);
        },

        getRecentSentFiles: function getRecentFilesCreated(userId, fileType) {
            var url = this.Routes.v2_get_recent_sent_files_for_user_path(userId);
            var data = {
                file_type: fileType
            };
            return this.apiFetch(url, data);
        },

        abortOnboarding: function abortOnboarding(reason) {
            var url = this.Routes.v2_self_onboarding_abort_path(this.getCurrUser()._id);
            return this.apiUpdate(url, { reason: reason }, this.currUser, true);
        },

        getBatchEmails: function getBatchEmails(userId) {
            var url = this.Routes.v2_get_batch_emails_path(userId);
            return this.apiFetch(url);
        },

        getBatchEmailContacts: function getBatchEmailContacts(userId, contactUserIds) {
            var url = this.Routes.v2_get_batch_email_contacts_path(userId);
            return this.apiCreate(url, { contact_user_ids: contactUserIds });
        },

        getBatchEmailsStatus: function getBatchEmailsStatus(userId) {
            var url = this.Routes.v2_get_batch_emails_status_path(userId);
            return this.apiFetch(url);
        },

        getAllowBulkWorkspaceEmails: function getBulkAllowWorkspaceEmails(userId) {
            var url = this.Routes.v2_get_allow_bulk_workspace_emails_path(userId);
            return this.apiFetch(url);
        },

        isUSOrCanadaBased: function isUSOrCanadaBased(user) {

            var isUsBasedLocation, isUsBasedNetwork, isUsBasedLogin, isCanadaBasedLocation, isCanadaBasedNetwork, isCanadaBasedLogin, searched = false;

            // 1 - hb_location
            if (user.hb_location && user.hb_location.country_code) {
                isUsBasedLocation = user.hb_location.country_code.toLowerCase() === 'us';
                isCanadaBasedLocation = user.hb_location.country_code.toLowerCase() === 'ca';
                searched = true;
            }

            // 2 - first_location
            if (user.user_attribution && user.user_attribution.first_login_location && user.user_attribution.first_login_location.country_code) {
                isUsBasedLogin = user.user_attribution.first_login_location.country_code.toLowerCase() === 'us';
                isCanadaBasedLogin = user.user_attribution.first_login_location.country_code.toLowerCase() === 'ca';
                searched = true;
            }

            if (searched) {
                return this.$q.when(isUsBasedLocation || isUsBasedNetwork || isUsBasedLogin || isCanadaBasedLocation || isCanadaBasedNetwork || isCanadaBasedLogin);
            }

            var deferred = this.$q.defer();

            // This is ass covering if we don't have location on the user, ask maxmind
            this.Maxmind.then(geoip2 => {
                geoip2.country(
                    function success(data) {
                        deferred.resolve(data.country && data.country.iso_code && (data.country.iso_code.toLowerCase() === 'us' || data.country.iso_code.toLowerCase() === 'ca'));
                    },
                    function error(data) {
                        deferred.resolve(false);
                    }
                );
            });

            return deferred.promise;
        },

        getNotificationSettings: function getNotificationSettings(saveToUser) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_get_notification_settings_path(currUser._id);
            var apiFetchPromise;
            if (saveToUser) {
                apiFetchPromise = this.apiFetch(url, null, this.currUser, true);

            } else {
                apiFetchPromise = this.apiFetch(url, null);
            }
            apiFetchPromise.then(function (data) {
                this.trigger('notificationSettingsUpdated');
                return data;
            }.bind(this));

            return apiFetchPromise;
        },

        getZapierApiKey: function getZapierApiKey(user) {
            var url = this.Routes.v2_user_get_zapier_api_key_path(user._id);
            return this.apiFetch(url);
        },

        getCalendlyPublicLink: function getCalendlyPublicLink(user) {
            var url = this.Routes.v2_calendly_public_link_path(user._id);
            return this.apiFetch(url, {}, null, false);
        },

        updateNotificationSettings: function updateNotificationSettings(newSettings) {
            var currUser = this.getCurrUser();
            var url = this.Routes.v2_update_notification_settings_path(currUser._id);
            return this.apiUpdate(url, newSettings, this.currUser, false);
        },

        dismissLoanOffer: function dismissLoanOffer() {
            var url = this.Routes.v2_dismiss_loan_offer_path(this.getCurrUser()._id);
            return this.apiUpdate(url, null, this.getCurrUser(), true);
        },

        convertMtaCookieToHeader: function convertMtaCookieToHeader() {
            var headers = {};
            var mta_uuid = this.$cookies.get('hb_mta_uuid');
            if (mta_uuid) {
                headers[this.Gon.hb_api_headers.mta_uuid] = mta_uuid;
            }
            return headers;
        },

        getUserInfo: function getUserInfo(id) {
            var url = this.Routes.v2_get_user_info_path(id);
            return this.apiFetch(url);
        },

        getValidPaymentMethods: function getValidPaymentMethods(user, file_id, only_for_vendor_account_settings) {
            this._checkPaymentMethodsBug(user, file_id, only_for_vendor_account_settings);

            const url = this.Routes.v2_get_user_payment_methods_path(user._id);
            const params = {file_id, only_for_vendor_account_settings, user_type: user && user.system_user_type};

            return this.apiFetch(url, params);
        },

        _checkPaymentMethodsBug: function _checkPaymentMethodsBug(user, file_id, only_for_vendor_account_settings) {
            // only check production clients
            if (!this.AppConfigService.isProduction() || !user || user.isVendor) {
                return;
            }

            const reportErr = (reason) => {
                this.AnalyticsService.trackError(
                    this,
                    this.AnalyticsService.analytics_events.company_id_error,
                    {},
                    {
                        reason: reason,
                        // these should be falsy, but just in case:
                        file_id: file_id,
                        only_for_vendor_account_settings: only_for_vendor_account_settings
                    }
                );
            };

            if (this.$window.location.hostname === 'www.honeybook.com') {
                reportErr('client in hb.com');
            } else {
                const cpConf = this.$window.clientPortalConfiguration;
                if (!cpConf) {
                    reportErr('client portal without conf');
                } else if (!cpConf.company_id) {
                    reportErr('client portal without company_id in conf');
                }
            }
        },

        getUserTaxEntities: function getUserTaxEntities(id) {
            const url = this.Routes.v2_get_tax_entities_path(id);
            return this.apiFetch(url);
        },

        getUserTaxDocuments: function getUserTaxDocuments() {
            const url = this.Routes.v2_list_tax_documents_path();
            return this.apiFetch(url);
        },

        getAmendmentContractTemplate: function getAmendmentContractTemplate() {
            var user = this.getCurrUser();
            var url = this.Routes.v2_get_amendment_contract_template_path(user._id);
            return this.apiFetch(url).then(function (resp) {
                if (resp.data && user.company) {
                    var template = user.company.agreements.find(function (agreement) { return agreement._id === resp.data._id; });

                    if (!template) {
                        template = this.ModelFactory.newModel('AgreementModel');
                        template.mixinFrom(resp.data);
                        user.company.agreements.push(template);
                    }

                    return template;
                }

                return resp;
            }.bind(this));
        },

        shouldOpenOooInEffectModal: function shouldOpenOooInEffectModal() {
            if (this.getCurrUser().ooo_in_effect && !this.$cookies.get(this.OOO_IN_EFFECT_MODAL_COOKIE_NAME)) {
                var midnight = new Date();
                midnight.setHours(24, 0, 0, 0);
                this.$cookies.put(this.OOO_IN_EFFECT_MODAL_COOKIE_NAME, 1, {path: '/', expires: midnight});
                return true;
            }

            return false;
        },

        shouldOpenOooHasEndedModal: function shouldOpenOooHasEndedModal() {
            return this.getCurrUser().ooo_has_ended;
        },
        
        sendClientPortalMagicLink: function sendClientPortalMagicLink(params) {
            const url = this.Routes.v2_users_send_magic_link_path();
            return this.apiCreate(url, params)
        },

        getRedirectUrlForIntellumSSO: function getRedirectUrlForIntellumSSO(code) {
            const url = this.Routes.v2_intellum_o_auth_authorize_code_path();
            return this.apiCreate(url, { code });
        }
    });
}());
