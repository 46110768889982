(function () {
    "use strict";

    //http://localhost:3000/#/workspace_file/54fee9f85cdc688c53000007/package

    // @ngInject
    function WorkspaceFileStepProposalControllerCtor(
            $scope, $stateParams, $state, $injector, $log,
            WorkspaceFilesManager, UsersManager, AppStates, moment, $timeout,
            WorkspaceFileService, PopupMessageService, AnalyticsService, PreviewModeService,
            ModalService, RedirectService, OnboardingService, WorkspaceService, WebsocketHelperService, Enums, DeviceService, UserService, MobileAppService,
            InjectBrandingService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFileStepProposalController';
        this.isPreviewMode = PreviewModeService.isInPreviewMode();
        this.OnboardingService = OnboardingService;
        this.RedirectService = RedirectService;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspaceService = WorkspaceService;
        this.$log = $log;
        this.AppStates = AppStates;
        this.$state = $state;
        this.AnalyticsService = AnalyticsService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.moment = moment;
        this.PreviewModeService = PreviewModeService;
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.Enums = Enums;
        this.MobileAppService = MobileAppService;
        this.InjectBrandingService = InjectBrandingService;

        this.workspaceFileId = $stateParams.workspace_file_id;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.ModalService = ModalService;
        this.isOwnerMode = this.workspaceFile && this.workspaceFile.isOwnerMode();
        this.isClientMode = this.workspaceFile && this.workspaceFile.isClientMode();
        this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);

        this.fileViewMode = WorkspaceFileService.whichCurrentFileView(this.workspaceFile);
        AnalyticsService.trackPageView(this, 'proposal step',
            {
                preview_mode: this.isPreviewMode,
                file_mode: this.fileViewMode
            }
        );

        this.isInAppBrowser = DeviceService.isInAppBrowser();

        this.WebsocketHelperService.registerToRoom(this.workspaceFileId + ".workspace_file_active_content_changed", this.getWorkspaceFile.bind(this));
        this.WebsocketHelperService.registerToRoom(this.user._id + ".workspace_file_status_changed", this.getWorkspaceFile.bind(this));

        this.appState.isFileRouteAndClientMode = false;
        this.isLoggedInAsClient = this.user.client_login_file_id && this.user.client_login_file_id === this.workspaceFile._id;

        PreviewModeService.setState('proposal');

        if (this.workspaceFile.file_type !== undefined) {
            // we dont want to wait for the update to load the film strip, cause it causes a blick in the ui when
            // moving between states
            this.loadFilmStrip();
        }

        this.register(this.workspaceFile, 'success', this.gotWorkspaceFile);
        this.register(this.workspaceFile, 'error', function error(message) {
            if (this.alreadyGotFile) {
                return;
            }
            if (!message) {
                message = 'FILE.ALERTS._UNABLE_TO_LOAD_MESSAGE_';
            }

            this.PopupMessageService.showAlert(PopupMessageService.severityTypes.info,
                message, function success() {
                    this.goToState(this.AppStates.root_core_navigation_home);
                }.bind(this));
        });

        this.workspaceFile._id = this.workspaceFileId;

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            UserService.onBookInPersonStateChange(event, toState);
        }.bind(this));

        $scope.$on('$destroy', function () {
            this.WebsocketHelperService.unregisterFromRoom(this.workspaceFileId + ".workspace_file_active_content_changed");
            this.WebsocketHelperService.unregisterFromRoom(this.user._id + ".workspace_file_status_changed");
        }.bind(this));

        this.isHeaderEditable = this.isOwnerMode && !this.isPreviewMode && !this.isViewOnlyMode;

        this.userHasDefaultProfileImage = this.user && this.user.isDefaultProfileImage();

        if(this.isInAppBrowser) {
            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.file_step_active, {step: 'proposal'});
            this.register(this.MobileAppService, this.MobileAppService.INBOUND.WORKSPACE_FILE._GO_TO_STEP_, function (data) {
                if(data.step === 'contract') {
                    this.continueToAgreement();
                }
            }.bind(this));
        }
    }


    Controllers.WorkspaceFileStepProposalController = Class(Controllers.BaseController, {

        constructor: WorkspaceFileStepProposalControllerCtor,

        gotWorkspaceFile: function gotWorkspaceFile(message, status) {
            if (this.workspaceFile.isQuestionnaire() || this.workspaceFile.isAgreement()) {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
                return;
            }
            // This code happens many many many times, make sure not to put code here you want to run only once.
            this.isOwnerMode = this.workspaceFile && this.workspaceFile.isOwnerMode();
            this.isClientMode = this.workspaceFile && this.workspaceFile.isClientMode();
            this.isAvatarUploadable = this.getIsAvatarUploadable();

            if(this.workspaceFile.company){

                this.InjectBrandingService.setBrandColorVars(this.workspaceFile.company.brand_color);
            }

            // redirect if you are not the client
            if (!this.alreadyGotFile &&
                !this.isPreviewMode &&
                (this.user._id === this.workspaceFile.owner._id || this.workspaceFile.isViewOnlyMode())) {
                this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile, null, true);
                return;
            }

            if (!this.alreadyGotFile && !this.isPreviewMode && !this.isOwnerMode) {
                this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.proposal_seen);
            }

            this.alreadyGotFile = true;

            this.loadFilmStrip();
        },

        loadFilmStrip: function loadFilmStrip() {
            if (this.filmStripLoaded === true) {
                return;
            }
            this.filmStripLoaded = true;
            this.linksTo = this.WorkspaceFileService.getFilmstripLinksForWorkspace(
                this.workspaceFile, 'proposal', this.$stateParams);
        },

        continueToAgreement: function continueToAgreement() {


            if (this.isPreviewMode) {
                this.PreviewModeService.advancedState(this.workspaceFile, {}, 'replace');
            }
            else {
                this.working = true;
                var promise = this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.agreement_seen);

                promise.then(function success() {
                    this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
                }.bind(this));

                promise.finally(function fulfilled() {
                    this.working = false;
                }.bind(this));
            }
        },

        continueToPayment: function continueToPayment() {
            var params = this.$state.params;

            if (this.isPreviewMode) {
                this.PreviewModeService.advancedState(this.workspaceFile, params, 'replace');
            }
            else {
                this.working = true;
                var promise = this.WorkspaceFilesManager.updateStatus(this.workspaceFile, this.WorkspaceFileService.fileStatuses.payments_seen);

                promise.then(
                    function success() {
                        this.WorkspaceFileService.gotoWorkspaceFileView(this.workspaceFile);
                    }.bind(this),
                    function error(resp) {
                        // We block some file status updates for admin logins
                        if (resp && resp.data && resp.data.error_type && resp.data.error_type === 'HBMalkutUserError') {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                        }
                    }.bind(this)
                );

                promise.finally(function fulfilled() {
                    this.working = false;
                }.bind(this));
            }
        },

        getFileCardClasses: function getFileCardClasses() {
            return this.WorkspaceFileService.getFileCardClasses(this.workspaceFile);
        },

        getProposalLabel: function getProposalLabel() {
            return this.WorkspaceFileService.getProposalLabel(this.workspaceFile);
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        openIntakeModal: function openIntakeModal() {
            this.OnboardingService.openInProductIntakeForm();
        },

        getWorkspaceFile: function getWorkspaceFile() {
            this.$timeout(function () {
                this.workspaceFile = this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
            }.bind(this) , 2000);
        },

        getExpirationEnd: function getExpirationEnd() {

            var expiration = this.moment(this.workspaceFile.auto_expiration.ends_on);

            if (this.isPreviewMode) {
                switch (this.workspaceFile.auto_expiration.period) {
                    case 'one_week':
                        expiration = this.moment().add(1, 'week');
                        break;
                    case 'two_weeks':
                        expiration = this.moment().add(2, 'week');
                        break;
                    case 'three_weeks':
                        expiration = this.moment().add(3, 'week');
                        break;
                    case 'four_weeks':
                        expiration = this.moment().add(4, 'week');
                        break;
                }
            }

            return expiration.format('ddd, MMM D, YYYY');
        },

        uploadProfileImage: function uploadProfileImage() {
            if (this.workspaceFile.owner._id === this.user._id && this.isAvatarUploadable) {
                this.ModalService.openUploadProfilePhotoModal(this.user).then(function updateUser () {
                    //refresh the avatar.
                    this.WorkspaceFilesManager.getWorkspaceFile(this.workspaceFileId);
                    this.userHasDefaultProfileImage = this.user && this.user.isDefaultProfileImage();
                }.bind(this));
            }
        },

        getIsAvatarUploadable: function getIsAvatarUploadable() {
            return (this.workspaceFile.owner._id === this.user._id) && !this.PreviewModeService.isInSendFilePreviewMode();
        }

    });
}());
