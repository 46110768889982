/**
 * Created by udicohen on 19/2/15.
 */
(function () {
    "use strict";


    // @ngInject
    function CompaniesManagerCtor(Routes, $q,
                                  APIService, ModelFactory, Enums, _,
                                  UsersManager, TemplatesManager, AnalyticsService, AbTestService, PubSubService, $injector) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.UsersManager = UsersManager;
        this.TemplatesManager = TemplatesManager;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.AbTestService = AbTestService;
        this.$injector = $injector;
        this._ = _;

        PubSubService.ventMyBitchUp(this);
    }


    Services.CompaniesManager = Class(Services.BaseModelManager, {

        constructor: CompaniesManagerCtor,

        deleteGiftCard: function deleteGiftCard(companyId, code) {
            var url = this.Routes.v2_delete_voucher_lead_path(companyId, code);
            return this.apiDelete(url);
        },

        sendClientPortalLink: function sendClientPortalLink(toUser, subject, body, workspaceId) {
            var url = this.Routes.v2_send_client_portal_link_path(this.getCurrCompany()._id);
            return this.apiCreate(url, {email: toUser.email, subject: subject, body: body, workspace_id: workspaceId });
        },

        convertToCompany: function convertToUser(jsonCompany) {
            var company = this.ModelFactory.newModel('CompanyModel');
            company.mixinFrom(jsonCompany, null, false, false);
            return company;
        },

        triggerCompanySubscribed: function triggerCompanySubscribed() {
            this.trigger(this.Enums.PubSubTriggers.companySubscribed);
        },

        triggerCompanyTemplatesUpdated: function triggerCompanyTemplatesUpdated() {
            this.trigger(this.Enums.PubSubTriggers.companyTemplatesUpdated);
        },

        /**
         * Fetches the company of the currently logged in user
         * @returns {CompanyModel} a {@link CompanyModel} that could be empty at first
         */
        getCurrCompany: function getCurrCompany(force) {
            this._internalGetCurrCompany(force);
            return this.UsersManager.getCurrUser().company;
        },

        /**
         * Fetches the company of the currently logged in user
         * @returns {Promise<CompanyModel>} a {@link CompanyModel} that could be empty at first
         */
        getCurrCompanyPromise: function getCurrCompanyPromise(force) {
            var defer = this.$q.defer();
            var company = this.UsersManager.getCurrUser().company;

            var promise = this._internalGetCurrCompany(force);
            if (promise) {
                promise.then(function getCompanySuccess() {
                    defer.resolve(company);
                }).catch(function getCompanyFailure(res) {
                    defer.reject(res);
                });
            } else {
                defer.resolve(company);
            }

            return defer.promise;
        },

        /**
         * Fetches the company of the currently logged in user
         * @returns {Promise<CompanyModel>} a {@link CompanyModel} that could be empty at first
         */
        _internalGetCurrCompany: function _internalGetCurrCompany(force) {
            var user = this.UsersManager.getCurrUser();
            var company = user.company;

            if (company.id() && this.isModelTimedOut(company, force)) {
                // reload the company from server in the background
                var url = this.Routes.v2_company_path(company.id());
                return this.apiFetch(url, null, company, true).then(
                    function success(resp){
                        company.setFullyFetched();
                        this.AnalyticsService.updateCompanyProperties(company);

                        if (company.subscribed) {
                            user.company.subscribed = company.subscribed;

                            this.FeatureRestrictionService = this.FeatureRestrictionService || this.$injector.get('FeatureRestrictionService');
                            this.FeatureRestrictionService.updateFeatureRestrictions();
                        }
                    }.bind(this)
                );
            }
        },

        /**
         * This method saves the supplied company's Overview settings
         *
         * @param company the company model to save
         * @param updateUserWithPhoneNumber flag for updating the phone number in the user model as well since we keep is on 2 models
         * @returns {promise} a promise that will be rejected/resolved when the action is done
         */
        saveCompanyFields: function saveCompanyFields(company, updateUserWithPhoneNumber, useMalkutPath) {
            var url = useMalkutPath ? this.Routes.v2_malkut_update_company_path(company.id()) : this.Routes.v2_update_company_path(company.id());
            var data = company.getOverviewJSON();

            data.update_user_with_phone_number = !!updateUserWithPhoneNumber; // !! is to make sure this flag is boolean

            return this.apiUpdate(url, data, company, true, null, 'waitForLast').then(
                function success(resp) {
                    company.setFullyFetched();
                    this.UsersManager.saveCurrUser();
                }.bind(this)
            );
        },

        validateCompanyWebsiteUrl: function validateCompanyWebsiteUrl(company) {
            var url = this.Routes.v2_company_validate_company_web_site_path(company.id());
            return this.apiUpdate(url, {}, company, true, null, 'waitForLast').then(
                function success(resp) {
                    company.setFullyFetched();
                    this.UsersManager.saveCurrUser();
                }.bind(this)
            );
        },

        validateCompanyUrlPrefix: function validateCompanyUrlPrefix(company, prefix) {
            var url = this.Routes.v2_validate_company_url_prefix_path(company._id, encodeURIComponent(prefix));
            return this.apiFetch(url);
        },

        verifyDomainIsValid: function verifyDomainIsValid(company, hostname) {
            var url = this.Routes.v2_verify_domain_is_valid_path(company._id);
            return this.apiFetch(url, {hostname: hostname});
        },

        verifyCustomDomainDnsConfigured: function verifyCustomDomainDnsConfigured(company, domain, subdomain) {
            var url = this.Routes.v2_verify_custom_domain_dns_configured_path(company._id);
            return this.apiFetch(url, {domain: domain, subdomain: subdomain});
        },

        verifyCustomDomainAvailable: function verifyCustomDomainAvailable(company, domain, subdomain) {
            var url = this.Routes.v2_verify_custom_domain_available_path(company._id);
            return this.apiFetch(url, {domain: domain, subdomain: subdomain});
        },

        activateCustomDomain: function activateCustomDomain(company, hostname) {
            var url = this.Routes.v2_activate_custom_domain_path(company._id);
            return this.apiUpdate(url, {hostname: hostname}, company, true, null, 'waitForLast');
        },

        getCompanyCustomDomain: function getCompanyCustomDomain(company) {
            var url = this.Routes.v2_get_company_custom_domain_path(company._id);
            return this.apiFetch(url);
        },

        getCompanyUrlPrefix: function getCompanyUrlPrefix(company) {
            var url = this.Routes.v2_get_company_url_prefix_path(company._id);
            return this.apiFetch(url);
        },

        updateCompanyUrlPrefix: function updateCompanyUrlPrefix(company, url_prefix) {
            var url = this.Routes.v2_update_company_url_prefix_path(company._id, url_prefix);
            return this.apiUpdate(url, null, company, true, null, 'waitForLast');
        },

        updateCompanyFlowTheme: function updateCompanyFlowTheme(company, theme) {
            var url = this.Routes.v2_update_company_flow_theme_path(company._id);
            return this.apiUpdate(url, {theme: theme}, company, true, null, 'waitForLast');
        },

        updateCompanyAdditionalInfo: function updateCompanyAdditionalInfo(company, data) {
            var url = this.Routes.v2_company_update_additional_info_path(company.id());
            return this.apiUpdate(url, data, company, false, null, 'waitForLast');
        },

        getCompanyAdditionalInfo: function getCompanyAdditionalInfo(company) {
            var url = this.Routes.v2_company_get_additional_info_path(company.id());
            return this.apiFetch(url);
        },

        deleteBankAccountOwner: function deleteBankAccountOwner(company, personId) {
            var url = this.Routes.v2_delete_bank_account_owner_path(company._id, personId);
            return this.apiDelete(url, null, company, true);
        },

        saveVerificationDocument: function saveVerificationDocument(company, s3_url, key, side) {
            var url = this.Routes.v2_save_verification_document_path(company._id);
            return this.apiCreate(url, {s3_url: s3_url, key: key, side: side}, company, true);
        },

        /**
         * saves the company settings. booliean variable that defines how the app behaves on the company level
         * @param company
         * @returns {promise}
         */
        saveCompanySettings: function saveCompanySettings(company) {
            var url = this.Routes.v2_update_company_settings_path(company.id());

            return this.apiUpdate(url, {company_settings: company.company_settings}, company, true);
        },

        /**
         * Deletes an image form the cover_images collection in Company
         *
         * @param company the company model to remove the image from
         * @param imageId the image id to remove
         * @returns {promise} a promise that will be rejected/resolved when the action is done
         */
        deleteCoverImage: function deleteCoverImage(company, imageId) {
            var url = this.Routes.v2_company_remove_cover_image_path(company.id(), imageId);
            return this.apiDelete(url, null, company, true);
        },

        /**
         * Deletes a file from the collection in Company
         *
         * @param company the company model to remove the image from
         * @param fileId the file id to remove
         * @returns {promise} a promise that will be rejected/resolved when the action is done
         */
        deleteUploadedFile: function deleteUploadedFile(company, fileId) {
            var url = this.Routes.v2_company_remove_uploaded_file_path(company.id(), fileId);
            return this.apiDelete(url, null, company, true);
        },
        deleteCompanyIconOrLogo: function deleteCompanyIconOrLogo(company,logoType) {
            var url = this.Routes.v2_company_delete_logo_or_icon_path(company._id,logoType);
            return this.apiDelete(url,null,company,true);
        },

        /**
         *  Set Default tax
         * @param company
         * @param tax
         *
         * @returns {*}
         */
        updatedDefaultTax: function updatedDefaultTax(company, tax, taxTitle) {
            var taxData = { default_tax: tax , default_tax_title: taxTitle};
            var url = this.Routes.v2_companies_save_default_tax_path(company.id());
            return this.apiUpdate(url, taxData, company, true);
        },

        updatedDefaultSVC: function updatedDefaultSVC(company, svc) {
            var svcData = {default_svc: svc};
            var url = this.Routes.v2_companies_save_default_svc_path(company.id());
            return this.apiUpdate(url, svcData, company, true);
        },

        updateAllInclusiveInstantPayouts: function updateAllInclusiveInstantPayouts(company, toggle_state, toggleSource) {
            var data = { all_inclusive_instant_payouts: toggle_state, all_inclusive_instant_payouts_source: toggleSource};
            var url = this.Routes.v2_companies_update_all_inclusive_instant_payouts_path(company.id());
            return this.apiUpdate(url, data, company, true);
        },

        /**
         *  Save as Proposal Template, create new template that is a duplication of current proposal
         * @param company
         * @param workspaceFile
         *
         * @returns {*}
         */
        saveAsVendorProposalTemplate: function saveAsVendorProposalTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id,
                title: title};
            var url = this.Routes.v2_save_as_vendor_proposal_template_path(company.id());
            return this.apiCreate(url, templateData, company, true);

        },

        /**
         *  delete Proposal Template
         * @param company
         * @param proposalId
         *
         * @returns {*}
         */
        deleteProposalTemplate: function deleteProposalTemplate(company, templateId) {

            var url = this.Routes.v2_delete_proposal_template_path(company.id(), templateId);
            return this.apiDelete(url, null, company, true);

        },

        /**
         *  Save Package Template, update existing template
         * @param company
         * @param workspaceFile
         * @packageId (in file we have multiple packages)
         *
         * @returns {*}
         */
        savePackageTemplate: function savePackageTemplate(company, workspaceFile, packageId) {
            var templateData = { workspace_id: workspaceFile._id };
            var url = this.Routes.v2_save_package_template_path(company.id(), packageId);
            return this.apiUpdate(url, templateData, company, true);
        },

        /**
         *  Save as Package Template, create new template that is a duplication of current package
         * @param company
         * @param workspaceFile
         * @packageId (in file we have multiple packages)
         *
         * @returns {*}
         */
        saveAsPackageTemplate: function saveAsPackageTemplate(company, workspaceFile, packageId, title) {
            var templateData = { workspace_id: workspaceFile._id,
                title: title};
            var url = this.Routes.v2_save_as_package_template_path(company.id(), packageId);
            return this.apiCreate(url, templateData, company, true);

        },

        /**
         *  delete Package Template
         * @param company
         * @param packageId
         *
         * @returns {*}
         */
        deletePackageTemplate: function deletePackageTemplate(company, packageId) {
            return this.TemplatesManager.deletePackage(company, packageId);
        },

        updateService: function updateService(company, packageId, serviceModel) {
            return this.TemplatesManager.updateService(company, packageId, serviceModel);
        },

        /**
         *  Save as Payments Template, create new template that is a duplication of current payments
         * @param company
         * @param workspaceFile
         *
         * @returns {*}
         */
        saveAsPaymentsTemplate: function saveAsPaymentsTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id,
                title: title};
            var url = this.Routes.v2_save_as_payments_template_path(company.id());
            return this.apiCreate(url, templateData, company, true);

        },

        /**
         *  delete Payments Template
         * @param company
         * @param templateId
         *
         * @returns {*}
         */
        deletePaymentsTemplate: function deletePaymentsTemplate(company, templateId) {

            var url = this.Routes.v2_delete_payments_template_path(company.id(), templateId);
            return this.apiDelete(url, null, company, true);

        },

        /**
         *  Save as Agreement Template, create new template that is a duplication of current agreement
         * @param company
         * @param workspaceFile
         *
         * @returns {*}
         */
        saveAsAgreementTemplate: function saveAsAgreementTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id,
                title: title};
            var url = this.Routes.v2_save_as_agreement_template_path(company.id());
            return this.apiCreate(url, templateData, company, true).then(function success(resp) {
                workspaceFile.agreement.origin_copy_id = this._findOriginId(company.agreements, title);
                return resp;
            }.bind(this));
        },

        /**
         *  delete Agreement Template
         * @param company
         * @param agreementId
         *
         * @returns {*}
         */
        deleteAgreementTemplate: function deleteAgreementTemplate(company, templateId) {
            return this.TemplatesManager.deleteAgreement(company, null, templateId);
        },

        saveAsQuestionnaireTemplate: function saveAsQuestionnaireTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id,
                title: title};
            var url = this.Routes.v2_save_as_questionnaire_template_path(company.id());
            return this.apiCreate(url, templateData, company, true).then(function success(resp) {
                workspaceFile.questionnaire.origin_copy_id = this._findOriginId(company.questionnaires, title);
                return resp;
            }.bind(this));

        },

        saveAsBrochureTemplate: function saveAsBrochureTemplate(company, workspaceFile, title) {
            if(workspaceFile.isNewBrochure()) {
                return this.TemplatesManager.createTemplateFromFile(company, 'brochure', workspaceFile._id, title).then(function success(resp) {
                    workspaceFile.origin_copy_id = this._findOriginId(company.getCompanyBrochures(true), title);
                    return resp;
                }.bind(this));
            } else {
                var templateData = { workspace_id: workspaceFile._id,
                    title: title};
                var url = this.Routes.v2_save_as_brochure_template_path(company.id());
                return this.apiCreate(url, templateData, company, true).then(function success(resp) {
                    workspaceFile.brochure_container.origin_copy_id = this._findOriginId(company.getCompanyBrochures(true), title);
                    return resp;
                }.bind(this));
            }
        },

        deleteBrochureTemplate: function deleteBrochureTemplate(company, templateId) {
            return this.TemplatesManager.deleteBrochure(company, null, templateId);
        },

        saveAsTimelineTemplate: function saveAsTimelineTemplate(company, workspaceFile, title) {
            var templateData = { workspace_id: workspaceFile._id, title: title};
            var url = this.Routes.v2_save_timeline_template_path(company.id());
            return this.apiCreate(url, templateData, company, true).then(function success(resp) {
                workspaceFile.timeline_container.origin_copy_id = this._findOriginId(company.timeline_containers, title);
                return resp;
            }.bind(this));
        },
        
        deleteTimelineTemplate: function deleteTimelineTemplate(company, templateId) {
            return this.TemplatesManager.deleteTimeline(company, null, templateId);
        },

        deleteQuestionnaireTemplate: function deleteQuestionnaireTemplate(company, templateId) {
            return this.TemplatesManager.deleteQuestionnaire(company, null, templateId);
        },

        getCompanyWidget: function getCompanyWidget(company, contactFormId){
            var url = this.Routes.v2_company_embed_contact_form_path(company._id, contactFormId);
            return this.apiFetch(url);
        },

        /**
         * Publishes the content of the widget to the vendor's site
         * @param {CompanyModel} company The company to update the widget to.
         * @param {String} htmlCode The full HTML of the updated widget
         * @returns {promise} a promise that will be resolved/rejected as the update is done
         */
        publishWidgetContent: function publishWidgetContent(company, htmlCode, doNotOverwrite) {
            var url = this.Routes.v2_publish_widget_path(company._id);
            return this.apiUpdate(url, {html_body: htmlCode, do_not_overwrite: doNotOverwrite});
        },

        addCompanyTeamMember: function addCompanyTeamMember(company, member){
            var url = this.Routes.v2_add_company_team_member_path(company._id);
            var data = member.dataOnly();
            delete data.company;
            return this.apiCreate(url, data, company, true);
        },

        getSetupAssets: function getSetupAssets(company) {
            var url = this.Routes.v2_get_setup_path(company._id);
            return this.apiFetch(url, null, company, true);
        },

        createCompanyImage: function createCompanyImage(company, s3Response, type) {
            var imageType = type || 'cover';
            var url = this.Routes.v2_companies_save_image_path(company._id, imageType);
            return this.apiCreate(url, s3Response, company, true);
        },

        createCompanyFile: function createCompanyFile(company, s3Response, type) {
            var fileType = type || 'unknown';
            var url = this.Routes.v2_company_save_asset_path(company._id, fileType);  //this.Routes.v2_companies_save_file_path(company._id, fileType);
            return this.apiCreate(url, s3Response, company, true);
        },

        createCompanyFiles: function createCompanyFiles(company, filesDetails) {
            var url = this.Routes.v2_company_save_batch_files_path(company._id);
            var data = {
                filesDetails: filesDetails.map(function(x) { return Object.assign(x, { type: x.type || 'unknown' }); })
            };

            return this.apiCreate(url, data, company, true);
        },

        setAutomationTimeFrame: function setAutomationTimeFrame(company, automationItemId, timeFrame) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.relevant_time_frame = timeFrame;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true);
        },

        setAutomationFlow: function setAutomationFlow(company, automationItemId, flow) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.flow_template_id = flow._id;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true);
        },

        setAutomationStatus: function setAutomationStatus(company, automationItemId, isOn,
                                                          emailTemplateId, questionnaireTemplateId, brochureTemplateId, flowTemplateId) {

            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.is_automation_on = isOn;

            if (isOn) {
                automationInfo.email_template_id = emailTemplateId;
                automationInfo.questionnaire_template_id = questionnaireTemplateId;
                automationInfo.brochure_container_template_id = brochureTemplateId;
                automationInfo.flow_template_id = flowTemplateId;
            }

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true, null, 'waitForLast');
        },

        setBookingRating: function setBookingRating(company) {
            var url = this.Routes.v2_update_booking_rating_enabled_path(company._id);
            return this.apiUpdate(url, {is_booking_rating_enabled: company.is_booking_rating_enabled}, company, true, null,'waitForLast');
        },

        declinePendingInvite: function declinePendingInvite(company_id, invite_id){
            var url = this.Routes.v2_decline_pending_invite_path(company_id);
            return this.apiUpdate(url, {invite_id: invite_id});
        },

        cancelPendingInvite: function cancelPendingInvite(company_id, invite_id){
            var url = this.Routes.v2_cancel_pending_invite_path(company_id);
            return this.apiUpdate(url, {invite_id: invite_id});
        },

        getPendingCompanyTeamMemberInvite: function getPendingCompanyTeamMemberInvite(company_id){
            var url = this.Routes.v2_get_company_pending_invite_path(company_id);
            return this.apiFetch(url);
        },

        getPendingTeamMemberInvite: function getPendingTeamMemberInvite(company_id){
            var url = this.Routes.v2_get_user_pending_invite_path(company_id);
            return this.apiFetch(url);
        },

        updateAutomationCustomEmail: function updateAutomationCustomEmail(company, automationType, subject, body) {
            var url = this.Routes.v2_update_automation_path(company._id);
            var automationInfo = {
                automation_type_cd: automationType,
                subject: subject,
                html_body: body
            };
            return this.apiUpdate(url, automationInfo, company, true);
        },

        setAutomationEventType: function setAutomationEventType(company, automationItemId, eventType) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.event_type = eventType;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true, null, 'waitForLast');
        },

        setAutomationEmailTemplateId: function setAutomationEmailTemplateId(company, automationItemId, tmplId) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.email_template_id = tmplId;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true, null, 'waitForLast');
        },

        setAutomationQuestionnaireTemplateId: function setAutomationQuestionnaireTemplateId(company, automationItemId, tmplId) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.questionnaire_template_id = tmplId;
            automationInfo.brochure_container_template_id = null;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true);
        },

        setAutomationBrochureTemplateId: function setAutomationBrochureTemplateId(company, automationItemId, tmplId) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.brochure_container_template_id = tmplId;
            automationInfo.questionnaire_template_id = null;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true);
        },

        getInquiryFollowUpReminder: function getInquiryFollowUpReminder() {
            var url = this.Routes.v2_get_inquiry_follow_up_state_path();
            return this.apiFetch(url);
        },

        updateInquiryFollowUpReminder: function updateInquiryFollowUpReminder(data) {
            var url = this.Routes.v2_update_inquiry_follow_up_path();
            return this.apiUpdate(url, data);
        },

        clearAutomationAttachment: function clearAutomationAttachment(company, automationItemId) {
            var automationInfo = {};
            automationInfo.automation_type_cd = automationItemId;
            automationInfo.questionnaire_template_id = null;
            automationInfo.brochure_container_template_id = null;

            var url = this.Routes.v2_update_automation_path(company._id);
            return this.apiUpdate(url, automationInfo, company, true);
        },

        saveUserCompanyPermission: function saveUserCompanyPermission(company, user, permissionName) {
            var data = {
                user_permission: permissionName
            };
            var url = this.Routes.v2_update_team_member_path(company._id, user._id);
            return this.apiUpdate(url, data, company, true);
        },

        setNotificationStatus: function setNotificationStatus(company, notificationTypeId, isOn) {

            var notification = {};
            notification.notification_type_cd = notificationTypeId;
            notification.is_notification_on = isOn;

            var url = this.Routes.v2_update_owner_notification_path(company._id);
            return this.apiUpdate(url, notification, company, true, null, 'waitForLast');

        },

        getRefundPaymentData: function getRefundPaymentData(company, fileId, paymentId) {
            var data = {
                file_id: fileId,
                payment_id: paymentId,
            };
            var url = this.Routes.v2_company_get_refund_payment_data_path(company._id);
            return this.apiFetch(url, data, null, false);
        },

        getQuickbooksTaxCode: function getQuickbooksTaxCode(company) {
            var url = this.Routes.v2_get_company_quickbooks_tax_code_path(company._id);
            return this.apiFetch(url);
        },

        updateQuickbooksTaxCode: function updateQuickbooksTaxCode(company, newCode) {
            var url = this.Routes.v2_update_company_quickbooks_tax_code_path(company._id);
            return this.apiUpdate(url, {new_code: newCode});
        },

        updateQuickbooksZeroTaxCode: function updateQuickbooksZeroTaxCode(company, newCode) {
            var url = this.Routes.v2_update_company_quickbooks_zero_tax_code_path(company._id);
            return this.apiUpdate(url, {new_code: newCode});
        },

        getQuickbooksDepositAccounts: function getQuickbooksDepositAccounts(company) {
            var url = this.Routes.v2_get_company_quickbooks_deposit_account_path(company._id);
            return this.apiFetch(url);
        },

        updateQuickbooksDepositAccount: function updateQuickbooksDepositAccount(company, depositAccountId) {
            var url = this.Routes.v2_update_company_quickbooks_deposit_account_path(company._id);
            var data = {};
            if (depositAccountId) {
                data.deposit_account_id = depositAccountId;
            }
            return this.apiUpdate(url, data);
        },

        disconnectQuickbooks: function disconnectQuickbooks(company) {
            var url = this.Routes.v2_destroy_quickbooks_path(company._id);
            return this.apiDelete(url, null, company, true);
        },

        getCompanySpaces: function (company) {
            company = company || this.UsersManager.getCurrUser().company;

            var url = this.Routes.v2_company_get_spaces_path(company._id);
            return this.apiFetch(url, null, company, true);
        },

        updateCompanySpaces: function updateCompanySpaces (company, spaces) {
            var url = this.Routes.v2_company_update_spaces_path(company._id);
            return this.apiUpdate(url, {spaces: spaces}, company, true);
        },

        setDefaultCoverImage: function setDefaultCoverImage(company, image_id) {
            var data = {
                image_id: image_id
            };

            var url = this.Routes.v2_company_set_default_cover_image_path(company._id);
            return this.apiUpdate(url, data, company, true, null, 'waitForLast');
        },

        accountSettupAssetsReady: function accountSettupAssetsReady(company, files){
            var url = this.Routes.v2_update_account_setup_assets_ready_path(company._id);

            var data = {uploaded_files: files};
            return this.apiCreate(url, data, company, true);
        },

        // expenses
        createExpense: function createExpense(company) {
            var url = this.Routes.v2_create_company_expense_path(company._id);
            var data = {};
            return this.apiCreate(url, data, null, false);
        },

        getExpenses: function getExpenses(company, params, page, perPage) {
            var data = params || {};
            if (page) {
                data.page = page;
                data.perPage = perPage || 50;
            }
            var url = this.Routes.v2_get_company_expenses_path(company._id);
            return this.apiFetch(url, data, null, false);
        },

        getFacebookLeadPages: function getFacebookLeadPages(company) {
            var data = {};
            var url = this.Routes.v2_get_company_facebook_lead_pages_path(company._id);
            return this.apiFetch(url, data, null, false);
        },

        updateProjectTypes: function updateProjectTypes(company, projectTypes, defaultProjectTypeLabel) {
            var url = this.Routes.v2_company_update_project_types_path(company._id);
            return this.apiUpdate(url, {project_types: projectTypes, default_project_type_label: defaultProjectTypeLabel}, company, true);
        },

        deleteProjectType: function deleteProjectType(company, projectTypeId) {
            var url = this.Routes.v2_company_delete_project_type_path(company._id, projectTypeId);
            return this.apiDelete(url, null).then(function success(resp) {
                var index = this._.findIndex(company.project_types, function(projectType) {
                    return projectType._id === projectTypeId;
                });
                if (index !== -1) {
                    company.project_types.splice(index, 1);
                }
            }.bind(this));
        },

        updateLeadSources: function updateLeadSource(company, leadSources, markContactFormAsUnpublished) {
            var url = this.Routes.v2_company_update_lead_sources_path(company._id);
            return this.apiUpdate(url, {lead_sources: leadSources, mark_contact_form_as_unpublished: markContactFormAsUnpublished}, company, true);
        },

        publishContactFormsLeadSource: function publishContactForm(company) {
            var url = this.Routes.v2_company_publish_contact_forms_lead_source_path(company._id);
            return this.apiCreate(url, null, company, true);
        },

        deleteExpense: function deleteExpense(company, expense) {
            var url = this.Routes.v2_remove_company_expense_path(company._id, expense._id);
            var data = {};
            return this.apiDelete(url, null, null, null);
        },

        updateExpense: function updateExpense(company, expense) {
            var url = this.Routes.v2_update_company_expense_path(company._id, expense._id);
            var data = {};
            return this.apiUpdate(url, expense, null, false, null, 'waitForLast');
        },

        downloadExpenseCsv: function downloadExpenseCsv(company, filter) {
            var url = this.Routes.v2_get_company_expenses_export_path(company._id);
            return this.apiFetch(url, filter, null, false, null, 'waitForLast');
        },

        getUsageOfPackage: function getUsageOfPackage(company, pckg) {
            var url = this.Routes.v2_company_get_package_usage_path(company._id, pckg._id);
            return this.apiFetch(url);
        },

        _findOriginId: function _findOriginId(templates, templateTitle) {
            var id = null;
            for (var i = 0; i < templates.length && !id; i++) {
                if (templates[i].title === templateTitle) {
                    id = templates[i]._id;
                }
            }

            return id;
        },

        getContactForm: function getContactForm(company, user, contactFormId) {
          var url = this.Routes.v2_company_contact_form_path(company._id, contactFormId);
          return this.apiFetch(url, null, company, true);
        },

        setFacebookLeadsPage: function setFacebookLeadsPage(page, user_access_token) {
            var user = this.UsersManager.getCurrUser();
            var company = user.company;

            var data = {
                page_name: page.name,
                page_id: page.id,
                user_access_token: user_access_token
            };

            var url = this.Routes.facebook_subscribe_page_path(company._id);
            return this.apiUpdate(url, data, company, true);
        },

        deleteFacebookLeadPage: function deleteFacebookLeadPage(company, page) {
            var url = this.Routes.facebook_unsubscribe_page_path(company._id, page._id);

            return this.apiDelete(url, null, null, null);
        },

        updateClientPortalConfiguration: function updateClientPortalConfiguration(company, confObj) {
            var url = this.Routes.v2_update_client_portal_configuration_path(company._id);
            return this.apiUpdate(url, confObj, company, true);
        },

        saveCustomProjectFields: function saveCustomProjectFields(company, fields) {
            var url = this.Routes.v2_save_custom_project_fields_path(company._id);
            return this.apiCreate(url, {custom_project_fields: fields}, company, true);
        },

        createBookingReview: function createBookingReview(company, event_id, rating_score, rating_comment) {
            var url = this.Routes.v2_create_booking_review_path(company._id);
            return this.apiCreate(url, {event_id: event_id, rating_score: rating_score, rating_comment: rating_comment});
        },

        updateThumbnailVisibleToClient: function updateThumbnailVisibleToClient(company, is_visible) {
            var url = this.Routes.v2_update_thumbnail_visible_to_client_path(company._id);
            var data = {is_flow_thumbnail_visible_to_client: is_visible};
            return this.apiUpdate(url, data, company, true);
        },

        updateSmsRemindersEnabled: function updateSmsRemindersEnabled(company, value) {
            var url = this.Routes.v2_company_sms_enabled_path(company._id);
            var data = {value: value};
            return this.apiUpdate(url, data, company, true);
        },

        updateContactEnrichmentEnabled: function updateContactEnrichmentEnabled(company, value) {
            var url = this.Routes.v2_contact_enrichment_enabled_path(company._id);
            var data = {value: value};
            return this.apiUpdate(url, data, company, true);
        },

        updateSmsPaymentRemindersEnabled: function updateSmsPaymentRemindersEnabled(company, value) {
            var url = this.Routes.v2_company_sms_payment_reminders_enabled_path(company._id);
            var data = {value: value};
            return this.apiUpdate(url, data, company, true);
        },

        fetchCompanyFlows: function fetchCompanyFlows(company) {
            var url = this.Routes.v2_list_company_flows_path(company._id);
            var data = { flow_type: 'template' };
            return this.apiFetch(url, data).then(function(res) {
                var flowsData = { flows: res.data };
                company.mixinFrom(flowsData);
                company.onModelSuccess();
                return flowsData;
            });
        },

        completeFilesUploadBatch: function completeFilesUploadBatch(company, batch_index) {
            var url = this.Routes.v2_update_batch_upload_completed_path(company._id, { batch_index: batch_index });
            return this.apiCreate(url);
        },

        sendUserCompanyInvite: function sendUserCompanyInvite(company_id, user_email, data){
            var url = this.Routes.v2_invite_existing_member_path(company_id,{invitee_email: user_email});
            return this.apiCreate(url, data);
        },

        canSendUserCompanyInvite: function canSendUserCompanyInvite(company_id, user_email){
            var url = this.Routes.v2_check_invite_existing_member_path(company_id);
            return this.apiFetch(url, {invitee_email: user_email});
        },

        enableCompanyTeamMember: function canSendUserCompanyInvite(companyId, teamMemberId, shouldDisableUser){
            var url = this.Routes.v2_update_company_team_member_status_path(companyId);
            return this.apiUpdate(url, { team_member_id: teamMemberId, enabled: !shouldDisableUser });
        },

        updateCompanyWithSuggestedAssets: function updateCompanyWithSuggestedAssets(company_id){
            var url = this.Routes.v2_link_suggested_assets_to_company_path(company_id);
            return this.apiUpdate(url);
        },

        resetCompanySuggestedBrandElements: function resetCompanySuggestedBrandElements(company_id){
            var url = this.Routes.v2_company_suggested_brand_elements_path(company_id);
            return this.apiDelete(url);
        }
    });
}());

