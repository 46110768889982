(function () {
    'use strict';

    class ContactFormContentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout, $window, $translate, ContactFormService,
                    ModalService, PopupMessageService, _, UiPersistenceService,
                    AnalyticsService, DeviceService, QuestionsStateService) {

            super($scope, $injector);
            this.__objectType = 'BrochureNewDirectiveController';

            this.ModalService = ModalService;
            this.PopupMessageService = PopupMessageService;
            this.UiPersistenceService = UiPersistenceService;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this._ = _;
            this.AnalyticsService = AnalyticsService;
            this.$window = $window;
            this.DeviceService = DeviceService;
            this.QuestionsStateService = QuestionsStateService;
            this.ContactFormService = ContactFormService;
            this.examplesBannerText = this.contactFormModel.isGiftCard() ? 'CONTACT_FORM.LABELS._STREAMLINE_LEADS_BANNER_TEXT_GIFT_' : 'CONTACT_FORM.LABELS._STREAMLINE_LEADS_BANNER_TEXT_' ;
            this.examplesBannerViewExamplesText = this.contactFormModel.isGiftCard() ? 'CONTACT_FORM.LABELS._STREAMLINE_LEADS_VIEW_EXAMPLE_TEXT_GIFT_' : 'CONTACT_FORM.LABELS._STREAMLINE_LEADS_VIEW_EXAMPLE_TEXT_';

        }

        $onInit() {
            this.initData();

            // watch the questions types in this contact form
            // used to mark which questions can't be added
            // because they are already in the contact form
            this.QuestionsStateService.watchModel(this.contactFormModel, this.companyModel);

            this.register(this.contactFormModel, 'success', function() {
                this.QuestionsStateService.updateQuestionsKeys();
            }.bind(this));
        }

        $onDestroy() {
            this.QuestionsStateService.unwatchModel();
        }

        initData() {
            // get the first section
            // in contact form there is only one for now
            this.contactFormSection = this.contactFormModel.file_dynamic_content.file_sections[0];
            this.sectionsList = [this.contactFormSection];
        }

        handleResetChanges() {
            this.AnalyticsService.trackClick(this, "reset_changes_contact_form_click");
            this.ContactFormService.resetContactForm(this.contactFormModel).then(() => {
                this.initData();
            });
        }

        publishChanges() {
            if (this.ContactFormService.isFormAndSettingsValid(this.contactFormModel)) {
                this.AnalyticsService.trackClick(this, "publish_changes_contact_form_click", {app_path: 'banner'});
                this.ContactFormService.publish(true, false, this.contactFormModel);
            }
        }

        getPublicUrl() {
            return this.ContactFormService.getPublicUrl(this.contactFormModel);
        }

        showExamplesModal() {
            const formType = this.contactFormModel.isGiftCard() ? 'gift card' : 'contact form';
            const analyticsEvent =  this.contactFormModel.isGiftCard() ? 'gift card examples banner main cta' : 'cf examples banner main cta';
            this.AnalyticsService.trackClick(this, analyticsEvent, {form_type: formType});
            const content_type = this.contactFormModel.isGiftCard() ? 'gift_card_examples' : 'contact_form_examples';
            this.ModalService.openTemplateExamplesModal(content_type);
        }

        shouldShowExamplesBanner() {
            return !this.ContactFormService.dismissExamplesBanner;
        }

        dismissExamplesBannerClick() {
            this.ContactFormService.dismissExamplesBanner = true;
        }
    }

    Components.ContactFormContentComponent = {
        bindings: {
            contactFormModel: '=',
            companyModel: '=',
            isClientMode: '=',
            isOwnerMode: '=',
            isPaddedInputStyle: '<'
        },
        controller: ContactFormContentController,
        name: 'contactFormContentVm',
        templateUrl: 'angular/app/modules/core/features/contact_form/contact_form_content/contact_form_content.html',
    };

}());
