(function () {
    'use strict';

    var SECTIONS = {
        SEND_CODE: 0,
        VERIFY_CODE: 1
    };

    Directives.TwoFactorAuthDirective = function TwoFactorAuthDirective() {

        // @ngInject
        function TwoFactorAuthDirectiveControllerFunc($scope, $injector, UsersManager, UserService, DatadogRUMService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'TwoFactorAuthDirectiveController';

            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this.DatadogRUMService = DatadogRUMService;
            this.section = SECTIONS.SEND_CODE;
            this.user = this.UsersManager.getCurrUser();

            // set phone number hint
            this.phoneLast4 = this.user.securityPhoneNumberLastFourDigits();

            this.mailToAddress = this.createMailToAddress();

            $scope.$watch('twoFactorAuthVm.error', function (newVal) {
                if (newVal) {
                    this.codeSubmitted = false;
                }
            }.bind(this));
        }

        var TwoFactorAuthDirectiveController = Class(Controllers.BaseController, {
            constructor: TwoFactorAuthDirectiveControllerFunc,

            sendVerificationCodeSms: function sendVerificationCodeSms() {
                this.selectedMethod = 'sms';
                this.verifyPhoneNumber();
            },

            sendVerificationCodeCall: function sendVerificationCodeCall() {
                this.selectedMethod = 'call';
                this.verifyPhoneNumber();
            },

            sendVerificationCodeEmail: function sendVerificationCodeEmail() {
                this.error = null;
                this.selectedMethod = 'email';
                this.tokenSendInProgress = true;
                const startTime = new Date().getTime();  // Record the start time for measuring execution time
                let networkInformation
                try {
                    networkInformation = {
                        rtt: navigator.connection.rtt,
                        downlink: navigator.connection.downlink,
                        effectiveType: navigator.connection.effectiveType,
                    };
                } catch (e) {
                    this.DatadogRUMService.addError(new Error('Failed to access navigator', {cause: e}), {
                        error: e
                    });
                }


                this.UsersManager.sendEmail2faVerification()
                    .then(function() {
                        this.section = SECTIONS.VERIFY_CODE;
                    }.bind(this))
                    .catch(function (res) {
                        if (res.status === 429) {
                            this.error = 'Oops! An error has occurred. Please retry later or contact us';
                        } else if (res && res.error && res.error.message) {
                            this.error = res.error.message;
                        } else if (res && res.data && res.data.error_message) {
                            this.error = res.data.error_message;
                        } else {
                            var message = 'error sending phone verification code Email';

                            const endTime = new Date().getTime();
                            const executionTime = endTime - startTime;

                            this.DatadogRUMService.addError(new Error(message), {
                                req_res: res,
                                req_time: executionTime,
                                network: networkInformation
                            });
                            this.error = 'Oops! An error has occurred during verification process. If the problem persists, please contact us';
                        }
                    }.bind(this))
                    .finally(function () {
                        this.tokenSendInProgress = false;
                    }.bind(this));
            },

            reset: function reset() {
                this.codeSubmitted = false;
                this.tokenSendInProgress = false;
                this.selectedMethod = null;
                this.error = null;
                this.section = SECTIONS.SEND_CODE;
            },

            verifyPhoneNumber: function verifyPhoneNumber() {
                this.error = null;
                this.tokenSendInProgress = true;
                const startTime = new Date().getTime();  // Record the start time for measuring execution time
                let networkInformation
                try {
                    networkInformation = {
                        rtt: navigator.connection.rtt,
                        downlink: navigator.connection.downlink,
                        effectiveType: navigator.connection.effectiveType,
                    };
                } catch (e) {
                    this.DatadogRUMService.addError(new Error('Failed to access navigator', {cause: e}), {
                        error: e
                    });
                }
                this.UsersManager.sendPhoneVerification(this.selectedMethod)
                    .then(function () {
                        this.section = SECTIONS.VERIFY_CODE;
                    }.bind(this))
                    .catch(function (res) {
                        if (res.status === 429) {
                            this.error = 'Oops! An error has occurred. Please retry later or contact us';
                        } else if (res && res.error && res.error.message) {
                            this.error = res.error.message;
                        } else if (res && res.data && res.data.error_message) {
                            this.error = res.data.error_message;
                        } else {
                            var message = 'error sending phone verification code SMS';

                            const endTime = new Date().getTime();
                            const executionTime = endTime - startTime;

                            this.DatadogRUMService.addError(new Error(message), {
                                req_res: res,
                                req_time: executionTime,
                                network: networkInformation
                            });
                            this.error = 'Oops! An error has occurred during verification process. If the problem persists, please contact us';
                        }
                    }.bind(this))
                    .finally(function () {
                        this.tokenSendInProgress = false;
                    }.bind(this));
            },

            submit: function submit() {
                this.error = null;
                this.codeSubmitted = true;
                this.userCode = (this.userCode+'').replace(/ /g, ''); // remove spaces inside the number if any
                this.onCodeSubmit({code: this.userCode, justClose: false, selectedMethod: this.selectedMethod});
            },

            close: function close() {
                this.onCodeSubmit({code: null, justClose: true, selectedMethod: this.selectedMethod});
            },

            allowCalls: function allowCalls() {
                return this.UserService.has2FAQualificationPeriodPassed(this.user);
            },

            createMailToAddress: function createMailToAddress() {
                var emailAddress = 'concierge@honeybook.com';
                var subject = '2%20Step%20Authentication%20Phone%20Number%20Request';
                var body = 'If%20you%20do%20not%20have%20a%202%20step%20authentication%20phone%20number%20set%20up%20or%20need%20to%20edit%20yours%2C%20we%27d%20be%20happy%20to%20help.%C2%A0Please%C2%A0let%20us%20know%20the%20following%3A%0A%0A1.%20The%20email%20login%20of%20your%20HoneyBook%20account%0A2.%20The%20phone%20number%20you%20wish%20to%20use%C2%A0%0A%0AThank%20you.%C2%A0'

                return emailAddress + '?subject=' + subject + '&body=' + body
            }

        });

        return {
            scope: {
                enablePhoneVerification: '=',
                enableEmailVerification: '=',
                onCodeSubmit: '&onCodeSubmit',
                error: '=',
                modal: '='
            },
            controller: TwoFactorAuthDirectiveController,
            templateUrl: 'angular/app/modules/common/ui_components/phone_verification/two_factor_auth_directive_template.html',
            controllerAs: 'twoFactorAuthVm',
            bindToController: true
        };
    };
}());

