(function () {
    "use strict";


    // @ngInject
    function TimeTrackingManagerCtor(APIService, Routes, ModelFactory, $q, _) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this._ = _;
    }


    Services.TimeTrackingManager = Class(Services.BaseModelManager, {

        constructor: TimeTrackingManagerCtor,

        createTimeTracking: function createTimeTracking(company, timeTrackingParams) {
            var url = this.Routes.v2_create_time_tracking_path(company._id);
            return this.apiCreate(url, timeTrackingParams);
        },

        updateTimeTracking: function updateTimeTracking(timeTracking) {
            var url = this.Routes.v2_update_time_tracking_path(timeTracking._id);
            return this.apiUpdate(url, timeTracking);
        },

        deleteTimeTracking: function deleteTimeTracking(timeTrackingId) {
            var url = this.Routes.v2_delete_time_tracking_path(timeTrackingId);
            return this.apiDelete(url);
        },

        fetchForCompany: function fetchForCompany(company, params) {
            var url = this.Routes.v2_get_company_time_trackings_path(company._id);
            return this.apiFetch(url, params, null, false);
        },

        fetchForProject: function fetchForProject(company, project) {
            var url = this.Routes.v2_get_project_time_trackings_path(company._id, project._id);
            return this.apiFetch(url, null);
        },

        downloadCSV: function downloadCSV(company, project, params) {
            var url = this.Routes.v2_download_time_tracking_csv_path(company._id);
            var data = {
                project_id: project && project._id,
                from_date: params && params.from_date,
                to_date: params && params.to_date,
            };
            return this.apiCreate(url, data).then(function (resp) {
                return resp.data.url;
            });
        },

        createFile: function createFile(project, timeTrackings) {
            var url = this.Routes.v2_create_file_from_time_tracking_path(project._id);
            var ids = this._.map(timeTrackings, function(t) { return t._id; });
            return this.apiCreate(url, {time_tracking_ids: ids});
        },

        createFlow: function createFlow(project, timeTrackings) {
            var url = this.Routes.v2_create_flow_from_time_tracking_path(project._id);
            var ids = this._.map(timeTrackings, function(t) { return t._id; });
            return this.apiCreate(url, {time_tracking_ids: ids});
        }
    });
}());