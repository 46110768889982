(function () {
    'use strict';

    Services.MathService = class MathService {
        
        // @ngInject
        constructor() {
            // constructor must exist
        }

        clamp(value, min, max) {
            return Math.min(Math.max(value, min), max);
        }
    };
}());
