Directives.TopNavProfileDirective = function TopNavProfileDirective() {

    // @ngInject
    function TopNavProfileDirectiveControllerFunc(
        $scope,
        $injector,
        ModalService,
        $state,
        AppStates,
        AnalyticsService,
        SetupGuideService,
        Enums,
        UsersManager,
        CompaniesManager,
        ReferralService,
        FeaturesService,
        OnboardingService,
        AuthService,
        AccountsService,
        RedirectService,
        FinanceAppService,
        PopupMessageService,
        IntercomService
    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TopNavProfileDirectiveController';

        this.$state = $state;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.ReferralService = ReferralService;
        this.AuthService = AuthService;
        this.SetupGuideService = SetupGuideService;
        this.FeaturesService = FeaturesService;
        this.Enums = Enums;
        this.ModalService = ModalService;
        this.OnboardingService = OnboardingService;
        this.AccountsService = AccountsService;
        this.FinanceAppService = FinanceAppService;
        this.RedirectService = RedirectService;
        this.bookkeeperMode = this.user.isBookkeeperMode();
        this.PopupMessageService = PopupMessageService;

        IntercomService.identify({new_integrations_page: true}, true);
        
        this.setSubscribedUserChanges();

        CompaniesManager.on(this.Enums.PubSubTriggers.companySubscribed, function companySubscribed() {
            this.setSubscribedUserChanges();
        }.bind(this));

        this.SetupGuideService.on(this.SetupGuideService.STEP_COMPLETED_EVENT_NAME, function (step) {
            this.setSubscribedUserChanges();
        }.bind(this));

        this.hasMarkedSetupGuideComplete = this.SetupGuideService.getPersistenceHomeOnboardingCard().hasMarkedComplete;

        FinanceAppService.isFinanceAppEnabled(this.user).then(function(isEligible) {
            this.showFinanceAppLink = isEligible;
        }.bind(this));

        ////////////////////////////////////////////////////////
        // TODO (Alon): Remove from here
        // Need to use existing 'hb-refer-friend' directive,
        // but we need to rafactor it so it will fit here as
        // well. For now, extracted the logic from there
        ////////////////////////////////////////////////////////
        this.showReferrAFriend = false;
        this.ReferralService.getCampaignDetails(this.user).then(function success(campaignDetails) {
            if (campaignDetails && campaignDetails.campaign_uid) {
                this.showReferrAFriend = true;
            }
        }.bind(this));

        this.onItemClick = this.onItemClick.bind(this);
        ////////////////////////////////////////////////////////
        // END
        ////////////////////////////////////////////////////////
    }

    var TopNavProfileDirectiveController = Class(Controllers.BaseController, {
        constructor: TopNavProfileDirectiveControllerFunc,

        logout: function logout() {
            if (this.UsersManager.getCurrUser().isProLogin()) {
                this.AuthService.proLogoutAsMember();
            } else {
                this.AuthService.logout();
            }
        },

        setSubscribedUserChanges: function setupUserFlyoutActions() {
            this.shouldBeAbleToUpgrade = this.user.shouldBeAbleToUpgrade();
            this.isSetupGuideLinkVisible = this.SetupGuideService.isSetupGuideActiveForSubscribedUsers();
        },

        referAFriendClick: function referAFriendClick() {
            this.$state.go(this.AppStates.root_core_navigation_invite_referrals);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.refer_a_friend_button_click,{
                campaign_uid :this.ReferralService.userCampaignUid
            });
        },

        onItemClick: function onItemClick() {
            if (angular.isFunction(this.onSelect)) {
                this.onSelect();
            }
        },

        getEditProfileLink: function getEditProfileLink() {
            return this.AppStates.root_core_navigation_settings_account;
        },

        getEditProfileAnalyticEvent: function getEditProfileAnalyticEvent() {
            //see getEditProfileLink()
            return 'account settings';
        },

        getDaysTillTrialEnds: function getDaysTillTrialEnds() {
            return this.user && this.user.nTillTrialEnds();
        },

        onUpgradeNow: function onUpgradeNow() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.action, {
                unique: 'membership page',
                source: 'user_flyout',
                trial_days_left: this.getDaysTillTrialEnds()
            });
            this.onItemClick();
            this.$state.go(this.AppStates.root_core_navigation_settings_companySettings_subscription);
        },

        startBookkeeperMode: function startBookkeeperMode(companyId) {
            if (!this.user.login_2fa_enabled) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'AVATAR_DROPDOWN._BOOKKEEPER_ALERT_',
                    function () {
                        this.UsersManager.toggleBookkeeperMode(undefined).then(function () {
                            this.RedirectService.redirect(this.user);
                        }.bind(this));
                    }.bind(this),
                    'AVATAR_DROPDOWN.ALERT._CTA_');
            } else {
                var isSelectingDifferentCompany = !companyId || companyId !== this.user.bookkeeperModeCompanyId;
                if (isSelectingDifferentCompany) {
                    if (!companyId) {
                        this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.toggle_bookkeeper_mode, {
                            source: 'user_menu',
                            mode: true
                        });
                    } else {
                        this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.change_auditee_company, {
                            source: 'user_menu'
                        });
                    }

                    this.UsersManager.toggleBookkeeperMode(true, companyId).then(function () {
                        this.RedirectService.redirect(this.user);
                    }.bind(this));
                }
            }
        },

        switchCompany: function switchCompany(companyId) {
            this.AccountsService.switchCompany(companyId);
        },

        backToAccount: function backToAccount() {
            this.AnalyticsService.trackAction(this, this.AnalyticsService.analytics_events.toggle_bookkeeper_mode, {
                source: 'user_menu',
                mode: false,
            });

            this.UsersManager.toggleBookkeeperMode(undefined).then(function () {
                this.RedirectService.redirect(this.user);
            }.bind(this));
        }

    });

    return {
        scope: {
            user: '=user',
            onSelect: '&'
        },
        templateUrl: 'angular/app/modules/core/features/top_nav/profile/top_nav_profile_template.html',
        controller: TopNavProfileDirectiveController,
        controllerAs: 'topNavProfileVm',
        bindToController: true
    };
};
