(function () {
    "use strict";

    // @ngInject
    Directives.ScrollToElement = function ScrollToElement($timeout) {
        return {
            restrict: 'A',
            scope: {
                hbScrollToElementWhenTrue: '=hbScrollToElementWhenTrue',
                hbScrollToElementTopOffset: '=?hbScrollToElementTopOffset'
            },
            link: function scrollToElementLink($scope, $element, attr) {
                var hbScrollToElementTopOffset = attr.hbScrollToElementTopOffset || 0;
                $scope.$watch('hbScrollToElementWhenTrue', function (newValue, oldValue) {
                  if(oldValue === false && newValue === true)  {
                      $timeout(function () {
                          //do it on a timeout ot give ng-show or other directive a chance to show the object first and set it's offset with a value
                          if (attr.hbScrollToElementTop === "true") {
                              $element.scrollParent().scrollTop(0, 300);
                          } else {
                              $element.scrollParent().scrollTo($element, hbScrollToElementTopOffset, 300);
                          }
                      }, 1);
                  }
                });
            }
        };
    };

}());