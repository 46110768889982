(function () {
    "use strict";

    class TextQuestionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, Enums) {
            super($scope, $injector);
            this.__objectType = 'TextQuestionController';

            this.$scope = $scope;
            this.Enums = Enums;

            this.showRequiredError = false;
        }

        focusQuestion() {
            this.$scope.$emit('childComponentFocusChanged', true);
        }

        updateQuestion() {
            this.question.updateQuestion(['placeholder']);
        }

        checkError() {
            this.showRequiredError = !this.question.isAnswerValid();
        }

        answer() {

            // trigger validation check
            this.checkError();

            // update
            return this.question.answerQuestion(['answer']);
        }
    }

    Components.TextQuestion = {
        bindings: {
            question: '=',
            isEditable: '<',
            isOwnerMode: '<',
            isAnswerable: '=',
            isStyleCustomizable: '<',
        },
        controller: TextQuestionController,
        controllerAs: 'textQuestionVm',
        name: 'hbTextQuestion',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/questions/text_question/text_question.html',
    };

}());
