/**
 * Created by Erez on 25/09/2016.
 */
Directives.GoogleLoginDirective = function GoogleLoginDirective() {

    // @ngInject
    function GoogleLoginDirectiveControllerFunc($scope, $injector, GoogleAPIService, UsersManager, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GoogleLoginDirectiveController';

        this.GoogleAPIService = GoogleAPIService;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;

        this.GoogleAPIService.renderGoogleSignInButton('googleLoginBtn', this.login.bind(this));
    }

    var GoogleLoginDirectiveController = Class(Controllers.BaseController, {
        constructor: GoogleLoginDirectiveControllerFunc,

        login: function login(idToken) {
            this.loggingIn = true;
            this.UsersManager.login_with_google(this.loginParams.user, idToken).then(

                function success(resp){
                    this.loginParams.loginSuccess(resp, true);
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.login_with_google);
                }.bind(this),

                function error(resp){
                    this.loginParams.loginError(resp);
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login_with_google, resp);
                    this.loggingIn = false;
                }.bind(this)
            );
        },
    });

    return {
        templateUrl: 'angular/app/modules/common/ui_components/google_login/google_login_directive_template.html',
        controller: GoogleLoginDirectiveController,
        controllerAs: 'googleVm',
        scope: {
            loginParams: "=loginParams",
            source: '@loginSource'
        },
        bindToController: true
    };
};