// This is the email editor that appears on: EMAIL TEMPLATES PAGE and BATCH EMAILS
Directives.EmailEditorDirective = function EmailsEditorDirective() {

    // @ngInject
    function EmailEditorDirectiveControllerFunc($scope, $injector, $q, _, $timeout, CKEDITOR, $document, UsersManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EmailEditorDirectiveController';

        this.$q = $q;
        this._ = _;
        this.subject = this.emailModel.subject;
        this.body = this.emailModel.html_body;
        this.subjectDirty = false;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.attachmentsDirty = false;
        this.CKEDITOR = CKEDITOR;
        this.editorReference = null;
        this.imageAttachments = this.emailModel.image_attachments || [];
        this.fileAttachments = this.emailModel.file_attachments || [];
        this.uploadingFilesPromises = [];
        this.config = {};

        $scope.$on('$destroy', function () {
            if (!this.emailModel.isBeingDeleted) {
                this.saveEmail();
            }
        }.bind(this));

    }

    var EmailEditorDirectiveController = Class(Controllers.BaseController, {
        constructor: EmailEditorDirectiveControllerFunc,

        setAttachmentsFlagAsDirty: function setAttachmentsFlagAsDirty() {
            this.attachmentsDirty = true;
        },

        subjectChanged: function subjectChanged() {
            this.subjectDirty = true;
        },

        saveEmail: function saveEmail() {
            if (this.editor !== null) {
                var fullHTML = this.editorReference.getData();

                if (this.editorReference.checkDirty() || this.subjectDirty || this.attachmentsDirty) {
                    this.emailModel.html_body = fullHTML;
                    this.emailModel.image_attachments = this.imageAttachments;
                    this.emailModel.file_attachments = this.fileAttachments;
                    if (!this.dontSave) {
                        this.emailModel.updateEmail(this);
                        this.editorReference.resetDirty();
                    }
                }
            }
        }
    });

    return {
        scope: {
            emailModel: '=emailModel',
            dontSave : '=dontSave',
            editorReference: '=?'
        },
        templateUrl: 'angular/app/modules/core/features/emails/editor/email_editor_directive_template.html',
        controller: EmailEditorDirectiveController,
        controllerAs: 'emailEditorDirectiveVm',
        bindToController: true
    };
};
