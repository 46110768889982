(function () {
    "use strict";

    // @ngInject
    function ServiceAutoCompleteControllerCtor($scope, $injector, WorkspaceFilesManager, $q, AnalyticsService, DeviceService, $filter, _, UIUtils, UsersManager, DatadogRUMService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ServiceAutoCompleteController';
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.$q = $q;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.DatadogRUMService = DatadogRUMService;
        this.$filter = $filter;

        this.UsersManager = UsersManager;

        this.debouncedLoadItems = _.debounce(this.loadItems, 500);
        this.UIUtils = UIUtils;
    }

    var ServiceAutoCompleteController = Class(Controllers.BaseController, {
        constructor: ServiceAutoCompleteControllerCtor,

        $onChanges: function $onChanges() {
            this.loadingItems = true;
            this.debouncedLoadItems();
        },

        onClearSearchClicked: function onClearSearchClicked() {
            this.searchTerm = null;
            this.loadItems();
        },

        onCreateItemClicked: function onCreateItemClicked() {
            if (this.DeviceService.device.isMobileSize) {
                this.onItemCreated({name: this.searchTerm});
            }

            this.close();
        },

        stripHTMLTagsFromItemDescription: function stripHTMLTagsFromItemDescription(serviceItem){
            if(this.UIUtils.isRichText(serviceItem.description)){
                serviceItem.description = this.UIUtils.stripHTMLTags(serviceItem.description)
            }
            return serviceItem;
        },

        loadItems: function _loadItems() {
            this.searchFiltered = !!this.searchTerm;

            var boundStripHTML = this.stripHTMLTagsFromItemDescription.bind(this);

            this.WorkspaceFilesManager.getItemsList(this.UsersManager.getCurrUser()._id, this.searchTerm)
                .then(function (res){
                    this.services = res.data.services.map(boundStripHTML);
                    this.packages = this.$filter('orderBy')(res.data.packages, 'name');
                    if (this.searchFiltered) {
                        this.filteredItems = this.services.concat(this.packages);
                    } else if (this.services.length >= 10) {
                        var separator = 5;
                        this.frequentItems = this.services.slice(0, separator);
                        this.services = this.services.slice(separator, -1);
                    } else {
                        this.frequentItems = this.services;
                    }

                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.services_auto_complete_loaded, {
                        filtered: this.searchFiltered,
                        frequent: this.frequentItems.length,
                        services: this.services.length,
                        packages: this.packages.length
                    });
                }.bind(this))
                .catch(function (err){
                    this.DatadogRUMService.addError(new Error('Error on legacy load item bank', {cause: err}),
                        {
                        user_id: this.UsersManager.getCurrUser()._id,
                        error: err
                        });
                })
                .finally(function final() {
                    this.loadingItems = false;
                }.bind(this));

        },

        itemSelected: function itemSelected(item) {
            this.onItemSelected(item);
            this.close();
        }
    });

    Components.ServiceAutoComplete = {
        bindings: {
            searchTerm: '<',
            isInPackage: '<',
            close: '&',
            onItemSelected: '&',
            onItemCreated: '&'
        },
        controller: ServiceAutoCompleteController,
        controllerAs: 'serviceAutoCompleteVm',
        name: 'hbServiceAutoComplete',
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/auto_complete/service_auto_complete_directive_template.html'
    };

}());