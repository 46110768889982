(function () {
    'use strict';

    class ProjectFormComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout, _, moment, UsersManager, CompaniesManager, EventService, TimezoneService,
                    UiPersistenceService, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'ProjectForm';
            this.$timeout = $timeout;
            this.moment = moment;
            this._ = _;
            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.EventService = EventService;
            this.TimezoneService = TimezoneService;
            this.UiPersistenceService = UiPersistenceService;
            this.AnalyticsService = AnalyticsService;
            this.currUser = UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
            this.shouldShowDateConflicts = this.company.isShowDateConflictsAutomationOn();
            this.creatingProject = false;
            this.shouldHideAddParticipantsModal = true;
            this.endDateVisible = false;
            this.onDateTimeChange = false;
            this.errorMessage = '';
            this.analyticsEventsSource = 'create new project modal';
            this.analyticsEventTypeMap = {
                event_date: this.AnalyticsService.analytics_events.edit_project_start_date,
                event_time_start: this.AnalyticsService.analytics_events.edit_project_start_time,
                event_end_date: this.AnalyticsService.analytics_events.edit_project_end_date,
                event_time_end: this.AnalyticsService.analytics_events.edit_project_end_time
            };
        }

        $onInit() {
            this.projectTypes = this.company.sortedProjectTypes();
            this.setInitialProjectType();
            this.leadSources = this.initLeadSourceList();
            this.timezones = this.TimezoneService.getTimezones();
            this.setInitialTimezone();
            this.projectFormBinding = this;
        }

        update(type, value) {
            this.project[type] = value;
        }

        initLeadSourceList() {
            const leadSources = this.company.sortedLeadSources();
            if (leadSources.length === 0) {
                const leadSourceEnumList = angular.copy(this.company.isNonEventType() ? this.EventService.nonEventLeadSourceEnum : this.EventService.eventLeadSourceEnum);
                return this.EventService.getTranslatedLeadSourcesEnum(leadSourceEnumList);
            }
            return leadSources;
        }

        setInitialProjectType() {
            this.projectType = this.projectTypes[0];
            this.update('event_type', this.projectType.label);
        }

        setProjectType(projectType) {
            this.projectType = projectType;
            this.update('event_type', projectType.label);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_type, {source: this.analyticsEventsSource});
        }

        sortedList(item, list) {
            return [item,  ...list].sort((a, b) => {
                return a.label < b.label ? -1 : b.label < a.label ? 1 : 0;
            });
        }

        addProjectType(projectType) {
            let tag = {
                label: projectType,
                editable: true,
                open_text: false
            };

            this.projectTypes = this.sortedList(tag, this.projectTypes);

            return this.CompaniesManager.updateProjectTypes(this.company, this.projectTypes)
                .then((list) => {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_project_type, {source: this.analyticsEventsSource, label: projectType});
                    return list.data.project_types.filter((i) => {
                        return i.label === tag.label;
                    });
                });
        }

        setLeadSource(leadSource) {
            this.leadSource = leadSource;
            this.update('lead_source', leadSource.label);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_lead_source, {source: this.analyticsEventsSource});
            this.removeProTip({leadSource: leadSource.label});
        }

        addLeadSource(leadSource) {
            var tag = {
                label: leadSource,
                editable: true,
                open_text: false
            };

            this.leadSources = this.sortedList(tag, this.leadSources);

            return this.CompaniesManager.updateLeadSources(this.company, this.leadSources)
                .then((list) => {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_project_lead_source, {source: this.analyticsEventsSource});
                    return list.data.lead_sources.filter((i) => {
                        return i.label === tag.label;
                    });
                });
        }

        setInitialTimezone() {
            this.timezone = this.project && this.project.event_timezone ? this.project.event_timezone : this.getDefaultTimezone();
            const translatedTimezone = this.TimezoneService.translateToServer(this.timezone);
            this.update('event_timezone', translatedTimezone);
        }

        getDefaultTimezone() {
            return this.TimezoneService.translateFromServer(this.CompaniesManager.getCurrCompany().default_timezone) || this.timezones[0];
        }

        setTimezone(timezone) {
            this.timezone = timezone;
            const translatedTimezone = this.TimezoneService.translateToServer(timezone);
            this.update('event_timezone', translatedTimezone);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_timezone, {source: this.analyticsEventsSource});
        }

        onDateTimeSelected(change) {
            this._.each(change, (value, type) => {
                this.update(type, value);
                this.AnalyticsService.track(this, this.analyticsEventTypeMap[type], {source: this.analyticsEventsSource});
            });

        }
    }

    Components.ProjectForm = {
        bindings: {
            selectedDate: '=',
            projectFormBinding: '=',
            project: '=',
            modalInstance: '=?',
            removeProTip: '&'
        },
        controller: ProjectFormComponent,
        name: 'hbProjectForm',
        templateUrl: 'angular/app/modules/core/features/project/project_form.html'
    };

}());
