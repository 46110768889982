(function () {
    'use strict';
    class MeetingSearchResults extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'MeetingSearchResults';
        }
    }

    Components.MeetingSearchResults = {
        bindings: {
            showResults: '<',
            list: '<',
            onSelect: '&',
            onCreateNew: '&'

        },
        controller: MeetingSearchResults,
        name: 'MeetingSearchResults',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_search_results/meeting_search_results.html'
    };
}());