Components.HbBlackLivesMatter = {
    // @ngInject
    controller: function BlackLivesMatterController() {

    },
    name: 'blmLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/black-lives-matter/black-lives-matter.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
