(function () {
    "use strict";

    // @ngInject
    function BrochureReviewSectionDirectiveControllerFunc($injector, $scope) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrochureReviewSectionDirectiveController';

        this._init();

        this.register(this.fileModel, 'success', function() {
            this._onSectionChange();
        }.bind(this));
    }

    var BrochureReviewSectionDirectiveController = Class(Controllers.BaseController, {
        constructor: BrochureReviewSectionDirectiveControllerFunc,

        _init: function _init() {
            this.block = this.section.file_blocks[0];
            this.proposal = this.block.proposal;
            this.isSelectionEmpty = this.proposal.isEmpty();
        },

        _onSectionChange: function _onSectionChange() {
            this._init();
        },

    });

    Components.BrochureReviewSection = {
        bindings: {
            section: '<',
            companyModel: '<',
            fileModel: '=?',
            isClientMode: '<',
            isOwnerMode: '<',
            isPreviewMode: '<',
            isEditable: '<',
        },
        templateUrl: 'angular/app/modules/core/features/brochure/brochure_new/brochure_review_section/brochure_review_section_directive.html',
        controller: BrochureReviewSectionDirectiveController,
        controllerAs: 'brochureReviewSectionVm',
        name: 'hbBrochureReviewSection'
    };

}());


