/**
 * Created by dvircohen on 13/07/2017.
 */
(function () {
    'use strict';

    // @ngInject
    function ProposalTemplateEmptyStateControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, $state, AppStates) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProposalTemplateEmptyStateController';
        AnalyticsService.trackPageView(this, 'ProposalTemplateEmptyStateView');

        this.TemplatesViewService = TemplatesViewService;
        this.$state = $state;
        this.AppStates = AppStates;
    }

    Controllers.ProposalTemplateEmptyStateController = Class(Controllers.BaseController, {
        constructor: ProposalTemplateEmptyStateControllerCtor
    });
}());

