
Directives.PrintDirective = function PrintDirective() {

    // @ngInject
    function PrintDirectiveControllerFunc($scope, PrintService, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PrintDirectiveController';
    }

    var PrintDirectiveController = Class(Controllers.BaseController, {
        constructor: PrintDirectiveControllerFunc
    });



    return {
        controller: PrintDirectiveController,
        bindToController: true
    };
};
