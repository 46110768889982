// @ngInject
Filters.MakeRangeFilter  = function MakeRangeFilter($filter) {
    // use for creating range in ng-repeat
    return function(input, total) {
        total = parseInt(total);
        for (var i=0; i<total; i++){
            input.push(i);
        }
        return input;
    };
};
