(function () {
    "use strict";

    // need to pass all injections from child controller.
    // @ngInject
    function AddTeamMemberToWorkspaceControllerCtor($, $scope, $modalInstance, $injector, userList, workspace, canSelectMultiple, isNewWorkspace,
                                                    UsersManager, CompaniesManager, WorkspacesManager,
                                                    AnalyticsService, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $, userList, canSelectMultiple);
        this.__objectType = 'AddTeamMemberToWorkspaceController';

        var self = this;

        this.$ = $;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.WorkspacesManager = WorkspacesManager;
        this.PopupMessageService = PopupMessageService;
        this.company = CompaniesManager.getCurrCompany();

        this.currUser = UsersManager.getCurrUser();
        this.workspace = workspace;
        this.isNewWorkspace = isNewWorkspace;

        this.userList = userList || [];
        this.userList = this.userList.filter(function (user) {
            if (user._id === self.currUser._id) {
                return false;
            }
            return true;
        });

        this.workspaceTeamMembers = this.getCurrentWorkspaceTeamMembers();
        this.workspaceTeamMembers.forEach(function (user) {
            this.setSelection(user);
        }.bind(this));

        this.actionInProgress = false;
    };

    Controllers.AddTeamMemberToWorkspaceController = Class(Controllers.SelectTeamMemberController, {
        constructor: AddTeamMemberToWorkspaceControllerCtor,

        headerTitle: function headerTitle() {
            return 'WORKSPACE.ADD_MEMBER._ADD_TEAM_MEMBER_DIALOG_TITLE_';
        },

        hasSelection: function hasSelection() {
            return (this.getSelectedUsers().length > 0 || this.workspaceTeamMembers.length > 0);
        },

        headerSubtitle: function headerSubtitle() {
            if (this.isNewWorkspace) {
                return 'WORKSPACE.ADD_MEMBER._ADD_TEAM_MEMBER_DIALOG_SUBTITLE_';
            }
            return '';
        },

        userNotAddedString: function userNotAddedString(){
            return 'WORKSPACE.ASSIGN_TEAM_MEMBERS._USER_NOT_ADDED_';
        },

        mapUserIds: function mapUserIds(userArray) {
            return userArray.map(function(user) {
                return user._id;
            });
        },

        submit: function submit(selectedUsers) {
            this.actionInProgress = true;
            var selectedUsersIDs = this.mapUserIds(selectedUsers);
            var currentMembersIDs = this.mapUserIds(this.workspaceTeamMembers);

            var removedUsersIDs = currentMembersIDs.filter(function (userId) {
                return (this.$.inArray(userId,selectedUsersIDs) === -1);
            }.bind(this));


            var addedUsersIDs = selectedUsersIDs.filter(function(userId) {
                return (this.$.inArray(userId,currentMembersIDs) === -1);
            }.bind(this));

            if (addedUsersIDs.length > 0 || removedUsersIDs.length > 0) {
                this._updateUsers(addedUsersIDs, removedUsersIDs);
            } else {
                this.closeModal();
            }
        },

        _updateUsers: function _updateUsers(addedUserIDs, removedUserIDs) {
            var self = this;

            this.WorkspacesManager.updateTeamMembersInWorkspace(this.workspace, addedUserIDs, removedUserIDs).then(
                function success(resp) {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.updated_workspace_team_members,
                        {
                            company_id: self.company._id,
                            added_user_ids: addedUserIDs,
                            removed_user_ids: removedUserIDs
                        });
                    self.closeModal();
                },
                function error(resp) {
                    self.actionInProgress = false;
                    self.lastError = resp.data.error_message || resp.data;
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.updated_workspace_team_members_error,
                        {
                            company_id: self.company._id,
                            added_user_ids: addedUserIDs,
                            removed_user_ids: removedUserIDs,
                            error: self.lastError
                        });
                    self.WorkspacesManager.getWorkspaceMembers(self.workspace).then(
                        function success (res) {
                            self.workspaceTeamMembers = self.getCurrentWorkspaceTeamMembers();
                        }
                    );
                }
            );
        },

        getCurrentWorkspaceTeamMembers: function getCurrentWorkspaceTeamMembers() {
            var workspaceTeamMembers = [];
            var length = this.workspace.members.length;
            this.userList.forEach(function (user) {
                for (var i=0; i<length; i++) {
                    if (user._id === this.workspace.members[i]._id) {
                        workspaceTeamMembers.push(user);
                        return;
                    }
                }
            }.bind(this));
            return workspaceTeamMembers;
        }


    });
}());

