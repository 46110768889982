(function () {
    "use strict";

    // @ngInject
    Components.ContactFormEmptyState = {
        bindings: {
            isGiftCard: '<'
        },
        controller: function ContactFormEmptyStateController($scope, ModalService) {
            this.hasBeenClicked = false;

            this.openVideoModal = (type) => {
                ModalService.openContactFormExplantionVideoModal(type)
                .then( () => this.hasBeenClicked = true );
            };

            const giftCardConfig = {
                image: 'giftcard/gift-card_2x',
                title: 'Increase your cash flow',
                text: 'A new way to offer services and generate immediate income.',
                primaryCtaText: 'Check out the video',
                primaryCtaCallback: () => this.openVideoModal('giftCard'),
                primaryCtaAnalyticsData: { eventName: 'gift card demo video'},
                ctaClass: this.hasBeenClicked ? 'cf-empty__button-link' : '',
                footer: 'Available on the Desktop app only'
            };

            const contactFormConfig = {
                image: 'empty_states/pipeline/inquiry',
                title: 'Capture your leads',
                text: this.hasBeenClicked ? 'Visit this page on your laptop to create a contact form.' : 'Contact forms help you automate your process so you never miss a thing.' ,
                primaryCtaText: this.hasBeenClicked ? 'Watch the video again' : 'Check out the video',
                primaryCtaCallback: () => this.openVideoModal('contactForm'),
                primaryCtaAnalyticsData: { eventName: 'contact form demo video'},
                ctaClass: this.hasBeenClicked ? 'cf-empty__button-link' : ''
            }

            this.currEmptyState = this.isGiftCard ? giftCardConfig : contactFormConfig

        },
        name: 'hbAccountSetupTilePrompt',
        templateUrl: 'angular/app/modules/core/features/contact_form/empty_state/contact_form_empty_state.html'

    }

})();