(function () {
    'use strict';
    Controllers.ScheduleMeetingModalController = class ScheduleMeetingModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $modalInstance, $state, _, options, moment, UsersManager, EventsManager, CompaniesManager,
                    PopupMessageService, AnalyticsService, EventService, UiPersistenceService,
                    ModalService, DeviceService, CalendarService, $q, AppStates, UserService, $window) {
            super($scope, $injector);
            this.__objectType = 'ScheduleMeetingModalController';
            this.modalInstance = $modalInstance;
            this.translate = $translate;
            this._ = _;
            this.moment = moment;

            this.AppStates = AppStates;
            this.UsersManager = UsersManager;
            this.EventsManager = EventsManager;
            this.CompaniesManager = CompaniesManager;
            this.EventService = EventService;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.UiPersistenceService = UiPersistenceService;
            this.ModalService = ModalService;
            this.CalendarService = CalendarService;
            this.UserService = UserService;
            this.$q = $q;
            this.$state = $state;
            this.$window = $window;

            this.ANALYTICS_EVENT_SOURCE = `${this.AnalyticsService.analytics_events.schedule_meeting} modal`;
            this.SERVER_DATE_FORMAT = 'YYYY-MM-DD';
            this.TIME_FORMAT = 'h:mm A';

            // modal params
            this.options = options;

            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.isEditMode = this.options ? !this.options.isCreateMeeting : false;

            this.googleCalendarProvider = null;
            this.nylasCalendarProvider = null;
            this.zoomAuthProvider = null;

            // existing meeting
            if(this.isEditMode && this.options.calendarItemModel) {
                this.existingMeetingParams = this.options.calendarItemModel;
            }
            this.currUser = this.UsersManager.getCurrUser();
            this.meetingFormBinding = {};
            this.errors = {};

            if ((this.options && this.options.type) || this.existingMeetingParams) {
              this.AnalyticsService.trackLoad(this, this.ANALYTICS_EVENT_SOURCE, {session_type: this.options.type || this.existingMeetingParams.type});
            }
            else {
              this.AnalyticsService.trackLoad(this, this.ANALYTICS_EVENT_SOURCE);
            }

            this.initTitle();
            this.initButtonLabel();

            var promiseProvider = this.UsersManager.getCalendarProvider(this.currUser)
                .then(function (response) {
                    if (response.data && response.data.calendar_provider && response.data.calendar_provider._type === 'GoogleCalendarProvider') {
                        this.googleCalendarProvider = response.data.calendar_provider;
                    }
                }.bind(this));

            var promiseZoomProvider = this.UsersManager.getZoomAuthProvider(this.currUser)
                .then(function (response) {
                    this.zoomAuthProvider = response.data.zoom_provider;
                }.bind(this));

        }

        showCreateProject() {
            this.ModalService.openCreateProjectModal({doNotGoToNewWorkspace: true}).then((resp) => {
                const selectedWorkspace = {
                    _id: resp._id,
                    properties: {
                        event_id: resp.event._id,
                        event_name: resp.event.event_name
                    }
                };
                const project = this.EventsManager.newEvent();
                this.meetingFormBinding.setConnectedProject(selectedWorkspace);
                project.mixinFrom(resp.event, null, true, true);
                const eventsMasterCollection = this.EventsManager.getEventsMasterCollection();
                eventsMasterCollection.add(project);
            });

        }

        openSyncModal(hasPendingChanges, type) {
            if (hasPendingChanges) {

                // show confirm popup
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.none,
                    'You will be redirected to sign in and complete this integration. Any unsaved changes will be lost. What would you like to do?',
                    'FREQUENT_BUTTONS._CONTINUE_',
                    'FREQUENT_BUTTONS._CANCEL_',
                    'Exit without saving?').then(function() {
                    this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.discard_meeting_changes);
                    this.modalInstance.dismiss();
                    if (type === 'zoom') {
                        this.UserService.connectToZoomAccount(this.$window.location.href);
                    } else {
                        this.$state.go(this.AppStates.root_core_navigation_calendar, {integrateFromScheduling: true});
                    }
                }.bind(this)).catch(function() {
                    this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.discard_meeting_changes);
                }.bind(this));
            } else {
                this.modalInstance.dismiss();
                if (type === 'zoom') {
                    this.UserService.connectToZoomAccount(this.$window.location.href);
                } else {
                    this.$state.go(this.AppStates.root_core_navigation_calendar, {integrateFromScheduling: true});
                }
            }
        }

        initButtonLabel() {
            this.buttonLabel = this.isEditMode ? 'CALENDAR.SCHEDULE_MEETING_MODAL._UPDATE_' : 'CALENDAR.SCHEDULE_MEETING_MODAL._CREATE_';
        }

        initTitle() {
            this.title = this.isEditMode ? 'CALENDAR.SCHEDULE_MEETING_MODAL._TITLE_UPDATE_' : 'CALENDAR.SCHEDULE_MEETING_MODAL._TITLE_';
        }

        submitForm() {
            if(this.isEditMode) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.update_meeting,
                    {source: this.ANALYTICS_EVENT_SOURCE, session_type: this.meetingFormBinding.meetingType ? this.meetingFormBinding.meetingType.key : '', video_conference_type: this.meetingFormBinding.videoConferenceType ? this.meetingFormBinding.videoConferenceType.key: ''});
                this.updateMeeting();
            }
            else {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.schedule_meeting,
                    {source: this.ANALYTICS_EVENT_SOURCE, session_type: this.meetingFormBinding.meetingType ? this.meetingFormBinding.meetingType.key : '', video_conference_type: this.meetingFormBinding.videoConferenceType ? this.meetingFormBinding.videoConferenceType.key: ''});
                this.createMeeting();
            }

        }

        close() {
            if (this.zoomMeetingId && !this.isEditMode) {
                // Edge case we do not handle - member edits an existing calendar item, adds a Zoom meeting and closes the modal without saving. Zoom temp meeting will remain on member's zoom account
                // Also, calendarItemId should always be null (cause we are not in edit mode) but in case it isn't...
                var calendarItemId = (this.options.calendarItemModel && this.options.calendarItemModel._id) ? this.options.calendarItemModel._id : null;
                this.currUser.cancelZoomVideoConferencingLink(this.currUser, this.zoomMeetingId, calendarItemId);
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.zoom_cancel_on_close_meeting_modal, {calendar_item_id: calendarItemId, zoom_meeting_id: this.zoomMeetingId });
            }

            this.AnalyticsService.trackClick(this, ['close', this.ANALYTICS_EVENT_SOURCE].join(' '), {source: this.ANALYTICS_EVENT_SOURCE, session_type: this.existingMeetingParams ? this.existingMeetingParams.type : this.options.type});
            this.modalInstance.dismiss();
            
        }

        createMeeting() {
            if(!this.creatingCalendarItem) {
                this.meetingFormBinding.meetingForm.$setSubmitted();

                if(this._isValidMeeting()) {
                    this.creatingCalendarItem = true;
                    const meetingParams = this._prepareMeetingParams();

                    const defer = this.$q.defer();

                    if (this.CalendarService.hasInvitees(meetingParams) ||
                        this.CalendarService.isConnectedToProject(meetingParams)) {
                        this.showOverlay = true;
                        this.PopupMessageService.showConfirmPromise(
                            this.PopupMessageService.severityTypes.none,
                            'CALENDAR.NOTIFY_INVITEES_POPUP._SCHEDULE_BODY_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._REVIEW_EMAIL_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._SCHEDULE_CTA_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._TITLE_', true, 'static', true).then(function() {
                            defer.resolve(true);
                        }).catch(function() {
                            defer.resolve(false);
                        });
                    } else {
                        defer.resolve(false);
                    }

                    defer.promise.then((notify) => {
                        meetingParams.notify = notify;
                        return this.UsersManager.createCalendarsItem(this.currUser, meetingParams);
                    }).then((resp) => {
                        this.AnalyticsService.trackSuccess(this, this.ANALYTICS_EVENT_SOURCE);
                        this.modalInstance.close(resp.data);
                        this.creatingCalendarItem = false;
                    }).catch((error) => {
                        let errorMsg = 'REPORTS.CALENDAR.MEETING_MODAL.ERRORS._COULD_NOT_CREATE_THIS_MEETING_';
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.create_meeting_error, errorMsg, {reason: errorMsg});
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, errorMsg);
                        this.creatingCalendarItem = false;
                    });
                }
                else {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.create_meeting_error, null,
                        {reason: 'missing ' + this._getValidationErrors() });
                }
            }
        }

        updateMeeting() {
            if(!this.updatingCalendarItem) {
                this.meetingFormBinding.meetingForm.$setSubmitted();

                if(this._isValidMeeting()) {
                    this.updatingCalendarItem = true;
                    const meetingParams = this._prepareMeetingParams();

                    const defer = this.$q.defer();

                    if ((this._isMeetingDateChanged() &&
                            (this.CalendarService.hasInvitees(this.existingMeetingParams || this.options) ||
                            this.CalendarService.isConnectedToProject(this.existingMeetingParams || this.options))) ||
                        (meetingParams.workspace_id && !this.existingMeetingParams) || (meetingParams.workspace_id !== this.existingMeetingParams.workspace_id)) {
                        this.showOverlay = true;
                        this.PopupMessageService.showConfirmPromise(
                            this.PopupMessageService.severityTypes.none,
                            'CALENDAR.NOTIFY_INVITEES_POPUP._RESCHEDULE_BODY_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._REVIEW_EMAIL_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._RESCHEDULE_CTA_',
                            'CALENDAR.NOTIFY_INVITEES_POPUP._TITLE_', true, null, true).then(function() {
                            defer.resolve(true);
                        }).catch(function() {
                            defer.resolve(false);
                        });
                    } else {
                        defer.resolve(false);
                    }

                    defer.promise.then((notify) => {
                        return this.UsersManager.updateCalendarItem(this.currUser, this.options.calendarItemModel._id, meetingParams, notify);
                    }).then((resp) => {
                        this.AnalyticsService.trackSuccess(this, this.ANALYTICS_EVENT_SOURCE);
                        this.updatingCalendarItem = false;
                        this.modalInstance.close(resp.data);
                    }).catch((error) =>  {
                        this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.update_meeting_error, {reason: error});
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.MEETING_MODAL.ERRORS._COULD_NOT_FIND_THIS_MEETING_');
                        this.updatingCalendarItem = false;
                    });
                } else {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.update_meeting_error , null,
                        {reason: 'missing ' + this._getValidationErrors() });
                }
            }
        }

        _formatDatesForServer() {
            this.meetingStartDate = this.moment(this.meetingFormBinding.meetingStartDate).format(this.SERVER_DATE_FORMAT);
            this.meetingEndDate = this.moment(this.meetingFormBinding.meetingEndDate).format(this.SERVER_DATE_FORMAT);
            // time range
            if(this.meetingFormBinding.meetingStartTime && this.meetingFormBinding.meetingEndTime) {
                this.meetingStartTime = this._formatTime(this.meetingFormBinding.meetingStartDate, this.meetingFormBinding.meetingStartTime);
                this.meetingEndTime = this._formatTime(this.meetingFormBinding.meetingEndDate, this.meetingFormBinding.meetingEndTime);
            } else if(!this.meetingFormBinding.isAllDay){
                // if missing start or end time - make it an all day meeting
                this.meetingFormBinding.isAllDay = true;
            }
        }

        _prepareMeetingParams() {
            const workspace = this.meetingFormBinding.selectedWorkspace;
            this._formatDatesForServer();

            return {
                title: this.meetingFormBinding.meetingTitle,
                // timezone: this.meetingFormBinding.timezone,
                date_start: this.meetingStartDate,
                date_time_start: this.meetingStartTime,
                date_time_end: this.meetingEndTime,
                date_end: this.meetingEndDate,
                // mark_as_busy: this.meetingFormBinding.shouldMarkAsBusy,
                all_day: this.meetingFormBinding.isAllDay,
                type: this.meetingFormBinding.meetingType.key,
                location: this.meetingFormBinding.meetingLocation,
                workspace_id: workspace && (workspace.properties.event_name !== "No project") ? workspace._id : null,
                event_id: (workspace && workspace.properties.event_id ? workspace.properties.event_id : null),
                description: this.meetingFormBinding.meetingDescription,
                session_config_id: this.options ? this.options.session_config_id : null,
                video_conference_type: this.meetingFormBinding.videoConferenceType ? this.meetingFormBinding.videoConferenceType.key : null,
                video_meeting_link: this.meetingFormBinding.videoMeetingLink,
                phone_call_number: this.meetingFormBinding.phoneCallNumber,
                video_meeting_id: this.zoomMeetingId
            };
        }

        _isValidMeeting() {
            const is_valid_date = this._validateRequiredField('date', this.meetingFormBinding.meetingStartDate);
            const is_valid_title = this._validateRequiredField('title', this.meetingFormBinding.meetingTitle);
            const is_valid_type = this._validateRequiredField('type', this.meetingFormBinding.meetingType);
            const is_valid_google_conference_type = this._validateGoogleConference();
            const is_valid_zoom_conference_type = this._validateZoomConference();
            let is_valid_project = false;

            const errors = Object.keys(this.errors).filter((errorType) => {
                return this.errors[errorType];
            });

            if (this.existingMeetingParams) {
                if(this.existingMeetingParams.event_name === null) {
                    return is_valid_date && is_valid_title && is_valid_type && is_valid_google_conference_type && is_valid_zoom_conference_type;
                }
            }

            if(!errors.length || (errors.length === 1 && this.errors['project'])) {
                is_valid_project = this._validateProject();
            }

            return is_valid_project && is_valid_google_conference_type && is_valid_date && is_valid_title && is_valid_type && is_valid_zoom_conference_type;
        }

        _validateGoogleConference() {
            const isGoogleVideoMeeting = this.meetingFormBinding.videoConferenceType && this.meetingFormBinding.videoConferenceType.key === 'google' && this.meetingFormBinding.meetingType.key === 'video_call' && this.meetingFormBinding.meetingType && this.meetingFormBinding;
            this.errors['videoConferenceType'] =
                this.meetingFormBinding.meetingType.key === 'video_call' &&
                (!this.meetingFormBinding.videoConferenceType ||
                    (this.meetingFormBinding.videoConferenceType.key === 'google' &&
                !this.googleCalendarProvider));
            this.errors['googleNotConnected'] = isGoogleVideoMeeting && !this.googleCalendarProvider;

            return !this.errors['videoConferenceType'];
        }

        _validateZoomConference() {
            this.errors['videoConferenceLink'] =
                this.meetingFormBinding.meetingType.key === 'video_call' && this.meetingFormBinding.videoConferenceType &&
                (this.meetingFormBinding.videoConferenceType.key === 'zoom' && (!this.zoomAuthProvider || (!this.meetingFormBinding.zoomMeetingDetails && !this.meetingFormBinding.videoMeetingLink)));

            return !this.errors['videoConferenceLink'];
        }

        _validateProject() {
            this.errors['project'] = !this.meetingFormBinding.selectedWorkspace;
            if(!this.meetingFormBinding.selectedWorkspace) {
                if(this.isMobile) {
                    this._openMwebConnectProjectModal();
                }
                else {
                    this._openConnectProjectModal();
                }
            }
            return !!this.meetingFormBinding.selectedWorkspace;
        }

        _validateRequiredField(fieldName, fieldValue) {
            this.errors[fieldName] = !fieldValue;
            return !!fieldValue;
        }

        _getValidationErrors() {
           const errorFields =   _.reduce(this.errors, (result, hasError, fieldName) => {
                (result[hasError] || (result[hasError] = [])).push(fieldName);
                return result;
            }, {})[true];
           return errorFields && errorFields.length > 0 ? errorFields.join(',') : null;
        }

        _openConnectProjectModal() {
            this.showOverlay = true;
            const options = {
                hasActiveProjects: this.meetingFormBinding.hasActiveProjects
            };
            this.ModalService.openConnectProjectModal(options).then((resp) => {
                const {selectedWorkspace, shouldCreateNewProject} = resp;

                if(selectedWorkspace) {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connect_project_to_meeting,
                        {type: selectedWorkspace.properties.event_name === 'No project' ? 'No project' : 'existing project'});
                    this.meetingFormBinding.setConnectedProject(selectedWorkspace);
                    this.showOverlay = false;

                    if(selectedWorkspace.properties.event_name) {
                        this.submitForm();
                    }
                }
                else if(shouldCreateNewProject) {
                    this.showCreateProject();
                    this.showOverlay = false;
                }
            }).catch((error) => {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connect_project_to_meeting, error);
                this.showOverlay = false;
            });
        }

        _openMwebConnectProjectModal() {
            const options = {
                hasActiveProjects: this.meetingFormBinding.hasActiveProjects
            };
            this.ModalService.openMwebConnectProjectModal(options).then((resp) => {
                if(resp.selectedWorkspace) {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.connect_project_to_meeting,
                        {type: resp.selectedWorkspace.properties.event_name === 'No project' ? 'No project' : 'existing project'});
                    this.meetingFormBinding.setConnectedProject(resp.selectedWorkspace);
                }
                else if(resp.shouldCreateNewProject) {
                    this.showCreateProject();
                }
            }).catch((error) => {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.connect_project_to_meeting, error);
            });
        }

        _getProjectAnalyticsProps(project) {
            return this._.pick(project.dataOnly(),
                'event_name', 'event_type', 'event_timezone', 'event_date', 'event_end_date',
                'event_time_start', 'event_time_end', 'lead_source');
        }

        _formatTime(date, time) {
            time = this.moment(time, this.TIME_FORMAT);
            return this.moment(date).set({
                'hour': time.get('hour'),
                'minute': time.get('minute'),
                'second': time.get('second')
            }).toString();
        }

        _isMeetingDateChanged() {
            return this.meetingFormBinding.meetingForm.meetingStartDate.$dirty ||
                (!!this.meetingFormBinding.meetingForm.meetingStartTime && this.meetingFormBinding.meetingForm.meetingStartTime.$dirty) ||
                this.meetingFormBinding.meetingForm.meetingEndDate.$dirty ||
                (!!this.meetingFormBinding.meetingForm.meetingEndTime && this.meetingFormBinding.meetingForm.meetingEndTime.$dirty) ||
                this.meetingFormBinding.meetingForm.isAllDay.$dirty;
        }
    };
}());
