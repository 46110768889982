
// @ngInject
Models.FeaturePlanModel = function FeaturePlanModel(BaseModel){
    return Class(BaseModel, {
        constructor: () => {
            this.constructor.$super.call(this);
            this.__className = 'FeaturePlanModel';
        },
    });
};
