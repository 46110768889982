/**
 * The hb-progress-bar component
 *
 * Shows a progress bar and handles its progress
 *
 * parameters:
 *
 */


(function () {
    'use strict';

    class ProgressBarController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $) {
            super($scope, $injector);
            this.$ = $;
            this.__objectType = 'ProgressBarController';
            this.DEFAULT_MAX = "100";
            this.DEFAULT_VALUE = "0";
        }
    }

    Components.ProgressBar = {
        bindings: {
            value: '=?',
            max: '=?',
        },
        controller: ProgressBarController,
        // link: function(a,b,c) {
        //
        // },
        name : 'hbProgressBar',
        templateUrl: 'angular/app/modules/common/ui_components/progress_bar/progress_bar.html',
    };

}());
