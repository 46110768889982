(function () {
    "use strict";

    Components.ApplyBranding = {
        bindings: {
            brandingModel: '<',
            selectors: '<'
        },

        // @ngInject
        controller: function ApplyBrandingController($scope, InjectBrandingService){
            this.$onChanges = function $onChanges() {
                if(this.brandingModel) {
                    this._applyBranding();

                    $scope.$watch('$ctrl.brandingModel.brand_color', function() {
                        if(this.colors &&
                            (this.colors.text_color !== this.brandingModel.text_color ||
                                this.colors.brand_color !== this.brandingModel.brand_color)) {
                            this._applyBranding();
                        }
                    }.bind(this));
                }
            };

            this._applyBranding = function _applyBranding() {
                InjectBrandingService.unregisterStyles(this);

                this.colors = {
                    text_color: this.brandingModel.text_color,
                    brand_color: this.brandingModel.brand_color
                };

                InjectBrandingService.registerStyles(this.colors, this.selectors, this);
            };

            this.$onDestroy = function $onDestroy() {
                InjectBrandingService.unregisterStyles(this);
            };
        },
        name: 'hbApplyBranding',
    };

}());