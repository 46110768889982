(function () {
    'use strict';

    // @ngInject
    function WorkflowsControllerCtor(
        $scope,
        $injector,
        $state,
        _,
        AnalyticsService,
        AppStates,
        UsersManager,
        WorkflowManager,
        Enums,
        OnboardingService,
        PopupMessageService,
        ModalService,
        UiPersistenceService,
        FeaturesService,
        FeatureRestrictionService,
        NewAutomationsService

    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkflowsController';
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;
        this.NewAutomationsService = NewAutomationsService;

        this.WorkflowManager = WorkflowManager;
        this.user = UsersManager.getCurrUser();
        this.company = this.user.company;
        this.AppStates = AppStates;
        this.OnboardingService = OnboardingService;
        this.PopupMessageService = PopupMessageService;
        this.ModalService = ModalService;
        this.UiPersistenceService = UiPersistenceService;
        this._ = _;
        this.$state = $state;
        this.FeaturesService = FeaturesService;
        this.$scope = $scope;

        //loaders model
        this.projectTypeToWorkflowMap = null;
        this.leadFormsWorkflowViewModels = null;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.automationsRestrictedFeature = this.FeatureRestrictionService.features.workflows;
        this.isAutomationsFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.automationsRestrictedFeature);
        this.shouldLoadOldAutomations = false;

        this.NewAutomationsService.isNewAutomationsEnabled().then(function (isEnabled) {
            if (isEnabled) {
                this.$state.go(AppStates.root_core_navigation_newautomations);
            } else {
                this.shouldLoadOldAutomations = true;
            }
        }.bind(this));
    }

    Controllers.WorkflowsController = Class(Controllers.BaseController, {
        constructor: WorkflowsControllerCtor,

        buildViewModel: function buildViewModel(data) {
            var workflows = data.workflows;
            if (!workflows) {
                this.workflowsViewModel = [];
                return;
            }

            this.buildLeadFormViewModel(data);

            this.workflowsViewModel = angular.copy(workflows);

            this.projectTypeToWorkflowMap = {};
            this.workflowsViewModel.forEach(function workflowIter(workflow) {
                if (workflow.relevant_project_types) {
                    workflow.relevant_project_types.forEach(function projectTypeIter(projectType) {
                        this.projectTypeToWorkflowMap[projectType] = workflow;
                    }.bind(this));
                }
            }.bind(this));
        },

        buildLeadFormViewModel: function buildLeadFormViewModel(data) {
            if (data.lead_forms_workflow_view_models) {
                this.leadFormsWorkflowViewModels = data.lead_forms_workflow_view_models;
            }
            return this.leadFormsWorkflowViewModels;
        },
    });
}());
