// @ngInject

Models.WorkspaceModel = function WorkspaceModel(BaseModel, UsersManager, WorkspacesManager, EventsManager, _, ModelFactory, OnboardingBotManager, FeaturesService) {

    var workspaceStatusesCD = {
        lead: 0,
        lead_archived: 1,
        client: 2,
        client_archived: 3,
        lead_sent: 4
    };

    var WorkspaceModelClass = Class(BaseModel, {

        DEFAULT_WORKSPACE_NAMES: ['Client Workspace', 'New Workspace'],

        constructor: function constructor() {
            WorkspaceModelClass.$super.call(this);
            this.__className = 'WorkspaceModel';
            this.__ModelFactory = ModelFactory;

            this.init();
        },

        init: function init() {
            var workspaceFilesMasterCollection = this.__ModelFactory.getWorkspaceFilesMasterCollection();
            workspaceFilesMasterCollection.on('itemAdded', this.fileAddedToMasterCollection, this);
            workspaceFilesMasterCollection.on('itemRemoved', this.fileRemovedFromMasterCollection, this);

            this.currUser = UsersManager.getCurrUser();
            this.hasWorkspaceNotification = false;
            this.is_deletable = true;
        },

        mapChilds: function mapChilds() {
            return {creator: 'UserModel',
                workspace_files: 'WorkspaceFilesCollection',
                members: 'UsersCollection',
                feed: 'FeedModel'};
        },

        afterMixingFrom: function afterMixingFrom(object) {
            var self = this;
            this.members.forEach(function (element, index) {
                element.setAsMemberOfWorkspace(self._id);
            });


            if (!this.alreadyUpdatingEvent && this.event) {
                this.alreadyUpdatingEvent = true;
                if (object.event) {
                    //only if the object we mixed from contains some event information (assuming it is the most updated)
                    //update the event model with this new information
                    EventsManager.updateEventFromWorkspace(object.event, this);
                }

                this.alreadyUpdatingEvent = false;
            }

            this._checkForNotificaitons();
        },

        getModelTimeout: function getModelTimeout() {
            return 10 * 1000; // workspace mode timeout is 10 seconds
        },

        _checkForNotificaitons: function checkForNotificaitons() {
            var currentUserId = this.currUser._id;
            //the workspace updated check if we have a file notification
            if (this.workspace_notifications && this.workspace_notifications.length > 0) {
                this.hasWorkspaceNotification = _.findWhere(this.workspace_notifications, {seen: false, file_owner_id: currentUserId });
            }
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
            copy.event = undefined;
            copy.feed = undefined; //don't copy entire feed
        },

        isBooked: function isBooked() {

            // cannot use <= since we get the cd and not the value.
            return (this.workspace_status_cd === workspaceStatusesCD.client );
        },

        isSentOrBooked: function isSentOrBooked() {

            // cannot use <= since we get the cd and not the value.
            // since we will change the status mechanism, i decided to leave it ugly. fun.
            return (this.workspace_status_cd === workspaceStatusesCD.client ||
                this.workspace_status_cd === workspaceStatusesCD.lead_sent);
        },

        isCanceled: function isCanceled() {
            return this.is_canceled;
        },

        isArchived: function isArchived() {
            return !this.active_state;
        },

        getWorkspaceOrProjectName: function getWorkspaceOrProjectName() {
            var name = this.workspace_tab_info && this.workspace_tab_info.name || '';
            if (this.event) {
                name = this.event.event_name;
            }
            return name;
        },

        getVendorsForRequestProposal: function getVendorsForRequestProposal(myId) {
            var users = this.members.filter(function (user) {
                return !!user.email && user._id !== myId && user.isVendor();
            });

            // we currently support sending request only to the first user (on the server side)
            if (users.length > 1) {
                return [users[0]];
            }

            return users;
        },

        shouldAllowSetPaymentInvoice: function shouldAllowSetPaymentInvoice(user) {
            var vendors = this.getVendorsForRequestProposal(user._id);
            return (vendors.length === 1 && this.members.length === 2);
        },

        isViewOnlyMode: function isViewOnlyMode() {
            var self = this;

            if (OnboardingBotManager.isOnboardingBotActiveOnWS(this)){
                return true;
            }

            if (!this.currUser.isVendor()) {
                return false;
            }

            var alreadyInWorkspace = this.members.filter(function (user) {
                return user._id === self.currUser._id;
            }).length > 0;

            if (alreadyInWorkspace) {
                return false;
            }


            var superAdminOfCreatorCompany = self.creator_company_id === self.currUser.company._id && self.currUser.hasCompanySuperAdminPermissions();
            return !superAdminOfCreatorCompany;
        },

        fileAddedToMasterCollection: function fileAddedToMasterCollection(file) {
            var id = file.workspace_id || file.couple_card_id;

            if (!id && file.workspace) {
                id = file.workspace._id;
            }

            if (!id && file.couple_card) {
                id = file.couple_card._id;
            }

            if (id && id === this._id) {
                this.workspace_files.push(file);
            }
        },

        fileRemovedFromMasterCollection: function fileRemovedFromMasterCollection(file) {
            var id = file.workspace_id || file.couple_card_id;

            if (!id && file.workspace) {
                id = file.workspace._id;
            }

            if (!id && file.couple_card) {
                id = file.couple_card._id;
            }

            if (id === this._id) {

                var self = this;
                this.workspace_files.forEach(function (element, index) {
                    if (element._id === file._id) {
                        self.workspace_files.splice(index, 1);
                        return;
                    }
                });
            }
        },

        getWorkspaceFilesOwnedByCurUser: function getWorkspaceFilesOwnedByCurUser() {
            return this.workspace_files.filter(function (file) {
                return file.isOwnedByCurrUser();
            });
        },

        isMemberInWorkspace: function isMemberInWorkspace(memberId) {
            for (var i in this.members) {
                if (this.members[i]._id === memberId) {
                    return true;
                }
            }

            return false;
        },

        totalAttachments: function totalAttachments() {
            if (!this.attachments) {
                return 0;
            }

            return this.attachments.images.length + this.attachments.files.length;
        },

        usersWithoutTeamMembers: function usersWithoutTeamMembers() {

            if (!this.currUser.company) {
                return this.members;
            }

            return this.members.filter(function filterFileClients(member) {
                return !member.company || member.company._id !== this.currUser.company._id;
            }.bind(this));
        },

        hasValidRecipient: function hasValidRecipient() {
            var usersWithoutTeamMembers = this.usersWithoutTeamMembers();
            return (!usersWithoutTeamMembers || usersWithoutTeamMembers.length > 0);
        },


        /*****************************************
         * WORKSPACE ACTIONS
         ******************************************/
        deleteWorkspace: function deleteWorkspace() {
            WorkspacesManager.deleteWorkspace(this);
        },

        updateWorkspaceName: function updateWorkspaceName() {
            return WorkspacesManager.updateWorkspaceName(this);
        },

        // =====================
        // Menu Actions
        // =====================
        allowDelete: function allowDelete() {
            return this.remove_action != null && this.remove_action === "delete";
        },
        
        getWorkflow: function getWorkflow() {
            return WorkspacesManager.getWorkflowTemplate();
        },

        deleteFeedItem: function deleteFeedItem(item) {
            return WorkspacesManager.deleteFeedItem(this, item);
        },

        restoreFlow: function restoreFlow(item) {
            if (!item.data) {
                return;
            }
            var flow_id = item.data.on_object_id;
            return WorkspacesManager.restoreFlow(flow_id);
        }
    });

    return WorkspaceModelClass;
};

