(function () {
    "use strict";

    // @ngInject
    function BaseComponentControllerFunc($injector, $scope, $sce, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BaseComponentController';
        this.AnalyticsService = AnalyticsService;
    }

    var BaseComponentController = Class(Controllers.BaseController, {
        constructor: BaseComponentControllerFunc,
    });

    Components.BaseComponent = {
        bindings: {
            component: '=',
            block: '=',
            isRootComponent: '<',
            isEditable: '<',
            isEditableByClient: '<',
            isOwnerMode: '<',
            isStyleCustomizable: '<',
        },
        controller: BaseComponentController,
        controllerAs: 'baseComponentVm',
        name: 'hbBaseComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/base/base_component.html',
    };

}());
