(function () {
    'use strict';

    // @ngInject
    function BrandsControllerCtor($scope, $injector, AnalyticsService, ModalService, UsersManager, AppStates,
                                  DeviceService,AccountsService, Enums, NavigationService, $timeout, FeaturesService,
                                  $translate, ReactModalService, FeatureRestrictionService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'BrandsController';
        AnalyticsService.trackPageView(this, 'BrandsController');

        this.AppStates = AppStates;
        this.ModalService = ModalService;
        this.AccountsService = AccountsService;
        this.UsersManager = UsersManager;
        this.DeviceService = DeviceService;
        this.Enums = Enums;
        this.$timeout = $timeout;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        this.device = this.DeviceService.device;
        this.user = this.UsersManager.getCurrUser();
        this.upgradeBannerTitle = $translate.instant('MULTI_BRAND.UPGRADE_PLAN._TITLE_');
        this.upgradeBannerTitleMobile = $translate.instant('MULTI_BRAND.UPGRADE_PLAN._TITLE_MOBILE_');
        this.upgradeBannerBenefits = [
            $translate.instant('MULTI_BRAND.UPGRADE_PLAN.BENEFITS._MEMBER_ROLE_'),
            $translate.instant('MULTI_BRAND.UPGRADE_PLAN.BENEFITS._WORKSPACES_')
        ];
        this.upgradeBannerBenefitsMobile = [
            $translate.instant('MULTI_BRAND.UPGRADE_PLAN.BENEFITS_MOBILE._MEMBER_ROLE_'),
            $translate.instant('MULTI_BRAND.UPGRADE_PLAN.BENEFITS_MOBILE._WORKSPACES_')
        ];
        
        this._buildBrandsList();

        NavigationService.registerPlusButtonCallback(this.addCompany.bind(this));

        this.restrictedFeature = this.FeatureRestrictionService.features.multiBrands;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('brandsVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
            NavigationService.unregisterPlusButtonCallback();
        });
    }

    Controllers.BrandsController = Class(Controllers.BaseController, {
        constructor: BrandsControllerCtor,

        addCompany() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            this.ModalService.openAddCompanyModal().then((values) => {
                this.$timeout(() => {
                    this._create(values.companyType, values.companyName);
                }, 200);
            });
        },

        switchCompany(companyId) {
            this.AccountsService.switchCompany(companyId);
        },

        _create(companyType, companyName) {
            this.AccountsService.createCompany(this.user.account_id, companyType, companyName).then((resp) => {
                return this.AccountsService.switchCompany(resp.data.company._id, true);
            }).then(() => {
                this.goToState(this.AppStates.root_core_navigation_settings_company_general);
            });
        },

        _buildBrandsList() {
            this.companies = [];
            this.user.companies.forEach((company) => {

                let _company = {
                    _id: company._id,
                    name: company.company_name,
                    icon_image: company.icon_image,
                    email: company.email
                };

                if (company._id === this.user.default_company_id) {
                    this.defaultCompany = _company;
                } else {
                    this.companies.push(_company);
                }
            });

            if (!this.defaultCompany) {
                this.defaultCompany = this.companies.pop();
            }
        }
    });
}());
