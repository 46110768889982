(function () {
    "use strict";

    Directives.ScrollBottomDirective = function ScrollBottomDirective() {

        // @ngInject
        function ScrollBottomDirectiveControllerFunc($scope, $, $element) {

            var self = this;

            self.$scope = $scope;

            function updateCanGetMore() {
                self.canGetMore = true;
            }

            updateCanGetMore();

            function scrollHandler() {

                var scrollTo = $('#scroll-to');

                if (!scrollTo && scrollTo.length === 0) {
                    return;
                }

                var hT = scrollTo.offset().top,
                    hH = scrollTo.outerHeight(),
                    wH = $(window).height(),
                    wS = $(this).scrollTop();
                if (wS > (hT + hH - wH - 100)) {
                    if (!self.canGetMore) {
                        // we got to the end but we're not ready for getting more contacts
                        setTimeout(scrollHandler, 100);
                    }
                    self.canGetMore = false;
                    self.$scope.callbackOnBottom();
                    setTimeout(updateCanGetMore, 100);
                }
            }

            var scrollableElement = self.$scope.elementHasInnerScroll ? $element : window;

            $(scrollableElement).on('scroll', scrollHandler);

            $scope.$on('$destroy', function () {
                $(scrollableElement).off('scroll', scrollHandler);
            });

        }

        var ScrollBottomDirectiveController = Class(Controllers.BaseController, {
            constructor: ScrollBottomDirectiveControllerFunc
        });

        return {
            restrict: 'A',
            scope: {
                callbackOnBottom: "&",
                elementHasInnerScroll: "@"
            },
            controller: ScrollBottomDirectiveController,
            link: function ($scope, $element, $attrs) {
                $element.append('<div id="scroll-to" style="height: 100px; width:1px"></div>');
            }
        };
    };
})();