(function () {
    'use strict';

    // @ngInject
    function PreviewModeServiceCtor(AppStates, $state, AppConfigService, OnboardingService, _) {
        this.AppStates = AppStates;
        this.$state = $state;
        this.AppConfigService = AppConfigService;
        this.OnboardingService = OnboardingService;
        this._ = _;

        this.init();
    }

    Services.PreviewModeService = Class({
        constructor: PreviewModeServiceCtor,

        init: function initPreviewMode() {

            this.inPreviewMode = false;
            this.inSendFilePreviewMode = false;

            this.previewStatesTransformation = {invoice: {}, proposal: {}, agreement:{}};

            // proposal
            this.previewStatesTransformation.proposal.initial = this.AppStates.root_core_workspaceFile_stepProposal;
            this.previewStatesTransformation.proposal[this.AppStates.root_core_workspaceFile_stepProposal] = this.AppStates.root_core_workspaceFile_stepAgreement;
            this.previewStatesTransformation.proposal[this.AppStates.root_core_workspaceFile_stepAgreement] = this.AppStates.root_core_workspaceFile_stepPayment;

            // invoice
            this.previewStatesTransformation.invoice.initial = this.AppStates.root_core_workspaceFile_stepProposal;
            this.previewStatesTransformation.invoice[this.AppStates.root_core_workspaceFile_stepProposal] = this.AppStates.root_core_workspaceFile_stepPayment;

            this.previewStatesTransformation.agreement.initial = this.AppStates.root_core_workspaceFile_stepAgreement;

            this.previewStates = {
                proposal: this.AppStates.root_core_workspaceFile_stepProposal,
                agreement: this.AppStates.root_core_workspaceFile_stepAgreement,
                payment: this.AppStates.root_core_workspaceFile_stepPayment
            };
        },

        enter: function enter(workspaceFile, params) {

            if (!workspaceFile.isInvoice() && !workspaceFile.isProposal() && !workspaceFile.isAgreement()) {
                return;
            }

            this.inPreviewMode = true;
            this.previewModeState = null;

            // Preview mode from the templates_editor
            this.inTemplatePreviewMode = Boolean(params.templateOriginId);

            this.templateOriginId = params.templateOriginId;
            this.templateOriginModelKey = params.templateOriginModelKey;

            if (workspaceFile.isProposal() || workspaceFile.isInvoice() || workspaceFile.isAgreement()) {
                // inFilePreviewMode variable added extra space at the top for the header.
                // on the onboarding we removed that header at the top so i dont want this addition.
                if (params && !params.isSendFilePreview) {
                    this.AppConfigService.appState().inFilePreviewMode = true;
                }

                if (params && params.shouldExplicitlyExitPreviewtoEditFile) {
                    //this indicates that we came directly to preview (not from file edit), and when "exiting"
                    //we need to explicitely go to edit. we can't allways use it because it ruins the back mechanism.
                    this.shouldExplicitlyExitPreviewtoEditFile = true;
                }

                if (params && params.isSendFilePreview) {
                    this.inSendFilePreviewMode = true;
                }

                this.advancedState(workspaceFile, params, true);
            }
        },

        advancedState: function advancedState(workspaceFile, params, location) {
            var state = this.previewModeState ? this.previewModeState : 'initial';
            this.previewModeState = this.previewStatesTransformation[workspaceFile.file_type][state];

            params = params || {};
            this._.extend(params, {workspace_file_id: workspaceFile._id, preventStateChangeModals: true});
            this.$state.go(this.previewModeState, params, {location: location});
        },

        setState: function setState(state) {
            this.previewModeState = this.previewStates[state];
        },

        inState: function inState(state) {
            return this.inPreviewMode && this.previewModeState === this.previewStates[state];
        },

        quit: function quit() {
            this.inPreviewMode = false;
            this.inSendFilePreviewMode = false;
            this.AppConfigService.appState().inFilePreviewMode = false;
            this.shouldExplicitlyExitPreviewtoEditFile = false;

            if (this.inTemplatePreviewMode) {
                this.inTemplatePreviewMode = false;
            }
        },

        returnToFile: function returnToFile(workspaceFile, params, removePreviewHistory) {
            this.quit();

            params = params || {};
            this._.extend(params, {workspace_file_id: workspaceFile._id, preventStateChangeModals: true});
            if (removePreviewHistory){
                this.$state.go(this.AppStates.root_core_workspaceFile, params, {location: 'replace'});
            }
            else{
                this.$state.go(this.AppStates.root_core_workspaceFile, params);
            }
        },

        isInPreviewMode: function isInPreviewMode() {
            return this.inPreviewMode;
        },

        isInSendFilePreviewMode: function isInSendFilePreviewMode() {
            return this.inSendFilePreviewMode;
        }
    });
}());
