/**
 * Created by dvircohen on 09/03/2016.
 */
(function () {
    'use strict';

    // @ngInject
    function DisableAutoPaymentControllerCtor(
        $modalInstance, $scope, $injector, Enums,
        AnalyticsService, WorkspaceFilesManager,
        workspaceFile) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'DisableAutoPayController';
        AnalyticsService.trackPageView(this, 'DisableAutoPayControllerView');

        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.workspaceFile = workspaceFile;
        this.modalInstance = $modalInstance;
        this.Enums = Enums;

        this.requestInProgress = false;
    }

    Controllers.DisableAutoPaymentController = Class(Controllers.BaseController, {
        constructor: DisableAutoPaymentControllerCtor,

        cancel: function cancel() {
            this.modalInstance.close();
        },

        disableAutoPay: function disable() {

            var self = this;
            self.requestInProgress = true;

            var promise = this.WorkspaceFilesManager.updateAutoPayment(this.workspaceFile, this.Enums.AutoPaymentStatus.inactive);
            promise.then(function() {
                self.cancel();
            }).finally(function() {
                self.requestInProgress = false;
            });

            return promise;
        }
    });
}());

