/**
 * directive which allows to call specific functions when an ng-repeat related event occurs
 * for example, the directive can be used to call a function on the last element of an ng-repeat, thus
 * notifying that an ng-repeat directive has finished rendering
 */


Directives.RepeatEventDirective = function RepeatEventDirective() {

    // @ngInject
    function RepeatEventDirectiveControllerFunc($scope, $injector, $attrs, $timeout) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'RepeatEventDirectiveController';

        this.$timeout = $timeout;
        this.$scope = $scope;

        if ($scope.$first && $attrs.hbRepeatStartAction) {
            this.evalCallback($attrs.hbRepeatStartAction);
        }

        if ($scope.$last && $attrs.hbRepeatEndAction) {
            this.evalCallback($attrs.hbRepeatEndAction);
        }

        var self = this;
        if ($attrs.hbRepeatIndexChangeAction) {
            //var scope = $scope;
            $scope.$watch('$index', function(newIndex, oldIndex) {
                if (newIndex !== oldIndex) {
                    if ($attrs.hbRepeatIndexChangeIf) {
                        if (self.$scope.$eval($attrs.hbRepeatIndexChangeIf)) {
                            self.evalCallback($attrs.hbRepeatIndexChangeAction);
                        }
                    } else {
                        self.evalCallback($attrs.hbRepeatIndexChangeAction);
                    }
                }
            });
        }
    }

    var RepeatEventDirectiveController = Class(Controllers.BaseController, {
        constructor: RepeatEventDirectiveControllerFunc,

        evalCallback: function evalCallback(codeString) {
            var self = this;
            this.$timeout(function () {
                self.$scope.$eval(codeString);
            }, 1);
        }

    });


    return {
        restrict: 'A',
        controller: RepeatEventDirectiveController,
        controllerAs: 'repeatEventDirectiveVm',
        bindToController: true
    };
};