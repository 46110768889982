/**
 * Created by inonstelman on 1/5/15.
 */


Directives.PopupMessageDirective = function PopupMessageDirective() {

    var TOP_LEVEL_CLASS = 'hb-popup-message-open';

    // @ngInject
    function PopupMessageDirectiveControllerFunc($element, PopupMessageService, $translate, $scope, $injector, UiPersistenceService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PopupMessageDirectiveController';
        var self = this;
        this.closing = false;
        this.openNext = false;

        var popupElem = $element.find('#messagePopupModal');
        var $body = $element.closest('body');

        popupElem.foundation('reveal', {
            animation: 'fade',
            closeOnBackgroundClick: false,
            bg_class: 'popup-message-bg',
            close_on_background_click: false
        });

        popupElem.on('opened', function(){
            $body.addClass(TOP_LEVEL_CLASS);
            $element.focus();
        });

        popupElem.on('close', function(){

            self.closing = true;

            // For the case where the user use ESC key to close the popup
            if (!self.callbackInvoked) {
                self.negBtnClick();
            }
        });

        popupElem.on('closed', function(){

            $body.removeClass(TOP_LEVEL_CLASS);

            self.closing = false;
            if (self.openNext === true) {
                self.openNext = false;

                // open popup in line
                self._openPopup();
            }
        });

        self.popupData = PopupMessageService.getPopupData();

        var onPopupChange = function onPopupChange(isActive){

            if (isActive && self.closing === true) {
                self.openNext = true; // will be opened when current popup is closed
                return;
            }

            if (isActive){
                self._openPopup();
                return;
            }

            popupElem.foundation('reveal', 'close');

        };

        PopupMessageService.registerPopupEvents(onPopupChange);

        self._openPopup = function _openPopup() {
            this.callbackInvoked = false;
            self.popupSeverity = self.popupData.popupInfo.severity;
            self.popupType = self.popupData.popupInfo.popupType;
            $translate(self.popupData.popupInfo.posBtnRes).then(function (text) {
                self.positiveBtnText = text;
            }, function translationFailed(){
                self.positiveBtnText = self.popupData.popupInfo.posBtnRes;
            });
            if (self.popupData.popupInfo.negBtnRes) {
                $translate(self.popupData.popupInfo.negBtnRes).then(function (text) {
                    self.negativeBtnText = text;
                }, function translationFailed(){
                    self.negativeBtnText = self.popupData.popupInfo.negBtnRes;
                });
            }


            if(angular.isObject(self.popupData.popupInfo.messageRes)){
                if(self.popupData.popupInfo.messageRes.dataList) {
                    self.dataList = self.popupData.popupInfo.messageRes.dataList;
                    self.popupText = self.popupData.popupInfo.messageRes.msg;
                } else {
                    self.popupText = $translate.instant(self.popupData.popupInfo.messageRes.translationId, self.popupData.popupInfo.messageRes.interpolateParams);
                }
            } else {
                self.popupText = self.popupData.popupInfo.messageRes;
            }

            self.successCallback = self.popupData.popupInfo.successCallback;
            self.dismissCallback = self.popupData.popupInfo.dismissCallback;
            self.dontShowAgain = self.popupData.popupInfo.dontShowAgain;
            self.branded = self.popupData.popupInfo.branded;


            popupElem.foundation('reveal', 'open');
        };

        self.posBtnClick = function posBtnClick() {
            this.callbackInvoked = true;
            PopupMessageService.clearPopup();
            if (self.dontShowAgain && self.hidePopUp) {
                UiPersistenceService.setUiPersistence(self.popupData.popupInfo.uiPersistenceKey, {'dontShow': true});
            }
            if (self.successCallback && typeof self.successCallback === 'function') {
                self.successCallback();
            }
        };


        self.negBtnClick = function negBtnClick() {
            this.callbackInvoked = true;
            if (self.dismissCallback && typeof self.dismissCallback === 'function') {
                self.dismissCallback();
            }

            PopupMessageService.clearPopup();
        };

    }

    var PopupMessageDirectiveController = Class(Controllers.BaseController, {
        constructor: PopupMessageDirectiveControllerFunc
    });
    return {
        scope: {

        },
        templateUrl: 'angular/app/modules/common/ui_components/popup_alert/popup_message_directive_template.html',
        controller: PopupMessageDirectiveController,
        controllerAs: 'popupMessageVm',
        bindToController: true
    };

};
