//Your create action must return a promise.
Directives.FilmstripWithActionsDirective = function FilmstripWithActionsDirective() {

    // @ngInject
    function FilmstripWithActionsDirectiveControllerFunc($scope, $injector, $timeout, Constants, AppConfigService, $document, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FilmstripWithActionsDirectiveController';
        this.createLoading = false;
        this.PopupMessageService = PopupMessageService;
        var self = this;
        var appStyleType = AppConfigService.getAppStyleType();
        //this.showCreateAction = true;
        //this.showDeleteAction = true;

        if (!this.addButtonText) {
            this.addButtonText = "+";
        }

        this.createProposalPane = function createAction(){
            this.createLoading = true;
            this.createAction().finally(function stopCreateLoading(){
                self.createLoading = false;

                var scrollIt = function(){
                    var el = $('#' + self.linksTo[self.linksTo.length - 1].element);
                    if(el){
                        $document.scrollTo(0, el.height() + el.offset().top);
                    }
                };

                //if a-la-carte is added to filmstrip, scroll to the last pane.
                if(self.showDeleteALaCarteAction()){
                    scrollIt();
                }
                else{
                    //scroll to the last pane after the collection is updated.
                    $timeout(scrollIt.bind(this),0);
                }


            });
        };

        this.deleteProposalPane = function deleteAction(link){
            var that = this;
            var deleteConfirmed = function deleteConfirmed(link) {
                that.deleteAction({id:link.id });
            };

            that.PopupMessageService.showConfirm(that.PopupMessageService.severityTypes.warning,
                'TIMELINE.ALERT._CONFIRM_DELETE_SECTION_',
                function ok() {
                    deleteConfirmed(link);
                }.bind(this),
                function cancelled(){

                },
                'Delete',
                'Cancel'
                );
        };

        this.deleteIntroPane = function deleteIntroPane(){
          this.deleteIntroAction();
        };

        this.deleteALaCarte = function deleteALaCarte(){
            this.deleteALaCarteAction();
        };

        this.createALaCarte = function createALaCarte(){
            this.createALaCarteAction();
        };

        this.clickAddPanel = function clickAddPanel(event){
            this.addMenuAction({$event:event});
        }

        function fixDefaultEnableToTrueAndScrollOffset(linksArray) {
            if (linksArray && angular.isArray(linksArray)) {
                linksArray.forEach(function (link, index) {
                    link.enable = (link.enabled === undefined) ? 'true' : link.enabled;

                    switch (appStyleType) {
                        case Constants.AppStyleTypes.small:
                            if (index === 0) {
                                link.scrollToOffset = 15;
                            } else {
                                // this is an offset to take into account the fixed positioned header height and the fact
                                // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                // this is the sum of them. should be changed if the styling is changed.
                                link.scrollToOffset = 220;
                            }
                            break;
                        default:
                            if (index === 0) {
                                link.scrollToOffset = 150; // we want the whole photo to show.
                            } else {
                                // this is an offset to take into account the fixed positioned header height and the fact
                                // that the objects referenced are within a styled frame with padding (f-s-frame) when scrolling.
                                // this is the sum of them. should be changed if the styling is changed.
                                link.scrollToOffset = 150;
                            }
                            break;
                    }
                });
            }
        }



        fixDefaultEnableToTrueAndScrollOffset(this.linksTo);

        $scope.$watch('filmstripWithActionsVm.linksTo', function (newVal, oldVal, $theScope) {
            //we got a new array fix the enable property
            fixDefaultEnableToTrueAndScrollOffset(newVal);
        });

        // this offset defines the threshold of when to switch the active object in the film-strip.
        // it should be somewhere in the middle of the screen to take the fixed banner at top into
        // consideration. since du-scroll-container was not set the document is the default container
        switch (appStyleType) {
            case Constants.AppStyleTypes.small:
                this.offsetFromTheTopForHotSpot = 300;
                break;
            default:
                //all the medium and big ones.
                this.offsetFromTheTopForHotSpot = 200;
        }
    }

    var FilmstripWithActionsDirectiveController = Class(Controllers.BaseController, {
        constructor: FilmstripWithActionsDirectiveControllerFunc
    });



    return {
        scope: {
            linksTo: '=linksTo',
            createAction: '&createAction',
            deleteAction: '&deleteAction',
            showCreateAction: '&showCreateAction',
            showDeleteAction: '&showDeleteAction',
            addButtonText: '@addButtonText',
            aLaCarte: '&?aLaCarte',
            createALaCarteAction: '&?',
            deleteALaCarteAction: '&?',
            showCreateALaCarteAction:'&?showCreateALaCarteAction',
            showDeleteALaCarteAction:'&?showDeleteALaCarteAction',
            shouldShowALaCartePane: '&?',
            selectedItem: '&?selectedItem',
            classes:'&?classes',
            intro: '&?intro',
            shouldShowIntroPane: '&?',
            addMenuAction: '&?',
            shouldShowAddMenuAction: '&?',
            deleteIntroAction: '&?',
            showDeleteIntroAction: '&?',
            branded: '=?branded'

        },
        templateUrl: 'angular/app/modules/common/ui_components/filmstrip/filmstrip_with_actions_template.html',
        controller: FilmstripWithActionsDirectiveController,
        controllerAs: 'filmstripWithActionsVm',
        bindToController: true
    };
};