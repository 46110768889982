(function () {
    'use strict';

    Services.NewAutomationsService = class NewAutomationsService {
        // @ngInject
        constructor(UsersManager, IntercomService, Enums, AccountsManager, StatsigService, _) {
            this.UsersManager = UsersManager;
            this.IntercomService = IntercomService;
            this.Enums = Enums;
            this.AccountsManager = AccountsManager;
            this.StatsigService = StatsigService;
            this._ = _;
            this.user = this.UsersManager.getCurrUser();
        }

        isNewAutomationsEnabled() {
            if (this.user.isN8nEnabled()) {
                // this is needed for members who are manually migrated to the new automations
                this.IntercomService.identify({n8n_automations: true}, true);
                return Promise.resolve(true);
            } else {
                return this.StatsigService.getExperimentConfig(this.Enums.StatsigExperiments.newAutomations).then(function (experiment) {
                    const isAccountTrueVariant = experiment && experiment.get('new_experience', false);
                    // member is in the new automations experiment, and gets the true variant
                    if(isAccountTrueVariant){
                        return true;
                        // member doesn't pass the gate
                    } else if(experiment && this._.isEmpty(experiment.value)) {
                        return this.AccountsManager.isN8nEnabled(this.user.company.account).then(function (response) {
                            if (response.data.is_n8n_enabled){
                                this.IntercomService.identify({n8n_automations: true}, true);
                                return true;
                            } else {
                                return false;
                            }
                        }.bind(this)).catch(function () {
                            return false;
                        }.bind(this));
                    } else {
                        return false;
                    }
                }.bind(this)).catch(function () {
                    return false;
                }.bind(this));
            }
        }
    };
})();

