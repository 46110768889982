// @ngInject
Filters.TaxSymbolFilter = function reverse() {
    return function (value, type) {
        if (type === 'relative') {
            return value + '%';
        }

        return '$' + value;
    };
};
