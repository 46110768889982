

(function () {
    'use strict';

    class TemplateFileCardController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $translate,
            $filter,
            AnalyticsService,
            TemplatesViewService,
            UsersManager,
            CompaniesManager,
            moment
        ) {
            super($scope, $injector);
            this.__objectType = 'TemplateFileCardController';
            this.moment = moment;
            this.$translate = $translate;
            this.$filter = $filter;
            this.TemplatesViewService = TemplatesViewService;
            this.AnalyticsService = AnalyticsService;
            this.user = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();
            this.MAX_LENGTH = 40;

            $scope.$watch('$ctrl.company.default_agreement', () => {
                this.shouldShowFilledStar = this.template._id === this.company.default_agreement
            });
        }

        $onInit() {
            this.isVisiblePopover = false;

            const templateIsOlderThanUser = this.moment(this.template.created_at).isBefore(this.user.created_at)
            const createdAt = templateIsOlderThanUser ? this.user.created_at : this.template.created_at;

            this.createdAtShort= createdAt ? this.moment(createdAt).format('MMM DD, YYYY') : null;
            this.createdAtLong = createdAt ? this.moment(createdAt).format('LLLL') : null;

            this.templateTypeName = this.template.viewModel.name === 'agreement' ? 'contract' : this.template.viewModel.name;

            this.tooltipText = this.$translate.instant('TEMPLATES_PAGE.ACTIONS._DEFAULT_');

            this.templateAttachmentsTitle = this.$translate.instant('TEMPLATES_PAGE._TEMPLATE_HAS_ATTACHMENTS_');
        }

        $onChanges(changes) {
            this.title = changes.templateFileListView && changes.templateFileListView.currentValue
                ? this.template.title
                : `${this.$filter('limitTo')(this.template.title, this.MAX_LENGTH)}${(this.template.title.length > this.MAX_LENGTH ? '...' : '')}`;

            this.titleTooltip = !(changes.templateFileListView && changes.templateFileListView.currentValue) && this.title.length > this.MAX_LENGTH
                ? this.template.title
                : '';
        }

        handlePopoverClick($event) {
            $event.stopPropagation();
            this.isVisiblePopover = !this.isVisiblePopover;
        }

        isDefaultable() {
            return this.TemplatesViewService.isTemplateTypeDefaultable(this.template.viewModel);
        }

        isCloneable() {
            return this.TemplatesViewService.isTemplateTypeCloneable(this.template.viewModel, this.template);
        }

        isDeletable() {
            return this.TemplatesViewService.isTemplateTypeDeletable(this.template.viewModel, this.template);
        }

        isPublishable() {
            return this.TemplatesViewService.isTemplatePublishable();
        }

        onDefaultClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            if (this.onDefault && angular.isFunction(this.onDefault)) {
                this.onDefault({ template: this.template });
            }
        }

        onSelectClick($event) {
            $event.stopPropagation();
            if (this.onSelect && angular.isFunction(this.onSelect)) {
                this.onSelect({ template: this.template });
            }
        }

        onCloneClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.isVisible = false;
            if (this.onClone && angular.isFunction(this.onClone)) {
                this.onClone({ template: this.template });
            }
        }

        onDeleteClick($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.isVisible = false;
            if (this.onDelete && angular.isFunction(this.onDelete)) {
                this.onDelete({ template: this.template});
            }
        }

        onPublishClick($event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (this.onPublish && angular.isFunction(this.onPublish)) {
                this.onPublish({ template: this.template});
            }
        }

        clickOutside() {
            this.isVisiblePopover = false;
        }

        shouldShowDropdown(template) {
            return template && (template.key !== 'emailSignature');
        }

        handleMouseLeave() {
            this.isVisiblePopover = false;
        }

    }

    Components.TemplateFileCard = {
        bindings: {
            template: '<',
            templateIdIsLoading: '<',
            onDelete: '&',
            onDefault: '&',
            onSelect: '&',
            onClone: '&',
            onPublish: '&',
            templateFileListView: '<',
        },
        controller: TemplateFileCardController,
        name: 'hbTemplateFileCard',
        templateUrl: 'angular/app/modules/core/features/company_settings/templates/template_file_card/template_file_card.html'
    };

}());