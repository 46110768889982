(function () {
    "use strict";

    Services.SearchV2Service = class SearchV2Service {

        // @ngInject
        constructor(ModalService, WorkspaceFileService, $state, AppStates,
                    AnalyticsService, UsersManager, FlowService, ContactViewService
        ) {
            this.ModalService = ModalService;
            this.WorkspaceFileService = WorkspaceFileService;
            this.$state = $state;
            this.AppStates = AppStates;
            this.AnalyticsService = AnalyticsService;
            this.UsersManager = UsersManager;
            this.FlowService = FlowService;
            this.ContactViewService = ContactViewService;
        }

        open() {
            if (this.modal) {
                return;
            }

            // body.modal-open has position:fixed due to an old bug where
            // `overflow:hidden` not being respected in ios safari.
            // this prevents all scrolling in the modal as well, which doesn't work
            // for search modal, which has scrolling inside.
            document.body.style.position = 'initial';
            this.modal = this.ModalService.openSearchV2Modal();
            this.modal.result.finally(() => {
                this.modal = null;
                document.body.style.removeProperty('position'); // revert
            });

        }

        dismiss() {
            if (!this.modal) {
                return;
            }

            this.modal.dismiss();
        }

        close() {
            if (!this.modal) {
                return;
            }

            this.modal.close();
        }

        projectClicked(projectId, workspaceId) {
            this.close();
            this.$state.go(this.AppStates.root_core_navigation_event_workspace_feed, {
                workspace_id: workspaceId,
                event_id: projectId
            });

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.search_result_item_clicked,
                {searched_id: projectId, searched_type: 'Event', search_version: 2});
        }

        fileClicked(fileId){
            this.close();

            this.WorkspaceFileService.gotoWorkspaceFileById(fileId, null, null, false);

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.search_result_item_clicked,
                {searched_id: fileId, searched_type: 'File', search_version: 2});
        }

        contactClicked(contactName, contactEmail, contact_id, client_id) {
            if (!!client_id) {
                this.ContactViewService.openContactView(contact_id, 'general search');
            } else {
                this.$state.go(this.AppStates.root_core_navigation_contacts, {search: contactEmail}, {location: "replace"});
            }
            this.close();

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.search_result_item_clicked,
                {searched_id: contactName, searched_type: 'Contact', search_version: 2});

        }

        flowClicked(flowId){
            this.close();

            this.FlowService.goToFlow(flowId);

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.search_result_item_clicked,
                 {searched_id: flowId, searched_type: 'Flow', search_version: 2});
        }

    };
}());
