(function () {
    'use strict';

    class FlowsOnboardingProgressController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $timeout,
            DeviceService,
            _,
            $translate
        ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingProgressController';

            this._ = _;
            this.$timeout = $timeout;
            this.DeviceService = DeviceService;
            this.$translate = $translate
        }

        getTranslatedTitle(step) {
            const title = this.stepTranslationMap[step.key] && this.stepTranslationMap[step.key].concat(
                       this.DeviceService.nxSmallBreakpoint() ? '._HEADER_TITLE_SHORT_' :'._HEADER_TITLE_'
                   );
            return this.$translate.instant(title);
        }

        isCurrentStep(step) {
            return step.key === this.currentStep.key;
        }

        isStepCompleted(step) {
            return step.status === 'complete';
        }

        isFirstPendingStep(step) {
            return this._.isEqual(this.firstPendingStep, step);
        }

        isLastStep(index) {
            return index === this.steps.length - 1;
        }

    }

    Components.FlowsOnboardingProgress = {
        bindings: {
            steps: '<',
            currentStep: '<',
            isCollapsed: '<',
            stepTranslationMap: '<',
            firstPendingStep: '<',
        },
        name: 'hbFlowsOnboardingProgress',
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard_progress_bar.html',
        controller: FlowsOnboardingProgressController,
    };
}());
