(function () {
    'use strict';

    // @ngInject
    Services.spectrum = function spectrum($window) {
        //    return $window.spectrum;
        function spectrumWrapper() {

            var ret = {

                storedObj: $window.spectrum,
                get: function get() {

                    if (!this.storedObj) {
                        this.storedObj = $window.spectrum;
                    }

                    return this.storedObj;
                }
            };


            return ret;
        }

        return spectrumWrapper();
    };

}());
