(function () {
    'use strict';

    class TwoStateButtonController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);

            $scope.$watch('$ctrl.isChecked', (isChecked, wasChecked) => {
                if (isChecked !== wasChecked) {
                    this.onButtonClick();
                }
            });
        }



    }

    Components.TwoStateButton = {
        bindings: {
            onButtonClick: '&',
            isChecked: '='
        },
        transclude: {
            content: '?content'
        },
        controller: TwoStateButtonController,
        templateUrl: 'angular/app/modules/common/ui_components/two_state_button/hb_two_state_button.html',
    };

}());
