(function () {
    'use strict';

    // @ngInject
    function CompanySettingsIntegrationsControllerCtor($scope, $injector, IntercomService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsIntegrationsController';
        
        IntercomService.identify({new_integrations_page: true}, true);
    }

    Controllers.CompanySettingsIntegrationsController = Class(Controllers.BaseController, {
        constructor: CompanySettingsIntegrationsControllerCtor
    });
}());
