(function () {
    "use strict";

    // @ngInject
    Directives.StickyHeaderDirective = function StickyHeaderDirective($document) {
        return {
            restrict: 'A',
            link: function autoInputSizeLink($scope, $element) {

                var $parent = $element.scrollParent();
                var $body = $element.closest('body');

                $parent.on('scroll.stickyHeader.honeybook', function () {
                    var isSticky = $parent.scrollTop() > 0;
                    $element.toggleClass('fixed', isSticky);
                    $body.toggleClass('hb-sticky-header', isSticky);
                });

                $scope.$on('$destroy', function () {
                    $document.off('.stickyHeader.honeybook');
                });
            }
        };
    };


}());