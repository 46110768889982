
(function () {
    'use strict';

    class NewClientPortalComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, AppStates, CompaniesManager, ClientPortalService, ModalService, UsersManager,
            AnalyticsService, DeviceService, $timeout, ToastService, WebsocketHelperService, $translate, PopupMessageService) {
            super($scope, $injector);
            this.__objectType = 'NewClientPortalComponent';
            this.AppStates = AppStates;
            this.CompaniesManager = CompaniesManager;
            this.company = CompaniesManager.getCurrCompany();
            this.ClientPortalService = ClientPortalService;
            this.ModalService = ModalService;
            this.$timeout = $timeout;
            this.AnalyticsService = AnalyticsService;
            this.WebsocketHelperService = WebsocketHelperService;
            this.ToastService = ToastService;
            this.$translate = $translate;
            this.PopupMessageService = PopupMessageService;
            this.isMobile = DeviceService.checkIfMobileSize();
            this.maxNumOfChanges = 3;
            this.helpCenterUrl = "https://help.honeybook.com/getting-started/clients-and-creatives/your-clients-honeybook-experience";
            this.disableCustomizeScreen = !(this.company.company_url_prefixes && this.company.company_url_prefixes.length);
            this._init();

            if (!this.company.wasFullyFetched()) {
                this.registerOnce(this.company, 'success', this._init);
            }

            this.user = UsersManager.getCurrUser();
            this.companyHasAnySubscription = this.user.companyHasAnySubscription();

            this.customDomainStatus = 'empty';
            this.isEditDomain = false;
            this.clientPortalValue = this.company.company_url_prefixes[this.company.company_url_prefixes.length - 1];
            this.customDomainValue = 'anyprefix.yourcompanysite.com';
           
        }

        _init() {
            this.clientPortalModel = this.ClientPortalService.getDefaultClientPortalConfiguration(this.company);

            if (!this.company.company_custom_domains) {
                this.customDomainStatus = 'empty';
            } else {
                this.getCompanyCustomDomain();
            }
        }

        getCompanyCustomDomain() {
            this.CompaniesManager.getCompanyCustomDomain(this.company)
                .then(resp => {
                    if (resp.data && resp.data.company_custom_domains.length > 0) {
                        let currentCustomDomain = this.findCurrentDomain(resp.data.company_custom_domains);
                        this.customDomainStatus = currentCustomDomain.validation_status;
                        this.customDomainValue = currentCustomDomain.full_hostname;
                    }
                });
        }

        customDomainCnameUpdated(json) {
            this.WebsocketHelperService.unregisterFromRoom(this.company._id + ".custom_domain_cname_updated");
            try {
                var data = JSON.parse(json).data;
                this.customDomainStatus = data.validation_status;
                if (this.customDomainStatus && this.customDomainStatus === 'inprogress') {
                    this.WebsocketHelperService.registerToRoom(this.company._id + ".custom_domain_updated", this.customDomainUpdated.bind(this));
                }
            }
            catch (e) {
                console.error('customDomainCnameUpdated json error:' + e);
                console.error('customDomainCnameUpdated json: ' + json);
            }
        }

        customDomainUpdated(json) {
            this.WebsocketHelperService.unregisterFromRoom(this.company._id + ".custom_domain_updated");
            try {
                var data = JSON.parse(json).data;
                this.customDomainStatus = data.validation_status;
                if (this.customDomainStatus && this.customDomainStatus === 'invalid') {
                    this.customDomainValue = 'anyprefix.yourcompanysite.com';
                }
            }
            catch (e) {
                console.error('customDomainUpdated json error:' + e);
                console.error('customDomainUpdated json: ' + json);
            }
        }

        openEducationModal() {
            this.ModalService.openClientPortalDemoModal();
        }

        toggleDisablePreview() {
            this.disableCustomizeScreen = !this.disableCustomizeScreen;
        }

        openSubDomainConfig() {
            this.WebsocketHelperService.registerToRoom(this.company._id + ".custom_domain_cname_updated", this.customDomainCnameUpdated.bind(this));

            this.ModalService.openSubdomainConfigModal(this.company).then(() => {
                this.getCompanyCustomDomain();
            });

            this.AnalyticsService.trackClick(this, this.customDomainStatus === 'invalid' ? this.AnalyticsService.analytics_events.try_again_custom_domain : this.AnalyticsService.analytics_events.open_custom_domain_modal);
        }

        onEditClicked() {
            this.isEditDomain = true;
            this.tempClientPortalValue = this.clientPortalValue;
        }

        onCancelEdit() {
            this.isEditDomain = false;
        }

        findCurrentDomain(companyCustomDomains) {
            var currentCustomDomain = companyCustomDomains.filter(function (ccd) { return ccd.validation_status === 'active'; });
            return currentCustomDomain.length ? currentCustomDomain[0] : companyCustomDomains[companyCustomDomains.length - 1];
        }

        onSaveClientPortal() {
            // if url is not changed, do nothing
            if (this.company.company_url_prefixes.length &&
                this.company.company_url_prefixes[this.company.company_url_prefixes.length - 1] === this.tempClientPortalValue) {
                this.onCancelEdit();
                return;
            }

            const isUrlAlreadyOwnedBySelf = this.company.company_url_prefixes.indexOf(this.prefix) > -1;
            let changesLeft = this.company.company_url_prefixes ? this.maxNumOfChanges - this.company.company_url_prefixes.length : this.maxNumOfChanges;
            if (!isUrlAlreadyOwnedBySelf) {
                changesLeft = changesLeft - 1;
            }
            let errMsg = this.$translate.instant('CLIENT_PORTAL._URL_CHANGES_COUNTDOWN_', { changesLeft: changesLeft });
            if (changesLeft === 0) {
                errMsg = this.$translate.instant('CLIENT_PORTAL._LAST_URL_CHANGE_');
            }
            const promise = this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.none, errMsg, 'confirm change', 'cancel', 'Change portal URL?');
            promise.then(() => {
                this.CompaniesManager.updateCompanyUrlPrefix(this.company, this.tempClientPortalValue)
                    .then(function onSuccess(resp) {
                        if (resp.data.company_url_prefixes) {
                            this.clientPortalValue = this.tempClientPortalValue;
                            this.prefix = resp.data.company_url_prefixes[resp.data.company_url_prefixes.length - 1];
                            this.isEditDomain = false;
                            this.CompaniesManager.updateClientPortalConfiguration(this.company, this.company.client_portal_configuration);
                        }
                    }.bind(this))
                    .catch(error => {
                        this.ToastService.showError({
                            iconCSSClass: 'icon-hb-nx-exclamation-mark-circle',
                            titleTranslation: 'CLIENT_PORTAL._FAILED_SAVING_',
                            content: error.data.error_message,
                            dismissOnTimeout: true,
                            timeout: 7000,
                        })
                    });
            });
        }

        isMaxChanges() {
            return this.maxNumOfChanges - this.company.company_url_prefixes.length <= 0;
        }

        editButtonTooltipText() {
            if (!this.isMaxChanges()) {
                return undefined;
            }

            return this.$translate.instant('CLIENT_PORTAL._MAX_CHANGES_TOOLTIP_')
        }
    }


    Components.NewClientPortalComponent = {
        bindings: {
            mobileView: '<'
        },
        controller: NewClientPortalComponent,
        name: 'hbCompanyClientPortal',
        templateUrl: 'angular/app/modules/core/features/settings/company/client_portal/new_client_portal_controller/new_client_portal_template.html'
    };

}());
