
 (function () {
    'use strict';

    class AbTestBrowserController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            AppConfigService,
            UsersManager,
            AbTestService
            
        ) {
            super($scope, $injector);
            this.AppConfigService = AppConfigService;
            this.UsersManager = UsersManager;
            this.AbTestService = AbTestService;
        }
        
        shouldShowComponent() {
            const currUser = this.UsersManager.getCurrUser();
            return this.AbTestService.isShowAbTestBrowser &&  (!this.AppConfigService.isProduction() || currUser.isAdminLogin())
            && !this.AppConfigService.isE2E() && !this.AppConfigService.isTest();
        }
    }

    Components.AbTestBrowser = {
        controller: AbTestBrowserController,
        name: 'hbAbTestBrowser',
        templateUrl: 'angular/app/modules/common/ui_components/ab_test_browser/ab_test_browser.html'
    };

}());
