/**
 * Created by iggy on 7/26/16.
 */
(function () {
    "use strict";

    // @ngInject
    function PipelineSendBrochureControllerCtor($scope, $injector, $state, $translate, $modalInstance, _, $log,
                                                CompaniesManager, AppStates, UiPersistenceService,
                                                WorkspaceFilesManager, PendingTasksManager, Enums, NavigationService,
                                                PopupMessageService, workspace, AnalyticsService, AbTestService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PipelineSendBrochureController';
        AnalyticsService.trackPageView(this, 'quick send page view');

        this.$translate = $translate;
        this.$state = $state;
        this._ = _;
        this.$log = $log;

        this.modalInstance = $modalInstance;
        this.AppStates = AppStates;
        this.Enums = Enums;
        this.UiPersistenceService = UiPersistenceService;
        this.companiesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.PendingTasksManager = PendingTasksManager;
        this.AbTestService = AbTestService;
        this.NavigationService = NavigationService;
        this.workspace = workspace;

        this.sendingBrochure = false;
        this.creatingBrochureFile = false;
        this.selectedBrochureTemplate = null;
        this.selectedEmailTemplate = null;
        this.pendingTaskPromise = null;
        this.uiPeresitencyHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.brochureQuickSend, {
            brochureTemplateId: null,
            emailTemplateId: null
        });

        this.company = this.companiesManager.getCurrCompany();
        var brochureTemplates = this.company.getCompanyBrochures();

        if (brochureTemplates.length) {
            this.brochureTemplates = true;

            //select the first brochure template as the default
            if (this.uiPeresitencyHash.brochureTemplateId) {
                //try to find the persisted brochure template on the list of the users's template (it could have been deleted)
                var brochureTemplate = this._.findWhere(brochureTemplates, {_id: this.uiPeresitencyHash.brochureTemplateId});
                if (brochureTemplate) {
                    this.selectedBrochureTemplate = brochureTemplate;
                }
            }

            if (!this.selectedBrochureTemplate) {
                //fallback to the first one.
                this.selectedBrochureTemplate = brochureTemplates[0];
            }

        } else {
            this.brochureTemplates = false;
        }

        var emailTemplate;
        if (this.uiPeresitencyHash.emailTemplateId) {
            emailTemplate = this._.findWhere(this.company.vendor_emails, {_id: this.uiPeresitencyHash.emailTemplateId});
            if (emailTemplate) {
                this.selectedEmailTemplate = emailTemplate;
            }
        }

        if (!emailTemplate) {
            //get the default brochure email tempalate (that cannot be deleted)
            emailTemplate = this._.findWhere(this.company.vendor_emails, {email_type: 'brochure'});
        }

        if (!emailTemplate) {
            //another fallback that should not happen. just take the first template
            emailTemplate = this.company.vendor_emails[0];
            this.$log.error('could not find the brochure default email template. this should not happen');
        }

        this.selectedEmailTemplate = emailTemplate;

        $scope.$on('$destroy', this.onDestroy.bind(this));
    }

    Controllers.PipelineSendBrochureController = Class(Controllers.BaseController, {
        constructor: PipelineSendBrochureControllerCtor,

        onDestroy: function onDestroy() {
            if(this.pendingTaskPromise){
                //if for some strange reason the client canceled the dialog. the task will continue on the server
                //just cancel the polling (we dont want to nag the server for nothing)
                this.PendingTasksManager.cancelTaskPolling(this.pendingTaskPromise);
            }
        },

        closePipelineSendBrochure: function closePipelineSendBrochure() {
            this.modalInstance.dismiss();
        },

        onBrochureTemplateSelected: function onBrochureTemplateSelected(brochure) {
            this.selectedBrochureTemplate = brochure;
            this.uiPeresitencyHash.brochureTemplateId = this.selectedBrochureTemplate._id;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.brochureQuickSend, this.uiPeresitencyHash);
        },

        onEmailSelected: function onEmailSelected(email) {
            this.selectedEmailTemplate = email;
            this.uiPeresitencyHash.emailTemplateId = this.selectedEmailTemplate._id;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.brochureQuickSend, this.uiPeresitencyHash);
        },

        viewTemplates: function viewTemplates() {
            this.modalInstance.dismiss();
            this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'brochure'});
        },

        onSendButtonPressed: function onSendButtonPressed() {
            if (this.selectedBrochureTemplate && this.selectedEmailTemplate) {
                this.sendingBrochure = true;
                this.pendingTaskPromise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.brochureQuickSend, {
                    brochure_template_id: this.selectedBrochureTemplate._id,
                    email_template_id: this.selectedEmailTemplate._id,
                    workspace_id: this.workspace._id
                });

                //saving the pending task promise is important since the .then returns another promise that is not the same one as returned
                //by the PendingTasksManager that can be used to cancel the polling.
                this.pendingTaskPromise
                    .then(function success() {
                        this.pendingTaskPromise = null;
                        this.modalInstance.close();
                    }.bind(this))
                    .catch(function fail(error) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }.bind(this))
                    .finally(function finallyHandler() {
                        this.sendingBrochure = false;
                    }.bind(this));
            }
        },

        onPreviewFirstClicked: function onPreviewFirstClicked() {
            if (this.selectedBrochureTemplate) {
                this.creatingBrochureFile = true;

                this.WorkspaceFilesManager.createBrochure(this.workspace, null, this.selectedBrochureTemplate._id)
                    .then(function success(resp) {
                        this.modalInstance.dismiss();

                        this.$state.go(this.AppStates.root_core_workspaceFile_brochure, {
                            workspace_file_id: resp.data._id
                        });
                    }.bind(this))
                    .catch(function fail(resp) {
                        if (resp && resp.data && resp.data.error_message) {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                        } else {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                        }
                    }.bind(this))
                    .finally(function finallyHandler() {
                        this.creatingBrochureFile = false;
                    }.bind(this));
            }
        }
    });
}());
