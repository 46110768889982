// @ngInject
Filters.reverse = function reverse() {
    return function (items) {
        if (angular.isArray(items)){
            return items.slice().reverse();
        } else {
            return items;
        }
    };
};
