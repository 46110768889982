(function () {
    'use strict';

    class ClientPortalLogoController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalLogoController';
        }
    }

    Components.ClientPortalLogo = {
        bindings: {
            logoModel: '<'
        },
        controller: ClientPortalLogoController,
        name: 'hbClientPortalLogo',
        templateUrl: 'angular/app/modules/core/features/login/client_portal/client_portal_logo_template.html',
    };

}());