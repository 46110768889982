(function () {
    'use strict';

    // @ngInject
    Directives.ColorPicker = function ColorPicker($timeout,AnalyticsService) {

        return {
            restrict: 'E',
            template: '<input type="text" class="cfColorPicker"/>',

            scope: {
                color: '=',
                colorSelected: '&',
                onShow: '&',
                appendTo: '@',
                colorPreview: '&?',
                showAlpha: '=',
                showPalette: '=',
                palette: '<',
                containerClassName: '@',
                inputClassName: '@',
                onHide: '&'
            },

            link(scope, element) {
                let render = () => {
                    $timeout(() => {
                        scope.initialColor = scope.color;

                        element.find('.cfColorPicker').addClass(scope.inputClassName).spectrum({
                            color: scope.color,
                            showInput: true,
                            className: "full-spectrum",
                            showInitial: true,
                            showPalette: scope.showPalette,
                            palette: scope.palette,
                            showSelectionPalette: true,
                            maxPaletteSize: 5,
                            preferredFormat: "hex",
                            localStorageKey: "spectrum.hb",
                            clickoutFiresChange: false,
                            appendTo: scope.appendTo,
                            showAlpha: scope.showAlpha,

                            hide(color) {
                                scope.$applyAsync(() => {
                                    if (scope.selectedColorFromPalette) {
                                        let newSelectedColor = scope.selectedColorFromPalette.toString();
                                        scope.initialColor = newSelectedColor;
                                        scope.color = newSelectedColor;
                                        $timeout(() => {
                                            scope.colorSelected({ color: newSelectedColor});
                                        });
                                    } else {
                                        scope.colorPreview && scope.colorSelected({ color: scope.initialColor });
                                    }
                                    scope.onHide();
                                    document.querySelectorAll('.sp-container.sp-hidden').forEach(el => el.classList.remove(scope.containerClassName));
                                });
                            },

                            change(color) {
                                scope.selectedColorFromPalette = color;
                            },

                            show(color) {
                                scope.onShow();
                                scope.selectedColorFromPalette = null;

                                if (scope.analyticsEvent){
                                    AnalyticsService.track(this,scope.analyticsEvent,{color: color});
                                }
                                document.querySelector('.sp-container:not(.sp-hidden)').classList.add(scope.containerClassName);
                            },

                            move(color) {
                                if (scope.colorPreview) {
                                    let colorPreview = color.toString();
                                    $timeout(() => {
                                        scope.colorPreview({ color: colorPreview });
                                    });
                                }
                            }
                        });
                    });
                };
                render();

                scope.$watch('color', function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue &&
                        (!scope.selectedColorFromPalette || scope.selectedColorFromPalette.toString() !== newValue.toString())) {
                        render();
                    }
                });
            }

        };
    };
}());
