
// @ngInject
Directives.BalloonCKEditor = function BalloonCKEditor(CkEditor5, FontsService, ColorsService) {

    const fontFamilyOptions = FontsService.ckFonts;

    const defaultToolbar = ['fontFamily', '|', 'fontSize', '|', 'bold', '|', 'underline', '|', 'italic', '|', 'hbfontColor', '|', 'alignment', '|', 'link', '|', 'removeFormat', '|', 'undo'];

    const textColors = ColorsService.textColors;

    return {
        scope: {
          hbBalloonToolbar: '<',
          //hbBalloonDefaultAlignment: '@'
        },
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {
            if (!ngModel) {
                return;
            }

            CkEditor5.BalloonEditor.create(( element[0] ), {
                placeholder: 'Your text here',
                toolbar: scope.hbBalloonToolbar || defaultToolbar,
                fontFamily: {
                    options: fontFamilyOptions
                },
                link: {
                    defaultProtocol: 'https://',
                    addTargetToExternalLinks: true
                }
            })
            .then( editor => {
                //Extending default limiter container or editor will show below
                // editor.plugins.get( 'ContextualBalloon' ).positionLimiter = () => document.body;

                // init data
                if(ngModel.$modelValue) {
                    editor.setData(ngModel.$modelValue);
                }

                // To allow set of defalut alignment. it's buggy since it overrides the user interaction.
                // if(scope.hbBalloonDefaultAlignment) {
                //    editor.execute( 'alignment', { value: scope.hbBalloonDefaultAlignment } );
                // }

                editor.model.document.on('change:data', () => {
                    ngModel.$setViewValue(editor.getData());

                    if (editor.getData() !== ngModel.$modelValue) {
                        scope.$applyAsync();
                    }
                });

                ngModel.$render = () => {
                    if(ngModel.$modelValue) {
                        editor.setData(ngModel.$modelValue);
                    }
                };

                scope.$on('$destroy', () => {
                    editor.destroy();
                });
            })
            .catch( error => {
                console.error( error );
            });
        }
    };
};
