// @ngInject

Models.FeedModel = function FeedModel(BaseModel) {
    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'FeedModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                feed_items: 'FeedItemsCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
        },

        hasItems: function hasItems() {
            return !!(this.feed_items && this.feed_items.length);
        },

        deleteItem: function deleteItem(item) {
            return this.__parent.deleteFeedItem(item);
        },

        restoreFlow: function restoreFlow(item) {
            return this.__parent.restoreFlow(item);
        }

    });

};
