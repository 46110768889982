
(function () {
    'use strict';

    class ProjectDetailsInfoComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $filter) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsInfo';

            this.$filter = $filter;
            this.isLink = this.type === 'link';
        }

        $onChanges() {
            if (this.filter) {
                this.displayValue = this.$filter(this.filter)(this.value);
            } else {
                this.displayValue = this.value;
            }
        }
    }

    Components.ProjectDetailsInfo = {
        bindings: {
            label: '@',
            type: '@',
            value: '<',
            filter: '@'
        },
        controller: ProjectDetailsInfoComponent,
        name: 'hbProjectDetailsInfo',
        templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_info_component_template.html'
    };

}());
