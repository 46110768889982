(function () {
    "use strict";

    // @ngInject
    function BusinessToolsControllerCtor($injector, $scope, $window, _, OnboardingService, Enums, UsersManager, DeviceService, AnalyticsService, PretrialersService, $state, AppStates) {
        this.constructor.$super.call(this, $scope, $injector, _, OnboardingService, Enums, UsersManager);
        this.__objectType = 'BusinessToolsController';

        AnalyticsService.trackPageView(this, 'business tools');
        this.noHover = true;
        this._ = _;
        this.$state = $state;
        this.AppStates = AppStates;
        this.OnboardingService = OnboardingService;
        this.Enums = Enums;
        this.user = UsersManager.getCurrUser();
        this.DeviceService = DeviceService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;
        this.$scope = $scope;
        this.disableScrollListener = false;
        this.PretrialersService = PretrialersService;
        this.shouldShowCleanAtwsUI = this.PretrialersService.shouldShowCleanAtwsUI();

        var isPreTrialer = this.user.shouldStartTrial();
        if (!isPreTrialer) {
            this.$state.go(this.AppStates.root_core_navigation_home);
        }

        this.tools = [
            {
                name: 'PAYMENTS',
                class:'payments',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_payments.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_payments.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/payments_2x.png',
                title:'Payments & Booking',
                subTitle:'More cha-chings for you.',
                content:['Clients can sign and pay <br> online, from anywhere','Track payments in real time','Set up payment schedules<br> and reminders'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013818/app/business_tools/mobile_2x/payments_2x.png',
                mobileActive:true
            },
            {
                name: 'CONTRACTS',
                class:'contracts',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_contracts.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_contracts.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1522237122/app/business_tools/assets/contracts_v2_2x.png', // 1
                title:'Contracts & Signatures',
                subTitle:'Legit and easy-to-sign.',
                content:['Clients review and sign from anywhere','Smart bar highlights where to sign<br> for no misses','Get notified as soon as a client signs'],
                mobileTemplateURL:'http://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1522237179/app/business_tools/mobile_2x/contracts_mobile_v2_2x.png'
            },
            {
                name: 'COMMUNICATION',
                class:'communictation',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_communication.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_communication.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/communication_2x.png',
                title:'Client Communication',
                subTitle:'Goodbye back-and-forth.',
                content:['View all details in one place','Send emails, invoices, contracts & more','Never miss inquiries or messages'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013818/app/business_tools/mobile_2x/communication_2x.png'
            },
            {
                name: 'PROJECTS',
                class:'projects',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_projects.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_projects.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/projects_2x.png',
                title:'Project Tracking',
                subTitle:'Step-by-step organization.',
                content:['Get the status of all your projects','Stay on top of new inquiries','Customize stages to your process'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013818/app/business_tools/mobile_2x/projects_2x.png'
            },
            {
                name: 'TEMPLATES',
                class:'templates',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_templates.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_templates.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/templates_2x.png',
                title:'Templates',
                subTitle:'Professional & ready-to-go.',
                content:['Invoices','Contracts','Packages','Emails','Brochures','Proposals','Questionnaire'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013820/app/business_tools/mobile_2x/templates_2x.png'
            },
            {
                name: 'SCHEDULING',
                class:'scheduling',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_scheduling.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_scheduling.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516011851/app/business_tools/assets_meetings_2x.png',
                title:'Scheduling & Appointments',
                subTitle:'A smarter kind of calendar.',
                content:['View bookings, appts. and deadlines','Schedule client meetings without <br> back-and-forth','Never double book (we’ll make sure)'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516012306/app/business_tools/mobile_2x/calendar_2x.png'
            }, {
                name: 'BUSINESS_TOOLS.TOOLS.MOBILE_APP._NAME_',
                class:'mobile-app',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_mobile.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_mobile.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/mobile_2x.png',
                title:'BUSINESS_TOOLS.TOOLS.MOBILE_APP._TITLE_',
                subTitle:'BUSINESS_TOOLS.TOOLS.MOBILE_APP._SUBTITLE_',
                content:['Access key project info from anywhere','Review and send crucial documents','Track payments, signatures and more <br> in realtime', 'Available on iOS and Android'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013818/app/business_tools/mobile_2x/mobile_2x.png'
            }, {
                name: 'REPORTS',
                class:'reports',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_reports.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_reports.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/reports_2x.png',
                title:'Reports',
                subTitle:'Easy-to-digest data.',
                content:['Track monthly income,<br> leads & bookings','See exactly where leads are<br> coming from','View your progress over time'],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013819/app/business_tools/mobile_2x/reports_2x.png'
            },
            {
                name: 'TASKS',
                class:'tasks',
                colorURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/icon_tasks.svg',
                grayURL: 'https://res.cloudinary.com/honeybook/image/upload/v1515080098/app/business_tools/tools_icons/gray_icons/icon_tasks.svg',
                templateURL: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1515078272/app/business_tools/assets/tasks_2x.png',
                title:'Tasks & To-do’s',
                subTitle:'Productivity on overdrive.',
                content:['Set-up to-do lists for every project','See what’s coming up next','Get automated task reminders '],
                mobileTemplateURL:'https://res.cloudinary.com/honeybook/image/upload/f_auto,q_auto,fl_lossy/v1516013818/app/business_tools/mobile_2x/tasks_2x.png'
            },


        ];


        // listen to window scroll event
        $(this.$window).scroll(this.windowScrollHandler.bind(this));

    }

    Controllers.BusinessToolsController = Class(Controllers.BaseController, {
        constructor: BusinessToolsControllerCtor,

        openIntakeForm: function openIntakeForm() {
            this.OnboardingService.openInProductIntakeForm();
        },

        onToolHover: function onToolHover(tool){
            tool.active=true;
            this.toolsListHover=true;
        },

        onToolUnHover: function onToolUnhover(tool){
            tool.active=false;
            this.toolsListHover=false;
        },

        onToolsMouseEnter: function onToolsMouseEnter(tool) {
            this.AnalyticsService.track(this, 'hover: business Tool', {tool: tool.name});
        },

        getTranslationTextByCompanyType: function getTranslationTextByCompanyType(){
            var typeTxt = this.PretrialersService.getCompanyTypePluralPhrase(this.user.company.company_type);
            if (typeTxt === "Small Businesses") {
               return  'BUSINESS_TOOLS._SUB_TEXT_3_';
            }else{
                return  'BUSINESS_TOOLS._SUB_TEXT_4_';
            }
        },


        onToolIconClicked: function onToolIconClicked(tool, event) {

            // set active tool
            this.setToolMenuItemActive(tool);

            var toolKey = tool.class;

            if(toolKey) {

                // Prevent default anchor click behavior
                //event.preventDefault();
                this.disableScrollListener = true;
                // scroll to the right section by the toolKey
                $('html,body').animate({
                    scrollTop: $("#" + toolKey).offset().top - 68 - 50
                }, 800, function () {
                    this.disableScrollListener = false;

                    // Add hash (#) to URL when done scrolling (default click behavior)
                    // window.location.hash = hash;
                }.bind(this));
                this.scrollToMenuItem(tool);
            }

        },

        setToolMenuItemActive: function setToolMenuItemActive(tool) {

            // clear active tool
            this.removeToolActive();

            // mark current tool as active
            tool.mobileActive = true;

            $('#tool-item--' + tool.class).addClass('active');

        },

        removeToolActive: function removeToolActive() {
            _.forEach(this.tools, function (tool) {
                tool.active = false;
                tool.mobileActive = false;
            });

            var toolsMenuSelector = ".tools-list";
            $(toolsMenuSelector + ' .tool-item').removeClass('active');
        },


        scrollToMenuItem: function scrollToMenuItem(tool) {
            var toolsMenuSelector = "#top-menu";

            var toolsMenuEl = $(toolsMenuSelector);
            var toolMenuEl = $('#tool-item--' + tool.class);

            var width = $(window).width();
            var scrollLeft = toolsMenuEl.scrollLeft();
            var offset = toolMenuEl.offset().left;
            var distance = offset + toolMenuEl.width();

            if(distance >= (width)) {

                $(toolsMenuEl).animate({
                    scrollLeft: offset + scrollLeft
                }, 600, function () {

                });
            } else if (offset <= 0) {

                //var scroll = scrollLeft + offset - width;
                var scroll = Math.max(scrollLeft - width, 0);

                $(toolsMenuEl).animate({
                    scrollLeft: scroll
                }, 300, function () {

                });
            }

        },


        windowScrollHandler: function windowScrollHandler() {
            if (this.disableScrollListener){
                return;
            }

            var toolsMenuSelector = ".tools-list";
            var stickyMenuClass = "fixed-header";
            var windowScroll = $(this.$window).scrollTop();

            //var scrollThreshold = (480 - 68);
            var scrollThreshold = $("#top-menu").length ? $("#top-menu").offset().top : 0;

            // if scrolling the hero, do nothing until we get to the content
            // remove sticky from menu
            if(windowScroll < scrollThreshold) {
                $(toolsMenuSelector).removeClass(stickyMenuClass);
                return;
            }

            // handle sticky

            // add sticky class to menu
            $(toolsMenuSelector).addClass(stickyMenuClass);

            // Get container scroll position
            var fromTop = windowScroll + 68 + 55;

            // Get visible tools elements by their id
            var visibleToolsElements = this.tools.map(function (tool) {
                var toolElementId = tool.class;
                var toolElement = $('#' + toolElementId);
                if (toolElement.length) {
                    return toolElement;
                } else {
                    return false;
                }
            });

            // Get id of current scroll item
            var currentVisibleTool;
            visibleToolsElements.forEach(function (el) {
                if (el && $(el).offset().top <= fromTop)
                    currentVisibleTool = el;
            });

            if(currentVisibleTool) {

                // mark the correct menu item if not the active one
                var toolClass = currentVisibleTool[0].id;
                var activeTool = this.tools.filter(function(tool) {
                    return tool.class === toolClass;
                });

                activeTool = (activeTool && activeTool[0]) ? activeTool[0] : null;

                if(activeTool && !activeTool.mobileActive) {

                    // set active
                    this.setToolMenuItemActive(activeTool);

                    this.scrollToMenuItem(activeTool);

                }
            }
        },

        gotoLastWorkspace: function gotoLastWorkspace() {
            this.PretrialersService.gotoLastWorkspace();
        }
    });
}());

