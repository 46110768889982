Components.NavBarView = {
    bindings: {
        backButton: '&',
        backButtonTitle: '@?',
        title: '@?'
    },
    transclude: true,
    // @ngInject
    controller: function TabsController($scope){

        this.handleClickBack = function handleClickBack() {
            this.backButton();
        };

    },
    name: 'nxNavbarView',
    templateUrl: 'angular/app/modules/common/ui_components/nx_navbar/nx_navbar_view.html',
}