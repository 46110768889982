(function () {
  "use strict";

  Controllers.FlowsTemplateGalleryController = class FlowsTemplateGalleryController extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, AppStates, $state, OnboardingService, FlowsBetaUserTypeService, $location,
                $stateParams, UsersManager, Gon, AnalyticsService) {
      super($scope, $injector);
      this.__objectType = 'FlowsTemplateGalleryController';
      this.$location = $location;
      this.$stateParams = $stateParams;
      this.UsersManager = UsersManager;
      this.Gon = Gon;
      this.AnalyticsService = AnalyticsService;
      this.currUser = this.UsersManager.getCurrUser();

      this.notifyIterable();

      OnboardingService.onFlowsTemplateGalleryVisited();

      if (!FlowsBetaUserTypeService.hasFlows) {
        $state.go(AppStates.root_core_navigation_templates);
      }

        this.queryParamsToStateParams(['preview', 'search', 'filters', 'industryFilters', 'hardFiltering']);
    }

      notifyIterable() {
        let data = {
            email: this.currUser.email,
            source: 'template_gallery'
        };

        this.AnalyticsService.reportIterableEvent('visited_template_gallery', data, this.currUser);
    }

    queryParamsToStateParams(paramNames = []) {
        const queryParams = {};

        paramNames.forEach(paramName => {
            if (this.$stateParams[paramName]) {
                queryParams[paramName] = this.$stateParams[paramName];
            }
        });

        if (Object.keys(queryParams).length) {
            this.$location.search(queryParams);
        }
    }
  };

}());
