(function () {
    'use strict';

    // @ngInject
    function ConfirmFileExpirationControllerCtor ($scope, $injector, $modalInstance, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfirmFileExpirationController';
        AnalyticsService.trackPageView(this, 'View');

        this.$modalInstance = $modalInstance;
    }

    Controllers.ConfirmFileExpirationController = Class(Controllers.BaseController, {
        constructor: ConfirmFileExpirationControllerCtor,

        proceed: function proceed(result) {
            this.$modalInstance.close(result);
        }

    });

}());
