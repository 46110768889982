(function () {
    'use strict';

    // @ngInject
    Services.qualtrics = function qualtrics($window, ThirdPartyLoaderService, AppConfigService, UsersManager) {
        function loadQualtrics() {
            if(typeof $window.QSI === "undefined") {
                $window.QSI = { config: {externalReference: UsersManager.getCurrUser()._id}}
            }
            if (!document.getElementById('ZN_07IS9IWJgvpEhVQ')) {
                console.log('qualtrics div added');
                const qualtricsDiv = document.createElement('div');
                qualtricsDiv.id = 'ZN_07IS9IWJgvpEhVQ';
                qualtricsDiv.innerHTML = "<!--DO NOT REMOVE-CONTENTS PLACED HERE-->";
                document.body.appendChild(qualtricsDiv);
            }

            if (AppConfigService.isProduction()) {
                // prod https://honeybook.qualtrics.com/DX/ZonesSection/EditUserPermissions?ContextZone=ZN_07IS9IWJgvpEhVQ / Published version
                (function(){var g=function(e,h,f,g){
                    this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
                    this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
                    this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
                    this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
                    this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
                    try{(new g(100,"r","QSI_S_ZN_07IS9IWJgvpEhVQ","https://zn07is9iwjgvpehvq-honeybook.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_07IS9IWJgvpEhVQ")).start()}catch(i){}})();
            } else {
                console.log('qualtrics is loaded in dev mode');
                // https://honeybook.qualtrics.com/DX/ZonesSection/EditUserPermissions?ContextZone=ZN_07IS9IWJgvpEhVQ / Editing Version (Testing)
                (function(){var g=function(e,h,f,g){
                    this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
                    this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
                    this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
                    this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
                    this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
                    try{(new g(100,"r","QSI_S_ZN_07IS9IWJgvpEhVQ","https://zn07is9iwjgvpehvq-honeybook.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_07IS9IWJgvpEhVQ&Q_VERSION=0")).start()}catch(i){}})();
            }
        }

        return ThirdPartyLoaderService.loadCallbackPromise(loadQualtrics, function () {});
    };
}());
