(function () {
    "use strict";


    // @ngInject
    function YouTubeAPIServiceCtor($window, $q, $timeout) {

        this.$window = $window;
        this.$q = $q;
        this.$timeout = $timeout;

        this.isYoutubeAPIReady = false;
        this.isYoutubeAPILoading = false;
        this.loadYTPromise = null;
    }

    Services.YouTubeAPIService = Class(function () {

        return {

            constructor: YouTubeAPIServiceCtor,

            _loadYoutubeScript: function _loadYoutubeScript() {
                /* taken from YouTube API: https://developers.google.com/youtube/iframe_api_reference */
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            },

            _init: function _init() {

                // create promise
                var deferred = this.$q.defer();
                this.isYoutubeAPILoading = true;

                var timer = this.$timeout(function() {
                    //if 30 seconds passed, with no onYouTubeIframeAPIReady, fire error.
                    deferred.reject('timeout');
                }, 30000);

                // load script (only one time)
                this._loadYoutubeScript();

                // subscribe once
                this.$window.onYouTubeIframeAPIReady = function onVideoIframeAPIReady() {
                    this.$timeout.cancel(timer);
                    this.isYoutubeAPIReady = true;
                    this.isYoutubeAPILoading = false;
                    deferred.resolve(true);
                }.bind(this);

                return deferred.promise;
            },

            _createPlayer: function _createPlayer(playerId, videoOptions) {
                return new YT.Player(playerId, videoOptions);
            },

            createPlayer: function createPlayer(playerId, videoOptions) {
                return this.$q(function(resolve, reject) {
                    if (this.isYoutubeAPIReady) {
                        resolve(this._createPlayer(playerId, videoOptions));
                    } else {
                        if(!this.isYoutubeAPILoading) {
                            this.loadYTPromise = this._init();
                        }
                        this.loadYTPromise
                            .then(function() {
                                resolve(this._createPlayer(playerId, videoOptions));
                            }.bind(this))
                            .catch(function(reason) {
                                this.isYoutubeAPILoading = false;
                                reject(reason);
                            }.bind(this));
                    }
                }.bind(this));
            }
        };
    });
}());