(function () {
    'use strict';

    Services.TemplatesImportService = class TemplatesImportService {
        // @ngInject
        constructor(UsersManager, FlowsBetaUserTypeService, $window, Constants,
                    AnalyticsService, PopupMessageService, $state, AppStates, TemplatesRepositoryManager, _) {
            this.UsersManager = UsersManager;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.$window = $window;
            this.Constants = Constants;
            this.AnalyticsService = AnalyticsService;
            this.PopupMessageService = PopupMessageService;
            this.$state = $state;
            this.AppStates = AppStates;
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this._ = _;
        }

        init() {
            if (this.UsersManager.getCurrUser()._id) {
                this.importTemplates();
            }

            this.UsersManager.on('loggingIn', this.importTemplates.bind(this));
            this.UsersManager.on('loggingOut', this.clearTemplateImportData.bind(this));
        }

        clearTemplateImportData() {
            this.$window.localStorage.removeItem(this.Constants.storage.FLOW_TEMPLATES);
        }

        importTemplates() {
            const importTemplatesFilterJson = this.$window.localStorage.getItem(this.Constants.storage.FLOW_TEMPLATES);
            if (importTemplatesFilterJson && Object.keys(importTemplatesFilterJson).length > 0) {
                const importTemplatesFilter = JSON.parse(importTemplatesFilterJson);
                const analyticsData = {
                    source: 'marketing'
                };
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.importing_templates, analyticsData);
                if (!this.FlowsBetaUserTypeService.hasFlows) {
                    this.PopupMessageService.showAlert(
                        this.PopupMessageService.severityTypes.info,
                        'TEMPLATES_PAGE.FLOW_TEMPLATE_IMPORT_REDIRECT_ALERT.DESCRIPTION',
                        null,
                        'close',
                        'extra-z-index'
                    );
                    this.$state.go(this.AppStates.root_core_navigation_flows_template_gallery, {
                        isRedirectedAfterTemplateImport: true
                    });
                    return this.clearTemplateImportData();
                }
                const currUser = this.UsersManager.getCurrUser();
                this.TemplatesRepositoryManager.importTemplates(currUser.company, importTemplatesFilter)
                    .then(() => {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.importing_templates, analyticsData);
                        var showConfirmation = this._.contains([this.AppStates.root_core_navigation_home], this.$state.current.name);
                        if(showConfirmation) {
                            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.success, 'New template added to your account', 'Browse templates', 'Close')
                                .then(() => {
                                    this.$state.go(this.AppStates.root_core_navigation_my_templates, {});
                                });
                        }
                    })
                    .catch((resp) => {
                        let errorMessage = "There was a problem loading a template.";
                        if (resp.data && resp.data.error_type && resp.data.error_type === 'HBUserError') {
                            errorMessage = errorMessage + ' ' + resp.data.error_message;
                        }
                        this.PopupMessageService.showErrorAlert(errorMessage);
                    })
                    .finally(() => {
                        this.clearTemplateImportData();
                    });
            }
        }
    };
})();
