(function () {
    'use strict';

    class InteractiveOnboardingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $timeout,
            $translate,
            AnalyticsService,
            DeviceService,
            OnboardingService,
            SetupGuideService,
            UiPersistenceService,
            $,
            _,
            UsersManager,
            Enums
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingController';

            this.$ = $;
            this.$timeout = $timeout;
            this.$scope = $scope;
            this.$translate = $translate;
            this._ = _;
            this.DeviceService = DeviceService;
            this.OnboardingService = OnboardingService;
            this.SetupGuideService = SetupGuideService;
            this.AnalyticsService = AnalyticsService;
            this.UsersManager = UsersManager;
            this.Enums = Enums;

            this.UiPersistenceService = UiPersistenceService;

            this.isLoading = true;
            this.isMarkedComplete = false;

            this.stepTranslationMap = {
                account: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT',
                branding: 'INTERACTIVE_ONBOARDING.STEP_BRANDING',
                services: 'INTERACTIVE_ONBOARDING.STEP_SERVICES',
                client_side: 'INTERACTIVE_ONBOARDING.STEP_CLIENT_SIDE',
                subscribe: 'INTERACTIVE_ONBOARDING.STEP_SUBSCRIBE',
                templates: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATES',
                account_setup: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP',
                contact_form: 'INTERACTIVE_ONBOARDING.STEP_CONTACT_FORM',
                bank_info: 'INTERACTIVE_ONBOARDING.STEP_BANK_INFO',
            };

            this.wizardStepKeys = [
                'account',
                'branding',
                'services',
                'client_side'
            ];

            this.listStepKeys = [
                'subscribe',
                'templates',
                'account_setup',
                'contact_form',
                'bank_info'
            ];

            this.user = this.UsersManager.getCurrUser();

            $scope.$watch('interactiveOnboardingVm.OnboardingService.onboardingSequence',
                (newSequence) => {
                    if (newSequence) {
                        this.currentStep = newSequence.current_step || {};

                        this.allSteps = newSequence.steps;

                        this.wizardSteps =
                            newSequence.steps
                                .filter(step => this.wizardStepKeys.includes(step.key));

                        this.listSteps =
                            newSequence.steps
                                .filter(step => this.listStepKeys.includes(step.key));

                        const wizardStepsAreComplete = this._.every(
                            this.wizardSteps, wizStep => wizStep.status === 'complete'
                        );

                        this.allSteps = this.wizardSteps.concat(this.listSteps);

                        this.shouldSeeList = wizardStepsAreComplete;
                        this.OnboardingService.setOnboardingPhaseOneComplete(wizardStepsAreComplete);
                        this.firstPendingStep = this.getFirstPendingStep(this.allSteps);
                        this.isSequenceComplete = newSequence.is_sequence_complete;
                        this.initializeMainView();
                    }
                }
            );

        }

        initializeMainView() {
            const $slideTarget = this.$('.js-interactive-onboarding-slide-target');
            if (this.isCollapsed) {
                $slideTarget.hide();
            }
            this.$timeout(() => {
                this.isLoading = false;
            }, 1000);
        }

        handleCollapse() {
            if (this.DeviceService.nxSmallBreakpoint()) {
                return;
            }

            this.isCollapsed = !this.isCollapsed;
            var $slideTarget = this.$('.js-interactive-onboarding-slide-target');

            if (this.isCollapsed) {
                $slideTarget.slideUp(500);
            } else {
                this.currentStep = this.getFirstPendingStep(this.allSteps);
                $slideTarget.slideDown(500);
            }

            this.SetupGuideService.setPersistenceHomeOnboardingCard({ isCollapsed: this.isCollapsed });
        }

        slideUpOnboardingContainer() {
            var $slideTarget = this.$('.js-interactive-onboarding-container');

            this.$timeout(() => {
                $slideTarget.slideUp(500);
            }, 1000);
        }

        handleMarkedComplete() {
            this.isMarkedComplete = true;
            this.slideUpOnboardingContainer();
            this.SetupGuideService.setPersistenceHomeOnboardingCard({ hasMarkedComplete: true });
            this.isCollapsed = true;
        }

        getFirstPendingStep(steps) {
            return steps.find(step => step.status === 'pending');
        }

        getFirstPendingStepTitle() {
            let titleTranslationString = 'INTERACTIVE_ONBOARDING._FINAL_HEADER_ACTION_TITLE_';

            if (
                this.firstPendingStep
                && this.stepTranslationMap[this.firstPendingStep.key]
            ) {
                titleTranslationString = this.stepTranslationMap[this.firstPendingStep.key] + '._HEADER_TITLE_VERBOSE_';
            }

            return titleTranslationString;
        }

        completeMwebWizard() {
            this.isMarkedComplete = true;
            this.slideUpOnboardingContainer();
            this.SetupGuideService.setPersistenceHomeOnboardingCard({ hasClickedCompleteMwebWizard: true });
        }

    };

    Components.InteractiveOnboarding = {
        bindings: {
            isCollapsed: '='
        },
        controller: InteractiveOnboardingController,
        controllerAs: 'interactiveOnboardingVm',
        name: 'hbInteractiveOnboarding',
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/interactive_onboarding.html',
        bindToController: true,
    };

}());
