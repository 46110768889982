(function () {
    "use strict";

    // @ngInject
    function DoodleServiceConstructor(Gon, Enums, _) {
        this.Gon = Gon;
        this.Enums = Enums;
        this._ = _;
    }

    Services.DoodleService = Class(function() {

        return {
            constructor: DoodleServiceConstructor,

            isDoodle: function isDoodle(name) {
                if(!this.validateDoodle(name)) {
                    return;
                }

                return this.Gon.doodle === name.toLowerCase();
            },

            getDoodle: function getDoodle() {
                var doodle = this.Gon.doodle;

                if(!this.validateDoodle(doodle)) {
                    return;
                }

                return doodle;
            },

            validateDoodle: function validateDoodle(doodle) {
                return (
                    doodle &&
                    this._.contains(this._.values(this.Enums.doodles), doodle));
            }
        };
    });
}());
