(function () {
    "use strict";

    // @ngInject
    function EventSpacesControllerCtor($scope, $injector, $q, UiPersistenceService, AppStates,
                                       EventService, UsersManager, $modalInstance, event, isNewEvent,
                                       CompaniesManager, EventsManager, AnalyticsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EventSpacesController';
        this.EventService = EventService;
        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.EventsManager = EventsManager;
        this.$modalInstance = $modalInstance;
        this.$q = $q;
        this.UiPersistenceService = UiPersistenceService;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;

        this.currUser = this.UsersManager.getCurrUser();
        this.isVenue = this.currUser.isVenueVendor(); // ??
        this.event = event;
        this.isNewEvent = !!isNewEvent;
        this.startedSpacesCount = 0;
        this.checkedSpaces = false;

        this.setSpaces();

        if (this.event.event_date) {

            this.EventsManager.getConflictingEventsByDate(this.event, true).then(function success(conflictingEvents) {
                conflictingEvents.forEach(this.eventConflictInSpace.bind(this));

            }.bind(this));
        }

        this.AnalyticsService.trackPageView(this, 'EventSpaces', {is_new_vent: this.isNewEvent});
    }

    Controllers.EventSpacesController = Class(Controllers.BaseController, {

        constructor: EventSpacesControllerCtor,
        goToCalendar: function goToCalendar() {
            var args = {
                is_new_event: this.isNewEvent
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.spaces_clicked_on_goto_cal, args);
            this.goToState(this.AppStates.root_core_navigation_calendar);
            this.$modalInstance.close({goToCalendar: true});
        },

        addOrRemoveSpace: function addOrRemoveSpace(spaceId) {
            this.selectedSpaces[spaceId] = !this.selectedSpaces[spaceId];
        },

        setUI: function setUI() {

            this.skipOrCloseButton = 'FREQUENT_BUTTONS._DONE_';

            if (this.isNewEvent) {
                this.skipOrCloseButton = this.hasSpaces ? 'FREQUENT_BUTTONS._NEXT_' : 'PROJECT.SPACES._SKIP_';
            }
        },

        updateTimesViewed: function () {
            if (!this.hasSpaces && this.isNewEvent) {
                var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.spacesPopupViewed);
                this.timesSeenPopup = uiPersistenceData && uiPersistenceData.times ? uiPersistenceData.times : 0;
                this.timesSeenPopup += 1;
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.spacesPopupViewed, {times: this.timesSeenPopup});

                // if (this.timesSeenPopup >= 3){
                //     // skipped more than 3 times
                //     this.skipOrClose();
                // }
            }
        },

        setSpaces: function () {
            this.gettingSpaces = true;

            this.companySpaces = this.currUser.company.company_spaces;

            this.CompaniesManager.getCompanySpaces(this.currUser.company).then(function () {
                this.companySpaces = this.currUser.company.company_spaces;
                this.conflictsByCompanySpaces = {};
                this.selectedSpaces = [];
                this.existingSpaces = [];
                this.gettingSpaces = false;
                this.checkedSpaces = true;
                this.hasSpaces = this.companySpaces.length > 0;

                // for existing events with spaces
                if (this.event.spaces) {
                    this.event.spaces.forEach(function (space) {
                        this.selectedSpaces[space.space_id] = true;
                        this.existingSpaces[space.space_id] = true;
                        this.startedSpacesCount += 1;
                    }.bind(this));
                }


                this.updateTimesViewed();

                this.setUI();


            }.bind(this));
        },

        eventConflictInSpace: function (event) {
            if (!event.spaces) {
                return;
            }

            event.spaces.forEach(function (space) {
                if (!this.conflictsByCompanySpaces[space.space_id]) {
                    this.conflictsByCompanySpaces[space.space_id] = [];
                }

                this.conflictsByCompanySpaces[space.space_id].push(event);
            }.bind(this));
        },

        addSpace: function (spaceId) {
            this.selectedSpaces[spaceId] = true;
            return this.EventsManager.addSpace(this.event, spaceId);
        },

        removeSpace: function (spaceId) {
            this.selectedSpaces[spaceId] = false;
            return this.EventsManager.removeSpace(this.event, spaceId);
        },

        skipOrClose: function skipOrClose() {

            var promises = [];

            this.endedSpacesCount = 0;
            this.companySpaces.forEach(function (space) {
                if (this.selectedSpaces[space._id]) {
                    this.endedSpacesCount += 1;
                }

                if (this.selectedSpaces[space._id] && !this.existingSpaces[space._id]) {
                    promises.push(this.addSpace(space._id));
                } else if (!this.selectedSpaces[space._id] && this.existingSpaces[space._id]) {
                    promises.push(this.removeSpace(space._id));
                }
            }.bind(this));

            var args = {
                is_new_event: this.isNewEvent,
                start_number_of_spaces: this.startedSpacesCount,
                end_number_of_spaces: this.endedSpacesCount,
            };

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.spaces_clicked_on_done, args);

            this.updatingSpaces = true;
            this.$q.all(promises).then(function () {
                this.updatingSpaces = true;
                this.$modalInstance.close({spaces: this.companySpaces});
            }.bind(this));
        },

        cancel: function cancel() {
            this.$modalInstance.dismiss();
        }
    });
}());
