(function () {
    'use strict';

    Controllers.ConnectCalendarController = class ConnectCalendarController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $q, UsersManager, UserService, AnalyticsService, PopupMessageService, AppStates, options, ModalService) {
            super($scope, $injector);
            this.__objectType = 'ConnectCalendarController';

            AnalyticsService.trackPageView(this, 'CalendarSettingsView');

            this.$modalInstance = $modalInstance;
            this.$q = $q;
            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this.PopupMessageService = PopupMessageService;
            this.AppStates = AppStates;
            this.ModalService = ModalService;

            this.MAX_SYNCED_EMAILS = 10;

            this.user = this.UsersManager.getCurrUser();
            this.options = options || {};

            this.init();
        }

        init() {

            this.loading = true;
            this.isConnected = this.options.isConnected;

            if(!this.isConnected) {
                this.loading = false;
                return;
            }

            // fetch the calendar provider
            let calendarProviderPromise = this.UsersManager.getCalendarProvider(this.user);

            // fetch the imported calendars list
            let calendarListPromise = this.UsersManager.getIntegratedCalendarsList();

            // on both requests resolved
            this.$q.all([calendarProviderPromise, calendarListPromise]).then(function(responses) {

                let provider = responses[0].data.calendar_provider;

                // save the main google account email
                this.mainGoogleAddress = provider.calendar_importer.account_email;

                // set the toggles values
                this.syncHbToCalendar = provider.shares_enabled;
                this.syncCalendarToHb = !!(provider.calendar_importer && provider.calendar_importer.enabled);

                // set emails list
                this.syncedEmailsList = provider.google_calendar_shares || [];
                this.importedCalendars = responses[1].data;

                // find the primary calendar and make that one automatically selected
                this.importedCalendars.forEach(function iterator(calendar) {
                    if(calendar.is_primary) {
                        calendar.imported = true;
                    }
                });

                // set the main calendar first
                this.importedCalendars.sort((calA, calB) => {
                    if(calA.imported !== calB.imported) {
                        return calA.imported ? -1 : 1;
                    }
                    return 0;
                });

                // save provider
                this.googleCalendarProvider = provider;
                this.loading = false;

            }.bind(this))
            .catch(function(error) {

                // try to figure out which error occurred
                let errorType = error.data.error_type;

                // token revoked error
                if(errorType === 'HBCalendarImporterTokenRevokedError') {

                    // TODO: Handle token revoked error
                }

                this.requireToReconnect = true;
                this.isConnected = false;
                this.loading = false;

            }.bind(this));
        }

        close () {
            let data = this._getData();
            this.$modalInstance.close(data);
        }

        dismiss () {
            let data = this._getData();
            if (this.options.commingFromGoogleAuth){
                this.disconnect();
            }else{
                this.$modalInstance.dismiss(data);
            }
        }

        _getData() {
            if(this.isCalendarDisconnected) {
                return { isCalendarDisconnected: this.isCalendarDisconnected};
            } else if (this.isConnected) {
                return {
                    showHbEventsOnCalendar: this.syncHbToCalendar,
                    sharedWithEmailsCollection: this.syncedEmailsList,
                    showCalendarEventsOnHb: this.syncCalendarToHb,
                    listOfCalendersToImport: this.importedCalendars.filter((cal) => cal.imported === true)
                };
            } else {
                return {};
            }
        }

        save () {
            this.close();
        }

        connect () {
            if (this.user.isNylasCalendarIntegrationEnabled()) {

                this.close(); // Close reconnect modal and open select email provider

                this.ModalService.openSelectEmailProviderModal('calendar')
                    .then(function (res) {
                        if (res.isGoogle) {
                            this.UserService.connectToAGoogleAccount("", 'calendar');
                        }
                    }.bind(this));
            } else {
                this.UserService.connectToAGoogleAccount("", 'calendar');
            }
        }

        disconnect () {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to disconnect your integrated calendar?').then(function yes() {
                    this._disconnect();
            }.bind(this));
        }

        _disconnect () {
            this.loading = true;
            return this.UsersManager.deleteCalendarProvider(this.user).then(function() {
                this.options.commingFromGoogleAuth = false;
                this.options.isConnected = false;
                this.isConnected = false;
                this.isCalendarDisconnected = true;
                this.loading = false;
                this.close();
            }.bind(this))
            .catch(function() {
                this.loading = false;
            }.bind(this));
        }

        reconnect() {
            this._disconnect().then(function() {
                this.connect();
            }.bind(this));
        }

        addEmail (email) {

            // set submitted so error is shown
            this.addEmailForm.$setSubmitted();

            if (this.addEmailForm.$valid && this.syncedEmailsList.length < this.MAX_SYNCED_EMAILS) {
                this.syncedEmailsList.push({email: email});
                this.newEmail = "";
                this.showNewEmail = false;
                this.addEmailForm.$setPristine();
            }
        }

        removeEmail (index) {
            this.syncedEmailsList.splice(index, 1);
        }
    };

}());

// /**
//  * connectToAGoogleAccount
//  * @param preferredGoogleAccountEmail preferred since the user starts the authentication with this email
//  * but he may end it up with another one (he can cahnge it on google's screens)
//  */
// connectToAGoogleAccount: function eyalGetMygoogleCal(preferredGoogleAccountEmail) {
//     this.UserService.connectToAGoogleAccount(preferredGoogleAccountEmail, 'calendar');
// },


// analyticsProperties () {
//     return {
//         connectState: this.activeConnectionState
//     };
// }

// self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'REPORTS.CALENDAR.SYNC._ERROR_GETTING_DATA_');
