// @ngInject

Models.FileDynamicContentModel = function FileDynamicContentModel(BaseModel) {

    return Class(BaseModel, {

        constructor: function constructor() {
            this.constructor.$super.call(this);
            this.__className = 'FileDynamicContentModel';

            this.cachable = false;
        },

        mapChilds: function mapChilds() {
            return {
                file_sections: 'FileSectionsCollection',
                selection_summary_section: 'FileSectionModel'
            };
        },

        afterMixingFrom: function afterMixingFrom(object) {

        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        bubbleUp: function bubbleUp() {
            return [
                'addSection',
                'updateSection',
                'deleteSection',
                'updateSectionsOrder',
                'addBlock',
                'updateBlock',
                'deleteBlock',
                'updateSectionBlocksOrder',
                'updateProposalOnBlock',
                'addPackageToBlock',
                'updatePackageOnBlock',
                'updatePackageQuantityOnBlock',
                'deletePackageFromBlock',
                'updatePackageServicesOrderOnBlock',
                'addServiceToBlock',
                'updateServiceOnBlock',
                'updateServiceQuantityOnBlock',
                'updateServiceImageOnBlock',
                'updateProposalSelection',
                'deleteServiceFromBlock',
                'updateProposalOrder',
                'moveServiceOnBlock',
                'updateComponent',
                'answerQuestion',
                'getRootModelType',
                'getRootModel',
            ];
        },

        isServiceSelectionsValid: function isServiceSelectionsValid() {
            var sections = this.file_sections;
            var valid = sections.every(function(section) {
                return section.isSelectionValid();
            });
            return valid;
        },

        isQuestionsValid: function isQuestionsValid() {
            return this.file_sections.every(function(section) {
                return section.file_blocks.every(function (block) {

                    // if no block component it's valid
                    if(!block.block_component || (block.block_component.type !== 'form' && block.block_component.type !== 'proposal')) {
                        return true;
                    }

                    if(block.block_component.type === 'proposal') {
						var proposal = block.block_component.proposal;
						var service = proposal.package_services[0];

						// amount
						if(service.price_per_unit <= 0) {
							return false;
						}

						if(proposal.enable_custom_amount && proposal.enable_amount_limit) {
							if(proposal.amount_limit_min <= 0 || proposal.amount_limit_max <= proposal.amount_limit_min) {
								return false;
							}

							if(service.price_per_unit < proposal.amount_limit_min ||
								service.price_per_unit > proposal.amount_limit_max) {
								return false;
							}
						}

						if(!!service.discount_type) {
							if(!service.discount || service.discount < 0) {
								return false;
							}
							

							if(service.discount_type === 'relative' && (service.discount < 1 || service.discount > 100)) {
								return false;
							}

							if(service.discount_type === 'absolute' && service.discount > service.price) {
								return false;
							}
						}

						return true;
					}

                    return block.block_component.questions.every(function (question) {
                        return question.isValid();
                    });
                });
            });
        },

        isQuestionsAnswersValid: function isQuestionsAnswersValid() {
            return this.file_sections.every(function(section) {
                return section.file_blocks.every(function (block) {

                    // if no block component questions it's valid
                    if(!block.block_component || block.block_component.type !== 'form') {
                        return true;
                    }

                    return block.block_component.questions.every(function (question) {
                        return question.isAnswerValid();
                    });
                });
            });
        },

        getConnectedQuestionBlock: function getConnectedQuestionBlock(connectedProperty) {
            var retBlock = null;
            this.file_sections.forEach(function(section) {
                if (!retBlock) {
                    section.file_blocks.forEach(function(block) {
                        if(!retBlock && block.block_component &&
                            block.block_component.type === 'form' &&
                            block.block_component.findConnectedQuestion(connectedProperty)) {
                            retBlock = block;
                        }
                    });
                }
            });
            return retBlock;
        },

        findConnectedQuestion: function findConnectedQuestion(connectedProperty) {
            var question = null;
            this.file_sections.forEach(function(section) {
                if (!question) {
                    section.file_blocks.forEach(function(block) {
                        if (!question) {
                            if(block.type === 'component' &&
                                block.block_component &&
                                block.block_component.type === 'form') {
                                    question = block.block_component.findConnectedQuestion(connectedProperty);
                            }
                        }
                    });
                }
            });
            return question;
        },

        getConnectedQuestionsInContent: function getConnectedQuestionsInContent() {

            // filter form blocks
            var questions = this.file_sections.map(function(section) {

                var _questions = [];
                section.file_blocks.forEach(function(block) {
                    if(block.type === 'component' &&
                        block.block_component &&
                        block.block_component.type === 'form') {
                        _questions = _questions.concat(block.block_component.getConnectedQuestions());
                    }
                });
                return _questions;

            });

            if(questions.length) {
                questions = questions.reduce(function(flat, toFlatten) {
                    return flat.concat(toFlatten);
                });
            }

            // map to keys array
            return questions.map(function(question) { return question.connected_property; });
        },


    });
};

