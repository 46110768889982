(function () {
    "use strict";


    // @ngInject
    function OnboardingBotManagerCtor(APIService, Routes, $q, AnalyticsService, ModalService, ModelFactory, UsersManager, EventService,
                                      $state, AppStates, FeaturesService, $translate, WorkspaceService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.AnalyticsService = AnalyticsService;
        this.ModalService = ModalService;
        this.$state = $state;
        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.FeaturesService = FeaturesService;
        this.EventService = EventService;
        this.$translate = $translate;
        this.WorkspaceService = WorkspaceService;

    }

    Services.OnboardingBotManager = Class(Services.BaseModelManager, {

        constructor: OnboardingBotManagerCtor,
        createOnboardingProject: function createOnboardingProject() {

            var url = this.Routes.v2_create_obot_project_path();
            return this.apiCreate(url);
        },

        getActionData: function getActionData(actionKey) {

            var clientBotActions = {
                send_brochure: {
                    title: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_BROCHURE._TITLE_"),
                    description: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_BROCHURE._DESCRIPTION_"),
                    primaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_BROCHURE._PRIMARY_CTA_"),
                    secondaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_BROCHURE._SECONDARY_CTA_"),
                    videoModalContentType : 'clientbot_inquiries'
                },
                select_brochure_package: {
                    title: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SELECT_BROCHURE_PACKAGE._TITLE_"),
                    description: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SELECT_BROCHURE_PACKAGE._DESCRIPTION_"),
                    primaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SELECT_BROCHURE_PACKAGE._PRIMARY_CTA_"),
                    secondaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SELECT_BROCHURE_PACKAGE._SECONDARY_CTA_"),
                    videoModalContentType : 'clientbot_brochures'
                },
                send_proposal: {
                    title: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_PROPOSAL._TITLE_"),
                    description: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_PROPOSAL._DESCRIPTION_"),
                    primaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_PROPOSAL._PRIMARY_CTA_"),
                    secondaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.SEND_PROPOSAL._SECONDARY_CTA_"),
                    videoModalContentType : 'clientbot_packages'
                },
                client_sign_and_pay: {
                    title: this.$translate.instant("ONBOARDING.BOT.ACTIONS.CLIENT_SIGN_AND_PAY._TITLE_"),
                    description: this.$translate.instant("ONBOARDING.BOT.ACTIONS.CLIENT_SIGN_AND_PAY._DESCRIPTION_"),
                    primaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.CLIENT_SIGN_AND_PAY._PRIMARY_CTA_"),
                    secondaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.CLIENT_SIGN_AND_PAY._SECONDARY_CTA_"),
                    videoModalContentType : 'clientbot_proposals'

                },
                whats_next: {
                    title: this.$translate.instant("ONBOARDING.BOT.ACTIONS.WHATS_NEXT._TITLE_"),
                    description: this.$translate.instant("ONBOARDING.BOT.ACTIONS.WHATS_NEXT._DESCRIPTION_"),
                    primaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.WHATS_NEXT._PRIMARY_CTA_"),
                    secondaryCtaText: this.$translate.instant("ONBOARDING.BOT.ACTIONS.WHATS_NEXT._SECONDARY_CTA_"),
                    videoModalContentType : 'clientbot_get_paid'
                }
            };

            var currStage= clientBotActions[actionKey];
            return Object.assign({}, currStage, {key: actionKey});
        },

        startOnboardingFlow: function startOnboardingFlow() {
            return this.createOnboardingProject().then(function (res) {
                var workspace = res.data;
                this.WorkspaceService.gotoWorkspace(workspace._id, workspace.event._id);
            }.bind(this));
        },

        isOnboardingBotActive: function isOnboardingBotActive() {
            var currUser =  this.UsersManager.getCurrUser();
            return currUser.isCompanyOwner();
        },

        isOnboardingBotActiveOnWS: function isOnboardingBotActiveOnWS(workspace) {
            return  (workspace.workspace_source === 'client_bot');
        },

        hasOnboardingBotAction: function hasOnboardingBotAction(workspace) {
            return workspace.onboarding_bot_actions && workspace.onboarding_bot_actions.length > 0;
        },

        doAction: function doAction(workspace, action) {
            var url = this.Routes.v2_do_obot_action_path(workspace._id);
            var data = {
                onboarding_bot_action: action
            };
            return this.apiCreate(url, data, workspace, true);
        }
    })
    ;
}());