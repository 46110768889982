/**
 *
 * This directive highlights the text of an input on click
 *
 */


(function () {
    'use strict';

    Directives.SelectOnClick = function SelectOnClick() {

        function SelectOnClickLink($scope, element) {
            element.on('click', function () {
                this.select();
            });
        }

        return {
            restrict: 'A',
            link: SelectOnClickLink
        };
    };


}());
