(function () {
    "use strict";

    // @ngInject
    function TimeTrackerViewAllModalControllerCtor($modalInstance, $scope, $injector, TimeTrackingManager,  _, AnalyticsService, workspace, project, viewState,
                                                   UsersManager, PopupMessageService, ModalService, DeviceService, $timeout, moment, EventsManager, TimeTrackingService, $stateParams, AppStates, Enums, FlowsBetaUserTypeService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerViewAllModalController';

        this.$modalInstance = $modalInstance;
        AnalyticsService.trackPageView(this, 'time_tracker_full_modal');


        this._ = _;
        this.UsersManager = UsersManager;
        this.TimeTrackingManager = TimeTrackingManager;
        this.PopupMessageService = PopupMessageService;
        this.ModalService = ModalService;
        this.DeviceService = DeviceService;
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.AppStates = AppStates;
        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.TimeTrackingService = TimeTrackingService;
        this.Enums = Enums;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;

        this.user = UsersManager.getCurrUser();
        this.viewState = viewState;

        this.timeTrackerEntryList = [];
        this.timeEntries = {};
        this.workspaceIsLoaded = false;
        this.showBackgroundLayer = false;
        this.requestInProgress = false;
        this.createInvoiceActive = false;
        this.showPreviouslyInvoiced = true;
        this.previouslyNonBillable = true;
        this.isMobile = DeviceService.checkIfMobileSize();

        this.EventsManager = EventsManager;


        if (workspace) {
            this.workspace = workspace;
            this.event = this.EventsManager.getEvent(this.workspace.event._id);
            this.setViewDefaults();
            this.headerBackCta = 'TIME_TRACKER.LABELS._BACK_TO_PROJECT_';
        }

        if (project) {
            this.event = project;
            this.setViewDefaults();
            this.headerBackCta = 'TIME_TRACKER.LABELS._BACK_TO_TIME_TRACKER_';
        }

    }

    Controllers.TimeTrackerViewAllModalController = Class(Controllers.BaseController, {
        constructor: TimeTrackerViewAllModalControllerCtor,

        setViewDefaults: function setViewDefaults() {
            this.event && this._fetchProjectEntries();
            this._pageViewState(this.viewState);
            this._setColumnFilters();
        },

        _setColumnFilters: function _setColumnFilters () {
            this.orderProperty = "+completed_on";
            this.currentColumn = "completed_on";
        },

        _fetchProjectEntries: function _fetchProjectEntries() {
            this.requestInProgress = true;

            return this.TimeTrackingManager.fetchForProject(this.user.company, this.event)
                .then(function(resp) {
                    this.timeTrackerEntryList.splice(0);
                    this.timeTrackerEntryList = resp.data;
                    this.requestInProgress = false;
                    this.workspaceIsLoaded = true;

            }.bind(this)).then(this._calculateTotals.bind(this));
        },

        _onEntryAdded: function _onEntryAdded(entry) {
            this.timeTrackerEntryList.push(entry);
            this._calculateTotals();
        },

        addNewEntry: function addNewEntry() {
            this.timeTracakerEntry = {};
            this.showBackgroundLayer = true;

            if (this.workspaceIsLoaded){
                this.ModalService.openTimeTrackerEntryModal(this.event._id, this.timeTracakerEntry, this.Enums.timeTrackerSource.all_entries_modal)
                    .then(function success(resp) {
                        this._onEntryAdded(resp.data);
                    }.bind(this))
                    .finally(function () {
                        this.showBackgroundLayer = false;
                    }.bind(this));
            }

            this.AnalyticsService.track(this, 'time_tracker_log_new',
                                                {   source: "time_tracker_full_modal",
                                                    type: "list",
                                                    app_path: "time_tracker_full_modal",
                                                    event: "time_tracker_log_new",
                                                    eventType: "click"} );

        },

        _deleteTimeTrackEntry: function _deleteTimeTrackEntry(entry) {

            this.TimeTrackingManager.deleteTimeTracking(entry._id).then(function success() {
                this._onEntryDeleted(entry);
            }.bind(this));

            this.AnalyticsService.track(this, 'time_tracker_entry_deleted',
                                                {   source: "time_tracker_full_modal",
                                                    app_path: "time_tracker_full_modal",
                                                    event: "time_tracker_entry_deleted",
                                                    eventType: "click"} );
        },

        _onEntryDeleted: function _onEntryDeleted(entry) {
            var index = this._.findIndex(this.timeTrackerEntryList, function(e) { return e._id === entry._id; });
            if (index !== -1) {
                this.timeTrackerEntryList.splice(index, 1);
            }
            this._calculateTotals();
        },

        confirmDeleteTimeTrackEntry: function confirmDeleteTimeTrackEntry(entry) {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'TIME_TRACKER.WARNING._MSG_',
                'TIME_TRACKER.WARNING._YES_DELETE_',
                'FREQUENT_BUTTONS._CANCEL_').then(function () {
                this._deleteTimeTrackEntry(entry);
            }.bind(this));
        },

        _onEntryUpdated: function _onEntryUpdated(entry) {
            var index = this._.findIndex(this.timeTrackerEntryList, function(e) { return e._id === entry._id; });
            this.timeTrackerEntryList[index] = this._.assign({}, this.timeTrackerEntryList[index], entry );
            this._calculateTotals();
        },


        _openTimeEntryModal: function _openTimeEntryModal(entry) {
            this.timeTracakerEntry = entry;
            this.showBackgroundLayer = true;

            this.ModalService.openTimeTrackerEntryModal(this.event._id, this.timeTracakerEntry, this.Enums.timeTrackerSource.all_entries_modal)
                .then(function success(resp) {
                    //user can delete from entry modal on mobile
                    this._.isEmpty(resp.data) ? this._onEntryDeleted(entry) : this._onEntryUpdated(resp.data);
                }.bind(this))
                .finally(function () {
                    this.showBackgroundLayer = false;
                }.bind(this));
        },


        editTimeTrackEntry: function editTimeTrackEntry(entry) {
            if (this.workspaceIsLoaded) {
                this._openTimeEntryModal(entry);
            }
        },

        _filterEntries: function _filterEntries(collection) {
            var timeTrackerCollection = {
                listNonBillable: [],
                listPreviouslyInvoiced: [],
                listUnfilter: []
            };

            collection.forEach(function (timeEntry) {
                if (timeEntry.invoiced_at) {
                    timeEntry.isInvoiceChecked = false;
                    timeTrackerCollection.listPreviouslyInvoiced.push(timeEntry);
                } else if (!timeEntry.billable) {
                    timeEntry.isInvoiceChecked = false;
                    timeTrackerCollection.listNonBillable.push(timeEntry);
                } else {
                    timeEntry.isInvoiceChecked = true;
                    timeTrackerCollection.listUnfilter.push(timeEntry);
                }
            });

            return timeTrackerCollection;
        },

        _calculateTotals: function _calculateTotals() {
            this.entriesTrackedHours = this.TimeTrackingService.getTotalHours(this.timeTrackerEntryList);

            if (this.DeviceService.checkIfMobileSize()) {
                this.timeEntries.listUnfilter = this.timeTrackerEntryList;

            } else {
                this.timeEntries = this._filterEntries(this.timeTrackerEntryList);
            }

        },

        formatDate : function formatDate (date) {
            return this.TimeTrackingService.formatDate(date);
        },

        formatDateShort: function formatDateShort(date) {
            return this.TimeTrackingService.formatDateShort(date);
        },

        handleClickColumnSort: function handleClickColumnSort(column) {
            var order = '';

            if (this.currentColumn === column) {
                this.sortDirectionReverse = !this.sortDirectionReverse;
                order = this.orderProperty.slice(0, 1) === '+' ? '-' : '+';

            } else {
                this.currentColumn = column;
                order = '+';
            }

            this.orderProperty = order + column;
        },

        mobileEditEntry: function mobileEditEntry(entry) {
            if (this.DeviceService.navLargeBreakPoint()) {
                this.editTimeTrackEntry(entry);
            }
        },

        downloadCSV: function downloadCSV($event) {
            this.downloadingCsv = true;
            this.TimeTrackingService.downloadCSV($event, this.user.company, this.event).finally(function handleFinally() {
                this.downloadingCsv = false;
            }.bind(this));
        },

        close: function close() {
            this.$modalInstance.close(this.timeTrackerEntryList);
        },

        setPageViewState: function (pageTitle, invoiceActive) {
            this.headerPageTitle = pageTitle;
            this.createInvoiceActive = invoiceActive;
        },

        activateCreateInvoice: function activateCreateInvoice() {
            this._pageViewState('create_invoice');
        },

        _pageViewState: function _pageViewState(currentState) {
            switch (currentState) {
                case ('view_all_entries'):
                    var title = this.DeviceService.checkIfMobileSize() ? 'TIME_TRACKER.LABELS._LOGGED_TIME_' : 'TIME_TRACKER.LABELS._ALL_LOGGED_TIME_';
                    this.setPageViewState(title, false);
                    break;
                case ('create_invoice'):
                    this.setPageViewState('TIME_TRACKER.LABELS._CREATE_NEW_INVOICE_', true);
                    break;
            }
        },

        getInvoicedEntries: function getInvoicedEntries() {
           return this.timeTrackerEntryList.filter(function (entry) { return entry.isInvoiceChecked === true; });
        },

        _handleInvoiceCreation: function _handleInvoiceCreation(promise, state, params, responseParamName){
            promise.then(function(response) {
                this.$modalInstance.dismiss();
                params[responseParamName] = response.data[responseParamName];
                this.goToState(state, params);
            }.bind(this)).finally(function () {
                this.requestInProgress = false;
            }.bind(this));
        },

        createInvoice: function createInvoice() {
            this.requestInProgress = true;
            // if user is a flipped member and can use flows - create invoice as flow - else create a file
            // also enable flows trialers (without files) to create invoice via flow
            var createInvoicePromise;
            var state;
            var params;
            var responseParamName;
            if (this.FlowsBetaUserTypeService.hasNewFlowExp) {
                createInvoicePromise = this.TimeTrackingManager.createFlow(this.event, this.getInvoicedEntries());
                state = this.AppStates.root_core_oneFlow;
                params = {action: 'edit'};
                responseParamName = 'flow_id';
            } else {
                createInvoicePromise = this.TimeTrackingManager.createFile(this.event, this.getInvoicedEntries())
                state = this.AppStates.root_core_workspaceFile;
                params =  {};
                responseParamName = 'workspace_file_id';
            }
            this._handleInvoiceCreation(createInvoicePromise, state, params, responseParamName);
        },

        togglePreviouslyInvoiced: function togglePreviouslyInvoiced() {
            this.showPreviouslyInvoiced = !this.showPreviouslyInvoiced;
        },

        togglePreviouslyNonBillable: function togglePreviouslyNonBillable() {
            this.previouslyNonBillable = !this.previouslyNonBillable;
        },

        hasSelectedEntry: function hasSelectedEntry() {
            return _.any(this.timeTrackerEntryList, function(e) { return e.isInvoiceChecked; });
        }

    });
}());
