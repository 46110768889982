
(function () {
    'use strict';

    class CalendlyIntegrationComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $state, AnalyticsService, ModalService, UsersManager, PopupMessageService, $timeout,
                    $window, UserService, UiPersistenceService, AppStates, FeaturesService, ReactModalService, FeatureRestrictionService) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsFieldVisibility';

            this.ModalService = ModalService;
            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this.PopupMessageService = PopupMessageService;
            this.$timeout = $timeout;
            this.$window = $window;
            this.UiPersistenceService = UiPersistenceService;
            this.AppStates = AppStates;
            this.$state = $state;
            this.AnalyticsService = AnalyticsService;
            this.FeaturesService = FeaturesService;
            this.ReactModalService = ReactModalService;
            this.FeatureRestrictionService = FeatureRestrictionService;

            this.isLoading = false;
            this.publicLink = '';
            this.userIsSubscribeToCalendly = false;
            this.user = UsersManager.getCurrUser();

            this.restrictedFeature = this.FeatureRestrictionService.features.calendly;
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

            const unsubscribeFeatureRestrictions = $scope.$watch('calendlyIntegrationVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
                this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
            }.bind(this));

            $scope.$on("$destroy", function () {
                unsubscribeFeatureRestrictions();
            });
        }

        $onInit() {

            // get calendly public link
            this.getCalendlyPublicLink();

        }

        getCalendlyPublicLink() {
            this.isLoading = true;
            this.UsersManager.getCalendlyPublicLink(this.userModel).then(function success(resp) {

                // set connected flag
                this.userIsSubscribeToCalendly = !!resp.data.public_link;

                //  generate link if connected
                if (this.userIsSubscribeToCalendly) {
                    this.publicLink = this.generatePublicLink(resp.data.public_link, this.userModel);
                }

            }.bind(this)).finally(function final() {
                this.isLoading = false;
            }.bind(this));
        }

        showCalendlyWarn() {
            return this.user.has_old_calendly_integration;
        }

        goToScheduler() {
            this.AnalyticsService.track(
                this,
                this.AnalyticsService.analytics_events.calendly_try_it_now,
                { current_plan: this.user.getPlanType() }
            );
            
            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.calendly_try_it_now);
            this.$state.go(this.AppStates.root_core_navigation_scheduling,{});
        }

        connect() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }

            this.UserService.connectToCalendlyAccount(this.$window.location.href);
        }

        generatePublicLink(calendlyLink, user) {
            return calendlyLink + "?utm_source=honeybook&utm_content=company-" + user.company._id + "-" + user._id;
        }

        disconnect() {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to disconnect?',
                'DISCONNECT','CANCEL', 'Disconnect Calendly').then(function yes() {
                this.confirmDisconnectFromCalendly();
            }.bind(this));
        }

        confirmDisconnectFromCalendly() {
            this.isLoading = true;
            this.UsersManager.disconnectCalendly(this.userModel)
                .then(function success() {
                    this.UsersManager.forceFetchCurrUser();
                    this.userIsSubscribeToCalendly = false;
                }.bind(this))
                .finally(function () {
                    this.isLoading = false;
                }.bind(this));
        }

        closeCalendlyMessage() {
            var calendlyDisconnectedMessage = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.calendlyDisconnectedMessage) || {};
            calendlyDisconnectedMessage.hide = true;
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.calendlyDisconnectedMessage, calendlyDisconnectedMessage);
        }

        _shouldShowCalendlyMessage() {
            var calendlyDisconnectedMessage = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.calendlyDisconnectedMessage) || {};
            return !calendlyDisconnectedMessage.hide;
        }


        onPublicLinkClick ($event) {
            $event.target.select();
        }

        onCopyLinkClick() {
            this.copying = true;
            this.$timeout(function() {
                this.copying = false;
            }.bind(this), 5000);
        }

    }

    Components.CalendlyIntegration = {
        bindings: {
            userModel: '<'
        },
        controller: CalendlyIntegrationComponent,
        controllerAs: 'calendlyIntegrationVm',
        name: 'hbCalendlyIntegration',
        templateUrl: 'angular/app/modules/core/features/settings/company/integrations/calendly/calendly_integration.html'
    };

}());
