(function () {
    "use strict";

    // @ngInject
    function TimeTrackerSampleModalControllerCtor($modalInstance, $scope, $injector, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimeTrackerSampleModalController';

        this.$modalInstance = $modalInstance;
        AnalyticsService.trackPageView(this, 'time_tracker_sample_modal');

        this.AnalyticsService = AnalyticsService;

    }

    Controllers.TimeTrackerSampleModalController = Class(Controllers.BaseController, {
        constructor: TimeTrackerSampleModalControllerCtor,

        dismiss: function dismiss() {
            this.$modalInstance.dismiss();
        },

    });
}());
