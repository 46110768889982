(function () {
    "use strict";

    Services.NetworkConnectionService = class NetworkConnectionService {

        // @ngInject
        constructor($window, _, UsersManager, AnalyticsService, DatadogRUMService, APIService) {
            this.$window = $window;
            this._ = _;
            this.UsersManager = UsersManager;
            this.AnalyticsService = AnalyticsService;
            this.DatadogRUMService = DatadogRUMService;
            this.APIService = APIService;
            // default debug on localhost
            // can use $injector to turn this on/off
            this.debug = window.location.hostname.indexOf("localhost") !== -1;
            this.onlineListener = () => {
                this._updateStatus('onlineListener');
            }

            this.offlineListener = () => {
                this._updateStatus('offlineListener');
            }

            this.lastApiCallSuccess = true;
            this.onApiError = () => {
                this.lastApiCallSuccess = false;
                this._updateStatus('onApiError');
            }

            this.onApiSuccess = () => {
                this.lastApiCallSuccess = true;
                this._updateStatus('onApiSuccess');
            }

            if (UsersManager.isLoggedIn()) {
                this.attach();
            }
            this.UsersManager.on('loggingIn', this.attach, this);
            this.UsersManager.on('loggingOut', this.detach, this);
        }

        attach() {
            console.debug('NetworkConnectionService: attach');

            // register network issues status event listener
            this.$window.addEventListener('online', this.onlineListener);
            this.$window.addEventListener('offline', this.offlineListener);

            // register api service listener
            this.APIService.on('success', this.onApiSuccess, this);
            this.APIService.on('error*', this.onApiError, this);


        }

        detach() {
            this.$window.removeEventListener('online', this.onlineListener);
            this.$window.removeEventListener('offline', this.offlineListener);

            this.APIService.off('success', this.onApiSuccess, this);
            this.APIService.off('error*', this.onApiError, this);
        }

        isBrowserOnline() {
            return navigator ?
                navigator.onLine :
                true; // best effort, assume its online if we can't detect it
        }

        isOfflineWithNetworkErrors() {
            return !this.isBrowserOnline() && !this.lastApiCallSuccess;
        }

        _updateStatus(caller) {

            const newStatus = {
                navigatorOnLine: this.isBrowserOnline(),
                lastApiCallSuccess: this.lastApiCallSuccess,
                isOfflineWithNetworkErrors: this.isOfflineWithNetworkErrors(),
                timestamp: new Date().toISOString(),
                caller: caller
            }
            if (!this.currStatus) {
                this.currStatus = newStatus;
                return; // first time
            }

            if (!this._.isEqual(this.currStatus.navigatorOnLine, newStatus.navigatorOnLine)) {
                console.log('how is it not equal?!', this.currStatus, newStatus);
                // Leaving this log here for debugging purposes in case we need to debug this in the future
                // if (this.debug) {
                //     console.log(`_updateStatus ${caller}`, {
                //         newStatus: newStatus,
                //         currStatus: this.currStatus
                //     });
                // }
                const msg =`Network status changed from ${this.currStatus.navigatorOnLine ? 'online' : 'offline'} to ${newStatus.navigatorOnLine ? 'online' : 'offline'}`;

                const context = {
                    oldStatus: this.currStatus,
                    newStatus: newStatus,
                    url: this.$window.location.href,
                    userAgent: this.$window.navigator.userAgent,
                    timestamp: new Date(),
                    caller: caller
                };

                this.currStatus = newStatus;

                this.DatadogRUMService.addError(new Error(msg), context);

                return true;
            }
            return false;
        }
    };
}());
