// @ngInject
Directives.FeatureFlagDirective = function FeatureFlagDirective($timeout, UsersManager) {
    return {
        restrict: 'A',
        link: function(scope, elem, attr) {
            elem.hide();
            let user = UsersManager.getCurrUser();
            let isFeatureDisplayed = angular.isDefined(attr.featureHidden) ? false : true;
            let shouldShowElement = user.isFeatureActive(attr.hbFeatureFlag) ? isFeatureDisplayed : !isFeatureDisplayed;
            if(shouldShowElement) {
                elem.show();
            } else {
                elem.remove();
            }
        }
    };
};