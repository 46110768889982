(function () {
    'use strict';

    // @ngInject
    function ImagesManagerCtor($q, APIService, Routes, CompaniesManager, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);

        this.Routes = Routes;
        this.CompaniesManager = CompaniesManager;
    }

    Services.ImagesManager = Class(Services.BaseModelManager, {
        constructor: ImagesManagerCtor,

        saveImageReposition: function saveImageReposition(image) {
            var url = this.Routes.v2_update_image_path(image._id);
            return this.apiUpdate(url, image);
        },

        updateCoverImageInCompany: function updateCoverImageInCompany(image) {
            var company = this.CompaniesManager.getCurrCompany(true);
            company.once('success', function() {
                company.cover_images.every(function everyImage(coverImage) {
                    if (image._id === coverImage._id) {
                        angular.extend(coverImage, image);
                        return false;
                    }
                });
            });
        },

        updateIconImageInCompany: function updateIconImageInCompany(image) {
            var currCompany = this.CompaniesManager.getCurrCompany();
            currCompany.icon_image = image;

            var fetchedCompany = this.CompaniesManager.getCurrCompany(true);
            fetchedCompany.once('success', function() {
                fetchedCompany.icon_image = image;
            });
        },

        resetImageRepoistion: function resetImageRepoistion(image){
            var url = this.Routes.v2_get_image_path(image._id);
            this.apiFetch(url).then(
                function success(resp){
                    var retImage = resp.data;
                    image.position_x = retImage.position_x;
                    image.position_y = retImage.position_y;
                    image.position_w = retImage.position_w;
                    image.position_h = retImage.position_h;
                },
                function error(resp){}
            );
        }
    });

}());
