
// @ngInject
Directives.FileReadDirective = function FileReadDirective() {
    return {
        restrict: 'A',
        scope: {
            hbFileRead: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.hbFileRead = loadEvent.target.result;
                    });
                }
                reader.readAsDataURL(changeEvent.target.files[0]);
            });
        }
    }
};
