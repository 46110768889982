Components.HbUsaFlagLogo = {
    //ngInject
    controller: function HbLogoComponentController() {
        this.$onInit = () => {
            this.fillColor = this.hbLogo.fillColor;
        }
    },
    name: 'hbUsaFlagLogo',
    templateUrl: 'angular/app/modules/common/ui_components/hb_logo/usa_flag/usa_flag_logo.html',
    require: {
        hbLogo: '^hbLogo'
    }
};
