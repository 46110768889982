(function () {
    'use strict';

    class ReactModalController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, ReactModalService) {
            super($scope, $injector);
            this.__objectType = 'ReactModalController';
            this.ReactModalService = ReactModalService;
        }
    }

    Components.ReactModal = {
        controller: ReactModalController,
        templateUrl: 'angular/app/modules/common/ui_components/react_modal/react_modal.html'
    };
})();
