(function () {
    "use strict";

    class AddBlockController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $timeout, Enums, AnalyticsService, $translate, QuestionService, QuestionsStateService, CompaniesManager) {
            super($scope, $injector);
            this.__objectType = 'AddBlockController';

            this.$timeout = $timeout;
            this.Enums = Enums;
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;
            this.QuestionService = QuestionService;
            this.QuestionsStateService = QuestionsStateService;
            this.company = CompaniesManager.getCurrCompany();

            // all optional tabs
            this.CONTENT =  {
                type: this.Enums.fileBuilderBlockTypes.content,
                name: 'DYNAMIC_CONTENT.ADD_BLOCK._CONTENT_',
                blocks: [
                    {
                        type: this.Enums.fileBuilderContentTypes.text,
                        name: 'BROCHURE.ADD_BLOCK._FULL_WIDTH_TEXT_',
                        icon: 'text'
                    },
                    {
                        type: this.Enums.fileBuilderContentTypes.two_column_text,
                        name: 'BROCHURE.ADD_BLOCK._2_COLUMN_TEXT_',
                        icon: 'two-column-text'
                    },
                    {
                        type: this.Enums.fileBuilderContentTypes.two_column_images,
                        name: 'BROCHURE.ADD_BLOCK._2_IMAGES_',
                        icon: 'two-column-images'
                    },
                    {
                        type: this.Enums.fileBuilderContentTypes.image,
                        name: 'BROCHURE.ADD_BLOCK._COVER_IMAGE_',
                        icon: 'image'
                    },
                    {
                        type: this.Enums.fileBuilderContentTypes.text_over_image,
                        name: 'BROCHURE.ADD_BLOCK._TEXT_ON_IMAGE_',
                        icon: 'text-over-image'
                    },
                    {
                        type: this.Enums.fileBuilderContentTypes.text_and_image,
                        name: 'BROCHURE.ADD_BLOCK._LEFT_ALIGNED_',
                        icon: 'text-and-image'
                    },
                ]
            };
            this.SERVICES = {
                type: this.Enums.fileBuilderBlockTypes.proposal,
                name: 'DYNAMIC_CONTENT.ADD_BLOCK._SERVICES_',
            };
            this.QUESTIONS = {
                type: this.Enums.fileBuilderBlockTypes.questions,
                name: 'DYNAMIC_CONTENT.ADD_BLOCK._QUESTIONS_',
                subCategories: [
                    {
                        type: this.Enums.questionsCategories.custom,
                        name: 'DYNAMIC_CONTENT.ADD_BLOCK._CUSTOM_',
                        blocks: [
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.two_fields,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._TWO_FIELDS_',
                                icon: 'two-fields'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.short_text,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._SHORT_TEXT_',
                                icon: 'short-text'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.long_text,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._LONG_TEXT_',
                                icon: 'long-text'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.drop_down,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._DROPDOWN_',
                                icon: 'drop-down'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.single_choice,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._SINGLE_CHOICE_',
                                icon: 'single-choice'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.multiple_choice,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._MULTIPLE_CHOICE_',
                                icon: 'multiple-choice'
                            },
                            {
                                type: 'component',
                                category: 'custom',
                                subType: this.Enums.customQuestions.date_picker,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._DATE_PICKER_',
                                icon: 'date-picker'
                            },
                        ]
                    },
                    {
                        type: this.Enums.questionsCategories.suggested,
                        name: 'DYNAMIC_CONTENT.ADD_BLOCK._SUGGESTED_',
                        blocks: [
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.full_name,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._FULL_NAME_',
                                icon: 'full-name'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.email,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._EMAIL_',
                                icon: 'email'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.phone_number,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PHONE_NUMBER_',
                                icon: 'phone-number'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.about,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._ABOUT_',
                                icon: 'about'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.lead_source,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LEAD_SOURCE_',
                                icon: 'lead-source'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.project_type,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_TYPE_',
                                icon: 'project-type'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.project_location,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LOCATION_',
                                icon: 'project-location'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.project_budget,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._BUDGET_',
                                icon: 'project-budget',
                                hide: this.company.shouldHideProjectField('budget')
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.project_date,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_DATE_',
                                icon: 'project-date'
                            },
                            {
                                type: 'component',
                                category: 'suggested',
                                subType: this.Enums.suggestedQuestions.guests,
                                name: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._GUESTS_',
                                icon: 'guests',
                                hide: this.company.shouldHideProjectField('guests')
                            },
                        ]
                    },
                ]
            };
        }

        $onInit() {

            // reset
            this.blocksList = [];

            // default title if not provided
            this.defaultTitle = this.$translate.instant('BROCHURE.ADD_BLOCK._TITLE_');

            // prepare allowed content
            this.contentCategories = [];
            if(this.modelType === 'contact_form') {
                this.contentCategories.push(this.CONTENT);
                this.contentCategories.push(this.QUESTIONS);

                this.onCategorySelect(this.QUESTIONS);
            } else if (this.modelType === 'gift_card' || this.modelType === 'credits') {
                this.contentCategories.push(this.CONTENT);
                this.contentCategories.push(this.QUESTIONS);

                this.onCategorySelect(this.CONTENT);
            } else if(this.modelType === 'brochure') {

                let questions = this.QUESTIONS;
                let availableQuestions = this.QuestionService.getAvailableQuestionTypes(this.modelType);

                // go over questions and filter required ones
                questions.subCategories.forEach(function(subCategory) {
                    let blocks = [];
                    subCategory.blocks.forEach(function(blockType) {
                        if(availableQuestions.indexOf(blockType.subType) > -1) {
                            blocks.push(blockType);
                        }
                    });
                    subCategory.blocks = blocks;
                });

                this.contentCategories.push(this.CONTENT);
                this.contentCategories.push(questions);
                this.contentCategories.push(this.SERVICES);

                // select the first one
                this.onCategorySelect(this.contentCategories[0]);
            } else {
                this.contentCategories.push(this.CONTENT);
                this.contentCategories.push(this.QUESTIONS);
                this.contentCategories.push(this.SERVICES);

                // select the first one
                this.onCategorySelect(this.contentCategories[0]);
            }
        }

        $onChanges() {

        }

        analyticsProperties() {
            return this.dynamicContentModel.getAnalyticsArgs();
        }

        onCategorySelect (category) {
            this.activeCategory = category;

            // Special handling for services
            // because they don't act like a tab
            if(this.activeCategory.type === this.Enums.fileBuilderBlockTypes.proposal) {
                this.onAddProposalBlock(category);
                return;
            }

            this.activeSubCategory = category.subCategories ? category.subCategories[0] : undefined;
            this.updateBlocksList();
        }

        onSubCategorySelect (subCategory) {
            this.activeSubCategory = subCategory;
            this.updateBlocksList();
        }

        updateBlocksList() {
            this.blocksList = this.activeSubCategory ?
                this.activeSubCategory.blocks :
                this.activeCategory.blocks;

            // when updating the block list, mark non addable questions
            if(this.activeSubCategory &&
               this.activeSubCategory.type === this.Enums.questionsCategories.suggested) {

                // exiting questions
                this.blocksList.forEach(function(blockType) {
                    blockType.disabled = this.QuestionsStateService.hasQuestion(blockType.subType);
                }.bind(this));
            }
        }

        onAddProposalBlock (category) {

            // trigger block type selection to add a block
            this.onSelectBlockType(category);
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.added_block,
                {block_type: this.sanitizeType(this.Enums.fileBuilderBlockTypes.proposal)});
        }

        onSelectBlockType(blockType) {
            if(!blockType.disabled) {
                this.onSelect({blockType: blockType});
            }
        }

        onContentHover($event, hover, blockType) {
            var targetElm = $($event.currentTarget);
            var nextElmSibilings = targetElm.nextAll('li');
            var prevElmSibilings = targetElm.prevAll('li');
            var inactive = 'add-block__content-item--inactive';
            var active = 'add-block__content-item--active';

            if (hover) {
                nextElmSibilings.removeClass(inactive).addClass(inactive);
                prevElmSibilings.removeClass(inactive).addClass(inactive);
                targetElm.addClass(active);
            } else {
                nextElmSibilings.removeClass(inactive);
                prevElmSibilings.removeClass(inactive);
                targetElm.removeClass(active);
            }
        }

        sanitizeType(type) {
            if (type) {
                return type.split('_').join(' ');
            }
        }

    }

    Components.AddBlock = {
        bindings: {
            modelType: '@?',
            dynamicContentModel: '=',
            onSelect: '&',
            onClose: '&?',
            addBlockTitle: '@?',

        },
        templateUrl: 'angular/app/modules/core/features/dynamic_content/add_block/add_block_directive.html',
        controller: AddBlockController,
        controllerAs: 'addBlockVm',
        name: 'hbAddBlock'
    };

}());



// {
//     type: this.Enums.setsQuestions.sets,
//     blocks: [
//         {
//             type: this.Enums.setsQuestions.basic,
//             name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._BASIC_',
//             setList: [
//                 {
//                     type: this.Enums.setsQuestions.project_type,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._PROJECT_TYPE_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_date,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._PROJECT_DATE_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_location,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._LOCATION_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_budget,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._BUDGET_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_guest_count,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._GET_COUNT_'
//                 },
//             ]
//         },
//         {
//             type: this.Enums.setsQuestions.project_details,
//             name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._PROJECT_DETAILS_',
//             setList: [
//                 {
//                     type: this.Enums.setsQuestions.project_type,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._PROJECT_TYPE_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_date,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._PROJECT_DATE_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_location,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._LOCATION_'
//                 },
//                 {
//                     type: this.Enums.setsQuestions.project_budget,
//                     name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._BUDGET_'
//                 },
//             ]
//         },
//         {
//             type: this.Enums.setsQuestions.company_type,
//             name: 'DYNAMIC_CONTENT.QUESTIONS.SETS._COMPANY_TYPE_',
//             setList: this.getVendorQuestions()
//         }
//     ]
// },

// getVendorQuestions: function getVendorQuestions() {
//     //for prototyping (will fetch questions by vendor types)
//     return [
//         {
//             type: 'how_are_you',
//             name: 'How are you?'
//         },
//         {
//             type: 'whats_your_name',
//             name: 'What\'s your name?'
//         },
//     ]
// },

// Not yet in use
// {
//     type: this.Enums.setsQuestions.sets,
//     name: 'DYNAMIC_CONTENT.ADD_BLOCK._SETS_',
// },

// isQuestionsOrContent() {
//     return (this.activeBlockType === this.Enums.fileBuilderBlockTypes.content) || (this.activeBlockType === this.Enums.fileBuilderBlockTypes.questions);
// }

// <ul ng-if="addBlockVm.isQuestionsOrContent()"
//     ng-class="addBlockVm.blockListRow ?
// 'add-block__content-list--row' : 'add-block__content-list'">

// not yet in use
// {
//     type: 'component',
//     subType: this.Enums.customQuestions.file_upload,
//     name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._FILE_UPLOAD_'
// },
// {
//     type: 'component',
//     subType: this.Enums.customQuestions.picture_choice,
//     name: 'DYNAMIC_CONTENT.QUESTIONS.CUSTOM._PICTURE_CHOICE_'
// },