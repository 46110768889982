(function () {
    "use strict";


    // @ngInject
    function TemplatesRepositoryManagerCtor(APIService, Routes, $q, ModelFactory) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
    }


    Services.TemplatesRepositoryManager = Class(Services.BaseModelManager, {

        constructor: TemplatesRepositoryManagerCtor,

        publishTemplate: function publishTemplate(templateId, repository, title, description, type, tags, publisherWebsite, imageId) {
            var url = this.Routes.v2_publish_template_path();
            var data = {
                template_id: templateId,
                repository: repository,
                type: type,
                title: title,
                description: description,
                publisher_website: publisherWebsite,
                tags: tags,
                header_image_cloudinary_public_id: imageId
            };
            return this.apiCreate(url, data);
        },

        fetchTemplateRecords: function fetchTemplateRecords(filters) {
            var url = this.Routes.v2_fetch_template_records_path();
            var data = {
                filters: filters
            };
            return this.apiFetch(url, data);
        },

        importTemplates: function importTemplates(company, filters) {
            var url = this.Routes.v2_import_templates_path();
            var data = {
                filters: filters
            };
            var promise = this.apiUpdate(url, data);
            promise.then(function updateCompany(resp) {
                company.mixinFrom(resp.data.company);
            });
            return promise;
        }

    });
}());
