
/**
 * The hb-breadcrumbs component
 *
 * This component shows breadcrumbs
 *
 */

(function () {
    'use strict';

    class BreadcrumbsComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, _) {
            super($scope, $injector);
            this.__objectType = 'BreadcrumbsComponentController';
            this._ = _;
        }

        onBreadcrumbItemClick(item, index) {
            if (this.onItemClick && angular.isFunction(this.onItemClick)) {
                this.onItemClick({index});
            }

            if (item.onClick && angular.isFunction(item.onClick)) {
                item.onClick();
            }
        }

        getLastDisplayNameFromStack() {
            if (this.hasBack()) {
                return this.stack[this.stack.length - 2].displayText;
            }
            return this.stack[0].displayText;
        }

        hasBack() {
            return (this.stack.length >= 2);
        }

        onSingleBreadcrumbItemClick() {
            var item = this.stack[this.stack.length - 2];
            if (this.onItemClick && angular.isFunction(this.onItemClick)) {
                this.onItemClick({index : this.stack.length - 2});
            }

            if (item.onClick && angular.isFunction(item.onClick)) {
                item.onClick();
            }
        }

    }

    Components.BreadcrumbsComponent = {
        bindings: {
            stack : '<',
            onItemClick: '&',
            mode : "@"
        },
        controller: BreadcrumbsComponentController,
        templateUrl: 'angular/app/modules/common/ui_components/hb_breadcrumbs/hb-breadcrumbs.html',
    };

}());
