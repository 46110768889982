(function () {
    'use strict';

    class SessionCardController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, moment, $element, Enums, AnalyticsService, SchedulingService, $timeout, $translate,
                    TimezoneService, AppStates, $state, $filter, SchedulingManager, ToastService, FeaturesService, ReactModalService,
                    UsersManager, FeatureRestrictionService) {
            super($scope, $injector);
            this.__objectType = 'SessionCardController';

            this.moment = moment;
            this.$element = $element;
            this.Enums = Enums;
            this.AnalyticsService = AnalyticsService;
            this.SchedulingService = SchedulingService;
            this.SchedulingManager = SchedulingManager;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.TimezoneService = TimezoneService;
            this.AppStates = AppStates;
            this.$state = $state;
            this.$filter = $filter;
            this.ToastService = ToastService;
            this.FeaturesService = FeaturesService;
            this.ReactModalService = ReactModalService;
            this.UsersManager = UsersManager;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.currUser = UsersManager.getCurrUser();

            this.teamMembers = undefined;
            this.copyLinkTooltip = "Copy Link";
            this.dupConfigTooltip = "Duplicate session";
            this.isDuplicating = false;
        }

        $onInit() {

            // set session type
            this._sessionType = this.getSessionTypeText(this.session);

            // build session duration text
            this._description = this.SchedulingService.getSessionDescriptionText(this.session);

            // set editable state
            this.editable = this.isEditable();
        }

        isEditable() {
            return this.user.hasCompanyAdminPermissions() ||
                this.user._id === this.session.user_id;
        }

        getSessionTypeText (session) {
            switch(session.session_type) {
                case this.Enums.sessionType.inPerson:
                    return 'SCHEDULING.SESSION.SESSION_TYPES._MEETING_';
                case this.Enums.sessionType.phoneCall:
                    return 'SCHEDULING.SESSION.SESSION_TYPES._PHONE_CALL_';
                case this.Enums.sessionType.videoCall:
                    return 'SCHEDULING.SESSION.SESSION_TYPES._VIDEO_CALL_';
                default:
                    return 'SCHEDULING.SESSION.SESSION_TYPES._OTHER_';
            }
        }

        onToggle() {
            if (this.isFeatureRestricted && this.session.enabled) {
                this.session.enabled = false;
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                this.FeatureRestrictionService.updateFeatureRestrictions();
                return;
            }
            
            this.onSessionUpdate({session: this.session});

            if(!this.session.enabled) {
                this.ToastService.showSuccess({
                    titleTranslation: this.$translate.instant('SCHEDULING.SESSION.DISABLED_TOAST._TITLE_', {session_name: this.session.session_name}),
                    contentTranslation: 'SCHEDULING.SESSION.DISABLED_TOAST._CONTENT_',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 5000
                });
            }
            this.FeatureRestrictionService.updateFeatureRestrictions();
        }

        editSession() {
            this.goToEditSession(this.session);
        }

        openSchechulerTroubleShootModal(e){
            e.stopPropagation();
            this.ReactModalService.openScheduleSessionPreview({sessionConfig:this.session})
        }

        showSharedWith(event) {
            if(!this.teamMembers) {
                const companyMembers = this.company.getAllCompanyMembers();
                this.teamMembers = companyMembers.filter(member => this.session.shared_member_ids.includes(member._id));
            }
            this.shouldShowSharedWith = true;
            this.preventCardClick(event);
        }

        getSessionUrl() {
            return this.session.session_url;
        }

        duplicateSessionConfig(event){
            this.preventCardClick(event);
            if (this.isDuplicating) {
                return;
            }
            this.isDuplicating = true;
            this.SchedulingManager.duplicateSession(this.session._id)
                .then(({data}) => {
                    let elem = this.$element.find('.session-item__dup')[0];
                    elem._tippy.hide();
                    this.goToEditSession(data);
                })
                .catch(()=>{
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, "Couldn't duplicate the configuration. If this issue persist please contact us.");
                })
                .finally(()=>{
                    this.isDuplicating = false;
                });
        }

        linkCopied() {
            let elem = this.$element.find('.session-item__copy')[0];
            this.linksIsCopied = true;
            this.copyLinkTooltip = "Link copied!";

            this.$timeout(() => {
                elem._tippy.hide();
            }, 2000);

            this.$timeout(() => {
                this.linksIsCopied = false;
                this.copyLinkTooltip = "Copy link";
            }, 2500);
        }

        onCardClick() {
            if(this.editable) {
                this.editSession();
            }
        }

        preventCardClick(event) {
            event.stopPropagation();
        }

        goToEditSession(session) {
            this.$state.go(this.AppStates.root_core_sessionConfig, {
                sessionId: session._id,
                userId: session.user_id
            });
        }
    }

    Components.SessionCard = {
        bindings: {
            session: '<',
            onSessionUpdate: '&',
            company: '<',
            user: '<',
            isFeatureRestricted: '<',
        },
        transclude: true,
        controller: SessionCardController,
        controllerAs: 'sessionCardVm',
        name: 'hbSessionCard',
        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/session_card/session_card.html',
    };

}());
