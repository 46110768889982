(function () {
    "use strict";

    // @ngInject
    function AddSecurityPhoneNumberModalController($scope, $injector, $modalInstance, $timeout, UsersManager, RedirectService, ToastService, user) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AddSecurityPhoneNumberModalController';

        this.UsersManager = UsersManager;
        this.RedirectService = RedirectService;
        this.modalInstance = $modalInstance;
        this.$timeout = $timeout;

        this.ToastService = ToastService;
        this.user = user;

        //
        this.codeError = null;
        this.stage = 'LOGOUT';
    }

    Controllers.AddSecurityPhoneNumberModalController = Class(Controllers.BaseController, {

        constructor: AddSecurityPhoneNumberModalController,

        dismiss: function dismiss() {
            this.modalInstance.dismiss();
        },

        logout: function logout() {
            let state = this.AppStates.root_core_navigation_settings_account;
            let params = { openAddSecurityPhoneNumberModal: true };
            this.modalInstance.dismiss();
            this.RedirectService.setToState(state, params);
            this.UsersManager.logout().then(() => {
                this.goToState(this.AppStates.root_core_login);
            });
        }
    });
}());

