(function () {
    "use strict";

    /**
     * Created by inonstelman on 1/5/15.
     *
     *
     * API:
     * showConfirm - 2 buttons
     * showAlert - only Confirm button
     *
     * Messages - supports translations and free text
     *
     * Example of Usage:
     * this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
     'PROPOSAL.SERVICES.ALERT._CONFIRM_DELETE_',
     deleteConfirmed);

     */

    // this is flag will be used during
    // the transition period to quickly
    // turn it off if we found problems
    var USE_NEW_POPUP = true;

    // @ngInject
    function PopupMessageServiceCtor($q, ModalService, _) {
        this.$q = $q;
        this.ModalService = ModalService;
        this.popupMessageOpen = false;
        this._ = _;
    }

    Services.PopupMessageService = Class(function () {


        return {

            constructor: PopupMessageServiceCtor,

            popupTypes: {
                confirm: 'Confirm',
                alert: 'Alert'
            },

            severityTypes: {
                info: 'Info',
                warning: 'Warning',
                error: 'Error',
                success: 'Success',
                none: 'none'
            },


            defaultPosBtnResource: 'FREQUENT_BUTTONS._CONFIRM_',
            defaultNegBtnResource: 'FREQUENT_BUTTONS._CANCEL_',

            popupData: {
                popupInfo: {
                    type: '',
                    messageRes: '',
                    posBtnRes: '',
                    negBtnRes: '',
                    successCallback: null,
                    dismissCallback: null
                },
                popupActive: false
            },

            getPopupData: function getPopupData() {
                return this.popupData;
            },

            // shows error alert, and does not call callbacks
            showErrorAlert: function showErrorAlert(messageResouce){
                return this.showAlert(this.severityTypes.error, messageResouce);
            },

            /**
             * shows a popup with 1 button
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param successCallback optional
             * @param posBtnResource optional
             */
            showAlert: function showAlert(severity, messageResource, successCallback, posBtnResource, cssClass) {
                return this._showPopup(severity, messageResource, null, successCallback, null, posBtnResource, null, this.popupTypes.alert, null, null, null, null, null, cssClass);
            },

            showAlertWithTitle: function showAlert(severity, messageResource, titleResource, successCallback, posBtnResource, cssClass) {
                return this._showPopup(severity, messageResource, titleResource, successCallback, null, posBtnResource, null, this.popupTypes.alert, null, null, null, null, null, cssClass);
            },

            /**
             * shows a popup with 1 button
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param successCallback optional
             * @param posBtnResource optional
             */
            showAlertWithDontShowAgain: function showConfirmWithDontShowAgain(severity, messageResource, successCallback, posBtnResource, uiPersistenceKey) {
                return this._showPopup(severity, messageResource, null, successCallback, null, posBtnResource || 'FREQUENT_BUTTONS._CONFIRM_', null, this.popupTypes.alert, true, uiPersistenceKey);
            },

            /**
             * same as showAlert, the only difference is that this function will not call a callback but instead return a promise.
             * The promise will be resolved (success) when the alert confirm button is clicked,
             * or rejected (error) if it closes in another way (ESC key, 'x' button)
             * @returns promise object
             */
            showAlertPromise: function showAlertPromise(severity, messageResource, posBtnResource, titleResource) {
                return this._showPopupPromise(severity, messageResource, titleResource, posBtnResource, null, this.popupTypes.alert);
            },

            /**
             * shows a popup with 1 button and invoke the closeCallback whether
             * the user close it with positive button, negative button or just ESC key
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param closeCallback optional callback to be invoked when the popup is closed
             * @param posBtnResource optional
             */
            showAlertWithCloseCallback: function showAlertWithCloseCallback(severity, messageResource, closeCallback, posBtnResource) {
                return this._showPopup(severity, messageResource, null, closeCallback, closeCallback, posBtnResource, null, this.popupTypes.alert);
            },

            showAlertPromiseWithCloseButtonCallback: function showAlertPromiseWithCloseButtonCallback(severity, messageResource, posBtnResource, titleResource, closeCallback, dismissCallback, showXButton) {
                return this._showPopup(severity, messageResource, titleResource, closeCallback, dismissCallback, posBtnResource, null, this.popupTypes.alert, undefined, undefined, undefined, undefined, undefined, undefined, undefined, showXButton);
            },

            /**
             * shows a popup with 2 buttons
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param successCallback optional
             * @param dismissCallback optional
             * @param posBtnResource optional
             * @param negBtnResource optional
             * @returns {*}
             */
            showConfirm: function showConfirm(severity, messageResource, successCallback, dismissCallback, posBtnResource, negBtnResource, titleResource, branded, showVerticalButtons) {
                return this._showPopup(severity, messageResource, titleResource, successCallback, dismissCallback, posBtnResource || 'FREQUENT_BUTTONS._CONFIRM_', negBtnResource || 'FREQUENT_BUTTONS._CANCEL_', this.popupTypes.confirm, undefined, undefined, undefined, undefined, showVerticalButtons, undefined, branded);
            },

            /**
             * shows a popup with 2 buttons
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param successCallback optional
             * @param dismissCallback optional
             * @param posBtnResource optional
             * @param negBtnResource optional
             * @returns {*}
             */
            showConfirmWithDontShowAgain: function showConfirmWithDontShowAgain(severity, messageResource, successCallback, dismissCallback, posBtnResource, negBtnResource, uiPersistenceKey, branded) {
                return this._showPopup(severity, messageResource, null, successCallback, dismissCallback, posBtnResource || 'FREQUENT_BUTTONS._CONFIRM_', negBtnResource || 'FREQUENT_BUTTONS._CANCEL_', this.popupTypes.confirm, true ,uiPersistenceKey, undefined, undefined, undefined, undefined, branded);
            },

            /**
             * same as showConfirm, the only difference is that this function will not call a callback but instead return a promise.
             * The promise will be resolved (success) if the dialogue is confirmed, or rejected (error) otherwise
             * @returns promise object
             */
            showConfirmPromise: function showConfirmPromise(severity, messageResource, posBtnResource, negBtnResource, titleRes, disableEscKey, hideBackdrop, showVerticalButtons, cssClass, branded) {
                return this._showPopupPromise(
                    severity,
                    messageResource,
                    titleRes,
                    posBtnResource || 'FREQUENT_BUTTONS._CONFIRM_',
                    negBtnResource || 'FREQUENT_BUTTONS._CANCEL_',
                    this.popupTypes.confirm,
                    undefined,
                    undefined,
                    disableEscKey,
                    hideBackdrop,
                    showVerticalButtons,
                    cssClass,
                    branded);
            },

            /**
             * shows a popup with 2 buttons
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param posBtnResource optional
             * @param negBtnResource optional
             * @returns {*}
             */
            showConfirmWithDontShowAgainPromise: function showConfirmWithDontShowAgain(severity, messageResource, title, posBtnResource, negBtnResource, uiPersistenceKey, cssClass, branded) {
                return this._showPopupPromise(severity, messageResource, title, posBtnResource || 'FREQUENT_BUTTONS._CONFIRM_', negBtnResource || 'FREQUENT_BUTTONS._CANCEL_', this.popupTypes.confirm, true , uiPersistenceKey, undefined, undefined, undefined, cssClass, branded);
            },

            /**
             * PRIVATE METHOD
             * @param severity from severityTypes 'enum'
             * @param messageResource plain text or translation string or
             * this could an object in the form { translationId: {string}, interpolateParams: {object} }
             * @param successCallback optional
             * @param dismissCallback optional
             * @param posBtnResource optional
             * @param negBtnResource optional
             * @param popupType from popupTypes 'enum'
             * @returns {*}
             */
            _showPopup: function _showPopup(severity, messageResource, titleResource, successCallback, dismissCallback, posBtnResource, negBtnResource, popupType, dontShowAgain, uiPersistenceKey, disableEscKey, hideBackdrop, showVerticalButtons, cssClass, branded, showXButton) {
                if (!severity || !messageResource) {
                    return;
                }

                this.popupData.cssClass = cssClass || '';

                if (typeof posBtnResource === 'undefined') {
                    posBtnResource = this.defaultPosBtnResource;
                }

                if (typeof negBtnResource === 'undefined') {
                    negBtnResource = this.defaultNegBtnResource;
                }

                // support static which disable close on click
                var backdrop = this._.isUndefined(hideBackdrop) ? true : hideBackdrop;

                this.popupData.popupInfo = {
                    severity: severity,
                    messageRes: messageResource,
                    titleRes: titleResource,
                    posBtnRes: posBtnResource,
                    negBtnRes: negBtnResource,
                    successCallback: successCallback,
                    dismissCallback: dismissCallback,
                    popupType: popupType || this.popupTypes.confirm,
                    dontShowAgain: dontShowAgain,
                    uiPersistenceKey:uiPersistenceKey,
                    closeOnEsc: !disableEscKey,
                    showBackdrop: backdrop,
                    showVerticalButtons: showVerticalButtons,
                    branded: branded,
                    showXButton: showXButton
                };
                this.popupData.popupActive = true;

                if(USE_NEW_POPUP) {
                    this._showNewPopUp();
                } else {
                    if (this.onPopupChange) {
                        this.onPopupChange(true);
                    }
                }
            },

            _showPopupPromise: function _showPopupPromise(severity, messageResource, titleResource, posBtnResource, negBtnResource, popupType, dontShowAgain, uiPersistenceKey, disableEscKey, hideBackdrop, showVerticalButtons, cssClass, branded) {
                return this.$q(function showPopupPromise(resolve, reject) {
                    var successCallback = function successCallback() {
                        resolve();
                    };
                    var dismissCallback = function dismissCallback() {
                        reject();
                    };
                    this._showPopup(severity, messageResource, titleResource, successCallback, dismissCallback, posBtnResource, negBtnResource, popupType, dontShowAgain, uiPersistenceKey, disableEscKey, hideBackdrop, showVerticalButtons, cssClass, branded);
                }.bind(this));
            },

            clearPopup: function clearPopup() {
                this.popupData.clearPopupActive = false;
                if (this.onPopupChange) {
                    this.onPopupChange(false);
                }
            },

            registerPopupEvents: function registerPopupEvents(onPopupChange) {
                this.onPopupChange = onPopupChange;
            },

            _showNewPopUp: function _showNewPopUp() {
                if(!this.popupMessageOpen) {
                    this.popupMessageOpen = true;
                    return this.ModalService.openMessagePopupModal(this.popupData).finally(function() {
                        this.popupMessageOpen = false;
                    }.bind(this));
                }

            }
        };
    });
}());
