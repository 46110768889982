honeybook_module = angular.module('honeybook', [
    'templates',
    'ui.router',
    'pascalprecht.translate',
    'honeybook.common',
    'honeybook.models',
    'honeybook.core',
    'cfp.hotkeys',
    'honeybook.ngInReact',
]);

try {
    if(angular.module('malkut')){
        angular.module('honeybook').requires.push('malkut');
    }
} catch(e){
    console.debug("Warn: module malkut not found.");
}

// @ngInject
honeybook_module.run(
    function ($rootScope, $state, $stateParams, AppStates, Constants, _, SplashScreenService, $timeout, $, GoogleTagManagerService,
              InitialAppLoadParamsService/* here coz it inits stuff in the constructor, need it on app load */, ngInReactService) {

        // It's very handy to add references to $state and $stateParams to the $rootScope
        // so that you can access them from any scope within your applications.For example,
        // <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
        // to active whenever 'contacts.list' or one of its decendents is active.
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        $rootScope.appStates = AppStates;
        $rootScope.constants = Constants;

        function isHoneyBookVm(targetObject) {
            var _isHoneyBookVm = false;
            _.each(targetObject, function (property) {
                if (property && property.__baseType === 'BaseController') {
                    _isHoneyBookVm = true;
                }
            });
            return _isHoneyBookVm;
        }

        var removeSplashScreen = _.once(function () {
            console.debug("[DEBUG] removeSplashScreen is called");
            SplashScreenService.hide();
        });

        var safariBlankScreenBugFix = _.once(function () {
            console.debug("[DEBUG] safariBlankScreenBugFix is called");
            $state.go(AppStates.root_core_login);
        });

        var onceFirstViewLoaded = _.once(function () {
            try {
                console.debug("[DEBUG] onceFirstViewLoaded is called");
                document.dispatchEvent(new Event('hb_angular_first_view_loaded'));
            } catch (e) {
                console.error('cannot dispatch event hb_angular_first_view_loaded:', e);
            }
        });

        var splashScreenTimer = $timeout(removeSplashScreen, 4500);
        
        var safariBugTimer;
        // This fix causes the React in Iframe to break
        // as it forces an extra navigation. Not sure why it happens only when 
        // the app is in an iframe, but disabling this fix for that case.
        if (!ngInReactService.isInIframe) {
            safariBugTimer = $timeout(safariBlankScreenBugFix, 4500);
        }
        $rootScope.$on('$viewContentLoaded', function (e) {
            console.debug("[DEBUG] $viewContentLoaded is called");

            // load foundation
            $(document).foundation('reflow');

            // As $viewContentLoaded is fired several times in the page load - for every ui-view that is loaded.
            // There is no way to actually determine which ui-view is loaded. (https://github.com/angular-ui/ui-router/issues/1768)
            // And I wanted to know that we are calling the function only after the HoneyBook Vm is loaded, and not just something generic.
            // If you have a better solution in mind, please feel free to create a PR.
            if (isHoneyBookVm(e.targetScope)) {
                console.debug("[DEBUG] calling remove splash screen");
                $timeout.cancel(splashScreenTimer);
                if (safariBugTimer) {
                    $timeout.cancel(safariBugTimer);
                }
                removeSplashScreen();
                onceFirstViewLoaded();
            }
        });


        if (ngInReactService.isInIframe) {
            document.body.className = document.body.className + ' in-iframe';
            ngInReactService.init();
        }

        console.debug("[DEBUG] app run is called");
    }
);


// @ngInject
honeybook_module.config(function ($provide) {
        $provide.decorator("$exceptionHandler", Decorators.$exceptionHandlerDecorator);
    }
);

//set up translations
// @ngInject
honeybook_module.config(function ($translateProvider, Translations) {
    $translateProvider
        .translations('en', Translations.en)
        .useSanitizeValueStrategy('escapeParameters')
        .preferredLanguage('en');
});

// hotkeys
// @ngInject
honeybook_module.config(function(hotkeysProvider) {
    hotkeysProvider.showTextualSymbols  = true;
    hotkeysProvider.showSymbols = false;
});

// @ngInject
honeybook_module.config(
    function ($stateProvider, $urlRouterProvider, AppStates, $locationProvider) {

        /////////////////////////////
        // Redirects and Otherwise //
        /////////////////////////////

        // Use $urlRouterProvider to configure any redirects (when) and invalid urls (otherwise).
        $urlRouterProvider

            // The `when` method says if the url is ever the 1st param, then redirect to the 2nd param
            // Here we are just setting up some convenience urls.

            //.when('/c?id', '/contacts/:id')

            // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
            .otherwise('/login');


        //////////////////////////
        // State Configurations //
        //////////////////////////

        // Use $stateProvider to configure your states.
        $stateProvider

            //////////
            // Root //
            //////////

            .state(AppStates.root, {

                abstract: true,
                // Use a url of "/" to set a states as the "index".
                url: "/",

                views: {

                    'app-container': {
                        // Example of an inline template string. By default, templates
                        // will populate the ui-view within the parent state's template.
                        // For top level states, like this one, the parent template is
                        // the index.html file. So this template will be inserted into the
                        // ui-view within index.html.
                        templateUrl: 'angular/app/application_template.html'
                    }

                }


            })


            ///////////////
            // NGINREACT //
            //////////////
            .state(AppStates.ng_in_react, {
                url: '/ng-in-react',
                views: {
                    'app-container': {
                        templateUrl: 'angular/app/ng_in_react_application_template.html'
                    }
                }
            })

        $locationProvider.html5Mode(true);
    }
);

// Delaying angular load until we have the fingerprint ready
// This is done since findgerprint calculation can't run while angular is bootstrapping
// the UI thread is blocked and some requests to the server are done during the bootstrap process.
// The fingerprint is now REQUIRED for every call to the server so it must be ready before any call is done
// Another delay load - for client portal staging envs only, we must delay the load until gon is ready
angular.element(document).ready(function () {

    function _bootstrap() {
        angular.bootstrap(angular.element('body'), ['honeybook']);
        console.debug("bootstrap succeeded!");
    }

    function bootstrapApp(timeout) {
        timeout = timeout || 0;
        setTimeout(function bootstrap() {
            // if gon is not ready, delay the load for another 500ms
            if (window.gon) {
                console.debug("gon loaded - going to bootstrap");

                if(window.isLoadingClientPortalConfiguration && Date.now() - window.isLoadingClientPortalConfiguration < 4500){
                    return bootstrapApp(100);
                }

                try {
                    _bootstrap();
                } catch (e) {
                    console.error("bootstrap failed");
                    console.error(e);
                }
                try {
                    document.dispatchEvent(new Event('hb_angular_app_bootstrap'));
                } catch (e) {
                    console.error('cannot dispatch event hb_angular_app_bootstrap:', e);
                }
            } else {
                console.debug('waiting for gon to start app');
                bootstrapApp(500);
            }
        }, timeout);
    }

    try {
        new window.FingerprintJS({excludeUserAgent: true, excludeTimezoneOffset: true}).get(function fingerprintResult(result) {
            Services.Fingerprint._cachedResult = result;
            bootstrapApp();
        });
    } catch (e) {
        bootstrapApp();
    }
});
