/**
 * Created by saratheneale in March 2016.
 */
(function () {
    'use strict';

    // @ngInject
    function ConfirmAutoPayControllerCtor(
        $modalInstance, $scope, $injector,
        AnalyticsService,
        ach) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfirmAutoPayController';
        AnalyticsService.trackPageView(this, 'ConfirmAutoPayControllerView');

        this.ach = ach;
        this.modalInstance = $modalInstance;

        this.requestInProgress = false;
    }

    Controllers.ConfirmAutoPayController = Class(Controllers.BaseController, {
        constructor: ConfirmAutoPayControllerCtor,

        cancel: function cancel() {
            this.modalInstance.dismiss();
        },

        confirmAutoPay: function confirm() {

            var self = this;
            self.requestInProgress = true;
            this.modalInstance.close();

        }
    });
}());

