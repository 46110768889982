Directives.FbLeadAdsIntegrationDirective = function FbLeadAdsIntegrationDirective()  {

    "use strict";

    // @ngInject
    function FbLeadAdsIntegrationDirectiveControllerCtor($scope, $state, $injector,
                                                         Routes, AnalyticsService, AppStates, _, $window, UserService, FacebookService,
                                                         UsersManager, ModalService, ServiceLazyLoaderService, CompaniesManager,
                                                         FeaturesService, ReactModalService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FbLeadAdsIntegrationDirectiveController';

        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.FacebookService = FacebookService;
        this.UserService = UserService;
        this.ModalService = ModalService;
        this.CompaniesManager = CompaniesManager;
        this.company = CompaniesManager.getCurrCompany();
        this.$window = $window;
        this.$state = $state;
        this._ = _;
        this.UsersManager = UsersManager;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.user = UsersManager.getCurrUser();

        this.restrictedFeature = this.FeatureRestrictionService.features.facebookLeads;
        this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

        const unsubscribeFeatureRestrictions = $scope.$watch('fbLeadAdsIntegrationVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });
    }


    var FbLeadAdsIntegrationDirectiveController = Class(Controllers.BaseController, {

        constructor: FbLeadAdsIntegrationDirectiveControllerCtor,

        connectFacebook: function connectFacebook() {
            if (this.isFeatureRestricted) {
                this.FeatureRestrictionService.handleRestrictedFeatureClick(this.restrictedFeature);
                return;
            }
            
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.open_facebook_leads_modal);
            this.loggingIn = true;
            this.FacebookService.hasPermissions(this.FacebookService.permissions_types.lead_ads).then((hasPermissions)=> {
                if (hasPermissions) {
                    this.$state.go(this.AppStates.root_core_navigation_facebook_leads);
                } else {
                    this.UserService.connectFacebook(this.user, this.FacebookService.permissions_types.lead_ads)
                        .then(()=> {
                            this.$state.go(this.AppStates.root_core_navigation_facebook_leads);
                        })
                        .catch(()=> {
                            this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.login);
                        })
                        .finally(()=> {
                            this.loggingin = false;
                        });
                }
            });
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/settings/account/fb_lead_ads_integration/fb_lead_ads_integrations_template.html',
        controller: FbLeadAdsIntegrationDirectiveController,
        controllerAs: 'fbLeadAdsIntegrationVm',
        bindToController: true
    };
};
