(function () {
    "use strict";

    // need to pass all injections from child controller.
    // @ngInject
    function AssignWorkspaceToTeamMemberControllerCtor($scope, $modalInstance, $injector, userList, workspace, teamMember, UsersManager, CompaniesManager,
                                                       WorkspacesManager, AnalyticsService, PopupMessageService, PendingTasksManager, OnScreenNotificationService, $) {

        this.constructor.$super.call(this, $scope, $modalInstance, $injector, $, userList, false);
        this.__objectType = 'AssignWorkspaceToTeamMemberController';

        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.WorkspacesManager = WorkspacesManager;
        this.PopupMessageService = PopupMessageService;
        this.PendingTasksManager = PendingTasksManager;
        this.OnScreenNotificationService = OnScreenNotificationService;
        this.company = CompaniesManager.getCurrCompany();

        this.currUser = UsersManager.getCurrUser();
        this.workspace = workspace;
        this.teamMember = teamMember;

        this.userList = userList || [];
        this.userList = this.userList.filter(function (user) {
            if (user._id === workspace.creator_id) {
                return false;
            }
            else if (this.teamMember) {
                return user._id !== this.teamMember._id;
            }


            return user._id !== this.currUser._id;
        }.bind(this));

        this.actionInProgress = false;
    }

    Controllers.AssignWorkspaceToTeamMemberController = Class(Controllers.SelectTeamMemberController, {
        constructor: AssignWorkspaceToTeamMemberControllerCtor,

        headerTitle: function headerTitle() {
            return 'WORKSPACE.ASSIGN_TEAM_MEMBERS._HEADER_TITLE_';
        },

        headerSubtitle: function headerSubtitle() {
            return '';
        },

        userNotAddedString: function userNotAddedString(){
            return 'WORKSPACE.ASSIGN_TEAM_MEMBERS._USER_NOT_ADDED_';
        },

        submit: function submit(selectedUsers) {
            var self = this;
            this.actionInProgress = true;
            var selectedUser = selectedUsers[0];
            
            if (!selectedUser) {
                this.closeModal();
            } else {
                var taskData = {
                    workspace_id: this.workspace._id,
                    assignee_id: selectedUser._id,
                    member_to_replace_id: this.teamMember ? this.teamMember._id : this.currUser._id,
                    assign_meetings: false
                };

                if(this.workspace.scheduled_sessions && this.workspace.scheduled_sessions.length) {
                    this.PopupMessageService.showConfirmPromise(
                        this.PopupMessageService.severityTypes.warning,
                        'WORKSPACE.ASSIGN_TEAM_MEMBERS.MOVE_SESSIONS._TEXT_',
                        'WORKSPACE.ASSIGN_TEAM_MEMBERS.MOVE_SESSIONS._YES_ASSIGN_',
                        'WORKSPACE.ASSIGN_TEAM_MEMBERS.MOVE_SESSIONS._NO_KEEP_')
                        .then(function() {
                            taskData.assign_meetings = true;
                            this.executePendingTask(selectedUser, taskData);
                        }.bind(this)).catch(function() {
                            this.executePendingTask(selectedUser, taskData);
                        }.bind(this));
                } else {
                    this.executePendingTask(selectedUser, taskData);
                }
            }
        },

        executePendingTask: function executePendingTask(selectedUser, taskData) {
            var self = this;

            var pendingTaskPromise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.assignTeamMember, taskData);

            var notification = this.OnScreenNotificationService.createOnScreenNotification(this.OnScreenNotificationService.types.info, "Assigning project to " + selectedUser.full_name, null, 0);
            this.OnScreenNotificationService.addPendingTaskToNotification(notification, pendingTaskPromise, "Project successfully assigned to " + selectedUser.full_name,
                "Failed to assign project to " + selectedUser.full_name + ". Please contact us for more info", this.AnalyticsService.analytics_events.assigned_workspace_to_team_member,
                this.AnalyticsService.analytics_events.assigned_workspace_to_team_member_error);

            this.goToState(this.AppStates.root_core_navigation_pipeline);
            this.closeModal();
        }


    });
}());

