Directives.WorkspaceCardDirective = function WorkspaceCardDirective() {

    // @ngInject
    function WorkspaceCardDirectiveController($state, $filter, $scope, UIUtils, AppStates, Enums, WorkspacesManager, PopupMessageService, AnalyticsService) {

        var self = this;

        self.PopupMessageService = PopupMessageService;
        self.tdbLabel = "";
        self.tags = [];
        self.shouldShowPopup = false;
        self.isUpdatingStatus = false;

        this.pushTags = function pushTags() {
            self.tags.push({label: self.workspace.__event.event_type, isTranslatable: false});
            if (self.workspace.__event.event_date != null) {
                self.tags.push({label: $filter('date')(self.workspace.__event.event_date, 'MMM dd, yyyy'), isTranslatable: false});
            } else {
                self.tags.push({label: 'WORKSPACE._TBD_', isTranslatable: true});
            }
            self.tags.push({label: self.workspace.__event.event_location, isTranslatable: false});
        };


        if (this.workspace.__event && this.workspace.__event.event_type) {

            this.pushTags();

        } else {

            var unwatchFn = $scope.$watch('workspaceCardVm.workspace.__event.event_type', function (newVal, oldVal) {
                if (self.workspace.__event && self.workspace.__event.event_type) {
                    self.pushTags();
                    unwatchFn();
                }

            });

        }

        this.getWorkspaceCardClasses = function getWorkspaceCardClasses() {
            var cardClasses = [
                this.workspace.status,
                UIUtils.generateClassForActiveState(this.workspace.active_state),
            ];

            return cardClasses.join(' ');
        };

        this.getStatusClasses = function getStatusClasses() {
            var classes = [
                this.workspace.status_type,
                this.workspace.workspace_status_type
            ];
            return classes.join(' ');
        };

        this.gotoWorkspace = function gotoWorkspace() {

            // came from clicking the popup
            if (this.deleteOrCancel) {
                this.deleteOrCancel = '';
                return;
            }
            $state.go(AppStates.root_core_navigation_event_workspace_feed, {workspace_id: this.workspace._id});
        };

        // =========================
        // Actions
        // =========================

        this.deleteWorkspace = function deleteWorkspace() {
            self.isUpdatingStatus = true;

            var trackingData = {
                workspace_id: self.workspace._id
            };

            WorkspacesManager.deleteWorkspace(this.workspace).then(function success() {
                self.isUpdatingStatus = false;
                AnalyticsService.track(self, AnalyticsService.analytics_events.workspace_deleted, trackingData);
            }, function error() {
                self.isUpdatingStatus = false;

                AnalyticsService.track(self, AnalyticsService.analytics_events.workspace_deleted_error, trackingData);

                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error,
                    'WORKSPACE.DELETE._ERROR_');
            });
        };

        // =========================
        // Menu
        // =========================
        this.allowDelete = function allowDelete() {
            return this.workspace.allowDelete();
        };

        this.deleteWorkspaceClicked = function deleteWorkspaceClicked() {
            this.shouldShowPopup = true;
            this.popupHeadline = 'WORKSPACE.DELETE._MESSAGE_';
            this.popupText = 'WORKSPACE.DELETE._ALERT_';
            this.deleteOrCancel = 'delete';
        };

        this.cancelDeleteOrCancelAction = function cancelDeleteOrCancelAction() {
            this.shouldShowPopup = false;
        };

        this.clickedYesForDeleteOrCancelAction = function clickedYesForDeleteOrCancelAction() {
            this.shouldShowPopup = false;
            if (this.deleteOrCancel === 'block') {
                this.blockWorkspace();
            } else {
                this.deleteWorkspace();
            }
        };
    }


    return {
        scope: {
            workspace: '=workspace',
            workspaceActiveStateChanged: '=workspaceActiveStateChanged'
        },
        templateUrl: 'angular/app/modules/core/features/workspace_card/workspace_card_directive_template.html',
        controller: WorkspaceCardDirectiveController,
        controllerAs: 'workspaceCardVm',
        bindToController: true
    };
};