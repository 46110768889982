Components.EmailEditorSuccess = {
    bindings: {
        sendSuccess: '<',
        filePreviewSendSuccess: '<',
        fileType: '<',
        close: '&',
        recipients: '<',
        isScheduled: '<',
        primaryText: '<',
        secondaryText: '<',
        showDoneButton: '<',
        isBlueCheckmark: '<'
    },

    // @ngInject
    controller: function EmailEditorSuccessControllerFunc($scope, $element, $attrs, AnalyticsService, WorkspaceFileService) {

        this.WorkspaceFileService = WorkspaceFileService;

        this.$onInit = function () {

            this.recipientEmail = this.recipients[0]
                ? this.recipients[0].email
                : 'your email';

            this.closeWithAnalytics = () => {
                AnalyticsService.trackConfirm(
                    this,
                    AnalyticsService.analytics_events.test_file_sent_modal
                );
                this.close();
            };

            // primary text
            this.successTextPrimary = 'EMAIL_EDITOR.SUCCESS._SUCCESS_COPY_';

        };

        this.$onChanges = function $onChanges(changes) {


            if (changes.primaryText && changes.primaryText.currentValue) {
                this.successTextPrimary = this.primaryText;
            }

            if (changes.secondaryText && changes.secondaryText.currentValue) {
                this.successTextSecondary = this.secondaryText;
            }

        };


    },
    name: 'hbEmailEditorSuccess',
    templateUrl: 'angular/app/modules/common/ui_components/email_editor/email_editor_success.html',
};
