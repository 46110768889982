(function () {
    'use strict';

    class BankAccountEntityInfoController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, $translate) {
            super($scope, $injector);
            this.__objectType = 'BankAccountEntityInfoController';
            this.AnalyticsService = AnalyticsService;
            this.$translate = $translate;

            this.$ctrl = $scope.$ctrl;
        }

        getEntityTitle() {
            if (!this.$ctrl.entity) {
                return "&nbsp;";
            }

            if (this.$ctrl.entity[this.$ctrl.titleAttrName]) {
                return this.$ctrl.entity[this.$ctrl.titleAttrName];
            }

            return this.$ctrl.entity.first_name + " " + this.$ctrl.entity.last_name;
        }

        getBankAccountEndingIn() {
            if (!this.$ctrl.entity) {
                return null;
            }
            
            return this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT._BANK_ACCOUNT_ENDING_IN_',
                {accountNumber: this.getLast4()}
            );
        }

        getLast4() {
            return (this.$ctrl.entity.account_number_last4 || this.$ctrl.entity.last4 || 'N/A').substr(-4);
        }

        getDebitCardEndingIn() {
            if (!this.$ctrl.entity) {
                return null;
            }

            return this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT._DEBIT_CARD_ENDING_IN_',
                {accountNumber: this.getLast4()}
            );
        }

        getAccountEndingIn() {
            if (!this.$ctrl.entity) {
                return null;
            }

            return this.$translate.instant(
                'COMPANY_SETTING.BANK_ACCOUNT._ENDING_IN_',
                {accountNumber: this.entity.mask}
            );
        }

        getAccountType() {
            return this.entity && this.entity.type && this.entity.subtype? `${this.entity.type} / ${this.entity.subtype}` : null;
        }

        getInstitutionName() {
            return this.entity && this.entity.institution_name ? this.entity.institution_name : null;
        }

        shouldShowEdit() {
            return !(this.hideEdit && this.hideEdit());
        }

        isEntityDeletable() {
            const { entity, type, hasBankAccount } = this.$ctrl;

            if (type === "owner") {
                return !entity.representative || !hasBankAccount;
            }

            return true;
        }

        isEntityEditable() {
            return this.$ctrl.type !== "owner" || this.$ctrl.hasBankAccount;
        }

        onEditClick() {
            this.AnalyticsService.trackClick(this, "edit bank account", {type: this.type});
            this.$ctrl.onEdit();
            this.hasMissingInfo = false;
        }
    }

    Components.BankAccountEntityInfo = {
        bindings: {
            iconClass: '@',
            titleAttrName: '@',
            subTitle: '<?',
            onDelete: '&',
            hideEdit: '&?',
            onEdit: '&',
            type: '@',
            entity: '=',
            hasBankAccount: '=',
            isBeingDeleted: '@',
            hasMissingInfo: '<'
        },
        controller: BankAccountEntityInfoController,
        name: 'hbBankAccountEntityInfo',
        templateUrl: 'angular/app/modules/core/features/settings/company/bank/bank_account_entity_info_template.html',
    };

}());
