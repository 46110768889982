
// @ngInject

Models.WorkspacesCollection = function WorkspacesCollection(BaseCollection) {

    var WorkspacesCollection = Class(BaseCollection, {

            constructor: function constructor(){
                WorkspacesCollection.$super.call(this);
                this._collection.__className = 'WorkspacesCollection';
                this._collection.__childsType = 'WorkspaceModel';
                return this._collection;
            }
        }
    );

    return WorkspacesCollection;
};
