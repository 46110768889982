/**
 * The hb-example-file component
 *
 * This component shows a mocked up honeybook file
 *
 * Parameters:
 * ---
 * width:         String | Width of the output file element (Default = 340px)
 * vendorName:    String | Any string expression
 * companyName:   String | Any string expression
 * coverPhotoId:  String | Cloudinary Public ID
 * logoId:        String | Cloudinary Public ID
 * fileType:      String | Optional, one of 'invoice', 'contract', 'proposal'
 *
 */

(function () {
    'use strict';

    class ExampleFileController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            _,
            $,
            $sce,
            UIUtils,
            CompaniesManager,
            CloudinaryConfigService
         ) {
            super($scope, $injector);
            this.__objectType = 'ExampleFileController';

            this.$ = $;
            this.$sce = $sce;
            this.UIUtils = UIUtils;
            this.CompaniesManager = CompaniesManager;
            this.CloudinaryConfigService = CloudinaryConfigService;
        }

        $onInit() {
            this.defaultCompanyName = "Business name";
            this.defaultVendorName = "Full name";
            this.cloudName = this.CloudinaryConfigService.getCloudinaryCloudName();

            this.company = this.CompaniesManager.getCurrCompany();
            this.shouldShowFileHeader = Boolean(this.brandColor) || Boolean(this.fileName);

            // Layout work
            // Original design | File dimensions: 340w x 400h = 1:1.17647
            const width                = parseFloat(this.width || '340');
            const height               = width * 1.17647;
            const fileHeaderHeight     = .1 * height;
            const fileHeroHeight       = this.shouldShowFileHeader ? (.3 * height) : (.35 * height);
            const fileBodyHeight       = this.shouldShowFileHeader ? (.6 * height) : (.65 * height);
            const logoSize             = .1412 * width; // @width = 340px :: logoWidth = 48 x 48
            const logoMargin           = logoSize / 2;  // @width = 340px :: logoMargin = 22
            const headingHeight        = logoSize + (2 * logoMargin); // Container for Business/Vendor name
            const companyNameFontSize  = width * .06;
            const vendorNameFontSize   = width * .04;

            // Cloudinary URL work
            this.blankCoverPhotoId = 'app/ui/example-file/blank';
            this.blankLogoPhotoId = 'app/ui/example-file/your-logo';

            const coverPhoto = this.coverPhotoId || this.blankCoverPhotoId;
            this.coverPhotoSrc = this.generateCloudinarySrc(coverPhoto);

            const logoPhoto = this.logoId || this.blankLogoPhotoId;
            this.logoPhotoSrc = this.generateCloudinarySrc(logoPhoto);

            if (this.fileType) {
                const fileTypeImageMap = {
                    invoice: 'app/ui/example-file/invoice-body',
                    contract: 'app/ui/example-file/contract-body',
                    proposal: 'app/ui/example-file/proposal-body',
                }
                this.fileContentImageUrl = this.generateCloudinarySrc(fileTypeImageMap[this.fileType]);
            }

            // Number -> String
            const inPixels = pixelCount => (pixelCount).toString() + 'px';

            this.fileStyle = {
                'width': inPixels(width),
            };

            this.fileHeaderStyle = {
                'height': inPixels(fileHeaderHeight),
                'padding': '0 ' + inPixels(logoMargin),
            };

            this.fileHeaderNameStyle = {
                'font-size': inPixels(vendorNameFontSize * 1.1),
            };

            this.fileHeaderButtonStyle = {
                'padding': inPixels(logoMargin / 5) + ' ' + inPixels(logoMargin / 2),
                'font-size': inPixels(vendorNameFontSize * .7),
                'color': this.UIUtils.makeBlackOrWhiteForContrast(this.brandColor),
                'background-color': this.brandColor,
            };

            this.fileHeroStyle = {
                'height': inPixels(fileHeroHeight),
                'background-image': 'url(' + this.coverPhotoSrc + ')',
            };

            this.logoContainerStyle = {
                'width': inPixels(logoSize),
                'height': inPixels(logoSize),
                'margin': inPixels(logoMargin),
            };

            this.logoImageStyle = {
                'background-image': 'url(' + this.logoPhotoSrc + ')',
            };

            this.headingContainerStyle = {
                'height': inPixels(headingHeight),
            };

            this.companyNameStyle = {
                'font-size': inPixels(companyNameFontSize),
            };

            this.vendorNameStyle = {
                'font-size': inPixels(vendorNameFontSize)
            };

            this.fileContentStyle = {
                'height': inPixels(fileBodyHeight),
                'background-image': 'url(' + this.fileContentImageUrl + ')'
            };

        }

        trustSrc(src) {
            return this.$sce.trustAsResourceUrl(src);
        }

        generateCloudinarySrc(publicId) {
            const img = this.$.cloudinary.image(publicId, {
                cloud_name: this.cloudName,
                secure: true,
                transformation: [
                    {
                        flags: 'lossy',
                        quality: 'auto',
                        fetch_format: 'auto'
                    }
                ]
            });

            return img[0].src;
        }

    }


    Components.ExampleFile = {
        bindings: {
            width: '<',
            vendorName: '<',
            companyName: '<',
            coverPhotoId: '<',
            defaultCoverImage: '<',
            logoId: '<',
            brandColor: '<',
            fileName: '<',
            fileType: '<',
            logoImage: '<'
        },
        controller: ExampleFileController,
        controllerAs: 'exampleFileVm',
        name: 'hbExampleFile',
        templateUrl: 'angular/app/modules/common/ui_components/example_file/example_file_component.html',
    };

}());
