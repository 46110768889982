(function () {
    'use strict';
    class MeetingPhoneField extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'MeetingPhoneField';
        }
    }

    Components.MeetingPhoneField = {
        bindings: {
            label: '@',
            name: '@',
            value: '=',
            required: '<?',
            autoFocus: '<?',
            placeholder: '@',
            analyticsEventSource: '@',
            analyticsEvent: '@',
            showError: '=',
            errorMessage: '@'

        },
        controller: MeetingPhoneField,
        name: 'MeetingPhoneField',
        templateUrl: 'angular/app/modules/core/features/calendar/create_meeting_form/meeting_phone_field/meeting_phone_field.html'
    };
}());