
(function () {
  'use strict';

  class CalendarToolbarComponent extends Controllers.BaseControllerES6 {

    // @ngInject
    constructor($scope, $injector, AnalyticsService, CalendarService, TimezoneService, StatsigService, Enums) {
      super($scope, $injector);
      this.__objectType = 'CalendarToolbarComponent';

      this.AnalyticsService = AnalyticsService;
      this.CalendarService = CalendarService;
      this.TimezoneService = TimezoneService;

      this.dateSelectionOpen = true;
    }

    $onInit() {
      this.switcher = false;

      if (this.defaultView) {
          this.activeView = this._findCalendarView(this.defaultView);
      } else {
          this.activeView = this.CalendarService.CALENDAR_MONTH_VIEW;
      }
    }

    $onChanges(changes) {
        if(changes['defaultView'] && changes['defaultView'].previousValue) {
            this.activeView = this._findCalendarView(this.defaultView);
        }
    }

    onChangeView(item){
      this.handleViewDropdown({view: item});
      this.activeView = this._findCalendarView(item);
      // Update UI here
      this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.calendar_toolbar_change_view, {
        filter: item.key,
      });
    }

    onControlChange(move){
      this.handleControl({move: move});
    }

    _findCalendarView(item) {
        return this.CalendarService.CALENDAR_VIEWS.find(cal => cal.key === item.key);
    }
  }

  Components.CalendarToolbar = {
    bindings: {
        calendar: '=',
        handleViewDropdown: '&',
        handleToday: '&',
        updateCalendarView: '&',
        handleControl: '&',
        defaultView: '<?'
    },
    controller: CalendarToolbarComponent,
    name : 'hbCalendarToolbar',
    templateUrl: 'angular/app/modules/core/features/calendar/calendar_toolbar/calendar_toolbar.html',
  };

}());
