(function () {
    "use strict";

    var COORD_NAME = {
        WIDTH: 'width',
        HEIGHT: 'height'
    };

    var IMAGE_EDGE_SIZE = 370;

    // @ngInject
    function AttachAssetModalControllerCtor($log, $scope, $injector, $modalInstance, $timeout, $window, $, $translate, _,
                                            ImagesManager, PhotosUploadManager, AnalyticsService, CompaniesManager, Enums, PopupMessageService,
                                            showCropTab, showGalleryTab, showUploadTab, defaultTab, allowMultipleAttach,
                                            image, aspectRatio, showPreviewWhenCropping, startUploadFile, model, acceptFilesFilter,
                                            assetTypeForUpload, DeviceService, showCropTitle, footNoteText, branded, footNoteTextUploadTabDesktop, footNoteTextUploadTabMobile, UIUtils, DatadogRUMService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AttachAssetModalController';
        this.$ = $;
        this.modalInstance = $modalInstance;
        this.ImagesManager = ImagesManager;
        this.Enums = Enums;
        this.$log = $log;
        this.$timeout = $timeout;
        this.$window = $window;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;
        this.DatadogRUMService = DatadogRUMService;
        this.showCropTab = showCropTab;
        this.showGalleryTab = showGalleryTab;
        this.showUploadTab = showUploadTab;
        this.allowMultipleAttach = allowMultipleAttach;
        this.acceptFilesFilter = acceptFilesFilter || '';
        this.footNoteText = !_.isEmpty(footNoteText) && $translate.instant(footNoteText);
        this.footNoteTextUploadTabDesktop = !_.isEmpty(footNoteTextUploadTabDesktop) && $translate.instant(footNoteTextUploadTabDesktop);
        this.footNoteTextUploadTabMobile = !_.isEmpty(footNoteTextUploadTabMobile) && $translate.instant(footNoteTextUploadTabMobile);
        this.startUploadFile = startUploadFile;
        this.assetTypeForUpload = assetTypeForUpload || 'cover';
        this.showCropTitle = showCropTitle;
        this.company = this.CompaniesManager.getCurrCompany();
        this.isMobile = DeviceService.nxSmallBreakpoint();
        this.branded = branded;

        // Show more of gallery on big screens
        this.imagesContainerHeight = "45vh";


        this.galleryArgs = {
            margins: 3,
            rowHeight: 110
        };


        if (showCropTab && image) {
            this.image = image;
            if (this.image === 'no-image') {
                this.image = null;
            }
        }

        if (model) {
            this.model = model;
            if (this.image === 'no-model') {
                this.model = null;
            }
        }

        this.setTransformedImageUrl(this.image);
    

        this.images = this.company.cover_images;
        this.files = this.company.company_assets;
        this.uploadingFileViewModel = null;
        this.filesToAttach = [];
        this.showLoader = true;
        this.galleryReady = false;
        this.fileReaderSupported = this.$window.FileReader !== null;
        this.isFileDragged = false;
        this.filesDropped = false;
        this.uploadInitialState = true;
        this.tabs = [];
        this.isMobile = DeviceService.checkIfMobileSize();
        this.isClosingDisabled = false;


        if (showCropTab) {
            this.tabs = [
                {title: "Crop", panel: "crop-tabs-1"}
            ];
        }

        if (showGalleryTab) {
            this.tabs.push({title: "Library", panel: "crop-tabs-2"});
        }

        if (showUploadTab) {
            this.tabs.push({title: "Upload", panel: "crop-tabs-3"});
        }

        this.activeTab = defaultTab;


        this.showPreviewWhenCropping = showPreviewWhenCropping;
        this.aspectRatio = aspectRatio;

        this.selectedFiles = null;
        this.filesToUpload = null;
        this.limitUploadQuantity = 5;

        this.errorMessage = null;

        // 15 MB limit
        this.fileSizeLimit = 15 * 1024 * 1024;

        this.addCoverImage = function addCoverImage(images) {
            images.forEach(function(image) {
                this.company.cover_images.push(image);
            }.bind(this))
        }.bind(this),

        $scope.$watch('attachAssetModalVm.selectedFiles', function (newValue, oldValue) {
            var filesToUpload;
            this.errorMessage = null;
            if (newValue && !angular.isArray(newValue)) {
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                filesToUpload = [newValue];
            } else {
                filesToUpload = this.selectedFiles;
            }
            var removedLargeFiles = false;
            if (filesToUpload != null && filesToUpload.length > 0) {
                var cleanedFiles = filesToUpload.filter(function (file) {
                                        return file.size <= this.fileSizeLimit;
                                    }.bind(this));

                if (cleanedFiles.length < filesToUpload.length) {
                    removedLargeFiles = true;
                    filesToUpload = cleanedFiles;
                }
            }

            this.filesToUpload = filesToUpload;

            if (removedLargeFiles) {
                this.errorMessage = 'UPLOAD_FILE._ERROR_FILE_TOO_LARGE_';
            }
        }.bind(this));

        this._load();

        //if the selection of files was done outside of this dialog and we need to start uploading it right away
        if (startUploadFile) {
            this.onFilesDropped(startUploadFile);
            this.selectedFiles = startUploadFile;
        }

        this.assetClicked = this.assetClicked.bind(this);

        var unwatchFiles = $scope.$watchCollection('attachAssetModalVm.files', function () {
            this.formattedAttachmentFiles = this.files.map(function (file) {
                return {
                    _id: file._id,
                    name: file.asset_name,
                    type: file.file_type,
                    url: file.url
                };
            }.bind(this));
        }.bind(this));

        $scope.$on('$destroy', function () {
            unwatchFiles();
        });
    }

    Controllers.AttachAssetModalController = Class(Controllers.BaseController, {
        constructor: AttachAssetModalControllerCtor,

        _load: function _load() {
            if (this.showCropTab) {
                this._calcUISizes();
            }

            this.showLoader = false;
        },

        _calcUISizes: function _calcUISizes(img) {
            var image = img || this.image;

            if (!image) {
                return;
            }

            // this.changeImageEdgeSizeOnMobile();

            if (image.width < IMAGE_EDGE_SIZE && image.height < IMAGE_EDGE_SIZE) {
                this.uiWidth = image.width;
                this.uiHeight = image.height;
            } else if (image.width > image.height) {
                this.uiWidth = IMAGE_EDGE_SIZE;
                this.uiHeight = image.height / image.width * IMAGE_EDGE_SIZE;
            } else {
                this.uiWidth = image.width / image.height * IMAGE_EDGE_SIZE;
                this.uiHeight = IMAGE_EDGE_SIZE;
            }

            this.loadCoordinates(image);
        },

        changeImageEdgeSizeOnMobile: function changeImageEdgeSizeOnMobile() {
            if (angular.element(this.$window).width() < 640) {
                IMAGE_EDGE_SIZE = 200;
            } else {
                IMAGE_EDGE_SIZE = 370;
            }
        },

        loadCoordinates: function loadCoordinates(image) {
            var x0, y0, x1, y1, w, h;
            if (image.position_x) {
                x0 = image.position_x / image.width * this.uiWidth;
            } else {
                x0 = 0;
            }
            if (image.position_y) {
                y0 = image.position_y / image.height * this.uiHeight;
            } else {
                y0 = 0;
            }
            if (image.position_w) {
                w = image.position_w / image.width * this.uiWidth;
            } else {
                w = 100;
            }
            if (image.position_h) {
                h = image.position_h / image.height * this.uiHeight;
            } else {
                h = 100;
            }

            x1 = x0 + w;
            y1 = y0 + h;

            //  coords is an array => [x0, y0, x1, y1, w, h]
            //  x0 - x1 = w
            //  y0 - y1 = h
            this.coords = [x0, y0, x1, y1, w, h];
        },

        changeActive: function changeActive(tab) {
            this.imageContainerExpended = false;
            if (tab.title === 'Crop' && !this.image) {
                return;
            }

            var oldTab = this.activeTab;
            this.activeTab = tab.title;

            if (this.activeTab === "Library") {
                this.galleryReady = true;
            }
            else {
                this.galleryReady = false;
            }
        },

        onResizeGalleryEnded: function onResizeGalleryEnded() {
            var self = this;
            var element = document.querySelector('#ImagesContainer');
            self.imagesExpendedContainerHeight = element.scrollHeight;
            if (element != null && element.clientHeight < element.scrollHeight) {
                self.imagesExpandButtonShow = true;
            }
            else {
                self.imagesExpandButtonShow = false;
            }


            if (this.activeTab === 'Library') {
                this.$timeout(function () {
                    this.galleryReady = true;
                }.bind(this), 0);
            }
        },

        onGalleryComplete: function onGalleryComplete() {
            if (this.activeTab === 'Library') {
                this.$timeout(function () {
                    this.galleryReady = true;
                }.bind(this), 0);
            }
        },

        cancel: function cancel() {
            // Dismiss selected assets
            this.images = this.company.cover_images;
            this.files = this.company.company_assets;

            this.closing = true;
            this.modalInstance.dismiss('cancel');
        },

        close: function close() {
            this.isClosingDisabled = true;

            if (this.image) {
                this.image.position_x = Math.round(this.normalizeCoordinate(this.coords[this.Enums.COORD_TYPE.X0], COORD_NAME.WIDTH));
                this.image.position_y = Math.round(this.normalizeCoordinate(this.coords[this.Enums.COORD_TYPE.Y0], COORD_NAME.HEIGHT));
                this.image.position_w = Math.round(this.normalizeCoordinate(this.coords[this.Enums.COORD_TYPE.W], COORD_NAME.WIDTH));
                this.image.position_h = Math.round(this.normalizeCoordinate(this.coords[this.Enums.COORD_TYPE.H], COORD_NAME.HEIGHT));

                var img = this.$.cloudinary.image(this.image.cloudinary_public_id, {
                    cloud_name: this.image.cloudinary_sub_account,
                    transformation: [
                        {
                            width: this.image.position_w,
                            height: this.image.position_h,
                            x: this.image.position_x,
                            y: this.image.position_y,
                            crop: 'crop',
                        },
                        {
                            crop: 'lfill',
                            width: 2048
                        },
                        {
                            flags: 'lossy',
                            fetch_format: 'auto'
                        }
                    ]
                });

                this.$(img).load(function () {
                    this.modalInstance.close(this.image);
                }.bind(this));
            }
            else {
                this.filesDropped = false;
                this.closing = true;
                this.modalInstance.close(this.filesToAttach);
            }
        },

        selectGalleryImage: function selectGalleryImage(img) {
            if (this.showCropTab) {
                this.setImage(img);
                this.changeActive(this.tabs[0]);
            }
        },

        setImage: function setImage(img) {
            this._calcUISizes(img);
            this.image = img;
            this.setTransformedImageUrl(img);
        },

        setTransformedImageUrl: function setTransformedImageUrl(image) {
            // add cloudinary flags to image
            if (image && image.original_url) {
                const newImageUrl = this.UIUtils.addDefaultFlagsToCloudinaryUrl(image.original_url, image.cloudinary_public_id);
                if (newImageUrl) {
                    this.transformedImageUrl = newImageUrl;
                } else {
                    this.DatadogRUMService.addError(new Error('AttachAssetModalControllerCtor: addDefaultFlagsToCloudinaryUrl failed'), {
                        image: image
                    });
                }
            }
        },


        normalizeCoordinate: function normalizeCoordinate(size, type) {
            if (!this.image) {
                return;
            }

            switch (type) {
                case COORD_NAME.WIDTH:
                    return size / this.uiWidth * this.image.width;
                case COORD_NAME.HEIGHT:
                    return size / this.uiHeight * this.image.height;
                default:
                    return size;
            }
        },

        selectGalleryFile: function selectGalleryFile(file) {
            if (!file.size || file.size <= this.fileSizeLimit) {
                this.filesToAttach = this.filesToAttach.concat([file]);
            }
        },

        removeGalleryFile: function removeGalleryFile(file) {
            var index = this.filesToAttach.indexOf(file);

            if (index >= 0) {
                this.filesToAttach = this.filesToAttach.filter(function (f, i) {
                    return i !== index;
                });
            }
        },


        onFileSelected: function onFileSelected($files) {
            if ($files.length > 0) {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_selected, this.analyticsProperties());
                this.uploadFiles($files);
            }
        },

        onFilesDropped: function onFilesDropped($files) {
            this.isFileDragged = false;
            this.filesDropped = true;

            if (!$files.length) {
                return;
            }

            this.uploadInitialState = false;
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_dragged_and_dropped, this.analyticsProperties());

            if (this.showCropTab) {
                this.uploadFiles($files);
            } else {
                this.generateThumbs($files);
            }
        },

        uploadFiles: function uploadFiles($files) {
            var self = this;
            var uploadingFileViewModel;
            if ($files && $files.length > 1) {
                self.multipleFilesDraggedWarning = true;
            }

            //remove large files before upload
            var filesToUpload = $files;
            if (filesToUpload != null && filesToUpload.length > 0) {
                var fileSizeLimit = this.fileSizeLimit;
                var cleanedFiles = filesToUpload.filter(function (file) {
                    return file.size <= fileSizeLimit;
                });
                if (cleanedFiles.length < filesToUpload.length) {
                    filesToUpload = cleanedFiles;
                }
            }
            $files = filesToUpload;

            if ($files && $files.length && $files.length > 0) {

                this.limitUploadQuantity = 1;

                //we take the first one since we allow only one file to be uploaded here
                uploadingFileViewModel = this.uploadingFileViewModel = {
                    instance: this.company, //the instance of the model containing these files
                    type: this.assetTypeForUpload,
                    dontUpdateServer: true,
                    uploading: false,
                    uploaded: true,
                    dataUrl: null,
                    url: null,
                    file: $files[0],
                    name: $files[0].name,
                    progress: 0
                };
            } else {
                return;
            }
            self.uploading = true;

            this.PhotosUploadManager.getUpdatedModelData(this.uploadingFileViewModel);

            var promise = this.PhotosUploadManager.uploadPhoto($files, this.uploadingFileViewModel, this);

            this.generateThumb(this.uploadingFileViewModel);

            promise
                .then(function success() {


                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_successfully_uploaded, {
                        companyId: self.company._id,
                        key: uploadingFileViewModel.key
                    });

                    uploadingFileViewModel.url = uploadingFileViewModel.file.s3Response.url;

                    return self.CompaniesManager.createCompanyImage(self.company, uploadingFileViewModel.file.s3Response, uploadingFileViewModel.type).then(function (result) {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_saved_on_company_model, {
                            companyId: self.company._id,
                            name: uploadingFileViewModel.name
                        });

                        //get the last image from the result and assume that this is the one that we added
                        self.$timeout(function () {
                            //do it in a timeout so the 100% will show.

                            var images = [];
                            var addedImage = null;

                            if (self.model) {
                                switch (self.model.type) {
                                    case "icon":
                                        images.push(result.data.icon_image);
                                        break;
                                    default:
                                        images = result.data.cover_images;
                                }
                            } else {
                                images = result.data.cover_images;
                            }

                            angular.forEach(images, function (image, key) {
                                if (image.phash === uploadingFileViewModel.file.s3Response.phash) {
                                    addedImage = image;
                                }
                            });


                            //var addedImage = images[images.length - 1];
                            self.selectGalleryImage(addedImage);
                        }, 100);
                    });
                })
                .catch(function error(resp) {
                    if (resp === null) {
                        //this is a result of a delete of a file during an upload. remove it from the view model since we know the upload stopped
                        //before it was inserted to our company model
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_deleted_while_uploading, {
                            companyId: self.company._id,
                            key: uploadingFileViewModel.key
                        });

                        self.uploading = false;
                        uploadingFileViewModel.uploadFailedInTheMiddle = true;
                        return;
                    }

                    if (resp) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.GALLERY._ERROR_UPLOADING_FILE_');
                        var message = 'Error saving the file: ' + uploadingFileViewModel.name + ' resp: ' + resp;
                        self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.error_unable_to_add_image_to_company_settings, message, {
                            companyId: self.company._id
                        });
                        uploadingFileViewModel.uploadFailedInTheMiddle = true;
                    }
                })
                .finally(function () {
                    if (!uploadingFileViewModel.uploadFailedInTheMiddle) {
                        uploadingFileViewModel.progress = 100;
                    }
                    //clean the uploaded file model so the user will be able to upload another file if he goes back to this tab.
                    self.$timeout(function () {
                        self.uploadingFileViewModel = null;
                    }, 500);

                    self.uploading = false;
                    self.uploadInitialState = true;
                });

            this.uploadingFileViewModel.file.uploadAPI.progress(function (percentUploaded) {
                //save the last 5 percent for the upload to the company model.
                self.uploadingFileViewModel.progress = parseInt(0.95 * percentUploaded);
            });
        },

        generateThumbs: function generateThumb($files) {
            var self = this;
            var files;

            if ($files && !angular.isArray($files)) {
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                files = [$files];
            } else {
                files = $files;
            }

            if (files != null && files.length > 0) {
                for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                    var file = files[fileIndex];
                    if (this.fileReaderSupported) {
                        //Execution
                        if (!file.dataUrl) {
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = function (e) {
                                var file = this;
                                self.$timeout(function () {
                                    file.dataUrl = e.target.result;
                                });
                            }.bind(file);
                        }
                    }
                    self.selectGalleryFile(file);
                }
            }
        },

        generateThumb: function generateThumb(fileViewModel) {
            var fileReaderSupported = this.$window.FileReader != null;

            if (fileReaderSupported && fileViewModel.file.type.indexOf('image') > -1) {
                this.readFileAsDataUrl(fileViewModel);
            }
        },

        readFileAsDataUrl: function readFileAsDataUrl(fileViewModel) {
            var self = this;

            var fileReader = new FileReader();
            fileReader.readAsDataURL(fileViewModel.file);
            fileReader.onload = function (e) {
                self.$timeout(function () {
                    fileViewModel.dataUrl = e.target.result;
                });
            };
        },

        analyticsProperties: function analyticsProperties() {
            return { companyId: this.company._id };
        },

        assetClicked: function assetClicked(asset) {
            // if crop tab enabled, multiple selection is not relevant
            if (this.showCropTab) {
                return;
            }

            this.isAssetSelected(asset) ?
                this.removeGalleryFile(asset) :
                this.selectGalleryFile(asset);
        },

        isImageTypeOfFile: function isImageTypeOfFile(file) {
            return (file.type.indexOf('image') > -1);
        },

        isAssetSelected: function isAssetSelected(asset) {
            for (var i in this.filesToAttach) {
                if (this.filesToAttach[i]._id === asset._id) {
                    return true;
                }
            }
            return false;
        },

        imagePositionInSelectedAssets: function (asset) {
            var counter = 0;
            for (var i in this.filesToAttach) {
                var fileToAttach = this.filesToAttach[i];
                if (fileToAttach._type === "CoverImage") {
                    counter++;
                }

                if (fileToAttach._id === asset._id) {
                    return counter;
                }
            }
        },

        assetPositionInSelectedAssets: function (asset) {
            var counter = 0;
            for (var i in this.filesToAttach) {
                var fileToAttach = this.filesToAttach[i];
                if (fileToAttach._type !== "CoverImage") {
                    counter++;
                }

                if (fileToAttach._id === asset._id) {
                    return counter;
                }
            }
            return -1;
        },

        changeImagesContainerHeight: function changeImagesContainerHeight() {
            this.imageContainerExpended = !this.imageContainerExpended;

            if (this.imageContainerExpended) {
                this.imagesContainerHeight = this.imagesExpendedContainerHeight;
            } else {
                if (!this.showCropTab && (this.files && this.files.length > 0)) {
                    this.imagesContainerHeight = "45vh";
                }
                else {
                    this.imagesContainerHeight = "65vh";
                }
            }
        },

        drag: function drag($isDragging) {
            this.isFileDragged = $isDragging;
            this.$scope.$applyAsync();
        }
    });
}());
