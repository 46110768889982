(function () {
    'use strict';

    const ANALYTICS_SOURCE = 'instant deposit promote auto modal';

    const MODES = {
        'payments': {
            titleTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_PROMOTE_AUTO_TOGGLE_MODAL._TITLE_',
            descriptionTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_PROMOTE_AUTO_TOGGLE_MODAL._DESCRIPTION_',
            noteTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_PROMOTE_AUTO_TOGGLE_MODAL._NOTE_'
        },
        'upcomingPaymentsModal': {
            titleTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_CONFIRM_MULTI_TOGGLE_MODAL._TITLE_',
            descriptionTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_CONFIRM_MULTI_TOGGLE_MODAL._DESCRIPTION_',
            noteTranslationKey: 'COMPANY_SETTING.BANK_ACCOUNT.INSTANT_DEPOSIT_CONFIRM_MULTI_TOGGLE_MODAL._NOTE_'
        }
    };

    Controllers.InstantDepositPromoteAutoModalController = class InstantDepositPromoteAutoModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $translate, AnalyticsService, CompaniesManager, source) {
            super($scope, $injector);
            this.__objectType = 'InstantDepositPromoteAutoModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;
            this.CompaniesManager = CompaniesManager;
            this.AnalyticsService = AnalyticsService;
            this.source = source;
            this.mode = MODES[source] || MODES['payments'];
            this.allInclusiveInstantPayoutsFlag = false;
            this.AnalyticsService.trackLoad(this, ANALYTICS_SOURCE, {source: this.source});

            this.loading = false;
        }

        isConfirmDisabled() {
            return this.loading || !this.allInclusiveInstantPayoutsFlag;
        }

        confirm() {
            var companyAllInclusiveInstantPayoutsToggled = false;
            if (!this.allInclusiveInstantPayoutsFlag) {
                // should not happen, btn should be disabled...
                this.$modalInstance.close(companyAllInclusiveInstantPayoutsToggled);
                return;
            }

            this.loading = true;
            this.CompaniesManager.updateAllInclusiveInstantPayouts(this.CompaniesManager.getCurrCompany(), true, this.source + '_universal_toggle')
                .then(resp => {
                    this.AnalyticsService.trackSuccess(this, 'universal instant deposit enabled',
                        {toggledTo: true, source: ANALYTICS_SOURCE});
                    companyAllInclusiveInstantPayoutsToggled = resp && resp.data && resp.data.all_inclusive_instant_payouts;
                })
                .catch(
                    function onError(resp) {
                        this.AnalyticsService.trackError(this, ANALYTICS_SOURCE, resp);
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                            (resp && resp.data.error_message) || 'ERRORS.SERVER_API._UNKNOWN_');
                    }.bind(this))
                .finally(() => {
                    this.loading = false;
                    this.$modalInstance.close(companyAllInclusiveInstantPayoutsToggled);
                });
        }

        cancel() {
            this.$modalInstance.dismiss();
        }
    };


}());
