(function () {
    "use strict";

    // @ngInject
    function EmptyEventControllerCtor($scope, $injector,
                                      EventService, UsersManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'EmptyEventController';
        this.EventService = EventService;

        this.currUser = UsersManager.getCurrUser();
    }

    Controllers.EmptyEventController = Class(Controllers.BaseController, {

        constructor: EmptyEventControllerCtor,

        createEvent: function createEvent() {
            this.EventService.createEventCommand();
        }

    });
}());
