(function () {
    "use strict";



    Controllers.InteractiveOnboardingExplainServicesModalController = class InteractiveOnboardingExplainServicesModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $modalInstance
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingExplainServicesModalController';

            this.$modalInstance = $modalInstance;
        }

        handleClose() {
            this.$modalInstance.close();
        }

    }
}());
