(function () {
    'use strict';

    class ClientPortalResetPasswordController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, UsersManager, RecaptchaService) {
            super($scope, $injector);
            this.__objectType = 'ClientPortalResetPasswordController';

            this.UsersManager = UsersManager;
            this.RecaptchaService = RecaptchaService;

            this.showResetForm = true;
            this.showConfirmation = false;
            this.RecaptchaService.load();
        }

        onResetPasswordClick() {

            if (this.previewOnly) {
                return;
            }

            this.resetPasswordForm.$setSubmitted();

            if (this.resetPasswordForm.$invalid) {
                return;
            }

            this.emailError = false;
            this.inProgress = true;
            this.RecaptchaService.getRecaptchaToken("resetPasswordCP").then(recaptchaToken => {
                const params = {client_portal_company_id: this.portalModel.company_id, "g-recaptcha-response-v3": recaptchaToken};
                this.UsersManager.sendResetPassword(this.user, params).finally(() => {
                    this.showResetForm = false;
                    this.showConfirmation = true;
                    this.inProgress = false;
                });
            }).catch(err => {
                this.showResetForm = false;
                this.showConfirmation = true;
                this.inProgress = false;
            });
        }
    }

    Components.ClientPortalResetPassword = {
        bindings: {
            user: '<',
            portalModel: '<',
            onBackClick: '&',
            previewOnly: '<'
        },
        controller: ClientPortalResetPasswordController,
        name: 'hbClientPortalResetPassword',
        templateUrl: 'angular/app/modules/core/features/login/client_portal/client_portal_reset_password_template.html',
    };

}());