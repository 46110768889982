Components.DividerComponent = {
    bindings: {
        orientation: '@',
    },
    // @ngInject
    controller: function DividerComponentController() {
        this.cssClass = [
            'hb-divider',
            'hb-divider--' + this.orientation
        ];
    },
    name: 'hbDivider',
    templateUrl: 'angular/app/modules/common/ui_components/divider/divider_component.html',
};
