(function () {
    "use strict";

    Controllers.TemplatesExplainModalController = class TemplatesExplainModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $translate,
            $modalInstance,
            UiPersistenceService,
            isReturnUser
        ) {
            super($scope, $injector);
            this.__objectType = 'TemplatesExplainModalController';
            this.$modalInstance = $modalInstance;
            this.$translate = $translate;
            this.UiPersistenceService = UiPersistenceService;
            this.isReturnUser = isReturnUser;

            const returnOrFirstTime = this.isReturnUser ? 'RETURN_USER' : 'FIRST_TIME';
            this.title = this.$translate.instant(`TEMPLATES_PAGE.EXPLAIN_MODAL.${returnOrFirstTime}._TITLE_`);
            this.desc1 = this.$translate.instant(`TEMPLATES_PAGE.EXPLAIN_MODAL.${returnOrFirstTime}.DESCRIPTION._P1_`);
            this.desc2 = this.$translate.instant(`TEMPLATES_PAGE.EXPLAIN_MODAL.${returnOrFirstTime}.DESCRIPTION._P2_`);

            this.updateTagText = this.$translate.instant('TEMPLATES_PAGE.EXPLAIN_MODAL._UPDATE_TAG_');
        }

        dismiss() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.templatesExplainModal,
                { hasNotSeen: false }
            );
            this.$modalInstance.close();
        }

        close() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.templatesExplainModal,
                { hasNotSeen: false }
            );
            this.$modalInstance.close();
        }
    };

}());
