// @ngInject
Models.BrochureProposalsContainerModel = function BrochureProposalsContainerModel(BaseModel, _,
                                                                                  ProposalsManager, BrochuresManager){
    return Class(BaseModel, {

        constructor: function constructor(){
            this.constructor.$super.call(this);
            this.__className = 'BrochureProposalsContainerModel';

            this.cachable = false;
        },

        afterMixingFrom: function afterMixingFrom(object) {

            if (object.a_la_carte_proposal === null) {
                this.a_la_carte_proposal._id = null;
            }

            if (object.greeting === null) {
                this.greeting = null;
            }
        },

        mapChilds: function mapChilds(){
            return {
                brochure_proposals: 'BrochureProposalsCollection',
                a_la_carte_proposal: 'BrochureALaCarteProposalModel'
            };
        },

        getAnalyticsArgs: function getAnalyticsArgs() {
            return this.__parent.getAnalyticsArgs();
        },

        isInvoice: function isInvoice() {
            return false;
        },

        hasIntro: function hasIntro() {
            return !!this.greeting;
        },

        hasPackages: function hasPackages() {
            return this.brochure_proposals.length > 0;
        },

        hasALaCarte: function hasALaCarte() {
            return this.a_la_carte_proposal && !this.a_la_carte_proposal.isEmpty();
        },

        allowDeleteIntro: function allowDeleteIntro() {
            return this.hasIntro() && (this.hasPackages() || this.hasALaCarte());
        },

        allowDeletePackage: function allowDeletePackage() {
            return this.brochure_proposals.length >= 2 || this.hasIntro() || this.hasALaCarte();
        },

        allowDeleteALaCarte: function allowDeleteALaCarte() {
            return this.hasALaCarte() && (this.hasIntro() || this.hasPackages());
        },

        /*****************************************
         * PACKAGES ACTIONS
         ******************************************/

        addPackage: function addPackage(proposal, packageToAddId) {
            return this.__parent.addPackageToBrochure(this, proposal, packageToAddId);
        },

        addAdditionalServiceToPackage: function addAdditionalServiceToPackage(proposal, packageModel, newService) {
            return this.__parent.addAdditionalServiceToPackageInBrochure(this, proposal, packageModel, newService);
        },

        addCustomItem: function addCustomItem(proposal, itemData) {
            return this.__parent.addCustomItemToBrochure(this, proposal, itemData);
        },

        addServiceToBrochureALaCarte: function addServiceToBrochureALaCarte(serviceData) {
            return this.__parent.addServiceToBrochureALaCarte(this, serviceData);
        },

        deleteServiceFromBrochureALaCarte: function deleteServiceFromBrochureALaCarte(serviceModel) {
            return this.__parent.deleteServiceFromBrochureALaCarte(this, serviceModel);
        },

        updateServiceOnBrochureALaCarte: function updateServiceOnBrochureALaCarte(serviceModel) {
            return this.__parent.updateServiceOnBrochureALaCarte(this, serviceModel.dataOnly());
        },

        updateBrochureALaCarteImage: function updateBrochureALaCarteImage(image) {
            return this.__parent.updateBrochureALaCarteImage(this, image);
        },

        deleteService: function deleteService(proposal, serviceModel, packageModel) {
            return this.__parent.deleteServiceFromBrochure(this, proposal, serviceModel, packageModel);
        },

        shouldShowSVC: function () {
            return false;
        },

        deletePackage: function deletePackage(proposal, packageModel) {
            return this.__parent.deletePackageFromBrochure(this, proposal, packageModel);
        },

        deleteServiceImage: function deleteServiceImage(proposal, serviceModel, packageModel) {
            return this.__parent.deleteServiceImageOnBrochure(this, proposal, serviceModel, packageModel);
        },

        updateService: function updateService(proposal, serviceModel, packageModel) {
            return this.__parent.updateServiceOnBrochure(this, proposal, serviceModel, packageModel);
        },

        updatePackage: function updatePackage(proposal, packageModel) {
            return this.__parent.updatePackageOnBrochure(this, proposal, packageModel);
        },

        updateProposalsOrder: function updateProposalsOrder(proposalsOrder) {
            return this.__parent.updateBrochureProposalsOrder(this, proposalsOrder);
        },

        updateServicesOrder: function updateServicesOrder(proposal, packageModel) {
            return this.__parent.updateBrochureServicesOrder(this, proposal, packageModel);
        },

        updateServicesOrderInALaCarte: function updateServicesOrderInALaCarte(aLaCarteModel) {
            return this.__parent.updateServicesOrderInALaCarte(this, aLaCarteModel);
        },

        updateServiceSelection: function updateServiceSelection(serviceModel, isSelected) {
            return this.__parent.updateALaCarteServiceSelection(this, serviceModel, isSelected);
        },
        
        updateProposal: function updateProposal(proposalData) {
            // TODO dvir wat is happening here? brochure should't call update proposal
            //return this.__parent.updateProposal(proposalData);
        },

        updateServiceImage: function updateServiceImage(proposal, serviceModel, image, packageModel) {
            return this.__parent.updateServiceImageOnBrochure(this, proposal, serviceModel, packageModel, image);
        },

        updateImageInALaCarteService: function updateImageInALaCarteService(serviceModel, image) {
            return this.__parent.updateImageInALaCarteService(this, serviceModel, image);
        },

        deleteImageFromALaCarteService: function deleteImageFromALaCarteService(serviceModel) {
            return this.__parent.deleteImageFromALaCarteService(this, serviceModel);
        },

        updateBrochureALaCarte: function updateBrochureALaCarte() {
            return this.__parent.updateBrochureALaCarte(this);
        },

        /*****************************************
         * BROCHURE ACTIONS
         ******************************************/
        updateBrochure: function updateBrochure(callingScope) {
            return this.__parent.updateBrochure(this, callingScope);
        },

        createGreeting: function createBrochureGreeting() {
            return this.__parent.createBrochureGreeting(this);
        },

        updateBrochureGreeting: function updateBrochureGreeting() {
            return this.__parent.updateBrochureGreeting(this);
        },

        deleteGreeting: function deleteGreeting() {
            return this.__parent.deleteBrochureGreeting(this);
        },

        updateBrochureGreetingImage: function updateBrochureGreetingImage(image) {
            return this.__parent.updateBrochureGreetingImage(this, image);
        },

        createBrochureProposal: function createBrochureProposal() {
            return this.__parent.createBrochureProposal(this);
        },

        deleteBrochureProposal: function deleteBrochureProposal(brochureProposal) {
            return this.__parent.deleteBrochureProposal(brochureProposal, this);
        },

        createBrochureALaCarteProposal: function createBrochureALaCarteProposal() {
            return this.__parent.createBrochureALaCarteProposal(this);
        },

        deleteBrochureALaCarteProposal: function deleteBrochureALaCarteProposal() {
            return this.__parent.deleteBrochureALaCarteProposal(this);
        },
        
        updateBrochureProposal: function updateBrochureProposal(brochureProposal) {
            return this.__parent.updateBrochureProposal(this, brochureProposal);
        },

        updateBrochureProposalImage: function updateBrochureProposalImage(proposal, imageData) {
            return this.__parent.updateBrochureProposalImage(this, proposal, imageData);
        },

        selectBrochureProposal: function selectBrochureProposal(brochureProposal) {
            return this.__parent.selectBrochureProposal(brochureProposal);
        }
    });

};

