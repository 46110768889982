(function () {

    // @ngInject
    function CompanySettingsBrandingControllerCtor($scope, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsBrandingController';
    }

    Controllers.CompanySettingsBrandingController = Class(Controllers.BaseController, {
        constructor: CompanySettingsBrandingControllerCtor,


    });

}());
