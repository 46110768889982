(function () {
    // @ngInject
    Services.CKEDITOR = function ckeditor($window) {
        // Enable CKEditor in all environments except IE7 and below
        // http://docs.ckeditor.com/#!/guide/dev_unsupported_environments
        if ($window.CKEDITOR && (!$window.CKEDITOR.env.ie || $window.CKEDITOR.env.version > 7 )) {
            $window.CKEDITOR.env.isCompatible = true;
        }

        // enable browser native context menu; enable spell check
        $window.CKEDITOR.dom.element.prototype.disableContextMenu = function(){};
        $window.CKEDITOR.config.removePlugins = 'liststyle,tabletools,contextmenu';
        $window.CKEDITOR.config.disableNativeSpellChecker = false;

        CKEDITOR.on('instanceReady', function (ev) {
            // Prevent drag-and-drop.
            ev.editor.document.on('drop', function (ev) {
               ev.data.preventDefault(true);
            });
         });

        return $window.CKEDITOR;
    };

}());
