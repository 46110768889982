(function () {
    'use strict';

    // @ngInject
    function PipelineSettingsControllerCtor($scope, $injector, $modalInstance, _, $log, $timeout, $translate, $, UIUtils,
                                            AnalyticsService, currentUserCompany, CompaniesManager, WorkspaceService, ModalService, PendingTasksManager, DeviceService, PopupMessageService) {
        var self = this;
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PipelineSettingsController';
        AnalyticsService.trackPageView(this, 'PipelineSettingsView');

        this.$log = $log;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.$ = $;
        this.$modalInstance = $modalInstance;
        this.PopupMessageService = PopupMessageService;

        this.AnalyticsService = AnalyticsService;
        this.WorkspaceService = WorkspaceService;
        this.CompaniesManager = CompaniesManager;
        this.PendingTasksManager = PendingTasksManager;
        this.currentUserCompany = currentUserCompany;
        this.ModalService = ModalService;
        this.DeviceService = DeviceService;
        this.UIUtils = UIUtils;

        this.currentPipelineStages = angular.copy(this.currentUserCompany.user_pipeline_stages);
        this.stages = [];
        this.additionalStagesPool = [];
        this.additionalStagesPoolIdCounter = 0;
        this.maxNumberOfEdiableStages = 10;
        this.worksapcesAdjustmets = [];
        this.adjustingWorkspaces = false;
        this.currentStageText = 'PIPELINE.SETTINGS._ADD_STAGE_TITLE_';
        this.dragInProcess = false;
        this.workspaceUpdatePromise = null;
        this.hoveredStage = null;
        this.isPipelineHovered = false;

        this.stagesSortableOptions = {
            revert: 200,
            zIndex: 9999999,
            axis: 'x',
            handle: '.drag-icon-container',
            //disabled: (!this.isEditableMode || this.isPreviewMode),
            start: function (event, ui) {
                self.dragInProcess = true;
                self.$scope.$applyAsync();
                self.debugConsoleLog("start to drag a stage");
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.pipeline_stage_drag_started);
            },

            update: function update(event, ui) {
                self.debugConsoleLog("update");
            },

            stop: function stop(event, ui) {
                self.dragInProcess = false;
                self.$scope.$applyAsync();
                if ((ui.item.sortable.dropindex !== undefined) && (ui.item.sortable.index !== ui.item.sortable.dropindex)) {
                    // the item got moved
                    self.debugConsoleLog('in stop after drop');
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.pipeline_stage_drag_dropped);
                    self.reassignStagesOrder();
                }
            }
        };

        this.newStagesPoolSortableOptions = {
            revert: 200,
            zIndex: 9999999,
            connectWith: '.pipeline-stages', //a unidirectional. you can only drat from this list to the other list
            handle: '.drag-icon-container',
            items: 'li:not(.not-sortable)',
            //disabled: (!this.isEditableMode || this.isPreviewMode),
            start: function startDragFromPoolHandler(event, ui) {
                self.dragInProcess = true;
                self.$scope.$applyAsync();
                self.debugConsoleLog("start to drag a new step from new stage pool");
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.pipeline_stage_drag_started);
            },

            update: function update(event, ui) {
                self.debugConsoleLog("update new stage pool");
                self.completeStageText();
            },

            stop: function stop(event, ui) {
                self.dragInProcess = false;
                self.$scope.$applyAsync();
                self.debugConsoleLog('in stop after drop new stage pool');
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.pipeline_stage_drag_dropped);
                self.reassignStagesOrder();
            }
        };

        $scope.$on('$destroy', function() {
            // if, for some reason, escBlock is still running
            self.unblockEscKey();
        });

        this.bypassStateChange = false;
        $scope.$on('$stateChangeStart', function() {
            if (!this.bypassStateChange) {
                this.close();
            }
        }.bind(this));

        this.init();

        this.isMobile = this.DeviceService.checkIfMobileSize();
    }

    Controllers.PipelineSettingsController = Class(Controllers.BaseController, {
        constructor: PipelineSettingsControllerCtor,
        debugConsoleLog: function (message) {
            //made this logging function so it would be easier to trace all the dragging and the moving of workspaces
            //once a stage is deleted
            //this.$log.log(message);
        },

        fillInUnnamedStages: function fillInUnnamedStages() {
            this._.forEach(this.stages, this.fillInUnnamedStage.bind(this));
        },

        fillInUnnamedStage: function fillInUnnamedStage(stage) {
            if (stage.name === "") {
                stage.name = this.$translate.instant('PIPELINE.SETTINGS._UNTITLED_STAGE_NAME_');
            }
        },

        generateEmptyStage: function generateEmptyStage() {
            this.additionalStagesPoolIdCounter++;

            return {
                clientId: this.additionalStagesPoolIdCounter,
                name: '',
                is_editable: true,
                isBeingAdded: false
            };
        },

        init: function init() {
            var self = this;
            var numberOfStagesInPool = 0;
            var numberOfEditableStages = 0;
            //build the initial list of new stages according to the current list the user has
            this._.forEach(this.currentPipelineStages, function iterator(stage, index) {
                var stageViewModel = this.generateEmptyStage();
                angular.extend(stageViewModel, stage);

                this.stages.push(stageViewModel);
                stageViewModel.name = this.WorkspaceService.getPipelineStageName(stageViewModel.name);
                stageViewModel.original_name = stageViewModel.name;

                if (stage.is_editable) {
                    numberOfEditableStages++;
                }
            }.bind(this));

            //sortBy will keep the current order in case there is no order on the pipeline stages
            //if there is an order then it makes sure they will be shown correctly
            this.stages = this._.sortBy(this.stages, function(stageViewModel){
                return stageViewModel.order ? stageViewModel.order : 0;
            });

            this.stages = this.stages.map(this.assignTooltipText);

            numberOfStagesInPool = this.maxNumberOfEdiableStages - numberOfEditableStages;

            //build the additional stages pool to add now stages from
            for(var i = 0; i < numberOfStagesInPool; i++){
                this.additionalStagesPool.push(this.generateEmptyStage());
            }
        },

        assignTooltipText: function assignTooltipText(stageViewModel){
            
            var tooltipText = [
                {
                    title: "Inquiry",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._INQUIRY_'
                },
                {
                    title: "Follow Up",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._FOLLOW_UP_'
                },
                {
                    title: "Proposal Sent",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PROPOSAL_SENT_'
                },
                {
                    title: "Proposal Signed",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PROPOSAL_SIGNED_'
                },
                {
                    title: "Retainer Paid",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._RETAINER_PAID_'
                },
                {
                    title: "Contract Signed",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PROPOSAL_SIGNED_'
                },
                {
                    title: "Contract Paid",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._RETAINER_PAID_'
                },
                {
                    title: "Planning",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PLANNING_'
                },
                {
                    title: "Completed",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._COMPLETED_'
                },
                {
                    title: "In Progress",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._IN_PROGRESS_'
                },
                {
                    title: "Proposal",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PROPOSAL_'
                },
                {
                    title: "Payment Received",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._PAYMENT_RECEIVED_'
                },
                {
                    title: "Default",
                    copy: 'PIPELINE.SETTINGS.TOOLTIP_COPY._DEFAULT_'
                },
            ];

            stageViewModel.tooltip = tooltipText.find(function(stage) {
              return (stage.title == stageViewModel.name 
                   || stage.title == "Default");
            }).copy;

            return stageViewModel;
        },

        //assign a new order according to the new location of the stages after the drop
        reassignStagesOrder: function reassignStagesOrder() {
            this._.forEach(this.stages, function (stage, index) {
                stage.order = index;
            });
        },

        updateHoveredStage: function updateHoveredStage(stage, hovered) {
            this.hoveredStage = stage;
            this.isPipelineHovered = hovered;
        },

        onDeleteStage: function onDeleteStage(stage) {
            this.deleteStage(stage);
        },

        onAddStageFromStagesPool: function onAddStageFromStagesPool(emptyStage) {
            if (emptyStage.isBeingAdded) {
                return;
            }
            this.stageText('_DRAG_STAGE_');
            emptyStage.isBeingAdded = true;
            emptyStage.tooltip = 'PIPELINE.SETTINGS.TOOLTIP_COPY._DEFAULT_';

        },

        onStopEditingNewStage: function onStopEditingNewStage(emptyStage, $event) {
            $event.stopPropagation();
            this.stageText('_ADD_STAGE_TITLE_');
            emptyStage.isBeingAdded = false;
        },

        stageText: function stageText(currDirectionText) {
            var baseText = 'PIPELINE.SETTINGS.';
            this.currentStageText = baseText + currDirectionText;
        },

        deleteStage: function deleteStage(stage) {
            var stageIndexToRemove = this._.findIndex(this.stages, {clientId: stage.clientId});
            if(stageIndexToRemove < 0){
               return;
            }

            var stageToMoveWorkspacesTo;
            if(stageIndexToRemove === 0){
                //this is the first step move all the workspaces in this stage to the one stage that is next
                stageToMoveWorkspacesTo = this.stages[1];
            } else {
                //move all the workspace on this stage to the previous one
                stageToMoveWorkspacesTo = this.stages[stageIndexToRemove -1];
            }

            this.ModalService.openConfirmDeleteStageModal(stageToMoveWorkspacesTo.name).then(function (result) {
                // if user clicks cancel, stop execution
                if (!result) {
                    return;
                }

                if (stage._id) {
                    this.debugConsoleLog('deleting a server stage...moving workspaces from: ' + stage.name + ' to:' + stageToMoveWorkspacesTo.name);
                    // this is a stage currently set on the server
                    this.worksapcesAdjustmets.push({
                        workspaces_on_stage_client_id: stage.clientId,
                        workspaces_on_stage_server_id: stage._id,
                        workspaces_on_stage_name: stage.name, //just for debugging
                        should_move_to_stage_client_id: stageToMoveWorkspacesTo.clientId
                    });
                }

                //check if he contains workspaces that were suppose to move to it
                var workspacesAdjustments = this._.where(this.worksapcesAdjustmets, {should_move_to_stage_client_id: stage.clientId});
                this._.forEach(workspacesAdjustments, function (workspacesAdjustment) {
                    //there were some listing of moving workspaces to this stage change it to be the new stage id to move to
                    workspacesAdjustment.should_move_to_stage_client_id = stageToMoveWorkspacesTo.clientId;
                    this.debugConsoleLog('found a previous workspaces that were supposed to move here...moving workspaces from: ' + workspacesAdjustment.workspaces_on_stage_name + ' to:' + stageToMoveWorkspacesTo.name);
                }.bind(this));

                this.stages.splice(stageIndexToRemove, 1);
                this.additionalStagesPool.push(this.generateEmptyStage());

                this.reassignStagesOrder();
            }.bind(this));

        },

        close: function close(result) {
            this.bypassStateChange = true;
            this.$modalInstance.close(result);
        },

        abort: function abort(error) {
            this.$modalInstance.dismiss(error);
        },

        checkIfChangesWereMadeToStages: function checkIfChangesWereMadeToStages() {
            var stagesWereChanged = false;
            if(this.currentUserCompany.user_pipeline_stages.length !== this.stages.length){
                stagesWereChanged = true;
            } else {
                //check the actual stages
                for(var i = 0; i < this.stages.length; i++){
                    if (this.currentUserCompany.user_pipeline_stages[i]._id !== this.stages[i]._id ||
                        this.currentUserCompany.user_pipeline_stages[i].order !== this.stages[i].order ||
                        (this.stages[i].is_editable && this.stages[i].original_name !== this.stages[i].name)) {
                        // because of the merging mechanism on our model (also from the local storage) it is possible that the stages
                        // will be on the same position in the array but have a different order value compare that too
                        stagesWereChanged = true;
                       break;
                   }
                }
            }

            return stagesWereChanged;
        },

        blockEscKey: function blockEscKey() {
            // disallow closing modal with esc
            this.$('body').on('keydown.pipeline.settings', function(e) {
                var escCode = 27; // escape key maps to keycode `27`

                if (e.keyCode === escCode) {
                    e.stopPropagation();
                }
            });
        },

        unblockEscKey: function unblockEscKey() {
            this.$('body').off('keydown.pipeline.settings');
        },

        onCloseClicked: function onCloseClicked() {
            this.close('dismissed');
        },

        onSaveClicked: function save() {
            if(!this.checkIfChangesWereMadeToStages()){
                this.close('dismissed');
                return;
            }

            //start modifying the workspace
            this.adjustingWorkspaces = true;
            this.blockEscKey(); // user cant close modal when save is in process

            var taskData = {
                pipeline_stages: this.stages,
                workspaces_adjustments: this.worksapcesAdjustmets
            };

            //make sure we do not send unnamed stages to the server
            this.fillInUnnamedStages();

            this.workspaceUpdatePromise = this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.changePipelineStages, taskData).then(
                function success() {
                    this.close('success');
                }.bind(this),
                function fail(error) {
                    this.abort('failed');
                }.bind(this))
                .finally(function finallyHandler() {
                    this.adjustingWorkspaces = false;
                    this.unblockEscKey();
                }.bind(this));
        },

        hasOverflow: function hasOverflow() {
            var content = angular.element('#js-pipeline-stages-list');

            if (content && content[0]){
                return content[0].offsetWidth < content[0].scrollWidth;
            }
        },

        completeStageText: function completeStageText() {
            this.stageText('_STAGE_COMPLETE_');
            this.$timeout(function() {
                this.stageText('_ADD_STAGE_TITLE_');
            }.bind(this), 5000);
        },

        onStageNameChanged: function onStageNameChanged() {
            this.reassignStagesOrder();
        },
    });
}());
