/**
 * Created by inonstelman on 1/1/15.
 */

Directives.InlineAlertDirective = function InlineAlertDirective() {

    // @ngInject
    function InlineAlertDirectiveControllerFunc($scope, $injector) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'InlineAlertDirectiveController';
        this.alertLevel = this.alertType;
        if(this.alertLevel === 'error'){
            this.alertLevel = 'alert';
        }

        this.showClose = this.closeable !== 'false';
        this.onDismiss = function onDismiss() {
            this.message = '';
        };

        this.goToLink = function goToLink()
        {
            if (angular.isDefined(this.goto))
            {
                this.goto();
            }
        };
    }

    var InlineAlertDirectiveController = Class(Controllers.BaseController, {
        constructor: InlineAlertDirectiveControllerFunc
    });

    return {
        scope: {
            message: '=message',
            alertType: '@type',
            closeable: '@closeable',
            goto: '&?goto'
        },
        templateUrl: 'angular/app/modules/common/ui_components/inline_alert/inline_alert_directive_template.html',
        controller: InlineAlertDirectiveController,
        controllerAs: 'inlineAlertVm',
        bindToController: true
    };
};
