(function() {
    'use strict';

    // @ngInject
    Components.AccountSetupTilePrompt = {
        controller: function AccountSetupTilePromptController(
            $scope,
            $element,
            $attrs,
            $state,
            $translate,
            $timeout,
            _,
            moment,
            AbTestService,
            AnalyticsService,
            AppStates,
            CompaniesManager,
            DeviceService,
            Enums,
            OnboardingService,
            UiPersistenceService,
            UsersManager
        ) {
            // View scope variables
            this.AppStates = AppStates;
            this.shouldDisplay = false;

            this.titleCopy = 'ONBOARDING.SETUP_GUIDE_MODAL.ACCOUNT_SETUP_TILE_PROMPT.SUBSCRIPTION._TITLE_';
            this.bodyCopy = 'ONBOARDING.SETUP_GUIDE_MODAL.ACCOUNT_SETUP_TILE_PROMPT.SUBSCRIPTION._BODY_';
            // Default link destination
            // This is updated by ABTests.accountSetupPromptToAccountSetupPage
            this.linkDestinationState = AppStates.root_core_accountSetup;

            // Local variables
            const currUser = UsersManager.getCurrUser();
            const isDesktop = !DeviceService.nxSmallBreakpoint();

            const UiPersistenceKey = UiPersistenceService.keys.accountSetupTilePrompt;

            var isTrialer = !currUser.companyHasSubscription();
            var isCompanyOwner = currUser.isCompanyOwner();

            CompaniesManager.on(Enums.PubSubTriggers.companySubscribed, () => {
                isTrialer = !currUser.companyHasSubscription();
                isCompanyOwner = currUser.isCompanyOwner();
            });

            const defaultUiPersistence = {
                closed: false,
                momentToHidePrompt: moment().add(1, 'week'),
                seenSetupGuide: false
            };

            const getPersistence = () => {
                return UiPersistenceService.getUiPersistence(
                    UiPersistenceKey, defaultUiPersistence
                );
            };

            const promptWasNeverClosed = () => !getPersistence()['closed'];

            const isWithinTimeToShowPrompt = () => {
                var persistencyValues = getPersistence();
                const timeToStop = persistencyValues['momentToHidePrompt'];
                const seenSetupGuide = persistencyValues['seenSetupGuide'];

                if (!seenSetupGuide) {
                    setSeenSetupGuide();
                }

                return seenSetupGuide && moment().isBefore(timeToStop);
            };

            const setClosed = () => {
                var uiPersistence = getPersistence();
                uiPersistence['closed'] = true;

                return UiPersistenceService.setUiPersistence(
                    UiPersistenceKey, uiPersistence
                );
            };

            const setSeenSetupGuide = () => {
                var uiPersistence = getPersistence();
                uiPersistence['seenSetupGuide'] = true;

                return UiPersistenceService.setUiPersistence(
                    UiPersistenceKey, uiPersistence
                );
            };

            // Event handlers
            this.handleClick = function handleClick(e) {
                e.preventDefault();
                $state.go(this.linkDestinationState);
                setClosed();
                this.shouldDisplay = false;
            };

            this.handleClose = function handleClose(e) {
                e.preventDefault();
                setClosed();
                this.shouldDisplay = false;
            };

            if (isWithinTimeToShowPrompt() &&
                promptWasNeverClosed() &&
                isDesktop &&
                isTrialer &&
                isCompanyOwner) {

                AnalyticsService.trackLoad(
                    this,
                    AnalyticsService.analytics_events.account_setup_prompt, {
                        source: 'tile'});

                $timeout(() => {
                    this.shouldDisplay = true;
                }, 2000);

            }

        },
        name: 'hbAccountSetupTilePrompt',
        templateUrl:
            'angular/app/modules/core/features/account_setup/account_setup_tile_prompt_template.html',
    };
})();
