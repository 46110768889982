(function () {
    "use strict";

    // @ngInject
    function RedemptionPolicyComponentControllerFunc($injector, $scope, $sce, AnalyticsService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'RedemptionPolicyComponentController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;
    }

    var RedemptionPolicyComponentController = Class(Controllers.BaseController, {
        constructor: RedemptionPolicyComponentControllerFunc,

        updateContent: function updateContent() {
            this.component.updateContent();
            // this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block_component, { field: 'content', component_type: "text"});
        },

        updateTitle: function updateTitle() {
            this.component.updateTitle();
            // this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block_component, { field: 'content', component_type: "text"});
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        focusField: function focusField(isFocus) {
            this.$scope.$emit('childComponentFocusChanged', isFocus);
        },

        resetToDefault: function resetToDefault() {
            this.component.resetToDefault();
        },

        toggleRedemptionPolicyIsOpen: function toggleRedemptionPolicyIsOpen() {
            this.isContentOpen = !this.isContentOpen;

            var contentContainer = document.querySelector('.redemption-policy-content');

            if (this.isContentOpen) {
                contentContainer.style.maxHeight = contentContainer.scrollHeight + 'px';
            } else {
                contentContainer.style.maxHeight = '0px';
            }
        },
    });

    Components.RedemptionPolicyComponent = {
        bindings: {
            component: '=',
            isEditable: '<',
        },
        controller: RedemptionPolicyComponentController,
        controllerAs: 'redemptionPolicyVm',
        name: 'hbRedemptionPolicyComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/redemption_policy/redemption_policy_component.html',
    };

}());
