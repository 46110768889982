(function () {
    'use strict';

    Services.ServiceLazyLoaderService = class ServiceLazyLoaderService {

        // @ngInject
        constructor($injector, Gon) {
            this.$injector = $injector;
            this.Gon = Gon;
            this.serviceCache = {};
            this.proxiesCache = {};
        }

        get(serviceName) {
            if (this.Gon && this.Gon.bypass_service_loader_proxy) {
                return this.$injector.get(serviceName);
            }

            if (!this.proxiesCache[serviceName]) {
                this.proxiesCache[serviceName] = new Proxy({serviceName: serviceName}, {
                    get: (target, p) => {
                        if (!this.serviceCache[target.serviceName]) {
                            this.serviceCache[target.serviceName] = this.$injector.get(target.serviceName);
                        }
                        return this.serviceCache[target.serviceName][p];
                    }
                });
            }

            return this.proxiesCache[serviceName];
        }
    };

}());