Directives.WidgetDirective = function WidgetDirective() {
    'use strict';

    // @ngInject
    function WidgetDirectiveControllerFunc($scope, $injector,$filter,$timeout, $window, $, UsersManager, Gon, AnalyticsService, CompaniesManager, $sce, AppConfigService,CompanyService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WidgetDirectiveController';
        this.currentUser = UsersManager.getCurrUser();
        this.isMalkut = AppConfigService.isMalkutServer();
        if (this.user === undefined)
        {
            this.user = this.currentUser;
        }

        this.companiesManager = CompaniesManager;
        this.company = this.companiesManager.getCurrCompany();
        var self = this;

        var setCompanyUrl = function setCompanyUrl() {
            if (this.company.hb_url) {
                this.company_hb_url = AppConfigService.appConfiguration().url + "widget/" + this.company.hb_url;
            }
        }.bind(this);
        if (this.company.wasFullyFetched()) {
            setCompanyUrl();
        } else {
            // there is a default company type until the model will be loaded
            this.register(this.company, 'success', setCompanyUrl);
        }

        this.lastWidgetColor = "#7bc8cd";
        this.loadingWidget = true;
        this.currURLPrefix = $window.location.origin;
        var widgetKey = 'assets_users_production/companies/' + this.user.company._id + '/widgets/honeybook_widget.html';
        this.website_url  = this.user.company.website_url;
        this.widget_model = this.user.company;
        this.allowPublish = true;



        // we read the widget through a proxy in our server so
        // we will not be bound by CORS when trying to change the content of the widget
        CompaniesManager.getCompanyWidget(this.user.company).then(
            function gotWidget(resp){
                self.widgetIFrameSourceURL = $sce.trustAsHtml(resp.data.html);
                self.iframeCode = resp.data.iframe_code;
            }
        );


        this.trustSrc = function trustSrc(src){
            return $sce.trustAsResourceUrl(src);
        };

        this.onIframeLoad = function onIframeLoad() {
            // We get the 'window' element of the iframe and then we can
            // use its jQuery function for manipulations
            this.$iframeWin = $('iframe#widgetIframe')[0].contentWindow;

            // Now we read the selected color
            this.loadColorPicker(this.$iframeWin.$("#hb_embed_signup .button").css("background-color"));

            this.fixUserDetails();
            this.setFormTargetInNonProductionMachines();

            this.loadingWidget = false;

            if (this.isMalkut) {
                // take it directly from the server...
                this.iframeCode = this.$iframeWin.document.documentElement.outerHTML;
            }else{
                this.disableWidgetForm();
            }

        }.bind(this);

        this.changeColor = function changeColor(color) {
            self=this;
            $timeout(function () {
                self.$iframeWin.$("#hb_embed_signup .button").css("background-color", color);
                self.$iframeWin.$("#hb_embed_signup .hb-field-group textarea").css("border-color", color);
                self.$iframeWin.$("#hb_embed_signup .hb-field-group input").css("border-color", color);
                self.$iframeWin.$("#hb-signup-success").css("border-color", color);

                AnalyticsService.track(self, AnalyticsService.analytics_events.widget_color_changed, {
                    company_url: self.website_url,
                    vendor_email: self.user.email,
                    color: color
                });
            }, 1);

        };


        this.enableCode = function () {
//  note: commented out wasnt working.  $("#enableCode").addClass("button-bg-copy").removeClass("button-bg-copy.open");
            this.class_status = true;
            if (!this.isMalkut) {
                this.publishWidget(true);
            }
        };

        this.onCopyCode = function keyupOnCode(e) {
            return AnalyticsService.track(this, 'copy widget code', {
                company_url: this.website_url,
                vendor_email: this.user.email
            });
        };

        this.setFormTargetInNonProductionMachines = function setFormTargetInNonProductionMachines() {
            if (Gon.environment !== 'production') {
                var currTarget = this.$iframeWin.$('form').attr('action');
                this.$iframeWin.$('form').attr('action', currTarget.replace(/https?:\/\/[^\/]+/, this.currURLPrefix));
            }
        };

        this.fixUserDetails = function fixUserDetails() {
            this.$iframeWin.$('form input#vendor-id').val(this.user._id);
            this.$iframeWin.$('form input#vendor-email').val(this.user.email);
            this.$iframeWin.$('form input#vendor-name').val(this.user.full_name);
        };

        this.disableWidgetForm = function disableWidgetForm() {
            this.$iframeWin.$('input,textarea,button').attr('disabled', '');
        };

        this.enableWidgetForm = function enableWidgetForm() {
            this.$iframeWin.$('input,textarea,button').removeAttr('disabled');
        };


        this.publishWidget = function publishWidget(doNotOverwrite) {
            if(!doNotOverwrite){
                this.publishing = true;
            }

            this.enableWidgetForm();
            var content = this.$iframeWin.$('html')[0].outerHTML;
            if (this.isMalkut)
            {
                content = this.iframeCode;
            }
            this.disableWidgetForm();

            var success = function success() {
                if (this.isMalkut) {
                    this.reload();
                }
                this.publishing = false;

                AnalyticsService.track(this, 'Widget publish was successful', {
                    companyId: this.widget_model._id
                });
            }.bind(this);

            var failure = function failure() {
                this.publishing = false;
                this.publishText = 'Couldn\'t save widget';
                AnalyticsService.track(this, 'Widget publish failed', {
                    companyId: this.widget_model._id
                });
            }.bind(this);


            CompaniesManager.publishWidgetContent(this.user.company, content, doNotOverwrite).then(
                success,
                failure
            );
        };

        this.loadColorPicker = function loadColorPicker(color) {
            $("#widgetSetting #color-picker").spectrum({
                color: color,
                showInput: true,
                className: "full-spectrum",
                showInitial: true,
                showPalette: true,
                showSelectionPalette: true,
                maxPaletteSize: 10,
                preferredFormat: "hex",
                localStorageKey: "spectrum.hb",
                clickoutFiresChange: true,
                hide: function (color) {
                    if(this.selectedColorFromPalette){
                        this.changeColor(this.selectedColorFromPalette.toString());
                    }
                }.bind(this),
                change: function (color) {
                    this.selectedColorFromPalette = color;
                }.bind(this),
                show: function (color) {
                    this.selectedColorFromPalette = null;
                }.bind(this),
                palette: [
                    ["rgb(0, 0, 0)", "rgb(67, 67, 67)", "rgb(102, 102, 102)", "rgb(204, 204, 204)",
                        "rgb(217, 217, 217)", "rgb(255, 255, 255)", "rgb(255, 153, 0)", "rgb(255, 255, 0)",
                        "rgb(0, 255, 0)", "rgb(0, 255, 255)", "rgb(74, 134, 232)", "rgb(230, 184, 175)",
                        "rgb(244, 204, 204)", "rgb(252, 229, 205)", "rgb(255, 242, 204)", "rgb(217, 234, 211)",
                        "rgb(208, 224, 227)", "rgb(201, 218, 248)", "rgb(207, 226, 243)", "rgb(217, 210, 233)",
                        "rgb(234, 209, 220)", "rgb(221, 126, 107)", "rgb(234, 153, 153)", "rgb(249, 203, 156)",
                        "rgb(255, 229, 153)", "rgb(182, 215, 168)", "rgb(162, 196, 201)", "rgb(164, 194, 244)",
                        "rgb(159, 197, 232)", "rgb(180, 167, 214)", "rgb(213, 166, 189)", "rgb(204, 65, 37)",
                        "rgb(224, 102, 102)", "rgb(246, 178, 107)", "rgb(255, 217, 102)", "rgb(147, 196, 125)",
                        "rgb(118, 165, 175)", "rgb(109, 158, 235)", "rgb(111, 168, 220)", "rgb(142, 124, 195)",
                        "rgb(194, 123, 160)", "rgb(166, 28, 0)", "rgb(204, 0, 0)", "rgb(230, 145, 56)",
                        "rgb(241, 194, 50)", "rgb(106, 168, 79)", "rgb(69, 129, 142)", "rgb(60, 120, 216)",
                        "rgb(61, 133, 198)", "rgb(103, 78, 167)", "rgb(166, 77, 121)", "rgb(91, 15, 0)",
                        "rgb(102, 0, 0)", "rgb(120, 63, 4)", "rgb(127, 96, 0)", "rgb(39, 78, 19)", "rgb(12, 52, 61)",
                        "rgb(28, 69, 135)", "rgb(7, 55, 99)", "rgb(32, 18, 77)", "rgb(76, 17, 48)"]
                ]
            });

            $scope.$on('$destroy', function () {
                $("#widgetSetting #color-picker").spectrum('destroy');
            });
        };

    }

    var WidgetDirectiveController = Class(Controllers.BaseController, {
        constructor: WidgetDirectiveControllerFunc
    });

    return {
        templateUrl: 'angular/app/modules/common/ui_components/widget/widget_directive_template.html',
        controller: WidgetDirectiveController,
        controllerAs: 'WidgetVm',
        bindToController: true,
        scope: {
            user: '=user',
            reload: '&reload'
        }
    };
};