// @ngInject
Filters.HbmarkerFilter  = function HbmarkerFilter($filter, $sce) {
    return function(text, class_name) {
        if (!class_name) {
            class_name = "hb-marker";
        }
        // Please note that text should be sanitized before it passed in.
        text = '<span class="' + class_name + '">' + text + '</span>';

        return $sce.trustAsHtml(text);
    };
};
