
// @ngInject
Directives.TippyDirective = function TippyDirective($timeout) {
    return {
        restrict: 'C',
        link: function(scope, elem, attr) {

            /* Tippy theme instance */
            var nxTippy = (function () {

                function $(s) {
                    return document.querySelector(s)
                }

                var __defaultInstance = function() {
                    tippy('.nx-tippy', {
                        placement: 'top',
                        animation: 'fade',
                        arrow: true,
                        theme: 'nx-tippy',
                        dynamicTitle: true,
                        maxWidth: '248px'
                    });
                };

                var __useTemplate = function (template) {
                    tippy(template.selector, {
                        html: $(template.templateId),
                        maxWidth:template.maxWidth || '248px',
                        arrow: true,
                        interactive: true,
                    });
                };

                return {
                    defaultInstance: __defaultInstance,
                    useTemplate: __useTemplate,
                };

            })();


            // wait for angular evaluations before creating the tooltip, it messes up the title
            $timeout(() => {

                /* Default instance */
                nxTippy.defaultInstance();

                /* Template instance */
                if ( attr.tippyTemplateId && attr.id) {

                    var templateSettings = {
                        selector: '#' + attr.id,
                        templateId: '#' + attr.tippyTemplateId,
                        maxWidth:  attr.tippyTemplateWidth,
                    };

                    nxTippy.useTemplate(templateSettings);
                }
            });
        }
    };
};