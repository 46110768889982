/**
 * Created by inonstelman on 12/25/14.
 */
/**
 * A base mixin that other mixins can extend upon. Provides basic infrastructure for defining new
 * mixins (`extendModel`) and mixing them into objects (`mixInto`).
 */

// @ngInject

Models.BaseModel = function BaseModel(ExtendService, $q, RepositoryService, ModelFactory, _, $log, ModelUtils, Hash, moment, uuid4, PubSubService) {

    return Class(function () {

            return {

                constructor: function constructor() {
                    this.__className = 'BaseModel';

                    var childrenModels = this.mapChilds() || {};

                    /* jshint ignore:start */
                    for (var key in childrenModels) {
                        var currModel = ModelFactory.getModelClass(childrenModels[key]);

                        //We need to find a solution for recursive pointers here
                        if (this instanceof currModel) {
                            continue;
                        }
                        this[key] = new currModel();
                        this[key].__parent = this;
                    }
                    /* jshint ignore:end */

                    this.mixinIgnoreKeys = {
                        lastError: true,
                        mixinIgnoreKeys: true
                    };

                    PubSubService.ventMyBitchUp(this);

                    this.__fullyFetched = false;

                    var bubbleUpFuncs = this.bubbleUp() || [];
                    bubbleUpFuncs.forEach(function(func) {
                        this[func] = function callParent() {
                            return this._callParent(func, Array.prototype.slice.call(arguments));
                        }.bind(this);
                    }.bind(this));
                },

                //Override this in child model if name is different
                collectionName: function collectionName() {
                    if (this._collectionName) {
                        return this._collectionName;
                    }
                    this._collectionName = this.__className.replace('Model', 'sCollection');
                    return this._collectionName;
                },

                id: function id() {
                    return this._id;
                },

                className: function className() {
                    return this.__className;
                },

                hash: function hash() {

                    if (!this.__hash) {
                        this.__hash = Hash();
                    }

                    return this.__hash;
                },

                resetState: function resetState() {
                    this.lastError = '';
                },

                mapChilds: function mapChilds() {
                    return {};
                },

                bubbleUp: function bubbleUp() {
                    return [];
                },

                getCopy: function getCopy(){

                    var newModel = ModelFactory.newModel(this.className());
                    angular.copy(this.dataOnly(), newModel);
                    return newModel;
                },

                dataOnly: function dataOnly() {

                    var copy = {};
                    //we only need a shallow copy
                    //angular.extend(copy, this);

                    /* jshint ignore:start */
                    for (var key in this) {
                        if(key.lastIndexOf('__', 0) === 0 || this.mixinIgnoreKeys[key] || typeof this[key] === 'function'){
                            continue;
                        }
                        if (this[key] && typeof this[key]['dataOnly'] === 'function') {
                            copy[key] = this[key].dataOnly();
                        }else{
                            copy[key] = this[key];
                        }
                    }
                    /* jshint ignore:end */

                    this._dataOnlyInterceptor(copy);
                    return copy;
                },

                fieldsOnly: function fieldsOnly(fields, fallbackToEmptyHash) {
                    if(!fields || !fields.length) {
                        if (fallbackToEmptyHash) {
                            return {};
                        } else {
                            // no fields - return data only
                            return this.dataOnly();
                        }
                    }

                    var data = {};
                    fields.forEach(function (field, index){
                        if(this[field] !== undefined) {
                            data[field] = this[field];
                        }
                    }.bind(this));

                    return data;
                },

                _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {

                },

                /**
                 * Override this.cachable when you don't want the models to be cached (always fetched from server)
                 */
                isCachable: function isCachable() {
                    if (typeof this.cachable !== 'undefined') {
                        return this.cachable;
                    }
                    return true;
                },

                /**
                 * Creates a model from object and childs
                 * @param fromObject
                 * @param parent optional
                 * @param saveToCache default true
                 * returns model from cache!
                 */
                mixinFrom: function mixinFrom(fromObject, parent, saveToCache, isUpdateFromServer) {
                    if (typeof saveToCache !== 'boolean'){
                        saveToCache = true;
                    }

                    // If we're actually mixing into something,
                    if (fromObject) {
                        var _this = this;

                        ModelUtils.extendModel(this, fromObject, saveToCache, isUpdateFromServer);

                        if(parent){
                            this.__parent = parent;
                        }

                        // If we've got some mixing customization todo, then invoke it
                        if (_this.afterMixingFrom) {
                            _this.afterMixingFrom.apply(_this, arguments);
                        }

                        if (isUpdateFromServer) {
                            this._lastServerUpdate = moment().toString();
                        }
                    }

                    if (!saveToCache) {
                        return this;
                    } else {
                        return this.saveToCache();
                    }

                },

                saveToCache: function saveToCache() {
                    var cachedObject = this;
                    if (this.isCachable()) {
                        cachedObject = RepositoryService.cachePut(this);
                    }
                    if (this.cascadeSaveToCache) {
                        this.cascadeSaveToCache.apply(this, arguments);
                    }

                    return cachedObject;
                },

                getModelTimeout: function getModelTimeout() {
                    return 60 * 1000; // default is 1 minute
                },

                onModelError: function onModelError(message, status) {
                    if (message && message.error_message) {
                        message = message.error_message;
                    }
                    this.lastError = message;
                    this._modelUpdating = false;

                    this.trigger('error', message, status);
                },

                onModelSuccess: function onModelSuccess(message, status, data) {
                    this._modelUpdating = false;
                    this.trigger('success', message, status, data);
                },

                onModelUpdating: function onModelUpdating() {
                    this._modelUpdating = true;
                    this.trigger('update');
                },

                isModelUpdating: function isModelUpdating() {
                    return this._modelUpdating;
                },

                setFullyFetched: function setFullyFetched() {
                    this.__fullyFetched = true;
                },

                wasFullyFetched: function wasFullyFetched() {
                    return this.__fullyFetched;
                },
                
                isEmpty: function isEmpty() {
                    return !this._id;
                },

                isModelOf: function isModelOf(type) {
                    return this.__className === type + 'Model';
                },

                _callParent: function(func, args) {

                    // Validate
                    if(!this.__parent[func] || typeof this.__parent[func] !== 'function') {
                        return;
                    }

                    // Construct
                    args.unshift(this);

                    // Call
                    return this.__parent[func].apply(this.__parent, args);
                }
            };
        }
    );
};
