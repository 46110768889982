(function () {
    "use strict";

    // @ngInject
    function CreateFirstProjectControllerFunc($scope, $injector, $modalInstance, EventService, OnboardingBotManager, showCreateProject, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CreateFirstProjectController';
        this.$modalInstance = $modalInstance;
        this.EventService = EventService;
        this.OnboardingBotManager = OnboardingBotManager;
        this.showCreateProject = showCreateProject;
        this.AnalyticsService = AnalyticsService;

        this.AnalyticsService.trackPageView(this, "create first project modal");


    }

    Controllers.CreateFirstProjectController = Class(Controllers.BaseController, {
        constructor: CreateFirstProjectControllerFunc,

        dismissModal: function dismissModal() {
            this.$modalInstance.dismiss();
        },

        createBotProject: function createBotProject() {
            this.busy = true;
            this.OnboardingBotManager.startOnboardingFlow().then(function success(){
                this.dismissModal();
            }.bind(this)).finally(function() {
                this.busy = false;
            }.bind(this));
        },

        createStandardProject: function createStandardProject() {
            if (!this.busy) {
                this.dismissModal();
                this.EventService.createEventCommand();
            }
        },

        close: function close() {
            this.$modalInstance.close();
        }
    });
}());
