(function () {
    'use strict';

    // @ngInject
    function AccountSetupStatusControllerCtor($scope, $window, $injector, _, UsersManager, AccountsManager,
                                              CompaniesManager, PopupMessageService, Enums) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AccountSetupStatusController';
        this.UsersManager = UsersManager;
        this.AccountsManager = AccountsManager;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;

        this.company = this.CompaniesManager.getCurrCompany();
        this.accountSetupEvents = Enums.accountSetupEvents;
        this.eventSendInProgress = {};

        this.stageTranslator = {
            not_ready: 'Not Ready',
            ready_to_start: 'Ready to start',
            completed: 'Completed'
        };

        this.UsersManager.forceFetchCurrUser().then(() => {
            this.currUser = this.UsersManager.getCurrUser();
            this.currAccount = this.currUser.account;
            this.stage = (this.currAccount && this.currAccount.account_setup_stage) || 'not_ready';

            this.setSetupStatus();
        });
    }

    Controllers.AccountSetupStatusController = Class(Controllers.BaseController, {

        constructor: AccountSetupStatusControllerCtor,

        setSetupStatus: function() {
            this.title = this.stageTranslator[this.stage];
            this.shouldShowCTA = this.stage === 'ready_to_start';
        },

        markAsDone: function() {
            if (this.currAccount) {
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    `Are you sure you finished this account? ${this.currUser.email}`
                ).then(() => {
                    this.AccountsManager.completeManualAccountSetup(this.currAccount)
                        .then(() => {
                            this.stage = 'completed';
                            this.setSetupStatus();
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, 'Done!');
                        })
                        .catch(() => {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Failed! please refresh and try again');
                        });
                });
            } else {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'No account found');
            }
        },

        markBatchAsDone: function(index) {
            if (this.currAccount) {
                this.PopupMessageService.showConfirmPromise(
                    this.PopupMessageService.severityTypes.info,
                    `Are you sure you finished this account? ${this.currUser.email}`
                ).then(() => {
                    Promise.all([
                        this.AccountsManager.completeManualAccountSetup(this.currAccount, index)
                    ]).then(() => {
                        this.company.upload_files_batches[index] = 'completed';
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.success, 'Done!');
                    }).catch(() => {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Failed! please refresh and try again');
                    });
                });
            } else {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'No account found');
            }
        },

        sendAccountSetupEvent: function sendAccountSetupEvent(event) {
            this.eventSendInProgress[event] = true;
            this.AccountsManager.sendAccountSetupEvent(this.currAccount, event).finally(() => this.eventSendInProgress[event] = false)
        }
    });
}());
