/**
 * Created by jonahkim on 7/13/16.
 *
 * Service for managing a user's event calendar data.
 */
(function () {
    "use strict";

    // @ngInject
    function CalendarServiceCtor($q, moment, ModelFactory, EventsManager, UsersManager, AnalyticsService, Enums) {

        this.$q = $q;
        this.moment = moment;
        this.ModelFactory = ModelFactory;
        this.UsersManager = UsersManager;
        this.EventsManager = EventsManager;
        this.AnalyticsService = AnalyticsService;
        this.Enums = Enums;

        this.CALENDAR_ITEM_TYPES = {
            BOOKED_PROJECT: 'event_booked',
            LEAD_PROJECT: 'event_lead',
            PAYMENT: 'payment',
            CALENDAR_ITEM: 'calendar_item',
            TEAM: 'team',
            TEAM_TYPES: {
                TEAM_PROJECT: 'team_project',
                TEAM_MEETING: 'team_meeting'
            },
            GOOGLE_EVENT: 'google_event',
            NYLAS_EVENT: 'nylas_event',
            PAYMENT_TYPES: {
                LATE: 'late_payments',
                IMMINENT: 'imminent_payments',
                DUE: 'due_payments',
                PAID: 'paid_payments',
            }
        };

        this.CALENDAR_VIEWS = [
            {
                label: 'CALENDAR.VIEWS_MENU._DAY_VIEW_',
                key: 'agendaDay'
            },
            {
                label: 'CALENDAR.VIEWS_MENU._WEEK_VIEW_',
                key: 'agendaWeek'
            },
            {
                label: 'CALENDAR.VIEWS_MENU._MONTH_VIEW_',
                key: 'month'
            }
        ];
        this.CALENDAR_DAY_VIEW = this.CALENDAR_VIEWS[0];
        this.CALENDAR_WEEK_VIEW = this.CALENDAR_VIEWS[1];
        this.CALENDAR_MONTH_VIEW = this.CALENDAR_VIEWS[2];

        // Event data that has been loaded for the user already.
        // Hash of event date -> event data[]
        // e.g. {7-13-2016: [{id: '123', name: 'My Cool Event', is_booked: true}, ...]
        this.eventsSummary;
        this.eventsCalendar = [];
        this.initEventsCalendar();
    }

    Services.CalendarService = Class(function () {

        return {

            constructor: CalendarServiceCtor,
            goOverDateRange: function (event, callBack) {
                var startDate = event.event_date;
                var endDate = event.event_end_date;
                if (!startDate) {
                    return;
                }

                var date;
                var dateFormat = "YYYY-MM-DD";
                var currDate = this.moment(startDate, dateFormat).startOf('day');

                if (!endDate) {
                    date = currDate.format(dateFormat);
                    callBack(date);
                    return;
                }

                var lastDate = this.moment(endDate, dateFormat).startOf('day');

                while (currDate <= lastDate) {

                    date = currDate.format(dateFormat);
                    callBack(date);

                    currDate = currDate.add(1, 'days');
                }
            },

            fillArrayWithRange: function (event) {
                this.goOverDateRange(event, function (date) {
                    if (date in this.eventsCalendar) {
                        this.eventsCalendar[date].push(event);
                    } else {
                        this.eventsCalendar[date] = [event];
                    }
                }.bind(this));
            },

            initEventsCalendar: function () {
                var allEvents = this.ModelFactory.getEventsMasterCollection();
                if (!allEvents) {
                    return;
                }
                this._calendarFromCollection(allEvents);

                // Subscribe to updates
                allEvents.on('success', function (message, status, isComplete) {
                    this._calendarFromCollection(this);
                }.bind(this));
                allEvents.on('itemAdded', function(event){
                    this.fillArrayWithRange(event);
                }.bind(this));
                allEvents.on('itemRemoved', function(event){
                    this.goOverDateRange(event, function (date) {
                        if (date in this.eventsCalendar) {
                            var events = this.eventsCalendar[date];
                            var index = events.indexOf(event);
                            if (index < 0) {
                                return;
                            }
                            events.splice(index, 1);
                        }
                    }.bind(this));
                }.bind(this));
            },

            getEvents: function (date) {
                var dateStr = date.format("YYYY-MM-DD");
                return this.eventsCalendar[dateStr];
            },

            getEventsCalendar: function () {
                if (this.eventsCalendar === undefined) {
                    this.initEventsCalendar();
                }
                return this.eventsCalendar;
            },

            _calendarFromCollection: function (collection) {
                angular.forEach(collection, function (event) {
                    this.fillArrayWithRange(event);
                }.bind(this));
            },

            getPopoverType: function getPopoverType(data){
                switch (data.eventType) {
                    case this.CALENDAR_ITEM_TYPES.GOOGLE_EVENT:
                        return this.Enums.calendarPopoverTypes.google_event;

                    case this.CALENDAR_ITEM_TYPES.NYLAS_EVENT:
                        return this.Enums.calendarPopoverTypes.nylas_event;

                    case this.CALENDAR_ITEM_TYPES.CALENDAR_ITEM:
                        return this.Enums.calendarPopoverTypes.hb_meeting;

                    case this.CALENDAR_ITEM_TYPES.LEAD_PROJECT:
                        return this.Enums.calendarPopoverTypes.lead_project;

                    case this.CALENDAR_ITEM_TYPES.BOOKED_PROJECT:
                        return this.Enums.calendarPopoverTypes.booked_project;

                    case this.CALENDAR_ITEM_TYPES.PAYMENT:
                        return this.Enums.calendarPopoverTypes.payment;

                    case this.CALENDAR_ITEM_TYPES.TEAM_TYPES.TEAM_MEETING:
                        return this.Enums.calendarPopoverTypes.team_meeting;

                    case this.CALENDAR_ITEM_TYPES.TEAM_TYPES.TEAM_PROJECT:
                        return this.Enums.calendarPopoverTypes.team_project;
                    default:
                        return this.checkOther(data);

                }
            },
            checkOther: function checkOther(data) {
                return undefined;
            },

            hasInvitees: function hasInvitees(event) {
                return !!event.client_id;
            },

            isConnectedToProject: function isConnectedToProject(event) {
                return !!event.workspace_id;
            },

            onReSyncCalendarItem: function onReSyncCalendarItem(calEvent, eventType) {
                var calendarItemId;
                if (eventType === "hb_meeting" && calEvent.hbData && calEvent.hbData.calendarItem){
                    calendarItemId = calEvent.hbData.calendarItem._id;
                } else if (eventType === "lead_project" || eventType === "booked_project"){
                    calendarItemId = calEvent.eventId;
                } else {
                    return Promise.reject({data:{error_message:"Resyncing this type of calendar item is not supported"}});
                }
                return this.UsersManager.reSyncCalendarItem(calendarItemId, eventType);
            },
        };
    });
}());
