/**
 * Created by dvircohen on 04/05/2017.
 */
// @ngInject
Filters.JoinArrayFilter  = function JoinArrayFilter(_, $window) {
    return function(array, attribute, delimiter) {

        if (!_.isArray(array)) {
            return undefined;
        }

        delimiter = (delimiter || ',').trim() + ' ';

        var string = '';
        array.forEach(function(item) {

            if (!item[attribute]) {
                $window.console.log(attribute + ' does not exists in ' + item);
            }
            else {
                string += item[attribute];
                string += delimiter;
            }
        });

        return string.substring(0, string.lastIndexOf(delimiter));
    };
};
