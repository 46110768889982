// @ngInject

Models.EventModel = function EventModel(BaseModel, ModelFactory){

    var EventModelClass = Class(BaseModel, {

        constructor: function constructor(){
            EventModelClass.$super.call(this);
            this.__className = 'EventModel';
            this.__ModelFactory = ModelFactory;

            this.init();
        },

        init: function init() {
            var workspacesMasterCollection = this.__ModelFactory.getWorkspacesMasterCollection();
            workspacesMasterCollection.on('itemAdded', this.workspaceAddedToMasterCollection, this);
            workspacesMasterCollection.on('itemRemoved', this.workspaceRemovedFromMasterCollection, this);
        },

        mapChilds: function mapChilds(){
            return {creator: 'UserModel',
                    workspaces: 'WorkspacesCollection',
                    event_users: 'UsersCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object){
            if(angular.isArray(this.workspaces)){
                var self = this;
                this.workspaces.forEach(function (workspace){
                    workspace.__event = self;
                });
            }
        },

        getModelTimeout: function getModelTimeout() {
            return 10 * 1000; // workspace mode timeout is 10 seconds
        },

        workspaceAddedToMasterCollection: function workspaceAddedToMasterCollection(workspace){
            var id = workspace.event_id;

            if(!id && workspace.__event){
                id = workspace.__event._id;
            }

            if (id && id === this._id){
                this.workspaces.push(workspace);
            }
        },

        workspaceRemovedFromMasterCollection: function workspaceRemovedFromMasterCollection(workspace){
            var id = workspace.event_id;

            if(!id && workspace.__event){
                id = workspace.__event._id;
            }

            var self = this;
            if (id === this._id){
                this.workspaces.forEach(function(element, index){
                    if (element._id === workspace._id){
                        self.workspaces.splice(index, 1);

                        return;
                    }
                });
            }
        },

        hasSentOrBookedWorkspaces: function hasSentOrBookedWorkspaces(includeArchived) {
            if (this.workspaces && this.workspaces.length) {
                for(var i = 0; i <  this.workspaces.length; i++){
                    if(this.workspaces[i].isSentOrBooked() && (includeArchived || !this.workspaces[i].isArchived())) {
                        return true;
                    }
                }
            }
            return false;
        },

        updateEventDetails: function updateEventDetails(eventObj, keepNullValues) {

            var self = this;
            ['event_name', 'event_date', 'event_end_date', 'event_time_start',
                'event_time_end', 'event_timezone', 'custom_project_fields_v2',
                'event_guests', 'event_budget', 'event_details',
                'event_location', 'event_type', 'event_image', 'event_info_html_body', 'is_in_view_mode'].forEach(function (key) {

                    if(eventObj.hasOwnProperty(key) && (keepNullValues || (typeof eventObj[key] !== 'undefined' && eventObj[key] !== null))) {
                        self[key] = eventObj[key];
                    }
                });
        },

        isEditable: function isEditable(){

            return false;
        },

        isViewOnlyMode: function isViewOnlyMode() {
            return this.is_in_view_mode;
        },

        addWorkspaceIfNeeded: function addWorkspaceIfNeeded(workspace) {

            if(this.workspaces){

                var exists = false;
                for(var i = 0; i < this.workspaces.length; i++){
                    if(this.workspaces[i]._id === workspace._id){
                        exists = true;
                        break;
                    }
                }

                if(!exists){
                    this.workspaces.push(workspace);
                }

                if(!workspace.__event){
                    workspace.__parent = this;
                    workspace.__event = this;
                }
            }
        },

        hasArchivedWorkspace: function hasArchivedWorkspace() {
            return this.workspaces && this.workspaces.some(function (workspace) {
                return workspace.isArchived();
            });
        }
    });

    return EventModelClass;
};

