Directives.ChromeExtensionIntegrationDirective = function ChromeExtensionIntegrationDirective()  {

    "use strict";

    // @ngInject
    function ChromeExtensionIntegrationDirectiveControllerCtor($scope, $injector, AnalyticsService, $window) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ChromeExtensionIntegrationDirectiveController';

        this.AnalyticsService = AnalyticsService;
        this.$window = $window;
    }


    var ChromeExtensionIntegrationDirectiveController = Class(Controllers.BaseController, {

        constructor: ChromeExtensionIntegrationDirectiveControllerCtor,

        goToChromeExtension: function connectFacebook() {
            this.$window.open("https://chrome.google.com/webstore/detail/honeybook-extend/cfefcfhojjgbbcnhbfhilapppfblnogg", '_blank');
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/settings/account/chrome_extension/chrome_extension_integrations_template.html',
        controller: ChromeExtensionIntegrationDirectiveController,
        controllerAs: 'chromeExtensionIntegrationVm',
        bindToController: true
    };
};
