/**
 * Created by inonstelman on 1/4/15.
 */

// @ngInject
Directives.AnalyticsDirective = function AnalyticsDirective(AnalyticsService) {
    var IS_PROPERTY_REGEXP = /hbAnalyticsEvent(?!(On|Event|If|Properties|EventType))/i;

    function isCommand(element) {
        return ['a:','button:','button:button','button:submit','input:button','input:submit'].indexOf(
                element.tagName.toLowerCase()+':'+(element.type||'')) >= 0;
    }

    function inferEventType(element) {
        if (isCommand(element)) {
            return 'click';
        }
        return 'click';
    }

    function inferEventName(element) {
        if (isCommand(element)) {
            return element.innerText || element.value;
        }
        return element.id || element.name || element.tagName;
    }

    function isProperty(name) {
//        return name.substr(0, 16) === 'hbAnalyticsEvent' && ['On', 'Event', 'If', 'Properties', 'EventType'].indexOf(name.substr(16)) === -1;
        return IS_PROPERTY_REGEXP.test(name);
    }

    function propertyName(name) {
        var s = name.slice(11); // slice off the 'analytics' prefix
        if (typeof s !== 'undefined' && s!==null && s.length > 0) {
            return s.substring(0, 1).toLowerCase() + s.substring(1);
        }
        else {
            return s;
        }
    }

    function classAny(classNames, classList) {
        for (var i=0; i < classNames.length; i++) {
            if (classList.contains(classNames[i])) {
                return true;
            }
        }
        return false;
    };

    function classAll(classNames, classList) {
        for (var i=0; i < classNames.length; i++) {
            if (!classList.contains(classNames[i])) {
                return false;
            }
        }
        return true;
    };

    return {
        restrict: 'A',
        link: function ($scope, $element, $attrs) {
            var eventType = $attrs.hbAnalyticsOn || inferEventType($element[0]);
            var trackingData = {};

            var scopeForAnalytics = $scope;


            angular.forEach($attrs.$attr, function(attr, name) {
                if (isProperty(name)) {
                    trackingData[propertyName(name)] = $attrs[name];
                    $attrs.$observe(name, function(value){
                        trackingData[propertyName(name)] = value;
                    });
                }
            });

            angular.element($element[0]).bind(eventType, function ($event) {
                var eventName = $attrs.hbAnalyticsEvent || inferEventName($element[0]);
                trackingData.eventType = $event.type;

                if($attrs.hbAnalyticsIf){
                    if(! $scope.$eval($attrs.hbAnalyticsIf)){
                        return; // Cancel this event if we don't pass the analytics-if condition
                    }
                }

                if ($attrs.hbAnalyticsElementTag && $attrs.hbAnalyticsElementTag.toUpperCase() !== $event.target.tagName.toUpperCase()) {
                    return; //cancel this event if element tag does not match target tag
                }

                if ($attrs.hbAnalyticsElementId && $attrs.hbAnalyticsElementId !== $event.target.id) {
                    return; //cancel this event if element id does not match target id
                }

                if ($attrs.hbAnalyticsElementClass && !$event.target.classList.contains($attrs.hbAnalyticsElementClass)) {
                    return; //cancel this event if target element does not have elementClass
                }

                if ($attrs.hbAnalyticsElementClassAny &&
                    !classAny($attrs.hbAnalyticsElementClassAny.split(/ +/), $event.target.classList)) {
                    return;
                }

                if ($attrs.hbAnalyticsElementClassAll &&
                    !classAll($attrs.hbAnalyticsElementClassAll.split(/ +/), $event.target.classList)) {
                    return;
                }


                // Allow components to pass through an expression that gets merged on to the event properties
                // eg. analytics-properties='myComponentScope.someConfigExpression.$analyticsProperties'
                if($attrs.hbAnalyticsProperties){

                    var attributesObj = {};
                    var attrsArr = $attrs.hbAnalyticsProperties.split(',');
                    attrsArr.forEach(function (element, index){
                        var keyValArr = element.split(':');
                        if (keyValArr[0] && keyValArr[1]) {
                            //todo yona find a better solution - what if company name has a comma
                            attributesObj[keyValArr[0].trim()] = keyValArr[1].trim();
                        }
                    });
                    angular.extend(trackingData, attributesObj);
                }

                if($attrs.hbAnalyticsScope){
                    scopeForAnalytics = $scope.$eval($attrs.hbAnalyticsScope);
                }

                AnalyticsService.track(scopeForAnalytics, $event.type.toLowerCase() +': ' + eventName, trackingData);
            });
        }
    };
};
