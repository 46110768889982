(function () {
    "use strict";

    class QuestionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $sce, AnalyticsService, QuestionsStateService, QuestionService) {
            super($scope, $injector);
            this.__objectType = 'QuestionController';

            this.$scope = $scope;
            this.$sce = $sce;
            this.AnalyticsService = AnalyticsService;
            this.QuestionService = QuestionService;
            this.QuestionsStateService = QuestionsStateService;
        }

        $onInit() {
            this.resetErrors();
            this._setConnectedFieldText();
            this.QuestionsStateService.on("CheckQuestionsErrors", this.onValidate.bind(this));
            //this.register(this.question, 'questionUpdated', this._onChanges.bind(this));
        }

        $onChanges(changes) {
            this._setQuestionType();
            this._setConnectedFieldText();
        }

        // _onChanges() {
        //     this._setQuestionType();
        //     console.log('ONCHANGE: ' + this.questionType);
        // }

        onValidate() {
            this.resetErrors();
            this.errors['text'] = !this.text;
        }

        resetErrors() {
            this.errors = {};
        }

        _setQuestionType() {
            this.questionType = this.QuestionService.getQuestionComponentType(this.question);
        }

        focusQuestion() {
            this.$scope.$emit('childComponentFocusChanged', true);
        }

        updateQuestion() {
            this.question.updateQuestion(['text']);
        }

        _setConnectedFieldText() {
            this.connectedIndicatorText = this.QuestionService.getConnectedQuestionDisplayText(this.question);
        }
    }

    Components.Question = {
        bindings: {
            question: '<',
            isEditable: '<',
            isOwnerMode: '<',
            isAnswerable: '=',
            isStyleCustomizable: '<',
        },
        controller: QuestionController,
        controllerAs: 'questionVm',
        name: 'hbQuestion',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/questions/question.html',
    };

}());
