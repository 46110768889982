Directives.ExpenseDirective = function ExpenseDirective() {

    // @ngInject
    function ExpenseDirectiveControllerFunc($scope, $injector, AppStates, CompanyService, $filter, CompaniesManager, AnalyticsService, moment,
                                            Enums, _, UsersManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ExpenseDirectiveController';
        this.AppStates = AppStates;
        this.CompanyService = CompanyService;
        this.CompaniesManager = CompaniesManager;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.moment = moment;
        this._ = _;
        
        this.Enums = Enums;
        this.user = this.UsersManager.getCurrUser();
      
        this.isEditMode = false;
        this.canEdit = this.user.hasCompanySuperAdminPermissions();
        this.isBookkeeperMode = this.user.isBookkeeperMode();
        this.date = null;
        this.activeExpense = 0;

        if (this.newCreatedExpense) {
            this.handleClickBackground();
        }
    }

    var ExpenseDirectiveController = Class(Controllers.BaseController, {

        constructor: ExpenseDirectiveControllerFunc,
        
        handleClickBackground: function handleClickBackground() {
            if (this.canEdit) {
                this.isEditMode = true;
            }
        },
        
        handleClickOutside: function handleClickOutside() {
            this.isEditMode = false;
            this.newCreatedExpense = false;
        },

        currentExpense: function currentExpense (){
           this.activeExpense = this.expense.amount;
        },

        updateExpense:function updateExpense(){
            // todo: this code caused hotfix HON-7306
            // if (this.expense.amount < 1 ){
            //     this.expense.amount = this.activeExpense;
            //     return;
            // }

            this.CompaniesManager.updateExpense(this.company, this.expense).then(function success(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_expense_update);
                    this.expense.event_name = resp.data.event_name;
                }.bind(this),
                function error() {

                });
        },

        handleClickDelete: function handleClickDelete() {
            this.CompaniesManager.deleteExpense(this.company, this.expense).then(function success(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_expense_creates);
                    this.onDelete(this.expense);
                }.bind(this),
                function error() {

                });
        },
        
        getCategoryNameById: function getCategoryNameBSuymbol(symbol) {
            if (symbol !== null) {
                var cats = this.Enums.ExpenseCategories;
                for (var group in cats) {
                    var options = cats[group].options;
                    for (var option in options) {
                        if (options[option].symbol === symbol) {
                            return options[option].name;
                        }
                    }
                }
            }
            return null;
        },

        updateExpenseDate: function updateExpenseDate() {
            if (this.date) {
                var date = this.moment(this.date, 'MMM DD, YYYY');
                if (date.isValid()) {
                    this.expense.expense_date = date.format('YYYY-MM-DD');
                }
                else {
                    this.expense.expense_date = this.moment().format('YYYY-MM-DD');
                }
            }

            return this.CompaniesManager.updateExpense(this.company, this.expense);
        },

        projectChanged: function projectChanged(projectId){
            this.expense.event_id = projectId;
            this.updateExpense();
        }

    });

    return {
        scope: {
            expense: '=',
            company: '=',
            onDelete: '&',
            first: '=',
            last: '=',
            newCreatedExpense: '=',
            showExpenseSource: '='
        },
        templateUrl: 'angular/app/modules/core/features/expenses/expense_directive_template.html',
        controller: ExpenseDirectiveController,
        controllerAs: 'expenseVm',
        bindToController: true
    };
};
