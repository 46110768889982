(function () {
    'use strict';

    // @ngInject
    function YahooRedirectControllerCtor($scope, $injector, $location, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, Enums, $window) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'YahooRedirectController';
        AnalyticsService.trackPageView(this, 'YahooRedirectView');
        this.$log = $log;
        this.$q = $q;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;

        this.Enums = Enums;

        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        this.$log.info("queryStringParams debug: " + JSON.stringify(queryStringParams));

        InitialAppLoadParamsService.cleanParamsFromView(['code']);
        var originState = 'email';

        if (queryStringParams.code) {
            queryStringParams["provider"] = "yahoo";
            this.UsersManager.setNylasAuthCode(queryStringParams)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_yahoo_integration_auth_code);
                    this.setActiveEmailProvider().then(function success() {
                        this.redirectUserBackToOriginPage(originState);
                    }.bind(this));
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.save_yahoo_integration_auth_code, resp);

                    if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBUserError") {

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    this.$log.error('failed to save the auth-code on the yahoo integration model');
                }.bind(this));

        } else {
            this.$log.error('Error in yahoo authentication process: ' + $location.url());
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.yahoo_integration_error);
            this.redirectUserBackToOriginPage(originState);
        }
    }

    Controllers.YahooRedirectController = Class(Controllers.BaseController, {
        constructor: YahooRedirectControllerCtor,

        setActiveEmailProvider: function setActiveEmailProvider() {
            return this.UsersManager.setActiveEmailProvider(this.user, 'nylas');
        },

        redirectUserBackToOriginPage: function redirectUserBackToOriginPage(originState) {
            this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
        }
    });
}());