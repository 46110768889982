Directives.AccountSettingsProLoginDirective = function AccountSettingsProLoginDirective() {

    // @ngInject
    function AccountSettingsProLoginControllerFunc($scope, $injector, $stateParams, UsersManager, ModalService, ToastService, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginTwoFactorAuthDirectiveController';
        this.UsersManager = UsersManager;
        this.ToastService = ToastService;
        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.hasAccess = !this.UsersManager.getCurrUser().isProLogin();
    }

    var AccountSettingsProLoginDirectiveController = Class(Controllers.BaseController, {
        constructor: AccountSettingsProLoginControllerFunc,
    });

    return {
        scope: {
            user: '=user'
        },
        templateUrl: 'angular/app/modules/core/features/settings/account/pro_login/account_settings_pro_login_template.html',
        controller: AccountSettingsProLoginDirectiveController,
        controllerAs: 'AccountSettingsProLoginVm',
        bindToController: true
    };
};