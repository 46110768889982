(function () {
  'use strict';

  class HBFilterController extends Controllers.BaseControllerES6 {
    // @ngInject
    constructor($scope, $injector) {
      super($scope, $injector);
      this.__objectType = 'HBFilterController';

      this.isPopoverActive = false;
      this.activeFilters = [];
    }

    $onChanges(param){
      if (param.reset){
        this.activeFilters = [];
      }
    };

    handlePopover(){
      this.isPopoverActive = !this.isPopoverActive;
    }

    isActive(item){
      return this.activeFilters.indexOf(item) > -1
    }

    handleChange(filter){
      const filterIndex = this.activeFilters.indexOf(filter.name);
      if (filterIndex === -1) {
        this.activeFilters.push(filter.name)
      } else {
        this.activeFilters.splice(filterIndex, 1);
      }

      this.onChange({filter})
    }

    handleClear(){
      this.activeFilters.splice(0);
      this.onClear();
    }

  }

  Components.HBFilter = {
    controller: HBFilterController,
    bindings: {
      label: '@',
      filterList: '<',
      reset: '<',
      onChange: '&',
      onClear: '&',
      analytics: '@'
    },
    name : 'hbFilter',
    templateUrl: 'angular/app/modules/common/ui_components/hb-filter/hb-filter.html'
  };

}());
