Directives.TimePickerDirective = function TimePickerDirective() {

    // @ngInject
    var TimePickerDirectiveControllerFunc = function TimePickerDirectiveControllerFunc($element, $scope, $timeout, $injector) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TimePickerDirectiveController';
        this.invalidTime      = false;
        var self = this;
        var inputSel = this.allowManualEdit ? '.input-textfield' : '.input-dropdown';
        var input = $element.find(inputSel);
        var interval           = parseFloat(this.interval) || 15;
        var checkIfValidTime   = false;
        var typeaheadHighlight = true;

        if (this.allowManualEdit) {
            checkIfValidTime = true;
            typeaheadHighlight = false;
        }

        input.timepicker({
            scrollDefaultTime: '1:00 pm',
            timeFormat: 'g:i a',
            step: interval,
            typeaheadHighlight: typeaheadHighlight
        });

        $element.on('remove', function () {
            input.timepicker('remove');
        });

        if (this.disableKeyboard) {
            $element.on('keydown', function() {
                return false;
            });
        }

        this.showLabel = this.showLabel || true;

        // called anytime time value is changed, whether it's valid or invalid;
        // the value should only resolve as invalid if user can manually enter value.
        // see timepicker api docs for more info
        input.on('change', function () {

            var shouldUpdate = true;

            if (this.validateValue && this.allowManualEdit) {
                shouldUpdate = false;

                var valueToCheck = input.val().replace(/ /, '');
                var regex = /^([01]?\d|2[0-3])(:)([0-5]\d)([ap]m)?$/;

                if (valueToCheck.match(regex) || !valueToCheck) {
                    shouldUpdate = true;
                }
            }

            if (!shouldUpdate) {
                input.val("1:00 pm");
            }
            $scope.$evalAsync(function eval() {
                if (typeof self.onChange === 'function') {
                    $timeout(self.onChange);
                }
            });
        }.bind(this));

        this.timepickerClick = function timepickerClick() {
            input.timepicker('show');
        };

        this.onBlur = function onBlur() {
            if (this.timepickerOnBlur && angular.isFunction(this.timepickerOnBlur)) {
                this.timepickerOnBlur();
            }
        };
    };


    var TimePickerDirectiveController = Class(Controllers.BaseController, {
        constructor: TimePickerDirectiveControllerFunc,
    });
    return {
        scope: {
            allowManualEdit: '=?allowManualEdit',
            validateValue: '=?validateValue',
            interval: '@?interval',
            placeholder: '@timepickerPlaceholder',
            model: '=timepickerModel',
            onChange: '&?timepickerOnChange',
            timepickerOnBlur: '&?timepickerOnBlur',
            disabled: '=',
            disableKeyboard: '<?',
            showPlaceholder: '<?',
            showLabel: '<?',
            inputName: '@?',
        },
        templateUrl: 'angular/app/modules/common/ui_components/timepicker/timepicker_directive_template.html',
        controllerAs: 'timepickerVm',
        controller: TimePickerDirectiveController,
        bindToController: true
    };
};
