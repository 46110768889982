/**
 * Created by udicohen on 21/7/16.
 */
(function () {
    "use strict";

    var REFRESH_TIMEOUT_MINIMUM_DURATION = 30 * 1000; // in milliseconds
    var REFRESH_TIMEOUT_MAXIMUM_DURATION = 4980 * 1000; // in milliseconds

    /**
     * This Directive takes care of reloading the site when received a command from the websockets (Moses)
     */
    // @ngInject
    Directives.GracefulReloadDirective = function GracefulReloadDirective($rootScope, $window, $timeout, $document,
                                                                          AppConfigService, APIService, Gon) {
        return {
            restrict: 'E',
            scope: {},
            controller: function GracefulReloadDirectiveController() {
                var timeout = null;
                var unwatch = null;

                APIService.on('success', function (data, status, headers, config, statusText) {
                    var apiClientVersion = typeof headers === "function" ? headers(Gon.hb_api_headers.api_client_version) : headers[Gon.hb_api_headers.api_client_version];
                    AppConfigService.checkIfNeedRefresh(apiClientVersion);
                });

                function cancelTimeout() {
                    if (timeout) {
                        $timeout.cancel(timeout);
                        timeout = null;
                    }
                }

                function reload() {
                    // $window.console.log('reload from GracefulReloadDirective');
                    $window.location.reload();
                }

                function reloadWithTimeout() {
                    cancelTimeout();

                    var randomRefreshTimeout = Math.floor(Math.random() * (REFRESH_TIMEOUT_MAXIMUM_DURATION - REFRESH_TIMEOUT_MINIMUM_DURATION)) + REFRESH_TIMEOUT_MINIMUM_DURATION;
                    // $window.console.log('reload in', randomRefreshTimeout);
                    timeout = $timeout(reload, randomRefreshTimeout);
                }

                angular.element($window).on('focus', function onTabFocus() {
                    cancelTimeout();

                    if (unwatch) {
                        unwatch();
                        unwatch = null;
                    }
                });

                angular.element($window).on('blur', function onTabBlur() {
                    if (AppConfigService.doesNeedsRefresh()) {
                        reloadWithTimeout();
                    }


                    if (unwatch) {
                        unwatch();
                    }

                    unwatch = $rootScope.$watch(AppConfigService.doesNeedsRefresh.bind(AppConfigService), function reload(refresh) {
                        if (refresh) {
                            reloadWithTimeout();
                        }
                    });
                });

                // Trigger the first event...
                try {
                    if ($document && $document[0] && !$document[0].hasFocus()) {
                        angular.element($window).trigger('blur');
                    }
                } catch (e) {

                }
            }
        };
    };


}());
