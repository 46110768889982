/**
 * Created by udicohen on 21/2/16.
 */
(function () {
    'use strict';

    // @ngInject
    Services.StripeHelperService = function StripeHelperService($q, Stripe, AppConfigService) {
        var _providerFunctions = {};

        this.getStripeForUser = function getStripeForUser(user) {
            return Stripe.then(function(stripe) {return $q.resolve(stripe(user.stripe_key));});
        };

        this.getStripeToken = function getStripeToken(card) {
            var configuration = AppConfigService.appConfiguration();
            //noinspection JSUnresolvedFunction
            var deferred = $q.defer();

            Stripe.then(function(stripe) {
                stripe.setPublishableKey(configuration.stripeKey);

                //noinspection JSUnresolvedFunction
                stripe.card.createToken(card,
                    function (status, response) {
                        if (status === 200) {
                            deferred.resolve(response);
                        } else {
                            deferred.reject(response);
                        }
                    }
                );
            });


            return deferred.promise;

        };

        this.registerStripeTokenProvider = function registerStripeTokenProvider(id, $scope, providerFunction) {
            _providerFunctions[id] = providerFunction;
            $scope.$on('$destroy', function() {
                delete _providerFunctions[id];
            });
        };

        this.getStripeTokenNew = function getStripeTokenNew(providerId, additionalData) {
            var providerFunction = _providerFunctions[providerId];
            if (angular.isFunction(providerFunction)) {
                return providerFunction(additionalData);
            }
        };
    };
}());
