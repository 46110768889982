(function () {
    'use strict';

    class SessionsListController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'SessionsListController';
            this.AnalyticsService = AnalyticsService;

            this.cardViewLimit = 5;
            this.NUMBER_OF_SHIMMERS = 3;
        }

        $onInit() {

        }

        create() {
            this.onSessionCreate({});
        }

        update(session) {
            this.onSessionUpdate({session: session});
        }

        onViewMore() {
            this.cardViewLimit = '';
        }
    }

    Components.SessionsList = {
        bindings: {
            sessions: '<',
            onSessionUpdate: '&',
            onSessionCreate: '&',
            showCreateSession: '<',
            company: '<',
            limit: '<',
            user: '<',
            isLoading: '<',
            isFeatureRestricted: '<',
        },
        transclude: true,
        controller: SessionsListController,
        controllerAs: 'sessionsListVm',
        name: 'hbSessionsList',
        templateUrl: 'angular/app/modules/core/features/calendar/scheduling/sessions_list/sessions_list.html',
    };

}());
