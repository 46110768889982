(function () {
    "use strict";

    // @ngInject
    function GeoLocationHelperServiceCtor(UIUtils) {
        this.UIUtils = UIUtils
    }

    Services.GeoLocationHelpeService = Class(function () {

        return {

            constructor: GeoLocationHelperServiceCtor,

            getGeoLocationData: function getGeoLocationData(geoResult) {
                if (!geoResult) {
                    return false;
                }

                var country = this.UIUtils.getCountryFromGeoAddrComponents(geoResult.address_components);
                var countryShortName = this.UIUtils.getCountryFromGeoAddrComponentsShortName(geoResult.address_components);
                var city = this.UIUtils.getCityFromGeoAddrComponentsLongName(geoResult.address_components);
                var state = this.UIUtils.getStateCodeFromGeoAddrComponentsShortName(geoResult.address_components);
                var geoLongitude = geoResult.geometry.location.lng();
                var geoLatitude = geoResult.geometry.location.lat();
                var locationLabel, fullAddress;

                if (country === 'United States' || country === 'Canada') {
                    locationLabel = city + ', ' + state;
                    fullAddress = locationLabel; // + ", " + country;
                } else {
                    locationLabel = city + ', ' + country;
                    fullAddress = locationLabel;
                }

                // MongoDB stores geospatical coordinates as long, lat
                return {
                    locationLabel: locationLabel,
                    fullAddress : fullAddress,
                    coordinates: [geoLongitude, geoLatitude],
                    city: city,
                    state: state,
                    country: country,
                    countryShortName: countryShortName
                };
            },

        };
    });
}());
