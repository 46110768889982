(function () {
    "use strict";

    Services.PlaidLinkService = class PlaidLinkService {

        // @ngInject
        constructor($http, $q, _, AppConfigService, $log, $timeout, UsersManager,
            AnalyticsService, Plaid, InitialAppLoadParamsService, Gon, DatadogRUMService) {
            this.$q = $q;
            this._ = _;
            this.$log = $log;
            this.$timeout = $timeout;
            this.AppConfigService = AppConfigService;
            this.AnalyticsService = AnalyticsService;
            this.DatadogRUMService = DatadogRUMService;
            this.UsersManager = UsersManager;
            this.Plaid = Plaid;
            this.InitialAppLoadParamsService = InitialAppLoadParamsService;
            this.Gon = Gon;

            this.storageKey = 'oauth-plaid-redirect';
            this.redirectAutoOpened = false;
        }

        launchPlaidLinkForAuth(redirectInfo) {
            return this.launchPlaidLink(['auth'], redirectInfo);
        }

        launchPlaidLinkForAuthAndTransactions(redirectInfo) {
            return this.launchPlaidLink(['auth', 'transactions'], redirectInfo);
        }

        launchPlaidLinkForTransactions(redirectInfo) {
            return this.launchPlaidLink(['transactions'], redirectInfo);
        }

        markRedirectOpened() {
            this.redirectAutoOpened = true;
        }

        loadRedirectData() {
            const queryParams = this.InitialAppLoadParamsService.getAllLoadParams();
            const redirectData = JSON.parse(localStorage.getItem(this.storageKey));
            if (!queryParams.oauth_state_id || !redirectData || !redirectData.prevLinkToken) {
                if (queryParams.oauth_state_id) {
                    this.DatadogRUMService.addError(
                        new Error('Error: got oauth state id but no redirect data'),
                        {
                            params: queryParams.oauth_state_id
                        }
                    );
                }
                this.clearRedirectData();
                return { isRedirect: false };
            }
            redirectData.oauth_state_id = queryParams.oauth_state_id;
            redirectData.isRedirect = true;
            redirectData.redirectAutoOpened = this.redirectAutoOpened;
            return redirectData;
        }

        saveRedirectData(data) {
            const { href } = window.location;
            const urlWithNoQuery = href.split('?')[0];
            const redirectData = {
                saveTime: Date.now(),
                originalUrl: urlWithNoQuery
            };
            Object.assign(redirectData, data);
            localStorage.setItem(this.storageKey, JSON.stringify(redirectData));
        }

        clearRedirectData() {
            localStorage.removeItem(this.storageKey);
        }

        getRedirectUri() {
            const { environment } = this.Gon;
            const { protocol, host, hostname } = window.location;
            // we currently don't support redirect on subdomains locally
            if (environment === 'development') {
                if (hostname.split('.')[0] !== 'localhost') {
                    return null;
                }
            }

            // make sure we don't 
            const whiteListMap = {
                development: ['lvh.me', 'localhost'],
                staging: ['hb-site-dev.com', 'stg.hbdev.io', 'api.e2e.stg-us-east-1-v7.hbdev.io', 'web.e2e.stg-us-east-1-v7.hbdev.io', 'ws.e2e.stg-us-east-1-v7.hbdev.io', 'iw.e2e.stg-us-east-1-v7.hbdev.io', 'api.preview.stg-us-east-1-v7.hbdev.io', 'web.preview.stg-us-east-1-v7.hbdev.io', 'ws.preview.stg-us-east-1-v7.hbdev.io', 'iw.preview.stg-us-east-1-v7.hbdev.io', 'e2e.hbdev.io'],
                production: ['honeybook.com', 'hbportal.co']
            };
            if (!(whiteListMap[environment] || []).some(url => host.includes(url))) {
                return null;
            }

            return `${protocol}//${host}/app/plaid-oauth`;
        }

        // stateless implementation of all plaid flow
        // plaid products (which we use):
        // * auth - accessing bank account and committing payments
        // * transactions - reading bank account transaction
        //
        // ...or any combination of those
        //
        // returns a promise:
        //      resolve: function launchPlaidLinkSuccess(plaidResponseMetadata)
        //      reject: function launchPlaidLinkError({err, metadata, reason)
        launchPlaidLink(requiredPlaidProducts, redirectInfo = { shouldRedirect: false }) {
            let launchPlaidLinkDeferred = this.$q.defer();
            let plaidLinkInstance = null;

            // initialize window.plaid lib
            this.Plaid.then(plaidLibInstance => {
                // get link token from server.
                const { isRedirect, prevLinkToken, oauth_state_id } = this.loadRedirectData();
                const { shouldRedirect } = redirectInfo;
                const redirectUri = shouldRedirect ? this.getRedirectUri() : null;

                const createParams = {
                    onLoad: () => {
                        plaidLinkInstance.open();
                    },

                    onExit: (err, metadata) => {
                        if (err) {
                            this.$log.error('exit plaid modal', { err, metadata });
                        }
                        this.AnalyticsService.track(this, 'exit plaid modal', { err, metadata });
                        launchPlaidLinkDeferred.reject({ err, metadata, reason: 'USER_FAIL' });
                    },

                    onEvent: (eventName, metadata) => {
                        this.AnalyticsService.track(this, 'plaid event', { eventName, metadata });
                        this.$log.debug('onEvent', eventName, metadata);
                        // to check - if volume too high we can reduce to vendors only
                        this.UsersManager.reportPlaidEvent(eventName, metadata);
                        if (eventName === 'OPEN' && !isRedirect) {
                            this.saveRedirectData(redirectInfo);
                        }
                        if (eventName === 'HANDOFF' || eventName === 'EXIT') {
                            this.clearRedirectData();
                        }
                    },

                    // __todo simulate events / errors using sandbox
                    // https://plaid.com/docs/link/web/#onsuccess
                    onSuccess: (public_token, metadata) => {
                        this.$log.debug('got public token √', { public_token, metadata });
                        this.AnalyticsService.trackSuccess(this, 'plaid public token created');

                        const { institution, accounts } = metadata;
                        if (!institution || !this._.some(accounts)) {
                            const errMsg = 'wrong response from plaid - response missing institution and/or accounts';
                            this.$log.error(errMsg, metadata);
                            this.AnalyticsService.track(this, errMsg, metadata);
                            launchPlaidLinkDeferred.reject({ reason: 'PLAID_ERROR', errMsg });
                            plaidLinkInstance.destroy();
                        } else {
                            this.UsersManager.plaidExchangePublicToken(
                                this.UsersManager.getCurrUser(),
                                public_token,
                                institution.institution_id,
                                institution.name,
                                accounts // accounts from plaid link flow
                            ).then(
                                resp => {
                                    // see server's exchange_plaid_auth_for_user comments
                                    // in case we had this institution already linked before
                                    const filteredAccounts = resp.data.accounts.filter(
                                        // use the link given accounts to reduce the list to what's relevant
                                        exchangeRespAccount => accounts.find(
                                            ma => ma.id === exchangeRespAccount.id ||
                                                ma.mask === exchangeRespAccount.mask // in case account ids were changed
                                        )
                                    );
                                    metadata.accounts = filteredAccounts.length > 0
                                        ? filteredAccounts // got a match
                                        : accounts // fallback - no match, use the newer...
                                        ;
                                    this.$log.debug('onSuccess resolve', { before: accounts, res: metadata });
                                    launchPlaidLinkDeferred.resolve(metadata);
                                },
                                err => {
                                    this.$log.error('failed plaidExchangePublicToken', err);
                                    this.AnalyticsService.track(this, 'failed exchanging public token', { err });
                                    this.launchPlaidLinkDeferred.reject({ err, reason: 'PLAID_ERROR' });
                                    plaidLinkInstance.destroy();
                                }
                            );
                        }
                    },

                    // required for OAuth:
                    // if not OAuth, set to null or do not include:-
                    // receivedRedirectUri
                };

                if (isRedirect) {
                    createParams.receivedRedirectUri = `${this.getRedirectUri()}?oauth_state_id=${oauth_state_id}`;
                    createParams.token = prevLinkToken;
                    plaidLinkInstance = plaidLibInstance.create(createParams);
                } else {
                    this.UsersManager.plaidCreateLinkToken(this.UsersManager.getCurrUser(), requiredPlaidProducts, redirectUri)
                        .then(response => {
                            const linkToken = response && response.data && response.data.link_token;
                            if (linkToken) {
                                redirectInfo.prevLinkToken = linkToken;
                                createParams.token = linkToken;
                                plaidLinkInstance = plaidLibInstance.create(createParams);
                                // docs are at https://plaid.com/docs/link/web/#create
                            } else {
                                const errMsg = 'wrong response from plaid - missing link token';
                                this.$log.error(errMsg, response);
                                launchPlaidLinkDeferred.reject({ reason: 'PLAID_ERROR', errMsg });
                                plaidLinkInstance.destroy();
                            }
                        }).catch(err => {
                            this.AnalyticsService.track(this, 'plaid link token creation error', { err });
                            this.$log.error('catch error from plaid in link token creation', err);
                            launchPlaidLinkDeferred.reject({ err, reason: 'PLAID_ERROR' });
                        });
                }

            })
                .catch(err => {
                    this.AnalyticsService.track(this, 'general error in plaid link', { err });
                    this.$log.error('catch error from plaid', err);
                    launchPlaidLinkDeferred.reject({ err, reason: 'PLAID_ERROR' });
                });

            return launchPlaidLinkDeferred.promise;
        }
    };
}());
