(function () {
    "use strict";

    // @ngInject
    function FinancialWidgetComponentControllerFunc($injector, $scope, UsersManager, FeaturesService, uuid4) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FinancialWidgetComponentController';
    }

    var FinancialWidgetComponentController = Class(Controllers.BaseController, {
        constructor: FinancialWidgetComponentControllerFunc,
    });

    Components.FinancialWidget = {
        bindings: {
            type: '@',
            props: '<'
        },
        controller: FinancialWidgetComponentController,
        controllerAs: 'financialWidgetVm',
        name: 'hbFinancialWidget',
        templateUrl: 'angular/app/modules/core/features/financial_widget/financial_widget.html',
    };
}());