/**
 * Created by inonstelman on 1/1/15.
 */

Directives.VisibleDirective = function VisibleDirective() {

    return {
        restrict: 'A',
        multiElement: true,
        link: function(scope, element, attr) {
            scope.$watch(attr.hbVisible, function hbVisibleWatchAction(value) {
                if (value) {
                    element.removeClass('hb-invisible');
                    element.addClass('hb-visible');
                } else {
                    element.removeClass('hb-visible');
                    element.addClass('hb-invisible');
                }
            });
        }
    };
}
