(function () {
    'use strict';

    // @ngInject
    function WorkflowServiceCtor(CompaniesManager, UsersManager, AnalyticsService, WorkflowManager, $q, $translate, PopupMessageService, UiPersistenceService) {


        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;

        this.company = this.CompaniesManager.getCurrCompany();
        this.user = this.UsersManager.getCurrUser();
        this.WorkflowManager = WorkflowManager;
        this.$q = $q;
        this.$translate = $translate;
        this.PopupMessageService = PopupMessageService;
        this.UiPersistenceService = UiPersistenceService;

        this.currentWorkflowViewModel = null;
        this.viewModelIsDirety = false;

        this.workspaceWorkflowInstanceObj = {};
    }

    Services.WorkflowService = Class({
        constructor: WorkflowServiceCtor,

        onExitTemplateWorkflowSetup: function onExitTemplateWorkflowSetup() {
            this.currentWorkflowViewModel = null;
            this.viewModelIsDirety = false;
        },

        setWorkflowViewModelAsDirty: function setWorkflowViewModelAsDirty() {
            this.viewModelIsDirety = true;
        },

        onSaveWorkflow: function onSaveWorkflow() {
            if (this.viewModelIsDirety) {

                if (this.currentWorkflowViewModel && this.currentWorkflowViewModel.workflow_steps && this.currentWorkflowViewModel.workflow_steps.length) {
                    for(var i = 0, length = this.currentWorkflowViewModel.workflow_steps.length; i < length; i++){
                        this.currentWorkflowViewModel.workflow_steps[i].order = i;
                    }
                }
                return this.WorkflowManager.updateWorkflow(this.company, this.currentWorkflowViewModel)
                    .then(function success(resp) {
                        this.viewModelIsDirety = false;
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.workflow_updated);
                        var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.workflowTemplateHasActiveClonedInstances);
                        if (resp.data.template_has_active_cloned_instances && (!uiPersistenceData || (uiPersistenceData && !uiPersistenceData.dontShow))) {
                            this.PopupMessageService.showAlertWithDontShowAgain(this.PopupMessageService.severityTypes.info, 'WORKFLOW.ALERTS._TEMPLATE_IS_ALREADY_ACTIVE_', null, null, this.UiPersistenceService.keys.workflowTemplateHasActiveClonedInstances);
                        }
                    }.bind(this));
            }

            //no need to save if it's not dirty
            return this.$q.resolve(true);
        },

        onWorkflowChanged: function onWorkflowChanged() {
            this.viewModelIsDirety = true;
        },

        setWorkspaceWorkflowObj: function setWorkspaceWorkflowObj(workflow){
            this.workspaceWorkflowInstanceObj = workflow;
        },

        getWorkspaceWorkflowObj: function getWorkspaceWorkflowObj(){
            return this.workspaceWorkflowInstanceObj;
        },

        translateEnum: function translateEnum(enumToTranslate) {

            if(!enumToTranslate || !enumToTranslate.length) {
                return [];
            }

            enumToTranslate.forEach(function(item) {

                if(item.textTranslationKey) {
                    item.text = this.$translate.instant(item.textTranslationKey);
                }

                if(item.descriptionTranslationKey) {
                    item.description = this.$translate.instant(item.descriptionTranslationKey);
                }

            }.bind(this));

            return enumToTranslate;
        },

        getApprovalTypeText: function getApprovalTypeText(approvalType) {

            // handle unknown
            if(!approvalType || !this.workflowStepsApprovalStatus[approvalType]) {
                return '';
            }

            if(approvalType === this.workflowStepsApprovalStatus.not_required) {
                return 'Send automatically';
            } else if(approvalType === this.workflowStepsApprovalStatus.required) {
                return 'Approve before sending';
            } else if(approvalType === this.workflowStepsApprovalStatus.pending) {
                return 'Pending approval';
            } else if(approvalType === this.workflowStepsApprovalStatus.approved) {
                return 'Approved';
            } else if(approvalType === this.workflowStepsApprovalStatus.declined) {
                return 'Declined';
            }

            return '';
        },

        workflowStepActionTypesEnum: [
            {
                key: 'task',
                textTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._TASK_',
                descriptionTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._TASK_DESCRIPTION_',
                text: '',
                description: ''
            },
            {
                key: 'questionnaire',
                textTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._QUEST_',
                descriptionTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._QUEST_DESCRIPTION_',
                text: '',
                description: ''
            },
            {
                key: 'email',
                textTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._EMAIL_',
                descriptionTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._EMAIL_DESCRIPTION_',
                text: '',
                description: ''
            },
            {
                key: 'brochure',
                textTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._BROCHURE_',
                descriptionTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._BROCHURE_DESCRIPTION_',
                text: '',
                description: ''
            },
            {
                key: 'flow',
                textTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._FLOW_',
                descriptionTranslationKey: 'WORKFLOW.WORKFLOW_STEPS_EDITOR._FLOW_DESCRIPTION_',
                text: '',
                description: ''
            }
        ],

        //the server statuses
        workflowStepsApprovalStatus: {
            not_required: "not_required",
            required: "required",
            pending: "pending",
            approved: "approved",
            declined: "declined"
        },

        //this is just for the drop down to select
        workflowApprovalEnum: [
            {
                key: 'not_required',
                textTranslationKey: 'WORKFLOW.APPROVAL._SEND_AUTOMATICALLY_',
                text: '',
            },
            {
                key: 'required',
                textTranslationKey: 'WORKFLOW.APPROVAL._APPROVE_BEFORE_SENDING_',
                text: '',
            }
        ],

        workflowTriggersEnum: [
            {
                key: 'after_workflow_execution',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_ACTIVATING_WORKFLOW_',
                text: '',
            },
            {
                key: 'before_project_date',
                textTranslationKey: 'WORKFLOW.TRIGGERS._BEFORE_PROJECT_DATE_',
                text: '',
            },
            {
                key: 'after_project_end_date',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_PROJECT_END_DATE_',
                text: '',
            },
            {
                key: 'before_project_end_date',
                textTranslationKey: 'WORKFLOW.TRIGGERS._BEFORE_PROJECT_END_DATE_',
                text: '',
            },
            {
                key: 'after_project_date',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_PROJECT_DATE_',
                text: '',
            },
            {
                key: 'after_step_execution',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_PREVIOUS_STEP_IS_COMPLETE_',
                text: '',
            },
            {
                key: 'after_contract_signed',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_CONTRACT_SIGNED_',
                text: '',
            },
            {
                key: 'after_questionnaire_submitted',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_QUESTIONNAIRE_SUBMITTED_',
                text: '',
            },
            {
                key: 'after_invoice_paid_in_full',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_INVOICE_PAID_IN_FULL_',
                text: '',
            },
            {
                key: 'after_first_payment_made',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_FIRST_PAYMENT_MADE_',
                text: '',
            },
            {
                key: 'after_project_move_to_completed',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_PROJECT_MOVE_TO_COMPLETED_',
                text: '',
            },
            {
                key: 'after_brochure_submitted',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_BROCHURE_SUBMITTED_',
                text: '',
            },
            {
                key: 'after_flow_completed',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_FLOW_COMPLETED_',
                text: '',
            },
            {
                key: 'after_all_required_signatures_signed',
                textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_ALL_REQUIRED_SIGNATURES_SIGNED_',
                text: '',
            }
            // ,
            // {
            //     key: 'after_scheduled_session_ended',
            //     textTranslationKey: 'WORKFLOW.TRIGGERS._AFTER_SCHEDULED_SESSION_ENDED_',
            //     text: '',
            // }
        ],

        workflowTriggerDelayUnitEnum: [
            {
                key: 'minutes',
                textTranslationKey: 'WORKFLOW.DELAY_UNIT_OPTIONS._MINUTES_',
                text: ''
            },
            {
                key: 'hours',
                textTranslationKey: 'WORKFLOW.DELAY_UNIT_OPTIONS._HOURS_',
                text: ''
            },
            {
                key: 'days',
                textTranslationKey: 'WORKFLOW.DELAY_UNIT_OPTIONS._DAYS_',
                text: ''
            },
            {
                key: 'weeks',
                textTranslationKey: 'WORKFLOW.DELAY_UNIT_OPTIONS._WEEKS_',
                text: ''
            }
        ]
    });
}());
