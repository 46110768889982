(function () {
    "use strict";

    // @ngInject
    function TextComponentControllerFunc($injector, $scope, $sce, AnalyticsService, UIUtils) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'TextComponentController';
        this.$sce = $sce;
        this.AnalyticsService = AnalyticsService;
        this.UIUtils = UIUtils;
    }

    var TextComponentController = Class(Controllers.BaseController, {
        constructor: TextComponentControllerFunc,

        updateContent: function updateContent() {
            this.component.updateContent();
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.saved_block_component, { field: 'content', component_type: "text"});
        },

        renderContent: function renderContent(content) {
            return this.$sce.trustAsHtml(content);
        },

        focusContent: function focusContent(isFocus) {
            this.$scope.$emit('childComponentFocusChanged', isFocus);
        },

    });

    Components.TextComponent = {
        bindings: {
            component: '=',
            isRootComponent: '<',
            isEditable: '<',
        },
        controller: TextComponentController,
        controllerAs: 'textComponentVm',
        name: 'hbTextComponent',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/components/text/text_component.html',
    };

}());
