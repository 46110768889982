(function () {
    'use strict';

    // @ngInject
    function SplashScreenServiceCtor($, $q) {
        this.splashScreenElm = $('#splash-screen');
        this.splashScreenElmHB = $('#splash-screen-hb');
        this.$q = $q;

        this.isShowHoneyBookSplashScreen = false;
    }

    Services.SplashScreenService = Class({
        constructor: SplashScreenServiceCtor,

        hide() {
            return this._fadePromise('fadeOut', this.splashScreenElm);
        },

        show() {
            return this._fadePromise('fadeIn', this.splashScreenElm);
        },

        hideHB() {
            this.isShowHoneyBookSplashScreen = false;
        },

        showHB() {
            let defer = this.$q.defer();
            this.isShowHoneyBookSplashScreen = true;
            setTimeout(()=> {
                defer.resolve();
            }, 3500);
            return defer.promise;
        },

        _fadePromise(type, loader) {
            let defer = this.$q.defer();
            loader[type](400, () => defer.resolve() );
            return defer.promise;
        }

    });
}());
