(function () {
    'use strict';

    class ReportModuleTabbedController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
        }

        onTabClick (tabKey) {
            if (this.selectedTabListItem.key !== tabKey) {
                let newSelectedTab = this.tabsList[tabKey];
                this.selectedTabListItem = newSelectedTab;
                this.onTabSelect(newSelectedTab);
            }
        }
    }

    Components.ReportModuleTabbed = {
        bindings: {
            tabsList: '=',
            selectedTabListItem: '=',
            onTabSelect: '&'
        },
        transclude: {
            headerContent: '?headerContent',
            headerControls: '?headerControls',
            footerContent: '?footerContent',
            footerControls: '?footerControls',
        },
        controller: ReportModuleTabbedController,
        name: 'hbReportModuleTabbed',
        templateUrl: 'angular/app/modules/common/ui_components/report/report_module_tabbed_component.html',
    };

}());
