Directives.SearchDirective = function SearchDirective() {

    // @ngInject
    function SearchDirectiveControllerFunc($scope, $injector, AnalyticsService, SearchablesManager, $element, $timeout, GoogleAPIService, _, ContactsManager,PopupMessageService, DeviceService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SearchDirectiveController';
        var self = this;
        this.SearchablesManager = SearchablesManager;
        this.searchTxt = "";
        if (!this.avatarSize) {
            this.avatarSize = 'm';
        }
        this.GoogleAPIService = GoogleAPIService;
        this.ContactsManager = ContactsManager;
        this.PopupMessageService = PopupMessageService;
        this._ = _;
        this.suggestedContacts = [];
        this.AnalyticsService = AnalyticsService;
        this.showConnectToGoogle = false;
        this.NUMBER_OF_CONTACTS = 5;
        this.searchTxt = "";
        this.lastContactClickedEmail = "";
        this.disableGotoContact = (typeof this.onContactSelected === "function");
        this.DeviceService = DeviceService;
        this.openSearchTooltip = false;

        var dropdownElem = $element.find('#searchResultsToggle');
        var dropdownList = $element.find('#search-results');
        if (this.searchFromModel) {

            $timeout(function () {
                dropdownElem.click();
                this.onFocus();
            }.bind(this), 300);

            $scope.$watch('searchVm.modelToSearchFrom', _.debounce(function (newVal, oldVal) {
                this.searchTxt = newVal;

                this.searchInputChanged();
            }, 300).bind(this));
        }

        this.searchEntities = this.searchEntities || 'all'; // default
        this.blockElmClass = this.bemClass || 'search';

        // is this used?
        this.searchFocused = false;

        // used to determine if dropdown should be open or closeDropdown
        // after register success
        this.isSearchInputInFocus = false;

        this.largeGridBreakPoint = this.DeviceService.navLargeBreakPoint();
        if (this.largeGridBreakPoint ) {
            this.placeholderText = 'Search';
        } else {
            this.placeholderText = 'Search...';
        }

        this.filterOnlyShowNonExisting = function filterOnlyShowNonExisting(item) {
            var index = this.searchData && this._.findIndex(this.searchData, function exists(userInSearch) {
                    var searchedEmail = userInSearch.properties.email && userInSearch.properties.email.toLowerCase();
                    return userInSearch.type === 'Contact' &&
                        ((item.user && searchedEmail === item.user.email.toLowerCase()) ||
                        (!item.user && searchedEmail === item.toLowerCase()));
            }.bind(this));
            return (index === -1);
        }.bind(this);

        this.searchInputChanged = function searchInputChanged() {
            if (this.searchTxt && this.searchTxt.length >= 3) {
                var sameAsLastClicked = this.lastContactClickedEmail && (this.lastContactClickedEmail === this.searchTxt);
                if (sameAsLastClicked){
                    return;
                }

                if (!this.showConnectToGoogle) {
                    this.getContactsToView(this.searchTxt);
                }

                if (!this.disableSearchAnalytics) {
                    AnalyticsService.track(this, AnalyticsService.analytics_events.search_in_dashboard,
                        {searchd_term: this.searchTxt});
                }

                this.searchDataBeingFetched = true;
                this.searchData = this.SearchablesManager.search(this.searchTxt, this.searchEntities,{searchCommunity: this.allowCommunitySearch});
                this.register(this.searchData, 'success', function onSearchDataFetched(callbackReason) {
                    this.searchDataBeingFetched = false;
                }.bind(this));
            }
        };

        function closeDropdown() {
            var elem = angular.element('#search-dropdown-' + (this.customSelector || ''));
            if (elem.is(":visible")) {
                elem.hide();
            }
        }

        this.contactClicked = function contactClicked(contactItem) {
            closeDropdown();
            this.lastContactClickedEmail = contactItem.properties.email;

            if (this.onContactSelected) {
                this.onContactSelected({contact: contactItem.properties});
            }
        };

        this.googleContactClicked = function googleContactClicked(contactItem) {
            closeDropdown();

            var self = this;
            var trackingArgs = {
                email: contactItem.user.email,
                from: 'google',
                source: 'search directive'
            };

            this.lastContactClickedEmail = contactItem.user.email;

            var newContact = this.ContactsManager.convertToContact(contactItem.user);
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.save_contact, trackingArgs);

            if (this.onContactSelected) {
                this.onContactSelected({contact: newContact, is_external: true});
            }
        };

        this.isDropdownClose = function isDropdownClose() {
            return dropdownList.css('display') === 'none';
        };

        this.connectGoogleContacts = function connectGoogleContacts() {
            this.UserService.connectToAGoogleAccount(null, 'contacts');
        };


        this.connectGoogleOptionShown = function connectGoogleOptionShown() {
            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.connect_to_google_option_show);
        };

    }

    var SearchDirectiveController = Class(Controllers.BaseController, {
        constructor: SearchDirectiveControllerFunc,

        showSearchInput: function showSearchInput() {
            this.isSearchInputInFocus = !this.isSearchInputInFocus;
            this.openSearchTooltip = !this.openSearchTooltip;
        },

        onFocus: function onFocus(){
            this.isSearchInputInFocus = true;
        },

        getSearchContainerClass: function() {
            if (this.isSearchInputInFocus && this.blockElmClass) {
                return this.blockElmClass + "__search-input-container--opened";
            }
        },

        getSearchInputClass: function () {
            if (this.isSearchInputInFocus && this.blockElmClass) {
                return this.blockElmClass + "__search-input--opened";
            }
        },

        onBlur: function onBlur() {
            if (!this.searchTxt) {
                this.isSearchInputInFocus = false;
                this.openSearchTooltip = false;
            }

        },

        getContactsToView: function (apiToCall, search) {
            var self = this;

            if (search === undefined) {
                search = null;
            }

            if (!this.UsersManager.getCurrUser().isGoogleIntegrationActive('contacts')) {
                this.showConnectToGoogle = true;
                return;
            }

            this.isLoadingContacts = true;
            this.UserService.searchGoogleContacts(search, 1, this.NUMBER_OF_CONTACTS)
                .then(function(searchResults){
                    self.suggestedContacts = searchResults.contacts;
                })
                .finally(function(){
                    self.isLoadingContacts = false;
                });
        },
    });

    return {
        scope: {
            searchEntities: '@?searchEntities',
            allowGoogleContactSearch: '@?allowGoogleContactSearch',
            allowCommunitySearch: '@?allowCommunitySearch',
            replaceEmailWithCompanyName: '@replaceEmailWithCompanyName',
            onContactSelected: '&?onContactSelected',
            searchFromModel: '@?searchFromModel',
            disableSearchAnalytics: '@?disableSearchAnalytics',
            modelToSearchFrom: '=?modelToSearchFrom',
            clickableComponentsSelector: '@?',
            avatarSize: '@?',
            bemClass: '@?',
            searchFocus: '=?searchFocus',
            onItemClick: '&?',
            customSelector: '@?'
        },
        templateUrl: 'angular/app/modules/core/features/search/search_directive_template.html',
        controller: SearchDirectiveController,
        controllerAs: 'searchVm',
        bindToController: true

    };
};
