//class InputBaseController {

(function () {
    "use strict";

    // @ngInject
    function InputBaseControllerCtor(
            $scope, $element, $attrs, $compile, $translate, _, $window) {

        // constructor(_, $translate) {
        this.isRequired = false;
        this.validationError = '';
        this._ = _;
        this.$translate = $translate;
        this.modelOptions = {
            allowInvalid: true,
            debounce: 250
        };
    }

    //InputBaseController.$inject = ['_', '$translate'];
    // Components.InputBaseController = InputBaseController;
    Components.InputBaseController = Class({
        constructor: InputBaseControllerCtor,

        //$onInit() {
        $onInit: function() {
            if (!this.tabIndex) {
                this.tabIndex = 0;
            }

            if (!this.label) {
                throw Error('label is a required field');
            }

            if (!this.flavor) {
                this.flavor = 'standard';
            }

            this.placeholder = '';
            if (this.flavor !== 'material') {
                this.placeholder = this.label;
            }

            this.cssClass = {
                'form-element': true,
                'form-element--standard': this.flavor === 'standard',
                'form-element--material': this.flavor === 'material',
                'form-element--error' : this.validationError,
            };

            // this.model.$render = () => {
            //     this.value = this.model.$viewValue;
            // }
            this.model.$render = function() {
                this.value = this.model.$viewValue;
            }.bind(this);

            if (!this.maximumLength) {
                this.maximumLength = 200;
            }

            if (this.minimumLength) {
                this.isRequired = true;
            }

            // Generate unique ids for all form input elements to
            // prevent clashes.
            this.name = this._.uniqueId(this.name);
        },

        // onChange() {
        onChange: function() {
            try {
                if (this.value && this.maximumLength
                        && this.value.length > this.maximumLength) {
                    this.value = this.value.substring(0, this.maximumLength);
                }
            } catch(e) {
                this.value = '';
            }

            this.model.$setViewValue(this.value);
        },

        onBlur: function () {
        },

        // TODO(sean): Error message translation
        // validate() {
        validate: function() {
            if (this.isRequired && !this.value) {
                this.validationError = this.$translate.instant(
                    'ERRORS._FIELD_IS_REQUIRED_');
                return false;
            }

            if (this.value && this.minimumLength) {
                if (this.value.length < this.minimumLength) {
                    this.validationError = this.$translate.instant(
                        'ERRORS._MIN_LENGTH_INT_') + this.minimumLength;
                    return false;
                }
            }

            if (this.value && this.maximumLength) {
                if (this.value.length > this.maximumLength) {
                    this.validationError = this.$translate.instant(
                        'ERRORS._MAX_LENGTH_INT_') + this.maximumLength;
                    return false;
                }
            }

            this.validationError = '';
            return true;
        }
    });

}());