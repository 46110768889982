
// @ngInject

Models.BrochureProposalsContainersCollection = function BrochureProposalsContainersCollection(BaseCollection) {

    return Class(BaseCollection, {

            constructor: function constructor(){
                this.constructor.$super.call(this);
                this._collection.__className = 'BrochureProposalsContainersCollection';
                this._collection.__childsType = 'BrochureProposalsContainerModel';
                return this._collection;
            }
        }
    );

};