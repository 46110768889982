Directives.ProposalSummaryDirective = function ProposalSummaryDirective() {

    // @ngInject
    function ProposalSummaryDirectiveControllerFunc($scope, $q, $injector, $rootScope, $timeout, _, UIUtils, Enums,
                                                    AnalyticsService, WorkspaceFileService, PopupMessageService,
                                                    uuid4, CompaniesManager, WorkspaceFilesManager, FeaturesService, UsersManager, InjectBrandingService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProposalSummaryDirectiveController';
        this.$q = $q;
        this._ = _;
        this.UIUtils = UIUtils;
        this.Enums = Enums;

        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.FeaturesService = FeaturesService;
        this.user = UsersManager.getCurrUser();
        this.proposal = this.proposalModel;
        this.VOUCHER_CODE_LENGTH = 8;
        this.InjectBrandingService = InjectBrandingService;

        this.isClientMode = this.fileModel && this.fileModel.isClientMode();

        var modelToRegister = this.fileModel ? this.fileModel : this.companyModel;
        this.register(modelToRegister, 'success', this._gotEntity.bind(this));

        if (this.fileModel && this.fileModel.hasProposal()) {
            var directiveId = uuid4.generate();
            WorkspaceFileService.addFileValidation(directiveId, function proposalValidator() {
                return $q(function (resolve, reject) {
                    if (this.proposal.total_price > 0) {
                        resolve();
                    } else {
                        $scope.scrollToTotalPrice();
                        if (this.fileModel.isRecurringPayment()) {
                            PopupMessageService.showAlert(PopupMessageService.severityTypes.error, 'PROPOSAL._ZERO_TOTAL_MESSAGE_ERROR_');
                            reject();
                        } else {
                            PopupMessageService.showConfirmPromise(PopupMessageService.severityTypes.warning, 'PROPOSAL._ZERO_TOTAL_MESSAGE_WARNING_', 'Continue', 'Cancel').then(function() {
                                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.confirm_send_file_amount_zero);
                                resolve();
                            }.bind(this))
                                .catch(function() {
                                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.cancel_send_file_amount_zero);
                                    reject();
                                }.bind(this));
                        }
                    }
                }.bind(this));
            }.bind(this), 600);
        }

        $scope.$on('$destroy', function onDestroy() {
            WorkspaceFileService.removeFileValidation(directiveId);
        }.bind(this));
    }

    var ProposalSummaryDirectiveController = Class(Controllers.BaseController, {
        constructor: ProposalSummaryDirectiveControllerFunc,

        shouldShowVoucher: function shouldShowVoucher() {
            var isFeatureActive = this.fileModel ? this.fileModel.is_promotion_feature_enabled : this.user.shouldShowGiftCardFeature();
            var isRecurring = this.fileModel && this.fileModel.isRecurringPayment();
            return isFeatureActive && !isRecurring && (this.allowVoucherEdit() || this.proposalModel.hasAppliedVoucher());
        },

        onVoucherCodeChange: function onVoucherCodeChange() {

            if (!this.voucherCode || this.voucherCode.length < this.VOUCHER_CODE_LENGTH || this.applyVoucherInProgress) {
                return;
            }

            this.applyVoucherInProgress = true;
            this.voucherErrorMsg = null;

            var analyticResult = 'valid';

            this.WorkspaceFilesManager.applyVoucher(this.fileModel, this.voucherCode.toUpperCase()).then(function() {
                this.showVoucherInput = false;
                this.voucherCode = null;
                this.showVoucherSuccess = true;
            }.bind(this)).catch(function(resp) {
                var errorType = resp.data.error_type;
                if (errorType === 'HBObjectNotFoundError') {
                    this.voucherErrorMsg = 'PROPOSAL.VOUCHER.ERRORS._INVALID_';
                    analyticResult = 'invalid';
                } else if (errorType === 'HBVoucherRedeemedError') {
                    this.voucherErrorMsg = 'PROPOSAL.VOUCHER.ERRORS._ALREADY_USED_';
                    analyticResult = 'already redeemed';
                } else if (errorType === 'HBVoucherOtherBrandError') {
                    this.voucherErrorMsg = resp.data.error_message;
                    analyticResult = 'other brand';
                }
                else {
                    analyticResult = 'unknown error';
                    this.voucherErrorMsg = 'ERRORS.SERVER_API._UNKNOWN_';
                }
            }.bind(this)).finally(function() {
                this.applyVoucherInProgress = false;
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.voucher_applied, {
                    workspace_id: this.fileModel.couple_card_id,
                    result: analyticResult
                });
            }.bind(this));
        },

        allowVoucherEdit: function allowVoucherEdit() {
            return this.isEditMode() && this.fileModel && !this.fileModel.hasPaidPayments();
        },

        canClearVoucher: function canClearVoucher() {
            return this.allowVoucherEdit() && !this.fileModel.isSent();
        },

        onClearVoucherClick: function onClearVoucherClick() {
            this.WorkspaceFilesManager.removeVoucher(this.fileModel).then(function() {
                this.showVoucherSuccess = false;
                this.voucherCode = null;
            }.bind(this));
        },


        updateProposal: function updateProposal() {
            var proposalData = {
                description: this.proposal.description,
                discount: this.proposal.discount,
                discount_type: this.proposal.discount_type || "relative",
                tax: this.proposal.tax,
                tax_title: this.proposal.tax_title,
                tax_type: this.proposal.tax_type,
                secondary_tax: this.proposal.secondary_tax,
                secondary_tax_title: this.proposal.secondary_tax_title,
                secondary_tax_type: this.proposal.secondary_tax_type,
                svc: this.proposal.svc,
                svc_type: this.proposal.svc_type || "relative",
                svc_taxable: this.proposal.svc_taxable
            };

            return this.proposal.updateProposal(proposalData);
        },

        handleDiscountPopover: function handleDiscountPopover(){
            if(!this.isEditMode()) {
                return;
            }
            this.discountSummaryPopover = !this.discountSummaryPopover;
        },

        onDiscountCancelChanges: function onDiscountCancelChanges(discount) {
            if(discount) {
                this.proposal.discount = discount.amount;
                this.proposal.discount_type = discount.type;
                this.updateProposal();
            }

            this.discountSummaryPopover = false;
        },

        onDiscountTypeChange: function onDiscountTypeChangey(type) {
            this.proposal.discount_type = type;
            this.updateProposal();
        },

        onDiscountAmountChange: function onDiscountAmountChange(amount) {
            this.proposal.discount = amount;
            this.updateProposal();
        },

        shouldShowDiscountOnSummary: function shouldShowDiscountOnSummary() {
            return this.isEditMode() && this.proposal.sub_total || this.proposal.discount;
        },

        shouldShowSvc: function shouldShowSvc() {
            return this._shouldShowSVCForCompany() && (this.proposal.has_selected_svc || this.proposal.svc);
        },

        shouldShowTax: function shouldShowTax() {
            if (this.isEditMode()) {
                return true;
            }
            else {
                return (this.proposalModel.tax_type === 'relative' && this.proposal.has_selected_tax) ||
                       (this.proposalModel.tax_type === 'absolute' && (this.proposalModel.tax > 0 || this.proposal.has_selected_tax))
            }
        },

        shouldShowTaxSvc: function shouldShowTaxSvc() {
            return this.shouldShowSvc() && (this.proposal.tax || this.proposal.secondary_tax) && (this.isEditMode() || this.proposal.svc_taxable);
        },


        taxSaved: function taxSaved() {
            if (this.proposalModel.tax_type === 'relative') {
                this.companyModel.updatedDefaultTax(this.proposalModel.tax, this.proposalModel.tax_title);
            }
        },

        togglePrimaryTaxPopover: function togglePrimaryTaxPopover() {
            if (this.isEditMode()) {
                this.showPrimaryTaxPopover = !this.showPrimaryTaxPopover;
            }
        },

        closePrimaryTaxPopover: function closePrimaryTaxPopover(){
            this.showPrimaryTaxPopover = false;
        },

        toggleSecondaryTaxPopover: function toggleSecondaryTaxPopover(){
            if (this.isEditMode()) {
                this.showSecondaryTaxPopover = !this.showSecondaryTaxPopover;
            }
        },

        closeSecondaryTaxPopover: function closeSecondaryTaxPopover(){
            this.showSecondaryTaxPopover = false;
        },


        toggleSvcPopover: function toggleSvcPopover(){
            if (this.isEditMode()) {
                this.showSvcPopover = !this.showSvcPopover;
            }
        },

        isEditMode: function isEditMode() {
            return this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
        },

        _shouldShowSVCForCompany: function _shouldShowSVCForCompany() {
            var company = this.companyModel || (this.fileModel && this.fileModel.company);
            return (!!company && company.isVenue());
        },

        _gotEntity: function _gotEntity() {

            // Hack for existing venues without svc
            // should happen only once for each venue
            if (this.isEditMode() &&
                this._shouldShowSVCForCompany() && !this.proposalModel.svc_type) {
                this.proposalModel.svc_type = 'relative';
                this.proposalModel.svc = this.companyModel.getDefaultSVC();
                this.updateProposal();
            }

            this._initBranding();
        },

        isRelativeAmount: function isRelativeAmount(){
           return this.proposalModel.discount_type === this.Enums.discountTypes.relative && this.proposalModel.discount_absolute > 0;
        },

        _initBranding: function _initBranding() {
            if (this.fileModel) {
                this.InjectBrandingService.setBrandColorVars(this.fileModel.company.brand_color);
            }
        }

    });

    return {
        scope: {
            proposalModel: '=',
            fileModel: '=', // might be null if in company templates
            companyModel: '=',
            isOwnerMode: '=',
            isPreviewMode: '=',
            isEditableMode: '=',
        },
        templateUrl: 'angular/app/modules/core/features/workspace_file/services/summary/proposal_summary_directive_template.html',
        controller: ProposalSummaryDirectiveController,
        controllerAs: 'proposalSummaryVm',
        bindToController: true,
        link: function proposalDirectiveLink(scope, element) {
            scope.scrollToTotalPrice = function scrollToTotalPrice() {
                var totalPriceElement = element.find('#grandTotal');
                return totalPriceElement.scrollParent().scrollTo(null, totalPriceElement.offset().top - 300, 300);
            };

        }
    };
};
