Directives.ContactFormDirective = function ContactFormDirective() {

    // @ngInject
    function ContactFormDirectiveControllerFunc($q, $sce, $, $state, $injector, $scope, $translate, $timeout, $element, $window, $document, SavingIndicatorService,
                                                AnalyticsService, QuestionService, ModalService, CompaniesManager, TemplatesViewService, ContactFormService,
                                                uuid4, PopupMessageService, _, Enums, AutomationService, AppConfigService, AppStates, DeviceService,
                                                UsersManager, EventService, moment, OnboardingService, ngToast, UiPersistenceService, FeaturesService, ServiceLazyLoaderService,
                                                AbTestService, CssVariablesService, UIUtils, FontsService, TemplatesManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ContactFormDirectiveController';
        this.AppStates = AppStates;
        this.$scope = $scope;
        this.currentUser = UsersManager.getCurrUser();
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.QuestionService = QuestionService;
        this.AutomationService = AutomationService;
        this.EventService = EventService;
        this.CompaniesManager = CompaniesManager;
        this.AppConfigService = AppConfigService;
        this.UsersManager = UsersManager;
        this.FeaturesService = FeaturesService;
        this.FacebookService = ServiceLazyLoaderService.get('FacebookService');
        this.FontsService = FontsService;
        this.Enums = Enums;
        this.$translate = $translate;
        this.$window = $window;
        this.$state = $state;
        this.$sce = $sce;
        this.$ = $;
        this._ = _;
        this.moment = moment;
        this.$timeout = $timeout;
        this.ModalService = ModalService;
        this.ngToast = ngToast;
        this.UiPersistenceService = UiPersistenceService;
        this.AbTestService = AbTestService;
        this.TemplatesViewService = TemplatesViewService;
        this.DeviceService = DeviceService;
        this.ContactFormService = ContactFormService;
        this.CssVariablesService = CssVariablesService;
        this.UIUtils = UIUtils;
        this.TemplatesManager = TemplatesManager;

        this.isMobile = this.DeviceService.nxSmallBreakpoint();
        this.linksIsCopied = false;
        this.copyLinkTooltip = "Copy link";
        this.baseTranslateHeaderWarning = '';
        this.headerWarning = '';
        this.headerWarningDescription = '';
        this.company = CompaniesManager.getCurrCompany(true);
        this.isCompanyModerator = this.currentUser.hasCompanyModeratorPermissions();
        this.otherTeamMembers = this.company.getOtherCompanyMembers();
        this.selectedUser = this.currentUser;
        this.showSaveRecipientLoader = false;
        this.isShowOnboadring = false;
        this.isPaddedInputStyle = false;
        this.appState.isContactFormAsideMenuOpen = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormAsideMenu, {open: true}).open;

        this.CssVariablesService.init('dynamicContent');

        var unwatch =  $scope.$watch('contactFormVm.contactFormModel.last_publish_timestamp', function(newTimeStamp, oldTimeStamp) {
            // change only when last time stamp is null and the new one is not null.
            if (!oldTimeStamp && newTimeStamp) {
                this.tradeShowUrl = this.ContactFormService.getPublicUrl(this.contactFormModel);
                unwatch();
            }
        }.bind(this));

        this.register(ContactFormService, 'showContactFormSidebar', this.showContactFormSidebar.bind(this));

        $scope.$on('$destroy', function () {
            this.CssVariablesService.clearAllVarsByType('dynamicContent');
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.contactFormAsideMenu, {open: this.appState.isContactFormAsideMenuOpen});
        }.bind(this));


        this.isDynamicFile = this.contactFormModel.isDynamicFile();
		this.isGiftCard = this.contactFormModel.isGiftCard();
		this.ContactFormService.initSideBarStack(this.contactFormModel);

        this.setRecipient();

        this.activeTab = 1;
        this.shouldShowLeadAd = this.isLeadAdsActive(this.currentUser);

        this.suggestedFieldsList = [
            {
                data_key: this.Enums.ContactFormDataKeys.event_type,
                analytics_event: "menu/add_event_type",
                class: "icon icon-hb-nx-folder-ribbon-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._PROJECT_TYPE_'
            },
            {
                data_key: this.Enums.ContactFormDataKeys.phone_number,
                analytics_event: "menu/add_client_phone",
                class: "icon icon-hb-nx-phone-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._CLIENT_PHONE_NUMBER_'
            },
            {
                data_key: this.Enums.ContactFormDataKeys.event_date,
                analytics_event: "menu/add_event_date",
                class: "icon icon-hb-nx-calendar-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._PROJECT_DATE_'
            },
            {
                data_key: this.Enums.ContactFormDataKeys.event_budget,
                analytics_event: "menu/add_event_budget",
                class: "icon icon-hb-nx-dolar-ciecle-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._PROJECT_BUDGET_'
            },
            {
                data_key: this.Enums.ContactFormDataKeys.event_location,
                analytics_event: "menu/add_event_location",
                class: "icon icon-hb-nx-location-pin-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._PROJECT_LOCATION_'
            },
            {
                data_key: this.Enums.ContactFormDataKeys.event_guests,
                analytics_event: "menu/add_guest_count",
                class: "icon icon-hb-nx-users-plus-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._GUEST_COUNT_'
            },
            // {
            //     data_key: this.Enums.ContactFormDataKeys.how_client_hear_about,
            //     analytics_event: "menu/add_how_client_hear_about",
            //     class: "icon icon-hb-comment-b",
            //     text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._HOW_DID_YOU_HEAR_ABOUT_'
            // },
            {
                data_key: this.Enums.ContactFormDataKeys.how_client_hear_about_company,
                analytics_event: "menu/add_how_client_hear_about_company",
                class: "icon icon-hb-nx-message-32",
                text: 'QUESTIONNAIRE.ADD_QUESTION_OR_SECTION._HOW_DID_YOU_HEAR_ABOUT_'
            }
        ];

        this.QUESTION_TYPES = {
            TEXT_BOX: 'textbox',
            OPEN_QUESTION: 'open_question',
            DROPDOWN: 'dropdown',
            RADIO: 'radio',
            CHECKBOX: 'checkbox',
            TEXTAREA: 'textarea',
        };

        this.captchaType = {
            type: "google",
            isChecked : this.contactFormModel.captcha_type === 'google',
            label: 'CONTACT_FORM.LABELS._GOOGLE_SPAM_PROTECTION_'
        };

        this.sortableOptions = {
            axis: 'y',
            revert: 200,
            handle: '.js-question-drag',
            opacity: 0.8,
            placeholder: 'question-sortable-placeholder',
            helper: 'clone',

            stop: function (event, ui) {

                var args = {};
                var id = ui.item[0].id.split("-")[1];

                // Create order data
                self.questions_model.forEach(function (questionViewModel, index) {
                    var questionModel = self._.findWhere(self.contactFormModel.questions, {_id: questionViewModel._id});
                    questionModel.order = index;

                    if (id === questionModel._id) {
                        args = questionModel.getAnalyticsArgs();
                    }

                }.bind(self));

                self.contactFormModel.updateQuestionsOrder();

                AnalyticsService.track(self, AnalyticsService.analytics_events.questionnaire_question_drag, args);
            }
        };

        this.setHeaderState();

        this.isEditMode = function isEditMode() {
            //return  this.isOwnerMode &&
            //    this.isEditableMode &&
            //    !this.isPreviewMode;v
            return true;
        }.bind(this);

//      Question Editable = this.isOwnerMode && this.isEditableMode && !this.isPreviewMode;
//      Answer Editable = !this.isOwnerMode && this.isEditableMode
//      Answer Visible = !this.isEditableMode
//      Placeholder Editable? = QuestionEditable
//      Placeholder Visible? =

//      Regular - Question Editable
//      Hover - Question Editable
//      Edit - Question Editable
//      Client - Answer Editable
//      Summary - Answer Visible

        // HTML elements bindings
        var self = this;
        this.directiveId = uuid4.generate();
        this.focusedOnId = '';
        this.showAddLoader = false;
        this.showTradeShow = false;

        this.questions_model = [];
        this.contactFormTabs = [];

        this.ShownSuggestedFieldsList = [];

        this.register(this.contactFormParentModel, 'success', this.gotContactFormParent);
        this.register(this.contactFormModel, 'success', this.gotContactForm);

        this.fillAutomationData = function fillAutomationData() {
            this.contactFormTabs = [
                {
                    title: 'Build',
                    tabNumber: 1,
                    mxpl: 'insert'
                },
                {
                    title: 'Customize',
                    tabNumber: 2,
                    mxpl: 'preferences'
                }
            ];
            this.company = this.contactFormParentModel;
            this.eventTypes = this.company.sortedProjectTypes();
            this.baseTranslateHeaderWarning = 'CONTACT_FORM.COMPANY.';

            if (this.company.hb_url) {
                if (this.contactFormModel.from_migration) {
                    this.company_hb_url = this.AppConfigService.appConfiguration().url + "widget/" + this.company.hb_url;
                } else {
                    this.company_hb_url = this.AppConfigService.appConfiguration().url + "widget/" + this.company.hb_url + "/cf_id/" + this.contactFormModel._id;
                }
                this.showTradeShow = true;
            }

            this.companyLeadReplyAction = this.AutomationService.buildActionInfo(this.company, this.Enums.WorkflowAutomationTypes.inquiry_reply_thank_you,
                'lead_thank_you',
                'lead_thank_you',
                '.ACTION_COPY._THANK_YOU_',
                '.ACTION_COPY._THANK_YOU_EDIT_',
                true,
                false,
                true,
                true,
                false,
                []);

            this.questionnaireAttachment = (this.companyLeadReplyAction.templateName === 'questionnaire');
            this.brochureAttachment = (this.companyLeadReplyAction.templateName === 'brochure');
        };

        if (this.isDynamicFile) {
            this.fonts = FontsService.fonts;
        } else {
            this.fonts = FontsService.oldContactFormFont;
            this.actionText();
        }

        this.fillViewModelAccordingToModel = function fillViewModelAccordingToModel() {

            this.setHeaderState();

            this.questions_model.splice(0);

            //sort the service according to the order attribute make sure that identical order will be left in it's original place (==stable sorting)
            this.questions_model = _.sortBy(this.contactFormModel.questions, function (question) {
                return question.order ? question.order : 0;
            });

            // init the suggested fields to show:
            this.ShownSuggestedFieldsList = _.filter(this.suggestedFieldsList, function (field) {
                return this.showSuggestedField(this.contactFormModel.questions, field.data_key);
            }.bind(this));

            this.redirectUrl = this.isDynamicFile ? this.contactFormModel.contact_form_settings.redirect_url :
                this.contactFormModel.redirect_url;

            if (this.isDynamicFile) {
                this._updateAllCssVars();
            } else {
                this.actionText();
            }
        };

        this.fillViewModelAccordingToModel();
        this.fillAutomationData();

        this.setDOMElement = function setDOMElement(elem) {
            this.$element = elem;
            this.$addQOptions = $('.add-question-options', this.$element);
        };

        this.addQuestion = function addQuestion(type) {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;

            this.contactFormModel.createQuestion({question_type: type}).then(function success() {
                this.showAddOptions = false;
                this.showAddLoader = false;
                this.closeMenu();

                $timeout(function () {
                    // get last added question
                    var question = this.questions_model[this.questions_model.length - 1];
                    this.initializeNewQuestion(question, false);
                }.bind(this));
            }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.too_many_items, {
                        model: 'contact form',
                        type: 'question'
                    });
                    if (resp.data.error_type === 'TooManyItemsError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    }
                }.bind(this))
                .finally(function final() {
                    this.showAddLoader = false;
                }.bind(this));
        }.bind(this);

        this.initializeNewQuestion = function initializeNewQuestion(question, isSuggested) {
            // initial empty answers when a question is created
            if (question.question_type === 'questions') {
                this.QuestionService.addOptionalAnswer(question);
            }
            else if ((question.question_type === 'radio' || question.question_type === 'checkbox') && !isSuggested) {
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
            } else if (question.question_type === 'dropdown' && !isSuggested) {
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
                this.QuestionService.addOptionalAnswer(question);
            }
            // click question to put into edit mode
            $element.find('#question-' + question._id + ' .questionnaire-question').click();
            // focus on question title field
            $element.find('#question-' + question._id + ' .question-title').focus();
        };

        this.addEventQuestions = function addEventQuestions() {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;
            this.contactFormModel.createEventQuestions().then(
                function success() {
                    self.showAddOptions = false;
                    self.showAddLoader = false;
                    self.closeMenu();
                },
                function error() {
                    self.showAddLoader = false;
                }
            );
        };

        this.updateQuestion = function updateQuestion(question) {
            return this.contactFormModel.updateQuestion(question);
        };

        this._deleteQuestion = function _deleteQuestion(question) {
            return this.contactFormModel.deleteQuestion(question);
        };

        this.deleteQuestion = function deleteQuestion(question) {
            if (question.data_type === 'hear_about') {
                // Show confirmation modal
                return this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.none, 'CONTACT_FORM._DELETE_LEAD_SOURCE_CONFIRMATION_MESSAGE_', 'CONTACT_FORM._CANCEL_', 'CONTACT_FORM._YES_DELETE_', 'CONTACT_FORM._DELETE_LEAD_SOURCE_CONFIRMATION_TITLE_').then(function() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.user_click_delete_hdyhau_contact_form, {
                        answer: 'cancel'
                    });
                }.bind(this), function() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.user_click_delete_hdyhau_contact_form, {
                        answer: 'delete'
                    });
                    return this._deleteQuestion(question);
                }.bind(this));
            } else {
                return this._deleteQuestion(question);
            }
        };

        this.closeMenu = function closeMenu() {
            this.showAddOptions = false;

            if (this.$question) {
                this.$question.find('.questionnaire-question').css('margin-bottom', 'inherit');
            }
        };

        this.toggleAddMenu = function toggleAddMenu() {
            this.showAddOptions = !this.showAddOptions;
            if (this.showAddOptions) {
                // scroll down to show add question menu
                $timeout(function () {
                    $window.scrollTo(0, $document[0].body.scrollHeight);
                });
            }
            var self = this;

            // if this isn't mobile...
            if (!($(window).width() / parseFloat($("html").css("font-size")) < 40.01)) {
                // add style to the closest hb-question
                if (this.$question) {
                    //this.$addQOptions.show();
                    setTimeout(function () {
                        var theHeight = $scope.questionnaireVm.$addQOptions.height() + 50;
                        $scope.questionnaireVm.$question.find('.questionnaire-question').css('margin-bottom', theHeight); //addClass('closestQuestionToAdd');
                    }, 2);

                }
            }
            else {
                if (this.$question) {
                    this.$question.find('.questionnaire-question').css('margin-bottom', 'inherit');
                }
            }
        };

        this.backFirstScreen = function backFirstScreen() {
            this.isSaveAsEnabled = false;
        };

        this.fontColor = function fontColor(color) {
            // old contact form update font color function

            var styleObj = this.contactFormModel.getStyleObj('form_component_style');
            styleObj.font_color = color;

            var rgbaColor = this.UIUtils.hexToRgbA(color);
            this.CssVariablesService.setStyle('dynamicContentTextColor', rgbaColor);

            var rgbaPlaceholderColor = this._getPlaceholderColor(rgbaColor);
            this.CssVariablesService.setStyle('dynamicContentPlaceholderColor', rgbaPlaceholderColor);

            this.contactFormModel.updateStyle('form_component_style');
            AnalyticsService.track(this, 'color choose', {color_for: 'font color'});
        };

        this.inputFieldColor = function inputFieldColor(color) {
            // old contact form update input border color function

            var styleObj = this.contactFormModel.getStyleObj('form_component_style');
            styleObj.border_color = color;

            var rgbaColor = this.UIUtils.hexToRgbA(color);
            this.CssVariablesService.setStyle('dynamicContentInputBorderColor', rgbaColor);

            this.contactFormModel.updateStyle('form_component_style');
            AnalyticsService.track(this, 'color choose', {color_for: 'field color'});
        };

        this.pickerOpenedFieldColor = function pickerOpenedFieldColor() {
            AnalyticsService.track(this, 'open color picker', {color_for: 'field color'});
        };

        this.pickerOpenedFontColor = function pickerOpenedFontColor() {
            AnalyticsService.track(this, 'open color picker', {color_for: 'font color'});
        };

        this.onOpenedFontFamilyList = function onOpenedFontFamilyList(type) {
            AnalyticsService.trackClick(this, 'open font list', {type: type});
        };

        this.onOpenedFontSizeList = function onOpenedFontSizeList(type) {
            AnalyticsService.trackClick(this, 'open font size', {type: type});
        };

        this.onToggleFontWeight = function onOpenedFontSizeList(type, newvalue, item) {
            AnalyticsService.trackClick(this, 'Toggle questions bold property', {newvalue: newvalue, type:type});

            var styleContainer = "form_component_style";
            if (type === this.Enums.ContactFormBuilderType.buttons) {
                styleContainer = "button_component_style";
            }
            this._setActiveFontOnModel(item, styleContainer);
            this.contactFormModel.updateStyle(styleContainer);
        };

        this.getContactFormType = function getContactFormType() {
            if (this.isDynamicFile) {
                return "dynamic";
            }

            return "static";
        };

        // This function should be deprecated after old contact form is gone
        this.activeFont = function activeFont(index, font) {
            AnalyticsService.track(this, this.AnalyticsService.analytics_events.selected_new_contact_form_font, {newValue: font.font_type});

            this._setActiveFontOnModel(font, 'form_component_style');
            this.contactFormModel.updateStyle('form_component_style');
        };

        // TODO (abe) refactor, perhaps by checking $pristine and/or $untouched state of question textarea
        this.shouldShowQuestionTooltip = function shouldShowQuestionTooltip() {
            if (this.isEditMode()) {
                // check if there are 2 questions, seems to be none before initialization
                if (this.contactFormModel.questions.length === 2) {
                    // question with tooltip has been modified, check by value comparison (refactor to check untouched?)
                    // and check if user has clicked the question and activated editMode on the question
                    return !this.contactFormModel.questions[1].question_text && !angular.element('#question-' + this.contactFormModel.questions[1]._id + ' .questionnaire-question').hasClass('edit-mode');
                } else {
                    // tooltip does not show if there are no questions, or only 1, or more than 2
                    return false;
                }
            } else {
                return false;
            }
        };

        this.showExamplesModal = function showExamplesModal() {
            this.ModalService.openContactFormExamplesModal();
        };

        if (this.isDynamicFile) {
            SavingIndicatorService.unregisterModel(this.currentUser);
            SavingIndicatorService.unregisterModel(this.company);
            SavingIndicatorService.registerModel(this.contactFormModel);
        }

        this.isFocusOnRedirectUrlText = false;
        this.redirectTypes = [
            {
                type: 'default',
                label: this.$translate.instant('CONTACT_FORM.LABELS._DEFAULT_THANK_YOU_')
            },
            {
                type: 'custom_url',
                label: this.$translate.instant('CONTACT_FORM.LABELS._THANK_YOU_URL_')
            }
        ];

        // Start: Remove after we decide to switch to new contact form layout
        this.publishing = false;
        this.gettingCode = false;
        this.gettingPreviewCode = false;

        this.captchaTypes = [
            {
                type: 'google',
                label: 'CONTACT_FORM.LABELS._GOOGLE_PROTECTION_'
            },
            {
                type: 'honeybook',
                label: 'CONTACT_FORM.LABELS._HONEYBOOK_PROTECTION_'
            }
        ];

        // End: Remove after we decide to switch to new contact form layout

        if (this.group) {
            this.tradeShowUrl = this.group_hb_url;
        } else if (this.company) {
            this.tradeShowUrl = this.ContactFormService.getPublicUrl(this.contactFormModel);
        }

        var uiPers = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormDynamicOnboarding, {});
        if (!this.isDynamicFile && !uiPers.seenDynamicCFTeaser && !this.AppConfigService.isE2E()) {
            this.showDynamicContactFormTeaser();
        }
        if (this.isDynamicFile && !uiPers.seenDynamicCFIntro && !this.isGiftCard && !this.AppConfigService.isE2E()) {
            this.$timeout(this.showOnboarding.bind(this), 3000);
        }

        if (this.contactFormModel.captcha_type == 'google' && !this.currentUser.isAdminLogin()) {
            this.hideRecaptchaToggle = true;
        }
    }

    var ContactFormDirectiveController = Class(Controllers.BaseController, {
        constructor: ContactFormDirectiveControllerFunc,

        setActiveFontFamily: function setActiveFontFamily(type, item, newvalue) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.selected_new_contact_form_font, {type: type, newvalue: newvalue});
            var styleContainer = "form_component_style";
            if (type === this.Enums.ContactFormBuilderType.buttons) {
                styleContainer = "button_component_style";
            }
            this._setActiveFontOnModel(item, styleContainer);
            this.contactFormModel.updateStyle(styleContainer);
        },

        setFontSize: function setSetFontSize(type, item, newvalue) {
            this.AnalyticsService.track(this, "Selected new font size", {type: type, newvalue: newvalue});
            var styleContainer = "form_component_style";
            if (type === this.Enums.ContactFormBuilderType.buttons) {
                styleContainer = "button_component_style";
            }
            this._setActiveFontOnModel(item, styleContainer);
            this.contactFormModel.updateStyle(styleContainer);
        },

        _getPlaceholderColor: function _getPlaceholderColor(color) {
            var placeholderOpacity = 0.4;
            var placeholderColor = '';
            var rgbaColor = this.UIUtils.hexToRgbA(color);
            var rgbaArray = rgbaColor.split(',');
            rgbaArray[3] = (placeholderOpacity * rgbaArray[3].substring(0, rgbaArray[3].length - 1)).toFixed(2);
            placeholderColor = rgbaArray.join() + ")";
            return placeholderColor;
        },

        openCaptchaVideo: function openCaptchaVideo(e) {

            e.preventDefault();

            var videoOptions = {
                videoTitle: '',
                videoId: 'gn1xiLHAtZM',
            };

            return this.ModalService.openGenericVideoModal(videoOptions);
        },

        _setActiveFontOnModel: function _setActiveFontOnModel(font, styleComponent) {
            if (!font) {
                return;
            }
            if (this.isDynamicFile) {
                var styleContainer = this.contactFormModel.file_dynamic_content.style_container[styleComponent] || {};
                styleContainer.font_type = font.font_type;
                styleContainer.font_family = font.font_family;
                styleContainer.font_url = font.font_url;
                styleContainer.font_img = font.font_img;
                styleContainer.font_weight = font.font_weight;
                styleContainer.font_size = font.font_size;

                this.contactFormModel.file_dynamic_content.style_container[styleComponent] = styleContainer;
            } else {
                this.contactFormModel.style.font_type = font.font_type;
                this.contactFormModel.style.font_family = font.font_family;
                this.contactFormModel.style.font_url = font.font_url;
            }
        },

        getCaptchaType: function getCaptchaType() {
            if (this.isDynamicFile) {
                return (this.contactFormModel.contact_form_settings.spam_protection_type);
            } else {
                return (this.captchaType.isChecked ? 'google' : 'honeybook');
            }
        },

        _updateSpamProtectionType: function _updateSpamProtectionType(type) {
            this.contactFormModel.contact_form_settings.spam_protection_type = type;
            this.contactFormModel.updateSettings(['spam_protection_type']);
        },

        setCaptchaType: function setCaptchaType(type) {
            if (this.captchaTogglingInProgress) {
                return;
            }
            this.captchaTogglingInProgress = true;
            var captchaType;
            if (type) {
                // remove after new contact form wins
                captchaType = type;
            } else {
                captchaType = this.getCaptchaType();
            }

            this.contactFormModel.setCaptchaType(captchaType).finally(function final() {
                this.captchaTogglingInProgress = false;
            }.bind(this));
        },

        getCurrRedirectUrlInModel: function getCurrRedirectUrlInModel() {
            if (this.isDynamicFile) {
                return this.contactFormModel.contact_form_settings.redirect_url;
            } else {
                return this.contactFormModel.redirectUrl;
            }
        },

        setRedirectType: function setRedirectType(type) {
            this.isFocusOnRedirectUrlText = (type === 'custom_url');
            this.contactFormModel.updateSettings(['redirect_type']);
        },

        initIsFocusOnRedirectUrlText: function initIsFocusOnRedirectUrlText() {
            this.isFocusOnRedirectUrlText = false;
        },
        
        setRedirectUrl: function setRedirectUrl(redirectUrl) {
            var self = this;
            var reg = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/;

            if (redirectUrl != null && redirectUrl.length !== 0 && !redirectUrl.includes("http://") && !redirectUrl.includes("https://")) {
                redirectUrl = 'http://' + redirectUrl;
            }

            var validation = reg.test(redirectUrl);
            if (validation || redirectUrl == null || redirectUrl.length === 0) {
                self.checkUrlInvalid = false;
                var savedRedirectUrl = this.getCurrRedirectUrlInModel();
                if (savedRedirectUrl !== redirectUrl) {
                    self.redirectUrlInProgress = true;
                    var promise;
                    if (this.isDynamicFile) {
                        self.contactFormModel.contact_form_settings.redirect_url = redirectUrl;
                        if (redirectUrl.length > 0)
                        {
                            this.contactFormModel.contact_form_settings.redirectUrlIsEmpty = false;
                        }
                        promise = this.contactFormModel.updateSettings(['redirect_url']);
                    } else {
                        self.contactFormModel.redirectUrl = redirectUrl;
                        promise = self.contactFormModel.setRedirectUrl(self.contactFormModel.redirectUrl);
                    }
                    promise.then(
                        function success() {

                        }).catch(function error(resp) {
                        if (resp && resp.data && resp.data.error_message) {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, resp.data.error_message);
                        } else {
                            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                        }
                    })
                        .finally(function final() {
                            self.redirectUrlInProgress = false;
                        });
                }
            } else {
                self.checkUrlInvalid = true;
            }
        },

        setAutomationProjectType: function setAutomationProjectType(projectType) {
            if (!this.isDynamicFile) {
                return;
            }
            var previousValue = this.contactFormModel.contact_form_settings.project_type;
            if (projectType && (previousValue !== projectType._id)) {
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.contact_form_project_type_automation, {
                    type: projectType.label,
                });

                this.contactFormModel.contact_form_settings.project_type = projectType._id;
                this.contactFormModel.updateSettings(['project_type']).then(
                    function success() {
                    }).catch(function error(resp) {
                    this.contactFormModel.contact_form_settings.project_type = previousValue;
                    if (resp && resp.data && resp.data.error_message) {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNKNOWN_');
                    }
                }.bind(this));
            }
        },

        beforeSelectAutomationProjectType: function beforeSelectAutomationProjectType(projectType) {
            var projectTypeQuestion = this.contactFormModel.file_dynamic_content.getConnectedQuestionBlock('event_type');
            if (projectTypeQuestion) {
                //project type question already exists
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, this.$translate.instant('CONTACT_FORM.ERRORS._CANT_SET_PROJECT_TYPE_'),
                    function() {
                        this._highligthConnectedQuestion(projectTypeQuestion)
                    }.bind(this), 'FREQUENT_BUTTONS._GOT_IT_');
                return false;
            }
            return true;
        },

        _highligthConnectedQuestion: function _highligthConnectedQuestion(question) {
            // click question to put into edit mode
            angular.element.find('#dynamic-file-block-'+ question._id)[0].classList.add('brochure-block__focus-block');

            this.$timeout(function () {
                angular.element.find('#dynamic-file-block-'+ question._id)[0].classList.remove('brochure-block__focus-block');
            }, 2500);
        },

        // Start: remove after new contact form wins

        actionText: function getFormCta() {

            var currCta = {};

            var contactFormCtaTypes =  {
                embed_code: {
                    type: 'embed_code',
                    text: 'CONTACT_FORM.LABELS._EMBED_CODE_'
                },
                publish_changes: {
                    type: 'publish_changes',
                    text: 'CONTACT_FORM.LABELS._PUBLISH_CHANGES_',
                },
                add_to_web_site: {
                    type: 'add_to_web_site',
                    text: 'CONTACT_FORM.LABELS._ADD_TO_WEB_SITE_'
                }
            };


            var contactFormState = this.contactFormModel.getContactFormState();
            currCta = contactFormCtaTypes[contactFormState];
            this.primaryCtaButtonType = currCta.type;
            this.primaryCtaButtonText = currCta.text;
        },

        addToWebSiteClick: function addToWebSiteClick() {
            this.publishing = true;

            this.ContactFormService.publish(false, true, this.contactFormModel)
                .then(function finishedAddToWebSite() {
                    if (this.isMalkut) {
                        this.reload();
                    }
                    this.publishing = false;

                }.bind(this))
                .catch(function () {
                    this.publishing = false;
                }.bind(this));
        },

        publishChangesClick: function publishChangesClick() {

            var emptyQuestionExists = this._.find(this.questions_model, function (question) {
                return !question.isShownToClient();
            });

            if (emptyQuestionExists) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.warning, 'CONTACT_FORM.ERRORS._INVALID_QUESTION_');
                return;
            }

            this.ContactFormService.publish(true, true, this.contactFormModel)
                .then(function finishedAddToWebSite() {
                    if (this.isMalkut) {
                        this.reload();
                    }
                    this.publishing = false;

                }.bind(this))
                .catch(function () {
                    this.publishing = false;
                }.bind(this));
        },

        embedCodeClick: function embedCodeClick() {
            this.openEmbedCodeModal();
        },


        // End: remove after new contact form wins

        addEventQuestions: function addEventQuestions() {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;
            this.contactFormModel.createEventQuestions().then(
                function success() {
                    this.showAddOptions = false;
                    this.showAddLoader = false;
                    this.closeMenu();
                }.bind(this),
                function error() {
                    this.showAddLoader = false;
                }.bind(this)
            );
        },

        updateQuestion: function updateQuestion(question) {

            this.contactFormModel.updateQuestion(question);
        },

        deleteQuestion: function deleteQuestion(question) {
            this.contactFormModel.deleteQuestion(question);
        },

        closeMenu: function closeMenu() {
            this.showAddOptions = false;

            if (this.$question) {
                this.$question.find('.questionnaire-question').css('margin-bottom', 'inherit');
            }
        },

        toggleFontType: function toggleFontType() {
            this.visibleFontType = !this.visibleFontType;
        },

        collapseFontType: function collapseFontType() {
            this.visibleFontType = false;
        },

        addQuestion: function addQuestion(type) {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;

            this.contactFormModel.createQuestion({question_type: type}).then(
                function success() {
                    this.showAddOptions = false;
                    this.showAddLoader = false;
                    this.closeMenu();

                    this.$timeout(function () {
                        // get last added question
                        var question = this.questions_model[this.questions_model.length - 1];
                        this.initializeNewQuestion(question);
                    }.bind(this));
                }.bind(this),
                function error() {
                    this.showAddLoader = false;
                }.bind(this));
        },

        addSuggestedQuestion: function addSuggestedQuestion(dataKey) {
            if (this.showAddLoader) { // we are at a stage of waiting for another add question request to end
                return;
            }
            this.showAddLoader = true;

            this.contactFormModel.createQuestion({data_key: dataKey}).then(
                function success() {
                    this.showAddOptions = false;
                    this.showAddLoader = false;
                    this.closeMenu();

                    this.$timeout(function () {
                        // get last added question
                        var question = this.questions_model[this.questions_model.length - 1];
                        this.initializeNewQuestion(question, true);
                    }.bind(this));
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.too_many_items, {
                        model: 'contact form',
                        type: 'question'
                    });
                    if (resp.data.error_type === 'TooManyItemsError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    }
                }.bind(this))
                .finally(function final() {
                    this.showAddLoader = false;
                }.bind(this));
        },

        gotContactFormParent: function gotContactFormParent() {
            this.fillAutomationData();
            this.gotContactForm();
        },

        _updateFormStyleCssVars: function _updateFormStyleCssVars(key, value) {

            switch (key) {
                case "background_color":
                    this._updateFieldBackgroundColorCssVars(value);
                    break;
                case "border_color":
                    this._updateFieldBorderColorCssVars(value);
                    break;
                case "font_color":
                    this._updateFontColorCssVars(value);
                    break;
                case "font_family":
                    this._updateFontFamilyCssVars(value);
                    break;
                case "font_size":
                    this._updateFontSizeCssVars(value);
                    break;
                case "font_weight":
                    this._updateFontWeightCssVars(value);
                    break;
                case "font_img":
                case "font_type":
                case "font_url":
                default:
                    break;
            }
        },

        _updateButtonStyleCssVars: function _updateButtonStyleCssVars(key, value) {
            switch (key) {
                case "background_color":
                    this._updateButtonBackgroundColorCssVars(value);
                    break;
                case "font_color":
                    this._updateButtonFontColorCssVars(value);
                    break;
                case "font_family":
                    this._updateButtonFontFamilyCssVars(value);
                    break;
                case "font_size":
                    this._updateButtonFontSizeCssVars(value);
                    break;
                case "font_weight":
                    this._updateButtonFontWeightCssVars(value);
                    break;
                case "border_color":
                case "font_img":
                case "font_type":
                case "font_url":
                default:
                    break;
            }
        },


        _updateButtonBackgroundColorCssVars: function _updateButtonBackgroundColorCssVars(value) {

            this.CssVariablesService.setStyle('dynamicContentButtonBackgroundColor', value);
        },

        _updateButtonFontColorCssVars: function _updateButtonFontColorCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentButtonTextColor', value);
        },

        _updateButtonFontFamilyCssVars: function _updateButtonFontFamilyCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentButtonFontFamily', value);
        },

        _updateButtonFontSizeCssVars: function _updateButtonFontSizeCssVars(value) {
            var currFontSizeItem = this._.first(this._.filter(this.FontsService.fontSizes, function(fontSizeItem){ return fontSizeItem.font_size === value; }));
            if (currFontSizeItem) {
                this.CssVariablesService.setStyle('dynamicContentButtonFontSize', currFontSizeItem.fontSize);
                this.$scope.$broadcast("triggerUpdateEvent");
            }
        },

        _updateButtonFontWeightCssVars: function _updateButtonFontWeightCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentButtonFontWeight', value);
        },

        _updateAllCssVars: function _updateAllCssVars() {
            var formStyleContainer = this.contactFormModel.getStyleObj('form_component_style');
            var buttonStyleContainer = this.contactFormModel.getStyleObj('button_component_style');
            var globalStyleContainer = this.contactFormModel.getStyleObj('global_style');

            // form_container_style
            for (var styleProp in formStyleContainer) {
                this._updateFormStyleCssVars(styleProp, formStyleContainer[styleProp]);
            }

            // global_style
            var backgroundColor = globalStyleContainer.background_color || '#ffffff';
            this._updateFormBackgroundCssVars(backgroundColor);


            // button_component_style
            if (buttonStyleContainer) {
                for (var styleProp in buttonStyleContainer) {
                    this._updateButtonStyleCssVars(styleProp, buttonStyleContainer[styleProp]);
                }
            }

        },


        _getColorAnalyticsEventProps: function _getColorAnalyticsEventProps(colorKey, styleType) {
            var colorType;
            switch (styleType) {
                case 'form_component_style':
                    colorType = "questions";
                    break;
                case 'button_component_style':
                    colorType = "buttons";
                    break;
                case 'global_style':
                    colorType = "global";
                    break;
                default:
                    console.error("You did not pass picker's color styleType")
                    break;
            }
            return {color_for: colorKey, type: colorType};
        },

        trackPickerOpened: function trackPickerOpened(colorKey, styleContainer) {
            this.AnalyticsService.track(this, 'open color picker', this._getColorAnalyticsEventProps(colorKey, styleContainer));
        },

        trackColorPickerUpdate: function trackColorPickerUpdate(colorKey, styleContainer) {
            this.AnalyticsService.track(this, 'color choose', this._getColorAnalyticsEventProps(colorKey, styleContainer));
        },

        _updateFontColorCssVars: function _updateFontColorCssVars(colorValue) {
            var rgbaColor = this.UIUtils.hexToRgbA(colorValue);
            this.CssVariablesService.setStyle('dynamicContentTextColor', rgbaColor);

            var rgbaPlaceholderColor = this._getPlaceholderColor(rgbaColor);
            this.CssVariablesService.setStyle('dynamicContentPlaceholderColor', rgbaPlaceholderColor);
        },

        _updateFieldBorderColorCssVars: function _updateFieldBorderColorCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentInputBorderColor', value);
        },

        _shouldShowPaddedInputStyle: function _shouldShowPaddedInputStyle(formBgColor, fieldBgColor) {
            if (formBgColor === fieldBgColor) {
                return false;
            }
            if (fieldBgColor.startsWith('rgba')) {
                var alphaValue = this.UIUtils.getAlphaFromRgbaValue(fieldBgColor);
                return (alphaValue > 0);
            }
            return true;
        },

        _updateFieldBorderStyleIfNeeded: function _updateFieldBorderStyleIfNeeded() {
            var formBgColor = this.CssVariablesService.getStyle('dynamicContentFormBackgroundColor');
            var fieldBgColor = this.CssVariablesService.getStyle('dynamicContentInputBackgroundColor');

            this.isPaddedInputStyle = this._shouldShowPaddedInputStyle(formBgColor, fieldBgColor);
        },

        _updateFieldBackgroundColorCssVars: function _updateFieldBackgroundColorCssVars(fieldBackgroundColor) {
            this.CssVariablesService.setStyle('dynamicContentInputBackgroundColor', fieldBackgroundColor);
            this._updateFieldBorderStyleIfNeeded();
        },

        _updateFontSizeCssVars: function _updateFontSizeCssVars(value) {
            var currFontSizeItem = this._.first(this._.filter(this.FontsService.fontSizes, function(fontSizeItem){ return fontSizeItem.font_size === value; }));
            if (currFontSizeItem) {
                this.CssVariablesService.setStyle('dynamicContentFontSize', currFontSizeItem.fontSize);
                this.$scope.$broadcast("triggerUpdateEvent");
            }
        },

        _updateFontWeightCssVars: function _updateFontWeightCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentFontWeight', value);
        },

        _updateFormBackgroundCssVars: function _updateFormBackgroundCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentFormBackgroundColor', value);
            this._updateFieldBorderStyleIfNeeded();
        },

        _updateFontFamilyCssVars: function _updateFontFamilyCssVars(value) {
            this.CssVariablesService.setStyle('dynamicContentFontFamily', value);
        },

        updateColor: function updateColor(styleContainer, colorKey, colorValue) {

            // update DB
            var styleObj = this.contactFormModel.getStyleObj(styleContainer);
            styleObj[colorKey] = colorValue;


            this.contactFormModel.updateStyle(styleContainer).then(function() {
                // track change
                this.trackColorPickerUpdate(colorKey, styleContainer);
            }.bind(this));


        },

        gotContactForm: function gotContactForm() {
            this.fillViewModelAccordingToModel();
        },

        setContactTabs: function setContactTabs(tab) {
            this.activeTab = tab.tabNumber;
        },

        isSetContactTabs: function isSetContactTabs(tab) {
            return this.activeTab === tab.tabNumber;
        },

        setHeaderState: function setHeaderState() {
            if (this.contactFormModel.last_inquiry_timestamp) {
                this.headerState = 'active';
                this.activeHeaderDateText = this.$translate.instant('CONTACT_FORM.LABELS._ACTIVE_TEXT', {date: this.moment(this.contactFormModel.last_inquiry_timestamp).format('ll')});
            }
            else if (this.contactFormModel.last_publish_timestamp) {
                this.headerState = 'installed';
            }
            else {
                if (!this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.contactFormNotInstalledBanner)) {
                    this.headerState = 'not_installed';
                }
            }
        },
        openEmbedCodeModal: function openEmbedCodeModal() {
            this.ModalService.openEmbededContactFormModal(this.contactFormModel, this.contactFormParentModel);
        },

        showSuggestedField: function showSuggestedField(questions, dataKey) {
            var loadedQuestion = this._.findWhere(questions, {data_key: dataKey});
            var shouldShow = false;
            if (!loadedQuestion) {
                    shouldShow = true;
            }
            return shouldShow;
        },

        previewContactForm: function previewContactForm() {
            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                this.gettingPreviewCode = true;
                this.ContactFormService.openContactFormPreview(this.contactFormModel).then(function () {
                    this.gettingPreviewCode = false
                }.bind(this));
            }
        },

        toggleAutomation: function toggleAutomation() {
            this.AutomationService.toggleAutomation(this.company, this.companyLeadReplyAction);
        },

        changeAutomationAttachment: function changeAutomationAttachment(action, attachment) {
            if (action.questionnaireTemplate) {
                this.AutomationService.changeAutomationQuestionnaire(this.company, action, attachment);
            } else if (action.brochureTemplate) {
                this.AutomationService.changeAutomationBrochure(this.company, action, attachment);
            }
        },

        changeAutomationTemplate: function changeAutomationTemplate(action, template) {
            this.AutomationService.changeAutomationTemplate(this.company, action, template);
        },

        changeAutomationEventType: function changeAutomationEventType(action, eventType) {
            var key = angular.isDefined(eventType) ? eventType._id : null;
            this.AutomationService.changeAutomationEventType(this.company, action, key);
        },

        chooseAttachment: function chooseAttachment(attachment) {
            var brochureAttachment = this.brochureAttachment;
            var questionnaireAttachment = this.questionnaireAttachment;

            if ((attachment === 'brochure' && brochureAttachment) || (attachment === 'questionnaire' && questionnaireAttachment)) {
                this.AutomationService.clearAutomationAttachment(this.company, this.companyLeadReplyAction);
            } else {

                if (attachment === 'brochure') {
                    this.AutomationService.changeAutomationBrochure(this.company, this.companyLeadReplyAction, this.companyLeadReplyAction.defaultBrochureTemplate);

                } else if (attachment === 'questionnaire') {
                    this.AutomationService.changeAutomationQuestionnaire(this.company, this.companyLeadReplyAction, this.companyLeadReplyAction.defaultQuestionnaireTemplate);
                }

            }
        },

        shouldSelectInquiryRecipient: function shouldSelectInquiryRecipient() {
            var teamMembers = this.company.getOtherCompanyMembers();
            return this.currentUser.hasCompanyModeratorPermissions() &&
                !!teamMembers && teamMembers.length > 0;
        },

        shouldAllowEditOptionalAnswers: function shouldAllowEditOptionalAnswers(question) {
            if (question.data_key.startsWith('custom_') || question.data_key === "event_services_manual") {
                return true;
            } else {
                return false;
            }
        },

        setUserAsRecipient: function setUserAsRecipient(user) {
            var self = this;
            if (self.selectedUser !== user) {
                self.selectedUser = user;

                var trackingData = {
                    user_id: user._id,
                    company_id: self.company._id,
                    contact_form_id: self.contactFormModel._id,
                    version: self.getContactFormType()
                };

                self.showSaveRecipientLoader = true;
                self.contactFormModel.setUserAsInquiryRecipient(user._id)
                    .then(function success(resp) {
                        self.AnalyticsService.track(self, 'user succesfully set as recipient', trackingData);
                        self.showSaveRecipientLoader = false;
                    })
                    .catch(function fail(resp) {
                        self.AnalyticsService.track(self, 'user failed to be set as recipient', trackingData);
                        self.showSaveRecipientLoader = false;
                        var errorMsg = 'Error saving user as recipient. If this error persists please contact us';
                        if (resp && resp.data && resp.data.error_message) {
                            errorMsg = resp.data.error_message;
                        }
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error,
                            errorMsg);
                    });
            }
        },

        setRecipient: function setRecipient() {
            var self = this;
            if (self.contactFormModel.vendor_id_for_inquiries !== 'undefined' && self.contactFormModel.vendor_id_for_inquiries !== null) {
                if (self.contactFormModel.vendor_id_for_inquiries === self.currentUser._id) {
                    self.selectedUser = self.currentUser;
                }
                else {
                    self.otherTeamMembers.forEach(function (user) {
                        if (user._id === self.contactFormModel.vendor_id_for_inquiries) {
                            self.selectedUser = user;
                        }
                    });
                }
            }
        },

        onAddNewTemplateClick: function onAddNewTemplateClick(templateViewModel) {
            if (this.contactFormModel.isGiftCard()) {
                this.ContactFormService.openAddGiftCardModal();
            } else {
                this.TemplatesViewService.addNewTemplate(templateViewModel);
                if (this.isMobile) {
                    this.appState.isContactFormAsideMenuOpen = false;
                }
            }
        },

        onTemplateSelect: function onTemplateSelect(template, templateViewModel) {
            this.TemplatesViewService.updateCurrentTemplate(template, templateViewModel);
            if (this.isMobile) {
                this.appState.isContactFormAsideMenuOpen = false;
            }
        },

        openFacebookLeadsIntroModal: function openFacebookLeadsIntroModal() {

            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.open_facebook_leads_modal);
            var paramsToPassToModel = {};
            this.FacebookService.hasPermissions(this.FacebookService.permissions_types.lead_ads).then(function success(hasPermissions) {
                if (hasPermissions ) {
                    this.$state.go(this.AppStates.root_core_navigation_facebook_leads);
                }else{
                    this.ModalService.openFacebookLeadsIntroModal(paramsToPassToModel)
                        .then(function success(result) {
                            if (result === 'success') {
                                this.$state.go(this.AppStates.root_core_navigation_facebook_leads);
                            }
                        }.bind(this))
                        .finally(function final() {
                        }.bind(this));
                }
            }.bind(this));
        },

        isLeadAdsActive: function isLeadAdsActive(currUser){
            return currUser.isCompanyOwner();
        },

        openTradeShowTab: function openTradeShowTab() {
            if (this.tradeShowUrl && this.contactFormModel.last_publish_timestamp) {
                this.$window.open(this.tradeShowUrl, '_blank');
            } else {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'COMPANY_SETTING.WIDGET._NEED_TO_PUBLISH_TRADE_SHOW_');
            }
        },

        // Sidebar start
        onAsideMenuItemClick: function handleAsideMenuItem(item) {
            this.appState.isContactFormAsideMenuOpen = true;
            this.ContactFormService.pushItemToSideBarStack(item);
        },

        isShowSideBarTitle: function isShowSideBarTitle() {
            return this.ContactFormService.hasItemsOnSideBarStack()
        },

        onBreadcrumbItemClick: function onBreadcrumbItemClick(index) {
            this.ContactFormService.removeSideBarStackItemsAfterIndex(index);
        },

        getCurrMenuItem : function getCurrMenuItem() {
            return this.ContactFormService.getCurrentSideBarStackItem();
        },

        onCollapseAsideMenu: function onCollapseAsideMenu() {
          this.$timeout(function() {
              this.ContactFormService.resetSideBarStack();
          }.bind(this), 200);
        },

        showOnboarding: function showOnboarding() {
            this.isShowOnboadring = true;
        },

        closeOnboarding: function closeOnboarding() {
            this.UiPersistenceService.setUiPersistenceInnerKey(this.UiPersistenceService.keys.contactFormDynamicOnboarding, 'seenDynamicCFIntro', true);
            this.isShowOnboadring = false;
            this.ContactFormService.resetSideBarStack();
            this.$timeout(function() {
                this.isShowOnboardingSidebar = false;
            }.bind(this), 500)

        },

        showOnboardingSidebar: function showOnboardingSidebar(menuItemType) {
            this.isShowOnboardingSidebar = true;
            this.appState.isContactFormAsideMenuOpen = true;
            switch (menuItemType) {
                case 'fonts':
                    // design fonts
                    this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.design));
                    this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.fonts));
                    break;
                case 'colors':
                    // design colors
                    this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.design));
                    this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.colors));
                    break;
                case 'settings':
                    // settings
                    this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(this.Enums.contactFormSideBarMenuKeys.settings));
                    break;
                default:
                    this.isShowOnboardingSidebar = false;
                    this.appState.isContactFormAsideMenuOpen = false;
                    break;
            }
        },

        showContactFormSidebar: function showContactFormSidebar(menuItemType, element) {
            this.appState.isContactFormAsideMenuOpen = true;
            this.ContactFormService.resetSideBarStack();
            this.ContactFormService.pushItemToSideBarStack(this.ContactFormService.getSideBarItemByKey(menuItemType));

            if (element === 'assignProjectType') {
                //TODO highlight element
            }
        },

        // Sidebar end

        showDynamicContactFormTeaser: function showDynamicContactFormTeaser() {
            this.ModalService.openVideoCarouselModal('dynamic_contact_form_teaser')
            .then(function(responseFromModal) {
                this.UiPersistenceService.setUiPersistenceInnerKey(this.UiPersistenceService.keys.contactFormDynamicOnboarding, 'seenDynamicCFTeaser', true);

                if (responseFromModal && responseFromModal.confirm) {
                    this.migrateToDynamicContactForm();
                }

            }.bind(this))
            .catch(function () {
                this.AnalyticsService.trackClick(this, 'old cf new intro dismiss');
            }.bind(this));
        },

        migrateToDynamicContactForm: function migrateToDynamicContactForm() {
            this.TemplatesManager.migrateCompanyContactFormsToDynamic(this.company);
        },

		analyticsProperties: function analyticsProperties() {
        	return {
        		form_type: this.isGiftCard ? 'gift card' : 'contact form'
			};
		}
    });

    return {
        scope: {
            contactFormModel: '=contactFormModel',
            contactFormParentModel: '=contactFormParentModel',
            isOwnerMode: '=isOwnerMode',
            isPreviewMode: '=isPreviewMode',
            isEditableMode: '=isEditableMode',
            isViewOnlyMode: '=isViewOnlyMode'
        },
        templateUrl: 'angular/app/modules/core/features/contact_form/contact_form_directive_template.html',
        controller: ContactFormDirectiveController,
        controllerAs: 'contactFormVm',
        bindToController: true,
        link: function (scope, element) {
            scope.contactFormVm.setDOMElement(element);
        }
    };
};
