Directives.FeedLeadConflictDirective = function FeedLeadConflictDirective() {

    // @ngInject
    function FeedLeadConflictDirectiveControllerFunc($scope, $injector, $translate, AnalyticsService,
                                                     EventsManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedLeadConflictDirectiveController';

        this.$translate = $translate;

        this.AnalyticsService = AnalyticsService;
        this.analyticsSource = 'feed conflict warning banner';
        this.AnalyticsService.trackPageView(this, 'event date conflict', {source: this.analyticsSource});
        this.EventsManager = EventsManager;
        this.eventsCache = {};
        this.chopListTo = 50; // Show only this number of projects in the conflicts list
    }

    var FeedLeadConflictDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedLeadConflictDirectiveControllerFunc,

        hidePvlAction: function hidePvlAction() {
            this.EventsManager.setPvlConflictView(this.eventModel, true);
        },

        getEventTitle: function getEventTitle(event_id) {
            if (!this.eventsCache[event_id]) {
                this.eventsCache[event_id] = this.EventsManager.getEvent(event_id);
            }
          return this.eventsCache[event_id].event_name;
        },

        getWarningMessage: function getWarningMessage() {
            var numOfConflictingProjects = this._getNumOfConflictingProjects();
            var message = '';
            if(numOfConflictingProjects === 1) {
                message = this.$translate.instant('WORKSPACE.FEED.LEAD_CONFLICT._ONE_');
            }
            else if(numOfConflictingProjects >= 2) {
                message = this.$translate.instant('WORKSPACE.FEED.LEAD_CONFLICT._MULTIPLE_', {numOfConflictingProjects: numOfConflictingProjects});
            }
            return message;
        },

        getAndMoreMessage: function getAndMoreMessage(type) {
            var projects = [];
            if(type === 'TENTATIVE') {
                projects = this.conflicts.lead_conflicting_event_ids;
            } else {
                projects = this.conflicts.booked_conflicting_event_ids;
            }

            var numberOfChoppedProjects = (projects.length || 0) - this.chopListTo;
            return this.$translate.instant(
                'WORKSPACE.FEED.LEAD_CONFLICT._AND_MORE_',
                { numberOfChoppedProjects: numberOfChoppedProjects, type: type });
        },

        _getNumOfConflictingProjects: function _getNumOfConflictingProjects() {
            var tentativeProjects = this.conflicts.lead_conflicting_event_ids;
            var bookedProjects = this.conflicts.booked_conflicting_event_ids;
            var numOfTentativeProjects = tentativeProjects && tentativeProjects.length > 0 ? tentativeProjects.length : 0;
            var numOfBookedProjects = bookedProjects && bookedProjects.length > 0 ? bookedProjects.length : 0;
            return numOfTentativeProjects + numOfBookedProjects;
        }
    });

    return {
        scope: {
            eventModel: '=eventModel',
            createPvlFunc: '&createPvlFunc',
            _hasConflict: '=hasConflict',
            conflicts: '=conflicts'
        },
        templateUrl: 'angular/app/modules/core/features/feed/viewer/feed_lead_conflict_directive_template.html',
        controller: FeedLeadConflictDirectiveController,
        controllerAs: 'feedLeadConflictVm',
        bindToController: true
    };
};