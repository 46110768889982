
Directives.LocationMapDirective = function LocationMapDirective() {
    // @ngInject
    function LocationMapDirectiveControllerFunc($scope, $element, google, $injector, AnalyticsService) {

        this.AnalyticsService = AnalyticsService;
        var self = this;

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LocationMapDirectiveController';

        this.codeAddress = function codeAddress(address) {
            google.then(function(googleSdk) {
                address = address || 'San Francisco';
                var geocoder = new googleSdk.maps.Geocoder();

                function loadNewMap(results) {
                  var mapCustomization = self.mapStyles;

                  var myOptions = {
                    zoom: 12,
                    center: results[0].geometry.location,
                    mapTypeId: googleSdk.maps.MapTypeId.ROADMAP,
                    streetViewControl: false,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    draggable: false,
                    zoomControl: false,
                    styles: [
                      {
                        "elementType": "labels",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "administrative.neighborhood",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [
                          {
                            "color": "#efefef"
                          }
                        ]
                      },
                      {
                        "featureType": "landscape.natural",
                        "elementType": "geometry",
                        "stylers": [
                          {
                            "color": "#efefef"
                          }
                        ]
                      },
                      {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "poi.business",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                          {
                            "color": "#c8e5b0"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road.arterial",
                        "elementType": "labels",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                          {
                            "color": "#dadada"
                          }
                        ]
                      },
                      {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                          {
                            "color": "#ede8a1"
                          },
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road.highway",
                        "elementType": "labels",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road.local",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "transit",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                          {
                            "color": "#a9d0e5"
                          }
                        ]
                      }
                    ]
                  };

                  var mapElement = $element.find('#hbMap').get()[0];
                  var map = new googleSdk.maps.Map(mapElement, myOptions);

                  var marker = new googleSdk.maps.Marker({
                    map: map,
                    position: results[0].geometry.location
                  });

                  //hide marker
                  marker.setVisible(false);

                }

                self.AnalyticsService.track(this, this.AnalyticsService.analytics_events.google_geocoding_opportunity);
                geocoder.geocode({
                    'address': address
                }, function(results, status) {
                    if (status == googleSdk.maps.GeocoderStatus.OK) {
                      loadNewMap(results);
                    }
                });
            }.bind(this));

        }.bind(this);


        $scope.$watch('locationMapVm.location', function (newVal){
                self.codeAddress(self.location);
        });

    }

    var LocationMapDirectiveController = Class(Controllers.BaseController, {
        constructor: LocationMapDirectiveControllerFunc
    });


    return {
        scope: {
            location: '=location'
        },
        templateUrl: 'angular/app/modules/common/ui_components/location_map/location_map_directive_template.html',
        controller: LocationMapDirectiveController,
        controllerAs: 'locationMapVm',
        bindToController: true
    };
};
