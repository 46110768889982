(function () {
    "use strict";

    Controllers.UploadingFilesController = class UploadingFilesController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $, $modalInstance, ModalService, isMoreThanOneFile) {
            super($scope, $injector);
            this.__objectType = 'UploadingFilesController';

            this.$ = $;
            this.ModalService = ModalService;
            this.$modalInstance = $modalInstance;
            this.isMoreThanOneFile = isMoreThanOneFile;
        }

        leave() {
            this.$modalInstance.close(false);
        }

        stay() {
            this.$modalInstance.close(true);
        }
    };
}());
