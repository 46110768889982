(function () {
    "use strict";

    // @ngInject
    function PreviewContactFormControllerCtor($scope, $injector, $, $sce, $modalInstance, $window, $http, AnalyticsService, ContactFormService, TemplatesManager, contactForm, company) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'PreviewContactFormController';

        this.$scope = $scope;
        this.TemplatesManager = TemplatesManager;
        this.$http = $http;
        this.$ = $;
        this.$window = $window;
        this.company = company;
        this.modalInstance = $modalInstance;
        this.AnalyticsService = AnalyticsService;
        this.$sce = $sce;
        this.contactForm = contactForm;
        this.ContactFormService = ContactFormService;
        this.isGiftCard = this.contactForm.isGiftCard()
        this.proTipText = 'You\'re now previewing this as a client. Feel free to play around—nothing will be sent!';
        this.contactFormJson = {};

        // add post message event listener
        var postMessageHandler = function(event) {
            $scope.$applyAsync(function () {
                this.onPostMessage(event);
            }.bind(this));
        }.bind(this);
        $window.addEventListener('message', postMessageHandler);

        // load contact form preview
        this.isLoading = true;
        this.TemplatesManager.getContactFormPreview(company, contactForm).then(function gotContactForm(response) {
            this.onContactFormPreviewSuccess(response);
            this.payData = response.data.pay_data;
        }.bind(this));

        $scope.$on('$destroy', function () {
            $window.removeEventListener('message', postMessageHandler);
        });

        if (this.contactForm.isGiftCard() && this.contactForm.is_instant_booking_enabled) {
            this.instantPayUrl = $sce.trustAsResourceUrl(
                this.ContactFormService.getPublicUrl(
                    contactForm, 
                    {path: 'instant_pay_preview'}
                )
            );
        }
    }

    Controllers.PreviewContactFormController = Class(Controllers.BaseController, {

        constructor: PreviewContactFormControllerCtor,

        onContactFormPreviewSuccess: function onContactFormPreviewSuccess(response) {
            this.contactFormJson = JSON.parse(response.data.contact_form);
            this.externalContactFormSnippet = this.$sce.trustAsHtml(response.data.embed_data.iframe_code);
            if (this.$window['_HB_']){
                this.$window['_HB_'].runSnippet();
            }
        },

        onPostMessage: function onPostMessage(event) {
            // if this is our event
            if (event.data.hbEvent && event.data.hbEvent.type === 'hb_contact_form_mounted') {
                this.loadContactForm();
            }

            if (event.data.hbEvent && event.data.hbEvent.type === 'hb_contact_form_submitted_sync_preview') {
                this.showInstantPayPreview = true;
            }

            if (event.data.hbEvent && event.data.hbEvent.type === 'hb_instant_pay_mounted') {
                 if (this.contactForm.isGiftCard() && this.contactForm.is_instant_booking_enabled) {
                    this.initInstantPayPreviewData();
                 }
            }
        },

        reset: function reset(){
            if (this.contactForm.isGiftCard()) {
                this.AnalyticsService.trackClick(this, "reset gift card preview mode");
                this.showInstantPayPreview = false;
                this.sendGiftCardResetMessage();
            } else {
                this.AnalyticsService.trackClick(this, "reset contact form preview mode");
            }

            this.isLoading = true;
            this.isReset = true;
            this.loadContactForm();
        },

        loadContactForm: function loadContactForm(){
            const hbNamespace = this.$window['_HB_'];
            hbNamespace.companyBrandingData = {
                company_id: this.company._id,
                brand_color: this.company.brand_color,
                text_color: this.company.text_color,
                title: this.company.company_name,
                logo_url: this.company.logo_image && this.company.logo_image.url,
                icon_url: this.company.icon_image && this.company.icon_image.url,
                website_url: this.company.website_url
            };

            // find iframe element
            hbNamespace.renderContactForm(this.contactFormJson, {
                _id: this.company._id,
                brand_color: this.company.brand_color,
                text_color: this.company.text_color,
                currency: this.company.currency,
                owner: {
                    _id: this.company.owner._id,
                    email: this.company.owner.email
                },
                title: this.company.company_name,
                logo_url: this.company.logo_image && this.company.logo_image.url,
                icon_url: this.company.icon_image && this.company.icon_image.url,
                website_url: this.company.website_url,
                is_powered_by_hb: this.company.is_powered_by_hb
            },
            {},
            {
                is_thank_you_preview: this.ContactFormService.thankYouPreview,
                is_reset_preview: this.isReset
            });

            this.isLoading = false;
            this.isReset = false;
            this.ContactFormService.thankYouPreview = false;
        },

        close: function close() {
            if (this.contactForm.isGiftCard()) {
                this.AnalyticsService.trackClick(this, "exit gift card preview mode");
            } else {
                this.AnalyticsService.trackClick(this, "exit contact form preview mode");
            }

            this.modalInstance.close();
        },

        initInstantPayPreviewData: function initInstantPayPreviewData() {
            if (!this.payData) {
                return setTimeout(this.initInstantPayPreviewData.bind(this), 100);
            }

            this.getGiftCardIframe().contentWindow.postMessage({
                hbEvent: {
                    type: 'hb_checkout_preview_data_received',
                    data: {
                       clientPortalConfig: this.company.client_portal_configuration,
                       gon: window.gon,
                       userConfig: {
                       },
                       paymentData: {
                           vendor: this.payData.vendor,
                           style_container: this.payData.style_container,
                           // TODO: take client data from contact form?
                           client: this.payData.client,
                           proposal: {
                               amount: this.payData.final_price_after_discount, // TODO: should be the same as final_price_after_discount in instant_pay_controller#load action
                               name: this.contactForm.title
                           }
                       }
                    }
                }
            }, '*')
        },
        
        getPublicUrl: function getPublicUrl(){
            return this.ContactFormService.getPublicUrl(this.contactForm);
        },

        getGiftCardIframe: function getGiftCardIframe() {
            return this.$('#cf-preview-instant-pay-iframe')[0];
        },

        sendGiftCardResetMessage: function sendGiftCardResetMessage() {
            this.getGiftCardIframe().contentWindow.postMessage({
                hbEvent: {
                    type: 'hb_contact_form_preview_reset'
                }
            }, '*');
        }

    });
}());
