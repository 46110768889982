(function () {
    'use strict';

    Controllers.ConnectNylasCalendarController = class ConnectNylasCalendarController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, $q, UsersManager, UserService, AnalyticsService, PopupMessageService, AppStates, options, ModalService) {
            super($scope, $injector);
            this.__objectType = 'ConnectNylasCalendarController';

            AnalyticsService.trackPageView(this, 'CalendarSettingsView');

            this.$modalInstance = $modalInstance;
            this.$q = $q;
            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this.PopupMessageService = PopupMessageService;
            this.AppStates = AppStates;
            this.ModalService = ModalService;

            this.user = this.UsersManager.getCurrUser();
            this.options = options;

            this.init();
        }

        init() {

            this.loading = true;
            this.isConnected = this.options.isConnected;

            if(!this.isConnected) {
                this.loading = false;
                return;
            }

            // fetch the calendar provider
            let calendarProviderPromise = this.UsersManager.getCalendarProvider(this.user);

            // fetch the imported calendars list
            let calendarListPromise = this.UsersManager.getIntegratedCalendarsList();

            // on both requests resolved
            this.$q.all([calendarProviderPromise, calendarListPromise]).then(function(responses) {

                let provider = responses[0].data.calendar_provider;

                // save the main google account email
                this.mainEmailAddress = provider.main_email;

                // set the toggles values
                this.syncHbToCalendar = provider.shares_enabled;
                this.syncCalendarToHb = provider.import_enabled;

                // set emails list
                this.syncedEmailsList = [];
                this.importedCalendars = responses[1].data;


                switch (provider.provider) {
                    case 'gmail':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-google-icon.png";
                        break;
                    case 'yahoo':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-yahoo-icon.png";
                        break;
                    case 'ews':
                    case 'eas':
                    case 'microsoft':
                    case 'outlook':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-outlook-icon.png";
                        break;
                    case 'icloud':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-icloud-icon.png";
                        break;
                    case 'aol':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-aol-icon.png";
                        break;
                    default:
                        this.activeProviderLogoImg = null;
                }

                // find the primary calendar and make that one automatically selected
                this.importedCalendars.forEach(function iterator(calendar) {
                    if(calendar.is_primary) {
                        calendar.imported = true;
                    }
                });

                // set the main calendar first
                this.importedCalendars.sort((calA, calB) => {
                    if(calA.imported !== calB.imported) {
                        return calA.imported ? -1 : 1;
                    }
                    return 0;
                });

                // save provider
                this.googleCalendarProvider = provider;
                this.loading = false;

            }.bind(this))
                .catch(function(error) {

                    // try to figure out which error occurred
                    let errorType = error.data.error_type;

                    // token revoked error
                    if(errorType === 'HBCalendarImporterTokenRevokedError') {

                        // TODO: Handle token revoked error
                    }

                    this.requireToReconnect = true;
                    this.isConnected = false;
                    this.loading = false;

                }.bind(this));
        }

        close () {
            let data = this._getData();
            this.$modalInstance.close(data);
        }

        dismiss () {
            let data = this._getData();
            if (this.options.commingFromNylasAuth) {
                this.disconnect();
            } else {
                this.$modalInstance.dismiss(data);
            }
        }

        _getData() {
            if(this.isCalendarDisconnected) {
                return { isCalendarDisconnected: this.isCalendarDisconnected};
            } else if (this.isConnected) {
                return {
                    showHbEventsOnCalendar: this.syncHbToCalendar,
                    sharedWithEmailsCollection: this.syncedEmailsList,
                    showCalendarEventsOnHb: this.syncCalendarToHb,
                    listOfCalendersToImport: this.importedCalendars.filter((cal) => cal.imported === true)
                };
            } else {
                return {};
            }
        }

        save () {
            this.close();
        }

        connect () {
            this.close();
            this.ModalService.openSelectEmailProviderModal('calendar')
                .then(function (res) {
                    if (res.isGoogle) {
                        this.UserService.connectToAGoogleAccount("", 'calendar');
                    }
                }.bind(this));
        }

        disconnect () {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning,
                'Are you sure you want to disconnect your integrated calendar?').then(function yes() {
                this._disconnect();
            }.bind(this));
        }

        _disconnect () {
            this.loading = true;
            return this.UsersManager.deleteCalendarProvider(this.user).then(function() {
                this.options.isConnected = false;
                this.isConnected = false;
                this.isCalendarDisconnected = true;
            }.bind(this))
                .catch(function() {
                }.bind(this))
                .finally(function () {
                    this.loading = false;
                    this.close();
                }.bind(this));
        }

        reconnect() {
            this._disconnect().then(function() {
                this.connect();
            }.bind(this));
        }
    };

}());