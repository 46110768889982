(function () {
    "use strict";

    // @ngInject
    function AccountSetupControllerCtor($scope, UsersManager, PhotosUploadManager, CompaniesManager, _, moment, Gon,
                                        $injector, AnalyticsService, PopupMessageService, AbTestService, OnboardingService,
                                        $state, AppStates, NavigationService, DeviceService, $log, $translate, $, UIUtils, UiPersistenceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AccountSetupController';

        this._ = _;

        this.PhotosUploadManager = PhotosUploadManager;
        this.CompaniesManager = CompaniesManager;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.OnboardingService  = OnboardingService;
        this.PopupMessageService = PopupMessageService;
        this.$state = $state;
        this.AppStates = AppStates;
        this.NavigationService = NavigationService;
        this.$log = $log;
        this.$translate = $translate;
        this.$ = $;
        this.UIUtils = UIUtils;
        this.UiPersistenceService = UiPersistenceService;

        this.user = UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();
        this.moment = moment;
        this.time_to_upload_materials = Gon.time_to_upload_materials;

        this.isLoading = true;

        this.getTranslationPath = (translationPath) => {
            return translationPath;
        };

    
        this.isMobile = DeviceService.nxSmallBreakpoint();
        const defaultExtensionPattern = '.doc,.png,.jpg,.jpeg,.pdf';
        this.accountSetupSteps = [
            {
                key: 'service',
                name: 'Services',
                title: 'COMPANY_SETTING.SETUP._INTRO_HEADER_1_',
                subtitle: 'COMPANY_SETTING.SETUP._INTRO_TEXT_1_',
                cta: 'COMPANY_SETTING.SETUP._CTA_1_',
                files: [],
                isMaxCapacity: false,
                extensionPattern: defaultExtensionPattern
            },
            {
                key: 'questionnaire',
                name: 'Questionnaires',
                title: 'COMPANY_SETTING.SETUP._INTRO_HEADER_2_',
                subtitle: 'COMPANY_SETTING.SETUP._INTRO_TEXT_2_',
                cta: 'COMPANY_SETTING.SETUP._CTA_2_',
                files: [],
                isMaxCapacity: false,
                extensionPattern: defaultExtensionPattern
            },
            {
                key: 'contract',
                name: 'Contracts',
                title: 'COMPANY_SETTING.SETUP._INTRO_HEADER_3_',
                subtitle: 'COMPANY_SETTING.SETUP._INTRO_TEXT_3_',
                cta: 'COMPANY_SETTING.SETUP._CTA_3_',
                files: [],
                isMaxCapacity: false,
                extensionPattern: defaultExtensionPattern
            },
            {
                key: 'invoice',
                name: 'Invoices',
                title: 'COMPANY_SETTING.SETUP._INTRO_HEADER_4_',
                subtitle: 'COMPANY_SETTING.SETUP._INTRO_TEXT_4_',
                cta: 'COMPANY_SETTING.SETUP._CTA_4_',
                files: [],
                maximumFiles: 1,
                isMaxCapacity: false,
                extensionPattern: '.csv,.xlsx',
                shouldHideWhatCanIUpload: true,
                uploaderTitle: 'COMPANY_SETTING.SETUP._UPLOADER_TITLE_4_',
                uploaderSubtitle: 'COMPANY_SETTING.SETUP._UPLOADER_SUBTITLE_4_',
                bottomText: 'COMPANY_SETTING.SETUP._UPLOADER_FILE_TYPE_4_',
                isUploadDisabled : () => !this.hasDownloadedInvoiceCSV(),
                leftColumn: {
                    subtitle: 'COMPANY_SETTING.SETUP._DOWNLOAD_SUBTITLE_4_',
                    downloadText: 'COMPANY_SETTING.SETUP._DOWNLOAD_TEXT_4_',
                    downloadHandler: this.downloadInvoiceCSV.bind(this)
                },
            },
            {
                key: 'review',
                name: 'Review',
                titleHasFiles: 'COMPANY_SETTING.SETUP._REVIEW_AND_SUBMIT_',
                titleNoFiles: 'COMPANY_SETTING.SETUP._REVIEW_AND_SUBMIT_NO_FILES_',
                subtitleHasFiles: 'COMPANY_SETTING.SETUP._REVIEW_UPLOADS_',
                subtitleNoFiles: 'COMPANY_SETTING.SETUP._NO_FILES_YET_'
            }
        ];

        this.currentStepIndex = 0;

        this.getStepFromIndex = index => this.accountSetupSteps[index];

        this.currentStep = this.getStepFromIndex(this.currentStepIndex);

        this.reviewSteps = this.accountSetupSteps.filter(step => step.files);

        this.maximumFilesPerStep = 10;

        this.isInvoiceStep = this.currentStep.key === 'invoice';

        if (!this.company.wasFullyFetched()) {
            this.loading = true;
            this.company.once('success', function companyFetched() {
                this.loading = false;
            }.bind(this));
        }

        this.files = [];
        this.numberOfFilesUploading = 0;

        this.initSuccessVariant();

        this.AnalyticsService.trackPageView(this, this.AnalyticsService.analytics_events.account_setup_modal, {
            companyId: this.company._id,
            source: this.user.companyHasSubscription() ? 'member' : 'trialer',
            account_setup_status: this.getMaterialsUploadStatus(true),
            isFromSetupPopup: this.$state.params.isFromSetupPopup
        });

        this.isShowWelcomePage = true;
        this.welcomeSteps = [{
                title: 'COMPANY_SETTING.SETUP._WELCOME_STEP_1_TITLE_',
                subtitle: 'COMPANY_SETTING.SETUP._WELCOME_STEP_1_SUBTITLE_',
            }, {
                title: 'COMPANY_SETTING.SETUP._WELCOME_STEP_2_TITLE_',
                subtitle: 'COMPANY_SETTING.SETUP._WELCOME_STEP_2_SUBTITLE_',
            }, {
                title: 'COMPANY_SETTING.SETUP._WELCOME_STEP_3_TITLE_',
                subtitle: 'COMPANY_SETTING.SETUP._WELCOME_STEP_3_SUBTITLE_',
            }
        ];

        this.onUploadMore = () => {
            this.goToStep(0);
            this.isShowWelcomePage = false;
            this.successVariant = null;
            this.AnalyticsService.trackAction(
                this,
                this.AnalyticsService.analytics_events.account_setup_upload_more
            );
        };

        this.isLoading = false;

    }

    Controllers.AccountSetupController = Class(Controllers.BaseController, {

        constructor: AccountSetupControllerCtor,

        deleteUploadedFile: function deleteUploadedFile(step, fileViewModel, $event) {
            $event.preventDefault();  // Prevents link from activating
            $event.stopPropagation(); // Prevents parent file-uploader from opening file explorer

            fileViewModel.beingDeleted = true;

            if (fileViewModel.uploading) {
                fileViewModel.file.uploadAPI.abort();
            }

            //delete it from the array
            const isNotDeletedFile = file => file.url !== fileViewModel.url;
            step.files = step.files.filter(isNotDeletedFile);

            if (step.files.length < (this.getMaximumFilesForCurrStep())) {
                step.isMaxCapacity = false;
                this.shouldShowContinuePopover = false;
            }

            return false;

        },

        uploadedFileCount: function uploadedFileCount() {
            // Returns the total number of uploaded files
            return (
              this.accountSetupSteps
                .filter(step => step.files) // Only steps with files                
                .map(step => step.files.length) // Get the length (fileCount) of each files array
                .reduce((memo, filesCount) => memo + filesCount, 0) // Sum fileCount over each step
            ); 
        },

        computePopoverText: function computePopoverText() {
            return this.$translate.instant('COMPANY_SETTING.SETUP._POPUP_TEXT_');
        },

        allowAccountSetup: function allowAccountSetup() {
            return this.company && 
                   this.company.offer_account_setup !== false &&
                   this.user.isCompanyOwner() && 
                   this.user.companyHasSubscription() &&
                   !this.successVariant &&
                   !this.loading;
        },

        getMaterialsUploadStatus: function getMaterialsUploadStatus(getNoneVariant) {
            const hasPending = this.company.hasPendingMaterialsUpload();
            const hasCompleted = this.company.hasCompletedMaterialsUpload();
            if (hasPending && hasCompleted) {
                return 'partial';
            } else if (hasPending) {
                return 'pending';
            } else if (hasCompleted) {
                return 'completed';
            } else if (getNoneVariant) {
                return 'none';
            }
        },

        initSuccessVariant: function initSuccessVariant() {
            if (!this.company.hasUploadedMaterials()) {
                this.isShowWelcomePage = true;
            // When clicked "import them now"
            } else if (this.$state.params.importFiles) {
                if (this.company.canUploadMaterials()) {
                    this.isShowWelcomePage = false;
                } else {
                    this.successVariant = 'success';
                }
                // when clicked "view status"/"upload materials"
            } else {
                this.successVariant = this.getMaterialsUploadStatus();
            }
        },

        onFilesDropped: function onFilesDropped($files) {
            if (this.checkFilesSize($files)) {
                this.upload($files);
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_setup_files_dropped, {companyId: this.company._id});
            }
            else {
                $files = [];
            }
        },

        onFileSelected: function onFileSelected($files) {
            if (this.checkFilesSize($files)) {
                this.upload($files);
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.company_setup_files_selected, {companyId: this.company._id});
            }
            else {
                $files = [];
            }
        },

        checkFilesSize: function checkFilesSize(files) {
            // 15 MB limit
            var removedLargeFiles = false;
            if (files != null && files.length > 0) {
                var fileSizeLimit = 15 * 1024 * 1024;
                var cleanedFiles = files.filter(function (file) {
                    return file.size <= fileSizeLimit;
                });

                if (cleanedFiles.length < files.length) {
                    if (files.length === 1) {
                        this.PopupMessageService.showErrorAlert('UPLOAD_FILE._ERROR_FILE_TOO_LARGE_');
                    }
                    else {
                        this.PopupMessageService.showErrorAlert('UPLOAD_FILE._ERROR_FILES_TOO_LARGE_');
                    }
                    return false;
                }
                return true;
            }

            if (removedLargeFiles) {
                this.PopupMessageService.showErrorAlert('UPLOAD_FILE._ERROR_FILE_TOO_LARGE_');
            }

        },

        isFirstStep: function isFirstStep() {
            return this.currentStepIndex === 0;
        },

        isLastStep: function isLastStep() {
            return this.currentStepIndex === (this.accountSetupSteps.length - 1);
        },

        nextStep: function nextStep() {
            if (this.isLastStep()) {
                this.$log.error("next step was called on last step");
                return;
            }

            this.currentStepIndex++;

            this.currentStep = this.getStepFromIndex(this.currentStepIndex);

            this.isInvoiceStep = this.currentStep.key === 'invoice';

            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.account_setup_next_step, {
                page: this.currentStep.key
            });
        },

        previousStep: function previousStep() {
            let pageKey;
            if (this.isFirstStep()) {
                this.isShowWelcomePage = true;
                pageKey = 'welcome_page';
            } else {
                this.currentStepIndex--;

                this.currentStep = this.getStepFromIndex(this.currentStepIndex);
                pageKey = this.currentStep.key;

                this.isInvoiceStep = pageKey === 'invoice';
            }
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.account_setup_previous_step, {
                page: pageKey
            });
        },

        goToStep: function goToStep(stepIndex) {
            if (stepIndex < 0 || stepIndex >= this.accountSetupSteps.length) {
                this.$log.error("moveToStep called with out of bounds index");
                return;
            }

            this.currentStepIndex = stepIndex;
            this.currentStep = this.getStepFromIndex(this.currentStepIndex);

            this.isInvoiceStep = this.currentStep.key === 'invoice';
        },

        createFileViewModel: function createFileModel(setupAsset) {
            var self = this;
            var fileViewModel = {
                instance: self.company, //the instance of the model containing these files
                type: 'setup_asset',
                dontUpdateServer: true,
                uploading: false,
                uploaded: true,
                beingDeleted: false,
                file: null,
                assetType: this.currentStep.key,
                stepIndex: this.currentStepIndex
            };

            this._.extend(fileViewModel, setupAsset);
            return fileViewModel;
        },

        handleMaximumFileCount: function handleMaximumFileCount() {
            this.PopupMessageService.showAlert(
                this.PopupMessageService.severityTypes.info,
                'COMPANY_SETTING.SETUP._FILE_LIMIT_MODAL_TEXT_', 
                () => {
                    this.shouldShowContinuePopover = true;
                });
            this.currentStep.isMaxCapacity = true;

        },

        upload: function upload(files) {
            var self = this;


            if (files && files.length) {
                files.forEach(function (file) {
                    var fileViewModel = self.createFileViewModel({name: file.name});
                    fileViewModel.uploaded = false;
                    //store on the view model the original file object from the browser
                    fileViewModel.file = file;

                    //enrich the fileViewModel with data that is required to upload it to s3j
                    self.PhotosUploadManager.getUpdatedModelData(fileViewModel);

                    if (self.currentStep.files.length >= self.getMaximumFilesForCurrStep()) {
                        self.handleMaximumFileCount();
                        return;
                    }

                    var promise = self.PhotosUploadManager.uploadPhoto([file], fileViewModel, self);
                    self.numberOfFilesUploading++;
                    fileViewModel.uploading = true;
                    self.files.push(fileViewModel);


                    self.currentStep.files.push(fileViewModel);
                    if (self.currentStep.files.length >= self.getMaximumFilesForCurrStep()) {
                        self.currentStep.isMaxCapacity = true;
                    }

                    promise
                        .then(function success() {
                            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.vendor_uploaded_setup_file_successful, {
                                companyId: self.company._id,
                                key: fileViewModel.key
                            });

                            fileViewModel.url = file.s3Response.url;
                        })
                        .catch(function error(resp) {


                            if (!resp) {
                                //this is a result of a delete of a file during an upload. remove it from the view model since we know the upload stopped
                                //before it was inserted to our company model
                                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.vendor_deleted_file_while_uploading, {
                                    companyId: self.company._id,
                                    key: fileViewModel.key
                                });
                                fileViewModel.uploadFailedInTheMiddle = true;
                                return;
                            } else {
                                self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.SETUP._ERROR_UPLOADING_FILE_');
                                var message = 'Error saving the file: ' + fileViewModel.name;
                                self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.error_unable_to_add_setup_file_to_company_settings, resp);
                                fileViewModel.uploadFailedInTheMiddle = true;
                            }
                        })
                        .finally(function () {
                            if (fileViewModel.uploadFailedInTheMiddle) {
                                var indexToRemove = self._.findIndex(self.currentStep.files, fileViewModel);
                                self.currentStep.files.splice(indexToRemove, 1);
                            }

                            fileViewModel.uploading = false;
                            fileViewModel.uploaded = true;
                            fileViewModel.instance = null;
                            self.numberOfFilesUploading--;
                        });
                });

            }
        },

        setMeUp: function setMeUp() {
            if (this.numberOfFilesUploading !== 0) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'COMPANY_SETTING.SETUP._FILE_WAIT_');
                return;
            }

            if (this.uploadedFileCount() === 0) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info, 'COMPANY_SETTING.SETUP._NO_FILES_' );
                return;
            }

            this.settingUp = true;
            this.CompaniesManager.accountSettupAssetsReady(this.company, this.files).then(
                function success(resp) {
                    this.settingUp = false;
                    this.successVariant = this.company.hasOneMaterialsUploadLeft() ? 'success_one_left' : 'success';
                    this.OnboardingService.onAccountSetupFilesUploaded();
                    this.AbTestService.onAccountSetup();
                }.bind(this),
                function error(resp) {
                    this.settingUp = false;
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.SETUP._FILE_ERROR_');
                }.bind(this)
            );
        },

        doneWelcome: function doneWelcome() {
            this.AnalyticsService.trackClick(this, "start account setup");
            this.isShowWelcomePage = false;

            this.$("html, body").animate({ scrollTop: 0 }, 200);
        },

        close: function close() {
            this.NavigationService.goHistoryBack();
        },

        getMaximumFilesForCurrStep: function getMaximumFilesForCurrStep() {
            return this.currentStep.maximumFiles || this.maximumFilesPerStep;
        },
        
        hasDownloadedInvoiceCSV: function hasDownloadedInvoiceCSV() {
            return this.UiPersistenceService.getUiPersistence(
                       this.UiPersistenceService.keys.hasDownloadedInvoiceCSV,
                       { value: false }
                   ).value;
        },
        
        downloadInvoiceCSV: function downloadInvoiceCSV($event) {
            if (!this.hasDownloadedInvoiceCSV()) {
                this.UiPersistenceService.setUiPersistence(
                    this.UiPersistenceService.keys.hasDownloadedInvoiceCSV,
                    { value: true }
                );
            }
            let url = 'https://s3.amazonaws.com/honeybook_cdn/account_setup/Open_Invoice_Transfer_Template.csv';
            this.UIUtils.downloadFileFromLink($event, url, this.AnalyticsService.analytics_events.account_setup_export_csv);
        }
    });
}());
