Directives.ConfettiDirective = function ConfettiDirective() {

    // @ngInject
    function ConfettiDirectiveControllerFunc($scope, $injector, $timeout, _, ConfettiService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ConfettiDirective';
        this.ConfettiService = ConfettiService;
        this._ = _;
    }

    var ConfettiDirective = Class(Controllers.BaseController, {
        constructor: ConfettiDirectiveControllerFunc,
        hide: function hide() {
            this.ConfettiService.hide();
        }

    });
    return {
        scope: {},
        templateUrl:
            'angular/app/modules/common/ui_components/confetti/hb_confetti_directive_template.html',
        controller: ConfettiDirective,
        controllerAs: 'confettiVm',
        bindToController: true
    };
};