(function () {
    'use strict';

    Directives.HtmlEventListenerDirective = function HtmlEventListenerDirective() {

        // @ngInject
        function HtmlEventListenerDirectiveFunc($scope, $injector, $, $document) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'HtmlEventListenerDirectiveController';

            $document.on(this.eventName + '.htmlEventListener.honeybook', this.eventAction);
            $scope.$on('$destroy', function scopeDestroy() {
                $document.off('.htmlEventListener.honeybook');
            });
        }

        var HtmlEventListenerDirectiveController = Class(Controllers.BaseController, {
            constructor: HtmlEventListenerDirectiveFunc
        });

        return {
            restrict: 'A',
            bindToController: true,
            controller: HtmlEventListenerDirectiveController,
            controllerAs: 'htmlEventListenerVm',
            scope: {
                eventName: '@',
                eventAction: '&'
            }
        };
    };
}());
