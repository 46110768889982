/**
 * The hb-start-tour component
 *
 * This component shows the start tour text
 *
 *
 * parameters:
 * text: string. translated text to be shown.
 * analyticsEvent: event to be fired on click
 * onClick: event to be fired on click
 *
 */


(function () {
    'use strict';

    class HBStartTourController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'HBStartTour';
        }
    }

    Components.HBStartTour = {
        bindings: {
            text: '@',
            analyticsEvent: '@',
            onClick: '&'
        },
        controller: HBStartTourController,
        templateUrl: 'angular/app/modules/common/ui_components/hb-start-tour/hb-start-tour.html'
    };

}());
