
(function () {
    'use strict';

    class ProjectDetailsIconComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'ProjectDetailsLocation';
        }
    }

    Components.ProjectDetailsIcon = {
        bindings: {
            iconClass: '@',
            label: '@',
            value: '<?',
            iconPosition: '@'
        },
        controller: ProjectDetailsIconComponent,
        name: 'hbProjectDetailsIcon',
        templateUrl: 'angular/app/modules/core/features/workspace/project_details/project_details_icon_component_template.html'
    };

}());
