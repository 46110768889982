(function () {
    'use strict';

    // @ngInject
    function ClientFeedbackDashboardCtor($scope, $window, $injector, _, AnalyticsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ClientFeedbackDashboardController';
        this.AnalyticsService = AnalyticsService;

        this.AnalyticsService.trackPageView(this, AnalyticsService.analytics_events.booking_process_dashboard);
    }

    Controllers.ClientFeedbackDashboardController = Class(Controllers.BaseController, {

        constructor: ClientFeedbackDashboardCtor,

    });
}());
