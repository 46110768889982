
(function () {
    "use strict";


    // @ngInject
    function WorkspaceServiceCtor($state, AppStates, ModalService, $interval, $translate, WorkspaceFileService, PreviewModeService, ModelFactory, WorkspacesManager, PopupMessageService, PubSubService, Enums, _, FeaturesService, StatsigService, ReactModalService) {

        this.$state = $state;
        this.AppStates = AppStates;
        this.ModalService = ModalService;
        this.$interval = $interval;
        this.$translate = $translate;
        this.WorkspaceFileService = WorkspaceFileService;
        this.PreviewModeService = PreviewModeService;
        this.WorkspacesManager = WorkspacesManager;
        this.PopupMessageService = PopupMessageService;
        this.clearWorkspaceConfig();
        this.Enums = Enums;
        this._ = _;
        this.FeaturesService = FeaturesService;
        this.StatsigService = StatsigService;
        this.ReactModalService = ReactModalService;
        this.ModelFactory = ModelFactory;

        this.intervalHandlesForCancelling = [];

        PubSubService.ventMyBitchUp(this);
    }


    Services.WorkspaceService = Class(function () {

        return {

            constructor: WorkspaceServiceCtor,

            getWorkspaceConfig: function getWorkspaceConfig(){
                return this.workspaceConfig;
            },

            clearWorkspaceConfig: function clearWorkspaceConfig(){
                this.workspaceConfig = {};
            },

            /**
             * goto Workspace route
             * @param workspaceId
             * @param newWorkspace should we open the add user dialog (default false)
             */
            gotoWorkspace: function gotoWorkspace(workspaceId, eventId, params, workspaceType, workspaceTab) {
                params = params || {};
                this.workspaceConfig = params;
                const tabRoute = (workspaceTab === this.AppStates.root_core_navigation_event_workspace_details) ?
                                    this.AppStates.root_core_navigation_event_workspace_details : this.AppStates.root_core_navigation_event_workspace_feed;

                if (workspaceType && (workspaceType === "general" || workspaceType === "timeline")) {
                    this.$state.go(tabRoute, {workspace_id: workspaceId, event_id: eventId});
                } else {
                    var origin = params.origin || '';
                    this.$state.go(tabRoute, {workspace_id: workspaceId, event_id: eventId, origin: origin });
                }
            },

            /**
             * goto Workspace route, open the add user dialog
             * @param workspaceId
             */
            gotoNewWorkspace: function gotoNewWorkspace(workspaceId, eventId, workspaceType, showLeadSourceToast, shouldHideAddParticipantsModal, workspaceTab){
                this.gotoWorkspace(workspaceId, eventId, {newWorkspace: true, showLeadSourceToast: showLeadSourceToast, shouldHideAddParticipantsModal: shouldHideAddParticipantsModal}, workspaceType, workspaceTab);
            },

            /**
             * goto Workspace route, open the add user dialog
             * @param workspaceId
             */
            gotoRequestProposalWorkspace: function gotoRequestProposalWorkspace(workspaceId, eventId){
                this.gotoWorkspace(workspaceId, eventId, {requestProposal: true});
            },

            addWorkspaceParticipant: function addWorkspaceParticipant(workspace, workspaceFile, company){
                return this.ReactModalService.openGlobalModal('AddClientToWorkspaceModalWrapper', {
                    workspaceId: workspace._id,
                    customFieldSchemas: company.custom_contact_fields
                }).then(function (response) {
                            // add user to workspace members
                            if (response.user) {
                                var newClientUser = this.ModelFactory.newUserModel();
                                var clientUser = angular.extend(newClientUser, response.user);
                                workspace.members.add(clientUser);
                            }

                            return response;
                        }.bind(this));
            },

            assignWorkspaceToTeamMembers: function assignWorkspaceToTeamMembers(workspace, company, teamMember) {
                var teamMemberId = teamMember && teamMember._id;
                var users = company.getOtherCompanyMembers(teamMemberId);
                return this.ModalService.openAssignWorkspaceToTeamMemberModal(workspace, users, teamMember);
            },

            addVendorToMemberList: function addVendorToMemberList(workspace){
                return this.ModalService.openAddWorkspaceVendorModal(workspace, workspace.members);
            },

            addIntervalHandleForCancelling: function addIntervalHandleForCancelling(handle){
                this.intervalHandlesForCancelling.push(handle);
            },

            cancelActiveIntervals: function cancelActiveIntervals(){
                this.intervalHandlesForCancelling.forEach(function clearInterval(handle){
                    this.$interval.cancel(handle);
                }.bind(this));

                this.intervalHandlesForCancelling = []
            },

            workspaceLoaded: function workspaceLoaded(workspaceId){
                this.loadedWorkspaceId = workspaceId;
            },

            workspaceUnloaded: function workspaceUnloaded(workspaceId){
                if(this.loadedWorkspaceId && this.loadedWorkspaceId === workspaceId){
                    this.loadedWorkspaceId = undefined;
                }
            },

            prepareStagesViewModel: function prepareStagesViewModel(userPipelineStages) {
                    var stages = angular.copy(userPipelineStages);
                    stages = this._.sortBy(stages, function (stage) {
                        return stage.order ? stage.order : 0;
                    });

                    stages.forEach(function (stage) {
                        if (!stage.originalName) {
                            stage.originalName = stage.name;
                        }
                        stage.originalName = stage.name;
                        stage.name = this.getPipelineStageName(stage.name);
                    }.bind(this));
                return stages;
            },

            getPipelineStageName: function getPipelineStageName(stageNameKey) {
                var translationKey = 'PIPELINE.STAGES.' + stageNameKey;
                var translatedName = this.$translate.instant(translationKey);
                if(translationKey.trim() === translatedName){
                    //meaning there is no such translation for that stage -> this is a custom name that
                    // was handed to it by vendor. take it as is
                    return stageNameKey;
                } else {
                    return translatedName;
                }
            },

            /**
             * Returns an empty state translation key for pipeline stage key suffix
             *
             * @param {String} suffix of a stage translation key (e.g: _INQUIRY_)
             */
            getPipelineStageEmptyStateKey: function getPipelineStageEmptyStateKey(stage) {
                stage = stage.replace(/^_|_$/g, '');
                return "PIPELINE.EMPTY_STATES." + stage;
            },

            generateAnalyticsProps: function generateAnalyticsProps(workspace) {
                if (workspace) {

                    var creatorName, creatorEmail, creatorId, creatorUserType, creatorHBUserType,
                        creatorCompanyId, creatorCompanyRole, creatorCompanyName, creatorCompanyType,
                        workspaceName, vendorsCount = 0, clientsCount = 0, membersCount = 0;
                    if (workspace.creator) {
                        var creator = workspace.creator;
                        var creatorName = creator.full_name;
                        var creatorEmail = creator.email;
                        var creatorId = creator._id;
                        var creatorUserType = creator.system_user_type;
                        var creatorCompanyRole = creator.company_role;
                        var creatorHBUserType = Array.isArray(creator.hb_user_type) ? creator.hb_user_type[0] : undefined;

                        if (creator.company) {
                            var company = creator.company;
                            var creatorCompanyId = company._id;
                            var creatorCompanyName = company.company_name;
                            var creatorCompanyType = company.company_type_name;
                        }
                    }

                    if(workspace.workspace_tab_info){
                        workspaceName = workspace.workspace_tab_info.name;
                    }
                    if(workspace.members && workspace.members.length > 0){
                        for(var i = 0; i < workspace.members.length; i++){
                            membersCount++;
                            if(workspace.members[i].isVendor()){
                                vendorsCount++;
                            }else if(workspace.members[i].isClient()){
                                clientsCount++;
                            }
                        }
                    }
                    return {workspace_name: workspaceName,
                        members_count: membersCount,
                        is_archived: workspace.isArchived(),
                        clients_count: clientsCount,
                        vendors_count: vendorsCount,
                        workspace_event_name: workspace.event ? workspace.event.event_name : '',
                        workspace_creator_id: creatorId,
                        workspace_creator_name: creatorName,
                        workspace_creator_email: creatorEmail,
                        workspace_creator_user_type: creatorUserType,
                        workspace_creator_hb_user_type: creatorHBUserType,
                        workspace_creator_company_id: creatorCompanyId,
                        workspace_creator_company_name: creatorCompanyName,
                        workspace_creator_company_type: creatorCompanyType,
                        workspace_creator_company_role: creatorCompanyRole
                    };
                }
            },

            removeMemberFromWorkspaceWrapper: function removeMemberWrapper(workspace, member, callback) {
                if (this.isMemberSignatory(workspace, member._id)) {
                    this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                        'WORKSPACE.REMOVE_MEMBER._IS_MEMBER_SIGNATORY_', this.removeMemberFromWorkspace.bind(this, workspace, member, callback));
                } else {
                    this.removeMemberFromWorkspace(workspace, member, callback);
                }
            },

            removeMemberFromWorkspace: function (workspace, member, callback) {
                this.WorkspacesManager.removeMember(workspace._id, member._id).then(function success() {
                    workspace.members.some(function (u, idx) {
                        if (u._id === member._id) {
                            workspace.members.splice(idx, 1);
                            this.WorkspacesManager.reloadWorkspaceInfo(workspace._id);
                            if (callback) {
                                callback('success');
                            }
                            return true;
                        } else {
                            return false;
                        }
                    }.bind(this));
                }.bind(this))
                    .catch(function errorDeleteMember(res) {
                        var message;
                        if (res && res.data && res.data.error_type) {
                            if (res.data.error_type === 'HBUserError') {
                                message = res.data.error_message;
                            } else {
                                this.$log.error(res.data.error_message);
                                message = 'ERRORS.SERVER_API._UNKNOWN_';
                            }
                        } else {
                            message = 'ERRORS.SERVER_API._UNEXPECTED_';
                        }

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                    }.bind(this));
            },

            isMemberSignatory: function isUserSignatory(workspace, user_id) {
                if (workspace.workspace_files && workspace.workspace_files.length > 0) {
                    for (var i = 0; i < workspace.workspace_files.length; i++) {
                        var file = workspace.workspace_files[i];
                        if (file.hasAgreement() && file.file_version_container && file.file_version_container.active_content && file.file_version_container.active_content.contract_signatures) {
                            var contractSignatures = file.file_version_container.active_content.contract_signatures;
                            if (contractSignatures) {
                                var isUserSignatory = contractSignatures.filter(function (signature) {
                                    if (user_id === signature.signee_id) {
                                        return signature;
                                    }
                                }.bind(this));
                                if (isUserSignatory.length && isUserSignatory[0].signed_on) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            },

            triggerAutoFeedMessage: function triggerAutoFeedMessage(subject, htmlBody) {
                this.trigger(this.Enums.PubSubTriggers.autoFeedMessage, {subject: subject, htmlBody: htmlBody});
            },

            triggerOpenFeedEditor: function triggerOpenFeedEditor() {
                this.trigger(this.Enums.PubSubTriggers.openFeedEditor);
            }
        };
    });
}());
