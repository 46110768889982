Directives.SelectCompanyTemplatesDirective = function SelectCompanyTemplatesDirective() {

    // @ngInject
    function SelectCompanyTemplatesDirectiveControllerFunc($injector, $scope, AnalyticsService, _, MalkutDashboardManager, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SelectCompanyTemplatesDirectiveController';
        this.AnalyticsService = AnalyticsService;
        this.MalkutDashboardManager = MalkutDashboardManager;
        this.PopupMessageService = PopupMessageService;
        this._ = _;

        this.totalSelected = 0;
        this.templatesFetched = false;
        this._init();
        this.toggleSelectAll = false;
    }
    var SelectCompanyTemplatesDirective = Class(Controllers.BaseController, {
        constructor: SelectCompanyTemplatesDirectiveControllerFunc,

        toggleExpand: function toggleExpand() {
            this.boxExpanded = !this.boxExpanded;
            this.animationInitial = false;
            this.setPersistency();
        },

        _init: function _init() {
            this.fetchTemplates();
        },

        selectAll: function selectAll() {
            this.toggleSelectAll = !this.toggleSelectAll;
            this.userTemplates.forEach(function (template) {
               template.clone_to_referral = this.toggleSelectAll;
            }.bind(this));
        },

        fetchTemplates: function fetchTemplates() {
            var self = this;
            self.gettingUserTemplates = true;

            var resPromise = this.MalkutDashboardManager.getUserTemplates(self.selectedUser._id)
                .then(function success(result) {
                    self.userTemplates = result.data.templates.filter(self.templatesFilter.bind(self));
                    self.fromUserId = result.data.from_user_id;
                    self.templatesFetched = true;
                    self.totalSelected = self.userTemplates.filter(function(template) {
                        return template.clone_to_referral;
                    }).length;

                    if (self.userTemplates.length === 0) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.warning,
                            "Failed to fetch templates for this user or they just don't exist",
                            function success() {
                            });
                    }

                })
                .catch(function error(result) {
                    self.templatesFetched = false;
                    self.lastError = result.data.error_message;
                    self.selectedUser = null;
                    self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, self.lastError);
                })
                .finally(function finallyDo() {
                    self.gettingUserTemplates = false;
                });
        },

        triggerAction: function triggerAction() {
            var filtered = this.userTemplates.filter(function(template) {
                return template.clone_to_referral;
            });
            var templates = filtered.map(function(template) {return template.id;});
            this.onTemplatesSelect({templates: templates});
        },

        updateTotalSelected: function updateTotalSelected(item) {
            if (item) {
                this.totalSelected++;
            }
            else {
                this.totalSelected--;
            }
        },

        templatesFilter: function templatesFilter(template) {
            if (this.templatesFilterType === 'flows') {
                return !!template.is_flow;
            }
            if (this.templatesFilterType === 'files') {
                return !template.is_flow;
            }

            // In any other case, don't filter
            return true;
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/templates/select_company_templates_template.html',
        controller: SelectCompanyTemplatesDirective,
        controllerAs: 'selectCompanyTemplatesVm',
        bindToController: true,
        scope: {
            selectedUser: '=',
            destinationCompany: '=',
            selectedTempaltes: '=',
            actionButtonText: '@',
            templatesFilterType: '=',
            onTemplatesSelect: '&'
        }
    };
};
