(function () {
    "use strict";

    class DatePickerQuestionController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, moment) {
            super($scope, $injector);
            this.__objectType = 'DatePickerQuestionController';

            this.moment = moment;

            this.displayDateFormat = "MM/DD/YY";
            this.apiDateFormat = "YYYY-MM-DD";
            this.showRequiredError = false;
            this._date = null;
        }

        $onChanges() {

            if(this.question.answer && !this._date) {

                // make sure date is valid
                let _date = this.moment(this.question.answer, this.apiDateFormat);

                if (_date.isValid()) {
                    this._date = _date.format(this.displayDateFormat);
                }
            }
        }

        checkError() {
            this.showRequiredError = !this.question.isAnswerValid();
        }

        answer() {

            // format for api
            this.question.answer = this.moment(this._date).format(this.apiDateFormat);

            // trigger validation check
            this.checkError();

            return this.question.answerQuestion(['answer']);
        }
    }

    Components.DatePickerQuestion = {
        bindings: {
            question: '<',
            isEditable: '<',
            isOwnerMode: '<',
            isAnswerable: '=',
            isStyleCustomizable: '<',
        },
        controller: DatePickerQuestionController,
        controllerAs: 'datePickerQuestionVm',
        name: 'hbDatePickerQuestion',
        templateUrl: 'angular/app/modules/core/features/dynamic_content/questions/datepicker_question/datepicker_question.html',
    };

}());
