/**
 * Created by dvircohen on 13/07/2017.
 */
(function () {
    'use strict';

    // @ngInject
    function GiftCardTemplateEmptyStateControllerCtor($scope, $injector, AnalyticsService, TemplatesViewService, DeviceService, $state, AppStates, ModalService, ContactFormService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GiftCardTemplateEmptyStateController';
        AnalyticsService.trackPageView(this, 'GiftCardTemplateEmptyStateView');

        this.TemplatesViewService = TemplatesViewService;
        this.$state = $state;
        this.AppStates = AppStates;
        this.ModalService = ModalService;
        this.DeviceService = DeviceService;
        this.ContactFormService = ContactFormService;
        this.isMobile = this.DeviceService.nxSmallBreakpoint();
        this.openAddGiftCardModal();
    }

    Controllers.GiftCardTemplateEmptyStateController = Class(Controllers.BaseController, {
        constructor: GiftCardTemplateEmptyStateControllerCtor,

        openAddGiftCardModal: function openAddGiftCardModal() {
            if (!this.isMobile) {
                return this.ContactFormService.openAddGiftCardModal();
            }
        }
    });
}());

