(function () {
    'use strict';

    class ContactFormDesignColorsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {

            super($scope, $injector);
            this.__objectType = 'ContactFormDesignColorsController';

            this.showAlpha = true;
            this.showPalette = false;

            const defaultColor = "#000000";
            this.questionFontColor = this.contactFormModel.file_dynamic_content.style_container.form_component_style ? this.contactFormModel.file_dynamic_content.style_container.form_component_style.font_color : defaultColor;
            this.questionFieldColor = this.contactFormModel.file_dynamic_content.style_container.form_component_style? this.contactFormModel.file_dynamic_content.style_container.form_component_style.border_color : defaultColor;
            this.questionBackgroundColor = this.contactFormModel.file_dynamic_content.style_container.form_component_style ? this.contactFormModel.file_dynamic_content.style_container.form_component_style.background_color : defaultColor;

            this.buttonBackgroundColor = this.contactFormModel.file_dynamic_content.style_container.button_component_style ? this.contactFormModel.file_dynamic_content.style_container.button_component_style.background_color : defaultColor;
            this.buttonFontColor = this.contactFormModel.file_dynamic_content.style_container.button_component_style ? this.contactFormModel.file_dynamic_content.style_container.button_component_style.font_color : defaultColor;

            this.globalBackgroundColor = this.contactFormModel.file_dynamic_content.style_container.global_style ? this.contactFormModel.file_dynamic_content.style_container.global_style.background_color : defaultColor;

        }
    }

    Components.ContactFormDesignColors = {
        bindings: {
            contactFormModel: '=',
            onColorSelected: '&',
            onPickerShow: '&',
        },
        controller: ContactFormDesignColorsController,
        templateUrl: 'angular/app/modules/core/features/contact_form/aside_menu_content/contact_form_design_colors/contact_form_design_colors.html',
    };

}());