/**
 * The hb-create-new-panel
 *
 * This directive shows the create new options: new project, new client, new invoice in randomized order.
 *
 * parameters:
 * base-css-class-name : the class name to be nested for both ul and li
 * on-before-item-click : function to be called before any item click
 * on-before-create-invoice-click: function to be called before create new invoice click
 * is-tile-mode: Boolean. display mode of tiles rather than a list
 * is-mobile: Boolean. is mobile.
 *
 */

(function () {
    'use strict';

    Directives.CreateNewPanel = function CreateNewPanel() {

        // @ngInject
        function CreateNewPanelControllerConstructor($scope, $injector, $state, UiPersistenceService, _, EventService, ModalService, WorkspaceService,
                                                     UsersManager, UserService, $timeout, Enums, $rootScope, NavigationService, AppStates, PopupMessageService,
                                                     FeaturesService, FlowsBetaUserTypeService, OtamStatusService, ReactModalService, FeatureRestrictionService,
                                                     StatsigService, ContactViewService, DeviceService) {
            this.constructor.$super.call(this, $scope, $injector);
            this._objectType = 'CreateNewPanelController';
            this.UiPersistenceService = UiPersistenceService;
            this._ = _;
            this.EventService = EventService;
            this.ModalService = ModalService;
            this.WorkspaceService = WorkspaceService;
            this.UserService = UserService;
            this.UsersManager = UsersManager;
            this.$timeout = $timeout;
            this.$rootScope = $rootScope;
            this.$state = $state;
            this.Enums = Enums;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.ReactModalService = ReactModalService;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.StatsigService = StatsigService;
            this.ContactViewService = ContactViewService;

            this.UsersManager.forceFetchCurrUser();
            this.currUser = this.UsersManager.getCurrUser();
            this.NavigationService = NavigationService;
            this.AppStates = AppStates;
            this.PopupMessageService = PopupMessageService;
            this.FeaturesService = FeaturesService;
            this.OtamStatusService = OtamStatusService;
            this.isUserVendor = this.currUser.isVendor();
            this.currentUser = UsersManager.getCurrUser();
            this.isOtamUser = this.OtamStatusService.hasOtamVariation;
            this.isLeadFormsEnabled = this.FlowsBetaUserTypeService.setIsLeadFormsUser();

            if (this.isUserVendor) {
                this.paymentsValueSum = this.UserService.paymentsValueSum;
            }

            this.isDesktop = !DeviceService.isInAppBrowser() && !DeviceService.nxSmallBreakpoint();

            const servicesHubPagePromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.servicesHubPage, 'show_page', false);
            const servicesHubPageConsultantsPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.servicesHubPageForConsultants, 'show_page', false);

            Promise.all([servicesHubPagePromise, servicesHubPageConsultantsPromise]).then(
                function (results) {
                    this.showServicesHub = this.isDesktop && (results[0] || results[1] || this.currUser.isTrialStartedAfterServicesHubRolloutDate());
                    this.setOptions();
                }.bind(this)
            );

            this.addNewPrefixToNames = !this.isTileMode && !(this.baseCssClassName === 'hb-nav');

            // turned this into a function so I can call it when the lead forms promise returns
            this.setOptions = function setOptions() {
                // + new options list
                this.options = [{
                    key: this.Enums.createNew.project,
                    order: 0,
                    translationKey: this.addNewPrefixToNames
                        ? "MAIN_NAV.CREATE_MENU._NEW_PROJECT_"
                        : "MAIN_NAV.CREATE_MENU._PROJECT_",
                    onClick: this.createEvent.bind(this),
                    iconCssClass: this.baseCssClassName + "__create-menu__option-icon icon-hb-nx-briefcase",
                    analyticsEvent: "create project",
                    analyticsProps: "source: create menu"
                }, {
                    key: this.Enums.createNew.client,
                    order: 0,
                    translationKey: this.addNewPrefixToNames
                        ? "MAIN_NAV.CREATE_MENU._NEW_CLIENT_"
                        : "MAIN_NAV.CREATE_MENU._CLIENT_",
                    onClick: this.onCreateClientClicked.bind(this),
                    iconCssClass: this.baseCssClassName + "__create-menu__option-icon icon-hb-nx-user",
                    analyticsEvent: "create client",
                    analyticsProps: "source: create menu"
                }, {
                        key: this.Enums.createNew.invoice,
                        order: 0,
                        translationKey: this.addNewPrefixToNames
                            ? "MAIN_NAV.CREATE_MENU._NEW_INVOICE_"
                            : "MAIN_NAV.CREATE_MENU._INVOICE_",
                        onClick: this.onCreateInvoiceClicked.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__option-icon icon-hb-nx-invoice",
                        analyticsEvent:  "create invoice",
                        analyticsProps: this.FlowsBetaUserTypeService.hasNewFlowExp ? "type: flow" : "source: create menu"
                }];

                if (this.FlowsBetaUserTypeService.hasOnlyFlows && this.isHomeForTrialer && !this.isMobile) {
                    this.options.push({
                        key: this.Enums.createNew.contract,
                        order: 0,
                        translationKey: "MAIN_NAV.CREATE_MENU._NEW_CONTRACT_",
                        onClick: this.onCreateContractClicked.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__option-icon icon-hb-nx-contract",
                        analyticsEvent:  "create contract",
                        analyticsProps: "type: flow"
                    })
                }

                if (this.showServicesHub) {
                    this.options.push({
                        key: this.Enums.createNew.service,
                        order: 0,
                        translationKey: this.addNewPrefixToNames
                            ? "MAIN_NAV.CREATE_MENU._NEW_SERVICE_"
                            : "MAIN_NAV.CREATE_MENU._SERVICE_",
                        onClick: this.onCreateService.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__service-icon",
                        iconSvg: '<i class="icon-hb-dollar-list-24"></i>',
                        analyticsEvent: "create service",
                        analyticsProps: "source: create menu"
                    });
                }

                if (this.showCreateMeeting) {
                    this.options.push({
                        key: this.Enums.createNew.meeting,
                        order: 0,
                        translationKey: this.addNewPrefixToNames
                            ? "MAIN_NAV.CREATE_MENU._NEW_SCHEDULE_"
                            : "MAIN_NAV.CREATE_MENU._SCHEDULE_",
                        onClick: this.onScheduleMeetingClicked.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__option-icon icon-hb-nx-calendar",
                        analyticsEvent: "create meeting",
                        analyticsProps: "source: create menu"
                    });
                }

                if (this.FlowsBetaUserTypeService.hasFlows) {
                    var createFlowStyles = this.baseCssClassName + "__create-menu__option-icon icon-hb-file-sparks-24 ";

                    var createFlowMenuItem = {
                        key: this.Enums.createNew.flow,
                        order: 0,
                        translationKey: "MAIN_NAV.CREATE_MENU._FLOW_",
                        translationKey: this.addNewPrefixToNames
                            ? "MAIN_NAV.CREATE_MENU._NEW_FLOW_"
                            : "MAIN_NAV.CREATE_MENU._FLOW_",
                        onClick: this.onCreateFlow.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__option-icon " + createFlowStyles,
                        analyticsEvent: "create flow",
                        analyticsProps: "source: create menu"
                    };

                    this.options.splice(3, 0, createFlowMenuItem);
                }

                if (this.isLeadFormsEnabled && this.baseCssClassName === 'hb-nav') {
                    var createLeadFormMenuItem = {
                        key: this.Enums.createNew.lead_form,
                        order: 0,
                        translationKey: this.addNewPrefixToNames
                            ? "MAIN_NAV.CREATE_MENU._NEW_LEAD_FORM_"
                            : "MAIN_NAV.CREATE_MENU._LEAD_FORM_",
                        onClick: this.onGoToLeadForms.bind(this),
                        iconCssClass: this.baseCssClassName + "__create-menu__option-icon--lead-form",
                        iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="21px" viewBox="0 0 20 21" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:evenodd;fill:rgb(56, 63, 73);fill-opacity:1;" d="M 0.910156 0 L 10.910156 0 L 10.910156 1.910156 L 1.816406 1.910156 L 1.816406 19.089844 L 18.183594 19.089844 L 18.183594 11.453125 L 20 11.453125 L 20 20.046875 C 20 20.574219 19.59375 21 19.089844 21 L 0.910156 21 C 0.40625 21 0 20.574219 0 20.046875 L 0 0.953125 C 0 0.425781 0.40625 0 0.910156 0 Z M 12.726562 3.339844 L 14.261719 4.113281 L 15 5.726562 L 15.738281 4.113281 L 17.273438 3.339844 L 15.738281 2.566406 L 15 0.953125 L 14.261719 2.566406 Z M 13.636719 7.636719 L 3.636719 7.636719 L 3.636719 9.546875 L 13.636719 9.546875 Z M 16.363281 11.453125 L 3.636719 11.453125 L 3.636719 13.363281 L 16.363281 13.363281 Z M 3.636719 15.273438 L 16.363281 15.273438 L 16.363281 17.183594 L 3.636719 17.183594 Z M 16.988281 8.886719 L 15.453125 8.113281 L 16.988281 7.339844 L 17.726562 5.726562 L 18.464844 7.339844 L 20 8.113281 L 18.464844 8.886719 L 17.726562 10.5 Z M 16.988281 8.886719 "/></g></svg>',
                        analyticsEvent: "public_flows",
                        analyticsProps: "source: create menu",
                        isNew: true
                    };

                    this.options.splice(6, 0, createLeadFormMenuItem);
                }
            }

            this.setOptions();

        }

        var CreateNewPanelController = Class(Controllers.BaseController, {
            constructor: CreateNewPanelControllerConstructor,

            itemClickProxy: function itemClickProxy(currOption) {

                if (!this.currUser.account.pricing_plan && this.currUser.isSubscriptionPaused()) {
                    return this.ModalService.openPausedOfferModal();
                } else if (this.currUser.notSelectedNextPlanCode() && this.paymentsValueSum > 10000) {
                    return this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.info, 'SETTINGS.SUBSCRIPTION.PASSED_10K._DESCRIPTION_', 'SETTINGS.SUBSCRIPTION.PASSED_10K._CTA_', 'SETTINGS.SUBSCRIPTION.PASSED_10K._TITLE_').then(function () {
                        this.ReactModalService.openUpgradePlanModal();
                    }.bind(this));
                }

                var isBlockedAccess = this.FeatureRestrictionService.checkIfBlockedAccess({
                    actionType: 'click'
                });
                if (isBlockedAccess) {
                    return;
                }

                if (this.onBeforeItemClick && angular.isFunction(this.onBeforeItemClick) && currOption) {
                    this.onBeforeItemClick({itemType: currOption.key});
                }

                if (currOption) {
                    currOption.onClick();
                }
            },

            createEvent: function createEvent() {
                this.EventService.createEventCommand();
            },

            onCreateClientClicked: function onCreateClientClicked() {
                // for desktop users, we show the upload clients csv modal
                // for the first time they click to add a new client
                if (this.isMobile) {
                    this._openAddClientModal();
                    return;
                }

                // TODO ALON - The brief says to open only for new subscribers. For now, we will open to all subscribers.
                var shouldShowModal = false;
                // if(true) {
                if (this.currUser.companyHasSubscription() || this.currUser.companyHasFreeSubscription()) {
                    var uploadClientsCSVModalSeen = this.UiPersistenceService.getUiPersistence(
                        this.UiPersistenceService.keys.uploadClientsCSV, {seen: false}).seen;
                    if (!uploadClientsCSVModalSeen) {
                        shouldShowModal = true;
                        this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.uploadClientsCSV, {seen: true});
                    }
                }

                if (shouldShowModal) {
                    this.ModalService.openUploadClientsModal().then(function () {

                    }).catch(function (reason) {

                        // if we got here it means the user didn't choose
                        // to upload a csv
                        if (reason === 'addClient') {

                            this.$timeout(function () {
                                this._openAddClientModal();
                            }.bind(this), 300);

                        } else if (reason === 'syncGoogleContact') {
                            this.UserService.goToContactsAndSyncGoogle();
                        }
                    }.bind(this));
                } else {
                    this._openAddClientModal();
                }
            },

            _openAddClientModal: function _openAddClientModal() {
                this.ModalService.openAddClientTopNavModal().then(function (resp) {
                    var promises = [
                        this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.createNewClientRedirectTrialers, false),
                        this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.createNewClientRedirectMembers, false)
                    ];
                    Promise.all(promises).then(function (results) {
                        if (_.some(results)) {
                            this.ContactViewService.openContactView(resp.user.contact._id, this.isHomeForTrialer ? 'home for trialer create client' : 'top nav create client');
                        } else {
                            this.WorkspaceService.gotoWorkspace(resp.workspaceId, resp.projectId, {fromTopNavMenu: true});
                        }
                    }.bind(this));
                }.bind(this));
            },

            onCreateInvoiceClicked: function onCreateInvoiceClicked() {
                if (this.FlowsBetaUserTypeService.hasNewFlowExp) {
                    return this.ModalService.openCreateFlowModal({}, this.currentUser.company, ['invoice']);

                } else {
                    this.ModalService.openStartFromFileWizardModal({
                        type: this.Enums.createNew.invoice
                    });
                }
            },

            onCreateContractClicked: function onCreateContractClicked() {
                return this.ModalService.openCreateFlowModal({}, this.currentUser.company, ['contract']);
            },

            onScheduleMeetingClicked: function onScheduleMeetingClicked() {
                var meetingProps = {
                    isCreateMeeting: true,
                    allDay: true
                };

                if (this.NavigationService.isRouteActive(this.AppStates.root_core_navigation_calendar)) {
                    this.$rootScope.$broadcast('handle-schedule-meeting', meetingProps);
                } else {
                    this.ModalService.openCalendarMeetingModal(meetingProps).then(function () {

                        // if on mobile, should go back to the state we came from
                        if (this.isMobile) {
                            this.NavigationService.goHistoryBack();
                        }

                    }.bind(this));
                }
            },

            onCreateFlow: function onCreateFlow() {
                return this.ModalService.openCreateFlowModal({}, this.currentUser.company);
            },

            onCreateService: function onCreateService() {
                return this.ReactModalService.openAddServiceTemplateModal({ source: 'top_nav_create_new'});
            },

            onGoToLeadForms: function onGoToLeadForms() {
                if(typeof this.onMenuClose === 'function') {
                    this.onMenuClose();
                }
                this.$state.go(this.AppStates.root_core_navigation_lead_capture, {});
            }
        });

        return {
            scope: {
                baseCssClassName: '@',
                isMobile: '=',
                isTileMode: '<?',
                onBeforeItemClick: '&?',
                onBeforeCreateInvoiceClick: '&?',
                showCreateMeeting: '<?',
                isHomeForTrialer: '<?',
                onMenuClose: '&?'
            },
            templateUrl: 'angular/app/modules/common/ui_components/create_new_panel/create_new_panel_template.html',
            controller: CreateNewPanelController,
            controllerAs: 'createNewPanelVm',
            bindToController: true
        };
    };


}());
