/**
 * The hb-popover-select-email-template component
 *
 * This component is a syntactic sugar for hb-popover when we want to show a popover with a list of available email templates to choose from
 *
 *
 * parameters:
 * is-visible: Boolean. a flag to show/hide the popover
 * show-arrow: Boolean. rather or not to show an arrow
 * arrow-position: String. top/bottom/left/right
 * on-template-select: Function. to be called on item click.
 * analytics-event-name: String. to tracked by analytics
 * allow-delete: Boolean. Should show the trash can.
 * allow-save: Boolean. Should show the save panel
 * on-template-delete: Function. to be called on trashcan
 * on-template-save: Function. to be called on template save click
 * is-saving-template: Boolean. indicating template is saving
 *
 */




(function () {
    'use strict';

    class PopoverSelectEmailTemplateController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, CompaniesManager, AnalyticsService) {
            super($scope, $injector);
            this.__objectType = 'PopoverSelectEmailTemplateController';
            this.AnalyticsService = AnalyticsService;

            this.templateName = '';
            this.company = CompaniesManager.getCurrCompany();
        }

        onClick(template) {
            if (this.analyticsEventName) {
                this.AnalyticsService.trackClick(this, this.analyticsEventName);
            }

            if (angular.isFunction(this.onTemplateSelect)) {
                this.onTemplateSelect({template: template});
            }

        }

        deleteTemplate(event, template) {
            event.stopPropagation();
            if (angular.isFunction(this.onTemplateDelete)) {
                this.onTemplateDelete({template: template});
            }
        }

        stopPropagation(event) {
            event.stopPropagation();
        }

        saveTemplate() {

            this.AnalyticsService.trackClick(this, 'email Save As');
            if (angular.isFunction(this.onTemplateSave)) {
                this.onTemplateSave({templateName: this.templateName});
            }
        }

        $onChanges(changes) {
            if (changes.isSavingTemplate && changes.isSavingTemplate.currentValue === false && changes.isSavingTemplate.previousValue === true) {
                this.templateName = '';
            }
        }

        inputSaveAsKeyUp(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();

                this.saveTemplate();
            } else if (keyCode === 32) {
                e.preventDefault();
                e.stopPropagation();
            }
        }

        inputSaveAsKeyDown(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
            } else if (keyCode === 32) {
                e.stopPropagation();
            }
        }

        isDisableSaveTemplateButton() {
            return this.templateName === '';
        }
    }

    Components.PopoverSelectEmailTemplate = {
        bindings: {
            isVisible: '<',
            showArrow: '<',
            arrowPosition: '@',
            onTemplateSelect: '&',
            analyticsEventName: '@',
            allowDelete: '<',
            allowSave: '<',
            onTemplateDelete: '&',
            onTemplateSave: '&',
            isSavingTemplate: '<',
        },
        controller: PopoverSelectEmailTemplateController,
        name : 'hbPopoverSelectEmailTemplate',
        templateUrl: 'angular/app/modules/common/ui_components/popover-select-email-template/popover-select-email-template.html',
    };

}());
