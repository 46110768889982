(function () {
    'use strict';

    Services.QuestionService = class QuestionService {

        // @ngInject
        constructor(uuid4, Enums, CompanyService, $translate, WorkspaceFilesManager, ModelFactory) {
            this.uuid4 = uuid4;
            this.Enums = Enums;
            this.CompanyService = CompanyService;
            this.$translate = $translate;
            this.WorkspaceFilesManager = WorkspaceFilesManager;
            this.ModelFactory = ModelFactory;

            var questionsDisplayText = [
                {key: this.Enums.suggestedQuestions.full_name, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._FULL_NAME_'},
                {key: this.Enums.suggestedQuestions.email, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._EMAIL_'},
                {key: this.Enums.suggestedQuestions.phone_number, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PHONE_NUMBER_'},
                {key: this.Enums.suggestedQuestions.about, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._ABOUT_'},
                {key: this.Enums.suggestedQuestions.lead_source, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LEAD_SOURCE_'},
                {key: this.Enums.suggestedQuestions.project_type, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_TYPE_'},
                {key: this.Enums.suggestedQuestions.project_location, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._LOCATION_'},
                {key: this.Enums.suggestedQuestions.project_budget, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._BUDGET_'},
                {key: this.Enums.suggestedQuestions.guests, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._GUESTS_'},
                {key: this.Enums.suggestedQuestions.project_date, text: 'DYNAMIC_CONTENT.QUESTIONS.SUGGESTED._PROJECT_DATE_'},
            ];

            this.connectedQuestionDisplayTextMap = questionsDisplayText.reduce((obj, item) => {
                obj[item.key] = item;
                return obj;
            }, {});

            this.initPresetQuestions();
        }

        ///////////////////////////////////
        // OLD QUESTIONS CODE
        //////////////////////////////////

        addOptionalAnswer(question) {
            question.optional_answers.push({
                _id: this.uuid4.generate(),
                answer_text: null,
                selected: false
            });
        }

        removeOptionalAnswer(question, id) {
            var index = _.findIndex(question.optional_answers, function (optional_answer) {
                return optional_answer._id === id;
            });

            if (index !== -1) {
                question.optional_answers.splice(index, 1);
            }
        }

        ///////////////////////////////////
        // END OF OLD QUESTIONS CODE
        //////////////////////////////////

        initPresetQuestions() {
            this.CONTACT_FORM_PRESETS = {};
            this.PREVIEW_QUESTIONS = {};
            this.WorkspaceFilesManager.getFormComponentPresets().then((resp) => {
                if (resp.data && typeof resp.data === 'object') {
                    this.CONTACT_FORM_PRESETS = resp.data;

                    Object.keys(this.Enums.suggestedQuestions).forEach((key) => {
                        let type = this.Enums.suggestedQuestions[key];
                        if (this.CONTACT_FORM_PRESETS[type]) {
                            let question = this.ModelFactory.newQuestionModel();
                            ['question_type', 'text', 'placeholder'].forEach(i => question[i] = this.CONTACT_FORM_PRESETS[type][i]);
                            if (question.isMultiChoiceQuestion()) {
                                question.addOption();
                                question.addOption();
                            }
                            this.PREVIEW_QUESTIONS[type] = question;
                        }
                    });
                }
            });
        }

        generatePreviewQuestion(newType, currQuestion) {
            let question = null;
            let isConnectedQuestion = newType.categoryName === this.Enums.questionsCategories.suggested;
            if (isConnectedQuestion) {
                if (this.PREVIEW_QUESTIONS[newType.key]) {
                    question = this.PREVIEW_QUESTIONS[newType.key];
                }
            } else if (newType.key === this.Enums.customQuestions.two_fields) {
                question = this.ModelFactory.newQuestionModel();
                question.question_type = 'open_question';
                return [currQuestion.getCopy(), question];
            } else {
                question = this.ModelFactory.newQuestionModel();
                question.question_type = newType.key;
                question.text = currQuestion.text;
                question.placeholder = currQuestion.placeholder;
                question.optional_answers = currQuestion.optional_answers ? currQuestion.optional_answers.slice() : null;
                if (question.isMultiChoiceQuestion()) {
                    while (!question.optional_answers || question.optional_answers.length < 2) {
                        question.addOption();
                    }
                }
            }
            return question;
        }

        getQuestionTypeByConnectedProperty(suggestedType) {

            // long text
            if(suggestedType === this.Enums.suggestedQuestions.about) {
                return this.Enums.customQuestions.long_text;
            }

            // Date Picker
            if(suggestedType === this.Enums.suggestedQuestions.project_date) {
                return this.Enums.customQuestions.date_picker;
            }

            // dropdown
            if(suggestedType === this.Enums.suggestedQuestions.lead_source ||
                suggestedType === this.Enums.suggestedQuestions.project_type) {
                return this.Enums.customQuestions.drop_down;
            }

            // short text
            if(suggestedType === this.Enums.suggestedQuestions.full_name ||
                suggestedType === this.Enums.suggestedQuestions.email ||
                suggestedType === this.Enums.suggestedQuestions.phone_number ||
                suggestedType === this.Enums.suggestedQuestions.project_location ||
                suggestedType === this.Enums.suggestedQuestions.project_budget ||
                suggestedType === this.Enums.suggestedQuestions.guests) {
                return this.Enums.customQuestions.short_text;
            }

        }

        getQuestionComponentType(question) {
            var componentType = '';
            var questionType = question.question_type;

            // short text
            if(questionType === this.Enums.customQuestions.short_text ||
                questionType === this.Enums.customQuestions.long_text) {

                componentType = 'text';

                // dropdown
            } else if(questionType === this.Enums.customQuestions.single_choice ||
                questionType === this.Enums.customQuestions.multiple_choice ||
                questionType === this.Enums.customQuestions.drop_down) {

                componentType = 'multi_choice';

                // date picker
            } else if(questionType === this.Enums.customQuestions.date_picker) {
                componentType = 'date_picker';
            }

            return componentType;
        }

        /**
         * @param component
         * @param question - when null, it is assumed the type update is for the entire form component / single question
         * @param newQuestionType
         * @param changeToSmartQuestion
         * @returns {*}
         */
        changeQuestionType(component, question, newQuestionType, changeToSmartQuestion) {
            var params = {};

            if (changeToSmartQuestion) {
                if (question) {
                    params['question'] = {question_id: question._id, question_preset: newQuestionType};
                } else {
                    params['form_preset'] = newQuestionType;
                }

            } else if (newQuestionType === this.Enums.customQuestions.two_fields) {
                params['form_type'] = 'two_fields';

            } else {
                params['question'] = { question_type: newQuestionType };
                if (question) {
                    params['question']['question_id'] = question._id;
                } else {
                    params['form_type'] = 'single_question';
                }
            }

            // update server
            return component.updateFormComponentType(params);

            // optimistic update
            //this.QuestionService.changeQuestionType(this.question, type);
            //this._onChanges();

        }

        editQuestionConnectedList(question, company, analyticsSource) {
            if(question.connected_property === this.Enums.suggestedQuestions.lead_source) {
                return this.CompanyService.editLeadSources(company, { secondary_source: analyticsSource});
            } else if(question.connected_property === this.Enums.suggestedQuestions.project_type) {
                return this.CompanyService.editProjectTypes(company, { secondary_source: analyticsSource});
            }
        }

        getAvailableQuestionTypes(modelType) {

            let customInputs = [
                this.Enums.customQuestions.two_fields,
                this.Enums.customQuestions.short_text,
                this.Enums.customQuestions.long_text,
                this.Enums.customQuestions.drop_down,
                this.Enums.customQuestions.single_choice,
                this.Enums.customQuestions.multiple_choice,
                this.Enums.customQuestions.date_picker
            ];

            let linkedInputs = [
                this.Enums.suggestedQuestions.about,
                this.Enums.suggestedQuestions.lead_source,
                this.Enums.suggestedQuestions.project_type,
                this.Enums.suggestedQuestions.project_location,
                this.Enums.suggestedQuestions.project_budget,
                this.Enums.suggestedQuestions.project_date,
                this.Enums.suggestedQuestions.guests
            ];

            if(modelType === 'contact_form') {
                linkedInputs = [
                    this.Enums.suggestedQuestions.full_name,
                    this.Enums.suggestedQuestions.email,
                    this.Enums.suggestedQuestions.phone_number
                ].concat(linkedInputs);
            }

            return customInputs.concat(linkedInputs);
        }

        getConnectedQuestionDisplayText(question) {
            if (question.isSmartQuestion()) {
                var translationKey = this.connectedQuestionDisplayTextMap[question.connected_property];
                return this.$translate.instant(translationKey.text);
            } else {
                return "";
            }
        }

    };

// changeQuestionType(question, type) {
//
//     // reset options of origin question type is connected
//     if(question.isSmartQuestion() && question.optional_answers) {
//         question.optional_answers = [];
//     }
//
//     // update type and connected property
//     if(type.categoryName === this.Enums.questionsCategories.custom) {
//         question.connected_property = undefined;
//         question.question_type = type.key;
//     } else if(type.categoryName === this.Enums.questionsCategories.suggested) {
//         question.connected_property = type.key;
//         question.question_type = this.getQuestionTypeByConnectedProperty(question.connected_property);
//     }
//
//     // if we changed to a multi choice question, make sure we have at least 2 options
//     var componentType = this.getQuestionComponentType(question);
//     if(type.categoryName === this.Enums.questionsCategories.custom &&
//         componentType === 'multi_choice') {
//         question.optional_answers = question.optional_answers || [];
//         while(question.optional_answers.length < 2) {
//             question.addOption();
//         }
//     }
//
//     question.trigger('questionUpdated');
//
// }

}());

